import { Link } from 'react-router-dom'

import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Como jogar" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Adivinhe a referência Bíblica em 5 tentativas. Depois de cada palpite, a
        cor dos blocos mudarão para mostrar o quão próximo seu palpite estava da
        solução.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="M" isCompleted={true} solutionLength={7} />
        <Cell value="T" isCompleted={true} solutionLength={7} />
        <Cell value="0" isCompleted={true} solutionLength={7} />
        <Cell value="1" isCompleted={true} solutionLength={7} />
        <Cell value=":" isCompleted={true} solutionLength={7} />
        <Cell value="0" isCompleted={true} solutionLength={7} />
        <Cell value="1" isCompleted={true} solutionLength={7} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Para o livro você deve acertar a sigla e para o capítulo e versículo
        sempre usar 2 dígitos:
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="1" isCompleted={true} solutionLength={7} />
        <Cell value="S" isCompleted={true} solutionLength={7} />
        <Cell value="0" isCompleted={true} solutionLength={7} />
        <Cell value="1" isCompleted={true} solutionLength={7} />
        <Cell value=":" isCompleted={true} solutionLength={7} />
        <Cell value="0" isCompleted={true} solutionLength={7} />
        <Cell value="3" isCompleted={true} solutionLength={7} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        <span style={{ color: '#a61717' }}>Obs:</span> Para os livros 1 e 2
        Samuel, 1 e 2 Reis, 1 e 2 Crônicas, 1 e 2 Coríntios, 1 e 2
        Tessalonicenses, 1 e 2 Timóteo, 1 e 2 Pedro, e 1, 2 e 3 João, sempre
        utilize o número seguido da letra.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="M"
          status="correct"
          position={0}
          solutionLength={7}
        />
        <Cell value="T" isCompleted={true} solutionLength={7} />
        <Cell value="0" isCompleted={true} solutionLength={7} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="1"
          status="correct"
          position={0}
          solutionLength={7}
        />
        <Cell value=":" isCompleted={true} solutionLength={7} />
        <Cell value="0" isCompleted={true} solutionLength={7} />
        <Cell value="1" isCompleted={true} solutionLength={7} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Caso você acerte uma letra ou número no lugar certo, este aparecerá em
        verde.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="M"
          status="correct"
          position={0}
          solutionLength={7}
        />
        <Cell value="T" isCompleted={true} solutionLength={7} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="0"
          status="present"
          position={0}
          solutionLength={7}
        />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="1"
          status="correct"
          position={0}
          solutionLength={7}
        />
        <Cell value=":" isCompleted={true} solutionLength={7} />
        <Cell value="0" isCompleted={true} solutionLength={7} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="1"
          status="present"
          position={0}
          solutionLength={7}
        />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Caso você acerte uma letra ou número no lugar errado, este aparecerá em
        amarelo.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="M"
          status="correct"
          position={0}
          solutionLength={7}
        />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="T"
          status="absent"
          position={0}
          solutionLength={7}
        />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="0"
          status="present"
          position={0}
          solutionLength={7}
        />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="1"
          status="correct"
          position={0}
          solutionLength={7}
        />
        <Cell value=":" isCompleted={true} solutionLength={7} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="0"
          status="absent"
          position={0}
          solutionLength={7}
        />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="1"
          status="present"
          position={0}
          solutionLength={7}
        />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Para os números e letras que não fazem parte do versículo, estes
        aparecerão em cinza.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="M" isCompleted={true} solutionLength={7} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="2"
          status="incorrectCharType"
          position={3}
          solutionLength={7}
        />
        <Cell value="0" isCompleted={true} solutionLength={7} />
        <Cell value="1" isCompleted={true} solutionLength={7} />
        <Cell value=":" isCompleted={true} solutionLength={7} />
        <Cell value="0" isCompleted={true} solutionLength={7} />
        <Cell value="1" isCompleted={true} solutionLength={7} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        O número 2 é um tipo de caractere incorreto; Experimente uma letra
      </p>
      {/* <div className="mb-1 mt-4 flex justify-center">
        <Cell value="1" isCompleted={true} solutionLength={7} />
        <Cell value="J" isCompleted={true} solutionLength={7} />
        <Cell value="O" isCompleted={true} solutionLength={7} />
        <Cell value="A" isCompleted={true} solutionLength={7} />
        <Cell value="O" isCompleted={true} solutionLength={7} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="1"
          status="low"
          position={4}
          solutionLength={7}
        />
        <Cell value=":" isCompleted={true} solutionLength={7} />
        <Cell value="6" isCompleted={true} solutionLength={7} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        O número 1 é muito baixo; Experimente um número maior.
      </p>
      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="J" isCompleted={true} solutionLength={6} />
        <Cell value="U" isCompleted={true} solutionLength={6} />
        <Cell value="D" isCompleted={true} solutionLength={6} />
        <Cell value="A" isCompleted={true} solutionLength={6} />
        <Cell value="S" isCompleted={true} solutionLength={6} />
        <Cell value="2" isCompleted={true} solutionLength={6} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="9"
          status="high"
          position={5}
          solutionLength={6}
        />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        O número 9 é muito alto; Experimente um número menor.
      </p> */}
      <div className="mt-5 columns-2 items-stretch justify-center text-center dark:text-white sm:mt-6">
        <div className="mt-4">
          <p>Precisa de mais ajuda?</p>
        </div>
        <Link
          to="/ajuda"
          className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-sky-700 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-base"
          onClick={() => handleClose()}
        >
          Ajuda Adicional
        </Link>
      </div>
    </BaseModal>
  )
}
