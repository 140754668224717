export const BOOKS: string[] = [
  'Gn',
  'Ex',
  'Lv',
  'Nm',
  'Dt',
  'Js',
  'Jz',
  'Rt',
  '1S',
  '2S',
  '1R',
  '2R',
  '1C',
  '2C',
  'Ed',
  'Ne',
  'Et',
  'Jo',
  'Sl',
  'Pv',
  'Ec',
  'Ct',
  'Is',
  'Jr',
  'Lm',
  'Ez',
  'Dn',
  'Os',
  'Jl',
  'Am',
  'Ob',
  'Jn',
  'Mq',
  'Na',
  'Hb',
  'Sf',
  'Ag',
  'Zc',
  'Ml',
  'MT',
  'Mc',
  'Lc',
  'Jo',
  'At',
  'Rm',
  '1C',
  '2C',
  'Gl',
  'Ef',
  'Fp',
  'Cl',
  '1T',
  '2T',
  '1T',
  '2T',
  'Tt',
  'Fm',
  'Hb',
  'Tg',
  '1P',
  '2P',
  '1J',
  '2J',
  '3J',
  'Jd',
  'Ap',
]

interface iLivros {
  sigla: string
  livro: string
}

export const livros: iLivros[] = [
  { sigla: 'GN', livro: 'Gênesis' },
  { sigla: 'EX', livro: 'Êxodo' },
  { sigla: 'LV', livro: 'Levítico' },
  { sigla: 'NM', livro: 'Números' },
  { sigla: 'DT', livro: 'Deuteronômio' },
  { sigla: 'JS', livro: 'Josué' },
  { sigla: 'JZ', livro: 'Juízes' },
  { sigla: 'RT', livro: 'Rute' },
  { sigla: '1SM', livro: '1 Samuel' },
  { sigla: '2SM', livro: '2 Samuel' },
  { sigla: '1RS', livro: '1 Reis' },
  { sigla: '2RS', livro: '2 Reis' },
  { sigla: '1CR', livro: '1Crônicas' },
  { sigla: '2CR', livro: '2Crônicas' },
  { sigla: 'ED', livro: 'Esdras' },
  { sigla: 'NE', livro: 'Neemias' },
  { sigla: 'ET', livro: 'Ester' },
  { sigla: 'JOB', livro: 'Jó' },
  { sigla: 'SL', livro: 'Salmos' },
  { sigla: 'PV', livro: 'Provérbios' },
  { sigla: 'EC', livro: 'Eclesiastes' },
  { sigla: 'CT', livro: 'Cânticos' },
  { sigla: 'IS', livro: 'Isaías' },
  { sigla: 'JR', livro: 'Jeremias' },
  { sigla: 'LM', livro: 'Lamentações' },
  { sigla: 'EZ', livro: 'Ezequiel' },
  { sigla: 'DN', livro: 'Daniel' },
  { sigla: 'OS', livro: 'Oséias' },
  { sigla: 'JL', livro: 'Joel' },
  { sigla: 'AM', livro: 'Amós' },
  { sigla: 'OB', livro: 'Obadias' },
  { sigla: 'JN', livro: 'Jonas' },
  { sigla: 'MQ', livro: 'Miquéias' },
  { sigla: 'NA', livro: 'Naum' },
  { sigla: 'HC', livro: 'Habacuque' },
  { sigla: 'SF', livro: 'Sofonias' },
  { sigla: 'AG', livro: 'Ageu' },
  { sigla: 'ZC', livro: 'Zacarias' },
  { sigla: 'ML', livro: 'Malaquias' },
  { sigla: 'MT', livro: 'Mateus' },
  { sigla: 'MC', livro: 'Marcos' },
  { sigla: 'LC', livro: 'Lucas' },
  { sigla: 'JO', livro: 'João' },
  { sigla: 'AT', livro: 'Atos' },
  { sigla: 'RM', livro: 'Romanos' },
  { sigla: '1CO', livro: '1 Coríntios' },
  { sigla: '2CO', livro: '2 Coríntios' },
  { sigla: 'GL', livro: 'Gálatas' },
  { sigla: 'EF', livro: 'Efésios' },
  { sigla: 'FP', livro: 'Filipenses' },
  { sigla: 'CL', livro: 'Colossenses' },
  { sigla: '1TS', livro: '1 Tessalonicenses' },
  { sigla: '2TS', livro: '2 Tessalonicenses' },
  { sigla: '1TM', livro: '1 Timóteo' },
  { sigla: '2TM', livro: '2 Timóteo' },
  { sigla: 'TI', livro: 'Tito' },
  { sigla: 'FM', livro: 'Filemom' },
  { sigla: 'HB', livro: 'Hebreus' },
  { sigla: 'TG', livro: 'Tiago' },
  { sigla: '1PE', livro: '1 Pedro' },
  { sigla: '2PE', livro: '2 Pedro' },
  { sigla: '1JO', livro: '1 João' },
  { sigla: '2JO', livro: '2 João' },
  { sigla: '3JO', livro: '3 João' },
  { sigla: 'JD', livro: 'Judas' },
  { sigla: 'AP', livro: 'Apocalipse' },
]

// export const BOOKS: string[] = [
//   'GENESIS',
//   'EXODO',
//   'LEVITICO',
//   'NUMEROS',
//   'DEUTERONOMIO',
//   'JOSUE',
//   'JUIZES',
//   'RUTE',
//   '1SAMUEL',
//   '2SAMUEL',
//   '1REIS',
//   '2REIS',
//   '1CRONICAS',
//   '2CRONICAS',
//   'ESDRAS',
//   'NEEMIAS',
//   'ESTER',
//   'JOB',
//   'SALMOS',
//   'PROVERBIOS',
//   'ECLESIASTES',
//   'CANTARES',
//   'ISAIAH',
//   'JEREMIAS',
//   'LAMENTACOES',
//   'EZEQUIEL',
//   'DANIEL',
//   'OSEIAS',
//   'JOEL',
//   'AMOS',
//   'OBADIAS',
//   'JONAS',
//   'MIQUEIAS',
//   'NAUM',
//   'HABACUQUE',
//   'SOFONIAS',
//   'AGEU',
//   'ZACARIAS',
//   'MALQUIAS',
//   'MATEUS',
//   'MARCOS',
//   'LUCAS',
//   'JOAO',
//   'ATOS',
//   'ROMANOS',
//   '1CORINTIOS',
//   '2CORINTIOS',
//   'GALATAS',
//   'EFESIOS',
//   'FILIPENSES',
//   'COLOSSENSES',
//   '1TESALONICENSES',
//   '2TESALONICENSES',
//   '1TIMOTEO',
//   '2TIMOTEO',
//   'TITO',
//   'FILEMON',
//   'HEBREUS',
//   'TIAGO',
//   '1PEDRO',
//   '2PEDRO',
//   '1JOAO',
//   '2JOAO',
//   '3JOAO',
//   'JUDAS',
//   'APOCALIPSE',
// ]
