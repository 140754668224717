import { ChevronDownIcon } from '@heroicons/react/outline'
import { useState } from 'react'

import { Cell } from '../components/grid/Cell'
import logo from './../img/logoFundoCinza.svg'

function Help() {
  const [isOldTestamentBooksOpen, setIsOldTestamentBooksOpen] =
    useState<boolean>(false)
  const [isNewTestamentBooksOpen, setIsNewTestamentBooksOpen] =
    useState<boolean>(false)

  const handleDropdowns = (testament: number) => {
    if (testament === 1) {
      setIsOldTestamentBooksOpen(!isOldTestamentBooksOpen)
      if (!isOldTestamentBooksOpen && isNewTestamentBooksOpen) {
        setIsNewTestamentBooksOpen(false)
      }
    }
    if (testament === 2) {
      setIsNewTestamentBooksOpen(!isNewTestamentBooksOpen)
      if (!isNewTestamentBooksOpen && isOldTestamentBooksOpen) {
        setIsOldTestamentBooksOpen(false)
      }
    }
  }

  const velhoTestamento = [
    { sigla: 'GN', livro: 'Gênesis' },
    { sigla: 'EX', livro: 'Êxodo' },
    { sigla: 'LV', livro: 'Levítico' },
    { sigla: 'NM', livro: 'Números' },
    { sigla: 'DT', livro: 'Deuteronômio' },
    { sigla: 'JS', livro: 'Josué' },
    { sigla: 'JZ', livro: 'Juízes' },
    { sigla: 'RT', livro: 'Rute' },
    { sigla: '1S', livro: 'Primeiro Samuel' },
    { sigla: '2S', livro: 'Segundo Samuel' },
    { sigla: '1R', livro: 'Primeiro Reis' },
    { sigla: '2R', livro: 'Segundo Reis' },
    { sigla: '1C', livro: 'Primeiro Crônicas' },
    { sigla: '2C', livro: 'Segundo Crônicas' },
    { sigla: 'ED', livro: 'Esdras' },
    { sigla: 'NE', livro: 'Neemias' },
    { sigla: 'ET', livro: 'Ester' },
    { sigla: 'JO', livro: 'Jó' },
    { sigla: 'SL', livro: 'Salmos' },
    { sigla: 'PV', livro: 'Provérbios' },
    { sigla: 'EC', livro: 'Eclesiastes' },
    { sigla: 'CT', livro: 'Cânticos dos Cânticos' },
    { sigla: 'IS', livro: 'Isaías' },
    { sigla: 'JR', livro: 'Jeremias' },
    { sigla: 'LM', livro: 'Lamentações' },
    { sigla: 'EZ', livro: 'Ezequiel' },
    { sigla: 'DN', livro: 'Daniel' },
    { sigla: 'OS', livro: 'Oséias' },
    { sigla: 'JL', livro: 'Joel' },
    { sigla: 'AM', livro: 'Amós' },
    { sigla: 'OB', livro: 'Obadias' },
    { sigla: 'JN', livro: 'Jonas' },
    { sigla: 'MQ', livro: 'Miquéias' },
    { sigla: 'NA', livro: 'Naum' },
    { sigla: 'HC', livro: 'Habacuque' },
    { sigla: 'SF', livro: 'Sofonias' },
    { sigla: 'AG', livro: 'Ageu' },
    { sigla: 'ZC', livro: 'Zacarias' },
    { sigla: 'ML', livro: 'Malaquias' },
  ]

  // const oldTestamentBooks = [
  //   'GN - Gênesis',
  //   'EX - Êxodo',
  //   'LV - Levítico',
  //   'NM - Números',
  //   'DT - Deuteronômio',
  //   'JS - Josué',
  //   'JZ - Juízes',
  //   'RT - Rute',
  //   '1S - Primeiro Samuel',
  //   '2S - Segundo Samuel',
  //   '1R - Primeiro Reis',
  //   '2R - Segundo Reis',
  //   '1C - Primeiro Crônicas',
  //   '2C - Segundo Crônicas',
  //   'ED - Esdras',
  //   'NE - Neemias',
  //   'ET - Ester',
  //   'JO - Jó',
  //   'SL - Salmos',
  //   'PV - Provérbios',
  //   'EC - Eclesiastes',
  //   'CT - Cânticos dos Cânticos',
  //   'IS - Isaías',
  //   'JR - Jeremias',
  //   'LM - Lamentações',
  //   'EZ - Ezequiel',
  //   'DN - Daniel',
  //   'OS - Oséias',
  //   'JL - Joel',
  //   'AM - Amós',
  //   'OB - Obadias',
  //   'JN - Jonas',
  //   'MQ - Miquéias',
  //   'NA - Naum',
  //   'HC - Habacuque',
  //   'SF - Sofonias',
  //   'AG - Ageu',
  //   'ZC - Zacarias',
  //   'ML - Malaquias',
  // ]

  const novoTestamento = [
    { sigla: 'MT', livro: 'Mateus' },
    { sigla: 'MC', livro: 'Marcos' },
    { sigla: 'LC', livro: 'Lucas' },
    { sigla: 'JO', livro: 'João' },
    { sigla: 'AT', livro: 'Atos' },
    { sigla: 'RM', livro: 'Romanos' },
    { sigla: '1C', livro: '1Coríntios' },
    { sigla: '2C', livro: '2Coríntios' },
    { sigla: 'GL', livro: 'Gálatas' },
    { sigla: 'EF', livro: 'Efésios' },
    { sigla: 'FP', livro: 'Filipenses' },
    { sigla: 'CL', livro: 'Colossenses' },
    { sigla: '1T', livro: '1Tessalonicenses' },
    { sigla: '2T', livro: '2Tessalonicenses' },
    { sigla: '1T', livro: '1Timóteo' },
    { sigla: '2T', livro: '2Timóteo' },
    { sigla: 'TI', livro: 'Tito' },
    { sigla: 'FM', livro: 'Filemom' },
    { sigla: 'HB', livro: 'Hebreus' },
    { sigla: 'TG', livro: 'Tiago' },
    { sigla: '1P', livro: '1Pedro' },
    { sigla: '2P', livro: '2Pedro' },
    { sigla: '1J', livro: '1João' },
    { sigla: '2J', livro: '2João' },
    { sigla: '3J', livro: '3João' },
    { sigla: 'JD', livro: 'Judas' },
    { sigla: 'AP', livro: 'Apocalipse' },
  ]

  // const newTestamentBooks = [
  //   'MT - Mateus',
  //   'MC - Marcos',
  //   'LC - Lucas',
  //   'JO - João',
  //   'AT - Atos',
  //   'RM - Romanos',
  //   '1C - 1Coríntios',
  //   '2C - 2Coríntios',
  //   'GL - Gálatas',
  //   'EF - Efésios',
  //   'FP - Filipenses',
  //   'CL - Colossenses',
  //   '1T - 1Tessalonicenses',
  //   '2T - 2Tessalonicenses',
  //   '1T - 1Timóteo',
  //   '2T - 2Timóteo',
  //   'TI - Tito',
  //   'FM - Filemom',
  //   'HB - Hebreus',
  //   'TG - Tiago',
  //   '1P - 1Pedro',
  //   '2P - 2Pedro',
  //   '1J - 1João',
  //   '2J - 2João',
  //   '3J - 3João',
  //   'JD - Judas',
  //   'AP - Apocalipse',
  // ]

  const oldTestamentBookList = velhoTestamento.map((book) => (
    <li className="dark:text-white" key={book.sigla}>
      <strong>{book.sigla}</strong> - {book.livro}
    </li>
  ))

  const newTestamentBookList = novoTestamento.map((book) => (
    <li className="dark:text-white" key={book.sigla}>
      <strong>{book.sigla}</strong> - {book.livro}
    </li>
  ))

  // const oldTestamentBookList = oldTestamentBooks.map((book) => (
  //   <li className="dark:text-white" key={book}>
  //     {book}
  //   </li>
  // ))
  // const newTestamentBookList = newTestamentBooks.map((book) => (
  //   <li className="dark:text-white" key={book}>
  //     {book}
  //   </li>
  // ))

  return (
    <div className="grid w-full grid-cols-12 gap-4">
      <div className="col-span-10 col-start-2 mt-2 bg-gray-100 text-center dark:bg-slate-800">
        <img src={logo} alt="Bibliando Logo" className="mx-auto my-12 w-12" />
        <h1 className="mb-8 text-2xl font-bold dark:text-white md:text-3xl">
          Ajuda Adicional
        </h1>
        <p className="mx-auto my-8 w-4/5 text-base dark:text-white md:text-xl">
          O Bibliando usa uma sequência de dicas codificadas por cores para
          ajudar nos palpites subsequentes.
        </p>
        <table className="mx-auto mb-8 w-4/5 max-w-xl table-auto border-spacing-0 text-left dark:text-white">
          <thead className="font-bold">
            <td className="border border-slate-600 p-4">Cor</td>
            <td className="border border-slate-600 p-4">Significado</td>
          </thead>
          <tr className="border border-slate-600">
            <td className="flex justify-center border-none border-slate-600 p-4">
              <Cell
                isRevealing={true}
                isCompleted={true}
                value="J"
                status="correct"
                position={0}
                solutionLength={8}
              />
            </td>
            <td className="border border-slate-600 p-4">
              <p>Letra correta, local correto</p>
              <p className="text-sm text-gray-500 dark:text-gray-300">
                A letra J está na solução e no local correto.
              </p>
            </td>
          </tr>
          <tr className="border border-slate-600 ">
            <td className="flex justify-center border-none border-slate-600 p-4">
              <Cell
                isRevealing={true}
                isCompleted={true}
                value="M"
                status="present"
                position={1}
                solutionLength={8}
              />
            </td>
            <td className="border border-slate-600 p-4">
              <p>Letra correta, local errado</p>
              <p className="text-sm text-gray-500 dark:text-gray-300">
                A letra M está na solução, mas no lugar errado.
              </p>
            </td>
          </tr>

          <tr className="border border-slate-600 ">
            <td className="flex justify-center border-none border-slate-600 p-4">
              <Cell
                isRevealing={true}
                isCompleted={true}
                value="K"
                status="absent"
                position={2}
                solutionLength={8}
              />
            </td>
            <td className="border border-slate-600 p-4">
              <p>Letra incorreta</p>
              <p className="text-sm text-gray-500 dark:text-gray-300">
                A letra K não está na solução.
              </p>
            </td>
          </tr>

          <tr className="border border-slate-600 ">
            <td className="flex justify-center border-none border-slate-600 p-4">
              <Cell
                isRevealing={true}
                isCompleted={true}
                value="2"
                status="incorrectCharType"
                position={3}
                solutionLength={8}
              />
            </td>
            <td className="border border-slate-600 p-4">
              <p>Tipo de caractere incorreto</p>
              <p className="text-sm text-gray-500 dark:text-gray-300">
                O número 2 é um tipo de caractere incorreto; Experimente dois
                pontos ou uma letra.
              </p>
            </td>
          </tr>

          <tr className="border border-slate-600 p-4">
            <td className="flex justify-center border-none border-slate-600 p-4">
              <Cell
                isRevealing={true}
                isCompleted={true}
                value="P"
                status="incorrectCharType"
                position={3}
                solutionLength={8}
              />
            </td>
            <td className="border border-slate-600 p-4">
              <p>Tipo de caractere incorreto</p>
              <p className="text-sm text-gray-500 dark:text-gray-300">
                A letra P é um tipo de caractere incorreto; Experimente dois
                pontos ou um número.
              </p>
            </td>
          </tr>

          <tr className="border border-slate-600 ">
            <td className="flex justify-center border-none border-slate-600 p-4">
              <Cell
                isRevealing={true}
                isCompleted={true}
                value="1"
                status="low"
                position={4}
                solutionLength={8}
              />
            </td>
            <td className="border border-slate-600 p-4">
              <p>Número muito baixo</p>
              <p className="text-sm text-gray-500 dark:text-gray-300">
                O número 1 é menor que o número da solução.
              </p>
            </td>
          </tr>

          <tr className="border border-slate-600 ">
            <td className="flex justify-center border-none border-slate-600 p-4">
              <Cell
                isRevealing={true}
                isCompleted={true}
                value="9"
                status="high"
                position={5}
                solutionLength={8}
              />
            </td>
            <td className="border border-slate-600 p-4">
              <p>Número muito alto</p>
              <p className="text-sm text-gray-500 dark:text-gray-300">
                O número 9 é maior que o número da solução.
              </p>
            </td>
          </tr>
        </table>
      </div>
      <div className="col-span-10 col-start-2 mb-16 mt-2 overflow-hidden bg-gray-100 text-left dark:bg-slate-800">
        <h1 className="my-8 text-center text-2xl font-bold dark:text-white md:text-3xl">
          Sintaxe de estimativa válida
        </h1>
        <p className="mx-auto my-12 w-4/5 text-base dark:text-white md:text-xl">
          Os palpites no Bibliando devem ser versículos bíblicos válidos sem
          espaços. Um versículo bíblico válido inclui um{' '}
          <span className="text-sky-600 dark:text-indigo-400">livro</span>, dois{' '}
          <span className="text-sky-600 dark:text-indigo-400">
            números do capítulo
          </span>{' '}
          {', '} e por último, dois{' '}
          <span className="text-sky-600 dark:text-indigo-400">
            números do versículo
          </span>
          .
        </p>
        <p className="mx-auto my-12 w-4/5 text-base dark:text-white md:text-xl">
          Exemplos: "
          <span className="text-sky-600 dark:text-indigo-400">GN 01:01</span>" ,
          "<span className="text-sky-600 dark:text-indigo-400">2C 06:01</span>"
          , "<span className="text-sky-600 dark:text-indigo-400">OB 02:01</span>
          "
        </p>
        <p className="mx-auto my-12 w-4/5 text-base dark:text-white md:text-xl">
          Para os livros 1 e 2 Samuel, 1 e 2 Reis, 1 e 2 Crônicas, 1 e 2
          Coríntios, 1 e 2 Tessalonicenses, 1 e 2 Timóteo, 1 e 2 Pedro, e 1, 2 e
          3 João, sempre utilize o número seguido da letra, por exemplo,"
          <span className="text-sky-600 dark:text-indigo-400">2S 01:01</span>"
          (não "<s className="text-sky-600 dark:text-indigo-400"> 2SM 01:01 </s>
          " ).
        </p>
        <p className="mx-auto my-12 w-4/5 text-center text-base dark:text-white md:text-xl">
          {' '}
          Use os menus suspensos abaixo para ver uma lista de nomes de livros.
        </p>
        <div className="mx-auto flex flex-col px-4 text-center md:w-2/3">
          <h2
            className={`${
              isOldTestamentBooksOpen
                ? 'text-sky-800 dark:text-indigo-400'
                : 'text-black dark:text-white'
            } mb-8 cursor-pointer text-lg font-bold transition-all delay-100 duration-300 hover:scale-105 md:text-2xl`}
            onClick={() => handleDropdowns(1)}
          >
            Livros do Antigo Testamento{' '}
            <ChevronDownIcon
              className={`${
                isOldTestamentBooksOpen
                  ? 'stroke-indigo-600 dark:stroke-indigo-400'
                  : 'stroke-black dark:stroke-white'
              } inline-block h-5 w-5 stroke-black dark:stroke-white md:h-7 md:w-7`}
            />{' '}
          </h2>
          <div className="text-center">
            {isOldTestamentBooksOpen && (
              <ul className="mb-8">{oldTestamentBookList}</ul>
            )}
          </div>
          <h2
            className={`${
              isNewTestamentBooksOpen
                ? 'text-sky-800 dark:text-indigo-400'
                : 'text-black dark:text-white'
            } mb-8 cursor-pointer text-lg font-bold transition-all delay-100 duration-300 hover:scale-105 md:text-2xl`}
            onClick={() => handleDropdowns(2)}
          >
            Livros do Novo Testamento{' '}
            <ChevronDownIcon
              className={`${
                isNewTestamentBooksOpen
                  ? 'stroke-indigo-600 dark:stroke-indigo-400'
                  : 'stroke-black dark:stroke-white'
              } inline-block h-5 w-5 stroke-black dark:stroke-white md:h-7 md:w-7`}
            />{' '}
          </h2>
          <div className="text-center">
            {isNewTestamentBooksOpen && (
              <ul className="mb-8">{newTestamentBookList}</ul>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Help
