import { BaseModal } from '../BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const PointsHelpModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal
      title="Pontos explicados"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <div className="transform overflow-hidden transition-all">
        <p className="m-4 text-left text-lg text-black dark:text-white">
          {'Os pontos são mais afetados por '}
          <span className="font-bold text-sky-800 dark:text-indigo-400">
            Jogos ganhos
          </span>
          {', '}
          <span className="font-bold text-sky-800 dark:text-indigo-400">
            Número médio de palpites
          </span>
          {' e '}
          <span className="font-bold text-sky-800 dark:text-indigo-400">
            Taxa de sucesso
          </span>
          {
            '. Continue a melhorar suas estatísticas para ver seu total de pontos aumentar!'
          }
        </p>
        <button
          aria-label="close"
          onClick={() => handleClose()}
          tabIndex={0}
          aria-pressed="false"
          className="absolute right-4 top-4"
        ></button>
      </div>
    </BaseModal>
  )
}
