import { Link } from 'react-router-dom'

import BibliandoLogo from '../components/logo/BibliandoLogo'
// import BellTowerLogo from './../components/logo/BellTowerLogo'
import { buttonClasses } from './../constants/classes'
// import gitLogoWhite from './../img/github-mark-white.png'
// import gitLogo from './../img/github-mark.png'
import logo from './../img/logoFundoCinza.svg'

function About() {
  return (
    <div className="grid w-full grid-cols-12 gap-4">
      <div className="col-span-10 col-start-2 mt-2 bg-gray-100 text-center dark:bg-slate-800">
        <img src={logo} alt="Bibliando Logo" className="mx-auto my-12 w-12" />
        <h1 className="text-l font-bold dark:text-white sm:text-xl md:text-3xl">
          Sobre
        </h1>
        <BibliandoLogo className="mx-auto mb-16 h-auto w-64 fill-blue-900 dark:fill-white sm:w-80 md:w-96" />
        <p className="mx-auto -mt-14 w-4/5 text-center text-base dark:text-white md:text-xl">
          {/* Bibliando é um jogo diário que testa o conhecimento dos usuários sobre
          Referências bíblicas. */}
          Explore o universo das Escrituras com um desafio diário: Bibliando é
          um jogo de Wordle que testa seu conhecimento sobre referências
          bíblicas. Todos os dias, você receberá uma nova referência para
          desvendar em um número limitado de tentativas, colocando seu
          conhecimento e sua habilidade de dedução à prova.
        </p>
        <p className="mx-auto mt-4 w-4/5 text-center text-base dark:text-white md:text-xl">
          Desafie-se a cada novo dia, compartilhe seu progresso com amigos e
          veja quem realmente conhece os versículos de cor. Pronto para testar
          sua memória e fortalecer seu entendimento bíblico? Venha jogar e
          descubra o quanto você sabe sobre a Bíblia!
        </p>
        <p className="mx-auto mt-4 w-4/5 text-center text-base dark:text-white md:text-xl">
          Bibliando não é afiliado de forma alguma ao "Wordle" do NYTimes. É um
          projeto que foi derivado de um incrível modelo de código aberto do
          Scripturle.
        </p>

        <div className="m-auto my-8 flex w-64 flex-col">
          <Link to="/" className={buttonClasses}>
            Jogue o jogo
          </Link>
          <Link to="/ajuda" className={`my-8 ${buttonClasses}`}>
            Como jogar
          </Link>
          <Link to="/historico-atualizacao" className={buttonClasses}>
            Histórico de atualizações
          </Link>
        </div>
      </div>

      <div className="col-span-10 col-start-2 mt-2 bg-gray-100 dark:bg-slate-800">
        <h1 className="text-l mt-8 text-center font-bold dark:text-white sm:text-xl md:text-3xl">
          Termo de Privacidade do Bibliando
        </h1>
        <p className="mx-auto mt-4 w-4/5 text-base dark:text-white md:text-xl">
          1. Sua privacidade é importante para nós, e nos comprometemos a
          protegê-la. Este Termo de Privacidade descreve como coletamos, usamos
          e armazenamos suas informações pessoais ao utilizar nosso jogo.
        </p>
        <p className="mx-auto mt-4 w-4/5 text-base dark:text-white md:text-xl">
          2. Coleta de Dados: Ao se cadastrar no Bibliando, coletamos as
          seguintes informações pessoais: Endereço de e-mail, fornecido
          diretamente por você ou via autenticação com Google. Dados sobre o seu
          progresso e desempenho no jogo, armazenados para melhorar sua
          experiência de uso.
        </p>
        <p className="mx-auto mt-4 w-4/5 text-base dark:text-white md:text-xl">
          3. Uso dos Dados: Utilizamos seus dados para: Fornecer acesso à
          plataforma e às funcionalidades do jogo. Permitir a personalização da
          experiência do usuário. Acompanhar o progresso individual e registrar
          seu histórico de respostas no jogo.
        </p>
        <p className="mx-auto mt-4 w-4/5 text-base dark:text-white md:text-xl">
          4. Armazenamento de Dados Localmente: Alguns dados do usuário são
          armazenados localmente em seu navegador para possibilitar um
          carregamento rápido e personalizar sua experiência a cada acesso.
          Firebase Cloud: Usamos o Firebase para gerenciar e armazenar
          informações de cadastro e progresso do usuário de forma segura.
        </p>
        <p className="mx-auto mt-4 w-4/5 text-base dark:text-white md:text-xl">
          5. Compartilhamento de Dados: Não compartilhamos, vendemos ou
          distribuímos suas informações pessoais para terceiros, exceto quando
          exigido por lei.
        </p>
        <p className="mx-auto mt-4 w-4/5 text-base dark:text-white md:text-xl">
          6. Segurança dos Dados: Implementamos medidas técnicas e
          organizacionais para proteger seus dados contra acesso não autorizado,
          perda ou alteração.
        </p>
        <p className="mx-auto mt-4 w-4/5 text-base dark:text-white md:text-xl">
          7. Direitos dos Usuários: Você tem o direito de: Acessar e revisar
          suas informações pessoais. Solicitar a exclusão dos seus dados da
          plataforma, mediante pedido por nossos canais de suporte.
        </p>
        <p className="mx-auto mt-4 w-4/5 text-base dark:text-white md:text-xl">
          8. Alterações neste Termo: Este Termo de Privacidade poderá ser
          atualizado periodicamente para refletir mudanças na nossa política de
          proteção de dados ou nas funcionalidades do jogo. Recomendamos revisar
          esta página regularmente.
        </p>
        <p className="mx-auto mt-4 w-4/5 text-base dark:text-white md:text-xl">
          9. Contato: Caso tenha dúvidas sobre este Termo de Privacidade ou
          deseje exercer algum de seus direitos, entre em contato conosco pelos
          canais de suporte disponíveis em nossa plataforma.
        </p>
        <p className="mx-auto mt-4 w-4/5 text-base dark:text-white md:text-xl">
          Este Termo de Privacidade garante a transparência e o comprometimento
          do Bibliando com a sua privacidade.
        </p>
        <p className="mx-auto mt-4 w-4/5 text-base dark:text-white md:text-xl">
          Utilizamos o Google Analytics para entender como os usuários interagem
          com o jogo.
        </p>
        <div className="m-auto my-8 flex w-64 flex-col">
          <Link
            to="https://policies.google.com/privacy"
            className={buttonClasses}
          >
            Política de Privacidade do Google
          </Link>
        </div>
      </div>

      {/* <div className="col-span-10 col-start-2 mb-0 bg-gray-100 dark:bg-slate-800 lg:col-span-5 lg:col-start-2 lg:col-end-7 lg:mb-8">
        <BellTowerLogo className="mx-auto my-8 h-auto w-24 fill-black dark:fill-white md:w-32" />
        <h2 className="text-center text-2xl font-bold text-black dark:text-white">
          Termo de Privacidade
        </h2>
        <p className="p-8 text-center text-black dark:text-white">
          Termo de Privacidade
        </p>
        <div className="my-4 flex flex-col text-center">
          <a
            href="https://fhu.edu"
            target="_blank"
            rel="noreferrer noopener"
            className={`mx-auto my-2 w-64 ${buttonClasses}`}
          >
            Freed-Hardeman <br /> University
          </a>
          <a
            href="https://fhu.edu/cs"
            target="_blank"
            rel="noreferrer noopener"
            className={`mx-auto my-2 w-64 ${buttonClasses}`}
          >
            FHU Computer <br /> Science
          </a>
        </div>
      </div>
      <div className="col-span-10 col-start-2 mb-8 bg-gray-100 dark:bg-slate-800 lg:col-span-5 lg:col-end-12">
        <img
          src={gitLogo}
          alt="GitHub"
          className="mx-auto mb-10 mt-20 block h-auto w-24 dark:hidden md:w-32 lg:mb-16"
        />
        <img
          src={gitLogoWhite}
          alt="GitHub"
          className="mx-auto mb-10 mt-20 hidden h-auto w-24 dark:block md:w-32 lg:mb-16"
        />
        <h2 className="text-center text-2xl font-bold text-black dark:text-white">
          About the Code
        </h2>
        <p className="p-8 text-center text-black dark:text-white">
          Scripturle is an open-source project that was forked from an amazing
          open-source template that no longer exists. Check out the Scripturle
          code on Github below.
        </p>
        <div className="my-4 flex flex-col text-center">
          <a
            href="https://github.com/FHU/thywordle"
            target="_blank"
            rel="noreferrer noopener"
            className={`mx-auto my-2 w-64 ${buttonClasses}`}
          >
            Scripturle <br /> Repo
          </a>
        </div>
      </div> */}
    </div>
  )
}

export default About
