import React from 'react'

export default function BibliandoLogo(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 170 60"
      // viewBox="0 0 200 60"
      xmlSpace="preserve"
      // width={1574}
      // height={428}
      {...props}
    >
      <g>
        <title>Layer 1</title>
        <text x="45" y="28" font-family="Arial, sans-serif" font-size="16">
          BIBLIANDO
        </text>
      </g>
    </svg>
    // <svg
    //   version="1.1"
    //   xmlns="http://www.w3.org/2000/svg"
    //   xmlnsXlink="http://www.w3.org/1999/xlink"
    //   x="0px"
    //   y="0px"
    //   viewBox="0 0 1574 428"
    //   xmlSpace="preserve"
    //   width={1574}
    //   height={428}
    //   {...props}
    // >
    //   <g>
    //     <title>Layer 1</title>
    //     <path d="m500.97,184.84c-1.55,19 -8.34,34.6 -25.26,44.96c12.44,19.45 24.81,38.8 37.61,58.82c-12.38,0 -23.82,0.15 -35.25,-0.16c-1.65,-0.04 -3.71,-1.93 -4.78,-3.5c-9.87,-14.6 -19.64,-29.27 -29.23,-44.05c-1.86,-2.87 -3.87,-4.01 -7.26,-3.82c-5.12,0.28 -10.26,0.07 -15.84,0.07c0,17.11 0,33.86 0,51.47c-7.53,0 -14.64,0 -21.74,0c-10.85,0 -10.85,0 -10.85,-11.03c0,-48.33 0,-96.65 0,-144.98c0,-1.79 0,-3.59 0,-5.57c1.2,-0.16 1.99,-0.36 2.79,-0.35c17.99,0.06 36,-0.14 53.98,0.37c6.4,0.18 12.95,1.45 19.09,3.33c24.68,7.56 37.01,25.95 36.74,54.44m-39.57,14.01c0.61,-0.78 1.24,-1.55 1.81,-2.36c9.95,-14.09 2.6,-35.78 -13.5,-37.97c-9.41,-1.28 -19.12,-0.24 -28.6,-0.24c0,16.02 0,31.72 0,47.62c8.72,0 17.26,0.75 25.58,-0.28c4.92,-0.62 9.49,-4.06 14.71,-6.77z" />
    //     <path d="m1046.18,220c0,-31.27 0,-62.04 0,-93.32c4.08,0 7.72,-0.06 11.35,0.01c17.64,0.33 35.42,-0.62 52.87,1.36c26.1,2.97 41.75,17.11 46.95,39.25c4.51,19.22 0.16,47.91 -23.74,62.58c12.36,19.33 24.73,38.67 37.56,58.74c-12.41,0 -23.97,0.12 -35.53,-0.15c-1.51,-0.04 -3.39,-1.86 -4.39,-3.34c-9.91,-14.71 -19.73,-29.49 -29.42,-44.35c-1.74,-2.65 -3.55,-3.9 -6.85,-3.7c-5.27,0.31 -10.57,0.08 -16.33,0.08c0,12.75 0.04,25.04 -0.02,37.32c-0.03,4.46 1.41,10.82 -0.89,12.88c-2.68,2.39 -8.73,1.13 -13.32,1.23c-5.94,0.13 -11.88,0.03 -18.24,0.03c0,-23.22 0,-45.67 0,-68.62m32.47,-42.5c0,9.41 0,18.81 0,28.43c8.88,0 17.19,0.33 25.47,-0.08c12.1,-0.6 20.55,-9.74 21.23,-22.29c0.72,-13.09 -6.2,-23.75 -17.76,-25.05c-9.48,-1.06 -19.18,-0.21 -28.94,-0.21c0,6.23 0,12.22 0,19.2z" />
    //     <path d="m1447.72,257.26c1.42,0.94 3.16,1.85 3.19,2.81c0.2,9.31 0.12,18.63 0.12,28.26c-1.5,0.11 -2.78,0.28 -4.06,0.28c-34.99,0.02 -69.97,-0.03 -104.95,0.09c-3.66,0.01 -4.84,-0.92 -4.83,-4.73c0.12,-51.14 0.08,-102.29 0.09,-153.43c0,-1.12 0.15,-2.24 0.25,-3.6c36.82,0 73.41,0 110.24,0c0,10.15 0,20.26 0,30.86c-25.72,0 -51.59,0 -77.74,0c0,11.53 0,22.57 0,33.97c22.98,0 45.73,0 68.73,0c0,10.45 0,20.54 0,31.12c-22.82,0 -45.7,0 -68.79,0c0,11.58 0,22.66 0,34.37c25.78,0 51.53,0 77.75,0z" />
    //     <path d="m1003.89,252.76c-20.39,45.85 -87.74,49.7 -115.59,11.6c-7.43,-10.16 -11.12,-21.57 -11.18,-34.04c-0.16,-33.82 -0.1,-67.65 -0.11,-101.48c0,-0.63 0.18,-1.26 0.29,-1.98c10.62,0 21.06,0 32.12,0c0,1.92 0,3.68 0,5.44c0,31.16 -0.01,62.33 0.01,93.49c0.01,16.1 10.67,29.16 26.34,32.36c16.19,3.3 32.7,-4.86 38.57,-19.58c1.55,-3.89 2.08,-8.42 2.1,-12.65c0.18,-31 0.1,-62 0.1,-92.99c0,-1.82 0,-3.64 0,-5.84c10.64,0 20.99,0 31.78,0c0,1.18 0,2.3 0,3.42c0,32.99 0.26,65.99 -0.2,98.98c-0.11,7.65 -2.68,15.27 -4.23,23.27z" />
    //     <path d="m612.31,238c0,-37.26 0,-74.03 0,-110.88c1.08,-0.2 1.71,-0.42 2.35,-0.41c18.48,0.07 36.98,-0.22 55.45,0.46c7.01,0.25 14.26,1.9 20.86,4.38c19.57,7.34 30.41,21.93 32.58,42.69c2.29,22.01 -5.69,48.48 -29.52,57.95c-8.28,3.29 -17.69,4.09 -26.69,5.07c-7.23,0.8 -14.62,0.17 -22.55,0.17c0,2.84 0,5.45 0,8.06c0,12.99 -0.06,25.99 0.05,38.98c0.03,2.84 -0.54,4.28 -3.8,4.21c-8.33,-0.17 -16.66,-0.18 -24.99,0.01c-3.3,0.07 -3.8,-1.41 -3.78,-4.22c0.09,-15.32 0.04,-30.65 0.04,-46.47m54.98,-32c1.33,-0.06 2.67,-0.01 3.99,-0.18c11.56,-1.48 19.6,-10.59 20.3,-22.95c0.66,-11.9 -6.2,-22.73 -17.05,-24.08c-9.74,-1.22 -19.75,-0.26 -29.42,-0.26c0,15.84 0,31.55 0,47.47c7.3,0 14.26,0 22.18,0z" />
    //     <path d="m194.36,134.61c4.1,2.24 7.88,4.28 12.22,6.64c-4.17,8.45 -8.16,16.55 -11.88,24.1c-9.53,-3.36 -18.5,-7.43 -27.92,-9.54c-6.24,-1.4 -13.2,-0.61 -19.67,0.34c-5.5,0.81 -9.65,4.53 -10.99,10.32c-1.43,6.18 -0.25,12.11 5.23,15.56c7.54,4.74 15.6,8.69 23.58,12.71c5.03,2.54 10.46,4.28 15.46,6.89c18.55,9.68 26.15,25.64 24.3,45.86c-1.86,20.2 -13.65,33.55 -32.63,39.36c-25.29,7.73 -49.35,2.68 -72.07,-10.01c-1.74,-0.97 -3.4,-2.1 -5.07,-3.18c-0.27,-0.17 -0.44,-0.49 -1.01,-1.15c4.6,-8.12 9.3,-16.39 14.11,-24.85c10.62,7.28 21.69,12.35 34.17,13.82c7.24,0.85 14.32,-0.03 20.79,-3.51c9.52,-5.12 11.54,-17.06 3.54,-24.28c-4.82,-4.36 -10.9,-7.46 -16.73,-10.53c-9.52,-5.02 -19.78,-8.79 -28.9,-14.43c-27.77,-17.22 -23.41,-60.14 2.99,-75.01c13.59,-7.66 28.11,-9.44 42.99,-7.08c9.24,1.47 18.12,5.11 27.49,7.97z" />
    //     <path d="m304.07,290.14c-36.49,-1.5 -64.39,-22.24 -74.58,-55.09c-10.81,-34.86 0.7,-75.17 27.11,-94.96c26.49,-19.85 68.05,-20.44 97.23,-1.07c-4.11,9.9 -8.26,19.9 -12.61,30.36c-13.7,-9.9 -28.09,-16.07 -44.98,-12.27c-10.81,2.44 -19.45,8.44 -26.12,17.23c-15.74,20.75 -13.99,53.38 3.15,71.44c14.97,15.76 47.89,19.38 70.63,0.44c4.41,9.53 8.78,18.97 13.36,28.86c-16.03,10.86 -33.46,16.05 -53.19,15.06z" />
    //     <path d="m1207.05,126.68c7.47,0 14.45,0 21.82,0c0,43.49 0,86.69 0,130.37c25.4,0 50.43,0 75.8,0c0,10.68 0,20.89 0,31.33c-35.78,0 -71.51,0 -107.53,0c0,-53.86 0,-107.55 0,-161.7c3.16,0 6.29,0 9.91,0z" />
    //     <path d="m778.75,192c0,-11.46 0,-22.42 0,-33.91c-12.68,0 -25.05,0 -37.6,0c0,-10.61 0,-20.7 0,-31.09c36.13,0 72.18,0 108.61,0c0,9.99 0,20.2 0,30.76c-12.55,0 -25.31,0 -38.59,0c0,43.67 0,87.02 0,130.61c-10.95,0 -21.36,0 -32.42,0c0,-32.06 0,-63.97 0,-96.37z" />
    //     <path d="m538.95,164c0.01,-12.33 0.02,-24.15 0.05,-35.98c0,-0.3 0.21,-0.59 0.41,-1.11c10.34,0 20.75,0 31.45,0c0,53.75 0,107.46 0,161.44c-10.53,0 -20.94,0 -31.91,0c0,-41.36 0,-82.6 0,-124.35z" />
    //   </g>
    // </svg>
  )
}
