import BibliaComponent from '../components/biblia/BibliaComponent'

function Biblia() {
  return (
    <div className="w-full font-sans">
      <BibliaComponent />
    </div>
  )
}

export default Biblia
