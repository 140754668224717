export const biblia_acf = [
  {
    abbrev: 'gn',
    capitulos: [
      [
        'No princípio criou Deus o céu e a terra.',
        'E a terra era sem forma e vazia; e havia trevas sobre a face do abismo; e o Espírito de Deus se movia sobre a face das águas.',
        'E disse Deus: Haja luz; e houve luz.',
        'E viu Deus que era boa a luz; e fez Deus separação entre a luz e as trevas.',
        'E Deus chamou à luz Dia; e às trevas chamou Noite. E foi a tarde e a manhã, o dia primeiro.',
        'E disse Deus: Haja uma expansão no meio das águas, e haja separação entre águas e águas.',
        'E fez Deus a expansão, e fez separação entre as águas que estavam debaixo da expansão e as águas que estavam sobre a expansão; e assim foi.',
        'E chamou Deus à expansão Céus, e foi a tarde e a manhã, o dia segundo.',
        'E disse Deus: Ajuntem-se as águas debaixo dos céus num lugar; e apareça a porção seca; e assim foi.',
        'E chamou Deus à porção seca Terra; e ao ajuntamento das águas chamou Mares; e viu Deus que era bom.',
        'E disse Deus: Produza a terra erva verde, erva que dê semente, árvore frutífera que dê fruto segundo a sua espécie, cuja semente está nela sobre a terra; e assim foi.',
        'E a terra produziu erva, erva dando semente conforme a sua espécie, e a árvore frutífera, cuja semente está nela conforme a sua espécie; e viu Deus que era bom.',
        'E foi a tarde e a manhã, o dia terceiro.',
        'E disse Deus: Haja luminares na expansão dos céus, para haver separação entre o dia e a noite; e sejam eles para sinais e para tempos determinados e para dias e anos.',
        'E sejam para luminares na expansão dos céus, para iluminar a terra; e assim foi.',
        'E fez Deus os dois grandes luminares: o luminar maior para governar o dia, e o luminar menor para governar a noite; e fez as estrelas.',
        'E Deus os pôs na expansão dos céus para iluminar a terra,',
        'E para governar o dia e a noite, e para fazer separação entre a luz e as trevas; e viu Deus que era bom.',
        'E foi a tarde e a manhã, o dia quarto.',
        'E disse Deus: Produzam as águas abundantemente répteis de alma vivente; e voem as aves sobre a face da expansão dos céus.',
        'E Deus criou as grandes baleias, e todo o réptil de alma vivente que as águas abundantemente produziram conforme as suas espécies; e toda a ave de asas conforme a sua espécie; e viu Deus que era bom.',
        'E Deus os abençoou, dizendo: Frutificai e multiplicai-vos, e enchei as águas nos mares; e as aves se multipliquem na terra.',
        'E foi a tarde e a manhã, o dia quinto.',
        'E disse Deus: Produza a terra alma vivente conforme a sua espécie; gado, e répteis e feras da terra conforme a sua espécie; e assim foi.',
        'E fez Deus as feras da terra conforme a sua espécie, e o gado conforme a sua espécie, e todo o réptil da terra conforme a sua espécie; e viu Deus que era bom.',
        'E disse Deus: Façamos o homem à nossa imagem, conforme a nossa semelhança; e domine sobre os peixes do mar, e sobre as aves dos céus, e sobre o gado, e sobre toda a terra, e sobre todo o réptil que se move sobre a terra.',
        'E criou Deus o homem à sua imagem; à imagem de Deus o criou; homem e mulher os criou.',
        'E Deus os abençoou, e Deus lhes disse: Frutificai e multiplicai-vos, e enchei a terra, e sujeitai-a; e dominai sobre os peixes do mar e sobre as aves dos céus, e sobre todo o animal que se move sobre a terra.',
        'E disse Deus: Eis que vos tenho dado toda a erva que dê semente, que está sobre a face de toda a terra; e toda a árvore, em que há fruto que dê semente, ser-vos-á para mantimento.',
        'E a todo o animal da terra, e a toda a ave dos céus, e a todo o réptil da terra, em que há alma vivente, toda a erva verde será para mantimento; e assim foi.',
        'E viu Deus tudo quanto tinha feito, e eis que era muito bom; e foi a tarde e a manhã, o dia sexto.',
      ],
      [
        'Assim os céus, a terra e todo o seu exército foram acabados.',
        'E havendo Deus acabado no dia sétimo a obra que fizera, descansou no sétimo dia de toda a sua obra, que tinha feito.',
        'E abençoou Deus o dia sétimo, e o santificou; porque nele descansou de toda a sua obra que Deus criara e fizera.',
        'Estas são as origens dos céus e da terra, quando foram criados; no dia em que o Senhor Deus fez a terra e os céus,',
        'E toda a planta do campo que ainda não estava na terra, e toda a erva do campo que ainda não brotava; porque ainda o Senhor Deus não tinha feito chover sobre a terra, e não havia homem para lavrar a terra.',
        'Um vapor, porém, subia da terra, e regava toda a face da terra.',
        'E formou o Senhor Deus o homem do pó da terra, e soprou em suas narinas o fôlego da vida; e o homem foi feito alma vivente.',
        'E plantou o Senhor Deus um jardim no Éden, do lado oriental; e pôs ali o homem que tinha formado.',
        'E o Senhor Deus fez brotar da terra toda a árvore agradável à vista, e boa para comida; e a árvore da vida no meio do jardim, e a árvore do conhecimento do bem e do mal.',
        'E saía um rio do Éden para regar o jardim; e dali se dividia e se tornava em quatro braços.',
        'O nome do primeiro é Pisom; este é o que rodeia toda a terra de Havilá, onde há ouro.',
        'E o ouro dessa terra é bom; ali há o bdélio, e a pedra sardônica.',
        'E o nome do segundo rio é Giom; este é o que rodeia toda a terra de Cuxe.',
        'E o nome do terceiro rio é Tigre; este é o que vai para o lado oriental da Assíria; e o quarto rio é o Eufrates.',
        'E tomou o Senhor Deus o homem, e o pôs no jardim do Éden para o lavrar e o guardar.',
        'E ordenou o Senhor Deus ao homem, dizendo: De toda a árvore do jardim comerás livremente,',
        'Mas da árvore do conhecimento do bem e do mal, dela não comerás; porque no dia em que dela comeres, certamente morrerás.',
        'E disse o Senhor Deus: Não é bom que o homem esteja só; far-lhe-ei uma ajudadora idônea para ele.',
        'Havendo, pois, o Senhor Deus formado da terra todo o animal do campo, e toda a ave dos céus, os trouxe a Adão, para este ver como lhes chamaria; e tudo o que Adão chamou a toda a alma vivente, isso foi o seu nome.',
        'E Adão pôs os nomes a todo o gado, e às aves dos céus, e a todo o animal do campo; mas para o homem não se achava ajudadora idônea.',
        'Então o Senhor Deus fez cair um sono pesado sobre Adão, e este adormeceu; e tomou uma das suas costelas, e cerrou a carne em seu lugar;',
        'E da costela que o Senhor Deus tomou do homem, formou uma mulher, e trouxe-a a Adão.',
        'E disse Adão: Esta é agora osso dos meus ossos, e carne da minha carne; esta será chamada mulher, porquanto do homem foi tomada.',
        'Portanto deixará o homem o seu pai e a sua mãe, e apegar-se-á à sua mulher, e serão ambos uma carne.',
        'E ambos estavam nus, o homem e a sua mulher; e não se envergonhavam.',
      ],
      [
        'Ora, a serpente era mais astuta que todas as alimárias do campo que o SENHOR Deus tinha feito. E esta disse à mulher: É assim que Deus disse: Não comereis de toda a árvore do jardim?',
        'E disse a mulher à serpente: Do fruto das árvores do jardim comeremos,',
        'Mas do fruto da árvore que está no meio do jardim, disse Deus: Não comereis dele, nem nele tocareis para que não morrais.',
        'Então a serpente disse à mulher: Certamente não morrereis.',
        'Porque Deus sabe que no dia em que dele comerdes se abrirão os vossos olhos, e sereis como Deus, sabendo o bem e o mal.',
        'E viu a mulher que aquela árvore era boa para se comer, e agradável aos olhos, e árvore desejável para dar entendimento; tomou do seu fruto, e comeu, e deu também a seu marido, e ele comeu com ela.',
        'Então foram abertos os olhos de ambos, e conheceram que estavam nus; e coseram folhas de figueira, e fizeram para si aventais.',
        'E ouviram a voz do Senhor Deus, que passeava no jardim pela viração do dia; e esconderam-se Adão e sua mulher da presença do Senhor Deus, entre as árvores do jardim.',
        'E chamou o Senhor Deus a Adão, e disse-lhe: Onde estás?',
        'E ele disse: Ouvi a tua voz soar no jardim, e temi, porque estava nu, e escondi-me.',
        'E Deus disse: Quem te mostrou que estavas nu? Comeste tu da árvore de que te ordenei que não comesses?',
        'Então disse Adão: A mulher que me deste por companheira, ela me deu da árvore, e comi.',
        'E disse o Senhor Deus à mulher: Por que fizeste isto? E disse a mulher: A serpente me enganou, e eu comi.',
        'Então o Senhor Deus disse à serpente: Porquanto fizeste isto, maldita serás mais que toda a fera, e mais que todos os animais do campo; sobre o teu ventre andarás, e pó comerás todos os dias da tua vida.',
        'E porei inimizade entre ti e a mulher, e entre a tua semente e a sua semente; esta te ferirá a cabeça, e tu lhe ferirás o calcanhar.',
        'E à mulher disse: Multiplicarei grandemente a tua dor, e a tua conceição; com dor darás à luz filhos; e o teu desejo será para o teu marido, e ele te dominará.',
        'E a Adão disse: Porquanto deste ouvidos à voz de tua mulher, e comeste da árvore de que te ordenei, dizendo: Não comerás dela, maldita é a terra por causa de ti; com dor comerás dela todos os dias da tua vida.',
        'Espinhos, e cardos também, te produzirá; e comerás a erva do campo.',
        'No suor do teu rosto comerás o teu pão, até que te tornes à terra; porque dela foste tomado; porquanto és pó e em pó te tornarás.',
        'E chamou Adão o nome de sua mulher Eva; porquanto era a mãe de todos os viventes.',
        'E fez o Senhor Deus a Adão e à sua mulher túnicas de peles, e os vestiu.',
        'Então disse o Senhor Deus: Eis que o homem é como um de nós, sabendo o bem e o mal; ora, para que não estenda a sua mão, e tome também da árvore da vida, e coma e viva eternamente,',
        'O Senhor Deus, pois, o lançou fora do jardim do Éden, para lavrar a terra de que fora tomado.',
        'E havendo lançado fora o homem, pôs querubins ao oriente do jardim do Éden, e uma espada inflamada que andava ao redor, para guardar o caminho da árvore da vida.',
      ],
      [
        'E conheceu Adão a Eva, sua mulher, e ela concebeu e deu à luz a Caim, e disse: Alcancei do SENHOR um homem.',
        'E deu à luz mais a seu irmão Abel; e Abel foi pastor de ovelhas, e Caim foi lavrador da terra.',
        'E aconteceu ao cabo de dias que Caim trouxe do fruto da terra uma oferta ao Senhor.',
        'E Abel também trouxe dos primogênitos das suas ovelhas, e da sua gordura; e atentou o Senhor para Abel e para a sua oferta.',
        'Mas para Caim e para a sua oferta não atentou. E irou-se Caim fortemente, e descaiu-lhe o semblante.',
        'E o Senhor disse a Caim: Por que te iraste? E por que descaiu o teu semblante?',
        'Se bem fizeres, não é certo que serás aceito? E se não fizeres bem, o pecado jaz à porta, e sobre ti será o seu desejo, mas sobre ele deves dominar.',
        'E falou Caim com o seu irmão Abel; e sucedeu que, estando eles no campo, se levantou Caim contra o seu irmão Abel, e o matou.',
        'E disse o Senhor a Caim: Onde está Abel, teu irmão? E ele disse: Não sei; sou eu guardador do meu irmão?',
        'E disse Deus: Que fizeste? A voz do sangue do teu irmão clama a mim desde a terra.',
        'E agora maldito és tu desde a terra, que abriu a sua boca para receber da tua mão o sangue do teu irmão.',
        'Quando lavrares a terra, não te dará mais a sua força; fugitivo e vagabundo serás na terra.',
        'Então disse Caim ao Senhor: É maior a minha maldade que a que possa ser perdoada.',
        'Eis que hoje me lanças da face da terra, e da tua face me esconderei; e serei fugitivo e vagabundo na terra, e será que todo aquele que me achar, me matará.',
        'O Senhor, porém, disse-lhe: Portanto qualquer que matar a Caim, sete vezes será castigado. E pôs o Senhor um sinal em Caim, para que o não ferisse qualquer que o achasse.',
        'E saiu Caim de diante da face do Senhor, e habitou na terra de Node, do lado oriental do Éden.',
        'E conheceu Caim a sua mulher, e ela concebeu, e deu à luz a Enoque; e ele edificou uma cidade, e chamou o nome da cidade conforme o nome de seu filho Enoque;',
        'E a Enoque nasceu Irade, e Irade gerou a Meujael, e Meujael gerou a Metusael e Metusael gerou a Lameque.',
        'E tomou Lameque para si duas mulheres; o nome de uma era Ada, e o nome da outra, Zilá.',
        'E Ada deu à luz a Jabal; este foi o pai dos que habitam em tendas e têm gado.',
        'E o nome do seu irmão era Jubal; este foi o pai de todos os que tocam harpa e órgão.',
        'E Zilá também deu à luz a Tubalcaim, mestre de toda a obra de cobre e ferro; e a irmã de Tubalcaim foi Noema.',
        'E disse Lameque a suas mulheres Ada e Zilá: Ouvi a minha voz; vós, mulheres de Lameque, escutai as minhas palavras; porque eu matei um homem por me ferir, e um jovem por me pisar.',
        'Porque sete vezes Caim será castigado; mas Lameque setenta vezes sete.',
        'E tornou Adão a conhecer a sua mulher; e ela deu à luz um filho, e chamou o seu nome Sete; porque, disse ela, Deus me deu outro filho em lugar de Abel; porquanto Caim o matou.',
        'E a Sete também nasceu um filho; e chamou o seu nome Enos; então se começou a invocar o nome do Senhor.',
      ],
      [
        'Este é o livro das gerações de Adão. No dia em que Deus criou o homem, à semelhança de Deus o fez.',
        'Homem e mulher os criou; e os abençoou e chamou o seu nome Adão, no dia em que foram criados.',
        'E Adão viveu cento e trinta anos, e gerou um filho à sua semelhança, conforme a sua imagem, e pôs-lhe o nome de Sete.',
        'E foram os dias de Adão, depois que gerou a Sete, oitocentos anos, e gerou filhos e filhas.',
        'E foram todos os dias que Adão viveu, novecentos e trinta anos, e morreu.',
        'E viveu Sete cento e cinco anos, e gerou a Enos.',
        'E viveu Sete, depois que gerou a Enos, oitocentos e sete anos, e gerou filhos e filhas.',
        'E foram todos os dias de Sete novecentos e doze anos, e morreu.',
        'E viveu Enos noventa anos, e gerou a Cainã.',
        'E viveu Enos, depois que gerou a Cainã, oitocentos e quinze anos, e gerou filhos e filhas.',
        'E foram todos os dias de Enos novecentos e cinco anos, e morreu.',
        'E viveu Cainã setenta anos, e gerou a Maalaleel.',
        'E viveu Cainã, depois que gerou a Maalaleel, oitocentos e quarenta anos, e gerou filhos e filhas.',
        'E foram todos os dias de Cainã novecentos e dez anos, e morreu.',
        'E viveu Maalaleel sessenta e cinco anos, e gerou a Jerede.',
        'E viveu Maalaleel, depois que gerou a Jerede, oitocentos e trinta anos, e gerou filhos e filhas.',
        'E foram todos os dias de Maalaleel oitocentos e noventa e cinco anos, e morreu.',
        'E viveu Jerede cento e sessenta e dois anos, e gerou a Enoque.',
        'E viveu Jerede, depois que gerou a Enoque, oitocentos anos, e gerou filhos e filhas.',
        'E foram todos os dias de Jerede novecentos e sessenta e dois anos, e morreu.',
        'E viveu Enoque sessenta e cinco anos, e gerou a Matusalém.',
        'E andou Enoque com Deus, depois que gerou a Matusalém, trezentos anos, e gerou filhos e filhas.',
        'E foram todos os dias de Enoque trezentos e sessenta e cinco anos.',
        'E andou Enoque com Deus; e não apareceu mais, porquanto Deus para si o tomou.',
        'E viveu Matusalém cento e oitenta e sete anos, e gerou a Lameque.',
        'E viveu Matusalém, depois que gerou a Lameque, setecentos e oitenta e dois anos, e gerou filhos e filhas.',
        'E foram todos os dias de Matusalém novecentos e sessenta e nove anos, e morreu.',
        'E viveu Lameque cento e oitenta e dois anos, e gerou um filho,',
        'A quem chamou Noé, dizendo: Este nos consolará acerca de nossas obras e do trabalho de nossas mãos, por causa da terra que o Senhor amaldiçoou.',
        'E viveu Lameque, depois que gerou a Noé, quinhentos e noventa e cinco anos, e gerou filhos e filhas.',
        'E foram todos os dias de Lameque setecentos e setenta e sete anos, e morreu.',
        'E era Noé da idade de quinhentos anos, e gerou Noé a Sem, Cão e Jafé.',
      ],
      [
        'E aconteceu que, como os homens começaram a multiplicar-se sobre a face da terra, e lhes nasceram filhas,',
        'Viram os filhos de Deus que as filhas dos homens eram formosas; e tomaram para si mulheres de todas as que escolheram.',
        'Então disse o Senhor: Não contenderá o meu Espírito para sempre com o homem; porque ele também é carne; porém os seus dias serão cento e vinte anos.',
        'Havia naqueles dias gigantes na terra; e também depois, quando os filhos de Deus entraram às filhas dos homens e delas geraram filhos; estes eram os valentes que houve na antiguidade, os homens de fama.',
        'E viu o Senhor que a maldade do homem se multiplicara sobre a terra e que toda a imaginação dos pensamentos de seu coração era só má continuamente.',
        'Então arrependeu-se o Senhor de haver feito o homem sobre a terra e pesou-lhe em seu coração.',
        'E disse o Senhor: Destruirei o homem que criei de sobre a face da terra, desde o homem até ao animal, até ao réptil, e até à ave dos céus; porque me arrependo de os haver feito.',
        'Noé, porém, achou graça aos olhos do Senhor.',
        'Estas são as gerações de Noé. Noé era homem justo e perfeito em suas gerações; Noé andava com Deus.',
        'E gerou Noé três filhos: Sem, Cão e Jafé.',
        'A terra, porém, estava corrompida diante da face de Deus; e encheu-se a terra de violência.',
        'E viu Deus a terra, e eis que estava corrompida; porque toda a carne havia corrompido o seu caminho sobre a terra.',
        'Então disse Deus a Noé: O fim de toda a carne é vindo perante a minha face; porque a terra está cheia de violência; e eis que os desfarei com a terra.',
        'Faze para ti uma arca da madeira de gofer; farás compartimentos na arca e a betumarás por dentro e por fora com betume.',
        'E desta maneira a farás: De trezentos côvados o comprimento da arca, e de cinqüenta côvados a sua largura, e de trinta côvados a sua altura.',
        'Farás na arca uma janela, e de um côvado a acabarás em cima; e a porta da arca porás ao seu lado; far-lhe-ás andares, baixo, segundo e terceiro.',
        'Porque eis que eu trago um dilúvio de águas sobre a terra, para desfazer toda a carne em que há espírito de vida debaixo dos céus; tudo o que há na terra expirará.',
        'Mas contigo estabelecerei a minha aliança; e entrarás na arca, tu e os teus filhos, tua mulher e as mulheres de teus filhos contigo.',
        'E de tudo o que vive, de toda a carne, dois de cada espécie, farás entrar na arca, para os conservar vivos contigo; macho e fêmea serão.',
        'Das aves conforme a sua espécie, e dos animais conforme a sua espécie, de todo o réptil da terra conforme a sua espécie, dois de cada espécie virão a ti, para os conservar em vida.',
        'E leva contigo de toda a comida que se come e ajunta-a para ti; e te será para mantimento, a ti e a eles.',
        'Assim fez Noé; conforme a tudo o que Deus lhe mandou, assim o fez.',
      ],
      [
        'Depois disse o SENHOR a Noé: Entra tu e toda a tua casa na arca, porque tenho visto que és justo diante de mim nesta geração.',
        'De todos os animais limpos tomarás para ti sete e sete, o macho e sua fêmea; mas dos animais que não são limpos, dois, o macho e sua fêmea.',
        'Também das aves dos céus sete e sete, macho e fêmea, para conservar em vida sua espécie sobre a face de toda a terra.',
        'Porque, passados ainda sete dias, farei chover sobre a terra quarenta dias e quarenta noites; e desfarei de sobre a face da terra toda a substância que fiz.',
        'E fez Noé conforme a tudo o que o Senhor lhe ordenara.',
        'E era Noé da idade de seiscentos anos, quando o dilúvio das águas veio sobre a terra.',
        'Noé entrou na arca, e com ele seus filhos, sua mulher e as mulheres de seus filhos, por causa das águas do dilúvio.',
        'Dos animais limpos e dos animais que não são limpos, e das aves, e de todo o réptil sobre a terra,',
        'Entraram de dois em dois para junto de Noé na arca, macho e fêmea, como Deus ordenara a Noé.',
        'E aconteceu que passados sete dias, vieram sobre a terra as águas do dilúvio.',
        'No ano seiscentos da vida de Noé, no mês segundo, aos dezessete dias do mês, naquele mesmo dia se romperam todas as fontes do grande abismo, e as janelas dos céus se abriram,',
        'E houve chuva sobre a terra quarenta dias e quarenta noites.',
        'E no mesmo dia entraram na arca Noé, seus filhos Sem, Cão e Jafé, sua mulher e as mulheres de seus filhos.',
        'Eles, e todo o animal conforme a sua espécie, e todo o gado conforme a sua espécie, e todo o réptil que se arrasta sobre a terra conforme a sua espécie, e toda a ave conforme a sua espécie, pássaros de toda qualidade.',
        'E de toda a carne, em que havia espírito de vida, entraram de dois em dois para junto de Noé na arca.',
        'E os que entraram eram macho e fêmea de toda a carne, como Deus lhe tinha ordenado; e o Senhor o fechou dentro.',
        'E durou o dilúvio quarenta dias sobre a terra, e cresceram as águas e levantaram a arca, e ela se elevou sobre a terra.',
        'E prevaleceram as águas e cresceram grandemente sobre a terra; e a arca andava sobre as águas.',
        'E as águas prevaleceram excessivamente sobre a terra; e todos os altos montes que havia debaixo de todo o céu, foram cobertos.',
        'Quinze côvados acima prevaleceram as águas; e os montes foram cobertos.',
        'E expirou toda a carne que se movia sobre a terra, tanto de ave como de gado e de feras, e de todo o réptil que se arrasta sobre a terra, e todo o homem.',
        'Tudo o que tinha fôlego de espírito de vida em suas narinas, tudo o que havia em terra seca, morreu.',
        'Assim foi destruído todo o ser vivente que havia sobre a face da terra, desde o homem até ao animal, até ao réptil, e até à ave dos céus; e foram extintos da terra; e ficou somente Noé, e os que com ele estavam na arca.',
        'E prevaleceram as águas sobre a terra cento e cinqüenta dias.',
      ],
      [
        'E lembrou-se Deus de Noé, e de todos os seres viventes, e de todo o gado que estavam com ele na arca; e Deus fez passar um vento sobre a terra, e aquietaram-se as águas.',
        'Cerraram-se também as fontes do abismo e as janelas dos céus, e a chuva dos céus deteve-se.',
        'E as águas iam-se escoando continuamente de sobre a terra, e ao fim de cento e cinqüenta dias minguaram.',
        'E a arca repousou no sétimo mês, no dia dezessete do mês, sobre os montes de Ararate.',
        'E foram as águas indo e minguando até ao décimo mês; no décimo mês, no primeiro dia do mês, apareceram os cumes dos montes.',
        'E aconteceu que ao cabo de quarenta dias, abriu Noé a janela da arca que tinha feito.',
        'E soltou um corvo, que saiu, indo e voltando, até que as águas se secaram de sobre a terra.',
        'Depois soltou uma pomba, para ver se as águas tinham minguado de sobre a face da terra.',
        'A pomba, porém, não achou repouso para a planta do seu pé, e voltou a ele para a arca; porque as águas estavam sobre a face de toda a terra; e ele estendeu a sua mão, e tomou-a, e recolheu-a consigo na arca.',
        'E esperou ainda outros sete dias, e tornou a enviar a pomba fora da arca.',
        'E a pomba voltou a ele à tarde; e eis, arrancada, uma folha de oliveira no seu bico; e conheceu Noé que as águas tinham minguado de sobre a terra.',
        'Então esperou ainda outros sete dias, e enviou fora a pomba; mas não tornou mais a ele.',
        'E aconteceu que no ano seiscentos e um, no mês primeiro, no primeiro dia do mês, as águas se secaram de sobre a terra. Então Noé tirou a cobertura da arca, e olhou, e eis que a face da terra estava enxuta.',
        'E no segundo mês, aos vinte e sete dias do mês, a terra estava seca.',
        'Então falou Deus a Noé dizendo:',
        'Sai da arca, tu com tua mulher, e teus filhos e as mulheres de teus filhos.',
        'Todo o animal que está contigo, de toda a carne, de ave, e de gado, e de todo o réptil que se arrasta sobre a terra, traze fora contigo; e povoem abundantemente a terra e frutifiquem, e se multipliquem sobre a terra.',
        'Então saiu Noé, e seus filhos, e sua mulher, e as mulheres de seus filhos com ele.',
        'Todo o animal, todo o réptil, e toda a ave, e tudo o que se move sobre a terra, conforme as suas famílias, saiu para fora da arca.',
        'E edificou Noé um altar ao Senhor; e tomou de todo o animal limpo e de toda a ave limpa, e ofereceu holocausto sobre o altar.',
        'E o Senhor sentiu o suave cheiro, e o Senhor disse em seu coração: Não tornarei mais a amaldiçoar a terra por causa do homem; porque a imaginação do coração do homem é má desde a sua meninice, nem tornarei mais a ferir todo o vivente, como fiz.',
        'Enquanto a terra durar, sementeira e sega, e frio e calor, e verão e inverno, e dia e noite, não cessarão.',
      ],
      [
        'E abençoou Deus a Noé e a seus filhos, e disse-lhes: Frutificai e multiplicai-vos e enchei a terra.',
        'E o temor de vós e o pavor de vós virão sobre todo o animal da terra, e sobre toda a ave dos céus; tudo o que se move sobre a terra, e todos os peixes do mar, nas vossas mãos são entregues.',
        'Tudo quanto se move, que é vivente, será para vosso mantimento; tudo vos tenho dado como a erva verde.',
        'A carne, porém, com sua vida, isto é, com seu sangue, não comereis.',
        'Certamente requererei o vosso sangue, o sangue das vossas vidas; da mão de todo o animal o requererei; como também da mão do homem, e da mão do irmão de cada um requererei a vida do homem.',
        'Quem derramar o sangue do homem, pelo homem o seu sangue será derramado; porque Deus fez o homem conforme a sua imagem.',
        'Mas vós frutificai e multiplicai-vos; povoai abundantemente a terra, e multiplicai-vos nela.',
        'E falou Deus a Noé e a seus filhos com ele, dizendo:',
        'E eu, eis que estabeleço a minha aliança convosco e com a vossa descendência depois de vós.',
        'E com toda a alma vivente, que convosco está, de aves, de gado, e de todo o animal da terra convosco; com todos que saíram da arca, até todo o animal da terra.',
        'E eu convosco estabeleço a minha aliança, que não será mais destruída toda a carne pelas águas do dilúvio, e que não haverá mais dilúvio, para destruir a terra.',
        'E disse Deus: Este é o sinal da aliança que ponho entre mim e vós, e entre toda a alma vivente, que está convosco, por gerações eternas.',
        'O meu arco tenho posto nas nuvens; este será por sinal da aliança entre mim e a terra.',
        'E acontecerá que, quando eu trouxer nuvens sobre a terra, aparecerá o arco nas nuvens.',
        'Então me lembrarei da minha aliança, que está entre mim e vós, e entre toda a alma vivente de toda a carne; e as águas não se tornarão mais em dilúvio para destruir toda a carne.',
        'E estará o arco nas nuvens, e eu o verei, para me lembrar da aliança eterna entre Deus e toda a alma vivente de toda a carne, que está sobre a terra.',
        'E disse Deus a Noé: Este é o sinal da aliança que tenho estabelecido entre mim e entre toda a carne, que está sobre a terra.',
        'E os filhos de Noé, que da arca saíram, foram Sem, Cão e Jafé; e Cão é o pai de Canaã.',
        'Estes três foram os filhos de Noé; e destes se povoou toda a terra.',
        'E começou Noé a ser lavrador da terra, e plantou uma vinha.',
        'E bebeu do vinho, e embebedou-se; e descobriu-se no meio de sua tenda.',
        'E viu Cão, o pai de Canaã, a nudez do seu pai, e fê-lo saber a ambos seus irmãos no lado de fora.',
        'Então tomaram Sem e Jafé uma capa, e puseram-na sobre ambos os seus ombros, e indo virados para trás, cobriram a nudez do seu pai, e os seus rostos estavam virados, de maneira que não viram a nudez do seu pai.',
        'E despertou Noé do seu vinho, e soube o que seu filho menor lhe fizera.',
        'E disse: Maldito seja Canaã; servo dos servos seja aos seus irmãos.',
        'E disse: Bendito seja o Senhor Deus de Sem; e seja-lhe Canaã por servo.',
        'Alargue Deus a Jafé, e habite nas tendas de Sem; e seja-lhe Canaã por servo.',
        'E viveu Noé, depois do dilúvio, trezentos e cinqüenta anos.',
        'E foram todos os dias de Noé novecentos e cinqüenta anos, e morreu.',
      ],
      [
        'Estas, pois, são as gerações dos filhos de Noé: Sem, Cão e Jafé; e nasceram-lhes filhos depois do dilúvio.',
        'Os filhos de Jafé são: Gomer, Magogue, Madai, Javã, Tubal, Meseque e Tiras.',
        'E os filhos de Gomer são: Asquenaz, Rifate e Togarma.',
        'E os filhos de Javã são: Elisá, Társis, Quitim e Dodanim.',
        'Por estes foram repartidas as ilhas dos gentios nas suas terras, cada qual segundo a sua língua, segundo as suas famílias, entre as suas nações.',
        'E os filhos de Cão são: Cuxe, Mizraim, Pute e Canaã.',
        'E os filhos de Cuxe são: Sebá, Havilá, Sabtá, Raamá e Sabtecá; e os filhos de Raamá: Sebá e Dedã.',
        'E Cuxe gerou a Ninrode; este começou a ser poderoso na terra. 9',
        'E este foi poderoso caçador diante da face do Senhor; por isso se diz: Como Ninrode, poderoso caçador diante do Senhor.',
        'E o princípio do seu reino foi Babel, Ereque, Acade e Calné, na terra de Sinar.',
        'Desta mesma terra saiu à Assíria e edificou a Nínive, Reobote-Ir, Calá,',
        'E Resen, entre Nínive e Calá (esta é a grande cidade).',
        'E Mizraim gerou a Ludim, a Anamim, a Leabim, a Naftuim,',
        'A Patrusim e a Casluim (donde saíram os filisteus) e a Caftorim.',
        'E Canaã gerou a Sidom, seu primogênito, e a Hete;',
        'E ao jebuseu, ao amorreu, ao girgaseu,',
        'E ao heveu, ao arqueu, ao sineu,',
        'E ao arvadeu, ao zemareu, e ao hamateu, e depois se espalharam as famílias dos cananeus.',
        'E foi o termo dos cananeus desde Sidom, indo para Gerar, até Gaza; indo para Sodoma e Gomorra, Admá e Zeboim, até Lasa.',
        'Estes são os filhos de Cão segundo as suas famílias, segundo as suas línguas, em suas terras, em suas nações.',
        'E a Sem nasceram filhos, e ele é o pai de todos os filhos de Éber, o irmão mais velho de Jafé.',
        'Os filhos de Sem são: Elão, Assur, Arfaxade, Lude e Arã.',
        'E os filhos de Arã são: Uz, Hul, Geter e Más.',
        'E Arfaxade gerou a Selá; e Selá gerou a Éber.',
        'E a Éber nasceram dois filhos: o nome de um foi Pelegue, porquanto em seus dias se repartiu a terra, e o nome do seu irmão foi Joctã.',
        'E Joctã gerou a Almodá, a Selefe, a Hazarmavé, a Jerá,',
        'A Hadorão, a Usal, a Dicla,',
        'A Obal, a Abimael, a Sebá,',
        'A Ofir, a Havilá e a Jobabe; todos estes foram filhos de Joctã.',
        'E foi a sua habitação desde Messa, indo para Sefar, montanha do oriente.',
        'Estes são os filhos de Sem segundo as suas famílias, segundo as suas línguas, nas suas terras, segundo as suas nações.',
        'Estas são as famílias dos filhos de Noé segundo as suas gerações, nas suas nações; e destes foram divididas as nações na terra depois do dilúvio.',
      ],
      [
        'E era toda a terra de uma mesma língua e de uma mesma fala.',
        'E aconteceu que, partindo eles do oriente, acharam um vale na terra de Sinar; e habitaram ali.',
        'E disseram uns aos outros: Eia, façamos tijolos e queimemo-los bem. E foi-lhes o tijolo por pedra, e o betume por cal.',
        'E disseram: Eia, edifiquemos nós uma cidade e uma torre cujo cume toque nos céus, e façamo-nos um nome, para que não sejamos espalhados sobre a face de toda a terra.',
        'Então desceu o Senhor para ver a cidade e a torre que os filhos dos homens edificavam;',
        'E o Senhor disse: Eis que o povo é um, e todos têm uma mesma língua; e isto é o que começam a fazer; e agora, não haverá restrição para tudo o que eles intentarem fazer.',
        'Eia, desçamos e confundamos ali a sua língua, para que não entenda um a língua do outro.',
        'Assim o Senhor os espalhou dali sobre a face de toda a terra; e cessaram de edificar a cidade.',
        'Por isso se chamou o seu nome Babel, porquanto ali confundiu o Senhor a língua de toda a terra, e dali os espalhou o Senhor sobre a face de toda a terra.',
        'Estas são as gerações de Sem: Sem era da idade de cem anos e gerou a Arfaxade, dois anos depois do dilúvio.',
        'E viveu Sem, depois que gerou a Arfaxade, quinhentos anos, e gerou filhos e filhas.',
        'E viveu Arfaxade trinta e cinco anos, e gerou a Selá.',
        'E viveu Arfaxade depois que gerou a Selá, quatrocentos e três anos, e gerou filhos e filhas.',
        'E viveu Selá trinta anos, e gerou a Éber;',
        'E viveu Selá, depois que gerou a Éber, quatrocentos e três anos, e gerou filhos e filhas.',
        'E viveu Éber trinta e quatro anos, e gerou a Pelegue.',
        'E viveu Éber, depois que gerou a Pelegue, quatrocentos e trinta anos, e gerou filhos e filhas.',
        'E viveu Pelegue trinta anos, e gerou a Reú.',
        'E viveu Pelegue, depois que gerou a Reú, duzentos e nove anos, e gerou filhos e filhas.',
        'E viveu Reú trinta e dois anos, e gerou a Serugue.',
        'E viveu Reú, depois que gerou a Serugue, duzentos e sete anos, e gerou filhos e filhas.',
        'E viveu Serugue trinta anos, e gerou a Naor.',
        'E viveu Serugue, depois que gerou a Naor, duzentos anos, e gerou filhos e filhas.',
        'E viveu Naor vinte e nove anos, e gerou a Terá.',
        'E viveu Naor, depois que gerou a Terá, cento e dezenove anos, e gerou filhos e filhas.',
        'E viveu Terá setenta anos, e gerou a Abrão, a Naor, e a Harã.',
        'E estas são as gerações de Terá: Terá gerou a Abrão, a Naor, e a Harã; e Harã gerou a Ló.',
        'E morreu Harã estando seu pai Terá ainda vivo, na terra do seu nascimento, em Ur dos caldeus.',
        'E tomaram Abrão e Naor mulheres para si: o nome da mulher de Abrão era Sarai, e o nome da mulher de Naor era Milca, filha de Harã, pai de Milca e pai de Iscá.',
        'E Sarai foi estéril, não tinha filhos.',
        'E tomou Terá a Abrão seu filho, e a Ló, filho de Harã, filho de seu filho, e a Sarai sua nora, mulher de seu filho Abrão, e saiu com eles de Ur dos caldeus, para ir à terra de Canaã; e vieram até Harã, e habitaram ali.',
        'E foram os dias de Terá duzentos e cinco anos, e morreu Terá em Harã.',
      ],
      [
        'Ora, o SENHOR disse a Abrão: Sai-te da tua terra, da tua parentela e da casa de teu pai, para a terra que eu te mostrarei.',
        'E far-te-ei uma grande nação, e abençoar-te-ei e engrandecerei o teu nome; e tu serás uma bênção.',
        'E abençoarei os que te abençoarem, e amaldiçoarei os que te amaldiçoarem; e em ti serão benditas todas as famílias da terra.',
        'Assim partiu Abrão como o Senhor lhe tinha dito, e foi Ló com ele; e era Abrão da idade de setenta e cinco anos quando saiu de Harã.',
        'E tomou Abrão a Sarai, sua mulher, e a Ló, filho de seu irmão, e todos os bens que haviam adquirido, e as almas que lhe acresceram em Harã; e saíram para irem à terra de Canaã; e chegaram à terra de Canaã.',
        'E passou Abrão por aquela terra até ao lugar de Siquém, até ao carvalho de Moré; e estavam então os cananeus na terra.',
        'E apareceu o Senhor a Abrão, e disse: À tua descendência darei esta terra. E edificou ali um altar ao Senhor, que lhe aparecera.',
        'E moveu-se dali para a montanha do lado oriental de Betel, e armou a sua tenda, tendo Betel ao ocidente, e Ai ao oriente; e edificou ali um altar ao Senhor, e invocou o nome do Senhor.',
        'Depois caminhou Abrão dali, seguindo ainda para o lado do sul.',
        'E havia fome naquela terra; e desceu Abrão ao Egito, para peregrinar ali, porquanto a fome era grande na terra.',
        'E aconteceu que, chegando ele para entrar no Egito, disse a Sarai, sua mulher: Ora, bem sei que és mulher formosa à vista;',
        'E será que, quando os egípcios te virem, dirão: Esta é sua mulher. E matar-me-ão a mim, e a ti te guardarão em vida.',
        'Dize, peço-te, que és minha irmã, para que me vá bem por tua causa, e que viva a minha alma por amor de ti.',
        'E aconteceu que, entrando Abrão no Egito, viram os egípcios a mulher, que era mui formosa.',
        'E viram-na os príncipes de Faraó, e gabaram-na diante de Faraó; e foi a mulher tomada para a casa de Faraó.',
        'E fez bem a Abrão por amor dela; e ele teve ovelhas, vacas, jumentos, servos e servas, jumentas e camelos.',
        'Feriu, porém, o Senhor a Faraó e a sua casa, com grandes pragas, por causa de Sarai, mulher de Abrão.',
        'Então chamou Faraó a Abrão, e disse: Que é isto que me fizeste? Por que não me disseste que ela era tua mulher?',
        'Por que disseste: É minha irmã? Por isso a tomei por minha mulher; agora, pois, eis aqui tua mulher; toma-a e vai-te.',
        'E Faraó deu ordens aos seus homens a respeito dele; e acompanharam-no, a ele, e a sua mulher, e a tudo o que tinha.',
      ],
      [
        'Subiu, pois, Abrão do Egito para o lado do sul, ele e sua mulher, e tudo o que tinha, e com ele Ló.',
        'E era Abrão muito rico em gado, em prata e em ouro.',
        'E fez as suas jornadas do sul até Betel, até ao lugar onde a princípio estivera a sua tenda, entre Betel e Ai;',
        'Até ao lugar do altar que outrora ali tinha feito; e Abrão invocou ali o nome do Senhor.',
        'E também Ló, que ia com Abrão, tinha rebanhos, gado e tendas.',
        'E não tinha capacidade a terra para poderem habitar juntos; porque os seus bens eram muitos; de maneira que não podiam habitar juntos.',
        'E houve contenda entre os pastores do gado de Abrão e os pastores do gado de Ló; e os cananeus e os perizeus habitavam então na terra.',
        'E disse Abrão a Ló: Ora, não haja contenda entre mim e ti, e entre os meus pastores e os teus pastores, porque somos irmãos.',
        'Não está toda a terra diante de ti? Eia, pois, aparta-te de mim; e se escolheres a esquerda, irei para a direita; e se a direita escolheres, eu irei para a esquerda.',
        'E levantou Ló os seus olhos, e viu toda a campina do Jordão, que era toda bem regada, antes do Senhor ter destruído Sodoma e Gomorra, e era como o jardim do Senhor, como a terra do Egito, quando se entra em Zoar.',
        'Então Ló escolheu para si toda a campina do Jordão, e partiu Ló para o oriente, e apartaram-se um do outro.',
        'Habitou Abrão na terra de Canaã e Ló habitou nas cidades da campina, e armou as suas tendas até Sodoma.',
        'Ora, eram maus os homens de Sodoma, e grandes pecadores contra o Senhor.',
        'E disse o Senhor a Abrão, depois que Ló se apartou dele: Levanta agora os teus olhos, e olha desde o lugar onde estás, para o lado do norte, e do sul, e do oriente, e do ocidente;',
        'Porque toda esta terra que vês, te hei de dar a ti, e à tua descendência, para sempre.',
        'E farei a tua descendência como o pó da terra; de maneira que se alguém puder contar o pó da terra, também a tua descendência será contada.',
        'Levanta-te, percorre essa terra, no seu comprimento e na sua largura; porque a ti a darei.',
        'E Abrão mudou as suas tendas, e foi, e habitou nos carvalhais de Manre, que estão junto a Hebrom; e edificou ali um altar ao Senhor.',
      ],
      [
        'E aconteceu nos dias de Anrafel, rei de Sinar, Arioque, rei de Elasar, Quedorlaomer, rei de Elão, e Tidal, rei de Goim,',
        'Que estes fizeram guerra a Bera, rei de Sodoma, a Birsa, rei de Gomorra, a Sinabe, rei de Admá, e a Semeber, rei de Zeboim, e ao rei de Belá (esta é Zoar).',
        'Todos estes se ajuntaram no vale de Sidim (que é o Mar Salgado).',
        'Doze anos haviam servido a Quedorlaomer, mas ao décimo terceiro ano rebelaram-se.',
        'E ao décimo quarto ano veio Quedorlaomer, e os reis que estavam com ele, e feriram aos refains em Asterote-Carnaim, e aos zuzins em Hã, e aos emins em Savé-Quiriataim,',
        'E aos horeus no seu monte Seir, até El-Parã que está junto ao deserto.',
        'Depois tornaram e vieram a En-Mispate (que é Cades), e feriram toda a terra dos amalequitas, e também aos amorreus, que habitavam em Hazazom-Tamar.',
        'Então saiu o rei de Sodoma, e o rei de Gomorra, e o rei de Admá, e o rei de Zeboim, e o rei de Belá (esta é Zoar), e ordenaram batalha contra eles no vale de Sidim,',
        'Contra Quedorlaomer, rei de Elão, e Tidal, rei de Goim, e Anrafel, rei de Sinar, e Arioque, rei de Elasar; quatro reis contra cinco.',
        'E o vale de Sidim estava cheio de poços de betume; e fugiram os reis de Sodoma e de Gomorra, e caíram ali; e os restantes fugiram para um monte.',
        'E tomaram todos os bens de Sodoma, e de Gomorra, e todo o seu mantimento e foram-se.',
        'Também tomaram a Ló, que habitava em Sodoma, filho do irmão de Abrão, e os seus bens, e foram-se.',
        'Então veio um, que escapara, e o contou a Abrão, o hebreu; ele habitava junto dos carvalhais de Manre, o amorreu, irmão de Escol, e irmão de Aner; eles eram confederados de Abrão.',
        'Ouvindo, pois, Abrão que o seu irmão estava preso, armou os seus criados, nascidos em sua casa, trezentos e dezoito, e os perseguiu até Dã.',
        'E dividiu-se contra eles de noite, ele e os seus criados, e os feriu, e os perseguiu até Hobá, que fica à esquerda de Damasco.',
        'E tornou a trazer todos os seus bens, e tornou a trazer também a Ló, seu irmão, e os seus bens, e também as mulheres, e o povo.',
        'E o rei de Sodoma saiu-lhe ao encontro (depois que voltou de ferir a Quedorlaomer e aos reis que estavam com ele) até ao Vale de Savé, que é o vale do rei.',
        'E Melquisedeque, rei de Salém, trouxe pão e vinho; e era este sacerdote do Deus Altíssimo.',
        'E abençoou-o, e disse: Bendito seja Abrão pelo Deus Altíssimo, o Possuidor dos céus e da terra;',
        'E bendito seja o Deus Altíssimo, que entregou os teus inimigos nas tuas mãos. E Abrão deu-lhe o dízimo de tudo.',
        'E o rei de Sodoma disse a Abrão: Dá-me a mim as pessoas, e os bens toma para ti.',
        'Abrão, porém, disse ao rei de Sodoma: Levantei minha mão ao Senhor, o Deus Altíssimo, o Possuidor dos céus e da terra,',
        'Jurando que desde um fio até à correia de um sapato, não tomarei coisa alguma de tudo o que é teu; para que não digas: Eu enriqueci a Abrão;',
        'Salvo tão-somente o que os jovens comeram, e a parte que toca aos homens que comigo foram, Aner, Escol e Manre; estes que tomem a sua parte.',
      ],
      [
        'Depois destas coisas veio a palavra do SENHOR a Abrão em visão, dizendo: Não temas, Abrão, eu sou o teu escudo, o teu grandíssimo galardão.',
        'Então disse Abrão: Senhor DEUS, que me hás de dar, pois ando sem filhos, e o mordomo da minha casa é o damasceno Eliézer?',
        'Disse mais Abrão: Eis que não me tens dado filhos, e eis que um nascido na minha casa será o meu herdeiro.',
        'E eis que veio a palavra do Senhor a ele dizendo: Este não será o teu herdeiro; mas aquele que de tuas entranhas sair, este será o teu herdeiro.',
        'Então o levou fora, e disse: Olha agora para os céus, e conta as estrelas, se as podes contar. E disse-lhe: Assim será a tua descendência.',
        'E creu ele no Senhor, e imputou-lhe isto por justiça.',
        'Disse-lhe mais: Eu sou o Senhor, que te tirei de Ur dos caldeus, para dar-te a ti esta terra, para herdá-la.',
        'E disse ele: Senhor DEUS, como saberei que hei de herdá-la?',
        'E disse-lhe: Toma-me uma bezerra de três anos, e uma cabra de três anos, e um carneiro de três anos, uma rola e um pombinho.',
        'E trouxe-lhe todos estes, e partiu-os pelo meio, e pôs cada parte deles em frente da outra; mas as aves não partiu.',
        'E as aves desciam sobre os cadáveres; Abrão, porém, as enxotava.',
        'E pondo-se o sol, um profundo sono caiu sobre Abrão; e eis que grande espanto e grande escuridão caiu sobre ele.',
        'Então disse a Abrão: Saibas, de certo, que peregrina será a tua descendência em terra alheia, e será reduzida à escravidão, e será afligida por quatrocentos anos,',
        'Mas também eu julgarei a nação, à qual ela tem de servir, e depois sairá com grande riqueza.',
        'E tu irás a teus pais em paz; em boa velhice serás sepultado.',
        'E a quarta geração tornará para cá; porque a medida da injustiça dos amorreus não está ainda cheia.',
        'E sucedeu que, posto o sol, houve escuridão, e eis um forno de fumaça, e uma tocha de fogo, que passou por aquelas metades.',
        'Naquele mesmo dia fez o Senhor uma aliança com Abrão, dizendo: « tua descendência tenho dado esta terra, desde o rio do Egito até ao grande rio Eufrates;',
        'E o queneu, e o quenezeu, e o cadmoneu,',
        'E o heteu, e o perizeu, e os refains,',
        'E o amorreu, e o cananeu, e o girgaseu, e o jebuseu.',
      ],
      [
        'Ora Sarai, mulher de Abrão, não lhe dava filhos, e ele tinha uma serva egípcia, cujo nome era Agar.',
        'E disse Sarai a Abrão: Eis que o Senhor me tem impedido de dar à luz; toma, pois, a minha serva; porventura terei filhos dela. E ouviu Abrão a voz de Sarai.',
        'Assim tomou Sarai, mulher de Abrão, a Agar egípcia, sua serva, e deu-a por mulher a Abrão seu marido, ao fim de dez anos que Abrão habitara na terra de Canaã.',
        'E ele possuiu a Agar, e ela concebeu; e vendo ela que concebera, foi sua senhora desprezada aos seus olhos.',
        'Então disse Sarai a Abrão: Meu agravo seja sobre ti; minha serva pus eu em teu regaço; vendo ela agora que concebeu, sou menosprezada aos seus olhos; o Senhor julgue entre mim e ti.',
        'E disse Abrão a Sarai: Eis que tua serva está na tua mão; faze-lhe o que bom é aos teus olhos. E afligiu-a Sarai, e ela fugiu de sua face.',
        'E o anjo do Senhor a achou junto a uma fonte de água no deserto, junto à fonte no caminho de Sur.',
        'E disse: Agar, serva de Sarai, donde vens, e para onde vais? E ela disse: Venho fugida da face de Sarai minha senhora.',
        'Então lhe disse o anjo do SENHOR: Torna-te para tua senhora, e humilha-te debaixo de suas mãos.',
        'Disse-lhe mais o anjo do Senhor: Multiplicarei sobremaneira a tua descendência, que não será contada, por numerosa que será.',
        'Disse-lhe também o anjo do Senhor: Eis que concebeste, e darás à luz um filho, e chamarás o seu nome Ismael; porquanto o Senhor ouviu a tua aflição.',
        'E ele será homem feroz, e a sua mão será contra todos, e a mão de todos contra ele; e habitará diante da face de todos os seus irmãos.',
        'E ela chamou o nome do Senhor, que com ela falava: Tu és Deus que me vê; porque disse: Não olhei eu também para aquele que me vê?',
        'Por isso se chama aquele poço de Beer-Laai-Rói; eis que está entre Cades e Berede.',
        'E Agar deu à luz um filho a Abrão; e Abrão chamou o nome do seu filho que Agar tivera, Ismael.',
        'E era Abrão da idade de oitenta e seis anos, quando Agar deu à luz Ismael.',
      ],
      [
        'Sendo, pois, Abrão da idade de noventa e nove anos, apareceu o SENHOR a Abrão, e disse-lhe: Eu sou o Deus Todo-Poderoso, anda em minha presença e sê perfeito.',
        'E porei a minha aliança entre mim e ti, e te multiplicarei grandissimamente.',
        'Então caiu Abrão sobre o seu rosto, e falou Deus com ele, dizendo:',
        'Quanto a mim, eis a minha aliança contigo: serás o pai de muitas nações;',
        'E não se chamará mais o teu nome Abrão, mas Abraão será o teu nome; porque por pai de muitas nações te tenho posto;',
        'E te farei frutificar grandissimamente, e de ti farei nações, e reis sairão de ti;',
        'E estabelecerei a minha aliança entre mim e ti e a tua descendência depois de ti em suas gerações, por aliança perpétua, para te ser a ti por Deus, e à tua descendência depois de ti.',
        'E te darei a ti e à tua descendência depois de ti, a terra de tuas peregrinações, toda a terra de Canaã em perpétua possessão e ser-lhes-ei o seu Deus.',
        'Disse mais Deus a Abraão: Tu, porém, guardarás a minha aliança, tu, e a tua descendência depois de ti, nas suas gerações.',
        'Esta é a minha aliança, que guardareis entre mim e vós, e a tua descendência depois de ti: Que todo o homem entre vós será circuncidado.',
        'E circuncidareis a carne do vosso prepúcio; e isto será por sinal da aliança entre mim e vós.',
        'O filho de oito dias, pois, será circuncidado, todo o homem nas vossas gerações; o nascido na casa, e o comprado por dinheiro a qualquer estrangeiro, que não for da tua descendência.',
        'Com efeito será circuncidado o nascido em tua casa, e o comprado por teu dinheiro; e estará a minha aliança na vossa carne por aliança perpétua.',
        'E o homem incircunciso, cuja carne do prepúcio não estiver circuncidada, aquela alma será extirpada do seu povo; quebrou a minha aliança.',
        'Disse Deus mais a Abraão: A Sarai tua mulher não chamarás mais pelo nome de Sarai, mas Sara será o seu nome.',
        'Porque eu a hei de abençoar, e te darei dela um filho; e a abençoarei, e será mãe das nações; reis de povos sairão dela.',
        'Então caiu Abraão sobre o seu rosto, e riu-se, e disse no seu coração: A um homem de cem anos há de nascer um filho? E dará à luz Sara da idade de noventa anos?',
        'E disse Abraão a Deus: Quem dera que viva Ismael diante de teu rosto!',
        'E disse Deus: Na verdade, Sara, tua mulher, te dará um filho, e chamarás o seu nome Isaque, e com ele estabelecerei a minha aliança, por aliança perpétua para a sua descendência depois dele.',
        'E quanto a Ismael, também te tenho ouvido; eis aqui o tenho abençoado, e fá-lo-ei frutificar, e fá-lo-ei multiplicar grandissimamente; doze príncipes gerará, e dele farei uma grande nação.',
        'A minha aliança, porém, estabelecerei com Isaque, o qual Sara dará à luz neste tempo determinado, no ano seguinte.',
        'Ao acabar de falar com Abraão, subiu Deus de diante dele.',
        'Então tomou Abraão a seu filho Ismael, e a todos os nascidos na sua casa, e a todos os comprados por seu dinheiro, todo o homem entre os da casa de Abraão; e circuncidou a carne do seu prepúcio, naquele mesmo dia, como Deus falara com ele.',
        'E era Abraão da idade de noventa e nove anos, quando lhe foi circuncidada a carne do seu prepúcio.',
        'E Ismael, seu filho, era da idade de treze anos, quando lhe foi circuncidada a carne do seu prepúcio.',
        'Naquele mesmo dia foram circuncidados Abraão e Ismael seu filho,',
        'E todos os homens da sua casa, os nascidos em casa, e os comprados por dinheiro ao estrangeiro, foram circuncidados com ele.',
      ],
      [
        'Depois apareceu-lhe o Senhor nos carvalhais de Manre, estando ele assentado à porta da tenda, no calor do dia',
        'E levantou os seus olhos, e olhou, e eis três homens em pé junto a ele. E vendo-os, correu da porta da tenda ao seu encontro e inclinou-se à terra,',
        'E disse: Meu Senhor, se agora tenho achado graça aos teus olhos, rogo-te que não passes de teu servo.',
        'Que se traga já um pouco de água, e lavai os vossos pés, e recostai-vos debaixo desta árvore;',
        'E trarei um bocado de pão, para que esforceis o vosso coração; depois passareis adiante, porquanto por isso chegastes até vosso servo. E disseram: Assim faze como disseste.',
        'E Abraão apressou-se em ir ter com Sara à tenda, e disse-lhe: Amassa depressa três medidas de flor de farinha, e faze bolos.',
        'E correu Abraão às vacas, e tomou uma vitela tenra e boa, e deu-a ao moço, que se apressou em prepará-la.',
        'E tomou manteiga e leite, e a vitela que tinha preparado, e pôs tudo diante deles, e ele estava em pé junto a eles debaixo da árvore; e comeram.',
        'E disseram-lhe: Onde está Sara, tua mulher? E ele disse: Ei-la aí na tenda.',
        'E disse: Certamente tornarei a ti por este tempo da vida; e eis que Sara tua mulher terá um filho. E Sara escutava à porta da tenda, que estava atrás dele.',
        'E eram Abraão e Sara já velhos, e adiantados em idade; já a Sara havia cessado o costume das mulheres.',
        'Assim, pois, riu-se Sara consigo, dizendo: Terei ainda deleite depois de haver envelhecido, sendo também o meu senhor já velho?',
        'E disse o Senhor a Abraão: Por que se riu Sara, dizendo: Na verdade darei eu à luz ainda, havendo já envelhecido?',
        'Haveria coisa alguma difícil ao Senhor? Ao tempo determinado tornarei a ti por este tempo da vida, e Sara terá um filho.',
        'E Sara negou, dizendo: Não me ri; porquanto temeu. E ele disse: Não digas isso, porque te riste.',
        'E levantaram-se aqueles homens dali, e olharam para o lado de Sodoma; e Abraão ia com eles, acompanhando-os.',
        'E disse o Senhor: Ocultarei eu a Abraão o que faço,',
        'Visto que Abraão certamente virá a ser uma grande e poderosa nação, e nele serão benditas todas as nações da terra?',
        'Porque eu o tenho conhecido, e sei que ele há de ordenar a seus filhos e à sua casa depois dele, para que guardem o caminho do Senhor, para agir com justiça e juízo; para que o Senhor faça vir sobre Abraão o que acerca dele tem falado.',
        'Disse mais o Senhor: Porquanto o clamor de Sodoma e Gomorra se tem multiplicado, e porquanto o seu pecado se tem agravado muito,',
        'Descerei agora, e verei se com efeito têm praticado segundo o seu clamor, que é vindo até mim; e se não, sabê-lo-ei.',
        'Então viraram aqueles homens os rostos dali, e foram-se para Sodoma; mas Abraão ficou ainda em pé diante da face do Senhor.',
        'E chegou-se Abraão, dizendo: Destruirás também o justo com o ímpio?',
        'Se porventura houver cinqüenta justos na cidade, destruirás também, e não pouparás o lugar por causa dos cinqüenta justos que estão dentro dela?',
        'Longe de ti que faças tal coisa, que mates o justo com o ímpio; que o justo seja como o ímpio, longe de ti. Não faria justiça o Juiz de toda a terra?',
        'Então disse o Senhor: Se eu em Sodoma achar cinqüenta justos dentro da cidade, pouparei a todo o lugar por amor deles.',
        'E respondeu Abraão dizendo: Eis que agora me atrevi a falar ao Senhor, ainda que sou pó e cinza.',
        'Se porventura de cinqüenta justos faltarem cinco, destruirás por aqueles cinco toda a cidade? E disse: Não a destruirei, se eu achar ali quarenta e cinco.',
        'E continuou ainda a falar-lhe, e disse: Se porventura se acharem ali quarenta? E disse: Não o farei por amor dos quarenta.',
        'Disse mais: Ora, não se ire o Senhor, se eu ainda falar: Se porventura se acharem ali trinta? E disse: Não o farei se achar ali trinta.',
        'E disse: Eis que agora me atrevi a falar ao Senhor: Se porventura se acharem ali vinte? E disse: Não a destruirei por amor dos vinte.',
        'Disse mais: Ora, não se ire o Senhor, que ainda só mais esta vez falo: Se porventura se acharem ali dez? E disse: Não a destruirei por amor dos dez.',
        'E retirou-se o Senhor, quando acabou de falar a Abraão; e Abraão tornou-se ao seu lugar.',
      ],
      [
        'E vieram os dois anjos a Sodoma à tarde, e estava Ló assentado à porta de Sodoma; e vendo-os Ló, levantou-se ao seu encontro e inclinou-se com o rosto à terra;',
        'E disse: Eis agora, meus senhores, entrai, peço-vos, em casa de vosso servo, e passai nela a noite, e lavai os vossos pés; e de madrugada vos levantareis e ireis vosso caminho. E eles disseram: Não, antes na rua passaremos a noite.',
        'E porfiou com eles muito, e vieram com ele, e entraram em sua casa; e fez-lhes banquete, e cozeu bolos sem levedura, e comeram.',
        'E antes que se deitassem, cercaram a casa, os homens daquela cidade, os homens de Sodoma, desde o moço até ao velho; todo o povo de todos os bairros.',
        'E chamaram a Ló, e disseram-lhe: Onde estão os homens que a ti vieram nesta noite? Traze-os fora a nós, para que os conheçamos.',
        'Então saiu Ló a eles à porta, e fechou a porta atrás de si,',
        'E disse: Meus irmãos, rogo-vos que não façais mal;',
        'Eis aqui, duas filhas tenho, que ainda não conheceram homens; fora vo-las trarei, e fareis delas como bom for aos vossos olhos; somente nada façais a estes homens, porque por isso vieram à sombra do meu telhado.',
        'Eles, porém, disseram: Sai daí. Disseram mais: Como estrangeiro este indivíduo veio aqui habitar, e quereria ser juiz em tudo? Agora te faremos mais mal a ti do que a eles. E arremessaram-se sobre o homem, sobre Ló, e aproximaram-se para arrombar a porta.',
        'Aqueles homens porém estenderam as suas mãos e fizeram entrar a Ló consigo na casa, e fecharam a porta;',
        'E feriram de cegueira os homens que estavam à porta da casa, desde o menor até ao maior, de maneira que se cansaram para achar a porta.',
        'Então disseram aqueles homens a Ló: Tens alguém mais aqui? Teu genro, e teus filhos, e tuas filhas, e todos quantos tens nesta cidade, tira-os fora deste lugar;',
        'Porque nós vamos destruir este lugar, porque o seu clamor tem aumentado diante da face do Senhor, e o Senhor nos enviou a destruí-lo.',
        'Então saiu Ló, e falou a seus genros, aos que haviam de tomar as suas filhas, e disse: Levantai-vos, saí deste lugar, porque o Senhor há de destruir a cidade. Foi tido porém por zombador aos olhos de seus genros.',
        'E ao amanhecer os anjos apertaram com Ló, dizendo: Levanta-te, toma tua mulher e tuas duas filhas que aqui estão, para que não pereças na injustiça desta cidade.',
        'Ele, porém, demorava-se, e aqueles homens lhe pegaram pela mão, e pela mão de sua mulher e de suas duas filhas, sendo-lhe o Senhor misericordioso, e tiraram-no, e puseram-no fora da cidade.',
        'E aconteceu que, tirando-os fora, disse: Escapa-te por tua vida; não olhes para trás de ti, e não pares em toda esta campina; escapa lá para o monte, para que não pereças.',
        'E Ló disse-lhe: Ora, não, meu Senhor!',
        'Eis que agora o teu servo tem achado graça aos teus olhos, e engrandeceste a tua misericórdia que a mim me fizeste, para guardar a minha alma em vida; mas eu não posso escapar no monte, para que porventura não me apanhe este mal, e eu morra.',
        'Eis que agora aquela cidade está perto, para fugir para lá, e é pequena; ora, deixe-me escapar para lá (não é pequena? ), para que minha alma viva.',
        'E disse-lhe: Eis aqui, tenho-te aceitado também neste negócio, para não destruir aquela cidade, de que falaste;',
        'Apressa-te, escapa-te para ali; porque nada poderei fazer, enquanto não tiveres ali chegado. Por isso se chamou o nome da cidade Zoar.',
        'Saiu o sol sobre a terra, quando Ló entrou em Zoar.',
        'Então o Senhor fez chover enxofre e fogo, do Senhor desde os céus, sobre Sodoma e Gomorra;',
        'E destruiu aquelas cidades e toda aquela campina, e todos os moradores daquelas cidades, e o que nascia da terra.',
        'E a mulher de Ló olhou para trás e ficou convertida numa estátua de sal.',
        'E Abraão levantou-se aquela mesma manhã, de madrugada, e foi para aquele lugar onde estivera diante da face do Senhor;',
        'E olhou para Sodoma e Gomorra e para toda a terra da campina; e viu, que a fumaça da terra subia, como a de uma fornalha.',
        'E aconteceu que, destruindo Deus as cidades da campina, lembrou-se Deus de Abraão, e tirou a Ló do meio da destruição, derrubando aquelas cidades em que Ló habitara.',
        'E subiu Ló de Zoar, e habitou no monte, e as suas duas filhas com ele; porque temia habitar em Zoar; e habitou numa caverna, ele e as suas duas filhas.',
        'Então a primogênita disse à menor: Nosso pai já é velho, e não há homem na terra que entre a nós, segundo o costume de toda a terra;',
        'Vem, demos de beber vinho a nosso pai, e deitemo-nos com ele, para que em vida conservemos a descendência de nosso pai.',
        'E deram de beber vinho a seu pai naquela noite; e veio a primogênita e deitou-se com seu pai, e não sentiu ele quando ela se deitou, nem quando se levantou.',
        'E sucedeu, no outro dia, que a primogênita disse à menor: Vês aqui, eu já ontem à noite me deitei com meu pai; demos-lhe de beber vinho também esta noite, e então entra tu, deita-te com ele, para que em vida conservemos a descendência de nosso pai.',
        'E deram de beber vinho a seu pai também naquela noite; e levantou-se a menor, e deitou-se com ele; e não sentiu ele quando ela se deitou, nem quando se levantou.',
        'E conceberam as duas filhas de Ló de seu pai.',
        'E a primogênita deu à luz um filho, e chamou-lhe Moabe; este é o pai dos moabitas até ao dia de hoje.',
        'E a menor também deu à luz um filho, e chamou-lhe Ben-Ami; este é o pai dos filhos de Amom até o dia de hoje.',
      ],
      [
        'E partiu Abraão dali para a terra do sul, e habitou entre Cades e Sur; e peregrinou em Gerar.',
        'E havendo Abraão dito de Sara, sua mulher: É minha irmã; enviou Abimeleque, rei de Gerar, e tomou a Sara.',
        'Deus, porém, veio a Abimeleque em sonhos de noite, e disse-lhe: Eis que morto serás por causa da mulher que tomaste; porque ela tem marido.',
        'Mas Abimeleque ainda não se tinha chegado a ela; por isso disse: Senhor, matarás também uma nação justa?',
        'Não me disse ele mesmo: É minha irmã? E ela também disse: É meu irmão. Em sinceridade do coração e em pureza das minhas mãos tenho feito isto.',
        'E disse-lhe Deus em sonhos: Bem sei eu que na sinceridade do teu coração fizeste isto; e também eu te tenho impedido de pecar contra mim; por isso não te permiti tocá-la.',
        'Agora, pois, restitui a mulher ao seu marido, porque profeta é, e rogará por ti, para que vivas; porém se não lha restituíres, sabe que certamente morrerás, tu e tudo o que é teu.',
        'E levantou-se Abimeleque pela manhã de madrugada, chamou a todos os seus servos, e falou todas estas palavras em seus ouvidos; e temeram muito aqueles homens.',
        'Então chamou Abimeleque a Abraão e disse-lhe: Que nos fizeste? E em que pequei contra ti, para trazeres sobre o meu reino tamanho pecado? Tu me fizeste aquilo que não deverias ter feito.',
        'Disse mais Abimeleque a Abraão: Que tens visto, para fazer tal coisa?',
        'E disse Abraão: Porque eu dizia comigo: Certamente não há temor de Deus neste lugar, e eles me matarão por causa da minha mulher.',
        'E, na verdade, é ela também minha irmã, filha de meu pai, mas não filha da minha mãe; e veio a ser minha mulher;',
        'E aconteceu que, fazendo-me Deus sair errante da casa de meu pai, eu lhe disse: Seja esta a graça que me farás em todo o lugar aonde chegarmos, dize de mim: É meu irmão.',
        'Então tomou Abimeleque ovelhas e vacas, e servos e servas, e os deu a Abraão; e restituiu-lhe Sara, sua mulher.',
        'E disse Abimeleque: Eis que a minha terra está diante da tua face; habita onde for bom aos teus olhos.',
        'E a Sara disse: Vês que tenho dado ao teu irmão mil moedas de prata; eis que ele te seja por véu dos olhos para com todos os que contigo estão, e até para com todos os outros; e estás advertida.',
        'E orou Abraão a Deus, e sarou Deus a Abimeleque, e à sua mulher, e às suas servas, de maneira que tiveram filhos;',
        'Porque o Senhor havia fechado totalmente todas as madres da casa de Abimeleque, por causa de Sara, mulher de Abraão.',
      ],
      [
        'E o SENHOR visitou a Sara, como tinha dito; e fez o SENHOR a Sara como tinha prometido.',
        'E concebeu Sara, e deu a Abraão um filho na sua velhice, ao tempo determinado, que Deus lhe tinha falado.',
        'E Abraão pôs no filho que lhe nascera, que Sara lhe dera, o nome de Isaque.',
        'E Abraão circuncidou o seu filho Isaque, quando era da idade de oito dias, como Deus lhe tinha ordenado.',
        'E era Abraão da idade de cem anos, quando lhe nasceu Isaque seu filho.',
        'E disse Sara: Deus me tem feito riso; todo aquele que o ouvir se rirá comigo.',
        'Disse mais: Quem diria a Abraão que Sara daria de mamar a filhos? Pois lhe dei um filho na sua velhice.',
        'E cresceu o menino, e foi desmamado; então Abraão fez um grande banquete no dia em que Isaque foi desmamado.',
        'E viu Sara que o filho de Agar, a egípcia, o qual tinha dado a Abraão, zombava.',
        'E disse a Abraão: Ponha fora esta serva e o seu filho; porque o filho desta serva não herdará com Isaque, meu filho.',
        'E pareceu esta palavra muito má aos olhos de Abraão, por causa de seu filho.',
        'Porém Deus disse a Abraão: Não te pareça mal aos teus olhos acerca do moço e acerca da tua serva; em tudo o que Sara te diz, ouve a sua voz; porque em Isaque será chamada a tua descendência.',
        'Mas também do filho desta serva farei uma nação, porquanto é tua descendência.',
        'Então se levantou Abraão pela manhã de madrugada, e tomou pão e um odre de água e os deu a Agar, pondo-os sobre o seu ombro; também lhe deu o menino e despediu-a; e ela partiu, andando errante no deserto de Berseba.',
        'E consumida a água do odre, lançou o menino debaixo de uma das árvores.',
        'E foi assentar-se em frente, afastando-se à distância de um tiro de arco; porque dizia: Que eu não veja morrer o menino. E assentou-se em frente, e levantou a sua voz, e chorou.',
        'E ouviu Deus a voz do menino, e bradou o anjo de Deus a Agar desde os céus, e disse-lhe: Que tens, Agar? Não temas, porque Deus ouviu a voz do menino desde o lugar onde está.',
        'Ergue-te, levanta o menino e pega-lhe pela mão, porque dele farei uma grande nação.',
        'E abriu-lhe Deus os olhos, e viu um poço de água; e foi encher o odre de água, e deu de beber ao menino.',
        'E era Deus com o menino, que cresceu; e habitou no deserto, e foi flecheiro.',
        'E habitou no deserto de Parã; e sua mãe tomou-lhe mulher da terra do Egito.',
        'E aconteceu naquele mesmo tempo que Abimeleque, com Ficol, príncipe do seu exército, falou com Abraão, dizendo: Deus é contigo em tudo o que fazes;',
        'Agora, pois, jura-me aqui por Deus, que não mentirás a mim, nem a meu filho, nem a meu neto; segundo a beneficência que te fiz, me farás a mim, e à terra onde peregrinaste.',
        'E disse Abraão: Eu jurarei.',
        'Abraão, porém, repreendeu a Abimeleque por causa de um poço de água, que os servos de Abimeleque haviam tomado à força.',
        'Então disse Abimeleque: Eu não sei quem fez isto; e também tu não mo fizeste saber, nem eu o ouvi senão hoje.',
        'E tomou Abraão ovelhas e vacas, e deu-as a Abimeleque; e fizeram ambos uma aliança.',
        'pôs Abraão, porém, à parte sete cordeiras do rebanho.',
        'E Abimeleque disse a Abraão: Para que estão aqui estas sete cordeiras, que puseste à parte?',
        'E disse: Tomarás estas sete cordeiras de minha mão, para que sejam em testemunho que eu cavei este poço.',
        'Por isso se chamou aquele lugar Berseba, porquanto ambos juraram ali.',
        'Assim fizeram aliança em Berseba. Depois se levantou Abimeleque e Ficol, príncipe do seu exército, e tornaram-se para a terra dos filisteus.',
        'E plantou um bosque em Berseba, e invocou lá o nome do Senhor, Deus eterno.',
        'E peregrinou Abraão na terra dos filisteus muitos dias.',
      ],
      [
        'E aconteceu depois destas coisas, que provou Deus a Abraão, e disse-lhe: Abraão! E ele disse: Eis-me aqui.',
        'E disse: Toma agora o teu filho, o teu único filho, Isaque, a quem amas, e vai-te à terra de Moriá, e oferece-o ali em holocausto sobre uma das montanhas, que eu te direi.',
        'Então se levantou Abraão pela manhã de madrugada, e albardou o seu jumento, e tomou consigo dois de seus moços e Isaque seu filho; e cortou lenha para o holocausto, e levantou-se, e foi ao lugar que Deus lhe dissera.',
        'Ao terceiro dia levantou Abraão os seus olhos, e viu o lugar de longe.',
        'E disse Abraão a seus moços: Ficai-vos aqui com o jumento, e eu e o moço iremos até ali; e havendo adorado, tornaremos a vós.',
        'E tomou Abraão a lenha do holocausto, e pô-la sobre Isaque seu filho; e ele tomou o fogo e o cutelo na sua mão, e foram ambos juntos.',
        'Então falou Isaque a Abraão seu pai, e disse: Meu pai! E ele disse: Eis-me aqui, meu filho! E ele disse: Eis aqui o fogo e a lenha, mas onde está o cordeiro para o holocausto?',
        'E disse Abraão: Deus proverá para si o cordeiro para o holocausto, meu filho. Assim caminharam ambos juntos.',
        'E chegaram ao lugar que Deus lhe dissera, e edificou Abraão ali um altar e pôs em ordem a lenha, e amarrou a Isaque seu filho, e deitou-o sobre o altar em cima da lenha.',
        'E estendeu Abraão a sua mão, e tomou o cutelo para imolar o seu filho;',
        'Mas o anjo do Senhor lhe bradou desde os céus, e disse: Abraão, Abraão! E ele disse: Eis-me aqui.',
        'Então disse: Não estendas a tua mão sobre o moço, e não lhe faças nada; porquanto agora sei que temes a Deus, e não me negaste o teu filho, o teu único filho.',
        'Então levantou Abraão os seus olhos e olhou; e eis um carneiro detrás dele, travado pelos seus chifres, num mato; e foi Abraão, e tomou o carneiro, e ofereceu-o em holocausto, em lugar de seu filho.',
        'E chamou Abraão o nome daquele lugar: o Senhor proverá; donde se diz até ao dia de hoje: No monte do Senhor se proverá.',
        'Então o anjo do Senhor bradou a Abraão pela segunda vez desde os céus,',
        'E disse: Por mim mesmo jurei, diz o Senhor: Porquanto fizeste esta ação, e não me negaste o teu filho, o teu único filho,',
        'Que deveras te abençoarei, e grandissimamente multiplicarei a tua descendência como as estrelas dos céus, e como a areia que está na praia do mar; e a tua descendência possuirá a porta dos seus inimigos;',
        'E em tua descendência serão benditas todas as nações da terra; porquanto obedeceste à minha voz.',
        'Então Abraão tornou aos seus moços, e levantaram-se, e foram juntos para Berseba; e Abraão habitou em Berseba.',
        'E sucedeu depois destas coisas, que anunciaram a Abraão, dizendo: Eis que também Milca deu filhos a Naor teu irmão.',
        'Uz o seu primogênito, e Buz seu irmão, e Quemuel, pai de Arã,',
        'E Quésede, e Hazo, e Pildas, e Jidlafe, e Betuel.',
        'E Betuel gerou Rebeca. Estes oito deu à luz Milca a Naor, irmão de Abraão.',
        'E a sua concubina, cujo nome era Reumá, ela lhe deu também a Tebá, Gaã, Taás e Maaca.',
      ],
      [
        'E foi a vida de Sara cento e vinte e sete anos; estes foram os anos da vida de Sara.',
        'E morreu Sara em Quiriate-Arba, que é Hebrom, na terra de Canaã; e veio Abraão lamentar Sara e chorar por ela.',
        'Depois se levantou Abraão de diante de sua morta, e falou aos filhos de Hete, dizendo:',
        'Estrangeiro e peregrino sou entre vós; dai-me possessão de sepultura convosco, para que eu sepulte a minha morta de diante da minha face.',
        'E responderam os filhos de Hete a Abraão, dizendo-lhe:',
        'Ouve-nos, meu senhor; príncipe poderoso és no meio de nós; enterra a tua morta na mais escolhida de nossas sepulturas; nenhum de nós te vedará a sua sepultura, para enterrar a tua morta.',
        'Então se levantou Abraão, inclinou-se diante do povo da terra, diante dos filhos de Hete.',
        'E falou com eles, dizendo: Se é de vossa vontade que eu sepulte a minha morta de diante de minha face, ouvi-me e falai por mim a Efrom, filho de Zoar,',
        'Que ele me dê a cova de Macpela, que ele tem no fim do seu campo; que ma dê pelo devido preço em herança de sepulcro no meio de vós.',
        'Ora Efrom habitava no meio dos filhos de Hete; e respondeu Efrom, heteu, a Abraão, aos ouvidos dos filhos de Hete, de todos os que entravam pela porta da sua cidade, dizendo:',
        'Não, meu senhor, ouve-me: O campo te dou, também te dou a cova que nele está, diante dos olhos dos filhos do meu povo ta dou; sepulta a tua morta.',
        'Então Abraão se inclinou diante da face do povo da terra,',
        'E falou a Efrom, aos ouvidos do povo da terra, dizendo: Mas se tu estás por isto, ouve-me, peço-te. O preço do campo o darei; toma-o de mim e sepultarei ali a minha morta.',
        'E respondeu Efrom a Abraão, dizendo-lhe:',
        'Meu senhor, ouve-me, a terra é de quatrocentos siclos de prata; que é isto entre mim e ti? Sepulta a tua morta.',
        'E Abraão deu ouvidos a Efrom, e Abraão pesou a Efrom a prata de que tinha falado aos ouvidos dos filhos de Hete, quatrocentos siclos de prata, corrente entre mercadores.',
        'Assim o campo de Efrom, que estava em Macpela, em frente de Manre, o campo e a cova que nele estava, e todo o arvoredo que no campo havia, que estava em todo o seu contorno ao redor,',
        'Se confirmou a Abraão em possessão diante dos olhos dos filhos de Hete, de todos os que entravam pela porta da cidade.',
        'E depois sepultou Abraão a Sara sua mulher na cova do campo de Macpela, em frente de Manre, que é Hebrom, na terra de Canaã.',
        'Assim o campo e a cova que nele estava foram confirmados a Abraão, pelos filhos de Hete, em possessão de sepultura.',
      ],
      [
        'E era Abraão já velho e adiantado em idade, e o SENHOR havia abençoado a Abraão em tudo.',
        'E disse Abraão ao seu servo, o mais velho da casa, que tinha o governo sobre tudo o que possuía: Põe agora a tua mão debaixo da minha coxa,',
        'Para que eu te faça jurar pelo Senhor Deus dos céus e Deus da terra, que não tomarás para meu filho mulher das filhas dos cananeus, no meio dos quais eu habito.',
        'Mas que irás à minha terra e à minha parentela, e dali tomarás mulher para meu filho Isaque.',
        'E disse-lhe o servo: Se porventura não quiser seguir-me a mulher a esta terra, farei, pois, tornar o teu filho à terra donde saíste?',
        'E Abraão lhe disse: Guarda-te, que não faças lá tornar o meu filho.',
        'O Senhor Deus dos céus, que me tomou da casa de meu pai e da terra da minha parentela, e que me falou, e que me jurou, dizendo: À tua descendência darei esta terra; ele enviará o seu anjo adiante da tua face, para que tomes mulher de lá para meu filho.',
        'Se a mulher, porém, não quiser seguir-te, serás livre deste meu juramento; somente não faças lá tornar a meu filho.',
        'Então pôs o servo a sua mão debaixo da coxa de Abraão seu senhor, e jurou-lhe sobre este negócio.',
        'E o servo tomou dez camelos, dos camelos do seu senhor, e partiu, pois que todos os bens de seu senhor estavam em sua mão, e levantou-se e partiu para Mesopotâmia, para a cidade de Naor.',
        'E fez ajoelhar os camelos fora da cidade, junto a um poço de água, pela tarde, ao tempo que as moças saíam a tirar água.',
        'E disse: Ó Senhor, Deus de meu senhor Abraão, dá-me hoje bom encontro, e faze beneficência ao meu senhor Abraão!',
        'Eis que eu estou em pé junto à fonte de água e as filhas dos homens desta cidade saem para tirar água;',
        'Seja, pois, que a donzela, a quem eu disser: Abaixa agora o teu cântaro para que eu beba; e ela disser: Bebe, e também darei de beber aos teus camelos; esta seja a quem designaste ao teu servo Isaque, e que eu conheça nisso que usaste de benevolência com meu senhor.',
        'E sucedeu que, antes que ele acabasse de falar, eis que Rebeca, que havia nascido a Betuel, filho de Milca, mulher de Naor, irmão de Abraão, saía com o seu cântaro sobre o seu ombro.',
        'E a donzela era mui formosa à vista, virgem, a quem homem não havia conhecido; e desceu à fonte, e encheu o seu cântaro e subiu.',
        'Então o servo correu-lhe ao encontro, e disse: Peço-te, deixa-me beber um pouco de água do teu cântaro.',
        'E ela disse: Bebe, meu senhor. E apressou-se e abaixou o seu cântaro sobre a sua mão e deu-lhe de beber.',
        'E, acabando ela de lhe dar de beber, disse: Tirarei também água para os teus camelos, até que acabem de beber.',
        'E apressou-se, e despejou o seu cântaro no bebedouro, e correu outra vez ao poço para tirar água, e tirou para todos os seus camelos.',
        'E o homem estava admirado de vê-la, calando-se, para saber se o Senhor havia prosperado a sua jornada ou não.',
        'E aconteceu que, acabando os camelos de beber, tomou o homem um pendente de ouro de meio siclo de peso, e duas pulseiras para as suas mãos, do peso de dez siclos de ouro;',
        'E disse: De quem és filha? Faze-mo saber, peço-te. Há também em casa de teu pai lugar para nós pousarmos?',
        'E ela lhe disse: Eu sou a filha de Betuel, filho de Milca, o qual ela deu a Naor.',
        'Disse-lhe mais: Também temos palha e muito pasto, e lugar para passar a noite.',
        'Então inclinou-se aquele homem e adorou ao Senhor,',
        'E disse: Bendito seja o SENHOR Deus de meu senhor Abraão, que não retirou a sua benevolência e a sua verdade de meu senhor; quanto a mim, o SENHOR me guiou no caminho à casa dos irmãos de meu senhor.',
        'E a donzela correu, e fez saber estas coisas na casa de sua mãe.',
        'E Rebeca tinha um irmão cujo nome era Labão, o qual correu ao encontro daquele homem até a fonte.',
        'E aconteceu que, quando ele viu o pendente, e as pulseiras sobre as mãos de sua irmã, e quando ouviu as palavras de sua irmã Rebeca, que dizia: Assim me falou aquele homem; foi ter com o homem, que estava em pé junto aos camelos, à fonte,',
        'E disse: Entra, bendito do Senhor; por que estás fora? pois eu já preparei a casa, e o lugar para os camelos.',
        'Então veio aquele homem à casa, e desataram os camelos, e deram palha e pasto aos camelos, e água para lavar os pés dele, e os pés dos homens que estavam com ele.',
        'Depois puseram comida diante dele. Ele, porém, disse: Não comerei, até que tenha dito as minhas palavras. E ele disse: Fala.',
        'Então disse: Eu sou o servo de Abraão.',
        'E o SENHOR abençoou muito o meu senhor, de maneira que foi engrandecido, e deu-lhe ovelhas e vacas, e prata e ouro, e servos e servas, e camelos e jumentos.',
        'E Sara, a mulher do meu senhor, deu à luz um filho a meu senhor depois da sua velhice, e ele deu-lhe tudo quanto tem.',
        'E meu senhor me fez jurar, dizendo: Não tomarás mulher para meu filho das filhas dos cananeus, em cuja terra habito;',
        'Irás, porém, à casa de meu pai, e à minha família, e tomarás mulher para meu filho.',
        'Então disse eu ao meu senhor: Porventura não me seguirá a mulher.',
        'E ele me disse: O Senhor, em cuja presença tenho andado, enviará o seu anjo contigo, e prosperará o teu caminho, para que tomes mulher para meu filho da minha família e da casa de meu pai;',
        'Então serás livre do meu juramento, quando fores à minha família; e se não te derem, livre serás do meu juramento.',
        'E hoje cheguei à fonte, e disse: Ó Senhor, Deus de meu senhor Abraão, se tu agora prosperas o meu caminho, no qual eu ando,',
        'Eis que estou junto à fonte de água; seja, pois, que a donzela que sair para tirar água e à qual eu disser: Peço-te, dá-me um pouco de água do teu cântaro;',
        'E ela me disser: Bebe tu e também tirarei água para os teus camelos; esta seja a mulher que o SENHOR designou ao filho de meu senhor.',
        'E antes que eu acabasse de falar no meu coração, eis que Rebeca saía com o seu cântaro sobre o seu ombro, desceu à fonte e tirou água; e eu lhe disse: Peço-te, dá-me de beber.',
        'E ela se apressou, e abaixou o seu cântaro de sobre si, e disse: Bebe, e também darei de beber aos teus camelos; e bebi, e ela deu também de beber aos camelos.',
        'Então lhe perguntei, e disse: De quem és filha? E ela disse: Filha de Betuel, filho de Naor, que lhe deu Milca. Então eu pus o pendente no seu rosto, e as pulseiras sobre as suas mãos;',
        'E inclinando-me adorei ao SENHOR, e bendisse ao SENHOR, Deus do meu senhor Abraão, que me havia encaminhado pelo caminho da verdade, para tomar a filha do irmão de meu senhor para seu filho.',
        'Agora, pois, se vós haveis de fazer benevolência e verdade a meu senhor, fazei-mo saber; e se não, também mo fazei saber, para que eu vá à direita, ou à esquerda.',
        'Então responderam Labão e Betuel, e disseram: Do Senhor procedeu este negócio; não podemos falar-te mal ou bem.',
        'Eis que Rebeca está diante da tua face; toma-a, e vai-te; seja a mulher do filho de teu senhor, como tem dito o SENHOR.',
        'E aconteceu que, o servo de Abraão, ouvindo as suas palavras, inclinou-se à terra diante do Senhor.',
        'E tirou o servo jóias de prata e jóias de ouro, e vestidos, e deu-os a Rebeca; também deu coisas preciosas a seu irmão e à sua mãe.',
        'Então comeram e beberam, ele e os homens que com ele estavam, e passaram a noite. E levantaram-se pela manhã, e disse: Deixai-me ir a meu senhor.',
        'Então disseram seu irmão e sua mãe: Fique a donzela conosco alguns dias, ou pelo menos dez dias, depois irá.',
        'Ele, porém, lhes disse: Não me detenhais, pois o SENHOR tem prosperado o meu caminho; deixai-me partir, para que eu volte a meu senhor.',
        'E disseram: Chamemos a donzela, e perguntemos-lho.',
        'E chamaram a Rebeca, e disseram-lhe: Irás tu com este homem? Ela respondeu: Irei.',
        'Então despediram a Rebeca, sua irmã, e sua ama, e o servo de Abraão, e seus homens.',
        'E abençoaram a Rebeca, e disseram-lhe: Ó nossa irmã, sê tu a mãe de milhares de milhares, e que a tua descendência possua a porta de seus aborrecedores!',
        'E Rebeca se levantou com as suas moças, e subiram sobre os camelos, e seguiram o homem; e tomou aquele servo a Rebeca, e partiu.',
        'Ora, Isaque vinha de onde se vem do poço de Beer-Laai-Rói; porque habitava na terra do sul.',
        'E Isaque saíra a orar no campo, à tarde; e levantou os seus olhos, e olhou, e eis que os camelos vinham.',
        'Rebeca também levantou seus olhos, e viu a Isaque, e desceu do camelo.',
        'E disse ao servo: Quem é aquele homem que vem pelo campo ao nosso encontro? E o servo disse: Este é meu senhor. Então tomou ela o véu e cobriu-se.',
        'E o servo contou a Isaque todas as coisas que fizera.',
        'E Isaque trouxe-a para a tenda de sua mãe Sara, e tomou a Rebeca, e foi-lhe por mulher, e amou-a. Assim Isaque foi consolado depois da morte de sua mãe.',
      ],
      [
        'E Abraão tomou outra mulher; e o seu nome era Quetura;',
        'E deu-lhe à luz Zinrã, Jocsã, Medã, Midiã, Jisbaque e Suá.',
        'E Jocsã gerou Seba e Dedã; e os filhos de Dedã foram Assurim, Letusim e Leumim.',
        'E os filhos de Midiã foram Efá, Efer, Enoque, Abida e Elda. Estes todos foram filhos de Quetura.',
        'Porém Abraão deu tudo o que tinha a Isaque;',
        'Mas aos filhos das concubinas que Abraão tinha, deu Abraão presentes e, vivendo ele ainda, despediu-os do seu filho Isaque, enviando-os ao oriente, para a terra oriental.',
        'Estes, pois, são os dias dos anos da vida de Abraão, que viveu cento e setenta e cinco anos.',
        'E Abraão expirou, morrendo em boa velhice, velho e farto de dias; e foi congregado ao seu povo;',
        'E Isaque e Ismael, seus filhos, sepultaram-no na cova de Macpela, no campo de Efrom, filho de Zoar, heteu, que estava em frente de Manre,',
        'O campo que Abraão comprara aos filhos de Hete. Ali está sepultado Abraão e Sara, sua mulher.',
        'E aconteceu depois da morte de Abraão, que Deus abençoou a Isaque seu filho; e habitava Isaque junto ao poço Beer-Laai-Rói.',
        'Estas, porém, são as gerações de Ismael filho de Abraão, que a serva de Sara, Agar, egípcia, deu a Abraão.',
        'E estes são os nomes dos filhos de Ismael, pelos seus nomes, segundo as suas gerações: O primogênito de Ismael era Nebaiote, depois Quedar, Adbeel e Mibsão,',
        'Misma, Dumá, Massá,',
        'Hadade, Tema, Jetur, Nafis e Quedemá.',
        'Estes são os filhos de Ismael, e estes são os seus nomes pelas suas vilas e pelos seus castelos; doze príncipes segundo as suas famílias.',
        'E estes são os anos da vida de Ismael, cento e trinta e sete anos, e ele expirou e, morrendo, foi congregado ao seu povo.',
        'E habitaram desde Havilá até Sur, que está em frente do Egito, como quem vai para a Assíria; e fez o seu assento diante da face de todos os seus irmãos.',
        'E estas são as gerações de Isaque, filho de Abraão: Abraão gerou a Isaque;',
        'E era Isaque da idade de quarenta anos, quando tomou por mulher a Rebeca, filha de Betuel, arameu de Padã-Arã, irmã de Labão, arameu.',
        'E Isaque orou insistentemente ao Senhor por sua mulher, porquanto era estéril; e o Senhor ouviu as suas orações, e Rebeca sua mulher concebeu.',
        'E os filhos lutavam dentro dela; então disse: Se assim é, por que sou eu assim? E foi perguntar ao Senhor.',
        'E o Senhor lhe disse: Duas nações há no teu ventre, e dois povos se dividirão das tuas entranhas, e um povo será mais forte do que o outro povo, e o maior servirá ao menor.',
        'E cumprindo-se os seus dias para dar à luz, eis gêmeos no seu ventre.',
        'E saiu o primeiro ruivo e todo como um vestido de pêlo; por isso chamaram o seu nome Esaú.',
        'E depois saiu o seu irmão, agarrada sua mão ao calcanhar de Esaú; por isso se chamou o seu nome Jacó. E era Isaque da idade de sessenta anos quando os gerou.',
        'E cresceram os meninos, e Esaú foi homem perito na caça, homem do campo; mas Jacó era homem simples, habitando em tendas.',
        'E amava Isaque a Esaú, porque a caça era de seu gosto, mas Rebeca amava a Jacó.',
        'E Jacó cozera um guisado; e veio Esaú do campo, e estava ele cansado;',
        'E disse Esaú a Jacó: Deixa-me, peço-te, comer desse guisado vermelho, porque estou cansado. Por isso se chamou Edom.',
        'Então disse Jacó: Vende-me hoje a tua primogenitura.',
        'E disse Esaú: Eis que estou a ponto de morrer; para que me servirá a primogenitura?',
        'Então disse Jacó: Jura-me hoje. E jurou-lhe e vendeu a sua primogenitura a Jacó.',
        'E Jacó deu pão a Esaú e o guisado de lentilhas; e ele comeu, e bebeu, e levantou-se, e saiu. Assim desprezou Esaú a sua primogenitura.',
      ],
      [
        'E havia fome na terra, além da primeira fome, que foi nos dias de Abraão; por isso foi Isaque a Abimeleque, rei dos filisteus, em Gerar.',
        'E apareceu-lhe o Senhor, e disse: Não desças ao Egito; habita na terra que eu te disser;',
        'Peregrina nesta terra, e serei contigo, e te abençoarei; porque a ti e à tua descendência darei todas estas terras, e confirmarei o juramento que tenho jurado a Abraão teu pai;',
        'E multiplicarei a tua descendência como as estrelas dos céus, e darei à tua descendência todas estas terras; e por meio dela serão benditas todas as nações da terra;',
        'Porquanto Abraão obedeceu à minha voz, e guardou o meu mandado, os meus preceitos, os meus estatutos, e as minhas leis.',
        'Assim habitou Isaque em Gerar.',
        'E perguntando-lhe os homens daquele lugar acerca de sua mulher, disse: É minha irmã; porque temia dizer: É minha mulher; para que porventura (dizia ele) não me matem os homens daquele lugar por amor de Rebeca; porque era formosa à vista.',
        'E aconteceu que, como ele esteve ali muito tempo, Abimeleque, rei dos filisteus, olhou por uma janela, e viu, e eis que Isaque estava brincando com Rebeca sua mulher.',
        'Então chamou Abimeleque a Isaque, e disse: Eis que na verdade é tua mulher; como pois disseste: É minha irmã? E disse-lhe Isaque: Porque eu dizia: Para que eu porventura não morra por causa dela.',
        'E disse Abimeleque: Que é isto que nos fizeste? Facilmente se teria deitado alguém deste povo com a tua mulher, e tu terias trazido sobre nós um delito.',
        'E mandou Abimeleque a todo o povo, dizendo: Qualquer que tocar neste homem ou em sua mulher, certamente morrerá.',
        'E semeou Isaque naquela mesma terra, e colheu naquele mesmo ano cem medidas, porque o Senhor o abençoava.',
        'E engrandeceu-se o homem, e ia enriquecendo-se, até que se tornou mui poderoso.',
        'E tinha possessão de ovelhas, e possessão de vacas, e muita gente de serviço, de maneira que os filisteus o invejavam.',
        'E todos os poços, que os servos de seu pai tinham cavado nos dias de seu pai Abraão, os filisteus entulharam e encheram de terra.',
        'Disse também Abimeleque a Isaque: Aparta-te de nós; porque muito mais poderoso te tens feito do que nós.',
        'Então Isaque partiu dali e fez o seu acampamento no vale de Gerar, e habitou lá.',
        'E tornou Isaque e cavou os poços de água que cavaram nos dias de Abraão seu pai, e que os filisteus entulharam depois da morte de Abraão, e chamou-os pelos nomes que os chamara seu pai.',
        'Cavaram, pois, os servos de Isaque naquele vale, e acharam ali um poço de águas vivas.',
        'E os pastores de Gerar porfiaram com os pastores de Isaque, dizendo: Esta água é nossa. Por isso chamou aquele poço Eseque, porque contenderam com ele.',
        'Então cavaram outro poço, e também porfiaram sobre ele; por isso chamou-o Sitna.',
        'E partiu dali, e cavou outro poço, e não porfiaram sobre ele; por isso chamou-o Reobote, e disse: Porque agora nos alargou o Senhor, e crescemos nesta terra.',
        'Depois subiu dali a Berseba.',
        'E apareceu-lhe o Senhor naquela mesma noite, e disse: Eu sou o Deus de Abraão teu pai; não temas, porque eu sou contigo, e abençoar-te-ei, e multiplicarei a tua descendência por amor de Abraão meu servo.',
        'Então edificou ali um altar, e invocou o nome do Senhor, e armou ali a sua tenda; e os servos de Isaque cavaram ali um poço.',
        'E Abimeleque veio a ele de Gerar, com Auzate seu amigo, e Ficol, príncipe do seu exército.',
        'E disse-lhes Isaque: Por que viestes a mim, pois que vós me odiais e me repelistes de vós?',
        'E eles disseram: Havemos visto, na verdade, que o Senhor é contigo, por isso dissemos: Haja agora juramento entre nós, entre nós e ti; e façamos aliança contigo.',
        'Que não nos faças mal, como nós te não temos tocado, e como te fizemos somente bem, e te deixamos ir em paz. Agora tu és o bendito do Senhor.',
        'Então lhes fez um banquete, e comeram e beberam;',
        'E levantaram-se de madrugada e juraram um ao outro; depois os despediu Isaque, e despediram-se dele em paz.',
        'E aconteceu, naquele mesmo dia, que vieram os servos de Isaque, e anunciaram-lhe acerca do negócio do poço, que tinham cavado; e disseram-lhe: Temos achado água.',
        'E chamou-o Seba; por isso é o nome daquela cidade Berseba até o dia de hoje.',
        'Ora, sendo Esaú da idade de quarenta anos, tomou por mulher a Judite, filha de Beeri, heteu, e a Basemate, filha de Elom, heteu.',
        'E estas foram para Isaque e Rebeca uma amargura de espírito.',
      ],
      [
        'E aconteceu que, como Isaque envelheceu, e os seus olhos se escureceram, de maneira que não podia ver, chamou a Esaú, seu filho mais velho, e disse-lhe: Meu filho. E ele lhe disse: Eis-me aqui.',
        'E ele disse: Eis que já agora estou velho, e não sei o dia da minha morte;',
        'Agora, pois, toma as tuas armas, a tua aljava e o teu arco, e sai ao campo, e apanha para mim alguma caça.',
        'E faze-me um guisado saboroso, como eu gosto, e traze-mo, para que eu coma; para que minha alma te abençoe, antes que morra.',
        'E Rebeca escutou quando Isaque falava ao seu filho Esaú. E foi Esaú ao campo para apanhar a caça que havia de trazer.',
        'Então falou Rebeca a Jacó seu filho, dizendo: Eis que tenho ouvido o teu pai que falava com Esaú teu irmão, dizendo:',
        'Traze-me caça, e faze-me um guisado saboroso, para que eu coma, e te abençoe diante da face do Senhor, antes da minha morte.',
        'Agora, pois, filho meu, ouve a minha voz naquilo que eu te mando:',
        'Vai agora ao rebanho, e traze-me de lá dois bons cabritos, e eu farei deles um guisado saboroso para teu pai, como ele gosta;',
        'E levá-lo-ás a teu pai, para que o coma; para que te abençoe antes da sua morte.',
        'Então disse Jacó a Rebeca, sua mãe: Eis que Esaú meu irmão é homem cabeludo, e eu homem liso;',
        'Porventura me apalpará o meu pai, e serei aos seus olhos como enganador; assim trarei eu sobre mim maldição, e não bênção.',
        'E disse-lhe sua mãe: Meu filho, sobre mim seja a tua maldição; somente obedece à minha voz, e vai, traze-mos.',
        'E foi, e tomou-os, e trouxe-os a sua mãe; e sua mãe fez um guisado saboroso, como seu pai gostava.',
        'Depois tomou Rebeca os vestidos de gala de Esaú, seu filho mais velho, que tinha consigo em casa, e vestiu a Jacó, seu filho menor;',
        'E com as peles dos cabritos cobriu as suas mãos e a lisura do seu pescoço;',
        'E deu o guisado saboroso e o pão que tinha preparado, na mão de Jacó seu filho.',
        'E foi ele a seu pai, e disse: Meu pai! E ele disse: Eis-me aqui; quem és tu, meu filho?',
        'E Jacó disse a seu pai: Eu sou Esaú, teu primogênito; tenho feito como me disseste; levanta-te agora, assenta-te e come da minha caça, para que a tua alma me abençoe.',
        'Então disse Isaque a seu filho: Como é isto, que tão cedo a achaste, filho meu? E ele disse: Porque o Senhor teu Deus a mandou ao meu encontro.',
        'E disse Isaque a Jacó: Chega-te agora, para que te apalpe, meu filho, se és meu filho Esaú mesmo, ou não.',
        'Então se chegou Jacó a Isaque seu pai, que o apalpou, e disse: A voz é a voz de Jacó, porém as mãos são as mãos de Esaú.',
        'E não o conheceu, porquanto as suas mãos estavam cabeludas, como as mãos de Esaú seu irmão; e abençoou-o.',
        'E disse: És tu meu filho Esaú mesmo? E ele disse: Eu sou.',
        'Então disse: Faze chegar isso perto de mim, para que coma da caça de meu filho; para que a minha alma te abençoe. E chegou-lhe, e comeu; trouxe-lhe também vinho, e bebeu.',
        'E disse-lhe Isaque seu pai: Ora chega-te, e beija-me, filho meu.',
        'E chegou-se, e beijou-o; então sentindo o cheiro das suas vestes, abençoou-o, e disse: Eis que o cheiro do meu filho é como o cheiro do campo, que o Senhor abençoou;',
        'Assim, pois, te dê Deus do orvalho dos céus, e das gorduras da terra, e abundância de trigo e de mosto.',
        'Sirvam-te povos, e nações se encurvem a ti; sê senhor de teus irmãos, e os filhos da tua mãe se encurvem a ti; malditos sejam os que te amaldiçoarem, e benditos sejam os que te abençoarem.',
        'E aconteceu que, acabando Isaque de abençoar a Jacó, apenas Jacó acabava de sair da presença de Isaque seu pai, veio Esaú, seu irmão, da sua caça;',
        'E fez também ele um guisado saboroso, e trouxe-o a seu pai; e disse a seu pai: Levanta-te, meu pai, e come da caça de teu filho, para que me abençoe a tua alma.',
        'E disse-lhe Isaque seu pai: Quem és tu? E ele disse: Eu sou teu filho, o teu primogênito Esaú.',
        'Então estremeceu Isaque de um estremecimento muito grande, e disse: Quem, pois, é aquele que apanhou a caça, e ma trouxe? E comi de tudo, antes que tu viesses, e abençoei-o, e ele será bendito.',
        'Esaú, ouvindo as palavras de seu pai, bradou com grande e mui amargo brado, e disse a seu pai: Abençoa-me também a mim, meu pai.',
        'E ele disse: Veio teu irmão com sutileza, e tomou a tua bênção.',
        'Então disse ele: Não é o seu nome justamente Jacó, tanto que já duas vezes me enganou? A minha primogenitura me tomou, e eis que agora me tomou a minha bênção. E perguntou: Não reservaste, pois, para mim nenhuma bênção?',
        'Então respondeu Isaque a Esaú dizendo: Eis que o tenho posto por senhor sobre ti, e todos os seus irmãos lhe tenho dado por servos; e de trigo e de mosto o tenho fortalecido; que te farei, pois, agora, meu filho?',
        'E disse Esaú a seu pai: Tens uma só bênção, meu pai? Abençoa-me também a mim, meu pai. E levantou Esaú a sua voz, e chorou.',
        'Então respondeu Isaque, seu pai, e disse-lhe: Eis que a tua habitação será nas gorduras da terra e no orvalho dos altos céus.',
        'E pela tua espada viverás, e ao teu irmão servirás. Acontecerá, porém, que quando te assenhoreares, então sacudirás o seu jugo do teu pescoço.',
        'E Esaú odiou a Jacó por causa daquela bênção, com que seu pai o tinha abençoado; e Esaú disse no seu coração: Chegar-se-ão os dias de luto de meu pai; e matarei a Jacó meu irmão.',
        'E foram denunciadas a Rebeca estas palavras de Esaú, seu filho mais velho; e ela mandou chamar a Jacó, seu filho menor, e disse-lhe: Eis que Esaú teu irmão se consola a teu respeito, propondo matar-te.',
        'Agora, pois, meu filho, ouve a minha voz, e levanta-te; acolhe-te a Labão meu irmão, em Harã,',
        'E mora com ele alguns dias, até que passe o furor de teu irmão;',
        'Até que se desvie de ti a ira de teu irmão, e se esqueça do que lhe fizeste; então mandarei trazer-te de lá; por que seria eu desfilhada também de vós ambos num mesmo dia?',
        'E disse Rebeca a Isaque: Enfadada estou da minha vida, por causa das filhas de Hete; se Jacó tomar mulher das filhas de Hete, como estas são, das filhas desta terra, para que me servirá a vida?',
      ],
      [
        'E Isaque chamou a Jacó, e abençoou-o, e ordenou-lhe, e disse-lhe: Não tomes mulher de entre as filhas de Canaã;',
        'Levanta-te, vai a Padã-Arã, à casa de Betuel, pai de tua mãe, e toma de lá uma mulher das filhas de Labão, irmão de tua mãe;',
        'E Deus Todo-Poderoso te abençoe, e te faça frutificar, e te multiplique, para que sejas uma multidão de povos;',
        'E te dê a bênção de Abraão, a ti e à tua descendência contigo, para que em herança possuas a terra de tuas peregrinações, que Deus deu a Abraão.',
        'Assim despediu Isaque a Jacó, o qual se foi a Padã-Arã, a Labão, filho de Betuel, arameu, irmão de Rebeca, mãe de Jacó e de Esaú.',
        'Vendo, pois, Esaú que Isaque abençoara a Jacó, e o enviara a Padã-Arã, para tomar mulher dali para si, e que, abençoando-o, lhe ordenara, dizendo: Não tomes mulher das filhas de Canaã;',
        'E que Jacó obedecera a seu pai e a sua mãe, e se fora a Padã-Arã;',
        'Vendo também Esaú que as filhas de Canaã eram más aos olhos de Isaque seu pai,',
        'Foi Esaú a Ismael, e tomou para si por mulher, além das suas mulheres, a Maalate filha de Ismael, filho de Abraão, irmã de Nebaiote.',
        'Partiu, pois, Jacó de Berseba, e foi a Harã;',
        'E chegou a um lugar onde passou a noite, porque já o sol era posto; e tomou uma das pedras daquele lugar, e a pôs por seu travesseiro, e deitou-se naquele lugar.',
        'E sonhou: e eis uma escada posta na terra, cujo topo tocava nos céus; e eis que os anjos de Deus subiam e desciam por ela;',
        'E eis que o Senhor estava em cima dela, e disse: Eu sou o Senhor Deus de Abraão teu pai, e o Deus de Isaque; esta terra, em que estás deitado, darei a ti e à tua descendência;',
        'E a tua descendência será como o pó da terra, e estender-se-á ao ocidente, e ao oriente, e ao norte, e ao sul, e em ti e na tua descendência serão benditas todas as famílias da terra;',
        'E eis que estou contigo, e te guardarei por onde quer que fores, e te farei tornar a esta terra; porque não te deixarei, até que haja cumprido o que te tenho falado.',
        'Acordando, pois, Jacó do seu sono, disse: Na verdade o Senhor está neste lugar; e eu não o sabia.',
        'E temeu, e disse: Quão terrível é este lugar! Este não é outro lugar senão a casa de Deus; e esta é a porta dos céus.',
        'Então levantou-se Jacó pela manhã de madrugada, e tomou a pedra que tinha posto por seu travesseiro, e a pôs por coluna, e derramou azeite em cima dela.',
        'E chamou o nome daquele lugar Betel; o nome porém daquela cidade antes era Luz.',
        'E Jacó fez um voto, dizendo: Se Deus for comigo, e me guardar nesta viagem que faço, e me der pão para comer, e vestes para vestir;',
        'E eu em paz tornar à casa de meu pai, o Senhor me será por Deus;',
        'E esta pedra que tenho posto por coluna será casa de Deus; e de tudo quanto me deres, certamente te darei o dízimo.',
      ],
      [
        'Então pôs-se Jacó a caminho e foi à terra do povo do oriente;',
        'E olhou, e eis um poço no campo, e eis três rebanhos de ovelhas que estavam deitados junto a ele; porque daquele poço davam de beber aos rebanhos; e havia uma grande pedra sobre a boca do poço.',
        'E ajuntavam ali todos os rebanhos, e removiam a pedra de sobre a boca do poço, e davam de beber às ovelhas; e tornavam a pôr a pedra sobre a boca do poço, no seu lugar.',
        'E disse-lhes Jacó: Meus irmãos, donde sois? E disseram: Somos de Harã.',
        'E ele lhes disse: Conheceis a Labão, filho de Naor? E disseram: Conhecemos.',
        'Disse-lhes mais: Está ele bem? E disseram: Está bem, e eis aqui Raquel sua filha, que vem com as ovelhas.',
        'E ele disse: Eis que ainda é pleno dia, não é tempo de ajuntar o gado; dai de beber às ovelhas, e ide apascentá-las.',
        'E disseram: Não podemos, até que todos os rebanhos se ajuntem, e removam a pedra de sobre a boca do poço, para que demos de beber às ovelhas.',
        'Estando ele ainda falando com eles, veio Raquel com as ovelhas de seu pai; porque ela era pastora.',
        'E aconteceu que, vendo Jacó a Raquel, filha de Labão, irmão de sua mãe, e as ovelhas de Labão, irmão de sua mãe, chegou Jacó, e revolveu a pedra de sobre a boca do poço e deu de beber às ovelhas de Labão, irmão de sua mãe.',
        'E Jacó beijou a Raquel, e levantou a sua voz e chorou.',
        'E Jacó anunciou a Raquel que era irmão de seu pai, e que era filho de Rebeca; então ela correu, e o anunciou a seu pai.',
        'E aconteceu que, ouvindo Labão as novas de Jacó, filho de sua irmã, correu-lhe ao encontro, e abraçou-o, e beijou-o, e levou-o à sua casa; e ele contou a Labão todas estas coisas.',
        'Então Labão disse-lhe: Verdadeiramente és tu o meu osso e a minha carne. E ficou com ele um mês inteiro.',
        'Depois disse Labão a Jacó: Porque tu és meu irmão, hás de servir-me de graça? Declara-me qual será o teu salário.',
        'E Labão tinha duas filhas; o nome da mais velha era Lia, e o nome da menor Raquel.',
        'Lia tinha olhos tenros, mas Raquel era de formoso semblante e formosa à vista.',
        'E Jacó amava a Raquel, e disse: Sete anos te servirei por Raquel, tua filha menor.',
        'Então disse Labão: Melhor é que eu a dê a ti, do que eu a dê a outro homem; fica comigo.',
        'Assim serviu Jacó sete anos por Raquel; e estes lhe pareceram como poucos dias, pelo muito que a amava.',
        'E disse Jacó a Labão: Dá-me minha mulher, porque meus dias são cumpridos, para que eu me case com ela.',
        'Então reuniu Labão a todos os homens daquele lugar, e fez um banquete.',
        'E aconteceu, à tarde, que tomou Lia, sua filha, e trouxe-a a Jacó que a possuiu.',
        'E Labão deu sua serva Zilpa a Lia, sua filha, por serva.',
        'E aconteceu que pela manhã, viu que era Lia; pelo que disse a Labão: Por que me fizeste isso? Não te tenho servido por Raquel? Por que então me enganaste?',
        'E disse Labão: Não se faz assim no nosso lugar, que a menor se dê antes da primogênita.',
        'Cumpre a semana desta; então te daremos também a outra, pelo serviço que ainda outros sete anos comigo servires.',
        'E Jacó fez assim, e cumpriu a semana de Lia; então lhe deu por mulher Raquel sua filha.',
        'E Labão deu sua serva Bila por serva a Raquel, sua filha.',
        'E possuiu também a Raquel, e amou também a Raquel mais do que a Lia e serviu com ele ainda outros sete anos.',
        'Vendo, pois, o Senhor que Lia era desprezada, abriu a sua madre; porém Raquel era estéril.',
        'E concebeu Lia, e deu à luz um filho, e chamou-o Rúben; pois disse: Porque o Senhor atendeu à minha aflição, por isso agora me amará o meu marido.',
        'E concebeu outra vez, e deu à luz um filho, dizendo: Porquanto o Senhor ouviu que eu era desprezada, e deu-me também este. E chamou-o Simeão.',
        'E concebeu outra vez, e deu à luz um filho, dizendo: Agora esta vez se unirá meu marido a mim, porque três filhos lhe tenho dado. Por isso chamou-o Levi.',
        'E concebeu outra vez e deu à luz um filho, dizendo: Esta vez louvarei ao Senhor. Por isso chamou-o Judá; e cessou de dar à luz.',
      ],
      [
        'Vendo Raquel que não dava filhos a Jacó, teve inveja de sua irmã, e disse a Jacó: Dá-me filhos, se não morro.',
        'Então se acendeu a ira de Jacó contra Raquel, e disse: Estou eu no lugar de Deus, que te impediu o fruto de teu ventre?',
        'E ela disse: Eis aqui minha serva Bila; coabita com ela, para que dê à luz sobre meus joelhos, e eu assim receba filhos por ela.',
        'Assim lhe deu a Bila, sua serva, por mulher; e Jacó a possuiu.',
        'E concebeu Bila, e deu a Jacó um filho.',
        'Então disse Raquel: Julgou-me Deus, e também ouviu a minha voz, e me deu um filho; por isso chamou-lhe Dã.',
        'E Bila, serva de Raquel, concebeu outra vez, e deu a Jacó o segundo filho.',
        'Então disse Raquel: Com grandes lutas tenho lutado com minha irmã; também venci; e chamou-lhe Naftali.',
        'Vendo, pois, Lia que cessava de ter filhos, tomou também a Zilpa, sua serva, e deu-a a Jacó por mulher.',
        'E deu Zilpa, serva de Lia, um filho a Jacó.',
        'Então disse Lia: Afortunada! e chamou-lhe Gade.',
        'Depois deu Zilpa, serva de Lia, um segundo filho a Jacó.',
        'Então disse Lia: Para minha ventura; porque as filhas me terão por bem-aventurada; e chamou-lhe Aser.',
        'E foi Rúben nos dias da ceifa do trigo, e achou mandrágoras no campo. E trouxe-as a Lia sua mãe. Então disse Raquel a Lia: Ora dá-me das mandrágoras de teu filho.',
        'E ela lhe disse: É já pouco que hajas tomado o meu marido, tomarás também as mandrágoras do meu filho? Então disse Raquel: Por isso ele se deitará contigo esta noite pelas mandrágoras de teu filho.',
        'Vindo, pois, Jacó à tarde do campo, saiu-lhe Lia ao encontro, e disse: A mim possuirás, esta noite, porque certamente te aluguei com as mandrágoras do meu filho. E deitou-se com ela aquela noite.',
        'E ouviu Deus a Lia, e concebeu, e deu à luz um quinto filho.',
        'Então disse Lia: Deus me tem dado o meu galardão, pois tenho dado minha serva ao meu marido. E chamou-lhe Issacar.',
        'E Lia concebeu outra vez, e deu a Jacó um sexto filho.',
        'E disse Lia: Deus me deu uma boa dádiva; desta vez morará o meu marido comigo, porque lhe tenho dado seis filhos. E chamou-lhe Zebulom.',
        'E depois teve uma filha, e chamou-lhe Diná.',
        'E lembrou-se Deus de Raquel; e Deus a ouviu, e abriu a sua madre.',
        'E ela concebeu, e deu à luz um filho, e disse: Tirou-me Deus a minha vergonha.',
        'E chamou-lhe José, dizendo: O Senhor me acrescente outro filho.',
        'E aconteceu que, como Raquel deu à luz a José, disse Jacó a Labão: Deixa-me ir, que me vá ao meu lugar, e à minha terra.',
        'Dá-me as minhas mulheres, e os meus filhos, pelas quais te tenho servido, e ir-me-ei; pois tu sabes o serviço que te tenho feito.',
        'Então lhe disse Labão: Se agora tenho achado graça em teus olhos, fica comigo. Tenho experimentado que o Senhor me abençoou por amor de ti.',
        'E disse mais: Determina-me o teu salário, que to darei.',
        'Então lhe disse: Tu sabes como te tenho servido, e como passou o teu gado comigo.',
        'Porque o pouco que tinhas antes de mim tem aumentado em grande número; e o SENHOR te tem abençoado por meu trabalho. Agora, pois, quando hei de trabalhar também por minha casa?',
        'E disse ele: Que te darei? Então disse Jacó: Nada me darás. Se me fizeres isto, tornarei a apascentar e a guardar o teu rebanho;',
        'Passarei hoje por todo o teu rebanho, separando dele todos os salpicados e malhados, e todos os morenos entre os cordeiros, e os malhados e salpicados entre as cabras; e isto será o meu salário.',
        'Assim testificará por mim a minha justiça no dia de amanhã, quando vieres e o meu salário estiver diante de tua face; tudo o que não for salpicado e malhado entre as cabras e moreno entre os cordeiros, ser-me-á por furto.',
        'Então disse Labão: Quem dera seja conforme a tua palavra.',
        'E separou naquele mesmo dia os bodes listrados e malhados e todas as cabras salpicadas e malhadas, todos em que havia brancura, e todos os morenos entre os cordeiros; e deu-os nas mãos dos seus filhos.',
        'E pôs três dias de caminho entre si e Jacó; e Jacó apascentava o restante dos rebanhos de Labão.',
        'Então tomou Jacó varas verdes de álamo e de aveleira e de castanheiro, e descascou nelas riscas brancas, descobrindo a brancura que nas varas havia,',
        'E pôs estas varas, que tinha descascado, em frente aos rebanhos, nos canos e nos bebedouros de água, aonde os rebanhos vinham beber, para que concebessem quando vinham beber.',
        'E concebiam os rebanhos diante das varas, e as ovelhas davam crias listradas, salpicadas e malhadas.',
        'Então separou Jacó os cordeiros, e pôs as faces do rebanho para os listrados, e todo o moreno entre o rebanho de Labão; e pôs o seu rebanho à parte, e não o pôs com o rebanho de Labão.',
        'E sucedia que cada vez que concebiam as ovelhas fortes, punha Jacó as varas nos canos, diante dos olhos do rebanho, para que concebessem diante das varas.',
        'Mas, quando era fraco o rebanho, não as punha. Assim as fracas eram de Labão, e as fortes de Jacó.',
        'E cresceu o homem em grande maneira, e teve muitos rebanhos, e servas, e servos, e camelos e jumentos.',
      ],
      [
        'Então ouvia as palavras dos filhos de Labão, que diziam: Jacó tem tomado tudo o que era de nosso pai, e do que era de nosso pai fez ele toda esta glória.',
        'Viu também Jacó o rosto de Labão, e eis que não era para com ele como anteriormente.',
        'E disse o Senhor a Jacó: Torna-te à terra dos teus pais, e à tua parentela, e eu serei contigo.',
        'Então mandou Jacó chamar a Raquel e a Lia ao campo, para junto do seu rebanho,',
        'E disse-lhes: Vejo que o rosto de vosso pai não é para comigo como anteriormente; porém o Deus de meu pai tem estado comigo;',
        'E vós mesmas sabeis que com todo o meu esforço tenho servido a vosso pai;',
        'Mas vosso pai me enganou e mudou o salário dez vezes; porém Deus não lhe permitiu que me fizesse mal.',
        'Quando ele dizia assim: Os salpicados serão o teu salário; então todos os rebanhos davam salpicados. E quando ele dizia assim: Os listrados serão o teu salário, então todos os rebanhos davam listrados.',
        'Assim Deus tirou o gado de vosso pai, e deu-o a mim.',
        'E sucedeu que, ao tempo em que o rebanho concebia, eu levantei os meus olhos e vi em sonhos, e eis que os bodes, que cobriam as ovelhas, eram listrados, salpicados e malhados.',
        'E disse-me o anjo de Deus em sonhos: Jacó! E eu disse: Eis-me aqui.',
        'E disse ele: Levanta agora os teus olhos e vê todos os bodes que cobrem o rebanho, que são listrados, salpicados e malhados; porque tenho visto tudo o que Labão te fez.',
        'Eu sou o Deus de Betel, onde tens ungido uma coluna, onde me fizeste um voto; levanta-te agora, sai-te desta terra e torna-te à terra da tua parentela.',
        'Então responderam Raquel e Lia e disseram-lhe: Há ainda para nós parte ou herança na casa de nosso pai?',
        'Não nos considera ele como estranhas? Pois vendeu-nos, e comeu de todo o nosso dinheiro.',
        'Porque toda a riqueza, que Deus tirou de nosso pai, é nossa e de nossos filhos; agora, pois, faze tudo o que Deus te mandou.',
        'Então se levantou Jacó, pondo os seus filhos e as suas mulheres sobre os camelos;',
        'E levou todo o seu gado, e todos os seus bens, que havia adquirido, o gado que possuía, que alcançara em Padã-Arã, para ir a Isaque, seu pai, à terra de Canaã.',
        'E havendo Labão ido a tosquiar as suas ovelhas, furtou Raquel os ídolos que seu pai tinha.',
        'E Jacó logrou a Labão, o arameu, porque não lhe fez saber que fugia.',
        'E fugiu ele com tudo o que tinha, e levantou-se e passou o rio; e se dirigiu para a montanha de Gileade.',
        'E no terceiro dia foi anunciado a Labão que Jacó tinha fugido.',
        'Então tomou consigo os seus irmãos, e atrás dele seguiu o seu caminho por sete dias; e alcançou-o na montanha de Gileade.',
        'Veio, porém, Deus a Labão, o arameu, em sonhos, de noite, e disse-lhe: Guarda-te, que não fales com Jacó nem bem nem mal.',
        'Alcançou, pois, Labão a Jacó, e armara Jacó a sua tenda naquela montanha; armou também Labão com os seus irmãos a sua, na montanha de Gileade.',
        'Então disse Labão a Jacó: Que fizeste, que me lograste e levaste as minhas filhas como cativas pela espada?',
        'Por que fugiste ocultamente, e lograste-me, e não me fizeste saber, para que eu te enviasse com alegria, e com cânticos, e com tamboril e com harpa?',
        'Também não me permitiste beijar os meus filhos e as minhas filhas. Loucamente agiste, agora, fazendo assim.',
        'Poder havia em minha mão para vos fazer mal, mas o Deus de vosso pai me falou ontem à noite, dizendo: Guarda-te, que não fales com Jacó nem bem nem mal.',
        'E agora se querias ir embora, porquanto tinhas saudades de voltar à casa de teu pai, por que furtaste os meus deuses?',
        'Então respondeu Jacó, e disse a Labão: Porque temia; pois que dizia comigo, se porventura não me arrebatarias as tuas filhas.',
        'Com quem achares os teus deuses, esse não viva; reconhece diante de nossos irmãos o que é teu do que está comigo, e toma-o para ti. Pois Jacó não sabia que Raquel os tinha furtado.',
        'Então entrou Labão na tenda de Jacó, e na tenda de Lia, e na tenda de ambas as servas, e não os achou; e saindo da tenda de Lia, entrou na tenda de Raquel.',
        'Mas tinha tomado Raquel os ídolos e os tinha posto na albarda de um camelo, e assentara-se sobre eles; e apalpou Labão toda a tenda, e não os achou.',
        'E ela disse a seu pai: Não se acenda a ira aos olhos de meu senhor, que não posso levantar-me diante da tua face; porquanto tenho o costume das mulheres. E ele procurou, mas não achou os ídolos.',
        'Então irou-se Jacó e contendeu com Labão; e respondeu Jacó, e disse a Labão: Qual é a minha transgressão? Qual é o meu pecado, que tão furiosamente me tens perseguido?',
        'Havendo apalpado todos os meus móveis, que achaste de todos os móveis de tua casa? Põe-no aqui diante dos meus irmãos e de teus irmãos; e que julguem entre nós ambos.',
        'Estes vinte anos eu estive contigo; as tuas ovelhas e as tuas cabras nunca abortaram, e não comi os carneiros do teu rebanho.',
        'Não te trouxe eu o despedaçado; eu o pagava; o furtado de dia e o furtado de noite da minha mão o requerias.',
        'Estava eu assim: De dia me consumia o calor, e de noite a geada; e o meu sono fugiu dos meus olhos.',
        'Tenho estado agora vinte anos na tua casa; catorze anos te servi por tuas duas filhas, e seis anos por teu rebanho; mas o meu salário tens mudado dez vezes.',
        'Se o Deus de meu pai, o Deus de Abraão e o temor de Isaque não fora comigo, por certo me despedirias agora vazio. Deus atendeu à minha aflição, e ao trabalho das minhas mãos, e repreendeu-te ontem à noite.',
        'Então respondeu Labão, e disse a Jacó: Estas filhas são minhas filhas, e estes filhos são meus filhos, e este rebanho é o meu rebanho, e tudo o que vês, é meu; e que farei hoje a estas minhas filhas, ou a seus filhos, que deram à luz?',
        'Agora pois vem, e façamos aliança eu e tu, que seja por testemunho entre mim e ti.',
        'Então tomou Jacó uma pedra, e erigiu-a por coluna.',
        'E disse Jacó a seus irmãos: Ajuntai pedras. E tomaram pedras, e fizeram um montão, e comeram ali sobre aquele montão.',
        'E chamou-o Labão Jegar-Saaduta; porém Jacó chamou-o Galeede.',
        'Então disse Labão: Este montão seja hoje por testemunha entre mim e ti. Por isso se lhe chamou Galeede,',
        'E Mispá, porquanto disse: Atente o Senhor entre mim e ti, quando nós estivermos apartados um do outro.',
        'Se afligires as minhas filhas, e se tomares mulheres além das minhas filhas, ninguém está conosco; atenta que Deus é testemunha entre mim e ti.',
        'Disse mais Labão a Jacó: Eis aqui este mesmo montão, e eis aqui essa coluna que levantei entre mim e ti.',
        'Este montão seja testemunha, e esta coluna seja testemunha, que eu não passarei este montão a ti, e que tu não passarás este montão e esta coluna a mim, para mal.',
        'O Deus de Abraão e o Deus de Naor, o Deus de seu pai, julgue entre nós. E jurou Jacó pelo temor de seu pai Isaque.',
        'E ofereceu Jacó um sacrifício na montanha, e convidou seus irmãos, para comer pão; e comeram pão e passaram a noite na montanha.',
        'E levantou-se Labão pela manhã de madrugada, e beijou seus filhos e suas filhas e abençoou-os e partiu; e voltou Labão ao seu lugar.',
      ],
      [
        'Jacó também seguiu o seu caminho, e encontraram-no os anjos de Deus.',
        'E Jacó disse, quando os viu: Este é o exército de Deus. E chamou aquele lugar Maanaim.',
        'E enviou Jacó mensageiros adiante de si a Esaú, seu irmão, à terra de Seir, território de Edom.',
        'E ordenou-lhes, dizendo: Assim direis a meu senhor Esaú: Assim diz Jacó, teu servo: Como peregrino morei com Labão, e me detive lá até agora;',
        'E tenho bois e jumentos, ovelhas, e servos e servas; e enviei para o anunciar a meu senhor, para que ache graça em teus olhos.',
        'E os mensageiros voltaram a Jacó, dizendo: Fomos a teu irmão Esaú; e também ele vem para encontrar-te, e quatrocentos homens com ele.',
        'Então Jacó temeu muito e angustiou-se; e repartiu o povo que com ele estava, e as ovelhas, e as vacas, e os camelos, em dois bandos.',
        'Porque dizia: Se Esaú vier a um bando e o ferir, o outro bando escapará.',
        'Disse mais Jacó: Deus de meu pai Abraão, e Deus de meu pai Isaque, o Senhor, que me disseste: Torna-te à tua terra, e a tua parentela, e far-te-ei bem;',
        'Menor sou eu que todas as beneficências, e que toda a fidelidade que fizeste ao teu servo; porque com meu cajado passei este Jordão, e agora me tornei em dois bandos.',
        'Livra-me, peço-te, da mão de meu irmão, da mão de Esaú; porque eu o temo; porventura não venha, e me fira, e a mãe com os filhos.',
        'E tu o disseste: Certamente te farei bem, e farei a tua descendência como a areia do mar, que pela multidão não se pode contar.',
        'E passou ali aquela noite; e tomou do que lhe veio à sua mão, um presente para seu irmão Esaú:',
        'Duzentas cabras e vinte bodes; duzentas ovelhas e vinte carneiros;',
        'Trinta camelas de leite com suas crias, quarenta vacas e dez novilhos; vinte jumentas e dez jumentinhos;',
        'E deu-os na mão dos seus servos, cada rebanho à parte, e disse a seus servos: Passai adiante de mim e ponde espaço entre rebanho e rebanho.',
        'E ordenou ao primeiro, dizendo: Quando Esaú, meu irmão, te encontrar, e te perguntar, dizendo: De quem és, e para onde vais, e de quem são estes diante de ti?',
        'Então dirás: São de teu servo Jacó, presente que envia a meu senhor, a Esaú; e eis que ele mesmo vem também atrás de nós.',
        'E ordenou também ao segundo, e ao terceiro, e a todos os que vinham atrás dos rebanhos, dizendo: Conforme a esta mesma palavra falareis a Esaú, quando o achardes.',
        'E direis também: Eis que o teu servo Jacó vem atrás de nós. Porque dizia: Eu o aplacarei com o presente, que vai adiante de mim, e depois verei a sua face; porventura ele me aceitará.',
        'Assim, passou o presente adiante dele; ele, porém, passou aquela noite no arraial.',
        'E levantou-se aquela mesma noite, e tomou as suas duas mulheres, e as suas duas servas, e os seus onze filhos, e passou o vau de Jaboque.',
        'E tomou-os e fê-los passar o ribeiro; e fez passar tudo o que tinha.',
        'Jacó, porém, ficou só; e lutou com ele um homem, até que a alva subiu.',
        'E vendo este que não prevalecia contra ele, tocou a juntura de sua coxa, e se deslocou a juntura da coxa de Jacó, lutando com ele.',
        'E disse: Deixa-me ir, porque já a alva subiu. Porém ele disse: Não te deixarei ir, se não me abençoares.',
        'E disse-lhe: Qual é o teu nome? E ele disse: Jacó.',
        'Então disse: Não te chamarás mais Jacó, mas Israel; pois como príncipe lutaste com Deus e com os homens, e prevaleceste.',
        'E Jacó lhe perguntou, e disse: Dá-me, peço-te, a saber o teu nome. E disse: Por que perguntas pelo meu nome? E abençoou-o ali.',
        'E chamou Jacó o nome daquele lugar Peniel, porque dizia: Tenho visto a Deus face a face, e a minha alma foi salva.',
        'E saiu-lhe o sol, quando passou a Peniel; e manquejava da sua coxa.',
        'Por isso os filhos de Israel não comem o nervo encolhido, que está sobre a juntura da coxa, até o dia de hoje; porquanto tocara a juntura da coxa de Jacó no nervo encolhido.',
      ],
      [
        'E levantou Jacó os seus olhos, e olhou, e eis que vinha Esaú, e quatrocentos homens com ele. Então repartiu os filhos entre Lia, e Raquel, e as duas servas.',
        'E pôs as servas e seus filhos na frente, e a Lia e seus filhos atrás; porém a Raquel e José os derradeiros.',
        'E ele mesmo passou adiante deles e inclinou-se à terra sete vezes, até que chegou a seu irmão.',
        'Então Esaú correu-lhe ao encontro, e abraçou-o, e lançou-se sobre o seu pescoço, e beijou-o; e choraram.',
        'Depois levantou os seus olhos, e viu as mulheres, e os meninos, e disse: Quem são estes contigo? E ele disse: Os filhos que Deus graciosamente tem dado a teu servo.',
        'Então chegaram as servas; elas e os seus filhos, e inclinaram-se.',
        'E chegou também Lia com seus filhos, e inclinaram-se; e depois chegou José e Raquel e inclinaram-se.',
        'E disse Esaú: De que te serve todo este bando que tenho encontrado? E ele disse: Para achar graça aos olhos de meu senhor.',
        'Mas Esaú disse: Eu tenho bastante, meu irmão; seja para ti o que tens.',
        'Então disse Jacó: Não, se agora tenho achado graça em teus olhos, peço-te que tomes o meu presente da minha mão; porquanto tenho visto o teu rosto, como se tivesse visto o rosto de Deus, e tomaste contentamento em mim.',
        'Toma, peço-te, a minha bênção, que te foi trazida; porque Deus graciosamente ma tem dado; e porque tenho de tudo. E instou com ele, até que a tomou.',
        'E disse: Caminhemos, e andemos, e eu partirei adiante de ti.',
        'Porém ele lhe disse: Meu senhor sabe que estes filhos são tenros, e que tenho comigo ovelhas e vacas de leite; se as afadigarem somente um dia, todo o rebanho morrerá.',
        'Ora passe o meu senhor adiante de seu servo; e eu irei como guia pouco a pouco, conforme ao passo do gado que vai adiante de mim, e conforme ao passo dos meninos, até que chegue a meu senhor em Seir.',
        'E Esaú disse: Permite então que eu deixe contigo alguns da minha gente. E ele disse: Para que é isso? Basta que ache graça aos olhos de meu senhor.',
        'Assim voltou Esaú aquele dia pelo seu caminho a Seir.',
        'Jacó, porém, partiu para Sucote e edificou para si uma casa; e fez cabanas para o seu gado; por isso chamou aquele lugar Sucote.',
        'E chegou Jacó salvo à Salém, cidade de Siquém, que está na terra de Canaã, quando vinha de Padã-Arã; e armou a sua tenda diante da cidade.',
        'E comprou uma parte do campo em que estendera a sua tenda, da mão dos filhos de Hamor, pai de Siquém, por cem peças de dinheiro.',
        'E levantou ali um altar, e chamou-lhe: Deus, o Deus de Israel.',
      ],
      [
        'E saiu Diná, filha de Lia, que esta dera a Jacó, para ver as filhas da terra.',
        'E Siquém, filho de Hamor, heveu, príncipe daquela terra, viu-a, e tomou-a, e deitou-se com ela, e humilhou-a.',
        'E apegou-se a sua alma com Diná, filha de Jacó, e amou a moça e falou afetuosamente à moça.',
        'Falou também Siquém a Hamor, seu pai, dizendo: Toma-me esta moça por mulher.',
        'Quando Jacó ouviu que Diná, sua filha, fora violada, estavam os seus filhos no campo com o gado; e calou-se Jacó até que viessem.',
        'E saiu Hamor, pai de Siquém, a Jacó, para falar com ele.',
        'E vieram os filhos de Jacó do campo, ouvindo isso, e entristeceram-se os homens, e iraram-se muito, porquanto Siquém cometera uma insensatez em Israel, deitando-se com a filha de Jacó; o que não se devia fazer assim.',
        'Então falou Hamor com eles, dizendo: A alma de Siquém, meu filho, está enamorada da vossa filha; dai-lha, peço-vos, por mulher;',
        'E aparentai-vos conosco, dai-nos as vossas filhas, e tomai as nossas filhas para vós;',
        'E habitareis conosco; e a terra estará diante de vós; habitai e negociai nela, e tomai possessão nela.',
        'E disse Siquém ao pai dela, e aos irmãos dela: Ache eu graça em vossos olhos, e darei o que me disserdes;',
        'Aumentai muito sobre mim o dote e a dádiva e darei o que me disserdes; dai-me somente a moça por mulher.',
        'Então responderam os filhos de Jacó a Siquém e a Hamor, seu pai, enganosamente, e falaram, porquanto havia violado a Diná, sua irmã.',
        'E disseram-lhe: Não podemos fazer isso, dar a nossa irmã a um homem não circuncidado; porque isso seria uma vergonha para nós;',
        'Nisso, porém, consentiremos a vós: se fordes como nós; que se circuncide todo o homem entre vós;',
        'Então dar-vos-emos as nossas filhas, e tomaremos nós as vossas filhas, e habitaremos convosco, e seremos um povo;',
        'Mas se não nos ouvirdes, e não vos circuncidardes, tomaremos a nossa filha e ir-nos-emos.',
        'E suas palavras foram boas aos olhos de Hamor, e aos olhos de Siquém, filho de Hamor.',
        'E não tardou o jovem em fazer isto; porque a filha de Jacó lhe contentava; e ele era o mais honrado de toda a casa de seu pai.',
        'Veio, pois, Hamor e Siquém, seu filho, à porta da sua cidade, e falaram aos homens da sua cidade, dizendo:',
        'Estes homens são pacíficos conosco; portanto habitarão nesta terra, e negociarão nela; eis que a terra é larga de espaço para eles; tomaremos nós as suas filhas por mulheres, e lhes daremos as nossas filhas.',
        'Nisto, porém, consentirão aqueles homens, em habitar conosco, para que sejamos um povo, se todo o homem entre nós se circuncidar, como eles são circuncidados.',
        'E seu gado, as suas possessões, e todos os seus animais não serão nossos? Consintamos somente com eles e habitarão conosco.',
        'E deram ouvidos a Hamor e a Siquém, seu filho, todos os que saíam da porta da cidade; e foi circuncidado todo o homem, de todos os que saíam pela porta da sua cidade.',
        'E aconteceu que, ao terceiro dia, quando estavam com a mais violenta dor, os dois filhos de Jacó, Simeão e Levi, irmãos de Diná, tomaram cada um a sua espada, e entraram afoitamente na cidade, e mataram todos os homens.',
        'Mataram também ao fio da espada a Hamor, e a seu filho Siquém; e tomaram a Diná da casa de Siquém, e saíram.',
        'Vieram os filhos de Jacó aos mortos e saquearam a cidade; porquanto violaram a sua irmã.',
        'As suas ovelhas, e as suas vacas, e os seus jumentos, e o que havia na cidade e no campo, tomaram.',
        'E todos os seus bens, e todos os seus meninos, e as suas mulheres, levaram presos, e saquearam tudo o que havia em casa.',
        'Então disse Jacó a Simeão e a Levi: Tendes-me turbado, fazendo-me cheirar mal entre os moradores desta terra, entre os cananeus e perizeus; tendo eu pouco povo em número, eles ajuntar-se-ão, e serei destruído, eu e minha casa.',
        'E eles disseram: Devia ele tratar a nossa irmã como a uma prostituta?',
      ],
      [
        'Depois disse Deus a Jacó: Levanta-te, sobe a Betel, e habita ali; e faze ali um altar ao Deus que te apareceu, quando fugiste da face de Esaú teu irmão.',
        'Então disse Jacó à sua família, e a todos os que com ele estavam: Tirai os deuses estranhos, que há no meio de vós, e purificai-vos, e mudai as vossas vestes.',
        'E levantemo-nos, e subamos a Betel; e ali farei um altar ao Deus que me respondeu no dia da minha angústia, e que foi comigo no caminho que tenho andado.',
        'Então deram a Jacó todos os deuses estranhos, que tinham em suas mãos, e as arrecadas que estavam em suas orelhas; e Jacó os escondeu debaixo do carvalho que está junto a Siquém.',
        'E partiram; e o terror de Deus foi sobre as cidades que estavam ao redor deles, e não seguiram após os filhos de Jacó.',
        'Assim chegou Jacó a Luz, que está na terra de Canaã (esta é Betel), ele e todo o povo que com ele havia.',
        'E edificou ali um altar, e chamou aquele lugar El-Betel; porquanto Deus ali se lhe tinha manifestado, quando fugia da face de seu irmão.',
        'E morreu Débora, a ama de Rebeca, e foi sepultada ao pé de Betel, debaixo do carvalho cujo nome chamou Alom-Bacute.',
        'E apareceu Deus outra vez a Jacó, vindo de Padã-Arã, e abençoou-o.',
        'E disse-lhe Deus: O teu nome é Jacó; não te chamarás mais Jacó, mas Israel será o teu nome. E chamou-lhe Israel.',
        'Disse-lhe mais Deus: Eu sou o Deus Todo-Poderoso; frutifica e multiplica-te; uma nação, sim, uma multidão de nações sairá de ti, e reis procederão dos teus lombos;',
        'E te darei a ti a terra que tenho dado a Abraão e a Isaque, e à tua descendência depois de ti darei a terra.',
        'E Deus subiu dele, do lugar onde falara com ele.',
        'E Jacó pôs uma coluna no lugar onde falara com ele, uma coluna de pedra; e derramou sobre ela uma libação, e deitou sobre ela azeite.',
        'E chamou Jacó aquele lugar, onde Deus falara com ele, Betel.',
        'E partiram de Betel; e havia ainda um pequeno espaço de terra para chegar a Efrata, e deu à luz Raquel, e ela teve trabalho em seu parto.',
        'E aconteceu que, tendo ela trabalho em seu parto, lhe disse a parteira: Não temas, porque também este filho terás.',
        'E aconteceu que, saindo-se-lhe a alma (porque morreu), chamou-lhe Benoni; mas seu pai chamou-lhe Benjamim.',
        'Assim morreu Raquel, e foi sepultada no caminho de Efrata; que é Belém.',
        'E Jacó pôs uma coluna sobre a sua sepultura; esta é a coluna da sepultura de Raquel até o dia de hoje.',
        'Então partiu Israel, e estendeu a sua tenda além de Migdal Eder.',
        'E aconteceu que, habitando Israel naquela terra, foi Rúben e deitou-se com Bila, concubina de seu pai; e Israel o soube. E eram doze os filhos de Jacó.',
        'Os filhos de Lia: Rúben, o primogênito de Jacó, depois Simeão e Levi, e Judá, e Issacar e Zebulom;',
        'Os filhos de Raquel: José e Benjamim;',
        'E os filhos de Bila, serva de Raquel: Dã e Naftali;',
        'E os filhos de Zilpa, serva de Lia: Gade e Aser. Estes são os filhos de Jacó, que lhe nasceram em Padã-Arã.',
        'E Jacó veio a seu pai Isaque, a Manre, a Quiriate-Arba (que é Hebrom), onde peregrinaram Abraão e Isaque.',
        'E foram os dias de Isaque cento e oitenta anos.',
        'E Isaque expirou, e morreu, e foi recolhido ao seu povo, velho e farto de dias; e Esaú e Jacó, seus filhos, o sepultaram.',
      ],
      [
        'E estas são as gerações de Esaú (que é Edom).',
        'Esaú tomou suas mulheres das filhas de Canaã; a Ada, filha de Elom, heteu, e a Aolibama, filha de Aná, filho de Zibeão, heveu.',
        'E a Basemate, filha de Ismael, irmã de Nebaiote.',
        'E Ada teve de Esaú a Elifaz; e Basemate teve a Reuel;',
        'E Aolibama deu à luz a Jeús, Jalão e Coré; estes são os filhos de Esaú, que lhe nasceram na terra de Canaã.',
        'E Esaú tomou suas mulheres, e seus filhos, e suas filhas, e todas as almas de sua casa, e seu gado, e todos os seus animais, e todos os seus bens, que havia adquirido na terra de Canaã; e foi para outra terra apartando-se de Jacó, seu irmão;',
        'Porque os bens deles eram muitos para habitarem juntos; e a terra de suas peregrinações não os podia sustentar por causa do seu gado.',
        'Portanto Esaú habitou na montanha de Seir; Esaú é Edom.',
        'Estas, pois, são as gerações de Esaú, pai dos edomeus, na montanha de Seir.',
        'Estes são os nomes dos filhos de Esaú: Elifaz, filho de Ada, mulher de Esaú; Reuel, filho de Basemate, mulher de Esaú.',
        'E os filhos de Elifaz foram: Temã, Omar, Zefô, Gaetã e Quenaz.',
        'E Timna era concubina de Elifaz, filho de Esaú, e teve de Elifaz a Amaleque. Estes são os filhos de Ada, mulher de Esaú.',
        'E estes foram os filhos de Reuel: Naate, Zerá, Samá e Mizá; estes foram os filhos de Basemate, mulher de Esaú.',
        'E estes foram os filhos de Aolibama, mulher de Esaú, filha de Aná, filho de Zibeão; ela teve de Esaú: Jeús, Jalão e Coré.',
        'Estes são os príncipes dos filhos de Esaú: os filhos de Elifaz, o primogênito de Esaú, o príncipe Temã, o príncipe Omar, o príncipe Zefô, o príncipe Quenaz.',
        'O príncipe Coré, o príncipe Gaetã, o príncipe Amaleque; estes são os príncipes de Elifaz na terra de Edom; estes são os filhos de Ada.',
        'E estes são os filhos de Reuel, filhos de Esaú: o príncipe Naate, o príncipe Zerá, o príncipe Samá, o príncipe Mizá; estes são os príncipes de Reuel, na terra de Edom; estes são os filhos de Basemate, mulher de Esaú.',
        'E estes são os filhos de Aolibama, mulher de Esaú: o príncipe Jeús, o príncipe Jalão, o príncipe Coré; estes são os príncipes de Aolibama, filha de Aná, mulher de Esaú.',
        'Estes são os filhos de Esaú, e estes são seus príncipes: Ele é Edom.',
        'Estes são os filhos de Seir, horeu, moradores daquela terra: Lotã, Sobal, Zibeão e Aná,',
        'Disom, Eser e Disã; estes são os príncipes dos horeus, filhos de Seir, na terra de Edom.',
        'E os filhos de Lotã foram Hori e Homã; e a irmã de Lotã era Timna.',
        'Estes são os filhos de Sobal: Alvã, Manaate, Ebal, Sefô e Onã.',
        'E estes são os filhos de Zibeão: Aiá e Aná; este é o Aná que achou as fontes termais no deserto, quando apascentava os jumentos de Zibeão, seu pai.',
        'E estes são os filhos de Aná: Disom e Aolibama, a filha de Aná.',
        'E estes são os filhos de Disã: Hendã, Esbã, Itrã e Querã.',
        'Estes são os filhos de Eser: Bilã, Zaavã e Acã.',
        'Estes são os filhos de Disã: Uz e Arã.',
        'Estes são os príncipes dos horeus: o príncipe Lotã, o príncipe Sobal, o príncipe Zibeão, o príncipe Aná.',
        'O príncipe Disom, o príncipe Eser, o príncipe Disã: estes são os príncipes dos horeus segundo os seus principados na terra de Seir.',
        'E estes são os reis que reinaram na terra de Edom, antes que reinasse rei algum sobre os filhos de Israel.',
        'Reinou, pois, em Edom Bela, filho de Beor, e o nome da sua cidade foi Dinabá.',
        'E morreu Bela; e Jobabe, filho de Zerá, de Bozra, reinou em seu lugar.',
        'E morreu Jobabe; e Husão, da terra dos temanitas, reinou em seu lugar.',
        'E morreu Husão, e em seu lugar reinou Hadade, filho de Bedade, o que feriu a Midiã, no campo de Moabe; e o nome da sua cidade foi Avite.',
        'E morreu Hadade; e Samlá de Masreca reinou em seu lugar.',
        'E morreu Samlá; e Saul de Reobote, junto ao rio, reinou em seu lugar.',
        'E morreu Saul; e Baal-Hanã, filho de Acbor, reinou em seu lugar.',
        'E morreu Baal-Hanã, filho de Acbor; e Hadar reinou em seu lugar, e o nome de sua cidade foi Pau; e o nome de sua mulher foi Meetabel, filha de Matrede, filha de Me-Zaabe.',
        'E estes são os nomes dos príncipes de Esaú, segundo as suas gerações, segundo os seus lugares, com os seus nomes: o príncipe Timna, o príncipe Alva, o príncipe Jetete,',
        'O príncipe Aolibama, o príncipe Ela, o príncipe Pinom,',
        'O príncipe Quenaz, o príncipe Temã, o príncipe Mibzar,',
        'O príncipe Magdiel, o príncipe Irã: estes são os príncipes de Edom, segundo as suas habitações, na terra da sua possessão. Este é Esaú, pai de Edom.',
      ],
      [
        'E Jacó habitou na terra das peregrinações de seu pai, na terra de Canaã.',
        'Estas são as gerações de Jacó. Sendo José de dezessete anos, apascentava as ovelhas com seus irmãos; sendo ainda jovem, andava com os filhos de Bila, e com os filhos de Zilpa, mulheres de seu pai; e José trazia más notícias deles a seu pai.',
        'E Israel amava a José mais do que a todos os seus filhos, porque era filho da sua velhice; e fez-lhe uma túnica de várias cores.',
        'Vendo, pois, seus irmãos que seu pai o amava mais do que a todos eles, odiaram-no, e não podiam falar com ele pacificamente.',
        'Teve José um sonho, que contou a seus irmãos; por isso o odiaram ainda mais.',
        'E disse-lhes: Ouvi, peço-vos, este sonho, que tenho sonhado:',
        'Eis que estávamos atando molhos no meio do campo, e eis que o meu molho se levantava, e também ficava em pé, e eis que os vossos molhos o rodeavam, e se inclinavam ao meu molho.',
        'Então lhe disseram seus irmãos: Tu, pois, deveras reinarás sobre nós? Tu deveras terás domínio sobre nós? Por isso ainda mais o odiavam por seus sonhos e por suas palavras.',
        'E teve José outro sonho, e o contou a seus irmãos, e disse: Eis que tive ainda outro sonho; e eis que o sol, e a lua, e onze estrelas se inclinavam a mim.',
        'E contando-o a seu pai e a seus irmãos, repreendeu-o seu pai, e disse-lhe: Que sonho é este que tiveste? Porventura viremos, eu e tua mãe, e teus irmãos, a inclinar-nos perante ti em terra?',
        'Seus irmãos, pois, o invejavam; seu pai porém guardava este negócio no seu coração.',
        'E seus irmãos foram apascentar o rebanho de seu pai, junto de Siquém.',
        'Disse, pois, Israel a José: Não apascentam os teus irmãos junto de Siquém? Vem, e enviar-te-ei a eles. E ele respondeu: Eis-me aqui.',
        'E ele lhe disse: Ora vai, vê como estão teus irmãos, e como está o rebanho, e traze-me resposta. Assim o enviou do vale de Hebrom, e foi a Siquém.',
        'E achou-o um homem, porque eis que andava errante pelo campo, e perguntou-lhe o homem, dizendo: Que procuras?',
        'E ele disse: Procuro meus irmãos; dize-me, peço-te, onde eles apascentam.',
        'E disse aquele homem: Foram-se daqui; porque ouvi-os dizer: Vamos a Dotã. José, pois, seguiu atrás de seus irmãos, e achou-os em Dotã.',
        'E viram-no de longe e, antes que chegasse a eles, conspiraram contra ele para o matarem.',
        'E disseram um ao outro: Eis lá vem o sonhador-mor!',
        'Vinde, pois, agora, e matemo-lo, e lancemo-lo numa destas covas, e diremos: Uma fera o comeu; e veremos que será dos seus sonhos.',
        'E ouvindo-o Rúben, livrou-o das suas mãos, e disse: Não lhe tiremos a vida.',
        'Também lhes disse Rúben: Não derrameis sangue; lançai-o nesta cova, que está no deserto, e não lanceis mãos nele; isto disse para livrá-lo das mãos deles e para torná-lo a seu pai.',
        'E aconteceu que, chegando José a seus irmãos, tiraram de José a sua túnica, a túnica de várias cores, que trazia.',
        'E tomaram-no, e lançaram-no na cova; porém a cova estava vazia, não havia água nela.',
        'Depois assentaram-se a comer pão; e levantaram os seus olhos, e olharam, e eis que uma companhia de ismaelitas vinha de Gileade; e seus camelos traziam especiarias e bálsamo e mirra, e iam levá-los ao Egito.',
        'Então Judá disse aos seus irmãos: Que proveito haverá que matemos a nosso irmão e escondamos o seu sangue?',
        'Vinde e vendamo-lo a estes ismaelitas, e não seja nossa mão sobre ele; porque ele é nosso irmão, nossa carne. E seus irmãos obedeceram.',
        'Passando, pois, os mercadores midianitas, tiraram e alçaram a José da cova, e venderam José por vinte moedas de prata, aos ismaelitas, os quais levaram José ao Egito.',
        'Voltando, pois, Rúben à cova, eis que José não estava na cova; então rasgou as suas vestes.',
        'E voltou a seus irmãos e disse: O menino não está; e eu aonde irei?',
        'Então tomaram a túnica de José, e mataram um cabrito, e tingiram a túnica no sangue.',
        'E enviaram a túnica de várias cores, mandando levá-la a seu pai, e disseram: Temos achado esta túnica; conhece agora se esta será ou não a túnica de teu filho.',
        'E conheceu-a, e disse: É a túnica de meu filho; uma fera o comeu; certamente José foi despedaçado.',
        'Então Jacó rasgou as suas vestes, pôs saco sobre os seus lombos e lamentou a seu filho muitos dias.',
        'E levantaram-se todos os seus filhos e todas as suas filhas, para o consolarem; recusou porém ser consolado, e disse: Porquanto com choro hei de descer ao meu filho até à sepultura. Assim o chorou seu pai.',
        'E os midianitas venderam-no no Egito a Potifar, oficial de Faraó, capitão da guarda.',
      ],
      [
        'E aconteceu no mesmo tempo que Judá desceu de entre seus irmãos e entrou na casa de um homem de Adulão, cujo nome era Hira,',
        'E viu Judá ali a filha de um homem cananeu, cujo nome era Sua; e tomou-a por mulher, e a possuiu.',
        'E ela concebeu e deu à luz um filho, e chamou-lhe Er.',
        'E tornou a conceber e deu à luz um filho, e chamou-lhe Onã.',
        'E continuou ainda e deu à luz um filho, e chamou-lhe Selá; e Judá estava em Quezibe, quando ela o deu à luz.',
        'Judá, pois, tomou uma mulher para Er, o seu primogênito, e o seu nome era Tamar.',
        'Er, porém, o primogênito de Judá, era mau aos olhos do Senhor, por isso o Senhor o matou.',
        'Então disse Judá a Onã: Toma a mulher do teu irmão, e casa-te com ela, e suscita descendência a teu irmão.',
        'Onã, porém, soube que esta descendência não havia de ser para ele; e aconteceu que, quando possuía a mulher de seu irmão, derramava o sêmen na terra, para não dar descendência a seu irmão.',
        'E o que fazia era mau aos olhos do Senhor, pelo que também o matou.',
        'Então disse Judá a Tamar sua nora: Fica-te viúva na casa de teu pai, até que Selá, meu filho, seja grande. Porquanto disse: Para que porventura não morra também este, como seus irmãos. Assim se foi Tamar e ficou na casa de seu pai.',
        'Passando-se pois muitos dias, morreu a filha de Sua, mulher de Judá; e depois de consolado Judá subiu aos tosquiadores das suas ovelhas em Timna, ele e Hira, seu amigo, o adulamita.',
        'E deram aviso a Tamar, dizendo: Eis que o teu sogro sobe a Timna, a tosquiar as suas ovelhas.',
        'Então ela tirou de sobre si os vestidos da sua viuvez e cobriu-se com o véu, e envolveu-se, e assentou-se à entrada das duas fontes que estão no caminho de Timna, porque via que Selá já era grande, e ela não lhe fora dada por mulher.',
        'E vendo-a Judá, teve-a por uma prostituta, porque ela tinha coberto o seu rosto.',
        'E dirigiu-se a ela no caminho, e disse: Vem, peço-te, deixa-me possuir-te. Porquanto não sabia que era sua nora. E ela disse: Que darás, para que possuas a mim?',
        'E ele disse: Eu te enviarei um cabrito do rebanho. E ela disse: Dar-me-ás penhor até que o envies?',
        'Então ele disse: Que penhor é que te darei? E ela disse: O teu selo, e o teu cordão, e o cajado que está em tua mão. O que ele lhe deu, e possuiu-a, e ela concebeu dele.',
        'E ela se levantou, e se foi e tirou de sobre si o seu véu, e vestiu os vestidos da sua viuvez.',
        'E Judá enviou o cabrito por mão do seu amigo, o adulamita, para tomar o penhor da mão da mulher; porém não a achou.',
        'E perguntou aos homens daquele lugar dizendo: Onde está a prostituta que estava no caminho junto às duas fontes? E disseram: Aqui não esteve prostituta alguma.',
        'E tornou-se a Judá e disse: Não a achei; e também disseram os homens daquele lugar: Aqui não esteve prostituta.',
        'Então disse Judá: Deixa-a ficar com o penhor, para que porventura não caiamos em desprezo; eis que tenho enviado este cabrito; mas tu não a achaste.',
        'E aconteceu que, quase três meses depois, deram aviso a Judá, dizendo: Tamar, tua nora, adulterou, e eis que está grávida do adultério. Então disse Judá: Tirai-a fora para que seja queimada.',
        'E tirando-a fora, ela mandou dizer a seu sogro: Do homem de quem são estas coisas eu concebi. E ela disse mais: Conhece, peço-te, de quem é este selo, e este cordão, e este cajado.',
        'E conheceu-os Judá e disse: Mais justa é ela do que eu, porquanto não a tenho dado a Selá meu filho. E nunca mais a conheceu.',
        'E aconteceu ao tempo de dar à luz que havia gêmeos em seu ventre;',
        'E sucedeu que, dando ela à luz, que um pôs fora a mão, e a parteira tomou-a, e atou em sua mão um fio encarnado, dizendo: Este saiu primeiro.',
        'Mas aconteceu que, tornando ele a recolher a sua mão, eis que saiu o seu irmão, e ela disse: Como tu tens rompido, sobre ti é a rotura. E chamaram-lhe Perez.',
        'E depois saiu o seu irmão, em cuja mão estava o fio encarnado; e chamaram-lhe Zerá.',
      ],
      [
        'E José foi levado ao Egito, e Potifar, oficial de Faraó, capitão da guarda, homem egípcio, comprou-o da mão dos ismaelitas que o tinham levado lá.',
        'E o SENHOR estava com José, e foi homem próspero; e estava na casa de seu senhor egípcio.',
        'Vendo, pois, o seu senhor que o SENHOR estava com ele, e tudo o que fazia o SENHOR prosperava em sua mão,',
        'José achou graça em seus olhos, e servia-o; e ele o pôs sobre a sua casa, e entregou na sua mão tudo o que tinha.',
        'E aconteceu que, desde que o pusera sobre a sua casa e sobre tudo o que tinha, o Senhor abençoou a casa do egípcio por amor de José; e a bênção do Senhor foi sobre tudo o que tinha, na casa e no campo.',
        'E deixou tudo o que tinha na mão de José, de maneira que nada sabia do que estava com ele, a não ser do pão que comia. E José era formoso de porte, e de semblante.',
        'E aconteceu depois destas coisas que a mulher do seu senhor pôs os seus olhos em José, e disse: Deita-te comigo.',
        'Porém ele recusou, e disse à mulher do seu senhor: Eis que o meu senhor não sabe do que há em casa comigo, e entregou em minha mão tudo o que tem;',
        'Ninguém há maior do que eu nesta casa, e nenhuma coisa me vedou, senão a ti, porquanto tu és sua mulher; como pois faria eu tamanha maldade, e pecaria contra Deus?',
        'E aconteceu que falando ela cada dia a José, e não lhe dando ele ouvidos, para deitar-se com ela, e estar com ela,',
        'Sucedeu num certo dia que ele veio à casa para fazer seu serviço; e nenhum dos da casa estava ali;',
        'E ela lhe pegou pela sua roupa, dizendo: Deita-te comigo. E ele deixou a sua roupa na mão dela, e fugiu, e saiu para fora.',
        'E aconteceu que, vendo ela que deixara a sua roupa em sua mão, e fugira para fora,',
        'Chamou aos homens de sua casa, e falou-lhes, dizendo: Vede, meu marido trouxe-nos um homem hebreu para escarnecer de nós; veio a mim para deitar-se comigo, e eu gritei com grande voz;',
        'E aconteceu que, ouvindo ele que eu levantava a minha voz e gritava, deixou a sua roupa comigo, e fugiu, e saiu para fora.',
        'E ela pôs a sua roupa perto de si, até que o seu senhor voltou à sua casa.',
        'Então falou-lhe conforme as mesmas palavras, dizendo: Veio a mim o servo hebreu, que nos trouxeste, para escarnecer de mim;',
        'E aconteceu que, levantando eu a minha voz e gritando, ele deixou a sua roupa comigo, e fugiu para fora.',
        'E aconteceu que, ouvindo o seu senhor as palavras de sua mulher, que lhe falava, dizendo: Conforme a estas mesmas palavras me fez teu servo, a sua ira se acendeu.',
        'E o senhor de José o tomou, e o entregou na casa do cárcere, no lugar onde os presos do rei estavam encarcerados; assim esteve ali na casa do cárcere.',
        'O Senhor, porém, estava com José, e estendeu sobre ele a sua benignidade, e deu-lhe graça aos olhos do carcereiro-mor.',
        'E o carcereiro-mor entregou na mão de José todos os presos que estavam na casa do cárcere, e ele ordenava tudo o que se fazia ali.',
        'E o carcereiro-mor não teve cuidado de nenhuma coisa que estava na mão dele, porquanto o Senhor estava com ele, e tudo o que fazia o Senhor prosperava.',
      ],
      [
        'E aconteceu, depois destas coisas, que o copeiro do rei do Egito, e o seu padeiro, ofenderam o seu senhor, o rei do Egito.',
        'E indignou-se Faraó muito contra os seus dois oficiais, contra o copeiro-mor e contra o padeiro-mor.',
        'E entregou-os à prisão, na casa do capitão da guarda, na casa do cárcere, no lugar onde José estava preso.',
        'E o capitão da guarda pô-los a cargo de José, para que os servisse; e estiveram muitos dias na prisão.',
        'E ambos tiveram um sonho, cada um seu sonho, na mesma noite, cada um conforme a interpretação do seu sonho, o copeiro e o padeiro do rei do Egito, que estavam presos na casa do cárcere.',
        'E veio José a eles pela manhã, e olhou para eles, e viu que estavam perturbados.',
        'Então perguntou aos oficiais de Faraó, que com ele estavam no cárcere da casa de seu senhor, dizendo: Por que estão hoje tristes os vossos semblantes?',
        'E eles lhe disseram: Tivemos um sonho, e ninguém há que o interprete. E José disse-lhes: Não são de Deus as interpretações? Contai-mo, peço-vos.',
        'Então contou o copeiro-mor o seu sonho a José, e disse-lhe: Eis que em meu sonho havia uma vide diante da minha face.',
        'E na vide três sarmentos, e brotando ela, a sua flor saía, e os seus cachos amadureciam em uvas;',
        'E o copo de Faraó estava na minha mão, e eu tomava as uvas, e as espremia no copo de Faraó, e dava o copo na mão de Faraó.',
        'Então disse-lhe José: Esta é a sua interpretação: Os três sarmentos são três dias;',
        'Dentro ainda de três dias Faraó levantará a tua cabeça, e te restaurará ao teu estado, e darás o copo de Faraó na sua mão, conforme o costume antigo, quando eras seu copeiro.',
        'Porém lembra-te de mim, quando te for bem; e rogo-te que uses comigo de compaixão, e que faças menção de mim a Faraó, e faze-me sair desta casa;',
        'Porque, de fato, fui roubado da terra dos hebreus; e tampouco aqui nada tenho feito para que me pusessem nesta cova.',
        'Vendo então o padeiro-mor que tinha interpretado bem, disse a José: Eu também sonhei, e eis que três cestos brancos estavam sobre a minha cabeça;',
        'E no cesto mais alto havia de todos os manjares de Faraó, obra de padeiro; e as aves o comiam do cesto, de sobre a minha cabeça.',
        'Então respondeu José, e disse: Esta é a sua interpretação: Os três cestos são três dias;',
        'Dentro ainda de três dias Faraó tirará a tua cabeça e te pendurará num pau, e as aves comerão a tua carne de sobre ti.',
        'E aconteceu ao terceiro dia, o dia do nascimento de Faraó, que fez um banquete a todos os seus servos; e levantou a cabeça do copeiro-mor, e a cabeça do padeiro-mor, no meio dos seus servos.',
        'E fez tornar o copeiro-mor ao seu ofício de copeiro, e este deu o copo na mão de Faraó,',
        'Mas ao padeiro-mor enforcou, como José havia interpretado.',
        'O copeiro-mor, porém, não se lembrou de José, antes se esqueceu dele.',
      ],
      [
        'E aconteceu que, ao fim de dois anos inteiros, Faraó sonhou, e eis que estava em pé junto ao rio.',
        'E eis que subiam do rio sete vacas, formosas à vista e gordas de carne, e pastavam no prado.',
        'E eis que subiam do rio após elas outras sete vacas, feias à vista e magras de carne; e paravam junto às outras vacas na praia do rio.',
        'E as vacas feias à vista e magras de carne, comiam as sete vacas formosas à vista e gordas. Então acordou Faraó.',
        'Depois dormiu e sonhou outra vez, e eis que brotavam de um mesmo pé sete espigas cheias e boas.',
        'E eis que sete espigas miúdas, e queimadas do vento oriental, brotavam após elas.',
        'E as espigas miúdas devoravam as sete espigas grandes e cheias. Então acordou Faraó, e eis que era um sonho.',
        'E aconteceu que pela manhã o seu espírito perturbou-se, e enviou e chamou todos os adivinhadores do Egito, e todos os seus sábios; e Faraó contou-lhes os seus sonhos, mas ninguém havia que lhos interpretasse.',
        'Então falou o copeiro-mor a Faraó, dizendo: Das minhas ofensas me lembro hoje:',
        'Estando Faraó muito indignado contra os seus servos, e pondo-me sob prisão na casa do capitão da guarda, a mim e ao padeiro-mor,',
        'Então tivemos um sonho na mesma noite, eu e ele; sonhamos, cada um conforme a interpretação do seu sonho.',
        'E estava ali conosco um jovem hebreu, servo do capitão da guarda, e contamos-lhe os nossos sonhos e ele no-los interpretou, a cada um conforme o seu sonho.',
        'E como ele nos interpretou, assim aconteceu; a mim me foi restituído o meu cargo, e ele foi enforcado.',
        'Então mandou Faraó chamar a José, e o fizeram sair logo do cárcere; e barbeou-se e mudou as suas roupas e apresentou-se a Faraó.',
        'E Faraó disse a José: Eu tive um sonho, e ninguém há que o interprete; mas de ti ouvi dizer que quando ouves um sonho o interpretas.',
        'E respondeu José a Faraó, dizendo: Isso não está em mim; Deus dará resposta de paz a Faraó.',
        'Então disse Faraó a José: Eis que em meu sonho estava eu em pé na margem do rio,',
        'E eis que subiam do rio sete vacas gordas de carne e formosas à vista, e pastavam no prado.',
        'E eis que outras sete vacas subiam após estas, muito feias à vista e magras de carne; não tenho visto outras tais, quanto à fealdade, em toda a terra do Egito.',
        'E as vacas magras e feias comiam as primeiras sete vacas gordas;',
        'E entravam em suas entranhas, mas não se conhecia que houvessem entrado; porque o seu parecer era feio como no princípio. Então acordei.',
        'Depois vi em meu sonho, e eis que de um mesmo pé subiam sete espigas cheias e boas;',
        'E eis que sete espigas secas, miúdas e queimadas do vento oriental, brotavam após elas.',
        'E as sete espigas miúdas devoravam as sete espigas boas. E eu contei isso aos magos, mas ninguém houve que mo interpretasse.',
        'Então disse José a Faraó: O sonho de Faraó é um só; o que Deus há de fazer, mostrou-o a Faraó.',
        'As sete vacas formosas são sete anos, as sete espigas formosas também são sete anos, o sonho é um só.',
        'E as sete vacas feias à vista e magras, que subiam depois delas, são sete anos, e as sete espigas miúdas e queimadas do vento oriental, serão sete anos de fome.',
        'Esta é a palavra que tenho dito a Faraó; o que Deus há de fazer, mostrou-o a Faraó.',
        'E eis que vêm sete anos, e haverá grande fartura em toda a terra do Egito.',
        'E depois deles levantar-se-ão sete anos de fome, e toda aquela fartura será esquecida na terra do Egito, e a fome consumirá a terra;',
        'E não será conhecida a abundância na terra, por causa daquela fome que haverá depois; porquanto será gravíssima.',
        'E que o sonho foi repetido duas vezes a Faraó, é porque esta coisa é determinada por Deus, e Deus se apressa em fazê-la.',
        'Portanto, Faraó previna-se agora de um homem entendido e sábio, e o ponha sobre a terra do Egito.',
        'Faça isso Faraó e ponha governadores sobre a terra, e tome a quinta parte da terra do Egito nos sete anos de fartura,',
        'E ajuntem toda a comida destes bons anos, que vêm, e amontoem o trigo debaixo da mão de Faraó, para mantimento nas cidades, e o guardem.',
        'Assim será o mantimento para provimento da terra, para os sete anos de fome, que haverá na terra do Egito; para que a terra não pereça de fome.',
        'E esta palavra foi boa aos olhos de Faraó, e aos olhos de todos os seus servos.',
        'E disse Faraó a seus servos: Acharíamos um homem como este em quem haja o espírito de Deus?',
        'Depois disse Faraó a José: Pois que Deus te fez saber tudo isto, ninguém há tão entendido e sábio como tu.',
        'Tu estarás sobre a minha casa, e por tua boca se governará todo o meu povo, somente no trono eu serei maior que tu.',
        'Disse mais Faraó a José: Vês aqui te tenho posto sobre toda a terra do Egito.',
        'E tirou Faraó o anel da sua mão, e o pôs na mão de José, e o fez vestir de roupas de linho fino, e pôs um colar de ouro no seu pescoço.',
        'E o fez subir no segundo carro que tinha, e clamavam diante dele: Ajoelhai. Assim o pôs sobre toda a terra do Egito.',
        'E disse Faraó a José: Eu sou Faraó; porém sem ti ninguém levantará a sua mão ou o seu pé em toda a terra do Egito.',
        'E Faraó chamou a José de Zafenate-Panéia, e deu-lhe por mulher a Azenate, filha de Potífera, sacerdote de Om; e saiu José por toda a terra do Egito.',
        'E José era da idade de trinta anos quando se apresentou a Faraó, rei do Egito. E saiu José da presença de Faraó e passou por toda a terra do Egito.',
        'E nos sete anos de fartura a terra produziu abundantemente.',
        'E ele ajuntou todo o mantimento dos sete anos, que houve na terra do Egito; e guardou o mantimento nas cidades, pondo nas mesmas o mantimento do campo que estava ao redor de cada cidade.',
        'Assim ajuntou José muitíssimo trigo, como a areia do mar, até que cessou de contar; porquanto não havia numeração.',
        'E nasceram a José dois filhos (antes que viesse um ano de fome), que lhe deu Azenate, filha de Potífera, sacerdote de Om.',
        'E chamou José ao primogênito Manassés, porque disse: Deus me fez esquecer de todo o meu trabalho, e de toda a casa de meu pai.',
        'E ao segundo chamou Efraim; porque disse: Deus me fez crescer na terra da minha aflição.',
        'Então acabaram-se os sete anos de fartura que havia na terra do Egito.',
        'E começaram a vir os sete anos de fome, como José tinha dito; e havia fome em todas as terras, mas em toda a terra do Egito havia pão.',
        'E tendo toda a terra do Egito fome, clamou o povo a Faraó por pão; e Faraó disse a todos os egípcios: Ide a José; o que ele vos disser, fazei.',
        'Havendo, pois, fome sobre toda a terra, abriu José tudo em que havia mantimento, e vendeu aos egípcios; porque a fome prevaleceu na terra do Egito.',
        'E de todas as terras vinham ao Egito, para comprar de José; porquanto a fome prevaleceu em todas as terras.',
      ],
      [
        'Vendo então Jacó que havia mantimento no Egito, disse a seus filhos: Por que estais olhando uns para os outros?',
        'Disse mais: Eis que tenho ouvido que há mantimentos no Egito; descei para lá, e comprai-nos dali, para que vivamos e não morramos.',
        'Então desceram os dez irmãos de José, para comprarem trigo no Egito.',
        'A Benjamim, porém, irmão de José, não enviou Jacó com os seus irmãos, porque dizia: Para que lhe não suceda, porventura, algum desastre.',
        'Assim, entre os que iam lá foram os filhos de Israel para comprar, porque havia fome na terra de Canaã.',
        'José, pois, era o governador daquela terra; ele vendia a todo o povo da terra; e os irmãos de José chegaram e inclinaram-se a ele, com o rosto em terra.',
        'E José, vendo os seus irmãos, conheceu-os; porém mostrou-se estranho para com eles, e falou-lhes asperamente, e disse-lhes: De onde vindes? E eles disseram: Da terra de Canaã, para comprarmos mantimento.',
        'José, pois, conheceu os seus irmãos; mas eles não o conheceram.',
        'Então José lembrou-se dos sonhos que havia tido deles e disse-lhes: Vós sois espias, e viestes para ver a nudez da terra.',
        'E eles lhe disseram: Não, senhor meu; mas teus servos vieram comprar mantimento.',
        'Todos nós somos filhos de um mesmo homem; somos homens de retidão; os teus servos não são espias.',
        'E ele lhes disse: Não; antes viestes para ver a nudez da terra.',
        'E eles disseram: Nós, teus servos, somos doze irmãos, filhos de um homem na terra de Canaã; e eis que o mais novo está com nosso pai hoje; mas um já não existe.',
        'Então lhes disse José: Isso é o que vos tenho dito, sois espias;',
        'Nisto sereis provados; pela vida de Faraó, não saireis daqui senão quando vosso irmão mais novo vier aqui.',
        'Enviai um dentre vós, que traga vosso irmão, mas vós ficareis presos, e vossas palavras sejam provadas, se há verdade convosco; e se não, pela vida de Faraó, vós sois espias.',
        'E pô-los juntos, em prisão, três dias.',
        'E ao terceiro dia disse-lhes José: Fazei isso, e vivereis; porque eu temo a Deus.',
        'Se sois homens de retidão, que fique um de vossos irmãos preso na casa de vossa prisão; e vós ide, levai mantimento para a fome de vossa casa,',
        'E trazei-me o vosso irmão mais novo, e serão verificadas vossas palavras, e não morrereis. E eles assim fizeram.',
        'Então disseram uns aos outros: Na verdade, somos culpados acerca de nosso irmão, pois vimos a angústia da sua alma, quando nos rogava; nós porém não ouvimos, por isso vem sobre nós esta angústia.',
        'E Rúben respondeu-lhes, dizendo: Não vo-lo dizia eu: Não pequeis contra o menino; mas não ouvistes; e vedes aqui, o seu sangue também é requerido.',
        'E eles não sabiam que José os entendia, porque havia intérprete entre eles.',
        'E retirou-se deles e chorou. Depois tornou a eles, e falou-lhes, e tomou a Simeão dentre eles, e amarrou-o perante os seus olhos.',
        'E ordenou José, que enchessem os seus sacos de trigo, e que lhes restituíssem o seu dinheiro a cada um no seu saco, e lhes dessem comida para o caminho; e fizeram-lhes assim.',
        'E carregaram o seu trigo sobre os seus jumentos e partiram dali.',
        'E, abrindo um deles o seu saco, para dar pasto ao seu jumento na estalagem, viu o seu dinheiro; porque eis que estava na boca do seu saco.',
        'E disse a seus irmãos: Devolveram o meu dinheiro, e ei-lo também aqui no saco. Então lhes desfaleceu o coração, e pasmavam, dizendo um ao outro: Que é isto que Deus nos tem feito?',
        'E vieram para Jacó, seu pai, na terra de Canaã; e contaram-lhe tudo o que lhes aconteceu, dizendo:',
        'O homem, o senhor da terra, falou conosco asperamente, e tratou-nos como espias da terra;',
        'Mas dissemos-lhe: Somos homens de retidão; não somos espias;',
        'Somos doze irmãos, filhos de nosso pai; um não mais existe, e o mais novo está hoje com nosso pai na terra de Canaã.',
        'E aquele homem, o senhor da terra, nos disse: Nisto conhecerei que vós sois homens de retidão; deixai comigo um de vossos irmãos, e tomai para a fome de vossas casas, e parti,',
        'E trazei-me vosso irmão mais novo; assim saberei que não sois espias, mas homens de retidão; então vos darei o vosso irmão e negociareis na terra.',
        'E aconteceu que, despejando eles os seus sacos, eis que cada um tinha o pacote com seu dinheiro no seu saco; e viram os pacotes com seu dinheiro, eles e seu pai, e temeram.',
        'Então Jacó, seu pai, disse-lhes: Tendes-me desfilhado; José já não existe e Simeão não está aqui; agora levareis a Benjamim. Todas estas coisas vieram sobre mim.',
        'Mas Rúben falou a seu pai, dizendo: Mata os meus dois filhos, se eu não tornar a trazê-lo para ti; entrega-o em minha mão, e tornarei a trazê-lo.',
        'Ele porém disse: Não descerá meu filho convosco; porquanto o seu irmão é morto, e só ele ficou. Se lhe suceder algum desastre no caminho por onde fordes, fareis descer minhas cãs com tristeza à sepultura.',
      ],
      [
        'E a fome era gravíssima na terra.',
        'E aconteceu que, como acabaram de comer o mantimento que trouxeram do Egito, disse-lhes seu pai: Voltai, comprai-nos um pouco de alimento.',
        'Mas Judá respondeu-lhe, dizendo: Fortemente nos protestou aquele homem, dizendo: Não vereis a minha face, se o vosso irmão não vier convosco.',
        'Se enviares conosco o nosso irmão, desceremos e te compraremos alimento;',
        'Mas se não o enviares, não desceremos; porquanto aquele homem nos disse: Não vereis a minha face, se o vosso irmão não vier convosco.',
        'E disse Israel: Por que me fizeste tal mal, fazendo saber àquele homem que tínheis ainda outro irmão?',
        'E eles disseram: Aquele homem particularmente nos perguntou por nós, e pela nossa parentela, dizendo: Vive ainda vosso pai? Tendes mais um irmão? E respondemos-lhe conforme as mesmas palavras. Podíamos nós saber que diria: Trazei vosso irmão?',
        'Então disse Judá a Israel, seu pai: Envia o jovem comigo, e levantar-nos-emos, e iremos, para que vivamos e não morramos, nem nós, nem tu, nem os nossos filhos.',
        'Eu serei fiador por ele, da minha mão o requererás; se eu não o trouxer, e não o puser perante a tua face, serei réu de crime para contigo para sempre.',
        'E se não nos tivéssemos detido, certamente já estaríamos segunda vez de volta.',
        'Então disse-lhes Israel, seu pai: Pois que assim é, fazei isso; tomai do mais precioso desta terra em vossos vasos, e levai ao homem um presente: um pouco do bálsamo e um pouco de mel, especiarias e mirra, terebinto e amêndoas;',
        'E tomai em vossas mãos dinheiro em dobro, e o dinheiro que voltou na boca dos vossos sacos tornai a levar em vossas mãos; bem pode ser que fosse erro.',
        'Tomai também a vosso irmão, e levantai-vos e voltai àquele homem;',
        'E Deus Todo-Poderoso vos dê misericórdia diante do homem, para que deixe vir convosco vosso outro irmão, e Benjamim; e eu, se for desfilhado, desfilhado ficarei.',
        'E os homens tomaram aquele presente, e dinheiro em dobro em suas mãos, e a Benjamim; e levantaram-se, e desceram ao Egito, e apresentaram-se diante de José.',
        'Vendo, pois, José a Benjamim com eles, disse ao que estava sobre a sua casa: Leva estes homens à casa, e mata reses, e prepara tudo; porque estes homens comerão comigo ao meio-dia.',
        'E o homem fez como José dissera, e levou-os à casa de José.',
        'Então temeram aqueles homens, porquanto foram levados à casa de José, e diziam: Por causa do dinheiro que dantes voltou nos nossos sacos, fomos trazidos aqui, para nos incriminar e cair sobre nós, para que nos tome por servos, e a nossos jumentos.',
        'Por isso chegaram-se ao homem que estava sobre a casa de José, e falaram com ele à porta da casa,',
        'E disseram: Ai! senhor meu, certamente descemos dantes a comprar mantimento;',
        'E aconteceu que, chegando à estalagem, e abrindo os nossos sacos, eis que o dinheiro de cada um estava na boca do seu saco, nosso dinheiro por seu peso; e tornamos a trazê-lo em nossas mãos;',
        'Também trouxemos outro dinheiro em nossas mãos, para comprar mantimento; não sabemos quem tenha posto o nosso dinheiro nos nossos sacos.',
        'E ele disse: Paz seja convosco, não temais; o vosso Deus, e o Deus de vosso pai, vos tem dado um tesouro nos vossos sacos; o vosso dinheiro me chegou a mim. E trouxe-lhes fora a Simeão.',
        'Depois levou os homens à casa de José, e deu-lhes água, e lavaram os seus pés; também deu pasto aos seus jumentos.',
        'E prepararam o presente, para quando José viesse ao meio-dia; porque tinham ouvido que ali haviam de comer pão.',
        'Vindo, pois, José à casa, trouxeram-lhe ali o presente que tinham em suas mãos; e inclinaram-se a ele até à terra.',
        'E ele lhes perguntou como estavam, e disse: Vosso pai, o ancião de quem falastes, está bem? Ainda vive?',
        'E eles disseram: Bem está o teu servo, nosso pai vive ainda. E abaixaram a cabeça, e inclinaram-se.',
        'E ele levantou os seus olhos, e viu a Benjamim, seu irmão, filho de sua mãe, e disse: Este é vosso irmão mais novo de quem falastes? Depois ele disse: Deus te dê a sua graça, meu filho.',
        'E José apressou-se, porque as suas entranhas comoveram-se por causa do seu irmão, e procurou onde chorar; e entrou na câmara, e chorou ali.',
        'Depois lavou o seu rosto, e saiu; e conteve-se, e disse: Ponde pão.',
        'E serviram-lhe à parte, e a eles também à parte, e aos egípcios, que comiam com ele, à parte; porque os egípcios não podem comer pão com os hebreus, porquanto é abominação para os egípcios.',
        'E assentaram-se diante dele, o primogênito segundo a sua primogenitura, e o menor segundo a sua menoridade; do que os homens se maravilhavam entre si.',
        'E apresentou-lhes as porções que estavam diante dele; porém a porção de Benjamim era cinco vezes maior do que as porções deles todos. E eles beberam, e se regalaram com ele.',
      ],
      [
        'E deu ordem ao que estava sobre a sua casa, dizendo: Enche de mantimento os sacos destes homens, quanto puderem levar, e põe o dinheiro de cada um na boca do seu saco.',
        'E o meu copo, o copo de prata, porás na boca do saco do mais novo, com o dinheiro do seu trigo. E fez conforme a palavra que José tinha dito.',
        'Vinda a luz da manhã, despediram-se estes homens, eles com os seus jumentos.',
        'Saindo eles da cidade, e não se havendo ainda distanciado, disse José ao que estava sobre a sua casa: Levanta-te, e persegue aqueles homens; e, alcançando-os, lhes dirás: Por que pagastes mal por bem?',
        'Não é este o copo em que bebe meu senhor e pelo qual bem adivinha? Procedestes mal no que fizestes.',
        'E alcançou-os, e falou-lhes as mesmas palavras.',
        'E eles disseram-lhe: Por que diz meu senhor tais palavras? Longe estejam teus servos de fazerem semelhante coisa.',
        'Eis que o dinheiro, que temos achado nas bocas dos nossos sacos, te tornamos a trazer desde a terra de Canaã; como, pois, furtaríamos da casa do teu senhor prata ou ouro?',
        'Aquele, com quem de teus servos for achado, morra; e ainda nós seremos escravos do meu senhor.',
        'E ele disse: Ora seja também assim conforme as vossas palavras; aquele com quem se achar será meu escravo, porém vós sereis desculpados.',
        'E eles apressaram-se e cada um pôs em terra o seu saco, e cada um abriu o seu saco.',
        'E buscou, começando do maior, e acabando no mais novo; e achou-se o copo no saco de Benjamim.',
        'Então rasgaram as suas vestes, e carregou cada um o seu jumento, e tornaram à cidade.',
        'E veio Judá com os seus irmãos à casa de José, porque ele ainda estava ali; e prostraram-se diante dele em terra.',
        'E disse-lhes José: Que é isto que fizestes? Não sabeis vós que um homem como eu pode, muito bem, adivinhar?',
        'Então disse Judá: Que diremos a meu senhor? Que falaremos? E como nos justificaremos? Achou Deus a iniqüidade de teus servos; eis que somos escravos de meu senhor, tanto nós como aquele em cuja mão foi achado o copo.',
        'Mas ele disse: Longe de mim que eu tal faça; o homem em cuja mão o copo foi achado, esse será meu servo; porém vós, subi em paz para vosso pai.',
        'Então Judá se chegou a ele, e disse: Ai! senhor meu, deixa, peço-te, o teu servo dizer uma palavra aos ouvidos de meu senhor, e não se acenda a tua ira contra o teu servo; porque tu és como Faraó.',
        'Meu senhor perguntou a seus servos, dizendo: Tendes vós pai, ou irmão?',
        'E dissemos a meu senhor: Temos um velho pai, e um filho da sua velhice, o mais novo, cujo irmão é morto; e só ele ficou de sua mãe, e seu pai o ama.',
        'Então tu disseste a teus servos: Trazei-mo a mim, e porei os meus olhos sobre ele.',
        'E nós dissemos a meu senhor: Aquele moço não poderá deixar a seu pai; se deixar a seu pai, este morrerá.',
        'Então tu disseste a teus servos: Se vosso irmão mais novo não descer convosco, nunca mais vereis a minha face.',
        'E aconteceu que, subindo nós a teu servo meu pai, e contando-lhe as palavras de meu senhor,',
        'Disse nosso pai: Voltai, comprai-nos um pouco de mantimento.',
        'E nós dissemos: Não poderemos descer; mas, se nosso irmão menor for conosco, desceremos; pois não poderemos ver a face do homem se este nosso irmão menor não estiver conosco.',
        'Então disse-nos teu servo, meu pai: Vós sabeis que minha mulher me deu dois filhos;',
        'E um ausentou-se de mim, e eu disse: Certamente foi despedaçado, e não o tenho visto até agora;',
        'Se agora também tirardes a este da minha face, e lhe acontecer algum desastre, fareis descer as minhas cãs com aflição à sepultura.',
        'Agora, pois, indo eu a teu servo, meu pai, e o moço não indo conosco, como a sua alma está ligada com a alma dele,',
        'Acontecerá que, vendo ele que o moço ali não está, morrerá; e teus servos farão descer as cãs de teu servo, nosso pai, com tristeza à sepultura.',
        'Porque teu servo se deu por fiador por este moço para com meu pai, dizendo: Se eu o não tornar para ti, serei culpado para com meu pai por todos os dias.',
        'Agora, pois, fique teu servo em lugar deste moço por escravo de meu senhor, e que suba o moço com os seus irmãos.',
        'Porque, como subirei eu a meu pai, se o moço não for comigo? para que não veja eu o mal que sobrevirá a meu pai.',
      ],
      [
        'Então José não se podia conter diante de todos os que estavam com ele; e clamou: Fazei sair daqui a todo o homem; e ninguém ficou com ele, quando José se deu a conhecer a seus irmãos.',
        'E levantou a sua voz com choro, de maneira que os egípcios o ouviam, e a casa de Faraó o ouviu.',
        'E disse José a seus irmãos: Eu sou José; vive ainda meu pai? E seus irmãos não lhe puderam responder, porque estavam pasmados diante da sua face.',
        'E disse José a seus irmãos: Peço-vos, chegai-vos a mim. E chegaram-se; então disse ele: Eu sou José vosso irmão, a quem vendestes para o Egito.',
        'Agora, pois, não vos entristeçais, nem vos pese aos vossos olhos por me haverdes vendido para cá; porque para conservação da vida, Deus me enviou adiante de vós.',
        'Porque já houve dois anos de fome no meio da terra, e ainda restam cinco anos em que não haverá lavoura nem sega.',
        'Pelo que Deus me enviou adiante de vós, para conservar vossa sucessão na terra, e para guardar-vos em vida por um grande livramento.',
        'Assim não fostes vós que me enviastes para cá, senão Deus, que me tem posto por pai de Faraó, e por senhor de toda a sua casa, e como regente em toda a terra do Egito.',
        'Apressai-vos, e subi a meu pai, e dizei-lhe: Assim tem dito o teu filho José: Deus me tem posto por senhor em toda a terra do Egito; desce a mim, e não te demores;',
        'E habitarás na terra de Gósen, e estarás perto de mim, tu e os teus filhos, e os filhos dos teus filhos, e as tuas ovelhas, e as tuas vacas, e tudo o que tens.',
        'E ali te sustentarei, porque ainda haverá cinco anos de fome, para que não pereças de pobreza, tu e tua casa, e tudo o que tens.',
        'E eis que vossos olhos, e os olhos de meu irmão Benjamim, vêem que é minha boca que vos fala.',
        'E fazei saber a meu pai toda a minha glória no Egito, e tudo o que tendes visto, e apressai-vos a fazer descer meu pai para cá.',
        'E lançou-se ao pescoço de Benjamim seu irmão, e chorou; e Benjamim chorou também ao seu pescoço.',
        'E beijou a todos os seus irmãos, e chorou sobre eles; e depois seus irmãos falaram com ele.',
        'E esta notícia ouviu-se na casa de Faraó: Os irmãos de José são vindos; e pareceu bem aos olhos de Faraó, e aos olhos de seus servos.',
        'E disse Faraó a José: Dize a teus irmãos: Fazei isto: carregai os vossos animais e parti, tornai à terra de Canaã.',
        'E tornai a vosso pai, e às vossas famílias, e vinde a mim; e eu vos darei o melhor da terra do Egito, e comereis da fartura da terra.',
        'A ti, pois, é ordenado: Fazei isto: tomai vós da terra do Egito carros para vossos meninos, para vossas mulheres, e para vosso pai, e vinde.',
        'E não vos pese coisa alguma dos vossos utensílios; porque o melhor de toda a terra do Egito será vosso.',
        'E os filhos de Israel fizeram assim. E José deu-lhes carros, conforme o mandado de Faraó; também lhes deu comida para o caminho.',
        'A todos lhes deu, a cada um, mudas de roupas; mas a Benjamim deu trezentas peças de prata, e cinco mudas de roupas.',
        'E a seu pai enviou semelhantemente dez jumentos carregados do melhor do Egito, e dez jumentos carregados de trigo e pão, e comida para seu pai, para o caminho.',
        'E despediu os seus irmãos, e partiram; e disse-lhes: Não contendais pelo caminho.',
        'E subiram do Egito, e vieram à terra de Canaã, a Jacó seu pai.',
        'Então lhe anunciaram, dizendo: José ainda vive, e ele também é regente em toda a terra do Egito. E o seu coração desmaiou, porque não os acreditava.',
        'Porém, havendo-lhe eles contado todas as palavras de José, que ele lhes falara, e vendo ele os carros que José enviara para levá-lo, reviveu o espírito de Jacó seu pai.',
        'E disse Israel: Basta; ainda vive meu filho José; eu irei e o verei antes que morra.',
      ],
      [
        'E partiu Israel com tudo quanto tinha, e veio a Berseba, e ofereceu sacrifícios ao Deus de seu pai Isaque.',
        'E falou Deus a Israel em visões de noite, e disse: Jacó, Jacó! E ele disse: Eis-me aqui.',
        'E disse: Eu sou Deus, o Deus de teu pai; não temas descer ao Egito, porque eu te farei ali uma grande nação.',
        'E descerei contigo ao Egito, e certamente te farei tornar a subir, e José porá a sua mão sobre os teus olhos.',
        'Então levantou-se Jacó de Berseba; e os filhos de Israel levaram a seu pai Jacó, e seus meninos, e as suas mulheres, nos carros que Faraó enviara para o levar.',
        'E tomaram o seu gado e os seus bens que tinham adquirido na terra de Canaã, e vieram ao Egito, Jacó e toda a sua descendência com ele;',
        'Os seus filhos e os filhos de seus filhos com ele, as filhas, e as filhas de seus filhos, e toda a sua descendência levou consigo ao Egito.',
        'E estes são os nomes dos filhos de Israel, que vieram ao Egito, Jacó e seus filhos: Rúben, o primogênito de Jacó.',
        'E os filhos de Rúben: Enoque, Palu, Hezrom e Carmi.',
        'E os filhos de Simeão: Jemuel, Jamim, Oade, Jaquim, Zoar e Saul, filho de uma mulher cananéia.',
        'E os filhos de Levi: Gérson, Coate e Merari.',
        'E os filhos de Judá: Er, Onã, Selá, Perez e Zerá; Er e Onã, porém, morreram na terra de Canaã; e os filhos de Perez foram Hezrom e Hamul.',
        'E os filhos de Issacar: Tola, Puva, Jó e Sinrom.',
        'E os filhos de Zebulom: Serede, Elom e Jaleel.',
        'Estes são os filhos de Lia, que ela deu a Jacó em Padã-Arã, além de Diná, sua filha; todas as almas de seus filhos e de suas filhas foram trinta e três.',
        'E os filhos de Gade: Zifiom, Hagi, Suni, Esbom, Eri, Arodi e Areli.',
        'E os filhos de Aser: Imna, Isvá, Isvi, Berias e Sera, a irmã deles; e os filhos de Berias: Héber e Malquiel.',
        'Estes são os filhos de Zilpa, a qual Labão deu à sua filha Lia; e deu a Jacó estas dezesseis almas.',
        'Os filhos de Raquel, mulher de Jacó: José e Benjamim.',
        'E nasceram a José na terra do Egito, Manassés e Efraim, que lhe deu Azenate, filha de Potífera, sacerdote de Om.',
        'E os filhos de Benjamim: Belá, Bequer, Asbel, Gera, Naamã, Eí, Rôs, Mupim, Hupim e Arde.',
        'Estes são os filhos de Raquel, que nasceram a Jacó, ao todo catorze almas.',
        'E o filho de Dã: Husim.',
        'E os filhos de Naftali: Jazeel, Guni, Jezer e Silém.',
        'Estes são os filhos de Bila, a qual Labão deu à sua filha Raquel; e deu estes a Jacó; todas as almas foram sete.',
        'Todas as almas que vieram com Jacó ao Egito, que saíram dos seus lombos, fora as mulheres dos filhos de Jacó, todas foram sessenta e seis almas.',
        'E os filhos de José, que lhe nasceram no Egito, eram duas almas. Todas as almas da casa de Jacó, que vieram ao Egito, eram setenta.',
        'E Jacó enviou Judá adiante de si a José, para o encaminhar a Gósen; e chegaram à terra de Gósen.',
        'Então José aprontou o seu carro, e subiu ao encontro de Israel, seu pai, a Gósen. E, apresentando-se-lhe, lançou-se ao seu pescoço, e chorou sobre o seu pescoço longo tempo.',
        'E Israel disse a José: Morra eu agora, pois já tenho visto o teu rosto, que ainda vives.',
        'Depois disse José a seus irmãos, e à casa de seu pai: Eu subirei e anunciarei a Faraó, e lhe direi: Meus irmãos e a casa de meu pai, que estavam na terra de Canaã, vieram a mim!',
        'E os homens são pastores de ovelhas, porque são homens de gado, e trouxeram consigo as suas ovelhas, e as suas vacas, e tudo o que têm.',
        'Quando, pois, acontecer que Faraó vos chamar, e disser: Qual é o vosso negócio?',
        'Então direis: Teus servos foram homens de gado desde a nossa mocidade até agora, tanto nós como os nossos pais; para que habiteis na terra de Gósen, porque todo o pastor de ovelhas é abominação aos egípcios.',
      ],
      [
        'Então veio José e anunciou a Faraó, e disse: Meu pai e os meus irmãos e as suas ovelhas, e as suas vacas, com tudo o que têm, são vindos da terra de Canaã, e eis que estão na terra de Gósen.',
        'E tomou uma parte de seus irmãos, a saber, cinco homens, e os pôs diante de Faraó.',
        'Então disse Faraó a seus irmãos: Qual é o vosso negócio? E eles disseram a Faraó: Teus servos são pastores de ovelhas, tanto nós como nossos pais.',
        'Disseram mais a Faraó: Viemos para peregrinar nesta terra; porque não há pasto para as ovelhas de teus servos, porquanto a fome é grave na terra de Canaã; agora, pois, rogamos-te que teus servos habitem na terra de Gósen.',
        'Então falou Faraó a José, dizendo: Teu pai e teus irmãos vieram a ti;',
        'A terra do Egito está diante de ti; no melhor da terra faze habitar teu pai e teus irmãos; habitem na terra de Gósen, e se sabes que entre eles há homens valentes, os porás por maiorais do gado, sobre o que eu tenho.',
        'E trouxe José a Jacó, seu pai, e o apresentou a Faraó; e Jacó abençoou a Faraó.',
        'E Faraó disse a Jacó: Quantos são os dias dos anos da tua vida?',
        'E Jacó disse a Faraó: Os dias dos anos das minhas peregrinações são cento e trinta anos, poucos e maus foram os dias dos anos da minha vida, e não chegaram aos dias dos anos da vida de meus pais nos dias das suas peregrinações.',
        'E Jacó abençoou a Faraó, e saiu da sua presença.',
        'E José fez habitar a seu pai e seus irmãos e deu-lhes possessão na terra do Egito, no melhor da terra, na terra de Ramessés, como Faraó ordenara.',
        'E José sustentou de pão a seu pai, seus irmãos e toda a casa de seu pai, segundo as suas famílias.',
        'E não havia pão em toda a terra, porque a fome era muito grave; de modo que a terra do Egito e a terra de Canaã desfaleciam por causa da fome.',
        'Então José recolheu todo o dinheiro que se achou na terra do Egito, e na terra de Canaã, pelo trigo que compravam; e José trouxe o dinheiro à casa de Faraó.',
        'Acabando-se, pois, o dinheiro da terra do Egito, e da terra de Canaã, vieram todos os egípcios a José, dizendo: Dá-nos pão; por que morreremos em tua presença? porquanto o dinheiro nos falta.',
        'E José disse: Dai o vosso gado, e eu vo-lo darei por vosso gado, se falta o dinheiro.',
        'Então trouxeram o seu gado a José; e José deu-lhes pão em troca de cavalos, e das ovelhas, e das vacas e dos jumentos; e os sustentou de pão aquele ano por todo o seu gado.',
        'E acabado aquele ano, vieram a ele no segundo ano e disseram-lhe: Não ocultaremos ao meu senhor que o dinheiro acabou; e meu senhor possui os animais, e nenhuma outra coisa nos ficou diante de meu senhor, senão o nosso corpo e a nossa terra;',
        'Por que morreremos diante dos teus olhos, tanto nós como a nossa terra? Compra-nos a nós e a nossa terra por pão, e nós e a nossa terra seremos servos de Faraó; e dá-nos semente, para que vivamos, e não morramos, e a terra não se desole.',
        'Assim José comprou toda a terra do Egito para Faraó, porque os egípcios venderam cada um o seu campo, porquanto a fome prevaleceu sobre eles; e a terra ficou sendo de Faraó.',
        'E, quanto ao povo, fê-lo passar às cidades, desde uma extremidade da terra do Egito até a outra extremidade.',
        'Somente a terra dos sacerdotes não a comprou, porquanto os sacerdotes tinham porção de Faraó, e eles comiam a sua porção que Faraó lhes tinha dado; por isso não venderam a sua terra.',
        'Então disse José ao povo: Eis que hoje tenho comprado a vós e a vossa terra para Faraó; eis aí tendes semente para vós, para que semeeis a terra.',
        'Há de ser, porém, que das colheitas dareis o quinto a Faraó, e as quatro partes serão vossas, para semente do campo, e para o vosso mantimento, e dos que estão nas vossas casas, e para que comam vossos filhos.',
        'E disseram: A vida nos tens dado; achemos graça aos olhos de meu senhor, e seremos servos de Faraó.',
        'José, pois, estabeleceu isto por estatuto, até ao dia de hoje, sobre a terra do Egito, que Faraó tirasse o quinto; só a terra dos sacerdotes não ficou sendo de Faraó.',
        'Assim habitou Israel na terra do Egito, na terra de Gósen, e nela tomaram possessão, e frutificaram, e multiplicaram-se muito.',
        'E Jacó viveu na terra do Egito dezessete anos, de sorte que os dias de Jacó, os anos da sua vida, foram cento e quarenta e sete anos.',
        'Chegando-se, pois, o tempo da morte de Israel, chamou a José, seu filho, e disse-lhe: Se agora tenho achado graça em teus olhos, rogo-te que ponhas a tua mão debaixo da minha coxa, e usa comigo de beneficência e verdade; rogo-te que não me enterres no Egito,',
        'Mas que eu jaza com os meus pais; por isso me levarás do Egito e me enterrarás na sepultura deles. E ele disse: Farei conforme a tua palavra.',
        'E disse ele: Jura-me. E ele jurou-lhe; e Israel inclinou-se sobre a cabeceira da cama.',
      ],
      [
        'E aconteceu, depois destas coisas, que alguém disse a José: Eis que teu pai está enfermo. Então tomou consigo os seus dois filhos, Manassés e Efraim.',
        'E alguém participou a Jacó, e disse: Eis que José teu filho vem a ti. E esforçou-se Israel, e assentou-se sobre a cama.',
        'E Jacó disse a José: O Deus Todo-Poderoso me apareceu em Luz, na terra de Canaã, e me abençoou.',
        'E me disse: Eis que te farei frutificar e multiplicar, e tornar-te-ei uma multidão de povos e darei esta terra à tua descendência depois de ti, em possessão perpétua.',
        'Agora, pois, os teus dois filhos, que te nasceram na terra do Egito, antes que eu viesse a ti no Egito, são meus: Efraim e Manassés serão meus, como Rúben e Simeão;',
        'Mas a tua geração, que gerarás depois deles, será tua; segundo o nome de seus irmãos serão chamados na sua herança.',
        'Vindo, pois, eu de Padã, morreu-me Raquel no caminho, na terra de Canaã, havendo ainda pequena distância para chegar a Efrata; e eu a sepultei ali, no caminho de Efrata, que é Belém.',
        'E Israel viu os filhos de José, e disse: Quem são estes?',
        'E José disse a seu pai: Eles são meus filhos, que Deus me tem dado aqui. E ele disse: Peço-te, traze-mos aqui, para que os abençoe.',
        'Os olhos de Israel, porém, estavam carregados de velhice, já não podia ver; e fê-los chegar a ele, e beijou-os, e abraçou-os.',
        'E Israel disse a José: Eu não cuidara ver o teu rosto; e eis que Deus me fez ver também a tua descendência.',
        'Então José os tirou dos joelhos de seu pai, e inclinou-se à terra diante da sua face.',
        'E tomou José a ambos, a Efraim na sua mão direita, à esquerda de Israel, e Manassés na sua mão esquerda, à direita de Israel, e fê-los chegar a ele.',
        'Mas Israel estendeu a sua mão direita e a pôs sobre a cabeça de Efraim, que era o menor, e a sua esquerda sobre a cabeça de Manassés, dirigindo as suas mãos propositadamente, não obstante Manassés ser o primogênito.',
        'E abençoou a José, e disse: O Deus, em cuja presença andaram os meus pais Abraão e Isaque, o Deus que me sustentou, desde que eu nasci até este dia;',
        'O anjo que me livrou de todo o mal, abençoe estes rapazes, e seja chamado neles o meu nome, e o nome de meus pais Abraão e Isaque, e multipliquem-se como peixes, em multidão, no meio da terra.',
        'Vendo, pois, José que seu pai punha a sua mão direita sobre a cabeça de Efraim, foi mau aos seus olhos; e tomou a mão de seu pai, para a transpor de sobre a cabeça de Efraim à cabeça de Manassés.',
        'E José disse a seu pai: Não assim, meu pai, porque este é o primogênito; põe a tua mão direita sobre a sua cabeça.',
        'Mas seu pai recusou, e disse: Eu o sei, meu filho, eu o sei; também ele será um povo, e também ele será grande; contudo o seu irmão menor será maior que ele, e a sua descendência será uma multidão de nações.',
        'Assim os abençoou naquele dia, dizendo: Em ti abençoará Israel, dizendo: Deus te faça como a Efraim e como a Manassés. E pôs a Efraim diante de Manassés.',
        'Depois disse Israel a José: Eis que eu morro, mas Deus será convosco, e vos fará tornar à terra de vossos pais.',
        'E eu tenho dado a ti um pedaço da terra a mais do que a teus irmãos, que tomei com a minha espada e com o meu arco, da mão dos amorreus.',
      ],
      [
        'Depois chamou Jacó a seus filhos, e disse: Ajuntai-vos, e anunciar-vos-ei o que vos há de acontecer nos dias vindouros;',
        'Ajuntai-vos, e ouvi, filhos de Jacó; e ouvi a Israel vosso pai.',
        'Rúben, tu és meu primogênito, minha força e o princípio de meu vigor, o mais excelente em alteza e o mais excelente em poder.',
        'Impetuoso como a água, não serás o mais excelente, porquanto subiste ao leito de teu pai. Então o contaminaste; subiu à minha cama.',
        'Simeão e Levi são irmãos; as suas espadas são instrumentos de violência.',
        'No seu secreto conselho não entre minha alma, com a sua congregação minha glória não se ajunte; porque no seu furor mataram homens, e na sua teima arrebataram bois.',
        'Maldito seja o seu furor, pois era forte, e a sua ira, pois era dura; eu os dividirei em Jacó, e os espalharei em Israel.',
        'Judá, a ti te louvarão os teus irmãos; a tua mão será sobre o pescoço de teus inimigos; os filhos de teu pai a ti se inclinarão.',
        'Judá é um leãozinho, da presa subiste, filho meu; encurva-se, e deita-se como um leão, e como um leão velho; quem o despertará?',
        'O cetro não se arredará de Judá, nem o legislador dentre seus pés, até que venha Siló; e a ele se congregarão os povos.',
        'Ele amarrará o seu jumentinho à vide, e o filho da sua jumenta à cepa mais excelente; ele lavará a sua roupa no vinho, e a sua capa em sangue de uvas.',
        'Os olhos serão vermelhos de vinho, e os dentes brancos de leite.',
        'Zebulom habitará no porto dos mares, e será como porto dos navios, e o seu termo será para Sidom.',
        'Issacar é jumento de fortes ossos, deitado entre dois fardos.',
        'E viu ele que o descanso era bom, e que a terra era deliciosa e abaixou seu ombro para acarretar, e serviu debaixo de tributo.',
        'Dã julgará o seu povo, como uma das tribos de Israel.',
        'Dã será serpente junto ao caminho, uma víbora junto à vereda, que morde os calcanhares do cavalo, e faz cair o seu cavaleiro por detrás.',
        'A tua salvação espero, ó Senhor!',
        'Quanto a Gade, uma tropa o acometerá; mas ele a acometerá por fim.',
        'De Aser, o seu pão será gordo, e ele dará delícias reais.',
        'Naftali é uma gazela solta; ele dá palavras formosas.',
        'José é um ramo frutífero, ramo frutífero junto à fonte; seus ramos correm sobre o muro.',
        'Os flecheiros lhe deram amargura, e o flecharam e odiaram.',
        'O seu arco, porém, susteve-se no forte, e os braços de suas mãos foram fortalecidos pelas mãos do Valente de Jacó (de onde é o pastor e a pedra de Israel).',
        'Pelo Deus de teu pai, o qual te ajudará, e pelo Todo-Poderoso, o qual te abençoará com bênçãos dos altos céus, com bênçãos do abismo que está embaixo, com bênçãos dos seios e da madre.',
        'As bênçãos de teu pai excederão as bênçãos de meus pais, até à extremidade dos outeiros eternos; elas estarão sobre a cabeça de José, e sobre o alto da cabeça do que foi separado de seus irmãos.',
        'Benjamim é lobo que despedaça; pela manhã comerá a presa, e à tarde repartirá o despojo.',
        'Todas estas são as doze tribos de Israel; e isto é o que lhes falou seu pai quando os abençoou; a cada um deles abençoou segundo a sua bênção.',
        'Depois ordenou-lhes, e disse-lhes: Eu me congrego ao meu povo; sepultai-me com meus pais, na cova que está no campo de Efrom, o heteu,',
        'Na cova que está no campo de Macpela, que está em frente de Manre, na terra de Canaã, a qual Abraão comprou com aquele campo de Efrom, o heteu, por herança de sepultura.',
        'Ali sepultaram a Abraão e a Sara sua mulher; ali sepultaram a Isaque e a Rebeca sua mulher; e ali eu sepultei a Lia.',
        'O campo e a cova que está nele, foram comprados aos filhos de Hete.',
        'Acabando, pois, Jacó de dar instruções a seus filhos, encolheu os pés na cama, e expirou, e foi congregado ao seu povo.',
      ],
      [
        'Então José se lançou sobre o rosto de seu pai e chorou sobre ele, e o beijou.',
        'E José ordenou aos seus servos, os médicos, que embalsamassem a seu pai; e os médicos embalsamaram a Israel.',
        'E cumpriram-se-lhe quarenta dias; porque assim se cumprem os dias daqueles que se embalsamam; e os egípcios o choraram setenta dias.',
        'Passados, pois, os dias de seu choro, falou José à casa de Faraó, dizendo: Se agora tenho achado graça aos vossos olhos, rogo-vos que faleis aos ouvidos de Faraó, dizendo:',
        'Meu pai me fez jurar, dizendo: Eis que eu morro; em meu sepulcro, que cavei para mim na terra de Canaã, ali me sepultarás. Agora, pois, te peço, que eu suba, para que sepulte a meu pai; então voltarei.',
        'E Faraó disse: Sobe, e sepulta a teu pai como ele te fez jurar.',
        'E José subiu para sepultar a seu pai; e subiram com ele todos os servos de Faraó, os anciãos da sua casa, e todos os anciãos da terra do Egito.',
        'Como também toda a casa de José, e seus irmãos, e a casa de seu pai; somente deixaram na terra de Gósen os seus meninos, e as suas ovelhas e as suas vacas.',
        'E subiram também com ele, tanto carros como gente a cavalo; e o cortejo foi grandíssimo.',
        'Chegando eles, pois, à eira de Atade, que está além do Jordão, fizeram um grande e dolorido pranto; e fez a seu pai uma grande lamentação por sete dias.',
        'E vendo os moradores da terra, os cananeus, o luto na eira de Atade, disseram: É este o pranto grande dos egípcios. Por isso chamou-se-lhe Abel-Mizraim, que está além do Jordão.',
        'E fizeram-lhe os seus filhos assim como ele lhes ordenara.',
        'Pois os seus filhos o levaram à terra de Canaã, e o sepultaram na cova do campo de Macpela, que Abraão tinha comprado com o campo, por herança de sepultura de Efrom, o heteu, em frente de Manre.',
        'Depois de haver sepultado seu pai, voltou José para o Egito, ele e seus irmãos, e todos os que com ele subiram a sepultar seu pai.',
        'Vendo então os irmãos de José que seu pai já estava morto, disseram: Porventura nos odiará José e certamente nos retribuirá todo o mal que lhe fizemos.',
        'Portanto mandaram dizer a José: Teu pai ordenou, antes da sua morte, dizendo:',
        'Assim direis a José: Perdoa, rogo-te, a transgressão de teus irmãos, e o seu pecado, porque te fizeram mal; agora, pois, rogamos-te que perdoes a transgressão dos servos do Deus de teu pai. E José chorou quando eles lhe falavam.',
        'Depois vieram também seus irmãos, e prostraram-se diante dele, e disseram: Eis-nos aqui por teus servos.',
        'E José lhes disse: Não temais; porventura estou eu em lugar de Deus?',
        'Vós bem intentastes mal contra mim; porém Deus o intentou para bem, para fazer como se vê neste dia, para conservar muita gente com vida.',
        'Agora, pois, não temais; eu vos sustentarei a vós e a vossos filhos. Assim os consolou, e falou segundo o coração deles.',
        'José, pois, habitou no Egito, ele e a casa de seu pai; e viveu José cento e dez anos.',
        'E viu José os filhos de Efraim, da terceira geração; também os filhos de Maquir, filho de Manassés, nasceram sobre os joelhos de José.',
        'E disse José a seus irmãos: Eu morro; mas Deus certamente vos visitará, e vos fará subir desta terra à terra que jurou a Abraão, a Isaque e a Jacó.',
        'E José fez jurar os filhos de Israel, dizendo: Certamente vos visitará Deus, e fareis transportar os meus ossos daqui.',
        'E morreu José da idade de cento e dez anos, e o embalsamaram e o puseram num caixão no Egito.',
      ],
    ],
    livro: 'genesis',
  },
  {
    abbrev: 'ex',
    capitulos: [
      [
        'Estes pois são os nomes dos filhos de Israel, que entraram no Egito com Jacó; cada um entrou com sua casa:',
        'Rúben, Simeão, Levi, e Judá;',
        'Issacar, Zebulom, e Benjamim;',
        'Dã e Naftali, Gade e Aser.',
        'Todas as almas, pois, que procederam dos lombos de Jacó, foram setenta almas; José, porém, estava no Egito.',
        'Faleceu José, e todos os seus irmãos, e toda aquela geração.',
        'E os filhos de Israel frutificaram, aumentaram muito, e multiplicaram-se, e foram fortalecidos grandemente; de maneira que a terra se encheu deles.',
        'E levantou-se um novo rei sobre o Egito, que não conhecera a José;',
        'O qual disse ao seu povo: Eis que o povo dos filhos de Israel é muito, e mais poderoso do que nós.',
        'Eia, usemos de sabedoria para com eles, para que não se multipliquem, e aconteça que, vindo guerra, eles também se ajuntem com os nossos inimigos, e pelejem contra nós, e subam da terra.',
        'E puseram sobre eles maiorais de tributos, para os afligirem com suas cargas. Porque edificaram a Faraó cidades-armazéns, Pitom e Ramessés.',
        'Mas quanto mais os afligiam, tanto mais se multiplicavam, e tanto mais cresciam; de maneira que se enfadavam por causa dos filhos de Israel.',
        'E os egípcios faziam servir os filhos de Israel com dureza;',
        'Assim que lhes fizeram amargar a vida com dura servidão, em barro e em tijolos, e com todo o trabalho no campo; com todo o seu serviço, em que os obrigavam com dureza.',
        'E o rei do Egito falou às parteiras das hebréias (das quais o nome de uma era Sifrá, e o da outra Puá),',
        'E disse: Quando ajudardes a dar à luz às hebréias, e as virdes sobre os assentos, se for filho, matai-o; mas se for filha, então viva.',
        'As parteiras, porém, temeram a Deus e não fizeram como o rei do Egito lhes dissera, antes conservavam os meninos com vida.',
        'Então o rei do Egito chamou as parteiras e disse-lhes: Por que fizestes isto, deixando os meninos com vida?',
        'E as parteiras disseram a Faraó: É que as mulheres hebréias não são como as egípcias; porque são vivas, e já têm dado à luz antes que a parteira venha a elas.',
        'Portanto Deus fez bem às parteiras. E o povo se aumentou, e se fortaleceu muito.',
        'E aconteceu que, como as parteiras temeram a Deus, ele estabeleceu-lhes casas.',
        'Então ordenou Faraó a todo o seu povo, dizendo: A todos os filhos que nascerem lançareis no rio, mas a todas as filhas guardareis com vida.',
      ],
      [
        'E foi um homem da casa de Levi e casou com uma filha de Levi.',
        'E a mulher concebeu e deu à luz um filho; e, vendo que ele era formoso, escondeu-o três meses.',
        'Não podendo, porém, mais escondê-lo, tomou uma arca de juncos, e a revestiu com barro e betume; e, pondo nela o menino, a pôs nos juncos à margem do rio.',
        'E sua irmã postou-se de longe, para saber o que lhe havia de acontecer.',
        'E a filha de Faraó desceu a lavar-se no rio, e as suas donzelas passeavam, pela margem do rio; e ela viu a arca no meio dos juncos, e enviou a sua criada, que a tomou.',
        'E abrindo-a, viu ao menino e eis que o menino chorava; e moveu-se de compaixão dele, e disse: Dos meninos dos hebreus é este.',
        'Então disse sua irmã à filha de Faraó: Irei chamar uma ama das hebréias, que crie este menino para ti?',
        'E a filha de Faraó disse-lhe: Vai. Foi, pois, a moça, e chamou a mãe do menino.',
        'Então lhe disse a filha de Faraó: Leva este menino, e cria-mo; eu te darei teu salário. E a mulher tomou o menino, e criou-o.',
        'E, quando o menino já era grande, ela o trouxe à filha de Faraó, a qual o adotou; e chamou-lhe Moisés, e disse: Porque das águas o tenho tirado.',
        'E aconteceu naqueles dias que, sendo Moisés já homem, saiu a seus irmãos, e atentou para as suas cargas; e viu que um egípcio feria a um hebreu, homem de seus irmãos.',
        'E olhou a um e a outro lado e, vendo que não havia ninguém ali, matou ao egípcio, e escondeu-o na areia.',
        'E tornou a sair no dia seguinte, e eis que dois homens hebreus contendiam; e disse ao injusto: Por que feres a teu próximo?',
        'O qual disse: Quem te tem posto a ti por maioral e juiz sobre nós? Pensas matar-me, como mataste o egípcio? Então temeu Moisés, e disse: Certamente este negócio foi descoberto.',
        'Ouvindo, pois, Faraó este caso, procurou matar a Moisés; mas Moisés fugiu de diante da face de Faraó, e habitou na terra de Midiã, e assentou-se junto a um poço.',
        'E o sacerdote de Midiã tinha sete filhas, as quais vieram tirar água, e encheram os bebedouros, para dar de beber ao rebanho de seu pai.',
        'Então vieram os pastores, e expulsaram-nas dali; Moisés, porém, levantou-se e defendeu-as, e deu de beber ao rebanho.',
        'E voltando elas a Reuel seu pai, ele disse: Por que hoje tornastes tão depressa?',
        'E elas disseram: Um homem egípcio nos livrou da mão dos pastores; e também nos tirou água em abundância, e deu de beber ao rebanho.',
        'E disse a suas filhas: E onde está ele? Por que deixastes o homem? Chamai-o para que coma pão.',
        'E Moisés consentiu em morar com aquele homem; e ele deu a Moisés sua filha Zípora,',
        'A qual deu à luz um filho, a quem ele chamou Gérson, porque disse: Peregrino fui em terra estranha.',
        'E aconteceu, depois de muitos dias, que morrendo o rei do Egito, os filhos de Israel suspiraram por causa da servidão, e clamaram; e o seu clamor subiu a Deus por causa de sua servidão.',
        'E ouviu Deus o seu gemido, e lembrou-se Deus da sua aliança com Abraão, com Isaque, e com Jacó;',
        'E viu Deus os filhos de Israel, e atentou Deus para a sua condição.',
      ],
      [
        'E apascentava Moisés o rebanho de Jetro, seu sogro, sacerdote em Midiã; e levou o rebanho atrás do deserto, e chegou ao monte de Deus, a Horebe.',
        'E apareceu-lhe o anjo do Senhor em uma chama de fogo do meio duma sarça; e olhou, e eis que a sarça ardia no fogo, e a sarça não se consumia.',
        'E Moisés disse: Agora me virarei para lá, e verei esta grande visão, porque a sarça não se queima.',
        'E vendo o Senhor que se virava para ver, bradou Deus a ele do meio da sarça, e disse: Moisés, Moisés. Respondeu ele: Eis-me aqui.',
        'E disse: Não te chegues para cá; tira os sapatos de teus pés; porque o lugar em que tu estás é terra santa.',
        'Disse mais: Eu sou o Deus de teu pai, o Deus de Abraão, o Deus de Isaque, e o Deus de Jacó. E Moisés encobriu o seu rosto, porque temeu olhar para Deus.',
        'E disse o Senhor: Tenho visto atentamente a aflição do meu povo, que está no Egito, e tenho ouvido o seu clamor por causa dos seus exatores, porque conheci as suas dores.',
        'Portanto desci para livrá-lo da mão dos egípcios, e para fazê-lo subir daquela terra, a uma terra boa e larga, a uma terra que mana leite e mel; ao lugar do cananeu, e do heteu, e do amorreu, e do perizeu, e do heveu, e do jebuseu.',
        'E agora, eis que o clamor dos filhos de Israel é vindo a mim, e também tenho visto a opressão com que os egípcios os oprimem.',
        'Vem agora, pois, e eu te enviarei a Faraó para que tires o meu povo (os filhos de Israel) do Egito.',
        'Então Moisés disse a Deus: Quem sou eu, que vá a Faraó e tire do Egito os filhos de Israel?',
        'E disse: Certamente eu serei contigo; e isto te será por sinal de que eu te enviei: Quando houveres tirado este povo do Egito, servireis a Deus neste monte.',
        'Então disse Moisés a Deus: Eis que quando eu for aos filhos de Israel, e lhes disser: O Deus de vossos pais me enviou a vós; e eles me disserem: Qual é o seu nome? Que lhes direi?',
        'E disse Deus a Moisés: EU SOU O QUE SOU. Disse mais: Assim dirás aos filhos de Israel: EU SOU me enviou a vós.',
        'E Deus disse mais a Moisés: Assim dirás aos filhos de Israel: O Senhor Deus de vossos pais, o Deus de Abraão, o Deus de Isaque, e o Deus de Jacó, me enviou a vós; este é meu nome eternamente, e este é meu memorial de geração em geração.',
        'Vai, e ajunta os anciãos de Israel e dize-lhes: O Senhor Deus de vossos pais, o Deus de Abraão, de Isaque e de Jacó, me apareceu, dizendo: Certamente vos tenho visitado e visto o que vos é feito no Egito.',
        'Portanto eu disse: Far-vos-ei subir da aflição do Egito à terra do cananeu, do heteu, do amorreu, do perizeu, do heveu e do jebuseu, a uma terra que mana leite e mel.',
        'E ouvirão a tua voz; e irás, tu com os anciãos de Israel, ao rei do Egito, e dir-lhe-eis: O Senhor Deus dos hebreus nos encontrou. Agora, pois, deixa-nos ir caminho de três dias para o deserto, para que sacrifiquemos ao Senhor nosso Deus.',
        'Eu sei, porém, que o rei do Egito não vos deixará ir, nem ainda por uma mão forte.',
        'Porque eu estenderei a minha mão, e ferirei ao Egito com todas as minhas maravilhas que farei no meio dele; depois vos deixará ir.',
        'E eu darei graça a este povo aos olhos dos egípcios; e acontecerá que, quando sairdes, não saireis vazios,',
        'Porque cada mulher pedirá à sua vizinha e à sua hóspeda jóias de prata, e jóias de ouro, e vestes, as quais poreis sobre vossos filhos e sobre vossas filhas; e despojareis os egípcios.',
      ],
      [
        'Então respondeu Moisés, e disse: Mas eis que não me crerão, nem ouvirão a minha voz, porque dirão: O SENHOR não te apareceu.',
        'E o Senhor disse-lhe: Que é isso na tua mão? E ele disse: Uma vara.',
        'E ele disse: Lança-a na terra. Ele a lançou na terra, e tornou-se em cobra; e Moisés fugia dela.',
        'Então disse o Senhor a Moisés: Estende a tua mão e pega-lhe pela cauda. E estendeu sua mão, e pegou-lhe pela cauda, e tornou-se em vara na sua mão;',
        'Para que creiam que te apareceu o Senhor Deus de seus pais, o Deus de Abraão, o Deus de Isaque e o Deus de Jacó.',
        'E disse-lhe mais o Senhor: Põe agora a tua mão no teu seio. E, tirando-a, eis que a sua mão estava leprosa, branca como a neve.',
        'E disse: Torna a por a tua mão no teu seio. E tornou a colocar sua mão no seu seio; depois tirou-a do seu seio, e eis que se tornara como a sua carne.',
        'E acontecerá que, se eles não te crerem, nem ouvirem a voz do primeiro sinal, crerão à voz do derradeiro sinal;',
        'E se acontecer que ainda não creiam a estes dois sinais, nem ouvirem a tua voz, tomarás das águas do rio, e as derramarás na terra seca; e as águas, que tomarás do rio, tornar-se-ão em sangue sobre a terra seca.',
        'Então disse Moisés ao Senhor: Ah, meu Senhor! eu não sou homem eloqüente, nem de ontem nem de anteontem, nem ainda desde que tens falado ao teu servo; porque sou pesado de boca e pesado de língua.',
        'E disse-lhe o Senhor: Quem fez a boca do homem? ou quem fez o mudo, ou o surdo, ou o que vê, ou o cego? Não sou eu, o Senhor?',
        'Vai, pois, agora, e eu serei com a tua boca e te ensinarei o que hás de falar.',
        'Ele, porém, disse: Ah, meu Senhor! Envia pela mão daquele a quem tu hás de enviar.',
        'Então se acendeu a ira do Senhor contra Moisés, e disse: Não é Arão, o levita, teu irmão? Eu sei que ele falará muito bem; e eis que ele também sai ao teu encontro; e, vendo-te, se alegrará em seu coração.',
        'E tu lhe falarás, e porás as palavras na sua boca; e eu serei com a tua boca, e com a dele, ensinando-vos o que haveis de fazer.',
        'E ele falará por ti ao povo; e acontecerá que ele te será por boca, e tu lhe serás por Deus.',
        'Toma, pois, esta vara na tua mão, com que farás os sinais.',
        'Então foi Moisés, e voltou para Jetro, seu sogro, e disse-lhe: Eu irei agora, e tornarei a meus irmãos, que estão no Egito, para ver se ainda vivem. Disse, pois, Jetro a Moisés: Vai em paz.',
        'Disse também o Senhor a Moisés em Midiã: Vai, volta para o Egito; porque todos os que buscavam a tua alma morreram.',
        'Tomou, pois, Moisés sua mulher e seus filhos, e os levou sobre um jumento, e tornou à terra do Egito; e Moisés tomou a vara de Deus na sua mão.',
        'E disse o Senhor a Moisés: Quando voltares ao Egito, atenta que faças diante de Faraó todas as maravilhas que tenho posto na tua mão; mas eu lhe endurecerei o coração, para que não deixe ir o povo.',
        'Então dirás a Faraó: Assim diz o Senhor: Israel é meu filho, meu primogênito.',
        'E eu te tenho dito: Deixa ir o meu filho, para que me sirva; mas tu recusaste deixá-lo ir; eis que eu matarei a teu filho, o teu primogênito.',
        'E aconteceu no caminho, numa estalagem, que o Senhor o encontrou, e o quis matar.',
        'Então Zípora tomou uma pedra aguda, e circuncidou o prepúcio de seu filho, e lançou-o a seus pés, e disse: Certamente me és um esposo sanguinário.',
        'E desviou-se dele. Então ela disse: Esposo sanguinário, por causa da circuncisão.',
        'Disse o Senhor a Arão: Vai ao deserto, ao encontro de Moisés. E ele foi, e encontrou-o no monte de Deus, e beijou-o.',
        'E relatou Moisés a Arão todas as palavras do Senhor, com que o enviara, e todos os sinais que lhe mandara.',
        'Então foram Moisés e Arão, e ajuntaram todos os anciãos dos filhos de Israel.',
        'E Arão falou todas as palavras que o Senhor falara a Moisés e fez os sinais perante os olhos do povo.',
        'E o povo creu; e quando ouviram que o Senhor visitava aos filhos de Israel, e que via a sua aflição, inclinaram-se, e adoraram.',
      ],
      [
        'E depois foram Moisés e Arão e disseram a Faraó: Assim diz o SENHOR Deus de Israel: Deixa ir o meu povo, para que me celebre uma festa no deserto.',
        'Mas Faraó disse: Quem é o Senhor, cuja voz eu ouvirei, para deixar ir Israel? Não conheço o Senhor, nem tampouco deixarei ir Israel.',
        'E eles disseram: O Deus dos hebreus nos encontrou; portanto deixa-nos agora ir caminho de três dias ao deserto, para que ofereçamos sacrifícios ao SENHOR nosso Deus, e ele não venha sobre nós com pestilência ou com espada.',
        'Então disse-lhes o rei do Egito: Moisés e Arão, por que fazeis cessar o povo das suas obras? Ide às vossas cargas.',
        'E disse também Faraó: Eis que o povo da terra já é muito, e vós os fazeis abandonar as suas cargas.',
        'Portanto deu ordem Faraó, naquele mesmo dia, aos exatores do povo, e aos seus oficiais, dizendo:',
        'Daqui em diante não torneis a dar palha ao povo, para fazer tijolos, como fizestes antes: vão eles mesmos, e colham palha para si.',
        'E lhes imporeis a conta dos tijolos que fizeram antes; nada diminuireis dela, porque eles estão ociosos; por isso clamam, dizendo: Vamos, sacrifiquemos ao nosso Deus.',
        'Agrave-se o serviço sobre estes homens, para que se ocupem nele e não confiem em palavras mentirosas.',
        'Então saíram os exatores do povo, e seus oficiais, e falaram ao povo, dizendo: Assim diz Faraó: Eu não vos darei palha;',
        'Ide vós mesmos, e tomai vós palha onde a achardes; porque nada se diminuirá de vosso serviço.',
        'Então o povo se espalhou por toda a terra do Egito, a colher restolho em lugar de palha.',
        'E os exatores os apertavam, dizendo: Acabai vossa obra, a tarefa de cada dia, como quando havia palha.',
        'E foram açoitados os oficiais dos filhos de Israel, que os exatores de Faraó tinham posto sobre eles, dizendo estes: Por que não acabastes vossa tarefa, fazendo tijolos como antes, assim também ontem e hoje?',
        'Por isso, os oficiais dos filhos de Israel, foram e clamaram a Faraó, dizendo: Por que fazes assim a teus servos?',
        'Palha não se dá a teus servos, e nos dizem: Fazei tijolos; e eis que teus servos são açoitados; porém o teu povo tem a culpa.',
        'Mas ele disse: Vós sois ociosos; vós sois ociosos; por isso dizeis: Vamos, sacrifiquemos ao Senhor.',
        'Ide, pois, agora, trabalhai; palha porém não se vos dará; contudo, dareis a conta dos tijolos.',
        'Então os oficiais dos filhos de Israel viram-se em aflição, porquanto se dizia: Nada diminuireis de vossos tijolos, da tarefa do dia no seu dia.',
        'E encontraram a Moisés e a Arão, que estavam defronte deles, quando saíram de Faraó.',
        'E disseram-lhes: O Senhor atente sobre vós, e julgue isso, porquanto fizestes o nosso caso repelente diante de Faraó, e diante de seus servos, dando-lhes a espada nas mãos, para nos matar.',
        'Então, tornando-se Moisés ao Senhor, disse: Senhor! por que fizeste mal a este povo? por que me enviaste?',
        'Porque desde que me apresentei a Faraó para falar em teu nome, ele maltratou a este povo; e de nenhuma sorte livraste o teu povo.',
      ],
      [
        'Então disse o SENHOR a Moisés: Agora verás o que hei de fazer a Faraó; porque por uma mão poderosa os deixará ir, sim, por uma mão poderosa os lançará de sua terra.',
        'Falou mais Deus a Moisés, e disse: Eu sou o Senhor.',
        'E eu apareci a Abraão, a Isaque, e a Jacó, como o Deus Todo-Poderoso; mas pelo meu nome, o Senhor, não lhes fui perfeitamente conhecido.',
        'E também estabeleci a minha aliança com eles, para dar-lhes a terra de Canaã, a terra de suas peregrinações, na qual foram peregrinos.',
        'E também tenho ouvido o gemido dos filhos de Israel, aos quais os egípcios fazem servir, e lembrei-me da minha aliança.',
        'Portanto dize aos filhos de Israel: Eu sou o Senhor, e vos tirarei de debaixo das cargas dos egípcios, e vos livrarei da servidão, e vos resgatarei com braço estendido e com grandes juízos.',
        'E eu vos tomarei por meu povo, e serei vosso Deus; e sabereis que eu sou o Senhor vosso Deus, que vos tiro de debaixo das cargas dos egípcios;',
        'E eu vos levarei à terra, acerca da qual levantei minha mão, jurando que a daria a Abraão, a Isaque e a Jacó, e vo-la darei por herança, eu o Senhor.',
        'Deste modo falou Moisés aos filhos de Israel, mas eles não ouviram a Moisés, por causa da angústia de espírito e da dura servidão.',
        'Falou mais o Senhor a Moisés, dizendo:',
        'Entra, e fala a Faraó rei do Egito, que deixe sair os filhos de Israel da sua terra.',
        'Moisés, porém, falou perante o Senhor, dizendo: Eis que os filhos de Israel não me têm ouvido; como, pois, Faraó me ouvirá? Também eu sou incircunciso de lábios.',
        'Todavia o Senhor falou a Moisés e a Arão, e deu-lhes mandamento para os filhos de Israel, e para Faraó rei do Egito, para que tirassem os filhos de Israel da terra do Egito.',
        'Estas são as cabeças das casas de seus pais: Os filhos de Rúben, o primogênito de Israel: Enoque e Palu, Hezrom e Carmi; estas são as famílias de Rúben.',
        'E os filhos de Simeão: Jemuel, Jamin, Oade, Jaquim, Zoar e Saul, filho de uma cananéia; estas são as famílias de Simeão.',
        'E estes são os nomes dos filhos de Levi, segundo as suas gerações: Gérson, Coate e Merari; e os anos da vida de Levi foram cento e trinta e sete anos.',
        'Os filhos de Gérson: Libni e Simei, segundo as suas famílias;',
        'E os filhos de Coate: Anrão, Izar, Hebrom e Uziel; e os anos da vida de Coate foram cento e trinta e três anos.',
        'E os filhos de Merari: Mali e Musi; estas são as famílias de Levi, segundo as suas gerações.',
        'E Anrão tomou por mulher a Joquebede, sua tia, e ela deu-lhe Arão e Moisés: e os anos da vida de Anrão foram cento e trinta e sete anos.',
        'E os filhos de Izar: Corá, Nefegue e Zicri.',
        'E os filhos de Uziel: Misael, Elzafã e Sitri.',
        'E Arão tomou por mulher a Eliseba, filha de Aminadabe, irmã de Naasson; e ela deu-lhe Nadabe, Abiú, Eleazar e Itamar.',
        'E os filhos de Corá: Assir, Elcana e Abiasafe; estas são as famílias dos coraítas.',
        'E Eleazar, filho de Arão, tomou por mulher uma das filhas de Putiel, e ela deu-lhe a Finéias; estes são os cabeças dos pais dos levitas, segundo as suas famílias.',
        'Estes são Arão e Moisés, aos quais o Senhor disse: Tirai os filhos de Israel da terra do Egito, segundo os seus exércitos.',
        'Estes são os que falaram a Faraó, rei do Egito, para que tirasse do Egito os filhos de Israel; estes são Moisés e Arão.',
        'E aconteceu que naquele dia, quando o Senhor falou a Moisés na terra do Egito,',
        'Falou o Senhor a Moisés, dizendo: Eu sou o Senhor; fala a Faraó, rei do Egito, tudo quanto eu te digo.',
        'Então disse Moisés perante o Senhor: Eis que eu sou incircunciso de lábios; como, pois, Faraó me ouvirá?',
      ],
      [
        'Então disse o SENHOR a Moisés: Eis que te tenho posto por deus sobre Faraó, e Arão, teu irmão, será o teu profeta.',
        'Tu falarás tudo o que eu te mandar; e Arão, teu irmão, falará a Faraó, que deixe ir os filhos de Israel da sua terra.',
        'Eu, porém, endurecerei o coração de Faraó, e multiplicarei na terra do Egito os meus sinais e as minhas maravilhas.',
        'Faraó, pois, não vos ouvirá; e eu porei minha mão sobre o Egito, e tirarei meus exércitos, meu povo, os filhos de Israel, da terra do Egito, com grandes juízos.',
        'Então os egípcios saberão que eu sou o Senhor, quando estender a minha mão sobre o Egito, e tirar os filhos de Israel do meio deles.',
        'Assim fizeram Moisés e Arão; como o Senhor lhes ordenara, assim fizeram.',
        'E Moisés era da idade de oitenta anos, e Arão da idade de oitenta e três anos quando falaram a Faraó.',
        'E o Senhor falou a Moisés e a Arão, dizendo:',
        'Quando Faraó vos falar, dizendo: Fazei vós um milagre, dirás a Arão: Toma a tua vara, e lança-a diante de Faraó; e se tornará em serpente.',
        'Então Moisés e Arão foram a Faraó, e fizeram assim como o Senhor ordenara; e lançou Arão a sua vara diante de Faraó, e diante dos seus servos, e tornou-se em serpente.',
        'E Faraó também chamou os sábios e encantadores; e os magos do Egito fizeram também o mesmo com os seus encantamentos.',
        'Porque cada um lançou sua vara, e tornaram-se em serpentes; mas a vara de Arão tragou as varas deles.',
        'Porém o coração de Faraó se endureceu, e não os ouviu, como o Senhor tinha falado.',
        'Então disse o Senhor a Moisés: O coração de Faraó está endurecido, recusa deixar ir o povo.',
        'Vai pela manhã a Faraó; eis que ele sairá às águas; põe-te em frente dele na beira do rio, e tomarás em tua mão a vara que se tornou em cobra.',
        'E lhe dirás: O Senhor Deus dos hebreus me tem enviado a ti, dizendo: Deixa ir o meu povo, para que me sirva no deserto; porém eis que até agora não tens ouvido.',
        'Assim diz o Senhor: Nisto saberás que eu sou o Senhor: Eis que eu com esta vara, que tenho em minha mão, ferirei as águas que estão no rio, e tornar-se-ão em sangue.',
        'E os peixes, que estão no rio, morrerão, e o rio cheirará mal; e os egípcios terão nojo de beber da água do rio.',
        'Disse mais o Senhor a Moisés: Dize a Arão: Toma tua vara, e estende a tua mão sobre as águas do Egito, sobre as suas correntes, sobre os seus rios, e sobre os seus tanques, e sobre todo o ajuntamento das suas águas, para que se tornem em sangue; e haja sangue em toda a terra do Egito, assim nos vasos de madeira como nos de pedra.',
        'E Moisés e Arão fizeram assim como o Senhor tinha mandado; e Arão levantou a vara, e feriu as águas que estavam no rio, diante dos olhos de Faraó, e diante dos olhos de seus servos; e todas as águas do rio se tornaram em sangue,',
        'E os peixes, que estavam no rio, morreram, e o rio cheirou mal, e os egípcios não podiam beber a água do rio; e houve sangue por toda a terra do Egito.',
        'Porém os magos do Egito também fizeram o mesmo com os seus encantamentos; de modo que o coração de Faraó se endureceu, e não os ouviu, como o Senhor tinha dito.',
        'E virou-se Faraó, e foi para sua casa; nem ainda nisto pôs seu coração.',
        'E todos os egípcios cavaram poços junto ao rio, para beberem água; porquanto não podiam beber da água do rio.',
        'Assim se cumpriram sete dias, depois que o Senhor ferira o rio.',
      ],
      [
        'Depois disse o SENHOR a Moisés: Vai a Faraó e dize-lhe: Assim diz o SENHOR: Deixa ir o meu povo, para que me sirva.',
        'E se recusares deixá-lo ir, eis que ferirei com rãs todos os teus termos.',
        'E o rio criará rãs, que subirão e virão à tua casa, e ao teu dormitório, e sobre a tua cama, e às casas dos teus servos, e sobre o teu povo, e aos teus fornos, e às tuas amassadeiras.',
        'E as rãs subirão sobre ti, e sobre o teu povo, e sobre todos os teus servos.',
        'Disse mais o Senhor a Moisés: Dize a Arão: Estende a tua mão com tua vara sobre as correntes, e sobre os rios, e sobre os tanques, e faze subir rãs sobre a terra do Egito.',
        'E Arão estendeu a sua mão sobre as águas do Egito, e subiram rãs, e cobriram a terra do Egito.',
        'Então os magos fizeram o mesmo com os seus encantamentos, e fizeram subir rãs sobre a terra do Egito.',
        'E Faraó chamou a Moisés e a Arão, e disse: Rogai ao Senhor que tire as rãs de mim e do meu povo; depois deixarei ir o povo, para que sacrifiquem ao Senhor.',
        'E disse Moisés a Faraó: Digna-te dizer-me quando é que hei de rogar por ti, e pelos teus servos, e por teu povo, para tirar as rãs de ti, e das tuas casas, e fiquem somente no rio?',
        'E ele disse: Amanhã. E Moisés disse: Seja conforme à tua palavra, para que saibas que ninguém há como o Senhor nosso Deus.',
        'E as rãs apartar-se-ão de ti, das tuas casas, dos teus servos, e do teu povo; somente ficarão no rio.',
        'Então saíram Moisés e Arão da presença de Faraó; e Moisés clamou ao Senhor por causa das rãs que tinha posto sobre Faraó.',
        'E o Senhor fez conforme a palavra de Moisés; e as rãs morreram nas casas, nos pátios, e nos campos.',
        'E ajuntaram-se em montões, e a terra cheirou mal.',
        'Vendo, pois, Faraó que havia descanso, endureceu o seu coração, e não os ouviu, como o Senhor tinha dito.',
        'Disse mais o Senhor a Moisés: Dize a Arão: Estende a tua vara, e fere o pó da terra, para que se torne em piolhos por toda a terra do Egito.',
        'E fizeram assim; e Arão estendeu a sua mão com a sua vara, e feriu o pó da terra, e havia muitos piolhos nos homens e no gado; todo o pó da terra se tornou em piolhos em toda a terra do Egito.',
        'E os magos fizeram também assim com os seus encantamentos para produzir piolhos, mas não puderam; e havia piolhos nos homens e no gado.',
        'Então disseram os magos a Faraó: Isto é o dedo de Deus. Porém o coração de Faraó se endureceu, e não os ouvia, como o Senhor tinha dito.',
        'Disse mais o Senhor a Moisés: Levanta-te pela manhã cedo e põe-te diante de Faraó; eis que ele sairá às águas; e dize-lhe: Assim diz o Senhor: Deixa ir o meu povo, para que me sirva.',
        'Porque se não deixares ir o meu povo, eis que enviarei enxames de moscas sobre ti, e sobre os teus servos, e sobre o teu povo, e às tuas casas; e as casas dos egípcios se encherão destes enxames, e também a terra em que eles estiverem.',
        'E naquele dia eu separarei a terra de Gósen, em que meu povo habita, que nela não haja enxames de moscas para que saibas que eu sou o Senhor no meio desta terra.',
        'E porei separação entre o meu povo e o teu povo; amanhã se fará este sinal.',
        'E o Senhor fez assim; e vieram grandes enxames de moscas à casa de Faraó e às casas dos seus servos, e sobre toda a terra do Egito; a terra foi corrompida destes enxames.',
        'Então chamou Faraó a Moisés e a Arão, e disse: Ide, e sacrificai ao vosso Deus nesta terra.',
        'E Moisés disse: Não convém que façamos assim, porque sacrificaríamos ao Senhor nosso Deus a abominação dos egípcios; eis que se sacrificássemos a abominação dos egípcios perante os seus olhos, não nos apedrejariam eles?',
        'Deixa-nos ir caminho de três dias ao deserto, para que sacrifiquemos ao Senhor nosso Deus, como ele nos disser.',
        'Então disse Faraó: Deixar-vos-ei ir, para que sacrifiqueis ao Senhor vosso Deus no deserto; somente que, indo, não vades longe; orai também por mim.',
        'E Moisés disse: Eis que saio de ti, e orarei ao Senhor, que estes enxames de moscas se retirem amanhã de Faraó, dos seus servos, e do seu povo; somente que Faraó não mais me engane, não deixando ir a este povo para sacrificar ao Senhor.',
        'Então saiu Moisés da presença de Faraó, e orou ao Senhor.',
        'E fez o Senhor conforme a palavra de Moisés, e os enxames de moscas se retiraram de Faraó, dos seus servos, e do seu povo; não ficou uma só.',
        'Mas endureceu Faraó ainda esta vez seu coração, e não deixou ir o povo.',
      ],
      [
        'Depois o SENHOR disse a Moisés: Vai a Faraó, e dize-lhe: Assim diz o SENHOR Deus dos hebreus: Deixa ir o meu povo, para que me sirva.',
        'Porque se recusares deixá-los ir, e ainda por força os detiveres,',
        'Eis que a mão do Senhor será sobre teu gado, que está no campo, sobre os cavalos, sobre os jumentos, sobre os camelos, sobre os bois, e sobre as ovelhas, com pestilência gravíssima.',
        'E o Senhor fará separação entre o gado dos israelitas e o gado dos egípcios, para que nada morra de tudo o que for dos filhos de Israel.',
        'E o Senhor assinalou certo tempo, dizendo: Amanhã fará o Senhor esta coisa na terra.',
        'E o Senhor fez isso no dia seguinte, e todo o gado dos egípcios morreu; porém do gado dos filhos de Israel não morreu nenhum.',
        'E Faraó enviou a ver, e eis que do gado de Israel não morrera nenhum; porém o coração de Faraó se agravou, e não deixou ir o povo.',
        'Então disse o Senhor a Moisés e a Arão: Tomai vossas mãos cheias de cinza do forno, e Moisés a espalhe para o céu diante dos olhos de Faraó;',
        'E tornar-se-á em pó miúdo sobre toda a terra do Egito, e se tornará em sarna, que arrebente em úlceras, nos homens e no gado, por toda a terra do Egito.',
        'E eles tomaram a cinza do forno, e puseram-se diante de Faraó, e Moisés a espalhou para o céu; e tornou-se em sarna, que arrebentava em úlceras nos homens e no gado;',
        'De maneira que os magos não podiam parar diante de Moisés, por causa da sarna; porque havia sarna nos magos, e em todos os egípcios.',
        'Porém o Senhor endureceu o coração de Faraó, e não os ouviu, como o Senhor tinha dito a Moisés.',
        'Então disse o Senhor a Moisés: Levanta-te pela manhã cedo, e põe-te diante de Faraó, e dize-lhe: Assim diz o Senhor Deus dos hebreus: Deixa ir o meu povo, para que me sirva;',
        'Porque esta vez enviarei todas as minhas pragas sobre o teu coração, e sobre os teus servos, e sobre o teu povo, para que saibas que não há outro como eu em toda a terra.',
        'Porque agora tenho estendido minha mão, para te ferir a ti e ao teu povo com pestilência, e para que sejas destruído da terra;',
        'Mas, deveras, para isto te mantive, para mostrar meu poder em ti, e para que o meu nome seja anunciado em toda a terra.',
        'Tu ainda te exaltas contra o meu povo, para não o deixar ir?',
        'Eis que amanhã por este tempo farei chover saraiva mui grave, qual nunca houve no Egito, desde o dia em que foi fundado até agora.',
        'Agora, pois, envia, recolhe o teu gado, e tudo o que tens no campo; todo o homem e animal, que for achado no campo, e não for recolhido à casa, a saraiva cairá sobre eles, e morrerão.',
        'Quem dos servos de Faraó temia a palavra do Senhor, fez fugir os seus servos e o seu gado para as casas;',
        'Mas aquele que não tinha considerado a palavra do Senhor deixou os seus servos e o seu gado no campo.',
        'Então disse o Senhor a Moisés: Estende a tua mão para o céu, e haverá saraiva em toda a terra do Egito, sobre os homens e sobre o gado, e sobre toda a erva do campo, na terra do Egito.',
        'E Moisés estendeu a sua vara para o céu, e o Senhor deu trovões e saraiva, e fogo corria pela terra; e o Senhor fez chover saraiva sobre a terra do Egito.',
        'E havia saraiva, e fogo misturado entre a saraiva, tão grave, qual nunca houve em toda a terra do Egito desde que veio a ser uma nação.',
        'E a saraiva feriu, em toda a terra do Egito, tudo quanto havia no campo, desde os homens até aos animais; também a saraiva feriu toda a erva do campo, e quebrou todas as árvores do campo.',
        'Somente na terra de Gósen, onde estavam os filhos de Israel, não havia saraiva.',
        'Então Faraó mandou chamar a Moisés e a Arão, e disse-lhes: Esta vez pequei; o Senhor é justo, mas eu e o meu povo ímpios.',
        'Orai ao Senhor (pois que basta) para que não haja mais trovões de Deus nem saraiva; e eu vos deixarei ir, e não ficareis mais aqui.',
        'Então lhe disse Moisés: Em saindo da cidade estenderei minhas mãos ao Senhor; os trovões cessarão, e não haverá mais saraiva; para que saibas que a terra é do Senhor.',
        'Todavia, quanto a ti e aos teus servos, eu sei que ainda não temereis diante do Senhor Deus.',
        'E o linho e a cevada foram feridos, porque a cevada já estava na espiga, e o linho na haste.',
        'Mas o trigo e o centeio não foram feridos, porque estavam cobertos.',
        'Saiu, pois, Moisés da presença de Faraó, da cidade, e estendeu as suas mãos ao Senhor; e cessaram os trovões e a saraiva, e a chuva não caiu mais sobre a terra.',
        'Vendo Faraó que cessou a chuva, e a saraiva, e os trovões, pecou ainda mais; e endureceu o seu coração, ele e os seus servos.',
        'Assim o coração de Faraó se endureceu, e não deixou ir os filhos de Israel, como o Senhor tinha dito por Moisés.',
      ],
      [
        'Depois disse o SENHOR a Moisés: Vai a Faraó, porque tenho endurecido o seu coração, e o coração de seus servos, para fazer estes meus sinais no meio deles,',
        'E para que contes aos ouvidos de teus filhos, e dos filhos de teus filhos, as coisas que fiz no Egito, e os meus sinais, que tenho feito entre eles; para que saibais que eu sou o Senhor.',
        'Assim foram Moisés e Arão a Faraó, e disseram-lhe: Assim diz o Senhor Deus dos hebreus: Até quando recusarás humilhar-te diante de mim? Deixa ir o meu povo para que me sirva;',
        'Porque se ainda recusares deixar ir o meu povo, eis que trarei amanhã gafanhotos aos teus termos.',
        'E cobrirão a face da terra, de modo que não se poderá ver a terra; e eles comerão o restante que escapou, o que vos ficou da saraiva; também comerão toda a árvore que vos cresce no campo;',
        'E encherão as tuas casas, e as casas de todos os teus servos e as casas de todos os egípcios, quais nunca viram teus pais, nem os pais de teus pais, desde o dia em que se acharam na terra até o dia de hoje. E virou-se, e saiu da presença de Faraó.',
        'E os servos de Faraó disseram-lhe: Até quando este homem nos há de ser por laço? Deixa ir os homens, para que sirvam ao Senhor seu Deus; ainda não sabes que o Egito está destruído?',
        'Então Moisés e Arão foram levados outra vez a Faraó, e ele disse-lhes: Ide, servi ao Senhor vosso Deus. Quais são os que hão de ir?',
        'E Moisés disse: Havemos de ir com os nossos jovens, e com os nossos velhos; com os nossos filhos, e com as nossas filhas, com as nossas ovelhas, e com os nossos bois havemos de ir; porque temos de celebrar uma festa ao Senhor.',
        'Então ele lhes disse: Seja o Senhor assim convosco, como eu vos deixarei ir a vós e a vossos filhos; olhai que há mal diante da vossa face.',
        'Não será assim; agora ide vós, homens, e servi ao Senhor; pois isso é o que pedistes. E os expulsaram da presença de Faraó.',
        'Então disse o Senhor a Moisés: Estende a tua mão sobre a terra do Egito para que os gafanhotos venham sobre a terra do Egito, e comam toda a erva da terra, tudo o que deixou a saraiva.',
        'Então estendeu Moisés sua vara sobre a terra do Egito, e o Senhor trouxe sobre a terra um vento oriental todo aquele dia e toda aquela noite; e aconteceu que pela manhã o vento oriental trouxe os gafanhotos.',
        'E vieram os gafanhotos sobre toda a terra do Egito, e assentaram-se sobre todos os termos do Egito; tão numerosos foram que, antes destes nunca houve tantos, nem depois deles haverá.',
        'Porque cobriram a face de toda a terra, de modo que a terra se escureceu; e comeram toda a erva da terra, e todo o fruto das árvores, que deixara a saraiva; e não ficou verde algum nas árvores, nem na erva do campo, em toda a terra do Egito.',
        'Então Faraó se apressou a chamar a Moisés e a Arão, e disse: Pequei contra o Senhor vosso Deus, e contra vós.',
        'Agora, pois, peço-vos que perdoeis o meu pecado somente desta vez, e que oreis ao Senhor vosso Deus que tire de mim somente esta morte.',
        'E saiu da presença de Faraó, e orou ao Senhor.',
        'Então o Senhor trouxe um vento ocidental fortíssimo, o qual levantou os gafanhotos e os lançou no Mar Vermelho; não ficou um só gafanhoto em todos os termos do Egito.',
        'O Senhor, porém, endureceu o coração de Faraó, e este não deixou ir os filhos de Israel.',
        'Então disse o Senhor a Moisés: Estende a tua mão para o céu, e virão trevas sobre a terra do Egito, trevas que se apalpem.',
        'E Moisés estendeu a sua mão para o céu, e houve trevas espessas em toda a terra do Egito por três dias.',
        'Não viu um ao outro, e ninguém se levantou do seu lugar por três dias; mas todos os filhos de Israel tinham luz em suas habitações.',
        'Então Faraó chamou a Moisés, e disse: Ide, servi ao Senhor; somente fiquem vossas ovelhas e vossas vacas; vão também convosco as vossas crianças.',
        'Moisés, porém, disse: Tu também darás em nossas mãos sacrifícios e holocaustos, que ofereçamos ao Senhor nosso Deus.',
        'E também o nosso gado há de ir conosco, nem uma unha ficará; porque daquele havemos de tomar, para servir ao Senhor nosso Deus; porque não sabemos com que havemos de servir ao Senhor, até que cheguemos lá.',
        'O Senhor, porém, endureceu o coração de Faraó, e este não os quis deixar ir.',
        'E disse-lhe Faraó: Vai-te de mim, guarda-te que não mais vejas o meu rosto; porque no dia em que vires o meu rosto, morrerás.',
        'E disse Moisés: Bem disseste; eu nunca mais verei o teu rosto.',
      ],
      [
        'E o Senhor disse a Moisés: Ainda uma praga trarei sobre Faraó, e sobre o Egito; depois vos deixará ir daqui; e, quando vos deixar ir totalmente, a toda a pressa vos lançará daqui.',
        'Fala agora aos ouvidos do povo, que cada homem peça ao seu vizinho, e cada mulher à sua vizinha, jóias de prata e jóias de ouro.',
        'E o Senhor deu ao povo graça aos olhos dos egípcios; também o homem Moisés era mui grande na terra do Egito, aos olhos dos servos de Faraó e aos olhos do povo.',
        'Disse mais Moisés: Assim o Senhor tem dito: À meia-noite eu sairei pelo meio do Egito;',
        'E todo o primogênito na terra do Egito morrerá, desde o primogênito de Faraó, que haveria de assentar-se sobre o seu trono, até ao primogênito da serva que está detrás da mó, e todo o primogênito dos animais.',
        'E haverá grande clamor em toda a terra do Egito, como nunca houve semelhante e nunca haverá;',
        'Mas entre todos os filhos de Israel nem mesmo um cão moverá a sua língua, desde os homens até aos animais, para que saibais que o Senhor fez diferença entre os egípcios e os israelitas.',
        'Então todos estes teus servos descerão a mim, e se inclinarão diante de mim, dizendo: Sai tu, e todo o povo que te segue as pisadas; e depois eu sairei. E saiu da presença de Faraó ardendo em ira.',
        'O Senhor dissera a Moisés: Faraó não vos ouvirá, para que as minhas maravilhas se multipliquem na terra do Egito.',
        'E Moisés e Arão fizeram todas estas maravilhas diante de Faraó; mas o Senhor endureceu o coração de Faraó, que não deixou ir os filhos de Israel da sua terra.',
      ],
      [
        'E falou o Senhor a Moisés e a Arão na terra do Egito, dizendo:',
        'Este mesmo mês vos será o princípio dos meses; este vos será o primeiro dos meses do ano.',
        'Falai a toda a congregação de Israel, dizendo: Aos dez deste mês tome cada um para si um cordeiro, segundo as casas dos pais, um cordeiro para cada família.',
        'Mas se a família for pequena para um cordeiro, então tome um só com seu vizinho perto de sua casa, conforme o número das almas; cada um conforme ao seu comer, fareis a conta conforme ao cordeiro.',
        'O cordeiro, ou cabrito, será sem mácula, um macho de um ano, o qual tomareis das ovelhas ou das cabras.',
        'E o guardareis até ao décimo quarto dia deste mês, e todo o ajuntamento da congregação de Israel o sacrificará à tarde.',
        'E tomarão do sangue, e pô-lo-ão em ambas as ombreiras, e na verga da porta, nas casas em que o comerem.',
        'E naquela noite comerão a carne assada no fogo, com pães ázimos; com ervas amargosas a comerão.',
        'Não comereis dele cru, nem cozido em água, senão assado no fogo, a sua cabeça com os seus pés e com a sua fressura.',
        'E nada dele deixareis até amanhã; mas o que dele ficar até amanhã, queimareis no fogo.',
        'Assim pois o comereis: Os vossos lombos cingidos, os vossos sapatos nos pés, e o vosso cajado na mão; e o comereis apressadamente; esta é a páscoa do Senhor.',
        'E eu passarei pela terra do Egito esta noite, e ferirei todo o primogênito na terra do Egito, desde os homens até aos animais; e em todos os deuses do Egito farei juízos. Eu sou o Senhor.',
        'E aquele sangue vos será por sinal nas casas em que estiverdes; vendo eu sangue, passarei por cima de vós, e não haverá entre vós praga de mortandade, quando eu ferir a terra do Egito.',
        'E este dia vos será por memória, e celebrá-lo-eis por festa ao Senhor; nas vossas gerações o celebrareis por estatuto perpétuo.',
        'Sete dias comereis pães ázimos; ao primeiro dia tirareis o fermento das vossas casas; porque qualquer que comer pão levedado, desde o primeiro até ao sétimo dia, aquela alma será cortada de Israel.',
        'E ao primeiro dia haverá santa convocação; também ao sétimo dia tereis santa convocação; nenhuma obra se fará neles, senão o que cada alma houver de comer; isso somente aprontareis para vós.',
        'Guardai pois a festa dos pães ázimos, porque naquele mesmo dia tirei vossos exércitos da terra do Egito; pelo que guardareis a este dia nas vossas gerações por estatuto perpétuo.',
        'No primeiro mês, aos catorze dias do mês, à tarde, comereis pães ázimos até vinte e um do mês à tarde.',
        'Por sete dias não se ache nenhum fermento nas vossas casas; porque qualquer que comer pão levedado, aquela alma será cortada da congregação de Israel, assim o estrangeiro como o natural da terra.',
        'Nenhuma coisa levedada comereis; em todas as vossas habitações comereis pães ázimos.',
        'Chamou pois Moisés a todos os anciãos de Israel, e disse-lhes: Escolhei e tomai vós cordeiros para vossas famílias, e sacrificai a páscoa.',
        'Então tomai um molho de hissopo, e molhai-o no sangue que estiver na bacia, e passai-o na verga da porta, e em ambas as ombreiras, do sangue que estiver na bacia; porém nenhum de vós saia da porta da sua casa até à manhã.',
        'Porque o Senhor passará para ferir aos egípcios, porém quando vir o sangue na verga da porta, e em ambas as ombreiras, o Senhor passará aquela porta, e não deixará o destruidor entrar em vossas casas, para vos ferir.',
        'Portanto guardai isto por estatuto para vós, e para vossos filhos para sempre.',
        'E acontecerá que, quando entrardes na terra que o Senhor vos dará, como tem dito, guardareis este culto.',
        'E acontecerá que, quando vossos filhos vos disserem: Que culto é este?',
        'Então direis: Este é o sacrifício da páscoa ao Senhor, que passou as casas dos filhos de Israel no Egito, quando feriu aos egípcios, e livrou as nossas casas. Então o povo inclinou-se, e adorou.',
        'E foram os filhos de Israel, e fizeram isso como o Senhor ordenara a Moisés e a Arão, assim fizeram.',
        'E aconteceu, à meia-noite, que o Senhor feriu a todos os primogênitos na terra do Egito, desde o primogênito de Faraó, que se sentava em seu trono, até ao primogênito do cativo que estava no cárcere, e todos os primogênitos dos animais.',
        'E Faraó levantou-se de noite, ele e todos os seus servos, e todos os egípcios; e havia grande clamor no Egito, porque não havia casa em que não houvesse um morto.',
        'Então chamou a Moisés e a Arão de noite, e disse: Levantai-vos, saí do meio do meu povo, tanto vós como os filhos de Israel; e ide, servi ao Senhor, como tendes dito.',
        'Levai também convosco vossas ovelhas e vossas vacas, como tendes dito; e ide, e abençoai-me também a mim.',
        'E os egípcios apertavam ao povo, apressando-se para lançá-los da terra; porque diziam: Todos seremos mortos.',
        'E o povo tomou a sua massa, antes que levedasse, e as suas amassadeiras atadas em suas roupas sobre seus ombros.',
        'Fizeram, pois, os filhos de Israel conforme à palavra de Moisés, e pediram aos egípcios jóias de prata, e jóias de ouro, e roupas.',
        'E o Senhor deu ao povo graça aos olhos dos egípcios, e estes lhe davam o que pediam; e despojaram aos egípcios.',
        'Assim partiram os filhos de Israel de Ramessés para Sucote, cerca de seiscentos mil a pé, somente de homens, sem contar os meninos.',
        'E subiu também com eles muita mistura de gente, e ovelhas, e bois, uma grande quantidade de gado.',
        'E cozeram bolos ázimos da massa que levaram do Egito, porque não se tinha levedado, porquanto foram lançados do Egito; e não se puderam deter, nem prepararam comida.',
        'O tempo que os filhos de Israel habitaram no Egito foi de quatrocentos e trinta anos.',
        'E aconteceu que, passados os quatrocentos e trinta anos, naquele mesmo dia, todos os exércitos do Senhor saíram da terra do Egito.',
        'Esta noite se guardará ao Senhor, porque nela os tirou da terra do Egito; esta é a noite do Senhor, que devem guardar todos os filhos de Israel nas suas gerações.',
        'Disse mais o Senhor a Moisés e a Arão: Esta é a ordenança da páscoa: nenhum filho do estrangeiro comerá dela.',
        'Porém todo o servo comprado por dinheiro, depois que o houveres circuncidado, então comerá dela.',
        'O estrangeiro e o assalariado não comerão dela.',
        'Numa casa se comerá; não levarás daquela carne fora da casa, nem dela quebrareis osso.',
        'Toda a congregação de Israel o fará.',
        'Porém se algum estrangeiro se hospedar contigo e quiser celebrar a páscoa ao Senhor, seja-lhe circuncidado todo o homem, e então chegará a celebrá-la, e será como o natural da terra; mas nenhum incircunciso comerá dela.',
        'Uma mesma lei haja para o natural e para o estrangeiro que peregrinar entre vós.',
        'E todos os filhos de Israel o fizeram; como o Senhor ordenara a Moisés e a Arão, assim fizeram.',
        'E aconteceu naquele mesmo dia que o Senhor tirou os filhos de Israel da terra do Egito, segundo os seus exércitos.',
      ],
      [
        'Então falou o SENHOR a Moisés, dizendo:',
        'Santifica-me todo o primogênito, o que abrir toda a madre entre os filhos de Israel, de homens e de animais; porque meu é.',
        'E Moisés disse ao povo: Lembrai-vos deste mesmo dia, em que saístes do Egito, da casa da servidão; pois com mão forte o Senhor vos tirou daqui; portanto não comereis pão levedado.',
        'Hoje, no mês de Abibe, vós saís.',
        'E acontecerá que, quando o Senhor te houver introduzido na terra dos cananeus, e dos heteus, e dos amorreus, e dos heveus, e dos jebuseus, a qual jurou a teus pais que te daria, terra que mana leite e mel, guardarás este culto neste mês.',
        'Sete dias comerás pães ázimos, e ao sétimo dia haverá festa ao Senhor.',
        'Sete dias se comerá pães ázimos, e o levedado não se verá contigo, nem ainda fermento será visto em todos os teus termos.',
        'E naquele mesmo dia farás saber a teu filho, dizendo: Isto é pelo que o Senhor me tem feito, quando eu saí do Egito.',
        'E te será por sinal sobre tua mão e por lembrança entre teus olhos, para que a lei do Senhor esteja em tua boca; porquanto com mão forte o Senhor te tirou do Egito.',
        'Portanto tu guardarás este estatuto a seu tempo, de ano em ano.',
        'Também acontecerá que, quando o Senhor te houver introduzido na terra dos cananeus, como jurou a ti e a teus pais, quando ta houver dado,',
        'Separarás para o Senhor tudo o que abrir a madre e todo o primogênito dos animais que tiveres; os machos serão do Senhor.',
        'Porém, todo o primogênito da jumenta resgatarás com um cordeiro; e se o não resgatares, cortar-lhe-ás a cabeça; mas todo o primogênito do homem, entre teus filhos, resgatarás.',
        'E quando teu filho te perguntar no futuro, dizendo: Que é isto? Dir-lhe-ás: O Senhor nos tirou com mão forte do Egito, da casa da servidão.',
        'Porque sucedeu que, endurecendo-se Faraó, para não nos deixar ir, o Senhor matou todos os primogênitos na terra do Egito, desde o primogênito do homem até o primogênito dos animais; por isso eu sacrifico ao Senhor todos os primogênitos, sendo machos; porém a todo o primogênito de meus filhos eu resgato.',
        'E será isso por sinal sobre tua mão, e por frontais entre os teus olhos; porque o Senhor, com mão forte, nos tirou do Egito.',
        'E aconteceu que, quando Faraó deixou ir o povo, Deus não os levou pelo caminho da terra dos filisteus, que estava mais perto; porque Deus disse: Para que porventura o povo não se arrependa, vendo a guerra, e volte ao Egito.',
        'Mas Deus fez o povo rodear pelo caminho do deserto do Mar Vermelho; e armados, os filhos de Israel subiram da terra do Egito.',
        'E Moisés levou consigo os ossos de José, porquanto havia este solenemente ajuramentado os filhos de Israel, dizendo: Certamente Deus vos visitará; fazei, pois, subir daqui os meus ossos convosco.',
        'Assim partiram de Sucote, e acamparam-se em Etã, à entrada do deserto.',
        'E o Senhor ia adiante deles, de dia numa coluna de nuvem para os guiar pelo caminho, e de noite numa coluna de fogo para os iluminar, para que caminhassem de dia e de noite.',
        'Nunca tirou de diante do povo a coluna de nuvem, de dia, nem a coluna de fogo, de noite.',
      ],
      [
        'Então falou o SENHOR a Moisés, dizendo:',
        'Fala aos filhos de Israel que voltem, e que se acampem diante de Pi-Hairote, entre Migdol e o mar, diante de Baal-Zefom; em frente dele assentareis o campo junto ao mar.',
        'Então Faraó dirá dos filhos de Israel: Estão embaraçados na terra, o deserto os encerrou.',
        'E eu endurecerei o coração de Faraó, para que os persiga, e serei glorificado em Faraó e em todo o seu exército, e saberão os egípcios que eu sou o Senhor. E eles fizeram assim.',
        'Sendo, pois, anunciado ao rei do Egito que o povo fugia, mudou-se o coração de Faraó e dos seus servos contra o povo, e disseram: Por que fizemos isso, havendo deixado ir a Israel, para que não nos sirva?',
        'E aprontou o seu carro, e tomou consigo o seu povo;',
        'E tomou seiscentos carros escolhidos, e todos os carros do Egito, e os capitães sobre eles todos.',
        'Porque o Senhor endureceu o coração de Faraó, rei do Egito, para que perseguisse aos filhos de Israel; porém os filhos de Israel saíram com alta mão.',
        'E os egípcios perseguiram-nos, todos os cavalos e carros de Faraó, e os seus cavaleiros e o seu exército, e alcançaram-nos acampados junto ao mar, perto de Pi-Hairote, diante de Baal-Zefom.',
        'E aproximando Faraó, os filhos de Israel levantaram seus olhos, e eis que os egípcios vinham atrás deles, e temeram muito; então os filhos de Israel clamaram ao Senhor.',
        'E disseram a Moisés: Não havia sepulcros no Egito, para nos tirar de lá, para que morramos neste deserto? Por que nos fizeste isto, fazendo-nos sair do Egito?',
        'Não é esta a palavra que te falamos no Egito, dizendo: Deixa-nos, que sirvamos aos egípcios? Pois que melhor nos fora servir aos egípcios, do que morrermos no deserto.',
        'Moisés, porém, disse ao povo: Não temais; estai quietos, e vede o livramento do Senhor, que hoje vos fará; porque aos egípcios, que hoje vistes, nunca mais os tornareis a ver.',
        'O Senhor pelejará por vós, e vós vos calareis.',
        'Então disse o Senhor a Moisés: Por que clamas a mim? Dize aos filhos de Israel que marchem.',
        'E tu, levanta a tua vara, e estende a tua mão sobre o mar, e fende-o, para que os filhos de Israel passem pelo meio do mar em seco.',
        'E eis que endurecerei o coração dos egípcios, e estes entrarão atrás deles; e eu serei glorificado em Faraó e em todo o seu exército, nos seus carros e nos seus cavaleiros,',
        'E os egípcios saberão que eu sou o Senhor, quando for glorificado em Faraó, nos seus carros e nos seus cavaleiros.',
        'E o anjo de Deus, que ia diante do exército de Israel, se retirou, e ia atrás deles; também a coluna de nuvem se retirou de diante deles, e se pôs atrás deles.',
        'E ia entre o campo dos egípcios e o campo de Israel; e a nuvem era trevas para aqueles, e para estes clareava a noite; de maneira que em toda a noite não se aproximou um do outro.',
        'Então Moisés estendeu a sua mão sobre o mar, e o Senhor fez retirar o mar por um forte vento oriental toda aquela noite; e o mar tornou-se em seco, e as águas foram partidas.',
        'E os filhos de Israel entraram pelo meio do mar em seco; e as águas foram-lhes como muro à sua direita e à sua esquerda.',
        'E os egípcios os seguiram, e entraram atrás deles todos os cavalos de Faraó, os seus carros e os seus cavaleiros, até ao meio do mar.',
        'E aconteceu que, na vigília daquela manhã, o Senhor, na coluna do fogo e da nuvem, viu o campo dos egípcios; e alvoroçou o campo dos egípcios.',
        'E tirou-lhes as rodas dos seus carros, e dificultosamente os governavam. Então disseram os egípcios: Fujamos da face de Israel, porque o Senhor por eles peleja contra os egípcios.',
        'E disse o Senhor a Moisés: Estende a tua mão sobre o mar, para que as águas tornem sobre os egípcios, sobre os seus carros e sobre os seus cavaleiros.',
        'Então Moisés estendeu a sua mão sobre o mar, e o mar retornou a sua força ao amanhecer, e os egípcios, ao fugirem, foram de encontro a ele, e o Senhor derrubou os egípcios no meio do mar,',
        'Porque as águas, tornando, cobriram os carros e os cavaleiros de todo o exército de Faraó, que os haviam seguido no mar; nenhum deles ficou.',
        'Mas os filhos de Israel foram pelo meio do mar seco; e as águas foram-lhes como muro à sua mão direita e à sua esquerda.',
        'Assim o Senhor salvou Israel naquele dia da mão dos egípcios; e Israel viu os egípcios mortos na praia do mar.',
        'E viu Israel a grande mão que o Senhor mostrara aos egípcios; e temeu o povo ao Senhor, e creu no Senhor e em Moisés, seu servo.',
      ],
      [
        'Então cantou Moisés e os filhos de Israel este cântico ao SENHOR, e falaram, dizendo: Cantarei ao SENHOR, porque gloriosamente triunfou; lançou no mar o cavalo e o seu cavaleiro.',
        'O Senhor é a minha força, e o meu cântico; ele me foi por salvação; este é o meu Deus, portanto lhe farei uma habitação; ele é o Deus de meu pai, por isso o exaltarei.',
        'O Senhor é homem de guerra; o Senhor é o seu nome.',
        'Lançou no mar os carros de Faraó e o seu exército; e os seus escolhidos príncipes afogaram-se no Mar Vermelho.',
        'Os abismos os cobriram; desceram às profundezas como pedra.',
        'A tua destra, ó Senhor, se tem glorificado em poder, a tua destra, ó Senhor, tem despedaçado o inimigo;',
        'E com a grandeza da tua excelência derrubaste aos que se levantaram contra ti; enviaste o teu furor, que os consumiu como o restolho.',
        'E com o sopro de tuas narinas amontoaram-se as águas, as correntes pararam como montão; os abismos coalharam-se no coração do mar.',
        'O inimigo dizia: Perseguirei, alcançarei, repartirei os despojos; fartar-se-á a minha alma deles, arrancarei a minha espada, a minha mão os destruirá.',
        'Sopraste com o teu vento, o mar os cobriu; afundaram-se como chumbo em veementes águas.',
        'Ó Senhor, quem é como tu entre os deuses? Quem é como tu glorificado em santidade, admirável em louvores, realizando maravilhas?',
        'Estendeste a tua mão direita; a terra os tragou.',
        'Tu, com a tua beneficência, guiaste a este povo, que salvaste; com a tua força o levaste à habitação da tua santidade.',
        'Os povos o ouviram, eles estremeceram, uma dor apoderou-se dos habitantes da Filístia.',
        'Então os príncipes de Edom se pasmaram; dos poderosos dos moabitas apoderou-se um tremor; derreteram-se todos os habitantes de Canaã.',
        'Espanto e pavor caiu sobre eles; pela grandeza do teu braço emudeceram como pedra; até que o teu povo houvesse passado, ó Senhor, até que passasse este povo que adquiriste.',
        'Tu os introduzirás, e os plantarás no monte da tua herança, no lugar que tu, ó Senhor, aparelhaste para a tua habitação, no santuário, ó Senhor, que as tuas mãos estabeleceram.',
        'O Senhor reinará eterna e perpetuamente;',
        'Porque os cavalos de Faraó, com os seus carros e com os seus cavaleiros, entraram no mar, e o Senhor fez tornar as águas do mar sobre eles; mas os filhos de Israel passaram em seco pelo meio do mar.',
        'Então Miriã, a profetisa, a irmã de Arão, tomou o tamboril na sua mão, e todas as mulheres saíram atrás dela com tamboris e com danças.',
        'E Miriã lhes respondia: Cantai ao Senhor, porque gloriosamente triunfou; e lançou no mar o cavalo com o seu cavaleiro.',
        'Depois fez Moisés partir os israelitas do Mar Vermelho, e saíram ao deserto de Sur; e andaram três dias no deserto, e não acharam água.',
        'Então chegaram a Mara; mas não puderam beber das águas de Mara, porque eram amargas; por isso chamou-se o lugar Mara.',
        'E o povo murmurou contra Moisés, dizendo: Que havemos de beber?',
        'E ele clamou ao Senhor, e o Senhor mostrou-lhe uma árvore, que lançou nas águas, e as águas se tornaram doces. Ali lhes deu estatutos e uma ordenança, e ali os provou.',
        'E disse: Se ouvires atento a voz do Senhor teu Deus, e fizeres o que é reto diante de seus olhos, e inclinares os teus ouvidos aos seus mandamentos, e guardares todos os seus estatutos, nenhuma das enfermidades porei sobre ti, que pus sobre o Egito; porque eu sou o Senhor que te sara.',
        'Então vieram a Elim, e havia ali doze fontes de água e setenta palmeiras; e ali se acamparam junto das águas.',
      ],
      [
        'E partindo de Elim, toda a congregação dos filhos de Israel veio ao deserto de Sim, que está entre Elim e Sinai, aos quinze dias do mês segundo, depois de sua saída da terra do Egito.',
        'E toda a congregação dos filhos de Israel murmurou contra Moisés e contra Arão no deserto.',
        'E os filhos de Israel disseram-lhes: Quem dera tivéssemos morrido por mão do Senhor na terra do Egito, quando estávamos sentados junto às panelas de carne, quando comíamos pão até fartar! Porque nos tendes trazido a este deserto, para matardes de fome a toda esta multidão.',
        'Então disse o Senhor a Moisés: Eis que vos farei chover pão dos céus, e o povo sairá, e colherá diariamente a porção para cada dia, para que eu o prove se anda em minha lei ou não.',
        'E acontecerá, no sexto dia, que prepararão o que colherem; e será o dobro do que colhem cada dia.',
        'Então disseram Moisés e Arão a todos os filhos de Israel: À tarde sabereis que o Senhor vos tirou da terra do Egito,',
        'E amanhã vereis a glória do Senhor, porquanto ouviu as vossas murmurações contra o Senhor. E quem somos nós, para que murmureis contra nós?',
        'Disse mais Moisés: Isso será quando o Senhor à tarde vos der carne para comer, e pela manhã pão a fartar, porquanto o Senhor ouviu as vossas murmurações, com que murmurais contra ele. E quem somos nós? As vossas murmurações não são contra nós, mas sim contra o Senhor.',
        'Depois disse Moisés a Arão: Dize a toda a congregação dos filhos de Israel: Chegai-vos à presença do Senhor, porque ouviu as vossas murmurações.',
        'E aconteceu que, quando falou Arão a toda a congregação dos filhos de Israel, e eles se viraram para o deserto, eis que a glória do Senhor apareceu na nuvem.',
        'E o Senhor falou a Moisés, dizendo:',
        'Tenho ouvido as murmurações dos filhos de Israel. Fala-lhes, dizendo: Entre as duas tardes comereis carne, e pela manhã vos fartareis de pão; e sabereis que eu sou o Senhor vosso Deus.',
        'E aconteceu que à tarde subiram codornizes, e cobriram o arraial; e pela manhã jazia o orvalho ao redor do arraial.',
        'E quando o orvalho se levantou, eis que sobre a face do deserto estava uma coisa miúda, redonda, miúda como a geada sobre a terra.',
        'E, vendo-a os filhos de Israel, disseram uns aos outros: Que é isto? Porque não sabiam o que era. Disse-lhes pois Moisés: Este é o pão que o Senhor vos deu para comer.',
        'Esta é a palavra que o Senhor tem mandado: Colhei dele cada um conforme ao que pode comer, um ômer por cabeça, segundo o número das vossas almas; cada um tomará para os que se acharem na sua tenda.',
        'E os filhos de Israel fizeram assim; e colheram, uns mais e outros menos.',
        'Porém, medindo-o com o ômer, não sobejava ao que colhera muito, nem faltava ao que colhera pouco; cada um colheu tanto quanto podia comer.',
        'E disse-lhes Moisés: Ninguém deixe dele para amanhã.',
        'Eles, porém, não deram ouvidos a Moisés, antes alguns deles deixaram dele para o dia seguinte; e criou bichos, e cheirava mal; por isso indignou-se Moisés contra eles.',
        'Eles, pois, o colhiam cada manhã, cada um conforme ao que podia comer; porque, aquecendo o sol, derretia-se.',
        'E aconteceu que ao sexto dia colheram pão em dobro, dois ômeres para cada um; e todos os príncipes da congregação vieram, e contaram-no a Moisés.',
        'E ele disse-lhes: Isto é o que o Senhor tem dito: Amanhã é repouso, o santo sábado do Senhor; o que quiserdes cozer no forno, cozei-o, e o que quiserdes cozer em água, cozei-o em água; e tudo o que sobejar, guardai para vós até amanhã.',
        'E guardaram-no até o dia seguinte, como Moisés tinha ordenado; e não cheirou mal nem nele houve algum bicho.',
        'Então disse Moisés: Comei-o hoje, porquanto hoje é o sábado do Senhor; hoje não o achareis no campo.',
        'Seis dias o colhereis, mas o sétimo dia é o sábado; nele não haverá.',
        'E aconteceu ao sétimo dia, que alguns do povo saíram para colher, mas não o acharam.',
        'Então disse o Senhor a Moisés: Até quando recusareis guardar os meus mandamentos e as minhas leis?',
        'Vede, porquanto o Senhor vos deu o sábado, portanto ele no sexto dia vos dá pão para dois dias; cada um fique no seu lugar, ninguém saia do seu lugar no sétimo dia.',
        'Assim repousou o povo no sétimo dia.',
        'E chamou a casa de Israel o seu nome maná; e era como semente de coentro branco, e o seu sabor como bolos de mel.',
        'E disse Moisés: Esta é a palavra que o Senhor tem mandado: Encherás um ômer dele e guardá-lo-ás para as vossas gerações, para que vejam o pão que vos tenho dado a comer neste deserto, quando eu vos tirei da terra do Egito.',
        'Disse também Moisés a Arão: Toma um vaso, e põe nele um ômer cheio de maná, e coloca-o diante do Senhor, para guardá-lo para as vossas gerações.',
        'Como o Senhor tinha ordenado a Moisés, assim Arão o pôs diante do Testemunho, para ser guardado.',
        'E comeram os filhos de Israel maná quarenta anos, até que entraram em terra habitada; comeram maná até que chegaram aos termos da terra de Canaã.',
        'E um ômer é a décima parte do efa.',
      ],
      [
        'Depois toda a congregação dos filhos de Israel partiu do deserto de Sim pelas suas jornadas, segundo o mandamento do Senhor, e acampou em Refidim; não havia ali água para o povo beber.',
        'Então contendeu o povo com Moisés, e disse: Dá-nos água para beber. E Moisés lhes disse: Por que contendeis comigo? Por que tentais ao Senhor?',
        'Tendo pois ali o povo sede de água, o povo murmurou contra Moisés, e disse: Por que nos fizeste subir do Egito, para nos matares de sede, a nós e aos nossos filhos, e ao nosso gado?',
        'E clamou Moisés ao Senhor, dizendo: Que farei a este povo? Daqui a pouco me apedrejará.',
        'Então disse o Senhor a Moisés: Passa diante do povo, e toma contigo alguns dos anciãos de Israel; e toma na tua mão a tua vara, com que feriste o rio, e vai.',
        'Eis que eu estarei ali diante de ti sobre a rocha, em Horebe, e tu ferirás a rocha, e dela sairão águas e o povo beberá. E Moisés assim o fez, diante dos olhos dos anciãos de Israel.',
        'E chamou aquele lugar Massá e Meribá, por causa da contenda dos filhos de Israel, e porque tentaram ao Senhor, dizendo: Está o Senhor no meio de nós, ou não?',
        'Então veio Amaleque, e pelejou contra Israel em Refidim.',
        'Por isso disse Moisés a Josué: Escolhe-nos homens, e sai, peleja contra Amaleque; amanhã eu estarei sobre o cume do outeiro, e a vara de Deus estará na minha mão.',
        'E fez Josué como Moisés lhe dissera, pelejando contra Amaleque; mas Moisés, Arão, e Hur subiram ao cume do outeiro.',
        'E acontecia que, quando Moisés levantava a sua mão, Israel prevalecia; mas quando ele abaixava a sua mão, Amaleque prevalecia.',
        'Porém as mãos de Moisés eram pesadas, por isso tomaram uma pedra, e a puseram debaixo dele, para assentar-se sobre ela; e Arão e Hur sustentaram as suas mãos, um de um lado e o outro do outro; assim ficaram as suas mãos firmes até que o sol se pôs.',
        'E assim Josué desfez a Amaleque e a seu povo, ao fio da espada.',
        'Então disse o Senhor a Moisés: Escreve isto para memória num livro, e relata-o aos ouvidos de Josué; que eu totalmente hei de riscar a memória de Amaleque de debaixo dos céus.',
        'E Moisés edificou um altar, ao qual chamou: O SENHOR É MINHA BANDEIRA.',
        'E disse: Porquanto jurou o Senhor, haverá guerra do Senhor contra Amaleque de geração em geração.',
      ],
      [
        'Ora Jetro, sacerdote de Midiã, sogro de Moisés, ouviu todas as coisas que Deus tinha feito a Moisés e a Israel seu povo, como o SENHOR tinha tirado a Israel do Egito.',
        'E Jetro, sogro de Moisés, tomou a Zípora, a mulher de Moisés, depois que ele lha enviara,',
        'Com seus dois filhos, dos quais um se chamava Gérson; porque disse: Eu fui peregrino em terra estranha;',
        'E o outro se chamava Eliézer; porque disse: O Deus de meu pai foi por minha ajuda, e me livrou da espada de Faraó.',
        'Vindo, pois, Jetro, o sogro de Moisés, com seus filhos e com sua mulher, a Moisés no deserto, ao monte de Deus, onde se tinha acampado,',
        'Disse a Moisés: Eu, teu sogro Jetro, venho a ti, com tua mulher e seus dois filhos com ela.',
        'Então saiu Moisés ao encontro de seu sogro, e inclinou-se, e beijou-o, e perguntaram um ao outro como estavam, e entraram na tenda.',
        'E Moisés contou a seu sogro todas as coisas que o Senhor tinha feito a Faraó e aos egípcios por amor de Israel, e todo o trabalho que passaram no caminho, e como o Senhor os livrara.',
        'E alegrou-se Jetro de todo o bem que o Senhor tinha feito a Israel, livrando-o da mão dos egípcios.',
        'E Jetro disse: Bendito seja o Senhor, que vos livrou das mãos dos egípcios e da mão de Faraó; que livrou a este povo de debaixo da mão dos egípcios.',
        'Agora sei que o Senhor é maior que todos os deuses; porque na coisa em que se ensoberbeceram, os sobrepujou.',
        'Então Jetro, o sogro de Moisés, tomou holocausto e sacrifícios para Deus; e veio Arão, e todos os anciãos de Israel, para comerem pão com o sogro de Moisés diante de deus.',
        'E aconteceu que, no outro dia, Moisés assentou-se para julgar o povo; e o povo estava em pé diante de Moisés desde a manhã até à tarde.',
        'Vendo, pois, o sogro de Moisés tudo o que ele fazia ao povo, disse: Que é isto, que tu fazes ao povo? Por que te assentas só, e todo o povo está em pé diante de ti, desde a manhã até à tarde?',
        'Então disse Moisés a seu sogro: É porque este povo vem a mim, para consultar a Deus;',
        'Quando tem algum negócio vem a mim, para que eu julgue entre um e outro e lhes declare os estatutos de Deus e as suas leis.',
        'O sogro de Moisés, porém, lhe disse: Não é bom o que fazes.',
        'Totalmente desfalecerás, assim tu como este povo que está contigo; porque este negócio é mui difícil para ti; tu só não o podes fazer.',
        'Ouve agora minha voz, eu te aconselharei, e Deus será contigo. Sê tu pelo povo diante de Deus, e leva tu as causas a Deus;',
        'E declara-lhes os estatutos e as leis, e faze-lhes saber o caminho em que devem andar, e a obra que devem fazer.',
        'E tu dentre todo o povo procura homens capazes, tementes a Deus, homens de verdade, que odeiem a avareza; e põe-nos sobre eles por maiorais de mil, maiorais de cem, maiorais de cinqüenta, e maiorais de dez;',
        'Para que julguem este povo em todo o tempo; e seja que todo o negócio grave tragam a ti, mas todo o negócio pequeno eles o julguem; assim a ti mesmo te aliviarás da carga, e eles a levarão contigo.',
        'Se isto fizeres, e Deus to mandar, poderás então subsistir; assim também todo este povo em paz irá ao seu lugar.',
        'E Moisés deu ouvidos à voz de seu sogro, e fez tudo quanto tinha dito;',
        'E escolheu Moisés homens capazes, de todo o Israel, e os pôs por cabeças sobre o povo; maiorais de mil, maiorais de cem, maiorais de cinqüenta e maiorais de dez.',
        'E eles julgaram o povo em todo o tempo; o negócio árduo trouxeram a Moisés, e todo o negócio pequeno julgaram eles.',
        'Então despediu Moisés o seu sogro, o qual se foi à sua terra.',
      ],
      [
        'Ao terceiro mês da saída dos filhos de Israel da terra do Egito, no mesmo dia chegaram ao deserto de Sinai,',
        'Porque partiram de Refidim e entraram no deserto de Sinai, onde se acamparam. Israel, pois, ali se acampou em frente ao monte.',
        'E subiu Moisés a Deus, e o Senhor o chamou do monte, dizendo: Assim falarás à casa de Jacó, e anunciarás aos filhos de Israel:',
        'Vós tendes visto o que fiz aos egípcios, como vos levei sobre asas de águias, e vos trouxe a mim;',
        'Agora, pois, se diligentemente ouvirdes a minha voz e guardardes a minha aliança, então sereis a minha propriedade peculiar dentre todos os povos, porque toda a terra é minha.',
        'E vós me sereis um reino sacerdotal e o povo santo. Estas são as palavras que falarás aos filhos de Israel.',
        'E veio Moisés, e chamou os anciãos do povo, e expôs diante deles todas estas palavras, que o Senhor lhe tinha ordenado.',
        'Então todo o povo respondeu a uma voz, e disse: Tudo o que o Senhor tem falado, faremos. E relatou Moisés ao Senhor as palavras do povo.',
        'E disse o Senhor a Moisés: Eis que eu virei a ti numa nuvem espessa, para que o povo ouça, falando eu contigo, e para que também te creiam eternamente. Porque Moisés tinha anunciado as palavras do seu povo ao Senhor.',
        'Disse também o Senhor a Moisés: Vai ao povo, e santifica-os hoje e amanhã, e lavem eles as suas roupas,',
        'E estejam prontos para o terceiro dia; porquanto no terceiro dia o Senhor descerá diante dos olhos de todo o povo sobre o monte Sinai.',
        'E marcarás limites ao povo em redor, dizendo: Guardai-vos, não subais ao monte, nem toqueis o seu termo; todo aquele que tocar o monte, certamente morrerá.',
        'Nenhuma mão tocará nele; porque certamente será apedrejado ou asseteado; quer seja animal, quer seja homem, não viverá; soando a buzina longamente, então subirão ao monte.',
        'Então Moisés desceu do monte ao povo, e santificou o povo; e lavaram as suas roupas.',
        'E disse ao povo: Estai prontos ao terceiro dia; e não vos chegueis a mulher.',
        'E aconteceu que, ao terceiro dia, ao amanhecer, houve trovões e relâmpagos sobre o monte, e uma espessa nuvem, e um sonido de buzina mui forte, de maneira que estremeceu todo o povo que estava no arraial.',
        'E Moisés levou o povo fora do arraial ao encontro de Deus; e puseram-se ao pé do monte.',
        'E todo o monte Sinai fumegava, porque o Senhor descera sobre ele em fogo; e a sua fumaça subiu como fumaça de uma fornalha, e todo o monte tremia grandemente.',
        'E o sonido da buzina ia crescendo cada vez mais; Moisés falava, e Deus lhe respondia em voz alta.',
        'E, descendo o Senhor sobre o monte Sinai, sobre o cume do monte, chamou o Senhor a Moisés ao cume do monte; e Moisés subiu.',
        'E disse o Senhor a Moisés: Desce, adverte ao povo que não traspasse o termo para ver o Senhor, para que muitos deles não pereçam.',
        'E também os sacerdotes, que se chegam ao Senhor, se hão de santificar, para que o Senhor não se lance sobre eles.',
        'Então disse Moisés ao Senhor: O povo não poderá subir ao monte Sinai, porque tu nos tens advertido, dizendo: Marca termos ao redor do monte, e santifica-o.',
        'E disse-lhe o Senhor: Vai, desce; depois subirás tu, e Arão contigo; os sacerdotes, porém, e o povo não traspassem o termo para subir ao Senhor, para que não se lance sobre eles.',
        'Então Moisés desceu ao povo, e disse-lhe isto.',
      ],
      [
        'Então falou Deus todas estas palavras, dizendo:',
        'Eu sou o Senhor teu Deus, que te tirei da terra do Egito, da casa da servidão.',
        'Não terás outros deuses diante de mim.',
        'Não farás para ti imagem de escultura, nem alguma semelhança do que há em cima nos céus, nem em baixo na terra, nem nas águas debaixo da terra.',
        'Não te encurvarás a elas nem as servirás; porque eu, o Senhor teu Deus, sou Deus zeloso, que visito a iniqüidade dos pais nos filhos, até a terceira e quarta geração daqueles que me odeiam.',
        'E faço misericórdia a milhares dos que me amam e aos que guardam os meus mandamentos.',
        'Não tomarás o nome do Senhor teu Deus em vão; porque o Senhor não terá por inocente o que tomar o seu nome em vão.',
        'Lembra-te do dia do sábado, para o santificar.',
        'Seis dias trabalharás, e farás toda a tua obra.',
        'Mas o sétimo dia é o sábado do Senhor teu Deus; não farás nenhuma obra, nem tu, nem teu filho, nem tua filha, nem o teu servo, nem a tua serva, nem o teu animal, nem o teu estrangeiro, que está dentro das tuas portas.',
        'Porque em seis dias fez o Senhor os céus e a terra, o mar e tudo que neles há, e ao sétimo dia descansou; portanto abençoou o Senhor o dia do sábado, e o santificou.',
        'Honra a teu pai e a tua mãe, para que se prolonguem os teus dias na terra que o Senhor teu Deus te dá.',
        'Não matarás.',
        'Não adulterarás.',
        'Não furtarás.',
        'Não dirás falso testemunho contra o teu próximo.',
        'Não cobiçarás a casa do teu próximo, não cobiçarás a mulher do teu próximo, nem o seu servo, nem a sua serva, nem o seu boi, nem o seu jumento, nem coisa alguma do teu próximo.',
        'E todo o povo viu os trovões e os relâmpagos, e o sonido da buzina, e o monte fumegando; e o povo, vendo isso retirou-se e pôs-se de longe.',
        'E disseram a Moisés: Fala tu conosco, e ouviremos: e não fale Deus conosco, para que não morramos.',
        'E disse Moisés ao povo: Não temais, Deus veio para vos provar, e para que o seu temor esteja diante de vós, a fim de que não pequeis.',
        'E o povo estava em pé de longe. Moisés, porém, se chegou à escuridão, onde Deus estava.',
        'Então disse o Senhor a Moisés: Assim dirás aos filhos de Israel: Vós tendes visto que, dos céus, eu falei convosco.',
        'Não fareis outros deuses comigo; deuses de prata ou deuses de ouro não fareis para vós.',
        'Um altar de terra me farás, e sobre ele sacrificarás os teus holocaustos, e as tuas ofertas pacíficas, as tuas ovelhas, e as tuas vacas; em todo o lugar, onde eu fizer celebrar a memória do meu nome, virei a ti e te abençoarei.',
        'E se me fizeres um altar de pedras, não o farás de pedras lavradas; se sobre ele levantares o teu buril, profaná-lo-ás.',
        'Também não subirás ao meu altar por degraus, para que a tua nudez não seja descoberta diante deles.',
      ],
      [
        'Estes são os estatutos que lhes proporás.',
        'Se comprares um servo hebreu, seis anos servirá; mas ao sétimo sairá livre, de graça.',
        'Se entrou só com o seu corpo, só com o seu corpo sairá; se ele era homem casado, sua mulher sairá com ele.',
        'Se seu senhor lhe houver dado uma mulher e ela lhe houver dado filhos ou filhas, a mulher e seus filhos serão de seu senhor, e ele sairá sozinho.',
        'Mas se aquele servo expressamente disser: Eu amo a meu senhor, e a minha mulher, e a meus filhos; não quero sair livre,',
        'Então seu senhor o levará aos juízes, e o fará chegar à porta, ou ao umbral da porta, e seu senhor lhe furará a orelha com uma sovela; e ele o servirá para sempre.',
        'E se um homem vender sua filha para ser serva, ela não sairá como saem os servos.',
        'Se ela não agradar ao seu senhor, e ele não se desposar com ela, fará que se resgate; não poderá vendê-la a um povo estranho, agindo deslealmente com ela.',
        'Mas se a desposar com seu filho, fará com ela conforme ao direito das filhas.',
        'Se lhe tomar outra, não diminuirá o mantimento desta, nem o seu vestido, nem a sua obrigação marital.',
        'E se lhe não fizer estas três coisas, sairá de graça, sem dar dinheiro.',
        'Quem ferir alguém, de modo que este morra, certamente será morto.',
        'Porém se lhe não armou cilada, mas Deus lho entregou nas mãos, ordenar-te-ei um lugar para onde fugirá.',
        'Mas se alguém agir premeditadamente contra o seu próximo, matando-o à traição, tirá-lo-ás do meu altar, para que morra.',
        'O que ferir a seu pai, ou a sua mãe, certamente será morto.',
        'E quem raptar um homem, e o vender, ou for achado na sua mão, certamente será morto.',
        'E quem amaldiçoar a seu pai ou a sua mãe, certamente será morto.',
        'E se dois homens pelejarem, ferindo-se um ao outro com pedra ou com o punho, e este não morrer, mas cair na cama,',
        'Se ele tornar a levantar-se e andar fora, sobre o seu bordão, então aquele que o feriu será absolvido; somente lhe pagará o tempo que perdera e o fará curar totalmente.',
        'Se alguém ferir a seu servo, ou a sua serva, com pau, e morrer debaixo da sua mão, certamente será castigado;',
        'Porém se sobreviver por um ou dois dias, não será castigado, porque é dinheiro seu.',
        'Se alguns homens pelejarem, e um ferir uma mulher grávida, e for causa de que aborte, porém não havendo outro dano, certamente será multado, conforme o que lhe impuser o marido da mulher, e julgarem os juízes.',
        'Mas se houver morte, então darás vida por vida,',
        'Olho por olho, dente por dente, mão por mão, pé por pé,',
        'Queimadura por queimadura, ferida por ferida, golpe por golpe.',
        'E quando alguém ferir o olho do seu servo, ou o olho da sua serva, e o danificar, o deixará ir livre pelo seu olho.',
        'E se tirar o dente do seu servo, ou o dente da sua serva, o deixará ir livre pelo seu dente.',
        'E se algum boi escornear homem ou mulher, que morra, o boi será apedrejado certamente, e a sua carne não se comerá; mas o dono do boi será absolvido.',
        'Mas se o boi dantes era escorneador, e o seu dono foi conhecedor disso, e não o guardou, matando homem ou mulher, o boi será apedrejado, e também o seu dono morrerá.',
        'Se lhe for imposto resgate, então dará por resgate da sua vida tudo quanto lhe for imposto,',
        'Quer tenha escorneado um filho, quer tenha escorneado uma filha; conforme a este estatuto lhe será feito.',
        'Se o boi escornear um servo, ou uma serva, dar-se-á trinta siclos de prata ao seu senhor, e o boi será apedrejado.',
        'Se alguém abrir uma cova, ou se alguém cavar uma cova, e não a cobrir, e nela cair um boi ou um jumento,',
        'O dono da cova o pagará; pagará em dinheiro ao seu dono, mas o animal morto será seu.',
        'Se o boi de alguém ferir o boi do seu próximo, e morrer, então se venderá o boi vivo, e o dinheiro dele se repartirá igualmente, e também repartirão entre si o boi morto.',
        'Mas se foi notório que aquele boi antes era escorneador, e seu dono não o guardou, certamente pagará boi por boi; porém o morto será seu.',
      ],
      [
        'Se alguém furtar boi ou ovelha, e o degolar ou vender, por um boi pagará cinco bois, e pela ovelha quatro ovelhas.',
        'Se o ladrão for achado roubando, e for ferido, e morrer, o que o feriu não será culpado do sangue.',
        'Se o sol houver saído sobre ele, o agressor será culpado do sangue; o ladrão fará restituição total; e se não tiver com que pagar, será vendido por seu furto.',
        'Se o furto for achado vivo na sua mão, seja boi, ou jumento, ou ovelha, pagará o dobro.',
        'Se alguém fizer pastar o seu animal num campo ou numa vinha, e largá-lo para comer no campo de outro, o melhor do seu próprio campo e o melhor da sua própria vinha restituirá.',
        'Se irromper um fogo, e pegar nos espinhos, e queimar a meda de trigo, ou a seara, ou o campo, aquele que acendeu o fogo totalmente pagará o queimado.',
        'Se alguém der ao seu próximo dinheiro, ou bens, a guardar, e isso for furtado da casa daquele homem, o ladrão, se for achado, pagará o dobro.',
        'Se o ladrão não for achado, então o dono da casa será levado diante dos juízes, a ver se não pôs a sua mão nos bens do seu próximo.',
        'Sobre todo o negócio fraudulento, sobre boi, sobre jumento, sobre gado miúdo, sobre roupa, sobre toda a coisa perdida, de que alguém disser que é sua, a causa de ambos será levada perante os juízes; aquele a quem condenarem os juízes pagará em dobro ao seu próximo.',
        'Se alguém der a seu próximo a guardar um jumento, ou boi, ou ovelha, ou outro animal, e este morrer, ou for dilacerado, ou arrebatado, ninguém o vendo,',
        'Então haverá juramento do Senhor entre ambos, de que não pôs a sua mão nos bens do seu próximo; e seu dono o aceitará, e o outro não o restituirá.',
        'Mas, se de fato lhe tiver sido furtado, pagá-lo-á ao seu dono.',
        'Porém se lhe for dilacerado, trá-lo-á em testemunho disso, e não pagará o dilacerado.',
        'E se alguém pedir emprestado a seu próximo algum animal, e for danificado ou morto, não estando presente o seu dono, certamente o pagará.',
        'Se o seu dono estava presente, não o pagará; se foi alugado, será pelo seu aluguel.',
        'Se alguém enganar alguma virgem, que não for desposada, e se deitar com ela, certamente a dotará e tomará por sua mulher.',
        'Se seu pai inteiramente recusar dar-lha, pagará ele em dinheiro conforme ao dote das virgens.',
        'A feiticeira não deixarás viver.',
        'Todo aquele que se deitar com animal, certamente morrerá.',
        'O que sacrificar aos deuses, e não só ao Senhor, será morto.',
        'O estrangeiro não afligirás, nem o oprimirás; pois estrangeiros fostes na terra do Egito.',
        'A nenhuma viúva nem órfão afligireis.',
        'Se de algum modo os afligires, e eles clamarem a mim, eu certamente ouvirei o seu clamor.',
        'E a minha ira se acenderá, e vos matarei à espada; e vossas mulheres ficarão viúvas, e vossos filhos órfãos.',
        'Se emprestares dinheiro ao meu povo, ao pobre que está contigo, não te haverás com ele como um usurário; não lhe imporeis usura.',
        'Se tomares em penhor a roupa do teu próximo, lho restituirás antes do pôr do sol,',
        'Porque aquela é a sua cobertura, e o vestido da sua pele; em que se deitaria? Será pois que, quando clamar a mim, eu o ouvirei, porque sou misericordioso.',
        'A Deus não amaldiçoarás, e o príncipe dentre o teu povo não maldirás.',
        'As tuas primícias, e os teus licores não retardarás; o primogênito de teus filhos me darás.',
        'Assim farás dos teus bois e das tuas ovelhas: sete dias estarão com sua mãe, e ao oitavo dia mos darás.',
        'E ser-me-eis homens santos; portanto não comereis carne despedaçada no campo; aos cães a lançareis.',
      ],
      [
        'Não admitirás falso boato, e não porás a tua mão com o ímpio, para seres testemunha falsa.',
        'Não seguirás a multidão para fazeres o mal; nem numa demanda falarás, tomando parte com a maioria para torcer o direito.',
        'Nem ao pobre favorecerás na sua demanda.',
        'Se encontrares o boi do teu inimigo, ou o seu jumento, desgarrado, sem falta lho reconduzirás.',
        'Se vires o jumento, daquele que te odeia, caído debaixo da sua carga, deixarás pois de ajudá-lo? Certamente o ajudarás a levantá-lo.',
        'Não perverterás o direito do teu pobre na sua demanda.',
        'De palavras de falsidade te afastarás, e não matarás o inocente e o justo; porque não justificarei o ímpio.',
        'Também suborno não tomarás; porque o suborno cega os que têm vista, e perverte as palavras dos justos.',
        'Também não oprimirás o estrangeiro; pois vós conheceis o coração do estrangeiro, pois fostes estrangeiros na terra do Egito.',
        'Também seis anos semearás tua terra, e recolherás os seus frutos;',
        'Mas ao sétimo a dispensarás e deixarás descansar, para que possam comer os pobres do teu povo, e da sobra comam os animais do campo. Assim farás com a tua vinha e com o teu olival.',
        'Seis dias farás os teus trabalhos, mas ao sétimo dia descansarás; para que descanse o teu boi, e o teu jumento; e para que tome alento o filho da tua escrava, e o estrangeiro.',
        'E em tudo o que vos tenho dito, guardai-vos; e do nome de outros deuses nem vos lembreis, nem se ouça da vossa boca.',
        'Três vezes no ano me celebrareis festa.',
        'A festa dos pães ázimos guardarás; sete dias comerás pães ázimos, como te tenho ordenado, ao tempo apontado no mês de Abibe; porque nele saíste do Egito; e ninguém apareça vazio perante mim;',
        'E a festa da sega dos primeiros frutos do teu trabalho, que houveres semeado no campo, e a festa da colheita, à saída do ano, quando tiveres colhido do campo o teu trabalho.',
        'Três vezes no ano todos os teus homens aparecerão diante do Senhor DEUS.',
        'Não oferecerás o sangue do meu sacrifício com pão levedado; nem ficará a gordura da minha festa de noite até pela manhã.',
        'As primícias dos primeiros frutos da tua terra trarás à casa do Senhor teu Deus; não cozerás o cabrito no leite de sua mãe.',
        'Eis que eu envio um anjo diante de ti, para que te guarde pelo caminho, e te leve ao lugar que te tenho preparado.',
        'Guarda-te diante dele, e ouve a sua voz, e não o provoques à ira; porque não perdoará a vossa rebeldia; porque o meu nome está nele.',
        'Mas se diligentemente ouvires a sua voz, e fizeres tudo o que eu disser, então serei inimigo dos teus inimigos, e adversário dos teus adversários.',
        'Porque o meu anjo irá adiante de ti, e te levará aos amorreus, e aos heteus, e aos perizeus, e aos cananeus, heveus e jebuseus; e eu os destruirei.',
        'Não te inclinarás diante dos seus deuses, nem os servirás, nem farás conforme às suas obras; antes os destruirás totalmente, e quebrarás de todo as suas estátuas.',
        'E servireis ao Senhor vosso Deus, e ele abençoará o vosso pão e a vossa água; e eu tirarei do meio de vós as enfermidades.',
        'Não haverá mulher que aborte, nem estéril na tua terra; o número dos teus dias cumprirei.',
        'Enviarei o meu terror adiante de ti, destruindo a todo o povo aonde entrares, e farei que todos os teus inimigos te voltem as costas.',
        'Também enviarei vespões adiante de ti, que lancem fora os heveus, os cananeus, e os heteus de diante de ti.',
        'Não os lançarei fora de diante de ti num só ano, para que a terra não se torne em deserto, e as feras do campo não se multipliquem contra ti.',
        'Pouco a pouco os lançarei de diante de ti, até que sejas multiplicado, e possuas a terra por herança.',
        'E porei os teus termos desde o Mar Vermelho até ao mar dos filisteus, e desde o deserto até ao rio; porque darei nas tuas mãos os moradores da terra, para que os lances fora de diante de ti.',
        'Não farás aliança alguma com eles, ou com os seus deuses.',
        'Na tua terra não habitarão, para que não te façam pecar contra mim; se servires aos seus deuses, certamente isso será um laço para ti.',
      ],
      [
        'Depois disse a Moisés: Sobe ao SENHOR, tu e Arão, Nadabe e Abiú, e setenta dos anciãos de Israel; e adorai de longe.',
        'E só Moisés se chegará ao Senhor; mas eles não se cheguem, nem o povo suba com ele.',
        'Veio, pois, Moisés, e contou ao povo todas as palavras do Senhor, e todos os estatutos; então o povo respondeu a uma voz, e disse: Todas as palavras, que o Senhor tem falado, faremos.',
        'Moisés escreveu todas as palavras do Senhor, e levantou-se pela manhã de madrugada, e edificou um altar ao pé do monte, e doze monumentos, segundo as doze tribos de Israel;',
        'E enviou alguns jovens dos filhos de Israel, os quais ofereceram holocaustos e sacrificaram ao Senhor sacrifícios pacíficos de bezerros.',
        'E Moisés tomou a metade do sangue, e a pôs em bacias; e a outra metade do sangue espargiu sobre o altar.',
        'E tomou o livro da aliança e o leu aos ouvidos do povo, e eles disseram: Tudo o que o Senhor tem falado faremos, e obedeceremos.',
        'Então tomou Moisés aquele sangue, e espargiu-o sobre o povo, e disse: Eis aqui o sangue da aliança que o Senhor tem feito convosco sobre todas estas palavras.',
        'E subiram Moisés e Arão, Nadabe e Abiú, e setenta dos anciãos de Israel.',
        'E viram o Deus de Israel, e debaixo de seus pés havia como que uma pavimentação de pedra de safira, que se parecia com o céu na sua claridade.',
        'Porém não estendeu a sua mão sobre os escolhidos dos filhos de Israel, mas viram a Deus, e comeram e beberam.',
        'Então disse o Senhor a Moisés: Sobe a mim ao monte, e fica lá; e dar-te-ei as tábuas de pedra e a lei, e os mandamentos que tenho escrito, para os ensinar.',
        'E levantou-se Moisés com Josué seu servidor; e subiu Moisés ao monte de Deus.',
        'E disse aos anciãos: Esperai-nos aqui, até que tornemos a vós; e eis que Arão e Hur ficam convosco; quem tiver algum negócio, se chegará a eles.',
        'E, subindo Moisés ao monte, a nuvem cobriu o monte.',
        'E a glória do Senhor repousou sobre o monte Sinai, e a nuvem o cobriu por seis dias; e ao sétimo dia chamou a Moisés do meio da nuvem.',
        'E o parecer da glória do Senhor era como um fogo consumidor no cume do monte, aos olhos dos filhos de Israel.',
        'E Moisés entrou no meio da nuvem, depois que subiu ao monte; e Moisés esteve no monte quarenta dias e quarenta noites.',
      ],
      [
        'Então falou o SENHOR a Moisés, dizendo:',
        'Fala aos filhos de Israel, que me tragam uma oferta alçada; de todo o homem cujo coração se mover voluntariamente, dele tomareis a minha oferta alçada.',
        'E esta é a oferta alçada que recebereis deles: ouro, e prata, e cobre,',
        'E azul, e púrpura, e carmesim, e linho fino, e pêlos de cabras,',
        'E peles de carneiros tintas de vermelho, e peles de texugos, e madeira de acácia,',
        'Azeite para a luz, especiarias para o óleo da unção, e especiarias para o incenso,',
        'Pedras de ônix, e pedras de engaste para o éfode e para o peitoral.',
        'E me farão um santuário, e habitarei no meio deles.',
        'Conforme a tudo o que eu te mostrar para modelo do tabernáculo, e para modelo de todos os seus pertences, assim mesmo o fareis.',
        'Também farão uma arca de madeira de acácia; o seu comprimento será de dois côvados e meio, e a sua largura de um côvado e meio, e de um côvado e meio a sua altura.',
        'E cobri-la-á de ouro puro; por dentro e por fora a cobrirás; e farás sobre ela uma coroa de ouro ao redor;',
        'E fundirás para ela quatro argolas de ouro, e as porás nos quatro cantos dela, duas argolas num lado dela, e duas argolas noutro lado.',
        'E farás varas de madeira de acácia, e as cobrirás com ouro.',
        'E colocarás as varas nas argolas, aos lados da arca, para se levar com elas a arca.',
        'As varas estarão nas argolas da arca, não se tirarão dela.',
        'Depois porás na arca o testemunho, que eu te darei.',
        'Também farás um propiciatório de ouro puro; o seu comprimento será de dois côvados e meio, e a sua largura de um côvado e meio.',
        'Farás também dois querubins de ouro; de ouro batido os farás, nas duas extremidades do propiciatório.',
        'Farás um querubim na extremidade de uma parte, e o outro querubim na extremidade da outra parte; de uma só peça com o propiciatório, fareis os querubins nas duas extremidades dele.',
        'Os querubins estenderão as suas asas por cima, cobrindo com elas o propiciatório; as faces deles uma defronte da outra; as faces dos querubins estarão voltadas para o propiciatório.',
        'E porás o propiciatório em cima da arca, depois que houveres posto na arca o testemunho que eu te darei.',
        'E ali virei a ti, e falarei contigo de cima do propiciatório, do meio dos dois querubins (que estão sobre a arca do testemunho), tudo o que eu te ordenar para os filhos de Israel.',
        'Também farás uma mesa de madeira de acácia; o seu comprimento será de dois côvados, e a sua largura de um côvado, e a sua altura de um côvado e meio.',
        'E cobri-la-ás com ouro puro; também lhe farás uma coroa de ouro ao redor.',
        'Também lhe farás uma moldura ao redor, da largura de quatro dedos, e lhe farás uma coroa de ouro ao redor da moldura.',
        'Também lhe farás quatro argolas de ouro; e porás as argolas aos quatro cantos, que estão nos seus quatro pés.',
        'Defronte da moldura estarão as argolas, como lugares para os varais, para se levar a mesa.',
        'Farás, pois, estes varais de madeira de acácia, e cobri-los-ás com ouro; e levar-se-á com eles a mesa.',
        'Também farás os seus pratos, e as suas colheres, e as suas cobertas, e as suas tigelas com que se hão de oferecer libações; de ouro puro os farás.',
        'E sobre a mesa porás o pão da proposição perante a minha face perpetuamente.',
        'Também farás um candelabro de ouro puro; de ouro batido se fará este candelabro; o seu pé, as suas hastes, os seus copos, os seus botões, e as suas flores serão do mesmo.',
        'E dos seus lados sairão seis hastes; três hastes do candelabro de um lado dele, e três hastes do outro lado dele.',
        'Numa haste haverá três copos a modo de amêndoas, um botão e uma flor; e três copos a modo de amêndoas na outra haste, um botão e uma flor; assim serão as seis hastes que saem do candelabro.',
        'Mas no candelabro mesmo haverá quatro copos a modo de amêndoas, com seus botões e com suas flores;',
        'E um botão debaixo de duas hastes que saem dele; e ainda um botão debaixo de duas outras hastes que saem dele; e ainda um botão debaixo de duas outras hastes que saem dele; assim se fará com as seis hastes que saem do candelabro.',
        'Os seus botões e as suas hastes serão do mesmo; tudo será de uma só peça, obra batida de ouro puro.',
        'Também lhe farás sete lâmpadas, as quais se acenderão para iluminar defronte dele.',
        'Os seus espevitadores e os seus apagadores serão de ouro puro.',
        'De um talento de ouro puro os farás, com todos estes vasos.',
        'Atenta, pois, que o faças conforme ao seu modelo, que te foi mostrado no monte.',
      ],
      [
        'E o tabernáculo farás de dez cortinas de linho fino torcido, e azul, púrpura, e carmesim; com querubins as farás de obra esmerada.',
        'O comprimento de uma cortina será de vinte e oito côvados, e a largura de uma cortina de quatro côvados; todas estas cortinas serão de uma medida.',
        'Cinco cortinas se enlaçarão uma à outra; e as outras cinco cortinas se enlaçarão uma com a outra.',
        'E farás laçadas de azul na orla de uma cortina, na extremidade, e na juntura; assim também farás na orla da extremidade da outra cortina, na segunda juntura.',
        'Cinqüenta laçadas farás numa cortina, e outras cinqüenta laçadas farás na extremidade da cortina que está na segunda juntura; as laçadas estarão presas uma com a outra.',
        'Farás também cinqüenta colchetes de ouro, e ajuntarás com estes colchetes as cortinas, uma com a outra, e será um tabernáculo.',
        'Farás também cortinas de pêlos de cabras para servirem de tenda sobre o tabernáculo; onze cortinas farás.',
        'O comprimento de uma cortina será de trinta côvados, e a largura da mesma cortina de quatro côvados; estas onze cortinas serão da mesma medida.',
        'E juntarás cinco destas cortinas à parte, e as outras seis cortinas também à parte; e dobrarás a sexta cortina à frente da tenda.',
        'E farás cinqüenta laçadas na borda de uma cortina, na extremidade, na juntura, e outras cinqüenta laçadas na borda da outra cortina, na segunda juntura.',
        'Farás também cinqüenta colchetes de cobre, e colocarás os colchetes nas laçadas, e assim ajuntarás a tenda, para que seja uma.',
        'E a parte que sobejar das cortinas da tenda, a saber, a metade da cortina que sobejar, penderá de sobra às costas do tabernáculo.',
        'E um côvado de um lado, e outro côvado do outro, que sobejará no comprimento das cortinas da tenda, penderá de sobra aos lados do tabernáculo de um e de outro lado, para cobri-lo.',
        'Farás também à tenda uma coberta de peles de carneiro, tintas de vermelho, e outra coberta de peles de texugo em cima.',
        'Farás também as tábuas para o tabernáculo de madeira de acácia, que serão postas verticalmente.',
        'O comprimento de uma tábua será de dez côvados, e a largura de cada tábua será de um côvado e meio.',
        'Dois encaixes terá cada tábua, travados um com o outro; assim farás com todas as tábuas do tabernáculo.',
        'E farás as tábuas para o tabernáculo assim: vinte tábuas para o lado meridional.',
        'Farás também quarenta bases de prata debaixo das vinte tábuas; duas bases debaixo de uma tábua para os seus dois encaixes e duas bases debaixo de outra tábua para os seus dois encaixes.',
        'Também haverá vinte tábuas ao outro lado do tabernáculo, para o lado norte,',
        'Com as suas quarenta bases de prata; duas bases debaixo de uma tábua, e duas bases debaixo de outra tábua,',
        'E ao lado do tabernáculo para o ocidente farás seis tábuas.',
        'Farás também duas tábuas para os cantos do tabernáculo, de ambos os lados.',
        'E por baixo se ajuntarão, e também em cima dele se ajuntarão numa argola. Assim se fará com as duas tábuas; ambas serão por tábuas para os dois cantos.',
        'Assim serão as oito tábuas com as suas bases de prata, dezesseis bases; duas bases debaixo de uma tábua, e duas bases debaixo da outra tábua.',
        'Farás também cinco travessas de madeira de acácia, para as tábuas de um lado do tabernáculo,',
        'E cinco travessas para as tábuas do outro lado do tabernáculo; como também cinco travessas para as tábuas do outro lado do tabernáculo, de ambos os lados, para o ocidente.',
        'E a travessa central estará no meio das tábuas, passando de uma extremidade até à outra.',
        'E cobrirás de ouro as tábuas, e farás de ouro as suas argolas, para passar por elas as travessas; também as travessas as cobrirás de ouro.',
        'Então levantarás o tabernáculo conforme ao modelo que te foi mostrado no monte.',
        'Depois farás um véu de azul, e púrpura, e carmesim, e de linho fino torcido; com querubins de obra prima se fará.',
        'E colocá-lo-ás sobre quatro colunas de madeira de acácia, cobertas de ouro; seus colchetes serão de ouro, sobre quatro bases de prata.',
        'Pendurarás o véu debaixo dos colchetes, e porás a arca do testemunho ali dentro do véu; e este véu vos fará separação entre o santuário e o lugar santíssimo,',
        'E porás a coberta do propiciatório sobre a arca do testemunho no lugar santíssimo,',
        'E a mesa porás fora do véu, e o candelabro defronte da mesa, ao lado do tabernáculo, para o sul; mas a mesa porás ao lado do norte.',
        'Farás também para a porta da tenda, uma cortina de azul, e púrpura, e carmesim, e de linho fino torcido, de obra de bordador.',
        'E farás para esta cortina cinco colunas de madeira de acácia, e as cobrirás de ouro; seus colchetes serão de ouro, e far-lhe-ás de fundição cinco bases de cobre.',
      ],
      [
        'Farás também o altar de madeira de acácia; cinco côvados será o comprimento, e cinco côvados a largura (será quadrado o altar), e três côvados a sua altura.',
        'E farás as suas pontas nos seus quatro cantos; as suas pontas serão do mesmo, e o cobrirás de cobre.',
        'Far-lhe-ás também os seus recipientes, para recolher a sua cinza, e as suas pás, e as suas bacias, e os seus garfos e os seus braseiros; todos os seus utensílios farás de cobre.',
        'Far-lhe-ás também um crivo de cobre em forma de rede, e farás a esta rede quatro argolas de metal nos seus quatro cantos.',
        'E as porás dentro da borda do altar para baixo, de maneira que a rede chegue até ao meio do altar.',
        'Farás também varais para o altar, varais de madeira de acácia, e os cobrirás de cobre.',
        'E os varais serão postos nas argolas, de maneira que os varais estejam de ambos os lados do altar, quando for levado.',
        'Oco e de tábuas o farás; como se te mostrou no monte, assim o farão.',
        'Farás também o pátio do tabernáculo, ao lado meridional que dá para o sul; o pátio terá cortinas de linho fino torcido; o comprimento de cada lado será de cem côvados.',
        'Também as suas vinte colunas e as suas vinte bases serão de cobre; os colchetes das colunas e as suas faixas serão de prata.',
        'Assim também para o lado norte as cortinas, no comprimento, serão de cem côvados; e as suas vinte colunas e as suas vinte bases serão de cobre; os colchetes das colunas e as suas faixas serão de prata,',
        'E na largura do pátio para o lado do ocidente haverá cortinas de cinqüenta côvados; as suas colunas dez, e as suas bases dez.',
        'Semelhantemente a largura do pátio do lado oriental para o levante será de cinqüenta côvados.',
        'De maneira que haja quinze côvados de cortinas de um lado; suas colunas três, e as suas bases três.',
        'E quinze côvados das cortinas do outro lado; as suas colunas três, e as suas bases três.',
        'E à porta do pátio haverá uma cortina de vinte côvados, de azul, e púrpura, e carmesim, e de linho fino torcido, de obra de bordador; as suas colunas quatro, e as suas bases quatro.',
        'Todas as colunas do pátio ao redor serão cingidas de faixas de prata; os seus colchetes serão de prata, mas as suas bases de cobre.',
        'O comprimento do pátio será de cem côvados, e a largura de cada lado de cinqüenta, e a altura de cinco côvados, as cortinas serão de linho fino torcido; mas as suas bases serão de cobre.',
        'No tocante a todos os vasos do tabernáculo em todo o seu serviço, até todos os seus pregos, e todos os pregos do pátio, serão de cobre.',
        'Tu pois ordenarás aos filhos de Israel que te tragam azeite puro de oliveiras, batido, para o candeeiro, para fazer arder as lâmpadas continuamente.',
        'Na tenda da congregação, fora do véu que está diante do testemunho, Arão e seus filhos as porão em ordem, desde a tarde até a manhã, perante o Senhor; isto será um estatuto perpétuo para os filhos de Israel, pelas suas gerações.',
      ],
      [
        'Depois tu farás chegar a ti teu irmão Arão, e seus filhos com ele, do meio dos filhos de Israel, para me administrarem o ofício sacerdotal; a saber: Arão, Nadabe, e Abiú, Eleazar e Itamar, os filhos de Arão.',
        'E farás vestes sagradas a Arão teu irmão, para glória e ornamento.',
        'Falarás também a todos os que são sábios de coração, a quem eu tenho enchido do espírito da sabedoria, que façam vestes a Arão para santificá-lo; para que me administre o ofício sacerdotal.',
        'Estas pois são as vestes que farão: um peitoral, e um éfode, e um manto, e uma túnica bordada, uma mitra, e um cinto; farão, pois, santas vestes para Arão, teu irmão, e para seus filhos, para me administrarem o ofício sacerdotal.',
        'E tomarão o ouro, e o azul, e a púrpura, e o carmesim, e o linho fino,',
        'E farão o éfode de ouro, e de azul, e de púrpura, e de carmesim, e de linho fino torcido, de obra esmerada.',
        'Terá duas ombreiras, que se unam às suas duas pontas, e assim se unirá.',
        'E o cinto de obra esmerada do seu éfode, que estará sobre ele, será da sua mesma obra, igualmente, de ouro, de azul, e de púrpura, e de carmesim, e de linho fino torcido.',
        'E tomarás duas pedras de ônix, e gravarás nelas os nomes dos filhos de Israel,',
        'Seis dos seus nomes numa pedra, e os outros seis nomes na outra pedra, segundo as suas gerações;',
        'Conforme à obra do lapidário, como o lavor de selos lavrarás estas duas pedras, com os nomes dos filhos de Israel; engastadas ao redor em ouro as farás.',
        'E porás as duas pedras nas ombreiras do éfode, por pedras de memória para os filhos de Israel; e Arão levará os seus nomes sobre ambos os seus ombros, para memória diante do Senhor.',
        'Farás também engastes de ouro,',
        'E duas cadeiazinhas de ouro puro; de igual medida, de obra de fieira as farás; e as cadeiazinhas de fieira porás nos engastes.',
        'Farás também o peitoral do juízo de obra esmerada, conforme à obra do éfode o farás; de ouro, de azul, e de púrpura, e de carmesim, e de linho fino torcido o farás.',
        'Quadrado e duplo, será de um palmo o seu comprimento, e de um palmo a sua largura.',
        'E o encherás de pedras de engaste, com quatro ordens de pedras; a ordem de um sárdio, de um topázio, e de um carbúnculo; esta será a primeira ordem;',
        'E a segunda ordem será de uma esmeralda, de uma safira, e de um diamante;',
        'E a terceira ordem será de um jacinto, de uma ágata, e de uma ametista;',
        'E a quarta ordem será de um berilo, e de um ônix, e de um jaspe; engastadas em ouro serão nos seus engastes.',
        'E serão aquelas pedras segundo os nomes dos filhos de Israel, doze segundo os seus nomes; serão esculpidas como selos, cada uma com o seu nome, para as doze tribos.',
        'Também farás para o peitoral cadeiazinhas de igual medida, obra trançada de ouro puro.',
        'Também farás para o peitoral dois anéis de ouro, e porás os dois anéis nas extremidades do peitoral.',
        'Então porás as duas cadeiazinhas de fieira de ouro nos dois anéis, nas extremidades do peitoral;',
        'E as duas pontas das duas cadeiazinhas de fieira colocarás nos dois engastes, e as porás nas ombreiras do éfode, na frente dele.',
        'Farás também dois anéis de ouro, e os porás nas duas extremidades do peitoral, na sua borda que estiver junto ao éfode por dentro.',
        'Farás também dois anéis de ouro, que porás nas duas ombreiras do éfode, abaixo, na frente dele, perto da sua juntura, sobre o cinto de obra esmerada do éfode.',
        'E ligarão o peitoral, com os seus anéis, aos anéis do éfode por cima, com um cordão de azul, para que esteja sobre o cinto de obra esmerada do éfode; e nunca se separará o peitoral do éfode.',
        'Assim Arão levará os nomes dos filhos de Israel no peitoral do juízo sobre o seu coração, quando entrar no santuário, para memória diante do Senhor continuamente.',
        'Também porás no peitoral do juízo Urim e Tumim, para que estejam sobre o coração de Arão, quando entrar diante do Senhor: assim Arão levará o juízo dos filhos de Israel sobre o seu coração diante do Senhor continuamente.',
        'Também farás o manto do éfode, todo de azul.',
        'E a abertura da cabeça estará no meio dele; esta abertura terá uma borda de obra tecida ao redor; como abertura de cota de malha será, para que não se rompa.',
        'E nas suas bordas farás romãs de azul, e de púrpura, e de carmesim, ao redor das suas bordas; e campainhas de ouro no meio delas ao redor.',
        'Uma campainha de ouro, e uma romã, outra campainha de ouro, e outra romã, haverá nas bordas do manto ao redor,',
        'E estará sobre Arão quando ministrar, para que se ouça o seu sonido, quando entrar no santuário diante do Senhor, e quando sair, para que não morra.',
        'Também farás uma lâmina de ouro puro, e nela gravarás como as gravuras de selos: SANTIDADE AO SENHOR.',
        'E atá-la-ás com um cordão de azul, de modo que esteja na mitra, na frente da mitra estará;',
        'E estará sobre a testa de Arão, para que Arão leve a iniqüidade das coisas santas, que os filhos de Israel santificarem em todas as ofertas de suas coisas santas; e estará continuamente na sua testa, para que tenham aceitação perante o Senhor.',
        'Também farás túnica de linho fino; também farás uma mitra de linho fino; mas o cinto farás de obra de bordador.',
        'Também farás túnicas aos filhos de Arão, e far-lhes-ás cintos; também lhes farás tiaras, para glória e ornamento.',
        'E vestirás com eles a Arão, teu irmão, e também seus filhos; e os ungirás e consagrarás, e os santificarás, para que me administrem o sacerdócio.',
        'Faze-lhes também calções de linho, para cobrirem a carne nua; irão dos lombos até as coxas.',
        'E estarão sobre Arão e sobre seus filhos, quando entrarem na tenda da congregação, ou quando chegarem ao altar para ministrar no santuário, para que não levem iniqüidade e morram; isto será estatuto perpétuo para ele e para a sua descendência depois dele.',
      ],
      [
        'Isto é o que lhes hás de fazer, para os santificar, para que me administrem o sacerdócio: Toma um novilho e dois carneiros sem mácula,',
        'E pão ázimo, e bolos ázimos, amassados com azeite, e coscorões ázimos, untados com azeite; com flor de farinha de trigo os farás,',
        'E os porás num cesto, e os trarás no cesto, com o novilho e os dois carneiros.',
        'Então farás chegar a Arão e a seus filhos à porta da tenda da congregação, e os lavarás com água;',
        'Depois tomarás as vestes, e vestirás a Arão da túnica e do manto do éfode, e do éfode, e do peitoral; e o cingirás com o cinto de obra de artífice do éfode.',
        'E a mitra porás sobre a sua cabeça; a coroa da santidade porás sobre a mitra.',
        'E tomarás o azeite da unção, e o derramarás sobre a sua cabeça; assim o ungirás.',
        'Depois farás chegar seus filhos, e lhes farás vestir túnicas.',
        'E os cingirás com o cinto, a Arão e a seus filhos, e lhes atarás as tiaras, para que tenham o sacerdócio por estatuto perpétuo, e consagrarás a Arão e a seus filhos;',
        'E farás chegar o novilho diante da tenda da congregação, e Arão e seus filhos porão as suas mãos sobre a cabeça do novilho;',
        'E imolarás o novilho perante o Senhor, à porta da tenda da congregação.',
        'Depois tomarás do sangue do novilho, e o porás com o teu dedo sobre as pontas do altar, e todo o sangue restante derramarás à base do altar.',
        'Também tomarás toda a gordura que cobre as entranhas, e o redenho de sobre o fígado, e ambos os rins, e a gordura que houver neles, e queimá-los-ás sobre o altar;',
        'Mas a carne do novilho, e a sua pele, e o seu esterco queimarás com fogo fora do arraial; é sacrifício pelo pecado.',
        'Depois tomarás um carneiro, e Arão e seus filhos porão as suas mãos sobre a cabeça do carneiro,',
        'E imolarás o carneiro, e tomarás o seu sangue, e o espalharás sobre o altar ao redor;',
        'E partirás o carneiro por suas partes, e lavarás as suas entranhas e as suas pernas, e as porás sobre as suas partes e sobre a sua cabeça.',
        'Assim queimarás todo o carneiro sobre o altar; é um holocausto para o Senhor, cheiro suave; uma oferta queimada ao Senhor.',
        'Depois tomarás o outro carneiro, e Arão e seus filhos porão as suas mãos sobre a sua cabeça;',
        'E imolarás o carneiro e tomarás do seu sangue, e o porás sobre a ponta da orelha direita de Arão, e sobre as pontas das orelhas direitas de seus filhos, como também sobre os dedos polegares das suas mãos direitas, e sobre os dedos polegares dos seus pés direitos; e o restante do sangue espalharás sobre o altar ao redor;',
        'Então tomarás do sangue, que estará sobre o altar, e do azeite da unção, e o espargirás sobre Arão e sobre as suas vestes, e sobre seus filhos, e sobre as vestes de seus filhos com ele; para que ele seja santificado, e as suas vestes, também seus filhos, e as vestes de seus filhos com ele.',
        'Depois tomarás do carneiro a gordura, e a cauda, e a gordura que cobre as entranhas, e o redenho do fígado, e ambos os rins com a gordura que houver neles, e o ombro direito, porque é carneiro das consagrações;',
        'E um pão, e um bolo de pão azeitado, e um coscorão do cesto dos pães ázimos que estão diante do Senhor.',
        'E tudo porás nas mãos de Arão, e nas mãos de seus filhos; e com movimento oferecerás perante o Senhor.',
        'Depois o tomarás das suas mãos e o queimarás no altar sobre o holocausto por cheiro suave perante o Senhor; é oferta queimada ao Senhor.',
        'E tomarás o peito do carneiro das consagrações, que é de Arão, e com movimento oferecerás perante o Senhor; e isto será a tua porção.',
        'E santificarás o peito da oferta de movimento e o ombro da oferta alçada, que foi movido e alçado do carneiro das consagrações, que for de Arão e de seus filhos.',
        'E será para Arão e para seus filhos por estatuto perpétuo dos filhos de Israel, porque é oferta alçada; e a oferta alçada será dos filhos de Israel, dos seus sacrifícios pacíficos; a sua oferta alçada será para o Senhor.',
        'E as vestes sagradas, que são de Arão, serão de seus filhos depois dele, para serem ungidos com elas para serem consagrados com elas.',
        'Sete dias as vestirá aquele que de seus filhos for sacerdote em seu lugar, quando entrar na tenda da congregação para ministrar no santuário.',
        'E tomarás o carneiro das consagrações e cozerás a sua carne no lugar santo;',
        'E Arão e seus filhos comerão a carne deste carneiro, e o pão que está no cesto, à porta da tenda da congregação.',
        'E comerão as coisas com que for feita expiação, para consagrá-los, e para santificá-los; mas o estranho delas não comerá, porque são santas.',
        'E se sobejar alguma coisa da carne das consagrações ou do pão até pela manhã, o que sobejar queimarás com fogo; não se comerá, porque é santo.',
        'Assim, pois, farás a Arão e a seus filhos conforme a tudo o que eu te tenho ordenado; por sete dias os consagrarás.',
        'Também cada dia prepararás um novilho por sacrifício pelo pecado para as expiações, e purificarás o altar, fazendo expiação sobre ele; e o ungirás para santificá-lo.',
        'Sete dias farás expiação pelo altar, e o santificarás; e o altar será santíssimo; tudo o que tocar o altar será santo.',
        'Isto, pois, é o que oferecereis sobre o altar: dois cordeiros de um ano, cada dia, continuamente.',
        'Um cordeiro oferecerás pela manhã, e o outro cordeiro oferecerás à tarde.',
        'Com um cordeiro a décima parte de flor de farinha, misturada com a quarta parte de um him de azeite batido, e para libação a quarta parte de um him de vinho,',
        'E o outro cordeiro oferecerás à tarde, e com ele farás como com a oferta da manhã, e conforme à sua libação, por cheiro suave; oferta queimada é ao Senhor.',
        'Este será o holocausto contínuo por vossas gerações, à porta da tenda da congregação, perante o Senhor, onde vos encontrarei, para falar contigo ali.',
        'E ali virei aos filhos de Israel, para que por minha glória sejam santificados.',
        'E santificarei a tenda da congregação e o altar; também santificarei a Arão e seus filhos, para que me administrem o sacerdócio.',
        'E habitarei no meio dos filhos de Israel, e lhes serei o seu Deus,',
        'E saberão que eu sou o Senhor seu Deus, que os tenho tirado da terra do Egito, para habitar no meio deles. Eu sou o Senhor seu Deus.',
      ],
      [
        'E farás um altar para queimar o incenso; de madeira de acácia o farás.',
        'O seu comprimento será de um côvado, e a sua largura de um côvado; será quadrado, e dois côvados a sua altura; dele mesmo serão as suas pontas.',
        'E com ouro puro o forrarás, o seu teto, e as suas paredes ao redor, e as suas pontas; e lhe farás uma coroa de ouro ao redor.',
        'Também lhe farás duas argolas de ouro debaixo da sua coroa; nos dois cantos as farás, de ambos os lados; e serão para lugares dos varais, com que será levado.',
        'E os varais farás de madeira de acácia, e os forrarás com ouro.',
        'E o porás diante do véu que está diante da arca do testemunho, diante do propiciatório, que está sobre o testemunho, onde me ajuntarei contigo.',
        'E Arão sobre ele queimará o incenso das especiarias; cada manhã, quando puser em ordem as lâmpadas, o queimará.',
        'E, acendendo Arão as lâmpadas à tarde, o queimará; este será incenso contínuo perante o Senhor pelas vossas gerações.',
        'Não oferecereis sobre ele incenso estranho, nem holocausto, nem oferta; nem tampouco derramareis sobre ele libações.',
        'E uma vez no ano Arão fará expiação sobre as suas pontas com o sangue do sacrifício das expiações; uma vez no ano fará expiação sobre ele pelas vossas gerações; santíssimo é ao Senhor.',
        'Falou mais o Senhor a Moisés dizendo:',
        'Quando fizeres a contagem dos filhos de Israel, conforme a sua soma, cada um deles dará ao Senhor o resgate da sua alma, quando os contares; para que não haja entre eles praga alguma, quando os contares.',
        'Todo aquele que passar pelo arrolamento dará isto: a metade de um siclo, segundo o siclo do santuário (este siclo é de vinte geras); a metade de um siclo é a oferta ao Senhor.',
        'Qualquer que passar pelo arrolamento, de vinte anos para cima, dará a oferta alçada ao Senhor.',
        'O rico não dará mais, e o pobre não dará menos da metade do siclo, quando derem a oferta alçada ao Senhor, para fazer expiação por vossas almas.',
        'E tomarás o dinheiro das expiações dos filhos de Israel, e o darás ao serviço da tenda da congregação; e será para memória aos filhos de Israel diante do Senhor, para fazer expiação por vossas almas.',
        'E falou o Senhor a Moisés, dizendo:',
        'Farás também uma pia de cobre com a sua base de cobre, para lavar; e a porás entre a tenda da congregação e o altar; e nela deitarás água.',
        'E Arão e seus filhos nela lavarão as suas mãos e os seus pés.',
        'Quando entrarem na tenda da congregação, lavar-se-ão com água, para que não morram, ou quando se chegarem ao altar para ministrar, para acender a oferta queimada ao Senhor.',
        'Lavarão, pois, as suas mãos e os seus pés, para que não morram; e isto lhes será por estatuto perpétuo a ele e à sua descendência nas suas gerações.',
        'Falou mais o Senhor a Moisés, dizendo:',
        'Tu, pois, toma para ti das principais especiarias, da mais pura mirra quinhentos siclos, e de canela aromática a metade, a saber, duzentos e cinqüenta siclos, e de cálamo aromático duzentos e cinqüenta siclos,',
        'E de cássia quinhentos siclos, segundo o siclo do santuário, e de azeite de oliveiras um him.',
        'E disto farás o azeite da santa unção, o perfume composto segundo a obra do perfumista: este será o azeite da santa unção.',
        'E com ele ungirás a tenda da congregação, e a arca do testemunho,',
        'E a mesa com todos os seus utensílios, e o candelabro com os seus utensílios, e o altar do incenso.',
        'E o altar do holocausto com todos os seus utensílios, e a pia com a sua base.',
        'Assim santificarás estas coisas, para que sejam santíssimas; tudo o que tocar nelas será santo.',
        'Também ungirás a Arão e seus filhos, e os santificarás para me administrarem o sacerdócio.',
        'E falarás aos filhos de Israel, dizendo: Este me será o azeite da santa unção nas vossas gerações.',
        'Não se ungirá com ele a carne do homem, nem fareis outro de semelhante composição; santo é, e será santo para vós.',
        'O homem que compuser um perfume como este, ou dele puser sobre um estranho, será extirpado do seu povo.',
        'Disse mais o Senhor a Moisés: Toma especiarias aromáticas, estoraque, e onicha, e gálbano; estas especiarias aromáticas e o incenso puro, em igual proporção;',
        'E disto farás incenso, um perfume segundo a arte do perfumista, temperado, puro e santo;',
        'E uma parte dele moerás, e porás diante do testemunho, na tenda da congregação, onde eu virei a ti; coisa santíssima vos será.',
        'Porém o incenso que fareis conforme essa composição, não o fareis para vós mesmos; santo será para o Senhor.',
        'O homem que fizer tal como este para cheirar, será extirpado do seu povo.',
      ],
      [
        'Depois falou o SENHOR a Moisés, dizendo:',
        'Eis que eu tenho chamado por nome a Bezalel, o filho de Uri, filho de Hur, da tribo de Judá,',
        'E o enchi do Espírito de Deus, de sabedoria, e de entendimento, e de ciência, em todo o lavor,',
        'Para elaborar projetos, e trabalhar em ouro, em prata, e em cobre,',
        'E em lapidar pedras para engastar, e em entalhes de madeira, para trabalhar em todo o lavor.',
        'E eis que eu tenho posto com ele a Aoliabe, o filho de Aisamaque, da tribo de Dã, e tenho dado sabedoria ao coração de todos aqueles que são hábeis, para que façam tudo o que te tenho ordenado.',
        'A saber: a tenda da congregação, e a arca do testemunho, e o propiciatório que estará sobre ela, e todos os pertences da tenda;',
        'E a mesa com os seus utensílios, e o candelabro de ouro puro com todos os seus pertences, e o altar do incenso;',
        'E o altar do holocausto com todos os seus utensílios, e a pia com a sua base;',
        'E as vestes do ministério, e as vestes sagradas de Arão o sacerdote, e as vestes de seus filhos, para administrarem o sacerdócio;',
        'E o azeite da unção, e o incenso aromático para o santuário; farão conforme a tudo que te tenho mandado.',
        'Falou mais o Senhor a Moisés, dizendo:',
        'Tu, pois, fala aos filhos de Israel, dizendo: Certamente guardareis meus sábados; porquanto isso é um sinal entre mim e vós nas vossas gerações; para que saibais que eu sou o Senhor, que vos santifica.',
        'Portanto guardareis o sábado, porque santo é para vós; aquele que o profanar certamente morrerá; porque qualquer que nele fizer alguma obra, aquela alma será eliminada do meio do seu povo.',
        'Seis dias se trabalhará, porém o sétimo dia é o sábado do descanso, santo ao Senhor; qualquer que no dia do sábado fizer algum trabalho, certamente morrerá.',
        'Guardarão, pois, o sábado os filhos de Israel, celebrando-o nas suas gerações por aliança perpétua.',
        'Entre mim e os filhos de Israel será um sinal para sempre; porque em seis dias fez o Senhor os céus e a terra, e ao sétimo dia descansou, e restaurou-se.',
        'E deu a Moisés (quando acabou de falar com ele no monte Sinai) as duas tábuas do testemunho, tábuas de pedra, escritas pelo dedo de Deus.',
      ],
      [
        'Mas vendo o povo que Moisés tardava em descer do monte, acercou-se de Arão, e disse-lhe: Levanta-te, faze-nos deuses, que vão adiante de nós; porque quanto a este Moisés, o homem que nos tirou da terra do Egito, não sabemos o que lhe sucedeu.',
        'E Arão lhes disse: Arrancai os pendentes de ouro, que estão nas orelhas de vossas mulheres, e de vossos filhos, e de vossas filhas, e trazei-mos.',
        'Então todo o povo arrancou os pendentes de ouro, que estavam nas suas orelhas, e os trouxeram a Arão.',
        'E ele os tomou das suas mãos, e trabalhou o ouro com um buril, e fez dele um bezerro de fundição. Então disseram: Este é teu deus, ó Israel, que te tirou da terra do Egito.',
        'E Arão, vendo isto, edificou um altar diante dele; e apregoou Arão, e disse: Amanhã será festa ao Senhor.',
        'E no dia seguinte madrugaram, e ofereceram holocaustos, e trouxeram ofertas pacíficas; e o povo assentou-se a comer e a beber; depois levantou-se a folgar.',
        'Então disse o Senhor a Moisés: Vai, desce; porque o teu povo, que fizeste subir do Egito, se tem corrompido,',
        'E depressa se tem desviado do caminho que eu lhe tinha ordenado; eles fizeram para si um bezerro de fundição, e perante ele se inclinaram, e ofereceram-lhe sacrifícios, e disseram: Este é o teu deus, ó Israel, que te tirou da terra do Egito.',
        'Disse mais o Senhor a Moisés: Tenho visto a este povo, e eis que é povo de dura cerviz.',
        'Agora, pois, deixa-me, para que o meu furor se acenda contra ele, e o consuma; e eu farei de ti uma grande nação.',
        'Moisés, porém, suplicou ao Senhor seu Deus e disse: Ó Senhor, por que se acende o teu furor contra o teu povo, que tiraste da terra do Egito com grande força e com forte mão?',
        'Por que hão de falar os egípcios, dizendo: Para mal os tirou, para matá-los nos montes, e para destruí-los da face da terra? Torna-te do furor da tua ira, e arrepende-te deste mal contra o teu povo.',
        'Lembra-te de Abraão, de Isaque, e de Israel, os teus servos, aos quais por ti mesmo tens jurado, e lhes disseste: Multiplicarei a vossa descendência como as estrelas dos céus, e darei à vossa descendência toda esta terra, de que tenho falado, para que a possuam por herança eternamente.',
        'Então o Senhor arrependeu-se do mal que dissera que havia de fazer ao seu povo.',
        'E virou-se Moisés e desceu do monte com as duas tábuas do testemunho na mão, tábuas escritas de ambos os lados; de um e de outro lado estavam escritas.',
        'E aquelas tábuas eram obra de Deus; também a escritura era a mesma escritura de Deus, esculpida nas tábuas.',
        'E, ouvindo Josué a voz do povo que jubilava, disse a Moisés: Alarido de guerra há no arraial.',
        'Porém ele respondeu: Não é alarido dos vitoriosos, nem alarido dos vencidos, mas o alarido dos que cantam, eu ouço.',
        'E aconteceu que, chegando Moisés ao arraial, e vendo o bezerro e as danças, acendeu-se-lhe o furor, e arremessou as tábuas das suas mãos, e quebrou-as ao pé do monte;',
        'E tomou o bezerro que tinham feito, e queimou-o no fogo, moendo-o até que se tornou em pó; e o espargiu sobre as águas, e deu-o a beber aos filhos de Israel.',
        'E Moisés perguntou a Arão: Que te tem feito este povo, que sobre ele trouxeste tamanho pecado?',
        'Então respondeu Arão: Não se acenda a ira do meu senhor; tu sabes que este povo é inclinado ao mal;',
        'E eles me disseram: Faze-nos um deus que vá adiante de nós; porque não sabemos o que sucedeu a este Moisés, a este homem que nos tirou da terra do Egito.',
        'Então eu lhes disse: Quem tem ouro, arranque-o; e deram-mo, e lancei-o no fogo, e saiu este bezerro.',
        'E, vendo Moisés que o povo estava despido, porque Arão o havia deixado despir-se para vergonha entre os seus inimigos,',
        'Pôs-se em pé Moisés na porta do arraial e disse: Quem é do Senhor, venha a mim. Então se ajuntaram a ele todos os filhos de Levi.',
        'E disse-lhes: Assim diz o Senhor Deus de Israel: Cada um ponha a sua espada sobre a sua coxa; e passai e tornai pelo arraial de porta em porta, e mate cada um a seu irmão, e cada um a seu amigo, e cada um a seu vizinho.',
        'E os filhos de Levi fizeram conforme à palavra de Moisés; e caíram do povo aquele dia uns três mil homens.',
        'Porquanto Moisés tinha dito: Consagrai hoje as vossas mãos ao Senhor; porquanto cada um será contra o seu filho e contra o seu irmão; e isto, para que ele vos conceda hoje uma bênção.',
        'E aconteceu que no dia seguinte Moisés disse ao povo: Vós cometestes grande pecado. Agora, porém, subirei ao Senhor; porventura farei propiciação por vosso pecado.',
        'Assim tornou-se Moisés ao Senhor, e disse: Ora, este povo cometeu grande pecado fazendo para si deuses de ouro.',
        'Agora, pois, perdoa o seu pecado; se não, risca-me, peço-te, do teu livro, que tens escrito.',
        'Então disse o Senhor a Moisés: Aquele que pecar contra mim, a este riscarei do meu livro.',
        'Vai, pois, agora, conduze este povo para onde te tenho dito; eis que o meu anjo irá adiante de ti; porém no dia da minha visitação visitarei neles o seu pecado.',
        'Assim feriu o Senhor o povo, por ter sido feito o bezerro que Arão tinha formado.',
      ],
      [
        'Disse mais o SENHOR a Moisés: Vai, sobe daqui, tu e o povo que fizeste subir da terra do Egito, à terra que jurei a Abraão, a Isaque, e a Jacó, dizendo: À tua descendência a darei.',
        'E enviarei um anjo adiante de ti, e lançarei fora os cananeus, e os amorreus, e os heteus, e os perizeus, e os heveus, e os jebuseus,',
        'A uma terra que mana leite e mel; porque eu não subirei no meio de ti, porquanto és povo de dura cerviz, para que te não consuma eu no caminho.',
        'E, ouvindo o povo esta má notícia, pranteou-se e ninguém pôs sobre si os seus atavios.',
        'Porquanto o Senhor tinha dito a Moisés: Dize aos filhos de Israel: És povo de dura cerviz; se por um momento subir no meio de ti, te consumirei; porém agora tira os teus atavios, para que eu saiba o que te hei de fazer.',
        'Então os filhos de Israel se despojaram dos seus atavios, ao pé do monte Horebe.',
        'E tomou Moisés a tenda, e a estendeu para si fora do arraial, desviada longe do arraial, e chamou-lhe a tenda da congregação. E aconteceu que todo aquele que buscava o Senhor saía à tenda da congregação, que estava fora do arraial.',
        'E acontecia que, saindo Moisés à tenda, todo o povo se levantava, e cada um ficava em pé à porta da sua tenda; e olhava para Moisés pelas costas, até ele entrar na tenda.',
        'E sucedia que, entrando Moisés na tenda, descia a coluna de nuvem, e punha-se à porta da tenda; e o Senhor falava com Moisés.',
        'E, vendo todo o povo a coluna de nuvem que estava à porta da tenda, todo o povo se levantava e cada um, à porta da sua tenda, adorava.',
        'E falava o Senhor a Moisés face a face, como qualquer fala com o seu amigo; depois tornava-se ao arraial; mas o seu servidor, o jovem Josué, filho de Num, nunca se apartava do meio da tenda.',
        'E Moisés disse ao Senhor: Eis que tu me dizes: Faze subir a este povo, porém não me fazes saber a quem hás de enviar comigo; e tu disseste: Conheço-te por teu nome, também achaste graça aos meus olhos.',
        'Agora, pois, se tenho achado graça aos teus olhos, rogo-te que me faças saber o teu caminho, e conhecer-te-ei, para que ache graça aos teus olhos; e considera que esta nação é o teu povo.',
        'Disse pois: Irá a minha presença contigo para te fazer descansar.',
        'Então lhe disse: Se tu mesmo não fores conosco, não nos faças subir daqui.',
        'Como, pois, se saberá agora que tenho achado graça aos teus olhos, eu e o teu povo? Acaso não é por andares tu conosco, de modo a sermos separados, eu e o teu povo, de todos os povos que há sobre a face da terra?',
        'Então disse o Senhor a Moisés: Farei também isto, que tens dito; porquanto achaste graça aos meus olhos, e te conheço por nome.',
        'Então ele disse: Rogo-te que me mostres a tua glória.',
        'Porém ele disse: Eu farei passar toda a minha bondade por diante de ti, e proclamarei o nome do Senhor diante de ti; e terei misericórdia de quem eu tiver misericórdia, e me compadecerei de quem eu me compadecer.',
        'E disse mais: Não poderás ver a minha face, porquanto homem nenhum verá a minha face, e viverá.',
        'Disse mais o Senhor: Eis aqui um lugar junto a mim; aqui te porás sobre a penha.',
        'E acontecerá que, quando a minha glória passar, pôr-te-ei numa fenda da penha, e te cobrirei com a minha mão, até que eu haja passado.',
        'E, havendo eu tirado a minha mão, me verás pelas costas; mas a minha face não se verá.',
      ],
      [
        'Então disse o SENHOR a Moisés: Lavra duas tábuas de pedra, como as primeiras; e eu escreverei nas tábuas as mesmas palavras que estavam nas primeiras tábuas, que tu quebraste.',
        'E prepara-te para amanhã, para que subas pela manhã ao monte Sinai, e ali põe-te diante de mim no cume do monte.',
        'E ninguém suba contigo, e também ninguém apareça em todo o monte; nem ovelhas nem bois se apascentem defronte do monte.',
        'Então Moisés lavrou duas tábuas de pedra, como as primeiras; e levantando-se pela manhã de madrugada, subiu ao monte Sinai, como o Senhor lhe tinha ordenado; e levou as duas tábuas de pedra nas suas mãos.',
        'E o Senhor desceu numa nuvem e se pôs ali junto a ele; e ele proclamou o nome do Senhor.',
        'Passando, pois, o Senhor perante ele, clamou: O Senhor, o Senhor Deus, misericordioso e piedoso, tardio em irar-se e grande em beneficência e verdade;',
        'Que guarda a beneficência em milhares; que perdoa a iniqüidade, e a transgressão e o pecado; que ao culpado não tem por inocente; que visita a iniqüidade dos pais sobre os filhos e sobre os filhos dos filhos até a terceira e quarta geração.',
        'E Moisés apressou-se, e inclinou a cabeça à terra, adorou,',
        'E disse: Senhor, se agora tenho achado graça aos teus olhos, vá agora o Senhor no meio de nós; porque este é povo de dura cerviz; porém perdoa a nossa iniqüidade e o nosso pecado, e toma-nos por tua herança.',
        'Então disse: Eis que eu faço uma aliança; farei diante de todo o teu povo maravilhas que nunca foram feitas em toda a terra, nem em nação alguma; de maneira que todo este povo, em cujo meio tu estás, veja a obra do Senhor; porque coisa terrível é o que faço contigo.',
        'Guarda o que eu te ordeno hoje; eis que eu lançarei fora diante de ti os amorreus, e os cananeus, e os heteus, e os perizeus, e os heveus e os jebuseus.',
        'Guarda-te de fazeres aliança com os moradores da terra aonde hás de entrar; para que não seja por laço no meio de ti.',
        'Mas os seus altares derrubareis, e as suas estátuas quebrareis, e os seus bosques cortareis.',
        'Porque não te inclinarás diante de outro deus; pois o nome do Senhor é Zeloso; é um Deus zeloso.',
        'Para que não faças aliança com os moradores da terra, e quando eles se prostituírem após os seus deuses, ou sacrificarem aos seus deuses, tu, como convidado deles, comas também dos seus sacrifícios,',
        'E tomes mulheres das suas filhas para os teus filhos, e suas filhas, prostituindo-se com os seus deuses, façam que também teus filhos se prostituam com os seus deuses.',
        'Não te farás deuses de fundição.',
        'A festa dos pães ázimos guardarás; sete dias comerás pães ázimos, como te tenho ordenado, ao tempo apontado do mês de Abibe; porque no mês de Abibe saíste do Egito.',
        'Tudo o que abre a madre meu é, até todo o teu gado, que seja macho, e que abre a madre de vacas e de ovelhas;',
        'O burro, porém, que abrir a madre, resgatarás com um cordeiro; mas, se o não resgatares, cortar-lhe-ás a cabeça; todo o primogênito de teus filhos resgatarás. E ninguém aparecerá vazio diante de mim.',
        'Seis dias trabalharás, mas ao sétimo dia descansarás: na aradura e na sega descansarás.',
        'Também guardarás a festa das semanas, que é a festa das primícias da sega do trigo, e a festa da colheita no fim do ano.',
        'Três vezes ao ano todos os homens aparecerão perante o Senhor DEUS, o Deus de Israel;',
        'Porque eu lançarei fora as nações de diante de ti, e alargarei o teu território; ninguém cobiçará a tua terra, quando subires para aparecer três vezes no ano diante do Senhor teu Deus.',
        'Não sacrificarás o sangue do meu sacrifício com pão levedado, nem o sacrifício da festa da páscoa ficará da noite para a manhã.',
        'As primícias dos primeiros frutos da tua terra trarás à casa do Senhor teu Deus; não cozerás o cabrito no leite de sua mãe.',
        'Disse mais o Senhor a Moisés: Escreve estas palavras; porque conforme ao teor destas palavras tenho feito aliança contigo e com Israel.',
        'E esteve ali com o Senhor quarenta dias e quarenta noites; não comeu pão, nem bebeu água, e escreveu nas tábuas as palavras da aliança, os dez mandamentos.',
        'E aconteceu que, descendo Moisés do monte Sinai trazia as duas tábuas do testemunho em suas mãos, sim, quando desceu do monte, Moisés não sabia que a pele do seu rosto resplandecia, depois que falara com ele.',
        'Olhando, pois, Arão e todos os filhos de Israel para Moisés, eis que a pele do seu rosto resplandecia; por isso temeram chegar-se a ele.',
        'Então Moisés os chamou, e Arão e todos os príncipes da congregação tornaram-se a ele; e Moisés lhes falou.',
        'Depois chegaram também todos os filhos de Israel; e ele lhes ordenou tudo o que o Senhor falara com ele no monte Sinai.',
        'Assim que Moisés acabou de falar com eles, pôs um véu sobre o seu rosto.',
        'Porém, entrando Moisés perante o Senhor, para falar com ele, tirava o véu até sair; e, saindo, falava com os filhos de Israel o que lhe era ordenado.',
        'Assim, pois, viam os filhos de Israel o rosto de Moisés, e que resplandecia a pele do seu rosto; e tornava Moisés a pôr o véu sobre o seu rosto, até entrar para falar com ele.',
      ],
      [
        'Então Moisés convocou toda a congregação dos filhos de Israel, e disse-lhes: Estas são as palavras que o SENHOR ordenou que se cumprissem.',
        'Seis dias se trabalhará, mas o sétimo dia vos será santo, o sábado do repouso ao Senhor; todo aquele que nele fizer qualquer trabalho morrerá.',
        'Não acendereis fogo em nenhuma das vossas moradas no dia do sábado.',
        'Falou mais Moisés a toda a congregação dos filhos de Israel, dizendo: Esta é a palavra que o Senhor ordenou, dizendo:',
        'Tomai do que tendes, uma oferta para o Senhor; cada um, cujo coração é voluntariamente disposto, a trará por oferta alçada ao Senhor: ouro, prata e cobre,',
        'Como também azul, púrpura, carmesim, linho fino, pêlos de cabras,',
        'E peles de carneiros, tintas de vermelho, e peles de texugos, madeira de acácia,',
        'E azeite para a luminária, e especiarias para o azeite da unção, e para o incenso aromático.',
        'E pedras de ônix, e pedras de engaste, para o éfode e para o peitoral.',
        'E venham todos os sábios de coração entre vós, e façam tudo o que o Senhor tem mandado;',
        'O tabernáculo, a sua tenda e a sua coberta, os seus colchetes e as suas tábuas, as suas barras, as suas colunas, e as suas bases;',
        'A arca e os seus varais, o propiciatório e o véu de cobertura,',
        'A mesa e os seus varais, e todos os seus pertences; e os pães da proposição,',
        'E o candelabro da luminária, e os seus utensílios, e as suas lâmpadas, e o azeite para a luminária,',
        'E o altar do incenso e os seus varais, e o azeite da unção, e o incenso aromático, e a cortina da porta para a entrada do tabernáculo,',
        'O altar do holocausto, e o crivo de cobre, os seus varais, e todos os seus pertences, a pia e a sua base,',
        'As cortinas do pátio, as suas colunas e as suas bases, e o reposteiro da porta do pátio,',
        'As estacas do tabernáculo, e as estacas do pátio, e as suas cordas,',
        'As vestes do ministério para ministrar no santuário, as vestes santas de Arão o sacerdote, e as vestes de seus filhos, para administrarem o sacerdócio.',
        'Então toda a congregação dos filhos de Israel saiu da presença de Moisés,',
        'E veio todo o homem, a quem o seu coração moveu, e todo aquele cujo espírito voluntariamente o excitou, e trouxeram a oferta alçada ao Senhor para a obra da tenda da congregação, e para todo o seu serviço, e para as vestes santas.',
        'Assim vieram homens e mulheres, todos dispostos de coração; trouxeram fivelas, e pendentes, e anéis, e braceletes, todos os objetos de ouro; e todo o homem fazia oferta de ouro ao Senhor;',
        'E todo o homem que se achou com azul, e púrpura, e carmesim, e linho fino, e pêlos de cabras, e peles de carneiro tintas de vermelho, e peles de texugos, os trazia;',
        'Todo aquele que fazia oferta alçada de prata ou de metal, a trazia por oferta alçada ao Senhor; e todo aquele que possuía madeira de acácia, a trazia para toda a obra do serviço.',
        'E todas as mulheres sábias de coração fiavam com as suas mãos, e traziam o que tinham fiado, o azul e a púrpura, o carmesim e o linho fino.',
        'E todas as mulheres, cujo coração as moveu em habilidade fiavam os pêlos das cabras.',
        'E os príncipes traziam pedras de ônix e pedras de engastes para o éfode e para o peitoral,',
        'E especiarias, e azeite para a luminária, e para o azeite da unção, e para o incenso aromático.',
        'Todo homem e mulher, cujo coração voluntariamente se moveu a trazer alguma coisa para toda a obra que o Senhor ordenara se fizesse pela mão de Moisés; assim os filhos de Israel trouxeram por oferta voluntária ao Senhor.',
        'Depois disse Moisés aos filhos de Israel: Eis que o Senhor tem chamado por nome a Bezalel, filho de Uri, filho de Hur, da tribo de Judá.',
        'E o Espírito de Deus o encheu de sabedoria, entendimento, ciência e em todo o lavor,',
        'E para criar invenções, para trabalhar em ouro, e em prata, e em cobre,',
        'E em lapidar de pedras para engastar, e em entalhar madeira, e para trabalhar em toda a obra esmerada.',
        'Também lhe dispôs o coração para ensinar a outros; a ele e a Aoliabe, o filho de Aisamaque, da tribo de Dã.',
        'Encheu-os de sabedoria do coração, para fazer toda a obra de mestre, até a mais engenhosa, e a do gravador, em azul, e em púrpura, em carmesim, e em linho fino, e do tecelão; fazendo toda a obra, e criando invenções.',
      ],
      [
        'Assim trabalharam Bezalel e Aoliabe, e todo o homem sábio de coração, a quem o SENHOR dera sabedoria e inteligência, para saber como haviam de fazer toda a obra para o serviço do santuário, conforme a tudo o que o SENHOR tinha ordenado.',
        'Então Moisés chamou a Bezalel e a Aoliabe, e a todo o homem sábio de coração, em cujo coração o Senhor tinha dado sabedoria; a todo aquele a quem o seu coração moveu a se chegar à obra para fazê-la.',
        'Estes receberam de Moisés toda a oferta alçada, que trouxeram os filhos de Israel para a obra do serviço do santuário, para fazê-la, e ainda eles lhe traziam cada manhã ofertas voluntárias.',
        'E vieram todos os sábios, que faziam toda a obra do santuário, cada um da obra que fazia,',
        'E falaram a Moisés, dizendo: O povo traz muito mais do que basta para o serviço da obra que o Senhor ordenou se fizesse.',
        'Então mandou Moisés que proclamassem por todo o arraial, dizendo: Nenhum homem, nem mulher, faça mais obra alguma para a oferta alçada do santuário. Assim o povo foi proibido de trazer mais,',
        'Porque tinham material bastante para toda a obra que havia de fazer-se, e ainda sobejava.',
        'Assim todo o sábio de coração, entre os que faziam a obra, fez o tabernáculo de dez cortinas de linho fino torcido, e de azul, e de púrpura, e de carmesim, com querubins; da obra mais esmerada as fez.',
        'O comprimento de cada cortina era de vinte e oito côvados, e a largura de quatro côvados; todas as cortinas tinham uma mesma medida.',
        'E ligou cinco cortinas uma com a outra; e outras cinco cortinas também ligou uma com outra.',
        'Depois fez laçadas de azul na borda de uma cortina, à extremidade, na juntura; assim também fez na borda, à extremidade da juntura da segunda cortina.',
        'Cinqüenta laçadas fez numa cortina, e cinqüenta laçadas fez numa extremidade da cortina, que se ligava com a segunda; estas laçadas eram contrapostas uma a outra.',
        'Também fez cinqüenta colchetes de ouro, e com estes colchetes uniu as cortinas uma com a outra; e assim foi feito um tabernáculo.',
        'Fez também cortinas de pêlos de cabras para a tenda sobre o tabernáculo; fez onze cortinas.',
        'O comprimento de uma cortina era de trinta côvados, e a largura de quatro côvados; estas onze cortinas tinham uma mesma medida.',
        'E uniu cinco cortinas à parte, e outras seis à parte,',
        'E fez cinqüenta laçadas na borda da última cortina, na juntura; também fez cinqüenta laçadas na borda da cortina, na outra juntura.',
        'Fez também cinqüenta colchetes de metal, para ajuntar a tenda, para que fosse um todo.',
        'Fez também, para a tenda, uma coberta de peles de carneiros, tintas de vermelho; e por cima uma coberta de peles de texugos.',
        'Também fez, de madeira de acácia, tábuas levantadas para o tabernáculo, que foram colocadas verticalmente.',
        'O comprimento de cada tábua era de dez côvados, e a largura de um côvado e meio.',
        'Cada tábua tinha duas cavilhas pregadas uma a outra; assim fez com todas as tábuas do tabernáculo.',
        'Assim, pois, fez as tábuas para o tabernáculo; vinte tábuas para o lado que dá para o sul;',
        'E fez quarenta bases de prata debaixo das vinte tábuas; duas bases debaixo de uma tábua, para as suas duas cavilhas, e duas debaixo de outra, para as suas duas cavilhas.',
        'Também fez vinte tábuas ao outro lado do tabernáculo, do lado norte,',
        'Com as suas quarenta bases de prata; duas bases debaixo de uma tábua, e duas bases debaixo de outra tábua.',
        'E ao lado do tabernáculo para o ocidente fez seis tábuas.',
        'Fez também duas tábuas para os cantos do tabernáculo nos dois lados,',
        'As quais por baixo estavam juntas, e também se ajuntavam por cima com uma argola; assim fez com ambas nos dois cantos.',
        'Assim eram oito tábuas com as suas bases de prata, a saber, dezesseis bases; duas bases debaixo de cada tábua.',
        'Fez também travessas de madeira de acácia; cinco para as tábuas de um lado do tabernáculo,',
        'E cinco travessas para as tábuas do outro lado do tabernáculo; e outras cinco travessas para as tábuas do tabernáculo do lado ocidental.',
        'E fez que a travessa do meio passasse pelo meio das tábuas de uma extremidade até a outra.',
        'E cobriu as tábuas de ouro, e as suas argolas (os lugares das travessas) fez de ouro; as travessas também cobriu de ouro.',
        'Depois fez o véu de azul, e de púrpura, e de carmesim, e de linho fino torcido; de obra esmerada o fez com querubins.',
        'E fez-lhe quatro colunas de madeira de acácia, e as cobriu de ouro; e seus colchetes fez de ouro, e fundiu-lhe quatro bases de prata.',
        'Fez também para a porta da tenda o véu de azul, e de púrpura, e de carmesim, e de linho fino torcido, da obra do bordador,',
        'Com as suas cinco colunas e os seus colchetes; e as suas cabeças e as suas molduras cobriu de ouro; e as suas cinco bases eram de cobre.',
      ],
      [
        'Fez também Bezalel a arca de madeira de acácia; o seu comprimento era de dois côvados e meio; e a sua largura de um côvado e meio; e a sua altura de um côvado e meio.',
        'E cobriu-a de ouro puro por dentro e por fora; e fez-lhe uma coroa de ouro ao redor;',
        'E fundiu-lhe quatro argolas de ouro nos seus quatro cantos; num lado duas, e no outro lado duas argolas;',
        'E fez varais de madeira de acácia, e os cobriu de ouro;',
        'E pôs os varais pelas argolas aos lados da arca, para se levar a arca.',
        'Fez também o propiciatório de ouro puro; o seu comprimento era de dois côvados e meio, e a sua largura de um côvado e meio.',
        'Fez também dois querubins de ouro; de obra batida os fez, nas duas extremidades do propiciatório.',
        'Um querubim na extremidade de um lado, e o outro na outra extremidade do outro lado; de uma só peça com o propiciatório fez os querubins nas duas extremidades dele.',
        'E os querubins estendiam as asas por cima, cobrindo com elas o propiciatório; e os seus rostos estavam defronte um do outro; os rostos dos querubins estavam virados para o propiciatório.',
        'Fez também a mesa de madeira de acácia; o seu comprimento era de dois côvados, e a sua largura de um côvado, e a sua altura de um côvado e meio.',
        'E cobriu-a de ouro puro, e fez-lhe uma coroa de ouro ao redor.',
        'Fez-lhe também, ao redor, uma moldura da largura da mão; e fez uma coroa de ouro ao redor da moldura.',
        'Fundiu-lhe também quatro argolas de ouro; e pôs as argolas nos quatro cantos que estavam em seus quatro pés.',
        'Defronte da moldura estavam as argolas para os lugares dos varais, para se levar a mesa.',
        'Fez também os varais de madeira de acácia, e os cobriu de ouro, para se levar a mesa.',
        'E fez de ouro puro os utensílios que haviam de estar sobre a mesa, os seus pratos e as suas colheres, e as suas tigelas e as suas taças em que se haviam de oferecer libações.',
        'Fez também o candelabro de ouro puro; de obra batida fez este candelabro; o seu pedestal, e as suas hastes, os seus copos, as suas maçãs, e as suas flores, formavam com ele uma só peça.',
        'Seis hastes saíam dos seus lados; três hastes do candelabro, de um lado dele, e três do outro lado.',
        'Numa haste estavam três copos do feitio de amêndoas, um botão e uma flor; e na outra haste três copos do feitio de amêndoas, um botão e uma flor; assim eram as seis hastes que saíam do candelabro.',
        'Mas no mesmo candelabro havia quatro copos do feitio de amêndoas com os seus botões e com as suas flores.',
        'E havia um botão debaixo de duas hastes da mesma peça; e outro botão debaixo de duas hastes da mesma peça; e mais um botão debaixo de duas hastes da mesma peça; assim se fez para as seis hastes, que saíam dele.',
        'Os seus botões e as suas hastes eram da mesma peça; tudo era uma obra batida de ouro puro.',
        'E fez-lhe, de ouro puro, sete lâmpadas com os seus espevitadores e os seus apagadores;',
        'De um talento de ouro puro fez o candelabro e todos os seus utensílios.',
        'E fez o altar do incenso de madeira de acácia; de um côvado era o seu comprimento, e de um côvado a sua largura, era quadrado; e de dois côvados a sua altura; dele mesmo eram feitas as suas pontas.',
        'E cobriu-o de ouro puro, a parte superior e as suas paredes ao redor, e as suas pontas; e fez-lhe uma coroa de ouro ao redor.',
        'Fez-lhe também duas argolas de ouro debaixo da sua coroa, e os seus dois cantos, de ambos os seus lados, para neles se colocar os varais, e com eles levá-lo.',
        'E os varais fez de madeira de acácia, e os cobriu de ouro.',
        'Também fez o azeite santo da unção, e o incenso aromático, puro, qual obra do perfumista.',
      ],
      [
        'Fez também o altar do holocausto de madeira de acácia; de cinco côvados era o seu comprimento, e de cinco côvados a sua largura, era quadrado; e de três côvados a sua altura.',
        'E fez-lhe as suas pontas nos seus quatro cantos; da mesma peça eram as suas pontas; e cobriu-o de cobre.',
        'Fez também todos os utensílios do altar; os cinzeiros, e as pás, e as bacias, e os garfos, e os braseiros; todos esses pertences fez de cobre.',
        'Fez também, para o altar, um crivo de cobre, em forma de rede, na sua cercadura em baixo, até ao meio do altar.',
        'E fundiu quatro argolas para as quatro extremidades do crivo de cobre, para os lugares dos varais.',
        'E fez os varais de madeira de acácia, e os cobriu de cobre.',
        'E pôs os varais pelas argolas aos lados do altar, para com eles levar o altar; fê-lo oco e de tábuas.',
        'Fez também a pia de cobre com a sua base de cobre, dos espelhos das mulheres que se reuniam, para servir à porta da tenda da congregação.',
        'Fez também o pátio do lado meridional; as cortinas do pátio eram de linho fino torcido, de cem côvados.',
        'As suas vinte colunas e as suas vinte bases eram de cobre; os colchetes destas colunas e as suas molduras eram de prata;',
        'E do lado norte cortinas de cem côvados; as suas vinte colunas e as suas vinte bases eram de cobre, os colchetes das colunas e as suas molduras eram de prata.',
        'E do lado do ocidente cortinas de cinqüenta côvados, as suas colunas dez, e as suas bases dez; os colchetes das colunas e as suas molduras eram de prata.',
        'E do lado leste, ao oriente, cortinas de cinqüenta côvados.',
        'As cortinas de um lado da porta eram de quinze côvados; as suas colunas três e as suas bases três.',
        'E do outro lado da porta do pátio, de ambos os lados, eram cortinas de quinze côvados; as suas colunas três e as suas bases três.',
        'Todas as cortinas do pátio ao redor eram de linho fino torcido.',
        'E as bases das colunas eram de cobre; os colchetes das colunas e as suas molduras eram de prata; e o revestimento dos seus capitéis era de prata; e todas as colunas do pátio eram cingidas de prata.',
        'E a cobertura da porta do pátio era de obra de bordador, de azul, e de púrpura, e de carmesim, e de linho fino torcido; e o comprimento era de vinte côvados, e a altura, na largura, de cinco côvados, conforme as cortinas do pátio.',
        'E as suas quatro colunas e as suas quatro bases eram de cobre, os seus colchetes de prata, e o revestimento dos seus capitéis, e as suas molduras, também de prata.',
        'E todas as estacas do tabernáculo e do pátio ao redor eram de cobre.',
        'Esta é a enumeração das coisas usadas no tabernáculo do testemunho, que por ordem de Moisés foram contadas para o ministério dos levitas, por intermédio de Itamar, filho de Arão, o sacerdote.',
        'Fez, pois, Bezalel, o filho de Uri, filho de Hur, da tribo de Judá, tudo quanto o Senhor tinha ordenado a Moisés.',
        'E com ele Aoliabe, filho de Aisamaque, da tribo de Dã, um mestre de obra, e engenhoso artífice, e bordador em azul, e em púrpura e em carmesim e em linho fino.',
        'Todo o ouro gasto na obra, em toda a obra do santuário, a saber, o ouro da oferta, foi vinte e nove talentos e setecentos e trinta siclos, conforme ao siclo do santuário;',
        'E a prata dos arrolados da congregação foi cem talentos e mil e setecentos e setenta e cinco siclos, conforme o siclo do santuário;',
        'Um beca por cabeça, isto é, meio siclo, conforme o siclo do santuário; de todo aquele que passava aos arrolados, da idade de vinte anos para cima, que foram seiscentos e três mil e quinhentos e cinqüenta.',
        'E houve cem talentos de prata para fundir as bases do santuário e as bases do véu; para as cem bases cem talentos; um talento para cada base.',
        'E dos mil e setecentos e setenta e cinco siclos fez os colchetes das colunas, e cobriu os seus capitéis, e os cingiu de molduras.',
        'E o cobre da oferta foi setenta talentos e dois mil e quatrocentos siclos.',
        'E dele fez as bases da porta da tenda da congregação e o altar de cobre, e o crivo de cobre e todos os utensílios do altar.',
        'E as bases do pátio ao redor, e as bases da porta do pátio, e todas as estacas do tabernáculo e todas as estacas do pátio ao redor.',
      ],
      [
        'Fizeram também as vestes do ministério, para ministrar no santuário, de azul, e de púrpura e de carmesim; também fizeram as vestes santas, para Arão, como o SENHOR ordenara a Moisés.',
        'Assim se fez o éfode de ouro, de azul, e de púrpura, e de carmesim e de linho fino torcido.',
        'E estenderam as lâminas de ouro, e as cortaram em fios, para tecê-los entre o azul, e entre a púrpura, e entre o carmesim, e entre o linho fino com trabalho esmerado.',
        'Fizeram-lhe ombreiras que se ajuntavam; e uniam-se em suas duas pontas.',
        'E o cinto de obra esmerada do éfode, que estava sobre ele, formava com ele uma só peça e era de obra semelhante, de ouro, de azul, e de púrpura, e de carmesim, e de linho fino torcido, como o Senhor ordenara a Moisés.',
        'Também prepararam as pedras de ônix, engastadas em ouro, lavradas com gravuras de um selo, com os nomes dos filhos de Israel.',
        'E as pôs sobre as ombreiras do éfode por pedras de memória para os filhos de Israel, como o Senhor ordenara a Moisés.',
        'Fez-se também o peitoral de obra de artífice, como a obra do éfode, de ouro, de azul, e de púrpura, e de carmesim, e de linho fino torcido.',
        'Quadrado era; duplo fizeram o peitoral; o seu comprimento era de um palmo, e a sua largura de um palmo dobrado.',
        'E engastaram nele quatro ordens de pedras; uma ordem de um sárdio, de um topázio, e de um carbúnculo; esta era a primeira ordem;',
        'E a segunda ordem de uma esmeralda, de uma safira e de um diamante;',
        'E a terceira ordem de um jacinto, de uma ágata, e de uma ametista;',
        'E a quarta ordem de um berilo, e de um ônix, e de um jaspe, engastadas em engastes de ouro.',
        'Estas pedras, pois, eram segundo os nomes dos filhos de Israel, doze segundo os seus nomes; como gravuras de selo, cada uma com o seu nome, segundo as doze tribos.',
        'Também fizeram para o peitoral cadeiazinhas de igual medida, obra de ouro puro trançado.',
        'E fizeram dois engastes de ouro e duas argolas de ouro; e puseram as duas argolas nas duas extremidades do peitoral.',
        'E puseram as duas cadeiazinhas de trança de ouro nas duas argolas, nas duas extremidades do peitoral.',
        'E as outras duas pontas das duas cadeiazinhas de trança puseram nos dois engastes; e as puseram sobre as ombreiras do éfode na frente dele.',
        'Fizeram também duas argolas de ouro, que puseram nas duas extremidades do peitoral, na sua borda que estava junto ao éfode por dentro.',
        'Fizeram mais duas argolas de ouro, que puseram nas duas ombreiras do éfode, abaixo, na frente dele, perto da sua juntura, sobre o cinto de obra esmerada do éfode.',
        'E ligaram o peitoral com as suas argolas às argolas do éfode com um cordão de azul, para que estivesse sobre o cinto de obra esmerada do éfode, e o peitoral não se separasse do éfode, como o Senhor ordenara a Moisés.',
        'E fez-se o manto do éfode de obra tecida, todo de azul.',
        'E a abertura do manto estava no meio dele, como abertura de cota de malha; esta abertura tinha uma borda em volta, para que se não rompesse.',
        'E nas bordas do manto fizeram romãs de azul, e de púrpura, e de carmesim, de fio torcido.',
        'Fizeram também as campainhas de ouro puro, pondo as campainhas no meio das romãs nas bordas do manto, ao redor, entre as romãs;',
        'Uma campainha e uma romã, outra campainha e outra romã, nas bordas do manto ao redor; para ministrar, como o Senhor ordenara a Moisés.',
        'Fizeram também as túnicas de linho fino, de obra tecida, para Arão e para seus filhos.',
        'E a mitra de linho fino, e o ornato das tiaras de linho fino, e os calções de linho fino torcido,',
        'E o cinto de linho fino torcido, e de azul, e de púrpura, e de carmesim, obra de bordador, como o Senhor ordenara a Moisés.',
        'Fizeram também, de ouro puro, a lâmina da coroa de santidade, e nela escreveram o escrito como de gravura de selo: SANTIDADE AO SENHOR.',
        'E ataram-na com um cordão de azul, para prendê-la à parte superior da mitra, como o Senhor ordenara a Moisés.',
        'Assim se acabou toda a obra do tabernáculo da tenda da congregação; e os filhos de Israel fizeram conforme a tudo o que o Senhor ordenara a Moisés; assim o fizeram.',
        'Depois trouxeram a Moisés o tabernáculo, a tenda e todos os seus pertences; os seus colchetes, as suas tábuas, os seus varais, e as suas colunas, e as suas bases;',
        'E a cobertura de peles de carneiro tintas de vermelho, e a cobertura de peles de texugos, e o véu de cobertura;',
        'A arca do testemunho, e os seus varais, e o propiciatório;',
        'A mesa com todos os seus pertences, e os pães da proposição;',
        'O candelabro puro com suas lâmpadas, as lâmpadas em ordem, e todos os seus pertences, e o azeite para a luminária;',
        'Também o altar de ouro, e o azeite da unção, e o incenso aromático, e a cortina da porta da tenda;',
        'O altar de cobre, e o seu crivo de cobre, os seus varais, e todos os seus pertences, a pia, e a sua base;',
        'As cortinas do pátio, as suas colunas, e as suas bases, e a cortina da porta do pátio, as suas cordas, e os seus pregos, e todos os utensílios do serviço do tabernáculo, para a tenda da congregação;',
        'As vestes do ministério para ministrar no santuário; as santas vestes de Arão o sacerdote, e as vestes dos seus filhos, para administrarem o sacerdócio.',
        'Conforme a tudo o que o Senhor ordenara a Moisés, assim fizeram os filhos de Israel toda a obra.',
        'Viu, pois, Moisés toda a obra, e eis que a tinham feito; como o Senhor ordenara, assim a fizeram; então Moisés os abençoou.',
      ],
      [
        'Falou mais o SENHOR a Moisés, dizendo:',
        'No primeiro mês, no primeiro dia do mês, levantarás o tabernáculo da tenda da congregação,',
        'E porás nele a arca do testemunho, e cobrirás a arca com o véu.',
        'Depois colocarás nele a mesa, e porás em ordem o que se deve pôr em ordem nela; também colocarás nele o candelabro, e acenderás as suas lâmpadas.',
        'E porás o altar de ouro para o incenso diante da arca do testemunho; então pendurarás a cortina da porta do tabernáculo.',
        'Porás também o altar do holocausto diante da porta do tabernáculo da tenda da congregação.',
        'E porás a pia entre a tenda da congregação e o altar, e nela porás água.',
        'Depois porás o pátio ao redor, e pendurarás a cortina à porta do pátio.',
        'Então tomarás o azeite da unção, e ungirás o tabernáculo, e tudo o que há nele; e o santificarás com todos os seus pertences, e será santo.',
        'Ungirás também o altar do holocausto, e todos os seus utensílios; e santificarás o altar; e o altar será santíssimo.',
        'Então ungirás a pia e a sua base, e a santificarás.',
        'Farás também chegar a Arão e a seus filhos à porta da tenda da congregação; e os lavarás com água.',
        'E vestirás a Arão as vestes santas, e o ungirás, e o santificarás, para que me administre o sacerdócio.',
        'Também farás chegar a seus filhos, e lhes vestirás as túnicas,',
        'E os ungirás como ungiste a seu pai, para que me administrem o sacerdócio, e a sua unção lhes será por sacerdócio perpétuo nas suas gerações.',
        'E Moisés fez conforme a tudo o que o Senhor lhe ordenou, assim o fez.',
        'Assim, no primeiro mês, no ano segundo, ao primeiro dia do mês foi levantado o tabernáculo.',
        'Moisés levantou o tabernáculo, e pôs as suas bases, e armou as suas tábuas, e colocou nele os seus varais, e levantou as suas colunas;',
        'E estendeu a tenda sobre o tabernáculo, e pôs a cobertura da tenda sobre ela, em cima, como o Senhor ordenara a Moisés.',
        'Tomou o testemunho, e pô-lo na arca, e colocou os varais na arca; e pôs o propiciatório em cima da arca.',
        'E introduziu a arca no tabernáculo, e pendurou o véu da cobertura, e cobriu a arca do testemunho, como o Senhor ordenara a Moisés.',
        'pôs também a mesa na tenda da congregação, ao lado do tabernáculo, para o norte, fora do véu,',
        'E sobre ela pôs em ordem o pão perante o Senhor, como o Senhor ordenara a Moisés.',
        'pôs também na tenda da congregação o candelabro na frente da mesa, ao lado do tabernáculo, para o sul,',
        'E acendeu as lâmpadas perante o Senhor, como o Senhor ordenara a Moisés.',
        'E pôs o altar de ouro na tenda da congregação, diante do véu,',
        'E acendeu sobre ele o incenso de especiarias aromáticas, como o Senhor ordenara a Moisés.',
        'Pendurou também a cortina da porta do tabernáculo,',
        'E pôs o altar do holocausto à porta do tabernáculo da tenda da congregação, e sobre ele ofereceu holocausto e oferta de alimentos, como o Senhor ordenara a Moisés.',
        'Pôs também a pia entre a tenda da congregação e o altar, e nela pôs água para lavar.',
        'E Moisés, e Arão e seus filhos nela lavaram as suas mãos e os seus pés.',
        'Quando entravam na tenda da congregação, e quando chegavam ao altar, lavavam-se, como o Senhor ordenara a Moisés.',
        'Levantou também o pátio ao redor do tabernáculo e do altar, e pendurou a cortina da porta do pátio. Assim Moisés acabou a obra.',
        'Então a nuvem cobriu a tenda da congregação, e a glória do Senhor encheu o tabernáculo;',
        'De maneira que Moisés não podia entrar na tenda da congregação, porquanto a nuvem permanecia sobre ela, e a glória do Senhor enchia o tabernáculo.',
        'Quando, pois, a nuvem se levantava de sobre o tabernáculo, então os filhos de Israel caminhavam em todas as suas jornadas.',
        'Se a nuvem, porém, não se levantava, não caminhavam, até ao dia em que ela se levantasse;',
        'Porquanto a nuvem do Senhor estava de dia sobre o tabernáculo, e o fogo estava de noite sobre ele, perante os olhos de toda a casa de Israel, em todas as suas jornadas.',
      ],
    ],
    livro: 'exodo',
  },
  {
    abbrev: 'lv',
    capitulos: [
      [
        'E chamou o SENHOR a Moisés, e falou com ele da tenda da congregação, dizendo:',
        'Fala aos filhos de Israel, e dize-lhes: Quando algum de vós oferecer oferta ao Senhor, oferecerá a sua oferta de gado, isto é, de gado vacum e de ovelha.',
        'Se a sua oferta for holocausto de gado, oferecerá macho sem defeito; à porta da tenda da congregação a oferecerá, de sua própria vontade, perante o Senhor.',
        'E porá a sua mão sobre a cabeça do holocausto, para que seja aceito a favor dele, para a sua expiação.',
        'Depois degolará o bezerro perante o Senhor; e os filhos de Arão, os sacerdotes, oferecerão o sangue, e espargirão o sangue em redor sobre o altar que está diante da porta da tenda da congregação.',
        'Então esfolará o holocausto, e o partirá nos seus pedaços.',
        'E os filhos de Arão, o sacerdote, porão fogo sobre o altar, pondo em ordem a lenha sobre o fogo.',
        'Também os filhos de Arão, os sacerdotes, porão em ordem os pedaços, a cabeça e o redenho sobre a lenha que está no fogo em cima do altar;',
        'Porém a sua fressura e as suas pernas lavar-se-ão com água; e o sacerdote tudo isso queimará sobre o altar; holocausto é, oferta queimada, de cheiro suave ao Senhor.',
        'E se a sua oferta for de gado miúdo, de ovelhas ou de cabras, para holocausto, oferecerá macho sem defeito.',
        'E o degolará ao lado do altar que dá para o norte, perante o Senhor; e os filhos de Arão, os sacerdotes, espargirão o seu sangue em redor sobre o altar.',
        'Depois o partirá nos seus pedaços, como também a sua cabeça e o seu redenho; e o sacerdote os porá em ordem sobre a lenha que está no fogo sobre o altar;',
        'Porém a fressura e as pernas lavar-se-ão com água; e o sacerdote tudo oferecerá, e o queimará sobre o altar; holocausto é, oferta queimada, de cheiro suave ao Senhor.',
        'E se a sua oferta ao Senhor for holocausto de aves, oferecerá a sua oferta de rolas ou de pombinhos;',
        'E o sacerdote a oferecerá sobre o altar, e tirar-lhe-á a cabeça, e a queimará sobre o altar; e o seu sangue será espremido na parede do altar;',
        'E o seu papo com as suas penas tirará e o lançará junto ao altar, para o lado do oriente, no lugar da cinza;',
        'E fendê-la-á junto às suas asas, porém não a partirá; e o sacerdote a queimará em cima do altar sobre a lenha que está no fogo; holocausto é, oferta queimada de cheiro suave ao Senhor.',
      ],
      [
        'E quando alguma pessoa oferecer oferta de alimentos ao SENHOR, a sua oferta será de flor de farinha, e nela deitará azeite, e porá o incenso sobre ela;',
        'E a trará aos filhos de Arão, os sacerdotes, um dos quais tomará dela um punhado da flor de farinha, e do seu azeite com todo o seu incenso; e o sacerdote queimará como memorial sobre o altar; oferta queimada é, de cheiro suave ao Senhor.',
        'E o que sobejar da oferta de alimentos, será de Arão e de seus filhos; coisa santíssima é, das ofertas queimadas ao Senhor.',
        'E, quando ofereceres oferta de alimentos, cozida no forno, será de bolos ázimos de flor de farinha, amassados com azeite, e coscorões ázimos untados com azeite.',
        'E, se a tua oferta for oferta de alimentos cozida na caçoula, será da flor de farinha sem fermento, amassada com azeite.',
        'Em pedaços a partirás, e sobre ela deitarás azeite; oferta é de alimentos.',
        'E, se a tua oferta for oferta de alimentos de frigideira, far-se-á da flor de farinha com azeite.',
        'Então trarás a oferta de alimentos, que se fará daquilo, ao Senhor; e se apresentará ao sacerdote, o qual a levará ao altar.',
        'E o sacerdote tomará daquela oferta de alimentos como memorial, e a queimará sobre o altar; oferta queimada é de cheiro suave ao Senhor.',
        'E, o que sobejar da oferta de alimentos, será de Arão e de seus filhos; coisa santíssima é, das ofertas queimadas ao Senhor.',
        'Nenhuma oferta de alimentos, que oferecerdes ao Senhor, se fará com fermento; porque de nenhum fermento, nem de mel algum, oferecereis oferta queimada ao Senhor.',
        'Deles oferecereis ao Senhor por oferta das primícias; porém sobre o altar não subirão por cheiro suave.',
        'E todas as tuas ofertas dos teus alimentos temperarás com sal; e não deixarás faltar à tua oferta de alimentos o sal da aliança do teu Deus; em todas as tuas ofertas oferecerás sal.',
        'E, se fizeres ao Senhor oferta de alimentos das primícias, oferecerás como oferta de alimentos das tuas primícias de espigas verdes, tostadas ao fogo; isto é, do grão trilhado de espigas verdes cheias.',
        'E sobre ela deitarás azeite, e porás sobre ela incenso; oferta é de alimentos.',
        'Assim o sacerdote queimará o seu memorial do seu grão trilhado, e do seu azeite, com todo o seu incenso; oferta queimada é ao Senhor.',
      ],
      [
        'E se a sua oferta for sacrifício pacífico; se a oferecer de gado, macho ou fêmea, a oferecerá sem defeito diante do SENHOR.',
        'E porá a sua mão sobre a cabeça da sua oferta, e a degolará diante da porta da tenda da congregação; e os filhos de Arão, os sacerdotes, espargirão o sangue sobre o altar em redor.',
        'Depois oferecerá, do sacrifício pacífico, a oferta queimada ao Senhor; a gordura que cobre a fressura, e toda a gordura que está sobre a fressura,',
        'E ambos os rins, e a gordura que está sobre eles, e junto aos lombos, e o redenho que está sobre o fígado com os rins, tirará.',
        'E os filhos de Arão queimarão isso sobre o altar, em cima do holocausto, que estará sobre a lenha que está no fogo; oferta queimada é, de cheiro suave ao Senhor.',
        'E se a sua oferta for de gado miúdo por sacrifício pacífico ao Senhor, seja macho ou fêmea, sem defeito o oferecerá.',
        'Se oferecer um cordeiro por sua oferta, oferecê-lo-á perante o Senhor;',
        'E porá a sua mão sobre a cabeça da sua oferta, e a degolará diante da tenda da congregação; e os filhos de Arão espargirão o seu sangue sobre o altar em redor.',
        'Então, do sacrifício pacífico, oferecerá ao Senhor, por oferta queimada, a sua gordura, a cauda toda, a qual tirará do espinhaço, e a gordura que cobre a fressura, e toda a gordura que está sobre a fressura;',
        'Como também ambos os rins, e a gordura que está sobre eles, e junto aos lombos, e o redenho que está sobre o fígado com os rins, tirá-los-á.',
        'E o sacerdote queimará isso sobre o altar; alimento é da oferta queimada ao Senhor.',
        'Mas, se a sua oferta for uma cabra, perante o Senhor a oferecerá,',
        'E porá a sua mão sobre a sua cabeça, e a degolará diante da tenda da congregação; e os filhos de Arão espargirão o seu sangue sobre o altar em redor.',
        'Depois oferecerá dela a sua oferta por oferta queimada ao Senhor, a gordura que cobre a fressura, e toda a gordura que está sobre a fressura;',
        'Como também ambos os rins, e a gordura que está sobre eles, e junto aos lombos, e o redenho que está sobre o fígado com os rins, tirá-los-á.',
        'E o sacerdote o queimará sobre o altar; alimento é da oferta queimada de cheiro suave. Toda a gordura será do Senhor.',
        'Estatuto perpétuo é pelas vossas gerações, em todas as vossas habitações: nenhuma gordura nem sangue algum comereis.',
      ],
      [
        'Falou mais o SENHOR a Moisés, dizendo:',
        'Fala aos filhos de Israel, dizendo: Quando uma alma pecar, por ignorância, contra alguns dos mandamentos do Senhor, acerca do que não se deve fazer, e proceder contra algum deles;',
        'Se o sacerdote ungido pecar para escândalo do povo, oferecerá ao Senhor, pelo seu pecado, que cometeu, um novilho sem defeito, por expiação do pecado.',
        'E trará o novilho à porta da tenda da congregação, perante o Senhor, e porá a sua mão sobre a cabeça do novilho, e degolará o novilho perante o Senhor.',
        'Então o sacerdote ungido tomará do sangue do novilho, e o trará à tenda da congregação;',
        'E o sacerdote molhará o seu dedo no sangue, e daquele sangue espargirá sete vezes perante o Senhor diante do véu do santuário.',
        'Também o sacerdote porá daquele sangue sobre as pontas do altar do incenso aromático, perante o Senhor que está na tenda da congregação; e todo o restante do sangue do novilho derramará à base do altar do holocausto, que está à porta da tenda da congregação.',
        'E tirará toda a gordura do novilho da expiação; a gordura que cobre a fressura, e toda a gordura que está sobre a fressura,',
        'E os dois rins, e a gordura que está sobre eles, que está junto aos lombos, e o redenho de sobre o fígado, com os rins, tirá-los-á,',
        'Como se tira do boi do sacrifício pacífico; e o sacerdote os queimará sobre o altar do holocausto.',
        'Mas o couro do novilho, e toda a sua carne, com a sua cabeça e as suas pernas, e as suas entranhas, e o seu esterco,',
        'Enfim, o novilho todo levará fora do arraial a um lugar limpo, onde se lança a cinza, e o queimará com fogo sobre a lenha; onde se lança a cinza se queimará.',
        'Mas, se toda a congregação de Israel pecar por ignorância, e o erro for oculto aos olhos do povo, e se fizerem contra alguns dos mandamentos do Senhor, aquilo que não se deve fazer, e forem culpados,',
        'E quando o pecado que cometeram for conhecido, então a congregação oferecerá um novilho, por expiação do pecado, e o trará diante da tenda da congregação,',
        'E os anciãos da congregação porão as suas mãos sobre a cabeça do novilho perante o Senhor; e degolar-se-á o novilho perante o Senhor.',
        'Então o sacerdote ungido trará do sangue do novilho à tenda da congregação,',
        'E o sacerdote molhará o seu dedo naquele sangue, e o espargirá sete vezes perante o Senhor, diante do véu.',
        'E daquele sangue porá sobre as pontas do altar, que está perante a face do Senhor, na tenda da congregação; e todo o restante do sangue derramará à base do altar do holocausto, que está diante da porta da tenda da congregação.',
        'E tirará dele toda a sua gordura, e queimá-la-á sobre o altar;',
        'E fará a este novilho, como fez ao novilho da expiação; assim lhe fará, e o sacerdote por eles fará propiciação, e lhes será perdoado o pecado.',
        'Depois levará o novilho fora do arraial, e o queimará como queimou o primeiro novilho; é expiação do pecado da congregação.',
        'Quando um príncipe pecar, e por ignorância proceder contra algum dos mandamentos do Senhor seu Deus, naquilo que não se deve fazer, e assim for culpado;',
        'Ou se o pecado que cometeu lhe for notificado, então trará pela sua oferta um bode tirado das cabras, macho sem defeito;',
        'E porá a sua mão sobre a cabeça do bode, e o degolará no lugar onde se degola o holocausto, perante a face do Senhor; expiação do pecado é.',
        'Depois o sacerdote com o seu dedo tomará do sangue da expiação, e o porá sobre as pontas do altar do holocausto; então o restante do seu sangue derramará à base do altar do holocausto.',
        'Também queimará sobre o altar toda a sua gordura como gordura do sacrifício pacífico; assim o sacerdote por ele fará expiação do seu pecado, e lhe será perdoado.',
        'E, se qualquer pessoa do povo da terra pecar por ignorância, fazendo contra algum dos mandamentos do Senhor, aquilo que não se deve fazer, e assim for culpada;',
        'Ou se o pecado que cometeu lhe for notificado, então trará pela sua oferta uma cabra sem defeito, pelo seu pecado que cometeu,',
        'E porá a sua mão sobre a cabeça da oferta da expiação do pecado, e a degolará no lugar do holocausto.',
        'Depois o sacerdote com o seu dedo tomará do seu sangue, e o porá sobre as pontas do altar do holocausto; e todo o restante do seu sangue derramará à base do altar;',
        'E tirará toda a gordura, como se tira a gordura do sacrifício pacífico; e o sacerdote a queimará sobre o altar, por cheiro suave ao Senhor; e o sacerdote fará expiação por ela, e ser-lhe-á perdoado o pecado.',
        'Mas, se pela sua oferta trouxer uma cordeira para expiação do pecado, sem defeito trará.',
        'E porá a sua mão sobre a cabeça da oferta da expiação do pecado, e a degolará por oferta pelo pecado, no lugar onde se degola o holocausto.',
        'Depois o sacerdote com o seu dedo tomará do sangue da expiação do pecado, e o porá sobre as pontas do altar do holocausto; então todo o restante do seu sangue derramará na base do altar.',
        'E tirará toda a sua gordura, como se tira a gordura do cordeiro do sacrifício pacífico; e o sacerdote a queimará sobre o altar, em cima das ofertas queimadas do Senhor; assim o sacerdote por ele fará expiação dos seus pecados que cometeu, e ele será perdoado.',
      ],
      [
        'E quando alguma pessoa pecar, ouvindo uma voz de blasfêmia, de que for testemunha, seja porque viu, ou porque soube, se o não denunciar, então levará a sua iniqüidade.',
        'Ou, quando alguma pessoa tocar em alguma coisa imunda, seja corpo morto de fera imunda, seja corpo morto de animal imundo, seja corpo morto de réptil imundo, ainda que não soubesse, contudo será ele imundo e culpado.',
        'Ou, quando tocar a imundícia de um homem, seja qualquer que for a sua imundícia, com que se faça imundo, e lhe for oculto, e o souber depois, será culpado.',
        'Ou, quando alguma pessoa jurar, pronunciando temerariamente com os seus lábios, para fazer mal, ou para fazer bem, em tudo o que o homem pronuncia temerariamente com juramento, e lhe for oculto, e o souber depois, culpado será numa destas coisas.',
        'Será, pois, que, culpado sendo numa destas coisas, confessará aquilo em que pecou.',
        'E a sua expiação trará ao Senhor, pelo seu pecado que cometeu: uma fêmea de gado miúdo, uma cordeira, ou uma cabrinha pelo pecado; assim o sacerdote por ela fará expiação do seu pecado.',
        'Mas, se em sua mão não houver recurso para gado miúdo, então trará, para expiação da culpa que cometeu, ao Senhor, duas rolas ou dois pombinhos; um para expiação do pecado, e o outro para holocausto;',
        'E os trará ao sacerdote, o qual primeiro oferecerá aquele que é para expiação do pecado; e com a sua unha lhe fenderá a cabeça junto ao pescoço, mas não o partirá;',
        'E do sangue da expiação do pecado espargirá sobre a parede do altar, porém o que sobejar daquele sangue espremer-se-á à base do altar; expiação do pecado é.',
        'E do outro fará holocausto conforme ao costume; assim o sacerdote por ela fará expiação do seu pecado que cometeu, e ele será perdoado.',
        'Porém, se em sua mão não houver recurso para duas rolas, ou dois pombinhos, então aquele que pecou trará como oferta a décima parte de um efa de flor de farinha, para expiação do pecado; não deitará sobre ela azeite nem lhe porá em cima o incenso, porquanto é expiação do pecado;',
        'E a trará ao sacerdote, e o sacerdote dela tomará a sua mão cheia pelo seu memorial, e a queimará sobre o altar, em cima das ofertas queimadas do Senhor; expiação de pecado é.',
        'Assim o sacerdote por ela fará expiação do seu pecado, que cometeu em alguma destas coisas, e lhe será perdoado; e o restante será do sacerdote, como a oferta de alimentos.',
        'E falou o Senhor a Moisés, dizendo:',
        'Quando alguma pessoa cometer uma transgressão, e pecar por ignorância nas coisas sagradas do Senhor, então trará ao Senhor pela expiação, um carneiro sem defeito do rebanho, conforme à tua estimação em siclos de prata, segundo o siclo do santuário, para expiação da culpa.',
        'Assim restituirá o que pecar nas coisas sagradas, e ainda lhe acrescentará a quinta parte, e a dará ao sacerdote; assim o sacerdote, com o carneiro da expiação, fará expiação por ele, e ser-lhe-á perdoado o pecado.',
        'E, se alguma pessoa pecar, e fizer, contra algum dos mandamentos do Senhor, aquilo que não se deve fazer, ainda que o não soubesse, contudo será ela culpada, e levará a sua iniqüidade;',
        'E trará ao sacerdote um carneiro sem defeito do rebanho, conforme à tua estimação, para expiação da culpa, e o sacerdote por ela fará expiação do erro que cometeu sem saber; e ser-lhe-á perdoado.',
        'Expiação de culpa é; certamente se fez culpado diante do Senhor.',
      ],
      [
        'Falou mais o SENHOR a Moisés, dizendo:',
        'Quando alguma pessoa pecar, e transgredir contra o Senhor, e negar ao seu próximo o que lhe deu em guarda, ou o que deixou na sua mão, ou o roubo, ou o que reteve violentamente ao seu próximo,',
        'Ou que achou o perdido, e o negar com falso juramento, ou fizer alguma outra coisa de todas em que o homem costuma pecar;',
        'Será pois que, como pecou e tornou-se culpado, restituirá o que roubou, ou o que reteve violentamente, ou o depósito que lhe foi dado em guarda, ou o perdido que achou,',
        'Ou tudo aquilo sobre que jurou falsamente; e o restituirá no seu todo, e ainda sobre isso acrescentará o quinto; àquele de quem é o dará no dia de sua expiação.',
        'E a sua expiação trará ao Senhor: um carneiro sem defeito do rebanho, conforme à tua estimação, para expiação da culpa trará ao sacerdote;',
        'E o sacerdote fará expiação por ela diante do Senhor, e será perdoada de qualquer das coisas que fez, tornando-se culpada.',
        'Falou mais o Senhor a Moisés, dizendo:',
        'Dá ordem a Arão e a seus filhos, dizendo: Esta é a lei do holocausto; o holocausto será queimado sobre o altar toda a noite até pela manhã, e o fogo do altar arderá nele.',
        'E o sacerdote vestirá a sua veste de linho, e vestirá as calças de linho, sobre a sua carne, e levantará a cinza, quando o fogo houver consumido o holocausto sobre o altar, e a porá junto ao altar.',
        'Depois despirá as suas vestes, e vestirá outras vestes; e levará a cinza fora do arraial para um lugar limpo.',
        'O fogo que está sobre o altar arderá nele, não se apagará; mas o sacerdote acenderá lenha nele cada manhã, e sobre ele porá em ordem o holocausto e sobre ele queimará a gordura das ofertas pacíficas.',
        'O fogo arderá continuamente sobre o altar; não se apagará.',
        'E esta é a lei da oferta de alimentos: os filhos de Arão a oferecerão perante o Senhor diante do altar.',
        'E dela tomará um punhado da flor de farinha, da oferta e do seu azeite, e todo o incenso que estiver sobre a oferta de alimentos; então o acenderá sobre o altar, cheiro suave é isso, por ser memorial ao Senhor.',
        'E o restante dela comerão Arão e seus filhos; ázimo se comerá no lugar santo, no pátio da tenda da congregação o comerão.',
        'Levedado não se cozerá; sua porção é que lhes dei das minhas ofertas queimadas; coisa santíssima é, como a expiação do pecado e como a expiação da culpa.',
        'Todo o homem entre os filhos de Arão comerá dela; estatuto perpétuo será para as vossas gerações das ofertas queimadas do Senhor; todo o que as tocar será santo.',
        'Falou mais o Senhor a Moisés, dizendo:',
        'Esta é a oferta de Arão e de seus filhos, a qual oferecerão ao Senhor no dia em que ele for ungido; a décima parte de um efa de flor de farinha pela oferta de alimentos contínua; a metade dela pela manhã, e a outra metade à tarde.',
        'Numa caçoula se fará com azeite; cozida a trarás; e os pedaços cozidos da oferta oferecerás em cheiro suave ao Senhor.',
        'Também o sacerdote, que de entre seus filhos for ungido em seu lugar, fará o mesmo; por estatuto perpétuo será ela toda queimada ao Senhor.',
        'Assim toda a oferta do sacerdote será totalmente queimada; não se comerá.',
        'Falou mais o Senhor a Moisés, dizendo:',
        'Fala a Arão e a seus filhos, dizendo: Esta é a lei da expiação do pecado; no lugar onde se degola o holocausto se degolará a expiação do pecado perante o Senhor; coisa santíssima é.',
        'O sacerdote que a oferecer pelo pecado a comerá; no lugar santo se comerá, no pátio da tenda da congregação.',
        'Tudo o que tocar a carne da oferta será santo; se o seu sangue for espargido sobre as vestes de alguém, lavarás em lugar santo aquilo sobre o que caiu.',
        'E o vaso de barro em que for cozida será quebrado; porém, se for cozida num vaso de cobre, esfregar-se-á e lavar-se-á na água.',
        'Todo o homem entre os sacerdotes a comerá; coisa santíssima é.',
        'Porém, não se comerá nenhuma oferta pelo pecado, cujo sangue se traz à tenda da congregação, para expiar no santuário; no fogo será queimada.',
      ],
      [
        'E esta é a lei da expiação da culpa; coisa santíssima é.',
        'No lugar onde degolam o holocausto, degolarão a oferta pela expiação da culpa, e o seu sangue se espargirá sobre o altar em redor.',
        'E dela se oferecerá toda a sua gordura; a cauda, e a gordura que cobre a fressura.',
        'Também ambos os rins, e a gordura que neles há, que está junto aos lombos, e o redenho sobre o fígado, com os rins se tirará;',
        'E o sacerdote os queimará sobre o altar em oferta queimada ao Senhor; expiação da culpa é.',
        'Todo o varão entre os sacerdotes a comerá; no lugar santo se comerá; coisa santíssima é.',
        'Como a expiação pelo pecado, assim será a expiação da culpa; uma mesma lei haverá para elas; será do sacerdote que houver feito propiciação com ela.',
        'Também o sacerdote, que oferecer o holocausto de alguém, terá para si o couro do holocausto que oferecer.',
        'Como também toda a oferta que se cozer no forno, com tudo que se preparar na frigideira e na caçoula, será do sacerdote que a oferecer.',
        'Também toda a oferta amassada com azeite, ou seca, será de todos os filhos de Arão, assim de um como de outro.',
        'E esta é a lei do sacrifício pacífico que se oferecerá ao Senhor:',
        'Se o oferecer por oferta de ação de graças, com o sacrifício de ação de graças, oferecerá bolos ázimos amassados com azeite; e coscorões ázimos amassados com azeite; e os bolos amassados com azeite serão fritos, de flor de farinha.',
        'Com os bolos oferecerá por sua oferta pão levedado, com o sacrifício de ação de graças da sua oferta pacífica.',
        'E de toda a oferta oferecerá uma parte por oferta alçada ao Senhor, que será do sacerdote que espargir o sangue da oferta pacífica.',
        'Mas a carne do sacrifício de ação de graças da sua oferta pacífica se comerá no dia do seu oferecimento; nada se deixará dela até à manhã.',
        'E, se o sacrifício da sua oferta for voto, ou oferta voluntária, no dia em que oferecer o seu sacrifício se comerá; e o que dele ficar também se comerá no dia seguinte;',
        'E o que ainda ficar da carne do sacrifício ao terceiro dia será queimado no fogo.',
        'Porque, se da carne do seu sacrifício pacífico se comer ao terceiro dia, aquele que a ofereceu não será aceito, nem lhe será imputado; coisa abominável será, e a pessoa que dela comer levará a sua iniqüidade.',
        'E a carne que tocar alguma coisa imunda não se comerá; com fogo será queimada; mas da outra carne, qualquer que estiver limpo, comerá dela.',
        'Porém, se alguma pessoa comer a carne do sacrifício pacífico, que é do Senhor, tendo ela sobre si a sua imundícia, aquela pessoa será extirpada do seu povo.',
        'E, se uma pessoa tocar alguma coisa imunda, como imundícia de homem, ou gado imundo, ou qualquer abominação imunda, e comer da carne do sacrifício pacífico, que é do Senhor, aquela pessoa será extirpada do seu povo.',
        'Depois falou o Senhor a Moisés, dizendo:',
        'Fala aos filhos de Israel, dizendo: Nenhuma gordura de boi, nem de carneiro, nem de cabra comereis;',
        'Porém pode-se usar da gordura de corpo morto, e da gordura do dilacerado por feras, para toda a obra, mas de nenhuma maneira a comereis;',
        'Porque qualquer que comer a gordura do animal, do qual se oferecer ao Senhor oferta queimada, a pessoa que a comer será extirpada do seu povo.',
        'E nenhum sangue comereis em qualquer das vossas habitações, quer de aves quer de gado.',
        'Toda a pessoa que comer algum sangue, aquela pessoa será extirpada do seu povo.',
        'Falou mais o Senhor a Moisés, dizendo:',
        'Fala aos filhos de Israel, dizendo: Quem oferecer ao Senhor o seu sacrifício pacífico, trará a sua oferta ao Senhor do seu sacrifício pacífico.',
        'As suas próprias mãos trarão as ofertas queimadas do Senhor; a gordura do peito com o peito trará para movê-lo por oferta movida perante o Senhor.',
        'E o sacerdote queimará a gordura sobre o altar, porém o peito será de Arão e de seus filhos.',
        'Também a espádua direita dareis ao sacerdote por oferta alçada dos vossos sacrifícios pacíficos.',
        'Aquele dos filhos de Arão que oferecer o sangue do sacrifício pacífico, e a gordura, esse terá a espádua direita para a sua porção;',
        'Porque o peito movido e a espádua alçada tomei dos filhos de Israel dos seus sacrifícios pacíficos, e os dei a Arão, o sacerdote, e a seus filhos, por estatuto perpétuo dos filhos de Israel.',
        'Esta é a porção de Arão e a porção de seus filhos das ofertas queimadas do Senhor, desde o dia em que ele os apresentou para administrar o sacerdócio ao Senhor.',
        'O que o Senhor ordenou que se lhes desse dentre os filhos de Israel no dia em que os ungiu; estatuto perpétuo é pelas suas gerações.',
        'Esta é a lei do holocausto, da oferta de alimentos, e da expiação do pecado, e da expiação da culpa, e da oferta das consagrações, e do sacrifício pacífico,',
        'Que o Senhor ordenou a Moisés no monte Sinai, no dia em que ordenou aos filhos de Israel que oferecessem as suas ofertas ao Senhor, no deserto de Sinai.',
      ],
      [
        'Falou mais o SENHOR a Moisés, dizendo:',
        'Toma a Arão e a seus filhos com ele, e as vestes, e o azeite da unção, como também o novilho da expiação do pecado, e os dois carneiros, e o cesto dos pães ázimos,',
        'E reúne toda a congregação à porta da tenda da congregação.',
        'Fez, pois, Moisés como o Senhor lhe ordenara, e a congregação reuniu-se à porta da tenda da congregação.',
        'Então disse Moisés à congregação: Isto é o que o Senhor ordenou que se fizesse.',
        'E Moisés fez chegar a Arão e a seus filhos, e os lavou com água.',
        'E vestiu-lhe a túnica, e cingiu-o com o cinto, e pôs sobre ele o manto; também pôs sobre ele o éfode, e cingiu-o com o cinto de obra esmerada do éfode e o apertou com ele.',
        'Depois pôs-lhe o peitoral, pondo no peitoral o Urim e o Tumim;',
        'E pôs a mitra sobre a sua cabeça; e sobre esta, na parte dianteira, pôs a lâmina de ouro, a coroa da santidade, como o Senhor ordenara a Moisés.',
        'Então Moisés tomou o azeite da unção, e ungiu o tabernáculo, e tudo o que havia nele, e o santificou;',
        'E dele espargiu sete vezes sobre o altar, e ungiu o altar e todos os seus utensílios, como também a pia e a sua base, para santificá-las.',
        'Depois derramou do azeite da unção sobre a cabeça de Arão, e ungiu-o, para santificá-lo.',
        'Também Moisés fez chegar os filhos de Arão, e vestiu-lhes as túnicas, e cingiu-os com o cinto, e apertou-lhes as tiaras, como o Senhor ordenara a Moisés.',
        'Então fez chegar o novilho da expiação do pecado; e Arão e seus filhos puseram as suas mãos sobre a cabeça do novilho da expiação do pecado;',
        'E o degolou; e Moisés tomou o sangue, e pôs dele com o seu dedo sobre as pontas do altar em redor, e purificou o altar; depois derramou o restante do sangue à base do altar, e o santificou, para fazer expiação por ele.',
        'Depois tomou toda a gordura que está na fressura, e o redenho do fígado, e os dois rins e a sua gordura; e Moisés queimou-os sobre o altar.',
        'Mas o novilho com o seu couro, e a sua carne, e o seu esterco, queimou com fogo fora do arraial, como o Senhor ordenara a Moisés.',
        'Depois fez chegar o carneiro do holocausto; e Arão e seus filhos puseram as suas mãos sobre a cabeça do carneiro;',
        'E degolou-o; e Moisés espargiu o sangue sobre o altar em redor.',
        'Partiu também o carneiro nos seus pedaços; e Moisés queimou a cabeça, e os pedaços e a gordura.',
        'Porém a fressura e as pernas lavou com água; e Moisés queimou todo o carneiro sobre o altar; holocausto de cheiro suave, uma oferta queimada ao Senhor, como o Senhor ordenou a Moisés.',
        'Depois fez chegar o outro carneiro, o carneiro da consagração; e Arão com seus filhos puseram as suas mãos sobre a cabeça do carneiro.',
        'E degolou-o; e Moisés tomou do seu sangue, e o pôs sobre a ponta da orelha direita de Arão, e sobre o polegar da sua mão direita, e sobre o polegar do seu pé direito.',
        'Moisés também fez chegar os filhos de Arão, e pôs daquele sangue sobre a ponta da orelha direita deles, e sobre o polegar da sua mão direita, e sobre o polegar do seu pé direito; e Moisés espargiu o restante do sangue sobre o altar em redor.',
        'E tomou a gordura, e a cauda, e toda a gordura que está na fressura, e o redenho do fígado, e ambos os rins, e a sua gordura e a espádua direita.',
        'Também do cesto dos pães ázimos, que estava diante do Senhor, tomou um bolo ázimo, e um bolo de pão azeitado, e um coscorão, e os pôs sobre a gordura e sobre a espádua direita.',
        'E tudo isto pôs nas mãos de Arão e nas mãos de seus filhos; e os ofereceu por oferta movida perante o Senhor.',
        'Depois Moisés tomou-os das suas mãos, e os queimou no altar sobre o holocausto; estes foram uma consagração, por cheiro suave, oferta queimada ao Senhor.',
        'E tomou Moisés o peito, e ofereceu-o por oferta movida perante o Senhor. Aquela foi a porção de Moisés do carneiro da consagração, como o Senhor ordenara a Moisés.',
        'Tomou Moisés também do azeite da unção, e do sangue que estava sobre o altar, e o espargiu sobre Arão e sobre as suas vestes, e sobre os seus filhos, e sobre as vestes de seus filhos com ele; e santificou a Arão e as suas vestes, e seus filhos, e as vestes de seus filhos com ele.',
        'E Moisés disse a Arão, e a seus filhos: Cozei a carne diante da porta da tenda da congregação, e ali a comereis com o pão que está no cesto da consagração, como tenho ordenado, dizendo: Arão e seus filhos a comerão.',
        'Mas o que sobejar da carne e do pão, queimareis com fogo.',
        'Também da porta da tenda da congregação não saireis por sete dias, até ao dia em que se cumprirem os dias da vossa consagração; porquanto por sete dias ele vos consagrará.',
        'Como se fez neste dia, assim o Senhor ordenou se fizesse, para fazer expiação por vós.',
        'Ficareis, pois, à porta da tenda da congregação dia e noite por sete dias, e guardareis as ordenanças do Senhor, para que não morrais; porque assim me foi ordenado.',
        'E Arão e seus filhos fizeram todas as coisas que o Senhor ordenara pela mão de Moisés.',
      ],
      [
        'E aconteceu, ao dia oitavo, que Moisés chamou a Arão e seus filhos, e os anciãos de Israel,',
        'E disse a Arão: Toma um bezerro, para expiação do pecado, e um carneiro para holocausto, sem defeito; e traze-os perante o Senhor.',
        'Depois falarás aos filhos de Israel, dizendo: Tomai um bode para expiação do pecado, e um bezerro, e um cordeiro de um ano, sem defeito, para holocausto;',
        'Também um boi e um carneiro por sacrifício pacífico, para sacrificar perante o Senhor, e oferta de alimentos, amassada com azeite; porquanto hoje o Senhor vos aparecerá.',
        'Então trouxeram o que ordenara Moisés, diante da tenda da congregação, e chegou-se toda a congregação e se pôs perante o Senhor.',
        'E disse Moisés: Esta é a coisa que o Senhor ordenou que fizésseis; e a glória do Senhor vos aparecerá.',
        'E disse Moisés a Arão: Chega-te ao altar, e faze a tua expiação de pecado e o teu holocausto; e faze expiação por ti e pelo povo; depois faze a oferta do povo, e faze expiação por eles, como ordenou o Senhor.',
        'Então Arão se chegou ao altar, e degolou o bezerro da expiação que era por si mesmo.',
        'E os filhos de Arão trouxeram-lhe o sangue, e molhou o seu dedo no sangue, e o pôs sobre as pontas do altar; e o restante do sangue derramou à base do altar.',
        'Mas a gordura, e os rins, e o redenho do fígado de expiação do pecado, queimou sobre o altar, como o Senhor ordenara a Moisés.',
        'Porém a carne e o couro queimou com fogo fora do arraial.',
        'Depois degolou o holocausto, e os filhos de Arão lhe entregaram o sangue, e espargiu-o sobre o altar em redor.',
        'Também lhe entregaram o holocausto nos seus pedaços, com a cabeça; e queimou-o sobre o altar.',
        'E lavou a fressura e as pernas, e as queimou sobre o holocausto no altar.',
        'Depois fez chegar a oferta do povo, e tomou o bode da expiação do pecado, que era pelo povo, e o degolou, e o preparou por expiação do pecado, como o primeiro.',
        'Fez também chegar o holocausto, e ofereceu-o segundo o rito.',
        'E fez chegar a oferta de alimentos, e a sua mão encheu dela, e queimou-a sobre o altar, além do holocausto da manhã.',
        'Depois degolou o boi e o carneiro em sacrifício pacífico, que era pelo povo; e os filhos de Arão entregaram-lhe o sangue, que espargiu sobre o altar em redor.',
        'Como também a gordura do boi e do carneiro, a cauda, e o que cobre a fressura, e os rins, e o redenho do fígado.',
        'E puseram a gordura sobre os peitos, e queimou a gordura sobre o altar;',
        'Mas os peitos e a espádua direita Arão ofereceu por oferta movida perante o Senhor, como Moisés tinha ordenado.',
        'Depois Arão levantou as suas mãos ao povo e o abençoou; e desceu, havendo feito a expiação do pecado, e o holocausto, e a oferta pacífica.',
        'Então entraram Moisés e Arão na tenda da congregação; depois saíram, e abençoaram ao povo; e a glória do Senhor apareceu a todo o povo.',
        'Porque o fogo saiu de diante do Senhor, e consumiu o holocausto e a gordura, sobre o altar; o que vendo todo o povo, jubilaram e caíram sobre as suas faces.',
      ],
      [
        'E os filhos de Arão, Nadabe e Abiú, tomaram cada um o seu incensário e puseram neles fogo, e colocaram incenso sobre ele, e ofereceram fogo estranho perante o SENHOR, o que não lhes ordenara.',
        'Então saiu fogo de diante do Senhor e os consumiu; e morreram perante o Senhor.',
        'E disse Moisés a Arão: Isto é o que o Senhor falou, dizendo: Serei santificado naqueles que se chegarem a mim, e serei glorificado diante de todo o povo. Porém Arão calou-se.',
        'E Moisés chamou a Misael e a Elzafã, filhos de Uziel, tio de Arão, e disse-lhes: Chegai, levai a vossos irmãos de diante do santuário, para fora do arraial.',
        'Então chegaram, e os levaram nas suas túnicas para fora do arraial, como Moisés lhes dissera.',
        'E Moisés disse a Arão, e a seus filhos Eleazar e Itamar: Não descobrireis as vossas cabeças, nem rasgareis vossas vestes, para que não morrais, nem venha grande indignação sobre toda a congregação; mas vossos irmãos, toda a casa de Israel, lamentem este incêndio que o Senhor acendeu.',
        'Nem saireis da porta da tenda da congregação, para que não morrais; porque está sobre vós o azeite da unção do Senhor. E fizeram conforme à palavra de Moisés.',
        'E falou o Senhor a Arão, dizendo:',
        'Não bebereis vinho nem bebida forte, nem tu nem teus filhos contigo, quando entrardes na tenda da congregação, para que não morrais; estatuto perpétuo será isso entre as vossas gerações;',
        'E para fazer diferença entre o santo e o profano e entre o imundo e o limpo,',
        'E para ensinar aos filhos de Israel todos os estatutos que o Senhor lhes tem falado por meio de Moisés.',
        'E disse Moisés a Arão, e a Eleazar e a Itamar, seus filhos, que lhe ficaram: Tomai a oferta de alimentos, restante das ofertas queimadas do Senhor, e comei-a sem levedura junto ao altar, porquanto é coisa santíssima.',
        'Portanto a comereis no lugar santo; porque isto é a tua porção, e a porção de teus filhos, das ofertas queimadas do Senhor; porque assim me foi ordenado.',
        'Também o peito da oferta movida e a espádua da oferta alçada, comereis em lugar limpo, tu, e teus filhos e tuas filhas contigo; porque foram dados por tua porção, e por porção de teus filhos, dos sacrifícios pacíficos dos filhos de Israel.',
        'A espádua da oferta alçada e o peito da oferta movida trarão com as ofertas queimadas de gordura, para oferecer por oferta movida perante o Senhor; o que será por estatuto perpétuo, para ti e para teus filhos contigo, como o Senhor tem ordenado.',
        'E Moisés diligentemente buscou o bode da expiação, e eis que já fora queimado; portanto indignou-se grandemente contra Eleazar e contra Itamar, os filhos de Arão que ficaram, dizendo:',
        'Por que não comestes a expiação do pecado no lugar santo, pois é coisa santíssima e Deus a deu a vós, para que levásseis a iniqüidade da congregação, para fazer expiação por eles diante do Senhor?',
        'Eis que não se trouxe o seu sangue para dentro do santuário; certamente devíeis ter comido no santuário, como tenho ordenado.',
        'Então disse Arão a Moisés: Eis que hoje ofereceram a sua expiação pelo pecado e o seu holocausto perante o Senhor, e tais coisas me sucederam; se hoje tivesse comido da oferta da expiação pelo pecado, seria isso porventura aceito aos olhos do Senhor?',
        'E Moisés, ouvindo isto, deu-se por satisfeito.',
      ],
      [
        'E falou o SENHOR a Moisés e a Arão, dizendo-lhes:',
        'Fala aos filhos de Israel, dizendo: Estes são os animais, que comereis dentre todos os animais que há sobre a terra;',
        'Dentre os animais, todo o que tem unhas fendidas, e a fenda das unhas se divide em duas, e rumina, deles comereis.',
        'Destes, porém, não comereis; dos que ruminam ou dos que têm unhas fendidas; o camelo, que rumina, mas não tem unhas fendidas; esse vos será imundo;',
        'E o coelho, porque rumina, mas não tem as unhas fendidas; esse vos será imundo;',
        'E a lebre, porque rumina, mas não tem as unhas fendidas; essa vos será imunda.',
        'Também o porco, porque tem unhas fendidas, e a fenda das unhas se divide em duas, mas não rumina; este vos será imundo.',
        'Das suas carnes não comereis, nem tocareis nos seus cadáveres; estes vos serão imundos.',
        'De todos os animais que há nas águas, comereis os seguintes: todo o que tem barbatanas e escamas, nas águas, nos mares e nos rios, esses comereis.',
        'Mas todo o que não tem barbatanas, nem escamas, nos mares e nos rios, todo o réptil das águas, e todo o ser vivente que há nas águas, estes serão para vós abominação.',
        'Ser-vos-ão, pois, por abominação; da sua carne não comereis, e abominareis o seu cadáver.',
        'Todo o que não tem barbatanas ou escamas, nas águas, será para vós abominação.',
        'Das aves, estas abominareis; não se comerão, serão abominação: a águia, e o quebrantosso, e o xofrango,',
        'E o milhano, e o abutre segundo a sua espécie.',
        'Todo o corvo segundo a sua espécie,',
        'E o avestruz, e o mocho, e a gaivota, e o gavião segundo a sua espécie.',
        'E o bufo, e o corvo marinho, e a coruja,',
        'E a gralha, e o cisne, e o pelicano,',
        'E a cegonha, a garça segundo a sua espécie, e a poupa, e o morcego.',
        'Todo o inseto que voa, que anda sobre quatro pés, será para vós uma abominação.',
        'Mas isto comereis de todo o inseto que voa, que anda sobre quatro pés: o que tiver pernas sobre os seus pés, para saltar com elas sobre a terra.',
        'Deles comereis estes: a locusta segundo a sua espécie, o gafanhoto devorador segundo a sua espécie, o grilo segundo a sua espécie, e o gafanhoto segundo a sua espécie.',
        'E todos os outros insetos que voam, que têm quatro pés, serão para vós uma abominação.',
        'E por estes sereis imundos: qualquer que tocar os seus cadáveres, imundo será até à tarde.',
        'Qualquer que levar os seus cadáveres lavará as suas vestes, e será imundo até à tarde.',
        'Todo o animal que tem unha fendida, mas a fenda não se divide em duas, e todo o que não rumina, vos será por imundo; qualquer que tocar neles será imundo.',
        'E todo o animal que anda sobre as suas patas, todo o animal que anda a quatro pés, vos será por imundo; qualquer que tocar nos seus cadáveres será imundo até à tarde.',
        'E o que levar os seus cadáveres lavará as suas vestes, e será imundo até à tarde; eles vos serão por imundos.',
        'Estes também vos serão por imundos entre os répteis que se arrastam sobre a terra; a doninha, e o rato, e a tartaruga segundo a sua espécie,',
        'E o ouriço cacheiro, e o lagarto, e a lagartixa, e a lesma e a toupeira.',
        'Estes vos serão por imundos dentre todos os répteis; qualquer que os tocar, estando eles mortos, será imundo até à tarde.',
        'E tudo aquilo sobre o que cair alguma coisa deles estando eles mortos será imundo; seja vaso de madeira, ou veste, ou pele, ou saco, qualquer instrumento, com que se faz alguma obra, será posto na água, e será imundo até à tarde; depois será limpo.',
        'E todo o vaso de barro, em que cair alguma coisa deles, tudo o que houver nele será imundo, e o vaso quebrareis.',
        'Todo o alimento que se come, sobre o qual cair água de tais vasos, será imundo; e toda a bebida que se bebe, depositada nesses vasos, será imunda.',
        'E aquilo sobre o que cair alguma parte de seu corpo morto, será imundo; o forno e o vaso de barro serão quebrados; imundos são: portanto vos serão por imundos.',
        'Porém a fonte ou cisterna, em que se recolhem águas, será limpa, mas quem tocar no seu cadáver será imundo.',
        'E, se dos seus cadáveres cair alguma coisa sobre alguma semente que se vai semear, será limpa;',
        'Mas se for deitada água sobre a semente, e se dos seus cadáveres cair alguma coisa sobre ela, vos será por imunda.',
        'E se morrer algum dos animais, que vos servem de mantimento, quem tocar no seu cadáver será imundo até à tarde;',
        'E quem comer do seu cadáver lavará as suas vestes, e será imundo até à tarde; e quem levar o seu corpo morto lavará as suas vestes, e será imundo até à tarde.',
        'Também todo o réptil, que se arrasta sobre a terra, será abominação; não se comerá.',
        'Tudo o que anda sobre o ventre, e tudo o que anda sobre quatro pés, ou que tem muitos pés, entre todo o réptil que se arrasta sobre a terra, não comereis, porquanto são uma abominação.',
        'Não vos façais abomináveis, por nenhum réptil que se arrasta, nem neles vos contamineis, para não serdes imundos por eles;',
        'Porque eu sou o Senhor vosso Deus; portanto vós vos santificareis, e sereis santos, porque eu sou santo; e não vos contaminareis com nenhum réptil que se arrasta sobre a terra;',
        'Porque eu sou o Senhor, que vos fiz subir da terra do Egito, para que eu seja vosso Deus, e para que sejais santos; porque eu sou santo.',
        'Esta é a lei dos animais, e das aves, e de toda criatura vivente que se move nas águas, e de toda criatura que se arrasta sobre a terra;',
        'Para fazer diferença entre o imundo e o limpo; e entre animais que se podem comer e os animais que não se podem comer.',
      ],
      [
        'Falou mais o SENHOR a Moisés, dizendo:',
        'Fala aos filhos de Israel, dizendo: Se uma mulher conceber e der à luz um menino, será imunda sete dias, assim como nos dias da separação da sua enfermidade, será imunda.',
        'E no dia oitavo se circuncidará ao menino a carne do seu prepúcio.',
        'Depois ficará ela trinta e três dias no sangue da sua purificação; nenhuma coisa santa tocará e não entrará no santuário até que se cumpram os dias da sua purificação.',
        'Mas, se der à luz uma menina será imunda duas semanas, como na sua separação; depois ficará sessenta e seis dias no sangue da sua purificação.',
        'E, quando forem cumpridos os dias da sua purificação por filho ou por filha, trará um cordeiro de um ano por holocausto, e um pombinho ou uma rola para expiação do pecado, diante da porta da tenda da congregação, ao sacerdote.',
        'O qual o oferecerá perante o Senhor, e por ela fará propiciação; e será limpa do fluxo do seu sangue; esta é a lei da que der à luz menino ou menina.',
        'Mas, se em sua mão não houver recursos para um cordeiro, então tomará duas rolas, ou dois pombinhos, um para o holocausto e outro para a propiciação do pecado; assim o sacerdote por ela fará expiação, e será limpa.',
      ],
      [
        'Falou mais o SENHOR a Moisés e a Arão, dizendo:',
        'Quando um homem tiver na pele da sua carne, inchação, ou pústula, ou mancha lustrosa, na pele de sua carne como praga da lepra, então será levado a Arão, o sacerdote, ou a um de seus filhos, os sacerdotes.',
        'E o sacerdote examinará a praga na pele da carne; se o pêlo na praga se tornou branco, e a praga parecer mais profunda do que a pele da sua carne, é praga de lepra; o sacerdote o examinará, e o declarará por imundo.',
        'Mas, se a mancha na pele de sua carne for branca, e não parecer mais profunda do que a pele, e o pêlo não se tornou branco, então o sacerdote encerrará o que tem a praga por sete dias;',
        'E ao sétimo dia o sacerdote o examinará; e eis que, se a praga, ao seu parecer parou, e na pele não se estendeu, então o sacerdote o encerrará por outros sete dias; 6',
        'E o sacerdote ao sétimo dia o examinará outra vez; e eis que, se a praga se recolheu, e na pele não se estendeu, então o sacerdote o declarará por limpo; é uma pústula; e lavará as suas vestes, e será limpo.',
        'Mas, se a pústula na pele se estende grandemente, depois que foi mostrado ao sacerdote para a sua purificação, outra vez será mostrado ao sacerdote,',
        'E o sacerdote o examinará, e eis que, se a pústula na pele se tem estendido, o sacerdote o declarará por imundo; é lepra.',
        'Quando no homem houver praga de lepra, será levado ao sacerdote,',
        'E o sacerdote o examinará, e eis que, se há inchação branca na pele, a qual tornou o pêlo em branco, e houver carne viva na inchação,',
        'Lepra inveterada é na pele da sua carne; portanto, o sacerdote o declarará por imundo; não o encerrará, porque imundo é.',
        'E, se a lepra se espalhar de todo na pele, e a lepra cobrir toda a pele do que tem a praga, desde a sua cabeça até aos seus pés, quanto podem ver os olhos do sacerdote,',
        'Então o sacerdote examinará, e eis que, se a lepra tem coberto toda a sua carne, então declarará o que tem a praga por limpo; todo se tornou branco; limpo está.',
        'Mas no dia em que aparecer nela carne viva será imundo.',
        'Vendo, pois, o sacerdote a carne viva, declará-lo-á por imundo; a carne é imunda; é lepra.',
        'Ou, tornando a carne viva, e mudando-se em branca, então virá ao sacerdote,',
        'E este o examinará, e eis que, se a praga se tornou branca, então o sacerdote declarará limpo o que tem a praga; limpo está.',
        'Se também a carne, em cuja pele houver alguma úlcera, sarar,',
        'E, em lugar da pústula, vier inchação branca ou mancha lustrosa, tirando a vermelho, mostrar-se-á então ao sacerdote.',
        'E o sacerdote examinará, e eis que, se ela parece mais funda do que a pele, e o seu pêlo se tornou branco, o sacerdote o declarará por imundo; é praga da lepra que brotou da pústula.',
        'E o sacerdote, vendo-a, e eis que se nela não houver pêlo branco, nem estiver mais funda do que a pele, mas encolhida, então o sacerdote o encerrará por sete dias.',
        'Se ela grandemente se estender na pele, o sacerdote o declarará por imundo; praga é.',
        'Mas se a mancha parar no seu lugar, não se estendendo, inflamação da pústula é; o sacerdote, pois, o declarará por limpo.',
        'Ou, quando na pele da carne houver queimadura de fogo, e no que é sarado da queimadura houver mancha lustrosa, tirando a vermelho ou branco,',
        'E o sacerdote vendo-a, e eis que se o pêlo na mancha se tornou branco e ela parece mais funda do que a pele, lepra é, que floresceu pela queimadura; portanto o sacerdote o declarará por imundo; é praga de lepra.',
        'Mas, se o sacerdote, vendo-a, e eis que, se na mancha não aparecer pêlo branco, nem estiver mais funda do que a pele, mas recolhida, o sacerdote o encerrará por sete dias.',
        'Depois o sacerdote o examinará ao sétimo dia; se grandemente se houver estendido na pele, o sacerdote o declarará por imundo; é praga de lepra.',
        'Mas se a mancha parar no seu lugar, e na pele não se estender, mas se recolher, inchação da queimadura é; portanto o sacerdote o declarará por limpo, porque inflamação é da queimadura.',
        'E, quando homem ou mulher tiver chaga na cabeça ou na barba,',
        'E o sacerdote, examinando a chaga, e eis que, se ela parece mais funda do que a pele, e pêlo amarelo fino há nela, o sacerdote o declarará por imundo; é tinha, é lepra da cabeça ou da barba.',
        'Mas, se o sacerdote, havendo examinado a praga da tinha, e eis que, se ela não parece mais funda do que a pele, e se nela não houver pêlo preto, então o sacerdote encerrará o que tem a praga da tinha por sete dias.',
        'E o sacerdote examinará a praga ao sétimo dia; e eis que, se a tinha não se tiver estendido, e nela não houver pêlo amarelo, nem a tinha parecer mais funda do que a pele,',
        'Então se rapará; mas não rapará a tinha; e o sacerdote segunda vez encerrará o que tem a tinha por sete dias.',
        'Depois o sacerdote examinará a tinha ao sétimo dia; e eis que, se a tinha não se houver estendido na pele, e ela não parecer mais funda do que a pele, o sacerdote o declarará por limpo, e lavará as suas vestes, e será limpo.',
        'Mas, se a tinha, depois da sua purificação, se houver estendido grandemente na pele,',
        'Então o sacerdote o examinará, e eis que, se a tinha se tem estendido na pele, o sacerdote não buscará pêlo amarelo; imundo está.',
        'Mas, se a tinha ao seu ver parou, e pêlo preto nela cresceu, a tinha está sã, limpo está; portanto o sacerdote o declarará por limpo.',
        'E, quando homem ou mulher tiver manchas lustrosas brancas na pele da sua carne,',
        'Então o sacerdote olhará, e eis que, se na pele da sua carne aparecem manchas lustrosas escurecidas, é impigem que floresceu na pele, limpo está.',
        'E, quando os cabelos do homem caírem da cabeça, calvo é, mas limpo está.',
        'E, se lhe caírem os cabelos na frente da cabeça, meio calvo é; mas limpo está.',
        'Porém, se na calva, ou na meia calva, houver praga branca avermelhada, é lepra, florescendo na sua calva ou na sua meia calva.',
        'Havendo, pois, o sacerdote examinado, e eis que, se a inchação da praga, na sua calva ou meia calva, está branca, tirando a vermelho, como parece a lepra na pele da carne,',
        'Leproso é aquele homem, imundo está; o sacerdote o declarará totalmente por imundo, na sua cabeça tem a praga.',
        'Também as vestes do leproso, em quem está a praga, serão rasgadas, e a sua cabeça será descoberta, e cobrirá o lábio superior, e clamará: Imundo, imundo.',
        'Todos os dias em que a praga houver nele, será imundo; imundo está, habitará só; a sua habitação será fora do arraial.',
        'Quando também em alguma roupa houver praga de lepra, em roupa de lã, ou em roupa de linho,',
        'Ou no fio urdido, ou no fio tecido, seja de linho, ou seja de lã, ou em pele, ou em qualquer obra de peles,',
        'E a praga na roupa, ou na pele, ou no fio urdido, ou no fio tecido, ou em qualquer coisa de peles aparecer verde ou vermelha, praga de lepra é, por isso se mostrará ao sacerdote,',
        'E o sacerdote examinará a praga, e encerrará aquilo que tem a praga por sete dias.',
        'Então examinará a praga ao sétimo dia; se a praga se houver estendido na roupa, ou no fio urdido, ou no fio tecido ou na pele, para qualquer obra que for feita da pele, lepra roedora é, imunda está;',
        'Por isso se queimará aquela roupa, ou fio urdido, ou fio tecido de lã, ou de linho, ou de qualquer obra de peles, em que houver a praga, porque lepra roedora é; com fogo se queimará.',
        'Mas, o sacerdote, vendo, e eis que, se a praga não se estendeu na roupa, ou no fio urdido, ou no tecido, ou em qualquer obra de peles,',
        'Então o sacerdote ordenará que se lave aquilo no qual havia a praga, e o encerrará segunda vez por sete dias;',
        'E o sacerdote, examinando a praga, depois que for lavada, e eis que se ela não mudou o seu aspecto, nem se estendeu, imundo está, com fogo o queimarás; praga penetrante é, seja por dentro ou por fora.',
        'Mas se o sacerdote verificar que a praga se tem recolhido, depois de lavada, então a rasgará da roupa, ou da pele ou do fio urdido ou tecido;',
        'E, se ainda aparecer na roupa, ou no fio urdido ou tecido ou em qualquer coisa de peles, lepra brotante é; com fogo queimarás aquilo em que há a praga;',
        'Mas a roupa ou fio urdido ou tecido ou qualquer coisa de peles, que lavares, e de que a praga se retirar, se lavará segunda vez, e será limpa.',
        'Esta é a lei da praga da lepra na roupa de lã, ou de linho, ou do fio urdido, ou tecido, ou de qualquer coisa de peles, para declará-la limpa, ou para declará-la imunda.',
      ],
      [
        'Depois falou o SENHOR a Moisés, dizendo:',
        'Esta será a lei do leproso no dia da sua purificação: será levado ao sacerdote,',
        'E o sacerdote sairá fora do arraial, e o examinará, e eis que, se a praga da lepra do leproso for sarada,',
        'Então o sacerdote ordenará que por aquele que se houver de purificar se tomem duas aves vivas e limpas, e pau de cedro, e carmesim, e hissopo.',
        'Mandará também o sacerdote que se degole uma ave num vaso de barro sobre águas vivas,',
        'E tomará a ave viva, e o pau de cedro, e o carmesim, e o hissopo, e os molhará, com a ave viva, no sangue da ave que foi degolada sobre as águas correntes.',
        'E sobre aquele que há de purificar-se da lepra espargirá sete vezes; então o declarará por limpo, e soltará a ave viva sobre a face do campo.',
        'E aquele que tem de purificar-se lavará as suas vestes, e rapará todo o seu pêlo, e se lavará com água; assim será limpo; e depois entrará no arraial, porém, ficará fora da sua tenda por sete dias;',
        'E será que ao sétimo dia rapará todo o seu pêlo, a sua cabeça, e a sua barba, e as sobrancelhas; sim, rapará todo o pêlo, e lavará as suas vestes, e lavará a sua carne com água, e será limpo,',
        'E ao oitavo dia tomará dois cordeiros sem defeito, e uma cordeira sem defeito, de um ano, e três dízimas de flor de farinha para oferta de alimentos, amassada com azeite, e um logue de azeite;',
        'E o sacerdote que faz a purificação apresentará o homem que houver de purificar-se, com aquelas coisas, perante o Senhor, à porta da tenda da congregação.',
        'E o sacerdote tomará um dos cordeiros, e o oferecerá por expiação da culpa, e o logue de azeite; e os oferecerá por oferta movida perante o Senhor.',
        'Então degolará o cordeiro no lugar em que se degola a oferta da expiação do pecado e o holocausto, no lugar santo; porque quer a oferta da expiação da culpa como a da expiação do pecado é para o sacerdote; coisa santíssima é.',
        'E o sacerdote tomará do sangue da expiação da culpa, e o porá sobre a ponta da orelha direita daquele que tem de purificar-se e sobre o dedo polegar da sua mão direita, e no dedo polegar do seu pé direito.',
        'Também o sacerdote tomará do logue de azeite, e o derramará na palma da sua própria mão esquerda.',
        'Então o sacerdote molhará o seu dedo direito no azeite que está na sua mão esquerda, e daquele azeite com o seu dedo espargirá sete vezes perante o Senhor;',
        'E o restante do azeite, que está na sua mão, o sacerdote porá sobre a ponta da orelha direita daquele que tem de purificar-se, e sobre o dedo polegar da sua mão direita, e sobre o dedo polegar do seu pé direito, em cima do sangue da expiação da culpa;',
        'E o restante do azeite que está na mão do sacerdote, o porá sobre a cabeça daquele que tem de purificar-se; assim o sacerdote fará expiação por ele perante o Senhor.',
        'Também o sacerdote fará a expiação do pecado, e fará expiação por aquele que tem de purificar-se da sua imundícia; e depois degolará o holocausto;',
        'E o sacerdote oferecerá o holocausto e a oferta de alimentos sobre o altar; assim o sacerdote fará expiação por ele, e será limpo.',
        'Porém se for pobre, e em sua mão não houver recursos para tanto, tomará um cordeiro para expiação da culpa em oferta de movimento, para fazer expiação por ele, e a dízima de flor de farinha, amassada com azeite, para oferta de alimentos, e um logue de azeite,',
        'E duas rolas, ou dois pombinhos, conforme as suas posses, dos quais um será para expiação do pecado, e o outro para holocausto.',
        'E ao oitavo dia da sua purificação os trará ao sacerdote, à porta da tenda da congregação, perante o Senhor.',
        'E o sacerdote tomará o cordeiro da expiação da culpa, e o logue de azeite, e os oferecerá por oferta movida perante o Senhor.',
        'Então degolará o cordeiro da expiação da culpa, e o sacerdote tomará do sangue da expiação da culpa, e o porá sobre a ponta da orelha direita daquele que tem de purificar-se, e sobre o dedo polegar da sua mão direita, e sobre o dedo polegar do seu pé direito.',
        'Também o sacerdote derramará do azeite na palma da sua própria mão esquerda.',
        'Depois o sacerdote com o seu dedo direito espargirá do azeite que está na sua mão esquerda, sete vezes perante o Senhor.',
        'E o sacerdote porá do azeite que está na sua mão na ponta da orelha direita daquele que tem de purificar-se, e no dedo polegar da sua mão direita, e no dedo polegar do seu pé direito; no lugar do sangue da expiação da culpa.',
        'E o que sobejar do azeite que está na mão do sacerdote porá sobre a cabeça daquele que tem de purificar-se, para fazer expiação por ele perante o Senhor.',
        'Depois oferecerá uma das rolas ou um dos pombinhos, conforme suas posses,',
        'Sim, conforme as suas posses, será um para expiação do pecado e o outro para holocausto com a oferta de alimentos; e assim o sacerdote fará expiação por aquele que tem de purificar-se perante o Senhor.',
        'Esta é a lei daquele em quem estiver a praga da lepra, cujas posses não lhe permitirem o devido para purificação.',
        'Falou mais o Senhor a Moisés e a Arão, dizendo:',
        'Quando tiverdes entrado na terra de Canaã que vos hei de dar por possessão, e eu enviar a praga da lepra em alguma casa da terra da vossa possessão,',
        'Então aquele, de quem for a casa, virá e informará ao sacerdote, dizendo: Parece-me que há como que praga em minha casa.',
        'E o sacerdote ordenará que desocupem a casa, antes que entre para examinar a praga, para que tudo o que está na casa não seja contaminado; e depois entrará o sacerdote, para examinar a casa;',
        'E, vendo a praga, e eis que se ela estiver nas paredes da casa em covinhas verdes ou vermelhas, e parecerem mais fundas do que a parede,',
        'Então o sacerdote sairá da casa para fora da porta, e fechá-la-á por sete dias.',
        'Depois, ao sétimo dia o sacerdote voltará, e examinará; e se vir que a praga nas paredes da casa se tem estendido,',
        'Então o sacerdote ordenará que arranquem as pedras, em que estiver a praga, e que as lancem fora da cidade, num lugar imundo;',
        'E fará raspar a casa por dentro ao redor, e o pó que houverem raspado lançarão fora da cidade, num lugar imundo;',
        'Depois tomarão outras pedras, e as porão no lugar das primeiras pedras; e outro barro se tomará, e a casa se rebocará.',
        'Porém, se a praga tornar a brotar na casa, depois de arrancadas as pedras e raspada a casa, e de novo rebocada,',
        'Então o sacerdote entrará e examinará, se a praga na casa se tem estendido, lepra roedora há na casa; imunda está.',
        'Portanto se derribará a casa, as suas pedras, e a sua madeira, como também todo o barro da casa; e se levará para fora da cidade a um lugar imundo.',
        'E o que entrar naquela casa, em qualquer dia em que estiver fechada, será imundo até à tarde.',
        'Também o que se deitar a dormir em tal casa, lavará as suas roupas; e o que comer em tal casa lavará as suas roupas.',
        'Porém, tornando o sacerdote a entrar na casa e examinando-a, se a praga não se tem estendido, depois que a casa foi rebocada, o sacerdote a declarará por limpa, porque a praga está curada.',
        'Depois tomará, para expiar a casa, duas aves, e pau de cedro, e carmesim e hissopo;',
        'E degolará uma ave num vaso de barro sobre águas correntes;',
        'Então tomará pau de cedro, e o hissopo, e o carmesim, e a ave viva, e os molhará no sangue da ave degolada e nas águas correntes, e espargirá a casa sete vezes;',
        'Assim expiará aquela casa com o sangue da ave, e com as águas correntes, e com a ave viva, e com o pau de cedro, e com o hissopo, e com o carmesim.',
        'Então soltará a ave viva para fora da cidade, sobre a face do campo; assim fará expiação pela casa, e será limpa.',
        'Esta é a lei de toda a praga da lepra, e da tinha,',
        'E da lepra das roupas, e das casas,',
        'E da inchação, e das pústulas, e das manchas lustrosas;',
        'Para ensinar quando alguma coisa será imunda, e quando será limpa. Esta é a lei da lepra.',
      ],
      [
        'Falou mais o SENHOR a Moisés e a Arão dizendo:',
        'Falai aos filhos de Israel, e dizei-lhes: Qualquer homem que tiver fluxo da sua carne, será imundo por causa do seu fluxo.',
        'Esta, pois, será a sua imundícia, por causa do seu fluxo; se a sua carne vasa o seu fluxo ou se a sua carne estanca o seu fluxo, esta é a sua imundícia.',
        'Toda a cama, em que se deitar o que tiver fluxo, será imunda; e toda a coisa, sobre o que se assentar, será imunda.',
        'E qualquer que tocar a sua cama, lavará as suas roupas, e se banhará em água, e será imundo até à tarde.',
        'E aquele que se assentar sobre aquilo em que se assentou o que tem o fluxo, lavará as suas roupas, e se banhará em água, e será imundo até à tarde.',
        'E aquele que tocar a carne do que tem o fluxo, lavará as suas roupas, e se banhará em água, e será imundo até à tarde.',
        'Quando também o que tem o fluxo cuspir sobre um limpo, então lavará este as suas roupas, e se banhará em água, e será imundo até à tarde.',
        'Também toda a sela, em que cavalgar o que tem o fluxo, será imunda.',
        'E qualquer que tocar em alguma coisa que esteve debaixo dele, será imundo até à tarde; e aquele que a levar, lavará as suas roupas, e se banhará em água, e será imundo até à tarde.',
        'Também todo aquele em quem tocar o que tem o fluxo, sem haver lavado as suas mãos com água, lavará as suas roupas, e se banhará em água, e será imundo até à tarde.',
        'E o vaso de barro, que tocar o que tem o fluxo, será quebrado; porém, todo o vaso de madeira será lavado com água.',
        'Quando, pois, o que tem o fluxo, estiver limpo do seu fluxo, contar-se-ão sete dias para a sua purificação, e lavará as suas roupas, e banhará a sua carne em águas correntes; e será limpo.',
        'E ao oitavo dia tomará duas rolas ou dois pombinhos, e virá perante o Senhor, à porta da tenda da congregação e os dará ao sacerdote;',
        'E o sacerdote oferecerá um para expiação do pecado, e o outro para holocausto; e assim o sacerdote fará por ele expiação do seu fluxo perante o Senhor.',
        'Também o homem, quando sair dele o sêmen da cópula, toda a sua carne banhará com água, e será imundo até à tarde.',
        'Também toda a roupa, e toda a pele em que houver sêmen da cópula se lavará com água, e será imundo até à tarde.',
        'E também se um homem se deitar com a mulher e tiver emissão de sêmen, ambos se banharão com água, e serão imundos até à tarde.',
        'Mas a mulher, quando tiver fluxo, e o seu fluxo de sangue estiver na sua carne, estará sete dias na sua separação, e qualquer que a tocar, será imundo até à tarde.',
        'E tudo aquilo sobre o que ela se deitar durante a sua separação, será imundo; e tudo sobre o que se assentar, será imundo.',
        'E qualquer que tocar na sua cama, lavará as suas vestes, e se banhará com água, e será imundo até à tarde.',
        'E qualquer que tocar alguma coisa, sobre o que ela se tiver assentado, lavará as suas vestes, e se banhará com água, e será imundo até à tarde.',
        'Se também tocar alguma coisa que estiver sobre a cama ou sobre aquilo em que ela se assentou, será imundo até à tarde.',
        'E se, com efeito, qualquer homem se deitar com ela, e a sua imundícia estiver sobre ele, imundo será por sete dias; também toda a cama, sobre que se deitar, será imunda.',
        'Também a mulher, quando tiver o fluxo do seu sangue, por muitos dias fora do tempo da sua separação, ou quando tiver fluxo de sangue por mais tempo do que a sua separação, todos os dias do fluxo da sua imundícia será imunda, como nos dias da sua separação.',
        'Toda a cama, sobre que se deitar todos os dias do seu fluxo, ser-lhe-á como a cama da sua separação; e toda a coisa, sobre que se assentar, será imunda, conforme a imundícia da sua separação.',
        'E qualquer que a tocar será imundo; portanto lavará as suas vestes, e se banhará com água, e será imundo até à tarde.',
        'Porém quando for limpa do seu fluxo, então se contarão sete dias, e depois será limpa.',
        'E ao oitavo dia tomará duas rolas, ou dois pombinhos, e os trará ao sacerdote, à porta da tenda da congregação.',
        'Então o sacerdote oferecerá um para expiação do pecado, e o outro para holocausto; e o sacerdote fará por ela expiação do fluxo da sua imundícia perante o Senhor.',
        'Assim separareis os filhos de Israel das suas imundícias, para que não morram nas suas imundícias, contaminando o meu tabernáculo, que está no meio deles.',
        'Esta é a lei daquele que tem o fluxo, e daquele de quem sai o sêmen da cópula, e que fica por eles imundo;',
        'Como também da mulher enferma na sua separação, e daquele que padece do seu fluxo, seja homem ou mulher, e do homem que se deita com mulher imunda.',
      ],
      [
        'E falou o SENHOR a Moisés, depois da morte dos dois filhos de Arão, que morreram quando se chegaram diante do SENHOR.',
        'Disse, pois, o Senhor a Moisés: Dize a Arão, teu irmão, que não entre no santuário em todo o tempo, para dentro do véu, diante do propiciatório que está sobre a arca, para que não morra; porque eu aparecerei na nuvem sobre o propiciatório.',
        'Com isto Arão entrará no santuário: com um novilho, para expiação do pecado, e um carneiro para holocausto.',
        'Vestirá ele a túnica santa de linho, e terá ceroulas de linho sobre a sua carne, e cingir-se-á com um cinto de linho, e se cobrirá com uma mitra de linho; estas são vestes santas; por isso banhará a sua carne na água, e as vestirá.',
        'E da congregação dos filhos de Israel tomará dois bodes para expiação do pecado e um carneiro para holocausto.',
        'Depois Arão oferecerá o novilho da expiação, que será para ele; e fará expiação por si e pela sua casa.',
        'Também tomará ambos os bodes, e os porá perante o Senhor, à porta da tenda da congregação.',
        'E Arão lançará sortes sobre os dois bodes; uma pelo Senhor, e a outra pelo bode emissário.',
        'Então Arão fará chegar o bode, sobre o qual cair a sorte pelo Senhor, e o oferecerá para expiação do pecado.',
        'Mas o bode, sobre que cair a sorte para ser bode emissário, apresentar-se-á vivo perante o Senhor, para fazer expiação com ele, a fim de enviá-lo ao deserto como bode emissário.',
        'E Arão fará chegar o novilho da expiação, que será por ele, e fará expiação por si e pela sua casa; e degolará o novilho da sua expiação.',
        'Tomará também o incensário cheio de brasas de fogo do altar, de diante do Senhor, e os seus punhos cheios de incenso aromático moído, e o levará para dentro do véu.',
        'E porá o incenso sobre o fogo perante o Senhor, e a nuvem do incenso cobrirá o propiciatório, que está sobre o testemunho, para que não morra.',
        'E tomará do sangue do novilho, e com o seu dedo espargirá sobre a face do propiciatório, para o lado oriental; e perante o propiciatório espargirá sete vezes do sangue com o seu dedo.',
        'Depois degolará o bode, da expiação, que será pelo povo, e trará o seu sangue para dentro do véu; e fará com o seu sangue como fez com o sangue do novilho, e o espargirá sobre o propiciatório, e perante a face do propiciatório.',
        'Assim fará expiação pelo santuário por causa das imundícias dos filhos de Israel e das suas transgressões, e de todos os seus pecados; e assim fará para a tenda da congregação que reside com eles no meio das suas imundícias.',
        'E nenhum homem estará na tenda da congregação quando ele entrar para fazer expiação no santuário, até que ele saia, depois de feita expiação por si mesmo, e pela sua casa, e por toda a congregação de Israel.',
        'Então sairá ao altar, que está perante o Senhor, e fará expiação por ele; e tomará do sangue do novilho, e do sangue do bode, e o porá sobre as pontas do altar ao redor.',
        'E daquele sangue espargirá sobre o altar, com o seu dedo, sete vezes, e o purificará das imundícias dos filhos de Israel, e o santificará.',
        'Havendo, pois, acabado de fazer expiação pelo santuário, e pela tenda da congregação, e pelo altar, então fará chegar o bode vivo.',
        'E Arão porá ambas as suas mãos sobre a cabeça do bode vivo, e sobre ele confessará todas as iniqüidades dos filhos de Israel, e todas as suas transgressões, e todos os seus pecados; e os porá sobre a cabeça do bode, e enviá-lo-á ao deserto, pela mão de um homem designado para isso.',
        'Assim aquele bode levará sobre si todas as iniqüidades deles à terra solitária; e deixará o bode no deserto.',
        'Depois Arão virá à tenda da congregação, e despirá as vestes de linho, que havia vestido quando entrara no santuário, e ali as deixará.',
        'E banhará a sua carne em água no lugar santo, e vestirá as suas vestes; então sairá e preparará o seu holocausto, e o holocausto do povo, e fará expiação por si e pelo povo.',
        'Também queimará a gordura da expiação do pecado sobre o altar.',
        'E aquele que tiver levado o bode emissário lavará as suas vestes, e banhará a sua carne em água; e depois entrará no arraial.',
        'Mas o novilho da expiação, e o bode da expiação do pecado, cujo sangue foi trazido para fazer expiação no santuário, serão levados fora do arraial; porém as suas peles, a sua carne, e o seu esterco queimarão com fogo.',
        'E aquele que os queimar lavará as suas vestes, e banhará a sua carne em água; e depois entrará no arraial.',
        'E isto vos será por estatuto perpétuo: no sétimo mês, aos dez do mês, afligireis as vossas almas, e nenhum trabalho fareis nem o natural nem o estrangeiro que peregrina entre vós.',
        'Porque naquele dia se fará expiação por vós, para purificar-vos; e sereis purificados de todos os vossos pecados perante o Senhor.',
        'É um sábado de descanso para vós, e afligireis as vossas almas; isto é estatuto perpétuo.',
        'E o sacerdote, que for ungido, e que for sagrado, para administrar o sacerdócio, no lugar de seu pai, fará a expiação, havendo vestido as vestes de linho, as vestes santas;',
        'Assim fará expiação pelo santo santuário; também fará expiação pela tenda da congregação e pelo altar; semelhantemente fará expiação pelos sacerdotes e por todo o povo da congregação.',
        'E isto vos será por estatuto perpétuo, para fazer expiação pelos filhos de Israel de todos os seus pecados, uma vez no ano. E fez Arão como o Senhor ordenara a Moisés.',
      ],
      [
        'Falou mais o SENHOR a Moisés, dizendo:',
        'Fala a Arão e aos seus filhos, e a todos os filhos de Israel, e dize-lhes: Esta é a palavra que o Senhor ordenou, dizendo:',
        'Qualquer homem da casa de Israel que degolar boi, ou cordeiro, ou cabra, no arraial, ou quem os degolar fora do arraial,',
        'E não os trouxer à porta da tenda da congregação, para oferecer oferta ao Senhor diante do tabernáculo do Senhor, a esse homem será imputado o sangue; derramou sangue; por isso será extirpado do seu povo;',
        'Para que os filhos de Israel, trazendo os seus sacrifícios, que oferecem sobre a face do campo, os tragam ao Senhor, à porta da tenda da congregação, ao sacerdote, e os ofereçam por sacrifícios pacíficos ao Senhor.',
        'E o sacerdote espargirá o sangue sobre o altar do Senhor, à porta da tenda da congregação, e queimará a gordura por cheiro suave ao Senhor.',
        'E nunca mais oferecerão os seus sacrifícios aos demônios, após os quais eles se prostituem; isto ser-lhes-á por estatuto perpétuo nas suas gerações.',
        'Dize-lhes, pois: Qualquer homem da casa de Israel, ou dos estrangeiros que peregrinam entre vós, que oferecer holocausto ou sacrifício,',
        'E não o trouxer à porta da tenda da congregação, para oferecê-lo ao Senhor, esse homem será extirpado do seu povo.',
        'E qualquer homem da casa de Israel, ou dos estrangeiros que peregrinam entre eles, que comer algum sangue, contra aquela alma porei a minha face, e a extirparei do seu povo.',
        'Porque a vida da carne está no sangue; pelo que vo-lo tenho dado sobre o altar, para fazer expiação pelas vossas almas; porquanto é o sangue que fará expiação pela alma.',
        'Portanto tenho dito aos filhos de Israel: Nenhum dentre vós comerá sangue, nem o estrangeiro, que peregrine entre vós, comerá sangue.',
        'Também qualquer homem dos filhos de Israel, ou dos estrangeiros que peregrinam entre eles, que caçar animal ou ave que se come, derramará o seu sangue, e o cobrirá com pó;',
        'Porquanto a vida de toda a carne é o seu sangue; por isso tenho dito aos filhos de Israel: Não comereis o sangue de nenhuma carne, porque a vida de toda a carne é o seu sangue; qualquer que o comer será extirpado.',
        'E todo o homem entre os naturais, ou entre os estrangeiros, que comer corpo morto ou dilacerado, lavará as suas vestes, e se banhará com água, e será imundo até à tarde; depois será limpo.',
        'Mas, se os não lavar, nem banhar a sua carne, levará sobre si a sua iniqüidade.',
      ],
      [
        'Falou mais o SENHOR a Moisés, dizendo:',
        'Fala aos filhos de Israel, e dize-lhes: Eu sou o Senhor vosso Deus.',
        'Não fareis segundo as obras da terra do Egito, em que habitastes, nem fareis segundo as obras da terra de Canaã, para a qual vos levo, nem andareis nos seus estatutos.',
        'Fareis conforme os meus juízos, e os meus estatutos guardareis, para andardes neles. Eu sou o Senhor vosso Deus.',
        'Portanto, os meus estatutos e os meus juízos guardareis; os quais, observando-os o homem, viverá por eles. Eu sou o Senhor.',
        'Nenhum homem se chegará a qualquer parenta da sua carne, para descobrir a sua nudez. Eu sou o Senhor.',
        'Não descobrirás a nudez de teu pai e de tua mãe: ela é tua mãe; não descobrirás a sua nudez.',
        'Não descobrirás a nudez da mulher de teu pai; é nudez de teu pai.',
        'A nudez da tua irmã, filha de teu pai, ou filha de tua mãe, nascida em casa, ou fora de casa, a sua nudez não descobrirás.',
        'A nudez da filha do teu filho, ou da filha de tua filha, a sua nudez não descobrirás; porque é tua nudez.',
        'A nudez da filha da mulher de teu pai, gerada de teu pai (ela é tua irmã), a sua nudez não descobrirás.',
        'A nudez da irmã de teu pai não descobrirás; ela é parenta de teu pai.',
        'A nudez da irmã de tua mãe não descobrirás; pois ela é parenta de tua mãe.',
        'A nudez do irmão de teu pai não descobrirás; não te chegarás à sua mulher; ela é tua tia.',
        'A nudez de tua nora não descobrirás: ela é mulher de teu filho; não descobrirás a sua nudez.',
        'A nudez da mulher de teu irmão não descobrirás; é a nudez de teu irmão.',
        'A nudez de uma mulher e de sua filha não descobrirás; não tomarás a filha de seu filho, nem a filha de sua filha, para descobrir a sua nudez; parentas são; maldade é.',
        'E não tomarás uma mulher juntamente com sua irmã, para fazê-la sua rival, descobrindo a sua nudez diante dela em sua vida.',
        'E não chegarás à mulher durante a separação da sua imundícia, para descobrir a sua nudez,',
        'Nem te deitarás com a mulher de teu próximo para cópula, para te contaminares com ela.',
        'E da tua descendência não darás nenhum para fazer passar pelo fogo perante Moloque; e não profanarás o nome de teu Deus. Eu sou o Senhor.',
        'Com homem não te deitarás, como se fosse mulher; abominação é;',
        'Nem te deitarás com um animal, para te contaminares com ele; nem a mulher se porá perante um animal, para ajuntar-se com ele; confusão é.',
        'Com nenhuma destas coisas vos contamineis; porque com todas estas coisas se contaminaram as nações que eu expulso de diante de vós.',
        'Por isso a terra está contaminada; e eu visito a sua iniqüidade, e a terra vomita os seus moradores.',
        'Porém vós guardareis os meus estatutos e os meus juízos, e nenhuma destas abominações fareis, nem o natural, nem o estrangeiro que peregrina entre vós;',
        'Porque todas estas abominações fizeram os homens desta terra, que nela estavam antes de vós; e a terra foi contaminada.',
        'Para que a terra não vos vomite, havendo-a contaminado, como vomitou a nação que nela estava antes de vós.',
        'Porém, qualquer que fizer alguma destas abominações, sim, aqueles que as fizerem serão extirpados do seu povo.',
        'Portanto guardareis o meu mandamento, não fazendo nenhuma das práticas abomináveis que se fizeram antes de vós, e não vos contamineis com elas. Eu sou o Senhor vosso Deus.',
      ],
      [
        'Falou mais o SENHOR a Moisés, dizendo:',
        'Fala a toda a congregação dos filhos de Israel, e dize-lhes: Santos sereis, porque eu, o Senhor vosso Deus, sou santo.',
        'Cada um temerá a sua mãe e a seu pai, e guardará os meus sábados. Eu sou o Senhor vosso Deus.',
        'Não vos virareis para os ídolos nem vos fareis deuses de fundição. Eu sou o Senhor vosso Deus.',
        'E, quando oferecerdes sacrifício pacífico ao Senhor, da vossa própria vontade o oferecereis.',
        'No dia em que o sacrificardes, e no dia seguinte, se comerá; mas o que sobejar ao terceiro dia, será queimado com fogo.',
        'E se alguma coisa dele for comida ao terceiro dia, coisa abominável é; não será aceita.',
        'E qualquer que o comer levará a sua iniqüidade, porquanto profanou a santidade do Senhor; por isso tal alma será extirpada do seu povo.',
        'Quando também fizerdes a colheita da vossa terra, o canto do teu campo não segarás totalmente, nem as espigas caídas colherás da tua sega.',
        'Semelhantemente não rabiscarás a tua vinha, nem colherás os bagos caídos da tua vinha; deixá-los-ás ao pobre e ao estrangeiro. Eu sou o Senhor vosso Deus.',
        'Não furtareis, nem mentireis, nem usareis de falsidade cada um com o seu próximo;',
        'Nem jurareis falso pelo meu nome, pois profanarás o nome do teu Deus. Eu sou o Senhor.',
        'Não oprimirás o teu próximo, nem o roubarás; a paga do diarista não ficará contigo até pela manhã.',
        'Não amaldiçoarás ao surdo, nem porás tropeço diante do cego; mas temerás o teu Deus. Eu sou o Senhor.',
        'Não farás injustiça no juízo; não respeitarás o pobre, nem honrarás o poderoso; com justiça julgarás o teu próximo.',
        'Não andarás como mexeriqueiro entre o teu povo; não te porás contra o sangue do teu próximo. Eu sou o Senhor.',
        'Não odiarás a teu irmão no teu coração; não deixarás de repreender o teu próximo, e por causa dele não sofrerás pecado.',
        'Não te vingarás nem guardarás ira contra os filhos do teu povo; mas amarás o teu próximo como a ti mesmo. Eu sou o Senhor.',
        'Guardarás os meus estatutos; não permitirás que se ajuntem misturadamente os teus animais de diferentes espécies; no teu campo não semearás sementes diversas, e não vestirás roupa de diversos estofos misturados.',
        'E, quando um homem se deitar com uma mulher que for serva desposada com outro homem, e não for resgatada nem se lhe houver dado liberdade, então serão açoitados; não morrerão, pois ela não foi libertada.',
        'E, por expiação da sua culpa, trará ao Senhor, à porta da tenda da congregação, um carneiro da expiação,',
        'E, com o carneiro da expiação da culpa, o sacerdote fará propiciação por ele perante o Senhor, pelo pecado que cometeu; e este lhe será perdoado.',
        'E, quando tiverdes entrado na terra, e plantardes toda a árvore de comer, ser-vos-á incircunciso o seu fruto; três anos vos será incircunciso; dele não se comerá.',
        'Porém no quarto ano todo o seu fruto será santo para dar louvores ao Senhor.',
        'E no quinto ano comereis o seu fruto, para que vos faça aumentar a sua produção. Eu sou o Senhor vosso Deus.',
        'Não comereis coisa alguma com o sangue; não agourareis nem adivinhareis.',
        'Não cortareis o cabelo, arredondando os cantos da vossa cabeça, nem danificareis as extremidades da tua barba.',
        'Pelos mortos não dareis golpes na vossa carne; nem fareis marca alguma sobre vós. Eu sou o Senhor.',
        'Não contaminarás a tua filha, fazendo-a prostituir-se; para que a terra não se prostitua, nem se encha de maldade.',
        'Guardareis os meus sábados, e o meu santuário reverenciareis. Eu sou o Senhor.',
        'Não vos virareis para os adivinhadores e encantadores; não os busqueis, contaminando-vos com eles. Eu sou o Senhor vosso Deus.',
        'Diante das cãs te levantarás, e honrarás a face do ancião; e temerás o teu Deus. Eu sou o Senhor.',
        'E quando o estrangeiro peregrinar convosco na vossa terra, não o oprimireis.',
        'Como um natural entre vós será o estrangeiro que peregrina convosco; amá-lo-ás como a ti mesmo, pois estrangeiros fostes na terra do Egito. Eu sou o Senhor vosso Deus.',
        'Não cometereis injustiça no juízo, nem na vara, nem no peso, nem na medida.',
        'Balanças justas, pesos justos, efa justo, e justo him tereis. Eu sou o Senhor vosso Deus, que vos tirei da terra do Egito.',
        'Por isso guardareis todos os meus estatutos, e todos os meus juízos, e os cumprireis. Eu sou o Senhor.',
      ],
      [
        'Falou mais o SENHOR a Moisés, dizendo:',
        'Também dirás aos filhos de Israel: Qualquer que, dos filhos de Israel, ou dos estrangeiros que peregrinam em Israel, der da sua descendência a Moloque, certamente morrerá; o povo da terra o apedrejará.',
        'E eu porei a minha face contra esse homem, e o extirparei do meio do seu povo, porquanto deu da sua descendência a Moloque, para contaminar o meu santuário e profanar o meu santo nome.',
        'E, se o povo da terra de alguma maneira esconder os seus olhos daquele homem, quando der, da sua descendência a Moloque, para não o matar,',
        'Então eu porei a minha face contra aquele homem, e contra a sua família, e o extirparei do meio do seu povo, bem como a todos que forem após ele, prostituindo-se com Moloque.',
        'Quando alguém se virar para os adivinhadores e encantadores, para se prostituir com eles, eu porei a minha face contra ele, e o extirparei do meio do seu povo.',
        'Portanto santificai-vos, e sede santos, pois eu sou o Senhor vosso Deus.',
        'E guardai os meus estatutos, e cumpri-os. Eu sou o Senhor que vos santifica.',
        'Quando um homem amaldiçoar a seu pai ou a sua mãe, certamente morrerá; amaldiçoou a seu pai ou a sua mãe; o seu sangue será sobre ele.',
        'Também o homem que adulterar com a mulher de outro, havendo adulterado com a mulher do seu próximo, certamente morrerá o adúltero e a adúltera.',
        'E o homem que se deitar com a mulher de seu pai descobriu a nudez de seu pai; ambos certamente morrerão; o seu sangue será sobre eles.',
        'Semelhantemente, quando um homem se deitar com a sua nora, ambos certamente morrerão; fizeram confusão; o seu sangue será sobre eles.',
        'Quando também um homem se deitar com outro homem, como com mulher, ambos fizeram abominação; certamente morrerão; o seu sangue será sobre eles.',
        'E, quando um homem tomar uma mulher e a sua mãe, maldade é; a ele e a elas queimarão com fogo, para que não haja maldade no meio de vós.',
        'Quando também um homem se deitar com um animal, certamente morrerá; e matareis o animal.',
        'Também a mulher que se chegar a algum animal, para ajuntar-se com ele, aquela mulher matarás bem assim como o animal; certamente morrerão; o seu sangue será sobre eles.',
        'E, quando um homem tomar a sua irmã, filha de seu pai, ou filha de sua mãe, e vir a nudez dela, e ela a sua, torpeza é; portanto serão extirpados aos olhos dos filhos do seu povo; descobriu a nudez de sua irmã, levará sobre si a sua iniqüidade.',
        'E, quando um homem se deitar com uma mulher no tempo da sua enfermidade, e descobrir a sua nudez, descobrindo a sua fonte, e ela descobrir a fonte do seu sangue, ambos serão extirpados do meio do seu povo.',
        'Também a nudez da irmã de tua mãe, ou da irmã de teu pai não descobrirás; porquanto descobriu a sua parenta, sobre si levarão a sua iniqüidade.',
        'Quando também um homem se deitar com a sua tia descobriu a nudez de seu tio; seu pecado sobre si levarão; sem filhos morrerão.',
        'E quando um homem tomar a mulher de seu irmão, imundícia é; a nudez de seu irmão descobriu; sem filhos ficarão.',
        'Guardai, pois, todos os meus estatutos, e todos os meus juízos, e cumpri-os, para que não vos vomite a terra, para a qual eu vos levo para habitar nela.',
        'E não andeis nos costumes das nações que eu expulso de diante de vós, porque fizeram todas estas coisas; portanto fui enfadado deles.',
        'E a vós vos tenho dito: Em herança possuireis a sua terra, e eu a darei a vós, para a possuirdes, terra que mana leite e mel. Eu sou o Senhor vosso Deus, que vos separei dos povos.',
        'Fareis, pois, diferença entre os animais limpos e imundos, e entre as aves imundas e as limpas; e as vossas almas não fareis abomináveis por causa dos animais, ou das aves, ou de tudo o que se arrasta sobre a terra; as quais coisas apartei de vós, para tê-las por imundas.',
        'E ser-me-eis santos, porque eu, o Senhor, sou santo, e vos separei dos povos, para serdes meus.',
        'Quando, pois, algum homem ou mulher em si tiver um espírito de necromancia ou espírito de adivinhação, certamente morrerá; serão apedrejados; o seu sangue será sobre eles.',
      ],
      [
        'Depois disse o SENHOR a Moisés: Fala aos sacerdotes, filhos de Arão, e dize-lhes: O sacerdote não se contaminará por causa de um morto entre o seu povo,',
        'Salvo por seu parente mais chegado: por sua mãe, e por seu pai, e por seu filho, e por sua filha, e por seu irmão.',
        'E por sua irmã virgem, chegada a ele, que ainda não teve marido; por ela também se contaminará.',
        'Ele sendo principal entre o seu povo, não se contaminará, pois que se profanaria.',
        'Não farão calva na sua cabeça, e não raparão as extremidades da sua barba, nem darão golpes na sua carne.',
        'Santos serão a seu Deus, e não profanarão o nome do seu Deus, porque oferecem as ofertas queimadas do Senhor, e o pão do seu Deus; portanto serão santos.',
        'Não tomarão mulher prostituta ou desonrada, nem tomarão mulher repudiada de seu marido; pois santo é a seu Deus.',
        'Portanto o santificarás, porquanto oferece o pão do teu Deus; santo será para ti, pois eu, o Senhor que vos santifica, sou santo.',
        'E quando a filha de um sacerdote começar a prostituir-se, profana a seu pai; com fogo será queimada.',
        'E o sumo sacerdote entre seus irmãos, sobre cuja cabeça foi derramado o azeite da unção, e que for consagrado para vestir as vestes, não descobrirá a sua cabeça nem rasgará as suas vestes;',
        'E não se chegará a cadáver algum, nem por causa de seu pai nem por sua mãe se contaminará;',
        'Nem sairá do santuário, para que não profane o santuário do seu Deus, pois a coroa do azeite da unção do seu Deus está sobre ele. Eu sou o Senhor.',
        'E ele tomará por esposa uma mulher na sua virgindade.',
        'Viúva, ou repudiada ou desonrada ou prostituta, estas não tomará; mas virgem do seu povo tomará por mulher.',
        'E não profanará a sua descendência entre o seu povo; porque eu sou o Senhor que o santifico.',
        'Falou mais o Senhor a Moisés, dizendo:',
        'Fala a Arão, dizendo: Ninguém da tua descendência, nas suas gerações, em que houver algum defeito, se chegará a oferecer o pão do seu Deus.',
        'Pois nenhum homem em quem houver alguma deformidade se chegará; como homem cego, ou coxo, ou de nariz chato, ou de membros demasiadamente compridos,',
        'Ou homem que tiver quebrado o pé, ou a mão quebrada,',
        'Ou corcunda, ou anão, ou que tiver defeito no olho, ou sarna, ou impigem, ou que tiver testículo mutilado.',
        'Nenhum homem da descendência de Arão, o sacerdote, em quem houver alguma deformidade, se chegará para oferecer as ofertas queimadas do Senhor; defeito nele há; não se chegará para oferecer o pão do seu Deus.',
        'Ele comerá do pão do seu Deus, tanto do santíssimo como do santo.',
        'Porém até ao véu não entrará, nem se chegará ao altar, porquanto defeito há nele, para que não profane os meus santuários; porque eu sou o Senhor que os santifico.',
        'E Moisés falou isto a Arão e a seus filhos, e a todos os filhos de Israel.',
      ],
      [
        'Depois falou o SENHOR a Moisés, dizendo:',
        'Dize a Arão e a seus filhos que se apartem das coisas santas dos filhos de Israel, que a mim me santificam, para que não profanem o meu santo nome. Eu sou o Senhor.',
        'Dize-lhes: Todo o homem, que entre as vossas gerações, de toda a vossa descendência, se chegar às coisas santas que os filhos de Israel santificam ao Senhor, tendo sobre si a sua imundícia, aquela alma será extirpada de diante da minha face. Eu sou o Senhor.',
        'Ninguém da descendência de Arão, que for leproso, ou tiver fluxo, comerá das coisas santas, até que seja limpo; como também o que tocar alguma coisa imunda de cadáver, ou aquele de que sair sêmen da cópula,',
        'Ou qualquer que tocar a algum réptil, pelo qual se fez imundo, ou a algum homem, pelo qual se fez imundo, segundo toda a sua imundícia;',
        'O homem que o tocar será imundo até à tarde, e não comerá das coisas santas, mas banhará a sua carne em água.',
        'E havendo-se o sol já posto, então será limpo, e depois comerá das coisas santas; porque este é o seu pão.',
        'O corpo morto e o dilacerado não comerá, para que não se contamine com ele. Eu sou o Senhor.',
        'Guardarão, pois, o meu mandamento, para que por isso não levem pecado, e morram nele, havendo-o profanado. Eu sou o Senhor que os santifico.',
        'Também nenhum estranho comerá das coisas santas; nem o hóspede do sacerdote, nem o diarista comerá das coisas santas.',
        'Mas quando o sacerdote comprar alguma pessoa com o seu dinheiro, aquela comerá delas, e os nascidos na sua casa, estes comerão do seu pão.',
        'E, quando a filha do sacerdote se casar com homem estranho, ela não comerá da oferta das coisas santas.',
        'Mas quando a filha do sacerdote for viúva ou repudiada, e não tiver filho, e se houver tornado à casa de seu pai, como na sua mocidade, do pão de seu pai comerá; mas nenhum estranho comerá dele.',
        'E quando alguém por erro comer a coisa santa, sobre ela acrescentará uma quinta parte, e a dará ao sacerdote com a coisa santa.',
        'Assim não profanarão as coisas santas dos filhos de Israel, que oferecem ao Senhor,',
        'Nem os farão levar a iniqüidade da culpa, comendo as suas coisas santas; pois eu sou o Senhor que as santifico.',
        'Falou mais o Senhor a Moisés, dizendo:',
        'Fala a Arão, e a seus filhos, e a todos os filhos de Israel, e dize-lhes: Qualquer que, da casa de Israel, ou dos estrangeiros em Israel, oferecer a sua oferta, quer dos seus votos, quer das suas ofertas voluntárias, que oferecem ao Senhor em holocausto,',
        'Segundo a sua vontade, oferecerá macho sem defeito, ou dos bois, ou dos cordeiros, ou das cabras.',
        'Nenhuma coisa em que haja defeito oferecereis, porque não seria aceita em vosso favor.',
        'E, quando alguém oferecer sacrifício pacífico ao Senhor, separando dos bois ou das ovelhas um voto, ou oferta voluntária, sem defeito será, para que seja aceito; nenhum defeito haverá nele.',
        'O cego, ou quebrado, ou aleijado, o verrugoso, ou sarnoso, ou cheio de impigens, estes não oferecereis ao Senhor, e deles não poreis oferta queimada ao Senhor sobre o altar.',
        'Porém boi, ou gado miúdo, comprido ou curto de membros, poderás oferecer por oferta voluntária, mas por voto não será aceito.',
        'O machucado, ou moído, ou despedaçado, ou cortado, não oferecereis ao Senhor; não fareis isto na vossa terra.',
        'Também da mão do estrangeiro nenhum alimento oferecereis ao vosso Deus, de todas estas coisas, pois a sua corrupção está nelas; defeito nelas há; não serão aceitas em vosso favor.',
        'Falou mais o Senhor a Moisés, dizendo:',
        'Quando nascer o boi, ou cordeiro, ou cabra, sete dias estará debaixo de sua mãe; depois, desde o oitavo dia em diante, será aceito por oferta queimada ao Senhor.',
        'Também boi ou gado miúdo, a ele e a seu filho não degolareis no mesmo dia.',
        'E, quando oferecerdes sacrifícios de louvores ao Senhor, o oferecereis da vossa vontade.',
        'No mesmo dia se comerá; dele nada deixareis ficar até pela manhã. Eu sou o Senhor.',
        'Por isso guardareis os meus mandamentos, e os cumprireis. Eu sou o Senhor.',
        'E não profanareis o meu santo nome, para que eu seja santificado no meio dos filhos de Israel. Eu sou o Senhor que vos santifico;',
        'Que vos tirei da terra do Egito, para ser o vosso Deus. Eu sou o Senhor.',
      ],
      [
        'Depois falou o SENHOR a Moisés, dizendo:',
        'Fala aos filhos de Israel, e dize-lhes: As solenidades do Senhor, que convocareis, serão santas convocações; estas são as minhas solenidades:',
        'Seis dias trabalho se fará, mas o sétimo dia será o sábado do descanso, santa convocação; nenhum trabalho fareis; sábado do Senhor é em todas as vossas habitações.',
        'Estas são as solenidades do Senhor, as santas convocações, que convocareis ao seu tempo determinado:',
        'No mês primeiro, aos catorze do mês, pela tarde, é a páscoa do Senhor.',
        'E aos quinze dias deste mês é a festa dos pães ázimos do Senhor; sete dias comereis pães ázimos.',
        'No primeiro dia tereis santa convocação; nenhum trabalho servil fareis;',
        'Mas sete dias oferecereis oferta queimada ao Senhor; ao sétimo dia haverá santa convocação; nenhum trabalho servil fareis.',
        'E falou o Senhor a Moisés, dizendo:',
        'Fala aos filhos de Israel, e dize-lhes: Quando houverdes entrado na terra, que vos hei de dar, e fizerdes a sua colheita, então trareis um molho das primícias da vossa sega ao sacerdote;',
        'E ele moverá o molho perante o Senhor, para que sejais aceitos; no dia seguinte ao sábado o sacerdote o moverá.',
        'E no dia em que moverdes o molho, preparareis um cordeiro sem defeito, de um ano, em holocausto ao Senhor,',
        'E a sua oferta de alimentos, será de duas dízimas de flor de farinha, amassada com azeite, para oferta queimada em cheiro suave ao Senhor, e a sua libação será de vinho, um quarto de him.',
        'E não comereis pão, nem trigo tostado, nem espigas verdes, até aquele mesmo dia em que trouxerdes a oferta do vosso Deus; estatuto perpétuo é por vossas gerações, em todas as vossas habitações.',
        'Depois para vós contareis desde o dia seguinte ao sábado, desde o dia em que trouxerdes o molho da oferta movida; sete semanas inteiras serão.',
        'Até ao dia seguinte ao sétimo sábado, contareis cinqüenta dias; então oferecereis nova oferta de alimentos ao Senhor.',
        'Das vossas habitações trareis dois pães de movimento; de duas dízimas de farinha serão, levedados se cozerão; primícias são ao Senhor.',
        'Também com o pão oferecereis sete cordeiros sem defeito, de um ano, e um novilho, e dois carneiros; holocausto serão ao Senhor, com a sua oferta de alimentos, e as suas libações, por oferta queimada de cheiro suave ao Senhor.',
        'Também oferecereis um bode para expiação do pecado, e dois cordeiros de um ano por sacrifício pacífico.',
        'Então o sacerdote os moverá com o pão das primícias por oferta movida perante o Senhor, com os dois cordeiros; santos serão ao Senhor para uso do sacerdote.',
        'E naquele mesmo dia apregoareis que tereis santa convocação; nenhum trabalho servil fareis; estatuto perpétuo é em todas as vossas habitações pelas vossas gerações.',
        'E, quando fizerdes a colheita da vossa terra, não acabarás de segar os cantos do teu campo, nem colherás as espigas caídas da tua sega; para o pobre e para o estrangeiro as deixarás. Eu sou o Senhor vosso Deus.',
        'E falou o Senhor a Moisés, dizendo:',
        'Fala aos filhos de Israel, dizendo: No mês sétimo, ao primeiro do mês, tereis descanso, memorial com sonido de trombetas, santa convocação.',
        'Nenhum trabalho servil fareis, mas oferecereis oferta queimada ao Senhor.',
        'Falou mais o Senhor a Moisés, dizendo:',
        'Mas aos dez dias desse sétimo mês será o dia da expiação; tereis santa convocação, e afligireis as vossas almas; e oferecereis oferta queimada ao Senhor.',
        'E naquele mesmo dia nenhum trabalho fareis, porque é o dia da expiação, para fazer expiação por vós perante o Senhor vosso Deus.',
        'Porque toda a alma, que naquele mesmo dia se não afligir, será extirpada do seu povo.',
        'Também toda a alma, que naquele mesmo dia fizer algum trabalho, eu a destruirei do meio do seu povo.',
        'Nenhum trabalho fareis; estatuto perpétuo é pelas vossas gerações em todas as vossas habitações.',
        'Sábado de descanso vos será; então afligireis as vossas almas; aos nove do mês à tarde, de uma tarde a outra tarde, celebrareis o vosso sábado.',
        'E falou o Senhor a Moisés, dizendo:',
        'Fala aos filhos de Israel, dizendo: Aos quinze dias deste mês sétimo será a festa dos tabernáculos ao Senhor por sete dias.',
        'Ao primeiro dia haverá santa convocação; nenhum trabalho servil fareis.',
        'Sete dias oferecereis ofertas queimadas ao Senhor; ao oitavo dia tereis santa convocação, e oferecereis ofertas queimadas ao Senhor; dia de proibição é, nenhum trabalho servil fareis.',
        'Estas são as solenidades do Senhor, que apregoareis para santas convocações, para oferecer ao Senhor oferta queimada, holocausto e oferta de alimentos, sacrifício e libações, cada qual em seu dia próprio;',
        'Além dos sábados do Senhor, e além dos vossos dons, e além de todos os vossos votos, e além de todas as vossas ofertas voluntárias, que dareis ao Senhor.',
        'Porém aos quinze dias do mês sétimo, quando tiverdes recolhido do fruto da terra, celebrareis a festa do Senhor por sete dias; no primeiro dia haverá descanso, e no oitavo dia haverá descanso.',
        'E no primeiro dia tomareis para vós ramos de formosas árvores, ramos de palmeiras, ramos de árvores frondosas, e salgueiros de ribeiras; e vos alegrareis perante o Senhor vosso Deus por sete dias.',
        'E celebrareis esta festa ao Senhor por sete dias cada ano; estatuto perpétuo é pelas vossas gerações; no mês sétimo a celebrareis.',
        'Sete dias habitareis em tendas; todos os naturais em Israel habitarão em tendas;',
        'Para que saibam as vossas gerações que eu fiz habitar os filhos de Israel em tendas, quando os tirei da terra do Egito. Eu sou o Senhor vosso Deus.',
        'Assim pronunciou Moisés as solenidades do Senhor aos filhos de Israel.',
      ],
      [
        'E falou o SENHOR a Moisés, dizendo:',
        'Ordena aos filhos de Israel que te tragam azeite de oliveira, puro, batido, para a luminária, para manter as lâmpadas acesas continuamente.',
        'Arão as porá em ordem perante o Senhor continuamente, desde a tarde até à manhã, fora do véu do testemunho, na tenda da congregação; estatuto perpétuo é pelas vossas gerações.',
        'Sobre o candelabro de ouro puro porá em ordem as lâmpadas perante o Senhor continuamente.',
        'Também tomarás da flor de farinha, e dela cozerás doze pães; cada pão será de duas dízimas de um efa.',
        'E os porás em duas fileiras, seis em cada fileira, sobre a mesa pura, perante o Senhor.',
        'E sobre cada fileira porás incenso puro, para que seja, para o pão, por oferta memorial; oferta queimada é ao Senhor.',
        'Em cada dia de sábado, isto se porá em ordem perante o Senhor continuamente, pelos filhos de Israel, por aliança perpétua.',
        'E será de Arão e de seus filhos, os quais o comerão no lugar santo, porque uma coisa santíssima é para eles, das ofertas queimadas ao Senhor, por estatuto perpétuo.',
        'E apareceu, no meio dos filhos de Israel o filho de uma mulher israelita, o qual era filho de um homem egípcio; e o filho da israelita e um homem israelita discutiram no arraial.',
        'Então o filho da mulher israelita blasfemou o nome do Senhor, e o amaldiçoou, por isso o trouxeram a Moisés; e o nome de sua mãe era Selomite, filha de Dibri, da tribo de Dã.',
        'E eles o puseram na prisão, até que a vontade do Senhor lhes pudesse ser declarada.',
        'E falou o Senhor a Moisés, dizendo:',
        'Tira o que tem blasfemado para fora do arraial; e todos os que o ouviram porão as suas mãos sobre a sua cabeça; então toda a congregação o apedrejará.',
        'E aos filhos de Israel falarás, dizendo: Qualquer que amaldiçoar o seu Deus, levará sobre si o seu pecado.',
        'E aquele que blasfemar o nome do Senhor, certamente morrerá; toda a congregação certamente o apedrejará; assim o estrangeiro como o natural, blasfemando o nome do Senhor, será morto.',
        'E quem matar a alguém certamente morrerá.',
        'Mas quem matar um animal, o restituirá, vida por vida.',
        'Quando também alguém desfigurar o seu próximo, como ele fez, assim lhe será feito:',
        'Quebradura por quebradura, olho por olho, dente por dente; como ele tiver desfigurado a algum homem, assim se lhe fará.',
        'Quem, pois, matar um animal, restitui-lo-á, mas quem matar um homem será morto.',
        'Uma mesma lei tereis; assim será para o estrangeiro como para o natural; pois eu sou o Senhor vosso Deus.',
        'E disse Moisés, aos filhos de Israel que levassem o que tinha blasfemado para fora do arraial, e o apedrejassem; e fizeram os filhos de Israel como o Senhor ordenara a Moisés.',
      ],
      [
        'Falou mais o SENHOR a Moisés no monte Sinai, dizendo:',
        'Fala aos filhos de Israel, e dize-lhes: Quando tiverdes entrado na terra, que eu vos dou, então a terra descansará um sábado ao Senhor.',
        'Seis anos semearás a tua terra, e seis anos podarás a tua vinha, e colherás os seus frutos;',
        'Porém ao sétimo ano haverá sábado de descanso para a terra, um sábado ao Senhor; não semearás o teu campo nem podarás a tua vinha.',
        'O que nascer de si mesmo da tua sega, não colherás, e as uvas da tua separação não vindimarás; ano de descanso será para a terra.',
        'Mas os frutos do sábado da terra vos serão por alimento, a ti, e ao teu servo, e à tua serva, e ao teu diarista, e ao estrangeiro que peregrina contigo;',
        'E ao teu gado, e aos teus animais, que estão na tua terra, todo o seu produto será por mantimento.',
        'Também contarás sete semanas de anos, sete vezes sete anos; de maneira que os dias das sete semanas de anos te serão quarenta e nove anos.',
        'Então no mês sétimo, aos dez do mês, farás passar a trombeta do jubileu; no dia da expiação fareis passar a trombeta por toda a vossa terra,',
        'E santificareis o ano qüinquagésimo, e apregoareis liberdade na terra a todos os seus moradores; ano de jubileu vos será, e tornareis, cada um à sua possessão, e cada um à sua família.',
        'O ano qüinquagésimo vos será jubileu; não semeareis nem colhereis o que nele nascer de si mesmo, nem nele vindimareis as uvas das separações,',
        'Porque jubileu é, santo será para vós; a novidade do campo comereis.',
        'Neste ano do jubileu tornareis cada um à sua possessão.',
        'E quando venderdes alguma coisa ao vosso próximo, ou a comprardes da mão do vosso próximo, ninguém engane a seu irmão;',
        'Conforme ao número dos anos, desde o jubileu, comprarás ao teu próximo; e conforme o número dos anos das colheitas, ele a venderá a ti.',
        'Conforme se multipliquem os anos, aumentarás o seu preço, e conforme à diminuição dos anos abaixarás o seu preço; porque conforme o número das colheitas é que ele te vende.',
        'Ninguém, pois, engane ao seu próximo; mas terás temor do teu Deus; porque eu sou o Senhor vosso Deus.',
        'E observareis os meus estatutos, e guardareis os meus juízos, e os cumprireis; assim habitareis seguros na terra.',
        'E a terra dará o seu fruto, e comereis a fartar, e nela habitareis seguros.',
        'E se disserdes: Que comeremos no ano sétimo? eis que não havemos de semear nem fazer a nossa colheita;',
        'Então eu mandarei a minha bênção sobre vós no sexto ano, para que dê fruto por três anos,',
        'E no oitavo ano semeareis, e comereis da colheita velha até ao ano nono; até que venha a nova colheita, comereis a velha.',
        'Também a terra não se venderá em perpetuidade, porque a terra é minha; pois vós sois estrangeiros e peregrinos comigo.',
        'Portanto em toda a terra da vossa possessão dareis resgate à terra.',
        'Quando teu irmão empobrecer e vender alguma parte da sua possessão, então virá o seu resgatador, seu parente, e resgatará o que vendeu seu irmão.',
        'E se alguém não tiver resgatador, porém conseguir o suficiente para o seu resgate,',
        'Então contará os anos desde a sua venda, e o que ficar restituirá ao homem a quem a vendeu, e tornará à sua possessão.',
        'Mas se não conseguir o suficiente para restituir-lha, então a que foi vendida ficará na mão do comprador até ao ano do jubileu; porém no ano do jubileu sairá, e ele tornará à sua possessão.',
        'E, quando alguém vender uma casa de moradia em cidade murada, então poderá resgatá-la até que se cumpra o ano da sua venda; durante um ano inteiro será lícito o seu resgate.',
        'Mas, se, cumprindo-se-lhe um ano inteiro, ainda não for resgatada, então a casa, que estiver na cidade que tem muro, em perpetuidade ficará ao que a comprou, pelas suas gerações; não sairá no jubileu.',
        'Mas as casas das aldeias que não têm muro ao redor, serão estimadas como o campo da terra; para elas haverá resgate, e sairão no jubileu.',
        'Mas, no tocante às cidades dos levitas, às casas das cidades da sua possessão, direito perpétuo de resgate terão os levitas.',
        'E se alguém comprar dos levitas, uma casa, a casa comprada e a cidade da sua possessão sairão do poder do comprador no jubileu; porque as casas das cidades dos levitas são a sua possessão no meio dos filhos de Israel.',
        'Mas o campo do arrabalde das suas cidades não se venderá, porque lhes é possessão perpétua.',
        'E, quando teu irmão empobrecer, e as suas forças decaírem, então sustentá-lo-ás, como estrangeiro e peregrino viverá contigo.',
        'Não tomarás dele juros, nem ganho; mas do teu Deus terás temor, para que teu irmão viva contigo.',
        'Não lhe darás teu dinheiro com usura, nem darás do teu alimento por interesse.',
        'Eu sou o Senhor vosso Deus, que vos tirei da terra do Egito, para vos dar a terra de Canaã, para ser vosso Deus.',
        'Quando também teu irmão empobrecer, estando ele contigo, e vender-se a ti, não o farás servir como escravo.',
        'Como diarista, como peregrino estará contigo; até ao ano do jubileu te servirá;',
        'Então sairá do teu serviço, ele e seus filhos com ele, e tornará à sua família e à possessão de seus pais.',
        'Porque são meus servos, que tirei da terra do Egito; não serão vendidos como se vendem os escravos.',
        'Não te assenhorearás dele com rigor, mas do teu Deus terás temor.',
        'E quanto a teu escravo ou a tua escrava que tiveres, serão das nações que estão ao redor de vós; deles comprareis escravos e escravas.',
        'Também os comprareis dos filhos dos forasteiros que peregrinam entre vós, deles e das suas famílias que estiverem convosco, que tiverem gerado na vossa terra; e vos serão por possessão.',
        'E possuí-los-eis por herança para vossos filhos depois de vós, para herdarem a possessão; perpetuamente os fareis servir; mas sobre vossos irmãos, os filhos de Israel, não vos assenhoreareis com rigor, uns sobre os outros.',
        'E se o estrangeiro ou peregrino que está contigo alcançar riqueza, e teu irmão, que está com ele, empobrecer, e vender-se ao estrangeiro ou peregrino que está contigo, ou a alguém da família do estrangeiro,',
        'Depois que se houver vendido, haverá resgate para ele; um de seus irmãos o poderá resgatar;',
        'Ou seu tio, ou o filho de seu tio o poderá resgatar; ou um dos seus parentes, da sua família, o poderá resgatar; ou, se alcançar riqueza, se resgatará a si mesmo.',
        'E acertará com aquele que o comprou, desde o ano que se vendeu a ele até ao ano do jubileu, e o preço da sua venda será conforme o número dos anos; conforme os dias de um diarista estará com ele.',
        'Se ainda faltarem muitos anos, conforme a eles restituirá, para seu resgate, parte do dinheiro pelo qual foi vendido,',
        'E se ainda restarem poucos anos até ao ano do jubileu, então fará contas com ele; segundo os seus anos restituirá o seu resgate.',
        'Como diarista, de ano em ano, estará com ele; não se assenhoreará sobre ele com rigor diante dos teus olhos.',
        'E, se desta sorte não se resgatar, sairá no ano do jubileu, ele e seus filhos com ele.',
        'Porque os filhos de Israel me são servos; meus servos são eles, que tirei da terra do Egito. Eu sou o Senhor vosso Deus.',
      ],
      [
        'Não fareis para vós ídolos, nem vos levantareis imagem de escultura, nem estátua, nem poreis pedra figurada na vossa terra, para inclinar-vos a ela; porque eu sou o SENHOR vosso Deus.',
        'Guardareis os meus sábados, e reverenciareis o meu santuário. Eu sou o Senhor.',
        'Se andardes nos meus estatutos, e guardardes os meus mandamentos, e os cumprirdes,',
        'Então eu vos darei as chuvas a seu tempo; e a terra dará a sua colheita, e a árvore do campo dará o seu fruto;',
        'E a debulha se vos chegará à vindima, e a vindima se chegará à sementeira; e comereis o vosso pão a fartar, e habitareis seguros na vossa terra.',
        'Também darei paz na terra, e dormireis seguros, e não haverá quem vos espante; e farei cessar os animais nocivos da terra, e pela vossa terra não passará espada.',
        'E perseguireis os vossos inimigos, e cairão à espada diante de vós.',
        'Cinco de vós perseguirão a um cento deles, e cem de vós perseguirão a dez mil; e os vossos inimigos cairão à espada diante de vós.',
        'E para vós olharei, e vos farei frutificar, e vos multiplicarei, e confirmarei a minha aliança convosco.',
        'E comereis da colheita velha, há muito tempo guardada, e tirareis fora a velha por causa da nova.',
        'E porei o meu tabernáculo no meio de vós, e a minha alma de vós não se enfadará.',
        'E andarei no meio de vós, e eu vos serei por Deus, e vós me sereis por povo.',
        'Eu sou o Senhor vosso Deus, que vos tirei da terra dos egípcios, para que não fôsseis seus escravos; e quebrei os timões do vosso jugo, e vos fiz andar eretos.',
        'Mas, se não me ouvirdes, e não cumprirdes todos estes mandamentos,',
        'E se rejeitardes os meus estatutos, e a vossa alma se enfadar dos meus juízos, não cumprindo todos os meus mandamentos, para invalidar a minha aliança,',
        'Então eu também vos farei isto: porei sobre vós terror, a tísica e a febre ardente, que consumam os olhos e atormentem a alma; e semeareis em vão a vossa semente, pois os vossos inimigos a comerão.',
        'E porei a minha face contra vós, e sereis feridos diante de vossos inimigos; e os que vos odeiam, de vós se assenhorearão, e fugireis, sem ninguém vos perseguir.',
        'E, se ainda com estas coisas não me ouvirdes, então eu prosseguirei a castigar-vos sete vezes mais, por causa dos vossos pecados.',
        'Porque quebrarei a soberba da vossa força; e farei que os vossos céus sejam como ferro e a vossa terra como cobre.',
        'E em vão se gastará a vossa força; a vossa terra não dará a sua colheita, e as árvores da terra não darão o seu fruto.',
        'E se andardes contrariamente para comigo, e não me quiserdes ouvir, trar-vos-ei pragas sete vezes mais, conforme os vossos pecados.',
        'Porque enviarei entre vós as feras do campo, as quais vos desfilharão, e desfarão o vosso gado, e vos diminuirão; e os vossos caminhos serão desertos.',
        'Se ainda com estas coisas não vos corrigirdes voltando para mim, mas ainda andardes contrariamente para comigo,',
        'Eu também andarei contrariamente para convosco, e eu, eu mesmo, vos ferirei sete vezes mais por causa dos vossos pecados.',
        'Porque trarei sobre vós a espada, que executará a vingança da aliança; e ajuntados sereis nas vossas cidades; então enviarei a peste entre vós, e sereis entregues na mão do inimigo.',
        'Quando eu vos quebrar o sustento do pão, então dez mulheres cozerão o vosso pão num só forno, e devolver-vos-ão o vosso pão por peso; e comereis, mas não vos fartareis.',
        'E se com isto não me ouvirdes, mas ainda andardes contrariamente para comigo,',
        'Também eu para convosco andarei contrariamente em furor; e vos castigarei sete vezes mais por causa dos vossos pecados.',
        'Porque comereis a carne de vossos filhos, e a carne de vossas filhas.',
        'E destruirei os vossos altos, e desfarei as vossas imagens, e lançarei os vossos cadáveres sobre os cadáveres dos vossos deuses; a minha alma se enfadará de vós.',
        'E reduzirei as vossas cidades a deserto, e assolarei os vossos santuários, e não cheirarei o vosso cheiro suave.',
        'E assolarei a terra e se espantarão disso os vossos inimigos que nela morarem.',
        'E espalhar-vos-ei entre as nações, e desembainharei a espada atrás de vós; e a vossa terra será assolada, e as vossas cidades serão desertas.',
        'Então a terra folgará nos seus sábados, todos os dias da sua assolação, e vós estareis na terra dos vossos inimigos; então a terra descansará, e folgará nos seus sábados.',
        'Todos os dias da assolação descansará, porque não descansou nos vossos sábados, quando habitáveis nela.',
        'E, quanto aos que de vós ficarem, eu porei tal pavor nos seus corações, nas terras dos seus inimigos, que o ruído de uma folha movida os perseguirá; e fugirão como quem foge da espada; e cairão sem ninguém os perseguir.',
        'E cairão uns sobre os outros como diante da espada, sem ninguém os perseguir; e não podereis resistir diante dos vossos inimigos.',
        'E perecereis entre as nações, e a terra dos vossos inimigos vos consumirá.',
        'E aqueles que entre vós ficarem se consumirão pela sua iniqüidade nas terras dos vossos inimigos, e pela iniqüidade de seus pais com eles se consumirão.',
        'Então confessarão a sua iniqüidade, e a iniqüidade de seus pais, com as suas transgressões, com que transgrediram contra mim; como também eles andaram contrariamente para comigo.',
        'Eu também andei para com eles contrariamente, e os fiz entrar na terra dos seus inimigos; se então o seu coração incircunciso se humilhar, e então tomarem por bem o castigo da sua iniqüidade,',
        'Também eu me lembrarei da minha aliança com Jacó, e também da minha aliança com Isaque, e também da minha aliança com Abraão me lembrarei, e da terra me lembrarei.',
        'E a terra será abandonada por eles, e folgará nos seus sábados, sendo assolada por causa deles; e tomarão por bem o castigo da sua iniqüidade, em razão mesmo de que rejeitaram os meus juízos e a sua alma se enfastiou dos meus estatutos.',
        'E, demais disto também, estando eles na terra dos seus inimigos, não os rejeitarei nem me enfadarei deles, para consumi-los e invalidar a minha aliança com eles, porque eu sou o Senhor seu Deus.',
        'Antes por amor deles me lembrarei da aliança com os seus antepassados, que tirei da terra do Egito perante os olhos dos gentios, para lhes ser por Deus. Eu sou o Senhor.',
        'Estes são os estatutos, e os juízos, e as leis que deu o Senhor entre si e os filhos de Israel, no monte Sinai, pela mão de Moisés.',
      ],
      [
        'Falou mais o SENHOR a Moisés, dizendo:',
        'Fala aos filhos de Israel, e dize-lhes: Quando alguém fizer particular voto, segundo a tua avaliação serão as pessoas ao Senhor.',
        'Se for a tua avaliação de um homem, da idade de vinte anos até a idade de sessenta, será a tua avaliação de cinqüenta siclos de prata, segundo o siclo do santuário.',
        'Porém, se for mulher, a tua avaliação será de trinta siclos.',
        'E, se for de cinco anos até vinte, a tua avaliação de um homem será vinte siclos e da mulher dez siclos.',
        'E, se for de um mês até cinco anos, a tua avaliação de um homem será de cinco siclos de prata, e a tua avaliação pela mulher será de três siclos de prata.',
        'E, se for de sessenta anos e acima, pelo homem a tua avaliação será de quinze siclos e pela mulher dez siclos.',
        'Mas, se for mais pobre do que a tua avaliação, então apresentar-se-á diante do sacerdote, para que o sacerdote o avalie; conforme as posses daquele que fez o voto, o avaliará o sacerdote.',
        'E, se for animal dos que se oferecem em oferta ao Senhor, tudo quanto der dele ao Senhor será santo.',
        'Não o mudará, nem o trocará bom por mau, ou mau por bom; se porém de alguma maneira trocar animal por animal, tanto um como o outro, será santo.',
        'E, se for algum animal imundo, dos que não se oferecem em oferta ao Senhor, então apresentará o animal diante do sacerdote,',
        'E o sacerdote o avaliará, seja bom ou seja mau; segundo a avaliação do sacerdote, assim será.',
        'Porém, se de alguma maneira o resgatar, então acrescentará a sua quinta parte sobre a tua avaliação.',
        'E quando alguém santificar a sua casa para ser santa ao Senhor, o sacerdote a avaliará, seja boa ou seja má; como o sacerdote a avaliar, assim será.',
        'Mas, se o que a santificou resgatar a sua casa, então acrescentará a quinta parte do dinheiro sobre a tua avaliação, e será sua.',
        'Se também alguém santificar ao Senhor uma parte do campo da sua possessão, então a tua avaliação será segundo a sua semente: um ômer de semente de cevada será avaliado por cinqüenta siclos de prata.',
        'Se santificar o seu campo desde o ano do jubileu, conforme à tua avaliação ficará.',
        'Mas, se santificar o seu campo depois do ano do jubileu, então o sacerdote lhe contará o dinheiro conforme aos anos restantes até ao ano do jubileu, e isto se abaterá da tua avaliação.',
        'E se aquele que santificou o campo de alguma maneira o resgatar, então acrescentará a quinta parte do dinheiro da tua avaliação, e ficará seu.',
        'E se não resgatar o campo, ou se vender o campo a outro homem, nunca mais se resgatará.',
        'Porém havendo o campo saído no ano do jubileu, será santo ao Senhor, como campo consagrado; a possessão dele será do sacerdote.',
        'E se alguém santificar ao Senhor o campo que comprou, e não for parte do campo da sua possessão,',
        'Então o sacerdote lhe contará o valor da tua avaliação até ao ano do jubileu; e no mesmo dia dará a tua avaliação como coisa santa ao Senhor.',
        'No ano do jubileu o campo tornará àquele de quem o comprou, àquele de quem era a possessão do campo.',
        'E toda a tua avaliação se fará conforme ao siclo do santuário; o siclo será de vinte geras.',
        'Mas o primogênito de um animal, por já ser do SENHOR, ninguém o santificará; seja boi ou gado miúdo, do SENHOR é.',
        'Mas, se for de um animal imundo, o resgatará, segundo a tua estimação, e sobre ele acrescentará a sua quinta parte; e se não se resgatar, vender-se-á segundo a tua estimação.',
        'Todavia, nenhuma coisa consagrada, que alguém consagrar ao Senhor de tudo o que tem, de homem, ou de animal, ou do campo da sua possessão, se venderá nem resgatará; toda a coisa consagrada será santíssima ao Senhor.',
        'Toda a coisa consagrada que for consagrada do homem, não será resgatada; certamente morrerá.',
        'Também todas as dízimas do campo, da semente do campo, do fruto das árvores, são do Senhor; santas são ao Senhor.',
        'Porém, se alguém das suas dízimas resgatar alguma coisa, acrescentará a sua quinta parte sobre ela.',
        'No tocante a todas as dízimas do gado e do rebanho, tudo o que passar debaixo da vara, o dízimo será santo ao Senhor.',
        'Não se investigará entre o bom e o mau, nem o trocará; mas, se de alguma maneira o trocar, tanto um como o outro será santo; não serão resgatados.',
        'Estes são os mandamentos que o Senhor ordenou a Moisés, para os filhos de Israel, no monte Sinai.',
      ],
    ],
    livro: 'levitico',
  },
  {
    abbrev: 'nm',
    capitulos: [
      [
        'Falou mais o SENHOR a Moisés no deserto de Sinai, na tenda da congregação, no primeiro dia do segundo mês, no segundo ano da sua saída da terra do Egito, dizendo:',
        'Tomai a soma de toda a congregação dos filhos de Israel, segundo as suas famílias, segundo a casa de seus pais, conforme o número dos nomes de todo o homem, cabeça por cabeça;',
        'Da idade de vinte anos para cima, todos os que em Israel podem sair à guerra, a estes contareis segundo os seus exércitos, tu e Arão.',
        'Estará convosco, de cada tribo, um homem que seja cabeça da casa de seus pais.',
        'Estes, pois, são os nomes dos homens que estarão convosco: De Rúben, Elizur, filho de Sedeur;',
        'De Simeão, Selumiel, filho de Zurisadai;',
        'De Judá, Naasson, filho de Aminadabe;',
        'De Issacar, Natanael, filho de Zuar;',
        'De Zebulom, Eliabe, filho de Helom;',
        'Dos filhos de José: De Efraim, Elisama, filho de Amiúde; de Manassés, Gamaliel, filho de Pedazur;',
        'De Benjamim, Abidã, filho de Gideoni;',
        'De Dã, Aieser, filho de Amisadai;',
        'De Aser, Pagiel, filho de Ocrã;',
        'De Gade, Eliasafe, filho de Deuel;',
        'De Naftali, Aira, filho de Enã.',
        'Estes foram os chamados da congregação, os príncipes das tribos de seus pais, os cabeças dos milhares de Israel.',
        'Então tomaram Moisés e Arão a estes homens, que foram declarados pelos seus nomes,',
        'E reuniram toda a congregação no primeiro dia do mês segundo, e declararam a sua descendência segundo as suas famílias, segundo a casa de seus pais, pelo número dos nomes dos de vinte anos para cima, cabeça por cabeça;',
        'Como o Senhor ordenara a Moisés, assim os contou no deserto de Sinai.',
        'Foram, pois, os filhos de Rúben, o primogênito de Israel, as suas gerações, pelas suas famílias, segundo a casa de seus pais, pelo número dos nomes, cabeça por cabeça, todo o homem de vinte anos para cima, todos os que podiam sair à guerra,',
        'Foram contados deles, da tribo de Rúben, quarenta e seis mil e quinhentos.',
        'Dos filhos de Simeão, as suas gerações pelas suas famílias, segundo a casa dos seus pais; os seus contados, pelo número dos nomes, cabeça por cabeça, todo o homem de vinte anos para cima, todos os que podiam sair à guerra,',
        'Foram contados deles, da tribo de Simeão, cinqüenta e nove mil e trezentos.',
        'Dos filhos de Gade, as suas gerações, pelas suas famílias, segundo a casa de seus pais, pelo número dos nomes dos de vinte anos para cima, todos os que podiam sair à guerra,',
        'Foram contados deles, da tribo de Gade, quarenta e cinco mil e seiscentos e cinqüenta.',
        'Dos filhos de Judá, as suas gerações, pelas suas famílias, segundo a casa de seus pais; pelo número dos nomes dos de vinte anos para cima, todos os que podiam sair à guerra,',
        'Foram contados deles, da tribo de Judá, setenta e quatro mil e seiscentos.',
        'Dos filhos de Issacar, as suas gerações, pelas suas famílias, segundo a casa de seus pais, pelo número dos nomes dos de vinte anos para cima, todos os que podiam sair à guerra,',
        'Foram contados deles da tribo de Issacar, cinqüenta e quatro mil e quatrocentos.',
        'Dos filhos de Zebulom, as suas gerações, pelas suas famílias, segundo a casa de seus pais, pelo número dos nomes dos de vinte anos para cima, todos os que podiam sair à guerra,',
        'Foram contados deles, da tribo de Zebulom, cinqüenta e sete mil e quatrocentos.',
        'Dos filhos de José, dos filhos de Efraim, as suas gerações, pelas suas famílias, segundo a casa de seus pais, pelo número dos nomes dos de vinte anos para cima, todos os que podiam sair à guerra,',
        'Foram contados deles, da tribo de Efraim, quarenta mil e quinhentos.',
        'Dos filhos de Manassés, as suas gerações, pelas suas famílias, segundo a casa de seus pais, pelo número dos nomes dos de vinte anos para cima, todos os que podiam sair à guerra,',
        'Foram contados deles, da tribo de Manassés, trinta e dois mil e duzentos.',
        'Dos filhos de Benjamim, as suas gerações, pelas suas famílias, segundo a casa de seus pais, pelo número dos nomes dos de vinte anos para cima, todos os que podiam sair à guerra,',
        'Foram contados deles, da tribo de Benjamim, trinta e cinco mil e quatrocentos.',
        'Dos filhos de Dã, as suas gerações, pelas suas famílias, segundo a casa de seus pais, pelo número dos nomes dos de vinte anos para cima, todos os que podiam sair à guerra,',
        'Foram contados deles, da tribo de Dã, sessenta e dois mil e setecentos.',
        'Dos filhos de Aser, as suas gerações, pelas suas famílias, segundo a casa de seus pais, pelo número dos nomes dos de vinte anos para cima, todos os que podiam sair à guerra,',
        'Foram contados deles, da tribo de Aser, quarenta e um mil e quinhentos.',
        'Dos filhos de Naftali, as suas gerações, pelas suas famílias, segundo a casa de seus pais, pelo número dos nomes dos de vinte anos para cima, todos os que podiam sair à guerra,',
        'Foram contados deles, da tribo de Naftali, cinqüenta e três mil e quatrocentos.',
        'Estes foram os contados, que contaram Moisés e Arão, e os príncipes de Israel, doze homens, cada um era pela casa de seus pais.',
        'Assim foram todos os contados dos filhos de Israel, segundo a casa de seus pais, de vinte anos para cima, todos os que podiam sair à guerra em Israel;',
        'Todos os contados eram seiscentos e três mil e quinhentos e cinqüenta.',
        'Mas os levitas, segundo a tribo de seus pais, não foram contados entre eles,',
        'Porquanto o Senhor tinha falado a Moisés, dizendo:',
        'Porém não contarás a tribo de Levi, nem tomarás a soma deles entre os filhos de Israel;',
        'Mas tu põe os levitas sobre o tabernáculo do testemunho, e sobre todos os seus utensílios, e sobre tudo o que pertence a ele; eles levarão o tabernáculo e todos os seus utensílios; e eles o administrarão, e acampar-se-ão ao redor do tabernáculo.',
        'E, quando o tabernáculo partir, os levitas o desarmarão; e, quando o tabernáculo se houver de assentar no arraial, os levitas o armarão; e o estranho que se chegar morrerá.',
        'E os filhos de Israel armarão as suas tendas, cada um no seu esquadrão, e cada um junto à sua bandeira, segundo os seus exércitos.',
        'Mas os levitas armarão as suas tendas ao redor do tabernáculo do testemunho, para que não haja indignação sobre a congregação dos filhos de Israel, pelo que os levitas terão o cuidado da guarda do tabernáculo do testemunho.',
        'Assim fizeram os filhos de Israel; conforme a tudo o que o Senhor ordenara a Moisés, assim o fizeram.',
      ],
      [
        'E falou o SENHOR a Moisés e a Arão, dizendo:',
        'Os filhos de Israel armarão as suas tendas, cada um debaixo da sua bandeira, segundo as insígnias da casa de seus pais; ao redor, defronte da tenda da congregação, armarão as suas tendas.',
        'Os que armarem as suas tendas do lado do oriente, para o nascente, serão os da bandeira do exército de Judá, segundo os seus esquadrões, e Naassom, filho de Aminadabe, será príncipe dos filhos de Judá.',
        'E o seu exército, os que foram contados deles, era de setenta e quatro mil e seiscentos.',
        'E junto a ele armará as suas tendas a tribo de Issacar; e Natanael, filho de Zuar, será príncipe dos filhos de Issacar.',
        'E o seu exército, os que foram contados deles, era de cinqüenta e quatro mil e quatrocentos.',
        'Depois a tribo de Zebulom; e Eliabe, filho de Helam, será príncipe dos filhos de Zebulom.',
        'E o seu exército, os que foram contados deles, era de cinqüenta e sete mil e quatrocentos.',
        'Todos os que foram contados do exército de Judá, cento e oitenta e seis mil e quatrocentos, segundo os seus esquadrões, estes marcharão primeiro.',
        'A bandeira do exército de Rúben, segundo os seus esquadrões, estará para o lado do sul; e Elizur, filho de Sedeur, será príncipe dos filhos de Rúben,',
        'E o seu exército, os que foram contados deles, era de quarenta e seis mil e quinhentos.',
        'E junto a ele armará as suas tendas a tribo de Simeão; e Selumiel, filho de Zurisadai, será príncipe dos filhos de Simeão.',
        'E o seu exército, os que foram contados deles, era de cinqüenta e nove mil e trezentos.',
        'Depois a tribo de Gade; e Eliasafe, filho de Reuel, será príncipe dos filhos de Gade.',
        'E o seu exército, os que foram contados deles, era de quarenta e cinco mil e seiscentos e cinqüenta.',
        'Todos os que foram contados no exército de Rúben foram cento e cinqüenta e um mil e quatrocentos e cinqüenta, segundo os seus esquadrões; e estes marcharão em segundo lugar.',
        'Então partirá a tenda da congregação com o exército dos levitas no meio dos exércitos; como armaram as suas tendas, assim marcharão, cada um no seu lugar, segundo as suas bandeiras.',
        'A bandeira do exército de Efraim segundo os seus esquadrões, estará para o lado do ocidente; e Elisama, filho de Amiúde, será príncipe dos filhos de Efraim.',
        'E o seu exército, os que foram contados deles, era de quarenta mil e quinhentos.',
        'E junto a ele estará a tribo de Manassés; e Gamaliel, filho de Pedazur, será príncipe dos filhos de Manassés.',
        'E o seu exército, os que foram contados deles, era de trinta e dois mil e duzentos.',
        'Depois a tribo de Benjamim; e Abidã, filho de Gideoni, será príncipe dos filhos de Benjamim,',
        'E o seu exército, os que foram contados deles, era de trinta e cinco mil e quatrocentos.',
        'Todos os que foram contados no exército de Efraim foram cento e oito mil e cem, segundo os seus esquadrões; e estes marcharão em terceiro lugar.',
        'A bandeira do exército de Dã estará para o norte, segundo os seus esquadrões; e Aieser, filho de Amisadai, será príncipe dos filhos de Dã.',
        'E o seu exército, os que foram contados deles, era de sessenta e dois mil e setecentos.',
        'E junto a ele armará as suas tendas a tribo de Aser; e Pagiel, filho de Ocrã, será príncipe dos filhos de Aser.',
        'E o seu exército, os que foram contados deles, era de quarenta e um mil e quinhentos.',
        'Depois a tribo de Naftali; e Aira, filho de Enã, será príncipe dos filhos de Naftali.',
        'E o seu exército, os que foram contados deles, era de cinqüenta e três mil e quatrocentos.',
        'Todos os que foram contados no exército de Dã foram cento e cinqüenta e sete mil e seiscentos; estes marcharão em último lugar, segundo as suas bandeiras.',
        'Estes são os que foram contados dos filhos de Israel, segundo a casa de seus pais; todos os que foram contados dos exércitos pelos seus esquadrões foram seiscentos e três mil e quinhentos e cinqüenta.',
        'Mas os levitas não foram contados entre os filhos de Israel, como o Senhor ordenara a Moisés.',
        'E os filhos de Israel fizeram conforme a tudo o que o Senhor ordenara a Moisés; assim armaram o arraial segundo as suas bandeiras, e assim marcharam, cada qual segundo as suas gerações, segundo a casa de seus pais.',
      ],
      [
        'E estas são as gerações de Arão e de Moisés, no dia em que o SENHOR falou com Moisés, no monte Sinai.',
        'E estes são os nomes dos filhos de Arão: o primogênito Nadabe; depois Abiú, Eleazar e Itamar.',
        'Estes são os nomes dos filhos de Arão, dos sacerdotes ungidos, cujas mãos foram consagradas para administrar o sacerdócio.',
        'Mas Nadabe e Abiú morreram perante o Senhor, quando ofereceram fogo estranho perante o Senhor no deserto de Sinai, e não tiveram filhos; porém Eleazar e Itamar administraram o sacerdócio diante de Arão, seu pai.',
        'E falou o Senhor a Moisés, dizendo:',
        'Faze chegar a tribo de Levi, e põe-na diante de Arão, o sacerdote, para que o sirvam,',
        'E tenham cuidado da sua guarda, e da guarda de toda a congregação, diante da tenda da congregação, para administrar o ministério do tabernáculo.',
        'E tenham cuidado de todos os utensílios da tenda da congregação, e da guarda dos filhos de Israel, para administrar o ministério do tabernáculo.',
        'Darás, pois, os levitas a Arão e a seus filhos; dentre os filhos de Israel lhes são dados em dádiva.',
        'Mas a Arão e a seus filhos ordenarás que guardem o seu sacerdócio, e o estranho que se chegar morrerá.',
        'E falou o Senhor a Moisés, dizendo:',
        'E eu, eis que tenho tomado os levitas do meio dos filhos de Israel, em lugar de todo o primogênito, que abre a madre, entre os filhos de Israel; e os levitas serão meus.',
        'Porque todo o primogênito é meu; desde o dia em que tenho ferido a todo o primogênito na terra do Egito, santifiquei para mim todo o primogênito em Israel, desde o homem até ao animal: meus serão; Eu sou o Senhor.',
        'E falou o Senhor a Moisés no deserto de Sinai, dizendo:',
        'Conta os filhos de Levi, segundo a casa de seus pais, pelas suas famílias; contarás a todo o homem da idade de um mês para cima.',
        'E Moisés os contou conforme ao mandado do Senhor, como lhe foi ordenado.',
        'Estes, pois, foram os filhos de Levi pelos seus nomes: Gérson, e Coate e Merari.',
        'E estes são os nomes dos filhos de Gérson pelas suas famílias: Libni e Simei.',
        'E os filhos de Coate pelas suas famílias: Amrão, e Izar, Hebrom e Uziel.',
        'E os filhos de Merari pelas suas famílias: Mali e Musi; estas são as famílias dos levitas, segundo a casa de seus pais.',
        'De Gérson é a família dos libnitas e a família dos simeítas; estas são as famílias dos gersonitas.',
        'Os que deles foram contados pelo número de todo o homem da idade de um mês para cima, sim, os que deles foram contados eram sete mil e quinhentos.',
        'As famílias dos gersonitas armarão as suas tendas atrás do tabernáculo, ao ocidente.',
        'E o príncipe da casa paterna dos gersonitas será Eliasafe, filho de Lael.',
        'E os filhos de Gérson terão a seu cargo, na tenda da congregação, o tabernáculo, a tenda, a sua coberta, e o véu da porta da tenda da congregação.',
        'E as cortinas do pátio, e o pavilhão da porta do pátio, que estão junto ao tabernáculo e junto ao altar, em redor; como também as suas cordas para todo o seu serviço.',
        'E de Coate é a família dos amramitas, e a família dos jizaritas, e a família dos hebronitas, e a família dos uzielitas; estas são as famílias dos coatitas.',
        'Pelo número contado de todo o homem da idade de um mês para cima, eram oito mil e seiscentos, que tinham cuidado da guarda do santuário.',
        'As famílias dos filhos de Coate armarão as suas tendas ao lado do tabernáculo, do lado do sul.',
        'E o príncipe da casa paterna das famílias dos coatitas será Elisafã, filho de Uziel.',
        'E a sua guarda será a arca, e a mesa, e o candelabro, e os altares, e os utensílios do santuário com que ministram, e o véu com todo o seu serviço.',
        'E o príncipe dos príncipes de Levi será Eleazar, filho de Arão, o sacerdote; terá a superintendência sobre os que têm cuidado da guarda do santuário.',
        'De Merari é a família dos malitas e a família dos musitas; estas são as famílias de Merari.',
        'E os que deles foram contados pelo número de todo o homem de um mês para cima, foram seis mil e duzentos.',
        'E o príncipe da casa paterna das famílias de Merari será Zuriel, filho de Abiail; armarão as suas tendas ao lado do tabernáculo, do lado do norte.',
        'E os filhos de Merari terão a seu cargo as tábuas do tabernáculo, os seus varais, as suas colunas, as suas bases, e todos os seus utensílios, com todo o seu serviço.',
        'E as colunas do pátio em redor, e as suas bases, as suas estacas e as suas cordas.',
        'E os que armarão as suas tendas diante do tabernáculo, ao oriente, diante da tenda da congregação, para o nascente, serão Moisés e Arão, com seus filhos, tendo o cuidado da guarda do santuário, pela guarda dos filhos de Israel; e o estranho que se chegar morrerá.',
        'Todos os que foram contados dos levitas, que contaram Moisés e Arão por mandado do Senhor, segundo as suas famílias, todo o homem de um mês para cima, foram vinte e dois mil.',
        'E disse o Senhor a Moisés: Conta todo o primogênito homem dos filhos de Israel, da idade de um mês para cima, e toma o número dos seus nomes,',
        'E para mim tomarás os levitas (eu sou o Senhor), em lugar de todo o primogênito dos filhos de Israel, e os animais dos levitas, em lugar de todo o primogênito entre os animais dos filhos de Israel.',
        'E contou Moisés, como o Senhor lhe ordenara, todo o primogênito entre os filhos de Israel.',
        'E todos os primogênitos homens, pelo número dos nomes dos da idade de um mês para cima, segundo os que eram contados deles, foram vinte e dois mil e duzentos e setenta e três.',
        'E falou o Senhor a Moisés, dizendo:',
        'Toma os levitas em lugar de todo o primogênito entre os filhos de Israel, e os animais dos levitas em lugar dos seus animais; porquanto os levitas serão meus: Eu sou o Senhor.',
        'Quanto aos duzentos e setenta e três, que se houverem de resgatar dos primogênitos dos filhos de Israel, que excedem ao número dos levitas,',
        'Tomarás, por cabeça, cinco siclos; conforme ao siclo do santuário os tomarás, a vinte geras o siclo.',
        'E a Arão e a seus filhos darás o dinheiro dos resgatados, dos que sobram entre eles.',
        'Então Moisés tomou o dinheiro do resgate dos que excederam sobre os resgatados pelos levitas.',
        'Dos primogênitos dos filhos de Israel recebeu o dinheiro, mil e trezentos e sessenta e cinco siclos, segundo o siclo do santuário.',
        'E Moisés deu o dinheiro dos resgatados a Arão e a seus filhos, segundo o mandado do Senhor, como o Senhor ordenara a Moisés.',
      ],
      [
        'E falou o SENHOR a Moisés e a Arão, dizendo:',
        'Fazei a soma dos filhos de Coate, dentre os filhos de Levi, pelas suas famílias, segundo a casa de seus pais;',
        'Da idade de trinta anos para cima até aos cinqüenta anos, será todo aquele que entrar neste serviço, para fazer o trabalho na tenda da congregação.',
        'Este será o ministério dos filhos de Coate na tenda da congregação, nas coisas santíssimas.',
        'Quando partir o arraial, Arão e seus filhos virão e tirarão o véu da tenda, e com ele cobrirão a arca do testemunho;',
        'E pôr-lhe-ão por cima uma coberta de peles de texugos, e sobre ela estenderão um pano, todo azul, e lhe colocarão os varais.',
        'Também sobre a mesa da proposição estenderão um pano azul; e sobre ela porão os pratos, as colheres, e as taças e os jarros para libação; também o pão contínuo estará sobre ela.',
        'Depois estenderão em cima deles um pano de carmesim, e com a coberta de peles de texugos o cobrirão, e lhe colocarão os seus varais.',
        'Então tomarão um pano azul, e cobrirão o candelabro da luminária, e as suas lâmpadas, e os seus espevitadores, e os seus apagadores, e todos os seus vasos de azeite, com que o servem.',
        'E envolverão, a ele e a todos os seus utensílios, na coberta de peles de texugos; e o colocarão sobre os varais.',
        'E sobre o altar de ouro estenderão um pano azul, e com a coberta de peles de texugos, o cobrirão, e lhe colocarão os seus varais.',
        'Também tomarão todos os utensílios do ministério, com que servem no santuário; e os colocarão num pano azul, e os cobrirão com uma coberta de peles de texugos, e os colocarão sobre os varais.',
        'E tirarão as cinzas do altar, e por cima dele estenderão um pano de púrpura.',
        'E sobre ele colocarão todos os seus instrumentos com que o servem: os seus braseiros, os garfos e as pás, e as bacias; todos os pertences do altar; e por cima dele estenderão uma coberta de peles de texugos, e lhe colocarão os seus varais.',
        'Havendo, pois, Arão e seus filhos, ao partir do arraial, acabado de cobrir o santuário, e todos os instrumentos do santuário, então os filhos de Coate virão para levá-lo; mas no santuário não tocarão para que não morram; este é o cargo dos filhos de Coate na tenda da congregação.',
        'Porém o cargo de Eleazar, filho de Arão, o sacerdote, será o azeite da luminária e o incenso aromático, e a contínua oferta dos alimentos, e o azeite da unção, o cargo de todo o tabernáculo, e de tudo que nele há, o santuário e os seus utensílios.',
        'E falou o Senhor a Moisés e a Arão, dizendo:',
        'Não deixareis extirpar a tribo das famílias dos coatitas do meio dos levitas.',
        'Mas isto lhes fareis, para que vivam e não morram, quando se aproximarem das coisas santíssimas: Arão e seus filhos virão, e a cada um colocarão no seu ministério e no seu cargo,',
        'Porém não entrarão a ver, quando cobrirem o santuário, para que não morram.',
        'Falou mais o Senhor a Moisés, dizendo:',
        'Fazei também a soma dos filhos de Gérson, segundo a casa de seus pais, segundo as suas famílias:',
        'Da idade de trinta anos para cima até aos cinqüenta, contarás a todo aquele que entrar a se ocupar no seu serviço, para executar o ministério na tenda da congregação.',
        'Este será o ministério das famílias dos gersonitas no serviço e no cargo.',
        'Levarão, pois, as cortinas do tabernáculo, e a tenda da congregação, e a sua coberta, e a coberta de peles de texugos, que está por cima dele, e a cortina da porta da tenda da congregação,',
        'E as cortinas do pátio, e a cortina da porta do pátio, que está junto ao tabernáculo, e junto ao altar em redor, e as suas cordas, e todos os instrumentos do seu ministério, com tudo o que diz respeito a eles, para que sirvam.',
        'Todo o ministério dos filhos dos gersonitas, em todo o seu cargo, e em todo o seu trabalho, será segundo o mandado de Arão e de seus filhos; e lhes designareis as responsabilidades do seu cargo.',
        'Este é o ministério das famílias dos filhos dos gersonitas na tenda da congregação; e a sua guarda será debaixo da mão de Itamar, filho de Arão, o sacerdote.',
        'Quanto aos filhos de Merari, segundo as suas famílias e segundo a casa de seus pais os contarás;',
        'Da idade de trinta anos para cima, até aos cinqüenta, contarás a todo aquele que entrar neste serviço, para administrar o ministério da tenda da congregação.',
        'Esta, pois, será a responsabilidade do seu cargo, segundo todo o seu ministério, na tenda da congregação: As tábuas do tabernáculo, e os seus varais, e as suas colunas, e as suas bases;',
        'Como também as colunas do pátio em redor, e as suas bases, e as suas estacas, e as suas cordas, com todos os seus instrumentos, e com todo o seu ministério; e contareis os objetos que ficarão a seu cargo, nome por nome.',
        'Este é o ministério das famílias dos filhos de Merari, segundo todo o seu ministério, na tenda da congregação, debaixo da mão de Itamar, filho de Arão, o sacerdote.',
        'Moisés, pois, e Arão e os príncipes da congregação contaram os filhos dos coatitas, segundo as suas famílias e segundo a casa de seus pais;',
        'Da idade de trinta anos para cima, até aos cinqüenta, todo aquele que entrou neste serviço, para o ministério da tenda da congregação.',
        'Os que deles foram contados, pois, segundo as suas famílias, foram dois mil e setecentos e cinqüenta.',
        'Estes são os que foram contados das famílias dos coatitas, de todo aquele que ministrava na tenda da congregação, os quais Moisés e Arão contaram, conforme ao mandado do Senhor pela mão de Moisés.',
        'Semelhantemente os que foram contados dos filhos de Gérson, segundo as suas famílias, e segundo a casa de seus pais;',
        'Da idade de trinta anos para cima até aos cinqüenta, todo aquele que entrou neste serviço, para o ministério na tenda da congregação.',
        'Os que deles foram contados, segundo as suas famílias, segundo a casa de seus pais, foram dois mil e seiscentos e trinta.',
        'Estes são os contados das famílias dos filhos de Gérson, de todo aquele que ministrava na tenda da congregação; os quais Moisés e Arão contaram, conforme ao mandado do Senhor.',
        'E os que foram contados das famílias dos filhos de Merari, segundo as suas famílias, segundo a casa de seus pais;',
        'Da idade de trinta anos para cima, até aos cinqüenta, todo aquele que entrou neste serviço, para o ministério na tenda da congregação.',
        'Os que deles foram contados, segundo as suas famílias, eram três mil e duzentos.',
        'Estes são os contados das famílias dos filhos de Merari; os quais Moisés e Arão contaram, conforme ao mandado do Senhor, pela mão de Moisés.',
        'Todos os que deles foram contados, que contaram Moisés e Arão, e os príncipes de Israel, dos levitas, segundo as suas famílias, segundo a casa de seus pais;',
        'Da idade de trinta anos para cima, até aos cinqüenta, todo aquele que entrava a executar o ministério da administração, e o ministério das cargas na tenda da congregação.',
        'Os que deles foram contados foram oito mil quinhentos e oitenta.',
        'Conforme ao mandado do Senhor, pela mão de Moisés, foram contados cada qual segundo o seu ministério, e segundo o seu cargo; assim foram contados por ele, como o Senhor ordenara a Moisés.',
      ],
      [
        'E falou o SENHOR a Moisés, dizendo:',
        'Ordena aos filhos de Israel que lancem fora do arraial a todo o leproso, e a todo o que padece fluxo, e a todos os imundos por causa de contato com algum morto.',
        'Desde o homem até a mulher os lançareis; fora do arraial os lançareis; para que não contaminem os seus arraiais, no meio dos quais eu habito.',
        'E os filhos de Israel fizeram assim, e os lançaram fora do arraial; como o Senhor falara a Moisés, assim fizeram os filhos de Israel.',
        'Falou mais o Senhor a Moisés, dizendo:',
        'Dize aos filhos de Israel: Quando homem ou mulher fizer algum de todos os pecados humanos, transgredindo contra o Senhor, tal alma culpada é.',
        'E confessará o seu pecado que cometeu; pela sua culpa, fará plena restituição, segundo a soma total, e lhe acrescentará a sua quinta parte, e a dará àquele contra quem se fez culpado.',
        'Mas, se aquele homem não tiver resgatador, a quem se restitua a culpa, então a culpa que se restituir ao Senhor será do sacerdote, além do carneiro da expiação pelo qual por ele se fará expiação.',
        'Semelhantemente toda a oferta de todas as coisas santificadas dos filhos de Israel, que trouxerem ao sacerdote, será sua.',
        'E as coisas santificadas de cada um serão suas; o que alguém der ao sacerdote será seu.',
        'Falou mais o Senhor a Moisés, dizendo:',
        'Fala aos filhos de Israel, e dize-lhes: Quando a mulher de alguém se desviar, e transgredir contra ele,',
        'De maneira que algum homem se tenha deitado com ela, e for oculto aos olhos de seu marido, e ela o tiver ocultado, havendo-se ela contaminado, e contra ela não houver testemunha, e no feito não for apanhada,',
        'E o espírito de ciúmes vier sobre ele, e de sua mulher tiver ciúmes, por ela se haver contaminado, ou sobre ele vier o espírito de ciúmes, e de sua mulher tiver ciúmes, não se havendo ela contaminado,',
        'Então aquele homem trará a sua mulher perante o sacerdote, e juntamente trará a sua oferta por ela; uma décima de efa de farinha de cevada, sobre a qual não deitará azeite, nem sobre ela porá incenso, porquanto é oferta de alimentos por ciúmes, oferta memorativa, que traz a iniqüidade em memória.',
        'E o sacerdote a fará chegar, e a porá perante a face do Senhor.',
        'E o sacerdote tomará água santa num vaso de barro; também tomará o sacerdote do pó que houver no chão do tabernáculo, e o deitará na água.',
        'Então o sacerdote apresentará a mulher perante o Senhor, e descobrirá a cabeça da mulher; e a oferta memorativa, que é a oferta por ciúmes, porá sobre as suas mãos, e a água amarga, que traz consigo a maldição, estará na mão do sacerdote.',
        'E o sacerdote a fará jurar, e dirá àquela mulher: Se ninguém contigo se deitou, e se não te apartaste de teu marido pela imundícia, destas águas amargas, amaldiçoantes, serás livre.',
        'Mas, se te apartaste de teu marido, e te contaminaste, e algum homem, fora de teu marido, se deitou contigo,',
        'Então o sacerdote fará jurar à mulher com o juramento da maldição; e o sacerdote dirá à mulher: O Senhor te ponha por maldição e por praga no meio do teu povo, fazendo-te o Senhor consumir a tua coxa e inchar o teu ventre.',
        'E esta água amaldiçoante entre nas tuas entranhas, para te fazer inchar o ventre, e te fazer consumir a coxa. Então a mulher dirá: Amém, Amém.',
        'Depois o sacerdote escreverá estas mesmas maldições num livro, e com a água amarga as apagará.',
        'E a água amarga, amaldiçoante, dará a beber à mulher, e a água amaldiçoante entrará nela para amargurar.',
        'E o sacerdote tomará a oferta por ciúmes da mão da mulher, e moverá a oferta perante o Senhor; e a oferecerá sobre o altar.',
        'Também o sacerdote tomará um punhado da oferta memorativa, e sobre o altar a queimará; e depois dará a beber a água à mulher.',
        'E, havendo-lhe dado a beber aquela água, será que, se ela se tiver contaminado, e contra seu marido tiver transgredido, a água amaldiçoante entrará nela para amargura, e o seu ventre se inchará, e consumirá a sua coxa; e aquela mulher será por maldição no meio do seu povo.',
        'E, se a mulher se não tiver contaminado, mas estiver limpa, então será livre, e conceberá filhos.',
        'Esta é a lei dos ciúmes, quando a mulher, em poder de seu marido, se desviar e for contaminada;',
        'Ou quando sobre o homem vier o espírito de ciúmes, e tiver ciúmes de sua mulher, apresente a mulher perante o Senhor, e o sacerdote nela execute toda esta lei.',
        'E o homem será livre da iniqüidade, porém a mulher levará a sua iniqüidade.',
      ],
      [
        'E falou o SENHOR a Moisés, dizendo:',
        'Fala aos filhos de Israel, e dize-lhes: Quando um homem ou mulher se tiver separado, fazendo voto de nazireu, para se separar ao Senhor,',
        'De vinho e de bebida forte se apartará; vinagre de vinho, nem vinagre de bebida forte não beberá; nem beberá alguma beberagem de uvas; nem uvas frescas nem secas comerá.',
        'Todos os dias do seu nazireado não comerá de coisa alguma, que se faz da vinha, desde os caroços até às cascas.',
        'Todos os dias do voto do seu nazireado sobre a sua cabeça não passará navalha; até que se cumpram os dias, que se separou ao Senhor, santo será, deixando crescer livremente o cabelo da sua cabeça.',
        'Todos os dias que se separar para o Senhor não se aproximará do corpo de um morto.',
        'Por seu pai, ou por sua mãe, por seu irmão, ou por sua irmã, por eles se não contaminará quando forem mortos; porquanto o nazireado do seu Deus está sobre a sua cabeça.',
        'Todos os dias do seu nazireado santo será ao Senhor.',
        'E se alguém vier a morrer junto a ele por acaso, subitamente, que contamine a cabeça do seu nazireado, então no dia da sua purificação rapará a sua cabeça, ao sétimo dia a rapará.',
        'E ao oitavo dia trará duas rolas, ou dois pombinhos, ao sacerdote, à porta da tenda da congregação;',
        'E o sacerdote oferecerá, um para expiação do pecado, e o outro para holocausto; e fará expiação por ele, do que pecou relativamente ao morto; assim naquele mesmo dia santificará a sua cabeça.',
        'Então separará os dias do seu nazireado ao Senhor, e para expiação da transgressão trará um cordeiro de um ano; e os dias antecedentes serão perdidos, porquanto o seu nazireado foi contaminado.',
        'E esta é a lei do nazireu: no dia em que se cumprirem os dias do seu nazireado, trá-lo-ão à porta da tenda da congregação;',
        'E ele oferecerá a sua oferta ao Senhor, um cordeiro sem defeito de um ano em holocausto, e uma cordeira sem defeito de um ano para expiação do pecado, e um carneiro sem defeito por oferta pacífica;',
        'E um cesto de pães ázimos, bolos de flor de farinha com azeite, amassados, e coscorões ázimos untados com azeite, como também a sua oferta de alimentos, e as suas libações.',
        'E o sacerdote os trará perante o Senhor, e sacrificará a sua expiação do pecado, e o seu holocausto;',
        'Também sacrificará o carneiro em sacrifício pacífico ao Senhor, com o cesto dos pães ázimos; e o sacerdote oferecerá a sua oferta de alimentos, e a sua libação.',
        'Então o nazireu à porta da tenda da congregação rapará a cabeça do seu nazireado, e tomará o cabelo da cabeça do seu nazireado, e o porá sobre o fogo que está debaixo do sacrifício pacífico.',
        'Depois o sacerdote tomará a espádua cozida do carneiro, e um pão ázimo do cesto, e um coscorão ázimo, e os porá nas mãos do nazireu, depois de haver rapado a cabeça do seu nazireado.',
        'E o sacerdote os oferecerá em oferta de movimento perante o Senhor: Isto é santo para o sacerdote, juntamente com o peito da oferta de movimento, e com a espádua da oferta alçada; e depois o nazireu poderá beber vinho.',
        'Esta é a lei do nazireu, que fizer voto da sua oferta ao Senhor pelo seu nazireado, além do que suas posses lhe permitirem; segundo o seu voto, que fizer, assim fará conforme à lei do seu nazireado.',
        'E falou o Senhor a Moisés, dizendo:',
        'Fala a Arão, e a seus filhos dizendo: Assim abençoareis os filhos de Israel, dizendo-lhes:',
        'O Senhor te abençoe e te guarde;',
        'O Senhor faça resplandecer o seu rosto sobre ti, e tenha misericórdia de ti;',
        'O Senhor sobre ti levante o seu rosto e te dê a paz.',
        'Assim porão o meu nome sobre os filhos de Israel, e eu os abençoarei.',
      ],
      [
        'E aconteceu, no dia em que Moisés acabou de levantar o tabernáculo, e o ungiu, e o santificou, e todos os seus utensílios; também o altar, e todos os seus pertences, e os ungiu, e os santificou,',
        'Que os príncipes de Israel, os cabeças da casa de seus pais, os que foram príncipes das tribos, que estavam sobre os que foram contados, ofereceram,',
        'E trouxeram a sua oferta perante o Senhor, seis carros cobertos, e doze bois; por dois príncipes um carro, e cada um deles um boi; e os apresentaram diante do tabernáculo.',
        'E falou o Senhor a Moisés, dizendo:',
        'Recebe-os deles, e serão para servir no ministério da tenda da congregação; e os darás aos levitas, a cada qual segundo o seu ministério.',
        'Assim Moisés recebeu os carros e os bois, e os deu aos levitas.',
        'Dois carros e quatro bois deu aos filhos de Gérson, segundo o seu ministério;',
        'E quatro carros e oito bois deu aos filhos de Merari, segundo o seu ministério, debaixo da mão de Itamar, filho de Arão, o sacerdote.',
        'Mas aos filhos de Coate nada deu, porquanto a seu cargo estava o santuário e o levavam aos ombros.',
        'E ofereceram os príncipes para a consagração do altar, no dia em que foi ungido; apresentaram, pois, os príncipes a sua oferta perante o altar.',
        'E disse o Senhor a Moisés: Cada príncipe oferecerá a sua oferta, cada qual no seu dia, para a consagração do altar.',
        'O que, pois, no primeiro dia apresentou a sua oferta foi Naassom, filho de Aminadabe, pela tribo de Judá.',
        'E a sua oferta foi um prato de prata, do peso de cento e trinta siclos, uma bacia de prata de setenta siclos, segundo o siclo do santuário; ambos cheios de flor de farinha, amassada com azeite, para oferta de alimentos;',
        'Uma colher de dez siclos de ouro, cheia de incenso;',
        'Um novilho, um carneiro, um cordeiro de um ano, para holocausto;',
        'Um bode para expiação do pecado;',
        'E para sacrifício pacífico dois bois, cinco carneiros, cinco bodes, cinco cordeiros de um ano; esta foi a oferta de Naassom, filho de Aminadabe.',
        'No segundo dia fez a sua oferta Natanael, filho de Zuar, príncipe de Issacar.',
        'E como sua oferta ofereceu um prato de prata, do peso de cento e trinta siclos, uma bacia de prata de setenta siclos, segundo o siclo do santuário; ambos cheios de flor de farinha amassada com azeite, para a oferta de alimentos;',
        'Uma colher de dez siclos de ouro, cheia de incenso;',
        'Um novilho, um carneiro, um cordeiro de um ano, para holocausto;',
        'Um bode para expiação do pecado;',
        'E para sacrifício pacífico dois bois, cinco carneiros, cinco bodes, cinco cordeiros de um ano; esta foi a oferta de Natanael, filho de Zuar.',
        'No terceiro dia ofereceu o príncipe dos filhos de Zebulom, Eliabe, filho de Helom.',
        'A sua oferta foi um prato de prata, do peso de cento e trinta siclos, uma bacia de prata de setenta siclos, segundo o siclo do santuário; ambos cheios de flor de farinha amassada com azeite, para oferta de alimentos;',
        'Uma colher de dez siclos de ouro, cheia de incenso;',
        'Um novilho, um carneiro, um cordeiro de um ano, para holocausto;',
        'Um bode para expiação do pecado;',
        'E para sacrifício pacífico dois bois, cinco carneiros, cinco bodes, cinco cordeiros de um ano; esta foi a oferta de Eliabe, filho de Helom.',
        'No quarto dia ofereceu o príncipe dos filhos de Rúben, Elizur, filho de Sedeur;',
        'A sua oferta foi um prato de prata, do peso de cento e trinta siclos, uma bacia de prata de setenta siclos, segundo o siclo do santuário; ambos cheios de flor de farinha, amassada com azeite, para oferta de alimentos;',
        'Uma colher de dez siclos de ouro, cheia de incenso;',
        'Um novilho, um carneiro, um cordeiro de um ano, para holocausto;',
        'Um bode para expiação do pecado;',
        'E para sacrifício pacífico dois bois, cinco carneiros, cinco bodes, cinco cordeiros de um ano; esta foi a oferta de Elizur, filho de Sedeur.',
        'No quinto dia ofereceu o príncipe dos filhos de Simeão, Selumiel, filho de Zurisadai.',
        'A sua oferta foi um prato de prata, do peso de cento e trinta siclos, uma bacia de prata de setenta siclos, segundo o siclo do santuário; ambos cheios de flor de farinha amassada com azeite, para oferta de alimentos;',
        'Uma colher de dez siclos de ouro, cheia de incenso;',
        'Um novilho, um carneiro, um cordeiro de um ano para holocausto;',
        'Um bode para expiação do pecado;',
        'E para sacrifício pacífico dois bois, cinco carneiros, cinco bodes, cinco cordeiros de um ano; esta foi a oferta de Selumiel, filho de Zurisadai.',
        'No sexto dia ofereceu o príncipe dos filhos de Gade; Eliasafe, filho de Deuel.',
        'A sua oferta foi um prato de prata, do peso de cento e trinta siclos, uma bacia de prata de setenta siclos, segundo o siclo do santuário; ambos cheios de flor de farinha, amassada com azeite, para oferta de alimentos;',
        'Uma colher de dez siclos de ouro, cheia de incenso;',
        'Um novilho, um carneiro, um cordeiro de um ano, para holocausto;',
        'Um bode para expiação do pecado.',
        'E para sacrifício pacífico dois bois, cinco carneiros, cinco bodes, cinco cordeiros de um ano; esta foi a oferta de Eliasafe, filho de Deuel.',
        'No sétimo dia ofereceu o príncipe dos filhos de Efraim, Elisama, filho de Amiúde.',
        'A sua oferta foi um prato de prata, do peso de cento e trinta siclos, uma bacia de prata de setenta siclos, segundo o siclo do santuário; ambos cheios de flor de farinha, amassada com azeite, para oferta de alimentos;',
        'Uma colher de dez siclos de ouro, cheia de incenso;',
        'Um novilho, um carneiro, um cordeiro de um ano, para holocausto;',
        'Um bode para expiação do pecado;',
        'E para sacrifício pacífico dois bois, cinco carneiros, cinco bodes, cinco cordeiros de um ano; esta foi a oferta de Elisama, filho de Amiúde.',
        'No oitavo dia ofereceu o príncipe dos filhos de Manassés, Gamaliel, filho de Pedazur.',
        'A sua oferta foi um prato de prata, do peso de cento e trinta siclos, uma bacia de prata de setenta siclos, segundo o siclo do santuário; ambos cheios de flor de farinha, amassada com azeite, para oferta de alimentos;',
        'Uma colher de dez siclos de ouro, cheia de incenso;',
        'Um novilho, um carneiro, um cordeiro de um ano, para holocausto;',
        'Um bode para expiação do pecado;',
        'E para sacrifício pacífico dois bois, cinco carneiros, cinco bodes, cinco cordeiros de um ano; esta foi a oferta de Gamaliel, filho de Pedazur.',
        'No dia nono ofereceu o príncipe dos filhos de Benjamim, Abidã, filho de Gideoni;',
        'A sua oferta foi um prato de prata, do peso de cento e trinta siclos, uma bacia de prata de setenta siclos, segundo o siclo do santuário; ambos cheios de flor de farinha, amassada com azeite, para oferta de alimentos;',
        'Uma colher de dez siclos de ouro, cheia de incenso;',
        'Um novilho, um carneiro, um cordeiro de um ano, para holocausto;',
        'Um bode para expiação do pecado;',
        'E para sacrifício pacífico dois bois, cinco carneiros, cinco bodes, cinco cordeiros de um ano; esta foi a oferta de Abidã filho de Gideoni.',
        'No décimo dia ofereceu o príncipe dos filhos de Dã, Aieser, filho de Amisadai.',
        'A sua oferta foi um prato de prata, do peso de cento e trinta siclos, uma bacia de prata de setenta siclos, segundo o siclo do santuário; ambos cheios de flor de farinha, amassada com azeite, para oferta de alimentos;',
        'Uma colher de dez siclos de ouro, cheia de incenso;',
        'Um novilho, um carneiro, um cordeiro de um ano, para holocausto;',
        'Um bode para expiação do pecado;',
        'E para sacrifício pacífico dois bois, cinco carneiros, cinco bodes, cinco cordeiros de um ano; esta foi a oferta de Aieser, filho de Amisadai.',
        'No dia undécimo ofereceu o príncipe dos filhos de Aser, Pagiel, filho de Ocrã;',
        'A sua oferta foi um prato de prata, do peso de cento e trinta siclos, uma bacia de prata de setenta siclos, segundo o siclo do santuário; ambos cheios de flor de farinha, amassada com azeite, para oferta de alimentos;',
        'Uma colher de dez siclos de ouro, cheia de incenso;',
        'Um novilho, um carneiro, um cordeiro de um ano, para holocausto;',
        'Um bode para expiação do pecado;',
        'E para sacrifício pacífico dois bois, cinco carneiros, cinco bodes, cinco cordeiros de um ano; esta foi a oferta de Pagiel, filho de Ocrã.',
        'No duodécimo dia ofereceu o príncipe dos filhos de Naftali, Aira, filho de Enã.',
        'A sua oferta foi um prato de prata, do peso de cento e trinta siclos, uma bacia de prata de setenta siclos, segundo o siclo do santuário; ambos cheios de flor de farinha amassada, com azeite para oferta de alimentos;',
        'Uma colher de dez siclos de ouro, cheia de incenso;',
        'Um novilho, um carneiro, um cordeiro de um ano, para holocausto;',
        'Um bode para expiação do pecado;',
        'E para sacrifício pacífico dois bois, cinco carneiros, cinco bodes, cinco cordeiros de um ano; esta foi a oferta de Aira, filho de Enã.',
        'Esta foi a consagração do altar, feita pelos príncipes de Israel, no dia em que foi ungido, doze pratos de prata, doze bacias de prata, doze colheres de ouro.',
        'Cada prato de prata de cento e trinta siclos, e cada bacia de setenta; toda a prata dos vasos foi dois mil e quatrocentos siclos, segundo o siclo do santuário;',
        'Doze colheres de ouro cheias de incenso, cada colher de dez siclos, segundo o siclo do santuário; todo o ouro das colheres foi de cento e vinte siclos;',
        'Todos os animais para holocausto foram doze novilhos, doze carneiros, doze cordeiros de um ano, com a sua oferta de alimentos e doze bodes para expiação do pecado.',
        'E todos os animais para sacrifício pacífico foram vinte e quatro novilhos, os carneiros sessenta, os bodes sessenta, os cordeiros de um ano sessenta; esta foi a consagração do altar, depois que foi ungido.',
        'E, quando Moisés entrava na tenda da congregação para falar com ele, então ouvia a voz que lhe falava de cima do propiciatório, que estava sobre a arca do testemunho entre os dois querubins; assim com ele falava.',
      ],
      [
        'E falou o SENHOR a Moisés, dizendo:',
        'Fala a Arão, e dize-lhe: Quando acenderes as lâmpadas, as sete lâmpadas iluminarão o espaço em frente do candelabro.',
        'E Arão fez assim: Acendeu as lâmpadas do candelabro para iluminar o espaço em frente, como o Senhor ordenara a Moisés.',
        'E era esta a obra do candelabro, obra de ouro batido; desde o seu pé até às suas flores era ele de ouro batido; conforme ao modelo que o Senhor mostrara a Moisés, assim ele fez o candelabro.',
        'E falou o Senhor a Moisés, dizendo:',
        'Toma os levitas do meio dos filhos de Israel e purifica-os;',
        'E assim lhes farás, para os purificar: Esparge sobre eles a água da expiação; e sobre toda a sua carne farão passar a navalha, e lavarão as suas vestes, e se purificarão.',
        'Então tomarão um novilho, com a sua oferta de alimentos de flor de farinha amassada com azeite; e tomarás tu outro novilho, para expiação do pecado.',
        'E farás chegar os levitas perante a tenda da congregação e ajuntarás toda a congregação dos filhos de Israel.',
        'Farás, pois, chegar os levitas perante o Senhor; e os filhos de Israel porão as suas mãos sobre os levitas.',
        'E Arão oferecerá os levitas por oferta movida, perante o Senhor, pelos filhos de Israel; e serão para servirem no ministério do Senhor.',
        'E os levitas colocarão as suas mãos sobre a cabeça dos novilhos; então sacrifica tu, um para expiação do pecado, e o outro para holocausto ao Senhor, para fazer expiação pelos levitas.',
        'E porás os levitas perante Arão, e perante os seus filhos, e os oferecerá por oferta movida ao Senhor.',
        'E separarás os levitas do meio dos filhos de Israel, para que os levitas sejam meus.',
        'E depois os levitas entrarão para fazerem o serviço da tenda da congregação; e tu os purificarás, e por oferta movida os oferecerás.',
        'Porquanto eles, dentre os filhos de Israel, me são dados; em lugar de todo aquele que abre a madre, do primogênito de cada um dos filhos de Israel, para mim os tenho tomado.',
        'Porque meu é todo o primogênito entre os filhos de Israel, entre os homens e entre os animais; no dia em que, na terra do Egito, feri a todo o primogênito, os santifiquei para mim.',
        'E tomei os levitas em lugar de todo o primogênito entre os filhos de Israel.',
        'E os levitas, dados a Arão e a seus filhos, dentre os filhos de Israel, tenho dado para ministrarem o ministério dos filhos de Israel na tenda da congregação e para fazer expiação pelos filhos de Israel, para que não haja praga entre eles, chegando-se os filhos de Israel ao santuário.',
        'E assim fizeram Moisés e Arão, e toda a congregação dos filhos de Israel, com os levitas; conforme a tudo o que o Senhor ordenara a Moisés acerca dos levitas, assim os filhos de Israel lhes fizeram.',
        'E os levitas se purificaram, e lavaram as suas vestes, e Arão os ofereceu por oferta movida perante o Senhor, e Arão fez expiação por eles, para purificá-los.',
        'E depois vieram os levitas, para exercerem o seu ministério na tenda da congregação, perante Arão e perante os seus filhos; como o Senhor ordenara a Moisés acerca dos levitas, assim lhes fizeram.',
        'E falou o Senhor a Moisés, dizendo:',
        'Este é o ofício dos levitas: Da idade de vinte e cinco anos para cima entrarão, para fazerem o serviço no ministério da tenda da congregação;',
        'Mas desde a idade de cinqüenta anos sairão do serviço deste ministério, e nunca mais servirão;',
        'Porém com os seus irmãos servirão na tenda da congregação, para terem cuidado da guarda; mas o ministério não exercerão; assim farás com os levitas quanto aos seus deveres.',
      ],
      [
        'E falou o SENHOR a Moisés no deserto de Sinai, no ano segundo da sua saída da terra do Egito, no primeiro mês, dizendo:',
        'Celebrem os filhos de Israel a páscoa a seu tempo determinado.',
        'No dia catorze deste mês, pela tarde, a seu tempo determinado a celebrareis; segundo todos os seus estatutos, e segundo todos os seus ritos, a celebrareis.',
        'Disse, pois, Moisés aos filhos de Israel que celebrassem a páscoa.',
        'Então celebraram a páscoa no dia catorze do primeiro mês, pela tarde, no deserto de Sinai; conforme a tudo o que o Senhor ordenara a Moisés, assim fizeram os filhos de Israel.',
        'E houve alguns que estavam imundos por terem tocado o corpo de um homem morto; e não podiam celebrar a páscoa naquele dia; por isso se chegaram perante Moisés e Arão naquele mesmo dia;',
        'E aqueles homens disseram-lhe: Imundos estamos nós pelo corpo de um homem morto; por que seríamos privados de oferecer a oferta do Senhor a seu tempo determinado no meio dos filhos de Israel?',
        'E disse-lhes Moisés: Esperai, e eu ouvirei o que o Senhor vos ordenará.',
        'Então falou o Senhor a Moisés, dizendo:',
        'Fala aos filhos de Israel, dizendo: Quando alguém entre vós, ou entre as vossas gerações, for imundo por tocar corpo morto, ou achar-se em jornada longe de vós, contudo ainda celebrará a páscoa ao Senhor.',
        'No mês segundo, no dia catorze à tarde, a celebrarão; com pães ázimos e ervas amargas a comerão.',
        'Dela nada deixarão até à manhã, e dela não quebrarão osso algum; segundo todo o estatuto da páscoa a celebrarão.',
        'Porém, quando um homem for limpo, e não estiver em viagem, e deixar de celebrar a páscoa, essa alma do seu povo será extirpada; porquanto não ofereceu a oferta do Senhor a seu tempo determinado; esse homem levará o seu pecado.',
        'E, quando um estrangeiro peregrinar entre vós, e também celebrar a páscoa ao Senhor, segundo o estatuto da páscoa e segundo o seu rito assim a celebrará; um mesmo estatuto haverá para vós, assim para o estrangeiro, como para o natural da terra.',
        'E no dia em que foi levantado o tabernáculo, a nuvem cobriu o tabernáculo sobre a tenda do testemunho; e à tarde estava sobre o tabernáculo com uma aparência de fogo até à manhã.',
        'Assim era de contínuo: a nuvem o cobria, e de noite havia aparência de fogo.',
        'Mas sempre que a nuvem se alçava de sobre a tenda, os filhos de Israel partiam; e no lugar onde a nuvem parava, ali os filhos de Israel se acampavam.',
        'Segundo a ordem do Senhor, os filhos de Israel partiam, e segundo a ordem do Senhor se acampavam; todos os dias em que a nuvem parava sobre o tabernáculo, ficavam acampados.',
        'E, quando a nuvem se detinha muitos dias sobre o tabernáculo, então os filhos de Israel cumpriam a ordem do Senhor, e não partiam.',
        'E, quando a nuvem ficava poucos dias sobre o tabernáculo, segundo a ordem do Senhor se alojavam, e segundo a ordem do Senhor partiam.',
        'Porém, outras vezes a nuvem ficava desde a tarde até à manhã, e quando ela se alçava pela manhã, então partiam; quer de dia quer de noite alçando-se a nuvem, partiam.',
        'Ou, quando a nuvem sobre o tabernáculo se detinha dois dias, ou um mês, ou um ano, ficando sobre ele, então os filhos de Israel se alojavam, e não partiam; e alçando-se ela, partiam.',
        'Segundo a ordem do Senhor se alojavam, e segundo a ordem do Senhor partiam; cumpriam o seu dever para com o Senhor, segundo a ordem do Senhor por intermédio de Moisés.',
      ],
      [
        'Falou mais o SENHOR a Moisés, dizendo:',
        'Faze-te duas trombetas de prata; de obra batida as farás, e elas te servirão para a convocação da congregação, e para a partida dos arraiais.',
        'E, quando as tocarem, então toda a congregação se reunirá a ti à porta da tenda da congregação.',
        'Mas, quando tocar uma só, então a ti se congregarão os príncipes, os cabeças dos milhares de Israel.',
        'Quando, retinindo, as tocardes, então partirão os arraiais que estão acampados do lado do oriente.',
        'Mas, quando a segunda vez retinindo, as tocardes, então partirão os arraiais que estão acampados do lado do sul; retinindo, as tocarão para as suas partidas.',
        'Porém, ajuntando a congregação, as tocareis; mas sem retinir.',
        'E os filhos de Arão, sacerdotes, tocarão as trombetas; e a vós serão por estatuto perpétuo nas vossas gerações.',
        'E, quando na vossa terra sairdes a pelejar contra o inimigo, que vos oprime, também tocareis as trombetas retinindo, e perante o Senhor vosso Deus haverá lembrança de vós, e sereis salvos de vossos inimigos.',
        'Semelhantemente, no dia da vossa alegria e nas vossas solenidades, e nos princípios de vossos meses, também tocareis as trombetas sobre os vossos holocaustos, sobre os vossos sacrifícios pacíficos, e vos serão por memorial perante vosso Deus: Eu sou o Senhor vosso Deus.',
        'E aconteceu, no ano segundo, no segundo mês, aos vinte do mês, que a nuvem se alçou de sobre o tabernáculo da congregação.',
        'E os filhos de Israel, segundo a ordem de marcha, partiram do deserto de Sinai; e a nuvem parou no deserto de Parã.',
        'Assim partiram pela primeira vez segundo a ordem do Senhor, por intermédio de Moisés.',
        'Porque primeiramente partiu a bandeira do arraial dos filhos de Judá segundo os seus exércitos; e sobre o seu exército estava Naassom, filho de Aminadabe.',
        'E sobre o exército da tribo dos filhos de Issacar, Natanael, filho de Zuar.',
        'E sobre o exército da tribo dos filhos de Zebulom, Eliabe, filho de Helom.',
        'Então desarmaram o tabernáculo, e os filhos de Gérson e os filhos de Merari partiram, levando o tabernáculo.',
        'Depois partiu a bandeira do arraial de Rúben segundo os seus exércitos; e sobre o seu exército estava Elizur, filho de Sedeur.',
        'E sobre o exército da tribo dos filhos de Simeão, Selumiel, filho de Zurisadai.',
        'E sobre o exército da tribo dos filhos de Gade, Eliasafe, filho de Deuel.',
        'Então partiram os coatitas, levando o santuário; e os outros levantaram o tabernáculo, enquanto estes vinham.',
        'Depois partiu a bandeira do arraial dos filhos de Efraim segundo os seus exércitos; e sobre o seu exército estava Elisama, filho de Amiúde.',
        'E sobre o exército da tribo dos filhos de Manassés, Gamaliel, filho de Pedazur.',
        'E sobre o exército da tribo dos filhos de Benjamim, Abidã, filho de Gideoni.',
        'Então partiu a bandeira do arraial dos filhos de Dã, fechando todos os arraiais segundo os seus exércitos; e sobre o seu exército estava Aieser, filho de Amisadai.',
        'E sobre o exército da tribo dos filhos de Aser, Pagiel, filho de Ocrã.',
        'E sobre o exército da tribo dos filhos de Naftali, Aira, filho de Enã.',
        'Esta era a ordem das partidas dos filhos de Israel segundo os seus exércitos, quando partiam.',
        'Disse então Moisés a Hobabe, filho de Reuel, o midianita, sogro de Moisés: Nós caminhamos para aquele lugar, de que o Senhor disse: Vo-lo darei; vai conosco e te faremos bem; porque o Senhor falou bem sobre Israel.',
        'Porém ele lhe disse: Não irei; antes irei à minha terra e à minha parentela.',
        'E ele disse: Ora, não nos deixes; porque tu sabes onde devemos acampar no deserto; nos servirás de guia.',
        'E será que, vindo tu conosco, e sucedendo o bem que o Senhor nos fizer, também nós te faremos bem.',
        'Assim partiram do monte do Senhor caminho de três dias; e a arca da aliança do Senhor caminhou diante deles caminho de três dias, para lhes buscar lugar de descanso.',
        'E a nuvem do Senhor ia sobre eles de dia, quando partiam do arraial.',
        'Acontecia que, partindo a arca, Moisés dizia: Levanta-te, Senhor, e dissipados sejam os teus inimigos, e fujam diante de ti os que te odeiam.',
        'E, pousando ela, dizia: Volta, ó Senhor, para os muitos milhares de Israel.',
      ],
      [
        'E aconteceu que, queixou-se o povo falando o que era mal aos ouvidos do SENHOR; e ouvindo o SENHOR a sua ira se acendeu; e o fogo do SENHOR ardeu entre eles e consumiu os que estavam na última parte do arraial.',
        'Então o povo clamou a Moisés, e Moisés orou ao Senhor, e o fogo se apagou.',
        'Pelo que chamou aquele lugar Taberá, porquanto o fogo do Senhor se acendera entre eles.',
        'E o vulgo, que estava no meio deles, veio a ter grande desejo; pelo que os filhos de Israel tornaram a chorar, e disseram: Quem nos dará carne a comer?',
        'Lembramo-nos dos peixes que no Egito comíamos de graça; e dos pepinos, e dos melões, e dos porros, e das cebolas, e dos alhos.',
        'Mas agora a nossa alma se seca; coisa nenhuma há senão este maná diante dos nossos olhos.',
        'E era o maná como semente de coentro, e a sua cor como a cor de bdélio.',
        'Espalhava-se o povo e o colhia, e em moinhos o moía, ou num gral o pisava, e em panelas o cozia, e dele fazia bolos; e o seu sabor era como o sabor de azeite fresco.',
        'E, quando o orvalho descia de noite sobre o arraial, o maná descia sobre ele.',
        'Então Moisés ouviu chorar o povo pelas suas famílias, cada qual à porta da sua tenda; e a ira do Senhor grandemente se acendeu, e pareceu mal aos olhos de Moisés.',
        'E disse Moisés ao Senhor: Por que fizeste mal a teu servo, e por que não achei graça aos teus olhos, visto que puseste sobre mim o cargo de todo este povo?',
        'Concebi eu porventura todo este povo? Dei-o eu à luz? para que me dissesses: leva-o ao teu colo, como a ama leva a criança que mama, à terra que juraste a seus pais?',
        'De onde teria eu carne para dar a todo este povo? Porquanto contra mim choram, dizendo: Dá-nos carne a comer;',
        'Eu só não posso levar a todo este povo, porque muito pesado é para mim.',
        'E se assim fazes comigo, mata-me, peço-te, se tenho achado graça aos teus olhos, e não me deixes ver o meu mal.',
        'E disse o Senhor a Moisés: Ajunta-me setenta homens dos anciãos de Israel, que sabes serem anciãos do povo e seus oficiais; e os trarás perante a tenda da congregação, e ali estejam contigo.',
        'Então eu descerei e ali falarei contigo, e tirarei do espírito que está sobre ti, e o porei sobre eles; e contigo levarão a carga do povo, para que tu não a leves sozinho.',
        'E dirás ao povo: Santificai-vos para amanhã, e comereis carne; porquanto chorastes aos ouvidos do Senhor, dizendo: Quem nos dará carne a comer? Pois íamos bem no Egito; por isso o Senhor vos dará carne, e comereis;',
        'Não comereis um dia, nem dois dias, nem cinco dias, nem dez dias, nem vinte dias;',
        'Mas um mês inteiro, até vos sair pelas narinas, até que vos enfastieis dela; porquanto rejeitastes ao Senhor, que está no meio de vós, e chorastes diante dele, dizendo: Por que saímos do Egito?',
        'E disse Moisés: Seiscentos mil homens de pé é este povo, no meio do qual estou; e tu tens dito: Dar-lhes-ei carne, e comerão um mês inteiro.',
        'Degolar-se-ão para eles ovelhas e vacas que lhes bastem? Ou ajuntar-se-ão para eles todos os peixes do mar, que lhes bastem?',
        'Porém, o Senhor disse a Moisés: Teria sido encurtada a mão do Senhor? Agora verás se a minha palavra se há de cumprir ou não.',
        'E saiu Moisés, e falou as palavras do Senhor ao povo, e ajuntou setenta homens dos anciãos do povo e os pôs ao redor da tenda.',
        'Então o Senhor desceu na nuvem, e lhe falou; e, tirando do espírito, que estava sobre ele, o pôs sobre aqueles setenta anciãos; e aconteceu que, quando o espírito repousou sobre eles, profetizaram; mas depois nunca mais.',
        'Porém no arraial ficaram dois homens; o nome de um era Eldade, e do outro Medade; e repousou sobre eles o espírito (porquanto estavam entre os inscritos, ainda que não saíram à tenda), e profetizavam no arraial.',
        'Então correu um moço e anunciou a Moisés e disse: Eldade e Medade profetizam no arraial.',
        'E Josué, filho de Num, servidor de Moisés, um dos seus jovens escolhidos, respondeu e disse: Moisés, meu senhor, proíbe-lho.',
        'Porém, Moisés lhe disse: Tens tu ciúmes por mim? Quem dera que todo o povo do Senhor fosse profeta, e que o Senhor pusesse o seu espírito sobre ele!',
        'Depois Moisés se recolheu ao arraial, ele e os anciãos de Israel.',
        'Então soprou um vento do Senhor e trouxe codornizes do mar, e as espalhou pelo arraial quase caminho de um dia, de um lado e de outro lado, ao redor do arraial; quase dois côvados sobre a terra.',
        'Então o povo se levantou todo aquele dia e toda aquela noite, e todo o dia seguinte, e colheram as codornizes; o que menos tinha, colhera dez ômeres; e as estenderam para si ao redor do arraial.',
        'Quando a carne estava entre os seus dentes, antes que fosse mastigada, se acendeu a ira do Senhor contra o povo, e feriu o Senhor o povo com uma praga mui grande.',
        'Por isso o nome daquele lugar se chamou Quibrote-Ataavá, porquanto ali enterraram o povo que teve o desejo.',
        'De Quibrote-Ataavá caminhou o povo para Hazerote, e pararam em Hazerote.',
      ],
      [
        'E falaram Miriã e Arão contra Moisés, por causa da mulher cusita, com quem casara; porquanto tinha casado com uma mulher cusita.',
        'E disseram: Porventura falou o Senhor somente por Moisés? Não falou também por nós? E o Senhor o ouviu.',
        'E era o homem Moisés mui manso, mais do que todos os homens que havia sobre a terra.',
        'E logo o Senhor disse a Moisés, a Arão e a Miriã: Vós três saí à tenda da congregação. E saíram eles três.',
        'Então o Senhor desceu na coluna de nuvem, e se pôs à porta da tenda; depois chamou a Arão e a Miriã e ambos saíram.',
        'E disse: Ouvi agora as minhas palavras; se entre vós houver profeta, eu, o Senhor, em visão a ele me farei conhecer, ou em sonhos falarei com ele.',
        'Não é assim com o meu servo Moisés que é fiel em toda a minha casa.',
        'Boca a boca falo com ele, claramente e não por enigmas; pois ele vê a semelhança do Senhor; por que, pois, não tivestes temor de falar contra o meu servo, contra Moisés?',
        'Assim a ira do Senhor contra eles se acendeu; e retirou-se.',
        'E a nuvem se retirou de sobre a tenda; e eis que Miriã ficou leprosa como a neve; e olhou Arão para Miriã, e eis que estava leprosa.',
        'Por isso Arão disse a Moisés: Ai, senhor meu, não ponhas sobre nós este pecado, pois agimos loucamente, e temos pecado.',
        'Ora, não seja ela como um morto, que saindo do ventre de sua mãe, a metade da sua carne já esteja consumida.',
        'Clamou, pois, Moisés ao Senhor, dizendo: Ó Deus, rogo-te que a cures.',
        'E disse o Senhor a Moisés: Se seu pai cuspira em seu rosto, não seria envergonhada sete dias? Esteja fechada sete dias fora do arraial, e depois a recolham.',
        'Assim Miriã esteve fechada fora do arraial sete dias, e o povo não partiu, até que recolheram a Miriã.',
        'Porém, depois o povo partiu de Hazerote; e acampou-se no deserto de Parã.',
      ],
      [
        'E falou o SENHOR a Moisés, dizendo:',
        'Envia homens que espiem a terra de Canaã, que eu hei de dar aos filhos de Israel; de cada tribo de seus pais enviareis um homem, sendo cada um príncipe entre eles.',
        'E enviou-os Moisés do deserto de Parã, segundo a ordem do Senhor; todos aqueles homens eram cabeças dos filhos de Israel.',
        'E estes são os seus nomes: Da tribo de Rúben, Samua, filho de Zacur;',
        'Da tribo de Simeão, Safate, filho de Hori;',
        'Da tribo de Judá, Calebe, filho de Jefoné;',
        'Da tribo de Issacar, Jigeal, filho de José;',
        'Da tribo de Efraim, Oséias, filho de Num;',
        'Da tribo de Benjamim, Palti, filho de Rafu;',
        'Da tribo de Zebulom, Gadiel, filho de Sodi;',
        'Da tribo de José, pela tribo de Manassés, Gadi filho de Susi;',
        'Da tribo de Dã, Amiel, filho de Gemali;',
        'Da tribo de Aser, Setur, filho de Micael;',
        'Da tribo de Naftali, Nabi, filho de Vofsi;',
        'Da tribo de Gade, Geuel, filho de Maqui.',
        'Estes são os nomes dos homens que Moisés enviou a espiar aquela terra; e a Oséias, filho de Num, Moisés chamou Josué.',
        'Enviou-os, pois, Moisés a espiar a terra de Canaã; e disse-lhes: Subi por aqui para o lado do sul, e subi à montanha:',
        'E vede que terra é, e o povo que nela habita; se é forte ou fraco; se pouco ou muito.',
        'E como é a terra em que habita, se boa ou má; e quais são as cidades em que eles habitam; se em arraiais, ou em fortalezas.',
        'Também como é a terra, se fértil ou estéril; se nela há árvores, ou não; e esforçai-vos, e tomai do fruto da terra. E eram aqueles dias os dias das primícias das uvas.',
        'Assim subiram e espiaram a terra desde o deserto de Zim, até Reobe, à entrada de Hamate.',
        'E subiram para o lado do sul, e vieram até Hebrom; e estavam ali Aimã, Sesai e Talmai, filhos de Anaque (Hebrom foi edificada sete anos antes de Zoã no Egito).',
        'Depois foram até ao vale de Escol, e dali cortaram um ramo de vide com um cacho de uvas, o qual trouxeram dois homens, sobre uma vara; como também das romãs e dos figos.',
        'Chamaram àquele lugar o vale de Escol, por causa do cacho que dali cortaram os filhos de Israel.',
        'E eles voltaram de espiar a terra, ao fim de quarenta dias.',
        'E caminharam, e vieram a Moisés e a Arão, e a toda a congregação dos filhos de Israel no deserto de Parã, em Cades; e deram-lhes notícias, a eles, e a toda a congregação, e mostraram-lhes o fruto da terra.',
        'E contaram-lhe, e disseram: Fomos à terra a que nos enviaste; e verdadeiramente mana leite e mel, e este é o seu fruto.',
        'O povo, porém, que habita nessa terra é poderoso, e as cidades fortificadas e mui grandes; e também ali vimos os filhos de Anaque.',
        'Os amalequitas habitam na terra do sul; e os heteus, e os jebuseus, e os amorreus habitam na montanha; e os cananeus habitam junto do mar, e pela margem do Jordão.',
        'Então Calebe fez calar o povo perante Moisés, e disse: Certamente subiremos e a possuiremos em herança; porque seguramente prevaleceremos contra ela.',
        'Porém, os homens que com ele subiram disseram: Não poderemos subir contra aquele povo, porque é mais forte do que nós.',
        'E infamaram a terra que tinham espiado, dizendo aos filhos de Israel: A terra, pela qual passamos a espiá-la, é terra que consome os seus moradores; e todo o povo que vimos nela são homens de grande estatura.',
        'Também vimos ali gigantes, filhos de Anaque, descendentes dos gigantes; e éramos aos nossos olhos como gafanhotos, e assim também éramos aos seus olhos.',
      ],
      [
        'Então toda a congregação levantou a sua voz; e o povo chorou naquela noite.',
        'E todos os filhos de Israel murmuraram contra Moisés e contra Arão; e toda a congregação lhes disse: Quem dera tivéssemos morrido na terra do Egito! ou, mesmo neste deserto!',
        'E por que o Senhor nos traz a esta terra, para cairmos à espada, e para que nossas mulheres e nossas crianças sejam por presa? Não nos seria melhor voltarmos ao Egito?',
        'E diziam uns aos outros: Constituamos um líder, e voltemos ao Egito.',
        'Então Moisés e Arão caíram sobre os seus rostos perante toda a congregação dos filhos de Israel.',
        'E Josué, filho de Num, e Calebe filho de Jefoné, dos que espiaram a terra, rasgaram as suas vestes.',
        'E falaram a toda a congregação dos filhos de Israel, dizendo: A terra pela qual passamos a espiar é terra muito boa.',
        'Se o Senhor se agradar de nós, então nos porá nesta terra, e no-la dará; terra que mana leite e mel.',
        'Tão-somente não sejais rebeldes contra o Senhor, e não temais o povo dessa terra, porquanto são eles nosso pão; retirou-se deles o seu amparo, e o Senhor é conosco; não os temais.',
        'Mas toda a congregação disse que os apedrejassem; porém a glória do Senhor apareceu na tenda da congregação a todos os filhos de Israel.',
        'E disse o Senhor a Moisés: Até quando me provocará este povo? e até quando não crerá em mim, apesar de todos os sinais que fiz no meio dele?',
        'Com pestilência o ferirei, e o rejeitarei; e te farei a ti povo maior e mais forte do que este.',
        'E disse Moisés ao Senhor: Assim os egípcios o ouvirão; porquanto com a tua força fizeste subir este povo do meio deles.',
        'E dirão aos moradores desta terra, os quais ouviram que tu, ó Senhor, estás no meio deste povo, que face a face, ó Senhor, lhes apareces, que tua nuvem está sobre ele e que vais adiante dele numa coluna de nuvem de dia, e numa coluna de fogo de noite.',
        'E se matares este povo como a um só homem, então as nações, que antes ouviram a tua fama, falarão, dizendo:',
        'Porquanto o Senhor não podia pôr este povo na terra que lhe tinha jurado; por isso os matou no deserto.',
        'Agora, pois, rogo-te que a força do meu Senhor se engrandeça; como tens falado, dizendo:',
        'O Senhor é longânimo, e grande em misericórdia, que perdoa a iniqüidade e a transgressão, que o culpado não tem por inocente, e visita a iniqüidade dos pais sobre os filhos até a terceira e quarta geração.',
        'Perdoa, pois, a iniqüidade deste povo, segundo a grandeza da tua misericórdia; e como também perdoaste a este povo desde a terra do Egito até aqui.',
        'E disse o Senhor: Conforme à tua palavra lhe perdoei.',
        'Porém, tão certamente como eu vivo, e como a glória do Senhor encherá toda a terra,',
        'E que todos os homens que viram a minha glória e os meus sinais, que fiz no Egito e no deserto, e me tentaram estas dez vezes, e não obedeceram à minha voz,',
        'Não verão a terra de que a seus pais jurei, e nenhum daqueles que me provocaram a verá.',
        'Porém o meu servo Calebe, porquanto nele houve outro espírito, e perseverou em seguir-me, eu o levarei à terra em que entrou, e a sua descendência a possuirá em herança.',
        'Ora, os amalequitas e os cananeus habitam no vale; tornai-vos amanhã e caminhai para o deserto pelo caminho do Mar Vermelho.',
        'Depois falou o Senhor a Moisés e a Arão dizendo:',
        'Até quando sofrerei esta má congregação, que murmura contra mim? Tenho ouvido as murmurações dos filhos de Israel, com que murmuram contra mim.',
        'Dize-lhes: Vivo eu, diz o Senhor, que, como falastes aos meus ouvidos, assim farei a vós outros.',
        'Neste deserto cairão os vossos cadáveres, como também todos os que de vós foram contados segundo toda a vossa conta, de vinte anos para cima, os que dentre vós contra mim murmurastes;',
        'Não entrareis na terra, pela qual levantei a minha mão que vos faria habitar nela, salvo Calebe, filho de Jefoné, e Josué, filho de Num.',
        'Mas os vossos filhos, de que dizeis: Por presa serão, porei nela; e eles conhecerão a terra que vós desprezastes.',
        'Porém, quanto a vós, os vossos cadáveres cairão neste deserto.',
        'E vossos filhos pastorearão neste deserto quarenta anos, e levarão sobre si as vossas infidelidades, até que os vossos cadáveres se consumam neste deserto.',
        'Segundo o número dos dias em que espiastes esta terra, quarenta dias, cada dia representando um ano, levareis sobre vós as vossas iniqüidades quarenta anos, e conhecereis o meu afastamento.',
        'Eu, o Senhor, falei; assim farei a toda esta má congregação, que se levantou contra mim; neste deserto se consumirão, e aí falecerão.',
        'E os homens que Moisés mandara a espiar a terra, e que, voltando, fizeram murmurar toda a congregação contra ele, infamando a terra,',
        'Aqueles mesmos homens que infamaram a terra, morreram de praga perante o Senhor.',
        'Mas Josué, filho de Num, e Calebe, filho de Jefoné, que eram dos homens que foram espiar a terra, ficaram com vida.',
        'E falou Moisés estas palavras a todos os filhos de Israel; então o povo se contristou muito.',
        'E levantaram-se pela manhã de madrugada, e subiram ao cume do monte, dizendo: Eis-nos aqui, e subiremos ao lugar que o Senhor tem falado; porquanto havemos pecado.',
        'Mas Moisés disse: Por que transgredis o mandado do Senhor? Pois isso não prosperará.',
        'Não subais, pois o Senhor não estará no meio de vós, para que não sejais feridos diante dos vossos inimigos.',
        'Porque os amalequitas e os cananeus estão ali diante da vossa face, e caireis à espada; pois, porquanto vos desviastes do Senhor, o Senhor não estará convosco.',
        'Contudo, temerariamente, tentaram subir ao cume do monte; mas a arca da aliança do Senhor e Moisés não se apartaram do meio do arraial.',
        'Então desceram os amalequitas e os cananeus, que habitavam na montanha, e os feriram, derrotando-os até Hormá.',
      ],
      [
        'Depois falou o SENHOR a Moisés, dizendo:',
        'Fala aos filhos de Israel, e dize-lhes: Quando entrardes na terra das vossas habitações, que eu vos hei de dar,',
        'E ao Senhor fizerdes oferta queimada, holocausto, ou sacrifício, para cumprir um voto, ou em oferta voluntária, ou nas vossas solenidades, para fazerdes ao Senhor um cheiro suave de ovelhas ou gado,',
        'Então aquele que apresentar a sua oferta ao Senhor, por oferta de alimentos trará uma décima de flor de farinha misturada com a quarta parte de um him de azeite.',
        'E de vinho para libação prepararás a quarta parte de um him, para holocausto, ou para sacrifício para cada cordeiro;',
        'E para cada carneiro prepararás uma oferta de alimentos de duas décimas de flor de farinha, misturada com a terça parte de um him de azeite.',
        'E de vinho para a libação oferecerás a terça parte de um him ao Senhor, em cheiro suave.',
        'E, quando preparares novilho para holocausto ou sacrifício, para cumprir um voto, ou um sacrifício pacífico ao Senhor,',
        'Com o novilho apresentarás uma oferta de alimentos de três décimas de flor de farinha misturada com a metade de um him de azeite.',
        'E de vinho para a libação oferecerás a metade de um him, oferta queimada em cheiro suave ao Senhor.',
        'Assim se fará com cada boi, ou com cada carneiro, ou com cada um dos cordeiros ou cabritos.',
        'Segundo o número que oferecerdes, assim o fareis com cada um, segundo o número deles.',
        'Todo o natural assim fará estas coisas, oferecendo oferta queimada em cheiro suave ao Senhor.',
        'Quando também peregrinar convosco algum estrangeiro, ou que estiver no meio de vós nas vossas gerações, e ele apresentar uma oferta queimada de cheiro suave ao Senhor, como vós fizerdes, assim fará ele.',
        'Um mesmo estatuto haja para vós, ó congregação, e para o estrangeiro que entre vós peregrina, por estatuto perpétuo nas vossas gerações; como vós, assim será o peregrino perante o Senhor.',
        'Uma mesma lei e um mesmo direito haverá para vós e para o estrangeiro que peregrina convosco.',
        'Falou mais o Senhor a Moisés, dizendo:',
        'Fala aos filhos de Israel, e dize-lhes: Quando entrardes na terra em que vos hei de introduzir,',
        'Acontecerá que, quando comerdes do pão da terra, então oferecereis ao Senhor oferta alçada.',
        'Das primícias da vossa massa oferecereis um bolo em oferta alçada; como a oferta da eira, assim o oferecereis.',
        'Das primícias das vossas massas dareis ao Senhor oferta alçada nas vossas gerações.',
        'E, quando vierdes a errar, e não cumprirdes todos estes mandamentos, que o Senhor falou a Moisés,',
        'Tudo quanto o Senhor vos tem mandado por intermédio de Moisés, desde o dia que o Senhor ordenou, e dali em diante, nas vossas gerações,',
        'Será que, quando se fizer alguma coisa por ignorância, e for encoberto aos olhos da congregação, toda a congregação oferecerá um novilho para holocausto em cheiro suave ao Senhor, com a sua oferta de alimentos e libação conforme ao estatuto, e um bode para expiação do pecado.',
        'E o sacerdote fará expiação por toda a congregação dos filhos de Israel, e lhes será perdoado, porquanto foi por ignorância; e trouxeram a sua oferta, oferta queimada ao Senhor, e a sua expiação do pecado perante o Senhor, por causa da sua ignorância.',
        'Será, pois, perdoado a toda a congregação dos filhos de Israel, e mais ao estrangeiro que peregrina no meio deles, porquanto por ignorância sobreveio a todo o povo.',
        'E, se alguma alma pecar por ignorância, para expiação do pecado oferecerá uma cabra de um ano.',
        'E o sacerdote fará expiação pela pessoa que pecou, quando pecar por ignorância, perante o Senhor, fazendo expiação por ela, e lhe será perdoado.',
        'Para o natural dos filhos de Israel, e para o estrangeiro que no meio deles peregrina, uma mesma lei vos será, para aquele que pecar por ignorância.',
        'Mas a pessoa que fizer alguma coisa temerariamente, quer seja dos naturais quer dos estrangeiros, injuria ao Senhor; tal pessoa será extirpada do meio do seu povo.',
        'Pois desprezou a palavra do Senhor, e anulou o seu mandamento; totalmente será extirpada aquela pessoa, a sua iniqüidade será sobre ela.',
        'Estando, pois, os filhos de Israel no deserto, acharam um homem apanhando lenha no dia de sábado.',
        'E os que o acharam apanhando lenha o trouxeram a Moisés e a Arão, e a toda a congregação.',
        'E o puseram em guarda; porquanto ainda não estava declarado o que se lhe devia fazer.',
        'Disse, pois, o Senhor a Moisés: Certamente morrerá aquele homem; toda a congregação o apedrejará fora do arraial.',
        'Então toda a congregação o tirou para fora do arraial, e o apedrejaram, e morreu, como o Senhor ordenara a Moisés.',
        'E falou o Senhor a Moisés, dizendo:',
        'Fala aos filhos de Israel, e dize-lhes: Que nas bordas das suas vestes façam franjas pelas suas gerações; e nas franjas das bordas ponham um cordão de azul.',
        'E as franjas vos serão para que, vendo-as, vos lembreis de todos os mandamentos do Senhor, e os cumprais; e não seguireis o vosso coração, nem após os vossos olhos, pelos quais andais vos prostituindo.',
        'Para que vos lembreis de todos os meus mandamentos, e os cumprais, e santos sejais a vosso Deus.',
        'Eu sou o Senhor vosso Deus, que vos tirei da terra do Egito, para ser vosso Deus. Eu sou o Senhor vosso Deus.',
      ],
      [
        'E Coré, filho de Izar, filho de Coate, filho de Levi, tomou consigo a Datã e a Abirão, filhos de Eliabe, e a Om, filho de Pelete, filhos de Rúben.',
        'E levantaram-se perante Moisés com duzentos e cinqüenta homens dos filhos de Israel, príncipes da congregação, chamados à assembléia, homens de posição,',
        'E se congregaram contra Moisés e contra Arão, e lhes disseram: Basta-vos, pois que toda a congregação é santa, todos são santos, e o Senhor está no meio deles; por que, pois, vos elevais sobre a congregação do Senhor?',
        'Quando Moisés ouviu isso, caiu sobre o seu rosto.',
        'E falou a Coré e a toda a sua congregação, dizendo: Amanhã pela manhã o Senhor fará saber quem é seu, e quem é o santo que ele fará chegar a si; e aquele a quem escolher fará chegar a si.',
        'Fazei isto: Tomai vós incensários, Coré e todo seu grupo;',
        'E, pondo fogo neles amanhã, sobre eles deitai incenso perante o Senhor; e será que o homem a quem o Senhor escolher, este será o santo; basta-vos, filhos de Levi.',
        'Disse mais Moisés a Coré: Ouvi agora, filhos de Levi:',
        'Porventura pouco para vós é que o Deus de Israel vos tenha separado da congregação de Israel, para vos fazer chegar a si, e administrar o ministério do tabernáculo do Senhor e estar perante a congregação para ministrar-lhe;',
        'E te fez chegar, e todos os teus irmãos, os filhos de Levi, contigo? ainda também procurais o sacerdócio?',
        'Assim tu e todo o teu grupo estais contra o Senhor; e Arão, quem é ele, que murmureis contra ele?',
        'E Moisés mandou chamar a Datã e a Abirão, filhos de Eliabe; porém eles disseram: Não subiremos;',
        'Porventura pouco é que nos fizeste subir de uma terra que mana leite e mel, para nos matares neste deserto, senão que também queres fazer-te príncipe sobre nós?',
        'Nem tampouco nos trouxeste a uma terra que mana leite e mel, nem nos deste campo e vinhas em herança; porventura arrancarás os olhos a estes homens? Não subiremos.',
        'Então Moisés irou-se muito, e disse ao Senhor: Não atentes para a sua oferta; nem um só jumento tomei deles, nem a nenhum deles fiz mal.',
        'Disse mais Moisés a Coré: Tu e todo o teu grupo ponde-vos perante o Senhor, tu e eles, e Arão, amanhã.',
        'E tomai cada um o seu incensário, e neles ponde incenso; e trazei cada um o seu incensário perante o Senhor, duzentos e cinqüenta incensários; também tu e Arão, cada um o seu incensário.',
        'Tomaram, pois, cada um o seu incensário, e neles puseram fogo, e neles deitaram incenso, e se puseram perante a porta da tenda da congregação com Moisés e Arão.',
        'E Coré fez ajuntar contra eles todo o povo à porta da tenda da congregação; então a glória do Senhor apareceu a toda a congregação.',
        'E falou o Senhor a Moisés e a Arão, dizendo:',
        'Apartai-vos do meio desta congregação, e os consumirei num momento.',
        'Mas eles se prostraram sobre os seus rostos, e disseram: Ó Deus, Deus dos espíritos de toda a carne, pecará um só homem, e indignar-te-ás tu contra toda esta congregação?',
        'E falou o Senhor a Moisés, dizendo:',
        'Fala a toda esta congregação, dizendo: Subi do derredor da habitação de Coré, Datã e Abirão.',
        'Então Moisés levantou-se, e foi a Datã e a Abirão; e após ele seguiram os anciãos de Israel.',
        'E falou à congregação, dizendo: Desviai-vos, peço-vos, das tendas destes homens ímpios, e não toqueis nada do que é seu para que porventura não pereçais em todos os seus pecados.',
        'Subiram, pois, do derredor da habitação de Coré, Datã e Abirão. E Datã e Abirão saíram, e se puseram à porta das suas tendas, juntamente com as suas mulheres, e seus filhos, e suas crianças.',
        'Então disse Moisés: Nisto conhecereis que o Senhor me enviou a fazer todos estes feitos, que de meu coração não procedem.',
        'Se estes morrerem como morrem todos os homens, e se forem visitados como são visitados todos os homens, então o Senhor não me enviou.',
        'Mas, se o Senhor criar alguma coisa nova, e a terra abrir a sua boca e os tragar com tudo o que é seu, e vivos descerem ao abismo, então conhecereis que estes homens irritaram ao Senhor.',
        'E aconteceu que, acabando ele de falar todas estas palavras, a terra que estava debaixo deles se fendeu.',
        'E a terra abriu a sua boca, e os tragou com as suas casas, como também a todos os homens que pertenciam a Coré, e a todos os seus bens.',
        'E eles e tudo o que era seu desceram vivos ao abismo, e a terra os cobriu, e pereceram do meio da congregação.',
        'E todo o Israel, que estava ao redor deles, fugiu ao clamor deles; porque diziam: Para que não nos trague a terra também a nós.',
        'Então saiu fogo do Senhor, e consumiu os duzentos e cinqüenta homens que ofereciam o incenso.',
        'E falou o Senhor a Moisés, dizendo:',
        'Dize a Eleazar, filho de Arão, o sacerdote, que tome os incensários do meio do incêndio, e espalhe o fogo longe, porque santos são;',
        'Quanto aos incensários daqueles que pecaram contra as suas almas, deles se façam folhas estendidas para cobertura do altar; porquanto os trouxeram perante o Senhor; pelo que santos são; e serão por sinal aos filhos de Israel.',
        'E Eleazar, o sacerdote, tomou os incensários de metal, que trouxeram aqueles que foram queimados, e os estenderam em folhas para cobertura do altar,',
        'Por memorial para os filhos de Israel, que nenhum estranho, que não for da descendência de Arão, se chegue para acender incenso perante o Senhor; para que não seja como Coré e a sua congregação, como o Senhor lhe tinha dito por intermédio de Moisés,',
        'Mas no dia seguinte toda a congregação dos filhos de Israel murmurou contra Moisés e contra Arão, dizendo: Vós matastes o povo do Senhor.',
        'E aconteceu que, ajuntando-se a congregação contra Moisés e Arão, e virando-se para a tenda da congregação, eis que a nuvem a cobriu, e a glória do Senhor apareceu.',
        'Vieram, pois, Moisés e Arão perante a tenda da congregação.',
        'Então falou o Senhor a Moisés, dizendo:',
        'Levantai-vos do meio desta congregação, e a consumirei num momento; então se prostraram sobre os seus rostos,',
        'E disse Moisés a Arão: Toma o teu incensário, e põe nele fogo do altar, e deita incenso sobre ele, e vai depressa à congregação, e faze expiação por eles; porque grande indignação saiu de diante do Senhor; já começou a praga.',
        'E tomou-o Arão, como Moisés tinha falado, e correu ao meio da congregação; e eis que já a praga havia começado entre o povo; e deitou incenso nele, e fez expiação pelo povo.',
        'E estava em pé entre os mortos e os vivos; e cessou a praga.',
        'E os que morreram daquela praga foram catorze mil e setecentos, fora os que morreram pela causa de Coré.',
        'E voltou Arão a Moisés à porta da tenda da congregação; e cessou a praga.',
      ],
      [
        'Então falou o SENHOR a Moisés, dizendo:',
        'Fala aos filhos de Israel, e toma deles uma vara para cada casa paterna de todos os seus príncipes, segundo as casas de seus pais, doze varas; e escreverás o nome de cada um sobre a sua vara.',
        'Porém o nome de Arão escreverás sobre a vara de Levi; porque cada cabeça da casa de seus pais terá uma vara.',
        'E as porás na tenda da congregação, perante o testemunho, onde eu virei a vós.',
        'E será que a vara do homem que eu tiver escolhido florescerá; assim farei cessar as murmurações dos filhos de Israel contra mim, com que murmuram contra vós.',
        'Falou, pois, Moisés aos filhos de Israel; e todos os seus príncipes deram-lhe cada um uma vara, para cada príncipe uma vara, segundo as casas de seus pais, doze varas; e a vara de Arão estava entre as deles.',
        'E Moisés pôs estas varas perante o Senhor na tenda do testemunho.',
        'Sucedeu, pois, que no dia seguinte Moisés entrou na tenda do testemunho, e eis que a vara de Arão, pela casa de Levi, florescia; porque produzira flores e brotara renovos e dera amêndoas.',
        'Então Moisés tirou todas as varas de diante do Senhor a todos os filhos de Israel; e eles o viram, e tomaram cada um a sua vara.',
        'Então o Senhor disse a Moisés: Torna a pôr a vara de Arão perante o testemunho, para que se guarde por sinal para os filhos rebeldes; assim farás acabar as suas murmurações contra mim, e não morrerão.',
        'E Moisés fez assim; como lhe ordenara o Senhor, assim fez.',
        'Então falaram os filhos de Israel a Moisés, dizendo: Eis aqui, nós expiramos, perecemos, nós todos perecemos.',
        'Todo aquele que se aproximar do tabernáculo do Senhor, morrerá; seremos pois todos consumidos?',
      ],
      [
        'Então disse o SENHOR a Arão: Tu, e teus filhos, e a casa de teu pai contigo, levareis sobre vós a iniqüidade do santuário; e tu e teus filhos contigo levareis sobre vós a iniqüidade do vosso sacerdócio.',
        'E também farás chegar contigo a teus irmãos, a tribo de Levi, a tribo de teu pai, para que se ajuntem a ti, e te sirvam; mas tu e teus filhos contigo estareis perante a tenda do testemunho.',
        'E eles cumprirão as tuas ordens e terão o encargo de toda a tenda; mas não se chegarão aos utensílios do santuário, nem ao altar, para que não morram, tanto eles como vós.',
        'Mas se ajuntarão a ti, e farão o serviço da tenda da congregação em todo o ministério da tenda; e o estranho não se chegará a vós.',
        'Vós, pois, fareis o serviço do santuário e o serviço do altar; para que não haja outra vez furor sobre os filhos de Israel.',
        'E eu, eis que tenho tomado vossos irmãos, os levitas, do meio dos filhos de Israel; são dados a vós em dádiva pelo Senhor, para que sirvam ao ministério da tenda da congregação.',
        'Mas tu e teus filhos contigo cumprireis o vosso sacerdócio no tocante a tudo o que é do altar, e a tudo o que está dentro do véu, nisso servireis; eu vos tenho dado o vosso sacerdócio em dádiva ministerial e o estranho que se chegar morrerá.',
        'Disse mais o Senhor a Arão: Eis que eu te tenho dado a guarda das minhas ofertas alçadas, com todas as coisas santas dos filhos de Israel; por causa da unção as tenho dado a ti e a teus filhos por estatuto perpétuo.',
        'Isto terás das coisas santíssimas do fogo; todas as suas ofertas com todas as suas ofertas de alimentos, e com todas as suas expiações pelo pecado, e com todas as suas expiações pela culpa, que me apresentarão; serão coisas santíssimas para ti e para teus filhos.',
        'No lugar santíssimo as comerás; todo o homem a comerá; santas serão para ti.',
        'Também isto será teu: a oferta alçada dos seus dons com todas as ofertas movidas dos filhos de Israel; a ti, a teus filhos, e a tuas filhas contigo, as tenho dado por estatuto perpétuo; todo o que estiver limpo na tua casa, delas comerá.',
        'Todo o melhor do azeite, e todo o melhor do mosto e do grão, as suas primícias que derem ao Senhor, as tenho dado a ti.',
        'Os primeiros frutos de tudo que houver na terra, que trouxerem ao Senhor, serão teus; todo o que estiver limpo na tua casa os comerá.',
        'Toda a coisa consagrada em Israel será tua.',
        'Tudo que abrir a madre, e toda a carne que trouxerem ao Senhor, tanto de homens como de animais, será teu; porém os primogênitos dos homens resgatarás; também os primogênitos dos animais imundos resgatarás.',
        'Os que deles se houverem de resgatar resgatarás, da idade de um mês, segundo a tua avaliação, por cinco siclos de dinheiro, segundo o siclo do santuário, que é de vinte geras.',
        'Mas o primogênito de vaca, ou primogênito de ovelha, ou primogênito de cabra, não resgatarás, santos são; o seu sangue espargirás sobre o altar, e a sua gordura queimarás em oferta queimada de cheiro suave ao Senhor.',
        'E a carne deles será tua; assim como o peito da oferta de movimento, e o ombro direito, teus serão.',
        'Todas as ofertas alçadas das coisas santas, que os filhos de Israel oferecerem ao Senhor, tenho dado a ti, e a teus filhos e a tuas filhas contigo, por estatuto perpétuo; aliança perpétua de sal perante o Senhor é, para ti e para a tua descendência contigo.',
        'Disse também o Senhor a Arão: Na sua terra herança nenhuma terás, e no meio deles, nenhuma parte terás; eu sou a tua parte e a tua herança no meio dos filhos de Israel.',
        'E eis que aos filhos de Levi tenho dado todos os dízimos em Israel por herança, pelo ministério que executam, o ministério da tenda da congregação.',
        'E nunca mais os filhos de Israel se chegarão à tenda da congregação, para que não levem sobre si o pecado e morram.',
        'Mas os levitas executarão o ministério da tenda da congregação, e eles levarão sobre si a sua iniqüidade; pelas vossas gerações estatuto perpétuo será; e no meio dos filhos de Israel nenhuma herança terão,',
        'Porque os dízimos dos filhos de Israel, que oferecerem ao Senhor em oferta alçada, tenho dado por herança aos levitas; porquanto eu lhes disse: No meio dos filhos de Israel nenhuma herança terão.',
        'E falou o Senhor a Moisés, dizendo:',
        'Também falarás aos levitas, e dir-lhes-ás: Quando receberdes os dízimos dos filhos de Israel, que eu deles vos tenho dado por vossa herança, deles oferecereis uma oferta alçada ao Senhor, os dízimos dos dízimos.',
        'E contar-se-vos-á a vossa oferta alçada, como grão da eira, e como plenitude do lagar.',
        'Assim também oferecereis ao Senhor uma oferta alçada de todos os vossos dízimos, que receberdes dos filhos de Israel, e deles dareis a oferta alçada do Senhor a Arão, o sacerdote.',
        'De todas as vossas dádivas oferecereis toda a oferta alçada do Senhor; de tudo o melhor deles, a sua santa parte.',
        'Dir-lhes-ás pois: Quando oferecerdes o melhor deles, como novidade da eira, e como novidade do lagar, se contará aos levitas.',
        'E o comereis em todo o lugar, vós e as vossas famílias, porque vosso galardão é pelo vosso ministério na tenda da congregação.',
        'Assim, não levareis sobre vós o pecado, quando deles oferecerdes o melhor; e não profanareis as coisas santas dos filhos de Israel, para que não morrais.',
      ],
      [
        'Falou mais o SENHOR a Moisés e a Arão dizendo:',
        'Este é o estatuto da lei, que o Senhor ordenou, dizendo: Dize aos filhos de Israel que te tragam uma novilha ruiva, que não tenha defeito, e sobre a qual não tenha sido posto jugo.',
        'E a dareis a Eleazar, o sacerdote; ele a tirará para fora do arraial, e degolar-se-á diante dele.',
        'E Eleazar, o sacerdote, tomará do seu sangue com o seu dedo, e dele espargirá para a frente da tenda da congregação sete vezes.',
        'Então queimará a novilha perante os seus olhos; o seu couro, e a sua carne, e o seu sangue, com o seu esterco, se queimará.',
        'E o sacerdote tomará pau de cedro, e hissopo, e carmesim, e os lançará no meio do fogo que queima a novilha.',
        'Então o sacerdote lavará as suas vestes, e banhará a sua carne na água, e depois entrará no arraial; e o sacerdote será imundo até à tarde.',
        'Também o que a queimou lavará as suas vestes com água, e em água banhará a sua carne, e imundo será até à tarde.',
        'E um homem limpo ajuntará a cinza da novilha, e a porá fora do arraial, num lugar limpo, e ficará ela guardada para a congregação dos filhos de Israel, para a água da separação; expiação é.',
        'E o que apanhou a cinza da novilha lavará as suas vestes, e será imundo até à tarde; isto será por estatuto perpétuo aos filhos de Israel e ao estrangeiro que peregrina no meio deles.',
        'Aquele que tocar em algum morto, cadáver de algum homem, imundo será sete dias.',
        'Ao terceiro dia se purificará com aquela água, e ao sétimo dia será limpo; mas, se ao terceiro dia se não purificar, não será limpo ao sétimo dia.',
        'Todo aquele que tocar em algum morto, cadáver de algum homem, e não se purificar, contamina o tabernáculo do Senhor; e aquela pessoa será extirpada de Israel; porque a água da separação não foi espargida sobre ele, imundo será; está nele ainda a sua imundícia.',
        'Esta é a lei, quando morrer algum homem em alguma tenda, todo aquele que entrar naquela tenda, e todo aquele que nela estiver, será imundo sete dias.',
        'Também todo o vaso aberto, sobre o qual não houver pano atado, será imundo.',
        'E todo aquele que sobre a face do campo tocar em alguém que for morto pela espada, ou em outro morto ou nos ossos de algum homem, ou numa sepultura, será imundo sete dias.',
        'Para um imundo, pois, tomarão da cinza da queima da expiação, e sobre ela colocarão água corrente num vaso.',
        'E um homem limpo tomará hissopo, e o molhará naquela água, e a espargirá sobre aquela tenda, e sobre todos os móveis, e sobre as pessoas que ali estiverem, como também sobre aquele que tocar os ossos, ou em alguém que foi morto, ou que faleceu, ou numa sepultura.',
        'E o limpo ao terceiro e sétimo dia espargirá sobre o imundo; e ao sétimo dia o purificará; e lavará as suas vestes, e se banhará na água, e à tarde será limpo.',
        'Porém o que for imundo, e se não purificar, do meio da congregação será ele extirpado; porquanto contaminou o santuário do Senhor; água de separação sobre ele não foi espargida; imundo é.',
        'Isto lhes será por estatuto perpétuo; e o que espargir a água da separação lavará as suas vestes; e o que tocar a água da separação será imundo até à tarde,',
        'E tudo o que tocar o imundo também será imundo; e a pessoa que o tocar será imunda até à tarde.',
      ],
      [
        'Chegando os filhos de Israel, toda a congregação, ao deserto de Zim, no mês primeiro, o povo ficou em Cades; e Miriã morreu ali, e ali foi sepultada.',
        'E não havia água para a congregação; então se reuniram contra Moisés e contra Arão.',
        'E o povo contendeu com Moisés, dizendo: Quem dera tivéssemos perecido quando pereceram nossos irmãos perante o Senhor!',
        'E por que trouxestes a congregação do Senhor a este deserto, para que morramos aqui, nós e os nossos animais?',
        'E por que nos fizestes subir do Egito, para nos trazer a este lugar mau? lugar onde não há semente, nem de figos, nem de vides, nem de romãs, nem tem água para beber.',
        'Então Moisés e Arão se foram de diante do povo à porta da tenda da congregação, e se lançaram sobre os seus rostos; e a glória do Senhor lhes apareceu.',
        'E o Senhor falou a Moisés dizendo:',
        'Toma a vara, e ajunta a congregação, tu e Arão, teu irmão, e falai à rocha, perante os seus olhos, e dará a sua água; assim lhes tirarás água da rocha, e darás a beber à congregação e aos seus animais.',
        'Então Moisés tomou a vara de diante do Senhor, como lhe tinha ordenado.',
        'E Moisés e Arão reuniram a congregação diante da rocha, e Moisés disse-lhes: Ouvi agora, rebeldes, porventura tiraremos água desta rocha para vós?',
        'Então Moisés levantou a sua mão, e feriu a rocha duas vezes com a sua vara, e saiu muita água; e bebeu a congregação e os seus animais.',
        'E o Senhor disse a Moisés e a Arão: Porquanto não crestes em mim, para me santificardes diante dos filhos de Israel, por isso não introduzireis esta congregação na terra que lhes tenho dado.',
        'Estas são as águas de Meribá, porque os filhos de Israel contenderam com o Senhor; e se santificou neles.',
        'Depois Moisés, de Cades, mandou mensageiros ao rei de Edom, dizendo: Assim diz teu irmão Israel: Sabes todo o trabalho que nos sobreveio,',
        'Como nossos pais desceram ao Egito, e nós no Egito habitamos muitos dias; e como os egípcios nos maltrataram, a nós e a nossos pais;',
        'E clamamos ao Senhor, e ele ouviu a nossa voz, e mandou um anjo, e nos tirou do Egito; e eis que estamos em Cades, cidade na extremidade dos teus termos.',
        'Deixa-nos, pois, passar pela tua terra; não passaremos pelo campo, nem pelas vinhas, nem beberemos a água dos poços; iremos pela estrada real; não nos desviaremos para a direita nem para a esquerda, até que passemos pelos teus termos.',
        'Porém Edom lhe disse: Não passarás por mim, para que eu não saia com a espada ao teu encontro.',
        'Então os filhos de Israel lhe disseram: Subiremos pelo caminho aplanado, e se eu e o meu gado bebermos das tuas águas, darei o preço delas; não desejo alguma outra coisa, senão passar a pé.',
        'Porém ele disse: Não passarás. E saiu-lhe Edom ao encontro com muita gente, e com mão forte.',
        'Assim recusou Edom deixar passar a Israel pelo seu termo; por isso Israel se desviou dele.',
        'Então partiram de Cades; e os filhos de Israel, toda a congregação, chegaram ao monte Hor.',
        'E falou o Senhor a Moisés e a Arão no monte Hor, nos termos da terra de Edom, dizendo:',
        'Arão será recolhido a seu povo, porque não entrará na terra que tenho dado aos filhos de Israel, porquanto rebeldes fostes à minha ordem, nas águas de Meribá.',
        'Toma a Arão e a Eleazar, seu filho, e faze-os subir ao monte Hor.',
        'E despe a Arão as suas vestes, e veste-as em Eleazar, seu filho, porque Arão será recolhido, e morrerá ali.',
        'Fez, pois, Moisés como o Senhor lhe ordenara; e subiram ao monte Hor perante os olhos de toda a congregação.',
        'E Moisés despiu a Arão de suas vestes, e as vestiu em Eleazar, seu filho; e morreu Arão ali sobre o cume do monte; e desceram Moisés e Eleazar do monte.',
        'Vendo, pois, toda a congregação que Arão era morto, choraram a Arão trinta dias, toda a casa de Israel.',
      ],
      [
        'Ouvindo o cananeu, rei de Arade, que habitava para o lado sul, que Israel vinha pelo caminho dos espias, pelejou contra Israel, e dele levou alguns prisioneiros.',
        'Então Israel fez um voto ao Senhor, dizendo: Se de fato entregares este povo na minha mão, destruirei totalmente as suas cidades.',
        'O Senhor, pois, ouviu a voz de Israel, e lhe entregou os cananeus; e os israelitas destruíram totalmente, a eles e às suas cidades; e o nome daquele lugar chamou Hormá.',
        'Então partiram do monte Hor, pelo caminho do Mar Vermelho, a rodear a terra de Edom; porém a alma do povo angustiou-se naquele caminho.',
        'E o povo falou contra Deus e contra Moisés: Por que nos fizestes subir do Egito para que morrêssemos neste deserto? Pois aqui nem pão nem água há; e a nossa alma tem fastio deste pão tão vil.',
        'Então o Senhor mandou entre o povo serpentes ardentes, que picaram o povo; e morreu muita gente em Israel.',
        'Por isso o povo veio a Moisés, e disse: Havemos pecado, porquanto temos falado contra o Senhor e contra ti; ora ao Senhor que tire de nós estas serpentes. Então Moisés orou pelo povo.',
        'E disse o Senhor a Moisés: Faze-te uma serpente ardente, e põe-na sobre uma haste; e será que viverá todo o que, tendo sido picado, olhar para ela.',
        'E Moisés fez uma serpente de metal, e pô-la sobre uma haste; e sucedia que, picando alguma serpente a alguém, quando esse olhava para a serpente de metal, vivia.',
        'Então os filhos de Israel partiram, e alojaram-se em Obote.',
        'Depois partiram de Obote e alojaram-se nos outeiros de Ije-Abarim, no deserto que está defronte de Moabe, ao nascente do sol.',
        'Dali partiram, e alojaram-se junto ao ribeiro de Zerede.',
        'E dali partiram e alojaram-se no lado de Arnom, que está no deserto e sai dos termos dos amorreus; porque Arnom é o termo de Moabe, entre Moabe e os amorreus.',
        'Por isso se diz no livro das guerras do Senhor: O que fiz no Mar Vermelho e nos ribeiros de Arnom,',
        'E à corrente dos ribeiros, que descendo para a situação de Ar, se encosta aos termos de Moabe.',
        'E dali partiram para Beer; este é o poço do qual o Senhor disse a Moisés: Ajunta o povo e lhe darei água.',
        'Então Israel cantou este cântico: Brota, ó poço! Cantai dele:',
        'Tu, poço, que cavaram os príncipes, que escavaram os nobres do povo, e o legislador com os seus bordões; e do deserto partiram para Mataná;',
        'E de Mataná a Naaliel, e de Naaliel a Bamote.',
        'E de Bamote ao vale que está no campo de Moabe, no cume de Pisga, e à vista do deserto.',
        'Então Israel mandou mensageiros a Siom, rei dos amorreus, dizendo:',
        'Deixa-me passar pela tua terra; não nos desviaremos pelos campos nem pelas vinhas; as águas dos poços não beberemos; iremos pela estrada real até que passemos os teus termos.',
        'Porém Siom não deixou passar a Israel pelos seus termos; antes Siom congregou todo o seu povo, e saiu ao encontro de Israel no deserto, e veio a Jaza, e pelejou contra Israel.',
        'Mas Israel o feriu ao fio da espada, e tomou a sua terra em possessão, desde Arnom até Jaboque, até aos filhos de Amom; porquanto o termo dos filhos de Amom era forte.',
        'Assim Israel tomou todas as cidades; e habitou em todas elas, em Hesbom e em todas as suas aldeias.',
        'Porque Hesbom era cidade de Siom, rei dos amorreus, que tinha pelejado contra o precedente rei dos moabitas, e tinha tomado da sua mão toda a sua terra até Arnom.',
        'Por isso dizem os que falam em provérbios: Vinde a Hesbom; edifique-se e estabeleça-se a cidade de Siom.',
        'Porque fogo saiu de Hesbom, e uma chama da cidade de Siom; e consumiu a Ar dos moabitas, e os senhores dos altos de Arnom.',
        'Ai de ti, Moabe! perdido és, povo de Quemos! entregou seus filhos, que iam fugindo, e suas filhas, como cativas a Siom, rei dos amorreus.',
        'E nós os derribamos; Hesbom perdida é até Dibom, e os assolamos até Nofá, que se estende até Medeba.',
        'Assim Israel habitou na terra dos amorreus.',
        'Depois mandou Moisés espiar a Jazer, e tomaram as suas aldeias, e daquela possessão lançaram os amorreus que estavam ali.',
        'Então viraram-se, e subiram o caminho de Basã; e Ogue, rei de Basã, saiu contra eles, ele e todo o seu povo, à peleja em Edrei.',
        'E disse o Senhor a Moisés: Não o temas, porque eu o tenho dado na tua mão, a ele, e a todo o seu povo, e a sua terra, e far-lhe-ás como fizeste a Siom, rei dos amorreus, que habitava em Hesbom.',
        'E de tal maneira o feriram, a ele e a seus filhos, e a todo o seu povo, que nenhum deles escapou; e tomaram a sua terra em possessão.',
      ],
      [
        'Depois partiram os filhos de Israel, e acamparam-se nas campinas de Moabe, além do Jordão na altura de Jericó.',
        'Vendo, pois, Balaque, filho de Zipor, tudo o que Israel fizera aos amorreus,',
        'Moabe temeu muito diante deste povo, porque era numeroso; e Moabe andava angustiado por causa dos filhos de Israel.',
        'Por isso Moabe disse aos anciãos dos midianitas: Agora lamberá esta congregação tudo quanto houver ao redor de nós, como o boi lambe a erva do campo. Naquele tempo Balaque, filho de Zipor, era rei dos moabitas.',
        'Este enviou mensageiros a Balaão, filho de Beor, a Petor, que está junto ao rio, na terra dos filhos do seu povo, a chamá-lo, dizendo: Eis que um povo saiu do Egito; eis que cobre a face da terra, e está parado defronte de mim.',
        'Vem, pois, agora, rogo-te, amaldiçoa-me este povo, pois mais poderoso é do que eu; talvez o poderei ferir e lançar fora da terra; porque eu sei que, a quem tu abençoares será abençoado, e a quem tu amaldiçoares será amaldiçoado.',
        'Então foram-se os anciãos dos moabitas e os anciãos dos midianitas com o preço dos encantamentos nas suas mãos; e chegaram a Balaão, e disseram-lhe as palavras de Balaque.',
        'E ele lhes disse: Passai aqui esta noite, e vos trarei a resposta, como o Senhor me falar; então os príncipes dos moabitas ficaram com Balaão.',
        'E veio Deus a Balaão, e disse: Quem são estes homens que estão contigo?',
        'E Balaão disse a Deus: Balaque, filho de Zipor, rei dos moabitas, os enviou, dizendo:',
        'Eis que o povo que saiu do Egito cobre a face da terra; vem agora, amaldiçoa-o; porventura poderei pelejar contra ele e expulsá-lo.',
        'Então disse Deus a Balaão: Não irás com eles, nem amaldiçoarás a este povo, porquanto é bendito.',
        'Então Balaão levantou-se pela manhã, e disse aos príncipes de Balaque: Ide à vossa terra, porque o Senhor recusa deixar-me ir convosco.',
        'E levantaram-se os príncipes dos moabitas, e vieram a Balaque, e disseram: Balaão recusou vir conosco.',
        'Porém Balaque tornou a enviar mais príncipes, mais honrados do que aqueles.',
        'Os quais foram a Balaão, e lhe disseram: Assim diz Balaque, filho de Zipor: Rogo-te que não te demores em vir a mim.',
        'Porque grandemente te honrarei, e farei tudo o que me disseres; vem pois, rogo-te, amaldiçoa-me este povo.',
        'Então Balaão respondeu, e disse aos servos de Balaque: Ainda que Balaque me desse a sua casa cheia de prata e de ouro, eu não poderia ir além da ordem do Senhor meu Deus, para fazer coisa pequena ou grande;',
        'Agora, pois, rogo-vos que também aqui fiqueis esta noite, para que eu saiba o que mais o Senhor me dirá.',
        'Veio, pois, Deus a Balaão, de noite, e disse-lhe: Se aqueles homens te vieram chamar, levanta-te, vai com eles; todavia, farás o que eu te disser.',
        'Então Balaão levantou-se pela manhã, e albardou a sua jumenta, e foi com os príncipes de Moabe.',
        'E a ira de Deus acendeu-se, porque ele se ia; e o anjo do Senhor pôs-se-lhe no caminho por adversário; e ele ia caminhando, montado na sua jumenta, e dois de seus servos com ele.',
        'Viu, pois, a jumenta o anjo do Senhor, que estava no caminho, com a sua espada desembainhada na mão; pelo que desviou-se a jumenta do caminho, indo pelo campo; então Balaão espancou a jumenta para fazê-la tornar ao caminho.',
        'Mas o anjo do Senhor pôs-se numa vereda entre as vinhas, havendo uma parede de um e de outro lado.',
        'Vendo, pois, a jumenta, o anjo do Senhor, encostou-se contra a parede, e apertou contra a parede o pé de Balaão; por isso tornou a espancá-la.',
        'Então o anjo do Senhor passou mais adiante, e pôs-se num lugar estreito, onde não havia caminho para se desviar nem para a direita nem para a esquerda.',
        'E, vendo a jumenta o anjo do Senhor, deitou-se debaixo de Balaão; e a ira de Balaão acendeu-se, e espancou a jumenta com o bordão.',
        'Então o Senhor abriu a boca da jumenta, a qual disse a Balaão: Que te fiz eu, que me espancaste estas três vezes?',
        'E Balaão disse à jumenta: Por que zombaste de mim; quem dera tivesse eu uma espada na mão, porque agora te mataria.',
        'E a jumenta disse a Balaão: Porventura não sou a tua jumenta, em que cavalgaste desde o tempo em que me tornei tua até hoje? Acaso tem sido o meu costume fazer assim contigo? E ele respondeu: Não.',
        'Então o Senhor abriu os olhos a Balaão, e ele viu o anjo do Senhor, que estava no caminho e a sua espada desembainhada na mão; pelo que inclinou a cabeça, e prostrou-se sobre a sua face.',
        'Então o anjo do Senhor lhe disse: Por que já três vezes espancaste a tua jumenta? Eis que eu saí para ser teu adversário, porquanto o teu caminho é perverso diante de mim;',
        'Porém a jumenta me viu, e já três vezes se desviou de diante de mim; se ela não se desviasse de diante de mim, na verdade que eu agora te haveria matado, e a ela deixaria com vida.',
        'Então Balaão disse ao anjo do Senhor: Pequei, porque não sabia que estavas neste caminho para te opores a mim; e agora, se parece mal aos teus olhos, voltarei.',
        'E disse o anjo do Senhor a Balaão: Vai-te com estes homens; mas somente a palavra que eu falar a ti, esta falarás. Assim Balaão se foi com os príncipes de Balaque.',
        'Ouvindo, pois, Balaque que Balaão vinha, saiu-lhe ao encontro até à cidade de Moabe, que está no termo de Arnom, na extremidade do termo dele.',
        'E Balaque disse a Balaão: Porventura não enviei diligentemente a chamar-te? Por que não vieste a mim? Não posso eu na verdade honrar-te?',
        'Então Balaão disse a Balaque: Eis que eu tenho vindo a ti; porventura poderei eu agora de alguma forma falar alguma coisa? A palavra que Deus puser na minha boca, essa falarei.',
        'E Balaão foi com Balaque, e chegaram a Quiriate-Huzote.',
        'Então Balaque matou bois e ovelhas; e deles enviou a Balaão e aos príncipes que estavam com ele.',
        'E sucedeu que, pela manhã Balaque tomou a Balaão, e o fez subir aos altos de Baal, e viu ele dali a última parte do povo.',
      ],
      [
        'Então Balaão disse a Balaque: Edifica-me aqui sete altares, e prepara-me aqui sete novilhos e sete carneiros.',
        'Fez, pois, Balaque como Balaão dissera: e Balaque e Balaão ofereceram um novilho e um carneiro sobre cada altar.',
        'Então Balaão disse a Balaque: Fica-te junto do teu holocausto, e eu irei; porventura o Senhor me sairá ao encontro, e o que me mostrar te notificarei. Então foi a um lugar alto.',
        'E encontrando-se Deus com Balaão, este lhe disse: Preparei sete altares, e ofereci um novilho e um carneiro sobre cada altar.',
        'Então o Senhor pôs a palavra na boca de Balaão, e disse: Torna-te para Balaque, e assim falarás.',
        'E tornando para ele, eis que estava junto do seu holocausto, ele e todos os príncipes dos moabitas.',
        'Então proferiu a sua parábola, e disse: De Arã, me mandou trazer Balaque, rei dos moabitas, das montanhas do oriente, dizendo: Vem, amaldiçoa-me a Jacó; e vem, denuncia a Israel.',
        'Como amaldiçoarei o que Deus não amaldiçoa? E como denunciarei, quando o Senhor não denuncia?',
        'Porque do cume das penhas o vejo, e dos outeiros o contemplo; eis que este povo habitará só, e entre as nações não será contado.',
        'Quem contará o pó de Jacó e o número da quarta parte de Israel? Que a minha alma morra da morte dos justos, e seja o meu fim como o seu.',
        'Então disse Balaque a Balaão: Que me fizeste? Chamei-te para amaldiçoar os meus inimigos, mas eis que inteiramente os abençoaste.',
        'E ele respondeu, e disse: Porventura não terei cuidado de falar o que o Senhor pôs na minha boca?',
        'Então Balaque lhe disse: Rogo-te que venhas comigo a outro lugar, de onde o verás; verás somente a última parte dele, mas a todo ele não verás; e amaldiçoa-mo dali.',
        'Assim o levou consigo ao campo de Zofim, ao cume de Pisga; e edificou sete altares, e ofereceu um novilho e um carneiro sobre cada altar.',
        'Então disse a Balaque: Fica aqui junto do teu holocausto, e eu irei ali ao encontro do Senhor.',
        'E, encontrando-se o Senhor com Balaão, pôs uma palavra na sua boca, e disse: Torna para Balaque, e assim falarás.',
        'E, vindo a ele, eis que estava junto do holocausto, e os príncipes dos moabitas com ele; disse-lhe pois Balaque: Que coisa falou o Senhor?',
        'Então proferiu a sua parábola, e disse: Levanta-te, Balaque, e ouve; inclina os teus ouvidos a mim, filho de Zipor.',
        'Deus não é homem, para que minta; nem filho do homem, para que se arrependa; porventura diria ele, e não o faria? Ou falaria, e não o confirmaria?',
        'Eis que recebi mandado de abençoar; pois ele tem abençoado, e eu não o posso revogar.',
        'Não viu iniqüidade em Israel, nem contemplou maldade em Jacó; o Senhor seu Deus é com ele, e no meio dele se ouve a aclamação de um rei.',
        'Deus os tirou do Egito; as suas forças são como as do boi selvagem.',
        'Pois contra Jacó não vale encantamento, nem adivinhação contra Israel; neste tempo se dirá de Jacó e de Israel: Que coisas Deus tem realizado!',
        'Eis que o povo se levantará como leoa, e se erguerá como leão; não se deitará até que coma a presa, e beba o sangue dos mortos.',
        'Então Balaque disse a Balaão: Nem o amaldiçoarás, nem o abençoarás.',
        'Porém Balaão respondeu, e disse a Balaque: Não te falei eu, dizendo: Tudo o que o Senhor falar isso farei?',
        'Disse mais Balaque a Balaão: Ora vem, e te levarei a outro lugar; porventura bem parecerá aos olhos de Deus que dali mo amaldiçoes.',
        'Então Balaque levou Balaão consigo ao cume de Peor, que dá para o lado do deserto.',
        'Balaão disse a Balaque: Edifica-me aqui sete altares, e prepara-me aqui sete novilhos e sete carneiros.',
        'Balaque, pois, fez como dissera Balaão: e ofereceu um novilho e um carneiro sobre cada altar.',
      ],
      [
        'Vendo Balaão que bem parecia aos olhos do SENHOR que abençoasse a Israel, não se foi esta vez como antes ao encontro dos encantamentos; mas voltou o seu rosto para o deserto.',
        'E, levantando Balaão os seus olhos, e vendo a Israel, que estava acampado segundo as suas tribos, veio sobre ele o Espírito de Deus.',
        'E proferiu a sua parábola, e disse: Fala, Balaão, filho de Beor, e fala o homem de olhos abertos;',
        'Fala aquele que ouviu as palavras de Deus, o que vê a visão do Todo-Poderoso; que cai, e se lhe abrem os olhos:',
        'Quão formosas são as tuas tendas, ó Jacó, as tuas moradas, ó Israel!',
        'Como ribeiros se estendem, como jardins à beira dos rios; como árvores de sândalo o Senhor os plantou, como cedros junto às águas;',
        'De seus baldes manarão águas, e a sua semente estará em muitas águas; e o seu rei se erguerá mais do que Agague, e o seu reino será exaltado.',
        'Deus o tirou do Egito; as suas forças são como as do boi selvagem; consumirá as nações, seus inimigos, e quebrará seus ossos, e com as suas setas os atravessará.',
        'Encurvou-se, deitou-se como leão, e como leoa; quem o despertará? benditos os que te abençoarem, e malditos os que te amaldiçoarem.',
        'Então a ira de Balaque se acendeu contra Balaão, e bateu ele as suas palmas; e Balaque disse a Balaão: Para amaldiçoar os meus inimigos te tenho chamado; porém agora já três vezes os abençoaste inteiramente.',
        'Agora, pois, foge para o teu lugar; eu tinha dito que te honraria grandemente; mas eis que o Senhor te privou desta honra.',
        'Então Balaão disse a Balaque: Não falei eu também aos teus mensageiros, que me enviaste, dizendo:',
        'Ainda que Balaque me desse a sua casa cheia de prata e ouro, não poderia ir além da ordem do Senhor, fazendo bem ou mal de meu próprio coração; o que o Senhor falar, isso falarei eu?',
        'Agora, pois, eis que me vou ao meu povo; vem, avisar-te-ei do que este povo fará ao teu povo nos últimos dias.',
        'Então proferiu a sua parábola, e disse: Fala Balaão, filho de Beor, e fala o homem de olhos abertos;',
        'Fala aquele que ouviu as palavras de Deus, e o que sabe a ciência do Altíssimo; o que viu a visão do Todo-Poderoso, que cai, e se lhe abrem os olhos.',
        'Vê-lo-ei, mas não agora, contemplá-lo-ei, mas não de perto; uma estrela procederá de Jacó e um cetro subirá de Israel, que ferirá os termos dos moabitas, e destruirá todos os filhos de Sete.',
        'E Edom será uma possessão, e Seir, seus inimigos, também será uma possessão; pois Israel fará proezas.',
        'E dominará um de Jacó, e matará os que restam das cidades.',
        'E vendo os amalequitas, proferiu a sua parábola, e disse: Amaleque é a primeira das nações; porém o seu fim será a destruição.',
        'E vendo os quenitas, proferiu a sua parábola, e disse: Firme está a tua habitação, e puseste o teu ninho na penha.',
        'Todavia o quenita será consumido, até que Assur te leve por prisioneiro.',
        'E, proferindo ainda a sua parábola, disse: Ai, quem viverá, quando Deus fizer isto?',
        'E as naus virão das costas de Quitim e afligirão a Assur; também afligirão a Éber; que também será para destruição.',
        'Então Balaão levantou-se, e se foi, e voltou ao seu lugar, e também Balaque se foi pelo seu caminho.',
      ],
      [
        'E Israel deteve-se em Sitim e o povo começou a prostituir-se com as filhas dos moabitas.',
        'Elas convidaram o povo aos sacrifícios dos seus deuses; e o povo comeu, e inclinou-se aos seus deuses.',
        'Juntando-se, pois, Israel a Baal-peor, a ira do Senhor se acendeu contra Israel.',
        'Disse o Senhor a Moisés: Toma todos os cabeças do povo, e enforca-os ao Senhor diante do sol, e o ardor da ira do Senhor se retirará de Israel.',
        'Então Moisés disse aos juízes de Israel: Cada um mate os seus homens que se juntaram a Baal-peor.',
        'E eis que veio um homem dos filhos de Israel, e trouxe a seus irmãos uma midianita, à vista de Moisés, e à vista de toda a congregação dos filhos de Israel, chorando eles diante da tenda da congregação.',
        'Vendo isso Finéias, filho de Eleazar, o filho de Arão, sacerdote, se levantou do meio da congregação, e tomou uma lança na sua mão;',
        'E foi após o homem israelita até à tenda, e os atravessou a ambos, ao homem israelita e à mulher, pelo ventre; então a praga cessou de sobre os filhos de Israel.',
        'E os que morreram daquela praga foram vinte e quatro mil.',
        'Então o Senhor falou a Moisés, dizendo:',
        'Finéias, filho de Eleazar, o filho de Arão, sacerdote, desviou a minha ira de sobre os filhos de Israel, pois foi zeloso com o meu zelo no meio deles; de modo que, no meu zelo, não consumi os filhos de Israel.',
        'Portanto dize: Eis que lhe dou a minha aliança de paz;',
        'E ele, e a sua descendência depois dele, terá a aliança do sacerdócio perpétuo, porquanto teve zelo pelo seu Deus, e fez expiação pelos filhos de Israel.',
        'E o nome do israelita, que foi morto com a midianita, era Zimri, filho de Salu, príncipe da casa paterna dos simeonitas.',
        'E o nome da mulher midianita morta era Cosbi, filha de Zur, cabeça do povo da casa paterna entre os midianitas.',
        'Falou mais o Senhor a Moisés, dizendo:',
        'Afligireis os midianitas e os ferireis,',
        'Porque eles vos afligiram a vós com os seus enganos com que vos enganaram no caso de Peor, e no caso de Cosbi, filha do príncipe dos midianitas, irmã deles, que foi morta no dia da praga no caso de Peor.',
      ],
      [
        'Aconteceu, pois, que, depois daquela praga, falou o SENHOR a Moisés, e a Eleazar, filho de Arão, o sacerdote, dizendo:',
        'Tomai a soma de toda a congregação dos filhos de Israel, da idade de vinte anos para cima, segundo as casas de seus pais; todos os que em Israel podem sair à guerra.',
        'Falaram-lhes, pois, Moisés e Eleazar, o sacerdote, nas campinas de Moabe, junto ao Jordão na altura de Jericó, dizendo:',
        'Conta o povo da idade de vinte anos para cima, como o Senhor ordenara a Moisés e aos filhos de Israel, que saíram do Egito.',
        'Rúben, o primogênito de Israel; os filhos de Rúben: de Enoque, a família dos enoquitas; de Palu, a família dos paluítas;',
        'De Hezrom, a família dos hezronitas; de Carmi, a família dos carmitas.',
        'Estas são as famílias dos rubenitas; e os que foram deles contados foram quarenta e três mil e setecentos e trinta.',
        'E os filhos de Palu, Eliabe;',
        'E os filhos de Eliabe, Nemuel, e Datã, e Abirão: estes, Datã e Abirão, foram os do conselho da congregação, que contenderam contra Moisés e contra Arão no grupo de Coré, quando rebelaram contra o Senhor;',
        'E a terra abriu a sua boca, e os tragou com Coré, quando morreu aquele grupo; quando o fogo consumiu duzentos e cinqüenta homens, os quais serviram de advertência.',
        'Mas os filhos de Coré não morreram.',
        'Os filhos de Simeão, segundo as suas famílias: de Nemuel, a família dos nemuelitas; de Jamim, a família dos jaminitas; de Jaquim, a família dos jaquinitas;',
        'De Zerá, a família dos zeraítas; de Saul, a família dos saulitas.',
        'Estas são as famílias dos simeonitas, vinte e dois mil e duzentos.',
        'Os filhos de Gade, segundo as suas gerações; de Zefom, a família dos zefonitas; de Hagi, a família dos hagitas; de Suni, a família dos sunitas;',
        'De Ozni, a família dos oznitas; de Eri, a família dos eritas;',
        'De Arode, a família dos aroditas; de Areli, a família dos arelitas.',
        'Estas são as famílias dos filhos de Gade, segundo os que foram deles contados, quarenta mil e quinhentos.',
        'Os filhos de Judá, Er e Onã; mas Er e Onã morreram na terra de Canaã.',
        'Assim os filhos de Judá foram segundo as suas famílias; de Selá, a família dos selanitas; de Perez, a família dos perezitas; de Zerá, a família dos zeraítas.',
        'E os filhos de Perez foram: de Hezrom, a família dos hezronitas; de Hamul, a família dos hamulitas.',
        'Estas são as famílias de Judá, segundo os que foram deles contados, setenta e seis mil e quinhentos.',
        'Os filhos de Issacar, segundo as suas famílias, foram: de Tola, a família dos tolaítas; de Puva, a família dos puvitas;',
        'De Jasube, a família dos jasubitas; de Sinrom, a família dos sinronitas.',
        'Estas são as famílias de Issacar, segundo os que foram deles contados, sessenta e quatro mil e trezentos.',
        'Os filhos de Zebulom, segundo as suas famílias, foram: de Serede, a família dos sereditas; de Elom, a família dos elonitas; de Jaleel, a família dos jaleelitas.',
        'Estas são as famílias dos zebulonitas, segundo os que foram deles contados, sessenta mil e quinhentos.',
        'Os filhos de José segundo as suas famílias, foram Manassés e Efraim.',
        'Os filhos de Manassés foram; de Maquir, a família dos maquiritas; e Maquir gerou a Gileade; de Gileade, a família dos gileaditas.',
        'Estes são os filhos de Gileade; de Jezer, a família dos jezeritas; de Heleque, a família dos helequitas;',
        'E de Asriel, a família dos asrielitas; e de Siquém, a família dos siquemitas;',
        'E de Semida, a família dos semidaítas; e de Hefer, a família dos heferitas.',
        'Porém, Zelofeade, filho de Hefer, não tinha filhos, senão filhas; e os nomes das filhas de Zelofeade foram Maalá, Noa, Hogla, Milca e Tirza.',
        'Estas são as famílias de Manassés; e os que foram deles contados, foram cinqüenta e dois mil e setecentos.',
        'Estes são os filhos de Efraim, segundo as suas famílias: de Sutela, a família dos sutelaítas; de Bequer, a família dos bequeritas; de Taã, a família dos taanitas.',
        'E estes são os filhos de Sutela: de Erã, a família dos eranitas.',
        'Estas são as famílias dos filhos de Efraim, segundo os que foram deles contados, trinta e dois mil e quinhentos; estes são os filhos de José, segundo as suas famílias.',
        'Os filhos de Benjamim, segundo as suas famílias: de Belá, a família dos belaítas; de Asbel, a família dos asbelitas; de Airã, a família dos airamitas;',
        'De Sufã, a família dos sufamitas; de Hufã, a família dos hufamitas.',
        'E os filhos de Belá foram Arde e Naamã; de Arde, a família dos arditas; de Naamã, a família dos naamanitas.',
        'Estes são os filhos de Benjamim, segundo as suas famílias; e os que foram deles contados, foram quarenta e cinco mil e seiscentos.',
        'Estes são os filhos de Dã, segundo as suas famílias; de Suã, a família dos suamitas. Estas são as famílias de Dã, segundo as suas famílias.',
        'Todas as famílias dos suamitas, segundo os que foram deles contados, foram sessenta e quatro mil e quatrocentos.',
        'Os filhos de Aser, segundo as suas famílias, foram: de Imna, a família dos imnaítas; de Isvi, a família dos isvitas; de Berias, a família dos beriítas.',
        'Dos filhos de Berias, foram; de Héber, a família dos heberitas; de Malquiel, a família dos malquielitas.',
        'E o nome da filha de Aser foi Sera.',
        'Estas são as famílias dos filhos de Aser, segundo os que foram deles contados, cinqüenta e três mil e quatrocentos.',
        'Os filhos de Naftali, segundo as suas famílias; de Jazeel, a família dos jazeelitas; de Guni, a família dos gunitas;',
        'De Jezer, a família dos jezeritas; de Silém, a família dos silemitas.',
        'Estas são as famílias de Naftali, segundo as suas famílias; e os que foram deles contados, foram quarenta e cinco mil e quatrocentos.',
        'Estes são os que foram contados dos filhos de Israel, seiscentos e um mil e setecentos e trinta.',
        'E falou o Senhor a Moisés, dizendo:',
        'A estes se repartirá a terra em herança, segundo o número dos nomes.',
        'Aos muitos aumentarás a sua herança, e aos poucos diminuirás a sua herança; a cada um se dará a sua herança, segundo os que foram deles contados.',
        'Todavia a terra se repartirá por sortes; segundo os nomes das tribos de seus pais a herdarão.',
        'Segundo sair a sorte, se repartirá a herança deles entre as tribos de muitos e as de poucos.',
        'E estes são os que foram contados dos levitas, segundo as suas famílias: de Gérson, a família dos gersonitas; de Coate, a família dos coatitas; de Merari, a família dos meraritas.',
        'Estas são as famílias de Levi: a família dos libnitas, a família dos hebronitas, a família dos malitas, a família dos musitas, a família dos coreítas. E Coate gerou a Anrão.',
        'E o nome da mulher de Anrão era Joquebede, filha de Levi, a qual nasceu a Levi no Egito; e de Anrão ela teve Arão, e Moisés, e Miriã, irmã deles.',
        'E a Arão nasceram Nadabe, Abiú, Eleazar, e Itamar.',
        'Porém Nadabe e Abiú morreram quando trouxeram fogo estranho perante o Senhor.',
        'E os que deles foram contados eram vinte e três mil, todo o homem da idade de um mês para cima; porque estes não foram contados entre os filhos de Israel, porquanto não lhes foi dada herança entre os filhos de Israel.',
        'Estes são os que foram contados por Moisés e Eleazar, o sacerdote, que contaram os filhos de Israel nas campinas de Moabe, junto ao Jordão na direção de Jericó.',
        'E entre estes nenhum houve dos que foram contados por Moisés e Arão, o sacerdote, quando contaram aos filhos de Israel no deserto de Sinai.',
        'Porque o Senhor dissera deles que certamente morreriam no deserto; e nenhum deles ficou senão Calebe, filho de Jefoné, e Josué, filho de Num.',
      ],
      [
        'E chegaram as filhas de Zelofeade, filho de Hefer, filho de Gileade, filho de Maquir, filho de Manassés, entre as famílias de Manassés, filho de José; e estes são os nomes delas; Maalá, Noa, Hogla, Milca, e Tirza;',
        'E apresentaram-se diante de Moisés, e diante de Eleazar, o sacerdote, e diante dos príncipes e de toda a congregação, à porta da tenda da congregação, dizendo:',
        'Nosso pai morreu no deserto, e não estava entre os que se congregaram contra o Senhor no grupo de Coré; mas morreu no seu próprio pecado, e não teve filhos.',
        'Por que se tiraria o nome de nosso pai do meio da sua família, porquanto não teve filhos? Dá-nos possessão entre os irmãos de nosso pai.',
        'E Moisés levou a causa delas perante o Senhor.',
        'E falou o Senhor a Moisés, dizendo:',
        'As filhas de Zelofeade falam o que é justo; certamente lhes darás possessão de herança entre os irmãos de seu pai; e a herança de seu pai farás passar a elas.',
        'E falarás aos filhos de Israel, dizendo: Quando alguém morrer e não tiver filho, então fareis passar a sua herança à sua filha.',
        'E, se não tiver filha, então a sua herança dareis a seus irmãos.',
        'Porém, se não tiver irmãos, então dareis a sua herança aos irmãos de seu pai.',
        'Se também seu pai não tiver irmãos, então dareis a sua herança a seu parente, àquele que lhe for o mais chegado da sua família, para que a possua; isto aos filhos de Israel será por estatuto de direito, como o Senhor ordenou a Moisés.',
        'Depois disse o Senhor a Moisés: Sobe a este monte de Abarim, e vê a terra que tenho dado aos filhos de Israel.',
        'E, tendo-a visto, então serás recolhido ao teu povo, assim como foi recolhido teu irmão Arão;',
        'Porquanto, no deserto de Zim, na contenda da congregação, fostes rebeldes ao meu mandado de me santificar nas águas diante dos seus olhos (estas são as águas de Meribá de Cades, no deserto de Zim).',
        'Então falou Moisés ao Senhor, dizendo:',
        'O Senhor, Deus dos espíritos de toda a carne, ponha um homem sobre esta congregação,',
        'Que saia diante deles, e que entre diante deles, e que os faça sair, e que os faça entrar; para que a congregação do Senhor não seja como ovelhas que não têm pastor.',
        'Então disse o Senhor a Moisés: Toma a Josué, filho de Num, homem em quem há o Espírito, e impõe a tua mão sobre ele.',
        'E apresenta-o perante Eleazar, o sacerdote, e perante toda a congregação, e dá-lhe as tuas ordens na presença deles.',
        'E põe sobre ele da tua glória, para que lhe obedeça toda a congregação dos filhos de Israel.',
        'E apresentar-se-á perante Eleazar, o sacerdote, o qual por ele consultará, segundo o juízo de Urim, perante o Senhor; conforme a sua palavra sairão, e conforme a sua palavra entrarão, ele e todos os filhos de Israel com ele, e toda a congregação.',
        'E fez Moisés como o Senhor lhe ordenara; porque tomou a Josué, e apresentou-o perante Eleazar, o sacerdote, e perante toda a congregação;',
        'E sobre ele impôs as suas mãos, e lhe deu ordens, como o Senhor falara por intermédio de Moisés.',
      ],
      [
        'Falou mais o SENHOR a Moisés, dizendo:',
        'Dá ordem aos filhos de Israel, e dize-lhes: Da minha oferta, do meu alimento para as minhas ofertas queimadas, do meu cheiro suave, tereis cuidado, para me oferecê-las ao seu tempo determinado.',
        'E dir-lhes-ás: Esta é a oferta queimada que oferecereis ao Senhor: dois cordeiros de um ano, sem defeito, cada dia, em contínuo holocausto;',
        'Um cordeiro sacrificarás pela manhã, e o outro cordeiro sacrificarás à tarde;',
        'E a décima parte de um efa de flor de farinha em oferta de alimentos, misturada com a quarta parte de um him de azeite batido.',
        'Este é o holocausto contínuo, instituído no monte Sinai, em cheiro suave, oferta queimada ao Senhor.',
        'E a sua libação será a quarta parte de um him para um cordeiro; no santuário, oferecerás a libação de bebida forte ao Senhor.',
        'E o outro cordeiro sacrificarás à tarde, como a oferta de alimentos da manhã, e como a sua libação o oferecerás em oferta queimada de cheiro suave ao Senhor.',
        'Porém, no dia de sábado, oferecerás dois cordeiros de um ano, sem defeito, e duas décimas de flor de farinha, misturada com azeite, em oferta de alimentos, com a sua libação.',
        'Holocausto é de cada sábado, além do holocausto contínuo, e a sua libação.',
        'E nos princípios dos vossos meses oferecereis, em holocausto ao Senhor, dois novilhos e um carneiro, sete cordeiros de um ano, sem defeito;',
        'E três décimas de flor de farinha misturada com azeite, em oferta de alimentos, para um novilho; e duas décimas de flor de farinha misturada com azeite, em oferta de alimentos, para um carneiro.',
        'E uma décima de flor de farinha misturada com azeite em oferta de alimentos, para um cordeiro; holocausto é de cheiro suave, oferta queimada ao Senhor.',
        'E as suas libações serão a metade de um him de vinho para um novilho, e a terça parte de um him para um carneiro, e a quarta parte de um him para um cordeiro; este é o holocausto da lua nova de cada mês, segundo os meses do ano.',
        'Também um bode para expiação do pecado ao Senhor, além do holocausto contínuo, com a sua libação se oferecerá.',
        'Porém no mês primeiro, aos catorze dias do mês, é a páscoa do Senhor.',
        'E aos quinze dias do mesmo mês haverá festa; sete dias se comerão pães ázimos.',
        'No primeiro dia haverá santa convocação; nenhum trabalho servil fareis;',
        'Mas oferecereis oferta queimada em holocausto ao Senhor, dois novilhos e um carneiro, e sete cordeiros de um ano; eles serão sem defeito.',
        'E a sua oferta de alimentos será de flor de farinha misturada com azeite; oferecereis três décimas para um novilho, e duas décimas para um carneiro.',
        'Para cada um dos sete cordeiros oferecereis uma décima;',
        'E um bode para expiação do pecado, para fazer expiação por vós.',
        'Estas coisas oferecereis, além do holocausto da manhã, que é o holocausto contínuo.',
        'Segundo este modo, cada dia oferecereis, por sete dias, o alimento da oferta queimada em cheiro suave ao Senhor; além do holocausto contínuo se oferecerá isto com a sua libação.',
        'E no sétimo dia tereis santa convocação; nenhum trabalho servil fareis.',
        'Semelhantemente, tereis santa convocação no dia das primícias, quando oferecerdes oferta nova de alimentos ao Senhor, segundo as vossas semanas; nenhum trabalho servil fareis.',
        'Então oferecereis ao Senhor por holocausto, em cheiro suave, dois novilhos, um carneiro e sete cordeiros de um ano;',
        'E a sua oferta de alimentos de flor de farinha misturada com azeite: três décimas para um novilho, duas décimas para um carneiro;',
        'E uma décima, para cada um dos sete cordeiros;',
        'Um bode para fazer expiação por vós.',
        'Além do holocausto contínuo, e a sua oferta de alimentos, os oferecereis (ser-vos-ão eles sem defeito) com as suas libações.',
      ],
      [
        'Semelhantemente, tereis santa convocação no sétimo mês, no primeiro dia do mês; nenhum trabalho servil fareis; será para vós dia de sonido de trombetas.',
        'Então por holocausto, em cheiro suave ao Senhor, oferecereis um novilho, um carneiro e sete cordeiros de um ano, sem defeito.',
        'E pela sua oferta de alimentos de flor de farinha misturada com azeite, três décimas para o novilho, e duas décimas para o carneiro,',
        'E uma décima para cada um dos sete cordeiros.',
        'E um bode para expiação do pecado, para fazer expiação por vós;',
        'Além do holocausto do mês, e a sua oferta de alimentos, e o holocausto contínuo, e a sua oferta de alimentos, com as suas libações, segundo o seu estatuto, em cheiro suave, oferta queimada ao Senhor.',
        'E no dia dez deste sétimo mês tereis santa convocação, e afligireis as vossas almas; nenhum trabalho fareis.',
        'Mas por holocausto, em cheiro suave ao Senhor, oferecereis um novilho, um carneiro e sete cordeiros de um ano; eles serão sem defeito.',
        'E, pela sua oferta de alimentos de flor de farinha misturada com azeite, três décimas para o novilho, duas décimas para o carneiro,',
        'E uma décima para cada um dos sete cordeiros;',
        'Um bode para expiação do pecado, além da expiação do pecado pelas propiciações, e do holocausto contínuo, e da sua oferta de alimentos com as suas libações.',
        'Semelhantemente, aos quinze dias deste sétimo mês tereis santa convocação; nenhum trabalho servil fareis; mas sete dias celebrareis festa ao Senhor.',
        'E, por holocausto em oferta queimada, de cheiro suave ao Senhor, oferecereis treze novilhos, dois carneiros e catorze cordeiros de um ano; todos eles sem defeito.',
        'E, pela sua oferta de alimentos de flor de farinha misturada com azeite, três décimas para cada um dos treze novilhos, duas décimas para cada carneiro, entre os dois carneiros;',
        'E uma décima para cada um dos catorze cordeiros;',
        'E um bode para expiação do pecado, além do holocausto contínuo, a sua oferta de alimentos e a sua libação;',
        'Depois, no segundo dia, doze novilhos, dois carneiros, catorze cordeiros de um ano, sem defeito;',
        'E a sua oferta de alimentos e as suas libações para os novilhos, para os carneiros e para os cordeiros, conforme o seu número, segundo o estatuto;',
        'E um bode para expiação do pecado, além do holocausto contínuo, da sua oferta de alimentos e das suas libações.',
        'E, no terceiro dia, onze novilhos, dois carneiros, catorze cordeiros de um ano, sem defeito;',
        'E as suas ofertas de alimentos, e as suas libações para os novilhos, para os carneiros e para os cordeiros, conforme o seu número, segundo o estatuto;',
        'E um bode para expiação do pecado, além do holocausto contínuo, e da sua oferta de alimentos e da sua libação.',
        'E, no quarto dia, dez novilhos, dois carneiros, catorze cordeiros de um ano, sem defeito;',
        'A sua oferta de alimentos, e as suas libações para os novilhos, para os carneiros, e para os cordeiros, conforme o seu número, segundo o estatuto;',
        'E um bode para expiação do pecado, além do holocausto contínuo, da sua oferta de alimentos e da sua libação.',
        'E, no quinto dia, nove novilhos, dois carneiros e catorze cordeiros de um ano, sem defeito.',
        'E a sua oferta de alimentos, e as suas libações para os novilhos, para os carneiros e para os cordeiros, conforme o seu número, segundo o estatuto;',
        'E um bode para expiação do pecado além do holocausto contínuo, e da sua oferta de alimentos e da sua libação.',
        'E, no sexto dia, oito novilhos, dois carneiros, catorze cordeiros de um ano, sem defeito;',
        'E a sua oferta de alimentos, e as suas libações para os bezerros, para os carneiros e para os cordeiros, conforme o seu número, segundo o estatuto;',
        'E um bode para expiação do pecado, além do holocausto contínuo, da sua oferta de alimentos e da sua libação.',
        'E, no sétimo dia, sete novilhos, dois carneiros, catorze cordeiros de um ano, sem defeito.',
        'E a sua oferta de alimentos, e as suas libações para os novilhos, para os carneiros e para os cordeiros, conforme o seu número, segundo o seu estatuto,',
        'E um bode para expiação do pecado, além do holocausto contínuo, da sua oferta de alimentos e da sua libação.',
        'No oitavo dia tereis dia de solenidade; nenhum trabalho servil fareis;',
        'E por holocausto em oferta queimada de cheiro suave ao Senhor oferecereis um novilho, um carneiro, sete cordeiros de um ano, sem defeito;',
        'A sua oferta de alimentos e as suas libações para o novilho, para o carneiro e para os cordeiros, conforme o seu número, segundo o estatuto.',
        'E um bode para expiação do pecado, além do holocausto contínuo, e da sua oferta de alimentos e da sua libação.',
        'Estas coisas fareis ao Senhor nas vossas solenidades além dos vossos votos, e das vossas ofertas voluntárias, com os vossos holocaustos, e com as vossas ofertas de alimentos, e com as vossas libações, e com as vossas ofertas pacíficas.',
        'E falou Moisés aos filhos de Israel, conforme a tudo o que o Senhor ordenara a Moisés.',
      ],
      [
        'E falou Moisés aos cabeças das tribos dos filhos de Israel, dizendo: Esta é a palavra que o SENHOR tem ordenado.',
        'Quando um homem fizer voto ao Senhor, ou fizer juramento, ligando a sua alma com obrigação, não violará a sua palavra: segundo tudo o que saiu da sua boca, fará.',
        'Também quando uma mulher, na sua mocidade, estando ainda na casa de seu pai, fizer voto ao Senhor, e com obrigação se ligar,',
        'E seu pai ouvir o seu voto e a sua obrigação, com que ligou a sua alma; e seu pai se calar para com ela, todos os seus votos serão válidos; e toda a obrigação com que ligou a sua alma, será válida.',
        'Mas se seu pai lhe tolher no dia que tal ouvir, todos os seus votos e as suas obrigações com que tiver ligado a sua alma, não serão válidos; mas o Senhor lhe perdoará, porquanto seu pai lhos tolheu.',
        'E se ela for casada, e for obrigada a alguns votos, ou à pronunciação dos seus lábios, com que tiver ligado a sua alma;',
        'E seu marido o ouvir, e se calar para com ela no dia em que o ouvir, os seus votos serão válidos; e as suas obrigações com que ligou a sua alma, serão válidas.',
        'Mas se seu marido lhe tolher no dia em que o ouvir, e anular o seu voto a que estava obrigada, como também a pronunciação dos seus lábios, com que ligou a sua alma; o Senhor lhe perdoará.',
        'No tocante ao voto da viúva, ou da repudiada, tudo com que ligar a sua alma, sobre ela será válido.',
        'Porém se fez voto na casa de seu marido, ou ligou a sua alma com obrigação de juramento;',
        'E seu marido o ouviu, e se calou para com ela, e não lho tolheu, todos os seus votos serão válidos, e toda a obrigação, com que ligou a sua alma, será válida.',
        'Porém se seu marido lhos anulou no dia em que os ouviu; tudo quanto saiu dos seus lábios, quer dos seus votos, quer da obrigação da sua alma, não será válido; seu marido lhos anulou, e o Senhor lhe perdoará.',
        'Todo o voto, e todo o juramento de obrigação, para humilhar a alma, seu marido o confirmará, ou anulará.',
        'Porém se seu marido, de dia em dia, se calar inteiramente para com ela, então confirma todos os seus votos e todas as suas obrigações, que estiverem sobre ela; confirmado lhos tem, porquanto se calou para com ela no dia em que o ouviu.',
        'Porém se de todo lhos anular depois que o ouviu, então ele levará a iniqüidade dela.',
        'Estes são os estatutos que o Senhor ordenou a Moisés entre o marido e sua mulher; entre o pai e sua filha, na sua mocidade, em casa de seu pai.',
      ],
      [
        'E falou o SENHOR a Moisés, dizendo:',
        'Vinga os filhos de Israel dos midianitas; depois recolhido serás ao teu povo.',
        'Falou, pois, Moisés ao povo, dizendo: Armem-se alguns de vós para a guerra, e saiam contra os midianitas, para fazerem a vingança do Senhor contra eles.',
        'Mil de cada tribo, entre todas as tribos de Israel, enviareis à guerra.',
        'Assim foram dados, dos milhares de Israel, mil de cada tribo; doze mil armados para a peleja.',
        'E Moisés os mandou à guerra, mil de cada tribo, e com eles Finéias, filho de Eleazar, o sacerdote, com os vasos do santuário, e com as trombetas do alarido na sua mão.',
        'E pelejaram contra os midianitas, como o Senhor ordenara a Moisés; e mataram a todos os homens.',
        'Mataram também, além dos que já haviam sido mortos, os reis dos midianitas: a Evi, e a Requém, e a Zur, e a Hur, e a Reba, cinco reis dos midianitas; também a Balaão, filho de Beor, mataram à espada.',
        'Porém, os filhos de Israel levaram presas as mulheres dos midianitas e as suas crianças; também levaram todos os seus animais e todo o seu gado, e todos os seus bens.',
        'E queimaram a fogo todas as suas cidades com todas as suas habitações e todos os seus acampamentos.',
        'E tomaram todo o despojo e toda a presa de homens e de animais.',
        'E trouxeram a Moisés e a Eleazar, o sacerdote, e à congregação dos filhos de Israel, os cativos, e a presa, e o despojo, para o arraial, nas campinas de Moabe, que estão junto ao Jordão, na altura de Jericó.',
        'Porém Moisés e Eleazar, o sacerdote, e todos os príncipes da congregação, saíram a recebê-los fora do arraial.',
        'E indignou-se Moisés grandemente contra os oficiais do exército, capitães dos milhares e capitães das centenas, que vinham do serviço da guerra.',
        'E Moisés disse-lhes: Deixastes viver todas as mulheres?',
        'Eis que estas foram as que, por conselho de Balaão, deram ocasião aos filhos de Israel de transgredir contra o Senhor no caso de Peor; por isso houve aquela praga entre a congregação do Senhor.',
        'Agora, pois, matai todo o homem entre as crianças, e matai toda a mulher que conheceu algum homem, deitando-se com ele.',
        'Porém, todas as meninas que não conheceram algum homem, deitando-se com ele, deixai-as viver para vós.',
        'E alojai-vos sete dias fora do arraial; qualquer que tiver matado alguma pessoa, e qualquer que tiver tocado algum morto, ao terceiro dia, e ao sétimo dia vos purificareis, a vós e a vossos cativos.',
        'Também purificareis toda a roupa, e toda a obra de peles, e toda a obra de pêlos de cabras, e todo o utensílio de madeira.',
        'E disse Eleazar, o sacerdote, aos homens da guerra, que foram à peleja: Este é o estatuto da lei que o Senhor ordenou a Moisés.',
        'Contudo o ouro, e a prata, o cobre, o ferro, o estanho, e o chumbo,',
        'Toda a coisa que pode resistir ao fogo, fareis passar pelo fogo, para que fique limpa, todavia se purificará com a água da purificação; mas tudo que não pode resistir ao fogo, fareis passar pela água.',
        'Também lavareis as vossas roupas ao sétimo dia, para que fiqueis limpos; e depois entrareis no arraial.',
        'Falou mais o Senhor a Moisés, dizendo:',
        'Faze a soma da presa que foi tomada, de homens e de animais, tu e Eleazar, o sacerdote, e os cabeças das casas dos pais da congregação,',
        'E divide a presa em duas metades, entre os que se armaram para a peleja, e saíram à guerra, e toda a congregação.',
        'Então para o Senhor tomarás o tributo dos homens de guerra, que saíram a esta peleja, de cada quinhentos uma alma, dos homens, e dos bois, e dos jumentos e das ovelhas.',
        'Da sua metade o tomareis, e o dareis ao sacerdote Eleazar, para a oferta alçada do Senhor.',
        'Mas, da metade dos filhos de Israel, tomarás um de cada cinqüenta, um dos homens, dos bois, dos jumentos, e das ovelhas, e de todos os animais; e os darás aos levitas que têm cuidado da guarda do tabernáculo do Senhor.',
        'E fizeram Moisés e Eleazar, o sacerdote, como o Senhor ordenara a Moisés.',
        'Foi a presa, restante do despojo que tomaram os homens de guerra, seiscentas e setenta e cinco mil ovelhas;',
        'E setenta e dois mil bois;',
        'E sessenta e um mil jumentos;',
        'E, das mulheres que não conheceram homem algum, deitando-se com ele, todas as almas foram trinta e duas mil.',
        'E a metade, que era a porção dos que saíram à guerra, foi em número de trezentas e trinta e sete mil e quinhentas ovelhas.',
        'E das ovelhas, o tributo para o Senhor foi de seiscentas e setenta e cinco.',
        'E foram os bois trinta e seis mil; e o seu tributo para o Senhor setenta e dois.',
        'E foram os jumentos trinta mil e quinhentos; e o seu tributo para o Senhor sessenta e um.',
        'E houve de pessoas dezesseis mil; e o seu tributo para o Senhor trinta e duas pessoas.',
        'E deu Moisés a Eleazar, o sacerdote, o tributo da oferta alçada do Senhor, como o Senhor ordenara a Moisés.',
        'E da metade dos filhos de Israel que Moisés separara da dos homens que pelejaram,',
        '(A metade para a congregação foi, das ovelhas, trezentas e trinta e sete mil e quinhentas;',
        'E dos bois trinta e seis mil;',
        'E dos jumentos trinta mil e quinhentos;',
        'E das pessoas, dezesseis mil).',
        'Desta metade dos filhos de Israel, Moisés tomou um de cada cinqüenta, de homens e de animais, e os deu aos levitas, que tinham cuidado da guarda do tabernáculo do Senhor, como o Senhor ordenara a Moisés.',
        'Então chegaram-se a Moisés os oficiais que estavam sobre os milhares do exército, os chefes de mil e os chefes de cem;',
        'E disseram a Moisés: Teus servos tomaram a soma dos homens de guerra que estiveram sob as nossas ordens; e não falta nenhum de nós.',
        'Por isso trouxemos uma oferta ao Senhor, cada um o que achou, objetos de ouro, cadeias, ou manilhas, anéis, arrecadas, e colares, para fazer expiação pelas nossas almas perante o Senhor.',
        'Assim Moisés e Eleazar, o sacerdote, receberam deles o ouro, sendo todos os objetos bem trabalhados.',
        'E foi todo o ouro da oferta alçada, que ofereceram ao Senhor, dezesseis mil e setecentos e cinqüenta siclos, dos chefes de mil e dos chefes de cem',
        '(Pois cada um dos homens de guerra, tinha tomado presa para si).',
        'Receberam, pois, Moisés e Eleazar, o sacerdote, o ouro dos chefes de mil e dos chefes de cem, e o levaram à tenda da congregação, por memorial para os filhos de Israel perante o Senhor.',
      ],
      [
        'E os filhos de Rúben e os filhos de Gade tinham gado em grande quantidade; e viram a terra de Jazer, e a terra de Gileade, e eis que o lugar era lugar de gado.',
        'Vieram, pois, os filhos de Gade, e os filhos de Rúben e falaram a Moisés e a Eleazar, o sacerdote, e aos chefes da congregação, dizendo:',
        'Atarote, e Dibom, e Jazer, e Ninra, e Hesbom, e Eleale, e Sebã, e Nebo, e Beom,',
        'A terra que o Senhor feriu diante da congregação de Israel, é terra para gado, e os teus servos têm gado.',
        'Disseram mais: Se achamos graça aos teus olhos, dê-se esta terra aos teus servos em possessão; e não nos faças passar o Jordão.',
        'Porém Moisés disse aos filhos de Gade e aos filhos de Rúben: Irão vossos irmãos à peleja, e ficareis vós aqui?',
        'Por que, pois, desencorajais o coração dos filhos de Israel, para que não passem à terra que o Senhor lhes tem dado?',
        'Assim fizeram vossos pais, quando os mandei de Cades-Barnéia, a ver esta terra.',
        'Chegando eles até ao vale de Escol, e vendo esta terra, desencorajaram o coração dos filhos de Israel, para que não entrassem na terra que o Senhor lhes tinha dado.',
        'Então a ira do Senhor se acendeu naquele mesmo dia, e jurou dizendo:',
        'Que os homens, que subiram do Egito, de vinte anos para cima, não verão a terra que jurei a Abraão, a Isaque, e a Jacó! porquanto não perseveraram em seguir-me;',
        'Exceto Calebe, filho de Jefoné o quenezeu, e Josué, filho de Num, porquanto perseveraram em seguir ao Senhor.',
        'Assim se acendeu a ira do Senhor contra Israel, e fê-los andar errantes pelo deserto quarenta anos até que se consumiu toda aquela geração, que fizera mal aos olhos do Senhor.',
        'E eis que vós, uma geração de homens pecadores, vos levantastes em lugar de vossos pais, para ainda mais acrescentar o furor da ira do Senhor contra Israel.',
        'Se vós vos virardes de segui-lo, também ele os deixará de novo no deserto, e destruireis a todo este povo.',
        'Então chegaram-se a ele, e disseram: Edificaremos currais aqui para o nosso gado, e cidades para as nossas crianças;',
        'Porém nós nos armaremos, apressando-nos adiante dos de Israel, até que os levemos ao seu lugar; e ficarão as nossas crianças nas cidades fortes por causa dos moradores da terra.',
        'Não voltaremos para nossas casas, até que os filhos de Israel estejam de posse, cada um, da sua herança.',
        'Porque não herdaremos com eles além do Jordão, nem mais adiante; porquanto nós já temos a nossa herança aquém do Jordão, ao oriente.',
        'Então Moisés lhes disse: Se isto fizerdes assim, se vos armardes à guerra perante o Senhor;',
        'E cada um de vós, armado, passar o Jordão perante o Senhor, até que haja lançado fora os seus inimigos de diante dele,',
        'E a terra esteja subjugada perante o Senhor; então voltareis e sereis inculpáveis perante o Senhor e perante Israel; e esta terra vos será por possessão perante o Senhor;',
        'E se não fizerdes assim, eis que pecastes contra o Senhor; e sabei que o vosso pecado vos há de achar.',
        'Edificai cidades para as vossas crianças, e currais para as vossas ovelhas; e fazei o que saiu da vossa boca.',
        'Então falaram os filhos de Gade, e os filhos de Rúben a Moisés, dizendo: Como ordena meu senhor, assim farão teus servos.',
        'As nossas crianças, as nossas mulheres, o nosso gado, e todos os nossos animais estarão aí nas cidades de Gileade.',
        'Mas os teus servos passarão, cada um armado para a guerra, a pelejar perante o SENHOR, como tem falado o meu senhor.',
        'Então Moisés deu ordem acerca deles a Eleazar, o sacerdote, e a Josué filho de Num, e aos cabeças das casas dos pais das tribos dos filhos de Israel.',
        'E disse-lhes Moisés: Se os filhos de Gade e os filhos de Rúben passarem convosco o Jordão, armado cada um para a guerra, perante o Senhor, e a terra estiver subjugada diante de vós, em possessão lhes dareis a terra de Gileade.',
        'Porém, se não passarem armados convosco, terão possessões entre vós, na terra de Canaã.',
        'E responderam os filhos de Gade e os filhos de Rúben, dizendo: O que o Senhor falou a teus servos, isso faremos.',
        'Nós passaremos, armados, perante o Senhor, à terra de Canaã, e teremos a possessão de nossa herança aquém do Jordão.',
        'Assim deu-lhes Moisés, aos filhos de Gade, e aos filhos de Rúben, e à meia tribo de Manassés, filho de José, o reino de Siom, rei dos amorreus, e o reino de Ogue, rei de Basã; a terra com as suas cidades nos seus termos, e as cidades ao seu redor.',
        'E os filhos de Gade edificaram a Dibom, e Atarote, e Aroer;',
        'E Atarote-Sofã, e Jazer, e Jogbeá;',
        'E Bete-Nimra, e Bete-Harã, cidades fortes; e currais de ovelhas.',
        'E os filhos de Rúben edificaram a Hesbom, e Eleale, e Quiriataim;',
        'E Nebo, e Baal-Meom, mudando-lhes o nome, e Sibma; e os nomes das cidades que edificaram chamaram por outros nomes.',
        'E os filhos de Maquir, filho de Manassés, foram-se para Gileade, e a tomaram; e daquela possessão expulsaram os amorreus que estavam nela.',
        'Assim Moisés deu Gileade a Maquir, filho de Manassés, o qual habitou nela.',
        'E foi Jair, filho de Manassés, e tomou as suas aldeias; e chamou-as Havote-Jair.',
        'E foi Nobá, e tomou a Quenate com as suas aldeias; e chamou-a Nobá, segundo o seu próprio nome.',
      ],
      [
        'Estas são as jornadas dos filhos de Israel, que saíram da terra do Egito, segundo os seus exércitos, sob a direção de Moisés e Arão.',
        'E escreveu Moisés as suas saídas, segundo as suas jornadas, conforme ao mandado do Senhor; e estas são as suas jornadas, segundo as suas saídas.',
        'Partiram, pois, de Ramessés no primeiro mês, no dia quinze do primeiro mês; no dia seguinte da páscoa saíram os filhos de Israel por alta mão, aos olhos de todos os egípcios,',
        'Enquanto os egípcios enterravam os que o Senhor tinha ferido entre eles, a todo o primogênito, e havendo o Senhor executado juízos também contra os seus deuses.',
        'Partiram, pois, os filhos de Israel de Ramessés, e acamparam-se em Sucote.',
        'E partiram de Sucote, e acamparam-se em Etã, que está no fim do deserto.',
        'E partiram de Etã, e voltaram a Pi-Hairote, que está defronte de Baal-Zefom, e acamparam-se diante de Migdol.',
        'E partiram de Pi-Hairote, e passaram pelo meio do mar ao deserto, e andaram caminho de três dias no deserto de Etã, e acamparam-se em Mara.',
        'E partiram de Mara, e vieram a Elim, e em Elim havia doze fontes de águas e setenta palmeiras, e acamparam-se ali.',
        'E partiram de Elim, e acamparam-se junto ao Mar Vermelho.',
        'E partiram do Mar Vermelho, e acamparam-se no deserto de Sim.',
        'E partiram do deserto de Sim, e acamparam-se em Dofca.',
        'E partiram de Dofca, e acamparam-se em Alus.',
        'E partiram de Alus, e acamparam-se em Refidim; porém não havia ali água, para que o povo bebesse.',
        'Partiram, pois, de Refidim, e acamparam-se no deserto de Sinai.',
        'E partiram do deserto de Sinai, e acamparam-se em Quibrote-Taavá.',
        'E partiram de Quibrote-Taavá, e acamparam-se em Hazerote.',
        'E partiram de Hazerote, e acamparam-se em Ritmá.',
        'E partiram de Ritmá, e acamparam-se em Rimom-Perez.',
        'E partiram de Rimom-Perez, e acamparam-se em Libna.',
        'E partiram de Libna, e acamparam-se em Rissa.',
        'E partiram de Rissa, e acamparam-se em Queelata.',
        'E partiram de Queelata, e acamparam-se no monte de Séfer.',
        'E partiram do monte de Séfer, e acamparam-se em Harada.',
        'E partiram de Harada, e acamparam-se em Maquelote.',
        'E partiram de Maquelote, e acamparam-se em Taate.',
        'E partiram de Taate, e acamparam-se em Tara.',
        'E partiram de Tara, e acamparam-se em Mitca.',
        'E partiram de Mitca, e acamparam-se em Hasmona.',
        'E partiram de Hasmona, e acamparam-se em Moserote.',
        'E partiram de Moserote, e acamparam-se em Bene-Jaacã.',
        'E partiram de Bene-Jaacã, e acamparam-se em Hor-Hagidgade.',
        'E partiram de Hor-Hagidgade, e acamparam-se em Jotbatá.',
        'E partiram de Jotbatá, e acamparam-se em Abrona.',
        'E partiram de Abrona, e acamparam-se em Ezion-Geber.',
        'E partiram de Ezion-Geber, e acamparam-se no deserto de Zim, que é Cades.',
        'E partiram de Cades, e acamparam-se no monte Hor, no fim da terra de Edom.',
        'Então Arão, o sacerdote, subiu ao monte Hor, conforme ao mandado do Senhor; e morreu ali no quinto mês do ano quadragésimo da saída dos filhos de Israel da terra do Egito, no primeiro dia do mês.',
        'E era Arão da idade de cento e vinte e três anos, quando morreu no monte Hor.',
        'E ouviu o cananeu, rei de Harade, que habitava o sul na terra de Canaã, que chegavam os filhos de Israel.',
        'E partiram do monte Hor, e acamparam-se em Zalmona.',
        'E partiram de Zalmona, e acamparam-se em Punom.',
        'E partiram de Punom, e acamparam-se em Obote.',
        'E partiram de Obote, e acamparam-se em Ije-Abarim, no termo de Moabe.',
        'E partiram de Ije-Abarim, e acamparam-se em Dibom-Gade.',
        'E partiram de Dibom-Gade, e acamparam-se em Almom-Diblataim.',
        'E partiram de Almom-Diblataim, e acamparam-se nos montes de Abarim, defronte de Nebo.',
        'E partiram dos montes de Abarim, e acamparam-se nas campinas de Moabe, junto ao Jordão, na direção de Jericó.',
        'E acamparam-se junto ao Jordão, desde Bete-Jesimote até Abel-Sitim, nas campinas de Moabe.',
        'E falou o Senhor a Moisés, nas campinas de Moabe junto ao Jordão na direção de Jericó, dizendo:',
        'Fala aos filhos de Israel, e dize-lhes: Quando houverdes passado o Jordão para a terra de Canaã,',
        'Lançareis fora todos os moradores da terra de diante de vós, e destruireis todas as suas pinturas; também destruireis todas as suas imagens de fundição, e desfareis todos os seus altos;',
        'E tomareis a terra em possessão, e nela habitareis; porquanto vos tenho dado esta terra, para possuí-la.',
        'E por sortes herdareis a terra, segundo as vossas famílias; aos muitos multiplicareis a herança, e aos poucos diminuireis a herança; conforme a sorte sair a alguém, ali a possuirá; segundo as tribos de vossos pais recebereis as heranças.',
        'Mas se não lançardes fora os moradores da terra de diante de vós, então os que deixardes ficar vos serão por espinhos nos vossos olhos, e por aguilhões nas vossas virilhas, e apertar-vos-ão na terra em que habitardes,',
        'E será que farei a vós como pensei fazer-lhes a eles.',
      ],
      [
        'Falou mais o SENHOR a Moisés, dizendo:',
        'Dá ordem aos filhos de Israel, e dize-lhes: Quando entrardes na terra de Canaã, esta há de ser a terra que vos cairá em herança; a terra de Canaã, segundo os seus termos.',
        'O lado do sul vos será desde o deserto de Zim até aos termos de Edom; e o termo do sul vos será desde a extremidade do Mar Salgado para o lado do oriente.',
        'E este limite vos irá rodeando do sul para a subida de Acrabim, e passará até Zim; e as suas saídas serão do sul a Cades-Barnéia; e sairá a Hazar-Adar, e passará a Azmom;',
        'Rodeará mais este limite de Azmom até ao rio do Egito; e as suas saídas serão para o lado do mar.',
        'Quanto ao limite do ocidente, o Mar Grande vos será por limite; este vos será o limite do ocidente.',
        'E este vos será o termo do norte: desde o Mar Grande marcareis até ao monte Hor.',
        'Desde o monte Hor marcareis até à entrada de Hamate; e as saídas deste termo serão até Zedade.',
        'E este limite seguirá até Zifrom, e as suas saídas serão em Hazar-Enã; este vos será o termo do norte.',
        'E por limite do lado do oriente marcareis de Hazar-Enã até Sefã.',
        'E este limite descerá desde Sefã até Ribla, para o lado do oriente de Aim; depois descerá este termo, e irá ao longo da borda do mar de Quinerete para o lado do oriente.',
        'Descerá também este limite ao longo do Jordão, e as suas saídas serão no Mar Salgado; esta vos será a terra, segundo os seus limites ao redor.',
        'E Moisés deu ordem aos filhos de Israel, dizendo: Esta é a terra que herdareis por sorte, a qual o Senhor mandou dar às nove tribos e à meia tribo.',
        'Porque a tribo dos filhos dos rubenitas, segundo a casa de seus pais, e a tribo dos filhos dos gaditas, segundo a casa de seus pais, já receberam; também a meia tribo de Manassés recebeu a sua herança.',
        'Já duas tribos e meia tribo receberam a sua herança aquém do Jordão, na direção de Jericó, do lado do oriente, ao nascente.',
        'Falou mais o Senhor a Moisés, dizendo:',
        'Estes são os nomes dos homens que vos repartirão a terra por herança: Eleazar, o sacerdote, e Josué, filho de Num.',
        'Tomareis mais de cada tribo um príncipe, para repartir a terra em herança.',
        'E estes são os nomes dos homens: Da tribo de Judá, Calebe, filho de Jefoné;',
        'E, da tribo dos filhos de Simeão, Samuel, filho de Amiúde;',
        'Da tribo de Benjamim, Elidade, filho de Quislom;',
        'E, da tribo dos filhos de Dã, o príncipe Buqui, filho de Jogli;',
        'Dos filhos de José, da tribo dos filhos de Manassés, o príncipe Haniel, filho de Éfode;',
        'E, da tribo dos filhos de Efraim, o príncipe Quemuel, filho de Siftã;',
        'E, da tribo dos filhos de Zebulom, o príncipe Elizafã, filho de Parnaque;',
        'E, da tribo dos filhos de Issacar, o príncipe Paltiel, filho de Azã;',
        'E, da tribo dos filhos de Aser, o príncipe Aiúde, filho de Selomi;',
        'E, da tribo dos filhos de Naftali, o príncipe Pedael, filho de Amiúde.',
        'Estes são aqueles a quem o Senhor ordenou, que repartissem as heranças aos filhos de Israel na terra de Canaã.',
      ],
      [
        'E falou o SENHOR a Moisés nas campinas de Moabe, junto ao Jordão na direção de Jericó, dizendo:',
        'Dá ordem aos filhos de Israel que, da herança da sua possessão, dêem cidades aos levitas, em que habitem; e também aos levitas dareis arrabaldes ao redor delas.',
        'E terão estas cidades para habitá-las; porém os seus arrabaldes serão o seu gado, e para os seus bens, e para todos os seus animais.',
        'E os arrabaldes das cidades, que dareis aos levitas, desde o muro da cidade para fora, serão de mil côvados em redor.',
        'E de fora da cidade, do lado do oriente, medireis dois mil côvados, e do lado do sul, dois mil côvados, e do lado do ocidente dois mil côvados, e do lado do norte dois mil côvados, e a cidade no meio; isto terão por arrabaldes das cidades.',
        'Das cidades, pois, que dareis aos levitas, haverá seis cidades de refúgio, as quais dareis para que o homicida ali se acolha; e, além destas, lhes dareis quarenta e duas cidades.',
        'Todas as cidades que dareis aos levitas serão quarenta e oito cidades, juntamente com os seus arrabaldes.',
        'E quanto às cidades que derdes da herança dos filhos de Israel, do que tiver muito tomareis muito, e do que tiver pouco tomareis pouco; cada um dará das suas cidades aos levitas, segundo a herança que herdar.',
        'Falou mais o Senhor a Moisés, dizendo:',
        'Fala aos filhos de Israel, e dize-lhes: Quando passardes o Jordão à terra de Canaã,',
        'Fazei com que vos estejam à mão cidades que vos sirvam de cidades de refúgio, para que ali se acolha o homicida que ferir a alguma alma por engano.',
        'E estas cidades vos serão por refúgio do vingador do sangue; para que o homicida não morra, até que seja apresentado à congregação para julgamento.',
        'E das cidades que derdes haverá seis cidades de refúgio para vós.',
        'Três destas cidades dareis além do Jordão, e três destas cidades dareis na terra de Canaã; cidades de refúgio serão.',
        'Serão por refúgio estas seis cidades para os filhos de Israel, e para o estrangeiro, e para o que se hospedar no meio deles, para que ali se acolha aquele que matar a alguém por engano.',
        'Porém, se o ferir com instrumento de ferro e morrer, homicida é; certamente o homicida morrerá.',
        'Ou, se lhe ferir com uma pedrada, de que possa morrer, e morrer, homicida é; certamente o homicida morrerá.',
        'Ou, se o ferir com instrumento de pau que tiver na mão, de que possa morrer, e ele morrer, homicida é; certamente morrerá o homicida.',
        'O vingador do sangue matará o homicida; encontrando-o, matá-lo-á.',
        'Se também o empurrar com ódio, ou com mau intento lançar contra ele alguma coisa, e morrer;',
        'Ou por inimizade o ferir com a sua mão, e morrer, certamente morrerá aquele que o ferir; homicida é; o vingador do sangue, encontrando o homicida, o matará.',
        'Porém, se o empurrar subitamente, sem inimizade, ou contra ele lançar algum instrumento sem intenção;',
        'Ou, sobre ele deixar cair alguma pedra sem o ver, de que possa morrer, e ele morrer, sem que fosse seu inimigo nem procurasse o seu mal;',
        'Então a congregação julgará entre aquele que feriu e o vingador do sangue, segundo estas leis.',
        'E a congregação livrará o homicida da mão do vingador do sangue, e a congregação o fará voltar à cidade do seu refúgio, onde se tinha acolhido; e ali ficará até à morte do sumo sacerdote, a quem ungiram com o santo óleo.',
        'Porém, se de alguma maneira o homicida sair dos limites da cidade de refúgio, onde se tinha acolhido,',
        'E o vingador do sangue o achar fora dos limites da cidade de seu refúgio, e o matar, não será culpado do sangue.',
        'Pois o homicida deverá ficar na cidade do seu refúgio, até à morte do sumo sacerdote; mas, depois da morte do sumo sacerdote, o homicida voltará à terra da sua possessão.',
        'E estas coisas vos serão por estatuto de direito às vossas gerações, em todas as vossas habitações.',
        'Todo aquele que matar alguma pessoa, conforme depoimento de testemunhas, será morto; mas uma só testemunha não testemunhará contra alguém, para que morra.',
        'E não recebereis resgate pela vida do homicida que é culpado de morte; pois certamente morrerá.',
        'Também não tomareis resgate por aquele que se acolher à sua cidade de refúgio, para tornar a habitar na terra, até à morte do sumo sacerdote.',
        'Assim não profanareis a terra em que estais; porque o sangue faz profanar a terra; e nenhuma expiação se fará pela terra por causa do sangue que nela se derramar, senão com o sangue daquele que o derramou.',
        'Não contaminareis pois a terra na qual vós habitais, no meio da qual eu habito; pois eu, o Senhor, habito no meio dos filhos de Israel.',
      ],
      [
        'E chegaram os chefes dos pais da família de Gileade, filho de Maquir, filho de Manassés, das famílias dos filhos de José, e falaram diante de Moisés, e diante dos príncipes, chefes dos pais dos filhos de Israel,',
        'E disseram: O SENHOR mandou a meu senhor que, por sorte, desse esta terra em herança aos filhos de Israel; e a meu senhor foi ordenado pelo SENHOR, que a herança do nosso irmão Zelofeade se desse às suas filhas.',
        'E, casando-se elas com alguns dos filhos das outras tribos dos filhos de Israel, então a sua herança será diminuída da herança de nossos pais, e acrescentada à herança da tribo a que vierem a pertencer; assim se tirará da sorte da nossa herança.',
        'Vindo também o ano do jubileu dos filhos de Israel, a sua herança será acrescentada à herança da tribo daqueles com que se casarem; assim a sua herança será tirada da herança da tribo de nossos pais.',
        'Então Moisés deu ordem aos filhos de Israel, segundo o mandado do Senhor, dizendo: A tribo dos filhos de José fala o que é justo.',
        'Isto é o que o Senhor mandou acerca das filhas de Zelofeade, dizendo: Sejam por mulheres a quem bem parecer aos seus olhos, contanto que se casem na família da tribo de seu pai.',
        'Assim a herança dos filhos de Israel não passará de tribo em tribo; pois os filhos de Israel se chegarão cada um à herança da tribo de seus pais.',
        'E qualquer filha que herdar alguma herança das tribos dos filhos de Israel se casará com alguém da família da tribo de seu pai; para que os filhos de Israel possuam cada um a herança de seus pais.',
        'Assim a herança não passará de uma tribo a outra; pois as tribos dos filhos de Israel se chegarão cada uma à sua herança.',
        'Como o Senhor ordenara a Moisés, assim fizeram as filhas de Zelofeade.',
        'Pois Maalá, Tirza, Hogla, Milca e Noa, filhas de Zelofeade, se casaram com os filhos de seus tios.',
        'E elas casaram-se nas famílias dos filhos de Manassés, filho de José; assim a sua herança ficou na tribo da família de seu pai.',
        'Estes são os mandamentos e os juízos que mandou o Senhor através de Moisés aos filhos de Israel nas campinas de Moabe, junto ao Jordão, na direção de Jericó.',
      ],
    ],
    livro: 'numeros',
  },
  {
    abbrev: 'dt',
    capitulos: [
      [
        'Estas são as palavras que Moisés falou a todo o Israel além do Jordão, no deserto, na planície defronte do Mar Vermelho, entre Parã e Tôfel, e Labã, e Hazerote, e Di-Zaabe.',
        'Onze jornadas há desde Horebe, caminho do monte Seir, até Cades-Barnéia.',
        'E sucedeu que, no ano quadragésimo, no mês undécimo, no primeiro dia do mês, Moisés falou aos filhos de Israel, conforme a tudo o que o Senhor lhe mandara acerca deles.',
        'Depois que feriu a Siom, rei dos amorreus, que habitava em Hesbom, e a Ogue, rei de Basã, que habitava em Astarote, em Edrei.',
        'Além do Jordão, na terra de Moabe, começou Moisés a declarar esta lei, dizendo:',
        'O Senhor nosso Deus nos falou em Horebe, dizendo: Assaz vos haveis demorado neste monte.',
        'Voltai-vos, e parti, e ide à montanha dos amorreus, e a todos os seus vizinhos, à planície, e à montanha, e ao vale, e ao sul, e à margem do mar; à terra dos cananeus, e ao Líbano, até ao grande rio, o rio Eufrates.',
        'Eis que tenho posto esta terra diante de vós; entrai e possuí a terra que o Senhor jurou a vossos pais, Abraão, Isaque e Jacó, que a daria a eles e à sua descendência depois deles.',
        'E no mesmo tempo eu vos falei, dizendo: Eu sozinho não poderei levar-vos.',
        'O Senhor vosso Deus já vos tem multiplicado; e eis que em multidão sois hoje como as estrelas do céu.',
        'O Senhor Deus de vossos pais vos aumente, ainda mil vezes mais do que sois; e vos abençoe, como vos tem falado.',
        'Como suportaria eu sozinho os vossos fardos, e as vossas cargas, e as vossas contendas?',
        'Tomai-vos homens sábios e entendidos, experimentados entre as vossas tribos, para que os ponha por chefes sobre vós.',
        'Então vós me respondestes, e dissestes: Bom é fazer o que tens falado.',
        'Tomei, pois, os chefes de vossas tribos, homens sábios e experimentados, e os tenho posto por cabeças sobre vós, por capitães de milhares, e por capitães de cem, e por capitães de cinqüenta, e por capitães de dez, e por governadores das vossas tribos.',
        'E no mesmo tempo mandei a vossos juízes, dizendo: Ouvi a causa entre vossos irmãos, e julgai justamente entre o homem e seu irmão, e entre o estrangeiro que está com ele.',
        'Não discriminareis as pessoas em juízo; ouvireis assim o pequeno como o grande; não temereis a face de ninguém, porque o juízo é de Deus; porém a causa que vos for difícil fareis vir a mim, e eu a ouvirei.',
        'Assim naquele tempo vos ordenei todas as coisas que havíeis de fazer.',
        'Então partimos de Horebe, e caminhamos por todo aquele grande e tremendo deserto que vistes, pelo caminho das montanhas dos amorreus, como o Senhor nosso Deus nos ordenara; e chegamos a Cades-Barnéia.',
        'Então eu vos disse: Chegados sois às montanhas dos amorreus, que o Senhor nosso Deus nos dá.',
        'Eis aqui o Senhor teu Deus tem posto esta terra diante de ti; sobe, toma posse dela, como te falou o Senhor Deus de teus pais; não temas, e não te assustes.',
        'Então todos vós chegastes a mim, e dissestes: Mandemos homens adiante de nós, para que nos espiem a terra e, de volta, nos ensinem o caminho pelo qual devemos subir, e as cidades a que devemos ir.',
        'Isto me pareceu bem; de modo que de vós tomei doze homens, de cada tribo um homem.',
        'E foram-se, e subiram à montanha, e chegaram até ao vale de Escol, e o espiaram.',
        'E tomaram do fruto da terra nas suas mãos, e no-lo trouxeram e nos informaram, dizendo: Boa é a terra que nos dá o Senhor nosso Deus.',
        'Porém vós não quisestes subir; mas fostes rebeldes ao mandado do Senhor nosso Deus.',
        'E murmurastes nas vossas tendas, e dissestes: Porquanto o Senhor nos odeia, nos tirou da terra do Egito para nos entregar nas mãos dos amorreus, para destruir-nos.',
        'Para onde subiremos? Nossos irmãos fizeram com que se derretesse o nosso coração, dizendo: Maior e mais alto é este povo do que nós, as cidades são grandes e fortificadas até aos céus; e também vimos ali filhos dos gigantes.',
        'Então eu vos disse: Não vos espanteis, nem os temais.',
        'O Senhor vosso Deus que vai adiante de vós, ele pelejará por vós, conforme a tudo o que fez convosco, diante de vossos olhos, no Egito;',
        'Como também no deserto, onde vistes que o Senhor vosso Deus nele vos levou, como um homem leva seu filho, por todo o caminho que andastes, até chegardes a este lugar.',
        'Mas nem por isso crestes no Senhor vosso Deus.',
        'Que foi adiante de vós por todo o caminho, para vos achar o lugar onde vós deveríeis acampar; de noite no fogo, para vos mostrar o caminho por onde havíeis de andar, e de dia na nuvem.',
        'Ouvindo, pois, o Senhor a voz das vossas palavras, indignou-se, e jurou, dizendo:',
        'Nenhum dos homens desta maligna geração verá esta boa terra que jurei dar a vossos pais.',
        'Salvo Calebe, filho de Jefoné; ele a verá, e a terra que pisou darei a ele e a seus filhos; porquanto perseverou em seguir ao Senhor.',
        'Também o Senhor se indignou contra mim por causa de vós, dizendo: Também tu lá não entrarás.',
        'Josué, filho de Num, que está diante de ti, ele ali entrará; fortalece-o, porque ele a fará herdar a Israel.',
        'E vossos meninos, de quem dissestes: Por presa serão; e vossos filhos, que hoje não conhecem nem o bem nem o mal, eles ali entrarão, e a eles a darei, e eles a possuirão.',
        'Porém vós virai-vos, e parti para o deserto, pelo caminho do Mar Vermelho.',
        'Então respondestes, e me dissestes: Pecamos contra o Senhor; nós subiremos e pelejaremos, conforme a tudo o que nos ordenou o Senhor nosso Deus. E armastes-vos, cada um de vós, dos seus instrumentos de guerra, e estivestes prestes para subir à montanha.',
        'E disse-me o Senhor: Dize-lhes: Não subais nem pelejeis, pois não estou no meio de vós; para que não sejais feridos diante de vossos inimigos.',
        'Porém, falando-vos eu, não ouvistes; antes fostes rebeldes ao mandado do Senhor, e vos ensoberbecestes, e subistes à montanha.',
        'E os amorreus, que habitavam naquela montanha, vos saíram ao encontro; e perseguiram-vos como fazem as abelhas e vos derrotaram desde Seir até Hormá.',
        'Tornando, pois, vós, e chorando perante o Senhor, o Senhor não ouviu a vossa voz, nem vos escutou.',
        'Assim permanecestes muitos dias em Cades, pois ali vos demorastes muito.',
      ],
      [
        'Depois viramo-nos, e caminhamos ao deserto, caminho do Mar Vermelho, como o SENHOR me tinha dito, e muitos dias rodeamos o monte Seir.',
        'Então o Senhor me falou, dizendo:',
        'Tendes rodeado bastante esta montanha; virai-vos para o norte.',
        'E dá ordem ao povo, dizendo: Passareis pelos termos de vossos irmãos, os filhos de Esaú, que habitam em Seir; e eles terão medo de vós; porém guardai-vos bem.',
        'Não vos envolvais com eles, porque não vos darei da sua terra nem ainda a pisada da planta de um pé; porquanto a Esaú tenho dado o monte Seir por herança.',
        'Comprareis deles, por dinheiro, comida para comerdes; e também água para beber deles comprareis por dinheiro.',
        'Pois o Senhor teu Deus te abençoou em toda a obra das tuas mãos; ele sabe que andas por este grande deserto; estes quarenta anos o Senhor teu Deus esteve contigo, coisa nenhuma te faltou.',
        'Passando, pois, por nossos irmãos, os filhos de Esaú, que habitavam em Seir, desde o caminho da planície de Elate e de Eziom-Geber, nos viramos e passamos o caminho do deserto de Moabe.',
        'Então o Senhor me disse: Não molestes aos de Moabe, e não contendas com eles em peleja, porque não te darei herança da sua terra; porquanto tenho dado a Ar por herança aos filhos de Ló.',
        '(Os emins dantes habitaram nela; um povo grande e numeroso, e alto como os gigantes.',
        'Também estes foram considerados gigantes como os anaquins; e os moabitas os chamavam emins.',
        'Outrora os horeus também habitaram em Seir; porém os filhos de Esaú os lançaram fora, e os destruíram de diante de si, e habitaram no seu lugar, assim como Israel fez à terra da sua herança, que o Senhor lhes tinha dado).',
        'Levantai-vos agora, e passai o ribeiro de Zerede. Assim passamos o ribeiro de Zerede.',
        'E os dias que caminhamos, desde Cades-Barnéia até que passamos o ribeiro de Zerede, foram trinta e oito anos, até que toda aquela geração dos homens de guerra se consumiu do meio do arraial, como o Senhor lhes jurara.',
        'Assim também foi contra eles a mão do Senhor, para os destruir do meio do arraial até os haver consumido.',
        'E sucedeu que, sendo já consumidos todos os homens de guerra, pela morte, do meio do povo,',
        'O Senhor me falou, dizendo:',
        'Hoje passarás a Ar, pelos termos de Moabe;',
        'E chegando até defronte dos filhos de Amom, não os molestes, e com eles não contendas; porque da terra dos filhos de Amom não te darei herança, porquanto aos filhos de Ló a tenho dado por herança.',
        '(Também essa foi considerada terra de gigantes; antes nela habitavam gigantes, e os amonitas os chamavam zamzumins;',
        'Um povo grande, e numeroso, e alto, como os gigantes; e o Senhor os destruiu de diante dos amonitas, e estes os lançaram fora, e habitaram no seu lugar;',
        'Assim como fez com os filhos de Esaú, que habitavam em Seir, de diante dos quais destruiu os horeus, e eles os lançaram fora, e habitaram no lugar deles até este dia;',
        'Também os caftorins, que saíram de Caftor, destruíram os aveus, que habitavam em Cazerim até Gaza, e habitaram no lugar deles).',
        'Levantai-vos, parti e passai o ribeiro de Arnom; eis aqui na tua mão tenho dado a Siom, amorreu, rei de Hesbom, e a sua terra; começa a possuí-la, e contende com eles em peleja.',
        'Neste dia começarei a pôr um terror e um medo de ti diante dos povos que estão debaixo de todo o céu; os que ouvirem a tua fama tremerão diante de ti e se angustiarão.',
        'Então mandei mensageiros desde o deserto de Quedemote a Siom, rei de Hesbom, com palavras de paz, dizendo:',
        'Deixa-me passar pela tua terra; somente pela estrada irei; não me desviarei para a direita nem para a esquerda.',
        'A comida, para que eu coma, vender-me-ás por dinheiro, e dar-me-ás por dinheiro a água para que eu beba; tão-somente deixa-me passar a pé;',
        'Como fizeram comigo os filhos de Esaú, que habitam em Seir, e os moabitas que habitam em Ar; até que eu passe o Jordão, à terra que o Senhor nosso Deus nos há de dar.',
        'Mas Siom, rei de Hesbom, não nos quis deixar passar por sua terra, porquanto o Senhor teu Deus endurecera o seu espírito, e fizera obstinado o seu coração para to dar na tua mão, como hoje se vê.',
        'E o Senhor me disse: Eis aqui, tenho começado a dar-te Siom, e a sua terra; começa, pois, a possuí-la para que herdes a sua terra.',
        'E Siom saiu-nos ao encontro, ele e todo o seu povo, à peleja, em Jaza;',
        'E o Senhor nosso Deus no-lo entregou, e o ferimos a ele, e a seus filhos, e a todo o seu povo.',
        'E naquele tempo tomamos todas as suas cidades, e cada uma destruímos com os seus homens, mulheres e crianças; não deixamos a ninguém.',
        'Somente tomamos por presa o gado para nós, e o despojo das cidades que tínhamos tomado.',
        'Desde Aroer, que está à margem do ribeiro de Arnom, e a cidade que está junto ao ribeiro, até Gileade, nenhuma cidade houve que de nós escapasse; tudo isto o Senhor nosso Deus nos entregou.',
        'Somente à terra dos filhos de Amom não chegastes; nem a toda a margem do ribeiro de Jaboque, nem às cidades da montanha, nem a coisa alguma que nos proibira o Senhor nosso Deus.',
      ],
      [
        'Depois nos viramos e subimos o caminho de Basã; e Ogue, rei de Basã, nos saiu ao encontro, ele e todo o seu povo, à peleja em Edrei.',
        'Então o Senhor me disse: Não o temas, porque a ele e a todo o seu povo, e a sua terra, tenho dado na tua mão; e far-lhe-ás como fizeste a Siom, rei dos amorreus, que habitava em Hesbom.',
        'E também o Senhor nosso Deus nos deu na nossa mão a Ogue, rei de Basã, e a todo o seu povo; de maneira que o ferimos até que não lhe ficou sobrevivente algum.',
        'E naquele tempo tomamos todas as suas cidades; nenhuma cidade houve que lhes não tomássemos; sessenta cidades, toda a região de Argobe, o reino de Ogue em Basã.',
        'Todas estas cidades eram fortificadas com altos muros, portas e ferrolhos; e muitas outras cidades sem muros.',
        'E destruímo-las como fizemos a Siom, rei de Hesbom, destruindo todas as cidades, homens, mulheres e crianças.',
        'Porém todo o gado, e o despojo das cidades, tomamos para nós por presa.',
        'Assim naquele tempo tomamos a terra das mãos daqueles dois reis dos amorreus, que estavam além do Jordão; desde o rio de Arnom, até ao monte de Hermom',
        '(A Hermom os sidônios chamam Siriom; porém os amorreus o chamam Senir);',
        'Todas as cidades do planalto, e todo o Gileade, e todo o Basã, até Salcá e Edrei, cidades do reino de Ogue em Basã.',
        'Porque só Ogue, o rei de Basã, restou dos gigantes; eis que o seu leito, um leito de ferro, não está porventura em Rabá dos filhos de Amom? De nove côvados, o seu comprimento, e de quatro côvados, a sua largura, pelo côvado comum.',
        'Tomamos, pois, esta terra em possessão naquele tempo: Desde Aroer, que está junto ao ribeiro de Arnom, e a metade da montanha de Gileade, com as suas cidades, tenho dado aos rubenitas e gaditas.',
        'E o restante de Gileade, como também todo o Basã, o reino de Ogue, dei à meia tribo de Manassés; toda aquela região de Argobe, por todo o Basã, se chamava a terra dos gigantes.',
        'Jair, filho de Manassés, alcançou toda a região de Argobe, até ao termo dos gesuritas, e maacatitas, e a chamou de seu nome, Havote-Jair até este dia.',
        'E a Maquir dei Gileade.',
        'Mas aos rubenitas e gaditas dei desde Gileade até ao ribeiro de Arnom, cujo meio serve de limite; e até ao ribeiro de Jaboque, o termo dos filhos de Amom.',
        'Como também a campina, e o Jordão por termo; desde Quinerete até ao mar da campina, o Mar Salgado, abaixo de Asdote-Pisga para o oriente.',
        'E no mesmo tempo vos ordenei, dizendo: O Senhor vosso Deus vos deu esta terra, para possuí-la; passai, pois, armados vós, todos os homens valentes, diante de vossos irmãos, os filhos de Israel.',
        'Tão-somente vossas mulheres, e vossas crianças, e vosso gado (porque eu sei que tendes muito gado), ficarão nas vossas cidades, que já vos tenho dado.',
        'Até que o Senhor dê descanso a vossos irmãos como a vós; para que eles herdem também a terra que o Senhor vosso Deus lhes há de dar além do Jordão; então voltareis cada qual à sua herança que já vos tenho dado.',
        'Também dei ordem a Josué no mesmo tempo, dizendo: Os teus olhos têm visto tudo o que o Senhor vosso Deus tem feito a estes dois reis; assim fará o Senhor a todos os reinos, a que tu passarás.',
        'Não os temais, porque o Senhor vosso Deus é o que peleja por vós.',
        'Também eu pedi graça ao Senhor no mesmo tempo, dizendo:',
        'Senhor DEUS! já começaste a mostrar ao teu servo a tua grandeza e a tua forte mão; pois, que Deus há nos céus e na terra, que possa fazer segundo as tuas obras, e segundo os teus grandes feitos?',
        'Rogo-te que me deixes passar, para que veja esta boa terra que está além do Jordão; esta boa montanha, e o Líbano!',
        'Porém o Senhor indignou-se muito contra mim por causa de vós, e não me ouviu; antes o Senhor me disse: Basta; não me fales mais deste assunto;',
        'Sobe ao cume de Pisga, e levanta os teus olhos ao ocidente, e ao norte, e ao sul, e ao oriente, e vê com os teus olhos; porque não passarás este Jordão.',
        'Manda, pois, a Josué, e anima-o, e fortalece-o; porque ele passará adiante deste povo, e o fará possuir a terra que verás.',
        'Assim ficamos neste vale, defronte de Bete-Peor.',
      ],
      [
        'Agora, pois, ó Israel, ouve os estatutos e os juízos que eu vos ensino, para os cumprirdes; para que vivais, e entreis, e possuais a terra que o SENHOR Deus de vossos pais vos dá.',
        'Não acrescentareis à palavra que vos mando, nem diminuireis dela, para que guardeis os mandamentos do Senhor vosso Deus, que eu vos mando.',
        'Os vossos olhos têm visto o que o Senhor fez por causa de Baal-Peor; pois a todo o homem que seguiu a Baal-Peor o Senhor teu Deus consumiu do meio de ti.',
        'Porém vós, que vos achegastes ao Senhor vosso Deus, hoje todos estais vivos.',
        'Vedes aqui vos tenho ensinado estatutos e juízos, como me mandou o Senhor meu Deus; para que assim façais no meio da terra a qual ides a herdar.',
        'Guardai-os pois, e cumpri-os, porque isso será a vossa sabedoria e o vosso entendimento perante os olhos dos povos, que ouvirão todos estes estatutos, e dirão: Este grande povo é nação sábia e entendida.',
        'Pois, que nação há tão grande, que tenha deuses tão chegados como o Senhor nosso Deus, todas as vezes que o invocamos?',
        'E que nação há tão grande, que tenha estatutos e juízos tão justos como toda esta lei que hoje ponho perante vós?',
        'Tão-somente guarda-te a ti mesmo, e guarda bem a tua alma, que não te esqueças daquelas coisas que os teus olhos têm visto, e não se apartem do teu coração todos os dias da tua vida; e as farás saber a teus filhos, e aos filhos de teus filhos.',
        'O dia em que estiveste perante o Senhor teu Deus em Horebe, quando o Senhor me disse: Ajunta-me este povo, e os farei ouvir as minhas palavras, e aprendê-las-ão, para me temerem todos os dias que na terra viverem, e as ensinarão a seus filhos;',
        'E vós vos chegastes, e vos pusestes ao pé do monte; e o monte ardia em fogo até ao meio dos céus, e havia trevas, e nuvens e escuridão;',
        'Então o Senhor vos falou do meio do fogo; a voz das palavras ouvistes; porém, além da voz, não vistes figura alguma.',
        'Então vos anunciou ele a sua aliança que vos ordenou cumprir, os dez mandamentos, e os escreveu em duas tábuas de pedra.',
        'Também o Senhor me ordenou ao mesmo tempo que vos ensinasse estatutos e juízos, para que os cumprísseis na terra a qual passais a possuir.',
        'Guardai, pois, com diligência as vossas almas, pois nenhuma figura vistes no dia em que o Senhor, em Horebe, falou convosco do meio do fogo;',
        'Para que não vos corrompais, e vos façais alguma imagem esculpida na forma de qualquer figura, semelhança de homem ou mulher;',
        'Figura de algum animal que haja na terra; figura de alguma ave alada que voa pelos céus;',
        'Figura de algum animal que se arrasta sobre a terra; figura de algum peixe que esteja nas águas debaixo da terra;',
        'Que não levantes os teus olhos aos céus e vejas o sol, e a lua, e as estrelas, todo o exército dos céus; e sejas impelido a que te inclines perante eles, e sirvas àqueles que o Senhor teu Deus repartiu a todos os povos debaixo de todos os céus.',
        'Mas o Senhor vos tomou, e vos tirou da fornalha de ferro do Egito, para que lhe sejais por povo hereditário, como neste dia se vê.',
        'Também o Senhor se indignou contra mim por causa das vossas palavras, e jurou que eu não passaria o Jordão, e que não entraria na boa terra que o Senhor teu Deus te dará por herança.',
        'Porque eu nesta terra morrerei, não passarei o Jordão; porém vós o passareis, e possuireis aquela boa terra.',
        'Guardai-vos e não vos esqueçais da aliança do Senhor vosso Deus, que tem feito convosco, e não façais para vós escultura alguma, imagem de alguma coisa que o Senhor vosso Deus vos proibiu.',
        'Porque o Senhor teu Deus é um fogo que consome, um Deus zeloso.',
        'Quando, pois, gerardes filhos, e filhos de filhos, e vos envelhecerdes na terra, e vos corromperdes, e fizerdes alguma escultura, semelhança de alguma coisa, e fizerdes o que é mau aos olhos do Senhor teu Deus, para o provocar à ira;',
        'Hoje tomo por testemunhas contra vós o céu e a terra, que certamente logo perecereis da terra, a qual passais o Jordão para a possuir; não prolongareis os vossos dias nela, antes sereis de todo destruídos.',
        'E o Senhor vos espalhará entre os povos, e ficareis poucos em número entre as nações às quais o Senhor vos conduzirá.',
        'E ali servireis a deuses que são obra de mãos de homens, madeira e pedra, que não vêem, nem ouvem, nem comem, nem cheiram.',
        'Então dali buscarás ao Senhor teu Deus, e o acharás, quando o buscares de todo o teu coração e de toda a tua alma.',
        'Quando estiverdes em angústia, e todas estas coisas te alcançarem, então nos últimos dias voltarás para o Senhor teu Deus, e ouvirás a sua voz.',
        'Porquanto o Senhor teu Deus é Deus misericordioso, e não te desamparará, nem te destruirá, nem se esquecerá da aliança que jurou a teus pais.',
        'Agora, pois, pergunta aos tempos passados, que te precederam desde o dia em que Deus criou o homem sobre a terra, desde uma extremidade do céu até à outra, se sucedeu jamais coisa tão grande como esta, ou se jamais se ouviu coisa como esta?',
        'Ou se algum povo ouviu a voz de Deus falando do meio do fogo, como tu a ouviste, e ficou vivo?',
        'Ou se Deus intentou ir tomar para si um povo do meio de outro povo com provas, com sinais, e com milagres, e com peleja, e com mão forte, e com braço estendido, e com grandes espantos, conforme a tudo quanto o Senhor vosso Deus vos fez no Egito aos vossos olhos?',
        'A ti te foi mostrado para que soubesses que o Senhor é Deus; nenhum outro há senão ele.',
        'Desde os céus te fez ouvir a sua voz, para te ensinar, e sobre a terra te mostrou o seu grande fogo, e ouviste as suas palavras do meio do fogo.',
        'E, porquanto amou teus pais, e escolheu a sua descendência depois deles, te tirou do Egito diante de si, com a sua grande força,',
        'Para lançar fora de diante de ti nações maiores e mais poderosas do que tu, para te introduzir e te dar a sua terra por herança, como neste dia se vê.',
        'Por isso hoje saberás, e refletirás no teu coração, que só o Senhor é Deus, em cima no céu e em baixo na terra; nenhum outro há.',
        'E guardarás os seus estatutos e os seus mandamentos, que te ordeno hoje para que te vá bem a ti, e a teus filhos depois de ti, e para que prolongues os dias na terra que o Senhor teu Deus te dá para todo o sempre.',
        'Então Moisés separou três cidades além do Jordão, do lado do nascimento do sol;',
        'Para que ali se acolhesse o homicida que involuntariamente matasse o seu próximo a quem dantes não tivesse ódio algum; e se acolhesse a uma destas cidades, e vivesse;',
        'A Bezer, no deserto, no planalto, para os rubenitas; e a Ramote, em Gileade, para os gaditas; e a Golã, em Basã, para os manassitas.',
        'Esta é, pois, a lei que Moisés propôs aos filhos de Israel.',
        'Estes são os testemunhos, e os estatutos, e os juízos, que Moisés falou aos filhos de Israel, havendo saído do Egito;',
        'Além do Jordão, no vale defronte de Bete-Peor, na terra de Siom, rei dos amorreus, que habitava em Hesbom, a quem feriu Moisés e os filhos de Israel, havendo eles saído do Egito.',
        'E tomaram a sua terra em possessão, como também a terra de Ogue, rei de Basã, dois reis dos amorreus, que estavam além do Jordão, do lado do nascimento do sol.',
        'Desde Aroer, que está à margem do ribeiro de Arnom, até ao monte Sião, que é Hermom,',
        'E toda a campina além do Jordão, do lado do oriente, até ao mar da campina, abaixo de Asdote-Pisga.',
      ],
      [
        'E chamou Moisés a todo o Israel, e disse-lhes: Ouve, ó Israel, os estatutos e juízos que hoje vos falo aos ouvidos; e aprendê-los-eis, e guardá-los-eis, para os cumprir.',
        'O Senhor nosso Deus fez conosco aliança em Horebe.',
        'Não com nossos pais fez o Senhor esta aliança, mas conosco, todos os que hoje aqui estamos vivos.',
        'Face a face o Senhor falou conosco no monte, do meio do fogo',
        '(Naquele tempo eu estava em pé entre o Senhor e vós, para vos notificar a palavra do Senhor; porque temestes o fogo e não subistes ao monte), dizendo:',
        'Eu sou o Senhor teu Deus, que te tirei da terra do Egito, da casa da servidão;',
        'Não terás outros deuses diante de mim;',
        'Não farás para ti imagem de escultura, nem semelhança alguma do que há em cima no céu, nem em baixo na terra, nem nas águas debaixo da terra;',
        'Não te encurvarás a elas, nem as servirás; porque eu, o Senhor teu Deus, sou Deus zeloso, que visito a iniqüidade dos pais nos filhos, até a terceira e quarta geração daqueles que me odeiam.',
        'E faço misericórdia a milhares dos que me amam e guardam os meus mandamentos.',
        'Não tomarás o nome do Senhor teu Deus em vão; porque o Senhor não terá por inocente ao que tomar o seu nome em vão.',
        'Guarda o dia de sábado, para o santificar, como te ordenou o Senhor teu Deus.',
        'Seis dias trabalharás, e farás todo o teu trabalho.',
        'Mas o sétimo dia é o sábado do Senhor teu Deus; não farás nenhum trabalho nele, nem tu, nem teu filho, nem tua filha, nem o teu servo, nem a tua serva, nem o teu boi, nem o teu jumento, nem animal algum teu, nem o estrangeiro que está dentro de tuas portas; para que o teu servo e a tua serva descansem como tu;',
        'Porque te lembrarás que foste servo na terra do Egito, e que o Senhor teu Deus te tirou dali com mão forte e braço estendido; por isso o Senhor teu Deus te ordenou que guardasses o dia de sábado.',
        'Honra a teu pai e a tua mãe, como o Senhor teu Deus te ordenou, para que se prolonguem os teus dias, e para que te vá bem na terra que te dá o Senhor teu Deus.',
        'Não matarás.',
        'Não adulterarás.',
        'Não furtarás.',
        'Não dirás falso testemunho contra o teu próximo.',
        'Não cobiçarás a mulher do teu próximo; e não desejarás a casa do teu próximo, nem o seu campo, nem o seu servo, nem a sua serva, nem o seu boi, nem o seu jumento, nem coisa alguma do teu próximo.',
        'Estas palavras falou o Senhor a toda a vossa congregação no monte, do meio do fogo, da nuvem e da escuridão, com grande voz, e nada acrescentou; e as escreveu em duas tábuas de pedra, e a mim mas deu.',
        'E sucedeu que, ouvindo a voz do meio das trevas, e vendo o monte ardendo em fogo, vos achegastes a mim, todos os cabeças das vossas tribos, e vossos anciãos;',
        'E dissestes: Eis aqui o Senhor nosso Deus nos fez ver a sua glória e a sua grandeza, e ouvimos a sua voz do meio do fogo; hoje vimos que Deus fala com o homem, e que este permanece vivo.',
        'Agora, pois, por que morreríamos? Pois este grande fogo nos consumiria; se ainda mais ouvíssemos a voz do Senhor nosso Deus morreríamos.',
        'Porque, quem há de toda a carne, que ouviu a voz do Deus vivente falando do meio do fogo, como nós, e ficou vivo?',
        'Chega-te tu, e ouve tudo o que disser o Senhor nosso Deus; e tu nos dirás tudo o que te disser o Senhor nosso Deus, e o ouviremos, e o cumpriremos.',
        'Ouvindo, pois, o Senhor as vossas palavras, quando me faláveis, o Senhor me disse: Eu ouvi as palavras deste povo, que eles te disseram; em tudo falaram bem.',
        'Quem dera que eles tivessem tal coração que me temessem, e guardassem todos os meus mandamentos todos os dias, para que bem lhes fosse a eles e a seus filhos para sempre.',
        'Vai, dize-lhes: Tornai-vos às vossas tendas.',
        'Tu, porém, fica-te aqui comigo, para que eu a ti te diga todos os mandamentos, e estatutos, e juízos, que tu lhes hás de ensinar, para que cumpram na terra que eu lhes darei para possuí-la.',
        'Olhai, pois, que façais como vos mandou o Senhor vosso Deus; não vos desviareis, nem para a direita nem para a esquerda.',
        'Andareis em todo o caminho que vos manda o Senhor vosso Deus, para que vivais e bem vos suceda, e prolongueis os dias na terra que haveis de possuir.',
      ],
      [
        'Estes, pois, são os mandamentos, os estatutos e os juízos que mandou o SENHOR vosso Deus para ensinar-vos, para que os cumprísseis na terra a que passais a possuir;',
        'Para que temas ao Senhor teu Deus, e guardes todos os seus estatutos e mandamentos, que eu te ordeno, tu, e teu filho, e o filho de teu filho, todos os dias da tua vida, e que teus dias sejam prolongados.',
        'Ouve, pois, ó Israel, e atenta em os guardares, para que bem te suceda, e muito te multipliques, como te disse o Senhor Deus de teus pais, na terra que mana leite e mel.',
        'Ouve, Israel, o Senhor nosso Deus é o único Senhor.',
        'Amarás, pois, o Senhor teu Deus de todo o teu coração, e de toda a tua alma, e de todas as tuas forças.',
        'E estas palavras, que hoje te ordeno, estarão no teu coração;',
        'E as ensinarás a teus filhos e delas falarás assentado em tua casa, e andando pelo caminho, e deitando-te e levantando-te.',
        'Também as atarás por sinal na tua mão, e te serão por frontais entre os teus olhos.',
        'E as escreverás nos umbrais de tua casa, e nas tuas portas.',
        'Quando, pois, o Senhor teu Deus te introduzir na terra que jurou a teus pais, Abraão, Isaque e Jacó, que te daria, com grandes e boas cidades, que tu não edificaste,',
        'E casas cheias de todo o bem, que tu não encheste, e poços cavados, que tu não cavaste, vinhas e olivais, que tu não plantaste, e comeres, e te fartares,',
        'Guarda-te, que não te esqueças do Senhor, que te tirou da terra do Egito, da casa da servidão.',
        'O Senhor teu Deus temerás e a ele servirás, e pelo seu nome jurarás.',
        'Não seguireis outros deuses, os deuses dos povos que houver ao redor de vós;',
        'Porque o Senhor teu Deus é um Deus zeloso no meio de ti, para que a ira do Senhor teu Deus se não acenda contra ti e te destrua de sobre a face da terra.',
        'Não tentareis o Senhor vosso Deus, como o tentastes em Massá;',
        'Diligentemente guardareis os mandamentos do Senhor vosso Deus, como também os seus testemunhos, e seus estatutos, que te tem mandado.',
        'E farás o que é reto e bom aos olhos do Senhor, para que bem te suceda, e entres, e possuas a boa terra, a qual o Senhor jurou dar a teus pais.',
        'Para que lance fora a todos os teus inimigos de diante de ti, como o Senhor tem falado.',
        'Quando teu filho te perguntar no futuro, dizendo: Que significam os testemunhos, e estatutos e juízos que o Senhor nosso Deus vos ordenou?',
        'Então dirás a teu filho: Éramos servos de Faraó no Egito; porém o Senhor, com mão forte, nos tirou do Egito;',
        'E o Senhor, aos nossos olhos, fez sinais e maravilhas, grandes e terríveis, contra o Egito, contra Faraó e toda sua casa;',
        'E dali nos tirou, para nos levar, e nos dar a terra que jurara a nossos pais.',
        'E o Senhor nos ordenou que cumpríssemos todos estes estatutos, que temêssemos ao Senhor nosso Deus, para o nosso perpétuo bem, para nos guardar em vida, como no dia de hoje.',
        'E será para nós justiça, quando tivermos cuidado de cumprir todos estes mandamentos perante o Senhor nosso Deus, como nos tem ordenado.',
      ],
      [
        'Quando o SENHOR teu Deus te houver introduzido na terra, à qual vais para a possuir, e tiver lançado fora muitas nações de diante de ti, os heteus, e os girgaseus, e os amorreus, e os cananeus, e os perizeus, e os heveus, e os jebuseus, sete nações mais numerosas e mais poderosas do que tu;',
        'E o Senhor teu Deus as tiver dado diante de ti, para as ferir, totalmente as destruirás; não farás com elas aliança, nem terás piedade delas;',
        'Nem te aparentarás com elas; não darás tuas filhas a seus filhos, e não tomarás suas filhas para teus filhos;',
        'Pois fariam desviar teus filhos de mim, para que servissem a outros deuses; e a ira do Senhor se acenderia contra vós, e depressa vos consumiria.',
        'Porém assim lhes fareis: Derrubareis os seus altares, quebrareis as suas estátuas; e cortareis os seus bosques, e queimareis a fogo as suas imagens de escultura.',
        'Porque povo santo és ao Senhor teu Deus; o Senhor teu Deus te escolheu, para que lhe fosses o seu povo especial, de todos os povos que há sobre a terra.',
        'O Senhor não tomou prazer em vós, nem vos escolheu, porque a vossa multidão era mais do que a de todos os outros povos, pois vós éreis menos em número do que todos os povos;',
        'Mas, porque o Senhor vos amava, e para guardar o juramento que fizera a vossos pais, o Senhor vos tirou com mão forte e vos resgatou da casa da servidão, da mão de Faraó, rei do Egito.',
        'Saberás, pois, que o Senhor teu Deus, ele é Deus, o Deus fiel, que guarda a aliança e a misericórdia até mil gerações aos que o amam e guardam os seus mandamentos.',
        'E retribui no rosto qualquer dos que o odeiam, fazendo-o perecer; não será tardio ao que o odeia; em seu rosto lho pagará.',
        'Guarda, pois, os mandamentos e os estatutos e os juízos que hoje te mando cumprir.',
        'Será, pois, que, se ouvindo estes juízos, os guardardes e cumprirdes, o Senhor teu Deus te guardará a aliança e a misericórdia que jurou a teus pais;',
        'E amar-te-á, e abençoar-te-á, e te fará multiplicar; abençoará o fruto do teu ventre, e o fruto da tua terra, o teu grão, e o teu mosto, e o teu azeite, e a criação das tuas vacas, e o rebanho do teu gado miúdo, na terra que jurou a teus pais dar-te.',
        'Bendito serás mais do que todos os povos; não haverá estéril entre ti, seja homem, seja mulher, nem entre os teus animais.',
        'E o Senhor de ti desviará toda a enfermidade; sobre ti não porá nenhuma das más doenças dos egípcios, que bem sabes, antes as porá sobre todos os que te odeiam.',
        'Pois consumirás a todos os povos que te der o Senhor teu Deus; os teus olhos não os poupará; e não servirás a seus deuses, pois isto te seria por laço.',
        'Se disseres no teu coração: Estas nações são mais numerosas do que eu; como as poderei lançar fora?',
        'Delas não tenhas temor; não deixes de te lembrar do que o Senhor teu Deus fez a Faraó e a todos os egípcios;',
        'Das grandes provas que viram os teus olhos, e dos sinais, e maravilhas, e mão forte, e braço estendido, com que o Senhor teu Deus te tirou; assim fará o Senhor teu Deus com todos os povos, diante dos quais tu temes.',
        'E mais, o Senhor teu Deus entre eles mandará vespões, até que pereçam os que ficarem e se esconderem de diante de ti.',
        'Não te espantes diante deles; porque o Senhor teu Deus está no meio de ti, Deus grande e terrível.',
        'E o Senhor teu Deus lançará fora estas nações pouco a pouco de diante de ti; não poderás destruí-las todas de pronto, para que as feras do campo não se multipliquem contra ti.',
        'E o Senhor teu Deus as entregará a ti, e lhes infligirá uma grande confusão até que sejam consumidas.',
        'Também os seus reis te entregará na mão, para que apagues os seus nomes de debaixo dos céus; nenhum homem resistirá diante de ti, até que os destruas.',
        'As imagens de escultura de seus deuses queimarás a fogo; a prata e o ouro que estão sobre elas não cobiçarás, nem os tomarás para ti, para que não te enlaces neles; pois abominação é ao Senhor teu Deus.',
        'Não porás, pois, abominação em tua casa, para que não sejas anátema, assim como ela; de todo a detestarás, e de todo a abominarás, porque anátema é.',
      ],
      [
        'Todos os mandamentos que hoje vos ordeno guardareis para os cumprir; para que vivais, e vos multipliqueis, e entreis, e possuais a terra que o SENHOR jurou a vossos pais.',
        'E te lembrarás de todo o caminho, pelo qual o Senhor teu Deus te guiou no deserto estes quarenta anos, para te humilhar, e te provar, para saber o que estava no teu coração, se guardarias os seus mandamentos, ou não.',
        'E te humilhou, e te deixou ter fome, e te sustentou com o maná, que tu não conheceste, nem teus pais o conheceram; para te dar a entender que o homem não viverá só de pão, mas de tudo o que sai da boca do Senhor viverá o homem.',
        'Nunca se envelheceu a tua roupa sobre ti, nem se inchou o teu pé nestes quarenta anos.',
        'Sabes, pois, no teu coração que, como um homem castiga a seu filho, assim te castiga o Senhor teu Deus.',
        'E guarda os mandamentos do Senhor teu Deus, para andares nos seus caminhos e para o temeres.',
        'Porque o Senhor teu Deus te põe numa boa terra, terra de ribeiros de águas, de fontes, e de mananciais, que saem dos vales e das montanhas;',
        'Terra de trigo e cevada, e de vides e figueiras, e romeiras; terra de oliveiras, de azeite e mel.',
        'Terra em que comerás o pão sem escassez, e nada te faltará nela; terra cujas pedras são ferro, e de cujos montes tu cavarás o cobre.',
        'Quando, pois, tiveres comido, e fores farto, louvarás ao Senhor teu Deus pela boa terra que te deu.',
        'Guarda-te que não te esqueças do Senhor teu Deus, deixando de guardar os seus mandamentos, e os seus juízos, e os seus estatutos que hoje te ordeno;',
        'Para não suceder que, havendo tu comido e fores farto, e havendo edificado boas casas, e habitando-as,',
        'E se tiverem aumentado os teus gados e os teus rebanhos, e se acrescentar a prata e o ouro, e se multiplicar tudo quanto tens,',
        'Se eleve o teu coração e te esqueças do Senhor teu Deus, que te tirou da terra do Egito, da casa da servidão;',
        'Que te guiou por aquele grande e terrível deserto de serpentes ardentes, e de escorpiões, e de terra seca, em que não havia água; e tirou água para ti da rocha pederneira;',
        'Que no deserto te sustentou com maná, que teus pais não conheceram; para te humilhar, e para te provar, para no fim te fazer bem;',
        'E digas no teu coração: A minha força, e a fortaleza da minha mão, me adquiriu este poder.',
        'Antes te lembrarás do Senhor teu Deus, que ele é o que te dá força para adquirires riqueza; para confirmar a sua aliança, que jurou a teus pais, como se vê neste dia.',
        'Será, porém, que, se de qualquer modo te esqueceres do Senhor teu Deus, e se ouvires outros deuses, e os servires, e te inclinares perante eles, hoje eu testifico contra vós que certamente perecereis.',
        'Como as nações que o Senhor destruiu diante de vós, assim vós perecereis, porquanto não queríeis obedecer à voz do Senhor vosso Deus.',
      ],
      [
        'Ouve, ó Israel, hoje passarás o Jordão, para entrares a possuir nações maiores e mais fortes do que tu; cidades grandes, e muradas até aos céus;',
        'Um povo grande e alto, filhos de gigantes, que tu conheces, e de que já ouviste. Quem resistiria diante dos filhos dos gigantes?',
        'Sabe, pois, hoje que o Senhor teu Deus, que passa adiante de ti, é um fogo consumidor, que os destruirá, e os derrubará de diante de ti; e tu os lançarás fora, e cedo os desfarás, como o Senhor te tem falado.',
        'Quando, pois, o Senhor teu Deus os lançar fora de diante de ti, não fales no teu coração, dizendo: Por causa da minha justiça é que o Senhor me trouxe a esta terra para a possuir; porque pela impiedade destas nações é que o Senhor as lança fora de diante de ti.',
        'Não é por causa da tua justiça, nem pela retidão do teu coração que entras a possuir a sua terra, mas pela impiedade destas nações o Senhor teu Deus as lança fora, de diante de ti, e para confirmar a palavra que o Senhor jurou a teus pais, Abraão, Isaque e Jacó.',
        'Sabe, pois, que não é por causa da tua justiça que o Senhor teu Deus te dá esta boa terra para possuí-la, pois tu és povo obstinado.',
        'Lembra-te, e não te esqueças, de que muito provocaste à ira ao Senhor teu Deus no deserto; desde o dia em que saístes do Egito, até que chegastes a esse lugar, rebeldes fostes contra o Senhor;',
        'Pois em Horebe provocastes à ira o Senhor, tanto que o Senhor se indignou contra vós para vos destruir.',
        'Subindo eu ao monte a receber as tábuas de pedra, as tábuas da aliança que o Senhor fizera convosco, então fiquei no monte quarenta dias e quarenta noites; pão não comi, e água não bebi;',
        'E o Senhor me deu as duas tábuas de pedra, escritas com o dedo de Deus; e nelas estava escrito conforme a todas aquelas palavras que o Senhor tinha falado convosco no monte, do meio do fogo, no dia da assembléia.',
        'Sucedeu, pois, que ao fim dos quarenta dias e quarenta noites, o Senhor me deu as duas tábuas de pedra, as tábuas da aliança.',
        'E o Senhor me disse: Levanta-te, desce depressa daqui, porque o teu povo, que tiraste do Egito, já se tem corrompido; cedo se desviaram do caminho que eu lhes tinha ordenado; fizeram para si uma imagem de fundição.',
        'Falou-me ainda o Senhor, dizendo: Atentei para este povo, e eis que ele é povo obstinado;',
        'Deixa-me que os destrua, e apague o seu nome de debaixo dos céus; e te faça a ti nação mais poderosa e mais numerosa do que esta.',
        'Então virei-me, e desci do monte; o qual ardia em fogo e as duas tábuas da aliança estavam em ambas as minhas mãos.',
        'E olhei, e eis que havíeis pecado contra o Senhor vosso Deus; vós tínheis feito um bezerro de fundição; cedo vos desviastes do caminho que o Senhor vos ordenara.',
        'Então peguei das duas tábuas, e as arrojei das minhas mãos, e as quebrei diante dos vossos olhos.',
        'E me lancei perante o Senhor, como antes, quarenta dias, e quarenta noites; não comi pão e não bebi água, por causa de todo o vosso pecado que havíeis cometido, fazendo mal aos olhos do Senhor, para o provocar à ira.',
        'Porque temi por causa da ira e do furor, com que o Senhor tanto estava irado contra vós para vos destruir; porém ainda por esta vez o Senhor me ouviu.',
        'Também o Senhor se irou muito contra Arão para o destruir; mas também orei por Arão ao mesmo tempo.',
        'Porém eu tomei o vosso pecado, o bezerro que tínheis feito, e o queimei a fogo, e o pisei, moendo-o bem, até que se desfez em pó; e o seu pó lancei no ribeiro que descia do monte.',
        'Também em Taberá, e em Massá, e em Quibrote-Hataavá provocastes muito a ira do Senhor.',
        'Quando também o Senhor vos enviou de Cades-Barnéia, dizendo: Subi, e possuí a terra, que vos tenho dado: rebeldes fostes ao mandado do Senhor vosso Deus, e não o crestes, e não obedecestes à sua voz.',
        'Rebeldes fostes contra o Senhor desde o dia em que vos conheci.',
        'E prostrei-me perante o Senhor; aqueles quarenta dias e quarenta noites estive prostrado, porquanto o Senhor dissera que vos queria destruir.',
        'E orei ao SENHOR, dizendo: Senhor DEUS, não destruas o teu povo e a tua herança, que resgataste com a tua grandeza, que tiraste do Egito com mão forte.',
        'Lembra-te dos teus servos, Abraão, Isaque, e Jacó. Não atentes para a dureza deste povo, nem para a sua impiedade, nem para o seu pecado;',
        'Para que o povo da terra donde nos tiraste não diga: Porquanto o Senhor não os pôde introduzir na terra de que lhes tinha falado, e porque os odiava, os tirou para matá-los no deserto;',
        'Todavia são eles o teu povo e a tua herança, que tiraste com a tua grande força e com o teu braço estendido.',
      ],
      [
        'Naquele mesmo tempo me disse o SENHOR: Alisa duas tábuas de pedra, como as primeiras, e sobe a mim ao monte, e faze-te uma arca de madeira;',
        'E naquelas tábuas escreverei as palavras que estavam nas primeiras tábuas, que quebraste, e as porás na arca.',
        'Assim, fiz uma arca de madeira de acácia, e alisei duas tábuas de pedra, como as primeiras; e subi ao monte com as duas tábuas na minha mão.',
        'Então escreveu nas tábuas, conforme à primeira escritura, os dez mandamentos, que o Senhor vos falara no dia da assembléia, no monte, do meio do fogo; e o Senhor mas deu a mim;',
        'E virei-me, e desci do monte, e pus as tábuas na arca que fizera; e ali estão, como o Senhor me ordenou.',
        'E partiram os filhos de Israel de Beerote-Bene-Jaacã a Moserá; ali faleceu Arão, e ali foi sepultado, e Eleazar, seu filho, administrou o sacerdócio em seu lugar.',
        'Dali partiram a Gudgodá, e de Gudgodá a Jotbatá, terra de ribeiros de águas.',
        'No mesmo tempo o Senhor separou a tribo de Levi, para levar a arca da aliança do Senhor, para estar diante do Senhor, para o servir, e para abençoar em seu nome até ao dia de hoje.',
        'Por isso Levi não tem parte nem herança com seus irmãos; o Senhor é a sua herança, como o Senhor teu Deus lhe tem falado.',
        'E eu estive no monte, como nos primeiros dias, quarenta dias e quarenta noites; e o Senhor me ouviu ainda por esta vez; não quis o Senhor destruir-te.',
        'Porém o Senhor me disse: Levanta-te, põe-te a caminho adiante do povo, para que entrem, e possuam a terra que jurei dar a seus pais.',
        'Agora, pois, ó Israel, que é que o Senhor teu Deus pede de ti, senão que temas o Senhor teu Deus, que andes em todos os seus caminhos, e o ames, e sirvas ao Senhor teu Deus com todo o teu coração e com toda a tua alma,',
        'Que guardes os mandamentos do Senhor, e os seus estatutos, que hoje te ordeno, para o teu bem?',
        'Eis que os céus e os céus dos céus são do Senhor teu Deus, a terra e tudo o que nela há.',
        'Tão-somente o Senhor se agradou de teus pais para os amar; e a vós, descendência deles, escolheu, depois deles, de todos os povos como neste dia se vê.',
        'Circuncidai, pois, o prepúcio do vosso coração, e não mais endureçais a vossa cerviz.',
        'Pois o SENHOR vosso Deus é o Deus dos deuses, e o Senhor dos senhores, o Deus grande, poderoso e terrível, que não faz acepção de pessoas, nem aceita recompensas;',
        'Que faz justiça ao órfão e à viúva, e ama o estrangeiro, dando-lhe pão e roupa.',
        'Por isso amareis o estrangeiro, pois fostes estrangeiros na terra do Egito.',
        'Ao Senhor teu Deus temerás; a ele servirás, e a ele te chegarás, e pelo seu nome jurarás.',
        'Ele é o teu louvor e o teu Deus, que te fez estas grandes e terríveis coisas que os teus olhos têm visto.',
        'Com setenta almas teus pais desceram ao Egito; e agora o Senhor teu Deus te pôs como as estrelas dos céus em multidão.',
      ],
      [
        'Amarás, pois, ao SENHOR teu Deus, e guardarás as suas ordenanças, e os seus estatutos, e os seus juízos, e os seus mandamentos, todos os dias.',
        'E hoje sabereis que falo, não com vossos filhos, que o não sabem, e não viram a instrução do Senhor vosso Deus, a sua grandeza, a sua mão forte, e o seu braço estendido;',
        'Nem tampouco os seus sinais, nem os seus feitos, que fez no meio do Egito a Faraó, rei do Egito, e a toda a sua terra;',
        'Nem o que fez ao exército dos egípcios, aos seus cavalos e aos seus carros, fazendo passar sobre eles as águas do Mar Vermelho quando vos perseguiam, e como o Senhor os destruiu, até ao dia de hoje;',
        'Nem o que vos fez no deserto, até que chegastes a este lugar;',
        'E o que fez a Datã e a Abirão, filhos de Eliabe, filho de Rúben; como a terra abriu a sua boca e os tragou com as suas casas e com as suas tendas, como também tudo o que subsistia, e lhes pertencia, no meio de todo o Israel;',
        'Porquanto os vossos olhos são os que viram toda a grande obra que fez o Senhor.',
        'Guardai, pois, todos os mandamentos que eu vos ordeno hoje, para que sejais fortes, e entreis, e ocupeis a terra que passais a possuir;',
        'E para que prolongueis os dias na terra que o Senhor jurou dar a vossos pais e à sua descendência, terra que mana leite e mel.',
        'Porque a terra que passas a possuir não é como a terra do Egito, de onde saíste, em que semeavas a tua semente, e a regavas com o teu pé, como a uma horta.',
        'Mas a terra que passais a possuir é terra de montes e de vales; da chuva dos céus beberá as águas;',
        'Terra de que o Senhor teu Deus tem cuidado; os olhos do Senhor teu Deus estão sobre ela continuamente, desde o princípio até ao fim do ano.',
        'E será que, se diligentemente obedecerdes a meus mandamentos que hoje vos ordeno, de amar ao Senhor vosso Deus, e de o servir de todo o vosso coração e de toda a vossa alma,',
        'Então darei a chuva da vossa terra a seu tempo, a temporã e a serôdia, para que recolhais o vosso grão, e o vosso mosto e o vosso azeite.',
        'E darei erva no teu campo aos teus animais, e comerás, e fartar-te-ás.',
        'Guardai-vos, que o vosso coração não se engane, e vos desvieis, e sirvais a outros deuses, e vos inclineis perante eles;',
        'E a ira do Senhor se acenda contra vós, e feche ele os céus, e não haja água, e a terra não dê o seu fruto, e cedo pereçais da boa terra que o Senhor vos dá.',
        'Ponde, pois, estas minhas palavras no vosso coração e na vossa alma, e atai-as por sinal na vossa mão, para que estejam por frontais entre os vossos olhos.',
        'E ensinai-as a vossos filhos, falando delas assentado em tua casa, e andando pelo caminho, e deitando-te, e levantando-te;',
        'E escreve-as nos umbrais de tua casa, e nas tuas portas;',
        'Para que se multipliquem os vossos dias e os dias de vossos filhos na terra que o Senhor jurou a vossos pais dar-lhes, como os dias dos céus sobre a terra.',
        'Porque se diligentemente guardardes todos estes mandamentos, que vos ordeno para os guardardes, amando ao Senhor vosso Deus, andando em todos os seus caminhos, e a ele vos achegardes,',
        'Também o Senhor, de diante de vós, lançará fora todas estas nações, e possuireis nações maiores e mais poderosas do que vós.',
        'Todo o lugar que pisar a planta do vosso pé será vosso; desde o deserto, e desde o Líbano, desde o rio, o rio Eufrates, até ao mar ocidental, será o vosso termo.',
        'Ninguém resistirá diante de vós; o Senhor vosso Deus porá sobre toda a terra, que pisardes, o vosso terror e o temor de vós, como já vos tem dito.',
        'Eis que hoje eu ponho diante de vós a bênção e a maldição;',
        'A bênção, quando cumprirdes os mandamentos do Senhor vosso Deus, que hoje vos mando;',
        'Porém a maldição, se não cumprirdes os mandamentos do Senhor vosso Deus, e vos desviardes do caminho que hoje vos ordeno, para seguirdes outros deuses que não conhecestes.',
        'E será que, quando o Senhor teu Deus te introduzir na terra, a que vais para possuí-la, então pronunciarás a bênção sobre o monte Gerizim, e a maldição sobre o monte Ebal.',
        'Porventura não estão eles além do Jordão, junto ao caminho do pôr do sol, na terra dos cananeus, que habitam na campina defronte de Gilgal, junto aos carvalhais de Moré?',
        'Porque passareis o Jordão para entrardes a possuir a terra, que vos dá o Senhor vosso Deus; e a possuireis, e nela habitareis.',
        'Tende, pois, cuidado em cumprir todos os estatutos e os juízos, que eu hoje vos proponho.',
      ],
      [
        'Estes são os estatutos e os juízos que tereis cuidado em cumprir na terra que vos deu o SENHOR Deus de vossos pais, para a possuir todos os dias que viverdes sobre a terra.',
        'Totalmente destruireis todos os lugares, onde as nações que possuireis serviram os seus deuses, sobre as altas montanhas, e sobre os outeiros, e debaixo de toda a árvore frondosa;',
        'E derrubareis os seus altares, e quebrareis as suas estátuas, e os seus bosques queimareis a fogo, e destruireis as imagens esculpidas dos seus deuses, e apagareis o seu nome daquele lugar.',
        'Assim não fareis ao Senhor vosso Deus;',
        'Mas o lugar que o Senhor vosso Deus escolher de todas as vossas tribos, para ali pôr o seu nome, buscareis, para sua habitação, e ali vireis.',
        'E ali trareis os vossos holocaustos, e os vossos sacrifícios, e os vossos dízimos, e a oferta alçada da vossa mão, e os vossos votos, e as vossas ofertas voluntárias, e os primogênitos das vossas vacas e das vossas ovelhas.',
        'E ali comereis perante o Senhor vosso Deus, e vos alegrareis em tudo em que puserdes a vossa mão, vós e as vossas casas, no que abençoar o Senhor vosso Deus.',
        'Não fareis conforme a tudo o que hoje fazemos aqui, cada qual tudo o que bem parece aos seus olhos.',
        'Porque até agora não entrastes no descanso e na herança que vos dá o Senhor vosso Deus.',
        'Mas passareis o Jordão, e habitareis na terra que vos fará herdar o Senhor vosso Deus; e vos dará repouso de todos os vossos inimigos em redor, e morareis seguros.',
        'Então haverá um lugar que escolherá o Senhor vosso Deus para ali fazer habitar o seu nome; ali trareis tudo o que vos ordeno; os vossos holocaustos, e os vossos sacrifícios, e os vossos dízimos, e a oferta alçada da vossa mão, e toda a escolha dos vossos votos que fizerdes ao Senhor.',
        'E vos alegrareis perante o Senhor vosso Deus, vós, e vossos filhos, e vossas filhas, e os vossos servos, e as vossas servas, e o levita que está dentro das vossas portas; pois convosco não tem parte nem herança.',
        'Guarda-te, que não ofereças os teus holocaustos em todo o lugar que vires;',
        'Mas no lugar que o Senhor escolher numa das tuas tribos ali oferecerás os teus holocaustos, e ali farás tudo o que te ordeno.',
        'Porém, conforme a todo o desejo da tua alma, matarás e comerás carne, dentro das tuas portas, segundo a bênção do Senhor teu Deus, que te dá em todas as tuas portas; o imundo e o limpo dela comerá, como do corço e do veado;',
        'Tão-somente o sangue não comereis; sobre a terra o derramareis como água.',
        'Dentro das tuas portas não poderás comer o dízimo do teu grão, nem do teu mosto, nem do teu azeite, nem os primogênitos das tuas vacas, nem das tuas ovelhas; nem nenhum dos teus votos, que houveres prometido, nem as tuas ofertas voluntárias, nem a oferta alçada da tua mão.',
        'Mas os comerás perante o Senhor teu Deus, no lugar que escolher o Senhor teu Deus, tu, e teu filho, e a tua filha, e o teu servo, e a tua serva, e o levita que está dentro das tuas portas; e perante o Senhor teu Deus te alegrarás em tudo em que puseres a tua mão.',
        'Guarda-te, que não desampares ao levita todos os teus dias na terra.',
        'Quando o Senhor teu Deus dilatar os teus termos, como te disse, e disseres: Comerei carne; porquanto a tua alma tem desejo de comer carne; conforme a todo o desejo da tua alma, comerás carne.',
        'Se estiver longe de ti o lugar que o Senhor teu Deus escolher, para ali pôr o seu nome, então matarás das tuas vacas e das tuas ovelhas, que o Senhor te tiver dado, como te tenho ordenado; e comerás dentro das tuas portas, conforme a todo o desejo da tua alma.',
        'Porém, como se come o corço e o veado, assim comerás; o imundo e o limpo também comerão deles.',
        'Somente esforça-te para que não comas o sangue; pois o sangue é vida; pelo que não comerás a vida com a carne;',
        'Não o comerás; na terra o derramarás como água.',
        'Não o comerás; para que bem te suceda a ti, e a teus filhos, depois de ti, quando fizeres o que for reto aos olhos do Senhor.',
        'Porém, as coisas santas que tiveres, e os teus votos tomarás, e virás ao lugar que o Senhor escolher.',
        'E oferecerás os teus holocaustos, a carne e o sangue sobre o altar do Senhor teu Deus; e o sangue dos teus sacrifícios se derramará sobre o altar do Senhor teu Deus; porém a carne comerás.',
        'Guarda e ouve todas estas palavras que te ordeno, para que bem te suceda a ti e a teus filhos depois de ti para sempre, quando fizeres o que for bom e reto aos olhos do Senhor teu Deus.',
        'Quando o Senhor teu Deus desarraigar de diante de ti as nações, aonde vais a possuí-las, e as possuíres e habitares na sua terra,',
        'Guarda-te, que não te enlaces seguindo-as, depois que forem destruídas diante de ti; e que não perguntes acerca dos seus deuses, dizendo: Assim como serviram estas nações os seus deuses, do mesmo modo também farei eu.',
        'Assim não farás ao Senhor teu Deus; porque tudo o que é abominável ao Senhor, e que ele odeia, fizeram eles a seus deuses; pois até seus filhos e suas filhas queimaram no fogo aos seus deuses.',
        'Tudo o que eu te ordeno, observarás para fazer; nada lhe acrescentarás nem diminuirás.',
      ],
      [
        'Quando profeta ou sonhador de sonhos se levantar no meio de ti, e te der um sinal ou prodígio,',
        'E suceder o tal sinal ou prodígio, de que te houver falado, dizendo: Vamos após outros deuses, que não conheceste, e sirvamo-los;',
        'Não ouvirás as palavras daquele profeta ou sonhador de sonhos; porquanto o Senhor vosso Deus vos prova, para saber se amais o Senhor vosso Deus com todo o vosso coração, e com toda a vossa alma.',
        'Após o Senhor vosso Deus andareis, e a ele temereis, e os seus mandamentos guardareis, e a sua voz ouvireis, e a ele servireis, e a ele vos achegareis.',
        'E aquele profeta ou sonhador de sonhos morrerá, pois falou rebeldia contra o Senhor vosso Deus, que vos tirou da terra do Egito, e vos resgatou da casa da servidão, para te apartar do caminho que te ordenou o Senhor teu Deus, para andares nele: assim tirarás o mal do meio de ti.',
        'Quando te incitar teu irmão, filho da tua mãe, ou teu filho, ou tua filha, ou a mulher do teu seio, ou teu amigo, que te é como a tua alma, dizendo-te em segredo: Vamos, e sirvamos a outros deuses que não conheceste, nem tu nem teus pais;',
        'Dentre os deuses dos povos que estão em redor de vós, perto ou longe de ti, desde uma extremidade da terra até à outra extremidade;',
        'Não consentirás com ele, nem o ouvirás; nem o teu olho o poupará, nem terás piedade dele, nem o esconderás;',
        'Mas certamente o matarás; a tua mão será a primeira contra ele, para o matar; e depois a mão de todo o povo.',
        'E o apedrejarás, até que morra, pois te procurou apartar do Senhor teu Deus, que te tirou da terra do Egito, da casa da servidão;',
        'Para que todo o Israel o ouça e o tema, e não torne a fazer semelhante maldade no meio de ti.',
        'Quando ouvires dizer, de alguma das tuas cidades que o Senhor teu Deus te dá para ali habitar:',
        'Uns homens, filhos de Belial, que saíram do meio de ti, incitaram os moradores da sua cidade, dizendo: Vamos, e sirvamos a outros deuses que não conhecestes;',
        'Então inquirirás e investigarás, e com diligência perguntarás; e eis que, sendo verdade, e certo que se fez tal abominação no meio de ti;',
        'Certamente ferirás, ao fio da espada, os moradores daquela cidade, destruindo a ela e a tudo o que nela houver, até os animais.',
        'E ajuntarás todo o seu despojo no meio da sua praça; e a cidade e todo o seu despojo queimarás totalmente para o Senhor teu Deus, e será montão perpétuo, nunca mais se edificará.',
        'Também não se pegará à tua mão nada do anátema, para que o Senhor se aparte do ardor da sua ira, e te faça misericórdia, e tenha piedade de ti, e te multiplique, como jurou a teus pais;',
        'Quando ouvires a voz do Senhor teu Deus, para guardares todos os seus mandamentos que hoje te ordeno; para fazeres o que for reto aos olhos do Senhor teu Deus.',
      ],
      [
        'Filhos sois do SENHOR vosso Deus; não vos dareis golpes, nem fareis calva entre vossos olhos por causa de algum morto.',
        'Porque és povo santo ao Senhor teu Deus; e o Senhor te escolheu, de todos os povos que há sobre a face da terra, para lhe seres o seu próprio povo.',
        'Nenhuma coisa abominável comereis.',
        'Estes são os animais que comereis: o boi, a ovelha, e a cabra.',
        'O veado e a corça, e o búfalo, e a cabra montês, e o texugo, e a camurça, e o gamo.',
        'Todo o animal que tem unhas fendidas, divididas em duas, que rumina, entre os animais, aquilo comereis.',
        'Porém estes não comereis, dos que somente ruminam, ou que têm a unha fendida: o camelo, e a lebre, e o coelho, porque ruminam mas não têm a unha fendida; imundos vos serão.',
        'Nem o porco, porque tem unha fendida, mas não rumina; imundo vos será; não comereis da carne destes, e não tocareis nos seus cadáveres.',
        'Isto comereis de tudo o que há nas águas; tudo o que tem barbatanas e escamas comereis.',
        'Mas tudo o que não tiver barbatanas nem escamas não o comereis; imundo vos será.',
        'Toda a ave limpa comereis.',
        'Porém estas são as que não comereis: a águia, e o quebrantosso, e o xofrango,',
        'E o abutre, e o falcão, e o milhafre, segundo a sua espécie.',
        'E todo o corvo, segundo a sua espécie.',
        'E o avestruz, e o mocho, e a gaivota, e o gavião, segundo a sua espécie.',
        'E o bufo, e a coruja, e a gralha,',
        'E o cisne, e o pelicano, e o corvo marinho,',
        'E a cegonha, e a garça, segundo a sua espécie, e a poupa, e o morcego.',
        'Também todo o inseto que voa, vos será imundo; não se comerá.',
        'Toda a ave limpa comereis.',
        'Não comereis nenhum animal morto; ao estrangeiro, que está dentro das tuas portas, o darás a comer, ou o venderás ao estranho, porquanto és povo santo ao Senhor teu Deus. Não cozerás o cabrito com leite da sua mãe.',
        'Certamente darás os dízimos de todo o fruto da tua semente, que cada ano se recolher do campo.',
        'E, perante o Senhor teu Deus, no lugar que escolher para ali fazer habitar o seu nome, comerás os dízimos do teu grão, do teu mosto e do teu azeite, e os primogênitos das tuas vacas e das tuas ovelhas; para que aprendas a temer ao Senhor teu Deus todos os dias.',
        'E quando o caminho te for tão comprido que os não possas levar, por estar longe de ti o lugar que escolher o Senhor teu Deus para ali pôr o seu nome, quando o Senhor teu Deus te tiver abençoado;',
        'Então vende-os, e ata o dinheiro na tua mão, e vai ao lugar que escolher o Senhor teu Deus;',
        'E aquele dinheiro darás por tudo o que deseja a tua alma, por vacas, e por ovelhas, e por vinho, e por bebida forte, e por tudo o que te pedir a tua alma; come-o ali perante o Senhor teu Deus, e alegra-te, tu e a tua casa;',
        'Porém não desampararás o levita que está dentro das tuas portas; pois não tem parte nem herança contigo.',
        'Ao fim de três anos tirarás todos os dízimos da tua colheita no mesmo ano, e os recolherás dentro das tuas portas;',
        'Então virá o levita (pois nem parte nem herança tem contigo), e o estrangeiro, e o órfão, e a viúva, que estão dentro das tuas portas, e comerão, e fartar-se-ão; para que o Senhor teu Deus te abençoe em toda a obra que as tuas mãos fizerem.',
      ],
      [
        'Ao fim dos sete anos farás remissão.',
        'Este, pois, é o modo da remissão: todo o credor remitirá o que emprestou ao seu próximo; não o exigirá do seu próximo ou do seu irmão, pois a remissão do Senhor é apregoada.',
        'Do estrangeiro o exigirás; mas o que tiveres em poder de teu irmão a tua mão o remitirá.',
        'Exceto quando não houver entre ti pobre algum; pois o Senhor abundantemente te abençoará na terra que o Senhor teu Deus te dará por herança, para possuí-la.',
        'Se somente ouvires diligentemente a voz do Senhor teu Deus para cuidares em cumprir todos estes mandamentos que hoje te ordeno;',
        'Porque o Senhor teu Deus te abençoará, como te tem falado; assim, emprestarás a muitas nações, mas não tomarás empréstimos; e dominarás sobre muitas nações, mas elas não dominarão sobre ti.',
        'Quando entre ti houver algum pobre, de teus irmãos, em alguma das tuas portas, na terra que o Senhor teu Deus te dá, não endurecerás o teu coração, nem fecharás a tua mão a teu irmão que for pobre;',
        'Antes lhe abrirás de todo a tua mão, e livremente lhe emprestarás o que lhe falta, quanto baste para a sua necessidade.',
        'Guarda-te, que não haja palavra perversa no teu coração, dizendo: Vai-se aproximando o sétimo ano, o ano da remissão; e que o teu olho seja maligno para com teu irmão pobre, e não lhe dês nada; e que ele clame contra ti ao Senhor, e que haja em ti pecado.',
        'Livremente lhe darás, e que o teu coração não seja maligno, quando lhe deres; pois por esta causa te abençoará o Senhor teu Deus em toda a tua obra, e em tudo o que puseres a tua mão.',
        'Pois nunca deixará de haver pobre na terra; pelo que te ordeno, dizendo: Livremente abrirás a tua mão para o teu irmão, para o teu necessitado, e para o teu pobre na tua terra.',
        'Quando teu irmão hebreu ou irmã hebréia se vender a ti, seis anos te servirá, mas no sétimo ano o deixarás ir livre.',
        'E, quando o deixares ir livre, não o despedirás vazio.',
        'Liberalmente o fornecerás do teu rebanho, e da tua eira, e do teu lagar; daquilo com que o Senhor teu Deus te tiver abençoado lhe darás.',
        'E lembrar-te-ás de que foste servo na terra do Egito, e de que o Senhor teu Deus te resgatou; portanto hoje te ordeno isso.',
        'Porém se ele te disser: Não sairei de ti; porquanto te amo a ti, e a tua casa, por estar bem contigo;',
        'Então tomarás uma sovela, e lhe furarás a orelha à porta, e teu servo será para sempre; e também assim farás à tua serva.',
        'Não seja duro aos teus olhos, quando despedi-lo liberto de ti; pois seis anos te serviu em equivalência ao dobro do salário do diarista; assim o Senhor teu Deus te abençoará em tudo o que fizeres.',
        'Todo o primogênito que nascer das tuas vacas e das tuas ovelhas, o macho santificarás ao Senhor teu Deus; com o primogênito do teu boi não trabalharás, nem tosquiarás o primogênito das tuas ovelhas.',
        'Perante o Senhor teu Deus os comerás de ano em ano, no lugar que o Senhor escolher, tu e a tua casa.',
        'Porém, havendo nele algum defeito, se for coxo, ou cego, ou tiver qualquer defeito, não o sacrificarás ao Senhor teu Deus.',
        'Nas tuas portas o comerás; o imundo e o limpo o comerão também, como da corça ou do veado.',
        'Somente o seu sangue não comerás; sobre a terra o derramarás como água.',
      ],
      [
        'Guarda o mês de Abibe, e celebra a páscoa ao SENHOR teu Deus; porque no mês de Abibe o SENHOR teu Deus te tirou do Egito, de noite.',
        'Então sacrificarás a páscoa ao Senhor teu Deus, das ovelhas e das vacas, no lugar que o Senhor escolher para ali fazer habitar o seu nome.',
        'Nela não comerás levedado; sete dias nela comerás pães ázimos, pão de aflição (porquanto apressadamente saíste da terra do Egito), para que te lembres do dia da tua saída da terra do Egito, todos os dias da tua vida.',
        'Levedado não aparecerá contigo por sete dias em todos os teus termos; também da carne que matares à tarde, no primeiro dia, nada ficará até à manhã.',
        'Não poderás sacrificar a páscoa em nenhuma das tuas portas que te dá o Senhor teu Deus;',
        'Senão no lugar que escolher o Senhor teu Deus, para fazer habitar o seu nome, ali sacrificarás a páscoa à tarde, ao pôr do sol, ao tempo determinado da tua saída do Egito.',
        'Então a cozerás, e comerás no lugar que escolher o Senhor teu Deus; depois voltarás pela manhã, e irás às tuas tendas.',
        'Seis dias comerás pães ázimos e no sétimo dia é solenidade ao Senhor teu Deus; nenhum trabalho farás.',
        'Sete semanas contarás; desde que a foice começar na seara iniciarás a contar as sete semanas.',
        'Depois celebrarás a festa das semanas ao Senhor teu Deus; o que deres será oferta voluntária da tua mão, segundo o Senhor teu Deus te houver abençoado.',
        'E te alegrarás perante o Senhor teu Deus, tu, e teu filho, e tua filha, e o teu servo, e a tua serva, e o levita que está dentro das tuas portas, e o estrangeiro, e o órfão, e a viúva, que estão no meio de ti, no lugar que o Senhor teu Deus escolher para ali fazer habitar o seu nome.',
        'E lembrar-te-ás de que foste servo no Egito; e guardarás estes estatutos, e os cumprirás.',
        'A festa dos tabernáculos celebrarás sete dias, quando tiveres colhido da tua eira e do teu lagar.',
        'E, na tua festa, alegrar-te-ás, tu, e teu filho, e tua filha, e o teu servo, e a tua serva, e o levita, e o estrangeiro, e o órfão, e a viúva, que estão dentro das tuas portas.',
        'Sete dias celebrarás a festa ao Senhor teu Deus, no lugar que o Senhor escolher; porque o Senhor teu Deus te há de abençoar em toda a tua colheita, e em todo o trabalho das tuas mãos; por isso certamente te alegrarás.',
        'Três vezes no ano todo o homem entre ti aparecerá perante o Senhor teu Deus, no lugar que escolher, na festa dos pães ázimos, e na festa das semanas, e na festa dos tabernáculos; porém não aparecerá vazio perante o Senhor;',
        'Cada um, conforme ao dom da sua mão, conforme a bênção do Senhor teu Deus, que lhe tiver dado.',
        'Juízes e oficiais porás em todas as tuas cidades que o Senhor teu Deus te der entre as tuas tribos, para que julguem o povo com juízo de justiça.',
        'Não torcerás o juízo, não farás acepção de pessoas, nem receberás peitas; porquanto a peita cega os olhos dos sábios, e perverte as palavras dos justos.',
        'A justiça, somente a justiça seguirás; para que vivas, e possuas em herança a terra que te dará o Senhor teu Deus.',
        'Não plantarás nenhuma árvore junto ao altar do Senhor teu Deus, que fizeres para ti.',
        'Nem levantarás imagem, a qual o Senhor teu Deus odeia.',
      ],
      [
        'Não sacrificarás ao SENHOR teu Deus, boi ou gado miúdo em que haja defeito ou alguma coisa má; pois abominação é ao SENHOR teu Deus.',
        'Quando no meio de ti, em alguma das tuas portas que te dá o Senhor teu Deus, se achar algum homem ou mulher que fizer mal aos olhos do Senhor teu Deus, transgredindo a sua aliança.',
        'Que se for, e servir a outros deuses, e se encurvar a eles ou ao sol, ou à lua, ou a todo o exército do céu, o que eu não ordenei,',
        'E te for denunciado, e o ouvires; então bem o inquirirás; e eis que, sendo verdade, e certo que se fez tal abominação em Israel,',
        'Então tirarás o homem ou a mulher que fez este malefício, às tuas portas, e apedrejarás o tal homem ou mulher, até que morra.',
        'Por boca de duas testemunhas, ou três testemunhas, será morto o que houver de morrer; por boca de uma só testemunha não morrerá.',
        'As mãos das testemunhas serão primeiro contra ele, para matá-lo; e depois as mãos de todo o povo; assim tirarás o mal do meio de ti.',
        'Quando alguma coisa te for difícil demais em juízo, entre sangue e sangue, entre demanda e demanda, entre ferida e ferida, em questões de litígios nas tuas portas, então te levantarás, e subirás ao lugar que escolher o Senhor teu Deus;',
        'E virás aos sacerdotes levitas, e ao juiz que houver naqueles dias, e inquirirás, e te anunciarão a sentença do juízo.',
        'E farás conforme ao mandado da palavra que te anunciarem no lugar que escolher o Senhor; e terás cuidado de fazer conforme a tudo o que te ensinarem.',
        'Conforme ao mandado da lei que te ensinarem, e conforme ao juízo que te disserem, farás; da palavra que te anunciarem te não desviarás, nem para a direita nem para a esquerda.',
        'O homem, pois, que se houver soberbamente, não dando ouvidos ao sacerdote, que está ali para servir ao Senhor teu Deus, nem ao juiz, esse homem morrerá; e tirarás o mal de Israel;',
        'Para que todo o povo o ouça, e tema, e nunca mais se ensoberbeça.',
        'Quando entrares na terra que te dá o Senhor teu Deus, e a possuíres, e nela habitares, e disseres: Porei sobre mim um rei, assim como têm todas as nações que estão em redor de mim;',
        'Porás certamente sobre ti como rei aquele que escolher o Senhor teu Deus; dentre teus irmãos porás rei sobre ti; não poderás pôr homem estranho sobre ti, que não seja de teus irmãos.',
        'Porém ele não multiplicará para si cavalos, nem fará voltar o povo ao Egito para multiplicar cavalos; pois o Senhor vos tem dito: Nunca mais voltareis por este caminho.',
        'Tampouco para si multiplicará mulheres, para que o seu coração não se desvie; nem prata nem ouro multiplicará muito para si.',
        'Será também que, quando se assentar sobre o trono do seu reino, então escreverá para si num livro, um traslado desta lei, do original que está diante dos sacerdotes levitas.',
        'E o terá consigo, e nele lerá todos os dias da sua vida, para que aprenda a temer ao Senhor seu Deus, para guardar todas as palavras desta lei, e estes estatutos, para cumpri-los;',
        'Para que o seu coração não se levante sobre os seus irmãos, e não se aparte do mandamento, nem para a direita nem para a esquerda; para que prolongue os seus dias no seu reino, ele e seus filhos no meio de Israel.',
      ],
      [
        'Os sacerdotes levitas, toda a tribo de Levi, não terão parte nem herança com Israel; das ofertas queimadas do SENHOR e da sua herança comerão.',
        'Por isso não terão herança no meio de seus irmãos; o Senhor é a sua herança, como lhes tem dito.',
        'Este, pois, será o direito dos sacerdotes, a receber do povo, dos que oferecerem sacrifício, seja boi ou gado miúdo; que darão ao sacerdote a espádua e as queixadas e o bucho.',
        'Dar-lhe-ás as primícias do teu grão, do teu mosto e do teu azeite, e as primícias da tosquia das tuas ovelhas.',
        'Porque o Senhor teu Deus o escolheu de todas as tuas tribos, para que assista e sirva no nome do Senhor, ele e seus filhos, todos os dias.',
        'E, quando chegar um levita de alguma das tuas portas, de todo o Israel, onde habitar; e vier com todo o desejo da sua alma ao lugar que o Senhor escolheu;',
        'E servir no nome do Senhor seu Deus, como também todos os seus irmãos, os levitas, que assistem ali perante o Senhor,',
        'Igual porção comerão, além das vendas do seu patrimônio.',
        'Quando entrares na terra que o Senhor teu Deus te der, não aprenderás a fazer conforme as abominações daquelas nações.',
        'Entre ti não se achará quem faça passar pelo fogo a seu filho ou a sua filha, nem adivinhador, nem prognosticador, nem agoureiro, nem feiticeiro;',
        'Nem encantador, nem quem consulte a um espírito adivinhador, nem mágico, nem quem consulte os mortos;',
        'Pois todo aquele que faz tal coisa é abominação ao Senhor; e por estas abominações o Senhor teu Deus os lança fora de diante de ti.',
        'Perfeito serás, como o Senhor teu Deus.',
        'Porque estas nações, que hás de possuir, ouvem os prognosticadores e os adivinhadores; porém a ti o Senhor teu Deus não permitiu tal coisa.',
        'O Senhor teu Deus te levantará um profeta do meio de ti, de teus irmãos, como eu; a ele ouvireis;',
        'Conforme a tudo o que pediste ao Senhor teu Deus em Horebe, no dia da assembléia, dizendo: Não ouvirei mais a voz do Senhor teu Deus, nem mais verei este grande fogo, para que não morra.',
        'Então o Senhor me disse: Falaram bem naquilo que disseram.',
        'Eis lhes suscitarei um profeta do meio de seus irmãos, como tu, e porei as minhas palavras na sua boca, e ele lhes falará tudo o que eu lhe ordenar.',
        'E será que qualquer que não ouvir as minhas palavras, que ele falar em meu nome, eu o requererei dele.',
        'Porém o profeta que tiver a presunção de falar alguma palavra em meu nome, que eu não lhe tenha mandado falar, ou o que falar em nome de outros deuses, esse profeta morrerá.',
        'E, se disseres no teu coração: Como conhecerei a palavra que o Senhor não falou?',
        'Quando o profeta falar em nome do Senhor, e essa palavra não se cumprir, nem suceder assim; esta é palavra que o Senhor não falou; com soberba a falou aquele profeta; não tenhas temor dele.',
      ],
      [
        'Quando o SENHOR teu Deus desarraigar as nações cuja terra te dará o SENHOR teu Deus, e tu as possuíres, e morares nas suas cidades e nas suas casas,',
        'Três cidades separarás, no meio da terra que te dará o Senhor teu Deus para a possuíres.',
        'Preparar-te-ás o caminho; e os termos da tua terra, que te fará possuir o Senhor teu Deus, dividirás em três; e isto será para que todo o homicida se acolha ali.',
        'E este é o caso tocante ao homicida, que se acolher ali, para que viva; aquele que por engano ferir o seu próximo, a quem não odiava antes;',
        'Como aquele que entrar com o seu próximo no bosque, para cortar lenha, e, pondo força na sua mão com o machado para cortar a árvore, o ferro saltar do cabo e ferir o seu próximo e este morrer, aquele se acolherá a uma destas cidades, e viverá;',
        'Para que o vingador do sangue não vá após o homicida, quando se enfurecer o seu coração, e o alcançar, por ser comprido o caminho, e lhe tire a vida; porque não é culpado de morte, pois o não odiava antes.',
        'Portanto te dou ordem, dizendo: Três cidades separarás.',
        'E, se o Senhor teu Deus dilatar os teus termos, como jurou a teus pais, e te der toda a terra que disse daria a teus pais',
        '(Quando guardares todos estes mandamentos, que hoje te ordeno, para cumprí-los, amando ao Senhor teu Deus e andando nos seus caminhos todos os dias), então acrescentarás outras três cidades além destas três.',
        'Para que o sangue inocente não se derrame no meio da tua terra, que o Senhor teu Deus te dá por herança, e haja sangue sobre ti.',
        'Mas, havendo alguém que odeia a seu próximo, e lhe arma ciladas, e se levanta contra ele, e o fere mortalmente, e se acolhe a alguma destas cidades,',
        'Então os anciãos da sua cidade mandarão buscá-lo; e dali o tirarão, e o entregarão na mão do vingador do sangue, para que morra.',
        'O teu olho não o perdoará; antes tirarás o sangue inocente de Israel, para que bem te suceda.',
        'Não mudes o limite do teu próximo, que estabeleceram os antigos na tua herança, que receberás na terra que te dá o Senhor teu Deus para a possuíres.',
        'Uma só testemunha contra alguém não se levantará por qualquer iniqüidade, ou por qualquer pecado, seja qual for o pecado que cometeu; pela boca de duas testemunhas, ou pela boca de três testemunhas, se estabelecerá o fato.',
        'Quando se levantar testemunha falsa contra alguém, para testificar contra ele acerca de transgressão,',
        'Então aqueles dois homens, que tiverem a demanda, se apresentarão perante o Senhor, diante dos sacerdotes e dos juízes que houver naqueles dias.',
        'E os juízes inquirirão bem; e eis que, sendo a testemunha falsa, que testificou falsamente contra seu irmão,',
        'Far-lhe-eis como cuidou fazer a seu irmão; e assim tirarás o mal do meio de ti.',
        'Para que os que ficarem o ouçam e temam, e nunca mais tornem a fazer tal mal no meio de ti.',
        'O teu olho não perdoará; vida por vida, olho por olho, dente por dente, mão por mão, pé por pé.',
      ],
      [
        'Quando saíres à peleja contra teus inimigos, e vires cavalos, e carros, e povo maior em número do que tu, deles não terás temor; pois o SENHOR teu Deus, que te tirou da terra do Egito, está contigo.',
        'E será que, quando vos achegardes à peleja, o sacerdote se adiantará, e falará ao povo,',
        'E dir-lhe-á: Ouvi, ó Israel, hoje vos achegais à peleja contra os vossos inimigos; não se amoleça o vosso coração: não temais nem tremais, nem vos aterrorizeis diante deles,',
        'Pois o Senhor vosso Deus é o que vai convosco, a pelejar contra os vossos inimigos, para salvar-vos.',
        'Então os oficiais falarão ao povo, dizendo: Qual é o homem que edificou casa nova e ainda não a consagrou? Vá, e torne-se à sua casa para que porventura não morra na peleja e algum outro a consagre.',
        'E qual é o homem que plantou uma vinha e ainda não a desfrutou? Vá, e torne-se à sua casa, para que porventura não morra na peleja e algum outro a desfrute.',
        'E qual é o homem que está desposado com alguma mulher e ainda não a recebeu? Vá, e torne-se à sua casa, para que porventura não morra na peleja e algum outro homem a receba.',
        'E continuarão os oficiais a falar ao povo, dizendo: Qual é o homem medroso e de coração tímido? Vá, e torne-se à sua casa, para que o coração de seus irmãos não se derreta como o seu coração.',
        'E será que, quando os oficiais acabarem de falar ao povo, então designarão os capitães dos exércitos para a dianteira do povo.',
        'Quando te achegares a alguma cidade para combatê-la, apregoar-lhe-ás a paz.',
        'E será que, se te responder em paz, e te abrir as portas, todo o povo que se achar nela te será tributário e te servirá.',
        'Porém, se ela não fizer paz contigo, mas antes te fizer guerra, então a sitiarás.',
        'E o Senhor teu Deus a dará na tua mão; e todo o homem que houver nela passarás ao fio da espada.',
        'Porém, as mulheres, e as crianças, e os animais; e tudo o que houver na cidade, todo o seu despojo, tomarás para ti; e comerás o despojo dos teus inimigos, que te deu o Senhor teu Deus.',
        'Assim farás a todas as cidades que estiverem mui longe de ti, que não forem das cidades destas nações.',
        'Porém, das cidades destas nações, que o Senhor teu Deus te dá em herança, nenhuma coisa que tem fôlego deixarás com vida.',
        'Antes destruí-las-ás totalmente: aos heteus, e aos amorreus, e aos cananeus, e aos perizeus, e aos heveus, e aos jebuseus, como te ordenou o Senhor teu Deus.',
        'Para que não vos ensinem a fazer conforme a todas as suas abominações, que fizeram a seus deuses, e pequeis contra o Senhor vosso Deus.',
        'Quando sitiares uma cidade por muitos dias, pelejando contra ela para a tomar, não destruirás o seu arvoredo, colocando nele o machado, porque dele comerás; pois que não o cortarás (pois o arvoredo do campo é mantimento para o homem), para empregar no cerco.',
        'Mas as árvores que souberes que não são árvores de alimento, destruí-las-ás e cortá-las-ás; e contra a cidade que guerrear contra ti edificarás baluartes, até que esta seja vencida.',
      ],
      [
        'Quando na terra que te der o SENHOR teu Deus, para possuí-la, se achar um morto, caído no campo, sem que se saiba quem o matou,',
        'Então sairão os teus anciãos e os teus juízes, e medirão a distância até as cidades que estiverem em redor do morto;',
        'E, na cidade mais próxima ao morto, os anciãos da mesma cidade tomarão uma novilha da manada, que não tenha trabalhado nem tenha puxado com o jugo;',
        'E os anciãos daquela cidade trarão a novilha a um vale áspero, que nunca foi lavrado nem semeado; e ali, naquele vale, degolarão a novilha;',
        'Então se achegarão os sacerdotes, filhos de Levi; pois o Senhor teu Deus os escolheu para o servirem, e para abençoarem em nome do Senhor; e pela sua palavra se decidirá toda a demanda e todo o ferimento;',
        'E todos os anciãos da mesma cidade, mais próxima ao morto, lavarão as suas mãos sobre a novilha degolada no vale;',
        'E protestarão, e dirão: As nossas mãos não derramaram este sangue, e os nossos olhos o não viram.',
        'Sê propício ao teu povo Israel, que tu, ó Senhor, resgataste, e não ponhas o sangue inocente no meio do teu povo Israel. E aquele sangue lhes será expiado.',
        'Assim tirarás o sangue inocente do meio de ti; pois farás o que é reto aos olhos do Senhor.',
        'Quando saíres à peleja contra os teus inimigos, e o Senhor teu Deus os entregar nas tuas mãos, e tu deles levares prisioneiros,',
        'E tu entre os presos vires uma mulher formosa à vista, e a cobiçares, e a tomares por mulher,',
        'Então a trarás para a tua casa; e ela rapará a cabeça e cortará as suas unhas.',
        'E despirá o vestido do seu cativeiro, e se assentará na tua casa, e chorará a seu pai e a sua mãe um mês inteiro; e depois chegarás a ela, e tu serás seu marido e ela tua mulher.',
        'E será que, se te não contentares dela, a deixarás ir à sua vontade; mas de modo algum a venderás por dinheiro, nem a tratarás como escrava, pois a tens humilhado.',
        'Quando um homem tiver duas mulheres, uma a quem ama e outra a quem despreza, e a amada e a desprezada lhe derem filhos, e o filho primogênito for da desprezada,',
        'Será que, no dia em que fizer herdar a seus filhos o que tiver, não poderá dar a primogenitura ao filho da amada, preferindo-o ao filho da desprezada, que é o primogênito.',
        'Mas ao filho da desprezada reconhecerá por primogênito, dando-lhe dobrada porção de tudo quanto tiver; porquanto aquele é o princípio da sua força, o direito da primogenitura é dele.',
        'Quando alguém tiver um filho contumaz e rebelde, que não obedecer à voz de seu pai e à voz de sua mãe, e, castigando-o eles, lhes não der ouvidos,',
        'Então seu pai e sua mãe pegarão nele, e o levarão aos anciãos da sua cidade, e à porta do seu lugar;',
        'E dirão aos anciãos da cidade: Este nosso filho é rebelde e contumaz, não dá ouvidos à nossa voz; é um comilão e um beberrão.',
        'Então todos os homens da sua cidade o apedrejarão, até que morra; e tirarás o mal do meio de ti, e todo o Israel ouvirá e temerá.',
        'Quando também em alguém houver pecado, digno do juízo de morte, e for morto, e o pendurares num madeiro,',
        'O seu cadáver não permanecerá no madeiro, mas certamente o enterrarás no mesmo dia; porquanto o pendurado é maldito de Deus; assim não contaminarás a tua terra, que o Senhor teu Deus te dá em herança.',
      ],
      [
        'Vendo extraviado o boi ou ovelha de teu irmão, não te desviarás deles; restituí-los-ás sem falta a teu irmão.',
        'E se teu irmão não estiver perto de ti, ou não o conheceres, recolhê-los-ás na tua casa, para que fiquem contigo, até que teu irmão os busque, e tu lhos restituirás.',
        'Assim também farás com o seu jumento, e assim farás com as suas roupas; assim farás também com toda a coisa perdida, que se perder de teu irmão, e tu a achares; não te poderás omitir.',
        'Se vires o jumento que é de teu irmão, ou o seu boi, caídos no caminho, não te desviarás deles; sem falta o ajudarás a levantá-los.',
        'Não haverá traje de homem na mulher, e nem vestirá o homem roupa de mulher; porque, qualquer que faz isto, abominação é ao Senhor teu Deus.',
        'Quando encontrares pelo caminho um ninho de ave numa árvore, ou no chão, com passarinhos, ou ovos, e a mãe posta sobre os passarinhos, ou sobre os ovos, não tomarás a mãe com os filhotes;',
        'Deixarás ir livremente a mãe, e os filhotes tomarás para ti; para que te vá bem e para que prolongues os teus dias.',
        'Quando edificares uma casa nova, farás um parapeito, no eirado, para que não ponhas culpa de sangue na tua casa, se alguém de algum modo cair dela.',
        'Não semearás a tua vinha com diferentes espécies de semente, para que não se degenere o fruto da semente que semeares, e a novidade da vinha.',
        'Com boi e com jumento não lavrarás juntamente.',
        'Não te vestirás de diversos estofos de lã e linho juntamente.',
        'Franjas porás nas quatro bordas da tua manta, com que te cobrires.',
        'Quando um homem tomar mulher e, depois de coabitar com ela, a desprezar,',
        'E lhe imputar coisas escandalosas, e contra ela divulgar má fama, dizendo: Tomei esta mulher, e me cheguei a ela, porém não a achei virgem;',
        'Então o pai da moça e sua mãe tomarão os sinais da virgindade da moça, e levá-los-ão aos anciãos da cidade, à porta;',
        'E o pai da moça dirá aos anciãos: Eu dei minha filha por mulher a este homem, porém ele a despreza;',
        'E eis que lhe imputou coisas escandalosas, dizendo: Não achei virgem a tua filha; porém eis aqui os sinais da virgindade de minha filha. E estenderão a roupa diante dos anciãos da cidade.',
        'Então os anciãos da mesma cidade tomarão aquele homem, e o castigarão.',
        'E o multarão em cem siclos de prata, e os darão ao pai da moça; porquanto divulgou má fama sobre uma virgem de Israel. E lhe será por mulher, em todos os seus dias não a poderá despedir.',
        'Porém se isto for verdadeiro, isto é, que a virgindade não se achou na moça,',
        'Então levarão a moça à porta da casa de seu pai, e os homens da sua cidade a apedrejarão, até que morra; pois fez loucura em Israel, prostituindo-se na casa de seu pai; assim tirarás o mal do meio de ti.',
        'Quando um homem for achado deitado com mulher que tenha marido, então ambos morrerão, o homem que se deitou com a mulher, e a mulher; assim tirarás o mal de Israel.',
        'Quando houver moça virgem, desposada, e um homem a achar na cidade, e se deitar com ela,',
        'Então trareis ambos à porta daquela cidade, e os apedrejareis, até que morram; a moça, porquanto não gritou na cidade, e o homem, porquanto humilhou a mulher do seu próximo; assim tirarás o mal do meio de ti.',
        'E se algum homem no campo achar uma moça desposada, e o homem a forçar, e se deitar com ela, então morrerá só o homem que se deitou com ela;',
        'Porém à moça não farás nada. A moça não tem culpa de morte; porque, como o homem que se levanta contra o seu próximo, e lhe tira a vida, assim é este caso.',
        'Pois a achou no campo; a moça desposada gritou, e não houve quem a livrasse.',
        'Quando um homem achar uma moça virgem, que não for desposada, e pegar nela, e se deitar com ela, e forem apanhados,',
        'Então o homem que se deitou com ela dará ao pai da moça cinqüenta siclos de prata; e porquanto a humilhou, lhe será por mulher; não a poderá despedir em todos os seus dias.',
        'Nenhum homem tomará a mulher de seu pai, nem descobrirá a nudez de seu pai.',
      ],
      [
        'Aquele a quem forem trilhados os testículos, ou cortado o membro viril, não entrará na congregação do SENHOR.',
        'Nenhum bastardo entrará na congregação do Senhor; nem ainda a sua décima geração entrará na congregação do Senhor.',
        'Nenhum amonita nem moabita entrará na congregação do Senhor; nem ainda a sua décima geração entrará na congregação do Senhor eternamente.',
        'Porquanto não saíram com pão e água, a receber-vos no caminho, quando saíeis do Egito; e porquanto alugaram contra ti a Balaão, filho de Beor, de Petor, de mesopotâmia, para te amaldiçoar.',
        'Porém o Senhor teu Deus não quis ouvir Balaão; antes o Senhor teu Deus trocou em bênção a maldição; porquanto o Senhor teu Deus te amava.',
        'Não lhes procurarás nem paz nem bem em todos os teus dias para sempre.',
        'Não abominarás o edomeu, pois é teu irmão; nem abominarás o egípcio, pois estrangeiro foste na sua terra.',
        'Os filhos que lhes nascerem na terceira geração, cada um deles entrará na congregação do Senhor.',
        'Quando o exército sair contra os teus inimigos, então te guardarás de toda a coisa má.',
        'Quando entre ti houver alguém que, por algum acidente noturno, não estiver limpo, sairá fora do arraial; não entrará no meio dele.',
        'Porém será que, declinando a tarde, se lavará em água; e, em se pondo o sol, entrará no meio do arraial.',
        'Também terás um lugar fora do arraial, para onde sairás.',
        'E entre as tuas armas terás uma pá; e será que, quando estiveres assentado, fora, então com ela cavarás e, virando-te, cobrirás o que defecaste.',
        'Porquanto o Senhor teu Deus anda no meio de teu arraial, para te livrar, e entregar a ti os teus inimigos; pelo que o teu arraial será santo, para que ele não veja coisa feia em ti, e se aparte de ti.',
        'Não entregarás a seu senhor o servo que, tendo fugido dele, se acolher a ti;',
        'Contigo ficará, no meio de ti, no lugar que escolher em alguma das tuas portas, onde lhe agradar; não o oprimirás.',
        'Não haverá prostituta dentre as filhas de Israel; nem haverá sodomita dentre os filhos de Israel.',
        'Não trarás o salário da prostituta nem preço de um sodomita à casa do Senhor teu Deus por qualquer voto; porque ambos são igualmente abominação ao Senhor teu Deus.',
        'A teu irmão não emprestarás com juros, nem dinheiro, nem comida, nem qualquer coisa que se empreste com juros.',
        'Ao estranho emprestarás com juros, porém a teu irmão não emprestarás com juros; para que o Senhor teu Deus te abençoe em tudo que puseres a tua mão, na terra a qual vais a possuir.',
        'Quando fizeres algum voto ao Senhor teu Deus, não tardarás em cumpri-lo; porque o Senhor teu Deus certamente o requererá de ti, e em ti haverá pecado.',
        'Porém, abstendo-te de votar, não haverá pecado em ti.',
        'O que saiu dos teus lábios guardarás, e cumprirás, tal como voluntariamente votaste ao Senhor teu Deus, declarando-o pela tua boca.',
        'Quando entrares na vinha do teu próximo, comerás uvas conforme ao teu desejo até te fartares, porém não as porás no teu cesto.',
        'Quando entrares na seara do teu próximo, com a tua mão arrancarás as espigas; porém não porás a foice na seara do teu próximo.',
      ],
      [
        'Quando um homem tomar uma mulher e se casar com ela, então será que, se não achar graça em seus olhos, por nela encontrar coisa indecente, far-lhe-á uma carta de repúdio, e lha dará na sua mão, e a despedirá da sua casa.',
        'Se ela, pois, saindo da sua casa, for e se casar com outro homem,',
        'E este também a desprezar, e lhe fizer carta de repúdio, e lha der na sua mão, e a despedir da sua casa, ou se este último homem, que a tomou para si por mulher, vier a morrer,',
        'Então seu primeiro marido, que a despediu, não poderá tornar a tomá-la, para que seja sua mulher, depois que foi contaminada; pois é abominação perante o Senhor; assim não farás pecar a terra que o Senhor teu Deus te dá por herança.',
        'Quando um homem for recém-casado não sairá à guerra, nem se lhe imporá encargo algum; por um ano inteiro ficará livre na sua casa para alegrar a mulher, que tomou.',
        'Não se tomará em penhor ambas as mós, nem a mó de cima nem a de baixo; pois se penhoraria assim a vida.',
        'Quando se achar alguém que tiver furtado um dentre os seus irmãos, dos filhos de Israel, e escravizá-lo, ou vendê-lo, esse ladrão morrerá, e tirarás o mal do meio de ti.',
        'Guarda-te da praga da lepra, e tenhas grande cuidado de fazer conforme a tudo o que te ensinarem os sacerdotes levitas; como lhes tenho ordenado, terás cuidado de o fazer.',
        'Lembra-te do que o Senhor teu Deus fez a Miriã no caminho, quando saíste do Egito.',
        'Quando emprestares alguma coisa ao teu próximo, não entrarás em sua casa, para lhe tirar o penhor.',
        'Fora ficarás; e o homem, a quem emprestaste, te trará fora o penhor.',
        'Porém, se for homem pobre, não te deitarás com o seu penhor.',
        'Em se pondo o sol, sem falta lhe restituirás o penhor; para que durma na sua roupa, e te abençoe; e isto te será justiça diante do Senhor teu Deus.',
        'Não oprimirás o diarista pobre e necessitado de teus irmãos, ou de teus estrangeiros, que está na tua terra e nas tuas portas.',
        'No seu dia lhe pagarás a sua diária, e o sol não se porá sobre isso; porquanto pobre é, e sua vida depende disso; para que não clame contra ti ao Senhor, e haja em ti pecado.',
        'Os pais não morrerão pelos filhos, nem os filhos pelos pais; cada um morrerá pelo seu pecado.',
        'Não perverterás o direito do estrangeiro e do órfão; nem tomarás em penhor a roupa da viúva.',
        'Mas lembrar-te-ás de que foste servo no Egito, e de que o Senhor teu Deus te livrou dali; pelo que te ordeno que faças isso.',
        'Quando no teu campo colheres a tua colheita, e esqueceres um molho no campo, não tornarás a tomá-lo; para o estrangeiro, para o órfão, e para a viúva será; para que o Senhor teu Deus te abençoe em toda a obra das tuas mãos,',
        'Quando sacudires a tua oliveira, não voltarás para colher o fruto dos ramos; para o estrangeiro, para o órfão, e para a viúva será.',
        'Quando vindimares a tua vinha, não voltarás para rebuscá-la; para o estrangeiro, para o órfão, e para a viúva será.',
        'E lembrar-te-ás de que foste servo na terra do Egito; portanto te ordeno que faças isso.',
      ],
      [
        'Quando houver contenda entre alguns, e vierem a juízo, para que os julguem, ao justo justificarão, e ao injusto condenarão.',
        'E será que, se o injusto merecer açoites, o juiz o fará deitar-se, para que seja açoitado diante de si; segundo a sua culpa, será o número de açoites.',
        'Quarenta açoites lhe fará dar, não mais; para que, porventura, se lhe fizer dar mais açoites do que estes, teu irmão não fique envilecido aos teus olhos.',
        'Não atarás a boca ao boi, quando trilhar.',
        'Quando irmãos morarem juntos, e um deles morrer, e não tiver filho, então a mulher do falecido não se casará com homem estranho, de fora; seu cunhado estará com ela, e a receberá por mulher, e fará a obrigação de cunhado para com ela.',
        'E o primogênito que ela lhe der será sucessor do nome do seu irmão falecido, para que o seu nome não se apague em Israel.',
        'Porém, se o homem não quiser tomar sua cunhada, esta subirá à porta dos anciãos, e dirá: Meu cunhado recusa suscitar a seu irmão nome em Israel; não quer cumprir para comigo o dever de cunhado.',
        'Então os anciãos da sua cidade o chamarão, e com ele falarão; e, se ele persistir, e disser: Não quero tomá-la;',
        'Então sua cunhada se chegará a ele na presença dos anciãos, e lhe descalçará o sapato do pé, e lhe cuspirá no rosto, e protestará, e dirá: Assim se fará ao homem que não edificar a casa de seu irmão;',
        'E o seu nome se chamará em Israel: A casa do descalçado.',
        'Quando pelejarem dois homens, um contra o outro, e a mulher de um chegar para livrar a seu marido da mão do que o fere, e ela estender a sua mão, e lhe pegar pelas suas vergonhas,',
        'Então cortar-lhe-ás a mão; não a poupará o teu olho.',
        'Na tua bolsa não terás pesos diversos, um grande e um pequeno.',
        'Na tua casa não terás dois tipos de efa, um grande e um pequeno.',
        'Peso inteiro e justo terás; efa inteiro e justo terás; para que se prolonguem os teus dias na terra que te dará o Senhor teu Deus.',
        'Porque abominação é ao Senhor teu Deus todo aquele que faz isto, todo aquele que fizer injustiça.',
        'Lembra-te do que te fez Amaleque no caminho, quando saías do Egito;',
        'Como te saiu ao encontro no caminho, e feriu na tua retaguarda todos os fracos que iam atrás de ti, estando tu cansado e afadigado; e não temeu a Deus.',
        'Será, pois, que, quando o Senhor teu Deus te tiver dado repouso de todos os teus inimigos em redor, na terra que o Senhor teu Deus te dá por herança, para possuí-la, então apagarás a memória de Amaleque de debaixo do céu; não te esqueças.',
      ],
      [
        'E será que, quando entrares na terra que o SENHOR teu Deus te der por herança, e a possuíres, e nela habitares,',
        'Então tomarás das primícias de todos os frutos do solo, que recolheres da terra, que te dá o Senhor teu Deus, e as porás num cesto, e irás ao lugar que escolher o Senhor teu Deus, para ali fazer habitar o seu nome.',
        'E irás ao sacerdote, que houver naqueles dias, e dir-lhe-ás: Hoje declaro perante o Senhor teu Deus que entrei na terra que o Senhor jurou a nossos pais dar-nos.',
        'E o sacerdote tomará o cesto da tua mão, e o porá diante do altar do Senhor teu Deus.',
        'Então testificarás perante o Senhor teu Deus, e dirás: Arameu, prestes a perecer, foi meu pai, e desceu ao Egito, e ali peregrinou com pouca gente, porém ali cresceu até vir a ser nação grande, poderosa, e numerosa.',
        'Mas os egípcios nos maltrataram e nos afligiram, e sobre nós impuseram uma dura servidão.',
        'Então clamamos ao Senhor Deus de nossos pais; e o Senhor ouviu a nossa voz, e atentou para a nossa miséria, e para o nosso trabalho, e para a nossa opressão.',
        'E o Senhor nos tirou do Egito com mão forte, e com braço estendido, e com grande espanto, e com sinais, e com milagres;',
        'E nos trouxe a este lugar, e nos deu esta terra, terra que mana leite e mel.',
        'E eis que agora eu trouxe as primícias dos frutos da terra que tu, ó Senhor, me deste. Então as porás perante o Senhor teu Deus, e te inclinarás perante o Senhor teu Deus,',
        'E te alegrarás por todo o bem que o Senhor teu Deus te tem dado a ti e à tua casa, tu e o levita, e o estrangeiro que está no meio de ti.',
        'Quando acabares de separar todos os dízimos da tua colheita no ano terceiro, que é o ano dos dízimos, então os darás ao levita, ao estrangeiro, ao órfão e à viúva, para que comam dentro das tuas portas, e se fartem;',
        'E dirás perante o Senhor teu Deus: Tirei da minha casa as coisas consagradas e as dei também ao levita, e ao estrangeiro, e ao órfão e à viúva, conforme a todos os teus mandamentos que me tens ordenado; não transgredi os teus mandamentos, nem deles me esqueci;',
        'Delas não comi no meu luto, nem delas nada tirei quando imundo, nem delas dei para os mortos; obedeci à voz do Senhor meu Deus; conforme a tudo o que me ordenaste, tenho feito.',
        'Olha desde a tua santa habitação, desde o céu, e abençoa o teu povo, a Israel, e a terra que nos deste, como juraste a nossos pais, terra que mana leite e mel.',
        'Neste dia, o Senhor teu Deus te manda cumprir estes estatutos e juízos; guarda-os pois, e cumpre-os com todo o teu coração e com toda a tua alma.',
        'Hoje declaraste ao Senhor que ele te será por Deus, e que andarás nos seus caminhos, e guardarás os seus estatutos, e os seus mandamentos, e os seus juízos, e darás ouvidos à sua voz.',
        'E o Senhor hoje te declarou que tu lhe serás por seu próprio povo, como te tem dito, e que guardarás todos os seus mandamentos.',
        'Para assim te exaltar sobre todas as nações que criou, para louvor, e para fama, e para glória, e para que sejas um povo santo ao Senhor teu Deus, como tem falado.',
      ],
      [
        'E deram ordem, Moisés e os anciãos, ao povo de Israel, dizendo: Guardai todos estes mandamentos que hoje vos ordeno;',
        'Será, pois, que, no dia em que passares o Jordão à terra que te der o Senhor teu Deus, levantar-te-ás umas pedras grandes, e as caiarás.',
        'E, havendo-o passado, escreverás nelas todas as palavras desta lei, para entrares na terra que te der o Senhor teu Deus, terra que mana leite e mel, como te falou o Senhor Deus de teus pais.',
        'Será, pois, que, quando houveres passado o Jordão, levantareis estas pedras, que hoje vos ordeno, no monte Ebal, e as caiarás.',
        'E ali edificarás um altar ao Senhor teu Deus, um altar de pedras; não alçarás instrumento de ferro sobre elas.',
        'De pedras brutas edificarás o altar do Senhor teu Deus; e sobre ele oferecerás holocaustos ao Senhor teu Deus.',
        'Também sacrificarás ofertas pacíficas, e ali comerás perante o Senhor teu Deus, e te alegrarás.',
        'E naquelas pedras escreverás todas as palavras desta lei, exprimindo-as nitidamente.',
        'Falou mais Moisés, juntamente com os sacerdotes levitas, a todo o Israel, dizendo: Guarda silêncio e ouve, ó Israel! Hoje vieste a ser povo do Senhor teu Deus.',
        'Portanto obedecerás à voz do Senhor teu Deus, e cumprirás os seus mandamentos e os seus estatutos que hoje te ordeno.',
        'E Moisés deu ordem naquele dia ao povo, dizendo:',
        'Quando houverdes passado o Jordão, estes estarão sobre o monte Gerizim, para abençoarem o povo: Simeão, e Levi, e Judá, e Issacar, e José, e Benjamim;',
        'E estes estarão sobre o monte Ebal para amaldiçoar: Rúben, Gade, e Aser, e Zebulom, Dã e Naftali.',
        'E os levitas testificarão a todo o povo de Israel em alta voz, e dirão:',
        'Maldito o homem que fizer imagem de escultura, ou de fundição, abominação ao Senhor, obra da mão do artífice, e a puser em um lugar escondido. E todo o povo, respondendo, dirá: Amém.',
        'Maldito aquele que desprezar a seu pai ou a sua mãe. E todo o povo dirá: Amém.',
        'Maldito aquele que remover os limites do seu próximo. E todo o povo dirá: Amém.',
        'Maldito aquele que fizer que o cego erre de caminho. E todo o povo dirá: Amém.',
        'Maldito aquele que perverter o direito do estrangeiro, do órfão e da viúva. E todo o povo dirá: Amém.',
        'Maldito aquele que se deitar com a mulher de seu pai, porquanto descobriu a nudez de seu pai. E todo o povo dirá: Amém.',
        'Maldito aquele que se deitar com algum animal. E todo o povo dirá: Amém.',
        'Maldito aquele que se deitar com sua irmã, filha de seu pai, ou filha de sua mãe. E todo o povo dirá: Amém.',
        'Maldito aquele que se deitar com sua sogra. E todo o povo dirá: Amém.',
        'Maldito aquele que ferir ao seu próximo em oculto. E todo o povo dirá: Amém.',
        'Maldito aquele que aceitar suborno para ferir uma pessoa inocente. E todo o povo dirá: Amém.',
        'Maldito aquele que não confirmar as palavras desta lei, não as cumprindo. E todo o povo dirá: Amém.',
      ],
      [
        'E será que, se ouvires a voz do SENHOR teu Deus, tendo cuidado de guardar todos os seus mandamentos que eu hoje te ordeno, o SENHOR teu Deus te exaltará sobre todas as nações da terra.',
        'E todas estas bênçãos virão sobre ti e te alcançarão, quando ouvires a voz do Senhor teu Deus:',
        'Bendito serás na cidade, e bendito serás no campo.',
        'Bendito o fruto do teu ventre, e o fruto da tua terra, e o fruto dos teus animais; e as crias das tuas vacas e das tuas ovelhas.',
        'Bendito o teu cesto e a tua amassadeira.',
        'Bendito serás ao entrares, e bendito serás ao saíres.',
        'O Senhor entregará, feridos diante de ti, os teus inimigos, que se levantarem contra ti; por um caminho sairão contra ti, mas por sete caminhos fugirão da tua presença.',
        'O Senhor mandará que a bênção esteja contigo nos teus celeiros, e em tudo o que puseres a tua mão; e te abençoará na terra que te der o Senhor teu Deus.',
        'O Senhor te confirmará para si como povo santo, como te tem jurado, quando guardares os mandamentos do Senhor teu Deus, e andares nos seus caminhos.',
        'E todos os povos da terra verão que é invocado sobre ti o nome do Senhor, e terão temor de ti.',
        'E o Senhor te dará abundância de bens no fruto do teu ventre, e no fruto dos teus animais, e no fruto do teu solo, sobre a terra que o Senhor jurou a teus pais te dar.',
        'O Senhor te abrirá o seu bom tesouro, o céu, para dar chuva à tua terra no seu tempo, e para abençoar toda a obra das tuas mãos; e emprestarás a muitas nações, porém tu não tomarás emprestado.',
        'E o Senhor te porá por cabeça, e não por cauda; e só estarás em cima, e não debaixo, se obedeceres aos mandamentos do Senhor teu Deus, que hoje te ordeno, para os guardar e cumprir.',
        'E não te desviarás de todas as palavras que hoje te ordeno, nem para a direita nem para a esquerda, andando após outros deuses, para os servires.',
        'Será, porém, que, se não deres ouvidos à voz do Senhor teu Deus, para não cuidares em cumprir todos os seus mandamentos e os seus estatutos, que hoje te ordeno, então virão sobre ti todas estas maldições, e te alcançarão:',
        'Maldito serás tu na cidade, e maldito serás no campo.',
        'Maldito o teu cesto e a tua amassadeira.',
        'Maldito o fruto do teu ventre, e o fruto da tua terra, e as crias das tuas vacas, e das tuas ovelhas.',
        'Maldito serás ao entrares, e maldito serás ao saíres.',
        'O Senhor mandará sobre ti a maldição; a confusão e a derrota em tudo em que puseres a mão para fazer; até que sejas destruído, e até que repentinamente pereças, por causa da maldade das tuas obras, pelas quais me deixaste.',
        'O Senhor fará pegar em ti a pestilência, até que te consuma da terra a que passas a possuir.',
        'O Senhor te ferirá com a tísica e com a febre, e com a inflamação, e com o calor ardente, e com a secura, e com crestamento e com ferrugem; e te perseguirão até que pereças.',
        'E os teus céus, que estão sobre a cabeça, serão de bronze; e a terra que está debaixo de ti, será de ferro.',
        'O Senhor dará por chuva sobre a tua terra, pó e poeira; dos céus descerá sobre ti, até que pereças.',
        'O Senhor te fará cair diante dos teus inimigos; por um caminho sairás contra eles, e por sete caminhos fugirás de diante deles, e serás espalhado por todos os reinos da terra.',
        'E o teu cadáver servirá de comida a todas as aves dos céus, e aos animais da terra; e ninguém os espantará.',
        'O Senhor te ferirá com as úlceras do Egito, com tumores, e com sarna, e com coceira, de que não possas curar-te;',
        'O Senhor te ferirá com loucura, e com cegueira, e com pasmo de coração;',
        'E apalparás ao meio-dia, como o cego apalpa na escuridão, e não prosperarás nos teus caminhos; porém somente serás oprimido e roubado todos os dias, e não haverá quem te salve.',
        'Desposar-te-ás com uma mulher, porém outro homem dormirá com ela; edificarás uma casa, porém não morarás nela; plantarás uma vinha, porém não aproveitarás o seu fruto.',
        'O teu boi será morto aos teus olhos, porém dele não comerás; o teu jumento será roubado diante de ti, e não voltará a ti; as tuas ovelhas serão dadas aos teus inimigos, e não haverá quem te salve.',
        'Teus filhos e tuas filhas serão dados a outro povo, os teus olhos o verão, e por eles desfalecerão todo o dia; porém não haverá poder na tua mão.',
        'O fruto da tua terra e todo o teu trabalho, comerá um povo que nunca conheceste; e tu serás oprimido e quebrantado todos os dias.',
        'E enlouquecerás com o que vires com os teus olhos.',
        'O Senhor te ferirá com úlceras malignas nos joelhos e nas pernas, de que não possas sarar, desde a planta do teu pé até ao alto da cabeça.',
        'O Senhor te levará a ti e a teu rei, que tiveres posto sobre ti, a uma nação que não conheceste, nem tu nem teus pais; e ali servirás a outros deuses, ao pau e à pedra.',
        'E serás por pasmo, por ditado, e por fábula, entre todos os povos a que o Senhor te levará.',
        'Lançarás muita semente ao campo; porém colherás pouco, porque o gafanhoto a consumirá.',
        'Plantarás vinhas, e cultivarás; porém não beberás vinho, nem colherás as uvas; porque o bicho as colherá.',
        'Em todos os termos terás oliveiras; porém não te ungirás com azeite; porque a azeitona cairá da tua oliveira.',
        'Filhos e filhas gerarás; porém não serão para ti; porque irão em cativeiro.',
        'Todo o teu arvoredo e o fruto da tua terra consumirá a lagarta.',
        'O estrangeiro, que está no meio de ti, se elevará muito sobre ti, e tu mais baixo descerás;',
        'Ele te emprestará a ti, porém tu não emprestarás a ele; ele será por cabeça, e tu serás por cauda.',
        'E todas estas maldições virão sobre ti, e te perseguirão, e te alcançarão, até que sejas destruído; porquanto não ouviste à voz do Senhor teu Deus, para guardares os seus mandamentos, e os seus estatutos, que te tem ordenado;',
        'E serão entre ti por sinal e por maravilha, como também entre a tua descendência para sempre.',
        'Porquanto não serviste ao Senhor teu Deus com alegria e bondade de coração, pela abundância de tudo.',
        'Assim servirás aos teus inimigos, que o Senhor enviará contra ti, com fome e com sede, e com nudez, e com falta de tudo; e sobre o teu pescoço porá um jugo de ferro, até que te tenha destruído.',
        'O Senhor levantará contra ti uma nação de longe, da extremidade da terra, que voa como a águia, nação cuja língua não entenderás;',
        'Nação feroz de rosto, que não respeitará o rosto do velho, nem se apiedará do moço;',
        'E comerá o fruto dos teus animais, e o fruto da tua terra, até que sejas destruído; e não te deixará grão, mosto, nem azeite, nem crias das tuas vacas, nem das tuas ovelhas, até que te haja consumido;',
        'E sitiar-te-á em todas as tuas portas, até que venham a cair os teus altos e fortes muros, em que confiavas em toda a tua terra; e te sitiará em todas as tuas portas, em toda a tua terra que te tem dado o Senhor teu Deus.',
        'E comerás o fruto do teu ventre, a carne de teus filhos e de tuas filhas, que te der o Senhor teu Deus, no cerco e no aperto com que os teus inimigos te apertarão.',
        'Quanto ao homem mais mimoso e delicado no meio de ti, o seu olho será maligno para com o seu irmão, e para com a mulher do seu regaço, e para com os demais de seus filhos que ainda lhe ficarem;',
        'De sorte que não dará a nenhum deles da carne de seus filhos, que ele comer; porquanto nada lhe ficou de resto no cerco e no aperto, com que o teu inimigo te apertará em todas as tuas portas.',
        'E quanto à mulher mais mimosa e delicada no meio de ti, que de mimo e delicadeza nunca tentou pôr a planta de seu pé sobre a terra, será maligno o seu olho contra o homem de seu regaço, e contra seu filho, e contra sua filha;',
        'E isto por causa de suas páreas, que saírem dentre os seus pés, e para com os seus filhos que tiver, porque os comerá às escondidas pela falta de tudo, no cerco e no aperto, com que o teu inimigo te apertará nas tuas portas.',
        'Se não tiveres cuidado de guardar todas as palavras desta lei, que estão escritas neste livro, para temeres este nome glorioso e temível, o SENHOR TEU DEUS,',
        'Então o Senhor fará espantosas as tuas pragas, e as pragas de tua descendência, grandes e permanentes pragas, e enfermidades malignas e duradouras;',
        'E fará tornar sobre ti todos os males do Egito, de que tu tiveste temor, e se apegarão a ti.',
        'Também o Senhor fará vir sobre ti toda a enfermidade e toda a praga, que não está escrita no livro desta lei, até que sejas destruído.',
        'E ficareis poucos em número, em lugar de haverem sido como as estrelas dos céus em multidão; porquanto não destes ouvidos à voz do Senhor teu Deus.',
        'E será que, assim como o Senhor se deleitava em vós, em fazer-vos bem e multiplicar-vos, assim o Senhor se deleitará em destruir-vos e consumir-vos; e desarraigados sereis da terra a qual passais a possuir.',
        'E o Senhor vos espalhará entre todos os povos, desde uma extremidade da terra até à outra; e ali servireis a outros deuses que não conheceste, nem tu nem teus pais; ao pau e à pedra.',
        'E nem ainda entre estas nações descansarás, nem a planta de teu pé terá repouso; porquanto o Senhor ali te dará coração agitado, e desfalecimento de olhos, e desmaio da alma.',
        'E a tua vida, como em suspenso, estará diante de ti; e estremecerás de noite e de dia, e não crerás na tua própria vida.',
        'Pela manhã dirás: Ah! quem me dera ver a noite! E à tarde dirás: ah! quem me dera ver a manhã! pelo pasmo de teu coração, que sentirás, e pelo que verás com os teus olhos.',
        'E o Senhor te fará voltar ao Egito em navios, pelo caminho de que te tenho dito; nunca jamais o verás; e ali sereis vendidos como escravos e escravas aos vossos inimigos; mas não haverá quem vos compre.',
      ],
      [
        'Estas são as palavras da aliança que o SENHOR ordenou a Moisés que fizesse com os filhos de Israel, na terra de Moabe, além da aliança que fizera com eles em Horebe.',
        'E chamou Moisés a todo o Israel, e disse-lhes: Tendes visto tudo quanto o Senhor fez perante vossos olhos, na terra do Egito, a Faraó, e a todos os seus servos, e a toda a sua terra;',
        'As grandes provas que os teus olhos têm visto, aqueles sinais e grandes maravilhas;',
        'Porém não vos tem dado o Senhor um coração para entender, nem olhos para ver, nem ouvidos para ouvir, até ao dia de hoje.',
        'E quarenta anos vos fiz andar pelo deserto; não se envelheceram sobre vós as vossas vestes, e nem se envelheceu o vosso sapato no vosso pé.',
        'Pão não comestes, e vinho e bebida forte não bebestes; para que soubésseis que eu sou o Senhor vosso Deus.',
        'Vindo vós, pois, a este lugar, Siom, rei de Hesbom, e Ogue, rei de Basã, nos saíram ao encontro, à peleja, e nós os ferimos;',
        'E tomamos a sua terra e a demos por herança aos rubenitas, e aos gaditas, e à meia tribo dos manassitas.',
        'Guardai, pois, as palavras desta aliança, e cumpri-as, para que prospereis em tudo quanto fizerdes.',
        'Vós todos estais hoje perante o Senhor vosso Deus; os capitães de vossas tribos, vossos anciãos, e os vossos oficiais, todos os homens de Israel;',
        'Os vossos meninos, as vossas mulheres, e o estrangeiro que está no meio do vosso arraial; desde o rachador da vossa lenha até ao tirador da vossa água;',
        'Para entrardes na aliança do Senhor teu Deus, e no seu juramento que o Senhor teu Deus hoje faz convosco;',
        'Para que hoje te confirme por seu povo, e ele te seja por Deus, como te tem dito, e como jurou a teus pais, Abraão, Isaque e Jacó.',
        'E não somente convosco faço esta aliança e este juramento;',
        'Mas com aquele que hoje está aqui em pé conosco perante o Senhor nosso Deus, e com aquele que hoje não está aqui conosco.',
        'Porque vós sabeis como habitamos na terra do Egito, e como passamos pelo meio das nações pelas quais passastes;',
        'E vistes as suas abominações, e os seus ídolos, o pau e a pedra, a prata e o ouro que havia entre eles,',
        'Para que entre vós não haja homem, nem mulher, nem família, nem tribo, cujo coração hoje se desvie do Senhor nosso Deus, para que vá servir aos deuses destas nações; para que entre vós não haja raiz que dê veneno e fel;',
        'E aconteça que, alguém ouvindo as palavras desta maldição, se abençoe no seu coração, dizendo: Terei paz, ainda que ande conforme o parecer do meu coração; para acrescentar à sede a bebedeira.',
        'O Senhor não lhe quererá perdoar; mas fumegará a ira do Senhor e o seu zelo contra esse homem, e toda a maldição escrita neste livro pousará sobre ele; e o Senhor apagará o seu nome de debaixo do céu.',
        'E o Senhor o separará para mal, de todas as tribos de Israel, conforme a todas as maldições da aliança escrita no livro desta lei.',
        'Então dirá à geração vindoura, os vossos filhos, que se levantarem depois de vós, e o estrangeiro que virá de terras remotas, vendo as pragas desta terra, e as suas doenças, com que o Senhor a terá afligido;',
        'E toda a sua terra abrasada com enxofre, e sal, de sorte que não será semeada, e nada produzirá, nem nela crescerá erva alguma; assim como foi a destruição de Sodoma e de Gomorra, de Admá e de Zeboim, que o Senhor destruiu na sua ira e no seu furor.',
        'E todas as nações dirão: Por que fez o Senhor assim com esta terra? Qual foi a causa do furor desta tão grande ira?',
        'Então se dirá: Porquanto deixaram a aliança do Senhor Deus de seus pais, que com eles tinha feito, quando os tirou do Egito;',
        'E foram, e serviram a outros deuses, e se inclinaram diante deles; deuses que eles não conheceram, e nenhum dos quais lhes tinha sido dado.',
        'Por isso a ira do Senhor se acendeu contra esta terra, para trazer sobre ela toda a maldição que está escrita neste livro.',
        'E o Senhor os arrancou da sua terra com ira, e com indignação, e com grande furor, e os lançou em outra terra como neste dia se vê.',
        'As coisas encobertas pertencem ao Senhor nosso Deus, porém as reveladas nos pertencem a nós e a nossos filhos para sempre, para que cumpramos todas as palavras desta lei.',
      ],
      [
        'E será que, sobrevindo-te todas estas coisas, a bênção ou a maldição, que tenho posto diante de ti, e te recordares delas entre todas as nações, para onde te lançar o SENHOR teu Deus,',
        'E te converteres ao Senhor teu Deus, e deres ouvidos à sua voz, conforme a tudo o que eu te ordeno hoje, tu e teus filhos, com todo o teu coração, e com toda a tua alma,',
        'Então o Senhor teu Deus te fará voltar do teu cativeiro, e se compadecerá de ti, e tornará a ajuntar-te dentre todas as nações entre as quais te espalhou o Senhor teu Deus.',
        'Ainda que os teus desterrados estejam na extremidade do céu, desde ali te ajuntará o Senhor teu Deus, e te tomará dali;',
        'E o Senhor teu Deus te trará à terra que teus pais possuíram, e a possuirás; e te fará bem, e te multiplicará mais do que a teus pais.',
        'E o Senhor teu Deus circuncidará o teu coração, e o coração de tua descendência, para amares ao Senhor teu Deus com todo o coração, e com toda a tua alma, para que vivas.',
        'E o Senhor teu Deus porá todas estas maldições sobre os teus inimigos, e sobre os que te odiarem, que te perseguirem.',
        'Converter-te-ás, pois, e darás ouvidos à voz do Senhor; cumprirás todos os seus mandamentos que hoje te ordeno.',
        'E o Senhor teu Deus te fará prosperar em toda a obra das tuas mãos, no fruto do teu ventre, e no fruto dos teus animais, e no fruto da tua terra para o teu bem; porquanto o Senhor tornará a alegrar-se em ti para te fazer bem, como se alegrou em teus pais,',
        'Quando deres ouvidos à voz do Senhor teu Deus, guardando os seus mandamentos e os seus estatutos, escritos neste livro da lei, quando te converteres ao Senhor teu Deus com todo o teu coração, e com toda a tua alma.',
        'Porque este mandamento, que hoje te ordeno, não te é encoberto, e tampouco está longe de ti.',
        'Não está nos céus, para dizeres: Quem subirá por nós aos céus, que no-lo traga, e no-lo faça ouvir, para que o cumpramos?',
        'Nem tampouco está além do mar, para dizeres: Quem passará por nós além do mar, para que no-lo traga, e no-lo faça ouvir, para que o cumpramos?',
        'Porque esta palavra está mui perto de ti, na tua boca, e no teu coração, para a cumprires.',
        'Vês aqui, hoje te tenho proposto a vida e o bem, e a morte e o mal;',
        'Porquanto te ordeno hoje que ames ao Senhor teu Deus, que andes nos seus caminhos, e que guardes os seus mandamentos, e os seus estatutos, e os seus juízos, para que vivas, e te multipliques, e o Senhor teu Deus te abençoe na terra a qual entras a possuir.',
        'Porém se o teu coração se desviar, e não quiseres dar ouvidos, e fores seduzido para te inclinares a outros deuses, e os servires,',
        'Então eu vos declaro hoje que, certamente, perecereis; não prolongareis os dias na terra a que vais, passando o Jordão, para que, entrando nela, a possuas;',
        'Os céus e a terra tomo hoje por testemunhas contra vós, de que te tenho proposto a vida e a morte, a bênção e a maldição; escolhe pois a vida, para que vivas, tu e a tua descendência,',
        'Amando ao Senhor teu Deus, dando ouvidos à sua voz, e achegando-te a ele; pois ele é a tua vida, e o prolongamento dos teus dias; para que fiques na terra que o Senhor jurou a teus pais, a Abraão, a Isaque, e a Jacó, que lhes havia de dar.',
      ],
      [
        'Depois foi Moisés, e falou estas palavras a todo o Israel,',
        'E disse-lhes: Da idade de cento e vinte anos sou eu hoje; já não poderei mais sair e entrar; além disto o Senhor me disse: Não passarás o Jordão.',
        'O Senhor teu Deus passará adiante de ti; ele destruirá estas nações de diante de ti, para que as possuas; Josué passará adiante de ti, como o Senhor tem falado.',
        'E o Senhor lhes fará como fez a Siom e a Ogue, reis dos amorreus, e à sua terra, os quais destruiu.',
        'Quando, pois, o Senhor vo-los der diante de vós, então com eles fareis conforme a todo o mandamento que vos tenho ordenado.',
        'Esforçai-vos, e animai-vos; não temais, nem vos espanteis diante deles; porque o Senhor teu Deus é o que vai contigo; não te deixará nem te desamparará.',
        'E chamou Moisés a Josué, e lhe disse aos olhos de todo o Israel: Esforça-te e anima-te; porque com este povo entrarás na terra que o Senhor jurou a teus pais lhes dar; e tu os farás herdá-la.',
        'O Senhor, pois, é aquele que vai adiante de ti; ele será contigo, não te deixará, nem te desamparará; não temas, nem te espantes.',
        'E Moisés escreveu esta lei, e a deu aos sacerdotes, filhos de Levi, que levavam a arca da aliança do Senhor, e a todos os anciãos de Israel.',
        'E ordenou-lhes Moisés, dizendo: Ao fim de cada sete anos, no tempo determinado do ano da remissão, na festa dos tabernáculos,',
        'Quando todo o Israel vier a comparecer perante o Senhor teu Deus, no lugar que ele escolher, lerás esta lei diante de todo o Israel aos seus ouvidos.',
        'Ajunta o povo, os homens e as mulheres, os meninos e os estrangeiros que estão dentro das tuas portas, para que ouçam e aprendam e temam ao Senhor vosso Deus, e tenham cuidado de fazer todas as palavras desta lei;',
        'E que seus filhos, que não a souberem, ouçam e aprendam a temer ao Senhor vosso Deus, todos os dias que viverdes sobre a terra a qual ides, passando o Jordão, para a possuir.',
        'E disse o Senhor a Moisés: Eis que os teus dias são chegados, para que morras; chama a Josué, e apresentai-vos na tenda da congregação, para que eu lhe dê ordens. Assim foram Moisés e Josué, e se apresentaram na tenda da congregação.',
        'Então o Senhor apareceu na tenda, na coluna de nuvem; e a coluna de nuvem estava sobre a porta da tenda.',
        'E disse o Senhor a Moisés: Eis que dormirás com teus pais; e este povo se levantará, e prostituir-se-á indo após os deuses estranhos na terra, para cujo meio vai, e me deixará, e anulará a minha aliança que tenho feito com ele.',
        'Assim se acenderá a minha ira naquele dia contra ele, e desampará-lo-ei, e esconderei o meu rosto dele, para que seja devorado; e tantos males e angústias o alcançarão, que dirá naquele dia: Não me alcançaram estes males, porque o meu Deus não está no meio de mim?',
        'Esconderei, pois, totalmente o meu rosto naquele dia, por todo o mal que tiver feito, por se haverem tornado a outros deuses.',
        'Agora, pois, escrevei-vos este cântico, e ensinai-o aos filhos de Israel; ponde-o na sua boca, para que este cântico me seja por testemunha contra os filhos de Israel.',
        'Porque introduzirei o meu povo na terra que jurei a seus pais, que mana leite e mel; e comerá, e se fartará, e se engordará; então se tornará a outros deuses, e os servirá, e me irritarão, e anularão a minha aliança.',
        'E será que, quando o alcançarem muitos males e angústias, então este cântico responderá contra ele por testemunha, pois não será esquecido da boca de sua descendência; porquanto conheço a sua imaginação, o que ele faz hoje, antes que o introduza na terra que tenho jurado.',
        'Assim Moisés escreveu este cântico naquele dia, e o ensinou aos filhos de Israel.',
        'E ordenou a Josué, filho de Num, e disse: Esforça-te e anima-te; porque tu introduzirás os filhos de Israel na terra que lhes jurei; e eu serei contigo.',
        'E aconteceu que, acabando Moisés de escrever num livro, todas as palavras desta lei,',
        'Deu ordem aos levitas, que levavam a arca da aliança do Senhor, dizendo:',
        'Tomai este livro da lei, e ponde-o ao lado da arca da aliança do Senhor vosso Deus, para que ali esteja por testemunha contra ti.',
        'Porque conheço a tua rebelião e a tua dura cerviz; eis que, vivendo eu ainda hoje convosco, rebeldes fostes contra o Senhor; e quanto mais depois da minha morte?',
        'Ajuntai perante mim todos os anciãos das vossas tribos, e vossos oficiais, e aos seus ouvidos falarei estas palavras, e contra eles por testemunhas tomarei o céu e a terra.',
        'Porque eu sei que depois da minha morte certamente vos corrompereis, e vos desviareis do caminho que vos ordenei; então este mal vos alcançará nos últimos dias, quando fizerdes mal aos olhos do Senhor, para o provocar à ira com a obra das vossas mãos.',
        'Então Moisés falou as palavras deste cântico aos ouvidos de toda a congregação de Israel, até se acabarem.',
      ],
      [
        'Inclinai os ouvidos, ó céus, e falarei; e ouça a terra as palavras da minha boca.',
        'Goteje a minha doutrina como a chuva, destile a minha palavra como o orvalho, como chuvisco sobre a erva e como gotas de água sobre a relva.',
        'Porque apregoarei o nome do Senhor; engrandecei a nosso Deus.',
        'Ele é a Rocha, cuja obra é perfeita, porque todos os seus caminhos justos são; Deus é a verdade, e não há nele injustiça; justo e reto é.',
        'Corromperam-se contra ele; não são seus filhos, mas a sua mancha; geração perversa e distorcida é.',
        'Recompensais assim ao Senhor, povo louco e ignorante? Não é ele teu pai que te adquiriu, te fez e te estabeleceu?',
        'Lembra-te dos dias da antiguidade, atenta para os anos de muitas gerações: pergunta a teu pai, e ele te informará; aos teus anciãos, e eles te dirão.',
        'Quando o Altíssimo distribuía as heranças às nações, quando dividia os filhos de Adão uns dos outros, estabeleceu os termos dos povos, conforme o número dos filhos de Israel.',
        'Porque a porção do Senhor é o seu povo; Jacó é a parte da sua herança.',
        'Achou-o numa terra deserta, e num ermo solitário cheio de uivos; cercou-o, instruiu-o, e guardou-o como a menina do seu olho.',
        'Como a águia desperta a sua ninhada, move-se sobre os seus filhos, estende as suas asas, toma-os, e os leva sobre as suas asas,',
        'Assim só o Senhor o guiou; e não havia com ele deus estranho.',
        'Ele o fez cavalgar sobre as alturas da terra, e comer os frutos do campo, e o fez chupar mel da rocha e azeite da dura pederneira.',
        'Manteiga de vacas, e leite de ovelhas, com a gordura dos cordeiros e dos carneiros que pastam em Basã, e dos bodes, com o mais escolhido trigo; e bebeste o sangue das uvas, o vinho puro.',
        'E, engordando-se Jesurum, deu coices (engordaste-te, engrossaste-te, e de gordura te cobriste) e deixou a Deus, que o fez, e desprezou a Rocha da sua salvação.',
        'Com deuses estranhos o provocaram a zelos; com abominações o irritaram.',
        'Sacrifícios ofereceram aos demônios, não a Deus; aos deuses que não conheceram, novos deuses que vieram há pouco, aos quais não temeram vossos pais.',
        'Esqueceste-te da Rocha que te gerou; e em esquecimento puseste o Deus que te formou;',
        'O que vendo o Senhor, os desprezou, por ter sido provocado à ira contra seus filhos e suas filhas;',
        'E disse: Esconderei o meu rosto deles, verei qual será o seu fim; porque são geração perversa, filhos em quem não há lealdade.',
        'A zelos me provocaram com aquilo que não é Deus; com as suas vaidades me provocaram à ira: portanto eu os provocarei a zelos com o que não é povo; com nação louca os despertarei à ira.',
        'Porque um fogo se acendeu na minha ira, e arderá até ao mais profundo do inferno, e consumirá a terra com a sua colheita, e abrasará os fundamentos dos montes.',
        'Males amontoarei sobre eles; as minhas setas esgotarei contra eles.',
        'Consumidos serão de fome, comidos pela febre ardente e de peste amarga; e contra eles enviarei dentes de feras, com ardente veneno de serpentes do pó.',
        'Por fora devastará a espada, e por dentro o pavor; ao jovem, juntamente com a virgem, assim à criança de peito como ao homem encanecido.',
        'Eu disse: Por todos os cantos os espalharei; farei cessar a sua memória dentre os homens,',
        'Se eu não receasse a ira do inimigo, para que os seus adversários não se iludam, e para que não digam: A nossa mão está exaltada; o Senhor não fez tudo isto.',
        'Porque são gente falta de conselhos, e neles não há entendimento.',
        'Quem dera eles fossem sábios! Que isto entendessem, e atentassem para o seu fim!',
        'Como poderia ser que um só perseguisse mil, e dois fizessem fugir dez mil, se a sua Rocha os não vendera, e o Senhor os não entregara?',
        'Porque a sua rocha não é como a nossa Rocha, sendo até os nossos inimigos juízes disto.',
        'Porque a sua vinha é a vinha de Sodoma e dos campos de Gomorra; as suas uvas são uvas venenosas, cachos amargos têm.',
        'O seu vinho é ardente veneno de serpentes, e peçonha cruel de víboras.',
        'Não está isto guardado comigo? Selado nos meus tesouros?',
        'Minha é a vingança e a recompensa, ao tempo que resvalar o seu pé; porque o dia da sua ruína está próximo, e as coisas que lhes hão de suceder, se apressam a chegar.',
        'Porque o Senhor fará justiça ao seu povo, e se compadecerá de seus servos; quando vir que o poder deles se foi, e não há preso nem desamparado.',
        'Então dirá: Onde estão os seus deuses? A rocha em quem confiavam,',
        'De cujos sacrifícios comiam a gordura, e de cujas libações bebiam o vinho? Levantem-se, e vos ajudem, para que haja para vós esconderijo.',
        'Vede agora que eu, eu o sou, e mais nenhum deus há além de mim; eu mato, e eu faço viver; eu firo, e eu saro, e ninguém há que escape da minha mão.',
        'Porque levantarei a minha mão aos céus, e direi: Eu vivo para sempre.',
        'Se eu afiar a minha espada reluzente, e se a minha mão travar o juízo, retribuirei a vingança sobre os meus adversários, e recompensarei aos que me odeiam.',
        'Embriagarei as minhas setas de sangue, e a minha espada comerá carne; do sangue dos mortos e dos prisioneiros, desde a cabeça, haverá vinganças do inimigo.',
        'Jubilai, ó nações, o seu povo, porque ele vingará o sangue dos seus servos, e sobre os seus adversários retribuirá a vingança, e terá misericórdia da sua terra e do seu povo.',
        'E veio Moisés, e falou todas as palavras deste cântico aos ouvidos do povo, ele e Josué, filho de Num.',
        'E, acabando Moisés de falar todas estas palavras a todo o Israel,',
        'Disse-lhes: Aplicai o vosso coração a todas as palavras que hoje testifico entre vós, para que as recomendeis a vossos filhos, para que tenham cuidado de cumprir todas as palavras desta lei.',
        'Porque esta palavra não vos é vã, antes é a vossa vida; e por esta mesma palavra prolongareis os dias na terra a qual, passando o Jordão, ides a possuir.',
        'Depois falou o Senhor a Moisés, naquele mesmo dia, dizendo:',
        'Sobe ao monte de Abarim, ao monte Nebo, que está na terra de Moabe, defronte de Jericó, e vê a terra de Canaã, que darei aos filhos de Israel por possessão.',
        'E morre no monte ao qual subirás; e recolhe-te ao teu povo, como Arão teu irmão morreu no monte Hor, e se recolheu ao seu povo.',
        'Porquanto transgredistes contra mim no meio dos filhos de Israel, às águas de Meribá de Cades, no deserto de Zim; pois não me santificastes no meio dos filhos de Israel.',
        'Pelo que verás a terra diante de ti, porém não entrarás nela, na terra que darei aos filhos de Israel.',
      ],
      [
        'Esta, porém, é a bênção com que Moisés, homem de Deus, abençoou os filhos de Israel antes da sua morte.',
        'Disse pois: O Senhor veio de Sinai, e lhes subiu de Seir; resplandeceu desde o monte Parã, e veio com dez milhares de santos; à sua direita havia para eles o fogo da lei.',
        'Na verdade ama os povos; todos os seus santos estão na sua mão; postos serão no meio, entre os teus pés, e cada um receberá das tuas palavras.',
        'Moisés nos deu a lei, como herança da congregação de Jacó.',
        'E foi rei em Jesurum, quando se congregaram os cabeças do povo com as tribos de Israel.',
        'Viva Rúben, e não morra, e que os seus homens não sejam poucos.',
        'E isto é o que disse de Judá: Ouve, ó Senhor, a voz de Judá, e introduze-o no seu povo; as suas mãos lhe bastem, e tu lhe sejas em ajuda contra os seus inimigos.',
        'E de Levi disse: Teu Tumim e teu Urim são para o teu amado, que tu provaste em Massá, com quem contendeste junto às águas de Meribá.',
        'Aquele que disse a seu pai, e à sua mãe: Nunca os vi; e não conheceu a seus irmãos, e não estimou a seus filhos; pois guardaram a tua palavra e observaram a tua aliança.',
        'Ensinaram os teus juízos a Jacó, e a tua lei a Israel; puseram incenso no teu nariz, e o holocausto sobre o teu altar.',
        'Abençoa o seu poder, ó Senhor, e aceita a obra das suas mãos; fere os lombos dos que se levantam contra ele e o odeiam, para que nunca mais se levantem.',
        'E de Benjamim disse: O amado do Senhor habitará seguro com ele; todo o dia o cobrirá, e morará entre os seus ombros.',
        'E de José disse: Bendita do Senhor seja a sua terra, com o mais excelente dos céus, com o orvalho e com o abismo que jaz abaixo.',
        'E com os mais excelentes frutos do sol, e com as mais excelentes produções das luas,',
        'E com o mais excelente dos montes antigos, e com o mais excelente dos outeiros eternos.',
        'E com o mais excelente da terra, e da sua plenitude, e com a benevolência daquele que habitava na sarça, venha sobre a cabeça de José, e sobre o alto da cabeça daquele que foi separado de seus irmãos.',
        'Ele tem a glória do primogênito do seu touro, e os seus chifres são chifres de boi selvagem; com eles rechaçará todos os povos até às extremidades da terra; estes pois são os dez milhares de Efraim, e estes são os milhares de Manassés.',
        'E de Zebulom disse: Zebulom, alegra-te nas tuas saídas; e tu, Issacar, nas tuas tendas.',
        'Eles chamarão os povos ao monte; ali apresentarão ofertas de justiça, porque chuparão a abundância dos mares e os tesouros escondidos da areia.',
        'E de Gade disse: Bendito aquele que faz dilatar a Gade; habita como a leoa, e despedaça o braço e o alto da cabeça.',
        'E se proveu da melhor parte, porquanto ali estava escondida a porção do legislador; por isso veio com os chefes do povo, executou a justiça do Senhor e os seus juízos para com Israel.',
        'E de Dã disse: Dã é cria de leão; que salta de Basã.',
        'E de Naftali disse: Farta-te, ó Naftali, da benevolência, e enche-te da bênção do Senhor; possui o ocidente e o sul.',
        'E de Aser disse: Bendito seja Aser com seus filhos; agrade a seus irmãos, e banhe em azeite o seu pé.',
        'Seja de ferro e de metal o teu calçado; e a tua força seja como os teus dias.',
        'Não há outro, ó Jesurum, semelhante a Deus, que cavalga sobre os céus para a tua ajuda, e com a sua majestade sobre as mais altas nuvens.',
        'O Deus eterno é a tua habitação, e por baixo estão os braços eternos; e ele lançará o inimigo de diante de ti, e dirá: Destrói-o.',
        'Israel, pois, habitará só, seguro, na terra da fonte de Jacó, na terra de grão e de mosto; e os seus céus gotejarão orvalho.',
        'Bem-aventurado tu, ó Israel! Quem é como tu? Um povo salvo pelo Senhor, o escudo do teu socorro, e a espada da tua majestade; por isso os teus inimigos te serão sujeitos, e tu pisarás sobre as suas alturas.',
      ],
      [
        'Então subiu Moisés das campinas de Moabe ao monte Nebo, ao cume de Pisga, que está em frente a Jericó e o SENHOR mostrou-lhe toda a terra desde Gileade até Dã;',
        'E todo Naftali, e a terra de Efraim, e Manassés e toda a terra de Judá, até ao mar ocidental;',
        'E o sul, e a campina do vale de Jericó, a cidade das palmeiras, até Zoar.',
        'E disse-lhe o Senhor: Esta é a terra que jurei a Abraão, Isaque, e Jacó, dizendo: À tua descendência a darei; eu te faço vê-la com os teus olhos, porém lá não passarás.',
        'Assim morreu ali Moisés, servo do Senhor, na terra de Moabe, conforme a palavra do Senhor.',
        'E o sepultou num vale, na terra de Moabe, em frente de Bete-Peor; e ninguém soube até hoje o lugar da sua sepultura.',
        'Era Moisés da idade de cento e vinte anos quando morreu; os seus olhos nunca se escureceram, nem perdeu o seu vigor.',
        'E os filhos de Israel prantearam a Moisés trinta dias, nas campinas de Moabe; e os dias do pranto no luto de Moisés se cumpriram.',
        'E Josué, filho de Num, foi cheio do espírito de sabedoria, porquanto Moisés tinha posto sobre ele as suas mãos; assim os filhos de Israel lhe deram ouvidos, e fizeram como o Senhor ordenara a Moisés.',
        'E nunca mais se levantou em Israel profeta algum como Moisés, a quem o Senhor conhecera face a face;',
        'Nem semelhante em todos os sinais e maravilhas, que o Senhor o enviou para fazer na terra do Egito, a Faraó, e a todos os seus servos, e a toda a sua terra.',
        'E em toda a mão forte, e em todo o grande espanto, que praticou Moisés aos olhos de todo o Israel.',
      ],
    ],
    livro: 'deuteronomio',
  },
  {
    abbrev: 'js',
    capitulos: [
      [
        'E sucedeu depois da morte de Moisés, servo do SENHOR, que o SENHOR falou a Josué, filho de Num, servo de Moisés, dizendo:',
        'Moisés, meu servo, é morto; levanta-te, pois, agora, passa este Jordão, tu e todo este povo, à terra que eu dou aos filhos de Israel.',
        'Todo o lugar que pisar a planta do vosso pé, vo-lo tenho dado, como eu disse a Moisés.',
        'Desde o deserto e do Líbano, até ao grande rio, o rio Eufrates, toda a terra dos heteus, e até o grande mar para o poente do sol, será o vosso termo.',
        'Ninguém te poderá resistir, todos os dias da tua vida; como fui com Moisés, assim serei contigo; não te deixarei nem te desampararei.',
        'Esforça-te, e tem bom ânimo; porque tu farás a este povo herdar a terra que jurei a seus pais lhes daria.',
        'Tão-somente esforça-te e tem mui bom ânimo, para teres o cuidado de fazer conforme a toda a lei que meu servo Moisés te ordenou; dela não te desvies, nem para a direita nem para a esquerda, para que prudentemente te conduzas por onde quer que andares.',
        'Não se aparte da tua boca o livro desta lei; antes medita nele dia e noite, para que tenhas cuidado de fazer conforme a tudo quanto nele está escrito; porque então farás prosperar o teu caminho, e serás bem sucedido.',
        'Não to mandei eu? Esforça-te, e tem bom ânimo; não temas, nem te espantes; porque o Senhor teu Deus é contigo, por onde quer que andares.',
        'Então Josué deu ordem aos príncipes do povo, dizendo:',
        'Passai pelo meio do arraial e ordenai ao povo, dizendo: Provede-vos de comida, porque dentro de três dias passareis este Jordão, para que entreis a possuir a terra que vos dá o Senhor vosso Deus, para a possuirdes.',
        'E falou Josué aos rubenitas, e aos gaditas, e à meia tribo de Manassés, dizendo:',
        'Lembrai-vos da palavra que vos mandou Moisés, o servo do Senhor, dizendo: O Senhor vosso Deus vos dá descanso, e vos dá esta terra.',
        'Vossas mulheres, vossos meninos e vosso gado fiquem na terra que Moisés vos deu deste lado do Jordão; porém vós passareis armados na frente de vossos irmãos, todos os valentes e valorosos, e ajudá-los-eis.',
        'Até que o Senhor dê descanso a vossos irmãos, como a vós, e eles também possuam a terra que o Senhor vosso Deus lhes dá; então tornareis à terra da vossa herança, e possuireis a que vos deu Moisés, o servo do Senhor, deste lado do Jordão, para o nascente do sol.',
        'Então responderam a Josué, dizendo: Tudo quanto nos ordenaste faremos, e onde quer que nos enviares iremos.',
        'Como em tudo ouvimos a Moisés, assim te ouviremos a ti, tão-somente que o Senhor teu Deus seja contigo, como foi com Moisés.',
        'Todo o homem, que for rebelde às tuas ordens, e não ouvir as tuas palavras em tudo quanto lhe mandares, morrerá. Tão-somente esforça-te, e tem bom ânimo.',
      ],
      [
        'E Josué, filho de Num, enviou secretamente, de Sitim, dois homens a espiar, dizendo: Ide reconhecer a terra e a Jericó. Foram, pois, e entraram na casa de uma mulher prostituta, cujo nome era Raabe, e dormiram ali.',
        'Então deu-se notícia ao rei de Jericó, dizendo: Eis que esta noite vieram aqui uns homens dos filhos de Israel, para espiar a terra.',
        'Por isso mandou o rei de Jericó dizer a Raabe: Tira fora os homens que vieram a ti e entraram na tua casa, porque vieram espiar toda a terra.',
        'Porém aquela mulher tomou os dois homens, e os escondeu, e disse: É verdade que vieram homens a mim, porém eu não sabia de onde eram.',
        'E aconteceu que, havendo-se de fechar a porta, sendo já escuro, aqueles homens saíram; não sei para onde aqueles homens se foram; ide após eles depressa, porque os alcançareis.',
        'Porém ela os tinha feito subir ao eirado, e os tinha escondido entre as canas do linho, que pusera em ordem sobre o eirado.',
        'E foram-se aqueles homens após eles pelo caminho do Jordão, até aos vaus; e, havendo eles saído, fechou-se a porta.',
        'E, antes que eles dormissem, ela subiu a eles no eirado;',
        'E disse aos homens: Bem sei que o Senhor vos deu esta terra e que o pavor de vós caiu sobre nós, e que todos os moradores da terra estão desfalecidos diante de vós.',
        'Porque temos ouvido que o Senhor secou as águas do Mar Vermelho diante de vós, quando saíeis do Egito, e o que fizestes aos dois reis dos amorreus, a Siom e a Ogue, que estavam além do Jordão, os quais destruístes.',
        'O que ouvindo, desfaleceu o nosso coração, e em ninguém mais há ânimo algum, por causa da vossa presença; porque o Senhor vosso Deus é Deus em cima nos céus e em baixo na terra.',
        'Agora, pois, jurai-me, vos peço, pelo Senhor, que, como usei de misericórdia convosco, vós também usareis de misericórdia para com a casa de meu pai, e dai-me um sinal seguro,',
        'De que conservareis com a vida a meu pai e a minha mãe, como também a meus irmãos e a minhas irmãs, com tudo o que têm e de que livrareis as nossas vidas da morte.',
        'Então aqueles homens responderam-lhe: A nossa vida responderá pela vossa até à morte, se não denunciardes este nosso negócio, e será, pois, que, dando-nos o Senhor esta terra, usaremos contigo de misericórdia e de fidelidade.',
        'Ela então os fez descer por uma corda pela janela, porquanto a sua casa estava sobre o muro da cidade, e ela morava sobre o muro.',
        'E disse-lhes: Ide-vos ao monte, para que, porventura, não vos encontrem os perseguidores, e escondei-vos lá três dias, até que voltem os perseguidores, e depois ide pelo vosso caminho.',
        'E, disseram-lhe aqueles homens: Desobrigados seremos deste juramento que nos fizeste jurar.',
        'Eis que, quando nós entrarmos na terra, atarás este cordão de fio de escarlata à janela por onde nos fizeste descer; e recolherás em casa contigo a teu pai, e a tua mãe, e a teus irmãos e a toda a família de teu pai.',
        'Será, pois, que qualquer que sair fora da porta da tua casa o seu sangue será sobre a sua cabeça, e nós seremos inocentes; mas qualquer que estiver contigo, em casa, o seu sangue seja sobre a nossa cabeça, se alguém nele puser mão.',
        'Porém, se tu denunciares este nosso negócio, seremos desobrigados do juramento que nos fizeste jurar.',
        'E ela disse: Conforme as vossas palavras, assim seja. Então os despediu; e eles se foram; e ela atou o cordão de escarlata à janela.',
        'Foram-se, pois, e chegaram ao monte, e ficaram ali três dias, até que voltaram os perseguidores, porque os perseguidores os buscaram por todo o caminho, porém não os acharam.',
        'Assim aqueles dois homens voltaram, e desceram do monte, e passaram, e chegaram a Josué, filho de Num, e contaram-lhe tudo quanto lhes acontecera;',
        'E disseram a Josué: Certamente o Senhor tem dado toda esta terra nas nossas mãos, pois até todos os moradores estão atemorizados diante de nós.',
      ],
      [
        'Levantou-se, pois, Josué de madrugada, e partiram de Sitim, ele e todos os filhos de Israel; e vieram até ao Jordão, e pousaram ali, antes que passassem.',
        'E sucedeu, ao fim de três dias, que os oficiais passaram pelo meio do arraial;',
        'E ordenaram ao povo, dizendo: Quando virdes a arca da aliança do Senhor vosso Deus, e que os sacerdotes levitas a levam, partireis vós também do vosso lugar, e a seguireis.',
        'Haja contudo, entre vós e ela, uma distância de dois mil côvados; e não vos chegueis a ela, para que saibais o caminho pelo qual haveis de ir; porquanto por este caminho nunca passastes antes.',
        'Disse Josué também ao povo: Santificai-vos, porque amanhã fará o Senhor maravilhas no meio de vós.',
        'E falou Josué aos sacerdotes, dizendo: Levantai a arca da aliança, e passai adiante deste povo. Levantaram, pois, a arca da aliança, e foram andando adiante do povo.',
        'E o Senhor disse a Josué: Hoje começarei a engrandecer-te perante os olhos de todo o Israel, para que saibam que, assim como fui com Moisés, assim serei contigo.',
        'Tu, pois, ordenarás aos sacerdotes que levam a arca da aliança, dizendo: Quando chegardes à beira das águas do Jordão, parareis aí.',
        'Então disse Josué aos filhos de Israel: Chegai-vos para cá, e ouvi as palavras do Senhor vosso Deus.',
        'Disse mais Josué: Nisto conhecereis que o Deus vivo está no meio de vós; e que certamente lançará de diante de vós aos cananeus, e aos heteus, e aos heveus, e aos perizeus, e aos girgaseus, e aos amorreus, e aos jebuseus.',
        'Eis que a arca da aliança do Senhor de toda a terra passa o Jordão diante de vós.',
        'Tomai, pois, agora doze homens das tribos de Israel, de cada tribo um homem;',
        'Porque há de acontecer que, assim que as plantas dos pés dos sacerdotes, que levam a arca do Senhor, o Senhor de toda a terra, repousem nas águas do Jordão, se separarão as águas do Jordão, e as águas, que vêm de cima, pararão amontoadas.',
        'E aconteceu que, partindo o povo das suas tendas, para passar o Jordão, levavam os sacerdotes a arca da aliança adiante do povo.',
        'E quando os que levavam a arca, chegaram ao Jordão, e os seus pés se molharam na beira das águas (porque o Jordão transbordava sobre todas as suas ribanceiras, todos os dias da ceifa),',
        'Pararam-se as águas, que vinham de cima; levantaram-se num montão, mui longe da cidade de Adão, que está ao lado de Zaretã; e as que desciam ao mar das campinas, que é o Mar Salgado, foram de todo separadas; então passou o povo em frente de Jericó.',
        'Porém os sacerdotes, que levavam a arca da aliança do Senhor, pararam firmes, em seco, no meio do Jordão, e todo o Israel passou a seco, até que todo o povo acabou de passar o Jordão.',
      ],
      [
        'Sucedeu que, acabando todo o povo de passar o Jordão, falou o SENHOR a Josué, dizendo:',
        'Tomai do povo doze homens, de cada tribo um homem;',
        'E mandai-lhes, dizendo: Tirai daqui, do meio do Jordão, do lugar onde estavam firmes os pés dos sacerdotes, doze pedras; e levai-as convosco à outra margem e depositai-as no alojamento em que haveis de passar esta noite.',
        'Chamou, pois, Josué os doze homens, que escolhera dos filhos de Israel; de cada tribo um homem;',
        'E disse-lhes Josué: Passai adiante da arca do Senhor vosso Deus, ao meio do Jordão; e cada um levante uma pedra sobre o ombro, segundo o número das tribos dos filhos de Israel;',
        'Para que isto seja por sinal entre vós; e quando vossos filhos no futuro perguntarem, dizendo: Que significam estas pedras?',
        'Então lhes direis que as águas do Jordão se separaram diante da arca da aliança do Senhor; passando ela pelo Jordão, separaram-se as águas do Jordão; assim estas pedras serão para sempre por memorial aos filhos de Israel.',
        'Fizeram, pois, os filhos de Israel assim como Josué tinha ordenado, e levantaram doze pedras do meio do Jordão como o Senhor dissera a Josué, segundo o número das tribos dos filhos de Israel; e levaram-nas consigo ao alojamento, e as depositaram ali.',
        'Levantou Josué também doze pedras no meio do Jordão, no lugar onde estiveram parados os pés dos sacerdotes, que levavam a arca da aliança; e ali estão até ao dia de hoje.',
        'Pararam, pois, os sacerdotes, que levavam a arca, no meio do Jordão, em pé, até que se cumpriu tudo quanto o Senhor mandara Josué dizer ao povo, conforme a tudo quanto Moisés tinha ordenado a Josué; e apressou-se o povo, e passou.',
        'E sucedeu que, assim que todo o povo acabou de passar, então passou a arca do Senhor, e os sacerdotes, à vista do povo.',
        'E passaram os filhos de Rúben, e os filhos de Gade, e a meia tribo de Manassés, armados na frente dos filhos de Israel, como Moisés lhes tinha falado;',
        'Uns quarenta mil homens de guerra, armados, passaram diante do Senhor para batalha, às campinas de Jericó.',
        'Naquele dia o Senhor engrandeceu a Josué diante dos olhos de todo o Israel; e temeram-no, como haviam temido a Moisés, todos os dias da sua vida.',
        'Falou, pois, o Senhor a Josué, dizendo:',
        'Dá ordem aos sacerdotes, que levam a arca do testemunho, que subam do Jordão.',
        'E deu Josué ordem aos sacerdotes, dizendo: Subi do Jordão.',
        'E aconteceu que, como os sacerdotes, que levavam a arca da aliança do Senhor, subiram do meio do Jordão, e as plantas dos pés dos sacerdotes se puseram em seco, as águas do Jordão se tornaram ao seu lugar, e corriam, como antes, sobre todas as suas ribanceiras.',
        'Subiu, pois, o povo, do Jordão no dia dez do mês primeiro; e alojaram-se em Gilgal, do lado oriental de Jericó.',
        'E as doze pedras, que tinham tomado do Jordão, levantou-as Josué em Gilgal.',
        'E falou aos filhos de Israel, dizendo: Quando no futuro vossos filhos perguntarem a seus pais, dizendo: Que significam estas pedras?',
        'Fareis saber a vossos filhos, dizendo: Israel passou em seco este Jordão.',
        'Porque o Senhor vosso Deus fez secar as águas do Jordão diante de vós, até que passásseis, como o Senhor vosso Deus fez ao Mar Vermelho que fez secar perante nós, até que passássemos.',
        'Para que todos os povos da terra conheçam a mão do Senhor, que é forte, para que temais ao Senhor vosso Deus todos os dias.',
      ],
      [
        'E sucedeu que, ouvindo todos os reis dos amorreus, que habitavam deste lado do Jordão, ao ocidente, e todos os reis dos cananeus, que estavam ao pé do mar, que o SENHOR tinha secado as águas do Jordão, de diante dos filhos de Israel, até que passassem, desfaleceu-se-lhes o coração, e não houve mais ânimo neles, por causa dos filhos de Israel.',
        'Naquele tempo disse o Senhor a Josué: Faze facas de pedra, e torna a circuncidar segunda vez aos filhos de Israel.',
        'Então Josué fez para si facas de pedra, e circuncidou aos filhos de Israel no monte dos prepúcios.',
        'E foi esta a causa por que Josué os circuncidou: todo o povo que tinha saído do Egito, os homens, todos os homens de guerra, já haviam morrido no deserto, pelo caminho, depois que saíram do Egito.',
        'Porque todos os do povo que saíram estavam circuncidados, mas a nenhum dos que nasceram no deserto, pelo caminho, depois de terem saído do Egito, haviam circuncidado.',
        'Porque quarenta anos andaram os filhos de Israel pelo deserto, até se acabar toda a nação, os homens de guerra, que saíram do Egito, e não obedeceram à voz do Senhor; aos quais o Senhor tinha jurado que lhes não havia de deixar ver a terra que o Senhor jurara a seus pais dar-nos; terra que mana leite e mel.',
        'Porém em seu lugar pôs a seus filhos; a estes Josué circuncidou, porquanto estavam incircuncisos, porque os não circuncidaram no caminho.',
        'E aconteceu que, acabando de circuncidar a toda a nação, ficaram no seu lugar no arraial, até que sararam.',
        'Disse mais o Senhor a Josué: Hoje retirei de sobre vós o opróbrio do Egito; por isso o nome daquele lugar se chamou Gilgal, até ao dia de hoje.',
        'Estando, pois, os filhos de Israel acampados em Gilgal, celebraram a páscoa no dia catorze do mês, à tarde, nas campinas de Jericó.',
        'E, ao outro dia depois da páscoa, nesse mesmo dia, comeram, do fruto da terra, pães ázimos e espigas tostadas.',
        'E cessou o maná no dia seguinte, depois que comeram do fruto da terra, e os filhos de Israel não tiveram mais maná; porém, no mesmo ano comeram dos frutos da terra de Canaã.',
        'E sucedeu que, estando Josué perto de Jericó, levantou os seus olhos e olhou; e eis que se pôs em pé diante dele um homem que tinha na mão uma espada nua; e chegou-se Josué a ele, e disse-lhe: És tu dos nossos, ou dos nossos inimigos?',
        'E disse ele: Não, mas venho agora como príncipe do exército do SENHOR. Então Josué se prostrou com o seu rosto em terra e o adorou, e disse-lhe: Que diz meu senhor ao seu servo?',
        'Então disse o príncipe do exército do Senhor a Josué: Descalça os sapatos de teus pés, porque o lugar em que estás é santo. E fez Josué assim.',
      ],
      [
        'Ora Jericó estava rigorosamente fechada por causa dos filhos de Israel; ninguém saía nem entrava.',
        'Então disse o Senhor a Josué: Olha, tenho dado na tua mão a Jericó, ao seu rei e aos seus homens valorosos.',
        'Vós, pois, todos os homens de guerra, rodeareis a cidade, cercando-a uma vez; assim fareis por seis dias.',
        'E sete sacerdotes levarão sete buzinas de chifres de carneiros adiante da arca, e no sétimo dia rodeareis a cidade sete vezes, e os sacerdotes tocarão as buzinas.',
        'E será que, tocando-se prolongadamente a buzina de carneiro, ouvindo vós o seu sonido, todo o povo gritará com grande brado; e o muro da cidade cairá abaixo, e o povo subirá por ele, cada um em frente.',
        'Então Josué, filho de Num, chamou aos sacerdotes e disse-lhes: Levai a arca da aliança; e sete sacerdotes levem sete buzinas de chifres de carneiros, adiante da arca do Senhor.',
        'E disse ao povo: Passai e rodeai a cidade; e quem estiver armado, passe adiante da arca do Senhor.',
        'E assim foi que, como Josué dissera ao povo, os sete sacerdotes, levando as sete buzinas de carneiros diante do Senhor, passaram e tocaram as buzinas; e a arca da aliança do Senhor os seguia.',
        'E os homens armados iam adiante dos sacerdotes, que tocavam as buzinas; e a retaguarda seguia após a arca; andando e tocando as buzinas iam os sacerdotes.',
        'Porém ao povo Josué tinha dado ordem, dizendo: Não gritareis, nem fareis ouvir a vossa voz, nem sairá palavra alguma da vossa boca até ao dia que eu vos diga: Gritai. Então gritareis.',
        'E fez a arca do Senhor rodear a cidade, contornando-a uma vez; e entraram no arraial, e ali passaram a noite.',
        'Depois Josué se levantou de madrugada, e os sacerdotes levaram a arca do Senhor.',
        'E os sete sacerdotes, que levavam as sete buzinas de chifres de carneiros, adiante da arca do Senhor, iam andando, e tocavam as buzinas, e os homens armados iam adiante deles e a retaguarda seguia atrás da arca do Senhor; os sacerdotes iam andando e tocando as buzinas.',
        'Assim rodearam outra vez a cidade no segundo dia e voltaram para o arraial; e assim fizeram seis dias.',
        'E sucedeu que, ao sétimo dia, madrugaram ao subir da alva, e da mesma maneira rodearam a cidade sete vezes; naquele dia somente rodearam a cidade sete vezes.',
        'E sucedeu que, tocando os sacerdotes pela sétima vez as buzinas, disse Josué ao povo: Gritai, porque o Senhor vos tem dado a cidade.',
        'Porém a cidade será anátema ao Senhor, ela e tudo quanto houver nela; somente a prostituta Raabe viverá; ela e todos os que com ela estiverem em casa; porquanto escondeu os mensageiros que enviamos.',
        'Tão-somente guardai-vos do anátema, para que não toqueis nem tomeis alguma coisa dele, e assim façais maldito o arraial de Israel, e o perturbeis.',
        'Porém toda a prata, e o ouro, e os vasos de metal, e de ferro são consagrados ao Senhor; irão ao tesouro do Senhor.',
        'Gritou, pois, o povo, tocando os sacerdotes as buzinas; e sucedeu que, ouvindo o povo o sonido da buzina, gritou o povo com grande brado; e o muro caiu abaixo, e o povo subiu à cidade, cada um em frente de si, e tomaram a cidade.',
        'E tudo quanto havia na cidade destruíram totalmente ao fio da espada, desde o homem até à mulher, desde o menino até ao velho, e até ao boi e gado miúdo, e ao jumento.',
        'Josué, porém, disse aos dois homens que tinham espiado a terra: Entrai na casa da mulher prostituta, e tirai-a de lá com tudo quanto tiver, como lhe tendes jurado.',
        'Então entraram os jovens espias, e tiraram a Raabe e a seu pai, e a sua mãe, e a seus irmãos, e a tudo quanto tinha; tiraram também a toda a sua parentela, e os puseram fora do arraial de Israel.',
        'Porém a cidade e tudo quanto havia nela queimaram a fogo; tão-somente a prata, e o ouro, e os vasos de metal e de ferro, deram para o tesouro da casa do Senhor.',
        'Assim deu Josué vida à prostituta Raabe e à família de seu pai, e a tudo quanto tinha; e habitou no meio de Israel até ao dia de hoje; porquanto escondera os mensageiros que Josué tinha enviado a espiar a Jericó.',
        'E naquele tempo Josué os esconjurou, dizendo: Maldito diante do Senhor seja o homem que se levantar e reedificar esta cidade de Jericó; sobre seu primogênito a fundará, e sobre o seu filho mais novo lhe porá as portas.',
        'Assim era o Senhor com Josué; e corria a sua fama por toda a terra.',
      ],
      [
        'E transgrediram os filhos de Israel no anátema; porque Acã filho de Carmi, filho de Zabdi, filho de Zerá, da tribo de Judá, tomou do anátema, e a ira do SENHOR se acendeu contra os filhos de Israel.',
        'Enviando, pois, Josué, de Jericó, alguns homens a Ai, que está junto a Bete-Áven do lado do oriente de Betel, falou-lhes dizendo: Subi, e espiai a terra. Subiram, pois, aqueles homens, e espiaram a Ai.',
        'E voltaram a Josué, e disseram-lhe: Não suba todo o povo; subam uns dois mil, ou três mil homens, a ferir a Ai; não fatigueis ali a todo o povo, porque poucos são.',
        'Assim, subiram lá, do povo, uns três mil homens, os quais fugiram diante dos homens de Ai.',
        'E os homens de Ai feriram deles uns trinta e seis, e os perseguiram desde a porta até Sebarim, e os feriram na descida; e o coração do povo se derreteu e se tornou como água.',
        'Então Josué rasgou as suas vestes, e se prostrou em terra sobre o seu rosto perante a arca do Senhor até à tarde, ele e os anciãos de Israel; e deitaram pó sobre as suas cabeças.',
        'E disse Josué: Ah! Senhor Deus! Por que, com efeito, fizeste passar a este povo o Jordão, para nos entregares nas mãos dos amorreus para nos fazerem perecer? Antes nos tivéssemos contentado em ficar além do Jordão!',
        'Ah, Senhor! Que direi? Pois Israel virou as costas diante dos inimigos!',
        'Ouvindo isto, os cananeus, e todos os moradores da terra, nos cercarão e desarraigarão o nosso nome da terra; e então que farás ao teu grande nome?',
        'Então disse o Senhor a Josué: Levanta-te; por que estás prostrado assim sobre o teu rosto?',
        'Israel pecou, e transgrediram a minha aliança que lhes tinha ordenado, e tomaram do anátema, e furtaram, e mentiram, e debaixo da sua bagagem o puseram.',
        'Por isso os filhos de Israel não puderam subsistir perante os seus inimigos; viraram as costas diante dos seus inimigos; porquanto estão amaldiçoados; não serei mais convosco, se não desarraigardes o anátema do meio de vós.',
        'Levanta-te, santifica o povo, e dize: Santificai-vos para amanhã, porque assim diz o Senhor Deus de Israel: Anátema há no meio de ti, Israel; diante dos teus inimigos não poderás suster-te, até que tireis o anátema do meio de vós.',
        'Amanhã, pois, vos chegareis, segundo as vossas tribos; e será que a tribo que o Senhor tomar se chegará, segundo as famílias; e a família que o Senhor tomar se chegará por casas; e a casa que o Senhor tomar se chegará homem por homem.',
        'E será que aquele que for tomado com o anátema será queimado a fogo, ele e tudo quanto tiver; porquanto transgrediu a aliança do Senhor, e fez uma loucura em Israel.',
        'Então Josué se levantou de madrugada, e fez chegar a Israel, segundo as suas tribos; e a tribo de Judá foi tomada;',
        'E, fazendo chegar a tribo de Judá, tomou a família dos zeraítas; e fazendo chegar a família dos zeraítas homem por homem, foi tomado Zabdi;',
        'E, fazendo chegar a sua casa, homem por homem, foi tomado Acã, filho de Carmi, filho de Zabdi, filho de Zerá, da tribo de Judá.',
        'Então disse Josué a Acã: Filho meu, dá, peço-te, glória ao Senhor Deus de Israel, e faze confissão perante ele; e declara-me agora o que fizeste, não mo ocultes.',
        'E respondeu Acã a Josué, e disse: Verdadeiramente pequei contra o Senhor Deus de Israel, e fiz assim e assim.',
        'Quando vi entre os despojos uma boa capa babilônica, e duzentos siclos de prata, e uma cunha de ouro, do peso de cinqüenta siclos, cobicei-os e tomei-os; e eis que estão escondidos na terra, no meio da minha tenda, e a prata por baixo dela.',
        'Então Josué enviou mensageiros, que foram correndo à tenda; e eis que tudo estava escondido na sua tenda, e a prata por baixo.',
        'Tomaram, pois, aquelas coisas do meio da tenda, e as trouxeram a Josué e a todos os filhos de Israel; e as puseram perante o Senhor.',
        'Então Josué, e todo o Israel com ele, tomaram a Acã filho de Zerá, e a prata, e a capa, e a cunha de ouro, e seus filhos, e suas filhas, e seus bois, e seus jumentos, e suas ovelhas, e sua tenda, e tudo quanto ele tinha; e levaram-nos ao vale de Acor.',
        'E disse Josué: Por que nos perturbaste? O Senhor te perturbará neste dia. E todo o Israel o apedrejou; e os queimaram a fogo depois de apedrejá-los.',
        'E levantaram sobre ele um grande montão de pedras, até o dia de hoje; assim o Senhor se apartou do ardor da sua ira; pelo que aquele lugar se chama o vale de Acor, até ao dia de hoje.',
      ],
      [
        'Então disse o SENHOR a Josué: Não temas, e não te espantes; toma contigo toda a gente de guerra, e levanta-te, sobe a Ai; olha que te tenho dado na tua mão o rei de Ai e o seu povo, e a sua cidade, e a sua terra.',
        'Farás, pois, a Ai e a seu rei, como fizeste a Jericó, e a seu rei; salvo que, para vós, tomareis os seus despojos, e o seu gado; põe emboscadas à cidade, por detrás dela.',
        'Então Josué levantou-se, e toda a gente de guerra, para subir contra Ai; e escolheu Josué trinta mil homens valorosos, e enviou-os de noite.',
        'E deu-lhes ordem, dizendo: Olhai! Ponde-vos de emboscadas contra a cidade, por detrás dela; não vos alongueis muito da cidade; e estai todos vós atentos.',
        'Porém eu e todo o povo que está comigo nos aproximaremos da cidade; e será que, quando nos saírem ao encontro, como antes, fugiremos diante deles.',
        'Deixai-os, pois, sair atrás de nós, até que os tiremos da cidade; porque dirão: Fogem diante de nós como antes. Assim fugiremos diante deles.',
        'Então saireis vós da emboscada, e tomareis a cidade; porque o Senhor vosso Deus vo-la dará nas vossas mãos.',
        'E será que tomando vós a cidade, pôr-lhe-eis fogo; conforme a palavra do Senhor fareis; olhai que vo-lo tenho mandado.',
        'Assim Josué os enviou, e eles se foram à emboscada; e ficaram entre Betel e Ai, ao ocidente de Ai; porém Josué passou aquela noite no meio do povo.',
        'E levantou-se Josué de madrugada, e contou o povo; e subiram ele e os anciãos de Israel adiante do povo contra Ai.',
        'E subiram também todos os homens de guerra, que estavam com ele; e aproximaram-se, e chegaram defronte da cidade; e alojaram-se do lado norte de Ai, e havia um vale entre eles e Ai.',
        'Tomou também uns cinco mil homens, e pô-los de emboscada entre Betel e Ai, ao ocidente da cidade.',
        'E puseram o povo, todo o arraial que estava ao norte da cidade, e a emboscada ao ocidente da cidade; e foi Josué aquela noite até ao meio do vale.',
        'E sucedeu que, vendo-o o rei de Ai, ele e todo o seu povo se apressaram, e se levantaram de madrugada, e os homens da cidade saíram ao encontro de Israel ao combate, ao tempo determinado, defronte das campinas; porém ele não sabia que se achava uma emboscada contra ele atrás da cidade.',
        'Josué, pois, e todo o Israel se houveram como feridos diante deles, e fugiram pelo caminho do deserto.',
        'Por isso todo o povo, que estava na cidade, foi convocado para os seguir; e seguiram a Josué e foram afastados da cidade.',
        'E nem um só homem ficou em Ai, nem em Betel, que não saísse após Israel; e deixaram a cidade aberta, e seguiram a Israel.',
        'Então o Senhor disse a Josué: Estende a lança que tens na tua mão, para Ai, porque a darei na tua mão. E Josué estendeu a lança, que estava na sua mão, para a cidade.',
        'Então a emboscada se levantou apressadamente do seu lugar, e, estendendo ele a sua mão, correram e entraram na cidade, e a tomaram; e apressando-se, puseram fogo na cidade.',
        'E virando-se os homens de Ai para trás, olharam, e eis que a fumaça da cidade subia ao céu, e não puderam fugir nem para uma parte nem para outra, porque o povo, que fugia para o deserto, se tornou contra os que os seguiam.',
        'E vendo Josué e todo o Israel que a emboscada tomara a cidade, e que a fumaça da cidade subia, voltaram, e feriram os homens de Ai.',
        'Também aqueles da cidade lhes saíram ao encontro, e assim ficaram no meio dos israelitas, uns de uma, e outros de outra parte; e feriram-nos, até que nenhum deles sobreviveu nem escapou.',
        'Porém ao rei de Ai tomaram vivo, e o trouxeram a Josué.',
        'E sucedeu que, acabando os israelitas de matar todos os moradores de Ai no campo, no deserto, onde os tinham seguido, e havendo todos caído ao fio da espada, até serem consumidos, todo o Israel se tornou a Ai e a feriu ao fio de espada.',
        'E todos os que caíram aquele dia, assim homens como mulheres, foram doze mil, todos moradores de Ai.',
        'Porque Josué não retirou a sua mão, que estendera com a lança, até destruir totalmente a todos os moradores de Ai.',
        'Tão-somente os israelitas tomaram para si o gado e os despojos da cidade, conforme à palavra do Senhor, que tinha ordenado a Josué.',
        'Queimou, pois, Josué a Ai e a tornou num montão perpétuo, em ruínas, até ao dia de hoje.',
        'E ao rei de Ai enforcou num madeiro, até à tarde; e ao pôr do sol ordenou Josué que o seu corpo fosse tirado do madeiro; e o lançaram à porta da cidade, e levantaram sobre ele um grande montão de pedras, até o dia de hoje.',
        'Então Josué edificou um altar ao Senhor Deus de Israel, no monte Ebal.',
        'Como Moisés, servo do Senhor, ordenara aos filhos de Israel, conforme ao que está escrito no livro da lei de Moisés, a saber: um altar de pedras inteiras, sobre o qual não se moverá instrumento de ferro; e ofereceram sobre ele holocaustos ao Senhor, e sacrificaram ofertas pacíficas.',
        'Também escreveu ali, em pedras, uma cópia da lei de Moisés, que este havia escrito diante dos filhos de Israel.',
        'E todo o Israel, com os seus anciãos, e os seus príncipes, e os seus juízes, estavam de um e de outro lado da arca, perante os sacerdotes levitas, que levavam a arca da aliança do Senhor, assim estrangeiros como naturais; metade deles em frente do monte Gerizim, e a outra metade em frente do monte Ebal, como Moisés, servo do Senhor, ordenara, para abençoar primeiramente o povo de Israel.',
        'E depois leu em alta voz todas as palavras da lei, a bênção e a maldição, conforme a tudo o que está escrito no livro da lei.',
        'Palavra nenhuma houve, de tudo o que Moisés ordenara, que Josué não lesse perante toda a congregação de Israel, e as mulheres, e os meninos, e os estrangeiros, que andavam no meio deles.',
      ],
      [
        'E sucedeu que, ouvindo isto todos os reis, que estavam aquém do Jordão, nas montanhas, e nas campinas, em toda a costa do grande mar, em frente do Líbano, os heteus, e os amorreus, os cananeus, os perizeus, os heveus, e os jebuseus,',
        'Se ajuntaram eles de comum acordo, para pelejar contra Josué e contra Israel.',
        'E os moradores de Gibeom, ouvindo o que Josué fizera com Jericó e com Ai,',
        'Usaram de astúcia, e foram e se fingiram embaixadores, e levando sacos velhos sobre os seus jumentos, e odres de vinho, velhos, e rotos, e remendados;',
        'E nos seus pés sapatos velhos e remendados, e roupas velhas sobre si; e todo o pão que traziam para o caminho era seco e bolorento.',
        'E vieram a Josué, ao arraial, a Gilgal, e disseram a ele e aos homens de Israel: Viemos de uma terra distante; fazei, pois, agora, acordo conosco.',
        'E os homens de Israel responderam aos heveus: Porventura habiteis no meio de nós; como pois faremos acordo convosco?',
        'Então disseram a Josué: Nós somos teus servos. E disse-lhes Josué: Quem sois vós, e de onde vindes?',
        'E lhe responderam: Teus servos vieram de uma terra mui distante, por causa do nome do Senhor teu Deus, porquanto ouvimos a sua fama, e tudo quanto fez no Egito;',
        'E tudo quanto fez aos dois reis dos amorreus, que estavam além do Jordão, a Siom rei de Hesbom, e a Ogue, rei de Basã, que estava em Astarote.',
        'Por isso nossos anciãos e todos os moradores da nossa terra nos falaram, dizendo: Tomai em vossas mãos provisão para o caminho, e ide-lhes ao encontro e dizei-lhes: Nós somos vossos servos; fazei, pois, agora acordo conosco.',
        'Este nosso pão tomamos quente das nossas casas para nossa provisão, no dia em que saímos para vir a vós; e ei-lo aqui agora já seco e bolorento;',
        'E estes odres, que enchemos de vinho, eram novos, e ei-los aqui já rotos; e estas nossas roupas e nossos sapatos já se têm envelhecido, por causa do mui longo caminho.',
        'Então os homens de Israel tomaram da provisão deles e não pediram conselho ao Senhor.',
        'E Josué fez paz com eles, e fez um acordo com eles, que lhes daria a vida; e os príncipes da congregação lhes prestaram juramento.',
        'E sucedeu que, ao fim de três dias, depois de fazerem acordo com eles, ouviram que eram seus vizinhos, e que moravam no meio deles.',
        'Porque, partindo os filhos de Israel, chegaram às cidades deles ao terceiro dia; e suas cidades eram Gibeom e Cefira, e Beerote, e Quiriate-Jearim.',
        'E os filhos de Israel não os feriram; porquanto os príncipes da congregação lhes juraram pelo Senhor Deus de Israel; por isso toda a congregação murmurava contra os príncipes.',
        'Então todos os príncipes disseram a toda a congregação: Nós juramos-lhes pelo Senhor Deus de Israel, pelo que não lhes podemos tocar.',
        'Isto, porém, lhes faremos: conservar-lhes-emos a vida, para que não haja grande ira sobre nós, por causa do juramento que já lhes fizemos.',
        'Disseram-lhes, pois, os príncipes: Vivam, e sejam rachadores de lenha e tiradores de água para toda a congregação, como os príncipes lhes disseram.',
        'E Josué os chamou, e falou-lhes dizendo: Por que nos enganastes dizendo: Mui longe de vós habitamos, morando vós no meio de nós?',
        'Agora, pois, sereis malditos; e dentre vós não deixará de haver servos, nem rachadores de lenha, nem tiradores de água, para a casa do meu Deus.',
        'Então responderam a Josué, e disseram: Porquanto com certeza foi anunciado aos teus servos que o Senhor teu Deus ordenou a Moisés, seu servo, que a vós daria toda esta terra, e destruiria todos os moradores da terra diante de vós, tememos muito por nossas vidas por causa de vós; por isso fizemos assim.',
        'E eis que agora estamos na tua mão; faze-nos aquilo que te pareça bom e reto.',
        'Assim pois lhes fez, e livrou-os das mãos dos filhos de Israel, e não os mataram.',
        'E naquele dia, Josué os fez rachadores de lenha e tiradores de água para a congregação e para o altar do Senhor, até ao dia de hoje, no lugar que ele escolhesse.',
      ],
      [
        'E sucedeu que, ouvindo Adoni-Zedeque, rei de Jerusalém, que Josué tomara a Ai, e a tinha destruído totalmente, e fizera a Ai, e ao seu rei, como tinha feito a Jericó e ao seu rei, e que os moradores de Gibeom fizeram paz com os israelitas, e estavam no meio deles,',
        'Temeram muito, porque Gibeom era uma cidade grande, como uma das cidades reais, e ainda maior do que Ai, e todos os seus homens valentes.',
        'Pelo que Adoni-Zedeque, rei de Jerusalém, enviou a Hoão, rei de Hebrom, e a Pirão, rei de Jarmute, e a Jafia, rei de Laquis e a Debir, rei de Eglom, dizendo:',
        'Subi a mim, e ajudai-me, e firamos a Gibeom, porquanto fez paz com Josué e com os filhos de Israel.',
        'Então se ajuntaram, e subiram cinco reis dos amorreus, o rei de Jerusalém, o rei de Hebrom, o rei de Jarmute, o rei de Laquis, o rei de Eglom, eles e todos os seus exércitos; e sitiaram a Gibeom e pelejaram contra ela.',
        'Enviaram, pois, os homens de Gibeom a Josué, ao arraial de Gilgal, dizendo: Não retires as tuas mãos de teus servos; sobe apressadamente a nós, e livra-nos e ajuda-nos, porquanto todos os reis dos amorreus, que habitam na montanha, se ajuntaram contra nós.',
        'Então subiu Josué, de Gilgal, ele e toda a gente de guerra com ele, e todos os homens valorosos.',
        'E o Senhor disse a Josué: Não os temas, porque os tenho dado na tua mão; nenhum deles te poderá resistir.',
        'E Josué lhes sobreveio de repente, porque toda a noite veio subindo desde Gilgal.',
        'E o Senhor os conturbou diante de Israel, e os feriu com grande matança em Gibeom; e perseguiu-os pelo caminho que sobe a Bete-Horom, e feriu-os até Azeca e a Maquedá.',
        'E sucedeu que fugindo eles de diante de Israel, à descida de Bete-Horom, o Senhor lançou sobre eles, do céu, grandes pedras, até Azeca, e morreram; e foram muitos mais os que morreram das pedras da saraiva do que os que os filhos de Israel mataram à espada.',
        'Então Josué falou ao Senhor, no dia em que o Senhor deu os amorreus nas mãos dos filhos de Israel, e disse na presença dos israelitas: Sol, detém-te em Gibeom, e tu, lua, no vale de Ajalom.',
        'E o sol se deteve, e a lua parou, até que o povo se vingou de seus inimigos. Isto não está escrito no livro de Jasher? O sol, pois, se deteve no meio do céu, e não se apressou a pôr-se, quase um dia inteiro.',
        'E não houve dia semelhante a este, nem antes nem depois dele, ouvindo o Senhor assim a voz de um homem; porque o Senhor pelejava por Israel.',
        'E voltou Josué, e todo o Israel com ele, ao arraial, em Gilgal.',
        'Aqueles cinco reis, porém, fugiram, e se esconderam numa cova em Maquedá.',
        'E foi anunciado a Josué, dizendo: Acharam-se os cinco reis escondidos numa cova em Maquedá.',
        'Disse, pois, Josué: Arrastai grandes pedras à boca da cova, e ponde sobre ela homens que os guardem;',
        'Porém vós não vos detenhais; persegui os vossos inimigos, e atacai os que vão ficando atrás; não os deixeis entrar nas suas cidades, porque o Senhor vosso Deus já vo-los deu na vossa mão.',
        'E sucedeu que, acabando Josué e os filhos de Israel de os ferir com grande matança, até consumi-los, e os que ficaram deles se retiraram às cidades fortificadas,',
        'Todo o povo voltou em paz a Josué, ao arraial em Maquedá; não havendo ninguém que movesse a sua língua contra os filhos de Israel.',
        'Depois disse Josué: Abri a boca da cova, e trazei-me para fora aqueles cinco reis.',
        'Fizeram, pois, assim, e trouxeram-lhe aqueles cinco reis para fora da cova: o rei de Jerusalém, o rei de Hebrom, o rei de Jarmute, o rei de Laquis e o rei de Eglom.',
        'E sucedeu que, trazendo aqueles reis a Josué, este chamou todos os homens de Israel, e disse aos capitães dos homens de guerra, que foram com ele: Chegai, ponde os vossos pés sobre os pescoços destes reis. E chegaram, e puseram os seus pés sobre os pescoços deles.',
        'Então Josué lhes disse: Não temais, nem vos espanteis; esforçai-vos e animai-vos; porque assim o fará o Senhor a todos os vossos inimigos, contra os quais pelejardes.',
        'E, depois disto, Josué os feriu, e os matou, e os enforcou em cinco madeiros; e ficaram enforcados nos madeiros até à tarde.',
        'E sucedeu que, ao pôr do sol, deu Josué ordem que os tirassem dos madeiros; e lançaram-nos na cova onde se esconderam; e puseram grandes pedras à boca da cova, que ainda ali estão até o dia de hoje.',
        'E naquele mesmo dia tomou Josué a Maquedá, e feriu-a a fio de espada, bem como ao seu rei; totalmente a destruiu com todos que nela havia, sem nada deixar; e fez ao rei de Maquedá como fizera ao rei de Jericó.',
        'Então Josué, e todo o Israel com ele, passou de Maquedá a Libna e pelejou contra ela.',
        'E também o Senhor a deu na mão de Israel, a ela e a seu rei, e a feriu a fio de espada, a ela e a todos que nela estavam; sem nada deixar; e fez ao seu rei como fizera ao rei de Jericó.',
        'Então Josué, e todo o Israel com ele, passou de Libna a Laquis; e a sitiou, e pelejou contra ela;',
        'E o Senhor deu a Laquis nas mãos de Israel, e tomou-a no dia seguinte e a feriu a fio de espada, a ela e a todos os que nela estavam, conforme a tudo o que fizera a Libna.',
        'Então Horão, rei de Gezer, subiu a ajudar a Laquis, porém Josué o feriu, a ele e ao seu povo, até não lhe deixar nem sequer um.',
        'E Josué, e todo o Israel com ele, passou de Laquis a Eglom, e a sitiaram, e pelejaram contra ela.',
        'E no mesmo dia a tomaram, e a feriram a fio de espada; e a todos os que nela estavam, destruiu totalmente no mesmo dia, conforme a tudo o que fizera a Laquis.',
        'Depois Josué, e todo o Israel com ele, subiu de Eglom a Hebrom, e pelejaram contra ela.',
        'E a tomaram, e a feriram ao fio de espada, assim ao seu rei como a todas as suas cidades; e a todos os que nelas estavam, a ninguém deixou com vida, conforme a tudo o que fizera a Eglom; e a destruiu totalmente, a ela e a todos os que nela estavam.',
        'Então Josué, e todo o Israel com ele, tornou a Debir, e pelejou contra ela.',
        'E tomou-a com o seu rei, e a todas as suas cidades, e as feriu a fio de espada, e a todos os que nelas estavam destruiu totalmente; nada deixou; como fizera a Hebrom, assim fez a Debir e ao seu rei, e como fizera a Libna e ao seu rei.',
        'Assim feriu Josué toda aquela terra, as montanhas, o sul, e as campinas, e as descidas das águas, e a todos os seus reis; nada deixou; mas tudo o que tinha fôlego destruiu, como ordenara o Senhor Deus de Israel.',
        'E Josué os feriu desde Cades-Barnéia, até Gaza, como também toda a terra de Gósen, e até Gibeom.',
        'E de uma vez tomou Josué todos estes reis, e as suas terras; porquanto o Senhor Deus de Israel pelejava por Israel.',
        'Então Josué, e todo o Israel com ele, voltou ao arraial em Gilgal.',
      ],
      [
        'Sucedeu depois disto que, ouvindo-o Jabim, rei de Hazor, enviou mensageiros a Jobabe, rei de Madom, e ao rei de Sinrom, e ao rei de Acsafe;',
        'E aos reis, que estavam ao norte, nas montanhas, e na campina para o sul de Quinerete, e nas planícies, e nas elevações de Dor, do lado do mar;',
        'Ao cananeu do oriente e do ocidente; e ao amorreu, e ao heteu, e ao perizeu, e ao jebuseu nas montanhas; e ao heveu ao pé de Hermom, na terra de Mizpá.',
        'Saíram pois estes, e todos os seus exércitos com eles, muito povo, em multidão como a areia que está na praia do mar; e muitíssimos cavalos e carros.',
        'Todos estes reis se ajuntaram, e vieram e se acamparam junto às águas de Merom, para pelejarem contra Israel.',
        'E disse o Senhor a Josué: Não temas diante deles; porque amanhã, a esta mesma hora, eu os darei todos feridos diante dos filhos de Israel; os seus cavalos jarretarás, e os seus carros queimarás a fogo.',
        'E Josué, e todos os homens de guerra com ele, veio apressadamente sobre eles às águas de Merom, e atacou-os de repente.',
        'E o Senhor os deu nas mãos de Israel; e eles os feriram, e os perseguiram até à grande Sidom, e até Misrefote-Maim, e até ao vale de Mizpá ao oriente; feriram até não lhes deixarem nenhum.',
        'E fez-lhes Josué como o Senhor lhe dissera; os seus cavalos jarretou, e os seus carros queimou a fogo.',
        'E naquele mesmo tempo voltou Josué, e tomou a Hazor, e feriu à espada ao seu rei; porquanto Hazor antes era a cabeça de todos estes reinos.',
        'E a todos os que nela estavam, feriram ao fio da espada, e totalmente os destruíram; nada restou do que tinha fôlego, e a Hazor queimou a fogo.',
        'E Josué tomou todas as cidades destes reis, e todos os seus reis, e os feriu ao fio da espada, destruindo-os totalmente, como ordenara Moisés servo do Senhor.',
        'Tão-somente não queimaram os israelitas as cidades que estavam sobre os seus outeiros; a não ser Hazor, a qual Josué queimou.',
        'E todos os despojos destas cidades, e o gado, os filhos de Israel tomaram para si; tão-somente a todos os homens feriram ao fio da espada, até que os destruíram; nada do que tinha fôlego deixaram com vida.',
        'Como ordenara o Senhor a Moisés, seu servo, assim Moisés ordenou a Josué; e assim Josué o fez; nem uma só palavra tirou de tudo o que o Senhor ordenara a Moisés.',
        'Assim Josué tomou toda aquela terra, as montanhas, e todo o sul, e toda a terra de Gósen, e as planícies, e as campinas, e as montanhas de Israel, e as suas planícies.',
        'Desde o monte Halaque, que sobe a Seir, até Baal-Gade, no vale do Líbano, ao pé do monte de Hermom; também tomou todos os seus reis, e os feriu e os matou.',
        'Por muito tempo Josué fez guerra contra todos estes reis.',
        'Não houve cidade que fizesse paz com os filhos de Israel, senão os heveus, moradores de Gibeom; por guerra as tomaram todas.',
        'Porquanto do Senhor vinha o endurecimento de seus corações, para saírem à guerra contra Israel, para que fossem totalmente destruídos e não achassem piedade alguma; mas para os destruir a todos como o Senhor tinha ordenado a Moisés.',
        'Naquele tempo veio Josué, e extirpou os anaquins das montanhas de Hebrom, de Debir, de Anabe e de todas as montanhas de Judá e de todas as montanhas de Israel; Josué os destruiu totalmente com as suas cidades.',
        'Nenhum dos anaquins foi deixado na terra dos filhos de Israel; somente ficaram alguns em Gaza, em Gate, e em Asdode.',
        'Assim Josué tomou toda esta terra, conforme a tudo o que o Senhor tinha dito a Moisés; e Josué a deu em herança aos filhos de Israel, conforme as suas divisões, segundo as suas tribos; e a terra descansou da guerra.',
      ],
      [
        'Estes, pois, são os reis da terra, aos quais os filhos de Israel feriram e cujas terras possuíram além do Jordão para o nascente do sol, desde o ribeiro de Arnom, até ao monte de Hermom, e toda a planície do oriente:',
        'Siom, rei dos amorreus, que habitava em Hesbom e que dominava desde Aroer, que está à beira do ribeiro de Arnom, e desde o meio do ribeiro, e a metade de Gileade, e até ao ribeiro de Jaboque, o termo dos filhos de Amom.',
        'E desde a campina até ao mar de Quinerete para o oriente, e até ao mar da campina, o Mar Salgado para o oriente, pelo caminho de Bete-Jesimote; e desde o sul, abaixo de Asdote-Pisga.',
        'Como também o termo de Ogue, rei de Basã que era do restante dos gigantes e que habitava em Astarote e em Edrei;',
        'E dominava no monte Hermom, e em Salcá, e em toda a Basã, até ao termo dos gesureus e dos maacateus, e metade de Gileade, termo de Siom, rei de Hesbom.',
        'A estes Moisés, servo do Senhor, e os filhos de Israel, feriram; e Moisés, servo do Senhor, deu esta terra em possessão aos rubenitas, e aos gaditas, e à meia tribo de Manassés.',
        'E estes são os reis da terra aos quais Josué e os filhos de Israel feriram aquém do Jordão para o ocidente, desde Baal-Gade, no vale do Líbano, até ao monte Halaque, que sobe a Seir; e Josué a deu às tribos de Israel em possessão, segundo as suas divisões.',
        'O que havia nas montanhas, e nas planícies, e nas campinas, e nas descidas das águas, e no deserto, e para o sul: o heteu, o amorreu, e o cananeu, o perizeu, o heveu, e o jebuseu.',
        'O rei de Jericó, um; o rei de Ai, que está ao lado de Betel, outro;',
        'O rei de Jerusalém, outro; o rei de Hebrom, outro;',
        'O rei de Jarmute, outro; o rei de Laquis, outro;',
        'O rei de Eglom, outro; o rei de Geser, outro;',
        'O rei de Debir, outro; o rei de Geder, outro;',
        'O rei de Hormá, outro; o rei de Harade, outro;',
        'O rei de Libna, outro; o rei de Adulão, outro;',
        'O rei de Maquedá, outro; o rei de Betel, outro;',
        'O rei de Tapua, outro; o rei de Hefer, outro;',
        'O rei de Afeque, outro; o rei de Lassarom, outro;',
        'O rei de Madom, outro; o rei de Hazor, outro;',
        'O rei de Sinrom-Meron, outro; o rei de Acsafe, outro;',
        'O rei de Taanaque, outro; o rei de Megido, outro;',
        'O rei de Quedes, outro; o rei de Jocneão do Carmelo, outro;',
        'O rei de Dor no outeiro de Dor, outro; o rei de Goim em Gilgal, outro;',
        'O rei de Tirza, outro; trinta e um reis ao todo.',
      ],
      [
        'Era, porém, Josué já velho, entrado em dias; e disse-lhe o SENHOR: Já estás velho, entrado em dias; e ainda muitíssima terra ficou para possuir.',
        'A terra que ainda fica é esta: Todos os termos dos filisteus e toda a Gesur;',
        'Desde Sior, que está em frente ao Egito, até ao termo de Ecrom para o norte, que se diz ser dos cananeus; cinco príncipes dos filisteus; o gazeu, e o asdodeu, o asqueloneu, o giteu, e o ecroneu, e os aveus;',
        'Desde o sul, toda a terra dos cananeus, e Meara, que é dos sidônios; até Afeca, até ao termo dos amorreus;',
        'Como também a terra dos gebalitas, e todo o Líbano, para o nascente do sol, desde Baal-Gade, ao pé do monte Hermom, até a entrada de Hamate;',
        'Todos os que habitam nas montanhas desde o Líbano até Misrefote-Maim, todos os sidônios; eu os lançarei de diante dos filhos de Israel; tão-somente reparte a terra em herança a Israel, como já te mandei.',
        'Reparte, pois, agora esta terra por herança às nove tribos, e à meia tribo de Manassés,',
        'Com a qual os rubenitas e os gaditas já receberam a sua herança, além do Jordão para o oriente, assim como já lhes tinha dado Moisés, servo do Senhor.',
        'Desde Aroer, que está à beira do ribeiro de Arnom, e a cidade que está no meio do vale, e toda a campina de Medeba até Dibom;',
        'E todas as cidades de Siom, rei dos amorreus, que reinou em Hesbom, até ao termo dos filhos de Amom;',
        'E Gileade, e o termo dos gesureus, e dos maacateus, e todo o monte Hermom, e toda a Basã até Salcá;',
        'Todo o reino de Ogue em Basã, que reinou em Astarote e em Edrei; este ficou do restante dos gigantes que Moisés feriu e expulsou.',
        'Porém os filhos de Israel não expulsaram os gesureus, nem os maacateus; antes Gesur e Maacate ficaram habitando no meio de Israel até ao dia de hoje.',
        'Tão-somente à tribo de Levi não deu herança; os sacrifícios queimados do Senhor Deus de Israel são a sua herança, como já lhe tinha falado.',
        'Assim Moisés deu à tribo dos filhos de Rúben, conforme as suas famílias.',
        'E foi o seu limite desde Aroer, que está à beira do ribeiro de Arnom, e a cidade que está no meio do vale, e toda a campina até Medeba;',
        'Hesbom e todas as suas cidades, que estão na campina; Dibom, e Bamote-Baal, e Bete-Baal-Meom;',
        'E Jasa e Quedemote, e Mefaate;',
        'E Quiriataim e Sibma, e Zerete-Saar, no monte do vale;',
        'Bete-Peor, e Asdote-Pisga, Bete-Jesimote;',
        'E todas as cidades da campina, e todo o reino de Siom, rei dos amorreus, que reinou em Hesbom, a quem Moisés feriu, como também aos príncipes de Midiã, Evi, e Requém, e Zur, e Hur, e Reba, príncipes de Siom, moradores da terra.',
        'Também os filhos de Israel mataram à espada a Balaão, filho de Beor, o adivinho, com os outros que por eles foram mortos.',
        'E o termo dos filhos de Rúben ficou sendo o Jordão e os seus limites; esta foi a herança dos filhos de Rúben, segundo as suas famílias, as cidades, e as suas aldeias.',
        'E deu Moisés à tribo de Gade, aos filhos de Gade, segundo as suas famílias.',
        'E foi o seu termo Jazer, e todas as cidades de Gileade, e metade da terra dos filhos de Amom, até Aroer, que está em frente de Rabá.',
        'E desde Hesbom até Ramate-Mizpá e Betonim, e desde Maanaim até ao termo de Debir;',
        'E no vale Bete-Arã, e Bete-Nimra, e Sucote, Zafom, que ficara do restante do reino de Siom, em Hesbom, o Jordão e o seu termo, até a extremidade do mar de Quinerete além do Jordão para o oriente.',
        'Esta é a herança dos filhos de Gade segundo as suas famílias, as cidades e as suas aldeias.',
        'Deu também Moisés herança à meia tribo de Manassés; e deu à meia tribo dos filhos de Manassés, segundo as suas famílias.',
        'De maneira que o seu termo foi desde Maanaim, todo o Basã, todo o reino de Ogue, rei de Basã, e todas as aldeias de Jair, que estão em Basã, sessenta cidades,',
        'E metade de Gileade, e Astarote, e Edrei, cidades do reino de Ogue em Basã, deu aos filhos de Maquir, filho de Manassés, a saber, à metade dos filhos de Maquir, segundo as suas famílias.',
        'Isto é o que Moisés repartiu em herança nas campinas de Moabe, além do Jordão para o oriente de Jericó.',
        'Porém, à tribo de Levi, Moisés não deu herança; o Senhor Deus de Israel é a sua herança, como já lhe tinha falado.',
      ],
      [
        'Isto, pois, é o que os filhos de Israel tiveram em herança, na terra de Canaã, o que Eleazar, o sacerdote, e Josué, filho de Num, e os cabeças dos pais das tribos dos filhos de Israel lhes fizeram repartir,',
        'Por sorte da sua herança, como o Senhor ordenara, pelo ministério de Moisés, acerca das nove tribos e da meia tribo.',
        'Porquanto às duas tribos e à meia tribo já dera Moisés herança além do Jordão; mas aos levitas não tinha dado herança entre eles.',
        'Porque os filhos de José eram duas tribos, Manassés e Efraim, e aos levitas não se deu herança na terra, senão cidades em que habitassem, e os seus arrabaldes para seu gado e para seus bens.',
        'Como o Senhor ordenara a Moisés, assim fizeram os filhos de Israel, e repartiram a terra.',
        'Então os filhos de Judá chegaram a Josué em Gilgal; e Calebe, filho de Jefoné o quenezeu, lhe disse: Tu sabes o que o Senhor falou a Moisés, homem de Deus, em Cades-Barnéia por causa de mim e de ti.',
        'Quarenta anos tinha eu, quando Moisés, servo do Senhor, me enviou de Cades-Barnéia a espiar a terra; e eu lhe trouxe resposta, como sentia no meu coração;',
        'Mas meus irmãos, que subiram comigo, fizeram derreter o coração do povo; eu porém perseverei em seguir ao Senhor meu Deus.',
        'Então Moisés naquele dia jurou, dizendo: Certamente a terra que pisou o teu pé será tua, e de teus filhos, em herança perpetuamente; pois perseveraste em seguir ao Senhor meu Deus.',
        'E agora eis que o Senhor me conservou em vida, como disse; quarenta e cinco anos são passados, desde que o Senhor falou esta palavra a Moisés, andando Israel ainda no deserto; e agora eis que hoje tenho já oitenta e cinco anos;',
        'E ainda hoje estou tão forte como no dia em que Moisés me enviou; qual era a minha força então, tal é agora a minha força, tanto para a guerra como para sair e entrar.',
        'Agora, pois, dá-me este monte de que o Senhor falou aquele dia; pois naquele dia tu ouviste que estavam ali os anaquins, e grandes e fortes cidades. Porventura o Senhor será comigo, para os expulsar, como o Senhor disse.',
        'E Josué o abençoou, e deu a Calebe, filho de Jefoné, a Hebrom em herança.',
        'Portanto Hebrom ficou sendo herança de Calebe, filho de Jefoné o quenezeu, até ao dia de hoje, porquanto perseverara em seguir ao Senhor Deus de Israel.',
        'E antes o nome de Hebrom era Quiriate-Arba, porque Arba foi o maior homem entre os anaquins. E a terra repousou da guerra.',
      ],
      [
        'A sorte que coube à tribo dos filhos de Judá, segundo as suas famílias, foi até ao termo de Edom, o deserto de Zim, para o sul, na extremidade do lado meridional.',
        'E foi o seu termo para o sul, desde a extremidade do Mar Salgado, desde a baía que olha para o sul;',
        'E sai para o sul, até à subida de Acrabim, e passa a Zim, e sobe do sul a Cades-Barnéia, e passa por Hezrom, e sobe a Adar, e vira para Carca;',
        'E passa Azmom, e sai ao ribeiro do Egito, e as saídas deste termo vão até ao mar; este será o vosso termo do lado do sul.',
        'O termo, porém, para o oriente será o Mar Salgado, até à foz do Jordão; e o termo para o norte será da baía do mar, desde a foz do Jordão.',
        'E este termo subirá até Bete-Hogla, e passará do norte a Bete-Arabá, e este termo subirá até à pedra de Boã, filho de Rúben.',
        'Subirá mais este termo a Debir desde o vale de Acor, indo para o norte rumo a Gilgal, a qual está em frente da subida de Adumim, que está para o sul do ribeiro; então este termo continua até às águas de En-Semes; e as suas saídas estão do lado de En-Rogel.',
        'E este termo sobe pelo vale do filho de Hinom, do lado sul dos jebuseus (esta é Jerusalém) e sobe este termo até ao cume do monte que está diante do vale de Hinom para o ocidente, que está no fim do vale dos refains do lado do norte.',
        'Então este termo vai desde a altura do monte até à fonte das águas de Neftoa; e sai até às cidades do monte de Efrom; vai mais este termo até Baalá (esta é Quiriate-Jearim).',
        'Então volta este termo desde Baalá para o ocidente, até às montanhas de Seir, e passa ao lado do monte de Jearim do lado do norte (esta é Quesalom) e desce a Bete-Semes, e passa por Timna;',
        'Sai este termo mais ao lado de Ecrom, para o norte, e este termo vai a Sicrom e passa o monte de Baalá, e sai em Jabneel; e assim este termo finda no mar.',
        'Será, porém, o termo do lado do ocidente o Mar Grande, e suas adjacências; este é o termo dos filhos de Judá ao redor, segundo as suas famílias.',
        'Mas a Calebe, filho de Jefoné, deu uma parte no meio dos filhos de Judá, conforme a ordem do Senhor a Josué; a saber, a cidade de Arba, que é Hebrom; este Arba era pai de Anaque.',
        'E Calebe expulsou dali os três filhos de Anaque: Sesai, e Aimã, e Talmai, gerados de Anaque.',
        'E dali subiu aos habitantes de Debir; e fora antes o nome de Debir, Quiriate-Sefer.',
        'E disse Calebe: Quem ferir a Quiriate-Sefer, e a tomar, lhe darei a minha filha Acsa por mulher.',
        'Tomou-a, pois, Otniel, filho de Quenaz, irmão de Calebe; e deu-lhe a sua filha Acsa por mulher.',
        'E sucedeu que, vindo ela a ele, o persuadiu que pedisse um campo a seu pai; e ela desceu do seu jumento; então Calebe lhe disse: Que é que tens?',
        'E ela disse: Dá-me uma bênção; pois me deste terra seca, dá-me também fontes de águas. Então lhe deu as fontes superiores e as fontes inferiores.',
        'Esta é a herança da tribo dos filhos de Judá, segundo as suas famílias.',
        'São, pois, as cidades da tribo dos filhos de Judá, até ao termo de Edom, no extremo sul: Cabzeel, e Eder, e Jagur.',
        'E Quiná, e Dimona, e Adada,',
        'E Quedes, e Hazor, e Itnã,',
        'Zife, e Telem, e Bealote,',
        'E Hazor-Hadata, e Queriote-Hezrom (que é Hazor),',
        'Amã e Sema, e Moladá,',
        'E Hazar-Gada, e Hesmom, e Bete-Palete,',
        'E Hazar-Sual, e Berseba, e Biziotiá,',
        'Baalá, e Iim, e Azem,',
        'E Eltolade, e Quesil, e Hormá.',
        'E Ziclague, e Madmana, e Sansana,',
        'E Lebaote, e Silim, e Aim, e Rimom; todas as cidades e as suas aldeias, vinte e nove.',
        'Nas planícies: Estaol, e Zorá, e Asná,',
        'E Zanoa, e En-Ganim, Tapua, e Enã.',
        'E Jarmute, e Adulão, Socó, e Azeca,',
        'E Saaraim, e Aditaim, e Gederá, e Gederotaim; catorze cidades e as suas aldeias.',
        'Zenã, e Hadasa, e Migdal-Gade,',
        'E Dileã, e Mizpe, e Jocteel,',
        'Laquis, e Bozcate, e Eglom,',
        'E Cabom, e Laamás, e Quitlis,',
        'E Gederote, Bete-Dagom, e Naamá, e Maquedá, dezesseis cidades e as suas aldeias.',
        'Libna, e Eter, e Asã,',
        'E Iftá, e Asná, e Nezibe,',
        'E Queila, e Aczibe, e Maressa; nove cidades e as suas aldeias.',
        'Ecrom, com suas vilas, e as suas aldeias.',
        'Desde Ecrom, e até ao mar, todas as que estão do lado de Asdode, e as suas aldeias.',
        'Asdode, com as suas vilas e as suas aldeias; Gaza, com as suas vilas e as suas aldeias, até ao rio do Egito, e o Mar Grande e o seu termo.',
        'E nas montanhas: Samir, Jatir, e Socó.',
        'E Daná, e Quiriate-Saná (que é Debir),',
        'E Anabe, Estemó, e Anim,',
        'E Gósen, e Holom, e Giló; onze cidades e as suas aldeias.',
        'Arabe, e Dumá e Esã,',
        'E Janim, e Bete-Tapua e Afeca,',
        'E Hunta, e Quiriate-Arba (que é Hebrom), e Zior; nove cidades e as suas aldeias.',
        'Maom, Carmelo, e Zife, e Jutá,',
        'E Jizreel, e Jocdeão, e Zanoa,',
        'Caim, Gibeá, e Timna; dez cidades e as suas aldeias.',
        'Halul, Bete-Zur, e Gedor,',
        'E Maarate, e Bete-Anote, e Eltecom; seis cidades e as suas aldeias.',
        'Quiriate-Baal (que é Quiriate-Jearim), e Rabá; duas cidades e as suas aldeias.',
        'No deserto: Bete-Arabá, Midim, e Secacá,',
        'E Nibsã, e a Cidade do Sal, e En-Gedi; seis cidades e as suas aldeias.',
        'Não puderam, porém, os filhos de Judá expulsar os jebuseus que habitavam em Jerusalém; assim habitaram os jebuseus com os filhos de Judá em Jerusalém, até ao dia de hoje.',
      ],
      [
        'Saiu depois a sorte dos filhos de José, desde o Jordão, na direção de Jericó, junto às águas de Jericó, para o oriente, estendendo-se pelo deserto que sobe de Jericó pelas montanhas de Betel.',
        'E de Betel vai para Luz, e passa ao termo dos arquitas, até Atarote,',
        'E desce do lado do ocidente ao termo de Jafleti, até ao termo de Bete-Horom de baixo, e até Gezer, indo terminar no mar.',
        'Assim alcançaram a sua herança os filhos de José, Manassés e Efraim.',
        'E foi o termo dos filhos de Efraim, segundo as suas famílias, como se segue: o termo da sua herança para o oriente era Atarote-Adar até Bete-Horom de cima;',
        'E sai este termo para o ocidente junto a Micmetá, desde o norte, e torna este termo para o oriente até Taanate-Siló, e passa por ela desde o oriente a Janoa;',
        'E desce desde Janoa a Atarote e a Naarate e toca em Jericó, terminando no Jordão.',
        'De Tapua vai este termo para o ocidente ao ribeiro de Caná, terminando no mar; esta é a herança da tribo dos filhos de Efraim, segundo as suas famílias,',
        'Mais as cidades que se separaram para os filhos de Efraim no meio da herança dos filhos de Manassés; todas aquelas cidades e as suas aldeias.',
        'E não expulsaram aos cananeus que habitavam em Gezer; e os cananeus habitam no meio dos efraimitas até ao dia de hoje; porém, sendo-lhes tributários.',
      ],
      [
        'Também coube sorte à tribo de Manassés, porquanto era o primogênito de José. Maquir, o primogênito de Manassés, pai de Gileade, porquanto era homem de guerra, teve a Gileade e Basã;',
        'Também os demais filhos de Manassés tiveram a sua parte, segundo as suas famílias, a saber: Os filhos de Abiezer, e os filhos de Heleque, e os filhos de Asriel, e os filhos de Siquém, e os filhos de Hefer, e os filhos de Semida; esses são os filhos de Manassés, filho de José, segundo as suas famílias.',
        'Zelofeade, porém, filho de Hefer, filho de Gileade, filho de Maquir, filho de Manassés, não teve filhos, mas só filhas; e estes são os nomes de suas filhas: Maalá, Noa, Hogla, Milca e Tirza.',
        'Estas, pois, chegaram diante de Eleazar, o sacerdote, e diante de Josué, filho de Num, e diante dos príncipes, dizendo: O Senhor ordenou a Moisés que se nos desse herança no meio de nossos irmãos, pelo que, conforme a ordem do Senhor, lhes deu herança no meio dos irmãos de seu pai.',
        'E couberam a Manassés dez quinhões, afora a terra de Gileade e Basã, que está além do Jordão;',
        'Porque as filhas de Manassés receberam herança entre os filhos dele; e os outros filhos de Manassés ficaram com a terra de Gileade.',
        'E o termo de Manassés foi desde Aser até Micmetá, que está defronte de Siquém; e estende-se este termo à direita até os moradores de En-Tapua.',
        'Tinha Manassés a terra de Tapua; porém Tapua, junto ao termo de Manassés, pertencia aos filhos de Efraim.',
        'Então descia este termo ao ribeiro de Caná. A Efraim couberam as cidades ao sul do ribeiro, entre as cidades de Manassés; e o termo de Manassés estava ao norte do ribeiro, indo terminar no mar.',
        'Efraim ao sul, e Manassés ao norte, e o mar é o seu termo; pelo norte tocam em Aser, e pelo oriente em Issacar.',
        'Porque em Issacar e em Aser tinha Manassés a Bete-Seã e as suas vilas, e Ibleã e as suas vilas, e os habitantes de Dor e as suas vilas, e os habitantes de En-Dor e as suas vilas, e os habitantes de Taanaque e as suas vilas, e os habitantes de Megido e as suas vilas; três outeiros.',
        'E os filhos de Manassés não puderam expulsar os habitantes daquelas cidades; porquanto os cananeus queriam habitar na mesma terra.',
        'E sucedeu que, engrossando em forças os filhos de Israel, fizeram tributários aos cananeus; porém não os expulsaram de todo.',
        'Então os filhos de José falaram a Josué, dizendo: Por que me deste por herança só uma sorte e um quinhão, sendo eu um tão grande povo, visto que o Senhor até aqui me tem abençoado?',
        'E disse-lhes Josué: Se tão grande povo és, sobe ao bosque, e ali corta, para ti, lugar na terra dos perizeus e dos refains; pois que as montanhas de Efraim te são tão estreitas.',
        'Então disseram os filhos de José: As montanhas não nos bastariam; também carros de ferro há entre todos os cananeus que habitam na terra do vale, entre os de Bete-Seã e as suas vilas, e entre os que estão no vale de Jizreel.',
        'Então Josué falou à casa de José, a Efraim e a Manassés, dizendo: Grande povo és, e grande força tens; não terás uma sorte apenas;',
        'Porém as montanhas serão tuas. Ainda que é bosque, cortá-lo-ás, e as suas extremidades serão tuas; porque expulsarás os cananeus, ainda que tenham carros de ferro, ainda que sejam fortes.',
      ],
      [
        'E Toda a congregação dos filhos de Israel se reuniu em Siló, e ali armaram a tenda da congregação, depois que a terra lhes foi sujeita.',
        'E dentre os filhos de Israel ficaram sete tribos que ainda não tinham repartido a sua herança.',
        'E disse Josué aos filhos de Israel: Até quando sereis negligentes em chegardes para possuir a terra que o Senhor Deus de vossos pais vos deu?',
        'De cada tribo escolhei vós três homens, para que eu os envie, e eles se levantem e percorram a terra, e a demarquem segundo as suas heranças, e voltem a mim.',
        'E dividi-la-ão em sete partes: Judá ficará no seu termo para o sul, e a casa de José ficará no seu termo para o norte.',
        'E vós demarcareis a terra em sete partes, e me trareis a mim aqui descrita, para que eu aqui lance as sortes perante o Senhor nosso Deus,',
        'Porquanto os levitas não têm parte no meio de vós, porque o sacerdócio do Senhor é a sua parte; e Gade, e Rúben, e a meia tribo de Manassés, receberam a sua herança além do Jordão para o oriente, a qual lhes deu Moisés, o servo do Senhor.',
        'Então aqueles homens se levantaram e se foram; e Josué deu ordem aos que iam demarcar a terra, dizendo: Ide, e percorrei a terra, e demarcai-a, e então voltai a mim, e aqui vos lançarei as sortes perante o Senhor, em Siló.',
        'Foram, pois, aqueles homens, e passaram pela terra, e a demarcaram, em sete partes segundo as cidades, descrevendo-a num livro; e voltaram a Josué, ao arraial em Siló.',
        'Então Josué lhes lançou as sortes em Siló, perante o Senhor; e ali repartiu Josué a terra aos filhos de Israel, conforme às suas divisões.',
        'E tirou a sorte da tribo dos filhos de Benjamim, segundo as suas famílias; e coube-lhe o termo da sua sorte entre os filhos de Judá e os filhos de José.',
        'E o seu termo foi para o lado do norte, desde o Jordão; e sobe aquele termo ao lado de Jericó para o norte, e sobe pela montanha para o ocidente, terminando no deserto de Bete-Áven.',
        'E dali passa este termo a Luz, ao lado de Luz (que é Betel), para o sul; e desce a Atarote-Adar, ao pé do monte que está do lado do sul de Bete-Horom de baixo;',
        'E vai este termo e volta ao lado do ocidente para o sul do monte que está defronte de Bete-Horom, para o sul, terminando em Quiriate-Baal (que é Quiriate-Jearim), cidade dos filhos de Judá; esta é a sua extensão para o ocidente.',
        'E a sua extensão para o sul começa na extremidade de Quiriate-Jearim; e vai este termo ao ocidente e segue até à fonte das águas de Neftoa.',
        'E desce este termo até à extremidade do monte que está defronte do vale do filho de Hinom, que está no vale dos refains para o norte, e desce pelo vale de Hinom do lado dos jebuseus para o sul; e então desce a En-Rogel;',
        'E vai desde o norte, e chega a En-Semes; e dali sai a Gelilote, que está defronte da subida de Adumim, e desce à pedra de Boã, filho de Rúben;',
        'E passa até ao lado, defronte de Arabá, para o norte, e desce a Arabá.',
        'Passa mais este termo até ao lado de Bete-Hogla, para o norte, saindo esse termo na baía do Mar Salgado, para o norte, na extremidade do Jordão, para o sul; este é o termo do sul.',
        'E o Jordão será seu termo do lado do oriente; esta é a herança dos filhos de Benjamim, nos seus termos em redor, segundo as suas famílias.',
        'E as cidades da tribo dos filhos de Benjamim, segundo as suas famílias, são: Jericó, e Bete-Hogla, e Emeque-Queziz,',
        'E Bete-Arabá, e Zemaraim, e Betel,',
        'E Avim, e Pará, e Ofra,',
        'E Quefar-Amonai, e Ofni e Gaba: doze cidades e as suas aldeias;',
        'Gibeão, e Ramá e Beerote,',
        'E Mizpá, e Cefira e Moza,',
        'E Requém e Irpeel, e Tarala,',
        'E Zela, Elefe, e Jebus (esta é Jerusalém), Gibeá e Quiriate: catorze cidades com as suas aldeias; esta é a herança dos filhos de Benjamim, segundo as suas famílias.',
      ],
      [
        'E Saiu a segunda sorte a Simeão, para a tribo dos filhos de Simeão, segundo as suas famílias; e foi a sua herança no meio da herança dos filhos de Judá.',
        'E tiveram na sua herança: Berseba, e Seba e Moladá.',
        'E Hazar-Sual, e Balá, e Azem,',
        'E Eltolade, e Betul, e Hormá,',
        'E Ziclague, e Bete-Marcabote, e Hazar-Susa,',
        'E Bete-Lebaote, e Saruém; treze cidades e as suas aldeias.',
        'E Aim, e Rimom, e Eter, e Asã; quatro cidades e as suas aldeias.',
        'E todas as aldeias que havia em redor destas cidades, até Baalate-Ber (que é Ramá), do sul; esta é a herança da tribo dos filhos de Simeão, segundo as suas famílias.',
        'A herança dos filhos de Simeão foi tirada do quinhão dos de Judá, porquanto a herança dos filhos de Judá era demasiadamente grande para eles; pelo que os filhos de Simeão tiveram a sua herança no meio deles.',
        'E saiu a terceira sorte pelos filhos de Zebulom, segundo as suas famílias; e foi o termo da sua herança até Saride.',
        'E sobe o seu termo pelo ocidente a Maralá, e vai até Dabesete, e chega também até ao ribeiro que está defronte de Jocneão.',
        'E de Saride volta para o oriente, para o nascente do sol, até ao termo de Quislote-Tabor, sai a Daberate, e vai subindo a Jafia.',
        'E dali passa pelo oriente, para o nascente, a Gate-Hefer, em Ete-Cazim, chegando a Rimom-Metoar, que vai até Neá;',
        'E rodeando-a, passa o termo para o norte a Hanatom, chegando ao vale de Iftá-El,',
        'E Catate, Naalal, e Sinrom, e Idala, e Belém; doze cidades e as suas aldeias.',
        'Esta é a herança dos filhos de Zebulom, segundo as suas famílias; estas cidades e as suas aldeias.',
        'A quarta sorte saiu para Issacar; aos filhos de Issacar, segundo as suas famílias.',
        'E foi o seu termo Jizreel, e Quesulote e Suném,',
        'E Hafaraim, e Siom, e Anaarate,',
        'E Rabite e Quisiom, e Ebes,',
        'E Remete, e En-Ganim, e En-Hadá, e Bete-Pazez.',
        'E chega este termo até Tabor, e Saazima, e Bete-Semes; e vai terminar no Jordão; dezesseis cidades e as suas aldeias.',
        'Esta é a herança da tribo dos filhos de Issacar, segundo as suas famílias; estas cidades e as suas aldeias.',
        'E saiu a quinta sorte para a tribo dos filhos de Aser, segundo as suas famílias.',
        'E foi o seu termo Helcate, e Hali, e Béten, e Acsafe,',
        'E Alameleque, e Amade, e Misal; e chega ao Carmelo para o ocidente, e a Sior-Libnate;',
        'E volta para o nascente do sol a Bete-Dagom, e chega a Zebulom e ao vale de Iftá-El, ao norte de Bete-Emeque e de Neiel, e vem sair a Cabul, pela esquerda,',
        'E Hebrom, e Reobe, e Hamom, e Caná, até à grande Sidom.',
        'E volta este termo a Ramá, e até à forte cidade de Tiro; então torna este termo a Hosa, para terminar no mar, na região de Aczibe.',
        'E Umá, e Afeque, e Reobe; vinte e duas cidades e as suas aldeias.',
        'Esta é a herança da tribo dos filhos de Aser, segundo as suas famílias; estas cidades e as suas aldeias.',
        'E saiu a sexta sorte para os filhos de Naftali, segundo as suas famílias.',
        'E foi o seu termo desde Helefe e desde Alom em Zaananim, e Adami-Neguebe, e Jabneel, até Lacum, terminando no Jordão.',
        'E volta este termo pelo ocidente a Aznote-Tabor, e dali passa a Hucoque; e chega a Zebulom ao sul, e chega a Aser ao ocidente, e a Judá pelo Jordão, ao nascente do sol.',
        'E são as cidades fortificadas: Zidim, Zer, e Hamate, Racate e Quinerete,',
        'E Adama, e Ramá, e Hazor,',
        'E Quedes, e Edrei, e En-Hazor,',
        'E Irom, e Migdal-El, Horém e Bete-Anate, e Bete-Semes; dezenove cidades e as suas aldeias.',
        'Esta é a herança da tribo dos filhos de Naftali, segundo as suas famílias; estas cidades e as suas aldeias.',
        'A sétima sorte saiu para a tribo dos filhos de Dã, segundo as suas famílias.',
        'E foi o termo da sua herança, Sora, e Estaol, e Ir-Semes,',
        'E Saalabim, e Aijalom, e Itla,',
        'E Elom, e Timna, e Ecrom,',
        'E Elteque, e Gibetom, e Baalate,',
        'E Jeúde, e Bene-Beraque, e Gate-Rimom,',
        'E Me-Jarcom, e Racom, com o termo defronte de Jafo;',
        'Saiu, porém, pequeno termo aos filhos de Dã, pelo que subiram os filhos de Dã, e pelejaram contra Lesém, e a tomaram, e a feriram ao fio da espada, e a possuíram e habitaram nela; e a Lesém chamaram Dã, conforme ao nome de Dã seu pai.',
        'Esta é a herança da tribo dos filhos de Dã, segundo as suas famílias; estas cidades e as suas aldeias.',
        'Acabando, pois, de repartir a terra em herança segundo os seus termos, deram os filhos de Israel a Josué, filho de Num, herança no meio deles.',
        'Segundo o mandado do Senhor lhe deram a cidade que pediu, a Timnate-Sera, na montanha de Efraim; e reedificou aquela cidade, e habitou nela.',
        'Estas são as heranças que Eleazar, o sacerdote, e Josué, filho de Num, e os cabeças dos pais das famílias repartiram às tribos dos filhos de Israel, em herança, por sorte, em Siló, perante o Senhor, à porta da tenda da congregação. E assim acabaram de repartir a terra.',
      ],
      [
        'Falou mais o SENHOR a Josué, dizendo:',
        'Fala aos filhos de Israel, dizendo: Apartai para vós as cidades de refúgio, de que vos falei pelo ministério de Moisés,',
        'Para que fuja para ali o homicida, que matar alguma pessoa por engano, e não com intenção; para que vos sirvam de refúgio contra o vingador do sangue.',
        'E fugindo para alguma daquelas cidades, pôr-se-á à porta dela e exporá a sua causa aos ouvidos dos anciãos da tal cidade; então o tomarão consigo na cidade; e lhe darão lugar, para que habite com eles.',
        'E se o vingador do sangue o seguir, não entregarão na sua mão o homicida, porquanto não feriu a seu próximo com intenção, e não o odiou antes.',
        'E habitará na mesma cidade, até que compareça em juízo perante a congregação, até que morra o sumo sacerdote que houver naqueles dias; então o homicida voltará, e virá à sua cidade e à sua casa, à cidade de onde fugiu.',
        'Então designaram a Quedes na Galiléia, na montanha de Naftali, e a Siquém, na montanha de Efraim, e a Quiriate-Arba (esta é Hebrom), na montanha de Judá.',
        'E, além do Jordão, na direção de Jericó para o oriente, designaram a Bezer, no deserto, na campina da tribo de Rúben, e a Ramote, em Gileade da tribo de Gade, e a Golã, em Basã da tribo de Manassés.',
        'Estas são as cidades que foram designadas para todos os filhos de Israel, e para o estrangeiro que habitasse entre eles, para que se acolhesse a elas todo aquele que por engano matasse alguma pessoa, para que não morresse às mãos do vingador do sangue, até se apresentar diante da congregação.',
      ],
      [
        'Então os cabeças dos pais dos levitas se achegaram a Eleazar, o sacerdote, e a Josué, filho de Num, e aos cabeças dos pais das tribos dos filhos de Israel;',
        'E falaram-lhes em Siló, na terra de Canaã, dizendo: O Senhor ordenou, pelo ministério de Moisés, que se nos dessem cidades para habitar, e os seus arrabaldes para os nossos animais.',
        'Por isso os filhos de Israel deram aos levitas da sua herança, conforme a ordem do Senhor, as seguintes cidades e os seus arrabaldes.',
        'E saiu a sorte para as famílias dos coatitas; e aos filhos de Arão, o sacerdote, que eram dos levitas, tiveram por sorte da tribo de Judá, e da tribo de Simeão, e da tribo de Benjamim, treze cidades;',
        'E aos outros filhos de Coate couberam por sorte, das famílias da tribo de Efraim, e da tribo de Dã, e da meia tribo de Manassés, dez cidades;',
        'E aos filhos de Gérson couberam por sorte, das famílias da tribo de Issacar, e da tribo de Aser, e da tribo de Naftali, e da meia tribo de Manassés, em Basã, treze cidades;',
        'Aos filhos de Merari, segundo as suas famílias, da tribo de Rúben, e da tribo de Gade, e da tribo de Zebulom, doze cidades;',
        'E deram os filhos de Israel aos levitas estas cidades e os seus arrabaldes por sorte, como o Senhor ordenara pelo ministério de Moisés.',
        'Deram mais, da tribo dos filhos de Judá e da tribo dos filhos de Simeão, estas cidades, que por nome foram mencionadas,',
        'Para que fossem dos filhos de Arão, das famílias dos coatitas dos filhos de Levi; porquanto a primeira sorte foi sua.',
        'Assim lhes deram a cidade de Arba, do pai de Anaque (esta é Hebrom), no monte de Judá, e os seus arrabaldes ao redor.',
        'Porém o campo da cidade, e as suas aldeias, deram a Calebe, filho de Jefoné, por sua possessão.',
        'Assim aos filhos de Arão, o sacerdote, deram Hebrom, cidade do refúgio do homicida, e os seus arrabaldes, Libna e os seus arrabaldes;',
        'Jatir e os seus arrabaldes, e Estemoa e os seus arrabaldes;',
        'E Holom e os seus arrabaldes, e Debir e os seus arrabaldes;',
        'E Aim e os seus arrabaldes, e Jutá e os seus arrabaldes, e Bete-Semes e os seus arrabaldes; nove cidades destas duas tribos.',
        'E da tribo de Benjamim, Gibeão e os seus arrabaldes, Geba e os seus arrabaldes;',
        'Anatote e os seus arrabaldes, e Almom e os seus arrabaldes; quatro cidades.',
        'Todas as cidades dos sacerdotes, filhos de Arão, foram treze cidades e os seus arrabaldes.',
        'E as famílias dos filhos de Coate, levitas, que ficaram dos filhos de Coate, tiveram as cidades da sua sorte, da tribo de Efraim.',
        'E deram-lhes Siquém, cidade de refúgio do homicida, e os seus arrabaldes, no monte de Efraim, e Gezer e os seus arrabaldes;',
        'E Quibzaim e os seus arrabaldes, e Bete-Horom e os seus arrabaldes; quatro cidades.',
        'E da tribo de Dã, Elteque e os seus arrabaldes, Gibetom e os seus arrabaldes;',
        'Aijalom e os seus arrabaldes, Gate-Rimom e os seus arrabaldes; quatro cidades.',
        'E da meia tribo de Manassés, Taanaque e os seus arrabaldes, e Gate-Rimom e os seus arrabaldes; duas cidades.',
        'As cidades para as famílias dos demais filhos de Coate, foram dez e os seus arrabaldes.',
        'E aos filhos de Gérson, das famílias dos levitas, deram da meia tribo de Manassés, Golã, cidade de refúgio do homicida, em Basã, e os seus arrabaldes, e Beesterá e os seus arrabaldes; duas cidades.',
        'E da tribo de Issacar, Quisiom e os seus arrabaldes, Daberate e os seus arrabaldes,',
        'Jarmute e os seus arrabaldes, En-Ganim e os seus arrabaldes; quatro cidades.',
        'E da tribo de Aser, Misal e os seus arrabaldes, Abdom e os seus arrabaldes,',
        'Helcate e os seus arrabaldes, e Reobe e os seus arrabaldes;',
        'E da tribo de Naftali, Quedes, cidade de refúgio do homicida, na Galiléia, e os seus arrabaldes, e Hamote-Dor e os seus arrabaldes, e Cartã e os seus arrabaldes; três cidades.',
        'Todas as cidades dos gersonitas, segundo as suas famílias, foram treze cidades e os seus arrabaldes.',
        'E às famílias dos filhos de Merari, aos demais levitas, foram dadas, da tribo de Zebulom, Jocneão e os seus arrabaldes, Cartã e os seus arrabaldes,',
        'Dimna e os seus arrabaldes, Naalal e os seus arrabaldes; quatro cidades.',
        'E da tribo de Rúben, Bezer e os seus arrabaldes, e Jaza e os seus arrabaldes,',
        'Quedemote e os seus arrabaldes, e Mefaate e os seus arrabaldes; quatro cidades.',
        'E da tribo de Gade, Ramote, cidade de refúgio do homicida, em Gileade, e os seus arrabaldes, e Maanaim e os seus arrabaldes,',
        'Hesbom e os seus arrabaldes, Jazer e os seus arrabaldes; ao todo, quatro cidades.',
        'Todas estas cidades foram dos filhos de Merari, segundo as suas famílias, que ainda restavam das famílias dos dos levitas; e foi a sua sorte doze cidades.',
        'Todas as cidades dos levitas, no meio da herança dos filhos de Israel, foram quarenta e oito cidades e os seus arrabaldes.',
        'Estavam estas cidades, cada uma com os seus arrabaldes em redor delas; assim estavam todas estas cidades.',
        'Desta maneira deu o Senhor a Israel toda a terra que jurara dar a seus pais; e a possuíram e habitaram nela.',
        'E o Senhor lhes deu repouso de todos os lados, conforme a tudo quanto jurara a seus pais; e nenhum de todos os seus inimigos pôde resisti-los; todos os seus inimigos o Senhor entregou-lhes nas mãos.',
        'Palavra alguma falhou de todas as boas coisas que o Senhor falou à casa de Israel; tudo se cumpriu.',
      ],
      [
        'Então Josué chamou os rubenitas, e os gaditas, e a meia tribo de Manassés.',
        'E disse-lhes: Tudo quanto Moisés, o servo do Senhor, vos ordenou, guardastes; e à minha voz obedecestes em tudo quanto vos ordenei.',
        'A vossos irmãos por todo este tempo, até ao dia de hoje, não desamparastes; antes tivestes cuidado de guardar o mandamento do Senhor vosso Deus.',
        'Agora o Senhor vosso Deus deu repouso a vossos irmãos, como lhes tinha prometido; voltai-vos, pois, agora, e ide-vos às vossas tendas, à terra da vossa possessão, que Moisés, o servo do Senhor, vos deu além do Jordão.',
        'Tão-somente tende cuidado de guardar com diligência o mandamento e a lei que Moisés, o servo do Senhor, vos mandou: que ameis ao Senhor vosso Deus, e andeis em todos os seus caminhos, e guardeis os seus mandamentos, e vos achegueis a ele, e o sirvais com todo o vosso coração, e com toda a vossa alma.',
        'Assim Josué os abençoou, e despediu-os; e foram-se às suas tendas.',
        'Ora, Moisés dera herança em Basã à meia tribo de Manassés, porém à outra metade Josué deu herança entre seus irmãos aquém do Jordão para o ocidente; e enviando-os Josué também às suas tendas os abençoou;',
        'E falou-lhes, dizendo: Voltai-vos às vossas tendas com grandes riquezas, e com muitíssimo gado, com prata, e com ouro, e com metal, e com ferro, e com muitíssimas roupas; e com vossos irmãos reparti o despojo dos vossos inimigos.',
        'Assim os filhos de Rúben, e os filhos de Gade, e a meia tribo de Manassés voltaram, e separaram-se dos filhos de Israel, de Siló, que está na terra de Canaã, para irem à terra de Gileade, à terra da sua possessão, de que foram feitos possuidores, conforme a ordem do Senhor pelo ministério de Moisés.',
        'E, chegando eles aos limites do Jordão, ainda na terra de Canaã, ali os filhos de Rúben, e os filhos de Gade, e a meia tribo de Manassés edificaram um altar junto ao Jordão, um altar de grande aparência.',
        'E ouviram os filhos de Israel dizer: Eis que os filhos de Rúben, e os filhos de Gade, e a meia tribo de Manassés edificaram um altar diante da terra de Canaã, nos limites do Jordão, do lado dos filhos de Israel.',
        'Ouvindo isso os filhos de Israel, reuniu-se toda a congregação dos filhos de Israel em Siló, para saírem em guerra contra eles.',
        'E enviaram os filhos de Israel, aos filhos de Rúben, e aos filhos de Gade, e à meia tribo de Manassés, na terra de Gileade, a Finéias, filho de Eleazar, o sacerdote,',
        'E a dez príncipes com ele, de cada casa paterna um príncipe, de todas as tribos de Israel; e cada um era cabeça da casa de seus pais entre os milhares de Israel.',
        'E, indo eles aos filhos de Rúben, e aos filhos de Gade, e à meia tribo de Manassés, à terra de Gileade, falaram-lhes, dizendo:',
        'Assim diz toda a congregação do Senhor: Que transgressão é esta, que cometestes contra o Deus de Israel, deixando hoje de seguir ao Senhor, edificando-vos um altar, para vos rebelardes contra o Senhor?',
        'Foi-nos pouco a iniqüidade de Peor, de que ainda até o dia de hoje não estamos purificados, mesmo que tenha havido castigo na congregação do Senhor,',
        'Para que hoje deixais de seguir o Senhor? Será que rebelando-vos hoje contra o Senhor, amanhã ele se irará contra toda a congregação de Israel.',
        'Se é, porém, que a terra da vossa herança é imunda, passai-vos para a terra da possessão do Senhor, onde habita o tabernáculo do Senhor, e tomai possessão entre nós; mas não vos rebeleis contra o Senhor, nem tampouco vos rebeleis contra nós, edificando-vos um altar, além do altar do Senhor nosso Deus.',
        'Não cometeu Acã, filho de Zerá, transgressão no tocante ao anátema? Não veio ira sobre toda a congregação de Israel, de modo que aquele homem não morreu só, na sua iniqüidade?',
        'Então responderam os filhos de Rúben, e os filhos de Gade, e a meia tribo de Manassés, e disseram aos cabeças dos milhares de Israel:',
        'O Senhor Deus dos deuses, o Senhor Deus dos deuses, ele o sabe, e Israel mesmo o saberá. Se foi por rebeldia, ou por transgressão contra o Senhor, hoje não nos preserve;',
        'Se nós edificamos um altar para nos desviarmos do Senhor, ou para sobre ele oferecer holocausto e oferta de alimentos, ou sobre ele apresentar oferta pacífica, o Senhor mesmo de nós o requeira.',
        'E, se antes o não fizemos por receio disto, dizendo: Amanhã vossos filhos virão a falar a nossos filhos, dizendo: Que tendes vós com o Senhor Deus de Israel?',
        'Pois o Senhor pôs o Jordão por termo entre nós e vós, ó filhos de Rúben, e filhos de Gade; não tendes parte no Senhor; e assim bem poderiam vossos filhos fazer desistir a nossos filhos de temer ao Senhor.',
        'Por isso dissemos: Preparemo-nos agora, e edifiquemos um altar, não para holocausto, nem para sacrifício,',
        'Mas para que, entre nós e vós, e entre as nossas gerações depois de nós, nos seja em testemunho, para podermos fazer o serviço do Senhor diante dele com os nossos holocaustos, e com os nossos sacrifícios, e com as nossas ofertas pacíficas; para que vossos filhos não digam amanhã a nossos filhos: Não tendes parte no Senhor.',
        'Por isso dissemos: Quando suceder que amanhã assim nos digam a nós e às nossas gerações, então diremos: Vede o modelo do altar do Senhor que fizeram nossos pais, não para holocausto nem para sacrifício, porém para ser testemunho entre nós e vós.',
        'Nunca tal nos aconteça que nos rebelemos contra o Senhor, ou que hoje nós abandonássemos o Senhor, edificando altar para holocausto, oferta de alimentos ou sacrifício, fora do altar do Senhor nosso Deus, que está perante o seu tabernáculo.',
        'Ouvindo, pois, Finéias, o sacerdote, e os príncipes da congregação, e os cabeças dos milhares de Israel, que com eles estavam, as palavras que disseram os filhos de Rúben, e os filhos de Gade, e os filhos de Manassés, pareceu bem aos seus olhos.',
        'E disse Finéias, filho de Eleazar, o sacerdote, aos filhos de Rúben, e aos filhos de Gade, e aos filhos de Manassés: Hoje sabemos que o Senhor está no meio de nós; porquanto não cometestes transgressão contra o Senhor; agora livrastes os filhos de Israel da mão do Senhor.',
        'E Finéias filho de Eleazar, o sacerdote, com os príncipes, deixando os filhos de Rúben, e os filhos de Gade, voltaram da terra de Gileade à terra de Canaã, aos filhos de Israel, e trouxeram-lhes a resposta.',
        'E pareceu a resposta boa aos olhos dos filhos de Israel, e os filhos de Israel louvaram a Deus; e não falaram mais em subir à guerra contra eles em exército, para destruírem a terra em que habitavam os filhos de Rúben e os filhos de Gade.',
        'E os filhos de Rúben e os filhos de Gade deram ao altar o nome de Ede; para que seja testemunho entre nós que o Senhor é Deus.',
      ],
      [
        'E sucedeu que, muitos dias depois que o SENHOR dera repouso a Israel de todos os seus inimigos em redor, e sendo Josué já velho e entrado em dias,',
        'Chamou Josué a todo o Israel, aos seus anciãos, e aos seus cabeças, e aos seus juízes, e aos seus oficiais, e disse-lhes: Eu já sou velho e entrado em dias,',
        'E vós já tendes visto tudo quanto o Senhor vosso Deus fez a todas estas nações por causa de vós; porque o Senhor vosso Deus é que tem pelejado por vós.',
        'Vede que vos reparti por sorte, em herança às vossas tribos, estas nações que restam, bem como as nações que tenho destruído, desde o Jordão até o grande mar para o pôr-do-sol.',
        'E o Senhor vosso Deus as impelirá, e as expelirá de diante de vós; e vós possuireis a sua terra, como o Senhor vosso Deus vos tem prometido.',
        'Esforçai-vos, pois, muito para guardardes e para fazerdes tudo quanto está escrito no livro da lei de Moisés; para que dele não vos aparteis, nem para a direita nem para a esquerda;',
        'Para que não entreis no meio destas nações que ainda ficam convosco; e dos nomes de seus deuses não façais menção, nem por eles façais jurar, nem os sirvais, nem a eles vos inclineis,',
        'Mas ao Senhor vosso Deus vos apegareis, como fizestes até o dia de hoje;',
        'Pois o Senhor expulsou de diante de vós grandes e fortes nações; e, quanto a vós, ninguém vos tem podido resistir, até o dia de hoje.',
        'Um só homem dentre vós perseguirá a mil; pois é o Senhor vosso Deus que peleja por vós, como já vos tem falado.',
        'Portanto, guardai diligentemente as vossas almas, para amardes ao Senhor vosso Deus.',
        'Porque, se de algum modo vos desviardes, e vos apegardes ao restante destas nações que ainda ficou entre vós, e com elas vos aparentardes, e vós a elas entrardes, e elas a vós,',
        'Sabei certamente que o Senhor vosso Deus não continuará a expulsar estas nações de diante de vós, mas elas vos serão por laço e rede, e açoite às vossas ilhargas, e espinhos aos vossos olhos; até que pereçais desta boa terra que vos deu o Senhor vosso Deus.',
        'E eis que vou hoje pelo caminho de toda a terra; e vós bem sabeis, com todo o vosso coração, e com toda a vossa alma, que nem uma só palavra falhou de todas as boas coisas que falou de vós o Senhor vosso Deus; todas vos sobrevieram, nenhuma delas falhou.',
        'E será que, assim como sobre vós vieram todas estas boas coisas, que o Senhor vosso Deus vos disse, assim trará o Senhor sobre vós todas aquelas más coisas, até vos destruir de sobre a boa terra que vos deu o Senhor vosso Deus.',
        'Quando transgredirdes a aliança do Senhor vosso Deus, que vos tem ordenado, e fordes e servirdes a outros deuses, e a eles vos inclinardes, então a ira do Senhor sobre vós se acenderá, e logo perecereis de sobre a boa terra que vos deu.',
      ],
      [
        'Depois reuniu Josué todas as tribos de Israel em Siquém; e chamou os anciãos de Israel, e os seus cabeças, e os seus juízes, e os seus oficiais; e eles se apresentaram diante de Deus.',
        'Então Josué disse a todo o povo: Assim diz o Senhor Deus de Israel: Além do rio habitaram antigamente vossos pais, Terá, pai de Abraão e pai de Naor; e serviram a outros deuses.',
        'Eu, porém, tomei a vosso pai Abraão dalém do rio e o fiz andar por toda a terra de Canaã; também multipliquei a sua descendência e dei-lhe a Isaque.',
        'E a Isaque dei Jacó e Esaú; e a Esaú dei a montanha de Seir, para a possuir; porém, Jacó e seus filhos desceram para o Egito.',
        'Então enviei Moisés e Arão e feri ao Egito, como o fiz no meio deles; e depois vos tirei de lá.',
        'E, tirando eu a vossos pais do Egito, viestes ao mar; e os egípcios perseguiram a vossos pais com carros e com cavaleiros, até ao Mar Vermelho.',
        'E clamaram ao Senhor, que pôs uma escuridão entre vós e os egípcios, e trouxe o mar sobre eles, e os cobriu, e os vossos olhos viram o que eu fiz no Egito; depois habitastes no deserto muitos dias.',
        'Então eu vos trouxe à terra dos amorreus, que habitavam além do Jordão, os quais pelejaram contra vós; porém os entreguei nas vossas mãos, e possuístes a sua terra, e os destruí de diante de vós.',
        'Levantou-se também Balaque, filho de Zipor, rei dos moabitas e pelejou contra Israel; e mandou chamar a Balaão, filho de Beor, para que vos amaldiçoasse.',
        'Porém eu não quis ouvir a Balaão; pelo que ele vos abençoou grandemente e eu vos livrei da sua mão.',
        'E, passando vós o Jordão, e vindo a Jericó, os habitantes de Jericó pelejaram contra vós, os amorreus, e os perizeus, e os cananeus, e os heteus, e os girgaseus, e os heveus, e os jebuseus; porém os entreguei nas vossas mãos.',
        'E enviei vespões adiante de vós, que os expulsaram de diante de vós, como a ambos os reis dos amorreus; não com a tua espada nem com o teu arco.',
        'E eu vos dei a terra em que não trabalhastes, e cidades que não edificastes, e habitais nelas e comeis das vinhas e dos olivais que não plantastes.',
        'Agora, pois, temei ao Senhor, e servi-o com sinceridade e com verdade; e deitai fora os deuses aos quais serviram vossos pais além do rio e no Egito, e servi ao Senhor.',
        'Porém, se vos parece mal aos vossos olhos servir ao Senhor, escolhei hoje a quem sirvais; se aos deuses a quem serviram vossos pais, que estavam além do rio, ou aos deuses dos amorreus, em cuja terra habitais; porém eu e a minha casa serviremos ao Senhor.',
        'Então respondeu o povo, e disse: Nunca nos aconteça que deixemos ao Senhor para servirmos a outros deuses;',
        'Porque o Senhor é o nosso Deus; ele é o que nos fez subir, a nós e a nossos pais, da terra do Egito, da casa da servidão, e o que tem feito estes grandes sinais aos nossos olhos, e nos guardou por todo o caminho que andamos, e entre todos os povos pelo meio dos quais passamos.',
        'E o Senhor expulsou de diante de nós a todos esses povos, até ao amorreu, morador da terra; também nós serviremos ao Senhor, porquanto é nosso Deus.',
        'Então Josué disse ao povo: Não podereis servir ao Senhor, porquanto é Deus santo, é Deus zeloso, que não perdoará a vossa transgressão nem os vossos pecados.',
        'Se deixardes ao Senhor, e servirdes a deuses estranhos, então ele se tornará, e vos fará mal, e vos consumirá, depois de vos ter feito o bem.',
        'Então disse o povo a Josué: Não, antes ao Senhor serviremos.',
        'E Josué disse ao povo: Sois testemunhas contra vós mesmos de que escolhestes ao Senhor, para o servir. E disseram: Somos testemunhas.',
        'Deitai, pois, agora, fora aos deuses estranhos que há no meio de vós, e inclinai o vosso coração ao Senhor Deus de Israel.',
        'E disse o povo a Josué: Serviremos ao Senhor nosso Deus, e obedeceremos à sua voz.',
        'Assim, naquele dia fez Josué aliança com o povo e lhe pôs por estatuto e direito em Siquém.',
        'E Josué escreveu estas palavras no livro da lei de Deus; e tomou uma grande pedra, e a erigiu ali debaixo do carvalho que estava junto ao santuário do Senhor.',
        'E disse Josué a todo o povo: Eis que esta pedra nos será por testemunho, pois ela ouviu todas as palavras, que o Senhor nos tem falado; e também será testemunho contra vós, para que não mintais a vosso Deus.',
        'Então Josué enviou o povo, cada um para a sua herança.',
        'E depois destas coisas sucedeu que Josué, filho de Num, servo do Senhor, faleceu, com idade de cento e dez anos.',
        'E sepultaram-no no termo da sua herança, em Timnate-Sera, que está no monte de Efraim, para o norte do monte de Gaás.',
        'Serviu, pois, Israel ao Senhor todos os dias de Josué, e todos os dias dos anciãos que ainda sobreviveram muito tempo depois de Josué, e que sabiam todas as obras que o Senhor tinha feito a Israel.',
        'Também os ossos de José, que os filhos de Israel trouxeram do Egito, foram enterrados em Siquém, naquela parte do campo que Jacó comprara aos filhos de Hemor, pai de Siquém, por cem peças de prata, e que se tornara herança dos filhos de José.',
        'Faleceu também Eleazar, filho de Arão, e o sepultaram no outeiro de Finéias, seu filho, que lhe fora dado na montanha de Efraim.',
      ],
    ],
    livro: 'josue',
  },
  {
    abbrev: 'jz',
    capitulos: [
      [
        'E sucedeu, depois da morte de Josué, que os filhos de Israel perguntaram ao SENHOR, dizendo: Quem dentre nós primeiro subirá aos cananeus, para pelejar contra eles?',
        'E disse o Senhor: Judá subirá; eis que entreguei esta terra na sua mão.',
        'Então disse Judá a Simeão, seu irmão: Sobe comigo à minha herança. E pelejemos contra os cananeus, e também eu contigo subirei à tua herança. E Simeão partiu com ele.',
        'E subiu Judá, e o Senhor lhe entregou na sua mão os cananeus e os perizeus; e feriram deles, em Bezeque, a dez mil homens.',
        'E acharam Adoni-Bezeque em Bezeque, e pelejaram contra ele; e feriram aos cananeus e aos perizeus.',
        'Porém Adoni-Bezeque fugiu, mas o seguiram, e prenderam-no e cortaram-lhe os dedos polegares das mãos e dos pés.',
        'Então disse Adoni-Bezeque: Setenta reis, com os dedos polegares das mãos e dos pés cortados, apanhavam as migalhas debaixo da minha mesa; assim como eu fiz, assim Deus me pagou. E levaram-no a Jerusalém, e morreu ali.',
        'E os filhos de Judá pelejaram contra Jerusalém, e tomando-a, feriram-na ao fio da espada; e puseram fogo na cidade.',
        'E depois os filhos de Judá desceram a pelejar contra os cananeus, que habitavam nas montanhas, e no sul, e nas planícies.',
        'E partiu Judá contra os cananeus que habitavam em Hebrom (era porém outrora o nome de Hebrom, Quiriate-Arba), e feriram a Sesai, e a Aimã e Talmai.',
        'E dali partiu contra os moradores de Debir; e era outrora o nome de Debir, Quiriate-Sefer.',
        'E disse Calebe: Quem ferir a Quiriate-Sefer, e a tomar, lhe darei a minha filha Acsa por mulher.',
        'E tomou-a Otniel, filho de Quenaz, o irmão de Calebe, mais novo do que ele; e Calebe lhe deu a sua filha Acsa por mulher.',
        'E sucedeu que, indo ela a ele, o persuadiu que pedisse um campo a seu pai; e ela desceu do jumento, e Calebe lhe disse: Que é que tens?',
        'E ela lhe disse: Dá-me uma bênção; pois me deste uma terra seca, dá-me também fontes de águas. E Calebe lhe deu as fontes superiores e as fontes inferiores.',
        'Também os filhos do queneu, sogro de Moisés, subiram da cidade das palmeiras com os filhos de Judá ao deserto de Judá, que está ao sul de Arade, e foram, e habitaram com o povo.',
        'E foi Judá com Simeão, seu irmão, e feriram aos cananeus que habitavam em Zefate; e totalmente a destruíram, e chamou-se o nome desta cidade Hormá.',
        'Tomou mais Judá a Gaza com o seu termo, e a Ascalom com o seu termo, e a Ecrom com o seu termo.',
        'E estava o Senhor com Judá, e despovoou as montanhas; porém não expulsou aos moradores do vale, porquanto tinham carros de ferro.',
        'E deram Hebrom a Calebe, como Moisés o dissera; e dali expulsou os três filhos de Anaque.',
        'Porém os filhos de Benjamim não expulsaram os jebuseus que habitavam em Jerusalém; antes os jebuseus ficaram habitando com os filhos de Benjamim em Jerusalém, até ao dia de hoje,',
        'E subiu também a casa de José contra Betel, e foi o Senhor com eles.',
        'E a casa de José mandou espias a Betel, e foi antes o nome desta cidade Luz.',
        'E viram os espias a um homem, que saía da cidade, e lhe disseram: Ora, mostra-nos a entrada da cidade, e usaremos contigo de misericórdia.',
        'E, mostrando-lhes ele a entrada da cidade, feriram-na ao fio da espada; porém àquele homem e a toda a sua família deixaram ir.',
        'Então aquele homem se foi à terra dos heteus, e edificou uma cidade, e chamou o seu nome Luz; este é o seu nome até ao dia de hoje.',
        'Manassés não expulsou os habitantes de Bete-Seã, nem mesmo dos lugares da sua jurisdição; nem a Taanaque, com os lugares da sua jurisdição; nem os moradores de Dor, com os lugares da sua jurisdição; nem os moradores de Ibleão, com os lugares da sua jurisdição; nem os moradores de Megido, com os lugares da sua jurisdição; e resolveram os cananeus habitar na mesma terra.',
        'E sucedeu que, quando Israel cobrou mais forças, fez dos cananeus tributários; porém não os expulsou de todo.',
        'Tampouco expulsou Efraim os cananeus que habitavam em Gezer; antes os cananeus ficaram habitando com ele, em Gezer.',
        'Tampouco expulsou Zebulom os moradores de Quitrom, nem os moradores de Naalol; porém os cananeus ficaram habitando com ele, e foram tributários.',
        'Tampouco Aser expulsou os moradores de Aco, nem os moradores de Sidom; como nem de Alabe, nem de Aczibe, nem de Helba, nem de Afeque, nem de Reobe;',
        'Porém os aseritas habitaram no meio dos cananeus que habitavam na terra; porquanto não os expulsaram.',
        'Tampouco Naftali expulsou os moradores de Bete-Semes, nem os moradores de Bete-Anate; mas habitou no meio dos cananeus que habitavam na terra; porém lhes foram tributários os moradores de Bete-Semes e Bete-Anate.',
        'E os amorreus impeliram os filhos de Dã até às montanhas; porque nem os deixavam descer ao vale.',
        'Também os amorreus quiseram habitar nas montanhas de Heres, em Aijalom e em Saalbim; porém prevaleceu a mão da casa de José, e ficaram tributários.',
        'E foi o termo dos amorreus desde a subida de Acrabim, desde a penha, e dali para cima.',
      ],
      [
        'E subiu o anjo do SENHOR de Gilgal a Boquim, e disse: Do Egito vos fiz subir, e vos trouxe à terra que a vossos pais tinha jurado e disse: Nunca invalidarei a minha aliança convosco.',
        'E, quanto a vós, não fareis acordo com os moradores desta terra, antes derrubareis os seus altares; mas vós não obedecestes à minha voz. Por que fizestes isso?',
        'Assim também eu disse: Não os expulsarei de diante de vós; antes estarão como espinhos nas vossas ilhargas, e os seus deuses vos serão por laço.',
        'E sucedeu que, falando o anjo do Senhor estas palavras a todos os filhos de Israel, o povo levantou a sua voz e chorou.',
        'Por isso chamaram àquele lugar, Boquim; e sacrificaram ali ao Senhor.',
        'E havendo Josué despedido o povo foram-se os filhos de Israel, cada um à sua herança, para possuírem a terra.',
        'E serviu o povo ao Senhor todos os dias de Josué, e todos os dias dos anciãos que ainda sobreviveram depois de Josué, e viram toda aquela grande obra do Senhor, que fizera a Israel.',
        'Faleceu, porém, Josué, filho de Num, servo do Senhor, com a idade de cento e dez anos;',
        'E sepultaram-no no termo da sua herança, em Timnate-Heres, no monte de Efraim, para o norte do monte de Gaás.',
        'E foi também congregada toda aquela geração a seus pais, e outra geração após ela se levantou, que não conhecia ao Senhor, nem tampouco a obra que ele fizera a Israel.',
        'Então fizeram os filhos de Israel o que era mau aos olhos do Senhor; e serviram aos baalins.',
        'E deixaram ao Senhor Deus de seus pais, que os tirara da terra do Egito, e foram-se após outros deuses, dentre os deuses dos povos, que havia ao redor deles, e adoraram a eles; e provocaram o Senhor à ira.',
        'Porquanto deixaram ao Senhor, e serviram a Baal e a Astarote.',
        'Por isso a ira do Senhor se acendeu contra Israel, e os entregou na mão dos espoliadores que os despojaram; e os entregou na mão dos seus inimigos ao redor; e não puderam mais resistir diante dos seus inimigos.',
        'Por onde quer que saíam, a mão do Senhor era contra eles para mal, como o Senhor tinha falado, e como o Senhor lhes tinha jurado; e estavam em grande aflição.',
        'E levantou o Senhor juízes, que os livraram da mão dos que os despojaram.',
        'Porém tampouco ouviram aos juízes, antes prostituíram-se após outros deuses, e adoraram a eles; depressa se desviaram do caminho, por onde andaram seus pais, obedecendo os mandamentos do Senhor; mas eles assim não fizeram.',
        'E, quando o Senhor lhes levantava juízes, o Senhor era com o juiz, e os livrava da mão dos seus inimigos, todos os dias daquele juiz; porquanto o Senhor se compadecia deles pelo seu gemido, por causa dos que os oprimiam e afligiam.',
        'Porém sucedia que, falecendo o juiz, reincidiam e se corrompiam mais do que seus pais, andando após outros deuses, servindo-os, e adorando-os; nada deixavam das suas obras, nem do seu obstinado caminho.',
        'Por isso a ira do Senhor se acendeu contra Israel, e disse: Porquanto este povo transgrediu a minha aliança, que tinha ordenado a seus pais, e não deram ouvidos à minha voz,',
        'Tampouco desapossarei mais de diante deles a nenhuma das nações, que Josué deixou, quando morreu;',
        'Para por elas provar a Israel, se há de guardar, ou não, o caminho do Senhor, como seus pais o guardaram, para nele andar.',
        'Assim o Senhor deixou ficar aquelas nações, e não as desterrou logo, nem as entregou na mão de Josué.',
      ],
      [
        'Estas, pois, são as nações que o SENHOR deixou ficar, para por elas provar a Israel, a saber, a todos os que não sabiam de todas as guerras de Canaã.',
        'Tão-somente para que as gerações dos filhos de Israel delas soubessem (para lhes ensinar a guerra), pelo menos os que dantes não sabiam delas.',
        'Cinco príncipes dos filisteus, e todos os cananeus, e sidônios, e heveus que habitavam nas montanhas do Líbano desde o monte de Baal-Hermom, até à entrada de Hamate.',
        'Estes, pois, ficaram, para por eles provar a Israel, para saber se dariam ouvido aos mandamentos do Senhor, que ele tinha ordenado a seus pais, pelo ministério de Moisés.',
        'Habitando, pois, os filhos de Israel no meio dos cananeus, dos heteus, e amorreus, e perizeus, e heveus, e jebuseus,',
        'Tomaram de suas filhas para si por mulheres, e deram as suas filhas aos filhos deles; e serviram aos seus deuses.',
        'E os filhos de Israel fizeram o que era mau aos olhos do Senhor, e se esqueceram do Senhor seu Deus; e serviram aos baalins e a Astarote.',
        'Então a ira do SENHOR se acendeu contra Israel, e ele os vendeu na mão de Cusã-Risataim, rei da mesopotâmia; e os filhos de Israel serviram a Cusã-Risataim oito anos.',
        'E os filhos de Israel clamaram ao Senhor, e o Senhor levantou-lhes um libertador, que os libertou: Otniel, filho de Quenaz, irmão de Calebe, mais novo do que ele.',
        'E veio sobre ele o Espírito do Senhor, e julgou a Israel, e saiu à peleja; e o Senhor entregou na sua mão a Cusã-Risataim, rei da Síria; contra o qual prevaleceu a sua mão.',
        'Então a terra sossegou quarenta anos; e Otniel, filho de Quenaz, faleceu.',
        'Porém os filhos de Israel tornaram a fazer o que era mau aos olhos do Senhor; então o Senhor fortaleceu a Eglom, rei dos moabitas, contra Israel; porquanto fizeram o que era mau aos olhos do Senhor.',
        'E reuniu consigo os filhos de Amom e os amalequitas, e foi, e feriu a Israel, e tomaram a cidade das palmeiras.',
        'E os filhos de Israel serviram a Eglom, rei dos moabitas, dezoito anos.',
        'Então os filhos de Israel clamaram ao Senhor, e o Senhor lhes levantou um libertador, a Eúde, filho de Gera, filho de Jemim, homem canhoto. E os filhos de Israel enviaram pela sua mão um presente a Eglom, rei dos moabitas.',
        'E Eúde fez para si uma espada de dois fios, do comprimento de um côvado; e cingiu-a por baixo das suas vestes, à sua coxa direita.',
        'E levou aquele presente a Eglom, rei dos moabitas; e era Eglom homem muito gordo.',
        'E sucedeu que, acabando de entregar o presente, despediu a gente que o trouxera.',
        'Porém ele mesmo voltou das imagens de escultura que estavam ao pé de Gilgal, e disse: Tenho uma palavra secreta para ti, ó rei. O qual disse: Cala-te. E todos os que lhe assistiam saíram de diante dele.',
        'E Eúde entrou numa sala de verão, que o rei tinha só para si, onde estava sentado, e disse: Tenho, para dizer-te, uma palavra de Deus. E levantou-se da cadeira.',
        'Então Eúde estendeu a sua mão esquerda, e tirou a espada de sobre sua coxa direita, e lha cravou no ventre,',
        'De tal maneira que entrou até o cabo após a lâmina, e a gordura encerrou a lâmina (porque não tirou a espada do ventre); e saiu-lhe o excremento.',
        'Então Eúde saiu ao pátio, e fechou as portas da sala e as trancou.',
        'E, saindo ele, vieram os servos do rei, e viram, e eis que as portas da sala estavam fechadas; e disseram: Sem dúvida está cobrindo seus pés na recâmara da sala de verão.',
        'E, esperando até se alarmarem, eis que ele não abria as portas da sala; então tomaram a chave, e abriram, e eis ali seu senhor estendido morto em terra.',
        'E Eúde escapou, enquanto eles se demoravam; porque ele passou pelas imagens de escultura, e escapou para Seirá.',
        'E sucedeu que, chegando ele, tocou a buzina nas montanhas de Efraim, e os filhos de Israel desceram com ele das montanhas, e ele adiante deles.',
        'E disse-lhes: Segui-me, porque o Senhor vos tem entregue vossos inimigos, os moabitas, nas vossas mãos; e desceram após ele, e tomaram os vaus do Jordão contra Moabe, e a ninguém deixaram passar.',
        'E naquele tempo feriram dos moabitas uns dez mil homens, todos corpulentos, e todos homens valorosos; e não escapou nenhum.',
        'Assim foi subjugado Moabe naquele dia debaixo da mão de Israel; e a terra sossegou oitenta anos.',
        'Depois dele foi Sangar, filho de Anate, que feriu a seiscentos homens dos filisteus com uma aguilhada de bois; e também ele libertou a Israel.',
      ],
      [
        'Porém os filhos de Israel tornaram a fazer o que era mau aos olhos do SENHOR, depois de falecer Eúde.',
        'E vendeu-os o Senhor na mão de Jabim, rei de Canaã, que reinava em Hazor; e Sísera era o capitão do seu exército, o qual então habitava em Harosete dos gentios.',
        'Então os filhos de Israel clamaram ao Senhor, porquanto ele tinha novecentos carros de ferro, e por vinte anos oprimia violentamente os filhos de Israel.',
        'E Débora, mulher profetisa, mulher de Lapidote, julgava a Israel naquele tempo.',
        'Ela assentava-se debaixo das palmeiras de Débora, entre Ramá e Betel, nas montanhas de Efraim; e os filhos de Israel subiam a ela a juízo.',
        'E mandou chamar a Baraque, filho de Abinoão de Quedes de Naftali, e disse-lhe: Porventura o Senhor Deus de Israel não deu ordem, dizendo: Vai, e atrai gente ao monte Tabor, e toma contigo dez mil homens dos filhos de Naftali e dos filhos de Zebulom;',
        'E atrairei a ti para o ribeiro de Quisom, a Sísera, capitão do exército de Jabim, com os seus carros, e com a sua multidão; e o darei na tua mão.',
        'Então lhe disse Baraque: Se fores comigo, irei; porém, se não fores comigo, não irei.',
        'E disse ela: Certamente irei contigo, porém não será tua a honra da jornada que empreenderes; pois à mão de uma mulher o Senhor venderá a Sísera. E Débora se levantou, e partiu com Baraque para Quedes.',
        'Então Baraque convocou a Zebulom e a Naftali em Quedes, e subiu com dez mil homens após ele; e Débora subiu com ele.',
        'E Héber, queneu, se tinha apartado dos queneus, dos filhos de Hobabe, sogro de Moisés; e tinha estendido as suas tendas até ao carvalho de Zaanaim, que está junto a Quedes,',
        'E anunciaram a Sísera que Baraque, filho de Abinoão, tinha subido ao monte Tabor.',
        'E Sísera convocou todos os seus carros, novecentos carros de ferro, e todo o povo que estava com ele, desde Harosete dos gentios até ao ribeiro de Quisom.',
        'Então disse Débora a Baraque: Levanta-te, porque este é o dia em que o Senhor tem dado a Sísera na tua mão; porventura o Senhor não saiu adiante de ti? Baraque, pois, desceu do monte Tabor, e dez mil homens após ele.',
        'E o Senhor derrotou a Sísera, e a todos os seus carros, e a todo o seu exército ao fio da espada, diante de Baraque; e Sísera desceu do carro, e fugiu a pé.',
        'E Baraque perseguiu os carros, e o exército, até Harosete dos gentios; e todo o exército de Sísera caiu ao fio da espada, até não ficar um só.',
        'Porém Sísera fugiu a pé à tenda de Jael, mulher de Héber, queneu; porquanto havia paz entre Jabim, rei de Hazor, e a casa de Héber, queneu.',
        'E Jael saiu ao encontro de Sísera, e disse-lhe: Entra, senhor meu, entra aqui, não temas. Ele entrou na sua tenda, e ela o cobriu com uma coberta.',
        'Então ele lhe disse: Dá-me, peço-te, de beber um pouco de água, porque tenho sede. Então ela abriu um odre de leite, e deu-lhe de beber, e o cobriu.',
        'E ele lhe disse: Põe-te à porta da tenda; e há de ser que se alguém vier e te perguntar: Há aqui alguém? Responderás então: Não.',
        'Então Jael, mulher de Héber, tomou uma estaca da tenda, e lançou mão de um martelo, e chegou-se mansamente a ele, e lhe cravou a estaca na fonte, de sorte que penetrou na terra, estando ele, porém, num profundo sono, e já muito cansado; e assim morreu.',
        'E eis que, seguindo Baraque a Sísera, Jael lhe saiu ao encontro, e disse-lhe: Vem, e mostrar-te-ei o homem que buscas. E foi a ela, e eis que Sísera jazia morto, com a estaca na fonte.',
        'Assim Deus naquele dia sujeitou a Jabim, rei de Canaã, diante dos filhos de Israel.',
        'E continuou a mão dos filhos de Israel a pesar e a endurecer-se sobre Jabim, rei de Canaã; até que exterminaram a Jabim, rei de Canaã.',
      ],
      [
        'E cantou Débora e Baraque, filho de Abinoão, naquele mesmo dia, dizendo:',
        'Louvai ao Senhor pela vingança de Israel, quando o povo se ofereceu voluntariamente.',
        'Ouvi, reis; dai ouvidos, príncipes; eu, eu cantarei ao Senhor; salmodiarei ao Senhor Deus de Israel.',
        'Ó Senhor, saindo tu de Seir, caminhando tu desde o campo de Edom, a terra estremeceu; até os céus gotejaram; até as nuvens gotejaram águas.',
        'Os montes se derreteram diante do Senhor, e até Sinai diante do Senhor Deus de Israel.',
        'Nos dias de Sangar, filho de Anate, nos dias de Jael cessaram os caminhos; e os que andavam por veredas iam por caminhos torcidos.',
        'Cessaram as aldeias em Israel, cessaram; até que eu, Débora, me levantei, por mãe em Israel me levantei.',
        'E se escolhia deuses novos, logo a guerra estava às portas; via-se por isso escudo ou lança entre quarenta mil em Israel?',
        'Meu coração é para os legisladores de Israel, que voluntariamente se ofereceram entre o povo; bendizei ao Senhor.',
        'Vós os que cavalgais sobre jumentas brancas, que vos assentais em juízo, que andais pelo caminho, falai disto.',
        'Donde se ouve o estrondo dos flecheiros, entre os lugares onde se tiram águas, ali falai das justiças do Senhor, das justiças que fez às suas aldeias em Israel; então o povo do Senhor descia às portas.',
        'Desperta, desperta, Débora, desperta, desperta, entoa um cântico; levanta-te, Baraque, e leva presos os teus cativos, tu, filho de Abinoão.',
        'Então fez dominar sobre os nobres entre o povo, aos que restaram; fez-me o Senhor dominar sobre os poderosos.',
        'De Efraim saiu a sua raiz contra Amaleque; e depois de ti vinha Benjamim dentre os teus povos; de Maquir desceram os legisladores, e de Zebulom os que levaram a cana do escriba.',
        'Também os principais de Issacar foram com Débora; e como Issacar, assim também Baraque, foi enviado a pé para o vale; nas divisões de Rúben foram grandes as resoluções do coração.',
        'Por que ficaste tu entre os currais para ouvires os balidos dos rebanhos? Nas divisões de Rúben tiveram grandes esquadrinhações do coração.',
        'Gileade ficou além do Jordão, e Dã por que se deteve nos navios? Aser se assentou na beira dos mares, e ficou junto às suas baías.',
        'Zebulom é um povo que expôs a sua vida à morte, como também Naftali, nas alturas do campo.',
        'Vieram reis, pelejaram; então pelejaram os reis de Canaã em Taanaque, junto às águas de Megido; não tomaram despojo de prata.',
        'Desde os céus pelejaram; até as estrelas desde os lugares dos seus cursos pelejaram contra Sísera.',
        'O ribeiro de Quisom os arrastou, aquele antigo ribeiro, o ribeiro de Quisom. Pisaste, ó minha alma, à força.',
        'Então os cascos dos cavalos se despedaçaram; pelo galopar, o galopar dos seus valentes.',
        'Amaldiçoai a Meroz, diz o anjo do Senhor, acremente amaldiçoai aos seus moradores; porquanto não vieram ao socorro do Senhor, ao socorro do Senhor com os valorosos.',
        'Bendita seja entre as mulheres, Jael, mulher de Héber, o queneu; bendita seja entre as mulheres nas tendas.',
        'Água pediu ele, leite lhe deu ela; em prato de nobres lhe ofereceu manteiga.',
        'À estaca estendeu a sua mão esquerda, e ao martelo dos trabalhadores a sua direita; e matou a Sísera, e rachou-lhe a cabeça, quando lhe pregou e atravessou as fontes.',
        'Entre os seus pés se encurvou, caiu, ficou estirado; entre os seus pés se encurvou, caiu; onde se encurvou, ali ficou abatido.',
        'A mãe de Sísera olhava pela janela, e exclamava pela grade: Por que tarda em vir o seu carro? Por que se demoram os ruídos dos seus carros?',
        'As mais sábias das suas damas responderam; e até ela respondia a si mesma:',
        'Porventura não achariam e repartiriam despojos? Uma ou duas moças a cada homem? Para Sísera despojos de estofos coloridos, despojos de estofos coloridos bordados; de estofos coloridos bordados de ambos os lados como despojo para os pescoços.',
        'Assim, ó Senhor, pereçam todos os teus inimigos! Porém os que te amam sejam como o sol quando sai na sua força.',
        'E sossegou a terra quarenta anos.',
      ],
      [
        'Porém os filhos de Israel fizeram o que era mau aos olhos do SENHOR; e o SENHOR os deu nas mãos dos midianitas por sete anos.',
        'E, prevalecendo a mão dos midianitas sobre Israel, fizeram os filhos de Israel para si, por causa dos midianitas, as covas que estão nos montes, as cavernas e as fortificações.',
        'Porque sucedia que, semeando Israel, os midianitas e os amalequitas, e também os do oriente, contra ele subiam.',
        'E punham-se contra ele em campo, e destruíam os frutos da terra, até chegarem a Gaza; e não deixavam mantimento em Israel, nem ovelhas, nem bois, nem jumentos.',
        'Porque subiam com os seus gados e tendas; vinham como gafanhotos, em grande multidão que não se podia contar, nem a eles nem aos seus camelos; e entravam na terra, para a destruir.',
        'Assim Israel empobreceu muito pela presença dos midianitas; então os filhos de Israel clamaram ao Senhor.',
        'E sucedeu que, clamando os filhos de Israel ao Senhor por causa dos midianitas,',
        'Enviou o Senhor um profeta aos filhos de Israel, que lhes disse: Assim diz o Senhor Deus de Israel: Do Egito eu vos fiz subir, e vos tirei da casa da servidão;',
        'E vos livrei da mão dos egípcios, e da mão de todos quantos vos oprimiam; e os expulsei de diante de vós, e a vós dei a sua terra.',
        'E vos disse: Eu sou o Senhor vosso Deus; não temais aos deuses dos amorreus, em cuja terra habitais; mas não destes ouvidos à minha voz.',
        'Então o anjo do Senhor veio, e assentou-se debaixo do carvalho que está em Ofra, que pertencia a Joás, abiezrita; e Gideão, seu filho, estava malhando o trigo no lagar, para o salvar dos midianitas.',
        'Então o anjo do Senhor lhe apareceu, e lhe disse: O Senhor é contigo, homem valoroso.',
        'Mas Gideão lhe respondeu: Ai, Senhor meu, se o Senhor é conosco, por que tudo isto nos sobreveio? E que é feito de todas as suas maravilhas que nossos pais nos contaram, dizendo: Não nos fez o Senhor subir do Egito? Porém agora o Senhor nos desamparou, e nos deu nas mãos dos midianitas.',
        'Então o Senhor olhou para ele, e disse: Vai nesta tua força, e livrarás a Israel das mãos dos midianitas; porventura não te enviei eu?',
        'E ele lhe disse: Ai, Senhor meu, com que livrarei a Israel? Eis que a minha família é a mais pobre em Manassés, e eu o menor na casa de meu pai.',
        'E o Senhor lhe disse: Porquanto eu hei de ser contigo, tu ferirás aos midianitas como se fossem um só homem.',
        'E ele disse: Se agora tenho achado graça aos teus olhos, dá-me um sinal de que és tu que falas comigo.',
        'Rogo-te que daqui não te apartes, até que eu volte e traga o meu presente, e o ponha perante ti. E disse: Eu esperarei até que voltes.',
        'E entrou Gideão e preparou um cabrito e pães ázimos de um efa de farinha; a carne pôs num cesto e o caldo pôs numa panela; e trouxe-lho até debaixo do carvalho, e lho ofereceu.',
        'Porém o anjo de Deus lhe disse: Toma a carne e os pães ázimos, e põe-nos sobre esta penha e derrama-lhe o caldo. E assim fez.',
        'E o anjo do Senhor estendeu a ponta do cajado, que estava na sua mão, e tocou a carne e os pães ázimos; então subiu o fogo da penha, e consumiu a carne e os pães ázimos; e o anjo do Senhor desapareceu de seus olhos.',
        'Então viu Gideão que era o anjo do SENHOR e disse: Ah, Senhor DEUS, pois vi o anjo do SENHOR face a face.',
        'Porém o Senhor lhe disse: Paz seja contigo; não temas; não morrerás.',
        'Então Gideão edificou ali um altar ao SENHOR, e chamou-lhe: O SENHOR É PAZ; e ainda até o dia de hoje está em Ofra dos abiezritas.',
        'E aconteceu naquela mesma noite, que o Senhor lhe disse: Toma o boi que pertence a teu pai, a saber, o segundo boi de sete anos, e derruba o altar de Baal, que é de teu pai; e corta o bosque que está ao pé dele.',
        'E edifica ao Senhor teu Deus um altar no cume deste lugar forte, num lugar conveniente; e toma o segundo boi, e o oferecerás em holocausto com a lenha que cortares do bosque.',
        'Então Gideão tomou dez homens dentre os seus servos, e fez como o Senhor lhe dissera; e sucedeu que, temendo ele a casa de seu pai, e os homens daquela cidade, não o fez de dia, mas fê-lo de noite.',
        'Levantando-se, pois, os homens daquela cidade, de madrugada, eis que estava o altar de Baal derrubado, e o bosque estava ao pé dele, cortado; e o segundo boi oferecido no altar que fora edificado.',
        'E uns aos outros disseram: Quem fez esta coisa? E, esquadrinhando, e inquirindo, disseram: Gideão, o filho de Joás, fez esta coisa.',
        'Então os homens daquela cidade disseram a Joás: Tira para fora a teu filho; para que morra; pois derribou o altar de Baal, e cortou o bosque que estava ao pé dele.',
        'Porém Joás disse a todos os que se puseram contra ele: Contendereis vós por Baal? Livrá-lo-eis vós? Qualquer que por ele contender ainda esta manhã será morto; se é deus, por si mesmo contenda; pois derrubaram o seu altar.',
        'Por isso naquele dia lhe chamaram Jerubaal, dizendo: Baal contenda contra ele, pois derrubou o seu altar.',
        'E todos os midianitas e amalequitas, e os filhos do oriente se ajuntaram, e passaram, e acamparam no vale de Jizreel.',
        'Então o Espírito do SENHOR revestiu a Gideão, o qual tocou a buzina, e os abiezritas se ajuntaram após ele.',
        'E enviou mensageiros por toda a tribo de Manassés, que também se ajuntou após ele; também enviou mensageiros a Aser, e a Zebulom, e a Naftali, que saíram-lhe ao encontro.',
        'E disse Gideão a Deus: Se hás de livrar a Israel por minha mão, como disseste,',
        'Eis que eu porei um velo de lã na eira; se o orvalho estiver somente no velo, e toda a terra ficar seca, então conhecerei que hás de livrar a Israel por minha mão, como disseste.',
        'E assim sucedeu; porque no outro dia se levantou de madrugada, e apertou o velo; e do orvalho que espremeu do velo, encheu uma taça de água.',
        'E disse Gideão a Deus: Não se acenda contra mim a tua ira, se ainda falar só esta vez; rogo-te que só esta vez faça a prova com o velo; rogo-te que só o velo fique seco, e em toda a terra haja o orvalho.',
        'E Deus assim fez naquela noite; pois só o velo ficou seco, e sobre toda a terra havia orvalho.',
      ],
      [
        'Então Jerubaal (que é Gideão) se levantou de madrugada, e todo o povo que com ele havia, e se acamparam junto à fonte de Harode, de maneira que tinha o arraial dos midianitas para o norte, no vale, perto do outeiro de Moré.',
        'E disse o Senhor a Gideão: Muito é o povo que está contigo, para eu dar aos midianitas em sua mão; a fim de que Israel não se glorie contra mim, dizendo: A minha mão me livrou.',
        'Agora, pois, apregoa aos ouvidos do povo, dizendo: Quem for medroso e tímido, volte, e retire-se apressadamente das montanhas de Gileade. Então voltaram do povo vinte e dois mil, e dez mil ficaram.',
        'E disse o Senhor a Gideão: Ainda há muito povo; faze-os descer às águas, e ali os provarei; e será que, daquele de que eu te disser: Este irá contigo, esse contigo irá; porém de todo aquele, de que eu te disser: Este não irá contigo, esse não irá.',
        'E fez descer o povo às águas. Então o Senhor disse a Gideão: Qualquer que lamber as águas com a sua língua, como as lambe o cão, esse porás à parte; como também a todo aquele que se abaixar de joelhos a beber.',
        'E foi o número dos que lamberam, levando a mão à boca, trezentos homens; e todo o restante do povo se abaixou de joelhos a beber as águas.',
        'E disse o Senhor a Gideão: Com estes trezentos homens que lamberam as águas vos livrarei, e darei os midianitas na tua mão; portanto, todos os demais se retirem, cada um ao seu lugar.',
        'E o povo tomou na sua mão a provisão e as suas buzinas, e enviou a todos os outros homens de Israel cada um à sua tenda, porém os trezentos homens reteve; e estava o arraial dos midianitas embaixo, no vale.',
        'E sucedeu que, naquela mesma noite, o Senhor lhe disse: Levanta-te, e desce ao arraial, porque o tenho dado na tua mão.',
        'E, se ainda temes descer, desce tu e teu moço Purá, ao arraial;',
        'E ouvirás o que dizem, e então, fortalecidas as tuas mãos descerás ao arraial. Então desceu ele com o seu moço Purá até ao extremo das sentinelas que estavam no arraial.',
        'E os midianitas, os amalequitas, e todos os filhos do oriente jaziam no vale como gafanhotos em multidão; e eram inumeráveis os seus camelos, como a areia que há na praia do mar.',
        'Chegando, pois, Gideão, eis que estava contando um homem ao seu companheiro um sonho, e dizia: Eis que tive um sonho, eis que um pão de cevada torrado rodava pelo arraial dos midianitas, e chegava até à tenda, e a feriu, e caiu, e a transtornou de cima para baixo; e ficou caída.',
        'E respondeu o seu companheiro, e disse: Não é isto outra coisa, senão a espada de Gideão, filho de Joás, varão israelita. Deus tem dado na sua mão aos midianitas, e todo este arraial.',
        'E sucedeu que, ouvindo Gideão a narração deste sonho, e a sua explicação, adorou; e voltou ao arraial de Israel, e disse: Levantai-vos, porque o Senhor tem dado o arraial dos midianitas nas nossas mãos.',
        'Então dividiu os trezentos homens em três companhias; e deu-lhes a cada um, nas suas mãos, buzinas, e cântaros vazios, com tochas neles acesas.',
        'E disse-lhes: Olhai para mim, e fazei como eu fizer; e eis que, chegando eu à extremidade do arraial, será que, como eu fizer, assim fareis vós.',
        'Tocando eu a buzina, eu e todos os que comigo estiverem, então também vós tocareis a buzina ao redor de todo o arraial, e direis: Espada do Senhor, e de Gideão.',
        'Chegou, pois, Gideão, e os cem homens que com ele iam, ao extremo do arraial, ao princípio da vigília da meia-noite, havendo sido de pouco trocadas as guardas; então tocaram as buzinas, e quebraram os cântaros, que tinham nas mãos.',
        'Assim tocaram as três companhias as buzinas, e quebraram os cântaros; e tinham nas suas mãos esquerdas as tochas acesas, e nas suas mãos direitas as buzinas, para tocarem, e clamaram: Espada do Senhor, e de Gideão.',
        'E conservou-se cada um no seu lugar ao redor do arraial; então todo o exército pôs-se a correr e, gritando, fugiu.',
        'Tocando, pois, os trezentos as buzinas, o Senhor tornou a espada de um contra o outro, e isto em todo o arraial, que fugiu para Zererá, até Bete-Sita, até aos limites de Abel-Meolá, acima de Tabate.',
        'Então os homens de Israel, de Naftali, de Aser e de todo o Manassés foram convocados, e perseguiram aos midianitas.',
        'Também Gideão enviou mensageiros a todas as montanhas de Efraim, dizendo: Descei ao encontro dos midianitas, e tomai-lhes as águas até Bete-Bara, e também o Jordão. Convocados, pois, todos os homens de Efraim, tomaram-lhes as águas até Bete-Bara e o Jordão.',
        'E prenderam a dois príncipes dos midianitas, a Orebe e a Zeebe; e mataram a Orebe na penha de Orebe, e a Zeebe mataram no lagar de Zeebe, e perseguiram aos midianitas; e trouxeram as cabeças de Orebe e de Zeebe a Gideão, além do Jordão.',
      ],
      [
        'Então os homens de Efraim lhe disseram: Que é isto que nos fizeste, que não nos chamaste, quando foste pelejar contra os midianitas? E contenderam com ele fortemente.',
        'Porém ele lhes disse: Que mais fiz eu agora do que vós? Não são porventura os rabiscos de Efraim melhores do que a vindima de Abiezer?',
        'Deus vos deu na vossa mão os príncipes dos midianitas, Orebe e Zeebe; que mais pude eu fazer do que vós? Então a sua ira se abrandou para com ele, quando falou esta palavra.',
        'E, como Gideão veio ao Jordão, passou com os trezentos homens que com ele estavam, já cansados, mas ainda perseguindo.',
        'E disse aos homens de Sucote: Dai, peço-vos, alguns pedaços de pão ao povo, que segue as minhas pisadas; porque estão cansados, e eu vou ao encalço de Zeba e Salmuna, reis dos midianitas.',
        'Porém os príncipes de Sucote disseram: Estão já, Zeba e Salmuna, em tua mão, para que demos pão ao teu exército?',
        'Então disse Gideão: Pois quando o Senhor der na minha mão a Zeba e a Salmuna, trilharei a vossa carne com os espinhos do deserto, e com os abrolhos.',
        'E dali subiu a Penuel, e falou-lhes da mesma maneira; e os homens de Penuel lhe responderam como os homens de Sucote lhe haviam respondido.',
        'Por isso também falou aos homens de Penuel, dizendo: Quando eu voltar em paz, derribarei esta torre.',
        'Estavam, pois, Zeba e Salmuna em Carcor, e os seus exércitos com eles, uns quinze mil homens, todos os que restaram do exército dos filhos do oriente; e os que caíram foram cento e vinte mil homens, que puxavam da espada.',
        'E subiu Gideão pelo caminho dos que habitavam em tendas, para o oriente de Nobá e Jogbeá; e feriu aquele exército, porquanto o exército estava descuidado.',
        'E fugiram Zeba e Salmuna; porém ele os perseguiu, e tomou presos a ambos os reis dos midianitas, a Zeba e a Salmuna, e afugentou a todo o exército.',
        'Voltando, pois, Gideão, filho de Joás, da peleja, antes do nascer do sol,',
        'Tomou preso a um moço dos homens de Sucote, e lhe fez perguntas; o qual lhe deu por escrito os nomes dos príncipes de Sucote, e dos seus anciãos, setenta e sete homens.',
        'Então veio aos homens de Sucote, e disse: Vede aqui a Zeba e a Salmuna, a respeito dos quais desprezivelmente me escarnecestes, dizendo: Estão já, Zeba e Salmuna, na tua mão, para que demos pão aos teus homens, já cansados?',
        'E tomou os anciãos daquela cidade, e os espinhos do deserto, e os abrolhos; e com eles ensinou aos homens de Sucote.',
        'E derrubou a torre de Penuel, e matou os homens da cidade.',
        'Depois perguntou a Zeba e a Salmuna: Que homens eram os que matastes em Tabor? E disseram: Como és tu, assim eram eles; cada um parecia filho de rei.',
        'Então disse ele: Meus irmãos eram, filhos de minha mãe; vive o Senhor, que, se os tivésseis deixado com vida, eu não vos mataria.',
        'E disse a Jeter, seu primogênito: Levanta-te, mata-os. Porém o moço não puxou da sua espada, porque temia; porquanto ainda era jovem.',
        'Então disseram Zeba e Salmuna: Levanta-te, e acomete-nos; porque, qual o homem, tal a sua valentia. Levantou-se, pois, Gideão, e matou a Zeba e a Salmuna, e tomou os ornamentos que estavam nos pescoços dos seus camelos.',
        'Então os homens de Israel disseram a Gideão: Domina sobre nós, tanto tu, como teu filho e o filho de teu filho; porquanto nos livraste da mão dos midianitas.',
        'Porém Gideão lhes disse: Sobre vós eu não dominarei, nem tampouco meu filho sobre vós dominará; o Senhor sobre vós dominará.',
        'E disse-lhes mais Gideão: Uma petição vos farei: Dá-me, cada um de vós, os pendentes do seu despojo (porque tinham pendentes de ouro, porquanto eram ismaelitas).',
        'E disseram eles: De boa vontade os daremos. E estenderam uma capa, e cada um deles deitou ali um pendente do seu despojo.',
        'E foi o peso dos pendentes de ouro, que pediu, mil e setecentos siclos de ouro, afora os ornamentos, e as cadeias, e as vestes de púrpura que traziam os reis dos midianitas, e afora as coleiras que os camelos traziam ao pescoço.',
        'E fez Gideão dele um éfode, e colocou-o na sua cidade, em Ofra; e todo o Israel prostituiu-se ali após ele; e foi por tropeço a Gideão e à sua casa.',
        'Assim foram abatidos os midianitas diante dos filhos de Israel, e nunca mais levantaram a sua cabeça; e sossegou a terra quarenta anos nos dias de Gideão.',
        'E foi Jerubaal, filho de Joás, e habitou em sua casa.',
        'E teve Gideão setenta filhos, que procederam dele, porque tinha muitas mulheres.',
        'E sua concubina, que estava em Siquém, lhe deu à luz também um filho; e pôs-lhe por nome Abimeleque.',
        'E faleceu Gideão, filho de Joás, numa boa velhice; e foi sepultado no sepulcro de seu pai Joás, em Ofra dos abiezritas.',
        'E sucedeu que, como Gideão faleceu, os filhos de Israel tornaram a se prostituir após os baalins; e puseram a Baal-Berite por deus.',
        'E assim os filhos de Israel não se lembraram do Senhor seu Deus, que os livrara da mão de todos os seus inimigos ao redor.',
        'Nem usaram de beneficência com a casa de Jerubaal, a saber, de Gideão, conforme a todo o bem que ele havia feito a Israel.',
      ],
      [
        'E Abimeleque, filho de Jerubaal, foi a Siquém, aos irmãos de sua mãe, e falou-lhes e a toda a geração da casa do pai de sua mãe, dizendo:',
        'Falai, peço-vos, aos ouvidos de todos os cidadãos de Siquém: Qual é melhor para vós, que setenta homens, todos os filhos de Jerubaal, dominem sobre vós, ou que um homem sobre vós domine? Lembrai-vos também de que sou osso vosso e carne vossa.',
        'Então os irmãos de sua mãe falaram acerca dele perante os ouvidos de todos os cidadãos de Siquém todas aquelas palavras; e o coração deles se inclinou a seguir Abimeleque, porque disseram: É nosso irmão.',
        'E deram-lhe setenta peças de prata, da casa de Baal-Berite; e com elas alugou Abimeleque uns homens ociosos e levianos, que o seguiram.',
        'E veio à casa de seu pai, a Ofra, e matou a seus irmãos, os filhos de Jerubaal, setenta homens, sobre uma pedra. Porém Jotão, filho menor de Jerubaal, ficou, porque se tinha escondido.',
        'Então se ajuntaram todos os cidadãos de Siquém, e toda a casa de Milo; e foram, e constituíram a Abimeleque rei, junto ao carvalho alto que está perto de Siquém.',
        'E, dizendo-o a Jotão, foi e pôs-se no cume do monte de Gerizim, e levantou a sua voz, e clamou e disse-lhes: Ouvi-me, cidadãos de Siquém, e Deus vos ouvirá a vós;',
        'Foram uma vez as árvores a ungir para si um rei, e disseram à oliveira: Reina tu sobre nós.',
        'Porém a oliveira lhes disse: Deixaria eu a minha gordura, que Deus e os homens em mim prezam, e iria pairar sobre as árvores?',
        'Então disseram as árvores à figueira: Vem tu, e reina sobre nós.',
        'Porém a figueira lhes disse: Deixaria eu a minha doçura, o meu bom fruto, e iria pairar sobre as árvores?',
        'Então disseram as árvores à videira: Vem tu, e reina sobre nós.',
        'Porém a videira lhes disse: Deixaria eu o meu mosto, que alegra a Deus e aos homens, e iria pairar sobre as árvores?',
        'Então todas as árvores disseram ao espinheiro: Vem tu, e reina sobre nós.',
        'E disse o espinheiro às árvores: Se, na verdade, me ungis por rei sobre vós, vinde, e confiai-vos debaixo da minha sombra; mas, se não, saia fogo do espinheiro que consuma os cedros do Líbano.',
        'Agora, pois, se é que em verdade e sinceridade agistes, fazendo rei a Abimeleque, e se bem fizestes para com Jerubaal e para com a sua casa, e se com ele usastes conforme ao merecimento das suas mãos',
        '(Porque meu pai pelejou por vós, e desprezou a sua vida, e vos livrou da mão dos midianitas;',
        'Porém vós hoje vos levantastes contra a casa de meu pai, e matastes a seus filhos, setenta homens, sobre uma pedra; e a Abimeleque, filho da sua serva, fizestes reinar sobre os cidadãos de Siquém, porque é vosso irmão);',
        'Pois, se em verdade e sinceridade usastes com Jerubaal e com a sua casa hoje, alegrai-vos com Abimeleque, e também ele se alegre convosco.',
        'Mas, se não, saia fogo de Abimeleque, e consuma aos cidadãos de Siquém, e a casa de Milo; e saia fogo dos cidadãos de Siquém, e da casa de Milo, que consuma a Abimeleque.',
        'Então partiu Jotão, e fugiu e foi para Beer; e ali habitou por medo de Abimeleque, seu irmão.',
        'Havendo, pois, Abimeleque dominado três anos sobre Israel,',
        'Enviou Deus um mau espírito entre Abimeleque e os cidadãos de Siquém; e estes se houveram aleivosamente contra Abimeleque;',
        'Para que a violência feita aos setenta filhos de Jerubaal viesse, e o seu sangue caísse sobre Abimeleque, seu irmão, que os matara, e sobre os cidadãos de Siquém, que fortaleceram as mãos dele para matar a seus irmãos;',
        'E os cidadãos de Siquém puseram contra ele quem lhe armasse emboscadas sobre os cumes dos montes; e a todo aquele que passava pelo caminho junto a eles o assaltavam; e contou-se isso a Abimeleque.',
        'Veio também Gaal, filho de Ebede, com seus irmãos, e passaram a Siquém; e os cidadãos de Siquém confiaram nele.',
        'E saíram ao campo, e vindimaram as suas vinhas, e pisaram as uvas, e fizeram festas; e foram à casa de seu deus, e comeram, e beberam, e amaldiçoaram a Abimeleque.',
        'E disse Gaal, filho de Ebede: Quem é Abimeleque, e quem é Siquém, para que o sirvamos? Não é porventura filho de Jerubaal? E não é Zebul o seu mordomo? Servi antes aos homens de Hamor, pai de Siquém; pois, por que razão serviríamos nós a ele?',
        'Ah! se este povo estivera na minha mão, eu expulsaria a Abimeleque. E diria a Abimeleque: Multiplica o teu exército, e sai.',
        'E, ouvindo Zebul, o maioral da cidade, as palavras de Gaal, filho de Ebede, se acendeu a sua ira;',
        'E enviou astutamente mensageiros a Abimeleque, dizendo: Eis que Gaal, filho de Ebede, e seus irmãos vieram a Siquém, e eis que eles estão sublevando esta cidade contra ti.',
        'Levanta-te, pois, de noite, tu e o povo que tiveres contigo, e põe emboscadas no campo.',
        'E levanta-te pela manhã ao sair o sol, e dá de golpe sobre a cidade; e eis que, saindo contra ti, ele e o povo que tiver com ele, faze-lhe como puderes.',
        'Levantou-se, pois, Abimeleque, e todo o povo que com ele havia, de noite, e puseram emboscadas a Siquém, com quatro tropas.',
        'E Gaal, filho de Ebede, saiu, e pôs-se à entrada da porta da cidade; e Abimeleque, e todo o povo que com ele havia, se levantou das emboscadas.',
        'E, vendo Gaal aquele povo, disse a Zebul: Eis que desce gente dos cumes dos montes. Zebul, ao contrário, lhe disse: As sombras dos montes vês como se fossem homens.',
        'Porém Gaal ainda tornou a falar, e disse: Eis ali desce gente do meio da terra, e uma tropa vem do caminho do carvalho de Meonenim.',
        'Então lhe disse Zebul: Onde está agora a tua boca, com a qual dizias: Quem é Abimeleque, para que o sirvamos? Não é este porventura o povo que desprezaste? Sai pois, peço-te, e peleja contra ele.',
        'E saiu Gaal à vista dos cidadãos de Siquém, e pelejou contra Abimeleque.',
        'E Abimeleque o perseguiu porquanto fugiu de diante dele; e muitos feridos caíram até à entrada da porta da cidade.',
        'E Abimeleque ficou em Aruma. E Zebul expulsou a Gaal e a seus irmãos, para que não pudessem habitar em Siquém.',
        'E sucedeu no dia seguinte que o povo saiu ao campo; disto foi avisado Abimeleque.',
        'Então tomou o povo, e o repartiu em três tropas, e pôs emboscadas no campo; e olhou, e eis que o povo saía da cidade, e levantou-se contra ele, e o feriu.',
        'Porque Abimeleque, e as tropas que com ele havia, romperam de improviso, e pararam à entrada da porta da cidade; e as outras duas tropas deram de improviso sobre todos quantos estavam no campo, e os feriram.',
        'E Abimeleque pelejou contra a cidade todo aquele dia, e tomou a cidade, e matou o povo que nela havia; e assolou a cidade, e a semeou de sal.',
        'O que ouvindo todos os cidadãos da torre de Siquém, entraram na fortaleza, na casa do deus Berite.',
        'E contou-se a Abimeleque que todos os cidadãos da torre de Siquém se haviam congregado.',
        'Subiu, pois, Abimeleque ao monte Salmom, ele e todo o povo que com ele havia; e Abimeleque tomou na sua mão um machado, e cortou um ramo de árvore, e o levantou, e pô-lo ao seu ombro, e disse ao povo, que com ele havia: O que me vistes fazer apressai-vos a fazê-lo assim como eu.',
        'Assim, pois, cada um de todo o povo, também cortou o seu ramo e seguiu a Abimeleque; e pondo os ramos junto da fortaleza, queimaram-na a fogo com os que nela estavam, de modo que todos os da torre de Siquém morreram, uns mil homens e mulheres.',
        'Então Abimeleque foi a Tebes e a sitiou, e a tomou.',
        'Havia, porém, no meio da cidade uma torre forte; e todos os homens e mulheres, e todos os cidadãos da cidade se refugiaram nela, e fecharam após si as portas, e subiram ao eirado da torre.',
        'E Abimeleque veio até à torre, e a combateu; e chegou-se até à porta da torre, para a incendiar.',
        'Porém uma mulher lançou um pedaço de uma mó sobre a cabeça de Abimeleque; e quebrou-lhe o crânio.',
        'Então chamou logo ao moço, que levava as suas armas, e disse-lhe: Desembainha a tua espada, e mata-me; para que não se diga de mim: Uma mulher o matou. E o moço o atravessou e ele morreu.',
        'Vendo, pois, os homens de Israel que Abimeleque já era morto, foram-se cada um para o seu lugar.',
        'Assim Deus fez tornar sobre Abimeleque o mal que tinha feito a seu pai, matando a seus setenta irmãos.',
        'Como também todo o mal dos homens de Siquém fez tornar sobre a cabeça deles; e a maldição de Jotão, filho de Jerubaal, veio sobre eles.',
      ],
      [
        'E depois de Abimeleque, se levantou, para livrar a Israel, Tola, filho de Puá, filho de Dodo, homem de Issacar; e habitava em Samir, na montanha de Efraim.',
        'E julgou a Israel vinte e três anos; e morreu, e foi sepultado em Samir.',
        'E depois dele se levantou Jair, gileadita, e julgou a Israel vinte e dois anos.',
        'E tinha este trinta filhos, que cavalgavam sobre trinta jumentos; e tinham trinta cidades, a que chamaram Havote-Jair, até ao dia de hoje; as quais estão na terra de Gileade.',
        'E morreu Jair, e foi sepultado em Camom.',
        'Então tornaram os filhos de Israel a fazer o que era mau aos olhos do Senhor, e serviram aos baalins, e a Astarote, e aos deuses da Síria, e aos deuses de Sidom, e aos deuses de Moabe, e aos deuses dos filhos de Amom, e aos deuses dos filisteus; e deixaram ao Senhor, e não o serviram.',
        'E a ira do Senhor se acendeu contra Israel; e vendeu-os nas mãos dos filisteus, e nas mãos dos filhos de Amom.',
        'E naquele mesmo ano oprimiram e vexaram aos filhos de Israel; dezoito anos oprimiram a todos os filhos de Israel que estavam além do Jordão, na terra dos amorreus, que está em Gileade.',
        'Até os filhos de Amom passaram o Jordão, para pelejar também contra Judá, e contra Benjamim, e contra a casa de Efraim; de modo que Israel ficou muito angustiado.',
        'Então os filhos de Israel clamaram ao Senhor, dizendo: Contra ti havemos pecado, visto que deixamos a nosso Deus, e servimos aos baalins.',
        'Porém o Senhor disse aos filhos de Israel: Porventura dos egípcios, e dos amorreus, e dos filhos de Amom, e dos filisteus,',
        'E dos sidônios, e dos amalequitas, e dos maonitas, que vos oprimiam, quando a mim clamastes, não vos livrei das suas mãos?',
        'Contudo vós me deixastes a mim, e servistes a outros deuses; pelo que não vos livrarei mais.',
        'Ide, e clamai aos deuses que escolhestes; que eles vos livrem no tempo do vosso aperto.',
        'Mas os filhos de Israel disseram ao Senhor: Pecamos; faze-nos conforme a tudo quanto te parecer bem aos teus olhos; tão-somente te rogamos que nos livres nesta vez.',
        'E tiraram os deuses alheios do meio de si, e serviram ao Senhor; então se angustiou a sua alma por causa da desgraça de Israel.',
        'E os filhos de Amom se reuniram e se acamparam em Gileade; e também os de Israel se congregaram, e se acamparam em Mizpá.',
        'Então o povo e os príncipes de Gileade disseram uns aos outros: Quem será o homem que começará a pelejar contra os filhos de Amom? Ele será por cabeça de todos os moradores de Gileade.',
      ],
      [
        'Era então Jefté, o gileadita, homem valoroso, porém filho de uma prostituta; mas Gileade gerara a Jefté.',
        'Também a mulher de Gileade lhe deu filhos, e, sendo os filhos desta mulher já grandes, expulsaram a Jefté, e lhe disseram: Não herdarás na casa de nosso pai, porque és filho de outra mulher.',
        'Então Jefté fugiu de diante de seus irmãos, e habitou na terra de Tobe; e homens levianos se ajuntaram a Jefté, e saíam com ele.',
        'E aconteceu que, depois de algum tempo, os filhos de Amom pelejaram contra Israel.',
        'E sucedeu que, como os filhos de Amom pelejassem contra Israel, foram os anciãos de Gileade buscar a Jefté na terra de Tobe.',
        'E disseram a Jefté: Vem, e sê o nosso chefe; para que combatamos contra os filhos de Amom.',
        'Porém Jefté disse aos anciãos de Gileade: Porventura não me odiastes a mim, e não me expulsastes da casa de meu pai? Por que, pois, agora viestes a mim, quando estais em aperto?',
        'E disseram os anciãos de Gileade a Jefté: Por isso tornamos a ti, para que venhas conosco, e combatas contra os filhos de Amom; e nos sejas por chefe sobre todos os moradores de Gileade.',
        'Então Jefté disse aos anciãos de Gileade: Se me levardes de volta para combater contra os filhos de Amom, e o Senhor mos der diante de mim, então eu vos serei por chefe?',
        'E disseram os anciãos de Gileade a Jefté: O Senhor será testemunha entre nós, e assim o faremos conforme a tua palavra.',
        'Assim Jefté foi com os anciãos de Gileade, e o povo o pôs por chefe e príncipe sobre si; e Jefté falou todas as suas palavras perante o Senhor em Mizpá.',
        'E enviou Jefté mensageiros ao rei dos filhos de Amom, dizendo: Que há entre mim e ti, que vieste a mim a pelejar contra a minha terra?',
        'E disse o rei dos filhos de Amom aos mensageiros de Jefté: É porque, saindo Israel do Egito, tomou a minha terra, desde Arnom até Jaboque, e ainda até ao Jordão: Restitui-ma agora, em paz.',
        'Porém Jefté prosseguiu ainda em enviar mensageiros ao rei dos filhos de Amom,',
        'Dizendo-lhe: Assim diz Jefté: Israel não tomou, nem a terra dos moabitas, nem a terra dos filhos de Amom.',
        'Porque, subindo Israel do Egito, andou pelo deserto até ao Mar Vermelho, e chegou até Cades.',
        'E Israel enviou mensageiros ao rei dos edomitas, dizendo: Rogo-te que me deixes passar pela tua terra. Porém o rei dos edomitas não lhe deu ouvidos; enviou também ao rei dos moabitas, o qual igualmente não consentiu; e assim Israel ficou em Cades.',
        'Depois andou pelo deserto e rodeou a terra dos edomitas e a terra dos moabitas, e veio do nascente do sol à terra dos moabitas, e alojou-se além de Arnom; porém não entrou nos limites dos moabitas, porque Arnom é limite dos moabitas.',
        'Mas Israel enviou mensageiros a Siom, rei dos amorreus, rei de Hesbom; e disse-lhe Israel: Deixa-nos, peço-te, passar pela tua terra até ao meu lugar.',
        'Porém Siom não confiou em Israel para este passar nos seus limites; antes Siom ajuntou todo o seu povo, e se acamparam em Jasa, e combateu contra Israel.',
        'E o Senhor Deus de Israel deu a Siom, com todo o seu povo, na mão de Israel, que os feriu; e Israel tomou por herança toda a terra dos amorreus que habitavam naquela região.',
        'E por herança tomaram todos os limites dos amorreus, desde Arnom até Jaboque, e desde o deserto até ao Jordão.',
        'Assim o Senhor Deus de Israel desapossou os amorreus de diante do seu povo de Israel; e os possuirias tu?',
        'Não possuirias tu aquilo que Quemós, teu deus, desapossasse de diante de ti? Assim possuiremos nós todos quantos o Senhor nosso Deus desapossar de diante de nós.',
        'Agora, pois, és tu ainda melhor do que Balaque, filho de Zipor, rei dos moabitas? Porventura contendeu ele em algum tempo com Israel, ou pelejou alguma vez contra ele?',
        'Enquanto Israel habitou trezentos anos em Hesbom e nas suas vilas, e em Aroer e nas suas vilas, em todas as cidades que estão ao longo de Arnom, por que o não recuperastes naquele tempo?',
        'Tampouco pequei eu contra ti! Porém tu usas mal comigo em pelejar contra mim; o Senhor, que é juiz julgue hoje entre os filhos de Israel e entre os filhos de Amom.',
        'Porém o rei dos filhos de Amom não deu ouvidos às palavras que Jefté lhe enviou.',
        'Então o Espírito do Senhor veio sobre Jefté, e atravessou ele por Gileade e Manassés, passando por Mizpá de Gileade, e de Mizpá de Gileade passou até aos filhos de Amom.',
        'E Jefté fez um voto ao Senhor, e disse: Se totalmente deres os filhos de Amom na minha mão,',
        'Aquilo que, saindo da porta de minha casa, me vier ao encontro, voltando eu dos filhos de Amom em paz, isso será do Senhor, e o oferecerei em holocausto.',
        'Assim Jefté passou aos filhos de Amom, a combater contra eles; e o Senhor os deu na sua mão.',
        'E os feriu com grande mortandade, desde Aroer até chegar a Minite, vinte cidades, e até Abel-Queramim; assim foram subjugados os filhos de Amom diante dos filhos de Israel.',
        'Vindo, pois, Jefté a Mizpá, à sua casa, eis que a sua filha lhe saiu ao encontro com adufes e com danças; e era ela a única filha; não tinha ele outro filho nem filha.',
        'E aconteceu que, quando a viu, rasgou as suas vestes, e disse: Ah! filha minha, muito me abateste, e estás entre os que me turbam! Porque eu abri a minha boca ao Senhor, e não tornarei atrás.',
        'E ela lhe disse: Meu pai, tu deste a palavra ao Senhor, faze de mim conforme o que prometeste; pois o Senhor te vingou dos teus inimigos, os filhos de Amom.',
        'Disse mais a seu pai: Concede-me isto: Deixa-me por dois meses que vá, e desça pelos montes, e chore a minha virgindade, eu e as minhas companheiras.',
        'E disse ele: Vai. E deixou-a ir por dois meses; então foi ela com as suas companheiras, e chorou a sua virgindade pelos montes.',
        'E sucedeu que, ao fim de dois meses, tornou ela para seu pai, o qual cumpriu nela o seu voto que tinha feito; e ela não conheceu homem; e daí veio o costume de Israel,',
        'Que as filhas de Israel iam de ano em ano lamentar, por quatro dias, a filha de Jefté, o gileadita.',
      ],
      [
        'Então se convocaram os homens de Efraim, e passaram para o norte, e disseram a Jefté: Por que passaste a combater contra os filhos de Amom, e não nos chamaste para ir contigo? Queimaremos a fogo a tua casa contigo.',
        'E Jefté lhes disse: Eu e o meu povo tivemos grande contenda com os filhos de Amom; e chamei-vos, e não me livrastes da sua mão;',
        'E, vendo eu que não me livráveis, arrisquei a minha vida, e passei contra os filhos de Amom, e o Senhor mos entregou nas mãos; por que, pois, subistes vós hoje, para combater contra mim?',
        'E ajuntou Jefté a todos os homens de Gileade, e combateu contra Efraim; e os homens de Gileade feriram a Efraim; porque este dissera-lhe: Fugitivos sois de Efraim, vós gileaditas que habitais entre Efraim e Manassés,',
        'Porque tomaram os gileaditas aos efraimitas os vaus do Jordão; e sucedeu que, quando algum dos fugitivos de Efraim dizia: Deixai-me passar; então os gileaditas perguntavam: És tu efraimita? E dizendo ele: Não,',
        'Então lhe diziam: Dize, pois, Chibolete; porém ele dizia: Sibolete; porque não o podia pronunciar bem; então pegavam dele, e o degolavam nos vaus do Jordão; e caíram de Efraim naquele tempo quarenta e dois mil.',
        'E Jefté julgou a Israel seis anos; e Jefté, o gileadita, faleceu, e foi sepultado numa das cidades de Gileade.',
        'E depois dele julgou a Israel Ibzã de Belém.',
        'E tinha este trinta filhos, e trinta filhas que casou fora; e trinta filhas trouxe de fora para seus filhos; e julgou a Israel sete anos.',
        'Então faleceu Ibzã, e foi sepultado em Belém.',
        'E depois dele julgou a Israel Elom, o zebulonita; e julgou a Israel dez anos.',
        'E faleceu Elom, o zebulonita, e foi sepultado em Aijalom, na terra de Zebulom.',
        'E depois dele julgou a Israel Abdom, filho de Hilel, o piratonita.',
        'E tinha este quarenta filhos, e trinta netos, que cavalgavam sobre setenta jumentos; e julgou a Israel oito anos.',
        'Então faleceu Abdom, filho de Hilel, o piratonita; e foi sepultado em Piratom, na terra de Efraim, no monte dos amalequitas.',
      ],
      [
        'E os filhos de Israel tornaram a fazer o que era mau aos olhos do SENHOR, e o SENHOR os entregou na mão dos filisteus por quarenta anos.',
        'E havia um homem de Zorá, da tribo de Dã, cujo nome era Manoá; e sua mulher, sendo estéril, não tinha filhos.',
        'E o anjo do Senhor apareceu a esta mulher, e disse-lhe: Eis que agora és estéril, e nunca tens concebido; porém conceberás, e terás um filho.',
        'Agora, pois, guarda-te de beber vinho, ou bebida forte, ou comer coisa imunda.',
        'Porque eis que tu conceberás e terás um filho sobre cuja cabeça não passará navalha; porquanto o menino será nazireu de Deus desde o ventre; e ele começará a livrar a Israel da mão dos filisteus.',
        'Então a mulher entrou, e falou a seu marido, dizendo: Um homem de Deus veio a mim, cuja aparência era semelhante a de um anjo de Deus, terribilíssima; e não lhe perguntei donde era, nem ele me disse o seu nome.',
        'Porém disse-me: Eis que tu conceberás e terás um filho; agora pois, não bebas vinho, nem bebida forte, e não comas coisa imunda; porque o menino será nazireu de Deus, desde o ventre até ao dia da sua morte.',
        'Então Manoá orou ao Senhor, e disse: Ah! Senhor meu, rogo-te que o homem de Deus, que enviaste, ainda venha para nós outra vez e nos ensine o que devemos fazer ao menino que há de nascer.',
        'E Deus ouviu a voz de Manoá; e o anjo de Deus veio outra vez à mulher, e ela estava no campo, porém não estava com ela seu marido Manoá.',
        'Apressou-se, pois, a mulher, e correu, e noticiou-o a seu marido, e disse-lhe: Eis que aquele homem que veio a mim o outro dia me apareceu.',
        'Então Manoá levantou-se, e seguiu a sua mulher, e foi àquele homem, e disse-lhe: És tu aquele homem que falou a esta mulher? E disse: Eu sou.',
        'Então disse Manoá: Cumpram-se as tuas palavras; mas qual será o modo de viver e o serviço do menino?',
        'E disse o anjo do Senhor a Manoá: De tudo quanto eu disse à mulher se guardará ela.',
        'De tudo quanto procede da videira não comerá, nem vinho nem bebida forte beberá, nem coisa imunda comerá; tudo quanto lhe tenho ordenado guardará.',
        'Então Manoá disse ao anjo do Senhor: Ora deixa que te detenhamos, e te preparemos um cabrito.',
        'Porém o anjo do Senhor disse a Manoá: Ainda que me detenhas, não comerei de teu pão; e se fizeres holocausto o oferecerás ao Senhor. Porque não sabia Manoá que era o anjo do Senhor.',
        'E disse Manoá ao anjo do Senhor: Qual é o teu nome, para que, quando se cumprir a tua palavra, te honremos?',
        'E o anjo do Senhor lhe disse: Por que perguntas assim pelo meu nome, visto que é maravilhoso?',
        'Então Manoá tomou um cabrito e uma oferta de alimentos, e os ofereceu sobre uma penha ao Senhor: e houve-se o anjo maravilhosamente, observando-o Manoá e sua mulher.',
        'E sucedeu que, subindo a chama do altar para o céu, o anjo do Senhor subiu na chama do altar; o que vendo Manoá e sua mulher, caíram em terra sobre seus rostos.',
        'E nunca mais apareceu o anjo do Senhor a Manoá, nem a sua mulher; então compreendeu Manoá que era o anjo do Senhor.',
        'E disse Manoá à sua mulher: Certamente morreremos, porquanto temos visto a Deus.',
        'Porém sua mulher lhe disse: Se o Senhor nos quisesse matar, não aceitaria da nossa mão o holocausto e a oferta de alimentos, nem nos mostraria tudo isto, nem nos deixaria ouvir tais coisas neste tempo.',
        'Depois teve esta mulher um filho, a quem pôs o nome de Sansão; e o menino cresceu, e o Senhor o abençoou.',
        'E o Espírito do SENHOR começou a incitá-lo de quando em quando para o campo de Maané-Dã, entre Zorá e Estaol.',
      ],
      [
        'E desceu Sansão a Timnate; e, vendo em Timnate uma mulher das filhas dos filisteus,',
        'Subiu, e declarou-o a seu pai e a sua mãe, e disse: Vi uma mulher em Timnate, das filhas dos filisteus; agora, pois, tomai-ma por mulher.',
        'Porém seu pai e sua mãe lhe disseram: Não há, porventura, mulher entre as filhas de teus irmãos, nem entre todo o meu povo, para que tu vás tomar mulher dos filisteus, daqueles incircuncisos? E disse Sansão a seu pai: Toma-me esta, porque ela agrada aos meus olhos.',
        'Mas seu pai e sua mãe não sabiam que isto vinha do Senhor; pois buscava ocasião contra os filisteus; porquanto naquele tempo os filisteus dominavam sobre Israel.',
        'Desceu, pois, Sansão com seu pai e com sua mãe a Timnate; e, chegando às vinhas de Timnate eis que um filho de leão, rugindo, lhe saiu ao encontro.',
        'Então o Espírito do Senhor se apossou dele tão poderosamente que despedaçou o leão, como quem despedaça um cabrito, sem ter nada na sua mão; porém nem a seu pai nem a sua mãe deu a saber o que tinha feito.',
        'E desceu, e falou àquela mulher, e ela agradou aos olhos de Sansão.',
        'E depois de alguns dias voltou ele para tomá-la; e, apartando-se do caminho para ver o corpo do leão morto, eis que nele havia um enxame de abelhas com mel.',
        'E tomou-o nas suas mãos, e foi andando e comendo dele; e foi a seu pai e a sua mãe, e deu-lhes do mel, e comeram; porém não lhes deu a saber que tomara o mel do corpo do leão.',
        'Descendo, pois, seu pai àquela mulher, fez Sansão ali um banquete; porque assim os moços costumavam fazer.',
        'E sucedeu que, como o vissem, trouxeram trinta companheiros para estarem com ele.',
        'Disse-lhes, pois, Sansão: Eu vos darei um enigma para decifrar; e, se nos sete dias das bodas o decifrardes e descobrirdes, eu vos darei trinta lençóis e trinta mudas de roupas.',
        'E, se não puderdes decifrar, vós me dareis a mim trinta lençóis e as trinta mudas de roupas. E eles lhe disseram: Dá-nos o teu enigma a decifrar, para que o ouçamos.',
        'Então lhes disse: Do comedor saiu comida, e do forte saiu doçura. E em três dias não puderam decifrar o enigma.',
        'E sucedeu que, ao sétimo dia, disseram à mulher de Sansão: Persuade a teu marido que nos declare o enigma, para que porventura não queimemos a fogo a ti e à casa de teu pai; chamastes-nos aqui para vos apossardes do que é nosso, não é assim?',
        'E a mulher de Sansão chorou diante dele, e disse: Tão-somente me desprezas, e não me amas; pois deste aos filhos do meu povo um enigma para decifrar, e ainda não o declaraste a mim. E ele lhe disse: Eis que nem a meu pai nem a minha mãe o declarei, e to declararia a ti?',
        'E chorou diante dele os sete dias em que celebravam as bodas; sucedeu, pois, que ao sétimo dia lho declarou, porquanto o importunava; então ela declarou o enigma aos filhos do seu povo.',
        'Disseram, pois, a Sansão os homens daquela cidade, ao sétimo dia, antes de se pôr o sol: Que coisa há mais doce do que o mel? E que coisa há mais forte do que o leão? E ele lhes disse: Se vós não lavrásseis com a minha novilha, nunca teríeis descoberto o meu enigma.',
        'Então o Espírito do Senhor tão poderosamente se apossou dele, que desceu aos ascalonitas, e matou deles trinta homens, e tomou as suas roupas, e deu as mudas de roupas aos que declararam o enigma; porém acendeu-se a sua ira, e subiu à casa de seu pai.',
        'E a mulher de Sansão foi dada ao seu companheiro que antes o acompanhava.',
      ],
      [
        'E aconteceu, depois de alguns dias, que, na sega do trigo, Sansão visitou a sua mulher, com um cabrito, e disse: Entrarei na câmara de minha mulher. Porém o pai dela não o deixou entrar.',
        'E disse-lhe seu pai: Por certo pensava eu que de todo a desprezavas; de sorte que a dei ao teu companheiro; porém não é sua irmã mais nova, mais formosa do que ela? Toma-a, pois, em seu lugar.',
        'Então Sansão disse acerca deles: Inocente sou esta vez para com os filisteus, quando lhes fizer algum mal.',
        'E foi Sansão, e pegou trezentas raposas; e, tomando tochas, as virou cauda a cauda, e lhes pôs uma tocha no meio de cada duas caudas.',
        'E chegou fogo às tochas, e largou-as na seara dos filisteus; e assim abrasou os molhos com a sega do trigo, e as vinhas e os olivais.',
        'Então perguntaram os filisteus: Quem fez isto? E responderam: Sansão, o genro do timnita, porque lhe tomou a sua mulher, e a deu a seu companheiro. Então subiram os filisteus, e queimaram a fogo a ela e a seu pai.',
        'Então lhes disse Sansão: É assim que fazeis? Pois, havendo-me vingado eu de vós, então cessarei.',
        'E feriu-os com grande ferimento, pernas juntamente com coxa; e desceu, e habitou na fenda da rocha de Etã.',
        'Então os filisteus subiram, e acamparam-se contra Judá, e estenderam-se por Leí.',
        'E perguntaram-lhes os homens de Judá: Por que subistes contra nós? E eles responderam: Subimos para amarrar a Sansão, para lhe fazer a ele como ele nos fez a nós.',
        'Então três mil homens de Judá desceram até a fenda da rocha de Etã, e disseram a Sansão: Não sabias tu que os filisteus dominam sobre nós? Por que, pois, nos fizeste isto? E ele lhes disse: Assim como eles me fizeram a mim, eu lhes fiz a eles.',
        'E disseram-lhe: Descemos para te amarrar e te entregar nas mãos dos filisteus. Então Sansão lhes disse: Jurai-me que vós mesmos não me acometereis.',
        'E eles lhe falaram, dizendo: Não, mas fortemente te amarraremos, e te entregaremos nas mãos deles; porém de maneira nenhuma te mataremos. E amarraram-no com duas cordas novas e fizeram-no subir da rocha.',
        'E, vindo ele a Leí, os filisteus lhe saíram ao encontro, jubilando; porém o Espírito do Senhor poderosamente se apossou dele, e as cordas que ele tinha nos braços se tornaram como fios de linho que se queimaram no fogo, e as suas amarraduras se desfizeram das suas mãos.',
        'E achou uma queixada fresca de um jumento, e estendeu a sua mão, e tomou-a, e feriu com ela mil homens.',
        'Então disse Sansão: Com uma queixada de jumento, montões sobre montões; com uma queixada de jumento feri a mil homens.',
        'E aconteceu que, acabando ele de falar, lançou a queixada da sua mão; e chamou aquele lugar Ramate-Leí.',
        'E como tivesse grande sede, clamou ao Senhor, e disse: Pela mão do teu servo tu deste esta grande salvação; morrerei eu pois agora de sede, e cairei na mão destes incircuncisos?',
        'Então Deus fendeu uma cavidade que estava na queixada; e saiu dela água, e bebeu; e recobrou o seu espírito e reanimou-se; por isso chamou aquele lugar: A fonte do que clama, que está em Leí até ao dia de hoje.',
        'E julgou a Israel, nos dias dos filisteus, vinte anos.',
      ],
      [
        'E foi Sansão a Gaza, e viu ali uma mulher prostituta, e entrou a ela.',
        'E foi dito aos gazitas: Sansão entrou aqui. Cercaram-no, e toda a noite lhe puseram espias à porta da cidade; porém toda a noite estiveram quietos, dizendo: Até à luz da manhã esperaremos; então o mataremos.',
        'Porém Sansão deitou-se até à meia-noite, e à meia-noite se levantou, e arrancou as portas da entrada da cidade com ambas as umbreiras, e juntamente com a tranca as tomou, pondo-as sobre os ombros; e levou-as para cima até ao cume do monte que está defronte de Hebrom.',
        'E depois disto aconteceu que se afeiçoou a uma mulher do vale de Soreque, cujo nome era Dalila.',
        'Então os príncipes dos filisteus subiram a ela, e lhe disseram: Persuade-o, e vê em que consiste a sua grande força, e como poderíamos assenhorear-nos dele e amarrá-lo, para assim o afligirmos; e te daremos, cada um de nós, mil e cem moedas de prata.',
        'Disse, pois, Dalila a Sansão: Declara-me, peço-te, em que consiste a tua grande força, e com que poderias ser amarrado para te poderem afligir.',
        'Disse-lhe Sansão: Se me amarrassem com sete vergas de vimes frescos, que ainda não estivessem secos, então me enfraqueceria, e seria como qualquer outro homem.',
        'Então os príncipes dos filisteus lhe trouxeram sete vergas de vimes frescos, que ainda não estavam secos; e amarraram-no com elas.',
        'E o espia estava com ela na câmara interior. Então ela lhe disse: Os filisteus vêm sobre ti, Sansão. Então quebrou as vergas de vimes, como se quebra o fio da estopa ao cheiro do fogo; assim não se soube em que consistia a sua força.',
        'Então disse Dalila a Sansão: Eis que zombaste de mim, e me disseste mentiras; ora declara-me agora com que poderias ser amarrado.',
        'E ele disse: Se me amarrassem fortemente com cordas novas, que ainda não houvessem sido usadas, então me enfraqueceria, e seria como qualquer outro homem.',
        'Então Dalila tomou cordas novas, e o amarrou com elas, e disse-lhe: Os filisteus vêm sobre ti, Sansão. E o espia estava na recâmara interior. Então as quebrou de seus braços como a um fio.',
        'E disse Dalila a Sansão: Até agora zombaste de mim, e me disseste mentiras; declara-me pois, agora, com que poderias ser amarrado? E ele lhe disse: Se teceres sete tranças dos cabelos da minha cabeça com os liços da teia.',
        'E ela as fixou com uma estaca, e disse-lhe: Os filisteus vêm sobre ti, Sansão: Então ele despertou do seu sono, e arrancou a estaca das tranças tecidas, juntamente com o liço da teia.',
        'Então ela lhe disse: Como dirás: Tenho-te amor, não estando comigo o teu coração? Já três vezes zombaste de mim, e ainda não me declaraste em que consiste a tua força.',
        'E sucedeu que, importunando-o ela todos os dias com as suas palavras, e molestando-o, a sua alma se angustiou até a morte.',
        'E descobriu-lhe todo o seu coração, e disse-lhe: Nunca passou navalha pela minha cabeça, porque sou nazireu de Deus desde o ventre de minha mãe; se viesse a ser rapado, ir-se-ia de mim a minha força, e me enfraqueceria, e seria como qualquer outro homem.',
        'Vendo, pois, Dalila que já lhe descobrira todo o seu coração, mandou chamar os príncipes dos filisteus, dizendo: Subi esta vez, porque agora me descobriu ele todo o seu coração. E os príncipes dos filisteus subiram a ter com ela, trazendo com eles o dinheiro.',
        'Então ela o fez dormir sobre os seus joelhos, e chamou a um homem, e rapou-lhe as sete tranças do cabelo de sua cabeça; e começou a afligi-lo, e retirou-se dele a sua força.',
        'E disse ela: Os filisteus vêm sobre ti, Sansão. E despertou ele do seu sono, e disse: Sairei ainda esta vez como dantes, e me sacudirei. Porque ele não sabia que já o Senhor se tinha retirado dele.',
        'Então os filisteus pegaram nele, e arrancaram-lhe os olhos, e fizeram-no descer a Gaza, e amarraram-no com duas cadeias de bronze, e girava ele um moinho no cárcere.',
        'E o cabelo da sua cabeça começou a crescer, como quando foi rapado.',
        'Então os príncipes dos filisteus se ajuntaram para oferecer um grande sacrifício ao seu deus Dagom, e para se alegrarem, e diziam: Nosso deus nos entregou nas mãos a Sansão, nosso inimigo.',
        'Semelhantemente, vendo-o o povo, louvava ao seu deus; porque dizia: Nosso deus nos entregou nas mãos o nosso inimigo, e ao que destruía a nossa terra, e ao que multiplicava os nossos mortos.',
        'E sucedeu que, alegrando-se-lhes o coração, disseram: Chamai a Sansão, para que brinque diante de nós. E chamaram a Sansão do cárcere, que brincava diante deles, e fizeram-no estar em pé entre as colunas.',
        'Então disse Sansão ao moço que o tinha pela mão: Guia-me para que apalpe as colunas em que se sustém a casa, para que me encoste a elas.',
        'Ora estava a casa cheia de homens e mulheres; e também ali estavam todos os príncipes dos filisteus; e sobre o telhado havia uns três mil homens e mulheres, que estavam vendo Sansão brincar.',
        'Então Sansão clamou ao SENHOR, e disse: Senhor DEUS, peço-te que te lembres de mim, e fortalece-me agora só esta vez, ó Deus, para que de uma vez me vingue dos filisteus, pelos meus dois olhos.',
        'Abraçou-se, pois, Sansão com as duas colunas do meio, em que se sustinha a casa, e arrimou-se sobre elas, com a sua mão direita numa, e com a sua esquerda na outra.',
        'E disse Sansão: Morra eu com os filisteus. E inclinou-se com força, e a casa caiu sobre os príncipes e sobre todo o povo que nela havia; e foram mais os mortos que matou na sua morte do que os que matara em sua vida.',
        'Então seus irmãos desceram, e toda a casa de seu pai, e tomaram-no, e subiram com ele, e sepultaram-no entre Zorá e Estaol, no sepulcro de Manoá, seu pai. Ele julgou a Israel vinte anos.',
      ],
      [
        'E havia um homem da montanha de Efraim, cujo nome era Mica.',
        'O qual disse à sua mãe: As mil e cem moedas de prata que te foram tiradas, por cuja causa lançaste maldições, e de que também me falaste, eis que esse dinheiro está comigo; eu o tomei. Então lhe disse sua mãe: Bendito do Senhor seja meu filho.',
        'Assim restituiu as mil e cem moedas de prata à sua mãe; porém sua mãe disse: Inteiramente tenho dedicado este dinheiro da minha mão ao Senhor, para meu filho fazer uma imagem de escultura e uma de fundição; de sorte que agora to tornarei a dar.',
        'Porém ele restituiu aquele dinheiro à sua mãe; e sua mãe tomou duzentas moedas de prata, e as deu ao ourives, o qual fez delas uma imagem de escultura e uma de fundição, que ficaram em casa de Mica.',
        'E teve este homem, Mica, uma casa de deuses; e fez um éfode e terafins, e consagrou um de seus filhos, para que lhe fosse por sacerdote.',
        'Naqueles dias não havia rei em Israel; cada um fazia o que parecia bem aos seus olhos.',
        'E havia um moço de Belém de Judá, da tribo de Judá, que era levita, e peregrinava ali.',
        'E este homem partiu da cidade de Belém de Judá para peregrinar onde quer que achasse conveniente. Chegando ele, pois, à montanha de Efraim, até à casa de Mica, seguindo o seu caminho,',
        'Disse-lhe Mica: Donde vens? E ele lhe disse: Sou levita de Belém de Judá, e vou peregrinar onde quer que achar conveniente.',
        'Então lhe disse Mica: Fica comigo, e sê-me por pai e sacerdote; e cada ano te darei dez moedas de prata, e vestuário, e o sustento. E o levita entrou.',
        'E consentiu o levita em ficar com aquele homem; e o moço lhe foi como um de seus filhos.',
        'E Mica consagrou o levita, e aquele moço lhe foi por sacerdote; e esteve em casa de Mica.',
        'Então disse Mica: Agora sei que o SENHOR me fará bem; porquanto tenho um levita por sacerdote.',
      ],
      [
        'Naqueles dias não havia rei em Israel; e nos mesmos dias a tribo dos danitas buscava para si herança para habitar; porquanto até àquele dia entre as tribos de Israel não lhe havia caído por sorte sua herança.',
        'E enviaram os filhos de Dã, da sua tribo, cinco homens dentre eles, homens valorosos, de Zorá e de Estaol, a espiar e reconhecer a terra, e lhes disseram: Ide, reconhecei a terra. E chegaram à montanha de Efraim, até à casa de Mica, e passaram ali a noite.',
        'E quando eles estavam junto da casa de Mica, reconheceram a voz do moço, do levita; e dirigindo-se para lá, lhe disseram: Quem te trouxe aqui? Que fazes aqui? E que é que tens aqui?',
        'E ele lhes disse: Assim e assim me tem feito Mica; pois me tem contratado, e eu lhe sirvo de sacerdote.',
        'Então lhe disseram: Consulta a Deus, para que possamos saber se prosperará o caminho que seguimos.',
        'E disse-lhes o sacerdote: Ide em paz; o caminho que seguis está perante o Senhor.',
        'Então foram-se aqueles cinco homens, e chegaram a Laís; e viram que o povo que havia no meio dela estava seguro, conforme ao costume dos sidônios, quieto e confiado; nem havia autoridade alguma do reino que por qualquer coisa envergonhasse a alguém naquela terra; também estavam longe dos sidônios, e não tinham relação com ninguém.',
        'Então voltaram a seus irmãos, a Zorá e a Estaol, os quais lhes disseram: Que dizeis vós?',
        'E eles disseram: Levantai-vos, e subamos contra eles; porque examinamos a terra, e eis que é muitíssimo boa. E vós estareis aqui tranqüilos? Não sejais preguiçosos em irdes para entrar a possuir esta terra.',
        'Quando lá chegardes, vereis um povo confiado, e a terra é larga de extensão; porque Deus vo-la entregou nas mãos; lugar em que não há falta de coisa alguma que há na terra.',
        'Então partiram dali, da tribo dos danitas, de Zorá e de Estaol, seiscentos homens munidos de armas de guerra.',
        'E subiram, e acamparam-se em Quiriate-Jearim, em Judá; então chamaram a este lugar Maané-Dã, até ao dia de hoje; eis que está por detrás de Quiriate-Jearim.',
        'E dali passaram à montanha de Efraim; e chegaram até a casa de Mica.',
        'Então responderam os cinco homens, que foram espiar a terra de Laís, e disseram a seus irmãos: Sabeis vós também que naquelas casas há um éfode, e terafins, e uma imagem de escultura e uma de fundição? Vede, pois, agora o que haveis de fazer.',
        'Então se dirigiram para lá, e chegaram à casa do moço, o levita, em casa de Mica, e o saudaram.',
        'E os seiscentos homens, que eram dos filhos de Dã, munidos com suas armas de guerra, ficaram à entrada da porta.',
        'Porém subindo os cinco homens, que foram espiar a terra, entraram ali, e tomaram a imagem de escultura, o éfode, e os terafins, e a imagem de fundição, ficando o sacerdote em pé à entrada da porta, com os seiscentos homens que estavam munidos com as armas de guerra.',
        'Entrando eles, pois, em casa de Mica, e tomando a imagem de escultura, e o éfode, e os terafins, e a imagem de fundição, disse-lhes o sacerdote: Que estais fazendo?',
        'E eles lhe disseram: Cala-te, põe a mão na boca, e vem conosco, e sê-nos por pai e sacerdote. É melhor ser sacerdote da casa de um só homem, do que ser sacerdote de uma tribo e de uma família em Israel?',
        'Então alegrou-se o coração do sacerdote, e tomou o éfode, e os terafins, e a imagem de escultura; e entrou no meio do povo.',
        'Assim viraram, e partiram; e os meninos, e o gado, e a bagagem puseram diante de si.',
        'E, estando já longe da casa de Mica, os homens que estavam nas casas junto à casa de Mica, reuniram-se, e alcançaram os filhos de Dã.',
        'E clamaram após os filhos de Dã, os quais viraram os seus rostos, e disseram a Mica: Que tens, que tanta gente convocaste?',
        'Então ele disse: Os meus deuses, que eu fiz, me tomastes, juntamente com o sacerdote, e partistes; que mais me resta agora? Como, pois, me dizeis: Que é que tens?',
        'Porém os filhos de Dã lhe disseram: Não nos faças ouvir a tua voz, para que porventura homens de ânimo mau não se lancem sobre vós, e tu percas a tua vida, e a vida dos da tua casa.',
        'Assim seguiram o seu caminho os filhos de Dã; e Mica, vendo que eram mais fortes do que ele, virou-se, e voltou à sua casa.',
        'Eles, pois, tomaram o que Mica tinha feito, e o sacerdote que tivera, e chegaram a Laís, a um povo quieto e confiado, e os feriram ao fio da espada, e queimaram a cidade a fogo.',
        'E ninguém houve que os livrasse, porquanto estavam longe de Sidom, e não tinham relações com ninguém, e a cidade estava no vale que está junto de Bete-Reobe; depois reedificaram a cidade e habitaram nela.',
        'E chamaram-lhe Dã, conforme ao nome de Dã, seu pai, que nascera a Israel; era, porém, antes o nome desta cidade Laís.',
        'E os filhos de Dã levantaram para si aquela imagem de escultura; e Jônatas, filho de Gérson, o filho de Manassés, ele e seus filhos foram sacerdotes da tribo dos danitas, até ao dia do cativeiro da terra.',
        'Assim, pois, estabeleceram para si a imagem de escultura, que fizera Mica, por todos os dias em que a casa de Deus esteve em Siló.',
      ],
      [
        'Aconteceu também naqueles dias, em que não havia rei em Israel, que houve um homem levita, que, peregrinando aos lados da montanha de Efraim, tomou para si uma concubina, de Belém de Judá.',
        'Porém a sua concubina adulterou contra ele, e deixando-o, foi para a casa de seu pai, em Belém de Judá, e esteve ali alguns dias, a saber, quatro meses.',
        'E seu marido se levantou, e foi atrás dela, para lhe falar conforme ao seu coração, e para tornar a trazê-la; e o seu moço e um par de jumentos iam com ele; e ela o levou à casa de seu pai, e, vendo-o o pai da moça, alegrou-se ao encontrar-se com ele.',
        'E seu sogro, o pai da moça, o deteve, e ficou com ele três dias; e comeram e beberam, e passaram ali a noite.',
        'E sucedeu que ao quarto dia pela manhã, de madrugada, ele levantou-se para partir; então o pai da moça disse a seu genro: Fortalece o teu coração com um bocado de pão, e depois partireis.',
        'Assentaram-se, pois, e comeram ambos juntos, e beberam; e disse o pai da moça ao homem: Peço-te que ainda esta noite queiras passá-la aqui, e alegre-se o teu coração.',
        'Porém o homem levantou-se para partir; mas seu sogro o constrangeu a tornar a passar ali a noite.',
        'E, madrugando ao quinto dia pela manhã para partir, disse o pai da moça: Ora, conforta o teu coração. E detiveram-se até já declinar o dia; e ambos juntos comeram.',
        'Então o homem levantou-se para partir, ele, e a sua concubina, e o seu moço; e disse-lhe seu sogro, o pai da moça: Eis que já o dia declina e a tarde já vem chegando; peço-te que aqui passes a noite; eis que o dia já vai acabando, passa aqui a noite, e que o teu coração se alegre; e amanhã de madrugada levanta-te a caminhar, e irás para a tua tenda.',
        'Porém o homem não quis ali passar a noite, mas levantou-se, e partiu, e chegou até defronte de Jebus (que é Jerusalém), e com ele o par de jumentos albardados, como também a sua concubina.',
        'Estando, pois, já perto de Jebus, e tendo-se já declinado muito o dia, disse o moço a seu senhor: Vamos agora, e retiremo-nos a esta cidade dos jebuseus, e passemos ali a noite.',
        'Porém disse-lhe seu senhor: Não nos retiraremos a nenhuma cidade estranha, que não seja dos filhos de Israel; mas iremos até Gibeá.',
        'Disse mais a seu moço: Vamos, e cheguemos a um daqueles lugares, e passemos a noite em Gibeá ou em Ramá.',
        'Passaram, pois, adiante, e caminharam, e o sol se lhes pôs junto a Gibeá, que é cidade de Benjamim.',
        'E retiraram-se para lá, para passarem a noite em Gibeá; e, entrando ele, assentou-se na praça da cidade, porque não houve quem os recolhesse em casa para ali passarem a noite.',
        'E eis que um velho homem vinha à tarde do seu trabalho do campo; e era este homem da montanha de Efraim, mas peregrinava em Gibeá; eram porém os homens deste lugar filhos de Benjamim.',
        'Levantando ele, pois, os olhos, viu a este viajante na praça da cidade, e disse o ancião: Para onde vais, e donde vens?',
        'E ele lhe disse: Viajamos de Belém de Judá até aos lados da montanha de Efraim, de onde sou; porquanto fui a Belém de Judá, porém agora vou à casa do Senhor; e ninguém há que me recolha em casa,',
        'Todavia temos palha e pasto para os nossos jumentos, e também pão e vinho há para mim, e para a tua serva, e para o moço que vem com os teus servos; de coisa nenhuma há falta.',
        'Então disse o ancião: Paz seja contigo; tudo quanto te faltar fique ao meu cargo; tão-somente não passes a noite na praça.',
        'E levou-o à sua casa, e deu pasto aos jumentos; e, lavando-se os pés, comeram e beberam.',
        'Estando eles alegrando o seu coração, eis que os homens daquela cidade (homens que eram filhos de Belial) cercaram a casa, batendo à porta; e falaram ao ancião, senhor da casa, dizendo: Tira para fora o homem que entrou em tua casa, para que o conheçamos.',
        'E o homem, dono da casa, saiu a eles e disse-lhes: Não, irmãos meus, ora não façais semelhante mal; já que este homem entrou em minha casa, não façais tal loucura.',
        'Eis que a minha filha virgem e a concubina dele vo-las tirarei fora; humilhai-as a elas, e fazei delas o que parecer bem aos vossos olhos; porém a este homem não façais essa loucura.',
        'Porém aqueles homens não o quiseram ouvir; então aquele homem pegou da sua concubina, e lha tirou para fora; e eles a conheceram e abusaram dela toda a noite até pela manhã, e, subindo a alva, a deixaram.',
        'E ao romper da manhã veio a mulher, e caiu à porta da casa daquele homem, onde estava seu senhor, e ficou ali até que se fez claro.',
        'E, levantando-se seu senhor pela manhã, e abrindo as portas da casa, e saindo a seguir o seu caminho, eis que a mulher, sua concubina, jazia à porta da casa, com as mãos sobre o limiar.',
        'E ele lhe disse: Levanta-te, e vamo-nos, porém ela não respondeu; então, levantando-se o homem a pôs sobre o jumento, e foi para o seu lugar.',
        'Chegando, pois, à sua casa, tomou um cutelo, e pegou na sua concubina, e a despedaçou com os seus ossos em doze partes; e enviou-as por todos os termos de Israel.',
        'E sucedeu que cada um que via aquilo dizia: Nunca tal se fez, nem se viu desde o dia em que os filhos de Israel subiram da terra do Egito, até ao dia de hoje; ponderai isto, considerai, e falai.',
      ],
      [
        'Então todos os filhos de Israel saíram, e a congregação se ajuntou, perante o SENHOR em Mizpá, como se fora um só homem, desde Dã até Berseba, como também a terra de Gileade.',
        'E os principais de todo o povo, de todas as tribos de Israel, se apresentaram na congregação do povo de Deus; quatrocentos mil homens de pé que tiravam a espada',
        '(Ouviram, pois, os filhos de Benjamim que os filhos de Israel haviam subido a Mizpá). E disseram os filhos de Israel: Falai, como sucedeu esta maldade?',
        'Então respondeu o homem levita, marido da mulher que fora morta, e disse: Cheguei com a minha concubina a Gibeá, cidade de Benjamim, para passar a noite.',
        'E os cidadãos de Gibeá se levantaram contra mim, e cercaram a casa de noite; intentaram matar-me, e violaram a minha concubina, de maneira que morreu.',
        'Então peguei na minha concubina, e fi-la em pedaços, e a enviei por toda a terra da herança de Israel; porquanto fizeram tal malefício e loucura em Israel.',
        'Eis que todos sois filhos de Israel; dai aqui a vossa palavra e conselho.',
        'Então todo o povo se levantou como um só homem, dizendo: Nenhum de nós irá à sua tenda nem nenhum de nós voltará à sua casa.',
        'Porém isto é o que faremos a Gibeá: procederemos contra ela por sorte.',
        'E de todas as tribos de Israel, tomaremos dez homens de cada cem, e cem de cada mil, e mil de cada dez mil, para providenciarem mantimento para o povo; para que, vindo ele a Gibeá de Benjamim, lhe façam conforme a toda a loucura que tem feito em Israel.',
        'Assim ajuntaram-se contra esta cidade todos os homens de Israel, unidos como um só homem.',
        'E as tribos de Israel enviaram homens por toda a tribo de Benjamim, dizendo: Que maldade é esta que se fez entre vós?',
        'Dai-nos, pois, agora aqueles homens, filhos de Belial, que estão em Gibeá, para que os matemos, e tiremos de Israel o mal. Porém os filhos de Benjamim não quiseram ouvir a voz de seus irmãos, os filhos de Israel.',
        'Antes os filhos de Benjamim se ajuntaram das cidades em Gibeá, para saírem a pelejar contra os filhos de Israel.',
        'E contaram-se naquele dia os filhos de Benjamim, das cidades, vinte e seis mil homens que tiravam a espada, afora os moradores de Gibeá, de que se contaram setecentos homens escolhidos.',
        'Entre todo este povo havia setecentos homens escolhidos, canhotos, os quais atiravam com a funda uma pedra em um cabelo, e não erravam.',
        'E contaram-se dos homens de Israel, afora os de Benjamim, quatrocentos mil homens que tiravam da espada, e todos eles homens de guerra.',
        'E levantaram-se os filhos de Israel, e subiram a Betel; e consultaram a Deus, dizendo: Quem dentre nós subirá primeiro a pelejar contra Benjamim? E disse o Senhor: Judá subirá primeiro.',
        'Levantaram-se, pois, os filhos de Israel pela manhã, e acamparam-se contra Gibeá.',
        'E os homens de Israel saíram à peleja contra Benjamim; e os homens de Israel ordenaram a batalha contra eles, ao pé de Gibeá.',
        'Então os filhos de Benjamim saíram de Gibeá, e derrubaram por terra, naquele dia, vinte e dois mil homens de Israel.',
        'Porém esforçou-se o povo, isto é, os homens de Israel, e tornaram a ordenar a peleja no lugar onde no primeiro dia a tinham ordenado.',
        'E subiram os filhos de Israel, e choraram perante o Senhor até à tarde, e perguntaram ao Senhor, dizendo: Tornar-me-ei a chegar à peleja contra os filhos de Benjamim, meu irmão? E disse o Senhor: Subi contra ele.',
        'Chegaram-se, pois, os filhos de Israel aos filhos de Benjamim, no dia seguinte.',
        'Também os de Benjamim no dia seguinte lhes saíram ao encontro fora de Gibeá, e derrubaram ainda por terra mais dezoito mil homens, todos dos que tiravam a espada.',
        'Então todos os filhos de Israel, e todo o povo, subiram, e vieram a Betel e choraram, e estiveram ali perante o Senhor, e jejuaram aquele dia até à tarde; e ofereceram holocaustos e ofertas pacíficas perante o Senhor.',
        'E os filhos de Israel perguntaram ao Senhor (porquanto a arca da aliança de Deus estava ali naqueles dias;',
        'E Finéias, filho de Eleazar, filho de Arão, estava perante ele naqueles dias), dizendo: Tornarei ainda a pelejar contra os filhos de Benjamim, meu irmão, ou pararei? E disse o Senhor: Subi, que amanhã eu to entregarei na mão.',
        'Então Israel pôs emboscadas em redor de Gibeá.',
        'E subiram os filhos de Israel ao terceiro dia contra os filhos de Benjamim, e ordenaram a peleja junto a Gibeá, como das outras vezes.',
        'Então os filhos de Benjamim saíram ao encontro do povo, e desviaram-se da cidade; e começaram a ferir alguns do povo, atravessando-os, como das outras vezes, pelos caminhos (um dos quais sobe para Betel, e o outro para Gibeá pelo campo), uns trinta dos homens de Israel.',
        'Então os filhos de Benjamim disseram: Estão derrotados diante de nós como dantes. Porém os filhos de Israel disseram: Fujamos, e desviemo-los da cidade para os caminhos.',
        'Então todos os homens de Israel se levantaram do seu lugar, e ordenaram a peleja em Baal-Tamar; e a emboscada de Israel saiu do seu lugar, da caverna de Gibeá.',
        'E dez mil homens escolhidos de todo o Israel vieram contra Gibeá, e a peleja se agravou; porém eles não sabiam o mal que lhes tocaria.',
        'Então feriu o Senhor a Benjamim diante de Israel; e destruíram os filhos de Israel, naquele dia, vinte e cinco mil e cem homens de Benjamim, todos dos que tiravam a espada.',
        'E viram os filhos de Benjamim que estavam feridos; porque os homens de Israel deram lugar aos benjamitas, porquanto estavam confiados na emboscada que haviam posto contra Gibeá.',
        'E a emboscada se apressou, e acometeu a Gibeá; e a emboscada arremeteu contra ela, e feriu ao fio da espada toda a cidade.',
        'E os homens de Israel tinham um sinal determinado com a emboscada, que era fazer levantar da cidade uma grande nuvem de fumaça.',
        'Viraram-se, pois, os homens de Israel na peleja; e já Benjamim começava a ferir, dos homens de Israel, quase trinta homens, pois diziam: Já infalivelmente estão derrotados diante de nós, como na peleja passada.',
        'Então a nuvem de fumaça começou a se levantar da cidade, como uma coluna; e, virando-se Benjamim a olhar para trás de si, eis que a fumaça da cidade subia ao céu.',
        'E os homens de Israel viraram os rostos, e os homens de Benjamim pasmaram; porque viram que o mal lhes tocaria.',
        'E viraram as costas diante dos homens de Israel, para o caminho do deserto; porém a peleja os apertou; e os que saíam das cidades os destruíram no meio deles.',
        'E cercaram aos de Benjamim, e os perseguiram, e à vontade os pisaram, até diante de Gibeá, para o nascente do sol.',
        'E caíram de Benjamim dezoito mil homens, todos estes sendo homens valentes.',
        'Então viraram as costas, e fugiram para o deserto, à penha de Rimom; colheram ainda deles pelos caminhos uns cinco mil homens; e de perto os seguiram até Gidom, e feriram deles dois mil homens.',
        'E, todos os que caíram de Benjamim, naquele dia, foram vinte e cinco mil homens que tiravam a espada, todos eles homens valentes.',
        'Porém seiscentos homens viraram as costas, e fugiram para o deserto, à penha de Rimom; e ficaram na penha de Rimom quatro meses.',
        'E os homens de Israel voltaram para os filhos de Benjamim, e os feriram ao fio da espada, desde os homens da cidade até aos animais, até a tudo quanto se achava, como também a todas as cidades, quantas acharam, puseram fogo.',
      ],
      [
        'Ora, tinham jurado os homens de Israel em Mizpá, dizendo: Nenhum de nós dará sua filha por mulher aos benjamitas.',
        'Veio, pois, o povo a Betel, e ali ficou até à tarde diante de Deus; e todos levantaram a sua voz, e prantearam com grande pranto,',
        'E disseram: Ah! Senhor Deus de Israel, por que sucedeu isto, que hoje falte uma tribo em Israel?',
        'E sucedeu que, no dia seguinte, o povo, pela manhã se levantou, e edificou ali um altar; e ofereceu holocaustos e ofertas pacíficas.',
        'E disseram os filhos de Israel: Quem de todas as tribos de Israel não subiu à assembléia do Senhor? Porque se tinha feito um grande juramento acerca dos que não fossem ao Senhor em Mizpá, dizendo: Morrerá certamente.',
        'E arrependeram-se os filhos de Israel acerca de Benjamim, seu irmão, e disseram: Cortada é hoje de Israel uma tribo.',
        'Como havemos de conseguir mulheres para os que restaram deles, pois nós temos jurado pelo Senhor que nenhuma de nossas filhas lhes daríamos por mulher?',
        'E disseram: Há algumas das tribos de Israel que não subiram ao Senhor a Mizpá? E eis que ninguém de Jabes-Gileade viera ao arraial, à assembléia.',
        'Porquanto, quando se contou o povo, eis que nenhum dos moradores de Jabes-Gileade se achou ali.',
        'Então a assembléia enviou para lá doze mil homens dos mais valentes, e lhes ordenou, dizendo: Ide, e ao fio da espada feri aos moradores de Jabes-Gileade, e às mulheres e aos meninos.',
        'Porém isto é o que haveis de fazer: A todo o homem e a toda a mulher que se houver deitado com um homem totalmente destruireis.',
        'E acharam entre os moradores de Jabes-Gileade quatrocentas moças virgens, que não tinham conhecido homem; e as trouxeram ao arraial, a Siló, que está na terra de Canaã.',
        'Então toda a assembléia enviou, e falou aos filhos de Benjamim, que estavam na penha de Rimom, e lhes proclamou a paz.',
        'E ao mesmo tempo voltaram os benjamitas; e deram-lhes as mulheres que haviam guardado com vida, das mulheres de Jabes-Gileade; porém estas ainda não lhes bastaram.',
        'Então o povo se arrependeu por causa de Benjamim; porquanto o Senhor tinha feito brecha nas tribos de Israel.',
        'E disseram os anciãos da assembléia: Que faremos acerca de mulheres para os que restaram, pois foram destruídas as mulheres de Benjamim?',
        'Disseram mais: Tenha Benjamim uma herança nos que restaram, e não seja destruída nenhuma tribo de Israel.',
        'Porém nós não lhes poderemos dar mulheres de nossas filhas, porque os filhos de Israel juraram, dizendo: Maldito aquele que der mulher aos benjamitas.',
        'Então disseram: Eis que de ano em ano há solenidade do Senhor em Siló, que se celebra para o norte de Betel do lado do nascente do sol, pelo caminho alto que sobe de Betel a Siquém, e para o sul de Lebona.',
        'E mandaram aos filhos de Benjamim, dizendo: Ide, e emboscai-vos nas vinhas.',
        'E olhai, e eis aí as filhas de Siló a dançar em rodas, saí vós das vinhas, e arrebatai cada um sua mulher das filhas de Siló, e ide-vos à terra de Benjamim.',
        'E será que, quando seus pais ou seus irmãos vierem a litigar conosco, nós lhes diremos: Por amor de nós, tende compaixão deles, pois nesta guerra não tomamos mulheres para cada um deles; porque não lhas destes vós, para que agora ficásseis culpados.',
        'E os filhos de Benjamim o fizeram assim, e levaram mulheres conforme ao número deles, das que arrebataram das rodas que dançavam; e foram-se, e voltaram à sua herança, e reedificaram as cidades, e habitaram nelas.',
        'Também os filhos de Israel partiram dali, cada um para a sua tribo e para a sua família; e saíram dali, cada um para a sua herança.',
        'Naqueles dias não havia rei em Israel; porém cada um fazia o que parecia reto aos seus olhos.',
      ],
    ],
    livro: 'juizes',
  },
  {
    abbrev: 'rt',
    capitulos: [
      [
        'E sucedeu que, nos dias em que os juízes julgavam, houve uma fome na terra; por isso um homem de Belém de Judá saiu a peregrinar nos campos de Moabe, ele e sua mulher, e seus dois filhos;',
        'E era o nome deste homem Elimeleque, e o de sua mulher Noemi, e os de seus dois filhos Malom e Quiliom, efrateus, de Belém de Judá; e chegaram aos campos de Moabe, e ficaram ali.',
        'E morreu Elimeleque, marido de Noemi; e ficou ela com os seus dois filhos,',
        'Os quais tomaram para si mulheres moabitas; e era o nome de uma Orfa, e o da outra Rute; e ficaram ali quase dez anos.',
        'E morreram também ambos, Malom e Quiliom, ficando assim a mulher desamparada dos seus dois filhos e de seu marido.',
        'Então se levantou ela com as suas noras, e voltou dos campos de Moabe, porquanto na terra de Moabe ouviu que o Senhor tinha visitado o seu povo, dando-lhe pão.',
        'Por isso saiu do lugar onde estivera, e as suas noras com ela. E, indo elas caminhando, para voltarem para a terra de Judá,',
        'Disse Noemi às suas noras: Ide, voltai cada uma à casa de sua mãe; e o Senhor use convosco de benevolência, como vós usastes com os falecidos e comigo.',
        'O Senhor vos dê que acheis descanso cada uma em casa de seu marido. E, beijando-as ela, levantaram a sua voz e choraram.',
        'E disseram-lhe: Certamente voltaremos contigo ao teu povo.',
        'Porém Noemi disse: Voltai, minhas filhas. Por que iríeis comigo? Tenho eu ainda no meu ventre mais filhos, para que vos sejam por maridos?',
        'Voltai, filhas minhas, ide-vos embora, que já mui velha sou para ter marido; ainda quando eu dissesse: Tenho esperança, ou ainda que esta noite tivesse marido e ainda tivesse filhos,',
        'Esperá-los-íeis até que viessem a ser grandes? Deter-vos-íeis por eles, sem tomardes marido? Não, filhas minhas, que mais amargo me é a mim do que a vós mesmas; porquanto a mão do Senhor se descarregou contra mim.',
        'Então levantaram a sua voz, e tornaram a chorar; e Orfa beijou a sua sogra, porém Rute se apegou a ela.',
        'Por isso disse Noemi: Eis que voltou tua cunhada ao seu povo e aos seus deuses; volta tu também após tua cunhada.',
        'Disse, porém, Rute: Não me instes para que te abandone, e deixe de seguir-te; porque aonde quer que tu fores irei eu, e onde quer que pousares, ali pousarei eu; o teu povo é o meu povo, o teu Deus é o meu Deus;',
        'Onde quer que morreres morrerei eu, e ali serei sepultada. Faça-me assim o Senhor, e outro tanto, se outra coisa que não seja a morte me separar de ti.',
        'Vendo Noemi, que de todo estava resolvida a ir com ela, deixou de lhe falar.',
        'Assim, pois, foram-se ambas, até que chegaram a Belém; e sucedeu que, entrando elas em Belém, toda a cidade se comoveu por causa delas, e diziam: Não é esta Noemi?',
        'Porém ela lhes dizia: Não me chameis Noemi; chamai-me Mara; porque grande amargura me tem dado o Todo-Poderoso.',
        'Cheia parti, porém vazia o Senhor me fez tornar; por que pois me chamareis Noemi? O Senhor testifica contra mim, e o Todo-Poderoso me tem feito mal.',
        'Assim Noemi voltou, e com ela Rute a moabita, sua nora, que veio dos campos de Moabe; e chegaram a Belém no princípio da colheita das cevadas.',
      ],
      [
        'E tinha Noemi um parente de seu marido, homem valente e poderoso, da família de Elimeleque; e era o seu nome Boaz.',
        'E Rute, a moabita, disse a Noemi: Deixa-me ir ao campo, e apanharei espigas atrás daquele em cujos olhos eu achar graça. E ela disse: Vai, minha filha.',
        'Foi, pois, e chegou, e apanhava espigas no campo após os segadores; e caiu-lhe em sorte uma parte do campo de Boaz, que era da família de Elimeleque.',
        'E eis que Boaz veio de Belém, e disse aos segadores: O Senhor seja convosco. E disseram-lhe eles: O Senhor te abençoe.',
        'Depois disse Boaz a seu moço, que estava posto sobre os segadores: De quem é esta moça?',
        'E respondeu o moço, que estava posto sobre os segadores, e disse: Esta é a moça moabita que voltou com Noemi dos campos de Moabe.',
        'Disse-me ela: Deixa-me colher espigas, e ajuntá-las entre as gavelas após os segadores. Assim ela veio, e desde pela manhã está aqui até agora, a não ser um pouco que esteve sentada em casa.',
        'Então disse Boaz a Rute: Ouve, filha minha; não vás colher em outro campo, nem tampouco passes daqui; porém aqui ficarás com as minhas moças.',
        'Os teus olhos estarão atentos no campo que segarem, e irás após elas; não dei ordem aos moços, que não te molestem? Tendo tu sede, vai aos vasos, e bebe do que os moços tirarem.',
        'Então ela caiu sobre o seu rosto, e se inclinou à terra; e disse-lhe: Por que achei graça em teus olhos, para que faças caso de mim, sendo eu uma estrangeira?',
        'E respondeu Boaz, e disse-lhe: Bem se me contou quanto fizeste à tua sogra, depois da morte de teu marido; e deixaste a teu pai e a tua mãe, e a terra onde nasceste, e vieste para um povo que antes não conheceste.',
        'O Senhor retribua o teu feito; e te seja concedido pleno galardão da parte do Senhor Deus de Israel, sob cujas asas te vieste abrigar.',
        'E disse ela: Ache eu graça em teus olhos, senhor meu, pois me consolaste, e falaste ao coração da tua serva, não sendo eu ainda como uma das tuas criadas.',
        'E, sendo já hora de comer, disse-lhe Boaz: Achega-te aqui, e come do pão, e molha o teu bocado no vinagre. E ela se assentou ao lado dos segadores, e ele lhe deu do trigo tostado, e comeu, e se fartou, e ainda lhe sobejou.',
        'E, levantando-se ela a colher, Boaz deu ordem aos seus moços, dizendo: Até entre as gavelas deixai-a colher, e não a censureis.',
        'E deixai cair alguns punhados, e deixai-os ficar, para que os colha, e não a repreendais.',
        'E esteve ela apanhando naquele campo até à tarde; e debulhou o que apanhou, e foi quase um efa de cevada.',
        'E tomou-o, e veio à cidade; e viu sua sogra o que tinha apanhado; também tirou, e deu-lhe o que sobejara depois de fartar-se.',
        'Então disse-lhe sua sogra: Onde colheste hoje, e onde trabalhaste? Bendito seja aquele que te reconheceu. E relatou à sua sogra com quem tinha trabalhado, e disse: O nome do homem com quem hoje trabalhei é Boaz.',
        'Então Noemi disse à sua nora: Bendito seja ele do Senhor, que ainda não tem deixado a sua beneficência nem para com os vivos nem para com os mortos. Disse-lhe mais Noemi: Este homem é nosso parente chegado, e um dentre os nossos remidores.',
        'E disse Rute, a moabita: Também ainda me disse: Com os moços que tenho te ajuntarás, até que acabem toda a sega que tenho.',
        'E disse Noemi a sua nora: Melhor é, filha minha, que saias com as suas moças, para que noutro campo não te encontrem.',
        'Assim, ajuntou-se com as moças de Boaz, para colher até que a sega das cevadas e dos trigos se acabou; e ficou com a sua sogra.',
      ],
      [
        'E disse-lhe Noemi, sua sogra: Minha filha, não hei de buscar descanso, para que fiques bem?',
        'Ora, pois, não é Boaz, com cujas moças estiveste, de nossa parentela? Eis que esta noite padejará a cevada na eira.',
        'Lava-te, pois, e unge-te, e veste os teus vestidos, e desce à eira; porém não te dês a conhecer ao homem, até que tenha acabado de comer e beber.',
        'E há de ser que, quando ele se deitar, notarás o lugar em que se deitar; então entrarás, e descobrir-lhe-ás os pés, e te deitarás, e ele te fará saber o que deves fazer.',
        'E ela lhe disse: Tudo quanto me disseres, farei.',
        'Então foi para a eira, e fez conforme a tudo quanto sua sogra lhe tinha ordenado.',
        'Havendo, pois, Boaz comido e bebido, e estando já o seu coração alegre, veio deitar-se ao pé de um monte de grãos; então veio ela de mansinho, e lhe descobriu os pés, e se deitou.',
        'E sucedeu que, pela meia-noite, o homem estremeceu, e se voltou; e eis que uma mulher jazia a seus pés.',
        'E disse ele: Quem és tu? E ela disse: Sou Rute, tua serva; estende pois tua capa sobre a tua serva, porque tu és o remidor.',
        'E disse ele: Bendita sejas tu do Senhor, minha filha; melhor fizeste esta tua última benevolência do que a primeira, pois após nenhum dos jovens foste, quer pobre quer rico.',
        'Agora, pois, minha filha, não temas; tudo quanto disseste te farei, pois toda a cidade do meu povo sabe que és mulher virtuosa.',
        'Porém agora é verdade que eu sou remidor, mas ainda outro remidor há mais chegado do que eu.',
        'Fica-te aqui esta noite, e será que, pela manhã, se ele te redimir, bem está, que te redima; porém, se não quiser te redimir, vive o Senhor, que eu te redimirei. Deita-te aqui até amanhã.',
        'Ficou-se, pois, deitada a seus pés até pela manhã, e levantou-se antes que pudesse um conhecer o outro, porquanto ele disse: Não se saiba que alguma mulher veio à eira.',
        'Disse mais: Dá-me a capa que tens sobre ti, e segura-a. E ela a segurou; e ele mediu seis medidas de cevada, e lhas pôs em cima; então foi para a cidade.',
        'E foi à sua sogra, que lhe disse: Como foi, minha filha? E ela lhe contou tudo quanto aquele homem lhe fizera.',
        'Disse mais: Estas seis medidas de cevada me deu, porque me disse: Não vás vazia à tua sogra.',
        'Então disse ela: Espera, minha filha, até que saibas como irá o caso, porque aquele homem não descansará até que conclua hoje este negócio.',
      ],
      [
        'E Boaz subiu à porta, e assentou-se ali; e eis que o remidor de que Boaz tinha falado ia passando, e disse-lhe: Ó fulano, vem cá, assenta-te aqui. E desviou-se para ali, e assentou-se.',
        'Então tomou dez homens dos anciãos da cidade, e disse: Assentai-vos aqui. E assentaram-se.',
        'Então disse ao remidor: Aquela parte da terra que foi de Elimeleque, nosso irmão, Noemi, que tornou da terra dos moabitas, está vendendo.',
        'E eu resolvi informar-te disso e dizer-te: Compra-a diante dos habitantes, e diante dos anciãos do meu povo; se a hás de redimir, redime-a, e se não a houveres de redimir, declara-mo, para que o saiba, pois outro não há senão tu que a redima, e eu depois de ti. Então disse ele: Eu a redimirei.',
        'Disse porém Boaz: No dia em que comprares a terra da mão de Noemi, também a comprarás da mão de Rute, a moabita, mulher do falecido, para suscitar o nome do falecido sobre a sua herança.',
        'Então disse o remidor: Para mim não a poderei redimir, para que não prejudique a minha herança; toma para ti o meu direito de remissão, porque eu não a poderei redimir.',
        'Havia, pois, já de muito tempo este costume em Israel, quanto a remissão e permuta, para confirmar todo o negócio; o homem descalçava o sapato e o dava ao seu próximo; e isto era por testemunho em Israel.',
        'Disse, pois, o remidor a Boaz: Toma-a para ti. E descalçou o sapato.',
        'Então Boaz disse aos anciãos e a todo o povo: Sois hoje testemunhas de que tomei tudo quanto foi de Elimeleque, e de Quiliom, e de Malom, da mão de Noemi,',
        'E de que também tomo por mulher a Rute, a moabita, que foi mulher de Malom, para suscitar o nome do falecido sobre a sua herança, para que o nome do falecido não seja desarraigado dentre seus irmãos e da porta do seu lugar; disto sois hoje testemunhas.',
        'E todo o povo que estava na porta, e os anciãos, disseram: Somos testemunhas; o Senhor faça a esta mulher, que entra na tua casa, como a Raquel e como a Lia, que ambas edificaram a casa de Israel; e porta-te valorosamente em Efrata, e faze-te nome afamado em Belém.',
        'E seja a tua casa como a casa de Perez (que Tamar deu à luz a Judá), pela descendência que o Senhor te der desta moça.',
        'Assim tomou Boaz a Rute, e ela lhe foi por mulher; e ele a possuiu, e o Senhor lhe fez conceber, e deu à luz um filho.',
        'Então as mulheres disseram a Noemi: Bendito seja o Senhor, que não deixou hoje de te dar remidor, e seja o seu nome afamado em Israel.',
        'Ele te será por restaurador da alma, e nutrirá a tua velhice, pois tua nora, que te ama, o deu à luz, e ela te é melhor do que sete filhos.',
        'E Noemi tomou o filho, e o pôs no seu colo, e foi sua ama.',
        'E as vizinhas lhe deram um nome, dizendo: A Noemi nasceu um filho. E deram-lhe o nome de Obede. Este é o pai de Jessé, pai de Davi.',
        'Estas são, pois, as gerações de Perez: Perez gerou a Hezrom,',
        'E Hezrom gerou a Rão, e Rão gerou a Aminadabe,',
        'E Aminadabe gerou a Naassom, e Naassom gerou a Salmom,',
        'E Salmom gerou a Boaz, e Boaz gerou a Obede,',
        'E Obede gerou a Jessé, e Jessé gerou a Davi.',
      ],
    ],
    livro: 'rute',
  },
  {
    abbrev: '1sm',
    capitulos: [
      [
        'Houve um homem de Ramataim-Zofim, da montanha de Efraim, cujo nome era Elcana, filho de Jeroão, filho de Eliú, filho de Toú, filho de Zufe, efrateu.',
        'E este tinha duas mulheres: o nome de uma era Ana, e o da outra Penina. E Penina tinha filhos, porém Ana não os tinha.',
        'Subia, pois, este homem, da sua cidade, de ano em ano, a adorar e a sacrificar ao Senhor dos Exércitos em Siló; e estavam ali os sacerdotes do Senhor, Hofni e Finéias, os dois filhos de Eli.',
        'E sucedeu que no dia em que Elcana sacrificava, dava ele porções a Penina, sua mulher, e a todos os seus filhos, e a todas as suas filhas.',
        'Porém a Ana dava uma parte excelente; porque amava a Ana, embora o Senhor lhe tivesse cerrado a madre.',
        'E a sua rival excessivamente a provocava, para a irritar; porque o Senhor lhe tinha cerrado a madre.',
        'E assim fazia ele de ano em ano. Sempre que Ana subia à casa do Senhor, a outra a irritava; por isso chorava, e não comia.',
        'Então Elcana, seu marido, lhe disse: Ana, por que choras? E por que não comes? E por que está mal o teu coração? Não te sou eu melhor do que dez filhos?',
        'Então Ana se levantou, depois que comeram e beberam em Siló; e Eli, sacerdote, estava assentado numa cadeira, junto a um pilar do templo do Senhor.',
        'Ela, pois, com amargura de alma, orou ao Senhor, e chorou abundantemente.',
        'E fez um voto, dizendo: Senhor dos Exércitos! Se benignamente atentares para a aflição da tua serva, e de mim te lembrares, e da tua serva não te esqueceres, mas à tua serva deres um filho homem, ao Senhor o darei todos os dias da sua vida, e sobre a sua cabeça não passará navalha.',
        'E sucedeu que, perseverando ela em orar perante o Senhor, Eli observou a sua boca.',
        'Porquanto Ana no seu coração falava; só se moviam os seus lábios, porém não se ouvia a sua voz; pelo que Eli a teve por embriagada.',
        'E disse-lhe Eli: Até quando estarás tu embriagada? Aparta de ti o teu vinho.',
        'Porém Ana respondeu: Não, senhor meu, eu sou uma mulher atribulada de espírito; nem vinho nem bebida forte tenho bebido; porém tenho derramado a minha alma perante o SENHOR.',
        'Não tenhas, pois, a tua serva por filha de Belial; porque da multidão dos meus cuidados e do meu desgosto tenho falado até agora.',
        'Então respondeu Eli: Vai em paz; e o Deus de Israel te conceda a petição que lhe fizeste.',
        'E disse ela: Ache a tua serva graça aos teus olhos. Assim a mulher foi o seu caminho, e comeu, e o seu semblante já não era triste.',
        'E levantaram-se de madrugada, e adoraram perante o Senhor, e voltaram, e chegaram à sua casa, em Ramá, e Elcana conheceu a Ana sua mulher, e o Senhor se lembrou dela.',
        'E sucedeu que, passado algum tempo, Ana concebeu, e deu à luz um filho, ao qual chamou Samuel; porque, dizia ela, o tenho pedido ao Senhor.',
        'E subiu aquele homem Elcana com toda a sua casa, a oferecer ao Senhor o sacrifício anual e a cumprir o seu voto.',
        'Porém Ana não subiu; mas disse a seu marido: Quando o menino for desmamado, então o levarei, para que apareça perante o Senhor, e lá fique para sempre.',
        'E Elcana, seu marido, lhe disse: Faze o que bem te parecer aos teus olhos; fica até que o desmames; então somente confirme o Senhor a sua palavra. Assim ficou a mulher, e deu leite a seu filho, até que o desmamou.',
        'E, havendo-o desmamado, tomou-o consigo, com três bezerros, e um efa de farinha, e um odre de vinho, e levou-o à casa do Senhor, em Siló, e era o menino ainda muito criança.',
        'E degolaram um bezerro, e trouxeram o menino a Eli.',
        'E disse ela: Ah, meu senhor, viva a tua alma, meu senhor; eu sou aquela mulher que aqui esteve contigo, para orar ao SENHOR.',
        'Por este menino orava eu; e o Senhor atendeu à minha petição, que eu lhe tinha feito.',
        'Por isso também ao Senhor eu o entreguei, por todos os dias que viver, pois ao Senhor foi pedido. E adorou ali ao Senhor.',
      ],
      [
        'Então orou Ana, e disse: O meu coração exulta ao SENHOR, o meu poder está exaltado no SENHOR; a minha boca se dilatou sobre os meus inimigos, porquanto me alegro na tua salvação.',
        'Não há santo como o Senhor; porque não há outro fora de ti; e rocha nenhuma há como o nosso Deus.',
        'Não multipliqueis palavras de altivez, nem saiam coisas arrogantes da vossa boca; porque o Senhor é o Deus de conhecimento, e por ele são as obras pesadas na balança.',
        'O arco dos fortes foi quebrado, e os que tropeçavam foram cingidos de força.',
        'Os fartos se alugaram por pão, e cessaram os famintos; até a estéril deu à luz sete filhos, e a que tinha muitos filhos enfraqueceu.',
        'O Senhor é o que tira a vida e a dá; faz descer à sepultura e faz tornar a subir dela.',
        'O Senhor empobrece e enriquece; abaixa e também exalta.',
        'Levanta o pobre do pó, e desde o monturo exalta o necessitado, para o fazer assentar entre os príncipes, para o fazer herdar o trono de glória; porque do Senhor são os alicerces da terra, e assentou sobre eles o mundo.',
        'Os pés dos seus santos guardará, porém os ímpios ficarão mudos nas trevas; porque o homem não prevalecerá pela força.',
        'Os que contendem com o Senhor serão quebrantados, desde os céus trovejará sobre eles; o Senhor julgará as extremidades da terra; e dará força ao seu rei, e exaltará o poder do seu ungido.',
        'Então Elcana foi a Ramá, à sua casa; porém o menino ficou servindo ao Senhor, perante o sacerdote Eli.',
        'Eram, porém, os filhos de Eli filhos de Belial; não conheciam ao Senhor.',
        'Porquanto o costume daqueles sacerdotes com o povo era que, oferecendo alguém algum sacrifício, estando-se cozendo a carne, vinha o moço do sacerdote, com um garfo de três dentes em sua mão;',
        'E enfiava-o na caldeira, ou na panela, ou no caldeirão, ou na marmita; e tudo quanto o garfo tirava, o sacerdote tomava para si; assim faziam a todo o Israel que ia ali a Siló.',
        'Também antes de queimarem a gordura vinha o moço do sacerdote, e dizia ao homem que sacrificava: Dá essa carne para assar ao sacerdote; porque não receberá de ti carne cozida, mas crua.',
        'E, dizendo-lhe o homem: Queime-se primeiro a gordura de hoje, e depois toma para ti quanto desejar a tua alma, então ele lhe dizia: Não, agora a hás de dar, e, se não, por força a tomarei.',
        'Era, pois, muito grande o pecado destes moços perante o Senhor, porquanto os homens desprezavam a oferta do Senhor.',
        'Porém Samuel ministrava perante o Senhor, sendo ainda jovem, vestido com um éfode de linho.',
        'E sua mãe lhe fazia uma túnica pequena, e de ano em ano lha trazia, quando com seu marido subia para oferecer o sacrifício anual.',
        'E Eli abençoava a Elcana e a sua mulher, e dizia: O Senhor te dê descendência desta mulher, pela petição que fez ao Senhor. E voltavam para o seu lugar.',
        'Visitou, pois, o Senhor a Ana, que concebeu, e deu à luz três filhos e duas filhas; e o jovem Samuel crescia diante do Senhor.',
        'Era, porém, Eli já muito velho, e ouvia tudo quanto seus filhos faziam a todo o Israel, e de como se deitavam com as mulheres que em bandos se ajuntavam à porta da tenda da congregação.',
        'E disse-lhes: Por que fazeis tais coisas? Pois ouço de todo este povo os vossos malefícios.',
        'Não, filhos meus, porque não é boa esta fama que ouço; fazeis transgredir o povo do Senhor.',
        'Pecando homem contra homem, os juízes o julgarão; pecando, porém, o homem contra o Senhor, quem rogará por ele? Mas não ouviram a voz de seu pai, porque o Senhor os queria matar.',
        'E o jovem Samuel ia crescendo, e fazia-se agradável, assim para com o Senhor, como também para com os homens.',
        'E veio um homem de Deus a Eli, e disse-lhe: Assim diz o Senhor: Não me manifestei, na verdade, à casa de teu pai, estando eles ainda no Egito, na casa de Faraó?',
        'E eu o escolhi dentre todas as tribos de Israel por sacerdote, para oferecer sobre o meu altar, para acender o incenso, e para trazer o éfode perante mim; e dei à casa de teu pai todas as ofertas queimadas dos filhos de Israel.',
        'Por que pisastes o meu sacrifício e a minha oferta de alimentos, que ordenei na minha morada, e honras a teus filhos mais do que a mim, para vos engordardes do principal de todas as ofertas do meu povo de Israel?',
        'Portanto, diz o Senhor Deus de Israel: Na verdade tinha falado eu que a tua casa e a casa de teu pai andariam diante de mim perpetuamente; porém agora diz o Senhor: Longe de mim tal coisa, porque aos que me honram honrarei, porém os que me desprezam serão desprezados.',
        'Eis que vêm dias em que cortarei o teu braço e o braço da casa de teu pai, para que não haja mais ancião algum em tua casa.',
        'E verás o aperto da morada de Deus, em lugar de todo o bem que houvera de fazer a Israel; nem haverá por todos os dias ancião algum em tua casa.',
        'O homem, porém, a quem eu não desarraigar do meu altar será para te consumir os olhos e para te entristecer a alma; e toda a multidão da tua casa morrerá quando chegar à idade varonil.',
        'E isto te será por sinal, a saber: o que acontecerá a teus dois filhos, a Hofni e a Finéias; ambos morrerão no mesmo dia.',
        'E eu suscitarei para mim um sacerdote fiel, que procederá segundo o meu coração e a minha alma, e eu lhe edificarei uma casa firme, e andará sempre diante do meu ungido.',
        'E será que todo aquele que restar da tua casa virá a inclinar-se diante dele por uma moeda de prata e por um bocado de pão, e dirá: Rogo-te que me admitas a algum ministério sacerdotal, para que possa comer um pedaço de pão.',
      ],
      [
        'E o jovem Samuel servia ao SENHOR perante Eli; e a palavra do SENHOR era de muita valia naqueles dias; não havia visão manifesta.',
        'E sucedeu, naquele dia, que, estando Eli deitado no seu lugar (e os seus olhos começavam a escurecer, pois não podia ver),',
        'E estando também Samuel já deitado, antes que a lâmpada de Deus se apagasse no templo do Senhor, onde estava a arca de Deus,',
        'O Senhor chamou a Samuel, e disse ele: Eis-me aqui.',
        'E correu a Eli, e disse: Eis-me aqui, porque tu me chamaste. Mas ele disse: Não te chamei eu, torna a deitar-te. E foi e se deitou.',
        'E o Senhor tornou a chamar outra vez a Samuel, e Samuel se levantou, e foi a Eli, e disse: Eis-me aqui, porque tu me chamaste. Mas ele disse: Não te chamei eu, filho meu, torna a deitar-te.',
        'Porém Samuel ainda não conhecia ao Senhor, e ainda não lhe tinha sido manifestada a palavra do Senhor.',
        'O Senhor, pois, tornou a chamar a Samuel terceira vez, e ele se levantou, e foi a Eli, e disse: Eis-me aqui, porque tu me chamaste. Então entendeu Eli que o Senhor chamava o jovem.',
        'Por isso Eli disse a Samuel: Vai deitar-te e há de ser que, se te chamar, dirás: Fala, Senhor, porque o teu servo ouve. Então Samuel foi e se deitou no seu lugar.',
        'Então veio o Senhor, e pôs-se ali, e chamou como das outras vezes: Samuel, Samuel. E disse Samuel: Fala, porque o teu servo ouve.',
        'E disse o Senhor a Samuel: Eis que vou fazer uma coisa em Israel, a qual todo o que ouvir lhe tinirão ambos os ouvidos.',
        'Naquele mesmo dia suscitarei contra Eli tudo quanto tenho falado contra a sua casa, começarei e acabarei.',
        'Porque eu já lhe fiz saber que julgarei a sua casa para sempre, pela iniqüidade que ele bem conhecia, porque, fazendo-se os seus filhos execráveis, não os repreendeu.',
        'Portanto, jurei à casa de Eli que nunca jamais será expiada a sua iniqüidade, nem com sacrifício, nem com oferta de alimentos.',
        'E Samuel ficou deitado até pela manhã, e então abriu as portas da casa do Senhor; porém temia Samuel relatar esta visão a Eli.',
        'Então chamou Eli a Samuel, e disse: Samuel, meu filho. E disse ele: Eis-me aqui.',
        'E ele disse: Qual é a palavra que te falou? Peço-te que não ma encubras; assim Deus te faça, e outro tanto, se me encobrires alguma palavra de todas as que te falou.',
        'Então Samuel lhe contou todas aquelas palavras, e nada lhe encobriu. E disse ele: Ele é o Senhor; faça o que bem parecer aos seus olhos.',
        'E crescia Samuel, e o Senhor era com ele, e nenhuma de todas as suas palavras deixou cair em terra.',
        'E todo o Israel, desde Dã até Berseba, conheceu que Samuel estava confirmado por profeta do Senhor.',
        'E continuou o Senhor a aparecer em Siló; porquanto o Senhor se manifestava a Samuel em Siló pela palavra do Senhor.',
      ],
      [
        'E veio a palavra de Samuel a todo o Israel; e Israel saiu à peleja contra os filisteus e acampou-se junto a Ebenézer; e os filisteus se acamparam junto a Afeque.',
        'E os filisteus se dispuseram em ordem de batalha, para sair contra Israel; e, estendendo-se a peleja, Israel foi ferido diante dos filisteus, porque feriram na batalha, no campo, uns quatro mil homens.',
        'E voltando o povo ao arraial, disseram os anciãos de Israel: Por que nos feriu o Senhor hoje diante dos filisteus? Tragamos de Siló a arca da aliança do Senhor, e venha no meio de nós, para que nos livre da mão de nossos inimigos.',
        'Enviou, pois, o povo a Siló, e trouxeram de lá a arca da aliança do Senhor dos Exércitos, que habita entre os querubins; e os dois filhos de Eli, Hofni e Finéias, estavam ali com a arca da aliança de Deus.',
        'E sucedeu que, vindo a arca da aliança do Senhor ao arraial, todo o Israel gritou com grande júbilo, até que a terra estremeceu.',
        'E os filisteus, ouvindo a voz de júbilo, disseram: Que voz de grande júbilo é esta no arraial dos hebreus? Então souberam que a arca do Senhor era vinda ao arraial.',
        'Por isso os filisteus se atemorizaram, porque diziam: Deus veio ao arraial. E diziam mais: Ai de nós! Tal nunca jamais sucedeu antes.',
        'Ai de nós! Quem nos livrará da mão desses grandiosos deuses? Estes são os deuses que feriram aos egípcios com todas as pragas junto ao deserto.',
        'Esforçai-vos, e sede homens, ó filisteus, para que porventura não venhais a servir aos hebreus, como eles serviram a vós; sede, pois, homens, e pelejai.',
        'Então pelejaram os filisteus, e Israel foi ferido, fugindo cada um para a sua tenda; e foi tão grande o estrago, que caíram de Israel trinta mil homens de pé.',
        'E foi tomada a arca de Deus: e os dois filhos de Eli, Hofni e Finéias, morreram.',
        'Então correu, da batalha, um homem de Benjamim, e chegou no mesmo dia a Siló; e trazia as vestes rotas, e terra sobre a cabeça.',
        'E, chegando ele, eis que Eli estava assentado numa cadeira, olhando para o caminho; porquanto o seu coração estava tremendo pela arca de Deus. Entrando, pois, aquele homem a anunciar isto na cidade, toda a cidade gritou.',
        'E Eli, ouvindo os gritos, disse: Que alvoroço é esse? Então chegou aquele homem apressadamente, e veio, e o anunciou a Eli.',
        'E era Eli da idade de noventa e oito anos; e estavam os seus olhos tão escurecidos, que já não podia ver.',
        'E disse aquele homem a Eli: Eu sou o que venho da batalha; porque eu fugi hoje da batalha. E disse ele: Que coisa sucedeu, filho meu?',
        'Então respondeu o que trazia as notícias, e disse: Israel fugiu de diante dos filisteus, e houve também grande matança entre o povo; e, além disso, também teus dois filhos, Hofni e Finéias, morreram, e a arca de Deus foi tomada.',
        'E sucedeu que, fazendo ele menção da arca de Deus, Eli caiu da cadeira para trás, ao lado da porta, e quebrou-se-lhe o pescoço e morreu; porquanto o homem era velho e pesado; e tinha ele julgado Israel quarenta anos.',
        'E, estando sua nora, a mulher de Finéias, grávida, e próxima ao parto, e ouvindo estas notícias, de que a arca de Deus era tomada, e de que seu sogro e seu marido morreram, encurvou-se e deu à luz; porquanto as dores lhe sobrevieram.',
        'E, ao tempo em que ia morrendo, disseram as mulheres que estavam com ela: Não temas, pois deste à luz um filho. Ela porém não respondeu, nem fez caso disso.',
        'E chamou ao menino Icabode, dizendo: De Israel se foi a glória! Porque a arca de Deus foi tomada, e por causa de seu sogro e de seu marido.',
        'E disse: De Israel a glória é levada presa; pois é tomada a arca de Deus.',
      ],
      [
        'Os filisteus, pois, tomaram a arca de Deus e a trouxeram de Ebenézer a Asdode.',
        'Tomaram os filisteus a arca de Deus, e a colocaram na casa de Dagom, e a puseram junto a Dagom.',
        'Levantando-se, porém, de madrugada no dia seguinte, os de Asdode, eis que Dagom estava caído com o rosto em terra, diante da arca do Senhor; e tomaram a Dagom, e tornaram a pô-lo no seu lugar.',
        'E, levantando-se de madrugada, no dia seguinte, pela manhã, eis que Dagom jazia caído com o rosto em terra diante da arca do Senhor; e a cabeça de Dagom e ambas as palmas das suas mãos estavam cortadas sobre o limiar; somente o tronco ficou a Dagom.',
        'Por isso nem os sacerdotes de Dagom, nem nenhum de todos os que entram na casa de Dagom pisam o limiar de Dagom em Asdode, até ao dia de hoje.',
        'Porém a mão do Senhor se agravou sobre os de Asdode, e os assolou; e os feriu com hemorróidas, em Asdode e nos seus termos.',
        'Vendo então os homens de Asdode que assim foi, disseram: Não fique conosco a arca do Deus de Israel; pois a sua mão é dura sobre nós, e sobre Dagom, nosso deus.',
        'Por isso enviaram mensageiros e congregaram a si todos os príncipes dos filisteus, e disseram: Que faremos nós da arca do Deus de Israel? E responderam: a arca do Deus de Israel será levada até Gate. Assim levaram para lá a arca do Deus de Israel.',
        'E sucedeu que, assim que a levaram, a mão do Senhor veio contra aquela cidade, com mui grande vexame; pois feriu aos homens daquela cidade, desde o pequeno até ao grande; e tinham hemorróidas nas partes íntimas.',
        'Então enviaram a arca de Deus a Ecrom. Sucedeu, porém, que, vindo a arca de Deus a Ecrom, os de Ecrom exclamaram, dizendo: Transportaram para nós a arca do Deus de Israel, para nos matarem, a nós e ao nosso povo.',
        'E enviaram, e congregaram a todos os príncipes dos filisteus, e disseram: Enviai a arca do Deus de Israel, e torne para o seu lugar, para que não mate nem a nós nem ao nosso povo. Porque havia mortal vexame em toda a cidade, e a mão de Deus muito se agravara ali.',
        'E os homens que não morriam eram tão atacados com hemorróidas que o clamor da cidade subia até o céu.',
      ],
      [
        'Havendo, pois, estado a arca do SENHOR na terra dos filisteus sete meses,',
        'Os filisteus chamaram os sacerdotes e os adivinhadores, dizendo: Que faremos nós com a arca do Senhor? Fazei-nos saber como a tornaremos a enviar ao seu lugar.',
        'Os quais disseram: Se enviardes a arca do Deus de Israel, não a envieis vazia, porém sem falta enviareis uma oferta para a expiação da culpa; então sereis curados, e se vos fará saber porque a sua mão não se retira de vós.',
        'Então disseram: Qual é a expiação da culpa que lhe havemos de enviar? E disseram: Segundo o número dos príncipes dos filisteus, cinco hemorróidas de ouro e cinco ratos de ouro; porquanto a praga é uma mesma sobre todos vós e sobre todos os vossos príncipes.',
        'Fazei, pois, umas imagens das vossas hemorróidas e dos vossos ratos, que andam destruindo a terra, e dai glória ao Deus de Israel; porventura aliviará a sua mão de cima de vós, e de cima do vosso deus, e de cima da vossa terra.',
        'Por que, pois, endureceríeis o vosso coração, como os egípcios e Faraó endureceram os seus corações? Porventura depois de os haver tratado tão mal, os não deixaram ir, e eles não se foram?',
        'Agora, pois, tomai e fazei-vos um carro novo, e tomai duas vacas com crias, sobre as quais não tenha subido o jugo, e atai as vacas ao carro, e tirai delas os seus bezerros e levai-os para casa.',
        'Então tomai a arca do Senhor, e ponde-a sobre o carro, e colocai, num cofre, ao seu lado, as figuras de ouro que lhe haveis de oferecer em expiação da culpa, e assim a enviareis, para que se vá.',
        'Vede então: Se ela subir pelo caminho do seu termo a Bete-Semes, foi ele quem nos fez este grande mal; e, se não, saberemos que não nos tocou a sua mão, e que isto nos sucedeu por acaso.',
        'E assim fizeram aqueles homens, e tomaram duas vacas que criavam, e as ataram ao carro; e os seus bezerros encerraram em casa.',
        'E puseram a arca do Senhor sobre o carro, como também o cofre com os ratos de ouro e com as imagens das suas hemorróidas.',
        'Então as vacas se encaminharam diretamente pelo caminho de Bete-Semes, e seguiam um mesmo caminho, andando e berrando, sem se desviarem, nem para a direita nem para a esquerda; e os príncipes dos filisteus foram atrás delas, até ao termo de Bete-Semes.',
        'E andavam os de Bete-Semes fazendo a sega do trigo no vale, e, levantando os seus olhos, viram a arca, e, vendo-a, se alegraram.',
        'E o carro veio ao campo de Josué, o bete-semita, e parou ali onde havia uma grande pedra. E fenderam a madeira do carro, e ofereceram as vacas ao Senhor em holocausto.',
        'E os levitas desceram a arca do Senhor, como também o cofre que estava junto a ela, em que estavam os objetos de ouro, e puseram-nos sobre aquela grande pedra; e os homens de Bete-Semes ofereceram holocaustos e sacrifícios ao Senhor no mesmo dia.',
        'E, vendo aquilo os cinco príncipes dos filisteus, voltaram para Ecrom no mesmo dia.',
        'Estas, pois, são as hemorróidas de ouro que enviaram os filisteus ao Senhor em expiação da culpa: Por Asdode uma, por Gaza outra, por Ascalom outra, por Gate outra, por Ecrom outra.',
        'Como também os ratos de ouro, segundo o número de todas as cidades dos filisteus, pertencentes aos cinco príncipes, desde as cidades fortificadas até às aldeias, e até Abel. A grande pedra, sobre a qual puseram a arca do Senhor, ainda está até ao dia de hoje no campo de Josué, o bete-semita.',
        'E o Senhor feriu os homens de Bete-Semes, porquanto olharam para dentro da arca do Senhor; feriu do povo cinqüenta mil e setenta homens; então o povo se entristeceu, porquanto o Senhor fizera tão grande estrago entre o povo.',
        'Então disseram os homens de Bete-Semes: Quem poderia subsistir perante este santo Senhor Deus? E a quem subirá de nós?',
        'Enviaram, pois, mensageiros aos habitantes de Quiriate-Jearim, dizendo: Os filisteus remeteram a arca do Senhor; descei, pois, e fazei-a subir para vós.',
      ],
      [
        'Então vieram os homens de Quiriate-Jearim, e levaram a arca do SENHOR, e a trouxeram à casa de Abinadabe, no outeiro; e consagraram a Eleazar, seu filho, para que guardasse a arca do SENHOR.',
        'E sucedeu que, desde aquele dia, a arca ficou em Quiriate-Jearim, e tantos dias se passaram que até chegaram vinte anos, e lamentava toda a casa de Israel pelo Senhor.',
        'Então falou Samuel a toda a casa de Israel, dizendo: Se com todo o vosso coração vos converterdes ao Senhor, tirai dentre vós os deuses estranhos e os astarotes, e preparai o vosso coração ao Senhor, e servi a ele só, e vos livrará da mão dos filisteus.',
        'Então os filhos de Israel tiraram dentre si aos baalins e aos astarotes, e serviram só ao Senhor.',
        'Disse mais Samuel: Congregai a todo o Israel em Mizpá; e orarei por vós ao Senhor.',
        'E congregaram-se em Mizpá, e tiraram água, e a derramaram perante o Senhor, e jejuaram aquele dia, e disseram ali: Pecamos contra o Senhor. E julgava Samuel os filhos de Israel em Mizpá.',
        'Ouvindo, pois, os filisteus que os filhos de Israel estavam congregados em Mizpá, subiram os maiorais dos filisteus contra Israel; o que ouvindo os filhos de Israel, temeram por causa dos filisteus.',
        'Por isso disseram os filhos de Israel a Samuel: Não cesses de clamar ao Senhor nosso Deus por nós, para que nos livre da mão dos filisteus.',
        'Então tomou Samuel um cordeiro de mama, e sacrificou-o inteiro em holocausto ao Senhor; e clamou Samuel ao Senhor por Israel, e o Senhor lhe deu ouvidos.',
        'E sucedeu que, estando Samuel sacrificando o holocausto, os filisteus chegaram à peleja contra Israel; e trovejou o Senhor aquele dia com grande estrondo sobre os filisteus, e os confundiu de tal modo que foram derrotados diante dos filhos de Israel.',
        'E os homens de Israel saíram de Mizpá; e perseguiram os filisteus, e os feriram até abaixo de Bete-Car.',
        'Então tomou Samuel uma pedra, e a pôs entre Mizpá e Sem, e chamou-lhe Ebenézer; e disse: Até aqui nos ajudou o Senhor.',
        'Assim os filisteus foram abatidos, e nunca mais vieram aos termos de Israel, porquanto foi a mão do Senhor contra os filisteus todos os dias de Samuel.',
        'E as cidades que os filisteus tinham tomado a Israel foram-lhe restituídas, desde Ecrom até Gate, e até os seus termos Israel arrebatou da mão dos filisteus; e houve paz entre Israel e entre os amorreus.',
        'E Samuel julgou a Israel todos os dias da sua vida.',
        'E ia de ano em ano, e rodeava a Betel, e a Gilgal, e a Mizpá, e julgava a Israel em todos aqueles lugares.',
        'Porém voltava a Ramá, porque estava ali a sua casa, e ali julgava a Israel; e edificou ali um altar ao Senhor.',
      ],
      [
        'E sucedeu que, tendo Samuel envelhecido, constituiu a seus filhos por juízes sobre Israel.',
        'E o nome do seu filho primogênito era Joel, e o nome do seu segundo, Abia; e foram juízes em Berseba.',
        'Porém seus filhos não andaram pelos caminhos dele, antes se inclinaram à avareza, e aceitaram suborno, e perverteram o direito.',
        'Então todos os anciãos de Israel se congregaram, e vieram a Samuel, a Ramá,',
        'E disseram-lhe: Eis que já estás velho, e teus filhos não andam pelos teus caminhos; constitui-nos, pois, agora um rei sobre nós, para que ele nos julgue, como o têm todas as nações.',
        'Porém esta palavra pareceu mal aos olhos de Samuel, quando disseram: Dá-nos um rei, para que nos julgue. E Samuel orou ao Senhor.',
        'E disse o Senhor a Samuel: Ouve a voz do povo em tudo quanto te dizem, pois não te têm rejeitado a ti, antes a mim me têm rejeitado, para eu não reinar sobre eles.',
        'Conforme a todas as obras que fizeram desde o dia em que os tirei do Egito até ao dia de hoje, a mim me deixaram, e a outros deuses serviram, assim também fazem a ti.',
        'Agora, pois, ouve à sua voz, porém protesta-lhes solenemente, e declara-lhes qual será o costume do rei que houver de reinar sobre eles.',
        'E falou Samuel todas as palavras do Senhor ao povo, que lhe pedia um rei.',
        'E disse: Este será o costume do rei que houver de reinar sobre vós; ele tomará os vossos filhos, e os empregará nos seus carros, e como seus cavaleiros, para que corram adiante dos seus carros.',
        'E os porá por chefes de mil, e de cinqüenta; e para que lavrem a sua lavoura, e façam a sua sega, e fabriquem as suas armas de guerra e os petrechos de seus carros.',
        'E tomará as vossas filhas para perfumistas, cozinheiras e padeiras.',
        'E tomará o melhor das vossas terras, e das vossas vinhas, e dos vossos olivais, e os dará aos seus servos.',
        'E as vossas sementes, e as vossas vinhas dizimará, para dar aos seus oficiais, e aos seus servos.',
        'Também os vossos servos, e as vossas servas, e os vossos melhores moços, e os vossos jumentos tomará, e os empregará no seu trabalho.',
        'Dizimará o vosso rebanho, e vós lhe servireis de servos.',
        'Então naquele dia clamareis por causa do vosso rei, que vós houverdes escolhido; mas o Senhor não vos ouvirá naquele dia.',
        'Porém o povo não quis ouvir a voz de Samuel; e disseram: Não, mas haverá sobre nós um rei.',
        'E nós também seremos como todas as outras nações; e o nosso rei nos julgará, e sairá adiante de nós, e fará as nossas guerras.',
        'Ouvindo, pois, Samuel todas as palavras do povo, as repetiu aos ouvidos do Senhor.',
        'Então o Senhor disse a Samuel: Dá ouvidos à sua voz, e constitui-lhes rei. Então Samuel disse aos homens de Israel: Volte cada um à sua cidade.',
      ],
      [
        'E havia um homem de Benjamim, cujo nome era Quis, filho de Abiel, filho de Zeror, filho de Becorate, filho de Afia, filho de um homem de Benjamim; homem poderoso.',
        'Este tinha um filho, cujo nome era Saul, moço, e tão belo que entre os filhos de Israel não havia outro homem mais belo do que ele; desde os ombros para cima sobressaía a todo o povo.',
        'E perderam-se as jumentas de Quis, pai de Saul; por isso disse Quis a Saul, seu filho: Toma agora contigo um dos moços, e levanta-te e vai procurar as jumentas.',
        'Passaram, pois, pela montanha de Efraim, e dali passaram à terra de Salisa, porém não as acharam; depois passaram à terra de Saalim, porém tampouco estavam ali; também passaram à terra de Benjamim, porém tampouco as acharam.',
        'Vindo eles então à terra de Zufe, Saul disse para o seu moço, com quem ele ia: Vem, e voltemos; para que porventura meu pai não deixe de inquietar-se pelas jumentas e se aflija por causa de nós.',
        'Porém ele lhe disse: Eis que há nesta cidade um homem de Deus, e homem honrado é; tudo quanto diz, sucede assim infalivelmente; vamo-nos agora lá; porventura nos mostrará o caminho que devemos seguir.',
        'Então Saul disse ao seu moço: Eis, porém, se lá formos, que levaremos então àquele homem? Porque o pão de nossos alforjes se acabou, e presente nenhum temos para levar ao homem de Deus; que temos?',
        'E o moço tornou a responder a Saul, e disse: Eis que ainda se acha na minha mão um quarto de um siclo de prata, o qual darei ao homem de Deus, para que nos mostre o caminho',
        '(Antigamente em Israel, indo alguém consultar a Deus, dizia assim: Vinde, e vamos ao vidente; porque ao profeta de hoje, antigamente se chamava vidente).',
        'Então disse Saul ao moço: Bem dizes; vem, pois, vamos. E foram-se à cidade onde estava o homem de Deus.',
        'E, subindo eles à cidade, acharam umas moças que saíam a tirar água; e disseram-lhes: Está aqui o vidente?',
        'E elas lhes responderam, e disseram: Sim, eis aí o tens diante de ti; apressa-te, pois, porque hoje veio à cidade; porquanto o povo tem hoje sacrifício no alto.',
        'Entrando vós na cidade, logo o achareis, antes que suba ao alto para comer; porque o povo não comerá, até que ele venha; porque ele é o que abençoa o sacrifício, e depois comem os convidados; subi, pois, agora, que hoje o achareis.',
        'Subiram, pois, à cidade; e, vindo eles no meio da cidade, eis que Samuel lhes saiu ao encontro, para subir ao alto.',
        'Porque o Senhor revelara isto aos ouvidos de Samuel, um dia antes que Saul viesse, dizendo:',
        'Amanhã a estas horas te enviarei um homem da terra de Benjamim, o qual ungirás por capitão sobre o meu povo de Israel, e ele livrará o meu povo da mão dos filisteus; porque tenho olhado para o meu povo; porque o seu clamor chegou a mim.',
        'E quando Samuel viu a Saul, o Senhor lhe respondeu: Eis aqui o homem de quem eu te falei. Este dominará sobre o meu povo.',
        'E Saul se chegou a Samuel no meio da porta, e disse: Mostra-me, peço-te, onde está a casa do vidente.',
        'E Samuel respondeu a Saul, e disse: Eu sou o vidente; sobe diante de mim ao alto, e comei hoje comigo; e pela manhã te despedirei, e tudo quanto está no teu coração, to declararei.',
        'E quanto às jumentas que há três dias se te perderam, não ocupes o teu coração com elas, porque já se acharam. E para quem é todo o desejo de Israel? Porventura não é para ti, e para toda a casa de teu pai?',
        'Então respondeu Saul, e disse: Porventura não sou eu filho de Benjamim, da menor das tribos de Israel? E a minha família a menor de todas as famílias da tribo de Benjamim? Por que, pois, me falas com semelhantes palavras?',
        'Porém Samuel tomou a Saul e ao seu moço, e os levou à câmara; e deu-lhes lugar acima de todos os convidados, que eram uns trinta homens.',
        'Então disse Samuel ao cozinheiro: Dá aqui a porção que te dei, de que te disse: Põe-na à parte contigo.',
        'Levantou, pois, o cozinheiro a espádua, com o que havia nela, e pô-la diante de Saul; e disse Samuel: Eis que o que foi reservado está diante de ti. Come; porque se guardou para ti para esta ocasião, dizendo eu: Tenho convidado o povo. Assim comeu Saul aquele dia com Samuel.',
        'Então desceram do alto para a cidade; e falou com Saul sobre o eirado.',
        'E se levantaram de madrugada; e sucedeu que, quase ao subir da alva, chamou Samuel a Saul ao eirado, dizendo: Levanta-te, e despedir-te-ei. Levantou-se Saul, e saíram ambos para fora, ele e Samuel.',
        'E, descendo eles para a extremidade da cidade, Samuel disse a Saul: Dize ao moço que passe adiante de nós (e passou); porém tu espera agora, e te farei ouvir a palavra de Deus.',
      ],
      [
        'Então tomou Samuel um vaso de azeite, e lho derramou sobre a cabeça, e beijou-o, e disse: Porventura não te ungiu o SENHOR por capitão sobre a sua herança?',
        'Apartando-te hoje de mim, acharás dois homens junto ao sepulcro de Raquel, no termo de Benjamim, em Zelza, os quais te dirão: Acharam-se as jumentas que foste buscar, e eis que já o teu pai deixou o negócio das jumentas, e anda aflito por causa de vós, dizendo: Que farei eu por meu filho?',
        'E quando dali passares mais adiante, e chegares ao carvalho de Tabor, ali te encontrarão três homens, que vão subindo a Deus a Betel; um levando três cabritos, o outro três bolos de pão e o outro um odre de vinho.',
        'E te perguntarão como estás, e te darão dois pães, que tomarás das suas mãos.',
        'Então chegarás ao outeiro de Deus, onde está a guarnição dos filisteus; e há de ser que, entrando ali na cidade, encontrarás um grupo de profetas que descem do alto, e trazem diante de si saltérios, e tambores, e flautas, e harpas; e eles estarão profetizando.',
        'E o Espírito do Senhor se apoderará de ti, e profetizarás com eles, e tornar-te-ás um outro homem.',
        'E há de ser que, quando estes sinais te vierem, faze o que achar a tua mão, porque Deus é contigo.',
        'Tu, porém, descerás antes de mim a Gilgal, e eis que eu descerei a ti, para sacrificar holocaustos, e para oferecer ofertas pacíficas; ali sete dias esperarás, até que eu venha a ti, e te declare o que hás de fazer.',
        'Sucedeu, pois, que, virando ele as costas para partir de Samuel, Deus lhe mudou o coração em outro; e todos aqueles sinais aconteceram naquele mesmo dia.',
        'E, chegando eles ao outeiro, eis que um grupo de profetas lhes saiu ao encontro; e o Espírito de Deus se apoderou dele, e profetizou no meio deles.',
        'E aconteceu que, como todos os que antes o conheciam viram que ele profetizava com os profetas, então disse o povo, cada um ao seu companheiro: Que é o que sucedeu ao filho de Quis? Está também Saul entre os profetas?',
        'Então um homem dali respondeu, e disse: Pois quem é o pai deles? Pelo que se tornou em provérbio: Está Saul também entre os profetas?',
        'E, acabando de profetizar, foi ao alto.',
        'E disse-lhe o tio de Saul, a ele e ao seu moço: Aonde fostes? E disse ele: A buscar as jumentas, e, vendo que não apareciam, fomos a Samuel.',
        'Então disse o tio de Saul: Declara-me, peço-te, o que vos disse Samuel?',
        'E disse Saul a seu tio: Declarou-nos, na verdade, que as jumentas foram encontradas. Porém o negócio do reino, de que Samuel falara, não lhe declarou.',
        'Convocou, pois, Samuel o povo ao Senhor, em Mizpá.',
        'E disse aos filhos de Israel: Assim disse o Senhor Deus de Israel: Eu fiz subir a Israel do Egito, e livrei-vos da mão dos egípcios e da mão de todos os reinos que vos oprimiam.',
        'Mas vós tendes rejeitado hoje a vosso Deus, que vos livrou de todos os vossos males e trabalhos, e lhe tendes falado: Põe um rei sobre nós. Agora, pois, ponde-vos perante o Senhor, pelas vossas tribos e segundo os vossos milhares.',
        'Tendo, pois, Samuel feito chegar todas as tribos, tomou-se a tribo de Benjamim.',
        'E, fazendo chegar a tribo de Benjamim pelas suas famílias, tomou-se a família de Matri; e dela se tomou Saul, filho de Quis; e o buscaram, porém não se achou.',
        'Então tornaram a perguntar ao Senhor se aquele homem ainda viria ali. E disse o Senhor: Eis que se escondeu entre a bagagem.',
        'E correram, e o tomaram dali, e pôs-se no meio do povo; e era mais alto do que todo o povo desde o ombro para cima.',
        'Então disse Samuel a todo o povo: Vedes já a quem o Senhor escolheu? Pois em todo o povo não há nenhum semelhante a ele. Então jubilou todo o povo, e disse: Viva o rei!',
        'E declarou Samuel ao povo o direito do reino, e escreveu-o num livro, e pô-lo perante o Senhor; então despediu Samuel a todo o povo, cada um para sua casa.',
        'E foi também Saul à sua casa, em Gibeá; e foram com ele do exército aqueles cujos corações Deus tocara.',
        'Mas os filhos de Belial disseram: É este o que nos há de livrar? E o desprezaram, e não lhe trouxeram presentes; porém ele se fez como surdo.',
      ],
      [
        'Então subiu Naás, amonita, e sitiou a Jabes-Gileade; e disseram todos os homens de Jabes a Naás: Faze aliança conosco, e te serviremos.',
        'Porém Naás, amonita, lhes disse: Com esta condição farei aliança convosco: que a todos vos arranque o olho direito, e assim ponha esta afronta sobre todo o Israel.',
        'Então os anciãos de Jabes lhe disseram: Deixa-nos por sete dias, para que enviemos mensageiros por todos os termos de Israel, e, não havendo ninguém que nos livre, então viremos a ti.',
        'E, vindo os mensageiros a Gibeá de Saul, falaram estas palavras aos ouvidos do povo. Então todo o povo levantou a sua voz, e chorou.',
        'E eis que Saul vinha do campo, atrás dos bois; e disse Saul: Que tem o povo, que chora? E contaram-lhe as palavras dos homens de Jabes.',
        'Então o Espírito de Deus se apoderou de Saul, ouvindo estas palavras; e acendeu-se em grande maneira a sua ira.',
        'E tomou uma junta de bois, e cortou-os em pedaços, e os enviou a todos os termos de Israel pelas mãos dos mensageiros, dizendo: Qualquer que não seguir a Saul e a Samuel, assim se fará aos seus bois. Então caiu o temor do Senhor sobre o povo, e saíram como um só homem.',
        'E contou-os em Bezeque; e houve dos filhos de Israel trezentos mil, e dos homens de Judá trinta mil.',
        'Então disseram aos mensageiros que vieram: Assim direis aos homens de Jabes-Gileade: Amanhã, em aquecendo o sol, vos virá livramento. Vindo, pois, os mensageiros, e anunciando-o aos homens de Jabes, se alegraram.',
        'E os homens de Jabes disseram aos amonitas: Amanhã sairemos a vós; então nos fareis conforme a tudo o que parecer bem aos vossos olhos.',
        'E sucedeu que ao outro dia Saul pôs o povo em três companhias, e vieram ao meio do arraial pela vigília da manhã, e feriram aos amonitas até que o dia aqueceu; e sucedeu que os restantes se espalharam, de modo que não ficaram dois deles juntos.',
        'Então disse o povo a Samuel: Quem é aquele que dizia que Saul não reinaria sobre nós? Dai-nos aqueles homens, e os mataremos.',
        'Porém Saul disse: Hoje não morrerá nenhum, pois hoje tem feito o Senhor um livramento em Israel.',
        'E disse Samuel ao povo: Vinde, vamos nós a Gilgal, e renovemos ali o reino.',
        'E todo o povo partiu para Gilgal, onde proclamaram a Saul por rei perante o Senhor, e ofereceram ali ofertas pacíficas perante o Senhor; e Saul se alegrou muito ali com todos os homens de Israel.',
      ],
      [
        'Então disse Samuel a todo o Israel: Eis que ouvi a vossa voz em tudo quanto me dissestes, e constituí sobre vós um rei.',
        'Agora, pois, eis que o rei vai adiante de vós. Eu já envelheci e encaneci, e eis que meus filhos estão convosco, e tenho andado diante de vós desde a minha mocidade até ao dia de hoje.',
        'Eis-me aqui; testificai contra mim perante o Senhor, e perante o seu ungido, a quem o boi tomei, a quem o jumento tomei, e a quem defraudei, a quem tenho oprimido, e de cuja mão tenho recebido suborno e com ele encobri os meus olhos, e vo-lo restituirei.',
        'Então disseram: Em nada nos defraudaste, nem nos oprimiste, nem recebeste coisa alguma da mão de ninguém.',
        'E ele lhes disse: O Senhor seja testemunha contra vós, e o seu ungido seja hoje testemunha, que nada tendes achado na minha mão. E disse o povo: Ele é testemunha.',
        'Então disse Samuel ao povo: O Senhor é o que escolheu a Moisés e a Arão, e tirou a vossos pais da terra do Egito.',
        'Agora, pois, ponde-vos aqui em pé, e pleitearei convosco perante o Senhor, sobre todos os atos de justiça do Senhor, que fez a vós e a vossos pais.',
        'Havendo entrado Jacó no Egito, vossos pais clamaram ao Senhor, e o Senhor enviou a Moisés e a Arão que tiraram a vossos pais do Egito, e os fizeram habitar neste lugar.',
        'Porém esqueceram-se do Senhor seu Deus; então os vendeu à mão de Sísera, capitão do exército de Hazor, e na mão dos filisteus, e na mão do rei dos moabitas, que pelejaram contra eles.',
        'E clamaram ao Senhor, e disseram: Pecamos, pois deixamos ao Senhor, e servimos aos baalins e astarotes; agora, pois, livra-nos da mão de nossos inimigos, e te serviremos.',
        'E o Senhor enviou a Jerubaal, e a Bedã, e a Jefté, e a Samuel; e livrou-vos da mão de vossos inimigos em redor, e habitastes seguros.',
        'E vendo vós que Naás, rei dos filhos de Amom, vinha contra vós, me dissestes: Não, mas reinará sobre nós um rei; sendo, porém, o Senhor vosso Deus, o vosso rei.',
        'Agora, pois, vedes aí o rei que elegestes e que pedistes; e eis que o Senhor tem posto sobre vós um rei.',
        'Se temerdes ao Senhor, e o servirdes, e derdes ouvidos à sua voz, e não fordes rebeldes ao mandado do Senhor, assim vós, como o rei que reina sobre vós, seguireis o Senhor vosso Deus.',
        'Mas se não derdes ouvidos à voz do Senhor, e antes fordes rebeldes ao mandado do Senhor, a mão do Senhor será contra vós, como o era contra vossos pais.',
        'Ponde-vos também agora aqui, e vede esta grande coisa que o Senhor vai fazer diante dos vossos olhos.',
        'Não é hoje a sega do trigo? Clamarei, pois, ao Senhor, e dará trovões e chuva; e sabereis e vereis que é grande a vossa maldade, que tendes feito perante o Senhor, pedindo para vós um rei.',
        'Então invocou Samuel ao Senhor, e o Senhor deu trovões e chuva naquele dia; por isso todo o povo temeu sobremaneira ao Senhor e a Samuel.',
        'E todo o povo disse a Samuel: Roga pelos teus servos ao Senhor teu Deus, para que não venhamos a morrer; porque a todos os nossos pecados temos acrescentado este mal, de pedirmos para nós um rei.',
        'Então disse Samuel ao povo: Não temais; vós tendes cometido todo este mal; porém não vos desvieis de seguir ao Senhor, mas servi ao Senhor com todo o vosso coração.',
        'E não vos desvieis; pois seguiríeis as vaidades, que nada aproveitam, e tampouco vos livrarão, porque vaidades são.',
        'Pois o Senhor, por causa do seu grande nome, não desamparará o seu povo; porque aprouve ao Senhor fazer-vos o seu povo.',
        'E quanto a mim, longe de mim que eu peque contra o Senhor, deixando de orar por vós; antes vos ensinarei o caminho bom e direito.',
        'Tão-somente temei ao Senhor, e servi-o fielmente com todo o vosso coração; porque vede quão grandiosas coisas vos fez.',
        'Porém, se perseverardes em fazer mal, perecereis, assim vós como o vosso rei.',
      ],
      [
        'Saul reinou um ano; e no segundo ano do seu reinado sobre Israel,',
        'Saul escolheu para si três mil homens de Israel; e estavam com Saul dois mil em Micmás e na montanha de Betel, e mil estavam com Jônatas em Gibeá de Benjamim; e o resto do povo despediu, cada um para sua casa.',
        'E Jônatas feriu a guarnição dos filisteus, que estava em Gibeá, o que os filisteus ouviram; pelo que Saul tocou a trombeta por toda a terra, dizendo: Ouçam os hebreus.',
        'Então todo o Israel ouviu dizer: Saul feriu a guarnição dos filisteus, e também Israel se fez abominável aos filisteus. Então o povo foi convocado para junto de Saul em Gilgal.',
        'E os filisteus se ajuntaram para pelejar contra Israel, trinta mil carros, e seis mil cavaleiros, e povo em multidão como a areia que está à beira do mar; e subiram, e se acamparam em Micmás, ao oriente de Bete-Áven.',
        'Vendo, pois, os homens de Israel que estavam em apuros (porque o povo estava angustiado), o povo se escondeu pelas cavernas, e pelos espinhais, e pelos penhascos, e pelas fortificações, e pelas covas.',
        'E alguns dos hebreus passaram o Jordão para a terra de Gade e Gileade; e, estando Saul ainda em Gilgal, todo o povo ia atrás dele tremendo.',
        'E esperou Saul sete dias, até ao tempo que Samuel determinara; não vindo, porém, Samuel a Gilgal, o povo se dispersava dele.',
        'Então disse Saul: Trazei-me aqui um holocausto, e ofertas pacíficas. E ofereceu o holocausto.',
        'E sucedeu que, acabando ele de oferecer o holocausto, eis que Samuel chegou; e Saul lhe saiu ao encontro, para o saudar.',
        'Então disse Samuel: Que fizeste? Disse Saul: Porquanto via que o povo se espalhava de mim, e tu não vinhas nos dias aprazados, e os filisteus já se tinham ajuntado em Micmás,',
        'Eu disse: Agora descerão os filisteus sobre mim a Gilgal, e ainda à face do Senhor não orei; e constrangi-me, e ofereci holocausto.',
        'Então disse Samuel a Saul: Procedeste nesciamente, e não guardaste o mandamento que o Senhor teu Deus te ordenou; porque agora o Senhor teria confirmado o teu reino sobre Israel para sempre;',
        'Porém agora não subsistirá o teu reino; já tem buscado o Senhor para si um homem segundo o seu coração, e já lhe tem ordenado o Senhor, que seja capitão sobre o seu povo, porquanto não guardaste o que o Senhor te ordenou.',
        'Então se levantou Samuel, e subiu de Gilgal a Gibeá de Benjamim; e Saul contou o povo que se achava com ele, uns seiscentos homens.',
        'E Saul e Jônatas, seu filho, e o povo que se achou com eles, ficaram em Gibeá de Benjamim; porém os filisteus se acamparam em Micmás.',
        'E os saqueadores saíram do campo dos filisteus em três companhias; uma das companhias foi pelo caminho de Ofra à terra de Sual.',
        'Outra companhia seguiu pelo caminho de Bete-Horom, e a outra companhia foi pelo caminho do termo que dá para o vale Zeboim na direção do deserto.',
        'E em toda a terra de Israel nem um ferreiro se achava, porque os filisteus tinham dito: Para que os hebreus não façam espada nem lança.',
        'Por isso todo o Israel tinha que descer aos filisteus para amolar cada um a sua relha, e a sua enxada, e o seu machado, e o seu sacho.',
        'Tinham porém limas para os seus sachos, e para as suas enxadas, e para as forquilhas de três dentes, e para os machados, e para consertar as aguilhadas.',
        'E sucedeu que, no dia da peleja, não se achou nem espada nem lança na mão de todo o povo que estava com Saul e com Jônatas; porém acharam-se com Saul e com Jônatas seu filho.',
        'E saiu a guarnição dos filisteus ao desfiladeiro de Micmás.',
      ],
      [
        'Sucedeu, pois, que um dia disse Jônatas, filho de Saul, ao moço que lhe levava as armas: Vem, passemos à guarnição dos filisteus, que está lá daquele lado. Porém não o fez saber a seu pai.',
        'E estava Saul à extremidade de Gibeá, debaixo da romeira que havia em Migrom; e o povo que estava com ele era uns seiscentos homens.',
        'E Aías, filho de Aitube, irmão de Icabode, o filho de Finéias, filho de Eli, sacerdote do Senhor em Siló, trazia o éfode; porém o povo não sabia que Jônatas tinha ido.',
        'E entre os desfiladeiros pelos quais Jônatas procurava passar à guarnição dos filisteus, deste lado havia uma penha aguda, e do outro lado uma penha aguda; e era o nome de uma Bozez, e o nome da outra Sené.',
        'Uma penha para o norte estava defronte de Micmás, e a outra para o sul, defronte de Gibeá.',
        'Disse, pois, Jônatas ao moço que lhe levava as armas: Vem, passemos à guarnição destes incircuncisos; porventura operará o Senhor por nós, porque para com o Senhor nenhum impedimento há de livrar com muitos ou com poucos.',
        'Então o seu pajem de armas lhe disse: Faze tudo o que tens no coração; segue, eis-me aqui contigo, conforme o que quiseres.',
        'Disse, pois, Jônatas: Eis que passaremos àqueles homens, e nos revelaremos a eles.',
        'Se nos disserem assim: Parai até que cheguemos a vós; então ficaremos no nosso lugar, e não subiremos a eles.',
        'Porém, se disserem: Subi a nós; então subiremos, pois o Senhor os tem entregado nas nossas mãos, e isto nos será por sinal.',
        'Revelando-se eles à guarnição dos filisteus, disseram os filisteus: Eis que já os hebreus saíram das cavernas em que se tinham escondido.',
        'E os homens da guarnição responderam a Jônatas e ao seu pajem de armas, e disseram: Subi a nós, e nós vos ensinaremos uma lição. E disse Jônatas ao seu pajem de armas: Sobe atrás de mim, porque o Senhor os tem entregado na mão de Israel.',
        'Então subiu Jônatas com os pés e com as mãos, e o seu pajem de armas atrás dele; e os filisteus caíam diante de Jônatas, e o seu pajem de armas os matava atrás dele.',
        'E sucedeu esta primeira derrota, em que Jônatas e o seu pajem de armas feriram uns vinte homens, em cerca de meia jeira de terra que uma junta de bois podia lavrar.',
        'E houve tremor no arraial, no campo e em todo o povo; também a mesma guarnição e os saqueadores tremeram, até a terra se estremeceu porquanto era tremor de Deus.',
        'Olharam, pois, as sentinelas de Saul em Gibeá de Benjamim, e eis que a multidão se dissolvia, e fugia para cá e para lá.',
        'Disse então Saul ao povo que estava com ele: Ora contai, e vede quem é que saiu dentre nós. E contaram, e eis que nem Jônatas nem o seu pajem de armas estavam ali.',
        'Então Saul disse a Aías: Traze aqui a arca de Deus (porque naquele dia estava a arca de Deus com os filhos de Israel).',
        'E sucedeu que, estando Saul ainda falando com o sacerdote, o alvoroço que havia no arraial dos filisteus ia crescendo muito, e se multiplicava, pelo que disse Saul ao sacerdote: Retira a tua mão.',
        'Então Saul e todo o povo que havia com ele se reuniram, e foram à peleja; e eis que a espada de um era contra o outro, e houve mui grande tumulto.',
        'Também com os filisteus havia hebreus, como dantes, que subiram com eles ao arraial em redor; e também estes se ajuntaram com os israelitas que estavam com Saul e Jônatas.',
        'Ouvindo, pois, todos os homens de Israel que se esconderam pela montanha de Efraim que os filisteus fugiam, eles também os perseguiram de perto na peleja.',
        'Assim livrou o Senhor a Israel naquele dia; e o arraial passou a Bete-Áven.',
        'E estavam os homens de Israel já exaustos naquele dia, porquanto Saul conjurou o povo, dizendo: Maldito o homem que comer pão até à tarde, antes que me vingue de meus inimigos. Por isso todo o povo se absteve de provar pão.',
        'E todo o povo chegou a um bosque; e havia mel na superfície do campo.',
        'E, chegando o povo ao bosque, eis que havia um manancial de mel; porém ninguém chegou a mão à boca, porque o povo temia a conjuração.',
        'Porém Jônatas não tinha ouvido quando seu pai conjurara o povo, e estendeu a ponta da vara que tinha na mão, e a molhou no favo de mel; e, tornando a mão à boca, aclararam-se os seus olhos.',
        'Então respondeu um do povo, e disse: Solenemente conjurou teu pai o povo, dizendo: Maldito o homem que comer hoje pão. Por isso o povo desfalecia.',
        'Então disse Jônatas: Meu pai tem turbado a terra; ora vede como se me aclararam os olhos por ter provado um pouco deste mel,',
        'Quanto mais se o povo hoje livremente tivesse comido do despojo que achou de seus inimigos. Porém agora não foi tão grande o estrago dos filisteus.',
        'Feriram, porém, aquele dia aos filisteus, desde Micmás até Aijalom, e o povo desfaleceu em extremo.',
        'Então o povo se lançou ao despojo, e tomaram ovelhas, e vacas, e bezerros, e os degolaram no chão; e o povo os comeu com sangue.',
        'E o anunciaram a Saul, dizendo: Eis que o povo peca contra o Senhor, comendo com sangue. E disse: Aleivosamente procedestes; trazei-me aqui já uma grande pedra.',
        'Disse mais Saul: Dispersai-vos entre o povo, e dizei-lhes: Trazei-me cada um o seu boi, e cada um a sua ovelha, e degolai-os aqui, e comei, e não pequeis contra o Senhor, comendo com sangue. Então todo o povo trouxe de noite, cada um pela sua mão, o seu boi, e os degolaram ali.',
        'Então edificou Saul um altar ao Senhor; este foi o primeiro altar que edificou ao Senhor.',
        'Depois disse Saul: Desçamos de noite atrás dos filisteus, e despojemo-los, até que amanheça o dia, e não deixemos deles um só homem. E disseram: Tudo o que parecer bem aos teus olhos faze. Disse, porém, o sacerdote: Cheguemo-nos aqui a Deus.',
        'Então consultou Saul a Deus, dizendo: Descerei atrás dos filisteus? Entregá-los-ás na mão de Israel? Porém aquele dia não lhe respondeu.',
        'Então disse Saul: Chegai-vos para cá, todos os chefes do povo, e informai-vos, e vede em que se cometeu hoje este pecado.',
        'Porque vive o Senhor que salva a Israel, que, ainda que seja em meu filho Jônatas, certamente morrerá. E nenhum de todo o povo lhe respondeu.',
        'Disse mais a todo o Israel: Vós estareis de um lado, e eu e meu filho Jônatas estaremos do outro lado. Então disse o povo a Saul: Faze o que parecer bem aos teus olhos.',
        'Falou, pois, Saul ao Senhor Deus de Israel: Mostra o inocente. Então Jônatas e Saul foram tomados por sorte, e o povo saiu livre.',
        'Então disse Saul: Lançai a sorte entre mim e Jônatas, meu filho. E foi tomado Jônatas.',
        'Disse então Saul a Jônatas: Declara-me o que tens feito. E Jônatas lho declarou, e disse: Tão-somente provei um pouco de mel com a ponta da vara que tinha na mão; eis que devo morrer?',
        'Então disse Saul: Assim me faça Deus, e outro tanto, que com certeza morrerás, Jônatas.',
        'Porém o povo disse a Saul: Morrerá Jônatas, que efetuou tão grande salvação em Israel? Nunca tal suceda; vive o Senhor, que não lhe há de cair no chão um só cabelo da sua cabeça! pois com Deus fez isso hoje. Assim o povo livrou a Jônatas, para que não morresse.',
        'E Saul deixou de seguir os filisteus; e os filisteus se foram ao seu lugar.',
        'Então tomou Saul o reino sobre Israel; e pelejou contra todos os seus inimigos em redor; contra Moabe, e contra os filhos de Amom, e contra Edom, e contra os reis de Zobá, e contra os filisteus, e para onde quer que se tornava executava castigo.',
        'E houve-se valorosamente, e feriu aos amalequitas, e liberou a Israel da mão dos que o saqueavam.',
        'E os filhos de Saul eram Jônatas, e Isvi, e Malquisua; e os nomes de suas duas filhas eram estes: o da mais velha Merabe, e o da mais nova, Mical.',
        'E o nome da mulher de Saul, Ainoã, filha de Aimaás; e o nome do capitão do exército, Abner, filho de Ner, tio de Saul.',
        'E Quis, pai de Saul, e Ner, pai de Abner, eram filhos de Abiel.',
        'E houve uma forte guerra contra os filisteus, todos os dias de Saul; por isso Saul a todos os homens valentes e valorosos que via, os agregava a si.',
      ],
      [
        'Então disse Samuel a Saul: Enviou-me o SENHOR a ungir-te rei sobre o seu povo, sobre Israel; ouve, pois, agora a voz das palavras do SENHOR.',
        'Assim diz o Senhor dos Exércitos: Eu me recordei do que fez Amaleque a Israel; como se lhe opôs no caminho, quando subia do Egito.',
        'Vai, pois, agora e fere a Amaleque; e destrói totalmente a tudo o que tiver, e não lhe perdoes; porém matarás desde o homem até à mulher, desde os meninos até aos de peito, desde os bois até às ovelhas, e desde os camelos até aos jumentos.',
        'O que Saul convocou ao povo, e os contou em Telaim, duzentos mil homens de pé, e dez mil homens de Judá.',
        'Chegando, pois, Saul à cidade de Amaleque, pôs emboscada no vale.',
        'E disse Saul aos queneus: Ide-vos, retirai-vos e saí do meio dos amalequitas, para que não vos destrua juntamente com eles, porque vós usastes de misericórdia com todos os filhos de Israel, quando subiram do Egito. Assim os queneus se retiraram do meio dos amalequitas.',
        'Então feriu Saul aos amalequitas desde Havilá até chegar a Sur, que está defronte do Egito.',
        'E tomou vivo a Agague, rei dos amalequitas; porém a todo o povo destruiu ao fio da espada.',
        'E Saul e o povo pouparam a Agague, e ao melhor das ovelhas e das vacas, e as da segunda ordem, e aos cordeiros e ao melhor que havia, e não os quiseram destruir totalmente; porém a toda a coisa vil e desprezível destruíram totalmente.',
        'Então veio a palavra do Senhor a Samuel, dizendo:',
        'Arrependo-me de haver posto a Saul como rei; porquanto deixou de me seguir, e não cumpriu as minhas palavras. Então Samuel se contristou, e toda a noite clamou ao Senhor.',
        'E madrugou Samuel para encontrar a Saul pela manhã: e anunciou-se a Samuel, dizendo: Já chegou Saul ao Carmelo, e eis que levantou para si uma coluna. Então voltando, passou e desceu a Gilgal.',
        'Veio, pois, Samuel a Saul; e Saul lhe disse: Bendito sejas tu do Senhor; cumpri a palavra do Senhor.',
        'Então disse Samuel: Que balido, pois, de ovelhas é este aos meus ouvidos, e o mugido de vacas que ouço?',
        'E disse Saul: De Amaleque as trouxeram; porque o povo poupou ao melhor das ovelhas, e das vacas, para as oferecer ao Senhor teu Deus; o resto, porém, temos destruído totalmente.',
        'Então disse Samuel a Saul: Espera, e te declararei o que o Senhor me disse esta noite. E ele disse-lhe: Fala.',
        'E disse Samuel: Porventura, sendo tu pequeno aos teus olhos, não foste por cabeça das tribos de Israel? E o Senhor te ungiu rei sobre Israel.',
        'E enviou-te o Senhor a este caminho, e disse: Vai, e destrói totalmente a estes pecadores, os amalequitas, e peleja contra eles, até que os aniquiles.',
        'Por que, pois, não deste ouvidos à voz do Senhor, antes te lançaste ao despojo, e fizeste o que parecia mau aos olhos do Senhor?',
        'Então disse Saul a Samuel: Antes dei ouvidos à voz do Senhor, e caminhei no caminho pelo qual o Senhor me enviou; e trouxe a Agague, rei de Amaleque, e os amalequitas destruí totalmente;',
        'Mas o povo tomou do despojo ovelhas e vacas, o melhor do interdito, para oferecer ao Senhor teu Deus em Gilgal.',
        'Porém Samuel disse: Tem porventura o Senhor tanto prazer em holocaustos e sacrifícios, como em que se obedeça à palavra do Senhor? Eis que o obedecer é melhor do que o sacrificar; e o atender melhor é do que a gordura de carneiros.',
        'Porque a rebelião é como o pecado de feitiçaria, e o porfiar é como iniqüidade e idolatria. Porquanto tu rejeitaste a palavra do Senhor, ele também te rejeitou a ti, para que não sejas rei.',
        'Então disse Saul a Samuel: Pequei, porquanto tenho transgredido a ordem do Senhor e as tuas palavras; porque temi ao povo, e dei ouvidos à sua voz.',
        'Agora, pois, rogo-te perdoa o meu pecado; e volta comigo, para que adore ao Senhor.',
        'Porém Samuel disse a Saul: Não voltarei contigo; porquanto rejeitaste a palavra do Senhor, já te rejeitou o Senhor, para que não sejas rei sobre Israel.',
        'E virando-se Samuel para se ir, ele lhe pegou pela orla da capa, e a rasgou.',
        'Então Samuel lhe disse: O Senhor tem rasgado de ti hoje o reino de Israel, e o tem dado ao teu próximo, melhor do que tu.',
        'E também aquele que é a Força de Israel não mente nem se arrepende; porquanto não é um homem para que se arrependa.',
        'Disse ele então: Pequei; honra-me, porém, agora diante dos anciãos do meu povo, e diante de Israel; e volta comigo, para que adore ao Senhor teu Deus.',
        'Então, voltando Samuel, seguiu a Saul; e Saul adorou ao Senhor.',
        'Então disse Samuel: Trazei-me aqui a Agague, rei dos amalequitas. E Agague veio a ele animosamente; e disse Agague: Na verdade já passou a amargura da morte.',
        'Disse, porém, Samuel: Assim como a tua espada desfilhou as mulheres, assim ficará desfilhada a tua mãe entre as mulheres. Então Samuel despedaçou a Agague perante o Senhor em Gilgal.',
        'Então Samuel se foi a Ramá; e Saul subiu à sua casa, a Gibeá de Saul.',
        'E nunca mais viu Samuel a Saul até ao dia da sua morte; porque Samuel teve dó de Saul. E o Senhor se arrependeu de haver posto a Saul rei sobre Israel.',
      ],
      [
        'Então disse o SENHOR a Samuel: Até quando terás dó de Saul, havendo-o eu rejeitado, para que não reine sobre Israel? Enche um chifre de azeite, e vem, enviar-te-ei a Jessé o belemita; porque dentre os seus filhos me tenho provido de um rei.',
        'Porém disse Samuel: Como irei eu? pois, ouvindo-o Saul, me matará. Então disse o Senhor: Toma uma bezerra das vacas em tuas mãos, e dize: Vim para sacrificar ao Senhor.',
        'E convidarás a Jessé ao sacrifício; e eu te farei saber o que hás de fazer, e ungir-me-ás a quem eu te disser.',
        'Fez, pois, Samuel o que dissera o Senhor, e veio a Belém; então os anciãos da cidade saíram ao encontro, tremendo, e disseram: De paz é a tua vinda?',
        'E disse ele: É de paz, vim sacrificar ao Senhor; santificai-vos, e vinde comigo ao sacrifício. E santificou ele a Jessé e a seus filhos, e os convidou ao sacrifício.',
        'E sucedeu que, entrando eles, viu a Eliabe, e disse: Certamente está perante o Senhor o seu ungido.',
        'Porém o Senhor disse a Samuel: Não atentes para a sua aparência, nem para a grandeza da sua estatura, porque o tenho rejeitado; porque o Senhor não vê como vê o homem, pois o homem vê o que está diante dos olhos, porém o Senhor olha para o coração.',
        'Então chamou Jessé a Abinadabe, e o fez passar diante de Samuel, o qual disse: Nem a este tem escolhido o Senhor.',
        'Então Jessé fez passar a Sama; porém disse: Tampouco a este tem escolhido o Senhor.',
        'Assim fez passar Jessé a seus sete filhos diante de Samuel; porém Samuel disse a Jessé: O Senhor não tem escolhido a estes.',
        'Disse mais Samuel a Jessé: Acabaram-se os moços? E disse: Ainda falta o menor, que está apascentando as ovelhas. Disse, pois, Samuel a Jessé: Manda chamá-lo, porquanto não nos assentaremos até que ele venha aqui.',
        'Então mandou chamá-lo e fê-lo entrar (e era ruivo e formoso de semblante e de boa presença); e disse o Senhor: Levanta-te, e unge-o, porque é este mesmo.',
        'Então Samuel tomou o chifre do azeite, e ungiu-o no meio de seus irmãos; e desde aquele dia em diante o Espírito do Senhor se apoderou de Davi; então Samuel se levantou, e voltou a Ramá.',
        'E o Espírito do Senhor se retirou de Saul, e atormentava-o um espírito mau da parte do Senhor.',
        'Então os criados de Saul lhe disseram: Eis que agora o espírito mau da parte de Deus te atormenta;',
        'Diga, pois, nosso senhor a seus servos, que estão na tua presença, que busquem um homem que saiba tocar harpa, e será que, quando o espírito mau da parte de Deus vier sobre ti, então ele tocará com a sua mão, e te acharás melhor.',
        'Então disse Saul aos seus servos: Buscai-me, pois, um homem que toque bem, e trazei-mo.',
        'Então respondeu um dos moços, e disse: Eis que tenho visto a um filho de Jessé, o belemita, que sabe tocar e é valente e vigoroso, e homem de guerra, e prudente em palavras, e de gentil presença; o Senhor é com ele.',
        'E Saul enviou mensageiros a Jessé, dizendo: Envia-me Davi, teu filho, o que está com as ovelhas.',
        'Então tomou Jessé um jumento carregado de pão, e um odre de vinho, e um cabrito, e enviou-os a Saul pela mão de Davi, seu filho.',
        'Assim Davi veio a Saul, e esteve perante ele, e o amou muito, e foi seu pajem de armas.',
        'Então Saul mandou dizer a Jessé: Deixa estar a Davi perante mim, pois achou graça em meus olhos.',
        'E sucedia que, quando o espírito mau da parte de Deus vinha sobre Saul, Davi tomava a harpa, e a tocava com a sua mão; então Saul sentia alívio, e se achava melhor, e o espírito mau se retirava dele.',
      ],
      [
        'E os filisteus ajuntaram as suas forças para a guerra e congregaram-se em Socó, que está em Judá, e acamparam-se entre Socó e Azeca, no termo de Damim.',
        'Porém Saul e os homens de Israel se ajuntaram e acamparam no vale do carvalho, e ordenaram a batalha contra os filisteus.',
        'E os filisteus estavam num monte de um lado, e os israelitas estavam num monte do outro lado; e o vale estava entre eles.',
        'Então saiu do arraial dos filisteus um homem guerreiro, cujo nome era Golias, de Gate, que tinha de altura seis côvados e um palmo.',
        'Trazia na cabeça um capacete de bronze, e vestia uma couraça de escamas; e era o peso da couraça de cinco mil siclos de bronze.',
        'E trazia grevas de bronze por cima de seus pés, e um escudo de bronze entre os seus ombros.',
        'E a haste da sua lança era como o eixo do tecelão, e a ponta da sua lança de seiscentos siclos de ferro, e diante dele ia o escudeiro.',
        'E parou, e clamou às companhias de Israel, e disse-lhes: Para que saireis a ordenar a batalha? Não sou eu filisteu e vós servos de Saul? Escolhei dentre vós um homem que desça a mim.',
        'Se ele puder pelejar comigo, e me ferir, a vós seremos por servos; porém, se eu o vencer, e o ferir, então a nós sereis por servos, e nos servireis.',
        'Disse mais o filisteu: Hoje desafio as companhias de Israel, dizendo: Dai-me um homem, para que ambos pelejemos.',
        'Ouvindo então Saul e todo o Israel estas palavras do filisteu, espantaram-se, e temeram muito.',
        'E Davi era filho de um homem efrateu, de Belém de Judá, cujo nome era Jessé, que tinha oito filhos; e nos dias de Saul era este homem já velho e adiantado em idade entre os homens.',
        'Foram-se os três filhos mais velhos de Jessé, e seguiram a Saul à guerra; e eram os nomes de seus três filhos, que se foram à guerra, Eliabe, o primogênito, e o segundo Abinadabe, e o terceiro Sama.',
        'E Davi era o menor; e os três maiores seguiram a Saul.',
        'Davi, porém, ia e voltava de Saul, para apascentar as ovelhas de seu pai em Belém.',
        'Chegava-se, pois, o filisteu pela manhã e à tarde; e apresentou-se por quarenta dias.',
        'E disse Jessé a Davi, seu filho: Toma, peço-te, para teus irmãos um efa deste grão tostado e estes dez pães, e corre a levá-los ao arraial, a teus irmãos.',
        'Porém estes dez queijos de leite leva ao capitão de mil; e visitarás a teus irmãos, a ver se vão bem; e tomarás o seu penhor.',
        'E estavam Saul, e eles, e todos os homens de Israel no vale do carvalho, pelejando com os filisteus.',
        'Davi então se levantou de madrugada, pela manhã, e deixou as ovelhas com um guarda, e carregou-se, e partiu, como Jessé lhe ordenara; e chegou ao lugar dos carros, quando já o exército saía em ordem de batalha, e a gritos chamavam à peleja.',
        'E os israelitas e filisteus se puseram em ordem, fileira contra fileira.',
        'E Davi deixou a carga que trouxera na mão do guarda da bagagem, e correu à batalha; e, chegando, perguntou a seus irmãos se estavam bem.',
        'E, estando ele ainda falando com eles, eis que vinha subindo do exército dos filisteus o homem guerreiro, cujo nome era Golias, o filisteu de Gate; e falou conforme àquelas palavras, e Davi as ouviu.',
        'Porém todos os homens em Israel, vendo aquele homem, fugiram de diante dele, e temiam grandemente.',
        'E diziam os homens de Israel: Vistes aquele homem que subiu? Pois subiu para afrontar a Israel; há de ser, pois, que, o homem que o ferir, o rei o enriquecerá de grandes riquezas, e lhe dará a sua filha, e fará livre a casa de seu pai em Israel.',
        'Então falou Davi aos homens que estavam com ele, dizendo: Que farão àquele homem, que ferir a este filisteu, e tirar a afronta de sobre Israel? Quem é, pois, este incircunciso filisteu, para afrontar os exércitos do Deus vivo?',
        'E o povo lhe tornou a falar conforme àquela palavra dizendo: Assim farão ao homem que o ferir.',
        'E, ouvindo Eliabe, seu irmão mais velho, falar àqueles homens, acendeu-se a ira de Eliabe contra Davi, e disse: Por que desceste aqui? Com quem deixaste aquelas poucas ovelhas no deserto? Bem conheço a tua presunção, e a maldade do teu coração, que desceste para ver a peleja.',
        'Então disse Davi: Que fiz eu agora? Porventura não há razão para isso?',
        'E desviou-se dele para outro, e falou conforme àquela palavra; e o povo lhe tornou a responder conforme às primeiras palavras.',
        'E, ouvidas as palavras que Davi havia falado, as anunciaram a Saul, que mandou chamá-lo.',
        'E Davi disse a Saul: Não desfaleça o coração de ninguém por causa dele; teu servo irá, e pelejará contra este filisteu.',
        'Porém Saul disse a Davi: Contra este filisteu não poderás ir para pelejar com ele; pois tu ainda és moço, e ele homem de guerra desde a sua mocidade.',
        'Então disse Davi a Saul: Teu servo apascentava as ovelhas de seu pai; e quando vinha um leão e um urso, e tomava uma ovelha do rebanho,',
        'Eu saía após ele e o feria, e livrava-a da sua boca; e, quando ele se levantava contra mim, lançava-lhe mão da barba, e o feria e o matava.',
        'Assim feria o teu servo o leão, como o urso; assim será este incircunciso filisteu como um deles; porquanto afrontou os exércitos do Deus vivo.',
        'Disse mais Davi: O Senhor me livrou das garras do leão, e das do urso; ele me livrará da mão deste filisteu. Então disse Saul a Davi: Vai, e o Senhor seja contigo.',
        'E Saul vestiu a Davi de suas vestes, e pôs-lhe sobre a cabeça um capacete de bronze; e o vestiu de uma couraça.',
        'E Davi cingiu a espada sobre as suas vestes, e começou a andar; porém nunca o havia experimentado; então disse Davi a Saul: Não posso andar com isto, pois nunca o experimentei. E Davi tirou aquilo de sobre si.',
        'E tomou o seu cajado na mão, e escolheu para si cinco seixos do ribeiro, e pô-los no alforje de pastor, que trazia, a saber, no surrão, e lançou mão da sua funda; e foi aproximando-se do filisteu.',
        'O filisteu também vinha se aproximando de Davi; e o que lhe levava o escudo ia adiante dele.',
        'E, olhando o filisteu, e vendo a Davi, o desprezou, porquanto era moço, ruivo, e de gentil aspecto.',
        'Disse, pois, o filisteu a Davi: Sou eu algum cão, para tu vires a mim com paus? E o filisteu pelos seus deuses amaldiçoou a Davi.',
        'Disse mais o filisteu a Davi: Vem a mim, e darei a tua carne às aves do céu e às bestas do campo.',
        'Davi, porém, disse ao filisteu: Tu vens a mim com espada, e com lança, e com escudo; porém eu venho a ti em nome do Senhor dos Exércitos, o Deus dos exércitos de Israel, a quem tens afrontado.',
        'Hoje mesmo o Senhor te entregará na minha mão, e ferir-te-ei, e tirar-te-ei a cabeça, e os corpos do arraial dos filisteus darei hoje mesmo às aves do céu e às feras da terra; e toda a terra saberá que há Deus em Israel;',
        'E saberá toda esta congregação que o Senhor salva, não com espada, nem com lança; porque do Senhor é a guerra, e ele vos entregará na nossa mão.',
        'E sucedeu que, levantando-se o filisteu, e indo encontrar-se com Davi, apressou-se Davi, e correu ao combate, a encontrar-se com o filisteu.',
        'E Davi pôs a mão no alforje, e tomou dali uma pedra e com a funda lha atirou, e feriu o filisteu na testa, e a pedra se lhe encravou na testa, e caiu sobre o seu rosto em terra.',
        'Assim Davi prevaleceu contra o filisteu, com uma funda e com uma pedra, e feriu o filisteu, e o matou; sem que Davi tivesse uma espada na mão.',
        'Por isso correu Davi, e pôs-se em pé sobre o filisteu, e tomou a sua espada, e tirou-a da bainha, e o matou, e lhe cortou com ela a cabeça; vendo então os filisteus, que o seu herói era morto, fugiram.',
        'Então os homens de Israel e Judá se levantaram, e jubilaram, e seguiram os filisteus, até chegar ao vale, e até às portas de Ecrom; e caíram os feridos dos filisteus pelo caminho de Saaraim até Gate e até Ecrom.',
        'Então voltaram os filhos de Israel de perseguirem os filisteus, e despojaram os seus arraiais.',
        'E Davi tomou a cabeça do filisteu, e a trouxe a Jerusalém; porém pôs as armas dele na sua tenda.',
        'Vendo, porém, Saul, sair Davi a encontrar-se com o filisteu, disse a Abner, o capitão do exército: De quem é filho este moço, Abner? E disse Abner: Vive a tua alma, ó rei, que o não sei.',
        'Disse então o rei: Pergunta, pois, de quem é filho este moço.',
        'Voltando, pois, Davi de ferir o filisteu, Abner o tomou consigo, e o trouxe à presença de Saul, trazendo ele na mão a cabeça do filisteu.',
        'E disse-lhe Saul: De quem és filho, jovem? E disse Davi: Filho de teu servo Jessé, belemita.',
      ],
      [
        'E sucedeu que, acabando ele de falar com Saul, a alma de Jônatas se ligou com a alma de Davi; e Jônatas o amou, como à sua própria alma.',
        'E Saul naquele dia o tomou, e não lhe permitiu que voltasse para casa de seu pai.',
        'E Jônatas e Davi fizeram aliança; porque Jônatas o amava como à sua própria alma.',
        'E Jônatas se despojou da capa que trazia sobre si, e a deu a Davi, como também as suas vestes, até a sua espada, e o seu arco, e o seu cinto.',
        'E saía Davi aonde quer que Saul o enviasse e conduzia-se com prudência, e Saul o pôs sobre os homens de guerra; e era aceito aos olhos de todo o povo, e até aos olhos dos servos de Saul.',
        'Sucedeu, porém, que, vindo eles, quando Davi voltava de ferir os filisteus, as mulheres de todas as cidades de Israel saíram ao encontro do rei Saul, cantando e dançando, com adufes, com alegria, e com instrumentos de música.',
        'E as mulheres dançando e cantando se respondiam umas às outras, dizendo: Saul feriu os seus milhares, porém, Davi os seus dez milhares.',
        'Então Saul se indignou muito, e aquela palavra pareceu mal aos seus olhos, e disse: Dez milhares deram a Davi, e a mim somente milhares; na verdade, que lhe falta, senão só o reino?',
        'E, desde aquele dia em diante, Saul tinha Davi em suspeita.',
        'E aconteceu no outro dia, que o mau espírito da parte de Deus se apoderou de Saul, e profetizava no meio da casa; e Davi tocava a harpa com a sua mão, como nos outros dias; Saul, porém, tinha na mão uma lança.',
        'E Saul atirou com a lança, dizendo: Encravarei a Davi na parede. Porém Davi se desviou dele por duas vezes.',
        'E temia Saul a Davi, porque o Senhor era com ele e se tinha retirado de Saul.',
        'Por isso Saul o desviou de si, e o pôs por capitão de mil; e saía e entrava diante do povo.',
        'E Davi se conduzia com prudência em todos os seus caminhos, e o Senhor era com ele.',
        'Vendo então Saul que tão prudentemente se conduzia, tinha receio dele.',
        'Porém todo o Israel e Judá amava a Davi, porquanto saía e entrava diante deles.',
        'Por isso Saul disse a Davi: Eis que Merabe, minha filha mais velha, te darei por mulher; sê-me somente filho valoroso, e guerreia as guerras do Senhor (porque Saul dizia consigo: Não seja contra ele a minha mão, mas sim a dos filisteus).',
        'Mas Davi disse a Saul: Quem sou eu, e qual é a minha vida e a família de meu pai em Israel, para vir a ser genro do rei?',
        'Sucedeu, porém, que ao tempo que Merabe, filha de Saul, devia ser dada a Davi, ela foi dada por mulher a Adriel, meolatita.',
        'Mas Mical, a outra filha de Saul amava a Davi; o que, sendo anunciado a Saul, pareceu isto bom aos seus olhos.',
        'E Saul disse: Eu lha darei, para que lhe sirva de laço, e para que a mão dos filisteus venha a ser contra ele. Pelo que Saul disse a Davi: Com a outra serás hoje meu genro.',
        'E Saul deu ordem aos seus servos: Falai em segredo a Davi, dizendo: Eis que o rei te está mui afeiçoado, e todos os seus servos te amam; agora, pois, consente em ser genro do rei.',
        'E os servos de Saul falaram todas estas palavras aos ouvidos de Davi. Então disse Davi: Parece-vos pouco aos vossos olhos ser genro do rei, sendo eu homem pobre e desprezível?',
        'E os servos de Saul lhe anunciaram isto, dizendo: Foram tais as palavras que falou Davi.',
        'Então disse Saul: Assim direis a Davi: O rei não tem necessidade de dote, senão de cem prepúcios de filisteus, para se tomar vingança dos inimigos do rei. Porquanto Saul tentava fazer cair a Davi pela mão dos filisteus.',
        'E anunciaram os seus servos estas palavras a Davi, e este negócio pareceu bem aos olhos de Davi, de que fosse genro do rei; porém ainda os dias não se haviam cumprido.',
        'Então Davi se levantou, e partiu com os seus homens, e feriu dentre os filisteus duzentos homens, e Davi trouxe os seus prepúcios, e os entregou todos ao rei, para que fosse genro do rei; então Saul lhe deu por mulher a sua filha.',
        'E viu Saul, e notou que o Senhor era com Davi; e Mical, filha de Saul, o amava.',
        'Então Saul temeu muito mais a Davi; e Saul foi todos os seus dias inimigo de Davi.',
        'E, saindo os príncipes dos filisteus à campanha, sucedia que Davi se conduzia com mais êxito do que todos os servos de Saul; portanto o seu nome era muito estimado.',
      ],
      [
        'E falou Saul a Jônatas, seu filho, e a todos os seus servos, para que matassem a Davi. Porém Jônatas, filho de Saul, estava mui afeiçoado a Davi.',
        'E Jônatas o anunciou a Davi, dizendo: Meu pai, Saul, procura matar-te, pelo que agora guarda-te pela manhã, e fica-te em oculto, e esconde-te.',
        'E sairei eu, e estarei à mão de meu pai no campo em que estiverdes, e eu falarei de ti a meu pai, e verei o que há, e to anunciarei.',
        'Então Jônatas falou bem de Davi a Saul, seu pai, e disse-lhe: Não peque o rei contra seu servo Davi, porque ele não pecou contra ti, e porque os seus feitos te são muito bons.',
        'Porque expôs a sua vida, e feriu aos filisteus, e fez o Senhor um grande livramento a todo o Israel; tu mesmo o viste, e te alegraste; porque, pois, pecarias contra o sangue inocente, matando a Davi, sem causa?',
        'E Saul deu ouvidos à voz de Jônatas, e jurou Saul: Vive o Senhor, que não morrerá.',
        'E Jônatas chamou a Davi, e contou-lhe todas estas palavras; e Jônatas levou Davi a Saul, e esteve perante ele como antes.',
        'E tornou a haver guerra; e saiu Davi, e pelejou contra os filisteus, e feriu-os com grande matança, e fugiram diante dele.',
        'Porém o espírito mau da parte do Senhor se tornou sobre Saul, estando ele assentado em sua casa, e tendo na mão a sua lança; e tocava Davi com a mão, a harpa.',
        'E procurou Saul encravar a Davi na parede, porém ele se desviou de diante de Saul, o qual feriu com a lança a parede; então fugiu Davi, e escapou naquela mesma noite.',
        'Porém Saul mandou mensageiros à casa de Davi, que o guardassem, e o matassem pela manhã; do que Mical, sua mulher, avisou a Davi, dizendo: Se não salvares a tua vida esta noite, amanhã te matarão.',
        'Então Mical desceu a Davi por uma janela; e ele se foi, e fugiu, e escapou.',
        'E Mical tomou uma estátua e a deitou na cama, e pôs-lhe à cabeceira uma pele de cabra, e a cobriu com uma coberta.',
        'E, mandando Saul mensageiros que trouxessem a Davi, ela disse: Está doente.',
        'Então Saul tornou a mandar mensageiros que fossem a Davi, dizendo: Trazei-mo na cama, para que o mate.',
        'Vindo, pois, os mensageiros, eis que a estátua estava na cama, e a pele de cabra à sua cabeceira.',
        'Então disse Saul a Mical: Por que assim me enganaste, e deixaste ir e escapar o meu inimigo? E disse Mical a Saul: Porque ele me disse: Deixa-me ir, por que hei de eu matar-te?',
        'Assim Davi fugiu e escapou, e foi a Samuel, em Ramá, e lhe participou tudo quanto Saul lhe fizera; e foram, ele e Samuel, e ficaram em Naiote.',
        'E o anunciaram a Saul, dizendo: Eis que Davi está em Naiote, em Ramá.',
        'Então enviou Saul mensageiros para trazerem a Davi, os quais viram uma congregação de profetas profetizando, onde estava Samuel que presidia sobre eles; e o Espírito de Deus veio sobre os mensageiros de Saul, e também eles profetizaram.',
        'E, avisado disto Saul, enviou outros mensageiros, e também estes profetizaram; então enviou Saul ainda uns terceiros mensageiros, os quais também profetizaram.',
        'Então foi também ele mesmo a Ramá, e chegou ao poço grande que estava em Secu; e, perguntando, disse: Onde estão Samuel e Davi? E disseram-lhe: Eis que estão em Naiote, em Ramá.',
        'Então foi para Naiote, em Ramá; e o mesmo Espírito de Deus veio sobre ele, e ia profetizando, até chegar a Naiote, em Ramá.',
        'E ele também despiu as suas vestes, e profetizou diante de Samuel, e esteve nu por terra todo aquele dia e toda aquela noite; por isso se diz: Está também Saul entre os profetas?',
      ],
      [
        'Então fugiu Davi de Naiote, em Ramá; e veio, e disse a Jônatas: Que fiz eu? Qual é o meu crime? E qual é o meu pecado diante de teu pai, que procura tirar-me a vida?',
        'E ele lhe disse: Tal não suceda; não morrerás; eis que meu pai não faz coisa nenhuma grande, nem pequena, sem primeiro me informar; por que, pois, meu pai me encobriria este negócio? Não será assim.',
        'Então Davi tornou a jurar, e disse: Teu pai sabe muito bem que achei graça em teus olhos; por isso disse: Não saiba isto Jônatas, para que não se magoe. Mas, na verdade, como vive o Senhor, e como vive a tua alma, há apenas um passo entre mim e a morte.',
        'E disse Jônatas a Davi: O que disser a tua alma, eu te farei.',
        'Disse Davi a Jônatas: Eis que amanhã é a lua nova, em que costumo assentar-me com o rei para comer; porém deixa-me ir, e esconder-me-ei no campo, até à tarde do terceiro dia.',
        'Se teu pai notar a minha ausência, dirás: Davi me pediu muito que o deixasse ir correndo a Belém, sua cidade; porquanto se faz lá o sacrifício anual para toda a linhagem.',
        'Se disser assim: Está bem; então teu servo tem paz; porém se muito se indignar, sabe que já está inteiramente determinado no mal.',
        'Usa, pois, de misericórdia com o teu servo, porque o fizeste entrar contigo em aliança do Senhor; se, porém, há em mim crime, mata-me tu mesmo; por que me levarias a teu pai?',
        'Então disse Jônatas: Longe de ti tal coisa; porém se de alguma forma soubesse que já este mal está inteiramente determinado por meu pai, para que viesse sobre ti, não to revelaria eu?',
        'E disse Davi a Jônatas: Quem me fará saber, se por acaso teu pai te responder asperamente?',
        'Então disse Jônatas a Davi: Vem e saiamos ao campo. E saíram ambos ao campo.',
        'E disse Jônatas a Davi: O Senhor Deus de Israel seja testemunha! Sondando eu a meu pai amanhã a estas horas, ou depois de amanhã, e eis que se houver coisa favorável para Davi, e eu então não enviar a ti, e não to fizer saber;',
        'O Senhor faça assim com Jônatas outro tanto; que se aprouver a meu pai fazer-te mal, também to farei saber, e te deixarei partir, e irás em paz; e o Senhor seja contigo, assim como foi com meu pai.',
        'E, se eu então ainda viver, porventura não usarás comigo da beneficência do Senhor, para que não morra?',
        'Nem tampouco cortarás da minha casa a tua beneficência eternamente; nem ainda quando o Senhor desarraigar da terra a cada um dos inimigos de Davi.',
        'Assim fez Jônatas aliança com a casa de Davi, dizendo: O Senhor o requeira da mão dos inimigos de Davi.',
        'E Jônatas fez jurar a Davi de novo, porquanto o amava; porque o amava com todo o amor da sua alma.',
        'E disse-lhe Jônatas: Amanhã é a lua nova, e não te acharão no teu lugar, pois o teu assento se achará vazio.',
        'E, ausentando-te tu três dias, desce apressadamente, e vai àquele lugar onde te escondeste no dia do negócio; e fica-te junto à pedra de Ezel.',
        'E eu atirarei três flechas para aquele lado, como se atirasse ao alvo.',
        'E eis que mandarei o moço dizendo: Anda, busca as flechas. Se eu expressamente disser ao moço: Olha que as flechas estão para cá de ti; toma-o contigo, e vem, porque há paz para ti, e não há nada, vive o Senhor.',
        'Porém se disser ao moço assim: Olha que as flechas estão para lá de ti; vai-te embora, porque o Senhor te deixa ir.',
        'E quanto ao negócio de que eu e tu falamos, eis que o Senhor está entre mim e ti eternamente.',
        'Escondeu-se, pois, Davi no campo; e, sendo a lua nova, assentou-se o rei para comer pão.',
        'E, assentando-se o rei, como das outras vezes, no seu assento, no lugar junto à parede, Jônatas se levantou, e assentou-se Abner ao lado de Saul; e o lugar de Davi apareceu vazio.',
        'Porém naquele dia não disse Saul nada, porque dizia: Aconteceu-lhe alguma coisa, pela qual não está limpo; certamente não está limpo.',
        'Sucedeu também no outro dia, o segundo da lua nova, que o lugar de Davi apareceu vazio; disse, pois, Saul a Jônatas, seu filho: Por que não veio o filho de Jessé nem ontem nem hoje a comer pão?',
        'E respondeu Jônatas a Saul: Davi me pediu encarecidamente que o deixasse ir a Belém.',
        'Dizendo: Peço-te que me deixes ir, porquanto a nossa linhagem tem um sacrifício na cidade, e meu irmão mesmo me mandou ir; se, pois, agora tenho achado graça em teus olhos, peço-te que me deixes partir, para que veja a meus irmãos; por isso não veio à mesa do rei.',
        'Então se acendeu a ira de Saul contra Jônatas, e disse-lhe: Filho da mulher perversa e rebelde; não sei eu que tens escolhido o filho de Jessé, para vergonha tua e para vergonha da nudez de tua mãe?',
        'Porque todos os dias que o filho de Jessé viver sobre a terra nem tu estarás seguro, nem o teu reino; pelo que envia, e traze-mo nesta hora; porque é digno de morte.',
        'Então respondeu Jônatas a Saul, seu pai, e lhe disse: Por que há de morrer? Que tem feito?',
        'Então Saul atirou-lhe com a lança, para o ferir; assim entendeu Jônatas que já seu pai tinha determinado matar a Davi.',
        'Por isso Jônatas, todo encolerizado, se levantou da mesa; e no segundo dia da lua nova não comeu pão; porque se magoava por causa de Davi, porque seu pai o tinha humilhado.',
        'E aconteceu, pela manhã, que Jônatas saiu ao campo, ao tempo que tinha ajustado com Davi, e um moço pequeno com ele.',
        'Então disse ao seu moço: Corre a buscar as flechas que eu atirar. Correu, pois, o moço, e ele atirou uma flecha, que fez passar além dele.',
        'E, chegando o moço ao lugar da flecha que Jônatas tinha atirado, gritou Jônatas atrás do moço, e disse: Não está porventura a flecha mais para lá de ti?',
        'E tornou Jônatas a gritar atrás do moço: Apressa-te, corre, não te demores. E o moço de Jônatas apanhou as flechas, e veio a seu senhor.',
        'E o moço não entendeu coisa alguma; só Jônatas e Davi sabiam deste negócio.',
        'Então Jônatas deu as suas armas ao moço que trazia, e disse-lhe: Anda, e leva-as à cidade.',
        'E, indo-se o moço, levantou-se Davi do lado do sul, e lançou-se sobre o seu rosto em terra, e inclinou-se três vezes; e beijaram-se um ao outro, e choraram juntos, mas Davi chorou muito mais.',
        'E disse Jônatas a Davi: Vai-te em paz; o que nós temos jurado ambos em nome do Senhor, dizendo: O Senhor seja entre mim e ti, e entre a minha descendência e a tua descendência, seja perpetuamente.',
        'Então se levantou Davi, e partiu; e Jônatas entrou na cidade.',
      ],
      [
        'Então veio Davi a Nobe, ao sacerdote Aimeleque; e Aimeleque, tremendo, saiu ao encontro de Davi, e disse-lhe: Por que vens só, e ninguém contigo?',
        'E disse Davi ao sacerdote Aimeleque: O rei me encomendou um negócio, e me disse: Ninguém saiba deste negócio, pelo qual eu te enviei, e o qual te ordenei; quanto aos moços, apontei-lhes tal e tal lugar.',
        'Agora, pois, que tens à mão? Dá-me cinco pães na minha mão, ou o que se achar.',
        'E, respondendo o sacerdote a Davi, disse: Não tenho pão comum à mão; há, porém, pão sagrado, se ao menos os moços se abstiveram das mulheres.',
        'E respondeu Davi ao sacerdote, e lhe disse: As mulheres, na verdade, se nos vedaram desde ontem e anteontem; quando eu saí, os vasos dos moços eram santos; e de algum modo é pão comum, sendo que hoje santifica-se outro no vaso.',
        'Então o sacerdote lhe deu o pão sagrado, porquanto não havia ali outro pão senão os pães da proposição, que se tiraram de diante do Senhor, para se pôr ali pão quente no dia em que aquele se tirasse.',
        'Estava, porém, ali naquele dia um dos criados de Saul, detido perante o Senhor, e era seu nome Doegue, edomeu, o mais poderoso dos pastores de Saul.',
        'E disse Davi a Aimeleque: Não tens aqui à mão lança ou espada alguma? Porque não trouxe à mão nem a minha espada nem as minhas armas, porque o negócio do rei era apressado.',
        'E disse o sacerdote: A espada de Golias, o filisteu, a quem tu feriste no vale do carvalho, eis que está aqui envolta num pano detrás do éfode. Se tu a queres tomar, toma-a, porque nenhuma outra há aqui, senão aquela. E disse Davi: Não há outra semelhante; dá-ma.',
        'E Davi levantou-se, e fugiu aquele dia de diante de Saul, e foi a Aquis, rei de Gate.',
        'Porém os criados de Aquis lhe disseram: Não é este Davi, o rei da terra? Não se cantava deste nas danças, dizendo: Saul feriu os seus milhares, porém Davi os seus dez milhares?',
        'E Davi considerou estas palavras no seu ânimo, e temeu muito diante de Aquis, rei de Gate.',
        'Por isso se contrafez diante dos olhos deles, e fez-se como doido entre as suas mãos, e esgravatava nas portas de entrada, e deixava correr a saliva pela barba.',
        'Então disse Aquis aos seus criados: Eis que bem vedes que este homem está louco; por que mo trouxestes a mim?',
        'Faltam-me a mim doidos, para que trouxésseis a este para que fizesse doidices diante de mim? Há de entrar este na minha casa?',
      ],
      [
        'Então Davi se retirou dali, e escapou para a caverna de Adulão; e ouviram-no seus irmãos e toda a casa de seu pai, e desceram ali para ter com ele.',
        'E ajuntou-se a ele todo o homem que se achava em aperto, e todo o homem endividado, e todo o homem de espírito desgostoso, e ele se fez capitão deles; e eram com ele uns quatrocentos homens.',
        'E foi Davi dali a Mizpá dos moabitas, e disse ao rei dos moabitas: Deixa estar meu pai e minha mãe convosco, até que saiba o que Deus há de fazer de mim.',
        'E trouxe-os perante o rei dos moabitas, e ficaram com ele todos os dias que Davi esteve no lugar forte.',
        'Porém o profeta Gade disse a Davi: Não fiques naquele lugar forte; vai, e entra na terra de Judá. Então Davi saiu, e foi para o bosque de Herete.',
        'E ouviu Saul que já se sabia de Davi e dos homens que estavam com ele; e estava Saul em Gibeá, debaixo de um arvoredo, em Ramá, e tinha na mão a sua lança, e todos os seus criados estavam com ele.',
        'Então disse Saul a todos os seus criados que estavam com ele: Ouvi, peço-vos, filhos de Benjamim, dar-vos-á também o filho de Jessé, a todos vós, terras e vinhas, e far-vos-á a todos capitães de milhares e capitães de centenas,',
        'Para que todos vós tenhais conspirado contra mim, e ninguém há que me dê aviso de que meu filho tem feito aliança com o filho de Jessé, e nenhum dentre vós há que se doa de mim, e mo participe, pois meu filho tem contra mim sublevado a meu servo, para me armar ciladas, como se vê neste dia?',
        'Então respondeu Doegue, o edomeu, que também estava com os criados de Saul, e disse: Vi o filho de Jessé chegar a Nobe, a Aimeleque, filho de Aitube,',
        'O qual consultou por ele ao Senhor, e lhe deu mantimento, e lhe deu também a espada de Golias, o filisteu.',
        'Então o rei mandou chamar a Aimeleque, sacerdote, filho de Aitube, e a toda a casa de seu pai, os sacerdotes que estavam em Nobe; e todos eles vieram ao rei.',
        'E disse Saul: Ouve, peço-te, filho de Aitube. E ele disse: Eis-me aqui, senhor meu.',
        'Então lhe disse Saul: Por que conspirastes contra mim, tu e o filho de Jessé? Pois deste-lhe pão e espada, e consultaste por ele a Deus, para que se levantasse contra mim a armar-me ciladas, como se vê neste dia?',
        'E respondeu Aimeleque ao rei e disse: E quem, entre todos os teus criados, há tão fiel como Davi, o genro do rei, pronto na sua obediência, e honrado na tua casa?',
        'Comecei, porventura, hoje a consultar por ele a Deus? Longe de mim tal! Não impute o rei coisa nenhuma a seu servo, nem a toda a casa de meu pai, pois o teu servo não soube nada de tudo isso, nem muito nem pouco.',
        'Porém o rei disse: Aimeleque, morrerás certamente, tu e toda a casa de teu pai,',
        'E disse o rei aos da sua guarda que estavam com ele: Virai-vos, e matai os sacerdotes do Senhor, porque também a sua mão é com Davi, e porque souberam que fugiu e não mo fizeram saber. Porém os criados do rei não quiseram estender as suas mãos para arremeter contra os sacerdotes do Senhor.',
        'Então disse o rei a Doegue: Vira-te, e arremete contra os sacerdotes. Então se virou Doegue, o edomeu, e arremeteu contra os sacerdotes, e matou naquele dia oitenta e cinco homens que vestiam éfode de linho.',
        'Também a Nobe, cidade destes sacerdotes, passou a fio de espada, desde o homem até à mulher, desde os meninos até aos de peito, e até os bois, jumentos e ovelhas passou a fio de espada.',
        'Porém escapou um dos filhos de Aimeleque, filho de Aitube, cujo nome era Abiatar, o qual fugiu para Davi.',
        'E Abiatar anunciou a Davi que Saul tinha matado os sacerdotes do Senhor.',
        'Então Davi disse a Abiatar: Bem sabia eu naquele dia que, estando ali Doegue, o edomeu, não deixaria de o denunciar a Saul; eu dei ocasião contra todas as almas da casa de teu pai.',
        'Fica comigo, não temas, porque quem procurar a minha morte também procurará a tua, pois estarás salvo comigo.',
      ],
      [
        'E foi anunciado a Davi, dizendo: Eis que os filisteus pelejam contra Queila, e saqueiam as eiras.',
        'E consultou Davi ao Senhor, dizendo: Irei eu, e ferirei a estes filisteus? E disse o Senhor a Davi: Vai, e ferirás aos filisteus, e livrarás a Queila.',
        'Porém os homens de Davi lhe disseram: Eis que tememos aqui em Judá, quanto mais indo a Queila contra os esquadrões dos filisteus.',
        'Então Davi tornou a consultar ao Senhor, e o Senhor lhe respondeu, e disse: Levanta-te, desce a Queila, porque te dou os filisteus na tua mão.',
        'Então Davi partiu com os seus homens a Queila, e pelejou contra os filisteus, e levou os gados, e fez grande estrago entre eles; e Davi livrou os moradores de Queila.',
        'E sucedeu que, quando Abiatar, filho de Aimeleque, fugiu para Davi, a Queila, desceu com o éfode na mão.',
        'E foi anunciado a Saul que Davi tinha ido a Queila, e disse Saul: Deus o entregou nas minhas mãos, pois está encerrado, entrando numa cidade de portas e ferrolhos.',
        'Então Saul mandou chamar a todo o povo à peleja, para que descessem a Queila, para cercar a Davi e os seus homens.',
        'Sabendo, pois, Davi, que Saul maquinava este mal contra ele, disse a Abiatar, sacerdote: Traze aqui o éfode.',
        'E disse Davi: Ó Senhor, Deus de Israel, teu servo tem ouvido que Saul procura vir a Queila, para destruir a cidade por causa de mim.',
        'Entregar-me-ão os cidadãos de Queila na sua mão? Descerá Saul, como o teu servo tem ouvido? Ah! Senhor Deus de Israel! Faze-o saber ao teu servo. E disse o Senhor: Descerá.',
        'Disse mais Davi: Entregar-me-ão os cidadãos de Queila, a mim e aos meus homens, nas mãos de Saul? E disse o Senhor: Entregarão.',
        'Então Davi se levantou com os seus homens, uns seiscentos, e saíram de Queila, e foram-se aonde puderam; e sendo anunciado a Saul, que Davi escapara de Queila, cessou de sair contra ele.',
        'E Davi permaneceu no deserto, nos lugares fortes, e ficou em um monte no deserto de Zife; e Saul o buscava todos os dias, porém Deus não o entregou na sua mão.',
        'Vendo, pois, Davi, que Saul saíra à busca da sua vida, permaneceu no deserto de Zife, num bosque.',
        'Então se levantou Jônatas, filho de Saul, e foi para Davi no bosque, e confortou a sua mão em Deus;',
        'E disse-lhe: Não temas, que não te achará a mão de Saul, meu pai; porém tu reinarás sobre Israel, e eu serei contigo o segundo; o que também Saul, meu pai, bem sabe.',
        'E ambos fizeram aliança perante o Senhor; Davi ficou no bosque, e Jônatas voltou para a sua casa.',
        'Então subiram os zifeus a Saul, a Gibeá, dizendo: Não se escondeu Davi entre nós, nos lugares fortes no bosque, no outeiro de Haquilá, que está à mão direita de Jesimom?',
        'Agora, pois, ó rei, apressadamente desce conforme a todo o desejo da tua alma; a nós cumpre entregá-lo nas mãos do rei.',
        'Então disse Saul: Bendito sejais vós do Senhor, porque vos compadecestes de mim.',
        'Ide, pois, e diligenciai ainda mais, e sabei e notai o lugar que freqüenta, e quem o tenha visto ali; porque me foi dito que é astutíssimo.',
        'Por isso atentai bem, e informai-vos acerca de todos os esconderijos, em que ele se esconde; e então voltai para mim com toda a certeza, e ir-me-ei convosco; e há de ser que, se estiver naquela terra, o buscarei entre todos os milhares de Judá.',
        'Então se levantaram eles e se foram a Zife, adiante de Saul; Davi, porém, e os seus homens estavam no deserto de Maom, na campina, à direita de Jesimom.',
        'E Saul e os seus homens se foram em busca dele; o que anunciaram a Davi, que desceu para aquela penha, e ficou no deserto de Maom; o que ouvindo Saul, seguiu a Davi para o deserto de Maom.',
        'E Saul ia deste lado do monte, e Davi e os seus homens do outro lado do monte; e, temeroso, Davi se apressou a escapar de Saul; Saul, porém, e os seus homens cercaram a Davi e aos seus homens, para lançar mão deles.',
        'Então veio um mensageiro a Saul, dizendo: Apressa-te, e vem, porque os filisteus com ímpeto entraram na terra.',
        'Por isso Saul voltou de perseguir a Davi, e foi ao encontro dos filisteus; por esta razão aquele lugar se chamou Rochedo das Divisões.',
        'E subiu Davi dali, e ficou nos lugares fortes de En-Gedi.',
      ],
      [
        'E sucedeu que, voltando Saul de perseguir os filisteus, anunciaram-lhe, dizendo: Eis que Davi está no deserto de En-Gedi.',
        'Então tomou Saul três mil homens, escolhidos dentre todo o Israel, e foi em busca de Davi e dos seus homens, até sobre os cumes das penhas das cabras montesas.',
        'E chegou a uns currais de ovelhas no caminho, onde estava uma caverna; e entrou nela Saul, a cobrir seus pés; e Davi e os seus homens estavam nos fundos da caverna.',
        'Então os homens de Davi lhe disseram: Eis aqui o dia, do qual o Senhor te diz: Eis que te dou o teu inimigo nas tuas mãos, e far-lhe-ás como te parecer bem aos teus olhos. E levantou-se Davi, e mansamente cortou a orla do manto de Saul.',
        'Sucedeu, porém, que depois o coração doeu a Davi, por ter cortado a orla do manto de Saul.',
        'E disse aos seus homens: O SENHOR me guarde de que eu faça tal coisa ao meu senhor, ao ungido do SENHOR, estendendo eu a minha mão contra ele; pois é o ungido do SENHOR.',
        'E com estas palavras Davi conteve os seus homens, e não lhes permitiu que se levantassem contra Saul; e Saul se levantou da caverna, e prosseguiu o seu caminho.',
        'Depois também Davi se levantou, e saiu da caverna, e gritou por detrás de Saul, dizendo: Rei, meu senhor! E, olhando Saul para trás, Davi se inclinou com o rosto em terra, e se prostrou.',
        'E disse Davi a Saul: Por que dás tu ouvidos às palavras dos homens que dizem: Eis que Davi procura o teu mal?',
        'Eis que este dia os teus olhos viram, que o SENHOR hoje te pôs em minhas mãos nesta caverna, e alguns disseram que te matasse; porém a minha mão te poupou; porque disse: Não estenderei a minha mão contra o meu senhor, pois é o ungido do SENHOR.',
        'Olha, pois, meu pai, vê aqui a orla do teu manto na minha mão; porque cortando-te eu a orla do manto, não te matei. Sabe, pois, e vê que não há na minha mão nem mal nem rebeldia alguma, e não pequei contra ti; porém tu andas à caça da minha vida, para ma tirares.',
        'Julgue o Senhor entre mim e ti, e vingue-me o Senhor de ti; porém a minha mão não será contra ti.',
        'Como diz o provérbio dos antigos: Dos ímpios procede a impiedade; porém a minha mão não será contra ti.',
        'Após quem saiu o rei de Israel? A quem persegues? A um cão morto? A uma pulga?',
        'O Senhor, porém, será juiz, e julgará entre mim e ti, e verá, e advogará a minha causa, e me defenderá da tua mão.',
        'E sucedeu que, acabando Davi de falar a Saul todas estas palavras, disse Saul: É esta a tua voz, meu filho Davi? Então Saul levantou a sua voz e chorou.',
        'E disse a Davi: Mais justo és do que eu; pois tu me recompensaste com bem, e eu te recompensei com mal.',
        'E tu mostraste hoje que procedeste bem para comigo, pois o Senhor me tinha posto em tuas mãos, e tu não me mataste.',
        'Porque, quem há que, encontrando o seu inimigo, o deixaria ir por bom caminho? O Senhor, pois, te pague com bem, por isso que hoje me fizeste.',
        'Agora, pois, eis que bem sei que certamente hás de reinar, e que o reino de Israel há de ser firme na tua mão.',
        'Portanto agora jura-me pelo Senhor que não desarraigarás a minha descendência depois de mim, nem desfarás o meu nome da casa de meu pai.',
        'Então jurou Davi a Saul. E foi Saul para a sua casa; porém Davi e os seus homens subiram ao lugar forte.',
      ],
      [
        'E faleceu Samuel, e todo o Israel se ajuntou, e o prantearam, e o sepultaram na sua casa, em Ramá. E Davi se levantou e desceu ao deserto de Parã.',
        'E havia um homem em Maom, que tinha as suas possessões no Carmelo; e era este homem muito poderoso, e tinha três mil ovelhas e mil cabras; e estava tosquiando as suas ovelhas no Carmelo.',
        'E era o nome deste homem Nabal, e o nome de sua mulher Abigail; e era a mulher de bom entendimento e formosa; porém o homem era duro, e maligno nas obras, e era da casa de Calebe.',
        'E ouviu Davi no deserto que Nabal tosquiava as suas ovelhas,',
        'E enviou Davi dez moços, e disse aos moços: Subi ao Carmelo, e, indo a Nabal, perguntai-lhe, em meu nome, como está.',
        'E assim direis àquele próspero: Paz tenhas, e que a tua casa tenha paz, e tudo o que tens tenha paz!',
        'Agora, pois, tenho ouvido que tens tosquiadores. Ora, os pastores que tens estiveram conosco; agravo nenhum lhes fizemos, nem coisa alguma lhes faltou todos os dias que estiveram no Carmelo.',
        'Pergunta-o aos teus moços, e eles to dirão. Estes moços, pois, achem graça em teus olhos, porque viemos em boa ocasião. Dá, pois, a teus servos e a Davi, teu filho, o que achares à mão.',
        'Chegando, pois, os moços de Davi, e falando a Nabal todas aquelas palavras em nome de Davi, se calaram.',
        'E Nabal respondeu aos criados de Davi, e disse: Quem é Davi, e quem é o filho de Jessé? Muitos servos há hoje, que fogem ao seu senhor.',
        'Tomaria eu, pois, o meu pão, e a minha água, e a carne das minhas reses que degolei para os meus tosquiadores, e o daria a homens que eu não sei donde vêm?',
        'Então os moços de Davi puseram-se a caminho e voltaram, e chegando, lhe anunciaram tudo conforme a todas estas palavras.',
        'Por isso disse Davi aos seus homens: Cada um cinja a sua espada. E cada um cingiu a sua espada, e cingiu também Davi a sua; e subiram após Davi uns quatrocentos homens, e duzentos ficaram com a bagagem.',
        'Porém um dentre os moços o anunciou a Abigail, mulher de Nabal, dizendo: Eis que Davi enviou mensageiros desde o deserto a saudar o nosso amo; porém ele os destratou.',
        'Todavia, aqueles homens têm-nos sido muito bons, e nunca fomos agravados por eles, e nada nos faltou em todos os dias que convivemos com eles quando estavam no campo.',
        'De muro em redor nos serviram, assim de dia como de noite, todos os dias que andamos com eles apascentando as ovelhas.',
        'Considera, pois, agora, e vê o que hás de fazer, porque o mal já está de todo determinado contra o nosso amo e contra toda a sua casa, e ele é um homem vil, que não há quem lhe possa falar.',
        'Então Abigail se apressou, e tomou duzentos pães, e dois odres de vinho, e cinco ovelhas guisadas, e cinco medidas de trigo tostado, e cem cachos de passas, e duzentas pastas de figos passados, e os pôs sobre jumentos.',
        'E disse aos seus moços: Ide adiante de mim, eis que vos seguirei de perto. O que, porém, não declarou a seu marido Nabal.',
        'E sucedeu que, andando ela montada num jumento, desceu pelo encoberto do monte, e eis que Davi e os seus homens lhe vinham ao encontro, e ela encontrou-se com eles.',
        'E disse Davi: Na verdade que em vão tenho guardado tudo quanto este tem no deserto, e nada lhe faltou de tudo quanto tem, e ele me pagou mal por bem.',
        'Assim faça Deus aos inimigos de Davi, e outro tanto, se eu deixar até amanhã de tudo o que tem, até mesmo um menino.',
        'Vendo, pois, Abigail a Davi, apressou-se, e desceu do jumento, e prostrou-se sobre o seu rosto diante de Davi, e se inclinou à terra.',
        'E lançou-se a seus pés, e disse: Ah, senhor meu, minha seja a transgressão; deixa, pois, falar a tua serva aos teus ouvidos, e ouve as palavras da tua serva.',
        'Meu senhor, agora não faça este homem vil, a saber, Nabal, impressão no seu coração, porque tal é ele qual é o seu nome. Nabal é o seu nome, e a loucura está com ele, e eu, tua serva, não vi os moços de meu senhor, que enviaste.',
        'Agora, pois, meu senhor, vive o SENHOR, e vive a tua alma, que o SENHOR te impediu de vires com sangue, e de que a tua mão te salvasse; e, agora, tais quais Nabal sejam os teus inimigos e os que procuram mal contra o meu senhor.',
        'E agora este é o presente que trouxe a tua serva a meu senhor; seja dado aos moços que seguem ao meu senhor.',
        'Perdoa, pois, à tua serva esta transgressão, porque certamente fará o SENHOR casa firme a meu senhor, porque meu senhor guerreia as guerras do SENHOR, e não se tem achado mal em ti por todos os teus dias,',
        'E, levantando-se algum homem para te perseguir, e para procurar a tua morte, contudo a vida de meu senhor será atada no feixe dos que vivem com o SENHOR teu Deus; porém a vida de teus inimigos ele arrojará ao longe, como do meio do côncavo de uma funda.',
        'E há de ser que, usando o SENHOR com o meu senhor conforme a todo o bem que já tem falado de ti, e te houver estabelecido príncipe sobre Israel,',
        'Então, meu senhor, não te será por tropeço, nem por pesar no coração, o sangue que sem causa derramaste, nem tampouco por ter se vingado o meu senhor a si mesmo; e quando o SENHOR fizer bem a meu senhor, lembra-te então da tua serva.',
        'Então Davi disse a Abigail: Bendito o Senhor Deus de Israel, que hoje te enviou ao meu encontro.',
        'E bendito o teu conselho, e bendita tu, que hoje me impediste de derramar sangue, e de vingar-me pela minha própria mão.',
        'Porque, na verdade, vive o Senhor Deus de Israel, que me impediu de que te fizesse mal, que se tu não te apressaras, e não me vieras ao encontro, não ficaria a Nabal até a luz da manhã nem mesmo um menino.',
        'Então Davi tomou da sua mão o que tinha trazido, e lhe disse: Sobe em paz à tua casa; vês aqui que tenho dado ouvidos à tua voz, e tenho aceitado a tua face.',
        'E, vindo Abigail a Nabal, eis que tinha em sua casa um banquete, como banquete de rei; e o coração de Nabal estava alegre nele, e ele já muito embriagado, pelo que ela não lhe deu a entender coisa alguma, pequena nem grande, até à luz da manhã.',
        'Sucedeu, pois, que pela manhã, estando Nabal já livre do vinho, sua mulher lhe deu a entender aquelas coisas; e se amorteceu o seu coração, e ficou ele como pedra.',
        'E aconteceu que, passados quase dez dias, feriu o Senhor a Nabal, e este morreu.',
        'E, ouvindo Davi que Nabal morrera, disse: Bendito seja o Senhor, que julgou a causa de minha afronta recebida da mão de Nabal, e deteve a seu servo do mal, fazendo o Senhor tornar o mal de Nabal sobre a sua cabeça. E mandou Davi falar a Abigail, para tomá-la por sua mulher.',
        'Vindo, pois, os criados de Davi a Abigail, no Carmelo, lhe falaram, dizendo: Davi nos tem mandado a ti, para te tomar por sua mulher.',
        'Então ela se levantou, e se inclinou com o rosto em terra, e disse: Eis que a tua serva servirá de criada para lavar os pés dos criados de meu senhor.',
        'E Abigail se apressou, e se levantou, e montou num jumento com as suas cinco moças que seguiam as suas pisadas; e ela seguiu os mensageiros de Davi, e foi sua mulher.',
        'Também tomou Davi a Ainoã de Jizreel; e ambas foram suas mulheres.',
        'Porque Saul tinha dado sua filha Mical, mulher de Davi, a Palti, filho de Laís, o qual era de Galim.',
      ],
      [
        'E vieram os zifeus a Saul, a Gibeá, dizendo: Não está Davi escondido no outeiro de Haquilá, defronte de Jesimom?',
        'Então Saul se levantou e desceu ao deserto de Zife, e com ele três mil homens escolhidos de Israel, a buscar a Davi no deserto de Zife.',
        'E acampou-se Saul no outeiro de Haquilá, que está defronte de Jesimom, junto ao caminho; porém Davi ficou no deserto, e viu que Saul vinha seguindo-o no deserto.',
        'Pois Davi enviou espias, e soube que Saul tinha vindo.',
        'E Davi se levantou, e foi ao lugar onde Saul se tinha acampado; viu Davi o lugar onde se tinha deitado Saul, e Abner, filho de Ner, capitão do seu exército; e Saul estava deitado dentro do lugar dos carros, e o povo estava acampado ao redor dele.',
        'E dirigindo-se Davi a Aimeleque, o heteu, e a Abisai, filho de Zeruia, irmão de Joabe, disse: Quem descerá comigo a Saul ao arraial? E respondeu Abisai: Eu descerei contigo.',
        'Foram, pois, Davi e Abisai de noite ao povo, e eis que Saul estava deitado dormindo dentro do lugar dos carros, e a sua lança estava fincada na terra à sua cabeceira; e Abner e o povo deitavam-se ao redor dele.',
        'Então disse Abisai a Davi: Deus te entregou hoje nas mãos o teu inimigo; deixa-me, pois, agora encravá-lo com a lança de uma vez na terra, e não o ferirei segunda vez.',
        'E disse Davi a Abisai: Nenhum dano lhe faças; porque quem estendeu a sua mão contra o ungido do Senhor, e ficou inocente?',
        'Disse mais Davi: Vive o Senhor que o Senhor o ferirá, ou o seu dia chegará em que morra, ou descerá para a batalha e perecerá.',
        'O Senhor me guarde, de que eu estenda a mão contra o ungido do Senhor; agora, porém, toma a lança que está à sua cabeceira e a bilha de água, e vamo-nos.',
        'Tomou, pois, Davi a lança e a bilha de água, da cabeceira de Saul, e foram-se; e ninguém houve que o visse, nem que o advertisse, nem que acordasse; porque todos estavam dormindo, porque da parte do Senhor havia caído sobre eles um profundo sono.',
        'E Davi, passando ao outro lado, pôs-se no cume do monte ao longe, de maneira que entre eles havia grande distância.',
        'E Davi bradou ao povo, e a Abner, filho de Ner, dizendo: Não responderás, Abner? Então Abner respondeu e disse: Quem és tu, que bradas ao rei?',
        'Então disse Davi a Abner: Porventura não és homem? E quem há em Israel como tu? Por que, pois, não guardaste o rei, teu senhor? Porque um do povo veio para destruir o rei, teu senhor.',
        'Não é bom isso, que fizeste; vive o SENHOR, que sois dignos de morte, vós que não guardastes a vosso senhor, o ungido do SENHOR; vede, pois, agora onde está a lança do rei, e a bilha de água, que tinha à sua cabeceira.',
        'Então conheceu Saul a voz de Davi, e disse: Não é esta a tua voz, meu filho Davi? E disse Davi: É minha voz, ó rei meu senhor.',
        'Disse mais: Por que persegue o meu senhor tanto o seu servo? Que fiz eu? E que maldade se acha nas minhas mãos?',
        'Ouve, pois, agora, te rogo, rei meu senhor, as palavras de teu servo: Se o SENHOR te incita contra mim, receba ele a oferta de alimentos; se, porém, são os filhos dos homens, malditos sejam perante o SENHOR; pois eles me têm expulsado hoje para que eu não tenha parte na herança do SENHOR, dizendo: Vai, serve a outros deuses.',
        'Agora, pois, não se derrame o meu sangue na terra diante do Senhor; pois saiu o rei de Israel em busca de uma pulga, como quem persegue uma perdiz nos montes.',
        'Então disse Saul: Pequei; volta, meu filho Davi, porque não tornarei a fazer-te mal; porque foi hoje preciosa a minha vida aos teus olhos; eis que procedi loucamente, e errei grandissimamente.',
        'Davi então respondeu, e disse: Eis aqui a lança do rei; venha cá um dos moços, e leve-a.',
        'O Senhor, porém, pague a cada um a sua justiça e a sua lealdade; pois o Senhor te entregou hoje na minha mão, porém não quis estender a minha mão contra o ungido do Senhor.',
        'E eis que, assim como foi a tua vida hoje de tanta estima aos meus olhos, assim seja a minha vida de muita estima aos olhos do Senhor, e ele me livre de toda a tribulação.',
        'Então Saul disse a Davi: Bendito sejas tu, meu filho Davi; pois grandes coisas farás e também prevalecerás. Então Davi se foi pelo seu caminho e Saul voltou para o seu lugar.',
      ],
      [
        'Disse, porém, Davi no seu coração: Ora, algum dia ainda perecerei pela mão de Saul; não há coisa melhor para mim do que escapar apressadamente para a terra dos filisteus, para que Saul perca a esperança de mim, e cesse de me buscar por todos os termos de Israel; e assim escaparei da sua mão.',
        'Então Davi se levantou, e passou, com os seiscentos homens que com ele estavam, a Aquis, filho de Maoque, rei de Gate.',
        'E Davi ficou com Aquis em Gate, ele e os seus homens, cada um com a sua casa; Davi com ambas as suas mulheres, Ainoã, a jizreelita, e Abigail, a mulher de Nabal, o carmelita.',
        'E, sendo Saul avisado que Davi tinha fugido para Gate, não cuidou mais de buscá-lo.',
        'E disse Davi a Aquis: Se eu tenho achado graça em teus olhos, dá-me lugar numa das cidades da terra, para que ali habite; pois por que razão habitaria o teu servo contigo na cidade real?',
        'Então lhe deu Aquis, naquele dia, a cidade de Ziclague (por isso Ziclague pertence aos reis de Judá, até ao dia de hoje).',
        'E foi o número dos dias, que Davi habitou na terra dos filisteus, um ano e quatro meses.',
        'E subia Davi com os seus homens, e davam sobre os gesuritas, e os gersitas, e os amalequitas; porque antigamente foram estes os moradores da terra que se estende na direção de Sur, até à terra do Egito.',
        'E Davi feria aquela terra, e não dava vida nem a homem nem a mulher, e tomava ovelhas, e vacas, e jumentos, e camelos, e vestes; e voltava, e vinha a Aquis.',
        'E dizendo Aquis: Onde atacastes hoje? Davi dizia: Sobre o sul de Judá, e sobre o sul dos jerameelitas, e sobre o sul dos queneus.',
        'E Davi não deixava com vida nem a homem nem a mulher, para trazê-los a Gate, dizendo: Para que porventura não nos denunciem, dizendo: Assim Davi o fazia. E este era o seu costume por todos os dias que habitou na terra dos filisteus.',
        'E Aquis confiava em Davi, dizendo: Fez-se ele por certo aborrecível para com o seu povo em Israel; por isso me será por servo para sempre.',
      ],
      [
        'E sucedeu naqueles dias que, juntando os filisteus os seus exércitos à peleja, para fazer guerra contra Israel, disse Aquis a Davi: Sabe de certo que comigo sairás ao arraial, tu e os teus homens.',
        'Então disse Davi a Aquis: Assim saberás o que fará o teu servo. E disse Aquis a Davi: Por isso te terei por guarda da minha pessoa para sempre.',
        'E Samuel já estava morto, e todo o Israel o tinha chorado, e o tinha sepultado em Ramá, que era a sua cidade; e Saul tinha desterrado os adivinhos e os encantadores.',
        'E ajuntaram-se os filisteus, e vieram, e acamparam-se em Suném; e ajuntou Saul a todo o Israel, e se acamparam em Gilboa.',
        'E, vendo Saul o arraial dos filisteus, temeu, e estremeceu muito o seu coração.',
        'E perguntou Saul ao Senhor, porém o Senhor não lhe respondeu, nem por sonhos, nem por Urim, nem por profetas.',
        'Então disse Saul aos seus criados: Buscai-me uma mulher que tenha o espírito de feiticeira, para que vá a ela, e consulte por ela. E os seus criados lhe disseram: Eis que em En-Dor há uma mulher que tem o espírito de adivinhar.',
        'E Saul se disfarçou, e vestiu outras roupas, e foi ele com dois homens, e de noite chegaram à mulher; e disse: Peço-te que me adivinhes pelo espírito de feiticeira, e me faças subir a quem eu te disser.',
        'Então a mulher lhe disse: Eis aqui tu sabes o que Saul fez, como tem destruído da terra os adivinhos e os encantadores; por que, pois, me armas um laço à minha vida, para me fazeres morrer?',
        'Então Saul lhe jurou pelo Senhor, dizendo: Vive o Senhor, que nenhum mal te sobrevirá por isso.',
        'A mulher então lhe disse: A quem te farei subir? E disse ele: Faze-me subir a Samuel.',
        'Vendo, pois, a mulher a Samuel, gritou com alta voz, e falou a Saul, dizendo: Por que me tens enganado? Pois tu mesmo és Saul.',
        'E o rei lhe disse: Não temas; que é que vês? Então a mulher disse a Saul: Vejo deuses que sobem da terra.',
        'E lhe disse: Como é a sua figura? E disse ela: Vem subindo um homem ancião, e está envolto numa capa. Entendendo Saul que era Samuel, inclinou-se com o rosto em terra, e se prostrou.',
        'Samuel disse a Saul: Por que me inquietaste, fazendo-me subir? Então disse Saul: Mui angustiado estou, porque os filisteus guerreiam contra mim, e Deus se tem desviado de mim, e não me responde mais, nem pelo ministério dos profetas, nem por sonhos; por isso te chamei a ti, para que me faças saber o que hei de fazer.',
        'Então disse Samuel: Por que, pois, me perguntas a mim, visto que o Senhor te tem desamparado, e se tem feito teu inimigo?',
        'Porque o Senhor tem feito para contigo como pela minha boca te disse, e o Senhor tem rasgado o reino da tua mão, e o tem dado ao teu próximo, a Davi.',
        'Como tu não deste ouvidos à voz do Senhor, e não executaste o fervor da sua ira contra Amaleque, por isso o Senhor te fez hoje isto.',
        'E o Senhor entregará também a Israel contigo na mão dos filisteus, e amanhã tu e teus filhos estareis comigo; e o arraial de Israel o Senhor entregará na mão dos filisteus.',
        'E imediatamente Saul caiu estendido por terra, e grandemente temeu por causa daquelas palavras de Samuel; e não houve força nele; porque não tinha comido pão todo aquele dia e toda aquela noite.',
        'Então veio a mulher a Saul e, vendo que estava tão perturbado, disse-lhe: Eis que a tua criada deu ouvidos à tua voz, e pus a minha vida na minha mão, e ouvi as palavras que disseste.',
        'Agora, pois, ouve também tu as palavras da tua serva, e porei um bocado de pão diante de ti, e come, para que tenhas forças para te pores a caminho.',
        'Porém ele o recusou, e disse: Não comerei. Porém os seus criados e a mulher o constrangeram; e deu ouvidos à sua voz; e levantou-se do chão, e se assentou sobre uma cama.',
        'E tinha a mulher em casa um bezerro cevado, e se apressou, e o matou, e tomou farinha, e a amassou, e a cozeu em bolos ázimos.',
        'E os trouxe diante de Saul e de seus criados, e comeram; depois levantaram-se e partiram naquela mesma noite.',
      ],
      [
        'E ajuntaram os filisteus todos os seus exércitos em Afeque; e acamparam-se os israelitas junto à fonte que está em Jizreel.',
        'E os príncipes dos filisteus se foram para lá com centenas e com milhares; porém Davi e os seus homens iam com Aquis na retaguarda.',
        'Disseram então os príncipes dos filisteus: Que fazem aqui estes hebreus? E disse Aquis aos príncipes dos filisteus: Não é este Davi, o servo de Saul, rei de Israel, que esteve comigo há alguns dias ou anos? Coisa nenhuma achei nele desde o dia em que se revoltou, até ao dia de hoje.',
        'Porém os príncipes dos filisteus muito se indignaram contra ele; e disseram-lhe os príncipes dos filisteus: Faze voltar este homem, para que torne ao lugar em que tu o puseste, e não desça conosco à batalha, para que não se torne nosso adversário na batalha; pois, com que poderia este agradar a seu Senhor? Porventura não seria com as cabeças destes homens?',
        'Não é este aquele Davi, de quem uns aos outros cantaram nas danças, dizendo: Saul feriu os seus milhares, porém Davi os seus dez milhares?',
        'Então Aquis chamou a Davi e disse-lhe: Vive o Senhor, que tu és reto, e que a tua entrada e a tua saída comigo no arraial é boa aos meus olhos; porque nenhum mal em ti achei, desde o dia em que a mim vieste, até ao dia de hoje; porém aos olhos dos príncipes não agradas.',
        'Volta, pois, agora, e vai em paz; para que não faças mal aos olhos dos príncipes dos filisteus.',
        'Então Davi disse a Aquis: Por quê? Que fiz? Ou que achaste no teu servo, desde o dia em que estive diante de ti, até ao dia de hoje, para que não vá e peleje contra os inimigos do rei, meu senhor?',
        'Respondeu, porém, Aquis, e disse a Davi: Bem o sei; e que na verdade aos meus olhos és bom como um anjo de Deus; porém disseram os príncipes dos filisteus: Não suba este conosco à batalha.',
        'Agora, pois, amanhã de madrugada levanta-te com os servos de teu senhor, que têm vindo contigo; e, levantando-vos pela manhã, de madrugada, e havendo luz, parti.',
        'Então Davi de madrugada se levantou, ele e os seus homens, para partirem pela manhã, e voltarem à terra dos filisteus; e os filisteus subiram a Jizreel.',
      ],
      [
        'Sucedeu, pois, que, chegando Davi e os seus homens ao terceiro dia a Ziclague, já os amalequitas tinham invadido o sul, e Ziclague, e tinham ferido a Ziclague e a tinham queimado a fogo.',
        'E tinham levado cativas as mulheres, e todos os que estavam nela, tanto pequenos como grandes; a ninguém, porém, mataram, tão-somente os levaram consigo, e foram o seu caminho.',
        'E Davi e os seus homens chegaram à cidade e eis que estava queimada a fogo, e suas mulheres, seus filhos e suas filhas tinham sido levados cativos.',
        'Então Davi e o povo que se achava com ele alçaram a sua voz, e choraram, até que neles não houve mais forças para chorar.',
        'Também as duas mulheres de Davi foram levadas cativas; Ainoã, a jizreelita, e Abigail, a mulher de Nabal, o carmelita.',
        'E Davi muito se angustiou, porque o povo falava de apedrejá-lo, porque a alma de todo o povo estava em amargura, cada um por causa dos seus filhos e das suas filhas; todavia Davi se fortaleceu no Senhor seu Deus.',
        'E disse Davi a Abiatar, o sacerdote, filho de Aimeleque: Traze-me, peço-te, aqui o éfode. E Abiatar trouxe o éfode a Davi.',
        'Então consultou Davi ao Senhor, dizendo: Perseguirei eu a esta tropa? Alcançá-la-ei? E lhe disse: Persegue-a, porque decerto a alcançarás e tudo libertarás.',
        'Partiu, pois, Davi, ele e os seiscentos homens que com ele se achavam, e chegaram ao ribeiro de Besor, onde pararam os que ficaram atrás.',
        'E perseguiu-os Davi, ele e os quatrocentos homens, pois que duzentos homens ficaram, por não poderem, de cansados que estavam, passar o ribeiro de Besor.',
        'E acharam no campo um homem egípcio, e o trouxeram a Davi; deram-lhe pão, e comeu, e deram-lhe a beber água.',
        'Deram-lhe também um pedaço de massa de figos secos e dois cachos de passas, e comeu, e voltou-lhe o seu espírito, porque havia três dias e três noites que não tinha comido pão nem bebido água.',
        'Então Davi lhe disse: De quem és tu, e de onde és? E disse o moço egípcio: Sou servo de um homem amalequita, e meu senhor me deixou, porque adoeci há três dias.',
        'Nós invadimos o lado do sul dos queretitas, e o lado de Judá, e o lado do sul de Calebe, e pusemos fogo a Ziclague.',
        'E disse-lhe Davi: Poderias, descendo, guiar-me a essa tropa? E disse-lhe: Por Deus jura-me que não me matarás, nem me entregarás na mão de meu senhor, e, descendo, te guiarei a essa tropa.',
        'E, descendo, o guiou e eis que estavam espalhados sobre a face de toda a terra, comendo, e bebendo, e dançando, por todo aquele grande despojo que tomaram da terra dos filisteus e da terra de Judá.',
        'E feriu-os Davi, desde o crepúsculo até à tarde do dia seguinte; nenhum deles escapou, senão só quatrocentos moços que, montados sobre camelos, fugiram.',
        'Assim salvou Davi tudo quanto tomaram os amalequitas; também as suas duas mulheres salvou Davi.',
        'E ninguém lhes faltou, desde o menor até ao maior, e até os filhos e as filhas; e também desde o despojo até tudo quanto lhes tinham tomado, tudo Davi tornou a trazer.',
        'Também tomou Davi todas as ovelhas e vacas, e levavam-nas adiante do outro gado, e diziam: Este é o despojo de Davi.',
        'E, chegando Davi aos duzentos homens que, de cansados que estavam, não puderam seguir a Davi, e que deixaram ficar no ribeiro de Besor, estes saíram ao encontro de Davi e do povo que com ele vinha; e, chegando-se Davi com o povo, os saudou em paz.',
        'Então todos os maus e perversos, dentre os homens que tinham ido com Davi, responderam, e disseram: Visto que não foram conosco, não lhes daremos do despojo que libertamos; mas que leve cada um sua mulher e seus filhos, e se vá.',
        'Porém Davi disse: Não fareis assim, irmãos meus, com o que nos deu o Senhor, que nos guardou, e entregou a tropa que contra nós vinha, nas nossas mãos.',
        'E quem vos daria ouvidos nisso? Porque qual é a parte dos que desceram à peleja, tal também será a parte dos que ficaram com a bagagem; igualmente repartirão.',
        'O que assim foi desde aquele dia em diante, porquanto o pôs por estatuto e direito em Israel até ao dia de hoje.',
        'E, chegando Davi a Ziclague, enviou do despojo aos anciãos de Judá, seus amigos, dizendo: Eis aí para vós uma bênção do despojo dos inimigos do Senhor;',
        'Aos de Betel, e aos de Ramote do sul, e aos de Jater,',
        'E aos de Aroer, e aos de Sifmote, e aos de Estemoa,',
        'E aos de Racal, e aos que estavam nas cidades jerameelitas e nas cidades dos queneus,',
        'E aos de Hormá, e aos de Corasã, e aos de Ataca,',
        'E aos de Hebrom, e a todos os lugares em que andara Davi, ele e os seus homens.',
      ],
      [
        'Os filisteus, pois, pelejaram contra Israel; e os homens de Israel fugiram de diante dos filisteus, e caíram mortos na montanha de Gilboa.',
        'E os filisteus perseguiram a Saul e a seus filhos; e mataram a Jônatas, e a Abinadabe, e a Malquisua, filhos de Saul.',
        'E a peleja se agravou contra Saul, e os flecheiros o alcançaram; e muito temeu por causa dos flecheiros.',
        'Então disse Saul ao seu pajem de armas: Arranca a tua espada, e atravessa-me com ela, para que porventura não venham estes incircuncisos, e me atravessem e escarneçam de mim. Porém o seu pajem de armas não quis, porque temia muito; então Saul tomou a espada, e se lançou sobre ela.',
        'Vendo, pois, o seu pajem de armas que Saul já era morto, também ele se lançou sobre a sua espada, e morreu com ele.',
        'Assim faleceu Saul, e seus três filhos, e o seu pajem de armas, e também todos os seus homens morreram juntamente naquele dia.',
        'E, vendo os homens de Israel, que estavam deste lado do vale e deste lado do Jordão, que os homens de Israel fugiram, e que Saul e seus filhos estavam mortos, abandonaram as cidades, e fugiram; e vieram os filisteus, e habitaram nelas.',
        'Sucedeu, pois, que, vindo os filisteus no outro dia para despojar os mortos, acharam a Saul e a seus três filhos estirados na montanha de Gilboa.',
        'E cortaram-lhe a cabeça, e o despojaram das suas armas, e enviaram pela terra dos filisteus, em redor, a anunciá-lo no templo dos seus ídolos e entre o povo.',
        'E puseram as suas armas no templo de Astarote, e o seu corpo o afixaram no muro de Bete-Sã.',
        'Ouvindo então os moradores de Jabes-Gileade, o que os filisteus fizeram a Saul,',
        'Todo o homem valoroso se levantou, e caminharam toda a noite, e tiraram o corpo de Saul e os corpos de seus filhos do muro, de Bete-Sã, e, vindo a Jabes, os queimaram.',
        'E tomaram os seus ossos, e os sepultaram debaixo de um arvoredo, em Jabes, e jejuaram sete dias.',
      ],
    ],
    livro: '1samuel',
  },
  {
    abbrev: '2sm',
    capitulos: [
      [
        'E sucedeu que, depois da morte de Saul, voltando Davi da derrota dos amalequitas, ficou dois dias em Ziclague;',
        'Ao terceiro dia um homem veio do arraial de Saul, com as vestes rotas e com terra sobre a cabeça; e, chegando ele a Davi, se lançou no chão, e se inclinou.',
        'E Davi lhe disse: Donde vens? E ele lhe disse: Escapei do arraial de Israel.',
        'E disse-lhe Davi: Como foi lá isso? peço-te, dize-mo. E ele lhe respondeu: O povo fugiu da batalha, e muitos do povo caíram, e morreram; assim como também Saul e Jônatas, seu filho, foram mortos.',
        'E disse Davi ao moço que lhe trazia as novas: Como sabes tu que Saul e Jônatas, seu filho, foram mortos?',
        'Então disse o moço que lhe dava a notícia: Cheguei por acaso à montanha de Gilboa, e eis que Saul estava encostado sobre a sua lança, e eis que os carros e a cavalaria apertavam-no.',
        'E, olhando ele para trás de si, viu-me, e chamou-me; e eu disse: Eis-me aqui.',
        'E ele me disse: Quem és tu? E eu lhe disse: Sou amalequita.',
        'Então ele me disse: Peço-te, arremessa-te sobre mim, e mata-me, porque angústias me têm cercado, pois toda a minha vida está ainda em mim.',
        'Arremessei-me, pois, sobre ele, e o matei, porque bem sabia eu que não viveria depois da sua queda, e tomei a coroa que tinha na cabeça, e o bracelete que trazia no braço, e os trouxe aqui a meu senhor.',
        'Então apanhou Davi as suas vestes, e as rasgou; assim fizeram todos os homens que estavam com ele.',
        'E prantearam, e choraram, e jejuaram até à tarde por Saul, e por Jônatas, seu filho, e pelo povo do Senhor, e pela casa de Israel, porque tinham caído à espada.',
        'Disse então Davi ao moço que lhe trouxera a nova: Donde és tu? E disse ele: Sou filho de um estrangeiro, amalequita.',
        'E Davi lhe disse: Como não temeste tu estender a mão para matares ao ungido do Senhor?',
        'Então chamou Davi a um dos moços, e disse: Chega, e lança-te sobre ele. E ele o feriu, e morreu.',
        'Pois Davi lhe dissera: O teu sangue seja sobre a tua cabeça, porque a tua própria boca testificou contra ti, dizendo: Eu matei o ungido do Senhor.',
        'E lamentou Davi a Saul e a Jônatas, seu filho, com esta lamentação',
        '(Dizendo ele que ensinassem aos filhos de Judá o uso do arco. Eis que está escrito no livro de Jasher):',
        'Ah, ornamento de Israel! Nos teus altos foi ferido, como caíram os poderosos!',
        'Não o noticieis em Gate, não o publiqueis nas ruas de Ascalom, para que não se alegrem as filhas dos filisteus, para que não saltem de contentamento as filhas dos incircuncisos.',
        'Vós, montes de Gilboa, nem orvalho, nem chuva caia sobre vós, nem haja campos de ofertas alçadas, pois aí desprezivelmente foi arrojado o escudo dos poderosos, o escudo de Saul, como se não fora ungido com óleo.',
        'Do sangue dos feridos, da gordura dos valentes, nunca se retirou para trás o arco de Jônatas, nem voltou vazia a espada de Saul.',
        'Saul e Jônatas, tão amados e queridos na sua vida, também na sua morte não se separaram; eram mais ligeiros do que as águias, mais fortes do que os leões.',
        'Vós, filhas de Israel, chorai por Saul, que vos vestia de escarlata em delícias, que vos fazia trazer ornamentos de ouro sobre as vossas vestes.',
        'Como caíram os poderosos, no meio da peleja! Jônatas nos teus altos foi morto.',
        'Angustiado estou por ti, meu irmão Jônatas; quão amabilíssimo me eras! Mais maravilhoso me era o teu amor do que o amor das mulheres.',
        'Como caíram os poderosos, e pereceram as armas de guerra!',
      ],
      [
        'E sucedeu depois disto que Davi consultou ao SENHOR, dizendo: Subirei a alguma das cidades de Judá? E disse-lhe o SENHOR: Sobe. E falou Davi: Para onde subirei? E disse: Para Hebrom.',
        'E subiu Davi para lá, e também as suas duas mulheres, Ainoã, a jizreelita, e Abigail, a mulher de Nabal, o carmelita.',
        'Fez também Davi subir os homens que estavam com ele, cada um com a sua família; e habitaram nas cidades de Hebrom.',
        'Então vieram os homens de Judá, e ungiram ali a Davi rei sobre a casa de Judá. E deram avisos a Davi, dizendo: Os homens de Jabes-Gileade foram os que sepultaram a Saul.',
        'Então enviou Davi mensageiros aos homens de Jabes-Gileade, para dizer-lhes: Benditos sejais vós do SENHOR, que fizestes tal beneficência a vosso senhor, a Saul, e o sepultastes!',
        'Agora, pois, o Senhor use convosco de beneficência e fidelidade; e também eu vos farei este bem, porquanto fizestes isto.',
        'Esforcem-se, pois, agora as vossas mãos, e sede homens valentes, pois Saul, vosso senhor, é morto, mas também os da casa de Judá já me ungiram a mim por seu rei.',
        'Porém Abner, filho de Ner, capitão do exército de Saul, tomou a Is-Bosete, filho de Saul, e o fez passar a Maanaim,',
        'E o constituiu rei sobre Gileade, e sobre os assuritas, e sobre Jizreel, e sobre Efraim, e sobre Benjamim, e sobre todo o Israel.',
        'Da idade de quarenta anos era Is-Bosete, filho de Saul, quando começou a reinar sobre Israel, e reinou dois anos; mas os da casa de Judá seguiam a Davi.',
        'E foi o número dos dias que Davi reinou em Hebrom, sobre a casa de Judá, sete anos e seis meses.',
        'Então saiu Abner, filho de Ner, com os servos de Is-Bosete, filho de Saul, de Maanaim a Gibeom.',
        'Saíram também Joabe, filho de Zeruia, e os servos de Davi, e se encontraram uns com os outros perto do tanque de Gibeom; e pararam estes deste lado do tanque, e os outros do outro lado do tanque.',
        'E disse Abner a Joabe: Deixa levantar os moços, e joguem diante de nós. E disse Joabe: Levantem-se.',
        'Então se levantaram, e passaram, em número de doze de Benjamim, da parte de Is-Bosete, filho de Saul, e doze dos servos de Davi.',
        'E cada um lançou mão da cabeça do outro, cravou-lhe a espada no lado, e caíram juntos, por isso se chamou àquele lugar Helcate-Hazurim, que está junto a Gibeom.',
        'E seguiu-se naquele dia uma crua peleja; porém Abner e os homens de Israel foram feridos diante dos servos de Davi.',
        'E estavam ali os três filhos de Zeruia, Joabe, Abisai, e Asael; e Asael era ligeiro de pés, como as gazelas do campo.',
        'E Asael perseguiu a Abner; e não se desviou de detrás de Abner, nem para a direita nem para a esquerda.',
        'E Abner, olhando para trás, perguntou: És tu Asael? E ele falou: Eu sou.',
        'Então lhe disse Abner: Desvia-te para a direita, ou para a esquerda, e lança mão de um dos moços, e toma os seus despojos. Porém Asael não quis desviar-se de detrás dele.',
        'Então Abner tornou a dizer a Asael: Desvia-te de detrás de mim; por que hei de eu ferir-te e dar contigo em terra? E como levantaria eu o meu rosto diante de Joabe, teu irmão?',
        'Porém, não querendo ele se desviar, Abner o feriu com a ponta da lança pela quinta costela, e a lança lhe saiu por detrás, e caiu ali, e morreu naquele mesmo lugar; e sucedeu que, todos os que chegavam ao lugar onde Asael caiu e morreu, paravam.',
        'Porém Joabe e Abisai perseguiram a Abner; e pôs-se o sol, chegando eles ao outeiro de Amá, que está diante de Gia, junto ao caminho do deserto de Gibeão.',
        'E os filhos de Benjamim se ajuntaram atrás de Abner, e fizeram um batalhão, e puseram-se no cume de um outeiro.',
        'Então Abner gritou a Joabe, e disse: Consumirá a espada para sempre? Não sabes tu que por fim haverá amargura? E até quando não hás de dizer ao povo que deixe de perseguir a seus irmãos?',
        'E disse Joabe: Vive Deus, que, se não tivesses falado, só pela manhã o povo teria cessado, cada um, de perseguir a seu irmão.',
        'Então Joabe tocou a buzina, e todo o povo parou, e não perseguiram mais a Israel; e tampouco pelejaram mais.',
        'E caminharam Abner e os seus homens toda aquela noite pela planície; e, passando o Jordão, caminharam por todo o Bitrom, e chegaram a Maanaim.',
        'Também Joabe voltou de perseguir a Abner, e ajuntou todo o povo; e dos servos de Davi faltaram dezenove homens, e Asael.',
        'Porém os servos de Davi feriram dentre os de Benjamim, e dentre os homens de Abner, a trezentos e sessenta homens, que ali ficaram mortos.',
        'E levantaram a Asael, e sepultaram-no na sepultura de seu pai, que estava em Belém; e Joabe e seus homens caminharam toda aquela noite, e amanheceu-lhes o dia em Hebrom.',
      ],
      [
        'E houve uma longa guerra entre a casa de Saul e a casa de Davi; porém Davi ia se fortalecendo, mas os da casa de Saul se iam enfraquecendo.',
        'E a Davi nasceram filhos em Hebrom; e foi o seu primogênito Amnom, de Ainoã a jizreelita;',
        'E seu segundo, Quileabe, de Abigail, mulher de Nabal, o carmelita; e o terceiro Absalão, filho de Maaca, filha de Talmai, rei de Gesur;',
        'E o quarto, Adonias, filho de Hagite; e o quinto, Sefatias, filho de Abital;',
        'E o sexto, Itreão, de Eglá, também mulher de Davi; estes nasceram a Davi em Hebrom.',
        'E, havendo guerra entre a casa de Saul e a casa de Davi, sucedeu que Abner se fez poderoso na casa de Saul.',
        'E tinha tido Saul uma concubina, cujo nome era Rispa, filha de Aiá; e disse Is-Bosete a Abner: Por que possuíste a concubina de meu pai?',
        'Então se irou muito Abner pelas palavras de Is-Bosete, e disse: Sou eu cabeça de cão, que pertença a Judá? Ainda hoje faço beneficência à casa de Saul, teu pai, a seus irmãos, e a seus amigos, e não te entreguei nas mãos de Davi, e tu hoje buscas motivo para me argüires por causa da maldade de uma mulher.',
        'Assim faça Deus a Abner, e outro tanto, se, como o Senhor jurou a Davi, assim eu não lhe fizer,',
        'Transferindo o reino da casa de Saul, e confirmando o trono de Davi sobre Israel, e sobre Judá, desde Dã até Berseba.',
        'E nenhuma palavra podia ele responder a Abner, porque o temia.',
        'Então enviou Abner da sua parte mensageiros a Davi, dizendo: De quem é a terra? E disse mais: Comigo faze o teu acordo, e eis que a minha mão será contigo, para tornar a ti todo o Israel.',
        'E disse Davi: Bem, eu farei contigo acordo, porém uma coisa te peço: não verás a minha face, se primeiro não me trouxeres a Mical, filha de Saul, quando vieres ver a minha face.',
        'Também enviou Davi mensageiros a Is-Bosete, filho de Saul, dizendo: Dá-me minha mulher Mical, que eu desposei por cem prepúcios de filisteus.',
        'E enviou Is-Bosete, e tirou-a de seu marido, a Paltiel, filho de Laís.',
        'E ia com ela seu marido, caminhando, e chorando atrás dela, até Baurim. Então lhe disse Abner: Vai-te, agora volta. E ele voltou.',
        'E falou Abner com os anciãos de Israel, dizendo: Já há muito tempo que procuráveis que Davi reinasse sobre vós.',
        'Fazei-o, pois, agora, porque o Senhor falou a Davi, dizendo: Pela mão de Davi meu servo livrarei o meu povo das mãos dos filisteus e das mãos de todos os seus inimigos.',
        'E falou também Abner aos de Benjamim; e foi também Abner dizer aos de Davi, em Hebrom, tudo o que era bom aos olhos de Israel e aos olhos de toda a casa de Benjamim.',
        'E foi Abner a Davi, em Hebrom, e vinte homens com ele; e Davi fez um banquete a Abner e aos homens que com ele estavam.',
        'Então disse Abner a Davi: Eu me levantarei, e irei, e ajuntarei ao rei meu senhor todo o Israel, para fazer acordo contigo; e tu reinarás sobre tudo o que desejar a tua alma. Assim despediu Davi a Abner, e ele foi em paz.',
        'E eis que os servos de Davi e Joabe vieram de uma batalha, e traziam consigo grande despojo; e já Abner não estava com Davi em Hebrom, porque o tinha despedido, e se tinha ido em paz.',
        'Chegando, pois, Joabe, e todo o exército que vinha com ele, deram aviso a Joabe, dizendo: Abner, filho de Ner, veio ao rei, e o despediu, e foi em paz.',
        'Então Joabe foi ao rei, e disse: Que fizeste? Eis que Abner veio ter contigo; por que pois o despediste, de maneira que se fosse assim livremente?',
        'Bem conheces a Abner, filho de Ner, que te veio enganar, e saber a tua saída e a tua entrada, e entender tudo quanto fazes.',
        'E Joabe, retirando-se de Davi, enviou mensageiros atrás de Abner, e o fizeram voltar desde o poço de Sirá, sem que Davi o soubesse.',
        'Voltando, pois, Abner a Hebrom, Joabe o levou à parte, à entrada da porta, para lhe falar em segredo; e feriu-o ali pela quinta costela, e morreu, por causa do sangue de Asael seu irmão.',
        'O que Davi depois ouvindo, disse: Inocente sou eu, e o meu reino, para com o Senhor, para sempre, do sangue de Abner, filho de Ner.',
        'Caia sobre a cabeça de Joabe e sobre toda a casa de seu pai, e nunca na casa de Joabe falte quem tenha fluxo, ou quem seja leproso, ou quem se atenha a bordão, ou quem caia à espada, ou quem necessite de pão.',
        'Joabe, pois, e Abisai, seu irmão, mataram a Abner, por ter morto a Asael, seu irmão, na peleja em Gibeão.',
        'Disse, pois, Davi a Joabe, e a todo o povo que com ele estava: Rasgai as vossas vestes; e cingi-vos de sacos e ide pranteando diante de Abner. E o rei Davi ia seguindo o féretro.',
        'E, sepultando a Abner em Hebrom, o rei levantou a sua voz, e chorou junto da sepultura de Abner; e chorou todo o povo.',
        'E o rei, pranteando Abner, disse: Havia de morrer Abner como morre o vilão?',
        'As tuas mãos não estavam atadas, nem os teus pés carregados de grilhões, mas caíste como os que caem diante dos filhos da maldade! Então todo o povo chorou muito mais por ele.',
        'Depois todo o povo veio fazer com que Davi comesse pão, sendo ainda dia; porém Davi jurou, dizendo: Assim Deus me faça, e outro tanto, se, antes que o sol se ponha, eu provar pão ou alguma coisa.',
        'O que todo o povo entendendo, pareceu bem aos seus olhos; assim como tudo quanto o rei fez pareceu bem aos olhos de todo o povo.',
        'E todo o povo e todo o Israel entenderam naquele mesmo dia que não procedera do rei que matasse a Abner, filho de Ner.',
        'Então disse o rei aos seus servos: Não sabeis que hoje caiu em Israel um príncipe e um grande?',
        'Que eu hoje estou fraco, ainda que ungido rei; estes homens, filhos de Zeruia, são mais duros do que eu; o Senhor pagará ao malfeitor, conforme a sua maldade.',
      ],
      [
        'Ouvindo, pois, o filho de Saul, que Abner morrera em Hebrom, as mãos se lhe afrouxaram; e todo o Israel pasmou.',
        'E tinha o filho de Saul dois homens capitães de tropas; e era o nome de um Baaná, e o nome do outro Recabe, filhos de Rimom, o beerotita, dos filhos de Benjamim, porque também Beerote se reputava de Benjamim.',
        'E tinham fugido os beerotitas para Gitaim, e ali têm peregrinado até ao dia de hoje.',
        'E Jônatas, filho de Saul, tinha um filho aleijado de ambos os pés; era da idade de cinco anos quando as novas de Saul e Jônatas vieram de Jizreel, e sua ama o tomou, e fugiu; e sucedeu que, apressando-se ela a fugir, ele caiu, e ficou coxo; e o seu nome era Mefibosete.',
        'E foram os filhos de Rimom, o beerotita, Recabe e Baaná, e entraram em casa de Is-Bosete no maior calor do dia, estando ele deitado a dormir, ao meio-dia.',
        'E ali entraram até ao meio da casa, como que vindo buscar trigo, e o feriram na quinta costela; e Recabe e Baaná, seu irmão, escaparam.',
        'Porque entraram na sua casa, estando ele na cama deitado, no seu quarto, e o feriram, e o mataram, e lhe cortaram a cabeça; e, tomando a sua cabeça, andaram toda a noite caminhando pela planície.',
        'E trouxeram a cabeça de Is-Bosete a Davi, a Hebrom, e disseram ao rei: Eis aqui a cabeça de Is-Bosete, filho de Saul, teu inimigo, que procurava a tua morte; assim o SENHOR vingou hoje ao rei meu senhor, de Saul e da sua descendência.',
        'Porém Davi, respondendo a Recabe e a Baaná, seu irmão, filhos de Rimom, o beerotita, disse-lhes: Vive o Senhor, que remiu a minha alma de toda a angústia,',
        'Se aquele que me trouxe novas, dizendo: Eis que Saul é morto, parecendo-lhe, porém, aos olhos que era como quem trazia boas novas, eu logo lancei mão dele, e o matei em Ziclague, cuidando ele que eu por isso lhe desse recompensa.',
        'Quanto mais a ímpios homens, que mataram um homem justo em sua casa, sobre a sua cama; agora, pois, não requereria eu o seu sangue de vossas mãos, e não vos exterminaria da terra?',
        'E deu Davi ordem aos seus moços que os matassem; e cortaram-lhes os pés e as mãos, e os penduraram sobre o tanque de Hebrom; tomaram, porém, a cabeça de Is-Bosete, e a sepultaram na sepultura de Abner, em Hebrom.',
      ],
      [
        'Então todas as tribos de Israel vieram a Davi, em Hebrom, e falaram, dizendo: Eis-nos aqui, somos teus ossos e tua carne.',
        'E também outrora, sendo Saul ainda rei sobre nós, eras tu o que saías e entravas com Israel; e também o Senhor te disse: Tu apascentarás o meu povo de Israel, e tu serás príncipe sobre Israel.',
        'Assim, pois, todos os anciãos de Israel vieram ao rei, em Hebrom; e o rei Davi fez com eles acordo em Hebrom, perante o Senhor; e ungiram a Davi rei sobre Israel.',
        'Da idade de trinta anos era Davi quando começou a reinar; quarenta anos reinou.',
        'Em Hebrom reinou sobre Judá sete anos e seis meses, e em Jerusalém reinou trinta e três anos sobre todo o Israel e Judá.',
        'E partiu o rei com os seus homens a Jerusalém, contra os jebuseus que habitavam naquela terra; e falaram a Davi, dizendo: Não entrarás aqui, pois os cegos e os coxos te repelirão, querendo dizer: Não entrará Davi aqui.',
        'Porém Davi tomou a fortaleza de Sião; esta é a cidade de Davi.',
        'Porque Davi disse naquele dia: Qualquer que ferir aos jebuseus, suba ao canal e fira aos coxos e aos cegos, a quem a alma de Davi odeia. Por isso se diz: Nem cego nem coxo entrará nesta casa.',
        'Assim habitou Davi na fortaleza, e a chamou a cidade de Davi; e Davi foi edificando em redor, desde Milo para dentro.',
        'E Davi ia, cada vez mais, aumentando e crescendo, porque o Senhor Deus dos Exércitos era com ele.',
        'E Hirão, rei de Tiro, enviou mensageiros a Davi, e madeira de cedro, e carpinteiros, e pedreiros que edificaram a Davi uma casa.',
        'E entendeu Davi que o Senhor o confirmara rei sobre Israel, e que exaltara o seu reino por amor do seu povo.',
        'E tomou Davi mais concubinas e mulheres de Jerusalém, depois que viera de Hebrom; e nasceram a Davi mais filhos e filhas.',
        'E estes são os nomes dos que lhe nasceram em Jerusalém: Samua, e Sobabe, e Natã, e Salomão,',
        'E Ibar, e Elisua, e Nefegue, e Jafia,',
        'E Elisama, e Eliada, e Elifelete.',
        'Ouvindo, pois, os filisteus que haviam ungido a Davi rei sobre Israel, todos os filisteus subiram em busca de Davi; o que ouvindo Davi, desceu à fortaleza.',
        'E os filisteus vieram, e se estenderam pelo vale de Refaim.',
        'E Davi consultou ao Senhor, dizendo: Subirei contra os filisteus? Entregar-mos-ás nas minhas mãos? E disse o Senhor a Davi: Sobe, porque certamente entregarei os filisteus nas tuas mãos.',
        'Então foi Davi a Baal-Perazim; e feriu-os ali Davi, e disse: Rompeu o Senhor a meus inimigos diante de mim, como quem rompe águas. Por isso chamou o nome daquele lugar Baal-Perazim.',
        'E deixaram ali os seus ídolos; e Davi e os seus homens os tomaram.',
        'E os filisteus tornaram a subir, e se estenderam pelo vale de Refaim.',
        'E Davi consultou ao Senhor, o qual disse: Não subirás; mas rodeia por detrás deles, e virás a eles por defronte das amoreiras.',
        'E há de ser que, ouvindo tu um estrondo de marcha pelas copas das amoreiras, então te apressarás; porque o Senhor saiu então diante de ti, a ferir o arraial dos filisteus.',
        'E fez Davi assim como o Senhor lhe tinha ordenado; e feriu os filisteus desde Geba, até chegar a Gezer.',
      ],
      [
        'E tornou Davi a ajuntar todos os escolhidos de Israel, em número de trinta mil.',
        'E levantou-se Davi, e partiu, com todo o povo que tinha consigo, para Baalim de Judá, para levarem dali para cima a arca de Deus, sobre a qual se invoca o nome, o nome do Senhor dos Exércitos, que se assenta entre os querubins.',
        'E puseram a arca de Deus em um carro novo, e a levaram da casa de Abinadabe, que está em Gibeá; e Uzá e Aiô, filhos de Abinadabe, guiavam o carro novo.',
        'E levando-o da casa de Abinadabe, que está em Gibeá, com a arca de Deus, Aiô ia adiante da arca.',
        'E Davi, e toda a casa de Israel, festejavam perante o Senhor, com toda a sorte de instrumentos de pau de faia, como também com harpas, e com saltérios, e com tamboris, e com pandeiros, e com címbalos.',
        'E, chegando à eira de Nacom, estendeu Uzá a mão à arca de Deus, e pegou nela; porque os bois a deixavam pender.',
        'Então a ira do Senhor se acendeu contra Uzá, e Deus o feriu ali por esta imprudência; e morreu ali junto à arca de Deus.',
        'E Davi se contristou, porque o Senhor abrira rotura em Uzá; e chamou àquele lugar Perez-Uzá, até ao dia de hoje.',
        'E temeu Davi ao Senhor naquele dia; e disse: Como virá a mim a arca do Senhor?',
        'E não quis Davi retirar para junto de si a arca do Senhor, à cidade de Davi; mas Davi a fez levar à casa de Obede-Edom, o giteu.',
        'E ficou a arca do Senhor em casa de Obede-Edom, o giteu, três meses; e abençoou o Senhor a Obede-Edom, e a toda a sua casa.',
        'Então avisaram a Davi, dizendo: Abençoou o Senhor a casa de Obede-Edom, e tudo quanto tem, por causa da arca de Deus; foi pois Davi, e trouxe a arca de Deus para cima, da casa de Obede-Edom, à cidade de Davi, com alegria.',
        'E sucedeu que, quando os que levavam a arca do Senhor tinham dado seis passos, sacrificava bois e carneiros cevados.',
        'E Davi saltava com todas as suas forças diante do Senhor; e estava Davi cingido de um éfode de linho.',
        'Assim subindo, levavam Davi e todo o Israel a arca do Senhor, com júbilo, e ao som das trombetas.',
        'E sucedeu que, entrando a arca do Senhor na cidade de Davi, Mical, a filha de Saul, estava olhando pela janela; e, vendo ao rei Davi, que ia bailando e saltando diante do Senhor, o desprezou no seu coração.',
        'E introduzindo a arca do Senhor, a puseram no seu lugar, na tenda que Davi lhe armara; e ofereceu Davi holocaustos e ofertas pacíficas perante o Senhor.',
        'E acabando Davi de oferecer os holocaustos e ofertas pacíficas, abençoou o povo em nome do Senhor dos Exércitos.',
        'E repartiu a todo o povo, e a toda a multidão de Israel, desde os homens até às mulheres, a cada um, um bolo de pão, e um bom pedaço de carne, e um frasco de vinho; então retirou-se todo o povo, cada um para sua casa,',
        'E, voltando Davi para abençoar a sua casa, Mical, a filha de Saul, saiu a encontrar-se com Davi, e disse: Quão honrado foi o rei de Israel, descobrindo-se hoje aos olhos das servas de seus servos, como sem pejo se descobre qualquer dos vadios.',
        'Disse, porém, Davi a Mical: Perante o Senhor, que me escolheu preferindo-me a teu pai, e a toda a sua casa, mandando-me que fosse soberano sobre o povo do Senhor, sobre Israel, perante o Senhor tenho me alegrado.',
        'E ainda mais do que isto me envilecerei, e me humilharei aos meus olhos; mas das servas, de quem falaste, delas serei honrado.',
        'E Mical, a filha de Saul, não teve filhos, até o dia da sua morte.',
      ],
      [
        'E sucedeu que, estando o rei Davi em sua casa, e tendo o SENHOR lhe dado descanso de todos os seus inimigos em redor,',
        'Disse o rei ao profeta Natã: Eis que eu moro em casa de cedro, e a arca de Deus mora dentro de cortinas.',
        'E disse Natã ao rei: Vai, e faze tudo quanto está no teu coração; porque o Senhor é contigo.',
        'Porém sucedeu naquela mesma noite, que a palavra do Senhor veio a Natã, dizendo:',
        'Vai, e dize a meu servo Davi: Assim diz o Senhor: Edificar-me-ás tu uma casa para minha habitação?',
        'Porque em casa nenhuma habitei desde o dia em que fiz subir os filhos de Israel do Egito até ao dia de hoje; mas andei em tenda e em tabernáculo.',
        'E em todo o lugar em que andei com todos os filhos de Israel, falei porventura alguma palavra a alguma das tribos de Israel, a quem mandei apascentar o meu povo de Israel, dizendo: Por que não me edificais uma casa de cedro?',
        'Agora, pois, assim dirás ao meu servo Davi: Assim diz o Senhor dos Exércitos: Eu te tomei da malhada, de detrás das ovelhas, para que fosses o soberano sobre o meu povo, sobre Israel.',
        'E fui contigo, por onde quer que foste, e destruí a teus inimigos diante de ti; e fiz grande o teu nome, como o nome dos grandes que há na terra.',
        'E prepararei lugar para o meu povo, para Israel, e o plantarei, para que habite no seu lugar, e não mais seja removido, e nunca mais os filhos da perversidade o aflijam, como dantes,',
        'E desde o dia em que mandei que houvesse juízes sobre o meu povo Israel; a ti, porém, te dei descanso de todos os teus inimigos; também o Senhor te faz saber que te fará casa.',
        'Quando teus dias forem completos, e vieres a dormir com teus pais, então farei levantar depois de ti um dentre a tua descendência, o qual sairá das tuas entranhas, e estabelecerei o seu reino.',
        'Este edificará uma casa ao meu nome, e confirmarei o trono do seu reino para sempre.',
        'Eu lhe serei por pai, e ele me será por filho; e, se vier a transgredir, castigá-lo-ei com vara de homens, e com açoites de filhos de homens.',
        'Mas a minha benignidade não se apartará dele; como a tirei de Saul, a quem tirei de diante de ti.',
        'Porém a tua casa e o teu reino serão firmados para sempre diante de ti; teu trono será firme para sempre.',
        'Conforme a todas estas palavras, e conforme a toda esta visão, assim falou Natã a Davi.',
        'Então entrou o rei Davi, e ficou perante o SENHOR, e disse: Quem sou eu, Senhor DEUS, e qual é a minha casa, para que me tenhas trazido até aqui?',
        'E ainda foi isto pouco aos teus olhos, Senhor DEUS, senão que também falaste da casa de teu servo para tempos distantes; é este o procedimento dos homens, ó Senhor DEUS?',
        'E que mais te pode dizer ainda Davi? Pois tu conheces bem a teu servo, ó Senhor DEUS.',
        'Por causa da tua palavra, e segundo o teu coração, fizeste toda esta grandeza; fazendo-a saber a teu servo.',
        'Portanto, grandioso és, ó Senhor Deus, porque não há semelhante a ti, e não há outro Deus senão tu só, segundo tudo o que temos ouvido com os nossos ouvidos.',
        'E quem há como o teu povo, como Israel, gente única na terra, a quem Deus foi resgatar para seu povo, para fazer-te nome, e para fazer-vos estas grandes e terríveis coisas à tua terra, diante do teu povo, que tu resgataste do Egito, desterrando as nações e a seus deuses?',
        'E confirmaste a teu povo Israel por teu povo para sempre, e tu, Senhor, te fizeste o seu Deus.',
        'Agora, pois, ó Senhor Deus, esta palavra que falaste acerca de teu servo e acerca da sua casa, confirma-a para sempre, e faze como tens falado.',
        'E engrandeça-se o teu nome para sempre, para que se diga: O Senhor dos Exércitos é Deus sobre Israel; e a casa de teu servo será confirmada diante de ti.',
        'Pois tu, Senhor dos Exércitos, Deus de Israel, revelaste aos ouvidos de teu servo, dizendo: Edificar-te-ei uma casa. Portanto o teu servo se animou para fazer-te esta oração.',
        'Agora, pois, Senhor DEUS, tu és o mesmo Deus, e as tuas palavras são verdade, e tens falado a teu servo este bem.',
        'Sê, pois, agora servido de abençoar a casa de teu servo, para permanecer para sempre diante de ti, pois tu, ó Senhor DEUS, o disseste; e com a tua bênção será para sempre bendita a casa de teu servo.',
      ],
      [
        'E sucedeu depois disto que Davi feriu os filisteus, e os sujeitou; e Davi tomou a Metegue-Ama das mãos dos filisteus.',
        'Também derrotou os moabitas, e os mediu com cordel, fazendo-os deitar por terra; e os mediu com dois cordéis para os matar, e com um cordel inteiro para os deixar com vida. Ficaram assim os moabitas por servos de Davi, pagando-lhe tributos.',
        'Feriu também Davi a Hadadezer, filho de Reobe, rei de Zobá, quando ele ia recuperar o seu domínio sobre o rio Eufrates.',
        'E tomou-lhe Davi mil carros e setecentos cavaleiros e vinte mil homens de pé; e Davi jarretou a todos os cavalos dos carros, e reservou deles cem carros.',
        'E vieram os sírios de Damasco a socorrer a Hadadezer, rei de Zobá; porém Davi feriu dos sírios vinte e dois mil homens.',
        'E Davi pôs guarnições na Síria de Damasco, e os sírios ficaram por servos de Davi, pagando-lhe tributos; e o Senhor guardou a Davi por onde quer que ia.',
        'E Davi tomou os escudos de ouro que havia com os servos de Hadadezer, e os trouxe a Jerusalém.',
        'Tomou mais o rei Davi uma quantidade muito grande de bronze de Betá e de Berotai, cidades de Hadadezer.',
        'Então ouvindo Toí, rei de Hamate, que Davi ferira a todo o exército de Hadadezer,',
        'Mandou Toí, seu filho Jorão, ao rei Davi, para lhe perguntar como estava, e para lhe dar os parabéns por haver pelejado contra Hadadezer, e por o haver ferido (porque Hadadezer de contínuo fazia guerra a Toí); e na sua mão trazia vasos de prata, e vasos de ouro, e vasos de bronze,',
        'Os quais também o rei Davi consagrou ao Senhor, juntamente com a prata e ouro que já havia consagrado de todas as nações que sujeitara.',
        'Da Síria, e de Moabe, e dos filhos de Amom, e dos filisteus, e de Amaleque, e dos despojos de Hadadezer, filho de Reobe, rei de Zobá.',
        'Também Davi ganhou nome, voltando ele de ferir os sírios no vale do Sal, a saber, a dezoito mil.',
        'E pôs guarnições, em Edom, em todo o Edom pôs guarnições, e todos os edomeus ficaram por servos de Davi; e o Senhor ajudava a Davi por onde quer que ia.',
        'Reinou, pois, Davi sobre todo o Israel; e Davi fazia direito e justiça a todo o seu povo.',
        'E Joabe, filho de Zeruia, era sobre o exército; e Jeosafá, filho de Ailude, era cronista.',
        'E Zadoque, filho de Aitube, e Aimeleque, filho de Abiatar, eram sacerdotes, e Seraías escrivão.',
        'Também Benaia, filho de Jeoiada, estava sobre os quereteus e peleteus; porém os filhos de Davi eram ministros.',
      ],
      [
        'E disse Davi: Há ainda alguém que tenha ficado da casa de Saul, para que lhe faça benevolência por amor de Jônatas?',
        'E havia um servo na casa de Saul cujo nome era Ziba; e o chamaram à presença de Davi. Disse-lhe o rei: És tu Ziba? E ele disse: Servo teu.',
        'E disse o rei: Não há ainda alguém da casa de Saul para que eu use com ele da benevolência de Deus? Então disse Ziba ao rei: Ainda há um filho de Jônatas, aleijado de ambos os pés.',
        'E disse-lhe o rei: Onde está? E disse Ziba ao rei: Eis que está em casa de Maquir, filho de Amiel, em Lo-Debar.',
        'Então mandou o rei Davi, e o tomou da casa de Maquir, filho de Amiel, de Lo-Debar.',
        'E Mefibosete, filho de Jônatas, o filho de Saul, veio a Davi, e se prostrou com o rosto por terra e inclinou-se; e disse Davi: Mefibosete! E ele disse: Eis aqui teu servo.',
        'E disse-lhe Davi: Não temas, porque decerto usarei contigo de benevolência por amor de Jônatas, teu pai, e te restituirei todas as terras de Saul, teu pai, e tu sempre comerás pão à minha mesa.',
        'Então se inclinou, e disse: Quem é teu servo, para teres olhado para um cão morto tal como eu?',
        'Então chamou Davi a Ziba, moço de Saul, e disse-lhe: Tudo o que pertencia a Saul, e a toda a sua casa, tenho dado ao filho de teu senhor.',
        'Trabalhar-lhe-ás, pois, a terra, tu e teus filhos, e teus servos, e recolherás os frutos, para que o filho de teu senhor tenha pão para comer; mas Mefibosete, filho de teu senhor, sempre comerá pão à minha mesa. E tinha Ziba quinze filhos e vinte servos.',
        'E disse Ziba ao rei: Conforme a tudo quanto meu senhor, o rei, manda a seu servo, assim fará teu servo. Quanto a Mefibosete, disse o rei, comerá à minha mesa como um dos filhos do rei.',
        'E tinha Mefibosete um filho pequeno, cujo nome era Mica; e todos quantos moravam em casa de Ziba eram servos de Mefibosete.',
        'Morava, pois, Mefibosete em Jerusalém, porquanto sempre comia à mesa do rei, e era coxo de ambos os pés.',
      ],
      [
        'E aconteceu depois disto que morreu o rei dos filhos de Amom, e seu filho Hanum reinou em seu lugar.',
        'Então disse Davi: Usarei de benevolência com Hanum, filho de Naás, como seu pai usou de benevolência comigo. E enviou Davi os seus servos para consolá-lo acerca de seu pai; e foram os servos de Davi à terra dos filhos de Amom.',
        'Então disseram os príncipes dos filhos de Amom a seu senhor, Hanum: Porventura honra Davi a teu pai aos teus olhos, porque te enviou consoladores? Não te enviou antes Davi os seus servos para reconhecerem esta cidade, e para espiá-la, e para transtorná-la?',
        'Então tomou Hanum os servos de Davi, e lhes raspou metade da barba, e lhes cortou metade das vestes, até às nádegas, e os despediu.',
        'Quando isso foi informado a Davi, enviou ele mensageiros a encontrá-los, porque estavam aqueles homens sobremaneira envergonhados. Mandou o rei dizer-lhes: Deixai-vos estar em Jericó, até que vos torne a crescer a barba, e então voltai.',
        'Vendo, pois, os filhos de Amom que se tinham feito abomináveis para com Davi, enviaram os filhos de Amom, e alugaram dos sírios de Bete-Reobe e dos sírios de Zobá vinte mil homens de pé, e do rei de Maaca mil homens e dos homens de Tobe doze mil homens.',
        'E ouvindo Davi, enviou a Joabe e a todo o exército dos valentes.',
        'E saíram os filhos de Amom, e ordenaram a batalha à entrada da porta; mas os sírios de Zobá e Reobe, e os homens de Tobe e Maaca estavam à parte no campo.',
        'Vendo, pois, Joabe que a batalha estava preparada contra ele pela frente e pela retaguarda, escolheu dentre todos os homens de Israel, e formou-os em linha contra os sírios.',
        'E o restante do povo entregou na mão de Abisai seu irmão, o qual formou em linha contra os filhos de Amom.',
        'E disse: Se os sírios forem mais fortes do que eu, tu me virás em socorro; e, se os filhos de Amom forem mais fortes do que tu, irei a socorrer-te.',
        'Esforça-te, pois, e esforcemo-nos pelo nosso povo, e pelas cidades de nosso Deus; e faça o Senhor o que bem parecer aos seus olhos.',
        'Então se achegou Joabe, e o povo que estava com ele, à peleja contra os sírios; e fugiram de diante dele.',
        'E, vendo os filhos de Amom que os sírios fugiam, também eles fugiram de diante de Abisai, e entraram na cidade; e voltou Joabe dos filhos de Amom, e veio para Jerusalém.',
        'Vendo, pois, os sírios que foram feridos diante de Israel, tornaram a refazer-se.',
        'E mandou Hadadezer, e fez sair os sírios que estavam do outro lado do rio, e vieram a Helã; e Sobaque, capitão do exército de Hadadezer, marchava diante deles.',
        'Do que informado Davi, ajuntou a todo o Israel, e passou o Jordão, e foi a Helã; e os sírios se puseram em ordem contra Davi, e pelejaram com ele.',
        'Porém os sírios fugiram de diante de Israel, e Davi feriu dentre os sírios aos homens de setecentos carros, e quarenta mil homens de cavalaria; feriu também a Sobaque, capitão do exército, que morreu ali.',
        'Vendo, pois, todos os reis, servos de Hadadezer, que foram feridos diante de Israel, fizeram paz com Israel, e o serviram; e temeram os sírios de socorrer aos filhos de Amom.',
      ],
      [
        'E aconteceu que, tendo decorrido um ano, no tempo em que os reis saem à guerra, enviou Davi a Joabe, e com ele os seus servos, e a todo o Israel; e eles destruíram os filhos de Amom, e cercaram a Rabá; porém Davi ficou em Jerusalém.',
        'E aconteceu que numa tarde Davi se levantou do seu leito, e andava passeando no terraço da casa real, e viu do terraço a uma mulher que se estava lavando; e era esta mulher mui formosa à vista.',
        'E mandou Davi indagar quem era aquela mulher; e disseram: Porventura não é esta Bate-Seba, filha de Eliã, mulher de Urias, o heteu?',
        'Então enviou Davi mensageiros, e mandou trazê-la; e ela veio, e ele se deitou com ela (pois já estava purificada da sua imundícia); então voltou ela para sua casa.',
        'E a mulher concebeu; e mandou dizer a Davi: Estou grávida.',
        'Então Davi mandou dizer a Joabe: Envia-me Urias, o heteu. E Joabe enviou Urias a Davi.',
        'Vindo, pois, Urias a ele, perguntou Davi como passava Joabe, e como estava o povo, e como ia a guerra.',
        'Depois disse Davi a Urias: Desce à tua casa, e lava os teus pés. E, saindo Urias da casa real, logo lhe foi mandado um presente da mesa do rei.',
        'Porém Urias se deitou à porta da casa real, com todos os servos do seu senhor; e não desceu à sua casa.',
        'E fizeram saber isto a Davi, dizendo: Urias não desceu a sua casa. Então disse Davi à Urias: Não vens tu duma jornada? Por que não desceste à tua casa?',
        'E disse Urias a Davi: A arca, e Israel, e Judá ficaram em tendas; e Joabe, meu senhor, e os servos de meu senhor estão acampados no campo; e hei de eu entrar na minha casa, para comer e beber, e para me deitar com minha mulher? Pela tua vida, e pela vida da tua alma, não farei tal coisa.',
        'Então disse Davi a Urias: Demora-te aqui ainda hoje, e amanhã te despedirei. Urias, pois, ficou em Jerusalém aquele dia e o seguinte.',
        'E Davi o convidou, e comeu e bebeu diante dele, e o embebedou; e à tarde saiu a deitar-se na sua cama com os servos de seu senhor; porém não desceu à sua casa.',
        'E sucedeu que pela manhã Davi escreveu uma carta a Joabe; e mandou-lha por mão de Urias.',
        'Escreveu na carta, dizendo: Ponde a Urias na frente da maior força da peleja; e retirai-vos de detrás dele, para que seja ferido e morra.',
        'Aconteceu, pois, que, tendo Joabe observado bem a cidade, pôs a Urias no lugar onde sabia que havia homens valentes.',
        'E, saindo os homens da cidade, e pelejando com Joabe, caíram alguns do povo, dos servos de Davi; e morreu também Urias, o heteu.',
        'Então enviou Joabe, e fez saber a Davi todo o sucesso daquela peleja.',
        'E deu ordem ao mensageiro, dizendo: Acabando tu de contar ao rei todo o sucesso desta peleja,',
        'E sucedendo que o rei se encolerize, e te diga: Por que vos chegastes tão perto da cidade a pelejar? Não sabíeis vós que haviam de atirar do muro?',
        'Quem feriu a Abimeleque, filho de Jerubesete? Não lançou uma mulher sobre ele do muro um pedaço de uma mó corredora, de que morreu em Tebes? Por que vos chegastes ao muro? Então dirás: Também morreu teu servo Urias, o heteu.',
        'E foi o mensageiro, e entrou, e fez saber a Davi tudo o que Joabe o enviara a dizer.',
        'E disse o mensageiro a Davi: Na verdade que mais poderosos foram aqueles homens do que nós, e saíram a nós ao campo; porém nós fomos contra eles, até à entrada da porta.',
        'Então os flecheiros atiraram contra os teus servos desde o alto do muro, e morreram alguns dos servos do rei; e também morreu o teu servo Urias, o heteu.',
        'E disse Davi ao mensageiro: Assim dirás a Joabe: Não te pareça isto mal aos teus olhos; pois a espada tanto consome este como aquele; esforça a tua peleja contra a cidade, e a derrota; esforça-o tu assim.',
        'Ouvindo, pois, a mulher de Urias que seu marido era morto, lamentou a seu senhor.',
        'E, passado o luto, enviou Davi, e a recolheu em sua casa, e lhe foi por mulher, e deu-lhe à luz um filho. Porém esta coisa que Davi fez pareceu mal aos olhos do Senhor.',
      ],
      [
        'E o SENHOR enviou Natã a Davi; e, apresentando-se ele a Davi, disse-lhe: Havia numa cidade dois homens, um rico e outro pobre.',
        'O rico possuía muitíssimas ovelhas e vacas.',
        'Mas o pobre não tinha coisa nenhuma, senão uma pequena cordeira que comprara e criara; e ela tinha crescido com ele e com seus filhos; do seu bocado comia, e do seu copo bebia, e dormia em seu regaço, e a tinha como filha.',
        'E, vindo um viajante ao homem rico, deixou este de tomar das suas ovelhas e das suas vacas para assar para o viajante que viera a ele; e tomou a cordeira do homem pobre, e a preparou para o homem que viera a ele.',
        'Então o furor de Davi se acendeu em grande maneira contra aquele homem, e disse a Natã: Vive o Senhor, que digno de morte é o homem que fez isso.',
        'E pela cordeira tornará a dar o quadruplicado, porque fez tal coisa, e porque não se compadeceu.',
        'Então disse Natã a Davi: Tu és este homem. Assim diz o Senhor Deus de Israel: Eu te ungi rei sobre Israel, e eu te livrei das mãos de Saul;',
        'E te dei a casa de teu senhor, e as mulheres de teu senhor em teu seio, e também te dei a casa de Israel e de Judá, e, se isto é pouco, mais te acrescentaria tais e tais coisas.',
        'Por que, pois, desprezaste a palavra do Senhor, fazendo o mal diante de seus olhos? A Urias, o heteu, feriste à espada, e a sua mulher tomaste por tua mulher; e a ele mataste com a espada dos filhos de Amom.',
        'Agora, pois, não se apartará a espada jamais da tua casa, porquanto me desprezaste, e tomaste a mulher de Urias, o heteu, para ser tua mulher.',
        'Assim diz o Senhor: Eis que suscitarei da tua própria casa o mal sobre ti, e tomarei tuas mulheres perante os teus olhos, e as darei a teu próximo, o qual se deitará com tuas mulheres perante este sol.',
        'Porque tu o fizeste em oculto, mas eu farei este negócio perante todo o Israel e perante o sol.',
        'Então disse Davi a Natã: Pequei contra o Senhor. E disse Natã a Davi: Também o Senhor perdoou o teu pecado; não morrerás.',
        'Todavia, porquanto com este feito deste lugar sobremaneira a que os inimigos do Senhor blasfemem, também o filho que te nasceu certamente morrerá.',
        'Então Natã foi para sua casa; e o Senhor feriu a criança que a mulher de Urias dera a Davi, e adoeceu gravemente.',
        'E buscou Davi a Deus pela criança; e jejuou Davi, e entrou, e passou a noite prostrado sobre a terra.',
        'Então os anciãos da sua casa se levantaram e foram a ele, para o levantar da terra; porém ele não quis, e não comeu pão com eles.',
        'E sucedeu que ao sétimo dia morreu a criança; e temiam os servos de Davi dizer-lhe que a criança estava morta, porque diziam: Eis que, sendo a criança ainda viva, lhe falávamos, porém não dava ouvidos à nossa voz; como, pois, lhe diremos que a criança está morta? Porque mais lhe afligiria.',
        'Viu, porém, Davi que seus servos falavam baixo, e entendeu Davi que a criança estava morta, pelo que disse Davi a seus servos: Está morta a criança? E eles disseram: Está morta.',
        'Então Davi se levantou da terra, e se lavou, e se ungiu, e mudou de roupas, e entrou na casa do Senhor, e adorou. Então foi à sua casa, e pediu pão; e lhe puseram pão, e comeu.',
        'E disseram-lhe seus servos: Que é isto que fizeste? Pela criança viva jejuaste e choraste; porém depois que morreu a criança te levantaste e comeste pão.',
        'E disse ele: Vivendo ainda a criança, jejuei e chorei, porque dizia: Quem sabe se DEUS se compadecerá de mim, e viverá a criança?',
        'Porém, agora que está morta, por que jejuaria eu? Poderei eu fazê-la voltar? Eu irei a ela, porém ela não voltará para mim.',
        'Então consolou Davi a Bate-Seba, sua mulher, e entrou a ela, e se deitou com ela, e ela deu à luz um filho, e deu-lhe o nome de Salomão; e o Senhor o amou.',
        'E enviou pela mão do profeta Natã, dando-lhe o nome de Jedidias, por amor ao Senhor.',
        'Ora pelejou Joabe contra Rabá, dos filhos de Amom, e tomou a cidade real.',
        'Então mandou Joabe mensageiros a Davi, e disse: Pelejei contra Rabá, e também tomei a cidade das águas.',
        'Ajunta, pois, agora o restante do povo, e cerca a cidade, e toma-a, para que tomando eu a cidade, não se aclame sobre ela o meu nome.',
        'Então ajuntou Davi a todo o povo, e marchou para Rabá, e pelejou contra ela, e a tomou.',
        'E tirou a coroa da cabeça do seu rei, cujo peso era de um talento de ouro, e havia nela pedras preciosas, e foi posta sobre a cabeça de Davi; e da cidade levou mui grande despojo.',
        'E, trazendo o povo que havia nela, o pôs às serras, e às talhadeiras de ferro, e aos machados de ferro, e os fez passar por forno de tijolos; e assim fez a todas as cidades dos filhos de Amom; e voltou Davi e todo o povo para Jerusalém.',
      ],
      [
        'E aconteceu depois disto que, tendo Absalão, filho de Davi, uma irmã formosa, cujo nome era Tamar, Amnom, filho de Davi, amou-a.',
        'E angustiou-se Amnom, até adoecer, por Tamar, sua irmã, porque era virgem; e parecia aos olhos de Amnom dificultoso fazer-lhe coisa alguma.',
        'Tinha, porém, Amnom um amigo, cujo nome era Jonadabe, filho de Siméia, irmão de Davi; e era Jonadabe homem mui sagaz.',
        'O qual lhe disse: Por que tu de dia em dia tanto emagreces, sendo filho do rei? Não mo farás saber a mim? Então lhe disse Amnom: Amo a Tamar, irmã de Absalão, meu irmão.',
        'E Jonadabe lhe disse: Deita-te na tua cama, e finge-te doente; e, quando teu pai te vier visitar, dize-lhe: Peço-te que minha irmã Tamar venha, e me dê de comer pão, e prepare a comida diante dos meus olhos, para que eu a veja e coma da sua mão.',
        'Deitou-se, pois, Amnom, e fingiu-se doente; e, vindo o rei visitá-lo, disse Amnom, ao rei: Peço-te que minha irmã Tamar venha, e prepare dois bolos diante dos meus olhos, para que eu coma de sua mão.',
        'Mandou então Davi à casa, a Tamar, dizendo: Vai à casa de Amnom, teu irmão, e faze-lhe alguma comida.',
        'E foi Tamar à casa de Amnom, seu irmão (ele porém estava deitado), e tomou massa, e a amassou, e fez bolos diante dos seus olhos, e cozeu os bolos.',
        'E tomou a frigideira, e os tirou diante dele; porém ele recusou comer. E disse Amnom: Fazei retirar a todos da minha presença. E todos se retiraram dele.',
        'Então disse Amnom a Tamar: Traze a comida ao quarto, e comerei da tua mão. E tomou Tamar os bolos que fizera, e levou-os a Amnom, seu irmão, no quarto.',
        'E chegando-lhos, para que comesse, pegou dela, e disse-lhe: Vem, deita-te comigo, minha irmã.',
        'Porém ela lhe disse: Não, meu irmão, não me forces, porque não se faz assim em Israel; não faças tal loucura.',
        'Porque, aonde iria eu com a minha vergonha? E tu serias como um dos loucos de Israel. Agora, pois, peço-te que fales ao rei, porque não me negará a ti.',
        'Porém ele não quis dar ouvidos à sua voz; antes, sendo mais forte do que ela, a forçou, e se deitou com ela.',
        'Depois Amnom sentiu grande aversão por ela, pois maior era o ódio que sentiu por ela do que o amor com que a amara. E disse-lhe Amnom: Levanta-te, e vai-te.',
        'Então ela lhe disse: Não há razão de me despedires assim; maior seria este mal do que o outro que já me tens feito. Porém não lhe quis dar ouvidos.',
        'E chamou a seu moço que o servia, e disse: Ponha fora a esta, e fecha a porta após ela.',
        'E trazia ela uma roupa de muitas cores (porque assim se vestiam as filhas virgens dos reis); e seu servo a pôs para fora, e fechou a porta após ela.',
        'Então Tamar tomou cinza sobre a sua cabeça, e a roupa de muitas cores que trazia rasgou; e pôs as mãos sobre a cabeça, e foi andando e clamando.',
        'E Absalão, seu irmão, lhe disse: Esteve Amnom, teu irmão, contigo? Ora, pois, minha irmã, cala-te; é teu irmão. Não se angustie o teu coração por isto. Assim ficou Tamar, e esteve solitária em casa de Absalão seu irmão.',
        'E, ouvindo o rei Davi todas estas coisas, muito se lhe acendeu a ira.',
        'Porém Absalão não falou com Amnom, nem mal nem bem; porque Absalão odiava a Amnom, por ter forçado a Tamar sua irmã.',
        'E aconteceu que, passados dois anos inteiros, Absalão tinha tosquiadores em Baal-Hazor, que está junto a Efraim; e convidou Absalão a todos os filhos do rei.',
        'E foi Absalão ao rei, e disse: Eis que teu servo tem tosquiadores; peço que o rei e os seus servos venham com o teu servo.',
        'O rei, porém, disse a Absalão: Não, filho meu, não vamos todos juntos, para não te sermos pesados. E instou com ele; porém não quis ir, mas o abençoou.',
        'Então disse Absalão: Quando não, deixa ir conosco Amnom, meu irmão. Porém o rei disse: Para que iria contigo?',
        'E, instando Absalão com ele, deixou ir com ele a Amnom, e a todos os filhos do rei.',
        'E Absalão deu ordem aos seus servos, dizendo: Tomai sentido; quando o coração de Amnom estiver alegre do vinho, e eu vos disser: Feri a Amnom, então o matareis; não temais: porque porventura não sou eu quem vo-lo ordenei? Esforçai-vos, e sede valentes.',
        'E os servos de Absalão fizeram a Amnom como Absalão lho havia ordenado. Então todos os filhos do rei se levantaram, e montaram cada um no seu mulo, e fugiram.',
        'E aconteceu que, estando eles ainda no caminho, chegou a nova a Davi, dizendo-se: Absalão feriu a todos os filhos do rei, e nenhum deles ficou.',
        'Então o rei se levantou, e rasgou as suas vestes, e se lançou por terra; da mesma maneira todos os seus servos estavam com vestes rotas.',
        'Mas Jonadabe, filho de Siméia, irmão de Davi, respondeu, e disse: Não diga o meu senhor que mataram a todos os moços filhos do rei, porque só morreu Amnom; porque assim tinha resolvido fazer Absalão, desde o dia em que forçou a Tamar sua irmã.',
        'Não se lhe ponha, pois, agora no coração do rei meu senhor tal coisa, dizendo: Morreram todos os filhos do rei; porque só morreu Amnom.',
        'E Absalão fugiu; e o moço que estava de guarda, levantou os seus olhos, e olhou; e eis que muito povo vinha pelo caminho por detrás dele, pelo lado do monte.',
        'Então disse Jonadabe ao rei: Eis aqui vêm os filhos do rei; conforme à palavra de teu servo, assim sucedeu.',
        'E aconteceu que, como acabou de falar, os filhos do rei vieram, e levantaram a sua voz, e choraram; e também o rei e todos os seus servos choraram amargamente.',
        'Assim Absalão fugiu, e foi a Talmai, filho de Amiur, rei de Gesur. E Davi pranteava por seu filho todos aqueles dias.',
        'Assim Absalão fugiu, e foi para Gesur; esteve ali três anos.',
        'Então tinha o rei Davi saudades de Absalão; porque já se tinha consolado acerca da morte de Amnom.',
      ],
      [
        'Conhecendo, pois, Joabe, filho de Zeruia, que o coração do rei estava inclinado para Absalão,',
        'Enviou Joabe a Tecoa, e tomou de lá uma mulher e disse-lhe: Ora, finge que estás de luto; veste roupas de luto, e não te unjas com óleo, e sê como uma mulher que há já muitos dias está de luto por algum morto.',
        'E vai ao rei, e fala-lhe conforme a esta palavra. E Joabe lhe pôs as palavras na boca.',
        'E a mulher tecoíta falou ao rei, e, deitando-se com o rosto em terra, se prostrou e disse: Salva-me, ó rei.',
        'E disse-lhe o rei: Que tens? E disse ela: Na verdade sou mulher viúva; morreu meu marido.',
        'Tinha, pois, a tua serva dois filhos, e estes brigaram entre si no campo, e não houve quem os apartasse; assim um feriu ao outro, e o matou.',
        'E eis que toda a linhagem se levantou contra a tua serva, e disseram: Dá-nos aquele que feriu a seu irmão, para que o matemos, por causa da vida de seu irmão, a quem matou, e para que destruamos também ao herdeiro. Assim apagarão a brasa que me ficou, de sorte que não deixam a meu marido nome, nem remanescente sobre a terra.',
        'E disse o rei à mulher: Vai para tua casa; e eu mandarei ordem acerca de ti.',
        'E disse a mulher tecoíta ao rei: A injustiça, rei meu senhor, venha sobre mim e sobre a casa de meu pai; e o rei e o seu trono fique inculpável.',
        'E disse o rei: Quem falar contra ti, traze-mo a mim; e nunca mais te tocará.',
        'E disse ela: Ora, lembre-se o rei do Senhor seu Deus, para que os vingadores do sangue não prossigam na destruição, e não exterminem a meu filho. Então disse ele: Vive o Senhor, que não há de cair no chão nem um dos cabelos de teu filho.',
        'Então disse a mulher: Peço-te que a tua serva fale uma palavra ao rei meu senhor. E disse ele: Fala.',
        'E disse a mulher: Por que, pois, pensaste tu uma tal coisa contra o povo de Deus? Porque, falando o rei tal palavra, fica como culpado; visto que o rei não torna a trazer o seu desterrado.',
        'Porque certamente morreremos, e seremos como águas derramadas na terra que não se ajuntam mais; Deus, pois, lhe não tirará a vida, mas cogita meios, para que não fique banido dele o seu desterrado.',
        'E se eu agora vim falar esta palavra ao rei, meu senhor, é porque o povo me atemorizou; dizia, pois, a tua serva: Falarei, pois, ao rei; porventura fará o rei segundo a palavra da sua serva.',
        'Porque o rei ouvirá, para livrar a sua serva da mão do homem que intenta destruir juntamente a mim e a meu filho da herança de Deus.',
        'Dizia mais a tua serva: Seja agora a palavra do rei meu senhor para descanso; porque como um anjo de Deus, assim é o rei, meu senhor, para ouvir o bem e o mal; e o SENHOR teu Deus será contigo.',
        'Então respondeu o rei, e disse à mulher: Peço-te que não me encubras o que eu te perguntar. E disse a mulher: Ora fale o rei, meu senhor.',
        'E disse o rei: Não é verdade que a mão de Joabe anda contigo em tudo isto? E respondeu a mulher, e disse: Vive a tua alma, ó rei meu senhor, que ninguém se poderá desviar, nem para a direita nem para a esquerda, de tudo quanto o rei, meu senhor, tem falado: Porque Joabe, teu servo, é quem me deu ordem, e foi ele que pôs na boca da tua serva todas estas palavras:',
        'Para mudar o aspecto deste caso foi que o teu servo Joabe fez isto; porém sábio é meu senhor, conforme à sabedoria de um anjo de Deus, para entender tudo o que há na terra.',
        'Então o rei disse a Joabe: Eis que fiz isto; vai, pois, e torna a trazer o jovem Absalão.',
        'Então Joabe se prostrou sobre o seu rosto em terra, e se inclinou, e agradeceu ao rei; e disse Joabe: Hoje conhece o teu servo que achei graça aos teus olhos, ó rei meu senhor, porque o rei fez segundo a palavra do teu servo.',
        'Levantou-se, pois, Joabe, e foi a Gesur, e trouxe Absalão a Jerusalém.',
        'E disse o rei: Torne para a sua casa, e não veja a minha face. Tornou, pois, Absalão para sua casa, e não viu a face do rei.',
        'Não havia, porém, em todo o Israel homem tão belo e tão aprazível como Absalão; desde a planta do pé até à cabeça não havia nele defeito algum.',
        'E, quando tosquiava a sua cabeça (e sucedia que no fim de cada ano a tosquiava, porquanto muito lhe pesava, e por isso a tosquiava), pesava o cabelo da sua cabeça duzentos siclos, segundo o peso real.',
        'Também nasceram a Absalão três filhos e uma filha, cujo nome era Tamar; e esta era mulher formosa à vista.',
        'Assim ficou Absalão dois anos inteiros em Jerusalém, e não viu a face do rei.',
        'Mandou, pois, Absalão chamar a Joabe, para o enviar ao rei; porém não quis vir a ele; e enviou ainda segunda vez e, contudo, não quis vir.',
        'Então disse aos seus servos: Vedes ali o pedaço de campo de Joabe pegado ao meu, e tem cevada nele; ide, e ponde-lhe fogo. E os servos de Absalão puseram fogo ao pedaço de campo.',
        'Então Joabe se levantou, e veio a Absalão, em casa, e disse-lhe: Por que puseram os teus servos fogo ao pedaço de campo que é meu?',
        'E disse Absalão a Joabe: Eis que enviei a ti, dizendo: Vem cá, para que te envie ao rei, a dizer-lhe: Para que vim de Gesur? Melhor me fora estar ainda lá. Agora, pois, veja eu a face do rei; e, se há ainda em mim alguma culpa, que me mate.',
        'Então foi Joabe ao rei, e assim lho disse. Então chamou a Absalão, e ele se apresentou ao rei, e se inclinou sobre o seu rosto em terra diante do rei; e o rei beijou a Absalão.',
      ],
      [
        'E aconteceu depois disto que Absalão fez aparelhar carros e cavalos, e cinqüenta homens que corressem adiante dele.',
        'Também Absalão se levantou pela manhã, e parava a um lado do caminho da porta. E sucedia que a todo o homem que tinha alguma demanda para vir ao rei a juízo, o chamava Absalão a si, e lhe dizia: De que cidade és tu? E, dizendo ele: De uma das tribos de Israel é teu servo;',
        'Então Absalão lhe dizia: Olha, os teus negócios são bons e retos, porém não tens quem te ouça da parte do rei.',
        'Dizia mais Absalão: Ah, quem me dera ser juiz na terra, para que viesse a mim todo o homem que tivesse demanda ou questão, para que lhe fizesse justiça!',
        'Sucedia também que, quando alguém se chegava a ele para se inclinar diante dele, ele estendia a sua mão, e pegava dele, e o beijava.',
        'E desta maneira fazia Absalão a todo o Israel que vinha ao rei para juízo; assim furtava Absalão o coração dos homens de Israel.',
        'Aconteceu, pois, ao cabo de quarenta anos, que Absalão disse ao rei: Deixa-me ir pagar em Hebrom o meu voto que fiz ao Senhor.',
        'Porque, morando eu em Gesur, na Síria, fez o teu servo um voto, dizendo: Se o Senhor outra vez me fizer tornar a Jerusalém, servirei ao Senhor.',
        'Então lhe disse o rei: Vai em paz. Levantou-se, pois, e foi para Hebrom.',
        'E enviou Absalão espias por todas as tribos de Israel, dizendo: Quando ouvirdes o som das trombetas, direis: Absalão reina em Hebrom.',
        'E de Jerusalém foram com Absalão duzentos homens convidados, porém iam na sua simplicidade, porque nada sabiam daquele negócio.',
        'Também Absalão mandou vir Aitofel, o gilonita, do conselho de Davi, à sua cidade de Giló, estando ele oferecendo os seus sacrifícios; e a conjuração se fortificava, e vinha o povo, e ia crescendo com Absalão.',
        'Então veio um mensageiro a Davi, dizendo: O coração de cada um em Israel segue a Absalão.',
        'Disse, pois, Davi a todos os seus servos que estavam com ele em Jerusalém: Levantai-vos, e fujamos, porque não poderíamos escapar diante de Absalão. Dai-vos pressa a caminhar, para que porventura não se apresse ele, e nos alcance, e lance sobre nós algum mal, e fira a cidade a fio de espada.',
        'Então os servos do rei disseram ao rei: Eis aqui os teus servos, para tudo quanto determinar o rei, nosso senhor.',
        'E saiu o rei, com toda a sua casa, a pé; deixou, porém, o rei dez mulheres concubinas, para guardarem a casa.',
        'Tendo, pois, saído o rei com todo o povo a pé, pararam num lugar distante.',
        'E todos os seus servos iam a seu lado, como também todos os quereteus e todos os peleteus; e todos os giteus, seiscentos homens que vieram de Gate a pé, caminhavam diante do rei.',
        'Disse, pois, o rei a Itai, o giteu: Por que irias tu também conosco? Volta-te, e fica-te com o rei, porque és estrangeiro, e também desterrado de teu lugar.',
        'Ontem vieste, e te levaria eu hoje conosco a caminhar? Pois eu vou para onde puder ir; volta, pois, e torna a levar teus irmãos contigo, com beneficência e fidelidade.',
        'Respondeu, porém, Itai ao rei, e disse: Vive o SENHOR, e vive o rei meu senhor, que no lugar em que estiver o rei meu senhor, seja para morte seja para vida, aí certamente estará também o teu servidor.',
        'Então Davi disse a Itai: Vem, pois, e passa adiante. Assim passou Itai, o giteu, e todos os seus homens, e todas as crianças que havia com ele.',
        'E toda a terra chorava a grandes vozes, passando todo o povo; também o rei passou o ribeiro de Cedrom, e passou todo o povo na direção do caminho do deserto.',
        'Eis que também Zadoque ali estava, e com ele todos os levitas que levavam a arca da aliança de Deus; e puseram ali a arca de Deus, e subiu Abiatar, até que todo o povo acabou de passar da cidade.',
        'Então disse o rei a Zadoque: Torna a levar a arca de Deus à cidade; que, se achar graça nos olhos do Senhor, ele me tornará a trazer para lá e me deixará ver a ela e a sua habitação.',
        'Se, porém, disser assim: Não tenho prazer em ti; eis-me aqui, faça de mim como parecer bem aos seus olhos.',
        'Disse mais o rei a Zadoque, o sacerdote: Não és tu porventura vidente? Torna, pois, em paz para a cidade, e convosco também vossos dois filhos, Aimaás, teu filho, e Jônatas, filho de Abiatar.',
        'Olhai que me demorarei nas campinas do deserto até que tenha notícias vossas.',
        'Zadoque, pois, e Abiatar, tornaram a levar para Jerusalém a arca de Deus; e ficaram ali.',
        'E seguiu Davi pela encosta do monte das Oliveiras, subindo e chorando, e com a cabeça coberta; e caminhava com os pés descalços; e todo o povo que ia com ele cobria cada um a sua cabeça, e subiam chorando sem cessar.',
        'Então fizeram saber a Davi, dizendo: Também Aitofel está entre os que se conjuraram com Absalão. Pelo que disse Davi: Ó Senhor, peço-te que torne em loucura o conselho de Aitofel.',
        'E aconteceu que, chegando Davi ao cume, para adorar ali a Deus, eis que Husai, o arquita, veio encontrar-se com ele com a roupa rasgada e terra sobre a cabeça.',
        'E disse-lhe Davi: Se passares comigo, ser-me-ás pesado.',
        'Porém se voltares para a cidade, e disseres a Absalão: Eu serei, ó rei, teu servo; bem fui antes servo de teu pai, mas agora serei teu servo; dissipar-me-ás então o conselho de Aitofel.',
        'E não estão ali contigo Zadoque e Abiatar, sacerdotes? E será que todas as coisas que ouvires da casa do rei, farás saber a Zadoque, e a Abiatar, sacerdotes.',
        'Eis que estão também ali com eles seus dois filhos, Aimaás filho de Zadoque, e Jônatas filho de Abiatar; pela mão deles aviso me mandareis, de todas as coisas que ouvirdes.',
        'Husai, pois, amigo de Davi, veio para a cidade; e Absalão entrou em Jerusalém.',
      ],
      [
        'E passando Davi um pouco mais adiante do cume, eis que Ziba, o servo de Mefibosete, veio encontrar-se com ele, com um par de jumentos albardados, e sobre eles duzentos pães, com cem cachos de passas, e cem de frutas de verão e um odre de vinho.',
        'E disse o rei a Ziba: Que pretendes com isto? E disse Ziba: Os jumentos são para a casa do rei, para se montarem neles; e o pão e as frutas de verão para comerem os moços; e o vinho para beberem os cansados no deserto.',
        'Então disse o rei: Ora, onde está o filho de teu senhor? E disse Ziba ao rei: Eis que ficou em Jerusalém; porque disse: Hoje me restituirá a casa de Israel o reino de meu pai.',
        'Então disse o rei a Ziba: Eis que teu é tudo quanto tem Mefibosete. E disse Ziba: Eu me inclino, que eu ache graça em teus olhos, ó rei meu senhor.',
        'E, chegando o rei Davi a Baurim, eis que dali saiu um homem da linhagem da casa de Saul, cujo nome era Simei, filho de Gera, e, saindo, ia amaldiçoando.',
        'E atirava pedras contra Davi, e contra todos os servos do rei Davi; ainda que todo o povo e todos os valentes iam à sua direita e à sua esquerda.',
        'E, amaldiçoando-o Simei, assim dizia: Sai, sai, homem de sangue, e homem de Belial.',
        'O Senhor te deu agora a paga de todo o sangue da casa de Saul, em cujo lugar tens reinado; já deu o Senhor o reino na mão de Absalão teu filho; e eis-te agora na tua desgraça, porque és um homem de sangue.',
        'Então disse Abisai, filho de Zeruia, ao rei: Por que amaldiçoaria este cão morto ao rei meu senhor? Deixa-me passar, e lhe tirarei a cabeça.',
        'Disse, porém, o rei: Que tenho eu convosco, filhos de Zeruia? Ora deixai-o amaldiçoar; pois o Senhor lhe disse: Amaldiçoa a Davi; quem pois diria: Por que assim fizeste?',
        'Disse mais Davi a Abisai, e a todos os seus servos: Eis que meu filho, que saiu das minhas entranhas, procura a minha morte; quanto mais ainda este benjamita? Deixai-o, que amaldiçoe; porque o Senhor lho disse.',
        'Porventura o Senhor olhará para a minha miséria; e o Senhor me pagará com bem a sua maldição deste dia.',
        'Prosseguiram, pois, o seu caminho, Davi e os seus homens; e também Simei ia ao longo do monte, defronte dele, caminhando e amaldiçoando, e atirava pedras contra ele, e levantava poeira.',
        'E o rei e todo o povo que ia com ele chegaram cansados, e refrescaram-se ali.',
        'Absalão, pois, e todo o povo, os homens de Israel, vieram a Jerusalém; e Aitofel com ele.',
        'E sucedeu que, chegando Husai, o arquita, amigo de Davi, a Absalão, disse Husai a Absalão: Viva o rei, viva o rei!',
        'Porém Absalão disse a Husai: É esta a tua beneficência para com o teu amigo? Por que não foste com o teu amigo?',
        'E disse Husai a Absalão: Não, porém daquele que eleger o Senhor, e todo este povo, e todos os homens de Israel, dele serei e com ele ficarei.',
        'E, demais disto, a quem serviria eu? Porventura não seria diante de seu filho? Como servi diante de teu pai, assim serei diante de ti.',
        'Então disse Absalão a Aitofel: Dai conselho entre vós sobre o que devemos fazer.',
        'E disse Aitofel a Absalão: Possue as concubinas de teu pai, que deixou para guardarem a casa; e assim todo o Israel ouvirá que te fizeste aborrecível para com teu pai; e se fortalecerão as mãos de todos os que estão contigo.',
        'Estenderam, pois, para Absalão uma tenda no terraço; e Absalão possuiu as concubinas de seu pai, perante os olhos de todo o Israel.',
        'E era o conselho de Aitofel, que aconselhava naqueles dias, como se a palavra de Deus se consultara; tal era todo o conselho de Aitofel, assim para com Davi como para com Absalão.',
      ],
      [
        'Disse mais Aitofel a Absalão: Deixa-me escolher doze mil homens, e me levantarei, e perseguirei a Davi esta noite.',
        'E irei sobre ele, pois está cansado e frouxo de mãos; e o espantarei, e fugirá todo o povo que está com ele; e então ferirei somente o rei.',
        'E farei tornar a ti todo o povo; pois o homem a quem tu buscas é como se tornassem todos; assim todo o povo estará em paz.',
        'E esta palavra pareceu boa aos olhos de Absalão, e aos olhos de todos os anciãos de Israel.',
        'Disse, porém, Absalão: Chamai agora também a Husai o arquita; e ouçamos também o que ele dirá.',
        'E, chegando Husai a Absalão, lhe falou Absalão, dizendo: Desta maneira falou Aitofel; faremos conforme à sua palavra? Se não, fala tu.',
        'Então disse Husai a Absalão: O conselho que Aitofel deu desta vez não é bom.',
        'Disse mais Husai: Bem conheces tu a teu pai, e a seus homens, que são valorosos, e que estão com o espírito amargurado, como a ursa no campo, roubada dos cachorros; e também teu pai é homem de guerra, e não passará a noite com o povo.',
        'Eis que agora estará escondido nalguma cova, ou em qualquer outro lugar; e será que, caindo no princípio alguns dentre eles, cada um que o ouvir então dirá: Houve derrota no povo que segue a Absalão.',
        'Então até o homem valente, cujo coração é como coração de leão, sem dúvida desmaiará; porque todo o Israel sabe que teu pai é valoroso, e homens valentes os que estão com ele.',
        'Eu, porém, aconselho que com toda a pressa se ajunte a ti todo o Israel desde Dã até Berseba, em multidão como a areia do mar; e tu em pessoa vás com eles à peleja.',
        'Então iremos a ele, em qualquer lugar que se achar, facilmente cairemos sobre ele, como o orvalho cai sobre a terra; e não ficará dele e de todos os homens que estão com ele nem ainda um só.',
        'E, se ele se retirar para alguma cidade, todo o Israel levará cordas àquela cidade; e arrastá-la-emos até ao ribeiro, até que não se ache ali nem uma só pedrinha.',
        'Então disse Absalão e todos os homens de Israel: Melhor é o conselho de Husai, o arquita, do que o conselho de Aitofel (porém assim o Senhor o ordenara, para aniquilar o bom conselho de Aitofel, para que o Senhor trouxesse o mal sobre Absalão).',
        'E disse Husai a Zadoque e a Abiatar, sacerdotes: assim e assim aconselhou Aitofel a Absalão e aos anciãos de Israel; porém assim e assim aconselhei eu.',
        'Agora, pois, enviai apressadamente, e avisai a Davi, dizendo: Não passes esta noite nas campinas do deserto; logo também passa ao outro lado, para que o rei e todo o povo que com ele está não seja devorado.',
        'Estavam, pois, Jônatas e Aimaás junto à fonte de Rogel; e foi uma criada, e lho disse, e eles foram e o disseram ao rei Davi, porque não podiam ser vistos entrar na cidade.',
        'Mas viu-os todavia um moço, e avisou a Absalão; porém ambos logo partiram apressadamente, e entraram em casa de um homem, em Baurim, o qual tinha um poço no seu pátio, e ali dentro desceram.',
        'E tomou a mulher a tampa, e a estendeu sobre a boca do poço, e espalhou grão descascado sobre ela; assim nada se soube.',
        'Chegando, pois, os servos de Absalão à mulher, àquela casa, disseram: Onde estão Aimaás e Jônatas? E a mulher lhes disse: Já passaram o vau das águas. E havendo-os buscado, e não os achando, voltaram para Jerusalém.',
        'E sucedeu que, depois que se retiraram, Aimaás e Jônatas saíram do poço, e foram, e anunciaram a Davi; e disseram a Davi: Levantai-vos, e passai depressa as águas, porque assim aconselhou contra vós Aitofel.',
        'Então Davi e todo o povo que com ele estava se levantou, e passaram o Jordão; e já pela luz da manhã nem ainda faltava um só que não tivesse passado o Jordão.',
        'Vendo, pois, Aitofel que se não tinha seguido o seu conselho, albardou o jumento, e levantou-se, e foi para sua casa e para a sua cidade, e deu ordem à sua casa, e se enforcou e morreu, e foi sepultado na sepultura de seu pai.',
        'E Davi foi a Maanaim; e Absalão passou o Jordão, ele e todo o homem de Israel com ele.',
        'E Absalão constituiu a Amasa em lugar de Joabe sobre o arraial; e era Amasa filho de um homem cujo nome era Itra, o israelita, o qual possuíra a Abigail, filha de Naás, irmã de Zeruia, mãe de Joabe.',
        'Israel, pois, e Absalão acamparam na terra de Gileade.',
        'E sucedeu que, chegando Davi a Maanaim, Sobi, filho de Naás, de Rabá, dos filhos de Amom, e Maquir, filho de Amiel, de Lo-Debar, e Barzilai, o gileadita, de Rogelim,',
        'Tomaram camas e bacias, e vasilhas de barro, e trigo, e cevada, e farinha, e grão torrado, e favas, e lentilhas, também torradas,',
        'E mel, e manteiga, e ovelhas, e queijos de vacas, e os trouxeram a Davi e ao povo que com ele estava, para comerem, porque disseram: Este povo no deserto está faminto, cansado e sedento.',
      ],
      [
        'E Davi contou o povo que tinha consigo, e pôs sobre eles capitães de mil e capitães de cem.',
        'E Davi enviou o povo, um terço sob o mando de Joabe, e outro terço sob o mando de Abisai, filho de Zeruia, irmão de Joabe, e outro terço sob o mando de Itai, o giteu; e disse o rei ao povo: Eu também sairei convosco.',
        'Porém o povo disse: Não sairás, porque, se formos obrigados a fugir, não se importarão conosco; e, ainda que metade de nós morra, não farão caso de nós, porque ainda, tais como nós somos, ajuntarás dez mil; melhor será, pois, que da cidade nos sirvas de socorro.',
        'Então disse-lhe Davi: O que bem parecer aos vossos olhos, farei. E o rei se pôs do lado da porta, e todo o povo saiu em centenas e em milhares.',
        'E o rei deu ordem a Joabe, e a Abisai, e a Itai, dizendo: Brandamente tratai, por amor de mim, ao jovem Absalão. E todo o povo ouviu quando o rei deu ordem a todos os capitães acerca de Absalão.',
        'Saiu, pois, o povo ao campo, a encontrar-se com Israel, e deu-se a batalha no bosque de Efraim.',
        'E ali foi ferido o povo de Israel, diante dos servos de Davi; e naquele mesmo dia houve ali uma grande derrota de vinte mil.',
        'Porque ali se derramou a batalha sobre a face de toda aquela terra; e foram mais os do povo que o bosque consumiu do que os que a espada consumiu naquele dia.',
        'E Absalão se encontrou com os servos de Davi; e Absalão ia montado num mulo; e, entrando o mulo debaixo dos espessos ramos de um grande carvalho, pegou-se-lhe a cabeça no carvalho, e ficou pendurado entre o céu e a terra; e o mulo, que estava debaixo dele, passou adiante.',
        'O que vendo um homem, fez saber a Joabe, e disse: Eis que vi a Absalão pendurado num carvalho.',
        'Então disse Joabe ao homem que lho fizera saber: Pois que o viste, por que o não feriste logo ali em terra? E forçoso seria o eu dar-te dez moedas de prata e um cinto.',
        'Disse, porém, aquele homem a Joabe: Ainda que eu pudesse pesar nas minhas mãos mil moedas de prata, não estenderia a minha mão contra o filho do rei, pois bem ouvimos que o rei te deu ordem a ti, e a Abisai, e a Itai, dizendo: Guardai-vos, cada um de vós, de tocar no jovem Absalão.',
        'Ainda que cometesse mentira a risco da minha vida, nem por isso coisa nenhuma se esconderia ao rei; e tu mesmo te oporias.',
        'Então disse Joabe: Não me demorarei assim contigo aqui. E tomou três dardos, e traspassou com eles o coração de Absalão, estando ele ainda vivo no meio do carvalho.',
        'E o cercavam dez moços, que levaram as armas de Joabe. E feriram a Absalão, e o mataram.',
        'Então tocou Joabe a buzina, e voltou o povo de perseguir a Israel, porque Joabe deteve o povo.',
        'E tomaram a Absalão, e o lançaram no bosque, numa grande cova, e levantaram sobre ele um mui grande montão de pedras; e todo o Israel fugiu, cada um para a sua tenda.',
        'Ora, Absalão, quando ainda vivia, tinha tomado e levantado para si uma coluna, que está no vale do rei, porque dizia: Filho nenhum tenho para conservar a memória do meu nome. E chamou aquela coluna pelo seu próprio nome; por isso até ao dia de hoje se chama o Pilar de Absalão.',
        'Então disse Aimaás, filho de Zadoque: Deixa-me correr, e denunciarei ao rei que já o Senhor o vingou da mão de seus inimigos.',
        'Mas Joabe lhe disse: Tu não serás hoje o portador de novas, porém outro dia as levarás; mas hoje não darás a nova, porque é morto o filho do rei.',
        'E disse Joabe a Cusi: Vai tu, e dize ao rei o que viste. E Cusi se inclinou a Joabe, e correu.',
        'E prosseguiu Aimaás, filho de Zadoque, e disse a Joabe: Seja o que for deixa-me também correr após Cusi. E disse Joabe: Para que agora correrias tu, meu filho, pois não tens mensagem conveniente?',
        'Seja o que for, disse Aimaás, correrei. E Joabe lhe disse: Corre. E Aimaás correu pelo caminho da planície, e passou a Cusi.',
        'E Davi estava assentado entre as duas portas; e a sentinela subiu ao terraço da porta junto ao muro; e levantou os olhos, e olhou, e eis que um homem corria só.',
        'Gritou, pois, a sentinela, e o disse ao rei: Se vem só, há novas em sua boca. E vinha andando e chegando.',
        'Então viu a sentinela outro homem que corria, e a sentinela gritou ao porteiro, e disse: Eis que lá vem outro homem correndo só. Então disse o rei: Também traz este novas.',
        'Disse mais a sentinela: Vejo o correr do primeiro, que parece ser o correr de Aimaás, filho de Zadoque. Então disse o rei: Este é homem de bem, e virá com boas novas.',
        'Gritou, pois, Aimaás, e disse ao rei: Paz. E inclinou-se ao rei com o rosto em terra, e disse: Bendito seja o SENHOR, que entregou os homens que levantaram a mão contra o rei meu senhor.',
        'Então disse o rei: Vai bem com o jovem, com Absalão? E disse Aimaás: Vi um grande alvoroço, quando Joabe mandou o servo do rei, e a mim teu servo; porém não sei o que era.',
        'E disse o rei: Vira-te, e põe-te aqui. E virou-se, e parou.',
        'E eis que vinha Cusi; e disse Cusi: Anunciar-se-á ao rei meu senhor que hoje o SENHOR te vingou da mão de todos os que se levantaram contra ti.',
        'Então disse o rei a Cusi: Vai bem com o jovem, com Absalão? E disse Cusi: Sejam como aquele jovem os inimigos do rei meu senhor, e todos os que se levantam contra ti para mal.',
        'Então o rei se perturbou, e subiu à sala que estava por cima da porta, e chorou; e andando, dizia assim: Meu filho Absalão, meu filho, meu filho, Absalão! Quem me dera que eu morrera por ti, Absalão, meu filho, meu filho!',
      ],
      [
        'E disseram a Joabe: Eis que o rei anda chorando, e lastima-se por Absalão.',
        'Então a vitória se tornou naquele mesmo dia em tristeza por todo o povo; porque naquele mesmo dia o povo ouvira dizer: Mui triste está o rei por causa de seu filho.',
        'E naquele mesmo dia o povo entrou às furtadelas na cidade, como o faz quando, envergonhado, foge da peleja.',
        'Estava, pois, o rei com o rosto coberto; e o rei gritava a alta voz: Meu filho Absalão, Absalão meu filho, meu filho!',
        'Então entrou Joabe na casa do rei, e disse: Hoje envergonhaste o rosto de todos os teus servos, que livraram hoje a tua vida, e a vida de teus filhos, e de tuas filhas, e a vida de tuas mulheres, e a vida de tuas concubinas;',
        'Amando tu aos teus inimigos, e odiando aos teus amigos. Porque hoje dás a entender que nada valem para contigo príncipes e servos; porque entendo hoje que se Absalão vivesse, e todos nós hoje fôssemos mortos, estarias bem contente.',
        'Levanta-te, pois, agora; sai, e fala conforme ao coração de teus servos; porque pelo Senhor te juro que, se não saíres, nem um só homem ficará contigo esta noite; e maior mal te será isto do que todo o mal que tem vindo sobre ti desde a tua mocidade até agora.',
        'Então o rei se levantou, e se assentou à porta; e fizeram saber a todo o povo dizendo: Eis que o rei está assentado à porta. Então todo o povo veio apresentar-se diante do rei; porém Israel havia fugido cada um para a sua tenda.',
        'E todo o povo, em todas as tribos de Israel, andava porfiando entre si, dizendo: O rei nos tirou das mãos de nossos inimigos, e ele nos livrou das mãos dos filisteus; e agora fugiu da terra por causa de Absalão.',
        'E Absalão, a quem ungimos sobre nós, já morreu na peleja; agora, pois, por que vos calais, e não fazeis voltar o rei?',
        'Então o rei Davi mandou dizer a Zadoque e a Abiatar, sacerdotes: Falai aos anciãos de Judá, dizendo: Por que seríeis vós os últimos em tornar a trazer o rei para a sua casa? Porque as palavras de todo o Israel chegaram ao rei, até à sua casa.',
        'Vós sois meus irmãos, meus ossos e minha carne sois vós; por que, pois, seríeis os últimos em tornar a trazer o rei?',
        'E a Amasa direis: Porventura não és tu meu osso e minha carne? Assim me faça Deus, e outro tanto, se não fores capitão do arraial diante de mim para sempre, em lugar de Joabe.',
        'Assim moveu ele o coração de todos os homens de Judá, como o de um só homem; e enviaram ao rei, dizendo: Volta tu com todos os teus servos.',
        'Então o rei voltou, e chegou até ao Jordão; e Judá veio a Gilgal, para ir encontrar-se com o rei, ao outro lado do Jordão.',
        'E apressou-se Simei, filho de Gera, benjamita, que era de Baurim; e desceu com os homens de Judá a encontrar-se com o rei Davi.',
        'E com ele mil homens de Benjamim, como também Ziba, servo da casa de Saul, e seus quinze filhos, e seus vinte servos com ele; e prontamente passaram o Jordão adiante do rei.',
        'E, atravessando a barca, para fazer passar a casa do rei e para fazer o que bem parecesse aos seus olhos, então Simei, filho de Gera, se prostrou diante do rei, quando ele passava o Jordão.',
        'E disse ao rei: Não me impute meu senhor a minha culpa, e não te lembres do que tão perversamente fez teu servo, no dia em que o rei meu senhor saiu de Jerusalém; não conserve o rei isso no coração.',
        'Porque teu servo deveras confessa que pecou; porém eis que eu sou o primeiro que de toda a casa de José desci a encontrar-me com o rei meu senhor.',
        'Então respondeu Abisai, filho de Zeruia, e disse: Não morreria, pois, Simei por isto, havendo amaldiçoado ao ungido do Senhor?',
        'Porém Davi disse: Que tenho eu convosco, filhos de Zeruia, para que hoje me sejais adversários? Morreria alguém hoje em Israel? Pois porventura não sei que hoje fui feito rei sobre Israel?',
        'E disse o rei a Simei: Não morrerás. E o rei lho jurou.',
        'Também Mefibosete, filho de Saul, desceu a encontrar-se com o rei, e não tinha lavado os pés, nem tinha feito a barba, nem tinha lavado as suas vestes desde o dia em que o rei tinha saído até ao dia em que voltou em paz.',
        'E sucedeu que, vindo ele a Jerusalém a encontrar-se com o rei, disse-lhe o rei: Por que não foste comigo, Mefibosete?',
        'E disse ele: Ó rei meu senhor, o meu servo me enganou; porque o teu servo dizia: Albardarei um jumento, e nele montarei, e irei com o rei; pois o teu servo é coxo.',
        'Demais disto, falsamente acusou a teu servo diante do rei meu senhor; porém o rei meu senhor é como um anjo de Deus; faze, pois, o que parecer bem aos teus olhos.',
        'Porque toda a casa de meu pai não era senão de homens dignos de morte diante do rei meu senhor; e contudo puseste a teu servo entre os que comem à tua mesa; e que mais direito tenho eu de clamar ao rei?',
        'E disse-lhe o rei: Por que ainda mais falas de teus negócios? Já disse eu: Tu e Ziba reparti as terras.',
        'E disse Mefibosete ao rei: Tome ele também tudo; pois já veio o rei meu senhor em paz à sua casa.',
        'Também Barzilai, o gileadita, desceu de Rogelim, e passou com o rei o Jordão, para o acompanhar ao outro lado do Jordão.',
        'E era Barzilai muito velho, da idade de oitenta anos; e ele tinha sustentado o rei, quando tinha a sua morada em Maanaim, porque era grande homem.',
        'E disse o rei a Barzilai: Passa tu comigo, e sustentar-te-ei comigo em Jerusalém.',
        'Porém Barzilai disse ao rei: Quantos serão os dias dos anos da minha vida, para que suba com o rei a Jerusalém?',
        'Da idade de oitenta anos sou eu hoje; poderia eu discernir entre o bom e o mau? Poderia o teu servo ter gosto no que comer e beber? Poderia eu mais ouvir a voz dos cantores e cantoras? E por que será o teu servo ainda pesado ao rei meu senhor?',
        'Com o rei passará teu servo ainda um pouco mais além do Jordão; e por que me recompensará o rei com tal recompensa?',
        'Deixa voltar o teu servo, e morrerei na minha cidade, junto à sepultura de meu pai e de minha mãe; mas eis aí está o teu servo Quimã; passe ele com o rei meu senhor, e faze-lhe o que bem parecer aos teus olhos.',
        'Então disse o rei: Quimã passará comigo, e eu lhe farei como bem parecer aos teus olhos, e tudo quanto me pedires te farei.',
        'Havendo, pois, todo o povo passado o Jordão, e passando também o rei, beijou o rei a Barzilai, e o abençoou; e ele voltou para o seu lugar.',
        'E dali passou o rei a Gilgal, e Quimã passou com ele; e todo o povo de Judá conduziu o rei, como também a metade do povo de Israel.',
        'E eis que todos os homens de Israel vieram ao rei, e disseram ao rei: Por que te furtaram nossos irmãos, os homens de Judá, e conduziram o rei e a sua casa dalém do Jordão, e todos os homens de Davi com eles?',
        'Então responderam todos os homens de Judá aos homens de Israel: Porquanto o rei é nosso parente; e por que vos irais por isso? Porventura comemos às custas do rei, ou nos deu algum presente?',
        'E responderam os homens de Israel aos homens de Judá, e disseram: Dez partes temos no rei, e até em Davi mais temos nós do que vós; por que, pois, não fizestes conta de nós, para que a nossa palavra não fosse a primeira, para tornar a trazer o nosso rei? Porém a palavra dos homens de Judá foi mais forte do que a palavra dos homens de Israel.',
      ],
      [
        'Então se achou ali por acaso um homem de Belial, cujo nome era Seba, filho de Bicri, homem de Benjamim, o qual tocou a buzina, e disse: Não temos parte em Davi, nem herança no filho de Jessé; cada um às suas tendas, ó Israel.',
        'Então todos os homens de Israel se separaram de Davi, e seguiram Seba, filho de Bicri; porém os homens de Judá se uniram ao seu rei desde o Jordão até Jerusalém.',
        'Vindo, pois, Davi para sua casa, em Jerusalém, tomou o rei as dez mulheres, suas concubinas, que deixara para guardarem a casa, e as pôs numa casa sob guarda, e as sustentava; porém não as possuiu; e estiveram encerradas até ao dia da sua morte, vivendo como viúvas.',
        'Disse mais o rei a Amasa: Convoca-me os homens de Judá para o terceiro dia; e tu então apresenta-te aqui.',
        'E foi Amasa para convocar a Judá; porém demorou-se além do tempo que lhe tinha sido designado.',
        'Então disse Davi a Abisai: Mais mal agora nos fará Seba, o filho de Bicri, do que Absalão; por isso toma tu os servos de teu senhor, e persegue-o, para que não ache para si cidades fortes, e escape dos nossos olhos.',
        'Então saíram atrás dele os homens de Joabe, e os quereteus, e os peleteus, e todos os valentes; estes saíram de Jerusalém para irem atrás de Seba, filho de Bicri.',
        'Chegando eles, pois, à pedra grande, que está junto a Gibeom, Amasa veio diante deles; e estava Joabe cingido da sua roupa que vestira, e sobre ela um cinto, ao qual estava presa a espada a seus lombos, na sua bainha; e, adiantando-se ele, lhe caiu a espada.',
        'E disse Joabe a Amasa: Vai bem, meu irmão? E Joabe, com a mão direita, pegou da barba de Amasa, para o beijar.',
        'E Amasa não se resguardou da espada que estava na mão de Joabe, de sorte que este o feriu com ela na quinta costela, e lhe derramou por terra as entranhas, e não o feriu segunda vez, e morreu; então Joabe e Abisai, seu irmão, foram atrás de Seba, filho de Bicri.',
        'Mas um dentre os homens de Joabe parou junto a ele, e disse: Quem há que queira bem a Joabe, e quem seja por Davi, siga Joabe.',
        'E Amasa estava envolto no seu sangue no meio do caminho; e, vendo aquele homem, que todo o povo parava, removeu a Amasa do caminho para o campo, e lançou sobre ele um manto; porque via que todo aquele que chegava a ele parava.',
        'E, como estava removido do caminho, todos os homens seguiram a Joabe, para perseguirem a Seba, filho de Bicri.',
        'E ele passou por todas as tribos de Israel até Abel, e Bete-Maaca e a todos os beritas; e ajuntaram-se, e também o seguiram.',
        'E vieram, e o cercaram em Abel de Bete-Maaca, e levantaram uma barragem contra a cidade, e isto colocado na trincheira; e todo o povo que estava com Joabe batia no muro, para derrubá-lo.',
        'Então uma mulher sábia gritou de dentro da cidade: Ouvi, ouvi, peço-vos que digais a Joabe: Chega-te aqui, para que eu te fale.',
        'Chegando-se a ela, a mulher lhe disse: Tu és Joabe? E disse ele: Eu sou. E ela lhe disse: Ouve as palavras da tua serva. E disse ele: Ouço.',
        'Então falou ela, dizendo: Antigamente costumava-se dizer: Certamente pediram conselho a Abel; e assim resolveram.',
        'Sou eu uma das pacíficas e das fiéis em Israel; e tu procuras matar uma cidade que é mãe em Israel; por que, pois, devorarias a herança do Senhor?',
        'Então respondeu Joabe, e disse: Longe, longe de mim que eu tal faça, que eu devore ou arruíne!',
        'A coisa não é assim; porém um só homem do monte de Efraim, cujo nome é Seba, filho de Bicri, levantou a mão contra o rei, contra Davi; entregai-me só este, e retirar-me-ei da cidade. Então disse a mulher a Joabe: Eis que te será lançada a sua cabeça pelo muro.',
        'E a mulher, na sua sabedoria, foi a todo o povo, e cortaram a cabeça de Seba, filho de Bicri, e a lançaram a Joabe; então este tocou a buzina, e se retiraram da cidade, cada um para a sua tenda, e Joabe voltou a Jerusalém, ao rei.',
        'E Joabe estava sobre todo o exército de Israel; e Benaia, filho de Joiada, sobre os quereteus e sobre os peleteus;',
        'E Adorão sobre os tributos; e Jeosafá, filho de Ailude, era o cronista;',
        'E Seva, o escrivão; e Zadoque e Abiatar, os sacerdotes;',
        'E também Ira, o jairita, era o oficial-mor de Davi.',
      ],
      [
        'E houve nos dias de Davi uma fome de três anos consecutivos; e Davi consultou ao SENHOR, e o SENHOR lhe disse: É por causa de Saul e da sua casa sanguinária, porque matou os gibeonitas.',
        'Então chamou o rei aos gibeonitas, e lhes falou (ora os gibeonitas não eram dos filhos de Israel, mas do restante dos amorreus, e os filhos de Israel lhes tinham jurado, porém Saul, no seu zelo à causa dos filhos de Israel e de Judá, procurou feri-los).',
        'Disse, pois, Davi aos gibeonitas: Que quereis que eu vos faça? E que satisfação vos darei, para que abençoeis a herança do Senhor?',
        'Então os gibeonitas lhe disseram: Não é por prata nem ouro que temos questão com Saul e com sua casa; nem tampouco pretendemos matar pessoa alguma em Israel. E disse ele: Que é, pois, que quereis que vos faça?',
        'E disseram ao rei: O homem que nos destruiu, e intentou contra nós de modo que fôssemos assolados, sem que pudéssemos subsistir em termo algum de Israel,',
        'De seus filhos se nos dêem sete homens, para que os enforquemos ao Senhor em Gibeá de Saul, o eleito do Senhor. E disse o rei: Eu os darei.',
        'Porém o rei poupou a Mefibosete, filho de Jônatas, filho de Saul, por causa do juramento do Senhor, que entre eles houvera, entre Davi e Jônatas, filho de Saul.',
        'Mas tomou o rei os dois filhos de Rispa, filha da Aiá, que tinha tido de Saul, a Armoni e a Mefibosete; como também os cinco filhos da irmã de Mical, filha de Saul, que tivera de Adriel, filho de Barzilai, meolatita,',
        'E os entregou na mão dos gibeonitas, os quais os enforcaram no monte, perante o Senhor; e caíram estes sete juntamente; e foram mortos nos dias da sega, nos dias primeiros, no princípio da sega das cevadas.',
        'Então Rispa, filha de Aiá, tomou um pano de cilício, e estendeu-lho sobre uma penha, desde o princípio da sega até que a água do céu caiu sobre eles; e não deixou as aves do céu pousar sobre eles de dia, nem os animais do campo de noite.',
        'E foi contado a Davi o que fizera Rispa, filha de Aiá, concubina de Saul.',
        'Então foi Davi, e tomou os ossos de Saul, e os ossos de Jônatas seu filho, dos moradores de Jabes-Gileade, os quais os furtaram da rua de Bete-Sã, onde os filisteus os tinham pendurado, quando feriram a Saul em Gilboa.',
        'E fez subir dali os ossos de Saul, e os ossos de Jônatas seu filho; e ajuntaram também os ossos dos enforcados.',
        'Enterraram os ossos de Saul, e de Jônatas seu filho na terra de Benjamim, em Zela, na sepultura de seu pai Quis, e fizeram tudo o que o rei ordenara; e depois disto Deus se aplacou com a terra.',
        'Tiveram mais os filisteus uma peleja contra Israel; e desceu Davi, e com ele os seus servos; e tanto pelejaram contra os filisteus, que Davi se cansou.',
        'E Isbi-Benobe, que era dos filhos do gigante, cuja lança pesava trezentos siclos de cobre, e que cingia uma espada nova, intentou ferir a Davi.',
        'Porém, Abisai, filho de Zeruia, o socorreu, e feriu o filisteu, e o matou. Então os homens de Davi lhe juraram, dizendo: Nunca mais sairás conosco à peleja, para que não apagues a lâmpada de Israel.',
        'E aconteceu depois disto que houve em Gobe ainda outra peleja contra os filisteus; então Sibecai, o husatita, feriu a Safe, que era dos filhos do gigante.',
        'Houve mais outra peleja contra os filisteus em Gobe; e El-Hanã, filho de Jaaré-Oregim, o belemita, feriu Golias, o giteu, de cuja lança era a haste como órgão de tecelão.',
        'Houve ainda também outra peleja em Gate, onde estava um homem de alta estatura, que tinha em cada mão seis dedos, e em cada pé outros seis, vinte e quatro ao todo, e também este nascera do gigante.',
        'E injuriava a Israel; porém Jônatas, filho de Simei, irmão de Davi, o feriu.',
        'Estes quatro nasceram ao gigante em Gate; e caíram pela mão de Davi e pela mão de seus servos.',
      ],
      [
        'E Falou Davi ao SENHOR as palavras deste cântico, no dia em que o SENHOR o livrou das mãos de todos os seus inimigos e das mãos de Saul.',
        'Disse pois: O Senhor é o meu rochedo, e o meu lugar forte, e o meu libertador.',
        'Deus é o meu rochedo, nele confiarei; o meu escudo, e a força da minha salvação, o meu alto retiro, e o meu refúgio. Ó meu Salvador, da violência me salvas.',
        'O Senhor, digno de louvor, invocarei, e de meus inimigos ficarei livre,',
        'Porque me cercaram as ondas de morte; as torrentes dos homens ímpios me assombraram.',
        'Cordas do inferno me cingiram; encontraram-me laços de morte.',
        'Estando em angústia, invoquei ao Senhor, e a meu Deus clamei; do seu templo ouviu ele a minha voz, e o meu clamor chegou aos seus ouvidos.',
        'Então se abalou e tremeu a terra, os fundamentos dos céus se moveram e abalaram, porque ele se irou.',
        'Subiu fumaça de suas narinas, e da sua boca um fogo devorador; carvões se incenderam dele.',
        'E abaixou os céus, e desceu; e uma escuridão havia debaixo de seus pés.',
        'E subiu sobre um querubim, e voou; e foi visto sobre as asas do vento.',
        'E por tendas pôs as trevas ao redor de si; ajuntamento de águas, nuvens dos céus.',
        'Pelo resplendor da sua presença brasas de fogo se acenderam.',
        'Trovejou desde os céus o Senhor; e o Altíssimo fez soar a sua voz.',
        'E disparou flechas, e os dissipou; raios, e os perturbou.',
        'E apareceram as profundezas do mar, e os fundamentos do mundo se descobriram; pela repreensão do Senhor, pelo sopro do vento das suas narinas.',
        'Desde o alto enviou, e me tomou; tirou-me das muitas águas.',
        'Livrou-me do meu poderoso inimigo, e daqueles que me tinham ódio, porque eram mais fortes do que eu.',
        'Encontraram-me no dia da minha calamidade; porém o Senhor se fez o meu amparo.',
        'E tirou-me para um lugar espaçoso, e livrou-me, porque tinha prazer em mim.',
        'Recompensou-me o Senhor conforme a minha justiça; conforme a pureza de minhas mãos me retribuiu.',
        'Porque guardei os caminhos do Senhor; e não me apartei impiamente do meu Deus.',
        'Porque todos os seus juízos estavam diante de mim; e de seus estatutos não me desviei.',
        'Porém fui sincero perante ele; e guardei-me da minha iniqüidade.',
        'E me retribuiu o Senhor conforme a minha justiça, conforme a minha pureza diante dos seus olhos.',
        'Com o benigno te mostras benigno; com o homem íntegro te mostras perfeito.',
        'Com o puro te mostras puro; mas com o perverso te mostras rígido.',
        'E o povo aflito livras; mas teus olhos são contra os altivos, e tu os abaterás.',
        'Porque tu, Senhor, és a minha lâmpada; e o Senhor ilumina as minhas trevas.',
        'Porque contigo passo pelo meio de um esquadrão; pelo meu Deus salto um muro.',
        'O caminho de Deus é perfeito, e a palavra do Senhor refinada; e é o escudo de todos os que nele confiam.',
        'Por que, quem é Deus, senão o Senhor? E quem é rochedo, senão o nosso Deus?',
        'Deus é a minha fortaleza e a minha força, e ele perfeitamente desembaraça o meu caminho.',
        'Faz ele os meus pés como os das cervas, e me põe sobre as minhas alturas.',
        'Instrui as minhas mãos para a peleja, de maneira que um arco de cobre se quebra pelos meus braços.',
        'Também me deste o escudo da tua salvação, e pela tua brandura me vieste a engrandecer.',
        'Alargaste os meus passos debaixo de mim, e não vacilaram os meus artelhos.',
        'Persegui os meus inimigos, e os derrotei, e nunca me tornei até que os consumisse.',
        'E os consumi, e os atravessei, de modo que nunca mais se levantaram, mas caíram debaixo dos meus pés.',
        'Porque me cingiste de força para a peleja; fizeste abater-se debaixo de mim os que se levantaram contra mim,',
        'E deste-me o pescoço de meus inimigos, daqueles que me tinham ódio, e os destruí.',
        'Olharam, porém não houve libertador; sim, para o Senhor, porém não lhes respondeu.',
        'Então os moí como o pó da terra; como a lama das ruas os trilhei e dissipei.',
        'Também me livraste das contendas do meu povo; guardaste-me para cabeça das nações; o povo que não conhecia me servirá.',
        'Os filhos de estranhos se me sujeitaram; ouvindo a minha voz, me obedeceram.',
        'Os filhos de estranhos desfaleceram; e, cingindo-se, saíram dos seus esconderijos.',
        'Vive o Senhor, e bendito seja o meu rochedo; e exaltado seja Deus, a rocha da minha salvação,',
        'O Deus que me dá inteira vingança, e sujeita os povos debaixo de mim.',
        'E o que me tira dentre os meus inimigos; e tu me exaltas sobre os que contra mim se levantam; do homem violento me livras.',
        'Por isso, ó Senhor, te louvarei entre os gentios, e entoarei louvores ao teu nome.',
        'Ele é a torre das salvações do seu rei, e usa de benignidade com o seu ungido, com Davi, e com a sua descendência para sempre.',
      ],
      [
        'E estas são as últimas palavras de Davi: Diz Davi, filho de Jessé, e diz o homem que foi levantado em altura, o ungido do Deus de Jacó, e o suave em salmos de Israel.',
        'O Espírito do Senhor falou por mim, e a sua palavra está na minha boca.',
        'Disse o Deus de Israel, a Rocha de Israel a mim me falou: Haverá um justo que domine sobre os homens, que domine no temor de Deus.',
        'E será como a luz da manhã, quando sai o sol, da manhã sem nuvens, quando pelo seu resplendor e pela chuva a erva brota da terra.',
        'Ainda que a minha casa não seja tal para com Deus, contudo estabeleceu comigo uma aliança eterna, que em tudo será bem ordenado e guardado, pois toda a minha salvação e todo o meu prazer está nele, apesar de que ainda não o faz brotar.',
        'Porém os filhos de Belial todos serão como os espinhos que se lançam fora, porque não podem ser tocados com a mão.',
        'Mas qualquer que os tocar se armará de ferro e da haste de uma lança; e a fogo serão totalmente queimados no mesmo lugar.',
        'Estes são os nomes dos poderosos que Davi teve: Josebe-Bassebete, filho de Taquemoni, o principal dos capitães; este era Adino, o eznita, que se opusera a oitocentos, e os feriu de uma vez.',
        'E depois dele Eleazar, filho de Dodó, filho de Aoí, entre os três valentes que estavam com Davi quando provocaram os filisteus que ali se ajuntaram à peleja, e quando se retiraram os homens de Israel.',
        'Este se levantou, e feriu os filisteus, até lhe cansar a mão e ficar a mão pegada à espada; e naquele dia o Senhor efetuou um grande livramento; e o povo voltou junto dele, somente a tomar o despojo.',
        'E depois dele Samá, filho de Agé, o hararita, quando os filisteus se ajuntaram numa multidão, onde havia um pedaço de terra cheio de lentilhas, e o povo fugira de diante dos filisteus.',
        'Este, pois, se pôs no meio daquele pedaço de terra, e o defendeu, e feriu os filisteus; e o Senhor efetuou um grande livramento.',
        'Também três dos trinta chefes desceram, e no tempo da sega foram a Davi, à caverna de Adulão; e a multidão dos filisteus acampara no vale de Refaim.',
        'Davi estava então num lugar forte, e a guarnição dos filisteus em Belém.',
        'E teve Davi desejo, e disse: Quem me dera beber da água da cisterna de Belém, que está junto à porta!',
        'Então aqueles três poderosos romperam pelo arraial dos filisteus, e tiraram água da cisterna de Belém, que está junto à porta, e a tomaram, e a trouxeram a Davi; porém ele não a quis beber, mas derramou-a perante o Senhor.',
        'E disse: Guarda-me, ó Senhor, de que tal faça; beberia eu o sangue dos homens que foram com risco da sua vida? De maneira que não a quis beber; isto fizeram aqueles três poderosos.',
        'Também Abisai, irmão de Joabe, filho de Zeruia, era chefe de três; e este alçou a sua lança contra trezentos e os feriu; e tinha nome entre os três.',
        'Porventura este não era o mais nobre dentre estes três? Pois era o primeiro deles; porém aos primeiros três não chegou.',
        'Também Benaia, filho de Joiada, filho de um homem valoroso de Cabzeel, grande em obras, este feriu dois fortes heróis de Moabe; e desceu ele, e feriu um leão no meio duma cova, no tempo da neve.',
        'Também este feriu um egípcio, homem de respeito; e na mão do egípcio havia uma lança, porém ele desceu a ele com um cajado, e arrancou a lança da mão do egípcio, e com ela o matou.',
        'Estas coisas fez Benaia, filho de Joiada, pelo que teve nome entre três poderosos.',
        'Dentre os trinta ele era o mais nobre, porém aos três primeiros não chegou; e Davi o pôs sobre os seus guardas.',
        'Asael, irmão de Joabe, estava entre os trinta; El-Hanã, filho de Dodó, de Belém;',
        'Samá, harodita; Elica, harodita;',
        'Helez, paltita; Ira, filho de Iques, tecoíta;',
        'Abiezer, anatotita; Mebunai, husatita;',
        'Zalmom, aoíta; Maarai, netofatita;',
        'Elebe, filho de Baaná, netofatita; Itai, filho de Ribai, de Gibeá dos filhos de Benjamim;',
        'Benaia, piratonita; Hidai, do ribeiro de Gaás;',
        'Abi-Albom, arbatita; Azmavete, barumita;',
        'Eliaba, saalbonita; os filhos de Jásen e Jônatas;',
        'Samá, hararita, Aião, filho de Sarar, ararita;',
        'Elifelete, filho de Aasbai, filho de um maacatita; Eliã, filho de Aitofel, gilonita;',
        'Hesrai, carmelita; Paarai, arbita;',
        'Igal, filho de Natã, de Zobá; Bani, gadita;',
        'Zeleque, amonita; Naarai, beerotita, o que trazia as armas de Joabe, filho de Zeruia;',
        'Ira, itrita; Garebe, itrita;',
        'Urias, heteu; trinta e sete ao todo.',
      ],
      [
        'E a ira do SENHOR se tornou a acender contra Israel; e incitou a Davi contra eles, dizendo: Vai, numera a Israel e a Judá.',
        'Disse, pois, o rei a Joabe, capitão do exército, o qual tinha consigo: Agora percorre todas as tribos de Israel, desde Dã até Berseba, e numera o povo, para que eu saiba o número do povo.',
        'Então disse Joabe ao rei: Ora, multiplique o SENHOR teu Deus a este povo cem vezes tanto quanto agora é, e os olhos do rei meu senhor o vejam; mas, por que deseja o rei meu senhor este negócio?',
        'Porém a palavra do rei prevaleceu contra Joabe, e contra os capitães do exército; Joabe, pois, saiu com os capitães do exército da presença do rei, para numerar o povo de Israel.',
        'E passaram o Jordão; e acamparam-se em Aroer, à direita da cidade que está no meio do ribeiro de Gade, junto a Jazer.',
        'E foram a Gileade, e à terra baixa de Hodsi; também foram até Dã-Jaã, e ao redor de Sidom.',
        'E foram à fortaleza de Tiro, e a todas as cidades dos heveus e dos cananeus; e saíram para o lado do sul de Judá, a Berseba.',
        'Assim percorreram toda a terra; e ao cabo de nove meses e vinte dias voltaram a Jerusalém.',
        'E Joabe deu ao rei a soma do número do povo contado; e havia em Israel oitocentos mil homens de guerra, que arrancavam da espada; e os homens de Judá eram quinhentos mil homens.',
        'E pesou o coração de Davi, depois de haver numerado o povo; e disse Davi ao Senhor: Muito pequei no que fiz; porém agora ó Senhor, peço-te que perdoes a iniqüidade do teu servo; porque tenho procedido mui loucamente.',
        'Levantando-se, pois, Davi pela manhã, veio a palavra do Senhor ao profeta Gade, vidente de Davi, dizendo:',
        'Vai, e dize a Davi: Assim diz o Senhor: Três coisas te ofereço; escolhe uma delas, para que ta faça.',
        'Foi, pois, Gade a Davi, e fez-lho saber; e disse-lhe: Queres que sete anos de fome te venham à tua terra; ou que por três meses fujas de teus inimigos, e eles te persigam; ou que por três dias haja peste na tua terra? Delibera agora, e vê que resposta hei de dar ao que me enviou.',
        'Então disse Davi a Gade: Estou em grande angústia; porém caiamos nas mãos do Senhor, porque muitas são as suas misericórdias; mas nas mãos dos homens não caia eu.',
        'Então enviou o Senhor a peste a Israel, desde a manhã até ao tempo determinado; e desde Dã até Berseba, morreram setenta mil homens do povo.',
        'Estendendo, pois, o anjo a sua mão sobre Jerusalém, para a destruir, o Senhor se arrependeu daquele mal; e disse ao anjo que fazia a destruição entre o povo: Basta, agora retira a tua mão. E o anjo do Senhor estava junto à eira de Araúna, o jebuseu.',
        'E, vendo Davi ao anjo que feria o povo, falou ao Senhor, dizendo: Eis que eu sou o que pequei, e eu que iniquamente procedi; porém estas ovelhas que fizeram? Seja, pois, a tua mão contra mim, e contra a casa de meu pai.',
        'E Gade veio naquele mesmo dia a Davi, e disse-lhe: Sobe, levanta ao Senhor um altar na eira de Araúna, o jebuseu.',
        'Davi subiu conforme à palavra de Gade, como o Senhor lhe tinha ordenado.',
        'E olhou Araúna, e viu que vinham para ele o rei e os seus servos; saiu, pois, Araúna e inclinou-se diante do rei com o rosto em terra.',
        'E disse Araúna: Por que vem o rei meu Senhor ao seu servo? E disse Davi: Para comprar de ti esta eira, a fim de edificar nela um altar ao Senhor, para que este castigo cesse de sobre o povo.',
        'Então disse Araúna a Davi: Tome, e ofereça o rei meu senhor o que bem parecer aos seus olhos; eis aí bois para o holocausto, e os trilhos, e o aparelho dos bois para a lenha.',
        'Tudo isto deu Araúna ao rei; disse mais Araúna ao rei: O Senhor teu Deus tome prazer em ti.',
        'Porém o rei disse a Araúna: Não, mas por preço justo to comprarei, porque não oferecerei ao Senhor meu Deus holocaustos que não me custem nada. Assim Davi comprou a eira e os bois por cinqüenta siclos de prata.',
        'E edificou ali Davi ao Senhor um altar, e ofereceu holocaustos, e ofertas pacíficas. Assim o Senhor se aplacou para com a terra e cessou aquele castigo de sobre Israel.',
      ],
    ],
    livro: '2samuel',
  },
  {
    abbrev: '1rs',
    capitulos: [
      [
        'Sendo, pois, o rei Davi já velho, e entrado em dias, cobriam-no de roupas, porém não se aquecia.',
        'Então disseram-lhe os seus servos: Busquem para o rei meu senhor uma moça virgem, que esteja perante o rei, e tenha cuidado dele; e durma no seu seio, para que o rei meu senhor se aqueça.',
        'E buscaram por todos os termos de Israel uma moça formosa, e acharam a Abisague, sunamita; e a trouxeram ao rei.',
        'E era a moça sobremaneira formosa; e tinha cuidado do rei, e o servia; porém o rei não a conheceu.',
        'Então Adonias, filho de Hagite, se levantou, dizendo: Eu reinarei. E preparou carros, e cavaleiros, e cinqüenta homens, que corressem adiante dele.',
        'E nunca seu pai o tinha contrariado, dizendo: Por que fizeste assim? E era ele também muito formoso de parecer; e Hagite o tivera depois de Absalão.',
        'E tinha entendimento com Joabe, filho de Zeruia, e com Abiatar o sacerdote; os quais o ajudavam, seguindo a Adonias.',
        'Porém Zadoque, o sacerdote, e Benaia, filho de Joiada, e Natã, o profeta, e Simei, e Rei, e os poderosos que Davi tinha, não estavam com Adonias.',
        'E matou Adonias ovelhas, e vacas, e animais cevados, junto à pedra de Zoelete, que está perto da fonte de Rogel; e convidou a todos os seus irmãos, os filhos do rei, e a todos os homens de Judá, servos do rei.',
        'Porém a Natã, o profeta, e a Benaia, e aos poderosos, e a Salomão, seu irmão, não convidou.',
        'Então falou Natã a Bate-Seba, mãe de Salomão, dizendo: Não ouviste que Adonias, filho de Hagite, reina? E que nosso senhor Davi não o sabe?',
        'Vem, pois, agora, e deixa-me dar-te um conselho, para que salves a tua vida, e a de Salomão teu filho.',
        'Vai, e chega ao rei Davi, e dize-lhe: Não juraste tu, rei senhor meu, à tua serva, dizendo: Certamente teu filho Salomão reinará depois de mim, e ele se assentará no meu trono? Por que, pois, reina Adonias?',
        'Eis que, estando tu ainda aí falando com o rei, eu também entrarei depois de ti, e confirmarei as tuas palavras.',
        'E foi Bate-Seba ao rei na sua câmara; e o rei era muito velho; e Abisague, a sunamita, servia ao rei.',
        'E Bate-Seba inclinou a cabeça, e se prostrou perante o rei; e disse o rei: Que tens?',
        'E ela lhe disse: Senhor meu, tu juraste à tua serva pelo Senhor teu Deus, dizendo: Salomão, teu filho, reinará depois de mim, e ele se assentará no meu trono.',
        'E agora eis que Adonias reina; e tu, ó rei meu senhor, não o sabes.',
        'E matou vacas, e animais cevados, e ovelhas em abundância, e convidou a todos os filhos do rei, e a Abiatar, o sacerdote, e a Joabe, capitão do exército, mas a teu servo Salomão não convidou.',
        'Porém, ó rei meu senhor, os olhos de todo o Israel estão sobre ti, para que lhe declares quem se assentará sobre o trono do rei meu senhor, depois dele.',
        'De outro modo sucederá que, quando o rei meu senhor dormir com seus pais, eu e Salomão meu filho seremos os culpados.',
        'E, estando ela ainda falando com o rei, eis que entra o profeta Natã.',
        'E o fizeram saber ao rei, dizendo: Eis aí está o profeta Natã. E entrou à presença do rei, e prostrou-se diante dele com o rosto em terra.',
        'E disse Natã: Ó rei meu senhor, disseste tu: Adonias reinará depois de mim, e ele se assentará sobre o meu trono?',
        'Porque hoje desceu, e matou vacas, e animais cevados, e ovelhas em abundância, e convidou a todos os filhos do rei e aos capitães do exército, e a Abiatar, o sacerdote, e eis que estão comendo e bebendo perante ele; e dizem: Viva o rei Adonias.',
        'Porém a mim, sendo eu teu servo, e a Zadoque, o sacerdote, e a Benaia, filho de Joiada, e a Salomão, teu servo, não convidou.',
        'Foi feito isto da parte do rei meu senhor? E não fizeste saber a teu servo quem se assentaria no trono do rei meu senhor depois dele?',
        'E respondeu o rei Davi, e disse: Chamai-me a Bate-Seba. E ela entrou à presença do rei; e ficou em pé diante do rei.',
        'Então jurou o rei e disse: Vive o Senhor, o qual remiu a minha alma de toda a angústia,',
        'Que, como te jurei pelo Senhor Deus de Israel, dizendo: Certamente teu filho Salomão reinará depois de mim, e ele se assentará no meu trono, em meu lugar, assim o farei no dia de hoje.',
        'Então Bate-Seba se inclinou com o rosto em terra e se prostrou diante do rei, e disse: Viva o rei Davi meu senhor para sempre.',
        'E disse o rei Davi: Chamai-me a Zadoque, o sacerdote, e a Natã, o profeta, e a Benaia, filho de Joiada. E eles entraram à presença do rei.',
        'E o rei lhes disse: Tomai convosco os servos de vosso senhor, e fazei subir a meu filho Salomão na mula que é minha; e levai-o a Giom.',
        'E Zadoque, o sacerdote, com Natã, o profeta, ali o ungirão rei sobre Israel; então tocareis a trombeta, e direis: Viva o rei Salomão!',
        'Então subireis após ele, e virá e se assentará no meu trono, e ele reinará em meu lugar; porque tenho ordenado que ele seja guia sobre Israel e sobre Judá.',
        'Então Benaia, filho de Joiada, respondeu ao rei, e disse: Amém; assim o diga o SENHOR Deus do rei meu senhor.',
        'Como o SENHOR foi com o rei meu senhor, assim o seja com Salomão, e faça que o seu trono seja maior do que o trono do rei Davi meu senhor.',
        'Então desceu Zadoque, o sacerdote, e Natã, o profeta, e Benaia, filho de Joiada, e os quereteus, e os peleteus, e fizeram montar a Salomão na mula do rei Davi, e o levaram a Giom.',
        'E Zadoque, o sacerdote, tomou o chifre de azeite do tabernáculo, e ungiu a Salomão; e tocaram a trombeta, e todo o povo disse: Viva o rei Salomão!',
        'E todo o povo subiu após ele, e o povo tocava gaitas, e alegrava-se com grande alegria; de maneira que com o seu clamor a terra retiniu.',
        'E o ouviu Adonias, e todos os convidados que estavam com ele, que tinham acabado de comer; também Joabe ouviu o sonido das trombetas, e disse: Por que há tal ruído de cidade alvoroçada?',
        'Estando ele ainda falando, eis que vem Jônatas, filho de Abiatar, o sacerdote, e disse Adonias: Entra, porque és homem valente, e trarás boas novas.',
        'E respondeu Jônatas, e disse a Adonias: Certamente nosso senhor, rei Davi, constituiu rei a Salomão;',
        'E o rei enviou com ele a Zadoque, o sacerdote, e a Natã, o profeta, e a Benaia, filho de Joiada, e aos quereteus e aos peleteus; e o fizeram montar na mula do rei.',
        'E Zadoque, o sacerdote, e Natã, o profeta, o ungiram rei em Giom, e dali subiram alegres, e a cidade está alvoroçada; este é o clamor que ouviste.',
        'E também Salomão está assentado no trono do reino.',
        'E também os servos do rei vieram abençoar a nosso senhor, o rei Davi, dizendo: Faça teu Deus que o nome de Salomão seja melhor do que o teu nome; e faça que o seu trono seja maior do que o teu trono. E o rei se inclinou no leito.',
        'E também disse o rei assim: Bendito o Senhor Deus de Israel, que hoje tem dado quem se assente no meu trono, e que os meus olhos o vissem.',
        'Então estremeceram e se levantaram todos os convidados que estavam com Adonias; e cada um se foi ao seu caminho.',
        'Porém Adonias temeu a Salomão; e levantou-se, e foi, e apegou-se às pontas do altar.',
        'E fez-se saber a Salomão, dizendo: Eis que Adonias teme ao rei Salomão; porque eis que apegou-se às pontas do altar, dizendo: Jure-me hoje o rei Salomão que não matará o seu servo à espada.',
        'E disse Salomão: Se for homem de bem, nem um de seus cabelos cairá em terra; se, porém, se achar nele maldade, morrerá.',
        'E mandou o rei Salomão, e o fizeram descer do altar; e veio, e prostrou-se perante o rei Salomão, e Salomão lhe disse: Vai para tua casa.',
      ],
      [
        'E aproximaram-se os dias da morte de Davi; e deu ele ordem a Salomão, seu filho, dizendo:',
        'Eu vou pelo caminho de toda a terra; esforça-te, pois, e sê homem.',
        'E guarda a ordenança do Senhor teu Deus, para andares nos seus caminhos, e para guardares os seus estatutos, e os seus mandamentos, e os seus juízos, e os seus testemunhos, como está escrito na lei de Moisés; para que prosperes em tudo quanto fizeres, e para onde quer que fores.',
        'Para que o Senhor confirme a palavra, que falou de mim, dizendo: Se teus filhos guardarem o seu caminho, para andarem perante a minha face fielmente, com todo o seu coração e com toda a sua alma, nunca, disse, te faltará sucessor ao trono de Israel.',
        'E também tu sabes o que me fez Joabe, filho de Zeruia, e o que fez aos dois capitães do exército de Israel, a Abner filho de Ner, e a Amasa, filho de Jeter, os quais matou, e em paz derramou o sangue de guerra, e pôs o sangue de guerra no cinto que tinha nos lombos, e nos sapatos que trazia nos pés.',
        'Faze, pois, segundo a tua sabedoria, e não permitas que suas cãs desçam à sepultura em paz.',
        'Porém com os filhos de Barzilai, o gileadita, usarás de beneficência, e estarão entre os que comem à tua mesa, porque assim se chegaram eles a mim, quando eu fugia por causa de teu irmão Absalão.',
        'E eis que também contigo está Simei, filho de Gera, filho de Benjamim, de Baurim, que me maldisse com maldição atroz, no dia em que ia a Maanaim; porém ele saiu a encontrar-se comigo junto ao Jordão, e eu pelo Senhor lhe jurei, dizendo que o não mataria à espada.',
        'Mas agora não o tenhas por inculpável, pois és homem sábio, e bem saberás o que lhe hás de fazer para que faças com que as suas cãs desçam à sepultura com sangue.',
        'E Davi dormiu com seus pais, e foi sepultado na cidade de Davi.',
        'E foram os dias que Davi reinou sobre Israel quarenta anos: sete anos reinou em Hebrom, e em Jerusalém reinou trinta e três anos.',
        'E Salomão se assentou no trono de Davi, seu pai, e o seu reino se fortificou sobremaneira.',
        'Então veio Adonias, filho de Hagite, a Bate-Seba, mãe de Salomão; e disse ela: De paz é a tua vinda? E ele disse: É de paz.',
        'Então disse ele: Uma palavra tenho que dizer-te. E ela disse: Fala.',
        'Disse, pois, ele: Bem sabes que o reino era meu, e todo o Israel tinha posto a vista em mim para que eu viesse a reinar, contudo o reino foi transferido e veio a ser de meu irmão, porque foi feito seu pelo Senhor.',
        'Assim que agora uma só petição te faço; não ma rejeites. E ela lhe disse: Fala.',
        'E ele disse: Peço-te que fales ao rei Salomão (porque ele não te rejeitará) que me dê por mulher a Abisague, a sunamita.',
        'E disse Bate-Seba: Bem, eu falarei por ti ao rei.',
        'Assim foi Bate-Seba ao rei Salomão, a falar-lhe por Adonias; e o rei se levantou a encontrar-se com ela, e se inclinou diante dela; então se assentou no seu trono, e fez pôr uma cadeira para a sua mãe, e ela se assentou à sua direita.',
        'Então disse ela: Só uma pequena petição te faço; não ma rejeites. E o rei lhe disse: Pede, minha mãe, porque não ta negarei.',
        'E ela disse: Dê-se Abisague, a sunamita, a Adonias, teu irmão, por mulher.',
        'Então respondeu o rei Salomão, e disse a sua mãe: E por que pedes a Abisague, a sunamita, para Adonias? Pede também para ele o reino (porque é meu irmão maior), para ele, digo, e também para Abiatar, sacerdote, e para Joabe, filho de Zeruia.',
        'E jurou o rei Salomão pelo Senhor, dizendo: Assim Deus me faça, e outro tanto, se não falou Adonias esta palavra contra a sua vida.',
        'Agora, pois, vive o Senhor, que me confirmou, e me fez assentar no trono de Davi, meu pai, e que me tem feito casa, como tinha falado, que hoje morrerá Adonias.',
        'E enviou o rei Salomão pela mão de Benaia, filho de Joiada, o qual arremeteu contra ele de modo que morreu.',
        'E a Abiatar, o sacerdote, disse o rei: Vai para Anatote, para os teus campos, porque és homem digno de morte; porém hoje não te matarei, porquanto levaste a arca do Senhor DEUS diante de Davi, meu pai, e porquanto foste aflito em tudo quanto meu pai foi aflito.',
        'Lançou, pois, Salomão fora a Abiatar, para que não fosse sacerdote do Senhor, para cumprir a palavra do Senhor, que tinha falado sobre a casa de Eli em Siló.',
        'E chegou a notícia até Joabe (porque Joabe tinha se desviado seguindo a Adonias, ainda que não tinha se desviado seguindo a Absalão), e Joabe fugiu para o tabernáculo do Senhor, e apegou-se às pontas do altar.',
        'E disseram ao rei Salomão que Joabe tinha fugido para o tabernáculo do Senhor; e eis que está junto ao altar; então Salomão enviou Benaia, filho de Joiada, dizendo: Vai, arremete sobre ele.',
        'E foi Benaia ao tabernáculo do Senhor, e lhe disse: Assim diz o rei: Sai daí. E disse ele: Não, porém aqui morrerei. E Benaia tornou com a resposta ao rei, dizendo: Assim falou Joabe, e assim me respondeu.',
        'E disse-lhe o rei: Faze como ele disse, e arremete contra ele, e sepulta-o, para que tires de mim e da casa de meu pai o sangue que Joabe sem causa derramou.',
        'Assim o Senhor fará recair o sangue dele sobre a sua cabeça, porque deu sobre dois homens mais justos e melhores do que ele, e os matou à espada, sem que meu pai Davi o soubesse, a saber: a Abner, filho de Ner, capitão do exército de Israel, e a Amasa, filho de Jeter, capitão do exército de Judá.',
        'Assim recairá o sangue destes sobre a cabeça de Joabe e sobre a cabeça da sua descendência para sempre; mas a Davi, e à sua descendência, e à sua casa, e ao seu trono, dará o Senhor paz para todo o sempre.',
        'E subiu Benaia, filho de Joiada, e arremeteu contra ele, e o matou; e foi sepultado em sua casa, no deserto.',
        'E o rei pôs a Benaia, filho de Joiada, em seu lugar sobre o exército, e a Zadoque, o sacerdote, pôs o rei em lugar de Abiatar.',
        'Depois mandou o rei, e chamou a Simei, e disse-lhe: Edifica-te uma casa em Jerusalém, e habita aí, e daí não saias, nem para uma nem para outra parte.',
        'Porque há de ser que no dia em que saíres e passares o ribeiro de Cedrom, de certo que sem dúvida morrerás; o teu sangue será sobre a tua cabeça.',
        'E Simei disse ao rei: Boa é essa palavra; como tem falado o rei meu senhor, assim fará o teu servo. E Simei habitou em Jerusalém muitos dias.',
        'Sucedeu, porém, que, ao cabo de três anos, dois servos de Simei fugiram para Aquis, filho de Maaca, rei de Gate; e deram parte a Simei, dizendo: Eis que teus servos estão em Gate.',
        'Então Simei se levantou, e albardou o seu jumento, e foi a Gate, ter com Aquis, em busca de seus servos; assim foi Simei, e trouxe os seus servos de Gate.',
        'E disseram a Salomão como Simei fora de Jerusalém a Gate, e já tinha voltado.',
        'Então o rei mandou chamar a Simei, e disse-lhe: Não te conjurei eu pelo Senhor, e protestei contra ti, dizendo: No dia em que saíres para uma ou outra parte, sabe de certo que, sem dúvida, morrerás? E tu me disseste: Boa é essa palavra que ouvi.',
        'Por que, pois, não guardaste o juramento do Senhor, nem a ordem que te dei?',
        'Disse mais o rei a Simei: Bem sabes tu toda a maldade que o teu coração reconhece, que fizeste a Davi, meu pai; pelo que o Senhor fez recair a tua maldade sobre a tua cabeça.',
        'Mas o rei Salomão será abençoado, e o trono de Davi será confirmado perante o Senhor para sempre.',
        'E o rei mandou a Benaia, filho de Joiada, o qual saiu, e arremeteu contra ele, de modo que morreu; assim foi confirmado o reino na mão de Salomão.',
      ],
      [
        'E Salomão se aparentou com Faraó, rei do Egito; e tomou a filha de Faraó, e a trouxe à cidade de Davi, até que acabasse de edificar a sua casa, e a casa do SENHOR, e a muralha de Jerusalém em redor.',
        'Entretanto, o povo sacrificava sobre os altos; porque até àqueles dias ainda não se havia edificado casa ao nome do Senhor.',
        'E Salomão amava ao Senhor, andando nos estatutos de Davi seu pai; somente que nos altos sacrificava, e queimava incenso.',
        'E foi o rei a Gibeom para lá sacrificar, porque aquele era o alto maior; mil holocaustos sacrificou Salomão naquele altar.',
        'E em Gibeom apareceu o Senhor a Salomão de noite em sonhos; e disse-lhe Deus: Pede o que queres que eu te dê.',
        'E disse Salomão: De grande beneficência usaste tu com teu servo Davi, meu pai, como também ele andou contigo em verdade, e em justiça, e em retidão de coração, perante a tua face; e guardaste-lhe esta grande beneficência, e lhe deste um filho que se assentasse no seu trono, como se vê neste dia.',
        'Agora, pois, ó Senhor meu Deus, tu fizeste reinar a teu servo em lugar de Davi meu pai; e sou apenas um menino pequeno; não sei como sair, nem como entrar.',
        'E teu servo está no meio do teu povo que elegeste; povo grande, que nem se pode contar, nem numerar, pela sua multidão.',
        'A teu servo, pois, dá um coração entendido para julgar a teu povo, para que prudentemente discirna entre o bem e o mal; porque quem poderia julgar a este teu tão grande povo?',
        'E esta palavra pareceu boa aos olhos do Senhor, de que Salomão pedisse isso.',
        'E disse-lhe Deus: Porquanto pediste isso, e não pediste para ti muitos dias, nem pediste para ti riquezas, nem pediste a vida de teus inimigos; mas pediste para ti entendimento, para discernires o que é justo;',
        'Eis que fiz segundo as tuas palavras; eis que te dei um coração tão sábio e entendido, que antes de ti igual não houve, e depois de ti igual não se levantará.',
        'E também até o que não pediste te dei, assim riquezas como glória; de modo que não haverá um igual entre os reis, por todos os teus dias.',
        'E, se andares nos meus caminhos, guardando os meus estatutos, e os meus mandamentos, como andou Davi teu pai, também prolongarei os teus dias.',
        'E acordou Salomão, e eis que era sonho. E indo a Jerusalém, pôs-se perante a arca da aliança do Senhor, e sacrificou holocausto, e preparou sacrifícios pacíficos, e fez um banquete a todos os seus servos.',
        'Então vieram duas mulheres prostitutas ao rei, e se puseram perante ele.',
        'E disse-lhe uma das mulheres: Ah! senhor meu, eu e esta mulher moramos numa casa; e tive um filho, estando com ela naquela casa.',
        'E sucedeu que, ao terceiro dia, depois do meu parto, teve um filho também esta mulher; estávamos juntas; nenhum estranho estava conosco na casa; somente nós duas naquela casa.',
        'E de noite morreu o filho desta mulher, porquanto se deitara sobre ele.',
        'E levantou-se à meia-noite, e tirou o meu filho do meu lado, enquanto dormia a tua serva, e o deitou no seu seio; e a seu filho morto deitou no meu seio.',
        'E, levantando-me eu pela manhã, para dar de mamar a meu filho, eis que estava morto; mas, atentando pela manhã para ele, eis que não era meu filho, que eu havia tido.',
        'Então disse a outra mulher: Não, mas o vivo é meu filho, e teu filho o morto. Porém esta disse: Não, por certo, o morto é teu filho, e meu filho o vivo. Assim falaram perante o rei.',
        'Então disse o rei: Esta diz: Este que vive é meu filho, e teu filho o morto; e esta outra diz: Não, por certo, o morto é teu filho e meu filho o vivo.',
        'Disse mais o rei: Trazei-me uma espada. E trouxeram uma espada diante do rei.',
        'E disse o rei: Dividi em duas partes o menino vivo; e dai metade a uma, e metade a outra.',
        'Mas a mulher, cujo filho era o vivo, falou ao rei (porque as suas entranhas se lhe enterneceram por seu filho), e disse: Ah! senhor meu, dai-lhe o menino vivo, e de modo nenhum o mateis. Porém a outra dizia: Nem teu nem meu seja; dividi-o.',
        'Então respondeu o rei, e disse: Dai a esta o menino vivo, e de maneira nenhuma o mateis, porque esta é sua mãe.',
        'E todo o Israel ouviu o juízo que havia dado o rei, e temeu ao rei; porque viram que havia nele a sabedoria de Deus, para fazer justiça.',
      ],
      [
        'Assim foi Salomão rei sobre todo o Israel.',
        'E estes eram os príncipes que tinha: Azarias, filho de Zadoque, sacerdote;',
        'Eliorefe e Aías, filhos de Sisa, secretários; Jeosafá, filho de Ailude, cronista;',
        'Benaia, filho de Joiada, sobre o exército; e Zadoque e Abiatar eram sacerdotes;',
        'E Azarias, filho de Natã, sobre os provedores; e Zabude, filho de Natã, oficial-mor, amigo do rei;',
        'E Aisar, mordomo; Adonirão, filho de Abda, sobre o tributo.',
        'E tinha Salomão doze oficiais sobre todo o Israel, que proviam ao rei e à sua casa; e cada um tinha que abastecê-lo por um mês no ano.',
        'E estes são os seus nomes: Ben-Hur, nas montanhas de Efraim;',
        'Ben-Dequer em Macaz, e em Saalbim, e em Bete-Semes, e em Elom, e em Bete-Hanã;',
        'Ben-Hesede em Arubote; também este tinha a Socó e a toda a terra de Hefer;',
        'Ben-Abinadabe em todo o termo de Dor; tinha este a Tafate, filha de Salomão, por mulher;',
        'Baana, filho de Ailude, tinha a Taanaque, e a Megido, e a toda a Bete-Seã, que está junto a Zaretã, abaixo de Jizreel, desde Bete-Seã até Abel-Meolá, para além de Jocmeão;',
        'O filho de Geber, em Ramote de Gileade; tinha este as aldeias de Jair, filho de Manassés, as quais estão em Gileade; também tinha o termo de Argobe, o qual está em Basã, sessenta grandes cidades, com muros e ferrolhos de cobre;',
        'Ainadabe, filho de Ido, em Maanaim.',
        'Aimaás em Naftali; também este tomou a Basemate, filha de Salomão, por mulher;',
        'Baaná, filho de Husai, em Aser e em Alote;',
        'Jeosafá, filho de Parua, em Issacar;',
        'Simei, filho de Elá, em Benjamim:',
        'Geber, filho de Uri, na terra de Gileade, a terra de Siom, rei dos amorreus, e de Ogue, rei de Basã; e só uma guarnição havia naquela terra.',
        'Eram, pois, os de Judá e Israel muitos, como a areia que está junto ao mar em multidão, comendo, e bebendo, e alegrando-se.',
        'E dominava Salomão sobre todos os reinos desde o rio até à terra dos filisteus, e até ao termo do Egito; os quais traziam presentes, e serviram a Salomão todos os dias da sua vida.',
        'Era, pois, o provimento de Salomão cada dia, trinta coros de flor de farinha, e sessenta coros de farinha;',
        'Dez bois cevados, e vinte bois de pasto, e cem carneiros; afora os veados e as cabras montesas, e os corços, e aves cevadas.',
        'Porque dominava sobre tudo quanto havia do lado de cá do rio, Tifsa até Gaza, sobre todos os reis do lado de cá do rio; e tinha paz de todos os lados em redor dele.',
        'E Judá e Israel habitavam seguros, cada um debaixo da sua videira, e debaixo da sua figueira, desde Dã até Berseba, todos os dias de Salomão.',
        'Tinha também Salomão quarenta mil estrebarias de cavalos para os seus carros, e doze mil cavaleiros.',
        'Proviam, pois, estes provedores, cada um no seu mês, ao rei Salomão e a todos quantos se chegaram à mesa do rei Salomão; coisa nenhuma deixavam faltar.',
        'E traziam a cevada e a palha para os cavalos e para os ginetes, para o lugar onde estava, cada um segundo o seu cargo.',
        'E deu Deus a Salomão sabedoria, e muitíssimo entendimento, e largueza de coração, como a areia que está na praia do mar.',
        'E era a sabedoria de Salomão maior do que a sabedoria de todos os do oriente e do que toda a sabedoria dos egípcios.',
        'E era ele ainda mais sábio do que todos os homens, e do que Etã, ezraíta, e Hemã, e Calcol, e Darda, filhos de Maol; e correu o seu nome por todas as nações em redor.',
        'E disse três mil provérbios, e foram os seus cânticos mil e cinco.',
        'Também falou das árvores, desde o cedro que está no Líbano até ao hissopo que nasce na parede; também falou dos animais e das aves, e dos répteis e dos peixes.',
        'E vinham de todos os povos a ouvir a sabedoria de Salomão, e de todos os reis da terra que tinham ouvido da sua sabedoria.',
      ],
      [
        'E enviou Hirão, rei de Tiro, os seus servos a Salomão (porque ouvira que ungiram a Salomão rei em lugar de seu pai), porquanto Hirão sempre tinha amado a Davi.',
        'Então Salomão mandou dizer a Hirão:',
        'Bem sabes tu que Davi, meu pai, não pôde edificar uma casa ao nome do Senhor seu Deus, por causa da guerra com que o cercaram, até que o Senhor pôs seus inimigos debaixo das plantas dos seus pés.',
        'Porém agora o Senhor meu Deus me tem dado descanso de todos os lados; adversário não há, nem algum mau encontro.',
        'E eis que eu intento edificar uma casa ao nome do Senhor meu Deus, como falou o Senhor a Davi, meu pai, dizendo: Teu filho, que porei em teu lugar no teu trono, ele edificará uma casa ao meu nome.',
        'Dá ordem, pois, agora, que do Líbano me cortem cedros, e os meus servos estarão com os teus servos, e eu te darei o salário dos teus servos, conforme a tudo o que disseres; porque bem sabes tu que entre nós ninguém há que saiba cortar a madeira como os sidônios.',
        'E aconteceu que, ouvindo Hirão as palavras de Salomão, muito se alegrou, e disse: Bendito seja hoje o Senhor, que deu a Davi um filho sábio sobre este tão grande povo.',
        'E enviou Hirão a Salomão, dizendo: Ouvi o que me mandaste dizer. Eu farei toda a tua vontade acerca das madeiras de cedro e de cipreste.',
        'Os meus servos as levarão desde o Líbano até ao mar, e eu as farei conduzir em jangadas pelo mar até ao lugar que me designares, e ali as desamarrarei; e tu as tomarás; tu também farás a minha vontade, dando sustento à minha casa.',
        'Assim deu Hirão a Salomão madeira de cedro e madeira de cipreste, conforme a toda a sua vontade.',
        'E Salomão deu a Hirão vinte mil coros de trigo, para sustento da sua casa, e vinte coros de azeite batido; isto dava Salomão a Hirão anualmente.',
        'Deu, pois, o Senhor a Salomão sabedoria, como lhe tinha falado; e houve paz entre Hirão e Salomão, e ambos fizeram acordo.',
        'E o rei Salomão fez subir uma leva de gente dentre todo o Israel, e foi a leva de gente trinta mil homens;',
        'E os enviava ao Líbano, cada mês, dez mil por turno; um mês estavam no Líbano, e dois meses cada um em sua casa; e Adonirão estava sobre a leva de gente.',
        'Tinha também Salomão setenta mil que levavam as cargas, e oitenta mil que talhavam pedras nas montanhas,',
        'Afora os chefes dos oficiais de Salomão, que estavam sobre aquela obra, três mil e trezentos, os quais davam as ordens ao povo que fazia aquela obra.',
        'E mandou o rei que trouxessem pedras grandes, e pedras valiosas, pedras lavradas, para fundarem a casa.',
        'E as lavraram os edificadores de Hirão, e os giblitas; e preparavam a madeira e as pedras para edificar a casa.',
      ],
      [
        'E sucedeu que no ano de quatrocentos e oitenta, depois de saírem os filhos de Israel do Egito, no ano quarto do reinado de Salomão sobre Israel, no mês de Zive (este é o mês segundo), começou a edificar a casa do SENHOR.',
        'E a casa que o rei Salomão edificou ao Senhor era de sessenta côvados de comprimento, e de vinte côvados de largura, e de trinta côvados de altura.',
        'E o pórtico diante do templo da casa era de vinte côvados de comprimento, segundo a largura da casa, e de dez côvados de largura diante da casa.',
        'E fez para a casa janelas de gelósias fixas.',
        'E edificou câmaras junto ao muro da casa, contra as paredes da casa, em redor, tanto do templo como do oráculo; e assim lhe fez câmaras laterais em redor.',
        'A câmara de baixo era de cinco côvados de largura, e a do meio de seis côvados de largura, e a terceira de sete côvados de largura; porque pela parte de fora da casa, em redor, fizera encostos, para que as vigas não se apoiassem nas paredes da casa.',
        'E edificava-se a casa com pedras preparadas, como as traziam se edificava; de maneira que nem martelo, nem machado, nem nenhum outro instrumento de ferro se ouviu na casa quando a edificavam.',
        'A porta da câmara do meio estava ao lado direito da casa, e por caracóis se subia à do meio, e da do meio à terceira.',
        'Assim, pois, edificou a casa, e a acabou; e cobriu a casa com pranchões e tabuados de cedro.',
        'Também edificou as câmaras em volta de toda a casa, de cinco côvados de altura, e as ligou à casa com madeira de cedro.',
        'Então veio a palavra do Senhor a Salomão, dizendo:',
        'Quanto a esta casa que tu edificas, se andares nos meus estatutos, e fizeres os meus juízos, e guardares todos os meus mandamentos, andando neles, confirmarei para contigo a minha palavra, a qual falei a Davi, teu pai;',
        'E habitarei no meio dos filhos de Israel, e não desampararei o meu povo de Israel.',
        'Assim edificou Salomão aquela casa, e a acabou.',
        'Também cobriu as paredes da casa por dentro com tábuas de cedro; desde o soalho da casa até ao teto tudo cobriu com madeira por dentro; e cobriu o soalho da casa com tábuas de cipreste.',
        'Edificou mais vinte côvados de tábuas de cedro nos lados da casa, desde o soalho até às paredes; e por dentro lhas edificou para o oráculo, para o Santo dos Santos.',
        'A casa, isto é, o templo anterior tinha quarenta côvados.',
        'E o cedro da casa por dentro era lavrado de botões e flores abertas; tudo era cedro, pedra nenhuma se via.',
        'E por dentro da casa, na parte mais interior, preparou o oráculo, para pôr ali a arca da aliança do Senhor.',
        'E o oráculo no interior era de vinte côvados de comprimento, e de vinte côvados de largura, e de vinte côvados de altura; e o revestiu de ouro puro; também revestiu de cedro o altar.',
        'E revestiu Salomão a casa por dentro de ouro puro; e com cadeias de ouro pôs uma cortina diante do oráculo, e o revestiu com ouro.',
        'Assim cobriu de ouro toda a casa, inteiramente; também cobriu de ouro todo o altar que estava diante do oráculo.',
        'E no oráculo fez dois querubins de madeira de oliveira, cada um da altura de dez côvados.',
        'E uma asa de um querubim era de cinco côvados, e a outra asa do querubim de outros cinco côvados; dez côvados havia desde a extremidade de uma das suas asas até à extremidade da outra das suas asas.',
        'Assim era também de dez côvados o outro querubim; ambos os querubins eram de uma mesma medida e de um mesmo talhe.',
        'A altura de um querubim era de dez côvados, e assim a do outro querubim.',
        'E pôs a estes querubins no meio da casa de dentro; e os querubins estendiam as asas, de maneira que a asa de um tocava na parede, e a asa do outro querubim tocava na outra parede; e as suas asas no meio da casa tocavam uma na outra.',
        'E revestiu de ouro os querubins.',
        'E todas as paredes da casa, em redor, lavrou de esculturas e entalhes de querubins, e de palmas, e de flores abertas, por dentro e por fora.',
        'Também revestiu de ouro o soalho da casa, por dentro e por fora.',
        'E à entrada do oráculo fez portas de madeira de oliveira; o umbral de cima com as ombreiras faziam a quinta parte da parede.',
        'Também as duas portas eram de madeira de oliveira; e lavrou nelas entalhes de querubins, e de palmas, e de flores abertas, os quais revestiu de ouro; também estendeu ouro sobre os querubins e sobre as palmas.',
        'E assim fez à porta do templo ombreiras de madeira de oliveira, da quarta parte da parede.',
        'E eram as duas portas de madeira de cipreste; e as duas folhas de uma porta eram dobradiças, assim como eram também dobradiças as duas folhas entalhadas das outras portas.',
        'E as lavrou de querubins e de palmas, e de flores abertas, e as revestiu de ouro acomodado ao lavor.',
        'Também edificou o pátio interior de três ordens de pedras lavradas e de uma ordem de vigas de cedro.',
        'No ano quarto se pôs o fundamento da casa do Senhor, no mês de Zive.',
        'E no ano undécimo, no mês de Bul, que é o mês oitavo, se acabou esta casa com todas as suas coisas, e com tudo o que lhe convinha; e a edificou em sete anos.',
      ],
      [
        'Porém a sua casa edificou Salomão em treze anos; e acabou toda a sua casa.',
        'Também edificou a casa do bosque do Líbano de cem côvados de comprimento, e de cinqüenta côvados de largura, e de trinta côvados de altura, sobre quatro ordens de colunas de cedro, e vigas de cedro sobre as colunas.',
        'E por cima estava coberta de cedro sobre as vigas, que estavam sobre quarenta e cinco colunas, quinze em cada ordem.',
        'E havia três ordens de janelas; e uma janela estava defronte da outra janela, em três ordens.',
        'Também todas as portas e ombreiras quadradas eram de uma mesma vista; e uma janela estava defronte da outra, em três ordens.',
        'Depois fez um pórtico de colunas de cinqüenta côvados de comprimento e de trinta côvados de largura; e o pórtico estava defronte delas, e as colunas com as grossas vigas defronte delas.',
        'Também fez o pórtico para o trono onde julgava, isto é, o pórtico do juízo, que estava revestido de cedro de soalho a soalho.',
        'E em sua casa, em que morava, havia outro pátio, por dentro do pórtico, de obra semelhante à deste; também para a filha de Faraó, que Salomão tomara por mulher, fez uma casa semelhante àquele pórtico.',
        'Todas estas coisas eram de pedras de grande valor, cortadas à medida, serradas à serra por dentro e por fora; e isto desde o fundamento até às beiras do teto, e por fora até ao grande pátio.',
        'Também estava fundado sobre pedras finas, pedras grandes; sobre pedras de dez côvados e pedras de oito côvados.',
        'E em cima delas pedras de grande valor, lavradas segundo as medidas, e madeira de cedro.',
        'Havia três ordens de pedras lavradas, com uma ordem de vigas de cedro; assim era também o pátio interior da casa do Senhor e o pórtico daquela casa.',
        'E enviou o rei Salomão um mensageiro e mandou trazer a Hirão de Tiro.',
        'Era ele filho de uma mulher viúva, da tribo de Naftali, e fora seu pai um homem de Tiro, que trabalhava em cobre; e era cheio de sabedoria, e de entendimento, e de ciência para fazer toda a obra de cobre; este veio ao rei Salomão, e fez toda a sua obra.',
        'E formou duas colunas de cobre; a altura de cada coluna era de dezoito côvados, e um fio de doze côvados cercava cada uma das colunas.',
        'Também fez dois capitéis de fundição de cobre para pôr sobre as cabeças das colunas; de cinco côvados era a altura de um capitel, e de cinco côvados a altura do outro capitel.',
        'As redes eram de malhas, as ligas de obra de cadeia para os capitéis que estavam sobre a cabeça das colunas, sete para um capitel e sete para o outro capitel.',
        'Assim fez as colunas, juntamente com duas fileiras em redor sobre uma rede, para cobrir os capitéis que estavam sobre a cabeça das romãs, assim também fez com o outro capitel.',
        'E os capitéis que estavam sobre a cabeça das colunas eram de obra de lírios no pórtico, de quatro côvados.',
        'Os capitéis, pois, sobre as duas colunas estavam também defronte, em cima da parte globular que estava junto à rede; e duzentas romãs, em fileiras em redor, estavam também sobre o outro capitel.',
        'Depois levantou as colunas no pórtico do templo; e levantando a coluna direita, pôs-lhe o nome de Jaquim; e levantando a coluna esquerda, pôs-lhe o nome de Boaz.',
        'E sobre a cabeça das colunas estava a obra de lírios; e assim se acabou a obra das colunas.',
        'Fez mais o mar de fundição, de dez côvados de uma borda até à outra borda, perfeitamente redondo, e de cinco côvados de alto; e um cordão de trinta côvados o cingia em redor.',
        'E por baixo da sua borda em redor havia botões que o cingiam; por dez côvados cercavam aquele mar em redor; duas ordens destes botões foram fundidas quando o mar foi fundido.',
        'E firmava-se sobre doze bois, três que olhavam para o norte, e três que olhavam para o ocidente, e três que olhavam para o sul, e três que olhavam para o oriente; e o mar estava em cima deles, e todas as suas partes posteriores para o lado de dentro.',
        'E a grossura era de um palmo, e a sua borda era como a de um copo, como de flor de lírios; ele levava dois mil batos.',
        'Fez também as dez bases de cobre; o comprimento de uma base de quatro côvados, e de quatro côvados a sua largura, e três côvados a sua altura.',
        'E esta era a obra das bases; tinham cintas, e as cintas estavam entre as molduras.',
        'E sobre as cintas que estavam entre as molduras havia leões, bois, e querubins, e sobre as molduras uma base por cima; e debaixo dos leões e dos bois junturas de obra estendida.',
        'E uma base tinha quatro rodas de metal, e lâminas de cobre; e os seus quatro cantos tinham suportes; debaixo da pia estavam estes suportes fundidos, do lado de cada uma das junturas.',
        'E a boca da pia estava dentro da coroa, e de um côvado por cima; e era a sua boca redonda segundo a obra da base, de côvado e meio; e também sobre a sua boca havia entalhes, e as suas cintas eram quadradas, não redondas.',
        'E as quatro rodas estavam debaixo das cintas, e os eixos das rodas na base; e era a altura de cada roda de côvado e meio.',
        'E era a obra das rodas como a obra da roda de carro; seus eixos, e suas cambas e seus cubos, e seus raios, todos eram fundidos.',
        'E havia quatro suportes aos quatro cantos de cada base; seus suportes saíam da base.',
        'E no alto de cada base havia uma peça redonda de meio côvado de altura; também sobre o alto de cada base havia asas e cintas, que saíam delas.',
        'E nas placas de seus esteios e nas suas cintas lavrou querubins, leões, e palmas, segundo o espaço de cada uma, e outros adornos em redor.',
        'Conforme a esta fez as dez bases; todas tinham uma mesma fundição, uma mesma medida, e um mesmo entalhe.',
        'Também fez dez pias de cobre; em cada pia cabiam quarenta batos, e cada pia era de quatro côvados, e sobre cada uma das dez bases estava uma pia.',
        'E pôs cinco bases à direita da casa, e cinco à esquerda da casa; porém o mar pôs ao lado direito da casa para o lado do oriente, da parte do sul.',
        'Depois fez Hirão as pias, e as pás, e as bacias; e acabou Hirão de fazer toda a obra que fez para o rei Salomão, para a casa do Senhor.',
        'A saber: as duas colunas, e os globos dos capitéis que estavam sobre a cabeça das duas colunas; e as duas redes, para cobrir os dois globos dos capitéis que estavam sobre a cabeça das colunas.',
        'E as quatrocentas romãs para as duas redes, a saber: duas carreiras de romãs para cada rede, para cobrirem os dois globos dos capitéis que estavam em cima das colunas.',
        'Juntamente com as dez bases, e as dez pias sobre as bases;',
        'Como também um mar, e os doze bois debaixo daquele mar;',
        'E os caldeirões, e as pás, e as bacias, e todos estes objetos que fez Hirão para o rei Salomão, para a casa do Senhor, todos eram de cobre polido.',
        'Na planície do Jordão, o rei os fundiu em terra barrenta; entre Sucote e Zaretã.',
        'E deixou Salomão de pesar todos os objetos, pelo seu excessivo número; nem se averiguou o peso do cobre.',
        'Também fez Salomão todos os objetos que convinham à casa do Senhor; o altar de ouro, e a mesa de ouro, sobre a qual estavam os pães da proposição.',
        'E os castiçais, cinco à direita e cinco à esquerda, diante do oráculo, de ouro finíssimo; e as flores, e as lâmpadas, e os espevitadores, também de ouro.',
        'Como também os vasos, e os apagadores, e as bacias, e as colheres, e os perfumadores, de ouro finíssimo; e as dobradiças para as portas da casa interior para o lugar santíssimo, e as das portas da casa do templo, também de ouro.',
        'Assim se acabou toda a obra que fez o rei Salomão para a casa do Senhor; então trouxe Salomão as coisas que seu pai Davi havia consagrado; a prata, e o ouro, e os objetos pôs entre os tesouros da casa do Senhor.',
      ],
      [
        'Então congregou Salomão os anciãos de Israel, e todos os cabeças das tribos, os chefes dos pais dos filhos de Israel, diante de si em Jerusalém; para fazerem subir a arca da aliança do SENHOR da cidade de Davi, que é Sião.',
        'E todos os homens de Israel se congregaram ao rei Salomão, na ocasião da festa, no mês de Etanim, que é o sétimo mês.',
        'E vieram todos os anciãos de Israel; e os sacerdotes alçaram a arca.',
        'E trouxeram a arca do Senhor para cima, e o tabernáculo da congregação, juntamente com todos os objetos sagrados que havia no tabernáculo; assim os trouxeram para cima os sacerdotes e os levitas.',
        'E o rei Salomão, e toda a congregação de Israel que se congregara a ele, estava com ele diante da arca, sacrificando ovelhas e vacas, que não se podiam contar nem numerar pela sua quantidade.',
        'Assim trouxeram os sacerdotes a arca da aliança do Senhor ao seu lugar, ao oráculo da casa, ao lugar santíssimo, até debaixo das asas dos querubins.',
        'Porque os querubins estendiam ambas as asas sobre o lugar da arca; e os querubins cobriam, por cima, a arca e os seus varais.',
        'E os varais sobressaíram tanto, que as pontas dos varais se viam desde o santuário diante do oráculo, porém de fora não se viam; e ficaram ali até ao dia de hoje.',
        'Na arca nada havia, senão só as duas tábuas de pedra, que Moisés ali pusera junto a Horebe, quando o Senhor fez a aliança com os filhos de Israel, saindo eles da terra do Egito.',
        'E sucedeu que, saindo os sacerdotes do santuário, uma nuvem encheu a casa do Senhor.',
        'E os sacerdotes não podiam permanecer em pé para ministrar, por causa da nuvem, porque a glória do Senhor enchera a casa do Senhor.',
        'Então falou Salomão: O Senhor disse que ele habitaria nas trevas.',
        'Certamente te edifiquei uma casa para morada, assento para a tua eterna habitação.',
        'Então virou o rei o seu rosto, e abençoou toda a congregação de Israel; e toda a congregação de Israel estava em pé.',
        'E disse: Bendito seja o Senhor Deus de Israel, que falou pela sua boca a Davi, meu pai, e pela sua mão o cumpriu, dizendo:',
        'Desde o dia em que eu tirei o meu povo Israel do Egito, não escolhi cidade alguma de todas as tribos de Israel, para edificar alguma casa para ali estabelecer o meu nome; porém escolhi a Davi, para que presidisse sobre o meu povo Israel.',
        'Também Davi, meu pai, propusera em seu coração o edificar casa ao nome do Senhor Deus de Israel.',
        'Porém o Senhor disse a Davi, meu pai: Porquanto propuseste no teu coração o edificar casa ao meu nome, bem fizeste em o propor no teu coração.',
        'Todavia tu não edificarás esta casa; porém teu filho, que sair de teus lombos, edificará esta casa ao meu nome.',
        'Assim confirmou o Senhor a sua palavra que falou; porque me levantei em lugar de Davi, meu pai, e me assentei no trono de Israel, como tem falado o Senhor; e edifiquei uma casa ao nome do Senhor Deus de Israel.',
        'E constituí ali lugar para a arca em que está a aliança do Senhor, a qual fez com nossos pais, quando os tirou da terra do Egito.',
        'E pôs-se Salomão diante do altar do Senhor, na presença de toda a congregação de Israel; e estendeu as suas mãos para os céus,',
        'E disse: Ó Senhor Deus de Israel, não há Deus como tu, em cima nos céus nem em baixo na terra; que guardas a aliança e a beneficência a teus servos que andam com todo o seu coração diante de ti.',
        'Que guardaste a teu servo Davi, meu pai, o que lhe disseras; porque com a tua boca o disseste, e com a tua mão o cumpriste, como neste dia se vê.',
        'Agora, pois, ó Senhor Deus de Israel, guarda a teu servo Davi, meu pai, o que lhe falaste, dizendo: Não te faltará sucessor diante de mim, que se assente no trono de Israel; somente que teus filhos guardem o seu caminho, para andarem diante de mim como tu andaste diante de mim.',
        'Agora também, ó Deus de Israel, cumpra-se a tua palavra que disseste a teu servo Davi, meu pai.',
        'Mas, na verdade, habitaria Deus na terra? Eis que os céus, e até o céu dos céus, não te poderiam conter, quanto menos esta casa que eu tenho edificado.',
        'Volve-te, pois, para a oração de teu servo, e para a sua súplica, ó Senhor meu Deus, para ouvires o clamor e a oração que o teu servo hoje faz diante de ti.',
        'Para que os teus olhos noite e dia estejam abertos sobre esta casa, sobre este lugar, do qual disseste: O meu nome estará ali; para ouvires a oração que o teu servo fizer neste lugar.',
        'Ouve, pois, a súplica do teu servo, e do teu povo Israel, quando orarem neste lugar; também ouve tu no lugar da tua habitação nos céus; ouve também, e perdoa.',
        'Quando alguém pecar contra o seu próximo, e puserem sobre ele juramento de maldição, fazendo-o jurar, e vier juramento de maldição diante do teu altar nesta casa,',
        'Ouve tu, então, nos céus e age e julga a teus servos, condenando ao injusto, fazendo recair o seu proceder sobre a sua cabeça, e justificando ao justo, rendendo-lhe segundo a sua justiça.',
        'Quando o teu povo Israel for ferido diante do inimigo, por ter pecado contra ti, e se converterem a ti, e confessarem o teu nome, e orarem e suplicarem a ti nesta casa,',
        'Ouve tu então nos céus, e perdoa o pecado do teu povo Israel, e torna-o a levar à terra que tens dado a seus pais.',
        'Quando os céus se fechar, e não houver chuva, por terem pecado contra ti, e orarem neste lugar, e confessarem o teu nome, e se converterem dos seus pecados, havendo-os tu afligido,',
        'Ouve tu então nos céus, e perdoa o pecado de teus servos e do teu povo Israel, ensinando-lhes o bom caminho em que andem, e dá chuva na tua terra que deste ao teu povo em herança.',
        'Quando houver fome na terra, quando houver peste, quando houver queima de searas, ferrugem, gafanhotos ou pulgão, quando o seu inimigo o cercar na terra das suas portas, ou houver alguma praga ou doença,',
        'Toda a oração, toda a súplica, que qualquer homem de todo o teu povo Israel fizer, conhecendo cada um a chaga do seu coração, e estendendo as suas mãos para esta casa,',
        'Ouve tu então nos céus, assento da tua habitação, e perdoa, e age, e dá a cada um conforme a todos os seus caminhos, e segundo vires o seu coração, porque só tu conheces o coração de todos os filhos dos homens.',
        'Para que te temam todos os dias que viverem na terra que deste a nossos pais.',
        'E também ouve ao estrangeiro, que não for do teu povo Israel, quando vier de terras remotas, por amor do teu nome',
        '(Porque ouvirão do teu grande nome, e da tua forte mão, e do teu braço estendido), e vier orar voltado para esta casa,',
        'Ouve tu nos céus, assento da tua habitação, e faze conforme a tudo o que o estrangeiro a ti clamar, a fim de que todos os povos da terra conheçam o teu nome, para te temerem como o teu povo Israel, e para saberem que o teu nome é invocado sobre esta casa que tenho edificado.',
        'Quando o teu povo sair à guerra contra o seu inimigo, pelo caminho por que os enviares, e orarem ao Senhor, para o lado desta cidade, que tu elegeste, e desta casa, que edifiquei ao teu nome,',
        'Ouve, então, nos céus a sua oração e a sua súplica, e faze-lhes justiça.',
        'Quando pecarem contra ti (pois não há homem que não peque), e tu te indignares contra eles, e os entregares às mãos do inimigo, de modo que os levem em cativeiro para a terra inimiga, quer longe ou perto esteja,',
        'E na terra aonde forem levados em cativeiro caírem em si, e se converterem, e na terra do seu cativeiro te suplicarem, dizendo: Pecamos, e perversamente procedemos, e cometemos iniqüidade,',
        'E se converterem a ti com todo o seu coração e com toda a sua alma, na terra de seus inimigos que os levarem em cativeiro, e orarem a ti para o lado da sua terra que deste a seus pais, para esta cidade que elegeste, e para esta casa que edifiquei ao teu nome;',
        'Ouve então nos céus, assento da tua habitação, a sua oração e a sua súplica, e faze-lhes justiça.',
        'E perdoa ao teu povo que houver pecado contra ti, todas as transgressões que houverem cometido contra ti; e dá-lhes misericórdia perante aqueles que os têm cativos, para que deles tenham compaixão.',
        'Porque são o teu povo e a tua herança que tiraste da terra do Egito, do meio do forno de ferro.',
        'Para que teus olhos estejam abertos à súplica do teu servo e à súplica do teu povo Israel, a fim de os ouvires em tudo quanto clamarem a ti.',
        'Pois tu para tua herança os elegeste de todos os povos da terra, como tens falado pelo ministério de Moisés, teu servo, quando tiraste a nossos pais do Egito, Senhor DEUS.',
        'Sucedeu, pois, que, acabando Salomão de fazer ao Senhor esta oração e esta súplica, estando de joelhos e com as mãos estendidas para os céus, se levantou de diante do altar do Senhor.',
        'E pôs-se em pé, e abençoou a toda a congregação de Israel em alta voz, dizendo:',
        'Bendito seja o Senhor, que deu repouso ao seu povo Israel, segundo tudo o que disse; nem uma só palavra caiu de todas as suas boas palavras que falou pelo ministério de Moisés, seu servo.',
        'O Senhor nosso Deus seja conosco, como foi com nossos pais; não nos desampare, e não nos deixe.',
        'Inclinando a si o nosso coração, para andar em todos os seus caminhos, e para guardar os seus mandamentos, e os seus estatutos, e os seus juízos que ordenou a nossos pais.',
        'E que estas minhas palavras, com que supliquei perante o Senhor, estejam perto, diante do Senhor nosso Deus, de dia e de noite, para que execute o juízo do seu servo e o juízo do seu povo Israel, a cada qual no seu dia.',
        'Para que todos os povos da terra saibam que o Senhor é Deus, e que não há outro.',
        'E seja o vosso coração inteiro para com o Senhor nosso Deus, para andardes nos seus estatutos, e guardardes os seus mandamentos como hoje.',
        'E o rei e todo o Israel com ele ofereceram sacrifícios perante a face do Senhor.',
        'E deu Salomão para o sacrifício pacífico que ofereceu ao Senhor, vinte e duas mil vacas e cento e vinte mil ovelhas; assim o rei e todos os filhos de Israel consagraram a casa do Senhor.',
        'No mesmo dia santificou o rei o meio do átrio que estava diante da casa do Senhor; porquanto ali preparara os holocaustos e as ofertas com a gordura dos sacrifícios pacíficos; porque o altar de cobre que estava diante da face do Senhor era muito pequeno para nele caberem os holocaustos e as ofertas, e a gordura dos sacrifícios pacíficos.',
        'No mesmo tempo celebrou Salomão a festa, e todo o Israel com ele, uma grande congregação, desde a entrada de Hamate até ao rio do Egito, perante a face do Senhor nosso Deus; por sete dias, e mais sete dias; catorze dias.',
        'E no oitavo dia despediu o povo, e eles abençoaram o rei; então se foram às suas tendas, alegres e felizes de coração, por causa de todo o bem que o Senhor fizera a Davi seu servo, e a Israel seu povo.',
      ],
      [
        'Sucedeu, pois, que, acabando Salomão de edificar a casa do SENHOR, e a casa do rei, e todo o desejo de Salomão, que lhe veio à vontade fazer,',
        'O Senhor tornou a aparecer a Salomão; como lhe tinha aparecido em Gibeom.',
        'E o Senhor lhe disse: Ouvi a tua oração, e a súplica que fizeste perante mim; santifiquei a casa que edificaste, a fim de pôr ali o meu nome para sempre; e os meus olhos e o meu coração estarão ali todos os dias.',
        'E se tu andares perante mim como andou Davi, teu pai, com inteireza de coração e com sinceridade, para fazeres segundo tudo o que te mandei, e guardares os meus estatutos e os meus juízos,',
        'Então confirmarei o trono de teu reino sobre Israel para sempre; como falei acerca de teu pai Davi, dizendo: Não te faltará sucessor sobre o trono de Israel;',
        'Porém, se vós e vossos filhos de qualquer maneira vos apartardes de mim, e não guardardes os meus mandamentos, e os meus estatutos, que vos tenho proposto, mas fordes, e servirdes a outros deuses, e vos prostrardes perante eles,',
        'Então destruirei a Israel da terra que lhes dei; e a esta casa, que santifiquei a meu nome, lançarei longe da minha presença; e Israel será por provérbio e motejo, entre todos os povos.',
        'E desta casa, que é tão exaltada, todo aquele que por ela passar pasmará, e assobiará, e dirá: Por que fez o Senhor assim a esta terra e a esta casa?',
        'E dirão: Porque deixaram ao Senhor seu Deus, que tirou da terra do Egito a seus pais, e se apegaram a deuses alheios, e se encurvaram perante eles, e os serviram; por isso trouxe o Senhor sobre eles todo este mal.',
        'E sucedeu, ao fim de vinte anos, que Salomão edificara as duas casas; a casa do Senhor e a casa do rei',
        '(Para o que Hirão, rei de Tiro, trouxera a Salomão madeira de cedro e de cipreste, e ouro, segundo todo o seu desejo); então deu o rei Salomão a Hirão vinte cidades na terra da Galiléia.',
        'E saiu Hirão de Tiro a ver as cidades que Salomão lhe dera, porém não foram boas aos seus olhos.',
        'Por isso disse: Que cidades são estas que me deste, irmão meu? E chamaram-nas: Terra de Cabul, até hoje.',
        'E enviara Hirão ao rei cento e vinte talentos de ouro.',
        'E esta é a causa do tributo que impôs o rei Salomão, para edificar a casa do Senhor e a sua casa, e Milo, e o muro de Jerusalém, como também a Hasor, e a Megido, e a Gezer.',
        'Porque Faraó, rei do Egito, subiu e tomou a Gezer, e a queimou a fogo, e matou os cananeus que moravam na cidade, e a deu em dote à sua filha, mulher de Salomão.',
        'Assim edificou Salomão a Gezer, e Bete-Horom, a baixa,',
        'E a Baalate, e a Tadmor, no deserto daquela terra,',
        'E a todas as cidades de provisões que Salomão tinha, e as cidades dos carros, e as cidades dos cavaleiros, e tudo o que Salomão quis edificar em Jerusalém, e no Líbano, e em toda a terra do seu domínio.',
        'Quanto a todo o povo que restou dos amorreus, heteus, perizeus, heveus, e jebuseus, e que não eram dos filhos de Israel,',
        'A seus filhos, que restaram depois deles na terra, os quais os filhos de Israel não puderam destruir totalmente, Salomão os reduziu a tributo servil, até hoje.',
        'Porém dos filhos de Israel não fez Salomão servo algum; porém eram homens de guerra, e seus criados, e seus príncipes, e seus capitães, e chefes dos seus carros e dos seus cavaleiros.',
        'Estes eram os chefes dos oficiais que estavam sobre a obra de Salomão, quinhentos e cinqüenta, que davam ordens ao povo que trabalhava na obra.',
        'Subiu, porém, a filha de Faraó da cidade de Davi, à sua casa, que Salomão lhe edificara; então edificou a Milo.',
        'E oferecia Salomão três vezes cada ano holocaustos e sacrifícios pacíficos sobre o altar que edificaram ao Senhor, e queimava incenso sobre o que estava perante o Senhor; e assim acabou a casa.',
        'Também o rei Salomão fez naus em Eziom-Geber, que está junto a Elate, à praia do mar de Sufe, na terra de Edom.',
        'E mandou Hirão com aquelas naus a seus servos, marinheiros, que sabiam do mar, com os servos de Salomão.',
        'E vieram a Ofir, e tomaram de lá quatrocentos e vinte talentos de ouro, e os trouxeram ao rei Salomão.',
      ],
      [
        'E ouvindo a rainha de Sabá a fama de Salomão, acerca do nome do SENHOR, veio prová-lo com questões difíceis.',
        'E chegou a Jerusalém com uma grande comitiva; com camelos carregados de especiarias, e muitíssimo ouro, e pedras preciosas; e foi a Salomão, e disse-lhe tudo quanto tinha no seu coração.',
        'E Salomão lhe deu resposta a todas as suas perguntas, nada houve que não lhe pudesse esclarecer.',
        'Vendo, pois, a rainha de Sabá toda a sabedoria de Salomão, e a casa que edificara,',
        'E a comida da sua mesa, e o assentar de seus servos, e o estar de seus criados, e as vestes deles, e os seus copeiros, e os holocaustos que ele oferecia na casa do Senhor, ficou fora de si.',
        'E disse ao rei: Era verdade a palavra que ouvi na minha terra, dos teus feitos e da tua sabedoria.',
        'E eu não cria naquelas palavras, até que vim e os meus olhos o viram; eis que não me disseram metade; sobrepujaste em sabedoria e bens a fama que ouvi.',
        'Bem-aventurados os teus homens, bem-aventurados estes teus servos, que estão sempre diante de ti, que ouvem a tua sabedoria!',
        'Bendito seja o Senhor teu Deus, que teve agrado em ti, para te pôr no trono de Israel; porque o Senhor ama a Israel para sempre, por isso te estabeleceu rei, para fazeres juízo e justiça.',
        'E deu ao rei cento e vinte talentos de ouro, e muitíssimas especiarias, e pedras preciosas; nunca veio especiaria em tanta abundância, como a que a rainha de Sabá deu ao rei Salomão.',
        'Também as naus de Hirão, que de Ofir levavam ouro, traziam de Ofir muita madeira de almugue, e pedras preciosas.',
        'E desta madeira de almugue fez o rei balaústres para a casa do Senhor, e para a casa do rei, como também harpas e alaúdes para os cantores; nunca veio tal madeira de almugue, nem se viu até o dia de hoje.',
        'E o rei Salomão deu à rainha de Sabá tudo o que ela desejou, tudo quanto pediu, além do que dera por sua generosidade; então voltou e partiu para a sua terra, ela e os seus servos.',
        'E o peso do ouro que se trazia a Salomão cada ano era de seiscentos e sessenta e seis talentos de ouro;',
        'Além do que entrava dos negociantes, e do contrato dos especieiros, e de todos os reis da Arábia, e dos governadores da mesma terra.',
        'Também o rei Salomão fez duzentos paveses de ouro batido; seiscentos siclos de ouro destinou para cada pavês;',
        'Fez também trezentos escudos de ouro batido; três arráteis de ouro destinou para cada escudo; e o rei os pôs na casa do bosque do Líbano.',
        'Fez mais o rei um grande trono de marfim, e o revestiu de ouro puríssimo.',
        'Tinha este trono seis degraus, e era o alto do trono por detrás redondo, e de ambos os lados tinha encostos até ao assento; e dois leões, em pé, juntos aos encostos.',
        'Também doze leões estavam ali sobre os seis degraus de ambos os lados; nunca se tinha feito obra semelhante em nenhum dos reinos.',
        'Também todas as taças de beber do rei Salomão eram de ouro, e todos os vasos da casa do bosque do Líbano eram de ouro puro; não havia neles prata, porque nos dias de Salomão não tinha valor algum.',
        'Porque o rei tinha no mar as naus de Társis, com as naus de Hirão; uma vez em três anos tornavam as naus de Társis, e traziam ouro e prata, marfim, e bugios, e pavões.',
        'Assim o rei Salomão excedeu a todos os reis da terra, tanto em riquezas como em sabedoria.',
        'E toda a terra buscava a face de Salomão, para ouvir a sabedoria que Deus tinha posto no seu coração.',
        'E cada um trazia o seu presente, vasos de prata e vasos de ouro, e roupas, e armaduras, e especiarias, cavalos e mulas; isso faziam de ano em ano.',
        'Também ajuntou Salomão carros e cavaleiros, de sorte que tinha mil e quatrocentos carros e doze mil cavaleiros; e os levou às cidades dos carros, e junto ao rei em Jerusalém.',
        'E fez o rei que em Jerusalém houvesse prata como pedras; e cedros em abundância como sicômoros que estão nas planícies.',
        'E traziam do Egito, para Salomão, cavalos e fio de linho; e os mercadores do rei recebiam o fio de linho, por um certo preço.',
        'E subia e saía um carro do Egito por seiscentos siclos de prata, e um cavalo por cento e cinqüenta; e assim, por meio deles, eram exportados para todos os reis dos heteus e para os reis da Síria.',
      ],
      [
        'E o rei Salomão amou muitas mulheres estrangeiras, além da filha de Faraó: moabitas, amonitas, edomitas, sidônias e hetéias,',
        'Das nações de que o Senhor tinha falado aos filhos de Israel: Não chegareis a elas, e elas não chegarão a vós; de outra maneira perverterão o vosso coração para seguirdes os seus deuses. A estas se uniu Salomão com amor.',
        'E tinha setecentas mulheres, princesas, e trezentas concubinas; e suas mulheres lhe perverteram o coração.',
        'Porque sucedeu que, no tempo da velhice de Salomão, suas mulheres lhe perverteram o coração para seguir outros deuses; e o seu coração não era perfeito para com o Senhor seu Deus, como o coração de Davi, seu pai,',
        'Porque Salomão seguiu a Astarote, deusa dos sidônios, e Milcom, a abominação dos amonitas.',
        'Assim fez Salomão o que parecia mal aos olhos do Senhor; e não perseverou em seguir ao Senhor, como Davi, seu pai.',
        'Então edificou Salomão um alto a Quemós, a abominação dos moabitas, sobre o monte que está diante de Jerusalém, e a Moloque, a abominação dos filhos de Amom.',
        'E assim fez para com todas as suas mulheres estrangeiras, as quais queimavam incenso e sacrificavam a seus deuses.',
        'Pelo que o Senhor se indignou contra Salomão; porquanto desviara o seu coração do Senhor Deus de Israel, o qual duas vezes lhe aparecera.',
        'E acerca deste assunto lhe tinha dado ordem que não seguisse a outros deuses; porém não guardou o que o Senhor lhe ordenara.',
        'Assim disse o Senhor a Salomão: Pois que houve isto em ti, que não guardaste a minha aliança e os meus estatutos que te mandei, certamente rasgarei de ti este reino, e o darei a teu servo.',
        'Todavia nos teus dias não o farei, por amor de Davi, teu pai; da mão de teu filho o rasgarei;',
        'Porém todo o reino não rasgarei; uma tribo darei a teu filho, por amor de meu servo Davi, e por amor a Jerusalém, que tenho escolhido.',
        'Levantou, pois, o Senhor contra Salomão um adversário, Hadade, o edomeu; ele era da descendência do rei em Edom.',
        'Porque sucedeu que, estando Davi em Edom, e subindo Joabe, o capitão do exército, a enterrar os mortos, feriu a todo o homem em Edom',
        '(Porque Joabe ficou ali seis meses com todo o Israel, até que destruiu a todo o homem em Edom).',
        'Hadade, porém, fugiu, ele e alguns homens edomeus, dos servos de seu pai, com ele, para ir ao Egito; era, porém, Hadade muito jovem.',
        'E levantaram-se de Midiã, e foram a Parã, e tomaram consigo homens de Parã, e foram ao Egito ter com Faraó, rei do Egito, o qual lhe deu uma casa, e lhe prometeu sustento, e lhe deu uma terra.',
        'E achou Hadade grande graça diante de Faraó, de maneira que lhe deu por mulher a irmã de sua mulher, a irmã de Tafnes, a rainha.',
        'E a irmã de Tafnes deu-lhe um filho, Genubate, o qual Tafnes criou na casa de Faraó; e Genubate estava na casa de Faraó, entre os filhos de Faraó.',
        'Ouvindo, pois, Hadade, no Egito, que Davi adormecera com seus pais, e que Joabe, capitão do exército, era morto, disse Hadade a Faraó: Despede-me, para que vá à minha terra.',
        'Porém Faraó lhe disse: Pois que te falta comigo, que procuras partir para a tua terra? E disse ele: Nada, mas todavia despede-me.',
        'Também Deus lhe levantou outro adversário, a Rezom, filho de Eliada, que tinha fugido de seu senhor Hadadezer, rei de Zobá,',
        'Contra quem também ajuntou homens, e foi capitão de um esquadrão, quando Davi os matou; e, indo-se para Damasco, habitaram ali, e reinaram em Damasco.',
        'E foi adversário de Israel, por todos os dias de Salomão, e isto além do mal que Hadade fazia; porque detestava a Israel, e reinava sobre a Síria.',
        'Até Jeroboão, filho de Nebate, efrateu, de Zereda, servo de Salomão (cuja mãe era mulher viúva, por nome Zerua), também levantou a mão contra o rei.',
        'E esta foi a causa por que levantou a mão contra o rei: Salomão tinha edificado a Milo, e cerrou as aberturas da cidade de Davi, seu pai.',
        'E o homem Jeroboão era forte e valente; e vendo Salomão a este jovem, que era laborioso, ele o pôs sobre todo o cargo da casa de José.',
        'Sucedeu, pois, naquele tempo que, saindo Jeroboão de Jerusalém, o profeta Aías, o silonita, o encontrou no caminho, e ele estava vestido com uma roupa nova, e os dois estavam sós no campo.',
        'E Aías pegou na roupa nova que tinha sobre si, e a rasgou em doze pedaços.',
        'E disse a Jeroboão: Toma para ti os dez pedaços, porque assim diz o Senhor Deus de Israel: Eis que rasgarei o reino da mão de Salomão, e a ti darei as dez tribos.',
        'Porém ele terá uma tribo, por amor de Davi, meu servo, e por amor de Jerusalém, a cidade que escolhi de todas as tribos de Israel.',
        'Porque me deixaram, e se encurvaram a Astarote, deusa dos sidônios, a Quemós, deus dos moabitas, e a Milcom, deus dos filhos de Amom; e não andaram pelos meus caminhos, para fazerem o que é reto aos meus olhos, a saber, os meus estatutos e os meus juízos, como Davi, seu pai.',
        'Porém não tomarei nada deste reino da sua mão; mas por príncipe o ponho por todos os dias da sua vida, por amor de Davi, meu servo, a quem escolhi, o qual guardou os meus mandamentos e os meus estatutos.',
        'Mas da mão de seu filho tomarei o reino, e darei a ti, as dez tribos dele.',
        'E a seu filho darei uma tribo; para que Davi, meu servo, sempre tenha uma lâmpada diante de mim em Jerusalém, a cidade que escolhi para pôr ali o meu nome.',
        'E te tomarei, e reinarás sobre tudo o que desejar a tua alma; e serás rei sobre Israel.',
        'E há de ser que, se ouvires tudo o que eu te mandar, e andares pelos meus caminhos, e fizeres o que é reto aos meus olhos, guardando os meus estatutos e os meus mandamentos, como fez Davi, meu servo, eu serei contigo, e te edificarei uma casa firme, como edifiquei a Davi, e te darei Israel.',
        'E por isso afligirei a descendência de Davi; todavia não para sempre.',
        'Assim Salomão procurou matar Jeroboão; porém Jeroboão se levantou, e fugiu para o Egito, a ter com Sisaque, rei do Egito; e esteve no Egito até que Salomão morreu.',
        'Quanto ao mais dos atos de Salomão, e a tudo quanto fez, e à sua sabedoria, porventura não está escrito no livro dos feitos de Salomão?',
        'E o tempo que reinou Salomão, em Jerusalém, sobre todo o Israel foi quarenta anos.',
        'E adormeceu Salomão com seus pais, e foi sepultado na cidade de Davi, seu pai; e Roboão, seu filho, reinou em seu lugar.',
      ],
      [
        'E foi Roboão para Siquém; porque todo o Israel se reuniu em Siquém, para o fazerem rei.',
        'Sucedeu que, Jeroboão, filho de Nebate, achando-se ainda no Egito, para onde fugira de diante do rei Salomão, voltou do Egito,',
        'Porque mandaram chamá-lo; veio, pois, Jeroboão e toda a congregação de Israel, e falaram a Roboão, dizendo:',
        'Teu pai agravou o nosso jugo; agora, pois, alivia tu a dura servidão de teu pai, e o pesado jugo que nos impôs, e nós te serviremos.',
        'E ele lhes disse: Ide-vos até ao terceiro dia, e então voltai a mim. E o povo se foi.',
        'E teve o rei Roboão conselho com os anciãos que estiveram na presença de Salomão, seu pai, quando este ainda vivia, dizendo: Como aconselhais vós que se responda a este povo?',
        'E eles lhe falaram, dizendo: Se hoje fores servo deste povo, e o servires, e respondendo-lhe, lhe falares boas palavras, todos os dias serão teus servos.',
        'Porém ele deixou o conselho que os anciãos lhe tinham dado, e teve conselho com os jovens que haviam crescido com ele, que estavam diante dele.',
        'E disse-lhes: Que aconselhais vós que respondamos a este povo, que me falou, dizendo: Alivia o jugo que teu pai nos impôs?',
        'E os jovens que haviam crescido com ele lhe falaram: Assim dirás a este povo que te falou: Teu pai fez pesadíssimo o nosso jugo, mas tu o alivia de sobre nós; assim lhe falarás: Meu dedo mínimo é mais grosso do que os lombos de meu pai.',
        'Assim que, se meu pai vos carregou de um jugo pesado, ainda eu aumentarei o vosso jugo; meu pai vos castigou com açoites, porém eu vos castigarei com escorpiões.',
        'Veio, pois, Jeroboão e todo o povo, ao terceiro dia, a Roboão, como o rei havia ordenado, dizendo: Voltai a mim ao terceiro dia.',
        'E o rei respondeu ao povo duramente; porque deixara o conselho que os anciãos lhe haviam dado.',
        'E lhe falou conforme ao conselho dos jovens, dizendo: Meu pai agravou o vosso jugo, porém eu ainda aumentarei o vosso jugo; meu pai vos castigou com açoites, porém eu vos castigarei com escorpiões.',
        'O rei, pois, não deu ouvidos ao povo; porque esta revolta vinha do Senhor, para confirmar a palavra que o Senhor tinha falado pelo ministério de Aías, o silonita, a Jeroboão, filho de Nebate.',
        'Vendo, pois, todo o Israel que o rei não lhe dava ouvidos, tornou-lhe o povo a responder, dizendo: Que parte temos nós com Davi? Não há para nós herança no filho de Jessé. Às tuas tendas, ó Israel! Provê agora a tua casa, ó Davi. Então Israel se foi às suas tendas.',
        'No tocante, porém, aos filhos de Israel que habitavam nas cidades de Judá, também sobre eles reinou Roboão.',
        'Então o rei Roboão enviou a Adorão, que estava sobre os tributos; e todo o Israel o apedrejou, e ele morreu; mas o rei Roboão se animou a subir ao carro para fugir para Jerusalém.',
        'Assim se rebelaram os israelitas contra a casa de Davi, até ao dia de hoje.',
        'E sucedeu que, ouvindo todo o Israel que Jeroboão tinha voltado, enviaram, e o chamaram para a congregação, e o fizeram rei sobre todo o Israel; e ninguém seguiu a casa de Davi senão somente a tribo de Judá.',
        'Vindo, pois, Roboão a Jerusalém, reuniu toda a casa de Judá e a tribo de Benjamim, cento e oitenta mil escolhidos, destros para a guerra, para pelejar contra a casa de Israel, para restituir o reino a Roboão, filho de Salomão.',
        'Porém veio a palavra de Deus a Semaías, homem de Deus, dizendo:',
        'Fala a Roboão, filho de Salomão, rei de Judá, e a toda a casa de Judá, e a Benjamim, e ao restante do povo, dizendo:',
        'Assim diz o Senhor: Não subireis nem pelejareis contra vossos irmãos, os filhos de Israel; volte cada um para a sua casa, porque eu é que fiz esta obra. E ouviram a palavra do Senhor, e voltaram segundo a palavra do Senhor.',
        'E Jeroboão edificou a Siquém, no monte de Efraim, e habitou ali; e saiu dali, e edificou a Penuel.',
        'E disse Jeroboão no seu coração: Agora tornará o reino à casa de Davi.',
        'Se este povo subir para fazer sacrifícios na casa do SENHOR, em Jerusalém, o coração deste povo se tornará a seu senhor, a Roboão, rei de Judá; e me matarão, e tornarão a Roboão, rei de Judá.',
        'Assim o rei tomou conselho, e fez dois bezerros de ouro; e lhes disse: Muito trabalho vos será o subir a Jerusalém; vês aqui teus deuses, ó Israel, que te fizeram subir da terra do Egito.',
        'E pôs um em Betel, e colocou o outro em Dã.',
        'E este feito se tornou em pecado; pois que o povo ia até Dã para adorar o bezerro.',
        'Também fez casa nos altos; e constituiu sacerdotes dos mais baixos do povo, que não eram dos filhos de Levi.',
        'E fez Jeroboão uma festa no oitavo mês, no dia décimo quinto do mês, como a festa que se fazia em Judá, e sacrificou no altar; semelhantemente fez em Betel, sacrificando aos bezerros que fizera; também em Betel estabeleceu sacerdotes dos altos que fizera.',
        'E sacrificou no altar que fizera em Betel, no dia décimo quinto do oitavo mês, que ele tinha imaginado no seu coração; assim fez a festa aos filhos de Israel, e sacrificou no altar, queimando incenso.',
      ],
      [
        'E eis que, por ordem do SENHOR, veio, de Judá a Betel, um homem de Deus; e Jeroboão estava junto ao altar, para queimar incenso.',
        'E ele clamou contra o altar por ordem do Senhor, e disse: Altar, altar! Assim diz o Senhor: Eis que um filho nascerá à casa de Davi, cujo nome será Josias, o qual sacrificará sobre ti os sacerdotes dos altos que sobre ti queimam incenso, e ossos de homens se queimarão sobre ti.',
        'E deu, naquele mesmo dia, um sinal, dizendo: Este é o sinal de que o Senhor falou: Eis que o altar se fenderá, e a cinza, que nele está, se derramará.',
        'Sucedeu, pois, que, ouvindo o rei a palavra do homem de Deus, que clamara contra o altar de Betel, Jeroboão estendeu a sua mão de sobre o altar, dizendo: Pegai-o! Mas a sua mão, que estendera contra ele, se secou, e não podia tornar a trazê-la a si.',
        'E o altar se fendeu, e a cinza se derramou do altar, segundo o sinal que o homem de Deus apontara por ordem do Senhor.',
        'Então respondeu o rei, e disse ao homem de Deus: Suplica ao Senhor teu Deus, e roga por mim, para que se me restitua a minha mão. Então o homem de Deus suplicou ao Senhor, e a mão do rei se lhe restituiu, e ficou como dantes.',
        'E o rei disse ao homem de Deus: Vem comigo para casa, e conforta-te; e dar-te-ei um presente.',
        'Porém o homem de Deus disse ao rei: Ainda que me desses metade da tua casa, não iria contigo, nem comeria pão nem beberia água neste lugar.',
        'Porque assim me ordenou o Senhor pela sua palavra, dizendo: Não comerás pão nem beberás água; e não voltarás pelo caminho por onde vieste.',
        'Assim foi por outro caminho; e não voltou pelo caminho, por onde viera a Betel.',
        'E morava em Betel um velho profeta; e vieram seus filhos, e contaram-lhe tudo o que o homem de Deus fizera aquele dia em Betel, e as palavras que dissera ao rei; e as contaram a seu pai.',
        'E disse-lhes seu pai: Por que caminho se foi? E seus filhos lhe mostraram o caminho por onde fora o homem de Deus que viera de Judá.',
        'Então disse a seus filhos: Albardai-me um jumento. E albardaram-lhe o jumento no qual ele montou.',
        'E foi após o homem de Deus, e achou-o assentado debaixo de um carvalho, e disse-lhe: És tu o homem de Deus que vieste de Judá? E ele disse: Sou.',
        'Então lhe disse: Vem comigo à casa, e come pão.',
        'Porém ele disse: Não posso voltar contigo, nem entrarei contigo; nem tampouco comerei pão, nem beberei contigo água neste lugar.',
        'Porque me foi mandado pela palavra do Senhor: Ali não comerás pão, nem beberás água; nem voltarás pelo caminho por onde vieste.',
        'E ele lhe disse: Também eu sou profeta como tu, e um anjo me falou por ordem do Senhor, dizendo: Faze-o voltar contigo à tua casa, para que coma pão e beba água (porém mentiu-lhe).',
        'Assim voltou com ele, e comeu pão em sua casa e bebeu água.',
        'E sucedeu que, estando eles à mesa, a palavra do Senhor veio ao profeta que o tinha feito voltar.',
        'E clamou ao homem de Deus, que viera de Judá, dizendo: Assim diz o Senhor: Porquanto foste rebelde à ordem do Senhor, e não guardaste o mandamento que o Senhor teu Deus te mandara,',
        'Antes voltaste, e comeste pão e bebeste água no lugar de que o Senhor te dissera: Não comerás pão nem beberás água; o teu cadáver não entrará no sepulcro de teus pais.',
        'E sucedeu que, depois que comeu pão, e depois que bebeu, albardou ele o jumento para o profeta que fizera voltar.',
        'Este, pois, se foi, e um leão o encontrou no caminho, e o matou; e o seu cadáver ficou estendido no caminho, e o jumento estava parado junto a ele, e também o leão estava junto ao cadáver.',
        'E eis que alguns homens passaram, e viram o corpo lançado no caminho, como também o leão, que estava junto ao corpo; e foram, e o disseram na cidade onde o velho profeta habitava.',
        'E, ouvindo-o o profeta que o fizera voltar do caminho, disse: É o homem de Deus, que foi rebelde à ordem do Senhor; por isso o Senhor o entregou ao leão, que o despedaçou e matou, segundo a palavra que o Senhor lhe dissera.',
        'Então disse a seus filhos: Albardai-me o jumento. Eles o albardaram.',
        'Então foi, e achou o cadáver estendido no caminho, e o jumento e o leão, que estavam parados junto ao cadáver; e o leão não tinha devorado o corpo, nem tinha despedaçado o jumento.',
        'Então o profeta levantou o cadáver do homem de Deus, e pô-lo em cima do jumento levando-o consigo; assim veio o velho profeta à cidade, para o chorar e enterrar.',
        'E colocou o cadáver no seu próprio sepulcro; e prantearam-no, dizendo: Ah, irmão meu!',
        'E sucedeu que, depois de o haver sepultado, disse a seus filhos: Morrendo eu, sepultai-me no sepulcro em que o homem de Deus está sepultado; ponde os meus ossos junto aos ossos dele.',
        'Porque certamente se cumprirá o que pela palavra do Senhor exclamou contra o altar que está em Betel, como também contra todas as casas dos altos que estão nas cidades de Samaria.',
        'Nem depois destas coisas deixou Jeroboão o seu mau caminho; antes, de todo o povo, tornou a constituir sacerdotes dos lugares altos; e a qualquer que queria consagrava sacerdote dos lugares altos.',
        'E isso foi causa de pecado à casa de Jeroboão, para destruí-la e extingui-la da terra.',
      ],
      [
        'Naquele tempo adoeceu Abias, filho de Jeroboão.',
        'E disse Jeroboão à sua mulher: Levanta-te agora, e disfarça-te, para que não conheçam que és mulher de Jeroboão; e vai a Siló. Eis que lá está o profeta Aías, o qual falou de mim, que eu seria rei sobre este povo.',
        'E leva contigo dez pães, e bolos, e uma botija de mel, e vai a ele; ele te declarará o que há de suceder a este menino.',
        'E a mulher de Jeroboão assim fez, e se levantou, e foi a Siló, e entrou na casa de Aías; e já Aías não podia ver, porque os seus olhos estavam já escurecidos por causa da sua velhice.',
        'Porém o Senhor disse a Aías: Eis que a mulher de Jeroboão vem consultar-te sobre seu filho, porque está doente; assim e assim lhe falarás; porque há de ser que, entrando ela, fingirá ser outra.',
        'E sucedeu que, ouvindo Aías o ruído de seus pés, entrando ela pela porta, disse-lhe ele: Entra, mulher de Jeroboão; por que te disfarças assim? Pois eu sou enviado a ti com duras novas.',
        'Vai, dize a Jeroboão: Assim diz o Senhor Deus de Israel: Porquanto te levantei do meio do povo, e te pus por príncipe sobre o meu povo de Israel,',
        'E rasguei o reino da casa de Davi, e o dei a ti, e tu não foste como o meu servo Davi, que guardou os meus mandamentos e que andou após mim com todo o seu coração para fazer somente o que era reto aos meus olhos,',
        'Antes tu fizeste o mal, pior do que todos os que foram antes de ti; e foste, e fizeste outros deuses e imagens de fundição, para provocar-me à ira, e me lançaste para trás das tuas costas;',
        'Portanto, eis que trarei mal sobre a casa de Jeroboão; destruirei de Jeroboão todo o homem até ao menino, tanto o escravo como o livre em Israel; e lançarei fora os descendentes da casa de Jeroboão, como se lança fora o esterco, até que de todo se acabe.',
        'Quem morrer dos de Jeroboão, na cidade, os cães o comerão, e o que morrer no campo as aves do céu o comerão, porque o Senhor o disse.',
        'Tu, pois, levanta-te, e vai para tua casa; entrando os teus pés na cidade, o menino morrerá.',
        'E todo o Israel o pranteará, e o sepultará; porque de Jeroboão só este entrará em sepultura, porquanto se achou nele coisa boa para com o Senhor Deus de Israel em casa de Jeroboão.',
        'O Senhor, porém, levantará para si um rei sobre Israel, que destruirá a casa de Jeroboão no mesmo dia. Que digo eu? Há de ser já.',
        'Também o Senhor ferirá a Israel como se agita a cana nas águas; e arrancará a Israel desta boa terra que tinha dado a seus pais, e o espalhará para além do rio; porquanto fizeram os seus ídolos, provocando o Senhor à ira.',
        'E entregará a Israel por causa dos pecados de Jeroboão, o qual pecou, e fez pecar a Israel.',
        'Então a mulher de Jeroboão se levantou, e foi, e chegou a Tirza; chegando ela ao limiar da porta, morreu o menino.',
        'E o sepultaram, e todo o Israel o pranteou, conforme a palavra do Senhor, a qual dissera pelo ministério de seu servo Aías, o profeta.',
        'Quanto ao mais dos atos de Jeroboão, como guerreou, e como reinou, eis que está escrito no livro das crônicas dos reis de Israel.',
        'E foram os dias que Jeroboão reinou vinte e dois anos; e dormiu com seus pais; e Nadabe, seu filho, reinou em seu lugar.',
        'E Roboão, filho de Salomão, reinava em Judá; de quarenta e um anos de idade era Roboão quando começou a reinar, e dezessete anos reinou em Jerusalém, na cidade que o Senhor escolhera de todas as tribos de Israel para pôr ali o seu nome; e era o nome de sua mãe Naamá, amonita.',
        'E fez Judá o que era mau aos olhos do Senhor; e com os seus pecados que cometeram, provocaram-no a zelos, mais do que todos os seus pais fizeram.',
        'Porque também eles edificaram altos, e estátuas, e imagens de Aserá sobre todo o alto outeiro e debaixo de toda a árvore verde.',
        'Havia também sodomitas na terra; fizeram conforme a todas as abominações dos povos que o Senhor tinha expulsado de diante dos filhos de Israel.',
        'Ora, sucedeu que, no quinto ano do rei Roboão, Sisaque, rei do Egito, subiu contra Jerusalém,',
        'E tomou os tesouros da casa do Senhor e os tesouros da casa do rei; e levou tudo. Também tomou todos os escudos de ouro que Salomão tinha feito.',
        'E em lugar deles fez o rei Roboão escudos de cobre, e os entregou nas mãos dos chefes da guarda que guardavam a porta da casa do rei.',
        'E todas as vezes que o rei entrava na casa do Senhor, os da guarda os levavam, e depois os tornavam à câmara da guarda.',
        'Quanto ao mais dos atos de Roboão, e a tudo quanto fez, porventura não está escrito no livro das crônicas dos reis de Judá?',
        'E houve guerra entre Roboão e Jeroboão todos os seus dias.',
        'E Roboão dormiu com seus pais, e foi sepultado com seus pais na cidade de Davi; e era o nome de sua mãe Naamá, amonita; e Abias, seu filho, reinou em seu lugar.',
      ],
      [
        'E no décimo oitavo ano do rei Jeroboão, filho de Nebate, Abias começou a reinar sobre Judá.',
        'E reinou três anos em Jerusalém; e era o nome de sua mãe Maaca, filha de Absalão.',
        'E andou em todos os pecados que seu pai tinha cometido antes dele; e seu coração não foi perfeito para com o Senhor seu Deus como o coração de Davi, seu pai.',
        'Mas por amor de Davi o Senhor seu Deus lhe deu uma lâmpada em Jerusalém, levantando a seu filho depois dele, e confirmando a Jerusalém.',
        'Porquanto Davi tinha feito o que era reto aos olhos do Senhor, e não se tinha desviado de tudo quanto lhe ordenara em todos os dias da sua vida, senão só no negócio de Urias, o heteu.',
        'E houve guerra entre Roboão e Jeroboão todos os dias da sua vida.',
        'Quanto ao mais dos atos de Abias, e a tudo quanto fez, porventura não está escrito no livro das crônicas dos reis de Judá? Também houve guerra entre Abias e Jeroboão.',
        'E Abias dormiu com seus pais, e o sepultaram na cidade de Davi; e Asa, seu filho, reinou em seu lugar.',
        'E no vigésimo ano de Jeroboão, rei de Israel, começou Asa a reinar em Judá.',
        'E quarenta e um anos reinou em Jerusalém; e era o nome de sua mãe Maaca, filha de Absalão.',
        'E Asa fez o que era reto aos olhos do Senhor, como Davi seu pai.',
        'Porque tirou da terra os sodomitas, e removeu todos os ídolos que seus pais fizeram.',
        'E até a Maaca, sua mãe, removeu para que não fosse rainha, porquanto tinha feito um horrível ídolo a Aserá; também Asa desfez o seu ídolo horrível, e o queimou junto ao ribeiro de Cedrom.',
        'Os altos, porém, não foram tirados; todavia foi o coração de Asa reto para com o Senhor todos os seus dias.',
        'E à casa do Senhor trouxe as coisas consagradas por seu pai, e as coisas que ele mesmo consagrara; prata, ouro e vasos.',
        'E houve guerra entre Asa e Baasa, rei de Israel, todos os seus dias.',
        'Porque Baasa, rei de Israel, subiu contra Judá, e edificou a Ramá, para que a ninguém fosse permitido sair, nem entrar a ter com Asa, rei de Judá.',
        'Então Asa tomou toda a prata e ouro que ficaram nos tesouros da casa do Senhor, e os tesouros da casa do rei, e os entregou nas mãos de seus servos; e o rei Asa os enviou a Ben-Hadade, filho de Tabrimom, filho de Heziom, rei da Síria, que habitava em Damasco, dizendo:',
        'Haja acordo entre mim e ti, como houve entre meu pai e teu pai; eis que te mando um presente, prata e ouro; vai, e anula o teu acordo com Baasa, rei de Israel, para que se retire de sobre mim.',
        'E Ben-Hadade deu ouvidos ao rei Asa, e enviou os capitães dos seus exércitos contra as cidades de Israel; e feriu a Ijom, e a Dã, e a Abel-Bete-Maaca, e a toda a Quinerete, com toda a terra de Naftali.',
        'E sucedeu que, ouvindo-o Baasa, deixou de edificar a Ramá; e ficou em Tirza.',
        'Então o rei Asa fez apregoar por toda a Judá que todos, sem exceção, trouxessem as pedras de Ramá, e a sua madeira com que Baasa edificara; e com elas edificou o rei Asa a Geba de Benjamim e a Mizpá.',
        'Quanto ao mais de todos os atos de Asa, e a todo o seu poder, e a tudo quanto fez, e as cidades que edificou, porventura não está escrito no livro das crônicas dos reis de Judá? Porém, no tempo da sua velhice, padeceu dos pés.',
        'E Asa dormiu com seus pais, e foi sepultado com seus pais na cidade de Davi, seu pai; e Jeosafá, seu filho, reinou em seu lugar.',
        'E Nadabe, filho de Jeroboão, começou a reinar sobre Israel no ano segundo de Asa, rei de Judá; e reinou sobre Israel dois anos.',
        'E fez o que era mau aos olhos do Senhor; e andou nos caminhos de seu pai, e no seu pecado com que seu pai fizera pecar a Israel.',
        'E conspirou contra ele Baasa, filho de Aías, da casa de Issacar, e feriu-o Baasa em Gibetom, que era dos filisteus, quando Nadabe e todo o Israel cercavam a Gibetom.',
        'E matou-o, pois, Baasa no ano terceiro de Asa, rei de Judá, e reinou em seu lugar.',
        'Sucedeu que, reinando ele, feriu a toda a casa de Jeroboão; nada de Jeroboão deixou que tivesse fôlego, até o destruir, conforme à palavra do Senhor que dissera pelo ministério de seu servo Aías, o silonita.',
        'Por causa dos pecados que Jeroboão cometera, e fez pecar a Israel, e por causa da provocação com que irritara ao Senhor Deus de Israel.',
        'Quanto ao mais dos atos de Nadabe, e a tudo quanto fez, porventura não está escrito no livro das crônicas dos reis de Israel?',
        'E houve guerra entre Asa e Baasa, rei de Israel, todos os seus dias.',
        'No ano terceiro de Asa, rei de Judá, Baasa, filho de Aías, começou a reinar sobre todo o Israel em Tirza, e reinou vinte e quatro anos.',
        'E fez o que era mau aos olhos do Senhor; e andou no caminho de Jeroboão, e no pecado com que ele tinha feito Israel pecar.',
      ],
      [
        'Então veio a palavra do SENHOR a Jeú, filho de Hanani, contra Baasa, dizendo:',
        'Porquanto te levantei do pó, e te pus por príncipe sobre o meu povo Israel, e tu tens andado no caminho de Jeroboão, e tens feito pecar a meu povo Israel, irritando-me com os seus pecados,',
        'Eis que tirarei os descendentes de Baasa, e os descendentes da sua casa, e farei a tua casa como a casa de Jeroboão, filho de Nebate.',
        'Quem morrer dos de Baasa, na cidade, os cães o comerão; e o que dele morrer no campo, as aves do céu o comerão.',
        'Quanto ao mais dos atos de Baasa, e ao que fez, e ao seu poder, porventura não está escrito no livro das crônicas dos reis de Israel?',
        'E Baasa dormiu com seus pais, e foi sepultado em Tirza; e Elá, seu filho, reinou em seu lugar.',
        'Assim veio também a palavra do Senhor, pelo ministério do profeta Jeú, filho de Hanani, contra Baasa e contra a sua casa; e isso por todo o mal que fizera aos olhos do Senhor, irritando-o com a obra de suas mãos, para ser como a casa de Jeroboão; e porque o havia ferido.',
        'No ano vinte e seis de Asa, rei de Judá, Elá, filho de Baasa, começou a reinar em Tirza sobre Israel; e reinou dois anos.',
        'E Zinri, seu servo, capitão de metade dos carros, conspirou contra ele, estando ele em Tirza, bebendo e embriagando-se em casa de Arsa, mordomo em Tirza.',
        'Entrou, pois, Zinri, e o feriu, e o matou, no ano vigésimo sétimo de Asa, rei de Judá; e reinou em seu lugar.',
        'E sucedeu que, reinando ele, e estando assentado no seu trono, feriu a toda a casa de Baasa; não lhe deixou homem algum, nem a seus parentes, nem a seus amigos.',
        'Assim destruiu Zinri toda a casa de Baasa, conforme à palavra do Senhor que, contra Baasa, ele falara pelo ministério do profeta Jeú,',
        'Por todos os pecados de Baasa, e os pecados de Elá, seu filho, que cometeram, e com que fizeram pecar a Israel, irritando ao Senhor Deus de Israel com as suas vaidades.',
        'Quanto ao mais dos atos de Elá, e a tudo quanto fez, não está escrito no livro das crônicas dos reis de Israel?',
        'No ano vigésimo sétimo de Asa, rei de Judá, reinou Zinri sete dias em Tirza; e o povo estava acampado contra Gibetom, que era dos filisteus.',
        'E o povo que estava acampado ouviu dizer: Zinri tem conspirado, e até matou o rei. Todo o Israel pois, no mesmo dia, no arraial, constituiu rei sobre Israel a Onri, capitão do exército.',
        'E subiu Onri, e todo o Israel com ele, de Gibetom, e cercaram a Tirza.',
        'E sucedeu que Zinri, vendo que a cidade era tomada, foi ao paço da casa do rei e queimou-a sobre si; e morreu,',
        'Por causa dos pecados que cometera, fazendo o que era mau aos olhos do Senhor, andando no caminho de Jeroboão, e no pecado que ele cometera, fazendo Israel pecar.',
        'Quanto ao mais dos atos de Zinri, e à conspiração que fez, porventura não está escrito no livro das crônicas dos reis de Israel?',
        'Então o povo de Israel se dividiu em dois partidos: metade do povo seguia a Tibni, filho de Ginate, para o fazer rei, e a outra metade seguia a Onri.',
        'Mas o povo que seguia a Onri foi mais forte do que o povo que seguia a Tibni, filho de Ginate; e Tibni morreu, e Onri reinou.',
        'No ano trinta e um de Asa, rei de Judá, Onri começou a reinar sobre Israel, e reinou doze anos; e em Tirza reinou seis anos.',
        'E de Semer comprou o monte de Samaria por dois talentos de prata, e edificou nele; e chamou a cidade que edificou Samaria, do nome de Semer, dono do monte.',
        'E fez Onri o que era mau aos olhos do Senhor; e fez pior do que todos quantos foram antes dele.',
        'E andou em todos os caminhos de Jeroboão, filho de Nebate, como também nos pecados com que ele tinha feito pecar a Israel, irritando ao Senhor Deus de Israel com as suas vaidades.',
        'Quanto ao mais dos atos de Onri, ao que fez, e ao poder que manifestou, porventura não está escrito no livro das crônicas dos reis de Israel?',
        'E Onri dormiu com seus pais, e foi sepultado em Samaria; e Acabe, seu filho, reinou em seu lugar.',
        'E Acabe, filho de Onri, começou a reinar sobre Israel no ano trigésimo oitavo de Asa, rei de Judá; e reinou Acabe, filho de Onri, sobre Israel, em Samaria, vinte e dois anos.',
        'E fez Acabe, filho de Onri, o que era mau aos olhos do Senhor, mais do que todos os que foram antes dele.',
        'E sucedeu que (como se fora pouco andar nos pecados de Jeroboão, filho de Nebate) ainda tomou por mulher a Jezabel, filha de Etbaal, rei dos sidônios; e foi e serviu a Baal, e o adorou.',
        'E levantou um altar a Baal, na casa de Baal que edificara em Samaria.',
        'Também Acabe fez um ídolo; de modo que Acabe fez muito mais para irritar ao Senhor Deus de Israel, do que todos os reis de Israel que foram antes dele.',
        'Em seus dias Hiel, o betelita, edificou a Jericó; em Abirão, seu primogênito, a fundou, e em Segube, seu filho menor, pôs as suas portas; conforme a palavra do Senhor, que falara pelo ministério de Josué, filho de Num.',
      ],
      [
        'Então Elias, o tisbita, dos moradores de Gileade, disse a Acabe: Vive o SENHOR Deus de Israel, perante cuja face estou, que nestes anos nem orvalho nem chuva haverá, senão segundo a minha palavra.',
        'Depois veio a ele a palavra do Senhor, dizendo:',
        'Retira-te daqui, e vai para o oriente, e esconde-te junto ao ribeiro de Querite, que está diante do Jordão.',
        'E há de ser que beberás do ribeiro; e eu tenho ordenado aos corvos que ali te sustentem.',
        'Foi, pois, e fez conforme a palavra do Senhor; porque foi, e habitou junto ao ribeiro de Querite, que está diante do Jordão.',
        'E os corvos lhe traziam pão e carne pela manhã; como também pão e carne à noite; e bebia do ribeiro.',
        'E sucedeu que, passados dias, o ribeiro se secou, porque não tinha havido chuva na terra.',
        'Então veio a ele a palavra do Senhor, dizendo:',
        'Levanta-te, e vai para Sarepta, que é de Sidom, e habita ali; eis que eu ordenei ali a uma mulher viúva que te sustente.',
        'Então ele se levantou, e foi a Sarepta; e, chegando à porta da cidade, eis que estava ali uma mulher viúva apanhando lenha; e ele a chamou, e lhe disse: Traze-me, peço-te, num vaso um pouco de água que beba.',
        'E, indo ela a trazê-la, ele a chamou e lhe disse: Traze-me agora também um bocado de pão na tua mão.',
        'Porém ela disse: Vive o Senhor teu Deus, que nem um bolo tenho, senão somente um punhado de farinha numa panela, e um pouco de azeite numa botija; e vês aqui apanhei dois cavacos, e vou prepará-lo para mim e para o meu filho, para que o comamos, e morramos.',
        'E Elias lhe disse: Não temas; vai, faze conforme à tua palavra; porém faze dele primeiro para mim um bolo pequeno, e traze-mo aqui; depois farás para ti e para teu filho.',
        'Porque assim diz o Senhor Deus de Israel: A farinha da panela não se acabará, e o azeite da botija não faltará até ao dia em que o Senhor dê chuva sobre a terra.',
        'E ela foi e fez conforme a palavra de Elias; e assim comeu ela, e ele, e a sua casa muitos dias.',
        'Da panela a farinha não se acabou, e da botija o azeite não faltou; conforme a palavra do Senhor, que ele falara pelo ministério de Elias.',
        'E depois destas coisas sucedeu que adoeceu o filho desta mulher, dona da casa; e a sua doença se agravou muito, até que nele nenhum fôlego ficou.',
        'Então ela disse a Elias: Que tenho eu contigo, homem de Deus? vieste tu a mim para trazeres à memória a minha iniqüidade, e matares a meu filho?',
        'E ele disse: Dá-me o teu filho. E ele o tomou do seu regaço, e o levou para cima, ao quarto, onde ele mesmo habitava, e o deitou em sua cama,',
        'E clamou ao Senhor, e disse: Ó Senhor meu Deus, também até a esta viúva, com quem me hospedo, afligiste, matando-lhe o filho?',
        'Então se estendeu sobre o menino três vezes, e clamou ao Senhor, e disse: Ó Senhor meu Deus, rogo-te que a alma deste menino torne a entrar nele.',
        'E o Senhor ouviu a voz de Elias; e a alma do menino tornou a entrar nele, e reviveu.',
        'E Elias tomou o menino, e o trouxe do quarto à casa, e o deu à sua mãe; e disse Elias: Vês aí, teu filho vive.',
        'Então a mulher disse a Elias: Nisto conheço agora que tu és homem de Deus, e que a palavra do Senhor na tua boca é verdade.',
      ],
      [
        'E sucedeu que, depois de muitos dias, a palavra do SENHOR veio a Elias, no terceiro ano, dizendo: Vai, apresenta-te a Acabe; porque darei chuva sobre a terra.',
        'E foi Elias apresentar-se a Acabe; e a fome era extrema em Samaria.',
        'E Acabe chamou a Obadias, o mordomo; e Obadias temia muito ao Senhor,',
        'Porque sucedeu que, destruindo Jezabel os profetas do Senhor, Obadias tomou cem profetas, e de cinqüenta em cinqüenta os escondeu numa cova, e os sustentou com pão e água.',
        'E disse Acabe a Obadias: Vai pela terra a todas as fontes de água, e a todos os rios; pode ser que achemos erva, para que em vida conservemos os cavalos e mulas, e não percamos todos os animais.',
        'E repartiram entre si a terra, para a percorrerem: Acabe foi à parte por um caminho, e Obadias também foi sozinho por outro caminho.',
        'Estando, pois, Obadias já em caminho, eis que Elias o encontrou; e Obadias, reconhecendo-o, prostrou-se sobre o seu rosto, e disse: És tu o meu senhor Elias?',
        'E disse-lhe ele: Eu sou; vai, e dize a teu senhor: Eis que Elias está aqui.',
        'Porém ele disse: Em que pequei, para que entregues a teu servo na mão de Acabe, para que me mate?',
        'Vive o SENHOR teu Deus, que não houve nação nem reino aonde o meu senhor não mandasse em busca de ti; e dizendo eles: Aqui não está, então fazia jurar os reinos e nações, que não te haviam achado.',
        'E agora dizes tu: Vai, dize a teu senhor: Eis que aqui está Elias.',
        'E poderia ser que, apartando-me eu de ti, o Espírito do Senhor te tomasse, não sei para onde, e, vindo eu a dar as novas a Acabe, e não te achando ele, me mataria; porém eu, teu servo, temo ao Senhor desde a minha mocidade.',
        'Porventura não disseram a meu senhor o que fiz, quando Jezabel matava os profetas do SENHOR? Como escondi a cem homens dos profetas do SENHOR, de cinqüenta em cinqüenta, numa cova, e os sustentei com pão e água?',
        'E agora dizes tu: Vai, dize a teu senhor: Eis que Elias está aqui; ele me mataria.',
        'E disse Elias: Vive o Senhor dos Exércitos, perante cuja face estou, que deveras hoje me apresentarei a ele.',
        'Então foi Obadias encontrar-se com Acabe, e lho anunciou; e foi Acabe encontrar-se com Elias.',
        'E sucedeu que, vendo Acabe a Elias, disse-lhe: És tu o perturbador de Israel?',
        'Então disse ele: Eu não tenho perturbado a Israel, mas tu e a casa de teu pai, porque deixastes os mandamentos do Senhor, e seguistes a Baalim.',
        'Agora, pois, manda reunir-se a mim todo o Israel no monte Carmelo; como também os quatrocentos e cinqüenta profetas de Baal, e os quatrocentos profetas de Aserá, que comem da mesa de Jezabel.',
        'Então Acabe convocou todos os filhos de Israel; e reuniu os profetas no monte Carmelo.',
        'Então Elias se chegou a todo o povo, e disse: Até quando coxeareis entre dois pensamentos? Se o Senhor é Deus, segui-o, e se Baal, segui-o. Porém o povo nada lhe respondeu.',
        'Então disse Elias ao povo: Só eu fiquei por profeta do Senhor, e os profetas de Baal são quatrocentos e cinqüenta homens.',
        'Dêem-se-nos, pois, dois bezerros, e eles escolham para si um dos bezerros, e o dividam em pedaços, e o ponham sobre a lenha, porém não lhe coloquem fogo, e eu prepararei o outro bezerro, e o porei sobre a lenha, e não lhe colocarei fogo.',
        'Então invocai o nome do vosso deus, e eu invocarei o nome do Senhor; e há de ser que o deus que responder por meio de fogo esse será Deus. E todo o povo respondeu, dizendo: É boa esta palavra.',
        'E disse Elias aos profetas de Baal: Escolhei para vós um dos bezerros, e preparai-o primeiro, porque sois muitos, e invocai o nome do vosso deus, e não lhe ponhais fogo.',
        'E tomaram o bezerro que lhes dera, e o prepararam; e invocaram o nome de Baal, desde a manhã até ao meio-dia, dizendo: Ah! Baal, responde-nos! Porém nem havia voz, nem quem respondesse; e saltavam sobre o altar que tinham feito.',
        'E sucedeu que ao meio-dia Elias zombava deles e dizia: Clamai em altas vozes, porque ele é um deus; pode ser que esteja falando, ou que tenha alguma coisa que fazer, ou que intente alguma viagem; talvez esteja dormindo, e despertará.',
        'E eles clamavam em altas vozes, e se retalhavam com facas e com lancetas, conforme ao seu costume, até derramarem sangue sobre si.',
        'E sucedeu que, passado o meio-dia, profetizaram eles, até a hora de se oferecer o sacrifício da tarde; porém não houve voz, nem resposta, nem atenção alguma.',
        'Então Elias disse a todo o povo: Chegai-vos a mim. E todo o povo se chegou a ele; e restaurou o altar do Senhor, que estava quebrado.',
        'E Elias tomou doze pedras, conforme ao número das tribos dos filhos de Jacó, ao qual veio a palavra do Senhor, dizendo: Israel será o teu nome.',
        'E com aquelas pedras edificou o altar em nome do Senhor; depois fez um rego em redor do altar, segundo a largura de duas medidas de semente.',
        'Então armou a lenha, e dividiu o bezerro em pedaços, e o pôs sobre a lenha.',
        'E disse: Enchei de água quatro cântaros, e derramai-a sobre o holocausto e sobre a lenha. E disse: Fazei-o segunda vez; e o fizeram segunda vez. Disse ainda: Fazei-o terceira vez; e o fizeram terceira vez;',
        'De maneira que a água corria ao redor do altar; e até o rego ele encheu de água.',
        'Sucedeu que, no momento de ser oferecido o sacrifício da tarde, o profeta Elias se aproximou, e disse: Ó Senhor Deus de Abraão, de Isaque e de Israel, manifeste-se hoje que tu és Deus em Israel, e que eu sou teu servo, e que conforme à tua palavra fiz todas estas coisas.',
        'Responde-me, Senhor, responde-me, para que este povo conheça que tu és o Senhor Deus, e que tu fizeste voltar o seu coração.',
        'Então caiu fogo do Senhor, e consumiu o holocausto, e a lenha, e as pedras, e o pó, e ainda lambeu a água que estava no rego.',
        'O que vendo todo o povo, caíram sobre os seus rostos, e disseram: Só o Senhor é Deus! Só o Senhor é Deus!',
        'E Elias lhes disse: Lançai mão dos profetas de Baal, que nenhum deles escape. E lançaram mão deles; e Elias os fez descer ao ribeiro de Quisom, e ali os matou.',
        'Então disse Elias a Acabe: Sobe, come e bebe, porque há ruído de uma abundante chuva.',
        'E Acabe subiu a comer e a beber; mas Elias subiu ao cume do Carmelo, e se inclinou por terra, e pôs o seu rosto entre os seus joelhos.',
        'E disse ao seu servo: Sobe agora, e olha para o lado do mar. E subiu, e olhou, e disse: Não há nada. Então disse ele: Volta lá sete vezes.',
        'E sucedeu que, à sétima vez, disse: Eis aqui uma pequena nuvem, como a mão de um homem, subindo do mar. Então disse ele: Sobe, e dize a Acabe: Aparelha o teu carro, e desce, para que a chuva não te impeça.',
        'E sucedeu que, entretanto, os céus se enegreceram com nuvens e vento, e veio uma grande chuva; e Acabe subiu ao carro, e foi para Jizreel.',
        'E a mão do Senhor estava sobre Elias, o qual cingiu os lombos, e veio correndo perante Acabe, até à entrada de Jizreel.',
      ],
      [
        'E Acabe fez saber a Jezabel tudo quanto Elias havia feito, e como totalmente matara todos os profetas à espada.',
        'Então Jezabel mandou um mensageiro a Elias, a dizer-lhe: Assim me façam os deuses, e outro tanto, se de certo amanhã a estas horas não puser a tua vida como a de um deles.',
        'O que vendo ele, se levantou e, para escapar com vida, se foi, e chegando a Berseba, que é de Judá, deixou ali o seu servo.',
        'Ele, porém, foi ao deserto, caminho de um dia, e foi sentar-se debaixo de um zimbro; e pediu para si a morte, e disse: Já basta, ó Senhor; toma agora a minha vida, pois não sou melhor do que meus pais.',
        'E deitou-se, e dormiu debaixo do zimbro; e eis que então um anjo o tocou, e lhe disse: Levanta-te, come.',
        'E olhou, e eis que à sua cabeceira estava um pão cozido sobre as brasas, e uma botija de água; e comeu, e bebeu, e tornou a deitar-se.',
        'E o anjo do Senhor tornou segunda vez, e o tocou, e disse: Levanta-te e come, porque te será muito longo o caminho.',
        'Levantou-se, pois, e comeu e bebeu; e com a força daquela comida caminhou quarenta dias e quarenta noites até Horebe, o monte de Deus.',
        'E ali entrou numa caverna e passou ali a noite; e eis que a palavra do Senhor veio a ele, e lhe disse: Que fazes aqui Elias?',
        'E ele disse: Tenho sido muito zeloso pelo Senhor Deus dos Exércitos, porque os filhos de Israel deixaram a tua aliança, derrubaram os teus altares, e mataram os teus profetas à espada, e só eu fiquei, e buscam a minha vida para ma tirarem.',
        'E Deus lhe disse: Sai para fora, e põe-te neste monte perante o Senhor. E eis que passava o Senhor, como também um grande e forte vento que fendia os montes e quebrava as penhas diante do Senhor; porém o Senhor não estava no vento; e depois do vento um terremoto; também o Senhor não estava no terremoto;',
        'E depois do terremoto um fogo; porém também o Senhor não estava no fogo; e depois do fogo uma voz mansa e delicada.',
        'E sucedeu que, ouvindo-a Elias, envolveu o seu rosto na sua capa, e saiu para fora, e pôs-se à entrada da caverna; e eis que veio a ele uma voz, que dizia: Que fazes aqui, Elias?',
        'E ele disse: Eu tenho sido em extremo zeloso pelo Senhor Deus dos Exércitos, porque os filhos de Israel deixaram a tua aliança, derrubaram os teus altares, e mataram os teus profetas à espada, e só eu fiquei; e buscam a minha vida para ma tirarem.',
        'E o Senhor lhe disse: Vai, volta pelo teu caminho para o deserto de Damasco; e, chegando lá, unge a Hazael rei sobre a Síria.',
        'Também a Jeú, filho de Ninsi, ungirás rei de Israel; e também a Eliseu, filho de Safate de Abel-Meolá, ungirás profeta em teu lugar.',
        'E há de ser que o que escapar da espada de Hazael, matá-lo-á Jeú; e o que escapar da espada de Jeú, matá-lo-á Eliseu.',
        'Também deixei ficar em Israel sete mil: todos os joelhos que não se dobraram a Baal, e toda a boca que não o beijou.',
        'Partiu, pois, Elias dali, e achou a Eliseu, filho de Safate, que andava lavrando com doze juntas de bois adiante dele, e ele estava com a duodécima; e Elias passou por ele, e lançou a sua capa sobre ele.',
        'Então deixou ele os bois, e correu após Elias; e disse: Deixa-me beijar a meu pai e a minha mãe, e então te seguirei. E ele lhe disse: Vai, e volta; pois, que te fiz eu?',
        'Voltou, pois, de o seguir, e tomou a junta de bois, e os matou, e com os aparelhos dos bois cozeu as carnes, e as deu ao povo, e comeram; então se levantou e seguiu a Elias, e o servia.',
      ],
      [
        'E Ben-Hadade, rei da Síria, ajuntou todo o seu exército; e havia com ele trinta e dois reis, e cavalos e carros; e subiu, e cercou a Samaria, e pelejou contra ela.',
        'E enviou à cidade mensageiros, a Acabe, rei de Israel,',
        'Que lhe disseram: Assim diz Ben-Hadade: A tua prata e o teu ouro são meus; e tuas mulheres e os melhores de teus filhos são meus.',
        'E respondeu o rei de Israel, e disse: Conforme a tua palavra, ó rei meu senhor, eu sou teu, e tudo quanto tenho.',
        'E tornaram a vir os mensageiros, e disseram: Assim diz Ben-Hadade: Enviei-te, na verdade, mensageiros que dissessem: Tu me hás de dar a tua prata, e o teu ouro, e as tuas mulheres e os teus filhos;',
        'Todavia amanhã a estas horas enviarei os meus servos a ti, e esquadrinharão a tua casa, e as casas dos teus servos; e há de ser que tudo o que de precioso tiveres, eles tomarão consigo, e o levarão.',
        'Então o rei de Israel chamou a todos os anciãos da terra, e disse: Notai agora, e vede como este homem procura o mal; pois mandou pedir-me as mulheres, os meus filhos, a minha prata e o meu ouro, e não lhos neguei.',
        'E todos os anciãos e todo o povo lhe disseram: Não lhe dês ouvidos, nem consintas.',
        'Por isso disse aos mensageiros de Ben-Hadade: Dizei ao rei, meu senhor: Tudo o que primeiro mandaste pedir a teu servo, farei, porém isto não posso fazer. E voltaram os mensageiros, e lhe levaram a resposta.',
        'E Ben-Hadade enviou a ele mensageiros dizendo: Assim me façam os deuses, e outro tanto, que o pó de Samaria não bastará para encher as mãos de todo o povo que me segue.',
        'Porém o rei de Israel respondeu: Dizei-lhe: Não se gabe quem se cinge das armas, como aquele que as descinge.',
        'E sucedeu que, ouvindo ele esta palavra, estando a beber com os reis nas tendas, disse aos seus servos: Ponde-vos em ordem contra a cidade.',
        'E eis que um profeta se chegou a Acabe rei de Israel, e lhe disse: Assim diz o Senhor: Viste toda esta grande multidão? Eis que hoje ta entregarei nas tuas mãos, para que saibas que eu sou o Senhor.',
        'E disse Acabe: Por quem? E ele disse: Assim diz o Senhor: Pelos moços dos príncipes das províncias. E disse: Quem começará a peleja? E disse: Tu.',
        'Então contou os moços dos príncipes das províncias, e foram duzentos e trinta e dois; e depois deles contou a todo o povo, todos os filhos de Israel, sete mil.',
        'E saíram ao meio-dia; e Ben-Hadade estava bebendo e embriagando-se nas tendas, ele e os reis, os trinta e dois reis, que o ajudavam.',
        'E os moços dos príncipes das províncias saíram primeiro; e Ben-Hadade enviou espias, que lhe deram avisos, dizendo: Saíram de Samaria uns homens.',
        'E ele disse: Ainda que para paz saíssem, tomai-os vivos; e ainda que à peleja saíssem, tomai-os vivos.',
        'Saíram, pois, da cidade os moços dos príncipes das províncias, e o exército que os seguia.',
        'E eles feriram cada um o seu adversário, e os sírios fugiram, e Israel os perseguiu; porém Ben-Hadade, rei da Síria, escapou a cavalo, com alguns cavaleiros.',
        'E saiu o rei de Israel, e feriu os cavalos e os carros; e feriu os sírios com grande estrago.',
        'Então o profeta chegou-se ao rei de Israel e lhe disse: Vai, esforça-te, e atenta, e olha o que hás de fazer; porque no decurso de um ano o rei da Síria subirá contra ti.',
        'Porque os servos do rei da Síria lhe disseram: Seus deuses são deuses dos montes, por isso foram mais fortes do que nós; mas pelejemos com eles em campo raso, e por certo veremos, se não somos mais fortes do que eles!',
        'Faze, pois, isto: tira os reis, cada um do seu lugar, e substitui-os por capitães;',
        'E forma outro exército, igual ao exército que perdeste, cavalo por cavalo, e carro por carro, e pelejemos com eles em campo raso, e veremos se não somos mais fortes do que eles! E deu ouvidos à sua voz, e assim fez.',
        'E sucedeu que, passado um ano, Ben-Hadade passou revista aos sírios, e subiu a Afeque, para pelejar contra Israel.',
        'Também aos filhos de Israel se passou revista, e providos de víveres marcharam contra eles; e os filhos de Israel acamparam-se defronte deles, como dois pequenos rebanhos de cabras; mas os sírios enchiam a terra.',
        'E chegou o homem de Deus, e falou ao rei de Israel, e disse: Assim diz o Senhor: Porquanto os sírios disseram: O Senhor é Deus dos montes, e não Deus dos vales; toda esta grande multidão entregarei nas tuas mãos; para que saibas que eu sou o Senhor.',
        'E sete dias estiveram acampados uns defronte dos outros; e sucedeu ao sétimo dia que a peleja começou, e os filhos de Israel feriram dos sírios cem mil homens de pé, num dia.',
        'E os restantes fugiram a Afeque, à cidade; e caiu o muro sobre vinte e sete mil homens, que restaram; Ben-Hadade, porém, fugiu, e veio à cidade, escondendo-se de câmara em câmara.',
        'Então lhe disseram os seus servos: Eis que já temos ouvido que os reis da casa de Israel são reis clementes; ponhamos, pois, sacos aos lombos, e cordas às cabeças, e saiamos ao rei de Israel; pode ser que ele te poupe a vida.',
        'Então cingiram sacos aos lombos e cordas às cabeças, e foram ao rei de Israel, e disseram: Diz o teu servo Ben-Hadade: Deixa-me viver. E disse Acabe: Pois ainda vive? É meu irmão.',
        'E aqueles homens tomaram isto por bom presságio, e apressaram-se em apanhar a sua palavra, e disseram: Teu irmão Ben-Hadade vive. E ele disse: Vinde, trazei-mo. Então Ben-Hadade foi a ele, e ele o fez subir ao carro.',
        'E disse ele: As cidades que meu pai tomou de teu pai tas restituirei, e faze para ti ruas em Damasco, como meu pai as fez em Samaria. E eu, respondeu Acabe, te deixarei ir com esta aliança. E fez com ele aliança e o deixou ir.',
        'Então um dos homens dos filhos dos profetas disse ao seu companheiro, pela palavra do Senhor: Ora fere-me. E o homem recusou feri-lo.',
        'E ele lhe disse: Porque não obedeceste à voz do Senhor, eis que, em te apartando de mim, um leão te ferirá. E como dele se apartou, um leão o encontrou e o feriu.',
        'Depois encontrou outro homem, e disse-lhe: Ora fere-me. E aquele homem deu-lhe um golpe, ferindo-o.',
        'Então foi o profeta, e pôs-se perante o rei no caminho; e disfarçou-se com cinza sobre os seus olhos.',
        'E sucedeu que, passando o rei, clamou ele ao rei, dizendo: Teu servo estava no meio da peleja, e eis que, desviando-se um homem, trouxe-me outro homem, e disse: Guarda-me este homem; se vier a faltar, será a tua vida em lugar da vida dele, ou pagarás um talento de prata.',
        'Sucedeu, pois, que, estando o teu servo ocupado de uma e de outra parte, eis que o homem desapareceu. Então o rei de Israel lhe disse: Esta é a tua sentença; tu mesmo a pronunciaste.',
        'Então ele se apressou, e tirou a cinza de sobre os seus olhos; e o rei de Israel o reconheceu, que era um dos profetas.',
        'E disse-lhe: Assim diz o Senhor: Porquanto soltaste da mão o homem que eu havia posto para destruição, a tua vida será em lugar da sua vida, e o teu povo em lugar do seu povo.',
        'E foi o rei de Israel para a sua casa, desgostoso e indignado; e chegou a Samaria.',
      ],
      [
        'E sucedeu depois destas coisas que, Nabote, o jizreelita, tinha uma vinha em Jizreel junto ao palácio de Acabe, rei de Samaria.',
        'Então Acabe falou a Nabote, dizendo: Dá-me a tua vinha, para que me sirva de horta, pois está vizinha ao lado da minha casa; e te darei por ela outra vinha melhor: ou, se for do teu agrado, dar-te-ei o seu valor em dinheiro.',
        'Porém Nabote disse a Acabe: Guarde-me o Senhor de que eu te dê a herança de meus pais.',
        'Então Acabe veio desgostoso e indignado à sua casa, por causa da palavra que Nabote, o jizreelita, lhe falara, quando disse: Não te darei a herança de meus pais. E deitou-se na sua cama, e voltou o rosto, e não comeu pão.',
        'Porém, vindo a ele Jezabel, sua mulher, lhe disse: Que há, que está tão desgostoso o teu espírito, e não comes pão?',
        'E ele lhe disse: Porque falei a Nabote, o jizreelita, e lhe disse: Dá-me a tua vinha por dinheiro; ou, se te apraz, te darei outra vinha em seu lugar. Porém ele disse: Não te darei a minha vinha.',
        'Então Jezabel, sua mulher lhe disse: Governas tu agora no reino de Israel? Levanta-te, come pão, e alegre-se o teu coração; eu te darei a vinha de Nabote, o jizreelita.',
        'Então escreveu cartas em nome de Acabe, e as selou com o seu sinete; e mandou as cartas aos anciãos e aos nobres que havia na sua cidade e habitavam com Nabote.',
        'E escreveu nas cartas, dizendo: Apregoai um jejum, e ponde Nabote diante do povo.',
        'E ponde defronte dele dois filhos de Belial, que testemunhem contra ele, dizendo: Blasfemaste contra Deus e contra o rei; e trazei-o fora, e apedrejai-o para que morra.',
        'E os homens da sua cidade, os anciãos e os nobres que habitavam na sua cidade, fizeram como Jezabel lhes ordenara, conforme estava escrito nas cartas que lhes mandara.',
        'Apregoaram um jejum, e puseram a Nabote diante do povo.',
        'Então vieram dois homens, filhos de Belial, e puseram-se defronte dele; e os homens, filhos de Belial, testemunharam contra ele, contra Nabote, perante o povo, dizendo: Nabote blasfemou contra Deus e contra o rei. E o levaram para fora da cidade, e o apedrejaram, e morreu.',
        'Então mandaram dizer a Jezabel: Nabote foi apedrejado, e morreu.',
        'E sucedeu que, ouvindo Jezabel que já fora apedrejado Nabote, e morrera, disse a Acabe: Levanta-te, e possui a vinha de Nabote, o jizreelita, a qual te recusou dar por dinheiro; porque Nabote não vive, mas é morto.',
        'E sucedeu que, ouvindo Acabe, que Nabote já era morto, levantou-se para descer para a vinha de Nabote, o jizreelita, para tomar posse dela.',
        'Então veio a palavra do Senhor a Elias, o tisbita, dizendo:',
        'Levanta-te, desce para encontrar-te com Acabe, rei de Israel, que está em Samaria; eis que está na vinha de Nabote, aonde tem descido para possuí-la.',
        'E falar-lhe-ás, dizendo: Assim diz o Senhor: Porventura não mataste e tomaste a herança? Falar-lhe-ás mais, dizendo: Assim diz o Senhor: No lugar em que os cães lamberam o sangue de Nabote lamberão também o teu próprio sangue.',
        'E disse Acabe a Elias: Já me achaste, inimigo meu? E ele disse: Achei-te; porquanto já te vendeste para fazeres o que é mau aos olhos do Senhor.',
        'Eis que trarei mal sobre ti, e arrancarei a tua posteridade, e arrancarei de Acabe a todo o homem, tanto o escravo como o livre em Israel;',
        'E farei a tua casa como a casa de Jeroboão, filho de Nebate, e como a casa de Baasa, filho de Aías; por causa da provocação, com que me provocaste e fizeste pecar a Israel.',
        'E também acerca de Jezabel falou o Senhor, dizendo: Os cães comerão a Jezabel junto ao antemuro de Jizreel.',
        'Aquele que morrer dos de Acabe, na cidade, os cães o comerão; e o que morrer no campo as aves do céu o comerão.',
        'Porém ninguém fora como Acabe, que se vendera para fazer o que era mau aos olhos do Senhor; porque Jezabel, sua mulher, o incitava.',
        'E fez grandes abominações, seguindo os ídolos, conforme a tudo o que fizeram os amorreus, os quais o Senhor lançou fora da sua possessão, de diante dos filhos de Israel.',
        'Sucedeu, pois, que Acabe, ouvindo estas palavras, rasgou as suas vestes, e cobriu a sua carne de saco, e jejuou; e jazia em saco, e andava mansamente.',
        'Então veio a palavra do Senhor a Elias tisbita, dizendo:',
        'Não viste que Acabe se humilha perante mim? Por isso, porquanto se humilha perante mim, não trarei este mal nos seus dias, mas nos dias de seu filho o trarei sobre a sua casa.',
      ],
      [
        'E estiveram quietos três anos, não havendo guerra entre a Síria e Israel.',
        'Porém no terceiro ano sucedeu que Jeosafá, rei de Judá, desceu para avistar-se com o rei de Israel.',
        'E o rei de Israel disse aos seus servos: Não sabeis vós que Ramote de Gileade é nossa, e nós estamos quietos, sem a tomar da mão do rei da Síria?',
        'Então perguntou a Jeosafá: Irás tu comigo à peleja a Ramote de Gileade? E disse Jeosafá ao rei de Israel: Serei como tu, e o meu povo como o teu povo, e os meus cavalos como os teus cavalos.',
        'Disse mais Jeosafá ao rei de Israel: Peço-te, consulta hoje a palavra do Senhor.',
        'Então o rei de Israel reuniu os profetas até quase quatrocentos homens, e disse-lhes: Irei à peleja contra Ramote de Gileade, ou deixarei de ir? E eles disseram: Sobe, porque o Senhor a entregará na mão do rei.',
        'Disse, porém, Jeosafá: Não há aqui ainda algum profeta do Senhor, ao qual possamos consultar?',
        'Então disse o rei de Israel a Jeosafá: Ainda há um homem por quem podemos consultar ao Senhor; porém eu o odeio, porque nunca profetiza de mim o que é bom, mas só o mal; este é Micaías, filho de Inlá. E disse Jeosafá: Não fale o rei assim.',
        'Então o rei de Israel chamou um oficial, e disse: Traze-me depressa a Micaías, filho de Inlá.',
        'E o rei de Israel e Jeosafá, rei de Judá, estavam assentados cada um no seu trono, vestidos de trajes reais, na praça, à entrada da porta de Samaria; e todos os profetas profetizavam na sua presença.',
        'E Zedequias, filho de Quenaaná, fez para si uns chifres de ferro, e disse: Assim diz o Senhor: Com estes ferirás aos sírios, até de todo os consumir.',
        'E todos os profetas profetizaram assim, dizendo: Sobe a Ramote de Gileade, e triunfarás, porque o Senhor a entregará na mão do rei.',
        'E o mensageiro que foi chamar a Micaías falou-lhe, dizendo: Vês aqui que as palavras dos profetas a uma voz predizem coisas boas para o rei; seja, pois, a tua palavra como a palavra de um deles, e fala bem.',
        'Porém Micaías disse: Vive o Senhor que o que o Senhor me disser isso falarei.',
        'E, vindo ele ao rei, o rei lhe disse: Micaías, iremos a Ramote de Gileade à peleja, ou deixaremos de ir? E ele lhe disse: Sobe, e serás bem sucedido; porque o Senhor a entregará na mão do rei.',
        'E o rei lhe disse: Até quantas vezes te conjurarei, que não me fales senão a verdade em nome do Senhor?',
        'Então disse ele: Vi a todo o Israel disperso pelos montes, como ovelhas que não tem pastor; e disse o Senhor: Estes não têm senhor; torne cada um em paz para sua casa.',
        'Então o rei de Israel disse a Jeosafá: Não te disse eu, que nunca profetizará de mim o que é bom, senão só o que é mal?',
        'Então ele disse: Ouve, pois, a palavra do Senhor: Vi ao Senhor assentado sobre o seu trono, e todo o exército do céu estava junto a ele, à sua mão direita e à sua esquerda.',
        'E disse o Senhor: Quem induzirá Acabe, para que suba, e caia em Ramote de Gileade? E um dizia desta maneira e outro de outra.',
        'Então saiu um espírito, e se apresentou diante do Senhor, e disse: Eu o induzirei. E o Senhor lhe disse: Com quê?',
        'E disse ele: Eu sairei, e serei um espírito de mentira na boca de todos os seus profetas. E ele disse: Tu o induzirás, e ainda prevalecerás; sai e faze assim.',
        'Agora, pois, eis que o Senhor pôs o espírito de mentira na boca de todos estes teus profetas, e o Senhor falou o mal contra ti.',
        'Então Zedequias, filho de Quenaaná, chegou, e feriu a Micaías no queixo, e disse: Por onde saiu de mim o Espírito do Senhor para falar a ti?',
        'E disse Micaías: Eis que o verás naquele mesmo dia, quando entrares de câmara em câmara para te esconderes.',
        'Então disse o rei de Israel: Tomai a Micaías, e tornai a levá-lo a Amom, o governador da cidade e a Joás filho do rei.',
        'E direis: Assim diz o rei: Colocai este homem na casa do cárcere, e sustentai-o com o pão de angústia, e com água de amargura, até que eu venha em paz.',
        'E disse Micaías: Se tu voltares em paz, o Senhor não tem falado por mim. Disse mais: Ouvi, povos todos!',
        'Assim o rei de Israel e Jeosafá, rei de Judá, subiram a Ramote de Gileade.',
        'E disse o rei de Israel a Jeosafá: Eu me disfarçarei, e entrarei na peleja; tu porém veste as tuas roupas. Disfarçou-se, pois o rei de Israel, e entrou na peleja.',
        'E o rei da Síria dera ordem aos capitães dos carros, que eram trinta e dois, dizendo: Não pelejareis nem contra pequeno nem contra grande, mas só contra o rei de Israel.',
        'Sucedeu que, vendo os capítães dos carros a Jeosafá, disseram eles: Certamente este é o rei de Israel. E chegaram-se a ele, para pelejar com ele; porém Jeosafá gritou.',
        'E sucedeu que, vendo os capitães dos carros que não era o rei de Israel, deixaram de segui-lo.',
        'Então um homem armou o arco, e atirou a esmo, e feriu o rei de Israel por entre as fivelas e as couraças; então ele disse ao seu carreteiro: Dá volta, e tira-me do exército, porque estou gravemente ferido.',
        'E a peleja foi crescendo nequele dia, e o rei foi sustentado no carro defronte dos sírios; porém ele morreu à tarde; e o sangue da ferida corria para o fundo do carro.',
        'E depois do sol posto passou um pregão pelo exército, dizendo: Cada um para a sua cidade, e cada um para a sua terra!',
        'E morreu o rei, e o levaram a Samaria; e sepultaram o rei em Samaria.',
        'E, lavando-se o carro no tanque de Samaria, os cães lamberam o seu sangue (ora as prostitutas se lavavam ali), conforme à palavra que o Senhor tinha falado.',
        'Quanto ao mais dos atos de Acabe, e a tudo quanto fez, e à casa de marfim que edificou, porventura não está escrito no livro das crônicas dos reis de Israel?',
        'Assim dormiu Acabe com seus pais; e Acazias, seu filho, reinou em seu lugar.',
        'E Jeosafá, filho de Asa, começou a reinar sobre Judá no quarto ano de Acabe, rei de Israel.',
        'E era Jeosafá da idade de trinta e cinco anos quando começou a reinar; e vinte e cinco anos reinou em Jerusalém; e era o nome de sua mãe Azuba, filha de Sili.',
        'E andou em todos os caminhos de seu pai Asa, não se desviou deles, fazendo o que era reto aos olhos do Senhor.',
        'Todavia os altos não se tiraram; ainda o povo sacrificava e queimava incenso nos altos.',
        'E Jeosafá esteve em paz com o rei de Israel.',
        'Quanto ao mais dos atos de Jeosafá, e ao poder que mostrou, e como guerreou, porventura não está escrito no livro das crônicas dos reis de Judá?',
        'Também expulsou da terra o restante dos sodomitas, que ficaram nos dias de seu pai Asa.',
        'Então não havia rei em Edom, porém um vice-rei.',
        'E fez Jeosafá navios de Társis, para irem a Ofir por causa do ouro; porém não foram, porque os navios se quebraram em Eziom-Geber.',
        'Então Acazias, filho de Acabe, disse a Jeosafá: Vão os meus servos com os teus servos nos navios. Porém Jeosafá não quis.',
        'E Jeosafá dormiu com seus pais, e foi sepultado junto a eles, na cidade de Davi, seu pai; e Jeorão, seu filho, reinou em seu lugar.',
        'E Acazias, filho de Acabe, começou a reinar sobre Israel, em Samaria, no ano dezessete de Jeosafá, rei de Judá; e reinou dois anos sobre Israel.',
        'E fez o que era mau aos olhos do Senhor; porque andou no caminho de seu pai, como também no caminho de sua mãe, e no caminho de Jeroboão, filho de Nebate, que fez pecar a Israel.',
        'E serviu a Baal, e adorou-o, e provocou a ira do Senhor Deus de Israel, conforme a tudo quanto fizera seu pai.',
      ],
    ],
    livro: '1reis',
  },
  {
    abbrev: '2rs',
    capitulos: [
      [
        'E depois da morte de Acabe, Moabe se rebelou contra Israel.',
        'E caiu Acazias pelas grades de um quarto alto, que tinha em Samaria, e adoeceu; e enviou mensageiros, e disse-lhes: Ide, e perguntai a Baal-Zebube, deus de Ecrom, se sararei desta doença.',
        'Mas o anjo do Senhor disse a Elias, o tisbita: Levanta-te, sobe para te encontrares com os mensageiros do rei de Samaria, e dize-lhes: Porventura não há Deus em Israel, para irdes consultar a Baal-Zebube, deus de Ecrom?',
        'E por isso assim diz o Senhor: Da cama, a que subiste, não descerás, mas sem falta morrerás. Então Elias partiu.',
        'E os mensageiros voltaram para ele; e ele lhes disse: Que há, que voltastes?',
        'E eles lhe disseram: Um homem saiu ao nosso encontro, e nos disse: Ide, voltai para o rei que vos mandou, e dizei-lhe: Assim diz o Senhor: Porventura não há Deus em Israel, para que mandes consultar a Baal-Zebube, deus de Ecrom? Portanto da cama, a que subiste, não descerás, mas sem falta morrerás.',
        'E ele lhes disse: Qual era a aparência do homem que veio ao vosso encontro e vos falou estas palavras?',
        'E eles lhe disseram: Era um homem peludo, e com os lombos cingidos de um cinto de couro. Então disse ele: É Elias, o tisbita.',
        'Então o rei lhe enviou um capitão de cinqüenta com seus cinqüenta; e, subindo a ele (porque eis que estava assentado no cume do monte), disse-lhe: Homem de Deus, o rei diz: Desce.',
        'Mas Elias respondeu, e disse ao capitão de cinqüenta: Se eu, pois, sou homem de Deus, desça fogo do céu, e te consuma a ti e aos teus cinqüenta. Então fogo desceu do céu, e consumiu a ele e aos seus cinqüenta.',
        'E tornou o rei a enviar-lhe outro capitão de cinqüenta, com os seus cinqüenta; ele lhe respondeu, dizendo: Homem de Deus, assim diz o rei: Desce depressa.',
        'E respondeu Elias: Se eu sou homem de Deus, desça fogo do céu, e te consuma a ti e aos teus cinqüenta. Então o fogo de Deus desceu do céu, e o consumiu a ele e aos seus cinqüenta.',
        'E tornou a enviar um terceiro capitão de cinqüenta, com os seus cinqüenta; então subiu o capitão de cinqüenta e, chegando, pôs-se de joelhos diante de Elias, e suplicou-lhe, dizendo: Homem de Deus, seja, peço-te, preciosa aos teus olhos a minha vida, e a vida destes cinqüenta teus servos.',
        'Eis que fogo desceu do céu, e consumiu aqueles dois primeiros capitães de cinqüenta, com os seus cinqüenta; porém, agora seja preciosa aos teus olhos a minha vida.',
        'Então o anjo do Senhor disse a Elias: Desce com este, não temas. E levantou-se, e desceu com ele ao rei.',
        'E disse-lhe: Assim diz o Senhor: Por que enviaste mensageiros a consultar a Baal-Zebube, deus de Ecrom? Porventura é porque não há Deus em Israel, para consultar a sua palavra? Portanto desta cama, a que subiste, não descerás, mas certamente morrerás.',
        'Assim, pois, morreu, conforme a palavra do Senhor, que Elias falara; e Jorão começou a reinar no seu lugar no ano segundo de Jeorão, filho de Jeosafá, rei de Judá; porquanto não tinha filho.',
        'O mais dos atos de Acazias, tudo quanto fez, porventura não está escrito no livro das crônicas dos reis de Israel?',
      ],
      [
        'Sucedeu que, quando o SENHOR estava para elevar a Elias num redemoinho ao céu, Elias partiu de Gilgal com Eliseu.',
        'E disse Elias a Eliseu: Fica-te aqui, porque o Senhor me enviou a Betel. Porém Eliseu disse: Vive o Senhor, e vive a tua alma, que não te deixarei. E assim foram a Betel.',
        'Então os filhos dos profetas que estavam em Betel saíram ao encontro de Eliseu, e lhe disseram: Sabes que o SENHOR hoje tomará o teu senhor por sobre a tua cabeça? E ele disse: Também eu bem o sei; calai-vos.',
        'E Elias lhe disse: Eliseu, fica-te aqui, porque o Senhor me enviou a Jericó. Porém ele disse: Vive o Senhor, e vive a tua alma, que não te deixarei. E assim foram a Jericó.',
        'Então os filhos dos profetas que estavam em Jericó se chegaram a Eliseu, e lhe disseram: Sabes que o SENHOR hoje tomará o teu senhor por sobre a tua cabeça? E ele disse: Também eu bem o sei; calai-vos.',
        'E Elias disse: Fica-te aqui, porque o Senhor me enviou ao Jordão. Mas ele disse: Vive o Senhor, e vive a tua alma, que não te deixarei. E assim ambos foram juntos.',
        'E foram cinqüenta homens dos filhos dos profetas, e pararam defronte deles, de longe: e assim ambos pararam junto ao Jordão.',
        'Então Elias tomou a sua capa e a dobrou, e feriu as águas, as quais se dividiram para os dois lados; e passaram ambos em seco.',
        'Sucedeu que, havendo eles passado, Elias disse a Eliseu: Pede-me o que queres que te faça, antes que seja tomado de ti. E disse Eliseu: Peço-te que haja porção dobrada de teu espírito sobre mim.',
        'E disse: Coisa difícil pediste; se me vires quando for tomado de ti, assim se te fará, porém, se não, não se fará.',
        'E sucedeu que, indo eles andando e falando, eis que um carro de fogo, com cavalos de fogo, os separou um do outro; e Elias subiu ao céu num redemoinho.',
        'O que vendo Eliseu, clamou: Meu pai, meu pai, carros de Israel, e seus cavaleiros! E nunca mais o viu; e, pegando as suas vestes, rasgou-as em duas partes.',
        'Também levantou a capa de Elias, que dele caíra; e, voltando-se, parou à margem do Jordão.',
        'E tomou a capa de Elias, que dele caíra, e feriu as águas, e disse: Onde está o Senhor Deus de Elias? Quando feriu as águas elas se dividiram de um ao outro lado; e Eliseu passou.',
        'Vendo-o, pois, os filhos dos profetas que estavam defronte em Jericó, disseram: O espírito de Elias repousa sobre Eliseu. E vieram-lhe ao encontro, e se prostraram diante dele em terra.',
        'E disseram-lhe: Eis que agora entre os teus servos há cinqüenta homens valentes; ora deixa-os ir para buscar a teu senhor; pode ser que o elevasse o Espírito do SENHOR e o lançasse em algum dos montes, ou em algum dos vales. Porém ele disse: Não os envieis.',
        'Mas eles insistiram com ele, até que, constrangido, disse-lhes: Enviai. E enviaram cinqüenta homens, que o buscaram três dias, porém não o acharam.',
        'Então voltaram para ele, pois ficara em Jericó; e disse-lhes: Eu não vos disse que não fôsseis?',
        'E os homens da cidade disseram a Eliseu: Eis que é boa a situação desta cidade, como o meu senhor vê; porém as águas são más, e a terra é estéril.',
        'E ele disse: Trazei-me um prato novo, e ponde nele sal. E lho trouxeram.',
        'Então saiu ele ao manancial das águas, e deitou sal nele; e disse: Assim diz o Senhor: Sararei a estas águas; e não haverá mais nelas morte nem esterilidade.',
        'Ficaram, pois, sãs aquelas águas, até ao dia de hoje, conforme a palavra que Eliseu tinha falado.',
        'Então subiu dali a Betel; e, subindo ele pelo caminho, uns meninos saíram da cidade, e zombavam dele, e diziam-lhe: Sobe, calvo; sobe, calvo!',
        'E, virando-se ele para trás, os viu, e os amaldiçoou no nome do Senhor; então duas ursas saíram do bosque, e despedaçaram quarenta e dois daqueles meninos.',
        'E dali foi para o monte Carmelo de onde voltou para Samaria.',
      ],
      [
        'E Jorão, filho de Acabe, começou a reinar sobre Israel, em Samaria, no décimo oitavo ano de Jeosafá, rei de Judá; e reinou doze anos.',
        'E fez o que era mau aos olhos do Senhor; porém não como seu pai, nem como sua mãe; porque tirou a estátua de Baal, que seu pai fizera.',
        'Contudo aderiu aos pecados de Jeroboão, filho de Nebate, com que fizera Israel pecar; não se apartou deles.',
        'Então Mesa, rei dos moabitas, era criador de gado, e pagava de tributo, ao rei de Israel, cem mil cordeiros, e cem mil carneiros com a sua lã.',
        'Sucedeu, porém, que, morrendo Acabe, o rei dos moabitas se rebelou contra o rei de Israel.',
        'Por isso Jorão ao mesmo tempo saiu de Samaria, e fez revista de todo o Israel.',
        'E foi, e mandou dizer a Jeosafá, rei de Judá: O rei dos moabitas se rebelou contra mim; irás tu comigo à guerra contra os moabitas? E disse ele: Subirei; e eu serei como tu, o meu povo como o teu povo, e os meus cavalos como os teus cavalos.',
        'E ele disse: Por que caminho subiremos? Então disse ele: Pelo caminho do deserto de Edom.',
        'E partiram o rei de Israel, o rei de Judá e o rei de Edom; e andaram rodeando com uma marcha de sete dias, e não havia água para o exército e nem para o gado que os seguia.',
        'Então disse o rei de Israel: Ah! o Senhor chamou a estes três reis, para entregá-los nas mãos dos moabitas.',
        'E disse Jeosafá: Não há aqui algum profeta do Senhor, para que consultemos ao Senhor por ele? Então respondeu um dos servos do rei de Israel, dizendo: Aqui está Eliseu, filho de Safate, que derramava água sobre as mãos de Elias.',
        'E disse Jeosafá: Está com ele a palavra do Senhor. Então o rei de Israel, Jeosafá, e o rei de Edom desceram a ter com ele.',
        'Mas Eliseu disse ao rei de Israel: Que tenho eu contigo? Vai aos profetas de teu pai e aos profetas de tua mãe. Porém o rei de Israel lhe disse: Não, porque o Senhor chamou a estes três reis para entregá-los nas mãos dos moabitas.',
        'E disse Eliseu: Vive o Senhor dos Exércitos, em cuja presença estou, que se eu não respeitasse a presença de Jeosafá, rei de Judá, não olharia para ti nem te veria.',
        'Ora, pois, trazei-me um músico. E sucedeu que, tocando o músico, veio sobre ele a mão do Senhor.',
        'E disse: Assim diz o Senhor: Fazei neste vale muitas covas.',
        'Porque assim diz o Senhor: Não vereis vento, e não vereis chuva; todavia este vale se encherá de tanta água, que bebereis vós, o vosso gado e os vossos animais.',
        'E ainda isto é pouco aos olhos do Senhor; também entregará ele os moabitas nas vossas mãos.',
        'E ferireis a todas as cidades fortes, e a todas as cidades escolhidas, e todas as boas árvores cortareis, e entupireis todas as fontes de água, e danificareis com pedras todos os bons campos.',
        'E sucedeu que, pela manhã, oferecendo-se a oferta de alimentos, eis que vinham as águas pelo caminho de Edom; e a terra se encheu de água.',
        'Ouvindo, pois, todos os moabitas que os reis tinham subido para pelejarem contra eles, convocaram a todos os que estavam em idade de cingir cinto e daí para cima, e puseram-se às fronteiras.',
        'E, levantando-se de madrugada, e saindo o sol sobre as águas, viram os moabitas, defronte deles, as águas vermelhas como sangue.',
        'E disseram: Isto é sangue; certamente que os reis se destruíram à espada e se mataram um ao outro! Agora, pois, à presa, moabitas!',
        'Porém, chegando eles ao arraial de Israel, os israelitas se levantaram, e feriram os moabitas, os quais fugiram diante deles e ainda entraram nas suas terras, ferindo ali também os moabitas.',
        'E arrasaram as cidades, e cada um lançou a sua pedra em todos os bons campos, e os entulharam, e entupiram todas as fontes de água, e cortaram todas as boas árvores, até que só em Quir-Haresete deixaram ficar as pedras, mas os fundeiros a cercaram e a feriram.',
        'Mas, vendo o rei dos moabitas que a peleja prevalecia contra ele, tomou consigo setecentos homens que sacavam espada, para romperem contra o rei de Edom, porém não puderam.',
        'Então tomou a seu filho primogênito, que havia de reinar em seu lugar, e o ofereceu em holocausto sobre o muro; pelo que houve grande indignação em Israel; por isso retiraram-se dele, e voltaram para a sua terra.',
      ],
      [
        'E uma mulher, das mulheres dos filhos dos profetas, clamou a Eliseu, dizendo: Meu marido, teu servo, morreu; e tu sabes que o teu servo temia ao SENHOR; e veio o credor, para levar os meus dois filhos para serem servos.',
        'E Eliseu lhe disse: Que te hei de fazer? Dize-me que é o que tens em casa. E ela disse: Tua serva não tem nada em casa, senão uma botija de azeite.',
        'Então disse ele: Vai, pede emprestadas, de todos os teus vizinhos, vasilhas vazias, não poucas.',
        'Então entra, e fecha a porta sobre ti, e sobre teus filhos, e deita o azeite em todas aquelas vasilhas, e põe à parte a que estiver cheia.',
        'Partiu, pois, dele, e fechou a porta sobre si e sobre seus filhos; e eles lhe traziam as vasilhas, e ela as enchia.',
        'E sucedeu que, cheias que foram as vasilhas, disse a seu filho: Traze-me ainda uma vasilha. Porém ele lhe disse: Não há mais vasilha alguma. Então o azeite parou.',
        'Então veio ela, e o fez saber ao homem de Deus; e disse ele: Vai, vende o azeite, e paga a tua dívida; e tu e teus filhos vivei do resto.',
        'Sucedeu também um dia que, indo Eliseu a Suném, havia ali uma mulher importante, a qual o reteve para comer pão; e sucedeu que todas as vezes que passava por ali entrava para comer pão.',
        'E ela disse a seu marido: Eis que tenho observado que este que sempre passa por nós é um santo homem de Deus.',
        'Façamos-lhe, pois, um pequeno quarto junto ao muro, e ali lhe ponhamos uma cama, uma mesa, uma cadeira e um candeeiro; e há de ser que, vindo ele a nós, para ali se recolherá.',
        'E sucedeu que um dia ele chegou ali, e recolheu-se àquele quarto, e se deitou.',
        'Então disse ao seu servo Geazi: Chama esta sunamita. E chamando-a ele, ela se pôs diante dele.',
        'Porque ele tinha falado a Geazi: Dize-lhe: Eis que tu nos tens tratado com todo o desvelo; que se há de fazer por ti? Haverá alguma coisa de que se fale por ti ao rei, ou ao capitão do exército? E disse ela: Eu habito no meio do meu povo.',
        'Então disse ele: Que se há de fazer por ela? E Geazi disse: Ora ela não tem filho, e seu marido é velho.',
        'Por isso disse ele: Chama-a. E, chamando-a ele, ela se pôs à porta.',
        'E ele disse: A este tempo determinado, segundo o tempo da vida, abraçarás um filho. E disse ela: Não, meu senhor, homem de Deus, não mintas à tua serva.',
        'E concebeu a mulher, e deu à luz um filho, no tempo determinado, no ano seguinte, segundo Eliseu lhe dissera.',
        'E, crescendo o filho, sucedeu que um dia saiu para ter com seu pai, que estava com os segadores,',
        'E disse a seu pai: Ai, a minha cabeça! Ai, a minha cabeça! Então disse a um moço: Leva-o à sua mãe.',
        'E ele o tomou, e o levou à sua mãe; e esteve sobre os seus joelhos até ao meio-dia, e morreu.',
        'E subiu ela, e o deitou sobre a cama do homem de Deus; e fechou a porta, e saiu.',
        'E chamou a seu marido, e disse: Manda-me já um dos moços, e uma das jumentas, para que eu corra ao homem de Deus, e volte.',
        'E disse ele: Por que vais a ele hoje? Não é lua nova nem sábado. E ela disse: Tudo vai bem.',
        'Então albardou a jumenta, e disse ao seu servo: Guia e anda, e não te detenhas no caminhar, senão quando eu to disser.',
        'Partiu ela, pois, e foi ao homem de Deus, ao monte Carmelo; e sucedeu que, vendo-a o homem de Deus de longe, disse a Geazi, seu servo: Eis aí a sunamita.',
        'Agora, pois, corre-lhe ao encontro e dize-lhe: Vai bem contigo? Vai bem com teu marido? Vai bem com teu filho? E ela disse: Vai bem.',
        'Chegando ela, pois, ao homem de Deus, ao monte, pegou nos seus pés; mas chegou Geazi para retirá-la; disse porém o homem de Deus: Deixa-a, porque a sua alma está triste de amargura, e o Senhor me encobriu, e não me manifestou.',
        'E disse ela: Pedi eu a meu senhor algum filho? Não disse eu: Não me enganes?',
        'E ele disse a Geazi: Cinge os teus lombos, toma o meu bordão na tua mão, e vai; se encontrares alguém não o saúdes, e se alguém te saudar, não lhe respondas; e põe o meu bordão sobre o rosto do menino.',
        'Porém disse a mãe do menino: Vive o Senhor, e vive a tua alma, que não te hei de deixar. Então ele se levantou, e a seguiu.',
        'E Geazi passou adiante deles, e pôs o bordão sobre o rosto do menino; porém não havia nele voz nem sentido; e voltou a encontrar-se com ele, e lhe trouxe aviso, dizendo: O menino não despertou.',
        'E, chegando Eliseu àquela casa, eis que o menino jazia morto sobre a sua cama.',
        'Então entrou ele, e fechou a porta sobre eles ambos, e orou ao Senhor.',
        'E subiu à cama e deitou-se sobre o menino, e, pondo a sua boca sobre a boca dele, e os seus olhos sobre os olhos dele, e as suas mãos sobre as mãos dele, se estendeu sobre ele; e a carne do menino aqueceu.',
        'Depois desceu, e andou naquela casa de uma parte para a outra, e tornou a subir, e se estendeu sobre ele, então o menino espirrou sete vezes, e abriu os olhos.',
        'Então chamou a Geazi, e disse: Chama esta sunamita. E chamou-a, e veio a ele. E disse ele: Toma o teu filho.',
        'E entrou ela, e se prostrou a seus pés, e se inclinou à terra; e tomou o seu filho e saiu.',
        'E, voltando Eliseu a Gilgal, havia fome naquela terra, e os filhos dos profetas estavam assentados na sua presença; e disse ao seu servo: Põe a panela grande ao lume, e faze um caldo de ervas para os filhos dos profetas.',
        'Então um deles saiu ao campo a apanhar ervas, e achou uma parra brava, e colheu dela enchendo a sua capa de colocíntidas; e veio, e as cortou na panela do caldo; porque não as conheciam.',
        'Assim deram de comer para os homens. E sucedeu que, comendo eles daquele caldo, clamaram e disseram: Homem de Deus, há morte na panela. Não puderam comer.',
        'Porém ele disse: Trazei farinha. E deitou-a na panela, e disse: Dai de comer ao povo. E já não havia mal nenhum na panela.',
        'E um homem veio de Baal-Salisa, e trouxe ao homem de Deus pães das primícias, vinte pães de cevada, e espigas verdes na sua palha, e disse: Dá ao povo, para que coma.',
        'Porém seu servo disse: Como hei de pôr isto diante de cem homens? E disse ele: Dá ao povo, para que coma; porque assim diz o Senhor: Comerão, e sobejará.',
        'Então lhos pôs diante, e comeram e ainda sobrou, conforme a palavra do Senhor.',
      ],
      [
        'E Naamã, capitão do exército do rei da Síria, era um grande homem diante do seu SENHOR, e de muito respeito; porque por ele o SENHOR dera livramento aos sírios; e era este homem herói valoroso, porém leproso.',
        'E saíram tropas da Síria, da terra de Israel, e levaram presa uma menina que ficou ao serviço da mulher de Naamã.',
        'E disse esta à sua senhora: Antes o meu senhor estivesse diante do profeta que está em Samaria; ele o restauraria da sua lepra.',
        'Então foi Naamã e notificou ao seu senhor, dizendo: Assim e assim falou a menina que é da terra de Israel.',
        'Então disse o rei da Síria: Vai, anda, e enviarei uma carta ao rei de Israel. E foi, e tomou na sua mão dez talentos de prata, seis mil siclos de ouro e dez mudas de roupas.',
        'E levou a carta ao rei de Israel, dizendo: Logo, em chegando a ti esta carta, saibas que eu te enviei Naamã, meu servo, para que o cures da sua lepra.',
        'E sucedeu que, lendo o rei de Israel a carta, rasgou as suas vestes, e disse: Sou eu Deus, para matar e para vivificar, para que este envie a mim um homem, para que eu o cure da sua lepra? Pelo que deveras notai, peço-vos, e vede que busca ocasião contra mim.',
        'Sucedeu, porém, que, ouvindo Eliseu, homem de Deus, que o rei de Israel rasgara as suas vestes, mandou dizer ao rei: Por que rasgaste as tuas vestes? Deixa-o vir a mim, e saberá que há profeta em Israel.',
        'Veio, pois, Naamã com os seus cavalos, e com o seu carro, e parou à porta da casa de Eliseu.',
        'Então Eliseu lhe mandou um mensageiro, dizendo: Vai, e lava-te sete vezes no Jordão, e a tua carne será curada e ficarás purificado.',
        'Porém, Naamã muito se indignou, e se foi, dizendo: Eis que eu dizia comigo: Certamente ele sairá, pôr-se-á em pé, invocará o nome do Senhor seu Deus, e passará a sua mão sobre o lugar, e restaurará o leproso.',
        'Não são porventura Abana e Farpar, rios de Damasco, melhores do que todas as águas de Israel? Não me poderia eu lavar neles, e ficar purificado? E voltou-se, e se foi com indignação.',
        'Então chegaram-se a ele os seus servos, e lhe falaram, e disseram: Meu pai, se o profeta te dissesse alguma grande coisa, porventura não a farias? Quanto mais, dizendo-te ele: Lava-te, e ficarás purificado.',
        'Então desceu, e mergulhou no Jordão sete vezes, conforme a palavra do homem de Deus; e a sua carne tornou-se como a carne de um menino, e ficou purificado.',
        'Então voltou ao homem de Deus, ele e toda a sua comitiva, e chegando, pôs-se diante dele, e disse: Eis que agora sei que em toda a terra não há Deus senão em Israel; agora, pois, peço-te que aceites uma bênção do teu servo.',
        'Porém ele disse: Vive o Senhor, em cuja presença estou, que não a aceitarei. E instou com ele para que a aceitasse, mas ele recusou.',
        'E disse Naamã: Se não queres, dê-se a este teu servo uma carga de terra que baste para carregar duas mulas; porque nunca mais oferecerá este teu servo holocausto nem sacrifício a outros deuses, senão ao Senhor.',
        'Nisto perdoe o SENHOR a teu servo; quando meu senhor entrar na casa de Rimom para ali adorar, e ele se encostar na minha mão, e eu também tenha de me encurvar na casa de Rimom; quando assim me encurvar na casa de Rimom, nisto perdoe o SENHOR a teu servo.',
        'E ele lhe disse: Vai em paz. E foi dele a uma pequena distância.',
        'Então Geazi, servo de Eliseu, homem de Deus, disse: Eis que meu senhor poupou a este sírio Naamã, não recebendo da sua mão alguma coisa do que trazia; porém, vive o SENHOR que hei de correr atrás dele, e receber dele alguma coisa.',
        'E foi Geazi a alcançar Naamã; e Naamã, vendo que corria atrás dele, desceu do carro a encontrá-lo, e disse-lhe: Vai tudo bem?',
        'E ele disse: Tudo vai bem; meu senhor me mandou dizer: Eis que agora mesmo vieram a mim dois jovens dos filhos dos profetas da montanha de Efraim; dá-lhes, pois, um talento de prata e duas mudas de roupas.',
        'E disse Naamã: Sê servido tomar dois talentos. E instou com ele, e amarrou dois talentos de prata em dois sacos, com duas mudas de roupas; e pô-los sobre dois dos seus servos, os quais os levaram diante dele.',
        'E, chegando ele a certa altura, tomou-os das suas mãos, e os depositou na casa; e despediu aqueles homens, e foram-se.',
        'Então ele entrou, e pôs-se diante de seu senhor. E disse-lhe Eliseu: Donde vens, Geazi? E disse: Teu servo não foi nem a uma nem a outra parte.',
        'Porém ele lhe disse: Porventura não foi contigo o meu coração, quando aquele homem voltou do seu carro a encontrar-te? Era a ocasião para receberes prata, e para tomares roupas, olivais e vinhas, ovelhas e bois, servos e servas?',
        'Portanto a lepra de Naamã se pegará a ti e à tua descendência para sempre. Então saiu de diante dele leproso, branco como a neve.',
      ],
      [
        'E disseram os filhos dos profetas a Eliseu: Eis que o lugar em que habitamos diante da tua face, nos é estreito.',
        'Vamos, pois, até ao Jordão e tomemos de lá, cada um de nós, uma viga, e façamo-nos ali um lugar para habitar. E disse ele: Ide.',
        'E disse um: Serve-te de ires com os teus servos. E disse: Eu irei.',
        'E foi com eles; e, chegando eles ao Jordão, cortaram madeira.',
        'E sucedeu que, derrubando um deles uma viga, o ferro caiu na água; e clamou, e disse: Ai, meu senhor! ele era emprestado.',
        'E disse o homem de Deus: Onde caiu? E mostrando-lhe ele o lugar, cortou um pau, e o lançou ali, e fez flutuar o ferro.',
        'E disse: Levanta-o. Então ele estendeu a sua mão e o tomou.',
        'E o rei da Síria fazia guerra a Israel; e consultou com os seus servos, dizendo: Em tal e tal lugar estará o meu acampamento.',
        'Mas o homem de Deus enviou ao rei de Israel, dizendo: Guarda-te de passares por tal lugar; porque os sírios desceram ali.',
        'Por isso o rei de Israel enviou àquele lugar, de que o homem de Deus lhe dissera, e de que o tinha avisado, e se guardou ali, não uma nem duas vezes.',
        'Então se turbou com este incidente o coração do rei da Síria, chamou os seus servos, e lhes disse: Não me fareis saber quem dos nossos é pelo rei de Israel?',
        'E disse um dos servos: Não, ó rei meu senhor; mas o profeta Eliseu, que está em Israel, faz saber ao rei de Israel as palavras que tu falas no teu quarto de dormir.',
        'E ele disse: Vai, e vê onde ele está, para que envie, e mande trazê-lo. E fizeram-lhe saber, dizendo: Eis que está em Dotã.',
        'Então enviou para lá cavalos, e carros, e um grande exército, os quais chegaram de noite, e cercaram a cidade.',
        'E o servo do homem de Deus se levantou muito cedo e saiu, e eis que um exército tinha cercado a cidade com cavalos e carros; então o seu servo lhe disse: Ai, meu senhor! Que faremos?',
        'E ele disse: Não temas; porque mais são os que estão conosco do que os que estão com eles.',
        'E orou Eliseu, e disse: Senhor, peço-te que lhe abras os olhos, para que veja. E o Senhor abriu os olhos do moço, e viu; e eis que o monte estava cheio de cavalos e carros de fogo, em redor de Eliseu.',
        'E, como desceram a ele, Eliseu orou ao Senhor e disse: Fere, peço-te, esta gente de cegueira. E feriu-a de cegueira, conforme a palavra de Eliseu.',
        'Então Eliseu lhes disse: Não é este o caminho, nem é esta a cidade; segui-me, e guiar-vos-ei ao homem que buscais. E os guiou a Samaria.',
        'E sucedeu que, chegando eles a Samaria, disse Eliseu: Ó Senhor, abre a estes os olhos para que vejam. O Senhor lhes abriu os olhos, para que vissem, e eis que estavam no meio de Samaria.',
        'E, quando o rei de Israel os viu, disse a Eliseu: Feri-los-ei, feri-los-ei, meu pai?',
        'Mas ele disse: Não os ferirás; feririas tu os que tomasses prisioneiros com a tua espada e com o teu arco? Põe-lhes diante pão e água, para que comam e bebam, e se vão para seu senhor.',
        'E apresentou-lhes um grande banquete, e comeram e beberam; e os despediu e foram para seu senhor; e não entraram mais tropas de sírios na terra de Israel.',
        'E sucedeu, depois disto, que Ben-Hadade, rei da Síria, ajuntou todo o seu exército; e subiu e cercou a Samaria.',
        'E houve grande fome em Samaria, porque eis que a cercaram, até que se vendeu uma cabeça de um jumento por oitenta peças de prata, e a quarta parte de um cabo de esterco de pombas por cinco peças de prata.',
        'E sucedeu que, passando o rei pelo muro, uma mulher lhe bradou, dizendo: Acode-me, ó rei meu senhor.',
        'E ele lhe disse: Se o Senhor te não acode, donde te acudirei eu? Da eira ou do lagar?',
        'Disse-lhe mais o rei: Que tens? E disse ela: Esta mulher me disse: Dá cá o teu filho, para que hoje o comamos, e amanhã comeremos o meu filho.',
        'Cozemos, pois, o meu filho, e o comemos; mas dizendo-lhe eu ao outro dia: Dá cá o teu filho, para que o comamos; escondeu o seu filho.',
        'E sucedeu que, ouvindo o rei as palavras desta mulher, rasgou as suas vestes, e ia passando pelo muro; e o povo viu que o rei trazia cilício por dentro, sobre a sua carne,',
        'E disse: Assim me faça Deus, e outro tanto, se a cabeça de Eliseu, filho de Safate, hoje ficar sobre ele.',
        'Estava então Eliseu assentado em sua casa, e também os anciãos estavam assentados com ele. E enviou o rei um homem adiante de si; mas, antes que o mensageiro viesse a ele, disse ele aos anciãos: Vistes como o filho do homicida mandou tirar-me a cabeça? Olhai pois que, quando vier o mensageiro, fechai-lhe a porta, e empurrai-o para fora com a porta; porventura não vem, após ele, o ruído dos pés de seu senhor?',
        'E, estando ele ainda falando com eles, eis que o mensageiro descia a ele; e disse: Eis que este mal vem do Senhor, que mais, pois, esperaria do Senhor?',
      ],
      [
        'Então disse Eliseu: Ouvi a palavra do SENHOR; assim diz o SENHOR: Amanhã, quase a este tempo, haverá uma medida de farinha por um siclo, e duas medidas de cevada por um siclo, à porta de Samaria.',
        'Porém um senhor, em cuja mão o rei se encostava, respondeu ao homem de Deus e disse: Eis que ainda que o SENHOR fizesse janelas no céu, poder-se-ia fazer isso? E ele disse: Eis que o verás com os teus olhos, porém disso não comerás.',
        'E quatro homens leprosos estavam à entrada da porta, os quais disseram uns aos outros: Para que estaremos nós aqui até morrermos?',
        'Se dissermos: Entremos na cidade, há fome na cidade, e morreremos aí; e se ficarmos aqui, também morreremos. Vamos nós, pois, agora, e passemos para o arraial dos sírios; se nos deixarem viver, viveremos, e se nos matarem, tão-somente morreremos.',
        'E levantaram-se ao crepúsculo, para irem ao arraial dos sírios; e, chegando à entrada do arraial dos sírios, eis que não havia ali ninguém.',
        'Porque o Senhor fizera ouvir no arraial dos sírios ruído de carros e ruído de cavalos, como o ruído de um grande exército; de maneira que disseram uns aos outros: Eis que o rei de Israel alugou contra nós os reis dos heteus e os reis dos egípcios, para virem contra nós.',
        'Por isso se levantaram, e fugiram no crepúsculo, e deixaram as suas tendas, os seus cavalos, os seus jumentos e o arraial como estava; e fugiram para salvarem a sua vida.',
        'Chegando, pois, estes leprosos à entrada do arraial, entraram numa tenda, e comeram, beberam e tomaram dali prata, ouro e roupas, e foram e os esconderam; então voltaram, e entraram em outra tenda, e dali também tomaram alguma coisa e a esconderam.',
        'Então disseram uns para os outros: Não fazemos bem; este dia é dia de boas novas, e nos calamos; se esperarmos até à luz da manhã, algum mal nos sobrevirá; por isso agora vamos, e o anunciaremos à casa do rei.',
        'Vieram, pois, e bradaram aos porteiros da cidade, e lhes anunciaram, dizendo: Fomos ao arraial dos sírios e eis que lá não havia ninguém, nem voz de homem, porém só cavalos atados, jumentos atados, e as tendas como estavam.',
        'E chamaram os porteiros, e o anunciaram dentro da casa do rei.',
        'E o rei se levantou de noite, e disse a seus servos: Agora vos farei saber o que é que os sírios nos fizeram; bem sabem eles que esfaimados estamos, pelo que saíram do arraial, a esconder-se pelo campo, dizendo: Quando saírem da cidade, então os tomaremos vivos, e entraremos na cidade.',
        'Então um dos seus servos respondeu e disse: Tomem-se, pois, cinco dos cavalos que restam aqui dentro (eis que são como toda a multidão dos israelitas que ficaram aqui; e eis que são como toda a multidão dos israelitas que já pereceram) e enviemo-los, e vejamos.',
        'Tomaram, pois, dois cavalos de carro; e o rei os enviou com mensageiros após o exército dos sírios, dizendo: Ide, e vede.',
        'E foram após eles até ao Jordão, e eis que todo o caminho estava cheio de roupas e de aviamentos que os sírios, apressando-se, lançaram fora; e voltaram os mensageiros e o anunciaram ao rei.',
        'Então saiu o povo, e saqueou o arraial dos sírios; e havia uma medida de farinha por um siclo, e duas medidas de cevada por um siclo, conforme a palavra do Senhor.',
        'E pusera o rei à porta o senhor em cuja mão se encostava; e o povo o atropelou na porta, e morreu, como falara o homem de Deus, o que falou quando o rei descera a ele.',
        'Porque assim sucedeu como o homem de Deus falara ao rei dizendo: Amanhã, quase a este tempo, haverá duas medidas de cevada por um siclo, e uma medida de farinha por um siclo, à porta de Samaria.',
        'E aquele senhor respondeu ao homem de Deus, e disse: Eis que ainda que o SENHOR fizesse janelas no céu poderia isso suceder? E ele disse: Eis que o verás com os teus olhos, porém dali não comerás.',
        'E assim lhe sucedeu, porque o povo o atropelou à porta, e morreu.',
      ],
      [
        'E falou Eliseu àquela mulher cujo filho ele ressuscitara, dizendo: Levanta-te e vai, tu e a tua família, e peregrina onde puderes peregrinar; porque o SENHOR chamou a fome, a qual também virá à terra por sete anos.',
        'E levantou-se a mulher, e fez conforme a palavra do homem de Deus; porque foi ela com a sua família, e peregrinou na terra dos filisteus sete anos.',
        'E sucedeu que, ao fim dos sete anos, a mulher voltou da terra dos filisteus, e saiu a clamar ao rei pela sua casa e pelas suas terras.',
        'Ora o rei falava a Geazi, servo do homem de Deus, dizendo: Conta-me, peço-te, todas as grandes obras que Eliseu tem feito.',
        'E sucedeu que, contando ele ao rei como ressuscitara a um morto, eis que a mulher cujo filho ressuscitara clamou ao rei pela sua casa e pelas suas terras. Então disse Geazi: Ó rei meu senhor, esta é a mulher, e este o seu filho a quem Eliseu ressuscitou.',
        'E o rei perguntou à mulher, e ela lho contou. Então o rei lhe deu um oficial, dizendo: Faze-lhe restituir tudo quanto era seu, e todas as rendas das terras desde o dia em que deixou a terra até agora.',
        'Depois veio Eliseu a Damasco, estando Ben-Hadade, rei da Síria, doente; e lho anunciaram, dizendo: O homem de Deus é chegado aqui.',
        'Então o rei disse a Hazael: Toma um presente na tua mão, e vai a encontrar-te com o homem de Deus; e pergunta por ele ao Senhor, dizendo: Hei de sarar desta doença?',
        'Foi, pois, Hazael a encontrar-se com ele, e tomou um presente na sua mão, a saber: de tudo o que de bom havia em Damasco, quarenta camelos carregados; e veio, e se pôs diante dele e disse: Teu filho Ben-Hadade, rei da Síria, me enviou a ti, a dizer: Sararei eu desta doença?',
        'E Eliseu lhe disse: Vai, e dize-lhe: Certamente viverás. Porém, o Senhor me tem mostrado que certamente morrerá.',
        'E afirmou a sua vista, e fitou os olhos nele até se envergonhar; e o homem de Deus chorou.',
        'Então disse Hazael: Por que chora o meu senhor? E ele disse: Porque sei o mal que hás de fazer aos filhos de Israel; porás fogo às suas fortalezas, e os seus jovens matarás à espada, e os seus meninos despedaçarás, e as suas mulheres grávidas fenderás.',
        'E disse Hazael: Pois, que é teu servo, que não é mais do que um cão, para fazer tão grande coisa? E disse Eliseu: O Senhor me tem mostrado que tu hás de ser rei da Síria.',
        'Então partiu de Eliseu, e foi a seu senhor, o qual lhe disse: Que te disse Eliseu? E disse ele: Disse-me que certamente viverás.',
        'E sucedeu que no outro dia tomou um cobertor e o molhou na água, e o estendeu sobre o seu rosto, e morreu; e Hazael reinou em seu lugar.',
        'E no ano quinto de Jorão, filho de Acabe, rei de Israel, reinando ainda Jeosafá em Judá, começou a reinar Jeorão, filho de Jeosafá, rei de Judá.',
        'Era ele da idade de trinta e dois anos quando começou a reinar, e oito anos reinou em Jerusalém.',
        'E andou no caminho dos reis de Israel, como também fizeram os da casa de Acabe, porque tinha por mulher a filha de Acabe, e fez o que era mal aos olhos do Senhor.',
        'Porém o Senhor não quis destruir a Judá por amor de Davi, seu servo, como lhe tinha falado que lhe daria, para sempre, uma lâmpada, a ele e a seus filhos.',
        'Nos seus dias se rebelaram os edomitas, contra o mando de Judá, e puseram sobre si um rei.',
        'Por isso Jeorão passou a Zair, e todos os carros com ele; e ele se levantou de noite, e feriu os edomitas que estavam ao redor dele, e os capitães dos carros; e o povo foi para as suas tendas.',
        'Todavia os edomitas ficaram rebeldes, contra o mando de Judá, até ao dia de hoje; então, no mesmo tempo, Libna também se rebelou.',
        'O mais dos atos de Jeorão, e tudo quanto fez, porventura não está escrito no livro das crônicas de Judá?',
        'E Jeorão dormiu com seus pais, e foi sepultado com seus pais na cidade de Davi; e Acazias, seu filho, reinou em seu lugar.',
        'No ano doze de Jorão, filho de Acabe, rei de Israel, começou a reinar Acazias, filho de Jeorão, rei de Judá.',
        'Era Acazias de vinte e dois anos de idade quando começou a reinar, e reinou um ano em Jerusalém; e era o nome de sua mãe Atalia, filha de Onri, rei de Israel.',
        'E andou no caminho da casa de Acabe, e fez o que era mal aos olhos do Senhor, como a casa de Acabe, porque era genro da casa de Acabe.',
        'E foi com Jorão, filho de Acabe, a Ramote de Gileade, à peleja contra Hazael, rei da Síria; e os sírios feriram a Jorão.',
        'Então voltou o rei Jorão para se curar, em Jizreel, das feridas que os sírios lhe fizeram em Ramá, quando pelejou contra Hazael, rei da Síria; e desceu Acazias, filho de Jeorão, rei de Judá, para ver a Jorão, filho de Acabe, em Jizreel, porquanto estava doente.',
      ],
      [
        'Então o profeta Eliseu chamou um dos filhos dos profetas, e lhe disse: Cinge os teus lombos; e toma este vaso de azeite na tua mão, e vai a Ramote de Gileade;',
        'E, chegando lá, vê onde está Jeú, filho de Jeosafá, filho de Ninsi; entra, e faze que ele se levante do meio de seus irmãos, e leva-o à câmara interior.',
        'E toma o vaso de azeite, e derrama-o sobre a sua cabeça, e dize: Assim diz o Senhor: Ungi-te rei sobre Israel. Então abre a porta, foge, e não te detenhas.',
        'Foi, pois, o moço, o jovem profeta, a Ramote de Gileade.',
        'E, entrando ele, eis que os capitães do exército estavam assentados ali; e disse: Capitão, tenho uma palavra que te dizer. E disse Jeú: A qual de todos nós? E disse: A ti, capitão!',
        'Então se levantou, entrou na casa, e derramou o azeite sobre a sua cabeça, e disse: Assim diz o Senhor Deus de Israel: Ungi-te rei sobre o povo do Senhor, sobre Israel.',
        'E ferirás a casa de Acabe, teu senhor, para que eu vingue o sangue de meus servos, os profetas, e o sangue de todos os servos do SENHOR, da mão de Jezabel.',
        'E toda a casa de Acabe perecerá; destruirei de Acabe todo o homem, tanto o encerrado como o absolvido em Israel.',
        'Porque à casa de Acabe hei de fazer como à casa de Jeroboão, filho de Nebate, e como à casa de Baasa, filho de Aías.',
        'E os cães comerão a Jezabel no pedaço de campo de Jizreel; não haverá quem a enterre. Então abriu a porta e fugiu.',
        'E, saindo Jeú aos servos de seu senhor, disseram-lhe: Vai tudo bem? Por que veio a ti este louco? E ele lhes disse: Bem conheceis o homem e o seu falar.',
        'Mas eles disseram: É mentira; agora faze-nos saber. E disse: Assim e assim me falou, a saber: Assim diz o Senhor: Ungi-te rei sobre Israel.',
        'Então se apressaram, tomando cada um a sua roupa puseram debaixo dele, no mais alto degrau; e tocaram a buzina e disseram: Jeú reina!',
        'Assim Jeú, filho de Jeosafá, filho de Ninsi, conspirou contra Jorão. Tinha, porém, Jorão cercado a Ramote de Gileade, ele e todo o Israel, por causa de Hazael, rei da Síria.',
        'Porém o rei Jorão voltou para se curar em Jizreel das feridas que os sírios lhe fizeram, quando pelejou contra Hazael, rei da Síria. E disse Jeú: Se é da vossa vontade, ninguém saia da cidade, nem escape, para ir denunciar isto em Jizreel.',
        'Então Jeú subiu a um carro, e foi a Jizreel, porque Jorão estava deitado ali; e também Acazias, rei de Judá, descera para ver a Jorão.',
        'E o atalaia estava na torre de Jizreel, e viu a tropa de Jeú, que vinha, e disse: Vejo uma tropa. Então disse Jorão: Toma um cavaleiro, e envia-lho ao encontro; e diga: Há paz?',
        'E o cavaleiro lhe foi ao encontro, e disse: Assim diz o rei: Há paz? E disse Jeú: Que tens tu que fazer com a paz? Passa-te para trás de mim. E o atalaia o fez saber, dizendo: Chegou a eles o mensageiro, porém não volta.',
        'Então enviou outro cavaleiro; e, chegando este a eles, disse: Assim diz o rei: Há paz? E disse Jeú: Que tens tu que fazer com a paz? Passa-te para trás de mim.',
        'E o atalaia o fez saber, dizendo: Também este chegou a eles, porém não volta; e o andar parece como o andar de Jeú, filho de Ninsi, porque anda furiosamente.',
        'Então disse Jorão: Aparelha o carro. E aparelharam o seu carro. E saiu Jorão, rei de Israel, e Acazias, rei de Judá, cada um em seu carro, e saíram ao encontro de Jeú, e o acharam no pedaço de campo de Nabote, o jizreelita.',
        'E sucedeu que, vendo Jorão a Jeú, disse: Há paz, Jeú? E disse ele: Que paz, enquanto as prostituições da tua mãe Jezabel e as suas feitiçarias são tantas?',
        'Então Jorão voltou as mãos e fugiu; e disse a Acazias: Traição há, Acazias.',
        'Mas Jeú entesou o seu arco com toda a força, e feriu a Jorão entre os braços, e a flecha lhe saiu pelo coração; e ele caiu no seu carro.',
        'Então Jeú disse a Bidcar, seu capitão: Toma-o, lança-o no pedaço do campo de Nabote, o jizreelita; porque, lembra-te de que, indo eu e tu juntos a cavalo após seu pai, Acabe, o Senhor pôs sobre ele esta sentença, dizendo:',
        'Por certo vi ontem, à tarde, o sangue de Nabote e o sangue de seus filhos, diz o Senhor; e neste mesmo campo te retribuirei, diz o Senhor. Agora, pois, toma-o e lança-o neste campo, conforme a palavra do Senhor.',
        'O que vendo Acazias, rei de Judá, fugiu pelo caminho da casa do jardim; porém Jeú o perseguiu dizendo: Feri também a este; e o feriram no carro à subida de Gur, que está junto a Ibleão. E fugiu a Megido, e morreu ali.',
        'E seus servos o levaram num carro a Jerusalém, e o sepultaram na sua sepultura junto a seus pais, na cidade de Davi',
        '(E no ano undécimo de Jorão, filho de Acabe, começou Acazias a reinar sobre Judá).',
        'Depois Jeú veio a Jizreel, o que ouvindo Jezabel, pintou-se em volta dos olhos, enfeitou a sua cabeça, e olhou pela janela.',
        'E, entrando Jeú pelas portas, disse ela: Teve paz Zinri, que matou a seu senhor?',
        'E levantou ele o rosto para a janela e disse: Quem é comigo? quem? E dois ou três eunucos olharam para ele.',
        'Então disse ele: Lançai-a daí abaixo. E lançaram-na abaixo; e foram salpicados com o seu sangue a parede e os cavalos, e Jeú a atropelou.',
        'Entrando ele e havendo comido e bebido, disse: Olhai por aquela maldita, e sepultai-a, porque é filha de rei.',
        'E foram para a sepultar; porém não acharam dela senão somente a caveira, os pés e as palmas das mãos.',
        'Então voltaram, e lho fizeram saber; e ele disse: Esta é a palavra do Senhor, a qual falou pelo ministério de Elias, o tisbita, seu servo, dizendo: No pedaço do campo de Jizreel os cães comerão a carne de Jezabel.',
        'E o cadáver de Jezabel será como esterco sobre o campo, na herdade de Jizreel; de modo que não se possa dizer: Esta é Jezabel.',
      ],
      [
        'E Acabe tinha setenta filhos em Samaria. Jeú escreveu cartas, e as enviou a Samaria, aos chefes de Jizreel, aos anciãos e aos aios dos filhos de Acabe, dizendo:',
        'Logo, em chegando a vós esta carta, pois estão convosco os filhos de vosso senhor, como também os carros, os cavalos, a cidade fortalecida e as armas,',
        'Olhai pelo melhor e mais reto dos filhos de vosso senhor, o qual ponde sobre o trono de seu pai, e pelejai pela casa de vosso senhor.',
        'Porém eles temeram muitíssimo, e disseram: Eis que dois reis não puderam resistir a ele; como, pois, poderemos nós resistir-lhe?',
        'Então o que tinha cargo da casa, e o que tinha cargo da cidade, os anciãos e os aios mandaram dizer a Jeú: Teus servos somos, e tudo quanto nos disseres faremos; a ninguém constituiremos rei; faze o que parecer bom aos teus olhos.',
        'Então segunda vez lhes escreveu outra carta, dizendo: Se fordes meus, e ouvirdes a minha voz, tomai as cabeças dos homens, filhos de vosso senhor, e vinde a mim amanhã, a este tempo, a Jizreel (os filhos do rei, setenta homens, estavam com os grandes da cidade, que os mantinham).',
        'Sucedeu que, chegada a eles a carta, tomaram os filhos do rei, e os mataram, setenta homens e puseram as suas cabeças nuns cestos, e lhas mandaram a Jizreel.',
        'E um mensageiro veio, e lhe anunciou dizendo: Trouxeram as cabeças dos filhos do rei. E ele disse: Ponde-as em dois montões à entrada da porta, até amanhã.',
        'E sucedeu que, pela manhã, saindo ele, parou, e disse a todo o povo: Vós sois justos; eis que eu conspirei contra o meu senhor, e o matei; mas quem feriu a todos estes?',
        'Sabei, pois, agora que, da palavra do Senhor que o Senhor falou contra a casa de Acabe, nada cairá em terra, porque o Senhor tem feito o que falou pelo ministério de seu servo Elias.',
        'Também Jeú feriu a todos os restantes da casa de Acabe em Jizreel, como também a todos os seus grandes, os seus conhecidos e seus sacerdotes, até não deixar nenhum restante.',
        'Então se levantou e partiu, e foi a Samaria. E, estando no caminho, em Bete-Equede dos pastores,',
        'Jeú achou os irmãos de Acazias, rei de Judá, e disse: Quem sois vós? E eles disseram: Os irmãos de Acazias somos; e descemos a saudar os filhos do rei e os filhos da rainha.',
        'Então disse ele: Apanhai-os vivos. E eles os apanharam vivos, e os mataram junto ao poço de Bete-Equede, quarenta e dois homens; e a nenhum deles deixou ficar.',
        'E, partindo dali, encontrou a Jonadabe, filho de Recabe, que lhe vinha ao encontro, o qual saudou e lhe disse: Reto é o teu coração para comigo, como o meu o é para contigo? E disse Jonadabe: É. Então, se é, dá-me a mão. E deu-lhe a mão, e Jeú fê-lo subir consigo ao carro.',
        'E disse: Vai comigo, e verás o meu zelo para com o Senhor. E o puseram no seu carro.',
        'E, chegando a Samaria, feriu a todos os que ficaram de Acabe em Samaria, até que os destruiu, conforme a palavra que o Senhor dissera a Elias.',
        'E ajuntou Jeú a todo o povo, e disse-lhe: Pouco serviu Acabe a Baal; Jeú, porém, muito o servirá.',
        'Por isso chamai-me agora todos os profetas de Baal, todos os seus servos e todos os seus sacerdotes; não falte nenhum, porque tenho um grande sacrifício a Baal; todo aquele que faltar não viverá. Porém Jeú fazia isto com astúcia, para destruir os servos de Baal.',
        'Disse mais Jeú: Consagrai a Baal uma assembléia solene. E a apregoaram.',
        'Também Jeú enviou por todo o Israel; e vieram todos os servos de Baal, e nenhum homem deles ficou que não viesse; e entraram na casa de Baal, e encheu-se a casa de Baal, de um lado ao outro.',
        'Então disse ao que tinha cargo das vestimentas: Tira as vestimentas para todos os servos de Baal. E ele lhes tirou para fora as vestimentas.',
        'E entrou Jeú com Jonadabe, filho de Recabe, na casa de Baal, e disse aos servos de Baal: Examinai, e vede bem, que porventura nenhum dos servos do Senhor aqui haja convosco, senão somente os servos de Baal.',
        'E, entrando eles a fazerem sacrifícios e holocaustos, Jeú preparou da parte de fora oitenta homens, e disse-lhes: Se escapar algum dos homens que eu entregar em vossas mãos, a vossa vida será pela vida dele.',
        'E sucedeu que, acabando de fazer o holocausto, disse Jeú aos da sua guarda e aos capitães: Entrai, feri-os, não escape nenhum. E os feriram ao fio da espada; e os da guarda e os capitães os lançaram fora, e entraram no mais interior da casa de Baal.',
        'E tiraram as estátuas da casa de Baal, e as queimaram.',
        'Também quebraram a estátua de Baal; e derrubaram a casa de Baal, e fizeram dela latrinas, até ao dia de hoje.',
        'E assim Jeú destruiu a Baal de Israel.',
        'Porém não se apartou Jeú de seguir os pecados de Jeroboão, filho de Nebate, com que fez pecar a Israel, a saber: dos bezerros de ouro, que estavam em Betel e em Dã.',
        'Por isso disse o Senhor a Jeú: Porquanto bem agiste em fazer o que é reto aos meus olhos e, conforme tudo quanto eu tinha no meu coração, fizeste à casa de Acabe, teus filhos, até à quarta geração, se assentarão no trono de Israel.',
        'Mas Jeú não teve cuidado de andar com todo o seu coração na lei do Senhor Deus de Israel, nem se apartou dos pecados de Jeroboão, com que fez pecar a Israel.',
        'Naqueles dias começou o Senhor a diminuir os termos de Israel; porque Hazael os feriu em todas as fronteiras de Israel.',
        'Desde o Jordão até ao nascente do sol, a toda a terra de Gileade; os gaditas, os rubenitas e os manassitas, desde Aroer, que está junto ao ribeiro de Arnom, a saber, Gileade e Basã.',
        'Ora o mais dos atos de Jeú, tudo quanto fez e todo o seu poder, porventura não está escrito no livro das crônicas de Israel?',
        'E Jeú dormiu com seus pais, e o sepultaram em Samaria; e Jeoacaz, seu filho, reinou em seu lugar.',
        'E os dias que Jeú reinou sobre Israel, em Samaria, foram vinte e oito anos.',
      ],
      [
        'Vendo, pois, Atalia, mãe de Acazias, que seu filho era morto, levantou-se, e destruiu toda a descendência real.',
        'Mas Jeoseba, filha do rei Jorão, irmã de Acazias, tomou a Joás, filho de Acazias, furtando-o dentre os filhos do rei, aos quais matavam, e o pôs, a ele e à sua ama na recâmara, e o escondeu de Atalia, e assim não o mataram.',
        'E esteve com ela escondido na casa do Senhor seis anos; e Atalia reinava sobre o país.',
        'E no sétimo ano enviou Joiada, e tomou os centuriões, com os capitães, e com os da guarda, e os colocou consigo na casa do Senhor; e fez com eles uma aliança e ajuramentou-os na casa do Senhor; e mostrou-lhes o filho do rei.',
        'E deu-lhes ordem, dizendo: Isto é o que haveis de fazer: Uma terça parte de vós, que entrais no sábado, fará a guarda da casa do rei.',
        'E outra terça parte estará à porta de Sur; e a outra terça parte à porta detrás dos da guarda; assim fareis a guarda desta casa, afastando a todos.',
        'E as duas partes de vós, a saber, todos os que saem no sábado, farão a guarda da casa do Senhor junto ao rei.',
        'E rodeareis o rei, cada um com as suas armas na mão, e aquele que entrar entre as fileiras o matarão; e vós estareis com o rei quando sair e quando entrar.',
        'Fizeram, pois, os centuriões conforme tudo quanto ordenara o sacerdote Joiada, tomando cada um os seus homens, tanto os que entravam no sábado como os que saíam no sábado; e foram ao sacerdote Joiada.',
        'E o sacerdote deu aos centuriões as lanças e os escudos que haviam sido do rei Davi, que estavam na casa do Senhor.',
        'E os da guarda se puseram, cada um com as armas na mão, desde o lado direito da casa até ao lado esquerdo da casa, do lado do altar, e do lado da casa, em redor do rei.',
        'Então Joiada fez sair o filho do rei, e lhe pôs a coroa, e lhe deu o testemunho; e o fizeram rei, e o ungiram, e bateram as palmas, e disseram: Viva o rei!',
        'E Atalia, ouvindo a voz dos da guarda e do povo, foi ter com o povo, na casa do Senhor.',
        'E olhou, e eis que o rei estava junto à coluna, conforme o costume, e os príncipes e os trombeteiros junto ao rei, e todo o povo da terra estava alegre e tocava as trombetas; então Atalia rasgou as suas vestes, e clamou: Traição! Traição!',
        'Porém o sacerdote Joiada deu ordem aos centuriões que comandavam as tropas, dizendo-lhes: Tirai-a para fora das fileiras, e a quem a seguir matai-o à espada. Porque o sacerdote disse: Não a matem na casa do Senhor.',
        'E lançaram mão dela; e ela foi, pelo caminho da entrada dos cavalos, à casa do rei, e ali a mataram.',
        'E Joiada fez uma aliança entre o Senhor e o rei e o povo, para que fosse o povo do Senhor; como também entre o rei e o povo.',
        'Então todo o povo da terra entrou na casa de Baal, e a derrubaram, como também os seus altares, e as suas imagens, totalmente quebraram, e a Matã, sacerdote de Baal, mataram diante dos altares; então o sacerdote pôs oficiais sobre a casa do Senhor.',
        'E tomou os centuriões, e os capitães, e os da guarda, e todo o povo da terra; e conduziram da casa do Senhor, o rei, e foram, pelo caminho da porta dos da guarda, à casa do rei, e ele se assentou no trono dos reis.',
        'E todo o povo da terra se alegrou, e a cidade repousou, depois que mataram a Atalia, à espada, junto à casa do rei,',
        'Era Joás da idade de sete anos quando o fizeram rei.',
      ],
      [
        'No ano sétimo de Jeú começou a reinar Joás, e quarenta anos reinou em Jerusalém; e era o nome de sua mãe Zíbia, de Berseba.',
        'E fez Joás o que era reto aos olhos do Senhor todos os dias em que o sacerdote Joiada o dirigia.',
        'Tão-somente os altos não foram tirados; porque ainda o povo sacrificava e queimava incenso nos altos.',
        'E disse Joás aos sacerdotes: Todo o dinheiro das coisas santas que se trouxer à casa do Senhor, a saber, o dinheiro daquele que passa o arrolamento, o dinheiro de cada uma das pessoas, segundo a sua avaliação, e todo o dinheiro que trouxer cada um voluntariamente para a casa do Senhor,',
        'Os sacerdotes o recebam, cada um dos seus conhecidos; e eles mesmos reparem as fendas da casa, toda a fenda que se achar nela.',
        'Sucedeu, porém, que, no ano vinte e três do rei Joás, os sacerdotes ainda não tinham reparado as fendas da casa.',
        'Então o rei Joás chamou o sacerdote Joiada e os mais sacerdotes, e lhes disse: Por que não reparais as fendas da casa? Agora, pois, não tomeis mais dinheiro de vossos conhecidos, mas entregai-o para o reparo das fendas da casa.',
        'E consentiram os sacerdotes em não tomarem mais dinheiro do povo, e em não repararem as fendas da casa.',
        'Porém o sacerdote Joiada tomou um cofre e fez um buraco na tampa; e a pôs ao pé do altar, à mão direita dos que entravam na casa do Senhor; e os sacerdotes que guardavam a entrada da porta punham ali todo o dinheiro que se trazia à casa do Senhor.',
        'Sucedeu que, vendo eles que já havia muito dinheiro no cofre, o escrivão do rei subia com o sumo sacerdote, e contavam e ensacavam o dinheiro que se achava na casa do Senhor.',
        'E o dinheiro, depois de pesado, davam nas mãos dos que faziam a obra, que tinham a seu cargo a casa do Senhor e eles o distribuíam aos carpinteiros e aos edificadores que reparavam a casa do Senhor.',
        'Como também aos pedreiros e aos cabouqueiros; e para se comprar madeira e pedras de cantaria para repararem as fendas da casa do Senhor, e para tudo quanto era necessário para reparar a casa.',
        'Todavia, do dinheiro que se trazia à casa do Senhor não se faziam nem taças de prata, nem garfos, nem bacias, nem trombetas, nem vaso algum de ouro ou vaso de prata para a casa do Senhor.',
        'Porque o davam aos que faziam a obra, e reparavam com ele a casa do Senhor.',
        'Também não pediam contas aos homens em cujas mãos entregavam aquele dinheiro, para o dar aos que faziam a obra, porque procediam com fidelidade.',
        'Mas o dinheiro do sacrifício por delitos, e o dinheiro por sacrifício de pecados, não se trazia à casa do Senhor; porque era para os sacerdotes.',
        'Então subiu Hazael, rei da Síria, e pelejou contra Gate, e a tomou; depois Hazael resolveu marchar contra Jerusalém.',
        'Porém Joás, rei de Judá, tomou todas as coisas santas que Jeosafá, Jorão e Acazias, seus pais, reis de Judá, consagraram, como também todo o ouro que se achou nos tesouros da casa do Senhor e na casa do rei e o mandou a Hazael, rei da Síria; e então se desviou de Jerusalém.',
        'Ora, o mais dos atos de Joás, e tudo quanto fez, porventura não está escrito no livro das crônicas dos reis de Judá?',
        'E levantaram-se os servos de Joás, e conspiraram contra ele ferindo-o na casa de Milo, no caminho que desce para Sila.',
        'Porque Jozacar, filho de Simeate, e Jozabade, filho de Somer, seus servos, o feriram, e morreu, e o sepultaram com seus pais na cidade de Davi. E Amazias, seu filho, reinou em seu lugar.',
      ],
      [
        'No ano vinte e três de Joás, filho de Acazias, rei de Judá, começou a reinar Jeoacaz, filho de Jeú, sobre Israel, em Samaria, e reinou dezessete anos.',
        'E fez o que era mau aos olhos do Senhor; porque seguiu os pecados de Jeroboão, filho de Nebate, que fez pecar a Israel; não se apartou deles.',
        'Por isso a ira do Senhor se acendeu contra Israel; e entregou-os na mão de Hazael, rei da Síria, e na mão de Ben-Hadade, filho de Hazael, todos aqueles dias.',
        'Porém Jeoacaz suplicou diante da face do Senhor; e o Senhor ouviu; porque viu a opressão de Israel, pois o rei da Síria os oprimia.',
        'E o Senhor deu um salvador a Israel, e saíram de sob as mãos dos sírios; e os filhos de Israel habitaram nas suas tendas, como no passado',
        '(Contudo não se apartaram dos pecados da casa de Jeroboão, com que fez Israel pecar; porém ele andou neles e também o bosque ficou em pé em Samaria).',
        'Porque não deixou a Jeoacaz, do povo, senão só cinqüenta cavaleiros, dez carros e dez mil homens de pé, porquanto o rei da Síria os tinha destruído e os tinha feito como o pó, trilhando-os.',
        'Ora, o mais dos atos de Jeoacaz, e tudo quanto fez, e o seu poder, porventura não está escrito no livro das crônicas dos reis de Israel?',
        'E Jeoacaz dormiu com seus pais, e o sepultaram em Samaria; e Jeoás, seu filho, reinou em seu lugar.',
        'No ano trinta e sete de Joás, rei de Judá, começou a reinar Jeoás, filho de Jeoacaz, sobre Israel, em Samaria, e reinou dezesseis anos.',
        'E fez o que era mau aos olhos do Senhor; não se apartou de nenhum dos pecados de Jeroboão, filho de Nebate, com que fez Israel pecar, porém andou neles.',
        'Ora, o mais dos atos de Jeoás, e tudo quanto fez, e o seu poder, com que pelejou contra Amazias, rei de Judá, porventura não está escrito no livro das crônicas dos reis de Israel?',
        'E Jeoás dormiu com seus pais, e Jeroboão se assentou no seu trono; e Jeoás foi sepultado em Samaria, junto aos reis de Israel.',
        'E Eliseu estava doente da enfermidade de que morreu, e Jeoás, rei de Israel, desceu a ele, e chorou sobre o seu rosto, e disse: Meu pai, meu pai, o carro de Israel, e seus cavaleiros!',
        'E Eliseu lhe disse: Toma um arco e flechas. E tomou um arco e flechas.',
        'Então disse ao rei de Israel: Põe a tua mão sobre o arco. E pôs sobre ele a sua mão; e Eliseu pôs as suas mãos sobre as do rei.',
        'E disse: Abre a janela para o oriente. E abriu-a. Então disse Eliseu: Atira. E atirou; e disse: A flecha do livramento do Senhor é a flecha do livramento contra os sírios; porque ferirás os sírios; em Afeque, até os consumir.',
        'Disse mais: Toma as flechas. E tomou-as. Então disse ao rei de Israel: Fere a terra. E feriu-a três vezes, e cessou.',
        'Então o homem de Deus se indignou muito contra ele, e disse: Cinco ou seis vezes a deverias ter ferido; então feririas os sírios até os consumir; porém agora só três vezes ferirás os sírios.',
        'Depois morreu Eliseu, e o sepultaram. Ora, as tropas dos moabitas invadiram a terra à entrada do ano.',
        'E sucedeu que, enterrando eles um homem, eis que viram uma tropa, e lançaram o homem na sepultura de Eliseu; e, caindo nela o homem, e tocando os ossos de Eliseu, reviveu, e se levantou sobre os seus pés.',
        'E Hazael, rei da Síria, oprimiu a Israel todos os dias de Jeoacaz.',
        'Porém o Senhor teve misericórdia deles, e se compadeceu deles, e tornou-se para eles por amor da sua aliança com Abraão, Isaque e Jacó, e não os quis destruir, e não os lançou ainda da sua presença.',
        'E morreu Hazael, rei da Síria e Ben-Hadade, seu filho, reinou em seu lugar.',
        'E Jeoás, filho de Jeoacaz, tornou a tomar as cidades das mãos de Ben-Hadade, que ele tinha tomado das mãos de Jeoacaz, seu pai, na guerra; três vezes Jeoás o feriu, e recuperou as cidades de Israel.',
      ],
      [
        'No segundo ano de Jeoás, filho de Jeoacaz, rei de Israel, começou a reinar Amazias, filho de Joás, rei de Judá.',
        'Tinha vinte e cinco anos quando começou a reinar, e vinte e nove anos reinou em Jerusalém. E era o nome de sua mãe Joadã, de Jerusalém.',
        'E fez o que era reto aos olhos do Senhor, ainda que não como seu pai Davi; fez, porém, conforme tudo o que fizera Joás seu pai.',
        'Tão-somente os altos não foram tirados; porque o povo ainda sacrificava e queimava incenso nos altos.',
        'Sucedeu que, sendo já o reino confirmado na sua mão, matou os servos que tinham matado o rei, seu pai.',
        'Porém os filhos dos assassinos não matou, como está escrito no livro da lei de Moisés, no qual o Senhor deu ordem, dizendo: Não matarão os pais por causa dos filhos, e os filhos não matarão por causa dos pais; mas cada um será morto pelo seu pecado.',
        'Este feriu a dez mil edomitas no vale do Sal, e tomou a Sela na guerra; e chamou-a Jocteel, até ao dia de hoje.',
        'Então Amazias enviou mensageiros a Jeoás, filho de Jeoacaz, filho de Jeú, rei de Israel, dizendo: Vem, vejamo-nos face a face.',
        'Porém Jeoás, rei de Israel, enviou a Amazias, rei de Judá, dizendo: O cardo que estava no Líbano mandou dizer ao cedro que estava no Líbano: Dá tua filha por mulher a meu filho; mas os animais do campo, que estavam no Líbano, passaram e pisaram o cardo.',
        'Na verdade feriste os moabitas, e o teu coração se ensoberbeceu; gloria-te disso, e fica em tua casa; e por que te entremeterias no mal, para caíres tu, e Judá contigo?',
        'Mas Amazias não o ouviu. E subiu Jeoás, rei de Israel, e Amazias, rei de Judá, e viram-se face a face, em Bete-Semes, que está em Judá.',
        'E Judá foi ferido diante de Israel, e fugiu cada um para a sua tenda.',
        'E Jeoás, rei de Israel, tomou a Amazias, rei de Judá, filho de Joás, filho de Acazias, em Bete-Semes; e veio a Jerusalém, e rompeu o muro de Jerusalém, desde a porta de Efraim até a porta da esquina, quatrocentos côvados.',
        'E tomou todo o ouro e a prata, e todos os vasos que se acharam na casa do Senhor e nos tesouros da casa do rei, como também os reféns e voltou para Samaria.',
        'Ora, o mais dos atos de Jeoás, o que fez e o seu poder, e como pelejou contra Amazias, rei de Judá, porventura não está escrito no livro das crônicas dos reis de Israel?',
        'E dormiu Jeoás com seus pais, e foi sepultado em Samaria, junto aos reis de Israel; e Jeroboão, seu filho, reinou em seu lugar.',
        'E viveu Amazias, filho de Joás, rei de Judá, depois da morte de Jeoás, filho de Jeoacaz, rei de Israel, quinze anos.',
        'Ora, o mais dos atos de Amazias, porventura não está escrito no livro das crônicas dos reis de Judá?',
        'E conspiraram contra ele em Jerusalém, e fugiu para Laquis; porém enviaram após ele até Laquis, e o mataram ali.',
        'E o trouxeram em cima de cavalos; e o sepultaram em Jerusalém, junto a seus pais, na cidade de Davi.',
        'E todo o povo de Judá tomou a Azarias, que já era de dezesseis anos, e o fizeram rei em lugar de Amazias, seu pai.',
        'Este edificou a Elate, e a restituiu a Judá, depois que o rei dormiu com seus pais.',
        'No décimo quinto ano de Amazias, filho de Joás, rei de Judá, começou a reinar em Samaria, Jeroboão, filho de Jeoás, rei de Israel, e reinou quarenta e um anos.',
        'E fez o que era mau aos olhos do Senhor; nunca se apartou de nenhum dos pecados de Jeroboão, filho de Nebate, com que fez pecar a Israel.',
        'Também este restituiu os termos de Israel, desde a entrada de Hamate, até ao mar da planície; conforme a palavra do Senhor Deus de Israel, a qual falara pelo ministério de seu servo Jonas, filho do profeta Amitai, o qual era de Gate-Hefer.',
        'Porque viu o Senhor que a miséria de Israel era muito amarga, e que nem havia escravo, nem absolvido, nem quem ajudasse a Israel.',
        'E ainda não falara o Senhor em apagar o nome de Israel de debaixo do céu; porém os livrou por meio de Jeroboão, filho de Jeoás.',
        'Ora, o mais dos atos de Jeroboão, tudo quanto fez, e seu poder, como pelejou, e como restituiu a Damasco e a Hamate, pertencentes a Judá, sendo rei em Israel, porventura não está escrito no livro das crônicas de Israel?',
        'E Jeroboão dormiu com seus pais, com os reis de Israel; e Zacarias, seu filho, reinou em seu lugar.',
      ],
      [
        'No ano vinte e sete de Jeroboão, rei de Israel, começou a reinar Azarias, filho de Amazias, rei de Judá.',
        'Tinha dezesseis anos quando começou a reinar, e cinqüenta e dois anos reinou em Jerusalém; e era o nome de sua mãe Jecolias, de Jerusalém.',
        'E fez o que era reto aos olhos do Senhor, conforme tudo o que fizera Amazias, seu pai.',
        'Tão-somente os altos não foram tirados; porque o povo ainda sacrificava e queimava incenso nos altos.',
        'E o Senhor feriu o rei, e ficou leproso até ao dia da sua morte; e habitou numa casa separada; porém Jotão, filho do rei, tinha o cargo da casa, julgando o povo da terra.',
        'Ora, o mais dos atos de Azarias, e tudo o que fez, porventura não está escrito no livro das crônicas dos reis de Judá?',
        'E Azarias dormiu com seus pais e o sepultaram junto a seus pais, na cidade de Davi; e Jotão, seu filho, reinou em seu lugar.',
        'No ano trinta e oito de Azarias, rei de Judá, reinou Zacarias, filho de Jeroboão, sobre Israel, em Samaria, seis meses.',
        'E fez o que era mau aos olhos do Senhor, como tinham feito seus pais; nunca se apartou dos pecados de Jeroboão, filho de Nebate, com que fez pecar a Israel.',
        'E Salum, filho de Jabes, conspirou contra ele e feriu-o diante do povo, e matou-o; e reinou em seu lugar.',
        'Ora, o mais dos atos de Zacarias, eis que está escrito no livro das crônicas dos reis de Israel.',
        'Esta foi a palavra do Senhor, que falou a Jeú: Teus filhos, até à quarta geração, se assentarão sobre o trono de Israel. E assim foi.',
        'Salum, filho de Jabes, começou a reinar no ano trinta e nove de Uzias, rei de Judá, e reinou um mês inteiro em Samaria.',
        'Porque Menaém, filho de Gadi, subiu de Tirza, e veio a Samaria; e feriu a Salum, filho de Jabes, em Samaria, e o matou, e reinou em seu lugar.',
        'Ora, o mais dos atos de Salum, e a conspiração que fez, eis que está escrito no livro das crônicas dos reis de Israel.',
        'Então Menaém feriu a Tifsa, e a todos os que nela havia, como também a seus termos desde Tirza, porque não lha tinham aberto; e os feriu, pois, e a todas as mulheres grávidas fendeu pelo meio.',
        'Desde o ano trinta e nove de Azarias, rei de Judá, Menaém, filho de Gadi, começou a reinar sobre Israel, e reinou dez anos em Samaria.',
        'E fez o que era mau aos olhos do Senhor; todos os seus dias não se apartou dos pecados de Jeroboão, filho de Nebate, com que fez pecar a Israel.',
        'Então veio Pul, rei da Assíria, contra a terra; e Menaém deu a Pul mil talentos de prata, para que este o ajudasse a firmar o reino na sua mão.',
        'E Menaém tirou este dinheiro de Israel, de todos os poderosos e ricos, para dá-lo ao rei da Assíria, de cada homem cinqüenta siclos de prata; assim voltou o rei da Assíria, e não ficou ali na terra.',
        'Ora, o mais dos atos de Menaém, e tudo quanto fez, porventura não está escrito no livro das crônicas dos reis de Israel?',
        'E Menaém dormiu com seus pais; e Pecaías, seu filho, reinou em seu lugar.',
        'No ano cinqüenta de Azarias, rei de Judá, começou a reinar Pecaías, filho de Menaém, sobre Israel, em Samaria, e reinou dois anos.',
        'E fez o que era mau aos olhos do Senhor; nunca se apartou dos pecados de Jeroboão, filho de Nebate, com que fez pecar a Israel.',
        'E Peca, filho de Remalias, seu capitão, conspirou contra ele, e o feriu em Samaria, no paço da casa do rei, juntamente com Argobe e com Arié, e com ele cinqüenta homens dos filhos dos gileaditas; e o matou, e reinou em seu lugar.',
        'Ora, o mais dos atos de Pecaías, e tudo quanto fez, eis que está escrito no livro das crônicas dos reis de Israel.',
        'No ano cinqüenta e dois de Azarias, rei de Judá, começou a reinar Peca, filho de Remalias, sobre Israel, em Samaria, e reinou vinte anos.',
        'E fez o que era mau aos olhos do Senhor; nunca se apartou dos pecados de Jeroboão, filho de Nebate, com que fez pecar a Israel.',
        'Nos dias de Peca, rei de Israel, veio Tiglate-Pileser, rei da Assíria, e tomou a Ijom, a Abel-Bete-Maaca, a Janoa, e a Quedes, a Hazor, a Gileade, e a Galiléia, e a toda a terra de Naftali, e os levou à Assíria.',
        'E Oséias, filho de Elá, conspirou contra Peca, filho de Remalias, e o feriu, e o matou, e reinou em seu lugar, no vigésimo ano de Jotão, filho de Uzias.',
        'Ora, o mais dos atos de Peca, e tudo quanto fez, eis que está escrito no livro das crônicas dos reis de Israel.',
        'No ano segundo de Peca, filho de Remalias, rei de Israel, começou a reinar Jotão, filho de Uzias, rei de Judá.',
        'Tinha vinte e cinco anos de idade quando começou a reinar, e reinou dezesseis anos em Jerusalém; e era o nome de sua mãe Jerusa, filha de Zadoque.',
        'E fez o que era reto aos olhos do Senhor; fez conforme tudo quanto fizera seu pai Uzias.',
        'Tão-somente os altos não foram tirados; porque o povo ainda sacrificava e queimava incenso nos altos. Este edificou a porta alta da casa do Senhor.',
        'Ora, o mais dos atos de Jotão, e tudo quanto fez, porventura não está escrito no livro das crônicas dos reis de Judá?',
        'Naqueles dias começou o Senhor a enviar contra Judá a Rezim, rei da Síria, e a Peca, filho de Remalias.',
        'E Jotão dormiu com seus pais, e foi sepultado junto a seus pais, na cidade de Davi, seu pai; e Acaz, seu filho, reinou em seu lugar.',
      ],
      [
        'No ano dezessete de Peca, filho de Remalias, começou a reinar Acaz, filho de Jotão, rei de Judá.',
        'Tinha Acaz vinte anos de idade quando começou a reinar, e reinou dezesseis anos em Jerusalém, e não fez o que era reto aos olhos do Senhor seu Deus, como Davi, seu pai.',
        'Porque andou no caminho dos reis de Israel, e até a seu filho fez passar pelo fogo, segundo as abominações dos gentios que o Senhor lançara fora de diante dos filhos de Israel.',
        'Também sacrificou, e queimou incenso nos altos e nos outeiros, como também debaixo de todo o arvoredo.',
        'Então subiu Rezim, rei da Síria, com Peca, filho de Remalias, rei de Israel, a Jerusalém, para pelejar; e cercaram a Acaz, porém não o puderam vencer.',
        'Naquele mesmo tempo Rezim, rei da Síria, restituiu Elate à Síria, e lançou fora de Elate os judeus; e os sírios vieram a Elate, e habitaram ali até ao dia de hoje.',
        'E Acaz enviou mensageiros a Tiglate-Pileser, rei da Assíria, dizendo: Eu sou teu servo e teu filho; sobe, e livra-me das mãos do rei da Síria, e das mãos do rei de Israel, que se levantam contra mim.',
        'E tomou Acaz a prata e o ouro que se achou na casa do Senhor, e nos tesouros da casa do rei, e mandou um presente ao rei da Assíria.',
        'E o rei da Assíria lhe deu ouvidos; pois o rei da Assíria subiu contra Damasco, e tomou-a e levou cativo o povo para Quir, e matou a Rezim.',
        'Então o rei Acaz foi a Damasco, a encontrar-se com Tiglate-Pileser, rei da Assíria; e, vendo um altar que estava em Damasco, o rei Acaz enviou ao sacerdote Urias o desenho e o modelo do altar, conforme toda a sua feitura.',
        'E Urias, o sacerdote, edificou um altar conforme tudo o que o rei Acaz lhe tinha enviado de Damasco; assim o fez o sacerdote Urias, antes que o rei Acaz viesse de Damasco.',
        'Vindo, pois, o rei de Damasco, viu o altar; e o rei se chegou ao altar, e sacrificou nele.',
        'E queimou o seu holocausto, e a sua oferta de alimentos, e derramou a sua libação, e espargiu o sangue dos seus sacrifícios pacíficos sobre o altar.',
        'Porém o altar de cobre, que estava perante o Senhor, ele tirou de diante da casa, de entre o seu altar e a casa do Senhor, e pô-lo ao lado do altar, do lado do norte.',
        'E o rei Acaz ordenou a Urias, o sacerdote, dizendo: Queima no grande altar o holocausto da manhã, como também a oferta de alimentos da noite, o holocausto do rei e a sua oferta de alimentos, e o holocausto de todo o povo da terra, a sua oferta de alimentos, as suas ofertas de bebidas e todo o sangue dos holocaustos, e todo o sangue dos sacrifícios espargirás nele; porém o altar de cobre será para mim, para nele inquirir.',
        'E fez Urias, o sacerdote, conforme tudo quanto o rei Acaz lhe ordenara.',
        'E o rei Acaz cortou as cintas das bases, e de cima delas tomou a pia, e tirou o mar de sobre os bois de cobre, que estavam debaixo dele, e pô-lo sobre um pavimento de pedra.',
        'Também a coberta que, para o sábado, edificaram na casa, e a entrada real externa, retirou da casa do Senhor, por causa do rei da Assíria.',
        'Ora, o mais dos atos de Acaz e o que fez, porventura não está escrito no livro das crônicas dos reis de Judá?',
        'E dormiu Acaz com seus pais, e foi sepultado junto a seus pais, na cidade de Davi; e Ezequias, seu filho, reinou em seu lugar.',
      ],
      [
        'No ano duodécimo de Acaz, rei de Judá, começou a reinar Oséias, filho de Elá, e reinou sobre Israel, em Samaria, nove anos.',
        'E fez o que era mau aos olhos do Senhor, contudo não como os reis de Israel que foram antes dele.',
        'Contra ele subiu Salmaneser, rei da Assíria; e Oséias ficou sendo servo dele, e pagava-lhe tributos.',
        'Porém o rei da Assíria achou em Oséias conspiração; porque enviara mensageiros a Sô, rei do Egito, e não pagava tributos ao rei da Assíria cada ano, como dantes; então o rei da Assíria o encerrou e aprisionou na casa do cárcere.',
        'Porque o rei da Assíria subiu por toda a terra, e veio até Samaria, e a cercou três anos.',
        'No ano nono de Oséias, o rei da Assíria tomou a Samaria, e levou Israel cativo para a Assíria; e fê-los habitar em Hala e em Habor junto ao rio de Gozã, e nas cidades dos medos,',
        'Porque sucedeu que os filhos de Israel pecaram contra o Senhor seu Deus, que os fizera subir da terra do Egito, de debaixo da mão de Faraó, rei do Egito; e temeram a outros deuses.',
        'E andaram nos estatutos das nações que o Senhor lançara fora de diante dos filhos de Israel, e nos dos reis de Israel, que eles fizeram.',
        'E os filhos de Israel fizeram secretamente coisas que não eram retas, contra o Senhor seu Deus; e edificaram altos em todas as suas cidades, desde a torre dos atalaias até à cidade fortificada.',
        'E levantaram, para si, estátuas e imagens do bosque, em todos os altos outeiros, e debaixo de todas as árvores verdes.',
        'E queimaram ali incenso em todos os altos, como as nações, que o Senhor expulsara de diante deles; e fizeram coisas ruins, para provocarem à ira o Senhor.',
        'E serviram os ídolos, dos quais o Senhor lhes dissera: Não fareis estas coisas.',
        'E o Senhor advertiu a Israel e a Judá, pelo ministério de todos os profetas e de todos os videntes, dizendo: Convertei-vos de vossos maus caminhos, e guardai os meus mandamentos e os meus estatutos, conforme toda a lei que ordenei a vossos pais e que eu vos enviei pelo ministério de meus servos, os profetas.',
        'Porém não deram ouvidos; antes endureceram a sua cerviz, como a cerviz de seus pais, que não creram no Senhor seu Deus.',
        'E rejeitaram os seus estatutos, e a sua aliança que fizera com seus pais, como também as suas advertências, com que protestara contra eles; e seguiram a vaidade, e tornaram-se vãos; como também seguiram as nações, que estavam ao redor deles, das quais o Senhor lhes tinha ordenado que não as imitassem.',
        'E deixaram todos os mandamentos do Senhor seu Deus, e fizeram imagens de fundição, dois bezerros; e fizeram um ídolo do bosque, e adoraram perante todo o exército do céu, e serviram a Baal.',
        'Também fizeram passar pelo fogo a seus filhos e suas filhas, e deram-se a adivinhações, e criam em agouros; e venderam-se para fazer o que era mau aos olhos do Senhor, para o provocarem à ira.',
        'Portanto o Senhor muito se indignou contra Israel, e os tirou de diante da sua face; nada mais ficou, senão somente a tribo de Judá.',
        'Até Judá não guardou os mandamentos do Senhor seu Deus; antes andaram nos estatutos de Israel, que eles fizeram.',
        'Por isso o Senhor rejeitou a toda a descendência de Israel, e os oprimiu, e os deu nas mãos dos despojadores, até que os expulsou da sua presença.',
        'Porque rasgou a Israel da casa de Davi; e eles fizeram rei a Jeroboão, filho de Nebate. E Jeroboão apartou a Israel de seguir ao Senhor, e os fez cometer um grande pecado.',
        'Assim andaram os filhos de Israel em todos os pecados que Jeroboão tinha feito; nunca se apartaram deles;',
        'Até que o Senhor tirou a Israel de diante da sua presença, como falara pelo ministério de todos os seus servos, os profetas; assim foi Israel expulso da sua terra à Assíria até ao dia de hoje.',
        'E o rei da Assíria trouxe gente de Babilônia, de Cuta, de Ava, de Hamate e Sefarvaim, e a fez habitar nas cidades de Samaria, em lugar dos filhos de Israel; e eles tomaram a Samaria em herança, e habitaram nas suas cidades.',
        'E sucedeu que, no princípio da sua habitação ali, não temeram ao Senhor; e o Senhor mandou entre eles, leões, que mataram a alguns deles.',
        'Por isso falaram ao rei da Assíria, dizendo: A gente que transportaste e fizeste habitar nas cidades de Samaria, não sabe o costume do Deus da terra; assim mandou leões entre ela, e eis que a matam, porquanto não sabe o culto do Deus da terra.',
        'Então o rei da Assíria mandou dizer: Levai ali um dos sacerdotes que transportastes de lá; e vá e habite lá, e ele lhes ensine o costume do Deus da terra.',
        'Veio, pois, um dos sacerdotes que transportaram de Samaria, e habitou em Betel, e lhes ensinou como deviam temer ao Senhor.',
        'Porém cada nação fez os seus deuses, e os puseram nas casas dos altos que os samaritanos fizeram, cada nação nas cidades, em que habitava.',
        'E os de babilônia fizeram Sucote-Benote; e os de Cuta fizeram Nergal; e os de Hamate fizeram Asima.',
        'E os aveus fizeram Nibaz e Tartaque; e os sefarvitas queimavam seus filhos no fogo a Adrameleque, e a Anameleque, deuses de Sefarvaim.',
        'Também temiam ao Senhor; e dos mais baixos do povo fizeram sacerdotes dos lugares altos, os quais lhes faziam o ministério nas casas dos lugares altos.',
        'Assim temiam ao Senhor, mas também serviam a seus deuses, segundo o costume das nações dentre as quais tinham sido transportados.',
        'Até ao dia de hoje fazem segundo os primeiros costumes; não temem ao Senhor, nem fazem segundo os seus estatutos, segundo as suas ordenanças, segundo a lei e segundo o mandamento que o Senhor ordenou aos filhos de Jacó, a quem deu o nome de Israel.',
        'Contudo o Senhor tinha feito uma aliança com eles, e lhes ordenara, dizendo: Não temereis a outros deuses, nem vos inclinareis diante deles, nem os servireis, nem lhes sacrificareis.',
        'Mas o Senhor, que vos fez subir da terra do Egito com grande força e com braço estendido, a este temereis, e a ele vos inclinareis e a ele sacrificareis.',
        'E os estatutos, as ordenanças, a lei e o mandamento, que vos escreveu, tereis cuidado de fazer todos os dias; e não temereis a outros deuses.',
        'E da aliança que fiz convosco não vos esquecereis; e não temereis a outros deuses.',
        'Mas ao Senhor vosso Deus temereis, e ele vos livrará das mãos de todos os vossos inimigos.',
        'Porém eles não ouviram; antes fizeram segundo o seu primeiro costume.',
        'Assim estas nações temiam ao Senhor e serviam as suas imagens de escultura; também seus filhos, e os filhos de seus filhos, como fizeram seus pais, assim fazem eles até ao dia de hoje.',
      ],
      [
        'E sucedeu que, no terceiro ano de Oséias, filho de Elá, rei de Israel, começou a reinar Ezequias, filho de Acaz, rei de Judá.',
        'Tinha vinte e cinco anos de idade quando começou a reinar, e vinte e nove anos reinou em Jerusalém; e era o nome de sua mãe Abi, filha de Zacarias.',
        'E fez o que era reto aos olhos do Senhor, conforme tudo o que fizera Davi, seu pai.',
        'Ele tirou os altos, quebrou as estátuas, deitou abaixo os bosques, e fez em pedaços a serpente de metal que Moisés fizera; porquanto até àquele dia os filhos de Israel lhe queimavam incenso, e lhe chamaram Neustã.',
        'No Senhor Deus de Israel confiou, de maneira que depois dele não houve quem lhe fosse semelhante entre todos os reis de Judá, nem entre os que foram antes dele.',
        'Porque se chegou ao Senhor, não se apartou dele, e guardou os mandamentos que o Senhor tinha dado a Moisés.',
        'Assim foi o Senhor com ele; para onde quer que saía se conduzia com prudência; e se rebelou contra o rei da Assíria, e não o serviu.',
        'Ele feriu os filisteus até Gaza, como também os seus termos, desde a torre dos atalaias até à cidade fortificada.',
        'E sucedeu, no quarto ano do rei Ezequias (que era o sétimo ano de Oséias, filho de Elá, rei de Israel), que Salmaneser, rei da Assíria, subiu contra Samaria, e a cercou.',
        'E a tomaram ao fim de três anos, no ano sexto de Ezequias, que era o ano nono de Oséias, rei de Israel, quando tomaram Samaria.',
        'E o rei da Assíria transportou a Israel para a Assíria; e os fez levar a Hala e a Habor, junto ao rio de Gozã, e às cidades dos medos;',
        'Porquanto não obedeceram à voz do Senhor seu Deus, antes transgrediram a sua aliança; e tudo quanto Moisés, servo do Senhor, tinha ordenado, nem o ouviram nem o fizeram.',
        'Porém no ano décimo quarto do rei Ezequias subiu Senaqueribe, rei da Assíria, contra todas as cidades fortificadas de Judá, e as tomou.',
        'Então Ezequias, rei de Judá, enviou ao rei da Assíria, a Laquis, dizendo: Pequei; retira-te de mim; tudo o que me impuseres suportarei. Então o rei da Assíria impôs a Ezequias, rei de Judá, trezentos talentos de prata e trinta talentos de ouro.',
        'Assim deu Ezequias toda a prata que se achou na casa do Senhor e nos tesouros da casa do rei.',
        'Naquele tempo cortou Ezequias o ouro das portas do templo do Senhor, e das ombreiras, de que ele, rei de Judá, as cobrira, e o deu ao rei da Assíria.',
        'Contudo enviou o rei da Assíria a Tartã, e a Rabe-Saris, e a Rabsaqué, de Laquis, com grande exército ao rei Ezequias, a Jerusalém; subiram, e vieram a Jerusalém. E, subindo e vindo eles, pararam ao pé do aqueduto da piscina superior, que está junto ao caminho do campo do lavandeiro.',
        'E chamaram o rei; e saíram a eles Eliaquim, filho de Hilquias, o mordomo, e Sebna, o escrivão, e Joá, filho de Asafe, o cronista.',
        'E Rabsaqué lhes disse: Ora, dizei a Ezequias: Assim diz o grande rei, o rei da Assíria: Que confiança é esta em que te estribas?',
        'Dizes tu (porém são palavras só de lábios): Há conselho e poder para a guerra. Em quem, pois, agora confias, que contra mim te rebelas?',
        'Eis que agora tu confias naquele bordão de cana quebrada, no Egito, no qual, se alguém se encostar, entrar-lhe-á pela mão e a furará; assim é Faraó, rei do Egito, para com todos os que nele confiam.',
        'Se, porém, me disserdes: No Senhor nosso Deus confiamos; porventura não é esse aquele cujos altos e cujos altares Ezequias tirou, dizendo a Judá e a Jerusalém: Perante este altar vos inclinareis em Jerusalém?',
        'Ora, pois, dá agora reféns ao meu senhor, o rei da Assíria, e dar-te-ei dois mil cavalos, se tu puderes dar cavaleiros para eles.',
        'Como, pois, farias virar o rosto de um só capitão dos menores servos de meu senhor, quando tu confias no Egito, por causa dos carros e cavaleiros?',
        'Agora, pois, subi eu porventura sem o Senhor contra este lugar, para o destruir? O Senhor me disse: Sobe contra esta terra, e destrói-a.',
        'Então disse Eliaquim, filho de Hilquias, e Sebna e Joá, a Rabsaqué: Rogamos-te que fales aos teus servos em siríaco; porque bem o entendemos; e não nos fales em judaico, aos ouvidos do povo que está em cima do muro.',
        'Porém Rabsaqué lhes disse: Porventura mandou-me meu senhor somente a teu senhor e a ti, para falar estas palavras e não antes aos homens, que estão sentados em cima do muro, para que juntamente convosco comam o seu excremento e bebam a sua urina?',
        'Rabsaqué, pois, se pôs em pé, e clamou em alta voz em judaico, e respondeu, dizendo: Ouvi a palavra do grande rei, do rei da Assíria.',
        'Assim diz o rei: Não vos engane Ezequias; porque não vos poderá livrar da sua mão;',
        'Nem tampouco vos faça Ezequias confiar no Senhor, dizendo: Certamente nos livrará o Senhor, e esta cidade não será entregue na mão do rei da Assíria.',
        'Não deis ouvidos a Ezequias; porque assim diz o rei da Assíria: Contratai comigo por presentes, e saí a mim, e coma cada um da sua vide e da sua figueira, e beba cada um a água da sua cisterna;',
        'Até que eu venha, e vos leve para uma terra como a vossa, terra de trigo e de mosto, terra de pão e de vinhas, terra de oliveiras, de azeite e de mel; e assim vivereis, e não morrereis; e não deis ouvidos a Ezequias; porque vos incita, dizendo: O Senhor nos livrará.',
        'Porventura os deuses das nações puderam livrar, cada um a sua terra, das mãos do rei da Assíria?',
        'Que é feito dos deuses de Hamate e de Arpade? Que é feito dos deuses de Sefarvaim, Hena e Iva? Porventura livraram a Samaria da minha mão?',
        'Quais são eles, dentre todos os deuses das terras, que livraram a sua terra da minha mão, para que o Senhor livrasse a Jerusalém da minha mão?',
        'Porém calou-se o povo, e não lhe respondeu uma só palavra; porque mandado do rei havia, dizendo: Não lhe respondereis.',
        'Então Eliaquim, filho de Hilquias, o mordomo, e Sebna, o escrivão, e Joá, filho de Asafe, o cronista, vieram a Ezequias com as vestes rasgadas, e lhe fizeram saber as palavras de Rabsaqué.',
      ],
      [
        'E aconteceu que, tendo Ezequias ouvido isto, rasgou as suas vestes, e se cobriu de saco, e entrou na casa do SENHOR.',
        'Então enviou a Eliaquim, o mordomo, e a Sebna, o escrivão, e os anciãos dos sacerdotes, cobertos de sacos, ao profeta Isaías, filho de Amós.',
        'E disseram-lhe: Assim diz Ezequias: Este dia é dia de angústia, de vituperação e de blasfêmia; porque os filhos chegaram ao parto, e não há força para dá-los à luz.',
        'Bem pode ser que o SENHOR teu Deus ouça todas as palavras de Rabsaqué, a quem enviou o seu senhor, o rei da Assíria, para afrontar o Deus vivo, e para vituperá-lo com as palavras que o SENHOR teu Deus tem ouvido; faze, pois, oração pelo restante que subsiste.',
        'E os servos do rei Ezequias foram a Isaías.',
        'E Isaías lhes disse: Assim direis a vosso senhor: Assim diz o SENHOR: Não temas as palavras que ouviste, com as quais os servos do rei da Assíria me blasfemaram.',
        'Eis que porei nele um espírito, e ele ouvirá um rumor, e voltará para a sua terra; à espada o farei cair na sua terra.',
        'Voltou, pois, Rabsaqué, e achou o rei da Assíria pelejando contra Libna, porque tinha ouvido que o rei havia partido de Laquis.',
        'E, ouvindo ele dizer de Tiraca, rei da Etiópia: Eis que saiu para te fazer guerra; tornou a enviar mensageiros a Ezequias, dizendo:',
        'Assim falareis a Ezequias, rei de Judá: Não te engane o teu Deus, em quem confias, dizendo: Jerusalém não será entregue na mão do rei da Assíria.',
        'Eis que já tens ouvido o que fizeram os reis da Assíria a todas as terras, destruindo-as totalmente; e tu, te livrarás?',
        'Porventura as livraram os deuses das nações, a quem meus pais destruíram, como a Gozã, a Harã, a Rezefe, e aos filhos de Éden, que estavam em Telassar?',
        'Que é feito do rei de Hamate, do rei de Arpade, e do rei da cidade de Sefarvaim, Hena e Iva?',
        'Recebendo, pois, Ezequias as cartas das mãos dos mensageiros e lendo-as, subiu à casa do Senhor; e Ezequias as estendeu perante o Senhor.',
        'E orou Ezequias perante o Senhor e disse: Ó Senhor Deus de Israel, que habitas entre os querubins, tu mesmo, só tu és Deus de todos os reinos da terra; tu fizeste os céus e a terra.',
        'Inclina, Senhor, o teu ouvido, e ouve; abre, Senhor, os teus olhos, e olha; e ouve as palavras de Senaqueribe, que enviou a este, para afrontar o Deus vivo.',
        'Verdade é, ó Senhor, que os reis da Assíria assolaram as nações e as suas terras.',
        'E lançaram os seus deuses no fogo; porquanto não eram deuses, mas obra de mãos de homens, madeira e pedra; por isso os destruíram.',
        'Agora, pois, ó Senhor nosso Deus, te suplico, livra-nos da sua mão; e assim saberão todos os reinos da terra que só tu és o Senhor Deus.',
        'Então Isaías, filho de Amós, mandou dizer a Ezequias: Assim diz o Senhor Deus de Israel: O que me pediste acerca de Senaqueribe, rei da Assíria, ouvi.',
        'Esta é a palavra que o Senhor falou dele: A virgem, a filha de Sião, te despreza, de ti zomba; a filha de Jerusalém meneia a cabeça por detrás de ti.',
        'A quem afrontaste e blasfemaste? E contra quem alçaste a voz e ergueste os teus olhos ao alto? Contra o Santo de Israel?',
        'Por meio de teus mensageiros afrontaste o Senhor, e disseste: Com a multidão de meus carros subi ao alto dos montes, aos lados do Líbano, e cortarei os seus altos cedros e as suas mais formosas faias, e entrarei nas suas pousadas extremas, até no bosque do seu campo fértil.',
        'Eu cavei, e bebi águas estranhas; e com as plantas de meus pés sequei todos os rios do Egito.',
        'Porventura não ouviste que já dantes fiz isto, e já desde os dias antigos o planejei? Agora, porém, o fiz vir, para que fosses tu que reduzisses as cidades fortificadas a montões desertos.',
        'Por isso os moradores delas, com pouca força, ficaram pasmados e confundidos; eram como a erva do campo, e a hortaliça verde, e o feno dos telhados, e o trigo queimado, antes de amadurecer.',
        'Porém o teu assentar, e o teu sair e o teu entrar, e o teu furor contra mim, eu o sei.',
        'Por causa do teu furor contra mim, e porque a tua revolta subiu aos meus ouvidos, portanto porei o meu anzol no teu nariz e o meu freio nos teus lábios, e te farei voltar pelo caminho por onde vieste.',
        'E isto te será por sinal; este ano se comerá o que nascer por si mesmo, e no ano seguinte o que daí proceder; porém, no terceiro ano semeai e segai, plantai vinhas, e comei os seus frutos.',
        'Porque o que escapou da casa de Judá, e restou, tornará a lançar raízes para baixo, e dará fruto para cima.',
        'Porque de Jerusalém sairá o restante, e do monte Sião o que escapou; o zelo do Senhor dos Exércitos fará isto.',
        'Portanto, assim diz o Senhor acerca do rei da Assíria: Não entrará nesta cidade, nem lançará nela flecha alguma; tampouco virá perante ela com escudo, nem levantará contra ela trincheira alguma.',
        'Pelo caminho por onde vier, por ele voltará; porém nesta cidade não entrará, diz o Senhor.',
        'Porque eu ampararei a esta cidade, para a livrar, por amor de mim e por amor do meu servo Davi.',
        'Sucedeu, pois, que naquela mesma noite saiu o anjo do Senhor, e feriu no arraial dos assírios a cento e oitenta e cinco mil deles; e, levantando-se pela manhã cedo, eis que todos eram cadáveres.',
        'Então Senaqueribe, rei da Assíria, partiu, e se foi, e voltou e ficou em Nínive.',
        'E sucedeu que, estando ele prostrado na casa de Nisroque, seu deus, Adrameleque e Sarezer, seus filhos, o feriram à espada; porém eles escaparam para a terra de Ararate; e Esar-Hadom, seu filho, reinou em seu lugar.',
      ],
      [
        'Naqueles dias adoeceu Ezequias mortalmente; e o profeta Isaías, filho de Amós, veio a ele e lhe disse: Assim diz o SENHOR: Põe em ordem a tua casa, porque morrerás, e não viverás.',
        'Então virou o rosto para a parede, e orou ao Senhor, dizendo:',
        'Ah, Senhor! Suplico-te lembrar de que andei diante de ti em verdade, com o coração perfeito, e fiz o que era bom aos teus olhos. E chorou Ezequias muitíssimo.',
        'Sucedeu, pois, que, não havendo Isaías ainda saído do meio do pátio, veio a ele a palavra do Senhor dizendo:',
        'Volta, e dize a Ezequias, capitão do meu povo: Assim diz o Senhor, o Deus de Davi, teu pai: Ouvi a tua oração, e vi as tuas lágrimas; eis que eu te sararei; ao terceiro dia subirás à casa do Senhor.',
        'E acrescentarei aos teus dias quinze anos, e das mãos do rei da Assíria te livrarei, a ti e a esta cidade; e ampararei esta cidade por amor de mim, e por amor de Davi, meu servo.',
        'Disse mais Isaías: Tomai uma pasta de figos. E a tomaram, e a puseram sobre a chaga; e ele sarou.',
        'E Ezequias disse a Isaías: Qual é o sinal de que o Senhor me sarará, e de que ao terceiro dia subirei à casa do Senhor?',
        'Disse Isaías: Isto te será sinal, da parte do Senhor, de que o Senhor cumprirá a palavra que disse: Adiantar-se-á a sombra dez graus, ou voltará dez graus atrás?',
        'Então disse Ezequias: É fácil que a sombra decline dez graus; não seja assim, mas volte a sombra dez graus atrás.',
        'Então o profeta Isaías clamou ao Senhor; e fez voltar a sombra dez graus atrás, pelos graus que tinha declinado no relógio de sol de Acaz.',
        'Naquele tempo enviou Berodaque-Baladã, filho de Baladã, rei de babilônia, cartas e um presente a Ezequias; porque ouvira que Ezequias tinha estado doente.',
        'E Ezequias lhes deu ouvidos; e lhes mostrou toda a casa de seu tesouro, a prata, o ouro, as especiarias e os melhores ungüentos, e a sua casa de armas, e tudo quanto se achou nos seus tesouros; coisa nenhuma houve que não lhes mostrasse, nem em sua casa, nem em todo o seu domínio.',
        'Então o profeta Isaías veio ao rei Ezequias, e lhe disse: Que disseram aqueles homens, e de onde vieram a ti? Disse Ezequias: Vieram de um país muito remoto, de babilônia.',
        'E disse ele: Que viram em tua casa? E disse Ezequias: Tudo quanto há em minha casa viram; coisa nenhuma há nos meus tesouros que eu não lhes mostrasse.',
        'Então disse Isaías a Ezequias: Ouve a palavra do Senhor.',
        'Eis que vêm dias em que tudo quanto houver em tua casa, e o que entesouraram teus pais até ao dia de hoje, será levado a babilônia; não ficará coisa alguma, disse o SENHOR.',
        'E ainda até de teus filhos, que procederem de ti, e que tu gerares, tomarão, para que sejam eunucos no paço do rei da babilônia.',
        'Então disse Ezequias a Isaías: Boa é a palavra do Senhor que disseste. Disse mais: E não haverá, pois, em meus dias paz e verdade?',
        'Ora, o mais dos atos de Ezequias, e todo o seu poder, e como fez a piscina e o aqueduto, e como fez vir a água à cidade, porventura não está escrito no livro das crônicas dos reis de Judá?',
        'E Ezequias dormiu com seus pais; e Manassés, seu filho, reinou em seu lugar.',
      ],
      [
        'Tinha Manassés doze anos de idade quando começou a reinar, e cinqüenta e cinco anos reinou em Jerusalém; e era o nome de sua mãe Hefzibá.',
        'E fez o que era mau aos olhos do Senhor, conforme as abominações dos gentios que o Senhor expulsara de suas possessões, de diante dos filhos de Israel.',
        'Porque tornou a edificar os altos que Ezequias, seu pai, tinha destruído, e levantou altares a Baal, e fez um bosque como o que fizera Acabe, rei de Israel, e se inclinou diante de todo o exército dos céus, e os serviu.',
        'E edificou altares na casa do Senhor, da qual o Senhor tinha falado: Em Jerusalém porei o meu nome.',
        'Também edificou altares a todo o exército dos céus em ambos os átrios da casa do Senhor.',
        'E até fez passar a seu filho pelo fogo, adivinhava pelas nuvens, era agoureiro e ordenou adivinhos e feiticeiros; e prosseguiu em fazer o que era mau aos olhos do Senhor, para o provocar à ira.',
        'Também pôs uma imagem de escultura, do bosque que tinha feito, na casa de que o Senhor dissera a Davi e a Salomão, seu filho: Nesta casa e em Jerusalém, que escolhi de todas as tribos de Israel, porei o meu nome para sempre;',
        'E não mais farei mover o pé de Israel desta terra que tenho dado a seus pais; contanto que somente tenham cuidado de fazer conforme tudo o que lhes tenho ordenado, e conforme toda a lei que Moisés, meu servo, lhes ordenou.',
        'Porém não ouviram; porque Manassés de tal modo os fez errar, que fizeram pior do que as nações, que o Senhor tinha destruído de diante dos filhos de Israel.',
        'Então o Senhor falou pelo ministério de seus servos, os profetas, dizendo:',
        'Porquanto Manassés, rei de Judá, fez estas abominações, fazendo pior do que tudo quanto fizeram os amorreus, que foram antes dele, e até também a Judá fez pecar com os seus ídolos;',
        'Por isso, assim diz o Senhor Deus de Israel: Eis que hei de trazer um mal sobre Jerusalém e Judá, que qualquer que ouvir, lhe ficarão retinindo ambos os ouvidos.',
        'E estenderei sobre Jerusalém o cordel de Samaria e o prumo da casa de Acabe; e limparei a Jerusalém, como quem limpa o prato, limpa-o e vira-o para baixo.',
        'E desampararei os restantes da minha herança, entregá-los-ei na mão de seus inimigos; e servirão de presa e despojo para todos os seus inimigos;',
        'Porquanto fizeram o que era mau aos meus olhos e me provocaram à ira, desde o dia em que seus pais saíram do Egito até hoje.',
        'Além disso, também Manassés derramou muitíssimo sangue inocente, até que encheu a Jerusalém de um ao outro extremo, afora o seu pecado, com que fez Judá pecar, fazendo o que era mau aos olhos do Senhor.',
        'Quanto ao mais dos feitos de Manassés, e a tudo quanto fez, e ao seu pecado, que praticou, porventura não está escrito no livro das crônicas dos reis de Judá?',
        'E Manassés dormiu com seus pais, e foi sepultado no jardim da sua casa, no jardim de Uzá; e Amom, seu filho, reinou em seu lugar.',
        'Tinha Amom vinte e dois anos de idade quando começou a reinar, e dois anos reinou em Jerusalém; e era o nome de sua mãe Mesulemete, filha de Harus, de Jotbá.',
        'E fez o que era mau aos olhos do Senhor, como fizera Manassés, seu pai.',
        'Porque andou em todo o caminho em que andara seu pai; e serviu os ídolos, a que seu pai tinha servido, e se inclinou diante deles.',
        'Assim deixou ao Senhor Deus de seus pais, e não andou no caminho do Senhor.',
        'E os servos de Amom conspiraram contra ele, e mataram o rei em sua casa.',
        'Porém o povo da terra feriu a todos os que conspiraram contra o rei Amom; e o povo da terra pôs Josias, seu filho, rei em seu lugar.',
        'Quanto ao mais dos atos de Amom, que fez, porventura não está escrito no livro das crônicas dos reis de Judá?',
        'E o sepultaram na sua sepultura, no jardim de Uzá; e Josias, seu filho, reinou em seu lugar.',
      ],
      [
        'Tinha Josias oito anos de idade quando começou a reinar, e reinou trinta e um anos em Jerusalém; e era o nome de sua mãe, Jedida, filha de Adaías, de Bozcate.',
        'E fez o que era reto aos olhos do Senhor; e andou em todo o caminho de Davi, seu pai, e não se apartou dele nem para a direita nem para a esquerda.',
        'Sucedeu que, no ano décimo oitavo do rei Josias, o rei mandou ao escrivão Safã, filho de Azalias, filho de Mesulão, à casa do Senhor, dizendo:',
        'Sobe a Hilquias, o sumo sacerdote, para que tome o dinheiro que se trouxe à casa do Senhor, o qual os guardas do umbral da porta ajuntaram do povo,',
        'E que o dêem na mão dos que têm cargo da obra, e estão encarregados da casa do Senhor; para que o dêem àqueles que fazem a obra que há na casa do Senhor, para repararem as fendas da casa;',
        'Aos carpinteiros, aos edificadores e aos pedreiros; e para comprar madeira e pedras lavradas, para repararem a casa.',
        'Porém não se pediu conta do dinheiro que se lhes entregara nas suas mãos, porquanto procediam com fidelidade.',
        'Então disse o sumo sacerdote Hilquias ao escrivão Safã: Achei o livro da lei na casa do Senhor. E Hilquias deu o livro a Safã, e ele o leu.',
        'Então o escrivão Safã veio ter com o rei e, dando-lhe conta, disse: Teus servos ajuntaram o dinheiro que se achou na casa, e o entregaram na mão dos que têm cargo da obra, que estão encarregados da casa do Senhor.',
        'Também Safã, o escrivão, fez saber ao rei, dizendo: O sacerdote Hilquias me deu um livro. E Safã o leu diante do rei.',
        'Sucedeu, pois, que, ouvindo o rei as palavras do livro da lei, rasgou as suas vestes.',
        'E o rei mandou a Hilquias, o sacerdote, a Aicão, filho de Safã, a Acbor, filho de Micaías, a Safã o escrivão e a Asaías, o servo do rei, dizendo:',
        'Ide, e consultai o Senhor por mim, pelo povo e por todo o Judá, acerca das palavras deste livro que se achou; porque grande é o furor do Senhor, que se acendeu contra nós; porquanto nossos pais não deram ouvidos às palavras deste livro, para fazerem conforme tudo quanto acerca de nós está escrito.',
        'Então foi o sacerdote Hilquias, e Aicão, Acbor, Safã e Asaías à profetisa Hulda, mulher de Salum, filho de Ticvá, o filho de Harás, o guarda das vestiduras (e ela habitava em Jerusalém, na segunda parte), e lhe falaram.',
        'E ela lhes disse: Assim diz o Senhor Deus de Israel: Dizei ao homem que vos enviou a mim:',
        'Assim diz o Senhor: Eis que trarei mal sobre este lugar, e sobre os seus moradores, a saber: todas as palavras do livro que leu o rei de Judá.',
        'Porquanto me deixaram, e queimaram incenso a outros deuses, para me provocarem à ira por todas as obras das suas mãos, o meu furor se acendeu contra este lugar, e não se apagará.',
        'Porém ao rei de Judá, que vos enviou a consultar o Senhor, assim lhe direis: Assim diz o Senhor Deus de Israel, acerca das palavras, que ouviste:',
        'Porquanto o teu coração se enterneceu, e te humilhaste perante o Senhor, quando ouviste o que falei contra este lugar, e contra os seus moradores, que seria para assolação e para maldição, e que rasgaste as tuas vestes, e choraste perante mim, também eu te ouvi, diz o Senhor.',
        'Por isso eis que eu te recolherei a teus pais, e tu serás recolhido em paz à tua sepultura, e os teus olhos não verão todo o mal que hei de trazer sobre este lugar. Então tornaram a trazer ao rei a resposta.',
      ],
      [
        'Então o rei ordenou, e todos os anciãos de Judá e de Jerusalém se reuniram a ele.',
        'O rei subiu à casa do Senhor, e com ele todos os homens de Judá, e todos os moradores de Jerusalém, os sacerdotes, os profetas e todo o povo, desde o menor até ao maior; e leu aos ouvidos deles todas as palavras do livro da aliança, que se achou na casa do Senhor.',
        'E o rei se pôs em pé junto à coluna, e fez a aliança perante o Senhor, para seguirem o Senhor, e guardarem os seus mandamentos, os seus testemunhos e os seus estatutos, com todo o coração e com toda a alma, confirmando as palavras desta aliança, que estavam escritas naquele livro; e todo o povo apoiou esta aliança.',
        'E o rei mandou ao sumo sacerdote Hilquias, aos sacerdotes da segunda ordem, e aos guardas do umbral da porta, que tirassem do templo do Senhor todos os vasos que se tinham feito para Baal, para o bosque e para todo o exército dos céus e os queimou fora de Jerusalém, nos campos de Cedrom e levou as cinzas deles a Betel.',
        'Também destituiu os sacerdotes que os reis de Judá estabeleceram para incensarem sobre os altos nas cidades de Judá e ao redor de Jerusalém, como também os que queimavam incenso a Baal, ao sol, à lua, e aos planetas, e a todo o exército dos céus.',
        'Também tirou da casa do Senhor o ídolo do bosque levando-o para fora de Jerusalém até ao ribeiro de Cedrom, e o queimou junto ao ribeiro de Cedrom, e o desfez em pó, e lançou o seu pó sobre as sepulturas dos filhos do povo.',
        'Também derrubou as casas dos sodomitas que estavam na casa do Senhor, em que as mulheres teciam casinhas para o ídolo do bosque.',
        'E a todos os sacerdotes trouxe das cidades de Judá, e profanou os altos em que os sacerdotes queimavam incenso, desde Geba até Berseba; e derrubou os altos que estavam às portas, junto à entrada da porta de Josué, o governador da cidade, que estava à esquerda daquele que entrava pela porta da cidade.',
        'Mas os sacerdotes dos altos não sacrificavam sobre o altar do Senhor em Jerusalém; porém comiam pães ázimos no meio de seus irmãos.',
        'Também profanou a Tofete, que está no vale dos filhos de Hinom, para que ninguém fizesse passar a seu filho, ou sua filha, pelo fogo a Moloque.',
        'Também tirou os cavalos que os reis de Judá tinham dedicado ao sol, à entrada da casa do Senhor, perto da câmara de Natã-Meleque, o camareiro, que estava no recinto; e os carros do sol queimou a fogo.',
        'Também o rei derrubou os altares que estavam sobre o terraço do cenáculo de Acaz, os quais os reis de Judá tinham feito, como também o rei derrubou os altares que fizera Manassés nos dois átrios da casa do Senhor; e esmiuçados os tirou dali e lançou o pó deles no ribeiro de Cedrom.',
        'O rei profanou também os altos que estavam defronte de Jerusalém, à mão direita do monte de Masite, os quais edificara Salomão, rei de Israel, a Astarote, a abominação dos sidônios, e a Quemós, a abominação dos moabitas, e a Milcom, a abominação dos filhos de Amom.',
        'Semelhantemente quebrou as estátuas, cortou os bosques e encheu o seu lugar com ossos de homens.',
        'E também o altar que estava em Betel, e o alto que fez Jeroboão, filho de Nebate, com que tinha feito Israel pecar, esse altar derrubou juntamente com o alto; queimando o alto, em pó o esmiuçou, e queimou o ídolo do bosque.',
        'E, virando-se Josias, viu as sepulturas que estavam ali no monte; e mandou tirar os ossos das sepulturas, e os queimou sobre aquele altar, e assim o profanou, conforme a palavra do Senhor, que profetizara o homem de Deus, quando anunciou estas palavras.',
        'Então disse: Que é este monumento que vejo? E os homens da cidade lhe disseram: É a sepultura do homem de Deus que veio de Judá, e anunciou estas coisas que fizeste contra este altar de Betel.',
        'E disse: Deixai-o estar; ninguém mexa nos seus ossos. Assim deixaram estar os seus ossos com os ossos do profeta que viera de Samaria.',
        'Demais disto também Josias tirou todas as casas dos altos que havia nas cidades de Samaria, e que os reis de Israel tinham feito para provocarem à ira o Senhor; e lhes fez conforme todos os atos que tinha feito em Betel.',
        'E sacrificou todos os sacerdotes dos altos, que havia ali, sobre os altares, e queimou ossos humanos sobre eles; depois voltou a Jerusalém.',
        'O rei deu ordem a todo o povo, dizendo: Celebrai a páscoa ao Senhor vosso Deus, como está escrito no livro da aliança.',
        'Porque nunca se celebrou tal páscoa como esta desde os dias dos juízes que julgaram a Israel, nem em todos os dias dos reis de Israel, nem tampouco dos reis de Judá.',
        'Porém no ano décimo oitavo do rei Josias esta páscoa se celebrou ao Senhor em Jerusalém.',
        'E também os adivinhos, os feiticeiros, os terafins, os ídolos, e todas as abominações que se viam na terra de Judá e em Jerusalém, os extirpou Josias, para confirmar as palavras da lei, que estavam escritas no livro que o sacerdote Hilquias achara na casa do Senhor.',
        'E antes dele não houve rei semelhante, que se convertesse ao Senhor com todo o seu coração, com toda a sua alma e com todas as suas forças, conforme toda a lei de Moisés; e depois dele nunca se levantou outro tal.',
        'Todavia o Senhor não se demoveu do ardor da sua grande ira, com que ardia contra Judá, por todas as provocações com que Manassés o tinha provocado.',
        'E disse o Senhor: Também a Judá hei de tirar de diante da minha face, como tirei a Israel, e rejeitarei esta cidade de Jerusalém que escolhi, como também a casa de que disse: Estará ali o meu nome.',
        'Ora, o mais dos atos de Josias e tudo quanto fez, porventura não está escrito no livro das crônicas dos reis de Judá?',
        'Nos seus dias subiu Faraó Neco, rei do Egito, contra o rei da Assíria, ao rio Eufrates; e o rei Josias lhe foi ao encontro; e, vendo-o ele, o matou em Megido.',
        'E seus servos, num carro, o levaram morto, de Megido, e o trouxeram a Jerusalém, e o sepultaram na sua sepultura; e o povo da terra tomou a Jeoacaz, filho de Josias, e ungiram-no, e fizeram-no rei em lugar de seu pai.',
        'Tinha Jeoacaz vinte e três anos de idade quando começou a reinar, e três meses reinou em Jerusalém; e era o nome de sua mãe Hamutal, filha de Jeremias, de Libna.',
        'E fez o que era mau aos olhos do Senhor, conforme tudo o que fizeram seus pais.',
        'Porém Faraó Neco o mandou prender em Ribla, em terra de Hamate, para que não reinasse em Jerusalém; e à terra impôs pena de cem talentos de prata e um talento de ouro.',
        'Também Faraó Neco constituiu rei a Eliaquim, filho de Josias, em lugar de seu pai Josias, e lhe mudou o nome para Jeoiaquim; porém a Jeoacaz tomou consigo, e foi ao Egito, e morreu ali.',
        'E Jeoiaquim deu aquela prata e aquele ouro a Faraó; porém tributou a terra, para dar esse dinheiro conforme o mandado de Faraó; a cada um segundo a sua avaliação exigiu a prata e o ouro do povo da terra, para o dar a Faraó Neco.',
        'Tinha Jeoiaquim vinte e cinco anos de idade quando começou a reinar, e reinou onze anos em Jerusalém; e era o nome de sua mãe Zebida, filha de Pedaías, de Ruma.',
        'E fez o que era mau aos olhos do Senhor, conforme tudo quanto fizeram seus pais.',
      ],
      [
        'Nos seus dias subiu Nabucodonosor, rei de babilônia, e Jeoiaquim ficou três anos seu servo; depois se virou, e se rebelou contra ele.',
        'E o Senhor enviou contra ele as tropas dos caldeus, as tropas dos sírios, as tropas dos moabitas e as tropas dos filhos de Amom; e as enviou contra Judá, para o destruir, conforme a palavra do Senhor, que falara pelo ministério de seus servos, os profetas.',
        'E, na verdade, conforme o mandado do Senhor, assim sucedeu a Judá, para o afastar da sua presença por causa dos pecados de Manassés, conforme tudo quanto fizera.',
        'Como também por causa do sangue inocente que derramou; pois encheu a Jerusalém de sangue inocente; e por isso o Senhor não quis perdoar.',
        'Ora, o mais dos atos de Jeoiaquim, e tudo quanto fez, porventura não está escrito no livro das crônicas dos reis de Judá?',
        'E Jeoiaquim dormiu com seus pais; e Joaquim, seu filho, reinou em seu lugar.',
        'E o rei do Egito nunca mais saiu da sua terra; porque o rei de babilônia tomou tudo quanto era do rei do Egito, desde o rio do Egito até ao rio Eufrates.',
        'Tinha Joaquim dezoito anos de idade quando começou a reinar, e reinou três meses em Jerusalém; e era o nome de sua mãe, Neusta, filha de Elnatã, de Jerusalém.',
        'E fez o que era mau aos olhos do Senhor, conforme tudo quanto fizera seu pai.',
        'Naquele tempo subiram os servos de Nabucodonosor, rei de babilônia, a Jerusalém; e a cidade foi cercada.',
        'Também veio Nabucodonosor, rei de babilônia, contra a cidade, quando já os seus servos a estavam sitiando.',
        'Então saiu Joaquim, rei de Judá, ao rei de babilônia, ele, sua mãe, seus servos, seus príncipes e seus oficiais; e o rei de babilônia o tomou preso, no ano oitavo do seu reinado.',
        'E tirou dali todos os tesouros da casa do Senhor e os tesouros da casa do rei; e partiu todos os vasos de ouro, que fizera Salomão, rei de Israel, no templo do Senhor, como o Senhor tinha falado.',
        'E transportou a toda a Jerusalém como também a todos os príncipes, e a todos os homens valorosos, dez mil presos, e a todos os artífices e ferreiros; ninguém ficou senão o povo pobre da terra.',
        'Assim transportou Joaquim à babilônia; como também a mãe do rei, as mulheres do rei, os seus oficiais e os poderosos da terra levou presos de Jerusalém à babilônia.',
        'E todos os homens valentes, até sete mil, e artífices e ferreiros até mil, e todos os homens destros na guerra, a estes o rei de babilônia levou presos para babilônia.',
        'E o rei de babilônia estabeleceu a Matanias, seu tio, rei em seu lugar; e lhe mudou o nome para Zedequias.',
        'Tinha Zedequias vinte e um anos de idade quando começou a reinar, e reinou onze anos em Jerusalém; e era o nome de sua mãe Hamutal, filha de Jeremias, de Libna.',
        'E fez o que era mau aos olhos do Senhor, conforme tudo quanto fizera Jeoiaquim.',
        'Porque assim sucedeu por causa da ira do SENHOR contra Jerusalém, e contra Judá, até os rejeitar de diante da sua presença; e Zedequias se rebelou contra o rei de babilônia.',
      ],
      [
        'E sucedeu que, no nono ano do seu reinado, no mês décimo, aos dez do mês, Nabucodonosor, rei de babilônia, veio contra Jerusalém, ele e todo o seu exército, e se acampou contra ela, e levantaram contra ela trincheiras em redor.',
        'E a cidade foi sitiada até ao undécimo ano do rei Zedequias.',
        'Aos nove do mês quarto, quando a cidade se via apertada pela fome, nem havia pão para o povo da terra,',
        'Então a cidade foi invadida, e todos os homens de guerra fugiram de noite pelo caminho da porta, entre os dois muros que estavam junto ao jardim do rei (porque os caldeus estavam contra a cidade em redor), e o rei se foi pelo caminho da campina.',
        'Porém o exército dos caldeus perseguiu o rei, e o alcançou nas campinas de Jericó; e todo o seu exército se dispersou.',
        'E tomaram o rei, e o fizeram subir ao rei de babilônia, a Ribla; e foi-lhe pronunciada a sentença.',
        'E aos filhos de Zedequias mataram diante dos seus olhos; e vazaram os olhos de Zedequias, e o ataram com duas cadeias de bronze, e o levaram a babilônia.',
        'E no quinto mês, no sétimo dia do mês (este era o ano décimo nono de Nabucodonosor, rei de babilônia), veio Nebuzaradã, capitão da guarda, servo do rei de babilônia, a Jerusalém.',
        'E queimou a casa do Senhor e a casa do rei, como também todas as casas de Jerusalém, e todas as casas dos grandes queimou.',
        'E todo o exército dos caldeus, que estava com o capitão da guarda, derrubou os muros em redor de Jerusalém.',
        'E o mais do povo que deixaram ficar na cidade, os rebeldes que se renderam ao rei de babilônia e o mais da multidão, Nebuzaradã, o capitão da guarda, levou presos.',
        'Porém dos mais pobres da terra deixou o capitão da guarda ficar alguns para vinheiros e para lavradores.',
        'Quebraram mais, os caldeus, as colunas de cobre que estavam na casa do SENHOR, como também as bases e o mar de cobre que estavam na casa do SENHOR; e levaram o seu bronze para babilônia.',
        'Também tomaram as caldeiras, as pás, os apagadores, as colheres e todos os vasos de cobre, com que se ministrava.',
        'Também o capitão-da-guarda tomou os braseiros, e as bacias, o que era de ouro puro, em ouro e o que era de prata, em prata.',
        'As duas colunas, um mar, e as bases, que Salomão fizera para a casa do Senhor; o cobre de todos estes vasos não tinha peso.',
        'A altura de uma coluna era de dezoito côvados, e sobre ela havia um capitel de cobre, e de altura tinha o capitel três côvados; e a rede e as romãs em redor do capitel, tudo era de cobre; e semelhante a esta era a outra coluna com a rede.',
        'Também o capitão-da-guarda tomou a Seraías, primeiro sacerdote, e a Sofonias, segundo sacerdote, e aos três guardas do umbral da porta.',
        'E da cidade tomou a um oficial, que tinha cargo dos homens de guerra, e a cinco homens dos que estavam na presença do rei, e se achavam na cidade, como também ao escrivão-mor do exército, que registrava o povo da terra para a guerra, e a sessenta homens do povo da terra, que se achavam na cidade.',
        'E tomando-os Nebuzaradã, o capitão da guarda, os levou ao rei de babilônia, a Ribla.',
        'E o rei de babilônia os feriu e os matou em Ribla, na terra de Hamate; e Judá foi levado preso para fora da sua terra.',
        'Porém, quanto ao povo que ficara na terra de Judá, que Nabucodonosor, rei de babilônia, deixou ficar, pôs sobre ele, por governador a Gedalias, filho de Aicão, filho de Safã.',
        'Ouvindo, pois, os capitães dos exércitos, eles e os seus homens, que o rei de babilônia pusera a Gedalias por governador, vieram a Gedalias, a Mizpá, a saber: Ismael, filho de Netanias, e Joanã, filho de Careá, e Seraías, filho de Tanumete, o netofatita, e Jazanias, filho do maacatita, eles e os seus homens.',
        'E Gedalias jurou a eles e aos seus homens, e lhes disse: Não temais ser servos dos caldeus; ficai na terra, servi ao rei de babilônia, e bem vos irá.',
        'Sucedeu, porém, que, no sétimo mês, veio Ismael, filho de Netanias, o filho de Elisama, da descendência real, e dez homens com ele, e feriram a Gedalias, e ele morreu, como também aos judeus, e aos caldeus que estavam com ele em Mizpá.',
        'Então todo o povo se levantou, desde o menor até ao maior, como também os capitães dos exércitos, e foram ao Egito, porque temiam os caldeus.',
        'Depois disto sucedeu que, no ano trinta e sete do cativeiro de Joaquim, rei de Judá, no mês duodécimo, aos vinte e sete do mês, Evil-Merodaque, rei de babilônia, no ano em que reinou, levantou a cabeça de Joaquim, rei de Judá, tirando-o da casa da prisão.',
        'E lhe falou benignamente; e pôs o seu trono acima do trono dos reis que estavam com ele em babilônia.',
        'E lhe mudou as roupas de prisão, e de contínuo comeu pão na sua presença todos os dias da sua vida.',
        'E, quanto à sua subsistência, pelo rei lhe foi dada subsistência contínua, a porção de cada dia no seu dia, todos os dias da sua vida.',
      ],
    ],
    livro: '2reis',
  },
  {
    abbrev: '1cr',
    capitulos: [
      [
        'Adão, Sete, Enos,',
        'Cainã, Maalaleel, Jerede,',
        'Enoque, Matusalém, Lameque,',
        'Noé, Sem, Cão e Jafé.',
        'Os filhos de Jafé foram: Gomer, Magogue, Madai, Javã, Tubal, Meseque e Tiras.',
        'E os filhos de Gomer: Asquenaz, Rifate, Togarma.',
        'E os filhos de Javã: Elisá, Társis, Quitim e Dodanim.',
        'Os filhos de Cão: Cuxe, Mizraim, Pute e Canaã.',
        'E os filhos de Cuxe eram: Sebá, Havilá, Sabtá, Raamá e Sabtecá; os filhos de Raamá: Sebá e Dedã.',
        'E Cuxe gerou a Ninrode, que começou a ser poderoso na terra.',
        'E Mizraim gerou a Ludim e a Anamim e a Leabim e a Naftuim,',
        'E a Patrusim e a Casluim (dos quais procedem os filisteus) e a Caftorim.',
        'E Canaã gerou a Sidom seu primogênito, e a Hete,',
        'E aos jebuseus e aos amorreus e aos girgaseus,',
        'E aos heveus e aos arqueus e aos sineus,',
        'E aos arvadeus e aos zemareus e aos hamateus.',
        'E foram os filhos de Sem: Elão, Assur, Arfaxade, Lude, Arã, Uz, Hul, Geter e Meseque.',
        'E Arfaxade gerou a Selá e Selá gerou a Éber.',
        'E a Éber nasceram dois filhos: o nome de um foi Pelegue, porquanto nos seus dias se repartiu a terra, e o nome de seu irmão era Joctã.',
        'E Joctã gerou a Almodá, a Selefe, a Hazarmavé, e a Jerá,',
        'E a Hadorão, a Usal, e a Dicla,',
        'E a Obal, a Abimael, a Sebá,',
        'E a Ofir, a Havilá, e a Jobabe: todos estes foram filhos de Joctã.',
        'Sem, Arfaxade, Selá,',
        'Éber, Pelegue, Reú,',
        'Serugue, Naor, Terá,',
        'Abrão, que é Abraão.',
        'Os filhos de Abraão foram: Isaque e Ismael.',
        'Estas são as suas gerações: o primogênito de Ismael foi Nebaiote, e, depois, Quedar, Adbeel, Mibsão,',
        'Misma, Dumá, Massá, Hadade, Tema,',
        'Jetur, Nafis e Quedemá; estes foram os filhos de Ismael.',
        'Quanto aos filhos de Quetura, concubina de Abraão, esta deu à luz a Zinrã, a Jocsã, a Medã, a Midiã, a Jisbaque e a Suá; e os filhos de Jocsã foram Seba e Dedã.',
        'E os filhos de Midiã: Efá, Efer, Enoque, Abida e Elda; todos estes foram filhos de Quetura.',
        'Abraão, pois, gerou a Isaque; e foram os filhos de Isaque: Esaú e Israel.',
        'Os filhos de Esaú: Elifaz, Reuel, Jeús, Jalão e Coré.',
        'Os filhos de Elifaz: Temã, Omar, Zefi, Gaetã, Quenaz, Timna e Amaleque.',
        'Os filhos de Reuel: Naate, Zerá, Samá e Mizá.',
        'E os filhos de Seir: Lotã, Sobal, Zibeão, Aná, Disom, Eser e Disã.',
        'E os filhos de Lotã: Hori e Homã; e a irmã de Lotã foi Timna.',
        'Os filhos de Sobal eram Alvã, Manaate, Ebal, Sefi e Onã; e os filhos de Zibeão eram Aiá e Aná.',
        'O filho de Aná foi Disom; e os filhos de Disom foram Hanrão, Esbã, Itrã e Querã.',
        'Os filhos de Eser eram: Bilã, Zaavã e Jaacã; os filhos de Disã eram: Uz e Arã.',
        'E estes são os reis que reinaram na terra de Edom, antes que reinasse rei sobre os filhos de Israel: Bela, filho de Beor, e era o nome da sua cidade Dinabá.',
        'E morreu Bela, e reinou em seu lugar Jobabe, filho de Zerá, de Bozra.',
        'E morreu Jobabe, e reinou em seu lugar Husão, da terra dos temanitas.',
        'E morreu Husão, e reinou em seu lugar Hadade, filho de Bedade; este feriu os midianitas no campo de Moabe; e era o nome da sua cidade Avite.',
        'E morreu Hadade, e reinou em seu lugar Samlá, de Masreca.',
        'E morreu Samlá, e reinou em seu lugar Saul, de Reobote, junto ao rio.',
        'E morreu Saul, e reinou em seu lugar Baal-Hanã, filho de Acbor.',
        'E, morrendo Baal-Hanã, Hadade reinou em seu lugar; e era o nome da sua cidade Paí; e o nome de sua mulher era Meetabel, filha de Matrede, filha de Me-Zaabe.',
        'E, morrendo Hadade, foram príncipes em Edom o príncipe Timna, o príncipe Alva, o príncipe Jetete,',
        'O príncipe Oolibama, o príncipe Elá, o príncipe Pinom,',
        'O príncipe Quenaz, o príncipe Temã, o príncipe Mibzar,',
        'O príncipe Magdiel, o príncipe Irã, estes foram os príncipes de Edom.',
      ],
      [
        'Estes são os filhos de Israel: Rúben, Simeão, Levi, Judá, Issacar e Zebulom;',
        'Dã, José e Benjamim, Naftali, Gade e Aser.',
        'Os filhos de Judá foram Er, e Onã, e Selá, estes três lhe nasceram da filha de Suá, a cananéia; e Er, o primogênito de Judá, foi mau aos olhos do Senhor, pelo que o matou.',
        'Porém Tamar, sua nora, lhe deu à luz Perez e Zerá; todos os filhos de Judá foram cinco.',
        'Os filhos de Perez foram Hezrom e Hamul.',
        'E os filhos de Zerá: Zinri, e Etã, e Hemã, e Calcol, e Dara: cinco ao todo.',
        'E os filhos de Carmi foram Acar, o perturbador de Israel, que pecou no anátema.',
        'E o filho de Etã foi Azarias.',
        'E os filhos de Hezrom, que lhe nasceram, foram Jerameel, e Rão, e Quelubai.',
        'E Rão gerou a Aminadabe, e Aminadabe gerou a Naassom, príncipe dos filhos de Judá.',
        'E Naassom gerou a Salma, e Salma gerou a Boaz.',
        'E Boaz gerou a Obede, e Obede gerou a Jessé.',
        'E Jessé gerou a Eliabe, seu primogênito, e Abinadabe, o segundo, e Siméia, o terceiro.',
        'Natanael, o quarto, Radai, o quinto.',
        'Ozém, o sexto, Davi, o sétimo.',
        'E foram suas irmãs Zeruia e Abigail; e foram os filhos de Zeruia: Abisai e Joabe, e Asael, três.',
        'E Abigail deu à luz a Amasa; e o pai de Amasa foi Jeter, o ismaelita.',
        'E Calebe, filho de Hezrom, gerou filhos de Azuba, sua mulher, e de Jeriote; e os filhos desta foram estes: Jeser, Sobabe, e Ardom.',
        'E morreu Azuba; e Calebe tomou para si a Efrate, da qual lhe nasceu Hur.',
        'E Hur gerou a Uri, e Uri gerou a Bezaleel.',
        'Então Hezrom coabitou com a filha de Maquir, pai de Gileade, e, sendo ele de sessenta anos, a tomou; e ela deu à luz a Segube.',
        'E Segube gerou a Jair; e este tinha vinte e três cidades na terra de Gileade.',
        'E Gesur e Arã tomaram deles as aldeias de Jair, e Quenate, e seus lugares, sessenta cidades; todos estes foram filhos de Maquir, pai de Gileade.',
        'E, depois da morte de Hezrom, em Calebe de Efrata, Abia, mulher de Hezrom, deu à luz a Asur, pai de Tecoa.',
        'E os filhos de Jerameel, primogênito de Hezrom, foram Rão, o primogênito, Buna, Orem, Ozém e Aías.',
        'Teve também Jerameel ainda outra mulher cujo nome era Atara; esta foi a mãe de Onã.',
        'E foram os filhos de Rão, primogênito de Jerameel: Maaz, Jamim, e Equer.',
        'E foram os filhos de Onã: Samai e Jada; e os filhos de Samai: Nadabe e Abisur.',
        'E o nome da mulher de Abisur era Abiail, que lhe deu a Abã e a Molide.',
        'E foram os filhos de Nadabe, Selede e Apaim; e Selede morreu sem filhos.',
        'E o filho de Apaim foi Isi; e o filho de Isi, Sesã. E o filho de Sesã, Alai.',
        'E os filhos de Jada, irmão de Samai, foram Jeter e Jônatas; e Jeter morreu sem filhos.',
        'E os filhos de Jônatas foram: Pelete e Zaza; estes foram os filhos de Jerameel.',
        'E Sesã não teve filhos, mas filhas; e tinha Sesã um servo egípcio, cujo nome era Jará.',
        'Deu, pois, Sesã sua filha por mulher a Jará, seu servo; e lhe deu à luz a Atai.',
        'E Atai gerou a Natã, e Natã gerou a Zabade.',
        'E Zabade gerou a Eflal, e Eflal gerou a Obede.',
        'E Obede gerou a Jeú, e Jeú gerou a Azarias.',
        'E Azarias gerou a Helez, e Helez gerou a Eleasá.',
        'E Eleasá gerou a Sismai, e Sismai gerou a Salum.',
        'E Salum gerou a Jecamias, e Jecamias gerou a Elisama.',
        'E foram os filhos de Calebe, irmão de Jerameel, Messa, seu primogênito (este foi o pai de Zife), e os filhos de Maressa, pai de Hebrom.',
        'E foram os filhos de Hebrom: Coré, Tápua, Requém e Sema.',
        'E Sema gerou a Raão, pai de Jorqueão; e Requém gerou a Samai.',
        'E foi o filho de Samai, Maom; e Maom foi pai de Bete-Zur.',
        'E Efá, a concubina de Calebe, deu à luz a Harã, a Mosa, e a Gazez; e Harã gerou a Gazez.',
        'E foram filhos de Jadai: Regém, Jotão, Gesã, Pelete, Efá e Saafe.',
        'De Maaca, concubina, Calebe gerou a Seber e a Tiraná.',
        'E a mulher de Saafe, pai de Madmana, deu à luz a Seva, pai de Macbena e pai de Gibeá; e foi a filha de Calebe, Acsa.',
        'Estes foram os filhos de Calebe, filho de Hur, o primogênito de Efrata: Sobal, pai de Quiriate-Jearim,',
        'E Salma, pai dos belemitas, Harefe, pai de Bete-Gader.',
        'E foram os filhos de Sobal, pai de Quiriate-Jearim: Haroé e metade dos menuítas.',
        'E as famílias de Quiriate-Jearim foram os jitreus, e os puteus, e os sumateus, e os misraeus; destes saíram os zorateus, e os estaoleus.',
        'Os filhos de Salma foram Belém e os netofatitas, Atarote, Bete-Joabe, e metade dos manaatitas, e os zoritas.',
        'E as famílias dos escribas que habitavam em Jabez foram os tiratitas, os simeatitas e os sucatitas; estes são os queneus, que vieram de Hamate, pai da casa de Recabe.',
      ],
      [
        'E estes foram os filhos de Davi, que lhe nasceram em Hebrom: o primogênito, Amnom, de Ainoã, a jizreelita; o segundo Daniel, de Abigail, a carmelita;',
        'O terceiro, Absalão, filho de Maaca, filha de Talmai, rei de Gesur; o quarto, Adonias, filho de Hagite;',
        'O quinto, Sefatias, de Abital; o sexto, Itreão, de Eglá, sua mulher.',
        'Seis filhos lhe nasceram em Hebrom, porque ali reinou sete anos e seis meses; e trinta e três anos reinou em Jerusalém.',
        'E estes lhe nasceram em Jerusalém: Siméia, e Sobabe, e Natã, e Salomão; estes quatro lhe nasceram de Bate-Sua, filha de Amiel.',
        'Nasceram-lhe mais Ibar, Elisama, Elifelete,',
        'Nogá, Nefegue, Jafia,',
        'Elisama, Eliada, e Elifelete, nove.',
        'Todos estes foram filhos de Davi, afora os filhos das concubinas e Tamar, irmã deles.',
        'E o filho de Salomão foi Roboão; de quem foi filho Abias; de quem foi filho Asa; de quem foi filho Jeosafá;',
        'De quem foi filho Jorão; de quem foi filho Acazias; de quem foi filho Joás;',
        'De quem foi filho Amazias; de quem foi filho Jotão;',
        'De quem foi filho Acaz; de quem foi filho Ezequias; de quem foi filho Manassés;',
        'De quem foi filho Amom; de quem foi filho Josias.',
        'E os filhos de Josias foram: o primogênito, Joanã: o segundo, Jeoiaquim; o terceiro, Zedequias; o quarto, Salum.',
        'E os filhos de Jeoiaquim: Jeconias, seu filho, e Zedequias, seu filho.',
        'E os filhos de Jeconias: Assir, e seu filho Sealtiel.',
        'Os filhos deste foram: Malquirão, Pedaías, Senazar, Jecamias, Hosama, e Nedabias.',
        'E os filhos de Pedaías: Zorobabel e Simei; e os filhos de Zorobabel: Mesulão, Hananias, e Selomite, sua irmã,',
        'E Hasubá, Oel, Berequias, Hasadias, Jusabe-Hesede, cinco.',
        'E os filhos de Hananias: Pelatias e Jesaías; os filhos de Refaías, os filhos de Arnã, os filhos de Obadias, e os filhos de Secanias.',
        'E o filho de Secanias foi Semaías; e os filhos de Semaías: Hatus, e Igeal, e Bariá, e Nearias, e Safate, seis.',
        'E os filhos de Nearias: Elioenai, e Ezequias, e Azricão, três.',
        'E os filhos de Elioenai; Hodavias, Eliasibe, Pelaías, Acube, Joanã, Delaías, e Anani, sete.',
      ],
      [
        'Os filhos de Judá foram: Perez, Hezrom, Carmi, Hur, e Sobal.',
        'E Reaías, filho de Sobal gerou a Jaate, e Jaate gerou a Aumai e a Laade; estas são as famílias dos zoratitas.',
        'E estes foram os filhos do pai de Etã: Jizreel, Isma e Idbas; e era o nome de sua irmã Hazelelponi.',
        'E mais Penuel, pai de Gedor, e Ezer, pai de Husá; estes foram os filhos de Hur, o primogênito de Efrata, pai de Belém.',
        'E tinha Asur, pai de Tecoa, duas mulheres: Helá e Naará.',
        'E Naará deu à luz a Auzão, e a Hefer, e a Temeni, e a Haastari; estes foram os filhos de Naará.',
        'E os filhos de Helá: Zerete, Izar e Etnã.',
        'E Coz gerou a Anube e a Zobeba e as famílias de Aarel, filho de Harum.',
        'E foi Jabez mais ilustre do que seus irmãos; e sua mãe deu-lhe o nome de Jabez, dizendo: Porquanto com dores o dei à luz.',
        'Porque Jabez invocou o Deus de Israel, dizendo: Se me abençoares muitíssimo, e meus termos ampliares, e a tua mão for comigo, e fizeres que do mal não seja afligido! E Deus lhe concedeu o que lhe tinha pedido.',
        'E Quelube, irmão de Suá, gerou a Meir; este é o pai de Estom.',
        'E Estom gerou a Bete-Rafa, a Pasea, e a Teina, pai de Ir-Naás; estes foram os homens de Reca.',
        'E foram os filhos de Quenaz: Otniel e Seraías; o filho de Otniel: Hatate.',
        'E Meonotai gerou a Ofra, e Seraías gerou a Joabe, pai dos do vale dos artífices; porque os dali eram artífices.',
        'E foram os filhos de Calebe, filho de Jefoné: Iru, Elá e Naã; e o filho de Elá: Quenaz.',
        'E os filhos de Jealelel: Zife, Zifa, Tiria e Asareel.',
        'E os filhos de Ezra: Jeter, Merede, Efer, e Jalom; e teve mais a Miriã, e Samai, e Isbá, pai de Estemoa.',
        'E sua mulher, Judia, deu à luz a Jerede, pai de Gedor, e a Héber, pai de Socó, e a Jecutiel, pai de Zanoa; e estes foram os filhos de Bitia, filha de Faraó, que Merede tomou.',
        'E foram os filhos da mulher de Hodias, irmã de Naã: Abiqueila, o garmita, Estemoa, o maacatita.',
        'E os filhos de Simeão: Amom, Rina, Bene-Hanã, e Tilom; e os filhos de Isi: Zoete e Bene-Zoete.',
        'Os filhos de Selá, filho de Judá: Er, pai de Leca, e Lada, pai de Maressa, e as famílias da casa dos que fabricavam linho fino, em casa de Asbéia.',
        'Como também Joquim, e os homens de Cozeba, e Joás, e Sarafe (que dominaram sobre os moabitas), e Jasubi-Leém; porém estas coisas já são antigas.',
        'Estes foram oleiros, e habitavam nas hortas e nos cerrados; estes ficaram ali com o rei na sua obra.',
        'Os filhos de Simeão foram Nemuel, Jamim, Jaribe, Zerá, e Saul,',
        'Cujo filho foi Salum, de quem foi filho Mibsão, de quem foi filho Misma.',
        'E os filhos de Misma foram: Hamuel, de quem foi filho Zacur, de quem foi filho Simei.',
        'E Simei teve dezesseis filhos, e seis filhas, porém seus irmãos não tiveram muitos filhos; e toda a sua família não se multiplicou tanto como as dos filhos de Judá.',
        'E habitaram em Berseba, e em Moladá, e em Hazar-Sual,',
        'E em Bila, e em Ezém, e em Tolade,',
        'E em Betuel, e em Hormá, e em Ziclague,',
        'E em Bete-Marcabote, e em Hazar-Susim, e em Bete-Biri, e em Saaraim; estas foram as suas cidades, até que Davi reinou.',
        'E foram as suas aldeias: Etã, Aim, Rimom, Toquém, e Asã, cinco cidades,',
        'E todas as suas aldeias, que estavam em redor destas cidades, até Baal. Estas foram as suas habitações e suas genealogias.',
        'Porém Mesobabe, e Janleque e Josa, filho de Amazias,',
        'E Joel, e Jeú, filho de Josibias, filho de Seraías, filho de Asiel,',
        'E Elioenai e Jaacobá, Jesoaías, Asaías, Adiel, Jesimiel, Benaias,',
        'E Ziza, filho de Sifi, filho de Alom, filho de Jedaías, filho de Sinri, filho de Semaías;',
        'Estes, registrados por seus nomes, foram príncipes nas suas famílias; e as famílias de seus pais se multiplicaram abundantemente.',
        'E chegaram até à entrada de Gedor, ao oriente do vale, a buscar pasto para os seus rebanhos.',
        'E acharam pasto fértil e terra espaçosa, e quieta, e descansada; porque os de Cão haviam habitado ali antes.',
        'Estes, pois, que estão descritos por seus nomes, vieram nos dias de Ezequias, rei de Judá, e derrubaram as tendas e habitações dos que se acharam ali, e as destruíram totalmente até o dia de hoje, e habitaram em seu lugar; porque ali havia pasto para os seus rebanhos.',
        'Também deles, dos filhos de Simeão, quinhentos homens foram às montanhas de Seir; levaram por cabeças a Pelatias, e a Nearias, e a Refaías, e a Uziel, filhos de Isi.',
        'E feriram o restante dos que escaparam dos amalequitas, e habitaram ali até o dia de hoje.',
      ],
      [
        'Quanto aos filhos de Rúben, o primogênito de Israel (pois ele era o primogênito; mas porque profanara a cama de seu pai, deu-se a sua primogenitura aos filhos de José, filho de Israel; de modo que não foi contado, na genealogia da primogenitura,',
        'Porque Judá foi poderoso entre seus irmãos, e dele veio o soberano; porém a primogenitura foi de José).',
        'Foram, pois, os filhos de Rúben, o primogênito de Israel: Enoque, Palu, Hezrom, e Carmi.',
        'Os filhos de Joel: Semaías, seu filho; Gogue, seu filho; Simei, seu filho;',
        'Mica, seu filho; Reaías, seu filho; Baal, seu filho;',
        'Beera, seu filho, o qual Tiglate-Pilneser, rei da Assíria, levou preso; este foi príncipe dos rubenitas.',
        'Quanto a seus irmãos pelas suas famílias, quando foram postos nas genealogias, segundo as suas descendências, tiveram por chefes Jeiel e Zacarias,',
        'E Bela, filho de Azaz, filho de Sema, filho de Joel, que habitou em Aroer, até Nebo e Baal-Meom,',
        'Também habitou do lado do oriente, até à entrada do deserto, desde o rio Eufrates; porque seu gado se tinha multiplicado na terra de Gileade.',
        'E nos dias de Saul fizeram guerra aos hagarenos, que caíram pela sua mão; e eles habitaram nas suas tendas defronte de todo o lado oriental de Gileade.',
        'E os filhos de Gade habitaram defronte deles, na terra de Basã, até Salcá.',
        'Joel foi chefe, e Safã o segundo; também Janai e Safate estavam em Basã.',
        'E seus irmãos, segundo as suas casas paternas, foram: Micael, Mesulão, Seba, Jorai, Jacã, Zia, e Éber, sete.',
        'Estes foram os filhos de Abiail filho de Huri, filho de Jaroa, filho de Gileade, filho de Micael, filho de Jesisai, filho de Jado, filho de Buz;',
        'Aí, filho de Abdiel, filho de Guni, foi chefe da casa de seus pais.',
        'E habitaram em Gileade, em Basã e nos lugares da sua jurisdição; como também em todos os arrabaldes de Sarom, até aos seus termos.',
        'Todos estes foram registrados, segundo as suas genealogias, nos dias de Jotão, rei de Judá, e nos dias de Jeroboão, rei de Israel.',
        'Dos filhos de Rúben, e dos gaditas, e da meia tribo de Manassés, homens muito valentes, que traziam escudo e espada, e entesavam o arco, e eram destros na guerra; houve quarenta e quatro mil e setecentos e sessenta, que saíam à peleja.',
        'E fizeram guerra aos hagarenos, como a Jetur, e a Nafis e a Nodabe.',
        'E foram ajudados contra eles, e os hagarenos e todos quantos estavam com eles foram entregues em sua mão; porque, na peleja, clamaram a Deus que lhes deu ouvidos, porquanto confiaram nele.',
        'E levaram preso o seu gado; seus camelos, cinqüenta mil, e duzentas e cinqüenta mil ovelhas, e dois mil jumentos, e cem mil homens.',
        'Porque muitos caíram feridos, porque de Deus era a peleja; e habitaram em seu lugar, até ao cativeiro.',
        'E os filhos da meia tribo de Manassés habitaram naquela terra; multiplicaram-se desde Basã até Baal-Hermom, e Senir, e o monte de Hermom.',
        'E estes foram cabeças de suas casas paternas, a saber: Hefer, Isi, Eliel, Azriel, Jeremias, Hodavias, e Jadiel, homens valentes, homens de nome, e chefes das casas de seus pais.',
        'Porém transgrediram contra o Deus de seus pais; e se prostituíram, seguindo os deuses dos povos da terra, os quais Deus destruíra de diante deles.',
        'Por isso o Deus de Israel suscitou o espírito de Pul, rei da Assíria, e o espírito de Tiglate-Pilneser, rei da Assíria, que os levaram presos, a saber: os rubenitas e gaditas, e a meia tribo de Manassés; e os trouxeram a Hala, e a Habor, e a Hara, e ao rio de Gozã, até ao dia de hoje.',
      ],
      [
        'Os filhos de Levi foram: Gérson, Coate e Merari,',
        'E os filhos de Coate: Anrão, e Izar, e Hebrom, e Uziel.',
        'E os filhos de Anrão: Arão, Moisés, e Miriã; e os filhos de Arão: Nadabe, Abiú, Eleazar, e Itamar.',
        'E Eleazar gerou a Finéias, e Finéias gerou a Abisua,',
        'E Abisua gerou a Buqui, e Buqui gerou a Uzi,',
        'E Uzi gerou a Zeraías, e Zeraías gerou a Meraiote.',
        'E Meraiote gerou a Amarias, e Amarias gerou a Aitube.',
        'E Aitube gerou a Zadoque, e Zadoque gerou a Aimaás,',
        'E Aimaás gerou a Azarias, e Azarias gerou a Joanã,',
        'E Joanã gerou a Azarias; e este é o que exerceu o sacerdócio na casa que Salomão tinha edificado em Jerusalém.',
        'E Azarias gerou a Amarias, e Amarias gerou a Aitube,',
        'E Aitube gerou a Zadoque, e Zadoque gerou a Salum,',
        'E Salum gerou a Hilquias, e Hilquias gerou a Azarias,',
        'E Azarias gerou a Seraías, e Seraías gerou a Jeozadaque,',
        'E Jeozadaque foi levado cativo quando o Senhor levou presos a Judá e a Jerusalém pela mão de Nabucodonosor.',
        'Os filhos de Levi foram, pois, Gérson, Coate, e Merari.',
        'E estes são os nomes dos filhos de Gérson: Libni e Simei.',
        'E os filhos de Coate: Anrão, Izar, Hebrom, e Uziel.',
        'Os filhos de Merari: Mali e Musi; estas são as famílias dos levitas, segundo seus pais.',
        'De Gérson: Libni, seu filho; Jaate, seu filho; Zima, seu filho;',
        'Joá, seu filho; Ido, seu filho; Zerá, seu filho; Jeatarai, seu filho.',
        'Os filhos de Coate foram: Aminadabe, seu filho; Coré, seu filho; Assir, seu filho;',
        'Elcana, seu filho; Ebiasafe, seu filho; Assir, seu filho;',
        'Taate, seu filho; Uriel, seu filho; Uzias, seu filho; e Saul, seu filho.',
        'E os filhos de Elcana: Amasai e Aimote.',
        'Quanto a Elcana: os filhos de Elcana foram Zofai, seu filho; e seu filho Naate.',
        'Seu filho Eliabe, seu filho Jeroão, seu filho Elcana.',
        'E os filhos de Samuel: Joel, seu primogênito, e o segundo Abias.',
        'Os filhos de Merari: Mali, seu filho Libni, seu filho Simei, seu filho Uzá.',
        'Seu filho Siméia, seu filho Hagias, seu filho Asaías.',
        'Estes são, pois, os que Davi constituiu para o ofício do canto na casa do Senhor, depois que a arca teve repouso.',
        'E ministravam diante do tabernáculo da tenda da congregação com cantares, até que Salomão edificou a casa do Senhor em Jerusalém; e estiveram, segundo o seu costume, no seu ministério.',
        'Estes são, pois, os que ali estavam com seus filhos: dos filhos dos coatitas, Hemã, o cantor, filho de Joel, filho de Samuel,',
        'Filho de Elcana, filho de Jeroão, filho de Eliel, filho de Toá,',
        'Filho de Zufe, filho de Elcana, filho de Maate, filho de Amasai,',
        'Filho de Elcana, filho de Joel, filho de Azarias, filho de Sofonias.',
        'Filho de Taate, filho de Assir, filho de Ebiasafe, filho de Coré,',
        'Filho de Isar, filho de Coate, filho de Levi, filho de Israel.',
        'E seu irmão Asafe estava à sua direita; e era Asafe filho de Berequias, filho de Siméia,',
        'Filho de Micael, filho de Baaséias, filho de Malquias,',
        'Filho de Etni, filho de Zerá, filho de Adaías,',
        'Filho de Etã, filho de Zima, filho de Simei,',
        'Filho de Jaate, filho de Gérson, filho de Levi.',
        'E seus irmãos, os filhos de Merari, estavam à esquerda; a saber: Etã, filho de Quisi, filho de Abdi, filho de Maluque,',
        'Filho de Hasabias, filho de Amazias, filho de Hilquias,',
        'Filho de Anzi, filho de Bani, filho de Semer,',
        'Filho de Mali, filho de Musi, filho de Merari, filho de Levi.',
        'E seus irmãos, os levitas, foram postos para todo o ministério do tabernáculo da casa de Deus.',
        'E Arão e seus filhos ofereceram sobre o altar do holocausto e sobre o altar do incenso, por todo o serviço do lugar santíssimo, e para fazer expiação por Israel, conforme tudo quanto Moisés, servo de Deus, tinha ordenado.',
        'E estes foram os filhos de Arão: seu filho Eleazar, seu filho Finéias, seu filho Abisua.',
        'Seu filho Buqui, seu filho Uzi, seu filho Seraías,',
        'Seu filho Meraiote, seu filho Amarias, seu filho Aitube,',
        'Seu filho Zadoque, seu filho Aimaás.',
        'E estas foram as suas habitações, segundo os seus acampamentos, nos seus termos, a saber: dos filhos de Arão, da família dos coatitas, porque a eles caiu a sorte.',
        'Deram-lhes, pois, a Hebrom, na terra de Judá, e os arrabaldes que a rodeiam.',
        'Porém o território da cidade e as suas aldeias deram a Calebe, filho de Jefoné.',
        'E aos filhos de Arão deram as cidades de refúgio: Hebrom e Libna e os seus arrabaldes, e Jatir e Estemoa e os seus arrabaldes.',
        'E Hilém, e os seus arrabaldes, Debir e os seus arrabaldes,',
        'E Asã e os seus arrabaldes, e Bete-Semes e os seus arrabaldes.',
        'E da tribo de Benjamim, Geba e os seus arrabaldes, Alemete e os seus arrabaldes, e Anatote e os seus arrabaldes; todas as suas cidades, pelas suas famílias, foram treze.',
        'Mas os filhos de Coate, que restaram da sua família, tiveram, por sorte, dez cidades da meia tribo de Manassés.',
        'E os filhos de Gérson, segundo as suas famílias, tiveram treze cidades da tribo de Issacar, e da tribo de Aser, e da tribo de Naftali e da tribo de Manassés, em Basã.',
        'Os filhos de Merari, segundo as suas famílias, tiveram, por sorte, doze cidades da tribo de Rúben, e da tribo de Gade, e da tribo de Zebulom.',
        'Assim os filhos de Israel deram aos levitas estas cidades e os seus arrabaldes.',
        'E deram-lhes por sorte estas cidades, da tribo dos filhos de Judá, da tribo dos filhos de Simeão, e da tribo dos filhos de Benjamim, às quais deram os seus nomes.',
        'E quanto ao mais das famílias dos filhos de Coate, se lhes deram, da tribo de Efraim as cidades dos seus termos.',
        'Porque lhes deram as cidades de refúgio, Siquém e os seus arrabaldes, nas montanhas de Efraim, como também Gezer e os seus arrabaldes,',
        'E Jocmeão e os seus arrabaldes, Bete-Horom e os seus arrabaldes,',
        'E Aijalom e os seus arrabaldes, Gate-Rimom e os seus arrabaldes.',
        'E da meia tribo de Manassés, Aner e os seus arrabaldes, e Bileã e os seus arrabaldes; estas cidades tiveram os que ficaram da família dos filhos de Coate.',
        'Os filhos de Gérson tiveram, da família da meia tribo de Manassés, Golã, em Basã, e os seus arrabaldes, e Astarote e os seus arrabaldes.',
        'E da tribo de Issacar, Quedes e os seus arrabaldes, e Daberate e os seus arrabaldes.',
        'E Ramote e os seus arrabaldes, e Aném e os seus arrabaldes.',
        'E da tribo de Aser, Masal e os seus arrabaldes, e Abdom e os seus arrabaldes,',
        'E Hucoque e os seus arrabaldes, e Reobe e os seus arrabaldes.',
        'E da tribo de Naftali, Quedes, em Galiléia, e os seus arrabaldes, Hamom e os seus arrabaldes e Quiriataim e os seus arrabaldes.',
        'Os que ficaram dos filhos de Merari tiveram, da tribo de Zebulom, a Rimom e os seus arrabaldes, a Tabor e os seus arrabaldes.',
        'E dalém do Jordão, na altura de Jericó, ao oriente do Jordão, da tribo de Rúben, a Bezer, no deserto, e os seus arrabaldes, e a Jaza e os seus arrabaldes,',
        'E a Quedemote e os seus arrabaldes, e a Mefaate e os seus arrabaldes.',
        'E da tribo de Gade, a Ramote, em Gileade, e os seus arrabaldes, e Maanaim e os seus arrabaldes,',
        'E a Hesbom e os seus arrabaldes, e a Jazer e os seus arrabaldes.',
      ],
      [
        'E quanto aos filhos de Issacar, foram: Tola, Pua, Jasube e Sinrom, quatro.',
        'E os filhos de Tola foram: Uzi, Refaías, Jeriel, Jamai, Ibsão e Semuel, chefes das casas de seus pais, descendentes de Tola, homens valentes nas suas gerações; o seu número, nos dias de Davi, foi de vinte e dois mil e seiscentos.',
        'E o filho de Uzi: Izraías; e os filhos de Izraías foram: Mical, Obadias, Joel e Issias; todos estes cinco chefes.',
        'E houve com eles nas suas gerações, segundo as suas casas paternas, em tropas de guerra, trinta e seis mil; porque tiveram muitas mulheres e filhos.',
        'E seus irmãos, em todas as famílias de Issacar, homens valentes, foram oitenta e sete mil, todos contados pelas suas genealogias.',
        'Os filhos de Benjamim foram: Belá, e Bequer, e Jediael, três.',
        'E os filhos de Belá: Esbom, e Uzi, e Uziel, e Jerimote, e Iri, cinco chefes da casa dos pais, homens valentes que foram contados pelas suas genealogias, vinte e dois mil e trinta e quatro.',
        'E os filhos de Bequer: Zemira, Joás, Eliezer, Elioenai, Onri, Jerimote, Abias, Anatote, e Alemete; todos estes foram filhos de Bequer.',
        'E foram contados pelas suas genealogias, segundo as suas gerações, e chefes das casas de seus pais, homens valentes, vinte mil e duzentos.',
        'E foi o filho de Jediael, Bilã; e os filhos de Bilã foram Jeús, Benjamim, Eúde, Quenaaná, Zetã, Társis e Aisaar.',
        'Todos estes filhos de Jediael foram chefes das famílias dos pais, homens valentes, dezessete mil e duzentos, que saíam no exército à peleja.',
        'E Supim, e Hupim, filhos de Ir, e Husim, dos filhos de Aer.',
        'Os filhos de Naftali: Jaziel, e Guni, e Jezer, e Salum, filhos de Bila.',
        'Os filhos de Manassés: Asriel, que a mulher de Gileade concebeu (porém a sua concubina, a síria, concebeu a Maquir, pai de Gileade;',
        'E Maquir tomou a irmã de Hupim e Supim por mulher, e era o seu nome Maaca), e foi o nome do segundo Zelofeade; e Zelofeade teve filhas.',
        'E Maaca, mulher de Maquir, deu à luz um filho, e chamou-o Perez; e o nome de seu irmão foi Seres; e foram seus filhos Ulão e Raquém.',
        'E o filho de Ulão, Bedã; estes foram os filhos de Gileade, filho de Maquir, filho de Manassés.',
        'E quanto à sua irmã Hamolequete, teve a Is-Hode, a Abiezer, e a Maalá.',
        'E foram os filhos de Semida: Aiã, Siquém, Liqui, e Anião.',
        'E os filhos de Efraim: Sutela, e seu filho Berede, e seu filho Taate, e seu filho Elada e seu filho Taate.',
        'E seu filho Zabade, e seu filho Sutela, e Ezer, e Elade; e os homens de Gate, naturais da terra, os mataram, porque desceram para tomar os seus gados.',
        'Por isso Efraim, seu pai, por muitos dias os chorou; e vieram seus irmãos para o consolar.',
        'Depois coabitou com sua mulher, e ela concebeu, e teve um filho; e chamou-o Berias; porque ia mal na sua casa.',
        'E sua filha foi Seerá, que edificou a Bete-Horom, a baixa e a alta, como também a Uzém-Seerá.',
        'E foi seu filho Refa, e Resefe, de quem foi filho Tela, de quem foi filho Taã,',
        'De quem foi filho Ladã, de quem foi filho Amiúde, de quem foi filho Elisama,',
        'De quem foi filho Num, de quem foi filho Josué.',
        'E foi a sua possessão e habitação Betel e os lugares da sua jurisdição; e ao oriente Naarã, e ao ocidente Gezer e os lugares da sua jurisdição, e Siquém e os lugares da sua jurisdição, até Gaza e os lugares da sua jurisdição;',
        'E do lado dos filhos de Manassés, Bete-Seã e os lugares da sua jurisdição, Taanaque e os lugares da sua jurisdição, Megido e os lugares da sua jurisdição, Dor e os lugares da sua jurisdição; nestas habitaram os filhos de José, filho de Israel.',
        'Os filhos de Aser foram: Imná, Isvá, Isvi, Berias, e Sera, irmã deles.',
        'E os filhos de Berias: Héber e Malquiel; este foi o pai de Birzavite.',
        'E Héber gerou a Jaflete, e a Somer, e a Hotão, e a Suá, irmã deles.',
        'E foram os filhos de Jaflete: Pasaque, e Bimal e Asvate; estes foram os filhos de Jaflete.',
        'E os filhos de Semer: Ai, Roga, Jeubá, e Arã.',
        'E os filhos de seu irmão Helém: Zofa, e Imna, e Seles, e Amal.',
        'Os filhos de Zofa: Suá, e Harnefer, e Sual, e Beri, e Inra,',
        'Bezer, Hode, Samá, Silsa, Itrã, e Beera.',
        'E os filhos de Jeter: Jefoné, Pispa e Ara.',
        'E os filhos de Ula: Ará e Haniel e Rizia.',
        'Todos estes foram filhos de Aser, chefes das casas paternas, homens escolhidos e valentes, chefes dos príncipes, e contados nas suas genealogias, no exército para a guerra; foi seu número de vinte e seis mil homens.',
      ],
      [
        'E Benjamim gerou a Belá, seu primogênito, a Asbel o segundo, e a Aará o terceiro,',
        'A Noá o quarto, e a Rafa o quinto.',
        'E Belá teve estes filhos: Adar, Gera, Abiúde,',
        'Abisua, Naamã, Aoá,',
        'Gera, Sefufá e Hurão.',
        'E estes foram os filhos de Eúde; que foram chefes dos pais dos moradores de Geba, e os levaram cativos a Manaate;',
        'E Naamã, e Aías e Gera; este os transportou, e gerou a Uzá e a Aiúde.',
        'E Saaraim (depois de os enviar), na terra de Moabe, gerou filhos de Husim e Baara, suas mulheres.',
        'E de Hodes, sua mulher, gerou a Jobabe, a Zíbia, a Mesa, a Malcã,',
        'A Jeuz, a Saquias e a Mirma; estes foram seus filhos, chefes dos pais.',
        'E de Husim gerou a Abitube e a Elpaal.',
        'E foram os filhos de Elpaal: Éber, Misã e Semede; este edificou a Ono e a Lode e os lugares da sua jurisdição.',
        'E Berias e Sema foram cabeças dos pais dos moradores de Aijalom; estes afugentaram os moradores de Gate.',
        'E Aiô, Sasaque, Jerimote,',
        'Zebadias, Arade, Eder,',
        'Micael, Ispa e Joa foram filhos de Berias.',
        'Zebadias, Mesulão, Hizque, Héber,',
        'Ismerai, Izlias e Jobabe, filhos de Elpaal.',
        'Jaquim, Zicri, Zabdi,',
        'Elienai, Ziletai, Eliel,',
        'Adaías, Beraías e Sinrate, filhos de Simei.',
        'E Ispã, Éber, Eliel,',
        'Abdom, Zicri, Hanã,',
        'Hananias, Elão, Antotias,',
        'E Ifdéias, e Penuel, filhos de Sasaque;',
        'E Sanserai, e Searias, e Atalias,',
        'E Jaaresias, e Elias e Zicri, filhos de Jeroão.',
        'Estes foram cabeças dos pais, segundo as suas gerações, chefes, e habitaram em Jerusalém.',
        'E em Gibeão habitou o pai de Gibeão; e era o nome de sua mulher Maaca;',
        'E seu filho primogênito, Abdom; depois Zur, e Quis, Baal, e Nadabe,',
        'E Gedor, Aiô, e Zequer,',
        'E Miclote gerou a Siméia; e também estes, defronte de seus irmãos, habitaram em Jerusalém com eles.',
        'E Ner gerou a Quis, e Quis gerou a Saul; e Saul gerou a Jônatas, a Malquisua, a Abinadabe, e a Esbaal.',
        'E o filho de Jônatas foi Meribe-Baal; e Meribe-Baal gerou a Mica.',
        'E os filhos de Mica foram: Pitom, Meleque, Tareá, e Acaz.',
        'E Acaz gerou a Jeoada; e Jeoada gerou a Alemete, e a Azmavete, e a Zinri; e Zinri gerou a Moza,',
        'E Moza gerou a Bineá, cujo filho foi Rafa, de quem foi filho Eleasá, cujo filho foi Azel.',
        'E teve Azel seis filhos, e estes foram os seus nomes: Azricão, Bocru, Ismael, Searias, Obadias, e Hanã; todos estes foram filhos de Azel.',
        'E os filhos de Ezeque, seu irmão: Ulão, seu primogênito, Jeús o segundo e Elifelete o terceiro.',
        'E foram os filhos de Ulão homens heróis, valentes, e flecheiros destros; e tiveram muitos filhos, e filhos de filhos, cento e cinqüenta; todos estes foram dos filhos de Benjamim.',
      ],
      [
        'E todo o Israel foi contado por genealogias, que estão escritas no livro dos reis de Israel; e os de Judá foram transportados a Babilônia, por causa da sua transgressão.',
        'E os primeiros habitantes, que moravam na sua possessão e nas suas cidades, foram os israelitas, os sacerdotes, os levitas, e os netineus.',
        'Porém alguns dos filhos de Judá, e dos filhos de Benjamim, e dos filhos de Efraim e Manassés, habitaram em Jerusalém:',
        'Utai, filho de Amiúde, filho de Onri, filho de Inri, filho de Bani, dos filhos de Perez, filho de Judá;',
        'E dos silonitas: Asaías o primogênito, e seus filhos;',
        'E dos filhos de Zerá: Jeuel, e seus irmãos, seiscentos e noventa;',
        'E dos filhos de Benjamim: Salu, filho de Mesulão, filho de Hodavias, filho de Hassenua,',
        'E Ibnéias, filho de Jeroão, e Elá, filho de Uzi, filho de Micri, e Mesulão, filho de Sefatias, filho de Reuel, filho de Ibnijas;',
        'E seus irmãos, segundo as suas gerações, novecentos e cinqüenta e seis; todos estes homens foram chefes dos pais nas casas de seus pais.',
        'E dos sacerdotes: Jedaías, e Jeoiaribe, e Jaquim,',
        'E Azarias, filho de Hilquias, filho de Mesulão, filho de Zadoque, filho de Meraiote, filho de Aitube, maioral da casa de Deus;',
        'Adaías, filho de Jeroão, filho de Pasur, filho de Malquias, e Masai, filho de Adiel, filho de Jazera, filho de Mesulão, filho de Mesilemite, filho de Imer;',
        'Como também seus irmãos, cabeças nas casas de seus pais, mil, setecentos e sessenta, homens valentes para a obra do ministério da casa de Deus.',
        'E dos levitas: Semaías, filho de Hassube, filho de Azricão, filho de Hasabias, dos filhos de Merari;',
        'E Baquebacar, Heres e Galal; e Matanias, filho de Mica, filho de Zicri, filho de Asafe;',
        'E Obadias, filho de Semaías, filho de Galal, filho de Jedutum; e Berequias, filho de Asa, filho de Elcana, morador das aldeias dos netofatitas.',
        'E foram porteiros: Salum, Acube, Talmom, Aimã, e seus irmãos, cujo chefe era Salum.',
        'E até aquele tempo estavam de guarda à porta do rei, do lado do oriente; estes foram os porteiros dos arraiais dos filhos de Levi.',
        'E Salum, filho de Coré, filho de Ebiasafe, filho de Corá, e seus irmãos da casa de seu pai, os coraítas, tinham cargo da obra do ministério, e eram guardas das portas do tabernáculo, como seus pais foram responsáveis pelo arraial do Senhor, e guardas da entrada.',
        'Finéias, filho de Eleazar, antes era líder entre eles; e o Senhor era com ele.',
        'E Zacarias, filho de Meselemias, porteiro da entrada da tenda da congregação.',
        'Todos estes, escolhidos para serem guardas das portas, foram duzentos e doze; e foram estes, segundo as suas aldeias, postos em suas genealogias; e Davi e Samuel, o vidente, os constituíram nos seus respectivos cargos.',
        'Estavam, pois, eles, e seus filhos, às portas da casa do Senhor, na casa da tenda, junto aos guardas,',
        'Os porteiros estavam aos quatro lados; ao oriente, ao ocidente, ao norte, e ao sul.',
        'E seus irmãos, que estavam nas suas aldeias, deviam, de tempo em tempo, vir por sete dias para servirem com eles.',
        'Porque havia naquele ofício quatro porteiros principais que eram levitas, e tinham o encargo das câmaras e dos tesouros da casa de Deus.',
        'E de noite ficavam em redor da casa de Deus, cuja guarda lhes tinha sido confiada, e tinham o encargo de abri-la cada manhã.',
        'E alguns deles estavam encarregados dos utensílios do ministério, porque por conta os traziam e por conta os tiravam.',
        'Porque deles havia alguns que tinham o encargo dos objetos e de todos os utensílios do santuário; como também da flor de farinha, do vinho, do azeite, do incenso, e das especiarias.',
        'E alguns dos filhos dos sacerdotes eram os obreiros da confecção das especiarias.',
        'E Matitias, dentre os levitas, o primogênito de Salum, o coraíta, tinha o encargo da obra que se fazia em sertãs.',
        'E alguns dos seus irmãos, dos filhos dos coatitas, tinham o encargo de preparar os pães da proposição para todos os sábados.',
        'Destes foram também os cantores, chefes dos pais entre os levitas, habitando nas câmaras, isentos de serviços; porque de dia e de noite estava a seu cargo ocuparem-se naquela obra.',
        'Estes foram cabeças dos pais entre os levitas, chefes em suas gerações; estes habitaram em Jerusalém.',
        'Porém em Gibeão habitaram Jeiel, pai de Gibeão (e era o nome de sua mulher Maaca).',
        'E seu filho primogênito Abdom; depois Zur, Quis, Baal, Ner e Nadabe,',
        'E Gedor, Aiô, Zacarias e Miclote.',
        'Miclote gerou a Simeão; e também estes habitaram em Jerusalém, defronte de seus irmãos, com eles.',
        'E Ner gerou a Quis; e Quis gerou a Saul, Saul gerou a Jônatas, a Malquisua, a Abinadabe e a Esbaal.',
        'E o filho de Jônatas foi Meribe-Baal, e Meribe-Baal gerou a Mica.',
        'E os filhos de Mica foram: Pitom, Meleque e Taréia.',
        'E Acaz gerou a Jaerá, e Jaerá gerou a Alemete, a Azmavete e a Zinri; e Zinri gerou a Moza.',
        'E Moza gerou a Bineá, cujo filho foi Refaías, de quem foi filho Eleasá, cujo filho foi Azel.',
        'E teve Azel seis filhos, e estes foram os seus nomes: Azricão, Bocru, Ismael, Searias, Obadias e Hanã; estes foram os filhos de Azel.',
      ],
      [
        'E os filisteus pelejaram com Israel; e os homens de Israel fugiram de diante dos filisteus, e caíram mortos nas montanhas de Gilboa.',
        'E os filisteus perseguiram a Saul e aos seus filhos e mataram a Jônatas, a Abinadabe e a Malquisua, filhos de Saul.',
        'E a peleja se agravou contra Saul, e os flecheiros o alcançaram; e temeu muito aos flecheiros.',
        'Então disse Saul ao seu escudeiro: Arranca a tua espada, e atravessa-me com ela; para que porventura não venham estes incircuncisos e escarneçam de mim. Porém o seu escudeiro não quis, porque temia muito; então tomou Saul a espada, e se lançou sobre ela.',
        'Vendo, pois, o seu escudeiro que Saul estava morto, também ele se lançou sobre a espada e morreu.',
        'Assim morreram Saul e seus três filhos; e toda a sua casa morreu juntamente.',
        'E, vendo todos os homens de Israel, que estavam no vale, que haviam fugido, e que Saul e seus filhos eram mortos, deixaram as suas cidades, e fugiram; então vieram os filisteus, e habitaram nelas.',
        'E sucedeu que, no dia seguinte, vindo os filisteus a despojar os mortos, acharam a Saul e a seus filhos estirados nas montanhas de Gilboa.',
        'E o despojaram, e tomaram a sua cabeça e as suas armas, e as enviaram pela terra dos filisteus em redor, para o anunciarem a seus ídolos e ao povo.',
        'E puseram as suas armas na casa do seu deus, e a sua cabeça afixaram na casa de Dagom.',
        'Ouvindo, pois, toda a Jabes de Gileade tudo quanto os filisteus fizeram a Saul,',
        'Então todos os homens valorosos se levantaram, e tomaram o corpo de Saul, e os corpos de seus filhos, e os trouxeram a Jabes; e sepultaram os seus ossos debaixo de um carvalho em Jabes, e jejuaram sete dias.',
        'Assim morreu Saul por causa da transgressão que cometeu contra o Senhor, por causa da palavra do Senhor, a qual não havia guardado; e também porque buscou a adivinhadora para a consultar.',
        'E não buscou ao Senhor, que por isso o matou, e transferiu o reino a Davi, filho de Jessé.',
      ],
      [
        'Então todo o Israel se ajuntou a Davi em Hebrom, dizendo: Eis que somos teus ossos e tua carne.',
        'E também outrora, sendo Saul ainda rei, eras tu o que fazias sair e entrar a Israel; também o Senhor teu Deus te disse: Tu apascentarás o meu povo Israel, e tu serás chefe sobre o meu povo Israel.',
        'Também vieram todos os anciãos de Israel ao rei, a Hebrom, e Davi fez com eles aliança em Hebrom, perante o Senhor; e ungiram a Davi rei sobre Israel, conforme a palavra do Senhor pelo ministério de Samuel.',
        'E partiu Davi e todo o Israel para Jerusalém, que é Jebus; porque ali estavam os jebuseus, habitantes da terra.',
        'E disseram os habitantes de Jebus a Davi: Tu não entrarás aqui. Porém Davi ganhou a fortaleza de Sião, que é a cidade de Davi.',
        'Porque disse Davi: Qualquer que primeiro ferir os jebuseus será chefe e capitão. Então Joabe, filho de Zeruia, subiu primeiro a ela; pelo que foi feito chefe.',
        'E Davi habitou na fortaleza; por isso foi chamada a cidade de Davi.',
        'E edificou a cidade ao redor, desde Milo até ao circuito; e Joabe renovou o restante da cidade.',
        'E Davi tornava-se cada vez mais forte; porque o Senhor dos Exércitos era com ele.',
        'E estes foram os chefes dos poderosos que Davi tinha, e que o apoiaram fortemente no seu reino, com todo o Israel, para o fazerem rei, conforme a palavra do Senhor, no tocante a Israel.',
        'E este é o número dos poderosos que Davi tinha: Jasobeão, hacmonita, chefe dos capitães, o qual, brandindo a sua lança contra trezentos, de uma vez os matou.',
        'E, depois dele Eleazar, filho de Dodó, o aoíta; ele estava entre os três poderosos.',
        'Este esteve com Davi em Pas-Damim, quando os filisteus ali se ajuntaram à peleja, onde havia um pedaço de campo cheio de cevada; e o povo fugiu de diante dos filisteus.',
        'E puseram-se no meio daquele campo, e o defenderam, e feriram os filisteus; e o Senhor efetuou um grande livramento.',
        'E três dos trinta capitães desceram à penha, a ter com Davi, na caverna de Adulão; e o exército dos filisteus estava acampado no vale de Refaim.',
        'E Davi estava então no lugar forte; e o alojamento dos filisteus estava então em Belém.',
        'E desejou Davi, e disse: Quem me dera beber da água do poço de Belém, que está junto à porta!',
        'Então aqueles três romperam pelo acampamento dos filisteus, e tiraram água do poço de Belém, que estava junto à porta, e tomaram dela e a trouxeram a Davi; porém Davi não a quis beber, mas a derramou ao Senhor,',
        'E disse: Nunca meu Deus permita que faça tal! Beberia eu o sangue destes homens com as suas vidas? Pois com perigo das suas vidas a trouxeram. E ele não a quis beber. Isto fizeram aqueles três homens.',
        'E também Abisai, irmão de Joabe, era chefe de três, o qual, brandindo a sua lança contra trezentos, os feriu; e teve nome entre os três.',
        'Ele foi o mais ilustre dos três, pelo que foi capitão deles; porém não igualou aos primeiros três.',
        'Também Benaia, filho de Joiada, filho de um homem poderoso de Cabzeel, grande em obras; ele feriu a dois heróis de Moabe; e também desceu, e feriu um leão dentro de uma cova, no tempo da neve.',
        'Também feriu ele a um homem egípcio, homem de grande altura, de cinco côvados; e trazia o egípcio uma lança na mão, como o órgão do tecelão; mas Benaia desceu contra ele com uma vara, e arrancou a lança da mão do egípcio, e com ela o matou.',
        'Estas coisas fez Benaia, filho de Joiada; pelo que teve nome entre aqueles três poderosos.',
        'Eis que dos trinta foi ele o mais ilustre; contudo não chegou aos primeiros três; e Davi o pôs sobre os da sua guarda.',
        'E foram os poderosos dos exércitos: Asael, irmão de Joabe, El-Hanã, filho de Dodó, de Belém;',
        'Samote, o harorita; Helez, o pelonita;',
        'Ira, filho de Iques, o tecoíta; Abiezer, o anatotita;',
        'Sibecai, o husatita; Ilai, o aoíta;',
        'Maarai, o netofatita; Helede, filho de Baaná, o netofatita;',
        'Itai, filho de Ribai, de Gileade, dos filhos de Benjamim; Benaia, o piratonita;',
        'Hurai, do ribeiro de Gaás; Abiel, o arbatita;',
        'Azmavete, o baarumita; Eliabe, o saalbonita;',
        'Dos filhos de Hasém, o gizonita: Jônatas, filho de Sage, o hararita;',
        'Aião, filho de Sacar, o hararita; Elifal, filho de Ur;',
        'Hefer, o mequeratita; Aías, o pelonita;',
        'Hezro, o carmelita; Naarai, filho de Ezbai;',
        'Joel, irmão de Natã; Mibar, filho de Hagri;',
        'Zeleque, o amonita; Naarai, o beerotita, escudeiro de Joabe, filho de Zeruia;',
        'Ira, o itrita; Garebe, o itrita;',
        'Urias, o heteu; Zabade, filho de Alai;',
        'Adina, filho de Siza, o rubenita, capitão dos rubenitas, e com ele trinta;',
        'Hanã, filho de Maaca; e Josafá, o mitatita;',
        'Uzias, o asteratita; Sama e Jeiel, filhos de Hotão, o aroerita;',
        'Jediael, filho de Sinri; e Joa, seu irmão, o tizita;',
        'Eliel, o maavita; e Jeribai e Josavias, filhos de Elnaão; e Itma, o moabita;',
        'Eliel, Obede, e Jaasiel, o mesobaíta.',
      ],
      [
        'Estes, porém, são os que vieram a Davi, a Ziclague, estando ele ainda escondido, por causa de Saul, filho de Quis; e eram dos valentes que o ajudaram na guerra.',
        'Estavam armados de arco, e usavam tanto da mão direita como da esquerda em atirar pedras e em atirar flechas com o arco; eram dos irmãos de Saul, benjamitas.',
        'Aiezer, o chefe, e Joás, filho de Semaa, o gibeatita, e Jeziel e Pelete, filhos de Azmavete; e Beraca, e Jeú, o anatotita,',
        'E Ismaías, o gibeonita, valente entre os trinta, líder deles; e Jeremias, e Jaaziel, e Joanã, e Jozabade, o gederatita,',
        'Eluzai, e Jerimote, e Bealias, e Samarias, e Sefatias, o harufita,',
        'Elcana, Issias, Azarel, Joezer, e Jasobeão, os coraítas,',
        'E Joela, e Zabadias, filhos de Jeroão de Gedor.',
        'E dos gaditas se desertaram para Davi, ao lugar forte no deserto, valentes, homens de guerra para pelejar, armados com escudo e lança; e seus rostos eram como rostos de leões, e ligeiros como corças sobre os montes:',
        'Ezer, o primeiro; Obadias, o segundo; Eliabe, o terceiro;',
        'Mismana, o quarto; Jeremias, o quinto;',
        'Atai, o sexto; Eliel, o sétimo;',
        'Joanã, o oitavo; Elzabade, o nono;',
        'Jeremias, o décimo; Macbanai, o undécimo;',
        'Estes, dos filhos de Gade, foram os capitães do exército; o menor tinha o encargo de cem homens e o maior de mil.',
        'Estes são os que passaram o Jordão no primeiro mês, quando ele transbordava por todas as suas ribanceiras, e fizeram fugir a todos os dos vales ao oriente e ao ocidente.',
        'Também alguns dos filhos de Benjamim e de Judá vieram a Davi, ao lugar forte.',
        'E Davi lhes saiu ao encontro, e lhes falou, dizendo: Se vós vindes a mim pacificamente e para me ajudar, o meu coração se unirá convosco; porém, se é para me entregar aos meus inimigos, sem que haja deslealdade nas minhas mãos, o Deus de nossos pais o veja e o repreenda.',
        'Então veio o espírito sobre Amasai, chefe de trinta, e disse: Nós somos teus, ó Davi, e contigo estamos, ó filho de Jessé! Paz, paz contigo, e paz com quem te ajuda, pois que teu Deus te ajuda. E Davi os recebeu, e os fez capitães das tropas.',
        'Também de Manassés alguns passaram para Davi, quando veio com os filisteus para a batalha contra Saul; todavia Davi não os ajudou, porque os príncipes dos filisteus, tendo feito conselho, o despediram, dizendo: À custa de nossas cabeças passará a Saul, seu senhor.',
        'Voltando ele, pois, a Ziclague, passaram-se para ele, de Manassés, Adna, Jozabade, Jediael, Micael, Jozabade, Eliú, e Ziletai, capitães de milhares dos de Manassés.',
        'E estes ajudaram a Davi contra aquela tropa, porque todos eles eram heróis poderosos, e foram capitães no exército.',
        'Porque naquele tempo, dia após dia, vinham a Davi para o ajudar, até que se fez um grande exército, como o exército de Deus.',
        'Ora este é o número dos chefes armados para a peleja, que vieram a Davi em Hebrom, para transferir a ele o reino de Saul, conforme a palavra do Senhor.',
        'Dos filhos de Judá, que traziam escudo e lança, seis mil e oitocentos, armados para a peleja;',
        'Dos filhos de Simeão, homens poderosos para pelejar, sete mil e cem;',
        'Dos filhos de Levi, quatro mil e seiscentos.',
        'Joiada, que era o líder dos de Arão, e com ele três mil e setecentos.',
        'E Zadoque, sendo ainda jovem, homem poderoso, com vinte e dois capitães da família de seu pai;',
        'E dos filhos de Benjamim, irmãos de Saul, três mil; porque até então havia ainda muitos deles que eram pela casa de Saul.',
        'E dos filhos de Efraim, vinte mil e oitocentos homens poderosos, homens de nome nas casas de seus pais.',
        'E da meia tribo de Manassés, dezoito mil, que foram apontados pelos seus nomes para virem fazer rei a Davi.',
        'E dos filhos de Issacar, duzentos de seus chefes, destros na ciência dos tempos, para saberem o que Israel devia fazer, e todos os seus irmãos seguiam suas ordens.',
        'De Zebulom, dos que podiam sair no exército, cinqüenta mil ordenados para a peleja com todas as armas de guerra; como também destros para ordenarem uma batalha, e não eram de coração dobre.',
        'E de Naftali, mil capitães, e com eles trinta e sete mil com escudo e lança.',
        'E dos danitas, ordenados para a peleja, vinte e oito mil e seiscentos.',
        'E de Aser, dos que podiam sair no exército, para ordenarem a batalha, quarenta mil.',
        'E do outro lado do Jordão, dos rubenitas e gaditas, e da meia tribo de Manassés, com toda a sorte de instrumentos de guerra para pelejar, cento e vinte mil.',
        'Todos estes homens de guerra, postos em ordem de batalha, vieram a Hebrom, com corações decididos, para constituírem a Davi rei sobre todo o Israel; e também todo o restante de Israel tinha o mesmo coração para constituir a Davi rei.',
        'E estiveram ali com Davi três dias, comendo e bebendo; porque seus irmãos lhes tinham preparado as provisões.',
        'E também seus vizinhos de mais perto, até Issacar, e Zebulom, e Naftali, trouxeram, sobre jumentos, e sobre camelos, e sobre mulos, e sobre bois, pão, provisões de farinha, pastas de figos e cachos de passas, e vinho, e azeite, e bois, gado miúdo em abundância; porque havia alegria em Israel.',
      ],
      [
        'E Davi tomou conselho com os capitães dos milhares, e das centenas, e com todos os líderes.',
        'E disse Davi a toda a congregação de Israel: Se bem vos parece, e se isto vem do Senhor nosso Deus, enviemos depressa mensageiros a todos os nossos outros irmãos em todas as terras de Israel, e aos sacerdotes, e aos levitas nas suas cidades e nos seus arrabaldes, para que se reúnam conosco;',
        'E tornemos a trazer para nós a arca do nosso Deus; porque não a buscamos nos dias de Saul.',
        'Então disse toda a congregação que se fizesse assim; porque este negócio pareceu reto aos olhos de todo o povo.',
        'Convocou, pois, Davi a todo o Israel desde Sior do Egito até chegar a Hamate; para trazer a arca de Deus de Quiriate-Jearim.',
        'E então Davi com todo o Israel subiu a Baalá de Quiriate-Jearim, que está em Judá, para fazer subir dali a arca de Deus, o Senhor que habita entre os querubins, sobre a qual é invocado o seu nome.',
        'E levaram a arca de Deus, da casa de Abinadabe, sobre um carro novo; e Uzá e Aiô guiavam o carro.',
        'E Davi e todo o Israel, alegraram-se perante Deus com todas as suas forças; com cânticos, e com harpas, e com saltérios, e com tamborins, e com címbalos, e com trombetas.',
        'E, chegando à eira de Quidom, estendeu Uzá a sua mão, para segurar a arca, porque os bois tropeçavam.',
        'Então se acendeu a ira do Senhor contra Uzá, e o feriu, por ter estendido a sua mão à arca; e morreu ali perante Deus.',
        'E Davi se encheu de tristeza porque o Senhor havia aberto brecha em Uzá; pelo que chamou aquele lugar Perez-Uzá, até ao dia de hoje.',
        'E aquele dia temeu Davi a Deus, dizendo: Como trarei a mim a arca de Deus?',
        'Por isso Davi não trouxe a arca a si, à cidade de Davi; porém a fez levar à casa de Obede-Edom, o giteu.',
        'Assim ficou a arca de Deus com a família de Obede-Edom, três meses em sua casa; e o Senhor abençoou a casa de Obede-Edom, e tudo quanto tinha.',
      ],
      [
        'Então Hirão, rei de Tiro, mandou mensageiros a Davi, e madeira de cedro, e pedreiros, e carpinteiros, para lhe edificarem uma casa.',
        'E entendeu Davi que o Senhor o tinha confirmado rei sobre Israel; porque o seu reino tinha sido muito exaltado por amor do seu povo Israel.',
        'E Davi tomou ainda mais mulheres em Jerusalém; e gerou Davi ainda mais filhos e filhas.',
        'E estes são os nomes dos filhos que teve em Jerusalém: Samua, Sobabe, Natã, Salomão,',
        'E Ibar, Elisua, Elpelete,',
        'E Nogá, Nefegue, Jafia,',
        'E Elisama, Eliada, e Elifelete.',
        'Ouvindo, pois, os filisteus que Davi havia sido ungido rei sobre todo o Israel, todos os filisteus subiram em busca de Davi; o que ouvindo Davi, logo saiu contra eles.',
        'E vindo os filisteus, se estenderam pelo vale de Refaim.',
        'Então consultou Davi a Deus, dizendo: Subirei contra os filisteus, e nas minhas mãos os entregarás? E o Senhor lhe disse: Sobe, porque os entregarei nas tuas mãos.',
        'E, subindo a Baal-Perazim, Davi ali os feriu; e disse Davi: Por minha mão Deus derrotou a meus inimigos, como se rompem as águas. Pelo que chamaram aquele lugar, Baal-Perazim.',
        'E deixaram ali seus deuses; e ordenou Davi que se queimassem a fogo;',
        'Porém os filisteus tornaram, e se estenderam pelo vale.',
        'E tornou Davi a consultar a Deus; e disse-lhe Deus: Não subirás atrás deles; mas rodeia-os por detrás, e vem a eles por defronte das amoreiras;',
        'E há de ser que, ouvindo tu um ruído de marcha pelas copas das amoreiras, então sairás à peleja; porque Deus terá saído diante de ti, para ferir o exército dos filisteus.',
        'E fez Davi como Deus lhe ordenara; e feriram o exército dos filisteus desde Gibeom até Gezer.',
        'Assim se espalhou o nome de Davi por todas aquelas terras; e o Senhor pôs o temor dele sobre todas aquelas nações.',
      ],
      [
        'Davi também fez casa para si na cidade de Davi; e preparou um lugar para a arca de Deus, e armou-lhe uma tenda.',
        'Então disse Davi: Ninguém pode levar a arca de Deus, senão os levitas; porque o Senhor os escolheu, para levar a arca de Deus, e para o servirem eternamente.',
        'E Davi convocou a todo o Israel em Jerusalém, para fazer subir a arca do Senhor ao seu lugar, que lhe tinha preparado.',
        'E Davi reuniu os filhos de Arão e os levitas:',
        'Dos filhos de Coate: Uriel, o chefe, e de seus irmãos cento e vinte.',
        'Dos filhos de Merari: Asaías, o chefe, e de seus irmãos duzentos e vinte.',
        'Dos filhos de Gérson: Joel, o chefe, e de seus irmãos cento e trinta.',
        'Dos filhos de Elizafã: Semaías, o chefe, e de seus irmãos duzentos.',
        'Dos filhos de Hebrom: Eliel, o chefe, e de seus irmãos oitenta.',
        'Dos filhos de Uziel: Aminadabe, o chefe, e de seus irmãos cento e doze.',
        'E chamou Davi os sacerdotes Zadoque e Abiatar, e os levitas, Uriel, Asaías, Joel, Semaías, Eliel, e Aminadabe.',
        'E disse-lhes: Vós sois os chefes dos pais entre os levitas; santificai-vos, vós e vossos irmãos, para que façais subir a arca do Senhor Deus de Israel, ao lugar que lhe tenho preparado.',
        'Porquanto vós não a levastes na primeira vez, o Senhor nosso Deus fez rotura em nós, porque não o buscamos segundo a ordenança.',
        'Santificaram-se, pois, os sacerdotes e os levitas, para fazerem subir a arca do Senhor Deus de Israel.',
        'E os filhos dos levitas trouxeram a arca de Deus sobre os seus ombros, pelas varas que nela havia, como Moisés tinha ordenado conforme a palavra do Senhor.',
        'E disse Davi aos chefes dos levitas que constituíssem, de seus irmãos, cantores, para que com instrumentos musicais, com alaúdes, harpas e címbalos, se fizessem ouvir, levantando a voz com alegria.',
        'Designaram, pois, os levitas a Hemã, filho de Joel; e dos seus irmãos, Asafe, filho de Berequias; e dos filhos de Merari, seus irmãos, Etã, filho de Cusaías.',
        'E com eles a seus irmãos da segunda ordem: a Zacarias, Bene, Jaaziel, Semiramote, Jeiel, Uni, Eliabe, Benaia, Maaséias, Matitias, Elifeleu, Micnéias, Obede-Edom, e Jeiel, os porteiros.',
        'E os cantores, Hemã, Asafe e Etã, se faziam ouvir com címbalos de metal;',
        'E Zacarias, Aziel, Semiramote, Jeiel, Uni, Eliabe, Maaséias, e Benaia, com alaúdes, sobre Alamote:',
        'E Matitias, Elifeleu, Micnéias, Obede-Edom, Jeiel, e Azazias, com harpas, sobre Seminite, para sobressaírem.',
        'E Quenanias, chefe dos levitas, tinha o encargo de dirigir o canto; ensinava-os a entoá-lo, porque era entendido.',
        'E Berequias e Elcana eram porteiros da arca.',
        'E Sebanias, Jeosafá, Netanel, Amasai, Zacarias, Benaia, e Eliezer, os sacerdotes, tocavam as trombetas perante a arca de Deus; e Obede-Edom e Jeías eram porteiros da arca.',
        'Sucedeu, pois, que Davi e os anciãos de Israel, e os capitães dos milhares, foram, com alegria, para fazer subir a arca da aliança do Senhor, da casa de Obede-Edom.',
        'E sucedeu que, ajudando Deus os levitas que levavam a arca da aliança do Senhor, sacrificaram sete novilhos e sete carneiros.',
        'E Davi ia vestido de um manto de linho fino, como também todos os levitas que levavam a arca, e os cantores, e Quenanias, mestre dos cantores; também Davi levava sobre si um éfode de linho,',
        'E todo o Israel fez subir a arca da aliança do Senhor, com júbilo, e ao som de buzinas, e de trombetas, e de címbalos, fazendo ressoar alaúdes e harpas.',
        'E sucedeu que, chegando a arca da aliança do Senhor à cidade de Davi, Mical, a filha de Saul, olhou de uma janela, e, vendo a Davi dançar e tocar, o desprezou no seu coração.',
      ],
      [
        'Trouxeram, pois, a arca de Deus, e a puseram no meio da tenda que Davi lhe tinha armado; e ofereceram holocaustos e sacrifícios pacíficos perante Deus.',
        'E, acabando Davi de oferecer os holocaustos e sacrifícios pacíficos, abençoou o povo em nome do Senhor.',
        'E repartiu a todos em Israel, tanto a homens como a mulheres, a cada um, um pão, e um bom pedaço de carne, e um frasco de vinho.',
        'E pôs alguns dos levitas por ministros perante a arca do Senhor; isto para recordarem, e louvarem, e celebrarem ao Senhor Deus de Israel.',
        'Era Asafe, o chefe, e Zacarias o segundo depois dele; Jeiel, e Semiramote, e Jeiel, e Matitias, e Eliabe, e Benaia, e Obede-Edom, e Jeiel, com alaúdes e com harpas; e Asafe se fazia ouvir com címbalos;',
        'Também Benaia, e Jaaziel, os sacerdotes, continuamente tocavam trombetas, perante a arca da aliança de Deus.',
        'Então naquele mesmo dia Davi, em primeiro lugar, deu o seguinte salmo para que, pelo ministério de Asafe e de seus irmãos, louvassem ao Senhor;',
        'Louvai ao Senhor, invocai o seu nome, fazei conhecidas as suas obras entre os povos.',
        'Cantai-lhe, salmodiai-lhe, atentamente falai de todas as suas maravilhas.',
        'Gloriai-vos no seu santo nome; alegre-se o coração dos que buscam ao Senhor.',
        'Buscai ao Senhor e a sua força; buscai a sua face continuamente.',
        'Lembrai-vos das maravilhas que fez, de seus prodígios, e dos juízos da sua boca;',
        'Vós, semente de Israel, seus servos, vós, filhos de Jacó, seus escolhidos.',
        'Ele é o Senhor nosso Deus; os seus juízos estão em toda a terra.',
        'Lembrai-vos perpetuamente da sua aliança e da palavra que prescreveu para mil gerações;',
        'Da aliança que fez com Abraão, e do seu juramento a Isaque;',
        'O qual também a Jacó confirmou por estatuto, e a Israel por aliança eterna,',
        'Dizendo: A ti te darei a terra de Canaã, quinhão da vossa herança.',
        'Quando eram poucos homens em número, sim, mui poucos, e estrangeiros nela,',
        'Quando andavam de nação em nação, e de um reino para outro povo,',
        'A ninguém permitiu que os oprimisse, e por amor deles repreendeu reis, dizendo:',
        'Não toqueis os meus ungidos, e aos meus profetas não façais mal.',
        'Cantai ao Senhor em toda a terra; anunciai de dia em dia a sua salvação.',
        'Contai entre as nações a sua glória, entre todos os povos as suas maravilhas.',
        'Porque grande é o Senhor, e mui digno de louvor, e mais temível é do que todos os deuses.',
        'Porque todos os deuses dos povos são ídolos; porém o Senhor fez os céus.',
        'Louvor e majestade há diante dele, força e alegria no seu lugar.',
        'Tributai ao Senhor, ó famílias dos povos, tributai ao Senhor glória e força.',
        'Tributai ao Senhor a glória de seu nome; trazei presentes, e vinde perante ele; adorai ao Senhor na beleza da sua santidade.',
        'Trema perante ele, trema toda a terra; pois o mundo se firmará, para que não se abale.',
        'Alegrem-se os céus, e regozije-se a terra; e diga-se entre as nações: O Senhor reina.',
        'Brame o mar com a sua plenitude; exulte o campo com tudo o que nele há;',
        'Então jubilarão as árvores dos bosques perante o Senhor; porquanto vem julgar a terra.',
        'Louvai ao Senhor, porque é bom; pois a sua benignidade dura perpetuamente.',
        'E dizei: Salva-nos, ó Deus da nossa salvação, e ajunta-nos, e livra-nos das nações, para que louvemos o teu santo nome, e nos gloriemos no teu louvor.',
        'Bendito seja o Senhor Deus de Israel, de eternidade a eternidade. E todo o povo disse: Amém! E louvou ao Senhor.',
        'Então Davi deixou ali, diante da arca da aliança do Senhor, a Asafe e a seus irmãos, para ministrarem continuamente perante a arca, segundo se ordenara para cada dia.',
        'E mais a Obede-Edom, com seus irmãos, sessenta e oito; a este Obede-Edom, filho de Jedutum, e a Hosa, deixou por porteiros.',
        'E deixou a Zadoque, o sacerdote, e a seus irmãos, os sacerdotes, diante do tabernáculo do Senhor, no alto que está em Gibeom,',
        'Para oferecerem holocaustos ao Senhor continuamente, pela manhã e à tarde, sobre o altar dos holocaustos; e isto segundo tudo o que está escrito na lei do Senhor que tinha prescrito a Israel.',
        'E com eles a Hemã, e a Jedutum, e aos mais escolhidos, que foram apontados pelos seus nomes, para louvarem ao Senhor, porque a sua benignidade dura perpetuamente.',
        'Com eles, pois, estavam Hemã e Jedutum, com trombetas e címbalos, para os que haviam de tocar, e com outros instrumentos de música de Deus; porém os filhos de Jedutum estavam à porta.',
        'Então todo o povo se retirou, cada um para a sua casa; e voltou Davi, para abençoar a sua casa.',
      ],
      [
        'Sucedeu, pois, que, morando Davi já em sua casa, disse ao profeta Natã: Eis que moro em casa de cedro, mas a arca da aliança do SENHOR está debaixo de cortinas.',
        'Então Natã disse a Davi: Tudo quanto tens no teu coração faze, porque Deus é contigo.',
        'Mas sucedeu, na mesma noite, que a palavra de Deus veio a Natã, dizendo:',
        'Vai, e dize a Davi meu servo: Assim diz o Senhor: Tu não me edificarás uma casa para eu morar;',
        'Porque em casa nenhuma morei, desde o dia em que fiz subir a Israel até ao dia de hoje; mas fui de tenda em tenda, e de tabernáculo em tabernáculo.',
        'Por todas as partes por onde andei com todo o Israel, porventura falei alguma palavra a algum dos juízes de Israel, a quem ordenei que apascentasse o meu povo, dizendo: Por que não me edificais uma casa de cedro?',
        'Agora, pois, assim dirás a meu servo Davi: Assim diz o Senhor dos Exércitos: Eu te tirei do curral, de detrás das ovelhas, para que fosses chefe do meu povo Israel.',
        'E estive contigo por toda a parte, por onde foste, e de diante de ti exterminei todos os teus inimigos, e te fiz um nome como o nome dos grandes que estão na terra,',
        'E ordenarei um lugar para o meu povo Israel, e o plantarei, para que habite no seu lugar, e nunca mais seja removido de uma para outra parte; e nunca mais os filhos da perversidade o debilitarão como dantes,',
        'E desde os dias em que ordenei juízes sobre o meu povo Israel. Assim abaterei a todos os teus inimigos; também te faço saber que o Senhor te edificará uma casa.',
        'E há de ser que, quando forem cumpridos os teus dias, para ires a teus pais, suscitarei a tua descendência depois de ti, um dos teus filhos, e estabelecerei o seu reino.',
        'Este me edificará casa; e eu confirmarei o seu trono para sempre.',
        'Eu lhe serei por pai, e ele me será por filho; e a minha benignidade não retirarei dele, como a tirei daquele, que foi antes de ti.',
        'Mas o confirmarei na minha casa e no meu reino para sempre, e o seu trono será firme para sempre.',
        'Conforme todas estas palavras, e conforme toda esta visão, assim falou Natã a Davi.',
        'Então entrou o rei Davi, e ficou perante o Senhor; e disse: Quem sou eu, Senhor Deus? e qual é a minha casa, para que me tenhas trazido até aqui?',
        'E ainda isto, ó Deus, foi pouco aos teus olhos; pelo que falaste da casa de teu servo para tempos distantes; e trataste-me como a um homem ilustre, ó Senhor Deus.',
        'Que mais te dirá Davi, acerca da honra feita a teu servo? Porém tu conheces bem a teu servo.',
        'Ó Senhor, por amor de teu servo, e segundo o teu coração, fizeste toda esta grandeza, para fazer notória todas estas grandes coisas.',
        'Senhor, ninguém há como tu, e não há Deus fora de ti, segundo tudo quanto ouvimos com os nossos ouvidos.',
        'E quem há como o teu povo Israel, única gente na terra, a quem Deus foi resgatar para seu povo, fazendo-te nome com coisas grandes e temerosas, lançando as nações de diante do teu povo, que resgataste do Egito?',
        'E confirmaste o teu povo Israel para ser teu povo para sempre; e tu, Senhor, lhe foste por Deus.',
        'Agora, pois, Senhor, a palavra que falaste de teu servo, e acerca da sua casa, confirma-a para sempre; e faze como falaste.',
        'Confirme-se e engrandeça-se o teu nome para sempre, e diga-se: O Senhor dos Exércitos é o Deus de Israel, é Deus para Israel; e permaneça firme diante de ti a casa de Davi, teu servo.',
        'Porque tu, Deus meu, revelaste ao ouvido de teu servo que lhe edificarias casa; pelo que o teu servo achou confiança para orar em tua presença.',
        'Agora, pois, Senhor, tu és o mesmo Deus, e falaste este bem acerca de teu servo.',
        'Agora, pois, foste servido abençoar a casa de teu servo, para que permaneça para sempre diante de ti: porque tu, Senhor, a abençoaste, e ficará abençoada para sempre.',
      ],
      [
        'E depois disto aconteceu que Davi derrotou os filisteus, e os sujeitou; e tomou a Gate, e os lugares da sua jurisdição, da mão dos filisteus.',
        'Também derrotou os moabitas; e os moabitas ficaram por servos de Davi, pagando tributos.',
        'Também Davi derrotou a Hadar-Ezer, rei de Zobá, junto a Hamate, quando ele ia estabelecer o seu domínio sobre o rio Eufrates.',
        'E Davi lhe tomou mil carros, e sete mil cavaleiros, e vinte mil homens de pé; e Davi jarretou todos os cavalos dos carros; porém reservou deles para cem carros.',
        'E vieram os sírios de Damasco a socorrer a Hadar-Ezer, rei de Zobá; porém Davi feriu dos sírios vinte e dois mil homens.',
        'E Davi pôs guarnições na Síria de Damasco, e os sírios ficaram por servos de Davi, pagando-lhe tributo; e o Senhor guardava a Davi, por onde quer que ia.',
        'E Davi tomou os escudos de ouro, que tinham os servos de Hadar-Ezer, e os trouxe a Jerusalém.',
        'Também de Tibate, e de Cum, cidades de Hadar-Ezer, tomou Davi muitíssimo cobre, de que Salomão fez o mar de cobre, e as colunas, e os utensílios de cobre.',
        'E ouvindo Toí, rei de Hamate, que Davi destruíra todo o exército de Hadar-Ezer, rei de Zobá,',
        'Mandou seu filho Hadorão a Davi, para lhe perguntar como estava, e para o abençoar, por haver pelejado contra Hadar-Ezer, e por havê-lo ferido (porque Hadar-Ezer fazia guerra a Toí), enviando-lhe também toda a sorte de vasos de ouro, e de prata, e de cobre.',
        'Os quais Davi também consagrou ao Senhor, juntamente com a prata e ouro que trouxera de todas as demais nações: dos edomeus, e dos moabitas, e dos filhos de Amom, e dos filisteus, e dos amalequitas.',
        'Também Abisai, filho de Zeruia, feriu a dezoito mil edomeus no Vale do Sal.',
        'E pôs guarnições em Edom, e todos os edomeus ficaram por servos de Davi; e o Senhor guardava a Davi, por onde quer que ia.',
        'E Davi reinou sobre todo o Israel; e fazia juízo e justiça a todo o seu povo.',
        'E Joabe, filho de Zeruia, comandava o exército; Jeosafá, filho de Ailude, era cronista.',
        'E Zadoque, filho de Aitube, e Abimeleque, filho de Abiatar, eram sacerdotes; e Savsa escrivão.',
        'E Benaia, filho de Joiada, estava sobre os quereteus e peleteus; porém os filhos de Davi eram os primeiros junto ao rei.',
      ],
      [
        'E aconteceu, depois disto que Naás, rei dos filhos de Amom, morreu; e seu filho reinou em seu lugar.',
        'Então disse Davi: Usarei de benevolência com Hanum, filho de Naás, porque seu pai usou de benevolência comigo. Por isso Davi enviou mensageiros para o consolarem acerca de seu pai. E, chegando os servos de Davi à terra dos filhos de Amom, a Hanum, para o consolarem,',
        'Disseram os príncipes dos filhos de Amom a Hanum: Pensas porventura, que foi para honrar teu pai aos teus olhos, que Davi te mandou consoladores? Não vieram seus servos a ti, a esquadrinhar, e a transtornar, e a espiar a terra?',
        'Por isso Hanum tomou os servos de Davi, e raspou-os, e cortou-lhes as vestes no meio até à coxa da perna, e os despediu.',
        'E foram-se, e avisaram a Davi acerca daqueles homens; e enviou ele mensageiros a encontrá-los; porque aqueles homens estavam sobremaneira envergonhados. Disse, pois, o rei: Deixai-vos ficar em Jericó, até que vos torne a crescer a barba, e então voltai.',
        'Vendo, pois, os filhos de Amom que se tinham feito odiosos para com Davi, enviou Hanum, e os filhos de Amom, mil talentos de prata para alugarem para si carros e cavaleiros da Mesopotâmia, e da Síria de Maaca, e de Zobá.',
        'E alugaram para si trinta e dois mil carros, e o rei de Maaca e o seu povo, e eles vieram, e se acamparam diante de Medeba; também os filhos de Amom se ajuntaram das suas cidades, e vieram para a guerra.',
        'O que ouvindo Davi, enviou Joabe e todo o exército dos homens valentes.',
        'E, saindo os filhos de Amom, ordenaram a batalha à porta da cidade; porém os reis que vieram se puseram à parte no campo.',
        'E, vendo Joabe que a batalha estava preparada contra ele, pela frente e pela retaguarda, separou dentre os mais escolhidos de Israel, e os ordenou contra os sírios.',
        'E o resto do povo entregou na mão de Abisai, seu irmão; e puseram-se em ordem de batalha contra os filhos de Amom.',
        'E disse: Se os sírios forem mais fortes do que eu, tu virás socorrer-me; e, se os filhos de Amom forem mais fortes do que tu, então eu te socorrerei.',
        'Esforça-te, e esforcemo-nos pelo nosso povo, e pelas cidades do nosso Deus, e faça o Senhor o que parecer bem aos seus olhos.',
        'Então se chegou Joabe, e o povo que tinha consigo, diante dos sírios, para a batalha; e fugiram de diante dele.',
        'Vendo, pois, os filhos de Amom que os sírios fugiram, também eles fugiram de diante de Abisai, seu irmão, e entraram na cidade; e veio Joabe para Jerusalém.',
        'E, vendo os sírios que foram derrotados diante de Israel, enviaram mensageiros, e fizeram sair os sírios que habitavam do outro lado do rio; e Sofaque, capitão do exército de Hadar-Ezer, marchava diante deles.',
        'Do que avisado Davi, ajuntou a todo o Israel, e passou o Jordão, e foi ter com eles, e ordenou contra eles a batalha; e, tendo Davi ordenado a batalha contra os sírios, pelejaram contra ele.',
        'Porém os sírios fugiram de diante de Israel, e feriu Davi, dos sírios, os homens de sete mil carros, e quarenta mil homens de pé; e a Sofaque, capitão do exército, matou.',
        'Vendo, pois, os servos de Hadar-Ezer que tinham sido feridos diante de Israel, fizeram paz com Davi, e o serviram; e os sírios nunca mais quiseram socorrer os filhos de Amom.',
      ],
      [
        'Aconteceu que, no decurso de um ano, no tempo em que os reis costumam sair para a guerra, Joabe levou o exército, e destruiu a terra dos filhos de Amom, e veio, e cercou a Rabá; porém Davi ficou em Jerusalém; e Joabe feriu a Rabá, e a destruiu.',
        'E Davi tirou a coroa da cabeça do rei deles, e achou nela o peso de um talento de ouro, e havia nela pedras preciosas; e foi posta sobre a cabeça de Davi; e levou da cidade mui grande despojo.',
        'Também levou o povo que estava nela, e os fez trabalhar com a serra, e com talhadeiras de ferro e com machados; e assim fez Davi com todas as cidades dos filhos de Amom; então voltou Davi, com todo o povo, para Jerusalém.',
        'E, depois disto, aconteceu que, levantando-se guerra em Gezer, com os filisteus, então Sibecai, o husatita, feriu a Sipai, dos filhos do gigante; e ficaram subjugados.',
        'E tornou a haver guerra com os filisteus; e El-Hanã, filho de Jair, feriu a Lami, irmão de Golias, o giteu, cuja haste da lança era como órgão de tecelão.',
        'E houve ainda outra guerra em Gate; onde havia um homem de grande estatura, e tinha vinte e quatro dedos, seis em cada mão, e seis em cada pé, e que também era filho do gigante.',
        'E injuriou a Israel; porém Jônatas, filho de Simei, irmão de Davi, o feriu;',
        'Estes nasceram ao gigante em Gate; e caíram pela mão de Davi e pela mão dos seus servos.',
      ],
      [
        'Então Satanás se levantou contra Israel, e incitou Davi a numerar a Israel.',
        'E disse Davi a Joabe e aos maiorais do povo: Ide, numerai a Israel, desde Berseba até Dã; e trazei-me a conta para que saiba o número deles.',
        'Então disse Joabe: O SENHOR acrescente ao seu povo cem vezes tanto como é; porventura, ó rei meu senhor, não são todos servos de meu senhor? Por que procura isto o meu senhor? Porque seria isto causa de delito para com Israel.',
        'Porém a palavra do rei prevaleceu contra Joabe; por isso saiu Joabe, e passou por todo o Israel; então voltou para Jerusalém.',
        'E Joabe deu a Davi a soma do número do povo; e era todo o Israel um milhão e cem mil homens, dos que arrancavam da espada; e de Judá quatrocentos e setenta mil homens, dos que arrancavam da espada.',
        'Porém os de Levi e Benjamim não contou entre eles, porque a palavra do rei foi abominável a Joabe.',
        'E este negócio também pareceu mau aos olhos de Deus; por isso feriu a Israel.',
        'Então disse Davi a Deus: Gravemente pequei em fazer este negócio; porém agora sê servido tirar a iniqüidade de teu servo, porque procedi mui loucamente.',
        'Falou, pois, o Senhor a Gade, o vidente de Davi, dizendo:',
        'Vai, e fala a Davi, dizendo: Assim diz o Senhor: Três coisas te proponho; escolhe uma delas, para que eu ta faça.',
        'E Gade veio a Davi, e lhe disse: Assim diz o Senhor: Escolhe para ti,',
        'Ou três anos de fome, ou que três meses sejas consumido diante dos teus adversários, e a espada de teus inimigos te alcance, ou que três dias a espada do Senhor, isto é, a peste na terra, e o anjo do Senhor destrua todos os termos de Israel; vê, pois, agora, que resposta hei de levar a quem me enviou.',
        'Então disse Davi a Gade: Estou em grande angústia; caia eu, pois, nas mãos do Senhor, porque são muitíssimas as suas misericórdias; mas que eu não caia nas mãos dos homens.',
        'Mandou, pois, o Senhor a peste a Israel; e caíram de Israel setenta mil homens.',
        'E Deus mandou um anjo a Jerusalém para a destruir; e, destruindo-a ele, o Senhor olhou, e se arrependeu daquele mal, e disse ao anjo destruidor: Basta, agora retira a tua mão. E o anjo do Senhor estava junto à eira de Ornã, o jebuseu.',
        'E, levantando Davi os seus olhos, viu o anjo do Senhor, que estava entre a terra e o céu, com a sua espada desembainhada na sua mão estendida contra Jerusalém; então Davi e os anciãos, cobertos de sacos, se prostraram sobre os seus rostos.',
        'E disse Davi a Deus: Não sou eu o que disse que se contasse o povo? E eu mesmo sou o que pequei, e fiz muito mal; mas estas ovelhas que fizeram? Ah! Senhor, meu Deus, seja a tua mão contra mim, e contra a casa de meu pai, e não para castigo de teu povo.',
        'Então o anjo do Senhor ordenou a Gade que dissesse a Davi para subir e levantar um altar ao Senhor na eira de Ornã, o jebuseu.',
        'Subiu, pois, Davi, conforme a palavra de Gade, que falara em nome do Senhor.',
        'E, virando-se Ornã, viu o anjo, e esconderam-se seus quatro filhos que estavam com ele; e Ornã estava trilhando o trigo.',
        'E Davi veio a Ornã; e olhou Ornã, e viu a Davi, e saiu da eira, e se prostrou perante Davi com o rosto em terra.',
        'E disse Davi a Ornã: Dá-me este lugar da eira, para edificar nele um altar ao Senhor; dá-mo pelo seu valor, para que cesse este castigo sobre o povo.',
        'Então disse Ornã a Davi: Toma-o para ti, e faça o rei meu senhor dele o que parecer bem aos seus olhos; eis que dou os bois para holocaustos, e os trilhos para lenha, e o trigo para oferta de alimentos; tudo dou.',
        'E disse o rei Davi a Ornã: Não, antes, pelo seu valor, a quero comprar; porque não tomarei o que é teu, para o Senhor, para que não ofereça holocausto sem custo.',
        'E Davi deu a Ornã, por aquele lugar, o peso de seiscentos siclos de ouro.',
        'Então Davi edificou ali um altar ao Senhor, e ofereceu nele holocaustos e sacrifícios pacíficos; e invocou o Senhor, o qual lhe respondeu com fogo do céu sobre o altar do holocausto.',
        'E o Senhor deu ordem ao anjo, e ele tornou a sua espada à bainha.',
        'Vendo Davi, no mesmo tempo, que o Senhor lhe respondera na eira de Ornã, o jebuseu, sacrificou ali.',
        'Porque o tabernáculo do Senhor, que Moisés fizera no deserto, e o altar do holocausto, estavam naquele tempo no alto de Gibeom.',
        'E não podia Davi ir perante ele consultar a Deus; porque estava aterrorizado por causa da espada do anjo do Senhor.',
      ],
      [
        'E disse Davi: Esta será a casa do SENHOR Deus, e este será o altar do holocausto para Israel.',
        'E deu ordem Davi que se ajuntassem os estrangeiros que estavam na terra de Israel; e ordenou cortadores de pedras, para que lavrassem pedras de cantaria, para edificar a casa de Deus.',
        'E aparelhou Davi ferro em abundância, para os pregos das portas das entradas, e para as junturas; como também cobre em abundância, que não foi pesado;',
        'E madeira de cedro sem conta; porque os sidônios e tírios traziam a Davi madeira de cedro em abundância.',
        'Porque dizia Davi: Salomão, meu filho, ainda é moço e tenro, e a casa que se há de edificar para o Senhor deve ser magnífica em excelência, para nome e glória em todas as terras; eu, pois, agora lhe prepararei materiais. Assim preparou Davi materiais em abundância, antes da sua morte.',
        'Então chamou a Salomão seu filho, e lhe ordenou que edificasse uma casa ao Senhor Deus de Israel.',
        'E disse Davi a Salomão: Filho meu, quanto a mim, tive em meu coração o propósito de edificar uma casa ao nome do Senhor meu Deus.',
        'Porém, veio a mim a palavra do Senhor, dizendo: Tu derramaste sangue em abundância, e fizeste grandes guerras; não edificarás casa ao meu nome; porquanto muito sangue tens derramado na terra, perante mim.',
        'Eis que o filho que te nascer será homem de repouso; porque repouso lhe hei de dar de todos os seus inimigos ao redor; portanto, Salomão será o seu nome, e paz e descanso darei a Israel nos seus dias.',
        'Ele edificará uma casa ao meu nome, e me será por filho, e eu lhe serei por pai, e confirmarei o trono de seu reino sobre Israel, para sempre.',
        'Agora, pois, meu filho, o Senhor seja contigo; e prospera, e edifica a casa do Senhor teu Deus, como ele disse de ti.',
        'O Senhor te dê tão-somente prudência e entendimento, e te instrua acerca de Israel; e isso para guardar a lei do Senhor teu Deus.',
        'Então prosperarás, se tiveres cuidado de cumprir os estatutos e os juízos, que o Senhor mandou a Moisés acerca de Israel; esforça-te, e tem bom ânimo; não temas, nem tenhas pavor.',
        'Eis que na minha aflição preparei para a casa do Senhor cem mil talentos de ouro, e um milhão de talentos de prata, e de cobre e de ferro que não se pesou, porque era em abundância; também madeira e pedras preparei, e tu suprirás o que faltar.',
        'Também tens contigo obreiros em grande número, cortadores e artífices em obra de pedra e madeira; e toda a sorte de peritos em toda a espécie de obra.',
        'Do ouro, da prata, e do cobre, e do ferro não há conta. Levanta-te, pois, e faze a obra, e o Senhor seja contigo.',
        'E Davi deu ordem a todos os príncipes de Israel que ajudassem a Salomão, seu filho, dizendo:',
        'Porventura não está convosco o Senhor vosso Deus, e não vos deu repouso ao redor? Porque entregou na minha mão os moradores da terra; e a terra foi sujeita perante o Senhor e perante o seu povo.',
        'Disponde, pois, agora o vosso coração e a vossa alma para buscardes ao Senhor vosso Deus; e levantai-vos, e edificai o santuário do Senhor Deus, para que a arca da aliança do Senhor, e os vasos sagrados de Deus se tragam a esta casa, que se há de edificar ao nome do Senhor.',
      ],
      [
        'Sendo, pois, Davi já velho, e cheio de dias, fez a Salomão, seu filho, rei sobre Israel.',
        'E reuniu a todos os príncipes de Israel, como também aos sacerdotes e levitas.',
        'E foram contados os levitas de trinta anos para cima; e foi o número deles, segundo as suas cabeças, trinta e oito mil homens.',
        'Destes havia vinte e quatro mil, para promoverem a obra da casa do Senhor, e seis mil oficiais e juízes,',
        'E quatro mil porteiros, e quatro mil para louvarem ao Senhor com os instrumentos, que eu fiz para o louvar, disse Davi.',
        'E Davi os repartiu por turnos, segundo os filhos de Levi, Gérson, Coate e Merari.',
        'Dos gersonitas: Ladã e Simei.',
        'Os filhos de Ladã: Jeiel, o chefe, e Zetã, e Joel, três.',
        'Os filhos de Simei: Selomite, Haziel, e Harã, três; estes foram os chefes dos pais de Ladã.',
        'E os filhos de Simei: Jaate, Ziza, Jeús, e Berias; estes foram os filhos de Simei, quatro.',
        'E Jaate era o chefe, e Ziza o segundo, mas Jeús e Berias não tiveram muitos filhos; por isso estes, sendo contados juntos se tornaram uma só família.',
        'Os filhos de Coate: Anrão, Izar, Hebrom, e Uziel, quatro.',
        'Os filhos de Anrão: Arão e Moisés; e Arão foi separado para santificar o santo dos santos, ele e seus filhos, eternamente; para incensar diante do Senhor, para o servirem, e para darem a bênção em seu nome eternamente.',
        'E, quanto a Moisés, homem de Deus, seus filhos foram contados entre os da tribo de Levi.',
        'Foram, pois, os filhos de Moisés, Gérson e Eliézer.',
        'Dos filhos de Gérson foi Sebuel o chefe.',
        'E, quanto aos filhos de Eliézer, foi Reabias o chefe; e Eliézer não teve outros filhos; porém os filhos de Reabias foram muitos.',
        'Dos filhos de Izar foi Selomite o chefe.',
        'Quanto aos filhos de Hebrom, foram Jerias o primeiro, Amarias o segundo, Jaaziel o terceiro, e Jecameão o quarto.',
        'Quanto aos filhos de Uziel, Mica o chefe, e Issias o segundo.',
        'Os filhos de Merari: Mali, e Musi; os filhos de Mali: Eleazar e Quis.',
        'E morreu Eleazar, e não teve filhos, porém filhas; e os filhos de Quis, seus parentes, as tomaram por mulheres.',
        'Os filhos de Musi: Mali, e Eder, e Jeremote, três.',
        'Estes são os filhos de Levi, segundo a casa de seus pais, chefes dos pais, conforme foram contados pelos seus nomes, segundo as suas cabeças, que faziam a obra do ministério da casa do Senhor, desde a idade de vinte anos para cima.',
        'Porque disse Davi: O Senhor Deus de Israel deu repouso ao seu povo, e habitará em Jerusalém para sempre.',
        'E também, quanto aos levitas, que nunca mais levassem o tabernáculo, nem algum de seus aparelhos pertencentes ao seu ministério.',
        'Porque, segundo as últimas palavras de Davi, foram contados os filhos de Levi da idade de vinte anos para cima:',
        'Porque o seu cargo era assistir aos filhos de Arão no ministério da casa do Senhor, nos átrios, e nas câmaras, e na purificação de todas as coisas sagradas, e na obra do ministério da casa de Deus.',
        'A saber: para os pães da proposição, e para a flor de farinha, para a oferta de alimentos, e para os coscorões ázimos, e para as sertãs, e para o tostado, e para todo o peso e medida;',
        'E para estarem cada manhã em pé para louvarem e celebrarem ao Senhor; e semelhantemente à tarde;',
        'E para oferecerem os holocaustos do Senhor, aos sábados, nas luas novas, e nas solenidades, segundo o seu número e costume, continuamente perante o Senhor;',
        'E para que tivessem cuidado da guarda da tenda da congregação, e da guarda do santuário, e da guarda dos filhos de Arão, seus irmãos, no ministério da casa do Senhor.',
      ],
      [
        'E quanto aos filhos de Arão, estas foram as suas divisões: os filhos de Arão: Nadabe, Abiú, Eleazar e Itamar.',
        'E morreram Nadabe e Abiú antes de seu pai, e não tiveram filhos; e Eleazar e Itamar administravam o sacerdócio.',
        'E Davi, com Zadoque, dos filhos de Eleazar, e Aimeleque, dos filhos de Itamar, dividiu-os segundo o seu ofício no seu ministério.',
        'E acharam-se muito mais chefes dos pais entre os filhos de Eleazar do que entre os filhos de Itamar, quando os repartiram; dos filhos de Eleazar dezesseis chefes das casas paternas, mas dos filhos de Itamar, segundo as casas paternas, oito.',
        'E os repartiram por sortes, uns com os outros; porque houve governadores do santuário e governadores da casa de Deus, assim dentre os filhos de Eleazar, como dentre os filhos de Itamar.',
        'E Semaías, filho de Natanael, o escrivão dentre os levitas, os registrou perante o rei, e os príncipes, e Zadoque, o sacerdote, e Aimeleque, filho de Abiatar, e os chefes dos pais entre os sacerdotes, e entre os levitas; dentre as casas dos pais tomou-se uma para Eleazar, e outra para Itamar.',
        'E saiu a primeira sorte a Jeoiaribe, a segunda a Jedaías,',
        'A terceira a Harim, a quarta a Seorim,',
        'A quinta a Malquias, a sexta a Miamim,',
        'A sétima a Hacoz, a oitava a Abias,',
        'A nona a Jesua, a décima a Secanias,',
        'A undécima a Eliasibe, a duodécima a Jaquim,',
        'A décima terceira a Hupa, a décima quarta a Jesebeabe,',
        'A décima quinta a Bilga, a décima sexta a Imer,',
        'A décima sétima a Hezir, a décima oitava a Hapizes,',
        'A décima nona a Petaías, a vigésima a Jeezquel,',
        'A vigésima primeira a Jaquim, a vigésima segunda a Gamul,',
        'A vigésima terceira a Delaías, a vigésima quarta a Maazias.',
        'O ofício destes no seu ministério era entrar na casa do Senhor, segundo lhes fora ordenado por Arão seu pai, como o Senhor Deus de Israel lhe tinha mandado.',
        'E do restante dos filhos de Levi: dos filhos de Anrão, Subael; dos filhos de Subael, Jedias.',
        'Quanto a Reabias: dos filhos de Reabias, Issias era o primeiro;',
        'Dos izaritas, Selomote; dos filhos de Selomote, Jaate;',
        'E dos filhos de Hebrom, Jerias o primeiro, Amarias o segundo, Jaaziel o terceiro, Jecameão o quarto;',
        'Dos filhos de Uziel, Mica; dos filhos de Mica, Samir;',
        'O irmão de Mica, Issias; dos filhos de Issias, Zacarias;',
        'Os filhos de Merari, Mali e Musi; dos filhos de Jaazias, Beno;',
        'Os filhos de Merari: de Jaazias, Beno, e Soão, e Zacur, e Ibri;',
        'De Mali, Eleazar; e este não teve filhos.',
        'Quanto a Quis: dos filhos de Quis, Jerameel;',
        'E os filhos de Musi: Mali, e Eder, e Jerimote; estes foram os filhos dos levitas, segundo as suas casas paternas.',
        'Estes também lançaram sortes como seus irmãos, os filhos de Arão, perante o rei Davi, e Zadoque, e Aimeleque, e os chefes das famílias entre os sacerdotes e entre os levitas; assim fizeram, tanto os pais principais como os irmãos menores.',
      ],
      [
        'E Davi, juntamente com os capitães do exército, separou para o ministério os filhos de Asafe, e de Hemã, e de Jedutum, para profetizarem com harpas, com címbalos, e com saltérios; e este foi o número dos homens aptos para a obra do seu ministério:',
        'Dos filhos de Asafe: Zacur, José, Netanias, e Asarela, filhos de Asafe; a cargo de Asafe, que profetizava debaixo das ordens do rei Davi.',
        'Quanto a Jedutum, os filhos: Gedalias, Zeri, Jesaías, Hasabias, e Matitias, seis, a cargo de seu pai, Jedutum, o qual profetizava com a harpa, louvando e dando graças ao Senhor.',
        'Quanto a Hemã, os filhos: Buquias, Matanias, Uziel, Sebuel, Jerimote, Hananias, Hanani, Eliata, Gidalti, Romanti-Ezer, Josbecasa, Maloti, Hotir, e Maaziote.',
        'Todos estes foram filhos de Hemã, o vidente do rei nas palavras de Deus, para exaltar o seu poder; porque Deus dera a Hemã catorze filhos e três filhas.',
        'Todos estes estavam sob a direção de seu pai, para a música da casa do Senhor, com saltérios, címbalos e harpas, para o ministério da casa de Deus; e Asafe, Jedutum, e Hemã, estavam sob as ordens do rei.',
        'E era o número deles, juntamente com seus irmãos instruídos no canto ao Senhor, todos eles mestres, duzentos e oitenta e oito.',
        'E deitaram sortes acerca da guarda igualmente, assim o pequeno como o grande, o mestre juntamente com o discípulo.',
        'Saiu, pois, a primeira sorte a Asafe, a saber a José; a segunda a Gedalias; e ele, e seus irmãos, e seus filhos, ao todo eram doze.',
        'A terceira a Zacur, seus filhos, e seus irmãos, doze.',
        'A quarta a Izri, seus filhos, e seus irmãos, doze.',
        'A quinta a Netanias, seus filhos, e seus irmãos, doze.',
        'A sexta a Buquias, seus filhos, e seus irmãos, doze.',
        'A sétima a Jesarela, seus filhos, e seus irmãos, doze.',
        'A oitava a Jesaías, seus filhos, e seus irmãos, doze.',
        'A nona a Matanias, seus filhos, e seus irmãos, doze.',
        'A décima a Simei, seus filhos, e seus irmãos, doze.',
        'A undécima a Azareel, seus filhos, e seus irmãos, doze.',
        'A duodécima a Hasabias, seus filhos, e seus irmãos, doze.',
        'A décima terceira a Subael, seus filhos, e seus irmãos, doze.',
        'A décima quarta a Matitias, seus filhos, e seus irmãos, doze.',
        'A décima quinta a Jeremote, seus filhos, e seus irmãos, doze.',
        'A décima sexta a Hananias, seus filhos, e seus irmãos, doze.',
        'A décima sétima a Josbecasa, seus filhos, e seus irmãos, doze.',
        'A décima oitava a Hanani, seus filhos, e seus irmãos, doze.',
        'A décima nona a Maloti, seus filhos, e seus irmãos, doze.',
        'A vigésima a Eliata, seus filhos, e seus irmãos, doze.',
        'A vigésima primeira a Hotir, seus filhos, e seus irmãos, doze.',
        'A vigésima segunda a Gidalti, seus filhos, e seus irmãos, doze.',
        'A vigésima terceira a Maaziote, seus filhos, e seus irmãos, doze.',
        'A vigésima quarta a Romanti-Ezer, seus filhos, e seus irmãos, doze.',
      ],
      [
        'Quanto às divisões dos porteiros: dos coraítas: Meselemias, filho de Coré, dos filhos de Asafe.',
        'E foram os filhos de Meselemias: Zacarias o primogênito, Jediael o segundo, Zebadias o terceiro, Jatniel o quarto,',
        'Elão o quinto, Joanã o sexto, Elioenai o sétimo.',
        'E os filhos de Obede-Edom foram: Semaías o primogênito, Jozabade o segundo, Joá o terceiro, e Sacar o quarto, e Natanael o quinto,',
        'Amiel o sexto, Issacar o sétimo, Peuletai o oitavo; porque Deus o tinha abençoado.',
        'Também a seu filho Semaías nasceram filhos, que dominaram sobre a casa de seu pai; porque foram homens valentes.',
        'Os filhos de Semaías: Otni, Rafael, Obede, e Elzabade, com seus irmãos, homens valentes, Eliú e Semaquias.',
        'Todos estes foram dos filhos de Obede-Edom; eles e seus filhos, e seus irmãos, homens valentes e de força para o ministério; ao todo sessenta e dois, de Obede-Edom.',
        'E os filhos e os irmãos de Meselemias, homens valentes, foram dezoito.',
        'E de Hosa, dentre os filhos de Merari, foram filhos: Sinri o chefe (ainda que não era o primogênito, contudo seu pai o constituiu chefe),',
        'Hilquias o segundo, Tebalias o terceiro, Zacarias o quarto; todos os filhos e irmãos de Hosa foram treze.',
        'Destes se fizeram as turmas dos porteiros, alternando os principais dos homens da guarda, juntamente com os seus irmãos, para ministrarem na casa do Senhor.',
        'E lançaram sortes, assim os pequenos como os grandes, segundo as casas de seus pais, para cada porta.',
        'E caiu a sorte do oriente a Selemias; e lançou-se a sorte por seu filho Zacarias, conselheiro entendido, e saiu-lhe a do norte.',
        'E para Obede-Edom a do sul; e para seus filhos a casa dos depósitos.',
        'Para Supim e Hosa a do ocidente, junto a porta Salequete, perto do caminho da subida; uma guarda defronte de outra guarda.',
        'Ao oriente seis levitas; ao norte quatro por dia, ao sul quatro por dia, porém para as casas dos depósitos de dois em dois.',
        'Em Parbar, ao ocidente, quatro junto ao caminho, e dois junto a Parbar.',
        'Estas são as turmas dos porteiros dentre os filhos dos coraítas, e dentre os filhos de Merari.',
        'E dos levitas: Aías tinha cargo dos tesouros da casa de Deus e dos tesouros das coisas sagradas.',
        'Quanto aos filhos de Ladã, os filhos dos gersonitas que pertencem a Ladã, chefes das casas paternas de Ladã: Jeieli.',
        'Os filhos de Jeieli: Zetã e Joel, seu irmão; estes tinham cargo dos tesouros da casa do Senhor,',
        'Dos anramitas, dos izaritas, dos hebronitas, dos uzielitas.',
        'E Sebuel, filho de Gérson, o filho de Moisés, era o chefe dos tesouros.',
        'E seus irmãos foram, do lado de Eliézer, Reabias seu filho, e Jesaías seu filho, e Jorão seu filho, e Zicri seu filho, e Selomite, seu filho.',
        'Este Selomite e seus irmãos tinham a seu cargo todos os tesouros das coisas dedicadas que o rei Davi e os chefes das casas paternas, capitães de milhares, e de centenas, e capitães do exército tinham consagrado.',
        'Dos despojos das guerras dedicaram ofertas para repararem a casa do Senhor.',
        'Como também tudo quanto tinha consagrado Samuel, o vidente, e Saul filho de Quis, e Abner filho de Ner, e Joabe filho de Zeruia; tudo que qualquer havia dedicado estava debaixo da mão de Selomite e seus irmãos.',
        'Dos izaritas, Quenanias e seus filhos foram postos sobre Israel como oficiais e como juízes, dos negócios externos.',
        'Dos hebronitas foram Hasabias e seus irmãos, homens valentes, mil e setecentos, que tinham a superintendência sobre Israel, além do Jordão para o ocidente, em toda a obra do Senhor, e para o serviço do rei.',
        'Dos hebronitas Jerias era o chefe, segundo as suas gerações conforme as suas famílias. No ano quarenta do reino de Davi se buscaram e acharam entre eles homens valentes em Jazer de Gileade.',
        'E seus irmãos, homens valentes, dois mil e setecentos, chefes dos pais; e o rei Davi os constituiu sobre os rubenitas e os gaditas, e a meia tribo dos manassitas, para todos os negócios de Deus, e para todos os negócios do rei.',
      ],
      [
        'Estes são os filhos de Israel segundo o seu número, os chefes dos pais, e os capitães dos milhares e das centenas, com os seus oficiais, que serviam ao rei em todos os negócios das turmas que entravam e saíam de mês em mês, em todos os meses do ano; cada turma de vinte e quatro mil.',
        'Sobre a primeira turma do primeiro mês estava Jasobeão, filho de Zabdiel; e em sua turma havia vinte e quatro mil.',
        'Era este dos filhos de Perez, chefe de todos os capitães dos exércitos, para o primeiro mês,',
        'E sobre a turma do segundo mês estava Dodai, o aoíta, com a sua turma, cujo líder era Miclote; também em sua turma havia vinte e quatro mil.',
        'O terceiro capitão do exército, para o terceiro mês, era Benaia, filho de Joiada, chefe dos sacerdotes; também em sua turma havia vinte e quatro mil.',
        'Era este Benaia valente entre os trinta, e sobre os trinta; e na sua turma estava Amizabade, seu filho.',
        'O quarto, do quarto mês, era Asael, irmão de Joabe, e depois dele Zebadias, seu filho; também em sua turma havia vinte e quatro mil.',
        'O quinto, do quinto mês, Samute, o israíta; também em sua turma havia vinte e quatro mil.',
        'O sexto, do sexto mês, Ira, filho de Iques, o tecoíta; também em sua turma havia vinte e quatro mil.',
        'O sétimo, do sétimo mês, Helez, o pelonita, dos filhos de Efraim; também em sua turma havia vinte e quatro mil.',
        'O oitavo, do oitavo mês, Sibecai, o husatita, dos zeraítas; também em sua turma havia vinte e quatro mil.',
        'O nono, do nono mês, Abiezer, o anatotita, dos benjamitas; também em sua turma havia vinte e quatro mil.',
        'O décimo, do décimo mês, Maarai, o netofatita, dos zeraítas; também em sua turma havia vinte e quatro mil.',
        'O undécimo, do undécimo mês, Benaia, o piratonita, dos filhos de Efraim; também em sua turma havia vinte e quatro mil.',
        'O duodécimo, do duodécimo mês, Heldai, o netofatita, de Otniel; também em sua turma havia vinte e quatro mil.',
        'Sobre as tribos de Israel estavam: sobre os rubenitas era líder Eliezer, filho de Zicri; sobre os simeonitas, Sefatias, filho de Maaca.',
        'Sobre os levitas, Hasabias, filho de Quemuel; sobre os aronitas, Zadoque;',
        'Sobre Judá, Eliú, dos irmãos de Davi; sobre Issacar, Onri, filho de Micael;',
        'Sobre Zebulom, Ismaías, filho de Obadias; sobre Naftali, Jerimote, filho de Azriel;',
        'Sobre os filhos de Efraim, Oséias, filho de Azazias; sobre a meia tribo de Manassés, Joel, filho de Pedaías;',
        'Sobre a outra meia tribo de Manassés em Gileade, Ido, filho de Zacarias; sobre Benjamim, Jaasiel, filho de Abner;',
        'Sobre Dã, Azarel, filho de Jeroão. Estes eram os príncipes das tribos de Israel.',
        'Não tomou, porém, Davi o número dos de vinte anos para baixo, porquanto o Senhor tinha falado que havia de multiplicar a Israel como as estrelas do céu.',
        'Joabe, filho de Zeruia, tinha começado a numerá-los, porém não acabou; porquanto viera por isso grande ira sobre Israel; assim o número não se pôs no registro das crônicas do rei Davi.',
        'E sobre os tesouros do rei estava Azmavete, filho de Adiel; e sobre os tesouros dos campos, das cidades, e das aldeias, e das torres, Jônatas, filho de Uzias.',
        'E sobre os que faziam a obra do campo, na lavoura da terra, Ezri, filho de Quelube.',
        'E sobre as vinhas, Simei, o ramatita; porém sobre o que das vides entrava nas adegas do vinho, Zabdi, o sifmita.',
        'E sobre os olivais e sicômoros que havia nas campinas, Baal-Hanã, o gederita; porém Joás sobre os armazéns do azeite.',
        'E sobre os gados que pastavam em Sarom, Sitrai, o saronita; porém, sobre os gados dos vales, Safate, filho de Adlai.',
        'E sobre os camelos, Obil, o ismaelita; e sobre as jumentas, Jedias, o meronotita.',
        'E sobre o gado miúdo, Jaziz, o hagrita; todos esses eram administradores da fazenda que tinha o rei Davi.',
        'E Jônatas, tio de Davi, era do conselho, homem entendido, e também escriba; e Jeiel, filho de Hacmoni, estava com os filhos do rei.',
        'E Aitofel era do conselho do rei; e Husai, o arquita, amigo do rei.',
        'E depois de Aitofel, Joiada, filho de Benaia, e Abiatar; porém Joabe era o general do exército do rei.',
      ],
      [
        'Então Davi reuniu em Jerusalém todos os príncipes de Israel, os príncipes das tribos, e os capitães das turmas, que serviam o rei, e os capitães dos milhares, e os capitães das centenas, e os administradores de toda a fazenda e possessão do rei, e de seus filhos, como também os oficiais, os poderosos, e todo o homem valente.',
        'E pôs-se o rei Davi em pé, e disse: Ouvi-me, irmãos meus, e povo meu; em meu coração propus eu edificar uma casa de repouso para a arca da aliança do Senhor e para o estrado dos pés do nosso Deus, e eu tinha feito o preparo para a edificar.',
        'Porém Deus me disse: Não edificarás casa ao meu nome, porque és homem de guerra, e derramaste muito sangue.',
        'E o Senhor Deus de Israel escolheu-me de toda a casa de meu pai, para que eternamente fosse rei sobre Israel; porque a Judá escolheu por soberano, e a casa de meu pai na casa de Judá; e entre os filhos de meu pai se agradou de mim para me fazer reinar sobre todo o Israel.',
        'E, de todos os meus filhos (porque muitos filhos me deu o Senhor), escolheu ele o meu filho Salomão para se assentar no trono do reino do Senhor sobre Israel.',
        'E me disse: Teu filho Salomão, ele edificará a minha casa e os meus átrios; porque o escolhi para filho, e eu lhe serei por pai.',
        'E estabelecerei o seu reino para sempre, se perseverar em cumprir os meus mandamentos e os meus juízos, como até ao dia de hoje.',
        'Agora, pois, perante os olhos de todo o Israel, a congregação do Senhor, e perante os ouvidos de nosso Deus, guardai e buscai todos os mandamentos do Senhor vosso Deus, para que possuais esta boa terra, e a façais herdar a vossos filhos depois de vós, para sempre.',
        'E tu, meu filho Salomão, conhece o Deus de teu pai, e serve-o com um coração perfeito e com uma alma voluntária; porque esquadrinha o Senhor todos os corações, e entende todas as imaginações dos pensamentos; se o buscares, será achado de ti; porém, se o deixares, rejeitar-te-á para sempre.',
        'Olha, pois, agora, porque o Senhor te escolheu para edificares uma casa para o santuário; esforça-te, e faze a obra.',
        'E deu Davi a Salomão, seu filho, a planta do alpendre com as suas casas, e as suas tesourarias, e os seus cenáculos, e as suas recâmaras interiores, como também da casa do propiciatório.',
        'E também a planta de tudo quanto tinha em mente, a saber: dos átrios da casa do Senhor, e de todas as câmaras ao redor, para os tesouros da casa de Deus, e para os tesouros das coisas sagradas;',
        'E para as turmas dos sacerdotes, e para os levitas, e para toda a obra do ministério da casa do Senhor, e para todos os utensílios do ministério da casa do Senhor.',
        'E deu ouro, segundo o peso do ouro, para todos os utensílios de cada ministério; também a prata, por peso, para todos os utensílios de prata, para todos os utensílios de cada ministério.',
        'E o peso para os castiçais de ouro, e suas candeias de ouro segundo o peso de cada castiçal e as suas candeias; também para os castiçais de prata, segundo o peso do castiçal e as suas candeias, segundo o uso de cada castiçal.',
        'Também deu o ouro por peso para as mesas da proposição, para cada mesa; como também a prata para as mesas de prata.',
        'E ouro puro para os garfos, e para as bacias, e para os jarros, e para as taças de ouro, para cada taça seu peso; como também para as taças de prata, para cada taça seu peso.',
        'E para o altar do incenso, ouro purificado, por seu peso; como também o ouro para o modelo do carro, a saber, dos querubins, que haviam de estender as asas, e cobrir a arca da aliança do Senhor.',
        'Tudo isto, disse Davi, fez-me entender o Senhor, por escrito da sua mão, a saber, todas as obras desta planta.',
        'E disse Davi a Salomão seu filho: Esforça-te e tem bom ânimo, e faze a obra; não temas, nem te apavores; porque o Senhor Deus, meu Deus, há de ser contigo; não te deixará, nem te desamparará, até que acabes toda a obra do serviço da casa do Senhor.',
        'E eis que aí tens as turmas dos sacerdotes e dos levitas para todo o ministério da casa de Deus; estão também contigo, para toda a obra, voluntários com sabedoria de toda a espécie para todo o ministério; como também todos os príncipes, e todo o povo, para todos os teus mandados.',
      ],
      [
        'Disse mais o rei Davi a toda a congregação: Salomão, meu filho, a quem só Deus escolheu, é ainda moço e tenro, e esta obra é grande; porque não é o palácio para homem, mas para o SENHOR Deus.',
        'Eu, pois, com todas as minhas forças já tenho preparado para a casa de meu Deus ouro para as obras de ouro, e prata para as de prata, e cobre para as de cobre, ferro para as de ferro e madeira para as de madeira, pedras de ônix, e as de engaste, e pedras ornamentais, e pedras de diversas cores, e toda a sorte de pedras preciosas, e pedras de mármore em abundância.',
        'E ainda, porque tenho afeto à casa de meu Deus, o ouro e prata particular que tenho eu dou para a casa do meu Deus, afora tudo quanto tenho preparado para a casa do santuário:',
        'Três mil talentos de ouro de Ofir; e sete mil talentos de prata purificada, para cobrir as paredes das casas.',
        'Ouro para os objetos de ouro, e prata para os de prata; e para toda a obra de mão dos artífices. Quem, pois, está disposto a encher a sua mão, para oferecer hoje voluntariamente ao Senhor?',
        'Então os chefes dos pais, e os príncipes das tribos de Israel, e os capitães de mil e de cem, até os chefes da obra do rei, voluntariamente contribuíram.',
        'E deram para o serviço da casa de Deus cinco mil talentos de ouro, e dez mil dracmas, e dez mil talentos de prata, e dezoito mil talentos de cobre, e cem mil talentos de ferro.',
        'E os que possuíam pedras preciosas, deram-nas para o tesouro da casa do Senhor, a cargo de Jeiel o gersonita.',
        'E o povo se alegrou porque contribuíram voluntariamente; porque, com coração perfeito, voluntariamente deram ao Senhor; e também o rei Davi se alegrou com grande alegria.',
        'Por isso Davi louvou ao Senhor na presença de toda a congregação; e disse Davi: Bendito és tu, Senhor Deus de Israel, nosso pai, de eternidade em eternidade.',
        'Tua é, Senhor, a magnificência, e o poder, e a honra, e a vitória, e a majestade; porque teu é tudo quanto há nos céus e na terra; teu é, Senhor, o reino, e tu te exaltaste por cabeça sobre todos.',
        'E riquezas e glória vêm de diante de ti, e tu dominas sobre tudo, e na tua mão há força e poder; e na tua mão está o engrandecer e o dar força a tudo.',
        'Agora, pois, ó Deus nosso, graças te damos, e louvamos o nome da tua glória.',
        'Porque quem sou eu, e quem é o meu povo, para que pudéssemos oferecer voluntariamente coisas semelhantes? Porque tudo vem de ti, e do que é teu to damos.',
        'Porque somos estrangeiros diante de ti, e peregrinos como todos os nossos pais; como a sombra são os nossos dias sobre a terra, e sem ti não há esperança.',
        'Senhor, nosso Deus, toda esta abundância, que preparamos, para te edificar uma casa ao teu santo nome, vem da tua mão, e é toda tua.',
        'E bem sei eu, Deus meu, que tu provas os corações, e que da sinceridade te agradas; eu também na sinceridade de meu coração voluntariamente dei todas estas coisas; e agora vi com alegria que o teu povo, que se acha aqui, voluntariamente te deu.',
        'Senhor Deus de Abraão, Isaque, e Israel, nossos pais, conserva isto para sempre no intento dos pensamentos do coração de teu povo; e encaminha o seu coração para ti.',
        'E a Salomão, meu filho, dá um coração perfeito, para guardar os teus mandamentos, os teus testemunhos, e os teus estatutos; e para fazer tudo, e para edificar este palácio que tenho preparado.',
        'Então disse Davi a toda a congregação: Agora louvai ao Senhor vosso Deus. Então toda a congregação louvou ao Senhor Deus de seus pais, e inclinaram-se, e prostraram-se perante o Senhor, e o rei.',
        'E ao outro dia imolaram sacrifícios ao Senhor, e ofereceram holocaustos ao Senhor, mil bezerros, mil carneiros, mil cordeiros, com as suas libações; e sacrifícios em abundância por todo o Israel.',
        'E comeram e beberam naquele dia perante o Senhor, com grande gozo; e a segunda vez fizeram rei a Salomão filho de Davi, e o ungiram ao Senhor por líder, e a Zadoque por sacerdote.',
        'Assim Salomão se assentou no trono do Senhor, como rei, em lugar de Davi seu pai, e prosperou; e todo o Israel lhe obedecia.',
        'E todos os príncipes, e os grandes, e até todos os filhos do rei Davi, se submeteram ao rei Salomão.',
        'E o Senhor magnificou a Salomão grandissimamente, perante os olhos de todo o Israel; e deu-lhe majestade real, qual antes dele não teve nenhum rei em Israel.',
        'Assim Davi, filho de Jessé, reinou sobre todo o Israel.',
        'E foram os dias que reinou sobre Israel, quarenta anos; em Hebrom reinou sete anos, e em Jerusalém reinou trinta e três.',
        'E morreu numa boa velhice, cheio de dias, riquezas e glória; e Salomão, seu filho, reinou em seu lugar.',
        'Os atos, pois, do rei Davi, assim os primeiros como os últimos, eis que estão escritos nas crônicas de Samuel, o vidente, e nas crônicas do profeta Natã, e nas crônicas de Gade, o vidente,',
        'Juntamente com todo o seu reinado e o seu poder; e os tempos que passaram sobre ele, e sobre Israel, e sobre todos os reinos daquelas terras.',
      ],
    ],
    livro: '1cronicas',
  },
  {
    abbrev: '2cr',
    capitulos: [
      [
        'Salomão, filho de Davi, fortaleceu-se no seu reino; e o SENHOR seu Deus era com ele, e o engrandeceu sobremaneira.',
        'E falou Salomão a todo o Israel, aos capitães de mil e de cem, aos juízes e a todos os governadores em todo o Israel, chefes das famílias.',
        'E foi Salomão, e toda a congregação com ele, ao alto que estava em Gibeom, porque ali estava a tenda da congregação de Deus, que Moisés, servo do Senhor, tinha feito no deserto.',
        'Mas Davi tinha feito subir a arca de Deus de Quiriate-Jearim ao lugar que lhe preparara; porque lhe tinha armado uma tenda em Jerusalém.',
        'Também o altar de cobre que tinha feito Bezaleel, filho de Uri, filho de Hur, estava ali diante do tabernáculo do Senhor; e Salomão e a congregação o buscavam.',
        'E Salomão ofereceu ali sacrifícios perante o Senhor, sobre o altar de cobre que estava na tenda da congregação; e ofereceu sobre ele mil holocaustos.',
        'Naquela mesma noite Deus apareceu a Salomão, e disse-lhe: Pede o que queres que eu te dê.',
        'E Salomão disse a Deus: Tu usaste de grande benignidade com meu pai Davi, e a mim me fizeste rei em seu lugar.',
        'Agora, pois, ó Senhor Deus, confirme-se a tua palavra, dada a meu pai Davi; porque tu me fizeste reinar sobre um povo numeroso como o pó da terra.',
        'Dá-me, pois, agora, sabedoria e conhecimento, para que possa sair e entrar perante este povo; pois quem poderia julgar a este tão grande povo?',
        'Então Deus disse a Salomão: Porquanto houve isto no teu coração, e não pediste riquezas, bens, ou honra, nem a morte dos que te odeiam, nem tampouco pediste muitos dias de vida, mas pediste para ti sabedoria e conhecimento, para poderes julgar a meu povo, sobre o qual te constituí rei,',
        'Sabedoria e conhecimento te são dados; e te darei riquezas, bens e honra, quais não teve nenhum rei antes de ti, e nem depois de ti haverá.',
        'Assim Salomão veio a Jerusalém, do alto que estava em Gibeom, de diante da tenda da congregação; e reinou sobre Israel.',
        'E Salomão ajuntou carros e cavaleiros, e teve mil e quatrocentos carros, e doze mil cavaleiros; os quais pôs nas cidades dos carros, e junto ao rei em Jerusalém.',
        'E fez o rei que houvesse ouro e prata em Jerusalém como pedras; e cedros em tanta abundância como figueiras bravas que há pelas campinas.',
        'E os cavalos, que tinha Salomão, eram trazidos do Egito; e os mercadores do rei os recebiam em tropas, cada uma pelo seu preço.',
        'E faziam subir e sair do Egito cada carro por seiscentos siclos de prata, e cada cavalo por cento e cinqüenta; e assim, por meio deles eram para todos os reis dos heteus, e para os reis da Síria.',
      ],
      [
        'E determinou Salomão edificar uma casa ao nome do SENHOR, como também uma casa para o seu reino.',
        'E designou Salomão setenta mil homens de carga, e oitenta mil que talhavam pedras na montanha, e três mil e seiscentos inspetores sobre eles.',
        'E Salomão mandou dizer a Hirão, rei de Tiro: Como fizeste com Davi meu pai, mandando-lhe cedros, para edificar uma casa em que morasse, assim também faze comigo.',
        'Eis que estou para edificar uma casa ao nome do Senhor meu Deus, para lhe consagrar, para queimar perante ele incenso aromático, e para a apresentação contínua do pão da proposição, para os holocaustos da manhã e da tarde, nos sábados e nas luas novas, e nas festividades do Senhor nosso Deus; o que é obrigação perpétua de Israel.',
        'E a casa que estou para edificar há de ser grande; porque o nosso Deus é maior do que todos os deuses.',
        'Porém, quem seria capaz de lhe edificar uma casa, visto que os céus e até os céus dos céus o não podem conter? E quem sou eu, que lhe edificasse casa, salvo para queimar incenso perante ele?',
        'Manda-me, pois, agora um homem hábil para trabalhar em ouro, em prata, em bronze, em ferro, em púrpura, em carmesim e em azul; e que saiba lavrar ao buril, juntamente com os peritos que estão comigo em Judá e em Jerusalém, os quais Davi, meu pai, preparou.',
        'Manda-me também madeiras de cedro, de cipreste, e algumins do Líbano; porque bem sei eu que os teus servos sabem cortar madeira no Líbano; e eis que os meus servos estarão com os teus servos.',
        'E isso para prepararem muita madeira; porque a casa que estou para fazer há de ser grande e maravilhosa.',
        'E eis que a teus servos, os cortadores, que cortarem a madeira, darei vinte mil coros de trigo malhado, vinte mil coros de cevada, vinte mil batos de vinho e vinte mil batos de azeite.',
        'E Hirão, rei de Tiro, respondeu por escrito que enviou a Salomão, dizendo: Porque o Senhor tem amado o seu povo, te constituiu sobre ele rei.',
        'Disse mais Hirão: Bendito seja o Senhor Deus de Israel, que fez os céus e a terra; o que deu ao rei Davi um filho sábio, de grande prudência e entendimento, que edifique casa ao Senhor, e para o seu reino.',
        'Agora, pois, envio um homem sábio de grande entendimento, a saber, Hirão Abiú.',
        'Filho de uma mulher das filhas de Dã, e cujo pai foi homem de Tiro; este sabe trabalhar em ouro, em prata, em bronze, em ferro, em pedras e em madeira, em púrpura, em azul, e em linho fino, e em carmesim, e é hábil para toda a obra do buril, e para toda a espécie de invenções, qualquer coisa que se lhe propuser, juntamente com os teus peritos, e os peritos de Davi, meu senhor, teu pai.',
        'Agora, pois, meu senhor, mande para os seus servos o trigo, a cevada, o azeite e o vinho, de que falou;',
        'E nós cortaremos tanta madeira no Líbano, quanta houveres mister, e ta traremos em jangadas pelo mar até Jope, e tu a farás subir a Jerusalém.',
        'E Salomão contou todos os homens estrangeiros, que havia na terra de Israel, conforme o censo com que os contara Davi seu pai; e acharam-se cento e cinqüenta e três mil e seiscentos.',
        'E designou deles setenta mil carregadores, e oitenta mil cortadores na montanha; como também três mil e seiscentos inspetores, para fazerem trabalhar o povo.',
      ],
      [
        'E começou Salomão a edificar a casa do SENHOR em Jerusalém, no monte Moriá, onde o SENHOR aparecera a Davi seu pai, no lugar que Davi tinha preparado na eira de Ornã, o jebuseu.',
        'E começou a edificar no segundo mês, no segundo dia, no ano quarto do seu reinado.',
        'E estes foram os fundamentos que Salomão pôs para edificar a casa de Deus: o comprimento em côvados, segundo a primeira medida, era de sessenta côvados, e a largura de vinte côvados.',
        'E o pátio, que estava na frente, tinha vinte côvados de comprimento, segundo a largura da casa, e a altura era de cento e vinte; e por dentro o revestiu com ouro puro.',
        'E a casa grande forrou com madeira de faia; e então a revestiu com ouro fino; e fez sobre ela palmas e cadeias.',
        'Também a casa adornou de pedras preciosas, para ornamento; e o ouro era ouro de Parvaim.',
        'Também na casa revestiu, com ouro, as traves, os umbrais, as suas paredes e as suas portas; e lavrou querubins nas paredes.',
        'Fez mais a casa do lugar santíssimo, cujo comprimento, segundo a largura da casa, era de vinte côvados, e também a largura de vinte côvados; e revestiu-a de ouro fino, do peso de seiscentos talentos.',
        'O peso dos pregos era de cinqüenta siclos de ouro; e as câmaras cobriu de ouro.',
        'Também fez na casa do lugar santíssimo dois querubins de obra móvel, e cobriu-os de ouro.',
        'E, quanto às asas dos querubins, o seu comprimento era de vinte côvados; a asa de um deles, de cinco côvados, e tocava na parede da casa; e a outra asa de cinco côvados, e tocava na asa do outro querubim.',
        'Também a asa do outro querubim era de cinco côvados, e tocava na parede da casa; era também a outra asa de cinco côvados, que tocava na asa do outro querubim.',
        'E as asas destes querubins se estendiam vinte côvados; e estavam postos em pé, e os seus rostos virados para a casa.',
        'Também fez o véu de azul, púrpura, carmesim e linho fino; e pôs sobre ele querubins;',
        'Fez também, diante da casa, duas colunas de trinta e cinco côvados de altura; e o capitel, que estava sobre cada uma, era de cinco côvados.',
        'Também fez cadeias no oráculo, e as pôs sobre as cabeças das colunas; fez também cem romãs, as quais pôs entre as cadeias.',
        'E levantou as colunas diante do templo, uma à direita, e outra à esquerda; e chamou o nome da que estava à direita Jaquim, e o nome da que estava à esquerda Boaz.',
      ],
      [
        'Também fez um altar de metal, de vinte côvados de comprimento, de vinte côvados de largura e de dez côvados de altura.',
        'Fez também o mar de fundição, de dez côvados de uma borda até a outra, redondo, e de cinco côvados de altura; cingia-o ao redor um cordão de trinta côvados.',
        'E por baixo dele havia figuras de bois, que cingiam o mar ao redor, dez em cada côvado, contornando-o; e tinha duas fileiras de bois, fundidos juntamente com o mar.',
        'E o mar estava posto sobre doze bois; três que olhavam para o norte, três que olhavam para o ocidente, três que olhavam para o sul e três que olhavam para o oriente; e o mar estava posto sobre eles; e as suas partes posteriores estavam todas para o lado de dentro.',
        'E tinha um palmo de grossura, e a sua borda foi feita como a borda de um copo, ou como uma flor-de-lis, da capacidade de três mil batos.',
        'Também fez dez pias; e pôs cinco à direita e cinco à esquerda, para lavarem nelas; o que pertencia ao holocausto o lavavam nelas; porém o mar era para que os sacerdotes se lavassem nele.',
        'Fez também dez castiçais de ouro, segundo a sua forma, e pô-los no templo, cinco à direita, e cinco à esquerda.',
        'Também fez dez mesas, e pô-las no templo, cinco à direita e cinco à esquerda; também fez cem bacias de ouro.',
        'Fez mais o pátio dos sacerdotes, e o grande átrio; como também as portas para o pátio, as quais revestiu de cobre.',
        'E pôs o mar ao lado direito, para o lado do oriente, na direção do sul.',
        'Também Hirão fez as caldeiras, as pás e as bacias. Assim acabou Hirão de fazer a obra, que fazia para o rei Salomão, na casa de Deus.',
        'As duas colunas, os globos, e os dois capitéis sobre as cabeças das colunas; e as duas redes, para cobrir os dois globos dos capitéis, que estavam sobre a cabeça das colunas.',
        'E as quatrocentas romãs para as duas redes; duas carreiras de romãs para cada rede, para cobrirem os dois globos dos capitéis que estavam em cima das colunas.',
        'Também fez as bases; e as pias pôs sobre as bases;',
        'Um mar, e os doze bois debaixo dele;',
        'Semelhantemente as caldeiras, as pás, os garfos e todos os seus utensílios, fez Hirão Abiú ao rei Salomão, para a casa do Senhor, de cobre polido.',
        'Na campina do Jordão os fundiu o rei, na terra argilosa, entre Sucote e Zeredá.',
        'E fez Salomão todos estes objetos em grande abundância, que não se podia averiguar o peso do cobre.',
        'Fez também Salomão todos os objetos que eram para a casa de Deus, como também o altar de ouro, e as mesas, sobre as quais estavam os pães da proposição.',
        'E os castiçais com as suas lâmpadas de ouro finíssimo, para as acenderem segundo o costume, perante o oráculo.',
        'E as flores, as lâmpadas e os espevitadores eram de ouro, do mais finíssimo ouro.',
        'Como também os apagadores, as bacias, as colheres e os incensários de ouro finíssimo; e quanto à entrada da casa, as suas portas de dentro do lugar santíssimo, e as portas da casa do templo, eram de ouro.',
      ],
      [
        'Assim se acabou toda a obra que Salomão fez para a casa do SENHOR; então trouxe Salomão as coisas que seu pai Davi havia consagrado, a prata, o ouro e todos os objetos, e pô-los entre os tesouros da casa de Deus.',
        'Então Salomão congregou em Jerusalém os anciãos de Israel, e todos os chefes das tribos, os chefes dos pais entre os filhos de Israel, para fazerem subir a arca da aliança do Senhor, da cidade de Davi, que é Sião.',
        'E todos os homens de Israel se congregaram ao rei na ocasião da festa, que foi no sétimo mês.',
        'E vieram todos os anciãos de Israel; e os levitas levantaram a arca.',
        'E fizeram subir a arca, e a tenda da congregação, com todos os objetos sagrados, que estavam na tenda; os sacerdotes e os levitas os fizeram subir.',
        'Então o rei Salomão e toda a congregação de Israel, que se tinha reunido com ele diante da arca, sacrificaram carneiros e bois, que não se podiam contar, nem numerar, por causa da sua abundância.',
        'Assim trouxeram os sacerdotes a arca da aliança do Senhor ao seu lugar, ao oráculo da casa, ao lugar santíssimo, até debaixo das asas dos querubins.',
        'Porque os querubins estendiam ambas as asas sobre o lugar da arca, e os querubins cobriam, por cima, a arca e os seus varais.',
        'Então os varais sobressaíam para que as pontas dos varais da arca se vissem perante o oráculo, mas não se vissem de fora; e ali tem estado até ao dia de hoje.',
        'Na arca não havia coisa alguma senão as duas tábuas, que Moisés tinha posto em Horebe, quando o Senhor fez aliança com os filhos de Israel, saíndo eles do Egito.',
        'E sucedeu que, saindo os sacerdotes do santuário (porque todos os sacerdotes, que ali se acharam, se santificaram, sem respeitarem as suas turmas,',
        'E os levitas, que eram cantores, todos eles, de Asafe, de Hemã, de Jedutum, de seus filhos e de seus irmãos, vestidos de linho fino, com címbalos, com saltérios e com harpas, estavam em pé para o oriente do altar; e com eles até cento e vinte sacerdotes, que tocavam as trombetas).',
        'E aconteceu que, quando eles uniformemente tocavam as trombetas, e cantavam, para fazerem ouvir uma só voz, bendizendo e louvando ao Senhor; e levantando eles a voz com trombetas, címbalos, e outros instrumentos musicais, e louvando ao Senhor, dizendo: Porque ele é bom, porque a sua benignidade dura para sempre, então a casa se encheu de uma nuvem, a saber, a casa do Senhor;',
        'E os sacerdotes não podiam permanecer em pé, para ministrar, por causa da nuvem; porque a glória do Senhor encheu a casa de Deus.',
      ],
      [
        'Então falou Salomão: O SENHOR disse que habitaria nas trevas.',
        'E eu te tenho edificado uma casa para morada, e um lugar para a tua eterna habitação.',
        'Então o rei virou o seu rosto, e abençoou a toda a congregação de Israel, e toda a congregação de Israel estava em pé.',
        'E ele disse: Bendito seja o Senhor Deus de Israel, que falou pela sua boca a Davi meu pai; e pelas suas mãos o cumpriu, dizendo:',
        'Desde o dia em que tirei a meu povo da terra do Egito, não escolhi cidade alguma de todas as tribos de Israel, para edificar nela uma casa em que estivesse o meu nome; nem escolhi homem algum para ser líder do meu povo, Israel.',
        'Porém escolhi a Jerusalém para que ali estivesse o meu nome; e escolhi a Davi, para que estivesse sobre o meu povo Israel.',
        'Também Davi meu pai teve no seu coração o edificar uma casa ao nome do Senhor Deus de Israel.',
        'Porém o Senhor disse a Davi, meu pai: Porquanto tiveste no teu coração o edificar uma casa ao meu nome, bem fizeste de ter isto no teu coração.',
        'Contudo tu não edificarás a casa, mas teu filho, que há de proceder de teus lombos, esse edificará a casa ao meu nome.',
        'Assim confirmou o Senhor a sua palavra, que falou; porque eu me levantei em lugar de Davi meu pai, e me assentei sobre o trono de Israel, como o Senhor disse, e edifiquei a casa ao nome do Senhor Deus de Israel.',
        'E pus nela a arca, em que está a aliança que o Senhor fez com os filhos de Israel.',
        'E pôs-se em pé, perante o altar do Senhor, na presença de toda a congregação de Israel, e estendeu as suas mãos.',
        'Porque Salomão tinha feito uma plataforma de metal, de cinco côvados de comprimento, de cinco côvados de largura e de três côvados de altura, e a tinha posto no meio do pátio, e pôs-se em pé sobre ela, e ajoelhou-se em presença de toda a congregação de Israel, e estendeu as suas mãos para o céu.',
        'E disse: Ó Senhor Deus de Israel, não há Deus semelhante a ti, nem nos céus nem na terra; que guardas a aliança e a beneficência aos teus servos que caminham perante ti de todo o seu coração.',
        'Que guardaste ao teu servo Davi, meu pai, o que lhe falaste; porque tu pela tua boca o disseste, e pela tua mão o cumpriste, como se vê neste dia.',
        'Agora, pois, Senhor Deus de Israel, guarda ao teu servo Davi, meu pai, o que falaste, dizendo: Nunca homem algum será cortado de diante de mim, que se assente sobre o trono de Israel; tão-somente que teus filhos guardem seu caminho, andando na minha lei, como tu andaste diante de mim.',
        'E agora, Senhor Deus de Israel, cumpra-se a tua palavra, que disseste ao teu servo Davi.',
        'Mas, na verdade, habitará Deus com os homens na terra? Eis que os céus, e o céu dos céus, não te podem conter, quanto menos esta casa que tenho edificado?',
        'Atende, pois, à oração do teu servo, e à sua súplica, ó Senhor meu Deus; para ouvires o clamor, e a oração, que o teu servo faz perante ti.',
        'Que os teus olhos estejam dia e noite abertos sobre este lugar, de que disseste que ali porias o teu nome; para ouvires a oração que o teu servo orar neste lugar.',
        'Ouve, pois, as súplicas do teu servo, e do teu povo Israel, que fizerem neste lugar; e ouve tu do lugar da tua habitação, desde os céus; ouve pois, e perdoa.',
        'Quando alguém pecar contra o seu próximo, e lhe impuser juramento de maldição, fazendo-o jurar, e o juramento de maldição vier perante o teu altar, nesta casa,',
        'Ouve tu, então, desde os céus, e age e julga a teus servos, condenando ao ímpio, retribuindo o seu proceder sobre a sua cabeça; e justificando ao justo, dando-lhe segundo a sua justiça.',
        'Quando também o teu povo Israel for ferido diante do inimigo, por ter pecado contra ti, e eles se converterem, e confessarem o teu nome, e orarem e suplicarem perante ti nesta casa,',
        'Então, ouve tu desde os céus, e perdoa os pecados do teu povo Israel; e torna a levá-los à terra que lhes tens dado e a seus pais.',
        'Quando os céus se fecharem, e não houver chuva, por terem pecado contra ti, e orarem neste lugar, e confessarem teu nome, e se converterem dos seus pecados, quando tu os afligires,',
        'Então, ouve tu desde os céus, e perdoa o pecado de teus servos, e do teu povo Israel, ensinando-lhes o bom caminho, em que andem; e dá chuva sobre a tua terra, que deste ao teu povo em herança.',
        'Quando houver fome na terra, quando houver peste, quando houver queima de seara, ou ferrugem, gafanhotos, ou lagarta, cercando-a algum dos seus inimigos nas terras das suas portas, ou quando houver qualquer praga, ou qualquer enfermidade,',
        'Toda a oração, e toda a súplica, que qualquer homem fizer, ou todo o teu povo Israel, conhecendo cada um a sua praga, e a sua dor, e estendendo as suas mãos para esta casa,',
        'Então, ouve tu desde os céus, do assento da tua habitação, e perdoa, e dá a cada um conforme a todos os seus caminhos, segundo conheces o seu coração (pois só tu conheces o coração dos filhos dos homens),',
        'A fim de que te temam, para andarem nos teus caminhos, todos os dias que viverem na terra que deste a nossos pais.',
        'Assim também ao estrangeiro, que não for do teu povo Israel, quando vier de terras remotas por amor do teu grande nome, e da tua poderosa mão, e do teu braço estendido, vindo eles e orando nesta casa;',
        'Então, ouve tu desde os céus, do assento da tua habitação, e faze conforme a tudo o que o estrangeiro te suplicar; a fim de que todos os povos da terra conheçam o teu nome, e te temam, como o teu povo Israel; e a fim de saberem que pelo teu nome é chamada esta casa que edifiquei.',
        'Quando o teu povo sair à guerra contra os seus inimigos, pelo caminho que os enviares, e orarem a ti para o lado desta cidade que escolheste, e desta casa, que edifiquei ao teu nome,',
        'Ouve, então, desde os céus a sua oração, e a sua súplica, e faze-lhes justiça.',
        'Quando pecarem contra ti (pois não há homem que não peque), e tu te indignares contra eles, e os entregares diante do inimigo, para que os que os cativarem os levem em cativeiro para alguma terra, remota ou vizinha,',
        'E na terra, para onde forem levados em cativeiro, caírem em si, e se converterem, e na terra do seu cativeiro, a ti suplicarem, dizendo: Pecamos, perversamente procedemos e impiamente agimos;',
        'E se converterem a ti com todo o seu coração e com toda a sua alma, na terra do seu cativeiro, a que os levaram presos, e orarem para o lado da sua terra, que deste a seus pais, e para esta cidade que escolheste, e para esta casa que edifiquei ao teu nome,',
        'Ouve, então, desde os céus, do assento da tua habitação, a sua oração e as suas súplicas, e executa o seu direito; e perdoa ao teu povo que houver pecado contra ti.',
        'Agora, pois, ó meu Deus, estejam os teus olhos abertos, e os teus ouvidos atentos à oração deste lugar.',
        'Levanta-te, pois, agora, Senhor Deus, para o teu repouso, tu e a arca da tua fortaleza; os teus sacerdotes, ó Senhor Deus, sejam vestidos de salvação, e os teus santos se alegrem do bem.',
        'Ó Senhor Deus, não faças virar o rosto do teu ungido; lembra-te das misericórdias de Davi teu servo.',
      ],
      [
        'E acabando Salomão de orar, desceu o fogo do céu, e consumiu o holocausto e os sacrifícios; e a glória do SENHOR encheu a casa.',
        'E os sacerdotes não podiam entrar na casa do Senhor, porque a glória do Senhor tinha enchido a casa do Senhor.',
        'E todos os filhos de Israel vendo descer o fogo, e a glória do Senhor sobre a casa, encurvaram-se com o rosto em terra sobre o pavimento, e adoraram e louvaram ao Senhor, dizendo: Porque ele é bom, porque a sua benignidade dura para sempre.',
        'E o rei e todo o povo ofereciam sacrifícios perante o Senhor.',
        'E o rei Salomão ofereceu sacrifícios de bois, vinte e dois mil, e de ovelhas, cento e vinte mil; e o rei e todo o povo consagraram a casa de Deus.',
        'E os sacerdotes, serviam em seus ofícios; como também os levitas com os instrumentos musicais do Senhor, que o rei Davi tinha feito, para louvarem ao Senhor, porque a sua benignidade dura para sempre, quando Davi o louvava pelo ministério deles; e os sacerdotes tocavam as trombetas diante deles, e todo o Israel estava em pé.',
        'E Salomão santificou o meio do átrio, que estava diante da casa do Senhor; porquanto ali tinha ele oferecido os holocaustos e a gordura dos sacrifícios pacíficos; porque no altar de metal, que Salomão tinha feito, não podia caber o holocausto, e a oferta de alimentos, e a gordura.',
        'E, assim, naquele mesmo tempo celebrou Salomão a festa por sete dias e todo o Israel com ele, uma grande congregação, desde a entrada de Hamate, até ao rio do Egito.',
        'E no dia oitavo realizaram uma assembléia solene; porque sete dias celebraram a consagração do altar, e sete dias a festa.',
        'E no dia vigésimo terceiro do sétimo mês, despediu o povo para as suas tendas, alegres e de bom ânimo, pelo bem que o Senhor tinha feito a Davi, e a Salomão, e a seu povo Israel.',
        'Assim Salomão acabou a casa do Senhor, e a casa do rei, e tudo quanto Salomão intentou fazer na casa do Senhor e na sua casa prosperamente o efetuou.',
        'E o Senhor apareceu de noite a Salomão, e disse-lhe: Ouvi a tua oração, e escolhi para mim este lugar para casa de sacrifício.',
        'Se eu fechar os céus, e não houver chuva; ou se ordenar aos gafanhotos que consumam a terra; ou se enviar a peste entre o meu povo;',
        'E se o meu povo, que se chama pelo meu nome, se humilhar, e orar, e buscar a minha face e se converter dos seus maus caminhos, então eu ouvirei dos céus, e perdoarei os seus pecados, e sararei a sua terra.',
        'Agora estarão abertos os meus olhos e atentos os meus ouvidos à oração deste lugar.',
        'Porque agora escolhi e santifiquei esta casa, para que o meu nome esteja nela perpetuamente; e nela estarão fixos os meus olhos e o meu coração todos os dias.',
        'E, quanto a ti, se andares diante de mim, como andou Davi teu pai, e fizeres conforme a tudo o que te ordenei, e guardares os meus estatutos e os meus juízos,',
        'Também confirmarei o trono do teu reino, conforme a aliança que fiz com Davi, teu pai, dizendo: Não te faltará sucessor que domine em Israel.',
        'Porém se vós vos desviardes, e deixardes os meus estatutos, e os meus mandamentos, que vos tenho proposto, e fordes, e servirdes a outros deuses, e vos prostrardes a eles,',
        'Então os arrancarei da minha terra que lhes dei, e lançarei da minha presença esta casa que consagrei ao meu nome, e farei com que seja por provérbio e motejo entre todos os povos.',
        'E desta casa, que é tão exaltada, qualquer que passar por ela se espantará e dirá: Por que fez o Senhor assim com esta terra e com esta casa?',
        'E dirão: Porque deixaram ao Senhor Deus de seus pais, que os tirou da terra do Egito, e se deram a outros deuses, e se prostraram a eles, e os serviram; por isso ele trouxe sobre eles todo este mal.',
      ],
      [
        'E sucedeu, ao fim de vinte anos, nos quais Salomão edificou a casa do SENHOR, e a sua própria casa,',
        'Que Salomão edificou as cidades que Hirão lhe tinha dado; e fez habitar nelas os filhos de Israel.',
        'Depois foi Salomão a Hamate-Zobá, e a tomou.',
        'Também edificou a Tadmor no deserto, e todas as cidades de provisões, que edificou em Hamate.',
        'Edificou também a alta Bete-Horom, e a baixa Bete-Horom; cidades fortes, com muros, portas e ferrolhos;',
        'Como também a Baalate, e todas as cidades de provisões, que Salomão tinha, e todas as cidades dos carros e as cidades dos cavaleiros; e tudo quanto, conforme ao seu desejo, Salomão quis edificar em Jerusalém, e no Líbano, e em toda a terra do seu domínio.',
        'Quanto a todo o povo, que tinha ficado dos heteus, amorreus, perizeus, heveus e jebuseus, que não eram de Israel,',
        'Dos seus filhos, que ficaram depois deles na terra, os quais os filhos de Israel não destruíram, Salomão os fez tributários, até ao dia de hoje.',
        'Porém, dos filhos de Israel, Salomão não fez servos para sua obra (mas eram homens de guerra, chefes dos seus capitàes, e capitàes dos seus carros e cavaleiros),',
        'Destes, pois, eram os chefes dos oficiais que o rei Salomão tinha, duzentos e cinqüenta, que presidiam sobre o povo.',
        'E Salomão fez subir a filha de Faraó da cidade de Davi para a casa que lhe tinha edificado; porque disse: Minha mulher não morará na casa de Davi, rei de Israel, porquanto santos são os lugares nos quais entrou a arca do Senhor.',
        'Então Salomão ofereceu holocaustos ao Senhor, sobre o altar do Senhor, que tinha edificado diante do pórtico,',
        'E isto segundo a ordem de cada dia, fazendo ofertas conforme o mandamento de Moisés, nos sábados e nas luas novas, e nas solenidades, três vezes no ano; na festa dos pães ázimos, na festa das semanas, e na festa das tendas.',
        'Também, conforme à ordem de Davi seu pai, designou as turmas dos sacerdotes para seus ministérios, como também as dos levitas acerca dos seus cargos, para louvarem e ministrarem diante dos sacerdotes, segundo o que estava ordenado para cada dia, e os porteiros pelas suas turmas a cada porta; porque assim tinha mandado Davi, o homem de Deus.',
        'E não se desviaram do mandado do rei aos sacerdotes e levitas, em negócio nenhum, nem acerca dos tesouros.',
        'Assim se preparou toda a obra de Salomão, desde o dia da fundação da casa do Senhor, até se acabar; e assim se concluiu a casa do Senhor.',
        'Então foi Salomão a Eziom-Geber, e a Elote, à praia do mar, na terra de Edom.',
        'E enviou-lhe Hirão, por meio de seus servos, navios, e servos práticos do mar, e foram com os servos de Salomão a Ofir, e tomaram de lá quatrocentos e cinqüenta talentos de ouro; e os trouxeram ao rei Salomão.',
      ],
      [
        'E ouvindo a rainha de Sabá a fama de Salomão, veio a Jerusalém, para prová-lo com questões difíceis, com um grande séquito, e com camelos carregados de especiarias; ouro em abundância e pedras preciosas; e foi a Salomão, e falou com ele de tudo o que tinha no seu coração.',
        'E Salomão lhe respondeu a todas as suas questões; e não houve nada que não lhe pudesse esclarecer.',
        'Vendo, pois, a rainha de Sabá a sabedoria de Salomão, e a casa que edificara;',
        'E as iguarias da sua mesa, o assentar dos seus servos, o estar dos seus criados, e as vestes deles; e os seus copeiros e as vestes deles; e a sua subida pela qual ele chegava à casa do Senhor, ela ficou como fora de si.',
        'Então disse ao rei: Era verdade a palavra que ouvi na minha terra acerca dos teus feitos e da tua sabedoria.',
        'Porém não cria naquelas palavras, até que vim, e meus olhos o viram, e eis que não me disseram a metade da grandeza da tua sabedoria; sobrepujaste a fama que ouvi.',
        'Bem-aventurados os teus homens, e bem-aventurados estes teus servos, que estão sempre diante de ti, e ouvem a tua sabedoria!',
        'Bendito seja o Senhor teu Deus, que se agradou de ti para te colocar no seu trono como rei para o Senhor teu Deus; porque teu Deus ama a Israel, para estabelecê-lo perpetuamente; por isso te constituiu rei sobre eles para fazeres juízo e justiça.',
        'E deu ao rei cento e vinte talentos de ouro, e especiarias em grande abundância, e pedras preciosas; e nunca houve tais especiarias, quais a rainha de Sabá deu ao rei Salomão.',
        'E também os servos de Hirão e os servos de Salomão, que de Ofir tinham trazido ouro, trouxeram madeira de algumins, e pedras preciosas.',
        'E, da madeira de algumins, o rei fez balaústres, para a casa do Senhor, e para a casa do rei, como também harpas e saltérios para os cantores, quais nunca dantes se viram na terra de Judá.',
        'E o rei Salomão deu à rainha de Sabá tudo quanto ela desejou, e tudo quanto lhe pediu, mais do que ela mesma trouxera ao rei. Assim voltou e foi para a sua terra, ela e os seus servos.',
        'E o peso do ouro, que vinha em um ano a Salomão, era de seiscentos e sessenta e seis talentos de ouro,',
        'Afora o que os negociantes e mercadores traziam; também todos os reis da Arábia, e os governadores da mesma terra traziam a Salomão ouro e prata.',
        'Também fez o rei Salomão duzentos paveses de ouro batido; para cada pavês destinou seiscentos siclos de ouro batido.',
        'Como também trezentos escudos de ouro batido; para cada escudo destinou trezentos siclos de ouro; e Salomão os pôs na casa do bosque do Líbano.',
        'Fez mais o rei um grande trono de marfim, e o revestiu de ouro puro.',
        'E o trono tinha seis degraus, e um estrado de ouro, que eram ligados ao trono, e encostos de ambos os lados no lugar do assento; e dois leões estavam junto aos encostos.',
        'E doze leões estavam ali de ambos os lados, sobre os seis degraus; outro tal não se fez em nenhum reino.',
        'Também todas as taças do rei Salomão eram de ouro, e todos os vasos da casa do bosque do Líbano, de ouro puro; a prata reputava-se por nada nos dias de Salomão.',
        'Porque, indo os navios do rei com os servos de Hirão, a Társis, voltavam os navios de Társis, uma vez em três anos, e traziam ouro e prata, marfim, bugios e pavões.',
        'Assim excedeu o rei Salomão a todos os reis da terra, em riquezas e sabedoria.',
        'E todos os reis da terra buscavam a presença de Salomão, para ouvirem a sabedoria que Deus tinha posto no seu coração.',
        'E cada um trazia o seu presente, vasos de prata, e vasos de ouro, e roupas, armaduras, especiarias, cavalos e mulas; assim faziam de ano em ano.',
        'Teve também Salomão quatro mil estrebarias para os cavalos de seus carros, e doze mil cavaleiros; e colocou-os nas cidades dos carros, e junto ao rei em Jerusalém.',
        'E dominava sobre todos os reis, desde o rio até à terra dos filisteus, e até ao termo do Egito.',
        'Também o rei fez que houvesse prata em Jerusalém como pedras, e cedros em tanta abundância como as figueiras bravas que há pelas campinas.',
        'E do Egito e de todas aquelas terras traziam cavalos a Salomão.',
        'Os demais atos de Salomão, tanto os primeiros como os últimos, porventura não estão escritos no livro das crônicas de Natã, o profeta, e na profecia de Aías, o silonita, e nas visões de Ido, o vidente, acerca de Jeroboão, filho de Nebate?',
        'E reinou Salomão em Jerusalém quarenta anos sobre todo o Israel.',
        'E dormiu Salomão com seus pais, e o sepultaram na cidade de Davi seu pai; e Roboão, seu filho, reinou em seu lugar.',
      ],
      [
        'E foi Roboão a Siquém, porque todo o Israel se reunira ali, para fazê-lo rei.',
        'Sucedeu que, ouvindo-o Jero-boão, filho de Nebate (o qual estava então no Egito para onde fugira da presença do rei Salomão), voltou do Egito,',
        'Porque enviaram a ele, e o chamaram; e vieram, Jeroboão e todo o Israel, e falaram a Roboão dizendo:',
        'Teu pai fez duro o nosso jugo; agora, pois, alivia tu a dura servidão de teu pai, e o pesado jugo que nos impôs, e nós te serviremos.',
        'E ele lhes disse: Daqui a três dias voltai a mim. Então o povo se foi.',
        'E tomou Roboão conselho com os anciãos, que estiveram perante Salomão seu pai, enquanto viveu, dizendo: Como aconselhais vós que se responda a este povo?',
        'E eles lhe falaram, dizendo: Se te fizeres benigno e afável para com este povo, e lhes falares boas palavras, todos os dias serão teus servos.',
        'Porém ele deixou o conselho que os anciãos lhe deram; e tomou conselho com os jovens, que haviam crescido com ele, e estavam perante ele.',
        'E disse-lhes: Que aconselhais vós, que respondamos a este povo, que me falou, dizendo: Alivia o jugo que teu pai nos impôs?',
        'E os jovens, que com ele haviam crescido, lhe falaram, dizendo: Assim dirás a este povo, que te falou: Teu pai agravou o nosso jugo, tu porém alivia-nos; assim, pois, lhe falarás: O meu dedo mínimo é mais grosso do que os lombos de meu pai.',
        'Assim que, se meu pai vos carregou de um jugo pesado, eu ainda aumentarei o vosso jugo; meu pai vos castigou com açoites, porém eu vos castigarei com escorpiões.',
        'Veio, pois, Jeroboão, e todo o povo, ao terceiro dia, a Roboão, como o rei havia ordenado, dizendo: Voltai a mim ao terceiro dia.',
        'E o rei lhes respondeu asperamente; porque o rei Roboão deixara o conselho dos anciãos.',
        'E falou-lhes conforme o conselho dos jovens, dizendo: Meu pai agravou o vosso jugo, porém eu o aumentarei mais; meu pai vos castigou com açoites, porém eu vos castigarei com escorpiões.',
        'Assim o rei não deu ouvidos ao povo, porque esta mudança vinha de Deus, para que o Senhor confirmasse a sua palavra, a qual falara pelo ministério de Aías, o silonita, a Jeroboão, filho de Nebate.',
        'Vendo, pois, todo o Israel, que o rei não lhe dava ouvidos, tornou-lhe o povo a responder, dizendo: Que parte temos nós com Davi? Já não temos herança no filho de Jessé. Cada um à sua tenda, ó Israel! Olha agora pela tua casa, ó Davi. Assim todo o Israel se foi para as suas tendas.',
        'Porém, quanto aos filhos de Israel, que habitavam nas cidades de Judá, sobre eles reinou Roboão.',
        'Então o rei Roboão enviou a Hadorão, que tinha cargo dos tributos; porém os filhos de Israel o apedrejaram, e ele morreu. Então o rei Roboão se esforçou para subir ao seu carro, e fugiu para Jerusalém.',
        'Assim se rebelaram os israelitas contra a casa de Davi, até ao dia de hoje.',
      ],
      [
        'Vindo, pois, Roboão a Jerusalém, reuniu, da casa de Judá e Benjamim, cento e oitenta mil escolhidos, destros na guerra, para pelejarem contra Israel, e para restituírem o reino a Roboão.',
        'Porém a palavra do Senhor veio a Semaías, homem de Deus, dizendo:',
        'Fala a Roboão, filho de Salomão, rei de Judá, e a todo o Israel, em Judá e Benjamim, dizendo:',
        'Assim diz o Senhor: Não subireis, nem pelejareis contra os vossos irmãos; volte cada um à sua casa; porque de mim proveio isto. E ouviram as palavras do Senhor, e desistiram de ir contra Jeroboão.',
        'E Roboão habitou em Jerusalém; e para defesa, edificou cidades em Judá.',
        'Edificou, pois, a Belém, a Etã, e a Tecoa,',
        'E a Bete-Zur, a Socó, a Adulão,',
        'E a Gate, a Maressa, a Zife,',
        'E a Adoraim, a Laquis, e a Azeca,',
        'E a Zorá, a Aijalom, e a Hebrom, que estavam em Judá e em Benjamim; cidades fortes.',
        'E fortificou estas fortalezas e pôs nelas capitàes, e armazéns de víveres, de azeite, e de vinho.',
        'E pôs em cada cidade paveses e lanças; fortificou-as grandemente; e Judá e Benjamim pertenceram-lhe.',
        'Também os sacerdotes e os levitas, que havia em todo o Israel, se reuniram a ele de todos os seus termos.',
        'Porque os levitas deixaram os seus arrabaldes, e a sua possessão, e vieram a Judá e a Jerusalém, porque Jeroboão e seus filhos os lançaram fora para que não ministrassem ao Senhor.',
        'E ele constituiu para si sacerdotes, para os altos, para os demônios, e para os bezerros, que fizera.',
        'Depois desses também, de todas as tribos de Israel, os que deram o seu coração a buscarem ao Senhor Deus de Israel, vieram a Jerusalém, para oferecerem sacrifícios ao Senhor Deus de seus pais.',
        'Assim fortaleceram o reino de Judá e corroboraram a Roboão, filho de Salomão, por três anos; porque três anos andaram no caminho de Davi e Salomão.',
        'E Roboão tomou para si, por mulher, a Maalate, filha de Jerimote, filho de Davi; e Abiail, filha de Eliabe, filho de Jessé.',
        'A qual lhe deu filhos: Jeús, Samarias e Zaã.',
        'E depois dela tomou a Maaca, filha de Absalão; esta lhe deu Abias, Atai, Ziza e Selomite.',
        'E amava Roboão mais a Maaca, filha de Absalão, do que a todas as suas outras mulheres e concubinas; porque ele tinha tomado dezoito mulheres, e sessenta concubinas; e gerou vinte e oito filhos, e sessenta filhas.',
        'E Roboão designou Abias, filho de Maaca, para ser chefe e líder entre os seus irmãos, porque queria fazê-lo rei.',
        'E usou de prudência, e de todos os seus filhos, alguns espalhou por todas as terras de Judá, e Benjamim, por todas as cidades fortes; e deu-lhes víveres em abundância; e lhes procurou muitas mulheres.',
      ],
      [
        'Sucedeu que, havendo Roboão confirmado o reino, e havendo-se fortalecido, deixou a lei do SENHOR, e com ele todo o Israel.',
        'E sucedeu que, no quinto ano do rei Roboão, Sisaque, rei do Egito, subiu contra Jerusalém (porque tinham transgredido contra o Senhor)',
        'Com mil e duzentos carros e com sessenta mil cavaleiros; e era inumerável o povo que vinha com ele do Egito, de líbios, suquitas e etíopes.',
        'E tomou as cidades fortificadas, que Judá tinha; e chegou até Jerusalém.',
        'Então veio Semaías, o profeta, a Roboão e aos príncipes de Judá que se ajuntaram em Jerusalém por causa de Sisaque, e disse-lhes: Assim diz o Senhor: Vós me deixastes a mim, por isso também eu vos deixei na mão de Sisaque.',
        'Então se humilharam os príncipes de Israel, e o rei, e disseram: O Senhor é justo.',
        'Vendo, pois, o Senhor que se humilhavam, veio a palavra do Senhor a Semaías, dizendo: Humilharam-se, não os destruirei; antes em breve lhes darei algum socorro, para que o meu furor não se derrame sobre Jerusalém, por mão de Sisaque.',
        'Porém serão seus servos; para que conheçam a diferença da minha servidão e da servidão dos reinos da terra.',
        'Subiu, pois, Sisaque, rei do Egito, contra Jerusalém, e tomou os tesouros da casa do Senhor, e os tesouros da casa do rei; levou tudo; também tomou os escudos de ouro, que Salomão fizera.',
        'E fez o rei Roboão em lugar deles escudos de cobre, e os entregou na mão dos chefes da guarda, que guardavam a porta da casa do rei.',
        'E todas as vezes que o rei entrava na casa do Senhor, vinham os da guarda, e os levavam; depois tornavam a pô-los na câmara da guarda.',
        'E humilhando-se ele, a ira do Senhor se desviou dele, para que não o destruísse de todo; porque em Judá ainda havia boas coisas.',
        'Fortificou-se, pois, o rei Roboão em Jerusalém, e reinou; porque Roboão era da idade de quarenta e um anos, quando começou a reinar; e reinou dezessete anos em Jerusalém, a cidade que o Senhor escolheu, dentre todas as tribos de Israel, para pôr ali o seu nome; e era o nome de sua mãe Naamá, amonita.',
        'E fez o que era mau; porquanto não preparou o seu coração para buscar ao Senhor.',
        'Os atos, pois, de Roboão, assim os primeiros, como os últimos, porventura não estão escritos nos livros de Semaías, o profeta, e de Ido, o vidente, na relação das genealogias? E houve guerras entre Roboão e Jeroboão em todos os seus dias.',
        'E Roboão dormiu com seus pais, e foi sepultado na cidade de Davi; e Abias, seu filho, reinou em seu lugar.',
      ],
      [
        'No ano décimo oitavo do rei Jeroboão, Abias começou a reinar sobre Judá.',
        'Três anos reinou em Jerusalém; e era o nome de sua mãe Micaía, filha de Uriel de Gibeá; e houve guerra entre Abias e Jeroboão.',
        'E Abias ordenou a peleja com um exército de valentes guerreiros, quatrocentos mil homens escolhidos; e Jeroboão dispôs contra ele a batalha com oitocentos mil homens escolhidos, todos homens corajosos.',
        'E pôs-se Abias em pé em cima do monte de Zemaraim, que está na montanha de Efraim, e disse: Ouvi-me, Jeroboão e todo o Israel:',
        'Porventura não vos convém saber que o Senhor Deus de Israel deu para sempre a Davi a soberania sobre Israel, a ele e a seus filhos, por uma aliança de sal?',
        'Contudo levantou-se Jeroboão, filho de Nebate, servo de Salomão, filho de Davi, e se rebelou contra seu senhor.',
        'E ajuntaram-se a ele homens vadios, filhos de Belial; e fortificaram-se contra Roboão, filho de Salomão, sendo Roboão ainda jovem, e terno de coração, e não lhes podia resistir.',
        'E agora julgais que podeis resistir ao reino do Senhor, que está na mão dos filhos de Davi, visto que sois uma grande multidão, e tendes convosco os bezerros de ouro que Jeroboão vos fez para deuses.',
        'Não lançastes vós fora os sacerdotes do Senhor, os filhos de Arão, e os levitas, e não fizestes para vós sacerdotes, como os povos das outras terras? Qualquer que vem a consagrar-se com um novilho e sete carneiros logo se faz sacerdote daqueles que não são deuses.',
        'Porém, quanto a nós, o Senhor é nosso Deus, e nunca o deixamos; e os sacerdotes que ministram ao Senhor são filhos de Arão, e os levitas se ocupam na sua obra.',
        'E queimam ao Senhor cada manhã e cada tarde holocaustos, incenso aromático, com os pães da proposição sobre a mesa pura, e o castiçal de ouro, e as suas lâmpadas para se acenderem cada tarde, porque nós temos cuidado do serviço do Senhor nosso Deus; porém vós o deixastes.',
        'E eis que Deus está conosco, à nossa frente, como também os seus sacerdotes, tocando com as trombetas, para dar alarme contra vós. Ó filhos de Israel, não pelejeis contra o Senhor Deus de vossos pais; porque não prosperareis.',
        'Mas Jeroboão armou uma emboscada, para dar sobre eles pela retaguarda; de maneira que estavam em frente de Judá e a emboscada por detrás deles.',
        'Então Judá olhou, e eis que tinham que pelejar por diante e por detrás; então clamaram ao Senhor; e os sacerdotes tocaram as trombetas.',
        'E os homens de Judá gritaram; e sucedeu que, gritando os homens de Judá, Deus feriu a Jeroboão e a todo o Israel diante de Abias e de Judá.',
        'E os filhos de Israel fugiram de diante de Judá; e Deus os entregou na sua mão.',
        'De maneira que Abias e o seu povo fizeram grande matança entre eles; porque caíram feridos de Israel quinhentos mil homens escolhidos.',
        'E foram humilhados os filhos de Israel naquele tempo; e os filhos de Judá prevaleceram, porque confiaram no Senhor Deus de seus pais.',
        'E Abias perseguiu Jeroboão; e tomou-lhe a Betel com os lugares da sua jurisdição, e a Jesana com os lugares da sua jurisdição, e a Efrom com os lugares da sua jurisdição.',
        'E Jeroboão não recobrou mais o seu poder nos dias de Abias; porém o Senhor o feriu, e morreu.',
        'Abias, porém, se fortificou, e tomou para si catorze mulheres, e gerou vinte e dois filhos e dezesseis filhas.',
        'Os demais atos de Abias, tanto os seus caminhos como as suas palavras, estão escritos na história do profeta Ido.',
      ],
      [
        'E Abias dormiu com seus pais, e o sepultaram na cidade de Davi, e Asa, seu filho, reinou em seu lugar; nos seus dias esteve a terra em paz dez anos.',
        'E Asa fez o que era bom e reto aos olhos do Senhor seu Deus.',
        'Porque tirou os altares dos deuses estranhos, e os altos; e quebrou as imagens, e cortou os bosques.',
        'E mandou a Judá que buscasse ao Senhor Deus de seus pais, e que observasse a lei e o mandamento.',
        'Também tirou de todas as cidades de Judá os altos e as imagens; e sob ele o reino esteve em paz.',
        'E edificou cidades fortificadas em Judá; porque a terra estava quieta, e não havia guerra contra ele naqueles anos; porquanto o Senhor lhe dera repouso.',
        'Disse, pois, a Judá: Edifiquemos estas cidades, e cerquemo-las de muros e torres, portas e ferrolhos, enquanto a terra ainda é nossa, pois buscamos ao Senhor nosso Deus; buscamo-lo, e deu-nos repouso de todos os lados. Edificaram, pois, e prosperaram.',
        'Tinha Asa um exército de trezentos mil de Judá, que traziam pavês e lança; e duzentos e oitenta mil de Benjamim, que traziam escudo e atiravam com arco; todos estes eram homens valentes.',
        'E Zerá, o etíope, saiu contra eles, com um exército de um milhão e com trezentos carros, e chegou até Maressa.',
        'Então Asa saiu contra ele; e ordenaram a batalha no vale de Zefatá, junto a Maressa.',
        'E Asa clamou ao Senhor seu Deus, e disse: Senhor, nada para ti é ajudar, quer o poderoso quer o de nenhuma força; ajuda-nos, pois, Senhor nosso Deus, porque em ti confiamos, e no teu nome viemos contra esta multidão. Senhor, tu és nosso Deus, não prevaleça contra ti o homem.',
        'E o Senhor feriu os etíopes diante de Asa e diante de Judá; e os etíopes fugiram.',
        'E Asa, e o povo que estava com ele os perseguiram até Gerar, e caíram tantos dos etíopes, que já não havia neles resistência alguma; porque foram destruídos diante do Senhor, e diante do seu exército; e levaram dali mui grande despojo.',
        'E feriram todas as cidades nos arredores de Gerar, porque o terror do Senhor veio sobre elas; e saquearam todas as cidades, porque havia nelas muita presa.',
        'Também feriram as malhadas do gado; e levaram ovelhas em abundância, e camelos, e voltaram para Jerusalém.',
      ],
      [
        'Então veio o Espírito de Deus sobre Azarias, filho de Odede.',
        'E saiu ao encontro de Asa, e disse-lhe: Ouvi-me, Asa, e todo o Judá e Benjamim: O Senhor está convosco, enquanto vós estais com ele, e, se o buscardes, o achareis; porém, se o deixardes, vos deixará.',
        'E Israel esteve por muitos dias sem o verdadeiro Deus, e sem sacerdote que o ensinasse, e sem lei.',
        'Mas quando na sua angústia voltaram para o Senhor Deus de Israel, e o buscaram, o acharam.',
        'E naqueles tempos não havia paz, nem para o que saía, nem para o que entrava, mas muitas perturbações sobre todos os habitantes daquelas terras.',
        'Porque nação contra nação e cidade contra cidade se despedaçavam; porque Deus os perturbara com toda a angústia.',
        'Mas esforçai-vos, e não desfaleçam as vossas mãos; porque a vossa obra tem uma recompensa.',
        'Ouvindo, pois, Asa estas palavras, e a profecia do profeta Odede, cobrou ânimo e tirou as abominações de toda a terra, de Judá e de Benjamim, como também das cidades que tomara nas montanhas de Efraim, e renovou o altar do Senhor, que estava diante do pórtico do Senhor.',
        'E reuniu a todo o Judá, e Benjamim, e com eles os estrangeiros de Efraim e Manassés, e de Simeão; porque muitos de Israel tinham passado a ele, vendo que o Senhor seu Deus era com ele.',
        'E ajuntaram-se em Jerusalém no terceiro mês; no ano décimo do reinado de Asa.',
        'E no mesmo dia ofereceram em sacrifício ao Senhor, do despojo que trouxeram, setecentos bois e sete mil ovelhas.',
        'E entraram na aliança para buscarem o Senhor Deus de seus pais, com todo o seu coração, e com toda a sua alma;',
        'E de que todo aquele que não buscasse ao Senhor Deus de Israel, morresse; assim o menor como o maior, tanto o homem como a mulher.',
        'E juraram ao Senhor, em alta voz, com júbilo e com trombetas e buzinas.',
        'E todo o Judá se alegrou deste juramento; porque de todo o seu coração juraram, e de toda a sua vontade o buscaram, e o acharam; e o Senhor lhes deu repouso ao redor.',
        'E também a Maaca, sua mãe, o rei Asa depôs, para que não fosse mais rainha, porquanto fizera um horrível ídolo, a Aserá; e Asa destruiu o seu horrível ídolo, e o despedaçou, e o queimou junto ao ribeiro de Cedrom.',
        'Os altos, porém, não foram tirados de Israel; contudo o coração de Asa foi perfeito todos os seus dias.',
        'E trouxe, à casa de Deus, as coisas consagradas por seu pai, e as coisas que ele mesmo tinha consagrado: prata, ouro e vasos.',
        'E não houve guerra até ao ano trigésimo quinto do reinado de Asa.',
      ],
      [
        'No trigésimo sexto ano do reinado de Asa, Baasa, rei de Israel, subiu contra Judá e edificou a Ramá, para não deixar ninguém sair, nem chegar a Asa, rei de Judá.',
        'Então Asa tirou a prata e o ouro dos tesouros da casa do Senhor, e da casa do rei; e enviou servos a Ben-Hadade, rei da Síria, que habitava em Damasco, dizendo:',
        'Acordo há entre mim e ti, como houve entre meu pai e o teu; eis que te envio prata e ouro; vai, pois, e anula o teu acordo com Baasa, rei de Israel, para que se retire de sobre mim.',
        'E Ben-Hadade deu ouvidos ao rei Asa, e enviou os capitães dos seus exércitos, contra as cidades de Israel, e eles feriram a Ijom, a Dã, a Abel-Maim, e a todas as cidades-armazéns de Naftali.',
        'E sucedeu que, ouvindo-o Baasa, deixou de edificar a Ramá, e não continuou a sua obra.',
        'Então o rei Asa tomou a todo o Judá, e levaram as pedras de Ramá, e a sua madeira, com que Baasa edificara; e com elas edificou a Geba e a Mizpá.',
        'Naquele mesmo tempo veio Hanani, o vidente, a Asa, rei de Judá, e disse-lhe: Porquanto confiaste no rei da Síria, e não confiaste no Senhor teu Deus, por isso o exército do rei da Síria escapou da tua mão.',
        'Porventura não foram os etíopes e os líbios um grande exército, com muitíssimos carros e cavaleiros? Confiando tu, porém, no Senhor, ele os entregou nas tuas mãos.',
        'Porque, quanto ao Senhor, seus olhos passam por toda a terra, para mostrar-se forte para com aqueles cujo coração é perfeito para com ele; nisto, pois, procedeste loucamente porque desde agora haverá guerras contra ti.',
        'Porém Asa se indignou contra o vidente, e lançou-o na casa do tronco; porque estava enfurecido contra ele, por causa disto; também Asa, no mesmo tempo, oprimiu a alguns do povo.',
        'E eis que os atos de Asa, tanto os primeiros, como os últimos, estão escritos no livro dos reis de Judá e Israel.',
        'E, no ano trinta e nove do seu reinado, Asa caiu doente de seus pés, a sua doença era em extremo grave; contudo, na sua enfermidade, não buscou ao Senhor, mas antes os médicos.',
        'E Asa dormiu com seus pais; e morreu no ano quarenta e um do seu reinado.',
        'E o sepultaram no seu sepulcro, que tinha cavado para si na cidade de Davi, havendo-o deitado na cama, que se enchera de perfumes e especiarias preparadas segundo a arte dos perfumistas; e, destas coisas fizeram-lhe uma grande queima.',
      ],
      [
        'E Jeosafá, seu filho, reinou em seu lugar; e fortificou-se contra Israel.',
        'E pôs soldados em todas as cidades fortificadas de Judá, e estabeleceu guarnições na terra de Judá, como também nas cidades de Efraim, que Asa seu pai tinha tomado.',
        'E o Senhor era com Jeosafá; porque andou nos primeiros caminhos de Davi seu pai, e não buscou a Baalins.',
        'Antes buscou ao Deus de seu pai, andou nos seus mandamentos, e não segundo as obras de Israel.',
        'E o Senhor confirmou o reino na sua mão, e todo o Judá deu presentes a Jeosafá, o qual teve riquezas e glória em abundância.',
        'E exaltou-se o seu coração nos caminhos do Senhor e, ainda mais, tirou os altos e os bosques de Judá.',
        'E no terceiro ano do seu reinado enviou ele os seus príncipes, a Bene-Hail, a Obadias, a Zacarias, a Natanael e a Micaías, para ensinarem nas cidades de Judá.',
        'E com eles os levitas, Semaías, Netanias, Zebadias, Asael, Semiramote, Jônatas, Adonias, Tobias e Tobe-Adonias e, com estes levitas, os sacerdotes, Elisama e Jeorão.',
        'E ensinaram em Judá, levando consigo o livro da lei do Senhor; e foram a todas as cidades de Judá, ensinando entre o povo.',
        'E veio o temor do Senhor sobre todos os reinos das terras, que estavam ao redor de Judá, e não guerrearam contra Jeosafá.',
        'E alguns dentre os filisteus traziam presentes a Jeosafá, e prata como tributo; também os árabes lhe trouxeram gado miúdo; sete mil e setecentos carneiros, e sete mil e setecentos bodes.',
        'Cresceu, pois, Jeosafá grandemente em extremo e edificou fortalezas e cidades de provisões em Judá.',
        'E teve muitas obras nas cidades de Judá, e homens de guerra e valentes, em Jerusalém.',
        'E este é o número deles segundo as suas casas paternas; em Judá eram capitàes dos milhares: o chefe Adna, e com ele trezentos mil homens valentes;',
        'E depois dele o capitão Joanã, e com ele duzentos e oitenta mil;',
        'E depois Amasias, filho de Zicri, que voluntariamente se entregou ao Senhor, e com ele duzentos mil homens valentes;',
        'E de Benjamim, Eliada, homem valente, e com ele duzentos mil, armados de arco e de escudo;',
        'E depois dele Jozabade, e com ele cento e oitenta mil, armados para a guerra.',
        'Estes estavam no serviço do rei; afora os que o rei tinha posto nas cidades fortes por todo o Judá.',
      ],
      [
        'Tinha, pois, Jeosafá riquezas e glória em abundância, e aparentou-se com Acabe.',
        'E depois de alguns anos desceu ele para Acabe em Samaria; e Acabe matou ovelhas e bois em abundância, para ele e para o povo que vinha com ele; e o persuadiu a subir com ele a Ramote de Gileade.',
        'Porque Acabe, rei de Israel, disse a Jeosafá, rei de Judá: Irás tu comigo a Ramote de Gileade? E ele lhe disse: Como tu és, serei eu; e o meu povo, como o teu povo; iremos contigo à guerra.',
        'Disse mais Jeosafá ao rei de Israel: Peço-te, consulta hoje a palavra do Senhor.',
        'Então o rei de Israel reuniu os profetas, quatrocentos homens, e disse-lhes: Iremos à guerra contra Ramote de Gileade, ou deixarei de ir? E eles disseram: Sobe; porque Deus a entregará na mão do rei.',
        'Disse, porém, Jeosafá: Não há ainda aqui algum profeta do Senhor, para que o consultemos?',
        'Então o rei de Israel disse a Jeosafá: Ainda há um homem por quem podemos consultar ao Senhor; porém eu o odeio, porque nunca profetiza de mim o que é bom, senão sempre o mal; este é Micaías, filho de Inlá. E disse Jeosafá: Não fale o rei assim.',
        'Então o rei de Israel chamou um oficial, e disse: Traze aqui depressa a Micaías, filho de Inlá.',
        'E o rei de Israel, e Jeosafá, rei de Judá, estavam assentados cada um no seu trono, vestidos com suas roupas reais, e estavam assentados na praça à entrada da porta de Samaria; e todos os profetas profetizavam na sua presença.',
        'E Zedequias, filho de Quenaaná, fez para si uns chifres de ferro, e disse: Assim diz o Senhor: Com estes ferirás aos sírios, até de todo os consumires.',
        'E todos os profetas profetizavam o mesmo, dizendo: Sobe a Ramote de Gileade, e triunfarás; porque o Senhor a dará na mão do rei.',
        'E o mensageiro, que foi chamar a Micaías, falou-lhe, dizendo: Eis que as palavras dos profetas, a uma voz, predizem coisas boas para o rei; seja, pois, também a tua palavra como a de um deles, e fala o que é bom.',
        'Porém Micaías disse: Vive o Senhor, que o que meu Deus me disser, isso falarei.',
        'Vindo, pois, ele ao rei, este lhe disse: Micaías, iremos a Ramote de Gileade à guerra, ou deixaremos de ir? E ele disse: Subi, e triunfarás; e serão dados na vossa mão.',
        'E o rei lhe disse: Até quantas vezes, te conjurarei, para que não me fales senão a verdade em nome do Senhor?',
        'Então disse ele: Vi a todo o Israel disperso pelos montes, como ovelhas que não têm pastor; e disse o SENHOR: Estes não têm senhor; torne cada um em paz para sua casa.',
        'Então o rei de Israel disse a Jeosafá: Não te disse eu, que ele não profetizaria de mim o que é bom, porém sempre o mal?',
        'Disse mais: Ouvi, pois, a palavra do Senhor: Vi ao Senhor assentado no seu trono, e todo o exército celestial em pé à sua mão direita, e à sua esquerda.',
        'E disse o Senhor: Quem persuadirá a Acabe rei de Israel, para que suba, e caia em Ramote de Gileade? Um dizia desta maneira, e outro de outra.',
        'Então saiu um espírito e se apresentou diante do Senhor, e disse: Eu o persuadirei. E o Senhor lhe disse: Com quê?',
        'E ele disse: Eu sairei, e serei um espírito de mentira na boca de todos os seus profetas. E disse o Senhor: Tu o persuadirás, e ainda prevalecerás; sai, e faze-o assim.',
        'Agora, pois, eis que o Senhor pôs um espírito de mentira na boca destes teus profetas; e o Senhor falou o mal a teu respeito.',
        'Então Zedequias, filho de Quenaaná, chegando-se, feriu a Micaías no queixo, e disse: Por que caminho passou de mim o Espírito do Senhor para falar a ti?',
        'E disse Micaías: Eis que o verás naquele dia, quando andares de câmara em câmara, para te esconderes.',
        'Então disse o rei de Israel: Tomai a Micaías, e tornai a levá-lo a Amom, o governador da cidade, e a Joás, filho do rei.',
        'E direis: Assim diz o rei: Colocai este homem na casa do cárcere; e sustentai-o com pão de angústia, e com água de angústia, até que eu volte em paz.',
        'E disse Micaías: Se voltares em paz, o Senhor não tem falado por mim. Disse mais: Ouvi, povos todos!',
        'Subiram, pois, o rei de Israel e Jeosafá, rei de Judá, a Ramote de Gileade.',
        'E disse o rei de Israel a Jeosafá: Disfarçando-me eu, então entrarei na peleja; tu, porém, veste as tuas roupas reais. Disfarçou-se, pois, o rei de Israel, e entraram na peleja.',
        'Deu ordem, porém, o rei da Síria aos capitães dos carros que tinha, dizendo: Não pelejareis nem contra pequeno, nem contra grande; senão só contra o rei de Israel.',
        'Sucedeu que, vendo os capitães dos carros a Jeosafá, disseram: Este é o rei de Israel, e o cercaram para pelejar; porém Jeosafá clamou, e o Senhor o ajudou. E Deus os desviou dele.',
        'Porque sucedeu que, vendo os capitães dos carros, que não era o rei de Israel, deixaram de segui-lo.',
        'Então um homem armou o arco e atirou a esmo, e feriu o rei de Israel entre as junturas e a couraça; então disse ao carreteiro: Dá volta, e tira-me do exército, porque estou gravemente ferido.',
        'E aquele dia cresceu a peleja, mas o rei de Israel susteve-se em pé no carro defronte dos sírios até à tarde; e morreu ao tempo do pôr do sol.',
      ],
      [
        'E Jeosafá, rei de Judá, voltou em paz à sua casa em Jerusalém.',
        'E Jeú, filho de Hanani, o vidente, saiu ao encontro do rei Jeosafá e lhe disse: Devias tu ajudar ao ímpio, e amar aqueles que odeiam ao Senhor? Por isso virá sobre ti grande ira da parte do Senhor.',
        'Boas coisas contudo se acharam em ti; porque tiraste os bosques da terra, e preparaste o teu coração para buscar a Deus.',
        'Habitou, pois, Jeosafá em Jerusalém; e tornou a passar pelo povo desde Berseba até as montanhas de Efraim, e fez com que tornassem ao Senhor Deus de seus pais.',
        'E estabeleceu juízes na terra, em todas as cidades fortificadas, de cidade em cidade.',
        'E disse aos juízes: Vede o que fazeis; porque não julgais da parte do homem, senão da parte do Senhor, e ele está convosco quando julgardes.',
        'Agora, pois, seja o temor do Senhor convosco; guardai-o, e fazei-o; porque não há no Senhor nosso Deus iniqüidade nem acepção de pessoas, nem aceitação de suborno.',
        'E também estabeleceu Jeosafá a alguns dos levitas e dos sacerdotes e dos chefes dos pais de Israel sobre o juízo do Senhor, e sobre as causas judiciais; e voltaram a Jerusalém.',
        'E deu-lhes ordem, dizendo: Assim fazei no temor do Senhor, com fidelidade, e com coração íntegro.',
        'E em toda a diferença que vier a vós de vossos irmãos que habitam nas suas cidades, entre sangue e sangue, entre lei e mandamento, entre estatutos e juízos, admoestai-os, que não se façam culpados para com o Senhor, e não venha grande ira sobre vós, e sobre vossos irmãos; fazei assim, e não vos fareis culpados.',
        'E eis que Amarias, o sumo sacerdote, presidirá sobre vós em todo o negócio do Senhor; e Zebadias, filho de Ismael, líder da casa de Judá, em todo o negócio do rei; também os oficiais, os levitas, estão perante vós; esforçai-vos, pois, e fazei-o; e o Senhor será com os bons.',
      ],
      [
        'E sucedeu que, depois disto, os filhos de Moabe, e os filhos de Amom, e com eles outros dos amonitas, vieram à peleja contra Jeosafá.',
        'Então vieram alguns que avisaram a Jeosafá, dizendo: Vem contra ti uma grande multidão dalém do mar e da Síria; e eis que já estão em Hazazom-Tamar, que é En-Gedi.',
        'Então Jeosafá temeu, e pôs-se a buscar o Senhor, e apregoou jejum em todo o Judá.',
        'E Judá se ajuntou, para pedir socorro ao Senhor; também de todas as cidades de Judá vieram para buscar ao Senhor.',
        'E pôs-se Jeosafá em pé na congregação de Judá e de Jerusalém, na casa do Senhor, diante do pátio novo.',
        'E disse: Ah! Senhor Deus de nossos pais, porventura não és tu Deus nos céus? Não és tu que dominas sobre todos os reinos das nações? Na tua mão há força e potência, e não há quem te possa resistir.',
        'Porventura, ó nosso Deus, não lançaste fora os moradores desta terra de diante do teu povo Israel, e não a deste para sempre à descendência de Abraão, teu amigo?',
        'E habitaram nela e edificaram-te nela um santuário ao teu nome, dizendo:',
        'Se algum mal nos sobrevier, espada, juízo, peste, ou fome, nós nos apresentaremos diante desta casa e diante de ti, pois teu nome está nesta casa, e clamaremos a ti na nossa angústia, e tu nos ouvirás e livrarás.',
        'Agora, pois, eis que os filhos de Amom, e de Moabe e os das montanhas de Seir, pelos quais não permitiste passar a Israel, quando vinham da terra do Egito, mas deles se desviaram e não os destruíram,',
        'Eis que nos dão o pago, vindo para lançar-nos fora da tua herança, que nos fizeste herdar.',
        'Ah! nosso Deus, porventura não os julgarás? Porque em nós não há força perante esta grande multidão que vem contra nós, e não sabemos o que faremos; porém os nossos olhos estão postos em ti.',
        'E todo o Judá estava em pé perante o Senhor, como também as suas crianças, as suas mulheres, e os seus filhos.',
        'Então veio o Espírito do Senhor, no meio da congregação, sobre Jaaziel, filho de Zacarias, filho de Benaia, filho de Jeiel, filho de Matanias, levita, dos filhos de Asafe,',
        'E disse: Dai ouvidos todo o Judá, e vós, moradores de Jerusalém, e tu, ó rei Jeosafá; assim o Senhor vos diz: Não temais, nem vos assusteis por causa desta grande multidão; pois a peleja não é vossa, mas de Deus.',
        'Amanhã descereis contra eles; eis que sobem pela ladeira de Ziz, e os achareis no fim do vale, diante do deserto de Jeruel.',
        'Nesta batalha não tereis que pelejar; postai-vos, ficai parados, e vede a salvação do Senhor para convosco, ó Judá e Jerusalém. Não temais, nem vos assusteis; amanhã saí-lhes ao encontro, porque o Senhor será convosco.',
        'Então Jeosafá se prostrou com o rosto em terra, e todo o Judá e os moradores de Jerusalém se lançaram perante o Senhor, adorando-o.',
        'E levantaram-se os levitas, dos filhos dos coatitas, e dos filhos dos coratitas, para louvarem ao Senhor Deus de Israel, com voz muito alta.',
        'E pela manhã cedo se levantaram e saíram ao deserto de Tecoa; e, ao saírem, Jeosafá pôs-se em pé, e disse: Ouvi-me, ó Judá, e vós, moradores de Jerusalém: Crede no Senhor vosso Deus, e estareis seguros; crede nos seus profetas, e prosperareis;',
        'E aconselhou-se com o povo, e ordenou cantores para o Senhor, que louvassem à Majestade santa, saindo diante dos armados, e dizendo: Louvai ao Senhor porque a sua benignidade dura para sempre.',
        'E, quando começaram a cantar e a dar louvores, o Senhor pôs emboscadas contra os filhos de Amom e de Moabe e os das montanhas de Seir, que vieram contra Judá, e foram desbaratados.',
        'Porque os filhos de Amom e de Moabe se levantaram contra os moradores das montanhas de Seir, para os destruir e exterminar; e, acabando eles com os moradores de Seir, ajudaram uns aos outros a destruir-se.',
        'Nisso chegou Judá à atalaia do deserto; e olharam para a multidão, e eis que eram corpos mortos, que jaziam em terra, e nenhum escapou.',
        'E vieram Jeosafá e o seu povo para saquear os seus despojos, e acharam entre eles riquezas e cadáveres em abundância, assim como objetos preciosos; e tomaram para si tanto, que não podiam levar; e três dias saquearam o despojo, porque era muito.',
        'E ao quarto dia se ajuntaram no vale de Beraca; pois ali louvaram ao Senhor. Por isso chamaram aquele lugar o vale de Beraca, até ao dia de hoje.',
        'Então voltaram todos os homens de Judá e de Jerusalém, e Jeosafá à frente deles, e tornaram a Jerusalém com alegria; porque o Senhor os alegrara sobre os seus inimigos.',
        'E vieram a Jerusalém com saltérios, com harpas e com trombetas, para a casa do Senhor.',
        'E veio o temor de Deus sobre todos os reinos daquelas terras, ouvindo eles que o Senhor havia pelejado contra os inimigos de Israel.',
        'E o reino de Jeosafá ficou quieto; e o seu Deus lhe deu repouso ao redor.',
        'E Jeosafá reinou sobre Judá; era da idade de trinta e cinco anos quando começou a reinar e vinte e cinco anos reinou em Jerusalém; e o nome de sua mãe era Azuba, filha de Sili.',
        'E andou no caminho de Asa, seu pai, e não se desviou dele, fazendo o que era reto aos olhos do Senhor.',
        'Contudo os altos não foram tirados porque o povo não tinha ainda disposto o seu coração para com o Deus de seus pais.',
        'Ora, o restante dos atos de Jeosafá, assim, desde os primeiros até os últimos, eis que está escrito nas notas de Jeú, filho de Hanani, que as inseriu no livro dos reis de Israel.',
        'Porém, depois disto, Jeosafá, rei de Judá, se aliou com Acazias, rei de Israel, que procedeu com toda a impiedade.',
        'E aliou-se com ele, para fazerem navios que fossem a Társis; e fizeram os navios em Eziom-Geber.',
        'Porém Eliezer, filho de Dodava, de Maressa, profetizou contra Jeosafá, dizendo: Porquanto te aliaste com Acazias, o Senhor despedaçou as tuas obras. E os navios se quebraram, e não puderam ir a Társis.',
      ],
      [
        'Depois Jeosafá dormiu com seus pais, e foi sepultado junto a eles na cidade de Davi; e Jeorão, seu filho, reinou em seu lugar.',
        'E teve irmãos, filhos de Jeosafá: Azarias, Jeiel, Zacarias, Asarias, Micael e Sefatias; todos estes foram filhos de Jeosafá, rei de Israel.',
        'E seu pai lhes deu muitos presentes de prata, de ouro e de coisas preciosíssimas, juntamente com cidades fortificadas em Judá; porém, o reino, deu a Jeorão, porquanto era o primogênito.',
        'E, subindo Jeorão ao reino de seu pai, e havendo-se fortificado, matou a todos os seus irmãos à espada, como também a alguns dos príncipes de Israel.',
        'Da idade de trinta e dois anos era Jeorão, quando começou a reinar; e reinou oito anos em Jerusalém.',
        'E andou no caminho dos reis de Israel, como fazia a casa de Acabe; porque tinha a filha de Acabe por mulher; e fazia o que era mau aos olhos do Senhor.',
        'Porém o Senhor não quis destruir a casa de Davi, em atenção à aliança que tinha feito com Davi; e porque também tinha falado que lhe daria por todos os dias uma lâmpada, a ele e a seus filhos.',
        'Nos seus dias se revoltaram os edomitas contra o mando de Judá, e constituíram para si um rei.',
        'Por isso Jeorão passou adiante com os seus príncipes, e todos os carros com ele; levantou-se de noite, e feriu aos edomeus, que o tinham cercado, como também aos capitães dos carros.',
        'Todavia os edomitas se revoltaram contra o mando de Judá até ao dia de hoje; então no mesmo tempo Libna se revoltou contra o seu mando; porque deixara ao Senhor Deus de seus pais.',
        'Ele também fez altos nos montes de Judá; e fez com que se corrompessem os moradores de Jerusalém, e até a Judá impeliu a isso.',
        'Então lhe veio um escrito da parte de Elias, o profeta, que dizia: Assim diz o Senhor Deus de Davi teu pai: Porquanto não andaste nos caminhos de Jeosafá, teu pai, e nos caminhos de Asa, rei de Judá,',
        'Mas andaste no caminho dos reis de Israel, e fizeste prostituir a Judá e aos moradores de Jerusalém, segundo a prostituição da casa de Acabe, e também mataste a teus irmãos da casa de teu pai, melhores do que tu;',
        'Eis que o Senhor ferirá com um grande flagelo ao teu povo, aos teus filhos, às tuas mulheres e a todas as tuas fazendas.',
        'Tu também terás grande enfermidade por causa de uma doença em tuas entranhas, até que elas saiam, de dia em dia, por causa do mal.',
        'Despertou, pois, o Senhor, contra Jeorão o espírito dos filisteus e dos árabes, que estavam do lado dos etíopes.',
        'Estes subiram a Judá, e deram sobre ela, e levaram todos os bens que se achou na casa do rei, como também a seus filhos e a suas mulheres; de modo que não lhe deixaram filho algum, senão a Jeoacaz, o mais moço de seus filhos.',
        'E depois de tudo isto o Senhor o feriu nas suas entranhas com uma enfermidade incurável.',
        'E sucedeu que, depois de muito tempo, ao fim de dois anos, saíram-lhe as entranhas por causa da doença; e morreu daquela grave enfermidade; e o seu povo não lhe queimou aroma como queimara a seus pais.',
        'Era da idade de trinta e dois anos quando começou a reinar, e reinou oito anos em Jerusalém; e foi sem deixar de si saudades; e sepultaram-no na cidade de Davi, porém não nos sepulcros dos reis.',
      ],
      [
        'E os moradores de Jerusalém, em lugar de Jeorão, fizeram rei a Acazias, seu filho mais moço, porque a tropa, que viera com os árabes ao arraial, tinha matado a todos os mais velhos. Assim reinou Acazias, filho de Jeorão, rei de Judá.',
        'Era da idade de quarenta e dois anos, quando começou a reinar, e reinou um ano em Jerusalém; e era o nome de sua mãe Atalia, filha de Onri.',
        'Também ele andou nos caminhos da casa de Acabe, porque sua mãe era sua conselheira, para proceder impiamente.',
        'E fez o que era mau aos olhos do Senhor, como a casa de Acabe, porque eles eram seus conselheiros depois da morte de seu pai, para a sua perdição.',
        'Também andou nos conselhos deles, e foi com Jorão, filho de Acabe, rei de Israel, à peleja contra Hazael, rei da Síria, junto a Ramote de Gileade; e os sírios feriram a Jorão.',
        'E voltou para curar-se em Jizreel, das feridas que lhe fizeram em Ramá, pelejando contra Hazael, rei da Síria; e Acazias, filho de Jeorão, rei de Judá, desceu para ver a Jorão, filho de Acabe, em Jizreel, porque estava doente.',
        'Foi, pois, da vontade de Deus, que Acazias, para sua ruína, visitasse Jorão; porque chegando ele, saiu com Jorão contra Jeú, filho de Ninsi, a quem o Senhor tinha ungido para desarraigar a casa de Acabe.',
        'E sucedeu que, executando Jeú juízo contra a casa de Acabe, achou os príncipes de Judá e os filhos dos irmãos de Acazias, que serviam a Acazias, e os matou.',
        'Depois buscou a Acazias (porque se tinha escondido em Samaria), e o alcançaram, e o trouxeram a Jeú, e o mataram, e o sepultaram; porque disseram: Filho é de Jeosafá, que buscou ao Senhor com todo o seu coração. E já não tinha a casa de Acazias ninguém que tivesse força para o reino.',
        'Vendo, pois, Atalia, mãe de Acazias, que seu filho era morto, levantou-se e destruiu toda a descendência real da casa de Judá.',
        'Porém Jeosabeate, filha do rei, tomou a Joás, filho de Acazias, furtando-o dentre os filhos do rei, aos quais matavam, e o pôs com a sua ama na câmara dos leitos; assim Jeosabeate, filha do rei Jeorão, mulher do sacerdote Joiada (porque era irmã de Acazias), o escondeu de Atalia, de modo que ela não o matou.',
        'E esteve com eles seis anos escondido na casa de Deus; e Atalia reinou sobre a terra.',
      ],
      [
        'Porém no sétimo ano Joiada se animou, e tomou consigo em aliança os chefes de cem, a Azarias, filho de Jeroão, a Ismael, filho de Joanã, a Azarias, filho de Obede, a Maaséias, filho de Adaías, e a Elisafate, filho de Zicri.',
        'Estes percorreram a Judá e ajuntaram os levitas de todas as cidades de Judá e os chefes dos pais de Israel, e vieram para Jerusalém.',
        'E toda aquela congregação fez aliança com o rei na casa de Deus; e Joiada lhes disse: Eis que o filho do rei reinará, como o Senhor falou a respeito dos filhos de Davi.',
        'Isto é o que haveis de fazer; uma terça parte de vós, ou seja, dos sacerdotes e dos levitas que entram no sábado, serão guardas das portas;',
        'E uma terça parte estará na casa do rei; e a outra terça parte à porta do fundamento; e todo o povo estará nos pátios da casa do Senhor.',
        'Porém ninguém entre na casa do Senhor, senão os sacerdotes e os levitas que ministram; estes entrarão, porque são santos; mas todo o povo fará a guarda diante do Senhor.',
        'E os levitas cercarão o rei de todos os lados, cada um com as suas armas na mão; e qualquer que entrar na casa será morto; porém vós estareis com o rei, quando entrar e quando sair.',
        'E fizeram os levitas e todo o Judá conforme a tudo o que ordenara o sacerdote Joiada; e tomou cada um os seus homens, tanto os que entravam no sábado como os que saíam no sábado; porque o sacerdote Joiada não tinha despedido as turmas.',
        'Também o sacerdote Joiada deu aos capitàes de cem as lanças, os escudos e as rodelas que foram do rei Davi, os quais estavam na casa de Deus.',
        'E dispôs todo o povo, a cada um com as suas armas na mão, desde o lado direito da casa até o lado esquerdo da casa, do lado do altar e da casa, em redor do rei.',
        'Então tiraram para fora ao filho do rei, e lhe puseram a coroa; deram-lhe o testemunho, e o fizeram rei; e Joiada e seus filhos o ungiram, e disseram: Viva o rei!',
        'Ouvindo, pois, Atalia a voz do povo que concorria e louvava o rei, veio ao povo, à casa do Senhor.',
        'E olhou, e eis que o rei estava junto à coluna, à entrada, e os principes e as trombetas junto ao rei; e todo o povo da terra estava alegre e tocava as trombetas; e também os cantores tocavam instrumentos musicais, e dirigiam o cantar de louvores; então Atalia rasgou os seus vestidos, e clamou: Traição, traição!',
        'Porém o sacerdote Joiada trouxe para fora os centuriões que estavam postos sobre o exército e disse-lhes: Tirai-a para fora das fileiras, e o que a seguir, morrerá à espada; porque dissera o sacerdote: Não a mateis na casa do Senhor.',
        'E lançaram mão dela; e ela foi pelo caminho da entrada da porta dos cavalos, à casa do rei, e ali a mataram.',
        'E Joiada fez aliança entre si e o povo e o rei, para que fossem o povo do Senhor.',
        'Depois todo o povo entrou na casa de Baal, e a derrubaram, e quebraram os seus altares, e as suas imagens, e a Matã, sacerdote de Baal, mataram diante dos altares.',
        'E Joiada ordenou os ofícios na casa do Senhor, sob a direção dos sacerdotes levitas a quem Davi designara na casa do Senhor, para oferecerem os holocaustos do Senhor, como está escrito na lei de Moisés, com alegria e com canto, conforme a instituição de Davi.',
        'E pôs porteiros às portas da casa do Senhor, para que nela não entrasse ninguém imundo em coisa alguma.',
        'E tomou os centuriões, os poderosos, os que tinham domínio entre o povo e todo o povo da terra, e conduziram o rei da casa do Senhor, e entraram na casa do rei passando pela porta maior, e assentaram-no no trono real.',
        'E todo o povo da terra se alegrou, e a cidade ficou em paz, depois que mataram a Atalia à espada.',
      ],
      [
        'Tinha Joás sete anos de idade quando começou a reinar, e quarenta anos reinou em Jerusalém; e era o nome da sua mãe Zíbia, de Berseba.',
        'E fez Joás o que era reto aos olhos do Senhor, todos os dias do sacerdote Joiada.',
        'E tomou-lhe Joiada duas mulheres, e gerou filhos e filhas.',
        'E, depois disto, Joás resolveu renovar a casa do Senhor.',
        'Reuniu, pois, os sacerdotes e os levitas, e disse-lhes: Saí pelas cidades de Judá, e levantai dinheiro de todo o Israel para reparar a casa do vosso Deus de ano em ano; e vós, apressai este negócio. Porém os levitas não se apressaram.',
        'E o rei chamou a Joiada, o chefe, e disse-lhe: Por que não requereste dos levitas, que trouxessem de Judá e de Jerusalém o tributo que Moisés, servo do Senhor, ordenou à congregação de Israel, para a tenda do testemunho?',
        'Porque, sendo Atalia ímpia, seus filhos arruinaram a casa de Deus, e até todas as coisas sagradas da casa do Senhor empregaram em Baalins.',
        'E o rei, pois, deu ordem e fizeram um cofre, e o puseram fora, à porta da casa do Senhor.',
        'E publicou-se em Judá e em Jerusalém que trouxessem ao Senhor o tributo de Moisés, o servo de Deus, ordenado a Israel no deserto.',
        'Então todos os príncipes e todo o povo se alegraram, e o trouxeram e o lançaram no cofre, até que ficou cheio.',
        'E sucedia que, quando levavam o cofre pelas mãos dos levitas, segundo o mandado do rei, e vendo-se que já havia muito dinheiro, vinha o escrivão do rei, e o oficial do sumo sacerdote, e esvaziavam o cofre, e tomavam-no e levavam-no de novo ao seu lugar; assim faziam de dia em dia, e ajuntaram dinheiro em abundância.',
        'O qual o rei e Joiada davam aos que tinham o encargo da obra do serviço da casa do Senhor; e contrataram pedreiros e carpinteiros, para renovarem a casa do Senhor; como também ferreiros e serralheiros, para repararem a casa do Senhor.',
        'E os que tinham o encargo da obra faziam com que o trabalho de reparação fosse crescendo pelas suas mãos; e restauraram a casa de Deus no seu estado, e a fortaleceram.',
        'E, depois de acabarem, trouxeram ao rei e a Joiada o resto do dinheiro, e dele fizeram utensílios para a casa do Senhor, objetos para ministrar e oferecer, colheres, vasos de ouro e de prata. E continuamente sacrificaram holocaustos na casa do Senhor, todos os dias de Joiada.',
        'E envelheceu Joiada, e morreu farto de dias; era da idade de cento e trinta anos quando morreu.',
        'E o sepultaram na cidade de Davi com os reis; porque tinha feito bem em Israel, e para com Deus e a sua casa.',
        'Porém, depois da morte de Joiada vieram os príncipes de Judá e prostraram-se perante o rei; e o rei os ouviu.',
        'E deixaram a casa do Senhor Deus de seus pais, e serviram às imagens do bosque e aos ídolos. Então, por causa desta sua culpa, veio grande ira sobre Judá e Jerusalém.',
        'Porém enviou profetas entre eles, para os reconduzir ao Senhor, os quais protestaram contra eles; mas eles não deram ouvidos.',
        'E o Espírito de Deus revestiu a Zacarias, filho do sacerdote Joiada, o qual se pôs em pé acima do povo, e lhes disse: Assim diz Deus: Por que transgredis os mandamentos do Senhor, de modo que não possais prosperar? Porque deixastes ao Senhor, também ele vos deixará.',
        'E eles conspiraram contra ele, e o apedrejaram por mandado do rei, no pátio da casa do Senhor.',
        'Assim o rei Joás não se lembrou da beneficência que Joiada, pai de Zacarias, lhe fizera; porém matou-lhe o filho, o qual, morrendo, disse: O Senhor o verá, e o requererá.',
        'E sucedeu que, decorrido um ano, o exército da Síria subiu contra ele; e vieram a Judá e a Jerusalém, e destruíram dentre o povo a todos os seus príncipes; e enviaram todo o seu despojo ao rei de Damasco.',
        'Porque ainda que o exército dos sírios viera com poucos homens, contudo o Senhor entregou na sua mão um exército mui numeroso, porquanto deixaram ao Senhor Deus de seus pais. Assim executaram juízos contra Joás.',
        'E, quando os sírios se retiraram, deixaram-no gravemente ferido; então seus servos conspiraram contra ele por causa do sangue do filho do sacerdote Joiada, e o feriram na sua cama, e morreu; e o sepultaram na cidade de Davi, porém não nos sepulcros dos reis.',
        'Estes, pois, foram os que conspiraram contra ele; Zabade, filho de Simeate, a amonita, e Jeozabade, filho de Sinrite, a moabita.',
        'E, quanto a seus filhos, e à grandeza do cargo que se lhe impôs, e à restauração da casa de Deus, eis que estão escritos no livro da história dos reis; e Amazias, seu filho, reinou em seu lugar.',
      ],
      [
        'Era Amazias da idade de vinte e cinco anos, quando começou a reinar, e reinou vinte e nove anos em Jerusalém; e era o nome de sua mãe Joadã, de Jerusalém.',
        'E fez o que era reto aos olhos do Senhor, porém não com inteireza de coração.',
        'Sucedeu que, sendo-lhe o reino já confirmado, matou a seus servos que mataram o rei seu pai;',
        'Porém não matou os filhos deles; mas fez segundo está escrito na lei, no livro de Moisés, como o Senhor ordenou, dizendo: Não morrerão os pais pelos filhos, nem os filhos pelos pais; mas cada um morrerá pelo seu pecado.',
        'E Amazias reuniu a Judá e os pôs segundo as casas dos pais, sob capitàes de milhares, e sob capitàes de cem, por todo o Judá e Benjamim; e os contou, de vinte anos para cima, e achou deles trezentos mil escolhidos que podiam sair à guerra, e manejar lança e escudo.',
        'Também de Israel tomou a soldo cem mil homens valentes, por cem talentos de prata.',
        'Porém um homem de Deus veio a ele, dizendo: Ó rei, não deixes ir contigo o exército de Israel; porque o Senhor não é com Israel, a saber com os filhos de Efraim.',
        'Se quiseres ir, faze-o assim, esforça-te para a peleja. Deus, porém, te fará cair diante do inimigo; porque força há em Deus para ajudar e para fazer cair.',
        'E disse Amazias ao homem de Deus: Que se fará, pois, dos cem talentos de prata que dei às tropas de Israel? E disse o homem de Deus: Mais tem o Senhor que te dar do que isso.',
        'Então separou Amazias as tropas que lhe tinham vindo de Efraim, para que se fossem ao seu lugar; pelo que se acendeu a sua ira contra Judá, e voltaram para as suas casas ardendo em ira.',
        'Esforçou-se, pois, Amazias, e conduziu o seu povo, e foi ao Vale do Sal; onde feriu a dez mil dos filhos de Seir.',
        'Também os filhos de Judá prenderam vivos dez mil, e os levaram ao cume da rocha; e do mais alto da rocha os lançaram abaixo, e todos se despedaçaram.',
        'Porém os homens das tropas que Amazias despedira, para que não fossem com ele à peleja, deram sobre as cidades de Judá desde Samaria, até Bete-Horom; e feriram deles três mil, e saquearam grande despojo.',
        'E sucedeu que, depois que Amazias veio da matança dos edomitas e trouxe consigo os deuses dos filhos de Seir, tomou-os por seus deuses, e prostrou-se diante deles, e queimou-lhes incenso.',
        'Então a ira do Senhor se acendeu contra Amazias, e mandou-lhe um profeta que lhe disse: Por que buscaste deuses deste povo, os quais não livraram o seu próprio povo da tua mão?',
        'E sucedeu que, falando ele ao rei, este lhe respondeu: Puseram-te por conselheiro do rei? Cala-te! Por que haveria de ser ferido? Então parou o profeta, e disse: Bem vejo eu que já Deus deliberou destruir-te; porquanto fizeste isto, e não deste ouvidos ao meu conselho.',
        'E, tendo tomado conselho, Amazias, rei de Judá, mandou dizer a Jeoás, filho de Jeoacaz, filho de Jeú, rei de Israel: Vem, vejamo-nos face a face.',
        'Porém Jeoás, rei de Israel, mandou dizer a Amazias, rei de Judá: O cardo que estava no Líbano mandou dizer ao cedro que estava no Líbano: Dá tua filha por mulher a meu filho; porém os animais do campo, que estavam no Líbano passaram e pisaram o cardo.',
        'Tu dizes: Eis que tenho ferido os edomitas; e elevou-se o teu coração, para te gloriares; agora, pois, fica em tua casa; por que te entremeterias no mal, para caíres tu e Judá contigo?',
        'Porém Amazias não lhe deu ouvidos, porque isto vinha de Deus, para entregá-los na mão dos seus inimigos; porquanto buscaram os deuses dos edomitas.',
        'E Jeoás, rei de Israel, subiu; e ele e Amazias, rei de Judá, viram-se face a face em Bete-Semes, que está em Judá.',
        'E Judá foi ferido diante de Israel; e fugiu cada um para a sua tenda.',
        'E Jeoás, rei de Israel, prendeu a Amazias, rei de Judá, filho de Joás, o filho de Jeoacaz, em Bete-Semes, e o trouxe a Jerusalém; e derrubou o muro de Jerusalém, desde a porta de Efraim até à porta da esquina, quatrocentos côvados.',
        'Também tomou todo o ouro, a prata, e todos os utensílios que se acharam na casa de Deus com Obede-Edom, e os tesouros da casa do rei, e os reféns; e voltou para Samaria.',
        'E viveu Amazias, filho de Joás, rei de Judá, depois da morte de Jeoás, filho de Jeoacaz, rei de Israel, quinze anos.',
        'Quanto ao mais dos atos de Amazias, tanto os primeiros como os últimos, eis que, porventura, não estão escritos no livro dos reis de Judá e de Israel?',
        'E desde o tempo em que Amazias se desviou do Senhor, conspiraram contra ele em Jerusalém, porém ele fugiu para Laquis; mas perseguiram-no até Laquis, e o mataram ali.',
        'E trouxeram-no sobre cavalos e sepultaram-no com seus pais na cidade de Judá.',
      ],
      [
        'Então todo o povo tomou a Uzias, que tinha dezesseis anos, e o fizeram rei em lugar de Amazias seu pai.',
        'Este edificou a Elote, e a restituiu a Judá, depois que o rei dormiu com seus pais.',
        'Tinha Uzias dezesseis anos quando começou a reinar, e cinqüenta e dois anos reinou em Jerusalém; e era o nome de sua mãe Jecolia, de Jerusalém.',
        'E fez o que era reto aos olhos do Senhor; conforme a tudo o que fizera Amazias seu pai.',
        'Porque deu-se a buscar a Deus nos dias de Zacarias, que era entendido nas visões de Deus; e nos dias em que buscou ao Senhor, Deus o fez prosperar.',
        'Porque saiu e guerreou contra os filisteus, e quebrou o muro de Gate, o muro de Jabne, e o muro de Asdode; e edificou cidades em Asdode, e entre os filisteus.',
        'E Deus o ajudou contra os filisteus e contra os árabes que habitavam em Gur-Baal, e contra os meunitas.',
        'E os amonitas deram presentes a Uzias; e o seu nome foi espalhado até à entrada do Egito, porque se fortificou altamente.',
        'Também Uzias edificou torres em Jerusalém, à porta da esquina, e à porta do vale, e à porta do ângulo, e as fortificou.',
        'Também edificou torres no deserto, e cavou muitos poços, porque tinha muito gado, tanto nos vales como nas campinas; tinha lavradores, e vinhateiros, nos montes e nos campos férteis; porque era amigo da agricultura.',
        'Tinha também Uzias um exército de homens destros na guerra, que saíam à guerra em tropas, segundo o número da resenha feita por mão de Jeiel, o escrivão, e Maaséias, oficial, sob a direção de Hananias, um dos capitàes do rei.',
        'O total dos chefes dos pais, homens valentes, era de dois mil e seiscentos.',
        'E debaixo das suas ordens havia um exército guerreiro de trezentos e sete mil e quinhentos homens, que faziam a guerra com força belicosa, para ajudar o rei contra os inimigos.',
        'E preparou Uzias, para todo o exército, escudos, lanças, capacetes, couraças e arcos, e até fundas para atirar pedras.',
        'Também fez em Jerusalém máquinas da invenção de engenheiros, que estivessem nas torres e nos cantos, para atirarem flechas e grandes pedras; e propagou a sua fama até muito longe; porque foi maravilhosamente ajudado, até que se fortificou.',
        'Mas, havendo-se já fortificado, exaltou-se o seu coração até se corromper; e transgrediu contra o Senhor seu Deus, porque entrou no templo do Senhor para queimar incenso no altar do incenso.',
        'Porém o sacerdote Azarias entrou após ele, e com ele oitenta sacerdotes do Senhor, homens valentes.',
        'E resistiram ao rei Uzias, e lhe disseram: A ti, Uzias, não compete queimar incenso perante o Senhor, mas aos sacerdotes, filhos de Arão, que são consagrados para queimar incenso; sai do santuário, porque transgrediste; e não será isto para honra tua da parte do Senhor Deus.',
        'Então Uzias se indignou; e tinha o incensário na sua mão para queimar incenso. Indignando-se ele, pois, contra os sacerdotes, a lepra lhe saiu à testa perante os sacerdotes, na casa do Senhor, junto ao altar do incenso.',
        'Então o sumo sacerdote Azarias olhou para ele, como também todos os sacerdotes, e eis que já estava leproso na sua testa, e apressuradamente o lançaram fora; e até ele mesmo se deu pressa a sair, visto que o Senhor o ferira.',
        'Assim ficou leproso o rei Uzias até ao dia da sua morte; e morou, por ser leproso, numa casa separada, porque foi excluído da casa do Senhor. E Jotão, seu filho, tinha o encargo da casa do rei, julgando o povo da terra.',
        'Quanto ao mais dos atos de Uzias, tanto os primeiros como os últimos, o profeta Isaías, filho de Amós, o escreveu.',
        'E dormiu Uzias com seus pais, e o sepultaram com eles no campo do sepulcro que era dos reis; porque disseram: Leproso é. E Jotão, seu filho, reinou em seu lugar.',
      ],
      [
        'Tinha Jotão vinte e cinco anos de idade, quando começou a reinar, e reinou dezesseis anos em Jerusalém; e era o nome de sua mãe Jerusa, filha de Zadoque.',
        'E fez o que era reto aos olhos do Senhor, conforme a tudo o que fizera Uzias, seu pai, exceto que não entrou no templo do Senhor. E o povo ainda se corrompia.',
        'Ele edificou a porta superior da casa do Senhor, e também edificou muitas obras sobre o muro de Ofel.',
        'Também edificou cidades nas montanhas de Judá, e castelos e torres nos bosques.',
        'Ele também guerreou contra o rei dos filhos de Amom, e prevaleceu sobre eles, de modo que os filhos de Amom naquele ano lhe deram cem talentos de prata, e dez mil coros de trigo, e dez mil de cevada; isto lhe trouxeram os filhos de Amom também no segundo e no terceiro ano.',
        'Assim se fortificou Jotão, porque dirigiu os seus caminhos na presença do Senhor seu Deus.',
        'Ora, o restante dos atos de Jotão, e todas as suas guerras e os seus caminhos, eis que estão escritos no livro dos reis de Israel e de Judá.',
        'Tinha vinte e cinco anos de idade, quando começou a reinar, e reinou dezesseis anos em Jerusalém.',
        'E dormiu Jotão com seus pais, e sepultaram-no na cidade de Davi; e Acaz, seu filho, reinou em seu lugar.',
      ],
      [
        'Tinha Acaz vinte anos de idade, quando começou a reinar, e dezesseis anos reinou em Jerusalém; e não fez o que era reto aos olhos do SENHOR, como Davi, seu pai.',
        'Antes andou nos caminhos dos reis de Israel, e, além disso, fez imagens fundidas a Baalins.',
        'Também queimou incenso no vale do filho de Hinom, e queimou a seus filhos no fogo, conforme as abominações dos gentios que o Senhor tinha expulsado de diante dos filhos de Israel.',
        'Também sacrificou, e queimou incenso nos altos e nos outeiros, como também debaixo de toda a árvore verde.',
        'Por isso o Senhor seu Deus o entregou na mão do rei dos sírios, os quais o feriram, e levaram dele em cativeiro uma grande multidão de presos, que trouxeram a Damasco; também foi entregue na mão do rei de Israel, o qual lhe infligiu grande derrota.',
        'Porque Peca, filho de Remalias, matou em Judá, num só dia, cento e vinte mil, todos homens valentes; porquanto deixaram ao Senhor Deus de seus pais.',
        'E Zicri, homem valente de Efraim, matou a Maasias, filho do rei, e a Azricão, o mordomo, e a Elcana, o segundo depois do rei.',
        'E os filhos de Israel levaram presos de seus irmãos duzentos mil, mulheres, filhos e filhas; e também saquearam deles grande despojo, que levaram para Samaria.',
        'Mas estava ali um profeta do Senhor, cujo nome era Obede, o qual saiu ao encontro do exército que vinha para Samaria, e lhe disse: Eis que, irando-se o Senhor Deus de vossos pais contra Judá, os entregou na vossa mão, e vós os matastes com uma raiva tal, que chegou até aos céus.',
        'E agora vós cuidais em sujeitar a vós os filhos de Judá e Jerusalém, como cativos e cativas; porventura não sois vós mesmos culpados contra o Senhor vosso Deus?',
        'Agora, pois, ouvi-me, e tornai a enviar os prisioneiros que trouxestes cativos de vossos irmãos; porque o ardor da ira do Senhor está sobre vós.',
        'Então se levantaram alguns homens dentre os cabeças dos filhos de Efraim, a saber, Azarias, filho de Joanã, Berequias, filho de Mesilemote, Jeizquias, filho de Salum, e Amasa, filho de Hadlai, contra os que voltavam da batalha.',
        'E lhes disseram: Não fareis entrar aqui estes cativos, porque, além da nossa culpa contra o Senhor, vós intentais acrescentar mais a nossos pecados e a nossas culpas, sendo que já temos grande culpa, e já o ardor da ira está sobre Israel.',
        'Então os homens armados deixaram os cativos e o despojo diante dos príncipes e de toda a congregação.',
        'E os homens que foram apontados por seus nomes se levantaram, e tomaram os cativos, e vestiram do despojo a todos os que dentre eles estavam nus; e vestiram-nos, e calçaram-nos, e deram-lhes de comer e de beber, e os ungiram, e a todos os que estavam fracos levaram sobre jumentos, e conduziram-nos a Jericó, à cidade das palmeiras, a seus irmãos. Depois voltaram para Samaria.',
        'Naquele tempo o rei Acaz mandou pedir aos reis da Assíria que o ajudassem.',
        'Porque outra vez os edomitas vieram, e feriram a Judá, e levaram presos em cativeiro.',
        'Também os filisteus deram sobre as cidades da campina e do sul de Judá, e tomaram a Bete-Semes, e a Aijalom, e a Gederote e a Socó, e os lugares da sua jurisdição, e a Timna, e os lugares da sua jurisdição, e a Ginzo, e os lugares da sua jurisdição; e habitaram ali.',
        'Porque o Senhor humilhou a Judá por causa de Acaz, rei de Israel; porque este se houve desenfreadamente em Judá, havendo prevaricado grandemente contra o Senhor.',
        'E veio a ele Tiglate-Pileser, rei da Assíria; porém o pôs em aperto, e não o fortaleceu.',
        'Porque Acaz tomou despojos da casa do Senhor, e da casa do rei, e dos príncipes, e os deu ao rei da Assíria; porém não o ajudou.',
        'E ao tempo em que este o apertou, então ainda mais transgrediu contra o Senhor, tal era o rei Acaz.',
        'Porque sacrificou aos deuses de Damasco, que o feriram e disse: Visto que os deuses dos reis da Síria os ajudam, eu lhes sacrificarei, para que me ajudem a mim. Porém eles foram a sua ruína, e de todo o Israel.',
        'E ajuntou Acaz os utensílios da casa de Deus, e fez em pedaços os utensílios da casa de Deus, e fechou as portas da casa do Senhor, e fez para si altares em todos os cantos de Jerusalém.',
        'Também em cada cidade de Judá fez altos para queimar incenso a outros deuses; assim provocou à ira o Senhor Deus de seus pais.',
        'Ora, o restante dos seus atos e de todos os seus caminhos, tanto os primeiros como os últimos, eis que estão escritos no livro dos reis de Judá e de Israel.',
        'E dormiu Acaz com seus pais, e o sepultaram na cidade, em Jerusalém; porém não o puseram nos sepulcros dos reis de Israel; e Ezequias, seu filho, reinou em seu lugar.',
      ],
      [
        'Tinha Ezequias vinte e cinco anos de idade, quando começou a reinar, e reinou vinte e nove anos em Jerusalém; e era o nome de sua mãe Abia, filha de Zacarias.',
        'E fez o que era reto aos olhos do Senhor, conforme a tudo quanto fizera Davi, seu pai.',
        'Ele, no primeiro ano do seu reinado, no primeiro mês, abriu as portas da casa do Senhor, e as reparou.',
        'E trouxe os sacerdotes, e os levitas, e ajuntou-os na praça oriental,',
        'E lhes disse: Ouvi-me, ó levitas, santificai-vos agora, e santificai a casa do Senhor Deus de vossos pais, e tirai do santuário a imundícia.',
        'Porque nossos pais transgrediram, e fizeram o que era mau aos olhos do Senhor nosso Deus, e o deixaram, e desviaram os seus rostos do tabernáculo do Senhor, e lhe deram as costas.',
        'Também fecharam as portas do alpendre, e apagaram as lâmpadas, e não queimaram incenso nem ofereceram holocaustos no santuário ao Deus de Israel.',
        'Por isso veio grande ira do Senhor sobre Judá e Jerusalém, e os entregou à perturbação, à assolação, e ao escárnio, como vós o estais vendo com os vossos olhos.',
        'Porque eis que nossos pais caíram à espada, e nossos filhos, e nossas filhas, e nossas mulheres; por isso estiveram em cativeiro.',
        'Agora me tem vindo ao coração, que façamos uma aliança com o Senhor Deus de Israel, para que se desvie de nós o ardor da sua ira.',
        'Agora, filhos meus, não sejais negligentes; pois o Senhor vos tem escolhido para estardes diante dele para o servirdes, e para serdes seus ministros e queimadores de incenso.',
        'Então se levantaram os levitas, Maate, filho de Amasai, e Joel, filho de Azarias, dos filhos dos coatitas; e dos filhos de Merari, Quis, filho de Abdi, e Azarias, filho de Jealelel; e dos gersonitas, Joá, filho de Zima, e Éden, filho de Joá;',
        'E dentre os filhos de Elisafã, Sinri e Jeuel; dentre os filhos de Asafe, Zacarias e Matanias;',
        'E dentre os filhos de Hemam, Jeuel e Simei; e dentre os filhos de Jedutum, Semaías e Uziel.',
        'E ajuntaram a seus irmãos, e santificaram-se e vieram conforme ao mandado do rei, pelas palavras do Senhor, para purificarem a casa do Senhor.',
        'E os sacerdotes entraram na casa do Senhor, para a purificar, e tiraram para fora, ao pátio da casa do Senhor, toda a imundícia que acharam no templo do Senhor; e os levitas a tomaram, para a levarem para fora, ao ribeiro de Cedrom.',
        'Começaram, pois, a santificar no primeiro dia, do primeiro mês; e ao oitavo dia do mês vieram ao alpendre do Senhor, e santificaram a casa do Senhor em oito dias; e no dia décimo sexto do primeiro mês acabaram.',
        'Então foram ter com o rei Ezequias, e disseram: Já purificamos toda a casa do Senhor, como também o altar do holocausto com todos os seus utensílios e a mesa da proposição com todos os seus utensílios.',
        'Também todos os objetos que o rei Acaz no seu reinado lançou fora, na sua transgressão, já preparamos e santificamos; e eis que estão diante do altar do Senhor.',
        'Então o rei Ezequias se levantou de madrugada, e reuniu os líderes da cidade, e subiu à casa do Senhor.',
        'E trouxeram sete novilhos e sete carneiros, e sete cordeiros e sete bodes, para sacrifício pelo pecado, pelo reino, e pelo santuário, e por Judá, e disse aos filhos de Arão, os sacerdotes, que os oferecessem sobre o altar do Senhor.',
        'E eles mataram os bois, e os sacerdotes tomaram o sangue e o espargiram sobre o altar; também mataram os carneiros, e espargiram o sangue sobre o altar; semelhantemente mataram os cordeiros, e espargiram o sangue sobre o altar.',
        'Então trouxeram os bodes para sacrifício pelo pecado, perante o rei e a congregação, e lhes impuseram as suas mãos.',
        'E os sacerdotes os mataram, e com o seu sangue fizeram expiação do pecado sobre o altar, para reconciliar a todo o Israel; porque o rei tinha ordenado que se fizesse aquele holocausto e sacrifício pelo pecado, por todo o Israel.',
        'E pôs os levitas na casa do Senhor com címbalos, com saltérios, e com harpas, conforme ao mandado de Davi e de Gade, o vidente do rei, e do profeta Natã; porque este mandado veio do Senhor, por mão de seus profetas.',
        'Estavam, pois, os levitas em pé com os instrumentos de Davi, e os sacerdotes com as trombetas.',
        'E Ezequias deu ordem que oferecessem o holocausto sobre o altar; e ao tempo em que começou o holocausto, começou também o canto do Senhor, com as trombetas e com os instrumentos de Davi, rei de Israel.',
        'E toda a congregação se prostrou, quando entoavam o canto, e as trombetas eram tocadas; tudo isto até o holocausto se acabar.',
        'E acabando de o oferecer, o rei e todos quantos com ele se achavam se prostraram e adoraram.',
        'Então o rei Ezequias e os príncipes disseram aos levitas que louvassem ao Senhor com as palavras de Davi, e de Asafe, o vidente. E o louvaram com alegria e se inclinaram e adoraram.',
        'E respondeu Ezequias, dizendo: Agora vos consagrastes a vós mesmos ao Senhor; chegai-vos e trazei sacrifícios e ofertas de louvor à casa do Senhor. E a congregação trouxe sacrifícios e ofertas de louvor, e todos os dispostos de coração trouxeram holocaustos.',
        'E o número dos holocaustos, que a congregação trouxe, foi de setenta bois, cem carneiros, duzentos cordeiros; tudo isto em holocausto para o Senhor.',
        'Houve, também, de coisas consagradas, seiscentos bois e três mil ovelhas.',
        'Eram, porém, os sacerdotes mui poucos, e não podiam esfolar a todos os holocaustos; pelo que seus irmãos os levitas os ajudaram, até a obra se acabar, e até que os outros sacerdotes se santificaram; porque os levitas foram mais retos de coração, para se santificarem, do que os sacerdotes.',
        'E houve também holocaustos em abundância, com a gordura das ofertas pacíficas, e com as ofertas de libação para os holocaustos. Assim se restabeleceu o ministério da casa do Senhor.',
        'E Ezequias, e todo o povo se alegraram, por causa daquilo que Deus tinha preparado para o povo; porque apressuradamente se fez esta obra.',
      ],
      [
        'Depois disto Ezequias enviou mensageiros por todo o Israel e Judá, e escreveu também cartas a Efraim e a Manassés para que viessem à casa do SENHOR em Jerusalém, para celebrarem a páscoa ao SENHOR Deus de Israel.',
        'Porque o rei tivera conselho com os seus príncipes, e com toda a congregação em Jerusalém, para celebrarem a páscoa no segundo mês.',
        'Porquanto não a puderam celebrar no tempo próprio, porque não se tinham santificado sacerdotes em número suficiente, e o povo não se tinha ajuntado em Jerusalém.',
        'E isto pareceu bem aos olhos do rei, e de toda a congregação.',
        'E ordenaram que se fizesse passar pregão por todo o Israel, desde Berseba até Dã, para que viessem a celebrar a páscoa ao Senhor Deus de Israel, em Jerusalém; porque muitos não a tinham celebrado como estava escrito.',
        'Foram, pois, os correios com as cartas, do rei e dos seus príncipes, por todo o Israel e Judá, segundo o mandado do rei, dizendo: Filhos de Israel, convertei-vos ao Senhor Deus de Abraão, de Isaque e de Israel; para que ele se volte para o restante de vós que escapou da mão dos reis da Assíria.',
        'E não sejais como vossos pais e como vossos irmãos, que transgrediram contra o Senhor Deus de seus pais, pelo que os entregou à desolação como vedes.',
        'Não endureçais agora a vossa cerviz, como vossos pais; dai a mão ao Senhor, e vinde ao seu santuário que ele santificou para sempre, e servi ao Senhor vosso Deus, para que o ardor da sua ira se desvie de vós.',
        'Porque, em vos convertendo ao Senhor, vossos irmãos e vossos filhos acharão misericórdia perante os que os levaram cativos, e tornarão a esta terra; porque o Senhor vosso Deus é misericordioso e compassivo, e não desviará de vós o seu rosto, se vos converterdes a ele.',
        'E os correios foram passando de cidade em cidade, pela terra de Efraim e Manassés até Zebulom; porém riram-se e zombaram deles.',
        'Todavia alguns de Aser, e de Manassés, e de Zebulom, se humilharam, e vieram a Jerusalém.',
        'E a mão de Deus esteve com Judá, dando-lhes um só coração, para fazerem o mandado do rei e dos príncipes, conforme a palavra do Senhor.',
        'E ajuntou-se em Jerusalém muito povo, para celebrar a festa dos pães ázimos, no segundo mês; uma congregação mui grande.',
        'E levantaram-se, e tiraram os altares que havia em Jerusalém; também tiraram todos os altares de incenso, e os lançaram no ribeiro de Cedrom.',
        'Então sacrificaram a páscoa no dia décimo quarto do segundo mês; e os sacerdotes e levitas se envergonharam e se santificaram e trouxeram holocaustos à casa do Senhor.',
        'E puseram-se no seu posto, segundo o seu costume, conforme a lei de Moisés, o homem de Deus; e os sacerdotes espargiam o sangue, tomando-o da mão dos levitas.',
        'Porque havia muitos na congregação que não se tinham santificado; pelo que os levitas tinham o encargo de matarem os cordeiros da páscoa por todo aquele que não estava limpo, para o santificarem ao Senhor.',
        'Porque uma multidão do povo, muitos de Efraim e Manassés, Issacar e Zebulom, não se tinham purificado, e contudo comeram a páscoa, não como está escrito; porém Ezequias orou por eles, dizendo: O Senhor, que é bom, perdoa todo aquele',
        'Que tem preparado o seu coração para buscar ao Senhor Deus, o Deus de seus pais, ainda que não esteja purificado segundo a purificação do santuário.',
        'E ouviu o Senhor a Ezequias, e sarou o povo.',
        'E os filhos de Israel, que se acharam em Jerusalém, celebraram a festa dos pães ázimos sete dias com grande alegria; e os levitas e os sacerdotes louvaram ao Senhor de dia em dia, com estrondosos instrumentos ao Senhor.',
        'E Ezequias falou benignamente a todos os levitas, que tinham bom entendimento no conhecimento do Senhor; e comeram as ofertas da solenidade por sete dias, oferecendo ofertas pacíficas, e louvando ao Senhor Deus de seus pais.',
        'E, tendo toda a congregação conselho para celebrarem outros sete dias, celebraram ainda sete dias com alegria.',
        'Porque Ezequias, rei de Judá, ofereceu à congregação mil novilhos e sete mil ovelhas; e os príncipes ofereceram à congregação mil novilhos e dez mil ovelhas; e os sacerdotes se santificaram em grande número.',
        'E alegraram-se, toda a congregação de Judá, e os sacerdotes, e os levitas, toda a congregação de todos os que vieram de Israel, como também os estrangeiros que vieram da terra de Israel e os que habitavam em Judá.',
        'E houve grande alegria em Jerusalém; porque desde os dias de Salomão, filho de Davi, rei de Israel, tal não houve em Jerusalém.',
        'Então os sacerdotes e os levitas se levantaram e abençoaram o povo; e a sua voz foi ouvida; porque a sua oração chegou até à santa habitação de Deus, até aos céus.',
      ],
      [
        'E acabando tudo isto, todos os israelitas que ali se achavam saíram às cidades de Judá e quebraram as estátuas, cortaram os bosques, e derrubaram os altos e altares por toda Judá e Benjamim, como também em Efraim e Manassés, até que tudo destruíram; então tornaram todos os filhos de Israel, cada um para sua possessão, para as cidades deles.',
        'E estabeleceu Ezequias as turmas dos sacerdotes e levitas, segundo as suas turmas, a cada um segundo o seu ministério; aos sacerdotes e levitas para o holocausto e para as ofertas pacíficas, para ministrarem, louvarem, e cantarem, às portas dos arraiais do Senhor.',
        'Também estabeleceu a parte da fazenda do rei para os holocaustos; para os holocaustos da manhã e da tarde, e para os holocaustos dos sábados, e das luas novas, e das solenidades; como está escrito na lei do Senhor.',
        'E ordenou ao povo, que morava em Jerusalém, que desse a parte dos sacerdotes e levitas, para que eles pudessem se dedicar à lei do Senhor.',
        'E, depois que se divulgou esta ordem, os filhos de Israel trouxeram muitas primícias de trigo, mosto, azeite, mel, e de todo o produto do campo; também os dízimos de tudo trouxeram em abundância.',
        'E os filhos de Israel e de Judá, que habitavam nas cidades de Judá, também trouxeram dízimos dos bois e das ovelhas, e dízimos das coisas dedicadas que foram consagradas ao Senhor seu Deus; e fizeram muitos montões.',
        'No terceiro mês começaram a fazer os primeiros montões; e no sétimo mês acabaram.',
        'Vindo, pois, Ezequias e os príncipes, e vendo aqueles montões, bendisseram ao Senhor e ao seu povo Israel.',
        'E perguntou Ezequias aos sacerdotes e aos levitas acerca daqueles montões.',
        'E Azarias, o sumo sacerdote da casa de Zadoque, lhe respondeu, dizendo: Desde que se começou a trazer estas ofertas à casa do Senhor, temos comido e temos fartado, e ainda sobejou em abundância; porque o Senhor abençoou ao seu povo, e sobejou esta abastança.',
        'Então ordenou Ezequias que se preparassem câmaras na casa do Senhor, e as prepararam.',
        'Ali recolheram fielmente as ofertas, e os dízimos, e as coisas consagradas; e tinham cargo disto Conanias, o levita principal, e Simei, seu irmão, o segundo.',
        'E Jeiel, Azarias, Naate, Asael, Jerimote, Jozabade, Eliel, Ismaquias, Maate, e Benaia, eram superintendentes sob a direção de Conanias e Simei, seu irmão, por mandado do rei Ezequias, e de Azarias, líder da casa de Deus.',
        'E Coré, filho de Imna, o levita, porteiro do lado do oriente, estava encarregado das ofertas voluntárias que se faziam a Deus, para distribuir as ofertas alçadas do Senhor e as coisas santíssimas.',
        'E debaixo das suas ordens estavam Éden, Miniamim, Jesua, Semaías, Amarias e Secanias, nas cidades dos sacerdotes, para distribuírem com fidelidade a seus irmãos, segundo as suas turmas, tanto aos pequenos como aos grandes;',
        'Exceto os que estavam contados pelas genealogias dos homens, da idade de três anos para cima, a todos os que entravam na casa do Senhor, para a obra de cada dia no seu dia, pelo seu ministério nas suas guardas, segundo as suas turmas.',
        'Quanto ao registro dos sacerdotes foi ele feito segundo as suas famílias, e o dos levitas, da idade de vinte anos para cima, foi feito segundo as suas guardas nas suas turmas;',
        'Como também conforme às genealogias, com todas as suas crianças, suas mulheres, e seus filhos, e suas filhas, por toda a congregação. Porque com fidelidade estes se santificavam nas coisas consagradas.',
        'Também dentre os filhos de Arão, os sacerdotes, que estavam nos campos dos arrabaldes das suas cidades, em cada cidade, havia homens que foram designados pelos seus nomes para distribuírem as porções a todo o homem entre os sacerdotes e a todos os que estavam contados entre os levitas.',
        'E assim fez Ezequias em todo o Judá; e fez o que era bom, e reto, e verdadeiro, perante o Senhor seu Deus.',
        'E toda a obra que começou no serviço da casa de Deus, e na lei, e nos mandamentos, para buscar a seu Deus, ele a fez de todo o seu coração, e prosperou.',
      ],
      [
        'Depois destas coisas e desta verdade, veio Senaqueribe, rei da Assíria, e entrou em Judá, e acampou-se contra as cidades fortificadas, e intentou apoderar-se delas.',
        'Vendo, pois, Ezequias que Senaqueribe vinha, e que estava resolvido contra Jerusalém,',
        'Teve conselho com os seus príncipes e os seus homens valentes, para que se tapassem as fontes das águas que havia fora da cidade; e eles o ajudaram.',
        'Assim muito povo se ajuntou, e tapou todas as fontes, como também o ribeiro que se estendia pelo meio da terra, dizendo: Por que viriam os reis da Assíria, e achariam tantas águas?',
        'E ele se animou, e edificou todo o muro quebrado até às torres, e levantou o outro muro por fora; e fortificou a Milo na cidade de Davi, e fez armas e escudos em abundância.',
        'E pôs capitàes de guerra sobre o povo, e reuniu-os na praça da porta da cidade, e falou-lhes ao coração, dizendo:',
        'Esforçai-vos, e tende bom ânimo; não temais, nem vos espanteis, por causa do rei da Assíria, nem por causa de toda a multidão que está com ele, porque há um maior conosco do que com ele.',
        'Com ele está o braço de carne, mas conosco o Senhor nosso Deus, para nos ajudar, e para guerrear por nós. E o povo descansou nas palavras de Ezequias, rei de Judá.',
        'Depois disto Senaqueribe, rei da Assíria, enviou os seus servos a Jerusalém (ele porém estava diante de Laquis, com todas as suas forças), a Ezequias, rei de Judá, e a todo o Judá que estava em Jerusalém, dizendo:',
        'Assim diz Senaqueribe, rei da Assíria: Em que confiais vós, para vos deixardes sitiar em Jerusalém?',
        'Porventura não vos incita Ezequias, para morrerdes à fome e à sede, dizendo: O Senhor nosso Deus nos livrará das mãos do rei da Assíria?',
        'Não é Ezequias o mesmo que tirou os seus altos e os seus altares, e falou a Judá e a Jerusalém, dizendo: Diante de um único altar vos prostrareis, e sobre ele queimareis incenso?',
        'Não sabeis vós o que eu e meus pais fizemos a todos os povos das terras? Porventura puderam de qualquer maneira os deuses das nações daquelas terras livrar o seu país da minha mão?',
        'Qual é, de todos os deuses daquelas nações que meus pais destruíram, o que pôde livrar o seu povo da minha mão, para que vosso Deus vos possa livrar da minha mão?',
        'Agora, pois, não vos engane Ezequias, nem vos incite assim, nem lhe deis crédito; porque nenhum deus de nação alguma, nem de reino algum, pôde livrar o seu povo da minha mão, nem da mão de meus pais; quanto menos vos poderá livrar o vosso Deus da minha mão?',
        'Também seus servos falaram ainda mais contra o Senhor Deus, e contra Ezequias, o seu servo.',
        'Escreveu também cartas, para blasfemar do Senhor Deus de Israel, e para falar contra ele, dizendo: Assim como os deuses das nações das terras não livraram o seu povo da minha mão, assim também o Deus de Ezequias não livrará o seu povo da minha mão.',
        'E clamaram em alta voz em judaico contra o povo de Jerusalém, que estava em cima do muro, para os atemorizar e os perturbar, para que tomassem a cidade.',
        'E falaram do Deus de Jerusalém, como dos deuses dos povos da terra, obras das mãos dos homens.',
        'Porém o rei Ezequias e o profeta Isaías, filho de Amós, oraram contra isso, e clamaram ao céu.',
        'Então o Senhor enviou um anjo que destruiu a todos os homens valentes, e os líderes, e os capitàes no arraial do rei da Assíria; e envergonhado voltou à sua terra; e, entrando na casa de seu deus, alguns dos seus próprios filhos, o mataram ali à espada.',
        'Assim livrou o Senhor a Ezequias, e aos moradores de Jerusalém, da mão de Senaqueribe, rei da Assíria, e da mão de todos; e de todos os lados os guiou.',
        'E muitos traziam a Jerusalém presentes ao Senhor, e coisas preciosíssimas a Ezequias, rei de Judá, de modo que depois disto foi exaltado perante os olhos de todas as nações.',
        'Naqueles dias Ezequias adoeceu mortalmente; e orou ao Senhor, o qual lhe falou, e lhe deu um sinal.',
        'Mas não correspondeu Ezequias ao benefício que lhe fora feito; porque o seu coração se exaltou; por isso veio grande ira sobre ele, e sobre Judá e Jerusalém.',
        'Ezequias, porém, se humilhou pela exaltação do seu coração, ele e os habitantes de Jerusalém; e a grande ira do Senhor não veio sobre eles, nos dias de Ezequias.',
        'E teve Ezequias riquezas e glória em grande abundância; proveu-se de tesouraria para prata, ouro, pedras preciosas, especiarias, escudos, e toda a espécie de objetos desejáveis.',
        'Também de armazéns para a colheita do trigo, e do vinho, e do azeite; e de estrebarias para toda a espécie de animais e de currais para os rebanhos.',
        'Edificou também cidades, e possuiu ovelhas e vacas em abundância; porque Deus lhe tinha dado muitíssimas possessões.',
        'Também o mesmo Ezequias tapou o manancial superior das águas de Giom, e as fez correr por baixo para o ocidente da cidade de Davi; porque Ezequias prosperou em todas as suas obras.',
        'Contudo, no tocante aos embaixadores dos príncipes de babilônia, que foram enviados a ele, a perguntarem acerca do prodígio que se fez naquela terra, Deus o desamparou, para tentá-lo, para saber tudo o que havia no seu coração.',
        'Quanto aos demais atos de Ezequias, e as suas boas obras, eis que estão escritos na visão do profeta Isaías, filho de Amós, e no livro dos reis de Judá e de Israel.',
        'E dormiu Ezequias com seus pais, e o sepultaram no mais alto dos sepulcros dos filhos de Davi; e todo o Judá e os habitantes de Jerusalém lhe fizeram honras na sua morte; e Manassés, seu filho, reinou em seu lugar.',
      ],
      [
        'Tinha Manassés doze anos de idade, quando começou a reinar, e cinqüenta e cinco anos reinou em Jerusalém.',
        'E fez o que era mau aos olhos do Senhor, conforme às abominações dos gentios que o Senhor lançara fora de diante dos filhos de Israel.',
        'Porque tornou a edificar os altos que Ezequias, seu pai, tinha derrubado; e levantou altares aos Baalins, e fez bosques, e prostrou-se diante de todo o exército dos céus, e o serviu.',
        'E edificou altares na casa do Senhor, da qual o Senhor tinha falado: Em Jerusalém estará o meu nome eternamente.',
        'Edificou altares a todo o exército dos céus, em ambos os átrios da casa do Senhor.',
        'Fez ele também passar seus filhos pelo fogo no vale do filho de Hinom, e usou de adivinhações e de agouros, e de feitiçarias, e consultou adivinhos e encantadores, e fez muitíssimo mal aos olhos do Senhor, para o provocar à ira.',
        'Também pôs uma imagem de escultura do ídolo que tinha feito, na casa de Deus, da qual Deus tinha falado a Davi e a Salomão seu filho: Nesta casa e em Jerusalém, que escolhi de todas as tribos de Israel, porei o meu nome para sempre.',
        'E nunca mais removerei o pé de Israel da terra que destinei a vossos pais; contanto que tenham cuidado de fazer tudo o que eu lhes ordenei, conforme a toda a lei, e estatutos, e juízos, dados pela mão de Moisés.',
        'E Manassés tanto fez errar a Judá e aos moradores de Jerusalém, que fizeram pior do que as nações que o Senhor tinha destruído de diante dos filhos de Israel.',
        'E falou o Senhor a Manassés e ao seu povo, porém não deram ouvidos.',
        'Assim o Senhor trouxe sobre eles os capitàes do exército do rei da Assíria, os quais prenderam a Manassés com ganchos e, amarrando-o com cadeias, o levaram para Babilônia.',
        'E ele, angustiado, orou deveras ao Senhor seu Deus, e humilhou-se muito perante o Deus de seus pais;',
        'E fez-lhe oração, e Deus se aplacou para com ele, e ouviu a sua súplica, e tornou a trazê-lo a Jerusalém, ao seu reino. Então conheceu Manassés que o Senhor era Deus.',
        'E depois disto edificou o muro de fora da cidade de Davi, ao ocidente de Giom, no vale, e à entrada da porta do peixe, e ao redor de Ofel, e o levantou muito alto; também pôs capitàes de guerra em todas as cidades fortificadas de Judá.',
        'E tirou da casa do Senhor os deuses estranhos e o ídolo, como também todos os altares que tinha edificado no monte da casa do Senhor, e em Jerusalém, e os lançou fora da cidade.',
        'E reparou o altar do Senhor e ofereceu sobre ele sacrifícios de ofertas pacíficas e de louvor; e ordenou a Judá que servisse ao Senhor Deus de Israel.',
        'Contudo o povo ainda sacrificava nos altos, mas somente ao Senhor seu Deus.',
        'O restante dos atos de Manassés, e a sua oração ao seu Deus, e as palavras dos videntes que lhe falaram no nome do Senhor Deus de Israel, eis que estão nas crônicas dos reis de Israel.',
        'E a sua oração, e como Deus se aplacou para com ele, e todo o seu pecado, e a sua transgressão, e os lugares onde edificou altos, e pôs bosques e imagens de escultura, antes que se humilhasse, eis que estão escritos nos livros dos videntes.',
        'E dormiu Manassés com seus pais, e o sepultaram em sua casa. Amom, seu filho, reinou em seu lugar.',
        'Tinha Amom vinte e dois anos de idade quando começou a reinar, e dois anos reinou em Jerusalém.',
        'E fez o que era mau aos olhos do Senhor, como havia feito Manassés, seu pai; porque Amom sacrificou a todas as imagens de escultura que Manassés, seu pai tinha feito, e as serviu.',
        'Mas não se humilhou perante o Senhor, como Manassés, seu pai, se humilhara; antes multiplicou Amom os seus delitos.',
        'E conspiraram contra ele os seus servos, e o mataram em sua casa.',
        'Porém o povo da terra feriu a todos quantos conspiraram contra o rei Amom; e o povo da terra fez reinar em seu lugar a Josias, seu filho.',
      ],
      [
        'Tinha Josias oito anos quando começou a reinar, e trinta e um anos reinou em Jerusalém.',
        'E fez o que era reto aos olhos do Senhor; e andou nos caminhos de Davi, seu pai, sem se desviar deles nem para a direita nem para a esquerda.',
        'Porque no oitavo ano do seu reinado, sendo ainda moço, começou a buscar o Deus de Davi, seu pai; e no duodécimo ano começou a purificar a Judá e a Jerusalém, dos altos, e dos bosques, e das imagens de escultura e de fundição.',
        'E derrubaram perante ele os altares de Baalins; e despedaçou as imagens, que estavam acima deles; e os bosques, e as imagens de escultura e de fundição quebrou e reduziu a pó, e o espargiu sobre as sepulturas dos que lhes tinham sacrificado.',
        'E os ossos dos sacerdotes queimou sobre os seus altares; e purificou a Judá e a Jerusalém.',
        'O mesmo fez nas cidades de Manassés, e de Efraim, e de Simeão, e ainda até Naftali, em seus lugares assolados ao redor.',
        'E, tendo derrubado os altares, e os bosques, e as imagens de escultura, até reduzi-los a pó, e tendo despedaçado todas as imagens do sol em toda a terra de Israel, então voltou para Jerusalém.',
        'E no ano décimo oitavo do seu reinado, havendo já purificado a terra e a casa, enviou a Safã, filho de Azalias, e a Maaséias, governador da cidade, e a Joá, filho de Joacaz, cronista, para repararem a casa do Senhor seu Deus.',
        'E foram a Hilquias, sumo sacerdote, e deram o dinheiro que se tinha trazido à casa de Deus, e que os levitas, que guardavam a entrada tinham recebido da mão de Manassés, e de Efraim, e de todo o restante de Israel, como também de todo o Judá e Benjamim, e dos habitantes de Jerusalém.',
        'E eles o entregaram aos que tinham o encargo da obra, e superintendiam a casa do Senhor; e estes o deram aos que faziam a obra, e trabalhavam na casa do Senhor, para consertarem e repararem a casa.',
        'E deram-no aos carpinteiros e aos edificadores, para comprarem pedras lavradas, e madeiras para as junturas e para servirem de vigas para as casas que os reis de Judá tinham destruído.',
        'E estes homens trabalhavam fielmente na obra; e os superintendentes sobre eles eram: Jaate e Obadias, levitas, dos filhos de Merari, como também Zacarias e Mesulão, dos filhos dos coatitas, para adiantarem a obra; e todos os levitas que eram entendidos em instrumentos de música.',
        'Estavam também sobre os carregadores e dirigiam todos os que trabalhavam em alguma obra; e dentre os levitas havia escrivães, oficiais e porteiros.',
        'E, tirando eles o dinheiro que se tinha trazido à casa do Senhor, Hilquias, o sacerdote, achou o livro da lei do Senhor, dada pela mão de Moisés.',
        'E Hilquias disse a Safã, o escrivão: Achei o livro da lei na casa do Senhor. E Hilquias deu o livro a Safã.',
        'E Safã levou o livro ao rei, e deu-lhe conta, dizendo: Teus servos fazem tudo quanto se lhes encomendou.',
        'E ajuntaram o dinheiro que se achou na casa do Senhor, e o deram na mão dos superintendentes e na mão dos que faziam a obra.',
        'Além disto, Safã, o escrivão, fez saber ao rei, dizendo: O sacerdote Hilquias entregou-me um livro. E Safã leu nele perante o rei.',
        'Sucedeu que, ouvindo o rei as palavras da lei, rasgou as suas vestes.',
        'E o rei ordenou a Hilquias, e a Aicão, filho de Safã, e a Abdom, filho de Mica, e a Safã, o escrivão, e a Asaías, servo do rei, dizendo:',
        'Ide, consultai ao Senhor por mim, e pelos que restam em Israel e em Judá, sobre as palavras deste livro que se achou; porque grande é o furor do Senhor, que se derramou sobre nós; porquanto nossos pais não guardaram a palavra do Senhor, para fazerem conforme a tudo quanto está escrito neste livro.',
        'Então Hilquias, e os enviados do rei, foram ter com a profetisa Hulda, mulher de Salum, filho de Tocate, filho de Harás, guarda das vestimentas (e habitava ela em Jerusalém na segunda parte); e falaram-lhe a esse respeito.',
        'E ela lhes disse: Assim diz o Senhor Deus de Israel: Dizei ao homem que vos enviou a mim:',
        'Assim diz o Senhor: Eis que trarei mal sobre este lugar, e sobre os seus habitantes, a saber, todas as maldições que estão escritas no livro que se leu perante o rei de Judá.',
        'Porque me deixaram, e queimaram incenso perante outros deuses, para me provocarem à ira com todas as obras das suas mãos; portanto o meu furor se derramou sobre este lugar, e não se apagará.',
        'Porém ao rei de Judá, que vos enviou a consultar ao Senhor, assim lhe direis: Assim diz o Senhor Deus de Israel, quanto às palavras que ouviste:',
        'Porquanto o teu coração se enterneceu, e te humilhaste perante Deus, ouvindo as suas palavras contra este lugar, e contra os seus habitantes, e te humilhaste perante mim, e rasgaste as tuas vestes, e choraste perante mim, também eu te ouvi, diz o Senhor.',
        'Eis que te reunirei a teus pais, e tu serás recolhido ao teu sepulcro em paz, e os teus olhos não verão todo o mal que hei de trazer sobre este lugar e sobre os seus habitantes. E tornaram com esta resposta ao rei.',
        'Então o rei mandou reunir todos os anciãos de Judá e Jerusalém.',
        'E o rei subiu à casa do Senhor, com todos os homens de Judá, e os habitantes de Jerusalém, e os sacerdotes, e os levitas, e todo o povo, desde o maior até ao menor; e ele leu aos ouvidos deles todas as palavras do livro da aliança que fora achado na casa do Senhor.',
        'E pôs-se o rei em pé em seu lugar, e fez aliança perante o Senhor, para seguirem ao Senhor, e para guardar os seus mandamentos, e os seus testemunhos, e os seus estatutos, com todo o seu coração, e com toda a sua alma, cumprindo as palavras da aliança, que estão escritas naquele livro.',
        'E fez com que todos quantos se achavam em Jerusalém e em Benjamim o firmassem; e os habitantes de Jerusalém fizeram conforme a aliança de Deus, o Deus de seus pais.',
        'E Josias tirou todas as abominações de todas as terras que eram dos filhos de Israel; e a todos quantos se achavam em Israel obrigou a que servissem ao Senhor seu Deus. Enquanto ele viveu não se desviaram de seguir o Senhor, o Deus de seus pais.',
      ],
      [
        'Então Josias celebrou a páscoa ao SENHOR em Jerusalém; e mataram o cordeiro da páscoa no décimo quarto dia do primeiro mês.',
        'E estabeleceu os sacerdotes nos seus cargos, e os animou ao ministério da casa do Senhor.',
        'E disse aos levitas que ensinavam a todo o Israel e estavam consagrados ao Senhor: Ponde a arca sagrada na casa que edificou Salomão, filho de Davi, rei de Israel; não tereis mais esta carga aos ombros; agora servi ao Senhor vosso Deus, e ao seu povo Israel.',
        'E preparai-vos segundo as vossas casas paternas e segundo as vossas turmas, conforme à prescrição de Davi, rei de Israel, e a de Salomão, seu filho.',
        'E estai no santuário segundo as divisões das casas paternas de vossos irmãos, os filhos do povo; e haja para cada divisão uma parte de uma família de levitas.',
        'E imolai a páscoa, e santificai-vos, e preparai-a para vossos irmãos, fazendo conforme a palavra do Senhor, dada pela mão de Moisés.',
        'E ofereceu Josias, aos filhos do povo, cordeiros e cabritos do rebanho, todos para os sacrifícios da páscoa, em número de trinta mil, por todos os que ali se achavam, e de bois três mil; isto era da fazenda do rei.',
        'Também apresentaram os seus príncipes ofertas voluntárias ao povo, aos sacerdotes e aos levitas: Hilquias, e Zacarias, e Jeiel, líderes da casa de Deus, deram aos sacerdotes para os sacrifícios da páscoa duas mil e seiscentas reses de gado miúdo, e trezentos bois.',
        'E Conanias, e Semaías, e Natanael, seus irmãos, como também Hasabias, e Jeiel, e Jozabade, chefe dos levitas, apresentaram aos levitas, para os sacrifícios da páscoa, cinco mil reses de gado miúdo, e quinhentos bois.',
        'Assim se preparou o serviço, e puseram-se os sacerdotes nos seus postos, e os levitas nas suas turmas, conforme a ordem do rei,',
        'Então imolaram a páscoa; e os sacerdotes espargiram o sangue recebido das mãos dos levitas que esfolavam as reses.',
        'E puseram de parte os holocaustos para os darem aos filhos do povo, segundo as divisões das casas paternas, para o oferecerem ao Senhor, como está escrito no livro de Moisés; e assim fizeram com os bois.',
        'E assaram a páscoa no fogo, segundo o rito; e as ofertas sagradas cozeram em panelas, e em caldeirões e em sertãs; e prontamente as repartiram entre todo o povo.',
        'Depois prepararam para si e para os sacerdotes; porque os sacerdotes, filhos de Arão, se ocuparam até à noite com o sacrifício dos holocaustos e da gordura; por isso os levitas prepararam para si e para os sacerdotes, filhos de Arão.',
        'E os cantores, filhos de Asafe, estavam no seu posto, segundo o mandado de Davi, e de Asafe, e de Hemã, e de Jedutum, vidente do rei, como também os porteiros a cada porta; não necessitaram de se desviarem do seu ministério; porquanto seus irmãos, os levitas, preparavam o necessário para eles.',
        'Assim se estabeleceu todo o serviço do Senhor naquele dia, para celebrar a páscoa, e oferecer holocaustos sobre o altar do Senhor, segundo a ordem do rei Josias.',
        'E os filhos de Israel que ali se acharam celebraram a páscoa naquele tempo, e a festa dos pães ázimos, durante sete dias.',
        'Nunca, pois, se celebrou tal páscoa em Israel, desde os dias do profeta Samuel; nem nenhum rei de Israel celebrou tal páscoa como a que celebrou Josias com os sacerdotes, e levitas, e todo o Judá e Israel, que ali se acharam, e os habitantes de Jerusalém.',
        'No décimo oitavo ano do reinado de Josias se celebrou esta páscoa.',
        'Depois de tudo isto, havendo Josias já preparado o templo, subiu Neco, rei do Egito, para guerrear contra Carquemis, junto ao Eufrates; e Josias lhe saiu ao encontro.',
        'Então ele lhe mandou mensageiros, dizendo: Que tenho eu contigo, rei de Judá? Não é contra ti que venho hoje, mas contra a casa que me faz guerra; e disse Deus que me apressasse; guarda-te de te opores a Deus, que é comigo, para que ele não te destrua.',
        'Porém Josias não virou dele o seu rosto, antes se disfarçou, para pelejar contra ele; e não deu ouvidos às palavras de Neco, que saíram da boca de Deus; antes veio pelejar no vale de Megido.',
        'E os flecheiros atiraram contra o rei Josias. Então o rei disse a seus servos: Tirai-me daqui, porque estou gravemente ferido.',
        'E seus servos o tiraram do carro, e o levaram no segundo carro que tinha, e o trouxeram a Jerusalém; e morreu, e o sepultaram nos sepulcros de seus pais; e todo o Judá e Jerusalém prantearam a Josias.',
        'E Jeremias fez uma lamentação sobre Josias; e todos os cantores e cantoras, nas suas lamentações, têm falado de Josias, até ao dia de hoje; porque as estabeleceram por estatuto em Israel; e eis que estão escritas nas lamentações.',
        'Quanto ao mais dos atos de Josias, e as suas boas obras, conforme o que está escrito na lei do Senhor,',
        'E os seus atos, tanto os primeiros como os últimos, eis que estão escritos no livro dos reis de Israel e de Judá.',
      ],
      [
        'Então o povo da terra tomou a Jeoacaz, filho de Josias, e o fez rei em lugar de seu pai, em Jerusalém.',
        'Tinha Jeoacaz a idade de vinte e três anos, quando começou a reinar; e três meses reinou em Jerusalém,',
        'Porque o rei do Egito o depôs em Jerusalém, e condenou a terra à contribuição de cem talentos de prata e um talento de ouro.',
        'E o rei do Egito pôs a Eliaquim, irmão de Jeoacaz, rei sobre Judá e Jerusalém, e mudou-lhe o nome em Jeoiaquim; mas a seu irmão Jeoacaz tomou Neco, e levou-o para o Egito.',
        'Tinha Jeoiaquim vinte e cinco anos de idade, quando começou a reinar, e reinou onze anos em Jerusalém; e fez o que era mau aos olhos do Senhor seu Deus.',
        'Subiu, pois, contra ele Nabucodonosor, rei de babilônia, e o amarrou com cadeias, para o levar a babilônia.',
        'Também alguns dos vasos da casa do SENHOR levou Nabucodonosor a babilônia, e pô-los no seu templo em babilônia.',
        'Quanto ao mais dos atos de Jeoiaquim, e as abominações que fez, e o mais que se achou nele, eis que estão escritos no livro dos reis de Israel e de Judá; e Joaquim, seu filho, reinou em seu lugar.',
        'Tinha Joaquim a idade de oito anos, quando começou a reinar; e reinou três meses e dez dias em Jerusalém; e fez o que era mau aos olhos do Senhor.',
        'E no decurso de um ano enviou o rei Nabucodonosor, e mandou trazê-lo a babilônia, com os mais preciosos vasos da casa do SENHOR; e pôs a Zedequias, seu irmão, rei sobre Judá e Jerusalém.',
        'Tinha Zedequias a idade de vinte e um anos anos, quando começou a reinar; e onze anos reinou em Jerusalém.',
        'E fez o que era mau aos olhos do Senhor seu Deus; nem se humilhou perante o profeta Jeremias, que falava da parte do Senhor.',
        'Além disto, também se rebelou contra o rei Nabucodonosor, que o tinha ajuramentado por Deus. Mas endureceu a sua cerviz, e tanto se obstinou no seu coração, que não se converteu ao Senhor Deus de Israel.',
        'Também todos os chefes dos sacerdotes e o povo aumentavam de mais em mais as transgressões, segundo todas as abominações dos gentios; e contaminaram a casa do Senhor, que ele tinha santificado em Jerusalém.',
        'E o Senhor Deus de seus pais, falou-lhes constantemente por intermédio dos mensageiros, porque se compadeceu do seu povo e da sua habitação.',
        'Eles, porém, zombaram dos mensageiros de Deus, e desprezaram as suas palavras, e mofaram dos seus profetas; até que o furor do Senhor tanto subiu contra o seu povo, que mais nenhum remédio houve.',
        'Porque fez subir contra eles o rei dos caldeus, o qual matou os seus jovens à espada, na casa do seu santuário, e não teve piedade nem dos jovens, nem das donzelas, nem dos velhos, nem dos decrépitos; a todos entregou na sua mão.',
        'E todos os vasos da casa de Deus, grandes e pequenos, os tesouros da casa do SENHOR, e os tesouros do rei e dos seus príncipes, tudo levou para babilônia.',
        'E queimaram a casa de Deus, e derrubaram os muros de Jerusalém, e todos os seus palácios queimaram a fogo, destruindo também todos os seus preciosos vasos.',
        'E os que escaparam da espada levou para babilônia; e fizeram-se servos dele e de seus filhos, até ao tempo do reino da Pérsia.',
        'Para que se cumprisse a palavra do Senhor, pela boca de Jeremias, até que a terra se agradasse dos seus sábados; todos os dias da assolação repousou, até que os setenta anos se cumpriram.',
        'Porém, no primeiro ano de Ciro, rei da Pérsia (para que se cumprisse a palavra do Senhor pela boca de Jeremias), despertou o Senhor o espírito de Ciro, rei da Pérsia, o qual fez passar pregão por todo o seu reino, como também por escrito, dizendo:',
        'Assim diz Ciro, rei da Pérsia: O Senhor Deus dos céus me deu todos os reinos da terra, e me encarregou de lhe edificar uma casa em Jerusalém, que está em Judá. Quem há entre vós, de todo o seu povo, o Senhor seu Deus seja com ele, e suba.',
      ],
    ],
    livro: '2cronicas',
  },
  {
    abbrev: 'ed',
    capitulos: [
      [
        'No primeiro ano de Ciro, rei da Pérsia (para que se cumprisse a palavra do SENHOR, pela boca de Jeremias), despertou o SENHOR o espírito de Ciro, rei da Pérsia, o qual fez passar pregão por todo o seu reino, como também por escrito, dizendo:',
        'Assim diz Ciro, rei da Pérsia: O Senhor Deus dos céus me deu todos os reinos da terra, e me encarregou de lhe edificar uma casa em Jerusalém, que está em Judá.',
        'Quem há entre vós, de todo o seu povo, seja seu Deus com ele, e suba a Jerusalém, que está em Judá, e edifique a casa do Senhor Deus de Israel (ele é o Deus) que está em Jerusalém.',
        'E todo aquele que ficar atrás em algum lugar em que andar peregrinando, os homens do seu lugar o ajudarão com prata, com ouro, com bens, e com gados, além das dádivas voluntárias para a casa de Deus, que está em Jerusalém.',
        'Então se levantaram os chefes dos pais de Judá e Benjamim, e os sacerdotes e os levitas, com todos aqueles cujo espírito Deus despertou, para subirem a edificar a casa do Senhor, que está em Jerusalém.',
        'E todos os que habitavam nos arredores lhes firmaram as mãos com vasos de prata, com ouro, com bens e com gado, e com coisas preciosas; além de tudo o que voluntariamente se deu.',
        'Também o rei Ciro tirou os utensílios da casa do Senhor, que Nabucodonosor tinha trazido de Jerusalém, e que tinha posto na casa de seus deuses.',
        'Estes tirou Ciro, rei da Pérsia, pela mão de Mitredate, o tesoureiro, que os entregou contados a Sesbazar, príncipe de Judá.',
        'E este é o número deles: trinta travessas de ouro, mil travessas de prata, vinte e nove facas,',
        'Trinta bacias de ouro, mais outras quatrocentas e dez bacias de prata, e mil outros utensílios.',
        'Todos os utensílios de ouro e de prata foram cinco mil e quatrocentos; todos estes levou Sesbazar, quando os do cativeiro subiram de babilônia para Jerusalém.',
      ],
      [
        'Estes são os filhos da província, que subiram do cativeiro, dentre os exilados, que Nabucodonosor, rei de babilônia, tinha transportado a babilônia, e tornaram a Jerusalém e a Judá, cada um para a sua cidade;',
        'Os quais vieram com Zorobabel, Jesua, Neemias, Seraías, Reelaías, Mardoqueu, Bilsã, Mizpar, Bigvai, Reum e Baaná. O número dos homens do povo de Israel:',
        'Os filhos de Parós, dois mil cento e setenta e dois.',
        'Os filhos de Sefatias, trezentos e setenta e dois.',
        'Os filhos de Ará, setecentos e setenta e cinco.',
        'Os filhos de Paate-Moabe, dos filhos de Jesuá-Joabe, dois mil oitocentos e doze.',
        'Os filhos de Elão, mil duzentos e cinqüenta e quatro.',
        'Os filhos de Zatu, novecentos e quarenta e cinco.',
        'Os filhos de Zacai, setecentos e sessenta.',
        'Os filhos de Bani, seiscentos e quarenta e dois.',
        'Os filhos de Bebai, seiscentos e vinte e três.',
        'Os filhos de Azgade, mil duzentos e vinte e dois.',
        'Os filhos de Adonicão, seiscentos e sessenta e seis.',
        'Os filhos de Bigvai, dois mil e cinqüenta e seis.',
        'Os filhos de Adim, quatrocentos e cinqüenta e quatro.',
        'Os filhos de Ater, de Ezequias, noventa e oito.',
        'Os filhos de Bezai, trezentos e vinte e três.',
        'Os filhos de Jora, cento e doze.',
        'Os filhos de Hasum, duzentos e vinte e três.',
        'Os filhos de Gibar, noventa e cinco.',
        'Os filhos de Belém, cento e vinte e três.',
        'Os homens de Netofá, cinqüenta e seis.',
        'Os homens de Anatote, cento e vinte e oito.',
        'Os filhos de Azmavete, quarenta e dois.',
        'Os filhos de Quiriate-Arim, Quefira e Beerote, setecentos e quarenta e três.',
        'Os filhos de Ramá, e de Geba, seiscentos e vinte e um.',
        'Os homens de Micmás, cento e vinte e dois.',
        'Os homens de Betel e de Ai, duzentos e vinte e três.',
        'Os filhos de Nebo, cinqüenta e dois.',
        'Os filhos de Magbis, cento e cinqüenta e seis.',
        'Os filhos do outro Elão, mil duzentos e cinqüenta e quatro.',
        'Os filhos de Harim, trezentos e vinte.',
        'Os filhos de Lode, de Hadide e de Ono, setecentos e vinte e cinco.',
        'Os filhos de Jericó, trezentos e quarenta e cinco.',
        'Os filhos de Senaá, três mil seiscentos e trinta.',
        'Os sacerdotes: os filhos de Jedaías, da casa de Jesuá, novecentos e setenta e três.',
        'Os filhos de Imer, mil e cinqüenta e dois.',
        'Os filhos de Pasur, mil duzentos e quarenta e sete.',
        'Os filhos de Harim, mil e dezessete.',
        'Os levitas: os filhos de Jesuá e Cadmiel, dos filhos de Hodavias, setenta e quatro.',
        'Os cantores: os filhos de Asafe, cento e vinte e oito.',
        'Os filhos dos porteiros: os filhos de Salum, os filhos de Ater, os filhos de Talmom, os filhos de Acube, os filhos de Hatita, os filhos de Sobai; ao todo, cento e trinta e nove.',
        'Os netinins: os filhos de Zia, os filhos de Hasufa, os filhos de Tabaote,',
        'Os filhos de Querós, os filhos de Siá, os filhos de Padom,',
        'Os filhos de Lebaná, os filhos de Hagaba, os filhos de Acube,',
        'Os filhos de Hagabe, os filhos de Sanlai, os filhos de Hanã,',
        'Os filhos de Gidel, os filhos de Gaar, os filhos de Reaías,',
        'Os filhos de Rezim, os filhos de Necoda, os filhos de Gazão,',
        'Os filhos de Uzá, os filhos de Paseá, os filhos de Besai,',
        'Os filhos de Asna, os filhos de Meunim, os filhos de Nefussim,',
        'Os filhos de Bacbuque, os filhos de Hacufa, os filhos de Harur,',
        'Os filhos de Bazlute, os filhos de Meída, os filhos de Harsa,',
        'Os filhos de Barcos, os filhos de Sísera, os filhos de Tama.',
        'Os filhos de Neziá, os filhos de Hatifa.',
        'Os filhos dos servos de Salomão; os filhos de Sotai, os filhos de Soferete, os filhos de Peruda,',
        'Os filhos de Jaalá, os filhos de Darcom, os filhos de Gidel,',
        'Os filhos de Sefatias, os filhos de Hatil, os filhos de PoquereteHazebaim, os filhos de Ami.',
        'Todos os netinins, e os filhos dos servos de Salomão, trezentos e noventa e dois.',
        'Também estes subiram de Tel-Melá e Tel-Harsa, Querube, Adã e Imer; porém não puderam provar que as suas famílias e a sua linhagem eram de Israel:',
        'Os filhos de Delaías, os filhos de Tobias, os filhos de Necoda, seiscentos e cinqüenta e dois.',
        'E dos filhos dos sacerdotes: os filhos de Habaías, os filhos de Coz, os filhos de Barzilai, que tomou mulher das filhas de Barzilai, o gileadita, e que foi chamado do seu nome.',
        'Estes procuraram o seu registro entre os que estavam arrolados nas genealogias, mas não se acharam nelas; assim, por imundos, foram excluídos do sacerdócio.',
        'E o governador lhes disse que não comessem das coisas consagradas, até que houvesse sacerdote com Urim e com Tumim.',
        'Toda esta congregação junta foi de quarenta e dois mil trezentos e sessenta,',
        'Afora os seus servos e as suas servas, que foram sete mil trezentos e trinta e sete; também tinha duzentos cantores e cantoras.',
        'Os seus cavalos, setecentos e trinta e seis; os seus mulos, duzentos e quarenta e cinco;',
        'Os seus camelos, quatrocentos e trinta e cinco; os jumentos, seis mil setecentos e vinte.',
        'E alguns dos chefes dos pais, vindo à casa do Senhor, que habita em Jerusalém, deram ofertas voluntárias para a casa de Deus, para a estabelecerem no seu lugar.',
        'Conforme as suas posses, deram para o tesouro da obra, em ouro, sessenta e uma mil dracmas, e em prata cinco mil libras, e cem vestes sacerdotais.',
        'E habitaram os sacerdotes e os levitas, e alguns do povo, tanto os cantores, como os porteiros, e os netinins, nas suas cidades; como também todo o Israel nas suas cidades.',
      ],
      [
        'Chegando, pois, o sétimo mês, e estando os filhos de Israel já nas cidades, ajuntou-se o povo, como um só homem, em Jerusalém.',
        'E levantou-se Jesuá, filho de Jozadaque, e seus irmãos, os sacerdotes, e Zorobabel, filho de Sealtiel, e seus irmãos, e edificaram o altar do Deus de Israel, para oferecerem sobre ele holocaustos, como está escrito na lei de Moisés, o homem de Deus.',
        'E firmaram o altar sobre as suas bases, porque o terror estava sobre eles, por causa dos povos das terras; e ofereceram sobre ele holocaustos ao Senhor, holocaustos pela manhã e à tarde.',
        'E celebraram a festa dos tabernáculos, como está escrito; ofereceram holocaustos cada dia, por ordem, conforme ao rito, cada coisa em seu dia.',
        'E depois disto o holocausto contínuo, e os das luas novas e de todas as solenidades consagradas ao Senhor; como também de qualquer que oferecia oferta voluntária ao Senhor;',
        'Desde o primeiro dia do sétimo mês começaram a oferecer holocaustos ao SENHOR; porém ainda não estavam postos os fundamentos do templo do SENHOR.',
        'Deram, pois, o dinheiro aos pedreiros e carpinteiros, como também comida e bebida, e azeite aos sidônios, e aos tírios, para trazerem do Líbano madeira de cedro ao mar, para Jope, segundo a concessão que lhes tinha feito Ciro, rei da Pérsia.',
        'E no segundo ano da sua vinda à casa de Deus em Jerusalém, no segundo mês, Zorobabel, filho de Sealtiel, e Jesuá, filho de Jozadaque, e os outros seus irmãos, os sacerdotes e os levitas, e todos os que vieram do cativeiro a Jerusalém, começaram a obra da casa do Senhor, e constituíram os levitas da idade de vinte anos para cima, para que a dirigissem.',
        'Então se levantou Jesuá, seus filhos, e seus irmãos, Cadmiel e seus filhos, os filhos de Judá, como um só homem, para dirigirem os que faziam a obra na casa de Deus, bem como os filhos de Henadade, seus filhos e seus irmãos, os levitas.',
        'Quando, pois, os edificadores lançaram os alicerces do templo do Senhor, então apresentaram-se os sacerdotes, já vestidos e com trombetas, e os levitas, filhos de Asafe, com címbalos, para louvarem ao Senhor conforme à instituição de Davi, rei de Israel.',
        'E cantavam juntos por grupo, louvando e rendendo graças ao Senhor, dizendo: porque é bom; porque a sua benignidade dura para sempre sobre Israel. E todo o povo jubilou com altas vozes, quando louvaram ao Senhor, pela fundação da casa do Senhor.',
        'Porém muitos dos sacerdotes, e levitas e chefes dos pais, já idosos, que viram a primeira casa, choraram em altas vozes quando à sua vista foram lançados os fundamentos desta casa; mas muitos levantaram as vozes com júbilo e com alegria.',
        'De maneira que não discernia o povo as vozes do júbilo de alegria das vozes do choro do povo; porque o povo jubilava com tão altas vozes, que o som se ouvia de muito longe.',
      ],
      [
        'Ouvindo, pois, os adversários de Judá e Benjamim que os que voltaram do cativeiro edificavam o templo ao SENHOR Deus de Israel,',
        'Chegaram-se a Zorobabel e aos chefes dos pais, e disseram-lhes: Deixai-nos edificar convosco, porque, como vós, buscaremos a vosso Deus; como também já lhe sacrificamos desde os dias de Esar-Hadom, rei da Assíria, que nos fez subir aqui.',
        'Porém Zorobabel, e Jesuá, e os outros chefes dos pais de Israel lhes disseram: Não convém que nós e vós edifiquemos casa a nosso Deus; mas nós sozinhos a edificaremos ao Senhor Deus de Israel, como nos ordenou o rei Ciro, rei da Pérsia.',
        'Todavia o povo da terra debilitava as mãos do povo de Judá, e inquietava-os no edificar.',
        'E alugaram contra eles conselheiros, para frustrarem o seu plano, todos os dias de Ciro, rei da Pérsia, até ao reinado de Dario, rei da Pérsia.',
        'No reinado de Assuero, no princípio do seu reinado, escreveram uma acusação contra os habitantes de Judá e de Jerusalém.',
        'E nos dias de Artaxerxes escreveram Bislão, Mitredate, Tabeel, e os outros seus companheiros, a Artaxerxes, rei da Pérsia; e a carta estava escrita em caracteres siríacos, e na língua siríaca.',
        'Escreveram, pois, Reum, o chanceler, e Sinsai, o escrivão, uma carta contra Jerusalém, ao rei Artaxerxes, do teor seguinte:',
        'Então escreveu Reum, o chanceler, e Sinsai, o escrivão, e os outros seus companheiros, os dinaítas, afarsaquitas, tarpelitas, afarsitas, arquevitas, babilônios, susanquitas, deavitas, elamitas,',
        'E os outros povos, que o grande e afamado Asnapar transportou, e que fez habitar na cidade de Samaria, e nas demais províncias dalém do rio, em tal tempo.',
        'Este, pois, é o teor da carta que mandaram ao rei Artaxerxes: Teus servos, os homens dalém do rio, em tal tempo.',
        'Saiba o rei que os judeus, que subiram de ti, vieram a nós em Jerusalém, e reedificam aquela rebelde e malvada cidade, e vão restaurando os seus muros, e reparando os seus fundamentos.',
        'Agora saiba o rei que, se aquela cidade se reedificar, e os muros se restaurarem, eles não pagarão os direitos, os tributos e os pedágios; e assim se danificará a fazenda dos reis.',
        'Agora, pois, porquanto somos assalariados do palácio, e não nos convém ver a desonra do rei, por isso mandamos avisar ao rei,',
        'Para que se busque no livro das crônicas de teus pais. E acharás no livro das crônicas, e saberás que aquela foi uma cidade rebelde, e danosa aos reis e províncias, e que nela houve rebelião em tempos antigos; por isso foi aquela cidade destruída.',
        'Nós, pois, fazemos notório ao rei que, se aquela cidade se reedificar, e os seus muros se restaurarem, sucederá que não terás porção alguma deste lado do rio.',
        'E o rei enviou esta resposta a Reum, o chanceler, e a Sinsai, o escrivão, e aos demais seus companheiros, que habitavam em Samaria; como também aos demais que estavam dalém do rio: Paz! em tal tempo.',
        'A carta que nos enviastes foi explicitamente lida diante de mim.',
        'E, ordenando-o eu, buscaram e acharam, que de tempos antigos aquela cidade se levantou contra os reis, e nela se têm feito rebelião e sedição.',
        'Também houve reis poderosos sobre Jerusalém que dalém do rio dominaram em todo o lugar, e se lhes pagaram direitos, tributos e pedágios.',
        'Agora, pois, dai ordem para impedirdes aqueles homens, a fim de que não se edifique aquela cidade, até que eu dê uma ordem.',
        'E guardai-vos de serdes remissos nisto; por que cresceria o dano para prejuízo dos reis?',
        'Então, depois que a cópia da carta do rei Artaxerxes foi lida perante Reum, e Sinsai, o escrivão, e seus companheiros, apressadamente foram eles a Jerusalém, aos judeus, e os impediram à força e com violência.',
        'Então cessou a obra da casa de Deus, que estava em Jerusalém; e cessou até ao ano segundo do reinado de Dario, rei da Pérsia.',
      ],
      [
        'E os profetas Ageu e Zacarias, filho de Ido, profetizaram aos judeus que estavam em Judá, e em Jerusalém; em nome do Deus de Israel lhes profetizaram.',
        'Então se levantaram Zorobabel, filho de Sealtiel, e Jesuá, filho de Jozadaque, e começaram a edificar a casa de Deus, que está em Jerusalém; e com eles os profetas de Deus, que os ajudavam.',
        'Naquele tempo vieram a eles Tatenai, governador dalém do rio, e Setar-Bozenai, e os seus companheiros, e disseram-lhes assim: Quem vos deu ordem para reedificardes esta casa, e restaurardes este muro?',
        'Disseram-lhes, mais: E quais são os nomes dos homens que construíram este edifício?',
        'Porém os olhos de Deus estavam sobre os anciãos dos judeus, e não os impediram, até que o negócio chegasse a Dario, e viesse resposta por carta sobre isso.',
        'Cópia da carta que Tatenai, o governador dalém do rio, com Setar-Bozenai e os seus companheiros, os afarsaquitas, que estavam dalém do rio, enviaram ao rei Dario.',
        'Enviaram-lhe uma carta, na qual estava escrito: Toda a paz ao rei Dario.',
        'Seja notório ao rei, que nós fomos à província de Judá, à casa do grande Deus, a qual se edifica com grandes pedras, e a madeira já está sendo posta nas paredes; e esta obra vai sendo feita com diligência, e se adianta em suas mãos.',
        'Então perguntamos aos anciãos, e assim lhes dissemos: Quem vos deu ordem para reedificardes esta casa, e restaurardes este muro?',
        'Além disso, lhes perguntamos também pelos seus nomes, para tos declararmos; para que te pudéssemos escrever os nomes dos homens que entre eles são os chefes.',
        'E esta foi a resposta que nos deram: Nós somos servos do Deus dos céus e da terra, e reedificamos a casa que há muitos anos foi edificada; porque um grande rei de Israel a edificou e a terminou.',
        'Mas depois que nossos pais provocaram à ira o Deus dos céus, ele os entregou nas mãos de Nabucodonosor, rei de babilônia, o caldeu, o qual destruiu esta casa, e transportou o povo para babilônia.',
        'Porém, no primeiro ano de Ciro, rei de babilônia, o rei Ciro deu ordem para que esta casa de Deus se reedificasse.',
        'E até os utensílios de ouro e prata, da casa de Deus, que Nabucodonosor tomou do templo que estava em Jerusalém e os levou para o templo de babilônia, o rei Ciro os tirou do templo de babilônia, e foram dados a um homem cujo nome era Sesbazar, a quem nomeou governador.',
        'E disse-lhe: Toma estes utensílios, vai e leva-os ao templo que está em Jerusalém, e faze reedificar a casa de Deus, no seu lugar.',
        'Então veio este Sesbazar, e pôs os fundamentos da casa de Deus, que está em Jerusalém, e desde então para cá se está reedificando, e ainda não está acabada.',
        'Agora, pois, se parece bem ao rei, busque-se na casa dos tesouros do rei, que está em babilônia, se é verdade que se deu uma ordem pelo rei Ciro para reedificar esta casa de Deus em Jerusalém; e sobre isto nos faça saber a vontade do rei.',
      ],
      [
        'Então o rei Dario deu ordem, e buscaram nos arquivos, onde se guardavam os tesouros em babilônia.',
        'E em Acmeta, no palácio, que está na província de Média, se achou um rolo, e nele estava escrito um memorial que dizia assim:',
        'No primeiro ano do rei Ciro, este baixou o seguinte decreto: A casa de Deus, em Jerusalém, se reedificará para lugar em que se ofereçam sacrifícios, e seus fundamentos serão firmes; a sua altura de sessenta côvados, e a sua largura de sessenta côvados;',
        'Com três carreiras de grandes pedras, e uma carreira de madeira nova; e a despesa se fará da casa do rei.',
        'Além disso, os utensílios de ouro e de prata da casa de Deus, que Nabucodonosor transportou do templo que estava em Jerusalém, e levou para babilônia, serão restituídos, para que voltem ao seu lugar, ao templo que está em Jerusalém, e serão postos na casa de Deus.',
        'Agora, pois, Tatenai, governador dalém do rio, Setar-Bozenai, e os seus companheiros, os afarsaquitas, que habitais dalém do rio, apartai-vos dali.',
        'Deixai que se faça a obra desta casa de Deus; que o governador dos judeus e os seus anciãos reedifiquem esta casa de Deus no seu lugar.',
        'Também por mim se decreta o que haveis de fazer com os anciãos dos judeus, para a reedificação desta casa de Deus, a saber: que da fazenda do rei, dos tributos dalém do rio se pague prontamente a despesa a estes homens, para que não interrompam a obra.',
        'E o que for necessário, como bezerros, carneiros, e cordeiros, para holocaustos ao Deus dos céus, trigo, sal, vinho e azeite, segundo o rito dos sacerdotes que estão em Jerusalém, dê-se-lhes, de dia em dia, para que não haja falta.',
        'Para que ofereçam sacrifícios de cheiro suave ao Deus dos céus, e orem pela vida do rei e de seus filhos.',
        'Também por mim se decreta que todo o homem que mudar este decreto, se arrancará um madeiro da sua casa, e, levantado, o pendurarão nele, e da sua casa se fará por isso um monturo.',
        'O Deus, pois, que fez habitar ali o seu nome derrube a todos os reis e povos que estenderem a sua mão para mudar o decreto e para destruir esta casa de Deus, que está em Jerusalém. Eu, Dario, baixei o decreto; com diligência se faça.',
        'Então Tatenai, o governador dalém do rio, Setar-Bozenai e os seus companheiros, assim fizeram diligentemente, conforme ao que decretara o rei Dario.',
        'E os anciãos dos judeus iam edificando e prosperando pela profecia do profeta Ageu, e de Zacarias, filho de Ido. E edificaram e terminaram a obra conforme ao mandado do Deus de Israel, e conforme ao decreto de Ciro e Dario, e de Artaxerxes, rei da Pérsia.',
        'E acabou-se esta casa no terceiro dia do mês de Adar, no sexto ano do reinado do rei Dario.',
        'E os filhos de Israel, os sacerdotes, os levitas, e o restante dos filhos do cativeiro, fizeram a dedicação desta casa de Deus com alegria.',
        'E ofereceram para a dedicação desta casa de Deus cem novilhos, duzentos carneiros, quatrocentos cordeiros, e doze cabritos por expiação do pecado de todo o Israel; segundo o número das tribos de Israel.',
        'E puseram os sacerdotes nas suas turmas e os levitas nas suas divisões, para o ministério de Deus, em Jerusalém, conforme ao que está escrito no livro de Moisés.',
        'E os filhos do cativeiro celebraram a páscoa no dia catorze do primeiro mês.',
        'Porque os sacerdotes e levitas se purificaram como se fossem um só homem, todos estavam limpos; e mataram o cordeiro da páscoa para todos os filhos do cativeiro, e para seus irmãos, os sacerdotes, e para si mesmos.',
        'Assim comeram a páscoa os filhos de Israel que tinham voltado do cativeiro, com todos os que com eles se apartaram da imundícia dos gentios da terra, para buscarem o Senhor Deus de Israel;',
        'E celebraram a festa dos pães ázimos por sete dias com alegria; porque o Senhor os tinha alegrado, e tinha mudado o coração do rei da Assíria a favor deles, para lhes fortalecer as mãos na obra da casa de Deus, o Deus de Israel.',
      ],
      [
        'E passadas estas coisas no reinado de Artaxerxes, rei da Pérsia, Esdras, filho de Seraías, filho de Azarias, filho de Hilquias,',
        'Filho de Salum, filho de Zadoque, filho de Aitube,',
        'Filho de Amarias, filho de Azarias, filho de Meraiote,',
        'Filho de Zeraquias, filho de Uzi, filho de Buqui,',
        'Filho de Abisua, filho de Finéias, filho de Eleazar, filho de Arão, o sumo sacerdote;',
        'Este Esdras subiu de babilônia; e era escriba hábil na lei de Moisés, que o SENHOR Deus de Israel tinha dado; e, segundo a mão do SENHOR seu Deus, que estava sobre ele, o rei lhe deu tudo quanto lhe pedira.',
        'Também subiram a Jerusalém alguns dos filhos de Israel, dos sacerdotes, dos levitas, dos cantores, dos porteiros e dos servidores do templo, no sétimo ano do rei Artaxerxes.',
        'E no quinto mês chegou a Jerusalém, no sétimo ano deste rei.',
        'Pois no primeiro dia do primeiro mês foi o princípio da partida de babilônia; e no primeiro dia do quinto mês chegou a Jerusalém, segundo a boa mão do seu Deus sobre ele.',
        'Porque Esdras tinha preparado o seu coração para buscar a lei do Senhor e para cumpri-la e para ensinar em Israel os seus estatutos e os seus juízos.',
        'Esta é, pois, a cópia da carta que o rei Artaxerxes deu ao sacerdote Esdras, o escriba das palavras dos mandamentos do Senhor, e dos seus estatutos sobre Israel:',
        'Artaxerxes, rei dos reis, ao sacerdote Esdras, escriba da lei do Deus do céu, paz perfeita, em tal tempo.',
        'Por mim se decreta que no meu reino todo aquele do povo de Israel, e dos seus sacerdotes e levitas, que quiser ir contigo a Jerusalém, vá.',
        'Porquanto és enviado da parte do rei e dos seus sete conselheiros para fazeres inquirição a respeito de Judá e de Jerusalém, conforme à lei do teu Deus, que está na tua mão;',
        'E para levares a prata e o ouro que o rei e os seus conselheiros voluntariamente deram ao Deus de Israel, cuja habitação está em Jerusalém;',
        'E toda a prata e o ouro que achares em toda a província de babilônia, com as ofertas voluntárias do povo e dos sacerdotes, que voluntariamente oferecerem, para a casa de seu Deus, que está em Jerusalém.',
        'Portanto diligentemente comprarás com este dinheiro novilhos, carneiros, cordeiros, com as suas ofertas de alimentos, e as suas libações, e as oferecerás sobre o altar da casa de vosso Deus, que está em Jerusalém.',
        'Também o que a ti e a teus irmãos bem parecer fazerdes do restante da prata e do ouro, o fareis conforme a vontade do vosso Deus.',
        'E os utensílios que te foram dados para o serviço da casa de teu Deus, restitui-os perante o Deus de Jerusalém.',
        'E tudo mais que for necessário para a casa de teu Deus, que te convenha dar, dá-lo-ás da casa dos tesouros do rei.',
        'E por mim mesmo, o rei Artaxerxes, se decreta a todos os tesoureiros que estão dalém do rio que tudo quanto vos pedir o sacerdote Esdras, escriba da lei do Deus dos céus, prontamente se faça.',
        'Até cem talentos de prata, e até cem coros de trigo, e até cem batos de vinho, e até cem batos de azeite; e sal à vontade.',
        'Tudo quanto se ordenar, segundo o mandado do Deus do céu, prontamente se faça para a casa do Deus dos céus; pois, para que haveria grande ira sobre o reino do rei e de seus filhos?',
        'Também vos fazemos saber acerca de todos os sacerdotes e levitas, cantores, porteiros, servidores do templo e ministros desta casa de Deus, que não será lícito impor-lhes, nem tributo, nem contribuição, nem renda.',
        'E tu, Esdras, conforme a sabedoria do teu Deus, que possues, nomeia magistrados e juízes, que julguem a todo o povo que está dalém do rio, a todos os que sabem as leis do teu Deus; e ao que não as sabe, lhe ensinarás.',
        'E todo aquele que não observar a lei do teu Deus e a lei do rei, seja julgado prontamente; quer seja morte, quer desterro, quer multa sobre os seus bens, quer prisão.',
        'Bendito seja o Senhor Deus de nossos pais, que tal inspirou ao coração do rei, para ornar a casa do Senhor, que está em Jerusalém.',
        'E que estendeu para mim a sua benignidade perante o rei e os seus conselheiros e todos os príncipes poderosos do rei. Assim me animei, segundo a mão do Senhor meu Deus sobre mim, e ajuntei dentre Israel alguns chefes para subirem comigo.',
      ],
      [
        'Estes, pois, são os chefes das casas paternas e esta a genealogia dos que subiram comigo de babilônia no reinado do rei Artaxerxes:',
        'Dos filhos de Finéias, Gérson; dos filhos de Itamar, Daniel; dos filhos de Davi, Hatus;',
        'Dos filhos de Secanias, e dos filhos de Parós, Zacarias, e com ele, segundo a genealogia, se contaram até cento e cinqüenta homens.',
        'Dos filhos de Paate-Moabe, Elioenai, filho de Zacarias, e com ele duzentos homens.',
        'Dos filhos de Secanias, o filho de Jeaziel, e com ele trezentos homens.',
        'E dos filhos de Adim, Ebede, filho de Jônatas, e com ele cinqüenta homens.',
        'E dos filhos de Elão, Jesaías, filho de Atalias, e com ele setenta homens.',
        'E dos filhos de Sefatias, Zebadias, filho de Micael, e com ele oitenta homens.',
        'Dos filhos de Joabe, Obadias, filho de Jeiel, e com ele duzentos e dezoito homens.',
        'E dos filhos de Selomite, o filho de Josifias, e com ele cento e sessenta homens.',
        'E dos filhos de Bebai, Zacarias, o filho de Bebai, e com ele vinte e oito homens.',
        'E dos filhos de Azgade, Joanã, o filho de Hacatã, e com ele cento e dez homens.',
        'E dos últimos filhos de Adonicão, cujos nomes eram estes: Elifelete, Jeiel e Semaías, e com eles sessenta homens.',
        'E dos filhos de Bigvai, Utai e Zabude, e com eles setenta homens.',
        'E ajuntei-os perto do rio que vai a Aava, e ficamos ali acampados três dias. Então atentei para o povo e para os sacerdotes, e não achei ali nenhum dos filhos de Levi.',
        'Enviei, pois, Eliezer, Ariel, Semaías, Elnatã, Jaribe, Elnatã, Natã, Zacarias e Mesulão, os chefes; como também a Joiaribe, e a Elnatã, que eram entendidos.',
        'E enviei-os com mandado a Ido, chefe em Casifia; e falei a eles o que deveriam dizer a Ido e aos seus irmãos, servidores do templo, em Casifia, que nos trouxessem ministros para a casa do nosso Deus.',
        'E trouxeram-nos, segundo a boa mão de Deus sobre nós, um homem entendido, dos filhos de Mali, filho de Levi, filho de Israel, a saber: Serebias, com os seus filhos e irmãos, dezoito;',
        'E a Hasabias, e com ele Jesaías, dos filhos de Merari, com seus irmãos e os filhos deles, vinte;',
        'E dos servidores do templo que Davi e os príncipes deram para o ministério dos levitas, duzentos e vinte servidores do templo; que foram todos mencionados por seus nomes.',
        'Então apregoei ali um jejum junto ao rio Aava, para nos humilharmos diante da face de nosso Deus, para lhe pedirmos caminho seguro para nós, para nossos filhos e para todos os nossos bens.',
        'Porque tive vergonha de pedir ao rei exército e cavaleiros para nos defenderem do inimigo pelo caminho; porquanto tínhamos falado ao rei, dizendo: A mão do nosso Deus é sobre todos os que o buscam, para o bem deles; mas o seu poder e a sua ira contra todos os que o deixam.',
        'Nós, pois, jejuamos, e pedimos isto ao nosso Deus, e moveu-se pelas nossas orações.',
        'Então separei doze dos chefes dos sacerdotes: Serebias, Hasabias, e com eles dez dos seus irmãos.',
        'E pesei-lhes a prata, o ouro e os vasos; que eram a oferta para a casa de nosso Deus, que ofereceram o rei, os seus conselheiros, os seus príncipes e todo o Israel que ali se achou.',
        'E pesei em suas mãos seiscentos e cinqüenta talentos de prata, e em vasos de prata cem talentos, e cem talentos de ouro,',
        'E vinte bacias de ouro, de mil dracmas, e dois vasos de bom metal lustroso, tão precioso como ouro.',
        'E disse-lhes: Vós sois santos ao Senhor, e são santos estes utensílios, como também esta prata e este ouro, oferta voluntária, oferecida ao Senhor Deus de vossos pais.',
        'Vigiai, pois, e guardai-os até que os peseis na presença dos chefes dos sacerdotes e dos levitas, e dos chefes dos pais de Israel, em Jerusalém, nas câmaras da casa do Senhor.',
        'Então os sacerdotes e os levitas receberam o peso da prata, do ouro e dos utensílios, para os trazerem a Jerusalém, à casa de nosso Deus.',
        'E partimos do rio Aava, no dia doze do primeiro mês, para irmos a Jerusalém; e a mão do nosso Deus estava sobre nós, e livrou-nos da mão dos inimigos, e dos que nos armavam ciladas pelo caminho.',
        'E chegamos a Jerusalém, e repousamos ali três dias.',
        'E no quarto dia se pesou a prata, o ouro e os utensílios, na casa do nosso Deus, por mão de Meremote, filho do sacerdote Urias; e com ele Eleazar, filho de Finéias, e com eles Jozabade, filho de Jesuá, e Noadias, filho de Binui, levitas.',
        'Tudo foi contado e pesado; e todo o peso foi registrado na mesma ocasião.',
        'E os exilados, que vieram do cativeiro, ofereceram holocaustos ao Deus de Israel: doze novilhos por todo o Israel, noventa e seis carneiros, setenta e sete cordeiros, e doze bodes em sacrifício pelo pecado; tudo em holocausto ao Senhor.',
        'Então deram as ordens do rei aos seus sátrapas, e aos governadores dalém do rio; e estes ajudaram o povo e a casa de Deus.',
      ],
      [
        'Acabadas, pois, estas coisas, chegaram-se a mim os príncipes, dizendo: O povo de Israel, os sacerdotes e os levitas, não se têm separado dos povos destas terras, seguindo as abominações dos cananeus, dos heteus, dos perizeus, dos jebuseus, dos amonitas, dos moabitas, dos egípcios, e dos amorreus.',
        'Porque tomaram das suas filhas para si e para seus filhos, e assim se misturou a linhagem santa com os povos dessas terras; e até os príncipes e magistrados foram os primeiros nesta transgressão.',
        'E, ouvindo eu tal coisa, rasguei as minhas vestes e o meu manto, e arranquei os cabelos da minha cabeça e da minha barba, e sentei-me atônito.',
        'Então se ajuntaram a mim todos os que tremiam das palavras do Deus de Israel por causa da transgressão dos do cativeiro; porém eu permaneci sentado atônito até ao sacrifício da tarde.',
        'E perto do sacrifício da tarde me levantei da minha aflição, havendo já rasgado as minhas vestes e o meu manto, e me pus de joelhos, e estendi as minhas mãos para o Senhor meu Deus;',
        'E disse: Meu Deus! Estou confuso e envergonhado, para levantar a ti a minha face, meu Deus; porque as nossas iniqüidades se multiplicaram sobre a nossa cabeça, e a nossa culpa tem crescido até aos céus.',
        'Desde os dias de nossos pais até ao dia de hoje estamos em grande culpa, e por causa das nossas iniqüidades somos entregues, nós e nossos reis e os nossos sacerdotes, na mão dos reis das terras, à espada, ao cativeiro, e ao roubo, e à confusão do rosto, como hoje se vê.',
        'E agora, por um pequeno momento, se manifestou a graça da parte do Senhor, nosso Deus, para nos deixar alguns que escapem, e para dar-nos uma estaca no seu santo lugar; para nos iluminar os olhos, ó Deus nosso, e para nos dar um pouco de vida na nossa servidão.',
        'Porque somos servos; porém na nossa servidão não nos desamparou o nosso Deus; antes estendeu sobre nós a sua benignidade perante os reis da Pérsia, para que nos desse vida, para levantarmos a casa do nosso Deus, e para restaurarmos as suas assolações; e para que nos desse uma parede de proteção em Judá e em Jerusalém.',
        'Agora, pois, ó nosso Deus, que diremos depois disto? Pois deixamos os teus mandamentos,',
        'Os quais mandaste pelo ministério de teus servos, os profetas, dizendo: A terra em que entrais para a possuir, terra imunda é pelas imundícias dos povos das terras, pelas suas abominações com que, na sua corrupção a encheram, de uma extremidade à outra.',
        'Agora, pois, vossas filhas não dareis a seus filhos, e suas filhas não tomareis para vossos filhos, e nunca procurareis a sua paz e o seu bem; para que sejais fortes, e comais o bem da terra, e a deixeis por herança a vossos filhos para sempre.',
        'E depois de tudo o que nos tem sucedido por causa das nossas más obras, e da nossa grande culpa, porquanto tu, ó nosso Deus, impediste que fôssemos destruídos, por causa da nossa iniqüidade, e ainda nos deste um remanescente como este;',
        'Tornaremos, pois, agora a violar os teus mandamentos e a aparentar-nos com os povos destas abominações? Não te indignarias tu assim contra nós até de todo nos consumir, até que não ficasse remanescente nem quem escapasse?',
        'Ah! Senhor Deus de Israel, justo és, pois ficamos qual um remanescente que escapou, como hoje se vê; eis que estamos diante de ti, na nossa culpa, porque ninguém há que possa estar na tua presença, por causa disto.',
      ],
      [
        'E enquanto Esdras orava, e fazia confissão, chorando e prostrando-se diante da casa de Deus, ajuntou-se a ele, de Israel, uma grande congregação de homens, mulheres e crianças; pois o povo chorava com grande choro.',
        'Então Secanias, filho de Jeiel, um dos filhos de Elão, tomou a palavra e disse a Esdras: Nós temos transgredido contra o nosso Deus, e casamos com mulheres estrangeiras dentre os povos da terra, mas, no tocante a isto, ainda há esperança para Israel.',
        'Agora, pois, façamos aliança com o nosso Deus de que despediremos todas as mulheres, e os que delas são nascidos, conforme ao conselho do meu senhor, e dos que tremem ao mandado do nosso Deus; e faça-se conforme a lei.',
        'Levanta-te, pois, porque te pertence este negócio, e nós seremos contigo; esforça-te, e age.',
        'Então Esdras se levantou, e ajuramentou os chefes dos sacerdotes e dos levitas, e a todo o Israel, de que fariam conforme a esta palavra; e eles juraram.',
        'E Esdras se levantou de diante da casa de Deus, e entrou na câmara de Joanã, filho de Eliasibe; e, chegando lá, não comeu pão, e nem bebeu água; porque lamentava pela transgressão dos do cativeiro.',
        'E fizeram passar pregão por Judá e Jerusalém, a todos os que vieram do cativeiro, para que se ajuntassem em Jerusalém.',
        'E que todo aquele que em três dias não viesse, segundo o conselho dos príncipes e dos anciãos, toda a sua fazenda se poria em interdito, e ele seria separado da congregação dos do cativeiro.',
        'Então todos os homens de Judá e Benjamim em três dias se ajuntaram em Jerusalém; era o nono mês, aos vinte dias do mês; e todo o povo se assentou na praça da casa de Deus, tremendo por este negócio e por causa das grandes chuvas.',
        'Então se levantou Esdras, o sacerdote, e disse-lhes: Vós tendes transgredido, e casastes com mulheres estrangeiras, aumentando a culpa de Israel.',
        'Agora, pois, fazei confissão ao Senhor Deus de vossos pais, e fazei a sua vontade; e apartai-vos dos povos das terras, e das mulheres estrangeiras.',
        'E respondeu toda a congregação, e disse em altas vozes: Assim seja, conforme às tuas palavras nos convém fazer.',
        'Porém o povo é muito, e também é tempo de grandes chuvas, e não se pode estar aqui fora; nem é obra de um dia nem de dois, porque somos muitos os que transgredimos neste negócio.',
        'Ora, ponham-se os nossos líderes, por toda a congregação sobre este negócio; e todos os que em nossas cidades casaram com mulheres estrangeiras venham em tempos apontados, e com eles os anciãos de cada cidade, e os seus juízes, até que desviemos de nós o ardor da ira do nosso Deus, por esta causa.',
        'Porém, somente Jônatas, filho de Asael, e Jaseías, filho de Ticva, se opuseram a isto; e Mesulão, e Sabetai, levita, os ajudaram.',
        'E assim o fizeram os que voltaram do cativeiro; e o sacerdote Esdras e os homens, chefes dos pais, segundo a casa de seus pais, e todos pelos seus nomes, foram apontados; e assentaram-se no primeiro dia do décimo mês, para inquirirem neste negócio.',
        'E no primeiro dia do primeiro mês acabaram de tratar com todos os homens que casaram com mulheres estrangeiras.',
        'E acharam-se dos filhos dos sacerdotes que casaram com mulheres estrangeiras: Dos filhos de Jesuá, filho de Jozadaque, e seus irmãos, Maaséias, e Eliezer, e Jaribe, e Gedalias.',
        'E deram as suas mãos prometendo que despediriam suas mulheres; e, achando-se culpados, ofereceram um carneiro do rebanho pelo seu delito.',
        'E dos filhos de Imer: Hanani e Zebadias.',
        'E dos filhos de Harim: Maaséias, Elias, Semaías, Jeiel e Uzias.',
        'E dos filhos de Pasur: Elioenai, Maaséias, Ismael, Netanel, Jozabade e Eleasa.',
        'E dos levitas: Jozabade, Simei, Quelaías (este é Quelita), Petaías, Judá e Eliezer.',
        'E dos cantores: Eliasibe; e dos porteiros: Salum, Telém e Uri.',
        'E de Israel, dos filhos de Parós: Ramias, Jezias, Malquias, Miamim, Eleazar, Malquias e Benaia.',
        'E dos filhos de Elão: Matanias, Zacarias, Jeiel, Abdi, Jeremote e Elias.',
        'E dos filhos de Zatu: Elioenai, Eliasibe, Matanias, Jeremote, Zabade e Aziza.',
        'E dos filhos de Bebai: Joanã, Hananias, Zabai e Atlai.',
        'E dos filhos de Bani: Mesulão, Maluque, Adaías, Jasube, Seal, Jeremote.',
        'E dos filhos de Paate-Moabe: Adna, Quelal, Benaia, Maséias, Matanias, Bezalel, Binui e Manassés.',
        'E dos filhos de Harim: Eliezer, Josias, Malquias, Semaías, Simeão,',
        'Benjamim, Maluque, Semarias.',
        'Dos filhos de Hasum: Matenai, Matatá, Zabade, Elifelete, Jeremai, Manassés e Simei.',
        'Dos filhos de Bani: Maadai, Anrão, e Uel,',
        'Benaia, Bedias, Queluí,',
        'Vanias, Meremote, Eliasibe,',
        'Matanias, Matnai e Jaasai,',
        'E Bani, Binui, Simei,',
        'E Selemias, Natã e Adaías,',
        'Macnadbai, Sasai, Sarai,',
        'Azareel, Selemias, Semarias,',
        'Salum, Amarias e José.',
        'Dos filhos de Nebo: Jeiel, Matitias, Zabade, Zebina, Jadai, Joel e Benaia.',
        'Todos estes tomaram mulheres estrangeiras; e alguns deles tinham mulheres de quem tiveram filhos.',
      ],
    ],
    livro: 'esdras',
  },
  {
    abbrev: 'ne',
    capitulos: [
      [
        'As palavras de Neemias, filho de Hacalias. E sucedeu no mês de Quislev, no ano vigésimo, estando eu em Susã, a fortaleza,',
        'Que veio Hanani, um de meus irmãos, ele e alguns de Judá; e perguntei-lhes pelos judeus que escaparam, e que restaram do cativeiro, e acerca de Jerusalém.',
        'E disseram-me: Os restantes, que ficaram do cativeiro, lá na província estão em grande miséria e desprezo; e o muro de Jerusalém fendido e as suas portas queimadas a fogo.',
        'E sucedeu que, ouvindo eu estas palavras, assentei-me e chorei, e lamentei por alguns dias; e estive jejuando e orando perante o Deus dos céus.',
        'E disse: Ah! Senhor Deus dos céus, Deus grande e terrível! Que guarda a aliança e a benignidade para com aqueles que o amam e guardam os seus mandamentos;',
        'Estejam, pois, atentos os teus ouvidos e os teus olhos abertos, para ouvires a oração do teu servo, que eu hoje faço perante ti, dia e noite, pelos filhos de Israel, teus servos; e faço confissão pelos pecados dos filhos de Israel, que temos cometido contra ti; também eu e a casa de meu pai temos pecado.',
        'De todo nos corrompemos contra ti, e não guardamos os mandamentos, nem os estatutos, nem os juízos, que ordenaste a Moisés, teu servo.',
        'Lembra-te, pois, da palavra que ordenaste a Moisés, teu servo, dizendo: Vós transgredireis, e eu vos espalharei entre os povos.',
        'E vós vos convertereis a mim, e guardareis os meus mandamentos, e os cumprireis; então, ainda que os vossos rejeitados estejam na extremidade do céu, de lá os ajuntarei e os trarei ao lugar que tenho escolhido para ali fazer habitar o meu nome.',
        'Eles são teus servos e o teu povo que resgataste com a tua grande força e com a tua forte mão.',
        'Ah! Senhor, estejam, pois, atentos os teus ouvidos à oração do teu servo, e à oração dos teus servos que desejam temer o teu nome; e faze prosperar hoje o teu servo, e dá-lhe graça perante este homem. Então era eu copeiro do rei.',
      ],
      [
        'Sucedeu, pois, no mês de Nisã, no ano vigésimo do rei Artaxerxes, que estava posto vinho diante dele, e eu peguei o vinho e o dei ao rei; porém eu nunca estivera triste diante dele.',
        'E o rei me disse: Por que está triste o teu rosto, pois não estás doente? Não é isto senão tristeza de coração; então temi sobremaneira.',
        'E disse ao rei: Viva o rei para sempre! Como não estaria triste o meu rosto, estando a cidade, o lugar dos sepulcros de meus pais, assolada, e tendo sido consumidas as suas portas a fogo?',
        'E o rei me disse: Que me pedes agora? Então orei ao Deus dos céus,',
        'E disse ao rei: Se é do agrado do rei, e se o teu servo é aceito em tua presença, peço-te que me envies a Judá, à cidade dos sepulcros de meus pais, para que eu a reedifique.',
        'Então o rei me disse, estando a rainha assentada junto a ele: Quanto durará a tua viagem, e quando voltarás? E aprouve ao rei enviar-me, apontando-lhe eu um certo tempo.',
        'Disse mais ao rei: Se ao rei parece bem, dêem-se-me cartas para os governadores dalém do rio, para que me permitam passar até que chegue a Judá.',
        'Como também uma carta para Asafe, guarda da floresta do rei, para que me dê madeira para cobrir as portas do paço da casa, para o muro da cidade e para a casa em que eu houver de entrar. E o rei mas deu, segundo a boa mão de Deus sobre mim.',
        'Então fui aos governadores dalém do rio, e dei-lhes as cartas do rei; e o rei tinha enviado comigo capitàes do exército e cavaleiros.',
        'O que ouvindo Sambalate, o horonita, e Tobias, o servo amonita, lhes desagradou extremamente que alguém viesse a procurar o bem dos filhos de Israel.',
        'E cheguei a Jerusalém, e estive ali três dias.',
        'E de noite me levantei, eu e poucos homens comigo, e não declarei a ninguém o que o meu Deus me pôs no coração para fazer em Jerusalém; e não havia comigo animal algum, senão aquele em que estava montado.',
        'E de noite saí pela porta do vale, e para o lado da fonte do dragão, e para a porta do monturo, e contemplei os muros de Jerusalém, que estavam fendidos, e as suas portas, que tinham sido consumidas pelo fogo.',
        'E passei à porta da fonte, e ao tanque do rei; e não havia lugar por onde pudesse passar o animal em que estava montado.',
        'Ainda, de noite subi pelo ribeiro e contemplei o muro; e, virando entrei pela porta do vale; assim voltei.',
        'E não souberam os magistrados aonde eu fora nem o que eu fazia; porque ainda nem aos judeus, nem aos sacerdotes, nem aos nobres, nem aos magistrados, nem aos mais que faziam a obra, até então tinha declarado coisa alguma.',
        'Então lhes disse: Bem vedes vós a miséria em que estamos, que Jerusalém está assolada, e que as suas portas têm sido queimadas a fogo; vinde, pois, e reedifiquemos o muro de Jerusalém, e não sejamos mais um opróbrio.',
        'Então lhes declarei como a mão do meu Deus me fora favorável, como também as palavras do rei, que ele me tinha dito; então disseram: Levantemo-nos, e edifiquemos. E esforçaram as suas mãos para o bem.',
        'O que ouvindo Sambalate, o horonita, e Tobias, o servo amonita, e Gesém, o árabe, zombaram de nós, e desprezaram-nos, e disseram: Que é isto que fazeis? Quereis rebelar-vos contra o rei?',
        'Então lhes respondi, e disse: O Deus dos céus é o que nos fará prosperar: e nós, seus servos, nos levantaremos e edificaremos; mas vós não tendes parte, nem justiça, nem memória em Jerusalém.',
      ],
      [
        'E levantou-se Eliasibe, o sumo sacerdote, com os seus irmãos, os sacerdotes, e reedificaram a porta do gado, a qual consagraram; e levantaram as suas portas, e até à torre de Meá consagraram, e até à torre de Hananel.',
        'E junto a ele edificaram os homens de Jericó; também ao seu lado edificou Zacur, filho de Imri.',
        'E à porta do peixe edificaram os filhos de Hassenaá; a qual emadeiraram, e levantaram as suas portas com as suas fechaduras e os seus ferrolhos.',
        'E ao seu lado reparou Meremote, filho de Urias, o filho de Coz; e ao seu lado reparou Mesulão, filho de Berequias, o filho de Mesezabeel; e ao seu lado reparou Zadoque, filho de Baana.',
        'E ao seu lado repararam os tecoítas; porém os seus nobres não submeteram a cerviz ao serviço de seu Senhor.',
        'E a porta velha repararam-na Joiada, filho de Paséia, e Mesulão, filho de Besodias; estes a emadeiraram, e levantaram as suas portas com as suas fechaduras e os seus ferrolhos.',
        'E ao seu lado repararam Melatias, o gibeonita, e Jadom, meronotita, homens de Gibeom e Mizpá, que pertenciam ao domínio do governador dalém do rio.',
        'Ao seu lado reparou Uziel, filho de Haraías, um dos ourives; e ao seu lado reparou Hananias, filho de um dos boticários; e fortificaram a Jerusalém até ao muro largo.',
        'E ao seu lado reparou Refaías, filho de Hur, líder da metade de Jerusalém.',
        'E ao seu lado reparou Jedaías, filho de Harumafe, e defronte de sua casa e ao seu lado reparou Hatus, filho de Hasabnéias.',
        'A outra porção reparou Malquias, filho de Harim, e Hasube, filho de Paate-Moabe; como também a torre dos fornos.',
        'E ao seu lado reparou Salum, filho de Haloés, líder da outra meia parte de Jerusalém, ele e suas filhas.',
        'A porta do vale reparou-a Hanum e os moradores de Zanoa; estes a edificaram, e lhe levantaram as portas com as suas fechaduras e os seus ferrolhos, como também mil côvados do muro, até a porta do monturo.',
        'E a porta do monturo reparou-a Malquias, filho de Recabe, líder do distrito de Bete-Haquerém; este a edificou, e lhe levantou as portas com as suas fechaduras e os seus ferrolhos.',
        'E a porta da fonte reparou-a Salum, filho de Col-Hoze, líder do distrito de Mizpá; este a edificou, e a cobriu, e lhe levantou as portas com as suas fechaduras e os seus ferrolhos, como também o muro do tanque de Hasselá, ao pé do jardim do rei, e até aos degraus que descem da cidade de Davi.',
        'Depois dele edificou Neemias, filho de Azbuque, líder da metade de Bete-Zur, até defronte dos sepulcros de Davi, até ao tanque artificial e até à casa dos valentes.',
        'Depois dele repararam os levitas, Reum, filho de Bani; ao seu lado reparou Hasabias, líder da metade de Queila, no seu distrito.',
        'Depois dele repararam seus irmãos, Bavai, filho de Henadade, líder da outra meia parte de Queila.',
        'Ao seu lado reparou Ezer, filho de Jesuá, líder de Mizpá, outra porção, defronte da subida à casa das armas, à esquina.',
        'Depois dele reparou com grande ardor Baruque, filho de Zabai, outra medida, desde a esquina até à porta da casa de Eliasibe, o sumo sacerdote.',
        'Depois dele reparou Meremote, filho de Urias, o filho de Coz, outra porção, desde a porta da casa de Eliasibe, até à extremidade da casa de Eliasibe.',
        'E depois dele repararam os sacerdotes que habitavam na campina.',
        'Depois reparou Benjamim e Hasube, defronte da sua casa; depois dele reparou Azarias, filho de Maaséias, o filho de Ananias, junto à sua casa.',
        'Depois dele reparou Binui, filho de Henadade, outra porção, desde a casa de Azarias até à esquina, e até ao canto.',
        'Palal, filho de Uzai, reparou defronte da esquina, e a torre que sai da casa real superior, que está junto ao pátio da prisão; depois dele Pedaías, filho de Parós.',
        'E os servidores do templo que habitavam em Ofel, até defronte da porta das águas, para o oriente, e até à torre alta.',
        'Depois repararam os tecoítas outra porção, defronte da torre grande e alta, e até ao muro de Ofel.',
        'Desde acima da porta dos cavalos repararam os sacerdotes, cada um defronte da sua casa.',
        'Depois deles reparou Zadoque, filho de Imer, defronte da sua casa; e depois dele reparou Semaías, filho de Secanias, guarda da porta oriental.',
        'Depois dele reparou Hananias, filho de Selemias, e Hanum, filho de Zalafe, o sexto, outra porção; depois dele reparou Mesulão, filho de Berequias, defronte da sua câmara.',
        'Depois dele reparou Malquias, filho de um ourives, até à casa dos servidores do templo e mercadores, defronte da porta de Mifcade, e até à câmara do canto.',
        'E entre a câmara do canto e a porta do gado, repararam os ourives e os mercadores.',
      ],
      [
        'E sucedeu que, ouvindo Sambalate que edificávamos o muro, ardeu em ira, e se indignou muito; e escarneceu dos judeus.',
        'E falou na presença de seus irmãos, e do exército de Samaria, e disse: Que fazem estes fracos judeus? Permitir-se-lhes-á isto? Sacrificarão? Acabá-lo-ão num só dia? Vivificarão dos montões do pó as pedras que foram queimadas?',
        'E estava com ele Tobias, o amonita, e disse: Ainda que edifiquem, contudo, vindo uma raposa, derrubará facilmente o seu muro de pedra.',
        'Ouve, ó nosso Deus, que somos tão desprezados, e torna o seu opróbrio sobre a sua cabeça, e dá-os por presa, na terra do cativeiro.',
        'E não cubras a sua iniqüidade, e não se risque de diante de ti o seu pecado, pois que te irritaram na presença dos edificadores.',
        'Porém edificamos o muro, e todo o muro se fechou até sua metade; porque o coração do povo se inclinava a trabalhar.',
        'E sucedeu que, ouvindo Sambalate e Tobias, e os árabes, os amonitas, e os asdoditas, que tanto ia crescendo a reparação dos muros de Jerusalém, que já as roturas se começavam a tapar, iraram-se sobremodo,',
        'E ligaram-se entre si todos, para virem guerrear contra Jerusalém, e para os desviarem do seu intento.',
        'Porém nós oramos ao nosso Deus e pusemos uma guarda contra eles, de dia e de noite, por causa deles.',
        'Então disse Judá: Já desfaleceram as forças dos carregadores, e o pó é muito, e nós não poderemos edificar o muro.',
        'Disseram, porém, os nossos inimigos: Nada saberão disto, nem verão, até que entremos no meio deles, e os matemos; assim faremos cessar a obra.',
        'E sucedeu que, vindo os judeus que habitavam entre eles, dez vezes nos disseram: De todos os lugares, tornarão contra nós.',
        'Então pus guardas nos lugares baixos por detrás do muro e nos altos; e pus ao povo pelas suas famílias com as suas espadas, com as suas lanças, e com os seus arcos.',
        'E olhei, e levantei-me, e disse aos nobres, aos magistrados, e ao restante do povo: Não os temais; lembrai-vos do grande e terrível Senhor, e pelejai pelos vossos irmãos, vossos filhos, vossas mulheres e vossas casas.',
        'E sucedeu que, ouvindo os nossos inimigos que já o sabíamos, e que Deus tinha dissipado o conselho deles, todos voltamos ao muro, cada um à sua obra.',
        'E sucedeu que, desde aquele dia, metade dos meus servos trabalhava na obra, e metade deles tinha as lanças, os escudos, os arcos e as couraças; e os líderes estavam por detrás de toda a casa de Judá.',
        'Os que edificavam o muro, os que traziam as cargas e os que carregavam, cada um com uma das mãos fazia a obra e na outra tinha as armas.',
        'E os edificadores cada um trazia a sua espada cingida aos lombos, e edificavam; e o que tocava a trombeta estava junto comigo.',
        'E disse eu aos nobres, aos magistrados e ao restante do povo: Grande e extensa é a obra, e nós estamos apartados do muro, longe uns dos outros.',
        'No lugar onde ouvirdes o som da buzina, ali vos ajuntareis conosco; o nosso Deus pelejará por nós.',
        'Assim trabalhávamos na obra; e metade deles tinha as lanças desde a subida da alva até ao sair das estrelas.',
        'Também naquele tempo disse ao povo: Cada um com o seu servo fique em Jerusalém, para que à noite nos sirvam de guarda, e de dia na obra.',
        'E nem eu, nem meus irmãos, nem meus servos, nem os homens da guarda que me seguiam largávamos as nossas vestes; cada um tinha suas armas e água.',
      ],
      [
        'Foi, porém, grande o clamor do povo e de suas mulheres, contra os judeus, seus irmãos.',
        'Porque havia quem dizia: Nós, nossos filhos e nossas filhas, somos muitos; então tomemos trigo, para que comamos e vivamos.',
        'Também havia quem dizia: As nossas terras, as nossas vinhas e as nossas casas empenhamos, para tomarmos trigo nesta fome.',
        'Também havia quem dizia: Tomamos emprestado dinheiro até para o tributo do rei, sobre as nossas terras e as nossas vinhas.',
        'Agora, pois, a nossa carne é como a carne de nossos irmãos, e nossos filhos como seus filhos; e eis que sujeitamos nossos filhos e nossas filhas para serem servos; e até algumas de nossas filhas são tão sujeitas, que já não estão no poder de nossas mãos; e outros têm as nossas terras e as nossas vinhas.',
        'Ouvindo eu, pois, o seu clamor, e estas palavras, muito me indignei.',
        'E considerei comigo mesmo no meu coração; depois pelejei com os nobres e com os magistrados, e disse-lhes: Sois usurários cada um para com seu irmão. E convoquei contra eles uma grande assembléia.',
        'E disse-lhes: Nós resgatamos os judeus, nossos irmãos, que foram vendidos às nações, segundo nossas posses; e vós outra vez venderíeis a vossos irmãos, ou vender-se-iam a nós? Então se calaram, e não acharam que responder.',
        'Disse mais: Não é bom o que fazeis; porventura não andaríeis no temor do nosso Deus, por causa do opróbrio das nações, os nossos inimigos?',
        'Também eu, meus irmãos e meus servos, a juros lhes temos emprestado dinheiro e trigo. Deixemos este ganho.',
        'Restituí-lhes hoje, vos peço, as suas terras, as suas vinhas, os seus olivais e as suas casas; como também a centésima parte do dinheiro, do trigo, do mosto e do azeite, que vós exigis deles.',
        'Então disseram: Restituir-lhes-emos, e nada procuraremos deles; faremos assim como dizes. Então chamei os sacerdotes, e os fiz jurar que fariam conforme a esta palavra.',
        'Também sacudi as minhas vestes, e disse: Assim sacuda Deus todo o homem da sua casa e do seu trabalho que não confirmar esta palavra, e assim seja sacudido e vazio. E toda a congregação disse: Amém! E louvaram ao Senhor; e o povo fez conforme a esta palavra.',
        'Também desde o dia em que me mandou que eu fosse seu governador na terra de Judá, desde o ano vinte, até ao ano trinta e dois do rei Artaxerxes, doze anos, nem eu nem meus irmãos comemos o pão do governador.',
        'Mas os primeiros governadores, que foram antes de mim, oprimiram o povo, e tomaram-lhe pão e vinho e, além disso, quarenta siclos de prata, como também os seus servos dominavam sobre o povo; porém eu assim não fiz, por causa do temor de Deus.',
        'Como também na obra deste muro fiz reparação, e terra nenhuma compramos; e todos os meus servos se ajuntaram ali à obra.',
        'Também dos judeus e dos magistrados, cento e cinqüenta homens, e os que vinham a nós dentre as nações que estão ao redor de nós, se punham à minha mesa.',
        'E o que se preparava para cada dia era um boi e seis ovelhas escolhidas; também aves se me preparavam e, de dez em dez dias, muito vinho de todas as espécies; e nem por isso exigi o pão do governador, porquanto a servidão deste povo era grande.',
        'Lembra-te de mim para bem, ó meu Deus, e de tudo quanto fiz a este povo.',
      ],
      [
        'Sucedeu que, ouvindo Sambalate, Tobias, Gesém, o árabe, e o resto dos nossos inimigos, que eu tinha edificado o muro, e que nele já não havia brecha alguma, ainda que até este tempo não tinha posto as portas nos portais,',
        'Sambalate e Gesém mandaram dizer-me: Vem, e congreguemo-nos juntamente nas aldeias, no vale de Ono. Porém intentavam fazer-me mal.',
        'E enviei-lhes mensageiros a dizer: Faço uma grande obra, de modo que não poderei descer; por que cessaria esta obra, enquanto eu a deixasse, e fosse ter convosco?',
        'E do mesmo modo enviaram a mim quatro vezes; e da mesma forma lhes respondi.',
        'Então Sambalate ainda pela quinta vez me enviou seu servo com uma carta aberta na sua mão;',
        'E na qual estava escrito: Entre os gentios se ouviu, e Gasmu diz: Tu e os judeus intentais rebelar-vos, então edificas o muro; e tu te farás rei deles segundo estas palavras;',
        'E que puseste profetas, para pregarem de ti em Jerusalém, dizendo: Este é rei em Judá; de modo que o rei o ouvirá, segundo estas palavras; vem, pois, agora, e consultemos juntamente.',
        'Porém eu mandei dizer-lhe: De tudo o que dizes coisa nenhuma sucedeu; mas tu, do teu coração, o inventas.',
        'Porque todos eles procuravam atemorizar-nos, dizendo: As suas mãos largarão a obra, e não se efetuará. Agora, pois, ó Deus, fortalece as minhas mãos.',
        'E, entrando eu em casa de Semaías, filho de Delaías, o filho de Meetabel (que estava encerrado), disse ele: Vamos juntamente à casa de Deus, ao meio do templo, e fechemos as portas do templo; porque virão matar-te; sim, de noite virão matar-te.',
        'Porém eu disse: Um homem como eu fugiria? E quem há, como eu, que entre no templo para que viva? De maneira nenhuma entrarei.',
        'E percebi que não era Deus quem o enviara; mas esta profecia falou contra mim, porquanto Tobias e Sambalate o subornaram.',
        'Para isto o subornaram, para me atemorizar, e para que assim fizesse, e pecasse, para que tivessem alguma causa para me infamarem, e assim me vituperarem.',
        'Lembra-te, meu Deus, de Tobias e de Sambalate, conforme a estas suas obras, e também da profetisa Noadia, e dos mais profetas que procuraram atemorizar-me.',
        'Acabou-se, pois, o muro aos vinte e cinco do mês de Elul; em cinqüenta e dois dias.',
        'E sucedeu que, ouvindo-o todos os nossos inimigos, todos os povos que havia em redor de nós temeram, e abateram-se muito a seus próprios olhos; porque reconheceram que o nosso Deus fizera esta obra.',
        'Também naqueles dias alguns nobres de Judá escreveram muitas cartas que iam para Tobias; e as cartas de Tobias vinham para eles.',
        'Porque muitos em Judá lhe eram ajuramentados, porque era genro de Secanias filho de Ará; e seu filho Joanã se casara com a filha de Mesulão, filho de Berequias.',
        'Também as suas boas ações contavam perante mim, e as minhas palavras transmitiam a ele; portanto Tobias escrevia cartas para me atemorizar.',
      ],
      [
        'Sucedeu que, depois que o muro foi edificado, eu levantei as portas; e foram estabelecidos os porteiros, os cantores e os levitas.',
        'Eu nomeei a Hanani, meu irmão, e a Hananias, líder da fortaleza, sobre Jerusalém; porque ele era homem fiel e temente a Deus, mais do que muitos.',
        'E disse-lhes: Não se abram as portas de Jerusalém até que o sol aqueça, e enquanto os que assisti-rem ali permanecerem, fechem as portas, e vós trancai-as; e ponhamse guardas dos moradores de Jerusalém, cada um na sua guarda, e cada um diante da sua casa.',
        'E era a cidade larga de espaço, e grande, porém pouco povo havia dentro dela; e ainda as casas não estavam edificadas.',
        'Então o meu Deus me pôs no coração que ajuntasse os nobres, os magistrados e o povo, para registrar as genealogias; e achei o livro da genealogia dos que subiram primeiro e nele estava escrito o seguinte:',
        'Estes são os filhos da província, que subiram do cativeiro dos exilados, que transportara Nabucodonosor, rei de Babilônia; e voltaram para Jerusalém e para Judá, cada um para a sua cidade;',
        'Os quais vieram com Zorobabel, Jesuá, Neemias, Azarias, Raamias, Naamani, Mordecai, Bilsã, Misperete, Bigvai, Neum, e Baaná; este é o número dos homens do povo de Israel.',
        'Foram os filhos de Parós, dois mil, cento e setenta e dois.',
        'Os filhos de Sefatias, trezentos e setenta e dois.',
        'Os filhos de Ará, seiscentos e cinqüenta e dois.',
        'Os filhos de Paate-Moabe, dos filhos de Jesuá e de Joabe, dois mil, oitocentos e dezoito.',
        'Os filhos de Elão, mil, duzentos e cinqüenta e quatro.',
        'Os filhos de Zatu, oitocentos e quarenta e cinco.',
        'Os filhos de Zacai, setecentos e sessenta.',
        'Os filhos de Binui, seiscentos e quarenta e oito.',
        'Os filhos de Bebai, seiscentos e vinte e oito.',
        'Os filhos de Azgade, dois mil, trezentos e vinte e dois.',
        'Os filhos de Adonicão, seiscentos e sessenta e sete.',
        'Os filhos de Bigvai, dois mil e sessenta e sete.',
        'Os filhos de Adim, seiscentos e cinqüenta e cinco.',
        'Os filhos de Ater, de Ezequias, noventa e oito.',
        'Os filhos de Hassum, trezentos e vinte e oito.',
        'Os filhos de Bezai, trezentos e vinte e quatro.',
        'Os filhos de Harife, cento e doze.',
        'Os filhos de Gibeom, noventa e cinco.',
        'Os homens de Belém e de Netofa, cento e oitenta e oito.',
        'Os homens de Anatote, cento e vinte e oito.',
        'Os homens de Bete-Azmavete, quarenta e dois.',
        'Os homens de Quiriate-Jearim, Quefira e Beerote, setecentos e quarenta e três.',
        'Os homens de Ramá e Geba, seiscentos e vinte e um.',
        'Os homens de Micmás, cento e vinte e dois.',
        'Os homens de Betel e Ai, cento e vinte e três.',
        'Os homens do outro Nebo, cinqüenta e dois.',
        'Os filhos do outro Elão, mil, duzentos e cinqüenta e quatro:',
        'Os filhos de Harim, trezentos e vinte.',
        'Os filhos de Jericó, trezentos e quarenta e cinco.',
        'Os filhos de Lode, Hadide e Ono, setecentos e vinte e um.',
        'Os filhos de Senaá, três mil, novecentos e trinta.',
        'Os sacerdotes: Os filhos de Jedaías, da casa de Jesuá, novecentos e setenta e três.',
        'Os filhos de Imer, mil e cinqüenta e dois.',
        'Os filhos de Pasur, mil, duzentos e quarenta e sete.',
        'Os filhos de Harim, mil e dezessete.',
        'Os levitas: Os filhos de Jesuá, de Cadmiel, dos filhos de Hodeva, setenta e quatro.',
        'Os cantores: Os filhos de Asafe, cento e quarenta e oito.',
        'Os porteiros: Os filhos de Salum, os filhos de Ater, os filhos de Talmom, os filhos de Acube, os filhos de Hatita, os filhos de Sobai, cento e trinta e oito.',
        'Os servidores do templo: Os filhos de Zia, os filhos de Hasufa, os filhos de Tabaote,',
        'Os filhos de Queros, os filhos de Sia, os filhos de Padom,',
        'Os filhos de Lebana, os filhos de Hagaba, os filhos de Salmai,',
        'Os filhos de Hanã, os filhos de Gidel, os filhos de Gaar,',
        'Os filhos de Reaías, os filhos de Rezim, os filhos de Necoda,',
        'Os filhos de Gazão, os filhos de Uzá, os filhos de Paseá,',
        'Os filhos de Besai, os filhos de Meunim, os filhos de Nefussim,',
        'Os filhos de Bacbuque, os filhos de Hacufa, os filhos de Harur,',
        'Os filhos de Bazlite, os filhos de Meída, os filhos de Harsa,',
        'Os filhos de Barcos, os filhos de Sísera, os filhos de Tamá,',
        'Os filhos de Neziá, os filhos de Hatifa.',
        'Os filhos dos servos de Salomão, os filhos de Sotai, os filhos de Soferete, os filhos de Perida,',
        'Os filhos de Jaala, os filhos de Darcom, os filhos de Gidel,',
        'Os filhos de Sefatias, os filhos de Hatil, os filhos de Poquerete-Hazebaim, os filhos de Amom.',
        'Todos os servidores do templo e os filhos dos servos de Salomão, trezentos e noventa e dois.',
        'Também estes subiram de Tel-Melá, e Tel-Harsa, Querube, Adom, Imer; porém não puderam provar que a casa de seus pais e a sua linhagem, eram de Israel.',
        'Os filhos de Delaías, os filhos de Tobias, os filhos de Necoda, seiscentos e quarenta e dois.',
        'E dos sacerdotes: os filhos de Habaías, os filhos de Coz, os filhos de Barzilai, que tomara uma mulher das filhas de Barzilai, o gileadita, e que foi chamado do seu nome.',
        'Estes buscaram o seu registro nos livros genealógicos, porém não se achou; então, como imundos, foram excluídos do sacerdócio.',
        'E o governador lhes disse que não comessem das coisas sagradas, até que se apresentasse o sacerdote com Urim e Tumim.',
        'Toda esta congregação junta foi de quarenta e dois mil, trezentos e sessenta,',
        'Afora os seus servos e as suas servas, que foram sete mil, trezentos e trinta e sete; e tinham duzentos e quarenta e cinco cantores e cantoras.',
        'Os seus cavalos, setecentos e trinta e seis; os seus mulos, duzentos e quarenta e cinco.',
        'Camelos, quatrocentos e trinta e cinco; jumentos, seis mil, setecentos e vinte.',
        'E uma parte dos chefes dos pais contribuíram para a obra. O governador deu para o tesouro, em ouro, mil dracmas, cinqüenta bacias, e quinhentas e trinta vestes sacerdotais.',
        'E alguns mais dos chefes dos pais contribuíram para o tesouro da obra, em ouro, vinte mil dracmas, e em prata, duas mil e duzentas libras.',
        'E o que deu o restante do povo foi, em ouro, vinte mil dracmas, e em prata, duas mil libras; e sessenta e sete vestes sacerdotais.',
        'E habitaram os sacerdotes, os levitas, os porteiros, os cantores, alguns do povo, os servidores do templo, e todo o Israel nas suas cidades.',
      ],
      [
        'E chegado o sétimo mês, e estando os filhos de Israel nas suas cidades, todo o povo se ajuntou como um só homem, na praça, diante da porta das águas; e disseram a Esdras, o escriba, que trouxesse o livro da lei de Moisés, que o SENHOR tinha ordenado a Israel.',
        'E Esdras, o sacerdote, trouxe a lei perante a congregação, tanto de homens como de mulheres, e todos os que podiam ouvir com entendimento, no primeiro dia do sétimo mês.',
        'E leu no livro diante da praça, que está diante da porta das águas, desde a alva até ao meio-dia, perante homens e mulheres, e os que podiam entender; e os ouvidos de todo o povo estavam atentos ao livro da lei.',
        'E Esdras, o escriba, estava sobre um púlpito de madeira, que fizeram para aquele fim; e estava em pé junto a ele, à sua mão direita, Matitias, Sema, Anaías, Urias, Hilquias e Maaséias; e à sua mão esquerda, Pedaías, Misael, Melquias, Hasum, Hasbadana, Zacarias e Mesulão.',
        'E Esdras abriu o livro perante à vista de todo o povo; porque estava acima de todo o povo; e, abrindo-o ele, todo o povo se pôs em pé.',
        'E Esdras louvou ao Senhor, o grande Deus; e todo o povo respondeu: Amém, Amém! levantando as suas mãos; e inclinaram suas cabeças, e adoraram ao Senhor, com os rostos em terra.',
        'E Jesuá, Bani, Serebias, Jamim, Acube, Sabetai, Hodias, Maaséias, Quelita, Azarias, Jozabade, Hanã, Pelaías, e os levitas ensinavam o povo na lei; e o povo estava no seu lugar.',
        'E leram no livro, na lei de Deus; e declarando, e explicando o sentido, faziam que, lendo, se entendesse.',
        'E Neemias, que era o governador, e o sacerdote Esdras, o escriba, e os levitas que ensinavam ao povo, disseram a todo o povo: Este dia é consagrado ao Senhor vosso Deus, então não vos lamenteis, nem choreis. Porque todo o povo chorava, ouvindo as palavras da lei.',
        'Disse-lhes mais: Ide, comei as gorduras, e bebei as doçuras, e enviai porções aos que não têm nada preparado para si; porque este dia é consagrado ao nosso Senhor; portanto não vos entristeçais; porque a alegria do Senhor é a vossa força.',
        'E os levitas fizeram calar a todo o povo, dizendo: Calai-vos; porque este dia é santo; por isso não vos entristeçais.',
        'Então todo o povo se foi a comer, a beber, a enviar porções e a fazer grande regozijo; porque entenderam as palavras que lhes fizeram saber.',
        'E no dia seguinte ajuntaram-se os chefes dos pais de todo o povo, os sacerdotes e os levitas, a Esdras, o escriba; e isto para atentarem nas palavras da lei.',
        'E acharam escrito na lei que o Senhor ordenara, pelo ministério de Moisés, que os filhos de Israel habitassem em cabanas, na solenidade da festa, no sétimo mês.',
        'Assim publicaram, e fizeram passar pregão por todas as suas cidades, e em Jerusalém, dizendo: Saí ao monte, e trazei ramos de oliveiras, e ramos de zambujeiros, e ramos de murtas, e ramos de palmeiras, e ramos de árvores espessas, para fazer cabanas, como está escrito.',
        'Saiu, pois, o povo, e os trouxeram, e fizeram para si cabanas, cada um no seu terraço, nos seus pátios, e nos átrios da casa de Deus, na praça da porta das águas, e na praça da porta de Efraim.',
        'E toda a congregação dos que voltaram do cativeiro fizeram cabanas, e habitaram nas cabanas, porque nunca fizeram assim os filhos de Israel, desde os dias de Jesua, filho de Num, até àquele dia; e houve mui grande alegria.',
        'E, de dia em dia, Esdras leu no livro da lei de Deus, desde o primeiro dia até ao derradeiro; e celebraram a solenidade da festa sete dias, e no oitavo dia, houve uma assembléia solene, segundo o rito.',
      ],
      [
        'E, no dia vinte e quatro deste mês, ajuntaram-se os filhos de Israel com jejum e com sacos, e traziam terra sobre si.',
        'E a descendência de Israel se apartou de todos os estrangeiros, e puseram-se em pé, e fizeram confissão pelos seus pecados e pelas iniqüidades de seus pais.',
        'E, levantando-se no seu lugar, leram no livro da lei do Senhor seu Deus uma quarta parte do dia; e na outra quarta parte fizeram confissão, e adoraram ao Senhor seu Deus.',
        'E Jesuá, Bani, Cadmiel, Sebanias, Buni, Serebias, Bani e Quenani se puseram em pé no lugar alto dos levitas, e clamaram em alta voz ao Senhor seu Deus.',
        'E os levitas, Jesuá, Cadmiel, Bani, Hasabnéias, Serebias, Hodias, Sebanias e Petaías, disseram: Levantai-vos, bendizei ao Senhor vosso Deus de eternidade em eternidade; e bendigam o teu glorioso nome, que está exaltado sobre toda a bênção e louvor.',
        'Só tu és Senhor; tu fizeste o céu, o céu dos céus, e todo o seu exército, a terra e tudo quanto nela há, os mares e tudo quanto neles há, e tu os guardas com vida a todos; e o exército dos céus te adora.',
        'Tu és o Senhor, o Deus, que elegeste a Abrão, e o tiraste de Ur dos caldeus, e lhe puseste por nome Abraão.',
        'E achaste o seu coração fiel perante ti, e fizeste com ele a aliança, de que darias à sua descendência a terra dos cananeus, dos heteus, dos amorreus, dos perizeus, dos jebuseus e dos girgaseus; e confirmaste as tuas palavras, porquanto és justo.',
        'E viste a aflição de nossos pais no Egito, e ouviste o seu clamor junto ao Mar Vermelho.',
        'E mostraste sinais e prodígios a Faraó, e a todos os seus servos, e a todo o povo da sua terra, porque soubeste que soberbamente os trataram; e assim adquiriste para ti nome, como hoje se vê.',
        'E o mar fendeste perante eles, e passaram pelo meio do mar, em seco; e lançaste os seus perseguidores nas profundezas, como uma pedra nas águas violentas.',
        'E guiaste-os de dia por uma coluna de nuvem, e de noite por uma coluna de fogo, para lhes iluminar o caminho por onde haviam de ir.',
        'E sobre o monte Sinai desceste, e dos céus falaste com eles, e deste-lhes juízos retos e leis verdadeiras, estatutos e mandamentos bons.',
        'E o teu santo sábado lhes fizeste conhecer; e preceitos, estatutos e lei lhes mandaste pelo ministério de Moisés, teu servo.',
        'E pão dos céus lhes deste na sua fome, e água da penha lhes produziste na sua sede; e lhes disseste que entrassem para possuírem a terra pela qual alçaste a tua mão, que lhes havias de dar.',
        'Porém eles e nossos pais se houveram soberbamente, e endureceram a sua cerviz, e não deram ouvidos aos teus mandamentos.',
        'E recusaram ouvir-te, e não se lembraram das tuas maravilhas, que lhes fizeste, e endureceram a sua cerviz e, na sua rebelião, levantaram um capitão, a fim de voltarem para a sua servidão; porém tu, ó Deus perdoador, clemente e misericordioso, tardio em irar-te, e grande em beneficência, tu não os desamparaste.',
        'Ainda mesmo quando eles fizeram para si um bezerro de fundição, e disseram: Este é o teu Deus, que te tirou do Egito; e cometeram grandes blasfêmias;',
        'Todavia tu, pela multidão das tuas misericórdias, não os deixaste no deserto. A coluna de nuvem nunca se apartou deles de dia, para os guiar pelo caminho, nem a coluna de fogo de noite, para lhes iluminar; e isto pelo caminho por onde haviam de ir.',
        'E deste o teu bom espírito, para os ensinar; e o teu maná não retiraste da sua boca; e água lhes deste na sua sede.',
        'De tal modo os sustentaste quarenta anos no deserto; nada lhes faltou; as suas roupas não se envelheceram, e os seus pés não se incharam.',
        'Também lhes deste reinos e povos, e os repartiste em porções; e eles possuíram a terra de Siom, a saber, a terra do rei de Hesbom, e a terra de Ogue, rei de Basã.',
        'E multiplicaste os seus filhos como as estrelas do céu, e trouxeste-os à terra de que tinhas falado a seus pais que nela entrariam para a possuírem.',
        'Assim os filhos entraram e possuíram aquela terra; e abateste perante eles os moradores da terra, os cananeus, e lhos entregaste na mão, como também os reis e os povos da terra, para fazerem deles conforme a sua vontade.',
        'E tomaram cidades fortificadas e terra fértil, e possuíram casas cheias de toda a fartura, cisternas cavadas, vinhas e olivais, e árvores frutíferas, em abundância; e comeram e se fartaram e engordaram e viveram em delícias, pela tua grande bondade.',
        'Porém se obstinaram, e se rebelaram contra ti, e lançaram a tua lei para trás das suas costas, e mataram os teus profetas, que protestavam contra eles, para que voltassem para ti; assim fizeram grandes abominações.',
        'Por isso os entregaste na mão dos seus adversários, que os angustiaram; mas no tempo de sua angústia, clamando a ti, desde os céus tu ouviste; e segundo a tua grande misericórdia lhes deste libertadores que os libertaram da mão de seus adversários.',
        'Porém, em tendo repouso, tornavam a fazer o mal diante de ti; e tu os deixavas na mão dos seus inimigos, para que dominassem sobre eles; e convertendo-se eles, e clamando a ti, tu os ouviste desde os céus, e segundo a tua misericórdia os livraste muitas vezes.',
        'E testificaste contra eles, para que voltassem para a tua lei; porém eles se houveram soberbamente, e não deram ouvidos aos teus mandamentos, mas pecaram contra os teus juízos, pelos quais o homem que os cumprir viverá; viraram o ombro, endureceram a sua cerviz, e não quiseram ouvir.',
        'Porém estendeste a tua benignidade sobre eles por muitos anos, e testificaste contra eles pelo teu Espírito, pelo ministério dos teus profetas; porém eles não deram ouvidos; por isso os entregaste nas mãos dos povos das terras.',
        'Mas pela tua grande misericórdia os não destruíste nem desamparaste, porque és um Deus clemente e misericordioso.',
        'Agora, pois, nosso Deus, o grande, poderoso e terrível Deus, que guardas a aliança e a beneficência, não tenhas em pouca conta toda a aflição que nos alcançou a nós, aos nossos reis, aos nossos príncipes, aos nossos sacerdotes, aos nossos profetas, aos nossos pais e a todo o teu povo, desde os dias dos reis da Assíria até ao dia de hoje.',
        'Porém tu és justo em tudo quanto tem vindo sobre nós; porque tu tens agido fielmente, e nós temos agido impiamente.',
        'E os nossos reis, os nossos príncipes, os nossos sacerdotes, e os nossos pais não guardaram a tua lei, e não deram ouvidos aos teus mandamentos e aos teus testemunhos, que testificaste contra eles.',
        'Porque eles nem no seu reino, nem na muita abundância de bens que lhes deste, nem na terra espaçosa e fértil que puseste diante deles, te serviram, nem se converteram de suas más obras.',
        'Eis que hoje somos servos; e até na terra que deste a nossos pais, para comerem o seu fruto e o seu bem, eis que somos servos nela.',
        'E ela multiplica os seus produtos para os reis, que puseste sobre nós, por causa dos nossos pecados; e conforme a sua vontade dominam sobre os nossos corpos e sobre o nosso gado; e estamos numa grande angústia.',
        'E, todavia fizemos uma firme aliança, e o escrevemos; e selaram-no os nossos príncipes, os nossos levitas e os nossos sacerdotes.',
      ],
      [
        'E os que selaram foram: Neemias, o governador, filho de Hacalias, e Zedequias,',
        'Seraías, Azarias, Jeremias,',
        'Pasur, Amarias, Malquias,',
        'Hatus, Sebanias, Maluque,',
        'Harim, Meremote, Obadias,',
        'Daniel, Ginetom, Baruque,',
        'Mesulão, Abias, Miamim,',
        'Maazias, Bilgai, Semaías; estes eram os sacerdotes.',
        'E os levitas: Jesuá, filho de Azanias, Binui, dos filhos de Henadade, Cadmiel,',
        'E seus irmãos: Sebanias, Hodias, Quelita, Pelaías, Hanã,',
        'Mica, Reobe, Hasabias,',
        'Zacur, Serebias, Sebanias,',
        'Hodias, Bani e Beninu.',
        'Os chefes do povo: Parós, Paate-Moabe, Elão, Zatu, Bani,',
        'Buni, Azgade, Bebai,',
        'Adonias, Bigvai, Adim,',
        'Ater, Ezequias, Azur,',
        'Hodias, Hasum, Bezai,',
        'Harife, Anatote, Nebai,',
        'Magpias, Mesulão, Hezir,',
        'Mesezabeel, Zadoque, Jadua,',
        'Pelatias, Hanã, Anaías,',
        'Oséias, Hananias, Hassube,',
        'Haloés, Pilha, Sobeque,',
        'Reum, Hasabná, Maaséias,',
        'E Aías, Hanã, Anã,',
        'Maluque, Harim e Baaná.',
        'E o restante do povo, os sacerdotes, os levitas, os porteiros, os cantores, os servidores do templo, todos os que se tinham separado dos povos das terras para a lei de Deus, suas mulheres, seus filhos e suas filhas, todos os que tinham conhecimento e entendimento,',
        'Firmemente aderiram a seus irmãos os mais nobres dentre eles, e convieram num anátema e num juramento, de que andariam na lei de Deus, que foi dada pelo ministério de Moisés, servo de Deus; e de que guardariam e cumpririam todos os mandamentos do Senhor nosso Senhor, e os seus juízos e os seus estatutos;',
        'E que não daríamos as nossas filhas aos povos da terra, nem tomaríamos as filhas deles para os nossos filhos.',
        'E que, trazendo os povos da terra no dia de sábado qualquer mercadoria, e qualquer grão para venderem, nada compraríamos deles no sábado, nem no dia santificado; e no sétimo ano deixaríamos descansar a terra, e perdoaríamos toda e qualquer cobrança.',
        'Também sobre nós pusemos preceitos, impondo-nos cada ano a terça parte de um siclo, para o ministério da casa do nosso Deus;',
        'Para os pães da proposição, para a contínua oferta de alimentos, e para o contínuo holocausto dos sábados, das luas novas, para as festas solenes, para as coisas sagradas, e para os sacrifícios pelo pecado, para expiação de Israel, e para toda a obra da casa do nosso Deus.',
        'Também lançamos sortes entre os sacerdotes, levitas, e o povo, acerca da oferta da lenha que se havia de trazer à casa do nosso Deus, segundo as casas de nossos pais, a tempos determinados, de ano em ano, para se queimar sobre o altar do Senhor nosso Deus, como está escrito na lei.',
        'Que também traríamos as primícias da nossa terra, e as primícias de todos os frutos de todas as árvores, de ano em ano, à casa do Senhor.',
        'E os primogênitos dos nossos filhos, e os do nosso gado, como está escrito na lei; e que os primogênitos do nosso gado e das nossas ovelhas traríamos à casa do nosso Deus, aos sacerdotes, que ministram na casa do nosso Deus.',
        'E que as primícias da nossa massa, as nossas ofertas alçadas, o fruto de toda a árvore, o mosto e o azeite, traríamos aos sacerdotes, às câmaras da casa do nosso Deus; e os dízimos da nossa terra aos levitas; e que os levitas receberiam os dízimos em todas as cidades, da nossa lavoura.',
        'E que o sacerdote, filho de Arão, estaria com os levitas quando estes recebessem os dízimos, e que os levitas trariam os dízimos dos dízimos à casa do nosso Deus, às câmaras da casa do tesouro.',
        'Porque àquelas câmaras os filhos de Israel e os filhos de Levi devem trazer ofertas alçadas do grão, do mosto e do azeite; porquanto ali estão os vasos do santuário, como também os sacerdotes que ministram, os porteiros e os cantores; e que assim não desampararíamos a casa do nosso Deus.',
      ],
      [
        'E os líderes do povo habitaram em Jerusalém, porém o restante do povo lançou sortes, para tirar um de dez, que habitasse na santa cidade de Jerusalém, e as nove partes nas outras cidades.',
        'E o povo bendisse a todos os homens que voluntariamente se ofereciam para habitar em Jerusalém.',
        'E estes são os chefes da província, que habitaram em Jerusalém; porém nas cidades de Judá habitou cada um na sua possessão, nas suas cidades, Israel, os sacerdotes, os levitas, os servidores do templo, e os filhos dos servos de Salomão.',
        'Habitaram, pois, em Jerusalém alguns dos filhos de Judá e dos filhos de Benjamim. Dos filhos de Judá, Ataías, filho de Uzias, filho de Zacarias, filho de Amarias, filho de Sefatias, filho de Maalaleel, dos filhos de Perez;',
        'E Maaséias, filho de Baruque, filho de Col-Hoze, filho de Hazaías, filho de Adaías, filho de Joiaribe, filho de Zacarias, filho de Siloni.',
        'Todos os filhos de Perez, que habitaram em Jerusalém, foram quatrocentos e sessenta e oito homens valentes.',
        'E estes são os filhos de Benjamim: Salu, filho de Mesulão, filho de Joede, filho de Pedaías, filho de Colaías, filho de Maaséias, filho de Itiel, filho de Jesaías.',
        'E depois dele Gabai e Salai, ao todo novecentos e vinte e oito.',
        'E Joel, filho de Zicri, superintendente sobre eles; e Judá, filho de Senua, o segundo sobre a cidade.',
        'Dos sacerdotes: Jedaías, filho de Joiaribe, Jaquim,',
        'Seraías, filho de Hilquias, filho de Mesulão, filho de Zadoque, filho de Meraiote, filho de Aitube, líder da casa de Deus,',
        'E seus irmãos, que faziam a obra na casa, oitocentos e vinte e dois; e Adaías, filho de Jeroão, filho de Pelalias, filho de Anzi, filho de Zacarias, filho de Pasur, filho de Malquias,',
        'E seus irmãos, chefes dos pais, duzentos e quarenta e dois; e Amassai, filho de Azareel, filho de Azai, filho de Mesilemote, filho de Imer,',
        'E os irmãos deles, homens valentes, cento e vinte e oito, e superintendente sobre eles Zabdiel, filho de Gedolim.',
        'E dos levitas: Semaías, filho de Hassube, filho de Azricão, filho de Hasabias, filho de Buni;',
        'E Sabetai, e Jozabade, dos chefes dos levitas, presidiam sobre a obra de fora da casa de Deus.',
        'E Matanias, filho de Mica, filho de Zabdi, filho de Asafe, o chefe, que iniciava as ações de graças na oração, e Bacbuquias, o segundo de seus irmãos; depois Abda, filho de Samua, filho de Galal, filho de Jedutum.',
        'Todos os levitas na santa cidade, foram duzentos e oitenta e quatro.',
        'E os porteiros, Acube, Talmom, com seus irmãos, os guardas das portas, cento e setenta e dois.',
        'E o restante de Israel, dos sacerdotes e levitas, habitou em todas as cidades de Judá, cada um na sua herança.',
        'E os servidores do templo, habitaram em Ofel; e Zia e Gispa presidiam sobre os servidores do templo.',
        'E o superintendente dos levitas em Jerusalém foi Uzi, filho de Bani, filho de Hasabias, filho de Matanias, filho de Mica; dos filhos de Asafe, os cantores, ao serviço da casa de Deus.',
        'Porque havia um mandado do rei acerca deles, e uma certa regra para os cantores, cada qual no seu dia.',
        'E Petaías, filho de Mesezabeel, dos filhos de Zerá, filho de Judá, estava à mão do rei, em todos os negócios do povo.',
        'E quanto às aldeias, com as suas terras, alguns dos filhos de Judá habitaram em Quiriate-Arba e nos lugares da sua jurisdição, e em Dibom, e nos lugares da sua jurisdição, e em Jecabzeel e nas suas aldeias,',
        'E em Jesuá, e em Molada, e em Bete-Pelete,',
        'E em Hazar-Sual, e em Berseba e nos lugares da sua jurisdição,',
        'E em Ziclague, em Mecona e nos lugares da sua jurisdição,',
        'E em En-Rimom, em Zorá e em Jarmute,',
        'Em Zanoa, Adulão e nas suas aldeias, em Laquis e nas suas terras, em Azaca e nos lugares da sua jurisdição. Acamparam-se desde Berseba até ao vale de Hinom.',
        'E os filhos de Benjamim habitaram desde Geba, em Micmás, Aia, Betel e nos lugares da sua jurisdição,',
        'E em Anatote, em Nobe, em Ananias,',
        'Em Hazor, em Ramá, em Gitaim,',
        'Em Hadide, em Zeboim, em Nebalate,',
        'Em Lode e em Ono, no vale dos artífices,',
        'E alguns dos levitas habitaram nas divisões de Judá e de Benjamim.',
      ],
      [
        'Estes são sacerdotes e levitas que subiram com Zorobabel, filho de Sealtiel, e com Jesuá: Seraías, Jeremias, Esdras,',
        'Amarias, Maluque, Hatus,',
        'Secanias, Reum, Meremote,',
        'Ido, Ginetoi, Abias,',
        'Miamim, Maadias, Bilga,',
        'Semaías, Joiaribe, Jedaías,',
        'Salu, Amoque, Hilquias, Jedaías; estes foram os chefes dos sacerdotes e de seus irmãos, nos dias de Jesuá.',
        'E os levitas: Jesuá, Binui, Cadmiel, Serebias, Judá, Matanias; este e seus irmãos dirigiam os louvores.',
        'E Bacbuquias e Uni, seus irmãos, estavam defronte deles, nas guardas.',
        'E Jesuá gerou a Joiaquim, e Joiaquim gerou a Eliasibe, e Eliasibe gerou a Joiada,',
        'E Joiada gerou a Jônatas, e Jônatas gerou a Jadua.',
        'E nos dias de Joiaquim foram sacerdotes, chefes dos pais: de Seraías, Meraías; de Jeremias, Hananias;',
        'De Esdras, Mesulão; de Amarias, Joanã;',
        'De Maluqui, Jônatas; de Sebanias, José;',
        'De Harim, Adna; de Meraiote, Helcai;',
        'De Ido, Zacarias; de Ginetom, Mesulão.',
        'De Abias, Zicri; de Miamim e de Moadias, Piltai;',
        'De Bilga, Samua; de Semaías, Jônatas;',
        'E de Joiaribe, Matenai; de Jedaías, Uzi;',
        'De Salai, Calai; de Amoque, Éber;',
        'De Hilquias, Hasabias; de Jedaías, Natanael.',
        'Dos levitas, nos dias de Eliasibe, foram inscritos como chefes de pais, Joiada, Joanã e Jadua; como também os sacerdotes, até ao reinado de Dario, o persa.',
        'Os filhos de Levi foram inscritos, como chefes de pais, no livro das crônicas, até aos dias de Joanã, filho de Eliasibe.',
        'Foram, pois, os chefes dos levitas: Hasabias, Serabias, e Jesuá, filho de Cadmiel; e seus irmãos estavam defronte deles, para louvarem e darem graças, segundo o mandado de Davi, homem de Deus; guarda contra guarda.',
        'Matanias, Bacbuquias, Obadias, Mesulão, Talmom e Acube, eram porteiros, que faziam a guarda às tesourarias das portas.',
        'Estes viveram nos dias de Jeoiaquim, filho de Jesuá, o filho de Jozadaque; como também nos dias de Neemias, o governador, e do sacerdote Esdras, o escriba.',
        'E na dedicação dos muros de Jerusalém buscaram os levitas de todos os seus lugares, para trazê-los, a fim de fazerem a dedicação com alegria, com louvores e com canto, saltérios, címbalos e com harpas.',
        'E assim ajuntaram os filhos dos cantores, tanto da campina dos arredores de Jerusalém, como das aldeias de Netofati;',
        'Como também da casa de Gilgal, e dos campos de Geba, e Azmavete; porque os cantores edificaram para si aldeias nos arredores de Jerusalém.',
        'E purificaram-se os sacerdotes e os levitas; e logo purificaram o povo, as portas e o muro.',
        'Então fiz subir os príncipes de Judá sobre o muro, e ordenei dois grandes coros em procissão, um à mão direita sobre o muro do lado da porta do monturo.',
        'E após ele ia Hosaías, e a metade dos príncipes de Judá.',
        'E Azarias, Esdras e Mesulão,',
        'Judá, Benjamim, Semaías e Jeremias.',
        'E dos filhos dos sacerdotes, com trombetas: Zacarias, filho de Jônatas, filho de Semaías, filho de Matanias, filho de Micaías, filho de Zacur, filho de Asafe.',
        'E seus irmãos, Semaías, e Azareel, Milalai, Gilalai, Maai, Natanael, Judá e Hanani, com os instrumentos musicais de Davi, homem de Deus; e Esdras, o escriba, ia adiante deles.',
        'Indo assim para a porta da fonte, defronte deles, subiram as escadas da cidade de Davi, onde começa a subida do muro, desde cima da casa de Davi, até à porta das águas, do lado do oriente.',
        'E o segundo coro ia em frente, e eu após ele; e a metade do povo ia sobre o muro, desde a torre dos fornos, até à muralha larga;',
        'E desde a porta de Efraim, passaram por cima da porta velha, e da porta do peixe, e pela torre de Hananeel e a torre de Meá, até à porta do gado; e pararam à porta da prisão.',
        'Então ambos os coros pararam na casa de Deus; como também eu, e a metade dos magistrados comigo.',
        'E os sacerdotes Eliaquim, Maaséias, Miniamim, Micaías, Elioenai, Zacarias e Hananias, com trombetas.',
        'Como também, Maaséias, Semaías, Eleazar, Uzi, Joanã, Malquias, Elão e Ezer; e faziam-se ouvir os cantores, juntamente com Jezraías, o seu superintendente.',
        'E ofereceram, no mesmo dia, grandes sacrifícios e se alegraram; porque Deus os alegrara com grande alegria; e até as mulheres e os meninos se alegraram, de modo que a alegria de Jerusalém se ouviu até de longe.',
        'Também no mesmo dia se nomearam homens sobre as câmaras, dos tesouros, das ofertas alçadas, das primícias, dos dízimos, para ajuntarem nelas, dos campos das cidades, as partes da lei para os sacerdotes e para os levitas; porque Judá estava alegre por causa dos sacerdotes e dos levitas que assistiam ali.',
        'E observava os preceitos do seu Deus, e os da purificação; como também os cantores e porteiros, conforme ao mandado de Davi e de seu filho Salomão.',
        'Porque já nos dias de Davi e Asafe, desde a antiguidade, havia chefes dos cantores, e dos cânticos de louvores e de ação de graças a Deus.',
        'Por isso todo o Israel, já nos dias de Zorobabel e nos dias de Neemias, dava aos cantores e aos porteiros as porções de cada dia; e santificavam as porções aos levitas, e os levitas as santificavam aos filhos de Arão.',
      ],
      [
        'Naquele dia leu-se no livro de Moisés, aos ouvidos do povo; e achou-se escrito nele que os amonitas e os moabitas não entrassem jamais na congregação de Deus,',
        'Porquanto não tinham saído ao encontro dos filhos de Israel com pão e água; antes contra eles assalariaram a Balaão para os amaldiçoar; porém o nosso Deus converteu a maldição em bênção.',
        'Sucedeu, pois, que, ouvindo eles esta lei, apartaram de Israel todo o elemento misto.',
        'Ora, antes disto, Eliasibe, sacerdote, que presidia sobre a câmara da casa do nosso Deus, se tinha aparentado com Tobias;',
        'E fizera-lhe uma câmara grande, onde dantes se depositavam as ofertas de alimentos, o incenso, os utensílios, os dízimos do grão, do mosto e do azeite, que se ordenaram para os levitas, cantores e porteiros, como também a oferta alçada para os sacerdotes.',
        'Mas durante tudo isto não estava eu em Jerusalém, porque no ano trinta e dois de Artaxerxes, rei de babilônia, fui ter com o rei; mas após alguns dias tornei a alcançar licença do rei.',
        'E voltando a Jerusalém, compreendi o mal que Eliasibe fizera para Tobias, fazendo-lhe uma câmara nos pátios da casa de Deus.',
        'O que muito me desagradou; de sorte que lancei todos os móveis da casa de Tobias fora da câmara.',
        'E, ordenando-o eu, purificaram as câmaras; e tornei a trazer para ali os utensílios da casa de Deus, com as ofertas de alimentos e o incenso.',
        'Também entendi que os quinhões dos levitas não se lhes davam, de maneira que os levitas e os cantores, que faziam a obra, tinham fugido cada um para a sua terra.',
        'Então contendi com os magistrados, e disse: Por que se desamparou a casa de Deus? Porém eu os ajuntei, e os restaurei no seu posto.',
        'Então todo o Judá trouxe os dízimos do grão, do mosto e do azeite aos celeiros.',
        'E por tesoureiros pus sobre os celeiros a Selemias, o sacerdote, e a Zadoque, o escrivão e a Pedaías, dentre os levitas; e com eles Hanã, filho de Zacur, o filho de Matanias; porque foram achados fiéis; e se lhes encarregou a eles a distribuição para seus irmãos.',
        'Por isto, Deus meu, lembra-te de mim e não risques as beneficências que eu fiz à casa de meu Deus e às suas observâncias.',
        'Naqueles dias vi em Judá os que pisavam lagares ao sábado e traziam feixes que carregavam sobre os jumentos; como também vinho, uvas e figos, e toda a espécie de cargas, que traziam a Jerusalém no dia de sábado; e protestei contra eles no dia em que vendiam mantimentos.',
        'Também habitavam em Jerusalém tírios que traziam peixe e toda a mercadoria, que vendiam no sábado aos filhos de Judá, e em Jerusalém.',
        'E contendi com os nobres de Judá, e lhes disse: Que mal é este que fazeis, profanando o dia de sábado?',
        'Porventura não fizeram vossos pais assim, e não trouxe o nosso Deus todo este mal sobre nós e sobre esta cidade? E vós ainda mais acrescentais o ardor de sua ira sobre Israel, profanando o sábado.',
        'Sucedeu, pois, que, dando já sombra nas portas de Jerusalém antes do sábado, ordenei que as portas fossem fechadas; e mandei que não as abrissem até passado o sábado; e pus às portas alguns de meus servos, para que nenhuma carga entrasse no dia de sábado.',
        'Então os negociantes e os vendedores de toda a mercadoria passaram a noite fora de Jerusalém, uma ou duas vezes.',
        'Protestei, pois, contra eles, e lhes disse: Por que passais a noite defronte do muro? Se outra vez o fizerdes, hei de lançar mão de vós. Daquele tempo em diante não vieram no sábado.',
        'Também disse aos levitas que se purificassem, e viessem guardar as portas, para santificar o sábado. Nisto também, Deus meu, lembra-te de mim e perdoa-me segundo a abundância da tua benignidade.',
        'Vi também naqueles dias judeus que tinham casado com mulheres asdoditas, amonitas e moabitas.',
        'E seus filhos falavam meio asdodita, e não podiam falar judaico, senão segundo a língua de cada povo.',
        'E contendi com eles, e os amaldiçoei e espanquei alguns deles, e lhes arranquei os cabelos, e os fiz jurar por Deus, dizendo: Não dareis mais vossas filhas a seus filhos, e não tomareis mais suas filhas, nem para vossos filhos nem para vós mesmos.',
        'Porventura não pecou nisto Salomão, rei de Israel, não havendo entre muitas nações rei semelhante a ele, e sendo ele amado de seu Deus, e pondo-o Deus rei sobre todo o Israel? E contudo as mulheres estrangeiras o fizeram pecar.',
        'E dar-vos-íamos nós ouvidos, para fazermos todo este grande mal, prevaricando contra o nosso Deus, casando com mulheres estrangeiras?',
        'Também um dos filhos de Joiada, filho de Eliasibe, o sumo sacerdote, era genro de Sambalate, o horonita, por isso o afugentei de mim.',
        'Lembra-te deles, Deus meu, pois contaminaram o sacerdócio, como também a aliança do sacerdócio e dos levitas.',
        'Assim os limpei de todo o estrangeiro, e designei os cargos dos sacerdotes e dos levitas, cada um na sua obra.',
        'Como também para com as ofertas de lenha em tempos determinados, e para com as primícias; lembra-te de mim, Deus meu, para bem.',
      ],
    ],
    livro: 'neemias',
  },
  {
    abbrev: 'et',
    capitulos: [
      [
        'E sucedeu nos dias de Assuero, o Assuero que reinou desde a India até a Etiópia, sobre cento e vinte e sete províncias,',
        'Que, naqueles dias, assentando-se o rei Assuero no trono do seu reino, que estava na fortaleza de Susã,',
        'No terceiro ano do seu reinado, fez um banquete a todos os seus príncipes e seus servos, estando assim perante ele o poder da Pérsia e Média e os nobres e príncipes das províncias,',
        'Para mostrar as riquezas da glória do seu reino, e o esplendor da sua excelente grandeza, por muitos dias, a saber: cento e oitenta dias.',
        'E, acabados aqueles dias, fez o rei um banquete a todo o povo que se achava na fortaleza de Susã, desde o maior até ao menor, por sete dias, no pátio do jardim do palácio real.',
        'As tapeçarias eram de pano branco, verde, e azul celeste, pendentes de cordões de linho fino e púrpura, e argolas de prata, e colunas de mármore; os leitos de ouro e de prata, sobre um pavimento de mármore vermelho, e azul, e branco, e preto.',
        'E dava-se de beber em copos de ouro, e os copos eram diferentes uns dos outros; e havia muito vinho real, segundo a generosidade do rei.',
        'E o beber era por lei, sem constrangimento; porque assim tinha ordenado o rei expressamente a todos os oficiais da sua casa, que fizessem conforme a vontade de cada um.',
        'Também a rainha Vasti deu um banquete às mulheres, na casa real do rei Assuero.',
        'E ao sétimo dia, estando já o coração do rei alegre do vinho, mandou a Meumã, Bizta, Harbona, Bigtá, Abagta, Zetar e Carcas, os sete camareiros que serviam na presença do rei Assuero,',
        'Que introduzissem na presença do rei a rainha Vasti, com a coroa real, para mostrar aos povos e aos príncipes a sua beleza, porque era formosa à vista.',
        'Porém a rainha Vasti recusou vir conforme a palavra do rei, por meio dos camareiros; assim o rei muito se enfureceu, e acendeu nele a sua ira.',
        'Então perguntou o rei aos sábios que entendiam dos tempos (porque assim se tratavam os negócios do rei na presença de todos os que sabiam a lei e o direito;',
        'E os mais chegados a ele eram: Carsena, Setar, Admata, Társis, Meres, Marsena, e Memucã, os sete príncipes dos persas e dos medos, que viam a face do rei, e se assentavam como principais no reino),',
        'O que, segundo a lei, se devia fazer à rainha Vasti, por não ter obedecido ao mandado do rei Assuero, por meio dos camareiros.',
        'Então disse Memucã na presença do rei e dos príncipes: Não somente contra o rei pecou a rainha Vasti, porém também contra todos os príncipes, e contra todos os povos que há em todas as províncias do rei Assuero.',
        'Porque a notícia do que fez a rainha chegará a todas as mulheres, de modo que aos seus olhos desprezarão a seus maridos quando ouvirem dizer: Mandou o rei Assuero que introduzissem à sua presença a rainha Vasti, porém ela não veio.',
        'E neste mesmo dia as senhoras da Pérsia e da Média, ouvindo o que fez a rainha, dirão o mesmo a todos os príncipes do rei; e assim haverá muito desprezo e indignação.',
        'Se bem parecer ao rei, saia da sua parte um edito real, e escreva-se nas leis dos persas e dos medos, e não se revogue, a saber: que Vasti não entre mais na presença do rei Assuero, e o rei dê o reino dela a outra que seja melhor do que ela.',
        'E, ouvindo-se o mandado, que o rei decretará em todo o seu reino (porque é grande), todas as mulheres darão honra a seus maridos, desde a maior até à menor.',
        'E pareceram bem estas palavras aos olhos do rei e dos príncipes; e fez o rei conforme a palavra de Memucã.',
        'Então enviou cartas a todas as províncias do rei, a cada província segundo a sua escrita, e a cada povo segundo a sua língua; que cada homem fosse senhor em sua casa, e que se falasse conforme a língua do seu povo.',
      ],
      [
        'Passadas estas coisas, e apaziguado já o furor do rei Assuero, lembrou-se de Vasti, e do que fizera, e do que se tinha decretado a seu respeito.',
        'Então disseram os servos do rei, que lhe serviam: Busquem-se para o rei moças virgens e formosas.',
        'E ponha o rei oficiais em todas as províncias do seu reino, que ajuntem a todas as moças virgens e formosas, na fortaleza de Susã, na casa das mulheres, aos cuidados de Hegai, camareiro do rei, guarda das mulheres, e dêem-se-lhes os seus enfeites.',
        'E a moça que parecer bem aos olhos do rei, reine em lugar de Vasti. E isto pareceu bem aos olhos do rei, e ele assim fez.',
        'Havia então um homem judeu na fortaleza de Susã, cujo nome era Mardoqueu, filho de Jair, filho de Simei, filho de Quis, homem benjamita,',
        'Que fora transportado de Jerusalém, com os cativos que foram levados com Jeconias, rei de Judá, o qual transportara Nabucodonosor, rei de babilônia.',
        'Este criara a Hadassa (que é Ester, filha de seu tio), porque não tinha pai nem mãe; e era jovem bela de presença e formosa; e, morrendo seu pai e sua mãe, Mardoqueu a tomara por sua filha.',
        'Sucedeu que, divulgando-se o mandado do rei e a sua lei, e ajuntando-se muitas moças na fortaleza de Susã, aos cuidados de Hegai, também levaram Ester à casa do rei, sob a custódia de Hegai, guarda das mulheres.',
        'E a moça pareceu formosa aos seus olhos, e alcançou graça perante ele; por isso se apressou a dar-lhe os seus enfeites, e os seus quinhões, como também em lhe dar sete moças de respeito da casa do rei; e a fez passar com as suas moças ao melhor lugar da casa das mulheres.',
        'Ester, porém, não declarou o seu povo e a sua parentela, porque Mardoqueu lhe tinha ordenado que o não declarasse.',
        'E passeava Mardoqueu cada dia diante do pátio da casa das mulheres, para se informar de como Ester passava, e do que lhe sucederia.',
        'E, chegando a vez de cada moça, para vir ao rei Assuero, depois que fora feito a ela segundo a lei das mulheres, por doze meses (porque assim se cumpriam os dias das suas purificações, seis meses com óleo de mirra, e seis meses com especiarias, e com as coisas para a purificação das mulheres),',
        'Desta maneira, pois, vinha a moça ao rei; dava-se-lhe tudo quanto ela desejava, para levar consigo da casa das mulheres à casa do rei;',
        'À tarde entrava, e pela manhã tornava à segunda casa das mulheres, sob os cuidados de Saasgaz, camareiro do rei, guarda das concubinas; não tornava mais ao rei, salvo se o rei a desejasse, e fosse chamada pelo nome.',
        'Chegando, pois, a vez de Ester, filha de Abiail, tio de Mardoqueu (que a tomara por sua filha), para ir ao rei, coisa nenhuma pediu, senão o que disse Hegai, camareiro do rei, guarda das mulheres; e alcançava Ester graça aos olhos de todos quantos a viam.',
        'Assim foi levada Ester ao rei Assuero, à sua casa real, no décimo mês, que é o mês de tebete, no sétimo ano do seu reinado.',
        'E o rei amou a Ester mais do que a todas as mulheres, e alcançou perante ele graça e benevolência mais do que todas as virgens; e pôs a coroa real na sua cabeça, e a fez rainha em lugar de Vasti.',
        'Então o rei deu um grande banquete a todos os seus príncipes e aos seus servos; era o banquete de Ester; e deu alívio às províncias, e fez presentes segundo a generosidade do rei.',
        'E reunindo-se segunda vez as virgens, Mardoqueu estava assentado à porta do rei.',
        'Ester, porém, não declarava a sua parentela e o seu povo, como Mardoqueu lhe ordenara; porque Ester cumpria o mandado de Mardoqueu, como quando a criara.',
        'Naqueles dias, assentando-se Mardoqueu à porta do rei, dois camareiros do rei, dos guardas da porta, Bigtã e Teres, grandemente se indignaram, e procuraram atentar contra o rei Assuero.',
        'E veio isto ao conhecimento de Mardoqueu, e ele o fez saber à rainha Ester; e Ester o disse ao rei, em nome de Mardoqueu.',
        'E inquiriu-se o negócio, e se descobriu, e ambos foram pendurados numa forca; e foi escrito nas crônicas perante o rei.',
      ],
      [
        'Depois destas coisas o rei Assuero engrandeceu a Hamã, filho de Hamedata, agagita, e o exaltou, e pôs o seu assento acima de todos os príncipes que estavam com ele.',
        'E todos os servos do rei, que estavam à porta do rei, se inclinavam e se prostravam perante Hamã; porque assim tinha ordenado o rei acerca dele; porém Mardoqueu não se inclinava nem se prostrava.',
        'Então os servos do rei, que estavam à porta do rei, disseram a Mardoqueu: Por que transgrides o mandado do rei?',
        'Sucedeu, pois, que, dizendo-lhe eles isto, dia após dia, e não lhes dando ele ouvidos, o fizeram saber a Hamã, para verem se as palavras de Mardoqueu se sustentariam, porque ele lhes tinha declarado que era judeu.',
        'Vendo, pois, Hamã que Mardoqueu não se inclinava nem se prostrava diante dele, Hamã se encheu de furor.',
        'Porém teve como pouco, nos seus propósitos, o pôr as mãos só em Mardoqueu (porque lhe haviam declarado de que povo era Mardoqueu); Hamã, pois, procurou destruir a todos os judeus, o povo de Mardoqueu, que havia em todo o reino de Assuero.',
        'No primeiro mês (que é o mês de Nisã), no ano duodécimo do rei Assuero, se lançou Pur, isto é, a sorte, perante Hamã, para cada dia, e para cada mês, até ao duodécimo mês, que é o mês de Adar.',
        'E Hamã disse ao rei Assuero: Existe espalhado e dividido entre os povos em todas as províncias do teu reino um povo, cujas leis são diferentes das leis de todos os povos, e que não cumpre as leis do rei; por isso não convém ao rei deixá-lo ficar.',
        'Se bem parecer ao rei, decrete-se que os matem; e eu porei nas mãos dos que fizerem a obra dez mil talentos de prata, para que entrem nos tesouros do rei.',
        'Então tirou o rei o anel da sua mão, e o deu a Hamã, filho de Hamedata, agagita, adversário dos judeus.',
        'E disse o rei a Hamã: Essa prata te é dada como também esse povo, para fazeres dele o que bem parecer aos teus olhos.',
        'Então chamaram os escrivães do rei no primeiro mês, no dia treze do mesmo e, conforme a tudo quanto Hamã mandou, se escreveu aos príncipes do rei, e aos governadores que havia sobre cada província, e aos líderes, de cada povo; a cada província segundo a sua escrita, e a cada povo segundo a sua língua; em nome do rei Assuero se escreveu, e com o anel do rei se selou.',
        'E enviaram-se as cartas por intermédio dos correios a todas as províncias do rei, para que destruíssem, matassem, e fizessem perecer a todos os judeus, desde o jovem até ao velho, crianças e mulheres, em um mesmo dia, a treze do duodécimo mês (que é o mês de Adar), e que saqueassem os seus bens.',
        'Uma cópia do despacho que determinou a divulgação da lei em cada província, foi enviada a todos os povos, para que estivessem preparados para aquele dia.',
        'Os correios, pois, impelidos pela palavra do rei, saíram, e a lei se proclamou na fortaleza de Susã. E o rei e Hamã se assentaram a beber, porém a cidade de Susã estava confusa.',
      ],
      [
        'Quando Mardoqueu soube tudo quanto se havia passado, rasgou as suas vestes, e vestiu-se de saco e de cinza, e saiu pelo meio da cidade, e clamou com grande e amargo clamor;',
        'E chegou até diante da porta do rei, porque ninguém vestido de saco podia entrar pelas portas do rei.',
        'E em todas as províncias aonde a palavra do rei e a sua lei chegava, havia entre os judeus grande luto, com jejum, e choro, e lamentação; e muitos estavam deitados em saco e em cinza.',
        'Então vieram as servas de Ester, e os seus camareiros, e fizeram-na saber, do que a rainha muito se doeu; e mandou roupas para vestir a Mardoqueu, e tirar-lhe o pano de saco; porém ele não as aceitou.',
        'Então Ester chamou a Hatá (um dos camareiros do rei, que este tinha posto para servi-la), e deu-lhe ordem para ir a Mardoqueu, para saber que era aquilo, e porquê.',
        'E, saindo Hatá a Mardoqueu, à praça da cidade, que estava diante da porta do rei,',
        'Mardoqueu lhe fez saber tudo quanto lhe tinha sucedido; como também a soma exata do dinheiro, que Hamã dissera que daria para os tesouros do rei, pelos judeus, para destruí-los.',
        'Também lhe deu a cópia da lei escrita, que se publicara em Susã, para os destruir, para que a mostrasse a Ester, e a fizesse saber; e para lhe ordenar que fosse ter com o rei, e lhe pedisse e suplicasse na sua presença pelo seu povo.',
        'Veio, pois, Hatá, e fez saber a Ester as palavras de Mardoqueu.',
        'Então falou Ester a Hatá, mandando-o dizer a Mardoqueu:',
        'Todos os servos do rei, e o povo das províncias do rei, bem sabem que todo o homem ou mulher que chegar ao rei no pátio interior, sem ser chamado, não há senão uma sentença, a de morte, salvo se o rei estender para ele o cetro de ouro, para que viva; e eu nestes trinta dias não tenho sido chamada para ir ao rei.',
        'E fizeram saber a Mardoqueu as palavras de Ester.',
        'Então Mardoqueu mandou que respondessem a Ester: Não imagines no teu íntimo que, por estares na casa do rei, escaparás só tu entre todos os judeus.',
        'Porque, se de todo te calares neste tempo, socorro e livramento de outra parte sairá para os judeus, mas tu e a casa de teu pai perecereis; e quem sabe se para tal tempo como este chegaste a este reino?',
        'Então disse Ester que tornassem a dizer a Mardoqueu:',
        'Vai, ajunta a todos os judeus que se acharem em Susã, e jejuai por mim, e não comais nem bebais por três dias, nem de dia nem de noite, e eu e as minhas servas também assim jejuaremos. E assim irei ter com o rei, ainda que não seja segundo a lei; e se perecer, pereci.',
        'Então Mardoqueu foi, e fez conforme a tudo quanto Ester lhe ordenou.',
      ],
      [
        'Sucedeu, pois, que ao terceiro dia Ester se vestiu com trajes reais, e se pôs no pátio interior da casa do rei, defronte do aposento do rei; e o rei estava assentado sobre o seu trono real, na casa real, defronte da porta do aposento.',
        'E sucedeu que, vendo o rei a rainha Ester, que estava no pátio, ela alcançou graça aos seus olhos; e o rei estendeu para Ester o cetro de ouro, que tinha na sua mão, e Ester chegou, e tocou a ponta do cetro.',
        'Então o rei lhe disse: Que é que queres, rainha Ester, ou qual é a tua petição? Até metade do reino se te dará.',
        'E disse Ester: Se parecer bem ao rei, venha hoje com Hamã ao banquete que lhe tenho preparado.',
        'Então disse o rei: Fazei apressar a Hamã, para que se atenda ao desejo de Ester. Vindo, pois, o rei e Hamã ao banquete, que Ester tinha preparado,',
        'Disse o rei a Ester, no banquete do vinho: Qual é a tua petição? E ser-te-á concedida, e qual é o teu desejo? E se fará, ainda até metade do reino.',
        'Então respondeu Ester, e disse: Minha petição e desejo é:',
        'Se achei graça aos olhos do rei, e se bem parecer ao rei conceder-me a minha petição, e cumprir o meu desejo, venha o rei com Hamã ao banquete que lhes hei de preparar, e amanhã farei conforme a palavra do rei.',
        'Então saiu Hamã naquele dia alegre e de bom ânimo; porém, vendo Mardoqueu à porta do rei, e que ele não se levantara nem se movera diante dele, então Hamã se encheu de furor contra Mardoqueu.',
        'Hamã, porém, se refreou, e foi para sua casa; e enviou, e mandou vir os seus amigos, e Zeres, sua mulher.',
        'E contou-lhes Hamã a glória das suas riquezas, a multidão de seus filhos, e tudo em que o rei o tinha engrandecido, e como o tinha exaltado sobre os príncipes e servos do rei.',
        'Disse mais Hamã: Tampouco a rainha Ester a ninguém fez vir com o rei ao banquete que tinha preparado, senão a mim; e também para amanhã estou convidado por ela juntamente com o rei.',
        'Porém tudo isto não me satisfaz, enquanto eu vir o judeu Mardoqueu assentado à porta do rei.',
        'Então lhe disseram Zeres, sua mulher, e todos os seus amigos: Faça-se uma forca de cinqüenta côvados de altura, e amanhã dize ao rei que nela seja enforcado Mardoqueu; e então entra alegre com o rei ao banquete. E este conselho bem pareceu a Hamã, que mandou fazer a forca.',
      ],
      [
        'Naquela mesma noite fugiu o sono do rei; então mandou trazer o livro de registro das crônicas, as quais se leram diante do rei.',
        'E achou-se escrito que Mardoqueu tinha denunciado Bigtã e Teres, dois dos camareiros do rei, da guarda da porta, que tinham procurado lançar mão do rei Assuero.',
        'Então disse o rei: Que honra e distinção se deu por isso a Mardoqueu? E os servos do rei, que ministravam junto a ele, disseram: Coisa nenhuma se lhe fez.',
        'Então disse o rei: Quem está no pátio? E Hamã tinha entrado no pátio exterior da casa do rei, para dizer ao rei que enforcassem a Mardoqueu na forca que lhe tinha preparado.',
        'E os servos do rei lhe disseram: Eis que Hamã está no pátio. E disse o rei que entrasse.',
        'E, entrando Hamã, o rei lhe disse: Que se fará ao homem de cuja honra o rei se agrada? Então Hamã disse no seu coração: De quem se agradaria o rei para lhe fazer honra mais do que a mim?',
        'Assim disse Hamã ao rei: Para o homem, de cuja honra o rei se agrada,',
        'Tragam a veste real que o rei costuma vestir, como também o cavalo em que o rei costuma andar montado, e ponha-se-lhe a coroa real na sua cabeça.',
        'E entregue-se a veste e o cavalo à mão de um dos príncipes mais nobres do rei, e vistam delas aquele homem a quem o rei deseja honrar; e levem-no a cavalo pelas ruas da cidade, e apregoe-se diante dele: Assim se fará ao homem a quem o rei deseja honrar!',
        'Então disse o rei a Hamã: Apressa-te, toma a veste e o cavalo, como disseste, e faze assim para com o judeu Mardoqueu, que está assentado à porta do rei; e coisa nenhuma omitas de tudo quanto disseste.',
        'E Hamã tomou a veste e o cavalo, e vestiu a Mardoqueu, e o levou a cavalo pelas ruas da cidade, e apregoou diante dele: Assim se fará ao homem a quem o rei deseja honrar!',
        'Depois disto Mardoqueu voltou para a porta do rei; porém Hamã se retirou correndo à sua casa, triste, e de cabeça coberta.',
        'E contou Hamã a Zeres, sua mulher, e a todos os seus amigos, tudo quanto lhe tinha sucedido. Então os seus sábios e Zeres, sua mulher, lhe disseram: Se Mardoqueu, diante de quem já começaste a cair, é da descendência dos judeus, não prevalecerás contra ele, antes certamente cairás diante dele.',
        'E estando eles ainda falando com ele, chegaram os camareiros do rei, e se apressaram a levar Hamã ao banquete que Ester preparara.',
      ],
      [
        'Vindo, pois, o rei com Hamã, para beber com a rainha Ester,',
        'Disse outra vez o rei a Ester, no segundo dia, no banquete do vinho: Qual é a tua petição, rainha Ester? E se te dará. E qual é o teu desejo? Até metade do reino, se te dará.',
        'Então respondeu a rainha Ester, e disse: Se, ó rei, achei graça aos teus olhos, e se bem parecer ao rei, dê-se-me a minha vida como minha petição, e o meu povo como meu desejo.',
        'Porque fomos vendidos, eu e o meu povo, para nos destruírem, matarem, e aniquilarem de vez; se ainda por servos e por servas nos vendessem, calar-me-ia; ainda que o opressor não poderia ter compensado a perda do rei.',
        'Então falou o rei Assuero, e disse à rainha Ester: Quem é esse e onde está esse, cujo coração o instigou a assim fazer?',
        'E disse Ester: O homem, o opressor, e o inimigo, é este mau Hamã. Então Hamã se perturbou perante o rei e a rainha.',
        'E o rei no seu furor se levantou do banquete do vinho e passou para o jardim do palácio; e Hamã se pôs em pé, para rogar à rainha Ester pela sua vida; porque viu que já o mal lhe estava determinado pelo rei.',
        'Tornando, pois, o rei do jardim do palácio à casa do banquete do vinho, Hamã tinha caído prostrado sobre o leito em que estava Ester. Então disse o rei: Porventura quereria ele também forçar a rainha perante mim nesta casa? Saindo esta palavra da boca do rei, cobriram o rosto de Hamã.',
        'Então disse Harbona, um dos camareiros que serviam diante do rei: Eis que também a forca de cinqüenta côvados de altura que Hamã fizera para Mardoqueu, que falara em defesa do rei, está junto à casa de Hamã. Então disse o rei: Enforcai-o nela.',
        'Enforcaram, pois, a Hamã na forca, que ele tinha preparado para Mardoqueu. Então o furor do rei se aplacou.',
      ],
      [
        'Naquele mesmo dia deu o rei Assuero à rainha Ester a casa de Hamã, inimigo dos judeus; e Mardoqueu veio perante o rei, porque Ester tinha declarado quem ele era.',
        'E tirou o rei o seu anel, que tinha tomado de Hamã, e o deu a Mardoqueu. E Ester encarregou Mardoqueu da casa de Hamã.',
        'Falou mais Ester perante o rei, e se lhe lançou aos seus pés; e chorou, e lhe suplicou que revogasse a maldade de Hamã, o agagita, e o intento que tinha projetado contra os judeus.',
        'E estendeu o rei para Ester o cetro de ouro. Então Ester se levantou, e pôs-se em pé perante o rei,',
        'E disse: Se bem parecer ao rei, e se eu achei graça perante ele, e se este negócio é reto diante do rei, e se eu lhe agrado aos seus olhos, escreva-se que se revoguem as cartas concebidas por Hamã filho de Hamedata, o agagita, as quais ele escreveu para aniquilar os judeus, que estão em todas as províncias do rei.',
        'Pois como poderei ver o mal que sobrevirá ao meu povo? E como poderei ver a destruição da minha parentela?',
        'Então disse o rei Assuero à rainha Ester e ao judeu Mardoqueu: Eis que dei a Ester a casa de Hamã, e a ele penduraram numa forca, porquanto estendera as mãos contra os judeus.',
        'Escrevei, pois, aos judeus, como parecer bem aos vossos olhos, em nome do rei, e selai-o com o anel do rei; porque o documento que se escreve em nome do rei, e que se sela com o anel do rei, não se pode revogar.',
        'Então foram chamados os escrivães do rei, naquele mesmo tempo, no terceiro mês (que é o mês de Sivã), aos vinte e três dias; e se escreveu conforme a tudo quanto ordenou Mardoqueu aos judeus, como também aos sátrapas, e aos governadores, e aos líderes das províncias, que se estendem da Úndia até Etiópia, cento e vinte e sete províncias, a cada província segundo o seu modo de escrever, e a cada povo conforme a sua língua; como também aos judeus segundo o seu modo de escrever, e conforme a sua língua.',
        'E escreveu-se em nome do rei Assuero e, selando-as com o anel do rei, enviaram as cartas pela mão de correios a cavalo, que cavalgavam sobre ginetes, que eram das cavalariças do rei.',
        'Nelas o rei concedia aos judeus, que havia em cada cidade, que se reunissem, e se dispusessem para defenderem as suas vidas, e para destruírem, matarem e aniquilarem todas as forças do povo e da província que viessem contra eles, crianças e mulheres, e que se saqueassem os seus bens,',
        'Num mesmo dia, em todas as províncias do rei Assuero, no dia treze do duodécimo mês, que é o mês de Adar;',
        'E uma cópia da carta seria divulgada como decreto em todas as províncias, e publicada entre todos os povos, para que os judeus estivessem preparados para aquele dia, para se vingarem dos seus inimigos.',
        'Os correios, sobre ginetes velozes, saíram apressuradamente, impelidos pela palavra do rei; e esta ordem foi publicada na fortaleza de Susã.',
        'Então Mardoqueu saiu da presença do rei com veste real azul-celeste e branca, como também com uma grande coroa de ouro, e com uma capa de linho fino e púrpura, e a cidade de Susã exultou e se alegrou.',
        'E para os judeus houve luz, e alegria, e gozo, e honra.',
        'Também em toda a província, e em toda a cidade, aonde chegava a palavra do rei e a sua ordem, havia entre os judeus alegria e gozo, banquetes e dias de folguedo; e muitos, dos povos da terra, se fizeram judeus, porque o temor dos judeus tinha caído sobre eles.',
      ],
      [
        'E, no duodécimo mês, que é o mês de Adar, no dia treze do mesmo mês em que chegou a palavra do rei e a sua ordem para se executar, no dia em que os inimigos dos judeus esperavam assenhorear-se deles, sucedeu o contrário, porque os judeus foram os que se assenhorearam dos que os odiavam.',
        'Porque os judeus nas suas cidades, em todas as províncias do rei Assuero, se ajuntaram para pôr as mãos naqueles que procuravam o seu mal; e ninguém podia resistir-lhes, porque o medo deles caíra sobre todos aqueles povos.',
        'E todos os líderes das províncias, e os sátrapas, e os governadores, e os que faziam a obra do rei, auxiliavam os judeus; porque tinha caído sobre eles o temor de Mardoqueu.',
        'Porque Mardoqueu era grande na casa do rei, e a sua fama crescia por todas as províncias, porque o homem Mardoqueu ia sendo engrandecido.',
        'Feriram, pois, os judeus a todos os seus inimigos, a golpes de espada, com matança e com destruição; e fizeram dos seus inimigos o que quiseram.',
        'E na fortaleza de Susã os judeus mataram e destruíram quinhentos homens;',
        'Como também a Parsandata, e a Dalfom e a Aspata,',
        'E a Porata, e a Adalia, e a Aridata,',
        'E a Farmasta, e a Arisai, e a Aridai, e a Vaisata; ú',
        'Os dez filhos de Hamã, filho de Hamedata, o inimigo dos judeus, mataram, porém ao despojo não estenderam a sua mão.',
        'No mesmo dia foi comunicado ao rei o número dos mortos na fortaleza de Susã.',
        'E disse o rei à rainha Ester: Na fortaleza de Susã os judeus mataram e destruíram quinhentos homens, e os dez filhos de Hamã; nas mais províncias do rei que teriam feito? Qual é, pois, a tua petição? E dar-se-te-á. Ou qual é ainda o teu requerimento? E far-se-á.',
        'Então disse Ester: Se bem parecer ao rei, conceda-se aos judeus que se acham em Susã que também façam amanhã conforme ao mandado de hoje; e pendurem numa forca os dez filhos de Hamã.',
        'Então disse o rei que assim se fizesse; e publicou-se um edito em Susã, e enforcaram os dez filhos de Hamã.',
        'E reuniram-se os judeus que se achavam em Susã também no dia catorze do mês de Adar, e mataram em Susã trezentos homens; porém ao despojo não estenderam a sua mão.',
        'Também os demais judeus que se achavam nas províncias do rei se reuniram e se dispuseram em defesa das suas vidas, e tiveram descanso dos seus inimigos; e mataram dos seus inimigos setenta e cinco mil; porém ao despojo não estenderam a sua mão.',
        'Sucedeu isto no dia treze do mês de Adar; e descansaram no dia catorze, e fizeram, daquele dia, dia de banquetes e de alegria.',
        'Também os judeus, que se achavam em Susã se ajuntaram nos dias treze e catorze do mesmo; e descansaram no dia quinze, e fizeram, daquele dia, dia de banquetes e de alegria.',
        'Os judeus, porém, das aldeias, que habitavam nas vilas, fizeram do dia catorze do mês de Adar dia de alegria e de banquetes, e dia de folguedo, e de mandarem presentes uns aos outros.',
        'E Mardoqueu escreveu estas coisas, e enviou cartas a todos os judeus que se achavam em todas as províncias do rei Assuero, aos de perto, e aos de longe,',
        'Ordenando-lhes que guardassem o dia catorze do mês de Adar, e o dia quinze do mesmo, todos os anos,',
        'Como os dias em que os judeus tiveram repouso dos seus inimigos, e o mês que se lhes mudou de tristeza em alegria, e de luto em dia de festa, para que os fizessem dias de banquetes e de alegria, e de mandarem presentes uns aos outros, e dádivas aos pobres.',
        'E os judeus encarregaram-se de fazer o que já tinham começado, como também o que Mardoqueu lhes tinha escrito.',
        'Porque Hamã, filho de Hamedata, o agagita, inimigo de todos os judeus, tinha intentado destruir os judeus, e tinha lançado Pur, isto é, a sorte, para os assolar e destruir.',
        'Mas, vindo isto perante o rei, mandou ele por cartas que o mau intento que Hamã formara contra os judeus, se tornasse sobre a sua cabeça; pelo que penduraram a ele e a seus filhos numa forca.',
        'Por isso àqueles dias chamam Purim, do nome Pur; assim também por causa de todas as palavras daquela carta, e do que viram sobre isso, e do que lhes tinha sucedido,',
        'Confirmaram os judeus, e tomaram sobre si, e sobre a sua descendência, e sobre todos os que se achegassem a eles, que não se deixaria de guardar estes dois dias conforme ao que se escrevera deles, e segundo o seu tempo determinado, todos os anos.',
        'E que estes dias seriam lembrados e guardados em cada geração, família, província e cidade, e que esses dias de Purim não fossem revogados entre os judeus, e que a memória deles nunca teria fim entre os de sua descendência.',
        'Então a rainha Ester, filha de Abiail, e Mardoqueu, o judeu, escreveram com toda autoridade uma segunda vez, para confirmar a carta a respeito de Purim.',
        'E mandaram cartas a todos os judeus, às cento e vinte e sete províncias do reino de Assuero, com palavras de paz e verdade.',
        'Para confirmarem estes dias de Purim nos seus tempos determinados, como Mardoqueu, o judeu, e a rainha Ester lhes tinham estabelecido, e como eles mesmos já o tinham estabelecido sobre si e sobre a sua descendência, acerca do jejum e do seu clamor.',
        'E o mandado de Ester estabeleceu os sucessos daquele Purim; e escreveu-se no livro.',
      ],
      [
        'Depois disto impôs o rei Assuero tributo sobre a terra, e sobre as ilhas do mar.',
        'E todos os atos do seu poder e do seu valor, e o relato da grandeza de Mardoqueu, a quem o rei exaltou, porventura não estão escritos no livro das crônicas dos reis da Média e da Pérsia?',
        'Porque o judeu Mardoqueu foi o segundo depois do rei Assuero, e grande entre os judeus, e estimado pela multidão de seus irmãos, procurando o bem do seu povo, e proclamando a prosperidade de toda a sua descendência.',
      ],
    ],
    livro: 'ester',
  },
  {
    abbrev: 'jo',
    capitulos: [
      [
        'Havia um homem na terra de Uz, cujo nome era Jó; e era este homem íntegro, reto e temente a Deus e desviava-se do mal.',
        'E nasceram-lhe sete filhos e três filhas.',
        'E o seu gado era de sete mil ovelhas, três mil camelos, quinhentas juntas de bois e quinhentas jumentas; eram também muitíssimos os servos a seu serviço, de maneira que este homem era maior do que todos os do oriente.',
        'E iam seus filhos à casa uns dos outros e faziam banquetes cada um por sua vez; e mandavam convidar as suas três irmãs a comerem e beberem com eles.',
        'Sucedia, pois, que, decorrido o turno de dias de seus banquetes, enviava Jó, e os santificava, e se levantava de madrugada, e oferecia holocaustos segundo o número de todos eles; porque dizia Jó: Porventura pecaram meus filhos, e amaldiçoaram a Deus no seu coração. Assim fazia Jó continuamente.',
        'E num dia em que os filhos de Deus vieram apresentar-se perante o Senhor, veio também Satanás entre eles.',
        'Então o Senhor disse a Satanás: Donde vens? E Satanás respondeu ao Senhor, e disse: De rodear a terra, e passear por ela.',
        'E disse o Senhor a Satanás: Observaste tu a meu servo Jó? Porque ninguém há na terra semelhante a ele, homem íntegro e reto, temente a Deus, e que se desvia do mal.',
        'Então respondeu Satanás ao Senhor, e disse: Porventura teme Jó a Deus debalde?',
        'Porventura tu não cercaste de sebe, a ele, e a sua casa, e a tudo quanto tem? A obra de suas mãos abençoaste e o seu gado se tem aumentado na terra.',
        'Mas estende a tua mão, e toca-lhe em tudo quanto tem, e verás se não blasfema contra ti na tua face.',
        'E disse o Senhor a Satanás: Eis que tudo quanto ele tem está na tua mão; somente contra ele não estendas a tua mão. E Satanás saiu da presença do Senhor.',
        'E sucedeu um dia, em que seus filhos e suas filhas comiam, e bebiam vinho, na casa de seu irmão primogênito,',
        'Que veio um mensageiro a Jó, e lhe disse: Os bois lavravam, e as jumentas pastavam junto a eles;',
        'E deram sobre eles os sabeus, e os tomaram, e aos servos feriram ao fio da espada; e só eu escapei para trazer-te a nova.',
        'Estando este ainda falando, veio outro e disse: Fogo de Deus caiu do céu, e queimou as ovelhas e os servos, e os consumiu, e só eu escapei para trazer-te a nova.',
        'Estando ainda este falando, veio outro, e disse: Ordenando os caldeus três tropas, deram sobre os camelos, e os tomaram, e aos servos feriram ao fio da espada; e só eu escapei para trazer-te a nova.',
        'Estando ainda este falando, veio outro, e disse: Estando teus filhos e tuas filhas comendo e bebendo vinho, em casa de seu irmão primogênito,',
        'Eis que um grande vento sobreveio dalém do deserto, e deu nos quatro cantos da casa, que caiu sobre os jovens, e morreram; e só eu escapei para trazer-te a nova.',
        'Então Jó se levantou, e rasgou o seu manto, e rapou a sua cabeça, e se lançou em terra, e adorou.',
        'E disse: Nu saí do ventre de minha mãe e nu tornarei para lá; o Senhor o deu, e o Senhor o tomou: bendito seja o nome do Senhor.',
        'Em tudo isto Jó não pecou, nem atribuiu a Deus falta alguma.',
      ],
      [
        'E, vindo outro dia, em que os filhos de Deus vieram apresentar-se perante o SENHOR, veio também Satanás entre eles, apresentar-se perante o SENHOR.',
        'Então o Senhor disse a Satanás: Donde vens? E respondeu Satanás ao Senhor, e disse: De rodear a terra, e passear por ela.',
        'E disse o Senhor a Satanás: Observaste o meu servo Jó? Porque ninguém há na terra semelhante a ele, homem íntegro e reto, temente a Deus e que se desvia do mal, e que ainda retém a sua sinceridade, havendo-me tu incitado contra ele, para o consumir sem causa.',
        'Então Satanás respondeu ao Senhor, e disse: Pele por pele, e tudo quanto o homem tem dará pela sua vida.',
        'Porém estende a tua mão, e toca-lhe nos ossos, e na carne, e verás se não blasfema contra ti na tua face!',
        'E disse o Senhor a Satanás: Eis que ele está na tua mão; porém guarda a sua vida.',
        'Então saiu Satanás da presença do Senhor, e feriu a Jó de úlceras malignas, desde a planta do pé até ao alto da cabeça.',
        'E Jó tomou um caco para se raspar com ele; e estava assentado no meio da cinza.',
        'Então sua mulher lhe disse: Ainda reténs a tua sinceridade? Amaldiçoa a Deus, e morre.',
        'Porém ele lhe disse: Como fala qualquer doida, falas tu; receberemos o bem de Deus, e não receberíamos o mal? Em tudo isto não pecou Jó com os seus lábios.',
        'Ouvindo, pois, três amigos de Jó todo este mal que tinha vindo sobre ele, vieram cada um do seu lugar: Elifaz o temanita, e Bildade o suíta, e Zofar o naamatita; e combinaram condoer-se dele, para o consolarem.',
        'E, levantando de longe os seus olhos, não o conheceram; e levantaram a sua voz e choraram, e rasgaram cada um o seu manto, e sobre as suas cabeças lançaram pó ao ar.',
        'E assentaram-se com ele na terra, sete dias e sete noites; e nenhum lhe dizia palavra alguma, porque viam que a dor era muito grande.',
      ],
      [
        'Depois disto abriu Jó a sua boca, e amaldiçoou o seu dia.',
        'E Jó, falando, disse:',
        'Pereça o dia em que nasci, e a noite em que se disse: Foi concebido um homem!',
        'Converta-se aquele dia em trevas; e Deus, lá de cima, não tenha cuidado dele, nem resplandeça sobre ele a luz.',
        'Contaminem-no as trevas e a sombra da morte; habitem sobre ele nuvens; a escuridão do dia o espante!',
        'Quanto àquela noite, dela se apodere a escuridão; e não se regozije ela entre os dias do ano; e não entre no número dos meses!',
        'Ah! que solitária seja aquela noite, e nela não entre voz de júbilo!',
        'Amaldiçoem-na aqueles que amaldiçoam o dia, que estão prontos para suscitar o seu pranto.',
        'Escureçam-se as estrelas do seu crepúsculo; que espere a luz, e não venha; e não veja as pálpebras da alva;',
        'Porque não fechou as portas do ventre; nem escondeu dos meus olhos a canseira.',
        'Por que não morri eu desde a madre? E em saindo do ventre, não expirei?',
        'Por que me receberam os joelhos? E por que os peitos, para que mamasse?',
        'Porque já agora jazeria e repousaria; dormiria, e então haveria repouso para mim.',
        'Com os reis e conselheiros da terra, que para si edificam casas nos lugares assolados,',
        'Ou com os príncipes que possuem ouro, que enchem as suas casas de prata,',
        'Ou como aborto oculto, não existiria; como as crianças que não viram a luz.',
        'Ali os maus cessam de perturbar; e ali repousam os cansados.',
        'Ali os presos juntamente repousam, e não ouvem a voz do exator.',
        'Ali está o pequeno e o grande, e o servo livre de seu senhor.',
        'Por que se dá luz ao miserável, e vida aos amargurados de ânimo?',
        'Que esperam a morte, e ela não vem; e cavam em procura dela mais do que de tesouros ocultos;',
        'Que de alegria saltam, e exultam, achando a sepultura?',
        'Por que se dá luz ao homem, cujo caminho é oculto, e a quem Deus o encobriu?',
        'Porque antes do meu pão vem o meu suspiro; e os meus gemidos se derramam como água.',
        'Porque aquilo que temia me sobreveio; e o que receava me aconteceu.',
        'Nunca estive tranqüilo, nem sosseguei, nem repousei, mas veio sobre mim a perturbação.',
      ],
      [
        'Então respondeu Elifaz o temanita, e disse:',
        'Se intentarmos falar-te, enfadar-te-ás? Mas quem poderia conter as palavras?',
        'Eis que ensinaste a muitos, e tens fortalecido as mãos fracas.',
        'As tuas palavras firmaram os que tropeçavam e os joelhos desfalecentes tens fortalecido.',
        'Mas agora, que se trata de ti, te enfadas; e tocando-te a ti, te perturbas.',
        'Porventura não é o teu temor de Deus a tua confiança, e a tua esperança a integridade dos teus caminhos?',
        'Lembra-te agora qual é o inocente que jamais pereceu? E onde foram os sinceros destruídos?',
        'Segundo eu tenho visto, os que lavram iniqüidade, e semeiam mal, segam o mesmo.',
        'Com o hálito de Deus perecem; e com o sopro da sua ira se consomem.',
        'O rugido do leão, e a voz do leão feroz, e os dentes dos leõezinhos se quebram.',
        'Perece o leão velho, porque não tem presa; e os filhos da leoa andam dispersos.',
        'Uma coisa me foi trazida em segredo; e os meus ouvidos perceberam um sussurro dela.',
        'Entre pensamentos vindos de visões da noite, quando cai sobre os homens o sono profundo,',
        'Sobrevieram-me o espanto e o tremor, e todos os meus ossos estremeceram.',
        'Então um espírito passou por diante de mim; fez-me arrepiar os cabelos da minha carne.',
        'Parou ele, porém não conheci a sua feição; um vulto estava diante dos meus olhos; houve silêncio, e ouvi uma voz que dizia:',
        'Seria porventura o homem mais justo do que Deus? Seria porventura o homem mais puro do que o seu Criador?',
        'Eis que ele não confia nos seus servos e aos seus anjos atribui loucura;',
        'Quanto menos àqueles que habitam em casas de lodo, cujo fundamento está no pó, e são esmagados como a traça!',
        'Desde a manhã até à tarde são despedaçados; e eternamente perecem sem que disso se faça caso.',
        'Porventura não passa com eles a sua excelência? Morrem, mas sem sabedoria.',
      ],
      [
        'Chama agora; há alguém que te responda? E para qual dos santos te virarás?',
        'Porque a ira destrói o louco; e o zelo mata o tolo.',
        'Bem vi eu o louco lançar raízes; porém logo amaldiçoei a sua habitação.',
        'Seus filhos estão longe da salvação; e são despedaçados às portas, e não há quem os livre.',
        'A sua messe, o faminto a devora, e até dentre os espinhos a tira; e o salteador traga a sua fazenda.',
        'Porque do pó não procede a aflição, nem da terra brota o trabalho.',
        'Mas o homem nasce para a tribulação, como as faíscas se levantam para voar.',
        'Porém eu buscaria a Deus; e a ele entregaria a minha causa.',
        'Ele faz coisas grandes e inescrutáveis, e maravilhas sem número.',
        'Ele dá a chuva sobre a terra, e envia águas sobre os campos.',
        'Para pôr aos abatidos num lugar alto; e para que os enlutados se exaltem na salvação.',
        'Ele aniquila as imaginações dos astutos, para que as suas mãos não possam levar coisa alguma a efeito.',
        'Ele apanha os sábios na sua própria astúcia; e o conselho dos perversos se precipita.',
        'Eles de dia encontram as trevas; e ao meio-dia andam às apalpadelas como de noite.',
        'Porém ao necessitado livra da espada, e da boca deles, e da mão do forte.',
        'Assim há esperança para o pobre; e a iniqüidade tapa a sua boca.',
        'Eis que bem-aventurado é o homem a quem Deus repreende; não desprezes, pois, a correção do Todo-Poderoso.',
        'Porque ele faz a chaga, e ele mesmo a liga; ele fere, e as suas mãos curam.',
        'Em seis angústias te livrará; e na sétima o mal não te tocará.',
        'Na fome te livrará da morte; e na guerra, da violência da espada.',
        'Do açoite da língua estarás encoberto; e não temerás a assolação, quando vier.',
        'Da assolação e da fome te rirás, e os animais da terra não temerás.',
        'Porque até com as pedras do campo terás o teu acordo, e as feras do campo serão pacíficas contigo.',
        'E saberás que a tua tenda está em paz; e visitarás a tua habitação, e não pecarás.',
        'Também saberás que se multiplicará a tua descendência e a tua posteridade como a erva da terra,',
        'Na velhice irás à sepultura, como se recolhe o feixe de trigo a seu tempo.',
        'Eis que isto já o havemos inquirido, e assim é; ouve-o, e medita nisso para teu bem.',
      ],
      [
        'Então Jó respondeu, dizendo:',
        'Oh! se a minha mágoa retamente se pesasse, e a minha miséria juntamente se pusesse numa balança!',
        'Porque, na verdade, mais pesada seria, do que a areia dos mares; por isso é que as minhas palavras têm sido engolidas.',
        'Porque as flechas do TodoPoderoso estão em mim, cujo ardente veneno suga o meu espírito; os terrores de Deus se armam contra mim.',
        'Porventura zurrará o jumento montês junto à relva? Ou mugirá o boi junto ao seu pasto?',
        'Ou comer-se-á sem sal o que é insípido? Ou haverá gosto na clara do ovo?',
        'A minha alma recusa tocá-las, pois são para mim como comida repugnante.',
        'Quem dera que se cumprisse o meu desejo, e que Deus me desse o que espero!',
        'E que Deus quisesse quebrantar-me, e soltasse a sua mão, e me acabasse!',
        'Isto ainda seria a minha consolação, e me refrigeraria no meu tormento, não me poupando ele; porque não ocultei as palavras do Santo.',
        'Qual é a minha força, para que eu espere? Ou qual é o meu fim, para que tenha ainda paciência?',
        'É porventura a minha força a força da pedra? Ou é de cobre a minha carne?',
        'Está em mim a minha ajuda? Ou desamparou-me a verdadeira sabedoria?',
        'Ao que está aflito devia o amigo mostrar compaixão, ainda ao que deixasse o temor do Todo-Poderoso.',
        'Meus irmãos aleivosamente me trataram, como um ribeiro, como a torrente dos ribeiros que passam,',
        'Que estão encobertos com a geada, e neles se esconde a neve,',
        'No tempo em que se derretem com o calor, se desfazem, e em se aquentando, desaparecem do seu lugar.',
        'Desviam-se as veredas dos seus caminhos; sobem ao vácuo, e perecem.',
        'Os caminhantes de Tema os vêem; os passageiros de Sabá esperam por eles.',
        'Ficam envergonhados, por terem confiado e, chegando ali, se confundem.',
        'Agora sois semelhantes a eles; vistes o terror, e temestes.',
        'Acaso disse eu: Dai-me ou oferecei-me presentes de vossos bens?',
        'Ou livrai-me das mãos do opressor? Ou redimi-me das mãos dos tiranos?',
        'Ensinai-me, e eu me calarei; e fazei-me entender em que errei.',
        'Oh! quão fortes são as palavras da boa razão! Mas que é o que censura a vossa argüição?',
        'Porventura buscareis palavras para me repreenderdes, visto que as razões do desesperado são como vento?',
        'Mas antes lançais sortes sobre o órfão; e cavais uma cova para o amigo.',
        'Agora, pois, se sois servidos, olhai para mim; e vede se minto em vossa presença.',
        'Voltai, pois, não haja iniqüidade; tornai-vos, digo, que ainda a minha justiça aparecerá nisso.',
        'Há porventura iniqüidade na minha língua? Ou não poderia o meu paladar distinguir coisas iníquas?',
      ],
      [
        'Porventura não tem o homem guerra sobre a terra? E não são os seus dias como os dias do jornaleiro?',
        'Como o servo que suspira pela sombra, e como o jornaleiro que espera pela sua paga,',
        'Assim me deram por herança meses de vaidade; e noites de trabalho me prepararam.',
        'Deitando-me a dormir, então digo: Quando me levantarei? Mas comprida é a noite, e farto-me de me revolver na cama até à alva.',
        'A minha carne se tem vestido de vermes e de torrões de pó; a minha pele está gretada, e se fez abominável.',
        'Os meus dias são mais velozes do que a lançadeira do tecelão, e acabam-se, sem esperança.',
        'Lembra-te de que a minha vida é como o vento; os meus olhos não tornarão a ver o bem.',
        'Os olhos dos que agora me vêem não me verão mais; os teus olhos estarão sobre mim, porém não serei mais.',
        'Assim como a nuvem se desfaz e passa, assim aquele que desce à sepultura nunca tornará a subir.',
        'Nunca mais tornará à sua casa, nem o seu lugar jamais o conhecerá.',
        'Por isso não reprimirei a minha boca; falarei na angústia do meu espírito; queixar-me-ei na amargura da minha alma.',
        'Sou eu porventura o mar, ou a baleia, para que me ponhas uma guarda?',
        'Dizendo eu: Consolar-me-á a minha cama; meu leito aliviará a minha ânsia;',
        'Então me espantas com sonhos, e com visões me assombras;',
        'Assim a minha alma escolheria antes a estrangulação; e antes a morte do que a vida.',
        'A minha vida abomino, pois não viveria para sempre; retira-te de mim; pois vaidade são os meus dias.',
        'Que é o homem, para que tanto o engrandeças, e ponhas nele o teu coração,',
        'E cada manhã o visites, e cada momento o proves?',
        'Até quando não apartarás de mim, nem me largarás, até que engula a minha saliva?',
        'Se pequei, que te farei, ó Guarda dos homens? Por que fizeste de mim um alvo para ti, para que a mim mesmo me seja pesado?',
        'E por que não perdoas a minha transgressão, e não tiras a minha iniqüidade? Porque agora me deitarei no pó, e de madrugada me buscarás, e não existirei mais.',
      ],
      [
        'Então respondendo Bildade o suíta, disse:',
        'Até quando falarás tais coisas, e as palavras da tua boca serão como um vento impetuoso?',
        'Porventura perverteria Deus o direito? E perverteria o TodoPoderoso a justiça?',
        'Se teus filhos pecaram contra ele, também ele os lançou na mão da sua transgressão.',
        'Mas, se tu de madrugada buscares a Deus, e ao Todo-Poderoso pedires misericórdia;',
        'Se fores puro e reto, certamente logo despertará por ti, e restaurará a morada da tua justiça.',
        'O teu princípio, na verdade, terá sido pequeno, porém o teu último estado crescerá em extremo.',
        'Pois, eu te peço, pergunta agora às gerações passadas; e prepara-te para a inquirição de seus pais.',
        'Porque nós somos de ontem, e nada sabemos; porquanto nossos dias sobre a terra são como a sombra.',
        'Porventura não te ensinarão eles, e não te falarão, e do seu coração não tirarão palavras?',
        'Porventura cresce o junco sem lodo? Ou cresce a espadana sem água?',
        'Estando ainda no seu verdor, ainda que não cortada, todavia antes de qualquer outra erva se seca.',
        'Assim são as veredas de todos quantos se esquecem de Deus; e a esperança do hipócrita perecerá.',
        'Cuja esperança fica frustrada; e a sua confiança será como a teia de aranha.',
        'Encostar-se-á à sua casa, mas ela não subsistirá; apegar-se-á a ela, mas não ficará em pé.',
        'Ele é viçoso perante o sol, e os seus renovos saem sobre o seu jardim;',
        'As suas raízes se entrelaçam, junto à fonte; para o pedregal atenta.',
        'Se Deus o consumir do seu lugar, negá-lo-á este, dizendo: Nunca te vi!',
        'Eis que este é a alegria do seu caminho, e outros brotarão do pó.',
        'Eis que Deus não rejeitará ao reto; nem toma pela mão aos malfeitores;',
        'Até que de riso te encha a boca, e os teus lábios de júbilo.',
        'Os que te odeiam se vestirão de confusão, e a tenda dos ímpios não existirá mais.',
      ],
      [
        'Então Jó respondeu, dizendo:',
        'Na verdade sei que assim é; porque, como se justificaria o homem para com Deus?',
        'Se quiser contender com ele, nem a uma de mil coisas lhe poderá responder.',
        'Ele é sábio de coração, e forte em poder; quem se endureceu contra ele, e teve paz?',
        'Ele é o que remove os montes, sem que o saibam, e o que os transtorna no seu furor.',
        'O que sacode a terra do seu lugar, e as suas colunas estremecem.',
        'O que fala ao sol, e ele não nasce, e sela as estrelas.',
        'O que sozinho estende os céus, e anda sobre os altos do mar.',
        'O que fez a Ursa, o Órion, e o Sete-estrelo, e as recâmaras do sul.',
        'O que faz coisas grandes e inescrutáveis; e maravilhas sem número.',
        'Eis que ele passa por diante de mim, e não o vejo; e torna a passar perante mim, e não o sinto.',
        'Eis que arrebata a presa; quem lha fará restituir? Quem lhe dirá: Que é o que fazes?',
        'Deus não revogará a sua ira; debaixo dele se encurvam os auxiliadores soberbos.',
        'Quanto menos lhe responderia eu, ou escolheria diante dele as minhas palavras!',
        'Porque, ainda que eu fosse justo, não lhe responderia; antes ao meu Juiz pediria misericórdia.',
        'Ainda que chamasse, e ele me respondesse, nem por isso creria que desse ouvidos à minha voz.',
        'Porque me quebranta com uma tempestade, e multiplica as minhas chagas sem causa.',
        'Não me permite respirar, antes me farta de amarguras.',
        'Quanto às forças, eis que ele é o forte; e, quanto ao juízo, quem me citará com ele?',
        'Se eu me justificar, a minha boca me condenará; se for perfeito, então ela me declarará perverso.',
        'Se for perfeito, não estimo a minha alma; desprezo a minha vida.',
        'A coisa é esta; por isso eu digo que ele consome ao perfeito e ao ímpio.',
        'Quando o açoite mata de repente, então ele zomba da prova dos inocentes.',
        'A terra é entregue nas mãos do ímpio; ele cobre o rosto dos juízes; se não é ele, quem é, logo?',
        'E os meus dias são mais velozes do que um correio; fugiram, e não viram o bem.',
        'Passam como navios veleiros; como águia que se lança à comida.',
        'Se eu disser: Eu me esquecerei da minha queixa, e mudarei o meu aspecto e tomarei alento,',
        'Receio todas as minhas dores, porque bem sei que não me terás por inocente.',
        'E, sendo eu ímpio, por que trabalharei em vão?',
        'Ainda que me lave com água de neve, e purifique as minhas mãos com sabão,',
        'Ainda me submergirás no fosso, e as minhas próprias vestes me abominarão.',
        'Porque ele não é homem, como eu, a quem eu responda, vindo juntamente a juízo.',
        'Não há entre nós árbitro que ponha a mão sobre nós ambos.',
        'Tire ele a sua vara de cima de mim, e não me amedronte o seu terror.',
        'Então falarei, e não o temerei; porque não sou assim em mim mesmo.',
      ],
      [
        'A minha alma tem tédio da minha vida; darei livre curso à minha queixa, falarei na amargura da minha alma.',
        'Direi a Deus: Não me condenes; faze-me saber por que contendes comigo.',
        'Parece-te bem que me oprimas, que rejeites o trabalho das tuas mãos e resplandeças sobre o conselho dos ímpios?',
        'Tens tu porventura olhos de carne? Vês tu como vê o homem?',
        'São os teus dias como os dias do homem? Ou são os teus anos como os anos de um homem,',
        'Para te informares da minha iniqüidade, e averiguares o meu pecado?',
        'Bem sabes tu que eu não sou iníquo; todavia ninguém há que me livre da tua mão.',
        'As tuas mãos me fizeram e me formaram completamente; contudo me consomes.',
        'Peço-te que te lembres de que como barro me formaste e me farás voltar ao pó.',
        'Porventura não me vazaste como leite, e como queijo não me coalhaste?',
        'De pele e carne me vestiste, e de ossos e nervos me teceste.',
        'Vida e misericórdia me concedeste; e o teu cuidado guardou o meu espírito.',
        'Porém estas coisas as ocultaste no teu coração; bem sei eu que isto esteve contigo.',
        'Se eu pecar, tu me observas; e da minha iniqüidade não me escusarás.',
        'Se for ímpio, ai de mim! E se for justo, não levantarei a minha cabeça; farto estou da minha ignomínia; e vê qual é a minha aflição,',
        'Porque se vai crescendo; tu me caças como a um leão feroz; tornas a fazer maravilhas para comigo.',
        'Tu renovas contra mim as tuas testemunhas, e multiplicas contra mim a tua ira; reveses e combate estão comigo.',
        'Por que, pois, me tiraste da madre? Ah! se então tivera expirado, e olho nenhum me visse!',
        'Então eu teria sido como se nunca fora; e desde o ventre seria levado à sepultura!',
        'Porventura não são poucos os meus dias? Cessa, pois, e deixa-me, para que por um pouco eu tome alento.',
        'Antes que eu vá para o lugar de que não voltarei, à terra da escuridão e da sombra da morte;',
        'Terra escuríssima, como a própria escuridão, terra da sombra da morte e sem ordem alguma, e onde a luz é como a escuridão.',
      ],
      [
        'Então respondeu Zofar, o naamatita, e disse:',
        'Porventura não se dará resposta à multidão de palavras? E o homem falador será justificado?',
        'Às tuas mentiras se hão de calar os homens? E zombarás tu sem que ninguém te envergonhe?',
        'Pois dizes: A minha doutrina é pura, e limpo sou aos teus olhos.',
        'Mas na verdade, quem dera que Deus falasse e abrisse os seus lábios contra ti!',
        'E te fizesse saber os segredos da sabedoria, que é multíplice em eficácia; sabe, pois, que Deus exige de ti menos do que merece a tua iniqüidade.',
        'Porventura alcançarás os caminhos de Deus, ou chegarás à perfeição do Todo-Poderoso?',
        'Como as alturas dos céus é a sua sabedoria; que poderás tu fazer? É mais profunda do que o inferno, que poderás tu saber?',
        'Mais comprida é a sua medida do que a terra, e mais larga do que o mar.',
        'Se ele passar, aprisionar, ou chamar a juízo, quem o impedirá?',
        'Porque ele conhece aos homens vãos, e vê o vício; e não o terá em consideração?',
        'Mas o homem vão é falto de entendimento; sim, o homem nasce como a cria do jumento montês.',
        'Se tu preparares o teu coração, e estenderes as tuas mãos para ele;',
        'Se há iniqüidade na tua mão, lança-a para longe de ti e não deixes habitar a injustiça nas tuas tendas.',
        'Porque então o teu rosto levantarás sem mácula; e estarás firme, e não temerás.',
        'Porque te esquecerás do cansaço, e lembrar-te-ás dele como das águas que já passaram.',
        'E a tua vida mais clara se levantará do que o meio-dia; ainda que haja trevas, será como a manhã.',
        'E terás confiança, porque haverá esperança; olharás em volta e repousarás seguro.',
        'E deitar-te-ás, e ninguém te espantará; muitos suplicarão o teu favor.',
        'Porém os olhos dos ímpios desfalecerão, e perecerá o seu refúgio; e a sua esperança será o expirar da alma.',
      ],
      [
        'Então Jó respondeu, dizendo:',
        'Na verdade, vós sois o povo, e convosco morrerá a sabedoria.',
        'Também eu tenho entendimento como vós, e não vos sou inferior; e quem não sabe tais coisas como essas?',
        'Eu sou motivo de riso para os meus amigos; eu, que invoco a Deus, e ele me responde; o justo e perfeito serve de zombaria.',
        'Tocha desprezível é, na opinião do que está descansado, aquele que está pronto a vacilar com os pés.',
        'As tendas dos assoladores têm descanso, e os que provocam a Deus estão seguros; nas suas mãos Deus lhes põe tudo.',
        'Mas, pergunta agora às alimárias, e cada uma delas te ensinará; e às aves dos céus, e elas te farão saber;',
        'Ou fala com a terra, e ela te ensinará; até os peixes do mar te contarão.',
        'Quem não entende, por todas estas coisas, que a mão do Senhor fez isto?',
        'Na sua mão está a alma de tudo quanto vive, e o espírito de toda a carne humana.',
        'Porventura o ouvido não provará as palavras, como o paladar prova as comidas?',
        'Com os idosos está a sabedoria, e na longevidade o entendimento.',
        'Com ele está a sabedoria e a força; conselho e entendimento tem.',
        'Eis que ele derruba, e ninguém há que edifique; prende um homem, e ninguém há que o solte.',
        'Eis que ele retém as águas, e elas secam; e solta-as, e elas transtornam a terra.',
        'Com ele está a força e a sabedoria; seu é o que erra e o que o faz errar.',
        'Aos conselheiros leva despojados, e aos juízes faz desvairar.',
        'Solta a autoridade dos reis, e ata o cinto aos seus lombos.',
        'Aos sacerdotes leva despojados, aos poderosos transtorna.',
        'Aos acreditados tira a fala, e tira o entendimento aos anciãos.',
        'Derrama desprezo sobre os príncipes, e afrouxa o cinto dos fortes.',
        'Das trevas descobre coisas profundas, e traz à luz a sombra da morte.',
        'Multiplica as nações e as faz perecer; dispersa as nações, e de novo as reconduz.',
        'Tira o entendimento aos chefes dos povos da terra, e os faz vaguear pelos desertos, sem caminho.',
        'Nas trevas andam às apalpadelas, sem terem luz, e os faz desatinar como ébrios.',
      ],
      [
        'Eis que tudo isto viram os meus olhos, e os meus ouvidos o ouviram e entenderam.',
        'Como vós o sabeis, também eu o sei; não vos sou inferior.',
        'Mas eu falarei ao Todo-Poderoso, e quero defender-me perante Deus.',
        'Vós, porém, sois inventores de mentiras, e vós todos médicos que não valem nada.',
        'Quem dera que vos calásseis de todo, pois isso seria a vossa sabedoria.',
        'Ouvi agora a minha defesa, e escutai os argumentos dos meus lábios.',
        'Porventura por Deus falareis perversidade e por ele falareis mentiras?',
        'Fareis acepção da sua pessoa? Contendereis por Deus?',
        'Ser-vos-ia bom, se ele vos esquadrinhasse? Ou zombareis dele, como se zomba de algum homem?',
        'Certamente vos repreenderá, se em oculto fizerdes acepção de pessoas.',
        'Porventura não vos espantará a sua alteza, e não cairá sobre vós o seu terror?',
        'As vossas memórias são como provérbios de cinza; as vossas defesas como defesas de lodo.',
        'Calai-vos perante mim, e falarei eu, e venha sobre mim o que vier.',
        'Por que razão tomarei eu a minha carne com os meus dentes, e porei a minha vida na minha mão?',
        'Ainda que ele me mate, nele esperarei; contudo os meus caminhos defenderei diante dele.',
        'Também ele será a minha salvação; porém o hipócrita não virá perante ele.',
        'Ouvi com atenção as minhas palavras, e com os vossos ouvidos a minha declaração.',
        'Eis que já tenho ordenado a minha causa, e sei que serei achado justo.',
        'Quem é o que contenderá comigo? Se eu agora me calasse, renderia o espírito.',
        'Duas coisas somente não faças para comigo; então não me esconderei do teu rosto:',
        'Desvia a tua mão para longe, de mim, e não me espante o teu terror.',
        'Chama, pois, e eu responderei; ou eu falarei, e tu me responderás.',
        'Quantas culpas e pecados tenho eu? Notifica-me a minha transgressão e o meu pecado.',
        'Por que escondes o teu rosto, e me tens por teu inimigo?',
        'Porventura acossarás uma folha arrebatada pelo vento? E perseguirás o restolho seco?',
        'Por que escreves contra mim coisas amargas e me fazes herdar as culpas da minha mocidade?',
        'Também pões os meus pés no tronco, e observas todos os meus caminhos, e marcas os sinais dos meus pés.',
        'E ele me consome como a podridão, e como a roupa, à qual rói a traça.',
      ],
      [
        'O homem, nascido da mulher, é de poucos dias e farto de inquietação.',
        'Sai como a flor, e murcha; foge também como a sombra, e não permanece.',
        'E sobre este tal abres os teus olhos, e a mim me fazes entrar no juízo contigo.',
        'Quem do imundo tirará o puro? Ninguém.',
        'Visto que os seus dias estão determinados, contigo está o número dos seus meses; e tu lhe puseste limites, e não passará além deles.',
        'Desvia-te dele, para que tenha repouso, até que, como o jornaleiro, tenha contentamento no seu dia.',
        'Porque há esperança para a árvore que, se for cortada, ainda se renovará, e não cessarão os seus renovos.',
        'Se envelhecer na terra a sua raiz, e o seu tronco morrer no pó,',
        'Ao cheiro das águas brotará, e dará ramos como uma planta.',
        'Porém, morto o homem, é consumido; sim, rendendo o homem o espírito, então onde está ele?',
        'Como as águas se retiram do mar, e o rio se esgota, e fica seco,',
        'Assim o homem se deita, e não se levanta; até que não haja mais céus, não acordará nem despertará de seu sono.',
        'Quem dera que me escondesses na sepultura, e me ocultasses até que a tua ira se fosse; e me pusesses um limite, e te lembrasses de mim!',
        'Morrendo o homem, porventura tornará a viver? Todos os dias de meu combate esperaria, até que viesse a minha mudança.',
        'Chamar-me-ias, e eu te responderia, e terias afeto à obra de tuas mãos.',
        'Mas agora contas os meus passos; porventura não vigias sobre o meu pecado?',
        'A minha transgressão está selada num saco, e amontoas as minhas iniqüidades.',
        'E, na verdade, caindo a montanha, desfaz-se; e a rocha se remove do seu lugar.',
        'As águas gastam as pedras, as cheias afogam o pó da terra; e tu fazes perecer a esperança do homem;',
        'Tu para sempre prevaleces contra ele, e ele passa; mudas o seu rosto, e o despedes.',
        'Os seus filhos recebem honra, sem que ele o saiba; são humilhados, sem que ele o perceba;',
        'Mas a sua carne nele tem dores, e a sua alma nele lamenta.',
      ],
      [
        'Então respondeu Elifaz o temanita, e disse:',
        'Porventura proferirá o sábio và sabedoria? E encherá do vento oriental o seu ventre,',
        'Argüindo com palavras que de nada servem, e com razões, de que nada aproveita?',
        'E tu tens feito vão o temor, e diminuis os rogos diante de Deus.',
        'Porque a tua boca declara a tua iniqüidade; e tu escolhes a língua dos astutos.',
        'A tua boca te condena, e não eu, e os teus lábios testificam contra ti.',
        'És tu porventura o primeiro homem que nasceu? Ou foste formado antes dos outeiros?',
        'Ou ouviste o secreto conselho de Deus e a ti só limitaste a sabedoria?',
        'Que sabes tu, que nós não saibamos? Que entendes, que não haja em nós?',
        'Também há entre nós encanecidos e idosos, muito mais idosos do que teu pai.',
        'Porventura fazes pouco caso das consolações de Deus, e da suave palavra que te dirigimos?',
        'Por que te arrebata o teu coração, e por que piscam os teus olhos?',
        'Para virares contra Deus o teu espírito, e deixares sair tais palavras da tua boca?',
        'Que é o homem, para que seja puro? E o que nasce da mulher, para ser justo?',
        'Eis que ele não confia nos seus santos, e nem os céus são puros aos seus olhos.',
        'Quanto mais abominável e corrupto é o homem que bebe a iniqüidade como a água?',
        'Escuta-me, mostrar-te-ei; e o que tenho visto te contarei',
        '(O que os sábios anunciaram, ouvindo-o de seus pais, e o não ocultaram;',
        'Aos quais somente se dera a terra, e nenhum estranho passou por entre eles):',
        'Todos os dias o ímpio é atormentado, e se reserva, para o tirano, um certo número de anos.',
        'O sonido dos horrores está nos seus ouvidos; até na paz lhe sobrevém o assolador.',
        'Não crê que tornará das trevas, mas que o espera a espada.',
        'Anda vagueando por pão, dizendo: Onde está? Bem sabe que já o dia das trevas lhe está preparado, à mão.',
        'Assombram-no a angústia e a tribulação; prevalecem contra ele, como o rei preparado para a peleja;',
        'Porque estendeu a sua mão contra Deus, e contra o Todo-Poderoso se embraveceu.',
        'Arremete contra ele com a dura cerviz, e contra os pontos grossos dos seus escudos.',
        'Porquanto cobriu o seu rosto com a sua gordura, e criou gordura nas ilhargas.',
        'E habitou em cidades assoladas, em casas em que ninguém morava, que estavam a ponto de fazer-se montões de ruínas.',
        'Não se enriquecerá, nem subsistirá a sua fazenda, nem se estenderão pela terra as suas possessões.',
        'Não escapará das trevas; a chama do fogo secará os seus renovos, e ao sopro da sua boca desaparecerá.',
        'Não confie, pois, na vaidade, enganando-se a si mesmo, porque a vaidade será a sua recompensa.',
        'Antes do seu dia ela se consumará; e o seu ramo não reverdecerá.',
        'Sacudirá as suas uvas verdes, como as da vide, e deixará cair a sua flor como a oliveira,',
        'Porque a congregação dos hipócritas se fará estéril, e o fogo consumirá as tendas do suborno.',
        'Concebem a malícia, e dão à luz a iniqüidade, e o seu ventre prepara enganos.',
      ],
      [
        'Então respondeu Jó, dizendo:',
        'Tenho ouvido muitas coisas como estas; todos vós sois consoladores molestos.',
        'Porventura não terão fim essas palavras de vento? Ou o que te irrita, para assim responderes?',
        'Falaria eu também como vós falais, se a vossa alma estivesse em lugar da minha alma, ou amontoaria palavras contra vós, e menearia contra vós a minha cabeça?',
        'Antes vos fortaleceria com a minha boca, e a consolação dos meus lábios abrandaria a vossa dor.',
        'Se eu falar, a minha dor não cessa, e, calando-me eu, qual é o meu alívio?',
        'Na verdade, agora tu me tens fatigado; tu assolaste toda a minha companhia,',
        'Testemunha disto é que já me fizeste enrugado, e a minha magreza já se levanta contra mim, e no meu rosto testifica contra mim.',
        'Na sua ira me despedaçou, e ele me perseguiu; rangeu os seus dentes contra mim; aguça o meu adversário os seus olhos contra mim.',
        'Abrem a sua boca contra mim; com desprezo me feriram nos queixos, e contra mim se ajuntam todos.',
        'Entrega-me Deus ao perverso, e nas mãos dos ímpios me faz cair.',
        'Descansado estava eu, porém ele me quebrantou; e pegou-me pela cerviz, e me despedaçou; também me pôs por seu alvo.',
        'Cercam-me os seus flecheiros; atravessa-me os rins, e não me poupa, e o meu fel derrama sobre a terra,',
        'Fere-me com ferimento sobre ferimento; arremete contra mim como um valente.',
        'Cosi sobre a minha pele o cilício, e revolvi a minha cabeça no pó.',
        'O meu rosto está todo avermelhado de chorar, e sobre as minhas pálpebras está a sombra da morte:',
        'Apesar de não haver violência nas minhas mãos, e de ser pura a minha oração.',
        'Ah! terra, não cubras o meu sangue e não haja lugar para ocultar o meu clamor!',
        'Eis que também agora a minha testemunha está no céu, e nas alturas o meu testemunho está.',
        'Os meus amigos são os que zombam de mim; os meus olhos se desfazem em lágrimas diante de Deus.',
        'Ah! se alguém pudesse contender com Deus pelo homem, como o homem pelo seu próximo!',
        'Porque decorridos poucos anos, eu seguirei o caminho por onde não tornarei.',
      ],
      [
        'O meu espírito se vai consumindo, os meus dias se vão apagando, e só tenho perante mim a sepultura.',
        'Deveras estou cercado de zombadores, e os meus olhos contemplam as suas provocações.',
        'Promete agora, e dá-me um fiador para contigo; quem há que me dê a mão?',
        'Porque aos seus corações encobriste o entendimento, por isso não os exaltarás.',
        'O que denuncia os seus amigos, a fim de serem despojados, também os olhos de seus filhos desfalecerão.',
        'Porém a mim me pôs por um provérbio dos povos, de modo que me tornei uma abominação para eles.',
        'Pelo que já se escureceram de mágoa os meus olhos, e já todos os meus membros são como a sombra.',
        'Os retos pasmarão disto, e o inocente se levantará contra o hipócrita.',
        'E o justo seguirá o seu caminho firmemente, e o puro de mãos irá crescendo em força.',
        'Mas, na verdade, tornai todos vós e vinde; porque sábio nenhum acharei entre vós.',
        'Os meus dias passaram, e malograram os meus propósitos, as aspirações do meu coração.',
        'Trocaram a noite em dia; a luz está perto do fim, por causa das trevas.',
        'Se eu esperar, a sepultura será a minha casa; nas trevas estenderei a minha cama.',
        'À corrupção clamo: Tu és meu pai; e aos vermes: Vós sois minha mãe e minha irmã.',
        'Onde, pois, estaria agora a minha esperança? Sim, a minha esperança, quem a poderá ver?',
        'As barras da sepultura descerão quando juntamente no pó teremos descanso.',
      ],
      [
        'Então respondeu Bildade, o suíta, e disse:',
        'Até quando poreis fim às palavras? Considerai bem, e então falaremos.',
        'Por que somos tratados como animais, e como imundos aos vossos olhos?',
        'Oh tu, que despedaças a tua alma na tua ira, será a terra deixada por tua causa? Remover-se-ão as rochas do seu lugar?',
        'Na verdade, a luz dos ímpios se apagará, e a chama do seu fogo não resplandecerá.',
        'A luz se escurecerá nas suas tendas, e a sua lâmpada sobre ele se apagará.',
        'Os seus passos firmes se estreitarão, e o seu próprio conselho o derrubará.',
        'Porque por seus próprios pés é lançado na rede, e andará nos fios enredados.',
        'O laço o apanhará pelo calcanhar, e a armadilha o prenderá.',
        'Está escondida debaixo da terra uma corda, e uma armadilha na vereda.',
        'Os assombros o espantarão de todos os lados, e o perseguirão a cada passo.',
        'Será faminto o seu vigor, e a destruição está pronta ao seu lado.',
        'Serão devorados os membros do seu corpo; sim, o primogênito da morte devorará os seus membros.',
        'A sua confiança será arrancada da sua tenda, onde está confiado, e isto o fará caminhar para o rei dos terrores.',
        'Morará na sua mesma tenda, o que não lhe pertence; espalhar-se-á enxofre sobre a sua habitação.',
        'Por baixo se secarão as suas raízes e por cima serão cortados os seus ramos.',
        'A sua memória perecerá da terra, e pelas praças não terá nome.',
        'Da luz o lançarão nas trevas, e afugentá-lo-ão do mundo.',
        'Não terá filho nem neto entre o seu povo, e nem quem lhe suceda nas suas moradas.',
        'Do seu dia se espantarão os do ocidente, assim como se espantam os do oriente.',
        'Tais são, na verdade, as moradas do perverso, e este é o lugar do que não conhece a Deus.',
      ],
      [
        'Respondeu, porém, Jó, dizendo:',
        'Até quando afligireis a minha alma, e me quebrantareis com palavras?',
        'Já dez vezes me vituperastes; não tendes vergonha de injuriar-me.',
        'Embora haja eu, na verdade, errado, comigo ficará o meu erro.',
        'Se deveras vos quereis engrandecer contra mim, e argüir-me pelo meu opróbrio,',
        'Sabei agora que Deus é o que me transtornou, e com a sua rede me cercou.',
        'Eis que clamo: Violência! Porém não sou ouvido. Grito: Socorro! Porém não há justiça.',
        'O meu caminho ele entrincheirou, e já não posso passar, e nas minhas veredas pôs trevas.',
        'Da minha honra me despojou; e tirou-me a coroa da minha cabeça.',
        'Quebrou-me de todos os lados, e eu me vou; e arrancou a minha esperança, como a uma árvore.',
        'E fez inflamar contra mim a sua ira, e me reputou para consigo, como a seus inimigos.',
        'Juntas vieram as suas tropas, e prepararam contra mim o seu caminho, e se acamparam ao redor da minha tenda.',
        'pôs longe de mim a meus irmãos, e os que me conhecem, como estranhos se apartaram de mim.',
        'Os meus parentes me deixaram, e os meus conhecidos se esqueceram de mim.',
        'Os meus domésticos e as minhas servas me reputaram como um estranho, e vim a ser um estrangeiro aos seus olhos.',
        'Chamei a meu criado, e ele não me respondeu; cheguei a suplicar-lhe com a minha própria boca.',
        'O meu hálito se fez estranho à minha mulher; tanto que supliquei o interesse dos filhos do meu corpo.',
        'Até os pequeninos me desprezam, e, levantando-me eu, falam contra mim.',
        'Todos os homens da minha confidência me abominam, e até os que eu amava se tornaram contra mim.',
        'Os meus ossos se apegaram à minha pele e à minha carne, e escapei só com a pele dos meus dentes.',
        'Compadecei-vos de mim, amigos meus, compadecei-vos de mim, porque a mão de Deus me tocou.',
        'Por que me perseguis assim como Deus, e da minha carne não vos fartais?',
        'Quem me dera agora, que as minhas palavras fossem escritas! Quem me dera, fossem gravadas num livro!',
        'E que, com pena de ferro, e com chumbo, para sempre fossem esculpidas na rocha.',
        'Porque eu sei que o meu Redentor vive, e que por fim se levantará sobre a terra.',
        'E depois de consumida a minha pele, contudo ainda em minha carne verei a Deus,',
        'Vê-lo-ei, por mim mesmo, e os meus olhos, e não outros o contemplarão; e por isso os meus rins se consomem no meu interior.',
        'Na verdade, que devíeis dizer: Por que o perseguimos? Pois a raiz da acusação se acha em mim.',
        'Temei vós mesmos a espada; porque o furor traz os castigos da espada, para saberdes que há um juízo.',
      ],
      [
        'Então respondeu Zofar, o naamatita, e disse:',
        'Visto que os meus pensamentos me fazem responder, eu me apresso.',
        'Eu ouvi a repreensão, que me envergonha, mas o espírito do meu entendimento responderá por mim.',
        'Porventura não sabes tu que desde a antiguidade, desde que o homem foi posto sobre a terra,',
        'O júbilo dos ímpios é breve, e a alegria dos hipócritas momentânea?',
        'Ainda que a sua altivez suba até ao céu, e a sua cabeça chegue até às nuvens.',
        'Contudo, como o seu próprio esterco, perecerá para sempre; e os que o viam dirão: Onde está?',
        'Como um sonho voará, e não será achado, e será afugentado como uma visão da noite.',
        'O olho, que já o viu, jamais o verá, nem o seu lugar o verá mais.',
        'Os seus filhos procurarão agradar aos pobres, e as suas mãos restituirão os seus bens.',
        'Os seus ossos estão cheios do vigor da sua mocidade, mas este se deitará com ele no pó.',
        'Ainda que o mal lhe seja doce na boca, e ele o esconda debaixo da sua língua,',
        'E o guarde, e não o deixe, antes o retenha no seu paladar,',
        'Contudo a sua comida se mudará nas suas entranhas; fel de áspides será interiormente.',
        'Engoliu riquezas, porém vomitá-las-á; do seu ventre Deus as lançará.',
        'Veneno de áspides sorverá; língua de víbora o matará.',
        'Não verá as correntes, os rios e os ribeiros de mel e manteiga.',
        'Restituirá o seu trabalho, e não o engolirá; conforme ao poder de sua mudança, e não saltará de gozo.',
        'Porquanto oprimiu e desamparou os pobres, e roubou a casa que não edificou.',
        'Porquanto não sentiu sossego no seu ventre; nada salvará das coisas por ele desejadas.',
        'Nada lhe sobejará do que coma; por isso as suas riquezas não durarão.',
        'Sendo plena a sua abastança, estará angustiado; toda a força da miséria virá sobre ele.',
        'Mesmo estando ele a encher a sua barriga, Deus mandará sobre ele o ardor da sua ira, e a fará chover sobre ele quando for comer.',
        'Ainda que fuja das armas de ferro, o arco de bronze o atravessará.',
        'Desembainhará a espada que sairá do seu corpo, e resplandecendo virá do seu fel; e haverá sobre ele assombros.',
        'Toda a escuridão se ocultará nos seus esconderijos; um fogo não assoprado o consumirá, irá mal com o que ficar na sua tenda.',
        'Os céus manifestarão a sua iniqüidade; e a terra se levantará contra ele.',
        'As riquezas de sua casa serão transportadas; no dia da sua ira todas se derramarão.',
        'Esta, da parte de Deus, é a porção do homem ímpio; esta é a herança que Deus lhe decretou.',
      ],
      [
        'Respondeu, porém, Jó, dizendo:',
        'Ouvi atentamente as minhas razões; e isto vos sirva de consolação.',
        'Sofrei-me, e eu falarei; e havendo eu falado, zombai.',
        'Porventura eu me queixo de algum homem? Porém, ainda que assim fosse, por que não se angustiaria o meu espírito?',
        'Olhai para mim, e pasmai; e ponde a mão sobre a boca.',
        'Porque, quando me lembro disto me perturbo, e a minha carne é sobressaltada de horror.',
        'Por que razão vivem os ímpios, envelhecem, e ainda se robustecem em poder?',
        'A sua descendência se estabelece com eles perante a sua face; e os seus renovos perante os seus olhos.',
        'As suas casas têm paz, sem temor; e a vara de Deus não está sobre eles.',
        'O seu touro gera, e não falha; pare a sua vaca, e não aborta.',
        'Fazem sair as suas crianças, como a um rebanho, e seus filhos andam saltando.',
        'Levantam a voz, ao som do tamboril e da harpa, e alegram-se ao som do órgão.',
        'Na prosperidade gastam os seus dias, e num momento descem à sepultura.',
        'E, todavia, dizem a Deus: Retira-te de nós; porque não desejamos ter conhecimento dos teus caminhos.',
        'Quem é o Todo-Poderoso, para que nós o sirvamos? E que nos aproveitará que lhe façamos orações?',
        'Vede, porém, que a prosperidade não está nas mãos deles; esteja longe de mim o conselho dos ímpios!',
        'Quantas vezes sucede que se apaga a lâmpada dos ímpios, e lhes sobrevém a sua destruição? E Deus na sua ira lhes reparte dores!',
        'Porque são como a palha diante do vento, e como a pragana, que arrebata o redemoinho.',
        'Deus guarda a sua violência para seus filhos, e dá-lhe o pago, para que o conheça.',
        'Seus olhos verão a sua ruína, e ele beberá do furor do Todo-Poderoso.',
        'Por que, que prazer teria na sua casa, depois de morto, cortando-se-lhe o número dos seus meses?',
        'Porventura a Deus se ensinaria ciência, a ele que julga os excelsos?',
        'Um morre na força da sua plenitude, estando inteiramente sossegado e tranqüilo.',
        'Com seus baldes cheios de leite, e a medula dos seus ossos umedecida.',
        'E outro, ao contrário, morre na amargura do seu coração, não havendo provado do bem.',
        'Juntamente jazem no pó, e os vermes os cobrem.',
        'Eis que conheço bem os vossos pensamentos; e os maus intentos com que injustamente me fazeis violência.',
        'Porque direis: Onde está a casa do príncipe, e onde a tenda em que moravam os ímpios?',
        'Porventura não perguntastes aos que passam pelo caminho, e não conheceis os seus sinais,',
        'Que o mau é preservado para o dia da destruição; e arrebatado no dia do furor?',
        'Quem acusará diante dele o seu caminho, e quem lhe dará o pago do que faz?',
        'Finalmente é levado à sepultura, e vigiam-lhe o túmulo.',
        'Os torrões do vale lhe são doces, e o seguirão todos os homens; e adiante dele foram inumeráveis.',
        'Como, pois, me consolais com vaidade? Pois nas vossas respostas ainda resta a transgressão.',
      ],
      [
        'Então respondeu Elifaz, o temanita, dizendo:',
        'Porventura será o homem de algum proveito a Deus? Antes a si mesmo o prudente será proveitoso.',
        'Ou tem o Todo-Poderoso prazer em que tu sejas justo, ou algum lucro em que tu faças perfeitos os teus caminhos?',
        'Ou te repreende, pelo temor que tem de ti, ou entra contigo em juízo?',
        'Porventura não é grande a tua malícia, e sem termo as tuas iniqüidades?',
        'Porque sem causa penhoraste a teus irmãos, e aos nus despojaste as vestes.',
        'Não deste ao cansado água a beber, e ao faminto retiveste o pão.',
        'Mas para o poderoso era a terra, e o homem tido em respeito habitava nela.',
        'As viúvas despediste vazias, e os braços dos órfãos foram quebrados.',
        'Por isso é que estás cercado de laços, e te perturba um pavor repentino,',
        'Ou trevas em que nada vês, e a abundância de águas que te cobre.',
        'Porventura Deus não está na altura dos céus? Olha para a altura das estrelas; quão elevadas estão.',
        'E dizes: que sabe Deus? Porventura julgará ele através da escuridão?',
        'As nuvens são esconderijo para ele, para que não veja; e passeia pelo circuito dos céus.',
        'Porventura queres guardar a vereda antiga, que pisaram os homens iníquos?',
        'Eles foram arrebatados antes do seu tempo; sobre o seu fundamento um dilúvio se derramou.',
        'Diziam a Deus: Retira-te de nós. E: Que foi que o Todo-Poderoso nos fez?',
        'Contudo ele encheu de bens as suas casas; mas o conselho dos ímpios esteja longe de mim.',
        'Os justos o vêem, e se alegram, e o inocente escarnece deles.',
        'Porquanto o nosso adversário não foi destruído, mas o fogo consumiu o que restou deles.',
        'Apega-te, pois, a ele, e tem paz, e assim te sobrevirá o bem.',
        'Aceita, peço-te, a lei da sua boca, e põe as suas palavras no teu coração.',
        'Se te voltares ao Todo-Poderoso, serás edificado; se afastares a iniqüidade da tua tenda,',
        'E deitares o teu tesouro no pó, e o ouro de Ofir nas pedras dos ribeiros,',
        'Então o Todo-Poderoso será o teu tesouro, e a tua prata acumulada.',
        'Porque então te deleitarás no Todo-Poderoso, e levantarás o teu rosto para Deus.',
        'Orarás a ele, e ele te ouvirá, e pagarás os teus votos.',
        'Determinarás tu algum negócio, e ser-te-á firme, e a luz brilhará em teus caminhos.',
        'Quando te abaterem, então tu dirás: Haja exaltação! E Deus salvará ao humilde.',
        'E livrará até ao que não é inocente; porque será libertado pela pureza de tuas mãos.',
      ],
      [
        'Respondeu, porém, Jó, dizendo:',
        'Ainda hoje a minha queixa está em amargura; a minha mão pesa sobre meu gemido.',
        'Ah, se eu soubesse onde o poderia achar! Então me chegaria ao seu tribunal.',
        'Exporia ante ele a minha causa, e a minha boca encheria de argumentos.',
        'Saberia as palavras com que ele me responderia, e entenderia o que me dissesse.',
        'Porventura segundo a grandeza de seu poder contenderia comigo? Não: ele antes me atenderia.',
        'Ali o reto pleitearia com ele, e eu me livraria para sempre do meu Juiz.',
        'Eis que se me adianto, ali não está; se torno para trás, não o percebo.',
        'Se opera à esquerda, não o vejo; se se encobre à direita, não o diviso.',
        'Porém ele sabe o meu caminho; provando-me ele, sairei como o ouro.',
        'Nas suas pisadas os meus pés se afirmaram; guardei o seu caminho, e não me desviei dele.',
        'Do preceito de seus lábios nunca me apartei, e as palavras da sua boca guardei mais do que a minha porção.',
        'Mas, se ele resolveu alguma coisa, quem então o desviará? O que a sua alma quiser, isso fará.',
        'Porque cumprirá o que está ordenado a meu respeito, e muitas coisas como estas ainda tem consigo.',
        'Por isso me perturbo perante ele, e quando isto considero, temo-me dele.',
        'Porque Deus macerou o meu coração, e o Todo-Poderoso me perturbou.',
        'Porquanto não fui desarraigado por causa das trevas, e nem encobriu o meu rosto com a escuridão.',
      ],
      [
        'Visto que do Todo-Poderoso não se encobriram os tempos, por que, os que o conhecem, não vêem os seus dias?',
        'Até os limites removem; roubam os rebanhos, e os apascentam.',
        'Do órfão levam o jumento; tomam em penhor o boi da viúva.',
        'Desviam do caminho os necessitados; e os pobres da terra juntos se escondem.',
        'Eis que, como jumentos monteses no deserto, saem à sua obra, madrugando para a presa; a campina dá mantimento a eles e aos seus filhos.',
        'No campo segam o seu pasto, e vindimam a vinha do ímpio.',
        'Ao nu fazem passar a noite sem roupa, não tendo ele coberta contra o frio.',
        'Pelas chuvas das montanhas são molhados e, não tendo refúgio, abraçam-se com as rochas.',
        'Ao orfãozinho arrancam dos peitos, e tomam o penhor do pobre.',
        'Fazem com que os nus vão sem roupa e aos famintos tiram as espigas.',
        'Dentro das suas paredes espremem o azeite; pisam os lagares, e ainda têm sede.',
        'Desde as cidades gemem os homens, e a alma dos feridos exclama, e contudo Deus lho não imputa como loucura.',
        'Eles estão entre os que se opõem à luz; não conhecem os seus caminhos, e não permanecem nas suas veredas.',
        'De madrugada se levanta o homicida, mata o pobre e necessitado, e de noite é como o ladrão.',
        'Assim como o olho do adúltero aguarda o crepúsculo, dizendo: Não me verá olho nenhum; e oculta o rosto,',
        'Nas trevas minam as casas, que de dia se marcaram; não conhecem a luz.',
        'Porque a manhã para todos eles é como sombra de morte; pois, sendo conhecidos, sentem os pavores da sombra da morte.',
        'É ligeiro sobre a superfície das águas; maldita é a sua parte sobre a terra; não volta pelo caminho das vinhas.',
        'A secura e o calor desfazem as águas da neve; assim desfará a sepultura aos que pecaram.',
        'A madre se esquecerá dele, os vermes o comerão gostosamente; nunca mais haverá lembrança dele; e a iniqüidade se quebrará como uma árvore.',
        'Aflige à estéril que não dá à luz, e à viúva não faz bem.',
        'Até aos poderosos arrasta com a sua força; se ele se levanta, não há vida segura.',
        'Se Deus lhes dá descanso, estribam-se nisso; seus olhos porém estão nos caminhos deles.',
        'Por um pouco se exaltam, e logo desaparecem; são abatidos, encerrados como todos os demais; e cortados como as cabeças das espigas.',
        'Se agora não é assim, quem me desmentirá e desfará as minhas razões?',
      ],
      [
        'Então respondeu Bildade, o suíta, e disse:',
        'Com ele estão domínio e temor; ele faz paz nas suas alturas.',
        'Porventura têm número as suas tropas? E sobre quem não se levanta a sua luz?',
        'Como, pois, seria justo o homem para com Deus, e como seria puro aquele que nasce de mulher?',
        'Eis que até a lua não resplandece, e as estrelas não são puras aos seus olhos.',
        'E quanto menos o homem, que é um verme, e o filho do homem, que é um vermezinho!',
      ],
      [
        'Jó, porém, respondeu, dizendo:',
        'Como ajudaste aquele que não tinha força, e sustentaste o braço que não tinha vigor?',
        'Como aconselhaste aquele que não tinha sabedoria, e plenamente fizeste saber a causa, assim como era?',
        'A quem proferiste palavras, e de quem é o espírito que saiu de ti?',
        'Os mortos tremem debaixo das águas, com os seus moradores.',
        'O inferno está nu perante ele, e não há coberta para a perdição.',
        'O norte estende sobre o vazio; e suspende a terra sobre o nada.',
        'Prende as águas nas suas nuvens, todavia a nuvem não se rasga debaixo delas.',
        'Encobre a face do seu trono, e sobre ele estende a sua nuvem.',
        'Marcou um limite sobre a superfície das águas em redor, até aos confins da luz e das trevas.',
        'As colunas do céu tremem, e se espantam da sua ameaça.',
        'Com a sua força fende o mar, e com o seu entendimento abate a soberba.',
        'Pelo seu Espírito ornou os céus; a sua mão formou a serpente enroscadiça.',
        'Eis que isto são apenas as orlas dos seus caminhos; e quão pouco é o que temos ouvido dele! Quem, pois, entenderia o trovão do seu poder?',
      ],
      [
        'E prosseguindo Jó em seu discurso, disse:',
        'Vive Deus, que desviou a minha causa, e o Todo-Poderoso, que amargurou a minha alma.',
        'Que, enquanto em mim houver alento, e o sopro de Deus nas minhas narinas,',
        'Não falarão os meus lábios iniqüidade, nem a minha língua pronunciará engano.',
        'Longe de mim que eu vos justifique; até que eu expire, nunca apartarei de mim a minha integridade.',
        'À minha justiça me apegarei e não a largarei; não me reprovará o meu coração em toda a minha vida.',
        'Seja como o ímpio o meu inimigo, e como o perverso o que se levantar contra mim.',
        'Porque qual será a esperança do hipócrita, havendo sido avaro, quando Deus lhe arrancar a sua alma?',
        'Porventura Deus ouvirá o seu clamor, sobrevindo-lhe a tribulação?',
        'Deleitar-se-á no TodoPoderoso, ou invocará a Deus em todo o tempo?',
        'Ensinar-vos-ei acerca da mão de Deus, e não vos encobrirei o que está com o Todo-Poderoso.',
        'Eis que todos vós já o vistes; por que, pois, vos desvaneceis na vossa vaidade?',
        'Esta, pois, é a porção do homem ímpio da parte de Deus, e a herança, que os tiranos receberão do Todo-Poderoso.',
        'Se os seus filhos se multiplicarem, será para a espada, e a sua prole não se fartará de pão.',
        'Os que ficarem dele na morte serão enterrados, e as suas viúvas não chorarão.',
        'Se amontoar prata como pó, e aparelhar roupas como lodo,',
        'Ele as aparelhará, porém o justo as vestirá, e o inocente repartirá a prata.',
        'E edificará a sua casa como a traça, e como o guarda que faz a cabana.',
        'Rico se deita, e não será recolhido; abre os seus olhos, e nada terá.',
        'Pavores se apoderam dele como águas; de noite o arrebata a tempestade.',
        'O vento oriental leva-o, e ele se vai, e varre-o com ímpeto do seu lugar.',
        'E Deus lançará isto sobre ele, e não lhe poupará; irá fugindo da sua mão.',
        'Cada um baterá palmas contra ele e assobiará tirando-o do seu lugar.',
      ],
      [
        'Na verdade, há veios de onde se extrai a prata, e lugar onde se refina o ouro.',
        'O ferro tira-se da terra, e da pedra se funde o cobre.',
        'Ele põe fim às trevas, e toda a extremidade ele esquadrinha, a pedra da escuridão e a da sombra da morte.',
        'Abre um poço de mina longe dos homens, em lugares esquecidos do pé; ficando pendentes longe dos homens, oscilam de um lado para outro.',
        'Da terra procede o pão, mas por baixo é revolvida como por fogo.',
        'As suas pedras são o lugar da safira, e tem pó de ouro.',
        'Essa vereda a ave de rapina a ignora, e não a viram os olhos da gralha.',
        'Nunca a pisaram filhos de animais altivos, nem o feroz leão passou por ela.',
        'Ele estende a sua mão contra o rochedo, e revolve os montes desde as suas raízes.',
        'Dos rochedos faz sair rios, e o seu olho vê tudo o que há de precioso.',
        'Os rios tapa, e nem uma gota sai deles, e tira à luz o que estava escondido.',
        'Porém onde se achará a sabedoria, e onde está o lugar da inteligência?',
        'O homem não conhece o seu valor, e nem ela se acha na terra dos viventes.',
        'O abismo diz: Não está em mim; e o mar diz: Ela não está comigo.',
        'Não se dará por ela ouro fino, nem se pesará prata em troca dela.',
        'Nem se pode comprar por ouro fino de Ofir, nem pelo precioso ônix, nem pela safira.',
        'Com ela não se pode comparar o ouro nem o cristal; nem se trocará por jóia de ouro fino.',
        'Não se fará menção de coral nem de pérolas; porque o valor da sabedoria é melhor que o dos rubis.',
        'Não se lhe igualará o topázio da Etiópia, nem se pode avaliar por ouro puro.',
        'Donde, pois, vem a sabedoria, e onde está o lugar da inteligência?',
        'Pois está encoberta aos olhos de todo o vivente, e oculta às aves do céu.',
        'A perdição e a morte dizem: Ouvimos com os nossos ouvidos a sua fama.',
        'Deus entende o seu caminho, e ele sabe o seu lugar.',
        'Porque ele vê as extremidades da terra; e vê tudo o que há debaixo dos céus.',
        'Quando deu peso ao vento, e tomou a medida das águas;',
        'Quando prescreveu leis para a chuva e caminho para o relâmpago dos trovões;',
        'Então a viu e relatou; estabeleceu-a, e também a esquadrinhou.',
        'E disse ao homem: Eis que o temor do Senhor é a sabedoria, e apartar-se do mal é a inteligência.',
      ],
      [
        'E prosseguiu Jó no seu discurso, dizendo:',
        'Ah! quem me dera ser como eu fui nos meses passados, como nos dias em que Deus me guardava!',
        'Quando fazia resplandecer a sua lâmpada sobre a minha cabeça e quando eu pela sua luz caminhava pelas trevas.',
        'Como fui nos dias da minha mocidade, quando o segredo de Deus estava sobre a minha tenda;',
        'Quando o Todo-Poderoso ainda estava comigo, e os meus filhos em redor de mim.',
        'Quando lavava os meus passos na manteiga, e da rocha me corriam ribeiros de azeite;',
        'Quando eu saía para a porta da cidade, e na rua fazia preparar a minha cadeira,',
        'Os moços me viam, e se escondiam, e até os idosos se levantavam e se punham em pé;',
        'Os príncipes continham as suas palavras, e punham a mão sobre a sua boca;',
        'A voz dos nobres se calava, e a sua língua apegava-se ao seu paladar.',
        'Ouvindo-me algum ouvido, me tinha por bem-aventurado; vendo-me algum olho, dava testemunho de mim;',
        'Porque eu livrava o miserável, que clamava, como também o órfão que não tinha quem o socorresse.',
        'A bênção do que ia perecendo vinha sobre mim, e eu fazia que rejubilasse o coração da viúva.',
        'Vestia-me da justiça, e ela me servia de vestimenta; como manto e diadema era a minha justiça.',
        'Eu me fazia de olhos para o cego, e de pés para o coxo.',
        'Dos necessitados era pai, e as causas de que eu não tinha conhecimento inquiria com diligência.',
        'E quebrava os queixos do perverso, e dos seus dentes tirava a presa.',
        'E dizia: No meu ninho expirarei, e multiplicarei os meus dias como a areia.',
        'A minha raiz se estendia junto às águas, e o orvalho permanecia sobre os meus ramos;',
        'A minha honra se renovava em mim, e o meu arco se reforçava na minha mão.',
        'Ouviam-me e esperavam, e em silêncio atendiam ao meu conselho.',
        'Havendo eu falado, não replicavam, e minhas razões destilavam sobre eles;',
        'Porque me esperavam, como à chuva; e abriam a sua boca, como à chuva tardia.',
        'Se eu ria para eles, não o criam, e a luz do meu rosto não faziam abater;',
        'Eu escolhia o seu caminho, assentava-me como chefe, e habitava como rei entre as suas tropas; como aquele que consola os que pranteiam.',
      ],
      [
        'Agora, porém, se riem de mim os de menos idade do que eu, cujos pais eu teria desdenhado de pôr com os cães do meu rebanho.',
        'De que também me serviria a força das mãos daqueles, cujo vigor se tinha esgotado?',
        'De míngua e fome se debilitaram; e recolhiam-se para os lugares secos, tenebrosos, assolados e desertos.',
        'Apanhavam malvas junto aos arbustos, e o seu mantimento eram as raízes dos zimbros.',
        'Do meio dos homens eram expulsos, e gritavam contra eles, como contra o ladrão;',
        'Para habitarem nos barrancos dos vales, e nas cavernas da terra e das rochas.',
        'Bramavam entre os arbustos, e ajuntavam-se debaixo das urtigas.',
        'Eram filhos de doidos, e filhos de gente sem nome, e da terra foram expulsos.',
        'Agora, porém, sou a sua canção, e lhes sirvo de provérbio.',
        'Abominam-me, e fogem para longe de mim, e no meu rosto não se privam de cuspir.',
        'Porque Deus desatou a sua corda, e me oprimiu, por isso sacudiram de si o freio perante o meu rosto.',
        'À direita se levantam os moços; empurram os meus pés, e preparam contra mim os seus caminhos de destruição.',
        'Desbaratam-me o caminho; promovem a minha miséria; contra eles não há ajudador.',
        'Vêm contra mim como por uma grande brecha, e revolvem-se entre a assolação.',
        'Sobrevieram-me pavores; como vento perseguem a minha honra, e como nuvem passou a minha felicidade.',
        'E agora derrama-se em mim a minha alma; os dias da aflição se apoderaram de mim.',
        'De noite se me traspassam os meus ossos, e os meus nervos não descansam.',
        'Pela grandeza do meu mal está desfigurada a minha veste, que, como a gola da minha túnica, me cinge.',
        'Lançou-me na lama, e fiquei semelhante ao pó e à cinza.',
        'Clamo a ti, porém, tu não me respondes; estou em pé, porém, para mim não atentas.',
        'Tornaste-te cruel contra mim; com a força da tua mão resistes violentamente.',
        'Levantas-me sobre o vento, fazes-me cavalgar sobre ele, e derretes-me o ser.',
        'Porque eu sei que me levarás à morte e à casa do ajuntamento determinada a todos os viventes.',
        'Porém não estenderá a mão para o túmulo, ainda que eles clamem na sua destruição.',
        'Porventura não chorei sobre aquele que estava aflito, ou não se angustiou a minha alma pelo necessitado?',
        'Todavia aguardando eu o bem, então me veio o mal, esperando eu a luz, veio a escuridão.',
        'As minhas entranhas fervem e não estão quietas; os dias da aflição me surpreendem.',
        'Denegrido ando, porém não do sol; levantando-me na congregação, clamo por socorro.',
        'Irmão me fiz dos chacais, e companheiro dos avestruzes.',
        'Enegreceu-se a minha pele sobre mim, e os meus ossos estão queimados do calor.',
        'A minha harpa se tornou em luto, e o meu órgão em voz dos que choram.',
      ],
      [
        'Fiz aliança com os meus olhos; como, pois, os fixaria numa virgem?',
        'Que porção teria eu do Deus lá de cima, ou que herança do TodoPoderoso desde as alturas?',
        'Porventura não é a perdição para o perverso, o desastre para os que praticam iniqüidade?',
        'Ou não vê ele os meus caminhos, e não conta todos os meus passos?',
        'Se andei com falsidade, e se o meu pé se apressou para o engano',
        '(Pese-me em balanças fiéis, e saberá Deus a minha sinceridade),',
        'Se os meus passos se desviaram do caminho, e se o meu coração segue os meus olhos, e se às minhas mãos se apegou qualquer coisa,',
        'Então semeie eu e outro coma, e seja a minha descendência arrancada até à raiz.',
        'Se o meu coração se deixou seduzir por uma mulher, ou se eu armei traições à porta do meu próximo,',
        'Então moa minha mulher para outro, e outros se encurvem sobre ela,',
        'Porque é uma infâmia, e é delito pertencente aos juízes.',
        'Porque é fogo que consome até à perdição, e desarraigaria toda a minha renda.',
        'Se desprezei o direito do meu servo ou da minha serva, quando eles contendiam comigo;',
        'Então que faria eu quando Deus se levantasse? E, inquirindo a causa, que lhe responderia?',
        'Aquele que me formou no ventre não o fez também a ele? Ou não nos formou do mesmo modo na madre?',
        'Se retive o que os pobres desejavam, ou fiz desfalecer os olhos da viúva,',
        'Ou se, sozinho comi o meu bocado, e o órfão não comeu dele',
        '(Porque desde a minha mocidade cresceu comigo como com seu pai, e fui o guia da viúva desde o ventre de minha mãe),',
        'Se alguém vi perecer por falta de roupa, e ao necessitado por não ter coberta,',
        'Se os seus lombos não me abençoaram, se ele não se aquentava com as peles dos meus cordeiros,',
        'Se eu levantei a minha mão contra o órfão, porquanto na porta via a minha ajuda,',
        'Então caia do ombro a minha espádua, e separe-se o meu braço do osso.',
        'Porque o castigo de Deus era para mim um assombro, e eu não podia suportar a sua grandeza.',
        'Se no ouro pus a minha esperança, ou disse ao ouro fino: Tu és a minha confiança;',
        'Se me alegrei de que era muita a minha riqueza, e de que a minha mão tinha alcançado muito;',
        'Se olhei para o sol, quando resplandecia, ou para a lua, caminhando gloriosa,',
        'E o meu coração se deixou enganar em oculto, e a minha boca beijou a minha mão,',
        'Também isto seria delito à punição de juízes; pois assim negaria a Deus que está lá em cima.',
        'Se me alegrei da desgraça do que me tem ódio, e se exultei quando o mal o atingiu',
        '(Também não deixei pecar a minha boca, desejando a sua morte com maldição);',
        'Se a gente da minha tenda não disse: Ah! quem nos dará da sua carne? Nunca nos fartaríamos dela.',
        'O estrangeiro não passava a noite na rua; as minhas portas abria ao viandante.',
        'Se, como Adão, encobri as minhas transgressões, ocultando o meu delito no meu seio;',
        'Porque eu temia a grande multidão, e o desprezo das famílias me apavorava, e eu me calei, e não saí da porta;',
        'Ah! quem me dera um que me ouvisse! Eis que o meu desejo é que o Todo-Poderoso me responda, e que o meu adversário escreva um livro.',
        'Por certo que o levaria sobre o meu ombro, sobre mim o ataria por coroa.',
        'O número dos meus passos lhe mostraria; como príncipe me chegaria a ele.',
        'Se a minha terra clamar contra mim, e se os seus sulcos juntamente chorarem,',
        'Se comi os seus frutos sem dinheiro, e sufoquei a alma dos seus donos,',
        'Por trigo me produza cardos, e por cevada joio. Acabaram-se as palavras de Jó.',
      ],
      [
        'Então aqueles três homens cessaram de responder a Jó; porque era justo aos seus próprios olhos.',
        'E acendeu-se a ira de Eliú, filho de Baraquel, o buzita, da família de Rão; contra Jó se acendeu a sua ira, porque se justificava a si mesmo, mais do que a Deus.',
        'Também a sua ira se acendeu contra os seus três amigos, porque, não achando que responder, todavia condenavam a Jó.',
        'Eliú, porém, esperou para falar a Jó, porquanto tinham mais idade do que ele.',
        'Vendo, pois, Eliú que já não havia resposta na boca daqueles três homens, a sua ira se acendeu.',
        'E respondeu Eliú, filho de Baraquel, o buzita, dizendo: Eu sou de menos idade, e vós sois idosos; receei-me e temi de vos declarar a minha opinião.',
        'Dizia eu: Falem os dias, e a multidão dos anos ensine a sabedoria.',
        'Na verdade, há um espírito no homem, e a inspiração do TodoPoderoso o faz entendido.',
        'Os grandes não são os sábios, nem os velhos entendem o que é direito.',
        'Assim digo: Dai-me ouvidos, e também eu declararei a minha opinião.',
        'Eis que aguardei as vossas palavras, e dei ouvidos às vossas considerações, até que buscásseis razões.',
        'Atentando, pois, para vós, eis que nenhum de vós há que possa convencer a Jó, nem que responda às suas razões;',
        'Para que não digais: Achamos a sabedoria; Deus o derrubou, e não homem algum.',
        'Ora ele não dirigiu contra mim palavra alguma, nem lhe responderei com as vossas palavras.',
        'Estão pasmados, não respondem mais, faltam-lhes as palavras.',
        'Esperei, pois, mas não falam; porque já pararam, e não respondem mais.',
        'Também eu responderei pela minha parte; também eu declararei a minha opinião.',
        'Porque estou cheio de palavras; o meu espírito me constrange.',
        'Eis que dentro de mim sou como o mosto, sem respiradouro, prestes a arrebentar, como odres novos.',
        'Falarei, para que ache alívio; abrirei os meus lábios, e responderei.',
        'Que não faça eu acepção de pessoas, nem use de palavras lisonjeiras com o homem!',
        'Porque não sei usar de lisonjas; em breve me levaria o meu Criador.',
      ],
      [
        'Assim, na verdade, ó Jó, ouve as minhas razões, e dá ouvidos a todas as minhas palavras.',
        'Eis que já abri a minha boca; já falou a minha língua debaixo do meu paladar.',
        'As minhas razões provam a sinceridade do meu coração, e os meus lábios proferem o puro saber.',
        'O Espírito de Deus me fez; e a inspiração do Todo-Poderoso me deu vida.',
        'Se podes, responde-me, põe em ordem as tuas razões diante de mim, e apresenta-te.',
        'Eis que vim de Deus, como tu; do barro também eu fui formado.',
        'Eis que não te perturbará o meu terror, nem será pesada sobre ti a minha mão.',
        'Na verdade tu falaste aos meus ouvidos; e eu ouvi a voz das tuas palavras. Dizias:',
        'Limpo estou, sem transgressão; puro sou, e não tenho iniqüidade.',
        'Eis que procura pretexto contra mim, e me considera como seu inimigo.',
        'Põe no tronco os meus pés, e observa todas as minhas veredas.',
        'Eis que nisso não tens razão; eu te respondo; porque maior é Deus do que o homem.',
        'Por que razão contendes com ele, sendo que não responde acerca de todos os seus feitos?',
        'Antes Deus fala uma e duas vezes; porém ninguém atenta para isso.',
        'Em sonho ou em visão noturna, quando cai sono profundo sobre os homens, e adormecem na cama.',
        'Então o revela ao ouvido dos homens, e lhes sela a sua instrução,',
        'Para apartar o homem daquilo que faz, e esconder do homem a soberba.',
        'Para desviar a sua alma da cova, e a sua vida de passar pela espada.',
        'Também na sua cama é castigado com dores; e com incessante contenda nos seus ossos;',
        'De modo que a sua vida abomina até o pão, e a sua alma a comida apetecível.',
        'Desaparece a sua carne a olhos vistos, e os seus ossos, que não se viam, agora aparecem.',
        'E a sua alma se vai chegando à cova, e a sua vida aos que trazem a morte.',
        'Se com ele, pois, houver um mensageiro, um intérprete, um entre milhares, para declarar ao homem a sua retidão,',
        'Então terá misericórdia dele, e lhe dirá: Livra-o, para que não desça à cova; já achei resgate.',
        'Sua carne se reverdecerá mais do que era na mocidade, e tornará aos dias da sua juventude.',
        'Deveras orará a Deus, o qual se agradará dele, e verá a sua face com júbilo, e restituirá ao homem a sua justiça.',
        'Olhará para os homens, e dirá: Pequei, e perverti o direito, o que de nada me aproveitou.',
        'Porém Deus livrou a minha alma de ir para a cova, e a minha vida verá a luz.',
        'Eis que tudo isto é obra de Deus, duas e três vezes para com o homem,',
        'Para desviar a sua alma da perdição, e o iluminar com a luz dos viventes.',
        'Escuta, pois, ó Jó, ouve-me; cala-te, e eu falarei.',
        'Se tens alguma coisa que dizer, responde-me; fala, porque desejo justificar-te.',
        'Se não, escuta-me tu; cala-te, e ensinar-te-ei a sabedoria.',
      ],
      [
        'Respondeu mais Eliú, dizendo:',
        'Ouvi, vós, sábios, as minhas razões; e vós, entendidos, inclinai os ouvidos para mim.',
        'Porque o ouvido prova as palavras, como o paladar experimenta a comida.',
        'O que é direito escolhamos para nós; e conheçamos entre nós o que é bom.',
        'Porque Jó disse: Sou justo, e Deus tirou o meu direito.',
        'Apesar do meu direito sou considerado mentiroso; a minha ferida é incurável, embora eu esteja sem transgressão.',
        'Que homem há como Jó, que bebe a zombaria como água?',
        'E caminha em companhia dos que praticam a iniqüidade, e anda com homens ímpios?',
        'Porque disse: De nada aproveita ao homem o comprazer-se em Deus.',
        'Portanto vós, homens de entendimento, escutai-me: Longe de Deus esteja o praticar a maldade e do Todo-Poderoso o cometer a perversidade!',
        'Porque, segundo a obra do homem, ele lhe paga; e faz a cada um segundo o seu caminho.',
        'Também, na verdade, Deus não procede impiamente; nem o Todo-Poderoso perverte o juízo.',
        'Quem lhe entregou o governo da terra? E quem fez todo o mundo?',
        'Se ele pusesse o seu coração contra o homem, e recolhesse para si o seu espírito e o seu fôlego,',
        'Toda a carne juntamente expiraria, e o homem voltaria para o pó.',
        'Se, pois, há em ti entendimento, ouve isto; inclina os ouvidos ao som da minha palavra.',
        'Porventura o que odiasse o direito se firmaria? E tu condenarias aquele que é justo e poderoso?',
        'Ou dir-se-á a um rei: Oh! Vil? Ou aos príncipes: Oh! ímpios?',
        'Quanto menos àquele, que não faz acepção das pessoas de príncipes, nem estima o rico mais do que o pobre; porque todos são obras de suas mãos.',
        'Eles num momento morrem; e até à meia-noite os povos são perturbados, e passam, e os poderosos serão tomados não por mão humana.',
        'Porque os seus olhos estão sobre os caminhos de cada um, e ele vê todos os seus passos.',
        'Não há trevas nem sombra de morte, onde se escondam os que praticam a iniqüidade.',
        'Porque Deus não sobrecarrega o homem mais do que é justo, para o fazer ir a juízo diante dele.',
        'Quebranta aos fortes, sem que se possa inquirir, e põe outros em seu lugar.',
        'Ele conhece, pois, as suas obras; de noite os transtorna, e ficam moídos.',
        'Ele os bate como ímpios que são, à vista dos espectadores;',
        'Porquanto se desviaram dele, e não compreenderam nenhum de seus caminhos,',
        'De sorte que o clamor do pobre subisse até ele, e que ouvisse o clamor dos aflitos.',
        'Se ele aquietar, quem então inquietará? Se encobrir o rosto, quem então o poderá contemplar? Seja isto para com um povo, seja para com um homem só,',
        'Para que o homem hipócrita nunca mais reine, e não haja laços no povo.',
        'Na verdade, quem a Deus disse: Suportei castigo, não ofenderei mais.',
        'O que não vejo, ensina-me tu; se fiz alguma maldade, nunca mais a hei de fazer?',
        'Virá de ti como há de ser a recompensa, para que tu a rejeites? Faze tu, pois, e não eu, a escolha; fala logo o que sabes.',
        'Os homens de entendimento dirão comigo, e o homem sábio que me ouvir:',
        'Jó falou sem conhecimento; e às suas palavras falta prudência.',
        'Pai meu! Provado seja Jó até ao fim, pelas suas respostas próprias de homens malignos.',
        'Porque ao seu pecado acrescenta a transgressão; entre nós bate palmas, e multiplica contra Deus as suas palavras.',
      ],
      [
        'Respondeu mais Eliú, dizendo:',
        'Tens por direito dizeres: Maior é a minha justiça do que a de Deus?',
        'Porque disseste: De que me serviria? Que proveito tiraria mais do que do meu pecado?',
        'Eu te darei resposta, a ti e aos teus amigos contigo.',
        'Atenta para os céus, e vê; e contempla as mais altas nuvens, que são mais altas do que tu.',
        'Se pecares, que efetuarás contra ele? Se as tuas transgressões se multiplicarem, que lhe farás?',
        'Se fores justo, que lhe darás, ou que receberá ele da tua mão?',
        'A tua impiedade faria mal a outro tal como tu; e a tua justiça aproveitaria ao filho do homem.',
        'Por causa das muitas opressões os homens clamam por causa do braço dos grandes.',
        'Porém ninguém diz: Onde está Deus que me criou, que dá salmos durante a noite;',
        'Que nos ensina mais do que aos animais da terra e nos faz mais sábios do que as aves dos céus?',
        'Clamam, porém ele não responde, por causa da arrogância dos maus.',
        'Certo é que Deus não ouvirá a vaidade, nem atentará para ela o Todo-Poderoso.',
        'E quanto ao que disseste, que o não verás, juízo há perante ele; por isso espera nele.',
        'Mas agora, porque a sua ira ainda não se exerce, nem grandemente considera a arrogância,',
        'Logo Jó em vão abre a sua boca, e sem ciência multiplica palavras.',
      ],
      [
        'Prosseguiu ainda Eliú, e disse:',
        'Espera-me um pouco, e mostrar-te-ei que ainda há razões a favor de Deus.',
        'De longe trarei o meu conhecimento; e ao meu Criador atribuirei a justiça.',
        'Porque na verdade, as minhas palavras não serão falsas; contigo está um que tem perfeito conhecimento.',
        'Eis que Deus é mui grande, contudo a ninguém despreza; grande é em força e sabedoria.',
        'Ele não preserva a vida do ímpio, e faz justiça aos aflitos.',
        'Do justo não tira os seus olhos; antes estão com os reis no trono; ali os assenta para sempre, e assim são exaltados.',
        'E se estão presos em grilhões, amarrados com cordas de aflição,',
        'Então lhes faz saber a obra deles, e as suas transgressões, porquanto prevaleceram nelas.',
        'Abre-lhes também os seus ouvidos, para sua disciplina, e ordena-lhes que se convertam da maldade.',
        'Se o ouvirem, e o servirem, acabarão seus dias em bem, e os seus anos em delícias.',
        'Porém se não o ouvirem, à espada serão passados, e expirarão sem conhecimento.',
        'E os hipócritas de coração amontoam para si a ira; e amarrando-os ele, não clamam por socorro.',
        'A sua alma morre na mocidade, e a sua vida perece entre os impuros.',
        'Ao aflito livra da sua aflição, e na opressão se revela aos seus ouvidos.',
        'Assim também te desviará da boca da angústia para um lugar espaçoso, em que não há aperto, e as iguarias da tua mesa serão cheias de gordura.',
        'Mas tu estás cheio do juízo do ímpio; o juízo e a justiça te sustentam.',
        'Porquanto há furor, guarda-te de que não sejas atingido pelo castigo violento, pois nem com resgate algum te livrarias dele.',
        'Estimaria ele tanto tuas riquezas? Não, nem ouro, nem todas as forças do poder.',
        'Não suspires pela noite, em que os povos sejam tomados do seu lugar.',
        'Guarda-te, e não declines para a iniqüidade; porquanto isso escolheste antes que a aflição.',
        'Eis que Deus é excelso em seu poder; quem ensina como ele?',
        'Quem lhe prescreveu o seu caminho? Ou, quem lhe dirá: Tu cometeste maldade?',
        'Lembra-te de engrandecer a sua obra, que os homens contemplam.',
        'Todos os homens a vêem, e o homem a enxerga de longe.',
        'Eis que Deus é grande, e nós não o compreendemos, e o número dos seus anos não se pode esquadrinhar.',
        'Porque faz miúdas as gotas das águas que, do seu vapor, derramam a chuva,',
        'A qual as nuvens destilam e gotejam sobre o homem abundantemente.',
        'Porventura pode alguém entender as extensões das nuvens, e os estalos da sua tenda?',
        'Eis que estende sobre elas a sua luz, e encobre as profundezas do mar.',
        'Porque por estas coisas julga os povos e lhes dá mantimento em abundância.',
        'Com as nuvens encobre a luz, e ordena não brilhar, interpondo a nuvem.',
        'O que nos dá a entender o seu pensamento, como também ao gado, acerca do temporal que sobe.',
      ],
      [
        'Sobre isto também treme o meu coração, e salta do seu lugar.',
        'Atentamente ouvi a indignação da sua voz, e o sonido que sai da sua boca.',
        'Ele o envia por debaixo de todos os céus, e a sua luz até aos confins da terra.',
        'Depois disto ruge uma voz; ele troveja com a sua voz majestosa; e ele não os detém quando a sua voz é ouvida.',
        'Com a sua voz troveja Deus maravilhosamente; faz grandes coisas, que nós não podemos compreender.',
        'Porque à neve diz: Cai sobre a terra; como também à garoa e à sua forte chuva.',
        'Ele sela as mãos de todo o homem, para que conheçam todos os homens a sua obra.',
        'E as feras entram nos seus esconderijos e ficam nas suas cavernas.',
        'Da recâmara do sul sai o tufão, e do norte o frio.',
        'Pelo sopro de Deus se dá a geada, e as largas águas se congelam.',
        'Também de umidade carrega as grossas nuvens, e esparge as nuvens com a sua luz.',
        'Então elas, segundo o seu prudente conselho, se espalham em redor, para que façam tudo quanto lhes ordena sobre a superfície do mundo na terra.',
        'Seja que por vara, ou para a sua terra, ou por misericórdia as faz vir.',
        'A isto, ó Jó, inclina os teus ouvidos; para, e considera as maravilhas de Deus.',
        'Porventura sabes tu como Deus as opera, e faz resplandecer a luz da sua nuvem?',
        'Tens tu notícia do equilíbrio das grossas nuvens e das maravilhas daquele que é perfeito nos conhecimentos?',
        'Ou de como as tuas roupas aquecem, quando do sul há calma sobre a terra?',
        'Ou estendeste com ele os céus, que estão firmes como espelho fundido?',
        'Ensina-nos o que lhe diremos: porque nós nada poderemos pôr em boa ordem, por causa das trevas.',
        'Contar-lhe-ia alguém o que tenho falado? Ou desejaria um homem que ele fosse devorado?',
        'E agora não se pode olhar para o sol, que resplandece nas nuvens, quando o vento, tendo passado, o deixa limpo.',
        'O esplendor de ouro vem do norte; pois, em Deus há uma tremenda majestade.',
        'Ao Todo-Poderoso não podemos alcançar; grande é em poder; porém a ninguém oprime em juízo e grandeza de justiça.',
        'Por isso o temem os homens; ele não respeita os que se julgam sábios de coração.',
      ],
      [
        'Depois disto o SENHOR respondeu a Jó de um redemoinho, dizendo:',
        'Quem é este que escurece o conselho com palavras sem conhecimento?',
        'Agora cinge os teus lombos, como homem; e perguntar-te-ei, e tu me ensinarás.',
        'Onde estavas tu, quando eu fundava a terra? Faze-mo saber, se tens inteligência.',
        'Quem lhe pôs as medidas, se é que o sabes? Ou quem estendeu sobre ela o cordel?',
        'Sobre que estão fundadas as suas bases, ou quem assentou a sua pedra de esquina,',
        'Quando as estrelas da alva juntas alegremente cantavam, e todos os filhos de Deus jubilavam?',
        'Ou quem encerrou o mar com portas, quando este rompeu e saiu da madre;',
        'Quando eu pus as nuvens por sua vestidura, e a escuridão por faixa?',
        'Quando eu lhe tracei limites, e lhe pus portas e ferrolhos,',
        'E disse: Até aqui virás, e não mais adiante, e aqui se parará o orgulho das tuas ondas?',
        'Ou desde os teus dias deste ordem à madrugada, ou mostraste à alva o seu lugar;',
        'Para que pegasse nas extremidades da terra, e os ímpios fossem sacudidos dela;',
        'E se transformasse como o barro sob o selo, e se pusessem como vestidos;',
        'E dos ímpios se desvie a sua luz, e o braço altivo se quebrante;',
        'Ou entraste tu até às origens do mar, ou passeaste no mais profundo do abismo?',
        'Ou descobriram-se-te as portas da morte, ou viste as portas da sombra da morte?',
        'Ou com o teu entendimento chegaste às larguras da terra? Faze-mo saber, se sabes tudo isto.',
        'Onde está o caminho onde mora a luz? E, quanto às trevas, onde está o seu lugar;',
        'Para que as tragas aos seus limites, e para que saibas as veredas da sua casa?',
        'De certo tu o sabes, porque já então eras nascido, e por ser grande o número dos teus dias!',
        'Ou entraste tu até aos tesouros da neve, e viste os tesouros da saraiva,',
        'Que eu retenho até ao tempo da angústia, até ao dia da peleja e da guerra?',
        'Onde está o caminho em que se reparte a luz, e se espalha o vento oriental sobre a terra?',
        'Quem abriu para a inundação um leito, e um caminho para os relâmpagos dos trovões,',
        'Para chover sobre a terra, onde não há ninguém, e no deserto, em que não há homem;',
        'Para fartar a terra deserta e assolada, e para fazer crescer os renovos da erva?',
        'A chuva porventura tem pai? Ou quem gerou as gotas do orvalho?',
        'De que ventre procedeu o gelo? E quem gerou a geada do céu?',
        'Como debaixo de pedra as águas se endurecem, e a superfície do abismo se congela.',
        'Ou poderás tu ajuntar as delícias do Sete-estrelo ou soltar os cordéis do Órion?',
        'Ou produzir as constelações a seu tempo, e guiar a Ursa com seus filhos?',
        'Sabes tu as ordenanças dos céus, ou podes estabelecer o domínio deles sobre a terra?',
        'Ou podes levantar a tua voz até às nuvens, para que a abundância das águas te cubra?',
        'Ou mandarás aos raios para que saiam, e te digam: Eis-nos aqui?',
        'Quem pôs a sabedoria no íntimo, ou quem deu à mente o entendimento?',
        'Quem numerará as nuvens com sabedoria? Ou os odres dos céus, quem os esvaziará,',
        'Quando se funde o pó numa massa, e se apegam os torrões uns aos outros?',
        'Porventura caçarás tu presa para a leoa, ou saciarás a fome dos filhos dos leões,',
        'Quando se agacham nos covis, e estão à espreita nas covas?',
        'Quem prepara aos corvos o seu alimento, quando os seus filhotes gritam a Deus e andam vagueando, por não terem o que comer?',
      ],
      [
        'Sabes tu o tempo em que as cabras montesas têm filhos, ou observastes as cervas quando dão suas crias?',
        'Contarás os meses que cumprem, ou sabes o tempo do seu parto?',
        'Quando se encurvam, produzem seus filhos, e lançam de si as suas dores.',
        'Seus filhos enrijam, crescem com o trigo; saem, e nunca mais tornam para elas.',
        'Quem despediu livre o jumento montês, e quem soltou as prisões ao jumento bravo,',
        'Ao qual dei o ermo por casa, e a terra salgada por morada?',
        'Ri-se do ruído da cidade; não ouve os muitos gritos do condutor.',
        'A região montanhosa é o seu pasto, e anda buscando tudo que está verde.',
        'Ou, querer-te-á servir o boi selvagem? Ou ficará no teu curral?',
        'Ou com corda amarrarás, no arado, ao boi selvagem? Ou escavará ele os vales após ti?',
        'Ou confiarás nele, por ser grande a sua força, ou deixarás a seu cargo o teu trabalho?',
        'Ou fiarás dele que te torne o que semeaste e o recolha na tua eira?',
        'A avestruz bate alegremente as suas asas, porém, são benignas as suas asas e penas?',
        'Ela deixa os seus ovos na terra, e os aquenta no pó,',
        'E se esquece de que algum pé os pode pisar, ou que os animais do campo os podem calcar.',
        'Endurece-se para com seus filhos, como se não fossem seus; debalde é seu trabalho, mas ela está sem temor,',
        'Porque Deus a privou de sabedoria, e não lhe deu entendimento.',
        'A seu tempo se levanta ao alto; ri-se do cavalo, e do que vai montado nele.',
        'Ou darás tu força ao cavalo, ou revestirás o seu pescoço com crinas?',
        'Ou espantá-lo-ás, como ao gafanhoto? Terrível é o fogoso respirar das suas ventas.',
        'Escarva a terra, e folga na sua força, e sai ao encontro dos armados.',
        'Ri-se do temor, e não se espanta, e não torna atrás por causa da espada.',
        'Contra ele rangem a aljava, o ferro flamante da lança e do dardo.',
        'Agitando-se e indignando-se, serve a terra, e não faz caso do som da buzina.',
        'Ao soar das buzinas diz: Eia! E cheira de longe a guerra, e o trovão dos capitàes, e o alarido.',
        'Ou voa o gavião pela tua inteligência, e estende as suas asas para o sul?',
        'Ou se remonta a águia ao teu mandado, e põe no alto o seu ninho?',
        'Nas penhas mora e habita; no cume das penhas, e nos lugares seguros.',
        'Dali descobre a presa; seus olhos a avistam de longe.',
        'E seus filhos chupam o sangue, e onde há mortos, ali está ela.',
      ],
      [
        'Respondeu mais o SENHOR a Jó, dizendo:',
        'Porventura o contender contra o Todo-Poderoso é sabedoria? Quem argüi assim a Deus, responda por isso.',
        'Então Jó respondeu ao Senhor, dizendo:',
        'Eis que sou vil; que te responderia eu? A minha mão ponho à boca.',
        'Uma vez tenho falado, e não replicarei; ou ainda duas vezes, porém não prosseguirei.',
        'Então o Senhor respondeu a Jó de um redemoinho, dizendo:',
        'Cinge agora os teus lombos como homem; eu te perguntarei, e tu me explicarás.',
        'Porventura também tornarás tu vão o meu juízo, ou tu me condenarás, para te justificares?',
        'Ou tens braço como Deus, ou podes trovejar com voz como ele o faz?',
        'Orna-te, pois, de excelência e alteza; e veste-te de majestade e de glória.',
        'Derrama os furores da tua ira, e atenta para todo o soberbo, e abate-o.',
        'Olha para todo o soberbo, e humilha-o, e atropela os ímpios no seu lugar.',
        'Esconde-os juntamente no pó; ata-lhes os rostos em oculto.',
        'Então também eu a ti confessarei que a tua mão direita te poderá salvar.',
        'Contemplas agora o beemote, que eu fiz contigo, que come a erva como o boi.',
        'Eis que a sua força está nos seus lombos, e o seu poder nos músculos do seu ventre.',
        'Quando quer, move a sua cauda como cedro; os nervos das suas coxas estão entretecidos.',
        'Os seus ossos são como tubos de bronze; a sua ossada é como barras de ferro.',
        'Ele é obra-prima dos caminhos de Deus; o que o fez o proveu da sua espada.',
        'Em verdade os montes lhe produzem pastos, onde todos os animais do campo folgam.',
        'Deita-se debaixo das árvores sombrias, no esconderijo das canas e da lama.',
        'As árvores sombrias o cobrem, com sua sombra; os salgueiros do ribeiro o cercam.',
        'Eis que um rio transborda, e ele não se apressa, confiando ainda que o Jordão se levante até à sua boca.',
        'Podê-lo-iam porventura caçar à vista de seus olhos, ou com laços lhe furar o nariz?',
      ],
      [
        'Poderás tirar com anzol o leviatã, ou ligarás a sua língua com uma corda?',
        'Podes pôr um anzol no seu nariz, ou com um gancho furar a sua queixada?',
        'Porventura multiplicará as súplicas para contigo, ou brandamente falará?',
        'Fará ele aliança contigo, ou o tomarás tu por servo para sempre?',
        'Brincarás com ele, como se fora um passarinho, ou o prenderás para tuas meninas?',
        'Os teus companheiros farão dele um banquete, ou o repartirão entre os negociantes?',
        'Encherás a sua pele de ganchos, ou a sua cabeça com arpões de pescadores?',
        'Põe a tua mão sobre ele, lembra-te da peleja, e nunca mais tal intentarás.',
        'Eis que é vã a esperança de apanhá-lo; pois não será o homem derrubado só ao vê-lo?',
        'Ninguém há tão atrevido, que a despertá-lo se atreva; quem, pois, é aquele que ousa erguer-se diante de mim?',
        'Quem primeiro me deu, para que eu haja de retribuir-lhe? Pois o que está debaixo de todos os céus é meu.',
        'Não me calarei a respeito dos seus membros, nem da sua grande força, nem a graça da sua compostura.',
        'Quem descobrirá a face da sua roupa? Quem entrará na sua couraça dobrada?',
        'Quem abrirá as portas do seu rosto? Pois ao redor dos seus dentes está o terror.',
        'As suas fortes escamas são o seu orgulho, cada uma fechada como com selo apertado.',
        'Uma à outra se chega tão perto, que nem o ar passa por entre elas.',
        'Umas às outras se ligam; tanto aderem entre si, que não se podem separar.',
        'Cada um dos seus espirros faz resplandecer a luz, e os seus olhos são como as pálpebras da alva.',
        'Da sua boca saem tochas; faíscas de fogo saltam dela.',
        'Das suas narinas procede fumaça, como de uma panela fervente, ou de uma grande caldeira.',
        'O seu hálito faz incender os carvões; e da sua boca sai chama.',
        'No seu pescoço reside a força; diante dele até a tristeza salta de prazer.',
        'Os músculos da sua carne estão pegados entre si; cada um está firme nele, e nenhum se move.',
        'O seu coração é firme como uma pedra e firme como a mó de baixo.',
        'Levantando-se ele, tremem os valentes; em razão dos seus abalos se purificam.',
        'Se alguém lhe tocar com a espada, essa não poderá penetrar, nem lança, dardo ou flecha.',
        'Ele considera o ferro como palha, e o cobre como pau podre.',
        'A seta o não fará fugir; as pedras das fundas se lhe tornam em restolho.',
        'As pedras atiradas são para ele como arestas, e ri-se do brandir da lança;',
        'Debaixo de si tem conchas pontiagudas; estende-se sobre coisas pontiagudas como na lama.',
        'As profundezas faz ferver, como uma panela; torna o mar como uma vasilha de ungüento.',
        'Após si deixa uma vereda luminosa; parece o abismo tornado em brancura de cãs.',
        'Na terra não há coisa que se lhe possa comparar, pois foi feito para estar sem pavor.',
        'Ele vê tudo que é alto; é rei sobre todos os filhos da soberba.',
      ],
      [
        'Então respondeu Jó ao SENHOR, dizendo:',
        'Bem sei eu que tudo podes, e que nenhum dos teus propósitos pode ser impedido.',
        'Quem é este, que sem conhecimento encobre o conselho? Por isso relatei o que não entendia; coisas que para mim eram inescrutáveis, e que eu não entendia.',
        'Escuta-me, pois, e eu falarei; eu te perguntarei, e tu me ensinarás.',
        'Com o ouvir dos meus ouvidos ouvi, mas agora te vêem os meus olhos.',
        'Por isso me abomino e me arrependo no pó e na cinza.',
        'Sucedeu que, acabando o Senhor de falar a Jó aquelas palavras, o Senhor disse a Elifaz, o temanita: A minha ira se acendeu contra ti, e contra os teus dois amigos, porque não falastes de mim o que era reto, como o meu servo Jó.',
        'Tomai, pois, sete bezerros e sete carneiros, e ide ao meu servo Jó, e oferecei holocaustos por vós, e o meu servo Jó orará por vós; porque deveras a ele aceitarei, para que eu não vos trate conforme a vossa loucura; porque vós não falastes de mim o que era reto como o meu servo Jó.',
        'Então foram Elifaz, o temanita, e Bildade, o suíta, e Zofar, o naamatita, e fizeram como o Senhor lhes dissera; e o Senhor aceitou a face de Jó.',
        'E o Senhor virou o cativeiro de Jó, quando orava pelos seus amigos; e o Senhor acrescentou, em dobro, a tudo quanto Jó antes possuía.',
        'Então vieram a ele todos os seus irmãos, e todas as suas irmãs, e todos quantos dantes o conheceram, e comeram com ele pão em sua casa, e se condoeram dele, e o consolaram acerca de todo o mal que o Senhor lhe havia enviado; e cada um deles lhe deu uma peça de dinheiro, e um pendente de ouro.',
        'E assim abençoou o Senhor o último estado de Jó, mais do que o primeiro; pois teve catorze mil ovelhas, e seis mil camelos, e mil juntas de bois, e mil jumentas.',
        'Também teve sete filhos e três filhas.',
        'E chamou o nome da primeira Jemima, e o nome da segunda Quezia, e o nome da terceira Quéren-Hapuque.',
        'E em toda a terra não se acharam mulheres tão formosas como as filhas de Jó; e seu pai lhes deu herança entre seus irmãos.',
        'E depois disto viveu Jó cento e quarenta anos; e viu a seus filhos, e aos filhos de seus filhos, até à quarta geração.',
        'Então morreu Jó, velho e farto de dias.',
      ],
    ],
    livro: 'jo',
  },
  {
    abbrev: 'sl',
    capitulos: [
      [
        'Bem-aventurado o homem que não anda segundo o conselho dos ímpios, nem se detém no caminho dos pecadores, nem se assenta na roda dos escarnecedores.',
        'Antes tem o seu prazer na lei do Senhor, e na sua lei medita de dia e de noite.',
        'Pois será como a árvore plantada junto a ribeiros de águas, a qual dá o seu fruto no seu tempo; as suas folhas não cairão, e tudo quanto fizer prosperará.',
        'Não são assim os ímpios; mas são como a moinha que o vento espalha.',
        'Por isso os ímpios não subsistirão no juízo, nem os pecadores na congregação dos justos.',
        'Porque o Senhor conhece o caminho dos justos; porém o caminho dos ímpios perecerá.',
      ],
      [
        'Por que se amotinam os gentios, e os povos imaginam coisas vàs?',
        'Os reis da terra se levantam e os governos consultam juntamente contra o Senhor e contra o seu ungido, dizendo:',
        'Rompamos as suas ataduras, e sacudamos de nós as suas cordas.',
        'Aquele que habita nos céus se rirá; o Senhor zombará deles.',
        'Então lhes falará na sua ira, e no seu furor os turbará.',
        'Eu, porém, ungi o meu Rei sobre o meu santo monte de Sião.',
        'Proclamarei o decreto: o Senhor me disse: Tu és meu Filho, eu hoje te gerei.',
        'Pede-me, e eu te darei os gentios por herança, e os fins da terra por tua possessão.',
        'Tu os esmigalharás com uma vara de ferro; tu os despedaçarás como a um vaso de oleiro.',
        'Agora, pois, ó reis, sede prudentes; deixai-vos instruir, juízes da terra.',
        'Servi ao Senhor com temor, e alegrai-vos com tremor.',
        'Beijai o Filho, para que se não ire, e pereçais no caminho, quando em breve se acender a sua ira; bem-aventurados todos aqueles que nele confiam.',
      ],
      [
        'Senhor, como se têm multiplicado os meus adversários! São muitos os que se levantam contra mim.',
        'Muitos dizem da minha alma: Não há salvação para ele em Deus. (Selá.)',
        'Porém tu, Senhor, és um escudo para mim, a minha glória, e o que exalta a minha cabeça.',
        'Com a minha voz clamei ao Senhor, e ouviu-me desde o seu santo monte. (Selá.)',
        'Eu me deitei e dormi; acordei, porque o Senhor me sustentou.',
        'Não temerei dez milhares de pessoas que se puseram contra mim e me cercam.',
        'Levanta-te, Senhor; salva-me, Deus meu; pois feriste a todos os meus inimigos nos queixos; quebraste os dentes aos ímpios.',
        'A salvação vem do Senhor; sobre o teu povo seja a tua bênção. (Selá.)',
      ],
      [
        'Ouve-me quando eu clamo, ó Deus da minha justiça, na angústia me deste largueza; tem misericórdia de mim e ouve a minha oração.',
        'Filhos dos homens, até quando convertereis a minha glória em infâmia? Até quando amareis a vaidade e buscareis a mentira? (Selá.)',
        'Sabei, pois, que o Senhor separou para si aquele que é piedoso; o Senhor ouvirá quando eu clamar a ele.',
        'Perturbai-vos e não pequeis; falai com o vosso coração sobre a vossa cama, e calai-vos. (Selá.)',
        'Oferecei sacrifícios de justiça, e confiai no Senhor.',
        'Muitos dizem: Quem nos mostrará o bem? Senhor, exalta sobre nós a luz do teu rosto.',
        'Puseste alegria no meu coração, mais do que no tempo em que se lhes multiplicaram o trigo e o vinho.',
        'Em paz também me deitarei e dormirei, porque só tu, Senhor, me fazes habitar em segurança.',
      ],
      [
        'DÁ ouvidos às minhas palavras, ó Senhor, atende à minha meditação.',
        'Atende à voz do meu clamor, Rei meu e Deus meu, pois a ti orarei.',
        'Pela manhã ouvirás a minha voz, ó Senhor; pela manhã apresentarei a ti a minha oração, e vigiarei.',
        'Porque tu não és um Deus que tenha prazer na iniqüidade, nem contigo habitará o mal.',
        'Os loucos não pararão à tua vista; odeias a todos os que praticam a maldade.',
        'Destruirás aqueles que falam a mentira; o Senhor aborrecerá o homem sanguinário e fraudulento.',
        'Porém eu entrarei em tua casa pela grandeza da tua benignidade; e em teu temor me inclinarei para o teu santo templo.',
        'Senhor, guia-me na tua justiça, por causa dos meus inimigos; endireita diante de mim o teu caminho.',
        'Porque não há retidão na boca deles; as suas entranhas são verdadeiras maldades, a sua garganta é um sepulcro aberto; lisonjeiam com a sua língua.',
        'Declara-os culpados, ó Deus; caiam por seus próprios conselhos; lança-os fora por causa da multidão de suas transgressões, pois se rebelaram contra ti.',
        'Porém alegrem-se todos os que confiam em ti; exultem eternamente, porquanto tu os defendes; e em ti se gloriem os que amam o teu nome.',
        'Pois tu, Senhor, abençoarás ao justo; circundá-lo-ás da tua benevolência como de um escudo.',
      ],
      [
        'Senhor, não me repreendas na tua ira, nem me castigues no teu furor.',
        'Tem misericórdia de mim, Senhor, porque sou fraco; sara-me, Senhor, porque os meus ossos estão perturbados.',
        'Até a minha alma está perturbada; mas tu, Senhor, até quando?.',
        'Volta-te, Senhor, livra a minha alma; salva-me por tua benignidade.',
        'Porque na morte não há lembrança de ti; no sepulcro quem te louvará?',
        'Já estou cansado do meu gemido, toda a noite faço nadar a minha cama; molho o meu leito com as minhas lágrimas,',
        'Já os meus olhos estão consumidos pela mágoa, e têm-se envelhecido por causa de todos os meus inimigos.',
        'Apartai-vos de mim todos os que praticais a iniqüidade; porque o Senhor já ouviu a voz do meu pranto.',
        'O Senhor já ouviu a minha súplica; o Senhor aceitará a minha oração.',
        'Envergonhem-se e perturbem-se todos os meus inimigos; tornem atrás e envergonhem-se num momento.',
      ],
      [
        'Senhor meu Deus, em ti confio; salva-me de todos os que me perseguem, e livra-me;',
        'Para que ele não arrebate a minha alma, como leão, despedaçando-a, sem que haja quem a livre.',
        'Senhor meu Deus, se eu fiz isto, se há perversidade nas minhas mãos,',
        'Se paguei com o mal àquele que tinha paz comigo (antes, livrei ao que me oprimia sem causa),',
        'Persiga o inimigo a minha alma e alcance-a; calque aos pés a minha vida sobre a terra, e reduza a pó a minha glória. (Selá.)',
        'Levanta-te, Senhor, na tua ira; exalta-te por causa do furor dos meus opressores; e desperta por mim para o juízo que ordenaste.',
        'Assim te rodeará o ajuntamento de povos; por causa deles, pois, volta-te para as alturas.',
        'O Senhor julgará os povos; julga-me, Senhor, conforme a minha justiça, e conforme a integridade que há em mim.',
        'Tenha já fim a malícia dos ímpios; mas estabeleça-se o justo; pois tu, ó justo Deus, provas os corações e os rins.',
        'O meu escudo é de Deus, que salva os retos de coração.',
        'Deus é juiz justo, um Deus que se ira todos os dias.',
        'Se o homem não se converter, Deus afiará a sua espada; já tem armado o seu arco, e está aparelhado.',
        'E já para ele preparou armas mortais; e porá em ação as suas setas inflamadas contra os perseguidores.',
        'Eis que ele está com dores de perversidade; concebeu trabalhos, e produziu mentiras.',
        'Cavou um poço e o fez fundo, e caiu na cova que fez.',
        'A sua obra cairá sobre a sua cabeça; e a sua violência descerá sobre a sua própria cabeça.',
        'Eu louvarei ao Senhor segundo a sua justiça, e cantarei louvores ao nome do Senhor altíssimo.',
      ],
      [
        'Ó Senhor, Senhor nosso, quão admirável é o teu nome em toda a terra, pois puseste a tua glória sobre os céus!',
        'Tu ordenaste força da boca das crianças e dos que mamam, por causa dos teus inimigos, para fazer calar ao inimigo e ao vingador.',
        'Quando vejo os teus céus, obra dos teus dedos, a lua e as estrelas que preparaste;',
        'Que é o homem mortal para que te lembres dele? e o filho do homem, para que o visites?',
        'Pois pouco menor o fizeste do que os anjos, e de glória e de honra o coroaste.',
        'Fazes com que ele tenha domínio sobre as obras das tuas mãos; tudo puseste debaixo de seus pés:',
        'Todas as ovelhas e bois, assim como os animais do campo,',
        'As aves dos céus, e os peixes do mar, e tudo o que passa pelas veredas dos mares.',
        'Ó Senhor, Senhor nosso, quão admirável é o teu nome sobre toda a terra!',
      ],
      [
        'Eu te louvarei, Senhor, com todo o meu coração; contarei todas as tuas maravilhas.',
        'Em ti me alegrarei e saltarei de prazer; cantarei louvores ao teu nome, ó Altíssimo.',
        'Porquanto os meus inimigos retornaram, caíram e pereceram diante da tua face.',
        'Pois tu tens sustentado o meu direito e a minha causa; tu te assentaste no tribunal, julgando justamente;.',
        'Repreendeste as nações, destruíste os ímpios; apagaste o seu nome para sempre e eternamente.',
        'Oh! inimigo! acabaram-se para sempre as assolações; e tu arrasaste as cidades, e a sua memória pereceu com elas.',
        'Mas o Senhor está assentado perpetuamente; já preparou o seu tribunal para julgar.',
        'Ele mesmo julgará o mundo com justiça; exercerá juízo sobre povos com retidão.',
        'O Senhor será também um alto refúgio para o oprimido; um alto refúgio em tempos de angústia.',
        'Em ti confiarão os que conhecem o teu nome; porque tu, Senhor, nunca desamparaste os que te buscam.',
        'Cantai louvores ao Senhor, que habita em Sião; anunciai entre os povos os seus feitos.',
        'Pois quando inquire do derramamento de sangue, lembra-se deles: não se esquece do clamor dos aflitos.',
        'Tem misericórdia de mim, Senhor, olha para a minha aflição, causada por aqueles que me odeiam; tu que me levantas das portas da morte;.',
        'Para que eu conte todos os teus louvores nas portas da filha de Sião, e me alegre na tua salvação.',
        'Os gentios enterraram-se na cova que fizeram; na rede que ocultaram ficou preso o seu pé.',
        'O Senhor é conhecido pelo juízo que fez; enlaçado foi o ímpio nas obras de suas mãos. (Higaiom; Selá.).',
        'Os ímpios serão lançados no inferno, e todas as nações que se esquecem de Deus.',
        'Porque o necessitado não será esquecido para sempre, nem a expectação dos pobres perecerá perpetuamente.',
        'Levanta-te, Senhor; não prevaleça o homem; sejam julgados os gentios diante da tua face.',
        'Põe-os em medo, Senhor, para que saibam as nações que são formadas por meros homens. (Selá.).',
      ],
      [
        'Por que estás ao longe, SENHOR? Por que te escondes nos tempos de angústia?',
        'Os ímpios na sua arrogância perseguem furiosamente o pobre; sejam apanhados nas ciladas que maquinaram.',
        'Porque o ímpio gloria-se do desejo da sua alma; bendiz ao avarento, e renuncia ao Senhor.',
        'Pela altivez do seu rosto o ímpio não busca a Deus; todas as suas cogitações são que não há Deus.',
        'Os seus caminhos atormentam sempre; os teus juízos estão longe da vista dele, em grande altura, e despreza aos seus inimigos.',
        'Diz em seu coração: Não serei abalado, porque nunca me verei na adversidade.',
        'A sua boca está cheia de imprecações, de enganos e de astúcia; debaixo da sua língua há malícia e maldade.',
        'Põe-se de emboscada nas aldeias; nos lugares ocultos mata o inocente; os seus olhos estão ocultamente fixos sobre o pobre.',
        'Arma ciladas no esconderijo, como o leão no seu covil; arma ciladas para roubar o pobre; rouba-o, prendendo-o na sua rede.',
        'Encolhe-se, abaixa-se, para que os pobres caiam em suas fortes garras.',
        'Diz em seu coração: Deus esqueceu-se, cobriu o seu rosto, e nunca isto verá.',
        'Levanta-te, Senhor. Ó Deus, levanta a tua mão; não te esqueças dos humildes.',
        'Por que blasfema o ímpio de Deus? dizendo no seu coração: Tu não o esquadrinharás?',
        'Tu o viste, porque atentas para o trabalho e enfado, para o retribuir com tuas mãos; a ti o pobre se encomenda; tu és o auxílio do órfão.',
        'Quebra o braço do ímpio e malvado; busca a sua impiedade, até que nenhuma encontres.',
        'O Senhor é Rei eterno; da sua terra perecerão os gentios.',
        'Senhor, tu ouviste os desejos dos mansos; confortarás os seus corações; os teus ouvidos estarão abertos para eles;',
        'Para fazer justiça ao órfão e ao oprimido, a fim de que o homem da terra não prossiga mais em usar da violência.',
      ],
      [
        'No SENHOR confio; como dizeis à minha alma: Fugi para a vossa montanha como pássaro?',
        'Pois eis que os ímpios armam o arco, põem as flechas na corda, para com elas atirarem, às escuras, aos retos de coração.',
        'Se forem destruídos os fundamentos, que poderá fazer o justo?',
        'O Senhor está no seu santo templo, o trono do Senhor está nos céus; os seus olhos estão atentos, e as suas pálpebras provam os filhos dos homens.',
        'O Senhor prova o justo; porém ao ímpio e ao que ama a violência odeia a sua alma.',
        'Sobre os ímpios fará chover laços, fogo, enxofre e vento tempestuoso; isto será a porção do seu copo.',
        'Porque o Senhor é justo, e ama a justiça; o seu rosto olha para os retos.',
      ],
      [
        'Salva-nos, SENHOR, porque faltam os homens bons; porque são poucos os fiéis entre os filhos dos homens.',
        'Cada um fala com falsidade ao seu próximo; falam com lábios lisonjeiros e coração dobrado.',
        'O Senhor cortará todos os lábios lisonjeiros e a língua que fala soberbamente.',
        'Pois dizem: Com a nossa língua prevaleceremos; são nossos os lábios; quem é senhor sobre nós?',
        'Pela opressão dos pobres, pelo gemido dos necessitados me levantarei agora, diz o Senhor; porei a salvo aquele para quem eles assopram.',
        'As palavras do Senhor são palavras puras, como prata refinada em fornalha de barro, purificada sete vezes.',
        'Tu os guardarás, Senhor; desta geração os livrarás para sempre.',
        'Os ímpios andam por toda parte, quando os mais vis dos filhos dos homens são exaltados.',
      ],
      [
        'Até quando te esquecerás de mim, SENHOR? Para sempre? Até quando esconderás de mim o teu rosto?',
        'Até quando consultarei com a minha alma, tendo tristeza no meu coração cada dia? Até quando se exaltará sobre mim o meu inimigo?',
        'Atende-me, ouve-me, ó Senhor meu Deus; ilumina os meus olhos para que eu não adormeça na morte;',
        'Para que o meu inimigo não diga: Prevaleci contra ele; e os meus adversários não se alegrem, vindo eu a vacilar.',
        'Mas eu confio na tua benignidade; na tua salvação se alegrará o meu coração.',
        'Cantarei ao Senhor, porquanto me tem feito muito bem.',
      ],
      [
        'Disse o néscio no seu coração: Não há Deus. Têm-se corrompido, fazem-se abomináveis em suas obras, não há ninguém que faça o bem.',
        'O Senhor olhou desde os céus para os filhos dos homens, para ver se havia algum que tivesse entendimento e buscasse a Deus.',
        'Desviaram-se todos e juntamente se fizeram imundos: não há quem faça o bem, não há sequer um.',
        'Não terão conhecimento os que praticam a iniqüidade, os quais comem o meu povo, como se comessem pão, e não invocam ao Senhor?',
        'Ali se acharam em grande pavor, porque Deus está na geração dos justos.',
        'Vós envergonhais o conselho dos pobres, porquanto o Senhor é o seu refúgio.',
        'Oh, se de Sião tivera já vindo a redenção de Israel! Quando o Senhor fizer voltar os cativos do seu povo, se regozijará Jacó e se alegrará Israel.',
      ],
      [
        'SENHOR, quem habitará no teu tabernáculo? Quem morará no teu santo monte?',
        'Aquele que anda sinceramente, e pratica a justiça, e fala a verdade no seu coração.',
        'Aquele que não difama com a sua língua, nem faz mal ao seu próximo, nem aceita nenhum opróbrio contra o seu próximo;',
        'A cujos olhos o réprobo é desprezado; mas honra os que temem ao Senhor; aquele que jura com dano seu, e contudo não muda.',
        'Aquele que não dá o seu dinheiro com usura, nem recebe peitas contra o inocente. Quem faz isto nunca será abalado.',
      ],
      [
        'Guarda-me, ó Deus, porque em ti confio.',
        'A minha alma disse ao Senhor: Tu és o meu Senhor, a minha bondade não chega à tua presença,',
        'Mas aos santos que estão na terra, e aos ilustres em quem está todo o meu prazer.',
        'As dores se multiplicarão àqueles que fazem oferendas a outro deus; eu não oferecerei as suas libações de sangue, nem tomarei os seus nomes nos meus lábios.',
        'O Senhor é a porção da minha herança e do meu cálice; tu sustentas a minha sorte.',
        'As linhas caem-me em lugares deliciosos: sim, coube-me uma formosa herança.',
        'Louvarei ao Senhor que me aconselhou; até os meus rins me ensinam de noite.',
        'Tenho posto o Senhor continuamente diante de mim; por isso que ele está à minha mão direita, nunca vacilarei.',
        'Portanto está alegre o meu coração e se regozija a minha glória; também a minha carne repousará segura.',
        'Pois não deixarás a minha alma no inferno, nem permitirás que o teu Santo veja corrupção.',
        'Far-me-ás ver a vereda da vida; na tua presença há fartura de alegrias; à tua mão direita há delícias perpetuamente.',
      ],
      [
        'Ouve, SENHOR, a justiça; atende ao meu clamor; dá ouvidos à minha oração, que não é feita com lábios enganosos.',
        'Saia a minha sentença de diante do teu rosto; atendam os teus olhos à razão.',
        'Provaste o meu coração; visitaste-me de noite; examinaste-me, e nada achaste; propus que a minha boca não transgredirá.',
        'Quanto ao trato dos homens, pela palavra dos teus lábios me guardei das veredas do destruidor.',
        'Dirige os meus passos nos teus caminhos, para que as minhas pegadas não vacilem.',
        'Eu te invoquei, ó Deus, pois me queres ouvir; inclina para mim os teus ouvidos, e escuta as minhas palavras.',
        'Faze maravilhosas as tuas beneficências, ó tu que livras aqueles que em ti confiam dos que se levantam contra a tua destra.',
        'Guarda-me como à menina do olho; esconde-me debaixo da sombra das tuas asas,',
        'Dos ímpios que me oprimem, dos meus inimigos mortais que me andam cercando.',
        'Na sua gordura se encerram, com a boca falam soberbamente.',
        'Têm-nos cercado agora nossos passos; e baixaram os seus olhos para a terra;',
        'Parecem-se com o leão que deseja arrebatar a sua presa, e com o leãozinho que se põe em esconderijos.',
        'Levanta-te, Senhor, detém-no, derriba-o, livra a minha alma do ímpio, com a tua espada;',
        'Dos homens com a tua mão, Senhor, dos homens do mundo, cuja porção está nesta vida, e cujo ventre enches do teu tesouro oculto. Estão fartos de filhos e dão os seus sobejos às suas crianças.',
        'Quanto a mim, contemplarei a tua face na justiça; eu me satisfarei da tua semelhança quando acordar.',
      ],
      [
        'Eu te amarei, ó SENHOR, fortaleza minha.',
        'O Senhor é o meu rochedo, e o meu lugar forte, e o meu libertador; o meu Deus, a minha fortaleza, em quem confio; o meu escudo, a força da minha salvação, e o meu alto refúgio.',
        'Invocarei o nome do Senhor, que é digno de louvor, e ficarei livre dos meus inimigos.',
        'Tristezas de morte me cercaram, e torrentes de impiedade me assombraram.',
        'Tristezas do inferno me cingiram, laços de morte me surpreenderam.',
        'Na angústia invoquei ao Senhor, e clamei ao meu Deus; desde o seu templo ouviu a minha voz, aos seus ouvidos chegou o meu clamor perante a sua face.',
        'Então a terra se abalou e tremeu; e os fundamentos dos montes também se moveram e se abalaram, porquanto se indignou.',
        'Das suas narinas subiu fumaça, e da sua boca saiu fogo que consumia; carvões se acenderam dele.',
        'Abaixou os céus, e desceu, e a escuridão estava debaixo de seus pés.',
        'E montou num querubim, e voou; sim, voou sobre as asas do vento.',
        'Fez das trevas o seu lugar oculto; o pavilhão que o cercava era a escuridão das águas e as nuvens dos céus.',
        'Ao resplendor da sua presença as nuvens se espalharam, e a saraiva e as brasas de fogo.',
        'E o Senhor trovejou nos céus, o Altíssimo levantou a sua voz; e houve saraiva e brasas de fogo.',
        'Mandou as suas setas, e as espalhou; multiplicou raios, e os desbaratou.',
        'Então foram vistas as profundezas das águas, e foram descobertos os fundamentos do mundo, pela tua repreensão, Senhor, ao sopro das tuas narinas.',
        'Enviou desde o alto, e me tomou; tirou-me das muitas águas.',
        'Livrou-me do meu inimigo forte e dos que me odiavam, pois eram mais poderosos do que eu.',
        'Surpreenderam-me no dia da minha calamidade; mas o Senhor foi o meu amparo.',
        'Trouxe-me para um lugar espaçoso; livrou-me, porque tinha prazer em mim.',
        'Recompensou-me o Senhor conforme a minha justiça, retribuiu-me conforme a pureza das minhas mãos.',
        'Porque guardei os caminhos do Senhor, e não me apartei impiamente do meu Deus.',
        'Porque todos os seus juízos estavam diante de mim, e não rejeitei os seus estatutos.',
        'Também fui sincero perante ele, e me guardei da minha iniqüidade.',
        'Assim que retribuiu-me o Senhor conforme a minha justiça, conforme a pureza de minhas mãos perante os seus olhos.',
        'Com o benigno te mostrarás benigno; e com o homem sincero te mostrarás sincero;',
        'Com o puro te mostrarás puro; e com o perverso te mostrarás indomável.',
        'Porque tu livrarás o povo aflito, e abaterás os olhos altivos.',
        'Porque tu acenderás a minha candeia; o Senhor meu Deus iluminará as minhas trevas.',
        'Porque contigo entrei pelo meio duma tropa, com o meu Deus saltei uma muralha.',
        'O caminho de Deus é perfeito; a palavra do Senhor é provada; é um escudo para todos os que nele confiam.',
        'Porque quem é Deus senão o Senhor? E quem é rochedo senão o nosso Deus?',
        'Deus é o que me cinge de força e aperfeiçoa o meu caminho.',
        'Faz os meus pés como os das cervas, e põe-me nas minhas alturas.',
        'Ensina as minhas mãos para a guerra, de sorte que os meus braços quebraram um arco de cobre.',
        'Também me deste o escudo da tua salvação; a tua mão direita me susteve, e a tua mansidão me engrandeceu.',
        'Alargaste os meus passos debaixo de mim, de maneira que os meus artelhos não vacilaram.',
        'Persegui os meus inimigos, e os alcancei; não voltei senão depois de os ter consumido.',
        'Atravessei-os de sorte que não se puderam levantar; caíram debaixo dos meus pés.',
        'Pois me cingiste de força para a peleja; fizeste abater debaixo de mim aqueles que contra mim se levantaram.',
        'Deste-me também o pescoço dos meus inimigos para que eu pudesse destruir os que me odeiam.',
        'Clamaram, mas não houve quem os livrasse; até ao Senhor, mas ele não lhes respondeu.',
        'Então os esmiucei como o pó diante do vento; deitei-os fora como a lama das ruas.',
        'Livraste-me das contendas do povo, e me fizeste cabeça dos gentios; um povo que não conheci me servirá.',
        'Em ouvindo a minha voz, me obedecerão; os estranhos se submeterão a mim.',
        'Os estranhos descairão, e terão medo nos seus esconderijos.',
        'O Senhor vive; e bendito seja o meu rochedo, e exaltado seja o Deus da minha salvação.',
        'É Deus que me vinga inteiramente, e sujeita os povos debaixo de mim;',
        'O que me livra de meus inimigos; sim, tu me exaltas sobre os que se levantam contra mim, tu me livras do homem violento.',
        'Assim que, ó Senhor, te louvarei entre os gentios, e cantarei louvores ao teu nome,',
        'Pois engrandece a salvação do seu rei, e usa de benignidade com o seu ungido, com Davi, e com a sua semente para sempre.',
      ],
      [
        'Os céus declaram a glória de Deus e o firmamento anuncia a obra das suas mãos.',
        'Um dia faz declaração a outro dia, e uma noite mostra sabedoria a outra noite.',
        'Não há linguagem nem fala onde não se ouça a sua voz.',
        'A sua linha se estende por toda a terra, e as suas palavras até ao fim do mundo. Neles pôs uma tenda para o sol,',
        'O qual é como um noivo que sai do seu tálamo, e se alegra como um herói, a correr o seu caminho.',
        'A sua saída é desde uma extremidade dos céus, e o seu curso até à outra extremidade, e nada se esconde ao seu calor.',
        'A lei do Senhor é perfeita, e refrigera a alma; o testemunho do Senhor é fiel, e dá sabedoria aos símplices.',
        'Os preceitos do Senhor são retos e alegram o coração; o mandamento do Senhor é puro, e ilumina os olhos.',
        'O temor do Senhor é limpo, e permanece eternamente; os juízos do Senhor são verdadeiros e justos juntamente.',
        'Mais desejáveis são do que o ouro, sim, do que muito ouro fino; e mais doces do que o mel e o licor dos favos.',
        'Também por eles é admoestado o teu servo; e em os guardar há grande recompensa.',
        'Quem pode entender os seus erros? Expurga-me tu dos que me são ocultos.',
        'Também da soberba guarda o teu servo, para que se não assenhoreie de mim. Então serei sincero, e ficarei limpo de grande transgressão.',
        'Sejam agradáveis as palavras da minha boca e a meditação do meu coração perante a tua face, Senhor, Rocha minha e Redentor meu!',
      ],
      [
        'O SENHOR te ouça no dia da angústia, o nome do Deus de Jacó te proteja.',
        'Envie-te socorro desde o seu santuário, e te sustenha desde Sião.',
        'Lembre-se de todas as tuas ofertas, e aceite os teus holocaustos. (Selá.)',
        'Conceda-te conforme ao teu coração, e cumpra todo o teu plano.',
        'Nós nos alegraremos pela tua salvação, e em nome do nosso Deus arvoraremos pendões; cumpra o Senhor todas as tuas petições.',
        'Agora sei que o Senhor salva o seu ungido; ele o ouvirá desde o seu santo céu, com a força salvadora da sua mão direita.',
        'Uns confiam em carros e outros em cavalos, mas nós faremos menção do nome do Senhor nosso Deus.',
        'Uns encurvam-se e caem, mas nós nos levantamos e estamos de pé.',
        'Salva-nos, Senhor; ouça-nos o rei quando clamarmos.',
      ],
      [
        'O rei se alegra em tua força, SENHOR; e na tua salvação grandemente se regozija.',
        'Cumpriste-lhe o desejo do seu coração, e não negaste as súplicas dos seus lábios. (Selá.)',
        'Pois vais ao seu encontro com as bênçãos de bondade; pões na sua cabeça uma coroa de ouro fino.',
        'Vida te pediu, e lha deste, mesmo longura de dias para sempre e eternamente.',
        'Grande é a sua glória pela tua salvação; glória e majestade puseste sobre ele.',
        'Pois o abençoaste para sempre; tu o enches de gozo com a tua face.',
        'Porque o rei confia no Senhor, e pela misericórdia do Altíssimo nunca vacilará.',
        'A tua mão alcançará todos os teus inimigos, a tua mão direita alcançará aqueles que te odeiam.',
        'Tu os farás como um forno de fogo no tempo da tua ira; o Senhor os devorará na sua indignação, e o fogo os consumirá.',
        'Seu fruto destruirás da terra, e a sua semente dentre os filhos dos homens.',
        'Porque intentaram o mal contra ti; maquinaram um ardil, mas não prevalecerão.',
        'Assim que tu lhes farás voltar as costas; e com tuas flechas postas nas cordas lhes apontarás ao rosto.',
        'Exalta-te, Senhor, na tua força; então cantaremos e louvaremos o teu poder.',
      ],
      [
        'Deus meu, Deus meu, por que me desamparaste? Por que te alongas do meu auxílio e das palavras do meu bramido?',
        'Deus meu, eu clamo de dia, e tu não me ouves; de noite, e não tenho sossego.',
        'Porém tu és santo, tu que habitas entre os louvores de Israel.',
        'Em ti confiaram nossos pais; confiaram, e tu os livraste.',
        'A ti clamaram e escaparam; em ti confiaram, e não foram confundidos.',
        'Mas eu sou verme, e não homem, opróbrio dos homens e desprezado do povo.',
        'Todos os que me vêem zombam de mim, estendem os lábios e meneiam a cabeça, dizendo:',
        'Confiou no Senhor, que o livre; livre-o, pois nele tem prazer.',
        'Mas tu és o que me tiraste do ventre; fizeste-me confiar, estando aos seios de minha mãe.',
        'Sobre ti fui lançado desde a madre; tu és o meu Deus desde o ventre de minha mãe.',
        'Não te alongues de mim, pois a angústia está perto, e não há quem ajude.',
        'Muitos touros me cercaram; fortes touros de Basã me rodearam.',
        'Abriram contra mim suas bocas, como um leão que despedaça e que ruge.',
        'Como água me derramei, e todos os meus ossos se desconjuntaram; o meu coração é como cera, derreteu-se no meio das minhas entranhas.',
        'A minha força se secou como um caco, e a língua se me pega ao paladar; e me puseste no pó da morte.',
        'Pois me rodearam cães; o ajuntamento de malfeitores me cercou, traspassaram-me as mãos e os pés.',
        'Poderia contar todos os meus ossos; eles vêem e me contemplam.',
        'Repartem entre si as minhas vestes, e lançam sortes sobre a minha roupa.',
        'Mas tu, Senhor, não te alongues de mim. Força minha, apressa-te em socorrer-me.',
        'Livra a minha alma da espada, e a minha predileta da força do cão.',
        'Salva-me da boca do leão; sim, ouviste-me, das pontas dos bois selvagens.',
        'Então declararei o teu nome aos meus irmãos; louvar-te-ei no meio da congregação.',
        'Vós, que temeis ao Senhor, louvai-o; todos vós, semente de Jacó, glorificai-o; e temei-o todos vós, semente de Israel.',
        'Porque não desprezou nem abominou a aflição do aflito, nem escondeu dele o seu rosto; antes, quando ele clamou, o ouviu.',
        'O meu louvor será de ti na grande congregação; pagarei os meus votos perante os que o temem.',
        'Os mansos comerão e se fartarão; louvarão ao Senhor os que o buscam; o vosso coração viverá eternamente.',
        'Todos os limites da terra se lembrarão, e se converterão ao Senhor; e todas as famílias das nações adorarão perante a tua face.',
        'Porque o reino é do Senhor, e ele domina entre as nações.',
        'Todos os que na terra são gordos comerão e adorarão, e todos os que descem ao pó se prostrarão perante ele; e nenhum poderá reter viva a sua alma.',
        'Uma semente o servirá; será declarada ao Senhor a cada geração.',
        'Chegarão e anunciarão a sua justiça ao povo que nascer, porquanto ele o fez.',
      ],
      [
        'O SENHOR é o meu pastor, nada me faltará.',
        'Deitar-me faz em verdes pastos, guia-me mansamente a águas tranqüilas.',
        'Refrigera a minha alma; guia-me pelas veredas da justiça, por amor do seu nome.',
        'Ainda que eu andasse pelo vale da sombra da morte, não temeria mal algum, porque tu estás comigo; a tua vara e o teu cajado me consolam.',
        'Preparas uma mesa perante mim na presença dos meus inimigos, unges a minha cabeça com óleo, o meu cálice transborda.',
        'Certamente que a bondade e a misericórdia me seguirão todos os dias da minha vida; e habitarei na casa do Senhor por longos dias.',
      ],
      [
        'Do SENHOR é a terra e a sua plenitude, o mundo e aqueles que nele habitam.',
        'Porque ele a fundou sobre os mares, e a firmou sobre os rios.',
        'Quem subirá ao monte do Senhor, ou quem estará no seu lugar santo?',
        'Aquele que é limpo de mãos e puro de coração, que não entrega a sua alma à vaidade, nem jura enganosamente.',
        'Este receberá a bênção do Senhor e a justiça do Deus da sua salvação.',
        'Esta é a geração daqueles que buscam, daqueles que buscam a tua face, ó Deus de Jacó. (Selá.)',
        'Levantai, ó portas, as vossas cabeças; levantai-vos, ó entradas eternas, e entrará o Rei da Glória.',
        'Quem é este Rei da Glória? O Senhor forte e poderoso, o Senhor poderoso na guerra.',
        'Levantai, ó portas, as vossas cabeças, levantai-vos, ó entradas eternas, e entrará o Rei da Glória.',
        'Quem é este Rei da Glória? O Senhor dos Exércitos, ele é o Rei da Glória. (Selá.)',
      ],
      [
        'A ti, SENHOR, levanto a minha alma.',
        'Deus meu, em ti confio, não me deixes confundido, nem que os meus inimigos triunfem sobre mim.',
        'Na verdade, não serão confundidos os que esperam em ti; confundidos serão os que transgridem sem causa.',
        'Faze-me saber os teus caminhos, Senhor; ensina-me as tuas veredas.',
        'Guia-me na tua verdade, e ensina-me, pois tu és o Deus da minha salvação; por ti estou esperando todo o dia.',
        'Lembra-te, Senhor, das tuas misericórdias e das tuas benignidades, porque são desde a eternidade.',
        'Não te lembres dos pecados da minha mocidade, nem das minhas transgressões; mas segundo a tua misericórdia, lembra-te de mim, por tua bondade, Senhor.',
        'Bom e reto é o Senhor; por isso ensinará o caminho aos pecadores.',
        'Guiará os mansos em justiça e aos mansos ensinará o seu caminho.',
        'Todas as veredas do Senhor são misericórdia e verdade para aqueles que guardam a sua aliança e os seus testemunhos.',
        'Por amor do teu nome, Senhor, perdoa a minha iniqüidade, pois é grande.',
        'Qual é o homem que teme ao Senhor? Ele o ensinará no caminho que deve escolher.',
        'A sua alma pousará no bem, e a sua semente herdará a terra.',
        'O segredo do Senhor é com aqueles que o temem; e ele lhes mostrará a sua aliança.',
        'Os meus olhos estão continuamente no Senhor, pois ele tirará os meus pés da rede.',
        'Olha para mim, e tem piedade de mim, porque estou solitário e aflito.',
        'As ânsias do meu coração se têm multiplicado; tira-me dos meus apertos.',
        'Olha para a minha aflição e para a minha dor, e perdoa todos os meus pecados.',
        'Olha para os meus inimigos, pois se vão multiplicando e me odeiam com ódio cruel.',
        'Guarda a minha alma, e livra-me; não me deixes confundido, porquanto confio em ti.',
        'Guardem-me a sinceridade e a retidão, porquanto espero em ti.',
        'Redime, ó Deus, a Israel de todas as suas angústias.',
      ],
      [
        'Julga-me, SENHOR, pois tenho andado em minha sinceridade; tenho confiado também no SENHOR; não vacilarei.',
        'Examina-me, Senhor, e prova-me; esquadrinha os meus rins e o meu coração.',
        'Porque a tua benignidade está diante dos meus olhos; e tenho andado na tua verdade.',
        'Não me tenho assentado com homens vãos, nem converso com os homens dissimulados.',
        'Tenho odiado a congregação de malfeitores; nem me ajunto com os ímpios.',
        'Lavo as minhas mãos na inocência; e assim andarei, Senhor, ao redor do teu altar.',
        'Para publicar com voz de louvor, e contar todas as tuas maravilhas.',
        'Senhor, eu tenho amado a habitação da tua casa e o lugar onde permanece a tua glória.',
        'Não apanhes a minha alma com os pecadores, nem a minha vida com os homens sanguinolentos,',
        'Em cujas mãos há malefício, e cuja mão direita está cheia de subornos.',
        'Mas eu ando na minha sinceridade; livra-me e tem piedade de mim.',
        'O meu pé está posto em caminho plano; nas congregações louvarei ao Senhor.',
      ],
      [
        'O SENHOR é a minha luz e a minha salvação; a quem temerei? O SENHOR é a força da minha vida; de quem me recearei?',
        'Quando os malvados, meus adversários e meus inimigos, se chegaram contra mim, para comerem as minhas carnes, tropeçaram e caíram.',
        'Ainda que um exército me cercasse, o meu coração não temeria; ainda que a guerra se levantasse contra mim, nisto confiaria.',
        'Uma coisa pedi ao Senhor, e a buscarei: que possa morar na casa do Senhor todos os dias da minha vida, para contemplar a formosura do Senhor, e inquirir no seu templo.',
        'Porque no dia da adversidade me esconderá no seu pavilhão; no oculto do seu tabernáculo me esconderá; pôr-me-á sobre uma rocha.',
        'Também agora a minha cabeça será exaltada sobre os meus inimigos que estão em redor de mim; por isso oferecerei sacrifício de júbilo no seu tabernáculo; cantarei, sim, cantarei louvores ao Senhor.',
        'Ouve, Senhor, a minha voz quando clamo; tem também piedade de mim, e responde-me.',
        'Quando tu disseste: Buscai o meu rosto; o meu coração disse a ti: O teu rosto, Senhor, buscarei.',
        'Não escondas de mim a tua face, não rejeites ao teu servo com ira; tu foste a minha ajuda, não me deixes nem me desampares, ó Deus da minha salvação.',
        'Porque, quando meu pai e minha mãe me desampararem, o Senhor me recolherá.',
        'Ensina-me, Senhor, o teu caminho, e guia-me pela vereda direita, por causa dos meus inimigos.',
        'Não me entregues à vontade dos meus adversários; pois se levantaram falsas testemunhas contra mim, e os que respiram crueldade.',
        'Pereceria sem dúvida, se não cresse que veria a bondade do Senhor na terra dos viventes.',
        'Espera no Senhor, anima-te, e ele fortalecerá o teu coração; espera, pois, no Senhor.',
      ],
      [
        'A ti clamarei, ó Senhor, Rocha minha; não emudeças para comigo; não aconteça, cal ando-te tu para comigo, que eu fique semelhante aos que descem ao abismo.',
        'Ouve a voz das minhas súplicas, quando a ti clamar, quando levantar as minhas mãos para o teu santo oráculo.',
        'Não me arrastes com os ímpios e com os que praticam a iniqüidade; que falam de paz ao seu próximo, mas têm mal nos seus corações.',
        'Dá-lhes segundo as suas obras e segundo a malícia dos seus esforços; dá-lhes conforme a obra das suas mãos; torna-lhes a sua recompensa.',
        'Porquanto não atentam às obras do Senhor, nem à obra das suas mãos; pois que ele os derrubará e não os reedificará.',
        'Bendito seja o Senhor, porque ouviu a voz das minhas súplicas.',
        'O Senhor é a minha força e o meu escudo; nele confiou o meu coração, e fui socorrido; assim o meu coração salta de prazer, e com o meu canto o louvarei.',
        'O Senhor é a força do seu povo; também é a força salvadora do seu ungido.',
        'Salva o teu povo, e abençoa a tua herança; e apascenta-os e exalta-os para sempre.',
      ],
      [
        'Dai ao SENHOR, ó filhos dos poderosos, dai ao SENHOR glória e força.',
        'Dai ao Senhor a glória devida ao seu nome, adorai o Senhor na beleza da santidade.',
        'A voz do Senhor ouve-se sobre as suas águas; o Deus da glória troveja; o Senhor está sobre as muitas águas.',
        'A voz do Senhor é poderosa; a voz do Senhor é cheia de majestade.',
        'A voz do Senhor quebra os cedros; sim, o Senhor quebra os cedros do Líbano.',
        'Ele os faz saltar como um bezerro; ao Líbano e Siriom, como filhotes de bois selvagens.',
        'A voz do Senhor separa as labaredas do fogo.',
        'A voz do Senhor faz tremer o deserto; o Senhor faz tremer o deserto de Cades.',
        'A voz do Senhor faz parir as cervas, e descobre as brenhas; e no seu templo cada um fala da sua glória.',
        'O Senhor se assentou sobre o dilúvio; o Senhor se assenta como Rei, perpetuamente.',
        'O Senhor dará força ao seu povo; o Senhor abençoará o seu povo com paz.',
      ],
      [
        'Exaltar-te-ei, ó SENHOR, porque tu me exaltaste; e não fizeste com que meus inimigos se alegrassem sobre mim.',
        'Senhor meu Deus, clamei a ti, e tu me saraste.',
        'Senhor, fizeste subir a minha alma da sepultura; conservaste-me a vida para que não descesse ao abismo.',
        'Cantai ao Senhor, vós que sois seus santos, e celebrai a memória da sua santidade.',
        'Porque a sua ira dura só um momento; no seu favor está a vida. O choro pode durar uma noite, mas a alegria vem pela manhã.',
        'Eu dizia na minha prosperidade: Não vacilarei jamais.',
        'Tu, Senhor, pelo teu favor fizeste forte a minha montanha; tu encobriste o teu rosto, e fiquei perturbado.',
        'A ti, Senhor, clamei, e ao Senhor supliquei.',
        'Que proveito há no meu sangue, quando desço à cova? Porventura te louvará o pó? Anunciará ele a tua verdade?',
        'Ouve, Senhor, e tem piedade de mim, Senhor; sê o meu auxílio.',
        'Tornaste o meu pranto em folguedo; desataste o meu pano de saco, e me cingiste de alegria,',
        'Para que a minha glória a ti cante louvores, e não se cale. Senhor, meu Deus, eu te louvarei para sempre.',
      ],
      [
        'Em ti, SENHOR, confio; nunca me deixes confundido. Livra-me pela tua justiça.',
        'Inclina para mim os teus ouvidos, livra-me depressa; sê a minha firme rocha, uma casa fortíssima que me salve.',
        'Porque tu és a minha rocha e a minha fortaleza; assim, por amor do teu nome, guia-me e encaminha-me.',
        'Tira-me da rede que para mim esconderam, pois tu és a minha força.',
        'Nas tuas mãos encomendo o meu espírito; tu me redimiste, Senhor Deus da verdade.',
        'Odeio aqueles que se entregam a vaidades enganosas; eu, porém, confio no Senhor.',
        'Eu me alegrarei e regozijarei na tua benignidade, pois consideraste a minha aflição; conheceste a minha alma nas angústias.',
        'E não me entregaste nas mãos do inimigo; puseste os meus pés num lugar espaçoso.',
        'Tem misericórdia de mim, ó Senhor, porque estou angustiado. Consumidos estão de tristeza os meus olhos, a minha alma e o meu ventre.',
        'Porque a minha vida está gasta de tristeza, e os meus anos de suspiros; a minha força descai por causa da minha iniqüidade, e os meus ossos se consomem.',
        'Fui opróbrio entre todos os meus inimigos, até entre os meus vizinhos, e horror para os meus conhecidos; os que me viam na rua fugiam de mim.',
        'Estou esquecido no coração deles, como um morto; sou como um vaso quebrado.',
        'Pois ouvi a murmuração de muitos, temor havia ao redor; enquanto juntamente consultavam contra mim, intentaram tirar-me a vida.',
        'Mas eu confiei em ti, Senhor; e disse: Tu és o meu Deus.',
        'Os meus tempos estão nas tuas mãos; livra-me das mãos dos meus inimigos e dos que me perseguem.',
        'Faze resplandecer o teu rosto sobre o teu servo; salva-me por tuas misericórdias.',
        'Não me deixes confundido, Senhor, porque te tenho invocado. Deixa confundidos os ímpios, e emudeçam na sepultura.',
        'Emudeçam os lábios mentirosos que falam coisas más com soberba e desprezo contra o justo.',
        'Oh! quão grande é a tua bondade, que guardaste para os que te temem, a qual operaste para aqueles que em ti confiam na presença dos filhos dos homens!',
        'Tu os esconderás, no secreto da tua presença, dos desaforos dos homens; encobri-los-ás em um pavilhão, da contenda das línguas.',
        'Bendito seja o Senhor, pois fez maravilhosa a sua misericórdia para comigo em cidade segura.',
        'Pois eu dizia na minha pressa: Estou cortado de diante dos teus olhos; não obstante, tu ouviste a voz das minhas súplicas, quando eu a ti clamei.',
        'Amai ao Senhor, vós todos que sois seus santos; porque o Senhor guarda os fiéis e retribui com abundância ao que usa de soberba.',
        'Esforçai-vos, e ele fortalecerá o vosso coração, vós todos que esperais no Senhor.',
      ],
      [
        'Bem-aventurado aquele cuja transgressão é perdoada, e cujo pecado é coberto.',
        'Bem-aventurado o homem a quem o Senhor não imputa maldade, e em cujo espírito não há engano.',
        'Quando eu guardei silêncio, envelheceram os meus ossos pelo meu bramido em todo o dia.',
        'Porque de dia e de noite a tua mão pesava sobre mim; o meu humor se tornou em sequidão de estio. (Selá.)',
        'Confessei-te o meu pecado, e a minha maldade não encobri. Dizia eu: Confessarei ao Senhor as minhas transgressões; e tu perdoaste a maldade do meu pecado. (Selá.)',
        'Por isso, todo aquele que é santo orará a ti, a tempo de te poder achar; até no transbordar de muitas águas, estas não lhe chegarão.',
        'Tu és o lugar em que me escondo; tu me preservas da angústia; tu me cinges de alegres cantos de livramento. (Selá.)',
        'Instruir-te-ei, e ensinar-te-ei o caminho que deves seguir; guiar-te-ei com os meus olhos.',
        'Não sejais como o cavalo, nem como a mula, que não têm entendimento, cuja boca precisa de cabresto e freio para que não se cheguem a ti.',
        'O ímpio tem muitas dores, mas àquele que confia no Senhor a misericórdia o cercará.',
        'Alegrai-vos no Senhor, e regozijai-vos, vós os justos; e cantai alegremente, todos vós que sois retos de coração.',
      ],
      [
        'Regozijai-vos no SENHOR, vós justos, pois aos retos convém o louvor.',
        'Louvai ao Senhor com harpa, cantai a ele com o saltério e um instrumento de dez cordas.',
        'Cantai-lhe um cântico novo; tocai bem e com júbilo.',
        'Porque a palavra do Senhor é reta, e todas as suas obras são fiéis.',
        'Ele ama a justiça e o juízo; a terra está cheia da bondade do Senhor.',
        'Pela palavra do Senhor foram feitos os céus, e todo o exército deles pelo espírito da sua boca.',
        'Ele ajunta as águas do mar como num montão; põe os abismos em depósitos.',
        'Tema toda a terra ao Senhor; temam-no todos os moradores do mundo.',
        'Porque falou, e foi feito; mandou, e logo apareceu.',
        'O Senhor desfaz o conselho dos gentios, quebranta os intentos dos povos.',
        'O conselho do Senhor permanece para sempre; os intentos do seu coração de geração em geração.',
        'Bem-aventurada é a nação cujo Deus é o Senhor, e o povo ao qual escolheu para sua herança.',
        'O Senhor olha desde os céus e está vendo a todos os filhos dos homens.',
        'Do lugar da sua habitação contempla todos os moradores da terra.',
        'Ele é que forma o coração de todos eles, que contempla todas as suas obras.',
        'Não há rei que se salve com a grandeza dum exército, nem o homem valente se livra pela muita força.',
        'O cavalo é falaz para a segurança; não livra ninguém com a sua grande força.',
        'Eis que os olhos do Senhor estão sobre os que o temem, sobre os que esperam na sua misericórdia;',
        'Para lhes livrar as almas da morte, e para os conservar vivos na fome.',
        'A nossa alma espera no Senhor; ele é o nosso auxílio e o nosso escudo.',
        'Pois nele se alegra o nosso coração; porquanto temos confiado no seu santo nome.',
        'Seja a tua misericórdia, Senhor, sobre nós, como em ti esperamos.',
      ],
      [
        'Louvarei ao SENHOR em todo o tempo; o seu louvor estará continuamente na minha boca.',
        'A minha alma se gloriará no Senhor; os mansos o ouvirão e se alegrarão.',
        'Engrandecei ao Senhor comigo; e juntos exaltemos o seu nome.',
        'Busquei ao Senhor, e ele me respondeu; livrou-me de todos os meus temores.',
        'Olharam para ele, e foram iluminados; e os seus rostos não ficaram confundidos.',
        'Clamou este pobre, e o Senhor o ouviu, e o salvou de todas as suas angústias.',
        'O anjo do Senhor acampa-se ao redor dos que o temem, e os livra.',
        'Provai, e vede que o Senhor é bom; bem-aventurado o homem que nele confia.',
        'Temei ao Senhor, vós, os seus santos, pois nada falta aos que o temem.',
        'Os filhos dos leões necessitam e sofrem fome, mas àqueles que buscam ao Senhor bem nenhum faltará.',
        'Vinde, meninos, ouvi-me; eu vos ensinarei o temor do Senhor.',
        'Quem é o homem que deseja a vida, que quer largos dias para ver o bem?',
        'Guarda a tua língua do mal, e os teus lábios de falarem o engano.',
        'Aparta-te do mal, e faze o bem; procura a paz, e segue-a.',
        'Os olhos do Senhor estão sobre os justos, e os seus ouvidos atentos ao seu clamor.',
        'A face do Senhor está contra os que fazem o mal, para desarraigar da terra a memória deles.',
        'Os justos clamam, e o Senhor os ouve, e os livra de todas as suas angústias.',
        'Perto está o Senhor dos que têm o coração quebrantado, e salva os contritos de espírito.',
        'Muitas são as aflições do justo, mas o Senhor o livra de todas.',
        'Ele lhe guarda todos os seus ossos; nem sequer um deles se quebra.',
        'A malícia matará o ímpio, e os que odeiam o justo serão punidos.',
        'O Senhor resgata a alma dos seus servos, e nenhum dos que nele confiam será punido.',
      ],
      [
        'Pleiteia, SENHOR, com aqueles que pleiteiam comigo; peleja contra os que pelejam contra mim.',
        'Pega do escudo e da rodela, e levanta-te em minha ajuda.',
        'Tira da lança e obstrui o caminho aos que me perseguem; dize à minha alma: Eu sou a tua salvação.',
        'Sejam confundidos e envergonhados os que buscam a minha vida; voltem atrás e envergonhem-se os que contra mim tentam mal.',
        'Sejam como a moinha perante o vento; o anjo do Senhor os faça fugir.',
        'Seja o seu caminho tenebroso e escorregadio, e o anjo do Senhor os persiga.',
        'Porque sem causa encobriram de mim a rede na cova, a qual sem razão cavaram para a minha alma.',
        'Sobrevenha-lhe destruição sem o saber, e prenda-o a rede que ocultou; caia ele nessa mesma destruição.',
        'E a minha alma se alegrará no Senhor; alegrar-se-á na sua salvação.',
        'Todos os meus ossos dirão: Senhor, quem é como tu, que livras o pobre daquele que é mais forte do que ele? Sim, o pobre e o necessitado daquele que o rouba.',
        'Falsas testemunhas se levantaram; depuseram contra mim coisas que eu não sabia.',
        'Tornaram-me o mal pelo bem, roubando a minha alma.',
        'Mas, quanto a mim, quando estavam enfermos, as minhas vestes eram o saco; humilhava a minha alma com o jejum, e a minha oração voltava para o meu seio.',
        'Portava-me como se ele fora meu irmão ou amigo; andava lamentando e muito encurvado, como quem chora por sua mãe.',
        'Mas eles com a minha adversidade se alegravam e se congregavam; os abjetos se congregavam contra mim, e eu não o sabia; rasgavam-me, e não cessavam.',
        'Com hipócritas zombadores nas festas, rangiam os dentes contra mim.',
        'Senhor, até quando verás isto? Resgata a minha alma das suas assolações, e a minha predileta dos leões.',
        'Louvar-te-ei na grande congregação; entre muitíssimo povo te celebrarei.',
        'Não se alegrem os meus inimigos de mim sem razão, nem acenem com os olhos aqueles que me odeiam sem causa.',
        'Pois não falam de paz; antes projetam enganar os quietos da terra.',
        'Abrem a boca de par em par contra mim, e dizem: Ah! Ah! os nossos olhos o viram.',
        'Tu, Senhor, o tens visto, não te cales; Senhor, não te alongues de mim:',
        'Desperta e acorda para o meu julgamento, para a minha causa, Deus meu e Senhor meu.',
        'Julga-me segundo a tua justiça, Senhor Deus meu, e não deixes que se alegrem de mim.',
        'Não digam em seus corações: Ah! alma nossa! Não digam: Nós o havemos devorado.',
        'Envergonhem-se e confundam-se à uma os que se alegram com o meu mal; vistam-se de vergonha e de confusão os que se engrandecem contra mim.',
        'Cantem e alegrem-se os que amam a minha justiça, e digam continuamente: O Senhor seja engrandecido, o qual ama a prosperidade do seu servo.',
        'E assim a minha língua falará da tua justiça e do teu louvor todo o dia.',
      ],
      [
        'A transgressão do ímpio diz no íntimo do meu coração: Não há temor de Deus perante os seus olhos.',
        'Porque em seus olhos se lisonjeia, até que a sua iniqüidade se descubra ser detestável.',
        'As palavras da sua boca são malícia e engano; deixou de entender e de fazer o bem.',
        'Projeta a malícia na sua cama; põe-se no caminho que não é bom; não aborrece o mal.',
        'A tua misericórdia, Senhor, está nos céus, e a tua fidelidade chega até às mais excelsas nuvens.',
        'A tua justiça é como as grandes montanhas; os teus juízos são um grande abismo. Senhor, tu conservas os homens e os animais.',
        'Quão preciosa é, ó Deus, a tua benignidade, pelo que os filhos dos homens se abrigam à sombra das tuas asas.',
        'Eles se fartarão da gordura da tua casa, e os farás beber da corrente das tuas delícias;',
        'Porque em ti está o manancial da vida; na tua luz veremos a luz.',
        'Estende a tua benignidade sobre os que te conhecem, e a tua justiça sobre os retos de coração.',
        'Não venha sobre mim o pé dos soberbos, e não me mova a mão dos ímpios.',
        'Ali caem os que praticam a iniqüidade; cairão, e não se poderão levantar.',
      ],
      [
        'NÃO te indignes por causa dos malfeitores, nem tenhas inveja dos que praticam a iniqüidade.',
        'Porque cedo serão ceifados como a erva, e murcharão como a verdura.',
        'Confia no Senhor e faze o bem; habitarás na terra, e verdadeiramente serás alimentado.',
        'Deleita-te também no Senhor, e te concederá os desejos do teu coração.',
        'Entrega o teu caminho ao Senhor; confia nele, e ele o fará.',
        'E ele fará sobressair a tua justiça como a luz, e o teu juízo como o meio-dia.',
        'Descansa no Senhor, e espera nele; não te indignes por causa daquele que prospera em seu caminho, por causa do homem que executa astutos intentos.',
        'Deixa a ira, e abandona o furor; não te indignes de forma alguma para fazer o mal.',
        'Porque os malfeitores serão desarraigados; mas aqueles que esperam no Senhor herdarão a terra.',
        'Pois ainda um pouco, e o ímpio não existirá; olharás para o seu lugar, e não aparecerá.',
        'Mas os mansos herdarão a terra, e se deleitarão na abundância de paz.',
        'O ímpio maquina contra o justo, e contra ele range os dentes.',
        'O Senhor se rirá dele, pois vê que vem chegando o seu dia.',
        'Os ímpios puxaram da espada e armaram o arco, para derrubarem o pobre e necessitado, e para matarem os de reta conduta.',
        'Porém a sua espada lhes entrará no coração, e os seus arcos se quebrarão.',
        'Vale mais o pouco que tem o justo, do que as riquezas de muitos ímpios.',
        'Pois os braços dos ímpios se quebrarão, mas o Senhor sustém os justos.',
        'O Senhor conhece os dias dos retos, e a sua herança permanecerá para sempre.',
        'Não serão envergonhados nos dias maus, e nos dias de fome se fartarão.',
        'Mas os ímpios perecerão, e os inimigos do Senhor serão como a gordura dos cordeiros; desaparecerão, e em fumaça se desfarão.',
        'O ímpio toma emprestado, e não paga; mas o justo se compadece e dá.',
        'Porque aqueles que ele abençoa herdarão a terra, e aqueles que forem por ele amaldiçoados serão desarraigados.',
        'Os passos de um homem bom são confirmados pelo Senhor, e deleita-se no seu caminho.',
        'Ainda que caia, não ficará prostrado, pois o Senhor o sustém com a sua mão.',
        'Fui moço, e agora sou velho; mas nunca vi desamparado o justo, nem a sua semente a mendigar o pão.',
        'Compadece-se sempre, e empresta, e a sua semente é abençoada.',
        'Aparta-te do mal e faze o bem; e terás morada para sempre.',
        'Porque o Senhor ama o juízo e não desampara os seus santos; eles são preservados para sempre; mas a semente dos ímpios será desarraigada.',
        'Os justos herdarão a terra e habitarão nela para sempre.',
        'A boca do justo fala a sabedoria; a sua língua fala do juízo.',
        'A lei do seu Deus está em seu coração; os seus passos não resvalarão.',
        'O ímpio espreita ao justo, e procura matá-lo.',
        'O Senhor não o deixará em suas mãos, nem o condenará quando for julgado.',
        'Espera no Senhor, e guarda o seu caminho, e te exaltará para herdares a terra; tu o verás quando os ímpios forem desarraigados.',
        'Vi o ímpio com grande poder espalhar-se como a árvore verde na terra natal.',
        'Mas passou e já não aparece; procurei-o, mas não se pôde encontrar.',
        'Nota o homem sincero, e considera o reto, porque o fim desse homem é a paz.',
        'Quanto aos transgressores, serão à uma destruídos, e as relíquias dos ímpios serão destruídas.',
        'Mas a salvação dos justos vem do Senhor; ele é a sua fortaleza no tempo da angústia.',
        'E o Senhor os ajudará e os livrará; ele os livrará dos ímpios e os salvará, porquanto confiam nele.',
      ],
      [
        'Ó Senhor, não me repreendas na tua ira, nem me castigues no teu furor.',
        'Porque as tuas flechas se cravaram em mim, e a tua mão sobre mim desceu.',
        'Não há coisa sã na minha carne, por causa da tua cólera; nem há paz em meus ossos, por causa do meu pecado.',
        'Pois já as minhas iniqüidades ultrapassam a minha cabeça; como carga pesada são demais para as minhas forças.',
        'As minhas chagas cheiram mal e estão corruptas, por causa da minha loucura.',
        'Estou encurvado, estou muito abatido, ando lamentando todo o dia.',
        'Porque as minhas ilhargas estão cheias de ardor, e não há coisa sã na minha carne.',
        'Estou fraco e mui quebrantado; tenho rugido pela inquietação do meu coração.',
        'Senhor, diante de ti está todo o meu desejo, e o meu gemido não te é oculto.',
        'O meu coração dá voltas, a minha força me falta; quanto à luz dos meus olhos, ela me deixou.',
        'Os meus amigos e os meus companheiros estão ao longe da minha chaga; e os meus parentes se põem à distância.',
        'Também os que buscam a minha vida me armam laços e os que procuram o meu mal falam coisas que danificam, e imaginam astúcias todo o dia.',
        'Mas eu, como surdo, não ouvia, e era como mudo, que não abre a boca.',
        'Assim eu sou como homem que não ouve, e em cuja boca não há reprovação.',
        'Porque em ti, Senhor, espero; tu, Senhor meu Deus, me ouvirás.',
        'Porque dizia eu: Ouve-me, para que não se alegrem de mim. Quando escorrega o meu pé, eles se engrandecem contra mim.',
        'Porque estou prestes a coxear; a minha dor está constantemente perante mim.',
        'Porque eu declararei a minha iniqüidade; afligir-me-ei por causa do meu pecado.',
        'Mas os meus inimigos estão vivos e são fortes, e os que sem causa me odeiam se multiplicam.',
        'Os que dão mal pelo bem são meus adversários, porquanto eu sigo o que é bom.',
        'Não me desampares, Senhor, meu Deus, não te alongues de mim.',
        'Apressa-te em meu auxílio, Senhor, minha salvação.',
      ],
      [
        'Eu disse: Guardarei os meus caminhos para não pecar com a minha língua; guardarei a boca com um freio, enquanto o ímpio estiver diante de mim.',
        'Com o silêncio fiquei mudo; calava-me mesmo acerca do bem, e a minha dor se agravou.',
        'Esquentou-se-me o coração dentro de mim; enquanto eu meditava se acendeu um fogo; então falei com a minha língua:',
        'Faze-me conhecer, Senhor, o meu fim, e a medida dos meus dias qual é, para que eu sinta quanto sou frágil.',
        'Eis que fizeste os meus dias como a palmos; o tempo da minha vida é como nada diante de ti; na verdade, todo homem, por mais firme que esteja, é totalmente vaidade. (Selá.)',
        'Na verdade, todo homem anda numa và aparência; na verdade, em vão se inquietam; amontoam riquezas, e não sabem quem as levará.',
        'Agora, pois, Senhor, que espero eu? A minha esperança está em ti.',
        'Livra-me de todas as minhas transgressões; não me faças o opróbrio dos loucos.',
        'Emudeci; não abro a minha boca, porquanto tu o fizeste.',
        'Tira de sobre mim a tua praga; estou desfalecido pelo golpe da tua mão.',
        'Quando castigas o homem, com repreensões por causa da iniqüidade, fazes com que a sua beleza se consuma como a traça; assim todo homem é vaidade. (Selá.)',
        'Ouve, Senhor, a minha oração, e inclina os teus ouvidos ao meu clamor; não te cales perante as minhas lágrimas, porque sou um estrangeiro contigo e peregrino, como todos os meus pais.',
        'Poupa-me, até que tome alento, antes que me vá, e não seja mais.',
      ],
      [
        'Esperei com paciência no SENHOR, e ele se inclinou para mim, e ouviu o meu clamor.',
        'Tirou-me dum lago horrível, dum charco de lodo, pôs os meus pés sobre uma rocha, firmou os meus passos.',
        'E pôs um novo cântico na minha boca, um hino ao nosso Deus; muitos o verão, e temerão, e confiarão no Senhor.',
        'Bem-aventurado o homem que põe no Senhor a sua confiança, e que não respeita os soberbos nem os que se desviam para a mentira.',
        'Muitas são, Senhor meu Deus, as maravilhas que tens operado para conosco, e os teus pensamentos não se podem contar diante de ti; se eu os quisera anunciar, e deles falar, são mais do que se podem contar.',
        'Sacrifício e oferta não quiseste; os meus ouvidos abriste; holocausto e expiação pelo pecado não reclamaste.',
        'Então disse: Eis aqui venho; no rolo do livro de mim está escrito.',
        'Deleito-me em fazer a tua vontade, ó Deus meu; sim, a tua lei está dentro do meu coração.',
        'Preguei a justiça na grande congregação; eis que não retive os meus lábios, Senhor, tu o sabes.',
        'Não escondi a tua justiça dentro do meu coração; apregoei a tua fidelidade e a tua salvação. Não escondi da grande congregação a tua benignidade e a tua verdade.',
        'Não retires de mim, Senhor, as tuas misericórdias; guardem-me continuamente a tua benignidade e a tua verdade.',
        'Porque males sem número me têm rodeado; as minhas iniqüidades me prenderam de modo que não posso olhar para cima. São mais numerosas do que os cabelos da minha cabeça; assim desfalece o meu coração.',
        'Digna-te, Senhor, livrar-me: Senhor, apressa-te em meu auxílio.',
        'Sejam à uma confundidos e envergonhados os que buscam a minha vida para destruí-la; tornem atrás e confundam-se os que me querem mal.',
        'Desolados sejam em pago da sua afronta os que me dizem: Ah! Ah!',
        'Folguem e alegrem-se em ti os que te buscam; digam constantemente os que amam a tua salvação: Magnificado seja o Senhor.',
        'Mas eu sou pobre e necessitado; contudo o Senhor cuida de mim. Tu és o meu auxílio e o meu libertador; não te detenhas, ó meu Deus.',
      ],
      [
        'Bem-aventurado é aquele que atende ao pobre; o SENHOR o livrará no dia do mal.',
        'O Senhor o livrará, e o conservará em vida; será abençoado na terra, e tu não o entregarás à vontade de seus inimigos.',
        'O Senhor o sustentará no leito da enfermidade; tu o restaurarás da sua cama de doença.',
        'Dizia eu: Senhor, tem piedade de mim; sara a minha alma, porque pequei contra ti.',
        'Os meus inimigos falam mal de mim, dizendo: Quando morrerá ele, e perecerá o seu nome?',
        'E, se algum deles vem me ver, fala coisas vãs; no seu coração amontoa a maldade; saindo para fora, é disso que fala.',
        'Todos os que me odeiam murmuram à uma contra mim; contra mim imaginam o mal, dizendo:',
        'Uma doença má se lhe tem apegado; e agora que está deitado, não se levantará mais.',
        'Até o meu próprio amigo íntimo, em quem eu tanto confiava, que comia do meu pão, levantou contra mim o seu calcanhar.',
        'Porém tu, Senhor, tem piedade de mim, e levanta-me, para que eu lhes dê o pago.',
        'Por isto conheço eu que tu me favoreces: que o meu inimigo não triunfa de mim.',
        'Quanto a mim, tu me sustentas na minha sinceridade, e me puseste diante da tua face para sempre.',
        'Bendito seja o Senhor Deus de Israel de século em século. Amém e Amém.',
      ],
      [
        'Assim como o cervo brama pelas correntes das águas, assim suspira a minha alma por ti, ó Deus!',
        'A minha alma tem sede de Deus, do Deus vivo; quando entrarei e me apresentarei ante a face de Deus?',
        'As minhas lágrimas servem-me de mantimento de dia e de noite, enquanto me dizem constantemente: Onde está o teu Deus?',
        'Quando me lembro disto, dentro de mim derramo a minha alma; pois eu havia ido com a multidão. Fui com eles à casa de Deus, com voz de alegria e louvor, com a multidão que festejava.',
        'Por que estás abatida, ó minha alma, e por que te perturbas em mim? Espera em Deus, pois ainda o louvarei pela salvação da sua face.',
        'Ó meu Deus, dentro de mim a minha alma está abatida; por isso lembro-me de ti desde a terra do Jordão, e desde os hermonitas, desde o pequeno monte.',
        'Um abismo chama outro abismo, ao ruído das tuas catadupas; todas as tuas ondas e as tuas vagas têm passado sobre mim.',
        'Contudo o Senhor mandará a sua misericórdia de dia, e de noite a sua canção estará comigo, uma oração ao Deus da minha vida.',
        'Direi a Deus, minha rocha: Por que te esqueceste de mim? Por que ando lamentando por causa da opressão do inimigo?',
        'Com ferida mortal em meus ossos me afrontam os meus adversários, quando todo dia me dizem: Onde está o teu Deus?',
        'Por que estás abatida, ó minha alma, e por que te perturbas dentro de mim? Espera em Deus, pois ainda o louvarei, o qual é a salvação da minha face, e o meu Deus.',
      ],
      [
        'Faze-me justiça, ó Deus, e pleiteia a minha causa contra a nação ímpia. Livra-me do homem fraudulento e injusto.',
        'Pois tu és o Deus da minha fortaleza; por que me rejeitas? Por que ando lamentando por causa da opressão do inimigo?',
        'Envia a tua luz e a tua verdade, para que me guiem e me levem ao teu santo monte, e aos teus tabernáculos.',
        'Então irei ao altar de Deus, a Deus, que é a minha grande alegria, e com harpa te louvarei, ó Deus, Deus meu.',
        'Por que estás abatida, ó minha alma? E por que te perturbas dentro de mim? Espera em Deus, pois ainda o louvarei, o qual é a salvação da minha face e Deus meu.',
      ],
      [
        'Ó Deus, nós ouvimos com os nossos ouvidos, e nossos pais nos têm contado a obra que fizeste em seus dias, nos tempos da antiguidade.',
        'Como expulsaste os gentios com a tua mão e os plantaste a eles; como afligiste os povos e os derrubaste.',
        'Pois não conquistaram a terra pela sua espada, nem o seu braço os salvou, mas a tua destra e o teu braço, e a luz da tua face, porquanto te agradaste deles.',
        'Tu és o meu Rei, ó Deus; ordena salvações para Jacó.',
        'Por ti venceremos os nossos inimigos; pelo teu nome pisaremos os que se levantam contra nós.',
        'Pois eu não confiarei no meu arco, nem a minha espada me salvará.',
        'Mas tu nos salvaste dos nossos inimigos, e confundiste os que nos odiavam.',
        'Em Deus nos gloriamos todo o dia, e louvamos o teu nome eternamente. (Selá.)',
        'Mas agora tu nos rejeitaste e nos confundiste, e não sais com os nossos exércitos.',
        'Tu nos fazes retirar do inimigo, e aqueles que nos odeiam nos saqueiam para si.',
        'Tu nos entregaste como ovelhas para comer, e nos espalhaste entre os gentios.',
        'Tu vendes por nada o teu povo, e não aumentas a tua riqueza com o seu preço.',
        'Tu nos pões por opróbrio aos nossos vizinhos, por escárnio e zombaria daqueles que estão à roda de nós.',
        'Tu nos pões por provérbio entre os gentios, por movimento de cabeça entre os povos.',
        'A minha confusão está constantemente diante de mim, e a vergonha do meu rosto me cobre,',
        'À voz daquele que afronta e blasfema, por causa do inimigo e do vingador.',
        'Tudo isto nos sobreveio; contudo não nos esquecemos de ti, nem nos houvemos falsamente contra a tua aliança.',
        'O nosso coração não voltou atrás, nem os nossos passos se desviaram das tuas veredas;',
        'Ainda que nos quebrantaste num lugar de dragões, e nos cobriste com a sombra da morte.',
        'Se nós esquecemos o nome do nosso Deus, e estendemos as nossas mãos para um deus estranho,',
        'Porventura não esquadrinhará Deus isso? Pois ele sabe os segredos do coração.',
        'Sim, por amor de ti, somos mortos todo o dia; somos reputados como ovelhas para o matadouro.',
        'Desperta, por que dormes, Senhor? Acorda, não nos rejeites para sempre.',
        'Por que escondes a tua face, e te esqueces da nossa miséria e da nossa opressão?',
        'Pois a nossa alma está abatida até ao pó; o nosso ventre se apega à terra.',
        'Levanta-te em nosso auxílio, e resgata-nos por amor das tuas misericórdias.',
      ],
      [
        'O meu coração ferve com palavras boas, falo do que tenho feito no tocante ao Rei. A minha língua é a pena de um destro escritor.',
        'Tu és mais formoso do que os filhos dos homens; a graça se derramou em teus lábios; por isso Deus te abençoou para sempre.',
        'Cinge a tua espada à coxa, ó valente, com a tua glória e a tua majestade.',
        'E neste teu esplendor cavalga prosperamente, por causa da verdade, da mansidão e da justiça; e a tua destra te ensinará coisas terríveis.',
        'As tuas flechas são agudas no coração dos inimigos do rei, e por elas os povos caíram debaixo de ti.',
        'O teu trono, ó Deus, é eterno e perpétuo; o cetro do teu reino é um cetro de eqüidade.',
        'Tu amas a justiça e odeias a impiedade; por isso Deus, o teu Deus, te ungiu com óleo de alegria mais do que a teus companheiros.',
        'Todas as tuas vestes cheiram a mirra e aloés e cássia, desde os palácios de marfim de onde te alegram.',
        'As filhas dos reis estavam entre as tuas ilustres mulheres; à tua direita estava a rainha ornada de finíssimo ouro de Ofir.',
        'Ouve, filha, e olha, e inclina os teus ouvidos; esquece-te do teu povo e da casa do teu pai.',
        'Então o rei se afeiçoará da tua formosura, pois ele é teu Senhor; adora-o.',
        'E a filha de Tiro estará ali com presentes; os ricos do povo suplicarão o teu favor.',
        'A filha do rei é toda ilustre lá dentro; o seu vestido é entretecido de ouro.',
        'Levá-la-ão ao rei com vestidos bordados; as virgens que a acompanham a trarão a ti.',
        'Com alegria e regozijo as trarão; elas entrarão no palácio do rei.',
        'Em lugar de teus pais estarão teus filhos; deles farás príncipes sobre toda a terra.',
        'Farei lembrado o teu nome de geração em geração; por isso os povos te louvarão eternamente.',
      ],
      [
        'Deus é o nosso refúgio e fortaleza, socorro bem presente na angústia.',
        'Portanto não temeremos, ainda que a terra se mude, e ainda que os montes se transportem para o meio dos mares.',
        'Ainda que as águas rujam e se perturbem, ainda que os montes se abalem pela sua braveza. (Selá.)',
        'Há um rio cujas correntes alegram a cidade de Deus, o santuário das moradas do Altíssimo.',
        'Deus está no meio dela; não se abalará. Deus a ajudará, já ao romper da manhã.',
        'Os gentios se embraveceram; os reinos se moveram; ele levantou a sua voz e a terra se derreteu.',
        'O Senhor dos Exércitos está conosco; o Deus de Jacó é o nosso refúgio. (Selá.)',
        'Vinde, contemplai as obras do Senhor; que desolações tem feito na terra!',
        'Ele faz cessar as guerras até ao fim da terra; quebra o arco e corta a lança; queima os carros no fogo.',
        'Aquietai-vos, e sabei que eu sou Deus; serei exaltado entre os gentios; serei exaltado sobre a terra.',
        'O Senhor dos Exércitos está conosco; o Deus de Jacó é o nosso refúgio. (Selá.)',
      ],
      [
        'Batei palmas, todos os povos; aclamai a Deus com voz de triunfo.',
        'Porque o Senhor Altíssimo é tremendo, e Rei grande sobre toda a terra.',
        'Ele nos subjugará os povos e as nações debaixo dos nossos pés.',
        'Escolherá para nós a nossa herança, a glória de Jacó, a quem amou. (Selá.)',
        'Deus subiu com júbilo, o Senhor subiu ao som de trombeta.',
        'Cantai louvores a Deus, cantai louvores; cantai louvores ao nosso Rei, cantai louvores.',
        'Pois Deus é o Rei de toda a terra, cantai louvores com inteligência.',
        'Deus reina sobre os gentios; Deus se assenta sobre o trono da sua santidade.',
        'Os príncipes do povo se ajuntam, o povo do Deus de Abraão; porque os escudos da terra são de Deus. Ele está muito elevado!',
      ],
      [
        'Grande é o SENHOR e mui digno de louvor, na cidade do nosso Deus, no seu monte santo.',
        'Formoso de sítio, e alegria de toda a terra é o monte Sião sobre os lados do norte, a cidade do grande Rei.',
        'Deus é conhecido nos seus palácios por um alto refúgio.',
        'Porque eis que os reis se ajuntaram; eles passaram juntos.',
        'Viram-no e ficaram maravilhados; ficaram assombrados e se apressaram em fugir.',
        'Tremor ali os tomou, e dores como de mulher de parto.',
        'Tu quebras as naus de Társis com um vento oriental.',
        'Como o ouvimos, assim o vimos na cidade do Senhor dos Exércitos, na cidade do nosso Deus. Deus a confirmará para sempre. (Selá.)',
        'Lembramo-nos, ó Deus, da tua benignidade, no meio do teu templo.',
        'Segundo é o teu nome, ó Deus, assim é o teu louvor, até aos fins da terra; a tua mão direita está cheia de justiça.',
        'Alegre-se o monte de Sião; alegrem-se as filhas de Judá por causa dos teus juízos.',
        'Rodeai Sião, e cercai-a, contai as suas torres.',
        'Marcai bem os seus antemuros, considerai os seus palácios, para que o conteis à geração seguinte.',
        'Porque este Deus é o nosso Deus para sempre; ele será nosso guia até à morte.',
      ],
      [
        'Ouvi isto, vós todos os povos; inclinai os ouvidos, todos os moradores do mundo,',
        'Tanto baixos como altos, tanto ricos como pobres.',
        'A minha boca falará de sabedoria, e a meditação do meu coração será de entendimento.',
        'Inclinarei os meus ouvidos a uma parábola; declararei o meu enigma na harpa.',
        'Por que temerei eu nos dias maus, quando me cercar a iniqüidade dos que me armam ciladas?',
        'Aqueles que confiam na sua fazenda, e se gloriam na multidão das suas riquezas,',
        'Nenhum deles de modo algum pode remir a seu irmão, ou dar a Deus o resgate dele.',
        '(Pois a redenção da sua alma é caríssima, e cessará para sempre),',
        'Para que viva para sempre, e não veja corrupção.',
        'Porque ele vê que os sábios morrem; perecem igualmente tanto o louco como o brutal, e deixam a outros os seus bens.',
        'O seu pensamento interior é que as suas casas serão perpétuas e as suas habitações de geração em geração; dão às suas terras os seus próprios nomes.',
        'Todavia o homem que está em honra não permanece; antes é como os animais, que perecem.',
        'Este caminho deles é a sua loucura; contudo a sua posteridade aprova as suas palavras. (Selá.)',
        'Como ovelhas são postos na sepultura; a morte se alimentará deles e os retos terão domínio sobre eles na manhã, e a sua formosura se consumirá na sepultura, a habitação deles.',
        'Mas Deus remirá a minha alma do poder da sepultura, pois me receberá. (Selá.)',
        'Não temas, quando alguém se enriquece, quando a glória da sua casa se engrandece.',
        'Porque, quando morrer, nada levará consigo, nem a sua glória o acompanhará.',
        'Ainda que na sua vida ele bendisse a sua alma; e os homens te louvarão, quando fizeres bem a ti mesmo,',
        'Irá para a geração de seus pais; eles nunca verão a luz.',
        'O homem que está em honra, e não tem entendimento, é semelhante aos animais, que perecem.',
      ],
      [
        'O Deus poderoso, o SENHOR, falou e chamou a terra desde o nascimento do sol até ao seu ocaso.',
        'Desde Sião, a perfeição da formosura, resplandeceu Deus.',
        'Virá o nosso Deus, e não se calará; um fogo se irá consumindo diante dele, e haverá grande tormenta ao redor dele.',
        'Chamará os céus lá do alto, e a terra, para julgar o seu povo.',
        'Ajuntai-me os meus santos, aqueles que fizeram comigo uma aliança com sacrifícios.',
        'E os céus anunciarão a sua justiça; pois Deus mesmo é o Juiz. (Selá.)',
        'Ouve, povo meu, e eu falarei; ó Israel, e eu protestarei contra ti: Sou Deus, sou o teu Deus.',
        'Não te repreenderei pelos teus sacrifícios, ou holocaustos, que estão continuamente perante mim.',
        'Da tua casa não tirarei bezerro, nem bodes dos teus currais.',
        'Porque meu é todo animal da selva, e o gado sobre milhares de montanhas.',
        'Conheço todas as aves dos montes; e minhas são todas as feras do campo.',
        'Se eu tivesse fome, não to diria, pois meu é o mundo e toda a sua plenitude.',
        'Comerei eu carne de touros? ou beberei sangue de bodes?',
        'Oferece a Deus sacrifício de louvor, e paga ao Altíssimo os teus votos.',
        'E invoca-me no dia da angústia; eu te livrarei, e tu me glorificarás.',
        'Mas ao ímpio diz Deus: Que fazes tu em recitar os meus estatutos, e em tomar a minha aliança na tua boca?',
        'Visto que odeias a correção, e lanças as minhas palavras para detrás de ti.',
        'Quando vês o ladrão, consentes com ele, e tens a tua parte com adúlteros.',
        'Soltas a tua boca para o mal, e a tua língua compõe o engano.',
        'Assentas-te a falar contra teu irmão; falas mal contra o filho de tua mãe.',
        'Estas coisas tens feito, e eu me calei; pensavas que era tal como tu, mas eu te argüirei, e as porei por ordem diante dos teus olhos:',
        'Ouvi pois isto, vós que vos esqueceis de Deus; para que eu vos não faça em pedaços, sem haver quem vos livre.',
        'Aquele que oferece o sacrifício de louvor me glorificará; e àquele que bem ordena o seu caminho eu mostrarei a salvação de Deus.',
      ],
      [
        'Tem misericórdia de mim, ó Deus, segundo a tua benignidade; apaga as minhas transgressões, segundo a multidão das tuas misericórdias.',
        'Lava-me completamente da minha iniqüidade, e purifica-me do meu pecado.',
        'Porque eu conheço as minhas transgressões, e o meu pecado está sempre diante de mim.',
        'Contra ti, contra ti somente pequei, e fiz o que é mal à tua vista, para que sejas justificado quando falares, e puro quando julgares.',
        'Eis que em iniqüidade fui formado, e em pecado me concebeu minha mãe.',
        'Eis que amas a verdade no íntimo, e no oculto me fazes conhecer a sabedoria.',
        'Purifica-me com hissopo, e ficarei puro; lava-me, e ficarei mais branco do que a neve.',
        'Faze-me ouvir júbilo e alegria, para que gozem os ossos que tu quebraste.',
        'Esconde a tua face dos meus pecados, e apaga todas as minhas iniqüidades.',
        'Cria em mim, ó Deus, um coração puro, e renova em mim um espírito reto.',
        'Não me lances fora da tua presença, e não retires de mim o teu Espírito Santo.',
        'Torna a dar-me a alegria da tua salvação, e sustém-me com um espírito voluntário.',
        'Então ensinarei aos transgressores os teus caminhos, e os pecadores a ti se converterão.',
        'Livra-me dos crimes de sangue, ó Deus, Deus da minha salvação, e a minha língua louvará altamente a tua justiça.',
        'Abre, Senhor, os meus lábios, e a minha boca entoará o teu louvor.',
        'Pois não desejas sacrifícios, senão eu os daria; tu não te deleitas em holocaustos.',
        'Os sacrifícios para Deus são o espírito quebrantado; a um coração quebrantado e contrito não desprezarás, ó Deus.',
        'Faze o bem a Sião, segundo a tua boa vontade; edifica os muros de Jerusalém.',
        'Então te agradarás dos sacrifícios de justiça, dos holocaustos e das ofertas queimadas; então se oferecerão novilhos sobre o teu altar.',
      ],
      [
        'Por que te glorias na malícia, ó homem poderoso? Pois a bondade de Deus permanece continuamente.',
        'A tua língua intenta o mal, como uma navalha amolada, traçando enganos.',
        'Tu amas mais o mal do que o bem, e a mentira mais do que o falar a retidão. (Selá.)',
        'Amas todas as palavras devoradoras, ó língua fraudulenta.',
        'Também Deus te destruirá para sempre; arrebatar-te-á e arrancar-te-á da tua habitação, e desarraigar-te-á da terra dos viventes. (Selá.)',
        'E os justos o verão, e temerão: e se rirão dele, dizendo:',
        'Eis aqui o homem que não pôs em Deus a sua fortaleza, antes confiou na abundância das suas riquezas, e se fortaleceu na sua maldade.',
        'Mas eu sou como a oliveira verde na casa de Deus; confio na misericórdia de Deus para sempre, eternamente.',
        'Para sempre te louvarei, porque tu o fizeste, e esperarei no teu nome, porque é bom diante de teus santos.',
      ],
      [
        'Disse o néscio no seu coração: Não há Deus. Têm-se corrompido, e cometido abominável iniqüidade; não há ninguém que faça o bem.',
        'Deus olhou desde os céus para os filhos dos homens, para ver se havia algum que tivesse entendimento e buscasse a Deus.',
        'Desviaram-se todos, e juntamente se fizeram imundos; não há quem faça o bem, não, nem sequer um.',
        'Acaso não têm conhecimento os que praticam a iniqüidade, os quais comem o meu povo como se comessem pão? Eles não invocaram a Deus.',
        'Ali se acharam em grande temor, onde não havia temor, pois Deus espalhou os ossos daquele que te cercava; tu os confundiste, porque Deus os rejeitou.',
        'Oh! se já de Sião viesse a salvação de Israel! Quando Deus fizer voltar os cativos do seu povo, então se regozijará Jacó e se alegrará Israel.',
      ],
      [
        'Salva-me, ó Deus, pelo teu nome, e faze-me justiça pelo teu poder.',
        'Ó Deus, ouve a minha oração, inclina os teus ouvidos às palavras da minha boca.',
        'Porque os estranhos se levantam contra mim, e tiranos procuram a minha vida; não têm posto Deus perante os seus olhos. (Selá.)',
        'Eis que Deus é o meu ajudador, o Senhor está com aqueles que sustêm a minha alma.',
        'Ele recompensará com o mal os meus inimigos. Destrói-os na tua verdade.',
        'Eu te oferecerei voluntariamente sacrifícios; louvarei o teu nome, ó Senhor, porque é bom,',
        'Pois me tem livrado de toda a angústia; e os meus olhos viram o meu desejo sobre os meus inimigos.',
      ],
      [
        'Inclina, ó Deus, os teus ouvidos à minha oração, e não te escondas da minha súplica.',
        'Atende-me, e ouve-me; lamento na minha queixa, e faço ruído,',
        'Pelo clamor do inimigo e por causa da opressão do ímpio; pois lançam sobre mim a iniqüidade, e com furor me odeiam.',
        'O meu coração está dolorido dentro de mim, e terrores da morte caíram sobre mim.',
        'Temor e tremor vieram sobre mim; e o horror me cobriu.',
        'Assim eu disse: Oh! quem me dera asas como de pomba! Então voaria, e estaria em descanso.',
        'Eis que fugiria para longe, e pernoitaria no deserto. (Selá.)',
        'Apressar-me-ia a escapar da fúria do vento e da tempestade.',
        'Despedaça, Senhor, e divide as suas línguas, pois tenho visto violência e contenda na cidade.',
        'De dia e de noite a cercam sobre os seus muros; iniqüidade e malícia estão no meio dela.',
        'Maldade há dentro dela; astúcia e engano não se apartam das suas ruas.',
        'Pois não era um inimigo que me afrontava; então eu o teria suportado; nem era o que me odiava que se engrandecia contra mim, porque dele me teria escondido.',
        'Mas eras tu, homem meu igual, meu guia e meu íntimo amigo.',
        'Consultávamos juntos suavemente, e andávamos em companhia na casa de Deus.',
        'A morte os assalte, e vivos desçam ao inferno; porque há maldade nas suas habitações e no meio deles.',
        'Eu, porém, invocarei a Deus, e o Senhor me salvará.',
        'De tarde e de manhã e ao meio-dia orarei; e clamarei, e ele ouvirá a minha voz.',
        'Livrou em paz a minha alma da peleja que havia contra mim; pois havia muitos comigo.',
        'Deus ouvirá, e os afligirá. Aquele que preside desde a antiguidade (Selá), porque não há neles nenhuma mudança, e portanto não temem a Deus.',
        'Tal homem pôs as suas mãos naqueles que têm paz com ele; quebrou a sua aliança.',
        'As palavras da sua boca eram mais macias do que a manteiga, mas havia guerra no seu coração: as suas palavras eram mais brandas do que o azeite; contudo, eram espadas desembainhadas.',
        'Lança o teu cuidado sobre o Senhor, e ele te susterá; não permitirá jamais que o justo seja abalado.',
        'Mas tu, ó Deus, os farás descer ao poço da perdição; homens de sangue e de fraude não viverão metade dos seus dias; mas eu em ti confiarei.',
      ],
      [
        'Tem misericórdia de mim, ó Deus, porque o homem procura devorar-me; pelejando todo dia, me oprime.',
        'Os meus inimigos procuram devorar-me todo dia; pois são muitos os que pelejam contra mim, ó Altíssimo.',
        'Em qualquer tempo em que eu temer, confiarei em ti.',
        'Em Deus louvarei a sua palavra, em Deus pus a minha confiança; não temerei o que me possa fazer a carne.',
        'Todos os dias torcem as minhas palavras; todos os seus pensamentos são contra mim para o mal.',
        'Ajuntam-se, escondem-se, marcam os meus passos, como aguardando a minha alma.',
        'Porventura escaparão eles por meio da sua iniqüidade? Ó Deus, derruba os povos na tua ira!',
        'Tu contas as minhas vagueações; põe as minhas lágrimas no teu odre. Não estão elas no teu livro?',
        'Quando eu a ti clamar, então voltarão para trás os meus inimigos: isto sei eu, porque Deus é por mim.',
        'Em Deus louvarei a sua palavra; no Senhor louvarei a sua palavra.',
        'Em Deus tenho posto a minha confiança; não temerei o que me possa fazer o homem.',
        'Os teus votos estão sobre mim, ó Deus; eu te renderei ações de graças;',
        'Pois tu livraste a minha alma da morte; não livrarás os meus pés da queda, para andar diante de Deus na luz dos viventes?',
      ],
      [
        'Tem misericórdia de mim, ó Deus, tem misericórdia de mim, porque a minha alma confia em ti; e à sombra das tuas asas me abrigo, até que passem as calamidades.',
        'Clamarei ao Deus altíssimo, ao Deus que por mim tudo executa.',
        'Ele enviará desde os céus, e me salvará do desprezo daquele que procurava devorar-me. (Selá.) Deus enviará a sua misericórdia e a sua verdade.',
        'A minha alma está entre leões, e eu estou entre aqueles que estão abrasados, filhos dos homens, cujos dentes são lanças e flechas, e a sua língua espada afiada.',
        'Sê exaltado, ó Deus, sobre os céus; seja a tua glória sobre toda a terra.',
        'Armaram uma rede aos meus passos; a minha alma está abatida. Cavaram uma cova diante de mim, porém eles mesmos caíram no meio dela. (Selá.)',
        'Preparado está o meu coração, ó Deus, preparado está o meu coração; cantarei, e darei louvores.',
        'Desperta, glória minha; despertai, saltério e harpa; eu mesmo despertarei ao romper da alva.',
        'Louvar-te-ei, Senhor, entre os povos; eu te cantarei entre as nações.',
        'Pois a tua misericórdia é grande até aos céus, e a tua verdade até às nuvens.',
        'Sê exaltado, ó Deus, sobre os céus; e seja a tua glória sobre toda a terra.',
      ],
      [
        'Acaso falais vós, deveras, ó congregação, a justiça? Julgais retamente, ó filhos dos homens?',
        'Antes no coração forjais iniqüidades; sobre a terra pesais a violência das vossas mãos.',
        'Alienam-se os ímpios desde a madre; andam errados desde que nasceram, falando mentiras.',
        'O seu veneno é semelhante ao veneno da serpente; são como a víbora surda, que tapa os ouvidos,',
        'Para não ouvir a voz dos encantadores, do encantador sábio em encantamentos.',
        'Ó Deus, quebra-lhes os dentes nas suas bocas; arranca, Senhor, os queixais aos filhos dos leões.',
        'Escorram como águas que correm constantemente. Quando ele armar as suas flechas, fiquem feitas em pedaços.',
        'Como a lesma se derrete, assim se vá cada um deles; como o aborto duma mulher, que nunca viu o sol.',
        'Antes que as vossas panelas sintam o calor dos espinhos, como por um redemoinho os arrebatará ele, vivo e em indignação.',
        'O justo se alegrará quando vir a vingança; lavará os seus pés no sangue do ímpio.',
        'Então dirá o homem: Deveras há uma recompensa para o justo; deveras há um Deus que julga na terra.',
      ],
      [
        'Livra-me, meu Deus, dos meus inimigos, defende-me daqueles que se levantam contra mim.',
        'Livra-me dos que praticam a iniqüidade, e salva-me dos homens sanguinários.',
        'Pois eis que põem ciladas à minha alma; os fortes se ajuntam contra mim, não por transgressão minha ou por pecado meu, ó Senhor.',
        'Eles correm, e se preparam, sem culpa minha; desperta para me ajudares, e olha.',
        'Tu, pois, ó Senhor, Deus dos Exércitos, Deus de Israel, desperta para visitares todos os gentios; não tenhas misericórdia de nenhum dos pérfidos que praticam a iniqüidade. (Selá.)',
        'Voltam à tarde; dão ganidos como cães, e rodeiam a cidade.',
        'Eis que eles dão gritos com as suas bocas; espadas estão nos seus lábios, porque, dizem eles: Quem ouve?',
        'Mas tu, Senhor, te rirás deles; zombarás de todos os gentios;',
        'Por causa da sua força eu te aguardarei; pois Deus é a minha alta defesa.',
        'O Deus da minha misericórdia virá ao meu encontro; Deus me fará ver o meu desejo sobre os meus inimigos.',
        'Não os mates, para que o meu povo não se esqueça; espalha-os pelo teu poder, e abate-os, ó Senhor, nosso escudo.',
        'Pelo pecado da sua boca e pelas palavras dos seus lábios, fiquem presos na sua soberba, e pelas maldições e pelas mentiras que falam.',
        'Consome-os na tua indignação, consome-os, para que não existam, e para que saibam que Deus reina em Jacó até aos fins da terra. (Selá.)',
        'E tornem a vir à tarde, e dêem ganidos como cães, e cerquem a cidade.',
        'Vagueiem para cima e para baixo por mantimento, e passem a noite sem se saciarem.',
        'Eu, porém, cantarei a tua força; pela manhã louvarei com alegria a tua misericórdia; porquanto tu foste o meu alto refúgio, e proteção no dia da minha angústia.',
        'A ti, ó fortaleza minha, cantarei salmos; porque Deus é a minha defesa e o Deus da minha misericórdia.',
      ],
      [
        'Ó Deus, tu nos rejeitaste, tu nos espalhaste, tu te indignaste; oh, volta-te para nós.',
        'Abalaste a terra, e a fendeste; sara as suas fendas, pois ela treme.',
        'Fizeste ver ao teu povo coisas árduas; fizeste-nos beber o vinho do atordoamento.',
        'Deste um estandarte aos que te temem, para o arvorarem no alto, por causa da verdade. (Selá.)',
        'Para que os teus amados sejam livres, salva-nos com a tua destra, e ouve-nos;',
        'Deus falou na sua santidade; eu me regozijarei, repartirei a Siquém e medirei o vale de Sucote.',
        'Meu é Gileade, e meu é Manassés; Efraim é a força da minha cabeça; Judá é o meu legislador.',
        'Moabe é a minha bacia de lavar; sobre Edom lançarei o meu sapato; alegra-te, ó Filístia, por minha causa.',
        'Quem me conduzirá à cidade forte? Quem me guiará até Edom?.',
        'Não serás tu, ó Deus, que nos tinhas rejeitado? tu, ó Deus, que não saíste com os nossos exércitos?',
        'Dá-nos auxílio na angústia, porque vão é o socorro do homem.',
        'Em Deus faremos proezas; porque ele é que pisará os nossos inimigos.',
      ],
      [
        'Ouve, ó Deus, o meu clamor; atende à minha oração.',
        'Desde o fim da terra clamarei a ti, quando o meu coração estiver desmaiado; leva-me para a rocha que é mais alta do que eu.',
        'Pois tens sido um refúgio para mim, e uma torre forte contra o inimigo.',
        'Habitarei no teu tabernáculo para sempre; abrigar-me-ei no esconderijo das tuas asas. (Selá.)',
        'Pois tu, ó Deus, ouviste os meus votos; deste-me a herança dos que temem o teu nome.',
        'Prolongarás os dias do rei; e os seus anos serão como muitas gerações.',
        'Ele permanecerá diante de Deus para sempre; prepara-lhe misericórdia e verdade que o preservem.',
        'Assim cantarei louvores ao teu nome perpetuamente, para pagar os meus votos de dia em dia.',
      ],
      [
        'A minha alma espera somente em Deus; dele vem a minha salvação.',
        'Só ele é a minha rocha e a minha salvação; é a minha defesa; não serei grandemente abalado.',
        'Até quando maquinareis o mal contra um homem? Sereis mortos todos vós, sereis como uma parede encurvada e uma sebe prestes a cair.',
        'Eles somente consultam como o hão de derrubar da sua excelência; deleitam-se em mentiras; com a boca bendizem, mas nas suas entranhas maldizem. (Selá.)',
        'Ó minha alma, espera somente em Deus, porque dele vem a minha esperança.',
        'Só ele é a minha rocha e a minha salvação; é a minha defesa; não serei abalado.',
        'Em Deus está a minha salvação e a minha glória; a rocha da minha fortaleza, e o meu refúgio estão em Deus.',
        'Confiai nele, ó povo, em todos os tempos; derramai perante ele o vosso coração. Deus é o nosso refúgio. (Selá.)',
        'Certamente que os homens de classe baixa são vaidade, e os homens de ordem elevada são mentira; pesados em balanças, eles juntos são mais leves do que a vaidade.',
        'Não confieis na opressão, nem vos ensoberbeçais na rapina; se as vossas riquezas aumentam, não ponhais nelas o coração.',
        'Deus falou uma vez; duas vezes ouvi isto: que o poder pertence a Deus.',
        'A ti também, Senhor, pertence a misericórdia; pois retribuirás a cada um segundo a sua obra.',
      ],
      [
        'O Deus, tu és o meu Deus, de madrugada te buscarei; a minha alma tem sede de ti; a minha carne te deseja muito em uma terra seca e cansada, onde não há água;',
        'Para ver a tua força e a tua glória, como te vi no santuário.',
        'Porque a tua benignidade é melhor do que a vida, os meus lábios te louvarão.',
        'Assim eu te bendirei enquanto viver; em teu nome levantarei as minhas mãos.',
        'A minha alma se fartará, como de tutano e de gordura; e a minha boca te louvará com alegres lábios,',
        'Quando me lembrar de ti na minha cama, e meditar em ti nas vigílias da noite.',
        'Porque tu tens sido o meu auxílio; então, à sombra das tuas asas me regozijarei.',
        'A minha alma te segue de perto; a tua destra me sustenta.',
        'Mas aqueles que procuram a minha alma para a destruir, irão para as profundezas da terra.',
        'Cairão à espada; serão uma ração para as raposas.',
        'Mas o rei se regozijará em Deus; qualquer que por ele jurar se gloriará; porque se taparão as bocas dos que falam a mentira.',
      ],
      [
        'Ouve, ó Deus, a minha voz na minha oração; guarda a minha vida do temor do inimigo.',
        'Esconde-me do secreto conselho dos maus, e do tumulto dos que praticam a iniqüidade.',
        'Que afiaram as suas línguas como espadas; e armaram por suas flechas palavras amargas,',
        'A fim de atirarem em lugar oculto ao que é íntegro; disparam sobre ele repentinamente, e não temem.',
        'Firmam-se em mau intento; falam de armar laços secretamente, e dizem: Quem os verá?',
        'Andam inquirindo malícias, inquirem tudo o que se pode inquirir; e ambos, o íntimo pensamento de cada um deles, e o coração, são profundos.',
        'Mas Deus atirará sobre eles uma seta, e de repente ficarão feridos.',
        'Assim eles farão com que as suas línguas tropecem contra si mesmos; todos aqueles que os virem, fugirão.',
        'E todos os homens temerão, e anunciarão a obra de Deus; e considerarão prudentemente os feitos dele.',
        'O justo se alegrará no Senhor, e confiará nele, e todos os retos de coração se gloriarão.',
      ],
      [
        'A ti, ó Deus, espera o louvor em Sião, e a ti se pagará o voto.',
        'Ó tu que ouves as orações, a ti virá toda a carne.',
        'Prevalecem as iniqüidades contra mim; porém tu limpas as nossas transgressões.',
        'Bem-aventurado aquele a quem tu escolhes, e fazes chegar a ti, para que habite em teus átrios; nós seremos fartos da bondade da tua casa e do teu santo templo.',
        'Com coisas tremendas em justiça nos responderás, ó Deus da nossa salvação; tu és a esperança de todas as extremidades da terra, e daqueles que estão longe sobre o mar.',
        'O que pela sua força consolida os montes, cingido de fortaleza;',
        'O que aplaca o ruído dos mares, o ruído das suas ondas, e o tumulto dos povos.',
        'E os que habitam nos fins da terra temem os teus sinais; tu fazes alegres as saídas da manhã e da tarde.',
        'Tu visitas a terra, e a refrescas; tu a enriqueces grandemente com o rio de Deus, que está cheio de água; tu lhe preparas o trigo, quando assim a tens preparada.',
        'Enches de água os seus sulcos; tu lhe aplanas as leivas; tu a amoleces com a muita chuva; abençoas as suas novidades.',
        'Coroas o ano com a tua bondade, e as tuas veredas destilam gordura.',
        'Destilam sobre os pastos do deserto, e os outeiros os cingem de alegria.',
        'Os campos se vestem de rebanhos, e os vales se cobrem de trigo; eles se regozijam e cantam.',
      ],
      [
        'Celebrai com júbilo a Deus, todas as terras.',
        'Cantai a glória do seu nome; dai glória ao seu louvor.',
        'Dizei a Deus: Quão tremendo és tu nas tuas obras! Pela grandeza do teu poder se submeterão a ti os teus inimigos.',
        'Todos os moradores da terra te adorarão e te cantarão; cantarão o teu nome. (Selá.)',
        'Vinde, e vede as obras de Deus: é tremendo nos seus feitos para com os filhos dos homens.',
        'Converteu o mar em terra seca; passaram o rio a pé; ali nos alegramos nele.',
        'Ele domina eternamente pelo seu poder; os seus olhos estão sobre as nações; não se exaltem os rebeldes. (Selá.)',
        'Bendizei, povos, ao nosso Deus, e fazei ouvir a voz do seu louvor,',
        'Ao que sustenta com vida a nossa alma, e não consente que sejam abalados os nossos pés.',
        'Pois tu, ó Deus, nos provaste; tu nos afinaste como se afina a prata.',
        'Tu nos puseste na rede; afligiste os nossos lombos,',
        'Fizeste com que os homens cavalgassem sobre as nossas cabeças; passamos pelo fogo e pela água; mas nos trouxeste a um lugar espaçoso.',
        'Entrarei em tua casa com holocaustos; pagar-te-ei os meus votos,',
        'Os quais pronunciaram os meus lábios, e falou a minha boca, quando estava na angústia.',
        'Oferecer-te-ei holocaustos gordurosos com incenso de carneiros; oferecerei novilhos com cabritos. (Selá.)',
        'Vinde, e ouvi, todos os que temeis a Deus, e eu contarei o que ele tem feito à minha alma.',
        'A ele clamei com a minha boca, e ele foi exaltado pela minha língua.',
        'Se eu atender à iniqüidade no meu coração, o Senhor não me ouvirá;',
        'Mas, na verdade, Deus me ouviu; atendeu à voz da minha oração.',
        'Bendito seja Deus, que não rejeitou a minha oração, nem desviou de mim a sua misericórdia.',
      ],
      [
        'Deus tenha misericórdia de nós e nos abençoe; e faça resplandecer o seu rosto sobre nós (Selá.)',
        'Para que se conheça na terra o teu caminho, e entre todas as nações a tua salvação.',
        'Louvem-te a ti, ó Deus, os povos; louvem-te os povos todos.',
        'Alegrem-se e regozijem-se as nações, pois julgarás os povos com eqüidade, e governarás as nações sobre a terra. (Selá.)',
        'Louvem-te a ti, ó Deus, os povos; louvem-te os povos todos.',
        'Então a terra dará o seu fruto; e Deus, o nosso Deus, nos abençoará.',
        'Deus nos abençoará, e todas as extremidades da terra o temerão.',
      ],
      [
        'Levante-se Deus, e sejam dissipados os seus inimigos; fugirão de diante dele os que o odeiam.',
        'Como se impele a fumaça, assim tu os impeles; assim como a cera se derrete diante do fogo, assim pereçam os ímpios diante de Deus.',
        'Mas alegrem-se os justos, e se regozijem na presença de Deus, e folguem de alegria.',
        'Cantai a Deus, cantai louvores ao seu nome; louvai aquele que vai montado sobre os céus, pois o seu nome é Senhor, e exultai diante dele.',
        'Pai de órfãos e juiz de viúvas é Deus, no seu lugar santo.',
        'Deus faz que o solitário viva em família; liberta aqueles que estão presos em grilhões; mas os rebeldes habitam em terra seca.',
        'Ó Deus, quando saías diante do teu povo, quando caminhavas pelo deserto, (Selá.).',
        'A terra abalava-se, e os céus destilavam perante a face de Deus; até o próprio Sinai foi comovido na presença de Deus, do Deus de Israel.',
        'Tu, ó Deus, mandaste a chuva em abundância, confortaste a tua herança, quando estava cansada.',
        'Nela habitava o teu rebanho; tu, ó Deus, fizeste provisão da tua bondade para o pobre.',
        'O Senhor deu a palavra; grande era o exército dos que anunciavam as boas novas.',
        'Reis de exércitos fugiram à pressa; e aquela que ficava em casa repartia os despojos.',
        'Ainda que vos tenhais deitado entre redis, contudo sereis como as asas duma pomba, cobertas de prata, e as suas penas, de ouro amarelo.',
        'Quando o Onipotente ali espalhou os reis, foi como a neve em Salmon.',
        'O monte de Deus é como o monte de Basã, um monte elevado como o monte de Basã.',
        'Por que saltais, ó montes elevados? Este é o monte que Deus desejou para a sua habitação, e o Senhor habitará nele eternamente.',
        'Os carros de Deus são vinte milhares, milhares de milhares. O Senhor está entre eles, como em Sinai, no lugar santo.',
        'Tu subiste ao alto, levaste cativo o cativeiro, recebeste dons para os homens, e até para os rebeldes, para que o Senhor Deus habitasse entre eles.',
        'Bendito seja o Senhor, que de dia em dia nos carrega de benefícios; o Deus que é a nossa salvação. (Selá.).',
        'O nosso Deus é o Deus da salvação; e a DEUS, o Senhor, pertencem os livramentos da morte.',
        'Mas Deus ferirá gravemente a cabeça de seus inimigos e o crânio cabeludo do que anda em suas culpas.',
        'Disse o Senhor: Eu os farei voltar de Basã, farei voltar o meu povo das profundezas do mar;',
        'Para que o teu pé mergulhe no sangue de teus inimigos, e no mesmo a língua dos teus cães.',
        'Ó Deus, eles têm visto os teus caminhos; os caminhos do meu Deus, meu Rei, no santuário.',
        'Os cantores iam adiante, os tocadores de instrumentos atrás; entre eles as donzelas tocando adufes.',
        'Celebrai a Deus nas congregações; ao Senhor, desde a fonte de Israel.',
        'Ali está o pequeno Benjamim, que domina sobre eles, os príncipes de Judá com o seu ajuntamento, os príncipes de Zebulom e os príncipes de Naftali.',
        'O teu Deus ordenou a tua força; fortalece, ó Deus, o que já fizeste para nós.',
        'Por amor do teu templo em Jerusalém, os reis te trarão presentes.',
        'Repreende asperamente as feras dos canaviais, a multidão dos touros, com os novilhos dos povos, até que cada um se submeta com peças de prata; dissipa os povos que desejam a guerra.',
        'Príncipes virão do Egito; a Etiópia cedo estenderá para Deus as suas mãos.',
        'Reinos da terra, cantai a Deus, cantai louvores ao Senhor. (Selá.)',
        'Àquele que vai montado sobre os céus dos céus, que existiam desde a antiguidade; eis que envia a sua voz, dá um brado veemente.',
        'Atribuí a Deus fortaleza; a sua excelência está sobre Israel e a sua fortaleza nas mais altas nuvens.',
        'Ó Deus, tu és tremendo desde os teus santuários; o Deus de Israel é o que dá força e poder ao seu povo. Bendito seja Deus!',
      ],
      [
        'Livra-me, ó Deus, pois as águas entraram até à minha alma.',
        'Atolei-me em profundo lamaçal, onde se não pode estar em pé; entrei na profundeza das águas, onde a corrente me leva.',
        'Estou cansado de clamar; a minha garganta se secou; os meus olhos desfalecem esperando o meu Deus.',
        'Aqueles que me odeiam sem causa são mais do que os cabelos da minha cabeça; aqueles que procuram destruir-me, sendo injustamente meus inimigos, são poderosos; então restituí o que não furtei.',
        'Tu, ó Deus, bem conheces a minha estultice; e os meus pecados não te são encobertos.',
        'Não sejam envergonhados por minha causa aqueles que esperam em ti, ó Senhor, DEUS dos Exércitos; não sejam confundidos por minha causa aqueles que te buscam, ó Deus de Israel.',
        'Porque por amor de ti tenho suportado afrontas; a confusão cobriu o meu rosto.',
        'Tenho-me tornado um estranho para com meus irmãos, e um desconhecido para com os filhos de minha mãe.',
        'Pois o zelo da tua casa me devorou, e as afrontas dos que te afrontam caíram sobre mim.',
        'Quando chorei, e castiguei com jejum a minha alma, isto se me tornou em afrontas.',
        'Pus por vestido um saco, e me fiz um provérbio para eles.',
        'Aqueles que se assentam à porta falam contra mim; e fui o cântico dos bebedores de bebida forte.',
        'Eu, porém, faço a minha oração a ti, Senhor, num tempo aceitável; ó Deus, ouve-me segundo a grandeza da tua misericórdia, segundo a verdade da tua salvação.',
        'Tira-me do lamaçal, e não me deixes atolar; seja eu livre dos que me odeiam, e das profundezas das águas.',
        'Não me leve a corrente das águas, e não me absorva ao profundo, nem o poço cerre a sua boca sobre mim.',
        'Ouve-me, Senhor, pois boa é a tua misericórdia. Olha para mim segundo a tua muitíssima piedade.',
        'E não escondas o teu rosto do teu servo, porque estou angustiado; ouve-me depressa.',
        'Aproxima-te da minha alma, e resgata-a; livra-me por causa dos meus inimigos.',
        'Bem tens conhecido a minha afronta, e a minha vergonha, e a minha confusão; diante de ti estão todos os meus adversários.',
        'Afrontas me quebrantaram o coração, e estou fraquíssimo; esperei por alguém que tivesse compaixão, mas não houve nenhum; e por consoladores, mas não os achei.',
        'Deram-me fel por mantimento, e na minha sede me deram a beber vinagre.',
        'Torne-se-lhes a sua mesa diante deles em laço, e a prosperidade em armadilha.',
        'Escureçam-se-lhes os seus olhos, para que não vejam, e faze com que os seus lombos tremam constantemente.',
        'Derrama sobre eles a tua indignação, e prenda-os o ardor da tua ira.',
        'Fique desolado o seu palácio; e não haja quem habite nas suas tendas.',
        'Pois perseguem àquele a quem feriste, e conversam sobre a dor daqueles a quem chagaste.',
        'Acrescenta iniqüidade à iniqüidade deles, e não entrem na tua justiça.',
        'Sejam riscados do livro dos vivos, e não sejam inscritos com os justos.',
        'Eu, porém, sou pobre e estou triste; ponha-me a tua salvação, ó Deus, num alto retiro.',
        'Louvarei o nome de Deus com um cântico, e engrandecê-lo-ei com ação de graças.',
        'Isto será mais agradável ao Senhor do que boi, ou bezerro que tem chifres e unhas.',
        'Os mansos verão isto, e se agradarão; o vosso coração viverá, pois que buscais a Deus.',
        'Porque o Senhor ouve os necessitados, e não despreza os seus cativos.',
        'Louvem-no os céus e a terra, os mares e tudo quanto neles se move.',
        'Porque Deus salvará a Sião, e edificará as cidades de Judá; para que habitem ali e a possuam.',
        'E herdá-la-á a semente de seus servos, e os que amam o seu nome habitarão nela.',
      ],
      [
        'Apressa-te, ó Deus, em me livrar; SENHOR, apressa-te em ajudar-me.',
        'Fiquem envergonhados e confundidos os que procuram a minha alma; voltem para trás e confundam-se os que me desejam mal.',
        'Virem as costas como recompensa da sua vergonha os que dizem: Ah! Ah!',
        'Folguem e alegrem-se em ti todos os que te buscam; e aqueles que amam a tua salvação digam continuamente: Engrandecido seja Deus.',
        'Eu, porém, estou aflito e necessitado; apressa-te por mim, ó Deus. Tu és o meu auxílio e o meu libertador; Senhor, não te detenhas.',
      ],
      [
        'Em ti, SENHOR, confio; nunca seja eu confundido.',
        'Livra-me na tua justiça, e faze-me escapar; inclina os teus ouvidos para mim, e salva-me.',
        'Sê tu a minha habitação forte, à qual possa recorrer continuamente. Deste um mandamento que me salva, pois tu és a minha rocha e a minha fortaleza.',
        'Livra-me, meu Deus, das mãos do ímpio, das mãos do homem injusto e cruel.',
        'Pois tu és a minha esperança, Senhor DEUS; tu és a minha confiança desde a minha mocidade.',
        'Por ti tenho sido sustentado desde o ventre; tu és aquele que me tiraste das entranhas de minha mãe; o meu louvor será para ti constantemente.',
        'Sou como um prodígio para muitos, mas tu és o meu refúgio forte.',
        'Encha-se a minha boca do teu louvor e da tua glória todo o dia.',
        'Não me rejeites no tempo da velhice; não me desampares, quando se for acabando a minha força.',
        'Porque os meus inimigos falam contra mim, e os que espiam a minha alma consultam juntos,',
        'Dizendo: Deus o desamparou; persegui-o e tomai-o, pois não há quem o livre.',
        'Ó Deus, não te alongues de mim; meu Deus, apressa-te em ajudar-me.',
        'Sejam confundidos e consumidos os que são adversários da minha alma; cubram-se de opróbrio e de confusão aqueles que procuram o meu mal.',
        'Mas eu esperarei continuamente, e te louvarei cada vez mais.',
        'A minha boca manifestará a tua justiça e a tua salvação todo o dia, pois não conheço o número delas.',
        'Sairei na força do Senhor DEUS, farei menção da tua justiça, e só dela.',
        'Ensinaste-me, ó Deus, desde a minha mocidade; e até aqui tenho anunciado as tuas maravilhas.',
        'Agora também, quando estou velho e de cabelos brancos, não me desampares, ó Deus, até que tenha anunciado a tua força a esta geração, e o teu poder a todos os vindouros.',
        'Também a tua justiça, ó Deus, está muito alta, pois fizeste grandes coisas. Ó Deus, quem é semelhante a ti?',
        'Tu, que me tens feito ver muitos males e angústias, me darás ainda a vida, e me tirarás dos abismos da terra.',
        'Aumentarás a minha grandeza, e de novo me consolarás.',
        'Também eu te louvarei com o saltério, bem como à tua verdade, ó meu Deus; cantarei com harpa a ti, ó Santo de Israel.',
        'Os meus lábios exultarão quando eu te cantar, assim como a minha alma, que tu remiste.',
        'A minha língua falará da tua justiça todo o dia; pois estão confundidos e envergonhados aqueles que procuram o meu mal.',
      ],
      [
        'Ó Deus, dá ao rei os teus juízos, e a tua justiça ao filho do rei.',
        'Ele julgará ao teu povo com justiça, e aos teus pobres com juízo.',
        'Os montes trarão paz ao povo, e os outeiros, justiça.',
        'Julgará os aflitos do povo, salvará os filhos do necessitado, e quebrantará o opressor.',
        'Temer-te-ão enquanto durarem o sol e a lua, de geração em geração.',
        'Ele descerá como chuva sobre a erva ceifada, como os chuveiros que umedecem a terra.',
        'Nos seus dias florescerá o justo, e abundância de paz haverá enquanto durar a lua.',
        'Dominará de mar a mar, e desde o rio até às extremidades da terra.',
        'Aqueles que habitam no deserto se inclinarão ante ele, e os seus inimigos lamberão o pó.',
        'Os reis de Társis e das ilhas trarão presentes; os reis de Sabá e de Seba oferecerão dons.',
        'E todos os reis se prostrarão perante ele; todas as nações o servirão.',
        'Porque ele livrará ao necessitado quando clamar, como também ao aflito e ao que não tem quem o ajude.',
        'Compadecer-se-á do pobre e do aflito, e salvará as almas dos necessitados.',
        'Libertará as suas almas do engano e da violência, e precioso será o seu sangue aos olhos dele.',
        'E viverá, e se lhe dará do ouro de Sabá; e continuamente se fará por ele oração; e todos os dias o bendirão.',
        'Haverá um punhado de trigo na terra sobre as cabeças dos montes; o seu fruto se moverá como o Líbano, e os da cidade florescerão como a erva da terra.',
        'O seu nome permanecerá eternamente; o seu nome se irá propagando de pais a filhos enquanto o sol durar, e os homens serão abençoados nele; todas as nações lhe chamarão bem-aventurado.',
        'Bendito seja o Senhor Deus, o Deus de Israel, que só ele faz maravilhas.',
        'E bendito seja para sempre o seu nome glorioso; e encha-se toda a terra da sua glória. Amém e Amém.',
        'Aqui acabam as orações de Davi, filho de Jessé.',
      ],
      [
        'Verdadeiramente bom é Deus para com Israel, para com os limpos de coração.',
        'Quanto a mim, os meus pés quase que se desviaram; pouco faltou para que escorregassem os meus passos.',
        'Pois eu tinha inveja dos néscios, quando via a prosperidade dos ímpios.',
        'Porque não há apertos na sua morte, mas firme está a sua força.',
        'Não se acham em trabalhos como outros homens, nem são afligidos como outros homens.',
        'Por isso a soberba os cerca como um colar; vestem-se de violência como de adorno.',
        'Os olhos deles estão inchados de gordura; eles têm mais do que o coração podia desejar.',
        'São corrompidos e tratam maliciosamente de opressão; falam arrogantemente.',
        'Põem as suas bocas contra os céus, e as suas línguas andam pela terra.',
        'Por isso o povo dele volta aqui, e águas de copo cheio se lhes espremem.',
        'E eles dizem: Como o sabe Deus? Há conhecimento no Altíssimo?',
        'Eis que estes são ímpios, e prosperam no mundo; aumentam em riquezas.',
        'Na verdade que em vão tenho purificado o meu coração; e lavei as minhas mãos na inocência.',
        'Pois todo o dia tenho sido afligido, e castigado cada manhã.',
        'Se eu dissesse: Falarei assim; eis que ofenderia a geração de teus filhos.',
        'Quando pensava em entender isto, foi para mim muito doloroso;',
        'Até que entrei no santuário de Deus; então entendi eu o fim deles.',
        'Certamente tu os puseste em lugares escorregadios; tu os lanças em destruição.',
        'Como caem na desolação, quase num momento! Ficam totalmente consumidos de terrores.',
        'Como um sonho, quando se acorda, assim, ó Senhor, quando acordares, desprezarás a aparência deles.',
        'Assim o meu coração se azedou, e sinto picadas nos meus rins.',
        'Assim me embruteci, e nada sabia; fiquei como um animal perante ti.',
        'Todavia estou de contínuo contigo; tu me sustentaste pela minha mão direita.',
        'Guiar-me-ás com o teu conselho, e depois me receberás na glória.',
        'Quem tenho eu no céu senão a ti? e na terra não há quem eu deseje além de ti.',
        'A minha carne e o meu coração desfalecem; mas Deus é a fortaleza do meu coração, e a minha porção para sempre.',
        'Pois eis que os que se alongam de ti, perecerão; tu tens destruído todos aqueles que se desviam de ti.',
        'Mas para mim, bom é aproximar-me de Deus; pus a minha confiança no Senhor DEUS, para anunciar todas as tuas obras.',
      ],
      [
        'Ó Deus, por que nos rejeitaste para sempre? Por que se acende a tua ira contra as ovelhas do teu pasto?',
        'Lembra-te da tua congregação, que compraste desde a antiguidade; da vara da tua herança, que remiste; deste monte Sião, em que habitaste.',
        'Levanta os teus pés para as perpétuas assolações, para tudo o que o inimigo tem feito de mal no santuário.',
        'Os teus inimigos bramam no meio dos teus lugares santos; põem neles as suas insígnias por sinais.',
        'Um homem se tornava famoso, conforme houvesse levantado machados, contra a espessura do arvoredo.',
        'Mas agora toda obra entalhada de uma vez quebram com machados e martelos.',
        'Lançaram fogo no teu santuário; profanaram, derrubando-a até ao chão, a morada do teu nome.',
        'Disseram nos seus corações: Despojemo-los duma vez. Queimaram todos os lugares santos de Deus na terra.',
        'Já não vemos os nossos sinais, já não há profeta, nem há entre nós alguém que saiba até quando isto durará.',
        'Até quando, ó Deus, nos afrontará o adversário? Blasfemará o inimigo o teu nome para sempre?',
        'Porque retiras a tua mão, a saber, a tua destra? Tira-a de dentro do teu seio.',
        'Todavia Deus é o meu Rei desde a antiguidade, operando a salvação no meio da terra.',
        'Tu dividiste o mar pela tua força; quebrantaste as cabeças das baleias nas águas.',
        'Fizeste em pedaços as cabeças do leviatã, e o deste por mantimento aos habitantes do deserto.',
        'Fendeste a fonte e o ribeiro; secaste os rios impetuosos.',
        'Teu é o dia e tua é a noite; preparaste a luz e o sol.',
        'Estabeleceste todos os limites da terra; verão e inverno tu os formaste.',
        'Lembra-te disto: que o inimigo afrontou ao Senhor e que um povo louco blasfemou o teu nome.',
        'Não entregues às feras a alma da tua rola; não te esqueças para sempre da vida dos teus aflitos.',
        'Atende a tua aliança; pois os lugares tenebrosos da terra estão cheios de moradas de crueldade.',
        'Oh, não volte envergonhado o oprimido; louvem o teu nome o aflito e o necessitado.',
        'Levanta-te, ó Deus, pleiteia a tua própria causa; lembra-te da afronta que o louco te faz cada dia.',
        'Não te esqueças dos gritos dos teus inimigos; o tumulto daqueles que se levantam contra ti aumenta continuamente.',
      ],
      [
        'A ti, ó Deus, glorificamos, a ti damos louvor, pois o teu nome está perto, as tuas maravilhas o declaram.',
        'Quando eu ocupar o lugar determinado, julgarei retamente.',
        'A terra e todos os seus moradores estão dissolvidos, mas eu fortaleci as suas colunas. (Selá.)',
        'Disse eu aos loucos: Não enlouqueçais, e aos ímpios: Não levanteis a fronte;',
        'Não levanteis a vossa fronte altiva, nem faleis com cerviz dura.',
        'Porque nem do oriente, nem do ocidente, nem do deserto vem a exaltação.',
        'Mas Deus é o Juiz: a um abate, e a outro exalta.',
        'Porque na mão do Senhor há um cálice cujo vinho é tinto; está cheio de mistura; e dá a beber dele; mas as escórias dele todos os ímpios da terra as sorverão e beberão.',
        'E eu o declararei para sempre; cantarei louvores ao Deus de Jacó.',
        'E quebrarei todas as forças dos ímpios, mas as forças dos justos serão exaltadas.',
      ],
      [
        'Conhecido é Deus em Judá; grande é o seu nome em Israel.',
        'E em Salém está o seu tabernáculo, e a sua morada em Sião.',
        'Ali quebrou as flechas do arco; o escudo, e a espada, e a guerra. (Selá.)',
        'Tu és mais ilustre e glorioso do que os montes de caça.',
        'Os que são ousados de coração são despojados; dormiram o seu sono; e nenhum dos homens de força achou as próprias mãos.',
        'À tua repreensão, ó Deus de Jacó, carros e cavalos são lançados num sono profundo.',
        'Tu, tu és temível; e quem subsistirá à tua vista, uma vez que te irares?',
        'Desde os céus fizeste ouvir o teu juízo; a terra tremeu e se aquietou,',
        'Quando Deus se levantou para fazer juízo, para livrar a todos os mansos da terra. (Selá.)',
        'Certamente a cólera do homem redundará em teu louvor; o restante da cólera tu o restringirás.',
        'Fazei votos, e pagai ao Senhor vosso Deus; tragam presentes, os que estão em redor dele, àquele que é temível.',
        'Ele ceifará o espírito dos príncipes; é tremendo para com os reis da terra.',
      ],
      [
        'Clamei a Deus com a minha voz, a Deus levantei a minha voz, e ele inclinou para mim os ouvidos.',
        'No dia da minha angústia busquei ao Senhor; a minha mão se estendeu de noite, e não cessava; a minha alma recusava ser consolada.',
        'Lembrava-me de Deus, e me perturbei; queixava-me, e o meu espírito desfalecia. (Selá.)',
        'Sustentaste os meus olhos acordados; estou tão perturbado que não posso falar.',
        'Considerava os dias da antiguidade, os anos dos tempos antigos.',
        'De noite chamei à lembrança o meu cântico; meditei em meu coração, e o meu espírito esquadrinhou.',
        'Rejeitará o Senhor para sempre e não tornará a ser favorável?',
        'Cessou para sempre a sua benignidade? Acabou-se já a promessa de geração em geração?',
        'Esqueceu-se Deus de ter misericórdia? Ou encerrou ele as suas misericórdias na sua ira? (Selá.)',
        'E eu disse: Isto é enfermidade minha; mas eu me lembrarei dos anos da destra do Altíssimo.',
        'Eu me lembrarei das obras do Senhor; certamente que eu me lembrarei das tuas maravilhas da antiguidade.',
        'Meditarei também em todas as tuas obras, e falarei dos teus feitos.',
        'O teu caminho, ó Deus, está no santuário. Quem é Deus tão grande como o nosso Deus?',
        'Tu és o Deus que fazes maravilhas; tu fizeste notória a tua força entre os povos.',
        'Com o teu braço remiste o teu povo, os filhos de Jacó e de José. (Selá.)',
        'As águas te viram, ó Deus, as águas te viram, e tremeram; os abismos também se abalaram.',
        'As nuvens lançaram água, os céus deram um som; as tuas flechas correram duma para outra parte.',
        'A voz do teu trovão estava no céu; os relâmpagos iluminaram o mundo; a terra se abalou e tremeu.',
        'O teu caminho é no mar, e as tuas veredas nas águas grandes, e os teus passos não são conhecidos.',
        'Guiaste o teu povo, como a um rebanho, pela mão de Moisés e de Arão.',
      ],
      [
        'Escutai a minha lei, povo meu; inclinai os vossos ouvidos às palavras da minha boca.',
        'Abrirei a minha boca numa parábola; falarei enigmas da antiguidade.',
        'Os quais temos ouvido e sabido, e nossos pais no-los têm contado.',
        'Não os encobriremos aos seus filhos, mostrando à geração futura os louvores do Senhor, assim como a sua força e as maravilhas que fez.',
        'Porque ele estabeleceu um testemunho em Jacó, e pôs uma lei em Israel, a qual deu aos nossos pais para que a fizessem conhecer a seus filhos;',
        'Para que a geração vindoura a soubesse, os filhos que nascessem, os quais se levantassem e a contassem a seus filhos;',
        'Para que pusessem em Deus a sua esperança, e se não esquecessem das obras de Deus, mas guardassem os seus mandamentos.',
        'E não fossem como seus pais, geração contumaz e rebelde, geração que não regeu o seu coração, e cujo espírito não foi fiel a Deus.',
        'Os filhos de Efraim, armados e trazendo arcos, viraram as costas no dia da peleja.',
        'Não guardaram a aliança de Deus, e recusaram andar na sua lei;',
        'E esqueceram-se das suas obras e das maravilhas que lhes fizera ver.',
        'Maravilhas que ele fez à vista de seus pais na terra do Egito, no campo de Zoã.',
        'Dividiu o mar, e os fez passar por ele; fez com que as águas parassem como num montão.',
        'De dia os guiou por uma nuvem, e toda a noite por uma luz de fogo.',
        'Fendeu as penhas no deserto; e deu-lhes de beber como de grandes abismos.',
        'Fez sair fontes da rocha, e fez correr as águas como rios.',
        'E ainda prosseguiram em pecar contra ele, provocando ao Altíssimo na solidão.',
        'E tentaram a Deus nos seus corações, pedindo carne para o seu apetite.',
        'E falaram contra Deus, e disseram: Acaso pode Deus prepararnos uma mesa no deserto?',
        'Eis que feriu a penha, e águas correram dela: rebentaram ribeiros em abundância. Poderá também dar-nos pão, ou preparar carne para o seu povo?',
        'Portanto o Senhor os ouviu, e se indignou; e acendeu um fogo contra Jacó, e furor também subiu contra Israel;',
        'Porquanto não creram em Deus, nem confiaram na sua salvação;',
        'Ainda que mandara às altas nuvens, e abriu as portas dos céus,',
        'E chovera sobre eles o maná para comerem, e lhes dera do trigo do céu.',
        'O homem comeu o pão dos anjos; ele lhes mandou comida a fartar.',
        'Fez soprar o vento do oriente nos céus, e o trouxe do sul com a sua força.',
        'E choveu sobre eles carne como pó, e aves de asas como a areia do mar.',
        'E as fez cair no meio do seu arraial, ao redor de suas habitações.',
        'Então comeram e se fartaram bem; pois lhes cumpriu o seu desejo.',
        'Não refrearam o seu apetite. Ainda lhes estava a comida na boca,',
        'Quando a ira de Deus desceu sobre eles, e matou os mais robustos deles, e feriu os escolhidos de Israel.',
        'Com tudo isto ainda pecaram, e não deram crédito às suas maravilhas.',
        'Por isso consumiu os seus dias na vaidade e os seus anos na angústia.',
        'Quando os matava, então o procuravam; e voltavam, e de madrugada buscavam a Deus.',
        'E se lembravam de que Deus era a sua rocha, e o Deus Altíssimo o seu Redentor.',
        'Todavia lisonjeavam-no com a boca, e com a língua lhe mentiam.',
        'Porque o seu coração não era reto para com ele, nem foram fiéis na sua aliança.',
        'Ele, porém, que é misericordioso, perdoou a sua iniqüidade; e não os destruiu, antes muitas vezes desviou deles o seu furor, e não despertou toda a sua ira.',
        'Porque se lembrou de que eram de carne, vento que passa e não volta.',
        'Quantas vezes o provocaram no deserto, e o entristeceram na solidão!',
        'Voltaram atrás, e tentaram a Deus, e limitaram o Santo de Israel.',
        'Não se lembraram da sua mão, nem do dia em que os livrou do adversário;',
        'Como operou os seus sinais no Egito, e as suas maravilhas no campo de Zoã;',
        'E converteu os seus rios em sangue, e as suas correntes, para que não pudessem beber.',
        'Enviou entre eles enxames de moscas que os consumiram, e rãs que os destruíram.',
        'Deu também ao pulgão a sua novidade, e o seu trabalho aos gafanhotos.',
        'Destruiu as suas vinhas com saraiva, e os seus sicômoros com pedrisco.',
        'Também entregou o seu gado à saraiva, e os seus rebanhos aos coriscos.',
        'Lançou sobre eles o ardor da sua ira, furor, indignação, e angústia, mandando maus anjos contra eles.',
        'Preparou caminho à sua ira; não poupou as suas almas da morte, mas entregou à pestilência as suas vidas.',
        'E feriu a todo primogênito no Egito, primícias da sua força nas tendas de Cão.',
        'Mas fez com que o seu povo saísse como ovelhas, e os guiou pelo deserto como um rebanho.',
        'E os guiou com segurança, que não temeram; mas o mar cobriu os seus inimigos.',
        'E os trouxe até ao termo do seu santuário, até este monte que a sua destra adquiriu.',
        'E expulsou os gentios de diante deles, e lhes dividiu uma herança por linha, e fez habitar em suas tendas as tribos de Israel.',
        'Contudo tentaram e provocaram o Deus Altíssimo, e não guardaram os seus testemunhos.',
        'Mas retiraram-se para trás, e portaram-se infielmente como seus pais; viraram-se como um arco enganoso.',
        'Pois o provocaram à ira com os seus altos, e moveram o seu zelo com as suas imagens de escultura.',
        'Deus ouviu isto e se indignou; e aborreceu a Israel sobremodo.',
        'Por isso desamparou o tabernáculo em Siló, a tenda que estabeleceu entre os homens.',
        'E deu a sua força ao cativeiro, e a sua glória à mão do inimigo.',
        'E entregou o seu povo à espada, e se enfureceu contra a sua herança.',
        'O fogo consumiu os seus jovens, e as suas moças não foram dadas em casamento.',
        'Os seus sacerdotes caíram à espada, e as suas viúvas não fizeram lamentação.',
        'Então o Senhor despertou, como quem acaba de dormir, como um valente que se alegra com o vinho.',
        'E feriu os seus adversários por detrás, e pô-los em perpétuo desprezo.',
        'Além disto, recusou o tabernáculo de José, e não elegeu a tribo de Efraim.',
        'Antes elegeu a tribo de Judá; o monte Sião, que ele amava.',
        'E edificou o seu santuário como altos palácios, como a terra, que fundou para sempre.',
        'Também elegeu a Davi seu servo, e o tirou dos apriscos das ovelhas;',
        'E o tirou do cuidado das que se acharam prenhes; para apascentar a Jacó, seu povo, e a Israel, sua herança.',
        'Assim os apascentou, segundo a integridade do seu coração, e os guiou pela perícia de suas mãos.',
      ],
      [
        'Ó Deus, os gentios vieram à tua herança; contaminaram o teu santo templo; reduziram Jerusalém a montões de pedras.',
        'Deram os corpos mortos dos teus servos por comida às aves dos céus, e a carne dos teus santos às feras da terra.',
        'Derramaram o sangue deles como a água ao redor de Jerusalém, e não houve quem os enterrasse.',
        'Somos feitos opróbrio para nossos vizinhos, escárnio e zombaria para os que estão à roda de nós.',
        'Até quando, Senhor? Acaso te indignarás para sempre? Arderá o teu zelo como fogo?',
        'Derrama o teu furor sobre os gentios que não te conhecem, e sobre os reinos que não invocam o teu nome.',
        'Porque devoraram a Jacó, e assolaram as suas moradas.',
        'Não te lembres das nossas iniqüidades passadas; venham ao nosso encontro depressa as tuas misericórdias, pois já estamos muito abatidos.',
        'Ajuda-nos, ó Deus da nossa salvação, pela glória do teu nome; e livra-nos, e perdoa os nossos pecados por amor do teu nome.',
        'Porque diriam os gentios: Onde está o seu Deus? Seja ele conhecido entre os gentios, à nossa vista, pela vingança do sangue dos teus servos, que foi derramado.',
        'Venha perante a tua face o gemido dos presos; segundo a grandeza do teu braço preserva aqueles que estão sentenciados à morte.',
        'E torna aos nossos vizinhos, no seu regaço, sete vezes tanto da sua injúria com a qual te injuriaram, Senhor.',
        'Assim nós, teu povo e ovelhas de teu pasto, te louvaremos eternamente; de geração em geração cantaremos os teus louvores.',
      ],
      [
        'Tu, que és pastor de Israel, dá ouvidos; tu, que guias a José como a um rebanho; tu, que te assentas entre os querubins, resplandece.',
        'Perante Efraim, Benjamim e Manassés, desperta o teu poder, e vem salvar-nos.',
        'Faze-nos voltar, ó Deus, e faze resplandecer o teu rosto, e seremos salvos.',
        'Ó Senhor Deus dos Exércitos, até quando te indignarás contra a oração do teu povo?',
        'Tu os sustentas com pão de lágrimas, e lhes dás a beber lágrimas com abundância.',
        'Tu nos pões em contendas com os nossos vizinhos, e os nossos inimigos zombam de nós entre si.',
        'Faze-nos voltar, ó Deus dos Exércitos, e faze resplandecer o teu rosto, e seremos salvos.',
        'Trouxeste uma vinha do Egito; lançaste fora os gentios, e a plantaste.',
        'Preparaste-lhe lugar, e fizeste com que ela deitasse raízes, e encheu a terra.',
        'Os montes foram cobertos da sua sombra, e os seus ramos se fizeram como os formosos cedros.',
        'Ela estendeu a sua ramagem até ao mar, e os seus ramos até ao rio.',
        'Por que quebraste então os seus valados, de modo que todos os que passam por ela a vindimam?',
        'O javali da selva a devasta, e as feras do campo a devoram.',
        'Oh! Deus dos Exércitos, volta-te, nós te rogamos, atende dos céus, e vê, e visita esta vide;',
        'E a videira que a tua destra plantou, e o sarmento que fortificaste para ti.',
        'Está queimada pelo fogo, está cortada; pereceu pela repreensão da tua face.',
        'Seja a tua mão sobre o homem da tua destra, sobre o filho do homem, que fortificaste para ti.',
        'Assim nós não te viraremos as costas; guarda-nos em vida, e invocaremos o teu nome.',
        'Faze-nos voltar, Senhor Deus dos Exércitos; faze resplandecer o teu rosto, e seremos salvos.',
      ],
      [
        'Exultai a Deus, nossa fortaleza; jubilai ao Deus de Jacó.',
        'Tomai um salmo, e trazei junto o tamborim, a harpa suave e o saltério.',
        'Tocai a trombeta na lua nova, no tempo apontado da nossa solenidade.',
        'Porque isto era um estatuto para Israel, e uma lei do Deus de Jacó.',
        'Ordenou-o em José por testemunho, quando saíra pela terra do Egito, onde ouvi uma língua que não entendia.',
        'Tirei de seus ombros a carga; as suas mãos foram livres dos cestos.',
        'Clamaste na angústia, e te livrei; respondi-te no lugar oculto dos trovões; provei-te nas águas de Meribá. (Selá.)',
        'Ouve-me, povo meu, e eu te atestarei: Ah, Israel, se me ouvires!',
        'Não haverá entre ti deus alheio nem te prostrarás ante um deus estranho.',
        'Eu sou o Senhor teu Deus, que te tirei da terra do Egito; abre bem a tua boca, e ta encherei.',
        'Mas o meu povo não quis ouvir a minha voz, e Israel não me quis.',
        'Portanto eu os entreguei aos desejos dos seus corações, e andaram nos seus próprios conselhos.',
        'Oh! se o meu povo me tivesse ouvido! se Israel andasse nos meus caminhos!',
        'Em breve abateria os seus inimigos, e viraria a minha mão contra os seus adversários.',
        'Os que odeiam ao Senhor ter-se-lhe-iam sujeitado, e o seu tempo seria eterno.',
        'E o sustentaria com o trigo mais fino, e o fartaria com o mel saído da rocha.',
      ],
      [
        'Deus está na congregação dos poderosos; julga no meio dos deuses.',
        'Até quando julgareis injustamente, e aceitareis as pessoas dos ímpios? (Selá.)',
        'Fazei justiça ao pobre e ao órfão; justificai o aflito e o necessitado.',
        'Livrai o pobre e o necessitado; tirai-os das mãos dos ímpios.',
        'Eles não conhecem, nem entendem; andam em trevas; todos os fundamentos da terra vacilam.',
        'Eu disse: Vós sois deuses, e todos vós filhos do Altíssimo.',
        'Todavia morrereis como homens, e caireis como qualquer dos príncipes.',
        'Levanta-te, ó Deus, julga a terra, pois tu possuis todas as nações.',
      ],
      [
        'Ó Deus, não estejas em silêncio; não te cales, nem te aquietes, ó Deus,',
        'Porque eis que teus inimigos fazem tumulto, e os que te odeiam levantaram a cabeça.',
        'Tomaram astuto conselho contra o teu povo, e consultaram contra os teus escondidos.',
        'Disseram: Vinde, e desarraiguemo-los para que não sejam nação, nem haja mais memória do nome de Israel.',
        'Porque consultaram juntos e unânimes; eles se unem contra ti:',
        'As tendas de Edom, e dos ismaelitas, de Moabe, e dos agarenos,',
        'De Gebal, e de Amom, e de Amaleque, a Filístia, com os moradores de Tiro;',
        'Também a Assíria se ajuntou com eles; foram ajudar aos filhos de Ló. (Selá.)',
        'Faze-lhes como aos midianitas; como a Sísera, como a Jabim na ribeira de Quisom;',
        'Os quais pereceram em Endor; tornaram-se como estrume para a terra.',
        'Faze aos seus nobres como a Orebe, e como a Zeebe; e a todos os seus príncipes, como a Zebá e como a Zalmuna,',
        'Que disseram: Tomemos para nós as casas de Deus em possessão.',
        'Deus meu, faze-os como um tufão, como a aresta diante do vento.',
        'Como o fogo que queima um bosque, e como a chama que incendeia as brenhas,',
        'Assim os persegue com a tua tempestade, e os assombra com o teu torvelinho.',
        'Encham-se de vergonha as suas faces, para que busquem o teu nome, Senhor.',
        'Confundam-se e assombrem-se perpetuamente; envergonhem-se, e pereçam,',
        'Para que saibam que tu, a quem só pertence o nome de Senhor, és o Altíssimo sobre toda a terra.',
      ],
      [
        'Quão amáveis são os teus tabernáculos, SENHOR dos Exércitos!',
        'A minha alma está desejosa, e desfalece pelos átrios do Senhor; o meu coração e a minha carne clamam pelo Deus vivo.',
        'Até o pardal encontrou casa, e a andorinha ninho para si, onde ponha seus filhos, até mesmo nos teus altares, Senhor dos Exércitos, Rei meu e Deus meu.',
        'Bem-aventurados os que habitam em tua casa; louvar-te-ão continuamente. (Selá.)',
        'Bem-aventurado o homem cuja força está em ti, em cujo coração estão os caminhos aplanados.',
        'Que, passando pelo vale de Baca, faz dele uma fonte; a chuva também enche os tanques.',
        'Vão indo de força em força; cada um deles em Sião aparece perante Deus.',
        'Senhor Deus dos Exércitos, escuta a minha oração; inclina os ouvidos, ó Deus de Jacó! (Selá.)',
        'Olha, ó Deus, escudo nosso, e contempla o rosto do teu ungido.',
        'Porque vale mais um dia nos teus átrios do que mil. Preferiria estar à porta da casa do meu Deus, a habitar nas tendas dos ímpios.',
        'Porque o Senhor Deus é um sol e escudo; o Senhor dará graça e glória; não retirará bem algum aos que andam na retidão.',
        'Senhor dos Exércitos, bem-aventurado o homem que em ti põe a sua confiança.',
      ],
      [
        'Abençoaste, SENHOR, a tua terra; fizeste voltar o cativeiro de Jacó.',
        'Perdoaste a iniqüidade do teu povo; cobriste todos os seus pecados. (Selá.)',
        'Fizeste cessar toda a tua indignação; desviaste-te do ardor da tua ira.',
        'Torna-nos a trazer, ó Deus da nossa salvação, e faze cessar a tua ira de sobre nós.',
        'Acaso estarás sempre irado contra nós? Estenderás a tua ira a todas as gerações?',
        'Não tornarás a vivificar-nos, para que o teu povo se alegre em ti?',
        'Mostra-nos, Senhor, a tua misericórdia, e concede-nos a tua salvação.',
        'Escutarei o que Deus, o Senhor, falar; porque falará de paz ao seu povo, e aos santos, para que não voltem à loucura.',
        'Certamente que a salvação está perto daqueles que o temem, para que a glória habite na nossa terra.',
        'A misericórdia e a verdade se encontraram; a justiça e a paz se beijaram.',
        'A verdade brotará da terra, e a justiça olhará desde os céus.',
        'Também o Senhor dará o que é bom, e a nossa terra dará o seu fruto.',
        'A justiça irá adiante dele, e nos porá no caminho das suas pisadas.',
      ],
      [
        'Inclina, SENHOR, os teus ouvidos, e ouve-me, porque estou necessitado e aflito.',
        'Guarda a minha alma, pois sou santo: ó Deus meu, salva o teu servo, que em ti confia.',
        'Tem misericórdia de mim, ó Senhor, pois a ti clamo todo o dia.',
        'Alegra a alma do teu servo, pois a ti, Senhor, levanto a minha alma.',
        'Pois tu, Senhor, és bom, e pronto a perdoar, e abundante em benignidade para todos os que te invocam.',
        'Dá ouvidos, Senhor, à minha oração e atende à voz das minhas súplicas.',
        'No dia da minha angústia clamo a ti, porquanto me respondes.',
        'Entre os deuses não há semelhante a ti, Senhor, nem há obras como as tuas.',
        'Todas as nações que fizeste virão e se prostrarão perante a tua face, Senhor, e glorificarão o teu nome.',
        'Porque tu és grande e fazes maravilhas; só tu és Deus.',
        'Ensina-me, Senhor, o teu caminho, e andarei na tua verdade; une o meu coração ao temor do teu nome.',
        'Louvar-te-ei, Senhor Deus meu, com todo o meu coração, e glorificarei o teu nome para sempre.',
        'Pois grande é a tua misericórdia para comigo; e livraste a minha alma do inferno mais profundo.',
        'Ó Deus, os soberbos se levantaram contra mim, e as assembléias dos tiranos procuraram a minha alma, e não te puseram perante os seus olhos.',
        'Porém tu, Senhor, és um Deus cheio de compaixão, e piedoso, sofredor, e grande em benignidade e em verdade.',
        'Volta-te para mim, e tem misericórdia de mim; dá a tua fortaleza ao teu servo, e salva ao filho da tua serva.',
        'Mostra-me um sinal para bem, para que o vejam aqueles que me odeiam, e se confundam; porque tu, Senhor, me ajudaste e me consolaste.',
      ],
      [
        'O seu fundamento está nos montes santos.',
        'O Senhor ama as portas de Sião, mais do que todas as habitações de Jacó.',
        'Coisas gloriosas se dizem de ti, ó cidade de Deus. (Selá.)',
        'Farei menção de Raabe e de Babilônia àqueles que me conhecem; eis que da Filístia, e de Tiro, e da Etiópia, se dirá: Este homem nasceu ali.',
        'E de Sião se dirá: Este e aquele homem nasceram ali; e o mesmo Altíssimo a estabelecerá.',
        'O Senhor contará na descrição dos povos que este homem nasceu ali. (Selá.)',
        'Assim os cantores como os tocadores de instrumentos estarão lá; todas as minhas fontes estão em ti.',
      ],
      [
        'SENHOR Deus da minha salvação, diante de ti tenho clamado de dia e de noite.',
        'Chegue a minha oração perante a tua face, inclina os teus ouvidos ao meu clamor;',
        'Porque a minha alma está cheia de angústia, e a minha vida se aproxima da sepultura.',
        'Estou contado com aqueles que descem ao abismo; estou como homem sem forças,',
        'Livre entre os mortos, como os feridos de morte que jazem na sepultura, dos quais te não lembras mais, e estão cortados da tua mão.',
        'Puseste-me no abismo mais profundo, em trevas e nas profundezas.',
        'Sobre mim pesa o teu furor; tu me afligiste com todas as tuas ondas. (Selá.)',
        'Alongaste de mim os meus conhecidos, puseste-me em extrema abominação para com eles. Estou fechado, e não posso sair.',
        'A minha vista desmaia por causa da aflição. Senhor, tenho clamado a ti todo o dia, tenho estendido para ti as minhas mãos.',
        'Mostrarás, tu, maravilhas aos mortos, ou os mortos se levantarão e te louvarão? (Selá.)',
        'Será anunciada a tua benignidade na sepultura, ou a tua fidelidade na perdição?',
        'Saber-se-ão as tuas maravilhas nas trevas, e a tua justiça na terra do esquecimento?',
        'Eu, porém, Senhor, tenho clamado a ti, e de madrugada te esperará a minha oração.',
        'Senhor, porque rejeitas a minha alma? Por que escondes de mim a tua face?',
        'Estou aflito, e prestes tenho estado a morrer desde a minha mocidade; enquanto sofro os teus terrores, estou perturbado.',
        'A tua ardente indignação sobre mim vai passando; os teus terrores me têm retalhado.',
        'Eles me rodeiam todo o dia como água; eles juntos me sitiam.',
        'Desviaste para longe de mim amigos e companheiros, e os meus conhecidos estão em trevas.',
      ],
      [
        'As benignidades do SENHOR cantarei perpetuamente; com a minha boca manifestarei a tua fidelidade de geração em geração.',
        'Pois disse eu: A tua benignidade será edificada para sempre; tu confirmarás a tua fidelidade até nos céus, dizendo:',
        'Fiz uma aliança com o meu escolhido, e jurei ao meu servo Davi, dizendo:',
        'A tua semente estabelecerei para sempre, e edificarei o teu trono de geração em geração. (Selá.)',
        'E os céus louvarão as tuas maravilhas, ó Senhor, a tua fidelidade também na congregação dos santos.',
        'Pois quem no céu se pode igualar ao Senhor? Quem entre os filhos dos poderosos pode ser semelhante ao Senhor?',
        'Deus é muito formidável na assembléia dos santos, e para ser reverenciado por todos os que o cercam.',
        'Ó Senhor Deus dos Exércitos, quem é poderoso como tu, Senhor, com a tua fidelidade ao redor de ti?',
        'Tu dominas o ímpeto do mar; quando as suas ondas se levantam, tu as fazes aquietar.',
        'Tu quebraste a Raabe como se fora ferida de morte; espalhaste os teus inimigos com o teu braço forte.',
        'Teus são os céus, e tua é a terra; o mundo e a sua plenitude tu os fundaste.',
        'O norte e o sul tu os criaste; Tabor e Hermom jubilam em teu nome.',
        'Tu tens um braço poderoso; forte é a tua mão, e alta está a tua destra.',
        'Justiça e juízo são a base do teu trono; misericórdia e verdade irão adiante do teu rosto.',
        'Bem-aventurado o povo que conhece o som alegre; andará, ó Senhor, na luz da tua face.',
        'Em teu nome se alegrará todo o dia, e na tua justiça se exaltará.',
        'Pois tu és a glória da sua força; e no teu favor será exaltado o nosso poder.',
        'Porque o Senhor é a nossa defesa, e o Santo de Israel o nosso Rei.',
        'Então falaste em visão ao teu santo, e disseste: Pus o socorro sobre um que é poderoso; exaltei a um eleito do povo.',
        'Achei a Davi, meu servo; com santo óleo o ungi,',
        'Com o qual a minha mão ficará firme, e o meu braço o fortalecerá.',
        'O inimigo não o importunará, nem o filho da perversidade o afligirá.',
        'E eu derrubarei os seus inimigos perante a sua face, e ferirei aos que o odeiam.',
        'E a minha fidelidade e a minha benignidade estarão com ele; e em meu nome será exaltado o seu poder.',
        'Porei também a sua mão no mar, e a sua direita nos rios.',
        'Ele me chamará, dizendo: Tu és meu pai, meu Deus, e a rocha da minha salvação.',
        'Também o farei meu primogênito mais elevado do que os reis da terra.',
        'A minha benignidade lhe conservarei eu para sempre, e a minha aliança lhe será firme,',
        'E conservarei para sempre a sua semente, e o seu trono como os dias do céu.',
        'Se os seus filhos deixarem a minha lei, e não andarem nos meus juízos,',
        'Se profanarem os meus preceitos, e não guardarem os meus mandamentos,',
        'Então visitarei a sua transgressão com a vara, e a sua iniqüidade com açoites.',
        'Mas não retirarei totalmente dele a minha benignidade, nem faltarei à minha fidelidade.',
        'Não quebrarei a minha aliança, não alterarei o que saiu dos meus lábios.',
        'Uma vez jurei pela minha santidade que não mentirei a Davi.',
        'A sua semente durará para sempre, e o seu trono, como o sol diante de mim.',
        'Será estabelecido para sempre como a lua e como uma testemunha fiel no céu. (Selá.)',
        'Mas tu rejeitaste e aborreceste; tu te indignaste contra o teu ungido.',
        'Abominaste a aliança do teu servo; profanaste a sua coroa, lançando-a por terra.',
        'Derrubaste todos os seus muros; arruinaste as suas fortificações.',
        'Todos os que passam pelo caminho o despojam; é um opróbrio para os seus vizinhos.',
        'Exaltaste a destra dos seus adversários; fizeste com que todos os seus inimigos se regozijassem.',
        'Também embotaste o fio da sua espada, e não o sustentaste na peleja.',
        'Fizeste cessar a sua glória, e deitaste por terra o seu trono.',
        'Abreviaste os dias da sua mocidade; cobriste-o de vergonha. (Selá.)',
        'Até quando, Senhor? Acaso te esconderás para sempre? Arderá a tua ira como fogo?',
        'Lembra-te de quão breves são os meus dias; por que criarias debalde todos os filhos dos homens?',
        'Que homem há, que viva, e não veja a morte? Livrará ele a sua alma do poder da sepultura? (Selá.)',
        'Senhor, onde estão as tuas antigas benignidades que juraste a Davi pela tua verdade?',
        'Lembra-te, Senhor, do opróbrio dos teus servos; como eu trago no meu peito o opróbrio de todos os povos poderosos,',
        'Com o qual, Senhor, os teus inimigos têm difamado, com o qual têm difamado as pisadas do teu ungido.',
        'Bendito seja o Senhor para sempre. Amém, e Amém.',
      ],
      [
        'SENHOR, tu tens sido o nosso refúgio, de geração em geração.',
        'Antes que os montes nascessem, ou que tu formasses a terra e o mundo, mesmo de eternidade a eternidade, tu és Deus.',
        'Tu reduzes o homem à destruição; e dizes: Tornai-vos, filhos dos homens.',
        'Porque mil anos são aos teus olhos como o dia de ontem que passou, e como a vigília da noite.',
        'Tu os levas como uma corrente de água; são como um sono; de manhã são como a erva que cresce.',
        'De madrugada floresce e cresce; à tarde corta-se e seca.',
        'Pois somos consumidos pela tua ira, e pelo teu furor somos angustiados.',
        'Diante de ti puseste as nossas iniqüidades, os nossos pecados ocultos, à luz do teu rosto.',
        'Pois todos os nossos dias vão passando na tua indignação; passamos os nossos anos como um conto que se conta.',
        'Os dias da nossa vida chegam a setenta anos, e se alguns, pela sua robustez, chegam a oitenta anos, o orgulho deles é canseira e enfado, pois cedo se corta e vamos voando.',
        'Quem conhece o poder da tua ira? Segundo és tremendo, assim é o teu furor.',
        'Ensina-nos a contar os nossos dias, de tal maneira que alcancemos corações sábios.',
        'Volta-te para nós, Senhor; até quando? Aplaca-te para com os teus servos.',
        'Farta-nos de madrugada com a tua benignidade, para que nos regozijemos, e nos alegremos todos os nossos dias.',
        'Alegra-nos pelos dias em que nos afligiste, e pelos anos em que vimos o mal.',
        'Apareça a tua obra aos teus servos, e a tua glória sobre seus filhos.',
        'E seja sobre nós a formosura do Senhor nosso Deus, e confirma sobre nós a obra das nossas mãos; sim, confirma a obra das nossas mãos.',
      ],
      [
        'Aquele que habita no esconderijo do Altíssimo, à sombra do Onipotente descansará.',
        'Direi do Senhor: Ele é o meu Deus, o meu refúgio, a minha fortaleza, e nele confiarei.',
        'Porque ele te livrará do laço do passarinheiro, e da peste perniciosa.',
        'Ele te cobrirá com as suas penas, e debaixo das suas asas te confiarás; a sua verdade será o teu escudo e broquel.',
        'Não terás medo do terror de noite nem da seta que voa de dia,.',
        'Nem da peste que anda na escuridão, nem da mortandade que assola ao meio-dia.',
        'Mil cairão ao teu lado, e dez mil à tua direita, mas não chegará a ti.',
        'Somente com os teus olhos contemplarás, e verás a recompensa dos ímpios.',
        'Porque tu, ó Senhor, és o meu refúgio. No Altíssimo fizeste a tua habitação.',
        'Nenhum mal te sucederá, nem praga alguma chegará à tua tenda.',
        'Porque aos seus anjos dará ordem a teu respeito, para te guardarem em todos os teus caminhos.',
        'Eles te sustentarão nas suas mãos, para que não tropeces com o teu pé em pedra.',
        'Pisarás o leão e a cobra; calcarás aos pés o filho do leão e a serpente.',
        'Porquanto tão encarecidamente me amou, também eu o livrarei; pô-lo-ei em retiro alto, porque conheceu o meu nome.',
        'Ele me invocará, e eu lhe responderei; estarei com ele na angústia; dela o retirarei, e o glorificarei.',
        'Fartá-lo-ei com longura de dias, e lhe mostrarei a minha salvação.',
      ],
      [
        'Bom é louvar ao SENHOR, e cantar louvores ao teu nome, ó Altíssimo;',
        'Para de manhã anunciar a tua benignidade, e todas as noites a tua fidelidade;',
        'Sobre um instrumento de dez cordas, e sobre o saltério; sobre a harpa com som solene.',
        'Pois tu, Senhor, me alegraste pelos teus feitos; exultarei nas obras das tuas mãos.',
        'Quão grandes são, Senhor, as tuas obras! Mui profundos são os teus pensamentos.',
        'O homem brutal não conhece, nem o louco entende isto.',
        'Quando o ímpio crescer como a erva, e quando florescerem todos os que praticam a iniqüidade, é que serão destruídos perpetuamente.',
        'Mas tu, Senhor, és o Altíssimo para sempre.',
        'Pois eis que os teus inimigos, Senhor, eis que os teus inimigos perecerão; serão dispersos todos os que praticam a iniqüidade.',
        'Porém tu exaltarás o meu poder, como o do boi selvagem. Serei ungido com óleo fresco.',
        'Os meus olhos verão o meu desejo sobre os meus inimigos, e os meus ouvidos ouvirão o meu desejo acerca dos malfeitores que se levantam contra mim.',
        'O justo florescerá como a palmeira; crescerá como o cedro no Líbano.',
        'Os que estão plantados na casa do Senhor florescerão nos átrios do nosso Deus.',
        'Na velhice ainda darão frutos; serão viçosos e vigorosos,',
        'Para anunciar que o Senhor é reto. Ele é a minha rocha e nele não há injustiça.',
      ],
      [
        'O SENHOR reina; está vestido de majestade. O SENHOR se revestiu e cingiu de poder; o mundo também está firmado, e não poderá vacilar.',
        'O teu trono está firme desde então; tu és desde a eternidade.',
        'Os rios levantam, ó Senhor, os rios levantam o seu ruído, os rios levantam as suas ondas.',
        'Mas o Senhor nas alturas é mais poderoso do que o ruído das grandes águas e do que as grandes ondas do mar.',
        'Mui fiéis são os teus testemunhos; a santidade convém à tua casa, Senhor, para sempre.',
      ],
      [
        'Ó Senhor Deus, a quem a vingança pertence, ó Deus, a quem a vingança pertence, mostra-te resplandecente.',
        'Exalta-te, tu, que és juiz da terra; dá a paga aos soberbos.',
        'Até quando os ímpios, Senhor, até quando os ímpios saltarão de prazer?',
        'Até quando proferirão, e falarão coisas duras, e se gloriarão todos os que praticam a iniqüidade?',
        'Reduzem a pedaços o teu povo, ó Senhor, e afligem a tua herança.',
        'Matam a viúva e o estrangeiro, e ao órfão tiram a vida.',
        'Contudo dizem: O Senhor não o verá; nem para isso atenderá o Deus de Jacó.',
        'Atendei, ó brutais dentre o povo; e vós, loucos, quando sereis sábios?',
        'Aquele que fez o ouvido não ouvirá? E o que formou o olho, não verá?',
        'Aquele que argüi os gentios não castigará? E o que ensina ao homem o conhecimento, não saberá?',
        'O Senhor conhece os pensamentos do homem, que são vaidade.',
        'Bem-aventurado é o homem a quem tu castigas, ó Senhor, e a quem ensinas a tua lei;',
        'Para lhe dares descanso dos dias maus, até que se abra a cova para o ímpio.',
        'Pois o Senhor não rejeitará o seu povo, nem desamparará a sua herança.',
        'Mas o juízo voltará à retidão, e segui-lo-ão todos os retos de coração.',
        'Quem será por mim contra os malfeitores? Quem se porá por mim contra os que praticam a iniqüidade?',
        'Se o Senhor não tivera ido em meu auxílio, a minha alma quase que teria ficado no silêncio.',
        'Quando eu disse: O meu pé vacila; a tua benignidade, Senhor, me susteve.',
        'Na multidão dos meus pensamentos dentro de mim, as tuas consolações recrearam a minha alma.',
        'Porventura o trono de iniqüidade te acompanha, o qual forja o mal por uma lei?',
        'Eles se ajuntam contra a alma do justo, e condenam o sangue inocente.',
        'Mas o Senhor é a minha defesa; e o meu Deus é a rocha do meu refúgio.',
        'E trará sobre eles a sua própria iniqüidade; e os destruirá na sua própria malícia; o Senhor nosso Deus os destruirá.',
      ],
      [
        'Vinde, cantemos ao SENHOR; jubilemos à rocha da nossa salvação.',
        'Apresentemo-nos ante a sua face com louvores, e celebremo-lo com salmos.',
        'Porque o Senhor é Deus grande, e Rei grande sobre todos os deuses.',
        'Nas suas mãos estão as profundezas da terra, e as alturas dos montes são suas.',
        'Seu é o mar, e ele o fez, e as suas mãos formaram a terra seca.',
        'Ó, vinde, adoremos e prostremo-nos; ajoelhemos diante do Senhor que nos criou.',
        'Porque ele é o nosso Deus, e nós povo do seu pasto e ovelhas da sua mão. Se hoje ouvirdes a sua voz,',
        'Não endureçais os vossos corações, assim como na provocação e como no dia da tentação no deserto;',
        'Quando vossos pais me tentaram, me provaram, e viram a minha obra.',
        'Quarenta anos estive desgostado com esta geração, e disse: É um povo que erra de coração, e não tem conhecido os meus caminhos.',
        'A quem jurei na minha ira que não entrarão no meu repouso.',
      ],
      [
        'Cantai ao SENHOR um cântico novo, cantai ao SENHOR toda a terra.',
        'Cantai ao Senhor, bendizei o seu nome; anunciai a sua salvação de dia em dia.',
        'Anunciai entre as nações a sua glória; entre todos os povos as suas maravilhas.',
        'Porque grande é o Senhor, e digno de louvor, mais temível do que todos os deuses.',
        'Porque todos os deuses dos povos são ídolos, mas o Senhor fez os céus.',
        'Glória e majestade estão ante a sua face, força e formosura no seu santuário.',
        'Dai ao Senhor, ó famílias dos povos, dai ao Senhor glória e força.',
        'Dai ao Senhor a glória devida ao seu nome; trazei oferenda, e entrai nos seus átrios.',
        'Adorai ao Senhor na beleza da santidade; tremei diante dele toda a terra.',
        'Dizei entre os gentios que o Senhor reina. O mundo também se firmará para que se não abale; julgará os povos com retidão.',
        'Alegrem-se os céus, e regozije-se a terra; brame o mar e a sua plenitude.',
        'Alegre-se o campo com tudo o que há nele; então se regozijarão todas as árvores do bosque,',
        'Ante a face do Senhor, porque vem, porque vem a julgar a terra; julgará o mundo com justiça e os povos com a sua verdade.',
      ],
      [
        'O SENHOR reina; regozije-se a terra; alegrem-se as muitas ilhas.',
        'Nuvens e escuridão estão ao redor dele; justiça e juízo são a base do seu trono.',
        'Um fogo vai adiante dele, e abrasa os seus inimigos em redor.',
        'Os seus relâmpagos iluminam o mundo; a terra viu e tremeu.',
        'Os montes derretem como cera na presença do Senhor, na presença do Senhor de toda a terra.',
        'Os céus anunciam a sua justiça, e todos os povos vêem a sua glória.',
        'Confundidos sejam todos os que servem imagens de escultura, que se gloriam de ídolos; prostrai-vos diante dele todos os deuses.',
        'Sião ouviu e se alegrou; e os filhos de Judá se alegraram por causa da tua justiça, ó Senhor.',
        'Pois tu, Senhor, és o mais alto sobre toda a terra; tu és muito mais exaltado do que todos os deuses.',
        'Vós, que amais ao Senhor, odiai o mal. Ele guarda as almas dos seus santos; ele os livra das mãos dos ímpios.',
        'A luz semeia-se para o justo, e a alegria para os retos de coração.',
        'Alegrai-vos, ó justos, no Senhor, e dai louvores à memória da sua santidade.',
      ],
      [
        'Cantai ao SENHOR um cântico novo, porque fez maravilhas; a sua destra e o seu braço santo lhe alcançaram a salvação.',
        'O Senhor fez notória a sua salvação, manifestou a sua justiça perante os olhos dos gentios.',
        'Lembrou-se da sua benignidade e da sua verdade para com a casa de Israel; todas as extremidades da terra viram a salvação do nosso Deus.',
        'Exultai no Senhor toda a terra; exclamai e alegrai-vos de prazer, e cantai louvores.',
        'Cantai louvores ao Senhor com a harpa; com a harpa e a voz do canto.',
        'Com trombetas e som de cornetas, exultai perante a face do Senhor, do Rei.',
        'Brame o mar e a sua plenitude; o mundo, e os que nele habitam.',
        'Os rios batam as palmas; regozijem-se também as montanhas,',
        'Perante a face do Senhor, porque vem a julgar a terra; com justiça julgará o mundo, e o povo com eqüidade.',
      ],
      [
        'O SENHOR reina; tremam os povos. Ele está assentado entre os querubins; comova-se a terra.',
        'O Senhor é grande em Sião, e mais alto do que todos os povos.',
        'Louvem o teu nome, grande e tremendo, pois é santo.',
        'Também o poder do Rei ama o juízo; tu firmas a eqüidade, fazes juízo e justiça em Jacó.',
        'Exaltai ao Senhor nosso Deus, e prostrai-vos diante do escabelo de seus pés, pois é santo.',
        'Moisés e Arão, entre os seus sacerdotes, e Samuel entre os que invocam o seu nome, clamavam ao Senhor, e Ele lhes respondia.',
        'Na coluna de nuvem lhes falava; eles guardaram os seus testemunhos, e os estatutos que lhes dera.',
        'Tu os escutaste, Senhor nosso Deus: tu foste um Deus que lhes perdoaste, ainda que tomaste vingança dos seus feitos.',
        'Exaltai ao Senhor nosso Deus e adorai-o no seu monte santo, pois o Senhor nosso Deus é santo.',
      ],
      [
        'Celebrai com júbilo ao SENHOR, todas as terras.',
        'Servi ao Senhor com alegria; e entrai diante dele com canto.',
        'Sabei que o Senhor é Deus; foi ele que nos fez, e não nós a nós mesmos; somos povo seu e ovelhas do seu pasto.',
        'Entrai pelas portas dele com gratidão, e em seus átrios com louvor; louvai-o, e bendizei o seu nome.',
        'Porque o Senhor é bom, e eterna a sua misericórdia; e a sua verdade dura de geração em geração.',
      ],
      [
        'Cantarei a misericórdia e o juízo; a ti, SENHOR, cantarei.',
        'Portar-me-ei com inteligência no caminho reto. Quando virás a mim? Andarei em minha casa com um coração sincero.',
        'Não porei coisa má diante dos meus olhos. Odeio a obra daqueles que se desviam; não se me pegará a mim.',
        'Um coração perverso se apartará de mim; não conhecerei o homem mau.',
        'Aquele que murmura do seu próximo às escondidas, eu o destruirei; aquele que tem olhar altivo e coração soberbo, não suportarei.',
        'Os meus olhos estarão sobre os fiéis da terra, para que se assentem comigo; o que anda num caminho reto, esse me servirá.',
        'O que usa de engano não ficará dentro da minha casa; o que fala mentiras não estará firme perante os meus olhos.',
        'Pela manhã destruirei todos os ímpios da terra, para desarraigar da cidade do Senhor todos os que praticam a iniqüidade.',
      ],
      [
        'SENHOR, ouve a minha oração, e chegue a ti o meu clamor.',
        'Não escondas de mim o teu rosto no dia da minha angústia, inclina para mim os teus ouvidos; no dia em que eu clamar, ouve-me depressa.',
        'Porque os meus dias se consomem como a fumaça, e os meus ossos ardem como lenha.',
        'O meu coração está ferido e seco como a erva, por isso me esqueço de comer o meu pão.',
        'Por causa da voz do meu gemido os meus ossos se apegam à minha pele.',
        'Sou semelhante ao pelicano no deserto; sou como um mocho nas solidões.',
        'Vigio, sou como o pardal solitário no telhado.',
        'Os meus inimigos me afrontam todo o dia; os que se enfurecem contra mim têm jurado contra mim.',
        'Pois tenho comido cinza como pão, e misturado com lágrimas a minha bebida,',
        'Por causa da tua ira e da tua indignação, pois tu me levantaste e me arremessaste.',
        'Os meus dias são como a sombra que declina, e como a erva me vou secando.',
        'Mas tu, Senhor, permanecerás para sempre, a tua memória de geração em geração.',
        'Tu te levantarás e terás piedade de Sião; pois o tempo de te compadeceres dela, o tempo determinado, já chegou.',
        'Porque os teus servos têm prazer nas suas pedras, e se compadecem do seu pó.',
        'Então os gentios temerão o nome do Senhor, e todos os reis da terra a tua glória.',
        'Quando o Senhor edificar a Sião, aparecerá na sua glória.',
        'Ele atenderá à oração do desamparado, e não desprezará a sua oração.',
        'Isto se escreverá para a geração futura; e o povo que se criar louvará ao Senhor.',
        'Pois olhou desde o alto do seu santuário, desde os céus o Senhor contemplou a terra,',
        'Para ouvir o gemido dos presos, para soltar os sentenciados à morte;',
        'Para anunciarem o nome do Senhor em Sião, e o seu louvor em Jerusalém,',
        'Quando os povos se ajuntarem, e os reinos, para servirem ao Senhor.',
        'Abateu a minha força no caminho; abreviou os meus dias.',
        'Dizia eu: Meu Deus, não me leves no meio dos meus dias, os teus anos são por todas as gerações.',
        'Desde a antiguidade fundaste a terra, e os céus são obra das tuas mãos.',
        'Eles perecerão, mas tu permanecerás; todos eles se envelhecerão como um vestido; como roupa os mudarás, e ficarão mudados.',
        'Porém tu és o mesmo, e os teus anos nunca terão fim.',
        'Os filhos dos teus servos continuarão, e a sua semente ficará firmada perante ti.',
      ],
      [
        'Bendize, ó minha alma, ao SENHOR, e tudo o que há em mim bendiga o seu santo nome.',
        'Bendize, ó minha alma, ao Senhor, e não te esqueças de nenhum de seus benefícios.',
        'Ele é o que perdoa todas as tuas iniqüidades, que sara todas as tuas enfermidades,',
        'Que redime a tua vida da perdição; que te coroa de benignidade e de misericórdia,',
        'Que farta a tua boca de bens, de sorte que a tua mocidade se renova como a da águia.',
        'O Senhor faz justiça e juízo a todos os oprimidos.',
        'Fez conhecidos os seus caminhos a Moisés, e os seus feitos aos filhos de Israel.',
        'Misericordioso e piedoso é o Senhor; longânimo e grande em benignidade.',
        'Não reprovará perpetuamente, nem para sempre reterá a sua ira.',
        'Não nos tratou segundo os nossos pecados, nem nos recompensou segundo as nossas iniqüidades.',
        'Pois assim como o céu está elevado acima da terra, assim é grande a sua misericórdia para com os que o temem.',
        'Assim como está longe o oriente do ocidente, assim afasta de nós as nossas transgressões.',
        'Assim como um pai se compadece de seus filhos, assim o Senhor se compadece daqueles que o temem.',
        'Pois ele conhece a nossa estrutura; lembra-se de que somos pó.',
        'Quanto ao homem, os seus dias são como a erva, como a flor do campo assim floresce.',
        'Passando por ela o vento, logo se vai, e o seu lugar não será mais conhecido.',
        'Mas a misericórdia do Senhor é desde a eternidade e até a eternidade sobre aqueles que o temem, e a sua justiça sobre os filhos dos filhos;',
        'Sobre aqueles que guardam a sua aliança, e sobre os que se lembram dos seus mandamentos para os cumprir.',
        'O Senhor tem estabelecido o seu trono nos céus, e o seu reino domina sobre tudo.',
        'Bendizei ao Senhor, todos os seus anjos, vós que excedeis em força, que guardais os seus mandamentos, obedecendo à voz da sua palavra.',
        'Bendizei ao Senhor, todos os seus exércitos, vós ministros seus, que executais o seu beneplácito.',
        'Bendizei ao Senhor, todas as suas obras, em todos os lugares do seu domínio; bendize, ó minha alma, ao Senhor.',
      ],
      [
        'Bendize, ó minha alma, ao SENHOR! SENHOR Deus meu, tu és magnificentíssimo; estás vestido de glória e de majestade.',
        'Ele se cobre de luz como de um vestido, estende os céus como uma cortina.',
        'Põe nas águas as vigas das suas câmaras; faz das nuvens o seu carro, anda sobre as asas do vento.',
        'Faz dos seus anjos espíritos, dos seus ministros um fogo abrasador.',
        'Lançou os fundamentos da terra; ela não vacilará em tempo algum.',
        'Tu a cobriste com o abismo, como com um vestido; as águas estavam sobre os montes.',
        'À tua repreensão fugiram; à voz do teu trovão se apressaram.',
        'Subiram aos montes, desceram aos vales, até ao lugar que para elas fundaste.',
        'Termo lhes puseste, que não ultrapassarão, para que não tornem mais a cobrir a terra.',
        'Tu, que fazes sair as fontes nos vales, as quais correm entre os montes.',
        'Dão de beber a todo o animal do campo; os jumentos monteses matam a sua sede.',
        'Junto delas as aves do céu terão a sua habitação, cantando entre os ramos.',
        'Ele rega os montes desde as suas câmaras; a terra farta-se do fruto das suas obras.',
        'Faz crescer a erva para o gado, e a verdura para o serviço do homem, para fazer sair da terra o pão,',
        'E o vinho que alegra o coração do homem, e o azeite que faz reluzir o seu rosto, e o pão que fortalece o coração do homem.',
        'As árvores do Senhor fartam-se de seiva, os cedros do Líbano que ele plantou,',
        'Onde as aves se aninham; quanto à cegonha, a sua casa é nas faias.',
        'Os altos montes são para as cabras monteses, e os rochedos são refúgio para os coelhos.',
        'Designou a lua para as estações; o sol conhece o seu ocaso.',
        'Ordenas a escuridão, e faz-se noite, na qual saem todos os animais da selva.',
        'Os leõezinhos bramam pela presa, e de Deus buscam o seu sustento.',
        'Nasce o sol e logo se acolhem, e se deitam nos seus covis.',
        'Então sai o homem à sua obra e ao seu trabalho, até à tarde.',
        'Ó Senhor, quão variadas são as tuas obras! Todas as coisas fizeste com sabedoria; cheia está a terra das tuas riquezas.',
        'Assim é este mar grande e muito espaçoso, onde há seres sem número, animais pequenos e grandes.',
        'Ali andam os navios; e o leviatã que formaste para nele folgar.',
        'Todos esperam de ti, que lhes dês o seu sustento em tempo oportuno.',
        'Dando-lho tu, eles o recolhem; abres a tua mão, e se enchem de bens.',
        'Escondes o teu rosto, e ficam perturbados; se lhes tiras o fôlego, morrem, e voltam para o seu pó.',
        'Envias o teu Espírito, e são criados, e assim renovas a face da terra.',
        'A glória do Senhor durará para sempre; o Senhor se alegrará nas suas obras.',
        'Olhando ele para a terra, ela treme; tocando nos montes, logo fumegam.',
        'Cantarei ao Senhor enquanto eu viver; cantarei louvores ao meu Deus, enquanto eu tiver existência.',
        'A minha meditação acerca dele será suave; eu me alegrarei no Senhor.',
        'Desapareçam da terra os pecadores, e os ímpios não sejam mais. Bendize, ó minha alma, ao Senhor. Louvai ao Senhor.',
      ],
      [
        'Louvai ao SENHOR, e invocai o seu nome; fazei conhecidas as suas obras entre os povos.',
        'Cantai-lhe, cantai-lhe salmos; falai de todas as suas maravilhas.',
        'Gloriai-vos no seu santo nome; alegre-se o coração daqueles que buscam ao Senhor.',
        'Buscai ao Senhor e a sua força; buscai a sua face continuamente.',
        'Lembrai-vos das maravilhas que fez, dos seus prodígios e dos juízos da sua boca;',
        'Vós, semente de Abraão, seu servo, vós, filhos de Jacó, seus escolhidos.',
        'Ele é o Senhor nosso Deus; os seus juízos estão em toda a terra.',
        'Lembrou-se da sua aliança para sempre, da palavra que mandou a milhares de gerações.',
        'A qual aliança fez com Abraão, e o seu juramento a Isaque.',
        'E confirmou o mesmo a Jacó por lei, e a Israel por aliança eterna,',
        'Dizendo: A ti darei a terra de Canaã, a região da vossa herança.',
        'Quando eram poucos homens em número, sim, mui poucos, e estrangeiros nela;',
        'Quando andavam de nação em nação e dum reino para outro povo;',
        'Não permitiu a ninguém que os oprimisse, e por amor deles repreendeu a reis, dizendo:',
        'Não toqueis os meus ungidos, e não maltrateis os meus profetas.',
        'Chamou a fome sobre a terra, quebrantou todo o sustento do pão.',
        'Mandou perante eles um homem, José, que foi vendido por escravo;',
        'Cujos pés apertaram com grilhões; foi posto em ferros;',
        'Até ao tempo em que chegou a sua palavra; a palavra do Senhor o provou.',
        'Mandou o rei, e o fez soltar; o governador dos povos, e o soltou.',
        'Fê-lo senhor da sua casa, e governador de toda a sua fazenda;',
        'Para sujeitar os seus príncipes a seu gosto, e instruir os seus anciãos.',
        'Então Israel entrou no Egito, e Jacó peregrinou na terra de Cão.',
        'E aumentou o seu povo em grande maneira, e o fez mais poderoso do que os seus inimigos.',
        'Virou o coração deles para que odiassem o seu povo, para que tratassem astutamente aos seus servos.',
        'Enviou Moisés, seu servo, e Arão, a quem escolhera.',
        'Mostraram entre eles os seus sinais e prodígios, na terra de Cão.',
        'Mandou trevas, e a fez escurecer; e não foram rebeldes à sua palavra.',
        'Converteu as suas águas em sangue, e matou os seus peixes.',
        'A sua terra produziu rãs em abundância, até nas câmaras dos seus reis.',
        'Falou ele, e vieram enxames de moscas e piolhos em todo o seu termo.',
        'Converteu as suas chuvas em saraiva, e fogo abrasador na sua terra.',
        'Feriu as suas vinhas e os seus figueirais, e quebrou as árvores dos seus termos.',
        'Falou ele e vieram gafanhotos e pulgão sem número.',
        'E comeram toda a erva da sua terra, e devoraram o fruto dos seus campos.',
        'Feriu também a todos os primogênitos da sua terra, as primícias de todas as suas forças.',
        'E tirou-os para fora com prata e ouro, e entre as suas tribos não houve um só fraco.',
        'O Egito se alegrou quando eles saíram, porque o seu temor caíra sobre eles.',
        'Estendeu uma nuvem por coberta, e um fogo para iluminar de noite.',
        'Oraram, e ele fez vir codornizes, e os fartou de pão do céu.',
        'Abriu a penha, e dela correram águas; correram pelos lugares secos, como um rio.',
        'Porque se lembrou da sua santa palavra, e de Abraão, seu servo.',
        'E tirou dali o seu povo com alegria, e os seus escolhidos com regozijo.',
        'E deu-lhes as terras dos gentios; e herdaram o trabalho dos povos;',
        'Para que guardassem os seus preceitos, e observassem as suas leis. Louvai ao Senhor.',
      ],
      [
        'Louvai ao SENHOR. Louvai ao SENHOR, porque ele é bom, porque a sua misericórdia dura para sempre.',
        'Quem pode contar as obras poderosas do Senhor? Quem anunciará os seus louvores?',
        'Bem-aventurados os que guardam o juízo, o que pratica justiça em todos os tempos.',
        'Lembra-te de mim, Senhor, segundo a tua boa vontade para com o teu povo; visita-me com a tua salvação.',
        'Para que eu veja os bens de teus escolhidos, para que eu me alegre com a alegria da tua nação, para que me glorie com a tua herança.',
        'Nós pecamos como os nossos pais, cometemos a iniqüidade, andamos perversamente.',
        'Nossos pais não entenderam as tuas maravilhas no Egito; não se lembraram da multidão das tuas misericórdias; antes o provocaram no mar, sim no Mar Vermelho.',
        'Não obstante, ele os salvou por amor do seu nome, para fazer conhecido o seu poder.',
        'Repreendeu, também, o Mar Vermelho, e este se secou, e os fez caminhar pelos abismos como pelo deserto.',
        'E os livrou da mão daquele que os odiava, e os remiu da mão do inimigo.',
        'E as águas cobriram os seus adversários; nem um só deles ficou.',
        'Então creram nas suas palavras, e cantaram os seus louvores.',
        'Porém cedo se esqueceram das suas obras; não esperaram o seu conselho.',
        'Mas deixaram-se levar à cobiça no deserto, e tentaram a Deus na solidão.',
        'E ele lhes cumpriu o seu desejo, mas enviou magreza às suas almas.',
        'E invejaram a Moisés no campo, e a Arão, o santo do Senhor.',
        'Abriu-se a terra, e engoliu a Datã, e cobriu o grupo de Abirão.',
        'E um fogo se acendeu no seu grupo; a chama abrasou os ímpios.',
        'Fizeram um bezerro em Horebe e adoraram a imagem fundida.',
        'E converteram a sua glória na figura de um boi que come erva.',
        'Esqueceram-se de Deus, seu Salvador, que fizera grandezas no Egito,',
        'Maravilhas na terra de Cão, coisas tremendas no Mar Vermelho.',
        'Por isso disse que os destruiria, não houvesse Moisés, seu escolhido, ficado perante ele na brecha, para desviar a sua indignação, a fim de não os destruir.',
        'Também desprezaram a terra aprazível; não creram na sua palavra.',
        'Antes murmuraram nas suas tendas, e não deram ouvidos à voz do Senhor.',
        'Por isso levantou a sua mão contra eles, para os derrubar no deserto;',
        'Para derrubar também a sua semente entre as nações, e espalhá-los pelas terras.',
        'Também se juntaram com Baal-Peor, e comeram os sacrifícios dos mortos.',
        'Assim o provocaram à ira com as suas invenções; e a peste rebentou entre eles.',
        'Então se levantou Finéias, e fez juízo, e cessou aquela peste.',
        'E isto lhe foi contado como justiça, de geração em geração, para sempre.',
        'Indignaram-no também junto às águas da contenda, de sorte que sucedeu mal a Moisés, por causa deles;',
        'Porque irritaram o seu espírito, de modo que falou imprudentemente com seus lábios.',
        'Não destruíram os povos, como o Senhor lhes dissera.',
        'Antes se misturaram com os gentios, e aprenderam as suas obras.',
        'E serviram aos seus ídolos, que vieram a ser-lhes um laço.',
        'Demais disto, sacrificaram seus filhos e suas filhas aos demônios,',
        'E derramaram sangue inocente, o sangue de seus filhos e de suas filhas que sacrificaram aos ídolos de Canaã; e a terra foi manchada com sangue.',
        'Assim se contaminaram com as suas obras, e se corromperam com os seus feitos.',
        'Então se acendeu a ira do Senhor contra o seu povo, de modo que abominou a sua herança.',
        'E os entregou nas mãos dos gentios; e aqueles que os odiavam se assenhorearam deles.',
        'E os seus inimigos os oprimiram, e foram humilhados debaixo das suas mãos.',
        'Muitas vezes os livrou, mas o provocaram com o seu conselho, e foram abatidos pela sua iniqüidade.',
        'Contudo, atendeu à sua aflição, ouvindo o seu clamor.',
        'E se lembrou da sua aliança, e se arrependeu segundo a multidão das suas misericórdias.',
        'Assim, também fez com que deles tivessem misericórdia os que os levaram cativos.',
        'Salva-nos, Senhor nosso Deus, e congrega-nos dentre os gentios, para que louvemos o teu nome santo, e nos gloriemos no teu louvor.',
        'Bendito seja o Senhor Deus de Israel, de eternidade em eternidade, e todo o povo diga: Amém. Louvai ao Senhor.',
      ],
      [
        'Louvai ao SENHOR, porque ele é bom, porque a sua benignidade dura para sempre.',
        'Digam-no os remidos do Senhor, os que remiu da mão do inimigo,',
        'E os que congregou das terras do oriente e do ocidente, do norte e do sul.',
        'Andaram desgarrados pelo deserto, por caminhos solitários; não acharam cidade para habitarem.',
        'Famintos e sedentos, a sua alma neles desfalecia.',
        'E clamaram ao Senhor na sua angústia, e os livrou das suas dificuldades.',
        'E os levou por caminho direito, para irem a uma cidade de habitação.',
        'Louvem ao Senhor pela sua bondade, e pelas suas maravilhas para com os filhos dos homens.',
        'Pois fartou a alma sedenta, e encheu de bens a alma faminta.',
        'Tal como a que se assenta nas trevas e sombra da morte, presa em aflição e em ferro;',
        'Porquanto se rebelaram contra as palavras de Deus, e desprezaram o conselho do Altíssimo.',
        'Portanto, lhes abateu o coração com trabalho; tropeçaram, e não houve quem os ajudasse.',
        'Então clamaram ao Senhor na sua angústia, e os livrou das suas dificuldades.',
        'Tirou-os das trevas e sombra da morte; e quebrou as suas prisões.',
        'Louvem ao Senhor pela sua bondade, e pelas suas maravilhas para com os filhos dos homens.',
        'Pois quebrou as portas de bronze, e despedaçou os ferrolhos de ferro.',
        'Os loucos, por causa da sua transgressão, e por causa das suas iniqüidades, são aflitos.',
        'A sua alma aborreceu toda a comida, e chegaram até às portas da morte.',
        'Então clamaram ao Senhor na sua angústia, e ele os livrou das suas dificuldades.',
        'Enviou a sua palavra, e os sarou; e os livrou da sua destruição.',
        'Louvem ao Senhor pela sua bondade, e pelas suas maravilhas para com os filhos dos homens.',
        'E ofereçam os sacrifícios de louvor, e relatem as suas obras com regozijo.',
        'Os que descem ao mar em navios, mercando nas grandes águas.',
        'Esses vêem as obras do Senhor, e as suas maravilhas no profundo.',
        'Pois ele manda, e se levanta o vento tempestuoso que eleva as suas ondas.',
        'Sobem aos céus; descem aos abismos, e a sua alma se derrete em angústias.',
        'Andam e cambaleiam como ébrios, e perderam todo o tino.',
        'Então clamam ao Senhor na sua angústia; e ele os livra das suas dificuldades.',
        'Faz cessar a tormenta, e acalmam-se as suas ondas.',
        'Então se alegram, porque se aquietaram; assim os leva ao seu porto desejado.',
        'Louvem ao Senhor pela sua bondade, e pelas suas maravilhas para com os filhos dos homens.',
        'Exaltem-no na congregação do povo, e glorifiquem-no na assembléia dos anciãos.',
        'Ele converte os rios em um deserto, e as fontes em terra sedenta;',
        'A terra frutífera em estéril, pela maldade dos que nela habitam.',
        'Converte o deserto em lagoa, e a terra seca em fontes.',
        'E faz habitar ali os famintos, para que edifiquem cidade para habitação;',
        'E semeiam os campos e plantam vinhas, que produzem fruto abundante.',
        'Também os abençoa, de modo que se multiplicam muito; e o seu gado não diminui.',
        'Depois se diminuem e se abatem, pela opressão, e aflição e tristeza.',
        'Derrama o desprezo sobre os príncipes, e os faz andar desgarrados pelo deserto, onde não há caminho.',
        'Porém livra ao necessitado da opressão, em um lugar alto, e multiplica as famílias como rebanhos.',
        'Os retos o verão, e se alegrarão, e toda a iniqüidade tapará a boca.',
        'Quem é sábio observará estas coisas, e eles compreenderão as benignidades do Senhor.',
      ],
      [
        'Preparado está o meu coração, ó Deus; cantarei e darei louvores até com a minha glória.',
        'Despertai, saltério e harpa; eu mesmo despertarei ao romper da alva.',
        'Louvar-te-ei entre os povos, Senhor, e a ti cantarei louvores entre as nações.',
        'Porque a tua benignidade se estende até aos céus, e a tua verdade chega até às mais altas nuvens.',
        'Exalta-te sobre os céus, ó Deus, e a tua glória sobre toda a terra.',
        'Para que sejam livres os teus amados, salva-nos com a tua destra, e ouve-nos.',
        'Deus falou na sua santidade; eu me regozijarei; repartirei a Siquém, e medirei o vale de Sucote.',
        'Meu é Gileade, meu é Manassés; e Efraim a força da minha cabeça, Judá o meu legislador.',
        'Moabe a minha bacia de lavar; sobre Edom lançarei o meu sapato, sobre a Filístia jubilarei.',
        'Quem me levará à cidade forte? Quem me guiará até Edom?',
        'Porventura não serás tu, ó Deus, que nos rejeitaste? E não sairás, ó Deus, com os nossos exércitos?',
        'Dá-nos auxílio para sair da angústia, porque vão é o socorro da parte do homem.',
        'Em Deus faremos proezas, pois ele calcará aos pés os nossos inimigos.',
      ],
      [
        'Ó Deus do meu louvor, não te cales,',
        'Pois a boca do ímpio e a boca do enganador estão abertas contra mim. Têm falado contra mim com uma língua mentirosa.',
        'Eles me cercaram com palavras odiosas, e pelejaram contra mim sem causa.',
        'Em recompensa do meu amor são meus adversários; mas eu faço oração.',
        'E me deram mal pelo bem, e ódio pelo meu amor.',
        'Põe sobre ele um ímpio, e Satanás esteja à sua direita.',
        'Quando for julgado, saia condenado; e a sua oração se lhe torne em pecado.',
        'Sejam poucos os seus dias, e outro tome o seu ofício.',
        'Sejam órfãos os seus filhos, e viúva sua mulher.',
        'Sejam vagabundos e pedintes os seus filhos, e busquem pão fora dos seus lugares desolados.',
        'Lance o credor mão de tudo quanto tenha, e despojem os estranhos o seu trabalho.',
        'Não haja ninguém que se compadeça dele, nem haja quem favoreça os seus órfãos.',
        'Desapareça a sua posteridade, o seu nome seja apagado na seguinte geração.',
        'Esteja na memória do Senhor a iniqüidade de seus pais, e não se apague o pecado de sua mãe.',
        'Antes estejam sempre perante o Senhor, para que faça desaparecer a sua memória da terra.',
        'Porquanto não se lembrou de fazer misericórdia; antes perseguiu ao homem aflito e ao necessitado, para que pudesse até matar o quebrantado de coração.',
        'Visto que amou a maldição, ela lhe sobrevenha, e assim como não desejou a bênção, ela se afaste dele.',
        'Assim como se vestiu de maldição, como sua roupa assim penetre ela nas suas entranhas, como água, e em seus ossos como azeite.',
        'Seja para ele como a roupa que o cobre, e como cinto que o cinja sempre.',
        'Seja este o galardão dos meus contrários, da parte do Senhor, e dos que falam mal contra a minha alma.',
        'Mas tu, ó DEUS o Senhor, trata comigo por amor do teu nome, porque a tua misericórdia é boa, livra-me,',
        'Pois estou aflito e necessitado, e o meu coração está ferido dentro de mim.',
        'Vou-me como a sombra que declina; sou sacudido como o gafanhoto.',
        'De jejuar estão enfraquecidos os meus joelhos, e a minha carne emagrece.',
        'E ainda lhes sou opróbrio; quando me contemplam, movem as cabeças.',
        'Ajuda-me, ó Senhor meu Deus, salva-me segundo a tua misericórdia.',
        'Para que saibam que esta é a tua mão, e que tu, Senhor, o fizeste.',
        'Amaldiçoem eles, mas abençoa tu; quando se levantarem fiquem confundidos; e alegre-se o teu servo.',
        'Vistam-se os meus adversários de vergonha, e cubram-se com a sua própria confusão como com uma capa.',
        'Louvarei grandemente ao Senhor com a minha boca; louvá-lo-ei entre a multidão.',
        'Pois se porá à direita do pobre, para o livrar dos que condenam a sua alma.',
      ],
      [
        'Disse o SENHOR ao meu Senhor: Assenta-te à minha mão direita, até que ponha os teus inimigos por escabelo dos teus pés.',
        'O Senhor enviará o cetro da tua fortaleza desde Sião, dizendo: Domina no meio dos teus inimigos.',
        'O teu povo será mui voluntário no dia do teu poder; nos ornamentos de santidade, desde a madre da alva, tu tens o orvalho da tua mocidade.',
        'Jurou o Senhor, e não se arrependerá: tu és um sacerdote eterno, segundo a ordem de Melquisedeque.',
        'O Senhor, à tua direita, ferirá os reis no dia da sua ira.',
        'Julgará entre os gentios; tudo encherá de corpos mortos; ferirá os cabeças de muitos países.',
        'Beberá do ribeiro no caminho, por isso exaltará a cabeça.',
      ],
      [
        'Louvai ao SENHOR. Louvarei ao SENHOR de todo o meu coração, na assembléia dos justos e na congregação.',
        'Grandes são as obras do Senhor, procuradas por todos os que nelas tomam prazer.',
        'A sua obra tem glória e majestade, e a sua justiça permanece para sempre.',
        'Fez com que as suas maravilhas fossem lembradas; piedoso e misericordioso é o Senhor.',
        'Deu mantimento aos que o temem; lembrar-se-á sempre da sua aliança.',
        'Anunciou ao seu povo o poder das suas obras, para lhe dar a herança dos gentios.',
        'As obras das suas mãos são verdade e juízo, seguros todos os seus mandamentos.',
        'Permanecem firmes para todo o sempre; e são feitos em verdade e retidão.',
        'Redenção enviou ao seu povo; ordenou a sua aliança para sempre; santo e tremendo é o seu nome.',
        'O temor do Senhor é o princípio da sabedoria; bom entendimento têm todos os que cumprem os seus mandamentos; o seu louvor permanece para sempre.',
      ],
      [
        'Louvai ao SENHOR. Bem-aventurado o homem que teme ao SENHOR, que em seus mandamentos tem grande prazer.',
        'A sua semente será poderosa na terra; a geração dos retos será abençoada.',
        'Prosperidade e riquezas haverá na sua casa, e a sua justiça permanece para sempre.',
        'Aos justos nasce luz nas trevas; ele é piedoso, misericordioso e justo.',
        'O homem bom se compadece, e empresta; disporá as suas coisas com juízo;',
        'Porque nunca será abalado; o justo estará em memória eterna.',
        'Não temerá maus rumores; o seu coração está firme, confiando no Senhor.',
        'O seu coração está bem confirmado, ele não temerá, até que veja o seu desejo sobre os seus inimigos.',
        'Ele espalhou, deu aos necessitados; a sua justiça permanece para sempre, e a sua força se exaltará em glória.',
        'O ímpio o verá, e se entristecerá; rangerá os dentes, e se consumirá; o desejo dos ímpios perecerá.',
      ],
      [
        'Louvai ao SENHOR. Louvai, servos do SENHOR, louvai o nome do SENHOR.',
        'Seja bendito o nome do Senhor, desde agora para sempre.',
        'Desde o nascimento do sol até ao ocaso, seja louvado o nome do Senhor.',
        'Exaltado está o Senhor acima de todas as nações, e a sua glória sobre os céus.',
        'Quem é como o Senhor nosso Deus, que habita nas alturas?',
        'O qual se inclina, para ver o que está nos céus e na terra!',
        'Levanta o pobre do pó e do monturo levanta o necessitado,',
        'Para o fazer assentar com os príncipes, mesmo com os príncipes do seu povo.',
        'Faz com que a mulher estéril habite em casa, e seja alegre mãe de filhos. Louvai ao Senhor.',
      ],
      [
        'Quando Israel saiu do Egito, e a casa de Jacó de um povo de língua estranha,',
        'Judá foi seu santuário, e Israel seu domínio.',
        'O mar viu isto, e fugiu; o Jordão voltou para trás.',
        'Os montes saltaram como carneiros, e os outeiros como cordeiros.',
        'Que tiveste tu, ó mar, que fugiste, e tu, ó Jordão, que voltaste para trás?',
        'Montes, que saltastes como carneiros, e outeiros, como cordeiros?',
        'Treme, terra, na presença do Senhor, na presença do Deus de Jacó.',
        'O qual converteu o rochedo em lago de águas, e o seixo em fonte de água.',
      ],
      [
        'Não a nós, SENHOR, não a nós, mas ao teu nome dá glória, por amor da tua benignidade e da tua verdade.',
        'Porque dirão os gentios: Onde está o seu Deus?',
        'Mas o nosso Deus está nos céus; fez tudo o que lhe agradou.',
        'Os ídolos deles são prata e ouro, obra das mãos dos homens.',
        'Têm boca, mas não falam; olhos têm, mas não vêem.',
        'Têm ouvidos, mas não ouvem; narizes têm, mas não cheiram.',
        'Têm mãos, mas não apalpam; pés têm, mas não andam; nem som algum sai da sua garganta.',
        'A eles se tornem semelhantes os que os fazem, assim como todos os que neles confiam.',
        'Israel, confia no Senhor; ele é o seu auxílio e o seu escudo.',
        'Casa de Arão, confia no Senhor; ele é o seu auxílio e o seu escudo.',
        'Vós, os que temeis ao Senhor, confiai no Senhor; ele é o seu auxílio e o seu escudo.',
        'O Senhor se lembrou de nós; ele nos abençoará; abençoará a casa de Israel; abençoará a casa de Arão.',
        'Abençoará os que temem ao Senhor, tanto pequenos como grandes.',
        'O Senhor vos aumentará cada vez mais, a vós e a vossos filhos.',
        'Sois benditos do Senhor, que fez os céus e a terra.',
        'Os céus são os céus do Senhor; mas a terra a deu aos filhos dos homens.',
        'Os mortos não louvam ao Senhor, nem os que descem ao silêncio.',
        'Mas nós bendiremos ao Senhor, desde agora e para sempre. Louvai ao Senhor.',
      ],
      [
        'Amo ao SENHOR, porque ele ouviu a minha voz e a minha súplica.',
        'Porque inclinou a mim os seus ouvidos; portanto, o invocarei enquanto viver.',
        'Os cordéis da morte me cercaram, e angústias do inferno se apoderaram de mim; encontrei aperto e tristeza.',
        'Então invoquei o nome do Senhor, dizendo: Ó Senhor, livra a minha alma.',
        'Piedoso é o Senhor e justo; o nosso Deus tem misericórdia.',
        'O Senhor guarda aos símplices; fui abatido, mas ele me livrou.',
        'Volta, minha alma, para o teu repouso, pois o Senhor te fez bem.',
        'Porque tu livraste a minha alma da morte, os meus olhos das lágrimas, e os meus pés da queda.',
        'Andarei perante a face do Senhor na terra dos viventes.',
        'Cri, por isso falei. Estive muito aflito.',
        'Dizia na minha pressa: Todos os homens são mentirosos.',
        'Que darei eu ao Senhor, por todos os benefícios que me tem feito?',
        'Tomarei o cálice da salvação, e invocarei o nome do Senhor.',
        'Pagarei os meus votos ao Senhor, agora, na presença de todo o seu povo.',
        'Preciosa é à vista do Senhor a morte dos seus santos.',
        'Ó Senhor, deveras sou teu servo; sou teu servo, filho da tua serva; soltaste as minhas ataduras.',
        'Oferecer-te-ei sacrifícios de louvor, e invocarei o nome do Senhor.',
        'Pagarei os meus votos ao Senhor, na presença de todo o meu povo,',
        'Nos átrios da casa do Senhor, no meio de ti, ó Jerusalém. Louvai ao Senhor.',
      ],
      [
        'Louvai ao SENHOR todas as nações, louvai-o todos os povos.',
        'Porque a sua benignidade é grande para conosco, e a verdade do Senhor dura para sempre. Louvai ao Senhor.',
      ],
      [
        'Louvai ao SENHOR, porque ele é bom, porque a sua benignidade dura para sempre.',
        'Diga agora Israel que a sua benignidade dura para sempre.',
        'Diga agora a casa de Arão que a sua benignidade dura para sempre.',
        'Digam agora os que temem ao Senhor que a sua benignidade dura para sempre.',
        'Invoquei o Senhor na angústia; o Senhor me ouviu, e me tirou para um lugar largo.',
        'O Senhor está comigo; não temerei o que me pode fazer o homem.',
        'O Senhor está comigo entre aqueles que me ajudam; por isso verei cumprido o meu desejo sobre os que me odeiam.',
        'É melhor confiar no Senhor do que confiar no homem.',
        'É melhor confiar no Senhor do que confiar nos príncipes.',
        'Todas as nações me cercaram, mas no nome do Senhor as despedaçarei.',
        'Cercaram-me, e tornaram a cercar-me; mas no nome do Senhor eu as despedaçarei.',
        'Cercaram-me como abelhas; porém apagaram-se como o fogo de espinhos; pois no nome do Senhor as despedaçarei.',
        'Com força me impeliste para me fazeres cair, porém o Senhor me ajudou.',
        'O Senhor é a minha força e o meu cântico; e se fez a minha salvação.',
        'Nas tendas dos justos há voz de júbilo e de salvação; a destra do Senhor faz proezas.',
        'A destra do Senhor se exalta; a destra do Senhor faz proezas.',
        'Não morrerei, mas viverei; e contarei as obras do Senhor.',
        'O Senhor me castigou muito, mas não me entregou à morte.',
        'Abri-me as portas da justiça; entrarei por elas, e louvarei ao Senhor.',
        'Esta é a porta do Senhor, pela qual os justos entrarão.',
        'Louvar-te-ei, pois me escutaste, e te fizeste a minha salvação.',
        'A pedra que os edificadores rejeitaram tornou-se a cabeça da esquina.',
        'Da parte do Senhor se fez isto; maravilhoso é aos nossos olhos.',
        'Este é o dia que fez o Senhor; regozijemo-nos, e alegremo-nos nele.',
        'Salva-nos, agora, te pedimos, ó Senhor; ó Senhor, te pedimos, prospera-nos.',
        'Bendito aquele que vem em nome do Senhor; nós vos bendizemos desde a casa do Senhor.',
        'Deus é o Senhor que nos mostrou a luz; atai a vítima da festa com cordas, até às pontas do altar.',
        'Tu és o meu Deus, e eu te louvarei; tu és o meu Deus, e eu te exaltarei.',
        'Louvai ao Senhor, porque ele é bom; porque a sua benignidade dura para sempre.',
      ],
      [
        'Bem-aventurados os retos em seus caminhos, que andam na lei do Senhor.',
        'Bem-aventurados os que guardam os seus testemunhos, e que o buscam com todo o coração.',
        'E não praticam iniqüidade, mas andam nos seus caminhos.',
        'Tu ordenaste os teus mandamentos, para que diligentemente os observássemos.',
        'Quem dera que os meus caminhos fossem dirigidos a observar os teus mandamentos.',
        'Então não ficaria confundido, atentando eu para todos os teus mandamentos.',
        'Louvar-te-ei com retidão de coração quando tiver aprendido os teus justos juízos.',
        'Observarei os teus estatutos; não me desampares totalmente.',
        'Com que purificará o jovem o seu caminho? Observando-o conforme a tua palavra.',
        'Com todo o meu coração te busquei; não me deixes desviar dos teus mandamentos.',
        'Escondi a tua palavra no meu coração, para eu não pecar contra ti.',
        'Bendito és tu, ó Senhor; ensina-me os teus estatutos.',
        'Com os meus lábios declarei todos os juízos da tua boca.',
        'Folguei tanto no caminho dos teus testemunhos, como em todas as riquezas.',
        'Meditarei nos teus preceitos, e terei respeito aos teus caminhos.',
        'Recrear-me-ei nos teus estatutos; não me esquecerei da tua palavra.',
        'Faze bem ao teu servo, para que viva e observe a tua palavra.',
        'Abre tu os meus olhos, para que veja as maravilhas da tua lei.',
        'Sou peregrino na terra; não escondas de mim os teus mandamentos.',
        'A minha alma está quebrantada de desejar os teus juízos em todo o tempo.',
        'Tu repreendeste asperamente os soberbos que são amaldiçoa-dos, que se desviam dos teus mandamentos.',
        'Tira de sobre mim o opróbrio e o desprezo, pois guardei os teus testemunhos.',
        'Príncipes também se assentaram, e falaram contra mim, mas o teu servo meditou nos teus estatutos.',
        'Também os teus testemunhos são o meu prazer e os meus conselheiros.',
        'A minha alma está pegada ao pó; vivifica-me segundo a tua palavra.',
        'Eu te contei os meus caminhos, e tu me ouviste; ensina-me os teus estatutos.',
        'Faze-me entender o caminho dos teus preceitos; assim falarei das tuas maravilhas.',
        'A minha alma consome-se de tristeza; fortalece-me segundo a tua palavra.',
        'Desvia de mim o caminho da falsidade, e concede-me piedosamente a tua lei.',
        'Escolhi o caminho da verdade; propus-me seguir os teus juízos.',
        'Apego-me aos teus testemunhos; ó Senhor, não me confundas.',
        'Correrei pelo caminho dos teus mandamentos, quando dilatares o meu coração.',
        'Ensina-me, ó Senhor, o caminho dos teus estatutos, e guardá-lo-ei até o fim.',
        'Dá-me entendimento, e guardarei a tua lei, e observá-la-ei de todo o meu coração.',
        'Faze-me andar na vereda dos teus mandamentos, porque nela tenho prazer.',
        'Inclina o meu coração aos teus testemunhos, e não à cobiça.',
        'Desvia os meus olhos de contemplarem a vaidade, e vivifica-me no teu caminho.',
        'Confirma a tua palavra ao teu servo, que é dedicado ao teu temor.',
        'Desvia de mim o opróbrio que temo, pois os teus juízos são bons.',
        'Eis que tenho desejado os teus preceitos; vivifica-me na tua justiça.',
        'Venham sobre mim também as tuas misericórdias, ó Senhor, e a tua salvação segundo a tua palavra.',
        'Assim terei que responder ao que me afronta, pois confio na tua palavra.',
        'E não tires totalmente a palavra de verdade da minha boca, pois tenho esperado nos teus juízos.',
        'Assim observarei de contínuo a tua lei para sempre e eternamente.',
        'E andarei em liberdade; pois busco os teus preceitos.',
        'Também falarei dos teus testemunhos perante os reis, e não me envergonharei.',
        'E recrear-me-ei em teus mandamentos, que tenho amado.',
        'Também levantarei as minhas mãos para os teus mandamentos, que amei, e meditarei nos teus estatutos.',
        'Lembra-te da palavra dada ao teu servo, na qual me fizeste esperar.',
        'Isto é a minha consolação na minha aflição, porque a tua palavra me vivificou.',
        'Os soberbos zombaram grandemente de mim; contudo não me desviei da tua lei.',
        'Lembrei-me dos teus juízos antiqüíssimos, ó Senhor, e assim me consolei.',
        'Grande indignação se apoderou de mim por causa dos ímpios que abandonam a tua lei.',
        'Os teus estatutos têm sido os meus cânticos na casa da minha peregrinação.',
        'Lembrei-me do teu nome, ó Senhor, de noite, e observei a tua lei.',
        'Isto fiz eu, porque guardei os teus mandamentos.',
        'O Senhor é a minha porção; eu disse que observaria as tuas palavras.',
        'Roguei deveras o teu favor com todo o meu coração; tem piedade de mim, segundo a tua palavra.',
        'Considerei os meus caminhos, e voltei os meus pés para os teus testemunhos.',
        'Apressei-me, e não me detive, a observar os teus mandamentos.',
        'Bandos de ímpios me despojaram, mas eu não me esqueci da tua lei.',
        'À meia-noite me levantarei para te louvar, pelos teus justos juízos.',
        'Companheiro sou de todos os que te temem e dos que guardam os teus preceitos.',
        'A terra, ó Senhor, está cheia da tua benignidade; ensina-me os teus estatutos.',
        'Fizeste bem ao teu servo, Senhor, segundo a tua palavra.',
        'Ensina-me bom juízo e ciência, pois cri nos teus mandamentos.',
        'Antes de ser afligido andava errado; mas agora tenho guardado a tua palavra.',
        'Tu és bom e fazes bem; ensina-me os teus estatutos.',
        'Os soberbos forjaram mentiras contra mim; mas eu com todo o meu coração guardarei os teus preceitos.',
        'Engrossa-se-lhes o coração como gordura, mas eu me recreio na tua lei.',
        'Foi-me bom ter sido afligido, para que aprendesse os teus estatutos.',
        'Melhor é para mim a lei da tua boca do que milhares de ouro ou prata.',
        'As tuas mãos me fizeram e me formaram; dá-me inteligência para entender os teus mandamentos.',
        'Os que te temem alegraram-se quando me viram, porque tenho esperado na tua palavra.',
        'Bem sei eu, ó Senhor, que os teus juízos são justos, e que segundo a tua fidelidade me afligiste.',
        'Sirva pois a tua benignidade para me consolar, segundo a palavra que deste ao teu servo.',
        'Venham sobre mim as tuas misericórdias, para que viva, pois a tua lei é a minha delícia.',
        'Confundam-se os soberbos, pois me trataram duma maneira perversa, sem causa; mas eu meditarei nos teus preceitos.',
        'Voltem-se para mim os que te temem, e aqueles que têm conhecido os teus testemunhos.',
        'Seja reto o meu coração nos teus estatutos, para que não seja confundido.',
        'Desfalece a minha alma pela tua salvação, mas espero na tua palavra.',
        'Os meus olhos desfalecem pela tua palavra; entrementes dizia: Quando me consolarás tu?',
        'Pois estou como odre na fumaça; contudo não me esqueço dos teus estatutos.',
        'Quantos serão os dias do teu servo? Quando me farás justiça contra os que me perseguem?',
        'Os soberbos me cavaram covas, o que não é conforme a tua lei.',
        'Todos os teus mandamentos são verdade. Com mentiras me perseguem; ajuda-me.',
        'Quase que me têm consumido sobre a terra, mas eu não deixei os teus preceitos.',
        'Vivifica-me segundo a tua benignidade; assim guardarei o testemunho da tua boca.',
        'Para sempre, ó Senhor, a tua palavra permanece no céu.',
        'A tua fidelidade dura de geração em geração; tu firmaste a terra, e ela permanece firme.',
        'Eles continuam até ao dia de hoje, segundo as tuas ordenações; porque todos são teus servos.',
        'Se a tua lei não fora toda a minha recreação, há muito que pereceria na minha aflição.',
        'Nunca me esquecerei dos teus preceitos; pois por eles me tens vivificado.',
        'Sou teu, salva-me; pois tenho buscado os teus preceitos.',
        'Os ímpios me esperam para me destruírem, mas eu considerarei os teus testemunhos.',
        'Tenho visto fim a toda a perfeição, mas o teu mandamento é amplíssimo.',
        'Oh! quanto amo a tua lei! É a minha meditação em todo o dia.',
        'Tu, pelos teus mandamentos, me fazes mais sábio do que os meus inimigos; pois estão sempre comigo.',
        'Tenho mais entendimento do que todos os meus mestres, porque os teus testemunhos são a minha meditação.',
        'Entendo mais do que os antigos; porque guardo os teus preceitos.',
        'Desviei os meus pés de todo caminho mau, para guardar a tua palavra.',
        'Não me apartei dos teus juízos, pois tu me ensinaste.',
        'Oh! quão doces são as tuas palavras ao meu paladar, mais doces do que o mel à minha boca.',
        'Pelos teus mandamentos alcancei entendimento; por isso odeio todo falso caminho.',
        'Lâmpada para os meus pés é tua palavra, e luz para o meu caminho.',
        'Jurei, e o cumprirei, que guardarei os teus justos juízos.',
        'Estou aflitíssimo; vivifica-me, ó Senhor, segundo a tua palavra.',
        'Aceita, eu te rogo, as oferendas voluntárias da minha boca, ó Senhor; ensina-me os teus juízos.',
        'A minha alma está de contínuo nas minhas mãos; todavia não me esqueço da tua lei.',
        'Os ímpios me armaram laço; contudo não me desviei dos teus preceitos.',
        'Os teus testemunhos tenho eu tomado por herança para sempre, pois são o gozo do meu coração.',
        'Inclinei o meu coração a guardar os teus estatutos, para sempre, até ao fim.',
        'Odeio os pensamentos vãos, mas amo a tua lei.',
        'Tu és o meu refúgio e o meu escudo; espero na tua palavra.',
        'Apartai-vos de mim, malfeitores, pois guardarei os mandamentos do meu Deus.',
        'Sustenta-me conforme a tua palavra, para que viva, e não me deixes envergonhado da minha esperança.',
        'Sustenta-me, e serei salvo, e de contínuo terei respeito aos teus estatutos.',
        'Tu tens pisado aos pés todos os que se desviam dos teus estatutos, pois o engano deles é falsidade.',
        'Tu tiraste da terra todos os ímpios, como a escória, por isso amo os teus testemunhos.',
        'O meu corpo se arrepiou com temor de ti, e temi os teus juízos.',
        'Fiz juízo e justiça; não me entregues aos meus opressores.',
        'Fica por fiador do teu servo para o bem; não deixes que os soberbos me oprimam.',
        'Os meus olhos desfaleceram pela tua salvação e pela promessa da tua justiça.',
        'Usa com o teu servo segundo a tua benignidade, e ensina-me os teus estatutos.',
        'Sou teu servo; dá-me inteligência, para entender os teus testemunhos.',
        'Já é tempo de operares, ó Senhor, pois eles têm quebrantado a tua lei.',
        'Por isso am',
        'Por isso estimo todos os teus preceitos acerca de tudo, como retos, e odeio toda falsa vereda.',
        'Maravilhosos são os teus testemunhos; portanto, a minha alma os guarda.',
        'A entrada das tuas palavras dá luz, dá entendimento aos símplices.',
        'Abri a minha boca, e respirei, pois que desejei os teus mandamentos.',
        'Olha para mim, e tem piedade de mim, conforme usas com os que amam o teu nome.',
        'Ordena os meus passos na tua palavra, e não se apodere de mim iniqüidade alguma.',
        'Livra-me da opressão do homem; assim guardarei os teus preceitos.',
        'Faze resplandecer o teu rosto sobre o teu servo, e ensina-me os teus estatutos.',
        'Rios de águas correm dos meus olhos, porque não guardam a tua lei.',
        'Justo és, ó Senhor, e retos são os teus juízos.',
        'Os teus testemunhos que ordenaste são retos e muito fiéis.',
        'O meu zelo me consumiu, porque os meus inimigos se esqueceram da tua palavra.',
        'A tua palavra é muito pura; portanto, o teu servo a ama.',
        'Pequeno sou e desprezado, porém não me esqueço dos teus mandamentos.',
        'A tua justiça é uma justiça eterna, e a tua lei é a verdade.',
        'Aflição e angústia se apoderam de mim; contudo os teus mandamentos são o meu prazer.',
        'A justiça dos teus testemunhos é eterna; dá-me inteligência, e viverei.',
        'Clamei de todo o meu coração; escuta-me, Senhor, e guardarei os teus estatutos.',
        'A ti te invoquei; salva-me, e guardarei os teus testemunhos.',
        'Antecipei o cair da noite, e clamei; esperei na tua palavra.',
        'Os meus olhos anteciparam as vigílias da noite, para meditar na tua palavra.',
        'Ouve a minha voz, segundo a tua benignidade; vivifica-me, ó Senhor, segundo o teu juízo.',
        'Aproximam-se os que se dão a maus tratos; afastam-se da tua lei.',
        'Tu estás perto, ó Senhor, e todos os teus mandamentos são a verdade.',
        'Acerca dos teus testemunhos soube, desde a antiguidade, que tu os fundaste para sempre.',
        'Olha para a minha aflição, e livra-me, pois não me esqueci da tua lei.',
        'Pleiteia a minha causa, e livra-me; vivifica-me segundo a tua palavra.',
        'A salvação está longe dos ímpios, pois não buscam os teus estatutos.',
        'Muitas são, ó Senhor, as tuas misericórdias; vivifica-me segundo os teus juízos.',
        'Muitos são os meus perseguidores e os meus inimigos; mas não me desvio dos teus testemunhos.',
        'Vi os transgressores, e me afligi, porque não observam a tua palavra.',
        'Considera como amo os teus preceitos; vivifica-me, ó Senhor, segundo a tua benignidade.',
        'A tua palavra é a verdade desde o princípio, e cada um dos teus juízos dura para sempre.',
        'Príncipes me perseguiram sem causa, mas o meu coração temeu a tua palavra.',
        'Folgo com a tua palavra, como aquele que acha um grande despojo.',
        'Abomino e odeio a mentira; mas amo a tua lei.',
        'Sete vezes no dia te louvo pelos juízos da tua justiça.',
        'Muita paz têm os que amam a tua lei, e para eles não há tropeço.',
        'Senhor, tenho esperado na tua salvação, e tenho cumprido os teus mandamentos.',
        'A minha alma tem observado os teus testemunhos; amo-os excessivamente.',
        'Tenho observado os teus preceitos, e os teus testemunhos, porque todos os meus caminhos estão diante de ti.',
        'Chegue a ti o meu clamor, ó Senhor; dá-me entendimento conforme a tua palavra.',
        'Chegue a minha súplica perante a tua face; livra-me segundo a tua palavra.',
        'Os meus lábios proferiram o louvor, quando me ensinaste os teus estatutos.',
        'A minha língua falará da tua palavra, pois todos os teus mandamentos são justiça.',
        'Venha a tua mão socorrer-me, pois escolhi os teus preceitos.',
        'Tenho desejado a tua salvação, ó Senhor; a tua lei é todo o meu prazer.',
        'Viva a minha alma, e louvar-te-á; ajudem-me os teus juízos.',
        'Desgarrei-me como a ovelha perdida; busca o teu servo, pois não me esqueci dos teus mandamentos.',
      ],
      [
        'Na minha angústia clamei ao SENHOR, e me ouviu.',
        'Senhor, livra a minha alma dos lábios mentirosos e da língua enganadora.',
        'Que te será dado, ou que te será acrescentado, língua enganadora?',
        'Flechas agudas do poderoso, com brasas vivas de zimbro.',
        'Ai de mim, que peregrino em Meseque, e habito nas tendas de Quedar.',
        'A minha alma bastante tempo habitou com os que detestam a paz.',
        'Pacífico sou, mas quando eu falo já eles procuram a guerra.',
      ],
      [
        'Levantarei os meus olhos para os montes, de onde vem o meu socorro.',
        'O meu socorro vem do Senhor que fez o céu e a terra.',
        'Não deixará vacilar o teu pé; aquele que te guarda não tosquenejará.',
        'Eis que não tosquenejará nem dormirá o guarda de Israel.',
        'O Senhor é quem te guarda; o Senhor é a tua sombra à tua direita.',
        'O sol não te molestará de dia nem a lua de noite.',
        'O Senhor te guardará de todo o mal; guardará a tua alma.',
        'O Senhor guardará a tua entrada e a tua saída, desde agora e para sempre.',
      ],
      [
        'Alegrei-me quando me disseram: Vamos á casa do SENHOR.',
        'Os nossos pés estão dentro das tuas portas, ó Jerusalém.',
        'Jerusalém está edificada como uma cidade que é compacta.',
        'Onde sobem as tribos, as tribos do Senhor, até ao testemunho de Israel, para darem graças ao nome do Senhor.',
        'Pois ali estão os tronos do juízo, os tronos da casa de Davi.',
        'Orai pela paz de Jerusalém; prosperarão aqueles que te amam.',
        'Haja paz dentro de teus muros, e prosperidade dentro dos teus palácios.',
        'Por causa dos meus irmãos e amigos, direi: Paz esteja em ti.',
        'Por causa da casa do Senhor nosso Deus, buscarei o teu bem.',
      ],
      [
        'A ti levanto os meus olhos, ó tu que habitas nos céus.',
        'Assim como os olhos dos servos atentam para as mãos dos seus senhores, e os olhos da serva para as mãos de sua senhora, assim os nossos olhos atentam para o SENHOR nosso Deus, até que tenha piedade de nós.',
        'Tem piedade de nós, ó Senhor, tem piedade de nós, pois estamos assaz fartos de desprezo.',
        'A nossa alma está extremamente farta da zombaria daqueles que estão à sua vontade e do desprezo dos soberbos.',
      ],
      [
        'Se não fora o Senhor, que esteve ao nosso lado, ora diga Israel;',
        'Se não fora o Senhor, que esteve ao nosso lado, quando os homens se levantaram contra nós,',
        'Eles então nos teriam engolido vivos, quando a sua ira se acendeu contra nós.',
        'Então as águas teriam transbordado sobre nós, e a corrente teria passado sobre a nossa alma;',
        'Então as águas altivas teriam passado sobre a nossa alma;',
        'Bendito seja o Senhor, que não nos deu por presa aos seus dentes.',
        'A nossa alma escapou, como um pássaro do laço dos passarinheiros; o laço quebrou-se, e nós escapamos.',
        'O nosso socorro está no nome do Senhor, que fez o céu e a terra.',
      ],
      [
        'Os que confiam no SENHOR serão como o monte de Sião, que não se abala, mas permanece para sempre.',
        'Assim como estão os montes à roda de Jerusalém, assim o Senhor está em volta do seu povo desde agora e para sempre.',
        'Porque o cetro da impiedade não permanecerá sobre a sorte dos justos, para que o justo não estenda as suas mãos para a iniqüidade.',
        'Faze bem, ó Senhor, aos bons e aos que são retos de coração.',
        'Quanto àqueles que se desviam para os seus caminhos tortuosos, levá-los-á o SENHOR com os que praticam a maldade; paz haverá sobre Israel.',
      ],
      [
        'Quando o SENHOR trouxe do cativeiro os que voltaram a Sião, estávamos como os que sonham.',
        'Então a nossa boca se encheu de riso e a nossa língua de cântico; então se dizia entre os gentios: Grandes coisas fez o Senhor a estes.',
        'Grandes coisas fez o Senhor por nós, pelas quais estamos alegres.',
        'Traze-nos outra vez, ó Senhor, do cativeiro, como as correntes das águas no sul.',
        'Os que semeiam em lágrimas segarão com alegria.',
        'Aquele que leva a preciosa semente, andando e chorando, voltará, sem dúvida, com alegria, trazendo consigo os seus molhos.',
      ],
      [
        'Se o SENHOR não edificar a casa, em vão trabalham os que a edificam; se o SENHOR não guardar a cidade, em vão vigia a sentinela.',
        'Inútil vos será levantar de madrugada, repousar tarde, comer o pão de dores, pois assim dá ele aos seus amados o sono.',
        'Eis que os filhos são herança do Senhor, e o fruto do ventre o seu galardão.',
        'Como flechas na mão de um homem poderoso, assim são os filhos da mocidade.',
        'Bem-aventurado o homem que enche deles a sua aljava; não serão confundidos, mas falarão com os seus inimigos à porta.',
      ],
      [
        'Bem-aventurado aquele que teme ao SENHOR e anda nos seus caminhos.',
        'Pois comerás do trabalho das tuas mãos; feliz serás, e te irá bem.',
        'A tua mulher será como a videira frutífera aos lados da tua casa; os teus filhos como plantas de oliveira à roda da tua mesa.',
        'Eis que assim será abençoado o homem que teme ao Senhor.',
        'O Senhor te abençoará desde Sião, e tu verás o bem de Jerusalém em todos os dias da tua vida.',
        'E verás os filhos de teus filhos, e a paz sobre Israel.',
      ],
      [
        'Muitas vezes me angustiaram desde a minha mocidade, diga agora Israel;',
        'Muitas vezes me angustiaram desde a minha mocidade; todavia não prevaleceram contra mim.',
        'Os lavradores araram sobre as minhas costas; compridos fizeram os seus sulcos.',
        'O Senhor é justo; cortou as cordas dos ímpios.',
        'Sejam confundidos, e voltem para trás todos os que odeiam a Sião.',
        'Sejam como a erva dos telhados que se seca antes que a arranquem.',
        'Com a qual o segador não enche a sua mão, nem o que ata os feixes enche o seu braço.',
        'Nem tampouco os que passam dizem: A bênção do Senhor seja sobre vós; nós vos abençoamos em nome do Senhor.',
      ],
      [
        'Das profundezas a ti clamo, ó SENHOR.',
        'Senhor, escuta a minha voz; sejam os teus ouvidos atentos à voz das minhas súplicas.',
        'Se tu, Senhor, observares as iniqüidades, Senhor, quem subsistirá?',
        'Mas contigo está o perdão, para que sejas temido.',
        'Aguardo ao Senhor; a minha alma o aguarda, e espero na sua palavra.',
        'A minha alma anseia pelo Senhor, mais do que os guardas pela manhã, mais do que aqueles que guardam pela manhã.',
        'Espere Israel no Senhor, porque no Senhor há misericórdia, e nele há abundante redenção.',
        'E ele remirá a Israel de todas as suas iniqüidades.',
      ],
      [
        'SENHOR, o meu coração não se elevou nem os meus olhos se levantaram; não me exercito em grandes matérias, nem em coisas muito elevadas para mim.',
        'Certamente que me tenho portado e sossegado como uma criança desmamada de sua mãe; a minha alma está como uma criança desmamada.',
        'Espere Israel no Senhor, desde agora e para sempre.',
      ],
      [
        'Lembra-te, SENHOR, de Davi, e de todas as suas aflições.',
        'Como jurou ao Senhor, e fez votos ao poderoso Deus de Jacó, dizendo:',
        'Certamente que não entrarei na tenda de minha casa, nem subirei à minha cama,',
        'Não darei sono aos meus olhos, nem repouso às minhas pálpebras,',
        'Enquanto não achar lugar para o Senhor, uma morada para o poderoso Deus de Jacó.',
        'Eis que ouvimos falar dela em Efrata, e a achamos no campo do bosque.',
        'Entraremos nos seus tabernáculos; prostrar-nos-emos ante o escabelo de seus pés.',
        'Levanta-te, Senhor, ao teu repouso, tu e a arca da tua força.',
        'Vistam-se os teus sacerdotes de justiça, e alegrem-se os teus santos.',
        'Por amor de Davi, teu servo, não faças virar o rosto do teu ungido.',
        'O Senhor jurou com verdade a Davi, e não se apartará dela: Do fruto do teu ventre porei sobre o teu trono.',
        'Se os teus filhos guardarem a minha aliança, e os meus testemunhos, que eu lhes hei de ensinar, também os seus filhos se assentarão perpetuamente no teu trono.',
        'Porque o Senhor escolheu a Sião; desejou-a para a sua habitação, dizendo:',
        'Este é o meu repouso para sempre; aqui habitarei, pois o desejei.',
        'Abençoarei abundantemente o seu mantimento; fartarei de pão os seus necessitados.',
        'Também vestirei os seus sacerdotes de salvação, e os seus santos saltarão de prazer.',
        'Ali farei brotar a força de Davi; preparei uma lâmpada para o meu ungido.',
        'Vestirei os seus inimigos de vergonha; mas sobre ele florescerá a sua coroa.',
      ],
      [
        'Oh! quão bom e quão suave é que os irmãos vivam em união.',
        'É como o óleo precioso sobre a cabeça, que desce sobre a barba, a barba de Arão, e que desce à orla das suas vestes.',
        'Como o orvalho de Hermom, e como o que desce sobre os montes de Sião, porque ali o Senhor ordena a bênção e a vida para sempre.',
      ],
      [
        'Eis aqui, bendizei ao SENHOR todos vós, servos do SENHOR, que assistis na casa do SENHOR todas as noites.',
        'Levantai as vossas mãos no santuário, e bendizei ao Senhor.',
        'O Senhor que fez o céu e a terra te abençoe desde Sião.',
      ],
      [
        'Louvai ao SENHOR. Louvai o nome do SENHOR; louvai-o, servos do SENHOR.',
        'Vós que assistis na casa do Senhor, nos átrios da casa do nosso Deus.',
        'Louvai ao Senhor, porque o Senhor é bom; cantai louvores ao seu nome, porque é agradável.',
        'Porque o Senhor escolheu para si a Jacó, e a Israel para seu próprio tesouro.',
        'Porque eu conheço que o Senhor é grande e que o nosso Senhor está acima de todos os deuses.',
        'Tudo o que o Senhor quis, fez, nos céus e na terra, nos mares e em todos os abismos.',
        'Faz subir os vapores das extremidades da terra; faz os relâmpagos para a chuva; tira os ventos dos seus tesouros.',
        'O que feriu os primogênitos do Egito, desde os homens até os animais;',
        'O que enviou sinais e prodígios no meio de ti, ó Egito, contra Faraó e contra os seus servos;',
        'O que feriu muitas nações, e matou poderosos reis:',
        'A Siom, rei dos amorreus, e a Ogue, rei de Basã, e a todos os reinos de Canaã;',
        'E deu a sua terra em herança, em herança a Israel, seu povo.',
        'O teu nome, ó Senhor, dura perpetuamente, e a tua memória, ó Senhor, de geração em geração.',
        'Pois o Senhor julgará o seu povo, e se arrependerá com respeito aos seus servos.',
        'Os ídolos dos gentios são prata e ouro, obra das mãos dos homens.',
        'Têm boca, mas não falam; têm olhos, e não vêem,',
        'Têm ouvidos, mas não ouvem, nem há respiro algum nas suas bocas.',
        'Semelhantes a eles se tornem os que os fazem, e todos os que confiam neles.',
        'Casa de Israel, bendizei ao Senhor; casa de Arão, bendizei ao Senhor;',
        'Casa de Levi, bendizei ao Senhor; vós os que temeis ao Senhor, louvai ao Senhor.',
        'Bendito seja o Senhor desde Sião, que habita em Jerusalém. Louvai ao Senhor.',
      ],
      [
        'Louvai ao SENHOR, porque ele é bom; porque a sua benignidade dura para sempre.',
        'Louvai ao Deus dos deuses; porque a sua benignidade dura para sempre.',
        'Louvai ao Senhor dos senhores; porque a sua benignidade dura para sempre.',
        'Aquele que só faz maravilhas; porque a sua benignidade dura para sempre.',
        'Aquele que por entendimento fez os céus; porque a sua benignidade dura para sempre.',
        'Aquele que estendeu a terra sobre as águas; porque a sua benignidade dura para sempre.',
        'Aquele que fez os grandes luminares; porque a sua benignidade dura para sempre;',
        'O sol para governar de dia; porque a sua benignidade dura para sempre;',
        'A lua e as estrelas para presidirem à noite; porque a sua benignidade dura para sempre;',
        'O que feriu o Egito nos seus primogênitos; porque a sua benignidade dura para sempre;',
        'E tirou a Israel do meio deles; porque a sua benignidade dura para sempre;',
        'Com mão forte, e com braço estendido; porque a sua benignidade dura para sempre;',
        'Aquele que dividiu o Mar Vermelho em duas partes; porque a sua benignidade dura para sempre;',
        'E fez passar Israel pelo meio dele; porque a sua benignidade dura para sempre;',
        'Mas derrubou a Faraó com o seu exército no Mar Vermelho; porque a sua benignidade dura para sempre.',
        'Aquele que guiou o seu povo pelo deserto; porque a sua benignidade dura para sempre;',
        'Aquele que feriu os grandes reis; porque a sua benignidade dura para sempre;',
        'E matou reis famosos; porque a sua benignidade dura para sempre;',
        'Siom, rei dos amorreus; porque a sua benignidade dura para sempre;',
        'E Ogue, rei de Basã; porque a sua benignidade dura para sempre;',
        'E deu a terra deles em herança; porque a sua benignidade dura para sempre;',
        'E mesmo em herança a Israel, seu servo; porque a sua benignidade dura para sempre;',
        'Que se lembrou da nossa baixeza; porque a sua benignidade dura para sempre;',
        'E nos remiu dos nossos inimigos; porque a sua benignidade dura para sempre;',
        'O que dá mantimento a toda a carne; porque a sua benignidade dura para sempre.',
        'Louvai ao Deus dos céus; porque a sua benignidade dura para sempre.',
      ],
      [
        'Junto aos rios da Babilônia, ali nos assentamos e choramos, quando nos lembramos de Sião.',
        'Sobre os salgueiros que há no meio dela, penduramos as nossas harpas.',
        'Pois lá aqueles que nos levaram cativos nos pediam uma canção; e os que nos destruíram, que os alegrássemos, dizendo: Cantai-nos uma das canções de Sião.',
        'Como cantaremos a canção do Senhor em terra estranha?',
        'Se eu me esquecer de ti, ó Jerusalém, esqueça-se a minha direita da sua destreza.',
        'Se me não lembrar de ti, apegue-se-me a língua ao meu paladar; se não preferir Jerusalém à minha maior alegria.',
        'Lembra-te, Senhor, dos filhos de Edom no dia de Jerusalém, que diziam: Descobri-a, descobri-a até aos seus alicerces.',
        'Ah! filha de babilônia, que vais ser assolada; feliz aquele que te retribuir o pago que tu nos pagaste a nós.',
        'Feliz aquele que pegar em teus filhos e der com eles nas pedras.',
      ],
      [
        'Eu te louvarei, de todo o meu coração; na presença dos deuses a ti cantarei louvores.',
        'Inclinar-me-ei para o teu santo templo, e louvarei o teu nome pela tua benignidade, e pela tua verdade; pois engrandeceste a tua palavra acima de todo o teu nome.',
        'No dia em que eu clamei, me escutaste; e alentaste com força a minha alma.',
        'Todos os reis da terra te louvarão, ó Senhor, quando ouvirem as palavras da tua boca;',
        'E cantarão os caminhos do Senhor; pois grande é a glória do Senhor.',
        'Ainda que o Senhor é excelso, atenta todavia para o humilde; mas ao soberbo conhece-o de longe.',
        'Andando eu no meio da angústia, tu me reviverás; estenderás a tua mão contra a ira dos meus inimigos, e a tua destra me salvará.',
        'O Senhor aperfeiçoará o que me toca; a tua benignidade, ó Senhor, dura para sempre; não desampares as obras das tuas mãos.',
      ],
      [
        'SENHOR, tu me sondaste, e me conheces.',
        'Tu sabes o meu assentar e o meu levantar; de longe entendes o meu pensamento.',
        'Cercas o meu andar, e o meu deitar; e conheces todos os meus caminhos.',
        'Não havendo ainda palavra alguma na minha língua, eis que logo, ó Senhor, tudo conheces.',
        'Tu me cercaste por detrás e por diante, e puseste sobre mim a tua mão.',
        'Tal ciência é para mim maravilhosíssima; tão alta que não a posso atingir.',
        'Para onde me irei do teu espírito, ou para onde fugirei da tua face?',
        'Se subir ao céu, lá tu estás; se fizer no inferno a minha cama, eis que tu ali estás também.',
        'Se tomar as asas da alva, se habitar nas extremidades do mar,',
        'Até ali a tua mão me guiará e a tua destra me susterá.',
        'Se disser: Decerto que as trevas me encobrirão; então a noite será luz à roda de mim.',
        'Nem ainda as trevas me encobrem de ti; mas a noite resplandece como o dia; as trevas e a luz são para ti a mesma coisa;',
        'Pois possuíste os meus rins; cobriste-me no ventre de minha mãe.',
        'Eu te louvarei, porque de um modo assombroso, e tão maravilhoso fui feito; maravilhosas são as tuas obras, e a minha alma o sabe muito bem.',
        'Os meus ossos não te foram encobertos, quando no oculto fui feito, e entretecido nas profundezas da terra.',
        'Os teus olhos viram o meu corpo ainda informe; e no teu livro todas estas coisas foram escritas; as quais em continuação foram formadas, quando nem ainda uma delas havia.',
        'E quão preciosos me são, ó Deus, os teus pensamentos! Quão grandes são as somas deles!',
        'Se as contasse, seriam em maior número do que a areia; quando acordo ainda estou contigo.',
        'Ó Deus, tu matarás decerto o ímpio; apartai-vos portanto de mim, homens de sangue.',
        'Pois falam malvadamente contra ti; e os teus inimigos tomam o teu nome em vão.',
        'Não odeio eu, ó Senhor, aqueles que te odeiam, e não me aflijo por causa dos que se levantam contra ti?',
        'Odeio-os com ódio perfeito; tenho-os por inimigos.',
        'Sonda-me, ó Deus, e conhece o meu coração; prova-me, e conhece os meus pensamentos.',
        'E vê se há em mim algum caminho mau, e guia-me pelo caminho eterno.',
      ],
      [
        'Livra-me, ó SENHOR, do homem mau; guarda-me do homem violento,',
        'Que pensa o mal no coração; continuamente se ajuntam para a guerra.',
        'Aguçaram as línguas como a serpente; o veneno das víboras está debaixo dos seus lábios. (Selá.)',
        'Guarda-me, ó Senhor, das mãos do ímpio; guarda-me do homem violento; os quais se propuseram transtornar os meus passos.',
        'Os soberbos armaram-me laços e cordas; estenderam a rede ao lado do caminho; armaram-me laços corrediços. (Selá.)',
        'Eu disse ao Senhor: Tu és o meu Deus; ouve a voz das minhas súplicas, ó Senhor.',
        'Ó Deus o Senhor, fortaleza da minha salvação, tu cobriste a minha cabeça no dia da batalha.',
        'Não concedas, ó Senhor, ao ímpio os seus desejos; não promovas o seu mau propósito, para que não se exalte. (Selá.)',
        'Quanto à cabeça dos que me cercam, cubra-os a maldade dos seus lábios.',
        'Caiam sobre eles brasas vivas; sejam lançados no fogo, em covas profundas, para que se não tornem a levantar.',
        'Não terá firmeza na terra o homem de má língua; o mal perseguirá o homem violento até que seja desterrado.',
        'Sei que o Senhor sustentará a causa do oprimido, e o direito do necessitado.',
        'Assim os justos louvarão o teu nome; os retos habitarão na tua presença.',
      ],
      [
        'SENHOR, a ti clamo, escuta-me; inclina os teus ouvidos à minha voz, quando a ti clamar.',
        'Suba a minha oração perante a tua face como incenso, e as minhas mãos levantadas sejam como o sacrifício da tarde.',
        'Põe, ó Senhor, uma guarda à minha boca; guarda a porta dos meus lábios.',
        'Não inclines o meu coração a coisas más, a praticar obras más, com aqueles que praticam a iniqüidade; e não coma das suas delícias.',
        'Fira-me o justo, será isso uma benignidade; e repreenda-me, será um excelente óleo, que não me quebrará a cabeça; pois a minha oração também ainda continuará nas suas próprias calamidades.',
        'Quando os seus juízes forem derrubados pelos lados da rocha, ouvirão as minhas palavras, pois são agradáveis.',
        'Os nossos ossos são espalhados à boca da sepultura como se alguém fendera e partira lenha na terra.',
        'Mas os meus olhos te contemplam, ó DEUS o Senhor; em ti confio; não desnudes a minha alma.',
        'Guarda-me dos laços que me armaram; e dos laços corrediços dos que praticam a iniqüidade.',
        'Caiam os ímpios nas suas próprias redes, até que eu tenha escapado inteiramente.',
      ],
      [
        'Com a minha voz clamei ao SENHOR; com a minha voz supliquei ao SENHOR.',
        'Derramei a minha queixa perante a sua face; expus-lhe a minha angústia.',
        'Quando o meu espírito estava angustiado em mim, então conheceste a minha vereda. No caminho em que eu andava, esconderam-me um laço.',
        'Olhei para a minha direita, e vi; mas não havia quem me conhecesse. Refúgio me faltou; ninguém cuidou da minha alma.',
        'A ti, ó Senhor, clamei; eu disse: Tu és o meu refúgio, e a minha porção na terra dos viventes.',
        'Atende ao meu clamor; porque estou muito abatido. Livra-me dos meus perseguidores; porque são mais fortes do que eu.',
        'Tira a minha alma da prisão, para que louve o teu nome; os justos me rodearão, pois me fizeste bem.',
      ],
      [
        'Ó Senhor, ouve a minha oração, inclina os ouvidos às minhas súplicas; escuta-me segundo a tua verdade, e segundo a tua justiça.',
        'E não entres em juízo com o teu servo, porque à tua vista não se achará justo nenhum vivente.',
        'Pois o inimigo perseguiu a minha alma; atropelou-me até ao chão; fez-me habitar na escuridão, como aqueles que morreram há muito.',
        'Pois que o meu espírito se angustia em mim; e o meu coração em mim está desolado.',
        'Lembro-me dos dias antigos; considero todos os teus feitos; medito na obra das tuas mãos.',
        'Estendo para ti as minhas mãos; a minha alma tem sede de ti, como terra sedenta. (Selá.)',
        'Ouve-me depressa, ó Senhor; o meu espírito desmaia. Não escondas de mim a tua face, para que não seja semelhante aos que descem à cova.',
        'Faze-me ouvir a tua benignidade pela manhã, pois em ti confio; faze-me saber o caminho que devo seguir, porque a ti levanto a minha alma.',
        'Livra-me, ó Senhor, dos meus inimigos; fujo para ti, para me esconder.',
        'Ensina-me a fazer a tua vontade, pois és o meu Deus. O teu Espírito é bom; guie-me por terra plana.',
        'Vivifica-me, ó Senhor, por amor do teu nome; por amor da tua justiça, tira a minha alma da angústia.',
        'E por tua misericórdia desarraiga os meus inimigos, e destrói a todos os que angustiam a minha alma; pois sou teu servo.',
      ],
      [
        'Bendito seja o SENHOR, minha rocha, que ensina as minhas mãos para a peleja e os meus dedos para a guerra;',
        'Benignidade minha e fortaleza minha; alto retiro meu e meu libertador és tu; escudo meu, em quem eu confio, e que me sujeita o meu povo.',
        'Senhor, que é o homem, para que o conheças, e o filho do homem, para que o estimes?',
        'O homem é semelhante à vaidade; os seus dias são como a sombra que passa.',
        'Abaixa, ó Senhor, os teus céus, e desce; toca os montes, e fumegarão.',
        'Vibra os teus raios e dissipa-os; envia as tuas flechas, e desbarata-os.',
        'Estende as tuas mãos desde o alto; livra-me, e arrebata-me das muitas águas e das mãos dos filhos estranhos,',
        'Cuja boca fala vaidade, e a sua mão direita é a destra de falsidade.',
        'A ti, ó Deus, cantarei um cântico novo; com o saltério e instrumento de dez cordas te cantarei louvores;',
        'A ti, que dás a salvação aos reis, e que livras a Davi, teu servo, da espada maligna.',
        'Livra-me, e tira-me das mãos dos filhos estranhos, cuja boca fala vaidade, e a sua mão direita é a destra de iniqüidade,',
        'Para que nossos filhos sejam como plantas crescidas na sua mocidade; para que as nossas filhas sejam como pedras de esquina lavradas à moda de palácio;',
        'Para que as nossas despensas se encham de todo provimento; para que os nossos rebanhos produzam a milhares e a dezenas de milhares nas nossas ruas.',
        'Para que os nossos bois sejam fortes para o trabalho; para que não haja nem assaltos, nem saídas, nem gritos nas nossas ruas.',
        'Bem-aventurado o povo ao qual assim acontece; bem-aventurado é o povo cujo Deus é o Senhor.',
      ],
      [
        'Eu te exaltarei, ó Deus, rei meu, e bendirei o teu nome pelos séculos dos séculos e para sempre.',
        'Cada dia te bendirei, e louvarei o teu nome pelos séculos dos séculos e para sempre.',
        'Grande é o Senhor, e muito digno de louvor, e a sua grandeza inescrutável.',
        'Uma geração louvará as tuas obras à outra geração, e anunciarão as tuas proezas.',
        'Falarei da magnificência gloriosa da tua majestade e das tuas obras maravilhosas.',
        'E se falará da força dos teus feitos terríveis; e contarei a tua grandeza.',
        'Proferirão abundantemente a memória da tua grande bondade, e cantarão a tua justiça.',
        'Piedoso e benigno é o Senhor, sofredor e de grande misericórdia.',
        'O Senhor é bom para todos, e as suas misericórdias são sobre todas as suas obras.',
        'Todas as tuas obras te louvarão, ó Senhor, e os teus santos te bendirão.',
        'Falarão da glória do teu reino, e relatarão o teu poder,',
        'Para fazer saber aos filhos dos homens as tuas proezas e a glória da magnificência do teu reino.',
        'O teu reino é um reino eterno; o teu domínio dura em todas as gerações.',
        'O Senhor sustenta a todos os que caem, e levanta a todos os abatidos.',
        'Os olhos de todos esperam em ti, e lhes dás o seu mantimento a seu tempo.',
        'Abres a tua mão, e fartas os desejos de todos os viventes.',
        'Justo é o Senhor em todos os seus caminhos, e santo em todas as suas obras.',
        'Perto está o Senhor de todos os que o invocam, de todos os que o invocam em verdade.',
        'Ele cumprirá o desejo dos que o temem; ouvirá o seu clamor, e os salvará.',
        'O Senhor guarda a todos os que o amam; mas todos os ímpios serão destruídos.',
        'A minha boca falará o louvor do Senhor, e toda a carne louvará o seu santo nome pelos séculos dos séculos e para sempre.',
      ],
      [
        'Louvai ao Senhor. Ó minha alma, louva ao Senhor.',
        'Louvarei ao Senhor durante a minha vida; cantarei louvores ao meu Deus enquanto eu for vivo.',
        'Não confieis em príncipes, nem em filho de homem, em quem não há salvação.',
        'Sai-lhe o espírito, volta para a terra; naquele mesmo dia perecem os seus pensamentos.',
        'Bem-aventurado aquele que tem o Deus de Jacó por seu auxílio, e cuja esperança está posta no Senhor seu Deus.',
        'O que fez os céus e a terra, o mar e tudo quanto há neles, e o que guarda a verdade para sempre;',
        'O que faz justiça aos oprimidos, o que dá pão aos famintos. O Senhor solta os encarcerados.',
        'O Senhor abre os olhos aos cegos; o Senhor levanta os abatidos; o Senhor ama os justos;',
        'O Senhor guarda os estrangeiros; sustém o órfão e a viúva, mas transtorna o caminho dos ímpios.',
        'O Senhor reinará eternamente; o teu Deus, ó Sião, de geração em geração. Louvai ao Senhor.',
      ],
      [
        'Louvai ao SENHOR, porque é bom cantar louvores ao nosso Deus, porque é agradável; decoroso é o louvor.',
        'O Senhor edifica a Jerusalém, congrega os dispersos de Israel.',
        'Sara os quebrantados de coração, e lhes ata as suas feridas.',
        'Conta o número das estrelas, chama-as a todas pelos seus nomes.',
        'Grande é o nosso Senhor, e de grande poder; o seu entendimento é infinito.',
        'O Senhor eleva os humildes, e abate os ímpios até à terra.',
        'Cantai ao Senhor em ação de graças; cantai louvores ao nosso Deus sobre a harpa.',
        'Ele é o que cobre o céu de nuvens, o que prepara a chuva para a terra, e o que faz produzir erva sobre os montes;',
        'O que dá aos animais o seu sustento, e aos filhos dos corvos, quando clamam.',
        'Não se deleita na força do cavalo, nem se compraz nas pernas do homem.',
        'O Senhor se agrada dos que o temem e dos que esperam na sua misericórdia.',
        'Louva, ó Jerusalém, ao Senhor; louva, ó Sião, ao teu Deus.',
        'Porque fortaleceu os ferrolhos das tuas portas; abençoa aos teus filhos dentro de ti.',
        'Ele é o que põe em paz os teus termos, e da flor da farinha te farta.',
        'O que envia o seu mandamento à terra; a sua palavra corre velozmente.',
        'O que dá a neve como lã; esparge a geada como cinza;',
        'O que lança o seu gelo em pedaços; quem pode resistir ao seu frio?',
        'Manda a sua palavra, e os faz derreter; faz soprar o vento, e correm as águas.',
        'Mostra a sua palavra a Jacó, os seus estatutos e os seus juízos a Israel.',
        'Não fez assim a nenhuma outra nação; e quanto aos seus juízos, não os conhecem. Louvai ao Senhor.',
      ],
      [
        'Louvai ao SENHOR. Louvai ao SENHOR desde os céus, louvai-o nas alturas.',
        'Louvai-o, todos os seus anjos; louvai-o, todos os seus exércitos.',
        'Louvai-o, sol e lua; louvai-o, todas as estrelas luzentes.',
        'Louvai-o, céus dos céus, e as águas que estão sobre os céus.',
        'Louvem o nome do Senhor, pois mandou, e logo foram criados.',
        'E os confirmou eternamente para sempre, e lhes deu um decreto que não ultrapassarão.',
        'Louvai ao Senhor desde a terra: vós, baleias, e todos os abismos;',
        'Fogo e saraiva, neve e vapores, e vento tempestuoso que executa a sua palavra;',
        'Montes e todos os outeiros, árvores frutíferas e todos os cedros;',
        'As feras e todos os gados, répteis e aves voadoras;',
        'Reis da terra e todos os povos, príncipes e todos os juízes da terra;',
        'Moços e moças, velhos e crianças.',
        'Louvem o nome do Senhor, pois só o seu nome é exaltado; a sua glória está sobre a terra e o céu.',
        'Ele também exalta o poder do seu povo, o louvor de todos os seus santos, dos filhos de Israel, um povo que lhe é chegado. Louvai ao Senhor.',
      ],
      [
        'Louvai ao SENHOR. Cantai ao SENHOR um cântico novo, e o seu louvor na congregação dos santos.',
        'Alegre-se Israel naquele que o fez, regozijem-se os filhos de Sião no seu Rei.',
        'Louvem o seu nome com danças; cantem-lhe o seu louvor com tamborim e harpa.',
        'Porque o Senhor se agrada do seu povo; ornará os mansos com a salvação.',
        'Exultem os santos na glória; alegrem-se nas suas camas.',
        'Estejam na sua garganta os altos louvores de Deus, e espada de dois fios nas suas mãos,',
        'Para tomarem vingança dos gentios, e darem repreensões aos povos;',
        'Para prenderem os seus reis com cadeias, e os seus nobres com grilhões de ferro;',
        'Para fazerem neles o juízo escrito; esta será a honra de todos os seus santos. Louvai ao Senhor.',
      ],
      [
        'Louvai ao SENHOR. Louvai a Deus no seu santuário; louvai-o no firmamento do seu poder.',
        'Louvai-o pelos seus atos poderosos; louvai-o conforme a excelência da sua grandeza.',
        'Louvai-o com o som de trombeta; louvai-o com o saltério e a harpa.',
        'Louvai-o com o tamborim e a dança, louvai-o com instrumentos de cordas e com órgãos.',
        'Louvai-o com os címbalos sonoros; louvai-o com címbalos altissonantes.',
        'Tudo quanto tem fôlego louve ao Senhor. Louvai ao Senhor.',
      ],
    ],
    livro: 'salmos',
  },
  {
    abbrev: 'pv',
    capitulos: [
      [
        'Provérbios de Salomão, filho de Davi, rei de Israel;',
        'Para se conhecer a sabedoria e a instrução; para se entenderem, as palavras da prudência.',
        'Para se receber a instrução do entendimento, a justiça, o juízo e a eqüidade;',
        'Para dar aos simples, prudência, e aos moços, conhecimento e bom siso;',
        'O sábio ouvirá e crescerá em conhecimento, e o entendido adquirirá sábios conselhos;',
        'Para entender os provérbios e sua interpretação; as palavras dos sábios e as suas proposições.',
        'O temor do Senhor é o princípio do conhecimento; os loucos desprezam a sabedoria e a instrução.',
        'Filho meu, ouve a instrução de teu pai, e não deixes o ensinamento de tua mãe,',
        'Porque serão como diadema gracioso em tua cabeça, e colares ao teu pescoço.',
        'Filho meu, se os pecadores procuram te atrair com agrados, não aceites.',
        'Se disserem: Vem conosco a tocaias de sangue; embosquemos o inocente sem motivo;',
        'Traguemo-los vivos, como a sepultura; e inteiros, como os que descem à cova;',
        'Acharemos toda sorte de bens preciosos; encheremos as nossas casas de despojos;',
        'Lança a tua sorte conosco; teremos todos uma só bolsa!',
        'Filho meu, não te ponhas a caminho com eles; desvia o teu pé das suas veredas;',
        'Porque os seus pés correm para o mal, e se apressam a derramar sangue.',
        'Na verdade é inútil estender-se a rede ante os olhos de qualquer ave.',
        'No entanto estes armam ciladas contra o seu próprio sangue; e espreitam suas próprias vidas.',
        'São assim as veredas de todo aquele que usa de cobiça: ela põe a perder a alma dos que a possuem.',
        'A sabedoria clama lá fora; pelas ruas levanta a sua voz.',
        'Nas esquinas movimentadas ela brada; nas entradas das portas e nas cidades profere as suas palavras:',
        'Até quando, ó simples, amareis a simplicidade? E vós escarnecedores, desejareis o escárnio? E vós insensatos, odiareis o conhecimento?',
        'Atentai para a minha repreensão; pois eis que vos derramarei abundantemente do meu espírito e vos farei saber as minhas palavras.',
        'Entretanto, porque eu clamei e recusastes; e estendi a minha mão e não houve quem desse atenção,',
        'Antes rejeitastes todo o meu conselho, e não quisestes a minha repreensão,',
        'Também de minha parte eu me rirei na vossa perdição e zombarei, em vindo o vosso temor.',
        'Vindo o vosso temor como a assolação, e vindo a vossa perdição como uma tormenta, sobrevirá a vós aperto e angústia.',
        'Então clamarão a mim, mas eu não responderei; de madrugada me buscarão, porém não me acharão.',
        'Porquanto odiaram o conhecimento; e não preferiram o temor do Senhor:',
        'Não aceitaram o meu conselho, e desprezaram toda a minha repreensão.',
        'Portanto comerão do fruto do seu caminho, e fartar-se-ão dos seus próprios conselhos.',
        'Porque o erro dos simples os matará, e o desvario dos insensatos os destruirá.',
        'Mas o que me der ouvidos habitará em segurança, e estará livre do temor do mal.',
      ],
      [
        'Filho meu, se aceitares as minhas palavras, e esconderes contigo os meus mandamentos,',
        'Para fazeres o teu ouvido atento à sabedoria; e inclinares o teu coração ao entendimento;',
        'Se clamares por conhecimento, e por inteligência alçares a tua voz,',
        'Se como a prata a buscares e como a tesouros escondidos a procurares,',
        'Então entenderás o temor do Senhor, e acharás o conhecimento de Deus.',
        'Porque o Senhor dá a sabedoria; da sua boca é que vem o conhecimento e o entendimento.',
        'Ele reserva a verdadeira sabedoria para os retos. Escudo é para os que caminham na sinceridade,',
        'Para que guardem as veredas do juízo. Ele preservará o caminho dos seus santos.',
        'Então entenderás a justiça, o juízo, a eqüidade e todas as boas veredas.',
        'Pois quando a sabedoria entrar no teu coração, e o conhecimento for agradável à tua alma,',
        'O bom siso te guardará e a inteligência te conservará;',
        'Para te afastar do mau caminho, e do homem que fala coisas perversas;',
        'Dos que deixam as veredas da retidão, para andarem pelos caminhos escusos;',
        'Que se alegram de fazer mal, e folgam com as perversidades dos maus,',
        'Cujas veredas são tortuosas e que se desviam nos seus caminhos;',
        'Para te afastar da mulher estranha, sim da estranha que lisonjeia com suas palavras;',
        'Que deixa o guia da sua mocidade e se esquece da aliança do seu Deus;',
        'Porque a sua casa se inclina para a morte, e as suas veredas para os mortos.',
        'Todos os que se dirigem a ela não voltarão e não atinarão com as veredas da vida.',
        'Para andares pelos caminhos dos bons, e te conservares nas veredas dos justos.',
        'Porque os retos habitarão a terra, e os íntegros permanecerão nela.',
        'Mas os ímpios serão arrancados da terra, e os aleivosos serão dela exterminados.',
      ],
      [
        'Filho meu, não te esqueças da minha lei, e o teu coração guarde os meus mandamentos.',
        'Porque eles aumentarão os teus dias e te acrescentarão anos de vida e paz.',
        'Não te desamparem a benignidade e a fidelidade; ata-as ao teu pescoço; escreve-as na tábua do teu coração.',
        'E acharás graça e bom entendimento aos olhos de Deus e do homem.',
        'Confia no Senhor de todo o teu coração, e não te estribes no teu próprio entendimento.',
        'Reconhece-o em todos os teus caminhos, e ele endireitará as tuas veredas.',
        'Não sejas sábio a teus próprios olhos; teme ao Senhor e aparta-te do mal.',
        'Isto será saúde para o teu âmago, e medula para os teus ossos.',
        'Honra ao Senhor com os teus bens, e com a primeira parte de todos os teus ganhos;',
        'E se encherão os teus celeiros, e transbordarão de vinho os teus lagares.',
        'Filho meu, não rejeites a correção do Senhor, nem te enojes da sua repreensão.',
        'Porque o Senhor repreende aquele a quem ama, assim como o pai ao filho a quem quer bem.',
        'Bem-aventurado o homem que acha sabedoria, e o homem que adquire conhecimento;',
        'Porque é melhor a sua mercadoria do que artigos de prata, e maior o seu lucro que o ouro mais fino.',
        'Mais preciosa é do que os rubis, e tudo o que mais possas desejar não se pode comparar a ela.',
        'Vida longa de dias está na sua mão direita; e na esquerda, riquezas e honra.',
        'Os seus caminhos são caminhos de delícias, e todas as suas veredas de paz.',
        'É árvore de vida para os que dela tomam, e são bem-aventurados todos os que a retêm.',
        'O Senhor, com sabedoria fundou a terra; com entendimento preparou os céus.',
        'Pelo seu conhecimento se fenderam os abismos, e as nuvens destilam o orvalho.',
        'Filho meu, não se apartem estas coisas dos teus olhos: guarda a verdadeira sabedoria e o bom siso;',
        'Porque serão vida para a tua alma, e adorno ao teu pescoço.',
        'Então andarás confiante pelo teu caminho, e o teu pé não tropeçará.',
        'Quando te deitares, não temerás; ao contrário, o teu sono será suave ao te deitares.',
        'Não temas o pavor repentino, nem a investida dos perversos quando vier.',
        'Porque o Senhor será a tua esperança; guardará os teus pés de serem capturados.',
        'Não deixes de fazer bem a quem o merece, estando em tuas mãos a capacidade de fazê-lo.',
        'Não digas ao teu próximo: Vai, e volta amanhã que to darei, se já o tens contigo.',
        'Não maquines o mal contra o teu próximo, pois que habita contigo confiadamente.',
        'Não contendas com alguém sem causa, se não te fez nenhum mal.',
        'Não tenhas inveja do homem violento, nem escolhas nenhum dos seus caminhos.',
        'Porque o perverso é abominável ao Senhor, mas com os sinceros ele tem intimidade.',
        'A maldição do Senhor habita na casa do ímpio, mas a habitação dos justos abençoará.',
        'Certamente ele escarnecerá dos escarnecedores, mas dará graça aos mansos.',
        'Os sábios herdarão honra, mas os loucos tomam sobre si vergonha.',
      ],
      [
        'Ouvi, filhos, a instrução do pai, e estai atentos para conhecerdes a prudência.',
        'Pois dou-vos boa doutrina; não deixeis a minha lei.',
        'Porque eu era filho tenro na companhia de meu pai, e único diante de minha mãe.',
        'E ele me ensinava e me dizia: Retenha o teu coração as minhas palavras; guarda os meus mandamentos, e vive.',
        'Adquire sabedoria, adquire inteligência, e não te esqueças nem te apartes das palavras da minha boca.',
        'Não a abandones e ela te guardará; ama-a, e ela te protegerá.',
        'A sabedoria é a coisa principal; adquire pois a sabedoria, emprega tudo o que possuis na aquisição de entendimento.',
        'Exalta-a, e ela te exaltará; e, abraçando-a tu, ela te honrará.',
        'Dará à tua cabeça um diadema de graça e uma coroa de glória te entregará.',
        'Ouve, filho meu, e aceita as minhas palavras, e se multiplicarão os anos da tua vida.',
        'No caminho da sabedoria te ensinei, e por veredas de retidão te fiz andar.',
        'Por elas andando, não se embaraçarão os teus passos; e se correres não tropeçarás.',
        'Apega-te à instrução e não a largues; guarda-a, porque ela é a tua vida.',
        'Não entres pela vereda dos ímpios, nem andes no caminho dos maus.',
        'Evita-o; não passes por ele; desvia-te dele e passa de largo.',
        'Pois não dormem, se não fizerem mal, e foge deles o sono se não fizerem alguém tropeçar.',
        'Porque comem o pão da impiedade, e bebem o vinho da violência.',
        'Mas a vereda dos justos é como a luz da aurora, que vai brilhando mais e mais até ser dia perfeito.',
        'O caminho dos ímpios é como a escuridão; nem sabem em que tropeçam.',
        'Filho meu, atenta para as minhas palavras; às minhas razões inclina o teu ouvido.',
        'Não as deixes apartar-se dos teus olhos; guarda-as no íntimo do teu coração.',
        'Porque são vida para os que as acham, e saúde para todo o seu corpo.',
        'Sobre tudo o que se deve guardar, guarda o teu coração, porque dele procedem as fontes da vida.',
        'Desvia de ti a falsidade da boca, e afasta de ti a perversidade dos lábios.',
        'Os teus olhos olhem para a frente, e as tuas pálpebras olhem direto diante de ti.',
        'Pondera a vereda de teus pés, e todos os teus caminhos sejam bem ordenados!',
        'Não declines nem para a direita nem para a esquerda; retira o teu pé do mal.',
      ],
      [
        'Filho meu, atende à minha sabedoria; à minha inteligência inclina o teu ouvido;',
        'Para que guardes os meus conselhos e os teus lábios observem o conhecimento.',
        'Porque os lábios da mulher estranha destilam favos de mel, e o seu paladar é mais suave do que o azeite.',
        'Mas o seu fim é amargoso como o absinto, agudo como a espada de dois gumes.',
        'Os seus pés descem para a morte; os seus passos estão impregnados do inferno.',
        'Para que não ponderes os caminhos da vida, as suas andanças são errantes: jamais os conhecerás.',
        'Agora, pois, filhos, dai-me ouvidos, e não vos desvieis das palavras da minha boca.',
        'Longe dela seja o teu caminho, e não te chegues à porta da sua casa;',
        'Para que não dês a outrem a tua honra, e não entregues a cruéis os teus anos de vida;',
        'Para que não farte a estranhos o teu esforço, e todo o fruto do teu trabalho vá parar em casa alheia;',
        'E no fim venhas a gemer, no consumir-se da tua carne e do teu corpo.',
        'E então digas: Como odiei a correção! e o meu coração desprezou a repreensão!',
        'E não escutei a voz dos que me ensinavam, nem aos meus mestres inclinei o meu ouvido!',
        'No meio da congregação e da assembléia foi que eu me achei em quase todo o mal.',
        'Bebe água da tua fonte, e das correntes do teu poço.',
        'Derramar-se-iam as tuas fontes por fora, e pelas ruas os ribeiros de águas?',
        'Sejam para ti só, e não para os estranhos contigo.',
        'Seja bendito o teu manancial, e alegra-te com a mulher da tua mocidade.',
        'Como cerva amorosa, e gazela graciosa, os seus seios te saciem todo o tempo; e pelo seu amor sejas atraído perpetuamente.',
        'E porque, filho meu, te deixarias atrair por outra mulher, e te abraçarias ao peito de uma estranha?',
        'Eis que os caminhos do homem estão perante os olhos do Senhor, e ele pesa todas as suas veredas.',
        'Quanto ao ímpio, as suas iniqüidades o prenderão, e com as cordas do seu pecado será detido.',
        'Ele morrerá, porque desavisadamente andou, e pelo excesso da sua loucura se perderá.',
      ],
      [
        'Filho meu, se ficaste por fiador do teu companheiro, se deste a tua mão ao estranho,',
        'E te deixaste enredar pelas próprias palavras; e te prendeste nas palavras da tua boca;',
        'Faze pois isto agora, filho meu, e livra-te, já que caíste nas mãos do teu companheiro: vai, humilha-te, e importuna o teu companheiro.',
        'Não dês sono aos teus olhos, nem deixes adormecer as tuas pálpebras.',
        'Livra-te, como a gazela da mão do caçador, e como a ave da mão do passarinheiro.',
        'Vai ter com a formiga, ó preguiçoso; olha para os seus caminhos, e sê sábio.',
        'Pois ela, não tendo chefe, nem guarda, nem dominador,',
        'Prepara no verão o seu pão; na sega ajunta o seu mantimento.',
        'Ó preguiçoso, até quando ficarás deitado? Quando te levantarás do teu sono?',
        'Um pouco a dormir, um pouco a tosquenejar; um pouco a repousar de braços cruzados;',
        'Assim sobrevirá a tua pobreza como o meliante, e a tua necessidade como um homem armado.',
        'O homem mau, o homem iníquo tem a boca pervertida.',
        'Acena com os olhos, fala com os pés e faz sinais com os dedos.',
        'Há no seu coração perversidade, todo o tempo maquina mal; anda semeando contendas.',
        'Por isso a sua destruição virá repentinamente; subitamente será quebrantado, sem que haja cura.',
        'Estas seis coisas o Senhor odeia, e a sétima a sua alma abomina:',
        'Olhos altivos, língua mentirosa, mãos que derramam sangue inocente,',
        'O coração que maquina pensamentos perversos, pés que se apressam a correr para o mal,',
        'A testemunha falsa que profere mentiras, e o que semeia contendas entre irmãos.',
        'Filho meu, guarda o mandamento de teu pai, e não deixes a lei da tua mãe;',
        'Ata-os perpetuamente ao teu coração, e pendura-os ao teu pescoço.',
        'Quando caminhares, te guiará; quando te deitares, te guardará; quando acordares, falará contigo.',
        'Porque o mandamento é lâmpada, e a lei é luz; e as repreensões da correção são o caminho da vida,',
        'Para te guardarem da mulher vil, e das lisonjas da estranha.',
        'Não cobices no teu coração a sua formosura, nem te prendas aos seus olhos.',
        'Porque por causa duma prostituta se chega a pedir um bocado de pão; e a adúltera anda à caça da alma preciosa.',
        'Porventura tomará alguém fogo no seu seio, sem que suas vestes se queimem?',
        'Ou andará alguém sobre brasas, sem que se queimem os seus pés?',
        'Assim ficará o que entrar à mulher do seu próximo; não será inocente todo aquele que a tocar.',
        'Não se injuria o ladrão, quando furta para saciar-se, tendo fome;',
        'E se for achado pagará o tanto sete vezes; terá de dar todos os bens da sua casa.',
        'Assim, o que adultera com uma mulher é falto de entendimento; aquele que faz isso destrói a sua alma.',
        'Achará castigo e vilipêndio, e o seu opróbrio nunca se apagará.',
        'Porque os ciúmes enfurecerão o marido; de maneira nenhuma perdoará no dia da vingança.',
        'Não aceitará nenhum resgate, nem se conformará por mais que aumentes os presentes.',
      ],
      [
        'Filho meu, guarda as minhas palavras, e esconde dentro de ti os meus mandamentos.',
        'Guarda os meus mandamentos e vive; e a minha lei, como a menina dos teus olhos.',
        'Ata-os aos teus dedos, escreve-os na tábua do teu coração.',
        'Dize à sabedoria: Tu és minha irmã; e à prudência chama de tua parenta,',
        'Para que elas te guardem da mulher alheia, da estranha que lisonjeia com as suas palavras.',
        'Porque da janela da minha casa, olhando eu por minhas frestas,',
        'Vi entre os simples, descobri entre os moços, um moço falto de juízo,',
        'Que passava pela rua junto à sua esquina, e seguia o caminho da sua casa;',
        'No crepúsculo, à tarde do dia, na tenebrosa noite e na escuridão.',
        'E eis que uma mulher lhe saiu ao encontro com enfeites de prostituta, e astúcia de coração.',
        'Estava alvoroçada e irrequieta; não paravam em sua casa os seus pés.',
        'Foi para fora, depois pelas ruas, e ia espreitando por todos os cantos;',
        'E chegou-se para ele e o beijou. Com face impudente lhe disse:',
        'Sacrifícios pacíficos tenho comigo; hoje paguei os meus votos.',
        'Por isto saí ao teu encontro a buscar diligentemente a tua face, e te achei.',
        'Já cobri a minha cama com cobertas de tapeçaria, com obras lavradas, com linho fino do Egito.',
        'Já perfumei o meu leito com mirra, aloés e canela.',
        'Vem, saciemo-nos de amores até à manhã; alegremo-nos com amores.',
        'Porque o marido não está em casa; foi fazer uma longa viagem;',
        'Levou na sua mão um saquitel de dinheiro; voltará para casa só no dia marcado.',
        'Assim, o seduziu com palavras muito suaves e o persuadiu com as lisonjas dos seus lábios.',
        'E ele logo a segue, como o boi que vai para o matadouro, e como vai o insensato para o castigo das prisões;',
        'Até que a flecha lhe atravesse o fígado; ou como a ave que se apressa para o laço, e não sabe que está armado contra a sua vida.',
        'Agora pois, filhos, dai-me ouvidos, e estai atentos às palavras da minha boca.',
        'Não se desvie para os caminhos dela o teu coração, e não te deixes perder nas suas veredas.',
        'Porque a muitos feridos derrubou; e são muitíssimos os que por causa dela foram mortos.',
        'A sua casa é caminho do inferno que desce para as câmaras da morte.',
      ],
      [
        'Não clama porventura a sabedoria, e a inteligência não faz ouvir a sua voz?',
        'No cume das alturas, junto ao caminho, nas encruzilhadas das veredas se posta.',
        'Do lado das portas da cidade, à entrada da cidade, e à entrada das portas está gritando:',
        'A vós, ó homens, clamo; e a minha voz se dirige aos filhos dos homens.',
        'Entendei, ó simples, a prudência; e vós, insensatos, entendei de coração.',
        'Ouvi, porque falarei coisas excelentes; os meus lábios se abrirão para a eqüidade.',
        'Porque a minha boca proferirá a verdade, e os meus lábios abominam a impiedade.',
        'São justas todas as palavras da minha boca: não há nelas nenhuma coisa tortuosa nem pervertida.',
        'Todas elas são retas para aquele que as entende bem, e justas para os que acham o conhecimento.',
        'Aceitai a minha correção, e não a prata; e o conhecimento, mais do que o ouro fino escolhido.',
        'Porque melhor é a sabedoria do que os rubis; e tudo o que mais se deseja não se pode comparar com ela.',
        'Eu, a sabedoria, habito com a prudência, e acho o conhecimento dos conselhos.',
        'O temor do Senhor é odiar o mal; a soberba e a arrogância, o mau caminho e a boca perversa, eu odeio.',
        'Meu é o conselho e a verdadeira sabedoria; eu sou o entendimento; minha é a fortaleza.',
        'Por mim reinam os reis e os príncipes decretam justiça.',
        'Por mim governam príncipes e nobres; sim, todos os juízes da terra.',
        'Eu amo aos que me amam, e os que cedo me buscarem, me acharão.',
        'Riquezas e honra estão comigo; assim como os bens duráveis e a justiça.',
        'Melhor é o meu fruto do que o ouro, do que o ouro refinado, e os meus ganhos mais do que a prata escolhida.',
        'Faço andar pelo caminho da justiça, no meio das veredas do juízo.',
        'Para que faça herdar bens permanentes aos que me amam, e eu encha os seus tesouros.',
        'O Senhor me possuiu no princípio de seus caminhos, desde então, e antes de suas obras.',
        'Desde a eternidade fui ungida, desde o princípio, antes do começo da terra.',
        'Quando ainda não havia abismos, fui gerada, quando ainda não havia fontes carregadas de águas.',
        'Antes que os montes se houvessem assentado, antes dos outeiros, eu fui gerada.',
        'Ainda ele não tinha feito a terra, nem os campos, nem o princípio do pó do mundo.',
        'Quando ele preparava os céus, aí estava eu, quando traçava o horizonte sobre a face do abismo;',
        'Quando firmava as nuvens acima, quando fortificava as fontes do abismo,',
        'Quando fixava ao mar o seu termo, para que as águas não traspassassem o seu mando, quando compunha os fundamentos da terra.',
        'Então eu estava com ele, e era seu arquiteto; era cada dia as suas delícias, alegrando-me perante ele em todo o tempo;',
        'Regozijando-me no seu mundo habitável e enchendo-me de prazer com os filhos dos homens.',
        'Agora, pois, filhos, ouvi-me, porque bem-aventurados serão os que guardarem os meus caminhos.',
        'Ouvi a instrução, e sede sábios, não a rejeiteis.',
        'Bem-aventurado o homem que me dá ouvidos, velando às minhas portas cada dia, esperando às ombreiras da minha entrada.',
        'Porque o que me achar, achará a vida, e alcançará o favor do Senhor.',
        'Mas o que pecar contra mim violentará a sua própria alma; todos os que me odeiam amam a morte.',
      ],
      [
        'A sabedoria já edificou a sua casa, já lavrou as suas sete colunas.',
        'Já abateu os seus animais e misturou o seu vinho, e já preparou a sua mesa.',
        'Já ordenou às suas criadas, e está convidando desde as alturas da cidade, dizendo:',
        'Quem é simples, volte-se para cá. Aos faltos de senso diz:',
        'Vinde, comei do meu pão, e bebei do vinho que tenho misturado.',
        'Deixai os insensatos e vivei; e andai pelo caminho do entendimento.',
        'O que repreende o escarnecedor, toma afronta para si; e o que censura o ímpio recebe a sua mancha.',
        'Não repreendas o escarnecedor, para que não te odeie; repreende o sábio, e ele te amará.',
        'Dá instrução ao sábio, e ele se fará mais sábio; ensina o justo e ele aumentará em entendimento.',
        'O temor do Senhor é o princípio da sabedoria, e o conhecimento do Santo a prudência.',
        'Porque por meu intermédio se multiplicam os teus dias, e anos de vida se te aumentarão.',
        'Se fores sábio, para ti serás sábio; e, se fores escarnecedor, só tu o suportarás.',
        'A mulher louca é alvoroçadora; é simples e nada sabe.',
        'Assenta-se à porta da sua casa numa cadeira, nas alturas da cidade,',
        'E põe-se a chamar aos que vão pelo caminho, e que passam reto pelas veredas, dizendo:',
        'Quem é simples, volte-se para cá. E aos faltos de entendimento ela diz:',
        'As águas roubadas são doces, e o pão tomado às escondidas é agradável.',
        'Mas não sabem que ali estão os mortos; os seus convidados estão nas profundezas do inferno.',
      ],
      [
        'Provérbios de Salomão: O filho sábio alegra a seu pai, mas o filho insensato é a tristeza de sua mãe.',
        'Os tesouros da impiedade de nada aproveitam; mas a justiça livra da morte.',
        'O Senhor não deixa o justo passar fome, mas rechaça a aspiração dos perversos.',
        'O que trabalha com mão displicente empobrece, mas a mão dos diligentes enriquece.',
        'O que ajunta no verão é filho ajuizado, mas o que dorme na sega é filho que envergonha.',
        'Bênçãos há sobre a cabeça do justo, mas a violência cobre a boca dos perversos.',
        'A memória do justo é abençoada, mas o nome dos perversos apodrecerá.',
        'O sábio de coração aceita os mandamentos, mas o insensato de lábios ficará transtornado.',
        'Quem anda em sinceridade, anda seguro; mas o que perverte os seus caminhos ficará conhecido.',
        'O que acena com os olhos causa dores, e o tolo de lábios ficará transtornado.',
        'A boca do justo é fonte de vida, mas a violência cobre a boca dos perversos.',
        'O ódio excita contendas, mas o amor cobre todos os pecados.',
        'Nos lábios do entendido se acha a sabedoria, mas a vara é para as costas do falto de entendimento.',
        'Os sábios entesouram a sabedoria; mas a boca do tolo o aproxima da ruína.',
        'Os bens do rico são a sua cidade forte, a pobreza dos pobres a sua ruína.',
        'A obra do justo conduz à vida, o fruto do perverso, ao pecado.',
        'O caminho para a vida é daquele que guarda a instrução, mas o que deixa a repreensão comete erro.',
        'O que encobre o ódio tem lábios falsos, e o que divulga má fama é um insensato.',
        'Na multidão de palavras não falta pecado, mas o que modera os seus lábios é sábio.',
        'Prata escolhida é a língua do justo; o coração dos perversos é de nenhum valor.',
        'Os lábios do justo apascentam a muitos, mas os tolos morrem por falta de entendimento.',
        'A bênção do Senhor é que enriquece; e não traz consigo dores.',
        'Para o tolo, o cometer desordem é divertimento; mas para o homem entendido é o ter sabedoria.',
        'Aquilo que o perverso teme sobrevirá a ele, mas o desejo dos justos será concedido.',
        'Como passa a tempestade, assim desaparece o perverso, mas o justo tem fundamento perpétuo.',
        'Como vinagre para os dentes, como fumaça para os olhos, assim é o preguiçoso para aqueles que o mandam.',
        'O temor do Senhor aumenta os dias, mas os perversos terão os anos da vida abreviados.',
        'A esperança dos justos é alegria, mas a expectação dos perversos perecerá.',
        'O caminho do Senhor é fortaleza para os retos, mas ruína para os que praticam a iniqüidade.',
        'O justo nunca jamais será abalado, mas os perversos não habitarão a terra.',
        'A boca do justo jorra sabedoria, mas a língua da perversidade será cortada.',
        'Os lábios do justo sabem o que agrada, mas a boca dos perversos, só perversidades.',
      ],
      [
        'Balança enganosa é abominação para o SENHOR, mas o peso justo é o seu prazer.',
        'Em vindo a soberba, virá também a afronta; mas com os humildes está a sabedoria.',
        'A sinceridade dos íntegros os guiará, mas a perversidade dos aleivosos os destruirá.',
        'De nada aproveitam as riquezas no dia da ira, mas a justiça livra da morte.',
        'A justiça do sincero endireitará o seu caminho, mas o perverso pela sua falsidade cairá.',
        'A justiça dos virtuosos os livrará, mas na sua perversidade serão apanhados os iníquos.',
        'Morrendo o homem perverso perece sua esperança, e acaba-se a expectação de riquezas.',
        'O justo é libertado da angústia, e vem o ímpio para o seu lugar.',
        'O hipócrita com a boca destrói o seu próximo, mas os justos se libertam pelo conhecimento.',
        'No bem dos justos exulta a cidade; e perecendo os ímpios, há júbilo.',
        'Pela bênção dos homens de bem a cidade se exalta, mas pela boca dos perversos é derrubada.',
        'O que despreza o seu próximo carece de entendimento, mas o homem entendido se mantém calado.',
        'O mexeriqueiro revela o segredo, mas o fiel de espírito o mantém em oculto.',
        'Não havendo sábios conselhos, o povo cai, mas na multidão de conselhos há segurança.',
        'Decerto sofrerá severamente aquele que fica por fiador do estranho, mas o que evita a fiança estará seguro.',
        'A mulher graciosa guarda a honra como os violentos guardam as riquezas.',
        'O homem bom cuida bem de si mesmo, mas o cruel prejudica o seu corpo.',
        'O ímpio faz obra falsa, mas para o que semeia justiça haverá galardão fiel.',
        'Como a justiça encaminha para a vida, assim o que segue o mal vai para a sua morte.',
        'Abominação ao Senhor são os perversos de coração, mas os de caminho sincero são o seu deleite.',
        'Ainda que junte as mãos, o mau não ficará impune, mas a semente dos justos será liberada.',
        'Como jóia de ouro no focinho de uma porca, assim é a mulher formosa que não tem discrição.',
        'O desejo dos justos é tão somente para o bem, mas a esperança dos ímpios é criar contrariedades.',
        'Ao que distribui mais se lhe acrescenta, e ao que retém mais do que é justo, é para a sua perda.',
        'A alma generosa prosperará e aquele que atende também será atendido.',
        'Ao que retém o trigo o povo amaldiçoa, mas bênção haverá sobre a cabeça do que o vende.',
        'O que cedo busca o bem, busca favor, mas o que procura o mal, esse lhe sobrevirá.',
        'Aquele que confia nas suas riquezas cairá, mas os justos reverdecerão como a folhagem.',
        'O que perturba a sua casa herdará o vento, e o tolo será servo do sábio de coração.',
        'O fruto do justo é árvore de vida, e o que ganha almas é sábio.',
        'Eis que o justo recebe na terra a retribuição; quanto mais o ímpio e o pecador!',
      ],
      [
        'O que ama a instrução ama o conhecimento, mas o que odeia a repreensão é estúpido.',
        'O homem de bem alcançará o favor do Senhor, mas ao homem de intenções perversas ele condenará.',
        'O homem não se estabelecerá pela impiedade, mas a raiz dos justos não será removida.',
        'A mulher virtuosa é a coroa do seu marido, mas a que o envergonha é como podridão nos seus ossos.',
        'Os pensamentos dos justos são retos, mas os conselhos dos ímpios, engano.',
        'As palavras dos ímpios são ciladas para derramar sangue, mas a boca dos retos os livrará.',
        'Os ímpios serão transtornados e não subsistirão, mas a casa dos justos permanecerá.',
        'Cada qual será louvado segundo o seu entendimento, mas o perverso de coração estará em desprezo.',
        'Melhor é o que se estima em pouco, e tem servos, do que o que se vangloria e tem falta de pão.',
        'O justo tem consideração pela vida dos seus animais, mas as afeições dos ímpios são cruéis.',
        'O que lavra a sua terra se fartará de pão; mas o que segue os ociosos é falto de juízo.',
        'O ímpio deseja a rede dos maus, mas a raiz dos justos produz o seu fruto.',
        'O ímpio se enlaça na transgressão dos lábios, mas o justo sairá da angústia.',
        'Cada um se fartará do fruto da sua boca, e da obra das suas mãos o homem receberá a recompensa.',
        'O caminho do insensato é reto aos seus próprios olhos, mas o que dá ouvidos ao conselho é sábio.',
        'A ira do insensato se conhece no mesmo dia, mas o prudente encobre a afronta.',
        'O que diz a verdade manifesta a justiça, mas a falsa testemunha diz engano.',
        'Há alguns que falam como que espada penetrante, mas a língua dos sábios é saúde.',
        'O lábio da verdade permanece para sempre, mas a língua da falsidade, dura por um só momento.',
        'No coração dos que maquinam o mal há engano, mas os que aconselham a paz têm alegria.',
        'Nenhum agravo sobrevirá ao justo, mas os ímpios ficam cheios de mal.',
        'Os lábios mentirosos são abomináveis ao Senhor, mas os que agem fielmente são o seu deleite.',
        'O homem prudente encobre o conhecimento, mas o coração dos tolos proclama a estultícia.',
        'A mão dos diligentes dominará, mas os negligentes serão tributários.',
        'A ansiedade no coração deixa o homem abatido, mas uma boa palavra o alegra.',
        'O justo é mais excelente do que o seu próximo, mas o caminho dos ímpios faz errar.',
        'O preguiçoso deixa de assar a sua caça, mas ser diligente é o precioso bem do homem.',
        'Na vereda da justiça está a vida, e no caminho da sua carreira não há morte.',
      ],
      [
        'O filho sábio atende à instrução do pai; mas o escarnecedor não ouve a repreensão.',
        'Do fruto da boca cada um comerá o bem, mas a alma dos prevaricadores comerá a violência.',
        'O que guarda a sua boca conserva a sua alma, mas o que abre muito os seus lábios se destrói.',
        'A alma do preguiçoso deseja, e coisa nenhuma alcança, mas a alma dos diligentes se farta.',
        'O justo odeia a palavra de mentira, mas o ímpio faz vergonha e se confunde.',
        'A justiça guarda ao que é de caminho certo, mas a impiedade transtornará o pecador.',
        'Há alguns que se fazem de ricos, e não têm coisa nenhuma, e outros que se fazem de pobres e têm muitas riquezas.',
        'O resgate da vida de cada um são as suas riquezas, mas o pobre não ouve ameaças.',
        'A luz dos justos alegra, mas a candeia dos ímpios se apagará.',
        'Da soberba só provém a contenda, mas com os que se aconselham se acha a sabedoria.',
        'A riqueza de procedência vã diminuirá, mas quem a ajunta com o próprio trabalho a aumentará.',
        'A esperança adiada desfalece o coração, mas o desejo atendido é árvore de vida.',
        'O que despreza a palavra perecerá, mas o que teme o mandamento será galardoado.',
        'A doutrina do sábio é uma fonte de vida para se desviar dos laços da morte.',
        'O bom entendimento favorece, mas o caminho dos prevaricadores é áspero.',
        'Todo prudente procede com conhecimento, mas o insensato espraia a sua loucura.',
        'O que prega a maldade cai no mal, mas o embaixador fiel é saúde.',
        'Pobreza e afronta virão ao que rejeita a instrução, mas o que guarda a repreensão será honrado.',
        'O desejo que se alcança deleita a alma, mas apartar-se do mal é abominável para os insensatos.',
        'O que anda com os sábios ficará sábio, mas o companheiro dos tolos será destruído.',
        'O mal perseguirá os pecadores, mas os justos serão galardoados com o bem.',
        'O homem de bem deixa uma herança aos filhos de seus filhos, mas a riqueza do pecador é depositada para o justo.',
        'O pobre, do sulco da terra, tira mantimento em abundância; mas há os que se consomem por falta de juízo.',
        'O que não faz uso da vara odeia seu filho, mas o que o ama, desde cedo o castiga.',
        'O justo come até ficar satisfeito, mas o ventre dos ímpios passará necessidade.',
      ],
      [
        'Toda mulher sábia edifica a sua casa; mas a tola a derruba com as próprias mãos.',
        'O que anda na retidão teme ao Senhor, mas o que se desvia de seus caminhos o despreza.',
        'Na boca do tolo está a punição da soberba, mas os sábios se conservam pelos próprios lábios.',
        'Não havendo bois o estábulo fica limpo, mas pela força do boi há abundância de colheita.',
        'A verdadeira testemunha não mentirá, mas a testemunha falsa se desboca em mentiras.',
        'O escarnecedor busca sabedoria e não acha nenhuma, para o prudente, porém, o conhecimento é fácil.',
        'Desvia-te do homem insensato, porque nele não acharás lábios de conhecimento.',
        'A sabedoria do prudente é entender o seu caminho, mas a estultícia dos insensatos é engano.',
        'Os insensatos zombam do pecado, mas entre os retos há benevolência.',
        'O coração conhece a sua própria amargura, e o estranho não participará no íntimo da sua alegria.',
        'A casa dos ímpios se desfará, mas a tenda dos retos florescerá.',
        'Há um caminho que ao homem parece direito, mas o fim dele são os caminhos da morte.',
        'Até no riso o coração sente dor e o fim da alegria é tristeza.',
        'O que no seu coração comete deslize, se enfada dos seus caminhos, mas o homem bom fica satisfeito com o seu proceder.',
        'O simples dá crédito a cada palavra, mas o prudente atenta para os seus passos.',
        'O sábio teme, e desvia-se do mal, mas o tolo se encoleriza, e dá-se por seguro.',
        'O que se indigna à toa fará doidices, e o homem de maus intentos será odiado.',
        'Os simples herdarão a estultícia, mas os prudentes serão coroados de conhecimento.',
        'Os maus inclinam-se diante dos bons, e os ímpios diante das portas dos justos.',
        'O pobre é odiado até pelo seu próximo, porém os amigos dos ricos são muitos.',
        'O que despreza ao seu próximo peca, mas o que se compadece dos humildes é bem-aventurado.',
        'Porventura não erram os que praticam o mal? mas beneficência e fidelidade haverá para os que praticam o bem.',
        'Em todo trabalho há proveito, mas ficar só em palavras leva à pobreza.',
        'A coroa dos sábios é a sua riqueza, a estultícia dos tolos é só estultícia.',
        'A testemunha verdadeira livra as almas, mas o que se desboca em mentiras é enganador.',
        'No temor do Senhor há firme confiança e ele será um refúgio para seus filhos.',
        'O temor do Senhor é fonte de vida, para desviar dos laços da morte.',
        'Na multidão do povo está a glória do rei, mas na falta de povo a ruína do príncipe.',
        'O longânimo é grande em entendimento, mas o que é de espírito impaciente mostra a sua loucura.',
        'O sentimento sadio é vida para o corpo, mas a inveja é podridão para os ossos.',
        'O que oprime o pobre insulta àquele que o criou, mas o que se compadece do necessitado o honra.',
        'Pela sua própria malícia é lançado fora o perverso, mas o justo até na morte se mantém confiante.',
        'No coração do prudente a sabedoria permanece, mas o que está no interior dos tolos se faz conhecido.',
        'A justiça exalta os povos, mas o pecado é a vergonha das nações.',
        'O rei se alegra no servo prudente, mas sobre o que o envergonha cairá o seu furor.',
      ],
      [
        'A resposta branda desvia o furor, mas a palavra dura suscita a ira.',
        'A língua dos sábios adorna a sabedoria, mas a boca dos tolos derrama a estultícia.',
        'Os olhos do Senhor estão em todo lugar, contemplando os maus e os bons.',
        'A língua benigna é árvore de vida, mas a perversidade nela deprime o espírito.',
        'O tolo despreza a instrução de seu pai, mas o que observa a repreensão se haverá prudentemente.',
        'Na casa do justo há um grande tesouro, mas nos ganhos do ímpio há perturbação.',
        'Os lábios dos sábios derramam o conhecimento, mas o coração dos tolos não faz assim.',
        'O sacrifício dos ímpios é abominável ao Senhor, mas a oração dos retos é o seu contentamento.',
        'O caminho do ímpio é abominável ao Senhor, mas ao que segue a justiça ele ama.',
        'Correção severa há para o que deixa a vereda, e o que odeia a repreensão morrerá.',
        'O inferno e a perdição estão perante o Senhor; quanto mais os corações dos filhos dos homens?',
        'O escarnecedor não ama aquele que o repreende, nem se chegará aos sábios.',
        'O coração alegre aformoseia o rosto, mas pela dor do coração o espírito se abate.',
        'O coração entendido buscará o conhecimento, mas a boca dos tolos se apascentará de estultícia.',
        'Todos os dias do oprimido são maus, mas o coração alegre é um banquete contínuo.',
        'Melhor é o pouco com o temor do Senhor, do que um grande tesouro onde há inquietação.',
        'Melhor é a comida de hortaliça, onde há amor, do que o boi cevado, e com ele o ódio.',
        'O homem iracundo suscita contendas, mas o longânimo apaziguará a luta.',
        'O caminho do preguiçoso é cercado de espinhos, mas a vereda dos retos é bem aplanada.',
        'O filho sábio alegra seu pai, mas o homem insensato despreza a sua mãe.',
        'A estultícia é alegria para o que carece de entendimento, mas o homem entendido anda retamente.',
        'Quando não há conselhos os planos se dispersam, mas havendo muitos conselheiros eles se firmam.',
        'O homem se alegra em responder bem, e quão boa é a palavra dita a seu tempo!',
        'Para o entendido, o caminho da vida leva para cima, para que se desvie do inferno em baixo.',
        'O Senhor desarraiga a casa dos soberbos, mas estabelece o termo da viúva.',
        'Abomináveis são para o Senhor os pensamentos do mau, mas as palavras dos puros são aprazíveis.',
        'O que agir com avareza perturba a sua casa, mas o que odeia presentes viverá.',
        'O coração do justo medita no que há de responder, mas a boca dos ímpios jorra coisas más.',
        'O Senhor está longe dos ímpios, mas a oração dos justos escutará.',
        'A luz dos olhos alegra o coração, a boa notícia fortalece os ossos.',
        'Os ouvidos que atendem à repreensão da vida farão a sua morada no meio dos sábios.',
        'O que rejeita a instrução menospreza a própria alma, mas o que escuta a repreensão adquire entendimento.',
        'O temor do Senhor é a instrução da sabedoria, e precedendo a honra vai a humildade.',
      ],
      [
        'Do homem são as preparações do coração, mas do SENHOR a resposta da língua.',
        'Todos os caminhos do homem são puros aos seus olhos, mas o Senhor pesa o espírito.',
        'Confia ao Senhor as tuas obras, e teus pensamentos serão estabelecidos.',
        'O Senhor fez todas as coisas para atender aos seus próprios desígnios, até o ímpio para o dia do mal.',
        'Abominação é ao Senhor todo o altivo de coração; não ficará impune mesmo de mãos postas.',
        'Pela misericórdia e verdade a iniqüidade é perdoada, e pelo temor do Senhor os homens se desviam do pecado.',
        'Sendo os caminhos do homem agradáveis ao Senhor, até a seus inimigos faz que tenham paz com ele.',
        'Melhor é o pouco com justiça, do que a abundância de bens com injustiça.',
        'O coração do homem planeja o seu caminho, mas o Senhor lhe dirige os passos.',
        'Nos lábios do rei se acha a sentença divina; a sua boca não transgride quando julga.',
        'O peso e a balança justos são do Senhor; obra sua são os pesos da bolsa.',
        'Abominação é aos reis praticarem impiedade, porque com justiça é que se estabelece o trono.',
        'Os lábios de justiça são o contentamento dos reis; eles amarão o que fala coisas retas.',
        'O furor do rei é mensageiro da morte, mas o homem sábio o apaziguará.',
        'No semblante iluminado do rei está a vida, e a sua benevolência é como a nuvem da chuva serôdia.',
        'Quão melhor é adquirir a sabedoria do que o ouro! e quão mais excelente é adquirir a prudência do que a prata!',
        'Os retos fazem o seu caminho desviar-se do mal; o que guarda o seu caminho preserva a sua alma.',
        'A soberba precede a ruína, e a altivez do espírito precede a queda.',
        'Melhor é ser humilde de espírito com os mansos, do que repartir o despojo com os soberbos.',
        'O que atenta prudentemente para o assunto achará o bem, e o que confia no Senhor será bem-aventurado.',
        'O sábio de coração será chamado prudente, e a doçura dos lábios aumentará o ensino.',
        'O entendimento para aqueles que o possuem, é uma fonte de vida, mas a instrução dos tolos é a sua estultícia.',
        'O coração do sábio instrui a sua boca, e aumenta o ensino dos seus lábios.',
        'As palavras suaves são favos de mel, doces para a alma, e saúde para os ossos.',
        'Há um caminho que parece direito ao homem, mas o seu fim são os caminhos da morte.',
        'O trabalhador trabalha para si mesmo, porque a sua boca o incita.',
        'O homem ímpio cava o mal, e nos seus lábios há como que uma fogueira.',
        'O homem perverso instiga a contenda, e o intrigante separa os maiores amigos.',
        'O homem violento coage o seu próximo, e o faz deslizar por caminhos nada bons.',
        'O que fecha os olhos para imaginar coisas ruins, ao cerrar os lábios pratica o mal.',
        'Coroa de honra são as cãs, quando elas estão no caminho da justiça.',
        'Melhor é o que tarda em irar-se do que o poderoso, e o que controla o seu ânimo do que aquele que toma uma cidade.',
        'A sorte se lança no regaço, mas do Senhor procede toda a determinação.',
      ],
      [
        'É melhor um bocado seco, e com ele a tranqüilidade, do que a casa cheia de iguarias e com desavença.',
        'O servo prudente dominará sobre o filho que faz envergonhar; e repartirá a herança entre os irmãos.',
        'O crisol é para a prata, e o forno para o ouro; mas o Senhor é quem prova os corações.',
        'O ímpio atenta para o lábio iníquo, o mentiroso inclina os ouvidos à língua maligna.',
        'O que escarnece do pobre insulta ao seu Criador, o que se alegra da calamidade não ficará impune.',
        'A coroa dos velhos são os filhos dos filhos; e a glória dos filhos são seus pais.',
        'Não convém ao tolo a fala excelente; quanto menos ao príncipe, o lábio mentiroso.',
        'O presente é, aos olhos dos que o recebem, como pedra preciosa; para onde quer que se volte servirá de proveito.',
        'Aquele que encobre a transgressão busca a amizade, mas o que revolve o assunto separa os maiores amigos.',
        'A repreensão penetra mais profundamente no prudente do que cem açoites no tolo.',
        'Na verdade o rebelde não busca senão o mal; afinal, um mensageiro cruel será enviado contra ele.',
        'Encontre-se o homem com a ursa roubada dos filhos, mas não com o louco na sua estultícia.',
        'Quanto àquele que paga o bem com o mal, não se apartará o mal da sua casa.',
        'Como o soltar das águas é o início da contenda, assim, antes que sejas envolvido afasta-te da questão.',
        'O que justifica o ímpio, e o que condena o justo, tanto um como o outro são abomináveis ao Senhor.',
        'De que serviria o preço na mão do tolo para comprar sabedoria, visto que não tem entendimento?',
        'Em todo o tempo ama o amigo e para a hora da angústia nasce o irmão.',
        'O homem falto de entendimento compromete-se, ficando por fiador na presença do seu amigo.',
        'O que ama a transgressão ama a contenda; o que exalta a sua porta busca a ruína.',
        'O perverso de coração jamais achará o bem; e o que tem a língua dobre vem a cair no mal.',
        'O que gera um tolo para a sua tristeza o faz; e o pai do insensato não tem alegria.',
        'O coração alegre é como o bom remédio, mas o espírito abatido seca até os ossos.',
        'O ímpio toma presentes em secreto para perverter as veredas da justiça.',
        'No rosto do entendido se vê a sabedoria, mas os olhos do tolo vagam pelas extremidades da terra.',
        'O filho insensato é tristeza para seu pai, e amargura para aquela que o deu à luz.',
        'Também não é bom punir o justo, nem tampouco ferir aos príncipes por eqüidade.',
        'O que possui o conhecimento guarda as suas palavras, e o homem de entendimento é de precioso espírito.',
        'Até o tolo, quando se cala, é reputado por sábio; e o que cerra os seus lábios é tido por entendido.',
      ],
      [
        'Busca satisfazer seu próprio desejo aquele que se isola; ele se insurge contra toda sabedoria.',
        'O tolo não tem prazer na sabedoria, mas só em que se manifeste aquilo que agrada o seu coração.',
        'Vindo o ímpio, vem também o desprezo, e com a ignomínia a vergonha.',
        'Águas profundas são as palavras da boca do homem, e ribeiro transbordante é a fonte da sabedoria.',
        'Não é bom favorecer o ímpio, e com isso, fazer o justo perder a questão.',
        'Os lábios do tolo entram na contenda, e a sua boca brada por açoites.',
        'A boca do tolo é a sua própria destruição, e os seus lábios um laço para a sua alma.',
        'As palavras do mexeriqueiro são como doces bocados; elas descem ao íntimo do ventre.',
        'O que é negligente na sua obra é também irmão do desperdiçador.',
        'Torre forte é o nome do Senhor; a ela correrá o justo, e estará em alto refúgio.',
        'Os bens do rico são a sua cidade forte, e como uma muralha na sua imaginação.',
        'O coração do homem se exalta antes de ser abatido e diante da honra vai a humildade.',
        'O que responde antes de ouvir comete estultícia que é para vergonha sua.',
        'O espírito do homem susterá a sua enfermidade, mas ao espírito abatido, quem o suportará?',
        'O coração do entendido adquire o conhecimento, e o ouvido dos sábios busca a sabedoria.',
        'Com presentes o homem alarga o seu caminho e o eleva diante dos grandes.',
        'O que pleiteia por algo, a princípio parece justo, porém vem o seu próximo e o examina.',
        'A sorte faz cessar os pleitos, e faz separação entre os poderosos.',
        'O irmão ofendido é mais difícil de conquistar do que uma cidade forte; e as contendas são como os ferrolhos de um palácio.',
        'Do fruto da boca de cada um se fartará o seu ventre; dos renovos dos seus lábios ficará satisfeito.',
        'A morte e a vida estão no poder da língua; e aquele que a ama comerá do seu fruto.',
        'Aquele que encontra uma esposa, acha o bem, e alcança a benevolência do Senhor.',
        'O pobre fala com rogos, mas o rico responde com dureza.',
        'O homem de muitos amigos deve mostrar-se amigável, mas há um amigo mais chegado do que um irmão.',
      ],
      [
        'Melhor é o pobre que anda na sua integridade do que o perverso de lábios e tolo.',
        'Assim como não é bom ficar a alma sem conhecimento, peca aquele que se apressa com seus pés.',
        'A estultícia do homem perverterá o seu caminho, e o seu coração se irará contra o Senhor.',
        'As riquezas granjeiam muitos amigos, mas ao pobre, o seu próprio amigo o deixa.',
        'A falsa testemunha não ficará impune e o que respira mentiras não escapará.',
        'Muitos se deixam acomodar pelos favores do príncipe, e cada um é amigo daquele que dá presentes.',
        'Todos os irmãos do pobre o odeiam; quanto mais se afastarão dele os seus amigos! Corre após eles com palavras, que não servem de nada.',
        'O que adquire entendimento ama a sua alma; o que cultiva a inteligência achará o bem.',
        'A falsa testemunha não ficará impune; e o que profere mentiras perecerá.',
        'Ao tolo não é certo gozar de deleites; quanto menos ao servo dominar sobre os príncipes!',
        'A prudência do homem faz reter a sua ira, e é glória sua o passar por cima da transgressão.',
        'Como o rugido do leão jovem é a indignação do rei, mas como o orvalho sobre a relva é a sua benevolência.',
        'O filho insensato é uma desgraça para o pai, e um gotejar contínuo as contendas da mulher.',
        'A casa e os bens são herança dos pais; porém do Senhor vem a esposa prudente.',
        'A preguiça faz cair em profundo sono, e a alma indolente padecerá fome.',
        'O que guardar o mandamento guardará a sua alma; porém o que desprezar os seus caminhos morrerá.',
        'Ao Senhor empresta o que se compadece do pobre, ele lhe pagará o seu benefício.',
        'Castiga o teu filho enquanto há esperança, mas não deixes que o teu ânimo se exalte até o matar.',
        'O homem de grande indignação deve sofrer o dano; porque se tu o livrares ainda terás de tornar a fazê-lo.',
        'Ouve o conselho, e recebe a correção, para que no fim sejas sábio.',
        'Muitos propósitos há no coração do homem, porém o conselho do Senhor permanecerá.',
        'O que o homem mais deseja é o que lhe faz bem; porém é melhor ser pobre do que mentiroso.',
        'O temor do Senhor encaminha para a vida; aquele que o tem ficará satisfeito, e não o visitará mal nenhum.',
        'O preguiçoso esconde a sua mão ao seio; e não tem disposição nem de torná-la à sua boca.',
        'Açoita o escarnecedor, e o simples tomará aviso; repreende ao entendido, e aprenderá conhecimento.',
        'O que aflige o seu pai, ou manda embora sua mãe, é filho que traz vergonha e desonra.',
        'Filho meu, ouvindo a instrução, cessa de te desviares das palavras do conhecimento.',
        'O ímpio escarnece do juízo, e a boca dos perversos devora a iniqüidade.',
        'Preparados estão os juízos para os escarnecedores, e os açoites para as costas dos tolos.',
      ],
      [
        'O vinho é escarnecedor, a bebida forte alvoroçadora; e todo aquele que neles errar nunca será sábio.',
        'Como o rugido do leão é o terror do rei; o que o provoca à ira peca contra a sua própria alma.',
        'Honroso é para o homem desviar-se de questões, mas todo tolo é intrometido.',
        'O preguiçoso não lavrará por causa do inverno, pelo que mendigará na sega, mas nada receberá.',
        'Como as águas profundas é o conselho no coração do homem; mas o homem de inteligência o trará para fora.',
        'A multidão dos homens apregoa a sua própria bondade, porém o homem fidedigno quem o achará?',
        'O justo anda na sua sinceridade; bem-aventurados serão os seus filhos depois dele.',
        'Assentando-se o rei no trono do juízo, com os seus olhos dissipa todo o mal.',
        'Quem poderá dizer: Purifiquei o meu coração, limpo estou de meu pecado?',
        'Dois pesos diferentes e duas espécies de medida são abominação ao Senhor, tanto um como outro.',
        'Até a criança se dará a conhecer pelas suas ações, se a sua obra é pura e reta.',
        'O ouvido que ouve, e o olho que vê, o Senhor os fez a ambos.',
        'Não ames o sono, para que não empobreças; abre os teus olhos, e te fartarás de pão.',
        'Nada vale, nada vale, dirá o comprador, mas, indo-se, então se gabará.',
        'Há ouro e abundância de rubis, mas os lábios do conhecimento são jóia preciosa.',
        'Ficando alguém por fiador de um estranho, tome-se-lhe a roupa; e por penhor àquele que se obriga pela mulher estranha.',
        'Suave é ao homem o pão da mentira, mas depois a sua boca se encherá de cascalho.',
        'Cada pensamento se confirma com conselho e com bons conselhos se faz a guerra.',
        'O que anda tagarelando revela o segredo; não te intrometas com o que lisonjeia com os seus lábios.',
        'O que amaldiçoa seu pai ou sua mãe, apagar-se-á a sua lâmpada em negras trevas.',
        'A herança que no princípio é adquirida às pressas, no fim não será abençoada.',
        'Não digas: Vingar-me-ei do mal; espera pelo Senhor, e ele te livrará.',
        'Pesos diferentes são abomináveis ao Senhor, e balança enganosa não é boa.',
        'Os passos do homem são dirigidos pelo Senhor; como, pois, entenderá o homem o seu caminho?',
        'Laço é para o homem apropriar-se do que é santo, e só refletir depois de feitos os votos.',
        'O rei sábio dispersa os ímpios e faz passar sobre eles a roda.',
        'O espírito do homem é a lâmpada do Senhor, que esquadrinha todo o interior até o mais íntimo do ventre.',
        'Benignidade e verdade guardam ao rei, e com benignidade sustém ele o seu trono.',
        'A glória do jovem é a sua força; e a beleza dos velhos são as cãs.',
        'Os vergões das feridas são a purificação dos maus, como também as pancadas que penetram até o mais íntimo do ventre.',
      ],
      [
        'Como ribeiros de águas assim é o coração do rei na mão do SENHOR, que o inclina a todo o seu querer.',
        'Todo caminho do homem é reto aos seus olhos, mas o Senhor sonda os corações.',
        'Fazer justiça e juízo é mais aceitável ao Senhor do que sacrifício.',
        'Os olhos altivos, o coração orgulhoso e a lavoura dos ímpios é pecado.',
        'Os pensamentos do diligente tendem só para a abundância, porém os de todo apressado, tão-somente para a pobreza.',
        'Trabalhar com língua falsa para ajuntar tesouros é vaidade que conduz aqueles que buscam a morte.',
        'As rapinas dos ímpios os destruirão, porquanto se recusam a fazer justiça.',
        'O caminho do homem é todo perverso e estranho, porém a obra do homem puro é reta.',
        'É melhor morar num canto de telhado do que ter como companheira em casa ampla uma mulher briguenta.',
        'A alma do ímpio deseja o mal; o seu próximo não agrada aos seus olhos.',
        'Quando o escarnecedor é castigado, o simples torna-se sábio; e o sábio quando é instruído recebe o conhecimento.',
        'O justo considera com prudência a casa do ímpio; mas Deus destrói os ímpios por causa dos seus males.',
        'O que tapa o seu ouvido ao clamor do pobre, ele mesmo também clamará e não será ouvido.',
        'O presente dado em segredo aplaca a ira, e a dádiva no regaço põe fim à maior indignação.',
        'O fazer justiça é alegria para o justo, mas destruição para os que praticam a iniqüidade.',
        'O homem que anda desviado do caminho do entendimento, na congregação dos mortos repousará.',
        'O que ama os prazeres padecerá necessidade; o que ama o vinho e o azeite nunca enriquecerá.',
        'O resgate do justo é o ímpio; o do honrado é o perverso.',
        'É melhor morar numa terra deserta do que com a mulher rixosa e irritadiça.',
        'Tesouro desejável e azeite há na casa do sábio, mas o homem insensato os esgota.',
        'O que segue a justiça e a beneficência achará a vida, a justiça e a honra.',
        'O sábio escala a cidade do poderoso e derruba a força da sua confiança.',
        'O que guarda a sua boca e a sua língua guarda a sua alma das angústias.',
        'O soberbo e presumido, zombador é o seu nome, trata com indignação e soberba.',
        'O desejo do preguiçoso o mata, porque as suas mãos recusam trabalhar.',
        'O cobiçoso cobiça o dia todo, mas o justo dá, e nada retém.',
        'O sacrifício dos ímpios já é abominação; quanto mais oferecendo-o com má intenção!',
        'A falsa testemunha perecerá, porém o homem que dá ouvidos falará sempre.',
        'O homem ímpio endurece o seu rosto; mas o reto considera o seu caminho.',
        'Não há sabedoria, nem inteligência, nem conselho contra o Senhor.',
        'Prepara-se o cavalo para o dia da batalha, porém do Senhor vem a vitória.',
      ],
      [
        'Vale mais ter um bom nome do que muitas riquezas; e o ser estimado é melhor do que a riqueza e o ouro.',
        'O rico e o pobre se encontram; a todos o Senhor os fez.',
        'O prudente prevê o mal, e esconde-se; mas os simples passam e acabam pagando.',
        'O galardão da humildade e o temor do Senhor são riquezas, honra e vida.',
        'Espinhos e laços há no caminho do perverso; o que guarda a sua alma retira-se para longe dele.',
        'Educa a criança no caminho em que deve andar; e até quando envelhecer não se desviará dele.',
        'O rico domina sobre os pobres e o que toma emprestado é servo do que empresta.',
        'O que semear a perversidade segará males; e com a vara da sua própria indignação será extinto.',
        'O que vê com bons olhos será abençoado, porque dá do seu pão ao pobre.',
        'Lança fora o escarnecedor, e se irá a contenda; e acabará a questão e a vergonha.',
        'O que ama a pureza de coração, e é amável de lábios, será amigo do rei.',
        'Os olhos do Senhor conservam o conhecimento, mas as palavras do iníquo ele transtornará.',
        'Diz o preguiçoso: Um leão está lá fora; serei morto no meio das ruas.',
        'Cova profunda é a boca das mulheres estranhas; aquele contra quem o Senhor se irar, cairá nela.',
        'A estultícia está ligada ao coração da criança, mas a vara da correção a afugentará dela.',
        'O que oprime ao pobre para se engrandecer a si mesmo, ou o que dá ao rico, certamente empobrecerá.',
        'Inclina o teu ouvido e ouve as palavras dos sábios, e aplica o teu coração ao meu conhecimento.',
        'Porque te será agradável se as guardares no teu íntimo, se aplicares todas elas aos teus lábios.',
        'Para que a tua confiança esteja no Senhor, faço-te sabê-las hoje, a ti mesmo.',
        'Porventura não te escrevi excelentes coisas, acerca de todo conselho e conhecimento,',
        'Para fazer-te saber a certeza das palavras da verdade, e assim possas responder palavras de verdade aos que te consultarem?',
        'Não roubes ao pobre, porque é pobre, nem atropeles na porta o aflito;',
        'Porque o Senhor defenderá a sua causa em juízo, e aos que os roubam ele lhes tirará a vida.',
        'Não sejas companheiro do homem briguento nem andes com o colérico,',
        'Para que não aprendas as suas veredas, e tomes um laço para a tua alma.',
        'Não estejas entre os que se comprometem, e entre os que ficam por fiadores de dívidas,',
        'Pois se não tens com que pagar, deixarias que te tirassem até a tua cama de debaixo de ti?',
        'Não removas os antigos limites que teus pais fizeram.',
        'Viste o homem diligente na sua obra? Perante reis será posto; não permanecerá entre os de posição inferior.',
      ],
      [
        'Quando te assentares a comer com um governador, atenta bem para o que é posto diante de ti,',
        'E se és homem de grande apetite, põe uma faca à tua garganta.',
        'Não cobices as suas iguarias porque são comidas enganosas.',
        'Não te fatigues para enriqueceres; e não apliques nisso a tua sabedoria.',
        'Porventura fixarás os teus olhos naquilo que não é nada? porque certamente criará asas e voará ao céu como a águia.',
        'Não comas o pão daquele que tem o olhar maligno, nem cobices as suas iguarias gostosas.',
        'Porque, como imaginou no seu coração, assim é ele. Come e bebe, te disse ele; porém o seu coração não está contigo.',
        'Vomitarás o bocado que comeste, e perderás as tuas suaves palavras.',
        'Não fales ao ouvido do tolo, porque desprezará a sabedoria das tuas palavras.',
        'Não removas os limites antigos nem entres nos campos dos órfãos,',
        'Porque o seu redentor é poderoso; e pleiteará a causa deles contra ti.',
        'Aplica o teu coração à instrução e os teus ouvidos às palavras do conhecimento.',
        'Não retires a disciplina da criança; pois se a fustigares com a vara, nem por isso morrerá.',
        'Tu a fustigarás com a vara, e livrarás a sua alma do inferno.',
        'Filho meu, se o teu coração for sábio, alegrar-se-á o meu coração, sim, o meu próprio.',
        'E exultarão os meus rins, quando os teus lábios falarem coisas retas.',
        'O teu coração não inveje os pecadores; antes permanece no temor do Senhor todo dia.',
        'Porque certamente acabará bem; não será malograda a tua esperança.',
        'Ouve tu, filho meu, e sê sábio, e dirige no caminho o teu coração.',
        'Não estejas entre os beberrões de vinho, nem entre os comilões de carne.',
        'Porque o beberrão e o comilão acabarão na pobreza; e a sonolência os faz vestir-se de trapos.',
        'Ouve teu pai, que te gerou, e não desprezes tua mãe, quando vier a envelhecer.',
        'Compra a verdade, e não a vendas; e também a sabedoria, a instrução e o entendimento.',
        'Grandemente se regozijará o pai do justo, e o que gerar um sábio, se alegrará nele.',
        'Alegrem-se teu pai e tua mãe, e regozije-se a que te gerou.',
        'Dá-me, filho meu, o teu coração, e os teus olhos observem os meus caminhos.',
        'Porque cova profunda é a prostituta, e poço estreito a estranha.',
        'Pois ela, como um salteador, se põe à espreita, e multiplica entre os homens os iníquos.',
        'Para quem são os ais? Para quem os pesares? Para quem as pelejas? Para quem as queixas? Para quem as feridas sem causa? E para quem os olhos vermelhos?',
        'Para os que se demoram perto do vinho, para os que andam buscando vinho misturado.',
        'Não olhes para o vinho quando se mostra vermelho, quando resplandece no copo e se escoa suavemente.',
        'No fim, picará como a cobra, e como o basilisco morderá.',
        'Os teus olhos olharão para as mulheres estranhas, e o teu coração falará perversidades.',
        'E serás como o que se deita no meio do mar, e como o que jaz no topo do mastro.',
        'E dirás: Espancaram-me e não me doeu; bateram-me e nem senti; quando despertarei? aí então beberei outra vez.',
      ],
      [
        'Não tenhas inveja dos homens malignos, nem desejes estar com eles.',
        'Porque o seu coração medita a rapina, e os seus lábios falam a malícia.',
        'Com a sabedoria se edifica a casa, e com o entendimento ela se estabelece;',
        'E pelo conhecimento se encherão as câmaras com todos os bens preciosos e agradáveis.',
        'O homem sábio é forte, e o homem de conhecimento consolida a força.',
        'Com conselhos prudentes tu farás a guerra; e há vitória na multidão dos conselheiros.',
        'A sabedoria é demasiadamente alta para o tolo, na porta não abrirá a sua boca.',
        'Àquele que cuida em fazer mal, chamá-lo-ão de pessoa danosa.',
        'O pensamento do tolo é pecado, e abominável aos homens é o escarnecedor.',
        'Se te mostrares fraco no dia da angústia, é que a tua força é pequena.',
        'Se tu deixares de livrar os que estão sendo levados para a morte, e aos que estão sendo levados para a matança;',
        'Se disseres: Eis que não o sabemos; porventura não o considerará aquele que pondera os corações? Não o saberá aquele que atenta para a tua alma? Não dará ele ao homem conforme a sua obra?',
        'Come mel, meu filho, porque é bom; o favo de mel é doce ao teu paladar.',
        'Assim será para a tua alma o conhecimento da sabedoria; se a achares, haverá galardão para ti e não será cortada a tua esperança.',
        'Não armes ciladas contra a habitação do justo, ó ímpio, nem assoles o seu lugar de repouso,',
        'Porque sete vezes cairá o justo, e se levantará; mas os ímpios tropeçarão no mal.',
        'Quando cair o teu inimigo, não te alegres, nem se regozije o teu coração quando ele tropeçar;',
        'Para que, vendo-o o Senhor, seja isso mau aos seus olhos, e desvie dele a sua ira.',
        'Não te indignes por causa dos malfeitores, nem tenhas inveja dos ímpios,',
        'Porque o homem maligno não terá galardão, e a lâmpada dos ímpios se apagará.',
        'Teme ao Senhor, filho meu, e ao rei, e não te ponhas com os que buscam mudanças,',
        'Porque de repente se levantará a sua destruição, e a ruína de ambos, quem o sabe?',
        'Também estes são provérbios dos sábios: Ter respeito a pessoas no julgamento não é bom.',
        'O que disser ao ímpio: Justo és, os povos o amaldiçoarão, as nações o detestarão.',
        'Mas para os que o repreenderem haverá delícias, e sobre eles virá a bênção do bem.',
        'Beijados serão os lábios do que responde com palavras retas.',
        'Prepara de fora a tua obra, e aparelha-a no campo, e então edifica a tua casa.',
        'Não sejas testemunha sem causa contra o teu próximo; e não enganes com os teus lábios.',
        'Não digas: Como ele me fez a mim, assim o farei eu a ele; pagarei a cada um segundo a sua obra.',
        'Passei pelo campo do preguiçoso, e junto à vinha do homem falto de entendimento,',
        'Eis que estava toda cheia de cardos, e a sua superfície coberta de urtiga, e o seu muro de pedras estava derrubado.',
        'O que eu tenho visto, o guardarei no coração, e vendo-o recebi instrução.',
        'Um pouco a dormir, um pouco a cochilar; outro pouco deitado de mãos cruzadas, para dormir,',
        'Assim te sobrevirá a tua pobreza como um vagabundo, e a tua necessidade como um homem armado.',
      ],
      [
        'Também estes são provérbios de Salomão, os quais transcreveram os homens de Ezequias, rei de Judá.',
        'A glória de Deus está nas coisas encobertas; mas a honra dos reis, está em descobri-las.',
        'Os céus, pela altura, e a terra, pela profundidade, assim o coração dos reis é insondável.',
        'Tira da prata as escórias, e sairá vaso para o fundidor;',
        'Tira o ímpio da presença do rei, e o seu trono se firmará na justiça.',
        'Não te glories na presença do rei, nem te ponhas no lugar dos grandes;',
        'Porque melhor é que te digam: Sobe aqui; do que seres humilhado diante do príncipe que os teus olhos já viram.',
        'Não te precipites em litigar, para que depois, ao fim, fiques sem ação, quando teu próximo te puser em apuros.',
        'Pleiteia a tua causa com o teu próximo, e não reveles o segredo a outrem,',
        'Para que não te desonre o que o ouvir, e a tua infâmia não se aparte de ti.',
        'Como maçãs de ouro em salvas de prata, assim é a palavra dita a seu tempo.',
        'Como pendentes de ouro e gargantilhas de ouro fino, assim é o sábio repreensor para o ouvido atento.',
        'Como o frio da neve no tempo da sega, assim é o mensageiro fiel para com os que o enviam; porque refresca a alma dos seus senhores.',
        'Como nuvens e ventos que não trazem chuva, assim é o homem que se gaba falsamente de dádivas.',
        'Pela longanimidade se persuade o príncipe, e a língua branda amolece até os ossos.',
        'Achaste mel? come só o que te basta; para que porventura não te fartes dele, e o venhas a vomitar.',
        'Não ponhas muito os pés na casa do teu próximo; para que se não enfade de ti, e passe a te odiar.',
        'Martelo, espada e flecha aguda é o homem que profere falso testemunho contra o seu próximo.',
        'Como dente quebrado, e pé desconjuntado, é a confiança no desleal, no tempo da angústia.',
        'O que canta canções para o coração aflito é como aquele que despe a roupa num dia de frio, ou como o vinagre sobre salitre.',
        'Se o teu inimigo tiver fome, dá-lhe pão para comer; e se tiver sede, dá-lhe água para beber;',
        'Porque assim lhe amontoarás brasas sobre a cabeça; e o Senhor to retribuirá.',
        'O vento norte afugenta a chuva, e a face irada, a língua fingida.',
        'Melhor é morar só num canto de telhado do que com a mulher briguenta numa casa ampla.',
        'Como água fresca para a alma cansada, tais são as boas novas vindas da terra distante.',
        'Como fonte turvada, e manancial poluído, assim é o justo que cede diante do ímpio.',
        'Comer mel demais não é bom; assim, a busca da própria glória não é glória.',
        'Como a cidade derrubada, sem muro, assim é o homem que não pode conter o seu espírito.',
      ],
      [
        'Como a neve no verão, e como a chuva na sega, assim não fica bem para o tolo a honra.',
        'Como ao pássaro o vaguear, como à andorinha o voar, assim a maldição sem causa não virá.',
        'O açoite é para o cavalo, o freio é para o jumento, e a vara é para as costas dos tolos.',
        'Não respondas ao tolo segundo a sua estultícia; para que também não te faças semelhante a ele.',
        'Responde ao tolo segundo a sua estultícia, para que não seja sábio aos seus próprios olhos.',
        'Os pés corta, e o dano sorve, aquele que manda mensagem pela mão dum tolo.',
        'Como as pernas do coxo, que pendem flácidas, assim é o provérbio na boca dos tolos.',
        'Como o que arma a funda com pedra preciosa, assim é aquele que concede honra ao tolo.',
        'Como o espinho que entra na mão do bêbado, assim é o provérbio na boca dos tolos.',
        'O Poderoso, que formou todas as coisas, paga ao tolo, e recompensa ao transgressor.',
        'Como o cão torna ao seu vômito, assim o tolo repete a sua estultícia.',
        'Tens visto o homem que é sábio a seus próprios olhos? Pode-se esperar mais do tolo do que dele.',
        'Diz o preguiçoso: Um leão está no caminho; um leão está nas ruas.',
        'Como a porta gira nos seus gonzos, assim o preguiçoso na sua cama.',
        'O preguiçoso esconde a sua mão ao seio; e cansa-se até de torná-la à sua boca.',
        'Mais sábio é o preguiçoso a seus próprios olhos do que sete homens que respondem bem.',
        'O que, passando, se põe em questão alheia, é como aquele que pega um cão pelas orelhas.',
        'Como o louco que solta faíscas, flechas, e mortandades,',
        'Assim é o homem que engana o seu próximo, e diz: Fiz isso por brincadeira.',
        'Sem lenha, o fogo se apagará; e não havendo intrigante, cessará a contenda.',
        'Como o carvão para as brasas, e a lenha para o fogo, assim é o homem contencioso para acender rixas.',
        'As palavras do intrigante são como doces bocados; elas descem ao mais íntimo do ventre.',
        'Como o caco de vaso coberto de escórias de prata, assim são os lábios ardentes com o coração maligno.',
        'Aquele que odeia dissimula com seus lábios, mas no seu íntimo encobre o engano;',
        'Quando te suplicar com voz suave não te fies nele, porque abriga sete abominações no seu coração,',
        'Cujo ódio se encobre com engano, a sua maldade será exposta perante a congregação.',
        'O que cava uma cova cairá nela; e o que revolve a pedra, esta voltará sobre ele.',
        'A língua falsa odeia aos que ela fere, e a boca lisonjeira provoca a ruína.',
      ],
      [
        'Não presumas do dia de amanhã, porque não sabes o que ele trará.',
        'Que um outro te louve, e não a tua própria boca; o estranho, e não os teus lábios.',
        'A pedra é pesada, e a areia é espessa; porém a ira do insensato é mais pesada que ambas.',
        'O furor é cruel e a ira impetuosa, mas quem poderá enfrentar a inveja?',
        'Melhor é a repreensão franca do que o amor encoberto.',
        'Leais são as feridas feitas pelo amigo, mas os beijos do inimigo são enganosos.',
        'A alma farta pisa o favo de mel, mas para a alma faminta todo amargo é doce.',
        'Qual a ave que vagueia longe do seu ninho, tal é o homem que anda vagueando longe da sua morada.',
        'O óleo e o perfume alegram o coração; assim o faz a doçura do amigo pelo conselho cordial.',
        'Não deixes o teu amigo, nem o amigo de teu pai; nem entres na casa de teu irmão no dia da tua adversidade; melhor é o vizinho perto do que o irmão longe.',
        'Sê sábio, filho meu, e alegra o meu coração, para que tenha alguma coisa que responder àquele que me desprezar.',
        'O avisado vê o mal e esconde-se; mas os simples passam e sofrem a pena.',
        'Quando alguém fica por fiador do estranho, toma-lhe até a sua roupa, e por penhor àquele que se obriga pela mulher estranha.',
        'O que, pela manhã de madrugada, abençoa o seu amigo em alta voz, lho será imputado por maldição.',
        'O gotejar contínuo em dia de grande chuva, e a mulher contenciosa, uma e outra são semelhantes;',
        'Tentar moderá-la será como deter o vento, ou como conter o óleo dentro da sua mão direita.',
        'Como o ferro com ferro se aguça, assim o homem afia o rosto do seu amigo.',
        'O que cuida da figueira comerá do seu fruto; e o que atenta para o seu senhor será honrado.',
        'Como na água o rosto corresponde ao rosto, assim o coração do homem ao homem.',
        'Como o inferno e a perdição nunca se fartam, assim os olhos do homem nunca se satisfazem.',
        'Como o crisol é para a prata, e o forno para o ouro, assim o homem é provado pelos louvores.',
        'Ainda que repreendas o tolo como quem bate o trigo com a mão de gral entre grãos pilados, não se apartará dele a sua estultícia.',
        'Procura conhecer o estado das tuas ovelhas; põe o teu coração sobre os teus rebanhos,',
        'Porque o tesouro não dura para sempre; e durará a coroa de geração em geração?',
        'Quando brotar a erva, e aparecerem os renovos, e se juntarem as ervas dos montes,',
        'Então os cordeiros serão para te vestires, e os bodes para o preço do campo;',
        'E a abastança do leite das cabras para o teu sustento, para sustento da tua casa e para sustento das tuas servas.',
      ],
      [
        'Os ímpios fogem sem que haja ninguém a persegui-los; mas os justos são ousados como um leão.',
        'Pela transgressão da terra muitos são os seus príncipes, mas por homem prudente e entendido a sua continuidade será prolongada.',
        'O homem pobre que oprime os pobres é como a chuva impetuosa, que causa a falta de alimento.',
        'Os que deixam a lei louvam o ímpio; porém os que guardam a lei contendem com eles.',
        'Os homens maus não entendem o juízo, mas os que buscam ao Senhor entendem tudo.',
        'Melhor é o pobre que anda na sua integridade do que o de caminhos perversos ainda que seja rico.',
        'O que guarda a lei é filho sábio, mas o companheiro dos desregrados envergonha a seu pai.',
        'O que aumenta os seus bens com usura e ganância ajunta-os para o que se compadece do pobre.',
        'O que desvia os seus ouvidos de ouvir a lei, até a sua oração será abominável.',
        'O que faz com que os retos errem por mau caminho, ele mesmo cairá na sua cova; mas os bons herdarão o bem.',
        'O homem rico é sábio aos seus próprios olhos, mas o pobre que é entendido, o examina.',
        'Quando os justos exultam, grande é a glória; mas quando os ímpios sobem, os homens se escondem.',
        'O que encobre as suas transgressões nunca prosperará, mas o que as confessa e deixa, alcançará misericórdia.',
        'Bem-aventurado o homem que continuamente teme; mas o que endurece o seu coração cairá no mal.',
        'Como leão rugidor, e urso faminto, assim é o ímpio que domina sobre um povo pobre.',
        'O príncipe falto de entendimento é também um grande opressor, mas o que odeia a avareza prolongará seus dias.',
        'O homem carregado do sangue de qualquer pessoa fugirá até à cova; ninguém o detenha.',
        'O que anda sinceramente salvar-se-á, mas o perverso em seus caminhos cairá logo.',
        'O que lavrar a sua terra virá a fartar-se de pão, mas o que segue a ociosos se fartará de pobreza.',
        'O homem fiel será coberto de bênçãos, mas o que se apressa a enriquecer não ficará impune.',
        'Dar importância à aparência das pessoas não é bom, porque até por um bocado de pão um homem prevaricará.',
        'O que quer enriquecer depressa é homem de olho maligno, porém não sabe que a pobreza há de vir sobre ele.',
        'O que repreende o homem gozará depois mais amizade do que aquele que lisonjeia com a língua.',
        'O que rouba a seu próprio pai, ou a sua mãe, e diz: Não é transgressão, companheiro é do homem destruidor.',
        'O orgulhoso de coração levanta contendas, mas o que confia no Senhor prosperará.',
        'O que confia no seu próprio coração é insensato, mas o que anda em sabedoria, será salvo.',
        'O que dá ao pobre não terá necessidade, mas o que esconde os seus olhos terá muitas maldições.',
        'Quando os ímpios se elevam, os homens andam se escondendo, mas quando perecem, os justos se multiplicam.',
      ],
      [
        'O Homem que muitas vezes repreendido endurece a cerviz, de repente será destruído sem que haja remédio.',
        'Quando os justos se engrandecem, o povo se alegra, mas quando o ímpio domina, o povo geme.',
        'O homem que ama a sabedoria alegra a seu pai, mas o companheiro de prostitutas desperdiça os bens.',
        'O rei com juízo sustém a terra, mas o amigo de peitas a transtorna.',
        'O homem que lisonjeia o seu próximo arma uma rede aos seus passos.',
        'Na transgressão do homem mau há laço, mas o justo jubila e se alegra.',
        'O justo se informa da causa dos pobres, mas o ímpio nem sequer toma conhecimento.',
        'Os homens escarnecedores alvoroçam a cidade, mas os sábios desviam a ira.',
        'O homem sábio que pleiteia com o tolo, quer se zangue, quer se ria, não terá descanso.',
        'Os homens sanguinários odeiam ao sincero, mas os justos procuram o seu bem.',
        'O tolo revela todo o seu pensamento, mas o sábio o guarda até o fim.',
        'O governador que dá atenção às palavras mentirosas, achará que todos os seus servos são ímpios.',
        'O pobre e o usurário se encontram; o Senhor ilumina os olhos de ambos.',
        'O rei que julga os pobres conforme a verdade firmará o seu trono para sempre.',
        'A vara e a repreensão dão sabedoria, mas a criança entregue a si mesma, envergonha a sua mãe.',
        'Quando os ímpios se multiplicam, multiplicam-se as transgressões, mas os justos verão a sua queda.',
        'Castiga o teu filho, e te dará descanso; e dará delícias à tua alma.',
        'Não havendo profecia, o povo perece; porém o que guarda a lei, esse é bem-aventurado.',
        'O servo não se emendará com palavras, porque, ainda que entenda, todavia não atenderá.',
        'Tens visto um homem precipitado no falar? Maior esperança há para um tolo do que para ele.',
        'Quando alguém cria o seu servo com mimos desde a meninice, por fim ele tornar-se-á seu filho.',
        'O homem irascível levanta contendas; e o furioso multiplica as transgressões.',
        'A soberba do homem o abaterá, mas a honra sustentará o humilde de espírito.',
        'O que tem parte com o ladrão odeia a sua própria alma; ouve maldições, e não o denuncia.',
        'O temor do homem armará laços, mas o que confia no Senhor será posto em alto retiro.',
        'Muitos buscam o favor do poderoso, mas o juízo de cada um vem do Senhor.',
        'Abominação é, para os justos, o homem iníquo; mas abominação é, para o iníquo, o de retos caminhos.',
      ],
      [
        'Palavras de Agur, filho de Jaque, o masaíta, que proferiu este homem a Itiel, a Itiel e a Ucal:',
        'Na verdade eu sou o mais bruto dos homens, nem mesmo tenho o conhecimento de homem.',
        'Nem aprendi a sabedoria, nem tenho o conhecimento do santo.',
        'Quem subiu ao céu e desceu? Quem encerrou os ventos nos seus punhos? Quem amarrou as águas numa roupa? Quem estabeleceu todas as extremidades da terra? Qual é o seu nome? E qual é o nome de seu filho, se é que o sabes?',
        'Toda a Palavra de Deus é pura; escudo é para os que confiam nele.',
        'Nada acrescentes às suas palavras, para que não te repreenda e sejas achado mentiroso.',
        'Duas coisas te pedi; não mas negues, antes que morra:',
        'Afasta de mim a vaidade e a palavra mentirosa; não me dês nem a pobreza nem a riqueza; mantém-me do pão da minha porção de costume;',
        'Para que, porventura, estando farto não te negue, e venha a dizer: Quem é o Senhor? ou que, empobrecendo, não venha a furtar, e tome o nome de Deus em vão.',
        'Não acuses o servo diante de seu senhor, para que não te amaldiçoe e tu fiques o culpado.',
        'Há uma geração que amaldiçoa a seu pai, e que não bendiz a sua mãe.',
        'Há uma geração que é pura aos seus próprios olhos, mas que nunca foi lavada da sua imundícia.',
        'Há uma geração cujos olhos são altivos, e as suas pálpebras são sempre levantadas.',
        'Há uma geração cujos dentes são espadas, e cujas queixadas são facas, para consumirem da terra os aflitos, e os necessitados dentre os homens.',
        'A sanguessuga tem duas filhas: Dá e Dá. Estas três coisas nunca se fartam; e com a quarta, nunca dizem: Basta!',
        'A sepultura; a madre estéril; a terra que não se farta de água; e o fogo; nunca dizem: Basta!',
        'Os olhos que zombam do pai, ou desprezam a obediência à mãe, corvos do ribeiro os arrancarão e os filhotes da águia os comerão.',
        'Estas três coisas me maravilham; e quatro há que não conheço:',
        'O caminho da águia no ar; o caminho da cobra na penha; o caminho do navio no meio do mar; e o caminho do homem com uma virgem.',
        'O caminho da mulher adúltera é assim: ela come, depois limpa a sua boca e diz: Não fiz nada de mal!',
        'Por três coisas se alvoroça a terra; e por quatro que não pode suportar:',
        'Pelo servo, quando reina; e pelo tolo, quando vive na fartura;',
        'Pela mulher odiosa, quando é casada; e pela serva, quando fica herdeira da sua senhora.',
        'Estas quatro coisas são das menores da terra, porém bem providas de sabedoria:',
        'As formigas não são um povo forte; todavia no verão preparam a sua comida;',
        'Os coelhos são um povo débil; e contudo, põem a sua casa na rocha;',
        'Os gafanhotos não têm rei; e contudo todos saem, e em bandos se repartem;',
        'A aranha se pendura com as mãos, e está nos palácios dos reis.',
        'Estes três têm um bom andar, e quatro passeiam airosamente;',
        'O leão, o mais forte entre os animais, que não foge de nada;',
        'O galgo; o bode também; e o rei a quem não se pode resistir.',
        'Se procedeste loucamente, exaltando-te, e se planejaste o mal, leva a mão à boca;',
        'Porque o mexer do leite produz manteiga, o espremer do nariz produz sangue; assim o forçar da ira produz contenda.',
      ],
      [
        'Palavras do rei Lemuel, a profecia que lhe ensinou a sua mãe.',
        'Como, filho meu? e como, filho do meu ventre? e como, filho dos meus votos?',
        'Não dês às mulheres a tua força, nem os teus caminhos ao que destrói os reis.',
        'Não é próprio dos reis, ó Lemuel, não é próprio dos reis beber vinho, nem dos príncipes o desejar bebida forte;',
        'Para que bebendo, se esqueçam da lei, e pervertam o direito de todos os aflitos.',
        'Dai bebida forte ao que está prestes a perecer, e o vinho aos amargurados de espírito.',
        'Que beba, e esqueça da sua pobreza, e da sua miséria não se lembre mais.',
        'Abre a tua boca a favor do mudo, pela causa de todos que são designados à destruição.',
        'Abre a tua boca; julga retamente; e faze justiça aos pobres e aos necessitados.',
        'Mulher virtuosa quem a achará? O seu valor muito excede ao de rubis.',
        'O coração do seu marido está nela confiado; assim ele não necessitará de despojo.',
        'Ela só lhe faz bem, e não mal, todos os dias da sua vida.',
        'Busca lã e linho, e trabalha de boa vontade com suas mãos.',
        'Como o navio mercante, ela traz de longe o seu pão.',
        'Levanta-se, mesmo à noite, para dar de comer aos da casa, e distribuir a tarefa das servas.',
        'Examina uma propriedade e adquire-a; planta uma vinha com o fruto de suas mãos.',
        'Cinge os seus lombos de força, e fortalece os seus braços.',
        'Vê que é boa a sua mercadoria; e a sua lâmpada não se apaga de noite.',
        'Estende as suas mãos ao fuso, e suas mãos pegam na roca.',
        'Abre a sua mão ao pobre, e estende as suas mãos ao necessitado.',
        'Não teme a neve na sua casa, porque toda a sua família está vestida de escarlata.',
        'Faz para si cobertas de tapeçaria; seu vestido é de seda e de púrpura.',
        'Seu marido é conhecido nas portas, e assenta-se entre os anciãos da terra.',
        'Faz panos de linho fino e vende-os, e entrega cintos aos mercadores.',
        'A força e a honra são seu vestido, e se alegrará com o dia futuro.',
        'Abre a sua boca com sabedoria, e a lei da beneficência está na sua língua.',
        'Está atenta ao andamento da casa, e não come o pão da preguiça.',
        'Levantam-se seus filhos e chamam-na bem-aventurada; seu marido também, e ele a louva.',
        'Muitas filhas têm procedido virtuosamente, mas tu és, de todas, a mais excelente!',
        'Enganosa é a beleza e vã a formosura, mas a mulher que teme ao Senhor, essa sim será louvada.',
        'Dai-lhe do fruto das suas mãos, e deixe o seu próprio trabalho louvá-la nas portas.',
      ],
    ],
    livro: 'proverbios',
  },
  {
    abbrev: 'ec',
    capitulos: [
      [
        'Palavras do pregador, filho de Davi, rei em Jerusalém.',
        'Vaidade de vaidades, diz o pregador, vaidade de vaidades! Tudo é vaidade.',
        'Que proveito tem o homem, de todo o seu trabalho, que faz debaixo do sol?',
        'Uma geração vai, e outra geração vem; mas a terra para sempre permanece.',
        'Nasce o sol, e o sol se põe, e apressa-se e volta ao seu lugar de onde nasceu.',
        'O vento vai para o sul, e faz o seu giro para o norte; continuamente vai girando o vento, e volta fazendo os seus circuitos.',
        'Todos os rios vão para o mar, e contudo o mar não se enche; ao lugar para onde os rios vão, para ali tornam eles a correr.',
        'Todas as coisas são trabalhosas; o homem não o pode exprimir; os olhos não se fartam de ver, nem os ouvidos se enchem de ouvir.',
        'O que foi, isso é o que há de ser; e o que se fez, isso se fará; de modo que nada há de novo debaixo do sol.',
        'Há alguma coisa de que se possa dizer: Vê, isto é novo? Já foi nos séculos passados, que foram antes de nós.',
        'Já não há lembrança das coisas que precederam, e das coisas que hão de ser também delas não haverá lembrança, entre os que hão de vir depois.',
        'Eu, o pregador, fui rei sobre Israel em Jerusalém.',
        'E apliquei o meu coração a esquadrinhar, e a informar-me com sabedoria de tudo quanto sucede debaixo do céu; esta enfadonha ocupação deu Deus aos filhos dos homens, para nela os exercitar.',
        'Atentei para todas as obras que se fazem debaixo do sol, e eis que tudo era vaidade e aflição de espírito.',
        'Aquilo que é torto não se pode endireitar; aquilo que falta não se pode calcular.',
        'Falei eu com o meu coração, dizendo: Eis que eu me engrandeci, e sobrepujei em sabedoria a todos os que houve antes de mim em Jerusalém; e o meu coração contemplou abundantemente a sabedoria e o conhecimento.',
        'E apliquei o meu coração a conhecer a sabedoria e a conhecer os desvarios e as loucuras, e vim a saber que também isto era aflição de espírito.',
        'Porque na muita sabedoria há muito enfado; e o que aumenta em conhecimento, aumenta em dor.',
      ],
      [
        'Disse eu no meu coração: Ora vem, eu te provarei com alegria; portanto goza o prazer; mas eis que também isso era vaidade.',
        'Ao riso disse: Está doido; e da alegria: De que serve esta?',
        'Busquei no meu coração como estimular com vinho a minha carne (regendo porém o meu coração com sabedoria), e entregar-me à loucura, até ver o que seria melhor que os filhos dos homens fizessem debaixo do céu durante o número dos dias de sua vida.',
        'Fiz para mim obras magníficas; edifiquei para mim casas; plantei para mim vinhas.',
        'Fiz para mim hortas e jardins, e plantei neles árvores de toda a espécie de fruto.',
        'Fiz para mim tanques de águas, para regar com eles o bosque em que reverdeciam as árvores.',
        'Adquiri servos e servas, e tive servos nascidos em casa; também tive grandes possessões de gados e ovelhas, mais do que todos os que houve antes de mim em Jerusalém.',
        'Amontoei também para mim prata e ouro, e tesouros dos reis e das províncias; provi-me de cantores e cantoras, e das delícias dos filhos dos homens; e de instrumentos de música de toda a espécie.',
        'E fui engrandecido, e aumentei mais do que todos os que houve antes de mim em Jerusalém; perseverou também comigo a minha sabedoria.',
        'E tudo quanto desejaram os meus olhos não lhes neguei, nem privei o meu coração de alegria alguma; mas o meu coração se alegrou por todo o meu trabalho, e esta foi a minha porção de todo o meu trabalho.',
        'E olhei eu para todas as obras que fizeram as minhas mãos, como também para o trabalho que eu, trabalhando, tinha feito, e eis que tudo era vaidade e aflição de espírito, e que proveito nenhum havia debaixo do sol.',
        'Então passei a contemplar a sabedoria, e a loucura e a estultícia. Pois que fará o homem que seguir ao rei? O mesmo que outros já fizeram.',
        'Então vi eu que a sabedoria é mais excelente do que a estultícia, quanto a luz é mais excelente do que as trevas.',
        'Os olhos do homem sábio estão na sua cabeça, mas o louco anda em trevas; então também entendi eu que o mesmo lhes sucede a ambos.',
        'Assim eu disse no meu coração: Como acontece ao tolo, assim me sucederá a mim; por que então busquei eu mais a sabedoria? Então disse no meu coração que também isto era vaidade.',
        'Porque nunca haverá mais lembrança do sábio do que do tolo; porquanto de tudo, nos dias futuros, total esquecimento haverá. E como morre o sábio, assim morre o tolo!',
        'Por isso odiei esta vida, porque a obra que se faz debaixo do sol me era penosa; sim, tudo é vaidade e aflição de espírito.',
        'Também eu odiei todo o meu trabalho, que realizei debaixo do sol, visto que eu havia de deixá-lo ao homem que viesse depois de mim.',
        'E quem sabe se será sábio ou tolo? Todavia, se assenhoreará de todo o meu trabalho que realizei e em que me houve sabiamente debaixo do sol; também isto é vaidade.',
        'Então eu me volvi e entreguei o meu coração ao desespero no tocante ao trabalho, o qual realizei debaixo do sol.',
        'Porque há homem cujo trabalho é feito com sabedoria, conhecimento, e destreza; contudo deixará o seu trabalho como porção de quem nele não trabalhou; também isto é vaidade e grande mal.',
        'Porque, que mais tem o homem de todo o seu trabalho, e da aflição do seu coração, em que ele anda trabalhando debaixo do sol?',
        'Porque todos os seus dias são dores, e a sua ocupação é aflição; até de noite não descansa o seu coração; também isto é vaidade.',
        'Não há nada melhor para o homem do que comer e beber, e fazer com que sua alma goze do bem do seu trabalho. Também vi que isto vem da mão de Deus.',
        'Pois quem pode comer, ou quem pode gozar melhor do que eu?',
        'Porque ao homem que é bom diante dele, dá Deus sabedoria e conhecimento e alegria; mas ao pecador dá trabalho, para que ele ajunte, e amontoe, para dá-lo ao que é bom perante Deus. Também isto é vaidade e aflição de espírito.',
      ],
      [
        'Tudo tem o seu tempo determinado, e há tempo para todo o propósito debaixo do céu.',
        'Há tempo de nascer, e tempo de morrer; tempo de plantar, e tempo de arrancar o que se plantou;',
        'Tempo de matar, e tempo de curar; tempo de derrubar, e tempo de edificar;',
        'Tempo de chorar, e tempo de rir; tempo de prantear, e tempo de dançar;',
        'Tempo de espalhar pedras, e tempo de ajuntar pedras; tempo de abraçar, e tempo de afastar-se de abraçar;',
        'Tempo de buscar, e tempo de perder; tempo de guardar, e tempo de lançar fora;',
        'Tempo de rasgar, e tempo de coser; tempo de estar calado, e tempo de falar;',
        'Tempo de amar, e tempo de odiar; tempo de guerra, e tempo de paz.',
        'Que proveito tem o trabalhador naquilo em que trabalha?',
        'Tenho visto o trabalho que Deus deu aos filhos dos homens, para com ele os exercitar.',
        'Tudo fez formoso em seu tempo; também pôs o mundo no coração do homem, sem que este possa descobrir a obra que Deus fez desde o princípio até ao fim.',
        'Já tenho entendido que não há coisa melhor para eles do que alegrar-se e fazer bem na sua vida;',
        'E também que todo o homem coma e beba, e goze do bem de todo o seu trabalho; isto é um dom de Deus.',
        'Eu sei que tudo quanto Deus faz durará eternamente; nada se lhe deve acrescentar, e nada se lhe deve tirar; e isto faz Deus para que haja temor diante dele.',
        'O que é, já foi; e o que há de ser, também já foi; e Deus pede conta do que passou.',
        'Vi mais debaixo do sol que no lugar do juízo havia impiedade, e no lugar da justiça havia iniqüidade.',
        'Eu disse no meu coração: Deus julgará o justo e o ímpio; porque há um tempo para todo o propósito e para toda a obra.',
        'Disse eu no meu coração, quanto a condição dos filhos dos homens, que Deus os provaria, para que assim pudessem ver que são em si mesmos como os animais.',
        'Porque o que sucede aos filhos dos homens, isso mesmo também sucede aos animais, e lhes sucede a mesma coisa; como morre um, assim morre o outro; e todos têm o mesmo fôlego, e a vantagem dos homens sobre os animais não é nenhuma, porque todos são vaidade.',
        'Todos vão para um lugar; todos foram feitos do pó, e todos voltarão ao pó.',
        'Quem sabe que o fôlego do homem vai para cima, e que o fôlego dos animais vai para baixo da terra?',
        'Assim que tenho visto que não há coisa melhor do que alegrar-se o homem nas suas obras, porque essa é a sua porção; pois quem o fará voltar para ver o que será depois dele?',
      ],
      [
        'Depois voltei-me, e atentei para todas as opressões que se fazem debaixo do sol; e eis que vi as lágrimas dos que foram oprimidos e dos que não têm consolador, e a força estava do lado dos seus opressores; mas eles não tinham consolador.',
        'Por isso eu louvei os que já morreram, mais do que os que vivem ainda.',
        'E melhor que uns e outros é aquele que ainda não é; que não viu as más obras que se fazem debaixo do sol.',
        'Também vi eu que todo o trabalho, e toda a destreza em obras, traz ao homem a inveja do seu próximo. Também isto é vaidade e aflição de espírito.',
        'O tolo cruza as suas mãos, e come a sua própria carne.',
        'Melhor é a mão cheia com descanso do que ambas as mãos cheias com trabalho, e aflição de espírito.',
        'Outra vez me voltei, e vi vaidade debaixo do sol.',
        'Há um que é só, e não tem ninguém, nem tampouco filho nem irmão; e contudo não cessa do seu trabalho, e também seus olhos não se satisfazem com riqueza; nem diz: Para quem trabalho eu, privando a minha alma do bem? Também isto é vaidade e enfadonha ocupação.',
        'Melhor é serem dois do que um, porque têm melhor paga do seu trabalho.',
        'Porque se um cair, o outro levanta o seu companheiro; mas ai do que estiver só; pois, caindo, não haverá outro que o levante.',
        'Também, se dois dormirem juntos, eles se aquentarão; mas um só, como se aquentará?',
        'E, se alguém prevalecer contra um, os dois lhe resistirão; e o cordão de três dobras não se quebra tão depressa.',
        'Melhor é a criança pobre e sábia do que o rei velho e insensato, que não se deixa mais admoestar.',
        'Porque um sai do cárcere para reinar; enquanto outro, que nasceu em seu reino, torna-se pobre.',
        'Vi a todos os viventes andarem debaixo do sol com a criança, a sucessora, que ficará no seu lugar.',
        'Não tem fim todo o povo que foi antes dele; tampouco os que lhe sucederem se alegrarão dele. Na verdade que também isto é vaidade e aflição de espírito.',
      ],
      [
        'Guarda o teu pé, quando entrares na casa de Deus; porque chegar-se para ouvir é melhor do que oferecer sacrifícios de tolos, pois não sabem que fazem mal.',
        'Não te precipites com a tua boca, nem o teu coração se apresse a pronunciar palavra alguma diante de Deus; porque Deus está nos céus, e tu estás sobre a terra; assim sejam poucas as tuas palavras.',
        'Porque, da muita ocupação vêm os sonhos, e a voz do tolo da multidão das palavras.',
        'Quando a Deus fizeres algum voto, não tardes em cumpri-lo; porque não se agrada de tolos; o que votares, paga-o.',
        'Melhor é que não votes do que votares e não cumprires.',
        'Não consintas que a tua boca faça pecar a tua carne, nem digas diante do anjo que foi erro; por que razão se iraria Deus contra a tua voz, e destruiria a obra das tuas mãos?',
        'Porque, como na multidão dos sonhos há vaidades, assim também nas muitas palavras; mas tu teme a Deus.',
        'Se vires em alguma província opressão do pobre, e violência do direito e da justiça, não te admires de tal procedimento; pois quem está altamente colocado tem superior que o vigia; e há mais altos do que eles.',
        'O proveito da terra é para todos; até o rei se serve do campo.',
        'Quem amar o dinheiro jamais dele se fartará; e quem amar a abundância nunca se fartará da renda; também isto é vaidade.',
        'Onde os bens se multiplicam, ali se multiplicam também os que deles comem; que mais proveito, pois, têm os seus donos do que os ver com os seus olhos?',
        'Doce é o sono do trabalhador, quer coma pouco quer muito; mas a fartura do rico não o deixa dormir.',
        'Há um grave mal que vi debaixo do sol, e atrai enfermidades: as riquezas que os seus donos guardam para o seu próprio dano;',
        'Porque as mesmas riquezas se perdem por qualquer má ventura, e havendo algum filho nada lhe fica na sua mão.',
        'Como saiu do ventre de sua mãe, assim nu tornará, indo-se como veio; e nada tomará do seu trabalho, que possa levar na sua mão.',
        'Assim que também isto é um grave mal que, justamente como veio, assim há de ir; e que proveito lhe vem de trabalhar para o vento,',
        'E de haver comido todos os seus dias nas trevas, e de haver padecido muito enfado, e enfermidade, e furor?',
        'Eis aqui o que eu vi, uma boa e bela coisa: comer e beber, e gozar cada um do bem de todo o seu trabalho, em que trabalhou debaixo do sol, todos os dias de vida que Deus lhe deu, porque esta é a sua porção.',
        'E a todo o homem, a quem Deus deu riquezas e bens, e lhe deu poder para delas comer e tomar a sua porção, e gozar do seu trabalho, isto é dom de Deus.',
        'Porque não se lembrará muito dos dias da sua vida; porquanto Deus lhe enche de alegria o seu coração.',
      ],
      [
        'Há um mal que tenho visto debaixo do sol, e é mui freqüente entre os homens:',
        'Um homem a quem Deus deu riquezas, bens e honra, e nada lhe falta de tudo quanto a sua alma deseja, e Deus não lhe dá poder para daí comer, antes o estranho lho come; também isto é vaidade e má enfermidade.',
        'Se o homem gerar cem filhos, e viver muitos anos, e os dias dos seus anos forem muitos, e se a sua alma não se fartar do bem, e além disso não tiver sepultura, digo que um aborto é melhor do que ele.',
        'Porquanto debalde veio, e em trevas se vai, e de trevas se cobre o seu nome.',
        'E ainda que nunca viu o sol, nem conheceu nada, mais descanso tem este do que aquele.',
        'E, ainda que vivesse duas vezes mil anos e não gozasse o bem, não vão todos para um mesmo lugar?',
        'Todo o trabalho do homem é para a sua boca, e contudo nunca se satisfaz o seu apetite.',
        'Porque, que mais tem o sábio do que o tolo? E que mais tem o pobre que sabe andar perante os vivos?',
        'Melhor é a vista dos olhos do que o vaguear da cobiça; também isto é vaidade e aflição de espírito.',
        'Seja qualquer o que for, já o seu nome foi nomeado, e sabe-se que é homem, e que não pode contender com o que é mais forte do que ele.',
        'Na verdade que há muitas coisas que multiplicam a vaidade; que mais tem o homem de melhor?',
        'Pois, quem sabe o que é bom nesta vida para o homem, por todos os dias da sua vida de vaidade, os quais gasta como sombra? Quem declarará ao homem o que será depois dele debaixo do sol?',
      ],
      [
        'Melhor é a boa fama do que o melhor ungüento, e o dia da morte do que o dia do nascimento de alguém.',
        'Melhor é ir à casa onde há luto do que ir à casa onde há banquete, porque naquela está o fim de todos os homens, e os vivos o aplicam ao seu coração.',
        'Melhor é a mágoa do que o riso, porque com a tristeza do rosto se faz melhor o coração.',
        'O coração dos sábios está na casa do luto, mas o coração dos tolos na casa da alegria.',
        'Melhor é ouvir a repreensão do sábio, do que ouvir alguém a canção do tolo.',
        'Porque qual o crepitar dos espinhos debaixo de uma panela, tal é o riso do tolo; também isto é vaidade.',
        'Verdadeiramente que a opressão faria endoidecer até ao sábio, e o suborno corrompe o coração.',
        'Melhor é o fim das coisas do que o princípio delas; melhor é o paciente de espírito do que o altivo de espírito.',
        'Não te apresses no teu espírito a irar-te, porque a ira repousa no íntimo dos tolos.',
        'Nunca digas: Por que foram os dias passados melhores do que estes? Porque não provém da sabedoria esta pergunta.',
        'Tão boa é a sabedoria como a herança, e dela tiram proveito os que vêem o sol.',
        'Porque a sabedoria serve de defesa, como de defesa serve o dinheiro; mas a excelência do conhecimento é que a sabedoria dá vida ao seu possuidor.',
        'Atenta para a obra de Deus; porque quem poderá endireitar o que ele fez torto?',
        'No dia da prosperidade goza do bem, mas no dia da adversidade considera; porque também Deus fez a este em oposição àquele, para que o homem nada descubra do que há de vir depois dele.',
        'Tudo isto vi nos dias da minha vaidade: há justo que perece na sua justiça, e há ímpio que prolonga os seus dias na sua maldade.',
        'Não sejas demasiadamente justo, nem demasiadamente sábio; por que te destruirias a ti mesmo?',
        'Não sejas demasiadamente ímpio, nem sejas louco; por que morrerias fora de teu tempo?',
        'Bom é que retenhas isto, e também daquilo não retires a tua mão; porque quem teme a Deus escapa de tudo isso.',
        'A sabedoria fortalece ao sábio, mais do que dez poderosos que haja na cidade.',
        'Na verdade que não há homem justo sobre a terra, que faça o bem, e nunca peque.',
        'Tampouco apliques o teu coração a todas as palavras que se disserem, para que não venhas a ouvir o teu servo amaldiçoar-te.',
        'Porque o teu coração também já confessou que muitas vezes tu amaldiçoaste a outros.',
        'Tudo isto provei-o pela sabedoria; eu disse: Sabedoria adquirirei; mas ela ainda estava longe de mim.',
        'O que já sucedeu é remoto e profundíssimo; quem o achará?',
        'Eu apliquei o meu coração para saber, e inquirir, e buscar a sabedoria e a razão das coisas, e para conhecer que a impiedade é insensatez e que a estultícia é loucura.',
        'E eu achei uma coisa mais amarga do que a morte, a mulher cujo coração são redes e laços, e cujas mãos são ataduras; quem for bom diante de Deus escapará dela, mas o pecador virá a ser preso por ela.',
        'Vedes aqui, isto achei, diz o pregador, conferindo uma coisa com a outra para achar a razão delas;',
        'A qual ainda busca a minha alma, porém ainda não a achei; um homem entre mil achei eu, mas uma mulher entre todas estas não achei.',
        'Eis aqui, o que tão-somente achei: que Deus fez ao homem reto, porém eles buscaram muitas astúcias.',
      ],
      [
        'Quem é como o sábio? E quem sabe a interpretação das coisas? A sabedoria do homem faz brilhar o seu rosto, e a dureza do seu rosto se muda.',
        'Eu digo: Observa o mandamento do rei, e isso em consideração ao juramento que fizeste a Deus.',
        'Não te apresses a sair da presença dele, nem persistas em alguma coisa má, porque ele faz tudo o que quer.',
        'Porque a palavra do rei tem poder; e quem lhe dirá: Que fazes?',
        'Quem guardar o mandamento não experimentará nenhum mal; e o coração do sábio discernirá o tempo e o juízo.',
        'Porque para todo o propósito há seu tempo e juízo; porquanto a miséria do homem pesa sobre ele.',
        'Porque não sabe o que há de suceder, e quando há de ser, quem lho dará a entender?',
        'Nenhum homem há que tenha domínio sobre o espírito, para o reter; nem tampouco tem ele poder sobre o dia da morte; como também não há licença nesta peleja; nem tampouco a impiedade livrará aos ímpios.',
        'Tudo isto vi quando apliquei o meu coração a toda a obra que se faz debaixo do sol; tempo há em que um homem tem domínio sobre outro homem, para desgraça sua.',
        'Assim também vi os ímpios, quando os sepultavam; e eles entravam, e saíam do lugar santo; e foram esquecidos na cidade, em que assim fizeram; também isso é vaidade.',
        'Porquanto não se executa logo o juízo sobre a má obra, por isso o coração dos filhos dos homens está inteiramente disposto para fazer o mal.',
        'Ainda que o pecador faça o mal cem vezes, e os dias se lhe prolonguem, contudo eu sei com certeza que bem sucede aos que temem a Deus, aos que temem diante dele.',
        'Porém o ímpio não irá bem, e ele não prolongará os seus dias, que são como a sombra; porque ele não teme diante de Deus.',
        'Ainda há outra vaidade que se faz sobre a terra: que há justos a quem sucede segundo as obras dos ímpios, e há ímpios a quem sucede segundo as obras dos justos. Digo que também isto é vaidade.',
        'Então louvei eu a alegria, porquanto para o homem nada há melhor debaixo do sol do que comer, beber e alegrar-se; porque isso o acompanhará no seu trabalho nos dias da sua vida que Deus lhe dá debaixo do sol.',
        'Aplicando eu o meu coração a conhecer a sabedoria, e a ver o trabalho que há sobre a terra (que nem de dia nem de noite vê o homem sono nos seus olhos);',
        'Então vi toda a obra de Deus, que o homem não pode perceber, a obra que se faz debaixo do sol; por mais que trabalhe o homem para a descobrir, não a achará; e, ainda que diga o sábio que a conhece, nem por isso a poderá compreender.',
      ],
      [
        'Deveras todas estas coisas considerei no meu coração, para declarar tudo isto: que os justos, e os sábios, e as suas obras, estão nas mãos de Deus, e também o homem não conhece nem o amor nem o ódio; tudo passa perante ele.',
        'Tudo sucede igualmente a todos; o mesmo sucede ao justo e ao ímpio, ao bom e ao puro, como ao impuro; assim ao que sacrifica como ao que não sacrifica; assim ao bom como ao pecador; ao que jura como ao que teme o juramento.',
        'Este é o mal que há entre tudo quanto se faz debaixo do sol; a todos sucede o mesmo; e que também o coração dos filhos dos homens está cheio de maldade, e que há desvarios no seu coração enquanto vivem, e depois se vão aos mortos.',
        'Ora, para aquele que está entre os vivos há esperança (porque melhor é o cão vivo do que o leão morto).',
        'Porque os vivos sabem que hão de morrer, mas os mortos não sabem coisa nenhuma, nem tampouco terão eles recompensa, mas a sua memória fica entregue ao esquecimento.',
        'Também o seu amor, o seu ódio, e a sua inveja já pereceram, e já não têm parte alguma para sempre, em coisa alguma do que se faz debaixo do sol.',
        'Vai, pois, come com alegria o teu pão e bebe com coração contente o teu vinho, pois já Deus se agrada das tuas obras.',
        'Em todo o tempo sejam alvas as tuas roupas, e nunca falte o óleo sobre a tua cabeça.',
        'Goza a vida com a mulher que amas, todos os dias da tua vida vã, os quais Deus te deu debaixo do sol, todos os dias da tua vaidade; porque esta é a tua porção nesta vida, e no teu trabalho, que tu fizeste debaixo do sol.',
        'Tudo quanto te vier à mão para fazer, faze-o conforme as tuas forças, porque na sepultura, para onde tu vais, não há obra nem projeto, nem conhecimento, nem sabedoria alguma.',
        'Voltei-me, e vi debaixo do sol que não é dos ligeiros a carreira, nem dos fortes a batalha, nem tampouco dos sábios o pão, nem tampouco dos prudentes as riquezas, nem tampouco dos entendidos o favor, mas que o tempo e a oportunidade ocorrem a todos.',
        'que também o homem não sabe o seu tempo; assim como os peixes que se pescam com a rede maligna, e como os passarinhos que se prendem com o laço, assim se enlaçam também os filhos dos homens no mau tempo, quando cai de repente sobre eles.',
        'Também vi esta sabedoria debaixo do sol, que para mim foi grande:',
        'Houve uma pequena cidade em que havia poucos homens, e veio contra ela um grande rei, e a cercou e levantou contra ela grandes baluartes;',
        'E encontrou-se nela um sábio pobre, que livrou aquela cidade pela sua sabedoria, e ninguém se lembrava daquele pobre homem.',
        'Então disse eu: Melhor é a sabedoria do que a força, ainda que a sabedoria do pobre foi desprezada, e as suas palavras não foram ouvidas.',
        'As palavras dos sábios devem em silêncio ser ouvidas, mais do que o clamor do que domina entre os tolos.',
        'Melhor é a sabedoria do que as armas de guerra, porém um só pecador destrói muitos bens.',
      ],
      [
        'Assim como as moscas mortas fazem exalar mau cheiro e inutilizar o ungüento do perfumador, assim é, para o famoso em sabedoria e em honra, um pouco de estultícia.',
        'O coração do sábio está à sua direita, mas o coração do tolo está à sua esquerda.',
        'E, até quando o tolo vai pelo caminho, falta-lhe o seu entendimento e diz a todos que é tolo.',
        'Levantando-se contra ti o espírito do governador, não deixes o teu lugar, porque a submissão é um remédio que aplaca grandes ofensas.',
        'Ainda há um mal que vi debaixo do sol, como o erro que procede do governador.',
        'A estultícia está posta em grandes alturas, mas os ricos estão assentados em lugar baixo.',
        'Vi os servos a cavalo, e os príncipes andando sobre a terra como servos.',
        'Quem abrir uma cova, nela cairá, e quem romper um muro, uma cobra o morderá.',
        'Aquele que transporta pedras, será maltratado por elas, e o que racha lenha expõe-se ao perigo.',
        'Se estiver embotado o ferro, e não se afiar o corte, então se deve redobrar a força; mas a sabedoria é excelente para dirigir.',
        'Seguramente a serpente morderá antes de estar encantada, e o falador não é melhor.',
        'Nas palavras da boca do sábio há favor, porém os lábios do tolo o devoram.',
        'O princípio das palavras da sua boca é a estultícia, e o fim do seu falar um desvario péssimo.',
        'O tolo multiplica as palavras, porém, o homem não sabe o que será; e quem lhe fará saber o que será depois dele?',
        'O trabalho dos tolos a cada um deles fatiga, porque não sabem como ir à cidade.',
        'Ai de ti, ó terra, quando teu rei é uma criança, e cujos príncipes comem de manhã.',
        'Bem-aventurada tu, ó terra, quando teu rei é filho dos nobres, e teus príncipes comem a tempo, para se fortalecerem, e não para bebedice.',
        'Por muita preguiça se enfraquece o teto, e pela frouxidão das mãos a casa goteja.',
        'Para rir se fazem banquetes, e o vinho produz alegria, e por tudo o dinheiro responde.',
        'Nem ainda no teu pensamento amaldiçoes ao rei, nem tampouco no mais interior da tua recâmara amaldiçoes ao rico; porque as aves dos céus levariam a voz, e os que têm asas dariam notícia do assunto.',
      ],
      [
        'Lança o teu pão sobre as águas, porque depois de muitos dias o acharás.',
        'Reparte com sete, e ainda até com oito, porque não sabes que mal haverá sobre a terra.',
        'Estando as nuvens cheias, derramam a chuva sobre a terra, e caindo a árvore para o sul, ou para o norte, no lugar em que a árvore cair ali ficará.',
        'Quem observa o vento, nunca semeará, e o que olha para as nuvens nunca segará.',
        'Assim como tu não sabes qual o caminho do vento, nem como se formam os ossos no ventre da mulher grávida, assim também não sabes as obras de Deus, que faz todas as coisas.',
        'Pela manhã semeia a tua semente, e à tarde não retires a tua mão, porque tu não sabes qual prosperará, se esta, se aquela, ou se ambas serão igualmente boas.',
        'Certamente suave é a luz, e agradável é aos olhos ver o sol.',
        'Porém, se o homem viver muitos anos, e em todos eles se alegrar, também se deve lembrar dos dias das trevas, porque hão de ser muitos. Tudo quanto sucede é vaidade.',
        'Alegra-te, jovem, na tua mocidade, e recreie-se o teu coração nos dias da tua mocidade, e anda pelos caminhos do teu coração, e pela vista dos teus olhos; sabe, porém, que por todas estas coisas te trará Deus a juízo.',
        'Afasta, pois, a ira do teu coração, e remove da tua carne o mal, porque a adolescência e a juventude são vaidade.',
      ],
      [
        'Lembra-te também do teu Criador nos dias da tua mocidade, antes que venham os maus dias, e cheguem os anos dos quais venhas a dizer: Não tenho neles contentamento;',
        'Antes que se escureçam o sol, e a luz, e a lua, e as estrelas, e tornem a vir as nuvens depois da chuva;',
        'No dia em que tremerem os guardas da casa, e se encurvarem os homens fortes, e cessarem os moedores, por já serem poucos, e se escurecerem os que olham pelas janelas;',
        'E as portas da rua se fecharem por causa do baixo ruído da moedura, e se levantar à voz das aves, e todas as filhas da música se abaterem.',
        'Como também quando temerem o que é alto, e houver espantos no caminho, e florescer a amendoeira, e o gafanhoto for um peso, e perecer o apetite; porque o homem se vai à sua casa eterna, e os pranteadore andarão rodeando pela praça;',
        'Antes que se rompa o cordão de prata, e se quebre o copo de ouro, e se despedace o cântaro junto à fonte, e se quebre a roda junto ao poço,',
        'E o pó volte à terra, como o era, e o espírito volte a Deus, que o deu.',
        'Vaidade de vaidades, diz o pregador, tudo é vaidade.',
        'E, quanto mais sábio foi o pregador, tanto mais ensinou ao povo sabedoria; e atentando, e esquadrinhando, compôs muitos provérbios.',
        'Procurou o pregador achar palavras agradáveis; e escreveu-as com retidão, palavras de verdade.',
        'As palavras dos sábios são como aguilhões, e como pregos, bem fixados pelos mestres das assembléias, que nos foram dadas pelo único Pastor.',
        'E, demais disto, filho meu, atenta: não há limite para fazer livros, e o muito estudar é enfado da carne.',
        'De tudo o que se tem ouvido, o fim é: Teme a Deus, e guarda os seus mandamentos; porque isto é o dever de todo o homem.',
        'Porque Deus há de trazer a juízo toda a obra, e até tudo o que está encoberto, quer seja bom, quer seja mau.',
      ],
    ],
    livro: 'eclesiastes',
  },
  {
    abbrev: 'ct',
    capitulos: [
      [
        'cântico dos cânticos, que é de Salomão.',
        'Beije-me ele com os beijos da sua boca; porque melhor é o teu amor do que o vinho.',
        'Suave é o aroma dos teus ungüentos; como o ungüento derramado é o teu nome; por isso as virgens te amam.',
        'Leva-me tu; correremos após ti. O rei me introduziu nas suas câmaras; em ti nos regozijaremos e nos alegraremos; do teu amor nos lembraremos, mais do que do vinho; os retos te amam.',
        'Eu sou morena, porém formosa, ó filhas de Jerusalém, como as tendas de Quedar, como as cortinas de Salomão.',
        'Não olheis para o eu ser morena, porque o sol resplandeceu sobre mim; os filhos de minha mãe indignaram-se contra mim, puseram-me por guarda das vinhas; a minha vinha, porém, não guardei.',
        'Dize-me, ó tu, a quem ama a minha alma: Onde apascentas o teu rebanho, onde o fazes descansar ao meio-dia; pois por que razão seria eu como a que anda errante junto aos rebanhos de teus companheiros?',
        'Se tu não o sabes, ó mais formosa entre as mulheres, sai-te pelas pisadas do rebanho, e apascenta as tuas cabras junto às moradas dos pastores.',
        'Às éguas dos carros de Faraó te comparo, ó meu amor.',
        'Formosas são as tuas faces entre os teus enfeites, o teu pescoço com os colares.',
        'Enfeites de ouro te faremos, com incrustações de prata.',
        'Enquanto o rei está assentado à sua mesa, o meu nardo exala o seu perfume.',
        'O meu amado é para mim como um ramalhete de mirra, posto entre os meus seios.',
        'Como um ramalhete de hena nas vinhas de En-Gedi, é para mim o meu amado.',
        'Eis que és formosa, ó meu amor, eis que és formosa; os teus olhos são como os das pombas.',
        'Eis que és formoso, ó amado meu, e também amável; o nosso leito é verde.',
        'As traves da nossa casa são de cedro, as nossas varandas de cipreste.',
      ],
      [
        'Eu sou a rosa de Sarom, o lírio dos vales.',
        'Qual o lírio entre os espinhos, tal é meu amor entre as filhas.',
        'Qual a macieira entre as árvores do bosque, tal é o meu amado entre os filhos;desejo muito a sua sombra, e debaixo dela me assento;e o seu fruto é doce ao meu paladar.',
        'Levou-me à casa do banquete, e o seu estandarte sobre mim era o amor.',
        'Sustentai-me com passas, confortai-me com maçãs, porque desfaleço de amor.',
        'A sua mão esquerda esteja debaixo da minha cabeça, e a sua mão direita me abrace.',
        'Conjuro-vos, ó filhas de Jerusalém, pelas gazelas e cervas do campo, que não acordeis nem desperteis o meu amor, até que queira.',
        'Esta é a voz do meu amado; ei-lo aí, que já vem saltando sobre os montes, pulando sobre os outeiros.',
        'O meu amado é semelhante ao gamo, ou ao filho do veado; eis que está detrás da nossa parede, olhando pelas janelas, espreitando pelas grades.',
        'O meu amado fala e me diz: Levanta-te, meu amor, formosa minha, e vem.',
        'Porque eis que passou o inverno; a chuva cessou, e se foi;',
        'Aparecem as flores na terra, o tempo de cantar chega, e a voz da rola ouve-se em nossa terra.',
        'A figueira já deu os seus figos verdes, e as vides em flor exalam o seu aroma; levanta-te, meu amor, formosa minha, e vem.',
        'Pomba minha, que andas pelas fendas das penhas, no oculto das ladeiras, mostra-me a tua face, faze-me ouvir a tua voz, porque a tua voz é doce, e a tua face graciosa.',
        'Apanhai-nos as raposas, as raposinhas, que fazem mal às vinhas, porque as nossas vinhas estão em flor.',
        'O meu amado é meu, e eu sou dele; ele apascenta o seu rebanho entre os lírios.',
        'Até que refresque o dia, e fujam as sombras, volta, amado meu; faze-te semelhante ao gamo ou ao filho dos veados sobre os montes de Beter.',
      ],
      [
        'De noite, em minha cama, busquei aquele a quem ama a minha alma; busquei-o, e não o achei.',
        'Levantar-me-ei, pois, e rodearei a cidade;pelas ruas e pelas praças buscarei aquele a quem ama a minha alma;busquei-o, e não o achei.',
        'Acharam-me os guardas, que rondavam pela cidade;eu lhes perguntei: Vistes aquele a quem ama a minha alma?',
        'Apartando-me eu um pouco deles, logo achei aquele a quem ama a minha alma; agarrei-me a ele, e não o larguei, até que o introduzi em casa de minha mãe, na câmara daquela que me gerou.',
        'Conjuro-vos, ó filhas de Jerusalém, pelas gazelas e cervas do campo, que não acordeis, nem desperteis o meu amor, até que queira.',
        'Quem é esta que sobe do deserto, como colunas de fumaça, perfumada de mirra, de incenso, e de todos os pós dos mercadores?',
        'Eis que é a liteira de Salomão; sessenta valentes estão ao redor dela, dos valentes de Israel;',
        'Todos armados de espadas, destros na guerra; cada um com a sua espada à cinta por causa dos temores noturnos.',
        'O rei Salomão fez para si uma carruagem de madeira do Líbano.',
        'Fez-lhe as colunas de prata, o estrado de ouro, o assento de púrpura, o interior revestido com amor, pelas filhas de Jerusalém.',
        'Saí, ó filhas de Sião, e contemplai ao rei Salomão com a coroa com que o coroou sua mãe no dia do seu desposório e no dia do júbilo do seu coração.',
      ],
      [
        'Eis que és formosa, meu amor, eis que és formosa; os teus olhos são como os das pombas entre as tuas tranças; o teu cabelo é como o rebanho de cabras que pastam no monte de Gileade.',
        'Os teus dentes são como o rebanho das ovelhas tosquiadas, que sobem do lavadouro, e das quais todas produzem gêmeos, e nenhuma há estéril entre elas.',
        'Os teus lábios são como um fio de escarlate, e o teu falar é agradável; a tua fronte é qual um pedaço de romã entre os teus cabelos.',
        'O teu pescoço é como a torre de Davi, edificada para pendurar armas; mil escudos pendem dela, todos broquéis de poderosos.',
        'Os teus dois seios são como dois filhos gêmeos da gazela, que se apascentam entre os lírios.',
        'Até que refresque o dia, e fujam as sombras, irei ao monte da mirra, e ao outeiro do incenso.',
        'Tu és toda formosa, meu amor, e em ti não há mancha.',
        'Vem comigo do Líbano, ó minha esposa, vem comigo do Líbano; olha desde o cume de Amana, desde o cume de Senir e de Hermom, desde os covis dos leões, desde os montes dos leopardos.',
        'Enlevaste-me o coração, minha irmã, minha esposa; enlevaste-me o coração com um dos teus olhares, com um colar do teu pescoço.',
        'Que belos são os teus amores, minha irmã, esposa minha! Quanto melhor é o teu amor do que o vinho! E o aroma dos teus ungüentos do que o de todas as especiarias!',
        'Favos de mel manam dos teus lábios, minha esposa! Mel e leite estão debaixo da tua língua, e o cheiro dos teus vestidos é como o cheiro do Líbano.',
        'Jardim fechado és tu, minha irmã, esposa minha, manancial fechado, fonte selada.',
        'Os teus renovos são um pomar de romãs, com frutos excelentes, o cipreste com o nardo.',
        'O nardo, e o açafrão, o cálamo, e a canela, com toda a sorte de árvores de incenso, a mirra e aloés, com todas as principais especiarias.',
        'És a fonte dos jardins, poço das águas vivas, que correm do Líbano!',
        'Levanta-te, vento norte, e vem tu, vento sul; assopra no meu jardim, para que destilem os seus aromas. Ah! entre o meu amado no jardim, e coma os seus frutos excelentes!',
      ],
      [
        'Já entrei no meu jardim, minha irmã, minha esposa; colhi a minha mirra com a minha especiaria, comi o meu favo com o meu mel, bebi o meu vinho com o meu leite; comei, amigos, bebei abundantemente, ó amados.',
        'Eu dormia, mas o meu coração velava; e eis a voz do meu amado que está batendo: abre-me, minha irmã, meu amor, pomba minha, imaculada minha, porque a minha cabeça está cheia de orvalho, os meus cabelos das gotas da noite.',
        'Já despi a minha roupa; como a tornarei a vestir? Já lavei os meus pés; como os tornarei a sujar?',
        'O meu amado pôs a sua mão pela fresta da porta, e as minhas entranhas estremeceram por amor dele.',
        'Eu me levantei para abrir ao meu amado, e as minhas mãos gotejavam mirra, e os meus dedos mirra com doce aroma, sobre as aldravas da fechadura.',
        'Eu abri ao meu amado, mas já o meu amado tinha se retirado, e tinha ido; a minha alma desfaleceu quando ele falou; busquei-o e não o achei, chamei-o e não me respondeu.',
        'Acharam-me os guardas que rondavam pela cidade; espancaram-me, feriram-me, tiraram-me o manto os guardas dos muros.',
        'Conjuro-vos, ó filhas de Jerusalém, que, se achardes o meu amado, lhe digais que estou enferma de amor.',
        'Que é o teu amado mais do que outro amado, ó tu, a mais formosa entre as mulheres? Que é o teu amado mais do que outro amado, que tanto nos conjuras?',
        'O meu amado é branco e rosado; ele é o primeiro entre dez mil.',
        'A sua cabeça é como o ouro mais apurado, os seus cabelos são crespos, pretos como o corvo.',
        'Os seus olhos são como os das pombas junto às correntes das águas, lavados em leite, postos em engaste.',
        'As suas faces são como um canteiro de bálsamo, como flores perfumadas; os seus lábios são como lírios gotejando mirra com doce aroma.',
        'As suas mãos são como anéis de ouro engastados de berilo; o seu ventre como alvo marfim, coberto de safiras.',
        'As suas pernas como colunas de mármore, colocadas sobre bases de ouro puro; o seu aspecto como o Líbano, excelente como os cedros.',
        'A sua boca é muitíssimo suave; sim, ele é totalmente desejável. Tal é o meu amado, e tal o meu amigo, ó filhas de Jerusalém.',
      ],
      [
        'Para onde foi o teu amado, ó mais formosa entre as mulheres? Para onde se retirou o teu amado, para que o busquemos contigo?',
        'O meu amado desceu ao seu jardim, aos canteiros de bálsamo, para apascentar nos jardins e para colher os lírios.',
        'Eu sou do meu amado, e o meu amado é meu;ele apascenta entre os lírios.',
        'Formosa és, meu amor, como Tirza, aprazível como Jerusalém, terrível como um exército com bandeiras.',
        'Desvia de mim os teus olhos, porque eles me dominam. O teu cabelo é como o rebanho das cabras que aparecem em Gileade.',
        'Os teus dentes são como o rebanho de ovelhas que sobem do lavadouro, e das quais todas produzem gêmeos, e não há estéril entre elas.',
        'Como um pedaço de romã, assim são as tuas faces entre os teus cabelos.',
        'Sessenta são as rainhas, e oitenta as concubinas, e as virgens sem número.',
        'Porém uma é a minha pomba, a minha imaculada, a única de sua mãe, e a mais querida daquela que a deu à luz; viram-na as filhas e chamaram-na bem-aventurada, as rainhas e as concubinas louvaram-na.',
        'Quem é esta que aparece como a alva do dia, formosa como a lua, brilhante como o sol, terrível como um exército com bandeiras?',
        'Desci ao jardim das nogueiras, para ver os frutos do vale, a ver se floresciam as vides e brotavam as romãzeiras.',
        'Antes de eu o sentir, me pôs a minha alma nos carros do meu nobre povo.',
        'Volta, volta, ó Sulamita, volta, volta, para que nós te vejamos. Por que olhais para a Sulamita como para as fileiras de dois exércitos?',
      ],
      [
        'Quão formosos são os teus pés nos sapatos, ó filha do príncipe! Os contornos de tuas coxas são como jóias, trabalhadas por mãos de artista.',
        'O teu umbigo como uma taça redonda, a que não falta bebida; o teu ventre como montão de trigo, cercado de lírios.',
        'Os teus dois seios como dois filhos gêmeos de gazela.',
        'O teu pescoço como a torre de marfim; os teus olhos como as piscinas de Hesbom, junto à porta de Bate-Rabim; o teu nariz como torre do Líbano, que olha para Damasco.',
        'A tua cabeça sobre ti é como o monte Carmelo, e os cabelos da tua cabeça como a púrpura; o rei está preso nas galerias.',
        'Quão formosa, e quão aprazível és, ó amor em delícias!',
        'A tua estatura é semelhante à palmeira; e os teus seios são semelhantes aos cachos de uvas.',
        'Dizia eu: Subirei à palmeira, pegarei em seus ramos; e então os teus seios serão como os cachos na vide, e o cheiro da tua respiração como o das maçãs.',
        'E a tua boca como o bom vinho para o meu amado, que se bebe suavemente, e faz com que falem os lábios dos que dormem.',
        'Eu sou do meu amado, e ele me tem afeição.',
        'Vem, ó amado meu, saiamos ao campo, passemos as noites nas aldeias.',
        'Levantemo-nos de manhã para ir às vinhas, vejamos se florescem as vides, se já aparecem as tenras uvas, se já brotam as romãzeiras; ali te darei os meus amores.',
        'As mandrágoras exalam o seu perfume, e às nossas portas há todo o gênero de excelentes frutos, novos e velhos; ó amado meu, eu os guardei para ti.',
      ],
      [
        'Ah! quem me dera que foras como meu irmão, que mamou aos seios de minha mãe! Quando te encontrasse lá fora, beijar-te-ia, e não me desprezariam!',
        'Levar-te-ia e te introduziria na casa de minha mãe, e tu me ensinarias; eu te daria a beber do vinho aromático e do mosto das minhas romãs.',
        'A sua mão esquerda esteja debaixo da minha cabeça, e a sua direita me abrace.',
        'Conjuro-vos, ó filhas de Jerusalém, que não acordeis nem desperteis o meu amor, até que queira.',
        'Quem é esta que sobe do deserto, e vem encostada ao seu amado?\nDebaixo da macieira te despertei, ali esteve tua mãe com dores; ali esteve com dores aquela que te deu à luz.',
        'Põe-me como selo sobre o teu coração, como selo sobre o teu braço, porque o amor é forte como a morte, e duro como a sepultura o ciúme; as suas brasas são brasas de fogo, com veementes labaredas.',
        'As muitas águas não podem apagar este amor, nem os rios afogá-lo; ainda que alguém desse todos os bens de sua casa pelo amor, certamente o desprezariam.',
        'Temos uma irmã pequena, que ainda não tem seios; que faremos a esta nossa irmã, no dia em que dela se falar?',
        'Se ela for um muro, edificaremos sobre ela um palácio de prata; e, se ela for uma porta, cercá-la-emos com tábuas de cedro.',
        'Eu sou um muro, e os meus seios são como as suas torres; então eu era aos seus olhos como aquela que acha paz.',
        'Teve Salomão uma vinha em Baal-Hamom; entregou-a a uns guardas; e cada um lhe trazia pelo seu fruto mil peças de prata.',
        'A minha vinha, que me pertence, está diante de mim; as mil peças de prata são para ti, ó Salomão, e duzentas para os que guardam o seu fruto.',
        'Ó tu, que habitas nos jardins, os companheiros estão atentos para ouvir a tua voz; faze-me, pois, também ouvi-la.',
        'Vem depressa, amado meu, e faze-te semelhante ao gamo ou ao filho dos veados sobre os montes dos aromas.',
      ],
    ],
    livro: 'canticos',
  },
  {
    abbrev: 'is',
    capitulos: [
      [
        'Visão de Isaías, filho de Amós, que ele teve a respeito de Judá e Jerusalém, nos dias de Uzias, Jotão, Acaz, e Ezequias, reis de Judá.',
        'Ouvi, ó céus, e dá ouvidos, tu, ó terra; porque o Senhor tem falado: Criei filhos, e engrandeci-os; mas eles se rebelaram contra mim.',
        'O boi conhece o seu possuidor, e o jumento a manjedoura do seu dono; mas Israel não tem conhecimento, o meu povo não entende.',
        'Ai, nação pecadora, povo carregado de iniqüidade, descendência de malfeitores, filhos corruptores; deixaram ao Senhor, blasfemaram o Santo de Israel, voltaram para trás.',
        'Por que seríeis ainda castigados, se mais vos rebelaríeis? Toda a cabeça está enferma e todo o coração fraco.',
        'Desde a planta do pé até a cabeça não há nele coisa sã, senão feridas, e inchaços, e chagas podres não espremidas, nem ligadas, nem amolecidas com óleo.',
        'A vossa terra está assolada, as vossas cidades estão abrasadas pelo fogo; a vossa terra os estranhos a devoram em vossa presença; e está como devastada, numa subversão de estranhos.',
        'E a filha de Sião é deixada como a cabana na vinha, como a choupana no pepinal, como uma cidade sitiada.',
        'Se o Senhor dos Exércitos não nos tivesse deixado algum remanescente, já como Sodoma seríamos, e semelhantes a Gomorra.',
        'Ouvi a palavra do Senhor, vós poderosos de Sodoma; dai ouvidos à lei do nosso Deus, ó povo de Gomorra.',
        'De que me serve a mim a multidão de vossos sacrifícios, diz o Senhor? Já estou farto dos holocaustos de carneiros, e da gordura de animais cevados; nem me agrado de sangue de bezerros, nem de cordeiros, nem de bodes.',
        'Quando vindes para comparecer perante mim, quem requereu isto de vossas mãos, que viésseis a pisar os meus átrios?',
        'Não continueis a trazer ofertas vãs; o incenso é para mim abominação, e as luas novas, e os sábados, e a convocação das assembléias; não posso suportar iniqüidade, nem mesmo a reunião solene.',
        'As vossas luas novas, e as vossas solenidades, a minha alma as odeia; já me são pesadas; já estou cansado de as sofrer.',
        'Por isso, quando estendeis as vossas mãos, escondo de vós os meus olhos; e ainda que multipliqueis as vossas orações, não as ouvirei, porque as vossas mãos estão cheias de sangue.',
        'Lavai-vos, purificai-vos, tirai a maldade de vossos atos de diante dos meus olhos; cessai de fazer mal.',
        'Aprendei a fazer bem; procurai o que é justo; ajudai o oprimido; fazei justiça ao órfão; tratai da causa das viúvas.',
        'Vinde então, e argüi-me, diz o Senhor: ainda que os vossos pecados sejam como a escarlata, eles se tornarão brancos como a neve; ainda que sejam vermelhos como o carmesim, se tornarão como a branca lã.',
        'Se quiserdes, e obedecerdes, comereis o bem desta terra.',
        'Mas se recusardes, e fordes rebeldes, sereis devorados à espada; porque a boca do Senhor o disse.',
        'Como se fez prostituta a cidade fiel! Ela que estava cheia de retidão! A justiça habitava nela, mas agora homicidas.',
        'A tua prata tornou-se em escórias, o teu vinho se misturou com água.',
        'Os teus príncipes são rebeldes, e companheiros de ladrões; cada um deles ama as peitas, e anda atrás das recompensas; não fazem justiça ao órfão, e não chega perante eles a causa da viúva.',
        'Portanto diz o Senhor, o Senhor dos Exércitos, o Forte de Israel: Ah! tomarei satisfações dos meus adversários, e vingar-me-ei dos meus inimigos.',
        'E voltarei contra ti a minha mão, e purificarei inteiramente as tuas escórias; e tirar-te-ei toda a impureza.',
        'E te restituirei os teus juízes, como foram dantes; e os teus conselheiros, como antigamente; e então te chamarão cidade de justiça, cidade fiel.',
        'Sião será remida com juízo, e os que voltam para ela com justiça.',
        'Mas os transgressores e os pecadores serão juntamente destruídos; e os que deixarem o Senhor serão consumidos.',
        'Porque vos envergonhareis pelos carvalhos que cobiçastes, e sereis confundidos pelos jardins que escolhestes.',
        'Porque sereis como o carvalho, ao qual caem as folhas, e como o jardim que não tem água.',
        'E o forte se tornará em estopa, e a sua obra em faísca; e ambos arderão juntamente, e não haverá quem os apague.',
      ],
      [
        'Palavra que viu Isaías, filho de Amós, a respeito de Judá e de Jerusalém.',
        'E acontecerá nos últimos dias que se firmará o monte da casa do Senhor no cume dos montes, e se elevará por cima dos outeiros; e concorrerão a ele todas as nações.',
        'E irão muitos povos, e dirão: Vinde, subamos ao monte do Senhor, à casa do Deus de Jacó, para que nos ensine os seus caminhos, e andemos nas suas veredas; porque de Sião sairá a lei, e de Jerusalém a palavra do Senhor.',
        'E ele julgará entre as nações, e repreenderá a muitos povos; e estes converterão as suas espadas em enxadões e as suas lanças em foices; uma nação não levantará espada contra outra nação, nem aprenderão mais a guerrear.',
        'Vinde, ó casa de Jacó, e andemos na luz do Senhor.',
        'Mas tu desamparaste o teu povo, a casa de Jacó, porque se encheram dos costumes do oriente e são agoureiros como os filisteus; e associam-se com os filhos dos estrangeiros,',
        'E a sua terra está cheia de prata e ouro, e não têm fim os seus tesouros; também a sua terra está cheia de cavalos, e os seus carros não têm fim.',
        'Também a sua terra está cheia de ídolos; inclinam-se perante a obra das suas mãos, diante daquilo que fabricaram os seus dedos.',
        'E o povo se abate, e os nobres se humilham; portanto não lhes perdoarás.',
        'Entra nas rochas, e esconde-te no pó, do terror do Senhor e da glória da sua majestade.',
        'Os olhos altivos dos homens serão abatidos, e a sua altivez será humilhada; e só o Senhor será exaltado naquele dia.',
        'Porque o dia do Senhor dos Exércitos será contra todo o soberbo e altivo, e contra todo o que se exalta, para que seja abatido;',
        'E contra todos os cedros do Líbano, altos e sublimes; e contra todos os carvalhos de Basã;',
        'E contra todos os montes altos, e contra todos os outeiros elevados;',
        'E contra toda a torre alta, e contra todo o muro fortificado;',
        'E contra todos os navios de Társis, e contra todas as pinturas desejáveis.',
        'E a arrogância do homem será humilhada, e a sua altivez se abaterá, e só o Senhor será exaltado naquele dia.',
        'E todos os ídolos desaparecerão totalmente.',
        'Então os homens entrarão nas cavernas das rochas, e nas covas da terra, do terror do Senhor, e da glória da sua majestade, quando ele se levantar para assombrar a terra.',
        'Naquele dia o homem lançará às toupeiras e aos morcegos os seus ídolos de prata, e os seus ídolos de ouro, que fizeram para diante deles se prostrarem.',
        'E entrarão nas fendas das rochas, e nas cavernas das penhas, por causa do terror do Senhor, e da glória da sua majestade, quando ele se levantar para abalar terrivelmente a terra.',
        'Deixai-vos do homem cujo fôlego está nas suas narinas; pois em que se deve ele estimar?',
      ],
      [
        'Porque, eis que o Senhor, o SENHOR dos Exércitos, tirará de Jerusalém e de Judá o sustento e o apoio; a todo o sustento de pão e a todo o sustento de água;',
        'O poderoso, e o homem de guerra, o juiz, e o profeta, e o adivinho, e o ancião,',
        'O capitão de cinqüenta, e o homem respeitável, e o conselheiro, e o sábio entre os artífices, e o eloqüente orador.',
        'E dar-lhes-ei meninos por príncipes, e crianças governarão sobre eles.',
        'E o povo será oprimido; um será contra o outro, e cada um contra o seu próximo; o menino se atreverá contra o ancião, e o vil contra o nobre.',
        'Quando alguém pegar de seu irmão na casa de seu pai, dizendo: Tu tens roupa, sê nosso governador, e toma sob a tua mão esta ruína;',
        'Naquele dia levantará este a sua voz, dizendo: Não posso ser médico, nem tampouco há em minha casa pão, ou roupa alguma; não me haveis de constituir governador sobre o povo.',
        'Porque Jerusalém está arruinada, e Judá caída; porque a sua língua e as suas obras são contra o Senhor, para provocarem os olhos da sua glória.',
        'O aspecto do seu rosto testifica contra eles; e publicam os seus pecados, como Sodoma; não os dissimulam. Ai da sua alma! Porque fazem mal a si mesmos.',
        'Dizei ao justo que bem lhe irá; porque comerão do fruto das suas obras.',
        'Ai do ímpio! Mal lhe irá; porque se lhe fará o que as suas mãos fizeram.',
        'Os opressores do meu povo são crianças, e mulheres dominam sobre ele; ah, povo meu! Os que te guiam te enganam, e destroem o caminho das tuas veredas.',
        'O Senhor se levanta para pleitear, e põe-se de pé para julgar os povos.',
        'O Senhor entrará em juízo contra os anciãos do seu povo, e contra os seus príncipes; é que fostes vós que consumistes esta vinha; o espólio do pobre está em vossas casas.',
        'Que tendes vós, que esmagais o meu povo e moeis as faces dos pobres? Diz o Senhor DEUS dos Exércitos.',
        'Diz ainda mais o Senhor: Porquanto as filhas de Sião se exaltam, e andam com o pescoço erguido, lançando olhares impudentes; e quando andam, caminham afetadamente, fazendo um tilintar com os seus pés;',
        'Portanto o Senhor fará tinhoso o alto da cabeça das filhas de Sião, e o Senhor porá a descoberto a sua nudez,',
        'Naquele dia tirará o Senhor os ornamentos dos pés, e as toucas, e adornos em forma de lua,',
        'Os pendentes, e os braceletes, as estolas,',
        'Os gorros, e os ornamentos das pernas, e os cintos e as caixinhas de perfumes, e os brincos,',
        'Os anéis, e as jóias do nariz,',
        'Os vestidos de festa, e os mantos, e os xales, e as bolsas.',
        'Os espelhos, e o linho finíssimo, e os turbantes, e os véus.',
        'E será que em lugar de perfume haverá mau cheiro; e por cinto uma corda; e em lugar de encrespadura de cabelos, calvície; e em lugar de veste luxuosa, pano de saco; e queimadura em lugar de formosura.',
        'Teus homens cairão à espada e teus poderosos na peleja.',
        'E as suas portas gemerão e prantearão; e ela, desolada, se assentará no chão.',
      ],
      [
        'E sete mulheres naquele dia lançarão mão de um homem, dizendo: Nós comeremos do nosso pão, e nos vestiremos do que é nosso; tão-somente queremos ser chamadas pelo teu nome; tira o nosso opróbrio.',
        'Naquele dia o renovo do Senhor será cheio de beleza e de glória; e o fruto da terra excelente e formoso para os que escaparem de Israel.',
        'E será que aquele que for deixado em Sião, e ficar em Jerusalém, será chamado santo; todo aquele que estiver inscrito entre os viventes em Jerusalém;',
        'Quando o Senhor lavar a imundícia das filhas de Sião, e limpar o sangue de Jerusalém, do meio dela, com o espírito de justiça, e com o espírito de ardor.',
        'E criará o Senhor sobre todo o lugar do monte de Sião, e sobre as suas assembléias, uma nuvem de dia e uma fumaça, e um resplendor de fogo flamejante de noite; porque sobre toda a glória haverá proteção.',
        'E haverá um tabernáculo para sombra contra o calor do dia; e para refúgio e esconderijo contra a tempestade e a chuva.',
      ],
      [
        'Agora cantarei ao meu amado o cântico do meu querido a respeito da sua vinha. O meu amado tem uma vinha num outeiro fértil.',
        'E cercou-a, e limpando-a das pedras, plantou-a de excelentes vides; e edificou no meio dela uma torre, e também construiu nela um lagar; e esperava que desse uvas boas, porém deu uvas bravas.',
        'Agora, pois, ó moradores de Jerusalém, e homens de Judá, julgai, vos peço, entre mim e a minha vinha.',
        'Que mais se podia fazer à minha vinha, que eu lhe não tenha feito? Por que, esperando eu que desse uvas boas, veio a dar uvas bravas?',
        'Agora, pois, vos farei saber o que eu hei de fazer à minha vinha: tirarei a sua sebe, para que sirva de pasto; derrubarei a sua parede, para que seja pisada;',
        'E a tornarei em deserto; não será podada nem cavada; porém crescerão nela sarças e espinheiros; e às nuvens darei ordem que não derramem chuva sobre ela.',
        'Porque a vinha do Senhor dos Exércitos é a casa de Israel, e os homens de Judá são a planta das suas delícias; e esperou que exercesse juízo, e eis aqui opressão; justiça, e eis aqui clamor.',
        'Ai dos que ajuntam casa a casa, reúnem campo a campo, até que não haja mais lugar, e fiquem como únicos moradores no meio da terra!',
        'A meus ouvidos disse o Senhor dos Exércitos: Em verdade que muitas casas ficarão desertas, e até as grandes e excelentes sem moradores.',
        'E dez jeiras de vinha não darão mais do que um bato; e um ômer de semente não dará mais do que um efa.',
        'Ai dos que se levantam pela manhã, e seguem a bebedice; e continuam até à noite, até que o vinho os esquente!',
        'E harpas e alaúdes, tamboris e gaitas, e vinho há nos seus banquetes; e não olham para a obra do Senhor, nem consideram as obras das suas mãos.',
        'Portanto o meu povo será levado cativo, por falta de entendimento; e os seus nobres terão fome, e a sua multidão se secará de sede.',
        'Portanto o inferno grandemente se alargou, e se abriu a sua boca desmesuradamente; e para lá descerão o seu esplendor, e a sua multidão, e a sua pompa, e os que entre eles se alegram.',
        'Então o plebeu se abaterá, e o nobre se humilhará; e os olhos dos altivos se humilharão.',
        'Porém o Senhor dos Exércitos será exaltado em juízo; e Deus, o Santo, será santificado em justiça.',
        'Então os cordeiros pastarão como de costume, e os estranhos comerão dos lugares devastados pelos gordos.',
        'Ai dos que puxam a iniqüidade com cordas de vaidade, e o pecado com tirantes de carro!',
        'E dizem: Avie-se, e acabe a sua obra, para que a vejamos; e aproxime-se e venha o conselho do Santo de Israel, para que o conheçamos.',
        'Ai dos que ao mal chamam bem, e ao bem mal; que fazem das trevas luz, e da luz trevas; e fazem do amargo doce, e do doce amargo!',
        'Ai dos que são sábios a seus próprios olhos, e prudentes diante de si mesmos!',
        'Ai dos que são poderosos para beber vinho, e homens de poder para misturar bebida forte;',
        'Dos que justificam ao ímpio por suborno, e aos justos negam a justiça!',
        'Por isso, como a língua de fogo consome a palha, e o restolho se desfaz pela chama, assim será a sua raiz como podridão, e a sua flor se esvaecerá como pó; porquanto rejeitaram a lei do Senhor dos Exércitos, e desprezaram a palavra do Santo de Israel.',
        'Por isso se acendeu a ira do Senhor contra o seu povo, e estendeu a sua mão contra ele, e o feriu, de modo que as montanhas tremeram, e os seus cadáveres se fizeram como lixo no meio das ruas; com tudo isto não tornou atrás a sua ira, mas a sua mão ainda está estendida.',
        'E ele arvorará o estandarte para as nações de longe, e lhes assobiará para que venham desde a extremidade da terra; e eis que virão apressurada e ligeiramente.',
        'Não haverá entre eles cansado, nem quem tropece; ninguém tosquenejará nem dormirá; não se lhe desatará o cinto dos seus lombos, nem se lhe quebrará a correia dos seus sapatos.',
        'As suas flechas serão agudas, e todos os seus arcos retesados; os cascos dos seus cavalos são reputados como pederneiras, e as rodas dos seus carros como redemoinho.',
        'O seu rugido será como o do leão; rugirão como filhos de leão; sim, rugirão e arrebatarão a presa, e a levarão, e não haverá quem a livre.',
        'E bramarão contra eles naquele dia, como o bramido do mar; então olharão para a terra, e eis que só verão trevas e ânsia, e a luz se escurecerá nos céus.',
      ],
      [
        'No ano em que morreu o rei Uzias, eu vi também ao Senhor assentado sobre um alto e sublime trono; e a cauda do seu manto enchia o templo.',
        'Serafins estavam por cima dele; cada um tinha seis asas; com duas cobriam os seus rostos, e com duas cobriam os seus pés, e com duas voavam.',
        'E clamavam uns aos outros, dizendo: Santo, Santo, Santo é o Senhor dos Exércitos; toda a terra está cheia da sua glória.',
        'E os umbrais das portas se moveram à voz do que clamava, e a casa se encheu de fumaça.',
        'Então disse eu: Ai de mim! Pois estou perdido; porque sou um homem de lábios impuros, e habito no meio de um povo de impuros lábios; os meus olhos viram o Rei, o Senhor dos Exércitos.',
        'Porém um dos serafins voou para mim, trazendo na sua mão uma brasa viva, que tirara do altar com uma tenaz;',
        'E com a brasa tocou a minha boca, e disse: Eis que isto tocou os teus lábios; e a tua iniqüidade foi tirada, e expiado o teu pecado.',
        'Depois disto ouvi a voz do Senhor, que dizia: A quem enviarei, e quem há de ir por nós? Então disse eu: Eis-me aqui, envia-me a mim.',
        'Então disse ele: Vai, e dize a este povo: Ouvis, de fato, e não entendeis, e vedes, em verdade, mas não percebeis.',
        'Engorda o coração deste povo, e faze-lhe pesados os ouvidos, e fecha-lhe os olhos; para que ele não veja com os seus olhos, e não ouça com os seus ouvidos, nem entenda com o seu coração, nem se converta e seja sarado.',
        'Então disse eu: Até quando Senhor? E respondeu: Até que sejam desoladas as cidades e fiquem sem habitantes, e as casas sem moradores, e a terra seja de todo assolada.',
        'E o Senhor afaste dela os homens, e no meio da terra seja grande o desamparo.',
        'Porém ainda a décima parte ficará nela, e tornará a ser pastada; e como o carvalho, e como a azinheira, que depois de se desfolharem, ainda ficam firmes, assim a santa semente será a firmeza dela.',
      ],
      [
        'Sucedeu, pois, nos dias de Acaz, filho de Jotão, filho de Uzias, rei de Judá, que Rezim, rei da Síria, e Peca, filho de Remalias, rei de Israel, subiram a Jerusalém, para pelejarem contra ela, mas nada puderam contra ela.',
        'E deram aviso à casa de Davi, dizendo: A Síria fez aliança com Efraim. Então se moveu o seu coração, e o coração do seu povo, como se movem as árvores do bosque com o vento.',
        'Então disse o Senhor a Isaías: Agora, tu e teu filho Sear-Jasube, saí ao encontro de Acaz, ao fim do canal do tanque superior, no caminho do campo do lavandeiro.',
        'E dize-lhe: Acautela-te, e aquieta-te; não temas, nem se desanime o teu coração por causa destes dois pedaços de tições fumegantes; por causa do ardor da ira de Rezim, e da Síria, e do filho de Remalias.',
        'Porquanto a Síria teve contra ti maligno conselho, com Efraim, e com o filho de Remalias, dizendo:',
        'Vamos subir contra Judá, e molestemo-lo e repartamo-lo entre nós, e façamos reinar no meio dele o filho de Tabeal.',
        'Assim diz o Senhor DEUS: Isto não subsistirá, nem tampouco acontecerá.',
        'Porém a cabeça da Síria será Damasco, e a cabeça de Damasco Rezim; e dentro de sessenta e cinco anos Efraim será destruído, e deixará de ser povo.',
        'Entretanto a cabeça de Efraim será Samaria, e a cabeça de Samaria o filho de Remalias; se não o crerdes, certamente não haveis de permanecer.',
        'E continuou o Senhor a falar com Acaz, dizendo:',
        'Pede para ti ao Senhor teu Deus um sinal; pede-o, ou em baixo nas profundezas, ou em cima nas alturas.',
        'Acaz, porém, disse: Não pedirei, nem tentarei ao Senhor.',
        'Então ele disse: Ouvi agora, ó casa de Davi: Pouco vos é afadigardes os homens, senão que também afadigareis ao meu Deus?',
        'Portanto o mesmo Senhor vos dará um sinal: Eis que a virgem conceberá, e dará à luz um filho, e chamará o seu nome Emanuel.',
        'Manteiga e mel comerá, quando ele souber rejeitar o mal e escolher o bem.',
        'Na verdade, antes que este menino saiba rejeitar o mal e escolher o bem, a terra, de que te enfadas, será desamparada dos seus dois reis.',
        'Porém o Senhor fará vir sobre ti, e sobre o teu povo, e sobre a casa de teu pai, pelo rei da Assíria, dias tais, quais nunca vieram, desde o dia em que Efraim se separou de Judá.',
        'Porque há de acontecer que naquele dia assobiará o Senhor às moscas, que há no extremo dos rios do Egito, e às abelhas que estão na terra da Assíria;',
        'E todas elas virão, e pousarão nos vales desertos e nas fendas das rochas, e em todos os espinheiros e em todos os arbustos.',
        'Naquele mesmo dia rapará o Senhor com uma navalha alugada, que está além do rio, isto é, com o rei da Assíria, a cabeça e os cabelos dos pés; e até a barba totalmente tirará.',
        'E sucederá naquele dia que um homem criará uma novilha e duas ovelhas.',
        'E acontecerá que por causa da abundância do leite que elas hão de dar, comerá manteiga; e manteiga e mel comerá todo aquele que restar no meio da terra.',
        'Sucederá também naquele dia que todo o lugar, em que houver mil vides, do valor de mil siclos de prata, será para as sarças e para os espinheiros.',
        'Com arco e flecha se entrará ali, porque toda a terra será sarças e espinheiros.',
        'E quanto a todos os montes, que costumavam cavar com enxadas, para ali não irás por causa do temor das sarças e dos espinheiros; porém servirão para se mandarem para lá os bois e para serem pisados pelas ovelhas.',
      ],
      [
        'Disse-me também o SENHOR: Toma um grande rolo, e escreve nele com caneta de homem: Apressando-se ao despojo, apressurou-se à presa.',
        'Então tomei comigo fiéis testemunhas, a Urias sacerdote, e a Zacarias, filho de Jeberequias,',
        'E fui ter com a profetisa, e ela concebeu, e deu à luz um filho; e o Senhor me disse: Põe-lhe o nome de Maer-Salal-Has-Baz.',
        'Porque antes que o menino saiba dizer meu pai, ou minha mãe, se levarão as riquezas de Damasco, e os despojos de Samaria, diante do rei da Assíria.',
        'E continuou o Senhor a falar ainda comigo, dizendo:',
        'Porquanto este povo desprezou as águas de Siloé que correm brandamente, e alegrou-se com Rezim e com o filho de Remalias,',
        'Portanto eis que o Senhor fará subir sobre eles as águas do rio, fortes e impetuosas, isto é, o rei da Assíria, com toda a sua glória; e subirá sobre todos os seus leitos, e transbordará por todas as suas ribanceiras.',
        'E passará a Judá, inundando-o, e irá passando por ele e chegará até ao pescoço; e a extensão de suas asas encherá a largura da tua terra, ó Emanuel.',
        'Ajuntai-vos, ó povos, e sereis quebrantados; dai ouvidos, todos os que sois de terras longínquas; cingi-vos e sereis feitos em pedaços, cingi-vos e sereis feitos em pedaços.',
        'Tomai juntamente conselho, e ele será frustrado; dizei uma palavra, e ela não subsistirá, porque Deus é conosco.',
        'Porque assim o Senhor me disse com mão forte, e me ensinou que não andasse pelo caminho deste povo, dizendo:',
        'Não chameis conjuração, a tudo quanto este povo chama conjuração; e não temais o que ele teme, nem tampouco vos assombreis.',
        'Ao Senhor dos Exércitos, a ele santificai; e seja ele o vosso temor e seja ele o vosso assombro.',
        'Então ele vos será por santuário; mas servirá de pedra de tropeço, e rocha de escândalo, às duas casas de Israel; por armadilha e laço aos moradores de Jerusalém.',
        'E muitos entre eles tropeçarão, e cairão, e serão quebrantados, e enlaçados, e presos.',
        'Liga o testemunho, sela a lei entre os meus discípulos.',
        'E esperarei ao Senhor, que esconde o seu rosto da casa de Jacó, e a ele aguardarei.',
        'Eis-me aqui, com os filhos que me deu o Senhor, por sinais e por maravilhas em Israel, da parte do Senhor dos Exércitos, que habita no monte de Sião.',
        'Quando, pois, vos disserem: Consultai os que têm espíritos familiares e os adivinhos, que chilreiam e murmuram: Porventura não consultará o povo a seu Deus? A favor dos vivos consultar-se-á aos mortos?',
        'À lei e ao testemunho! Se eles não falarem segundo esta palavra, é porque não há luz neles.',
        'E passarão pela terra duramente oprimidos e famintos; e será que, tendo fome, e enfurecendo-se, então amaldiçoarão ao seu rei e ao seu Deus, olhando para cima.',
        'E, olhando para a terra, eis que haverá angústia e escuridão, e sombras de ansiedade, e serão empurrados para as trevas.',
      ],
      [
        'Mas a terra, que foi angustiada, não será entenebrecida; envileceu nos primeiros tempos, a terra de Zebulom, e a terra de Naftali; mas nos últimos tempos a enobreceu junto ao caminho do mar, além do Jordão, na Galiléia das nações.',
        'O povo que andava em trevas, viu uma grande luz, e sobre os que habitavam na região da sombra da morte resplandeceu a luz.',
        'Tu multiplicaste a nação, a alegria lhe aumentaste; todos se alegrarão perante ti, como se alegram na ceifa, e como exultam quando se repartem os despojos.',
        'Porque tu quebraste o jugo da sua carga, e o bordão do seu ombro, e a vara do seu opressor, como no dia dos midianitas.',
        'Porque todo calçado que levava o guerreiro no tumulto da batalha, e todo o manto revolvido em sangue, serão queimados, servindo de combustível ao fogo.',
        'Porque um menino nos nasceu, um filho se nos deu, e o principado está sobre os seus ombros, e se chamará o seu nome: Maravilhoso, Conselheiro, Deus Forte, Pai da Eternidade, Príncipe da Paz.',
        'Do aumento deste principado e da paz não haverá fim, sobre o trono de Davi e no seu reino, para o firmar e o fortificar com juízo e com justiça, desde agora e para sempre; o zelo do Senhor dos Exércitos fará isto.',
        'O Senhor enviou uma palavra a Jacó, e ela caiu em Israel.',
        'E todo este povo o saberá, Efraim e os moradores de Samaria, que em soberba e altivez de coração, dizem:',
        'Os tijolos caíram, mas com cantaria tornaremos a edificar; cortaram-se os sicômoros, mas em cedros as mudaremos.',
        'Portanto o Senhor suscitará, contra ele, os adversários de Rezim, e juntará os seus inimigos.',
        'Pela frente virão os sírios, e por detrás os filisteus, e devorarão a Israel à boca escancarada; e nem com tudo isto cessou a sua ira, mas ainda está estendida a sua mão.',
        'Todavia este povo não se voltou para quem o feria, nem buscou ao Senhor dos Exércitos.',
        'Assim o Senhor cortará de Israel a cabeça e a cauda, o ramo e o junco, num mesmo dia',
        '(O ancião e o homem de respeito é a cabeça; e o profeta que ensina a falsidade é a cauda).',
        'Porque os guias deste povo são enganadores, e os que por eles são guiados são destruídos.',
        'Por isso o Senhor não se regozija nos seus jovens, e não se compadecerá dos seus órfãos e das suas viúvas, porque todos eles são hipócritas e malfazejos, e toda a boca profere doidices; e nem com tudo isto cessou a sua ira, mas ainda está estendida a sua mão.',
        'Porque a impiedade lavra como um fogo, ela devora as sarças e os espinheiros; e ela se ateará no emaranhado da floresta; e subirão em espessas nuvens de fumaça.',
        'Por causa da ira do Senhor dos Exércitos a terra se escurecerá, e será o povo como combustível para o fogo; ninguém poupará ao seu irmão.',
        'Se colher à direita, ainda terá fome, e se comer à esquerda, ainda não se fartará; cada um comerá a carne de seu braço.',
        'Manassés a Efraim, e Efraim a Manassés, e ambos serão contra Judá. Com tudo isto não cessou a sua ira, mas ainda está estendida a sua mão.',
      ],
      [
        'Ai dos que decretam leis injustas, e dos escrivães que prescrevem opressão.',
        'Para desviarem os pobres do seu direito, e para arrebatarem o direito dos aflitos do meu povo; para despojarem as viúvas e roubarem os órfãos!',
        'Mas que fareis vós no dia da visitação, e na desolação, que há de vir de longe? A quem recorrereis para obter socorro, e onde deixareis a vossa glória,',
        'Sem que cada um se abata entre os presos, e caia entre mortos? Com tudo isto a sua ira não cessou, mas ainda está estendida a sua mão.',
        'Ai da Assíria, a vara da minha ira, porque a minha indignação é como bordão nas suas mãos.',
        'Enviá-la-ei contra uma nação hipócrita, e contra o povo do meu furor lhe darei ordem, para que lhe roube a presa, e lhe tome o despojo, e o ponha para ser pisado aos pés, como a lama das ruas.',
        'Ainda que ele não cuide assim, nem o seu coração assim o imagine; antes no seu coração intenta destruir e desarraigar não poucas nações.',
        'Porque diz: Não são meus príncipes todos eles reis?',
        'Não é Calno como Carquemis? Não é Hamate como Arpade? E Samaria como Damasco?',
        'Como a minha mão alcançou os reinos dos ídolos, cujas imagens esculpidas eram melhores do que as de Jerusalém e do que as de Samaria,',
        'Porventura como fiz a Samaria e aos seus ídolos, não o faria igualmente a Jerusalém e aos seus ídolos?',
        'Por isso acontecerá que, havendo o Senhor acabado toda a sua obra no monte Sião e em Jerusalém, então castigarei o fruto da arrogante grandeza do coração do rei da Assíria e a pompa da altivez dos seus olhos.',
        'Porquanto disse: Com a força da minha mão o fiz, e com a minha sabedoria, porque sou prudente; e removi os limites dos povos, e roubei os seus tesouros, e como valente abati aos habitantes.',
        'E achou a minha mão as riquezas dos povos como a um ninho, e como se ajuntam os ovos abandonados, assim eu ajuntei a toda a terra, e não houve quem movesse a asa, ou abrisse a boca, ou murmurasse.',
        'Porventura gloriar-se-á o machado contra o que corta com ele, ou presumirá a serra contra o que puxa por ela, como se o bordão movesse aos que o levantam, ou a vara levantasse como não sendo pau?',
        'Por isso o Senhor, o Senhor dos Exércitos, fará definhar os que entre eles são gordos, e debaixo da sua glória ateará um incêndio, como incêndio de fogo.',
        'Porque a Luz de Israel virá a ser como fogo e o seu Santo por labareda, que abrase e consuma os seus espinheiros e as suas sarças num só dia.',
        'Também consumirá a glória da sua floresta, e do seu campo fértil, desde a alma até à carne, e será como quando desmaia o porta-bandeira.',
        'E o resto das árvores da sua floresta será tão pouco em número, que um menino poderá contá-las.',
        'E acontecerá naquele dia que os restantes de Israel, e os que tiverem escapado da casa de Jacó, nunca mais se estribarão sobre aquele que os feriu; antes estribar-se-ão verdadeiramente sobre o Senhor, o Santo de Israel.',
        'Os restantes se converterão ao Deus forte, sim, os restantes de Jacó.',
        'Porque ainda que o teu povo, ó Israel, seja como a areia do mar, só um remanescente dele se converterá; uma destruição está determinada, transbordando em justiça.',
        'Porque determinada já a destruição, o Senhor DEUS dos Exércitos a executará no meio de toda esta terra.',
        'Por isso assim diz o Senhor DEUS dos Exércitos: Povo meu, que habitas em Sião, não temas à Assíria, quando te ferir com a vara, e contra ti levantar o seu bordão à maneira dos egípcios.',
        'Porque daqui a bem pouco se cumprirá a minha indignação e a minha ira, para a consumir.',
        'Porque o Senhor dos Exércitos suscitará contra ela um flagelo, como na matança de Midiã junto à rocha de Orebe; e a sua vara estará sobre o mar, e ele a levantará como sucedeu aos egípcios.',
        'E acontecerá, naquele dia, que a sua carga será tirada do teu ombro, e o seu jugo do teu pescoço; e o jugo será despedaçado por causa da unção.',
        'Já vem chegando a Aiate, já vai passando por Migrom, e em Micmás deixa a sua bagagem.',
        'Já passaram o desfiladeiro, já se alojam em Geba; já Ramá treme, e Gibeá de Saul vai fugindo.',
        'Clama alto com a tua voz, ó filha de Galim! Ouve, ó Laís! Ó tu pobre Anatote!',
        'Madmena já se foi; os moradores de Gebim vão fugindo em bandos.',
        'Ainda um dia parará em Nobe; acenará com a sua mão contra o monte da filha de Sião, o outeiro de Jerusalém.',
        'Mas eis que o Senhor, o Senhor dos Exércitos, cortará os ramos com violência, e os de alta estatura serão cortados, e os altivos serão abatidos.',
        'E cortará com ferro a espessura da floresta, e o Líbano cairá à mão de um poderoso.',
      ],
      [
        'Porque brotará um rebento do tronco de Jessé, e das suas raízes um renovo frutificará.',
        'E repousará sobre ele o Espírito do Senhor, o espírito de sabedoria e de entendimento, o espírito de conselho e de fortaleza, o espírito de conhecimento e de temor do Senhor.',
        'E deleitar-se-á no temor do Senhor; e não julgará segundo a vista dos seus olhos, nem repreenderá segundo o ouvir dos seus ouvidos.',
        'Mas julgará com justiça aos pobres, e repreenderá com eqüidade aos mansos da terra; e ferirá a terra com a vara de sua boca, e com o sopro dos seus lábios matará ao ímpio,',
        'E a justiça será o cinto dos seus lombos, e a fidelidade o cinto dos seus rins.',
        'E morará o lobo com o cordeiro, e o leopardo com o cabrito se deitará, e o bezerro, e o filho de leão e o animal cevado andarão juntos, e um menino pequeno os guiará.',
        'A vaca e a ursa pastarão juntas, seus filhos se deitarão juntos, e o leão comerá palha como o boi.',
        'E brincará a criança de peito sobre a toca da áspide, e a desmamada colocará a sua mão na cova do basilisco.',
        'Não se fará mal nem dano algum em todo o meu santo monte, porque a terra se encherá do conhecimento do Senhor, como as águas cobrem o mar.',
        'E acontecerá naquele dia que a raiz de Jessé, a qual estará posta por estandarte dos povos, será buscada pelos gentios; e o lugar do seu repouso será glorioso.',
        'E há de ser que naquele dia o Senhor tornará a pôr a sua mão para adquirir outra vez o remanescente do seu povo, que for deixado, da Assíria, e do Egito, e de Patros, e da Etiópia, e de Elã, e de Sinar, e de Hamate, e das ilhas do mar.',
        'E levantará um estandarte entre as nações, e ajuntará os desterrados de Israel, e os dispersos de Judá congregará desde os quatro confins da terra.',
        'E afastar-se-á a inveja de Efraim, e os adversários de Judá serão desarraigados; Efraim não invejará a Judá, e Judá não oprimirá a Efraim.',
        'Antes voarão sobre os ombros dos filisteus ao ocidente; juntos despojarão aos do oriente; em Edom e Moabe porão as suas mãos, e os filhos de Amom lhes obedecerão.',
        'E o Senhor destruirá totalmente a língua do mar do Egito, e moverá a sua mão contra o rio com a força do seu vento e, ferindo-o, dividi-lo-á em sete correntes e fará que por ele passem com sapatos secos.',
        'E haverá caminho plano para o remanescente do seu povo, que for deixado da Assíria, como sucedeu a Israel no dia em que subiu da terra do Egito.',
      ],
      [
        'E dirás naquele dia: Graças te dou, ó SENHOR, porque, ainda que te iraste contra mim, a tua ira se retirou, e tu me consolas.',
        'Eis que Deus é a minha salvação; nele confiarei, e não temerei, porque o SENHOR DEUS é a minha força e o meu cântico, e se tornou a minha salvação.',
        'E vós com alegria tirareis águas das fontes da salvação.',
        'E direis naquele dia: Dai graças ao Senhor, invocai o seu nome, fazei notório os seus feitos entre os povos, contai quão excelso é o seu nome.',
        'Cantai ao Senhor, porque fez coisas grandiosas; saiba-se isto em toda a terra.',
        'Exulta e jubila, ó habitante de Sião, porque grande é o Santo de Israel no meio de ti.',
      ],
      [
        'Peso de babilônia, que viu Isaías, filho de Amós.',
        'Alçai uma bandeira sobre o monte elevado, levantai a voz para eles; acenai-lhes com a mão, para que entrem pelas portas dos nobres.',
        'Eu dei ordens aos meus santificados; sim, já chamei os meus poderosos para executarem a minha ira, os que exultam com a minha majestade.',
        'Já se ouve a gritaria da multidão sobre os montes, como a de muito povo; o som do rebuliço de reinos e de nações congregados. O Senhor dos Exércitos passa em revista o exército de guerra.',
        'Já vem de uma terra remota, desde a extremidade do céu, o Senhor, e os instrumentos da sua indignação, para destruir toda aquela terra.',
        'Clamai, pois, o dia do Senhor está perto; vem do Todo-Poderoso como assolação.',
        'Portanto, todas as mãos se debilitarão, e o coração de todos os homens se desanimará.',
        'E assombrar-se-ão, e apoderar-se-ão deles dores e ais, e se angustiarão, como a mulher com dores de parto; cada um se espantará do seu próximo; os seus rostos serão rostos flamejantes.',
        'Eis que vem o dia do Senhor, horrendo, com furor e ira ardente, para pôr a terra em assolação, e dela destruir os pecadores.',
        'Porque as estrelas dos céus e as suas constelações não darão a sua luz; o sol se escurecerá ao nascer, e a lua não resplandecerá com a sua luz.',
        'E visitarei sobre o mundo a maldade, e sobre os ímpios a sua iniqüidade; e farei cessar a arrogância dos atrevidos, e abaterei a soberba dos tiranos.',
        'Farei que o homem seja mais precioso do que o ouro puro, e mais raro do que o ouro fino de Ofir.',
        'Por isso farei estremecer os céus; e a terra se moverá do seu lugar, por causa do furor do Senhor dos Exércitos, e por causa do dia da sua ardente ira.',
        'E cada um será como a corça que foge, e como a ovelha que ninguém recolhe; cada um voltará para o seu povo, e cada um fugirá para a sua terra.',
        'Todo o que for achado será transpassado; e todo o que se unir a ele cairá à espada.',
        'E suas crianças serão despedaçadas perante os seus olhos; as suas casas serão saqueadas, e as suas mulheres violadas.',
        'Eis que eu despertarei contra eles os medos, que não farão caso da prata, nem tampouco desejarão ouro.',
        'E os seus arcos despedaçarão os jovens, e não se compadecerão do fruto do ventre; os seus olhos não pouparão aos filhos.',
        'E babilônia, o ornamento dos reinos, a glória e a soberba dos caldeus, será como Sodoma e Gomorra, quando Deus as transtornou.',
        'Nunca mais será habitada, nem nela morará alguém de geração em geração; nem o árabe armará ali a sua tenda, nem tampouco os pastores ali farão deitar os seus rebanhos.',
        'Mas as feras do deserto repousarão ali, e as suas casas se encherão de horríveis animais; e ali habitarão os avestruzes, e os sátiros pularão ali.',
        'E os animais selvagens das ilhas uivarão em suas casas vazias, como também os chacais nos seus palácios de prazer; pois bem perto já vem chegando o seu tempo, e os seus dias não se prolongarão.',
      ],
      [
        'Porque o SENHOR se compadecerá de Jacó, e ainda escolherá a Israel e os porá na sua própria terra; e ajuntar-se-ão com eles os estrangeiros, e se achegarão à casa de Jacó.',
        'E os povos os receberão, e os levarão aos seus lugares, e a casa de Israel os possuirá por servos, e por servas, na terra do Senhor; e cativarão aqueles que os cativaram, e dominarão sobre os seus opressores.',
        'E acontecerá que no dia em que o Senhor vier a dar-te descanso do teu sofrimento, e do teu pavor, e da dura servidão com que te fizeram servir,',
        'Então proferirás este provérbio contra o rei de babilônia, e dirás: Como já cessou o opressor, como já cessou a cidade dourada!',
        'Já quebrantou o Senhor o bastão dos ímpios e o cetro dos dominadores.',
        'Aquele que feria aos povos com furor, com golpes incessantes, e que com ira dominava sobre as nações agora é perseguido, sem que alguém o possa impedir.',
        'Já descansa, já está sossegada toda a terra; rompem cantando.',
        'Até as faias se alegram sobre ti, e os cedros do Líbano, dizendo: Desde que tu caíste ninguém sobe contra nós para nos cortar.',
        'O inferno desde o profundo se turbou por ti, para te sair ao encontro na tua vinda; despertou por ti os mortos, e todos os chefes da terra, e fez levantar dos seus tronos a todos os reis das nações.',
        'Estes todos responderão, e te dirão: Tu também adoeceste como nós, e foste semelhante a nós.',
        'Já foi derrubada na sepultura a tua soberba com o som das tuas violas; os vermes debaixo de ti se estenderão, e os bichos te cobrirão.',
        'Como caíste desde o céu, ó Lúcifer, filho da alva! Como foste cortado por terra, tu que debilitavas as nações!',
        'E tu dizias no teu coração: Eu subirei ao céu, acima das estrelas de Deus exaltarei o meu trono, e no monte da congregação me assentarei, aos lados do norte.',
        'Subirei sobre as alturas das nuvens, e serei semelhante ao Altíssimo.',
        'E contudo levado serás ao inferno, ao mais profundo do abismo.',
        'Os que te virem te contemplarão, considerar-te-ão, e dirão: É este o homem que fazia estremecer a terra e que fazia tremer os reinos?',
        'Que punha o mundo como o deserto, e assolava as suas cidades? Que não abria a casa de seus cativos?',
        'Todos os reis das nações, todos eles, jazem com honra, cada um na sua morada.',
        'Porém tu és lançado da tua sepultura, como um renovo abominável, como as vestes dos que foram mortos atravessados à espada, como os que descem ao covil de pedras, como um cadáver pisado.',
        'Com eles não te reunirás na sepultura; porque destruíste a tua terra e mataste o teu povo; a descendência dos malignos não será jamais nomeada.',
        'Preparai a matança para os seus filhos por causa da maldade de seus pais, para que não se levantem, e nem possuam a terra, e encham a face do mundo de cidades.',
        'Porque me levantarei contra eles, diz o SENHOR dos Exércitos, e extirparei de babilônia o nome, e os sobreviventes, o filho e o neto, diz o SENHOR.',
        'E farei dela uma possessão de ouriços e a lagoas de águas; e varrê-la-ei com vassoura de perdição, diz o Senhor dos Exércitos.',
        'O Senhor dos Exércitos jurou, dizendo: Como pensei, assim sucederá, e como determinei, assim se efetuará.',
        'Quebrantarei a Assíria na minha terra, e nas minhas montanhas a pisarei, para que o seu jugo se aparte deles e a sua carga se desvie dos seus ombros.',
        'Este é o propósito que foi determinado sobre toda a terra; e esta é a mão que está estendida sobre todas as nações.',
        'Porque o Senhor dos Exércitos o determinou; quem o invalidará? E a sua mão está estendida; quem pois a fará voltar atrás?',
        'No ano em que morreu o rei Acaz, foi dada esta sentença.',
        'Não te alegres, tu, toda a Filístia, por estar quebrada a vara que te feria; porque da raiz da cobra sairá um basilisco, e o seu fruto será uma serpente ardente, voadora.',
        'E os primogênitos dos pobres serão apascentados, e os necessitados se deitarão seguros; porém farei morrer de fome a tua raiz, e ele matará os teus sobreviventes.',
        'Dá uivos, ó porta, grita, ó cidade; tu, ó Filístia, estás toda derretida; porque do norte vem uma fumaça, e não haverá quem fique sozinho nas suas convocações.',
        'Que se responderá, pois, aos mensageiros da nação? Que o Senhor fundou a Sião, para que os opressos do seu povo nela encontrem refúgio.',
      ],
      [
        'Peso de Moabe. Certamente numa noite foi destruída Ar de Moabe, e foi desfeita; certamente numa noite foi destruída Quir de Moabe e foi desfeita.',
        'Vai subindo a Bajite, e a Dibom, aos lugares altos, para chorar; por Nebo e por Medeba clamará Moabe; todas as cabeças ficarão calvas, e toda a barba será rapada.',
        'Cingiram-se de sacos nas suas ruas; nos seus terraços e nas suas praças todos andam gritando, e choram abundantemente.',
        'Assim Hesbom como Eleale, andam gritando; até Jaaz se ouve a sua voz; por isso os armados de Moabe clamam; a sua alma lhes será penosa.',
        'O meu coração clama por causa de Moabe; os seus fugitivos foram até Zoar, como uma novilha de três anos; porque vão chorando pela subida de Luíte, porque no caminho de Horonaim levantam um lastimoso pranto.',
        'Porque as águas de Ninrim serão pura assolação; porque já secou o feno, acabou a erva, e não há verdura alguma.',
        'Por isso a abundância que ajuntaram, e o que guardaram, ao ribeiro dos salgueiros o levarão.',
        'Porque o pranto rodeará aos limites de Moabe; até Eglaim chegará o seu clamor, e ainda até Beer-Elim chegará o seu lamento.',
        'Porquanto as águas de Dimom estão cheias de sangue, porque ainda acrescentarei mais a Dimom; leões contra aqueles que escaparem de Moabe e contra o restante da terra.',
      ],
      [
        'Enviai o cordeiro ao governador da terra, desde Sela, no deserto, até ao monte da filha de Sião.',
        'De outro modo sucederá que serão as filhas de Moabe junto aos vaus de Arnom como o pássaro vagueante, lançado fora do ninho.',
        'Toma conselho, executa juízo, põe a tua sombra no pino do meio-dia como a noite; esconde os desterrados, e não descubras os fugitivos.',
        'Habitem contigo os meus desterrados, ó Moabe; serve-lhes de refúgio perante a face do destruidor; porque o homem violento terá fim; a destruição é desfeita, e os opressores são consumidos sobre a terra.',
        'Porque o trono se firmará em benignidade, e sobre ele no tabernáculo de Davi se assentará em verdade um que julgue, e busque o juízo, e se apresse a fazer justiça.',
        'Ouvimos da soberba de Moabe, que é soberbíssimo; da sua altivez, da sua soberba, e do seu furor; porém, as suas mentiras não serão firmes.',
        'Portanto Moabe clamará por Moabe; todos clamarão; gemereis pelos fundamentos de Quir-Haresete, pois certamente já estão abatidos.',
        'Porque os campos de Hesbom enfraqueceram, e a vinha de Sibma; os senhores dos gentios quebraram as suas melhores plantas que haviam chegado a Jazer e vagueiam no deserto; os seus rebentos se estenderam e passaram além do mar.',
        'Por isso prantearei, com o pranto de Jazer, a vinha de Sibma; regar-te-ei com as minhas lágrimas, ó Hesbom e Eleale; porque o júbilo dos teus frutos de verão e da tua sega desapareceu.',
        'E fugiu a alegria e o regozijo do campo fértil, e nas vinhas não se canta, nem há júbilo algum; já não se pisarão as uvas nos lagares. Eu fiz cessar o júbilo.',
        'Por isso o meu íntimo vibra por Moabe como harpa, e o meu interior por Quir-Heres.',
        'E será que, quando virem Moabe cansado nos altos, então entrará no seu santuário a orar, porém não prevalecerá.',
        'Esta é a palavra que o Senhor falou contra Moabe desde aquele tempo.',
        'Porém agora falou o Senhor, dizendo: Dentro de três anos (tais como os anos de jornaleiros), será envilecida a glória de Moabe, com toda a sua grande multidão; e o restante será pouco, pequeno e impotente.',
      ],
      [
        'Peso de Damasco. Eis que Damasco será tirada, e já não será cidade, antes será um montão de ruínas.',
        'As cidades de Aroer serão abandonadas; hão de ser para os rebanhos que se deitarão sem que alguém os espante.',
        'E a fortaleza de Efraim cessará, como também o reino de Damasco e o restante da Síria; serão como a glória dos filhos de Israel, diz o Senhor dos Exércitos.',
        'E naquele dia será diminuída a glória de Jacó, e a gordura da sua carne ficará emagrecida.',
        'Porque será como o segador que colhe a cana do trigo e com o seu braço sega as espigas; e será também como o que colhe espigas no vale de Refaim.',
        'Porém ainda ficarão nele alguns rabiscos, como no sacudir da oliveira: duas ou três azeitonas na mais alta ponta dos ramos, e quatro ou cinco nos seus ramos mais frutíferos, diz o Senhor Deus de Israel.',
        'Naquele dia atentará o homem para o seu Criador, e os seus olhos olharão para o Santo de Israel.',
        'E não atentará para os altares, obra das suas mãos, nem olhará para o que fizeram seus dedos, nem para os bosques, nem para as imagens.',
        'Naquele dia as suas cidades fortificadas serão como lugares abandonados, no bosque ou sobre o cume das montanhas, os quais foram abandonados ante os filhos de Israel; e haverá assolação.',
        'Porque te esqueceste do Deus da tua salvação, e não te lembraste da rocha da tua fortaleza, portanto farás plantações formosas, e assentarás nelas sarmentos estranhos.',
        'E no dia em que as plantares as farás crescer, e pela manhã farás que a tua semente brote; mas a colheita voará no dia da angústia e das dores insofríveis.',
        'Ai do bramido dos grandes povos que bramam como bramam os mares, e do rugido das nações que rugem como rugem as impetuosas águas.',
        'Rugirão as nações, como rugem as muitas águas, mas Deus as repreenderá e elas fugirão para longe; e serão afugentadas como a pragana dos montes diante do vento, e como o que rola levado pelo tufão.',
        'Ao anoitecer eis que há pavor, mas antes que amanheça já não existe; esta é a parte daqueles que nos despojam, e a sorte daqueles que nos saqueiam.',
      ],
      [
        'Ai da terra que ensombreia com as suas asas, que está além dos rios da Etiópia.',
        'Que envia embaixadores por mar em navios de junco sobre as águas, dizendo: Ide, mensageiros velozes, a um povo de elevada estatura e de pele lisa; a um povo terrível desde o seu princípio; a uma nação forte e esmagadora, cuja terra os rios dividem.',
        'Vós, todos os habitantes do mundo, e vós os moradores da terra, quando se arvorar a bandeira nos montes, o vereis; e quando se tocar a trombeta, o ouvireis.',
        'Porque assim me disse o Senhor: Estarei quieto, olhando desde a minha morada, como o ardor do sol resplandecente depois da chuva, como a nuvem do orvalho no calor da sega.',
        'Porque antes da sega, quando já o fruto está perfeito e, passada a flor, as uvas verdes amadurecerem, então, com foice podará os sarmentos e tirará os ramos e os lançará fora.',
        'Serão deixados juntos às aves dos montes e aos animais da terra; e sobre eles veranearão as aves de rapina, e todos os animais da terra invernarão sobre eles.',
        'Naquele tempo trará um presente ao Senhor dos Exércitos um povo de elevada estatura e de pele lisa, e um povo terrível desde o seu princípio; uma nação forte e esmagadora, cuja terra os rios dividem; ao lugar do nome do Senhor dos Exércitos, ao monte Sião.',
      ],
      [
        'Peso do Egito. Eis que o SENHOR vem cavalgando numa nuvem ligeira, e entrará no Egito; e os ídolos do Egito estremecerão diante dele, e o coração dos egípcios se derreterá no meio deles.',
        'Porque farei com que os egípcios, se levantem contra os egípcios, e cada um pelejará contra o seu irmão, e cada um contra o seu próximo, cidade contra cidade, reino contra reino.',
        'E o espírito do Egito se esvaecerá no seu interior, e destruirei o seu conselho; e eles consultarão aos seus ídolos, e encantadores, e aqueles que têm espíritos familiares e feiticeiros.',
        'E entregarei os egípcios nas mãos de um senhor cruel, e um rei rigoroso os dominará, diz o Senhor, o SENHOR dos Exércitos.',
        'E secarão as águas do mar, e o rio se esgotará e ressequirá.',
        'Também os rios exalarão mau cheiro e se esgotarão e secarão os canais do Egito; as canas e os juncos murcharão.',
        'A relva junto ao rio, junto às ribanceiras dos rios, e tudo o que foi semeado junto ao rio, secará, será arrancado e não subsistirá.',
        'E os pescadores gemerão, e suspirarão todos os que lançam anzol ao rio, e os que estendem rede sobre as águas desfalecerão.',
        'E envergonhar-se-ão os que trabalham em linho fino, e os que tecem pano branco.',
        'E os seus fundamentos serão despedaçados, e todos os que trabalham por salário ficarão com tristeza de alma.',
        'Na verdade são loucos os príncipes de Zoã; o conselho dos sábios conselheiros de Faraó se embruteceu; como, pois, a Faraó direis: Sou filho de sábios, filho de antigos reis?',
        'Onde estão agora os teus sábios? Notifiquem-te agora, ou informem-te sobre o que o Senhor dos Exércitos determinou contra o Egito.',
        'Loucos tornaram-se os príncipes de Zoã, enganados estão os príncipes de Nofe; eles fizeram errar o Egito, aqueles que são a pedra de esquina das suas tribos.',
        'O Senhor derramou no meio dele um perverso espírito; e eles fizeram errar o Egito em toda a sua obra, como o bêbado quando se revolve no seu vômito.',
        'E não aproveitará ao Egito obra alguma que possa fazer a cabeça, a cauda, o ramo, ou o junco.',
        'Naquele tempo os egípcios serão como mulheres, e tremerão e temerão por causa do movimento da mão do Senhor dos Exércitos, que há de levantar-se contra eles.',
        'E a terra de Judá será um espanto para o Egito; todo aquele a quem isso se anunciar se assombrará, por causa do propósito do Senhor dos Exércitos, que determinou contra eles.',
        'Naquele tempo haverá cinco cidades na terra do Egito que falarão a língua de Canaã e farão juramento ao Senhor dos Exércitos; e uma se chamará: Cidade de destruição.',
        'Naquele tempo o Senhor terá um altar no meio da terra do Egito, e uma coluna se erigirá ao Senhor, junto da sua fronteira.',
        'E servirá de sinal e de testemunho ao Senhor dos Exércitos na terra do Egito, porque ao Senhor clamarão por causa dos opressores, e ele lhes enviará um salvador e um protetor, que os livrará.',
        'E o Senhor se dará a conhecer ao Egito, e os egípcios conhecerão ao Senhor naquele dia, e o adorarão com sacrifícios e ofertas, e farão votos ao Senhor, e os cumprirão.',
        'E ferirá o Senhor ao Egito, ferirá e o curará; e converter-se-ão ao Senhor, e mover-se-á às suas orações, e os curará;',
        'Naquele dia haverá estrada do Egito até à Assíria, e os assírios virão ao Egito, e os egípcios irão à Assíria; e os egípcios servirão com os assírios.',
        'Naquele dia Israel será o terceiro com os egípcios e os assírios, uma bênção no meio da terra.',
        'Porque o Senhor dos Exércitos os abençoará, dizendo: Bendito seja o Egito, meu povo, e a Assíria, obra de minhas mãos, e Israel, minha herança.',
      ],
      [
        'No ano em que Tartã, enviado por Sargom, rei da Assíria, veio a Asdode, e guerreou contra ela, e a tomou,',
        'Nesse mesmo tempo falou o Senhor por intermédio de Isaías, filho de Amós, dizendo: Vai, solta o cilício de teus lombos, e descalça os sapatos dos teus pés. E ele assim o fez, indo nu e descalço.',
        'Então disse o Senhor: Assim como o meu servo Isaías andou três anos nu e descalço, por sinal e prodígio sobre o Egito e sobre a Etiópia,',
        'Assim o rei da Assíria levará em cativeiro os presos do Egito, e os exilados da Etiópia, tanto moços como velhos, nus e descalços, e com as nádegas descobertas, para vergonha do Egito.',
        'E assombrar-se-ão, e envergonhar-se-ão, por causa dos etíopes, sua esperança, como também dos egípcios, sua glória.',
        'Então os moradores desta ilha dirão naquele dia: Vede que tal é a nossa esperança, à qual fugimos por socorro, para nos livrarmos da face do rei da Assíria! Como pois escaparemos nós?',
      ],
      [
        'Peso do deserto do mar. Como os tufões de vento do sul, que tudo assolam, ele virá do deserto, de uma terra horrível.',
        'Dura visão me foi anunciada: o pérfido trata perfidamente, e o destruidor anda destruindo. Sobe, ó Elão, sitia, ó Média, que já fiz cessar todo o seu gemido.',
        'Por isso os meus lombos estão cheios de angústia; dores se apoderam de mim como as dores daquela que dá à luz; fiquei abatido quando ouvi, e desanimado vendo isso.',
        'O meu coração se agita, o horror apavora-me; a noite que desejava, se me tornou em temor.',
        'Põem-se a mesa, estão de atalaia, comem, bebem; levantai-vos, príncipes, e untai o escudo.',
        'Porque assim me disse o Senhor: Vai, põe uma sentinela, e ela que diga o que vir.',
        'E quando vir um carro com um par de cavaleiros, um carro com jumentos, e um carro com camelos, ela que observe atentamente com grande cuidado.',
        'E clamou: Um leão, meu Senhor! Sobre a torre de vigia estou em pé continuamente de dia, e de guarda me ponho noites inteiras.',
        'E eis agora vem um carro com homens, e um par de cavaleiros. Então respondeu e disse: Caída é babilônia, caída é! E todas as imagens de escultura dos seus deuses quebraram-se no chão.',
        'Ah, malhada minha, e trigo da minha eira! O que ouvi do Senhor dos Exércitos, Deus de Israel, isso vos anunciei.',
        'Peso de Dumá. Gritam-me de Seir: Guarda, que houve de noite? Guarda, que houve de noite?',
        'E disse o guarda: Vem a manhã, e também a noite; se quereis perguntar, perguntai; voltai, vinde.',
        'Peso contra Arábia. Nos bosques da Arábia passareis a noite, ó viandantes de Dedanim.',
        'Saí com água ao encontro dos sedentos; moradores da terra de Tema, saí com pão ao encontro dos fugitivos.',
        'Porque fogem de diante das espadas, de diante da espada desembainhada, e de diante do arco armado, e de diante do peso da guerra.',
        'Porque assim me disse o Senhor: Dentro de um ano, como os anos de jornaleiro, desaparecerá toda a glória de Quedar.',
        'E os restantes do número dos flecheiros, os poderosos dos filhos de Quedar, serão diminuídos, porque assim disse o Senhor Deus de Israel.',
      ],
      [
        'Peso do vale da visão. Que tens agora, pois que com todos os teus subiste aos telhados?',
        'Tu, cheia de clamores, cidade turbulenta, cidade alegre, os teus mortos não foram mortos à espada, nem morreram na guerra.',
        'Todos os teus governadores juntamente fugiram, foram atados pelos arqueiros; todos os que em ti se acharam, foram amarrados juntamente, e fugiram para longe.',
        'Portanto digo: Desviai de mim a vista, e chorarei amargamente; não vos canseis mais em consolar-me pela destruição da filha do meu povo.',
        'Porque dia de alvoroço, e de atropelamento, e de confusão é este da parte do Senhor DEUS dos Exércitos, no vale da visão; dia de derrubar o muro e de clamar até aos montes.',
        'Porque Elão tomou a aljava, juntamente com carros de homens e cavaleiros; e Quir descobriu os escudos.',
        'E os teus mais formosos vales se encherão de carros, e os cavaleiros se colocarão em ordem às portas.',
        'E ele tirou a coberta de Judá, e naquele dia olhaste para as armas da casa do bosque.',
        'E vistes as brechas da cidade de Davi, porquanto já eram muitas, e ajuntastes as águas do tanque de baixo.',
        'Também contastes as casas de Jerusalém, e derrubastes as casas, para fortalecer os muros.',
        'Fizestes também um reservatório entre os dois muros para as águas do tanque velho, porém não olhastes acima, para aquele que isto tinha feito, nem considerastes o que o formou desde a antiguidade.',
        'E o Senhor DEUS dos Exércitos, chamou naquele dia para chorar e para prantear, e para raspar a cabeça, e cingir com o cilício.',
        'Porém eis aqui gozo e alegria, matam-se bois e degolam-se ovelhas, come-se carne, e bebe-se vinho, e diz-se: Comamos e bebamos, porque amanhã morreremos.',
        'Mas o SENHOR dos Exércitos revelou-se aos meus ouvidos, dizendo: Certamente esta maldade não vos será expiada até que morrais, diz o Senhor DEUS dos Exércitos.',
        'Assim diz o Senhor DEUS dos Exércitos: Anda e vai ter com este tesoureiro, com Sebna, o mordomo, e dize-lhe:',
        'Que é que tens aqui, ou a quem tens tu aqui, para que cavasses aqui uma sepultura? Cavando em lugar alto a sua sepultura, e cinzelando na rocha uma morada para ti mesmo?',
        'Eis que o Senhor te arrojará violentamente como um homem forte, e de todo te envolverá.',
        'Certamente com violência te fará rolar, como se faz rolar uma bola num país espaçoso; ali morrerás, e ali acabarão os carros da tua glória, ó opróbrio da casa do teu senhor.',
        'E demitir-te-ei do teu posto, e te arrancarei do teu assento.',
        'E será naquele dia que chamarei a meu servo Eliaquim, filho de Hilquias;',
        'E vesti-lo-ei da tua túnica, e cingi-lo-ei com o teu cinto, e entregarei nas suas mãos o teu domínio, e será como pai para os moradores de Jerusalém, e para a casa de Judá.',
        'E porei a chave da casa de Davi sobre o seu ombro, e abrirá, e ninguém fechará; e fechará, e ninguém abrirá.',
        'E fixá-lo-ei como a um prego num lugar firme, e será como um trono de honra para a casa de seu pai.',
        'E nele pendurarão toda a honra da casa de seu pai, a prole e os descendentes, como também todos os vasos menores, desde as taças até os frascos.',
        'Naquele dia, diz o Senhor dos Exércitos, o prego fincado em lugar firme será tirado; e será cortado, e cairá, e a carga que nele estava se desprenderá, porque o Senhor o disse.',
      ],
      [
        'Peso de Tiro. Uivai, navios de Társis, porque está assolada, a ponto de não haver nela casa nenhuma, e de ninguém mais entrar nela; desde a terra de Quitim lhes foi isto revelado.',
        'Calai-vos, moradores da ilha, vós a quem encheram os mercadores de Sidom, navegando pelo mar.',
        'E a sua provisão era a semente de Sior, que vinha com as muitas águas, a ceifa do Nilo, e ela era a feira das nações.',
        'Envergonha-te, ó Sidom, porque o mar, a fortaleza do mar, fala, dizendo: Eu não tive dores de parto, nem dei à luz, nem ainda criei jovens, nem eduquei virgens.',
        'Como quando se ouviram as novas do Egito, assim haverá dores quando se ouvirem as de Tiro.',
        'Passai a Társis; clamai, moradores da ilha.',
        'É esta, porventura, a vossa cidade exultante, cuja origem é dos dias antigos, cujos pés a levaram para longe a peregrinar?',
        'Quem formou este desígnio contra Tiro, distribuidora de coroas, cujos mercadores são príncipes e cujos negociantes são os mais nobres da terra?',
        'O Senhor dos Exércitos formou este desígnio para denegrir a soberba de toda a glória, e envilecer os mais nobres da terra.',
        'Passa como o Nilo pela tua terra, ó filha de Társis; já não há quem te restrinja.',
        'Ele estendeu a sua mão sobre o mar, e turbou os reinos; o Senhor deu ordens contra Canaã, para que se destruíssem as suas fortalezas.',
        'E disse: Nunca mais exultarás de alegria, ó oprimida virgem, filha de Sidom; levanta-te, passa a Quitim, e ainda ali não terás descanso.',
        'Vede a terra dos caldeus, ainda este povo não era povo; a Assíria a fundou para os que moravam no deserto; levantaram as suas fortalezas, e edificaram os seus palácios; porém converteu-a em ruína.',
        'Uivai, navios de Társis, porque está destruída a vossa fortaleza.',
        'Naquele dia Tiro será posta em esquecimento por setenta anos, conforme os dias de um rei; porém no fim de setenta anos Tiro cantará como uma prostituta.',
        'Toma a harpa, rodeia a cidade, ó prostituta entregue ao esquecimento; faça doces melodias, canta muitas canções, para que haja memória de ti.',
        'Porque será no fim de setenta anos que o Senhor visitará a Tiro, e ela tornará à sua ganância de prostituta, e prostituir-se-á com todos os reinos que há sobre a face da terra.',
        'E o seu comércio e a sua ganância de prostituta serão consagrados ao Senhor; não se entesourará, nem se fechará; mas o seu comércio será para os que habitam perante o Senhor, para que comam até se saciarem, e tenham vestimenta durável.',
      ],
      [
        'Eis que o SENHOR esvazia a terra, e a desola, e transtorna a sua superfície, e dispersa os seus moradores.',
        'E o que suceder ao povo, assim sucederá ao sacerdote; ao servo, como ao seu senhor; à serva, como à sua senhora; ao comprador, como ao vendedor; ao que empresta, como ao que toma emprestado; ao que dá usura, como ao que paga usura.',
        'De todo se esvaziará a terra, e de todo será saqueada, porque o Senhor pronunciou esta palavra.',
        'A terra pranteia e se murcha; o mundo enfraquece e se murcha; enfraquecem os mais altos do povo da terra.',
        'Na verdade a terra está contaminada por causa dos seus moradores; porquanto têm transgredido as leis, mudado os estatutos, e quebrado a aliança eterna.',
        'Por isso a maldição tem consumido a terra; e os que habitam nela são desolados; por isso são queimados os moradores da terra, e poucos homens restam.',
        'Pranteia o mosto, enfraquece a vide; e suspiram todos os alegres de coração.',
        'Cessa o folguedo dos tamboris, acaba o ruído dos que exultam, e cessa a alegria da harpa.',
        'Com canções não beberão vinho; a bebida forte será amarga para os que a beberem.',
        'Demolida está a cidade vazia, todas as casas fecharam, ninguém pode entrar.',
        'Há lastimoso clamor nas ruas por falta do vinho; toda a alegria se escureceu, desterrou-se o gozo da terra.',
        'Na cidade só ficou a desolação, a porta ficou reduzida a ruínas.',
        'Porque assim será no interior da terra, e no meio destes povos, como a sacudidura da oliveira, e como os rabiscos, quando está acabada a vindima.',
        'Estes alçarão a sua voz, e cantarão com alegria; e por causa da glória do Senhor exultarão desde o mar.',
        'Por isso glorificai ao Senhor no oriente, e nas ilhas do mar, ao nome do Senhor Deus de Israel.',
        'Dos confins da terra ouvimos cantar: Glória ao justo. Mas eu disse: Emagreço, emagreço, ai de mim! Os pérfidos têm tratado perfidamente; sim, os pérfidos têm tratado perfidamente.',
        'O temor, e a cova, e o laço vêm sobre ti, ó morador da terra.',
        'E será que aquele que fugir da voz de temor cairá na cova, e o que subir da cova o laço o prenderá; porque as janelas do alto estão abertas, e os fundamentos da terra tremem.',
        'De todo está quebrantada a terra, de todo está rompida a terra, e de todo é movida a terra.',
        'De todo cambaleará a terra como o ébrio, e será movida e removida como a choça de noite; e a sua transgressão se agravará sobre ela, e cairá, e nunca mais se levantará.',
        'E será que naquele dia o Senhor castigará os exércitos do alto nas alturas, e os reis da terra sobre a terra.',
        'E serão ajuntados como presos numa masmorra, e serão encerrados num cárcere; e outra vez serão castigados depois de muitos dias.',
        'E a lua se envergonhará, e o sol se confundirá quando o Senhor dos Exércitos reinar no monte Sião e em Jerusalém, e perante os seus anciãos gloriosamente.',
      ],
      [
        'Ó Senhor, tu és o meu Deus; exaltar-te-ei, e louvarei o teu nome, porque fizeste maravilhas; os teus conselhos antigos são verdade e firmeza.',
        'Porque da cidade fizeste um montão de pedras, e da cidade forte uma ruína, e do paço dos estranhos, que não seja mais cidade, e jamais se torne a edificar.',
        'Por isso te glorificará um povo poderoso, e a cidade das nações formidáveis te temerá.',
        'Porque foste a fortaleza do pobre, e a fortaleza do necessitado, na sua angústia; refúgio contra a tempestade, e sombra contra o calor; porque o sopro dos opressores é como a tempestade contra o muro.',
        'Como o calor em lugar seco, assim abaterás o ímpeto dos estranhos; como se abranda o calor pela sombra da espessa nuvem, assim o cântico dos tiranos será humilhado.',
        'E o Senhor dos Exércitos dará neste monte a todos os povos uma festa com animais gordos, uma festa de vinhos velhos, com tutanos gordos, e com vinhos velhos, bem purificados.',
        'E destruirá neste monte a face da cobertura, com que todos os povos andam cobertos, e o véu com que todas as nações se cobrem.',
        'Aniquilará a morte para sempre, e assim enxugará o Senhor DEUS as lágrimas de todos os rostos, e tirará o opróbrio do seu povo de toda a terra; porque o SENHOR o disse.',
        'E naquele dia se dirá: Eis que este é o nosso Deus, a quem aguardávamos, e ele nos salvará; este é o Senhor, a quem aguardávamos; na sua salvação gozaremos e nos alegraremos.',
        'Porque a mão do Senhor descansará neste monte; mas Moabe será trilhado debaixo dele, como se trilha a palha no monturo.',
        'E estenderá as suas mãos por entre eles, como as estende o nadador para nadar; e abaterá a sua altivez com as ciladas das suas mãos.',
        'E abaixará as altas fortalezas dos teus muros, abatê-las-á e derrubá-las-á por terra até ao pó.',
      ],
      [
        'Naquele dia se entoará este cântico na terra de Judá: Temos uma cidade forte, a que Deus pôs a salvação por muros e antemuros.',
        'Abri as portas, para que entre nelas a nação justa, que observa a verdade.',
        'Tu conservarás em paz aquele cuja mente está firme em ti; porque ele confia em ti.',
        'Confiai no SENHOR perpetuamente; porque o SENHOR DEUS é uma rocha eterna.',
        'Porque ele abate os que habitam no alto, na cidade elevada; humilha-a, humilha-a até ao chão, e derruba-a até ao pó.',
        'O pé pisá-la-á; os pés dos aflitos, e os passos dos pobres.',
        'O caminho do justo é todo plano; tu retamente pesas o andar do justo.',
        'Também no caminho dos teus juízos, Senhor, te esperamos; no teu nome e na tua memória está o desejo da nossa alma.',
        'Com minha alma te desejei de noite, e com o meu espírito, que está dentro de mim, madrugarei a buscar-te; porque, havendo os teus juízos na terra, os moradores do mundo aprendem justiça.',
        'Ainda que se mostre favor ao ímpio, nem por isso aprende a justiça; até na terra da retidão ele pratica a iniqüidade, e não atenta para a majestade do Senhor.',
        'Senhor, a tua mão está exaltada, mas nem por isso a vêem; vê-la-ão, porém, e confundir-se-ão por causa do zelo que tens do teu povo; e o fogo consumirá os teus adversários.',
        'Senhor, tu nos darás a paz, porque tu és o que fizeste em nós todas as nossas obras.',
        'Ó Senhor Deus nosso, já outros senhores têm tido domínio sobre nós; porém, por ti só, nos lembramos de teu nome.',
        'Morrendo eles, não tornarão a viver; falecendo, não ressuscitarão; por isso os visitaste e destruíste, e apagaste toda a sua memória.',
        'Tu, Senhor, aumentaste a esta nação, tu aumentaste a esta nação, fizeste-te glorioso; alargaste todos os confins da terra.',
        'Ó Senhor, na angústia te buscaram; vindo sobre eles a tua correção, derramaram a sua oração secreta.',
        'Como a mulher grávida, quando está próxima a sua hora, tem dores de parto, e dá gritos nas suas dores, assim fomos nós diante de ti, ó Senhor!',
        'Bem concebemos nós e tivemos dores de parto, porém demos à luz o vento; livramento não trouxemos à terra, nem caíram os moradores do mundo.',
        'Os teus mortos e também o meu cadáver viverão e ressuscitarão; despertai e exultai, os que habitais no pó, porque o teu orvalho será como o orvalho das ervas, e a terra lançará de si os mortos.',
        'Vai, pois, povo meu, entra nos teus quartos, e fecha as tuas portas sobre ti; esconde-te só por um momento, até que passe a ira.',
        'Porque eis que o Senhor sairá do seu lugar, para castigar os moradores da terra, por causa da sua iniqüidade, e a terra descobrirá o seu sangue, e não encobrirá mais os seu mortos.',
      ],
      [
        'Naquele dia o SENHOR castigará com a sua dura espada, grande e forte, o leviatã, serpente veloz, e o leviatã, a serpente tortuosa, e matará o dragão, que está no mar.',
        'Naquele dia haverá uma vinha de vinho tinto; cantai-lhe.',
        'Eu, o Senhor, a guardo, e cada momento a regarei; para que ninguém lhe faça dano, de noite e de dia a guardarei.',
        'Não há indignação em mim. Quem me poria sarças e espinheiros diante de mim na guerra? Eu iria contra eles e juntamente os queimaria.',
        'Ou que se apodere da minha força, e faça paz comigo; sim, que faça paz comigo.',
        'Dias virão em que Jacó lançará raízes, e florescerá e brotará Israel, e encherão de fruto a face do mundo.',
        'Feriu-o como feriu aos que o feriram? Ou matou-o, assim como matou aos que foram mortos por ele?',
        'Com medida contendeste com ela, quando a rejeitaste, quando a tirou com o seu vento forte, no tempo do vento leste.',
        'Por isso se expiará a iniqüidade de Jacó, e este será todo o fruto de se haver tirado seu pecado; quando ele fizer a todas as pedras do altar como pedras de cal feitas em pedaços, então os bosques e as imagens não poderão ficar em pé.',
        'Porque a cidade fortificada ficará solitária, será uma habitação rejeitada e abandonada como um deserto; ali pastarão os bezerros, e ali se deitarão, e devorarão os seus ramos.',
        'Quando os seus ramos se secarem, serão quebrados, e vindo as mulheres, os acenderão, porque este povo não é povo de entendimento, assim aquele que o fez não se compadecerá dele, e aquele que o formou não lhe mostrará nenhum favor.',
        'E será naquele dia que o Senhor debulhará seus cereais desde as correntes do rio, até ao rio do Egito; e vós, ó filhos de Israel, sereis colhidos um a um.',
        'E será naquele dia que se tocará uma grande trombeta, e os que andavam perdidos pela terra da Assíria, e os que foram desterrados para a terra do Egito, tornarão a vir, e adorarão ao Senhor no monte santo em Jerusalém.',
      ],
      [
        'Ai da coroa de soberba dos bêbados de Efraim, cujo glorioso ornamento é como a flor que cai, que está sobre a cabeça do fértil vale dos vencidos do vinho.',
        'Eis que o Senhor tem um forte e poderoso; como tempestade de saraiva, tormenta destruidora, e como tempestade de impetuosas águas que transbordam, ele, com a mão, derrubará por terra.',
        'A coroa de soberba dos bêbados de Efraim será pisada aos pés.',
        'E a flor caída do seu glorioso ornamento, que está sobre a cabeça do fértil vale, será como o fruto temporão antes do verão, que, vendo-o alguém, e tendo-o ainda na mão, o engole.',
        'Naquele dia o Senhor dos Exércitos será por coroa gloriosa, e por diadema formosa, para os restantes de seu povo.',
        'E por espírito de juízo, para o que se assenta a julgar, e por fortaleza para os que fazem recuar a peleja até à porta.',
        'Mas também estes erram por causa do vinho, e com a bebida forte se desencaminham; até o sacerdote e o profeta erram por causa da bebida forte; são absorvidos pelo vinho; desencaminham-se por causa da bebida forte; andam errados na visão e tropeçam no juízo.',
        'Porque todas as suas mesas estão cheias de vômitos e imundícia, e não há lugar limpo.',
        'A quem, pois, se ensinaria o conhecimento? E a quem se daria a entender doutrina? Ao desmamado do leite, e ao arrancado dos seios?',
        'Porque é mandamento sobre mandamento, mandamento sobre mandamento, regra sobre regra, regra sobre regra, um pouco aqui, um pouco ali.',
        'Assim por lábios gaguejantes, e por outra língua, falará a este povo.',
        'Ao qual disse: Este é o descanso, dai descanso ao cansado; e este é o refrigério; porém não quiseram ouvir.',
        'Assim, pois, a palavra do Senhor lhes será mandamento sobre mandamento, mandamento sobre mandamento, regra sobre regra, regra sobre regra, um pouco aqui, um pouco ali; para que vão, e caiam para trás, e se quebrantem e se enlacem, e sejam presos.',
        'Ouvi, pois, a palavra do Senhor, homens escarnecedores, que dominais este povo que está em Jerusalém.',
        'Porquanto dizeis: Fizemos aliança com a morte, e com o inferno fizemos acordo; quando passar o dilúvio do açoite, não chegará a nós, porque pusemos a mentira por nosso refúgio, e debaixo da falsidade nos escondemos.',
        'Portanto assim diz o Senhor DEUS: Eis que eu assentei em Sião uma pedra, uma pedra já provada, pedra preciosa de esquina, que está bem firme e fundada; aquele que crer não se apresse.',
        'E regrarei o juízo pela linha, e a justiça pelo prumo, e a saraiva varrerá o refúgio da mentira, e as águas cobrirão o esconderijo.',
        'E a vossa aliança com a morte se anulará; e o vosso acordo com o inferno não subsistirá; e, quando o dilúvio do açoite passar, então sereis por ele pisados.',
        'Desde que comece a passar, vos arrebatará, porque manhã após manhã passará, de dia e de noite; e será que somente o ouvir tal notícia causará grande turbação.',
        'Porque a cama será tão curta que ninguém se poderá estender nela; e o cobertor tão estreito que ninguém se poderá cobrir com ele.',
        'Porque o Senhor se levantará como no monte Perazim, e se irará, como no vale de Gibeão, para fazer a sua obra, a sua estranha obra, e para executar o seu ato, o seu estranho ato.',
        'Agora, pois, não mais escarneçais, para que vossos grilhões não se façam mais fortes; porque já ao Senhor DEUS dos Exércitos ouvi falar de uma destruição, e essa já está determinada sobre toda a terra.',
        'Inclinai os ouvidos, e ouvi a minha voz; atendei bem e ouvi o meu discurso.',
        'Porventura lavra todo o dia o lavrador, para semear? Ou abre e desterroa todo o dia a sua terra?',
        'Não é antes assim: quando já tem nivelado a sua superfície, então espalha nela ervilhaca, e semeia cominho; ou lança nela do melhor trigo, ou cevada escolhida, ou centeio, cada qual no seu lugar?',
        'O seu Deus o ensina, e o instrui acerca do que há de fazer.',
        'Porque a ervilhaca não se trilha com trilho, nem sobre o cominho passa roda de carro; mas com uma vara se sacode a ervilhaca, e o cominho com um pau.',
        'O trigo é esmiuçado, mas não se trilha continuamente, nem se esmiúça com as rodas do seu carro, nem se quebra com os seus cavaleiros.',
        'Até isto procede do Senhor dos Exércitos; porque é maravilhoso em conselho e grande em obra.',
      ],
      [
        'Ai de Ariel, Ariel, a cidade onde Davi acampou! Acrescentai ano a ano, e sucedam-se as festas.',
        'Contudo porei a Ariel em aperto, e haverá pranto e tristeza; e ela será para mim como Ariel.',
        'Porque te cercarei com o meu arraial, e te sitiarei com baluartes, e levantarei trincheiras contra ti.',
        'Então serás abatida, falarás de debaixo da terra, e a tua fala desde o pó sairá fraca, e será a tua voz debaixo da terra, como a de um que tem espírito familiar, e a tua fala assobiará desde o pó.',
        'E a multidão dos teus inimigos será como o pó miúdo, e a multidão dos tiranos como a pragana que passa, e num momento repentino isso acontecerá.',
        'Do Senhor dos Exércitos serás visitada com trovões, e com terremotos, e grande ruído com tufão de vento, e tempestade, e labareda de fogo consumidor.',
        'E como o sonho e uma visão de noite será a multidão de todas as nações que hão de pelejar contra Ariel, como também todos os que pelejarem contra ela e contra a sua fortaleza, e a puserem em aperto.',
        'Será também como o faminto que sonha, que está a comer, porém, acordando, sente-se vazio; ou como o sedento que sonha que está a beber, porém, acordando, eis que ainda desfalecido se acha, e a sua alma com sede; assim será toda a multidão das nações, que pelejarem contra o monte Sião.',
        'Tardai, e maravilhai-vos, folgai, e clamai; bêbados estão, mas não de vinho, andam titubeando, mas não de bebida forte.',
        'Porque o Senhor derramou sobre vós um espírito de profundo sono, e fechou os vossos olhos, vendou os profetas, e os vossos principais videntes.',
        'Por isso toda a visão vos é como as palavras de um livro selado que se dá ao que sabe ler, dizendo: Lê isto, peço-te; e ele dirá: Não posso, porque está selado.',
        'Ou dá-se o livro ao que não sabe ler, dizendo: Lê isto, peço-te; e ele dirá: Não sei ler.',
        'Porque o Senhor disse: Pois que este povo se aproxima de mim, e com a sua boca, e com os seus lábios me honra, mas o seu coração se afasta para longe de mim e o seu temor para comigo consiste só em mandamentos de homens, em que foi instruído;',
        'Portanto eis que continuarei a fazer uma obra maravilhosa no meio deste povo, uma obra maravilhosa e um assombro; porque a sabedoria dos seus sábios perecerá, e o entendimento dos seus prudentes se esconderá.',
        'Ai dos que querem esconder profundamente o seu propósito do Senhor, e fazem as suas obras às escuras, e dizem: Quem nos vê? E quem nos conhece?',
        'Vós tudo perverteis, como se o oleiro fosse igual ao barro, e a obra dissesse do seu artífice: Não me fez; e o vaso formado dissesse do seu oleiro: Nada sabe.',
        'Porventura não se converterá o Líbano, num breve momento, em campo fértil? E o campo fértil não se reputará por um bosque?',
        'E naquele dia os surdos ouvirão as palavras do livro, e dentre a escuridão e dentre as trevas os olhos dos cegos as verão.',
        'E os mansos terão gozo sobre gozo no Senhor; e os necessitados entre os homens se alegrarão no Santo de Israel.',
        'Porque o tirano é reduzido a nada, e se consome o escarnecedor, e todos os que se dão à iniqüidade são desarraigados;',
        'Os que fazem culpado ao homem por uma palavra, e armam laços ao que repreende na porta, e os que sem motivo põem de parte o justo.',
        'Portanto assim diz o Senhor, que remiu a Abraão, acerca da casa de Jacó: Jacó não será agora envergonhado, nem agora se descorará a sua face.',
        'Mas quando ele vir seus filhos, obra das minhas mãos no meio dele, santificarão o meu nome; sim, santificarão ao Santo de Jacó, e temerão ao Deus de Israel.',
        'E os errados de espírito virão a ter entendimento, e os murmuradores aprenderão doutrina.',
      ],
      [
        'Ai dos filhos rebeldes, diz o SENHOR, que tomam conselho, mas não de mim; e que se cobrem, com uma cobertura, mas não do meu espírito, para acrescentarem pecado sobre pecado;',
        'Que descem ao Egito, sem pedirem o meu conselho; para se fortificarem com a força de Faraó, e para confiarem na sombra do Egito.',
        'Porque a força de Faraó se vos tornará em vergonha, e a confiança na sombra do Egito em confusão.',
        'Porque os seus príncipes já estão em Zoã, e os seus embaixadores já chegaram a Hanes.',
        'Todos se envergonharão de um povo que de nada lhes servirá nem de ajuda, nem de proveito, porém de vergonha, e de opróbrio.',
        'Peso dos animais do sul. Para a terra de aflição e de angústia (de onde vêm a leoa e o leão, a víbora, e a serpente ardente, voadora) levarão às costas de jumentinhos as suas riquezas, e sobre as corcovas de camelos os seus tesouros, a um povo que de nada lhes aproveitará.',
        'Porque o Egito os ajudará em vão, e para nenhum fim; por isso clamei acerca disto: No estarem quietos será a sua força.',
        'Vai, pois, agora, escreve isto numa tábua perante eles e registra-o num livro; para que fique até ao último dia, para sempre e perpetuamente.',
        'Porque este é um povo rebelde, filhos mentirosos, filhos que não querem ouvir a lei do Senhor.',
        'Que dizem aos videntes: Não vejais; e aos profetas: Não profetizeis para nós o que é reto; dizei-nos coisas aprazíveis, e vede para nós enganos.',
        'Desviai-vos do caminho, apartai-vos da vereda; fazei que o Santo de Israel cesse de estar perante nós.',
        'Por isso, assim diz o Santo de Israel: porquanto rejeitais esta palavra, e confiais na opressão e perversidade, e sobre isso vos estribais,',
        'Por isso esta maldade vos será como a brecha de um alto muro que, formando uma barriga, está prestes a cair e cuja quebra virá subitamente.',
        'E ele o quebrará como se quebra o vaso do oleiro e, quebrando-o, não se compadecerá; de modo que não se achará entre os seus pedaços um caco para tomar fogo do lar, ou tirar água da poça.',
        'Porque assim diz o Senhor DEUS, o Santo de Israel: Voltando e descansando sereis salvos; no sossego e na confiança estaria a vossa força, mas não quisestes.',
        'Mas dizeis: Não; antes sobre cavalos fugiremos; portanto fugireis; e, sobre cavalos ligeiros cavalgaremos; por isso os vossos perseguidores também serão ligeiros.',
        'Mil homens fugirão ao grito de um, e ao grito de cinco todos vós fugireis, até que sejais deixados como o mastro no cume do monte, e como a bandeira no outeiro.',
        'Por isso, o Senhor esperará, para ter misericórdia de vós; e por isso se levantará, para se compadecer de vós, porque o Senhor é um Deus de eqüidade; bem-aventurados todos os que nele esperam.',
        'Porque o povo habitará em Sião, em Jerusalém; não chorarás mais; certamente se compadecerá de ti, à voz do teu clamor e, ouvindo-a, te responderá.',
        'Bem vos dará o Senhor pão de angústia e água de aperto, mas os teus mestres nunca mais fugirão de ti, como voando com asas; antes os teus olhos verão a todos os teus mestres.',
        'E os teus ouvidos ouvirão a palavra do que está por detrás de ti, dizendo: Este é o caminho, andai nele, sem vos desviardes nem para a direita nem para a esquerda.',
        'E terás por contaminadas as coberturas de tuas esculturas de prata, e o revestimento das tuas esculturas fundidas de ouro; e as lançarás fora como um pano imundo, e dirás a cada uma delas: Fora daqui.',
        'Então te dará chuva sobre a tua semente, com que semeares a terra, como também pão da novidade da terra; e esta será fértil e cheia; naquele dia o teu gado pastará em largos pastos.',
        'E os bois e os jumentinhos, que lavram a terra, comerão grão puro, que for padejado com a pá, e cirandado com a ciranda.',
        'E em todo o monte alto, e em todo o outeiro levantado, haverá ribeiros e correntes de águas, no dia da grande matança, quando caírem as torres.',
        'E a luz da lua será como a luz do sol, e a luz do sol sete vezes maior, como a luz de sete dias, no dia em que o Senhor ligar a quebradura do seu povo, e curar a chaga da sua ferida.',
        'Eis que o nome do Senhor vem de longe, ardendo a sua ira, sendo pesada a sua carga; os seus lábios estão cheios de indignação, e a sua língua é como um fogo consumidor.',
        'E a sua respiração como o ribeiro transbordante, que chega até ao pescoço, para peneirar as nações com peneira de destruição, e um freio de fazer errar nas queixadas dos povos.',
        'Um cântico haverá entre vós, como na noite em que se celebra uma festa santa; e alegria de coração, como a daquele que vai com flauta, para entrar no monte do Senhor, à Rocha de Israel.',
        'E o Senhor fará ouvir a sua voz majestosa e fará ver o abaixamento do seu braço, com indignação de ira, e labareda de fogo consumidor, raios e dilúvio e pedras de saraiva.',
        'Porque com a voz do Senhor será desfeita em pedaços a Assíria, que feriu com a vara.',
        'E a cada pancada do bordão do juízo que o Senhor lhe der, haverá tamboris e harpas; e com combates de agitação combaterá contra eles.',
        'Porque Tofete já há muito está preparada; sim, está preparada para o rei; ele a fez profunda e larga; a sua pira é de fogo, e tem muita lenha; o assopro do Senhor como torrente de enxofre a acenderá.',
      ],
      [
        'Ai dos que descem ao Egito a buscar socorro, e se estribam em cavalos; e têm confiança em carros, porque são muitos; e nos cavaleiros, porque são poderosíssimos; e não atentam para o Santo de Israel, e não buscam ao SENHOR.',
        'Todavia também ele é sábio, e fará vir o mal, e não retirará as suas palavras; e levantar-se-á contra a casa dos malfeitores, e contra a ajuda dos que praticam a iniqüidade.',
        'Porque os egípcios são homens, e não Deus; e os seus cavalos, carne, e não espírito; e quando o Senhor estender a sua mão, tanto tropeçará o auxiliador, como cairá o ajudado, e todos juntamente serão consumidos.',
        'Porque assim me disse o Senhor: Como o leão e o leãozinho rugem sobre a sua presa, ainda que se convoque contra ele uma multidão de pastores, não se espantam das suas vozes, nem se abatem pela sua multidão, assim o Senhor dos Exércitos descerá, para pelejar sobre o monte Sião, e sobre o seu outeiro.',
        'Como as aves voam, assim o Senhor dos Exércitos amparará a Jerusalém; ele a amparará, a livrará e, passando, a salvará.',
        'Convertei-vos, pois, àquele contra quem os filhos de Israel se rebelaram tão profundamente.',
        'Porque naquele dia cada um lançará fora os seus ídolos de prata, e os seus ídolos de ouro, que vos fabricaram as vossas mãos para pecardes,',
        'E a Assíria cairá pela espada, não de poderoso homem; e a espada, não de homem desprezível, a consumirá; e fugirá perante a espada e os seus jovens serão tributários.',
        'E de medo passará a sua rocha, e os seus príncipes terão pavor da bandeira, diz o Senhor, cujo fogo está em Sião e a sua fornalha em Jerusalém.',
      ],
      [
        'Eis que reinará um rei com justiça, e dominarão os príncipes segundo o juízo.',
        'E será aquele homem como um esconderijo contra o vento, e um refúgio contra a tempestade, como ribeiros de águas em lugares secos, e como a sombra de uma grande rocha em terra sedenta.',
        'E os olhos dos que vêem não olharão para trás; e os ouvidos dos que ouvem estarão atentos.',
        'E o coração dos imprudentes entenderá o conhecimento; e a língua dos gagos estará pronta para falar distintamente.',
        'Ao vil nunca mais se chamará liberal; e do avarento nunca mais se dirá que é generoso.',
        'Porque o vil fala obscenidade, e o seu coração pratica a iniqüidade, para usar hipocrisia, e para proferir mentiras contra o Senhor, para deixar vazia a alma do faminto, e fazer com que o sedento venha a ter falta de bebida.',
        'Também todas as armas do avarento são más; ele maquina invenções malignas, para destruir os mansos com palavras falsas, mesmo quando o pobre chega a falar retamente.',
        'Mas o liberal projeta coisas liberais, e pela liberalidade está em pé.',
        'Levantai-vos, mulheres, que estais sossegadas, e ouvi a minha voz; e vós, filhas, que estais tão seguras, inclinai os ouvidos às minhas palavras.',
        'Porque num ano e dias vireis a ser turbadas, ó mulheres que estais tão seguras; porque a vindima se acabará, e a colheita não virá.',
        'Tremei, mulheres que estais sossegadas, e turbai-vos vós, que estais tão seguras; despi-vos, e ponde-vos nuas, e cingi com saco os vossos lombos.',
        'Baterão nos peitos, pelos campos desejáveis, e pelas vinhas frutíferas.',
        'Sobre a terra do meu povo virão espinheiros e sarças, como também sobre todas as casas onde há alegria, na cidade jubilosa.',
        'Porque os palácios serão abandonados, a multidão da cidade cessará; e as fortificações e as torres servirão de cavernas para sempre, para alegria dos jumentos monteses, e para pasto dos rebanhos;',
        'Até que se derrame sobre nós o espírito lá do alto; então o deserto se tornará em campo fértil, e o campo fértil será reputado por um bosque.',
        'E o juízo habitará no deserto, e a justiça morará no campo fértil.',
        'E o efeito da justiça será paz, e a operação da justiça, repouso e segurança para sempre.',
        'E o meu povo habitará em morada de paz, e em moradas bem seguras, e em lugares quietos de descanso.',
        'Mas, descendo ao bosque, cairá saraiva e a cidade será inteiramente abatida.',
        'Bem-aventurados vós os que semeais junto a todas as águas; e deixais livres os pés do boi e do jumento.',
      ],
      [
        'Ai de ti, despojador, que não foste despojado, e que procedes perfidamente contra os que não procederam perfidamente contra ti! Acabando tu de despojar, serás despojado; e, acabando tu de tratar perfidamente, perfidamente te tratarão.',
        'Senhor, tem misericórdia de nós, por ti temos esperado; sê tu o nosso braço cada manhã, como também a nossa salvação no tempo da tribulação.',
        'Ao ruído do tumulto fugirão os povos; à tua exaltação as nações serão dispersas.',
        'Então ajuntar-se-á o vosso despojo como se ajunta a lagarta; como os gafanhotos saltam, assim ele saltará sobre eles.',
        'O Senhor está exaltado, pois habita nas alturas; encheu a Sião de juízo e justiça.',
        'E haverá estabilidade nos teus tempos, abundância de salvação, sabedoria e conhecimento; e o temor do Senhor será o seu tesouro.',
        'Eis que os seus embaixadores estão clamando de fora; e os mensageiros de paz estão chorando amargamente.',
        'As estradas estão desoladas, cessou o que passava pela vereda, ele rompeu a aliança, desprezou as cidades, e já não faz caso dos homens.',
        'A terra geme e pranteia, o Líbano se envergonha e se murcha; Sarom se tornou como um deserto; e Basã e Carmelo foram sacudidos.',
        'Agora, pois, me levantarei, diz o Senhor; agora me erguerei. Agora serei exaltado.',
        'Concebestes palha, dareis à luz restolho; e o vosso espírito vos devorará como o fogo.',
        'E os povos serão como as queimas de cal; como espinhos cortados arderão no fogo.',
        'Ouvi, vós os que estais longe, o que tenho feito; e vós que estais vizinhos, conhecei o meu poder.',
        'Os pecadores de Sião se assombraram, o tremor surpreendeu os hipócritas. Quem dentre nós habitará com o fogo consumidor? Quem dentre nós habitará com as labaredas eternas?',
        'O que anda em justiça, e o que fala com retidão; o que rejeita o ganho da opressão, o que sacode das suas mãos todo o presente; o que tapa os seus ouvidos para não ouvir falar de derramamento de sangue e fecha os seus olhos para não ver o mal.',
        'Este habitará nas alturas; as fortalezas das rochas serão o seu alto refúgio, o seu pão lhe será dado, as suas águas serão certas.',
        'Os teus olhos verão o rei na sua formosura, e verão a terra que está longe.',
        'O teu coração considerará o assombro dizendo: Onde está o escrivão? Onde está o que pesou o tributo? Onde está o que conta as torres?',
        'Não verás mais aquele povo atrevido, povo de fala obscura, que não se pode compreender e de língua tão estranha que não se pode entender.',
        'Olha para Sião, a cidade das nossas solenidades; os teus olhos verão a Jerusalém, habitação quieta, tenda que não será removida, cujas estacas nunca serão arrancadas e das suas cordas nenhuma se quebrará.',
        'Mas ali o glorioso Senhor será para nós um lugar de rios e correntes largas; barco nenhum de remo passará por ele, nem navio grande navegará por ele.',
        'Porque o Senhor é o nosso Juiz; o Senhor é o nosso legislador; o Senhor é o nosso rei, ele nos salvará.',
        'As tuas cordas se afrouxaram; não puderam ter firme o seu mastro, e nem desfraldar a vela; então a presa de abundantes despojos se repartirá; e até os coxos dividirão a presa.',
        'E morador nenhum dirá: Enfermo estou; porque o povo que habitar nela será absolvido da iniqüidade.',
      ],
      [
        'Chegai-vos, nações, para ouvir, e vós povos, escutai; ouça a terra, e a sua plenitude, o mundo, e tudo quanto produz.',
        'Porque a indignação do Senhor está sobre todas as nações, e o seu furor sobre todo o exército delas; ele as destruiu totalmente, entregou-as à matança.',
        'E os seus mortos serão arremessados e dos seus cadáveres subirá o seu mau cheiro; e os montes se derreterão com o seu sangue.',
        'E todo o exército dos céus se dissolverá, e os céus se enrolarão como um livro; e todo o seu exército cairá, como cai a folha da vide e como cai o figo da figueira.',
        'Porque a minha espada se embriagou nos céus; eis que sobre Edom descerá, e sobre o povo do meu anátema para exercer juízo.',
        'A espada do Senhor está cheia de sangue, está engordurada da gordura do sangue de cordeiros e de bodes, da gordura dos rins de carneiros; porque o Senhor tem sacrifício em Bozra, e grande matança na terra de Edom.',
        'E os bois selvagens cairão com eles, e os bezerros com os touros; e a sua terra embriagar-se-á de sangue até se fartar, e o seu pó se engrossará com a gordura.',
        'Porque será o dia da vingança do Senhor, ano de retribuições pela contenda de Sião.',
        'E os seus ribeiros se tornarão em pez, e o seu pó em enxofre, e a sua terra em pez ardente.',
        'Nem de noite nem de dia se apagará; para sempre a sua fumaça subirá; de geração em geração será assolada; pelos séculos dos séculos ninguém passará por ela.',
        'Mas o pelicano e a coruja a possuirão, e o bufo e o corvo habitarão nela; e ele estenderá sobre ela o cordel de confusão e nível de vaidade.',
        'Eles chamarão ao reino os seus nobres, mas nenhum haverá; e todos os seus príncipes não serão coisa alguma.',
        'E nos seus palácios crescerão espinhos, urtigas e cardos nas suas fortalezas; e será uma habitação de chacais, e sítio para avestruzes.',
        'As feras do deserto se encontrarão com as feras da ilha, e o sátiro clamará ao seu companheiro; e os animais noturnos ali pousarão, e acharão lugar de repouso para si.',
        'Ali se aninhará a coruja e porá os seus ovos, e tirará os seus filhotes, e os recolherá debaixo da sua sombra; também ali os abutres se ajuntarão uns com os outros.',
        'Buscai no livro do Senhor, e lede; nenhuma destas coisas faltará, ninguém faltará com a sua companheira; porque a minha boca tem ordenado, e o seu espírito mesmo as tem ajuntado.',
        'Porque ele mesmo lançou as sortes por elas, e sua mão lhas tens repartido com o cordel; para sempre a possuirão, de geração em geração habitarão nela.',
      ],
      [
        'O deserto e o lugar solitário se alegrarão disto; e o ermo exultará e florescerá como a rosa.',
        'Abundantemente florescerá, e também jubilará de alegria e cantará; a glória do Líbano se lhe deu, a excelência do Carmelo e Sarom; eles verão a glória do Senhor, o esplendor do nosso Deus.',
        'Fortalecei as mãos fracas, e firmai os joelhos trementes.',
        'Dizei aos turbados de coração: Sede fortes, não temais; eis que o vosso Deus virá com vingança, com recompensa de Deus; ele virá, e vos salvará.',
        'Então os olhos dos cegos serão abertos, e os ouvidos dos surdos se abrirão.',
        'Então os coxos saltarão como cervos, e a língua dos mudos cantará; porque águas arrebentarão no deserto e ribeiros no ermo.',
        'E a terra seca se tornará em lagos, e a terra sedenta em mananciais de águas; e nas habitações em que jaziam os chacais haverá erva com canas e juncos.',
        'E ali haverá uma estrada, um caminho, que se chamará o caminho santo; o imundo não passará por ele, mas será para aqueles; os caminhantes, até mesmo os loucos, não errarão.',
        'Ali não haverá leão, nem animal feroz subirá a ele, nem se achará nele; porém só os remidos andarão por ele.',
        'E os resgatados do Senhor voltarão; e virão a Sião com júbilo, e alegria eterna haverá sobre as suas cabeças; gozo e alegria alcançarão, e deles fugirá a tristeza e o gemido.',
      ],
      [
        'E aconteceu no ano décimo quarto do rei Ezequias, que Senaqueribe, rei da Assíria, subiu contra todas as cidades fortificadas de Judá, e as tomou.',
        'Então o rei da Assíria enviou a Rabsaqué, de Laquis a Jerusalém, ao rei Ezequias com um grande exército, e ele parou junto ao aqueduto do açude superior, junto ao caminho do campo do lavandeiro.',
        'Então saíram a ter com ele Eliaquim, filho de Hilquias, o mordomo, e Sebna, o escrivão, e Joá, filho de Asafe, o cronista.',
        'E Rabsaqué lhes disse: Ora dizei a Ezequias: Assim diz o grande rei, o rei da Assíria: Que confiança é esta, em que esperas?',
        'Bem posso eu dizer: Teu conselho e poder para a guerra são apenas vãs palavras; em quem, pois, agora confias, que contra mim te rebelas?',
        'Eis que confias no Egito, aquele bordão de cana quebrada, o qual, se alguém se apoiar nele lhe entrará pela mão, e a furará; assim é Faraó, rei do Egito, para com todos os que nele confiam.',
        'Porém se me disseres: No Senhor, nosso Deus, confiamos; porventura não é este aquele cujos altos e altares Ezequias tirou, e disse a Judá e a Jerusalém: Perante este altar adorareis?',
        'Ora, pois, empenha-te com meu senhor, o rei da Assíria, e dar-te-ei dois mil cavalos, se tu puderes dar cavaleiros para eles.',
        'Como, pois, poderás repelir a um só capitão dos menores servos do meu senhor, quando confias no Egito, por causa dos carros e cavaleiros?',
        'Agora, pois, subi eu sem o Senhor contra esta terra, para destruí-la? O Senhor mesmo me disse: Sobe contra esta terra, e destrói-a.',
        'Então disseram Eliaquim, Sebna e Joá a Rabsaqué: Pedimos-te que fales aos teus servos em siríaco, porque bem o entendemos, e não nos fales em judaico, aos ouvidos do povo que está sobre o muro.',
        'Rabsaqué, porém, disse: Porventura mandou-me o meu senhor ao teu senhor e a ti, para dizer estas palavras e não antes aos homens que estão assentados sobre o muro, para que comam convosco o seu esterco, e bebam a sua urina?',
        'Rabsaqué, pois, se pôs em pé, e clamou em alta voz em judaico, e disse: Ouvi as palavras do grande rei, do rei da Assíria.',
        'Assim diz o rei: Não vos engane Ezequias; porque não vos poderá livrar.',
        'Nem tampouco Ezequias vos faça confiar no Senhor, dizendo: Infalivelmente nos livrará o Senhor, e esta cidade não será entregue nas mãos do rei da Assíria.',
        'Não deis ouvidos a Ezequias; porque assim diz o rei da Assíria: Aliai-vos comigo, e saí a mim, e coma cada um da sua vide, e da sua figueira, e beba cada um da água da sua cisterna;',
        'Até que eu venha, e vos leve para uma terra como a vossa; terra de trigo e de mosto, terra de pão e de vinhas.',
        'Não vos engane Ezequias, dizendo: O Senhor nos livrará. Porventura os deuses das nações livraram cada um a sua terra das mãos do rei da Assíria?',
        'Onde estão os deuses de Hamate e de Arpade? Onde estão os deuses de Sefarvaim? Porventura livraram a Samaria da minha mão?',
        'Quais dentre todos os deuses destes países livraram a sua terra das minhas mãos, para que o Senhor livrasse a Jerusalém das minhas mãos?',
        'Eles, porém, se calaram, e não lhe responderam palavra alguma; porque havia mandado do rei, dizendo: Não lhe respondereis.',
        'Então Eliaquim, filho de Hilquias, o mordomo, e Sebna, o escrivão, e Joá, filho de Asafe, o cronista, vieram a Ezequias, com as vestes rasgadas, e lhe fizeram saber as palavras de Rabsaqué.',
      ],
      [
        'E aconteceu que, tendo ouvido isso, o rei Ezequias rasgou as suas vestes, e se cobriu de saco, e entrou na casa do SENHOR.',
        'Então enviou Eliaquim, o mordomo, e Sebna, o escrivão, e os anciãos dos sacerdotes, cobertos de sacos, ao profeta Isaías, filho de Amós.',
        'E disseram-lhe: Assim diz Ezequias: Este dia é dia de angústia, e de vitupério, e de blasfêmias; porque chegados são os filhos ao parto, e força não há para dá-los à luz.',
        'Porventura o SENHOR teu Deus terá ouvido as palavras de Rabsaqué, a quem o rei da Assíria, seu senhor, enviou para afrontar o Deus vivo, e para vituperá-lo com as palavras que o SENHOR teu Deus tem ouvido; faze oração pelo remanescente que ficou.',
        'E os servos do rei Ezequias foram ter com Isaías.',
        'E Isaías lhes disse: Assim direis a vosso senhor: Assim diz o SENHOR: Não temas à vista das palavras que ouviste, com as quais os servos do rei da Assíria me blasfemaram.',
        'Eis que porei nele um espírito, e ele ouvirá um rumor, e voltará para a sua terra; e fá-lo-ei cair morto à espada na sua terra.',
        'Voltou, pois, Rabsaqué, e achou ao rei da Assíria pelejando contra Libna; porque ouvira que já se havia retirado de Laquis.',
        'E, ouviu ele dizer que Tiraca, rei da Etiópia, tinha saído para lhe fazer guerra. Assim que ouviu isto, enviou mensageiros a Ezequias, dizendo:',
        'Assim falareis a Ezequias, rei de Judá, dizendo: Não te engane o teu Deus, em quem confias, dizendo: Jerusalém não será entregue na mão do rei da Assíria.',
        'Eis que já tens ouvido o que fizeram os reis da Assíria a todas as terras, destruindo-as totalmente; e escaparias tu?',
        'Porventura as livraram os deuses das nações que meus pais destruíram: Gozã, e Harã, e Rezefe, e os filhos de Éden, que estavam em Telassar?',
        'Onde está o rei de Hamate, e o rei de Arpade, e o rei da cidade de Sefarvaim, Hena e Iva?',
        'Recebendo, pois, Ezequias as cartas das mãos dos mensageiros, e lendo-as, subiu à casa do Senhor; e Ezequias as estendeu perante o Senhor.',
        'E orou Ezequias ao Senhor, dizendo:',
        'Ó Senhor dos Exércitos, Deus de Israel, que habitas entre os querubins; tu mesmo, só tu és Deus de todos os reinos da terra; tu fizeste os céus e a terra.',
        'Inclina, ó Senhor, o teu ouvido, e ouve; abre, Senhor, os teus olhos, e vê; e ouve todas as palavras de Senaqueribe, as quais ele enviou para afrontar o Deus vivo.',
        'Verdade é, Senhor, que os reis da Assíria assolaram todas as nações e suas terras.',
        'E lançaram no fogo os seus deuses; porque deuses não eram, senão obra de mãos de homens, madeira e pedra; por isso os destruíram.',
        'Agora, pois, ó Senhor nosso Deus, livra-nos da sua mão; e assim saberão todos os reinos da terra, que só tu és o Senhor.',
        'Então Isaías, filho de Amós, mandou dizer a Ezequias: Assim diz o Senhor Deus de Israel: Quanto ao que pediste acerca de Senaqueribe, rei da Assíria,',
        'Esta é a palavra que o Senhor falou a respeito dele: A virgem, a filha de Sião, te despreza, de ti zomba; a filha de Jerusalém meneia a cabeça por detrás de ti.',
        'A quem afrontaste e blasfemaste? E contra quem alçaste a voz, e ergueste os teus olhos ao alto? Contra o Santo de Israel.',
        'Por meio de teus servos afrontaste o Senhor, e disseste: Com a multidão dos meus carros subi eu aos cumes dos montes, aos últimos recessos do Líbano; e cortarei os seus altos cedros e as suas faias escolhidas, e entrarei na altura do seu cume, ao bosque do seu campo fértil.',
        'Eu cavei, e bebi as águas; e com as plantas de meus pés sequei todos os rios dos lugares sitiados.',
        'Porventura não ouviste que já há muito tempo eu fiz isto, e já desde os dias antigos o tinha formado? Agora porém o fiz vir, para que tu fosses o que destruísse as cidades fortificadas, e as reduzisse a montões de ruínas.',
        'Por isso os seus moradores, dispondo de pouca força, andaram atemorizados e envergonhados; tornaram-se como a erva do campo, e a relva verde, e o feno dos telhados, e o trigo queimado antes da seara.',
        'Porém eu conheço o teu assentar, e o teu sair, e o teu entrar, e o teu furor contra mim.',
        'Por causa do teu furor contra mim, e porque a tua arrogância subiu até aos meus ouvidos, portanto porei o meu anzol no teu nariz e o meu freio nos teus lábios, e te farei voltar pelo caminho por onde vieste.',
        'E isto te será por sinal: Este ano se comerá o que espontaneamente nascer, e no segundo ano o que daí proceder; porém no terceiro ano semeai e segai, e plantai vinhas, e comei os frutos delas.',
        'Porque o que escapou da casa de Judá, e restou, tornará a lançar raízes para baixo, e dará fruto para cima.',
        'Porque de Jerusalém sairá o restante, e do monte de Sião os que escaparem; o zelo do Senhor dos Exércitos fará isto.',
        'Portanto, assim diz o Senhor acerca do rei da Assíria: Não entrará nesta cidade, nem lançará nela flecha alguma; tampouco virá perante ela com escudo, ou levantará trincheira contra ela.',
        'Pelo caminho por onde vier, por esse voltará; porém nesta cidade não entrará, diz o Senhor.',
        'Porque eu ampararei esta cidade, para livrá-la, por amor de mim e por amor do meu servo Davi.',
        'Então saiu o anjo do Senhor, e feriu no arraial dos assírios a cento e oitenta e cinco mil deles; e, quando se levantaram pela manhã cedo, eis que todos estes eram corpos mortos.',
        'Assim Senaqueribe, rei da Assíria, se retirou, e se foi, e voltou, e habitou em Nínive.',
        'E sucedeu que, estando ele prostrado na casa de Nisroque, seu deus, Adrameleque e Sarezer, seus filhos, o feriram à espada; escaparam para a terra de Ararate; e Esar-Hadom, seu filho, reinou em seu lugar.',
      ],
      [
        'Naqueles dias Ezequias adoeceu de uma enfermidade mortal; e veio a ele o profeta Isaías, filho de Amós, e lhe disse: Assim diz o SENHOR: Põe em ordem a tua casa, porque morrerás, e não viverás.',
        'Então virou Ezequias o seu rosto para a parede, e orou ao Senhor.',
        'E disse: Ah! Senhor, peço-te, lembra-te agora, de que andei diante de ti em verdade, e com coração perfeito, e fiz o que era reto aos teus olhos. E chorou Ezequias muitíssimo.',
        'Então veio a palavra do Senhor a Isaías, dizendo:',
        'Vai, e dize a Ezequias: Assim diz o Senhor, o Deus de Davi teu pai: Ouvi a tua oração, e vi as tuas lágrimas; eis que acrescentarei aos teus dias quinze anos.',
        'E livrar-te-ei das mãos do rei da Assíria, a ti, e a esta cidade, e defenderei esta cidade.',
        'E isto te será da parte do Senhor como sinal de que o Senhor cumprirá esta palavra que falou.',
        'Eis que farei retroceder dez graus a sombra lançada pelo sol declinante no relógio de Acaz. Assim retrocedeu o sol os dez graus que já tinha declinado.',
        'O escrito de Ezequias, rei de Judá, de quando adoeceu e sarou de sua enfermidade:',
        'Eu disse: No cessar de meus dias ir-me-ei às portas da sepultura; já estou privado do restante de meus anos.',
        'Disse: Não verei ao Senhor, o Senhor na terra dos viventes; jamais verei o homem com os moradores do mundo.',
        'Já o tempo da minha vida se foi, e foi arrebatada de mim, como tenda de pastor; cortei a minha vida como tecelão; ele me cortará do tear; desde a manhã até à noite me acabarás.',
        'Esperei com paciência até à madrugada; como um leão quebrou todos os meus ossos; desde a manhã até à noite me acabarás.',
        'Como o grou, ou a andorinha, assim eu chilreava, e gemia como a pomba; alçava os meus olhos ao alto; ó Senhor, ando oprimido, fica por meu fiador.',
        'Que direi? Como me prometeu, assim o fez; assim passarei mansamente por todos os meus anos, por causa da amargura da minha alma.',
        'Senhor, por estas coisas se vive, e em todas elas está a vida do meu espírito, portanto cura-me e faze-me viver.',
        'Eis que foi para a minha paz que tive grande amargura, mas a ti agradou livrar a minha alma da cova da corrupção; porque lançaste para trás das tuas costas todos os meus pecados.',
        'Porque não te louvará a sepultura, nem a morte te glorificará; nem esperarão em tua verdade os que descem à cova.',
        'O vivente, o vivente, esse te louvará, como eu hoje o faço; o pai aos filhos fará notória a tua verdade.',
        'O Senhor veio salvar-me; por isso, tangendo em meus instrumentos, nós o louvaremos todos os dias de nossa vida na casa do Senhor.',
        'E dissera Isaías: Tomem uma pasta de figos, e a ponham como emplastro sobre a chaga; e sarará.',
        'Também dissera Ezequias: Qual será o sinal de que hei de subir à casa do Senhor?',
      ],
      [
        'Naquele tempo enviou Merodaque-Baladã, filho de Baladã, rei de babilônia, cartas e um presente a Ezequias, porque tinha ouvido dizer que havia estado doente e que já tinha convalescido.',
        'E Ezequias se alegrou com eles, e lhes mostrou a casa do seu tesouro, a prata, e o ouro, e as especiarias, e os melhores ungüentos, e toda a sua casa de armas, e tudo quanto se achava nos seus tesouros; coisa nenhuma houve, nem em sua casa, nem em todo o seu domínio, que Ezequias não lhes mostrasse.',
        'Então o profeta Isaías veio ao rei Ezequias, e lhe disse: Que foi que aqueles homens disseram, e de onde vieram a ti? E disse Ezequias: De uma terra remota vieram a mim, de babilônia.',
        'E disse ele: Que foi que viram em tua casa? E disse Ezequias: Viram tudo quanto há em minha casa; coisa nenhuma há nos meus tesouros que eu deixasse de lhes mostrar.',
        'Então disse Isaías a Ezequias: Ouve a palavra do Senhor dos Exércitos:',
        'Eis que virão dias em que tudo quanto houver em tua casa, e o que entesouraram teus pais até ao dia de hoje, será levado para babilônia; não ficará coisa alguma, disse o SENHOR.',
        'E até de teus filhos, que procederem de ti, e tu gerares, tomarão, para que sejam eunucos no palácio do rei de babilônia.',
        'Então disse Ezequias a Isaías: Boa é a palavra do Senhor que disseste. Disse mais: Pois haverá paz e verdade em meus dias.',
      ],
      [
        'Consolai, consolai o meu povo, diz o vosso Deus.',
        'Falai benignamente a Jerusalém, e bradai-lhe que já a sua milícia é acabada, que a sua iniqüidade está expiada e que já recebeu em dobro da mão do Senhor, por todos os seus pecados.',
        'Voz do que clama no deserto: Preparai o caminho do Senhor; endireitai no ermo vereda a nosso Deus.',
        'Todo o vale será exaltado, e todo o monte e todo o outeiro será abatido; e o que é torcido se endireitará, e o que é áspero se aplainará.',
        'E a glória do Senhor se manifestará, e toda a carne juntamente a verá, pois a boca do Senhor o disse.',
        'Uma voz diz: Clama; e alguém disse: Que hei de clamar? Toda a carne é erva e toda a sua beleza como a flor do campo.',
        'Seca-se a erva, e cai a flor, soprando nela o Espírito do Senhor. Na verdade o povo é erva.',
        'Seca-se a erva, e cai a flor, porém a palavra de nosso Deus subsiste eternamente.',
        'Tu, ó Sião, que anuncias boas novas, sobe a um monte alto. Tu, ó Jerusalém, que anuncias boas novas, levanta a tua voz fortemente; levanta-a, não temas, e dize às cidades de Judá: Eis aqui está o vosso Deus.',
        'Eis que o Senhor DEUS virá com poder e seu braço dominará por ele; eis que o seu galardão está com ele, e o seu salário diante da sua face.',
        'Como pastor apascentará o seu rebanho; entre os seus braços recolherá os cordeirinhos, e os levará no seu regaço; as que amamentam guiará suavemente.',
        'Quem mediu na concha da sua mão as águas, e tomou a medida dos céus aos palmos, e recolheu numa medida o pó da terra e pesou os montes com peso e os outeiros em balanças?',
        'Quem guiou o Espírito do Senhor, ou como seu conselheiro o ensinou?',
        'Com quem tomou ele conselho, que lhe desse entendimento, e lhe ensinasse o caminho do juízo, e lhe ensinasse conhecimento, e lhe mostrasse o caminho do entendimento?',
        'Eis que as nações são consideradas por ele como a gota de um balde, e como o pó miúdo das balanças; eis que ele levanta as ilhas como a uma coisa pequeníssima.',
        'Nem todo o Líbano basta para o fogo, nem os seus animais bastam para holocaustos.',
        'Todas as nações são como nada perante ele; ele as considera menos do que nada e como uma coisa vã.',
        'A quem, pois, fareis semelhante a Deus, ou com que o comparareis?',
        'O artífice funde a imagem, e o ourives a cobre de ouro, e forja para ela cadeias de prata.',
        'O empobrecido, que não pode oferecer tanto, escolhe madeira que não se apodrece; artífice sábio busca, para gravar uma imagem que não se pode mover.',
        'Porventura não sabeis? Porventura não ouvis, ou desde o princípio não se vos notificou, ou não atentastes para os fundamentos da terra?',
        'Ele é o que está assentado sobre o círculo da terra, cujos moradores são para ele como gafanhotos; é ele o que estende os céus como cortina, e os desenrola como tenda, para neles habitar;',
        'O que reduz a nada os príncipes, e torna em coisa vã os juízes da terra.',
        'E mal se tem plantado, mal se tem semeado, e mal se tem arraigado na terra o seu tronco, já se secam, quando ele sopra sobre eles, e um tufão os leva como a pragana.',
        'A quem, pois, me fareis semelhante, para que eu lhe seja igual? diz o Santo.',
        'Levantai ao alto os vossos olhos, e vede quem criou estas coisas; foi aquele que faz sair o exército delas segundo o seu número; ele as chama a todas pelos seus nomes; por causa da grandeza das suas forças, e porquanto é forte em poder, nenhuma delas faltará.',
        'Por que dizes, ó Jacó, e tu falas, ó Israel: O meu caminho está encoberto ao Senhor, e o meu juízo passa despercebido ao meu Deus?',
        'Não sabes, não ouviste que o eterno Deus, o Senhor, o Criador dos fins da terra, nem se cansa nem se fatiga? É inescrutável o seu entendimento.',
        'Dá força ao cansado, e multiplica as forças ao que não tem nenhum vigor.',
        'Os jovens se cansarão e se fatigarão, e os moços certamente cairão;',
        'Mas os que esperam no Senhor renovarão as forças, subirão com asas como águias; correrão, e não se cansarão; caminharão, e não se fatigarão.',
      ],
      [
        'Calai-vos perante mim, ó ilhas, e os povos renovem as forças; cheguem-se, e então falem; cheguemo-nos juntos a juízo.',
        'Quem suscitou do oriente o justo e o chamou para o seu pé? Quem deu as nações à sua face e o fez dominar sobre reis? Ele os entregou à sua espada como o pó e como pragana arrebatada pelo vento ao seu arco.',
        'Ele os persegue e passa em paz, por uma vereda por onde os seus pés nunca tinham caminhado.',
        'Quem operou e fez isto, chamando as gerações desde o princípio? Eu o Senhor, o primeiro, e com os últimos eu mesmo.',
        'As ilhas o viram, e temeram; os fins da terra tremeram; aproximaram-se, e vieram.',
        'Um ao outro ajudou, e ao seu irmão disse: Esforça-te.',
        'E o artífice animou ao ourives, e o que alisa com o martelo ao que bate na bigorna, dizendo da coisa soldada: Boa é. Então com pregos a firma, para que não venha a mover-se.',
        'Porém tu, ó Israel, servo meu, tu Jacó, a quem elegi descendência de Abraão, meu amigo;',
        'Tu a quem tomei desde os fins da terra, e te chamei dentre os seus mais excelentes, e te disse: Tu és o meu servo, a ti escolhi e nunca te rejeitei.',
        'Não temas, porque eu sou contigo; não te assombres, porque eu sou teu Deus; eu te fortaleço, e te ajudo, e te sustento com a destra da minha justiça.',
        'Eis que, envergonhados e confundidos serão todos os que se indignaram contra ti; tornar-se-ão em nada, e os que contenderem contigo, perecerão.',
        'Buscá-los-ás, porém não os acharás; os que pelejarem contigo, tornar-se-ão em nada, e como coisa que não é nada, os que guerrearem contigo.',
        'Porque eu, o Senhor teu Deus, te tomo pela tua mão direita; e te digo: Não temas, eu te ajudo.',
        'Não temas, tu verme de Jacó, povozinho de Israel; eu te ajudo, diz o Senhor, e o teu redentor é o Santo de Israel.',
        'Eis que farei de ti um trilho novo, que tem dentes agudos; os montes trilharás e moerás; e os outeiros tornarás como a pragana.',
        'Tu os padejarás e o vento os levará, e o redemoinho os espalhará; mas tu te alegrarás no Senhor e te gloriarás no Santo de Israel.',
        'Os aflitos e necessitados buscam águas, e não há, e a sua língua se seca de sede; eu o Senhor os ouvirei, eu, o Deus de Israel não os desampararei.',
        'Abrirei rios em lugares altos, e fontes no meio dos vales; tornarei o deserto em lagos de águas, e a terra seca em mananciais de água.',
        'Plantarei no deserto o cedro, a acácia, e a murta, e a oliveira; porei no ermo juntamente a faia, o pinheiro e o álamo.',
        'Para que todos vejam, e saibam, e considerem, e juntamente entendam que a mão do Senhor fez isto, e o Santo de Israel o criou.',
        'Apresentai a vossa demanda, diz o Senhor; trazei as vossas firmes razões, diz o Rei de Jacó.',
        'Tragam e anunciem-nos as coisas que hão de acontecer; anunciai-nos as coisas passadas, para que atentemos para elas, e saibamos o fim delas; ou fazei-nos ouvir as coisas futuras.',
        'Anunciai-nos as coisas que ainda hão de vir, para que saibamos que sois deuses; ou fazei bem, ou fazei mal, para que nos assombremos, e juntamente o vejamos.',
        'Eis que sois menos do que nada e a vossa obra é menos do que nada; abominação é quem vos escolhe.',
        'Suscitei a um do norte, e ele há de vir; desde o nascimento do sol invocará o meu nome; e virá sobre os príncipes, como sobre o lodo e, como o oleiro pisa o barro, os pisará.',
        'Quem anunciou isto desde o princípio, para que o possamos saber, ou desde antes, para que digamos: Justo é? Porém não há quem anuncie, nem tampouco quem manifeste, nem tampouco quem ouça as vossas palavras.',
        'Eu sou o que primeiro direi a Sião: Eis que ali estão; e a Jerusalém darei um anunciador de boas novas.',
        'E quando olhei, não havia ninguém; nem mesmo entre estes, conselheiro algum havia a quem perguntasse ou que me respondesse palavra.',
        'Eis que todos são vaidade; as suas obras não são coisa alguma; as suas imagens de fundição são vento e confusão.',
      ],
      [
        'Eis aqui o meu servo, a quem sustenho, o meu eleito, em quem se apraz a minha alma; pus o meu espírito sobre ele; ele trará justiça aos gentios.',
        'Não clamará, não se exaltará, nem fará ouvir a sua voz na praça.',
        'A cana trilhada não quebrará, nem apagará o pavio que fumega; com verdade trará justiça.',
        'Não faltará, nem será quebrantado, até que ponha na terra a justiça; e as ilhas aguardarão a sua lei.',
        'Assim diz Deus, o Senhor, que criou os céus, e os estendeu, e espraiou a terra, e a tudo quanto produz; que dá a respiração ao povo que nela está, e o espírito aos que andam nela.',
        'Eu, o Senhor, te chamei em justiça, e te tomarei pela mão, e te guardarei, e te darei por aliança do povo, e para luz dos gentios.',
        'Para abrir os olhos dos cegos, para tirar da prisão os presos, e do cárcere os que jazem em trevas.',
        'Eu sou o Senhor; este é o meu nome; a minha glória, pois, a outrem não darei, nem o meu louvor às imagens de escultura.',
        'Eis que as primeiras coisas já se cumpriram, e as novas eu vos anuncio, e, antes que venham à luz, vo-las faço ouvir.',
        'Cantai ao Senhor um cântico novo, e o seu louvor desde a extremidade da terra; vós os que navegais pelo mar, e tudo quanto há nele; vós, ilhas, e seus habitantes.',
        'Alcem a voz o deserto e as suas cidades, com as aldeias que Quedar habita; exultem os que habitam nas rochas, e clamem do cume dos montes.',
        'Dêem a glória ao Senhor, e anunciem o seu louvor nas ilhas.',
        'O Senhor sairá como poderoso, como homem de guerra despertará o zelo; clamará, e fará grande ruído, e prevalecerá contra seus inimigos.',
        'Por muito tempo me calei; estive em silêncio, e me contive; mas agora darei gritos como a que está de parto, e a todos os assolarei e juntamente devorarei.',
        'Os montes e outeiros tornarei em deserto, e toda a sua erva farei secar, e tornarei os rios em ilhas, e as lagoas secarei.',
        'E guiarei os cegos pelo caminho que nunca conheceram, fá-los-ei caminhar pelas veredas que não conheceram; tornarei as trevas em luz perante eles, e as coisas tortas farei direitas. Estas coisas lhes farei, e nunca os desampararei.',
        'Tornarão atrás e confundir-se-ão de vergonha os que confiam em imagens de escultura, e dizem às imagens de fundição: Vós sois nossos deuses.',
        'Surdos, ouvi, e vós, cegos, olhai, para que possais ver.',
        'Quem é cego, senão o meu servo, ou surdo como o meu mensageiro, a quem envio? E quem é cego como o que é perfeito, e cego como o servo do Senhor?',
        'Tu vês muitas coisas, mas não as guardas; ainda que tenhas os ouvidos abertos, nada ouves.',
        'O Senhor se agradava dele por amor da sua justiça; engrandeceu-o pela lei, e o fez glorioso.',
        'Mas este é um povo roubado e saqueado; todos estão enlaçados em cavernas, e escondidos em cárceres; são postos por presa, e ninguém há que os livre; por despojo, e ninguém diz: Restitui.',
        'Quem há entre vós que ouça isto, que atenda e ouça o que há de ser depois?',
        'Quem entregou a Jacó por despojo, e a Israel aos roubadores? Porventura não foi o Senhor, aquele contra quem pecamos, e nos caminhos do qual não queriam andar, não dando ouvidos à sua lei?',
        'Por isso derramou sobre eles a indignação da sua ira, e a força da guerra, e lhes pôs labaredas em redor; porém nisso não atentaram; e os queimou, mas não puseram nisso o coração.',
      ],
      [
        'Mas agora, assim diz o SENHOR que te criou, ó Jacó, e que te formou, ó Israel: Não temas, porque eu te remi; chamei-te pelo teu nome, tu és meu.',
        'Quando passares pelas águas estarei contigo, e quando pelos rios, eles não te submergirão; quando passares pelo fogo, não te queimarás, nem a chama arderá em ti.',
        'Porque eu sou o Senhor teu Deus, o Santo de Israel, o teu Salvador; dei o Egito por teu resgate, a Etiópia e a Seba em teu lugar.',
        'Visto que foste precioso aos meus olhos, também foste honrado, e eu te amei, assim dei os homens por ti, e os povos pela tua vida.',
        'Não temas, pois, porque estou contigo; trarei a tua descendência desde o oriente, e te ajuntarei desde o ocidente.',
        'Direi ao norte: Dá; e ao sul: Não retenhas; trazei meus filhos de longe e minhas filhas das extremidades da terra,',
        'A todos os que são chamados pelo meu nome, e os que criei para a minha glória: eu os formei, e também eu os fiz.',
        'Trazei o povo cego, que tem olhos; e os surdos, que têm ouvidos.',
        'Todas as nações se congreguem, e os povos se reúnam; quem dentre eles pode anunciar isto, e fazer-nos ouvir as coisas antigas? Apresentem as suas testemunhas, para que se justifiquem, e se ouça, e se diga: Verdade é.',
        'Vós sois as minhas testemunhas, diz o Senhor, e meu servo, a quem escolhi; para que o saibais, e me creiais, e entendais que eu sou o mesmo, e que antes de mim deus nenhum se formou, e depois de mim nenhum haverá.',
        'Eu, eu sou o Senhor, e fora de mim não há Salvador.',
        'Eu anunciei, e eu salvei, e eu o fiz ouvir, e deus estranho não houve entre vós, pois vós sois as minhas testemunhas, diz o Senhor; eu sou Deus.',
        'Ainda antes que houvesse dia, eu sou; e ninguém há que possa fazer escapar das minhas mãos; agindo eu, quem o impedirá?',
        'Assim diz o SENHOR, vosso Redentor, o Santo de Israel: Por amor de vós enviei a babilônia, e a todos fiz descer como fugitivos, os caldeus, nos navios com que se vangloriavam.',
        'Eu sou o Senhor, vosso Santo, o Criador de Israel, vosso Rei.',
        'Assim diz o Senhor, o que preparou no mar um caminho, e nas águas impetuosas uma vereda;',
        'O que fez sair o carro e o cavalo, o exército e a força; eles juntamente se deitaram, e nunca se levantarão; estão extintos; como um pavio se apagaram.',
        'Não vos lembreis das coisas passadas, nem considereis as antigas.',
        'Eis que faço uma coisa nova, agora sairá à luz; porventura não a percebeis? Eis que porei um caminho no deserto, e rios no ermo.',
        'Os animais do campo me honrarão, os chacais, e os avestruzes; porque porei águas no deserto, e rios no ermo, para dar de beber ao meu povo, ao meu eleito.',
        'A esse povo que formei para mim; o meu louvor relatarão.',
        'Contudo tu não me invocaste a mim, ó Jacó, mas te cansaste de mim, ó Israel.',
        'Não me trouxeste o gado miúdo dos teus holocaustos, nem me honraste com os teus sacrifícios; não te fiz servir com ofertas, nem te fatiguei com incenso.',
        'Não me compraste por dinheiro cana aromática, nem com a gordura dos teus sacrifícios me satisfizeste, mas me deste trabalho com os teus pecados, e me cansaste com as tuas iniqüidades.',
        'Eu, eu mesmo, sou o que apago as tuas transgressões por amor de mim, e dos teus pecados não me lembro.',
        'Faze-me lembrar; entremos juntos em juízo; conta tu as tuas razões, para que te possas justificar.',
        'Teu primeiro pai pecou, e os teus intérpretes prevaricaram contra mim.',
        'Por isso profanei os príncipes do santuário; e entreguei Jacó ao anátema, e Israel ao opróbrio.',
      ],
      [
        'Agora, pois, ouve, ó Jacó, servo meu, e tu, ó Israel, a quem escolhi.',
        'Assim diz o Senhor que te criou e te formou desde o ventre, e que te ajudará: Não temas, ó Jacó, servo meu, e tu, Jesurum, a quem escolhi.',
        'Porque derramarei água sobre o sedento, e rios sobre a terra seca; derramarei o meu Espírito sobre a tua posteridade, e a minha bênção sobre os teus descendentes.',
        'E brotarão como a erva, como salgueiros junto aos ribeiros das águas.',
        'Este dirá: Eu sou do Senhor; e aquele se chamará do nome de Jacó; e aquele outro escreverá com a sua mão ao Senhor, e por sobrenome tomará o nome de Israel.',
        'Assim diz o Senhor, Rei de Israel, e seu Redentor, o Senhor dos Exércitos: Eu sou o primeiro, e eu sou o último, e fora de mim não há Deus.',
        'E quem proclamará como eu, e anunciará isto, e o porá em ordem perante mim, desde que ordenei um povo eterno? E anuncie-lhes as coisas vindouras, e as que ainda hão de vir.',
        'Não vos assombreis, nem temais; porventura desde então não vo-lo fiz ouvir, e não vo-lo anunciei? Porque vós sois as minhas testemunhas. Porventura há outro Deus fora de mim? Não, não há outra Rocha que eu conheça.',
        'Todos os artífices de imagens de escultura são vaidade, e as suas coisas mais desejáveis são de nenhum préstimo; e suas próprias testemunhas, nada vêem nem entendem para que sejam envergonhados.',
        'Quem forma um deus, e funde uma imagem de escultura, que é de nenhum préstimo?',
        'Eis que todos os seus companheiros ficarão confundidos, pois os mesmos artífices não passam de homens; ajuntem-se todos, e levantem-se; assombrar-se-ão, e serão juntamente confundidos.',
        'O ferreiro, com a tenaz, trabalha nas brasas, e o forma com martelos, e o lavra com a força do seu braço; ele tem fome e a sua força enfraquece, e não bebe água, e desfalece.',
        'O carpinteiro estende a régua, desenha-o com uma linha, aplaina-o com a plaina, e traça-o com o compasso; e o faz à semelhança de um homem, segundo a forma de um homem, para ficar em casa.',
        'Quando corta para si cedros, toma, também, o cipreste e o carvalho; assim escolhe dentre as árvores do bosque; planta um olmeiro, e a chuva o faz crescer.',
        'Então serve ao homem para queimar; e toma deles, e se aquenta, e os acende, e coze o pão; também faz um deus, e se prostra diante dele; também fabrica uma imagem de escultura, e ajoelha-se diante dela.',
        'Metade dele queima no fogo, com a outra metade prepara a carne para comer, assa-a e farta-se dela; também se aquenta, e diz: Ora já me aquentei, já vi o fogo.',
        'Então do resto faz um deus, uma imagem de escultura; ajoelha-se diante dela, e se inclina, e roga-lhe, e diz: Livra-me, porquanto tu és o meu deus.',
        'Nada sabem, nem entendem; porque tapou os olhos para que não vejam, e os seus corações para que não entendam.',
        'E nenhum deles cai em si, e já não têm conhecimento nem entendimento para dizer: Metade queimei no fogo, e cozi pão sobre as suas brasas, assei sobre elas carne, e a comi; e faria eu do resto uma abominação? Ajoelhar-me-ei ao que saiu de uma árvore?',
        'Apascenta-se de cinza; o seu coração enganado o desviou, de maneira que já não pode livrar a sua alma, nem dizer: Porventura não há uma mentira na minha mão direita?',
        'Lembra-te destas coisas, ó Jacó, e Israel, porquanto és meu servo; eu te formei, meu servo és, ó Israel, não me esquecerei de ti.',
        'Apaguei as tuas transgressões como a névoa, e os teus pecados como a nuvem; torna-te para mim, porque eu te remi.',
        'Cantai alegres, vós, ó céus, porque o Senhor o fez; exultai vós, as partes mais baixas da terra; vós, montes, retumbai com júbilo; também vós, bosques, e todas as suas árvores; porque o Senhor remiu a Jacó, e glorificou-se em Israel.',
        'Assim diz o Senhor, teu redentor, e que te formou desde o ventre: Eu sou o Senhor que faço tudo, que sozinho estendo os céus, e espraio a terra por mim mesmo;',
        'Que desfaço os sinais dos inventores de mentiras, e enlouqueço os adivinhos; que faço tornar atrás os sábios, e converto em loucura o conhecimento deles;',
        'Que confirmo a palavra do seu servo, e cumpro o conselho dos seus mensageiros; que digo a Jerusalém: Tu serás habitada, e às cidades de Judá: Sereis edificadas, e eu levantarei as suas ruínas;',
        'Que digo à profundeza: Seca-te, e eu secarei os teus rios.',
        'Que digo de Ciro: É meu pastor, e cumprirá tudo o que me apraz, dizendo também a Jerusalém: Tu serás edificada; e ao templo: Tu serás fundado.',
      ],
      [
        'Assim diz o SENHOR ao seu ungido, a Ciro, a quem tomo pela mão direita, para abater as nações diante de sua face, e descingir os lombos dos reis, para abrir diante dele as portas, e as portas não se fecharão.',
        'Eu irei adiante de ti, e endireitarei os caminhos tortuosos; quebrarei as portas de bronze, e despedaçarei os ferrolhos de ferro.',
        'Dar-te-ei os tesouros escondidos, e as riquezas encobertas, para que saibas que eu sou o Senhor, o Deus de Israel, que te chama pelo teu nome.',
        'Por amor de meu servo Jacó, e de Israel, meu eleito, eu te chamei pelo teu nome, pus o teu sobrenome, ainda que não me conhecesses.',
        'Eu sou o Senhor, e não há outro; fora de mim não há Deus; eu te cingirei, ainda que tu não me conheças;',
        'Para que se saiba desde o nascente do sol, e desde o poente, que fora de mim não há outro; eu sou o Senhor, e não há outro.',
        'Eu formo a luz, e crio as trevas; eu faço a paz, e crio o mal; eu, o Senhor, faço todas estas coisas.',
        'Destilai, ó céus, dessas alturas, e as nuvens chovam justiça; abra-se a terra, e produza a salvação, e ao mesmo tempo frutifique a justiça; eu, o Senhor, as criei.',
        'Ai daquele que contende com o seu Criador! o caco entre outros cacos de barro! Porventura dirá o barro ao que o formou: Que fazes? ou a tua obra: Não tens mãos?',
        'Ai daquele que diz ao pai: Que é o que geras? E à mulher: Que dás tu à luz?',
        'Assim diz o Senhor, o Santo de Israel, aquele que o formou: Perguntai-me as coisas futuras; demandai-me acerca de meus filhos, e acerca da obra das minhas mãos.',
        'Eu fiz a terra, e criei nela o homem; eu o fiz; as minhas mãos estenderam os céus, e a todos os seus exércitos dei as minhas ordens.',
        'Eu o despertei em justiça, e todos os seus caminhos endireitarei; ele edificará a minha cidade, e soltará os meus cativos, não por preço nem por presente, diz o Senhor dos Exércitos.',
        'Assim diz o SENHOR: O trabalho do Egito, e o comércio dos etíopes e dos sabeus, homens de alta estatura, passarão para ti, e serão teus; irão atrás de ti, virão em grilhões, e diante de ti se prostrarão; far-te-ão as suas súplicas, dizendo: Deveras Deus está em ti, e não há nenhum outro deus.',
        'Verdadeiramente tu és o Deus que te ocultas, o Deus de Israel, o Salvador.',
        'Envergonhar-se-ão, e também se confundirão todos; cairão juntamente na afronta os que fabricam imagens.',
        'Porém Israel é salvo pelo Senhor, com uma eterna salvação; por isso não sereis envergonhados nem confundidos em toda a eternidade.',
        'Porque assim diz o Senhor que tem criado os céus, o Deus que formou a terra, e a fez; ele a confirmou, não a criou vazia, mas a formou para que fosse habitada: Eu sou o Senhor e não há outro.',
        'Não falei em segredo, nem em lugar algum escuro da terra; não disse à descendência de Jacó: Buscai-me em vão; eu sou o Senhor, que falo a justiça, e anuncio coisas retas.',
        'Congregai-vos, e vinde; chegai-vos juntos, os que escapastes das nações; nada sabem os que conduzem em procissão as suas imagens de escultura, feitas de madeira, e rogam a um deus que não pode salvar.',
        'Anunciai, e chegai-vos, e tomai conselho todos juntos; quem fez ouvir isto desde a antiguidade? Quem desde então o anunciou? Porventura não sou eu, o Senhor? Pois não há outro Deus senão eu; Deus justo e Salvador não há além de mim.',
        'Olhai para mim, e sereis salvos, vós, todos os termos da terra; porque eu sou Deus, e não há outro.',
        'Por mim mesmo tenho jurado, já saiu da minha boca a palavra de justiça, e não tornará atrás; que diante de mim se dobrará todo o joelho, e por mim jurará toda a língua.',
        'De mim se dirá: Deveras no Senhor há justiça e força; até ele virão, mas serão envergonhados todos os que se indignarem contra ele.',
        'Mas no Senhor será justificada, e se gloriará toda a descendência de Israel.',
      ],
      [
        'Bel está abatido, Nebo se encurvou, os seus ídolos são postos sobre os animais e sobre as feras; as cargas dos vossos fardos são canseiras para as feras já cansadas.',
        'Juntamente se encurvaram e se abateram; não puderam livrar-se da carga, mas a sua alma entrou em cativeiro.',
        'Ouvi-me, ó casa de Jacó, e todo o restante da casa de Israel; vós a quem trouxe nos braços desde o ventre, e sois levados desde a madre.',
        'E até à velhice eu serei o mesmo, e ainda até às cãs eu vos carregarei; eu vos fiz, e eu vos levarei, e eu vos trarei, e vos livrarei.',
        'A quem me assemelhareis, e com quem me igualareis, e me comparareis, para que sejamos semelhantes?',
        'Gastam o ouro da bolsa, e pesam a prata nas balanças; assalariam o ourives, e ele faz um deus, e diante dele se prostram e se inclinam.',
        'Sobre os ombros o tomam, o levam, e o põem no seu lugar; ali fica em pé, do seu lugar não se move; e, se alguém clama a ele, resposta nenhuma dá, nem livra alguém da sua tribulação.',
        'Lembrai-vos disto, e considerai; trazei-o à memória, ó prevaricadores.',
        'Lembrai-vos das coisas passadas desde a antiguidade; que eu sou Deus, e não há outro Deus, não há outro semelhante a mim.',
        'Que anuncio o fim desde o princípio, e desde a antiguidade as coisas que ainda não sucederam; que digo: O meu conselho será firme, e farei toda a minha vontade.',
        'Que chamo a ave de rapina desde o oriente, e de uma terra remota o homem do meu conselho; porque assim o disse, e assim o farei vir; eu o formei, e também o farei.',
        'Ouvi-me, ó duros de coração, os que estais longe da justiça.',
        'Faço chegar a minha justiça, e não estará ao longe, e a minha salvação não tardará; mas estabelecerei em Sião a salvação, e em Israel a minha glória.',
      ],
      [
        'Desce, e assenta-te no pó, ó virgem filha de babilônia; assenta-te no chão; já não há trono, ó filha dos caldeus, porque nunca mais serás chamada a tenra nem a delicada.',
        'Toma a mó, e mói a farinha; remove o teu véu, descalça os pés, descobre as pernas e passa os rios.',
        'A tua vergonha se descobrirá, e ver-se-á o teu opróbrio; tomarei vingança, e não pouparei a homem algum.',
        'O nosso redentor cujo nome é o Senhor dos Exércitos, é o Santo de Israel.',
        'Assenta-te calada, e entra nas trevas, ó filha dos caldeus, porque nunca mais serás chamada senhora de reinos.',
        'Muito me agastei contra o meu povo, profanei a minha herança, e os entreguei na tua mão; porém não usaste com eles de misericórdia, e até sobre os velhos fizeste muito pesado o teu jugo.',
        'E disseste: Eu serei senhora para sempre; até agora não te importaste com estas coisas, nem te lembraste do fim delas.',
        'Agora, pois, ouve isto, tu que és dada a prazeres, que habitas tão segura, que dizes no teu coração: Eu o sou, e fora de mim não há outra; não ficarei viúva, nem conhecerei a perda de filhos.',
        'Porém ambas estas coisas virão sobre ti num momento, no mesmo dia, perda de filhos e viuvez; em toda a sua plenitude virão sobre ti, por causa da multidão das tuas feitiçarias, e da grande abundância dos teus muitos encantamentos.',
        'Porque confiaste na tua maldade e disseste: Ninguém me pode ver; a tua sabedoria e o teu conhecimento, isso te fez desviar, e disseste no teu coração: Eu sou, e fora de mim não há outra.',
        'Portanto sobre ti virá o mal, sem que saibas a sua origem, e tal destruição cairá sobre ti, sem que a possas evitar; e virá sobre ti de repente desolação que não poderás conhecer.',
        'Deixa-te estar com os teus encantamentos, e com a multidão das tuas feitiçarias, em que trabalhaste desde a tua mocidade, a ver se podes tirar proveito, ou se porventura te podes fortalecer.',
        'Cansaste-te na multidão dos teus conselhos; levantem-se pois agora os agoureiros dos céus, os que contemplavam os astros, os prognosticadores das luas novas, e salvem-te do que há de vir sobre ti.',
        'Eis que serão como a pragana, o fogo os queimará; não poderão salvar a sua vida do poder das chamas; não haverá brasas, para se aquentar, nem fogo para se assentar junto dele.',
        'Assim serão para contigo aqueles com quem trabalhaste, os teus negociantes desde a tua mocidade; cada qual irá vagueando pelo seu caminho; ninguém te salvará.',
      ],
      [
        'Ouvi isto, casa de Jacó, que vos chamais do nome de Israel, e saístes das águas de Judá, que jurais pelo nome do SENHOR, e fazeis menção do Deus de Israel, mas não em verdade nem em justiça.',
        'E até da santa cidade tomam o nome e se firmam sobre o Deus de Israel; o Senhor dos Exércitos é o seu nome.',
        'As primeiras coisas desde a antiguidade as anunciei; da minha boca saíram, e eu as fiz ouvir; apressuradamente as fiz, e aconteceram.',
        'Porque eu sabia que eras duro, e a tua cerviz um nervo de ferro, e a tua testa de bronze.',
        'Por isso te anunciei desde então, e te fiz ouvir antes que acontecesse, para que não dissesses: O meu ídolo fez estas coisas, e a minha imagem de escultura, e a minha imagem de fundição as mandou.',
        'Já o tens ouvido; olha bem para tudo isto; porventura não o anunciareis? Desde agora te faço ouvir coisas novas e ocultas, e que nunca conheceste.',
        'Agora são criadas, e não de há muito, e antes deste dia não as ouviste, para que porventura não digas: Eis que eu já as sabia.',
        'Nem tu as ouviste, nem tu as conheceste, nem tampouco há muito foi aberto o teu ouvido, porque eu sabia que procederias muito perfidamente, e que eras chamado transgressor desde o ventre.',
        'Por amor do meu nome retardarei a minha ira, e por amor do meu louvor me refrearei para contigo, para que te não venha a cortar.',
        'Eis que já te purifiquei, mas não como a prata; escolhi-te na fornalha da aflição.',
        'Por amor de mim, por amor de mim o farei, porque, como seria profanado o meu nome? E a minha glória não a darei a outrem.',
        'Dá-me ouvidos, ó Jacó, e tu, ó Israel, a quem chamei; eu sou o mesmo, eu o primeiro, eu também o último.',
        'Também a minha mão fundou a terra, e a minha destra mediu os céus a palmos; eu os chamarei, e aparecerão juntos.',
        'Ajuntai-vos todos vós, e ouvi: Quem, dentre eles, tem anunciado estas coisas? O SENHOR o amou, e executará a sua vontade contra babilônia, e o seu braço será contra os caldeus.',
        'Eu, eu o tenho falado; também já o chamei, e o trarei, e farei próspero o seu caminho.',
        'Chegai-vos a mim, ouvi isto: Não falei em segredo desde o princípio; desde o tempo em que aquilo se fez eu estava ali, e agora o Senhor DEUS me enviou a mim, e o seu Espírito.',
        'Assim diz o Senhor, o teu Redentor, o Santo de Israel: Eu sou o Senhor teu Deus, que te ensina o que é útil, e te guia pelo caminho em que deves andar.',
        'Ah! se tivesses dado ouvidos aos meus mandamentos, então seria a tua paz como o rio, e a tua justiça como as ondas do mar!',
        'Também a tua descendência seria como a areia, e os que procedem das tuas entranhas como os seus grãos; o seu nome nunca seria cortado nem destruído de diante de mim.',
        'Saí de babilônia, fugi de entre os caldeus. E anunciai com voz de júbilo, fazei ouvir isso, e levai-o até ao fim da terra; dizei: O SENHOR remiu a seu servo Jacó.',
        'E não tinham sede, quando os levava pelos desertos; fez-lhes correr água da rocha; fendeu a rocha, e as águas correram.',
        'Mas os ímpios não têm paz, diz o Senhor.',
      ],
      [
        'Ouvi-me, ilhas, e escutai vós, povos de longe: O SENHOR me chamou desde o ventre, desde as entranhas de minha mãe fez menção do meu nome.',
        'E fez a minha boca como uma espada aguda, com a sombra da sua mão me cobriu; e me pôs como uma flecha limpa, e me escondeu na sua aljava;',
        'E me disse: Tu és meu servo; és Israel, aquele por quem hei de ser glorificado.',
        'Porém eu disse: Debalde tenho trabalhado, inútil e vãmente gastei as minhas forças; todavia o meu direito está perante o Senhor, e o meu galardão perante o meu Deus.',
        'E agora diz o Senhor, que me formou desde o ventre para ser seu servo, para que torne a trazer Jacó; porém Israel não se deixará ajuntar; contudo aos olhos do Senhor serei glorificado, e o meu Deus será a minha força.',
        'Disse mais: Pouco é que sejas o meu servo, para restaurares as tribos de Jacó, e tornares a trazer os preservados de Israel; também te dei para luz dos gentios, para seres a minha salvação até à extremidade da terra.',
        'Assim diz o Senhor, o Redentor de Israel, o seu Santo, à alma desprezada, ao que a nação abomina, ao servo dos que dominam: Os reis o verão, e se levantarão, como também os príncipes, e eles diante de ti se inclinarão, por amor do Senhor, que é fiel, e do Santo de Israel, que te escolheu.',
        'Assim diz o Senhor: No tempo aceitável te ouvi e no dia da salvação te ajudei, e te guardarei, e te darei por aliança do povo, para restaurares a terra, e dar-lhes em herança as herdades assoladas;',
        'Para dizeres aos presos: Saí; e aos que estão em trevas: Aparecei. Eles pastarão nos caminhos, e em todos os lugares altos haverá o seu pasto.',
        'Nunca terão fome, nem sede, nem o calor, nem o sol os afligirá; porque o que se compadece deles os guiará e os levará mansamente aos mananciais das águas.',
        'E farei de todos os meus montes um caminho; e as minhas estradas serão levantadas.',
        'Eis que estes virão de longe, e eis que aqueles do norte, e do ocidente, e aqueles outros da terra de Sinim.',
        'Exultai, ó céus, e alegra-te, ó terra, e vós, montes, estalai com júbilo, porque o Senhor consolou o seu povo, e dos seus aflitos se compadecerá.',
        'Porém Sião diz: Já me desamparou o Senhor, e o meu Senhor se esqueceu de mim.',
        'Porventura pode uma mulher esquecer-se tanto de seu filho que cria, que não se compadeça dele, do filho do seu ventre? Mas ainda que esta se esquecesse dele, contudo eu não me esquecerei de ti.',
        'Eis que nas palmas das minhas mãos eu te gravei; os teus muros estão continuamente diante de mim.',
        'Os teus filhos pressurosamente virão, mas os teus destruidores e os teus assoladores sairão do meio de ti.',
        'Levanta os teus olhos ao redor, e olha; todos estes que se ajuntam vêm a ti; vivo eu, diz o Senhor, que de todos estes te vestirás, como de um ornamento, e te cingirás deles como noiva.',
        'Porque nos teus desertos, e nos teus lugares solitários, e na tua terra destruída, agora te verás apertada de moradores, e os que te devoravam se afastarão para longe de ti.',
        'E até mesmo os filhos da tua orfandade dirão aos teus ouvidos: Muito estreito é para mim este lugar; aparta-te de mim, para que possa habitar nele.',
        'E dirás no teu coração: Quem me gerou estes? Pois eu estava desfilhada e solitária; entrara em cativeiro, e me retirara; quem, pois, me criou estes? Eis que eu fui deixada sozinha; e estes onde estavam?',
        'Assim diz o Senhor DEUS: Eis que levantarei a minha mão para os gentios, e ante os povos arvorarei a minha bandeira; então trarão os teus filhos nos braços, e as tuas filhas serão levadas sobre os ombros.',
        'E os reis serão os teus aios, e as suas rainhas as tuas amas; diante de ti se inclinarão com o rosto em terra, e lamberão o pó dos teus pés; e saberás que eu sou o Senhor, que os que confiam em mim não serão confundidos.',
        'Porventura tirar-se-ia a presa ao poderoso, ou escapariam os legalmente presos?',
        'Mas assim diz o Senhor: Por certo que os presos se tirarão ao poderoso, e a presa do tirano escapará; porque eu contenderei com os que contendem contigo, e os teus filhos eu remirei.',
        'E sustentarei os teus opressores com a sua própria carne, e com o seu próprio sangue se embriagarão, como com mosto; e toda a carne saberá que eu sou o Senhor, o teu Salvador, e o teu Redentor, o Forte de Jacó.',
      ],
      [
        'Assim diz o SENHOR: Onde está a carta de divórcio de vossa mãe, pela qual eu a repudiei? Ou quem é o meu credor a quem eu vos tenha vendido? Eis que por vossas maldades fostes vendidos, e por vossas transgressões vossa mãe foi repudiada.',
        'Por que razão vim eu, e ninguém apareceu? Chamei, e ninguém respondeu? Porventura tanto se encolheu a minha mão, que já não possa remir? Ou não há mais força em mim para livrar? Eis que com a minha repreensão faço secar o mar, torno os rios em deserto, até que cheirem mal os seus peixes, porquanto não têm água e morrem de sede.',
        'Eu visto os céus de negridão, pôr-lhes-ei um saco para a sua cobertura.',
        'O Senhor DEUS me deu uma língua erudita, para que eu saiba dizer a seu tempo uma boa palavra ao que está cansado. Ele desperta-me todas as manhãs, desperta-me o ouvido para que ouça, como aqueles que aprendem.',
        'O Senhor DEUS me abriu os ouvidos, e eu não fui rebelde; não me retirei para trás.',
        'As minhas costas ofereci aos que me feriam, e a minha face aos que me arrancavam os cabelos; não escondi a minha face dos que me afrontavam e me cuspiam.',
        'Porque o Senhor DEUS me ajuda, assim não me confundo; por isso pus o meu rosto como um seixo, porque sei que não serei envergonhado.',
        'Perto está o que me justifica; quem contenderá comigo? Compareçamos juntamente; quem é meu adversário? Chegue-se para mim.',
        'Eis que o Senhor DEUS me ajuda; quem há que me condene? Eis que todos eles como roupas se envelhecerão, e a traça os comerá.',
        'Quem há entre vós que tema ao Senhor e ouça a voz do seu servo? Quando andar em trevas, e não tiver luz nenhuma, confie no nome do Senhor, e firme-se sobre o seu Deus.',
        'Eis que todos vós, que acendeis fogo, e vos cingis com faíscas, andai entre as labaredas do vosso fogo, e entre as faíscas, que acendestes. Isto vos sobrevirá da minha mão, e em tormentos jazereis.',
      ],
      [
        'Ouvi-me, vós os que seguis a justiça, os que buscais ao SENHOR. Olhai para a rocha de onde fostes cortados, e para a caverna do poço de onde fostes cavados.',
        'Olhai para Abraão, vosso pai, e para Sara, que vos deu à luz; porque, sendo ele só, o chamei, e o abençoei e o multipliquei.',
        'Porque o Senhor consolará a Sião; consolará a todos os seus lugares assolados, e fará o seu deserto como o Éden, e a sua solidão como o jardim do Senhor; gozo e alegria se achará nela, ação de graças, e voz de melodia.',
        'Atendei-me, povo meu, e nação minha, inclinai os ouvidos para mim; porque de mim sairá a lei, e o meu juízo farei repousar para a luz dos povos.',
        'Perto está a minha justiça, vem saindo a minha salvação, e os meus braços julgarão os povos; as ilhas me aguardarão, e no meu braço esperarão.',
        'Levantai os vossos olhos para os céus, e olhai para a terra em baixo, porque os céus desaparecerão como a fumaça, e a terra se envelhecerá como roupa, e os seus moradores morrerão semelhantemente; porém a minha salvação durará para sempre, e a minha justiça não será abolida.',
        'Ouvi-me, vós que conheceis a justiça, povo em cujo coração está a minha lei; não temais o opróbrio dos homens, nem vos turbeis pelas suas injúrias.',
        'Porque a traça os roerá como a roupa, e o bicho os comerá como a lã; mas a minha justiça durará para sempre, e a minha salvação de geração em geração.',
        'Desperta, desperta, veste-te de força, ó braço do Senhor; desperta como nos dias passados, como nas gerações antigas. Não és tu aquele que cortou em pedaços a Raabe, o que feriu ao chacal?',
        'Não és tu aquele que secou o mar, as águas do grande abismo? O que fez o caminho no fundo do mar, para que passassem os remidos?',
        'Assim voltarão os resgatados do Senhor, e virão a Sião com júbilo, e perpétua alegria haverá sobre as suas cabeças; gozo e alegria alcançarão, a tristeza e o gemido fugirão.',
        'Eu, eu sou aquele que vos consola; quem, pois, és tu para que temas o homem que é mortal, ou o filho do homem, que se tornará em erva?',
        'E te esqueces do Senhor que te criou, que estendeu os céus, e fundou a terra, e temes continuamente todo o dia o furor do angustiador, quando se prepara para destruir; pois onde está o furor do que te atribulava?',
        'O exilado cativo depressa será solto, e não morrerá na caverna, e o seu pão não lhe faltará.',
        'Porque eu sou o Senhor teu Deus, que agito o mar, de modo que bramem as suas ondas. O Senhor dos Exércitos é o seu nome.',
        'E ponho as minhas palavras na tua boca, e te cubro com a sombra da minha mão; para plantar os céus, e para fundar a terra, e para dizer a Sião: Tu és o meu povo.',
        'Desperta, desperta, levanta-te, ó Jerusalém, que bebeste da mão do Senhor o cálice do seu furor; bebeste e sorveste os sedimentos do cálice do atordoamento.',
        'De todos os filhos que ela teve, nenhum há que a guie mansamente; e de todos os filhos que criou, nenhum há que a tome pela mão.',
        'Estas duas coisas te aconteceram; quem terá compaixão de ti? A assolação, e o quebrantamento, e a fome, e a espada! Por quem te consolarei?',
        'Os teus filhos já desmaiaram, jazem nas entradas de todos os caminhos, como o antílope na rede; cheios estão do furor do Senhor e da repreensão do teu Deus.',
        'Portanto agora ouve isto, ó aflita, e embriagada, mas não de vinho.',
        'Assim diz o teu Senhor o Senhor, e o teu Deus, que pleiteará a causa do seu povo: Eis que eu tomo da tua mão o cálice do atordoamento, os sedimentos do cálice do meu furor, nunca mais dele beberás.',
        'Porém, pô-lo-ei nas mãos dos que te entristeceram, que disseram à tua alma: Abaixa-te, e passaremos sobre ti; e tu puseste as tuas costas como chão, e como caminho, aos viandantes.',
      ],
      [
        'Desperta, desperta, veste-te da tua fortaleza, ó Sião; veste-te das tuas roupas formosas, ó Jerusalém, cidade santa, porque nunca mais entrará em ti nem incircunciso nem imundo.',
        'Sacode-te do pó, levanta-te, e assenta-te, ó Jerusalém: solta-te das cadeias de teu pescoço, ó cativa filha de Sião.',
        'Porque assim diz o Senhor: Por nada fostes vendidos; também sem dinheiro sereis resgatados.',
        'Porque assim diz o Senhor DEUS: O meu povo em tempos passados desceu ao Egito, para peregrinar lá, e a Assíria sem razão o oprimiu.',
        'E agora, que tenho eu que fazer aqui, diz o Senhor, pois o meu povo foi tomado sem nenhuma razão? Os que dominam sobre ele dão uivos, diz o Senhor; e o meu nome é blasfemado incessantemente o dia todo.',
        'Portanto o meu povo saberá o meu nome; pois, naquele dia, saberá que sou eu mesmo o que falo: Eis-me aqui.',
        'Quão formosos são, sobre os montes, os pés do que anuncia as boas novas, que faz ouvir a paz, do que anuncia o bem, que faz ouvir a salvação, do que diz a Sião: O teu Deus reina!',
        'Eis a voz dos teus atalaias! Eles alçam a voz, juntamente exultam; porque olho a olho verão, quando o Senhor fizer Sião voltar.',
        'Clamai cantando, exultai juntamente, desertos de Jerusalém; porque o Senhor consolou o seu povo, remiu a Jerusalém.',
        'O Senhor desnudou o seu santo braço perante os olhos de todas as nações; e todos os confins da terra verão a salvação do nosso Deus.',
        'Retirai-vos, retirai-vos, saí daí, não toqueis coisa imunda; saí do meio dela, purificai-vos, os que levais os vasos do Senhor.',
        'Porque vós não saireis apressadamente, nem ireis fugindo; porque o Senhor irá diante de vós, e o Deus de Israel será a vossa retaguarda.',
        'Eis que o meu servo procederá com prudência; será exaltado, e elevado, e mui sublime.',
        'Como pasmaram muitos à vista dele, pois o seu parecer estava tão desfigurado, mais do que o de outro qualquer, e a sua figura mais do que a dos outros filhos dos homens.',
        'Assim borrifará muitas nações, e os reis fecharão as suas bocas por causa dele; porque aquilo que não lhes foi anunciado verão, e aquilo que eles não ouviram entenderão.',
      ],
      [
        'Quem deu crédito à nossa pregação? E a quem se manifestou o braço do SENHOR?',
        'Porque foi subindo como renovo perante ele, e como raiz de uma terra seca; não tinha beleza nem formosura e, olhando nós para ele, não havia boa aparência nele, para que o desejássemos.',
        'Era desprezado, e o mais rejeitado entre os homens, homem de dores, e experimentado nos trabalhos; e, como um de quem os homens escondiam o rosto, era desprezado, e não fizemos dele caso algum.',
        'Verdadeiramente ele tomou sobre si as nossas enfermidades, e as nossas dores levou sobre si; e nós o reputávamos por aflito, ferido de Deus, e oprimido.',
        'Mas ele foi ferido por causa das nossas transgressões, e moído por causa das nossas iniqüidades; o castigo que nos traz a paz estava sobre ele, e pelas suas pisaduras fomos sarados.',
        'Todos nós andávamos desgarrados como ovelhas; cada um se desviava pelo seu caminho; mas o Senhor fez cair sobre ele a iniqüidade de nós todos.',
        'Ele foi oprimido e afligido, mas não abriu a sua boca; como um cordeiro foi levado ao matadouro, e como a ovelha muda perante os seus tosquiadores, assim ele não abriu a sua boca.',
        'Da opressão e do juízo foi tirado; e quem contará o tempo da sua vida? Porquanto foi cortado da terra dos viventes; pela transgressão do meu povo ele foi atingido.',
        'E puseram a sua sepultura com os ímpios, e com o rico na sua morte; ainda que nunca cometeu injustiça, nem houve engano na sua boca.',
        'Todavia, ao Senhor agradou moê-lo, fazendo-o enfermar; quando a sua alma se puser por expiação do pecado, verá a sua posteridade, prolongará os seus dias; e o bom prazer do Senhor prosperará na sua mão.',
        'Ele verá o fruto do trabalho da sua alma, e ficará satisfeito; com o seu conhecimento o meu servo, o justo, justificará a muitos; porque as iniqüidades deles levará sobre si.',
        'Por isso lhe darei a parte de muitos, e com os poderosos repartirá ele o despojo; porquanto derramou a sua alma na morte, e foi contado com os transgressores; mas ele levou sobre si o pecado de muitos, e intercedeu pelos transgressores.',
      ],
      [
        'Canta alegremente, ó estéril, que não deste à luz; rompe em cântico, e exclama com alegria, tu que não tiveste dores de parto; porque mais são os filhos da mulher solitária, do que os filhos da casada, diz o SENHOR.',
        'Amplia o lugar da tua tenda, e estendam-se as cortinas das tuas habitações; não o impeças; alonga as tuas cordas, e fixa bem as tuas estacas.',
        'Porque transbordarás para a direita e para a esquerda; e a tua descendência possuirá os gentios e fará que sejam habitadas as cidades assoladas.',
        'Não temas, porque não serás envergonhada; e não te envergonhes, porque não serás humilhada; antes te esquecerás da vergonha da tua mocidade, e não te lembrarás mais do opróbrio da tua viuvez.',
        'Porque o teu Criador é o teu marido; o Senhor dos Exércitos é o seu nome; e o Santo de Israel é o teu Redentor; que é chamado o Deus de toda a terra.',
        'Porque o Senhor te chamou como a mulher desamparada e triste de espírito; como a mulher da mocidade, que fora desprezada, diz o teu Deus.',
        'Por um breve momento te deixei, mas com grandes misericórdias te recolherei;',
        'Com um pouco de ira escondi a minha face de ti por um momento; mas com benignidade eterna me compadecerei de ti, diz o Senhor, o teu Redentor.',
        'Porque isto será para mim como as águas de Noé; pois jurei que as águas de Noé não passariam mais sobre a terra; assim jurei que não me irarei mais contra ti, nem te repreenderei.',
        'Porque os montes se retirarão, e os outeiros serão abalados; porém a minha benignidade não se apartará de ti, e a aliança da minha paz não mudará, diz o Senhor que se compadece de ti.',
        'Tu, oprimida, arrojada com a tormenta e desconsolada, eis que eu assentarei as tuas pedras com todo o ornamento, e te fundarei sobre as safiras.',
        'E farei os teus vitrais de rubis, e as tuas portas de carbúnculos, e todos os teus termos de pedras aprazíveis.',
        'E todos os teus filhos serão ensinados do Senhor; e a paz de teus filhos será abundante.',
        'Com justiça serás estabelecida; estarás longe da opressão, porque já não temerás; e também do terror, porque não chegará a ti.',
        'Eis que seguramente poderão vir a juntar-se contra ti, mas não será por mim; quem se ajuntar contra ti cairá por causa de ti.',
        'Eis que eu criei o ferreiro, que assopra as brasas no fogo, e que produz a ferramenta para a sua obra; também criei o assolador, para destruir.',
        'Toda a ferramenta preparada contra ti não prosperará, e toda a língua que se levantar contra ti em juízo tu a condenarás; esta é a herança dos servos do Senhor, e a sua justiça que de mim procede, diz o Senhor.',
      ],
      [
        'Ó VÓS, todos os que tendes sede, vinde às águas, e os que não tendes dinheiro, vinde, comprai, e comei; sim, vinde, comprai, sem dinheiro e sem preço, vinho e leite.',
        'Por que gastais o dinheiro naquilo que não é pão? E o produto do vosso trabalho naquilo que não pode satisfazer? Ouvi-me atentamente, e comei o que é bom, e a vossa alma se deleite com a gordura.',
        'Inclinai os vossos ouvidos, e vinde a mim; ouvi, e a vossa alma viverá; porque convosco farei uma aliança perpétua, dando-vos as firmes beneficências de Davi.',
        'Eis que eu o dei por testemunha aos povos, como líder e governador dos povos.',
        'Eis que chamarás a uma nação que não conheces, e uma nação que nunca te conheceu correrá para ti, por amor do Senhor teu Deus, e do Santo de Israel; porque ele te glorificou.',
        'Buscai ao Senhor enquanto se pode achar, invocai-o enquanto está perto.',
        'Deixe o ímpio o seu caminho, e o homem maligno os seus pensamentos, e se converta ao Senhor, que se compadecerá dele; torne para o nosso Deus, porque grandioso é em perdoar.',
        'Porque os meus pensamentos não são os vossos pensamentos, nem os vossos caminhos os meus caminhos, diz o Senhor.',
        'Porque assim como os céus são mais altos do que a terra, assim são os meus caminhos mais altos do que os vossos caminhos, e os meus pensamentos mais altos do que os vossos pensamentos.',
        'Porque, assim como desce a chuva e a neve dos céus, e para lá não tornam, mas regam a terra, e a fazem produzir, e brotar, e dar semente ao semeador, e pão ao que come,',
        'Assim será a minha palavra, que sair da minha boca; ela não voltará para mim vazia, antes fará o que me apraz, e prosperará naquilo para que a enviei.',
        'Porque com alegria saireis, e em paz sereis guiados; os montes e os outeiros romperão em cântico diante de vós, e todas as árvores do campo baterão palmas.',
        'Em lugar do espinheiro crescerá a faia, e em lugar da sarça crescerá a murta; o que será para o Senhor por nome, e por sinal eterno, que nunca se apagará.',
      ],
      [
        'Assim diz o SENHOR: Guardai o juízo, e fazei justiça, porque a minha salvação está prestes a vir, e a minha justiça, para se manifestar.',
        'Bem-aventurado o homem que fizer isto, e o filho do homem que lançar mão disto; que se guarda de profanar o sábado, e guarda a sua mão de fazer algum mal.',
        'E não fale o filho do estrangeiro, que se houver unido ao Senhor, dizendo: Certamente o Senhor me separará do seu povo; nem tampouco diga o eunuco: Eis que sou uma árvore seca.',
        'Porque assim diz o Senhor a respeito dos eunucos, que guardam os meus sábados, e escolhem aquilo em que eu me agrado, e abraçam a minha aliança:',
        'Também lhes darei na minha casa e dentro dos meus muros um lugar e um nome, melhor do que o de filhos e filhas; um nome eterno darei a cada um deles, que nunca se apagará.',
        'E aos filhos dos estrangeiros, que se unirem ao Senhor, para o servirem, e para amarem o nome do Senhor, e para serem seus servos, todos os que guardarem o sábado, não o profanando, e os que abraçarem a minha aliança,',
        'Também os levarei ao meu santo monte, e os alegrarei na minha casa de oração; os seus holocaustos e os seus sacrifícios serão aceitos no meu altar; porque a minha casa será chamada casa de oração para todos os povos.',
        'Assim diz o Senhor DEUS, que congrega os dispersos de Israel: Ainda ajuntarei outros aos que já se lhe ajuntaram.',
        'Vós, todos os animais do campo, todos os animais dos bosques, vinde comer.',
        'Todos os seus atalaias são cegos, nada sabem; todos são cães mudos, não podem ladrar; andam adormecidos, estão deitados, e gostam do sono.',
        'E estes cães são gulosos, não se podem fartar; e eles são pastores que nada compreendem; todos eles se tornam para o seu caminho, cada um para a sua ganância, cada um por sua parte.',
        'Vinde, dizem, trarei vinho, e beberemos bebida forte; e o dia de amanhã será como este, e ainda muito mais abundante.',
      ],
      [
        'Perece o justo, e não há quem considere isso em seu coração, e os homens compassivos são recolhidos, sem que alguém considere que o justo é levado antes do mal.',
        'Entrará em paz; descansarão nas suas camas, os que houverem andado na sua retidão.',
        'Mas chegai-vos aqui, vós os filhos da agoureira, descendência adulterina, e de prostituição.',
        'De quem fazeis o vosso passatempo? Contra quem escancarais a boca, e deitais para fora a língua? Porventura não sois filhos da transgressão, descendência da falsidade,',
        'Que vos inflamais com os deuses debaixo de toda a árvore verde, e sacrificais os filhos nos ribeiros, nas fendas dos penhascos?',
        'Nas pedras lisas dos ribeiros está a tua parte; estas, estas são a tua sorte; sobre elas também derramaste a tua libação, e lhes ofereceste ofertas; contentar-me-ia eu com estas coisas?',
        'Sobre o monte alto e levantado pões a tua cama; e lá subiste para oferecer sacrifícios.',
        'E detrás das portas, e dos umbrais puseste o teu memorial; pois te descobriste a outros que não a mim, e subiste, alargaste a tua cama, e fizeste aliança com alguns deles; amaste a sua cama, onde quer que a viste.',
        'E foste ao rei com óleo, e multiplicaste os teus perfumes e enviaste os teus embaixadores para longe, e te abateste até ao inferno.',
        'Na tua comprida viagem te cansaste; porém não disseste: Não há esperança; achaste novo vigor na tua mão; por isso não adoeceste.',
        'Mas de quem tiveste receio, ou temor, para que mentisses, e não te lembrasses de mim, nem no teu coração me pusesses? Não é porventura porque eu me calei, e isso há muito tempo, e não me temes?',
        'Eu publicarei a tua justiça, e as tuas obras, que não te aproveitarão.',
        'Quando clamares, livrem-te os ídolos que ajuntaste; mas o vento a todos levará, e um sopro os arrebatará; mas o que confia em mim possuirá a terra, e herdará o meu santo monte.',
        'E dir-se-á: Aplanai, aplanai a estrada, preparai o caminho; tirai os tropeços do caminho do meu povo.',
        'Porque assim diz o Alto e o Sublime, que habita na eternidade, e cujo nome é Santo: Num alto e santo lugar habito; como também com o contrito e abatido de espírito, para vivificar o espírito dos abatidos, e para vivificar o coração dos contritos.',
        'Porque não contenderei para sempre, nem continuamente me indignarei; porque o espírito perante a minha face se desfaleceria, e as almas que eu fiz.',
        'Pela iniqüidade da sua avareza me indignei, e o feri; escondi-me, e indignei-me; contudo, rebelde, seguiu o caminho do seu coração.',
        'Eu vejo os seus caminhos, e o sararei, e o guiarei, e lhe tornarei a dar consolação, a saber, aos seus pranteadores.',
        'Eu crio os frutos dos lábios: paz, paz, para o que está longe; e para o que está perto, diz o Senhor, e eu o sararei.',
        'Mas os ímpios são como o mar bravo, porque não se pode aquietar, e as suas águas lançam de si lama e lodo.',
        'Não há paz para os ímpios, diz o meu Deus.',
      ],
      [
        'Clama em alta voz, não te detenhas, levanta a tua voz como a trombeta e anuncia ao meu povo a sua transgressão, e à casa de Jacó os seus pecados.',
        'Todavia me procuram cada dia, tomam prazer em saber os meus caminhos, como um povo que pratica justiça, e não deixa o direito do seu Deus; perguntam-me pelos direitos da justiça, e têm prazer em se chegarem a Deus,',
        'Dizendo: Por que jejuamos nós, e tu não atentas para isso? Por que afligimos as nossas almas, e tu não o sabes? Eis que no dia em que jejuais achais o vosso próprio contentamento, e requereis todo o vosso trabalho.',
        'Eis que para contendas e debates jejuais, e para ferirdes com punho iníquo; não jejueis como hoje, para fazer ouvir a vossa voz no alto.',
        'Seria este o jejum que eu escolheria, que o homem um dia aflija a sua alma, que incline a sua cabeça como o junco, e estenda debaixo de si saco e cinza? Chamarias tu a isto jejum e dia aprazível ao Senhor?',
        'Porventura não é este o jejum que escolhi, que soltes as ligaduras da impiedade, que desfaças as ataduras do jugo e que deixes livres os oprimidos, e despedaces todo o jugo?',
        'Porventura não é também que repartas o teu pão com o faminto, e recolhas em casa os pobres abandonados; e, quando vires o nu, o cubras, e não te escondas da tua carne?',
        'Então romperá a tua luz como a alva, e a tua cura apressadamente brotará, e a tua justiça irá adiante de ti, e a glória do Senhor será a tua retaguarda.',
        'Então clamarás, e o Senhor te responderá; gritarás, e ele dirá: Eis-me aqui. Se tirares do meio de ti o jugo, o estender do dedo, e o falar iniquamente;',
        'E se abrires a tua alma ao faminto, e fartares a alma aflita; então a tua luz nascerá nas trevas, e a tua escuridão será como o meio-dia.',
        'E o Senhor te guiará continuamente, e fartará a tua alma em lugares áridos, e fortificará os teus ossos; e serás como um jardim regado, e como um manancial, cujas águas nunca faltam.',
        'E os que de ti procederem edificarão as antigas ruínas; e levantarás os fundamentos de geração em geração; e chamar-te-ão reparador das roturas, e restaurador de veredas para morar.',
        'Se desviares o teu pé do sábado, de fazeres a tua vontade no meu santo dia, e chamares ao sábado deleitoso, e o santo dia do Senhor, digno de honra, e o honrares não seguindo os teus caminhos, nem pretendendo fazer a tua própria vontade, nem falares as tuas próprias palavras,',
        'Então te deleitarás no Senhor, e te farei cavalgar sobre as alturas da terra, e te sustentarei com a herança de teu pai Jacó; porque a boca do Senhor o disse.',
      ],
      [
        'Eis que a mão do SENHOR não está encolhida, para que não possa salvar; nem agravado o seu ouvido, para não poder ouvir.',
        'Mas as vossas iniqüidades fazem separação entre vós e o vosso Deus; e os vossos pecados encobrem o seu rosto de vós, para que não vos ouça.',
        'Porque as vossas mãos estão contaminadas de sangue, e os vossos dedos de iniqüidade; os vossos lábios falam falsidade, a vossa língua pronuncia perversidade.',
        'Ninguém há que clame pela justiça, nem ninguém que compareça em juízo pela verdade; confiam na vaidade, e falam mentiras; concebem o mal, e dão à luz a iniqüidade.',
        'Chocam ovos de basilisco, e tecem teias de aranha; o que comer dos ovos deles, morrerá; e, quebrando-os, sairá uma víbora.',
        'As suas teias não prestam para vestes nem se poderão cobrir com as suas obras; as suas obras são obras de iniqüidade, e obra de violência há nas suas mãos.',
        'Os seus pés correm para o mal, e se apressam para derramarem o sangue inocente; os seus pensamentos são pensamentos de iniqüidade; destruição e quebrantamento há nas suas estradas.',
        'Não conhecem o caminho da paz, nem há justiça nos seus passos; fizeram para si veredas tortuosas; todo aquele que anda por elas não tem conhecimento da paz.',
        'Por isso o juízo está longe de nós, e a justiça não nos alcança; esperamos pela luz, e eis que só há trevas; pelo resplendor, mas andamos em escuridão.',
        'Apalpamos as paredes como cegos, e como os que não têm olhos andamos apalpando; tropeçamos ao meio-dia como nas trevas, e nos lugares escuros como mortos.',
        'Todos nós bramamos como ursos, e continuamente gememos como pombas; esperamos pelo juízo, e não o há; pela salvação, e está longe de nós.',
        'Porque as nossas transgressões se multiplicaram perante ti, e os nossos pecados testificam contra nós; porque as nossas transgressões estão conosco, e conhecemos as nossas iniqüidades;',
        'Como o prevaricar, e mentir contra o Senhor, e o desviarmo-nos do nosso Deus, o falar de opressão e rebelião, o conceber e proferir do coração palavras de falsidade.',
        'Por isso o direito se tornou atrás, e a justiça se pôs de longe; porque a verdade anda tropeçando pelas ruas, e a eqüidade não pode entrar.',
        'Sim, a verdade desfalece, e quem se desvia do mal arrisca-se a ser despojado; e o Senhor viu, e pareceu mal aos seus olhos que não houvesse justiça.',
        'E vendo que ninguém havia, maravilhou-se de que não houvesse um intercessor; por isso o seu próprio braço lhe trouxe a salvação, e a sua própria justiça o susteve.',
        'Pois vestiu-se de justiça, como de uma couraça, e pôs o capacete da salvação na sua cabeça, e por vestidura pôs sobre si vestes de vingança, e cobriu-se de zelo, como de um manto.',
        'Conforme forem as obras deles, assim será a sua retribuição, furor aos seus adversários, e recompensa aos seus inimigos; às ilhas dará ele a sua recompensa.',
        'Então temerão o nome do Senhor desde o poente, e a sua glória desde o nascente do sol; vindo o inimigo como uma corrente de águas, o Espírito do Senhor arvorará contra ele a sua bandeira.',
        'E virá um Redentor a Sião e aos que em Jacó se converterem da transgressão, diz o Senhor.',
        'Quanto a mim, esta é a minha aliança com eles, diz o Senhor: o meu espírito, que está sobre ti, e as minhas palavras, que pus na tua boca, não se desviarão da tua boca nem da boca da tua descendência, nem da boca da descendência da tua descendência, diz o Senhor, desde agora e para todo o sempre.',
      ],
      [
        'Levanta-te, resplandece, porque vem a tua luz, e a glória do SENHOR vai nascendo sobre ti;',
        'Porque eis que as trevas cobriram a terra, e a escuridão os povos; mas sobre ti o Senhor virá surgindo, e a sua glória se verá sobre ti.',
        'E os gentios caminharão à tua luz, e os reis ao resplendor que te nasceu.',
        'Levanta em redor os teus olhos, e vê; todos estes já se ajuntaram, e vêm a ti; teus filhos virão de longe, e tuas filhas serão criadas ao teu lado.',
        'Então o verás, e serás iluminado, e o teu coração estremecerá e se alargará; porque a abundância do mar se tornará a ti, e as riquezas dos gentios virão a ti.',
        'A multidão de camelos te cobrirá, os dromedários de Midiã e Efá; todos virão de Sabá; ouro e incenso trarão, e publicarão os louvores do Senhor.',
        'Todas as ovelhas de Quedar se congregarão a ti; os carneiros de Nebaiote te servirão; com agrado subirão ao meu altar, e eu glorificarei a casa da minha glória.',
        'Quem são estes que vêm voando como nuvens, e como pombas às suas janelas?',
        'Certamente as ilhas me aguardarão, e primeiro os navios de Társis, para trazer teus filhos de longe, e com eles a sua prata e o seu ouro, para o nome do Senhor teu Deus, e para o Santo de Israel, porquanto ele te glorificou.',
        'E os filhos dos estrangeiros edificarão os teus muros, e os seus reis te servirão; porque no meu furor te feri, mas na minha benignidade tive misericórdia de ti.',
        'E as tuas portas estarão abertas de contínuo, nem de dia nem de noite se fecharão; para que tragam a ti as riquezas dos gentios, e, conduzidos com elas, os seus reis.',
        'Porque a nação e o reino que não te servirem perecerão; sim, essas nações serão de todo assoladas.',
        'A glória do Líbano virá a ti; a faia, o pinheiro, e o álamo conjuntamente, para ornarem o lugar do meu santuário, e glorificarei o lugar dos meus pés.',
        'Também virão a ti, inclinando-se, os filhos dos que te oprimiram; e prostrar-se-ão às plantas dos teus pés todos os que te desprezaram; e chamar-te-ão a cidade do Senhor, a Sião do Santo de Israel.',
        'Em lugar de seres deixada, e odiada, de modo que ninguém passava por ti, far-te-ei uma excelência perpétua, um gozo de geração em geração.',
        'E mamarás o leite dos gentios, e alimentar-te-ás ao peito dos reis; e saberás que eu sou o Senhor, o teu Salvador, e o teu Redentor, o Poderoso de Jacó.',
        'Por cobre trarei ouro, e por ferro trarei prata, e por madeira, bronze, e por pedras, ferro; e farei pacíficos os teus oficiais e justos os teus exatores.',
        'Nunca mais se ouvirá de violência na tua terra, desolação nem destruição nos teus termos; mas aos teus muros chamarás Salvação, e às tuas portas Louvor.',
        'Nunca mais te servirá o sol para luz do dia nem com o seu resplendor a lua te iluminará; mas o Senhor será a tua luz perpétua, e o teu Deus a tua glória.',
        'Nunca mais se porá o teu sol, nem a tua lua minguará; porque o Senhor será a tua luz perpétua, e os dias do teu luto findarão.',
        'E todos os do teu povo serão justos, para sempre herdarão a terra; serão renovos por mim plantados, obra das minhas mãos, para que eu seja glorificado.',
        'O menor virá a ser mil, e o mínimo uma nação forte; eu, o Senhor, ao seu tempo o farei prontamente.',
      ],
      [
        'O espírito do Senhor DEUS está sobre mim; porque o SENHOR me ungiu, para pregar boas novas aos mansos; enviou-me a restaurar os contritos de coração, a proclamar liberdade aos cativos, e a abertura de prisão aos presos;',
        'A apregoar o ano aceitável do Senhor e o dia da vingança do nosso Deus; a consolar todos os tristes;',
        'A ordenar acerca dos tristes de Sião que se lhes dê glória em vez de cinza, óleo de gozo em vez de tristeza, vestes de louvor em vez de espírito angustiado; a fim de que se chamem árvores de justiça, plantações do Senhor, para que ele seja glorificado.',
        'E edificarão os lugares antigamente assolados, e restaurarão os anteriormente destruídos, e renovarão as cidades assoladas, destruídas de geração em geração.',
        'E haverá estrangeiros, que apascentarão os vossos rebanhos; e estranhos serão os vossos lavradores e os vossos vinhateiros.',
        'Porém vós sereis chamados sacerdotes do Senhor, e vos chamarão ministros de nosso Deus; comereis a riqueza dos gentios, e na sua glória vos gloriareis.',
        'Em lugar da vossa vergonha tereis dupla honra; e em lugar da afronta exultareis na vossa parte; por isso na sua terra possuirão o dobro, e terão perpétua alegria.',
        'Porque eu, o Senhor, amo o juízo, odeio o que foi roubado oferecido em holocausto; portanto, firmarei em verdade a sua obra; e farei uma aliança eterna com eles.',
        'E a sua posteridade será conhecida entre os gentios, e os seus descendentes no meio dos povos; todos quantos os virem os conhecerão, como descendência bendita do Senhor.',
        'Regozijar-me-ei muito no Senhor, a minha alma se alegrará no meu Deus; porque me vestiu de roupas de salvação, cobriu-me com o manto de justiça, como um noivo se adorna com turbante sacerdotal, e como a noiva que se enfeita com as suas jóias.',
        'Porque, como a terra produz os seus renovos, e como o jardim faz brotar o que nele se semeia, assim o Senhor DEUS fará brotar a justiça e o louvor para todas as nações.',
      ],
      [
        'Por amor de Sião não me calarei, e por amor de Jerusalém não me aquietarei, até que saia a sua justiça como um resplendor, e a sua salvação como uma tocha acesa.',
        'E os gentios verão a tua justiça, e todos os reis a tua glória; e chamar-te-ão por um nome novo, que a boca do Senhor designará.',
        'E serás uma coroa de glória na mão do Senhor, e um diadema real na mão do teu Deus.',
        'Nunca mais te chamarão: Desamparada, nem a tua terra se denominará jamais: Assolada; mas chamar-te-ão: O meu prazer está nela, e à tua terra: A casada; porque o Senhor se agrada de ti, e a tua terra se casará.',
        'Porque, como o jovem se casa com a virgem, assim teus filhos se casarão contigo; e como o noivo se alegra da noiva, assim se alegrará de ti o teu Deus.',
        'Ó Jerusalém, sobre os teus muros pus guardas, que todo o dia e toda a noite jamais se calarão; ó vós, os que fazeis lembrar ao Senhor, não haja descanso em vós,',
        'Nem deis a ele descanso, até que confirme, e até que ponha a Jerusalém por louvor na terra.',
        'Jurou o Senhor pela sua mão direita, e pelo braço da sua força: Nunca mais darei o teu trigo por comida aos teus inimigos, nem os estrangeiros beberão o teu mosto, em que trabalhaste.',
        'Mas os que o ajuntarem o comerão, e louvarão ao Senhor; e os que o colherem beberão nos átrios do meu santuário.',
        'Passai, passai pelas portas; preparai o caminho ao povo; aplanai, aplanai a estrada, limpai-a das pedras; arvorai a bandeira aos povos.',
        'Eis que o Senhor fez ouvir até às extremidades da terra: Dizei à filha de Sião: Eis que vem a tua salvação; eis que com ele vem o seu galardão, e a sua obra diante dele.',
        'E chamar-lhes-ão: Povo santo, remidos do Senhor; e tu serás chamada: Procurada, a cidade não desamparada.',
      ],
      [
        'Quem é este, que vem de Edom, de Bozra, com vestes tintas; este que é glorioso em sua vestidura, que marcha com a sua grande força? Eu, que falo em justiça, poderoso para salvar.',
        'Por que está vermelha a tua vestidura, e as tuas roupas como as daquele que pisa no lagar?',
        'Eu sozinho pisei no lagar, e dos povos ninguém houve comigo; e os pisei na minha ira, e os esmaguei no meu furor; e o seu sangue salpicou as minhas vestes, e manchei toda a minha vestidura.',
        'Porque o dia da vingança estava no meu coração; e o ano dos meus remidos é chegado.',
        'E olhei, e não havia quem me ajudasse; e admirei-me de não haver quem me sustivesse, por isso o meu braço me trouxe a salvação, e o meu furor me susteve.',
        'E atropelei os povos na minha ira, e os embriaguei no meu furor; e a sua força derrubei por terra.',
        'As benignidades do Senhor mencionarei, e os muitos louvores do Senhor, conforme tudo quanto o Senhor nos concedeu; e grande bondade para com a casa de Israel, que usou com eles segundo as suas misericórdias, e segundo a multidão das suas benignidades.',
        'Porque dizia: Certamente eles são meu povo, filhos que não mentirão; assim ele se fez o seu Salvador.',
        'Em toda a angústia deles ele foi angustiado, e o anjo da sua presença os salvou; pelo seu amor, e pela sua compaixão ele os remiu; e os tomou, e os conduziu todos os dias da antiguidade.',
        'Mas eles foram rebeldes, e contristaram o seu Espírito Santo; por isso se lhes tornou em inimigo, e ele mesmo pelejou contra eles.',
        'Todavia se lembrou dos dias da antiguidade, de Moisés, e do seu povo, dizendo: Onde está agora o que os fez subir do mar com os pastores do seu rebanho? Onde está o que pôs no meio deles o seu Espírito Santo?',
        'Aquele cujo braço glorioso ele fez andar à mão direita de Moisés, que fendeu as águas diante deles, para fazer para si um nome eterno?',
        'Aquele que os guiou pelos abismos, como o cavalo no deserto, de modo que nunca tropeçaram?',
        'Como o animal que desce ao vale, o Espírito do Senhor lhes deu descanso; assim guiaste ao teu povo, para te fazeres um nome glorioso.',
        'Atenta desde os céus, e olha desde a tua santa e gloriosa habitação. Onde estão o teu zelo e as tuas obras poderosas? A comoção das tuas entranhas, e das tuas misericórdias, detém-se para comigo?',
        'Mas tu és nosso Pai, ainda que Abraão não nos conhece, e Israel não nos reconhece; tu, ó Senhor, és nosso Pai; nosso Redentor desde a antiguidade é o teu nome.',
        'Por que, ó Senhor, nos fazes errar dos teus caminhos? Por que endureces o nosso coração, para que não te temamos? Volta, por amor dos teus servos, às tribos da tua herança.',
        'Só por um pouco de tempo o teu santo povo a possuiu; nossos adversários pisaram o teu santuário.',
        'Somos feitos como aqueles sobre quem tu nunca dominaste, e como os que nunca se chamaram pelo teu nome.',
      ],
      [
        'Oh! se fendesses os céus, e descesses, e os montes se escoassem de diante da tua face,',
        'Como o fogo abrasador de fundição, fogo que faz ferver as águas, para fazeres notório o teu nome aos teus adversários, e assim as nações tremessem da tua presença!',
        'Quando fazias coisas terríveis, que nunca esperávamos, descias, e os montes se escoavam diante da tua face.',
        'Porque desde a antiguidade não se ouviu, nem com ouvidos se percebeu, nem com os olhos se viu um Deus além de ti que trabalha para aquele que nele espera.',
        'Saíste ao encontro daquele que se alegrava e praticava justiça e dos que se lembram de ti nos teus caminhos; eis que te iraste, porque pecamos; neles há eternidade, para que sejamos salvos?',
        'Mas todos nós somos como o imundo, e todas as nossas justiças como trapo da imundícia; e todos nós murchamos como a folha, e as nossas iniqüidades como um vento nos arrebatam.',
        'E já ninguém há que invoque o teu nome, que se desperte, e te detenhas; porque escondes de nós o teu rosto, e nos fazes derreter, por causa das nossas iniqüidades.',
        'Mas agora, ó Senhor, tu és nosso Pai; nós o barro e tu o nosso oleiro; e todos nós a obra das tuas mãos.',
        'Não te enfureças tanto, ó Senhor, nem perpetuamente te lembres da iniqüidade; olha, pois, nós te pedimos, todos nós somos o teu povo.',
        'As tuas santas cidades tornaram-se um deserto; Sião está feita um deserto, Jerusalém está assolada.',
        'A nossa santa e gloriosa casa, em que te louvavam nossos pais, foi queimada a fogo; e todas as nossas coisas preciosas se tornaram em assolação.',
        'Conter-te-ias tu ainda sobre estas coisas, ó Senhor? Ficarias calado, e nos afligirias tanto?',
      ],
      [
        'Fui buscado dos que não perguntavam por mim, fui achado daqueles que não me buscavam; a uma nação que não se chamava do meu nome eu disse: Eis-me aqui. Eis-me aqui.',
        'Estendi as minhas mãos o dia todo a um povo rebelde, que anda por caminho, que não é bom, após os seus pensamentos;',
        'Povo que de contínuo me irrita diante da minha face, sacrificando em jardins e queimando incenso sobre altares de tijolos;',
        'Que habita entre as sepulturas, e passa as noites junto aos lugares secretos; come carne de porco e tem caldo de coisas abomináveis nos seus vasos;',
        'Que dizem: Fica onde estás, e não te chegues a mim, porque sou mais santo do que tu. Estes são fumaça no meu nariz, um fogo que arde todo o dia.',
        'Eis que está escrito diante de mim: não me calarei; mas eu pagarei, sim, pagarei no seu seio,',
        'As vossas iniqüidades, e juntamente as iniqüidades de vossos pais, diz o Senhor, que queimaram incenso nos montes, e me afrontaram nos outeiros; assim lhes tornarei a medir as suas obras antigas no seu seio.',
        'Assim diz o Senhor: Como quando se acha mosto num cacho de uvas, dizem: Não o desperdices, pois há bênção nele, assim farei por amor de meus servos, que não os destrua a todos,',
        'E produzirei descendência a Jacó, e a Judá um herdeiro que possua os meus montes; e os meus eleitos herdarão a terra e os meus servos habitarão ali.',
        'E Sarom servirá de curral de rebanhos, e o vale de Acor lugar de repouso de gados, para o meu povo, que me buscou.',
        'Mas a vós, os que vos apartais do Senhor, os que vos esqueceis do meu santo monte, os que preparais uma mesa para a Fortuna, e que misturais a bebida para o Destino.',
        'Também vos destinareis à espada, e todos vos encurvareis à matança; porquanto chamei, e não respondestes; falei, e não ouvistes; mas fizestes o que era mau aos meus olhos, e escolhestes aquilo em que não tinha prazer.',
        'Portanto assim diz o Senhor DEUS: Eis que os meus servos comerão, mas vós padecereis fome; eis que os meus servos beberão, porém vós tereis sede; eis que os meus servos se alegrarão, mas vós vos envergonhareis;',
        'Eis que os meus servos exultarão pela alegria de coração, mas vós gritareis pela tristeza de coração; e uivareis pelo quebrantamento de espírito.',
        'E deixareis o vosso nome aos meus eleitos por maldição; e o Senhor DEUS vos matará; e a seus servos chamará por outro nome.',
        'Assim que aquele que se bendisser na terra, se bendirá no Deus da verdade; e aquele que jurar na terra, jurará pelo Deus da verdade; porque já estão esquecidas as angústias passadas, e estão escondidas dos meus olhos.',
        'Porque, eis que eu crio novos céus e nova terra; e não haverá mais lembrança das coisas passadas, nem mais se recordarão.',
        'Mas vós folgareis e exultareis perpetuamente no que eu crio; porque eis que crio para Jerusalém uma alegria, e para o seu povo gozo.',
        'E exultarei em Jerusalém, e me alegrarei no meu povo; e nunca mais se ouvirá nela voz de choro nem voz de clamor.',
        'Não haverá mais nela criança de poucos dias, nem velho que não cumpra os seus dias; porque o menino morrerá de cem anos; porém o pecador de cem anos será amaldiçoado.',
        'E edificarão casas, e as habitarão; e plantarão vinhas, e comerão o seu fruto.',
        'Não edificarão para que outros habitem; não plantarão para que outros comam; porque os dias do meu povo serão como os dias da árvore, e os meus eleitos gozarão das obras das suas mãos.',
        'Não trabalharão debalde, nem terão filhos para a perturbação; porque são a posteridade bendita do Senhor, e os seus descendentes estarão com eles.',
        'E será que antes que clamem eu responderei; estando eles ainda falando, eu os ouvirei.',
        'O lobo e o cordeiro se apascentarão juntos, e o leão comerá palha como o boi; e pó será a comida da serpente. Não farão mal nem dano algum em todo o meu santo monte, diz o Senhor.',
      ],
      [
        'Assim diz o SENHOR: O céu é o meu trono, e a terra o escabelo dos meus pés; que casa me edificaríeis vós? E qual seria o lugar do meu descanso?',
        'Porque a minha mão fez todas estas coisas, e assim todas elas foram feitas, diz o Senhor; mas para esse olharei, para o pobre e abatido de espírito, e que treme da minha palavra.',
        'Quem mata um boi é como o que tira a vida a um homem; quem sacrifica um cordeiro é como o que degola um cão; quem oferece uma oblação é como o que oferece sangue de porco; quem queima incenso em memorial é como o que bendiz a um ídolo; também estes escolhem os seus próprios caminhos, e a sua alma se deleita nas suas abominações.',
        'Também eu escolherei as suas calamidades, farei vir sobre eles os seus temores; porquanto clamei e ninguém respondeu, falei e não escutaram; mas fizeram o que era mau aos meus olhos, e escolheram aquilo em que eu não tinha prazer.',
        'Ouvi a palavra do Senhor, os que tremeis da sua palavra. Vossos irmãos, que vos odeiam e que para longe vos lançam por amor do meu nome, dizem: Seja glorificado o Senhor, para que vejamos a vossa alegria; mas eles serão confundidos.',
        'Uma voz de grande rumor virá da cidade, uma voz do templo, a voz do Senhor, que dá o pago aos seus inimigos.',
        'Antes que estivesse de parto, deu à luz; antes que lhe viessem as dores, deu à luz um menino.',
        'Quem jamais ouviu tal coisa? Quem viu coisas semelhantes? Poder-se-ia fazer nascer uma terra num só dia? Nasceria uma nação de uma só vez? Mas Sião esteve de parto e já deu à luz seus filhos.',
        'Abriria eu a madre, e não geraria? diz o Senhor; geraria eu, e fecharia a madre? diz o teu Deus.',
        'Regozijai-vos com Jerusalém, e alegrai-vos por ela, vós todos os que a amais; enchei-vos por ela de alegria, todos os que por ela pranteastes;',
        'Para que mameis, e vos farteis dos peitos das suas consolações; para que sugueis, e vos deleiteis com a abundância da sua glória.',
        'Porque assim diz o Senhor: Eis que estenderei sobre ela a paz como um rio, e a glória dos gentios como um ribeiro que transborda; então mamareis, ao colo vos trarão, e sobre os joelhos vos afagarão.',
        'Como alguém a quem consola sua mãe, assim eu vos consolarei; e em Jerusalém vós sereis consolados.',
        'E vós vereis e alegrar-se-á o vosso coração, e os vossos ossos reverdecerão como a erva tenra; então a mão do Senhor será notória aos seus servos, e ele se indignará contra os seus inimigos.',
        'Porque, eis que o Senhor virá com fogo; e os seus carros como um torvelinho; para tornar a sua ira em furor, e a sua repreensão em chamas de fogo.',
        'Porque com fogo e com a sua espada entrará o Senhor em juízo com toda a carne; e os mortos do Senhor serão multiplicados.',
        'Os que se santificam, e se purificam, nos jardins uns após outros; os que comem carne de porco, e a abominação, e o rato, juntamente serão consumidos, diz o Senhor.',
        'Porque conheço as suas obras e os seus pensamentos; vem o dia em que ajuntarei todas as nações e línguas; e virão e verão a minha glória.',
        'E porei entre eles um sinal, e os que deles escaparem enviarei às nações, a Társis, Pul, e Lude, flecheiros, a Tubal e Javã, até às ilhas de mais longe, que não ouviram a minha fama, nem viram a minha glória; e anunciarão a minha glória entre os gentios.',
        'E trarão a todos os vossos irmãos, dentre todas as nações, por oferta ao Senhor, sobre cavalos, e em carros, e em liteiras, e sobre mulas, e sobre dromedários, trarão ao meu santo monte, a Jerusalém, diz o Senhor; como quando os filhos de Israel trazem as suas ofertas em vasos limpos à casa do Senhor.',
        'E também deles tomarei a alguns para sacerdotes e para levitas, diz o Senhor.',
        'Porque, como os novos céus, e a nova terra, que hei de fazer, estarão diante da minha face, diz o Senhor, assim também há de estar a vossa posteridade e o vosso nome.',
        'E será que desde uma lua nova até à outra, e desde um sábado até ao outro, virá toda a carne a adorar perante mim, diz o Senhor.',
        'E sairão, e verão os cadáveres dos homens que prevaricaram contra mim; porque o seu verme nunca morrerá, nem o seu fogo se apagará; e serão um horror a toda a carne.',
      ],
    ],
    livro: 'isaias',
  },
  {
    abbrev: 'jr',
    capitulos: [
      [
        'Palavras de Jeremias, filho de Hilquias, um dos sacerdotes que estavam em Anatote, na terra de Benjamim;',
        'Ao qual veio a palavra do Senhor, nos dias de Josias, filho de Amom, rei de Judá, no décimo terceiro ano do seu reinado.',
        'E lhe veio também nos dias de Jeoiaquim, filho de Josias, rei de Judá, até ao fim do ano undécimo de Zedequias, filho de Josias, rei de Judá, até que Jerusalém foi levada em cativeiro no quinto mês.',
        'Assim veio a mim a palavra do Senhor, dizendo:',
        'Antes que te formasse no ventre te conheci, e antes que saísses da madre, te santifiquei; às nações te dei por profeta.',
        'Então disse eu: Ah, Senhor DEUS! Eis que não sei falar; porque ainda sou um menino.',
        'Mas o Senhor me disse: Não digas: Eu sou um menino; porque a todos a quem eu te enviar, irás; e tudo quanto te mandar, falarás.',
        'Não temas diante deles; porque estou contigo para te livrar, diz o Senhor.',
        'E estendeu o Senhor a sua mão, e tocou-me na boca; e disse-me o Senhor: Eis que ponho as minhas palavras na tua boca;',
        'Olha, ponho-te neste dia sobre as nações, e sobre os reinos, para arrancares, e para derrubares, e para destruíres, e para arruinares; e também para edificares e para plantares.',
        'Ainda veio a mim a palavra do Senhor, dizendo: Que é que vês, Jeremias? E eu disse: Vejo uma vara de amendoeira.',
        'E disse-me o Senhor: Viste bem; porque eu velo sobre a minha palavra para cumpri-la.',
        'E veio a mim a palavra do Senhor segunda vez, dizendo: Que é que vês? E eu disse: Vejo uma panela a ferver, cuja face está para o lado do norte.',
        'E disse-me o Senhor: Do norte se descobrirá o mal sobre todos os habitantes da terra.',
        'Porque eis que eu convoco todas as famílias dos reinos do norte, diz o Senhor; e virão, e cada um porá o seu trono à entrada das portas de Jerusalém, e contra todos os seus muros em redor, e contra todas as cidades de Judá.',
        'E eu pronunciarei contra eles os meus juízos, por causa de toda a sua malícia; pois me deixaram, e queimaram incenso a deuses estranhos, e se encurvaram diante das obras das suas mãos.',
        'Tu, pois, cinge os teus lombos, e levanta-te, e dize-lhes tudo quanto eu te mandar; não te espantes diante deles, para que eu não te envergonhe diante deles.',
        'Porque, eis que hoje te ponho por cidade forte, e por coluna de ferro, e por muros de bronze, contra toda a terra, contra os reis de Judá, contra os seus príncipes, contra os seus sacerdotes, e contra o povo da terra.',
        'E pelejarão contra ti, mas não prevalecerão contra ti; porque eu sou contigo, diz o Senhor, para te livrar.',
      ],
      [
        'E veio a mim a palavra do SENHOR, dizendo:',
        'Vai, e clama aos ouvidos de Jerusalém, dizendo: Assim diz o Senhor: Lembro-me de ti, da piedade da tua mocidade, e do amor do teu noivado, quando me seguias no deserto, numa terra que não se semeava.',
        'Então Israel era santidade para o Senhor, e as primícias da sua novidade; todos os que o devoravam eram tidos por culpados; o mal vinha sobre eles, diz o Senhor.',
        'Ouvi a palavra do Senhor, ó casa de Jacó, e todas as famílias da casa de Israel;',
        'Assim diz o Senhor: Que injustiça acharam vossos pais em mim, para se afastarem de mim, indo após a vaidade, e tornando-se levianos?',
        'E não disseram: Onde está o Senhor, que nos fez subir da terra do Egito, que nos guiou através do deserto, por uma terra árida, e de covas, por uma terra de sequidão e sombra de morte, por uma terra pela qual ninguém transitava, e na qual não morava homem algum?',
        'E eu vos introduzi numa terra fértil, para comerdes o seu fruto e o seu bem; mas quando nela entrastes contaminastes a minha terra, e da minha herança fizestes uma abominação.',
        'Os sacerdotes não disseram: Onde está o Senhor? E os que tratavam da lei não me conheciam, e os pastores prevaricavam contra mim, e os profetas profetizavam por Baal, e andaram após o que é de nenhum proveito.',
        'Portanto ainda contenderei convosco, diz o Senhor; e até com os filhos de vossos filhos contenderei.',
        'Pois, passai às ilhas de Quitim, e vede; e enviai a Quedar, e atentai bem, e vede se jamais sucedeu coisa semelhante.',
        'Houve alguma nação que trocasse os seus deuses, ainda que não fossem deuses? Todavia o meu povo trocou a sua glória por aquilo que é de nenhum proveito.',
        'Espantai-vos disto, ó céus, e horrorizai-vos! Ficai verdadeiramente desolados, diz o Senhor.',
        'Porque o meu povo fez duas maldades: a mim me deixaram, o manancial de águas vivas, e cavaram cisternas, cisternas rotas, que não retêm águas.',
        'Acaso é Israel um servo? É ele um escravo nascido em casa? Por que, pois, veio a ser presa?',
        'Os filhos de leão rugiram sobre ele, levantaram a sua voz; e fizeram da sua terra uma desolação; as suas cidades se queimaram, e ninguém habita nelas.',
        'Até os filhos de Nofe e de Tafnes te quebraram o alto da cabeça.',
        'Porventura não fizeste isto a ti mesmo, deixando o Senhor teu Deus, no tempo em que ele te guiava pelo caminho?',
        'Agora, pois, que te importa a ti o caminho do Egito, para beberes as águas de Sior? E que te importa a ti o caminho da Assíria, para beberes as águas do rio?',
        'A tua malícia te castigará, e as tuas apostasias te repreenderão; sabe, pois, e vê, que mal e quão amargo é deixares ao SENHOR teu Deus, e não teres em ti o meu temor, diz o Senhor DEUS dos Exércitos.',
        'Quando eu já há muito quebrava o teu jugo, e rompia as tuas ataduras, dizias tu: Nunca mais transgredirei; contudo em todo o outeiro alto e debaixo de toda a árvore verde te andas encurvando e prostituindo-te.',
        'Eu mesmo te plantei como vide excelente, uma semente inteiramente fiel; como, pois, te tornaste para mim uma planta degenerada como vide estranha?',
        'Por isso, ainda que te laves com salitre, e amontoes sabão, a tua iniqüidade está gravada diante de mim, diz o Senhor DEUS.',
        'Como dizes logo: Não estou contaminada nem andei após os baalins? Vê o teu caminho no vale, conhece o que fizeste; dromedária ligeira és, que anda torcendo os seus caminhos.',
        'Jumenta montês, acostumada ao deserto, que, conforme o desejo da sua alma, sorve o vento, quem a deteria no seu cio? Todos os que a buscarem não se cansarão; no mês dela a acharão.',
        'Evita que o teu pé ande descalço, e a tua garganta tenha sede. Mas tu dizes: Não há esperança; porque amo os estranhos, após eles andarei.',
        'Como fica confundido o ladrão quando o apanham, assim se confundem os da casa de Israel; eles, os seus reis, os seus príncipes, e os seus sacerdotes, e os seus profetas,',
        'Que dizem ao pau: Tu és meu pai; e à pedra: Tu me geraste; porque me viraram as costas, e não o rosto; mas no tempo da sua angústia dirão: Levanta-te, e livra-nos.',
        'Onde, pois, estão os teus deuses, que fizeste para ti? Que se levantem, se te podem livrar no tempo da tua angústia; porque os teus deuses, ó Judá, são tão numerosos como as tuas cidades.',
        'Por que contendeis comigo? Todos vós transgredistes contra mim, diz o Senhor.',
        'Em vão castiguei os vossos filhos; eles não aceitaram a correção; a vossa espada devorou os vossos profetas como um leão destruidor.',
        'Oh geração! Considerai vós a palavra do Senhor: Porventura tenho eu sido para Israel um deserto? Ou uma terra da mais espessa escuridão? Por que, pois, diz o meu povo: Temos determinado; não viremos mais a ti?',
        'Porventura esquece-se a virgem dos seus enfeites, ou a noiva dos seus adornos? Todavia o meu povo se esqueceu de mim por inumeráveis dias.',
        'Por que ornamentas o teu caminho, para buscares o amor? Pois até às malignas ensinaste os teus caminhos.',
        'Até nas orlas dos teus vestidos se achou o sangue das almas dos inocentes e necessitados; não cavei para achar, pois se vê em todas estas coisas.',
        'E ainda dizes: Eu estou inocente; certamente a sua ira se desviou de mim. Eis que entrarei em juízo contigo, porquanto dizes: Não pequei.',
        'Por que te desvias tanto, mudando o teu caminho? Também do Egito serás envergonhada, como foste envergonhada da Assíria.',
        'Também daquele sairás com as mãos sobre a tua cabeça; porque o Senhor rejeitou a tua confiança, e não prosperarás com eles.',
      ],
      [
        'Eles dizem: Se um homem despedir sua mulher, e ela o deixar, e se ajuntar a outro homem, porventura tornará ele outra vez para ela? Não se poluirá de todo aquela terra? Ora, tu te prostituíste com muitos amantes; mas ainda assim, torna para mim, diz o SENHOR.',
        'Levanta os teus olhos aos altos, e vê: onde não te prostituíste? Nos caminhos te assentavas para eles, como o árabe no deserto; assim poluíste a terra com as tuas fornicações e com a tua malícia.',
        'Por isso foram retiradas as chuvas, e não houve chuva serôdia; mas tu tens a fronte de uma prostituta, e não queres ter vergonha.',
        'Ao menos desde agora não chamarás por mim, dizendo: Pai meu, tu és o guia da minha mocidade?',
        'Conservará ele para sempre a sua ira? Ou a guardará continuamente? Eis que tens falado e feito quantas maldades pudeste.',
        'Disse mais o Senhor nos dias do rei Josias: Viste o que fez a rebelde Israel? Ela foi a todo o monte alto, e debaixo de toda a árvore verde, e ali andou prostituindo-se.',
        'E eu disse: Depois que fizer tudo isto, voltará para mim; mas não voltou; e viu isto a sua aleivosa irmã Judá.',
        'E vi que, por causa de tudo isto, por ter cometido adultério a rebelde Israel, a despedi, e lhe dei a sua carta de divórcio, que a aleivosa Judá, sua irmã, não temeu; mas se foi e também ela mesma se prostituiu.',
        'E sucedeu que pela fama da sua prostituição, contaminou a terra; porque adulterou com a pedra e com a madeira.',
        'E, contudo, apesar de tudo isso a sua aleivosa irmã Judá não voltou para mim de todo o seu coração, mas falsamente, diz o Senhor.',
        'E o Senhor me disse: Já a rebelde Israel mostrou-se mais justa do que a aleivosa Judá.',
        'Vai, pois, e apregoa estas palavras para o lado norte, e dize: Volta, ó rebelde Israel, diz o Senhor, e não farei cair a minha ira sobre ti; porque misericordioso sou, diz o Senhor, e não conservarei para sempre a minha ira.',
        'Somente reconhece a tua iniqüidade, que transgrediste contra o Senhor teu Deus; e estendeste os teus caminhos aos estranhos, debaixo de toda a árvore verde, e não deste ouvidos à minha voz, diz o Senhor.',
        'Convertei-vos, ó filhos rebeldes, diz o Senhor; pois eu vos desposei; e vos tomarei, a um de uma cidade, e a dois de uma família; e vos levarei a Sião.',
        'E dar-vos-ei pastores segundo o meu coração, os quais vos apascentarão com ciência e com inteligência.',
        'E sucederá que, quando vos multiplicardes e frutificardes na terra, naqueles dias, diz o Senhor, nunca mais se dirá: A arca da aliança do Senhor, nem lhes virá ao coração; nem dela se lembrarão, nem a visitarão; nem se fará outra.',
        'Naquele tempo chamarão a Jerusalém o trono do Senhor, e todas as nações se ajuntarão a ela, em nome do Senhor, em Jerusalém; e nunca mais andarão segundo o propósito do seu coração maligno.',
        'Naqueles dias andará a casa de Judá com a casa de Israel; e virão juntas da terra do norte, para a terra que dei em herança a vossos pais.',
        'Mas eu dizia: Como te porei entre os filhos, e te darei a terra desejável, a excelente herança dos exércitos das nações? Mas eu disse: Tu me chamarás meu pai, e de mim não te desviarás.',
        'Deveras, como a mulher se aparta aleivosamente do seu marido, assim aleivosamente te houveste comigo, ó casa de Israel, diz o Senhor.',
        'Nos lugares altos se ouviu uma voz, pranto e súplicas dos filhos de Israel; porquanto perverteram o seu caminho, e se esqueceram do Senhor seu Deus.',
        'Voltai, ó filhos rebeldes, eu curarei as vossas rebeliões. Eis-nos aqui, vimos a ti; porque tu és o Senhor nosso Deus.',
        'Certamente em vão se confia nos outeiros e na multidão das montanhas; deveras no Senhor nosso Deus está a salvação de Israel.',
        'Porque a confusão devorou o trabalho de nossos pais desde a nossa mocidade; as suas ovelhas e o seu gado, os seus filhos e as suas filhas.',
        'Deitemo-nos em nossa vergonha; e cubra-nos a nossa confusão, porque pecamos contra o Senhor nosso Deus, nós e nossos pais, desde a nossa mocidade até o dia de hoje; e não demos ouvidos à voz do Senhor nosso Deus.',
      ],
      [
        'Se voltares, ó Israel, diz o SENHOR, volta para mim; e se tirares as tuas abominações de diante de mim, não andarás mais vagueando,',
        'E jurarás: Vive o Senhor na verdade, no juízo e na justiça; e nele se bendirão as nações, e nele se gloriarão.',
        'Porque assim diz o Senhor aos homens de Judá e a Jerusalém: Preparai para vós o campo de lavoura, e não semeeis entre espinhos.',
        'Circuncidai-vos ao Senhor, e tirai os prepúcios do vosso coração, ó homens de Judá e habitantes de Jerusalém, para que o meu furor não venha a sair como fogo, e arda de modo que não haja quem o apague, por causa da malícia das vossas obras.',
        'Anunciai em Judá, e fazei ouvir em Jerusalém, e dizei: Tocai a trombeta na terra, gritai em alta voz, dizendo: Ajuntai-vos, e entremos nas cidades fortificadas.',
        'Arvorai a bandeira rumo a Sião, fugi, não vos detenhais; porque eu trago do norte um mal, e uma grande destruição.',
        'Já um leão subiu da sua ramada, e um destruidor dos gentios; ele já partiu, e saiu do seu lugar para fazer da tua terra uma desolação, a fim de que as tuas cidades sejam destruídas, e ninguém habite nelas.',
        'Por isto cingi-vos de sacos, lamentai, e uivai, porque o ardor da ira do Senhor não se desviou de nós.',
        'E sucederá naquele tempo, diz o Senhor, que se desfará o coração do rei e o coração dos príncipes; e os sacerdotes pasmarão, e os profetas se maravilharão.',
        'Então disse eu: Ah, Senhor DEUS! Verdadeiramente enganaste grandemente a este povo e a Jerusalém, dizendo: Tereis paz; pois a espada penetra-lhe até à alma.',
        'Naquele tempo se dirá a este povo e a Jerusalém: Um vento seco das alturas do deserto veio ao caminho da filha do meu povo; não para padejar, nem para limpar;',
        'Mas um vento mais veemente virá da minha parte; agora também eu pronunciarei juízos contra eles.',
        'Eis que virá subindo como nuvens e os seus carros como a tormenta; os seus cavalos serão mais ligeiros do que as águias; ai de nós, que somos assolados!',
        'Lava o teu coração da malícia, ó Jerusalém, para que sejas salva; até quando permanecerão no meio de ti os pensamentos da tua iniqüidade?',
        'Porque uma voz anuncia desde Dã, e faz ouvir a calamidade desde o monte de Efraim.',
        'Lembrai isto às nações; fazei ouvir contra Jerusalém, que vigias vêm de uma terra remota, e levantarão a sua voz contra as cidades de Judá.',
        'Como os guardas de um campo, estão contra ela ao redor; porquanto ela se rebelou contra mim, diz o Senhor.',
        'O teu caminho e as tuas obras te fizeram estas coisas; esta é a tua maldade, e amargosa é, que te chega até ao coração.',
        'Ah, entranhas minhas, entranhas minhas! Estou com dores no meu coração! O meu coração se agita em mim. Não posso me calar; porque tu, ó minha alma, ouviste o som da trombeta e o alarido da guerra.',
        'Destruição sobre destruição se apregoa; porque já toda a terra está destruída; de repente foram destruídas as minhas tendas, e as minhas cortinas num momento.',
        'Até quando verei a bandeira, e ouvirei a voz da trombeta?',
        'Deveras o meu povo está louco, já não me conhece; são filhos néscios, e não entendidos; são sábios para fazer mal, mas não sabem fazer o bem.',
        'Observei a terra, e eis que era sem forma e vazia; também os céus, e não tinham a sua luz.',
        'Observei os montes, e eis que estavam tremendo; e todos os outeiros estremeciam.',
        'Observei, e eis que não havia homem algum; e todas as aves do céu tinham fugido.',
        'Vi também que a terra fértil era um deserto; e todas as suas cidades estavam derrubadas diante do Senhor, diante do furor da sua ira.',
        'Porque assim diz o Senhor: Toda esta terra será assolada; de todo, porém, não a consumirei.',
        'Por isto lamentará a terra, e os céus em cima se enegrecerão; porquanto assim o disse, assim o propus, e não me arrependi nem me desviarei disso.',
        'Ao clamor dos cavaleiros e dos flecheiros fugiram todas as cidades; entraram pelas matas e treparam pelos penhascos; todas as cidades ficaram abandonadas, e já ninguém habita nelas.',
        'Agora, pois, que farás, ó assolada? Ainda que te vistas de carmesim, ainda que te adornes com enfeites de ouro, ainda que te pintes em volta dos teus olhos, debalde te farias bela; os amantes te desprezam, e procuram tirar-te a vida.',
        'Porquanto ouço uma voz, como a de uma mulher que está de parto, uma angústia como a de que está com dores de parto do primeiro filho; a voz da filha de Sião, ofegante, que estende as suas mãos, dizendo: Oh! ai de mim agora, porque já a minha alma desmaia por causa dos assassinos.',
      ],
      [
        'Dai voltas às ruas de Jerusalém, e vede agora; e informai-vos, e buscai pelas suas praças, a ver se achais alguém, ou se há homem que pratique a justiça ou busque a verdade; e eu lhe perdoarei.',
        'E ainda que digam: Vive o Senhor, de certo falsamente juram.',
        'Ah Senhor, porventura não atentam os teus olhos para a verdade? Feriste-os, e não lhes doeu; consumiste-os, e não quiseram receber a correção; endureceram as suas faces mais do que uma rocha; não quiseram voltar.',
        'Eu, porém, disse: Deveras estes são pobres; são loucos, pois não sabem o caminho do Senhor, nem o juízo do seu Deus.',
        'Irei aos grandes, e falarei com eles; porque eles sabem o caminho do Senhor, o juízo do seu Deus; mas estes juntamente quebraram o jugo, e romperam as ataduras.',
        'Por isso um leão do bosque os feriu, um lobo dos desertos os assolará; um leopardo vigia contra as suas cidades; qualquer que sair delas será despedaçado; porque as suas transgressões se avolumam, multiplicaram-se as suas apostasias.',
        'Como, vendo isto, te perdoaria? Teus filhos me deixam a mim e juram pelos que não são deuses; quando os fartei, então adulteraram, e em casa de meretrizes se ajuntaram em bandos.',
        'Como cavalos bem fartos, levantam-se pela manhã, rinchando cada um à mulher do seu próximo.',
        'Deixaria eu de castigar por estas coisas, diz o Senhor, ou não se vingaria a minha alma de uma nação como esta?',
        'Subi aos seus muros, e destruí-os (porém não façais uma destruição final); tirai os seus ramos, porque não são do Senhor.',
        'Porque aleivosissimamente se houveram contra mim a casa de Israel e a casa de Judá, diz o Senhor.',
        'Negaram ao Senhor, e disseram: Não é ele; nem mal nos sobrevirá, nem veremos espada nem fome.',
        'E até os profetas serão como vento, porque a palavra não está com eles; assim se lhes sucederá.',
        'Portanto assim diz o Senhor Deus dos Exércitos: Porquanto disseste tal palavra, eis que converterei as minhas palavras na tua boca em fogo, e a este povo em lenha, eles serão consumidos.',
        'Eis que trarei sobre vós uma nação de longe, ó casa de Israel, diz o Senhor; é uma nação robusta, é uma nação antiqüíssima, uma nação cuja língua ignorarás, e não entenderás o que ela falar.',
        'A sua aljava é como uma sepultura aberta; todos eles são poderosos.',
        'E comerão a tua sega e o teu pão, que teus filhos e tuas filhas haviam de comer; comerão as tuas ovelhas e as tuas vacas; comerão a tua vide e a tua figueira; as tuas cidades fortificadas, em que confiavas, abatê-las-ão à espada.',
        'Contudo, ainda naqueles dias, diz o Senhor, não farei de vós uma destruição final.',
        'E sucederá que, quando disserdes: Por que nos fez o Senhor nosso Deus todas estas coisas? Então lhes dirás: Como vós me deixastes, e servistes a deuses estranhos na vossa terra, assim servireis a estrangeiros, em terra que não é vossa.',
        'Anunciai isto na casa de Jacó, e fazei-o ouvir em Judá, dizendo:',
        'Ouvi agora isto, ó povo insensato, e sem coração, que tendes olhos e não vedes, que tendes ouvidos e não ouvis.',
        'Porventura não me temereis a mim? diz o Senhor; não temereis diante de mim, que pus a areia por limite ao mar, por ordenança eterna, que ele não traspassará? Ainda que se levantem as suas ondas, não prevalecerão; ainda que bramem, não a traspassarão.',
        'Mas este povo é de coração rebelde e pertinaz: rebelaram-se e foram-se.',
        'E não dizem no seu coração: Temamos agora ao Senhor nosso Deus, que dá chuva, a temporã e a tardia, ao seu tempo; e nos conserva as semanas determinadas da sega.',
        'As vossas iniqüidades desviam estas coisas, e os vossos pecados apartam de vós o bem.',
        'Porque ímpios se acham entre o meu povo; andam espiando, como quem arma laços; põem armadilhas, com que prendem os homens.',
        'Como uma gaiola está cheia de pássaros, assim as suas casas estão cheias de engano; por isso se engrandeceram, e enriqueceram;',
        'Engordam-se, estão nédios, e ultrapassam até os feitos dos malignos; não julgam a causa do órfão; todavia prosperam; nem julgam o direito dos necessitados.',
        'Porventura não castigaria eu por causa destas coisas? diz o Senhor; não me vingaria eu de uma nação como esta?',
        'Coisa espantosa e horrenda se anda fazendo na terra.',
        'Os profetas profetizam falsamente, e os sacerdotes dominam pelas mãos deles, e o meu povo assim o deseja; mas que fareis ao fim disto?',
      ],
      [
        'Fugi para salvação vossa, filhos de Benjamim, do meio de Jerusalém; e tocai a buzina em Tecoa, e levantai um sinal de fogo sobre Bete-Haquerém; porque do lado norte surge um mal e uma grande destruição.',
        'À formosa e delicada assemelhei a filha de Sião.',
        'Mas contra ela virão pastores com os seus rebanhos; levantarão contra ela tendas em redor, e cada um apascentará no seu lugar.',
        'Preparai a guerra contra ela, levantai-vos, e subamos ao pino do meio-dia. Ai de nós! Já declina o dia, já se vão estendendo as sombras da tarde.',
        'Levantai-vos, e subamos de noite, e destruamos os seus palácios.',
        'Porque assim diz o SENHOR dos Exércitos: Cortai árvores, e levantai trincheiras contra Jerusalém; esta é a cidade que há de ser castigada, só opressão há no meio dela.',
        'Como a fonte produz as suas águas, assim ela produz a sua malícia; violência e estrago se ouvem nela; enfermidade e feridas há diante de mim continuamente.',
        'Corrige-te, ó Jerusalém, para que a minha alma não se aparte de ti, para que não te torne em assolação e terra não habitada.',
        'Assim diz o Senhor dos Exércitos: Diligentemente respigarão os resíduos de Israel como uma vinha; torna a tua mão, como o vindimador, aos cestos.',
        'A quem falarei e testemunharei, para que ouça? Eis que os seus ouvidos estão incircuncisos, e não podem ouvir; eis que a palavra do Senhor é para eles coisa vergonhosa, e não gostam dela.',
        'Por isso estou cheio do furor do Senhor; estou cansado de o conter; derramá-lo-ei sobre os meninos pelas ruas e na reunião de todos os jovens; porque até o marido com a mulher serão presos, e o velho com o que está cheio de dias.',
        'E as suas casas passarão a outros, como também as suas herdades e as suas mulheres juntamente; porque estenderei a minha mão contra os habitantes desta terra, diz o Senhor.',
        'Porque desde o menor deles até ao maior, cada um se dá à avareza; e desde o profeta até ao sacerdote, cada um usa de falsidade.',
        'E curam superficialmente a ferida da filha do meu povo, dizendo: Paz, paz; quando não há paz.',
        'Porventura envergonham-se de cometer abominação? Pelo contrário, de maneira nenhuma se envergonham, nem tampouco sabem que coisa é envergonhar-se; portanto cairão entre os que caem; no tempo em que eu os visitar, tropeçarão, diz o Senhor.',
        'Assim diz o Senhor: Ponde-vos nos caminhos, e vede, e perguntai pelas veredas antigas, qual é o bom caminho, e andai por ele; e achareis descanso para as vossas almas; mas eles dizem: Não andaremos nele.',
        'Também pus atalaias sobre vós, dizendo: Estai atentos ao som da trombeta; mas dizem: Não escutaremos.',
        'Portanto ouvi, vós, nações; e informa-te tu, ó congregação, do que se faz entre eles!',
        'Ouve tu, ó terra! Eis que eu trarei mal sobre este povo, o próprio fruto dos seus pensamentos; porque não estão atentos às minhas palavras, e rejeitam a minha lei.',
        'Para que, pois, me vem o incenso de Sabá e a melhor cana aromática de terras remotas? Vossos holocaustos não me agradam, nem me são suaves os vossos sacrifícios.',
        'Portanto assim diz o Senhor: Eis que armarei tropeços a este povo; e tropeçarão neles pais e filhos juntamente; o vizinho e o seu companheiro perecerão.',
        'Assim diz o Senhor: Eis que um povo vem da terra do norte, e uma grande nação se levantará das extremidades da terra.',
        'Arco e lança trarão; são cruéis, e não usarão de misericórdia; a sua voz rugirá como o mar, e em cavalos virão montados, dispostos como homens de guerra contra ti, ó filha de Sião.',
        'Ouvimos a sua fama, afrouxaram-se as nossas mãos; angústia nos tomou, e dores como as de parturiente.',
        'Não saiais ao campo, nem andeis pelo caminho; porque espada do inimigo e espanto há ao redor.',
        'Ó filha do meu povo, cinge-te de saco, e revolve-te na cinza; pranteia como por um filho único, pranto de amargura; porque de repente virá o destruidor sobre nós.',
        'Por torre de guarda te pus entre o meu povo, por fortaleza, para que soubesses e examinasses o seu caminho.',
        'Todos eles são os mais rebeldes, andam murmurando; são duros como bronze e ferro; todos eles são corruptores.',
        'Já o fole se queimou, o chumbo se consumiu com o fogo; em vão fundiu o fundidor tão diligentemente, pois os maus não são arrancados.',
        'Prata rejeitada lhes chamarão, porque o Senhor os rejeitou.',
      ],
      [
        'A palavra que da parte do SENHOR, veio a Jeremias, dizendo:',
        'Põe-te à porta da casa do Senhor, e proclama ali esta palavra, e dize: Ouvi a palavra do Senhor, todos de Judá, os que entrais por estas portas, para adorardes ao Senhor.',
        'Assim diz o Senhor dos Exércitos, o Deus de Israel: Melhorai os vossos caminhos e as vossas obras, e vos farei habitar neste lugar.',
        'Não vos fieis em palavras falsas, dizendo: Templo do Senhor, templo do Senhor, templo do Senhor é este.',
        'Mas, se deveras melhorardes os vossos caminhos e as vossas obras; se deveras praticardes o juízo entre um homem e o seu próximo;',
        'Se não oprimirdes o estrangeiro, e o órfão, e a viúva, nem derramardes sangue inocente neste lugar, nem andardes após outros deuses para vosso próprio mal,',
        'Eu vos farei habitar neste lugar, na terra que dei a vossos pais, desde os tempos antigos e para sempre.',
        'Eis que vós confiais em palavras falsas, que para nada vos aproveitam.',
        'Porventura furtareis, e matareis, e adulterareis, e jurareis falsamente, e queimareis incenso a Baal, e andareis após outros deuses que não conhecestes,',
        'E então vireis, e vos poreis diante de mim nesta casa, que se chama pelo meu nome, e direis: Fomos libertados para fazermos todas estas abominações?',
        'É pois esta casa, que se chama pelo meu nome, uma caverna de salteadores aos vossos olhos? Eis que eu, eu mesmo, vi isto, diz o Senhor.',
        'Mas ide agora ao meu lugar, que estava em Siló, onde, ao princípio, fiz habitar o meu nome, e vede o que lhe fiz, por causa da maldade do meu povo Israel.',
        'Agora, pois, porquanto fazeis todas estas obras, diz o Senhor, e eu vos falei, madrugando, e falando, e não ouvistes, e chamei-vos, e não respondestes,',
        'Farei também a esta casa, que se chama pelo meu nome, na qual confiais, e a este lugar, que vos dei a vós e a vossos pais, como fiz a Siló.',
        'E lançar-vos-ei de diante de minha face, como lancei a todos os vossos irmãos, a toda a geração de Efraim.',
        'Tu, pois, não ores por este povo, nem levantes por ele clamor ou oração, nem me supliques, porque eu não te ouvirei.',
        'Porventura não vês tu o que andam fazendo nas cidades de Judá, e nas ruas de Jerusalém?',
        'Os filhos apanham a lenha, e os pais acendem o fogo, e as mulheres preparam a massa, para fazerem bolos à rainha dos céus, e oferecem libações a outros deuses, para me provocarem à ira.',
        'Acaso é a mim que eles provocam à ira? diz o Senhor, e não a si mesmos, para confusão dos seus rostos?',
        'Portanto assim diz o Senhor DEUS: Eis que a minha ira e o meu furor se derramarão sobre este lugar, sobre os homens e sobre os animais, e sobre as árvores do campo, e sobre os frutos da terra; e acender-se-á, e não se apagará.',
        'Assim diz o Senhor dos Exércitos, o Deus de Israel: Ajuntai os vossos holocaustos aos vossos sacrifícios, e comei carne.',
        'Porque nunca falei a vossos pais, no dia em que os tirei da terra do Egito, nem lhes ordenei coisa alguma acerca de holocaustos ou sacrifícios.',
        'Mas isto lhes ordenei, dizendo: Dai ouvidos à minha voz, e eu serei o vosso Deus, e vós sereis o meu povo; e andai em todo o caminho que eu vos mandar, para que vos vá bem.',
        'Mas não ouviram, nem inclinaram os seus ouvidos, mas andaram nos seus próprios conselhos, no propósito do seu coração malvado; e andaram para trás, e não para diante.',
        'Desde o dia em que vossos pais saíram da terra do Egito, até hoje, enviei-vos todos os meus servos, os profetas, todos os dias madrugando e enviando-os.',
        'Mas não me deram ouvidos, nem inclinaram os seus ouvidos, mas endureceram a sua cerviz, e fizeram pior do que seus pais.',
        'Dir-lhes-ás, pois, todas estas palavras, mas não te darão ouvidos; chamá-los-ás, mas não te responderão.',
        'E lhes dirás: Esta é a nação que não deu ouvidos à voz do Senhor seu Deus e não aceitou a correção; já pereceu a verdade, e foi cortada da sua boca.',
        'Corta o teu cabelo e lança-o de ti, e levanta um pranto sobre as alturas; porque já o Senhor rejeitou e desamparou a geração do seu furor.',
        'Porque os filhos de Judá fizeram o que era mau aos meus olhos, diz o Senhor; puseram as suas abominações na casa que se chama pelo meu nome, para contaminá-la.',
        'E edificaram os altos de Tofete, que está no Vale do Filho de Hinom, para queimarem no fogo a seus filhos e a suas filhas, o que nunca ordenei, nem me subiu ao coração.',
        'Portanto, eis que vêm dias, diz o Senhor, em que não se chamará mais Tofete, nem Vale do Filho de Hinom, mas o Vale da Matança; e enterrarão em Tofete, por não haver outro lugar.',
        'E os cadáveres deste povo servirão de pasto às aves dos céus e aos animais da terra; e ninguém os espantará.',
        'E farei cessar nas cidades de Judá, e nas ruas de Jerusalém, a voz de gozo, e a voz de alegria, a voz de esposo e a voz de esposa; porque a terra se tornará em desolação.',
      ],
      [
        'Naquele tempo, diz o SENHOR, tirarão para fora das suas sepulturas os ossos dos reis de Judá, e os ossos dos seus príncipes, e os ossos dos sacerdotes, e os ossos dos profetas, e os ossos dos habitantes de Jerusalém;',
        'E expô-los-ão ao sol, e à lua, e a todo o exército do céu, a quem tinham amado, e a quem tinham servido, e após quem tinham ido, e a quem tinham buscado e diante de quem se tinham prostrado; não serão recolhidos nem sepultados; serão como esterco sobre a face da terra.',
        'E será escolhida antes a morte do que a vida por todos os que restarem desta raça maligna, que ficarem em todos os lugares onde os lancei, diz o Senhor dos Exércitos.',
        'Dize-lhes mais: Assim diz o Senhor: Porventura cairão e não se tornarão a levantar? Desviar-se-ão, e não voltarão?',
        'Por que, pois, se desvia este povo de Jerusalém com uma apostasia tão contínua? Persiste no engano, não quer voltar.',
        'Eu escutei e ouvi; não falam o que é reto, ninguém há que se arrependa da sua maldade, dizendo: Que fiz eu? Cada um se desvia na sua carreira, como um cavalo que arremete com ímpeto na batalha.',
        'Até a cegonha no céu conhece os seus tempos determinados; e a rola, e o grou e a andorinha observam o tempo da sua arribação; mas o meu povo não conhece o juízo do Senhor.',
        'Como, pois, dizeis: Nós somos sábios, e a lei do Senhor está conosco? Eis que em vão tem trabalhado a falsa pena dos escribas.',
        'Os sábios são envergonhados, espantados e presos; eis que rejeitaram a palavra do Senhor; que sabedoria, pois, têm eles?',
        'Portanto darei suas mulheres a outros, e os seus campos a novos possuidores; porque desde o menor até ao maior, cada um deles se dá à avareza; desde o profeta até ao sacerdote, cada um deles usa de falsidade.',
        'E curam a ferida da filha de meu povo levianamente, dizendo: Paz, paz; quando não há paz.',
        'Porventura envergonham-se de cometerem abominação? Não; de maneira nenhuma se envergonham, nem sabem que coisa é envergonhar-se; portanto cairão entre os que caem e tropeçarão no tempo em que eu os visitar, diz o Senhor.',
        'Certamente os apanharei, diz o Senhor; já não há uvas na vide, nem figos na figueira, e até a folha caiu; e o que lhes dei passará deles.',
        'Por que nos assentamos ainda? Juntai-vos e entremos nas cidades fortificadas, e ali pereçamos; pois já o Senhor nosso Deus nos destinou a perecer e nos deu a beber água de fel; porquanto pecamos contra o Senhor.',
        'Espera-se a paz, mas não há bem; o tempo da cura, e eis o terror.',
        'Já desde Dã se ouve o resfolegar dos seus cavalos, toda a terra treme ao som dos rinchos dos seus fortes; e vêm, e devoram a terra, e sua abundância, a cidade e os que habitam nela.',
        'Porque eis que envio entre vós serpentes e basiliscos, contra os quais não há encantamento, e vos morderão, diz o Senhor.',
        'Oh! se eu pudesse consolar-me na minha tristeza! O meu coração desfalece em mim.',
        'Eis a voz do clamor da filha do meu povo de terra mui remota; não está o Senhor em Sião? Não está nela o seu rei? Por que me provocaram à ira com as suas imagens de escultura, com vaidades estranhas?',
        'Passou a sega, findou o verão, e nós não estamos salvos.',
        'Estou quebrantado pela ferida da filha do meu povo; ando de luto; o espanto se apoderou de mim.',
        'Porventura não há bálsamo em Gileade? Ou não há lá médico? Por que, pois, não se realizou a cura da filha do meu povo?',
      ],
      [
        'Oh! se a minha cabeça se tornasse em águas, e os meus olhos numa fonte de lágrimas! Então choraria de dia e de noite os mortos da filha do meu povo.',
        'Oh! se tivesse no deserto uma estalagem de caminhantes! Então deixaria o meu povo, e me apartaria dele, porque todos eles são adúlteros, um bando de aleivosos.',
        'E encurvam a língua como se fosse o seu arco, para a mentira; fortalecem-se na terra, mas não para a verdade; porque avançam de malícia em malícia, e a mim não me conhecem, diz o Senhor.',
        'Guardai-vos cada um do seu próximo, e de irmão nenhum vos fieis; porque todo o irmão não faz mais do que enganar, e todo o próximo anda caluniando.',
        'E zombará cada um do seu próximo, e não falam a verdade; ensinam a sua língua a falar a mentira, andam-se cansando em proceder perversamente.',
        'A tua habitação está no meio do engano; pelo engano recusam conhecer-me, diz o Senhor.',
        'Portanto assim diz o Senhor dos Exércitos: Eis que eu os fundirei e os provarei; pois, de que outra maneira procederia com a filha do meu povo?',
        'Uma flecha mortífera é a língua deles; fala engano; com a sua boca fala cada um de paz com o seu próximo mas no seu coração arma-lhe ciladas.',
        'Porventura por estas coisas não os castigaria? diz o Senhor; ou não se vingaria a minha alma de nação tal como esta?',
        'Pelos montes levantarei choro e pranto, e pelas pastagens do deserto lamentação; porque já estão queimadas, e ninguém passa por elas; nem se ouve mugido de gado; desde as aves dos céus, até os animais, andaram vagueando, e fugiram.',
        'E farei de Jerusalém montões de pedras, morada de chacais, e das cidades de Judá farei assolação, de sorte que não haja habitante.',
        'Quem é o homem sábio, que entenda isto? e a quem falou a boca do Senhor, para que o possa anunciar? Por que razão pereceu a terra, e se queimou como deserto, sem que ninguém passa por ela?',
        'E disse o Senhor: Porque deixaram a minha lei, que pus perante eles, e não deram ouvidos à minha voz, nem andaram nela,',
        'Antes andaram após o propósito do seu próprio coração, e após os baalins, como lhes ensinaram os seus pais.',
        'Portanto assim diz o Senhor dos Exércitos, Deus de Israel: Eis que darei de comer losna a este povo, e lhe darei a beber água de fel.',
        'E os espalharei entre gentios, que não conheceram, nem eles nem seus pais, e mandarei a espada após eles, até que venha a consumi-los.',
        'Assim diz o Senhor dos Exércitos: Considerai, e chamai carpideiras que venham; e mandai procurar mulheres hábeis, para que venham.',
        'E se apressem, e levantem o seu lamento sobre nós; e desfaçam-se em lágrimas os nossos olhos, e as nossas pálpebras destilem águas.',
        'Porque uma voz de pranto se ouviu de Sião: Como estamos arruinados! Estamos mui envergonhados, porque deixamos a terra, e por terem eles lançado fora as nossas moradas.',
        'Ouvi, pois, vós, mulheres, a palavra do Senhor, e os vossos ouvidos recebam a palavra da sua boca; e ensinai o pranto a vossas filhas, e cada uma à sua vizinha a lamentação;',
        'Porque a morte subiu pelas nossas janelas, e entrou em nossos palácios, para exterminar as crianças das ruas e os jovens das praças.',
        'Fala: Assim diz o Senhor: Até os cadáveres dos homens jazerão como esterco sobre a face do campo, e como gavela atrás do segador, e não há quem a recolha.',
        'Assim diz o Senhor: Não se glorie o sábio na sua sabedoria, nem se glorie o forte na sua força; não se glorie o rico nas suas riquezas,',
        'Mas o que se gloriar, glorie-se nisto: em me entender e me conhecer, que eu sou o Senhor, que faço beneficência, juízo e justiça na terra; porque destas coisas me agrado, diz o Senhor.',
        'Eis que vêm dias, diz o Senhor, em que castigarei a todo o circuncidado com o incircunciso.',
        'Ao Egito, e a Judá, e a Edom, e aos filhos de Amom, e a Moabe, e a todos os que cortam os cantos do seu cabelo, que habitam no deserto; porque todas as nações são incircuncisas, e toda a casa de Israel é incircuncisa de coração.',
      ],
      [
        'Ouvi a palavra que o SENHOR vos fala a vós, ó casa de Israel.',
        'Assim diz o Senhor: Não aprendais o caminho dos gentios, nem vos espanteis dos sinais dos céus; porque com eles se atemorizam as nações.',
        'Porque os costumes dos povos são vaidade; pois corta-se do bosque um madeiro, obra das mãos do artífice, feita com machado;',
        'Com prata e com ouro o enfeitam, com pregos e com martelos o firmam, para que não se mova.',
        'São como a palmeira, obra torneada, porém não podem falar; certamente são levados, porquanto não podem andar. Não tenhais receio deles, pois não podem fazer mal, nem tampouco têm poder de fazer bem.',
        'Ninguém há semelhante a ti, ó Senhor; tu és grande, e grande o teu nome em poder.',
        'Quem não te temeria a ti, ó Rei das nações? Pois isto só a ti pertence; porquanto entre todos os sábios das nações, e em todo o seu reino, ninguém há semelhante a ti.',
        'Mas eles todos se embruteceram e tornaram-se loucos; ensino de vaidade é o madeiro.',
        'Trazem prata batida de Társis e ouro de Ufaz, trabalho do artífice, e das mãos do fundidor; fazem suas roupas de azul e púrpura; obra de peritos são todos eles.',
        'Mas o Senhor Deus é a verdade; ele mesmo é o Deus vivo e o Rei eterno; ao seu furor treme a terra, e as nações não podem suportar a sua indignação.',
        'Assim lhes direis: Os deuses que não fizeram os céus e a terra desaparecerão da terra e de debaixo deste céu.',
        'Ele fez a terra com o seu poder; ele estabeleceu o mundo com a sua sabedoria, e com a sua inteligência estendeu os céus.',
        'Fazendo ele soar a sua voz, logo há rumor de águas no céu, e faz subir os vapores da extremidade da terra; faz os relâmpagos para a chuva, e dos seus tesouros faz sair o vento.',
        'Todo o homem é embrutecido no seu conhecimento; envergonha-se todo o fundidor da sua imagem de escultura; porque sua imagem fundida é mentira, e nelas não há espírito.',
        'Vaidade são, obra de enganos: no tempo da sua visitação virão a perecer.',
        'Não é semelhante a estes aquele que é a porção de Jacó; porque ele é o que formou tudo, e Israel é a vara da sua herança: Senhor dos Exércitos é o seu nome.',
        'Ajunta da terra a tua mercadoria, ó tu que habitas em lugar sitiado.',
        'Porque assim diz o Senhor: Eis que desta vez arrojarei como se fora com uma funda aos moradores da terra, e os angustiarei, para que venham a achá-lo, dizendo:',
        'Ai de mim por causa do meu quebrantamento! A minha chaga me causa grande dor; e eu havia dito: Certamente isto é enfermidade que eu poderei suportar.',
        'A minha tenda está destruída, e todas as minhas cordas se romperam; os meus filhos foram-se de mim, e não existem; ninguém há mais que estenda a minha tenda, nem que levante as minhas cortinas,',
        'Porque os pastores se embruteceram, e não buscaram ao Senhor; por isso não prosperaram, e todos os seus rebanhos se espalharam.',
        'Eis que vem uma voz de rumor, grande tremor da terra do norte, para fazer das cidades de Judá uma assolação, uma morada de chacais.',
        'Eu sei, ó Senhor, que não é do homem o seu caminho; nem do homem que caminha o dirigir os seus passos.',
        'Castiga-me, ó Senhor, porém com juízo, não na tua ira, para que não me reduzas a nada.',
        'Derrama a tua indignação sobre os gentios que não te conhecem, e sobre as gerações que não invocam o teu nome; porque devoraram a Jacó, e devoraram-no e consumiram-no, e assolaram a sua morada.',
      ],
      [
        'A palavra que veio a Jeremias, da parte do SENHOR, dizendo:',
        'Ouvi as palavras desta aliança, e falai aos homens de Judá, e aos habitantes de Jerusalém.',
        'Dize-lhes pois: Assim diz o Senhor Deus de Israel: Maldito o homem que não escutar as palavras desta aliança,',
        'Que ordenei a vossos pais no dia em que os tirei da terra do Egito, da fornalha de ferro, dizendo: Dai ouvidos à minha voz, e fazei conforme a tudo quanto vos mando; e vós sereis o meu povo, e eu serei o vosso Deus.',
        'Para que confirme o juramento que fiz a vossos pais de dar-lhes uma terra que manasse leite e mel, como se vê neste dia. Então eu respondi, e disse: Amém, ó Senhor.',
        'E disse-me o Senhor: Apregoa todas estas palavras nas cidades de Judá, e nas ruas de Jerusalém, dizendo: Ouvi as palavras desta aliança, e cumpri-as.',
        'Porque deveras adverti a vossos pais, no dia em que os tirei da terra do Egito, até ao dia de hoje, madrugando, e protestando, e dizendo: Dai ouvidos à minha voz.',
        'Mas não ouviram, nem inclinaram os seus ouvidos, antes andaram cada um conforme o propósito do seu coração malvado; por isso trouxe sobre eles todas as palavras desta aliança que lhes mandei que cumprissem, porém não cumpriram.',
        'Disse-me mais o Senhor: Uma conspiração se achou entre os homens de Judá, entre os habitantes de Jerusalém.',
        'Tornaram às maldades de seus primeiros pais, que não quiseram ouvir as minhas palavras; e eles andaram após outros deuses para os servir; a casa de Israel e a casa de Judá quebraram a minha aliança, que tinha feito com seus pais.',
        'Portanto assim diz o Senhor: Eis que trarei mal sobre eles, de que não poderão escapar; e clamarão a mim, mas eu não os ouvirei.',
        'Então irão as cidades de Judá e os habitantes de Jerusalém e clamarão aos deuses a quem eles queimaram incenso; estes, porém, de nenhum modo os livrarão no tempo do seu mal.',
        'Porque, segundo o número das tuas cidades, são os teus deuses, ó Judá! E, segundo o número das ruas de Jerusalém, levantastes altares à impudência, altares para queimardes incenso a Baal.',
        'Tu, pois, não ores por este povo, nem levantes por ele clamor nem oração; porque não os ouvirei no tempo em que eles clamarem a mim, por causa do seu mal.',
        'Que direito tem a minha amada na minha casa, visto que com muitos tem cometido grande lascívia? Crês que os sacrifícios e as carnes santificadas poderão afastar de ti o mal? Então saltarias de prazer.',
        'Denominou-te o Senhor oliveira verde, formosa por seus deliciosos frutos, mas agora à voz de um grande tumulto acendeu fogo ao redor dela e se quebraram os seus ramos.',
        'Porque o Senhor dos Exércitos, que te plantou, pronunciou contra ti o mal, pela maldade da casa de Israel e da casa de Judá, que para si mesma fizeram, pois me provocaram à ira, queimando incenso a Baal.',
        'E o Senhor me fez saber, e assim o soube; então me fizeste ver as suas ações.',
        'E eu era como um cordeiro, como um boi que levam à matança; porque não sabia que maquinavam propósitos contra mim, dizendo: Destruamos a árvore com o seu fruto, e cortemo-lo da terra dos viventes, e não haja mais memória do seu nome.',
        'Mas, ó Senhor dos Exércitos, justo Juiz, que provas os rins e o coração, veja eu a tua vingança sobre eles; pois a ti descobri a minha causa.',
        'Portanto, assim diz o Senhor acerca dos homens de Anatote, que buscam a tua vida, dizendo: Não profetizes no nome do Senhor, para que não morras às nossas mãos.',
        'Portanto, assim diz o Senhor dos Exércitos: Eis que eu os castigarei; os jovens morrerão à espada, os seus filhos e suas filhas morrerão de fome.',
        'E não haverá deles um remanescente, porque farei vir o mal sobre os homens de Anatote, no ano da sua visitação.',
      ],
      [
        'Justo serias, ó SENHOR, ainda que eu entrasse contigo num pleito; contudo falarei contigo dos teus juízos. Por que prospera o caminho dos ímpios, e vivem em paz todos os que procedem aleivosamente?',
        'Plantaste-os, e eles se arraigaram; crescem, dão também fruto; chegado estás à sua boca, porém longe dos seus rins.',
        'Mas tu, ó Senhor, me conheces, tu me vês, e provas o meu coração para contigo; arranca-os como as ovelhas para o matadouro, e dedica-os para o dia da matança.',
        'Até quando lamentará a terra, e se secará a erva de todo o campo? Pela maldade dos que habitam nela, perecem os animais e as aves; porquanto dizem: Ele não verá o nosso fim.',
        'Se te fatigas correndo com homens que vão a pé, como poderás competir com os cavalos? Se tão-somente numa terra de paz estás confiado, como farás na enchente do Jordão?',
        'Porque até os teus irmãos, e a casa de teu pai, eles próprios procedem deslealmente contigo; eles mesmos clamam após ti em altas vozes: Não te fies neles, ainda que te digam coisas boas.',
        'Desamparei a minha casa, abandonei a minha herança; entreguei a amada da minha alma na mão de seus inimigos.',
        'Tornou-se a minha herança para mim como leão numa floresta; levantou a sua voz contra mim, por isso eu a odiei.',
        'A minha herança é para mim ave de rapina de várias cores. Andam as aves de rapina contra ela em redor. Vinde, pois, ajuntai todos os animais do campo, trazei-os para a devorarem.',
        'Muitos pastores destruíram a minha vinha, pisaram o meu campo; tornaram em desolado deserto o meu campo desejado.',
        'Em desolação a puseram, e clama a mim na sua desolação; e toda a terra está desolada, porquanto não há ninguém que tome isso a sério.',
        'Sobre todos os lugares altos do deserto vieram destruidores; porque a espada do Senhor devora desde um extremo da terra até o outro; não há paz para nenhuma carne.',
        'Semearam trigo, e segaram espinhos; cansaram-se, mas de nada se aproveitaram; envergonhados sereis das vossas colheitas, e por causa do ardor da ira do Senhor.',
        'Assim diz o Senhor, acerca de todos os meus maus vizinhos, que tocam a minha herança, que fiz herdar ao meu povo Israel: Eis que os arrancarei da sua terra, e a casa de Judá arrancarei do meio deles.',
        'E será que, depois de os haver arrancado, tornarei, e me compadecerei deles, e os farei voltar cada um à sua herança, e cada um à sua terra.',
        'E será que, se diligentemente aprenderem os caminhos do meu povo, jurando pelo meu nome: Vive o Senhor, como ensinaram o meu povo a jurar por Baal; então edificar-se-ão no meio do meu povo.',
        'Mas se não quiserem ouvir, totalmente arrancarei a tal nação, e a farei perecer, diz o Senhor.',
      ],
      [
        'Assim me disse o SENHOR: Vai, e compra um cinto de linho e põe-no sobre os teus lombos, mas não o coloques na água.',
        'E comprei o cinto, conforme a palavra do Senhor, e o pus sobre os meus lombos.',
        'Então me veio a palavra do Senhor pela segunda vez, dizendo:',
        'Toma o cinto que compraste, e que trazes sobre os teus lombos, e levanta-te; vai ao Eufrates, e esconde-o ali na fenda de uma rocha.',
        'E fui, e escondi-o junto ao Eufrates, como o Senhor me havia ordenado.',
        'Sucedeu, ao final de muitos dias, que me disse o Senhor: Levanta-te, vai ao Eufrates, e toma dali o cinto que te ordenei que o escondesses ali.',
        'E fui ao Eufrates, e cavei, e tomei o cinto do lugar onde o havia escondido; e eis que o cinto tinha apodrecido, e para nada prestava.',
        'Então veio a mim a palavra do Senhor, dizendo:',
        'Assim diz o Senhor: Do mesmo modo farei apodrecer a soberba de Judá, e a muita soberba de Jerusalém.',
        'Este povo maligno, que recusa ouvir as minhas palavras, que caminha segundo a dureza do seu coração, e anda após deuses alheios, para servi-los, e inclinar-se diante deles, será tal como este cinto, que para nada presta.',
        'Porque, como o cinto está pegado aos lombos do homem, assim eu liguei a mim toda a casa de Israel, e toda a casa de Judá, diz o Senhor, para me serem por povo, e por nome, e por louvor, e por glória; mas não deram ouvidos.',
        'Portanto, dize-lhes esta palavra: Assim diz o Senhor Deus de Israel: Todo o odre se encherá de vinho; e dir-te-ão: Porventura não sabemos nós muito bem que todo o odre se encherá de vinho?',
        'Mas tu dize-lhes: Assim diz o Senhor: Eis que eu encherei de embriaguez a todos os habitantes desta terra, e aos reis da estirpe de Davi, que estão assentados sobre o seu trono, e aos sacerdotes, e aos profetas, e a todos os habitantes de Jerusalém.',
        'E fá-los-ei em pedaços atirando uns contra os outros, e juntamente os pais com os filhos, diz o Senhor; não perdoarei, nem pouparei, nem terei deles compaixão, para que não os destrua.',
        'Escutai, e inclinai os ouvidos; não vos ensoberbeçais; porque o Senhor falou:',
        'Dai glória ao Senhor vosso Deus, antes que venha a escuridão e antes que tropecem vossos pés nos montes tenebrosos; antes que, esperando vós luz, ele a mude em sombra de morte, e a reduza à escuridão.',
        'E, se isto não ouvirdes, a minha alma chorará em lugares ocultos, por causa da vossa soberba; e amargamente chorarão os meus olhos, e se desfarão em lágrimas, porquanto o rebanho do Senhor foi levado cativo.',
        'Dize ao rei e à rainha: Humilhai-vos, e assentai-vos no chão; porque já caiu todo o ornato de vossas cabeças, a coroa da vossa glória.',
        'As cidades do sul estão fechadas, e ninguém há que as abra; todo o Judá foi levado cativo, sim, inteiramente cativo.',
        'Levantai os vossos olhos, e vede os que vêm do norte; onde está o rebanho que se te deu, o rebanho da tua glória?',
        'Que dirás, quando ele te castigar porque os ensinaste a serem capitães, e chefe sobre ti? Porventura não te tomarão as dores, como à mulher que está de parto?',
        'Quando, pois, disseres no teu coração: Por que me sobrevieram estas coisas? Pela multidão das tuas maldades se descobriram as tuas fraldas, e os teus calcanhares sofrem violência.',
        'Porventura pode o etíope mudar a sua pele, ou o leopardo as suas manchas? Então podereis vós fazer o bem, sendo ensinados a fazer o mal.',
        'Assim os espalharei como o restolho, que passa com o vento do deserto.',
        'Esta será a tua sorte, a porção que te será medida por mim, diz o Senhor; pois te esqueceste de mim, e confiaste em mentiras.',
        'Assim também eu levantarei as tuas fraldas sobre o teu rosto; e aparecerá a tua ignomínia.',
        'Já vi as tuas abominações, e os teus adultérios, e os teus rinchos, e a enormidade da tua prostituição sobre os outeiros no campo; ai de ti, Jerusalém! Até quando ainda não te purificarás?',
      ],
      [
        'A palavra do SENHOR, que veio a Jeremias, a respeito da grande seca.',
        'Anda chorando Judá, e as suas portas estão enfraquecidas; andam de luto até ao chão, e o clamor de Jerusalém vai subindo.',
        'E os seus mais ilustres enviam os seus pequenos a buscar água; vão às cisternas, e não acham água; voltam com os seus cântaros vazios; envergonham-se e confundem-se, e cobrem as suas cabeças.',
        'Por causa da terra que se fendeu, porque não há chuva sobre a terra, os lavradores se envergonham e cobrem as suas cabeças.',
        'Porque até as cervas no campo têm as suas crias, e abandonam seus filhos, porquanto não há erva.',
        'E os jumentos monteses se põem nos lugares altos, sorvem o vento como os chacais; desfalecem os seus olhos, porquanto não há erva.',
        'Posto que as nossas maldades testificam contra nós, ó Senhor, age por amor do teu nome; porque as nossas rebeldias se multiplicaram; contra ti pecamos.',
        'Ó esperança de Israel, e Redentor seu no tempo da angústia, por que serias como um estrangeiro na terra e como o viandante que se retira a passar a noite?',
        'Por que serias como homem surpreendido, como poderoso que não pode livrar? Mas tu estás no meio de nós, ó Senhor, e nós somos chamados pelo teu nome; não nos desampares.',
        'Assim diz o Senhor, acerca deste povo: Pois que tanto gostaram de andar errantes, e não retiveram os seus pés, por isso o Senhor não se agrada deles, mas agora se lembrará da iniqüidade deles, e visitará os seus pecados.',
        'Disse-me mais o Senhor: Não rogues por este povo para seu bem.',
        'Quando jejuarem, não ouvirei o seu clamor, e quando oferecerem holocaustos e ofertas de alimentos, não me agradarei deles; antes eu os consumirei pela espada, e pela fome e pela peste.',
        'Então disse eu: Ah! Senhor DEUS, eis que os profetas lhes dizem: Não vereis espada, e não tereis fome; antes vos darei paz verdadeira neste lugar.',
        'E disse-me o Senhor: Os profetas profetizam falsamente no meu nome; nunca os enviei, nem lhes dei ordem, nem lhes falei; visão falsa, e adivinhação, e vaidade, e o engano do seu coração é o que eles vos profetizam.',
        'Portanto assim diz o Senhor acerca dos profetas que profetizam no meu nome, sem que eu os tenha mandado, e que dizem: Nem espada, nem fome haverá nesta terra: À espada e à fome, serão consumidos esses profetas.',
        'E o povo a quem eles profetizam será lançado nas ruas de Jerusalém, por causa da fome e da espada; e não haverá quem os sepultem, tanto a eles, como as suas mulheres, e os seus filhos e as suas filhas; porque derramarei sobre eles a sua maldade.',
        'Portanto lhes dirás esta palavra: Os meus olhos derramem lágrimas de noite e de dia, e não cessem; porque a virgem, filha do meu povo, está gravemente ferida, de chaga mui dolorosa.',
        'Se eu saio ao campo, eis ali os mortos à espada, e, se entro na cidade, estão ali os debilitados pela fome; e até os profetas e os sacerdotes percorrem uma terra, que não conhecem.',
        'Porventura já de todo rejeitaste a Judá? Ou repugna a tua alma a Sião? Por que nos feriste de tal modo que já não há cura para nós? Aguardamos a paz, e não aparece o bem; e o tempo da cura, e eis aqui turbação.',
        'Ah! Senhor! conhecemos a nossa impiedade e a maldade de nossos pais; porque pecamos contra ti.',
        'Não nos rejeites por amor do teu nome; não abatas o trono da tua glória; lembra-te, e não anules a tua aliança conosco.',
        'Porventura há, entre as vaidades dos gentios, alguém que faça chover? Ou podem os céus dar chuvas? Não és tu, ó Senhor nosso Deus? Portanto em ti esperamos, pois tu fazes todas estas coisas.',
      ],
      [
        'Disse-me, porém, o SENHOR: Ainda que Moisés e Samuel se pusessem diante de mim, não estaria a minha alma com este povo; lança-os de diante da minha face, e saiam.',
        'E será que, quando te disserem: Para onde iremos? Dir-lhes-ás: Assim diz o Senhor: Os que para a morte, para a morte, e os que para a espada, para a espada; e os que para a fome, para a fome; e os que para o cativeiro, para o cativeiro.',
        'Porque visitá-los-ei com quatro gêneros de males, diz o Senhor: com espada para matar, e com cães, para os arrastarem, e com aves dos céus, e com animais da terra, para os devorarem e destruírem.',
        'Entregá-los-ei ao desterro em todos os reinos da terra; por causa de Manassés, filho de Ezequias, rei de Judá, e por tudo quanto fez em Jerusalém.',
        'Porque quem se compadeceria de ti, ó Jerusalém? Ou quem se entristeceria por ti? Ou quem se desviaria a perguntar pela tua paz?',
        'Tu me deixaste, diz o Senhor, e tornaste-te para trás; por isso estenderei a minha mão contra ti, e te destruirei; já estou cansado de me arrepender.',
        'E padejá-los-ei com a pá nas portas da terra; já desfilhei, e destruí o meu povo; não voltaram dos seus caminhos.',
        'As suas viúvas mais se multiplicaram do que a areia dos mares; trouxe ao meio-dia um destruidor sobre a mãe dos jovens; fiz que caísse de repente sobre ela, e enchesse a cidade de terrores.',
        'A que dava à luz sete se enfraqueceu; expirou a sua alma; pôs-se-lhe o sol sendo ainda de dia, confundiu-se, e envergonhou-se; e os que ficarem dela entregarei à espada, diante dos seus inimigos, diz o Senhor.',
        'Ai de mim, minha mãe, por que me deste à luz homem de rixa e homem de contendas para toda a terra? Nunca lhes emprestei com usura, nem eles me emprestaram com usura, todavia cada um deles me amaldiçoa.',
        'Disse o Senhor: De certo que o teu remanescente será para o bem; de certo, no tempo da calamidade, e no tempo da angústia, farei que o inimigo te dirija súplicas.',
        'Pode alguém quebrar o ferro, o ferro do norte, ou o aço?',
        'As tuas riquezas e os teus tesouros entregarei sem preço ao saque; e isso por todos os teus pecados, mesmo em todos os teus limites.',
        'E te farei passar aos teus inimigos numa terra que não conheces; porque o fogo se acendeu em minha ira, e sobre vós arderá;',
        'Tu, ó Senhor, o sabes; lembra-te de mim, e visita-me, e vinga-me dos meus perseguidores; não me arrebates por tua longanimidade; sabe que por amor de ti tenho sofrido afronta.',
        'Achando-se as tuas palavras, logo as comi, e a tua palavra foi para mim o gozo e alegria do meu coração; porque pelo teu nome sou chamado, ó Senhor Deus dos Exércitos.',
        'Nunca me assentei na assembléia dos zombadores, nem me regozijei; por causa da tua mão me assentei solitário; pois me encheste de indignação.',
        'Por que dura a minha dor continuamente, e a minha ferida me dói, e já não admite cura? Serias tu para mim como coisa mentirosa e como águas inconstantes?',
        'Portanto assim diz o Senhor: Se tu voltares, então te trarei, e estarás diante de mim; e se apartares o precioso do vil, serás como a minha boca; tornem-se eles para ti, mas não voltes tu para eles.',
        'E eu te porei contra este povo como forte muro de bronze; e pelejarão contra ti, mas não prevalecerão contra ti; porque eu sou contigo para te guardar, para te livrar deles, diz o Senhor.',
        'E arrebatar-te-ei da mão dos malignos, e livrar-te-ei da mão dos fortes.',
      ],
      [
        'E veio a mim a palavra do SENHOR, dizendo:',
        'Não tomarás para ti mulher, nem terás filhos nem filhas neste lugar.',
        'Porque assim diz o Senhor, acerca dos filhos e das filhas que nascerem neste lugar, acerca de suas mães, que os tiverem, e de seus pais que os gerarem nesta terra:',
        'Morrerão de enfermidades dolorosas, e não serão pranteados nem sepultados; servirão de esterco sobre a face da terra; e pela espada e pela fome serão consumidos, e os seus cadáveres servirão de mantimento para as aves do céu e para os animais da terra.',
        'Porque assim diz o Senhor: Não entres na casa do luto, nem vás a lamentar, nem te compadeças deles; porque deste povo, diz o Senhor, retirei a minha paz, benignidade e misericórdia.',
        'E morrerão grandes e pequenos nesta terra, e não serão sepultados, e não os prantearão, nem se farão por eles incisões, nem por eles se raparão os cabelos.',
        'E não se partirá pão para consolá-los por causa de seus mortos; nem lhes darão a beber do copo de consolação, pelo pai ou pela mãe de alguém.',
        'Nem entres na casa do banquete, para te assentares com eles a comer e a beber.',
        'Porque assim diz o Senhor dos Exércitos, o Deus de Israel: Eis que farei cessar, neste lugar, perante os vossos olhos, e em vossos dias, a voz de gozo e a voz de alegria, a voz do esposo e a voz da esposa.',
        'E será que, quando anunciares a este povo todas estas palavras, e eles te disserem: Por que pronuncia o Senhor sobre nós todo este grande mal? E qual é a nossa iniqüidade, e qual é o nosso pecado, que cometemos contra o Senhor nosso Deus?',
        'Então lhes dirás: Porquanto vossos pais me deixaram, diz o Senhor, e se foram após outros deuses, e os serviram, e se inclinaram diante deles, e a mim me deixaram, e a minha lei não a guardaram.',
        'E vós fizestes pior do que vossos pais; porque, eis que cada um de vós anda segundo o propósito do seu mau coração, para não me dar ouvidos a mim.',
        'Portanto lançar-vos-ei fora desta terra, para uma terra que não conhecestes, nem vós nem vossos pais; e ali servireis a deuses alheios de dia e de noite, porque não usarei de misericórdia convosco.',
        'Portanto, eis que dias vêm, diz o Senhor, em que nunca mais se dirá: Vive o Senhor, que fez subir os filhos de Israel da terra do Egito.',
        'Mas: Vive o Senhor, que fez subir os filhos de Israel da terra do norte, e de todas as terras para onde os tinha lançado; porque eu os farei voltar à sua terra, a qual dei a seus pais.',
        'Eis que mandarei muitos pescadores, diz o Senhor, os quais os pescarão; e depois enviarei muitos caçadores, os quais os caçarão de sobre todo o monte, e de sobre todo o outeiro, e até das fendas das rochas.',
        'Porque os meus olhos estão sobre todos os seus caminhos; não se escondem da minha face, nem a sua maldade se encobre aos meus olhos.',
        'E primeiramente pagarei em dobro a sua maldade e o seu pecado, porque profanaram a minha terra com os cadáveres das suas coisas detestáveis, e das suas abominações encheram a minha herança.',
        'Ó Senhor, fortaleza minha, e força minha, e refúgio meu no dia da angústia; a ti virão os gentios desde os fins da terra, e dirão: Nossos pais herdaram só mentiras, e vaidade, em que não havia proveito.',
        'Porventura fará um homem deuses para si, que contudo não são deuses?',
        'Portanto, eis que lhes farei conhecer, desta vez lhes farei conhecer a minha mão e o meu poder; e saberão que o meu nome é o Senhor.',
      ],
      [
        'O pecado de Judá está escrito com um ponteiro de ferro, com ponta de diamante, gravado na tábua do seu coração e nas pontas dos vossos altares.',
        'Como também seus filhos se lembram dos seus altares, e dos seus bosques, junto às árvores frondosas, sobre os altos outeiros,',
        'Ó meu monte, no campo, a tua riqueza e todos os teus tesouros, darei por presa, como também os teus altos, por causa do pecado, em todos os teus termos.',
        'Assim por ti mesmo te privarás da tua herança que te dei, e far-te-ei servir os teus inimigos, na terra que não conheces; porque o fogo que acendeste na minha ira arderá para sempre.',
        'Assim diz o Senhor: Maldito o homem que confia no homem, e faz da carne o seu braço, e aparta o seu coração do Senhor!',
        'Porque será como a tamargueira no deserto, e não verá quando vem o bem; antes morará nos lugares secos do deserto, na terra salgada e inabitável.',
        'Bendito o homem que confia no Senhor, e cuja confiança é o Senhor.',
        'Porque será como a árvore plantada junto às águas, que estende as suas raízes para o ribeiro, e não receia quando vem o calor, mas a sua folha fica verde; e no ano de sequidão não se afadiga, nem deixa de dar fruto.',
        'Enganoso é o coração, mais do que todas as coisas, e perverso; quem o conhecerá?',
        'Eu, o Senhor, esquadrinho o coração e provo os rins; e isto para dar a cada um segundo os seus caminhos e segundo o fruto das suas ações.',
        'Como a perdiz, que choca ovos que não pôs, assim é aquele que ajunta riquezas, mas não retamente; no meio de seus dias as deixará, e no seu fim será um insensato.',
        'Um trono de glória, posto bem alto desde o princípio, é o lugar do nosso santuário.',
        'Ó Senhor, esperança de Israel, todos aqueles que te deixam serão envergonhados; os que se apartam de mim serão escritos sobre a terra; porque abandonam o Senhor, a fonte das águas vivas.',
        'Cura-me, Senhor, e sararei; salva-me, e serei salvo; porque tu és o meu louvor.',
        'Eis que eles me dizem: Onde está a palavra do Senhor? Venha agora.',
        'Porém eu não me apressei em ser o pastor seguindo-te; nem tampouco desejei o dia da aflição, tu o sabes; o que saiu dos meus lábios está diante de tua face.',
        'Não me sejas por espanto; meu refúgio és tu no dia do mal.',
        'Envergonhem-se os que me perseguem, e não me envergonhe eu; assombrem-se eles, e não me assombre eu; traze sobre eles o dia do mal, e destrói-os com dobrada destruição.',
        'Assim me disse o Senhor: Vai, e põe-te à porta dos filhos do povo, pela qual entram os reis de Judá, e pela qual saem; como também em todas as portas de Jerusalém.',
        'E dize-lhes: Ouvi a palavra do Senhor, vós, reis de Judá e todo o Judá, e todos os moradores de Jerusalém que entrais por estas portas.',
        'Assim diz o Senhor: Guardai as vossas almas, e não tragais cargas no dia de sábado, nem as introduzais pelas portas de Jerusalém;',
        'Nem tireis cargas de vossas casas no dia de sábado, nem façais obra alguma; antes santificai o dia de sábado, como eu ordenei a vossos pais.',
        'Mas não escutaram, nem inclinaram os seus ouvidos; antes endureceram a sua cerviz, para não ouvirem, e para não receberem correção.',
        'Mas se vós diligentemente me ouvirdes, diz o Senhor, não introduzindo cargas pelas portas desta cidade no dia de sábado, e santificardes o dia de sábado, não fazendo nele obra alguma,',
        'Então entrarão pelas portas desta cidade reis e príncipes, que se assentem sobre o trono de Davi, andando em carros e em cavalos; e eles e seus príncipes, os homens de Judá, e os moradores de Jerusalém; e esta cidade será habitada para sempre.',
        'E virão das cidades de Judá, e dos arredores de Jerusalém, e da terra de Benjamim, e das planícies, e das montanhas, e do sul, trazendo holocaustos, e sacrifícios, e ofertas de alimentos, e incenso, trazendo também sacrifícios de louvores à casa do Senhor.',
        'Mas, se não me ouvirdes, para santificardes o dia de sábado, e para não trazerdes carga alguma, quando entrardes pelas portas de Jerusalém no dia de sábado, então acenderei fogo nas suas portas, o qual consumirá os palácios de Jerusalém, e não se apagará.',
      ],
      [
        'A palavra do SENHOR, que veio a Jeremias, dizendo:',
        'Levanta-te, e desce à casa do oleiro, e lá te farei ouvir as minhas palavras.',
        'E desci à casa do oleiro, e eis que ele estava fazendo a sua obra sobre as rodas,',
        'Como o vaso, que ele fazia de barro, quebrou-se na mão do oleiro, tornou a fazer dele outro vaso, conforme o que pareceu bem aos olhos do oleiro fazer.',
        'Então veio a mim a palavra do Senhor, dizendo:',
        'Não poderei eu fazer de vós como fez este oleiro, ó casa de Israel? diz o Senhor. Eis que, como o barro na mão do oleiro, assim sois vós na minha mão, ó casa de Israel.',
        'No momento em que falar contra uma nação, e contra um reino para arrancar, e para derrubar, e para destruir,',
        'Se a tal nação, porém, contra a qual falar se converter da sua maldade, também eu me arrependerei do mal que pensava fazer-lhe.',
        'No momento em que falar de uma nação e de um reino, para edificar e para plantar,',
        'Se fizer o mal diante dos meus olhos, não dando ouvidos à minha voz, então me arrependerei do bem que tinha falado que lhe faria.',
        'Ora, pois, fala agora aos homens de Judá, e aos moradores de Jerusalém, dizendo: Assim diz o Senhor: Eis que estou forjando mal contra vós; e projeto um plano contra vós; convertei-vos, pois, agora cada um do seu mau caminho, e melhorai os vossos caminhos e as vossas ações.',
        'Mas eles dizem: Não há esperança, porque andaremos segundo as nossas imaginações; e cada um fará segundo o propósito do seu mau coração.',
        'Portanto, assim diz o Senhor: Perguntai agora entre os gentios quem ouviu tal coisa? Coisa mui horrenda fez a virgem de Israel.',
        'Porventura a neve do Líbano deixará a rocha do campo ou esgotar-se-ão as águas frias que correm de terras estranhas?',
        'Contudo o meu povo se tem esquecido de mim, queimando incenso à vaidade, que os fez tropeçar nos seus caminhos, e nas veredas antigas, para que andassem por veredas afastadas, não aplainadas;',
        'Para fazerem da sua terra objeto de espanto e de perpétuos assobios; todo aquele que passar por ela se espantará, e meneará a sua cabeça;',
        'Com vento oriental os espalharei diante do inimigo; mostrar-lhes-ei as costas e não o rosto, no dia da sua perdição.',
        'Então disseram: Vinde, e maquinemos projetos contra Jeremias; porque não perecerá a lei do sacerdote, nem o conselho do sábio, nem a palavra do profeta; vinde e firamo-lo com a língua, e não atendamos a nenhuma das suas palavras.',
        'Olha para mim, Senhor, e ouve a voz dos que contendem comigo.',
        'Porventura pagar-se-á mal por bem? Pois cavaram uma cova para a minha alma. Lembra-te de que eu compareci à tua presença, para falar a favor deles, e para desviar deles a tua indignação;',
        'Portanto entrega seus filhos à fome, e entrega-os ao poder da espada, e sejam suas mulheres roubadas dos filhos, e fiquem viúvas; e seus maridos sejam feridos de morte, e os seus jovens sejam feridos à espada na peleja.',
        'Ouça-se o clamor de suas casas, quando de repente trouxeres uma tropa sobre eles. Porquanto cavaram uma cova para prender-me e armaram laços aos meus pés.',
        'Mas tu, ó Senhor, sabes todo o seu conselho contra mim para matar-me; não perdoes a sua maldade, nem apagues o seu pecado de diante da tua face; mas tropecem diante de ti; trata-os assim no tempo da tua ira.',
      ],
      [
        'Assim disse o SENHOR: Vai, e compra uma botija de oleiro, e leva contigo alguns dos anciãos do povo e alguns dos anciãos dos sacerdotes;',
        'E sai ao Vale do Filho de Hinom, que está à entrada da porta do sol, e apregoa ali as palavras que eu te disser;',
        'E dirás: Ouvi a palavra do Senhor, ó reis de Judá, e moradores de Jerusalém. Assim diz o Senhor dos Exércitos, o Deus de Israel: Eis que trarei um mal sobre este lugar, e quem quer que dele ouvir retinir-lhe-ão os ouvidos.',
        'Porquanto me deixaram e alienaram este lugar, e nele queimaram incenso a outros deuses, que nunca conheceram, nem eles nem seus pais, nem os reis de Judá; e encheram este lugar de sangue de inocentes.',
        'Porque edificaram os altos de Baal, para queimarem seus filhos no fogo em holocaustos a Baal; o que nunca lhes ordenei, nem falei, nem me veio ao pensamento.',
        'Por isso eis que dias vêm, diz o Senhor, em que este lugar não se chamará mais Tofete, nem o Vale do Filho de Hinom, mas o Vale da Matança.',
        'Porque dissiparei o conselho de Judá e de Jerusalém neste lugar, e os farei cair à espada diante de seus inimigos, e pela mão dos que buscam a vida deles; e darei os seus cadáveres para pasto às aves dos céus e aos animais da terra.',
        'E farei esta cidade objeto de espanto e de assobio; todo aquele que passar por ela se espantará, e assobiará por causa de todas as suas pragas.',
        'E lhes farei comer a carne de seus filhos e a carne de suas filhas, e comerá cada um a carne do seu amigo, no cerco e no aperto em que os apertarão os seus inimigos, e os que buscam a vida deles.',
        'Então quebrarás a botija à vista dos homens que forem contigo.',
        'E dir-lhes-ás: Assim diz o Senhor dos Exércitos: Deste modo quebrarei eu a este povo, e a esta cidade, como se quebra o vaso do oleiro, que não pode mais refazer-se, e os enterrarão em Tofete, porque não haverá mais lugar para os enterrar.',
        'Assim farei a este lugar, diz o Senhor, e aos seus moradores; sim, para pôr a esta cidade como a Tofete.',
        'E as casas de Jerusalém, e as casas dos reis de Judá, serão imundas como o lugar de Tofete, como também todas as casas, sobre cujos terraços queimaram incenso a todo o exército dos céus, e ofereceram libações a deuses estranhos.',
        'Vindo, pois, Jeremias de Tofete onde o tinha enviado o Senhor a profetizar, se pôs em pé no átrio da casa do Senhor, e disse a todo o povo:',
        'Assim diz o Senhor dos Exércitos, o Deus de Israel: Eis que trarei sobre esta cidade, e sobre todas as suas vilas, todo o mal que pronunciei contra ela, porquanto endureceram a sua cerviz, para não ouvirem as minhas palavras.',
      ],
      [
        'E Pasur, filho de Imer, o sacerdote, que havia sido nomeado presidente na casa do SENHOR, ouviu a Jeremias, que profetizava estas palavras.',
        'E feriu Pasur ao profeta Jeremias, e o colocou no cepo que está na porta superior de Benjamim, na casa do Senhor.',
        'E sucedeu que no dia seguinte Pasur tirou a Jeremias do cepo. Então disse-lhe Jeremias: O Senhor não chama o teu nome Pasur, mas, Terror por todos os lados.',
        'Porque assim diz o SENHOR: Eis que farei de ti um terror para ti mesmo, e para todos os teus amigos. Eles cairão à espada de seus inimigos, e teus olhos o verão. Entregarei todo o Judá na mão do rei de babilônia; ele os levará presos a babilônia, e feri-los-á à espada.',
        'Também entregarei toda a riqueza desta cidade, e todo o seu trabalho, e todas as suas coisas preciosas, sim, todos os tesouros dos reis de Judá entregarei na mão de seus inimigos, e saqueá-los-ão, e tomá-los-ão e levá-los-ão a babilônia.',
        'E tu, Pasur, e todos os moradores da tua casa ireis para o cativeiro; e virás a babilônia, e ali morrerás, e ali serás sepultado, tu, e todos os teus amigos, aos quais profetizaste falsamente.',
        'Persuadiste-me, ó Senhor, e persuadido fiquei; mais forte foste do que eu, e prevaleceste; sirvo de escárnio todo o dia; cada um deles zomba de mim.',
        'Porque desde que falo, grito, clamo: Violência e destruição; porque se tornou a palavra do Senhor um opróbrio e ludíbrio todo o dia.',
        'Então disse eu: Não me lembrarei dele, e não falarei mais no seu nome; mas isso foi no meu coração como fogo ardente, encerrado nos meus ossos; e estou fatigado de sofrer, e não posso mais.',
        'Porque ouvi a murmuração de muitos, terror de todos os lados: Denunciai, e o denunciaremos; todos os que têm paz comigo aguardam o meu manquejar, dizendo: Bem pode ser que se deixe persuadir; então prevaleceremos contra ele e nos vingaremos dele.',
        'Mas o Senhor está comigo como um valente terrível; por isso tropeçarão os meus perseguidores, e não prevalecerão; ficarão muito confundidos; porque não se houveram prudentemente, terão uma confusão perpétua que nunca será esquecida.',
        'Tu, pois, ó Senhor dos Exércitos, que provas o justo, e vês os rins e o coração, permite que eu veja a tua vingança contra eles; pois já te revelei a minha causa.',
        'Cantai ao Senhor, louvai ao Senhor; pois livrou a alma do necessitado da mão dos malfeitores.',
        'Maldito o dia em que nasci; não seja bendito o dia em que minha mãe me deu à luz.',
        'Maldito o homem que deu as novas a meu pai, dizendo: Nasceu-te um filho; alegrando-o com isso grandemente.',
        'E seja esse homem como as cidades que o Senhor destruiu e não se arrependeu; e ouça clamor pela manhã, e ao tempo do meio-dia um alarido.',
        'Por que não me matou na madre? Assim minha mãe teria sido a minha sepultura, e teria ficado grávida perpetuamente!',
        'Por que saí da madre, para ver trabalho e tristeza, e para que os meus dias se consumam na vergonha?',
      ],
      [
        'A palavra que veio a Jeremias da parte do Senhor, quando o rei Zedequias lhe enviou a Pasur, filho de Malquias, e a Sofonias, filho de Maaséias, o sacerdote, dizendo:',
        'Pergunta agora por nós ao SENHOR, por que Nabucodonosor, rei de babilônia, guerreia contra nós; bem pode ser que o SENHOR trate conosco segundo todas as suas maravilhas, e o faça retirar-se de nós.',
        'Então Jeremias lhes disse: Assim direis a Zedequias:',
        'Assim diz o SENHOR Deus de Israel: Eis que virarei contra vós as armas de guerra, que estão nas vossas mãos, com que vós pelejais contra o rei de babilônia, e contra os caldeus, que vos têm cercado de fora dos muros, e ajuntá-los-ei no meio desta cidade.',
        'E eu pelejarei contra vós com mão estendida e com braço forte, e com ira, e com indignação e com grande furor.',
        'E ferirei os habitantes desta cidade, assim os homens como os animais; de grande pestilência morrerão.',
        'E depois disto, diz o SENHOR, entregarei Zedequias, rei de Judá, e seus servos, e o povo, e os que desta cidade restarem da pestilência, e da espada, e da fome, na mão de Nabucodonosor, rei de babilônia, e na mão de seus inimigos, e na mão dos que buscam a sua vida; e feri-los-á ao fio da espada; não os poupará, nem se compadecerá, nem terá misericórdia.',
        'E a este povo dirás: Assim diz o Senhor: Eis que ponho diante de vós o caminho da vida e o caminho da morte.',
        'O que ficar nesta cidade há de morrer à espada, ou de fome, ou de pestilência; mas o que sair, e se render aos caldeus, que vos têm cercado, viverá, e terá a sua vida por despojo.',
        'Porque pus o meu rosto contra esta cidade para mal, e não para bem, diz o SENHOR; na mão do rei de babilônia se entregará, e ele queimá-la-á a fogo.',
        'E à casa do rei de Judá dirás: Ouvi a palavra do Senhor:',
        'Ó casa de Davi, assim diz o Senhor: Julgai pela manhã justamente, e livrai o espoliado da mão do opressor; para que não saia o meu furor como fogo, e se acenda, sem que haja quem o apague, por causa da maldade de vossas ações.',
        'Eis que eu sou contra ti, ó moradora do vale, ó rocha da campina, diz o Senhor; contra vós que dizeis: Quem descerá contra nós? Ou quem entrará nas nossas moradas?',
        'Eu vos castigarei segundo o fruto das vossas ações, diz o Senhor; e acenderei o fogo no seu bosque, que consumirá a tudo o que está em redor dela.',
      ],
      [
        'Assim diz o SENHOR: Desce à casa do rei de Judá, e anuncia ali esta palavra,',
        'E dize: Ouve a palavra do Senhor, ó rei de Judá, que te assentas no trono de Davi, tu, e os teus servos, o teu povo, que entrais por estas portas.',
        'Assim diz o Senhor: Exercei o juízo e a justiça, e livrai o espoliado da mão do opressor; e não oprimais ao estrangeiro, nem ao órfão, nem à viúva; não façais violência, nem derrameis sangue inocente neste lugar.',
        'Porque, se deveras cumprirdes esta palavra, entrarão pelas portas desta casa os reis que se assentarão em lugar de Davi sobre o seu trono, andando em carros e montados em cavalos, eles, e os seus servos, e o seu povo.',
        'Mas, se não derdes ouvidos a estas palavras, por mim mesmo tenho jurado, diz o Senhor, que esta casa se tornará em assolação.',
        'Porque assim diz o Senhor acerca da casa do rei de Judá: Tu és para mim Gileade, e a cabeça do Líbano; mas por certo que farei de ti um deserto e cidades desabitadas.',
        'Porque preparei contra ti destruidores, cada um com as suas armas; e cortarão os teus cedros escolhidos, e lançá-los-ão no fogo.',
        'E muitas nações passarão por esta cidade, e dirá cada um ao seu próximo: Por que procedeu o Senhor assim com esta grande cidade?',
        'E dirão: Porque deixaram a aliança do Senhor seu Deus, e se inclinaram diante de outros deuses, e os serviram.',
        'Não choreis o morto, nem o lastimeis; chorai abundantemente aquele que sai, porque nunca mais tornará nem verá a terra onde nasceu.',
        'Porque assim diz o Senhor acerca de Salum, filho de Josias, rei de Judá, que reinou em lugar de Josias, seu pai, e que saiu deste lugar: Nunca mais ali tornará.',
        'Mas no lugar para onde o levaram cativo ali morrerá, e nunca mais verá esta terra.',
        'Ai daquele que edifica a sua casa com injustiça, e os seus aposentos sem direito, que se serve do serviço do seu próximo sem remunerá-lo, e não lhe dá o salário do seu trabalho.',
        'Que diz: Edificarei para mim uma casa espaçosa, e aposentos largos; e que lhe abre janelas, forrando-a de cedro, e pintando-a de vermelhão.',
        'Porventura reinarás tu, porque te encerras em cedro? Acaso teu pai não comeu e bebeu, e não praticou o juízo e a justiça? Por isso lhe sucedeu bem.',
        'Julgou a causa do aflito e necessitado; então lhe sucedeu bem; porventura não é isto conhecer-me? diz o Senhor.',
        'Mas os teus olhos e o teu coração não atentam senão para a tua avareza, e para derramar sangue inocente, e para praticar a opressão, e a violência.',
        'Portanto assim diz o SENHOR acerca de Jeoiaquim, filho de Josias, rei de Judá: Não o lamentarão, dizendo: Ai, meu irmão, ou ai, minha irmã! Nem o lamentarão, dizendo: Ai, senhor, ou, ai, sua glória!',
        'Em sepultura de jumento será sepultado, sendo arrastado e lançado para bem longe, fora das portas de Jerusalém.',
        'Sobe ao Líbano, e clama, e levanta a tua voz em Basã, e clama desde Abarim; porque estão destruídos todos os teus namorados.',
        'Falei contigo na tua prosperidade, mas tu disseste: Não ouvirei. Este tem sido o teu caminho, desde a tua mocidade, pois nunca deste ouvidos à minha voz.',
        'O vento apascentará a todos os teus pastores, e os teus namorados irão para o cativeiro; certamente então te confundirás, e te envergonharás por causa de toda a tua maldade.',
        'Ó tu, que habitas no Líbano e fazes o teu ninho nos cedros, quão lastimada serás quando te vierem as dores e os ais como da que está de parto.',
        'Vivo eu, diz o Senhor, que ainda que Conias, filho de Jeoiaquim, rei de Judá, fosse o anel do selo na minha mão direita, contudo dali te arrancaria.',
        'E entregar-te-ei na mão dos que buscam a tua vida, e na mão daqueles diante de quem tu temes, a saber, na mão de Nabucodonosor, rei de babilônia, e na mão dos caldeus.',
        'E lançar-te-ei, a ti e à tua mãe que te deu à luz, para uma terra estranha, em que não nasceste, e ali morrereis.',
        'Mas à terra, para a qual eles com toda a alma desejam voltar, para lá não voltarão.',
        'É, pois, este homem Conias um ídolo desprezado e quebrado, ou um vaso de que ninguém se agrada? Por que razão foram arremessados fora, ele e a sua geração, e arrojados para uma terra que não conhecem?',
        'Ó terra, terra, terra! Ouve a palavra do Senhor.',
        'Assim diz o Senhor: Escrevei que este homem está privado de filhos, homem que não prosperará nos seus dias; porque nenhum da sua geração prosperará, para se assentar no trono de Davi, e reinar ainda em Judá.',
      ],
      [
        'Ai dos pastores que destroem e dispersam as ovelhas do meu pasto, diz o SENHOR.',
        'Portanto assim diz o Senhor Deus de Israel, contra os pastores que apascentam o meu povo: Vós dispersastes as minhas ovelhas, e as afugentastes, e não as visitas-tes; eis que visitarei sobre vós a maldade das vossas ações, diz o Senhor.',
        'E eu mesmo recolherei o restante das minhas ovelhas, de todas as terras para onde as tiver afugentado, e as farei voltar aos seus apriscos; e frutificarão, e se multiplicarão.',
        'E levantarei sobre elas pastores que as apascentem, e nunca mais temerão, nem se assombrarão, e nem uma delas faltará, diz o Senhor.',
        'Eis que vêm dias, diz o Senhor, em que levantarei a Davi um Renovo justo; e, sendo rei, reinará e agirá sabiamente, e praticará o juízo e a justiça na terra.',
        'Nos seus dias Judá será salvo, e Israel habitará seguro; e este será o seu nome, com o qual Deus o chamará: O SENHOR JUSTIÇA NOSSA.',
        'Portanto, eis que vêm dias, diz o Senhor, em que nunca mais dirão: Vive o Senhor, que fez subir os filhos de Israel da terra do Egito;',
        'Mas: Vive o Senhor, que fez subir, e que trouxe a geração da casa de Israel da terra do norte, e de todas as terras para onde os tinha arrojado; e habitarão na sua terra.',
        'Quanto aos profetas, já o meu coração está quebrantado dentro de mim; todos os meus ossos estremecem; sou como um homem embriagado, e como um homem vencido de vinho, por causa do Senhor, e por causa das suas santas palavras.',
        'Porque a terra está cheia de adúlteros, e a terra chora por causa da maldição; os pastos do deserto se secam; porque a sua carreira é má, e a sua força não é reta.',
        'Porque tanto o profeta, como o sacerdote, estão contaminados; até na minha casa achei a sua maldade, diz o Senhor.',
        'Portanto o seu caminho lhes será como lugares escorregadios na escuridão; serão empurrados, e cairão nele; porque trarei sobre eles mal, no ano da sua visitação, diz o Senhor.',
        'Nos profetas de Samaria bem vi loucura; profetizavam da parte de Baal, e faziam errar o meu povo Israel.',
        'Mas nos profetas de Jerusalém vejo uma coisa horrenda: cometem adultérios, e andam com falsidade, e fortalecem as mãos dos malfeitores, para que não se convertam da sua maldade; eles têm-se tornado para mim como Sodoma, e os seus moradores como Gomorra.',
        'Portanto assim diz o Senhor dos Exércitos acerca dos profetas: Eis que lhes darei a comer losna, e lhes farei beber águas de fel; porque dos profetas de Jerusalém saiu a contaminação sobre toda a terra.',
        'Assim diz o Senhor dos Exércitos: Não deis ouvidos às palavras dos profetas, que entre vós profetizam; fazem-vos desvanecer; falam da visão do seu coração, não da boca do Senhor.',
        'Dizem continuamente aos que me desprezam: O Senhor disse: Paz tereis; e a qualquer que anda segundo a dureza do seu coração, dizem: Não virá mal sobre vós.',
        'Porque, quem esteve no conselho do Senhor, e viu, e ouviu a sua palavra? Quem esteve atento à sua palavra, e ouviu?',
        'Eis que saiu com indignação a tempestade do Senhor; e uma tempestade penosa cairá cruelmente sobre a cabeça dos ímpios.',
        'Não se desviará a ira do Senhor, até que execute e cumpra os desígnios do seu coração; nos últimos dias entendereis isso claramente.',
        'Não mandei esses profetas, contudo eles foram correndo; não lhes falei, contudo eles profetizaram.',
        'Mas, se estivessem estado no meu conselho, então teriam feito o meu povo ouvir as minhas palavras, e o teriam feito voltar do seu mau caminho, e da maldade das suas ações.',
        'Porventura sou eu Deus de perto, diz o Senhor, e não também Deus de longe?',
        'Esconder-se-ia alguém em esconderijos, de modo que eu não o veja? diz o Senhor. Porventura não encho eu os céus e a terra? diz o Senhor.',
        'Tenho ouvido o que dizem aqueles profetas, profetizando mentiras em meu nome, dizendo: Sonhei, sonhei.',
        'Até quando sucederá isso no coração dos profetas que profetizam mentiras, e que só profetizam do engano do seu coração?',
        'Os quais cuidam fazer com que o meu povo se esqueça do meu nome pelos seus sonhos que cada um conta ao seu próximo, assim como seus pais se esqueceram do meu nome por causa de Baal.',
        'O profeta que tem um sonho conte o sonho; e aquele que tem a minha palavra, fale a minha palavra com verdade. Que tem a palha com o trigo? diz o Senhor.',
        'Porventura a minha palavra não é como o fogo, diz o Senhor, e como um martelo que esmiúça a pedra?',
        'Portanto, eis que eu sou contra os profetas, diz o Senhor, que furtam as minhas palavras, cada um ao seu próximo.',
        'Eis que eu sou contra os profetas, diz o Senhor, que usam de sua própria linguagem, e dizem: Ele disse.',
        'Eis que eu sou contra os que profetizam sonhos mentirosos, diz o Senhor, e os contam, e fazem errar o meu povo com as suas mentiras e com as suas leviandades; pois eu não os enviei, nem lhes dei ordem; e não trouxeram proveito algum a este povo, diz o Senhor.',
        'Quando, pois, te perguntar este povo, ou qualquer profeta, ou sacerdote, dizendo: Qual é o peso do Senhor? Então lhe dirás: Este é o peso: Que vos deixarei, diz o Senhor.',
        'E, quanto ao profeta, e ao sacerdote, e ao povo, que disser: Peso do Senhor, eu castigarei o tal homem e a sua casa.',
        'Assim direis, cada um ao seu próximo, e cada um ao seu irmão: Que respondeu o Senhor? e que falou o Senhor?',
        'Mas nunca mais vos lembrareis do peso do Senhor; porque a cada um lhe servirá de peso a sua própria palavra; pois torceis as palavras do Deus vivo, do Senhor dos Exércitos, o nosso Deus.',
        'Assim dirás ao profeta: Que te respondeu o Senhor, e que falou o Senhor?',
        'Mas, porque dizeis: Peso do Senhor; assim o diz o Senhor: Porque dizeis esta palavra: Peso do Senhor, havendo-vos ordenado, dizendo: Não direis: Peso do Senhor;',
        'Por isso, eis que também eu me esquecerei totalmente de vós, e tirarei da minha presença, a vós e a cidade que vos dei a vós e a vossos pais;',
        'E porei sobre vós perpétuo opróbrio, e eterna vergonha, que não será esquecida.',
      ],
      [
        'Fez-me o SENHOR ver, e eis dois cestos de figos, postos diante do templo do SENHOR, depois que Nabucodonosor, rei de babilônia, levou em cativeiro a Jeconias, filho de Jeoiaquim, rei de Judá, e os príncipes de Judá, e os carpinteiros, e os ferreiros de Jerusalém, e os trouxe a babilônia.',
        'Um cesto tinha figos muito bons, como os figos temporãos; mas o outro cesto tinha figos muito ruins, que não se podiam comer, de ruins que eram.',
        'E disse-me o Senhor: Que vês tu, Jeremias? E eu disse: Figos: os figos bons, muito bons e os ruins, muito ruins, que não se podem comer, de ruins que são.',
        'Então veio a mim a palavra do Senhor, dizendo:',
        'Assim diz o Senhor, o Deus de Israel: Como a estes bons figos, assim também conhecerei aos de Judá, levados em cativeiro; os quais enviei deste lugar para a terra dos caldeus, para o seu bem.',
        'Porei os meus olhos sobre eles, para o seu bem, e os farei voltar a esta terra, e edificá-los-ei, e não os destruirei; e plantá-los-ei, e não os arrancarei.',
        'E dar-lhes-ei coração para que me conheçam, porque eu sou o Senhor; e ser-me-ão por povo, e eu lhes serei por Deus; porque se converterão a mim de todo o seu coração.',
        'E como os figos ruins, que se não podem comer, de ruins que são (porque assim diz o Senhor), assim entregarei Zedequias, rei de Judá, e os seus príncipes, e o restante de Jerusalém, que ficou nesta terra, e os que habitam na terra do Egito.',
        'E entregá-los-ei para que sejam um prejuízo, uma ofensa para todos os reinos da terra, um opróbrio e um provérbio, e um escárnio, e uma maldição em todos os lugares para onde eu os arrojar.',
        'E enviarei entre eles a espada, a fome, e a peste, até que se consumam de sobre a terra que lhes dei a eles e a seus pais.',
      ],
      [
        'A palavra que veio a Jeremias acerca de todo o povo de Judá no quarto ano de Jeoiaquim, filho de Josias, rei de Judá (que é o primeiro ano de Nabucodonosor, rei de babilônia),',
        'A qual anunciou o profeta Jeremias a todo o povo de Judá, e a todos os habitantes de Jerusalém, dizendo:',
        'Desde o ano treze de Josias, filho de Amom, rei de Judá, até o dia de hoje, período de vinte e três anos, tem vindo a mim a palavra do Senhor, e vo-la tenho anunciado, madrugando e falando; mas vós não escutastes.',
        'Também vos enviou o Senhor todos os seus servos, os profetas, madrugando e enviando-os, mas vós não escutastes, nem inclinastes os vossos ouvidos para ouvir,',
        'Quando diziam: Convertei-vos agora cada um do seu mau caminho, e da maldade das suas ações, e habitai na terra que o Senhor vos deu, e a vossos pais, para sempre.',
        'E não andeis após outros deuses para os servirdes, e para vos inclinardes diante deles, nem me provoqueis à ira com a obra de vossas mãos, para que não vos faça mal.',
        'Porém não me destes ouvidos, diz o Senhor, mas me provocastes à ira com a obra de vossas mãos, para vosso mal.',
        'Portanto assim diz o Senhor dos Exércitos: Visto que não escutastes as minhas palavras,',
        'Eis que eu enviarei, e tomarei a todas as famílias do norte, diz o SENHOR, como também a Nabucodonosor, rei de babilônia, meu servo, e os trarei sobre esta terra, e sobre os seus moradores, e sobre todas estas nações em redor, e os destruirei totalmente, e farei que sejam objeto de espanto, e de assobio, e de perpétuas desolações.',
        'E farei desaparecer dentre eles a voz de gozo, e a voz de alegria, a voz do esposo, e a voz da esposa, como também o som das mós, e a luz do candeeiro.',
        'E toda esta terra virá a ser um deserto e um espanto; e estas nações servirão ao rei de babilônia setenta anos.',
        'Acontecerá, porém, que, quando se cumprirem os setenta anos, visitarei o rei de babilônia, e esta nação, diz o SENHOR, castigando a sua iniqüidade, e a da terra dos caldeus; farei deles ruínas perpétuas.',
        'E trarei sobre aquela terra todas as minhas palavras, que disse contra ela, a saber, tudo quanto está escrito neste livro, que profetizou Jeremias contra todas estas nações.',
        'Porque também deles se servirão muitas nações e grandes reis; assim lhes retribuirei segundo os seus feitos, e segundo as obras das suas mãos.',
        'Porque assim me disse o Senhor Deus de Israel: Toma da minha mão este copo do vinho do furor, e darás a beber dele a todas as nações, às quais eu te enviarei.',
        'Para que bebam e tremam, e enlouqueçam, por causa da espada, que eu enviarei entre eles.',
        'E tomei o copo da mão do Senhor, e dei a beber a todas as nações, às quais o Senhor me enviou;',
        'A Jerusalém, e às cidades de Judá, e aos seus reis, e aos seus príncipes, para fazer deles uma desolação, um espanto, um assobio, e uma maldição, como hoje se vê;',
        'A Faraó, rei do Egito, e a seus servos, e a seus príncipes, e a todo o seu povo;',
        'E a toda a mistura de povo, e a todos os reis da terra de Uz, e a todos os reis da terra dos filisteus, e a Ascalom, e a Gaza, e a Ecrom, e ao remanescente de Asdode,',
        'E a Edom, e a Moabe, e aos filhos de Amom;',
        'E a todos os reis de Tiro, e a todos os reis de Sidom; e aos reis das ilhas que estão além do mar;',
        'A Dedã, e a Tema, e a Buz e a todos os que estão nos lugares mais distantes.',
        'E a todos os reis da Arábia, e todos os reis do povo misto que habita no deserto;',
        'E a todos os reis de Zinri, e a todos os reis de Elão, e a todos os reis da Média;',
        'E a todos os reis do norte, os de perto, e os de longe, tanto um como o outro, e a todos os reinos do mundo, que estão sobre a face da terra, e o rei de Sesaque beberá depois deles.',
        'Pois lhes dirás: Assim diz o Senhor dos Exércitos, o Deus de Israel: Bebei, e embebedai-vos, e vomitai, e caí, e não torneis a levantar-vos, por causa da espada que eu vos enviarei.',
        'E será que, se não quiserem tomar o copo da tua mão para beber, então lhes dirás: Assim diz o Senhor dos Exércitos: Certamente bebereis.',
        'Porque, eis que na cidade que se chama pelo meu nome começo a castigar; e ficareis vós totalmente impunes? Não ficareis impunes, porque eu chamo a espada sobre todos os moradores da terra, diz o Senhor dos Exércitos.',
        'Tu, pois, lhes profetizarás todas estas palavras, e lhes dirás: O Senhor desde o alto bramirá, e fará ouvir a sua voz desde a morada da sua santidade; terrivelmente bramirá contra a sua habitação, com grito de alegria, como dos que pisam as uvas, contra todos os moradores da terra.',
        'Chegará o estrondo até à extremidade da terra, porque o Senhor tem contenda com as nações, entrará em juízo com toda a carne; os ímpios entregará à espada, diz o Senhor.',
        'Assim diz o Senhor dos Exércitos: Eis que o mal passa de nação para nação, e grande tormenta se levantará dos confins da terra.',
        'E serão os mortos do Senhor, naquele dia, desde uma extremidade da terra até à outra; não serão pranteados, nem recolhidos, nem sepultados; mas serão por esterco sobre a face da terra.',
        'Uivai, pastores, e clamai, e revolvei-vos na cinza, principais do rebanho, porque já se cumpriram os vossos dias para serdes mortos, e dispersos, e vós então caireis como um vaso precioso.',
        'E não haverá refúgio para os pastores, nem salvamento para os principais do rebanho.',
        'Voz de grito dos pastores, e uivos dos principais do rebanho; porque o Senhor está destruindo o pasto deles.',
        'Porque as suas malhadas pacíficas serão desarraigadas, por causa do furor da ira do Senhor.',
        'Deixou a sua tenda, como o filho de leão; porque a sua terra foi posta em desolação, por causa do furor do opressor, e por causa do furor da sua ira.',
      ],
      [
        'No princípio do reinado de Jeoiaquim, filho de Josias, rei de Judá, veio esta palavra do SENHOR, dizendo:',
        'Assim diz o Senhor: Põe-te no átrio da casa do Senhor e dize a todas as cidades de Judá, que vêm adorar na casa do Senhor, todas as palavras que te mandei que lhes dissesses; não omitas nenhuma palavra.',
        'Bem pode ser que ouçam, e se convertam cada um do seu mau caminho, e eu me arrependa do mal que intento fazer-lhes por causa da maldade das suas ações.',
        'Dize-lhes pois: Assim diz o Senhor: Se não me derdes ouvidos para andardes na minha lei, que pus diante de vós,',
        'Para que ouvísseis as palavras dos meus servos, os profetas, que eu vos envio, madrugando e enviando, mas não ouvistes;',
        'Então farei que esta casa seja como Siló, e farei desta cidade uma maldição para todas as nações da terra.',
        'Os sacerdotes, e os profetas, e todo o povo, ouviram a Jeremias, falando estas palavras na casa do Senhor.',
        'E sucedeu que, acabando Jeremias de dizer tudo quanto o Senhor lhe havia ordenado que dissesse a todo o povo, pegaram nele os sacerdotes, e os profetas, e todo o povo, dizendo: Certamente morrerás,',
        'Por que profetizaste no nome do Senhor, dizendo: Como Siló será esta casa, e esta cidade será assolada, de sorte que não fique nenhum morador nela? E ajuntou-se todo o povo contra Jeremias, na casa do Senhor.',
        'E, ouvindo os príncipes de Judá estas palavras, subiram da casa do rei à casa do Senhor, e se assentaram à entrada da porta nova do Senhor.',
        'Então falaram os sacerdotes e os profetas aos príncipes e a todo o povo, dizendo: Este homem é réu de morte, porque profetizou contra esta cidade, como ouvistes com os vossos ouvidos.',
        'E falou Jeremias a todos os príncipes e a todo o povo, dizendo: O Senhor me enviou a profetizar contra esta casa, e contra esta cidade, todas as palavras que ouvistes.',
        'Agora, pois, melhorai os vossos caminhos e as vossas ações, e ouvi a voz do Senhor vosso Deus, e arrepender-se-á o Senhor do mal que falou contra vós.',
        'Quanto a mim, eis que estou nas vossas mãos; fazei de mim conforme o que for bom e reto aos vossos olhos.',
        'Sabei, porém, com certeza que, se me matardes, trareis sangue inocente sobre vós, e sobre esta cidade, e sobre os seus habitantes; porque, na verdade, o Senhor me enviou a vós, para dizer aos vossos ouvidos todas estas palavras.',
        'Então disseram os príncipes, e todo o povo aos sacerdotes e aos profetas: Este homem não é réu de morte, porque em nome do Senhor, nosso Deus, nos falou.',
        'Também se levantaram alguns homens dentre os anciãos da terra, e falaram a toda a congregação do povo, dizendo:',
        'Miquéias, o morastita, profetizou nos dias de Ezequias, rei de Judá, e falou a todo o povo de Judá, dizendo: Assim disse o Senhor dos Exércitos: Sião será lavrada como um campo, e Jerusalém se tornará em montões de pedras, e o monte desta casa como os altos de um bosque.',
        'Mataram-no, porventura, Ezequias, rei de Judá, e todo o Judá? Antes não temeu ao Senhor, e não implorou o favor do Senhor? E o Senhor não se arrependeu do mal que falara contra eles? Nós fazemos um grande mal contra as nossas almas.',
        'Também houve outro homem que profetizava em nome do Senhor, a saber: Urias, filho de Semaías de Quiriate-Jearim, o qual profetizou contra esta cidade, e contra esta terra, conforme todas as palavras de Jeremias.',
        'E, ouvindo o rei Jeoiaquim, e todos os seus poderosos e todos os príncipes, as suas palavras, procurou o rei matá-lo; mas ouvindo isto, Urias temeu e fugiu, e foi para o Egito;',
        'Mas o rei Jeoiaquim enviou alguns homens ao Egito, a saber: Elnatã, filho de Acbor, e outros homens com ele, ao Egito.',
        'Os quais tiraram a Urias do Egito, e o trouxeram ao rei Jeoiaquim, que o feriu à espada, e lançou o seu cadáver nas sepulturas dos filhos do povo.',
        'Porém a mão de Aicão, filho de Safã, foi com Jeremias, para que o não entregassem na mão do povo, para ser morto.',
      ],
      [
        'No princípio do reinado de Jeoiaquim, filho de Josias, rei de Judá, veio esta palavra a Jeremias da parte do SENHOR, dizendo:',
        'Assim me disse o Senhor: Faze uns grilhões e jugos, e põe-nos ao teu pescoço.',
        'E envia-os ao rei de Edom, e ao rei de Moabe, e ao rei dos filhos de Amom, e ao rei de Tiro, e ao rei de Sidom, pela mão dos mensageiros que vêm a Jerusalém a ter com Zedequias, rei de Judá.',
        'E lhes ordenarás, que digam aos seus senhores: Assim diz o SENHOR dos Exércitos, o Deus de Israel: Assim direis a vossos senhores:',
        'Eu fiz a terra, o homem, e os animais que estão sobre a face da terra, com o meu grande poder, e com o meu braço estendido, e a dou a quem é reto aos meus olhos.',
        'E agora eu entreguei todas estas terras na mão de Nabucodonosor, rei de babilônia, meu servo; e ainda até os animais do campo lhe dei, para que o sirvam.',
        'E todas as nações servirão a ele, e a seu filho, e ao filho de seu filho, até que também venha o tempo da sua própria terra, quando muitas nações e grandes reis se servirão dele.',
        'E acontecerá que, se alguma nação e reino não servirem o mesmo Nabucodonosor, rei de babilônia, e não puserem o seu pescoço debaixo do jugo do rei de babilônia, a essa nação castigarei com espada, e com fome, e com peste, diz o SENHOR, até que a consuma pela sua mão;',
        'E vós não deis ouvidos aos vossos profetas, e aos vossos adivinhos, e aos vossos sonhos, e aos vossos agoureiros, e aos vossos encantadores, que vos falam, dizendo: Não servireis ao rei de babilônia.',
        'Porque mentiras vos profetizam, para vos mandarem para longe da vossa terra, e para que eu vos expulse dela, e pereçais.',
        'Mas a nação que colocar o seu pescoço sob o jugo do rei de babilônia, e o servir, eu a deixarei na sua terra, diz o SENHOR, e lavrá-la-á e habitará nela.',
        'E falei com Zedequias, rei de Judá, conforme todas estas palavras, dizendo: Colocai os vossos pescoços no jugo do rei de babilônia, e servi-o, a ele e ao seu povo, e vivereis.',
        'Por que morrerias tu e o teu povo, à espada, e à fome, e de peste, como o SENHOR disse contra a nação que não servir ao rei de babilônia?',
        'E não deis ouvidos às palavras dos profetas, que vos falam, dizendo: Não servireis ao rei de babilônia; porque vos profetizam mentiras.',
        'Porque não os enviei, diz o Senhor, e profetizam falsamente em meu nome; para que eu vos lance fora, e pereçais, vós e os profetas que vos profetizam.',
        'Também falei aos sacerdotes, e a todo este povo, dizendo: Assim diz o SENHOR: Não deis ouvidos às palavras dos vossos profetas, que vos profetizam, dizendo: Eis que os utensílios da casa do SENHOR cedo voltarão de babilônia, porque vos profetizam mentiras.',
        'Não lhes deis ouvidos, servi ao rei de babilônia, e vivereis; por que se tornaria esta cidade em desolação?',
        'Porém, se são profetas, e se há palavras do SENHOR com eles, orem agora ao SENHOR dos Exércitos, para que os utensílios que ficaram na casa do SENHOR, e na casa do rei de Judá, e em Jerusalém, não vão para a babilônia.',
        'Porque assim diz o Senhor dos Exércitos acerca das colunas, e do mar, e das bases, e dos demais utensílios que ficaram na cidade,',
        'Os quais Nabucodonosor, rei de babilônia, não levou, quando transportou de Jerusalém para babilônia a Jeconias, filho de Jeoiaquim, rei de Judá, como também a todos os nobres de Judá e de Jerusalém;',
        'Assim, pois, diz o Senhor dos Exércitos, o Deus de Israel, acerca dos utensílios que ficaram na casa do Senhor, e na casa do rei de Judá, e em Jerusalém:',
        'À Babilônia serão levados, e ali ficarão até o dia em que eu os visitarei, diz o Senhor; então os farei subir, e os tornarei a trazer a este lugar.',
      ],
      [
        'E sucedeu no mesmo ano, no princípio do reinado de Zedequias, rei de Judá, no ano quarto, no mês quinto, que Hananias, filho de Azur, o profeta que era de Gibeom, me falou na casa do SENHOR, na presença dos sacerdotes e de todo o povo, dizendo:',
        'Assim fala o SENHOR dos Exércitos, o Deus de Israel, dizendo: Eu quebrei o jugo do rei de babilônia.',
        'Depois de passados dois anos completos, eu tornarei a trazer a este lugar todos os utensílios da casa do SENHOR, que deste lugar tomou Nabucodonosor, rei de babilônia, levando-os a babilônia.',
        'Também a Jeconias, filho de Jeoiaquim, rei de Judá, e a todos os do cativeiro de Judá, que entraram em babilônia, eu tornarei a trazer a este lugar, diz o SENHOR; porque quebrarei o jugo do rei de babilônia.',
        'Então falou o profeta Jeremias ao profeta Hananias, na presença dos sacerdotes, e na presença de todo o povo que estava na casa do Senhor.',
        'Disse, pois, Jeremias, o profeta: Amém! Assim faça o SENHOR; confirme o SENHOR as tuas palavras, que profetizaste, e torne ele a trazer os utensílios da casa do SENHOR, e todos os do cativeiro de babilônia a este lugar.',
        'Mas ouve agora esta palavra, que eu falo aos teus ouvidos e aos ouvidos de todo o povo:',
        'Os profetas que houve antes de mim e antes de ti, desde a antiguidade, profetizaram contra muitas terras, e contra grandes reinos, acerca de guerra, e de mal, e de peste.',
        'O profeta que profetizar de paz, quando se cumprir a palavra desse profeta, será conhecido como aquele a quem o Senhor na verdade enviou.',
        'Então Hananias, o profeta, tomou o jugo do pescoço do profeta Jeremias, e o quebrou.',
        'E falou Hananias na presença de todo o povo, dizendo: Assim diz o SENHOR: Assim, passados dois anos completos, quebrarei o jugo de Nabucodonosor, rei de babilônia, de sobre o pescoço de todas as nações. E Jeremias, o profeta, seguiu o seu caminho.',
        'Mas veio a palavra do Senhor a Jeremias, depois que Hananias, o profeta, quebrou o jugo de sobre o pescoço de Jeremias, o profeta, dizendo:',
        'Vai, e fala a Hananias, dizendo: Assim diz o Senhor: Jugos de madeira quebraste, mas em vez deles farás jugos de ferro.',
        'Porque assim diz o SENHOR dos Exércitos, o Deus de Israel: Jugo de ferro pus sobre o pescoço de todas estas nações, para servirem a Nabucodonosor, rei de babilônia, e servi-lo-ão, e até os animais do campo lhe dei.',
        'E disse o profeta Jeremias ao profeta Hananias: Ouve agora, Hananias: Não te enviou o Senhor, mas tu fizeste que este povo confiasse em mentiras.',
        'Portanto, assim diz o Senhor: Eis que te lançarei de sobre a face da terra; este ano morrerás, porque falaste em rebeldia contra o Senhor.',
        'E morreu Hananias, o profeta, no mesmo ano, no sétimo mês.',
      ],
      [
        'E Estas são as palavras da carta que Jeremias, o profeta, enviou de Jerusalém, aos que restaram dos anciãos do cativeiro, como também aos sacerdotes, e aos profetas, e a todo o povo que Nabucodonosor havia deportado de Jerusalém para babilônia',
        '(Depois que saíram de Jerusalém o rei Jeconias, e a rainha, e os eunucos, e os príncipes de Judá e Jerusalém, e os carpinteiros e ferreiros),',
        'Pela mão de Elasa, filho de Safã, e de Gemarias, filho de Hilquias (os quais Zedequias, rei de Judá, tinha enviado a babilônia, a Nabucodonosor, rei de babilônia), dizendo:',
        'Assim diz o SENHOR dos Exércitos, o Deus de Israel, a todos os do cativeiro, os quais fiz transportar de Jerusalém para babilônia:',
        'Edificai casas e habitai-as; e plantai jardins, e comei o seu fruto.',
        'Tomai mulheres e gerai filhos e filhas, e tomai mulheres para vossos filhos, e dai vossas filhas a maridos, para que tenham filhos e filhas; e multiplicai-vos ali, e não vos diminuais.',
        'E procurai a paz da cidade, para onde vos fiz transportar em cativeiro, e orai por ela ao Senhor; porque na sua paz vós tereis paz.',
        'Porque assim diz o Senhor dos Exércitos, o Deus de Israel: Não vos enganem os vossos profetas que estão no meio de vós, nem os vossos adivinhos, nem deis ouvidos aos vossos sonhos, que sonhais;',
        'Porque eles vos profetizam falsamente em meu nome; não os enviei, diz o Senhor.',
        'Porque assim diz o SENHOR: Certamente que passados setenta anos em babilônia, vos visitarei, e cumprirei sobre vós a minha boa palavra, tornando a trazer-vos a este lugar.',
        'Porque eu bem sei os pensamentos que tenho a vosso respeito, diz o Senhor; pensamentos de paz, e não de mal, para vos dar o fim que esperais.',
        'Então me invocareis, e ireis, e orareis a mim, e eu vos ouvirei.',
        'E buscar-me-eis, e me achareis, quando me buscardes com todo o vosso coração.',
        'E serei achado de vós, diz o Senhor, e farei voltar os vossos cativos e congregar-vos-ei de todas as nações, e de todos os lugares para onde vos lancei, diz o Senhor, e tornarei a trazer-vos ao lugar de onde vos transportei.',
        'Porque dizeis: O SENHOR nos levantou profetas em babilônia.',
        'Porque assim diz o Senhor acerca do rei que se assenta no trono de Davi, e de todo o povo que habita nesta cidade, vossos irmãos, que não saíram conosco para o cativeiro.',
        'Assim diz o Senhor dos Exércitos: Eis que enviarei entre eles a espada, a fome e a peste, e fá-los-ei como a figos podres que não se podem comer, de ruins que são.',
        'E persegui-los-ei com a espada, com a fome, e com a peste; e dá-los-ei para deslocarem-se por todos os reinos da terra, para serem uma maldição, e um espanto, e um assobio, e um opróbrio entre todas as nações para onde os tiver lançado.',
        'Porquanto não deram ouvidos às minhas palavras, diz o Senhor, mandando-lhes eu os meus servos, os profetas, madrugando e enviando; mas vós não escutastes, diz o Senhor.',
        'Vós, pois, ouvi a palavra do SENHOR, todos os do cativeiro que enviei de Jerusalém a babilônia.',
        'Assim diz o SENHOR dos Exércitos, o Deus de Israel, acerca de Acabe, filho de Colaías, e de Zedequias, filho de Maaséias, que vos profetizam falsamente em meu nome: Eis que os entregarei na mão de Nabucodonosor, rei de babilônia, e ele os ferirá diante dos vossos olhos.',
        'E todos os transportados de Judá, que estão em babilônia, tomarão deles uma maldição, dizendo: O SENHOR te faça como Zedequias, e como Acabe, os quais o rei de babilônia assou no fogo;',
        'Porquanto fizeram loucura em Israel, e cometeram adultério com as mulheres dos seus vizinhos, e anunciaram falsamente, em meu nome uma palavra, que não lhes mandei, e eu o sei e sou testemunha disso, diz o Senhor.',
        'E a Semaías, o neelamita, falarás, dizendo:',
        'Assim fala o Senhor dos Exércitos, o Deus de Israel, dizendo: Porquanto tu enviaste no teu nome cartas a todo o povo que está em Jerusalém, como também a Sofonias, filho de Maaséias, o sacerdote, e a todos os sacerdotes, dizendo:',
        'O Senhor te pôs por sacerdote em lugar de Joiada, o sacerdote, para que sejas encarregado da casa do Senhor sobre todo o homem fanático, e que profetiza, para o lançares na prisão e no tronco.',
        'Agora, pois, por que não repreendeste a Jeremias, o anatotita, que vos profetiza?',
        'Porque até nos mandou dizer em babilônia: Ainda o cativeiro muito há de durar; edificai casas, e habitai nelas; e plantai pomares, e comei o seu fruto.',
        'E leu Sofonias, o sacerdote, esta carta aos ouvidos de Jeremias, o profeta.',
        'E veio a palavra do Senhor a Jeremias, dizendo:',
        'Manda a todos os do cativeiro, dizendo: Assim diz o Senhor acerca de Semaías, o neelamita: Porquanto Semaías vos profetizou, e eu não o enviei, e vos fez confiar em mentiras,',
        'Portanto assim diz o Senhor: Eis que castigarei a Semaías, o neelamita, e a sua descendência; ele não terá ninguém que habite entre este povo, e não verá o bem que hei de fazer ao meu povo, diz o Senhor, porque falou em rebeldia contra o Senhor.',
      ],
      [
        'A palavra que do SENHOR veio a Jeremias, dizendo:',
        'Assim diz o Senhor Deus de Israel: Escreve num livro todas as palavras que te tenho falado.',
        'Porque eis que vêm dias, diz o Senhor, em que farei voltar do cativeiro o meu povo Israel, e de Judá, diz o Senhor; e tornarei a trazê-los à terra que dei a seus pais, e a possuirão.',
        'E estas são as palavras que disse o Senhor, acerca de Israel e de Judá.',
        'Porque assim diz o Senhor: Ouvimos uma voz de tremor, de temor mas não de paz.',
        'Perguntai, pois, e vede, se um homem pode dar à luz. Por que, pois, vejo a cada homem com as mãos sobre os lombos como a que está dando à luz? e por que se tornaram pálidos todos os rostos?',
        'Ah! porque aquele dia é tão grande, que não houve outro semelhante; e é tempo de angústia para Jacó; ele, porém, será salvo dela.',
        'Porque será naquele dia, diz o Senhor dos Exércitos, que eu quebrarei o seu jugo de sobre o teu pescoço, e quebrarei os teus grilhões; e nunca mais se servirão dele os estrangeiros.',
        'Mas servirão ao Senhor, seu Deus, como também a Davi, seu rei, que lhes levantarei.',
        'Não temas, pois, tu, ó meu servo Jacó, diz o Senhor, nem te espantes, ó Israel; porque eis que te livrarei de terras de longe, e à tua descendência da terra do seu cativeiro; e Jacó voltará, e descansará, e ficará em sossego, e não haverá quem o atemorize.',
        'Porque eu sou contigo, diz o Senhor, para te salvar; porquanto darei fim a todas as nações entre as quais te espalhei; a ti, porém, não darei fim, mas castigar-te-ei com medida, e de todo não te terei por inocente.',
        'Porque assim diz o Senhor: A tua ferida é incurável; a tua chaga é dolorosa.',
        'Não há quem defenda a tua causa para te aplicar curativo; não tens remédios que possam curar.',
        'Todos os teus amantes se esqueceram de ti, e não perguntam por ti; porque te feri com ferida de inimigo, e com castigo de quem é cruel, pela grandeza da tua maldade e multidão de teus pecados.',
        'Por que gritas por causa da tua ferida? Tua dor é incurável. Pela grandeza de tua maldade, e multidão de teus pecados, eu fiz estas coisas.',
        'Por isso todos os que te devoram serão devorados; e todos os teus adversários irão, todos eles, para o cativeiro; e os que te roubam serão roubados, e a todos os que te despojam entregarei ao saque.',
        'Porque te restaurarei a saúde, e te curarei as tuas chagas, diz o Senhor; porquanto te chamaram a repudiada, dizendo: É Sião, já ninguém pergunta por ela.',
        'Assim diz o Senhor: Eis que farei voltar do cativeiro as tendas de Jacó, e apiedar-me-ei das suas moradas; e a cidade será reedificada sobre o seu montão, e o palácio permanecerá como habitualmente.',
        'E sairá deles o louvor e a voz de júbilo; e multiplicá-los-ei, e não serão diminuídos, e glorificá-los-ei, e não serão apoucados.',
        'E seus filhos serão como na antiguidade, e a sua congregação será confirmada diante de mim; e castigarei todos os seus opressores.',
        'E os seus nobres serão deles; e o seu governador sairá do meio deles, e o farei aproximar, e ele se chegará a mim; pois, quem de si mesmo se empenharia para chegar-se a mim? diz o Senhor.',
        'E ser-me-eis por povo, e eu vos serei por Deus.',
        'Eis que a tempestade do Senhor, a sua indignação, já saiu; uma tempestade varredora, cairá cruelmente sobre a cabeça dos ímpios.',
        'Não voltará atrás o furor da ira do Senhor, até que tenha executado e até que tenha cumprido os desígnios do seu coração; no fim dos dias entendereis isto.',
      ],
      [
        'Naquele tempo, diz o SENHOR, serei o Deus de todas as famílias de Israel, e elas serão o meu povo.',
        'Assim diz o Senhor: O povo dos que escaparam da espada achou graça no deserto. Israel mesmo, quando eu o fizer descansar.',
        'Há muito que o Senhor me apareceu, dizendo: Porquanto com amor eterno te amei, por isso com benignidade te atraí.',
        'Ainda te edificarei, e serás edificada, ó virgem de Israel! Ainda serás adornada com os teus tamboris, e sairás nas danças dos que se alegram.',
        'Ainda plantarás vinhas nos montes de Samaria; os plantadores as plantarão e comerão como coisas comuns.',
        'Porque haverá um dia em que gritarão os vigias sobre o monte de Efraim: Levantai-vos, e subamos a Sião, ao Senhor nosso Deus.',
        'Porque assim diz o Senhor: Cantai sobre Jacó com alegria, e exultai por causa do chefe das nações; proclamai, cantai louvores, e dizei: Salva, Senhor, ao teu povo, o restante de Israel.',
        'Eis que os trarei da terra do norte, e os congregarei das extremidades da terra; entre os quais haverá cegos e aleijados, grávidas e as de parto juntamente; em grande congregação voltarão para aqui.',
        'Virão com choro, e com súplicas os levarei; guiá-los-ei aos ribeiros de águas, por caminho direito, no qual não tropeçarão, porque sou um pai para Israel, e Efraim é o meu primogênito.',
        'Ouvi a palavra do Senhor, ó nações, e anunciai-a nas ilhas longínquas, e dizei: Aquele que espalhou a Israel o congregará e o guardará, como o pastor ao seu rebanho.',
        'Porque o Senhor resgatou a Jacó, e o livrou da mão do que era mais forte do que ele.',
        'Assim que virão, e exultarão no alto de Sião, e correrão aos bens do Senhor, ao trigo, e ao mosto, e ao azeite, e aos cordeiros e bezerros; e a sua alma será como um jardim regado, e nunca mais andarão tristes.',
        'Então a virgem se alegrará na dança, como também os jovens e os velhos juntamente; e tornarei o seu pranto em alegria, e os consolarei, e lhes darei alegria em lugar de tristeza.',
        'E saciarei a alma dos sacerdotes com gordura, e o meu povo se fartará dos meus bens, diz o Senhor.',
        'Assim diz o Senhor: Uma voz se ouviu em Ramá, lamentação, choro amargo; Raquel chora seus filhos; não quer ser consolada quanto a seus filhos, porque já não existem.',
        'Assim diz o Senhor: Reprime a tua voz de choro, e as lágrimas de teus olhos; porque há galardão para o teu trabalho, diz o Senhor, pois eles voltarão da terra do inimigo.',
        'E há esperança quanto ao teu futuro, diz o Senhor, porque teus filhos voltarão para os seus termos.',
        'Bem ouvi eu que Efraim se queixava, dizendo: Castigaste-me e fui castigado, como novilho ainda não domado; converte-me, e converter-me-ei, porque tu és o Senhor meu Deus.',
        'Na verdade que, depois que me converti, tive arrependimento; e depois que fui instruído, bati na minha coxa; fiquei confuso, e também me envergonhei; porque suportei o opróbrio da minha mocidade.',
        'Não é Efraim para mim um filho precioso, criança das minhas delícias? Porque depois que falo contra ele, ainda me lembro dele solicitamente; por isso se comovem por ele as minhas entranhas; deveras me compadecerei dele, diz o Senhor.',
        'Levanta para ti sinais, faze para ti altos marcos, aplica o teu coração à vereda, ao caminho por onde andaste; volta, pois, ó virgem de Israel, regressa a estas tuas cidades.',
        'Até quando andarás errante, ó filha rebelde? Porque o Senhor criou uma coisa nova sobre a terra; uma mulher cercará a um homem.',
        'Assim diz o Senhor dos Exércitos, o Deus de Israel: Ainda dirão esta palavra na terra de Judá, e nas suas cidades, quando eu vos restaurar do seu cativeiro: O Senhor te abençoe, ó morada de justiça, ó monte de santidade!',
        'E nela habitarão Judá, e todas as suas cidades juntamente; como também os lavradores e os que pastoreiam o rebanho.',
        'Porque satisfiz a alma cansada, e toda a alma entristecida saciei.',
        'Nisto despertei, e olhei, e o meu sono foi doce para mim.',
        'Eis que dias vêm, diz o Senhor, em que semearei a casa de Israel, e a casa de Judá, com a semente de homens, e com a semente de animais.',
        'E será que, como velei sobre eles, para arrancar, e para derrubar, e para transtornar, e para destruir, e para afligir, assim velarei sobre eles, para edificar e para plantar, diz o Senhor.',
        'Naqueles dias nunca mais dirão: Os pais comeram uvas verdes, e os dentes dos filhos se embotaram.',
        'Mas cada um morrerá pela sua iniqüidade; de todo o homem que comer as uvas verdes os dentes se embotarão.',
        'Eis que dias vêm, diz o Senhor, em que farei uma aliança nova com a casa de Israel e com a casa de Judá.',
        'Não conforme a aliança que fiz com seus pais, no dia em que os tomei pela mão, para os tirar da terra do Egito; porque eles invalidaram a minha aliança apesar de eu os haver desposado, diz o Senhor.',
        'Mas esta é a aliança que farei com a casa de Israel depois daqueles dias, diz o Senhor: Porei a minha lei no seu interior, e a escreverei no seu coração; e eu serei o seu Deus e eles serão o meu povo.',
        'E não ensinará mais cada um a seu próximo, nem cada um a seu irmão, dizendo: Conhecei ao Senhor; porque todos me conhecerão, desde o menor até ao maior deles, diz o Senhor; porque lhes perdoarei a sua maldade, e nunca mais me lembrarei dos seus pecados.',
        'Assim diz o Senhor, que dá o sol para luz do dia, e as ordenanças da lua e das estrelas para luz da noite, que agita o mar, bramando as suas ondas; o Senhor dos Exércitos é o seu nome.',
        'Se falharem estas ordenanças de diante de mim, diz o Senhor, deixará também a descendência de Israel de ser uma nação diante de mim para sempre.',
        'Assim disse o Senhor: Se puderem ser medidos os céus lá em cima, e sondados os fundamentos da terra cá em baixo, também eu rejeitarei toda a descendência de Israel, por tudo quanto fizeram, diz o Senhor.',
        'Eis que vêm dias, diz o Senhor, em que esta cidade será reedificada para o Senhor, desde a torre de Hananeel até à porta da esquina.',
        'E a linha de medir estender-se-á para diante dela, até ao outeiro de Garebe, e virar-se-á para Goa.',
        'E todo o vale dos cadáveres e da cinza, e todos os campos até ao ribeiro de Cedrom, até à esquina da porta dos cavalos para o oriente, serão consagrados ao Senhor; não se arrancará nem se derrubará mais eternamente.',
      ],
      [
        'A palavra que veio a Jeremias da parte do SENHOR, no ano décimo de Zedequias, rei de Judá, o qual foi o décimo oitavo de Nabucodonosor.',
        'Ora, nesse tempo o exército do rei de babilônia cercava Jerusalém; e Jeremias, o profeta, estava encerrado no pátio da guarda que estava na casa do rei de Judá;',
        'Porque Zedequias, rei de Judá, o tinha encerrado, dizendo: Por que profetizas tu, dizendo: Assim diz o SENHOR: Eis que entrego esta cidade na mão do rei de babilônia, e ele a tomará;',
        'E Zedequias, rei de Judá, não escapará das mãos dos caldeus; mas certamente será entregue na mão do rei de babilônia, e com ele falará boca a boca, e os seus olhos verão os dele;',
        'E ele levará Zedequias para babilônia, e ali estará, até que eu o visite, diz o SENHOR e, ainda que pelejeis contra os caldeus, não ganhareis?',
        'Disse, pois, Jeremias: Veio a mim a palavra do Senhor, dizendo:',
        'Eis que Hanameel, filho de Salum, teu tio, virá a ti dizendo: Compra para ti a minha herdade que está em Anatote, pois tens o direito de resgate para comprá-la.',
        'Veio, pois, a mim Hanameel, filho de meu tio, segundo a palavra do Senhor, ao pátio da guarda, e me disse: Compra agora a minha herdade que está em Anatote, na terra de Benjamim; porque teu é o direito de herança, e tens o resgate; compra-a para ti. Então entendi que isto era a palavra do Senhor.',
        'Comprei, pois, a herdade de Hanameel, filho de meu tio, a qual está em Anatote; e pesei-lhe o dinheiro, dezessete siclos de prata.',
        'E assinei a escritura, e selei-a, e fiz confirmar por testemunhas; e pesei-lhe o dinheiro numa balança.',
        'E tomei a escritura da compra, selada segundo a lei e os estatutos, e a cópia aberta.',
        'E dei a escritura da compra a Baruque, filho de Nerias, filho de Maaséias, na presença de Hanameel, filho de meu tio e na presença das testemunhas, que subscreveram a escritura da compra, e na presença de todos os judeus que se assentavam no pátio da guarda.',
        'E dei ordem a Baruque, na presença deles, dizendo:',
        'Assim diz o Senhor dos Exércitos, o Deus de Israel: Toma estas escrituras, este auto de compra, tanto a selada, como a aberta, e coloca-as num vaso de barro, para que se possam conservar muitos dias.',
        'Porque assim diz o Senhor dos Exércitos, o Deus de Israel: Ainda se comprarão casas, e campos, e vinhas nesta terra.',
        'E depois que dei a escritura da compra a Baruque, filho de Nerias, orei ao Senhor, dizendo:',
        'Ah Senhor DEUS! Eis que tu fizeste os céus e a terra com o teu grande poder, e com o teu braço estendido; nada há que te seja demasiado difícil;',
        'Tu que usas de benignidade com milhares, e retribuis a maldade dos pais ao seio dos filhos depois deles; o grande, o poderoso Deus cujo nome é o Senhor dos Exércitos;',
        'Grande em conselho, e magnífico em obras; porque os teus olhos estão abertos sobre todos os caminhos dos filhos dos homens, para dar a cada um segundo os seus caminhos e segundo o fruto das suas obras;',
        'Tu puseste sinais e maravilhas na terra do Egito até ao dia de hoje, tanto em Israel, como entre os outros homens, e te fizeste um nome, o qual tu tens neste dia.',
        'E tiraste o teu povo Israel da terra do Egito, com sinais e com maravilhas, e com mão forte, e com braço estendido, e com grande espanto,',
        'E lhes deste esta terra, que juraste a seus pais que lhes havias de dar, terra que mana leite e mel.',
        'E entraram nela, e a possuíram, mas não obedeceram à tua voz, nem andaram na tua lei; tudo o que lhes mandaste que fizessem, eles não o fizeram; por isso ordenaste lhes sucedesse todo este mal.',
        'Eis aqui os valados; já vieram contra a cidade para tomá-la, e a cidade está entregue na mão dos caldeus, que pelejam contra ela, pela espada, pela fome e pela pestilência; e o que disseste se cumpriu, e eis aqui o estás presenciando.',
        'Contudo tu me disseste, ó Senhor DEUS: Compra para ti o campo por dinheiro, e faze que o confirmem testemunhas, embora a cidade já esteja entregue na mão dos caldeus.',
        'Então veio a palavra do Senhor a Jeremias, dizendo:',
        'Eis que eu sou o Senhor, o Deus de toda a carne; acaso haveria alguma coisa demasiado difícil para mim?',
        'Portanto assim diz o SENHOR: Eis que eu entrego esta cidade na mão dos caldeus, e na mão de Nabucodonosor, rei de babilônia, e ele a tomará.',
        'E os caldeus, que pelejam contra esta cidade, entrarão nela, e pôr-lhe-ão fogo, e queimarão, as casas sobre cujos terraços queimaram incenso a Baal e ofereceram libações a outros deuses, para me provocarem à ira.',
        'Porque os filhos de Israel e os filhos de Judá não fizeram senão mal aos meus olhos, desde a sua mocidade; porque os filhos de Israel nada fizeram senão provocar-me à ira com as obras das suas mãos, diz o Senhor.',
        'Porque para a minha ira e para o meu furor me tem sido esta cidade, desde o dia em que a edificaram, e até ao dia de hoje, para que a tirasse da minha presença;',
        'Por causa de toda a maldade dos filhos de Israel, e dos filhos de Judá, que fizeram, para me provocarem à ira, eles e os seus reis, os seus príncipes, os seus sacerdotes, e os seus profetas, como também os homens de Judá e os moradores de Jerusalém.',
        'E viraram-me as costas, e não o rosto; ainda que eu os ensinava, madrugando e ensinando-os, contudo eles não deram ouvidos, para receberem o ensino.',
        'Antes puseram as suas abominações na casa que se chama pelo meu nome, para a profanarem.',
        'E edificaram os altos de Baal, que estão no Vale do Filho de Hinom, para fazerem passar seus filhos e suas filhas pelo fogo a Moloque; o que nunca lhes ordenei, nem veio ao meu coração, que fizessem tal abominação, para fazerem pecar a Judá.',
        'E por isso agora assim diz o SENHOR, o Deus de Israel, acerca desta cidade, da qual vós dizeis: Já está dada na mão do rei de babilônia, pela espada, pela fome, e pela pestilência:',
        'Eis que eu os congregarei de todas as terras, para onde os tenho lançado na minha ira, e no meu furor, e na minha grande indignação; e os tornarei a trazer a este lugar, e farei que habitem nele seguramente.',
        'E eles serão o meu povo, e eu lhes serei o seu Deus;',
        'E lhes darei um mesmo coração, e um só caminho, para que me temam todos os dias, para seu bem, e o bem de seus filhos, depois deles.',
        'E farei com eles uma aliança eterna de não me desviar de fazer-lhes o bem; e porei o meu temor nos seus corações, para que nunca se apartem de mim.',
        'E alegrar-me-ei deles, fazendo-lhes bem; e plantá-los-ei nesta terra firmemente, com todo o meu coração e com toda a minha alma.',
        'Porque assim diz o Senhor: Como eu trouxe sobre este povo todo este grande mal, assim eu trarei sobre ele todo o bem que lhes tenho declarado.',
        'E comprar-se-ão campos nesta terra, da qual vós dizeis: Está desolada, sem homens, sem animais; está entregue na mão dos caldeus.',
        'Comprarão campos por dinheiro, e assinarão as escrituras, e as selarão, e farão que confirmem testemunhas, na terra de Benjamim, e nos contornos de Jerusalém, e nas cidades de Judá, e nas cidades das montanhas, e nas cidades das planícies, e nas cidades do sul; porque os farei voltar do seu cativeiro, diz o Senhor.',
      ],
      [
        'E veio a palavra do SENHOR a Jeremias, segunda vez, estando ele ainda encarcerado no pátio da guarda, dizendo:',
        'Assim diz o Senhor que faz isto, o Senhor que forma isto, para o estabelecer; o Senhor é o seu nome.',
        'Clama a mim, e responder-te-ei, e anunciar-te-ei coisas grandes e firmes que não sabes.',
        'Porque assim diz o Senhor, o Deus de Israel, acerca das casas desta cidade, e das casas dos reis de Judá, que foram derrubadas com os aríetes e à espada.',
        'Eles entraram a pelejar contra os caldeus, mas isso é para os encher de cadáveres de homens, que feri na minha ira e no meu furor; porquanto escondi o meu rosto desta cidade, por causa de toda a sua maldade.',
        'Eis que eu trarei a ela saúde e cura, e os sararei, e lhes manifestarei abundância de paz e de verdade.',
        'E removerei o cativeiro de Judá e o cativeiro de Israel, e os edificarei como ao princípio.',
        'E os purificarei de toda a sua maldade com que pecaram contra mim; e perdoarei todas as suas maldades, com que pecaram e transgrediram contra mim;',
        'E este lugar me servirá de nome, de gozo, de louvor, e de glória, entre todas as nações da terra, que ouvirem todo o bem que eu lhe faço; e espantar-se-ão e perturbar-se-ão por causa de todo o bem, e por causa de toda a paz que eu lhe dou.',
        'Assim diz o Senhor: Neste lugar de que vós dizeis que está desolado, e sem homem, sem animal nas cidades de Judá, e nas ruas de Jerusalém, que estão assoladas, sem homem, sem morador, sem animal, ainda se ouvirá:',
        'A voz de gozo, e a voz de alegria, a voz do esposo e a voz da esposa, e a voz dos que dizem: Louvai ao Senhor dos Exércitos, porque bom é o Senhor, porque a sua benignidade dura para sempre; dos que trazem ofertas de ação de graças à casa do Senhor; pois farei voltar os cativos da terra como ao princípio, diz o Senhor.',
        'Assim diz o Senhor dos Exércitos: Ainda neste lugar, que está deserto, sem homem nem animal, e em todas as suas cidades, haverá uma morada de pastores, que façam repousar aos seus rebanhos.',
        'Nas cidades das montanhas, nas cidades das planícies, e nas cidades do sul, e na terra de Benjamim, e nos contornos de Jerusalém, e nas cidades de Judá, ainda passarão os rebanhos pelas mãos dos contadores, diz o Senhor.',
        'Eis que vêm dias, diz o Senhor, em que cumprirei a boa palavra que falei à casa de Israel e à casa de Judá;',
        'Naqueles dias e naquele tempo farei brotar a Davi um Renovo de justiça, e ele fará juízo e justiça na terra.',
        'Naqueles dias Judá será salvo e Jerusalém habitará seguramente; e este é o nome com o qual Deus a chamará: O Senhor é a nossa justiça.',
        'Porque assim diz o Senhor: Nunca faltará a Davi homem que se assente sobre o trono da casa de Israel;',
        'Nem aos sacerdotes levíticos faltará homem diante de mim, que ofereça holocausto, queime oferta de alimentos e faça sacrifício todos os dias.',
        'E veio a palavra do Senhor a Jeremias, dizendo:',
        'Assim diz o Senhor: Se puderdes invalidar a minha aliança com o dia, e a minha aliança com a noite, de tal modo que não haja dia e noite a seu tempo,',
        'Também se poderá invalidar a minha aliança com Davi, meu servo, para que não tenha filho que reine no seu trono; como também com os levitas, sacerdotes, meus ministros.',
        'Como não se pode contar o exército dos céus, nem medir-se a areia do mar, assim multiplicarei a descendência de Davi, meu servo, e os levitas que ministram diante de mim.',
        'E veio ainda a palavra do Senhor a Jeremias, dizendo:',
        'Porventura não tens visto o que este povo está dizendo: As duas gerações, que o Senhor escolheu, agora as rejeitou? Assim desprezam o meu povo, como se não fora mais uma nação diante deles.',
        'Assim diz o Senhor: Se a minha aliança com o dia e com a noite não permanecer, e eu não puser as ordenanças dos céus e da terra,',
        'Também rejeitarei a descendência de Jacó, e de Davi, meu servo, para que não tome da sua descendência os que dominem sobre a descendência de Abraão, Isaque, e Jacó; porque removerei o seu cativeiro, e apiedar-me-ei deles.',
      ],
      [
        'A palavra que do SENHOR veio a Jeremias, quando Nabucodonosor, rei de babilônia, e todo o seu exército, e todos os reinos da terra, que estavam sob o domínio da sua mão, e todos os povos, pelejavam contra Jerusalém, e contra todas as suas cidades, dizendo:',
        'Assim diz o SENHOR, o Deus de Israel: Vai, e fala a Zedequias, rei de Judá, e dize-lhe: Assim diz o SENHOR: Eis que eu entrego esta cidade na mão do rei de babilônia, o qual queimá-la-á a fogo.',
        'E tu não escaparás da sua mão, antes certamente serás preso e entregue na sua mão; e teus olhos verão os olhos do rei de babilônia, e ele te falará boca a boca, e entrarás em babilônia.',
        'Todavia ouve a palavra do Senhor, ó Zedequias, rei de Judá; assim diz o Senhor acerca de ti: Não morrerás à espada.',
        'Em paz morrerás, e conforme as queimas para teus pais, os reis precedentes, que foram antes de ti, assim queimarão para ti, e prantear-te-ão, dizendo: Ah, Senhor! Pois eu disse a palavra, diz o Senhor.',
        'E falou Jeremias, o profeta, a Zedequias, rei de Judá, todas estas palavras, em Jerusalém,',
        'Quando o exército do rei de babilônia pelejava contra Jerusalém, e contra todas as cidades que restavam de Judá, contra Laquis e contra Azeca; porque estas fortes cidades foram as que ficaram dentre as cidades de Judá.',
        'A palavra que do Senhor veio a Jeremias, depois que o rei Zedequias fez aliança com todo o povo que havia em Jerusalém, para lhes apregoar a liberdade;',
        'Que cada um despedisse livre o seu servo, e cada um a sua serva, hebreu ou hebréia; de maneira que ninguém se fizesse servir deles, sendo judeus, seus irmãos.',
        'E obedeceram todos os príncipes, e todo o povo que havia entrado na aliança, que cada um despedisse livre o seu servo, e cada um a sua serva, de maneira que não se fizessem mais servir deles; obedeceram, pois, e os soltaram,',
        'Mas depois se arrependeram, e fizeram voltar os servos e as servas que haviam libertado, e os sujeitaram por servos e por servas.',
        'Veio, pois, a palavra do Senhor a Jeremias, da parte do Senhor, dizendo:',
        'Assim diz o Senhor, Deus de Israel: Eu fiz aliança com vossos pais, no dia em que os tirei da terra do Egito, da casa da servidão, dizendo:',
        'Ao fim de sete anos libertareis cada um a seu irmão hebreu, que te for vendido, e te houver servido seis anos, e despedi-lo-ás livre de ti; mas vossos pais não me ouviram, nem inclinaram os seus ouvidos.',
        'E vos havíeis hoje arrependido, e fizestes o que é reto aos meus olhos, apregoando liberdade cada um ao seu próximo; e fizestes diante de mim uma aliança, na casa que se chama pelo meu nome;',
        'Mudastes, porém, e profanastes o meu nome, e fizestes voltar cada um ao seu servo, e cada um à sua serva, os quais já tínheis despedido libertos conforme a vontade deles; e os sujeitastes, para que se vos fizessem servos e servas.',
        'Portanto assim diz o Senhor: Vós não me ouvistes a mim, para apregoardes a liberdade, cada um ao seu irmão, e cada um ao seu próximo; pois eis que eu vos apregôo a liberdade, diz o Senhor, para a espada, para a pestilência, e para a fome; e farei que sejais espanto a todos os reinos da terra.',
        'E entregarei os homens que transgrediram a minha aliança, que não cumpriram as palavras da aliança que fizeram diante de mim, com o bezerro, que dividiram em duas partes, e passaram pelo meio das suas porções;',
        'A saber, os príncipes de Judá, e os príncipes de Jerusalém, os eunucos, e os sacerdotes, e todo o povo da terra que passou por meio das porções do bezerro;',
        'Entregá-los-ei, digo, na mão de seus inimigos, e na mão dos que procuram a sua morte, e os cadáveres deles servirão de alimento para as aves dos céus e para os animais da terra.',
        'E até o rei Zedequias, rei de Judá, e seus príncipes entregarei na mão de seus inimigos e na mão dos que procuram a sua morte, a saber, na mão do exército do rei de babilônia, que já se retirou de vós.',
        'Eis que eu darei ordem, diz o Senhor, e os farei voltar a esta cidade, e pelejarão contra ela, e a tomarão, e a queimarão a fogo; e as cidades de Judá porei em assolação, de sorte que ninguém habite nelas.',
      ],
      [
        'A palavra que do SENHOR veio a Jeremias, nos dias de Jeoiaquim, filho de Josias, rei de Judá, dizendo:',
        'Vai à casa dos recabitas, e fala com eles, e leva-os à casa do Senhor, a uma das câmaras e dá-lhes vinho a beber.',
        'Então tomei a Jazanias, filho de Jeremias, filho de Habazinias, e a seus irmãos, e a todos os seus filhos, e a toda a casa dos recabitas;',
        'E os levei à casa do Senhor, à câmara dos filhos de Hanã, filho de Jigdalias, homem de Deus, que estava junto à câmara dos príncipes, que ficava sobre a câmara de Maaséias, filho de Salum, guarda do vestíbulo;',
        'E pus diante dos filhos da casa dos recabitas taças cheias de vinho, e copos, e disse-lhes: Bebei vinho.',
        'Porém eles disseram: Não beberemos vinho, porque Jonadabe, filho de Recabe, nosso pai, nos ordenou, dizendo: Nunca jamais bebereis vinho, nem vós nem vossos filhos;',
        'Não edificareis casa, nem semeareis semente, nem plantareis vinha, nem a possuireis; mas habitareis em tendas todos os vossos dias, para que vivais muitos dias sobre a face da terra, em que vós andais peregrinando.',
        'Obedecemos, pois, à voz de Jonadabe, filho de Recabe, nosso pai, em tudo quanto nos ordenou; de maneira que não bebemos vinho em todos os nossos dias, nem nós, nem nossas mulheres, nem nossos filhos, nem nossas filhas;',
        'Nem edificamos casas para nossa habitação; nem temos vinha, nem campo, nem semente.',
        'Mas habitamos em tendas, e assim obedecemos e fazemos conforme tudo quanto nos ordenou Jonadabe, nosso pai.',
        'Sucedeu, porém, que, subindo Nabucodonosor, rei de babilônia, a esta terra, dissemos: Vinde, e vamo-nos a Jerusalém, por causa do exército dos caldeus, e por causa do exército dos sírios; e assim ficamos em Jerusalém.',
        'Então veio a palavra do Senhor a Jeremias, dizendo:',
        'Assim diz o Senhor dos Exércitos, o Deus de Israel: Vai, e dize aos homens de Judá e aos moradores de Jerusalém: Porventura nunca aceitareis instrução, para ouvirdes as minhas palavras? diz o Senhor.',
        'As palavras de Jonadabe, filho de Recabe, que ordenou a seus filhos que não bebessem vinho, foram guardadas; pois não beberam até este dia, antes obedeceram o mandamento de seu pai; a mim, porém, que vos tenho falado, madrugando e falando, não me ouvistes.',
        'E vos tenho enviado todos os meus servos, os profetas, madrugando, e insistindo, e dizendo: Convertei-vos, agora, cada um do seu mau caminho, e fazei boas as vossas ações, e não sigais a outros deuses para servi-los; e assim ficareis na terra que vos dei a vós e a vossos pais; porém não inclinastes o vosso ouvido, nem me obedecestes a mim.',
        'Visto que os filhos de Jonadabe, filho de Recabe, guardaram o mandamento de seu pai que ele lhes ordenou, mas este povo não me obedeceu,',
        'Por isso assim diz o Senhor Deus dos Exércitos, o Deus de Israel: Eis que trarei sobre Judá, e sobre todos os moradores de Jerusalém, todo o mal que falei contra eles; pois lhes tenho falado, e não ouviram; e clamei a eles, e não responderam.',
        'E à casa dos recabitas disse Jeremias: Assim diz o Senhor dos Exércitos, o Deus de Israel: Pois que obedecestes ao mandamento de Jonadabe, vosso pai, e guardastes todos os seus mandamentos, e fizestes conforme tudo quanto vos ordenou,',
        'Portanto assim diz o Senhor dos Exércitos, Deus de Israel: Nunca faltará homem a Jonadabe, filho de Recabe, que esteja na minha presença todos os dias.',
      ],
      [
        'Sucedeu, pois, no ano quarto de Jeoiaquim, filho de Josias, rei de Judá, que veio esta palavra do SENHOR a Jeremias, dizendo:',
        'Toma o rolo de um livro, e escreve nele todas as palavras que te tenho falado de Israel, e de Judá, e de todas as nações, desde o dia em que eu te falei, desde os dias de Josias até ao dia de hoje.',
        'Porventura ouvirão os da casa de Judá todo o mal que eu intento fazer-lhes; para que cada qual se converta do seu mau caminho, e eu perdoe a sua maldade e o seu pecado.',
        'Então Jeremias chamou a Baruque, filho de Nerias; e escreveu Baruque da boca de Jeremias no rolo de um livro todas as palavras do Senhor, que ele lhe tinha falado.',
        'E Jeremias deu ordem a Baruque, dizendo: Eu estou encarcerado; não posso entrar na casa do Senhor.',
        'Entra, pois, tu, e pelo rolo que escreveste da minha boca, lê as palavras do Senhor aos ouvidos do povo, na casa do Senhor, no dia de jejum; e também, aos ouvidos de todos os de Judá, que vêm das suas cidades, as lerás.',
        'Pode ser que caia a sua súplica diante do Senhor, e se converta cada um do seu mau caminho; porque grande é a ira e o furor que o Senhor tem expressado contra este povo.',
        'E fez Baruque, filho de Nerias, conforme tudo quanto lhe havia ordenado Jeremias, o profeta, lendo naquele livro as palavras do Senhor, na casa do Senhor.',
        'E aconteceu, no quinto ano de Jeoiaquim, filho de Josias, rei de Judá, no mês nono, que apregoaram jejum diante do Senhor a todo o povo em Jerusalém, como também a todo o povo que vinha das cidades de Judá a Jerusalém.',
        'Leu, pois, Baruque naquele livro as palavras de Jeremias, na casa do Senhor, na câmara de Gemarias, filho de Safã, o escriba, no átrio superior, à entrada da porta nova da casa do Senhor, aos ouvidos de todo o povo.',
        'E, ouvindo Micaías, filho de Gemarias, filho de Safã, todas as palavras do Senhor, daquele livro,',
        'Desceu à casa do rei, à câmara do escriba. E eis que todos os príncipes estavam ali assentados, a saber: Elisama, o escriba, e Delaías, filho de Semaías, e Elnatã, filho de Acbor, e Gemarias, filho de Safã, e Zedequias, filho de Hananias, e todos os outros príncipes.',
        'E Micaías anunciou-lhes todas as palavras que ouvira, quando Baruque leu o livro, aos ouvidos do povo.',
        'Então todos os príncipes mandaram Jeudi, filho de Netanias, filho de Selemias, filho de Cusi, a Baruque, para lhe dizer: O rolo que leste aos ouvidos do povo, toma-o na tua mão, e vem. E Baruque, filho de Nerias, tomou o rolo na sua mão, e foi ter com eles.',
        'E disseram-lhe: Assenta-te agora, e lê-o aos nossos ouvidos. E leu Baruque aos ouvidos deles.',
        'E sucedeu que, ouvindo eles todas aquelas palavras, voltaram-se temerosos uns para os outros, e disseram a Baruque: Sem dúvida alguma anunciaremos ao rei todas estas palavras.',
        'E perguntaram a Baruque, dizendo: Declara-nos agora como escreveste da sua boca todas estas palavras.',
        'E disse-lhes Baruque: Da sua boca ele me ditava todas estas palavras, e eu com tinta as escrevia no livro.',
        'Então disseram os príncipes a Baruque: Vai, esconde-te, tu e Jeremias, e ninguém saiba onde estais.',
        'E foram ter com o rei ao átrio: mas depositaram o rolo na câmara de Elisama, o escriba, e anunciaram aos ouvidos do rei todas aquelas palavras.',
        'Então enviou o rei a Jeudi, para que tomasse o rolo; e Jeudi tomou-o da câmara de Elisama, o escriba, e leu-o aos ouvidos do rei e aos ouvidos de todos os príncipes que estavam em torno do rei.',
        'Ora, o rei estava assentado na casa de inverno, pelo nono mês; e diante dele estava um braseiro aceso.',
        'E sucedeu que, tendo Jeudi lido três ou quatro folhas, cortou-as com um canivete de escrivão, e lançou-as no fogo que havia no braseiro, até que todo o rolo se consumiu no fogo que estava sobre o braseiro.',
        'E não temeram, nem rasgaram as suas vestes, nem o rei, nem nenhum dos seus servos que ouviram todas aquelas palavras.',
        'E, posto que Elnatã, e Delaías, e Gemarias tivessem rogado ao rei que não queimasse o rolo, ele não lhes deu ouvidos.',
        'Antes deu ordem o rei a Jerameel, filho de Hamaleque, e a Seraías, filho de Azriel, e a Selemias, filho de Abdeel, que prendessem a Baruque, o escrivão, e a Jeremias, o profeta; mas o Senhor os escondera.',
        'Então veio a Jeremias a palavra do Senhor, depois que o rei queimara o rolo, com as palavras que Baruque escrevera da boca de Jeremias, dizendo:',
        'Toma ainda outro rolo, e escreve nele todas aquelas palavras que estavam no primeiro rolo, que queimou Jeoiaquim, rei de Judá.',
        'E a Jeoiaquim, rei de Judá, dirás: Assim diz o SENHOR: Tu queimaste este rolo, dizendo: Por que escreveste nele, dizendo: Certamente virá o rei de babilônia, e destruirá esta terra e fará cessar nela homens e animais?',
        'Portanto assim diz o Senhor, acerca de Jeoiaquim, rei de Judá: Não terá quem se assente sobre o trono de Davi, e será lançado o seu cadáver ao calor do dia, e à geada da noite.',
        'E castigarei a sua iniqüidade nele, e na sua descendência, e nos seus servos; e trarei sobre ele e sobre os moradores de Jerusalém, e sobre os homens de Judá, todo aquele mal que lhes tenho falado, e não ouviram.',
        'Tomou, pois, Jeremias outro rolo, e deu-o a Baruque, filho de Nerias, o escrivão, o qual escreveu nele, da boca de Jeremias, todas as palavras do livro que Jeoiaquim, rei de Judá, tinha queimado no fogo; e ainda se lhes acrescentaram muitas palavras semelhantes.',
      ],
      [
        'E Zedequias, filho de Josias, a quem Nabucodonosor, rei de babilônia, constituiu rei na terra de Judá, reinou em lugar de Conias, filho de Jeoiaquim.',
        'Mas nem ele, nem os seus servos, nem o povo da terra deram ouvidos às palavras do Senhor que falou pelo ministério de Jeremias, o profeta.',
        'Contudo mandou o rei Zedequias a Jucal, filho de Selemias, e a Sofonias, filho de Maaséias, o sacerdote, ao profeta Jeremias, para lhe dizer: Roga agora por nós ao Senhor nosso Deus.',
        'E entrava e saía Jeremias entre o povo, porque não o tinham posto na prisão.',
        'E o exército de Faraó saíra do Egito; e quando os caldeus, que tinham sitiado Jerusalém, ouviram esta notícia, retiraram-se de Jerusalém.',
        'Então veio a Jeremias, o profeta, a palavra do Senhor, dizendo:',
        'Assim diz o Senhor, Deus de Israel: Assim direis ao rei de Judá, que vos enviou a mim para me consultar: Eis que o exército de Faraó, que saiu em vosso socorro, voltará para a sua terra no Egito.',
        'E voltarão os caldeus, e pelejarão contra esta cidade, e a tomarão, e a queimarão a fogo.',
        'Assim diz o Senhor: Não enganeis as vossas almas, dizendo: Sem dúvida se retirarão os caldeus de nós, pois não se retirarão.',
        'Porque ainda que ferísseis a todo o exército dos caldeus, que peleja contra vós, e só ficassem deles homens feridos, cada um levantar-se-ia na sua tenda, e queimaria a fogo esta cidade.',
        'E sucedeu que, subindo de Jerusalém o exército dos caldeus, por causa do exército de Faraó,',
        'Saiu Jeremias de Jerusalém, a fim de ir à terra de Benjamim, para dali se separar no meio do povo.',
        'Mas, estando ele à porta de Benjamim, achava-se ali um capitão da guarda, cujo nome era Jerias, filho de Selemias, filho de Hananias, o qual prendeu a Jeremias, o profeta, dizendo: Tu foges para os caldeus.',
        'E Jeremias disse: Isso é falso, não fujo para os caldeus. Mas ele não lhe deu ouvidos; e assim Jerias prendeu a Jeremias, e o levou aos príncipes.',
        'E os príncipes se iraram muito contra Jeremias, e o feriram; e puseram-no na prisão, na casa de Jônatas, o escrivão; porque a tinham transformado em cárcere.',
        'Entrando, pois, Jeremias nas celas do calabouço, ali ficou muitos dias.',
        'E mandou o rei Zedequias soltá-lo; e o rei lhe perguntou em sua casa, em segredo: Há porventura alguma palavra do SENHOR? E disse Jeremias: Há. E disse ainda: Na mão do rei de babilônia serás entregue.',
        'Disse mais Jeremias ao rei Zedequias: Em que tenho pecado contra ti, e contra os teus servos, e contra este povo, para que me pusésseis na prisão?',
        'Onde estão agora os vossos profetas, que vos profetizavam, dizendo: O rei de babilônia não virá contra vós nem contra esta terra?',
        'Ora, pois, ouve agora, ó rei meu senhor: Seja aceita agora a minha súplica diante de ti, e não me deixes tornar à casa de Jônatas, o escriba, para que eu não venha a morrer ali.',
        'Então ordenou o rei Zedequias que pusessem a Jeremias no átrio da guarda; e deram-lhe um pão cada dia, da rua dos padeiros, até que se acabou todo o pão da cidade; assim ficou Jeremias no átrio da guarda.',
      ],
      [
        'Ouviram, pois, Sefatias, filho de Matã, e Gedalias, filho de Pasur, e Jucal, filho de Selemias, e Pasur, filho de Malquias, as palavras que anunciava Jeremias a todo o povo, dizendo:',
        'Assim diz o Senhor: O que ficar nesta cidade morrerá à espada, de fome e de pestilência; mas o que sair aos caldeus viverá; porque a sua alma lhe será por despojo, e viverá.',
        'Assim diz o SENHOR: Esta cidade infalivelmente será entregue na mão do exército do rei de babilônia, e ele a tomará.',
        'E disseram os príncipes ao rei: Morra este homem, visto que ele assim enfraquece as mãos dos homens de guerra que restam nesta cidade, e as mãos de todo o povo, dizendo-lhes tais palavras; porque este homem não busca a paz para este povo, porém o mal.',
        'E disse o rei Zedequias: Eis que ele está na vossa mão; porque o rei nada pode fazer contra vós.',
        'Então tomaram a Jeremias, e o lançaram na cisterna de Malquias, filho do rei, que estava no átrio da guarda; e desceram a Jeremias com cordas; mas na cisterna não havia água, senão lama; e atolou-se Jeremias na lama.',
        'E, ouvindo Ebede-Meleque, o etíope, um eunuco que então estava na casa do rei, que tinham posto a Jeremias na cisterna (estava, porém, o rei assentado à porta de Benjamim),',
        'Logo Ebede-Meleque saiu da casa do rei, e falou ao rei, dizendo:',
        'Ó rei, senhor meu, estes homens agiram mal em tudo quanto fizeram a Jeremias, o profeta, lançando-o na cisterna; de certo morrerá de fome no lugar onde se acha, pois não há mais pão na cidade.',
        'Então deu ordem o rei a Ebede-Meleque, o etíope, dizendo: Toma contigo daqui trinta homens, e tira a Jeremias, o profeta, da cisterna, antes que morra.',
        'E tomou Ebede-Meleque os homens consigo, e foi à casa do rei, por debaixo da tesouraria, e tomou dali uns trapos velhos e rotos, e roupas velhas, e desceu-os a Jeremias na cisterna por meio de cordas.',
        'E disse Ebede-Meleque, o etíope, a Jeremias: Põe agora estes trapos velhos e rotos, já apodrecidos, nas axilas, calçando as cordas. E Jeremias assim o fez.',
        'E puxaram a Jeremias com as cordas, e o alçaram da cisterna; e ficou Jeremias no átrio da guarda.',
        'Então o rei Zedequias mandou trazer à sua presença Jeremias, o profeta, à terceira entrada da casa do Senhor; e disse o rei a Jeremias: Pergunto-te uma coisa, não me encubras nada.',
        'E disse Jeremias a Zedequias: Se eu te declarar, porventura não me matarás? E se eu te aconselhar, não me ouvirás?',
        'Então jurou o rei Zedequias a Jeremias, em segredo, dizendo: Vive o Senhor, que nos fez esta alma, que não te matarei nem te entregarei na mão destes homens que procuram a tua morte.',
        'Então Jeremias disse a Zedequias: Assim diz o SENHOR, Deus dos Exércitos, Deus de Israel: Se voluntariamente saíres aos príncipes do rei de babilônia, então viverá a tua alma, e esta cidade não se queimará a fogo, e viverás tu e a tua casa.',
        'Mas, se não saíres aos príncipes do rei de babilônia, então será entregue esta cidade na mão dos caldeus, e queimá-la-ão a fogo, e tu não escaparás da mão deles.',
        'E disse o rei Zedequias a Jeremias: Receio-me dos judeus, que se passaram para os caldeus; que estes me entreguem na mão deles, e escarneçam de mim.',
        'E disse Jeremias: Não te entregarão; ouve, peço-te, a voz do Senhor, conforme a qual eu te falo; e bem te irá, e viverá a tua alma.',
        'Mas, se tu não quiseres sair, esta é a palavra que me mostrou o Senhor:',
        'Eis que todas as mulheres que ficaram na casa do rei de Judá serão levadas aos príncipes do rei de babilônia, e elas mesmas dirão: Teus pacificadores te incitaram e prevaleceram contra ti, mas agora que se atolaram os teus pés na lama, voltaram atrás.',
        'Assim que a todas as tuas mulheres e a teus filhos levarão aos caldeus, e nem tu escaparás da sua mão, antes pela mão do rei de babilônia serás preso, e esta cidade será queimada a fogo.',
        'Então disse Zedequias a Jeremias: Ninguém saiba estas palavras, e não morrerás.',
        'E quando os príncipes, ouvindo que falei contigo, vierem a ti, e te disserem: Declara-nos agora o que disseste ao rei e o que ele te disse, não no-lo encubras, e não te mataremos;',
        'Então lhes dirás: Eu lancei a minha súplica diante do rei, que não me fizesse tornar à casa de Jônatas, para morrer ali.',
        'Vindo, pois, todos os príncipes a Jeremias, e interrogando-o, declarou-lhes todas as palavras que o rei lhe havia ordenado; e calados o deixaram, porque o assunto não foi revelado.',
        'E ficou Jeremias no átrio da guarda, até o dia em que Jerusalém foi tomada, e ainda ali estava quando Jerusalém foi tomada.',
      ],
      [
        'No ano nono de Zedequias, rei de Judá, no décimo mês, veio Nabucodonosor, rei de babilônia, e todo o seu exército, contra Jerusalém, e a cercaram.',
        'No ano undécimo de Zedequias, no quarto mês, aos nove do mês, fez-se uma brecha na cidade.',
        'Entraram nela todos os príncipes do rei de babilônia, e pararam na porta do meio, a saber: Nergal-Sarezer, Sangar-Nebo, Sarsequim, Rabe-Saris, Nergal-Sarezer, Rabe-Mague, e todos os outros príncipes do rei de babilônia.',
        'E sucedeu que, vendo-os Zedequias, rei de Judá, e todos os homens de guerra, fugiram, saindo de noite da cidade, pelo caminho do jardim do rei, pela porta que está entre os dois muros; e seguiram pelo caminho da campina.',
        'Mas o exército dos caldeus os perseguiu, e alcançou a Zedequias nas campinas de Jericó; e eles o prenderam, e fizeram-no subir a Nabucodonosor, rei de babilônia, a Ribla, na terra de Hamate, e o rei o sentenciou.',
        'E o rei de babilônia matou em Ribla os filhos de Zedequias, diante dos seus olhos; também matou o rei de babilônia a todos os nobres de Judá.',
        'E cegou os olhos de Zedequias, e o atou com duas cadeias de bronze, para levá-lo a babilônia.',
        'E os caldeus incendiaram a casa do rei e as casas do povo, e derrubaram os muros de Jerusalém.',
        'E o restante do povo, que ficou na cidade, e os desertores que se tinham passado para ele, e o restante do povo que ficou, Nebuzaradã, capitão da guarda, levou cativo para a babilônia.',
        'Porém os pobres dentre o povo, que não tinham nada, Nebuzaradã, capitão da guarda, deixou na terra de Judá; e deu-lhes vinhas e campos naquele dia.',
        'Mas Nabucodonosor, rei de babilônia, havia ordenado acerca de Jeremias, a Nebuzaradã, capitão da guarda, dizendo:',
        'Toma-o, e põe sobre ele os teus olhos, e não lhe faças nenhum mal; antes como ele te disser, assim procederás com ele.',
        'Por isso mandou Nebuzaradã, capitão da guarda, e Nebusazbã, Rabe-Saris, Nergal-Sarezer, Rabe-Mague, e todos os príncipes do rei de babilônia,',
        'Mandaram retirar a Jeremias do átrio da guarda, e o entregaram a Gedalias, filho de Aicão, filho de Safã, para que o levassem à casa; e ele habitou entre o povo.',
        'Ora, tinha vindo a Jeremias a palavra do Senhor, estando ele ainda encarcerado no átrio da guarda, dizendo:',
        'Vai, e fala a Ebede-Meleque, o etíope, dizendo: Assim diz o Senhor dos Exércitos, Deus de Israel: Eis que eu trarei as minhas palavras sobre esta cidade para mal e não para bem; e cumprir-se-ão diante de ti naquele dia.',
        'A ti, porém, eu livrarei naquele dia, diz o Senhor, e não serás entregue na mão dos homens, a quem temes.',
        'Porque certamente te livrarei, e não cairás à espada; mas a tua alma terás por despojo, porquanto confiaste em mim, diz o Senhor.',
      ],
      [
        'A palavra que veio a Jeremias da parte do SENHOR, depois que Nebuzaradã, capitão da guarda, o deixara ir de Ramá, quando o tomou, estando ele atado com cadeias no meio de todos os do cativeiro de Jerusalém e de Judá, que foram levados cativos para babilônia.',
        'Tomou o capitão da guarda a Jeremias, e disse-lhe: O Senhor teu Deus pronunciou este mal, contra este lugar.',
        'E o Senhor o trouxe, e fez como havia falado; porque pecastes contra o Senhor, e não obedecestes à sua voz, portanto vos sucedeu isto.',
        'Agora, pois, eis que te soltei hoje das cadeias que estavam sobre as tuas mãos. Se te apraz vir comigo para babilônia, vem, e eu cuidarei de ti, mas se não te apraz vir comigo para babilônia, deixa de vir. Olha, toda a terra está diante de ti; para onde parecer bom e reto aos teus olhos ir, para ali vai.',
        'Mas, como ele ainda não tinha voltado, disse-lhe: Volta a Gedalias, filho de Aicão, filho de Safã, a quem o rei de babilônia pôs sobre as cidades de Judá, e habita com ele no meio do povo; ou se para qualquer outra parte te aprouver ir, vai. E deu-lhe o capitão da guarda sustento para o caminho, e um presente, e o deixou ir.',
        'Assim veio Jeremias a Gedalias, filho de Aicão, a Mizpá; e habitou com ele no meio do povo que havia ficado na terra.',
        'Ouvindo, pois, todos os capitães dos exércitos, que estavam no campo, eles e os seus homens, que o rei de babilônia tinha nomeado a Gedalias, filho de Aicão, governador da terra, e que lhe havia confiado os homens, e as mulheres, e os meninos, e os mais pobres da terra, que não foram levados cativos a babilônia,',
        'Vieram ter com Gedalias, a Mizpá; a saber: Ismael, filho de Netanias, e Joanã e Jônatas, filhos de Careá, e Seraías, filho de Tanumete, e os filhos de Efai, o netofatita, e Jezanias, filho de um maacatita, eles e os seus homens.',
        'E jurou Gedalias, filho de Aicão, filho de Safã, a eles e aos seus homens, dizendo: Não temais servir aos caldeus; ficai na terra, e servi o rei de babilônia, e bem vos irá.',
        'Quanto a mim, eis que habito em Mizpá, para estar às ordens dos caldeus que vierem a nós; e vós recolhei o vinho, e as frutas de verão, e o azeite, e colocai-os nos vossos vasos, e habitai nas vossas cidades, que tomastes.',
        'Do mesmo modo todos os judeus que estavam em Moabe, e entre os filhos de Amom, e em Edom, e os que havia em todas aquelas terras, ouviram que o rei de babilônia havia deixado alguns em Judá, e que havia posto sobre eles a Gedalias, filho de Aicão, filho de Safã,',
        'Então voltaram todos os judeus de todos os lugares, para onde foram lançados, e vieram à terra de Judá, a Gedalias, a Mizpá; e recolheram vinho e frutas do verão com muita abundância.',
        'Joanã, filho de Careá, e todos os capitães dos exércitos, que estavam no campo, vieram a Gedalias, a Mizpá.',
        'E disseram-lhe: Bem sabes que Baalis, rei dos filhos de Amom, enviou a Ismael, filho de Netanias, para tirar-te a vida. Mas, Gedalias, filho de Aicão, não lhes deu crédito.',
        'Todavia Joanã, filho de Careá, falou a Gedalias em segredo, em Mizpá, dizendo: Irei agora, e ferirei a Ismael, filho de Netanias, sem que ninguém o saiba; por que razão te tiraria ele a vida, de modo que todos os judeus, que se têm congregado a ti, fossem dispersos, e perecesse o restante de Judá?',
        'Mas disse Gedalias, filho de Aicão, a Joanã, filho de Careá: Não faças tal coisa; porque falas falsamente contra Ismael.',
      ],
      [
        'Sucedeu, porém, no mês sétimo, que veio Ismael, filho de Netanias, filho de Elisama, de sangue real, e com ele dez homens, príncipes do rei, a Gedalias, filho de Aicão, a Mizpá; e comeram pão juntos ali em Mizpá.',
        'E levantou-se Ismael, filho de Netanias, com os dez homens que estavam com ele, e feriram à espada a Gedalias, filho de Aicão, filho de Safã, matando assim aquele que o rei de babilônia havia posto por governador sobre a terra.',
        'Também matou Ismael a todos os judeus que com ele, com Gedalias, estavam em Mizpá, como também aos caldeus, homens de guerra, que se achavam ali.',
        'Sucedeu, pois, no dia seguinte, depois que ele matara a Gedalias, sem ninguém o saber,',
        'Que vieram homens de Siquém, de Siló, e de Samaria; oitenta homens, com a barba rapada, e as vestes rasgadas, e retalhando-se; e trazendo nas suas mãos ofertas e incenso, para levarem à casa do Senhor.',
        'E, saindo-lhes ao encontro Ismael, filho de Netanias, desde Mizpá, ia chorando; e sucedeu que, encontrando-os lhes disse: Vinde a Gedalias, filho de Aicão.',
        'Sucedeu, porém, que, entrando eles até ao meio da cidade, matou-os Ismael, filho de Netanias, e os lançou num poço, ele e os homens que estavam com ele.',
        'Mas houve entre eles dez homens que disseram a Ismael: Não nos mates, porque temos, no campo, tesouros, trigo, cevada, azeite e mel. E ele por isso os deixou, e não os matou entre seus irmãos.',
        'E o poço em que Ismael lançou todos os cadáveres dos homens que matou por causa de Gedalias é o mesmo que fez o rei Asa, por causa de Baasa, rei de Israel; foi esse mesmo que Ismael, filho de Netanias, encheu de mortos.',
        'E Ismael levou cativo a todo o restante do povo que estava em Mizpá, isto é, as filhas do rei, e todo o povo que ficara em Mizpá, que Nebuzaradã, capitão da guarda, havia confiado a Gedalias, filho de Aicão; e levou-os cativos Ismael, filho de Netanias, e se foi para passar aos filhos de Amom.',
        'Ouvindo, pois, Joanã, filho de Careá, e todos os capitães dos exércitos que estavam com ele, todo o mal que havia feito Ismael, filho de Netanias,',
        'Tomaram todos os seus homens, e foram pelejar contra Ismael, filho de Netanias; e acharam-no ao pé das grandes águas que há em Gibeom.',
        'E aconteceu que, vendo todo o povo, que estava com Ismael, a Joanã, filho de Careá, e a todos os capitães dos exércitos, que vinham com ele, se alegrou.',
        'E todo o povo que Ismael levara cativo de Mizpá virou as costas, e voltou, e foi para Joanã, filho de Careá.',
        'Mas Ismael, filho de Netanias, escapou com oito homens de diante de Joanã, e se foi para os filhos de Amom.',
        'Então tomou Joanã, filho de Careá, e todos os capitães dos exércitos que estavam com ele, a todo o restante do povo que ele havia recobrado de Ismael, filho de Netanias, desde Mizpá, depois de haver matado a Gedalias, filho de Aicão, isto é, aos homens poderosos de guerra, e às mulheres, e aos meninos, e aos eunucos que havia recobrado de Gibeom.',
        'E partiram, indo habitar em Gerute-Quimã, que está perto de Belém, para dali irem e entrarem no Egito,',
        'Por causa dos caldeus; porque os temiam, por ter Ismael, filho de Netanias, matado a Gedalias, filho de Aicão, a quem o rei de babilônia tinha feito governador sobre a terra.',
      ],
      [
        'Então chegaram todos os capitães dos exércitos, e Joanã, filho de Careá, e Jezanias, filho de Hosaías, e todo o povo, desde o menor até ao maior,',
        'E disseram a Jeremias, o profeta: Aceita agora a nossa súplica diante de ti, e roga ao Senhor teu Deus, por nós e por todo este remanescente; porque de muitos restamos uns poucos, como nos vêem os teus olhos;',
        'Para que o Senhor teu Deus nos ensine o caminho por onde havemos de andar e aquilo que havemos de fazer.',
        'E disse-lhes Jeremias, o profeta: Eu vos tenho ouvido; eis que orarei ao Senhor vosso Deus conforme as vossas palavras; e seja o que for que o Senhor vos responder eu vo-lo declararei; não vos ocultarei uma só palavra.',
        'Então eles disseram a Jeremias: Seja o Senhor entre nós testemunha verdadeira e fiel, se não fizermos conforme toda a palavra com que te enviar a nós o Senhor teu Deus.',
        'Seja ela boa, ou seja má, à voz do Senhor nosso Deus, a quem te enviamos, obedeceremos, para que nos suceda bem, obedecendo à voz do Senhor nosso Deus.',
        'E sucedeu que ao fim de dez dias veio a palavra do Senhor a Jeremias.',
        'Então chamou a Joanã, filho de Careá, e a todos os capitães dos exércitos, que havia com ele, e a todo o povo, desde o menor até ao maior,',
        'E disse-lhes: Assim diz o Senhor, Deus de Israel, a quem me enviastes, para apresentar a vossa súplica diante dele:',
        'Se de boa mente ficardes nesta terra, então vos edificarei, e não vos derrubarei; e vos plantarei, e não vos arrancarei; porque estou arrependido do mal que vos tenho feito.',
        'Não temais o rei de babilônia, a quem vós temeis; não o temais, diz o SENHOR, porque eu sou convosco, para vos salvar e para vos livrar da sua mão.',
        'E vos concederei misericórdia, para que ele tenha misericórdia de vós, e vos faça voltar à vossa terra.',
        'Mas se vós disserdes: Não ficaremos nesta terra, não obedecendo à voz do Senhor vosso Deus,',
        'Dizendo: Não, antes iremos à terra do Egito, onde não veremos guerra, nem ouviremos som de trombeta, nem teremos fome de pão, e ali ficaremos,',
        'Nesse caso ouvi a palavra do Senhor, ó remanescente de Judá: Assim diz o Senhor dos Exércitos, Deus de Israel: Se vós absolutamente propuserdes a entrar no Egito, e entrardes para lá habitar,',
        'Acontecerá que a espada que vós temeis vos alcançará ali na terra do Egito, e a fome que vós receais vos seguirá de perto no Egito, e ali morrereis.',
        'Assim será com todos os homens que puseram os seus rostos para entrarem no Egito, a fim de lá habitarem: morrerão à espada, e de fome, e de peste; e deles não haverá quem reste e escape do mal que eu farei vir sobre eles.',
        'Porque assim diz o Senhor dos Exércitos, Deus de Israel: Como se derramou a minha ira e a minha indignação sobre os habitantes de Jerusalém, assim se derramará a minha indignação sobre vós, quando entrardes no Egito; e sereis objeto de maldição, e de espanto, e de execração, e de opróbrio, e não vereis mais este lugar.',
        'Falou o Senhor acerca de vós, ó remanescente de Judá! Não entreis no Egito; tende por certo que hoje testifiquei contra vós.',
        'Porque vos enganastes a vós mesmos, pois me enviastes ao Senhor vosso Deus, dizendo: Ora por nós ao Senhor nosso Deus; e conforme tudo o que disser o Senhor nosso Deus, declara-no-lo assim, e o faremos.',
        'E vo-lo tenho declarado hoje; mas não destes ouvidos à voz do Senhor vosso Deus, em coisa alguma pela qual ele me enviou a vós.',
        'Agora, pois, sabei por certo que morrereis à espada, de fome e de peste no mesmo lugar onde desejais ir, para lá morardes.',
      ],
      [
        'E sucedeu que, acabando Jeremias de falar a todo o povo todas as palavras do SENHOR seu Deus, com as quais o SENHOR seu Deus lho havia enviado, para que lhes dissesse todas estas palavras,',
        'Então falaram Azarias, filho de Hosaías, e Joanã, filho de Careá, e todos os homens soberbos, dizendo a Jeremias: Tu dizes mentiras; o Senhor nosso Deus não te enviou a dizer: Não entreis no Egito, para ali habitar;',
        'Mas Baruque, filho de Nerias, te incita contra nós, para entregar-nos na mão dos caldeus, para nos matarem, ou para nos levarem cativos para babilônia.',
        'Não obedeceu, pois, Joanã, filho de Careá, nem nenhum de todos os capitães dos exércitos, nem o povo todo, à voz do Senhor, para ficarem na terra de Judá.',
        'Antes tomou Joanã, filho de Careá, e todos os capitães dos exércitos a todo o restante de Judá, que havia voltado dentre todas as nações, para onde haviam sido lançados, para morarem na terra de Judá;',
        'Aos homens, e às mulheres, e aos meninos, e às filhas do rei, e a toda a alma que Nebuzaradã, capitão da guarda, deixara com Gedalias, filho de Aicão, filho de Safã; como também a Jeremias, o profeta, e a Baruque, filho de Nerias;',
        'E entraram na terra do Egito, porque não obedeceram à voz do Senhor; e vieram até Tafnes.',
        'Então veio a palavra do Senhor a Jeremias, em Tafnes, dizendo:',
        'Toma na tua mão pedras grandes, e esconde-as no barro, no forno que está à entrada da casa de Faraó, em Tafnes, perante os olhos dos homens de Judá,',
        'E dize-lhes: Assim diz o SENHOR dos Exércitos, Deus de Israel: Eis que eu enviarei, e tomarei a Nabucodonosor, rei de babilônia, meu servo, e porei o seu trono sobre estas pedras que escondi; e ele estenderá a sua tenda real sobre elas.',
        'E virá, e ferirá a terra do Egito; entregando para a morte, quem é para a morte; e quem é para o cativeiro, para o cativeiro; e quem é para a espada, para a espada.',
        'E lançarei fogo às casas dos deuses do Egito, e queimá-los-á, e levá-los-á cativos; e vestir-se-á da terra do Egito, como veste o pastor a sua roupa, e sairá dali em paz.',
        'E quebrará as estátuas de Bete-Semes, que está na terra do Egito; e as casas dos deuses do Egito queimará a fogo.',
      ],
      [
        'A palavra que veio a Jeremias, acerca de todos os judeus, habitantes da terra do Egito, que habitavam em Migdol, e em Tafnes, e em Nofe, e na terra de Patros, dizendo:',
        'Assim diz o Senhor dos Exércitos, Deus de Israel: Vós vistes todo o mal que fiz vir sobre Jerusalém, e sobre todas as cidades de Judá; e eis que elas são hoje uma desolação, e ninguém habita nelas;',
        'Por causa da maldade que fizeram, para me irarem, indo queimar incenso, e servir a deuses estranhos, que nunca conheceram, nem eles, nem vós, nem vossos pais.',
        'E eu vos enviei todos os meus servos, os profetas, madrugando e enviando a dizer: Ora, não façais esta coisa abominável que odeio.',
        'Mas eles não escutaram, nem inclinaram os seus ouvidos, para se converterem da sua maldade, para não queimarem incenso a outros deuses.',
        'Derramou-se, pois, a minha indignação e a minha ira, e acendeu-se nas cidades de Judá, e nas ruas de Jerusalém, e elas tornaram-se em deserto e em desolação, como hoje se vê.',
        'Agora, pois, assim diz o Senhor, Deus dos Exércitos, Deus de Israel: Por que fazeis vós tão grande mal contra as vossas almas, para vos desarraigardes, ao homem e à mulher, à criança e ao que mama, do meio de Judá, a fim de não deixardes remanescente algum;',
        'Irando-me com as obras de vossas mãos, queimando incenso a deuses estranhos na terra do Egito, aonde vós entrastes para lá habitar; para que a vós mesmos vos desarraigueis, e para que sirvais de maldição, e de opróbrio entre todas as nações da terra?',
        'Esquecestes já as maldades de vossos pais, e as maldades dos reis de Judá, e as maldades de suas mulheres, e as vossas maldades, e as maldades de vossas mulheres, que cometeram na terra de Judá, e nas ruas de Jerusalém?',
        'Não se humilharam até ao dia de hoje, nem temeram, nem andaram na minha lei, nem nos meus estatutos, que pus diante de vós e diante de vossos pais.',
        'Portanto assim diz o Senhor dos Exércitos, Deus de Israel: Eis que eu ponho o meu rosto contra vós para mal, e para desarraigar a todo o Judá.',
        'E tomarei os que restam de Judá, os quais puseram os seus rostos para entrarem na terra do Egito, para lá habitar e todos eles serão consumidos na terra do Egito; cairão à espada, e de fome morrerão; consumir-se-ão, desde o menor até ao maior; à espada e de fome morrerão; e servirão de execração, e de espanto, e de maldição, e de opróbrio.',
        'Porque castigarei os que habitam na terra do Egito, como castiguei Jerusalém, com a espada, com a fome e com a peste.',
        'De maneira que da parte remanescente de Judá, que entrou na terra do Egito, para lá habitar, não haverá quem escape e fique para tornar à terra de Judá, à qual eles suspiram voltar para nela morar; porém não tornarão senão uns fugitivos.',
        'Então responderam a Jeremias todos os homens que sabiam que suas mulheres queimavam incenso a deuses estranhos, e todas as mulheres que estavam presentes em grande multidão, como também todo o povo que habitava na terra do Egito, em Patros, dizendo:',
        'Quanto à palavra que nos anunciaste em nome do Senhor, não obedeceremos a ti;',
        'Mas certamente cumpriremos toda a palavra que saiu da nossa boca, queimando incenso à rainha dos céus, e oferecendo-lhe libações, como nós e nossos pais, nossos reis e nossos príncipes, temos feito, nas cidades de Judá, e nas ruas de Jerusalém; e então tínhamos fartura de pão, e andávamos alegres, e não víamos mal algum.',
        'Mas desde que cessamos de queimar incenso à rainha dos céus, e de lhe oferecer libações, tivemos falta de tudo, e fomos consumidos pela espada e pela fome.',
        'E quando nós queimávamos incenso à rainha dos céus, e lhe oferecíamos libações, acaso lhe fizemos bolos, para a adorar, e oferecemos-lhe libações sem nossos maridos?',
        'Então disse Jeremias a todo o povo, aos homens e às mulheres, e a todo o povo que lhe havia dado esta resposta, dizendo:',
        'Porventura não se lembrou o Senhor, e não lhe veio ao coração o incenso que queimastes nas cidades de Judá e nas ruas de Jerusalém, vós e vossos pais, vossos reis e vossos príncipes, como também o povo da terra?',
        'De maneira que o Senhor não podia por mais tempo sofrer a maldade das vossas ações, as abominações que cometestes; por isso se tornou a vossa terra em desolação, e em espanto, e em maldição, sem habitantes, como hoje se vê.',
        'Porque queimastes incenso, e porque pecastes contra o Senhor, e não obedecestes à voz do Senhor, e na sua lei, e nos seus testemunhos não andastes, por isso vos sucedeu este mal, como se vê neste dia.',
        'Disse mais Jeremias a todo o povo e a todas as mulheres: Ouvi a palavra do Senhor, vós, todo o Judá, que estais na terra do Egito.',
        'Assim fala o Senhor dos Exércitos, Deus de Israel, dizendo: Vós e vossas mulheres não somente falastes por vossa boca, senão também o cumpristes por vossas mãos, dizendo: Certamente cumpriremos os nossos votos que fizemos de queimar incenso à rainha dos céus e de lhe oferecer libações; confirmai, pois, os vossos votos, e perfeitamente cumpri-os.',
        'Portanto ouvi a palavra do SENHOR, todo o Judá, que habitais na terra do Egito: Eis que eu juro pelo meu grande nome, diz o SENHOR, que nunca mais será pronunciado o meu nome pela boca de nenhum homem de Judá em toda a terra do Egito dizendo: Vive o Senhor DEUS!',
        'Eis que velarei sobre eles para mal, e não para bem; e serão consumidos todos os homens de Judá, que estão na terra do Egito, pela espada e pela fome, até que de todo se acabem.',
        'E os que escaparem da espada voltarão da terra do Egito à terra de Judá, poucos em número; e todo o restante de Judá, que entrou na terra do Egito, para habitar ali, saberá se subsistirá a minha palavra ou a sua.',
        'E isto vos servirá de sinal, diz o Senhor, que eu vos castigarei neste lugar, para que saibais que certamente subsistirão as minhas palavras contra vós para mal.',
        'Assim diz o SENHOR: Eis que eu darei Faraó-Hofra, rei do Egito, na mão de seus inimigos, e na mão dos que procuram a sua morte; como entreguei Zedequias, rei de Judá, na mão de Nabucodonosor, rei de babilônia, seu inimigo, e que procurava a sua morte.',
      ],
      [
        'A palavra que Jeremias, o profeta, falou a Baruque, filho de Nerias, quando este escrevia, num livro, estas palavras, da boca de Jeremias, no ano quarto de Jeoiaquim, filho de Josias, rei de Judá, dizendo:',
        'Assim diz o Senhor, Deus de Israel, acerca de ti, ó Baruque:',
        'Disseste: Ai de mim agora, porque me acrescentou o Senhor tristeza sobre minha dor! Estou cansado do meu gemido, e não acho descanso.',
        'Assim lhe dirás: Isto diz o Senhor: Eis que o que edifiquei eu derrubo, e o que plantei eu arranco, e isso em toda esta terra.',
        'E procuras tu grandezas para ti mesmo? Não as procures; porque eis que trarei mal sobre toda a carne, diz o SENHOR; porém te darei a tua alma por despojo, em todos os lugares para onde fores.',
      ],
      [
        'A palavra do SENHOR, que veio a Jeremias, o profeta, contra os gentios,',
        'Acerca do Egito, contra o exército de Faraó-Neco, rei do Egito, que estava junto ao rio Eufrates em Carquemis, ao qual feriu Nabucodonosor, rei de babilônia, no ano quarto de Jeoiaquim, filho de Josias, rei de Judá.',
        'Preparai o escudo e o pavês, e chegai-vos para a peleja.',
        'Selai os cavalos e montai, cavaleiros, e apresentai-vos com elmos; limpai as lanças, vesti-vos de couraças.',
        'Por que razão vejo os medrosos voltando as costas? Os seus valentes estão abatidos, e vão fugindo, sem olharem para trás; terror há ao redor, diz o Senhor.',
        'Não fuja o ligeiro, e não escape o valente; para o lado norte, junto à borda do rio Eufrates tropeçaram e caíram.',
        'Quem é este que vem subindo como o Nilo, cujas águas se movem como os rios?',
        'O Egito vem subindo como o Nilo, e como rios cujas águas se movem; e disse: Subirei, cobrirei a terra, destruirei a cidade, e os que nela habitam.',
        'Subi, ó cavalos, e estrondeai, ó carros, e saiam os valentes; os etíopes, e os do Líbano, que manejam o escudo, e os lídios, que manejam e entesam o arco.',
        'Porque este dia é o dia do Senhor DEUS dos Exércitos, dia de vingança para ele se vingar dos seus adversários; e a espada devorará, e fartar-se-á, e embriagar-se-á com o sangue deles; porque o Senhor DEUS dos Exércitos tem um sacrifício na terra do norte, junto ao rio Eufrates.',
        'Sobe a Gileade, e toma bálsamo, ó virgem filha do Egito; debalde multiplicas remédios, pois já não há cura para ti.',
        'As nações ouviram a tua vergonha, e a terra está cheia do teu clamor; porque o valente tropeçou com o valente e ambos caíram juntos.',
        'A palavra que falou o SENHOR a Jeremias, o profeta, acerca da vinda de Nabucodonosor, rei de babilônia, para ferir a terra do Egito.',
        'Anunciai no Egito, e fazei ouvir isto em Migdol; fazei também ouvi-lo em Nofe, e em Tafnes, dizei: Apresenta-te, e prepara-te; porque a espada já devorou o que está ao redor de ti.',
        'Por que foram derrubados os teus valentes? Não puderam manter-se firmes, porque o Senhor os abateu.',
        'Multiplicou os que tropeçavam; também caíram uns sobre os outros, e disseram: Levanta-te, e voltemos ao nosso povo, e à terra do nosso nascimento, por causa da espada que oprime.',
        'Clamaram ali: Faraó rei do Egito é apenas um barulho; deixou passar o tempo assinalado.',
        'Vivo eu, diz o rei, cujo nome é o Senhor dos Exércitos, que certamente como o Tabor entre os montes, e como o Carmelo junto ao mar, certamente assim ele virá.',
        'Prepara os utensílios para ires ao cativeiro, ó moradora, filha do Egito; porque Nofe será tornada em desolação, e será incendiada, até que ninguém mais aí more.',
        'Bezerra mui formosa é o Egito; mas já vem a destruição, vem do norte.',
        'Até os seus mercenários no meio dela são como bezerros cevados; mas também eles viraram as costas, fugiram juntos; não ficaram firmes; porque veio sobre eles o dia da sua ruína e o tempo do seu castigo.',
        'A sua voz irá como a da serpente; porque marcharão com um exército, e virão contra ela com machados, como cortadores de lenha.',
        'Cortarão o seu bosque, diz o Senhor, embora seja impenetrável; porque se multiplicaram mais do que os gafanhotos; são inumeráveis.',
        'A filha do Egito será envergonhada; será entregue na mão do povo do norte.',
        'Diz o Senhor dos Exércitos, o Deus de Israel: Eis que eu castigarei a Amom de Nô, e a Faraó, e ao Egito, e aos seus deuses, e aos seus reis; ao próprio Faraó, e aos que nele confiam.',
        'E os entregarei na mão dos que procuram a sua morte, na mão de Nabucodonosor, rei de babilônia, e na mão dos seus servos; mas depois será habitada, como nos dias antigos, diz o SENHOR.',
        'Mas não temas tu, servo meu, Jacó, nem te espantes, ó Israel; porque eis que te livrarei mesmo de longe, como também a tua descendência da terra do seu cativeiro; e Jacó voltará, e descansará, e sossegará, e não haverá quem o atemorize.',
        'Tu não temas, servo meu, Jacó, diz o Senhor, porque estou contigo; porque porei termo a todas as nações entre as quais te lancei; mas a ti não darei fim, mas castigar-te-ei com justiça, e não te darei de todo por inocente.',
      ],
      [
        'A palavra do SENHOR, que veio a Jeremias, o profeta, contra os filisteus, antes que Faraó ferisse a Gaza.',
        'Assim diz o Senhor: Eis que se levantam as águas do norte, e tornar-se-ão em torrente transbordante, e alagarão a terra e sua plenitude, a cidade, e os que nela habitam; e os homens clamarão, e todos os moradores da terra se lamentarão;',
        'Ao ruído estrepitoso dos cascos dos seus fortes cavalos, ao barulho de seus carros, ao estrondo das suas rodas; os pais não atendem aos filhos, por causa da fraqueza das mãos;',
        'Por causa do dia que vem, para destruir a todos os filisteus, para cortar de Tiro e de Sidom todo o restante que os socorra; porque o Senhor destruirá os filisteus, o remanescente da ilha de Caftor.',
        'A calvície veio sobre Gaza, foi desarraigada Ascalom, com o restante do seu vale; até quando te retalharás?',
        'Ah; espada do Senhor! Até quando deixarás de repousar? Volta para a tua bainha, descansa, e aquieta-te.',
        'Mas como te aquietarás? Pois o Senhor deu ordem à espada contra Ascalom, e contra a praia do mar, para onde ele a enviou.',
      ],
      [
        'Contra Moabe, assim diz o SENHOR dos Exércitos, Deus de Israel: Ai de Nebo, porque foi destruída; envergonhada está Quiriataim, já está tomada; Misgabe está envergonhada e desanimada.',
        'A glória de Moabe já não existe mais; em Hesbom tramaram mal contra ela, dizendo: Vinde, e exterminemo-la, para que não seja mais nação; também tu, ó Madmém, serás silenciada; a espada te perseguirá.',
        'Voz de clamor de Horonaim; ruína e grande destruição!',
        'Está destruída Moabe; seus filhinhos fizeram ouvir um clamor.',
        'Porque pela subida de Luíte eles irão com choro contínuo; porque na descida de Horonaim os adversários de Moabe ouviram as angústias do grito da destruição.',
        'Fugi, salvai a vossa vida; sede como a tamargueira no deserto;',
        'Porque, por causa da tua confiança nas tuas obras, e nos teus tesouros, também tu serás tomada; e Quemós sairá para o cativeiro, os seus sacerdotes e os seus príncipes juntamente.',
        'Porque virá o destruidor sobre cada uma das cidades, e nenhuma cidade escapará, e perecerá o vale, e destruir-se-á a campina; porque o Senhor o disse.',
        'Dai asas a Moabe; porque voando sairá, e as suas cidades se tornarão em desolação, e ninguém morará nelas.',
        'Maldito aquele que fizer a obra do Senhor fraudulosamente; e maldito aquele que retém a sua espada do sangue.',
        'Moabe esteve descansado desde a sua mocidade, e repousou nas suas borras, e não foi mudado de vasilha para vasilha, nem foi para o cativeiro; por isso conservou o seu sabor, e o seu cheiro não se alterou.',
        'Portanto, eis que dias vêm, diz o Senhor, em que lhe enviarei derramadores que o derramarão; e despejarão as suas vasilhas, e romperão os seus odres.',
        'E Moabe terá vergonha de Quemós como a casa de Israel se envergonhou de Betel, sua confiança.',
        'Como direis: Somos valentes e homens fortes para a guerra?',
        'Moabe está destruído, e subiu das suas cidades, e os seus jovens escolhidos desceram à matança, diz o Rei, cujo nome é o Senhor dos Exércitos.',
        'Está prestes a vir a calamidade de Moabe; e apressa-se muito a sua aflição.',
        'Condoei-vos dele todos os que estais ao seu redor, e todos os que sabeis o seu nome; dizei: Como se quebrou a vara forte, o cajado formoso?',
        'Desce da tua glória, e assenta-te em terra seca, ó moradora, filha de Dibom; porque o destruidor de Moabe subiu contra ti, e desfez as tuas fortalezas.',
        'Põe-te no caminho, e espia, ó moradora de Aroer; pergunta ao que vai fugindo; e à que escapou dize: Que sucedeu?',
        'Moabe está envergonhado, porque foi quebrantado; lamentai e gritai; anunciai em Arnom que Moabe está destruído.',
        'Também o julgamento veio sobre a terra da campina; sobre Holom, sobre Jaza, sobre Mefaate,',
        'Sobre Dibom, sobre Nebo, sobre Bete-Diblataim,',
        'Sobre Quiriataim, sobre Bete-Gamul, sobre Bete-Meom,',
        'Sobre Queriote, e sobre Bozra; e até sobre todas as cidades da terra de Moabe, as de longe e as de perto.',
        'Já é cortado o poder de Moabe, e é quebrantado o seu braço, diz o Senhor.',
        'Embriagai-o, porque contra o Senhor se engrandeceu; e Moabe se revolverá no seu vômito, e ele também se tornará objeto de escárnio.',
        'Pois não foi também Israel objeto de escárnio? Porventura foi achado entre ladrões, para que sempre que fales dele, saltes de alegria?',
        'Deixai as cidades, e habitai no rochedo, ó moradores de Moabe; e sede como a pomba que se aninha nos lados da boca da caverna.',
        'Ouvimos da soberba de Moabe, que é soberbíssimo, como também da sua arrogância, e da sua vaidade, e da sua altivez e do seu orgulhoso coração.',
        'Eu conheço, diz o Senhor, a sua indignação, mas isso nada é; as suas mentiras nada farão.',
        'Por isso gemerei por Moabe, sim, gritarei por todo o Moabe; pelos homens de Quir-Heres lamentarei;',
        'Com o choro de Jazer chorar-te-ei, ó vide de Sibma; os teus ramos passaram o mar, chegaram até ao mar de Jazer; porém o destruidor caiu sobre os teus frutos do verão, e sobre a tua vindima.',
        'Tirou-se, pois, o folguedo e a alegria do campo fértil e da terra de Moabe; porque fiz cessar o vinho nos lagares; já não pisarão uvas com júbilo; o júbilo não será júbilo.',
        'Por causa do grito de Hesbom até Eleale e até Jaaz, se ouviu a sua voz desde Zoar até Horonaim, como bezerra de três anos; porque até as águas do Ninrim se tornarão em assolação.',
        'E farei cessar em Moabe, diz o Senhor, quem sacrifique nos altos, e queime incenso aos seus deuses.',
        'Por isso ressoará como flauta o meu coração por Moabe, também ressoará como flauta o meu coração pelos homens de Quir-Heres; porquanto a abundância que ajuntou se perdeu.',
        'Porque toda a cabeça será tosquiada, e toda a barba será diminuída; sobre todas as mãos haverá sarjaduras, e sobre os lombos, sacos.',
        'Sobre todos os telhados de Moabe e nas suas ruas haverá um pranto geral; porque quebrei a Moabe, como a um vaso que não agrada, diz o Senhor.',
        'Como está quebrantado! Como gritam! Como virou Moabe a cerviz envergonhado! Assim será Moabe objeto de escárnio e de desmaio, para todos que estão em redor dele.',
        'Porque assim diz o Senhor: Eis que voará como a águia, e estenderá as suas asas sobre Moabe.',
        'São tomadas as cidades, e ocupadas as fortalezas; e naquele dia será o coração dos valentes de Moabe como o coração da mulher que está com dores de parto.',
        'E Moabe será destruído, para que não seja povo; porque se engrandeceu contra o Senhor.',
        'Temor, e cova, e laço, vêm sobre ti, ó morador de Moabe, diz o Senhor.',
        'O que fugir do temor cairá na cova, e o que subir da cova ficará preso no laço; porque trarei sobre ele, sobre Moabe, o ano do seu castigo, diz o Senhor.',
        'Os que fugiam sem força pararam à sombra de Hesbom; pois saiu fogo de Hesbom, e a labareda do meio de Siom, e devorou o canto de Moabe e o alto da cabeça dos turbulentos.',
        'Ai de ti, Moabe! Pereceu o povo de Quemós; porque teus filhos ficaram cativos, e tuas filhas em cativeiro.',
        'Mas nos últimos dias farei voltar os cativos de Moabe, diz o Senhor. Até aqui o juízo de Moabe.',
      ],
      [
        'Contra os filhos de Amom. Assim diz o SENHOR: Acaso Israel não tem filhos, nem tem herdeiro? Por que, pois, herdou Malcã a Gade e o seu povo habitou nas suas cidades?',
        'Portanto, eis que vêm dias, diz o Senhor, em que farei ouvir em Rabá dos filhos de Amom o alarido de guerra, e tornar-se-á num montão de ruínas, e os lugares da sua jurisdição serão queimados a fogo; e Israel herdará aos que o herdaram, diz o Senhor.',
        'Lamenta, ó Hesbom, porque é destruída Ai; clamai, ó filhas de Rabá, cingi-vos de sacos, lamentai, e dai voltas pelos valados; porque Malcã irá em cativeiro, juntamente com seus sacerdotes e os seus príncipes.',
        'Por que te glorias nos vales, teus luxuriantes vales, ó filha rebelde, que confias nos teus tesouros, dizendo: Quem virá contra mim?',
        'Eis que eu trarei temor sobre ti, diz o Senhor DEUS dos Exércitos, de todos os que estão ao redor de ti; e sereis lançados fora cada um diante de si, e ninguém recolherá o desgarrado.',
        'Mas depois disto farei voltar os cativos dos filhos de Amom, diz o Senhor.',
        'Acerca de Edom. Assim diz o Senhor dos Exércitos: Acaso não há mais sabedoria em Temã? Pereceu o conselho dos entendidos? Corrompeu-se a sua sabedoria?',
        'Fugi, voltai-vos, buscai profundezas para habitar, ó moradores de Dedã, porque eu trarei sobre ele a ruína de Esaú, no tempo em que o castiguei.',
        'Se vindimadores viessem a ti, não deixariam rabiscos? Se ladrões de noite viessem, não te danificariam quanto lhes bastasse?',
        'Mas eu despi a Esaú, descobri os seus esconderijos, e não se poderá esconder; foi destruída a sua descendência, como também seus irmãos e seus vizinhos, e ele já não existe.',
        'Deixa os teus órfãos, eu os guardarei em vida; e as tuas viúvas confiem em mim.',
        'Porque assim diz o Senhor: Eis que os que não estavam condenados a beber do copo, totalmente o beberão; e tu ficarias inteiramente impune? Não ficarás impune, mas certamente o beberás.',
        'Porque por mim mesmo jurei, diz o Senhor, que Bozra servirá de espanto, de opróbrio, de assolação, e de maldição; e todas as suas cidades se tornarão em desolações perpétuas.',
        'Ouvi novas vindas do Senhor, que um embaixador é enviado aos gentios, para lhes dizer: Ajuntai-vos, e vinde contra ela, e levantai-vos para a guerra.',
        'Porque eis que te fiz pequeno entre os gentios, desprezado entre os homens.',
        'Quanto à tua terribilidade, enganou-te a arrogância do teu coração, tu que habitas nas cavernas das rochas, que ocupas as alturas dos outeiros; ainda que eleves o teu ninho como a águia, de lá te derrubarei, diz o Senhor.',
        'Assim servirá Edom de desolação; todo aquele que passar por ela se espantará, e assobiará por causa de todas as suas pragas.',
        'Será como a destruição de Sodoma e Gomorra, e dos seus vizinhos, diz o Senhor; não habitará ninguém ali, nem morará nela filho de homem.',
        'Eis que ele como leão subirá da enchente do Jordão contra a morada do forte; porque num momento o farei correr dali; e quem é o escolhido que porei sobre ela? Pois quem é semelhante a mim? e quem me fixará o tempo? e quem é o pastor que subsistirá perante mim?',
        'Portanto ouvi o conselho do Senhor, que ele decretou contra Edom, e os seus desígnios que ele intentou entre os moradores de Temã: Certamente os menores do rebanho serão arrastados; certamente ele assolará as suas moradas com eles.',
        'A terra estremeceu com o estrondo da sua queda; e do seu grito, até ao Mar Vermelho se ouviu o som.',
        'Eis que ele como águia subirá, e voará, e estenderá as suas asas contra Bozra; e o coração dos valentes de Edom naquele dia será como o coração da mulher que está com dores de parto.',
        'Acerca de Damasco. Envergonhou-se Hamate e Arpade, porquanto ouviram más novas, desmaiaram; no mar há angústia, não se pode sossegar.',
        'Enfraquecida está Damasco; virou as costas para fugir, e o tremor a tomou; angústia e dores a tomaram como da que está de parto.',
        'Como está abandonada a cidade do louvor, a cidade da minha alegria!',
        'Portanto cairão os seus jovens nas suas ruas; e todos os homens de guerra serão consumidos naquele dia, diz o Senhor dos Exércitos.',
        'E acenderei fogo no muro de Damasco, e consumirá os palácios de Bene-Hadade.',
        'Acerca de Quedar, e dos reinos de Hazor, que Nabucodonosor, rei de babilônia, feriu. Assim diz o SENHOR: Levantai-vos, subi contra Quedar, e destruí os filhos do oriente.',
        'Tomarão as suas tendas, os seus gados, as suas cortinas e todos os seus utensílios, e os seus camelos levarão para si; e lhes clamarão: Há medo por todos os lados.',
        'Fugi, desviai-vos para muito longe, buscai profundezas para habitar, ó moradores de Hazor, diz o SENHOR; porque Nabucodonosor, rei de babilônia, tomou conselho contra vós, e formou um desígnio contra vós.',
        'Levantai-vos, subi contra uma nação tranqüila, que habita confiadamente, diz o Senhor, que não tem portas, nem ferrolhos; habitam sós.',
        'E os seus camelos serão para presa, e a multidão dos seus gados para despojo; e os espalharei a todo o vento, àqueles que estão nos lugares mais distantes, e de todos os seus lados lhes trarei a sua ruína, diz o Senhor.',
        'E Hazor se tornará em morada de chacais, em assolação para sempre; ninguém habitará ali, nem morará nela filho de homem.',
        'A palavra do Senhor, que veio a Jeremias, o profeta, contra Elão, no princípio do reinado de Zedequias, rei de Judá, dizendo:',
        'Assim diz o Senhor dos Exércitos: Eis que eu quebrarei o arco de Elão, o principal do seu poder.',
        'E trarei sobre Elão os quatro ventos dos quatro cantos dos céus, e os espalharei na direção de todos estes ventos; e não haverá nação aonde não cheguem os fugitivos de Elão.',
        'E farei que Elão tema diante de seus inimigos e diante dos que procuram a sua morte; e farei vir sobre eles o mal, o furor da minha ira, diz o Senhor; e enviarei após eles a espada, até que venha a consumi-los.',
        'E porei o meu trono em Elão; e destruirei dali o rei e os príncipes, diz o Senhor.',
        'Acontecerá, porém, nos últimos dias, que farei voltar os cativos de Elão, diz o Senhor.',
      ],
      [
        'A palavra que falou o SENHOR contra a babilônia, contra a terra dos caldeus, por intermédio de Jeremias, o profeta.',
        'Anunciai entre as nações; e fazei ouvir, e arvorai um estandarte, fazei ouvir, não encubrais; dizei: Tomada está babilônia, confundido está Bel, espatifado está Merodaque, confundidos estão os seus ídolos, e quebradas estão as suas imagens.',
        'Porque subiu contra ela uma nação do norte, que fará da sua terra uma solidão, e não haverá quem nela habite; tanto os homens como os animais fugiram, e se foram.',
        'Naqueles dias, e naquele tempo, diz o Senhor, os filhos de Israel virão, eles e os filhos de Judá juntamente; andando e chorando virão, e buscarão ao Senhor seu Deus.',
        'Pelo caminho de Sião perguntarão, para ali voltarão os seus rostos, dizendo: Vinde, e unamo-nos ao Senhor, numa aliança eterna que nunca será esquecida.',
        'Ovelhas perdidas têm sido o meu povo, os seus pastores as fizeram errar, para os montes as desviaram; de monte para outeiro andaram, esqueceram-se do lugar do seu repouso.',
        'Todos os que as achavam as devoravam, e os seus adversários diziam: Culpa nenhuma teremos; porque pecaram contra o Senhor, a morada da justiça, sim, o Senhor, a esperança de seus pais.',
        'Fugi do meio de babilônia, e saí da terra dos caldeus, e sede como os bodes diante do rebanho.',
        'Porque eis que eu suscitarei e farei subir contra a babilônia uma congregação de grandes nações da terra do norte, e se prepararão contra ela; dali será tomada; as suas flechas serão como as de valente herói, nenhuma tornará sem efeito.',
        'A Caldéia servirá de presa; todos os que a saquearam serão fartos, diz o Senhor.',
        'Porquanto vos alegrastes, e vos regozijastes, ó saqueadores da minha herança, porquanto vos engordastes como novilha no pasto, e mugistes como touros.',
        'Será mui confundida vossa mãe, ficará envergonhada a que vos deu à luz; eis que ela será a última das nações, um deserto, uma terra seca e uma solidão.',
        'Por causa do furor do SENHOR não será habitada, antes se tornará em total assolação; qualquer que passar por babilônia se espantará, assobiará por todas as suas pragas.',
        'Ordenai-vos contra babilônia ao redor, todos os que armais arcos; atirai-lhe, não poupeis as flechas, porque pecou contra o SENHOR.',
        'Gritai contra ela ao redor, ela já se submeteu; caíram seus fundamentos, estão derrubados os seus muros; porque esta é a vingança do Senhor; vingai-vos dela; como ela fez, assim lhe fazei.',
        'Arrancai de babilônia o que semeia, e o que leva a foice no tempo da sega; por causa da espada aflitiva virar-se-á cada um para o seu povo, e fugirá cada um para a sua terra.',
        'Cordeiro desgarrado é Israel; os leões o afugentaram; o primeiro a devorá-lo foi o rei da Assíria; e, por último Nabucodonosor, rei de babilônia, lhe quebrou os ossos.',
        'Portanto, assim diz o SENHOR dos Exércitos, Deus de Israel: Eis que castigarei o rei de babilônia, e a sua terra, como castiguei o rei da Assíria.',
        'E farei tornar Israel para a sua morada, e ele pastará no Carmelo e em Basã; e fartar-se-á a sua alma no monte de Efraim e em Gileade.',
        'Naqueles dias, e naquele tempo, diz o Senhor, buscar-se-á a maldade de Israel, e não será achada; e os pecados de Judá, mas não se acharão; porque perdoarei os remanescentes que eu deixar.',
        'Sobe contra a terra de Merataim, sim, contra ela, e contra os moradores de Pecode; assola e inteiramente destrói tudo após eles, diz o Senhor, e faze conforme tudo o que te mandei.',
        'Estrondo de batalha há na terra, e de grande destruição.',
        'Como foi cortado e quebrado o martelo de toda a terra! Como se tornou babilônia objeto de espanto entre as nações!',
        'Laços te armei, e também foste presa, ó babilônia, e tu não o soubeste; foste achada, e também apanhada; porque contra o SENHOR te entremeteste.',
        'O SENHOR abriu o seu depósito, e tirou os instrumentos da sua indignação; porque o Senhor DEUS dos Exércitos, tem uma obra a realizar na terra dos caldeus.',
        'Vinde contra ela dos confins da terra, abri os seus celeiros; fazei dela montões de ruínas, e destruí-a de todo; nada lhe fique de sobra.',
        'Matai a todos os seus novilhos, desçam a matança. Ai deles, porque veio o seu dia, o tempo do seu castigo!',
        'Eis a voz dos que fugiram e escaparam da terra de babilônia, para anunciarem em Sião a vingança do SENHOR nosso Deus, a vingança do seu templo.',
        'Convocai contra babilônia os flecheiros, a todos os que armam arcos; acampai-vos contra ela em redor, ninguém escape dela; pagai-lhe conforme a sua obra, conforme tudo o que fez, fazei-lhe; porque se houve arrogantemente contra o SENHOR, contra o Santo de Israel.',
        'Portanto, cairão os seus jovens nas suas ruas; e todos os seus homens de guerra serão desarraigados naquele dia, diz o Senhor.',
        'Eis que eu sou contra ti, ó soberbo, diz o Senhor DEUS dos Exércitos; porque veio o teu dia, o tempo em que te hei de castigar.',
        'Então tropeçará o soberbo, e cairá, e ninguém haverá que o levante; e porei fogo nas suas cidades, o qual consumirá todos os seus arredores.',
        'Assim diz o Senhor dos Exércitos: Os filhos de Israel e os filhos de Judá foram oprimidos juntamente; e todos os que os levaram cativos os retiveram, não os quiseram soltar.',
        'Mas o seu Redentor é forte, o SENHOR dos Exércitos é o seu nome; certamente pleiteará a causa deles, para dar descanso à terra, e inquietar os moradores de babilônia.',
        'A espada virá sobre os caldeus, diz o SENHOR, e sobre os moradores de babilônia, e sobre os seus príncipes, e sobre os seus sábios.',
        'A espada virá sobre os mentirosos, e ficarão insensatos; a espada virá sobre os seus poderosos, e desfalecerão.',
        'A espada virá sobre os seus cavalos, e sobre os seus carros, e sobre toda a mistura de povos, que está no meio dela; e tornar-se-ão como mulheres; a espada virá sobre os seus tesouros, e serão saqueados.',
        'Cairá a seca sobre as suas águas, e secarão; porque é uma terra de imagens esculpidas, e pelos seus ídolos andam enfurecidos.',
        'Por isso habitarão nela as feras do deserto, com os animais selvagens das ilhas; também habitarão nela as avestruzes; e nunca mais será povoada, nem será habitada de geração em geração.',
        'Como quando Deus subverteu a Sodoma e a Gomorra, e as suas cidades vizinhas, diz o Senhor, assim ninguém habitará ali, nem morará nela filho de homem.',
        'Eis que um povo vem do norte; uma grande nação e muitos reis se levantarão dos extremos da terra.',
        'Armam-se de arco e lança; eles são cruéis, e não têm piedade; a sua voz bramará como o mar, e sobre cavalos cavalgarão, todos postos em ordem como um homem para a batalha, contra ti, ó filha de babilônia.',
        'O rei de babilônia ouviu a sua fama, e desfaleceram as suas mãos; a angústia se apoderou dele, como da que está de parto.',
        'Eis que ele como leão subirá da enchente do Jordão, contra a morada forte, porque num momento o farei correr dali; e quem é o escolhido que porei sobre ela? porque quem é semelhante a mim, e quem me fixará o tempo? E quem é o pastor que poderá permanecer perante mim?',
        'Portanto ouvi o conselho do SENHOR, que ele decretou contra babilônia, e os seus desígnios que intentou contra a terra dos caldeus: certamente os pequenos do rebanho serão arrastados; certamente ele assolará as suas moradas sobre eles.',
        'Ao estrondo da tomada de babilônia estremeceu a terra; e o grito se ouviu entre as nações.',
      ],
      [
        'Assim diz o SENHOR: Eis que levantarei um vento destruidor contra babilônia, e contra os que habitam no meio dos que se levantam contra mim.',
        'E enviarei padejadores contra babilônia, que a padejarão, e despejarão a sua terra; porque virão contra ela em redor no dia da calamidade.',
        'O flecheiro arme o seu arco contra o que arma o seu arco, e contra o que se exalta na sua couraça; e não perdoeis aos seus jovens; destruí a todo o seu exército.',
        'E os mortos cairão na terra dos caldeus, e atravessados nas suas ruas.',
        'Porque Israel e Judá não foram abandonados do seu Deus, do Senhor dos Exércitos, ainda que a sua terra esteja cheia de culpas contra o Santo de Israel.',
        'Fugi do meio de babilônia, e livrai cada um a sua alma, e não vos destruais na sua maldade; porque este é o tempo da vingança do SENHOR; que lhe dará a sua recompensa.',
        'babilônia era um copo de ouro na mão do SENHOR, o qual embriagava a toda a terra; do seu vinho beberam as nações; por isso as nações enlouqueceram.',
        'Num momento caiu babilônia, e ficou arruinada; lamentai por ela, tomai bálsamo para a sua dor, porventura sarará.',
        'Queríamos curar babilônia, porém ela não sarou; deixai-a, e vamo-nos cada um para a sua terra; porque o seu juízo chegou até ao céu, e se elevou até às mais altas nuvens.',
        'O Senhor trouxe a nossa justiça à luz; vinde e contemos em Sião a obra do Senhor, nosso Deus.',
        'Aguçai as flechas, preparai os escudos; o SENHOR despertou o espírito dos reis da Média; porque o seu intento é contra babilônia para a destruir; porque esta é a vingança do SENHOR, a vingança do seu templo.',
        'Arvorai um estandarte sobre os muros de babilônia, reforçai a guarda, colocai sentinelas, preparai as ciladas; porque como o SENHOR intentou, assim fez o que tinha falado contra os moradores de babilônia.',
        'Ó tu, que habitas sobre muitas águas, rica de tesouros, é chegado o teu fim, a medida da tua avareza.',
        'Jurou o Senhor dos Exércitos por si mesmo, dizendo: Ainda que te enchi de homens, como de lagarta, contudo levantarão gritaria contra ti.',
        'Ele fez a terra com o seu poder, e ordenou o mundo com a sua sabedoria, e estendeu os céus com o seu entendimento.',
        'Fazendo ele ouvir a sua voz, grande estrondo de águas há nos céus, e faz subir os vapores desde o fim da terra; faz os relâmpagos com a chuva, e tira o vento dos seus tesouros,',
        'Embrutecido é todo o homem, no seu conhecimento; envergonha-se todo o artífice da imagem de escultura; porque a sua imagem de fundição é mentira, e nelas não há espírito.',
        'Vaidade são, obra de enganos; no tempo da sua visitação perecerão.',
        'Não é semelhante a estes a porção de Jacó; porque ele é o que formou tudo; e Israel é a tribo da sua herança; o Senhor dos Exércitos é o seu nome.',
        'Tu és meu machado de batalha e minhas armas de guerra, e por meio de ti despedaçarei as nações e por ti destruirei os reis;',
        'E por meio de ti despedaçarei o cavalo e o seu cavaleiro; e por meio de ti despedaçarei o carro e o que nele vai;',
        'E por meio de ti despedaçarei o homem e a mulher, e por meio de ti despedaçarei o velho e o moço, e por meio de ti despedaçarei o jovem e a virgem;',
        'E por meio de ti despedaçarei o pastor e o seu rebanho, e por meio de ti despedaçarei o lavrador e a sua junta de bois, e por meio de ti despedaçarei os capitães e os magistrados.',
        'E pagarei a babilônia, e a todos os moradores da Caldéia, toda a maldade que fizeram em Sião, aos vossos olhos, diz o SENHOR.',
        'Eis-me aqui contra ti, ó monte destruidor, diz o Senhor, que destróis toda a terra; e estenderei a minha mão contra ti, e te revolverei das rochas, e farei de ti um monte de queima.',
        'E não tomarão de ti pedra para esquina, nem pedra para fundamentos, porque te tornarás em assolação perpétua, diz o Senhor.',
        'Arvorai um estandarte na terra, tocai a buzina entre as nações, preparai as nações contra ela, convocai contra ela os reinos de Ararate, Mini, e Asquenaz; ordenai contra ela um capitão, fazei subir cavalos, como lagartas eriçadas.',
        'Preparai contra ela as nações, os reis da Média, os seus capitães, e todos os seus magistrados, e toda a terra do seu domínio.',
        'Então tremerá a terra, e doer-se-á, porque cada um dos desígnios do SENHOR está firme contra babilônia, para fazer da terra de babilônia uma desolação, sem habitantes.',
        'Os poderosos de babilônia cessaram de pelejar, ficaram nas fortalezas, desfaleceu a sua força, tornaram-se como mulheres; incendiaram as suas moradas, quebrados foram os seus ferrolhos.',
        'Um correio correrá ao encontro de outro correio, e um mensageiro ao encontro de outro mensageiro, para anunciar ao rei de babilônia que a sua cidade está tomada de todos os lados.',
        'E os vaus estão ocupados, e os canaviais queimados a fogo; e os homens de guerra ficaram assombrados.',
        'Porque assim diz o SENHOR dos Exércitos, o Deus de Israel: A filha de babilônia é como uma eira, no tempo da debulha; ainda um pouco, e o tempo da sega lhe virá.',
        'Nabucodonosor, rei de babilônia, devorou-me, colocou-me de lado, fez de mim um vaso vazio, como chacal me tragou, encheu o seu ventre das minhas delicadezas; lançou-me fora.',
        'A violência que se fez a mim e à minha carne venha sobre babilônia, dirá a moradora de Sião; e o meu sangue caia sobre os moradores da Caldéia, dirá Jerusalém.',
        'Portanto, assim diz o Senhor: Eis que pleitearei a tua causa, e tomarei vingança por ti; e secarei o seu mar, e farei que se esgote o seu manancial.',
        'E babilônia se tornará em montões, morada de chacais, espanto e assobio, sem que haja quem nela habite.',
        'Juntamente rugirão como filhos dos leões; bramarão como filhotes de leões.',
        'Estando eles excitados, lhes darei a sua bebida, e os embriagarei, para que andem saltando; porém dormirão um perpétuo sono, e não acordarão, diz o Senhor.',
        'Fá-los-ei descer como cordeiros à matança, como carneiros e bodes.',
        'Como foi tomada Sesaque, e apanhada de surpresa a glória de toda a terra! Como se tornou babilônia objeto de espanto entre as nações!',
        'O mar subiu sobre babilônia; com a multidão das suas ondas se cobriu.',
        'Tornaram-se as suas cidades em desolação, terra seca e deserta, terra em que ninguém habita, nem passa por ela filho de homem.',
        'E castigarei a Bel em babilônia, e tirarei da sua boca o que tragou, e nunca mais concorrerão a ele as nações; também o muro de babilônia caiu.',
        'Saí do meio dela, ó povo meu, e livrai cada um a sua alma do ardor da ira do Senhor.',
        'E para que porventura não se enterneça o vosso coração, e não temais pelo rumor que se ouvir na terra; porque virá num ano um rumor, e depois noutro ano outro rumor; e haverá violência na terra, dominador contra dominador.',
        'Portanto, eis que vêm dias, em que farei juízo sobre as imagens de escultura de babilônia, e toda a sua terra será envergonhada, e todos os seus mortos cairão no meio dela.',
        'E os céus e a terra, com tudo quanto neles há, jubilarão sobre babilônia; porque do norte lhe virão os destruidores, diz o SENHOR.',
        'Como babilônia fez cair mortos os de Israel, assim em babilônia cairão os mortos de toda a terra.',
        'Vós, que escapastes da espada, ide-vos, não pareis; de longe lembrai-vos do Senhor, e suba Jerusalém a vossa mente.',
        'Direis: Envergonhados estamos, porque ouvimos opróbrio; vergonha cobriu o nosso rosto, porquanto vieram estrangeiros contra os santuários da casa do Senhor.',
        'Portanto, eis que vêm dias, diz o Senhor, em que farei juízo sobre as suas imagens de escultura; e gemerão os feridos em toda a sua terra.',
        'Ainda que babilônia subisse aos céus, e ainda que fortificasse a altura da sua fortaleza, todavia de mim virão destruidores sobre ela, diz o SENHOR.',
        'De babilônia se ouve clamor de grande destruição da terra dos caldeus;',
        'Porque o SENHOR tem destruído babilônia, e tem feito perecer nela a sua grande voz; quando as suas ondas bramam como muitas águas, é emitido o ruído da sua voz.',
        'Porque o destruidor vem sobre ela, sobre babilônia, e os seus poderosos serão presos, já estão quebrados os seus arcos; porque o SENHOR, Deus das recompensas, certamente lhe retribuirá.',
        'E embriagarei os seus príncipes, e os seus sábios e os seus capitães, e os seus magistrados, e os seus poderosos; e dormirão um sono eterno, e não acordarão, diz o Rei, cujo nome é o Senhor dos Exércitos.',
        'Assim diz o SENHOR dos Exércitos: Os largos muros de babilônia serão totalmente derrubados, e as suas altas portas serão abrasadas pelo fogo; e trabalharão os povos em vão, e as nações no fogo, e eles se cansarão.',
        'A palavra que Jeremias, o profeta, mandou a Seraías, filho de Nerias, filho de Maaséias, indo ele com Zedequias, rei de Judá, a babilônia, no quarto ano do seu reinado. E Seraías era o camareiro-mor.',
        'Escreveu, pois, Jeremias num livro todo o mal que havia de vir sobre babilônia, a saber, todas estas palavras que estavam escritas contra babilônia.',
        'E disse Jeremias a Seraías: Quando chegares a babilônia, verás e lerás todas estas palavras.',
        'E dirás: Senhor, tu falaste contra este lugar, que o havias de desarraigar, até não ficar nele morador algum, nem homem nem animal, e que se tornaria em perpétua desolação.',
        'E será que, acabando tu de ler este livro, atar-lhe-ás uma pedra e lançá-lo-ás no meio do Eufrates.',
        'E dirás: Assim será afundada babilônia, e não se levantará, por causa do mal que eu hei de trazer sobre ela; e eles se cansarão. Até aqui são as palavras de Jeremias.',
      ],
      [
        'Era Zedequias da idade de vinte e um anos quando começou a reinar, e reinou onze anos em Jerusalém; e o nome de sua mãe era Hamutal, filha de Jeremias, de Libna.',
        'E fez o que era mau aos olhos do Senhor, conforme tudo o que fizera Jeoiaquim.',
        'Assim, por causa da ira do SENHOR, contra Jerusalém e Judá, ele os lançou de diante dele, e Zedequias se rebelou contra o rei de babilônia.',
        'E aconteceu, que no ano nono do seu reinado, no décimo mês, no décimo dia do mês, veio Nabucodonosor, rei de babilônia, contra Jerusalém, ele e todo o seu exército, e se acamparam contra ela, e levantaram contra ela trincheiras ao redor.',
        'Assim esteve cercada a cidade, até ao undécimo ano do rei Zedequias.',
        'No quarto mês, aos nove dias do mês, quando já a fome prevalecia na cidade, e o povo da terra não tinha pão,',
        'Então foi aberta uma brecha na cidade, e todos os homens de guerra fugiram, e saíram da cidade de noite, pelo caminho da porta entre os dois muros, a qual estava perto do jardim do rei (porque os caldeus cercavam a cidade ao redor), e foram pelo caminho da campina.',
        'Mas o exército dos caldeus perseguiu o rei, e alcançou a Zedequias nas campinas de Jericó, e todo o seu exército se espalhou, abandonando-o.',
        'E prenderam o rei, e o fizeram subir ao rei de babilônia, a Ribla, na terra de Hamate, o qual lhe pronunciou a sentença.',
        'E o rei de babilônia degolou os filhos de Zedequias à sua vista, e também degolou a todos os príncipes de Judá em Ribla.',
        'E cegou os olhos a Zedequias, e o atou com cadeias; e o rei de babilônia o levou para babilônia, e o conservou na prisão até o dia da sua morte.',
        'E no quinto mês, no décimo dia do mês, que era o décimo nono ano do rei Nabucodonosor, rei de babilônia, Nebuzaradã, capitão da guarda, que assistia na presença do rei de babilônia, veio a Jerusalém.',
        'E queimou a casa do Senhor, e a casa do rei; e também a todas as casas de Jerusalém, e a todas as casas dos grandes ele as incendiou.',
        'E todo o exército dos caldeus, que estava com o capitão da guarda, derrubou a todos os muros em redor de Jerusalém.',
        'E dos mais pobres do povo, e a parte do povo, que tinha ficado na cidade, e os rebeldes que se haviam passado para o rei de babilônia, e o mais da multidão, Nebuzaradã, capitão da guarda, levou presos.',
        'Mas dos mais pobres da terra Nebuzaradã, capitão da guarda, deixou ficar alguns, para serem vinhateiros e lavradores.',
        'Quebraram mais os caldeus as colunas de bronze, que estavam na casa do SENHOR, e as bases, e o mar de bronze, que estavam na casa do SENHOR, e levaram todo o bronze para babilônia.',
        'Também tomaram os caldeirões, e as pás, e as espevitadeiras, e as bacias, e as colheres, e todos os utensílios de bronze, com que se ministrava.',
        'E tomou o capitão da guarda as bacias, e os braseiros, e as tigelas, e os caldeirões, e os castiçais, e as colheres, e os copos; tanto o que era de puro ouro, como o que era de prata maciça.',
        'Quanto às duas colunas, ao único mar, e aos doze bois de bronze, que estavam debaixo das bases, que fizera o rei Salomão para a casa do Senhor, o peso do bronze de todos estes utensílios era incalculável.',
        'Quanto às colunas, a altura de cada uma era de dezoito côvados, e um fio de doze côvados a cercava; e era a sua espessura de quatro dedos, e era oca.',
        'E havia sobre ela um capitel de bronze; e a altura do capitel era de cinco côvados; a rede e as romãs ao redor do capitel eram de bronze; e semelhante a esta era a segunda coluna, com as romãs.',
        'E havia noventa e seis romãs em cada lado; as romãs todas, em redor da rede, eram cem.',
        'Levou também o capitão da guarda a Seraías, o sacerdote chefe, e a Sofonias, o segundo sacerdote, e aos três guardas da porta.',
        'E da cidade tomou a um eunuco que tinha a seu cargo os homens de guerra, e a sete homens que estavam próximos à pessoa do rei, que se achavam na cidade, como também o escrivão-mor do exército, que alistava o povo da terra para a guerra, e a sessenta homens do povo da terra, que se achavam no meio da cidade.',
        'Tomando-os, pois, Nebuzaradã, capitão da guarda, levou-os ao rei de babilônia, a Ribla.',
        'E o rei de babilônia os feriu e os matou em Ribla, na terra de Hamate; assim Judá foi levado cativo para fora da sua terra.',
        'Este é o povo que Nabucodonosor levou cativo, no sétimo ano: três mil e vinte e três judeus.',
        'No ano décimo oitavo de Nabucodonosor, ele levou cativas de Jerusalém oitocentas e trinta e duas pessoas.',
        'No ano vinte e três de Nabucodonosor, Nebuzaradã, capitão da guarda, levou cativas, dos judeus, setecentas e quarenta e cinco pessoas; todas as pessoas foram quatro mil e seiscentas.',
        'Sucedeu, pois, no ano trigésimo sétimo do cativeiro de Jeoiaquim, rei de Judá, no duodécimo mês, aos vinte e cinco dias do mês, que Evil-Merodaque, rei de babilônia, no primeiro ano do seu reinado, levantou a cabeça de Jeoiaquim, rei de Judá, e tirou-o do cárcere;',
        'E falou com ele benignamente, e pôs o seu trono acima dos tronos dos reis que estavam com ele em babilônia;',
        'E lhe fez mudar as vestes da sua prisão; e passou a comer pão sempre na presença do rei, todos os dias da sua vida.',
        'E, quanto à sua alimentação, foi-lhe dada refeição contínua do rei de babilônia, porção cotidiana, no seu dia, até o dia da sua morte, todos os dias da sua vida.',
      ],
    ],
    livro: 'jeremias',
  },
  {
    abbrev: 'lm',
    capitulos: [
      [
        'Como está sentada solitária aquela cidade, antes tão populosa! Tornou-se como viúva, a que era grande entre as nações! A que era princesa entre as províncias, tornou-se tributária!',
        'Chora amargamente de noite, e as suas lágrimas lhe correm pelas faces; não tem quem a console entre todos os seus amantes; todos os seus amigos se houveram aleivosamente com ela, tornaram-se seus inimigos.',
        'Judá passou em cativeiro por causa da aflição, e por causa da grande servidão; ela habita entre os gentios, não acha descanso; todos os seus perseguidores a alcançam entre as suas dificuldades.',
        'Os caminhos de Sião pranteiam, porque não há quem venha à festa solene; todas as suas portas estão desoladas; os seus sacerdotes suspiram; as suas virgens estão tristes, e ela mesma tem amargura.',
        'Os seus adversários têm sido feitos chefes, os seus inimigos prosperam; porque o Senhor a afligiu, por causa da multidão das suas transgressões; os seus filhinhos foram para o cativeiro na frente do adversário.',
        'E da filha de Sião já se foi toda a sua formosura; os seus príncipes ficaram sendo como corços que não acham pasto e caminham sem força adiante do perseguidor.',
        'Lembra-se Jerusalém, nos dias da sua aflição e dos seus exílios, de todas as suas mais queridas coisas, que tivera desde os tempos antigos; quando caía o seu povo na mão do adversário, e não havia quem a socorresse; os adversários a viram, e fizeram escárnio da sua ruína.',
        'Jerusalém gravemente pecou, por isso se fez errante; todos os que a honravam, a desprezaram, porque viram a sua nudez; ela também suspira e volta para trás.',
        'A sua imundícia está nas suas saias; nunca se lembrou do seu fim; por isso foi pasmosamente abatida, não tem consolador; vê, Senhor, a minha aflição, porque o inimigo se tem engrandecido.',
        'Estendeu o adversário a sua mão a todas as coisas mais preciosas dela; pois ela viu entrar no seu santuário os gentios, acerca dos quais mandaste que não entrassem na tua congregação.',
        'Todo o seu povo anda suspirando, buscando o pão; deram as suas coisas mais preciosas a troco de mantimento para restaurarem a alma; vê, Senhor, e contempla, que sou desprezível.',
        'Não vos comove isto a todos vós que passais pelo caminho? Atendei, e vede, se há dor como a minha dor, que veio sobre mim, com que o Senhor me afligiu, no dia do furor da sua ira.',
        'Desde o alto enviou fogo a meus ossos, o qual se assenhoreou deles; estendeu uma rede aos meus pés, fez-me voltar para trás, fez-me assolada e enferma todo o dia.',
        'O jugo das minhas transgressões está atado pela sua mão; elas estão entretecidas, subiram sobre o meu pescoço, e ele abateu a minha força; entregou-me o Senhor nas mãos daqueles a quem não posso resistir.',
        'O Senhor atropelou todos os meus poderosos no meio de mim; convocou contra mim uma assembléia, para esmagar os meus jovens; o Senhor pisou como num lagar a virgem filha de Judá.',
        'Por estas coisas eu ando chorando; os meus olhos, os meus olhos se desfazem em águas; porque se afastou de mim o consolador que devia restaurar a minha alma; os meus filhos estão assolados, porque prevaleceu o inimigo.',
        'Estende Sião as suas mãos, não há quem a console; mandou o Senhor acerca de Jacó que lhe fossem inimigos os que estão em redor dele; Jerusalém é entre eles como uma mulher imunda.',
        'Justo é o Senhor, pois me rebelei contra o seu mandamento; ouvi, pois, todos os povos, e vede a minha dor; as minhas virgens e os meus jovens foram levados para o cativeiro.',
        'Chamei os meus amantes, mas eles me enganaram; os meus sacerdotes e os meus anciãos expiraram na cidade; enquanto buscavam para si mantimento, para restaurarem a sua alma.',
        'Olha, Senhor, porque estou angustiada; turbadas estão as minhas entranhas; o meu coração está transtornado dentro de mim, porque gravemente me rebelei; fora me desfilhou a espada, em casa está a morte.',
        'Ouviram que eu suspiro, mas não tenho quem me console; todos os meus inimigos que souberam do meu mal folgam, porque tu o fizeste; mas, em trazendo tu o dia que apregoaste, serão como eu.',
        'Venha toda a sua maldade diante de ti, e faze-lhes como me fizeste a mim por causa de todas as minhas transgressões; porque os meus suspiros são muitos, e o meu coração está desfalecido.',
      ],
      [
        'Como cobriu o Senhor de nuvens na sua ira a filha de Sião! Derrubou do céu à terra a glória de Israel, e não se lembrou do escabelo de seus pés, no dia da sua ira.',
        'Devorou o Senhor todas as moradas de Jacó, e não se apiedou; derrubou no seu furor as fortalezas da filha de Judá, e abateu-as até à terra; profanou o reino e os seus príncipes.',
        'No furor da sua ira cortou toda a força de Israel; retirou para trás a sua destra de diante do inimigo; e ardeu contra Jacó, como labareda de fogo que consome em redor.',
        'Armou o seu arco como inimigo, firmou a sua destra como adversário, e matou tudo o que era formoso à vista; derramou a sua indignação como fogo na tenda da filha de Sião.',
        'Tornou-se o Senhor como inimigo; devorou a Israel, devorou a todos os seus palácios, destruiu as suas fortalezas; e multiplicou na filha de Judá a lamentação e a tristeza.',
        'E arrancou o seu tabernáculo com violência, como se fosse o de uma horta; destruiu o lugar da sua congregação; o Senhor, em Sião, pôs em esquecimento a festa solene e o sábado, e na indignação da sua ira rejeitou com desprezo o rei e o sacerdote.',
        'Rejeitou o Senhor o seu altar, detestou o seu santuário; entregou na mão do inimigo os muros dos seus palácios; deram gritos na casa do Senhor, como em dia de festa solene.',
        'Intentou o Senhor destruir o muro da filha de Sião; estendeu o cordel sobre ele, não retirou a sua mão destruidora; fez gemer o antemuro e o muro; estão eles juntamente enfraquecidos.',
        'As suas portas caíram por terra; ele destruiu e quebrou os seus ferrolhos; o seu rei e os seus príncipes estão entre os gentios, onde não há lei, nem os seus profetas acham visão alguma do Senhor.',
        'Estão sentados na terra, silenciosos os anciãos da filha de Sião; lançam pó sobre as suas cabeças, cingiram sacos; as virgens de Jerusalém abaixam as suas cabeças até à terra.',
        'Já se consumiram os meus olhos com lágrimas, turbadas estão as minhas entranhas, o meu fígado se derramou pela terra por causa do quebrantamento da filha do meu povo; pois desfalecem o menino e a criança de peito pelas ruas da cidade.',
        'Ao desfalecerem, como feridos, pelas ruas da cidade, ao exalarem as suas almas no regaço de suas mães, perguntam a elas: Onde está o trigo e o vinho?',
        'Que testemunho te trarei? A quem te compararei, ó filha de Jerusalém? A quem te assemelharei, para te consolar, ó virgem filha de Sião? Porque grande como o mar é a tua quebradura; quem te sarará?',
        'Os teus profetas viram para ti, vaidade e loucura, e não manifestaram a tua maldade, para impedirem o teu cativeiro; mas viram para ti cargas vãs e motivos de expulsão.',
        'Todos os que passam pelo caminho batem palmas, assobiam e meneiam as suas cabeças sobre a filha de Jerusalém, dizendo: É esta a cidade que denominavam: perfeita em formosura, gozo de toda a terra?',
        'Todos os teus inimigos abrem as suas bocas contra ti, assobiam, e rangem os dentes; dizem: Devoramo-la; certamente este é o dia que esperávamos; achamo-lo, vimo-lo.',
        'Fez o Senhor o que intentou; cumpriu a sua palavra, que ordenou desde os dias da antiguidade; derrubou, e não se apiedou; fez que o inimigo se alegrasse por tua causa, exaltou o poder dos teus adversários.',
        'O coração deles clamou ao Senhor: Ó muralha da filha de Sião, corram as tuas lágrimas como um ribeiro, de dia e de noite; não te dês descanso, nem parem as meninas de teus olhos.',
        'Levanta-te, clama de noite no princípio das vigias; derrama o teu coração como águas diante da presença do Senhor; levanta a ele as tuas mãos, pela vida de teus filhinhos, que desfalecem de fome à entrada de todas as ruas.',
        'Vê, ó Senhor, e considera a quem fizeste assim! Hão de comer as mulheres o fruto de si mesmas, as crianças que trazem nos braços? Ou matar-se-á no santuário do Senhor o sacerdote e o profeta?',
        'Jazem por terra pelas ruas o moço e o velho, as minhas virgens e os meus jovens vieram a cair à espada; tu os mataste no dia da tua ira; mataste e não te apiedaste.',
        'Convocaste os meus temores em redor como num dia de solenidade; não houve no dia da ira do Senhor quem escapasse, ou ficasse; aqueles que eu trouxe nas mãos e sustentei, o meu inimigo os consumiu.',
      ],
      [
        'Eu sou aquele homem que viu a aflição pela vara do seu furor.',
        'Ele me guiou e me fez andar em trevas e não na luz.',
        'Deveras fez virar e revirar a sua mão contra mim o dia todo.',
        'Fez envelhecer a minha carne e a minha pele, quebrou os meus ossos.',
        'Edificou contra mim, e me cercou de fel e trabalho.',
        'Assentou-me em lugares tenebrosos, como os que estavam mortos há muito.',
        'Cercou-me de uma sebe, e não posso sair; agravou os meus grilhões.',
        'Ainda quando clamo e grito, ele exclui a minha oração.',
        'Fechou os meus caminhos com pedras lavradas, fez tortuosas as minhas veredas.',
        'Fez-se-me como urso de emboscada, um leão em esconderijos.',
        'Desviou os meus caminhos, e fez-me em pedaços; deixou-me assolado.',
        'Armou o seu arco, e me pôs como alvo à flecha.',
        'Fez entrar nos meus rins as flechas da sua aljava.',
        'Fui feito um objeto de escárnio para todo o meu povo, e a sua canção todo o dia.',
        'Fartou-me de amarguras, embriagou-me de absinto.',
        'Quebrou com cascalho os meus dentes, abaixou-me na cinza.',
        'E afastaste da paz a minha alma; esqueci-me do bem.',
        'Então disse eu: Já pereceu a minha força, como também a minha esperança no Senhor.',
        'Lembra-te da minha aflição e do meu pranto, do absinto e do fel.',
        'Minha alma certamente disto se lembra, e se abate dentro de mim.',
        'Disto me recordarei na minha mente; por isso esperarei.',
        'As misericórdias do Senhor são a causa de não sermos consumidos, porque as suas misericórdias não têm fim;',
        'Novas são cada manhã; grande é a tua fidelidade.',
        'A minha porção é o Senhor, diz a minha alma; portanto esperarei nele.',
        'Bom é o Senhor para os que esperam por ele, para a alma que o busca.',
        'Bom é ter esperança, e aguardar em silêncio a salvação do Senhor.',
        'Bom é para o homem suportar o jugo na sua mocidade.',
        'Assente-se solitário e fique em silêncio; porquanto Deus o pôs sobre ele.',
        'Ponha a sua boca no pó; talvez ainda haja esperança.',
        'Dê a sua face ao que o fere; farte-se de afronta.',
        'Pois o Senhor não rejeitará para sempre.',
        'Pois, ainda que entristeça a alguém, usará de compaixão, segundo a grandeza das suas misericórdias.',
        'Porque não aflige nem entristece de bom grado aos filhos dos homens.',
        'Pisar debaixo dos seus pés a todos os presos da terra,',
        'Perverter o direito do homem perante a face do Altíssimo,',
        'Subverter ao homem no seu pleito, não o veria o Senhor?',
        'Quem é aquele que diz, e assim acontece, quando o Senhor o não mande?',
        'Porventura da boca do Altíssimo não sai tanto o mal como o bem?',
        'De que se queixa, pois, o homem vivente? Queixe-se cada um dos seus pecados.',
        'Esquadrinhemos os nossos caminhos, e provemo-los, e voltemos para o Senhor.',
        'Levantemos os nossos corações com as mãos para Deus nos céus, dizendo:',
        'Nós transgredimos, e fomos rebeldes; por isso tu não perdoaste.',
        'Cobriste-te de ira, e nos perseguiste; mataste, não perdoaste.',
        'Cobriste-te de nuvens, para que não passe a nossa oração.',
        'Como escória e refugo nos puseste no meio dos povos.',
        'Todos os nossos inimigos abriram contra nós a sua boca.',
        'Temor e laço vieram sobre nós, assolação e destruição.',
        'Torrentes de água derramaram os meus olhos, por causa da destruição da filha do meu povo.',
        'Os meus olhos choram, e não cessam, porque não há descanso,',
        'Até que o Senhor atente e veja desde os céus.',
        'Os meus olhos entristecem a minha alma, por causa de todas as filhas da minha cidade.',
        'Como ave me caçam os que, sem causa, são meus inimigos.',
        'Cortaram-me a vida na masmorra, e lançaram pedras sobre mim.',
        'águas correram sobre a minha cabeça; eu disse: Estou cortado.',
        'Invoquei o teu nome, Senhor, desde a mais profunda masmorra.',
        'Ouviste a minha voz; não escondas o teu ouvido ao meu suspiro, ao meu clamor.',
        'Tu te aproximaste no dia em que te invoquei; disseste: Não temas.',
        'Pleiteaste, Senhor, as causas da minha alma, remiste a minha vida.',
        'Viste, Senhor, a injustiça que me fizeram; julga a minha causa.',
        'Viste toda a sua vingança, todos os seus pensamentos contra mim.',
        'Ouviste a sua afronta, Senhor, todos os seus pensamentos contra mim,',
        'Os lábios dos que se levantam contra mim e os seus desígnios me são contrários todo o dia.',
        'Observa-os ao assentarem-se e ao levantarem-se; eu sou a sua música.',
        'Tu lhes darás recompensa, Senhor, conforme a obra das suas mãos.',
        'Tu lhes darás ânsia de coração, maldição tua sobre eles.',
        'Na tua ira os perseguirás, e os destruirás de debaixo dos céus do Senhor.',
      ],
      [
        'Como se escureceu o ouro! Como se mudou o ouro puro e bom! Como estão espalhadas as pedras do santuário sobre cada rua!',
        'Os preciosos filhos de Sião, avaliados a puro ouro, como são agora reputados por vasos de barro, obra das mãos do oleiro!',
        'Até os chacais abaixam o peito, dão de mamar aos seus filhos; mas a filha do meu povo tornou-se cruel como os avestruzes no deserto.',
        'A língua do que mama fica pegada pela sede ao seu paladar; os meninos pedem pão, e ninguém lho reparte.',
        'Os que comiam comidas finas agora desfalecem nas ruas; os que se criaram em carmesim abraçam monturos.',
        'Porque maior é a iniqüidade da filha do meu povo do que o pecado de Sodoma, a qual foi subvertida como num momento, sem que mãos lhe tocassem.',
        'Os seus nobres eram mais puros do que a neve, mais brancos do que o leite, mais vermelhos de corpo do que os rubis, e mais polidos do que a safira.',
        'Mas agora escureceu-se o seu aspecto mais do que o negrume; não são conhecidos nas ruas; a sua pele se lhes pegou aos ossos, secou-se, tornou-se como um pau.',
        'Os mortos à espada foram mais ditosos do que os mortos à fome; porque estes morreram lentamente, por falta dos frutos dos campos.',
        'As mãos das mulheres compassivas cozeram seus próprios filhos; serviram-lhes de alimento na destruição da filha do meu povo.',
        'Deu o Senhor cumprimento ao seu furor; derramou o ardor da sua ira, e acendeu fogo em Sião, que consumiu os seus fundamentos.',
        'Não creram os reis da terra, nem todos os moradores do mundo, que entrasse o adversário e o inimigo pelas portas de Jerusalém.',
        'Foi por causa dos pecados dos profetas, das maldades dos seus sacerdotes, que derramaram o sangue dos justos no meio dela.',
        'Vagueiam como cegos nas ruas, andam contaminados de sangue; de tal sorte que ninguém pode tocar nas suas roupas.',
        'Desviai-vos, imundos! gritavam-lhes; desviai-vos, desviai-vos, não toqueis! quando fugiram e também andaram errantes, dizia-se entre os gentios: Nunca mais morarão aqui.',
        'A face indignada do Senhor os espalhou, ele nunca mais tornará a olhar para eles; não respeitaram a pessoa dos sacerdotes, nem se compadeceram dos velhos.',
        'Os nossos olhos desfaleciam, esperando o nosso vão socorro; olhávamos atentamente para uma nação que não nos podia livrar.',
        'Espiaram os nossos passos, de maneira que não podíamos andar pelas nossas ruas; está chegado o nosso fim, estão cumpridos os nossos dias, porque é vindo o nosso fim.',
        'Os nossos perseguidores foram mais ligeiros do que as águias dos céus; sobre os montes nos perseguiram, no deserto nos armaram ciladas.',
        'O fôlego das nossas narinas, o ungido do Senhor, foi preso nas suas covas; dele dizíamos: Debaixo da sua sombra viveremos entre os gentios.',
        'Regozija-te e alegra-te, ó filha de Edom, que habitas na terra de Uz; o cálice passará também para ti; embebedar-te-ás, e te descobrirás.',
        'O castigo da tua maldade está consumado, ó filha de Sião; ele nunca mais te levará para o cativeiro; ele visitará a tua maldade, ó filha de Edom, descobrirá os teus pecados.',
      ],
      [
        'Lembra-te, SENHOR, do que nos tem sucedido; considera, e olha o nosso opróbrio.',
        'A nossa herança passou a estrangeiros, e as nossas casas a forasteiros.',
        'Órfãos somos sem pai, nossas mães são como viúvas.',
        'A nossa água por dinheiro a bebemos, por preço vem a nossa lenha.',
        'Os nossos perseguidores estão sobre os nossos pescoços; estamos cansados, e não temos descanso.',
        'Aos egípcios e aos assírios estendemos as mãos, para nos fartarem de pão.',
        'Nossos pais pecaram, e já não existem; e nós levamos as suas maldades.',
        'Servos dominam sobre nós; ninguém há que nos livre da sua mão.',
        'Com perigo de nossas vidas trazemos o nosso pão, por causa da espada do deserto.',
        'Nossa pele se queimou como um forno, por causa do ardor da fome.',
        'Forçaram as mulheres em Sião, as virgens nas cidades de Judá.',
        'Os príncipes foram enforcados pelas mãos deles; as faces dos velhos não foram reverenciadas.',
        'Aos jovens obrigaram a moer, e os meninos caíram debaixo das cargas de lenha.',
        'Os velhos já não estão mais às portas, os jovens já deixaram a sua música.',
        'Cessou o gozo de nosso coração; converteu-se em lamentação a nossa dança.',
        'Caiu a coroa da nossa cabeça; ai de nós! porque pecamos.',
        'Por isso desmaiou o nosso coração; por isso se escureceram os nossos olhos.',
        'Pelo monte de Sião, que está assolado, andam as raposas.',
        'Tu, Senhor, permaneces eternamente, e o teu trono subsiste de geração em geração.',
        'Por que te esquecerias de nós para sempre? Por que nos desampararias por tanto tempo?',
        'Converte-nos a ti, Senhor, e seremos convertidos; renova os nossos dias como dantes.',
        'Mas tu nos rejeitaste totalmente. Tu estás muito enfurecido contra nós.',
      ],
    ],
    livro: 'lamentacoes',
  },
  {
    abbrev: 'ez',
    capitulos: [
      [
        'E aconteceu no trigésimo ano, no quarto mês, no quinto dia do mês, que estando eu no meio dos cativos, junto ao rio Quebar, se abriram os céus, e eu tive visões de Deus.',
        'No quinto dia do mês, no quinto ano do cativeiro do rei Jeoiaquim,',
        'Veio expressamente a palavra do Senhor a Ezequiel, filho de Buzi, o sacerdote, na terra dos caldeus, junto ao rio Quebar, e ali esteve sobre ele a mão do Senhor.',
        'Olhei, e eis que um vento tempestuoso vinha do norte, uma grande nuvem, com um fogo revolvendo-se nela, e um resplendor ao redor, e no meio dela havia uma coisa, como de cor de âmbar, que saía do meio do fogo.',
        'E do meio dela saía a semelhança de quatro seres viventes. E esta era a sua aparência: tinham a semelhança de homem.',
        'E cada um tinha quatro rostos, como também cada um deles quatro asas.',
        'E os seus pés eram pés direitos; e as plantas dos seus pés como a planta do pé de uma bezerra, e luziam como a cor de cobre polido.',
        'E tinham mãos de homem debaixo das suas asas, aos quatro lados; e assim todos quatro tinham seus rostos e suas asas.',
        'Uniam-se as suas asas uma à outra; não se viravam quando andavam, e cada qual andava continuamente em frente.',
        'E a semelhança dos seus rostos era como o rosto de homem; e do lado direito todos os quatro tinham rosto de leão, e do lado esquerdo todos os quatro tinham rosto de boi; e também tinham rosto de águia todos os quatro.',
        'Assim eram os seus rostos. As suas asas estavam estendidas por cima; cada qual tinha duas asas juntas uma a outra, e duas cobriam os corpos deles.',
        'E cada qual andava para adiante de si; para onde o espírito havia de ir, iam; não se viravam quando andavam.',
        'E, quanto à semelhança dos seres viventes, o seu aspecto era como ardentes brasas de fogo, com uma aparência de lâmpadas; o fogo subia e descia por entre os seres viventes, e o fogo resplandecia, e do fogo saíam relâmpagos;',
        'E os seres viventes corriam, e voltavam, à semelhança de um clarão de relâmpago.',
        'E vi os seres viventes; e eis que havia uma roda sobre a terra junto aos seres viventes, uma para cada um dos quatro rostos.',
        'O aspecto das rodas, e a obra delas, era como a cor de berilo; e as quatro tinham uma mesma semelhança; e o seu aspecto, e a sua obra, era como se estivera uma roda no meio de outra roda.',
        'Andando elas, andavam pelos seus quatro lados; não se viravam quando andavam.',
        'E os seus aros eram tão altos, que faziam medo; e estas quatro tinham as suas cambotas cheias de olhos ao redor.',
        'E, andando os seres viventes, andavam as rodas ao lado deles; e, elevando-se os seres viventes da terra, elevavam-se também as rodas.',
        'Para onde o espírito queria ir, eles iam; para onde o espírito tinha de ir; e as rodas se elevavam defronte deles, porque o espírito do ser vivente estava nas rodas.',
        'Andando eles, andavam elas e, parando eles, paravam elas e, elevando-se eles da terra, elevavam-se também as rodas defronte deles; porque o espírito do ser vivente estava nas rodas.',
        'E sobre as cabeças dos seres viventes havia uma semelhança de firmamento, com a aparência de cristal terrível, estendido por cima, sobre as suas cabeças.',
        'E debaixo do firmamento estavam as suas asas direitas uma em direção à outra; cada um tinha duas, que lhe cobriam o corpo de um lado; e cada um tinha outras duas asas, que os cobriam do outro lado.',
        'E, andando eles, ouvi o ruído das suas asas, como o ruído de muitas águas, como a voz do Onipotente, um tumulto como o estrépito de um exército; parando eles, abaixavam as suas asas.',
        'E ouviu-se uma voz vinda do firmamento, que estava por cima das suas cabeças; parando eles, abaixavam as suas asas.',
        'E por cima do firmamento, que estava por cima das suas cabeças, havia algo semelhante a um trono que parecia de pedra de safira; e sobre esta espécie de trono havia uma figura semelhante a de um homem, na parte de cima, sobre ele.',
        'E vi-a como a cor de âmbar, como a aparência do fogo pelo interior dele ao redor, desde o aspecto dos seus lombos, e daí para cima; e, desde o aspecto dos seus lombos e daí para baixo, vi como a semelhança de fogo, e um resplendor ao redor dele.',
        'Como o aspecto do arco que aparece na nuvem no dia da chuva, assim era o aspecto do resplendor em redor. Este era o aspecto da semelhança da glória do Senhor; e, vendo isto, caí sobre o meu rosto, e ouvi a voz de quem falava.',
      ],
      [
        'E disse-me: Filho do homem, põe-te em pé, e falarei contigo.',
        'Então entrou em mim o Espírito, quando ele falava comigo, e me pôs em pé, e ouvi o que me falava.',
        'E disse-me: Filho do homem, eu te envio aos filhos de Israel, às nações rebeldes que se rebelaram contra mim; eles e seus pais transgrediram contra mim até este mesmo dia.',
        'E os filhos são de semblante duro, e obstinados de coração; eu te envio a eles, e lhes dirás: Assim diz o Senhor DEUS.',
        'E eles, quer ouçam quer deixem de ouvir (porque eles são casa rebelde), hão de saber, contudo, que esteve no meio deles um profeta.',
        'E tu, ó filho do homem, não os temas, nem temas as suas palavras; ainda que estejam contigo sarças e espinhos, e tu habites entre escorpiões, não temas as suas palavras, nem te assustes com os seus semblantes, porque são casa rebelde.',
        'Mas tu lhes dirás as minhas palavras, quer ouçam quer deixem de ouvir, pois são rebeldes.',
        'Mas tu, ó filho do homem, ouve o que eu te falo, não sejas rebelde como a casa rebelde; abre a tua boca, e come o que eu te dou.',
        'Então vi, e eis que uma mão se estendia para mim, e eis que nela havia um rolo de livro.',
        'E estendeu-o diante de mim, e ele estava escrito por dentro e por fora; e nele estavam escritas lamentações, e suspiros e ais.',
      ],
      [
        'Depois me disse: Filho do homem, come o que achares; come este rolo, e vai, fala à casa de Israel.',
        'Então abri a minha boca, e me deu a comer o rolo.',
        'E disse-me: Filho do homem, dá de comer ao teu ventre, e enche as tuas entranhas deste rolo que eu te dou. Então o comi, e era na minha boca doce como o mel.',
        'E disse-me ainda: Filho do homem, vai, entra na casa de Israel, e dize-lhe as minhas palavras.',
        'Porque tu não és enviado a um povo de estranha fala, nem de língua difícil, mas à casa de Israel;',
        'Nem a muitos povos de estranha fala, e de língua difícil, cujas palavras não possas entender; se eu aos tais te enviara, certamente te dariam ouvidos.',
        'Mas a casa de Israel não te quererá dar ouvidos, porque não me querem dar ouvidos a mim; pois toda a casa de Israel é de fronte obstinada e dura de coração.',
        'Eis que fiz duro o teu rosto contra os seus rostos, e forte a tua fronte contra a sua fronte.',
        'Fiz como diamante a tua fronte, mais forte do que a pederneira; não os temas, pois, nem te assombres com os seus rostos, porque são casa rebelde.',
        'Disse-me mais: Filho do homem, recebe no teu coração todas as minhas palavras que te hei de dizer, e ouve-as com os teus ouvidos.',
        'Eia, pois, vai aos do cativeiro, aos filhos do teu povo, e lhes falarás e lhes dirás: Assim diz o Senhor DEUS, quer ouçam quer deixem de ouvir.',
        'E levantou-me o Espírito, e ouvi por detrás de mim uma voz de grande estrondo, que dizia: Bendita seja a glória do SENHOR, desde o seu lugar.',
        'E ouvi o ruído das asas dos seres viventes, que tocavam umas nas outras, e o ruído das rodas defronte deles, e o sonido de um grande estrondo.',
        'Então o espírito me levantou, e me levou; e eu me fui amargurado, na indignação do meu Espírito; porém a mão do SENHOR era forte sobre mim.',
        'E fui a Tel-Abibe, aos do cativeiro, que moravam junto ao rio Quebar, e eu morava onde eles moravam; e fiquei ali sete dias, pasmado no meio deles.',
        'E sucedeu que, ao fim de sete dias, veio a palavra do Senhor a mim, dizendo:',
        'Filho do homem: Eu te dei por atalaia sobre a casa de Israel; e tu da minha boca ouvirás a palavra e avisá-los-ás da minha parte.',
        'Quando eu disser ao ímpio: Certamente morrerás; e tu não o avisares, nem falares para avisar o ímpio acerca do seu mau caminho, para salvar a sua vida, aquele ímpio morrerá na sua iniqüidade, mas o seu sangue, da tua mão o requererei.',
        'Mas, se avisares ao ímpio, e ele não se converter da sua impiedade e do seu mau caminho, ele morrerá na sua iniqüidade, mas tu livraste a tua alma.',
        'Semelhantemente, quando o justo se desviar da sua justiça, e cometer a iniqüidade, e eu puser diante dele um tropeço, ele morrerá: porque tu não o avisaste, no seu pecado morrerá; e suas justiças, que tiver praticado, não serão lembradas, mas o seu sangue, da tua mão o requererei.',
        'Mas, avisando tu o justo, para que não peque, e ele não pecar, certamente viverá; porque foi avisado; e tu livraste a tua alma.',
        'E a mão do Senhor estava sobre mim ali, e ele me disse: Levanta-te, e sai ao vale, e ali falarei contigo.',
        'E levantei-me, e saí ao vale, e eis que a glória do Senhor estava ali, como a glória que vi junto ao rio Quebar; e caí sobre o meu rosto.',
        'Então entrou em mim o Espírito, e me pôs em pé, e falou comigo, e me disse: Entra, encerra-te dentro da tua casa.',
        'E quanto a ti, ó filho do homem, eis que porão cordas sobre ti, e te ligarão com elas; não sairás, pois, ao meio deles.',
        'E eu farei que a tua língua se pegue ao teu paladar, e ficarás mudo, e não lhes servirás de repreendedor; porque eles são casa rebelde.',
        'Mas, quando eu falar contigo, abrirei a tua boca, e lhes dirás: Assim diz o Senhor DEUS: Quem ouvir ouça, e quem deixar de ouvir, deixe; porque eles são casa rebelde.',
      ],
      [
        'Tu, pois, ó filho do homem, toma um tijolo, e pô-lo-ás diante de ti, e grava nele a cidade de Jerusalém.',
        'E põe contra ela um cerco, e edifica contra ela uma fortificação, e levanta contra ela uma trincheira, e põe contra ela arraiais, e põe-lhe aríetes em redor.',
        'E tu toma uma sertã de ferro, e põe-na por muro de ferro entre ti e a cidade; e dirige para ela o teu rosto, e assim será cercada, e a cercarás; isto servirá de sinal à casa de Israel.',
        'Tu também deita-te sobre o teu lado esquerdo, e põe a iniqüidade da casa de Israel sobre ele; conforme o número dos dias que te deitares sobre ele, levarás as suas iniqüidades.',
        'Porque eu já te tenho fixado os anos da sua iniqüidade, conforme o número dos dias, trezentos e noventa dias; e levarás a iniqüidade da casa de Israel.',
        'E, quando tiveres cumprido estes dias, tornar-te-ás a deitar sobre o teu lado direito, e levarás a iniqüidade da casa de Judá quarenta dias; um dia te dei para cada ano.',
        'Dirigirás, pois, o teu rosto para o cerco de Jerusalém, com o teu braço descoberto, e profetizarás contra ela.',
        'E eis que porei sobre ti cordas; assim tu não te voltarás de um lado para o outro, até que cumpras os dias do teu cerco.',
        'E tu, toma trigo, e cevada, e favas, e lentilhas, e milho e aveia, e coloca-os numa vasilha, e faze deles pão; conforme o número dos dias que tu te deitares sobre o teu lado, trezentos e noventa dias, comerás disso.',
        'E a tua comida, que hás de comer, será do peso de vinte siclos por dia; de tempo em tempo a comerás.',
        'Também beberás a água por medida, a saber, a sexta parte de um him; de tempo em tempo beberás.',
        'E o que comeres será como bolos de cevada, e cozê-los-ás sobre o esterco que sai do homem, diante dos olhos deles.',
        'E disse o Senhor: Assim comerão os filhos de Israel o seu pão imundo, entre os gentios para onde os lançarei.',
        'Então disse eu: Ah! Senhor DEUS! Eis que a minha alma não foi contaminada, pois desde a minha mocidade até agora, nunca comi daquilo que morrer de si mesmo, ou que é despedaçado por feras; nem carne abominável entrou na minha boca.',
        'E disse-me: Vê, dei-te esterco de vacas, em lugar de esterco de homem; e sobre ele prepararás o teu pão.',
        'Disse-me ainda: Filho do homem, eis que eu quebrarei o sustento de pão em Jerusalém, e comerão o pão por peso, e com ansiedade; e a água beberão por medida, e com espanto;',
        'Para que lhes falte o pão e a água, e se espantem uns com os outros, e se consumam nas suas iniqüidades.',
      ],
      [
        'E tu, ó filho do homem, toma uma faca afiada, como navalha de barbeiro, e a farás passar pela tua cabeça e pela tua barba; então tomarás uma balança de peso, e repartirás os cabelos.',
        'Uma terça parte queimarás no fogo, no meio da cidade, quando se cumprirem os dias do cerco; então tomarás outra terça parte, e feri-la-ás com uma faca ao redor dela; e a outra terça parte espalharás ao vento; porque desembainharei a espada atrás deles.',
        'Também tomarás dali um pequeno número, e atá-los-ás nas bordas do teu manto.',
        'E ainda destes tomarás alguns, e os lançarás no meio do fogo e os queimarás a fogo; e dali sairá um fogo contra toda a casa de Israel.',
        'Assim diz o Senhor DEUS: Esta é Jerusalém; coloquei-a no meio das nações e das terras que estão ao redor dela.',
        'Ela, porém, mudou em impiedade os meus juízos, mais do que as nações, e os meus estatutos mais do que as terras que estão ao redor dela; porque rejeitaram os meus juízos e os meus estatutos, e não andaram neles.',
        'Portanto assim diz o Senhor DEUS: Porque multiplicastes mais do que as nações, que estão ao redor de vós, e não andastes nos meus estatutos, nem guardastes os meus juízos, nem ainda procedestes segundo os juízos das nações que estão ao redor de vós;',
        'Por isso assim diz o Senhor DEUS: Eis que eu, sim eu, estou contra ti; e executarei juízos no meio de ti aos olhos das nações.',
        'E farei em ti o que nunca fiz, e o que jamais farei, por causa de todas as tuas abominações.',
        'Portanto os pais comerão a seus filhos no meio de ti, e os filhos comerão a seus pais; e executarei em ti juízos, e tudo o que restar de ti, espalharei a todos os ventos.',
        'Portanto, como eu vivo, diz o Senhor DEUS, certamente, porquanto profanaste o meu santuário com todas as tuas coisas detestáveis, e com todas as tuas abominações, também eu te diminuirei, e o meu olho não te perdoará, nem também terei piedade.',
        'Uma terça parte de ti morrerá de peste, e se consumirá de fome no meio de ti; e outra terça parte cairá à espada em redor de ti; e a outra terça parte espalharei a todos os ventos, e desembainharei a espada atrás deles.',
        'Assim se cumprirá a minha ira, e satisfarei neles o meu furor, e me consolarei; e saberão que eu, o Senhor, tenho falado no meu zelo, quando eu cumprir neles o meu furor.',
        'E pôr-te-ei em desolação, e por objeto de opróbrio entre as nações que estão em redor de ti, aos olhos de todos os que passarem.',
        'E será objeto de opróbrio e blasfêmia, instrução e espanto às nações que estão em redor de ti, quando eu executar em ti juízos com ira, e com furor, e com terríveis castigos. Eu, o Senhor, falei.',
        'Quando eu enviar as malignas flechas da fome contra eles, que servirão para destruição, as quais eu mandarei para vos destruir, então aumentarei a fome sobre vós, e vos quebrarei o sustento do pão.',
        'E enviarei sobre vós a fome, e as feras que te desfilharão; e a peste e o sangue passarão por ti; e trarei a espada sobre ti. Eu, o Senhor, falei.',
      ],
      [
        'E veio a mim a palavra do SENHOR, dizendo:',
        'Filho do homem, dirige o teu rosto para os montes de Israel, e profetiza contra eles.',
        'E dirás: Montes de Israel, ouvi a palavra do Senhor DEUS: Assim diz o Senhor DEUS aos montes, aos outeiros, aos ribeiros e aos vales: Eis que eu, sim eu, trarei a espada sobre vós, e destruirei os vossos lugares altos.',
        'E serão assolados os vossos altares, e quebradas as vossas imagens do sol e derrubarei os vossos mortos, diante dos vossos ídolos.',
        'E porei os cadáveres dos filhos de Israel diante dos seus ídolos; e espalharei os vossos ossos em redor dos vossos altares.',
        'Em todos os vossos lugares habitáveis, as cidades serão destruídas, e os lugares altos assolados; para que os vossos altares sejam destruídos e assolados, e os vossos ídolos se quebrem e se acabem, e as vossas imagens sejam cortadas, e desfeitas as vossas obras.',
        'E os mortos cairão no meio de vós, para que saibais que eu sou o Senhor.',
        'Porém deixarei um remanescente, para que tenhais entre as nações alguns que escaparem da espada, quando fordes espalhados pelas terras.',
        'Então os que dentre vós escaparem se lembrarão de mim entre as nações para onde foram levados em cativeiro; porquanto me quebrantei por causa do seu coração corrompido, que se desviou de mim, e por causa dos seus olhos, que andaram se corrompendo após os seus ídolos; e terão nojo de si mesmos, por causa das maldades que fizeram em todas as suas abominações.',
        'E saberão que eu sou o Senhor, e que não disse debalde que lhes faria este mal.',
        'Assim diz o Senhor DEUS: Bate com a mão, e bate com o teu pé, e dize: Ah! Por todas as grandes abominações da casa de Israel! Porque cairão à espada, e de fome, e de peste.',
        'O que estiver longe morrerá de peste, e o que está perto cairá à espada; e o que restar e ficar cercado morrerá de fome; assim cumprirei o meu furor sobre eles.',
        'Então sabereis que eu sou o Senhor, quando os seus mortos estiverem no meio dos seus ídolos, em redor dos seus altares, em todo o outeiro alto, em todos os cumes dos montes, e debaixo de toda a árvore verde, e debaixo de todo o carvalho frondoso, no lugar onde ofereciam cheiro suave a todos os seus ídolos.',
        'E estenderei a minha mão sobre eles, e farei a terra desolada, e mais devastada do que o deserto do lado de Dibla, em todas as suas habitações; e saberão que eu sou o Senhor.',
      ],
      [
        'Depois veio a mim a palavra do SENHOR, dizendo:',
        'E tu, ó filho do homem, assim diz o Senhor DEUS acerca da terra de Israel: Vem o fim, o fim vem sobre os quatro cantos da terra.',
        'Agora vem o fim sobre ti, e enviarei sobre ti a minha ira, e te julgarei conforme os teus caminhos, e trarei sobre ti todas as tuas abominações.',
        'E não te poupará o meu olho, nem terei piedade de ti, mas porei sobre ti os teus caminhos, e as tuas abominações estarão no meio de ti; e sabereis que eu sou o Senhor.',
        'Assim diz o Senhor DEUS: Um mal, eis que um só mal vem.',
        'Vem o fim, o fim vem, despertou-se contra ti; eis que vem.',
        'A manhà vem para ti, ó habitante da terra. Vem o tempo; chegado é o dia da turbação, e não mais o sonido de alegria dos montes.',
        'Agora depressa derramarei o meu furor sobre ti, e cumprirei a minha ira contra ti, e te julgarei conforme os teus caminhos, e porei sobre ti todas as tuas abominações.',
        'E não te poupará o meu olho, nem terei piedade de ti; conforme os teus caminhos, assim te punirei, e as tuas abominações estarão no meio de ti; e sabereis que eu, o Senhor, é que firo.',
        'Eis aqui o dia, eis que vem; veio a manhà, já floresceu a vara, já reverdeceu a soberba.',
        'A violência se levantou em vara de impiedade; nada restará deles, nem da sua multidão, nem do seu rumor, nem haverá lamentação por eles.',
        'Vem o tempo, é chegado o dia; o que compra não se alegre, e o que vende não se entristeça; porque a ira ardente está sobre toda a multidão deles.',
        'Porque o que vende não tornará a possuir o que vendeu, ainda que esteja entre os viventes; porque a visão, sobre toda a sua multidão, não tornará para trás, nem ninguém fortalecerá a sua vida com a sua iniqüidade.',
        'Já tocaram a trombeta, e tudo prepararam, mas não há quem vá à peleja, porque a minha ardente ira está sobre toda a sua multidão.',
        'Fora está a espada, e dentro a peste e a fome; o que estiver no campo morrerá à espada, e o que estiver na cidade a fome e a peste o consumirão.',
        'E escaparão os que fugirem deles, mas estarão pelos montes, como pombas dos vales, todos gemendo, cada um por causa da sua iniqüidade.',
        'Todas as mãos se enfraquecerão, e todos os joelhos serão débeis como água.',
        'E cingir-se-ão de sacos, e o terror os cobrirá; e sobre todos os rostos haverá vergonha, e sobre todas as suas cabeças, calva.',
        'A sua prata lançarão pelas ruas, e o seu ouro será removido; nem a sua prata nem o seu ouro os poderá livrar no dia do furor do Senhor; eles não fartarão a sua alma, nem lhes encherão o estômago, porque isto foi o tropeço da sua iniqüidade.',
        'E a glória do seu ornamento ele a pôs em magnificência, mas eles fizeram nela imagens das suas abominações e coisas detestáveis; por isso eu lha tenho feito coisa imunda.',
        'E entregá-la-ei por presa, na mão dos estrangeiros, e aos ímpios da terra por despojo; e a profanarão.',
        'E desviarei deles o meu rosto, e profanarão o meu lugar oculto; porque entrarão nele saqueadores, e o profanarão.',
        'Faze uma cadeia, porque a terra está cheia de crimes de sangue, e a cidade está cheia de violência.',
        'E farei vir os piores dentre os gentios e possuirão as suas casas; e farei cessar a arrogância dos fortes, e os seus lugares santos serão profanados.',
        'Vem a destruição; eles buscarão a paz, mas não há nenhuma.',
        'Miséria sobre miséria virá, e se levantará rumor sobre rumor; então buscarão do profeta uma visão, mas do sacerdote perecerá a lei e dos anciãos o conselho.',
        'O rei lamentará, e o príncipe se vestirá de desolação, e as mãos do povo da terra se conturbarão; conforme o seu caminho lhes farei, e conforme os seus merecimentos os julgarei; e saberão que eu sou o Senhor.',
      ],
      [
        'Sucedeu, pois, no sexto ano, no sexto mês, no quinto dia do mês, estando eu assentado na minha casa, e os anciãos de Judá assentados diante de mim, que ali a mão do Senhor DEUS caiu sobre mim.',
        'E olhei, e eis uma semelhança como o aspecto de fogo; desde o aspecto dos seus lombos, e daí para baixo, era fogo; e dos seus lombos e daí para cima como o aspecto de um resplendor como a cor de âmbar.',
        'E estendeu a forma de uma mão, e tomou-me pelos cabelos da minha cabeça; e o Espírito me levantou entre a terra e o céu, e levou-me a Jerusalém em visões de Deus, até à entrada da porta do pátio de dentro, que olha para o norte, onde estava o assento da imagem do ciúmes, que provoca ciúmes.',
        'E eis que a glória do Deus de Israel estava ali, conforme o aspecto que eu tinha visto no vale.',
        'E disse-me: Filho do homem, levanta agora os teus olhos para o caminho do norte. E levantei os meus olhos para o caminho do norte, e eis que ao norte da porta do altar, estava esta imagem de ciúmes na entrada.',
        'E disse-me: Filho do homem, vês tu o que eles estão fazendo? As grandes abominações que a casa de Israel faz aqui, para que me afaste do meu santuário? Mas ainda tornarás a ver maiores abominações.',
        'E levou-me à porta do átrio; então olhei, e eis que havia um buraco na parede.',
        'E disse-me: Filho do homem, cava agora naquela parede. E cavei na parede, e eis que havia uma porta.',
        'Então me disse: Entra, e vê as malignas abominações que eles fazem aqui.',
        'E entrei, e olhei, e eis que toda a forma de répteis, e animais abomináveis, e de todos os ídolos da casa de Israel, estavam pintados na parede em todo o redor.',
        'E estavam em pé diante deles setenta homens dos anciãos da casa de Israel, e Jaazanias, filho de Safã, em pé, no meio deles, e cada um tinha na mão o seu incensário; e subia uma espessa nuvem de incenso.',
        'Então me disse: Viste, filho do homem, o que os anciãos da casa de Israel fazem nas trevas, cada um nas suas câmaras pintadas de imagens? Pois dizem: O Senhor não nos vê; o Senhor abandonou a terra.',
        'E disse-me: Ainda tornarás a ver maiores abominações, que estes fazem.',
        'E levou-me à entrada da porta da casa do Senhor, que está do lado norte, e eis que estavam ali mulheres assentadas chorando a Tamuz.',
        'E disse-me: Vês isto, filho do homem? Ainda tornarás a ver abominações maiores do que estas.',
        'E levou-me para o átrio interior da casa do Senhor, e eis que estavam à entrada do templo do Senhor, entre o pórtico e o altar, cerca de vinte e cinco homens, de costas para o templo do Senhor, e com os rostos para o oriente; e eles, virados para o oriente adoravam o sol.',
        'Então me disse: Vês isto, filho do homem? Há porventura coisa mais leviana para a casa de Judá, do que tais abominações, que fazem aqui? Havendo enchido a terra de violência, tornam a irritar-me; e ei-los a chegar o ramo ao seu nariz.',
        'Por isso também eu os tratarei com furor; o meu olho não poupará, nem terei piedade; ainda que me gritem aos ouvidos com grande voz, contudo não os ouvirei.',
      ],
      [
        'Então me gritou aos ouvidos com grande voz, dizendo: Fazei chegar os intendentes da cidade, cada um com as suas armas destruidoras na mão.',
        'E eis que vinham seis homens a caminho da porta superior, que olha para o norte, e cada um com a sua arma destruidora na mão, e entre eles um homem vestido de linho, com um tinteiro de escrivão à sua cintura; e entraram, e se puseram junto ao altar de bronze.',
        'E a glória do Deus de Israel se levantou de sobre o querubim, sobre o qual estava, indo até a entrada da casa; e clamou ao homem vestido de linho, que tinha o tinteiro de escrivão à sua cintura.',
        'E disse-lhe o Senhor: Passa pelo meio da cidade, pelo meio de Jerusalém, e marca com um sinal as testas dos homens que suspiram e que gemem por causa de todas as abominações que se cometem no meio dela.',
        'E aos outros disse ele, ouvindo eu: Passai pela cidade após ele, e feri; não poupe o vosso olho, nem vos compadeçais.',
        'Matai velhos, jovens, virgens, meninos e mulheres, até exterminá-los; mas a todo o homem que tiver o sinal não vos chegueis; e começai pelo meu santuário. E começaram pelos homens mais velhos que estavam diante da casa.',
        'E disse-lhes: Contaminai a casa e enchei os átrios de mortos; saí. E saíram, e feriram na cidade.',
        'Sucedeu, pois, que, havendo-os ferido, e ficando eu sozinho, caí sobre a minha face, e clamei, e disse: Ah! Senhor DEUS! dar-se-á caso que destruas todo o restante de Israel, derramando a tua indignação sobre Jerusalém?',
        'Então me disse: A maldade da casa de Israel e de Judá é grandíssima, e a terra se encheu de sangue e a cidade se encheu de perversidade; porque dizem: O Senhor abandonou a terra, e o Senhor não vê.',
        'Pois, também, quanto a mim, não poupará o meu olho, nem me compadecerei; sobre a cabeça deles farei recair o seu caminho.',
        'Eis que o homem que estava vestido de linho, a cuja cintura estava o tinteiro, tornou com a resposta, dizendo: Fiz como me mandaste.',
      ],
      [
        'Depois olhei, e eis que no firmamento, que estava por cima da cabeça dos querubins, apareceu sobre eles uma como pedra de safira, semelhante a forma de um trono.',
        'E falou ao homem vestido de linho, dizendo: Vai por entre as rodas, até debaixo do querubim, e enche as tuas mãos de brasas acesas dentre os querubins e espalha-as sobre a cidade. E ele entrou à minha vista.',
        'E os querubins estavam ao lado direito da casa, quando entrou aquele homem; e uma nuvem encheu o átrio interior.',
        'Então se levantou a glória do Senhor de sobre o querubim indo para a entrada da casa; e encheu-se a casa de uma nuvem, e o átrio se encheu do resplendor da glória do Senhor.',
        'E o ruído das asas dos querubins se ouviu até ao átrio exterior, como a voz do Deus Todo-Poderoso, quando fala.',
        'Sucedeu, pois, que, dando ele ordem ao homem vestido de linho, dizendo: Toma fogo dentre as rodas, dentre os querubins, entrou ele, e parou junto às rodas.',
        'Então estendeu um querubim a sua mão dentre os querubins para o fogo que estava entre os querubins; e tomou dele, e o pôs nas mãos do que estava vestido de linho; o qual o tomou, e saiu.',
        'E apareceu nos querubins uma semelhança de mão de homem debaixo das suas asas.',
        'Então olhei, e eis quatro rodas junto aos querubins, uma roda junto a um querubim, e outra roda junto a outro querubim; e o aspecto das rodas era como a cor da pedra de berilo.',
        'E, quanto ao seu aspecto, as quatro tinham uma mesma semelhança; como se estivesse uma roda no meio de outra roda.',
        'Andando estes, andavam para os quatro lados deles; não se viravam quando andavam, mas para o lugar para onde olhava a cabeça, para esse seguiam; não se viravam quando andavam.',
        'E todo o seu corpo, as suas costas, as suas mãos, as suas asas e as rodas, as rodas que os quatro tinham, estavam cheias de olhos ao redor.',
        'E, quanto às rodas, ouvindo eu, se lhes gritava: Roda!',
        'E cada um tinha quatro rostos; o rosto do primeiro era rosto de querubim, e o rosto do segundo, rosto de homem, e do terceiro era rosto de leão, e do quarto, rosto de águia.',
        'E os querubins se elevaram ao alto; estes são os mesmos seres viventes que vi junto ao rio Quebar.',
        'E, andando os querubins, andavam as rodas juntamente com eles; e, levantando os querubins as suas asas, para se elevarem de sobre a terra, também as rodas não se separavam deles.',
        'Parando eles, paravam elas; e, elevando-se eles elevavam-se elas, porque o espírito do ser vivente estava nelas.',
        'Então saiu a glória do Senhor de sobre a entrada da casa, e parou sobre os querubins.',
        'E os querubins alçaram as suas asas, e se elevaram da terra aos meus olhos, quando saíram; e as rodas os acompanhavam; e cada um parou à entrada da porta oriental da casa do Senhor; e a glória do Deus de Israel estava em cima, sobre eles.',
        'Estes são os seres viventes que vi debaixo do Deus de Israel, junto ao rio Quebar, e conheci que eram querubins.',
        'Cada um tinha quatro rostos e cada um quatro asas, e a semelhança de mãos de homem debaixo das suas asas.',
        'E a semelhança dos seus rostos era a dos rostos que eu tinha visto junto ao rio Quebar, o aspecto deles, e eles mesmos; cada um andava para diante do seu rosto.',
      ],
      [
        'Então me levantou o Espírito, e me levou à porta oriental da casa do SENHOR, a qual olha para o oriente; e eis que estavam à entrada da porta vinte e cinco homens; e no meio deles vi a Jaazanias, filho de Azur, e a Pelatias, filho de Benaia, príncipes do povo.',
        'E disse-me: Filho do homem, estes são os homens que maquinam perversidade, e dão mau conselho nesta cidade.',
        'Os quais dizem: Não está próximo o tempo de edificar casas; esta cidade é o caldeirão, e nós a carne.',
        'Portanto, profetiza contra eles; profetiza, ó filho do homem.',
        'Caiu, pois, sobre mim o Espírito do Senhor, e disse-me: Fala: Assim diz o Senhor: Assim haveis falado, ó casa de Israel, porque, quanto às coisas que vos sobem ao espírito, eu as conheço.',
        'Multiplicastes os vossos mortos nesta cidade, e enchestes as suas ruas de mortos.',
        'Portanto, assim diz o Senhor DEUS: Vossos mortos, que deitastes no meio dela, esses são a carne e ela é o caldeirão; a vós, porém, vos tirarei do meio dela.',
        'Temestes a espada, e a espada trarei sobre vós, diz o Senhor DEUS.',
        'E vos farei sair do meio dela, e vos entregarei na mão de estrangeiros, e exercerei os meus juízos entre vós.',
        'Caireis à espada, e nos confins de Israel vos julgarei; e sabereis que eu sou o Senhor.',
        'Esta cidade não vos servirá de caldeirão, nem vós servireis de carne no meio dela; nos confins de Israel vos julgarei.',
        'E sabereis que eu sou o Senhor, porque não andastes nos meus estatutos, nem cumpristes os meus juízos; antes fizestes conforme os juízos dos gentios que estão ao redor de vós.',
        'E aconteceu que, profetizando eu, morreu Pelatias, filho de Benaia; então caí sobre o meu rosto, e clamei com grande voz, e disse: Ah! Senhor DEUS! Porventura darás tu fim ao remanescente de Israel?',
        'Então veio a mim a palavra do Senhor, dizendo:',
        'Filho do homem, teus irmãos, sim, teus irmãos, os homens de teu parentesco, e toda a casa de Israel, todos eles são aqueles a quem os habitantes de Jerusalém disseram: Apartai-vos para longe do Senhor; esta terra nos foi dada em possessão.',
        'Portanto, dize: Assim diz o Senhor DEUS: Ainda que os lancei para longe entre os gentios, e ainda que os espalhei pelas terras, todavia lhes serei como um pequeno santuário, nas terras para onde forem.',
        'Portanto, dize: Assim diz o Senhor DEUS: Hei de ajuntar-vos do meio dos povos, e vos recolherei das terras para onde fostes lançados, e vos darei a terra de Israel.',
        'E virão ali, e tirarão dela todas as suas coisas detestáveis e todas as suas abominações.',
        'E lhes darei um só coração, e um espírito novo porei dentro deles; e tirarei da sua carne o coração de pedra, e lhes darei um coração de carne;',
        'Para que andem nos meus estatutos, e guardem os meus juízos, e os cumpram; e eles me serão por povo, e eu lhes serei por Deus.',
        'Mas, quanto àqueles cujo coração andar conforme o coração das suas coisas detestáveis, e as suas abominações, farei recair nas suas cabeças o seu caminho, diz o Senhor DEUS.',
        'Então os querubins elevaram as suas asas, e as rodas os acompanhavam; e a glória do Deus de Israel estava em cima sobre eles.',
        'E a glória do Senhor se alçou desde o meio da cidade; e se pôs sobre o monte que está ao oriente da cidade.',
        'Depois o Espírito me levantou, e me levou à Caldéia, para os do cativeiro, em visão, pelo Espírito de Deus; e subiu de sobre mim a visão que eu tinha tido.',
        'E falei aos do cativeiro todas as coisas que o Senhor me havia mostrado.',
      ],
      [
        'E veio a mim a palavra do SENHOR, dizendo:',
        'Filho do homem, tu habitas no meio da casa rebelde, que tem olhos para ver e não vê, e tem ouvidos para ouvir e não ouve; porque eles são casa rebelde.',
        'Tu, pois, ó filho do homem, prepara mobílias para mudares, e de dia muda aos olhos deles; e do teu lugar mudarás para outro lugar aos olhos deles; bem pode ser que reparem nisso, ainda que eles são casa rebelde.',
        'Aos olhos deles, pois, tirarás para fora, de dia, as tuas mobílias, como quem vai mudar; então tu sairás de tarde aos olhos deles, como quem sai mudando para o cativeiro.',
        'Faze para ti, à vista deles, uma abertura na parede, e tira-as para fora, por ali.',
        'Aos olhos deles, nos seus ombros, às escuras as tirarás, e cobrirás o teu rosto, para que não vejas a terra; porque te dei por sinal à casa de Israel.',
        'E fiz assim, como se me deu ordem; as minhas mobílias tirei para fora de dia, como mobílias do cativeiro; então à tarde fiz, com a mão, uma abertura na parede; às escuras as tirei para fora, e nos meus ombros as levei, aos olhos deles.',
        'E, pela manhã, veio a mim a palavra do Senhor, dizendo:',
        'Filho do homem, porventura não te disse a casa de Israel, aquela casa rebelde: Que fazes tu?',
        'Dize-lhes: Assim diz o Senhor DEUS: Esta carga refere-se ao príncipe em Jerusalém, e a toda a casa de Israel, que está no meio dela.',
        'Dize: Eu sou o vosso sinal. Assim como eu fiz, assim se lhes fará a eles; irão para o exílio em cativeiro.',
        'E o príncipe que está no meio deles levará aos ombros as mobílias, e às escuras sairá; farão uma abertura na parede para as tirarem por ela; o seu rosto cobrirá, para que com os seus olhos não veja a terra.',
        'Também estenderei a minha rede sobre ele, e será apanhado no meu laço; e o levarei à babilônia, à terra dos caldeus, e contudo não a verá, ainda que ali morrerá.',
        'E a todos os ventos espalharei os que estiverem ao redor dele para seu socorro, e a todas as suas tropas; e desembainharei a espada atrás deles.',
        'Assim saberão que eu sou o Senhor, quando eu os dispersar entre as nações e os espalhar pelas terras.',
        'Mas deles deixarei ficar alguns poucos, escapos da espada, da fome, e da peste, para que contem todas as suas abominações entre as nações para onde forem; e saberão que eu sou o Senhor.',
        'Então veio a mim a palavra do Senhor, dizendo:',
        'Filho do homem, o teu pão comerás com tremor, e a tua água beberás com estremecimento e com receio.',
        'E dirás ao povo da terra: Assim diz o Senhor DEUS acerca dos habitantes de Jerusalém, na terra de Israel: O seu pão comerão com receio, e a sua água beberão com susto, pois a sua terra será despojada de sua abundância, por causa da violência de todos os que nela habitam.',
        'E as cidades habitadas serão devastadas, e a terra se tornará em desolação; e sabereis que eu sou o Senhor.',
        'E veio ainda a mim a palavra do Senhor, dizendo:',
        'Filho do homem, que provérbio é este que vós tendes na terra de Israel, dizendo: Prolongar-se-ão os dias, e perecerá toda a visão?',
        'Portanto, dize-lhes: Assim diz o Senhor DEUS: Farei cessar este provérbio, e já não se servirão mais dele em Israel; mas dize-lhes: Os dias estão próximos e o cumprimento de toda a visão.',
        'Porque não haverá mais alguma visão vã, nem adivinhação lisonjeira, no meio da casa de Israel.',
        'Porque eu, o SENHOR, falarei, e a palavra que eu falar se cumprirá; não será mais adiada; porque em vossos dias, ó casa rebelde, falarei uma palavra e a cumprirei, diz o Senhor DEUS.',
        'Veio mais a mim a palavra do Senhor, dizendo:',
        'Filho do homem, eis que os da casa de Israel dizem: A visão que este tem é para muitos dias, e ele profetiza de tempos que estão longe.',
        'Portanto dize-lhes: Assim diz o Senhor DEUS: Não será mais adiada nenhuma das minhas palavras; e a palavra que falei se cumprirá, diz o Senhor DEUS.',
      ],
      [
        'E veio a mim a palavra do SENHOR, dizendo:',
        'Filho do homem, profetiza contra os profetas de Israel que profetizam, e dize aos que só profetizam de seu coração: Ouvi a palavra do Senhor;',
        'Assim diz o Senhor DEUS: Ai dos profetas loucos, que seguem o seu próprio espírito e que nada viram!',
        'Os teus profetas, ó Israel, são como raposas nos desertos.',
        'Não subistes às brechas, nem reparastes o muro para a casa de Israel, para estardes firmes na peleja no dia do Senhor.',
        'Viram vaidade e adivinhação mentirosa os que dizem: O Senhor disse; quando o Senhor não os enviou; e fazem que se espere o cumprimento da palavra.',
        'Porventura não tivestes visão de vaidade, e não falastes adivinhação mentirosa, quando dissestes: O Senhor diz, sendo que eu tal não falei?',
        'Portanto assim diz o Senhor DEUS: Como tendes falado vaidade, e visto a mentira, portanto eis que eu sou contra vós, diz o Senhor DEUS.',
        'E a minha mão será contra os profetas que vêem vaidade e que adivinham mentira; não estarão na congregação do meu povo, nem nos registros da casa de Israel se escreverão, nem entrarão na terra de Israel; e sabereis que eu sou o Senhor DEUS.',
        'Porquanto, sim, porquanto andam enganando o meu povo, dizendo: Paz, não havendo paz; e quando um edifica uma parede, eis que outros a cobrem com argamassa não temperada;',
        'Dize aos que a cobrem com argamassa não temperada que ela cairá. Haverá uma grande pancada de chuva, e vós, ó pedras grandes de saraiva, caireis, e um vento tempestuoso a fenderá.',
        'Ora, eis que, caindo a parede, não vos dirão: Onde está a argamassa com que a cobristes?',
        'Portanto assim diz o Senhor DEUS: Fendê-la-ei no meu furor com vento tempestuoso, e chuva de inundar haverá na minha ira, e grandes pedras de saraiva na minha indignação, para a consumir.',
        'E derrubarei a parede que cobristes com argamassa não temperada, e darei com ela por terra, e o seu fundamento se descobrirá; assim cairá, e perecereis no meio dela, e sabereis que eu sou o Senhor.',
        'Assim cumprirei o meu furor contra a parede, e contra os que a cobriram com argamassa não temperada; e vos direi: Já não há parede, nem existem os que a cobriram;',
        'Os profetas de Israel, que profetizam acerca de Jerusalém, e vêem para ela visão de paz, não havendo paz, diz o Senhor DEUS.',
        'E tu, ó filho do homem, dirige o teu rosto contra as filhas do teu povo, que profetizam de seu coração, e profetiza contra elas,',
        'E dize: Assim diz o Senhor DEUS: Ai das que cosem almofadas para todas as axilas, e que fazem véus para as cabeças de pessoas de toda a estatura, para caçarem as almas! Porventura caçareis as almas do meu povo, e as almas guardareis em vida para vós?',
        'E vós me profanastes entre o meu povo, por punhados de cevada, e por pedaços de pão, para matardes as almas que não haviam de morrer, e para guardardes em vida as almas que não haviam de viver, mentindo assim ao meu povo que escuta a mentira?',
        'Portanto assim diz o Senhor DEUS: Eis aí vou eu contra as vossas almofadas, com que vós ali caçais as almas fazendo-as voar, e as arrancarei de vossos braços, e soltarei as almas, sim, as almas que vós caçais fazendo-as voar.',
        'E rasgarei os vossos véus, e livrarei o meu povo das vossas mãos, e nunca mais estará em vossas mãos para ser caçado; e sabereis que eu sou o Senhor.',
        'Visto que entristecestes o coração do justo com falsidade, não o havendo eu entristecido; e fortalecestes as mãos do ímpio, para que não se desviasse do seu mau caminho, para conservá-lo em vida.',
        'Portanto não vereis mais vaidade, nem mais fareis adivinhações; mas livrarei o meu povo da vossa mão, e sabereis que eu sou o Senhor.',
      ],
      [
        'E vieram a mim alguns homens dos anciãos de Israel, e se assentaram diante de mim.',
        'Então veio a mim a palavra do Senhor, dizendo:',
        'Filho do homem, estes homens levantaram os seus ídolos nos seus corações, e o tropeço da sua maldade puseram diante da sua face; devo eu de alguma maneira ser interrogado por eles?',
        'Portanto fala com eles, e dize-lhes: Assim diz o Senhor DEUS: Qualquer homem da casa de Israel, que levantar os seus ídolos no seu coração, e puser o tropeço da sua maldade diante da sua face, e vier ao profeta, eu, o SENHOR, vindo ele, lhe responderei conforme a multidão dos seus ídolos;',
        'Para que eu possa apanhar a casa de Israel no seu coração, porquanto todos se apartaram de mim para seguirem os seus ídolos.',
        'Portanto dize à casa de Israel: Assim diz o Senhor DEUS: Convertei-vos, e tornai-vos dos vossos ídolos; e desviai os vossos rostos de todas as vossas abominações;',
        'Porque qualquer homem da casa de Israel, e dos estrangeiros que peregrinam em Israel, que se alienar de mim, e levantar os seus ídolos no seu coração, e puser o tropeço da sua maldade diante do seu rosto, e vier ao profeta, para me consultar por meio dele, eu, o Senhor, lhe responderei por mim mesmo.',
        'E porei o meu rosto contra o tal homem, e o assolarei para que sirva de sinal e provérbio, e arrancá-lo-ei do meio do meu povo; e sabereis que eu sou o Senhor.',
        'E se o profeta for enganado, e falar alguma coisa, eu, o Senhor, terei enganado esse profeta; e estenderei a minha mão contra ele, e destruí-lo-ei do meio do meu povo Israel.',
        'E levarão sobre si o castigo da sua iniqüidade; o castigo do profeta será como o castigo de quem o consultar.',
        'Para que a casa de Israel não se desvie mais de mim, nem mais se contamine com todas as suas transgressões; então eles serão o meu povo, e eu lhes serei o seu Deus, diz o Senhor DEUS.',
        'Veio ainda a mim a palavra do Senhor, dizendo:',
        'Filho do homem, quando uma terra pecar contra mim, se rebelando gravemente, então estenderei a minha mão contra ela, e lhe quebrarei o sustento do pão, e enviarei contra ela fome, e cortarei dela homens e animais.',
        'Ainda que estivessem no meio dela estes três homens, Noé, Daniel e Jó, eles pela sua justiça livrariam apenas as suas almas, diz o Senhor DEUS.',
        'Se eu fizer passar pela terra as feras selvagens, e elas a desfilharem de modo que fique desolada, e ninguém possa passar por ela por causa das feras;',
        'E estes três homens estivessem no meio dela, vivo eu, diz o Senhor DEUS, que nem a filhos nem a filhas livrariam; eles só ficariam livres, e a terra seria assolada.',
        'Ou, se eu trouxer a espada sobre aquela terra, e disser: Espada, passa pela terra; e eu cortar dela homens e animais;',
        'Ainda que aqueles três homens estivessem nela, vivo eu, diz o Senhor DEUS, que nem filhos nem filhas livrariam, mas somente eles ficariam livres.',
        'Ou, se eu enviar a peste sobre aquela terra, e derramar o meu furor sobre ela com sangue, para cortar dela homens e animais,',
        'Ainda que Noé, Daniel e Jó estivessem no meio dela, vivo eu, diz o Senhor DEUS, que nem um filho nem uma filha eles livrariam, mas somente eles livrariam as suas próprias almas pela sua justiça.',
        'Porque assim diz o Senhor DEUS: Quanto mais, se eu enviar os meus quatro maus juízos, a espada, a fome, as feras, e a peste, contra Jerusalém, para cortar dela homens e feras?',
        'Mas eis que alguns fugitivos restarão nela, que serão levados para fora, assim filhos e filhas; eis que eles virão a vós, e vereis o seu caminho e os seus feitos; e ficareis consolados do mal que eu trouxe sobre Jerusalém, e de tudo o que trouxe sobre ela.',
        'E sereis consolados, quando virdes o seu caminho e os seus feitos; e sabereis que não fiz sem razão tudo quanto nela tenho feito, diz o Senhor DEUS.',
      ],
      [
        'E veio a mim a palavra do SENHOR, dizendo:',
        'Filho do homem, que mais é a árvore da videira do que qualquer outra árvore, ou do que o sarmento que está entre as árvores do bosque?',
        'Toma-se dela madeira para fazer alguma obra? Ou toma-se dela alguma estaca, para que se lhe pendure um vaso?',
        'Eis que é lançado no fogo, para ser consumido; ambas as suas extremidades consome o fogo, e o meio dela fica também queimado; serviria porventura para alguma obra?',
        'Ora, se estando inteiro, não servia para obra alguma, quanto menos sendo consumido pelo fogo, e, sendo queimado, se faria ainda obra dele?',
        'Portanto, assim diz o Senhor DEUS: Como a árvore da videira entre as árvores do bosque, que tenho entregue ao fogo para que seja consumido, assim entregarei os habitantes de Jerusalém.',
        'E porei a minha face contra eles; do fogo sairão, mas o fogo os consumirá; e sabereis que eu sou o Senhor, quando tiver posto a minha face contra eles.',
        'E tornarei a terra em desolação, porquanto grandemente transgrediram, diz o Senhor DEUS.',
      ],
      [
        'E veio a mim outra vez a palavra do SENHOR, dizendo:',
        'Filho do homem, faze conhecer a Jerusalém as suas abominações.',
        'E dize: Assim diz o Senhor DEUS a Jerusalém: A tua origem e o teu nascimento procedem da terra dos cananeus. Teu pai era amorreu, e tua mãe hetéia.',
        'E, quanto ao teu nascimento, no dia em que nasceste não te foi cortado o umbigo, nem foste lavada com água para te limpar; nem tampouco foste esfregada com sal, nem envolta em faixas.',
        'Não se apiedou de ti olho algum, para te fazer alguma coisa disto, compadecendo-se de ti; antes foste lançada em pleno campo, pelo nojo da tua pessoa, no dia em que nasceste.',
        'E, passando eu junto de ti, vi-te a revolver-te no teu sangue, e disse-te: Ainda que estejas no teu sangue, vive; sim, disse-te: Ainda que estejas no teu sangue, vive.',
        'Eu te fiz multiplicar como o renovo do campo, e cresceste, e te engrandeceste, e chegaste à grande formosura; avultaram os seios, e cresceu o teu cabelo; mas estavas nua e descoberta.',
        'E, passando eu junto de ti, vi-te, e eis que o teu tempo era tempo de amores; e estendi sobre ti a aba do meu manto, e cobri a tua nudez; e dei-te juramento, e entrei em aliança contigo, diz o Senhor DEUS, e tu ficaste sendo minha.',
        'Então te lavei com água, e te enxuguei do teu sangue, e te ungi com óleo.',
        'E te vesti com roupas bordadas, e te calcei com pele de texugo, e te cingi com linho fino, e te cobri de seda.',
        'E te enfeitei com adornos, e te pus braceletes nas mãos e um colar ao redor do teu pescoço.',
        'E te pus um pendente na testa, e brincos nas orelhas, e uma coroa de glória na cabeça.',
        'E assim foste ornada de ouro e prata, e o teu vestido foi de linho fino, e de seda e de bordados; nutriste-te de flor de farinha, e mel e azeite; e foste formosa em extremo, e foste próspera, até chegares a realeza.',
        'E correu de ti a tua fama entre os gentios, por causa da tua formosura, pois era perfeita, por causa da minha glória que eu pusera em ti, diz o Senhor DEUS.',
        'Mas confiaste na tua formosura, e te corrompeste por causa da tua fama, e prostituías-te a todo o que passava, para seres dele.',
        'E tomaste dos teus vestidos, e fizeste lugares altos pintados de diversas cores, e te prostituíste sobre eles, como nunca sucedera, nem sucederá.',
        'E tomaste as tuas jóias de enfeite, que eu te dei do meu ouro e da minha prata, e fizeste imagens de homens, e te prostituíste com elas.',
        'E tomaste os teus vestidos bordados, e as cobriste; e o meu azeite e o meu perfume puseste diante delas.',
        'E o meu pão que te dei, a flor de farinha, e o azeite e o mel com que eu te sustentava, também puseste diante delas em cheiro suave; e assim foi, diz o Senhor DEUS.',
        'Além disto, tomaste a teus filhos e tuas filhas, que me tinhas gerado, e os sacrificaste a elas, para serem consumidos; acaso é pequena a tua prostituição?',
        'E mataste a meus filhos, e os entregaste a elas para os fazerem passar pelo fogo.',
        'E em todas as tuas abominações, e nas tuas prostituições, não te lembraste dos dias da tua mocidade, quando tu estavas nua e descoberta, e revolvida no teu sangue.',
        'E sucedeu, depois de toda a tua maldade (ai, ai de ti! diz o Senhor DEUS),',
        'Que edificaste uma abóbada, e fizeste lugares altos em cada rua.',
        'A cada canto do caminho edificaste o teu lugar alto, e fizeste abominável a tua formosura, e alargaste os teus pés a todo o que passava, e multiplicaste as tuas prostituições.',
        'Também te prostituíste com os filhos do Egito, teus vizinhos grandes de carne, e multiplicaste a tua prostituição para me provocares à ira.',
        'Por isso estendi a minha mão sobre ti, e diminuí a tua porção; e te entreguei à vontade das que te odeiam, das filhas dos filisteus, as quais se envergonhavam do teu caminho depravado.',
        'Também te prostituíste com os filhos da Assíria, porquanto eras insaciável; e prostituindo-te com eles, nem ainda assim ficaste farta.',
        'Antes multiplicaste as tuas prostituições na terra de Canaã até Caldéia, e nem ainda com isso te fartaste.',
        'Quão fraco é o teu coração, diz o Senhor DEUS, fazendo tu todas estas coisas, obras de uma meretriz imperiosa!',
        'Edificando tu a tua abóbada ao canto de cada caminho, e fazendo o teu lugar alto em cada rua! Nem foste como a meretriz, pois desprezaste a paga;',
        'Foste como a mulher adúltera que, em lugar de seu marido, recebe os estranhos.',
        'A todas as meretrizes dão paga, mas tu dás os teus presentes a todos os teus amantes; e lhes dás presentes, para que venham a ti de todas as partes, pelas tuas prostituições.',
        'Assim que contigo sucede o contrário das outras mulheres nas tuas prostituições, pois ninguém te procura para prostituição; porque, dando tu a paga, e a ti não sendo dada a paga, fazes o contrário.',
        'Portanto, ó meretriz, ouve a palavra do Senhor.',
        'Assim diz o Senhor DEUS: Porquanto se derramou o teu dinheiro, e se descobriu a tua nudez nas tuas prostituições com os teus amantes, como também com todos os ídolos das tuas abominações, e do sangue de teus filhos que lhes deste;',
        'Portanto, eis que ajuntarei a todos os teus amantes, com os quais te deleitaste, como também a todos os que amaste, com todos os que odiaste, e ajuntá-los-ei contra ti em redor, e descobrirei a tua nudez diante deles, para que vejam toda a tua nudez.',
        'E julgar-te-ei como são julgadas as adúlteras e as que derramam sangue; e entregar-te-ei ao sangue de furor e de ciúme.',
        'E entregar-te-ei nas mãos deles; e eles derrubarão a tua abóbada, e transtornarão os teus altos lugares, e te despirão os teus vestidos, e tomarão as tuas jóias de enfeite, e te deixarão nua e descoberta.',
        'Então farão subir contra ti uma multidão, e te apedrejarão, e te traspassarão com as suas espadas.',
        'E queimarão as tuas casas a fogo, e executarão juízos contra ti aos olhos de muitas mulheres; e te farei cessar de ser meretriz, e paga não darás mais.',
        'Assim satisfarei em ti o meu furor, e os meus ciúmes se desviarão de ti, e me aquietarei, e nunca mais me indignarei.',
        'Porquanto não te lembraste dos dias da tua mocidade, e me provocaste à ira com tudo isto, eis que também eu farei recair o teu caminho sobre a tua cabeça, diz o Senhor DEUS, e não mais farás tal perversidade sobre todas as tuas abominações.',
        'Eis que todo o que usa de provérbios usará contra ti este provérbio, dizendo: Tal mãe, tal filha.',
        'Tu és filha de tua mãe, que tinha nojo de seu marido e de seus filhos; e tu és irmã de tuas irmãs, que tinham nojo de seus maridos e de seus filhos; vossa mãe foi hetéia, e vosso pai amorreu.',
        'E tua irmã, a maior, é Samaria, ela e suas filhas, a qual habita à tua esquerda; e a tua irmã menor, que habita à tua mão direita, é Sodoma e suas filhas.',
        'Todavia não andaste nos seus caminhos, nem fizeste conforme as suas abominações; mas como se isto fora mui pouco, ainda te corrompeste mais do que elas, em todos os teus caminhos.',
        'Vivo eu, diz o Senhor DEUS, que não fez Sodoma, tua irmã, nem ela, nem suas filhas, como fizeste tu e tuas filhas.',
        'Eis que esta foi a iniqüidade de Sodoma, tua irmã: Soberba, fartura de pão, e abundância de ociosidade teve ela e suas filhas; mas nunca fortaleceu a mão do pobre e do necessitado.',
        'E se ensoberbeceram, e fizeram abominações diante de mim; portanto, vendo eu isto as tirei dali.',
        'Também Samaria não cometeu a metade de teus pecados; e multiplicaste as tuas abominações mais do que elas, e justificaste a tuas irmãs, com todas as tuas abominações que fizeste.',
        'Tu, também, que julgaste a tuas irmãs, leva a tua vergonha pelos pecados, que cometeste, mais abomináveis do que elas; mais justas são do que tu; envergonha-te logo também, e leva a tua vergonha, pois justificaste a tuas irmãs.',
        'Eu, pois, farei voltar os cativos delas; os cativos de Sodoma e suas filhas, e os cativos de Samaria e suas filhas, e os cativos do teu cativeiro dentre elas;',
        'Para que leves a tua vergonha, e sejas envergonhada por tudo o que fizeste, dando-lhes tu consolação.',
        'Quando tuas irmãs, Sodoma e suas filhas, tornarem ao seu primeiro estado, e também Samaria e suas filhas tornarem ao seu primeiro estado, também tu e tuas filhas tornareis ao vosso primeiro estado.',
        'Nem mesmo Sodoma, tua irmã, foi mencionada pela tua boca, no dia da tua soberba,',
        'Antes que se descobrisse a tua maldade, como no tempo do desprezo das filhas da Síria, e de todos os que estavam ao redor dela, as filhas dos filisteus, que te desprezavam em redor.',
        'A tua perversidade e as tuas abominações tu levarás, diz o Senhor.',
        'Porque assim diz o Senhor DEUS: Eu te farei como fizeste, que desprezaste o juramento, quebrando a aliança.',
        'Contudo eu me lembrarei da minha aliança, que fiz contigo nos dias da tua mocidade; e estabelecerei contigo uma aliança eterna.',
        'Então te lembrarás dos teus caminhos, e te confundirás, quando receberes tuas irmãs maiores do que tu, com as menores do que tu, porque tas darei por filhas, mas não pela tua aliança.',
        'Porque eu estabelecerei a minha aliança contigo, e saberás que eu sou o Senhor;',
        'Para que te lembres disso, e te envergonhes, e nunca mais abras a tua boca, por causa da tua vergonha, quando eu te expiar de tudo quanto fizeste, diz o Senhor DEUS.',
      ],
      [
        'E veio a mim a palavra do SENHOR, dizendo:',
        'Filho do homem, propõe um enigma, e profere uma parábola para com a casa de Israel.',
        'E disse: Assim diz o Senhor DEUS: Uma grande águia, de grandes asas, de plumagem comprida, e cheia de penas de várias cores, veio ao Líbano e levou o mais alto ramo de um cedro.',
        'E arrancou a ponta mais alta dos seus renovos, e a levou a uma terra de mercancia; numa cidade de mercadores a pôs.',
        'Tomou da semente da terra, e a lançou num solo frutífero; tomando-a, colocou-a junto às muitas águas, plantando-a como salgueiro.',
        'E brotou, e tornou-se numa videira muito larga, de pouca altura, virando-se para ela os seus ramos, porque as suas raízes estavam debaixo dela; e tornou-se numa videira, e produzia sarmentos, e brotava renovos.',
        'E houve mais uma grande águia, de grandes asas, e cheia de penas; e eis que esta videira lançou para ela as suas raízes, e estendeu para ela os seus ramos, desde as covas do seu plantio, para que a regasse.',
        'Num bom campo, junto a muitas águas, estava ela plantada, para produzir ramos, e para dar fruto, a fim de que fosse videira excelente.',
        'Dize: Assim diz o Senhor DEUS: Porventura há de prosperar? Não lhe arrancará as suas raízes, e não cortará o seu fruto, para que se seque? Para que sequem todas as folhas de seus renovos, e isto não com grande força, nem muita gente, para arrancá-la pelas suas raízes.',
        'Mas, estando plantada, prosperará? Porventura, tocando-lhe vento oriental, de todo não se secará? Nas covas do seu plantio se secará.',
        'Então veio a mim a palavra do Senhor, dizendo:',
        'Dize agora à casa rebelde: Não sabeis o que significam estas coisas? Dize: Eis que veio o rei de babilônia a Jerusalém, e tomou o seu rei e os seus príncipes, e os levou consigo para babilônia.',
        'E tomou um da descendência real, e fez aliança com ele, e o fez prestar juramento; e tomou consigo os poderosos da terra,',
        'Para que o reino ficasse humilhado, e não se levantasse, embora, guardando a sua aliança, pudesse subsistir.',
        'Mas rebelou-se contra ele, enviando os seus mensageiros ao Egito, para que se lhe mandassem cavalos e muita gente. Porventura prosperará ou escapará aquele que faz tais coisas, ou quebrará a aliança, e ainda escapará?',
        'Vivo eu, diz o Senhor DEUS, que no lugar em que habita o rei que o fez reinar, cujo juramento desprezou, e cuja aliança quebrou, sim, com ele no meio de babilônia certamente morrerá.',
        'E Faraó, nem com grande exército, nem com uma companhia numerosa, fará coisa alguma com ele em guerra, levantando trincheiras e edificando baluartes, para destruir muitas vidas.',
        'Porque desprezou o juramento, quebrando a aliança; eis que ele tinha dado a sua mão; contudo fez todas estas coisas; não escapará.',
        'Portanto, assim diz o Senhor DEUS: Vivo eu, que o meu juramento, que desprezou, e a minha aliança, que quebrou, isto farei recair sobre a sua cabeça.',
        'E estenderei sobre ele a minha rede, e ficará preso no meu laço; e o levarei a babilônia, e ali entrarei em juízo com ele por causa da rebeldia que praticou contra mim.',
        'E todos os seus fugitivos, com todas as suas tropas, cairão à espada, e os que restarem serão espalhados a todo o vento; e sabereis que eu, o Senhor, o disse.',
        'Assim diz o Senhor DEUS: Também eu tomarei um broto do topo do cedro, e o plantarei; do principal dos seus renovos cortarei o mais tenro, e o plantarei sobre um monte alto e sublime.',
        'No monte alto de Israel o plantarei, e produzirá ramos, e dará fruto, e se fará um cedro excelente; e habitarão debaixo dele aves de toda plumagem, à sombra dos seus ramos habitarão.',
        'Assim saberão todas as árvores do campo que eu, o Senhor, abati a árvore alta, elevei a árvore baixa, sequei a árvore verde, e fiz reverdecer a árvore seca; eu, o Senhor, o disse, e o fiz.',
      ],
      [
        'E veio a mim a palavra do SENHOR, dizendo:',
        'Que pensais, vós, os que usais esta parábola sobre a terra de Israel, dizendo: Os pais comeram uvas verdes, e os dentes dos filhos se embotaram?',
        'Vivo eu, diz o Senhor DEUS, que nunca mais direis esta parábola em Israel.',
        'Eis que todas as almas são minhas; como o é a alma do pai, assim também a alma do filho é minha: a alma que pecar, essa morrerá.',
        'Sendo, pois, o homem justo, e praticando juízo e justiça,',
        'Não comendo sobre os montes, nem levantando os seus olhos para os ídolos da casa de Israel, nem contaminando a mulher do seu próximo, nem se chegando à mulher na sua separação,',
        'Não oprimindo a ninguém, tornando ao devedor o seu penhor, não roubando, dando o seu pão ao faminto, e cobrindo ao nu com roupa,',
        'Não dando o seu dinheiro à usura, e não recebendo demais, desviando a sua mão da injustiça, e fazendo verdadeiro juízo entre homem e homem;',
        'Andando nos meus estatutos, e guardando os meus juízos, e procedendo segundo a verdade, o tal justo certamente viverá, diz o Senhor DEUS.',
        'E se ele gerar um filho ladrão, derramador de sangue, que fizer a seu irmão qualquer destas coisas;',
        'E não cumprir todos aqueles deveres, mas antes comer sobre os montes, e contaminar a mulher de seu próximo,',
        'Oprimir ao pobre e necessitado, praticar roubos, não tornar o penhor, e levantar os seus olhos para os ídolos, e cometer abominação,',
        'E emprestar com usura, e receber demais, porventura viverá? Não viverá. Todas estas abominações ele fez, certamente morrerá; o seu sangue será sobre ele.',
        'E eis que também, se ele gerar um filho que veja todos os pecados que seu pai fez e, vendo-os, não cometer coisas semelhantes,',
        'Não comer sobre os montes, e não levantar os seus olhos para os ídolos da casa de Israel, e não contaminar a mulher de seu próximo,',
        'E não oprimir a ninguém, e não retiver o penhor, e não roubar, der o seu pão ao faminto, e cobrir ao nu com roupa,',
        'Desviar do pobre a sua mão, não receber usura e juros, cumprir os meus juízos, e andar nos meus estatutos, o tal não morrerá pela iniqüidade de seu pai; certamente viverá.',
        'Seu pai, porque praticou a extorsão, roubou os bens do irmão, e fez o que não era bom no meio de seu povo, eis que ele morrerá pela sua iniqüidade.',
        'Mas dizeis: Por que não levará o filho a iniqüidade do pai? Porque o filho procedeu com retidão e justiça, e guardou todos os meus estatutos, e os praticou, por isso certamente viverá.',
        'A alma que pecar, essa morrerá; o filho não levará a iniqüidade do pai, nem o pai levará a iniqüidade do filho. A justiça do justo ficará sobre ele e a impiedade do ímpio cairá sobre ele.',
        'Mas se o ímpio se converter de todos os pecados que cometeu, e guardar todos os meus estatutos, e proceder com retidão e justiça, certamente viverá; não morrerá.',
        'De todas as transgressões que cometeu não haverá lembrança contra ele; pela justiça que praticou viverá.',
        'Desejaria eu, de qualquer maneira, a morte do ímpio? diz o Senhor DEUS; Não desejo antes que se converta dos seus caminhos, e viva?',
        'Mas, desviando-se o justo da sua justiça, e cometendo a iniqüidade, fazendo conforme todas as abominações que faz o ímpio, porventura viverá? De todas as justiças que tiver feito não se fará memória; na sua transgressão com que transgrediu, e no seu pecado com que pecou, neles morrerá.',
        'Dizeis, porém: O caminho do Senhor não é direito. Ouvi agora, ó casa de Israel: Porventura não é o meu caminho direito? Não são os vossos caminhos tortuosos?',
        'Desviando-se o justo da sua justiça, e cometendo iniqüidade, morrerá por ela; na iniqüidade, que cometeu, morrerá.',
        'Mas, convertendo-se o ímpio da impiedade que cometeu, e procedendo com retidão e justiça, conservará este a sua alma em vida.',
        'Pois que reconsidera, e se converte de todas as suas transgressões que cometeu; certamente viverá, não morrerá.',
        'Contudo, diz a casa de Israel: O caminho do Senhor não é direito. Porventura não são direitos os meus caminhos, ó casa de Israel? E não são tortuosos os vossos caminhos?',
        'Portanto, eu vos julgarei, cada um conforme os seus caminhos, ó casa de Israel, diz o Senhor DEUS. Tornai-vos, e convertei-vos de todas as vossas transgressões, e a iniqüidade não vos servirá de tropeço.',
        'Lançai de vós todas as vossas transgressões com que transgredistes, e fazei-vos um coração novo e um espírito novo; pois, por que razão morreríeis, ó casa de Israel?',
        'Porque não tenho prazer na morte do que morre, diz o Senhor DEUS; convertei-vos, pois, e vivei.',
      ],
      [
        'E tu levanta uma lamentação sobre os príncipes de Israel,',
        'E dize: Quem foi tua mãe? Uma leoa entre os leões a qual, deitada no meio dos leõezinhos, criou os seus filhotes.',
        'E educou um dos seus filhotes, o qual veio a ser leãozinho e aprendeu a apanhar a presa, e devorou homens,',
        'E, ouvindo falar dele as nações, foi apanhado na cova delas, e o trouxeram com cadeias à terra do Egito.',
        'Vendo, pois, ela que havia esperado muito, e que a sua expectação era perdida, tomou outro dos seus filhotes, e fez dele um leãozinho.',
        'Este, pois, andando continuamente no meio dos leões, veio a ser leãozinho, e aprendeu a apanhar a presa, e devorou homens.',
        'E conheceu os seus palácios, e destruiu as suas cidades; e assolou-se a terra, e a sua plenitude, ao som do seu rugido.',
        'Então se ajuntaram contra ele os povos das províncias ao redor, e estenderam sobre ele a rede, e foi apanhado na cova deles.',
        'E com cadeias colocaram-no em uma jaula, e o levaram ao rei de babilônia; fizeram-no entrar nos lugares fortes, para que não se ouvisse mais a sua voz nos montes de Israel.',
        'Tua mãe era como uma videira no teu sangue, plantada junto às águas; ela frutificou, e encheu-se de ramos, por causa das muitas águas.',
        'E tinha varas fortes para cetros de dominadores, e elevou-se a sua estatura entre os espessos ramos, e foi vista na sua altura com a multidão dos seus ramos.',
        'Mas foi arrancada com furor, foi lançada por terra, e o vento oriental secou o seu fruto; quebraram-se e secaram-se as suas fortes varas, o fogo as consumiu,',
        'E agora está plantada no deserto, numa terra seca e sedenta.',
        'E de uma vara dos seus ramos saiu fogo que consumiu o seu fruto de maneira que nela não há mais vara forte, cetro para dominar. Esta é a lamentação, e servirá de lamentação.',
      ],
      [
        'E aconteceu, no sétimo ano, no quinto mês, aos dez do mês, que vieram alguns dos anciãos de Israel, para consultarem o SENHOR; e assentaram-se diante de mim.',
        'Então veio a mim a palavra do Senhor, dizendo:',
        'Filho do homem, fala aos anciãos de Israel, e dize-lhes: Assim diz o Senhor DEUS: Viestes consultar-me? Vivo eu, que não me deixarei ser consultado por vós, diz o Senhor DEUS.',
        'Porventura tu os julgarias, julgarias tu, ó filho do homem? Notifica-lhes as abominações de seus pais;',
        'E dize-lhes: Assim diz o Senhor DEUS: No dia em que escolhi a Israel, levantei a minha mão para a descendência da casa de Jacó, e me dei a conhecer a eles na terra do Egito, e levantei a minha mão para eles, dizendo: Eu sou o SENHOR vosso Deus;',
        'Naquele dia levantei a minha mão para eles, para os tirar da terra do Egito, para uma terra que já tinha previsto para eles, a qual mana leite e mel, e é a glória de todas as terras.',
        'Então lhes disse: Cada um lance de si as abominações dos seus olhos, e não vos contamineis com os ídolos do Egito; eu sou o Senhor vosso Deus.',
        'Mas rebelaram-se contra mim, e não me quiseram ouvir; ninguém lançava de si as abominações dos seus olhos, nem deixava os ídolos do Egito; então eu disse que derramaria sobre eles o meu furor, para cumprir a minha ira contra eles no meio da terra do Egito.',
        'O que fiz, porém, foi por amor do meu nome, para que não fosse profanado diante dos olhos dos gentios, no meio dos quais estavam, a cujos olhos eu me dei a conhecer a eles, para os tirar da terra do Egito.',
        'E os tirei da terra do Egito, e os levei ao deserto.',
        'E dei-lhes os meus estatutos e lhes mostrei os meus juízos, os quais, cumprindo-os o homem, viverá por eles.',
        'E também lhes dei os meus sábados, para que servissem de sinal entre mim e eles; para que soubessem que eu sou o Senhor que os santifica.',
        'Mas a casa de Israel se rebelou contra mim no deserto, não andando nos meus estatutos, e rejeitando os meus juízos, os quais, cumprindo-os, o homem viverá por eles; e profanaram grandemente os meus sábados; e eu disse que derramaria sobre eles o meu furor no deserto, para os consumir.',
        'O que fiz, porém, foi por amor do meu nome, para que não fosse profanado diante dos olhos dos gentios perante a vista dos quais os fiz sair.',
        'E, contudo, eu levantei a minha mão para eles no deserto, para não os deixar entrar na terra que lhes tinha dado, a qual mana leite e mel, e é a glória de todas as terras;',
        'Porque rejeitaram os meus juízos, e não andaram nos meus estatutos, e profanaram os meus sábados; porque o seu coração andava após os seus ídolos.',
        'Não obstante o meu olho lhes perdoou, e eu não os destruí nem os consumi no deserto.',
        'Mas disse eu a seus filhos no deserto: Não andeis nos estatutos de vossos pais, nem guardeis os seus juízos, nem vos contamineis com os seus ídolos.',
        'Eu sou o Senhor vosso Deus; andai nos meus estatutos, e guardai os meus juízos, e executai-os.',
        'E santificai os meus sábados, e servirão de sinal entre mim e vós, para que saibais que eu sou o Senhor vosso Deus.',
        'Mas também os filhos se rebelaram contra mim, e não andaram nos meus estatutos, nem guardaram os meus juízos para os fazer, os quais, cumprindo-os, o homem viverá por eles; eles profanaram os meus sábados; por isso eu disse que derramaria sobre eles o meu furor, para cumprir contra eles a minha ira no deserto.',
        'Mas contive a minha mão, e o fiz por amor do meu nome, para que não fosse profanado perante os olhos dos gentios, à vista dos quais os fiz sair.',
        'Também levantei a minha mão para eles no deserto, para os espalhar entre os gentios, e os derramar pelas terras,',
        'Porque não executaram os meus juízos, e rejeitaram os meus estatutos, e profanaram os meus sábados, e os seus olhos iam após os ídolos de seus pais.',
        'Por isso também lhes dei estatutos que não eram bons, juízos pelos quais não haviam de viver;',
        'E os contaminei em seus próprios dons, nos quais faziam passar pelo fogo tudo o que abre a madre; para assolá-los para que soubessem que eu sou o Senhor.',
        'Portanto fala à casa de Israel, ó filho do homem, e dize-lhe: Assim diz o Senhor DEUS: Ainda até nisto me blasfemaram vossos pais, e que procederam traiçoeiramente contra mim.',
        'Porque, havendo-os eu introduzido na terra sobre a qual eu levantara a minha mão, para lha dar, então olharam para todo o outeiro alto, e para toda a árvore frondosa, e ofereceram ali os seus sacrifícios e apresentaram ali a provocação das suas ofertas; puseram ali os seus cheiros suaves, e ali derramaram as suas libações.',
        'E eu lhes disse: Que alto é este, aonde vós ides? E seu nome tem sido Bamá até o dia de hoje.',
        'Portanto dize à casa de Israel: Assim diz o Senhor DEUS: Contaminai-vos a vós mesmos a maneira de vossos pais? E vos prostituístes com as suas abominações?',
        'E, quando ofereceis os vossos dons, e fazeis passar os vossos filhos pelo fogo, não é certo que estais contaminados com todos os vossos ídolos, até este dia? E vós me consultaríeis, ó casa de Israel? Vivo eu, diz o Senhor DEUS, que vós não me consultareis.',
        'E o que veio à vossa mente de modo algum sucederá, quando dizeis: Seremos como os gentios, como as outras famílias da terra, servindo ao madeiro e à pedra.',
        'Vivo eu, diz o Senhor DEUS, que com mão forte, e com braço estendido, e com indignação derramada, hei de reinar sobre vós.',
        'E vos tirarei dentre os povos, e vos congregarei das terras nas quais andais espalhados, com mão forte, e com braço estendido, e com indignação derramada.',
        'E vos levarei ao deserto dos povos; e ali face a face entrarei em juízo convosco;',
        'Como entrei em juízo com vossos pais, no deserto da terra do Egito, assim entrarei em juízo convosco, diz o Senhor DEUS.',
        'Também vos farei passar debaixo da vara, e vos farei entrar no vínculo da aliança.',
        'E separarei dentre vós os rebeldes, e os que transgrediram contra mim; da terra das suas peregrinações os tirarei, mas à terra de Israel não voltarão; e sabereis que eu sou o Senhor.',
        'Quanto a vós, ó casa de Israel, assim diz o Senhor DEUS; Ide, sirva cada um os seus ídolos, pois que a mim não me quereis ouvir; mas não profaneis mais o meu santo nome com as vossas dádivas e com os vossos ídolos.',
        'Porque no meu santo monte, no monte alto de Israel, diz o Senhor DEUS, ali me servirá toda a casa de Israel, toda ela naquela terra; ali me deleitarei neles, e ali requererei as vossas ofertas alçadas, e as primícias das vossas oblações, com todas as vossas coisas santas;',
        'Com cheiro suave me deleitarei em vós, quando eu vos tirar dentre os povos e vos congregar das terras em que andais espalhados; e serei santificado em vós perante os olhos dos gentios.',
        'E sabereis que eu sou o Senhor, quando eu vos introduzir na terra de Israel, terra pela qual levantei a minha mão para dá-la a vossos pais.',
        'E ali vos lembrareis de vossos caminhos, e de todos os vossos atos com que vos contaminastes, e tereis nojo de vós mesmos, por causa de todas as vossas maldades que tendes cometido.',
        'E sabereis que eu sou o SENHOR, quando eu proceder para convosco por amor do meu nome; não conforme os vossos maus caminhos, nem conforme os vossos atos corruptos, ó casa de Israel, disse o Senhor DEUS.',
        'E veio a mim a palavra do Senhor, dizendo:',
        'Filho do homem, dirige o teu rosto para o caminho do sul, e derrama as tuas palavras contra o sul, e profetiza contra o bosque do campo do sul.',
        'E dize ao bosque do sul: Ouve a palavra do SENHOR: Assim diz o Senhor DEUS: Eis que acenderei em ti um fogo que em ti consumirá toda a árvore verde e toda a árvore seca; não se apagará a chama flamejante, antes com ela se queimarão todos os rostos, desde o sul até ao norte.',
        'E verá toda a carne que eu, o Senhor, o acendi; não se apagará.',
        'Então disse eu: Ah! Senhor DEUS! Eles dizem de mim: Não é este um proferidor de parábolas?',
      ],
      [
        'E veio a mim a palavra do SENHOR, dizendo:',
        'Filho do homem, dirige o teu rosto contra Jerusalém, e derrama as tuas palavras sobre os santuários, e profetiza sobre a terra de Israel.',
        'E dize à terra de Israel: Assim diz o Senhor: Eis que sou contra ti, e tirarei a minha espada da bainha, e exterminarei do meio de ti o justo e o ímpio.',
        'E, por isso que hei de exterminar do meio de ti o justo e o ímpio, a minha espada sairá da sua bainha contra toda a carne, desde o sul até o norte.',
        'E saberá toda a carne que eu, o Senhor, tirei a minha espada da bainha; nunca mais voltará a ela.',
        'Tu, porém, ó filho do homem, suspira; suspira aos olhos deles, com quebrantamento dos teus lombos e com amargura.',
        'E será que, quando eles te disserem: Por que suspiras tu? Dirás: Por causa das novas, porque vêm; e todo o coração desmaiará, e todas as mãos se enfraquecerão, e todo o espírito se angustiará, e todos os joelhos se desfarão em águas; eis que vêm, e se cumprirão, diz o Senhor DEUS.',
        'E veio a mim a palavra do Senhor, dizendo:',
        'Filho do homem, profetiza, e dize: Assim diz o Senhor: dize: A espada, a espada está afiada e polida.',
        'Para grande matança está afiada, para reluzir está polida. Alegrar-nos-emos pois? A vara de meu filho é que despreza todo o madeiro.',
        'E foi dada a polir, para ser manejada; esta espada está afiada, e está polida, para ser posta na mão do matador.',
        'Grita e geme, ó filho do homem, porque ela será contra o meu povo, contra todos os príncipes de Israel. Estes, juntamente com o meu povo, estão espantados com a espada; bate, pois, na tua coxa.',
        'Pois se faz uma prova; e que seria se a espada desprezasse mesmo a vara? Ela não seria mais, diz o Senhor DEUS.',
        'Tu, pois, ó filho do homem, profetiza e bate com as mãos uma na outra; e dobre-se a espada até a terceira vez, a espada dos mortos; ela é a espada para a grande matança, que os traspassará até o seu interior.',
        'Para que desmaie o coração, e se multipliquem as destruições, contra todas as suas portas, pus a ponta da espada, a que foi feita para reluzir, e está preparada para a matança!',
        'Ó espada, une-te, vira-te para a direita; prepara-te, vira-te para a esquerda, para onde quer que o teu rosto se dirigir.',
        'E também eu baterei com as minhas mãos uma na outra, e farei descansar a minha indignação; eu, o Senhor, o disse.',
        'E veio a mim a palavra do Senhor, dizendo:',
        'Tu, pois, ó filho do homem, propõe dois caminhos, por onde venha a espada do rei de babilônia. Ambos procederão de uma mesma terra, e escolhe um lugar; escolhe-o no cimo do caminho da cidade.',
        'Um caminho proporás, por onde virá a espada contra Rabá dos filhos de Amom, e contra Judá, em Jerusalém, a fortificada.',
        'Porque o rei de babilônia parará na encruzilhada, no cimo dos dois caminhos, para fazer adivinhações; aguçará as suas flechas, consultará as imagens, atentará para o fígado.',
        '« sua direita estará a adivinhação sobre Jerusalém, para ordenar aos capitàes, para abrirem a boca, ordenando a matança, para levantarem a voz com júbilo, para pôrem os aríetes contra as portas, para levantarem trincheiras, para edificarem baluartes.',
        'Isto será como adivinhação vã, aos olhos daqueles que lhes fizeram juramentos; mas ele se lembrará da iniqüidade, para que sejam apanhados.',
        'Portanto assim diz o Senhor DEUS: Visto que me fazeis lembrar da vossa iniqüidade, descobrindo-se as vossas transgressões, aparecendo os vossos pecados em todos os vossos atos; visto que viestes em memória, sereis apanhados com a mão.',
        'E tu, ó profano e ímpio príncipe de Israel, cujo dia virá no tempo da extrema iniqüidade,',
        'Assim diz o Senhor DEUS: Tira o diadema, e remove a coroa; esta não será a mesma; exalta ao humilde, e humilha ao soberbo.',
        'Ao revés, ao revés, ao revés porei aquela coroa, e ela não mais será, até que venha aquele a quem pertence de direito; a ele a darei.',
        'E tu, ó filho do homem, profetiza, e dize: Assim diz o Senhor DEUS acerca dos filhos de Amom, e acerca do seu opróbrio; dize pois: A espada, a espada está desembainhada, polida para a matança, para consumir, por estar reluzente;',
        'Entretanto te profetizam vaidade, te adivinham mentira, para te porem no pescoço dos ímpios, daqueles que estão mortos, cujo dia veio no tempo da iniqüidade final.',
        'Torne a tua espada à sua bainha. No lugar em que foste criado, na terra do teu nascimento, eu te julgarei.',
        'E derramarei sobre ti a minha indignação, assoprarei contra ti o fogo do meu furor, entregar-te-ei nas mãos dos homens brutais, inventores de destruição.',
        'Ao fogo servirás para ser consumido; o teu sangue estará no meio da terra; já não serás mais lembrado, porque eu, o Senhor, o disse.',
      ],
      [
        'E veio a mim a palavra do SENHOR, dizendo:',
        'Tu, pois, ó filho do homem, porventura julgarás, julgarás a cidade sanguinária? Faze-lhe conhecer, pois, todas as suas abominações.',
        'E dize: Assim diz o Senhor DEUS: Ai da cidade que derrama o sangue no meio de si para que venha o seu tempo! Que faz ídolos contra si mesma, para se contaminar!',
        'Pelo teu sangue que derramaste te fizeste culpada, e pelos teus ídolos que fabricaste te contaminaste, e fizeste aproximarem-se os teus dias, e tem chegado o fim dos teus anos; por isso eu te fiz o opróbrio das nações e o escárnio de todas as terras.',
        'As que estão perto de ti e as que estão longe escarnecerão de ti, infamada, cheia de inquietação.',
        'Eis que os príncipes de Israel, cada um conforme o seu poder, estavam em ti para derramarem sangue.',
        'Ao pai e à mãe desprezaram em ti; para com o estrangeiro usaram de opressão no meio de ti; ao órfão e à viúva oprimiram em ti.',
        'As minhas coisas santas desprezaste, e os meus sábados profanaste.',
        'Homens caluniadores se acharam em ti, para derramarem sangue; e em ti sobre os montes comeram; perversidade cometeram no meio de ti.',
        'A vergonha do pai descobriram em ti; a que estava imunda, na sua separação, humilharam no meio de ti.',
        'Um cometeu abominação com a mulher do seu próximo, outro contaminou abominavelmente a sua nora, e outro humilhou no meio de ti a sua irmã, filha de seu pai.',
        'Presentes receberam no meio de ti para derramarem sangue; usura e juros ilícitos tomaste, e usaste de avareza com o teu próximo, oprimindo-o; mas de mim te esqueceste, diz o Senhor DEUS.',
        'E eis que bati as mãos contra a avareza que cometeste, e por causa do sangue que houve no meio de ti.',
        'Porventura estará firme o teu coração? Porventura estarão fortes as tuas mãos, nos dias em que eu tratarei contigo? Eu, o Senhor, o disse, e o farei.',
        'E espalhar-te-ei entre as nações, e dispersar-te-ei pelas terras, e porei termo à tua imundícia.',
        'E tu serás profanada em ti mesma aos olhos dos gentios, e saberás que eu sou o Senhor.',
        'E veio a mim a palavra do Senhor, dizendo:',
        'Filho do homem, a casa de Israel se tornou para mim em escórias; todos eles são bronze, e estanho, e ferro, e chumbo no meio do forno; em escórias de prata se tornaram.',
        'Portanto assim diz o Senhor DEUS: Pois que todos vós vos tornastes em escórias, por isso eis que eu vos ajuntarei no meio de Jerusalém.',
        'Como se ajuntam a prata, e o bronze, e o ferro, e o chumbo, e o estanho, no meio do forno, para assoprar o fogo sobre eles, a fim de se fundirem, assim vos ajuntarei na minha ira e no meu furor, e ali vos deixarei e fundirei.',
        'E congregar-vos-ei, e assoprarei sobre vós o fogo do meu furor; e sereis fundidos no meio dela.',
        'Como se funde a prata no meio do forno, assim sereis fundidos no meio dela; e sabereis que eu, o Senhor, derramei o meu furor sobre vós.',
        'E veio a mim a palavra do Senhor, dizendo:',
        'Filho do homem, dize-lhe: Tu és uma terra que não está purificada; e que não tem chuva no dia da indignação.',
        'Conspiração dos seus profetas há no meio dela, como um leão que ruge, que arrebata a presa; eles devoram as almas; tomam tesouros e coisas preciosas, multiplicam as suas viúvas no meio dela.',
        'Os seus sacerdotes violentam a minha lei, e profanam as minhas coisas santas; não fazem diferença entre o santo e o profano, nem discernem o impuro do puro; e de meus sábados escondem os seus olhos, e assim sou profanado no meio deles.',
        'Os seus príncipes no meio dela são como lobos que arrebatam a presa, para derramarem sangue, para destruírem as almas, para seguirem a avareza.',
        'E os seus profetas têm feito para eles cobertura com argamassa não temperada, profetizando vaidade, adivinhando-lhes mentira, dizendo: Assim diz o Senhor DEUS; sem que o SENHOR tivesse falado.',
        'Ao povo da terra oprimem gravemente, e andam roubando, e fazendo violência ao pobre e necessitado, e ao estrangeiro oprimem sem razão.',
        'E busquei dentre eles um homem que estivesse tapando o muro, e estivesse na brecha perante mim por esta terra, para que eu não a destruísse; porém a ninguém achei.',
        'Por isso eu derramei sobre eles a minha indignação; com o fogo do meu furor os consumi; fiz que o seu caminho recaísse sobre a sua cabeça, diz o Senhor DEUS.',
      ],
      [
        'Veio mais a mim a palavra do SENHOR, dizendo:',
        'Filho do homem, houve duas mulheres, filhas de uma mesma mãe.',
        'Estas se prostituíram no Egito; prostituíram-se na sua mocidade; ali foram apertados os seus seios, e ali foram apalpados os seios da sua virgindade.',
        'E os seus nomes eram: Aolá, a mais velha, e Aolibá, sua irmã; e foram minhas, e tiveram filhos e filhas; e, quanto aos seus nomes, Samaria é Aolá, e Jerusalém é Aolibá.',
        'E prostituiu-se Aolá, sendo minha; e enamorou-se dos seus amantes, dos assírios, seus vizinhos,',
        'Vestidos de azul, capitàes e magistrados, todos jovens cobiçáveis, cavaleiros montados a cavalo.',
        'Assim cometeu ela as suas devassidões com eles, que eram todos a flor dos filhos da Assíria, e com todos os de quem se enamorava; com todos os seus ídolos se contaminou.',
        'E as suas prostituições, que trouxe do Egito, não as deixou; porque com ela se deitaram na sua mocidade, e eles apalparam os seios da sua virgindade, e derramaram sobre ela a sua impudicícia.',
        'Portanto a entreguei na mão dos seus amantes, na mão dos filhos da Assíria, de quem se enamorara.',
        'Estes descobriram a sua vergonha, levaram seus filhos e suas filhas, mas a ela mataram à espada; e tornou-se falada entre as mulheres, e sobre ela executaram os juízos.',
        'Vendo isto sua irmã Aolibá, corrompeu o seu imoderado amor mais do que ela, e as suas devassidões foram mais do que as de sua irmã.',
        'Enamorou-se dos filhos da Assíria, dos capitàes e dos magistrados seus vizinhos, vestidos com primor, cavaleiros que andam montados em cavalos, todos jovens cobiçáveis.',
        'E vi que se tinha contaminado; o caminho de ambas era o mesmo.',
        'E aumentou as suas impudicícias, porque viu homens pintados na parede, imagens dos caldeus, pintadas de vermelho;',
        'Cingidos de cinto nos seus lombos, e tiaras largas e tingidas nas suas cabeças, todos com parecer de príncipes, semelhantes aos filhos de babilônia em Caldéia, terra do seu nascimento.',
        'E enamorou-se deles, ao lançar sobre eles os seus olhos; e lhes mandou mensageiros à Caldéia.',
        'Então vieram a ela os filhos de babilônia para o leito dos amores, e a contaminaram com as suas impudicícias; e ela se contaminou com eles; então a sua alma apartou-se deles.',
        'Assim pôs a descoberto as suas devassidões, e descobriu a sua vergonha; então a minha alma se apartou dela, como já tinha se apartado a minha alma de sua irmã.',
        'Todavia ela multiplicou as suas prostituições, lembrando-se dos dias da sua mocidade, em que se prostituíra na terra do Egito.',
        'E enamorou-se dos seus amantes, cuja carne é como a de jumentos, e cujo fluxo é como o de cavalos.',
        'Assim trouxeste à memória a perversidade da tua mocidade, quando os do Egito apalpavam os teus seios, por causa dos peitos da tua mocidade.',
        'Por isso, ó Aolibá, assim diz o Senhor DEUS: Eis que eu suscitarei contra ti os teus amantes, dos quais se tinha apartado a tua alma, e os trarei contra ti de toda a parte em derredor.',
        'Os filhos de Babilônia, e todos os caldeus de Pecode, e de Soa, e de Coa, e todos os filhos da Assíria com eles, jovens cobiçáveis, capitàes e magistrados todos eles, grandes e afamados senhores, todos eles montados a cavalo.',
        'E virão contra ti com carros, carretas e rodas, e com multidão de povos; e se colocarão contra ti em redor com paveses, e escudos e capacetes; e porei diante deles o juízo, e julgar-te-ão segundo os seus juízos.',
        'E porei contra ti o meu zelo, e usarão de indignação contigo. Tirar-te-ão o nariz e as orelhas, e o que restar cairá à espada. Eles tomarão teus filhos e tuas filhas, e o que ficar por último em ti será consumido pelo fogo.',
        'Também te despirão as tuas vestes, e te tomarão as tuas belas jóias.',
        'Assim farei cessar em ti a tua perversidade e a tua prostituição trazida da terra do Egito; e não levantarás os teus olhos para eles, nem te lembrarás nunca mais do Egito.',
        'Porque assim diz o Senhor DEUS: Eis que eu te entregarei na mão dos que odeias, na mão daqueles de quem tem se apartado a tua alma.',
        'E eles te tratarão com ódio, e levarão todo o fruto do teu trabalho, e te deixarão nua e despida; e descobrir-se-á a vergonha da tua prostituição, e a tua perversidade, e as tuas devassidões.',
        'Estas coisas se te farão, porque te prostituíste após os gentios, e te contaminaste com os seus ídolos.',
        'No caminho de tua irmã andaste; por isso entregarei o seu cálice na tua mão.',
        'Assim diz o Senhor DEUS: Beberás o cálice de tua irmã, fundo e largo; servirás de riso e escárnio; pois nele cabe muito.',
        'De embriaguez e de dor te encherás; o cálice de tua irmã Samaria é cálice de espanto e de assolação.',
        'Bebê-lo-ás, pois, e esgotá-lo-ás, e os seus cacos roerás, e os teus seios arrancarás; porque eu o falei, diz o Senhor DEUS.',
        'Portanto, assim diz o Senhor DEUS: Como te esqueceste de mim, e me lançaste para trás das tuas costas, também carregarás com a tua perversidade e as tuas devassidões.',
        'Disse-me ainda o Senhor: Filho do homem, porventura julgarás tu a Aolá e a Aolibá? Mostra-lhes, pois, as suas abominações.',
        'Porque adulteraram, e sangue se acha nas suas mãos, e com os seus ídolos adulteraram, e até os seus filhos, que de mim geraram, fizeram passar pelo fogo, para os consumir.',
        'E ainda isto me fizeram: contaminaram o meu santuário no mesmo dia, e profanaram os meus sábados.',
        'Porquanto, havendo sacrificado seus filhos aos seus ídolos, vinham ao meu santuário no mesmo dia para o profanarem; e eis que assim fizeram no meio da minha casa.',
        'E, mais ainda, mandaram vir alguns homens, de longe, aos quais fora enviado um mensageiro, e eis que vieram. Por amor deles te lavaste, coloriste os teus olhos, e te ornaste de enfeites.',
        'E te assentaste sobre um leito de honra, diante do qual estava uma mesa preparada; e puseste sobre ela o meu incenso e o meu azeite.',
        'Com ela se ouvia a voz de uma multidão satisfeita; com homens de classe baixa foram trazidos beberrões do deserto; e puseram braceletes nas mãos das mulheres e coroas de esplendor nas suas cabeças.',
        'Então disse à envelhecida em adultérios: Agora deveras se prostituirão com ela, e ela com eles?',
        'E entraram a ela, como quem entra a uma prostituta; assim entraram a Aolá e a Aolibá, mulheres infames.',
        'De maneira que homens justos as julgarão como se julgam as adúlteras, e como se julgam as que derramam sangue; porque são adúlteras, e sangue há nas suas mãos.',
        'Porque assim diz o Senhor DEUS: Farei subir contra elas uma multidão, e as entregarei ao desterro e ao saque.',
        'E a multidão as apedrejará, e as golpeará com as suas espadas; eles a seus filhos e a suas filhas matarão, e as suas casas queimarão a fogo.',
        'Assim farei cessar a perversidade da terra, para que se escarmentem todas as mulheres, e não façam conforme a vossa perversidade;',
        'O castigo da vossa perversidade eles farão recair sobre vós, e levareis os pecados dos vossos ídolos; e sabereis que eu sou o Senhor DEUS.',
      ],
      [
        'E veio a mim a palavra do SENHOR, no nono ano, no décimo mês, aos dez do mês, dizendo:',
        'Filho do homem, escreve o nome deste dia, deste mesmo dia; porque o rei de babilônia se pôs contra Jerusalém neste mesmo dia.',
        'E fala por parábola à casa rebelde, e dize-lhes: Assim diz o Senhor DEUS: Põe a panela ao lume, põe-na, e deita-lhe também água dentro.',
        'Ajunta nela pedaços, todos os bons pedaços, as coxas e as espáduas; enche-a de ossos escolhidos.',
        'Escolhe o melhor do rebanho, e queima também os ossos debaixo dela; faze-a ferver bem, e cozam-se dentro dela os seus ossos.',
        'Portanto, assim diz o Senhor DEUS: Ai da cidade sanguinária, da panela que escuma por dentro, e cuja escuma não saiu dela! Tira dela pedaço por pedaço; não caia sorte sobre ela;',
        'Porque o seu sangue está no meio dela, sobre uma penha descalvada o pôs; não o derramou sobre a terra, para o cobrir com pó.',
        'Para fazer subir a indignação, para tomar vingança, eu pus o seu sangue numa penha descalvada, para que não fosse coberto.',
        'Portanto, assim diz o Senhor DEUS: Ai da cidade sanguinária! Também eu farei uma grande fogueira.',
        'Amontoa muita lenha, acende o fogo, ferve bem a carne, e tempera o caldo, e ardam os ossos.',
        'Então a porás vazia sobre as suas brasas, para que ela aqueça, e se queime o seu cobre, e se funda a sua imundícia no meio dela, e se consuma a sua escuma.',
        'Ela com mentiras se cansou; e não saiu dela a sua muita escuma; ao fogo irá a sua escuma.',
        'Na imundícia está a infâmia, porquanto te purifiquei, e não permaneceste pura; nunca mais serás purificada da tua imundícia, enquanto eu não fizer descansar sobre ti a minha indignação.',
        'Eu, o SENHOR, o disse: viva isso, e o farei, não me tornarei atrás, e não pouparei, nem me arrependerei; conforme os teus caminhos, e conforme os teus feitos, te julgarão, diz o Senhor DEUS.',
        'E veio a mim a palavra do Senhor, dizendo:',
        'Filho do homem, eis que, de um golpe tirarei de ti o desejo dos teus olhos, mas não lamentarás, nem chorarás, nem te correrão as lágrimas.',
        'Geme em silêncio, não faças luto por mortos; ata o teu turbante, e põe nos pés os teus sapatos, e não cubras os teus lábios, e não comas o pão dos homens.',
        'E falei ao povo pela manhã, e à tarde morreu minha mulher; e fiz pela manhã como me foi mandado.',
        'E o povo me disse: Porventura não nos farás saber o que significam para nós estas coisas que estás fazendo?',
        'E eu lhes disse: Veio a mim a palavra do Senhor, dizendo:',
        'Dize à casa de Israel: Assim diz o Senhor DEUS: Eis que eu profanarei o meu santuário, a glória da vossa força, o desejo dos vossos olhos, e o anelo das vossas almas; e vossos filhos e vossas filhas, que deixastes, cairão à espada.',
        'E fareis como eu fiz; não vos cobrireis os lábios, e não comereis o pão dos homens.',
        'E tereis nas cabeças os vossos turbantes, e os vossos sapatos nos pés; não lamentareis, nem chorareis, mas definhar-vos-eis nas vossas maldades, e gemereis uns com os outros.',
        'Assim vos servirá Ezequiel de sinal; conforme tudo quanto ele fez, fareis; quando isso suceder, sabereis que eu sou o Senhor DEUS.',
        'E quanto a ti, filho do homem, não sucederá que no dia que eu lhes tirar a sua força, a alegria da sua glória, o desejo dos seus olhos, e o anelo de suas almas, com seus filhos e suas filhas,',
        'Nesse dia virá ter contigo aquele que escapar, para te dar notícias pessoalmente?',
        'Naquele dia abrir-se-á a tua boca para com aquele que escapar, e falarás, e não mais ficarás mudo; assim virás a ser para eles um sinal, e saberão que eu sou o Senhor.',
      ],
      [
        'E veio a mim a palavra do SENHOR, dizendo:',
        'Filho do homem, dirige o teu rosto contra os filhos de Amom, e profetiza contra eles.',
        'E dize aos filhos de Amom: Ouvi a palavra do Senhor DEUS: Assim diz o Senhor DEUS: Porquanto tu disseste: Ah! contra o meu santuário, quando foi profanado; e contra a terra de Israel, quando foi assolada; e contra a casa de Judá, quando foi ao cativeiro;',
        'Portanto, eis que te entregarei em possessão aos do oriente, e em ti estabelecerão os seus acampamentos, e porão em ti as suas moradas; eles comerão os teus frutos, e eles beberão o teu leite.',
        'E farei de Rabá uma estrebaria de camelos, e dos filhos de Amom um curral de ovelhas; e sabereis que eu sou o Senhor.',
        'Porque assim diz o Senhor DEUS: Porquanto bateste com as mãos, e pateaste com os pés, e com todo o desprezo do teu coração te alegraste contra a terra de Israel,',
        'Portanto, eis que eu tenho estendido a minha mão sobre ti, e te darei por despojo aos gentios, e te arrancarei dentre os povos, e te destruirei dentre as terras, e acabarei de todo contigo; e saberás que eu sou o Senhor.',
        'Assim diz o Senhor DEUS: Porquanto dizem Moabe e Seir: Eis que a casa de Judá é como todos os gentios;',
        'Portanto, eis que eu abrirei o lado de Moabe desde as cidades, desde as suas cidades da fronteira, a glória da terra, Bete-Jesimote, Baal-Meom, e Quiriataim.',
        'E aos do oriente, contra os filhos de Amom, o entregarei em possessão, para que não haja memória dos filhos de Amom entre as nações.',
        'Também executarei juízos sobre Moabe, e saberão que eu sou o Senhor.',
        'Assim diz o Senhor DEUS: Porquanto Edom se houve vingativamente para com a casa de Judá, e se fez culpadíssimo, quando se vingou deles;',
        'Portanto assim diz o Senhor DEUS: Também estenderei a minha mão sobre Edom, e arrancarei dela homens e animais; e a tornarei em deserto, e desde Temã até Dedã cairão à espada.',
        'E exercerei a minha vingança sobre Edom, pela mão do meu povo de Israel; e farão em Edom segundo a minha ira e segundo o meu furor; e conhecerão a minha vingança, diz o Senhor DEUS.',
        'Assim diz o Senhor DEUS: Porquanto os filisteus se houveram vingativamente, e executaram vingança com desprezo de coração, para destruírem com perpétua inimizade,',
        'Portanto assim diz o Senhor DEUS: Eis que eu estendo a minha mão sobre os filisteus, e arrancarei os quereteus, e destruirei o restante da costa do mar.',
        'E executarei sobre eles grandes vinganças, com furiosos castigos, e saberão que eu sou o Senhor, quando eu tiver exercido a minha vingança sobre eles.',
      ],
      [
        'E sucedeu no undécimo ano, ao primeiro do mês, que veio a mim a palavra do SENHOR, dizendo:',
        'Filho do homem, visto que Tiro disse contra Jerusalém: Ah! está quebrada a porta dos povos; virou-se para mim; eu me encherei, agora que ela está assolada;',
        'Portanto assim diz o Senhor DEUS: Eis que eu estou contra ti, ó Tiro, e farei subir contra ti muitas nações, como o mar faz subir as suas ondas,',
        'Elas destruirão os muros de Tiro, e derrubarão as suas torres; e eu lhe varrerei o seu pó, e dela farei uma penha descalvada.',
        'No meio do mar virá a ser um enxugadouro das redes; porque eu o falei, diz o Senhor DEUS; e servirá de despojo para as nações.',
        'E suas filhas, que estão no campo, serão mortas à espada; e saberão que eu sou o Senhor.',
        'Porque assim diz o Senhor DEUS: Eis que eu, desde o norte, trarei contra Tiro a Nabucodonosor, rei de babilônia, o rei dos reis, com cavalos, e com carros, e com cavaleiros, e companhias, e muito povo.',
        'As tuas filhas que estão no campo, ele as matará à espada, e levantará um baluarte contra ti, e fundará uma trincheira contra ti, e levantará paveses contra ti.',
        'E disporá os seus aríetes contra os teus muros, e derrubará as tuas torres com os seus machados.',
        'Por causa da multidão de seus cavalos te cobrirá o seu pó; os teus muros tremerão com o estrondo dos cavaleiros, e das rodas, e dos carros, quando ele entrar pelas tuas portas, como os homens entram numa cidade em que se fez brecha.',
        'Com os cascos dos seus cavalos pisará todas as tuas ruas; ao teu povo matará à espada, e as tuas fortes colunas cairão por terra.',
        'E roubarão as tuas riquezas, e saquearão as tuas mercadorias, e derrubarão os teus muros, e arrazarão as tuas casas agradáveis; e lançarão no meio das águas as tuas pedras, e as tuas madeiras, e o teu pó.',
        'E farei cessar o ruído das tuas cantigas, e o som dos tuas harpas não se ouvirá mais.',
        'E farei de ti uma penha descalvada; virás a ser um enxugadouro das redes, nunca mais serás edificada; porque eu o SENHOR o falei, diz o Senhor DEUS.',
        'Assim diz o Senhor DEUS a Tiro: Porventura não tremerão as ilhas com o estrondo da tua queda, quando gemerem os feridos, quando se fizer uma espantosa matança no meio de ti?',
        'E todos os príncipes do mar descerão dos seus tronos, e tirarão de si os seus mantos, e despirão as suas vestes bordadas; se vestirão de tremores, sobre a terra se assentarão, e estremecerão a cada momento; e por tua causa pasmarão.',
        'E levantarão uma lamentação sobre ti, e te dirão: Como pereceste, ó bem povoada e afamada cidade, que foste forte no mar; ela e os seus moradores, que atemorizaram a todos os seus habitantes!',
        'Agora, estremecerão as ilhas no dia da tua queda; sim, as ilhas, que estão no mar, turbar-se-ão com tua saída.',
        'Porque assim diz o Senhor DEUS: Quando eu te fizer uma cidade assolada, como as cidades que não se habitam, quando eu fizer subir sobre ti o abismo, e as muitas águas te cobrirem,',
        'Então te farei descer com os que descem à cova, ao povo antigo, e te farei habitar nas mais baixas partes da terra, em lugares desertos antigos, com os que descem à cova, para que não sejas habitada; e estabelecerei a glória na terra dos viventes.',
        'Farei de ti um grande espanto, e não mais existirás; e quando te buscarem então nunca mais serás achada para sempre, diz o Senhor DEUS.',
      ],
      [
        'E veio a mim a palavra do SENHOR, dizendo:',
        'Tu pois, ó filho do homem, levanta uma lamentação sobre Tiro.',
        'E dize a Tiro, que habita nas entradas do mar, e negocia com os povos em muitas ilhas: Assim diz o Senhor Deus: Ó Tiro, tu dizes: Eu sou perfeita em formosura.',
        'No coração dos mares estão os teus termos; os que te edificaram aperfeiçoaram a tua formosura.',
        'Fabricaram todos os teus conveses de faias de Senir; trouxeram cedros do Líbano para te fazerem mastros.',
        'Fizeram os teus remos de carvalhos de Basã; os teus bancos fizeram-nos de marfim engastado em buxo das ilhas dos quiteus.',
        'Linho fino bordado do Egito era a tua cortina, para te servir de vela; azul e púrpura das ilhas de Elisá era a tua cobertura.',
        'Os moradores de Sidom e de Arvade foram os teus remadores; os teus sábios, ó Tiro, que se achavam em ti, esses foram os teus pilotos.',
        'Os anciãos de Gebal e seus sábios foram em ti os que consertavam as tuas fendas; todos os navios do mar e os marinheiros se acharam em ti, para tratarem dos teus negócios.',
        'Os persas, e os lídios, e os de Pute eram no teu exército os teus soldados; escudos e capacetes penduraram em ti; eles manifestaram a tua beleza.',
        'Os filhos de Arvade e o teu exército estavam sobre os teus muros em redor, e os gamaditas nas tuas torres; penduravam os seus escudos nos teus muros em redor; eles aperfeiçoavam a tua formosura.',
        'Társis negociava contigo, por causa da abundância de toda a casta de riquezas; com prata, ferro, estanho e chumbo, negociavam em tuas feiras.',
        'Javã, Tubal e Meseque eram teus mercadores; em troca das tuas mercadorias davam pessoas de homens e objetos de bronze.',
        'Os da casa de Togarma trocavam pelas tuas mercadorias, cavalos, e cavaleiros e mulos.',
        'Os filhos de Dedã eram os teus mercadores; muitas ilhas eram o comércio da tua mão; dentes de marfim e pau de ébano tornavam a dar-te em presente.',
        'A Síria negociava contigo por causa da multidão das tuas manufaturas; pelas tuas mercadorias davam esmeralda, púrpura, obra bordada, linho fino, corais e ágata.',
        'Judá e a terra de Israel, eram os teus mercadores; pelas tuas mercadorias trocavam trigo de Minite, e Panague, e mel, azeite e bálsamo.',
        'Damasco negociava contigo, por causa da multidão das tuas obras, por causa da abundância de toda a sorte de riqueza, dando em troca vinho de Helbom e lã branca.',
        'Também Dã e Javã, de Uzal, pelas tuas mercadorias, davam em troca ferro trabalhado, cássia e cálamo aromático, que assim entravam no teu comércio.',
        'Dedã negociava contigo com panos preciosos para carros.',
        'A Arábia, e todos os príncipes de Quedar, eram mercadores ao teu serviço, com cordeiros, carneiros e bodes; nestas coisas negociavam contigo.',
        'Os mercadores de Sabá e Raamá eram os teus mercadores; em todos os seus mais finos aromas, em toda a pedra preciosa e ouro, negociaram nas tuas feiras.',
        'Harã, e Cane e Éden, os mercadores de Sabá, Assur e Quilmade negociavam contigo.',
        'Estes eram teus mercadores em roupas escolhidas, em pano de azul, e bordados, e em cofres de roupas preciosas, amarrados com cordas e feitos de cedros, entre tua mercadoria.',
        'Os navios de Társis eram as tuas caravanas que traziam tuas mercadorias; e te encheste, e te glorificaste muito no meio dos mares.',
        'Os teus remadores te conduziram sobre grandes águas; o vento oriental te quebrou no meio dos mares.',
        'As tuas riquezas, as tuas feiras, e tuas mercadorias, os teus marinheiros, os teus pilotos, os que consertavam as tuas fendas, os que faziam os teus negócios, e todos os teus soldados, que estão em ti, juntamente com toda a tua companhia, que está no meio de ti, cairão no meio dos mares no dia da tua queda,',
        'Ao estrondo da gritaria dos teus pilotos tremerão os arrabaldes.',
        'E todos os que pegam no remo, os marinheiros, e todos os pilotos do mar descerão de seus navios, e pararão em terra.',
        'E farão ouvir a sua voz sobre ti, e gritarão amargamente; e lançarão pó sobre as cabeças, e na cinza se revolverão.',
        'E far-se-ão calvos por tua causa, e cingir-se-ão de sacos, e chorarão sobre ti com amargura de alma, e com amarga lamentação.',
        'E no seu pranto levantarão uma lamentação sobre ti, e te lamentarão sobre ti, dizendo: Quem foi como Tiro, como a que foi destruída no meio do mar?',
        'Quando as tuas mercadorias saiam pelos mares, fartaste a muitos povos; com a multidão das tuas riquezas e do teu negócio, enriqueceste os reis da terra.',
        'No tempo em que foste quebrantada pelos mares, nas profundezas das águas, caíram, no meio de ti, os teus negócios e toda a tua companhia.',
        'Todos os moradores das ilhas estão a teu respeito cheios de espanto; e os seus reis tremeram sobremaneira, e ficaram perturbados nos seus rostos;',
        'Os mercadores dentre os povos assobiaram contra ti; tu te tornaste em grande espanto, e jamais subsistirá.',
      ],
      [
        'E veio a mim a palavra do SENHOR, dizendo:',
        'Filho do homem, dize ao príncipe de Tiro: Assim diz o Senhor DEUS: Porquanto o teu coração se elevou e disseste: Eu sou Deus, sobre a cadeira de Deus me assento no meio dos mares; e não passas de homem, e não és Deus, ainda que estimas o teu coração como se fora o coração de Deus;',
        'Eis que tu és mais sábio que Daniel; e não há segredo algum que se possa esconder de ti.',
        'Pela tua sabedoria e pelo teu entendimento alcançaste para ti riquezas, e adquiriste ouro e prata nos teus tesouros.',
        'Pela extensão da tua sabedoria no teu comércio aumentaste as tuas riquezas; e eleva-se o teu coração por causa das tuas riquezas;',
        'Portanto, assim diz o Senhor DEUS: Porquanto estimas o teu coração, como se fora o coração de Deus,',
        'Por isso eis que eu trarei sobre ti estrangeiros, os mais terríveis dentre as nações, os quais desembainharão as suas espadas contra a formosura da tua sabedoria, e mancharão o teu resplendor.',
        'Eles te farão descer à cova e morrerás da morte dos traspassados no meio dos mares.',
        'Acaso dirás ainda diante daquele que te matar: Eu sou Deus? mas tu és homem, e não Deus, na mão do que te traspassa.',
        'Da morte dos incircuncisos morrerás, por mão de estrangeiros, porque eu o falei, diz o Senhor DEUS.',
        'Veio a mim a palavra do Senhor, dizendo:',
        'Filho do homem, levanta uma lamentação sobre o rei de Tiro, e dize-lhe: Assim diz o Senhor DEUS: Tu eras o selo da medida, cheio de sabedoria e perfeito em formosura.',
        'Estiveste no Éden, jardim de Deus; de toda a pedra preciosa era a tua cobertura: sardônia, topázio, diamante, turquesa, ônix, jaspe, safira, carbúnculo, esmeralda e ouro; em ti se faziam os teus tambores e os teus pífaros; no dia em que foste criado foram preparados.',
        'Tu eras o querubim, ungido para cobrir, e te estabeleci; no monte santo de Deus estavas, no meio das pedras afogueadas andavas.',
        'Perfeito eras nos teus caminhos, desde o dia em que foste criado, até que se achou iniqüidade em ti.',
        'Na multiplicação do teu comércio encheram o teu interior de violência, e pecaste; por isso te lancei, profanado, do monte de Deus, e te fiz perecer, ó querubim cobridor, do meio das pedras afogueadas.',
        'Elevou-se o teu coração por causa da tua formosura, corrompeste a tua sabedoria por causa do teu resplendor; por terra te lancei, diante dos reis te pus, para que olhem para ti.',
        'Pela multidão das tuas iniqüidades, pela injustiça do teu comércio profanaste os teus santuários; eu, pois, fiz sair do meio de ti um fogo, que te consumiu e te tornei em cinza sobre a terra, aos olhos de todos os que te vêem.',
        'Todos os que te conhecem entre os povos estão espantados de ti; em grande espanto te tornaste, e nunca mais subsistirá.',
        'E veio a mim a palavra do Senhor, dizendo:',
        'Filho do homem, dirige o teu rosto contra Sidom, e profetiza contra ela,',
        'E dize: Assim diz o Senhor DEUS: Eis-me contra ti, ó Sidom, e serei glorificado no meio de ti; e saberão que eu sou o SENHOR, quando nela executar juízos e nela me santificar.',
        'Porque enviarei contra ela a peste, e o sangue nas suas ruas, e os traspassados cairão no meio dela, estando a espada contra ela por todos os lados; e saberão que eu sou o Senhor.',
        'E a casa de Israel nunca mais terá espinho que a fira, nem espinho que cause dor, entre os que se acham ao redor deles e que os desprezam; e saberão que eu sou o Senhor DEUS.',
        'Assim diz o Senhor DEUS: Quando eu congregar a casa de Israel dentre os povos entre os quais estão espalhados, e eu me santificar entre eles, perante os olhos dos gentios, então habitarão na sua terra que dei a meu servo, a Jacó.',
        'E habitarão nela seguros, e edificarão casas, e plantarão vinhas, e habitarão seguros, quando eu executar juízos contra todos os que estão ao seu redor e que os desprezam; e saberão que eu sou o Senhor seu Deus.',
      ],
      [
        'No décimo ano, no décimo mês, no dia doze do mês, veio a mim a palavra do SENHOR, dizendo:',
        'Filho do homem, dirige o teu rosto contra Faraó, rei do Egito, e profetiza contra ele e contra todo o Egito.',
        'Fala, e dize: Assim diz o Senhor DEUS: Eis-me contra ti, ó Faraó, rei do Egito, grande dragão, que pousas no meio dos teus rios, e que dizes: O meu rio é meu, e eu o fiz para mim.',
        'Mas eu porei anzóis em teus queixos, e farei que os peixes dos teus rios se apeguem às tuas escamas; e tirar-te-ei do meio dos teus rios, e todos os peixes dos teus rios se apegarem às tuas escamas.',
        'E te deixarei no deserto, a ti e a todo o peixe dos teus rios; sobre a face do campo cairás; não serás recolhido nem ajuntado; aos animais da terra e às aves do céu te dei por mantimento.',
        'E saberão todos os moradores do Egito que eu sou o Senhor, porquanto se tornaram um bordão de cana para a casa de Israel.',
        'Tomando-te eles pela mão, te quebraste, e lhes rasgaste todo o ombro; e quando se apoiaram em ti, te quebraste, e lhes fazias tremer todos os seus lombos.',
        'Portanto, assim diz o Senhor DEUS: Eis que eu trarei sobre ti a espada, e de ti destruirei homem e animal,',
        'E a terra do Egito se tornará em desolação e deserto; e saberão que eu sou o Senhor, porquanto disse: O rio é meu, e eu o fiz.',
        'Portanto, eis que eu estou contra ti, e contra os teus rios; e tornarei a terra do Egito deserta, em completa desolação, desde a torre de Syene até aos confins da Etiópia.',
        'Não passará por ela pé de homem, nem pé de animal passará por ela, nem será habitada quarenta anos.',
        'Porque tornarei a terra do Egito em desolação no meio das terras desoladas; e as suas cidades entre as cidades desertas se tornarão em desolação por quarenta anos; e espalharei os egípcios entre as nações, e os dispersarei pelas terras.',
        'Porém, assim diz o Senhor DEUS: Ao fim de quarenta anos ajuntarei os egípcios dentre os povos entre os quais foram espalhados.',
        'E removerei o cativeiro dos egípcios, e os farei voltar à terra de Patros, à terra de sua origem; e serão ali um reino humilde;',
        'Mais humilde se fará do que os outros reinos, e nunca mais se exalçará sobre as nações; porque os diminuirei, para que não dominem sobre as nações.',
        'E não será mais a confiança da casa de Israel, para lhes trazer à lembrança a sua iniqüidade, quando olharem para trás deles; antes saberão que eu sou o Senhor DEUS.',
        'E sucedeu que, no ano vinte e sete, no primeiro mês, no primeiro dia do mês, veio a mim a palavra do Senhor, dizendo:',
        'Filho do homem, Nabucodonosor, rei de babilônia, fez com que o seu exército prestasse um grande serviço contra Tiro; toda a cabeça se tornou calva, e todo o ombro se pelou; e não houve paga de Tiro para ele, nem para o seu exército, pelo serviço que prestou contra ela.',
        'Portanto, assim diz o Senhor DEUS: Eis que eu darei a Nabucodonosor, rei de babilônia, a terra do Egito; e levará a sua multidão, e tomará o seu despojo, e roubará a sua presa, e isto será a recompensa para o seu exército.',
        'Como recompensa do seu trabalho, com que serviu contra ela, lhe dei a terra do Egito; porquanto trabalharam por mim, diz o Senhor DEUS.',
        'Naquele dia farei brotar o poder na casa de Israel, e abrirei a tua boca no meio deles; e saberão que eu sou o Senhor.',
      ],
      [
        'E veio a mim a palavra do SENHOR, dizendo:',
        'Filho do homem, profetiza, e dize: Assim diz o Senhor DEUS: Gemei: Ah! Aquele dia!',
        'Porque está perto o dia, sim, está perto o dia do Senhor; dia nublado; será o tempo dos gentios.',
        'A espada virá ao Egito, e haverá grande dor na Etiópia, quando caírem os traspassados no Egito; e tomarão a sua multidão, e serão destruídos os seus fundamentos.',
        'Etiópia, Pute e Lude, e toda a mistura de gente, e Cube, e os homens da terra da liga, juntamente com eles cairão à espada.',
        'Assim diz o SENHOR: Também cairão os que sustém o Egito, e descerá a soberba de seu poder; desde a torre de Syene ali cairão à espada, diz o Senhor DEUS.',
        'E serão desolados no meio das terras assoladas; e as suas cidades estarão no meio das cidades desertas.',
        'E saberão que eu sou o Senhor, quando eu puser fogo no Egito, e forem destruídos todos os que lhe davam auxílio.',
        'Naquele dia sairão mensageiros de diante de mim em navios, para espantarem a Etiópia descuidada; e haverá neles grandes dores, como no dia do Egito; pois, eis que já vem.',
        'Assim diz o Senhor DEUS: Eu, pois, farei cessar a multidão do Egito, por mão de Nabucodonosor, rei de babilônia.',
        'Ele e o seu povo com ele, os mais terríveis das nações, serão levados para destruírem a terra; e desembainharão as suas espadas contra o Egito, e encherão a terra de mortos.',
        'E secarei os rios, e venderei a terra entregando-a na mão dos maus, e assolarei a terra e a sua plenitude pela mão dos estrangeiros; eu, o Senhor, o disse.',
        'Assim diz o Senhor DEUS: Também destruirei os ídolos, e farei cessar as imagens de Nofe; e não haverá mais um príncipe da terra do Egito; e porei o temor na terra do Egito.',
        'E assolarei a Patros, e porei fogo a Zoã, e executarei juízos em Nô.',
        'E derramarei o meu furor sobre Sim, a fortaleza do Egito, e exterminarei a multidão de Nô.',
        'E porei fogo no Egito; Sim terá grande dor, e Nô será fendida, e Nofe terá angústias cotidianas.',
        'Os jovens de Áven e Pi-Besete cairão à espada, e as cidades irão em cativeiro.',
        'E em Tafnes se escurecerá o dia, quando eu quebrar ali os jugos do Egito, e nela cessar a soberba do seu poder; uma nuvem a cobrirá, e suas filhas irão em cativeiro.',
        'Assim executarei juízos no Egito, e saberão que eu sou o Senhor.',
        'E sucedeu que, no ano undécimo, no primeiro mês, aos sete do mês, veio a mim a palavra do Senhor, dizendo:',
        'Filho do homem, eu quebrei o braço de Faraó, rei do Egito, e eis que não foi atado para se lhe aplicar remédios, nem lhe colocarão ligaduras para o atar, a fim de torná-lo forte, para pegar na espada.',
        'Portanto assim diz o Senhor DEUS: Eis que eu estou contra Faraó, rei do Egito, e quebrarei os seus braços, assim o forte como o que está quebrado, e farei cair da sua mão a espada.',
        'E espalharei os egípcios entre as nações, e os dispersarei pelas terras.',
        'E fortalecerei os braços do rei de babilônia, e porei a minha espada na sua mão; mas quebrarei os braços de Faraó, e diante dele gemerá como geme o traspassado.',
        'Eu fortalecerei os braços do rei de babilônia, mas os braços de Faraó cairão; e saberão que eu sou o SENHOR, quando eu puser a minha espada na mão do rei de babilônia, e ele a estender sobre a terra do Egito.',
        'E espalharei os egípcios entre as nações, e os dispersarei entre as terras; assim saberão que eu sou o Senhor.',
      ],
      [
        'E sucedeu, no ano undécimo, no terceiro mês, ao primeiro do mês, que veio a mim a palavra do SENHOR, dizendo:',
        'Filho do homem, dize a Faraó, rei do Egito, e à sua multidão: A quem és semelhante na tua grandeza?',
        'Eis que a Assíria era um cedro no Líbano, de ramos formosos, de sombrosa ramagem e de alta estatura, e a sua copa estava entre os ramos espessos.',
        'As águas o fizeram crescer, o abismo o exalçou; as suas correntes corriam em torno da sua plantação, e ele enviava os regatos a todas as árvores do campo.',
        'Por isso se elevou a sua estatura sobre todas as árvores do campo, e se multiplicaram os seus ramos, e se alongaram as suas varas, por causa das muitas águas quando brotava.',
        'Todas as aves do céu se aninhavam nos seus ramos, e todos os animais do campo geravam debaixo dos seus ramos, e todas as grandes nações habitavam à sua sombra.',
        'Assim era ele formoso na sua grandeza, na extensão dos seus ramos, porque a sua raiz estava junto às muitas águas.',
        'Os cedros, no jardim de Deus, não o podiam obscurecer; as faias não igualavam os seus ramos, e os castanheiros não eram como os seus renovos; nenhuma árvore no jardim de Deus se assemelhou a ele na sua formosura.',
        'Formoso o fiz com a multidão dos seus ramos; e todas as árvores do Éden, que estavam no jardim de Deus, tiveram inveja dele.',
        'Portanto assim diz o Senhor DEUS: Porquanto te elevaste na tua estatura, e se levantou a sua copa no meio dos espessos ramos, e o seu coração se exalçou na sua altura,',
        'Eu o entregarei na mão do mais poderoso dos gentios, que lhe dará o tratamento merecido; pela sua impiedade o lançarei fora.',
        'E estrangeiros, das mais terríveis nações o cortarão, e deixá-lo-ão; cairão os seus ramos sobre os montes e por todos os vales, e os seus renovos serão quebrados por todos os rios da terra; e todos os povos da terra se retirarão da sua sombra, e o deixarão.',
        'Todas as aves do céu habitarão sobre a sua ruína, e todos os animais do campo se acolherão sob os seus renovos;',
        'Para que todas as árvores junto às águas não se exaltem na sua estatura, nem levantem a sua copa no meio dos ramos espessos, nem as que bebem as águas venham a confiar em si, por causa da sua altura; porque todos estão entregues à morte, até à terra mais baixa, no meio dos filhos dos homens, com os que descem à cova.',
        'Assim diz o Senhor DEUS: No dia em que ele desceu ao inferno, fiz eu que houvesse luto; fiz cobrir o abismo, por sua causa, e retive as suas correntes, e detiveram-se as muitas águas; e cobri o Líbano de preto por causa dele, e todas as árvores do campo por causa dele desfaleceram.',
        'Ao som da sua queda fiz tremer as nações, quando o fiz descer ao inferno, com os que descem à cova; e todas as árvores do Éden, a flor e o melhor do Líbano, todas as árvores que bebem águas, se consolavam nas partes mais baixas da terra.',
        'Também estes com ele descerão ao inferno a juntar-se aos que foram traspassados à espada, sim, aos que foram seu braço, e que habitavam à sombra no meio dos gentios.',
        'A quem, pois, és semelhante em glória e em grandeza entre as árvores do Éden? Todavia serás precipitado com as árvores do Éden às partes mais baixas da terra; no meio dos incircuncisos jazerás com os que foram traspassados à espada; este é Faraó e toda a sua multidão, diz o Senhor DEUS.',
      ],
      [
        'E sucedeu que, no ano duodécimo, no duodécimo mês, ao primeiro do mês, veio a mim a palavra do SENHOR, dizendo:',
        'Filho do homem, levanta uma lamentação sobre Faraó, rei do Egito, e dize-lhe: Eras semelhante a um filho do leão entre as nações, mas tu és como uma baleia nos mares, e rompias os teus rios, e turbavas as águas com os teus pés, e pisavas os teus rios.',
        'Assim diz o Senhor DEUS: Portanto, estenderei sobre ti a minha rede com reunião de muitos povos, e te farão subir na minha rede.',
        'Então te deixarei em terra; sobre a face do campo te lançarei, e farei posar sobre ti todas as aves do céu, e fartarei de ti os animais de toda a terra.',
        'E porei as tuas carnes sobre os montes, e encherei os vales da tua altura.',
        'E regarei com o teu sangue a terra onde nadas, até aos montes; e os rios se encherão de ti.',
        'E, apagando-te eu, cobrirei os céus, e enegrecerei as suas estrelas; ao sol encobrirei com uma nuvem, e a lua não fará resplandecer a sua luz.',
        'Todas as brilhantes luzes do céu enegrecerei sobre ti, e trarei trevas sobre a tua terra, diz o Senhor DEUS.',
        'E afligirei os corações de muitos povos, quando eu levar a tua destruição entre as nações, às terras que não conheceste.',
        'E farei com que muitos povos fiquem pasmados de ti, e os seus reis tremam sobremaneira, quando eu brandir a minha espada ante os seus rostos; e estremecerão a cada momento, cada um pela sua vida, no dia da tua queda.',
        'Porque assim diz o Senhor DEUS: A espada do rei de babilônia virá sobre ti.',
        'Farei cair a tua multidão pelas espadas dos poderosos, que são todos os mais terríveis das nações; e destruirão a soberba do Egito, e toda a sua multidão será destruída.',
        'E exterminarei todos os seus animais sobre as muitas águas; nem as turbará mais pé de homem, nem as turbarão unhas de animais.',
        'Então farei assentar as suas águas, e farei correr os seus rios como o azeite, diz o Senhor DEUS.',
        'Quando eu tornar a terra do Egito em desolação, e ela for despojada da sua plenitude, e quando ferir a todos os que habitam nela, então saberão que eu sou o Senhor.',
        'Esta é a lamentação que se fará; que as filhas das nações farão; sobre o Egito e sobre toda a sua multidão, diz o Senhor DEUS.',
        'E sucedeu que, no ano duodécimo, aos quinze do mês, veio a mim a palavra do Senhor, dizendo:',
        'Filho do homem, pranteia sobre a multidão do Egito, e faze-a descer, a ela e às filhas das nações magníficas, às partes mais baixas da terra, juntamente com os que descem à cova.',
        'A quem sobrepujas tu em formosura? Desce, e deita-te com os incircuncisos.',
        'No meio daqueles que foram mortos à espada cairão; à espada ela está entregue; arrastai-a e a toda a sua multidão.',
        'Os mais poderosos dos fortes lhe falarão desde o meio do inferno, com os que a socorrem; desceram, jazeram com os incircuncisos mortos à espada.',
        'Ali está Assur com toda a sua multidão; em redor dele estão os seus sepulcros; todos eles mortos, abatidos à espada.',
        'Os seus sepulcros foram postos nas extremidades da cova, e a sua multidão está em redor do seu sepulcro; todos eles mortos, abatidos à espada; os que tinham causado espanto na terra dos viventes.',
        'Ali está Elão com toda a sua multidão em redor do seu sepulcro; todos eles mortos, abatidos à espada; desceram incircuncisos às partes mais baixas da terra, causaram terror na terra dos viventes e levaram a sua vergonha com os que desceram à cova.',
        'No meio dos mortos lhe puseram uma cama, entre toda a sua multidão; ao redor dele estão os seus sepulcros; todos eles são incircuncisos, mortos à espada; porque causaram terror na terra dos viventes, e levaram a sua vergonha com os que desceram à cova; foi posto no meio dos mortos.',
        'Ali estão Meseque, Tubal e toda a sua multidão; ao redor deles estão os seus sepulcros; todos eles são incircuncisos, e mortos à espada, porquanto causaram terror na terra dos viventes.',
        'Porém não jazerão com os poderosos que caíram dos incircuncisos, os quais desceram ao inferno com as suas armas de guerra e puseram as suas espadas debaixo das suas cabeças; e a sua iniqüidade está sobre os seus ossos, porquanto eram o terror dos fortes na terra dos viventes.',
        'Também tu serás quebrado no meio dos incircuncisos, e jazerás com os que foram mortos à espada.',
        'Ali está Edom, os seus reis e todos os seus príncipes, que com o seu poder foram postos com os que foram mortos à espada; estes jazem com os incircuncisos e com os que desceram à cova.',
        'Ali estão os príncipes do norte, todos eles, e todos os sidônios, que desceram com os mortos, envergonhados com o terror causado pelo seu poder; e jazem incircuncisos com os que foram mortos à espada, e levam a sua vergonha com os que desceram à cova.',
        'Faraó os verá, e se consolará com toda a sua multidão; sim, o próprio Faraó, e todo o seu exército, mortos à espada, diz o Senhor DEUS.',
        'Porque também eu pus o meu espanto na terra dos viventes; por isso jazerá no meio dos incircuncisos, com os mortos à espada, Faraó e toda a sua multidão, diz o Senhor DEUS.',
      ],
      [
        'E veio a mim a palavra do SENHOR, dizendo:',
        'Filho do homem, fala aos filhos do teu povo, e dize-lhes: Quando eu fizer vir a espada sobre a terra, e o povo da terra tomar um homem dos seus termos, e o constituir por seu atalaia;',
        'E, vendo ele que a espada vem sobre a terra, tocar a trombeta e avisar o povo;',
        'Se aquele que ouvir o som da trombeta, não se der por avisado, e vier a espada, e o alcançar, o seu sangue será sobre a sua cabeça.',
        'Ele ouviu o som da trombeta, e não se deu por avisado, o seu sangue será sobre ele; mas o que se dá por avisado salvará a sua vida.',
        'Mas, se quando o atalaia vir que vem a espada, e não tocar a trombeta, e não for avisado o povo, e a espada vier, e levar uma vida dentre eles, este tal foi levado na sua iniqüidade, porém o seu sangue requererei da mão do atalaia.',
        'A ti, pois, ó filho do homem, te constituí por atalaia sobre a casa de Israel; tu, pois, ouvirás a palavra da minha boca, e lha anunciarás da minha parte.',
        'Se eu disser ao ímpio: Ó ímpio, certamente morrerás; e tu não falares, para dissuadir ao ímpio do seu caminho, morrerá esse ímpio na sua iniqüidade, porém o seu sangue eu o requererei da tua mão.',
        'Mas, se advertires o ímpio do seu caminho, para que dele se converta, e ele não se converter do seu caminho, ele morrerá na sua iniqüidade; mas tu livraste a tua alma.',
        'Tu, pois, filho do homem, dize à casa de Israel: Assim falais vós, dizendo: Visto que as nossas transgressões e os nossos pecados estão sobre nós, e nós desfalecemos neles, como viveremos então?',
        'Dize-lhes: Vivo eu, diz o Senhor DEUS, que não tenho prazer na morte do ímpio, mas em que o ímpio se converta do seu caminho, e viva. Convertei-vos, convertei-vos dos vossos maus caminhos; pois, por que razão morrereis, ó casa de Israel?',
        'Tu, pois, filho do homem, dize aos filhos do teu povo: A justiça do justo não o livrará no dia da sua transgressão; e, quanto à impiedade do ímpio, não cairá por ela, no dia em que se converter da sua impiedade; nem o justo poderá viver pela sua justiça no dia em que pecar.',
        'Quando eu disser ao justo que certamente viverá, e ele, confiando na sua justiça, praticar a iniqüidade, não virão à memória todas as suas justiças, mas na sua iniqüidade, que pratica, ele morrerá.',
        'Quando eu também disser ao ímpio: Certamente morrerás; se ele se converter do seu pecado, e praticar juízo e justiça,',
        'Restituindo esse ímpio o penhor, indenizando o que furtou, andando nos estatutos da vida, e não praticando iniqüidade, certamente viverá, não morrerá.',
        'De todos os seus pecados que cometeu não se terá memória contra ele; juízo e justiça fez, certamente viverá.',
        'Todavia os filhos do teu povo dizem: Não é justo o caminho do Senhor; mas o próprio caminho deles é que não é justo.',
        'Desviando-se o justo da sua justiça, e praticando iniqüidade, morrerá nela.',
        'E, convertendo-se o ímpio da sua impiedade, e praticando juízo e justiça, ele viverá por eles.',
        'Todavia, vós dizeis: Não é justo o caminho do Senhor; julgar-vos-ei a cada um conforme os seus caminhos, ó casa de Israel.',
        'E sucedeu que, no ano duodécimo do nosso cativeiro, no décimo mês, aos cinco do mês, veio a mim um que tinha escapado de Jerusalém, dizendo: A cidade está ferida.',
        'Ora, a mão do Senhor estivera sobre mim pela tarde, antes que viesse o que tinha escapado; e ele abrira a minha boca antes que esse homem viesse ter comigo pela manhã; e abriu-se a minha boca, e não fiquei mais calado.',
        'Então veio a mim a palavra do Senhor, dizendo:',
        'Filho do homem, os moradores destes lugares desertos da terra de Israel falam, dizendo: Abraão era um só, e possuiu esta terra; mas nós somos muitos, esta terra nos foi dada em possessão.',
        'Dize-lhes portanto: Assim diz o Senhor DEUS: Comeis a carne com o sangue, e levantais os vossos olhos para os vossos ídolos, e derramais o sangue! Porventura possuireis a terra?',
        'Vós vos estribais sobre a vossa espada, cometeis abominação, e cada um contamina a mulher do seu próximo! E possuireis a terra?',
        'Assim lhes dirás: Assim disse o Senhor DEUS: Vivo eu, que os que estiverem em lugares desertos, cairão à espada, e o que estiver em campo aberto o entregarei às feras, para que o devorem, e os que estiverem em lugares fortes e em cavernas morrerão de peste.',
        'E tornarei a terra em desolação e espanto e cessará a soberba do seu poder; e os montes de Israel ficarão tão desolados que ninguém passará por eles.',
        'Então saberão que eu sou o Senhor, quando eu tornar a terra em desolação e espanto, por causa de todas as abominações que cometeram.',
        'Quanto a ti, ó filho do homem, os filhos do teu povo falam de ti junto às paredes e nas portas das casas; e fala um com o outro, cada um a seu irmão, dizendo: Vinde, peço-vos, e ouvi qual seja a palavra que procede do Senhor.',
        'E eles vêm a ti, como o povo costumava vir, e se assentam diante de ti, como meu povo, e ouvem as tuas palavras, mas não as põem por obra; pois lisonjeiam com a sua boca, mas o seu coração segue a sua avareza.',
        'E eis que tu és para eles como uma canção de amores, de quem tem voz suave, e que bem tange; porque ouvem as tuas palavras, mas não as põem por obra.',
        'Mas, quando vier isto (eis que está para vir), então saberão que houve no meio deles um profeta.',
      ],
      [
        'E veio a mim a palavra do SENHOR, dizendo:',
        'Filho do homem, profetiza contra os pastores de Israel; profetiza, e dize aos pastores: Assim diz o Senhor DEUS: Ai dos pastores de Israel que se apascentam a si mesmos! Não devem os pastores apascentar as ovelhas?',
        'Comeis a gordura, e vos vestis da lã; matais o cevado; mas não apascentais as ovelhas.',
        'As fracas não fortalecestes, e a doente não curastes, e a quebrada não ligastes, e a desgarrada não tornastes a trazer, e a perdida não buscastes; mas dominais sobre elas com rigor e dureza.',
        'Assim se espalharam, por não haver pastor, e tornaram-se pasto para todas as feras do campo, porquanto se espalharam.',
        'As minhas ovelhas andaram desgarradas por todos os montes, e por todo o alto outeiro; sim, as minhas ovelhas andaram espalhadas por toda a face da terra, sem haver quem perguntasse por elas, nem quem as buscasse.',
        'Portanto, ó pastores, ouvi a palavra do Senhor:',
        'Vivo eu, diz o Senhor DEUS, que, porquanto as minhas ovelhas foram entregues à rapina, e as minhas ovelhas vieram a servir de pasto a todas as feras do campo, por falta de pastor, e os meus pastores não procuraram as minhas ovelhas; e os pastores apascentaram a si mesmos, e não apascentaram as minhas ovelhas;',
        'Portanto, ó pastores, ouvi a palavra do Senhor:',
        'Assim diz o Senhor DEUS: Eis que eu estou contra os pastores; das suas mãos demandarei as minhas ovelhas, e eles deixarão de apascentar as ovelhas; os pastores não se apascentarão mais a si mesmos; e livrarei as minhas ovelhas da sua boca, e não lhes servirão mais de pasto.',
        'Porque assim diz o Senhor DEUS: Eis que eu, eu mesmo, procurarei pelas minhas ovelhas, e as buscarei.',
        'Como o pastor busca o seu rebanho, no dia em que está no meio das suas ovelhas dispersas, assim buscarei as minhas ovelhas; e livrá-las-ei de todos os lugares por onde andam espalhadas, no dia nublado e de escuridão.',
        'E tirá-las-ei dos povos, e as congregarei dos países, e as trarei à sua própria terra, e as apascentarei nos montes de Israel, junto aos rios, e em todas as habitações da terra.',
        'Em bons pastos as apascentarei, e nos altos montes de Israel será o seu aprisco; ali se deitarão num bom redil, e pastarão em pastos gordos nos montes de Israel.',
        'Eu mesmo apascentarei as minhas ovelhas, e eu as farei repousar, diz o Senhor DEUS.',
        'A perdida buscarei, e a desgarrada tornarei a trazer, e a quebrada ligarei, e a enferma fortalecerei; mas a gorda e a forte destruirei; apascentá-las-ei com juízo.',
        'E quanto a vós, ó ovelhas minhas, assim diz o Senhor DEUS: Eis que eu julgarei entre ovelhas e ovelhas, entre carneiros e bodes.',
        'Acaso não vos basta pastar os bons pastos, senão que pisais o resto de vossos pastos aos vossos pés? E não vos basta beber as águas claras, senão que sujais o resto com os vossos pés?',
        'E quanto às minhas ovelhas elas pastarão o que haveis pisado com os vossos pés, e beberão o que haveis sujado com os vossos pés.',
        'Por isso o Senhor DEUS assim lhes diz: Eis que eu, eu mesmo, julgarei entre a ovelha gorda e a ovelha magra.',
        'Porquanto com o lado e com o ombro dais empurrões, e com os vossos chifres escorneais todas as fracas, até que as espalhais para fora.',
        'Portanto livrarei as minhas ovelhas, para que não sirvam mais de rapina, e julgarei entre ovelhas e ovelhas.',
        'E suscitarei sobre elas um só pastor, e ele as apascentará; o meu servo Davi é que as apascentará; ele lhes servirá de pastor.',
        'E eu, o Senhor, lhes serei por Deus, e o meu servo Davi será príncipe no meio delas; eu, o Senhor, o disse.',
        'E farei com elas uma aliança de paz, e acabarei com as feras da terra, e habitarão em segurança no deserto, e dormirão nos bosques.',
        'E delas e dos lugares ao redor do meu outeiro, farei uma bênção; e farei descer a chuva a seu tempo; chuvas de bênção serão.',
        'E as árvores do campo darão o seu fruto, e a terra dará a sua novidade, e estarão seguras na sua terra; e saberão que eu sou o Senhor, quando eu quebrar as ataduras do seu jugo e as livrar da mão dos que se serviam delas.',
        'E não servirão mais de rapina aos gentios, as feras da terra nunca mais as devorarão; e habitarão seguramente, e ninguém haverá que as espante.',
        'E lhes levantarei uma plantação de renome, e nunca mais serão consumidas pela fome na terra, nem mais levarão sobre si o opróbrio dos gentios.',
        'Saberão, porém, que eu, o SENHOR seu Deus, estou com elas, e que elas são o meu povo, a casa de Israel, diz o Senhor DEUS.',
        'Vós, pois, ó ovelhas minhas, ovelhas do meu pasto; homens sois; porém eu sou o vosso Deus, diz o Senhor DEUS.',
      ],
      [
        'E veio a mim a palavra do SENHOR, dizendo:',
        'Filho do homem, dirige o teu rosto contra o monte Seir, e profetiza contra ele.',
        'E dize-lhe: Assim diz o Senhor DEUS: Eis que eu estou contra ti, ó monte Seir, e estenderei a minha mão contra ti, e te farei maior desolação.',
        'As tuas cidades farei desertas, e tu serás desolado; e saberás que eu sou o Senhor.',
        'Porquanto guardaste inimizade perpétua, e espalhaste os filhos de Israel pelo poder da espada no tempo da sua calamidade e no tempo da iniqüidade final.',
        'Por isso vivo eu, diz o Senhor DEUS, que te preparei para sangue, e o sangue te perseguirá; visto que não odiaste o sangue, o sangue te perseguirá.',
        'E farei do monte Seir uma extrema desolação, e exterminarei dele o que por ele passar, e o que por ele voltar.',
        'E encherei os seus montes dos seus mortos; nos teus outeiros, e nos teus vales, e em todos os teus rios cairão os mortos à espada.',
        'Em desolações perpétuas te porei, e as tuas cidades nunca mais serão habitadas; assim sabereis que eu sou o Senhor.',
        'Porquanto disseste: As duas nações e as duas terras serão minhas, e as possuiremos, sendo que o Senhor se achava ali.',
        'Portanto, vivo eu, diz o Senhor DEUS, que procederei conforme a tua ira, e conforme a tua inveja, de que usaste, no teu ódio contra eles; e me farei conhecer entre eles, quando te julgar.',
        'E saberás que eu, o Senhor, ouvi todas as tuas blasfêmias, que proferiste contra os montes de Israel, dizendo: Já estão assolados, a nós nos são entregues por pasto.',
        'Assim vos engrandecestes contra mim com a vossa boca, e multiplicastes as vossas palavras contra mim. Eu o ouvi.',
        'Assim diz o Senhor DEUS: Quando toda a terra se alegrar eu te porei em desolação.',
        'Como te alegraste da herança da casa de Israel, porque foi assolada, assim te farei a ti; assolado serás, ó monte Seir, e todo o Edom, sim, todo ele; e saberão que eu sou o Senhor.',
      ],
      [
        'E tu, ó filho do homem, profetiza aos montes de Israel, e dize: Montes de Israel, ouvi a palavra do SENHOR.',
        'Assim diz o Senhor DEUS: Pois que disse o inimigo contra vós: Ah! ah! até as alturas eternas serão nossa herança;',
        'Portanto, profetiza, e dize: Assim diz o Senhor DEUS: Porquanto vos assolaram e devoraram de todos os lados, para que ficásseis feitos herança do restante dos gentios, e tendes andado em lábios paroleiros, e em infâmia do povo,',
        'Portanto, ouvi, ó montes de Israel, a palavra do Senhor DEUS: Assim diz o Senhor DEUS aos montes e aos outeiros, aos rios e aos vales, aos lugares assolados e solitários, e às cidades desamparadas que se tornaram em rapina e em escárnio para o restante dos gentios que lhes estão em redor;',
        'Portanto, assim diz o Senhor DEUS: Certamente no fogo do meu zelo falei contra o restante dos gentios, e contra todo o Edom, que se apropriaram da minha terra, com toda a alegria de seu coração, e com menosprezo da alma, para a lançarem fora à rapina.',
        'Portanto, profetiza sobre a terra de Israel, e dize aos montes, e aos outeiros, aos rios e aos vales: Assim diz o Senhor DEUS: Eis que falei no meu zelo e no meu furor, porque levastes sobre vós o opróbrio dos gentios.',
        'Portanto, assim diz o Senhor DEUS: Eu levantei a minha mão, para que os gentios, que estão ao redor de vós, levem o seu opróbrio.',
        'Mas vós, ó montes de Israel, produzireis os vossos ramos, e dareis o vosso fruto para o meu povo de Israel; porque estão prestes a vir.',
        'Porque eis que eu estou convosco, e eu me voltarei para vós, e sereis lavrados e semeados.',
        'E multiplicarei homens sobre vós, a toda a casa de Israel, a toda ela; e as cidades serão habitadas, e os lugares devastados serão edificados.',
        'E multiplicarei homens e animais sobre vós, e eles se multiplicarão, e frutificarão. E farei com que sejais habitados como dantes e vos tratarei melhor que nos vossos princípios; e sabereis que eu sou o Senhor.',
        'E farei andar sobre vós homens, o meu povo de Israel; eles te possuirão, e serás a sua herança, e nunca mais os desfilharás.',
        'Assim diz o Senhor DEUS: Porquanto vos dizem: Tu és uma terra que devora os homens, e és uma terra que desfilha as suas nações;',
        'Por isso tu não devorarás mais os homens, nem desfilharás mais as tuas nações, diz o Senhor DEUS.',
        'E farei que nunca mais tu ouças a afronta dos gentios; nem levarás mais sobre ti o opróbrio das gentes, nem mais desfilharás a tua nação, diz o Senhor DEUS.',
        'E veio a mim a palavra do Senhor, dizendo:',
        'Filho do homem, quando a casa de Israel habitava na sua terra, então a contaminaram com os seus caminhos e com as suas ações. Como a imundícia de uma mulher em sua separação, tal era o seu caminho perante o meu rosto.',
        'Derramei, pois, o meu furor sobre eles, por causa do sangue que derramaram sobre a terra, e dos seus ídolos, com que a contaminaram.',
        'E espalhei-os entre os gentios, e foram dispersos pelas terras; conforme os seus caminhos, e conforme os seus feitos, eu os julguei.',
        'E, chegando aos gentios para onde foram, profanaram o meu santo nome, porquanto se dizia deles: Estes são o povo do Senhor, e saíram da sua terra.',
        'Mas eu os poupei por amor do meu santo nome, que a casa de Israel profanou entre os gentios para onde foi.',
        'Dize portanto à casa de Israel: Assim diz o Senhor DEUS: Não é por respeito a vós que eu faço isto, ó casa de Israel, mas pelo meu santo nome, que profanastes entre as nações para onde fostes.',
        'E eu santificarei o meu grande nome, que foi profanado entre os gentios, o qual profanastes no meio deles; e os gentios saberão que eu sou o SENHOR, diz o Senhor DEUS, quando eu for santificado aos seus olhos.',
        'E vos tomarei dentre os gentios, e vos congregarei de todas as terras, e vos trarei para a vossa terra.',
        'Então aspergirei água pura sobre vós, e ficareis purificados; de todas as vossas imundícias e de todos os vossos ídolos vos purificarei.',
        'E dar-vos-ei um coração novo, e porei dentro de vós um espírito novo; e tirarei da vossa carne o coração de pedra, e vos darei um coração de carne.',
        'E porei dentro de vós o meu Espírito, e farei que andeis nos meus estatutos, e guardeis os meus juízos, e os observeis.',
        'E habitareis na terra que eu dei a vossos pais e vós sereis o meu povo, e eu serei o vosso Deus.',
        'E livrar-vos-ei de todas as vossas imundícias; e chamarei o trigo, e o multiplicarei, e não trarei fome sobre vós.',
        'E multiplicarei o fruto das árvores, e a novidade do campo, para que nunca mais recebais o opróbrio da fome entre os gentios.',
        'Então vos lembrareis dos vossos maus caminhos, e dos vossos feitos, que não foram bons; e tereis nojo em vós mesmos das vossas iniqüidades e das vossas abominações.',
        'Não é por amor de vós que eu faço isto, diz o Senhor DEUS; notório vos seja; envergonhai-vos, e confundi-vos por causa dos vossos caminhos, ó casa de Israel.',
        'Assim diz o Senhor DEUS: No dia em que eu vos purificar de todas as vossas iniqüidades, então farei com que sejam habitadas as cidades e sejam edificados os lugares devastados.',
        'E a terra assolada será lavrada, em lugar de estar assolada aos olhos de todos os que passavam.',
        'E dirão: Esta terra assolada ficou como jardim do Éden: e as cidades solitárias, e assoladas, e destruídas, estão fortalecidas e habitadas.',
        'Então saberão os gentios, que tiverem ficado ao redor de vós, que eu, o Senhor, tenho reedificado as cidades destruídas, e plantado o que estava devastado. Eu, o Senhor, o disse e o farei.',
        'Assim diz o Senhor DEUS: Ainda por isso serei solicitado pela casa de Israel, que lho faça; multiplicar-lhes-ei os homens, como a um rebanho.',
        'Como o rebanho santificado, como o rebanho de Jerusalém nas suas solenidades, assim as cidades desertas se encherão de rebanhos de homens; e saberão que eu sou o Senhor.',
      ],
      [
        'Veio sobre mim a mão do SENHOR, e ele me fez sair no Espírito do SENHOR, e me pôs no meio de um vale que estava cheio de ossos.',
        'E me fez passar em volta deles; e eis que eram mui numerosos sobre a face do vale, e eis que estavam sequíssimos.',
        'E me disse: Filho do homem, porventura viverão estes ossos? E eu disse: Senhor DEUS, tu o sabes.',
        'Então me disse: Profetiza sobre estes ossos, e dize-lhes: Ossos secos, ouvi a palavra do Senhor.',
        'Assim diz o Senhor DEUS a estes ossos: Eis que farei entrar em vós o espírito, e vivereis.',
        'E porei nervos sobre vós e farei crescer carne sobre vós, e sobre vós estenderei pele, e porei em vós o espírito, e vivereis, e sabereis que eu sou o Senhor.',
        'Então profetizei como se me deu ordem. E houve um ruído, enquanto eu profetizava; e eis que se fez um rebuliço, e os ossos se achegaram, cada osso ao seu osso.',
        'E olhei, e eis que vieram nervos sobre eles, e cresceu a carne, e estendeu-se a pele sobre eles por cima; mas não havia neles espírito.',
        'E ele me disse: Profetiza ao espírito, profetiza, ó filho do homem, e dize ao espírito: Assim diz o Senhor DEUS: Vem dos quatro ventos, ó espírito, e assopra sobre estes mortos, para que vivam.',
        'E profetizei como ele me deu ordem; então o espírito entrou neles, e viveram, e se puseram em pé, um exército grande em extremo.',
        'Então me disse: Filho do homem, estes ossos são toda a casa de Israel. Eis que dizem: Os nossos ossos se secaram, e pereceu a nossa esperança; nós mesmos estamos cortados.',
        'Portanto profetiza, e dize-lhes: Assim diz o Senhor DEUS: Eis que eu abrirei os vossos sepulcros, e vos farei subir das vossas sepulturas, ó povo meu, e vos trarei à terra de Israel.',
        'E sabereis que eu sou o Senhor, quando eu abrir os vossos sepulcros, e vos fizer subir das vossas sepulturas, ó povo meu.',
        'E porei em vós o meu Espírito, e vivereis, e vos porei na vossa terra; e sabereis que eu, o SENHOR, disse isto, e o fiz, diz o SENHOR.',
        'E outra vez veio a mim a palavra do Senhor, dizendo:',
        'Tu, pois, ó filho do homem, toma um pedaço de madeira, e escreve nele: Por Judá e pelos filhos de Israel, seus companheiros. E toma outro pedaço de madeira, e escreve nele: Por José, vara de Efraim, e por toda a casa de Israel, seus companheiros.',
        'E ajunta um ao outro, para que se unam, e se tornem uma só vara na tua mão.',
        'E quando te falarem os filhos do teu povo, dizendo: Porventura não nos declararás o que significam estas coisas?',
        'Tu lhes dirás: Assim diz o Senhor DEUS: Eis que eu tomarei a vara de José que esteve na mão de Efraim, e a das tribos de Israel, suas companheiras, e as ajuntarei à vara de Judá, e farei delas uma só vara, e elas se farão uma só na minha mão.',
        'E as varas, sobre que houve- res escrito, estarão na tua mão, perante os olhos deles.',
        'Dize-lhes pois: Assim diz o Senhor DEUS: Eis que eu tomarei os filhos de Israel dentre os gentios, para onde eles foram, e os congregarei de todas as partes, e os levarei à sua terra.',
        'E deles farei uma nação na terra, nos montes de Israel, e um rei será rei de todos eles, e nunca mais serão duas nações; nunca mais para o futuro se dividirão em dois reinos.',
        'E nunca mais se contaminarão com os seus ídolos, nem com as suas abominações, nem com as suas transgressões, e os livrarei de todas as suas habitações, em que pecaram, e os purificarei. Assim eles serão o meu povo, e eu serei o seu Deus.',
        'E meu servo Davi será rei sobre eles, e todos eles terão um só pastor; e andarão nos meus juízos e guardarão os meus estatutos, e os observarão.',
        'E habitarão na terra que dei a meu servo Jacó, em que habitaram vossos pais; e habitarão nela, eles e seus filhos, e os filhos de seus filhos, para sempre, e Davi, meu servo, será seu príncipe eternamente.',
        'E farei com eles uma aliança de paz; e será uma aliança perpétua. E os estabelecerei, e os multiplicarei, e porei o meu santuário no meio deles para sempre.',
        'E o meu tabernáculo estará com eles, e eu serei o seu Deus e eles serão o meu povo.',
        'E os gentios saberão que eu sou o Senhor que santifico a Israel, quando estiver o meu santuário no meio deles para sempre.',
      ],
      [
        'Veio a mim a palavra do SENHOR, dizendo:',
        'Filho do homem, dirige o teu rosto contra Gogue, terra de Magogue, príncipe e chefe de Meseque, e Tubal, e profetiza contra ele.',
        'E dize: Assim diz o Senhor DEUS: Eis que eu sou contra ti, ó Gogue, príncipe e chefe de Meseque e de Tubal;',
        'E te farei voltar, e porei anzóis nos teus queixos, e te levarei a ti, com todo o teu exército, cavalos e cavaleiros, todos vestidos com primor, grande multidão, com escudo e rodela, manejando todos a espada;',
        'Persas, etíopes, e os de Pute com eles, todos com escudo e capacete;',
        'Gômer e todas as suas tropas; a casa de Togarma, do extremo norte, e todas as suas tropas, muitos povos contigo.',
        'Prepara-te, e dispõe-te, tu e todas as multidões do teu povo que se reuniram a ti, e serve-lhes tu de guarda.',
        'Depois de muitos dias serás visitado. No fim dos anos virás à terra que se recuperou da espada, e que foi congregada dentre muitos povos, junto aos montes de Israel, que sempre se faziam desertos; mas aquela terra foi tirada dentre as nações, e todas elas habitarão seguramente.',
        'Então subirás, virás como uma tempestade, far-te-ás como uma nuvem para cobrir a terra, tu e todas as tuas tropas, e muitos povos contigo.',
        'Assim diz o Senhor DEUS: E acontecerá naquele dia que subirão palavras no teu coração, e maquinarás um mau desígnio,',
        'E dirás: Subirei contra a terra das aldeias não muradas; virei contra os que estão em repouso, que habitam seguros; todos eles habitam sem muro, e não têm ferrolhos nem portas;',
        'A fim de tomar o despojo, e para arrebatar a presa, e tornar a tua mão contra as terras desertas que agora se acham habitadas, e contra o povo que se congregou dentre as nações, o qual adquiriu gado e bens, e habita no meio da terra.',
        'Sebá e Dedã, e os mercadores de Társis, e todos os seus leõezinhos te dirão: Vens tu para tomar o despojo? Ajuntaste a tua multidão para arrebatar a tua presa? Para levar a prata e o ouro, para tomar o gado e os bens, para saquear o grande despojo?',
        'Portanto, profetiza, ó filho do homem, e dize a Gogue: Assim diz o Senhor DEUS: Porventura não o saberás naquele dia, quando o meu povo Israel habitar em segurança?',
        'Virás, pois, do teu lugar, do extremo norte, tu e muitos povos contigo, montados todos a cavalo, grande ajuntamento, e exército poderoso,',
        'E subirás contra o meu povo Israel, como uma nuvem, para cobrir a terra. Nos últimos dias sucederá que hei de trazer-te contra a minha terra, para que os gentios me conheçam a mim, quando eu me houver santificado em ti, ó Gogue, diante dos seus olhos.',
        'Assim diz o Senhor DEUS: Não és tu aquele de quem eu disse nos dias antigos, por intermédio dos meus servos, os profetas de Israel, os quais naqueles dias profetizaram largos anos, que te traria contra eles?',
        'Sucederá, porém, naquele dia, no dia em que vier Gogue contra a terra de Israel, diz o Senhor DEUS, que a minha indignação subirá à minha face.',
        'Porque disse no meu zelo, no fogo do meu furor, que, certamente, naquele dia haverá grande tremor sobre a terra de Israel;',
        'De tal modo que tremerão diante da minha face os peixes do mar, e as aves do céu, e os animais do campo, e todos os répteis que se arrastam sobre a terra, e todos os homens que estão sobre a face da terra; e os montes serão deitados abaixo, e os precipícios se desfarão, e todos os muros desabarão por terra.',
        'Porque chamarei contra ele a espada sobre todos os meus montes, diz o Senhor DEUS; a espada de cada um se voltará contra seu irmão.',
        'E contenderei com ele por meio da peste e do sangue; e uma chuva inundante, e grandes pedras de saraiva, fogo, e enxofre farei chover sobre ele, e sobre as suas tropas, e sobre os muitos povos que estiverem com ele.',
        'Assim eu me engrandecerei e me santificarei, e me darei a conhecer aos olhos de muitas nações; e saberão que eu sou o Senhor.',
      ],
      [
        'Tu, pois, ó filho do homem, profetiza ainda contra Gogue, e dize: Assim diz o Senhor DEUS: Eis que eu sou contra ti, ó Gogue, príncipe e chefe de Meseque e de Tubal.',
        'E te farei voltar, mas deixarei uma sexta parte de ti, e far-te-ei subir do extremo norte, e te trarei aos montes de Israel.',
        'E, com um golpe, tirarei o teu arco da tua mão esquerda, e farei cair as tuas flechas da tua mão direita.',
        'Nos montes de Israel cairás, tu e todas as tuas tropas, e os povos que estão contigo; e às aves de rapina, de toda espécie, e aos animais do campo, te darei por comida.',
        'Sobre a face do campo cairás, porque eu o falei, diz o Senhor DEUS.',
        'E enviarei um fogo sobre Magogue e entre os que habitam seguros nas ilhas; e saberão que eu sou o Senhor.',
        'E farei conhecido o meu santo nome no meio do meu povo Israel, e nunca mais deixarei profanar o meu santo nome; e os gentios saberão que eu sou o Senhor, o Santo em Israel.',
        'Eis que vem, e se cumprirá, diz o Senhor DEUS; este é o dia de que tenho falado.',
        'E os habitantes das cidades de Israel sairão, e acenderão o fogo, e queimarão as armas, e os escudos e as rodelas, com os arcos, e com as flechas, e com os bastões de mão, e com as lanças; e acenderão fogo com elas por sete anos.',
        'E não trarão lenha do campo, nem a cortarão dos bosques, mas com as armas acenderão fogo; e roubarão aos que os roubaram, e despojarão aos que os despojaram, diz o Senhor DEUS.',
        'E sucederá que, naquele dia, darei ali a Gogue um lugar de sepultura em Israel, o vale dos que passam ao oriente do mar; e pararão os que por ele passarem; e ali sepultarão a Gogue, e a toda a sua multidão, e lhe chamarão o vale da multidão de Gogue.',
        'E a casa de Israel os enterrará durante sete meses, para purificar a terra.',
        'Sim, todo o povo da terra os enterrará, e será para eles memo-rável dia em que eu for glorificado, diz o Senhor Deus.',
        'E separarão homens que incessantemente percorrerão a terra, para que eles, juntamente com os que passam, sepultem os que tiverem ficado sobre a face da terra, para a purificarem; durante sete meses farão esta busca.',
        'E os que percorrerem a terra, a qual atravessarão, vendo algum osso de homem, porão ao lado um sinal; até que os enterradores o tenham enterrado no vale da multidão de Gogue.',
        'E também o nome da cidade será Hamona; assim purificarão a terra.',
        'Tu, pois, ó filho do homem, assim diz o Senhor DEUS, dize às aves de toda espécie, e a todos os animais do campo: Ajuntai-vos e vinde, congregai-vos de toda parte para o meu sacrifício, que eu ofereci por vós, um sacrifício grande, nos montes de Israel, e comei carne e bebei sangue.',
        'Comereis a carne dos poderosos e bebereis o sangue dos príncipes da terra; dos carneiros, dos cordeiros, e dos bodes, e dos bezerros, todos cevados de Basã.',
        'E comereis a gordura até vos fartardes e bebereis o sangue até vos embebedardes, do meu sacrifício que ofereci por vós.',
        'E, à minha mesa, fartar-vos-ei de cavalos, de carros, de poderosos, e de todos os homens de guerra, diz o Senhor DEUS.',
        'E eu porei a minha glória entre os gentios e todos os gentios verão o meu juízo, que eu tiver executado, e a minha mão, que sobre elas tiver descarregado.',
        'E saberão os da casa de Israel que eu sou o Senhor seu Deus, desde aquele dia em diante.',
        'E os gentios saberão que os da casa de Israel, por causa da sua iniqüidade, foram levados em cativeiro, porque se rebelaram contra mim, e eu escondi deles a minha face, e os entreguei nas mãos de seus adversários, e todos caíram à espada.',
        'Conforme a sua imundícia e conforme as suas transgressões me houve com eles, e escondi deles a minha face.',
        'Portanto assim diz o Senhor DEUS: Agora tornarei a trazer os cativos de Jacó, e me compadecerei de toda a casa de Israel; zelarei pelo meu santo nome.',
        'E levarão sobre si a sua vergonha, e toda a sua rebeldia, com que se rebelaram contra mim, quando eles habitarem seguros na sua terra, sem haver quem os espante.',
        'Quando eu os tornar a trazer de entre os povos, e os houver ajuntado das terras de seus inimigos, e eu for santificado neles aos olhos de muitas nações,',
        'Então saberão que eu sou o Senhor seu Deus, vendo que eu os fiz ir em cativeiro entre os gentios, e os ajuntarei para voltarem a sua terra, e não mais deixarei lá nenhum deles.',
        'Nem lhes esconderei mais a minha face, pois derramarei o meu Espírito sobre a casa de Israel, diz o Senhor DEUS.',
      ],
      [
        'No ano vinte e cinco do nosso cativeiro, no princípio do ano, no décimo dia do mês, catorze anos depois que a cidade foi conquistada, naquele mesmo dia veio sobre mim a mão do SENHOR, e me levou para lá.',
        'Em visões de Deus me levou à terra de Israel, e me pôs sobre um monte muito alto, sobre o qual havia como que um edifício de cidade para o lado sul.',
        'E, havendo-me levado ali, eis que um homem cuja aparência era como a do bronze, tendo um cordel de linho na sua mão e uma cana de medir, e estava em pé na porta.',
        'E disse-me o homem: Filho do homem, vê com os teus olhos, e ouve com os teus ouvidos, e põe no teu coração tudo quanto eu te fizer ver; porque para to mostrar foste tu aqui trazido; anuncia, pois, à casa de Israel tudo quanto vires.',
        'E havia um muro fora da casa, em seu redor, e na mão do homem uma cana de medir, de seis côvados, cada um dos quais tinha um côvado e um palmo; e ele mediu a largura do edifício, uma cana, e a altura, uma cana.',
        'Então veio à porta que olhava para o caminho do oriente, e subiu pelos seus degraus; mediu o umbral da porta, uma cana de largo, e o outro umbral, uma cana de largo.',
        'E cada câmara tinha uma cana de comprido, e uma cana de largo, e o espaço entre os aposentos era de cinco côvados; e o umbral da porta, ao pé do vestíbulo da porta, por dentro, era de uma cana.',
        'Também mediu o vestíbulo da porta, por dentro, uma cana.',
        'Então mediu o vestíbulo da porta, que tinha oito côvados, e os seus pilares, dois côvados, e este vestíbulo da porta, estava por dentro.',
        'As câmaras da porta para o lado do oriente eram três de um lado e três do outro; a mesma medida era a dos três; também os pilares de um lado e do outro tinham a mesma medida.',
        'Mediu mais a largura da entrada da porta, que era de dez côvados; e o comprimento da porta, treze côvados.',
        'E o espaço em frente das câmaras era de um côvado, e de um côvado o espaço do outro lado; e cada câmara tinha seis côvados de um lado e seis côvados do outro.',
        'Então mediu a porta desde o telhado de uma câmara até ao telhado da outra, vinte e cinco côvados de largo, porta contra porta.',
        'Fez também os pilares, de sessenta côvados, cada pilar, do átrio, em redor da porta.',
        'E, desde a face da porta da entrada até à face do vestíbulo da porta interior, havia cinqüenta côvados.',
        'Havia também janelas estreitas nas câmaras, e nos seus pilares, dentro da porta ao redor, e da mesma sorte nos vestíbulos; e as janelas estavam ao redor, na parte de dentro, e nos pilares havia palmeiras.',
        'E ele me levou ao átrio exterior, e eis que havia nele câmaras, e um pavimento que estava feito no átrio em redor; trinta câmaras havia naquele pavimento.',
        'E o pavimento do lado das portas era proporcional ao comprimento das portas; o pavimento estava mais baixo.',
        'E mediu a largura desde a dianteira da porta inferior até a dianteira do átrio interior, por fora, cem côvados, do lado do oriente e do norte.',
        'E, quanto à porta que olhava para o caminho do norte, no átrio exterior, ele mediu o seu comprimento e a sua largura.',
        'E as suas câmaras eram três de um lado, e três do outro, e os seus pilares e os seus arcos eram da medida da primeira porta: cinqüenta côvados era o seu comprimento, e a largura vinte e cinco côvados.',
        'E as suas janelas, e os seus arcos, e as suas palmeiras, eram da medida da porta que olhava para o caminho do oriente; e subia-se para ela por sete degraus, e os seus arcos estavam diante dela.',
        'E a porta do átrio interior estava defronte da porta do norte bem como da do oriente; e mediu de porta a porta cem côvados.',
        'Então ele me levou ao caminho do sul, e eis que havia ali uma porta que olhava para o caminho do sul, e mediu os seus pilares e os seus arcos conforme estas medidas.',
        'E havia também janelas em redor dos seus arcos, como as outras janelas; cinqüenta côvados era o comprimento, e a largura vinte e cinco côvados.',
        'E de sete degraus eram as suas subidas, e os seus arcos estavam diante delas; e tinha palmeiras, uma de um lado e outra do outro, nos seus pilares.',
        'Também havia uma porta no átrio interior para o caminho do sul; e mediu de porta a porta, para o caminho do sul, cem côvados.',
        'Então me levou ao átrio interior pela porta do sul; e mediu a porta do sul, conforme estas medidas.',
        'E as suas câmaras, e os seus pilares, e os seus arcos eram conforme estas medidas; e tinham também janelas ao redor dos seus arcos; o comprimento era de cinqüenta côvados, e a largura de vinte e cinco côvados.',
        'E havia arcos em redor; o comprimento era de vinte e cinco côvados, e a largura de cinco côvados.',
        'E os seus arcos estavam na direção do átrio exterior, e havia palmeiras nos seus pilares; e de oito degraus eram as suas subidas.',
        'Depois me levou ao átrio interior, para o caminho do oriente, e mediu a porta conforme estas medidas;',
        'E também as suas câmaras, e os seus pilares, e os seus arcos, conforme estas medidas; e havia também janelas em redor dos seus arcos; o comprimento de cinqüenta côvados, e a largura de vinte e cinco côvados.',
        'E os seus arcos estavam no átrio de fora; também havia palmeiras nos seus pilares de um e de outro lado; e eram as suas subidas de oito degraus.',
        'Então me levou à porta do norte, e mediu conforme estas medidas;',
        'As suas câmaras, os seus pilares, e os seus arcos; também tinha janelas em redor; o comprimento era de cinqüenta côvados, e a largura de vinte e cinco côvados.',
        'E os seus pilares estavam no átrio exterior; também havia palmeiras nos seus pilares de um e de outro lado; e eram as suas subidas de oito degraus.',
        'E as suas câmaras e as suas entradas estavam junto aos pilares das portas onde lavavam o holocausto.',
        'E no vestíbulo da porta havia duas mesas de um lado, e duas mesas do outro, para nelas se matar o holocausto e a oferta pelo pecado e pela culpa.',
        'Também do lado de fora da subida para a entrada da porta do norte havia duas mesas; e do outro lado, que estava no vestíbulo da porta, havia duas mesas.',
        'Quatro mesas de um lado, e quatro mesas do outro; aos lados da porta oito mesas, sobre as quais imolavam.',
        'E as quatro mesas para o holocausto eram de pedras lavradas; o comprimento era de um côvado e meio, e a largura de um côvado e meio, e a altura de um côvado; e sobre elas se punham os instrumentos com que imolavam o holocausto e o sacrifício.',
        'E os ganchos de um palmo de comprimento, estavam fixos por dentro em redor, e sobre as mesas estava a carne da oferta.',
        'E fora da porta interior estavam as câmaras dos cantores, no átrio de dentro, que estava ao lado da porta do norte e olhava para o caminho do sul; uma estava ao lado da porta do oriente, e olhava para o caminho do norte.',
        'E ele me disse: Esta câmara que olha para o caminho do sul é para os sacerdotes que têm a guarda da casa.',
        'Mas a câmara que olha para o caminho do norte é para os sacerdotes que têm a guarda do altar; são estes os filhos de Zadoque, que se chegam ao Senhor, dentre os filhos de Levi, para o servir.',
        'E mediu o átrio; o comprimento de cem côvados e a largura de cem côvados, um quadrado; e o altar estava diante da casa.',
        'Então me levou ao vestíbulo da casa, e mediu a cada pilar do vestíbulo, cinco côvados de um lado, e cinco côvados do outro; e a largura da porta, três côvados de um lado, e três côvados do outro.',
        'O comprimento do vestíbulo era de vinte côvados, e a largura de onze côvados, e era por degraus, que se subia a ele; e havia colunas junto aos pilares, uma de um lado e outra do outro.',
      ],
      [
        'Então me levou ao templo, e mediu os pilares, seis côvados de largura de um lado, e seis côvados de largura do outro, que era a largura da tenda.',
        'E a largura da entrada, dez côvados; e os lados da entrada, cinco côvados de um lado e cinco côvados do outro; também mediu o seu comprimento, de quarenta côvados, e a largura, de vinte côvados.',
        'E entrou no interior, e mediu o pilar da entrada, dois côvados, e a entrada, seis côvados, e a largura da entrada, sete côvados.',
        'Também mediu o seu comprimento, vinte côvados, e a largura, vinte côvados, diante do templo, e disse-me: Este é o Santo dos Santos.',
        'E mediu a parede da casa, seis côvados, e a largura das câmaras laterais, quatro côvados, por todo o redor da casa.',
        'E as câmaras laterais, estavam em três andares, câmara sobre câmara, trinta em cada andar, e elas entravam na parede que tocava na casa pelas câmaras laterais em redor, para prenderem nela, e não travavam na parede da casa.',
        'E havia maior largura nas câmaras laterais superiores, porque o caracol da casa ia subindo muito alto por todo o redor da casa, por isso que a casa tinha mais largura para cima; e assim da câmara baixa se subia à mais alta pelo meio.',
        'E olhei para a altura da casa ao redor; e eram os fundamentos das câmaras laterais da medida de uma cana inteira, seis côvados grandes.',
        'A grossura da parede das câmaras laterais de fora era de cinco côvados; e o que foi deixado vazio era o lugar das câmaras laterais, que estavam por dentro.',
        'E entre as câmaras havia a largura de vinte côvados por todo o redor da casa.',
        'E as entradas das câmaras laterais estavam voltadas para o lugar vazio; uma entrada para o caminho do norte, e outra entrada para o do sul; e a largura do lugar vazio era de cinco côvados em redor.',
        'Era também o edifício que estava diante do lugar separado, do lado do ocidente, da largura de setenta côvados; e a parede do edifício de cinco côvados de largura em redor, e o seu comprimento era de noventa côvados.',
        'Assim mediu a casa, do comprimento de cem côvados, como também o lugar separado, e o edifício, e as suas paredes, cem côvados de comprimento.',
        'E a largura da frente da casa, e do lugar separado para o oriente, de uma e de outra parte, de cem côvados.',
        'Também mediu o comprimento do edifício, diante do lugar separado, que estava por detrás, e as suas galerias de uma e de outra parte, cem côvados, com o templo de dentro e os vestíbulos do átrio.',
        'Os umbrais e as janelas estreitas, e as galerias em redor nos três andares, defronte do umbral, estavam cobertas de madeira em redor; e isto desde o chão até às janelas; e as janelas estavam cobertas.',
        'No espaço em cima da porta, e até na casa, no seu interior e na parte de fora, e até toda a parede em redor, por dentro e por fora, tudo por medida.',
        'E foi feito com querubins e palmeiras, de maneira que cada palmeira estava entre querubim e querubim, e cada querubim tinha dois rostos,',
        'A saber: um rosto de homem olhava para a palmeira de um lado, e um rosto de leãozinho para a palmeira do outro lado; assim foi feito por toda a casa em redor.',
        'Desde o chão até acima da entrada estavam feitos os querubins e as palmeiras, como também pela parede do templo.',
        'As ombreiras do templo eram quadradas e, no tocante à frente do santuário, a aparência de uma era como a aparência da outra,',
        'O altar de madeira era de três côvados de altura, e o seu comprimento de dois côvados; os seus cantos, o seu comprimento e as suas paredes eram de madeira; e disse-me: Esta é a mesa que está perante a face do Senhor.',
        'E o templo e o santuário, ambos tinham duas portas.',
        'E as portas tinham duas folhas; duas folhas que viravam; duas para uma porta e duas para a outra.',
        'E nelas, isto é, nas portas do templo, foram feitos querubins e palmeiras, como estavam feitos nas paredes, e havia uma trave grossa de madeira na frente do vestíbulo por fora.',
        'E havia janelas estreitas, e palmeiras, de um e de outro lado, pelos lados do vestíbulo, como também nas câmaras da casa e nas grossas traves.',
      ],
      [
        'Depois disto fez-me sair para fora, ao átrio exterior, para o lado do caminho do norte; e me levou às câmaras que estavam defronte do lugar separado, e que estavam defronte do edifício, do lado norte.',
        'Do comprimento de cem côvados, era a entrada do norte; e a largura era de cinqüenta côvados.',
        'Em frente dos vinte côvados, que tinha o átrio interior, e em frente do pavimento que tinha o átrio exterior, havia galeria contra galeria em três andares.',
        'E diante das câmaras havia um passeio de dez côvados de largo, do lado de dentro, e um caminho de um côvado, e as suas entradas eram para o lado do norte.',
        'E as câmaras superiores eram mais estreitas; porque as galerias tomavam aqui mais espaço do que as de baixo e as do meio do edifício.',
        'Porque elas eram de três andares, e não tinham colunas como as colunas dos átrios; por isso desde o chão se iam estreitando, mais do que as de baixo e as do meio.',
        'E o muro que estava de fora, defronte das câmaras, no caminho do átrio exterior, diante das câmaras, tinha cinqüenta côvados de comprimento.',
        'Pois o comprimento das câmaras, que estavam no átrio exterior, era de cinqüenta côvados; e eis que defronte do templo havia cem côvados.',
        'Por baixo destas câmaras estava a entrada do lado do oriente, quando se entra nelas pelo átrio exterior.',
        'Na largura do muro do átrio para o lado do oriente, diante do lugar separado, e diante do edifício, havia também câmaras.',
        'E o caminho que havia diante delas era da aparência das câmaras, que davam para o norte; conforme o seu comprimento, assim era a sua largura; e todas as suas saídas eram também conforme os seus padrões, e conforme as suas entradas.',
        'E conforme as portas das câmaras, que olhavam para o caminho do sul, havia também uma entrada no topo do caminho, isto é, do caminho em frente do muro direito, para o caminho do oriente, quando se entra por elas.',
        'Então me disse: As câmaras do norte, e as câmaras do sul, que estão diante do lugar separado, elas são câmaras santas, em que os sacerdotes, que se chegam ao Senhor, comerão as coisas mais santas; ali porão as coisas mais santas, e a oferta de manjar, a oferta pelo pecado, e a oferta pela culpa; porque o lugar é santo.',
        'Quando os sacerdotes entrarem, não sairão do santuário para o átrio exterior, mas porão ali as suas vestiduras com que ministraram, porque elas são santas; e vestir-se-ão de outras vestiduras, e assim se aproximarão do lugar pertencente ao povo.',
        'E, acabando ele de medir a casa interior, ele me fez sair pelo caminho da porta, cuja face olha para o caminho do oriente; e a mediu em redor.',
        'Mediu o lado oriental com a cana de medir, quinhentas canas, com a cana de medir, ao redor.',
        'Mediu o lado do norte, com a cana de medir, quinhentas canas ao redor.',
        'Mediu também o lado do sul, com a cana de medir, quinhentas canas.',
        'Deu uma volta para o lado do ocidente, e mediu, com a cana de medir, quinhentas canas.',
        'Mediu pelos quatro lados; e havia um muro em redor, de quinhentas canas de comprimento, e quinhentas de largura, para fazer separação entre o santo e o profano.',
      ],
      [
        'Então me levou à porta, à porta que olha para o caminho do oriente.',
        'E eis que a glória do Deus de Israel vinha do caminho do oriente; e a sua voz era como a voz de muitas águas, e a terra resplandeceu por causa da sua glória.',
        'E o aspecto da visão que tive era como o da visão que eu tivera quando vim destruir a cidade; e eram as visões como as que tive junto ao rio Quebar; e caí sobre o meu rosto.',
        'E a glória do Senhor entrou na casa pelo caminho da porta, cuja face está para o lado do oriente.',
        'E levantou-me o Espírito, e me levou ao átrio interior; e eis que a glória do SENHOR encheu a casa.',
        'E ouvi alguém que falava comigo de dentro da casa, e um homem se pôs em pé junto de mim.',
        'E disse-me: Filho do homem, este é o lugar do meu trono, e o lugar das plantas dos meus pés, onde habitarei no meio dos filhos de Israel para sempre; e os da casa de Israel não contaminarão mais o meu nome santo, nem eles nem os seus reis, com suas prostituições e com os cadáveres dos seus reis, nos seus altos,',
        'Pondo o seu limiar ao pé do meu limiar, e o seu umbral junto ao meu umbral, e havendo uma parede entre mim e eles; e contaminaram o meu santo nome com as suas abominações que cometiam; por isso eu os consumi na minha ira.',
        'Agora lancem eles para longe de mim a sua prostituição, e os cadáveres dos seus reis, e habitarei no meio deles para sempre.',
        'Tu, pois, ó filho do homem, mostra à casa de Israel esta casa, para que se envergonhe das suas maldades, e meça o modelo.',
        'E, envergonhando-se eles de tudo quanto fizeram, faze-lhes saber a forma desta casa, e a sua figura, e as suas saídas, e as suas entradas, e todas as suas formas, e todos os seus estatutos, todas as suas formas, e todas as suas leis; e escreve isto aos seus olhos, para que guardem toda a sua forma, e todos os seus estatutos, e os cumpram.',
        'Esta é a lei da casa: Sobre o cume do monte todo o seu contorno em redor será santíssimo; eis que esta é a lei da casa.',
        'E estas são as medidas do altar, em côvados (o côvado é um côvado e um palmo): e o fundo será de um côvado de altura, e um côvado de largura, e a sua borda em todo o seu contorno, de um palmo; e esta é a base do altar.',
        'E do fundo, desde a terra até a armação inferior, dois côvados, e de largura um côvado, e desde a pequena armação até a grande, quatro côvados, e a largura de um côvado.',
        'E o altar, de quatro côvados; e desde o altar e para cima havia quatro pontas.',
        'E o altar terá doze côvados de comprimento, e doze de largura, quadrado nos quatro lados.',
        'E a armação, catorze côvados de comprimento, e catorze de largura, nos seus quatro lados; e o contorno, ao redor dela, de meio côvado, e o fundo dela de um côvado, ao redor; e os seus degraus davam para o oriente.',
        'E disse-me: Filho do homem, assim diz o Senhor DEUS: Estes são os estatutos do altar, no dia em que o fizerem, para oferecerem sobre ele holocausto e para aspergirem sobre ele sangue.',
        'E aos sacerdotes levitas, que são da descendência de Zadoque, que se chegam a mim (diz o Senhor DEUS) para me servirem, darás um bezerro, para oferta pelo pecado.',
        'E tomarás do seu sangue, e o porás sobre as suas quatro pontas, e sobre os quatro cantos da armação, e no contorno ao redor; assim o purificarás e o expiarás.',
        'Então tomarás o bezerro da oferta pelo pecado, e o queimará no lugar da casa para isso designado, fora do santuário.',
        'E no segundo dia oferecerás um bode, sem mancha, como oferta pelo pecado; e purificarão o altar, como o purificaram com o bezerro.',
        'E, acabando tu de purificá-lo, oferecerás um bezerro, sem mancha, e um carneiro do rebanho, sem mancha.',
        'E oferecê-los-ás perante a face do Senhor; e os sacerdotes deitarão sal sobre eles, e oferecê-los-ão em holocausto ao Senhor.',
        'Por sete dias prepararás, cada dia um bode como oferta pelo pecado; também prepararão um bezerro, e um carneiro do rebanho, sem mancha.',
        'Por sete dias expiarão o altar, e o purificarão; e assim consagrar-se-ão.',
        'E, cumprindo eles estes dias, será que, ao oitavo dia, e dali em diante, os sacerdotes oferecerão sobre o altar os vossos holocaustos e as vossas ofertas pacíficas; e eu me deleitarei em vós, diz o Senhor DEUS.',
      ],
      [
        'Então me fez voltar para o caminho da porta exterior do santuário, que olha para o oriente, a qual estava fechada.',
        'E disse-me o Senhor: Esta porta permanecerá fechada, não se abrirá; ninguém entrará por ela, porque o Senhor, o Deus de Israel entrou por ela; por isso permanecerá fechada.',
        'Quanto ao príncipe, por ser príncipe, se assentará nela para sempre, para comer o pão diante do Senhor; pelo caminho do vestíbulo da porta entrará e por esse mesmo caminho sairá.',
        'Depois me levou pelo caminho da porta do norte, diante da casa; e olhei, e eis que a glória do Senhor encheu a casa do Senhor; então caí sobre o meu rosto.',
        'E disse-me o Senhor: Filho do homem, pondera no teu coração, e vê com os teus olhos, e ouve com os teus ouvidos, tudo quanto eu te disser de todos os estatutos da casa do Senhor, e de todas as suas leis; e considera no teu coração a entrada da casa, com todas as saídas do santuário.',
        'E dize ao rebelde, à casa de Israel: Assim diz o Senhor DEUS: Bastem-vos todas as vossas abominações, ó casa de Israel!',
        'Porque introduzistes estrangeiros, incircuncisos de coração e incircuncisos de carne, para estarem no meu santuário, para o profanarem em minha casa, quando ofereceis o meu pão, a gordura, e o sangue; e eles invalidaram a minha aliança, por causa de todas as vossas abominações.',
        'E não guardastes a ordenança a respeito das minhas coisas sagradas; antes vos constituístes, a vós mesmos, guardas da minha ordenança no meu santuário.',
        'Assim diz o Senhor DEUS: Nenhum estrangeiro, incircunciso de coração ou incircunciso de carne, entrará no meu santuário, dentre os estrangeiros que se acharem no meio dos filhos de Israel.',
        'Mas os levitas que se apartaram para longe de mim, quando Israel andava errado; os quais andavam transviados, desviados de mim, para irem atrás dos seus ídolos, levarão sobre si a sua iniqüidade.',
        'Contudo serão ministros no meu santuário, nos ofícios das portas da casa, e servirão à casa; eles matarão o holocausto, e o sacrifício para o povo, e estarão perante eles, para os servir.',
        'Porque lhes ministraram diante dos seus ídolos, e fizeram a casa de Israel cair em iniqüidade; por isso eu levantei a minha mão contra eles, diz o Senhor DEUS, e levarão sobre si a sua iniqüidade.',
        'E não se chegarão a mim, para me servirem no sacerdócio, nem para se chegarem a alguma de todas as minhas coisas sagradas, às coisas que são santíssimas, mas levarão sobre si a sua vergonha e as suas abominações que cometeram.',
        'Contudo, eu os constituirei guardas da ordenança da casa, em todo o seu serviço, e em tudo o que nela se fizer.',
        'Mas os sacerdotes levíticos, os filhos de Zadoque, que guardaram a ordenança do meu santuário quando os filhos de Israel se extraviaram de mim, eles se chegarão a mim, para me servirem, e estarão diante de mim, para me oferecerem a gordura e o sangue, diz o Senhor DEUS.',
        'Eles entrarão no meu santuário, e se chegarão à minha mesa, para me servirem, e guardarão a minha ordenança;',
        'E será que, quando entrarem pelas portas do átrio interior, se vestirão com vestes de linho; e não se porá lã sobre eles, quando servirem nas portas do átrio interior, e dentro.',
        'Gorros de linho estarão sobre as suas cabeças, e calções de linho sobre os seus lombos; não se cingirão de modo que lhes venha suor.',
        'E, saindo eles ao átrio exterior, ao átrio de fora, ao povo, despirão as suas vestiduras com que ministraram, e as porão nas santas câmaras, e se vestirão de outras vestes, para que não santifiquem o povo estando com as suas vestiduras.',
        'E não raparão a sua cabeça, nem deixarão crescer o cabelo; antes, como convém, tosquiarão as suas cabeças.',
        'E nenhum sacerdote beberá vinho quando entrar no átrio interior.',
        'E eles não se casarão nem com viúva nem com repudiada, mas tomarão virgens da linhagem da casa de Israel, ou viúva que for viúva de sacerdote.',
        'E a meu povo ensinarão a distinguir entre o santo e o profano, e o farão discernir entre o impuro e o puro.',
        'E, quando houver disputa, eles assistirão a ela para a julgarem; pelos meus juízos as julgarão; e as minhas leis e os meus estatutos guardarão em todas as minhas solenidades, e santificarão os meus sábados.',
        'E eles não se aproximarão de nenhum homem morto, para se contaminarem; mas por pai, ou por mãe, ou por filho, ou por filha, ou por irmão, ou por irmã que não tiver marido, se poderão contaminar.',
        'E, depois da sua purificação, contar-se-lhe-ão sete dias.',
        'E, no dia em que ele entrar no lugar santo, no átrio interior, para ministrar no lugar santo, oferecerá a sua expiação pelo pecado, diz o Senhor DEUS.',
        'Eles terão uma herança: eu serei a sua herança. Não lhes dareis, portanto, possessão em Israel; eu sou a sua possessão.',
        'Eles comerão a oferta de alimentos, e a oferta pelo pecado e a oferta pela culpa; e toda a coisa consagrada em Israel será deles.',
        'E as primícias de todos os primeiros frutos de tudo, e toda a oblação de tudo, de todas as vossas oblações, serão dos sacerdotes; também as primeiras das vossas massas dareis ao sacerdote, para que faça repousar a bênção sobre a tua casa.',
        'Nenhuma coisa, que tenha morrido ou tenha sido despedaçada, de aves e de animais, comerão os sacerdotes.',
      ],
      [
        'Quando, pois, repartirdes a terra em herança, oferecereis uma oferta ao SENHOR, uma porção santa da terra; o seu comprimento será de vinte e cinco mil canas e a largura de dez mil. Esta será santa em toda a sua extensão ao redor.',
        'Desta porção o santuário ocupará quinhentas canas de comprimento, e quinhentas de largura, em quadrado, e terá em redor um espaço vazio de cinqüenta côvados.',
        'E desta porção medirás vinte e cinco mil côvados de comprimento, e a largura de dez mil; e ali estará o santuário, o lugar santíssimo.',
        'Esta será a porção santa da terra; ela será para os sacerdotes, ministros do santuário, que dele se aproximam para servir ao Senhor; e lhes servirá de lugar para suas casas, e de lugar santo para o santuário.',
        'E os levitas, ministros da casa, terão em sua possessão, vinte e cinco mil canas de comprimento, para vinte câmaras.',
        'E para possessão da cidade, de largura dareis cinco mil canas, e de comprimento vinte e cinco mil, defronte da oferta santa; o que será para toda a casa de Israel.',
        'O príncipe, porém, terá a sua parte deste e do outro lado da área santa, e da possessão da cidade, diante da santa oferta, e em frente da possessão da cidade, desde o extremo ocidental até o extremo oriental, e de comprimento, corresponderá a uma das porções, desde o termo ocidental até ao termo oriental.',
        'E esta terra será a sua possessão em Israel; e os meus príncipes nunca mais oprimirão o meu povo, antes deixarão a terra à casa de Israel, conforme as suas tribos.',
        'Assim diz o Senhor DEUS: Basta já, ó príncipes de Israel; afastai a violência e a assolação e praticai juízo e justiça; tirai as vossas imposições do meu povo, diz o Senhor DEUS.',
        'Tereis balanças justas, efa justo e bato justo.',
        'O efa e o bato serão de uma mesma medida, de modo que o bato contenha a décima parte do ômer, e o efa a décima parte do ômer; conforme o ômer será a sua medida.',
        'E o siclo será de vinte geras; vinte siclos, vinte e cinco siclos, e quinze siclos terá a vossa mina.',
        'Esta será a oferta que haveis de oferecer: a sexta parte de um efa de cada ômer de trigo; também dareis a sexta parte de um efa de cada ômer de cevada.',
        'Quanto à ordenança do azeite, de cada bato de azeite oferecereis a décima parte de um bato tirado de um coro, que é um ômer de dez batos; porque dez batos fazem um ômer.',
        'E um cordeiro do rebanho, de cada duzentos, da terra mais regada de Israel, para oferta de alimentos, e para holocausto, e para sacrifício pacífico; para que façam expiação por eles, diz o Senhor DEUS.',
        'Todo o povo da terra concorrerá com esta oferta, para o príncipe em Israel.',
        'E estarão a cargo do príncipe os holocaustos, e as ofertas de alimentos, e as libações, nas festas, e nas luas novas, e nos sábados, em todas as solenidades da casa de Israel. Ele preparará a oferta pelo pecado, e a oferta de alimentos, e o holocausto, e os sacrifícios pacíficos, para fazer expiação pela casa de Israel.',
        'Assim diz o Senhor DEUS: No primeiro mês, no primeiro dia do mês, tomarás um bezerro sem mancha e purificarás o santuário.',
        'E o sacerdote tomará do sangue do sacrifício pelo pecado, e porá dele nas ombreiras da casa, e nos quatro cantos da armação do altar, e nas ombreiras da porta do átrio interior.',
        'Assim também farás no sétimo dia do mês, pelos que erram, e pelos símplices; assim expiareis a casa.',
        'No primeiro mês, no dia catorze do mês, tereis a páscoa, uma festa de sete dias; pão ázimo se comerá.',
        'E no mesmo dia o príncipe preparará por si e por todo o povo da terra, um bezerro como oferta pelo pecado.',
        'E durante os sete dias da festa preparará um holocausto ao Senhor, de sete bezerros e sete carneiros sem mancha, cada dia, durante os sete dias; e em sacrifício pelo pecado um bode cada dia.',
        'Também preparará uma oferta de alimentos, a saber, um efa, para cada bezerro, e um efa para cada carneiro, e um him de azeite para cada efa.',
        'No sétimo mês, no dia quinze do mês, na festa, fará o mesmo por sete dias, tanto o sacrifício pelo pecado, como o holocausto, e como a oferta de alimentos, e como o azeite.',
      ],
      [
        'Assim diz o Senhor DEUS: A porta do átrio interior que dá para o oriente, estará fechada durante os seis dias que são de trabalho; mas no dia de sábado ela se abrirá; também no dia da lua nova se abrirá.',
        'E o príncipe entrará pelo caminho do vestíbulo da porta, por fora, e permanecerá junto da ombreira da porta; e os sacerdo- tes prepararão o holocausto, e os sacrifícios pacíficos dele; e ele adorará junto ao umbral da porta, e sairá; mas a porta não se fechará até à tarde.',
        'E o povo da terra adorará à entrada da mesma porta, nos sábados e nas luas novas, diante do Senhor.',
        'E o holocausto, que o príncipe oferecer ao Senhor, será, no dia de sábado, seis cordeiros sem mancha e um carneiro sem mancha.',
        'E a oferta de alimentos será um efa para o carneiro; e para o cordeiro, a oferta de alimentos será o que puder dar; e de azeite um him para cada efa.',
        'Mas no dia da lua nova será um bezerro sem mancha, e seis cordeiros e um carneiro; eles serão sem mancha.',
        'E preparará por oferta de manjares um efa para o bezerro e um efa para o carneiro, mas para os cordeiros, o que a sua mão puder dar; e um him de azeite para um efa.',
        'E, quando entrar o príncipe, entrará pelo caminho do vestíbulo da porta, e sairá pelo mesmo caminho.',
        'Mas, quando vier o povo da terra perante a face do Senhor nas solenidades, aquele que entrar pelo caminho da porta do norte, para adorar, sairá pelo caminho da porta do sul; e aquele que entrar pelo caminho da porta do sul sairá pelo caminho da porta do norte; não tornará pelo caminho da porta por onde entrou, mas sairá pela outra que está oposta.',
        'E o príncipe entrará no meio deles; quando eles entrarem e, saindo eles, sairão todos.',
        'E nas festas e nas solenidades a oferta de alimentos será um efa para o bezerro, e um efa para o carneiro, mas para os cordeiros o que puder dar; e de azeite um him para um efa.',
        'E, quando o príncipe fizer oferta voluntária de holocaustos, ou de sacrifícios pacíficos, uma oferta voluntária ao Senhor, então lhe abrirão a porta que dá para o oriente, e fará o seu holocausto e os seus sacrifícios pacíficos, como houver feito no dia de sábado; e sairá, e se fechará a porta depois dele sair.',
        'E prepararás um cordeiro de um ano sem mancha, em holocausto ao Senhor, cada dia; todas as manhãs o prepararás.',
        'E, juntamente com ele prepararás uma oferta de alimentos, todas as manhãs, a sexta parte de um efa, e de azeite a terça parte de um him, para misturar com a flor de farinha; por oferta de alimentos para o Senhor, em estatutos perpétuos e contínuos.',
        'Assim prepararão o cordeiro, e a oferta de alimentos, e o azeite, todas as manhãs, em holocausto contínuo.',
        'Assim diz o Senhor DEUS: Quando o príncipe der um presente a algum de seus filhos, é sua herança, pertencerá a seus filhos; será possessão deles por herança.',
        'Mas, dando ele um presente da sua herança a algum dos seus servos, será deste até ao ano da liberdade; então tornará para o príncipe, porque herança dele é; seus filhos a herdarão.',
        'E o príncipe não tomará nada da herança do povo por opressão, defraudando-os da sua possessão; da sua própria possessão deixará herança a seus filhos, para que o meu povo não seja separado, cada um da sua possessão.',
        'Depois disto me trouxe pela entrada que estava ao lado da porta, às câmaras santas dos sacerdotes, que olhavam para o norte; e eis que ali havia um lugar nos fundos extremos, para o lado do ocidente.',
        'E ele me disse: Este é o lugar onde os sacerdotes cozerão a oferta pela culpa, e a oferta pelo pecado, e onde cozerão a oferta de alimentos, para que não as tragam ao átrio exterior para santificarem o povo.',
        'Então me levou para fora, para o átrio exterior, e me fez passar pelos quatro cantos do átrio; e eis que em cada canto do átrio havia outro átrio.',
        'Nos quatro cantos do átrio havia outros átrios juntos, de quarenta côvados de comprimento e de trinta de largura; estes quatro cantos tinham uma mesma medida.',
        'E havia uma fileira construída ao redor deles, ao redor dos quatro; e havia cozinhas feitas por baixo das fileiras ao redor.',
        'E me disse: Estas são as cozinhas, onde os ministros da casa cozerão o sacrifício do povo.',
      ],
      [
        'Depois disto me fez voltar à porta da casa, e eis que saíam águas por debaixo do umbral da casa para o oriente; porque a face da casa dava para o oriente, e as águas desciam de debaixo, desde o lado direito da casa, ao sul do altar.',
        'E ele me fez sair pelo caminho da porta do norte, e me fez dar uma volta pelo caminho de fora, até à porta exterior, pelo caminho que dá para o oriente e eis que corriam as águas do lado direito.',
        'E saiu aquele homem para o oriente, tendo na mão um cordel de medir; e mediu mil côvados, e me fez passar pelas águas, águas que me davam pelos artelhos.',
        'E mediu mais mil côvados, e me fez passar pelas águas, águas que me davam pelos joelhos; e outra vez mediu mil, e me fez passar pelas águas que me davam pelos lombos.',
        'E mediu mais mil, e era um rio, que eu não podia atravessar, porque as águas eram profundas, águas que se deviam passar a nado, rio pelo qual não se podia passar.',
        'E disse-me: Viste isto, filho do homem? Então levou-me, e me fez voltar para a margem do rio.',
        'E, tendo eu voltado, eis que à margem do rio havia uma grande abundância de árvores, de um e de outro lado.',
        'Então disse-me: Estas águas saem para a região oriental, e descem ao deserto, e entram no mar; e, sendo levadas ao mar, as águas tornar-se-ão saudáveis.',
        'E será que toda a criatura vivente que passar por onde quer que entrarem estes rios viverá; e haverá muitíssimo peixe, porque lá chegarão estas águas, e serão saudáveis, e viverá tudo por onde quer que entrar este rio.',
        'Será também que os pescadores estarão em pé junto dele; desde En-Gedi até En-Eglaim haverá lugar para estender as redes; o seu peixe, segundo a sua espécie, será como o peixe do mar grande, em multidão excessiva.',
        'Mas os seus charcos e os seus pântanos não tornar-se-ão saudáveis; serão deixados para sal.',
        'E junto ao rio, à sua margem, de um e de outro lado, nascerá toda a sorte de árvore que dá fruto para se comer; não cairá a sua folha, nem acabará o seu fruto; nos seus meses produzirá novos frutos, porque as suas águas saem do santuário; e o seu fruto servirá de comida e a sua folha de remédio.',
        'Assim diz o Senhor DEUS: Este será o termo conforme o qual repartireis a terra em herança, segundo as doze tribos de Israel; José terá duas partes.',
        'E vós a herdareis, tanto um como o outro; terra sobre a qual levantei a minha mão, para dá-la a vossos pais; assim esta mesma terra vos cairá a vós em herança.',
        'E este será o termo da terra; do lado do norte, desde o mar grande, caminho de Hetlom, até à entrada de Zedade;',
        'Hamate, Berota, Sibraim, que estão entre o termo de Damasco e o termo de Hamate; Hazer-Haticom, que está junto ao termo de Haurã.',
        'E o termo será desde o mar até Hazar-Enom, o termo de Damasco, e na direção do norte, para o norte, está o termo de Hamate. Este será o lado do norte.',
        'E o lado do oriente, entre Haurã, e Damasco, e Gileade, e a terra de Israel será o Jordão; desde o termo do norte até ao mar do oriente medireis. Este será o lado do oriente.',
        'E o lado do sul, para o sul, será desde Tamar até às águas da contenda de Cades, junto ao ribeiro, até ao mar grande. Este será o lado do sul.',
        'E o lado do ocidente será o mar grande, desde o termo do sul até a entrada de Hamate. Este será o lado do ocidente.',
        'Repartireis, pois, esta terra entre vós, segundo as tribos de Israel.',
        'Será, porém, que a sorteareis para vossa herança, e para a dos estrangeiros que habitam no meio de vós, que gerarão filhos no meio de vós; e vos serão como naturais entre os filhos de Israel; convosco entrarão em herança, no meio das tribos de Israel.',
        'E será que na tribo em que habitar o estrangeiro, ali lhe dareis a sua herança, diz o Senhor DEUS.',
      ],
      [
        'E estes são os nomes das tribos: desde o extremo norte, ao longo do caminho de Hetlom, indo para Hamate, até Hazar-Enom, termo de Damasco para o norte, ao pé de Hamate, terá Dã uma parte, desde o lado oriental até o ocidental.',
        'E junto ao termo de Dã, desde o lado oriental até o ocidental, Aser terá uma porção.',
        'E junto ao termo de Aser, desde o lado oriental até o ocidental, Naftali, uma porção.',
        'E junto ao termo de Naftali, desde o lado oriental até o lado ocidental, Manassés, uma porção.',
        'E junto ao termo de Manassés, desde o lado oriental até o lado ocidental, Efraim, uma porção.',
        'E junto ao termo de Efraim, desde o lado oriental até o lado ocidental, Rúben, uma porção.',
        'E junto ao termo de Rúben, desde o lado oriental até o lado ocidental, Judá, uma porção.',
        'E junto ao termo de Judá, desde o lado oriental até o lado ocidental, será a oferta que haveis de fazer de vinte e cinco mil canas de largura, e de comprimento de cada uma das porções, desde o lado oriental até o lado ocidental; e o santuário estará no meio dela.',
        'A oferta que haveis de oferecer ao Senhor será do comprimento de vinte e cinco mil canas, e da largura de dez mil.',
        'E ali será a oferta santa para os sacerdotes, medindo para o norte vinte e cinco mil canas de comprimento, e para o ocidente dez mil de largura, e para o oriente dez mil de largura, e para o sul vinte e cinco mil de comprimento; e o santuário do Senhor estará no meio dela.',
        'E será para os sacerdotes santificados dentre os filhos de Zadoque, que guardaram a minha ordenança, que não se desviaram, quando os filhos de Israel se extraviaram, como se extraviaram os outros levitas.',
        'E eles terão uma oferta, da oferta da terra, lugar santíssimo, junto ao limite dos levitas.',
        'E os levitas terão, consoante ao termo dos sacerdotes, vinte e cinco mil canas de comprimento, e de largura dez mil; todo o comprimento será vinte e cinco mil, e a largura dez mil.',
        'E não venderão disto, nem trocarão, nem transferirão as primícias da terra, porque é santidade ao Senhor.',
        'Mas as cinco mil canas, as que restaram da largura, diante das vinte e cinco mil, ficarão para uso comum, para a cidade, para habitação e para arrabaldes; e a cidade estará no meio delas.',
        'E estas serão as suas medidas: o lado do norte de quatro mil e quinhentas canas, o lado do sul de quatro mil e quinhentas, o lado oriental de quatro mil e quinhentas e o lado ocidental de quatro mil e quinhentas.',
        'E os arrabaldes da cidade serão para o norte de duzentas e cinqüenta canas, para o sul de duzentas e cinqüenta, para o oriente de duzentas e cinqüenta e para o ocidente de duzentas e cinqüenta.',
        'E, quanto ao que restou do comprimento, consoante com a santa oferta, será dez mil para o oriente, e dez mil para o ocidente; e corresponderá à santa oferta; e a sua novidade será para sustento daqueles que servem a cidade.',
        'E os que servem à cidade, servi-la-ão dentre todas as tribos de Israel.',
        'Toda a oferta será de vinte e cinco mil canas com mais vinte e cinco mil; em quadrado oferecereis a oferta santa, com a possessão da cidade.',
        'E o que restou será para o príncipe; deste e do outro lado da oferta santa, e da possessão da cidade, diante das vinte e cinco mil canas da oferta, até ao termo do oriente e do ocidente, diante das vinte e cinco mil, até ao termo do ocidente, correspondente às porções, será para o príncipe; e a santa oferta e o santuário da casa estarão no meio dela.',
        'E desde a possessão dos levitas, e desde a possessão da cidade, no meio do que pertencer ao príncipe, entre o termo de Judá, e o termo de Benjamim, será isso para o príncipe.',
        'E, quanto ao restante das tribos, desde o lado oriental até o lado ocidental, Benjamim terá uma porção.',
        'E junto ao termo de Benjamim, desde o lado oriental até o lado ocidental, Simeão terá uma porção.',
        'E junto ao termo de Simeão, desde o lado oriental até o lado ocidental, Issacar terá uma porção.',
        'E junto ao termo de Issacar, desde o lado oriental até o lado ocidental, Zebulom terá uma porção.',
        'E junto ao termo de Zebulom, desde o lado oriental até o lado ocidental, Gade terá uma porção.',
        'E junto ao termo de Gade, ao sul, do lado sul, será o termo desde Tamar até às águas da contenda de Cades, junto ao rio até ao mar grande.',
        'Esta é a terra que sorteareis em herança às tribos de Israel; e estas são as suas porções, diz o Senhor DEUS.',
        'E estas são as saídas da cidade, desde o lado norte: quatro mil e quinhentas canas por medida.',
        'E as portas da cidade serão conforme os nomes das tribos de Israel; três portas para o norte: a porta de Rúben uma, a porta de Judá outra, a porta de Levi outra.',
        'E do lado oriental quatro mil e quinhentas canas, e três portas, a saber: a porta de José uma, a porta de Benjamim outra, a porta de Dã outra.',
        'E do lado sul quatro mil e quinhentas canas por medida, e três portas: a porta de Simeão uma, a porta de Issacar outra, a porta de Zebulom outra.',
        'Do lado ocidental quatro mil e quinhentas canas, e as suas três portas: a porta de Gade uma, a porta de Aser outra, a porta de Naftali outra.',
        'Dezoito mil canas por medida terá ao redor; e o nome da cidade desde aquele dia será: o Senhor está ali.',
      ],
    ],
    livro: 'ezequiel',
  },
  {
    abbrev: 'dn',
    capitulos: [
      [
        'No ano terceiro do reinado de Jeoiaquim, rei de Judá, veio Nabucodonosor, rei de babilônia, a Jerusalém, e a sitiou.',
        'E o Senhor entregou nas suas mãos a Jeoiaquim, rei de Judá, e uma parte dos utensílios da casa de Deus, e ele os levou para a terra de Sinar, para a casa do seu deus, e pôs os utensílios na casa do tesouro do seu deus.',
        'E disse o rei a Aspenaz, chefe dos seus eunucos, que trouxesse alguns dos filhos de Israel, e da linhagem real e dos príncipes,',
        'Jovens em quem não houvesse defeito algum, de boa aparência, e instruídos em toda a sabedoria, e doutos em ciência, e entendidos no conhecimento, e que tivessem habilidade para assistirem no palácio do rei, e que lhes ensinassem as letras e a língua dos caldeus.',
        'E o rei lhes determinou a porção diária, das iguarias do rei, e do vinho que ele bebia, e que assim fossem mantidos por três anos, para que no fim destes pudessem estar diante do rei.',
        'E entre eles se achavam, dos filhos de Judá, Daniel, Hananias, Misael e Azarias;',
        'E o chefe dos eunucos lhes pôs outros nomes, a saber: a Daniel pôs o de Beltessazar, e a Hananias o de Sadraque, e a Misael o de Mesaque, e a Azarias o de Abednego.',
        'E Daniel propôs no seu coração não se contaminar com a porção das iguarias do rei, nem com o vinho que ele bebia; portanto pediu ao chefe dos eunucos que lhe permitisse não se contaminar.',
        'Ora, Deus fez com que Daniel achasse graça e misericórdia diante do chefe dos eunucos.',
        'E disse o chefe dos eunucos a Daniel: Tenho medo do meu senhor, o rei, que determinou a vossa comida e a vossa bebida; pois por que veria ele os vossos rostos mais tristes do que os dos outros jovens da vossa idade? Assim porias em perigo a minha cabeça para com o rei.',
        'Então disse Daniel ao despenseiro a quem o chefe dos eunucos havia constituído sobre Daniel, Hananias, Misael e Azarias:',
        'Experimenta, peço-te, os teus servos dez dias, e que se nos dêem legumes a comer, e água a beber.',
        'Então se examine diante de ti a nossa aparência, e a aparência dos jovens que comem a porção das iguarias do rei; e, conforme vires, procederás para com os teus servos.',
        'E ele consentiu isto, e os experimentou dez dias.',
        'E, ao fim dos dez dias, apareceram os seus semblantes melhores, e eles estavam mais gordos de carne do que todos os jovens que comiam das iguarias do rei.',
        'Assim o despenseiro tirou-lhes a porção das iguarias, e o vinho de que deviam beber, e lhes dava legumes.',
        'Quanto a estes quatro jovens, Deus lhes deu o conhecimento e a inteligência em todas as letras, e sabedoria; mas a Daniel deu entendimento em toda a visão e sonhos.',
        'E ao fim dos dias, em que o rei tinha falado que os trouxessem, o chefe dos eunucos os trouxe diante de Nabucodonosor.',
        'E o rei falou com eles; entre todos eles não foram achados outros tais como Daniel, Hananias, Misael e Azarias; portanto ficaram assistindo diante do rei.',
        'E em toda a matéria de sabedoria e de discernimento, sobre o que o rei lhes perguntou, os achou dez vezes mais doutos do que todos os magos astrólogos que havia em todo o seu reino.',
        'E Daniel permaneceu até ao primeiro ano do rei Ciro.',
      ],
      [
        'E no segundo ano do reinado de Nabucodonosor, Nabucodonosor teve sonhos; e o seu espírito se perturbou, e passou-se-lhe o sono.',
        'Então o rei mandou chamar os magos, os astrólogos, os encantadores e os caldeus, para que declarassem ao rei os seus sonhos; e eles vieram e se apresentaram diante do rei.',
        'E o rei lhes disse: Tive um sonho; e para saber o sonho está perturbado o meu espírito.',
        'E os caldeus disseram ao rei em aramaico: Ó rei, vive eternamente! Dize o sonho a teus servos, e daremos a interpretação.',
        'Respondeu o rei, e disse aos caldeus: O assunto me tem escapado; se não me fizerdes saber o sonho e a sua interpretação, sereis despedaçados, e as vossas casas serão feitas um monturo;',
        'Mas se vós me declarardes o sonho e a sua interpretação, recebereis de mim dádivas, recompensas e grande honra; portanto declarai-me o sonho e a sua interpretação.',
        'Responderam segunda vez, e disseram: Diga o rei o sonho a seus servos, e daremos a sua interpretação.',
        'Respondeu o rei, e disse: Percebo muito bem que vós quereis ganhar tempo; porque vedes que o assunto me tem escapado.',
        'De modo que, se não me fizerdes saber o sonho, uma só sentença será a vossa; pois vós preparastes palavras mentirosas e perversas para as proferirdes na minha presença, até que se mude o tempo; portanto dizei-me o sonho, para que eu entenda que me podeis dar a sua interpretação.',
        'Responderam os caldeus na presença do rei, e disseram: Não há ninguém sobre a terra que possa declarar a palavra ao rei; pois nenhum rei há, grande ou dominador, que requeira coisas semelhantes de algum mago, ou astrólogo, ou caldeu.',
        'Porque o assunto que o rei requer é difícil; e ninguém há que o possa declarar diante do rei, senão os deuses, cuja morada não é com a carne.',
        'Por isso o rei muito se irou e enfureceu; e ordenou que matassem a todos os sábios de babilônia.',
        'E saiu o decreto, segundo o qual deviam ser mortos os sábios; e buscaram a Daniel e aos seus companheiros, para que fossem mortos.',
        'Então Daniel falou avisada e prudentemente a Arioque, capitão da guarda do rei, que tinha saído para matar os sábios de babilônia.',
        'Respondeu, e disse a Arioque, capitão do rei: Por que se apressa tanto o decreto da parte do rei? Então Arioque explicou o caso a Daniel.',
        'E Daniel entrou; e pediu ao rei que lhe desse tempo, para que lhe pudesse dar a interpretação.',
        'Então Daniel foi para a sua casa, e fez saber o caso a Hananias, Misael e Azarias, seus companheiros;',
        'Para que pedissem misericórdia ao Deus do céu, sobre este mistério, a fim de que Daniel e seus companheiros não perecessem, juntamente com o restante dos sábios da Babilônia.',
        'Então foi revelado o mistério a Daniel numa visão de noite; então Daniel louvou o Deus do céu.',
        'Falou Daniel, dizendo: Seja bendito o nome de Deus de eternidade a eternidade, porque dele são a sabedoria e a força;',
        'E ele muda os tempos e as estações; ele remove os reis e estabelece os reis; ele dá sabedoria aos sábios e conhecimento aos entendidos.',
        'Ele revela o profundo e o escondido; conhece o que está em trevas, e com ele mora a luz.',
        'Ó Deus de meus pais, eu te dou graças e te louvo, porque me deste sabedoria e força; e agora me fizeste saber o que te pedimos, porque nos fizeste saber este assunto do rei.',
        'Por isso Daniel foi ter com Arioque, ao qual o rei tinha constituído para matar os sábios de babilônia; entrou, e disse-lhe assim: Não mates os sábios de babilônia; introduze-me na presença do rei, e declararei ao rei a interpretação.',
        'Então Arioque depressa introduziu a Daniel na presença do rei, e disse-lhe assim: Achei um homem dentre os cativos de Judá, o qual fará saber ao rei a interpretação.',
        'Respondeu o rei, e disse a Daniel (cujo nome era Beltessazar): Podes tu fazer-me saber o sonho que tive e a sua interpretação?',
        'Respondeu Daniel na presença do rei, dizendo: O segredo que o rei requer, nem sábios, nem astrólogos, nem magos, nem adivinhos o podem declarar ao rei;',
        'Mas há um Deus no céu, o qual revela os mistérios; ele, pois, fez saber ao rei Nabucodonosor o que há de acontecer nos últimos dias; o teu sonho e as visões da tua cabeça que tiveste na tua cama são estes:',
        'Estando tu, ó rei, na tua cama, subiram os teus pensamentos, acerca do que há de ser depois disto. Aquele, pois, que revela os mistérios te fez saber o que há de ser.',
        'E a mim me foi revelado esse mistério, não porque haja em mim mais sabedoria que em todos os viventes, mas para que a interpretação se fizesse saber ao rei, e para que entendesses os pensamentos do teu coração.',
        'Tu, ó rei, estavas vendo, e eis aqui uma grande estátua; esta estátua, que era imensa, cujo esplendor era excelente, e estava em pé diante de ti; e a sua aparência era terrível.',
        'A cabeça daquela estátua era de ouro fino; o seu peito e os seus braços de prata; o seu ventre e as suas coxas de cobre;',
        'As pernas de ferro; os seus pés em parte de ferro e em parte de barro.',
        'Estavas vendo isto, quando uma pedra foi cortada, sem auxílio de mão, a qual feriu a estátua nos pés de ferro e de barro, e os esmiuçou.',
        'Então foi juntamente esmiuçado o ferro, o barro, o bronze, a prata e o ouro, os quais se fizeram como pragana das eiras do estio, e o vento os levou, e não se achou lugar algum para eles; mas a pedra, que feriu a estátua, se tornou grande monte, e encheu toda a terra.',
        'Este é o sonho; também a sua interpretação diremos na presença do rei.',
        'Tu, ó rei, és rei de reis; a quem o Deus do céu tem dado o reino, o poder, a força, e a glória.',
        'E onde quer que habitem os filhos de homens, na tua mão entregou os animais do campo, e as aves do céu, e fez que reinasse sobre todos eles; tu és a cabeça de ouro.',
        'E depois de ti se levantará outro reino, inferior ao teu; e um terceiro reino, de bronze, o qual dominará sobre toda a terra.',
        'E o quarto reino será forte como ferro; pois, como o ferro, esmiúça e quebra tudo; como o ferro que quebra todas as coisas, assim ele esmiuçará e fará em pedaços.',
        'E, quanto ao que viste dos pés e dos dedos, em parte de barro de oleiro, e em parte de ferro, isso será um reino dividido; contudo haverá nele alguma coisa da firmeza do ferro, pois viste o ferro misturado com barro de lodo.',
        'E como os dedos dos pés eram em parte de ferro e em parte de barro, assim por uma parte o reino será forte, e por outra será frágil.',
        'Quanto ao que viste do ferro misturado com barro de lodo, misturar-se-ão com semente humana, mas não se ligarão um ao outro, assim como o ferro não se mistura com o barro.',
        'Mas, nos dias desses reis, o Deus do céu levantará um reino que não será jamais destruído; e este reino não passará a outro povo; esmiuçará e consumirá todos esses reinos, mas ele mesmo subsistirá para sempre,',
        'Da maneira que viste que do monte foi cortada uma pedra, sem auxílio de mãos, e ela esmiuçou o ferro, o bronze, o barro, a prata e o ouro; o grande Deus fez saber ao rei o que há de ser depois disto. Certo é o sonho, e fiel a sua interpretação.',
        'Então o rei Nabucodonosor caiu sobre a sua face, e adorou a Daniel, e ordenou que lhe oferecessem uma oblação e perfumes suaves.',
        'Respondeu o rei a Daniel, e disse: Certamente o vosso Deus é Deus dos deuses, e o Senhor dos reis e revelador de mistérios, pois pudeste revelar este mistério.',
        'Então o rei engrandeceu a Daniel, e lhe deu muitas e grandes dádivas, e o pôs por governador de toda a província de babilônia, como também o fez chefe dos governadores sobre todos os sábios de babilônia.',
        'E pediu Daniel ao rei, e constituiu ele sobre os negócios da província de babilônia a Sadraque, Mesaque e Abednego; mas Daniel permaneceu na porta do rei.',
      ],
      [
        'O rei Nabucodonosor fez uma estátua de ouro, cuja altura era de sessenta côvados, e a sua largura de seis côvados; levantou-a no campo de Dura, na província de babilônia.',
        'Então o rei Nabucodonosor mandou reunir os príncipes, os prefeitos, os governadores, os conselheiros, os tesoureiros, os juízes, os capitàes, e todos os oficiais das províncias, para que viessem à consagração da estátua que o rei Nabucodonosor tinha levantado.',
        'Então se reuniram os príncipes, os prefeitos e governadores, os capitàes, os juízes, os tesoureiros, os conselheiros, e todos os oficiais das províncias, à consagração da estátua que o rei Nabucodonosor tinha levantado; e estavam em pé diante da imagem que Nabucodonosor tinha levantado.',
        'E o arauto apregoava em alta voz: Ordena-se a vós, ó povos, nações e línguas:',
        'Quando ouvirdes o som da buzina, da flauta, da harpa, da sambuca, do saltério, da gaita de foles, e de toda a espécie de música, prostrar-vos-eis, e adorareis a estátua de ouro que o rei Nabucodonosor tem levantado.',
        'E qualquer que não se prostrar e não a adorar, será na mesma hora lançado dentro da fornalha de fogo ardente.',
        'Portanto, no mesmo instante em que todos os povos ouviram o som da buzina, da flauta, da harpa, da sambuca, do saltério e de toda a espécie de música, prostraram-se todos os povos, nações e línguas, e adoraram a estátua de ouro que o rei Nabucodonosor tinha levantado.',
        'Por isso, no mesmo instante chegaram perto alguns caldeus, e acusaram os judeus.',
        'E responderam, dizendo ao rei Nabucodonosor: Ó rei, vive eternamente!',
        'Tu, ó rei, fizeste um decreto, pelo qual todo homem que ouvisse o som da buzina, da flauta, da harpa, da sambuca, do saltério, e da gaita de foles, e de toda a espécie de música, se prostrasse e adorasse a estátua de ouro;',
        'E, qualquer que não se prostrasse e adorasse, seria lançado dentro da fornalha de fogo ardente.',
        'Há uns homens judeus, os quais constituíste sobre os negócios da província de babilônia: Sadraque, Mesaque e Abednego; estes homens, ó rei, não fizeram caso de ti; a teus deuses não servem, nem adoram a estátua de ouro que levantaste.',
        'Então Nabucodonosor, com ira e furor, mandou trazer a Sadraque, Mesaque e Abednego. E trouxeram a estes homens perante o rei.',
        'Falou Nabucodonosor, e lhes disse: É de propósito, ó Sadraque, Mesaque e Abednego, que vós não servis a meus deuses nem adorais a estátua de ouro que levantei?',
        'Agora, pois, se estais prontos, quando ouvirdes o som da buzina, da flauta, da harpa, da sambuca, do saltério, da gaita de foles, e de toda a espécie de música, para vos prostrardes e adorardes a estátua que fiz, bom é; mas, se não a adorardes, sereis lançados, na mesma hora, dentro da fornalha de fogo ardente. E quem é o Deus que vos poderá livrar das minhas mãos?',
        'Responderam Sadraque, Mesaque e Abednego, e disseram ao rei Nabucodonosor: Não necessitamos de te responder sobre este negócio.',
        'Eis que o nosso Deus, a quem nós servimos, é que nos pode livrar; ele nos livrará da fornalha de fogo ardente, e da tua mão, ó rei.',
        'E, se não, fica sabendo ó rei, que não serviremos a teus deuses nem adoraremos a estátua de ouro que levantaste.',
        'Então Nabucodonosor se encheu de furor, e mudou-se o aspecto do seu semblante contra Sadraque, Mesaque e Abednego; falou, e ordenou que a fornalha se aquecesse sete vezes mais do que se costumava aquecer.',
        'E ordenou aos homens mais poderosos, que estavam no seu exército, que atassem a Sadraque, Mesaque e Abednego, para lançá-los na fornalha de fogo ardente.',
        'Então estes homens foram atados, vestidos com as suas capas, suas túnicas, e seus chapéus, e demais roupas, e foram lançados dentro da fornalha de fogo ardente.',
        'E, porque a palavra do rei era urgente, e a fornalha estava sobremaneira quente, a chama do fogo matou aqueles homens que carregaram a Sadraque, Mesaque, e Abednego.',
        'E estes três homens, Sadraque, Mesaque e Abednego, caíram atados dentro da fornalha de fogo ardente.',
        'Então o rei Nabucodonosor se espantou, e se levantou depressa; falou, dizendo aos seus conselheiros: Não lançamos nós, dentro do fogo, três homens atados? Responderam e disseram ao rei: É verdade, ó rei.',
        'Respondeu, dizendo: Eu, porém, vejo quatro homens soltos, que andam passeando dentro do fogo, sem sofrer nenhum dano; e o aspecto do quarto é semelhante ao Filho de Deus.',
        'Então chegando-se Nabucodonosor à porta da fornalha de fogo ardente, falou, dizendo: Sadraque, Mesaque e Abednego, servos do Deus Altíssimo, saí e vinde! Então Sadraque, Mesaque e Abednego saíram do meio do fogo.',
        'E reuniram-se os príncipes, os capitàes, os governadores e os conselheiros do rei e, contemplando estes homens, viram que o fogo não tinha tido poder algum sobre os seus corpos; nem um só cabelo da sua cabeça se tinha queimado, nem as suas capas se mudaram, nem cheiro de fogo tinha passado sobre eles.',
        'Falou Nabucodonosor, dizendo: Bendito seja o Deus de Sadraque, Mesaque e Abednego, que enviou o seu anjo, e livrou os seus servos, que confiaram nele, pois violaram a palavra do rei, preferindo entregar os seus corpos, para que não servissem nem adorassem algum outro deus, senão o seu Deus.',
        'Por mim, pois, é feito um decreto, pelo qual todo o povo, e nação e língua que disser blasfêmia contra o Deus de Sadraque, Mesaque e Abednego, seja despedaçado, e as suas casas sejam feitas um monturo; porquanto não há outro Deus que possa livrar como este.',
        'Então o rei fez prosperar a Sadraque, Mesaque e Abednego, na província de babilônia.',
      ],
      [
        'Nabucodonosor rei, a todos os povos, nações e línguas, que moram em toda a terra: Paz vos seja multiplicada.',
        'Pareceu-me bem fazer conhecidos os sinais e maravilhas que Deus, o Altíssimo, tem feito para comigo.',
        'Quão grandes são os seus sinais, e quão poderosas as suas maravilhas! O seu reino é um reino sempiterno, e o seu domínio de geração em geração.',
        'Eu, Nabucodonosor, estava sossegado em minha casa, e próspero no meu palácio.',
        'Tive um sonho, que me espantou; e estando eu na minha cama, as imaginações e as visões da minha cabeça me turbaram.',
        'Por isso expedi um decreto, para que fossem introduzidos à minha presença todos os sábios de babilônia, para que me fizessem saber a interpretação do sonho.',
        'Então entraram os magos, os astrólogos, os caldeus e os adivinhadores, e eu contei o sonho diante deles; mas não me fizeram saber a sua interpretação.',
        'Mas por fim entrou na minha presença Daniel, cujo nome é Beltessazar, segundo o nome do meu deus, e no qual há o espírito dos deuses santos; e eu lhe contei o sonho, dizendo:',
        'Beltessazar, mestre dos magos, pois eu sei que há em ti o espírito dos deuses santos, e nenhum mistério te é difícil, dize-me as visões do meu sonho que tive e a sua interpretação.',
        'Eis, pois, as visões da minha cabeça, estando eu na minha cama: Eu estava assim olhando, e vi uma árvore no meio da terra, cuja altura era grande;',
        'Crescia esta árvore, e se fazia forte, de maneira que a sua altura chegava até ao céu; e era vista até aos confins da terra.',
        'A sua folhagem era formosa, e o seu fruto abundante, e havia nela sustento para todos; debaixo dela os animais do campo achavam sombra, e as aves do céu faziam morada nos seus ramos, e toda a carne se mantinha dela.',
        'Estava vendo isso nas visões da minha cabeça, estando eu na minha cama; e eis que um vigia, um santo, descia do céu,',
        'Clamando fortemente, e dizendo assim: Derrubai a árvore, e cortai-lhe os ramos, sacudi as suas folhas, espalhai o seu fruto; afugentem-se os animais de debaixo dela, e as aves dos seus ramos.',
        'Mas deixai na terra o tronco com as suas raízes, atada com cadeias de ferro e de bronze, na erva do campo; e seja molhado do orvalho do céu, e seja a sua porção com os animais na erva da terra;',
        'Seja mudado o seu coração, para que não seja mais coração de homem, e lhe seja dado coração de animal; e passem sobre ele sete tempos.',
        'Esta sentença é por decreto dos vigias, e esta ordem por mandado dos santos, a fim de que conheçam os viventes que o Altíssimo tem domínio sobre o reino dos homens, e o dá a quem quer, e até ao mais humilde dos homens constitui sobre ele.',
        'Este sonho eu, rei Nabucodonosor vi. Tu, pois, Beltessazar, dize a interpretação, porque todos os sábios do meu reino não puderam fazer-me saber a sua interpretação, mas tu podes; pois há em ti o espírito dos deuses santos.',
        'Então Daniel, cujo nome era Beltessazar, esteve atônito por uma hora, e os seus pensamentos o turbavam; falou, pois, o rei, dizendo: Beltessazar, não te espante o sonho, nem a sua interpretação. Respondeu Beltessazar, dizendo: Senhor meu, seja o sonho contra os que te têm ódio, e a sua interpretação aos teus inimigos.',
        'A árvore que viste, que cresceu, e se fez forte, cuja altura chegava até ao céu, e que foi vista por toda a terra;',
        'Cujas folhas eram formosas, e o seu fruto abundante, e em que para todos havia sustento, debaixo da qual moravam os animais do campo, e em cujos ramos habitavam as aves do céu;',
        'És tu, ó rei, que cresceste, e te fizeste forte; a tua grandeza cresceu, e chegou até ao céu, e o teu domínio até à extremidade da terra.',
        'E quanto ao que viu o rei, um vigia, um santo, que descia do céu, e dizia: Cortai a árvore, e destruí-a, mas o tronco com as suas raízes deixai na terra, e atada com cadeias de ferro e de bronze, na erva do campo; e seja molhado do orvalho do céu, e a sua porção seja com os animais do campo, até que passem sobre ele sete tempos;',
        'Esta é a interpretação, ó rei; e este é o decreto do Altíssimo, que virá sobre o rei, meu senhor:',
        'Serás tirado dentre os homens, e a tua morada será com os animais do campo, e te farão comer erva como os bois, e serás molhado do orvalho do céu; e passar-se-ão sete tempos por cima de ti; até que conheças que o Altíssimo tem domínio sobre o reino dos homens, e o dá a quem quer.',
        'E quanto ao que foi falado, que deixassem o tronco com as raízes da árvore, o teu reino voltará para ti, depois que tiveres conhecido que o céu reina.',
        'Portanto, ó rei, aceita o meu conselho, e põe fim aos teus pecados, praticando a justiça, e às tuas iniqüidades, usando de misericórdia com os pobres, pois, talvez se prolongue a tua tranqüilidade.',
        'Todas estas coisas vieram sobre o rei Nabucodonosor.',
        'Ao fim de doze meses, quando passeava no palácio real de babilônia,',
        'Falou o rei, dizendo: Não é esta a grande babilônia que eu edifiquei para a casa real, com a força do meu poder, e para glória da minha magnificência?',
        'Ainda estava a palavra na boca do rei, quando caiu uma voz do céu: A ti se diz, ó rei Nabucodonosor: Passou de ti o reino.',
        'E serás tirado dentre os homens, e a tua morada será com os animais do campo; far-te-ão comer erva como os bois, e passar-se-ão sete tempos sobre ti, até que conheças que o Altíssimo domina sobre o reino dos homens, e o dá a quem quer.',
        'Na mesma hora se cumpriu a palavra sobre Nabucodonosor, e foi tirado dentre os homens, e comia erva como os bois, e o seu corpo foi molhado do orvalho do céu, até que lhe cresceu pêlo, como as penas da águia, e as suas unhas como as das aves.',
        'Mas ao fim daqueles dias eu, Nabucodonosor, levantei os meus olhos ao céu, e tornou-me a vir o entendimento, e eu bendisse o Altíssimo, e louvei e glorifiquei ao que vive para sempre, cujo domínio é um domínio sempiterno, e cujo reino é de geração em geração.',
        'E todos os moradores da terra são reputados em nada, e segundo a sua vontade ele opera com o exército do céu e os moradores da terra; não há quem possa estorvar a sua mão, e lhe diga: Que fazes?',
        'No mesmo tempo tornou a mim o meu entendimento, e para a dignidade do meu reino tornou-me a vir a minha majestade e o meu resplendor; e buscaram-me os meus conselheiros e os meus senhores; e fui restabelecido no meu reino, e a minha glória foi aumentada.',
        'Agora, pois, eu, Nabucodonosor, louvo, exalço e glorifico ao Rei do céu; porque todas as suas obras são verdade, e os seus caminhos juízo, e pode humilhar aos que andam na soberba.',
      ],
      [
        'O rei Belsazar deu um grande banquete a mil dos seus senhores, e bebeu vinho na presença dos mil.',
        'Havendo Belsazar provado o vinho, mandou trazer os vasos de ouro e de prata, que Nabucodonosor, seu pai, tinha tirado do templo que estava em Jerusalém, para que bebessem neles o rei, os seus príncipes, as suas mulheres e concubinas.',
        'Então trouxeram os vasos de ouro, que foram tirados do templo da casa de Deus, que estava em Jerusalém, e beberam neles o rei, os seus príncipes, as suas mulheres e concubinas.',
        'Beberam o vinho, e deram louvores aos deuses de ouro, de prata, de bronze, de ferro, de madeira, e de pedra.',
        'Na mesma hora apareceram uns dedos de mão de homem, e escreviam, defronte do castiçal, na caiadura da parede do palácio real; e o rei via a parte da mão que estava escrevendo.',
        'Mudou-se então o semblante do rei, e os seus pensamentos o turbaram; as juntas dos seus lombos se relaxaram, e os seus joelhos batiam um no outro.',
        'E gritou o rei com força, que se introduzissem os astrólogos, os caldeus e os adivinhadores; e falou o rei, dizendo aos sábios de babilônia: Qualquer que ler este escrito, e me declarar a sua interpretação, será vestido de púrpura, e trará uma cadeia de ouro ao pescoço e, no reino, será o terceiro governante.',
        'Então entraram todos os sábios do rei; mas não puderam ler o escrito, nem fazer saber ao rei a sua interpretação.',
        'Então o rei Belsazar perturbou-se muito, e mudou-se-lhe o semblante; e os seus senhores estavam sobressaltados.',
        'A rainha, por causa das palavras do rei e dos seus senhores, entrou na casa do banquete, e respondeu, dizendo: Ó rei, vive para sempre! Não te perturbem os teus pensamentos, nem se mude o teu semblante.',
        'Há no teu reino um homem, no qual há o espírito dos deuses santos; e nos dias de teu pai se achou nele luz, e inteligência, e sabedoria, como a sabedoria dos deuses; e teu pai, o rei Nabucodonosor, sim, teu pai, o rei, o constituiu mestre dos magos, dos astrólogos, dos caldeus e dos adivinhadores;',
        'Porquanto se achou neste Daniel um espírito excelente, e conhecimento, e entendimento, interpretando sonhos e explicando enigmas, e resolvendo dúvidas, ao qual o rei pôs o nome de Beltessazar. Chame-se, pois, agora Daniel, e ele dará a interpretação.',
        'Então Daniel foi introduzido à presença do rei. Falou o rei, dizendo a Daniel: És tu aquele Daniel, um dos filhos dos cativos de Judá, que o rei, meu pai, trouxe de Judá?',
        'Tenho ouvido dizer a teu respeito que o espírito dos deuses está em ti, e que em ti se acham a luz, e o entendimento e a excelente sabedoria.',
        'Agora mesmo foram introduzidos à minha presença os sábios e os astrólogos, para lerem este escrito, e me fazerem saber a sua interpretação; mas não puderam dar a interpretação destas palavras.',
        'Eu, porém, tenho ouvido dizer de ti que podes dar interpretação e resolver dúvidas. Agora, se puderes ler este escrito, e fazer-me saber a sua interpretação, serás vestido de púrpura, e terás cadeia de ouro ao pescoço e no reino serás o terceiro governante.',
        'Então respondeu Daniel, e disse na presença do rei: As tuas dádivas fiquem contigo, e dá os teus prêmios a outro; contudo lerei ao rei o escrito, e far-lhe-ei saber a interpretação.',
        'Ó rei! Deus, o Altíssimo, deu a Nabucodonosor, teu pai, o reino, e a grandeza, e a glória, e a majestade.',
        'E por causa da grandeza, que lhe deu, todos os povos, nações e línguas tremiam e temiam diante dele; a quem queria matava, e a quem queria conservava em vida; e a quem queria engrandecia, e a quem queria abatia.',
        'Mas quando o seu coração se exaltou, e o seu espírito se endureceu em soberba, foi derrubado do seu trono real, e passou dele a sua glória.',
        'E foi tirado dentre os filhos dos homens, e o seu coração foi feito semelhante ao dos animais, e a sua morada foi com os jumentos monteses; fizeram-no comer a erva como os bois, e do orvalho do céu foi molhado o seu corpo, até que conheceu que Deus, o Altíssimo, tem domínio sobre o reino dos homens, e a quem quer constitui sobre ele.',
        'E tu, Belsazar, que és seu filho, não humilhaste o teu coração, ainda que soubeste tudo isto.',
        'E te levantaste contra o Senhor do céu, pois foram trazidos à tua presença os vasos da casa dele, e tu, os teus senhores, as tuas mulheres e as tuas concubinas, bebestes vinho neles; além disso, deste louvores aos deuses de prata, de ouro, de bronze, de ferro, de madeira e de pedra, que não vêem, não ouvem, nem sabem; mas a Deus, em cuja mão está a tua vida, e de quem são todos os teus caminhos, a ele não glorificaste.',
        'Então dele foi enviada aquela parte da mão, que escreveu este escrito.',
        'Este, pois, é o escrito que se escreveu: MENE, MENE, TEQUEL, UFARSIM.',
        'Esta é a interpretação daquilo: MENE: Contou Deus o teu reino, e o acabou.',
        'TEQUEL: Pesado foste na balança, e foste achado em falta.',
        'PERES: Dividido foi o teu reino, e dado aos medos e aos persas.',
        'Então mandou Belsazar que vestissem a Daniel de púrpura, e que lhe pusessem uma cadeia de ouro ao pescoço, e proclamassem a respeito dele que havia de ser o terceiro no governo do seu reino.',
        'Naquela noite foi morto Belsazar, rei dos caldeus.',
        'E Dario, o medo, ocupou o reino, sendo da idade de sessenta e dois anos.',
      ],
      [
        'E pareceu bem a Dario constituir sobre o reino cento e vinte príncipes, que estivessem sobre todo o reino;',
        'E sobre eles três presidentes, dos quais Daniel era um, aos quais estes príncipes dessem conta, para que o rei não sofresse dano.',
        'Então o mesmo Daniel sobrepujou a estes presidentes e príncipes; porque nele havia um espírito excelente; e o rei pensava constituí-lo sobre todo o reino.',
        'Então os presidentes e os príncipes procuravam achar ocasião contra Daniel a respeito do reino; mas não podiam achar ocasião ou culpa alguma; porque ele era fiel, e não se achava nele nenhum erro nem culpa.',
        'Então estes homens disseram: Nunca acharemos ocasião alguma contra este Daniel, se não a acharmos contra ele na lei do seu Deus.',
        'Então estes presidentes e príncipes foram juntos ao rei, e disseram-lhe assim: Ó rei Dario, vive para sempre!',
        'Todos os presidentes do reino, os capitàes e príncipes, conselheiros e governadores, concordaram em promulgar um edito real e confirmar a proibição que qualquer que, por espaço de trinta dias, fizer uma petição a qualquer deus, ou a qualquer homem, e não a ti, ó rei, seja lançado na cova dos leões.',
        'Agora, pois, ó rei, confirma a proibição, e assina o edito, para que não seja mudado, conforme a lei dos medos e dos persas, que não se pode revogar.',
        'Por esta razão o rei Dario assinou o edito e a proibição.',
        'Daniel, pois, quando soube que o edito estava assinado, entrou em sua casa (ora havia no seu quarto janelas abertas do lado de Jerusalém), e três vezes no dia se punha de joelhos, e orava, e dava graças diante do seu Deus, como também antes costumava fazer.',
        'Então aqueles homens foram juntos, e acharam a Daniel orando e suplicando diante do seu Deus.',
        'Então se apresentaram ao rei e, a respeito do edito real, disseram-lhe: Porventura não assinaste o edito, pelo qual todo o homem que fizesse uma petição a qualquer deus, ou a qualquer homem, por espaço de trinta dias, e não a ti, ó rei, fosse lançado na cova dos leões? Respondeu o rei, dizendo: Esta palavra é certa, conforme a lei dos medos e dos persas, que não se pode revogar.',
        'Então responderam ao rei, dizendo-lhe: Daniel, que é dos filhos dos cativos de Judá, não tem feito caso de ti, ó rei, nem do edito que assinaste, antes três vezes por dia faz a sua oração.',
        'Ouvindo então o rei essas palavras, ficou muito penalizado, e a favor de Daniel propôs dentro do seu coração livrá-lo; e até ao pôr do sol trabalhou para salvá-lo.',
        'Então aqueles homens foram juntos ao rei, e disseram-lhe: Sabe, ó rei, que é lei dos medos e dos persas que nenhum edito ou decreto, que o rei estabeleça, se pode mudar.',
        'Então o rei ordenou que trouxessem a Daniel, e lançaram-no na cova dos leões. E, falando o rei, disse a Daniel: O teu Deus, a quem tu continuamente serves, ele te livrará.',
        'E foi trazida uma pedra e posta sobre a boca da cova; e o rei a selou com o seu anel e com o anel dos seus senhores, para que não se mudasse a sentença acerca de Daniel.',
        'Então o rei se dirigiu para o seu palácio, e passou a noite em jejum, e não deixou trazer à sua presença instrumentos de música; e fugiu dele o sono.',
        'Pela manhã, ao romper do dia, levantou-se o rei, e foi com pressa à cova dos leões.',
        'E, chegando-se à cova, chamou por Daniel com voz triste; e disse o rei a Daniel: Daniel, servo do Deus vivo, dar-se-ia o caso que o teu Deus, a quem tu continuamente serves, tenha podido livrar-te dos leões?',
        'Então Daniel falou ao rei: Ó rei, vive para sempre!',
        'O meu Deus enviou o seu anjo, e fechou a boca dos leões, para que não me fizessem dano, porque foi achada em mim inocência diante dele; e também contra ti, ó rei, não tenho cometido delito algum.',
        'Então o rei muito se alegrou em si mesmo, e mandou tirar a Daniel da cova. Assim foi tirado Daniel da cova, e nenhum dano se achou nele, porque crera no seu Deus.',
        'E ordenou o rei, e foram trazidos aqueles homens que tinham acusado a Daniel, e foram lançados na cova dos leões, eles, seus filhos e suas mulheres; e ainda não tinham chegado ao fundo da cova quando os leões se apoderaram deles, e lhes esmigalharam todos os ossos.',
        'Então o rei Dario escreveu a todos os povos, nações e línguas que moram em toda a terra: A paz vos seja multiplicada.',
        'Da minha parte é feito um decreto, pelo qual em todo o domínio do meu reino os homens tremam e temam perante o Deus de Daniel; porque ele é o Deus vivo e que permanece para sempre, e o seu reino não se pode destruir, e o seu domínio durará até o fim.',
        'Ele salva, livra, e opera sinais e maravilhas no céu e na terra; ele salvou e livrou Daniel do poder dos leões.',
        'Este Daniel, pois, prosperou no reinado de Dario, e no reinado de Ciro, o persa.',
      ],
      [
        'No primeiro ano de Belsazar, rei de babilônia, teve Daniel um sonho e visões da sua cabeça quando estava na sua cama; escreveu logo o sonho, e relatou a suma das coisas.',
        'Falou Daniel, e disse: Eu estava olhando na minha visão da noite, e eis que os quatro ventos do céu agitavam o mar grande.',
        'E quatro animais grandes, diferentes uns dos outros, subiam do mar.',
        'O primeiro era como leão, e tinha asas de águia; enquanto eu olhava, foram-lhe arrancadas as asas, e foi levantado da terra, e posto em pé como um homem, e foi-lhe dado um coração de homem.',
        'Continuei olhando, e eis aqui o segundo animal, semelhante a um urso, o qual se levantou de um lado, tendo na boca três costelas entre os seus dentes; e foi-lhe dito assim: Levanta-te, devora muita carne.',
        'Depois disto, eu continuei olhando, e eis aqui outro, semelhante a um leopardo, e tinha quatro asas de ave nas suas costas; tinha também este animal quatro cabeças, e foi-lhe dado domínio.',
        'Depois disto eu continuei olhando nas visões da noite, e eis aqui o quarto animal, terrível e espantoso, e muito forte, o qual tinha dentes grandes de ferro; ele devorava e fazia em pedaços, e pisava aos pés o que sobejava; era diferente de todos os animais que apareceram antes dele, e tinha dez chifres.',
        'Estando eu a considerar os chifres, eis que, entre eles subiu outro chifre pequeno, diante do qual três dos primeiros chifres foram arrancados; e eis que neste chifre havia olhos, como os de homem, e uma boca que falava grandes coisas.',
        'Eu continuei olhando, até que foram postos uns tronos, e um ancião de dias se assentou; a sua veste era branca como a neve, e o cabelo da sua cabeça como a pura lã; e seu trono era de chamas de fogo, e as suas rodas de fogo ardente.',
        'Um rio de fogo manava e saía de diante dele; milhares de milhares o serviam, e milhões de milhões assistiam diante dele; assentou-se o juízo, e abriram-se os livros.',
        'Então estive olhando, por causa da voz das grandes palavras que o chifre proferia; estive olhando até que o animal foi morto, e o seu corpo desfeito, e entregue para ser queimado pelo fogo;',
        'E, quanto aos outros animais, foi-lhes tirado o domínio; todavia foi-lhes prolongada a vida até certo espaço de tempo.',
        'Eu estava olhando nas minhas visões da noite, e eis que vinha nas nuvens do céu um como o filho do homem; e dirigiu-se ao ancião de dias, e o fizeram chegar até ele.',
        'E foi-lhe dado o domínio, e a honra, e o reino, para que todos os povos, nações e línguas o servissem; o seu domínio é um domínio eterno, que não passará, e o seu reino tal, que não será destruído.',
        'Quanto a mim, Daniel, o meu espírito foi abatido dentro do corpo, e as visões da minha cabeça me perturbaram.',
        'Cheguei-me a um dos que estavam perto, e pedi-lhe a verdade acerca de tudo isto. E ele me disse, e fez-me saber a interpretação das coisas.',
        'Estes grandes animais, que são quatro, são quatro reis, que se levantarão da terra.',
        'Mas os santos do Altíssimo receberão o reino, e o possuirão para todo o sempre, e de eternidade em eternidade.',
        'Então tive desejo de conhecer a verdade a respeito do quarto animal, que era diferente de todos os outros, muito terrível, cujos dentes eram de ferro e as suas unhas de bronze; que devorava, fazia em pedaços e pisava aos pés o que sobrava;',
        'E também a respeito dos dez chifres que tinha na cabeça, e do outro que subiu, e diante do qual caíram três, isto é, daquele que tinha olhos, e uma boca que falava grandes coisas, e cujo parecer era mais robusto do que o dos seus companheiros.',
        'Eu olhava, e eis que este chifre fazia guerra contra os santos, e prevaleceu contra eles.',
        'Até que veio o ancião de dias, e fez justiça aos santos do Altíssimo; e chegou o tempo em que os santos possuíram o reino.',
        'Disse assim: O quarto animal será o quarto reino na terra, o qual será diferente de todos os reinos; e devorará toda a terra, e a pisará aos pés, e a fará em pedaços.',
        'E, quanto aos dez chifres, daquele mesmo reino se levantarão dez reis; e depois deles se levantará outro, o qual será diferente dos primeiros, e abaterá a três reis.',
        'E proferirá palavras contra o Altíssimo, e destruirá os santos do Altíssimo, e cuidará em mudar os tempos e a lei; e eles serão entregues na sua mão, por um tempo, e tempos, e a metade de um tempo.',
        'Mas o juízo será estabelecido, e eles tirarão o seu domínio, para o destruir e para o desfazer até ao fim.',
        'E o reino, e o domínio, e a majestade dos reinos debaixo de todo o céu serão dados ao povo dos santos do Altíssimo; o seu reino será um reino eterno, e todos os domínios o servirão, e lhe obedecerão.',
        'Aqui terminou o assunto. Quanto a mim, Daniel, os meus pensamentos muito me perturbaram, e mudou-se em mim o meu semblante; mas guardei o assunto no meu coração.',
      ],
      [
        'No ano terceiro do reinado do rei Belsazar apareceu-me uma visão, a mim, Daniel, depois daquela que me apareceu no princípio.',
        'E vi na visão; e sucedeu que, quando vi, eu estava na cidadela de Susã, na província de Elão; vi, pois, na visão, que eu estava junto ao rio Ulai.',
        'E levantei os meus olhos, e vi, e eis que um carneiro estava diante do rio, o qual tinha dois chifres; e os dois chifres eram altos, mas um era mais alto do que o outro; e o mais alto subiu por último.',
        'Vi que o carneiro dava marradas para o ocidente, e para o norte e para o sul; e nenhum dos animais lhe podia resistir; nem havia quem pudesse livrar-se da sua mão; e ele fazia conforme a sua vontade, e se engrandecia.',
        'E, estando eu considerando, eis que um bode vinha do ocidente sobre toda a terra, mas sem tocar no chão; e aquele bode tinha um chifre insigne entre os olhos.',
        'E dirigiu-se ao carneiro que tinha os dois chifres, ao qual eu tinha visto em pé diante do rio, e correu contra ele no ímpeto da sua força.',
        'E vi-o chegar perto do carneiro, enfurecido contra ele, e ferindo-o quebrou-lhe os dois chifres, pois não havia força no carneiro para lhe resistir, e o bode o lançou por terra, e o pisou aos pés; não houve quem pudesse livrar o carneiro da sua mão.',
        'E o bode se engrandeceu sobremaneira; mas, estando na sua maior força, aquele grande chifre foi quebrado; e no seu lugar subiram outros quatro também insignes, para os quatro ventos do céu.',
        'E de um deles saiu um chifre muito pequeno, o qual cresceu muito para o sul, e para o oriente, e para a terra formosa.',
        'E se engrandeceu até contra o exército do céu; e a alguns do exército, e das estrelas, lançou por terra, e os pisou.',
        'E se engrandeceu até contra o príncipe do exército; e por ele foi tirado o sacrifício contínuo, e o lugar do seu santuário foi lançado por terra.',
        'E um exército foi dado contra o sacrifício contínuo, por causa da transgressão; e lançou a verdade por terra, e o fez, e prosperou.',
        'Depois ouvi um santo que falava; e disse outro santo àquele que falava: Até quando durará a visão do sacrifício contínuo, e da transgressão assoladora, para que sejam entregues o santuário e o exército, a fim de serem pisados?',
        'E ele me disse: Até duas mil e trezentas tardes e manhãs; e o santuário será purificado.',
        'E aconteceu que, havendo eu, Daniel, tido a visão, procurei o significado, e eis que se apresen- tou diante de mim como que uma semelhança de homem.',
        'E ouvi uma voz de homem entre as margens do Ulai, a qual gritou, e disse: Gabriel, dá a entender a este a visão.',
        'E veio perto de onde eu estava; e, vindo ele, me amedrontei, e caí sobre o meu rosto; mas ele me disse: Entende, filho do homem, porque esta visão acontecerá no fim do tempo.',
        'E, estando ele falando comigo, caí adormecido com o rosto em terra; ele, porém, me tocou, e me fez estar em pé.',
        'E disse: Eis que te farei saber o que há de acontecer no último tempo da ira; pois isso pertence ao tempo determinado do fim.',
        'Aquele carneiro que viste com dois chifres são os reis da Média e da Pérsia,',
        'Mas o bode peludo é o rei da Grécia; e o grande chifre que tinha entre os olhos é o primeiro rei;',
        'O ter sido quebrado, levantando-se quatro em lugar dele, significa que quatro reinos se levantarão da mesma nação, mas não com a força dele.',
        'Mas, no fim do seu reinado, quando acabarem os prevaricadores, se levantará um rei, feroz de semblante, e será entendido em adivinhações.',
        'E se fortalecerá o seu poder, mas não pela sua própria força; e destruirá maravilhosamente, e prosperará, e fará o que lhe aprouver; e destruirá os poderosos e o povo santo.',
        'E pelo seu entendimento também fará prosperar o engano na sua mão; e no seu coração se engrandecerá, e destruirá a muitos que vivem em segurança; e se levantará contra o Príncipe dos príncipes, mas sem mão será quebrado.',
        'E a visão da tarde e da manhã que foi falada, é verdadeira. Tu, porém, cerra a visão, porque se refere a dias muito distantes.',
        'E eu, Daniel, enfraqueci, e estive enfermo alguns dias; então levantei-me e tratei do negócio do rei. E espantei-me acerca da visão, e não havia quem a entendesse.',
      ],
      [
        'No ano primeiro de Dario, filho de Assuero, da linhagem dos medos, o qual foi constituído rei sobre o reino dos caldeus,',
        'No primeiro ano do seu reinado, eu, Daniel, entendi pelos livros que o número dos anos, de que falara o Senhor ao profeta Jeremias, em que haviam de cumprir-se as desolações de Jerusalém, era de setenta anos.',
        'E eu dirigi o meu rosto ao Senhor Deus, para o buscar com oração e súplicas, com jejum, e saco e cinza.',
        'E orei ao Senhor meu Deus, e confessei, e disse: Ah! Senhor! Deus grande e tremendo, que guardas a aliança e a misericórdia para com os que te amam e guardam os teus mandamentos;',
        'Pecamos, e cometemos iniqüidades, e procedemos impiamente, e fomos rebeldes, apartando-nos dos teus mandamentos e dos teus juízos;',
        'E não demos ouvidos aos teus servos, os profetas, que em teu nome falaram aos nossos reis, aos nossos príncipes, e a nossos pais, como também a todo o povo da terra.',
        'A ti, ó Senhor, pertence a justiça, mas a nós a confusão de rosto, como hoje se vê; aos homens de Judá, e aos moradores de Jerusalém, e a todo o Israel, aos de perto e aos de longe, em todas as terras por onde os tens lançado, por causa das suas rebeliões que cometeram contra ti.',
        'Ó Senhor, a nós pertence a confusão de rosto, aos nossos reis, aos nossos príncipes, e a nossos pais, porque pecamos contra ti.',
        'Ao Senhor, nosso Deus, pertencem a misericórdia, e o perdão; pois nos rebelamos contra ele,',
        'E não obedecemos à voz do Senhor, nosso Deus, para andarmos nas suas leis, que nos deu por intermédio de seus servos, os profetas.',
        'Sim, todo o Israel transgrediu a tua lei, desviando-se para não obedecer à tua voz; por isso a maldição e o juramento, que estão escritos na lei de Moisés, servo de Deus, se derramaram sobre nós; porque pecamos contra ele.',
        'E ele confirmou a sua palavra, que falou contra nós, e contra os nossos juízes que nos julgavam, trazendo sobre nós um grande mal; porquanto debaixo de todo o céu nunca se fez como se tem feito em Jerusalém.',
        'Como está escrito na lei de Moisés, todo este mal nos sobreveio; apesar disso, não suplicamos à face do Senhor nosso Deus, para nos convertermos das nossas iniqüidades, e para nos aplicarmos à tua verdade.',
        'Por isso o Senhor vigiou sobre o mal, e o trouxe sobre nós; porque justo é o Senhor, nosso Deus, em todas as suas obras, que fez, pois não obedecemos à sua voz.',
        'Agora, pois, ó Senhor, nosso Deus, que tiraste o teu povo da terra do Egito com mão poderosa, e ganhaste para ti nome, como hoje se vê; temos pecado, temos procedido impiamente.',
        'Ó Senhor, segundo todas as tuas justiças, aparte-se a tua ira e o teu furor da tua cidade de Jerusalém, do teu santo monte; porque por causa dos nossos pecados, e por causa das iniqüidades de nossos pais, tornou-se Jerusalém e o teu povo um opróbrio para todos os que estão em redor de nós.',
        'Agora, pois, ó Deus nosso, ouve a oração do teu servo, e as suas súplicas, e sobre o teu santuário assolado faze resplandecer o teu rosto, por amor do Senhor.',
        'Inclina, ó Deus meu, os teus ouvidos, e ouve; abre os teus olhos, e olha para a nossa desolação, e para a cidade que é chamada pelo teu nome, porque não lançamos as nossas súplicas perante a tua face fiados em nossas justiças, mas em tuas muitas misericórdias.',
        'Ó Senhor, ouve; ó Senhor, perdoa; ó Senhor, atende-nos e age sem tardar; por amor de ti mesmo, ó Deus meu; porque a tua cidade e o teu povo são chamados pelo teu nome.',
        'Estando eu ainda falando e orando, e confessando o meu pecado, e o pecado do meu povo Israel, e lançando a minha súplica perante a face do Senhor, meu Deus, pelo monte santo do meu Deus,',
        'Estando eu, digo, ainda falando na oração, o homem Gabriel, que eu tinha visto na minha visão ao princípio, veio, voando rapidamente, e tocou-me, à hora do sacrifício da tarde.',
        'Ele me instruiu, e falou comigo, dizendo: Daniel, agora saí para fazer-te entender o sentido.',
        'No princípio das tuas súplicas, saiu a ordem, e eu vim, para to declarar, porque és mui amado; considera, pois, a palavra, e entende a visão.',
        'Setenta semanas estão determinadas sobre o teu povo, e sobre a tua santa cidade, para cessar a transgressão, e para dar fim aos pecados, e para expiar a iniqüidade, e trazer a justiça eterna, e selar a visão e a profecia, e para ungir o Santíssimo.',
        'Sabe e entende: desde a saída da ordem para restaurar, e para edificar a Jerusalém, até ao Messias, o Príncipe, haverá sete semanas, e sessenta e duas semanas; as ruas e o muro se reedificarão, mas em tempos angustiosos.',
        'E depois das sessenta e duas semanas será cortado o Messias, mas não para si mesmo; e o povo do príncipe, que há de vir, destruirá a cidade e o santuário, e o seu fim será com uma inundação; e até ao fim haverá guerra; estão determinadas as assolações.',
        'E ele firmará aliança com muitos por uma semana; e na metade da semana fará cessar o sacrifício e a oblação; e sobre a asa das abominações virá o assolador, e isso até à consumação; e o que está determinado será derramado sobre o assolador.',
      ],
      [
        'No terceiro ano de Ciro, rei da Pérsia, foi revelada uma palavra a Daniel, cujo nome era Beltessazar; a palavra era verdadeira e envolvia grande conflito; e ele entendeu esta palavra, e tinha entendimento da visão.',
        'Naqueles dias eu, Daniel, estive triste por três semanas.',
        'Alimento desejável não comi, nem carne nem vinho entraram na minha boca, nem me ungi com ungüento, até que se cumpriram as três semanas.',
        'E no dia vinte e quatro do primeiro mês eu estava à borda do grande rio Hidequel;',
        'E levantei os meus olhos, e olhei, e eis um homem vestido de linho, e os seus lombos cingidos com ouro fino de Ufaz;',
        'E o seu corpo era como berilo, e o seu rosto parecia um relâmpago, e os seus olhos como tochas de fogo, e os seus braços e os seus pés brilhavam como bronze polido; e a voz das suas palavras era como a voz de uma multidão.',
        'E só eu, Daniel, tive aquela visão. Os homens que estavam comigo não a viram; contudo caiu sobre eles um grande temor, e fugiram, escondendo-se.',
        'Fiquei, pois, eu só, a contemplar esta grande visão, e não ficou força em mim; transmudou-se o meu semblante em corrupção, e não tive força alguma.',
        'Contudo ouvi a voz das suas palavras; e, ouvindo o som das suas palavras, eu caí sobre o meu rosto num profundo sono, com o meu rosto em terra.',
        'E eis que certa mão me tocou, e fez com que me movesse sobre os meus joelhos e sobre as palmas das minhas mãos.',
        'E me disse: Daniel, homem muito amado, entende as palavras que vou te dizer, e levanta-te sobre os teus pés, porque a ti sou enviado. E, falando ele comigo esta palavra, levantei-me tremendo.',
        'Então me disse: Não temas, Daniel, porque desde o primeiro dia em que aplicaste o teu coração a compreender e a humilhar-te perante o teu Deus, são ouvidas as tuas palavras; e eu vim por causa das tuas palavras.',
        'Mas o príncipe do reino da Pérsia me resistiu vinte e um dias, e eis que Miguel, um dos primeiros príncipes, veio para ajudar-me, e eu fiquei ali com os reis da Pérsia.',
        'Agora vim, para fazer-te entender o que há de acontecer ao teu povo nos derradeiros dias; porque a visão é ainda para muitos dias.',
        'E, falando ele comigo estas palavras, abaixei o meu rosto para a terra, e emudeci.',
        'E eis que alguém, semelhante aos filhos dos homens, tocou-me os lábios; então abri a minha boca, e falei, dizendo àquele que estava em pé diante de mim: senhor meu, por causa da visão sobrevieram-me dores, e não me ficou força alguma.',
        'Como, pois, pode o servo do meu senhor falar com o meu senhor? Porque, quanto a mim, desde agora não resta força em mim, e nem fôlego ficou em mim.',
        'E aquele, que tinha aparência de um homem, tocou-me outra vez, e fortaleceu-me.',
        'E disse: Não temas, homem muito amado, paz seja contigo; anima-te, sim, anima-te. E, falando ele comigo, fiquei fortalecido, e disse: Fala, meu senhor, porque me fortaleceste.',
        'E ele disse: Sabes por que eu vim a ti? Agora, pois, tornarei a pelejar contra o príncipe dos persas; e, saindo eu, eis que virá o príncipe da Grécia.',
        'Mas eu te declararei o que está registrado na escritura da verdade; e ninguém há que me anime contra aqueles, senão Miguel, vosso príncipe.',
      ],
      [
        'Eu, pois, no primeiro ano de Dario, o medo, levantei-me para animá-lo e fortalecê-lo.',
        'E agora te declararei a verdade: Eis que ainda três reis estarão na Pérsia, e o quarto acumulará grandes riquezas, mais do que todos; e, tornando-se forte, por suas riquezas, suscitará a todos contra o reino da Grécia.',
        'Depois se levantará um rei valente, que reinará com grande domínio, e fará o que lhe aprouver.',
        'Mas, estando ele em pé, o seu reino será quebrado, e será repartido para os quatro ventos do céu; mas não para a sua posteridade, nem tampouco segundo o seu domínio com que reinou, porque o seu reino será arrancado, e passará a outros que não eles.',
        'E será forte o rei do sul; mas um dos seus príncipes será mais forte do que ele, e reinará poderosamente; seu domínio será grande.',
        'Mas, ao fim de alguns anos, eles se aliarão; e a filha do rei do sul virá ao rei do norte para fazer um tratado; mas ela não reterá a força do seu braço; nem ele persistirá, nem o seu braço, porque ela será entregue, e os que a tiverem trazido, e seu pai, e o que a fortalecia naqueles tempos.',
        'Mas de um renovo das raízes dela um se levantará em seu lugar, e virá com o exército, e entrará na fortaleza do rei do norte, e operará contra eles, e prevalecerá.',
        'Também os seus deuses com as suas imagens de fundição, com os seus objetos preciosos de prata e ouro, levará cativos para o Egito; e por alguns anos ele persistirá contra o rei do norte.',
        'E entrará no reino o rei do sul, e tornará para a sua terra.',
        'Mas seus filhos intervirão e reunirão uma multidão de grandes forças; e virá apressadamente e inundará, e passará adiante; e, voltando levará a guerra até a sua fortaleza.',
        'Então o rei do sul se exasperará, e sairá, e pelejará contra ele, contra o rei do norte; este porá em campo grande multidão, e aquela multidão será entregue na sua mão.',
        'A multidão será tirada e o seu coração se elevará; mas ainda que derrubará muitos milhares, contudo não prevalecerá.',
        'Porque o rei do norte tornará, e porá em campo uma multidão maior do que a primeira, e ao fim dos tempos, isto é, de anos, virá à pressa com grande exército e com muitas riquezas.',
        'E, naqueles tempos, muitos se levantarão contra o rei do sul; e os violentos dentre o teu povo se levantarão para cumprir a visão, mas eles cairão.',
        'E o rei do norte virá, e levantará baluartes, e tomará a cidade forte; e os braços do sul não poderão resistir, nem o seu povo escolhido, pois não haverá força para resistir.',
        'O que, pois, há de vir contra ele fará segundo a sua vontade, e ninguém poderá resistir diante dele; e estará na terra gloriosa, e por sua mão haverá destruição.',
        'E dirigirá o seu rosto, para vir com a potência de todo o seu reino, e com ele os retos, assim ele fará; e lhe dará uma filha das mulheres, para corrompê-la; ela, porém, não subsistirá, nem será para ele.',
        'Depois virará o seu rosto para as ilhas, e tomará muitas; mas um príncipe fará cessar o seu opróbrio contra ele, e ainda fará recair sobre ele o seu opróbrio.',
        'Virará então o seu rosto para as fortalezas da sua própria terra, mas tropeçará, e cairá, e não será achado.',
        'E em seu lugar se levantará quem fará passar um arrecadador pela glória do reino; mas em poucos dias será quebrantado, e isto sem ira e sem batalha.',
        'Depois se levantará em seu lugar um homem vil, ao qual não tinham dado a dignidade real; mas ele virá caladamente, e tomará o reino com engano.',
        'E com os braços de uma inundação serão varridos de diante dele; e serão quebrantados, como também o príncipe da aliança.',
        'E, depois do concerto com ele, usará de engano; e subirá, e se tornará forte com pouca gente.',
        'Virá também caladamente aos lugares mais férteis da província, e fará o que nunca fizeram seus pais, nem os pais de seus pais; repartirá entre eles a presa e os despojos, e os bens, e formará os seus projetos contra as fortalezas, mas por certo tempo.',
        'E suscitará a sua força e a sua coragem contra o rei do sul com um grande exército; e o rei do sul se envolverá na guerra com um grande e mui poderoso exército; mas não subsistirá, porque maquinarão projetos contra ele.',
        'E os que comerem os seus alimentos o destruirão; e o exército dele será arrasado, e cairão muitos mortos.',
        'Também estes dois reis terão o coração atento para fazerem o mal, e a uma mesma mesa falarão a mentira; mas isso não prosperará, porque ainda verá o fim no tempo determinado.',
        'Então tornará para a sua terra com muitos bens, e o seu coração será contra a santa aliança; e fará o que lhe aprouver, e tornará para a sua terra.',
        'No tempo determinado tornará a vir em direção do sul; mas não será na última vez como foi na primeira.',
        'Porque virão contra ele navios de Quitim, que lhe causarão tristeza; e voltará, e se indignará contra a santa aliança, e fará o que lhe aprouver; voltará e atenderá aos que tiverem abandonado a santa aliança.',
        'E braços serão colocados sobre ele, que profanarão o santuário e a fortaleza, e tirarão o sacrifício contínuo, estabelecendo abominação desoladora.',
        'E aos violadores da aliança ele com lisonjas perverterá, mas o povo que conhece ao seu Deus se tornará forte e fará proezas.',
        'E os entendidos entre o povo ensinarão a muitos; todavia cairão pela espada, e pelo fogo, e pelo cativeiro, e pelo roubo, por muitos dias.',
        'E, caindo eles, serão ajudados com pequeno socorro; mas muitos se ajuntarão a eles com lisonjas.',
        'E alguns dos entendidos cairão, para serem provados, purificados, e embranquecidos, até ao fim do tempo, porque será ainda para o tempo determinado.',
        'E este rei fará conforme a sua vontade, e levantar-se-á, e engrandecer-se-á sobre todo deus; e contra o Deus dos deuses falará coisas espantosas, e será próspero, até que a ira se complete; porque aquilo que está determinado será feito.',
        'E não terá respeito ao Deus de seus pais, nem terá respeito ao amor das mulheres, nem a deus algum, porque sobre tudo se engrandecerá.',
        'Mas em seu lugar honrará a um deus das forças; e a um deus a quem seus pais não conheceram honrará com ouro, e com prata, e com pedras preciosas, e com coisas agradáveis.',
        'Com o auxílio de um deus estranho agirá contra as poderosas fortalezas; aos que o reconhecerem multiplicará a honra, e os fará reinar sobre muitos, e repartirá a terra por preço.',
        'E, no fim do tempo, o rei do sul lutará com ele, e o rei do norte se levantará contra ele com carros, e com cavaleiros, e com muitos navios; e entrará nas suas terras e as inundará, e passará.',
        'E entrará na terra gloriosa, e muitos países cairão, mas da sua mão escaparão estes: Edom e Moabe, e os chefes dos filhos de Amom.',
        'E estenderá a sua mão contra os países, e a terra do Egito não escapará.',
        'E apoderar-se-á dos tesouros de ouro e de prata e de todas as coisas preciosas do Egito; e os líbios e os etíopes o seguirão.',
        'Mas os rumores do oriente e do norte o espantarão; e sairá com grande furor, para destruir e extirpar a muitos.',
        'E armará as tendas do seu palácio entre o mar grande e o monte santo e glorioso; mas chegará ao seu fim, e não haverá quem o socorra.',
      ],
      [
        'E naquele tempo se levantará Miguel, o grande príncipe, que se levanta a favor dos filhos do teu povo, e haverá um tempo de angústia, qual nunca houve, desde que houve nação até àquele tempo; mas naquele tempo livrar-se-á o teu povo, todo aquele que for achado escrito no livro.',
        'E muitos dos que dormem no pó da terra ressuscitarão, uns para vida eterna, e outros para vergonha e desprezo eterno.',
        'Os que forem sábios, pois, resplandecerão como o fulgor do firmamento; e os que a muitos ensinam a justiça, como as estrelas sempre e eternamente.',
        'E tu, Daniel, encerra estas palavras e sela este livro, até ao fim do tempo; muitos correrão de uma parte para outra, e o conhecimento se multiplicará.',
        'Então eu, Daniel, olhei, e eis que estavam em pé outros dois, um deste lado, à beira do rio, e o outro do outro lado, à beira do rio.',
        'E ele disse ao homem vestido de linho, que estava sobre as águas do rio: Quando será o fim destas maravilhas?',
        'E ouvi o homem vestido de linho, que estava sobre as águas do rio, o qual levantou ao céu a sua mão direita e a sua mão esquerda, e jurou por aquele que vive eternamente que isso seria para um tempo, tempos e metade do tempo, e quando tiverem acabado de espalhar o poder do povo santo, todas estas coisas serão cumpridas.',
        'Eu, pois, ouvi, mas não entendi; por isso eu disse: Senhor meu, qual será o fim destas coisas?',
        'E ele disse: Vai, Daniel, porque estas palavras estão fechadas e seladas até ao tempo do fim.',
        'Muitos serão purificados, e embranquecidos, e provados; mas os ímpios procederão impiamente, e nenhum dos ímpios entenderá, mas os sábios entenderão.',
        'E desde o tempo em que o sacrifício contínuo for tirado, e posta a abominação desoladora, haverá mil duzentos e noventa dias.',
        'Bem-aventurado o que espera e chega até mil trezentos e trinta e cinco dias.',
        'Tu, porém, vai até ao fim; porque descansarás, e te levantarás na tua herança, no fim dos dias.',
      ],
    ],
    livro: 'daniel',
  },
  {
    abbrev: 'os',
    capitulos: [
      [
        'Palavra do SENHOR, que foi dirigida a Oséias, filho de Beeri, nos dias de Uzias, Jotão, Acaz, Ezequias, reis de Judá, e nos dias de Jeroboão, filho de Joás, rei de Israel.',
        'O princípio da palavra do Senhor por meio de Oséias. Disse, pois, o Senhor a Oséias: Vai, toma uma mulher de prostituições, e filhos de prostituição; porque a terra certamente se prostitui, desviando-se do Senhor.',
        'Foi, pois, e tomou a Gômer, filha de Diblaim, e ela concebeu, e lhe deu um filho.',
        'E disse-lhe o Senhor: Põe-lhe o nome de Jizreel; porque daqui a pouco visitarei o sangue de Jizreel sobre a casa de Jeú, e farei cessar o reino da casa de Israel.',
        'E naquele dia quebrarei o arco de Israel no vale de Jizreel.',
        'E tornou ela a conceber, e deu à luz uma filha. E Deus disse: Põe-lhe o nome de Lo-Ruama; porque eu não tornarei mais a compadecer-me da casa de Israel, mas tudo lhe tirarei.',
        'Mas da casa de Judá me compadecerei, e os salvarei pelo Senhor seu Deus, pois não os salvarei pelo arco, nem pela espada, nem pela guerra, nem pelos cavalos, nem pelos cavaleiros.',
        'E, depois de haver desmamado a Lo-Ruama, concebeu e deu à luz um filho.',
        'E Deus disse: Põe-lhe o nome de Lo-Ami; porque vós não sois meu povo, nem eu serei vosso Deus.',
        'Todavia o número dos filhos de Israel será como a areia do mar, que não pode medir-se nem contar-se; e acontecerá que no lugar onde se lhes dizia: Vós não sois meu povo, se lhes dirá: Vós sois filhos do Deus vivo.',
        'E os filhos de Judá e os filhos de Israel juntos se congregarão, e constituirão sobre si uma só cabeça, e subirão da terra; porque grande será o dia de Jizreel.',
      ],
      [
        'Dizei a vossos irmãos: Ami; e a vossas irmãs: Ruama.',
        'Contendei com vossa mãe, contendei, porque ela não é minha mulher, e eu não sou seu marido; e desvie ela as suas prostituições da sua vista e os seus adultérios de entre os seus seios.',
        'Para que eu não a despoje, ficando ela nua, e a ponha como no dia em que nasceu, e a faça como um deserto, e a torne como uma terra seca, e a mate à sede;',
        'E não me compadeça de seus filhos, porque são filhos de prostituições.',
        'Porque sua mãe se prostituiu; aquela que os concebeu houve-se torpemente, porque diz: Irei atrás de meus amantes, que me dão o meu pão e a minha água, a minha lã e o meu linho, o meu óleo e as minhas bebidas.',
        'Portanto, eis que cercarei o teu caminho com espinhos; e levantarei um muro de sebe, para que ela não ache as suas veredas.',
        'Ela irá atrás de seus amantes, mas não os alcançará; e buscá-los-á, mas não os achará; então dirá: Ir-me-ei, e tornar-me-ei a meu primeiro marido, porque melhor me ia então do que agora.',
        'Ela, pois, não reconhece que eu lhe dei o grão, e o mosto, e o azeite, e que lhe multipliquei a prata e o ouro, que eles usaram para Baal.',
        'Portanto tornarei a tirar o meu grão a seu tempo e o meu mosto no seu tempo determinado; e arrebatarei a minha lã e o meu linho, com que cobriam a sua nudez.',
        'E agora descobrirei a sua vileza diante dos olhos dos seus amantes, e ninguém a livrará da minha mão.',
        'E farei cessar todo o seu gozo, as suas festas, as suas luas novas, e os seus sábados, e todas as suas festividades.',
        'E devastarei a sua vide e a sua figueira, de que ela diz: É esta a minha paga que me deram os meus amantes; eu, pois, farei delas um bosque, e as feras do campo as devorarão.',
        'Castigá-la-ei pelos dias dos Baalins, nos quais lhes queimou incenso, e se adornou dos seus pendentes e das suas jóias, e andou atrás de seus amantes, mas de mim se esqueceu, diz o SENHOR.',
        'Portanto, eis que eu a atrairei, e a levarei para o deserto, e lhe falarei ao coração.',
        'E lhe darei as suas vinhas dali, e o vale de Acor, por porta de esperança; e ali cantará, como nos dias de sua mocidade, e como no dia em que subiu da terra do Egito.',
        'E naquele dia, diz o SENHOR, tu me chamarás: Meu marido; e não mais me chamarás: Meu senhor.',
        'E da sua boca tirarei os nomes dos Baalins, e não mais se lembrará desses nomes.',
        'E naquele dia farei por eles aliança com as feras do campo, e com as aves do céu, e com os répteis da terra; e da terra quebrarei o arco, e a espada, e a guerra, e os farei deitar em segurança.',
        'E desposar-te-ei comigo para sempre; desposar-te-ei comigo em justiça, e em juízo, e em benignidade, e em misericórdias.',
        'E desposar-te-ei comigo em fidelidade, e conhecerás ao Senhor.',
        'E acontecerá naquele dia que eu atenderei, diz o Senhor; eu atenderei aos céus, e estes atenderão à terra.',
        'E a terra atenderá ao trigo, e ao mosto, e ao azeite, e estes atenderão a Jizreel.',
        'E semeá-la-ei para mim na terra, e compadecer-me-ei dela que não obteve misericórdia; e eu direi àquele que não era meu povo: Tu és meu povo; e ele dirá: Tu és meu Deus!',
      ],
      [
        'E o SENHOR me disse: Vai outra vez, ama uma mulher, amada de seu amigo, contudo adúltera, como o SENHOR ama os filhos de Israel, embora eles olhem para outros deuses, e amem os bolos de uvas.',
        'E comprei-a para mim por quinze peças de prata, e um ômer, e meio ômer de cevada;',
        'E ele lhe disse: Tu ficarás comigo muitos dias; não te prostituirás, nem serás de outro homem; assim também eu esperarei por ti.',
        'Porque os filhos de Israel ficarão por muitos dias sem rei, e sem príncipe, e sem sacrifício, e sem estátua, e sem éfode ou terafim.',
        'Depois tornarão os filhos de Israel, e buscarão ao Senhor seu Deus, e a Davi, seu rei; e temerão ao Senhor, e à sua bondade, no fim dos dias.',
      ],
      [
        'Ouvi a palavra do SENHOR, vós filhos de Israel, porque o SENHOR tem uma contenda com os habitantes da terra; porque na terra não há verdade, nem benignidade, nem conhecimento de Deus.',
        'Só permanecem o perjurar, o mentir, o matar, o furtar e o adulterar; fazem violência, um ato sanguinário segue imediatamente a outro.',
        'Por isso a terra se lamentará, e qualquer que morar nela desfalecerá, com os animais do campo e com as aves do céu; e até os peixes do mar serão tirados.',
        'Todavia ninguém contenda, ninguém repreenda, porque o teu povo é como os que contendem com o sacerdote.',
        'Por isso tropeçarás de dia, e o profeta contigo tropeçará de noite; e destruirei a tua mãe.',
        'O meu povo foi destruído, porque lhe faltou o conhecimento; porque tu rejeitaste o conhecimento, também eu te rejeitarei, para que não sejas sacerdote diante de mim; e, visto que te esqueceste da lei do teu Deus, também eu me esquecerei de teus filhos.',
        'Como eles se multiplicaram, assim pecaram contra mim; eu mudarei a sua honra em vergonha.',
        'Comem da oferta pelo pecado do meu povo, e pela transgressão dele têm desejo ardente.',
        'Por isso, como é o povo, assim será o sacerdote; e castigá-lo-ei segundo os seus caminhos, e dar-lhe-ei a recompensa das suas obras.',
        'Comerão, mas não se fartarão; entregar-se-ão à luxúria, mas não se multiplicarão; porque deixaram de atentar ao Senhor.',
        'A luxúria, e o vinho, e o mosto tiram o coração.',
        'O meu povo consulta a sua madeira, e a sua vara lhe responde, porque o espírito da luxúria os engana, e prostituem-se, apartando-se da sujeição do seu Deus.',
        'Sacrificam sobre os cumes dos montes, e queimam incenso sobre os outeiros, debaixo do carvalho, e do álamo, e do olmeiro, porque é boa a sua sombra; por isso vossas filhas se prostituem, e as vossas noras adulteram.',
        'Eu não castigarei vossas filhas, quando se prostituem, nem vossas noras, quando adulteram; porque eles mesmos com as prostitutas se desviam, e com as meretrizes sacrificam; pois o povo que não tem entendimento será transtornado.',
        'Ainda que tu, ó Israel, queiras prostituir-te, contudo não se faça culpado Judá; não venhais a Gilgal, e não subais a Bete-Áven, e não jureis, dizendo: Vive o Senhor.',
        'Porque como uma novilha obstinada se rebelou Israel; agora o Senhor os apascentará como a um cordeiro num lugar espaçoso.',
        'Efraim está entregue aos ídolos; deixa-o.',
        'A sua bebida se foi; lançaram-se à luxúria continuamente; certamente os seus governadores amam a vergonha.',
        'Um vento os envolveu nas suas asas, e envergonhar-se-ão por causa dos seus sacrifícios.',
      ],
      [
        'Ouvi isto, ó sacerdotes, e escutai, ó casa de Israel, e dai ouvidos, ó casa do rei, porque contra vós se dirige este juízo, visto que fostes um laço para Mizpá, e rede estendida sobre o Tabor.',
        'Os revoltos se aprofundaram na matança; mas eu castigarei a todos eles.',
        'Eu conheço a Efraim, e Israel não se esconde de mim; porque agora te tens prostituído, ó Efraim, e Israel se contaminou.',
        'Não querem ordenar as suas ações a fim de voltarem para o seu Deus, porque o espírito das prostituições está no meio deles, e não conhecem ao Senhor.',
        'A soberba de Israel testificará no seu rosto; e Israel e Efraim cairão pela sua injustiça, e Judá cairá juntamente com eles.',
        'Então irão com os seus rebanhos, e com o seu gado, para buscarem ao Senhor, mas não o acharão; ele se retirou deles.',
        'Aleivosamente se houveram contra o Senhor, porque geraram filhos estranhos; agora em um só mês os consumirá com as suas porções.',
        'Tocai a buzina em Gibeá, a trombeta em Ramá; gritai altamente em Bete-Áven; depois de ti, ó Benjamim.',
        'Efraim será para assolação no dia do castigo; entre as tribos de Israel manifestei o que está certo.',
        'Os príncipes de Judá são como os que mudam os limites; derramarei, pois, o meu furor sobre eles como água.',
        'Efraim está oprimido e quebrantado no juízo, porque quis andar após o mandamento dos homens.',
        'Portanto a Efraim serei como a traça, e para a casa de Judá como a podridão.',
        'Quando Efraim viu a sua enfermidade, e Judá a sua chaga, subiu Efraim à Assíria e enviou ao rei Jarebe; mas ele não poderá sarar-vos, nem curar a vossa chaga.',
        'Porque para Efraim serei como um leão, e como um leãozinho à casa de Judá: eu, eu o despedaçarei, e irme-ei embora; arrebatarei, e não haverá quem livre.',
        'Irei e voltarei ao meu lugar, até que se reconheçam culpados e busquem a minha face; estando eles angustiados, de madrugada me buscarão.',
      ],
      [
        'Vinde, e tornemos ao SENHOR, porque ele despedaçou, e nos sarará; feriu, e nos atará a ferida.',
        'Depois de dois dias nos dará a vida; ao terceiro dia nos ressuscitará, e viveremos diante dele.',
        'Então conheçamos, e prossigamos em conhecer ao Senhor; a sua saída, como a alva, é certa; e ele a nós virá como a chuva, como chuva serôdia que rega a terra.',
        'Que te farei, ó Efraim? Que te farei, ó Judá? Porque a vossa benignidade é como a nuvem da manhã e como o orvalho da madrugada, que cedo passa.',
        'Por isso os abati pelos profetas; pelas palavras da minha boca os matei; e os teus juízos sairão como a luz,',
        'Porque eu quero a misericórdia, e não o sacrifício; e o conhecimento de Deus, mais do que os holocaustos.',
        'Mas eles transgrediram a aliança, como Adão; eles se portaram aleivosamente contra mim.',
        'Gileade é a cidade dos que praticam iniqüidade, manchada de sangue.',
        'Como as hordas de salteadores que esperam alguns, assim é a companhia dos sacerdotes que matam no caminho num mesmo consenso; sim, eles cometem abominações.',
        'Vejo uma coisa horrenda na casa de Israel, ali está a prostituição de Efraim; Israel está contaminado.',
        'Também para ti, ó Judá, está assinada uma sega, quando eu trouxer o cativeiro do meu povo.',
      ],
      [
        'Sarando eu a Israel, se descobriu a iniqüidade de Efraim, como também as maldades de Samaria, porque praticaram a falsidade; e o ladrão entra, e a horda dos salteadores despoja por fora.',
        'E não dizem no seu coração que eu me lembro de toda a sua maldade; agora, pois, os cercam as suas obras; diante da minha face estão.',
        'Com a sua malícia alegram ao rei, e com as suas mentiras aos príncipes.',
        'Todos eles são adúlteros; são semelhantes ao forno aceso pelo padeiro, que cessa de mexer nas brasas, depois que amassou a massa, até que seja levedada.',
        'E no dia do nosso rei os príncipes se tornaram doentes com frascos de vinho; ele estendeu a sua mão com os escarnecedores.',
        'Porque, prepararam o coração como um forno, na sua emboscada; toda a noite dorme o seu padeiro, pela manhã arde como fogo de chama.',
        'Todos eles estão quentes como um forno, e consomem os seus juízes; todos os seus reis caem, ninguém entre eles há que me invoque.',
        'Efraim se mistura com os povos; Efraim é um bolo que não foi virado.',
        'Estrangeiros lhe comeram a força, e ele não o sabe; também as cãs se espalharam sobre ele, e não o sabe.',
        'E a soberba de Israel testificará diante dele; todavia não voltarão para o Senhor seu Deus, nem o buscarão em tudo isto.',
        'Porque Efraim é como uma pomba ingênua, sem entendimento; invocam o Egito, vão para a Assíria.',
        'Quando forem, sobre eles estenderei a minha rede, e como aves do céu os farei descer; castigá-los-ei, conforme o que eles têm ouvido na sua congregação.',
        'Ai deles, porque fugiram de mim; destruição sobre eles, porque se rebelaram contra mim; eu os remi, mas disseram mentiras contra mim.',
        'E não clamaram a mim com seu coração, mas davam uivos nas suas camas; para o trigo e para o vinho se ajuntam, mas contra mim se rebelam.',
        'Eu os corrigi, e lhes esforcei os braços, mas pensam mal contra mim.',
        'Eles voltaram, mas não para o Altíssimo. Fizeram-se como um arco enganador; caem à espada os seus príncipes, por causa do furor da sua língua; este será o seu escárnio na terra do Egito.',
      ],
      [
        'Põe a trombeta à tua boca. Ele virá como a águia contra a casa do SENHOR, porque transgrediram a minha aliança, e se rebelaram contra a minha lei.',
        'E a mim clamarão: Deus meu! Nós, Israel, te conhecemos.',
        'Israel rejeitou o bem; o inimigo persegui-lo-á.',
        'Eles fizeram reis, mas não por mim; constituíram príncipes, mas eu não o soube; da sua prata e do seu ouro fizeram ídolos para si, para serem destruídos.',
        'O teu bezerro, ó Samaria, te rejeitou; a minha ira se acendeu contra eles; até quando serão eles incapazes da inocência?',
        'Porque isso vem de Israel, um artífice o fez, e não é Deus; mas em pedaços será desfeito o bezerro de Samaria.',
        'Porque semearam vento, e segarão tormenta, não haverá seara, a erva não dará farinha; se a der, tragá-la-ão os estrangeiros.',
        'Israel foi devorado; agora está entre os gentios como um vaso em que ninguém tem prazer.',
        'Porque subiram à Assíria, como um jumento montês, por si só; Efraim mercou amores.',
        'Todavia, ainda que eles merquem entre as nações, eu os congregarei; e serão um pouco afligidos por causa da carga do rei dos príncipes.',
        'Porquanto Efraim multiplicou os altares para pecar; teve altares para pecar.',
        'Escrevi-lhe as grandezas da minha lei, porém essas são estimadas como coisa estranha.',
        'Quanto aos sacrifícios das minhas ofertas, sacrificam carne, e a comem, mas o Senhor não as aceita; agora se lembrará da sua iniqüidade, e punirá os seus pecados; eles voltarão para o Egito.',
        'Porque Israel se esqueceu do seu Criador, e edificou templos, e Judá multiplicou cidades fortificadas. Mas eu enviarei um fogo contra as suas cidades, que consumirá os seus palácios.',
      ],
      [
        'Não te alegres, ó Israel, não exultes, como os povos; porque ao prostituir-te abandonaste o teu Deus; amaste a paga de meretriz sobre todas as eiras de trigo.',
        'A eira e o lagar não os manterão; e o mosto lhes faltará.',
        'Na terra do Senhor não permanecerão; mas Efraim tornará ao Egito, e na Assíria comerão comida imunda.',
        'Não derramarão libações de vinho ao Senhor, nem lhe agradarão as suas ofertas. Os seus sacrifícios lhes serão como pão de pranteadores; todos os que dele comerem serão imundos, porque o seu pão será somente para si mesmos; não entrará na casa do Senhor.',
        'Que fareis vós no dia da solenidade, e no dia da festa do Senhor?',
        'Porque, eis que eles se foram por causa da destruição, mas o Egito os recolherá, Mênfis os sepultará; o desejável da sua prata as urtigas o possuirão por herança, espinhos crescerão nas suas tendas.',
        'Chegarão os dias da punição, chegarão os dias da retribuição; Israel o saberá; o profeta é um insensato, o homem de espírito é um louco; por causa da abundância da tua iniqüidade também haverá grande ódio.',
        'Efraim era o vigia com o meu Deus, mas o profeta é como um laço de caçador de aves em todos os seus caminhos, e ódio na casa do seu Deus.',
        'Muito profundamente se corromperam, como nos dias de Gibeá; ele lembrar-se-á das suas injustiças, visitará os pecados deles.',
        'Achei a Israel como uvas no deserto, vi a vossos pais como a fruta temporã da figueira no seu princípio; mas eles foram para Baal-Peor, e se consagraram a essa vergonha, e se tornaram abomináveis como aquilo que amaram.',
        'Quanto a Efraim, a sua glória como ave voará, não haverá nascimento, não haverá gestação nem concepção.',
        'Ainda que venham a criar seus filhos, contudo os privarei deles para que não fique nenhum homem. Ai deles, quando deles eu me apartar!',
        'Efraim, assim como vi a Tiro, está plantado num lugar aprazível; mas Efraim levará os seus filhos ao matador.',
        'Dá-lhes, ó Senhor; mas que lhes darás? Dá-lhes uma madre que aborte e seios secos.',
        'Toda a sua malícia se acha em Gilgal, porque ali os odiei; por causa da maldade das suas obras lançá-los-ei para fora de minha casa. Não os amarei mais; todos os seus príncipes são rebeldes.',
        'Efraim foi ferido, secou-se a sua raiz; não darão fruto; sim, ainda que gerem, matarei os frutos desejáveis do seu ventre.',
        'O meu Deus os rejeitará, porque não o ouviram, e errantes andarão entre as nações.',
      ],
      [
        'Israel é uma vide estéril que dá fruto para si mesmo; conforme a abundância do seu fruto, multiplicou também os altares; conforme a bondade da sua terra, assim, fizeram boas as estátuas.',
        'O seu coração está dividido, por isso serão culpados; o Senhor demolirá os seus altares, e destruirá as suas estátuas.',
        'Certamente agora dirão: Não temos rei, porque não tememos ao Senhor; e o rei, que faria por nós?',
        'Falaram palavras, jurando falsamente, fazendo uma aliança; por isso florescerá o juízo como erva peçonhenta nos sulcos dos campos.',
        'Os moradores de Samaria serão atemorizados pelo bezerro de Bete-«ven; porque o seu povo se lamentará por causa dele, como também os seus sacerdotes idólatras que nele se regozijavam, por causa da sua glória, que se apartou dela.',
        'Também será levada para a Assíria como um presente ao rei Jarebe; Efraim ficará confuso, e Israel se envergonhará por causa do seu próprio conselho.',
        'O rei de Samaria será desfeito como a espuma sobre a face da água.',
        'E os altos de Áven, pecado de Israel, serão destruídos; espinhos e cardos crescerão sobre os seus altares; e dirão aos montes: Cobri-nos! E aos outeiros: Caí sobre nós!',
        'Desde os dias de Gibeá pecaste, ó Israel; ali permaneceram; a peleja em Gibeá, contra os filhos da perversidade, não os alcançará.',
        'Eu os castigarei na medida do meu desejo; e congregar-se-ão contra eles os povos, quando eu os atar pela sua dupla transgressão.',
        'Porque Efraim é uma bezerra domada, que gosta de trilhar; e eu poupava a formosura do seu pescoço; mas farei cavalgar Efraim. Judá lavrará, Jacó lhe desfará os torrões.',
        'Semeai para vós em justiça, ceifai segundo a misericórdia; lavrai o campo de lavoura; porque é tempo de buscar ao Senhor, até que venha e chova a justiça sobre vós.',
        'Lavrastes a impiedade, segastes a iniqüidade, e comestes o fruto da mentira; porque confiaste no teu caminho, na multidão dos teus poderosos.',
        'Portanto, entre o teu povo se levantará um grande tumulto, e todas as tuas fortalezas serão destruídas, como Salmã destruiu a Bete-Arbel no dia da guerra; a mãe ali foi despedaçada com os filhos.',
        'Assim vos fará Betel por causa da vossa grande malícia; de madrugada o rei de Israel será totalmente destruído.',
      ],
      [
        'Quando Israel era menino, eu o amei; e do Egito chamei a meu filho.',
        'Mas, como os chamavam, assim se iam da sua face; sacrificavam a baalins, e queimavam incenso às imagens de escultura.',
        'Todavia, eu ensinei a andar a Efraim; tomando-os pelos seus braços, mas não entenderam que eu os curava.',
        'Atraí-os com cordas humanas, com laços de amor, e fui para eles como os que tiram o jugo de sobre as suas queixadas, e lhes dei mantimento.',
        'Não voltará para a terra do Egito, mas a Assíria será seu rei; porque recusam converter-se.',
        'E cairá a espada sobre as suas cidades, e consumirá os seus ramos, e os devorará, por causa dos seus próprios conselhos.',
        'Porque o meu povo é inclinado a desviar-se de mim; ainda que chamam ao Altíssimo, nenhum deles o exalta.',
        'Como te deixaria, ó Efraim? Como te entregaria, ó Israel? Como te faria como Admá? Te poria como Zeboim? Está comovido em mim o meu coração, as minhas compaixões à uma se acendem.',
        'Não executarei o furor da minha ira; não voltarei para destruir a Efraim, porque eu sou Deus e não homem, o Santo no meio de ti; eu não entrarei na cidade.',
        'Andarão após o Senhor; ele rugirá como leão; rugindo, pois, ele, os filhos do ocidente tremerão.',
        'Tremendo virão como um passarinho, os do Egito, e como uma pomba os da terra da Assíria, e os farei habitar em suas casas, diz o Senhor.',
        'Efraim me cercou com mentira, e a casa de Israel com engano; mas Judá ainda domina com Deus, e com os santos está fiel.',
      ],
      [
        'Efraim se apascenta de vento, e segue o vento leste; todo o dia multiplica a mentira e a destruição; e fazem aliança com a Assíria, e o azeite se leva ao Egito.',
        'O Senhor também com Judá tem contenda, e castigará Jacó segundo os seus caminhos; segundo as suas obras o recompensará.',
        'No ventre pegou do calcanhar de seu irmão, e na sua força lutou com Deus.',
        'Lutou com o anjo, e prevaleceu; chorou, e lhe suplicou; em Betel o achou, e ali falou conosco,',
        'Sim, o Senhor, o Deus dos Exércitos; o Senhor é o seu memorial.',
        'Tu, pois, converte-te a teu Deus; guarda a benevolência e o juízo, e em teu Deus espera sempre.',
        'É um mercador; tem nas mãos uma balança enganosa; ama a opressão.',
        'E diz Efraim: Contudo me tenho enriquecido, e tenho adquirido para mim grandes bens; em todo o meu trabalho não acharão em mim iniqüidade alguma que seja pecado.',
        'Mas eu sou o Senhor teu Deus desde a terra do Egito; eu ainda te farei habitar em tendas, como nos dias da festa solene.',
        'Falei aos profetas, e multipliquei a visão; e pelo ministério dos profetas propus símiles.',
        'Não é Gileade iniqüidade? Pura vaidade são eles; em Gilgal sacrificam bois; os seus altares são como montões de pedras nos sulcos dos campos.',
        'Jacó fugiu para o campo da Síria, e Israel serviu por uma mulher, e por uma mulher guardou o gado.',
        'Mas o Senhor por meio de um profeta fez subir a Israel do Egito, e por um profeta foi ele guardado.',
        'Efraim mui amargosamente provocou a sua ira; portanto deixará ficar sobre ele o seu sangue, e o seu Senhor o recompensará pelo seu opróbrio.',
      ],
      [
        'Quando Efraim falava, tremia-se; foi exaltado em Israel; mas ele se fez culpado em Baal, e morreu.',
        'E agora multiplicaram pecados, e da sua prata fizeram uma imagem de fundição, ídolos segundo o seu entendimento, todos obra de artífices, dos quais dizem: Os homens que sacrificam beijem os bezerros.',
        'Por isso serão como a nuvem da manhã, e como o orvalho da madrugada, que cedo passa; como folhelho que a tempestade lança da eira, e como a fumaça da chaminé.',
        'Todavia, eu sou o Senhor teu Deus desde a terra do Egito; portanto não reconhecerás outro deus além de mim, porque não há Salvador senão eu.',
        'Eu te conheci no deserto, na terra muito seca.',
        'Depois eles se fartaram em proporção do seu pasto; estando fartos, ensoberbeceu-se o seu coração, por isso se esqueceram de mim.',
        'Serei, pois, para eles como leão; como leopardo espiarei no caminho.',
        'Como ursa roubada dos seus filhos, os encontrarei, e lhes romperei as teias do seu coração, e como leão ali os devorarei; as feras do campo os despedaçarão.',
        'Para a tua perda, ó Israel, te rebelaste contra mim, a saber, contra o teu ajudador.',
        'Onde está agora o teu rei, para que te guarde em todas as tuas cidades, e os teus juízes, dos quais disseste: Dá-me rei e príncipes?',
        'Dei-te um rei na minha ira, e tirei-o no meu furor.',
        'A iniqüidade de Efraim está atada, o seu pecado está armazenado.',
        'Dores de mulher de parto lhe sobrevirão; ele é um filho insensato; porque é tempo e não está no lugar em que deve vir à luz.',
        'Eu os remirei da mão do inferno, e os resgatarei da morte. Onde estão, ó morte, as tuas pragas? Onde está, ó inferno, a tua perdição? O arrependimento está escondido de meus olhos.',
        'Ainda que ele dê fruto entre os irmãos, virá o vento leste, vento do Senhor, subindo do deserto, e secar-se-á a sua nascente, e secar-se-á a sua fonte; ele saqueará o tesouro de todos os vasos desejáveis.',
        'Samaria virá a ser deserta, porque se rebelou contra o seu Deus; cairão à espada, seus filhos serão despedaçados, e as suas grávidas serão fendidas pelo meio.',
      ],
      [
        'Converte-te, ó Israel, ao SENHOR teu Deus; porque pelos teus pecados tens caído.',
        'Tomai convosco palavras, e convertei-vos ao Senhor; dizei-lhe: Tira toda a iniqüidade, e aceita o que é bom; e ofereceremos como novilhos os sacrifícios dos nossos lábios.',
        'Não nos salvará a Assíria, não iremos montados em cavalos, e à obra das nossas mãos já não diremos mais: Tu és o nosso deus; porque por ti o órfão alcança misericórdia.',
        'Eu sararei a sua infidelidade, eu voluntariamente os amarei; porque a minha ira se apartou deles.',
        'Eu serei para Israel como o orvalho. Ele florescerá como o lírio e lançará as suas raízes como o Líbano.',
        'Estender-se-ão os seus galhos, e a sua glória será como a da oliveira, e sua fragrância como a do Líbano.',
        'Voltarão os que habitam debaixo da sua sombra; serão vivificados como o trigo, e florescerão como a vide; a sua memória será como o vinho do Líbano.',
        'Efraim dirá: Que mais tenho eu com os ídolos? Eu o tenho ouvido, e cuidarei dele; eu sou como a faia verde; de mim é achado o teu fruto.',
        'Quem é sábio, para que entenda estas coisas? Quem é prudente, para que as saiba? Porque os caminhos do Senhor são retos, e os justos andarão neles, mas os transgressores neles cairão.',
      ],
    ],
    livro: 'oseias',
  },
  {
    abbrev: 'jl',
    capitulos: [
      [
        'Palavra do SENHOR, que foi dirigida a Joel, filho de Petuel.',
        'Ouvi isto, vós anciãos, e escutai, todos os moradores da terra: Porventura isto aconteceu em vossos dias, ou nos dias de vossos pais?',
        'Fazei sobre isto uma narração a vossos filhos, e vossos filhos a seus filhos, e os filhos destes à outra geração.',
        'O que ficou da lagarta, o gafanhoto o comeu, e o que ficou do gafanhoto, a locusta o comeu, e o que ficou da locusta, o pulgão o comeu.',
        'Despertai-vos, bêbados, e chorai; gemei, todos os que bebeis vinho, por causa do mosto, porque tirado é da vossa boca.',
        'Porque subiu contra a minha terra uma nação poderosa e sem número; os seus dentes são dentes de leão, e têm queixadas de um leão velho.',
        'Fez da minha vide uma assolação, e tirou a casca da minha figueira; despiu-a toda, e a lançou por terra; os seus sarmentos se embranqueceram.',
        'Lamenta como a virgem que está cingida de saco, pelo marido da sua mocidade.',
        'Foi cortada a oferta de alimentos e a libação da casa do Senhor; os sacerdotes, ministros do Senhor, estão entristecidos.',
        'O campo está assolado, e a terra triste; porque o trigo está destruído, o mosto se secou, o azeite acabou.',
        'Envergonhai-vos, lavradores, gemei, vinhateiros, sobre o trigo e a cevada; porque a colheita do campo pereceu.',
        'A vide se secou, a figueira se murchou, a romeira também, e a palmeira e a macieira; todas as árvores do campo se secaram, e já não há alegria entre os filhos dos homens.',
        'Cingi-vos e lamentai-vos, sacerdotes; gemei, ministros do altar; entrai e passai a noite vestidos de saco, ministros do meu Deus; porque a oferta de alimentos, e a libação, foram cortadas da casa de vosso Deus.',
        'Santificai um jejum, convocai uma assembléia solene, congregai os anciãos, e todos os moradores desta terra, na casa do Senhor vosso Deus, e clamai ao Senhor.',
        'Ai do dia! Porque o dia do Senhor está perto, e virá como uma assolação do Todo-Poderoso.',
        'Porventura o mantimento não está cortado de diante de nossos olhos, a alegria e o regozijo da casa de nosso Deus?',
        'As sementes apodreceram debaixo dos seus torrões, os celeiros foram assolados, os armazéns derrubados, porque se secou o trigo.',
        'Como geme o animal! As manadas de gados estão confusas, porque não têm pasto; também os rebanhos de ovelhas estão perecendo.',
        'A ti, ó Senhor, clamo, porque o fogo consumiu os pastos do deserto, e a chama abrasou todas as árvores do campo.',
        'Também todos os animais do campo bramam a ti; porque as correntes de água se secaram, e o fogo consumiu os pastos do deserto.',
      ],
      [
        'Tocai a trombeta em Sião, e clamai em alta voz no meu santo monte; tremam todos os moradores da terra, porque o dia do SENHOR vem, já está perto;',
        'Dia de trevas e de escuridão; dia de nuvens e densas trevas, como a alva espalhada sobre os montes; povo grande e poderoso, qual nunca houve desde o tempo antigo, nem depois dele haverá pelos anos adiante, de geração em geração.',
        'Diante dele um fogo consome, e atrás dele uma chama abrasa; a terra diante dele é como o jardim do Éden, mas atrás dele um desolado deserto; sim, nada lhe escapará.',
        'A sua aparência é como a de cavalos; e como cavaleiros assim correm.',
        'Como o estrondo de carros, irão saltando sobre os cumes dos montes, como o ruído da chama de fogo que consome a pragana, como um povo poderoso, posto em ordem para o combate.',
        'Diante dele temerão os povos; todos os rostos se tornarão enegrecidos.',
        'Como valentes correrão, como homens de guerra subirão os muros; e marchará cada um no seu caminho e não se desviará da sua fileira.',
        'Ninguém apertará a seu irmão; marchará cada um pelo seu caminho; sobre a mesma espada se arremessarão, e não serão feridos.',
        'Irão pela cidade, correrão pelos muros, subirão às casas, entrarão pelas janelas como o ladrão.',
        'Diante dele tremerá a terra, abalar-se-ão os céus; o sol e a lua se enegrecerão, e as estrelas retirarão o seu resplendor.',
        'E o Senhor levantará a sua voz diante do seu exército; porque muitíssimo grande é o seu arraial; porque poderoso é, executando a sua palavra; porque o dia do Senhor é grande e mui terrível, e quem o poderá suportar?',
        'Ainda assim, agora mesmo diz o Senhor: Convertei-vos a mim de todo o vosso coração; e isso com jejuns, e com choro, e com pranto.',
        'E rasgai o vosso coração, e não as vossas vestes, e convertei-vos ao Senhor vosso Deus; porque ele é misericordioso, e compassivo, e tardio em irar-se, e grande em benignidade, e se arrepende do mal.',
        'Quem sabe se não se voltará e se arrependerá, e deixará após si uma bênção, em oferta de alimentos e libação para o Senhor vosso Deus?',
        'Tocai a trombeta em Sião, santificai um jejum, convocai uma assembléia solene.',
        'Congregai o povo, santificai a congregação, ajuntai os anciãos, congregai as crianças, e os que mamam; saia o noivo da sua recâmara, e a noiva do seu aposento.',
        'Chorem os sacerdotes, ministros do Senhor, entre o alpendre e o altar, e digam: Poupa a teu povo, ó Senhor, e não entregues a tua herança ao opróbrio, para que os gentios o dominem; por que diriam entre os povos: Onde está o seu Deus?',
        'Então o Senhor se mostrou zeloso da sua terra, e compadeceu-se do seu povo.',
        'E o Senhor, respondendo, disse ao seu povo: Eis que vos envio o trigo, e o mosto, e o azeite, e deles sereis fartos, e vos não entregarei mais ao opróbrio entre os gentios.',
        'Mas removerei para longe de vós o exército do norte, e lançá-lo-ei em uma terra seca e deserta; a sua frente para o mar oriental, e a sua retaguarda para o mar ocidental; e subirá o seu mau cheiro, e subirá a sua podridão; porque fez grandes coisas.',
        'Não temas, ó terra: regozija-te e alegra-te, porque o Senhor fez grandes coisas.',
        'Não temais, animais do campo, porque os pastos do deserto reverdecerão, porque o arvoredo dará o seu fruto, a vide e a figueira darão a sua força.',
        'E vós, filhos de Sião, regozijai-vos e alegrai-vos no Senhor vosso Deus, porque ele vos dará em justa medida a chuva temporã; fará descer a chuva no primeiro mês, a temporã e a serôdia.',
        'E as eiras se encherão de trigo, e os lagares trasbordarão de mosto e de azeite.',
        'E restituir-vos-ei os anos que comeu o gafanhoto, a locusta, e o pulgão e a lagarta, o meu grande exército que enviei contra vós.',
        'E comereis abundantemente e vos fartareis, e louvareis o nome do Senhor vosso Deus, que procedeu para convosco maravilhosamente; e o meu povo nunca mais será envergonhado.',
        'E vós sabereis que eu estou no meio de Israel, e que eu sou o Senhor vosso Deus, e que não há outro; e o meu povo nunca mais será envergonhado.',
        'E há de ser que, depois derramarei o meu Espírito sobre toda a carne, e vossos filhos e vossas filhas profetizarão, os vossos velhos terão sonhos, os vossos jovens terão visões.',
        'E também sobre os servos e sobre as servas naqueles dias derramarei o meu Espírito.',
        'E mostrarei prodígios no céu, e na terra, sangue e fogo, e colunas de fumaça.',
        'O sol se converterá em trevas, e a lua em sangue, antes que venha o grande e terrível dia do Senhor.',
        'E há de ser que todo aquele que invocar o nome do Senhor será salvo; porque no monte Sião e em Jerusalém haverá livramento, assim como disse o Senhor, e entre os sobreviventes, aqueles que o Senhor chamar.',
      ],
      [
        'Porque, eis que naqueles dias, e naquele tempo, em que removerei o cativeiro de Judá e de Jerusalém,',
        'Congregarei todas as nações, e as farei descer ao vale de Jeosafá; e ali com elas entrarei em juízo, por causa do meu povo, e da minha herança, Israel, a quem elas espalharam entre as nações e repartiram a minha terra.',
        'E lançaram sortes sobre o meu povo, e deram um menino por uma meretriz, e venderam uma menina por vinho, para beberem.',
        'E também que tendes vós comigo, Tiro e Sidom, e todas as regiões da Filístia? É tal o pago que vós me dais? Pois se me pagais assim, bem depressa vos farei tornar a vossa paga sobre a vossa cabeça.',
        'Visto como levastes a minha prata e o meu ouro, e as minhas coisas desejáveis e formosas pusestes nos vossos templos.',
        'E vendestes os filhos de Judá e os filhos de Jerusalém aos filhos dos gregos, para os apartar para longe dos seus termos.',
        'Eis que eu os suscitarei do lugar para onde os vendestes, e farei tornar a vossa paga sobre a vossa própria cabeça.',
        'E venderei vossos filhos e vossas filhas na mão dos filhos de Judá, que os venderão aos sabeus, a um povo distante, porque o Senhor o disse.',
        'Proclamai isto entre os gentios; preparai a guerra, suscitai os fortes; cheguem-se, subam todos os homens de guerra.',
        'Forjai espadas das vossas enxadas, e lanças das vossas foices; diga o fraco: Eu sou forte.',
        'Ajuntai-vos, e vinde, todos os gentios em redor, e congregai-vos. Ó Senhor, faze descer ali os teus fortes;',
        'Suscitem-se os gentios, e subam ao vale de Jeosafá; pois ali me assentarei para julgar todos os gentios em redor.',
        'Lançai a foice, porque já está madura a seara; vinde, descei, porque o lagar está cheio, e os vasos dos lagares transbordam, porque a sua malícia é grande.',
        'Multidões, multidões no vale da decisão; porque o dia do Senhor está perto, no vale da decisão.',
        'O sol e a lua se enegrecerão, e as estrelas retirarão o seu resplendor.',
        'E o Senhor bramará de Sião, e de Jerusalém fará ouvir a sua voz; e os céus e a terra tremerão, mas o Senhor será o refúgio do seu povo, e a fortaleza dos filhos de Israel.',
        'E vós sabereis que eu sou o Senhor vosso Deus, que habito em Sião, o meu santo monte; e Jerusalém será santa; estranhos não passarão mais por ela.',
        'E há de ser que, naquele dia, os montes destilarão mosto, e os outeiros manarão leite, e todos os rios de Judá estarão cheios de águas; e sairá uma fonte, da casa do Senhor, e regará o vale de Sitim.',
        'O Egito se fará uma desolação, e Edom se fará um deserto assolado, por causa da violência que fizeram aos filhos de Judá, em cuja terra derramaram sangue inocente.',
        'Mas Judá será habitada para sempre, e Jerusalém de geração em geração.',
        'E purificarei o sangue dos que eu não tinha purificado; porque o Senhor habitará em Sião.',
      ],
    ],
    livro: 'joel',
  },
  {
    abbrev: 'am',
    capitulos: [
      [
        'As palavras de Amós, que estava entre os pastores de Tecoa, as quais viu a respeito de Israel, nos dias de Uzias, rei de Judá, e nos dias de Jeroboão, filho de Joás, rei de Israel, dois anos antes do terremoto.',
        'Ele disse: O Senhor bramará de Sião, e de Jerusalém fará ouvir a sua voz; os prados dos pastores prantearão, e secar-se-á o cume do Carmelo.',
        'Assim diz o Senhor: Por três transgressões de Damasco, e por quatro, não retirarei o castigo, porque trilharam a Gileade com trilhos de ferro.',
        'Por isso porei fogo à casa de Hazael, e ele consumirá os palácios de Ben-Hadade.',
        'E quebrarei o ferrolho de Damasco, e exterminarei o morador do vale de Áven, e ao que tem o cetro de Bete-Éden; e o povo da Síria será levado em cativeiro a Quir, diz o Senhor.',
        'Assim diz o Senhor: Por três transgressões de Gaza, e por quatro, não retirarei o castigo, porque levaram em cativeiro todos os cativos para os entregarem a Edom.',
        'Por isso porei fogo ao muro de Gaza, e ele consumirá os seus palácios.',
        'E exterminarei o morador de Asdode, e o que tem o cetro de Ascalom, e tornarei a minha mão contra Ecrom; e o restante dos filisteus perecerá, diz o Senhor DEUS.',
        'Assim diz o Senhor: Por três transgressões de Tiro, e por quatro, não retirarei o castigo, porque entregaram todos os cativos a Edom, e não se lembraram da aliança dos irmãos.',
        'Por isso porei fogo ao muro de Tiro, e ele consumirá os seus palácios.',
        'Assim diz o Senhor: Por três transgressões de Edom, e por quatro, não retirarei o castigo, porque perseguiu a seu irmão à espada, e aniquilou as suas misericórdias; e a sua ira despedaçou eternamente, e conservou a sua indignação para sempre.',
        'Por isso porei fogo a Temã, e ele consumirá os palácios de Bozra.',
        'Assim diz o Senhor: Por três transgressões dos filhos de Amom, e por quatro, não retirarei o castigo, porque fenderam o ventre às grávidas de Gileade, para dilatarem os seus termos.',
        'Por isso porei fogo ao muro de Rabá, e ele consumirá os seus palácios, com alarido no dia da batalha, com tempestade no dia da tormenta.',
        'E o seu rei irá para o cativeiro, ele e os seus príncipes juntamente, diz o Senhor.',
      ],
      [
        'Assim diz o SENHOR: Por três transgressões de Moabe, e por quatro, não retirarei o castigo, porque queimou os ossos do rei de Edom, até os tornar a cal.',
        'Por isso porei fogo a Moabe, e consumirá os palácios de Queriote; e Moabe morrerá com grande estrondo, com alarido, com som de trombeta.',
        'E exterminarei o juiz do meio dele, e a todos os seus príncipes com ele matarei, diz o Senhor.',
        'Assim diz o Senhor: Por três transgressões de Judá, e por quatro, não retirarei o castigo, porque rejeitaram a lei do Senhor, e não guardaram os seus estatutos, antes se deixaram enganar por suas próprias mentiras, após as quais andaram seus pais.',
        'Por isso porei fogo a Judá, e ele consumirá os palácios de Jerusalém.',
        'Assim diz o Senhor: Por três transgressões de Israel, e por quatro, não retirarei o castigo, porque vendem o justo por dinheiro, e o necessitado por um par de sapatos,',
        'Suspirando pelo pó da terra, sobre a cabeça dos pobres, pervertem o caminho dos mansos; e um homem e seu pai entram à mesma moça, para profanarem o meu santo nome.',
        'E se deitam junto a qualquer altar sobre roupas empenhadas, e na casa dos seus deuses bebem o vinho dos que tinham multado.',
        'Todavia eu destruí diante dele o amorreu, cuja altura era como a altura dos cedros, e que era forte como os carvalhos; mas destruí o seu fruto por cima, e as suas raízes por baixo.',
        'Também vos fiz subir da terra do Egito, e quarenta anos vos guiei no deserto, para que possuísseis a terra do amorreu.',
        'E dentre vossos filhos suscitei profetas, e dentre os vossos jovens nazireus. Não é isto assim, filhos de Israel? diz o Senhor.',
        'Mas vós aos nazireus destes vinho a beber, e aos profetas ordenastes, dizendo: Não profetizareis.',
        'Eis que eu vos apertarei no vosso lugar como se aperta um carro cheio de feixes.',
        'Assim perecerá a fuga ao ágil; nem o forte corroborará a sua força, nem o poderoso livrará a sua vida.',
        'E não ficará em pé o que maneja o arco, nem o ligeiro de pés se livrará, nem tampouco se livrará o que vai montado a cavalo.',
        'E o mais corajoso entre os fortes fugirá nu naquele dia, diz o Senhor.',
      ],
      [
        'Ouvi esta palavra que o SENHOR fala contra vós, filhos de Israel, contra toda a família que fiz subir da terra do Egito, dizendo:',
        'De todas as famílias da terra só a vós vos tenho conhecido; portanto eu vos punirei por todas as vossas iniqüidades.',
        'Porventura andarão dois juntos, se não estiverem de acordo?',
        'Rugirá o leão no bosque, sem que tenha presa? Levantará o leãozinho no seu covil a sua voz, se nada tiver apanhado?',
        'Cairá a ave no laço em terra, se não houver armadilha para ela? Levantar-se-á da terra o laço, sem que tenha apanhado alguma coisa?',
        'Tocar-se-á a trombeta na cidade, e o povo não estremecerá? Sucederá algum mal na cidade, sem que o Senhor o tenha feito?',
        'Certamente o Senhor DEUS não fará coisa alguma, sem ter revelado o seu segredo aos seus servos, os profetas.',
        'Rugiu o leão, quem não temerá? Falou o Senhor DEUS, quem não profetizará?',
        'Fazei ouvir isso nos palácios de Asdode, e nos palácios da terra do Egito, e dizei: Ajuntai-vos sobre os montes de Samaria, e vede que grandes alvoroços há no meio dela, e como são oprimidos dentro dela.',
        'Porque não sabem fazer o que é reto, diz o Senhor, aqueles que entesouram nos seus palácios a violência e a destruição.',
        'Portanto, o Senhor DEUS diz assim: O inimigo virá, e cercará a terra, derrubará a tua fortaleza, e os teus palácios serão saqueados.',
        'Assim diz o Senhor: Como o pastor livra da boca do leão as duas pernas, ou um pedaço da orelha, assim serão livrados os filhos de Israel que habitam em Samaria, no canto da cama, e no damasco do leito.',
        'Ouvi, e protestai contra a casa de Jacó, diz o Senhor DEUS, o Deus dos Exércitos;',
        'Pois no dia em que eu punir as transgressões de Israel, também castigarei os altares de Betel; e as pontas do altar serão cortadas, e cairão por terra.',
        'E ferirei a casa de inverno juntamente com a casa de verão; e as casas de marfim perecerão, e as grandes casas terão fim, diz o Senhor.',
      ],
      [
        'Ouvi esta palavra vós, vacas de Basã, que estais no monte de Samaria, que oprimis aos pobres, que esmagais os necessitados, que dizeis a vossos senhores: Dai cá, e bebamos.',
        'Jurou o Senhor DEUS, pela sua santidade, que dias estão para vir sobre vós, em que vos levarão com ganchos e a vossos descendentes com anzóis de pesca.',
        'E saireis pelas brechas, uma após outra, e sereis lançadas para Harmom, disse o Senhor.',
        'Vinde a Betel, e transgredi; a Gilgal, e multiplicai as transgressões; e cada manhã trazei os vossos sacrifícios, e os vossos dízimos de três em três dias.',
        'E oferecei o sacrifício de louvores do que é levedado, e apregoai as ofertas voluntárias, publicai-as; porque disso gostais, ó filhos de Israel, disse o Senhor DEUS.',
        'Por isso também vos dei limpeza de dentes em todas as vossas cidades, e falta de pão em todos os vossos lugares; contudo não vos convertestes a mim, disse o Senhor.',
        'Além disso, retive de vós a chuva quando ainda faltavam três meses para a ceifa; e fiz que chovesse sobre uma cidade, e não chovesse sobre a outra cidade; sobre um campo choveu, mas o outro, sobre o qual não choveu, secou-se.',
        'E andaram errantes duas ou três cidades, indo a outra cidade para beberem água, mas não se saciaram; contudo não vos convertestes a mim, disse o Senhor.',
        'Feri-vos com queimadura, e com ferrugem; a multidão das vossas hortas, e das vossas vinhas, e das vossas figueiras, e das vossas oliveiras, comeu a locusta; contudo não vos convertestes a mim, disse o Senhor.',
        'Enviei a peste contra vós, à maneira do Egito; os vossos jovens matei à espada, e os vossos cavalos deixei levar presos, e o mau cheiro dos vossos arraiais fiz subir às vossas narinas; contudo não vos convertestes a mim, disse o Senhor.',
        'Subverti a alguns dentre vós, como Deus subverteu a Sodoma e Gomorra, e vós fostes como um tição arrebatado do incêndio; contudo não vos convertestes a mim, disse o Senhor.',
        'Portanto, assim te farei, ó Israel! E porque isso te farei, prepara-te, ó Israel, para te encontrares com o teu Deus.',
        'Porque eis aqui o que forma os montes, e cria o vento, e declara ao homem qual seja o seu pensamento, o que faz da manhã trevas, e pisa os altos da terra; o Senhor, o Deus dos Exércitos, é o seu nome.',
      ],
      [
        'Ouvi esta palavra, que levanto como uma lamentação sobre vós, ó casa de Israel.',
        'A virgem de Israel caiu, e não mais tornará a levantar-se; desamparada está na sua terra, não há quem a levante.',
        'Porque assim diz o Senhor DEUS: A cidade da qual saem mil conservará cem, e aquela da qual saem cem conservará dez, para a casa de Israel.',
        'Porque assim diz o Senhor à casa de Israel: Buscai-me, e vivei.',
        'Mas não busqueis a Betel, nem venhais a Gilgal, nem passeis a Berseba, porque Gilgal certamente será levada ao cativeiro, e Betel será desfeita em nada.',
        'Buscai ao Senhor, e vivei, para que ele não irrompa na casa de José como um fogo, e a consuma, e não haja em Betel quem o apague.',
        'Vós que converteis o juízo em alosna, e deitais por terra a justiça,',
        'Procurai o que faz o sete-estrêlo e o órion e torna a sombra da noite em manhã, e faz escurecer o dia como a noite, que chama as águas do mar, e as derrama sobre a terra; o Senhor é o seu nome.',
        'O que promove súbita destruição contra o forte; de modo que venha a destruição contra a fortaleza.',
        'Odeiam na porta ao que os repreende, e abominam ao que fala sinceramente.',
        'Portanto, visto que pisais o pobre e dele exigis um tributo de trigo, edificastes casas de pedras lavradas, mas nelas não habitareis; vinhas desejáveis plantastes, mas não bebereis do seu vinho.',
        'Porque sei que são muitas as vossas transgressões e graves os vossos pecados; afligis o justo, tomais resgate, e rejeitais os necessitados na porta.',
        'Portanto, o que for prudente guardará silêncio naquele tempo, porque o tempo será mau.',
        'Buscai o bem, e não o mal, para que vivais; e assim o Senhor, o Deus dos Exércitos, estará convosco, como dizeis.',
        'Odiai o mal, e amai o bem, e estabelecei na porta o juízo. Talvez o Senhor Deus dos Exércitos tenha piedade do remanescente de José.',
        'Portanto, assim diz o Senhor, o Deus dos Exércitos, o Senhor: Em todas as ruas haverá pranto, e em todas as estradas dirão: Ai! Ai! E ao lavrador chamarão para choro, e para pranto os que souberem prantear.',
        'E em todas as vinhas haverá pranto; porque passarei pelo meio de ti, diz o Senhor.',
        'Ai daqueles que desejam o dia do Senhor! Para que quereis vós este dia do Senhor? Será de trevas e não de luz.',
        'É como se um homem fugisse de diante do leão, e se encontrasse com ele o urso; ou como se entrando numa casa, a sua mão encostasse à parede, e fosse mordido por uma cobra.',
        'Não será, pois, o dia do Senhor trevas e não luz, e escuridão, sem que haja resplendor?',
        'Odeio, desprezo as vossas festas, e as vossas assembléias solenes não me exalarão bom cheiro.',
        'E ainda que me ofereçais holocaustos, ofertas de alimentos, não me agradarei delas; nem atentarei para as ofertas pacíficas de vossos animais gordos.',
        'Afasta de mim o estrépito dos teus cânticos; porque não ouvirei as melodias das tuas violas.',
        'Corra, porém, o juízo como as águas, e a justiça como o ribeiro impetuoso.',
        'Oferecestes-me vós sacrifícios e oblações no deserto por quarenta anos, ó casa de Israel?',
        'Antes levastes a tenda de vosso Moloque, e a estátua das vossas imagens, a estrela do vosso deus, que fizestes para vós mesmos.',
        'Portanto vos levarei cativos, para além de Damasco, diz o Senhor, cujo nome é o Deus dos Exércitos.',
      ],
      [
        'Ai dos que vivem sossegados em Sião, e dos que estão confiados no monte de Samaria, que têm nome entre as primeiras das nações, e aos quais vem a casa de Israel!',
        'Passai a Calne, e vede; e dali ide à grande Hamate; e depois descei a Gate dos filisteus; serão melhores que estes reinos? Ou maior o seu termo do que o vosso termo?',
        'Ó vós que afastais o dia mau, e fazeis chegar o assento da violência.',
        'Ai dos que dormem em camas de marfim, e se estendem sobre os seus leitos, e comem os cordeiros do rebanho, e os bezerros do meio do curral;',
        'Que cantam ao som da viola, e inventam para si instrumentos musicais, assim como Davi;',
        'Que bebem vinho em taças, e se ungem com o mais excelente óleo: mas não se afligem pela ruína de José;',
        'Portanto agora irão em cativeiro entre os primeiros dos que forem levados cativos, e cessarão os festins dos banqueteadores.',
        'Jurou o Senhor DEUS por si mesmo, diz o SENHOR, o Deus dos Exércitos: Abomino a soberba de Jacó, e odeio os seus palácios; por isso entregarei a cidade e tudo o que nela há.',
        'E acontecerá que, se numa casa ficarem dez homens, morrerão.',
        'Quando o tio de alguém, aquele que o queima, o tomar para levar-lhe os ossos para fora da casa, e disser ao que estiver no mais interior da casa: Está ainda alguém contigo? E este responder: Ninguém; então lhe dirá ele: Cala-te, porque não devemos fazer menção do nome do Senhor.',
        'Porque, eis que o Senhor ordena, e ferirá a casa grande de brechas, e a casa pequena de fendas.',
        'Porventura correrão cavalos sobre rocha? Lavrar-se-á nela com bois? Mas vós haveis tornado o juízo em fel, e o fruto da justiça em alosna;',
        'Vós que vos alegrais do nada, vós que dizeis: Não é assim que por nossa própria força nos temos tornado poderosos?',
        'Porque, eis que eu levantarei sobre vós, ó casa de Israel, uma nação, diz o Senhor, o Deus dos Exércitos, e oprimir-vos-á, desde a entrada de Hamate até ao ribeiro do deserto.',
      ],
      [
        'O SENHOR DEUS assim me fez ver, e eis que ele formava gafanhotos no princípio do rebento da erva serôdia, e eis que era a erva serôdia depois de findas as ceifas do rei.',
        'E aconteceu que, tendo eles comido completamente a erva da terra, eu disse: Senhor DEUS, perdoa, rogo-te; quem levantará a Jacó? pois ele é pequeno.',
        'Então o Senhor se arrependeu disso. Não acontecerá, disse o Senhor.',
        'Assim me mostrou o Senhor DEUS: Eis que o Senhor DEUS clamava, para contender com fogo; este consumiu o grande abismo, e também uma parte da terra.',
        'Então eu disse: Senhor Deus, cessa, eu te peço; quem levantará a Jacó? pois é pequeno.',
        'E o SENHOR se arrependeu disso. Nem isso acontecerá, disse o Senhor DEUS.',
        'Mostrou-me também assim: e eis que o Senhor estava sobre um muro, levantado a prumo; e tinha um prumo na sua mão.',
        'E o Senhor me disse: Que vês tu, Amós? E eu disse: Um prumo. Então disse o Senhor: Eis que eu porei o prumo no meio do meu povo Israel; nunca mais passarei por ele.',
        'Mas os altos de Isaque serão assolados, e destruídos os santuários de Israel; e levantar-me-ei com a espada contra a casa de Jeroboão.',
        'Então Amazias, o sacerdote de Betel, mandou dizer a Jeroboão, rei de Israel: Amós tem conspirado contra ti, no meio da casa de Israel; a terra não poderá sofrer todas as suas palavras.',
        'Porque assim diz Amós: Jeroboão morrerá à espada, e Israel certamente será levado para fora da sua terra em cativeiro.',
        'Depois Amazias disse a Amós: Vai-te, ó vidente, e foge para a terra de Judá, e ali come o pão, e ali profetiza;',
        'Mas em Betel daqui por diante não profetizes mais, porque é o santuário do rei e casa real.',
        'E respondeu Amós, dizendo a Amazias: Eu não sou profeta, nem filho de profeta, mas boiadeiro, e cultivador de sicômoros.',
        'Mas o Senhor me tirou de seguir o rebanho, e o Senhor me disse: Vai, e profetiza ao meu povo Israel.',
        'Agora, pois, ouve a palavra do Senhor: Tu dizes: Não profetizes contra Israel, nem fales contra a casa de Isaque.',
        'Portanto assim diz o Senhor: Tua mulher se prostituirá na cidade, e teus filhos e tuas filhas cairão à espada, e a tua terra será repartida a cordel, e tu morrerás na terra imunda, e Israel certamente será levado cativo para fora da sua terra.',
      ],
      [
        'O SENHOR DEUS assim me fez ver: E eis aqui um cesto de frutos do verão.',
        'E disse: Que vês, Amós? E eu disse: Um cesto de frutos do verão. Então o Senhor me disse: Chegou o fim sobre o meu povo Israel; nunca mais passarei por ele.',
        'Mas os cânticos do templo naquele dia serão gemidos, diz o Senhor DEUS; multiplicar-se-ão os cadáveres; em todos os lugares serão lançados fora em silêncio.',
        'Ouvi isto, vós que anelais o abatimento do necessitado; e destruís os miseráveis da terra,',
        'Dizendo: Quando passará a lua nova, para vendermos o grão, e o sábado, para abrirmos os celeiros de trigo, diminuindo o efa, e aumentando o siclo, e procedendo dolosamente com balanças enganosas,',
        'Para comprarmos os pobres por dinheiro, e os necessitados por um par de sapatos, e para vendermos o refugo do trigo?',
        'Jurou o Senhor pela glória de Jacó: Eu não me esquecerei de todas as suas obras para sempre.',
        'Por causa disto não estremecerá a terra, e não chorará todo aquele que nela habita? Certamente levantar-se-á toda ela como o grande rio, e será agitada, e baixará como o rio do Egito.',
        'E sucederá que, naquele dia, diz o Senhor Deus, farei que o sol se ponha ao meio-dia, e a terra se entenebreça no dia claro.',
        'E tornarei as vossas festas em luto, e todos os vossos cânticos em lamentações; e porei pano de saco sobre todos os lombos, e calva sobre toda cabeça; e farei que isso seja como luto por um filho único, e o seu fim como dia de amarguras.',
        'Eis que vêm dias, diz o Senhor DEUS, em que enviarei fome sobre a terra; não fome de pão, nem sede de água, mas de ouvir as palavras do SENHOR.',
        'E irão errantes de um mar até outro mar, e do norte até ao oriente; correrão por toda a parte, buscando a palavra do Senhor, mas não a acharão.',
        'Naquele dia as virgens formosas e os jovens desmaiarão de sede.',
        'Os que juram pela culpa de Samaria, dizendo: Vive o teu deus, ó Dã; e vive o caminho de Berseba; esses mesmos cairão, e não se levantarão jamais.',
      ],
      [
        'Vi o Senhor, que estava em pé sobre o altar; e me disse: Fere o capitel, e estremeçam os umbrais, e faze tudo em pedaços sobre a cabeça de todos eles; e eu matarei à espada até ao último deles; nenhum deles conseguirá fugir, nenhum deles escapará.',
        'Ainda que cavem até ao inferno, a minha mão os tirará dali; e, se subirem ao céu, dali os farei descer.',
        'E, se se esconderem no cume do Carmelo, buscá-los-ei, e dali os tirarei; e, se dos meus olhos se ocultarem no fundo do mar, ali darei ordem à serpente, e ela os picará.',
        'E, se forem em cativeiro diante de seus inimigos, ali darei ordem à espada que os mate; e eu porei os meus olhos sobre eles para o mal, e não para o bem.',
        'Porque o Senhor DEUS dos Exércitos é o que toca a terra, e ela se derrete, e todos os que habitam nela chorarão; e ela subirá toda como um rio, e abaixará como o rio do Egito.',
        'Ele é o que edifica as suas câmaras superiores no céu, e fundou na terra a sua abóbada, e o que chama as águas do mar, e as derrama sobre a terra; o Senhor é o seu nome.',
        'Não me sois, vós, ó filhos de Israel, como os filhos dos etíopes? diz o Senhor: Não fiz eu subir a Israel da terra do Egito, e aos filisteus de Caftor, e aos sírios de Quir?',
        'Eis que os olhos do Senhor DEUS estão contra este reino pecador, e eu o destruirei de sobre a face da terra; mas não destruirei de todo a casa de Jacó, diz o SENHOR.',
        'Porque eis que darei ordem, e sacudirei a casa de Israel entre todas as nações, assim como se sacode grão no crivo, sem que caia na terra um só grão.',
        'Todos os pecadores do meu povo morrerão à espada, os que dizem: Não nos alcançará nem nos encontrará o mal.',
        'Naquele dia tornarei a levantar o tabernáculo caído de Davi, e repararei as suas brechas, e tornarei a levantar as suas ruínas, e o edificarei como nos dias da antigüidade;',
        'Para que possuam o restante de Edom, e todos os gentios que são chamados pelo meu nome, diz o Senhor, que faz essas coisas.',
        'Eis que vêm dias, diz o Senhor, em que o que lavra alcançará ao que sega, e o que pisa as uvas ao que lança a semente; e os montes destilarão mosto, e todos os outeiros se derreterão.',
        'E trarei do cativeiro meu povo Israel, e eles reedificarão as cidades assoladas, e nelas habitarão, e plantarão vinhas, e beberão o seu vinho, e farão pomares, e lhes comerão o fruto.',
        'E plantá-los-ei na sua terra, e não serão mais arrancados da sua terra que lhes dei, diz o Senhor teu Deus.',
      ],
    ],
    livro: 'amos',
  },
  {
    abbrev: 'ob',
    capitulos: [
      [
        'Visão de Obadias: Assim diz o Senhor DEUS a respeito de Edom: Temos ouvido a pregação do SENHOR, e foi enviado aos gentios um emissário, dizendo: Levantai-vos, e levantemo-nos contra ela para a guerra.',
        'Eis que te fiz pequeno entre os gentios; tu és muito desprezado.',
        'A soberba do teu coração te enganou, como o que habita nas fendas das rochas, na sua alta morada, que diz no seu coração: Quem me derrubará em terra?',
        'Se te elevares como águia, e puseres o teu ninho entre as estrelas, dali te derrubarei, diz o Senhor.',
        'Se viessem a ti ladrões, ou assaltantes de noite (como estás destruído! ), não furtariam o que lhes bastasse? Se a ti viessem os vindimadores, não deixariam algumas uvas?',
        'Como foram rebuscados os bens de Esaú! Como foram investigados os seus tesouros escondidos!',
        'Todos os teus confederados te levaram até a fronteira; os que gozam da tua paz te enganaram, prevaleceram contra ti; os que comem o teu pão puseram debaixo de ti uma armadilha; não há nele entendimento.',
        'Porventura não acontecerá naquele dia, diz o Senhor, que farei perecer os sábios de Edom, e o entendimento do monte de Esaú?',
        'E os teus poderosos, ó Temã, estarão atemorizados, para que do monte de Esaú seja cada um exterminado pela matança.',
        'Por causa da violência feita a teu irmão Jacó, cobrir-te-á a confusão, e serás exterminado para sempre.',
        'No dia em que o confrontaste, no dia em que estranhos levaram cativo o seu exército, e os estrangeiros entravam pelas suas portas, e lançaram sortes sobre Jerusalém, tu eras também como um deles.',
        'Mas tu não devias olhar com prazer para o dia de teu irmão, no dia do seu infortúnio; nem alegrar-te sobre os filhos de Judá, no dia da sua ruína; nem alargar a tua boca, no dia da angústia;',
        'Nem entrar pela porta do meu povo, no dia da sua calamidade; sim, tu não devias olhar satisfeito o seu mal, no dia da sua calamidade; nem lançar mão dos seus bens, no dia da sua calamidade;',
        'Nem parar nas encruzilhadas, para exterminares os que escapassem; nem entregar os que lhe restassem, no dia da angústia.',
        'Porque o dia do Senhor está perto, sobre todos os gentios; como tu fizeste, assim se fará contigo; a tua recompensa voltará sobre a tua cabeça.',
        'Porque, como vós bebestes no meu santo monte, assim beberão também de contínuo todos os gentios; beberão, e sorverão, e serão como se nunca tivessem sido.',
        'Mas no monte Sião haverá livramento, e ele será santo; e os da casa de Jacó possuirão as suas herdades.',
        'E a casa de Jacó será fogo, e a casa de José uma chama, e a casa de Esaú palha; e se acenderão contra eles, e os consumirão; e ninguém mais restará da casa de Esaú, porque o Senhor o falou.',
        'E os do sul possuirão o monte de Esaú, e os das planícies, os filisteus; possuirão também os campos de Efraim, e os campos de Samaria; e Benjamim possuirá a Gileade.',
        'E os cativos deste exército, dos filhos de Israel, possuirão os cananeus, até Zarefate; e os cativos de Jerusalém, que estão em Sefarade, possuirão as cidades do sul.',
        'E subirão salvadores ao monte Sião, para julgarem o monte de Esaú; e o reino será do Senhor.',
      ],
    ],
    livro: 'obadias',
  },
  {
    abbrev: 'jn',
    capitulos: [
      [
        'E veio a palavra do SENHOR a Jonas, filho de Amitai, dizendo:',
        'Levanta-te, vai à grande cidade de Nínive, e clama contra ela, porque a sua malícia subiu até à minha presença.',
        'Porém, Jonas se levantou para fugir da presença do Senhor para Társis. E descendo a Jope, achou um navio que ia para Társis; pagou, pois, a sua passagem, e desceu para dentro dele, para ir com eles para Társis, para longe da presença do Senhor.',
        'Mas o Senhor mandou ao mar um grande vento, e fez-se no mar uma forte tempestade, e o navio estava a ponto de quebrar-se.',
        'Então temeram os marinheiros, e clamavam cada um ao seu deus, e lançaram ao mar as cargas, que estavam no navio, para o aliviarem do seu peso; Jonas, porém, desceu ao porão do navio, e, tendo-se deitado, dormia um profundo sono.',
        'E o mestre do navio chegou- se a ele, e disse-lhe: Que tens, dorminhoco? Levanta-te, clama ao teu Deus; talvez assim ele se lembre de nós para que não pereçamos.',
        'E diziam cada um ao seu companheiro: Vinde, e lancemos sortes, para que saibamos por que causa nos sobreveio este mal. E lançaram sortes, e a sorte caiu sobre Jonas.',
        'Então lhe disseram: Declara-nos tu agora, por causa de quem nos sobreveio este mal. Que ocupação é a tua? Donde vens? Qual é a tua terra? E de que povo és tu?',
        'E ele lhes disse: Eu sou hebreu, e temo ao Senhor, o Deus do céu, que fez o mar e a terra seca.',
        'Então estes homens se enche-ram de grande temor, e disseram-lhe: Por que fizeste tu isto? Pois sabiam os homens que fugia da presença do Senhor, porque ele lho tinha declarado.',
        'E disseram-lhe: Que te faremos nós, para que o mar se nos acalme? Porque o mar ia se tornando cada vez mais tempestuoso.',
        'E ele lhes disse: Levantai-me, e lançai-me ao mar, e o mar se vos aquietará; porque eu sei que por minha causa vos sobreveio esta grande tempestade.',
        'Entretanto, os homens remavam, para fazer voltar o navio à terra, mas não podiam, porquanto o mar se ia embravecendo cada vez mais contra eles.',
        'Então clamaram ao Senhor, e disseram: Ah, Senhor! Nós te rogamos, que não pereçamos por causa da alma deste homem, e que não ponhas sobre nós o sangue inocente; porque tu, Senhor, fizeste como te aprouve.',
        'E levantaram a Jonas, e o lançaram ao mar, e cessou o mar da sua fúria.',
        'Temeram, pois, estes homens ao Senhor com grande temor; e ofereceram sacrifício ao Senhor, e fizeram votos.',
        'Preparou, pois, o Senhor um grande peixe, para que tragasse a Jonas; e esteve Jonas três dias e três noites nas entranhas do peixe.',
      ],
      [
        'E orou Jonas ao SENHOR, seu Deus, das entranhas do peixe.',
        'E disse: Na minha angústia clamei ao Senhor, e ele me respondeu; do ventre do inferno gritei, e tu ouviste a minha voz.',
        'Porque tu me lançaste no profundo, no coração dos mares, e a corrente das águas me cercou; todas as tuas ondas e as tuas vagas têm passado por cima de mim.',
        'E eu disse: Lançado estou de diante dos teus olhos; todavia tornarei a ver o teu santo templo.',
        'As águas me cercaram até à alma, o abismo me rodeou, e as algas se enrolaram na minha cabeça.',
        'Eu desci até aos fundamentos dos montes; a terra me encerrou para sempre com os seus ferrolhos; mas tu fizeste subir a minha vida da perdição, ó Senhor meu Deus.',
        'Quando desfalecia em mim a minha alma, lembrei-me do Senhor; e entrou a ti a minha oração, no teu santo templo.',
        'Os que observam as falsas vaidades deixam a sua misericórdia.',
        'Mas eu te oferecerei sacrifício com a voz do agradecimento; o que votei pagarei. Do Senhor vem a salvação.',
        'Falou, pois, o Senhor ao peixe, e este vomitou a Jonas na terra seca.',
      ],
      [
        'E veio a palavra do SENHOR segunda vez a Jonas, dizendo:',
        'Levanta-te, e vai à grande cidade de Nínive, e prega contra ela a mensagem que eu te digo.',
        'E levantou-se Jonas, e foi a Nínive, segundo a palavra do Senhor. Ora, Nínive era uma cidade muito grande, de três dias de caminho.',
        'E começou Jonas a entrar pela cidade caminho de um dia, e pregava, dizendo: Ainda quarenta dias, e Nínive será subvertida.',
        'E os homens de Nínive creram em Deus; e proclamaram um jejum, e vestiram-se de saco, desde o maior até ao menor.',
        'Esta palavra chegou também ao rei de Nínive; e ele levantou-se do seu trono, e tirou de si as suas vestes, e cobriu-se de saco, e sentou-se sobre a cinza.',
        'E fez uma proclamação que se divulgou em Nínive, pelo decreto do rei e dos seus grandes, dizendo: Nem homens, nem animais, nem bois, nem ovelhas provem coisa alguma, nem se lhes dê alimentos, nem bebam água;',
        'Mas os homens e os animais sejam cobertos de sacos, e clamem fortemente a Deus, e convertam-se, cada um do seu mau caminho, e da violência que há nas suas mãos.',
        'Quem sabe se se voltará Deus, e se arrependerá, e se apartará do furor da sua ira, de sorte que não pereçamos?',
        'E Deus viu as obras deles, como se converteram do seu mau caminho; e Deus se arrependeu do mal que tinha anunciado lhes faria, e não o fez.',
      ],
      [
        'Mas isso desagradou extremamente a Jonas, e ele ficou irado.',
        'E orou ao Senhor, e disse: Ah! Senhor! Não foi esta minha palavra, estando ainda na minha terra? Por isso é que me preveni, fugindo para Társis, pois sabia que és Deus compassivo e misericordioso, longânimo e grande em benignidade, e que te arrependes do mal.',
        'Peço-te, pois, ó Senhor, tira-me a vida, porque melhor me é morrer do que viver.',
        'E disse o Senhor: Fazes bem que assim te ires?',
        'Então Jonas saiu da cidade, e sentou-se ao oriente dela; e ali fez uma cabana, e sentou-se debaixo dela, à sombra, até ver o que aconteceria à cidade.',
        'E fez o Senhor Deus nascer uma aboboreira, e ela subiu por cima de Jonas, para que fizesse sombra sobre a sua cabeça, a fim de o livrar do seu enfado; e Jonas se alegrou em extremo por causa da aboboreira.',
        'Mas Deus enviou um verme, no dia seguinte ao subir da alva, o qual feriu a aboboreira, e esta se secou.',
        'E aconteceu que, aparecendo o sol, Deus mandou um vento calmoso oriental, e o sol feriu a cabeça de Jonas; e ele desmaiou, e desejou com toda a sua alma morrer, dizendo: Melhor me é morrer do que viver.',
        'Então disse Deus a Jonas: Fazes bem que assim te ires por causa da aboboreira? E ele disse: Faço bem que me revolte até à morte.',
        'E disse o Senhor: Tiveste tu compaixão da aboboreira, na qual não trabalhaste, nem a fizeste crescer, que numa noite nasceu, e numa noite pereceu;',
        'E não hei de eu ter compaixão da grande cidade de Nínive, em que estão mais de cento e vinte mil homens que não sabem discernir entre a sua mão direita e a sua mão esquerda, e também muitos animais?',
      ],
    ],
    livro: 'jonas',
  },
  {
    abbrev: 'mq',
    capitulos: [
      [
        'Palavra do SENHOR, que veio a Miquéias, morastita, nos dias de Jotão, Acaz e Ezequias, reis de Judá, a qual ele viu sobre Samaria e Jerusalém.',
        'Ouvi, todos os povos, presta atenção, ó terra, e tudo o que nela há; e seja o Senhor DEUS testemunha contra vós, o Senhor, desde o seu santo templo.',
        'Porque eis que o Senhor está para sair do seu lugar, e descerá, e andará sobre as alturas da terra.',
        'E os montes debaixo dele se derreterão, e os vales se fenderão, como a cera diante do fogo, como as águas que se precipitam num abismo.',
        'Tudo isto por causa da transgressão de Jacó, e dos pecados da casa de Israel. Qual é a transgressão de Jacó? Não é Samaria? E quais os altos de Judá? Não é Jerusalém?',
        'Por isso farei de Samaria um montão de pedras do campo, uma terra de plantar vinhas, e farei rolar as suas pedras no vale, e descobrirei os seus fundamentos.',
        'E todas as suas imagens de escultura serão despedaçadas, e todas as suas ofertas serão queimadas pelo fogo, e de todos os seus ídolos eu farei uma assolação; porque pela paga de prostituta os ajuntou, e para a paga de prostituta voltarão.',
        'Por isso lamentarei, e gemerei, andarei despojado e nu; farei lamentação como de chacais, e pranto como de avestruzes.',
        'Porque a sua chaga é incurável, porque chegou até Judá; estendeu-se até à porta do meu povo, até Jerusalém.',
        'Não o anuncieis em Gate, nem choreis muito; revolve-te no pó, na casa de Afra.',
        'Passa, ó moradora de Safir, em vergonhosa nudez; a moradora de Zaanã não saiu; o pranto de Bete-Ezel tirará de vós a sua posição.',
        'Porque a moradora de Marote sofre pelo bem; porque desceu do Senhor o mal até à porta de Jerusalém.',
        'Ata os animais ligeiros ao carro, ó moradora de Laquis; esta foi o princípio do pecado para a filha de Sião, porque em ti se acharam as transgressões de Israel.',
        'Por isso darás presentes a Moresete-Gate; as casas de Aczibe se tornarão em engano para os reis de Israel.',
        'Ainda trarei a ti, ó moradora de Maressa, aquele que te possuirá; chegará até Adulão a glória de Israel.',
        'Faze-te calva, e tosquia-te, por causa dos filhos das tuas delícias; alarga a tua calva como a águia, porque de ti foram levados cativos.',
      ],
      [
        'Ai daqueles que nas suas camas intentam a iniqüidade, e maquinam o mal; à luz da alva o praticam, porque está no poder da sua mão!',
        'E cobiçam campos, e roubam-nos, cobiçam casas, e arrebatam-nas; assim fazem violência a um homem e à sua casa, a uma pessoa e à sua herança.',
        'Portanto, assim diz o Senhor: Eis que projeto um mal contra esta família, do qual não tirareis os vossos pescoços, e não andareis tão altivos, porque o tempo será mau.',
        'Naquele dia se levantará sobre vós um provérbio, e se lamentará pranto lastimoso, dizendo: Nós estamos inteiramente desolados; a porção do meu povo ele a troca; como me despoja! Tira os nossos campos e os reparte!',
        'Portanto, não terás tu na congregação do Senhor quem lance o cordel pela sorte.',
        'Não profetizeis aos que profetizam; eles não profetizarão para eles, pois não se apartará a sua vergonha.',
        'Ó vós que sois chamados casa de Jacó, porventura encurtou-se o Espírito do Senhor? São estas as suas obras? E não é assim que fazem bem as minhas palavras ao que anda retamente?',
        'Mas ontem, se levantou o meu povo como inimigo; de sobre a vestidura tirastes a capa daqueles que passavam seguros, como homens que voltavam da guerra.',
        'Lançastes fora as mulheres do meu povo, da casa das suas delícias; das suas crianças tirastes para sempre a minha glória.',
        'Levantai-vos, e ide-vos, porque este não é lugar de descanso; por causa da imundícia que traz destruição, sim, destruição enorme.',
        'Se houver alguém que, andando com espírito de falsidade, mentir, dizendo: Eu te profetizarei sobre o vinho e a bebida forte; será esse tal o profeta deste povo.',
        'Certamente te ajuntarei todo, ó Jacó; certamente congregarei o restante de Israel; pô-los-ei todos juntos, como ovelhas de Bozra; como o rebanho no meio do seu pasto, farão estrondo por causa da multidão dos homens.',
        'Subirá diante deles o que abrirá o caminho; eles romperão, e entrarão pela porta, e sairão por ela; e o rei irá adiante deles, e o Senhor à testa deles.',
      ],
      [
        'E disse eu: Ouvi, peço-vos, ó chefes de Jacó, e vós, príncipes da casa de Israel; não é a vós que pertence saber o juízo?',
        'A vós que odiais o bem, e amais o mal, que arrancais a pele de cima deles, e a carne de cima dos seus ossos;',
        'E que comeis a carne do meu povo, e lhes arrancais a pele, e lhes esmiuçais os ossos, e os repartis como para a panela e como carne dentro do caldeirão.',
        'Então clamarão ao Senhor, mas não os ouvirá; antes esconderá deles a sua face naquele tempo, visto que eles fizeram mal nas suas obras.',
        'Assim diz o Senhor acerca dos profetas que fazem errar o meu povo, que mordem com os seus dentes, e clamam paz; mas contra aquele que nada lhes dá na boca preparam guerra.',
        'Portanto, se vos fará noite sem visão, e tereis trevas sem adivinhação, e haverá o sol sobre os profetas, e o dia sobre eles se enegrecerá.',
        'E os videntes se envergonharão, e os adivinhadores se confundirão; sim, todos eles cobrirão os seus lábios, porque não haverá resposta de Deus.',
        'Mas eu estou cheio do poder do Espírito do Senhor, e de juízo e de força, para anunciar a Jacó a sua transgressão e a Israel o seu pecado.',
        'Ouvi agora isto, vós, chefes da casa de Jacó, e príncipes da casa de Israel, que abominais o juízo e perverteis tudo o que é direito,',
        'Edificando a Sião com sangue, e a Jerusalém com iniqüidade.',
        'Os seus chefes dão as sentenças por suborno, e os seus sacerdotes ensinam por interesse, e os seus profetas adivinham por dinheiro; e ainda se encostam ao Senhor, dizendo: Não está o Senhor no meio de nós? Nenhum mal nos sobrevirá.',
        'Portanto, por causa de vós, Sião será lavrada como um campo, e Jerusalém se tornará em montões de pedras, e o monte desta casa como os altos de um bosque.',
      ],
      [
        'Mas nos últimos dias acontecerá que o monte da casa do SENHOR será estabelecido no cume dos montes, e se elevará sobre os outeiros, e a ele afluirão os povos.',
        'E irão muitas nações, e dirão: Vinde, e subamos ao monte do Senhor, e à casa do Deus de Jacó, para que nos ensine os seus caminhos, e andemos pelas suas veredas; porque de Sião sairá a lei, e de Jerusalém a palavra do Senhor.',
        'E julgará entre muitos povos, e castigará nações poderosas e longínquas, e converterão as suas espadas em pás, e as suas lanças em foices; uma nação não levantará a espada contra outra nação, nem aprenderão mais a guerra.',
        'Mas assentar-se-á cada um debaixo da sua videira, e debaixo da sua figueira, e não haverá quem os espante, porque a boca do Senhor dos Exércitos o disse.',
        'Porque todos os povos andam, cada um em nome do seu deus; mas nós andaremos em nome do Senhor nosso Deus, para todo o sempre.',
        'Naquele dia, diz o Senhor, congregarei a que coxeava, e recolherei a que tinha sido expulsa, e a que eu tinha maltratado.',
        'E da que coxeava farei um remanescente, e da que tinha sido arrojada para longe, uma nação poderosa; e o Senhor reinará sobre eles no monte Sião, desde agora e para sempre.',
        'E a ti, ó torre do rebanho, fortaleza da filha de Sião, a ti virá; sim, a ti virá o primeiro domínio, o reino da filha de Jerusalém.',
        'E agora, por que fazes tão grande pranto? Não há em ti rei? Pereceu o teu conselheiro? Apoderou-se de ti a dor, como da que está de parto?',
        'Sofre dores, e trabalha, para dar à luz, ó filha de Sião, como a que está de parto, porque agora sairás da cidade, e morarás no campo, e virás até babilônia; ali, porém, serás livrada; ali te remirá o SENHOR da mão de teus inimigos.',
        'Agora se congregaram muitas nações contra ti, que dizem: Seja profanada, e vejam os nossos olhos o seu desejo sobre Sião.',
        'Mas não sabem os pensamentos do Senhor, nem entendem o seu conselho; porque as ajuntou como gavelas numa eira.',
        'Levanta-te e trilha, ó filha de Sião; porque eu farei de ferro o teu chifre, e de bronze as tuas unhas; e esmiuçarás a muitos povos, e o seu ganho será consagrado ao Senhor, e os seus bens ao Senhor de toda a terra.',
      ],
      [
        'Agora ajunta-te em tropas, ó filha de tropas; pôr-se-á cerco contra nós; ferirão com a vara na face ao juiz de Israel.',
        'E tu, Belém Efrata, posto que pequena entre os milhares de Judá, de ti me sairá o que governará em Israel, e cujas saídas são desde os tempos antigos, desde os dias da eternidade.',
        'Portanto os entregará até ao tempo em que a que está de parto tiver dado à luz; então o restante de seus irmãos voltará aos filhos de Israel.',
        'E ele permanecerá, e apascentará ao povo na força do Senhor, na excelência do nome do Senhor seu Deus; e eles permanecerão, porque agora será engrandecido até aos fins da terra.',
        'E este será a nossa paz; quando a Assíria vier à nossa terra, e quando pisar em nossos palácios, levantaremos contra ela sete pastores e oito príncipes dentre os homens.',
        'Esses consumirão a terra da Assíria à espada, e a terra de Ninrode nas suas entradas. Assim nos livrará da Assíria, quando vier à nossa terra, e quando calcar os nossos termos.',
        'E o remanescente de Jacó estará no meio de muitos povos, como orvalho da parte do Senhor, como chuvisco sobre a erva, que não espera pelo homem, nem aguarda a filhos de homens.',
        'E o restante de Jacó estará entre os gentios, no meio de muitos povos, como um leão entre os animais do bosque, como um leãozinho entre os rebanhos de ovelhas, o qual, quando passar, pisará e despedaçará, sem que haja quem as livre.',
        'A tua mão se exaltará sobre os teus adversários; e todos os teus inimigos serão exterminados.',
        'E sucederá naquele dia, diz o Senhor, que eu exterminarei do meio de ti os teus cavalos, e destruirei os teus carros.',
        'E destruirei as cidades da tua terra, e derrubarei todas as tuas fortalezas;',
        'E exterminarei as feitiçarias da tua mão; e não terás adivinhadores;',
        'E destruirei do meio de ti as tuas imagens de escultura e as tuas estátuas; e tu não te inclinarás mais diante da obra das tuas mãos.',
        'E arrancarei os teus bosques do meio de ti; e destruirei as tuas cidades.',
        'E com ira e com furor farei vingança sobre os gentios que não ouvem.',
      ],
      [
        'Ouvi agora o que diz o SENHOR: Levanta-te, contende com os montes, e ouçam os outeiros a tua voz.',
        'Ouvi, montes, a demanda do Senhor, e vós, fortes fundamentos da terra; porque o Senhor tem uma demanda com o seu povo, e com Israel entrará em juízo.',
        'Ó povo meu; que te tenho feito? E com que te enfadei? Testifica contra mim.',
        'Pois te fiz subir da terra do Egito, e da casa da servidão te remi; e enviei adiante de ti a Moisés, Arão e Miriã.',
        'Povo meu, lembra-te agora do que consultou Balaque, rei de Moabe, e o que lhe respondeu Balaão, filho de Beor, e do que aconteceu desde Sitim até Gilgal, para que conheças as justiças do Senhor.',
        'Com que me apresentarei ao Senhor, e me inclinarei diante do Deus altíssimo? Apresentar-me-ei diante dele com holocaustos, com bezerros de um ano?',
        'Agradar-se-á o Senhor de milhares de carneiros, ou de dez mil ribeiros de azeite? Darei o meu primogênito pela minha transgressão, o fruto do meu ventre pelo pecado da minha alma?',
        'Ele te declarou, ó homem, o que é bom; e que é o que o Senhor pede de ti, senão que pratiques a justiça, e ames a benignidade, e andes humildemente com o teu Deus?',
        'A voz do Senhor clama à cidade e o que é sábio verá o teu nome. Ouvi a vara, e quem a ordenou.',
        'Ainda há na casa do ímpio tesouros da impiedade, e medida escassa, que é detestável?',
        'Seria eu limpo com balanças falsas, e com uma bolsa de pesos enganosos?',
        'Porque os seus ricos estão cheios de violência, e os seus habitantes falam mentiras e a sua língua é enganosa na sua boca.',
        'Assim eu também te enfraquecerei, ferindo-te e assolando-te por causa dos teus pecados.',
        'Tu comerás, mas não te fartarás, e a tua humilhação estará no meio de ti; removerás os teus bens mas não livrarás; e aquilo que livrares, eu o entregarei à espada.',
        'Tu semearás, mas não segarás; pisarás a azeitona, mas não te ungirás com azeite; e pisarás o mosto, mas não beberás vinho.',
        'Porque se observam os estatutos de Onri, e toda a obra da casa de Acabe, e andais nos conselhos deles; para que eu te faça uma desolação, e dos seus habitantes um assobio; assim trareis sobre vós o opróbrio do meu povo.',
      ],
      [
        'Ai de mim! porque estou feito como as colheitas de frutas do verão, como os rabiscos da vindima; não há cacho de uvas para comer, nem figos temporãos que a minha alma deseja.',
        'Já pereceu da terra o homem piedoso, e não há entre os homens um que seja justo; todos armam ciladas para sangue; cada um caça a seu irmão com a rede,',
        'As suas mãos fazem diligentemente o mal; assim demanda o príncipe, e o juiz julga pela recompensa, e o grande fala da corrupção da sua alma, e assim todos eles tecem o mal.',
        'O melhor deles é como um espinho; o mais reto é pior do que a sebe de espinhos; veio o dia dos teus vigias, veio o dia da tua punição; agora será a sua confusão.',
        'Não creiais no amigo, nem confieis no vosso guia; daquela que repousa no teu seio, guarda as portas da tua boca.',
        'Porque o filho despreza ao pai, a filha se levanta contra sua mãe, a nora contra sua sogra, os inimigos do homem são os da sua própria casa.',
        'Eu, porém, olharei para o Senhor; esperarei no Deus da minha salvação; o meu Deus me ouvirá.',
        'Ó inimiga minha, não te alegres a meu respeito; ainda que eu tenha caído, levantar-me-ei; se morar nas trevas, o Senhor será a minha luz.',
        'Sofrerei a ira do Senhor, porque pequei contra ele, até que julgue a minha causa, e execute o meu direito; ele me tirará para a luz, e eu verei a sua justiça.',
        'E a minha inimiga verá isso, e cobri-la-á a vergonha, que me diz: Onde está o Senhor teu Deus? Os meus olhos a contemplarão; agora será ela pisada como a lama das ruas.',
        'No dia em que reedificar os teus muros, nesse dia estará longe e dilatado o estatuto.',
        'Naquele dia virá a ti, desde a Assíria e das cidades fortificadas, e das cidades fortificadas até ao rio, e do mar até ao mar, e da montanha até à montanha.',
        'Mas esta terra será posta em desolação, por causa dos seus moradores, por causa do fruto das suas obras.',
        'Apascenta o teu povo com a tua vara, o rebanho da tua herança, que habita a sós, no bosque, no meio do Carmelo; apascentem-se em Basã e Gileade, como nos dias do passado.',
        'Eu lhes mostrarei maravilhas, como nos dias da tua saída da terra do Egito.',
        'As nações o verão, e envergonhar-se-ão, por causa de todo o seu poder; porão a mão sobre a boca, e os seus ouvidos ficarão surdos.',
        'Lamberão o pó como serpente, como vermes da terra, tremendo, sairão dos seus esconderijos; com pavor virão ao Senhor nosso Deus, e terão medo de ti.',
        'Quem é Deus semelhante a ti, que perdoa a iniqüidade, e que passa por cima da rebelião do restante da sua herança? Ele não retém a sua ira para sempre, porque tem prazer na sua benignidade.',
        'Tornará a apiedar-se de nós; sujeitará as nossas iniqüidades, e tu lançarás todos os seus pecados nas profundezas do mar.',
        'Darás a Jacó a fidelidade, e a Abraão a benignidade, que juraste a nossos pais desde os dias antigos.',
      ],
    ],
    livro: 'miqueias',
  },
  {
    abbrev: 'na',
    capitulos: [
      [
        'Peso de Nínive. Livro da visão de Naum, o elcosita.',
        'O Senhor é Deus zeloso e vingador; o Senhor é vingador e cheio de furor; o Senhor toma vingança contra os seus adversários, e guarda a ira contra os seus inimigos.',
        'O Senhor é tardio em irar-se, mas grande em poder, e ao culpado não tem por inocente; o Senhor tem o seu caminho na tormenta e na tempestade, e as nuvens são o pó dos seus pés.',
        'Ele repreende ao mar, e o faz secar, e esgota todos os rios; desfalecem Basã e o Carmelo, e a flor do Líbano murcha.',
        'Os montes tremem perante ele, e os outeiros se derretem; e a terra se levanta na sua presença; e o mundo, e todos os que nele habitam.',
        'Quem parará diante do seu furor, e quem persistirá diante do ardor da sua ira? A sua cólera se derramou como um fogo, e as rochas foram por ele derrubadas.',
        'O Senhor é bom, ele serve de fortaleza no dia da angústia, e conhece os que confiam nele.',
        'E com uma inundação trasbordante acabará de uma vez com o seu lugar; e as trevas perseguirão os seus inimigos.',
        'Que pensais vós contra o Senhor? Ele mesmo vos consumirá de todo; não se levantará por duas vezes a angústia.',
        'Porque ainda que eles se entrelacem como os espinhos, e se saturem de vinho como bêbados, serão inteiramente consumidos como palha seca.',
        'De ti saiu um que maquinou o mal contra o Senhor, um conselheiro vil.',
        'Assim diz o Senhor: Por mais seguros que estejam, e por mais numerosos que sejam, ainda assim serão exterminados, e ele passará; eu te afligi, mas não te afligirei mais.',
        'Mas agora quebrarei o seu jugo de sobre ti, e romperei os teus laços.',
        'Contra ti, porém, o Senhor deu ordem que não haja mais linhagem do teu nome; da casa dos teus deuses exterminarei as imagens de escultura e de fundição; ali farei o teu sepulcro, porque és vil.',
        'Eis sobre os montes os pés do que traz as boas novas, do que anuncia a paz! Celebra as tuas festas, ó Judá, cumpre os teus votos, porque o ímpio não tornará mais a passar por ti; ele é inteiramente exterminado.',
      ],
      [
        'O destruidor subiu contra ti. Guarda tu a fortaleza, vigia o caminho, fortalece os lombos, reforça muito o teu poder.',
        'Porque o Senhor restaurará a excelência de Jacó como a excelência de Israel; porque os saqueadores os despojaram, e destruíram os seus sarmentos.',
        'Os escudos dos seus fortes serão vermelhos, os homens valorosos estarão vestidos de escarlate, os carros como tochas flamejantes no dia da sua preparação, e os ciprestes serão terrivelmente abalados.',
        'Os carros correrão furiosamente nas ruas, colidirão um contra o outro nos largos caminhos; o seu aspecto será como o de tochas, correrão como relâmpagos.',
        'Ele se lembrará dos seus valentes; eles, porém, tropeçarão na sua marcha; apressar-se-ão para chegar ao seu muro, quando o amparo for preparado.',
        'As portas dos rios se abrirão, e o palácio será dissolvido.',
        'É decretado: ela será levada cativa, conduzida para cima; e as suas servas a acompanharão, gemendo como pombas, batendo em seus peitos.',
        'Nínive desde que existiu tem sido como um tanque de águas, porém elas agora vazam. Parai, parai, clamar-se-á; mas ninguém olhará para trás.',
        'Saqueai a prata, saqueai o ouro, porque não têm fim as provi-sões, riquezas há de todo o gênero de bens desejáveis.',
        'Vazia, esgotada e devastada está; derrete-se o coração, e tremem os joelhos, e em todos os lombos há dor, e os rostos de todos eles se enegrecem.',
        'Onde está agora o covil dos leões, e as pastagens dos leõezinhos, onde passeava o leão velho, e o filhote do leão, sem haver ninguém que os espantasse?',
        'O leão arrebatava o que bastava para os seus filhotes, e estrangulava a presa para as suas leoas, e enchia de presas as suas cavernas, e os seus covis de rapina.',
        'Eis que eu estou contra ti, diz o Senhor dos Exércitos, e queimarei na fumaça os teus carros, e a espada devorará os teus leõezinhos, e arrancarei da terra a tua presa, e não se ouvirá mais a voz dos teus mensageiros.',
      ],
      [
        'Ai da cidade ensangüentada! Ela está toda cheia de mentiras e de rapina; não se aparta dela o roubo.',
        'Estrépito de açoite há, e o barulho do ruído das rodas; e os cavalos atropelam, e carros vão saltando.',
        'O cavaleiro levanta a espada flamejante, como a lança relampejante, e ali haverá uma multidão de mortos, e abundância de cadáveres, e não terão fim os defuntos; tropeçarão nos seus corpos;',
        'Por causa da multidão dos pecados da meretriz mui graciosa, da mestra das feitiçarias, que vendeu as nações com as suas fornicações, e as famílias pelas suas feitiçarias.',
        'Eis que eu estou contra ti, diz o Senhor dos Exércitos; e levantarei a tua saia sobre a tua face, e às nações mostrarei a tua nudez, e aos reinos a tua vergonha.',
        'E lançarei sobre ti coisas abomináveis, e envergonhar-te-ei, e pôr-te-ei como espetáculo.',
        'E há de ser que, todos os que te virem, fugirão de ti, e dirão: Nínive está destruída, quem terá compaixão dela? Donde te buscarei consoladores?',
        'És tu melhor do que Nô-Amom, que está assentada entre os canais do Nilo, cercada de águas, tendo por esplanada o mar, e ainda o mar por muralha?',
        'Etiópia e Egito eram a sua força, e não tinha fim; Pute e Líbia foram o seu socorro.',
        'Todavia foi levada cativa para o desterro; também os seus filhos foram despedaçados nas entradas de todas as ruas, e sobre os seus nobres lançaram sortes, e todos os seus grandes foram presos com grilhões.',
        'Tu também serás embriagada, e te esconderás; também buscarás força por causa do inimigo.',
        'Todas as tuas fortalezas serão como figueiras com figos temporãos; se os sacodem, caem na boca do que os há de comer.',
        'Eis que o teu povo no meio de ti são como mulheres; as portas da tua terra estarão de todo abertas aos teus inimigos; o fogo consumirá os teus ferrolhos.',
        'Tira águas para o cerco, reforça as tuas fortalezas; entra no lodo, e pisa o barro, pega a forma para os tijolos.',
        'O fogo ali te consumirá, a espada te exterminará; consumir-te-á, como a locusta. Multiplica-te como a locusta, multiplica-te como os gafanhotos.',
        'Multiplicaste os teus negociantes mais do que as estrelas do céu; a locusta se espalhará e voará.',
        'Os teus príncipes são como os gafanhotos, e os teus capitàes como os gafanhotos grandes, que se acampam nas sebes nos dias de frio; em subindo o sol voam, de sorte que não se sabe mais o lugar onde estão.',
        'Os teus pastores dormirão, ó rei da Assíria, os teus ilustres repousarão, o teu povo se espalhará pelos montes, sem que haja quem o ajunte.',
        'Não há cura para a tua ferida, a tua chaga é dolorosa. Todos os que ouvirem a tua fama baterão as palmas sobre ti; porque, sobre quem não passou continuamente a tua malícia?',
      ],
    ],
    livro: 'naum',
  },
  {
    abbrev: 'hc',
    capitulos: [
      [
        'O peso que viu o profeta Habacuque.',
        'Até quando, Senhor, clamarei eu, e tu não me escutarás? Gritar-te-ei: Violência! e não salvarás?',
        'Por que razão me mostras a iniqüidade, e me fazes ver a opressão? Pois que a destruição e a violência estão diante de mim, havendo também quem suscite a contenda e o litígio.',
        'Por esta causa a lei se afrouxa, e a justiça nunca se manifesta; porque o ímpio cerca o justo, e a justiça se manifesta distorcida.',
        'Vede entre os gentios e olhai, e maravilhai-vos, e admirai-vos; porque realizarei em vossos dias uma obra que vós não crereis, quando for contada.',
        'Porque eis que suscito os caldeus, nação amarga e impetuosa, que marcha sobre a largura da terra, para apoderar-se de moradas que não são suas.',
        'Horrível e terrível é; dela mesma sairá o seu juízo e a sua dignidade.',
        'E os seus cavalos são mais ligeiros do que os leopardos, e mais espertos do que os lobos à tarde; os seus cavaleiros espalham-se por toda parte; os seus cavaleiros virão de longe; voarão como águias que se apressam a devorar.',
        'Eles todos virão para fazer violência; os seus rostos buscarão o vento oriental, e reunirão os cativos como areia.',
        'E escarnecerão dos reis, e dos príncipes farão zombaria; eles se rirão de todas as fortalezas, porque amontoarão terra, e as tomarão.',
        'Então muda a sua mente, e seguirá, e se fará culpado, atribuindo este seu poder ao seu deus.',
        'Não és tu desde a eternidade, ó Senhor meu Deus, meu Santo? Nós não morreremos. Ó Senhor, para juízo o puseste, e tu, ó Rocha, o fundaste para castigar.',
        'Tu és tão puro de olhos, que não podes ver o mal, e a opressão não podes contemplar. Por que olhas para os que procedem aleivosamente, e te calas quando o ímpio devora aquele que é mais justo do que ele?',
        'E por que farias os homens como os peixes do mar, como os répteis, que não têm quem os governe?',
        'Ele a todos levantará com o anzol, apanhá-los-á com a sua rede, e os ajuntará na sua rede varredoura; por isso ele se alegrará e se regozijará.',
        'Por isso sacrificará à sua rede, e queimará incenso à sua varredoura; porque com elas engordou a sua porção, e engrossou a sua comida.',
        'Porventura por isso esvaziará a sua rede e não terá piedade de matar as nações continuamente?',
      ],
      [
        'Sobre a minha guarda estarei, e sobre a fortaleza me apresentarei e vigiarei, para ver o que falará a mim, e o que eu responderei quando eu for argüido.',
        'Então o Senhor me respondeu, e disse: Escreve a visão e torna-a bem legível sobre tábuas, para que a possa ler quem passa correndo.',
        'Porque a visão é ainda para o tempo determinado, mas se apressa para o fim, e não enganará; se tardar, espera-o, porque certamente virá, não tardará.',
        'Eis que a sua alma está orgulhosa, não é reta nele; mas o justo pela sua fé viverá.',
        'Tanto mais que, por ser dado ao vinho é desleal; homem soberbo que não permanecerá; que alarga como o inferno a sua alma; e é como a morte que não se farta, e ajunta a si todas as nações, e congrega a si todos os povos.',
        'Não levantarão, pois, todos estes contra ele uma parábola e um provérbio sarcástico contra ele? E se dirá: Ai daquele que multiplica o que não é seu! (até quando? ) e daquele que carrega sobre si dívidas!',
        'Porventura não se levantarão de repente os teus extorquiadores, e não despertarão os que te farão tremer, e não lhes servirás tu de despojo?',
        'Porquanto despojaste a muitas nações, todos os demais povos te despojarão a ti, por causa do sangue dos homens, e da violência feita à terra, à cidade, e a todos os que nela habitam.',
        'Ai daquele que, para a sua casa, ajunta cobiçosamente bens mal adquiridos, para pôr o seu ninho no alto, a fim de se livrar do poder do mal!',
        'Vergonha maquinaste para a tua casa; destruindo tu a muitos povos, pecaste contra a tua alma.',
        'Porque a pedra clamará da parede, e a trave lhe responderá do madeiramento.',
        'Ai daquele que edifica a cidade com sangue, e que funda a cidade com iniqüidade!',
        'Porventura não vem do Senhor dos Exércitos que os povos trabalhem pelo fogo e os homens se cansem em vão?',
        'Porque a terra se encherá do conhecimento da glória do Senhor, como as águas cobrem o mar.',
        'Ai daquele que dá de beber ao seu companheiro! Ai de ti, que adicionas à bebida o teu furor, e o embebedas para ver a sua nudez!',
        'Serás farto de ignomínia em lugar de honra; bebe tu também, e sê como um incircunciso; o cálice da mão direita do Senhor voltará a ti, e ignomínia cairá sobre a tua glória.',
        'Porque a violência cometida contra o Líbano te cobrirá, e a destruição das feras te amedrontará, por causa do sangue dos homens, e da violência feita à terra, à cidade, e a todos os que nela habitam.',
        'Que aproveita a imagem de escultura, depois que a esculpiu o seu artífice? Ela é imagem de fundição que ensina mentira, para que quem a formou confie na sua obra, fazendo ídolos mudos?',
        'Ai daquele que diz ao pau: Acorda! e à pedra muda: Desperta! Pode isso ensinar? Eis que está coberta de ouro e de prata, mas dentro dela não há espírito algum.',
        'Mas o Senhor está no seu santo templo; cale-se diante dele toda a terra.',
      ],
      [
        'Oração do profeta Habacuque sobre Sigionote.',
        'Ouvi, Senhor, a tua palavra, e temi; aviva, ó Senhor, a tua obra no meio dos anos, no meio dos anos faze-a conhecida; na tua ira lembra-te da misericórdia.',
        'Deus veio de Temã, e do monte de Parã o Santo (Selá). A sua glória cobriu os céus, e a terra encheu-se do seu louvor.',
        'E o resplendor se fez como a luz, raios brilhantes saíam da sua mão, e ali estava o esconderijo da sua força.',
        'Adiante dele ia a peste, e brasas ardentes saíam dos seus passos.',
        'Parou, e mediu a terra; olhou, e separou as nações; e os montes perpétuos foram esmiuçados; os outeiros eternos se abateram, porque os caminhos eternos lhe pertencem.',
        'Vi as tendas de Cusã em aflição; tremiam as cortinas da terra de Midiã.',
        'Acaso é contra os rios, Senhor, que estás irado? É contra os ribeiros a tua ira, ou contra o mar o teu furor, visto que andas montado sobre os teus cavalos, e nos teus carros de salvação?',
        'Descoberto se movimentou o teu arco; os juramentos feitos às tribos foram uma palavra segura. (Selá. ) Tu fendeste a terra com rios.',
        'Os montes te viram, e tremeram; a inundação das águas passou; o abismo deu a sua voz, levantou ao alto as suas mãos.',
        'O sol e a lua pararam nas suas moradas; andaram à luz das tuas flechas, ao resplendor do relâmpago da tua lança.',
        'Com indignação marchaste pela terra, com ira trilhaste os gentios.',
        'Tu saíste para salvação do teu povo, para salvação do teu ungido; tu feriste a cabeça da casa do ímpio, descobrindo o alicerce até ao pescoço. (Selá.)',
        'Tu traspassaste com as suas próprias lanças a cabeça das suas vilas; eles me acometeram tempestuosos para me espalharem; alegravam-se, como se estivessem para devorar o pobre em segredo.',
        'Tu com os teus cavalos marchaste pelo mar, pela massa de grandes águas.',
        'Ouvindo-o eu, o meu ventre se comoveu, à sua voz tremeram os meus lábios; entrou a podridão nos meus ossos, e estremeci dentro de mim; no dia da angústia descansarei, quando subir contra o povo que invadirá com suas tropas.',
        'Porque ainda que a figueira não floresça, nem haja fruto na vide; ainda que decepcione o produto da oliveira, e os campos não produzam mantimento; ainda que as ovelhas da malhada sejam arrebatadas, e nos currais não haja gado;',
        'Todavia eu me alegrarei no Senhor; exultarei no Deus da minha salvação.',
        'O Senhor Deus é a minha força, e fará os meus pés como os das cervas, e me fará andar sobre as minhas alturas. (Para o cantor-mor sobre os meus instrumentos de corda).',
      ],
    ],
    livro: 'habacuque',
  },
  {
    abbrev: 'sf',
    capitulos: [
      [
        'Palavra do SENHOR, que veio a Sofonias, filho de Cusi, filho de Gedalias, filho de Amarias, filho de Ezequias, nos dias de Josias, filho de Amom, rei de Judá.',
        'Hei de consumir por completo tudo de sobre a terra, diz o Senhor.',
        'Consumirei os homens e os animais, consumirei as aves do céu, e os peixes do mar, e os tropeços juntamente com os ímpios; e exterminarei os homens de sobre a terra, diz o Senhor.',
        'E estenderei a minha mão contra Judá, e contra todos os habitantes de Jerusalém, e exterminarei deste lugar o restante de Baal, e o nome dos sacerdotes dos ídolos, juntamente com os sacerdotes;',
        'E os que sobre os telhados adoram o exército do céu; e os que se inclinam jurando ao Senhor, e juram por Milcom;',
        'E os que deixam de andar em seguimento do Senhor, e os que não buscam ao Senhor, nem perguntam por ele.',
        'Cala-te diante do Senhor DEUS, porque o dia do SENHOR está perto; porque o SENHOR preparou o sacrifício, e santificou os seus convidados.',
        'Acontecerá que, no dia do sacrifício do Senhor, castigarei os príncipes, e os filhos do rei, e todos os que se vestem de trajes estrangeiros.',
        'Castigarei naquele dia todo aquele que salta sobre o limiar, que enche de violência e engano a casa dos seus senhores.',
        'E naquele dia, diz o Senhor, far-se-á ouvir uma voz de clamor desde a porta do peixe, e um uivo desde a segunda parte, e grande quebrantamento desde os outeiros.',
        'Uivai vós, moradores de Mactes, porque todo o povo que mercadejava está arruinado, todos os que estavam carregados de dinheiro foram destruídos.',
        'E há de ser que, naquele tempo, esquadrinharei a Jerusalém com lanternas, e castigarei os homens que se espessam como a borra do vinho, que dizem no seu coração: O Senhor não faz o bem nem faz o mal.',
        'Por isso serão saqueados os seus bens, e assoladas as suas casas; e edificarão casas, mas não habitarão nelas, e plantarão vinhas, mas não lhes beberão o seu vinho.',
        'O grande dia do Senhor está perto, sim, está perto, e se apressa muito; amarga é a voz do dia do Senhor; clamará ali o poderoso.',
        'Aquele dia será um dia de indignação, dia de tribulação e de angústia, dia de alvoroço e de assolação, dia de trevas e de escuridão, dia de nuvens e de densas trevas,',
        'Dia de trombeta e de alarido contra as cidades fortificadas e contra as torres altas.',
        'E angustiarei os homens, que andarão como cegos, porque pecaram contra o Senhor; e o seu sangue se derramará como pó, e a sua carne será como esterco.',
        'Nem a sua prata nem o seu ouro os poderá livrar no dia da indignação do Senhor, mas pelo fogo do seu zelo toda esta terra será consumida, porque certamente fará de todos os moradores da terra uma destruição total e apressada.',
      ],
      [
        'Congregai-vos, sim, congregai-vos, ó nação não desejável;',
        'Antes que o decreto produza o seu efeito, e o dia passe como a pragana; antes que venha sobre vós o furor da ira do Senhor, antes que venha sobre vós o dia da ira do Senhor.',
        'Buscai ao Senhor, vós todos os mansos da terra, que tendes posto por obra o seu juízo; buscai a justiça, buscai a mansidão; pode ser que sejais escondidos no dia da ira do Senhor.',
        'Porque Gaza será desamparada, e Ascalom assolada; Asdode ao meio-dia será expelida, e Ecrom será desarraigada.',
        'Ai dos habitantes da costa do mar, a nação dos quereteus! A palavra do Senhor será contra vós, ó Canaã, terra dos filisteus; e eu vos destruirei, até que não haja morador.',
        'E a costa do mar será de pastos e cabanas para os pastores, e currais para os rebanhos.',
        'E será a costa para o restante da casa de Judá; ali apascentarão os seus rebanhos; de tarde se deitarão nas casas de Ascalom; porque o Senhor seu Deus os visitará, e os fará tornar do seu cativeiro.',
        'Eu ouvi o escárnio de Moabe, e as injuriosas palavras dos filhos de Amom, com que escarneceram do meu povo, e se engrandeceram contra o seu termo.',
        'Portanto, tão certo como eu vivo, diz o Senhor dos Exércitos, o Deus de Israel, Moabe será como Sodoma, e os filhos de Amom como Gomorra, campo de urtigas e poços de sal, e desolação perpétua; o restante do meu povo os saqueará, e o restante do meu povo os possuirá.',
        'Isso terão em recompensa da sua soberba, porque escarneceram, e se engrandeceram contra o povo do Senhor dos Exércitos.',
        'O Senhor será terrível para eles, porque emagrecerá todos os deuses da terra; e todos virão adorá-lo, cada um desde o seu lugar, de todas as ilhas dos gentios.',
        'Também vós, ó etíopes, sereis mortos com a minha espada.',
        'Estenderá também a sua mão contra o norte, e destruirá a Assíria; e fará de Nínive uma desolação, terra seca como o deserto.',
        'E no meio dela repousarão os rebanhos, todos os animais das nações; e alojar-se-ão nos seus capitéis assim o pelicano como o ouriço; o canto das aves se ouvirá nas janelas; e haverá desolação nos limiares, quando tiver descoberto a sua obra de cedro.',
        'Esta é a cidade alegre, que habita despreocupadamente, que diz no seu coração: Eu sou, e não há outra além de mim; como se tornou em desolação, em pousada de animais! Todo o que passar por ela assobiará, e meneará a sua mão.',
      ],
      [
        'Ai da rebelde e contaminada, da cidade opressora!',
        'Não obedeceu à sua voz, não aceitou o castigo; não confiou no Senhor; nem se aproximou do seu Deus.',
        'Os seus príncipes são leões rugidores no meio dela; os seus juízes são lobos da tarde, que não deixam os ossos para a manhã.',
        'Os seus profetas são levianos, homens aleivosos; os seus sacerdotes profanaram o santuário, e fizeram violência à lei.',
        'O Senhor é justo no meio dela; ele não comete iniqüidade; cada manhã traz o seu juízo à luz; nunca falta; mas o perverso não conhece a vergonha.',
        'Exterminei as nações, as suas torres estão assoladas; fiz desertas as suas praças, a ponto de não ficar quem passe por elas; as suas cidades foram destruídas, até não ficar ninguém, até não haver quem as habite.',
        'Eu dizia: Certamente me temerás, e aceitarás a correção, e assim a sua morada não seria destruída, conforme tudo aquilo porque a castiguei; mas eles se levantaram de madrugada, corromperam todas as suas obras.',
        'Portanto esperai-me, diz o Senhor, no dia em que eu me levantar para o despojo; porque o meu decreto é ajuntar as nações e congregar os reinos, para sobre eles derramar a minha indignação, e todo o ardor da minha ira; porque toda esta terra será consumida pelo fogo do meu zelo.',
        'Porque então darei uma linguagem pura aos povos, para que todos invoquem o nome do Senhor, para que o sirvam com um mesmo consenso.',
        'Dalém dos rios da Etiópia, meus zelosos adoradores, que constituem a filha dos meus dispersos, me trarão sacrifício.',
        'Naquele dia não te envergonharás de nenhuma das tuas obras, com as quais te rebelaste contra mim; porque então tirarei do meio de ti os que exultam na tua soberba, e tu nunca mais te ensoberbecerás no meu monte santo.',
        'Mas deixarei no meio de ti um povo humilde e pobre; e eles confiarão no nome do Senhor.',
        'O remanescente de Israel não cometerá iniqüidade, nem proferirá mentira, e na sua boca não se achará língua enganosa; mas serão apascentados, e deitar-se-ão, e não haverá quem os espante.',
        'Canta alegremente, ó filha de Sião; rejubila, ó Israel; regozija- te, e exulta de todo o coração, ó filha de Jerusalém.',
        'O Senhor afastou os teus juízos, exterminou o teu inimigo; o Senhor, o rei de Israel, está no meio de ti; tu não verás mais mal algum.',
        'Naquele dia se dirá a Jerusalém: Não temas, ó Sião, não se enfraqueçam as tuas mãos.',
        'O Senhor teu Deus, o poderoso, está no meio de ti, ele salvará; ele se deleitará em ti com alegria; calar-se-á por seu amor, regozijar-se-á em ti com júbilo.',
        'Os entristecidos por causa da reunião solene, congregarei; esses que são de ti e para os quais o opróbrio dela era um peso.',
        'Eis que naquele tempo procederei contra todos os que te afligem, e salvarei a que coxeia, e recolherei a que foi expulsa; e deles farei um louvor e um nome em toda a terra em que foram envergonhados.',
        'Naquele tempo vos farei voltar, naquele tempo vos recolherei; certamente farei de vós um nome e um louvor entre todos os povos da terra, quando fizer voltar os vossos cativos diante dos vossos olhos, diz o Senhor.',
      ],
    ],
    livro: 'sofonias',
  },
  {
    abbrev: 'ag',
    capitulos: [
      [
        'No segundo ano do rei Dario, no sexto mês, no primeiro dia do mês, veio a palavra do SENHOR, por intermédio do profeta Ageu, a Zorobabel, filho de Sealtiel, governador de Judá, e a Josué, filho de Jozadaque, o sumo sacerdote, dizendo:',
        'Assim fala o Senhor dos Exércitos, dizendo: Este povo diz: Não veio ainda o tempo, o tempo em que a casa do Senhor deve ser edificada.',
        'Veio, pois, a palavra do Senhor, por intermédio do profeta Ageu, dizendo:',
        'Porventura é para vós tempo de habitardes nas vossas casas forradas, enquanto esta casa fica deserta?',
        'Ora, pois, assim diz o Senhor dos Exércitos: Considerai os vossos caminhos.',
        'Semeais muito, e recolheis pouco; comeis, porém não vos fartais; bebeis, porém não vos saciais; vestis-vos, porém ninguém se aquece; e o que recebe salário, recebe-o num saco furado.',
        'Assim diz o Senhor dos Exércitos: Considerai os vossos caminhos.',
        'Subi ao monte, e trazei madeira, e edificai a casa; e dela me agradarei, e serei glorificado, diz o Senhor.',
        'Esperastes o muito, mas eis que veio a ser pouco; e esse pouco, quando o trouxestes para casa, eu dissipei com um sopro. Por que causa? disse o Senhor dos Exércitos. Por causa da minha casa, que está deserta, enquanto cada um de vós corre à sua própria casa.',
        'Por isso retém os céus sobre vós o orvalho, e a terra detém os seus frutos.',
        'E mandei vir a seca sobre a terra, e sobre os montes, e sobre o trigo, e sobre o mosto, e sobre o azeite, e sobre o que a terra produz; como também sobre os homens, e sobre o gado, e sobre todo o trabalho das mãos.',
        'Então Zorobabel, filho de Sealtiel, e Josué, filho de Jozadaque, sumo sacerdote, e todo o restante do povo obedeceram à voz do Senhor seu Deus, e às palavras do profeta Ageu, assim como o Senhor seu Deus o enviara; e temeu o povo diante do Senhor.',
        'Então Ageu, o mensageiro do Senhor, falou ao povo conforme a mensagem do Senhor, dizendo: Eu sou convosco, diz o Senhor.',
        'E o Senhor suscitou o espírito de Zorobabel, filho de Sealtiel, governador de Judá, e o espírito de Josué, filho de Jozadaque, sumo sacerdote, e o espírito de todo o restante do povo, e eles vieram, e fizeram a obra na casa do Senhor dos Exércitos, seu Deus,',
        'Ao vigésimo quarto dia do sexto mês, no segundo ano do rei Dario.',
      ],
      [
        'No sétimo mês, ao vigésimo primeiro dia do mês, veio a palavra do SENHOR por intermédio do profeta Ageu, dizendo:',
        'Fala agora a Zorobabel, filho de Sealtiel, governador de Judá, e a Josué, filho de Jozadaque, sumo sacerdote, e ao restante do povo, dizendo:',
        'Quem há entre vós que, tendo ficado, viu esta casa na sua primeira glória? E como a vedes agora? Não é esta como nada diante dos vossos olhos, comparada com aquela?',
        'Ora, pois, esforça-te, Zorobabel, diz o Senhor, e esforça-te, Josué, filho de Jozadaque, sumo sacerdote, e esforça-te, todo o povo da terra, diz o Senhor, e trabalhai; porque eu sou convosco, diz o Senhor dos Exércitos.',
        'Segundo a palavra da aliança que fiz convosco, quando saístes do Egito, o meu Espírito permanece no meio de vós; não temais.',
        'Porque assim diz o Senhor dos Exércitos: Ainda uma vez, daqui a pouco, farei tremer os céus e a terra, o mar e a terra seca;',
        'E farei tremer todas as nações, e virão coisas preciosas de todas as nações, e encherei esta casa de glória, diz o Senhor dos Exércitos.',
        'Minha é a prata, e meu é o ouro, disse o Senhor dos Exércitos.',
        'A glória desta última casa será maior do que a da primeira, diz o Senhor dos Exércitos, e neste lugar darei a paz, diz o Senhor dos Exércitos.',
        'Ao vigésimo quarto dia do mês nono, no segundo ano de Dario, veio a palavra do Senhor por intermédio do profeta Ageu, dizendo:',
        'Assim diz o Senhor dos Exércitos: Pergunta agora aos sacerdotes, acerca da lei, dizendo:',
        'Se alguém leva carne santa na orla das suas vestes, e com ela tocar no pão, ou no guisado, ou no vinho, ou no azeite, ou em outro qualquer mantimento, porventura ficará isto santificado? E os sacerdotes responderam: Não.',
        'E disse Ageu: Se alguém que for contaminado pelo contato com o corpo morto, tocar nalguma destas coisas, ficará ela imunda? E os sacerdotes responderam, dizendo: Ficará imunda.',
        'Então respondeu Ageu, dizendo: Assim é este povo, e assim é esta nação diante de mim, diz o Senhor; e assim é toda a obra das suas mãos; e tudo o que ali oferecem imundo é.',
        'Agora, pois, eu vos rogo, considerai isto, desde este dia em diante, antes que se lançasse pedra sobre pedra no templo do Senhor,',
        'Antes que sucedessem estas coisas, vinha alguém a um montão de grão, de vinte medidas, e havia somente dez; quando vinha ao lagar para tirar cinqüenta, havia somente vinte.',
        'Feri-vos com queimadura, e com ferrugem, e com saraiva, em toda a obra das vossas mãos, e não houve entre vós quem voltasse para mim, diz o Senhor.',
        'Considerai, pois, vos rogo, desde este dia em diante; desde o vigésimo quarto dia do mês nono, desde o dia em que se fundou o templo do Senhor, considerai essas coisas.',
        'Porventura há ainda semente no celeiro? Além disso a videira, a figueira, a romeira, a oliveira, não têm dado os seus frutos; mas desde este dia vos abençoarei.',
        'E veio a palavra do Senhor segunda vez a Ageu, aos vinte e quatro dias do mês, dizendo:',
        'Fala a Zorobabel, governador de Judá, dizendo: Farei tremer os céus e a terra;',
        'E transtornarei o trono dos reinos, e destruirei a força dos reinos dos gentios; e transtornarei os carros e os que neles andam; e os cavalos e os seus cavaleiros cairão, cada um pela espada do seu irmão.',
        'Naquele dia, diz o Senhor dos Exércitos, tomar-te-ei, ó Zorobabel, servo meu, filho de Sealtiel, diz o Senhor, e far-te-ei como um anel de selar; porque te escolhi, diz o Senhor dos Exércitos.',
      ],
    ],
    livro: 'ageu',
  },
  {
    abbrev: 'zc',
    capitulos: [
      [
        'No oitavo mês do segundo ano de Dario veio a palavra do SENHOR ao profeta Zacarias, filho de Baraquias, filho de Ido, dizendo:',
        'O Senhor se irou fortemente contra vossos pais.',
        'Portanto dize-lhes: Assim diz o Senhor dos Exércitos: Tornai-vos para mim, diz o Senhor dos Exércitos, e eu me tornarei para vós, diz o Senhor dos Exércitos.',
        'E não sejais como vossos pais, aos quais clamavam os primeiros profetas, dizendo: Assim diz o Senhor dos Exércitos: Convertei-vos agora dos vossos maus caminhos e das vossas más obras; mas não ouviram, nem me escutaram, diz o Senhor.',
        'Vossos pais, onde estão? E os profetas, viverão eles para sempre?',
        'Contudo as minhas palavras e os meus estatutos, que eu ordenei aos profetas, meus servos, não alcançaram a vossos pais? E eles voltaram, e disseram: Assim como o Senhor dos Exércitos fez tenção de nos tratar, segundo os nossos caminhos, e segundo as nossas obras, assim ele nos tratou.',
        'Aos vinte e quatro dias do mês undécimo (que é o mês de Sebate), no segundo ano de Dario, veio a palavra do Senhor ao profeta Zacarias, filho de Baraquias, filho de Ido, dizendo:',
        'Olhei de noite, e vi um homem montado num cavalo vermelho; e ele estava parado entre as murtas que estavam na baixada; e atrás dele estavam cavalos vermelhos, malhados e brancos.',
        'E eu disse: Senhor meu, quem são estes? E disse-me o anjo que falava comigo: Eu te mostrarei quem são estes.',
        'Então respondeu o homem que estava entre as murtas, e disse: Estes são os que o Senhor tem enviado para percorrerem a terra.',
        'E eles responderam ao anjo do Senhor, que estava entre as murtas, e disseram: Nós já percorremos a terra, e eis que toda a terra está tranqüila e quieta.',
        'Então o anjo do Senhor respondeu, e disse: Ó Senhor dos Exércitos, até quando não terás compaixão de Jerusalém, e das cidades de Judá, contra as quais estiveste irado estes setenta anos?',
        'E respondeu o Senhor ao anjo, que falava comigo, com palavras boas, palavras consoladoras.',
        'E o anjo que falava comigo disse-me: Clama, dizendo: Assim diz o Senhor dos Exércitos: Com grande zelo estou zelando por Jerusalém e por Sião.',
        'E com grande indignação estou irado contra os gentios em descanso; porque eu estava pouco indignado, mas eles agravaram o mal.',
        'Portanto, assim diz o Senhor: Voltei-me para Jerusalém com misericórdia; nela será edificada a minha casa, diz o Senhor dos Exércitos, e o cordel será estendido sobre Jerusalém:',
        'Clama outra vez, dizendo: Assim diz o Senhor dos Exércitos: As minhas cidades ainda aumentarão e prosperarão; porque o Senhor ainda consolará a Sião e ainda escolherá a Jerusalém.',
        'E levantei os meus olhos, e vi, e eis quatro chifres.',
        'E eu disse ao anjo que falava comigo: Que são estes? E ele me disse: Estes são os chifres que dispersaram a Judá, a Israel e a Jerusalém.',
        'E o Senhor me mostrou quatro carpinteiros.',
        'Então eu disse: Que vêm estes fazer? E ele falou, dizendo: Estes são os chifres que dispersaram a Judá, de maneira que ninguém pôde levantar a sua cabeça; estes, pois, vieram para os amedrontarem, para derrubarem os chifres dos gentios que levantaram o seu poder contra a terra de Judá, para a espalharem.',
      ],
      [
        'Tornei a levantar os meus olhos, e vi, e eis um homem que tinha na mão um cordel de medir.',
        'E eu disse: Para onde vais tu? E ele me disse: Vou medir Jerusalém, para ver qual é a sua largura e qual o seu comprimento.',
        'E eis que saiu o anjo que falava comigo, e outro anjo lhe saiu ao encontro.',
        'E disse-lhe: Corre, fala a este jovem, dizendo: Jerusalém será habitada como as aldeias sem muros, por causa da multidão dos homens e dos animais que haverá nela.',
        'Pois eu, diz o Senhor, serei para ela um muro de fogo em redor, e para glória estarei no meio dela.',
        'Ah, ah! Fugi agora da terra do norte, diz o Senhor, porque vos espalhei pelos quatro ventos do céu, diz o Senhor.',
        'Ah! Sião! Escapa, tu, que habitas com a filha de babilônia.',
        'Porque assim diz o Senhor dos Exércitos: Depois da glória ele me enviou às nações que vos despojaram; porque aquele que tocar em vós toca na menina do seu olho.',
        'Porque eis aí levantarei a minha mão sobre eles, e eles virão a ser a presa daqueles que os serviram; assim sabereis vós que o Senhor dos Exércitos me enviou.',
        'Exulta, e alegra-te ó filha de Sião, porque eis que venho, e habitarei no meio de ti, diz o Senhor.',
        'E naquele dia muitas nações se ajuntarão ao Senhor, e serão o meu povo, e habitarei no meio de ti e saberás que o Senhor dos Exércitos me enviou a ti.',
        'Então o Senhor herdará a Judá como sua porção na terra santa, e ainda escolherá a Jerusalém.',
        'Cala-te, toda a carne, diante do Senhor, porque ele se levantou da sua santa morada.',
      ],
      [
        'E ele mostrou-me o sumo sacerdote Josué, o qual estava diante do anjo do SENHOR, e Satanás estava à sua mão direita, para se lhe opor.',
        'Mas o Senhor disse a Satanás: O Senhor te repreenda, ó Satanás, sim, o Senhor, que escolheu Jerusalém, te repreenda; não é este um tição tirado do fogo?',
        'Josué, vestido de vestes sujas, estava diante do anjo.',
        'Então respondeu, aos que estavam diante dele, dizendo: Tirai-lhe estas vestes sujas. E a Josué disse: Eis que tenho feito com que passe de ti a tua iniqüidade, e te vestirei de vestes finas.',
        'E disse eu: Ponham-lhe uma mitra limpa sobre a sua cabeça. E puseram uma mitra limpa sobre a sua cabeça, e vestiram-no das roupas; e o anjo do Senhor estava em pé.',
        'E o anjo do Senhor protestou a Josué, dizendo:',
        'Assim diz o Senhor dos Exércitos: Se andares nos meus caminhos, e se observares a minha ordenança, também tu julgarás a minha casa, e também guardarás os meus átrios, e te darei livre acesso entre os que estão aqui.',
        'Ouve, pois, Josué, sumo sacerdote, tu e os teus companheiros que se assentam diante de ti, porque são homens portentosos; eis que eu farei vir o meu servo, o RENOVO.',
        'Porque eis aqui a pedra que pus diante de Josué; sobre esta pedra única estão sete olhos; eis que eu esculpirei a sua escultura, diz o Senhor dos Exércitos, e tirarei a iniqüidade desta terra num só dia.',
        'Naquele dia, diz o Senhor dos Exércitos, cada um de vós convidará o seu próximo para debaixo da videira e para debaixo da figueira.',
      ],
      [
        'E o anjo que falava comigo voltou, e despertou-me, como a um homem que é despertado do seu sono,',
        'E disse-me: Que vês? E eu disse: Olho, e eis que vejo um castiçal todo de ouro, e um vaso de azeite no seu topo, com as suas sete lâmpadas; e sete canudos, um para cada uma das lâmpadas que estão no seu topo.',
        'E, por cima dele, duas oliveiras, uma à direita do vaso de azeite, e outra à sua esquerda.',
        'E respondi, dizendo ao anjo que falava comigo: Senhor meu, que é isto?',
        'Então respondeu o anjo que falava comigo, dizendo-me: Não sabes tu o que é isto? E eu disse: Não, senhor meu.',
        'E respondeu-me, dizendo: Esta é a palavra do Senhor a Zorobabel, dizendo: Não por força nem por violência, mas sim pelo meu Espírito, diz o Senhor dos Exércitos.',
        'Quem és tu, ó grande monte? Diante de Zorobabel tornar-te-ás uma campina; porque ele trará a pedra angular com aclamações: Graça, graça a ela.',
        'E a palavra do Senhor veio novamente a mim, dizendo:',
        'As mãos de Zorobabel têm lançado os alicerces desta casa; também as suas mãos a acabarão, para que saibais que o Senhor dos Exércitos me enviou a vós.',
        'Porque, quem despreza o dia das coisas pequenas? Pois esses se alegrarão, vendo o prumo na mão de Zorobabel; esses são os sete olhos do Senhor, que percorrem por toda a terra.',
        'Respondi mais, dizendo-lhe: Que são as duas oliveiras à direita e à esquerda do castiçal?',
        'E, respondendo-lhe outra vez, disse: Que são aqueles dois ramos de oliveira, que estão junto aos dois tubos de ouro, e que vertem de si azeite dourado?',
        'E ele me falou, dizendo: Não sabes tu o que é isto? E eu disse: Não, senhor meu.',
        'Então ele disse: Estes são os dois ungidos, que estão diante do Senhor de toda a terra.',
      ],
      [
        'E outra vez levantei os meus olhos, e vi, e eis um rolo volante.',
        'E disse-me o anjo: Que vês? E eu disse: Vejo um rolo volante, que tem vinte côvados de comprido e dez côvados de largo.',
        'Então disse-me: Esta é a maldição que sairá pela face de toda a terra; porque qualquer que furtar será desarraigado, conforme está estabelecido de um lado do rolo; como também qualquer que jurar falsamente, será desarraigado, conforme está estabelecido do outro lado do rolo.',
        'Eu a farei sair, disse o Senhor dos Exércitos, e ela entrará na casa do ladrão, e na casa do que jurar falsamente pelo meu nome; e permanecerá no meio da sua casa, e a consumirá juntamente com a sua madeira e com as suas pedras.',
        'E saiu o anjo, que falava comigo, e disse-me: Levanta agora os teus olhos, e vê que é isto que sai.',
        'E eu disse: Que é isto? E ele disse: Isto é um efa que sai. Disse ainda: Este é o aspecto deles em toda a terra.',
        'E eis que foi levantado um talento de chumbo, e uma mulher estava assentada no meio do efa.',
        'E ele disse: Esta é a impiedade. E a lançou dentro do efa; e lançou sobre a boca deste o peso de chumbo.',
        'E levantei os meus olhos, e vi, e eis que saíram duas mulheres; e traziam vento nas suas asas, pois tinham asas como as da cegonha; e levantaram o efa entre a terra e o céu.',
        'Então eu disse ao anjo que falava comigo: Para onde levam elas o efa?',
        'E ele me disse: Para lhe edificarem uma casa na terra de Sinar; e, estando ela acabada, ele será posto ali na sua base.',
      ],
      [
        'E outra vez levantei os meus olhos, e vi, e eis que quatro carros saiam dentre dois montes, e estes montes eram montes de bronze.',
        'No primeiro carro eram cavalos vermelhos, e no segundo carro, cavalos pretos,',
        'E no terceiro carro, cavalos brancos, e no quarto carro, cavalos malhados, todos eram fortes.',
        'E respondi, dizendo ao anjo que falava comigo: Que é isto, senhor meu?',
        'E o anjo respondeu, dizendo-me: Estes são os quatro espíritos dos céus, saindo donde estavam perante o Senhor de toda a terra.',
        'O carro em que estão os cavalos pretos, sai para a terra do norte, e os brancos saem atrás deles, e os malhados saem para a terra do sul.',
        'E os cavalos fortes saíam, e procuravam ir por diante, para percorrerem a terra. E ele disse: Ide, percorrei a terra. E percorreram a terra.',
        'E chamou-me, e falou-me, dizendo: Eis que aqueles que saíram para a terra do norte fizeram repousar o meu Espírito na terra do norte.',
        'E a palavra do Senhor veio a mim, dizendo:',
        'Toma dos que foram levados cativos, a saber, de Heldai, de Tobias e de Jedaías, os quais vieram de babilônia, e vem tu no mesmo dia, e entra na casa de Josias, filho de Sofonias.',
        'Toma, digo, prata e ouro, e faze coroas, e põe-nas na cabeça do sumo sacerdote Josué, filho de Jozadaque.',
        'E fala-lhe, dizendo: Assim diz o SENHOR dos Exércitos: Eis aqui o homem cujo nome é RENOVO; ele brotará do seu lugar, e edificará o templo do SENHOR.',
        'Ele mesmo edificará o templo do Senhor, e ele levará a glória; assentar-se-á no seu trono e dominará, e será sacerdote no seu trono, e conselho de paz haverá entre ambos os ofícios.',
        'E estas coroas serão para Helém, e para Tobias, e para Jedaías, e para Hem, filho de Sofonias, como um memorial no templo do Senhor.',
        'E aqueles que estão longe virão, e edificarão no templo do Senhor, e vós sabereis que o Senhor dos Exércitos me tem enviado a vós; e isto sucederá assim, se diligentemente ouvirdes a voz do Senhor vosso Deus.',
      ],
      [
        'Aconteceu, no quarto ano do rei Dario, que a palavra do SENHOR veio a Zacarias, no quarto dia do nono mês, que é Quisleu.',
        'Quando o povo enviou Sarezer e Régen-Meleque, e os seus homens, à casa de Deus, para suplicarem o favor do Senhor,',
        'E para dizerem aos sacerdotes, que estavam na casa do Senhor dos Exércitos, e aos profetas: Chorarei eu no quinto mês, fazendo abstinência, como tenho feito por tantos anos?',
        'Então a palavra do Senhor dos Exércitos veio a mim, dizendo:',
        'Fala a todo o povo desta terra, e aos sacerdotes, dizendo: Quando jejuastes, e pranteastes, no quinto e no sétimo mês, durante estes setenta anos, porventura, foi mesmo para mim que jejuastes?',
        'Ou quando comestes, e quando bebestes, não foi para vós mesmos que comestes e bebestes?',
        'Não foram estas as palavras que o Senhor pregou pelo ministério dos primeiros profetas, quando Jerusalém estava habitada e em paz, com as suas cidades ao redor dela, e o sul e a campina eram habitados?',
        'E a palavra do Senhor veio a Zacarias, dizendo:',
        'Assim falou o Senhor dos Exércitos, dizendo: Executai juízo verdadeiro, mostrai piedade e misericórdia cada um para com seu irmão.',
        'E não oprimais a viúva, nem o órfão, nem o estrangeiro, nem o pobre, nem intente cada um, em seu coração, o mal contra o seu irmão.',
        'Eles, porém, não quiseram escutar, e deram-me o ombro rebelde, e ensurdeceram os seus ouvidos, para que não ouvissem.',
        'Sim, fizeram os seus corações como pedra de diamante, para que não ouvissem a lei, nem as palavras que o Senhor dos Exércitos enviara pelo seu Espírito por intermédio dos primeiros profetas; daí veio a grande ira do Senhor dos Exércitos.',
        'E aconteceu que, assim como ele clamou e eles não ouviram, também eles clamaram, e eu não ouvi, diz o Senhor dos Exércitos.',
        'Assim os espalhei com um turbilhão por entre todas as nações, que eles não conheceram, e a terra foi assolada atrás deles, de sorte que ninguém passava por ela, nem se voltava; porque fizeram da terra desejada uma desolação.',
      ],
      [
        'Depois veio a mim a palavra do SENHOR dos Exércitos, dizendo:',
        'Assim diz o Senhor dos Exércitos: Zelei por Sião com grande zelo, e com grande indignação zelei por ela.',
        'Assim diz o Senhor: Voltarei para Sião, e habitarei no meio de Jerusalém; e Jerusalém chamar-se-á a cidade da verdade, e o monte do Senhor dos Exércitos, o monte santo.',
        'Assim diz o Senhor dos Exércitos: Ainda nas praças de Jerusalém habitarão velhos e velhas; levando cada um, na mão, o seu bordão, por causa da sua muita idade.',
        'E as ruas da cidade se encherão de meninos e meninas, que nelas brincarão.',
        'Assim diz o Senhor dos Exércitos: Se isto for maravilhoso aos olhos do restante deste povo naqueles dias, será também maravilhoso aos meus olhos? diz o Senhor dos Exércitos.',
        'Assim diz o Senhor dos Exércitos: Eis que salvarei o meu povo da terra do oriente e da terra do ocidente;',
        'E trá-los-ei, e habitarão no meio de Jerusalém; e eles serão o meu povo, e eu lhes serei o seu Deus em verdade e em justiça.',
        'Assim diz o Senhor dos Exércitos: Esforcem-se as vossas mãos, ó vós que nestes dias ouvistes estas palavras da boca dos profetas, que estiveram no dia em que foi posto o fundamento da casa do Senhor dos Exércitos, para que o templo fosse edificado.',
        'Porque antes destes dias não tem havido salário para os homens, nem lhes davam ganhos os animais; nem havia paz para o que entrava nem para o que saía, por causa do inimigo, porque eu incitei a todos os homens, cada um contra o seu próximo.',
        'Mas agora não serei para com o restante deste povo como nos primeiros dias, diz o Senhor dos Exércitos.',
        'Porque haverá semente de prosperidade; a vide dará o seu fruto, e a terra dará a sua novidade, e os céus darão o seu orvalho; e farei que o restante deste povo herde tudo isto.',
        'E há de suceder, ó casa de Judá, e casa de Israel, que, assim como fostes uma maldição entre os gentios, assim vos salvarei, e sereis uma bênção; não temais, esforcem-se as vossas mãos.',
        'Porque assim diz o Senhor dos Exércitos: Como pensei fazer-vos mal, quando vossos pais me provocaram à ira, diz o Senhor dos Exércitos, e não me arrependi,',
        'Assim tornei a pensar nestes dias fazer o bem a Jerusalém e à casa de Judá; não temais.',
        'Estas são as coisas que deveis fazer: Falai a verdade cada um com o seu próximo; executai juízo de verdade e de paz nas vossas portas.',
        'E nenhum de vós pense mal no seu coração contra o seu próximo, nem ameis o juramento falso; porque todas estas são coisas que eu odeio diz o Senhor.',
        'E a palavra do Senhor dos Exércitos veio a mim, dizendo:',
        'Assim diz o Senhor dos Exércitos: O jejum do quarto, e o jejum do quinto, e o jejum do sétimo, e o jejum do décimo mês será para a casa de Judá gozo, alegria, e festividades solenes; amai, pois, a verdade e a paz.',
        'Assim diz o Senhor dos Exércitos: Ainda sucederá que virão os povos e os habitantes de muitas cidades.',
        'E os habitantes de uma cidade irão à outra, dizendo: Vamos depressa suplicar o favor do Senhor, e buscar o Senhor dos Exércitos; eu também irei.',
        'Assim virão muitos povos e poderosas nações, a buscar em Jerusalém ao Senhor dos Exércitos, e a suplicar o favor do Senhor.',
        'Assim diz o Senhor dos Exércitos: Naquele dia sucederá que pegarão dez homens, de todas as línguas das nações, pegarão, sim, na orla das vestes de um judeu, dizendo: Iremos convosco, porque temos ouvido que Deus está convosco.',
      ],
      [
        'O peso da palavra do SENHOR contra a terra de Hadraque, e Damasco, o seu repouso; porque o olhar do homem, e de todas as tribos de Israel, se volta para o SENHOR.',
        'E também Hamate que confina com ela, e Tiro e Sidom, ainda que sejam mais sábias.',
        'E Tiro edificou para si fortalezas, e amontoou prata como o pó, e ouro fino como a lama das ruas.',
        'Eis que o Senhor a despojará e ferirá no mar a sua força, e ela será consumida pelo fogo.',
        'Ascalom o verá e temerá; também Gaza, e terá grande dor; igualmente Ecrom; porque a sua esperança será confundida; e o rei de Gaza perecerá, e Ascalom não será habitada.',
        'E um bastardo habitará em Asdode, e exterminarei a soberba dos filisteus.',
        'E da sua boca tirarei o seu sangue, e dentre os seus dentes as suas abominações; e ele também ficará como um remanescente para o nosso Deus; e será como governador em Judá, e Ecrom como um jebuseu.',
        'E acampar-me-ei ao redor da minha casa, contra o exército, para que ninguém passe, nem volte; para que não passe mais sobre eles o opressor; porque agora vi com os meus olhos.',
        'Alegra-te muito, ó filha de Sião; exulta, ó filha de Jerusalém; eis que o teu rei virá a ti, justo e Salvador, pobre, e montado sobre um jumento, e sobre um jumentinho, filho de jumenta.',
        'E de Efraim destruirei os carros, e de Jerusalém os cavalos; e o arco de guerra será destruído, e ele anunciará paz aos gentios; e o seu domínio se estenderá de mar a mar, e desde o rio até às extremidades da terra.',
        'Ainda quanto a ti, por causa do sangue da tua aliança, libertei os teus presos da cova em que não havia água.',
        'Voltai à fortaleza, ó presos de esperança; também hoje vos anuncio que vos restaurarei em dobro.',
        'Porque curvei Judá para mim, enchi com Efraim o arco; suscitarei a teus filhos, ó Sião, contra os teus filhos, ó Grécia! E pôr-te-ei, ó Sião, como a espada de um poderoso.',
        'E o SENHOR será visto sobre eles, e as suas flechas sairão como o relâmpago; e o Senhor DEUS fará soar a trombeta, e irá com os redemoinhos do sul.',
        'O Senhor dos Exércitos os amparará; eles devorarão, depois que os tiverem sujeitado, as pedras da funda; também beberão e farão barulho como excitados pelo vinho; e encher-se-ão como bacias de sacrifício, como os cantos do altar.',
        'E o Senhor seu Deus naquele dia os salvará, como ao rebanho do seu povo: porque como pedras de uma coroa eles resplandecerão na sua terra.',
        'Porque, quão grande é a sua bondade! E quão grande é a sua formosura! O trigo fará florescer os jovens e o mosto as virgens.',
      ],
      [
        'Pedi ao SENHOR chuva no tempo da chuva serôdia, sim, ao SENHOR que faz relâmpagos; e lhes dará chuvas abundantes, e a cada um erva no campo.',
        'Porque os ídolos têm falado vaidade, e os adivinhos têm visto mentira, e contam sonhos falsos; com vaidade consolam, por isso seguem o seu caminho como ovelhas; estão aflitos, porque não há pastor.',
        'Contra os pastores se acendeu a minha ira, e castigarei os bodes; mas o Senhor dos Exércitos visitará o seu rebanho, a casa de Judá, e os fará como o seu majestoso cavalo na peleja.',
        'Dele sairá a pedra de esquina, dele a estaca, dele o arco de guerra, dele juntamente sairá todo o opressor.',
        'E serão como poderosos que na batalha esmagam ao inimigo no lodo das ruas; porque o Senhor estará com eles; e confundirão os que andam montados em cavalos.',
        'E fortalecerei a casa de Judá, e salvarei a casa de José, e fá-los- ei voltar, porque me compadeci deles; e serão como se eu não os tivera rejeitado, porque eu sou o Senhor seu Deus, e os ouvirei.',
        'E os de Efraim serão como um poderoso, e o seu coração se alegrará como pelo vinho; e seus filhos o verão, e se alegrarão; o seu coração se regozijará no Senhor.',
        'Eu lhes assobiarei, e os ajuntarei, porque eu os tenho remido; e multiplicar-se-ão como antes se tinham multiplicado.',
        'Ainda que os espalhei por entre os povos, eles se lembrarão de mim em lugares remotos; e viverão com seus filhos, e voltarão.',
        'Porque eu os farei voltar da terra do Egito, e os congregarei da Assíria; e trá-los-ei à terra de Gileade e do Líbano, e não se achará lugar bastante para eles.',
        'E ele passará pelo mar com angústia, e ferirá as ondas no mar, e todas as profundezas do Nilo se secarão; então será derrubada a soberba da Assíria, e o cetro do Egito se retirará.',
        'E eu os fortalecerei no Senhor, e andarão no seu nome, diz o Senhor.',
      ],
      [
        'Abre, ó Líbano, as tuas portas para que o fogo consuma os teus cedros.',
        'Geme, ó cipreste, porque o cedro caiu, porque os mais poderosos são destruídos; gemei, ó carvalhos de Basã, porque o bosque forte é derrubado.',
        'Voz de uivo dos pastores! porque a sua glória é destruída; voz de bramido dos filhos de leões, porque foi destruída a soberba do Jordão.',
        'Assim diz o Senhor meu Deus: Apascenta as ovelhas da matança,',
        'Cujos possuidores as matam, e não se têm por culpados; e cujos vendedores dizem: Louvado seja o Senhor, porque tenho enriquecido; e os seus pastores não têm piedade delas.',
        'Certamente não terei mais piedade dos moradores desta terra, diz o Senhor; mas, eis que entregarei os homens cada um na mão do seu próximo e na mão do seu rei; eles ferirão a terra, e eu não os livrarei da sua mão.',
        'Eu, pois, apascentei as ovelhas da matança, as pobres ovelhas do rebanho. Tomei para mim duas varas: a uma chamei Graça, e à outra chamei União; e apascentei as ovelhas.',
        'E destruí os três pastores num mês; porque a minha alma se impacientou deles, e também a alma deles se enfastiou de mim.',
        'E eu disse: Não vos apascentarei mais; o que morrer, morra; e o que for destruído, seja destruído; e as que restarem comam cada uma a carne da outra.',
        'E tomei a minha vara Graça, e a quebrei, para desfazer a minha aliança, que tinha estabelecido com todos estes povos.',
        'E foi desfeita naquele dia; e assim conheceram os pobres do rebanho, que me respeitavam, que isto era palavra do Senhor.',
        'Porque eu lhes disse: Se parece bem aos vossos olhos, dai-me o meu salário e, se não, deixai-o. E pesaram o meu salário, trinta moedas de prata.',
        'O Senhor, pois, disse-me: Arroja isso ao oleiro, esse belo preço em que fui avaliado por eles. E tomei as trinta moedas de prata, e as arrojei ao oleiro, na casa do Senhor.',
        'Então quebrei a minha segunda vara União, para romper a irmandade entre Judá e Israel.',
        'E o Senhor disse-me: Toma ainda para ti o instrumento de um pastor insensato.',
        'Porque, eis que suscitarei um pastor na terra, que não cuidará das que estão perecendo, não buscará a pequena, e não curará a ferida, nem apascentará a sã; mas comerá a carne da gorda, e lhe despedaçará as unhas.',
        'Ai do pastor inútil, que abandona o rebanho! A espada cairá sobre o seu braço e sobre o seu olho direito; e o seu braço completamente se secará, e o seu olho direito completamente se escurecerá.',
      ],
      [
        'Peso da palavra do SENHOR sobre Israel: Fala o SENHOR, o que estende o céu, e que funda a terra, e que forma o espírito do homem dentro dele.',
        'Eis que eu farei de Jerusalém um copo de tremor para todos os povos em redor, e também para Judá, durante o cerco contra Jerusalém.',
        'E acontecerá naquele dia que farei de Jerusalém uma pedra pesada para todos os povos; todos os que a carregarem certamente serão despedaçados; e ajuntar-se-á contra ela todo o povo da terra.',
        'Naquele dia, diz o Senhor, ferirei de espanto a todos os cavalos, e de loucura os que montam neles; mas sobre a casa de Judá abrirei os meus olhos, e ferirei de cegueira a todos os cavalos dos povos.',
        'Então os governadores de Judá dirão no seu coração: Os habitantes de Jerusalém são a minha força no Senhor dos Exércitos, seu Deus.',
        'Naquele dia porei os governadores de Judá como um braseiro ardente no meio da lenha, e como um facho de fogo entre gavelas; e à direita e à esquerda consumirão a todos os povos em redor, e Jerusalém será habitada outra vez no seu lugar, em Jerusalém;',
        'E o Senhor salvará primeiramente as tendas de Judá, para que a glória da casa de Davi e a glória dos habitantes de Jerusalém não seja exaltada sobre Judá.',
        'Naquele dia o Senhor protegerá os habitantes de Jerusalém; e o mais fraco dentre eles naquele dia será como Davi, e a casa de Davi será como Deus, como o anjo do Senhor diante deles.',
        'E acontecerá naquele dia, que procurarei destruir todas as nações que vierem contra Jerusalém;',
        'Mas sobre a casa de Davi, e sobre os habitantes de Jerusalém, derramarei o Espírito de graça e de súplicas; e olharão para mim, a quem traspassaram; e prantearão sobre ele, como quem pranteia pelo filho unigênito; e chorarão amargamente por ele, como se chora amargamente pelo primogênito.',
        'Naquele dia será grande o pranto em Jerusalém, como o pranto de Hadade-Rimom no vale de Megido.',
        'E a terra pranteará, cada família à parte: a família da casa de Davi à parte, e suas mulheres à parte; e a família da casa de Natã à parte, e suas mulheres à parte;',
        'A família da casa de Levi à parte, e suas mulheres à parte; a família de Simei à parte, e suas mulheres à parte.',
        'Todas as mais famílias remanescentes, cada família à parte, e suas mulheres à parte.',
      ],
      [
        'Naquele dia haverá uma fonte aberta para a casa de Davi, e para os habitantes de Jerusalém, para purificação do pecado e da imundícia.',
        'E acontecerá naquele dia, diz o Senhor dos Exércitos, que tirarei da terra os nomes dos ídolos, e deles não haverá mais memória; e também farei sair da terra os profetas e o espírito da impureza.',
        'E acontecerá que, quando alguém ainda profetizar, seu pai e sua mãe, que o geraram, lhe dirão: Não viverás, porque falaste mentira em nome do Senhor; e seu pai e sua mãe, que o geraram, o traspassarão quando profetizar.',
        'E acontecerá naquele dia que os profetas se envergonharão, cada um da sua visão, quando profetizarem; nem mais se vestirão de manto de pelos, para mentirem.',
        'Mas dirão: Não sou profeta, sou lavrador da terra; porque certo homem ensinou-me a guardar o gado desde a minha mocidade.',
        'E se alguém lhe disser: Que feridas são estas nas tuas mãos? Dirá ele: São feridas com que fui ferido em casa dos meus amigos.',
        'Ó espada, desperta-te contra o meu pastor, e contra o homem que é o meu companheiro, diz o Senhor dos Exércitos. Fere ao pastor, e espalhar-se-ão as ovelhas; mas volverei a minha mão sobre os pequenos.',
        'E acontecerá em toda a terra, diz o Senhor, que as duas partes dela serão extirpadas, e expirarão; mas a terceira parte restará nela.',
        'E farei passar esta terceira parte pelo fogo, e a purificarei, como se purifica a prata, e a provarei, como se prova o ouro. Ela invocará o meu nome, e eu a ouvirei; direi: É meu povo; e ela dirá: O Senhor é o meu Deus.',
      ],
      [
        'Eis que vem o dia do SENHOR, em que teus despojos se repartirão no meio de ti.',
        'Porque eu ajuntarei todas as nações para a peleja contra Jerusalém; e a cidade será tomada, e as casas serão saqueadas, e as mulheres forçadas; e metade da cidade sairá para o cativeiro, mas o restante do povo não será extirpado da cidade.',
        'E o Senhor sairá, e pelejará contra estas nações, como pele- jou, sim, no dia da batalha.',
        'E naquele dia estarão os seus pés sobre o monte das Oliveiras, que está defronte de Jerusalém para o oriente; e o monte das Oliveiras será fendido pelo meio, para o oriente e para o ocidente, e haverá um vale muito grande; e metade do monte se apartará para o norte, e a outra metade dele para o sul.',
        'E fugireis pelo vale dos meus montes, pois o vale dos montes chegará até Azel; e fugireis assim como fugistes de diante do terremoto nos dias de Uzias, rei de Judá. Então virá o Senhor meu Deus, e todos os santos contigo.',
        'E acontecerá naquele dia, que não haverá preciosa luz, nem espessa escuridão.',
        'Mas será um dia conhecido do Senhor; nem dia nem noite será; mas acontecerá que ao cair da tarde haverá luz.',
        'Naquele dia também acontecerá que sairão de Jerusalém águas vivas, metade delas para o mar oriental, e metade delas para o mar ocidental; no verão e no inverno sucederá isto.',
        'E o Senhor será rei sobre toda a terra; naquele dia um será o Senhor, e um será o seu nome.',
        'Toda a terra em redor se tornará em planície, desde Geba até Rimom, ao sul de Jerusalém, e ela será exaltada, e habitada no seu lugar, desde a porta de Benjamim até ao lugar da primeira porta, até à porta da esquina, e desde a torre de Hananeel até aos lagares do rei.',
        'E habitarão nela, e não haverá mais destruição, porque Jerusalém habitará segura.',
        'E esta será a praga com que o Senhor ferirá a todos os povos que guerrearam contra Jerusalém: a sua carne apodrecerá, estando eles em pé, e lhes apodrecerão os olhos nas suas órbitas, e a língua lhes apodrecerá na sua boca.',
        'Naquele dia também acontecerá que haverá da parte do Senhor uma grande perturbação entre eles; porque cada um pegará na mão do seu próximo, e cada um levantará a mão contra o seu próximo.',
        'E também Judá pelejará em Jerusalém, e as riquezas de todos os gentios serão ajuntadas ao redor, ouro e prata e roupas em grande abundância.',
        'Assim será também a praga dos cavalos, dos mulos, dos camelos e dos jumentos e de todos os animais que estiverem naqueles arraiais, como foi esta praga.',
        'E acontecerá que, todos os que restarem de todas as nações que vieram contra Jerusalém, subirão de ano em ano para adorar o Rei, o Senhor dos Exércitos, e para celebrarem a festa dos tabernáculos.',
        'E acontecerá que, se alguma das famílias da terra não subir a Jerusalém, para adorar o Rei, o Senhor dos Exércitos, não virá sobre ela a chuva.',
        'E, se a família dos egípcios não subir, nem vier, não virá sobre ela a chuva; virá sobre eles a praga com que o Senhor ferirá os gentios que não subirem a celebrar a festa dos tabernáculos.',
        'Este será o castigo do pecado dos egípcios e o castigo do pecado de todas as nações que não subirem a celebrar a festa dos tabernáculos.',
        'Naquele dia será gravado sobre as campainhas dos cavalos: SANTIDADE AO SENHOR; e as panelas na casa do SENHOR serão como as bacias diante do altar.',
        'E todas as panelas em Jerusalém e Judá serão consagradas ao Senhor dos Exércitos, e todos os que sacrificarem virão, e delas tomarão, e nelas cozerão. E naquele dia não haverá mais cananeu na casa do Senhor dos Exércitos.',
      ],
    ],
    livro: 'zacarias',
  },
  {
    abbrev: 'ml',
    capitulos: [
      [
        'Peso da palavra do SENHOR contra Israel, por intermédio de Malaquias.',
        'Eu vos tenho amado, diz o Senhor. Mas vós dizeis: Em que nos tens amado? Não era Esaú irmão de Jacó? disse o Senhor; todavia amei a Jacó,',
        'E odiei a Esaú; e fiz dos seus montes uma desolação, e dei a sua herança aos chacais do deserto.',
        'Ainda que Edom diga: Empobrecidos estamos, porém tornaremos a edificar os lugares desolados; assim diz o Senhor dos Exércitos: Eles edificarão, e eu destruirei; e lhes chamarão: Termo de impiedade, e povo contra quem o Senhor está irado para sempre.',
        'E os vossos olhos o verão, e direis: O Senhor seja engrandecido além dos termos de Israel.',
        'O filho honra o pai, e o servo o seu senhor; se eu sou pai, onde está a minha honra? E, se eu sou senhor, onde está o meu temor? diz o SENHOR dos Exércitos a vós, ó sacerdotes, que desprezais o meu nome. E vós dizeis: Em que nós temos desprezado o teu nome?',
        'Ofereceis sobre o meu altar pão imundo, e dizeis: Em que te havemos profanado? Nisto que dizeis: A mesa do Senhor é desprezível.',
        'Porque, quando ofereceis animal cego para o sacrifício, isso não é mau? E quando ofereceis o coxo ou enfermo, isso não é mau? Ora apresenta-o ao teu governador; porventura terá ele agrado em ti? ou aceitará ele a tua pessoa? diz o Senhor dos Exércitos.',
        'Agora, pois, eu suplico, pedi a Deus, que ele seja misericordioso conosco; isto veio das vossas mãos; aceitará ele a vossa pessoa? diz o Senhor dos Exércitos.',
        'Quem há também entre vós que feche as portas por nada, e não acenda debalde o fogo do meu altar? Eu não tenho prazer em vós, diz o Senhor dos Exércitos, nem aceitarei oferta da vossa mão.',
        'Mas desde o nascente do sol até ao poente é grande entre os gentios o meu nome; e em todo o lugar se oferecerá ao meu nome incenso, e uma oferta pura; porque o meu nome é grande entre os gentios, diz o Senhor dos Exércitos.',
        'Mas vós o profanais, quando dizeis: A mesa do Senhor é impura, e o seu produto, isto é, a sua comida é desprezível.',
        'E dizeis ainda: Eis aqui, que canseira! E o lançastes ao desprezo, diz o Senhor dos Exércitos; vós ofereceis o que foi roubado, e o coxo e o enfermo; assim trazeis a oferta. Aceitaria eu isso de vossa mão? diz o Senhor.',
        'Pois seja maldito o enganador que, tendo macho no seu rebanho, promete e oferece ao Senhor o que tem mácula; porque eu sou grande Rei, diz o Senhor dos Exércitos, o meu nome é temível entre os gentios.',
      ],
      [
        'Agora, ó sacerdotes, este mandamento é para vós.',
        'Se não ouvirdes e se não propuserdes, no vosso coração, dar honra ao meu nome, diz o Senhor dos Exércitos, enviarei a maldição contra vós, e amaldiçoarei as vossas bênçãos; e também já as tenho amaldiçoado, porque não aplicais a isso o coração.',
        'Eis que reprovarei a vossa semente, e espalharei esterco sobre os vossos rostos, o esterco das vossas festas solenes; e para junto deste sereis levados.',
        'Então sabereis que eu vos enviei este mandamento, para que a minha aliança fosse com Levi, diz o Senhor dos Exércitos.',
        'Minha aliança com ele foi de vida e de paz, e eu lhas dei para que temesse; então temeu-me, e assombrou-se por causa do meu nome.',
        'A lei da verdade esteve na sua boca, e a iniqüidade não se achou nos seus lábios; andou comigo em paz e em retidão, e da iniqüidade converteu a muitos.',
        'Porque os lábios do sacerdote devem guardar o conhecimento, e da sua boca devem os homens buscar a lei porque ele é o mensageiro do Senhor dos Exércitos.',
        'Mas vós vos desviastes do caminho; a muitos fizestes tropeçar na lei; corrompestes a aliança de Levi, diz o Senhor dos Exércitos.',
        'Por isso também eu vos fiz desprezíveis, e indignos diante de todo o povo, visto que não guardastes os meus caminhos, mas fizestes acepção de pessoas na lei.',
        'Não temos nós todos um mesmo Pai? Não nos criou um mesmo Deus? Por que agimos aleivosamente cada um contra seu irmão, profanando a aliança de nossos pais?',
        'Judá tem sido desleal, e abominação se cometeu em Israel e em Jerusalém; porque Judá profanou o santuário do Senhor, o qual ele ama, e se casou com a filha de deus estranho.',
        'O Senhor destruirá das tendas de Jacó o homem que fizer isto, o que vela, e o que responde, e o que apresenta uma oferta ao Senhor dos Exércitos.',
        'Ainda fazeis isto outra vez, cobrindo o altar do Senhor de lágrimas, com choro e com gemidos; de sorte que ele não olha mais para a oferta, nem a aceitará com prazer da vossa mão.',
        'E dizeis: Por quê? Porque o Senhor foi testemunha entre ti e a mulher da tua mocidade, com a qual tu foste desleal, sendo ela a tua companheira, e a mulher da tua aliança.',
        'E não fez ele somente um, ainda que lhe sobrava o espírito? E por que somente um? Ele buscava uma descendência para Deus. Portanto guardai-vos em vosso espírito, e ninguém seja infiel para com a mulher da sua mocidade.',
        'Porque o Senhor, o Deus de Israel diz que odeia o repúdio, e aquele que encobre a violência com a sua roupa, diz o Senhor dos Exércitos; portanto guardai-vos em vosso espírito, e não sejais desleais.',
        'Enfadais ao Senhor com vossas palavras; e ainda dizeis: Em que o enfadamos? Nisto que dizeis: Qualquer que faz o mal passa por bom aos olhos do Senhor, e desses é que ele se agrada, ou, onde está o Deus do juízo?',
      ],
      [
        'Eis que eu envio o meu mensageiro, que preparará o caminho diante de mim; e de repente virá ao seu templo o Senhor, a quem vós buscais; e o mensageiro da aliança, a quem vós desejais, eis que ele vem, diz o SENHOR dos Exércitos.',
        'Mas quem suportará o dia da sua vinda? E quem subsistirá, quando ele aparecer? Porque ele será como o fogo do ourives e como o sabão dos lavandeiros.',
        'E assentar-se-á como fundidor e purificador de prata; e purificará os filhos de Levi, e os refinará como ouro e como prata; então ao Senhor trarão oferta em justiça.',
        'E a oferta de Judá e de Jerusalém será agradável ao Senhor, como nos dias antigos, e como nos primeiros anos.',
        'E chegar-me-ei a vós para juízo; e serei uma testemunha veloz contra os feiticeiros, contra os adúlteros, contra os que juram falsamente, contra os que defraudam o diarista em seu salário, e a viúva, e o órfão, e que pervertem o direito do estrangeiro, e não me temem, diz o Senhor dos Exércitos.',
        'Porque eu, o Senhor, não mudo; por isso vós, ó filhos de Jacó, não sois consumidos.',
        'Desde os dias de vossos pais vos desviastes dos meus estatutos, e não os guardastes; tornai-vos para mim, e eu me tornarei para vós, diz o Senhor dos Exércitos; mas vós dizeis: Em que havemos de tornar?',
        'Roubará o homem a Deus? Todavia vós me roubais, e dizeis: Em que te roubamos? Nos dízimos e nas ofertas.',
        'Com maldição sois amaldiçoados, porque a mim me roubais, sim, toda esta nação.',
        'Trazei todos os dízimos à casa do tesouro, para que haja mantimento na minha casa, e depois fazei prova de mim nisto, diz o Senhor dos Exércitos, se eu não vos abrir as janelas do céu, e não derramar sobre vós uma bênção tal até que não haja lugar suficiente para a recolherdes.',
        'E por causa de vós repreenderei o devorador, e ele não destruirá os frutos da vossa terra; e a vossa vide no campo não será estéril, diz o Senhor dos Exércitos.',
        'E todas as nações vos chamarão bem-aventurados; porque vós sereis uma terra deleitosa, diz o Senhor dos Exércitos.',
        'As vossas palavras foram agressivas para mim, diz o Senhor; mas vós dizeis: Que temos falado contra ti?',
        'Vós tendes dito: Inútil é servir a Deus; que nos aproveita termos cuidado em guardar os seus preceitos, e em andar de luto diante do Senhor dos Exércitos?',
        'Ora, pois, nós reputamos por bem-aventurados os soberbos; também os que cometem impiedade são edificados; sim, eles tentam a Deus, e escapam.',
        'Então aqueles que temeram ao Senhor falaram freqüentemente um ao outro; e o Senhor atentou e ouviu; e um memorial foi escrito diante dele, para os que temeram o Senhor, e para os que se lembraram do seu nome.',
        'E eles serão meus, diz o Senhor dos Exércitos; naquele dia serão para mim jóias; poupá-los-ei, como um homem poupa a seu filho, que o serve.',
        'Então voltareis e vereis a diferença entre o justo e o ímpio; entre o que serve a Deus, e o que não o serve.',
      ],
      [
        'Porque eis que aquele dia vem ardendo como fornalha; todos os soberbos, e todos os que cometem impiedade, serão como a palha; e o dia que está para vir os abrasará, diz o SENHOR dos Exércitos, de sorte que lhes não deixará nem raiz nem ramo.',
        'Mas para vós, os que temeis o meu nome, nascerá o sol da justiça, e cura trará nas suas asas; e saireis e saltareis como bezerros da estrebaria.',
        'E pisareis os ímpios, porque se farão cinza debaixo das plantas de vossos pés, naquele dia que estou preparando, diz o Senhor dos Exércitos.',
        'Lembrai-vos da lei de Moisés, meu servo, que lhe mandei em Horebe para todo o Israel, a saber, estatutos e juízos.',
        'Eis que eu vos enviarei o profeta Elias, antes que venha o grande e terrível dia do Senhor;',
        'E ele converterá o coração dos pais aos filhos, e o coração dos filhos a seus pais; para que eu não venha, e fira a terra com maldição.',
      ],
    ],
    livro: 'malaquias',
  },
  {
    abbrev: 'mt',
    capitulos: [
      [
        'Livro da geração de Jesus Cristo, filho de Davi, filho de Abraão.',
        'Abraão gerou a Isaque; e Isaque gerou a Jacó; e Jacó gerou a Judá e a seus irmãos;',
        'E Judá gerou, de Tamar, a Perez e a Zerá; e Perez gerou a Esrom; e Esrom gerou a Arão;',
        'E Arão gerou a Aminadabe; e Aminadabe gerou a Naassom; e Naassom gerou a Salmom;',
        'E Salmom gerou, de Raabe, a Boaz; e Boaz gerou de Rute a Obede; e Obede gerou a Jessé;',
        'E Jessé gerou ao rei Davi; e o rei Davi gerou a Salomão da que foi mulher de Urias.',
        'E Salomão gerou a Roboão; e Roboão gerou a Abias; e Abias gerou a Asa;',
        'E Asa gerou a Josafá; e Josafá gerou a Jorão; e Jorão gerou a Uzias;',
        'E Uzias gerou a Jotão; e Jotão gerou a Acaz; e Acaz gerou a Ezequias;',
        'E Ezequias gerou a Manassés; e Manassés gerou a Amom; e Amom gerou a Josias;',
        'E Josias gerou a Jeconias e a seus irmãos na deportação para babilônia.',
        'E, depois da deportação para a babilônia, Jeconias gerou a Salatiel; e Salatiel gerou a Zorobabel;',
        'E Zorobabel gerou a Abiúde; e Abiúde gerou a Eliaquim; e Eliaquim gerou a Azor;',
        'E Azor gerou a Sadoque; e Sadoque gerou a Aquim; e Aquim gerou a Eliúde;',
        'E Eliúde gerou a Eleazar; e Eleazar gerou a Matã; e Matã gerou a Jacó;',
        'E Jacó gerou a José, marido de Maria, da qual nasceu JESUS, que se chama o Cristo.',
        'De sorte que todas as gerações, desde Abraão até Davi, são catorze gerações; e desde Davi até a deportação para a babilônia, catorze gerações; e desde a deportação para a babilônia até Cristo, catorze gerações.',
        'Ora, o nascimento de Jesus Cristo foi assim: Que estando Maria, sua mãe, desposada com José, antes de se ajuntarem, achou-se ter concebido do Espírito Santo.',
        'Então José, seu marido, como era justo, e a não queria infamar, intentou deixá-la secretamente.',
        'E, projetando ele isto, eis que em sonho lhe apareceu um anjo do Senhor, dizendo: José, filho de Davi, não temas receber a Maria, tua mulher, porque o que nela está gerado é do Espírito Santo;',
        'E dará à luz um filho e chamarás o seu nome JESUS; porque ele salvará o seu povo dos seus pecados.',
        'Tudo isto aconteceu para que se cumprisse o que foi dito da parte do Senhor, pelo profeta, que diz;',
        'Eis que a virgem conceberá, e dará à luz um filho, E chamá-lo-ão pelo nome de EMANUEL, Que traduzido é: Deus conosco.',
        'E José, despertando do sono, fez como o anjo do Senhor lhe ordenara, e recebeu a sua mulher;',
        'E não a conheceu até que deu à luz seu filho, o primogênito; e pôs-lhe por nome Jesus.',
      ],
      [
        'E, tendo nascido Jesus em Belém de Judéia, no tempo do rei Herodes, eis que uns magos vieram do oriente a Jerusalém,',
        'Dizendo: Onde está aquele que é nascido rei dos judeus? porque vimos a sua estrela no oriente, e viemos a adorá-lo.',
        'E o rei Herodes, ouvindo isto, perturbou-se, e toda Jerusalém com ele.',
        'E, congregados todos os príncipes dos sacerdotes, e os escribas do povo, perguntou-lhes onde havia de nascer o Cristo.',
        'E eles lhe disseram: Em Belém de Judéia; porque assim está escrito pelo profeta:',
        'E tu, Belém, terra de Judá,De modo nenhum és a menor entre as capitais de Judá;porque de ti sairá o Guia que há de apascentar o meu povo Israel.',
        'Então Herodes, chamando secretamente os magos, inquiriu exatamente deles acerca do tempo em que a estrela lhes aparecera.',
        'E, enviando-os a Belém, disse: Ide, e perguntai diligentemente pelo menino e, quando o achardes, participai-mo, para que também eu vá e o adore.',
        'E, tendo eles ouvido o rei, partiram; e eis que a estrela, que tinham visto no oriente, ia adiante deles, até que, chegando, se deteve sobre o lugar onde estava o menino.',
        'E, vendo eles a estrela, regoziram-se muito com grande alegria.',
        'E, entrando na casa, acharam o menino com Maria sua mãe e, prostrando-se, o adoraram; e abrindo os seus tesouros, ofertaram-lhe dádivas: ouro, incenso e mirra.',
        'E, sendo por divina revelação avisados num sonho para que não voltassem para junto de Herodes, partiram para a sua terra por outro caminho.',
        'E, tendo eles se retirado, eis que o anjo do Senhor apareceu a José num sonho, dizendo: Levanta-te, e toma o menino e sua mãe, e foge para o Egito, e demora-te lá até que eu te diga; porque Herodes há de procurar o menino para o matar.',
        'E, levantando-se ele, tomou o menino e sua mãe, de noite, e foi para o Egito.',
        'E esteve lá, até à morte de Herodes, para que se cumprisse o que foi dito da parte do Senhor pelo profeta, que diz: Do Egito chamei o meu Filho.',
        'Então Herodes, vendo que tinha sido iludido pelos magos, irritou-se muito, e mandou matar todos os meninos que havia em Belém, e em todos os seus contornos, de dois anos para baixo, segundo o tempo que diligentemente inquirira dos magos.',
        'Então se cumpriu o que foi dito pelo profeta Jeremias, que diz:',
        'Em Ramá se ouviu uma voz, Lamentação, choro e grande pranto: Raquel chorando os seus filhos, E não quer ser consolada, porque já não existem.',
        'Morto, porém, Herodes, eis que o anjo do Senhor apareceu num sonho a José no Egito,',
        'Dizendo: Levanta-te, e toma o menino e sua mãe, e vai para a terra de Israel; porque já estão mortos os que procuravam a morte do menino.',
        'Então ele se levantou, e tomou o menino e sua mãe, e foi para a terra de Israel.',
        'E, ouvindo que Arquelau reinava na Judéia em lugar de Herodes, seu pai, receou ir para lá; mas avisado num sonho, por divina revelação, foi para as partes da Galiléia.',
        'E chegou, e habitou numa cidade chamada Nazaré, para que se cumprisse o que fora dito pelos profetas: Ele será chamado Nazareno.',
      ],
      [
        'E, naqueles dias, apareceu João o Batista pregando no deserto da Judéia,',
        'E dizendo: Arrependei-vos, porque é chegado o reino dos céus.',
        'Porque este é o anunciado pelo profeta Isaías, que disse:Voz do que clama no deserto:Preparai o caminho do Senhor,Endireitai as suas veredas.',
        'E este João tinha as suas vestes de pelos de camelo, e um cinto de couro em torno de seus lombos; e alimentava-se de gafanhotos e de mel silvestre.',
        'Então ia ter com ele Jerusalém, e toda a Judéia, e toda a província adjacente ao Jordão;',
        'E eram por ele batizados no rio Jordão, confessando os seus pecados.',
        'E, vendo ele muitos dos fariseus e dos saduceus, que vinham ao seu batismo, dizia-lhes: Raça de víboras, quem vos ensinou a fugir da ira futura?',
        'Produzi, pois, frutos dignos de arrependimento;',
        'E não presumais, de vós mesmos, dizendo: Temos por pai a Abraão; porque eu vos digo que, mesmo destas pedras, Deus pode suscitar filhos a Abraão.',
        'E também agora está posto o machado à raiz das árvores; toda a árvore, pois, que não produz bom fruto, é cortada e lançada no fogo.',
        'E eu, em verdade, vos batizo com água, para o arrependimento; mas aquele que vem após mim é mais poderoso do que eu; cujas alparcas não sou digno de levar; ele vos batizará com o Espírito Santo, e com fogo.',
        'Em sua mão tem a pá, e limpará a sua eira, e recolherá no celeiro o seu trigo, e queimará a palha com fogo que nunca se apagará.',
        'Então veio Jesus da Galiléia ter com João, junto do Jordão, para ser batizado por ele.',
        'Mas João opunha-se-lhe, dizendo: Eu careço de ser batizado por ti, e vens tu a mim?',
        'Jesus, porém, respondendo, disse-lhe: Deixa por agora, porque assim nos convém cumprir toda a justiça. Então ele o permitiu.',
        'E, sendo Jesus batizado, saiu logo da água, e eis que se lhe abriram os céus, e viu o Espírito de Deus descendo como pomba e vindo sobre ele.',
        'E eis que uma voz dos céus dizia: Este é o meu Filho amado, em quem me comprazo.',
      ],
      [
        'Então foi conduzido Jesus pelo Espírito ao deserto, para ser tentado pelo diabo.',
        'E, tendo jejuado quarenta dias e quarenta noites, depois teve fome;',
        'E, chegando-se a ele o tentador, disse: Se tu és o Filho de Deus, manda que estas pedras se tornem em pães.',
        'Ele, porém, respondendo, disse: Está escrito: Nem só de pão viverá o homem, mas de toda a palavra que sai da boca de Deus.',
        'Então o diabo o transportou à cidade santa, e colocou-o sobre o pináculo do templo,',
        'E disse-lhe: Se tu és o Filho de Deus, lança-te de aqui abaixo; porque está escrito: Que aos seus anjos dará ordens a teu respeito, E tomar-te-ão nas mãos, Para que nunca tropeces com o teu pé em alguma pedra.',
        'Disse-lhe Jesus: Também está escrito: Não tentarás o Senhor teu Deus.',
        'Novamente o transportou o diabo a um monte muito alto; e mostrou-lhe todos os reinos do mundo, e a glória deles.',
        'E disse-lhe: Tudo isto te darei se, prostrado, me adorares.',
        'Então disse-lhe Jesus: Vai-te, Satanás, porque está escrito: Ao Senhor teu Deus adorarás, e só a ele servirás.',
        'Então o diabo o deixou; e, eis que chegaram os anjos, e o serviam.',
        'Jesus, porém, ouvindo que João estava preso, voltou para a Galiléia;',
        'E, deixando Nazaré, foi habitar em Cafarnaum, cidade marítima, nos confins de Zebulom e Naftali;',
        'Para que se cumprisse o que foi dito pelo profeta Isaías, que diz:',
        'A terra de Zebulom, e a terra de Naftali, Junto ao caminho do mar, além do Jordão, A Galiléia das nações;',
        'O povo, que estava assentado em trevas, Viu uma grande luz; aos que estavam assentados na região e sombra da morte,A luz raiou.',
        'Desde então começou Jesus a pregar, e a dizer: Arrependei-vos, porque é chegado o reino dos céus.',
        'E Jesus, andando junto ao mar da Galiléia, viu a dois irmãos, Simão, chamado Pedro, e André, seu irmão, os quais lançavam as redes ao mar, porque eram pescadores;',
        'E disse-lhes: Vinde após mim, e eu vos farei pescadores de homens.',
        'Então eles, deixando logo as redes, seguiram-no.',
        'E, adiantando-se dali, viu outros dois irmãos, Tiago, filho de Zebedeu, e João, seu irmão, num barco com seu pai, Zebedeu, consertando as redes;',
        'E chamou-os; eles, deixando imediatamente o barco e seu pai, seguiram-no.',
        'E percorria Jesus toda a Galiléia, ensinando nas suas sinagogas e pregando o evangelho do reino, e curando todas as enfermidades e moléstias entre o povo.',
        'E a sua fama correu por toda a Síria, e traziam-lhe todos os que padeciam, acometidos de várias enfermidades e tormentos, os endemoninhados, os lunáticos, e os paralíticos, e ele os curava.',
        'E seguia-o uma grande multidão da Galiléia, de Decápolis, de Jerusalém, da Judéia, e de além do Jordão.',
      ],
      [
        'E Jesus, vendo a multidão, subiu a um monte, e, assentando-se, aproximaram-se dele os seus discípulos;',
        'E, abrindo a sua boca, os ensinava, dizendo:',
        'Bem-aventurados os pobres de espírito, porque deles é o reino dos céus;',
        'Bem-aventurados os que choram, porque eles serão consolados;',
        'Bem-aventurados os mansos, porque eles herdarão a terra;',
        'Bem-aventurados os que têm fome e sede de justiça, porque eles serão fartos;',
        'Bem-aventurados os misericordiosos, porque eles alcançarão misericórdia;',
        'Bem-aventurados os limpos de coração, porque eles verão a Deus;',
        'Bem-aventurados os pacificadores, porque eles serão chamados filhos de Deus;',
        'Bem-aventurados os que sofrem perseguição por causa da justiça, porque deles é o reino dos céus;',
        'Bem-aventurados sois vós, quando vos injuriarem e perseguirem e, mentindo, disserem todo o mal contra vós por minha causa.',
        'Exultai e alegrai-vos, porque é grande o vosso galardão nos céus; porque assim perseguiram os profetas que foram antes de vós.',
        'Vós sois o sal da terra; e se o sal for insípido, com que se há de salgar? Para nada mais presta senão para se lançar fora, e ser pisado pelos homens.',
        'Vós sois a luz do mundo; não se pode esconder uma cidade edificada sobre um monte;',
        'Nem se acende a candeia e se coloca debaixo do alqueire, mas no velador, e dá luz a todos que estão na casa.',
        'Assim resplandeça a vossa luz diante dos homens, para que vejam as vossas boas obras e glorifiquem a vosso Pai, que está nos céus.',
        'Não cuideis que vim destruir a lei ou os profetas: não vim abrogar, mas cumprir.',
        'Porque em verdade vos digo que, até que o céu e a terra passem, nem um jota ou um til jamais passará da lei, sem que tudo seja cumprido.',
        'Qualquer, pois, que violar um destes mandamentos, por menor que seja, e assim ensinar aos homens, será chamado o menor no reino dos céus; aquele, porém, que os cumprir e ensinar será chamado grande no reino dos céus.',
        'Porque vos digo que, se a vossa justiça não exceder a dos escribas e fariseus, de modo nenhum entrareis no reino dos céus.',
        'Ouvistes que foi dito aos antigos: Não matarás; mas qualquer que matar será réu de juízo.',
        'Eu, porém, vos digo que qualquer que, sem motivo, se encolerizar contra seu irmão, será réu de juízo; e qualquer que disser a seu irmão: Raca, será réu do sinédrio; e qualquer que lhe disser: Louco, será réu do fogo do inferno.',
        'Portanto, se trouxeres a tua oferta ao altar, e aí te lembrares de que teu irmão tem alguma coisa contra ti,',
        'Deixa ali diante do altar a tua oferta, e vai reconciliar-te primeiro com teu irmão e, depois, vem e apresenta a tua oferta.',
        'Concilia-te depressa com o teu adversário, enquanto estás no caminho com ele, para que não aconteça que o adversário te entregue ao juiz, e o juiz te entregue ao oficial, e te encerrem na prisão.',
        'Em verdade te digo que de maneira nenhuma sairás dali enquanto não pagares o último ceitil.',
        'Ouvistes que foi dito aos antigos: Não cometerás adultério.',
        'Eu, porém, vos digo, que qualquer que atentar numa mulher para a cobiçar, já em seu coração cometeu adultério com ela.',
        'Portanto, se o teu olho direito te escandalizar, arranca-o e atira-o para longe de ti; pois te é melhor que se perca um dos teus membros do que seja todo o teu corpo lançado no inferno.',
        'E, se a tua mão direita te escandalizar, corta-a e atira-a para longe de ti, porque te é melhor que um dos teus membros se perca do que seja todo o teu corpo lançado no inferno.',
        'Também foi dito: Qualquer que deixar sua mulher, dê-lhe carta de divórcio.',
        'Eu, porém, vos digo que qualquer que repudiar sua mulher, a não ser por causa de fornicação, faz que ela cometa adultério, e qualquer que casar com a repudiada comete adultério.',
        'Outrossim, ouvistes que foi dito aos antigos: Não perjurarás, mas cumprirás os teus juramentos ao Senhor.',
        'Eu, porém, vos digo que de maneira nenhuma jureis; nem pelo céu, porque é o trono de Deus;',
        'Nem pela terra, porque é o escabelo de seus pés; nem por Jerusalém, porque é a cidade do grande Rei;',
        'Nem jurarás pela tua cabeça, porque não podes tornar um cabelo branco ou preto.',
        'Seja, porém, o vosso falar: Sim, sim; Não, não; porque o que passa disto é de procedência maligna.',
        'Ouvistes que foi dito: Olho por olho, e dente por dente.',
        'Eu, porém, vos digo que não resistais ao mau; mas, se qualquer te bater na face direita, oferece-lhe também a outra;',
        'E, ao que quiser pleitear contigo, e tirar-te a túnica, larga-lhe também a capa;',
        'E, se qualquer te obrigar a caminhar uma milha, vai com ele duas.',
        'Dá a quem te pedir, e não te desvies daquele que quiser que lhe emprestes.',
        'Ouvistes que foi dito: Amarás o teu próximo, e odiarás o teu inimigo.',
        'Eu, porém, vos digo: Amai a vossos inimigos, bendizei os que vos maldizem, fazei bem aos que vos odeiam, e orai pelos que vos maltratam e vos perseguem; para que sejais filhos do vosso Pai que está nos céus;',
        'Porque faz que o seu sol se levante sobre maus e bons, e a chuva desça sobre justos e injustos.',
        'Pois, se amardes os que vos amam, que galardão tereis? Não fazem os publicanos também o mesmo?',
        'E, se saudardes unicamente os vossos irmãos, que fazeis de mais? Não fazem os publicanos também assim?',
        'Sede vós pois perfeitos, como é perfeito o vosso Pai que está nos céus.',
      ],
      [
        'Guardai-vos de fazer a vossa esmola diante dos homens, para serdes vistos por eles; aliás, não tereis galardão junto de vosso Pai, que está nos céus.',
        'Quando, pois, deres esmola, não faças tocar trombeta diante de ti, como fazem os hipócritas nas sinagogas e nas ruas, para serem glorificados pelos homens. Em verdade vos digo que já receberam o seu galardão.',
        'Mas, quando tu deres esmola, não saiba a tua mão esquerda o que faz a tua direita;',
        'Para que a tua esmola seja dada em secreto; e teu Pai, que vê em secreto, ele mesmo te recompensará publicamente.',
        'E, quando orares, não sejas como os hipócritas; pois se comprazem em orar em pé nas sinagogas, e às esquinas das ruas, para serem vistos pelos homens. Em verdade vos digo que já receberam o seu galardão.',
        'Mas tu, quando orares, entra no teu aposento e, fechando a tua porta, ora a teu Pai que está em secreto; e teu Pai, que vê em secreto, te recompensará publicamente.',
        'E, orando, não useis de vãs repetições, como os gentios, que pensam que por muito falarem serão ouvidos.',
        'Não vos assemelheis, pois, a eles; porque vosso Pai sabe o que vos é necessário, antes de vós lho pedirdes.',
        'Portanto, vós orareis assim: Pai nosso, que estás nos céus, santificado seja o teu nome;',
        'Venha o teu reino, seja feita a tua vontade, assim na terra como no céu;',
        'O pão nosso de cada dia nos dá hoje;',
        'E perdoa-nos as nossas dívidas, assim como nós perdoamos aos nossos devedores;',
        'E não nos conduzas à tentação; mas livra-nos do mal; porque teu é o reino, e o poder, e a glória, para sempre. Amém.',
        'Porque, se perdoardes aos homens as suas ofensas, também vosso Pai celestial vos perdoará a vós;',
        'Se, porém, não perdoardes aos homens as suas ofensas, também vosso Pai vos não perdoará as vossas ofensas.',
        'E, quando jejuardes, não vos mostreis contristados como os hipócritas; porque desfiguram os seus rostos, para que aos homens pareça que jejuam. Em verdade vos digo que já receberam o seu galardão.',
        'Tu, porém, quando jejuares, unge a tua cabeça, e lava o teu rosto,',
        'Para não pareceres aos homens que jejuas, mas a teu Pai, que está em secreto; e teu Pai, que vê em secreto, te recompensará publicamente.',
        'Não ajunteis tesouros na terra, onde a traça e a ferrugem tudo consomem, e onde os ladrões minam e roubam;',
        'Mas ajuntai tesouros no céu, onde nem a traça nem a ferrugem consomem, e onde os ladrões não minam nem roubam.',
        'Porque onde estiver o vosso tesouro, aí estará também o vosso coração.',
        'A candeia do corpo são os olhos; de sorte que, se os teus olhos forem bons, todo o teu corpo terá luz;',
        'Se, porém, os teus olhos forem maus, o teu corpo será tenebroso. Se, portanto, a luz que em ti há são trevas, quão grandes serão tais trevas!',
        'Ninguém pode servir a dois senhores; porque ou há de odiar um e amar o outro, ou se dedicará a um e desprezará o outro. Não podeis servir a Deus e a Mamom.',
        'Por isso vos digo: Não andeis cuidadosos quanto à vossa vida, pelo que haveis de comer ou pelo que haveis de beber; nem quanto ao vosso corpo, pelo que haveis de vestir. Não é a vida mais do que o mantimento, e o corpo mais do que o vestuário?',
        'Olhai para as aves do céu, que nem semeiam, nem segam, nem ajuntam em celeiros; e vosso Pai celestial as alimenta. Não tendes vós muito mais valor do que elas?',
        'E qual de vós poderá, com todos os seus cuidados, acrescentar um côvado à sua estatura?',
        'E, quanto ao vestuário, por que andais solícitos? Olhai para os lírios do campo, como eles crescem; não trabalham nem fiam;',
        'E eu vos digo que nem mesmo Salomão, em toda a sua glória, se vestiu como qualquer deles.',
        'Pois, se Deus assim veste a erva do campo, que hoje existe, e amanhã é lançada no forno, não vos vestirá muito mais a vós, homens de pouca fé?',
        'Não andeis, pois, inquietos, dizendo: Que comeremos, ou que beberemos, ou com que nos vestiremos?',
        'Porque todas estas coisas os gentios procuram. Decerto vosso Pai celestial bem sabe que necessitais de todas estas coisas;',
        'Mas, buscai primeiro o reino de Deus, e a sua justiça, e todas estas coisas vos serão acrescentadas.',
        'Não vos inquieteis, pois, pelo dia de amanhã, porque o dia de amanhã cuidará de si mesmo. Basta a cada dia o seu mal.',
      ],
      [
        'Não julgueis, para que não sejais julgados.',
        'Porque com o juízo com que julgardes sereis julgados, e com a medida com que tiverdes medido vos hão de medir a vós.',
        'E por que reparas tu no argueiro que está no olho do teu irmão, e não vês a trave que está no teu olho?',
        'Ou como dirás a teu irmão: Deixa-me tirar o argueiro do teu olho, estando uma trave no teu?',
        'Hipócrita, tira primeiro a trave do teu olho, e então cuidarás em tirar o argueiro do olho do teu irmão.',
        'Não deis aos cães as coisas santas, nem deiteis aos porcos as vossas pérolas, não aconteça que as pisem com os pés e, voltando-se, vos despedacem.',
        'Pedi, e dar-se-vos-á; buscai, e encontrareis; batei, e abrir-se-vos-á.',
        'Porque, aquele que pede, recebe; e, o que busca, encontra; e, ao que bate, abrir-se-lhe-á.',
        'E qual dentre vós é o homem que, pedindo-lhe pão o seu filho, lhe dará uma pedra?',
        'E, pedindo-lhe peixe, lhe dará uma serpente?',
        'Se vós, pois, sendo maus, sabeis dar boas coisas aos vossos filhos, quanto mais vosso Pai, que está nos céus, dará bens aos que lhe pedirem?',
        'Portanto, tudo o que vós quereis que os homens vos façam, fazei-lho também vós, porque esta é a lei e os profetas.',
        'Entrai pela porta estreita; porque larga é a porta, e espaçoso o caminho que conduz à perdição, e muitos são os que entram por ela;',
        'E porque estreita é a porta, e apertado o caminho que leva à vida, e poucos há que a encontrem.',
        'Acautelai-vos, porém, dos falsos profetas, que vêm até vós vestidos como ovelhas, mas, interiormente, são lobos devoradores.',
        'Por seus frutos os conhecereis. Porventura colhem-se uvas dos espinheiros, ou figos dos abrolhos?',
        'Assim, toda a árvore boa produz bons frutos, e toda a árvore má produz frutos maus.',
        'Não pode a árvore boa dar maus frutos; nem a árvore má dar frutos bons.',
        'Toda a árvore que não dá bom fruto corta-se e lança-se no fogo.',
        'Portanto, pelos seus frutos os conhecereis.',
        'Nem todo o que me diz: Senhor, Senhor! entrará no reino dos céus, mas aquele que faz a vontade de meu Pai, que está nos céus.',
        'Muitos me dirão naquele dia: Senhor, Senhor, não profetizamos nós em teu nome? e em teu nome não expulsamos demônios? e em teu nome não fizemos muitas maravilhas?',
        'E então lhes direi abertamente: Nunca vos conheci; apartai-vos de mim, vós que praticais a iniqüidade.',
        'Todo aquele, pois, que escuta estas minhas palavras, e as pratica, assemelhá-lo-ei ao homem prudente, que edificou a sua casa sobre a rocha;',
        'E desceu a chuva, e correram rios, e assopraram ventos, e combateram aquela casa, e não caiu, porque estava edificada sobre a rocha.',
        'E aquele que ouve estas minhas palavras, e não as cumpre, compará-lo-ei ao homem insensato, que edificou a sua casa sobre a areia;',
        'E desceu a chuva, e correram rios, e assopraram ventos, e combateram aquela casa, e caiu, e foi grande a sua queda.',
        'E aconteceu que, concluindo Jesus este discurso, a multidão se admirou da sua doutrina;',
        'Porquanto os ensinava como tendo autoridade; e não como os escribas.',
      ],
      [
        'E, descendo ele do monte, seguiu-o uma grande multidão.',
        'E, eis que veio um leproso, e o adorou, dizendo: Senhor, se quiseres, podes tornar-me limpo.',
        'E Jesus, estendendo a mão, tocou-o, dizendo: Quero; sê limpo. E logo ficou purificado da lepra.',
        'Disse-lhe então Jesus: Olha, não o digas a alguém, mas vai, mostra-te ao sacerdote, e apresenta a oferta que Moisés determinou, para lhes servir de testemunho.',
        'E, entrando Jesus em Cafarnaum, chegou junto dele um centurião, rogando-lhe,',
        'E dizendo: Senhor, o meu criado jaz em casa, paralítico, e violentamente atormentado.',
        'E Jesus lhe disse: Eu irei, e lhe darei saúde.',
        'E o centurião, respondendo, disse: Senhor, não sou digno de que entres debaixo do meu telhado, mas dize somente uma palavra, e o meu criado há de sarar.',
        'Pois também eu sou homem sob autoridade, e tenho soldados às minhas ordens; e digo a este: Vai, e ele vai; e a outro: Vem, e ele vem; e ao meu criado: Faze isto, e ele o faz.',
        'E maravilhou-se Jesus, ouvindo isto, e disse aos que o seguiam: Em verdade vos digo que nem mesmo em Israel encontrei tanta fé.',
        'Mas eu vos digo que muitos virão do oriente e do ocidente, e assentar-se-ão à mesa com Abraão, e Isaque, e Jacó, no reino dos céus;',
        'E os filhos do reino serão lançados nas trevas exteriores; ali haverá pranto e ranger de dentes.',
        'Então disse Jesus ao centurião: Vai, e como creste te seja feito. E naquela mesma hora o seu criado sarou.',
        'E Jesus, entrando em casa de Pedro, viu a sogra deste acamada, e com febre.',
        'E tocou-lhe na mão, e a febre a deixou; e levantou-se, e serviu-os.',
        'E, chegada a tarde, trouxeram-lhe muitos endemoninhados, e ele com a sua palavra expulsou deles os espíritos, e curou todos os que estavam enfermos;',
        'Para que se cumprisse o que fora dito pelo profeta Isaías, que diz: Ele tomou sobre si as nossas enfermidades, e levou as nossas doenças.',
        'E Jesus, vendo em torno de si uma grande multidão, ordenou que passassem para o outro lado;',
        'E, aproximando-se dele um escriba, disse-lhe: Mestre, aonde quer que fores, eu te seguirei.',
        'E disse Jesus: As raposas têm covis, e as aves do céu têm ninhos, mas o Filho do homem não tem onde reclinar a cabeça.',
        'E outro de seus discípulos lhe disse: Senhor, permite-me que primeiramente vá sepultar meu pai.',
        'Jesus, porém, disse-lhe: Segue-me, e deixa os mortos sepultar os seus mortos.',
        'E, entrando ele no barco, seus discípulos o seguiram;',
        'E eis que no mar se levantou uma tempestade, tão grande que o barco era coberto pelas ondas; ele, porém, estava dormindo.',
        'E os seus discípulos, aproximando-se, o despertaram, dizendo: Senhor, salva-nos! que perecemos.',
        'E ele disse-lhes: Por que temeis, homens de pouca fé? Então, levantando-se, repreendeu os ventos e o mar, e seguiu-se uma grande bonança.',
        'E aqueles homens se maravilharam, dizendo: Que homem é este, que até os ventos e o mar lhe obedecem?',
        'E, tendo chegado ao outro lado, à província dos gergesenos, saíram-lhe ao encontro dois endemoninhados, vindos dos sepulcros; tão ferozes eram que ninguém podia passar por aquele caminho.',
        'E eis que clamaram, dizendo: Que temos nós contigo, Jesus, Filho de Deus? Vieste aqui atormentar-nos antes do tempo?',
        'E andava pastando distante deles uma manada de muitos porcos.',
        'E os demônios rogaram-lhe, dizendo: Se nos expulsas, permite-nos que entremos naquela manada de porcos.',
        'E ele lhes disse: Ide. E, saindo eles, se introduziram na manada dos porcos; e eis que toda aquela manada de porcos se precipitou no mar por um despenhadeiro, e morreram nas águas.',
        'Os porqueiros fugiram e, chegando à cidade, divulgaram tudo o que acontecera aos endemoninhados.',
        'E eis que toda aquela cidade saiu ao encontro de Jesus e, vendo-o, rogaram-lhe que se retirasse dos seus termos.',
      ],
      [
        'E, entrando no barco, passou para o outro lado, e chegou à sua cidade. E eis que lhe trouxeram um paralítico, deitado numa cama.',
        'E Jesus, vendo a fé deles, disse ao paralítico: Filho, tem bom ânimo, perdoados te são os teus pecados.',
        'E eis que alguns dos escribas diziam entre si: Ele blasfema.',
        'Mas Jesus, conhecendo os seus pensamentos, disse: Por que pensais mal em vossos corações?',
        'Pois, qual é mais fácil? dizer: Perdoados te são os teus pecados; ou dizer: Levanta-te e anda?',
        'Ora, para que saibais que o Filho do homem tem na terra autoridade para perdoar pecados (disse então ao paralítico): Levanta-te, toma a tua cama, e vai para tua casa.',
        'E, levantando-se, foi para sua casa.',
        'E a multidão, vendo isto, maravilhou-se, e glorificou a Deus, que dera tal poder aos homens.',
        'E Jesus, passando adiante dali, viu assentado na alfândega um homem, chamado Mateus, e disse-lhe: Segue-me. E ele, levantando-se, o seguiu.',
        'E aconteceu que, estando ele em casa sentado à mesa, chegaram muitos publicanos e pecadores, e sentaram-se juntamente com Jesus e seus discípulos.',
        'E os fariseus, vendo isto, disseram aos seus discípulos: Por que come o vosso Mestre com os publicanos e pecadores?',
        'Jesus, porém, ouvindo, disse-lhes: Não necessitam de médico os sãos, mas, sim, os doentes.',
        'Ide, porém, e aprendei o que significa: Misericórdia quero, e não sacrifício. Porque eu nào vim a chamar os justos, mas os pecadores, ao arrependimento.',
        'Então, chegaram ao pé dele os discípulos de João, dizendo: Por que jejuamos nós e os fariseus muitas vezes, e os teus discípulos não jejuam?',
        'E disse-lhes Jesus: Podem porventura andar tristes os filhos das bodas, enquanto o esposo está com eles? Dias, porém, virão, em que lhes será tirado o esposo, e então jejuarão.',
        'Ninguém deita remendo de pano novo em roupa velha, porque semelhante remendo rompe a roupa, e faz-se maior a rotura.',
        'Nem se deita vinho novo em odres velhos; aliás rompem-se os odres, e entorna-se o vinho, e os odres estragam-se; mas deita-se vinho novo em odres novos, e assim ambos se conservam.',
        'Dizendo-lhes ele estas coisas, eis que chegou um chefe, e o adorou, dizendo: Minha filha faleceu agora mesmo; mas vem, impõe-lhe a tua mão, e ela viverá.',
        'E Jesus, levantando-se, seguiu-o, ele e os seus discípulos.',
        'E eis que uma mulher que havia já doze anos padecia de um fluxo de sangue, chegando por detrás dele, tocou a orla de sua roupa;',
        'Porque dizia consigo: Se eu tão-somente tocar a sua roupa, ficarei sã.',
        'E Jesus, voltando-se, e vendo-a, disse: Tem ânimo, filha, a tua fé te salvou. E imediatamente a mulher ficou sã.',
        'E Jesus, chegando à casa daquele chefe, e vendo os instrumentistas, e o povo em alvoroço,',
        'Disse-lhes: Retirai-vos, que a menina não está morta, mas dorme. E riam-se dele.',
        'E, logo que o povo foi posto fora, entrou Jesus, e pegou-lhe na mão, e a menina levantou-se.',
        'E espalhou-se aquela notícia por todo aquele país.',
        'E, partindo Jesus dali, seguiram-no dois cegos, clamando, e dizendo: Tem compaixão de nós, filho de Davi.',
        'E, quando chegou à casa, os cegos se aproximaram dele; e Jesus disse-lhes: Credes vós que eu possa fazer isto? Disseram-lhe eles: Sim, Senhor.',
        'Tocou então os olhos deles, dizendo: Seja-vos feito segundo a vossa fé.',
        'E os olhos se lhes abriram. E Jesus ameaçou-os, dizendo: Olhai que ninguém o saiba.',
        'Mas, tendo eles saído, divulgaram a sua fama por toda aquela terra.',
        'E, havendo-se eles retirado, trouxeram-lhe um homem mudo e endemoninhado.',
        'E, expulso o demônio, falou o mudo; e a multidão se maravilhou, dizendo: Nunca tal se viu em Israel.',
        'Mas os fariseus diziam: Ele expulsa os demônios pelo príncipe dos demônios.',
        'E percorria Jesus todas as cidades e aldeias, ensinando nas sinagogas deles, e pregando o evangelho do reino, e curando todas as enfermidades e moléstias entre o povo.',
        'E, vendo as multidões, teve grande compaixão delas, porque andavam cansadas e desgarradas, como ovelhas que não têm pastor.',
        'Então, disse aos seus discípulos: A seara é realmente grande, mas poucos os ceifeiros.',
        'Rogai, pois, ao Senhor da seara, que mande ceifeiros para a sua seara.',
      ],
      [
        'E, chamando os seus doze discípulos, deu-lhes poder sobre os espíritos imundos, para os expulsarem, e para curarem toda a enfermidade e todo o mal.',
        'Ora, os nomes dos doze apóstolos são estes: O primeiro, Simão, chamado Pedro, e André, seu irmão; Tiago, filho de Zebedeu, e João, seu irmão;',
        'Filipe e Bartolomeu; Tomé e Mateus, o publicano; Tiago, filho de Alfeu, e Lebeu, apelidado Tadeu;',
        'Simão, o Cananita, e Judas Iscariotes, aquele que o traiu.',
        'Jesus enviou estes doze, e lhes ordenou, dizendo: Não ireis pelo caminho dos gentios, nem entrareis em cidade de samaritanos;',
        'Mas ide antes às ovelhas perdidas da casa de Israel;',
        'E, indo, pregai, dizendo: É chegado o reino dos céus.',
        'Curai os enfermos, limpai os leprosos, ressuscitai os mortos, expulsai os demônios; de graça recebestes, de graça dai.',
        'Não possuais ouro, nem prata, nem cobre, em vossos cintos,',
        'Nem alforges para o caminho, nem duas túnicas, nem alparcas, nem bordões; porque digno é o operário do seu alimento.',
        'E, em qualquer cidade ou aldeia em que entrardes, procurai saber quem nela seja digno, e hospedai-vos aí, até que vos retireis.',
        'E, quando entrardes nalguma casa, saudai-a;',
        'E, se a casa for digna, desça sobre ela a vossa paz; mas, se não for digna, torne para vós a vossa paz.',
        'E, se ninguém vos receber, nem escutar as vossas palavras, saindo daquela casa ou cidade, sacudi o pó dos vossos pés.',
        'Em verdade vos digo que, no dia do juízo, haverá menos rigor para o país de Sodoma e Gomorra do que para aquela cidade.',
        'Eis que vos envio como ovelhas ao meio de lobos; portanto, sede prudentes como as serpentes e inofensivos como as pombas.',
        'Acautelai-vos, porém, dos homens; porque eles vos entregarão aos sinédrios, e vos açoitarão nas suas sinagogas;',
        'E sereis até conduzidos à presença dos governadores, e dos reis, por causa de mim, para lhes servir de testemunho a eles, e aos gentios.',
        'Mas, quando vos entregarem, não vos dê cuidado como, ou o que haveis de falar, porque naquela mesma hora vos será ministrado o que haveis de dizer.',
        'Porque não sois vós quem falará, mas o Espírito de vosso Pai é que fala em vós.',
        'E o irmão entregará à morte o irmão, e o pai o filho; e os filhos se levantarão contra os pais, e os matarão.',
        'E odiados de todos sereis por causa do meu nome; mas aquele que perseverar até ao fim, esse será salvo.',
        'Quando pois vos perseguirem nesta cidade, fugi para outra; porque em verdade vos digo que não acabareis de percorrer as cidades de Israel sem que venha o Filho do homem.',
        'Não é o discípulo mais do que o mestre, nem o servo mais do que o seu senhor.',
        'Basta ao discípulo ser como seu mestre, e ao servo como seu senhor. Se chamaram Belzebu ao pai de família, quanto mais aos seus domésticos?',
        'Portanto, não os temais; porque nada há encoberto que não haja de revelar-se, nem oculto que não haja de saber-se.',
        'O que vos digo em trevas dizei-o em luz; e o que escutais ao ouvido pregai-o sobre os telhados.',
        'E não temais os que matam o corpo e não podem matar a alma; temei antes aquele que pode fazer perecer no inferno a alma e o corpo.',
        'Não se vendem dois passarinhos por um ceitil? e nenhum deles cairá em terra sem a vontade de vosso Pai.',
        'E até mesmo os cabelos da vossa cabeça estão todos contados.',
        'Não temais, pois; mais valeis vós do que muitos passarinhos.',
        'Portanto, qualquer que me confessar diante dos homens, eu o confessarei diante de meu Pai, que está nos céus.',
        'Mas qualquer que me negar diante dos homens, eu o negarei também diante de meu Pai, que está nos céus.',
        'Não cuideis que vim trazer a paz à terra; não vim trazer paz, mas espada;',
        'Porque eu vim pôr em dissensão o homem contra seu pai, e a filha contra sua mãe, e a nora contra sua sogra;',
        'E assim os inimigos do homem serão os seus familiares.',
        'Quem ama o pai ou a mãe mais do que a mim não é digno de mim; e quem ama o filho ou a filha mais do que a mim não é digno de mim.',
        'E quem não toma a sua cruz, e não segue após mim, não é digno de mim.',
        'Quem achar a sua vida perdê-la-á; e quem perder a sua vida, por amor de mim, achá-la-á.',
        'Quem vos recebe, a mim me recebe; e quem me recebe a mim, recebe aquele que me enviou.',
        'Quem recebe um profeta em qualidade de profeta, receberá galardão de profeta; e quem recebe um justo na qualidade de justo, receberá galardão de justo.',
        'E qualquer que tiver dado só que seja um copo de água fria a um destes pequenos, em nome de discípulo, em verdade vos digo que de modo algum perderá o seu galardão.',
      ],
      [
        'E aconteceu que, acabando Jesus de dar instruções aos seus doze discípulos, partiu dali a ensinar e a pregar nas cidades deles.',
        'E João, ouvindo no cárcere falar dos feitos de Cristo, enviou dois dos seus discípulos,',
        'A dizer-lhe: És tu aquele que havia de vir, ou esperamos outro?',
        'E Jesus, respondendo, disse-lhes: Ide, e anunciai a João as coisas que ouvis e vedes:',
        'Os cegos vêem, e os coxos andam; os leprosos são limpos, e os surdos ouvem; os mortos são ressuscitados, e aos pobres é anunciado o evangelho.',
        'E bem-aventurado é aquele que não se escandalizar em mim.',
        'E, partindo eles, começou Jesus a dizer às turbas, a respeito de João: Que fostes ver no deserto? uma cana agitada pelo vento?',
        'Sim, que fostes ver? um homem ricamente vestido? Os que trajam ricamente estão nas casas dos reis.',
        'Mas, então que fostes ver? um profeta? Sim, vos digo eu, e muito mais do que profeta;',
        'Porque é este de quem está escrito:Eis que diante da tua face envio o meu anjo,que preparará diante de ti o teu caminho.',
        'Em verdade vos digo que, entre os que de mulher têm nascido, não apareceu alguém maior do que João o Batista; mas aquele que é o menor no reino dos céus é maior do que ele.',
        'E, desde os dias de João o Batista até agora, se faz violência ao reino dos céus, e pela força se apoderam dele.',
        'Porque todos os profetas e a lei profetizaram até João.',
        'E, se quereis dar crédito, é este o Elias que havia de vir.',
        'Quem tem ouvidos para ouvir, ouça.',
        'Mas, a quem assemelharei esta geração? É semelhante aos meninos que se assentam nas praças, e clamam aos seus companheiros,',
        'E dizem: Tocamo-vos flauta, e não dançastes; cantamo-vos lamentações, e não chorastes.',
        'Porquanto veio João, não comendo nem bebendo, e dizem: Tem demônio.',
        'Veio o Filho do homem, comendo e bebendo, e dizem: Eis aí um homem comilão e beberrão, amigo dos publicanos e pecadores. Mas a sabedoria é justificada por seus filhos.',
        'Então começou ele a lançar em rosto às cidades onde se operou a maior parte dos seus prodígios o não se haverem arrependido, dizendo:',
        'Ai de ti, Corazim! ai de ti, Betsaida! porque, se em Tiro e em Sidom fossem feitos os prodígios que em vós se fizeram, há muito que se teriam arrependido, com saco e com cinza.',
        'Por isso eu vos digo que haverá menos rigor para Tiro e Sidom, no dia do juízo, do que para vós.',
        'E tu, Cafarnaum, que te ergues até ao céu, serás abatida até ao inferno; porque, se em Sodoma tivessem sido feitos os prodígios que em ti se operaram, teria ela permanecido até hoje.',
        'Eu vos digo, porém, que haverá menos rigor para os de Sodoma, no dia do juízo, do que para ti.',
        'Naquele tempo, respondendo Jesus, disse: Graças te dou, ó Pai, Senhor do céu e da terra, que ocultaste estas coisas aos sábios e entendidos, e as revelaste aos pequeninos.',
        'Sim, ó Pai, porque assim te aprouve.',
        'Todas as coisas me foram entregues por meu Pai, e ninguém conhece o Filho, senão o Pai; e ninguém conhece o Pai, senão o Filho, e aquele a quem o Filho o quiser revelar.',
        'Vinde a mim, todos os que estais cansados e oprimidos, e eu vos aliviarei.',
        'Tomai sobre vós o meu jugo, e aprendei de mim, que sou manso e humilde de coração; e encontrareis descanso para as vossas almas.',
        'Porque o meu jugo é suave e o meu fardo é leve.',
      ],
      [
        'Naquele tempo passou Jesus pelas searas, em um sábado; e os seus discípulos, tendo fome, começaram a colher espigas, e a comer.',
        'E os fariseus, vendo isto, disseram-lhe: Eis que os teus discípulos fazem o que não é lícito fazer num sábado.',
        'Ele, porém, lhes disse: Não tendes lido o que fez Davi, quando teve fome, ele e os que com ele estavam?',
        'Como entrou na casa de Deus, e comeu os pães da proposição, que não lhe era lícito comer, nem aos que com ele estavam, mas só aos sacerdotes?',
        'Ou não tendes lido na lei que, aos sábados, os sacerdotes no templo violam o sábado, e ficam sem culpa?',
        'Pois eu vos digo que está aqui quem é maior do que o templo.',
        'Mas, se vós soubésseis o que significa: Misericórdia quero, e não sacrifício, não condenaríeis os inocentes.',
        'Porque o Filho do homem até do sábado é Senhor.',
        'E, partindo dali, chegou à sinagoga deles.',
        'E, estava ali um homem que tinha uma das mãos mirrada; e eles, para o acusarem, o interrogaram, dizendo: É lícito curar nos sábados?',
        'E ele lhes disse: Qual dentre vós será o homem que tendo uma ovelha, se num sábado ela cair numa cova, não lançará mão dela, e a levantará?',
        'Pois, quanto mais vale um homem do que uma ovelha? É, por conseqüência, lícito fazer bem nos sábados.',
        'Então disse àquele homem: Estende a tua mão. E ele a estendeu, e ficou sã como a outra.',
        'E os fariseus, tendo saído, formaram conselho contra ele, para o matarem.',
        'Jesus, sabendo isso, retirou-se dali, e acompanharam-no grandes multidões, e ele curou a todas.',
        'E recomendava-lhes rigorosamente que o não descobrissem,',
        'Para que se cumprisse o que fora dito pelo profeta Isaías, que diz:',
        'Eis aqui o meu servo, que escolhi,o meu amado, em quem a minha alma se compraz;porei sobre ele o meu espírito,e anunciará aos gentios o juízo.',
        'Não contenderá, nem clamará,Nem alguém ouvirá pelas ruas a sua voz;',
        'Não esmagará a cana quebrada,enão apagará o morrão que fumega,até que faça triunfar o juízo;',
        'E no seu nome os gentios esperarão.',
        'Trouxeram-lhe, então, um endemoninhado cego e mudo; e, de tal modo o curou, que o cego e mudo falava e via.',
        'E toda a multidão se admirava e dizia: Não é este o Filho de Davi?',
        'Mas os fariseus, ouvindo isto, diziam: Este não expulsa os demônios senão por Belzebu, príncipe dos demônios.',
        'Jesus, porém, conhecendo os seus pensamentos, disse-lhes: Todo o reino dividido contra si mesmo é devastado; e toda a cidade, ou casa, dividida contra si mesma não subsistirá.',
        'E, se Satanás expulsa a Satanás, está dividido contra si mesmo; como subsistirá, pois, o seu reino?',
        'E, se eu expulso os demônios por Belzebu, por quem os expulsam então vossos filhos? Portanto, eles mesmos serão os vossos juízes.',
        'Mas, se eu expulso os demônios pelo Espírito de Deus, logo é chegado a vós o reino de Deus.',
        'Ou, como pode alguém entrar na casa do homem valente, e furtar os seus bens, se primeiro não maniatar o valente, saqueando então a sua casa?',
        'Quem não é comigo é contra mim; e quem comigo não ajunta, espalha.',
        'Portanto, eu vos digo: Todo o pecado e blasfêmia se perdoará aos homens; mas a blasfêmia contra o Espírito não será perdoada aos homens.',
        'E, se qualquer disser alguma palavra contra o Filho do homem, ser-lhe-á perdoado; mas, se alguém falar contra o Espírito Santo, não lhe será perdoado, nem neste século nem no futuro.',
        'Ou fazei a árvore boa, e o seu fruto bom, ou fazei a árvore má, e o seu fruto mau; porque pelo fruto se conhece a árvore.',
        'Raça de víboras, como podeis vós dizer boas coisas, sendo maus? Pois do que há em abundância no coração, disso fala a boca.',
        'O homem bom tira boas coisas do bom tesouro do seu coração, e o homem mau do mau tesouro tira coisas más.',
        'Mas eu vos digo que de toda a palavra ociosa que os homens disserem hão de dar conta no dia do juízo.',
        'Porque por tuas palavras serás justificado, e por tuas palavras serás condenado.',
        'Então alguns dos escribas e dos fariseus tomaram a palavra, dizendo: Mestre, quiséramos ver da tua parte algum sinal.',
        'Mas ele lhes respondeu, e disse: Uma geração má e adúltera pede um sinal, porém, não se lhe dará outro sinal senão o sinal do profeta Jonas;',
        'Pois, como Jonas esteve três dias e três noites no ventre da baleia, assim estará o Filho do homem três dias e três noites no seio da terra.',
        'Os ninivitas ressurgirão no juízo com esta geração, e a condenarão, porque se arrependeram com a pregação de Jonas. E eis que está aqui quem é maior do que Jonas.',
        'A rainha do sul se levantará no dia do juízo com esta geração, e a condenará; porque veio dos confins da terra para ouvir a sabedoria de Salomão. E eis que está aqui quem é maior do que Salomão.',
        'E, quando o espírito imundo tem saído do homem, anda por lugares áridos, buscando repouso, e não o encontra.',
        'Então diz: Voltarei para a minha casa, de onde saí. E, voltando, acha-a desocupada, varrida e adornada.',
        'Então vai, e leva consigo outros sete espíritos piores do que ele e, entrando, habitam ali; e são os últimos atos desse homem piores do que os primeiros. Assim acontecerá também a esta geração má.',
        'E, falando ele ainda à multidão, eis que estavam fora sua mãe e seus irmãos, pretendendo falar-lhe.',
        'E disse-lhe alguém: Eis que estão ali fora tua mãe e teus irmãos, que querem falar-te.',
        'Ele, porém, respondendo, disse ao que lhe falara: Quem é minha mãe? E quem são meus irmãos?',
        'E, estendendo a sua mão para os seus discípulos, disse: Eis aqui minha mãe e meus irmãos;',
        'Porque, qualquer que fizer a vontade de meu Pai que está nos céus, este é meu irmão, e irmã e mãe.',
      ],
      [
        'Tendo Jesus saído de casa, naquele dia, estava assentado junto ao mar;',
        'E ajuntou-se muita gente ao pé dele, de sorte que, entrando num barco, se assentou; e toda a multidão estava em pé na praia.',
        'E falou-lhe de muitas coisas por parábolas, dizendo: Eis que o semeador saiu a semear.',
        'E, quando semeava, uma parte da semente caiu ao pé do caminho, e vieram as aves, e comeram-na;',
        'E outra parte caiu em pedregais, onde não havia terra bastante, e logo nasceu, porque não tinha terra funda;',
        'Mas, vindo o sol, queimou-se, e secou-se, porque não tinha raiz.',
        'E outra caiu entre espinhos, e os espinhos cresceram e sufocaram-na.',
        'E outra caiu em boa terra, e deu fruto: um a cem, outro a sessenta e outro a trinta.',
        'Quem tem ouvidos para ouvir, ouça.',
        'E, acercando-se dele os discípulos, disseram-lhe: Por que lhes falas por parábolas?',
        'Ele, respondendo, disse-lhes: Porque a vós é dado conhecer os mistérios do reino dos céus, mas a eles não lhes é dado;',
        'Porque àquele que tem, se dará, e terá em abundância; mas àquele que não tem, até aquilo que tem lhe será tirado.',
        'Por isso lhes falo por parábolas; porque eles, vendo, não vêem; e, ouvindo, não ouvem nem compreendem.',
        'E neles se cumpre a profecia de Isaías, que diz:Ouvindo, ouvireis, mas não compreendereis,e, vendo, vereis, mas não percebereis.',
        'Porque o coração deste povo está endurecido,E ouviram de mau grado com seus ouvidos,E fecharam seus olhos;Para que não vejam com os olhos,E ouçam com os ouvidos,e compreendam com o coração,e se convertam,e eu os cure.',
        'Mas, bem-aventurados os vossos olhos, porque vêem, e os vossos ouvidos, porque ouvem.',
        'Porque em verdade vos digo que muitos profetas e justos desejaram ver o que vós vedes, e não o viram; e ouvir o que vós ouvis, e não o ouviram.',
        'Escutai vós, pois, a parábola do semeador.',
        'Ouvindo alguém a palavra do reino, e não a entendendo, vem o maligno, e arrebata o que foi semeado no seu coração; este é o que foi semeado ao pé do caminho.',
        'O que foi semeado em pedregais é o que ouve a palavra, e logo a recebe com alegria;',
        'Mas não tem raiz em si mesmo, antes é de pouca duração; e, chegada a angústia e a perseguição, por causa da palavra, logo se ofende;',
        'E o que foi semeado entre espinhos é o que ouve a palavra, mas os cuidados deste mundo, e a sedução das riquezas sufocam a palavra, e fica infrutífera;',
        'Mas, o que foi semeado em boa terra é o que ouve e compreende a palavra; e dá fruto, e um produz cem, outro sessenta, e outro trinta.',
        'Propôs-lhes outra parábola, dizendo: O reino dos céus é semelhante ao homem que semeia a boa semente no seu campo;',
        'Mas, dormindo os homens, veio o seu inimigo, e semeou joio no meio do trigo, e retirou-se.',
        'E, quando a erva cresceu e frutificou, apareceu também o joio.',
        'E os servos do pai de família, indo ter com ele, disseram-lhe: Senhor, não semeaste tu, no teu campo, boa semente? Por que tem, então, joio?',
        'E ele lhes disse: Um inimigo é quem fez isso. E os servos lhe disseram: Queres pois que vamos arrancá-lo?',
        'Ele, porém, lhes disse: Não; para que, ao colher o joio, não arranqueis também o trigo com ele.',
        'Deixai crescer ambos juntos até à ceifa; e, por ocasião da ceifa, direi aos ceifeiros: Colhei primeiro o joio, e atai-o em molhos para o queimar; mas, o trigo, ajuntai-o no meu celeiro.',
        'Outra parábola lhes propôs, dizendo: O reino dos céus é semelhante ao grão de mostarda que o homem, pegando nele, semeou no seu campo;',
        'O qual é, realmente, a menor de todas as sementes; mas, crescendo, é a maior das plantas, e faz-se uma árvore, de sorte que vêm as aves do céu, e se aninham nos seus ramos.',
        'Outra parábola lhes disse: O reino dos céus é semelhante ao fermento, que uma mulher toma e introduz em três medidas de farinha, até que tudo esteja levedado.',
        'Tudo isto disse Jesus, por parábolas à multidão, e nada lhes falava sem parábolas;',
        'Para que se cumprisse o que fora dito pelo profeta, que disse: Abrirei em parábolas a minha boca; Publicarei coisas ocultas desde a fundação do mundo.',
        'Então, tendo despedido a multidão, foi Jesus para casa. E chegaram ao pé dele os seus discípulos, dizendo: Explica-nos a parábola do joio do campo.',
        'E ele, respondendo, disse-lhes: O que semeia a boa semente, é o Filho do homem;',
        'O campo é o mundo; e a boa semente são os filhos do reino; e o joio são os filhos do maligno;',
        'O inimigo, que o semeou, é o diabo; e a ceifa é o fim do mundo; e os ceifeiros são os anjos.',
        'Assim como o joio é colhido e queimado no fogo, assim será na consumação deste mundo.',
        'Mandará o Filho do homem os seus anjos, e eles colherão do seu reino tudo o que causa escândalo, e os que cometem iniqüidade.',
        'E lançá-los-ão na fornalha de fogo; ali haverá pranto e ranger de dentes.',
        'Então os justos resplandecerão como o sol, no reino de seu Pai. Quem tem ouvidos para ouvir, ouça.',
        'Também o reino dos céus é semelhante a um tesouro escondido num campo, que um homem achou e escondeu; e, pelo gozo dele, vai, vende tudo quanto tem, e compra aquele campo.',
        'Outrossim o reino dos céus é semelhante ao homem, negociante, que busca boas pérolas;',
        'E, encontrando uma pérola de grande valor, foi, vendeu tudo quanto tinha, e comprou-a.',
        'Igualmente o reino dos céus é semelhante a uma rede lançada ao mar, e que apanha toda a qualidade de peixes.',
        'E, estando cheia, a puxam para a praia; e, assentando-se, apanham para os cestos os bons; os ruins, porém, lançam fora.',
        'Assim será na consumação dos séculos: virão os anjos, e separarão os maus de entre os justos,',
        'E lançá-los-ão na fornalha de fogo; ali haverá pranto e ranger de dentes.',
        'E disse-lhes Jesus: Entendestes todas estas coisas? Disseram-lhe eles: Sim, Senhor.',
        'E ele disse-lhes: Por isso, todo o escriba instruído acerca do reino dos céus é semelhante a um pai de família, que tira do seu tesouro coisas novas e velhas.',
        'E aconteceu que Jesus, concluindo estas parábolas, se retirou dali.',
        'E, chegando à sua pátria, ensinava-os na sinagoga deles, de sorte que se maravilhavam, e diziam: De onde veio a este a sabedoria, e estas maravilhas?',
        'Não é este o filho do carpinteiro? e não se chama sua mãe Maria, e seus irmãos Tiago, e José, e Simão, e Judas?',
        'E não estão entre nós todas as suas irmãs? De onde lhe veio, pois, tudo isto?',
        'E escandalizavam-se nele. Jesus, porém, lhes disse: Não há profeta sem honra, a não ser na sua pátria e na sua casa.',
        'E não fez ali muitas maravilhas, por causa da incredulidade deles.',
      ],
      [
        'Naquele tempo ouviu Herodes, o tetrarca, a fama de Jesus,',
        'E disse aos seus criados: Este é João o Batista; ressuscitou dos mortos, e por isso estas maravilhas operam nele.',
        'Porque Herodes tinha prendido João, e tinha-o maniatado e encerrado no cárcere, por causa de Herodias, mulher de seu irmão Filipe;',
        'Porque João lhe dissera: Não te é lícito possuí-la.',
        'E, querendo matá-lo, temia o povo; porque o tinham como profeta.',
        'Festejando-se, porém, o dia natalício de Herodes, dançou a filha de Herodias diante dele, e agradou a Herodes.',
        'Por isso prometeu, com juramento, dar-lhe tudo o que pedisse;',
        'E ela, instruída previamente por sua mãe, disse: Dá-me aqui, num prato, a cabeça de João o Batista.',
        'E o rei afligiu-se, mas, por causa do juramento, e dos que estavam à mesa com ele, ordenou que se lhe desse.',
        'E mandou degolar João no cárcere.',
        'E a sua cabeça foi trazida num prato, e dada à jovem, e ela a levou a sua mãe.',
        'E chegaram os seus discípulos, e levaram o corpo, e o sepultaram; e foram anunciá-lo a Jesus.',
        'E Jesus, ouvindo isto, retirou-se dali num barco, para um lugar deserto, apartado; e, sabendo-o o povo, seguiu-o a pé desde as cidades.',
        'E, Jesus, saindo, viu uma grande multidão, e possuído de íntima compaixão para com ela, curou os seus enfermos.',
        'E, sendo chegada a tarde, os seus discípulos aproximaram-se dele, dizendo: O lugar é deserto, e a hora é já avançada; despede a multidão, para que vão pelas aldeias, e comprem comida para si.',
        'Jesus, porém, lhes disse: Não é mister que vão; dai-lhes vós de comer.',
        'Então eles lhe disseram: Não temos aqui senão cinco pães e dois peixes.',
        'E ele disse: Trazei-mos aqui.',
        'E, tendo mandado que a multidão se assentasse sobre a erva, tomou os cinco pães e os dois peixes, e, erguendo os olhos ao céu, os abençoou, e, partindo os pães, deu-os aos discípulos, e os discípulos à multidão.',
        'E comeram todos, e saciaramse; e levantaram dos pedaços, que sobejaram, doze alcofas cheias.',
        'E os que comeram foram quase cinco mil homens, além das mulheres e crianças.',
        'E logo ordenou Jesus que os seus discípulos entrassem no barco, e fossem adiante para o outro lado, enquanto despedia a multidão.',
        'E, despedida a multidão, subiu ao monte para orar, à parte. E, chegada já a tarde, estava ali só.',
        'E o barco estava já no meio do mar, açoitado pelas ondas; porque o vento era contrário;',
        'Mas, à quarta vigília da noite, dirigiu-se Jesus para eles, andan-do por cima do mar.',
        'E os discípulos, vendo-o an-dando sobre o mar, assustaram-se, dizendo: É um fantasma. E gritaram com medo.',
        'Jesus, porém, lhes falou logo, dizendo: Tende bom ânimo, sou eu, não temais.',
        'E respondeu-lhe Pedro, e disse: Senhor, se és tu, manda-me ir ter contigo por cima das águas.',
        'E ele disse: Vem. E Pedro, descendo do barco, andou sobre as águas para ir ter com Jesus.',
        'Mas, sentindo o vento forte, teve medo; e, começando a ir para o fundo, clamou, dizendo: Senhor, salva-me!',
        'E logo Jesus, estendendo a mão, segurou-o, e disse-lhe: Homem de pouca fé, por que duvidaste?',
        'E, quando subiram para o barco, acalmou o vento.',
        'Então aproximaram-se os que estavam no barco, e adoraram-no, dizendo: És verdadeiramente o Filho de Deus.',
        'E, tendo passado para o outro lado, chegaram à terra de Genesaré.',
        'E, quando os homens daquele lugar o conheceram, mandaram por todas aquelas terras em redor e trouxeram-lhe todos os que estavam enfermos.',
        'E rogavam-lhe que ao menos eles pudessem tocar a orla da sua roupa; e todos os que a tocavam ficavam sãos.',
      ],
      [
        'Então chegaram ao pé de Jesus uns escribas e fariseus de Jerusalém, dizendo:',
        'Por que transgridem os teus discípulos a tradição dos anciãos? pois não lavam as mãos quando comem pão.',
        'Ele, porém, respondendo, disse-lhes: Por que transgredis vós, também, o mandamento de Deus pela vossa tradição?',
        'Porque Deus ordenou, dizendo: Honra a teu pai e a tua mãe; e: Quem maldisser ao pai ou à mãe, certamente morrerá.',
        'Mas vós dizeis: Qualquer que disser ao pai ou à mãe: É oferta ao Senhor o que poderias aproveitar de mim; esse não precisa honrar nem a seu pai nem a sua mãe,',
        'E assim invalidastes, pela vossa tradição, o mandamento de Deus.',
        'Hipócritas, bem profetizou Isaías a vosso respeito, dizendo:',
        'Este povo se aproxima de mim com a sua boca e me honra com os seus lábios, mas o seu coração está longe de mim.',
        'Mas, em vão me adoram, ensinando doutrinas que são preceitos dos homens.',
        'E, chamando a si a multidão, disse-lhes: Ouvi, e entendei:',
        'O que contamina o homem não é o que entra na boca, mas o que sai da boca, isso é o que contamina o homem.',
        'Então, acercando-se dele os seus discípulos, disseram-lhe: Sabes que os fariseus, ouvindo essas palavras, se escandalizaram?',
        'Ele, porém, respondendo, disse: Toda a planta, que meu Pai celestial não plantou, será arrancada.',
        'Deixai-os; são cegos condutores de cegos. Ora, se um cego guiar outro cego, ambos cairão na cova.',
        'E Pedro, tomando a palavra, disse-lhe: Explica-nos essa parábola.',
        'Jesus, porém, disse: Até vós mesmos estais ainda sem entender?',
        'Ainda não compreendeis que tudo o que entra pela boca desce para o ventre, e é lançado fora?',
        'Mas, o que sai da boca, procede do coração, e isso contamina o homem.',
        'Porque do coração procedem os maus pensamentos, mortes, adultérios, fornicação, furtos, falsos testemunhos e blasfêmias.',
        'São estas coisas que contaminam o homem; mas comer sem lavar as mãos, isso não contamina o homem.',
        'E, partindo Jesus dali, foi para as partes de Tiro e de Sidom.',
        'E eis que uma mulher cananéia, que saíra daquelas cercanias, clamou, dizendo: Senhor, Filho de Davi, tem misericórdia de mim, que minha filha está miseravelmente endemoninhada.',
        'Mas ele não lhe respondeu palavra. E os seus discípulos, chegando ao pé dele, rogaram-lhe, dizendo: Despede-a, que vem gritando atrás de nós.',
        'E ele, respondendo, disse: Eu não fui enviado senão às ovelhas perdidas da casa de Israel.',
        'Então chegou ela, e adorou-o, dizendo: Senhor, socorre-me!',
        'Ele, porém, respondendo, disse: Não é bom pegar no pão dos filhos e deitá-lo aos cachorrinhos.',
        'E ela disse: Sim, Senhor, mas também os cachorrinhos comem das migalhas que caem da mesa dos seus senhores.',
        'Então respondeu Jesus, e disse-lhe: Ó mulher, grande é a tua fé! Seja isso feito para contigo como tu desejas. E desde aquela hora a sua filha ficou sã.',
        'Partindo Jesus dali, chegou ao pé do mar da Galiléia, e, subindo a um monte, assentou-se lá.',
        'E veio ter com ele grandes multidões, que traziam coxos, cegos, mudos, aleijados, e outros muitos, e os puseram aos pés de Jesus, e ele os sarou,',
        'De tal sorte, que a multidão se maravilhou vendo os mudos a falar, os aleijados sãos, os coxos a andar, e os cegos a ver; e glorificava o Deus de Israel.',
        'E Jesus, chamando os seus discípulos, disse: Tenho compaixão da multidão, porque já está comigo há três dias, e não tem o que comer; e não quero despedi-la em jejum, para que não desfaleça no caminho.',
        'E os seus discípulos disseram-lhe: De onde nos viriam, num deserto, tantos pães, para saciar tal multidão?',
        'E Jesus disse-lhes: Quantos pães tendes? E eles disseram: Sete, e uns poucos de peixinhos.',
        'Então mandou à multidão que se assentasse no chão,',
        'E, tomando os sete pães e os peixes, e dando graças, partiu-os, e deu-os aos seus discípulos, e os discípulos à multidão.',
        'E todos comeram e se saciaram; e levantaram, do que sobejou, sete cestos cheios de pedaços.',
        'Ora, os que tinham comido eram quatro mil homens, além de mulheres e crianças.',
        'E, tendo despedido a multidão, entrou no barco, e dirigiu-se ao território de Magadã.',
      ],
      [
        'E, chegando-se os fariseus e os saduceus, para o tentarem, pediram-lhe que lhes mostrasse algum sinal do céu.',
        'Mas ele, respondendo, disse-lhes: Quando é chegada a tarde, dizeis: Haverá bom tempo, porque o céu está rubro.',
        'E, pela manhã: Hoje haverá tempestade, porque o céu está de um vermelho sombrio. Hipócritas, sabeis discernir a face do céu, e não conheceis os sinais dos tempos?',
        'Uma geração má e adúltera pede um sinal, e nenhum sinal lhe será dado, senão o sinal do profeta Jonas. E, deixando-os, retirou-se.',
        'E, passando seus discípulos para o outro lado, tinham-se esquecido de trazer pão.',
        'E Jesus disse-lhes: Adverti, e acautelai-vos do fermento dos fariseus e saduceus.',
        'E eles arrazoavam entre si, dizendo: É porque não trouxemos pão.',
        'E Jesus, percebendo isso, disse: Por que arrazoais entre vós, homens de pouca fé, sobre o não terdes trazido pão?',
        'Não compreendeis ainda, nem vos lembrais dos cinco pães para cinco mil homens, e de quantas alcofas levantastes?',
        'Nem dos sete pães para quatro mil, e de quantos cestos levantastes?',
        'Como não compreendestes que não vos falei a respeito do pão, mas que vos guardásseis do fermento dos fariseus e saduceus?',
        'Então compreenderam que não dissera que se guardassem do fermento do pão, mas da doutrina dos fariseus.',
        'E, chegando Jesus às partes de Cesaréia de Filipe, interrogou os seus discípulos, dizendo: Quem dizem os homens ser o Filho do homem?',
        'E eles disseram: Uns, João o Batista; outros, Elias; e outros, Jeremias, ou um dos profetas.',
        'Disse-lhes ele: E vós, quem dizeis que eu sou?',
        'E Simão Pedro, respondendo, disse: Tu és o Cristo, o Filho do Deus vivo.',
        'E Jesus, respondendo, disse-lhe: Bem-aventurado és tu, Simão Barjonas, porque to não revelou a carne e o sangue, mas meu Pai, que está nos céus.',
        'Pois também eu te digo que tu és Pedro, e sobre esta pedra edificarei a minha igreja, e as portas do inferno não prevalecerão contra ela;',
        'E eu te darei as chaves do reino dos céus; e tudo o que ligares na terra será ligado nos céus, e tudo o que desligares na terra será desligado nos céus.',
        'Então mandou aos seus discípulos que a ninguém dissessem que ele era Jesus o Cristo.',
        'Desde então começou Jesus a mostrar aos seus discípulos que convinha ir a Jerusalém, e padecer muitas coisas dos anciãos, e dos principais dos sacerdotes, e dos escribas, e ser morto, e ressuscitar ao terceiro dia.',
        'E Pedro, tomando-o de parte, começou a repreendê-lo, dizendo: Senhor, tem compaixão de ti; de modo nenhum te acontecerá isso.',
        'Ele, porém, voltando-se, disse a Pedro: Para trás de mim, Satanás, que me serves de escândalo; porque não compreendes as coisas que são de Deus, mas só as que são dos homens.',
        'Então disse Jesus aos seus discípulos: Se alguém quiser vir após mim, renuncie-se a si mesmo, tome sobre si a sua cruz, e siga-me;',
        'Porque aquele que quiser salvar a sua vida, perdê-la-á, e quem perder a sua vida por amor de mim, achá-la-á.',
        'Pois que aproveita ao homem ganhar o mundo inteiro, se perder a sua alma? Ou que dará o homem em recompensa da sua alma?',
        'Porque o Filho do homem virá na glória de seu Pai, com os seus anjos; e então dará a cada um segundo as suas obras.',
        'Em verdade vos digo que alguns há, dos que aqui estão, que não provarão a morte até que vejam vir o Filho do homem no seu reino.',
      ],
      [
        'Seis dias depois, tomou Jesus consigo a Pedro, e a Tiago, e a João, seu irmão, e os conduziu em particular a um alto monte,',
        'E transfigurou-se diante deles; e o seu rosto resplandeceu como o sol, e as suas vestes se tornaram brancas como a luz.',
        'E eis que lhes apareceram Moisés e Elias, falando com ele.',
        'E Pedro, tomando a palavra, disse a Jesus: Senhor, bom é estarmos aqui; se queres, façamos aqui três tabernáculos, um para ti, um para Moisés, e um para Elias.',
        'E, estando ele ainda a falar, eis que uma nuvem luminosa os cobriu. E da nuvem saiu uma voz que dizia: Este é o meu amado Filho, em quem me comprazo; escutai-o.',
        'E os discípulos, ouvindo isto, caíram sobre os seus rostos, e tiveram grande medo.',
        'E, aproximando-se Jesus, tocou-lhes, e disse: Levantai-vos, e não tenhais medo.',
        'E, erguendo eles os olhos, ninguém viram senão unicamente a Jesus.',
        'E, descendo eles do monte, Jesus lhes ordenou, dizendo: A ninguém conteis a visão, até que o Filho do homem seja ressuscitado dentre os mortos.',
        'E os seus discípulos o interrogaram, dizendo: Por que dizem então os escribas que é mister que Elias venha primeiro?',
        'E Jesus, respondendo, disse-lhes: Em verdade Elias virá primeiro, e restaurará todas as coisas;',
        'Mas digo-vos que Elias já veio, e não o conheceram, mas fizeram-lhe tudo o que quiseram. Assim farão eles também padecer o Filho do homem.',
        'Então entenderam os discípulos que lhes falara de João o Batista.',
        'E, quando chegaram à multidão, aproximou-se-lhe um homem, pondo-se de joelhos diante dele, e dizendo:',
        'Senhor, tem misericórdia de meu filho, que é lunático e sofre muito; pois muitas vezes cai no fogo, e muitas vezes na água;',
        'E trouxe-o aos teus discípulos; e não puderam curá-lo.',
        'E Jesus, respondendo, disse: Ó geração incrédula e perversa! até quando estarei eu convosco, e até quando vos sofrerei? Trazeimo aqui.',
        'E, repreendeu Jesus o demônio, que saiu dele, e desde aquela hora o menino sarou.',
        'Então os discípulos, aproximando-se de Jesus em particular, disseram: Por que não pudemos nós expulsá-lo?',
        'E Jesus lhes disse: Por causa de vossa incredulidade; porque em verdade vos digo que, se tiverdes fé como um grão de mostarda, direis a este monte: Passa daqui para acolá, e há de passar; e nada vos será impossível.',
        'Mas esta casta de demônios não se expulsa senão pela oração e pelo jejum.',
        'Ora, achando-se eles na Galiléia, disse-lhes Jesus: O Filho do homem será entregue nas mãos dos homens;',
        'E matá-lo-ão, e ao terceiro dia ressuscitará. E eles se entristeceram muito.',
        'E, chegando eles a Cafarnaum, aproximaram-se de Pedro os que cobravam as dracmas, e disseram: O vosso mestre não paga as dracmas?',
        'Disse ele: Sim. E, entrando em casa, Jesus se lhe antecipou, dizendo: Que te parece, Simão? De quem cobram os reis da terra os tributos, ou o censo? Dos seus filhos, ou dos alheios?',
        'Disse-lhe Pedro: Dos alheios. Disse-lhe Jesus: Logo, estão livres os filhos.',
        'Mas, para que os não escandalizemos, vai ao mar, lança o anzol, tira o primeiro peixe que subir, e abrindo-lhe a boca, encontrarás um estáter; toma-o, e dá-o por mim e por ti.',
      ],
      [
        'Naquela mesma hora chegaram os discípulos ao pé de Jesus, dizendo: Quem é o maior no reino dos céus?',
        'E Jesus, chamando um menino, o pôs no meio deles,',
        'E disse: Em verdade vos digo que, se não vos converterdes e não vos fizerdes como meninos, de modo algum entrareis no reino dos céus.',
        'Portanto, aquele que se tornar humilde como este menino, esse é o maior no reino dos céus.',
        'E qualquer que receber em meu nome um menino, tal como este, a mim me recebe.',
        'Mas, qualquer que escandalizar um destes pequeninos, que crêem em mim, melhor lhe fora que se lhe pendurasse ao pescoço uma mó de azenha, e se submergisse na profundeza do mar.',
        'Ai do mundo, por causa dos escândalos; porque é mister que venham escândalos, mas ai daquele homem por quem o escândalo vem!',
        'Portanto, se a tua mão ou o teu pé te escandalizar, corta-o, e atira-o para longe de ti; melhor te é entrar na vida coxo, ou aleijado, do que, tendo duas mãos ou dois pés, seres lançado no fogo eterno.',
        'E, se o teu olho te escandalizar, arranca-o, e atira-o para longe de ti; melhor te é entrar na vida com um só olho, do que, tendo dois olhos, seres lançado no fogo do inferno.',
        'Vede, não desprezeis algum destes pequeninos, porque eu vos digo que os seus anjos nos céus sempre vêem a face de meu Pai que está nos céus.',
        'Porque o Filho do homem veio salvar o que se tinha perdido.',
        'Que vos parece? Se algum homem tiver cem ovelhas, e uma delas se desgarrar, não irá pelos montes, deixando as noventa e nove, em busca da que se desgarrou?',
        'E, se porventura achá-la, em verdade vos digo que maior prazer tem por aquela do que pelas noventa e nove que se não desgarraram.',
        'Assim, também, não é vontade de vosso Pai, que está nos céus, que um destes pequeninos se perca.',
        'Ora, se teu irmão pecar contra ti, vai, e repreende-o entre ti e ele só; se te ouvir, ganhaste a teu irmão;',
        'Mas, se não te ouvir, leva ainda contigo um ou dois, para que pela boca de duas ou três testemunhas toda a palavra seja confirmada.',
        'E, se não as escutar, dize-o à igreja; e, se também não escutar a igreja, considera-o como um gentio e publicano.',
        'Em verdade vos digo que tudo o que ligardes na terra será ligado no céu, e tudo o que desligardes na terra será desligado no céu.',
        'Também vos digo que, se dois de vós concordarem na terra acerca de qualquer coisa que pedirem, isso lhes será feito por meu Pai, que está nos céus.',
        'Porque, onde estiverem dois ou três reunidos em meu nome, aí estou eu no meio deles.',
        'Então Pedro, aproximando-se dele, disse: Senhor, até quantas vezes pecará meu irmão contra mim, e eu lhe perdoarei? Até sete?',
        'Jesus lhe disse: Não te digo que até sete; mas, até setenta vezes sete.',
        'Por isso o reino dos céus pode comparar-se a um certo rei que quis fazer contas com os seus servos;',
        'E, começando a fazer contas, foi-lhe apresentado um que lhe devia dez mil talentos;',
        'E, não tendo ele com que pagar, o seu senhor mandou que ele, e sua mulher e seus filhos fossem vendidos, com tudo quanto tinha, para que a dívida se lhe pagasse.',
        'Então aquele servo, prostrando-se, o reverenciava, dizendo: Senhor, sê generoso para comigo, e tudo te pagarei.',
        'Então o Senhor daquele servo, movido de íntima compaixão, soltou-o e perdoou-lhe a dívida.',
        'Saindo, porém, aquele servo, encontrou um dos seus conservos, que lhe devia cem dinheiros, e, lançando mão dele, sufocava-o, dizendo: Paga-me o que me deves.',
        'Então o seu companheiro, prostrando-se a seus pés, rogava-lhe, dizendo: Sê generoso para comigo, e tudo te pagarei.',
        'Ele, porém, não quis, antes foi encerrá-lo na prisão, até que pagasse a dívida.',
        'Vendo, pois, os seus conservos o que acontecia, contristaram-se muito, e foram declarar ao seu senhor tudo o que se passara.',
        'Então o seu senhor, chamando-o à sua presença, disse-lhe: Servo malvado, perdoei-te toda aquela dívida, porque me suplicaste.',
        'Não devias tu, igualmente, ter compaixão do teu companheiro, como eu também tive misericórdia de ti?',
        'E, indignado, o seu senhor o entregou aos atormentadores, até que pagasse tudo o que lhe devia.',
        'Assim vos fará, também, meu Pai celestial, se do coração não perdoardes, cada um a seu irmão, as suas ofensas.',
      ],
      [
        'E aconteceu que, concluindo Jesus estes discursos, saiu da Galiléia, e dirigiu- se aos confins da Judéia, além do Jordão;',
        'E seguiram-no grandes multidões, e curou-as ali.',
        'Então chegaram ao pé dele os fariseus, tentando-o, e dizendo-lhe: É lícito ao homem repudiar sua mulher por qualquer motivo?',
        'Ele, porém, respondendo, disse-lhes: Não tendes lido que aquele que os fez no princípio macho e fêmea os fez,',
        'E disse: Portanto, deixará o homem pai e mãe, e se unirá a sua mulher, e serão dois numa só carne?',
        'Assim não são mais dois, mas uma só carne. Portanto, o que Deus ajuntou não o separe o homem.',
        'Disseram-lhe eles: Então, por que mandou Moisés dar-lhe carta de divórcio, e repudiá-la?',
        'Disse-lhes ele: Moisés, por causa da dureza dos vossos corações, vos permitiu repudiar vossas mulheres; mas ao princípio não foi assim.',
        'Eu vos digo, porém, que qualquer que repudiar sua mulher, não sendo por causa de fornicação, e casar com outra, comete adultério; e o que casar com a repudiada também comete adultério.',
        'Disseram-lhe seus discípulos: Se assim é a condição do homem relativamente à mulher, não convém casar.',
        'Ele, porém, lhes disse: Nem todos podem receber esta palavra, mas só aqueles a quem foi concedido.',
        'Porque há eunucos que assim nasceram do ventre da mãe; e há eunucos que foram castrados pelos homens; e há eunucos que se castraram a si mesmos, por causa do reino dos céus. Quem pode receber isto, receba-o.',
        'Trouxeram-lhe, então, alguns meninos, para que sobre eles pusesse as mãos, e orasse; mas os discípulos os repreendiam.',
        'Jesus, porém, disse: Deixai os meninos, e não os estorveis de vir a mim; porque dos tais é o reino dos céus.',
        'E, tendo-lhes imposto as mãos, partiu dali.',
        'E eis que, aproximando-se dele um jovem, disse-lhe: Bom Mestre, que bem farei para conseguir a vida eterna?',
        'E ele disse-lhe: Por que me chamas bom? Não há bom senão um só, que é Deus. Se queres, porém, entrar na vida, guarda os mandamentos.',
        'Disse-lhe ele: Quais? E Jesus disse: Não matarás, não cometerás adultério, não furtarás, não dirás falso testemunho;',
        'Honra teu pai e tua mãe, e amarás o teu próximo como a ti mesmo.',
        'Disse-lhe o jovem: Tudo isso tenho guardado desde a minha mocidade; que me falta ainda?',
        'Disse-lhe Jesus: Se queres ser perfeito, vai, vende tudo o que tens e dá-o aos pobres, e terás um tesouro no céu; e vem, e segue-me.',
        'E o jovem, ouvindo esta palavra, retirou-se triste, porque possuía muitas propriedades.',
        'Disse então Jesus aos seus discípulos: Em verdade vos digo que é difícil entrar um rico no reino dos céus.',
        'E, outra vez vos digo que é mais fácil passar um camelo pelo fundo de uma agulha do que entrar um rico no reino de Deus.',
        'Os seus discípulos, ouvindo isto, admiraram-se muito, dizendo: Quem poderá pois salvar-se?',
        'E Jesus, olhando para eles, disse-lhes: Aos homens é isso impossível, mas a Deus tudo é possível.',
        'Então Pedro, tomando a palavra, disse-lhe: Eis que nós deixamos tudo, e te seguimos; que receberemos?',
        'E Jesus disse-lhes: Em verdade vos digo que vós, que me seguistes, quando, na regeneração, o Filho do homem se assentar no trono da sua glória, também vos assentareis sobre doze tronos, para julgar as doze tribos de Israel.',
        'E todo aquele que tiver deixado casas, ou irmãos, ou irmãs, ou pai, ou mãe, ou mulher, ou filhos, ou terras, por amor de meu nome, receberá cem vezes tanto, e herdará a vida eterna.',
        'Porém, muitos primeiros serão os derradeiros, e muitos derradeiros serão os primeiros.',
      ],
      [
        'Porque o reino dos céus é semelhante a um homem, pai de família, que saiu de madrugada a assalariar trabalhadores para a sua vinha.',
        'E, ajustando com os trabalhadores a um dinheiro por dia, mandou-os para a sua vinha.',
        'E, saindo perto da hora terceira, viu outros que estavam ociosos na praça,',
        'E disse-lhes: Ide vós também para a vinha, e dar-vos-ei o que for justo. E eles foram.',
        'Saindo outra vez, perto da hora sexta e nona, fez o mesmo.',
        'E, saindo perto da hora undécima, encontrou outros que estavam ociosos, e perguntou- lhes: Por que estais ociosos todo o dia?',
        'Disseram-lhe eles: Porque ninguém nos assalariou. Diz-lhes ele: Ide vós também para a vinha, e recebereis o que for justo.',
        'E, aproximando-se a noite, diz o senhor da vinha ao seu mordomo: Chama os trabalhadores, e paga-lhes o jornal, começando pelos derradeiros, até aos primeiros.',
        'E, chegando os que tinham ido perto da hora undécima, receberam um dinheiro cada um.',
        'Vindo, porém, os primeiros, cuidaram que haviam de receber mais; mas do mesmo modo receberam um dinheiro cada um.',
        'E, recebendo-o, murmuravam contra o pai de família,',
        'Dizendo: Estes derradeiros trabalharam só uma hora, e tu os igualaste conosco, que suportamos a fadiga e a calma do dia.',
        'Mas ele, respondendo, disse a um deles: Amigo, não te faço agravo; não ajustaste tu comigo um dinheiro?',
        'Toma o que é teu, e retira-te; eu quero dar a este derradeiro tanto como a ti.',
        'Ou não me é lícito fazer o que quiser do que é meu? Ou é mau o teu olho porque eu sou bom?',
        'Assim os derradeiros serão primeiros, e os primeiros derradeiros; porque muitos são chamados, mas poucos escolhidos.',
        'E, subindo Jesus a Jerusalém, chamou à parte os seus doze discípulos, e no caminho disse-lhes:',
        'Eis que vamos para Jerusalém, e o Filho do homem será entregue aos príncipes dos sacerdotes, e aos escribas, e condená-lo-ão à morte.',
        'E o entregarão aos gentios para que dele escarneçam, e o açoitem e crucifiquem, e ao terceiro dia ressuscitará.',
        'Então se aproximou dele a mãe dos filhos de Zebedeu, com seus filhos, adorando-o, e fazendo-lhe um pedido.',
        'E ele diz-lhe: Que queres? Ela respondeu: Dize que estes meus dois filhos se assentem, um à tua direita e outro à tua esquerda, no teu reino.',
        'Jesus, porém, respondendo, disse: Não sabeis o que pedis. Podeis vós beber o cálice que eu hei de beber, e ser batizados com o batismo com que eu sou batizado? Dizem-lhe eles: Podemos.',
        'E diz-lhes ele: Na verdade bebereis o meu cálice e sereis batizados com o batismo com que eu sou batizado, mas o assentar-se à minha direita ou à minha esquerda não me pertence dá-lo, mas é para aqueles para quem meu Pai o tem preparado.',
        'E, quando os dez ouviram isto, indignaram-se contra os dois irmãos.',
        'Então Jesus, chamando-os para junto de si, disse: Bem sabeis que pelos príncipes dos gentios são estes dominados, e que os grandes exercem autoridade sobre eles.',
        'Não será assim entre vós; mas todo aquele que quiser entre vós fazer-se grande seja vosso serviçal;',
        'E, qualquer que entre vós quiser ser o primeiro, seja vosso servo;',
        'Bem como o Filho do homem não veio para ser servido, mas para servir, e para dar a sua vida em resgate de muitos.',
        'E, saindo eles de Jericó, seguiu-o grande multidão.',
        'E eis que dois cegos, assentados junto do caminho, ouvindo que Jesus passava, clamaram, dizendo: Senhor, Filho de Davi, tem misericórdia de nós!',
        'E a multidão os repreendia, para que se calassem; eles, porém, cada vez clamavam mais, dizendo: Senhor, Filho de Davi, tem misericórdia de nós!',
        'E Jesus, parando, chamou-os, e disse: Que quereis que vos faça?',
        'Disseram-lhe eles: Senhor, que os nossos olhos sejam abertos.',
        'Então Jesus, movido de íntima compaixão, tocou-lhes nos olhos, e logo seus olhos viram; e eles o seguiram.',
      ],
      [
        'E, quando se aproximaram de Jerusalém, e chegaram a Betfagé, ao Monte das Oliveiras, enviou, então, Jesus dois discípulos, dizendo-lhes:',
        'Ide à aldeia que está defronte de vós, e logo encontrareis uma jumenta presa, e um jumentinho com ela; desprendei-a, e trazei-mos.',
        'E, se alguém vos disser alguma coisa, direis que o Senhor os há de mister; e logo os enviará.',
        'Ora, tudo isto aconteceu para que se cumprisse o que foi dito pelo profeta, que diz:',
        'Dizei à filha de Sião: Eis que o teu Rei aí te vem,Manso, e assentado sobre uma jumenta,E sobre um jumentinho, filho de animal de carga.',
        'E, indo os discípulos, e fazendo como Jesus lhes ordenara,',
        'Trouxeram a jumenta e o jumentinho, e sobre eles puseram as suas vestes, e fizeram-no assentar em cima.',
        'E muitíssima gente estendia as suas vestes pelo caminho, e outros cortavam ramos de árvores, e os espalhavam pelo caminho.',
        'E a multidão que ia adiante, e a que seguia, clamava, dizendo: Hosana ao Filho de Davi; bendito o que vem em nome do Senhor. Hosana nas alturas!',
        'E, entrando ele em Jerusalém, toda a cidade se alvoroçou, dizendo: Quem é este?',
        'E a multidão dizia: Este é Jesus, o profeta de Nazaré da Galiléia.',
        'E entrou Jesus no templo de Deus, e expulsou todos os que vendiam e compravam no templo, e derribou as mesas dos cambistas e as cadeiras dos que vendiam pombas;',
        'E disse-lhes: Está escrito: A minha casa será chamada casa de oração; mas vós a tendes convertido em covil de ladrões.',
        'E foram ter com ele no templo cegos e coxos, e curou-os.',
        'Vendo, então, os principais dos sacerdotes e os escribas as maravilhas que fazia, e os meninos clamando no templo: Hosana ao Filho de Davi, indignaram-se,',
        'E disseram-lhe: Ouves o que estes dizem? E Jesus lhes disse: Sim; nunca lestes: Pela boca dos meninos e das criancinhas de peito tiraste o perfeito louvor?',
        'E, deixando-os, saiu da cidade para Betânia, e ali passou a noite.',
        'E, de manhã, voltando para a cidade, teve fome;',
        'E, avistando uma figueira perto do caminho, dirigiu-se a ela, e não achou nela senão folhas. E disse-lhe: Nunca mais nasça fruto de ti! E a figueira secou imediatamente.',
        'E os discípulos, vendo isto, maravilharam-se, dizendo: Como secou imediatamente a figueira?',
        'Jesus, porém, respondendo, disse-lhes: Em verdade vos digo que, se tiverdes fé e não duvidardes, não só fareis o que foi feito à figueira, mas até se a este monte disserdes: Ergue-te, e precipita-te no mar, assim será feito;',
        'E, tudo o que pedirdes em oração, crendo, o recebereis.',
        'E, chegando ao templo, acercaram-se dele, estando já ensinando, os príncipes dos sacerdotes e os anciãos do povo, dizendo: Com que autoridade fazes isto? e quem te deu tal autoridade?',
        'E Jesus, respondendo, disse-lhes: Eu também vos perguntarei uma coisa; se ma disserdes, também eu vos direi com que autoridade faço isto.',
        'O batismo de João, de onde era? Do céu, ou dos homens? E pensavam entre si, dizendo: Se dissermos: Do céu, ele nos dirá: Então por que não o crestes?',
        'E, se dissermos: Dos homens, tememos o povo, porque todos consideram João como profeta.',
        'E, respondendo a Jesus, disseram: Não sabemos. Ele disse-lhes: Nem eu vos digo com que autoridade faço isto.',
        'Mas, que vos parece? Um homem tinha dois filhos, e, dirigindo-se ao primeiro, disse: Filho, vai trabalhar hoje na minha vinha.',
        'Ele, porém, respondendo, disse: Não quero. Mas depois, arrependendo-se, foi.',
        'E, dirigindo-se ao segundo, falou-lhe de igual modo; e, respondendo ele, disse: Eu vou, senhor; e não foi.',
        'Qual dos dois fez a vontade do pai? Disseram-lhe eles: O primeiro. Disse-lhes Jesus: Em verdade vos digo que os publicanos e as meretrizes entram adiante de vós no reino de Deus.',
        'Porque João veio a vós no caminho da justiça, e não o crestes, mas os publicanos e as meretrizes o creram; vós, porém, vendo isto, nem depois vos arrependestes para o crer.',
        'Ouvi, ainda, outra parábola: Houve um homem, pai de família, que plantou uma vinha, e circundou-a de um valado, e construiu nela um lagar, e edificou uma torre, e arrendou-a a uns lavradores, e ausentou-se para longe.',
        'E, chegando o tempo dos frutos, enviou os seus servos aos lavradores, para receber os seus frutos.',
        'E os lavradores, apoderando-se dos servos, feriram um, mataram outro, e apedrejaram outro.',
        'Depois enviou outros servos, em maior número do que os primeiros; e eles fizeram-lhes o mesmo.',
        'E, por último, enviou-lhes seu filho, dizendo: Terão respeito a meu filho.',
        'Mas os lavradores, vendo o filho, disseram entre si: Este é o herdeiro; vinde, matemo-lo, e apoderemo-nos da sua herança.',
        'E, lançando mão dele, o arrastaram para fora da vinha, e o mataram.',
        'Quando, pois, vier o senhor da vinha, que fará àqueles lavradores?',
        'Dizem-lhe eles: Dará afrontosa morte aos maus, e arrendará a vinha a outros lavradores, que a seu tempo lhe dêem os frutos.',
        'Diz-lhes Jesus: Nunca lestes nas Escrituras:A pedra, que os edificadores rejeitaram,essa foi posta por cabeça do ângulo;pelo Senhor foi feito isto,E é maravilhoso aos nossos olhos?',
        'Portanto, eu vos digo que o reino de Deus vos será tirado, e será dado a uma nação que dê os seus frutos.',
        'E, quem cair sobre esta pedra, despedaçar-se-á; e aquele sobre quem ela cair ficará reduzido a pó.',
        'E os príncipes dos sacerdotes e os fariseus, ouvindo estas palavras, entenderam que falava deles;',
        'E, pretendendo prendê-lo, recearam o povo, porquanto o tinham por profeta.',
      ],
      [
        'Então Jesus, tomando a palavra, tornou a falar-lhes em parábolas, dizendo:',
        'O reino dos céus é semelhante a um certo rei que celebrou as bodas de seu filho;',
        'E enviou os seus servos a chamar os convidados para as bodas, e estes não quiseram vir.',
        'Depois, enviou outros servos, dizendo: Dizei aos convidados: Eis que tenho o meu jantar preparado, os meus bois e cevados já mortos, e tudo já pronto; vinde às bodas.',
        'Eles, porém, não fazendo caso, foram, um para o seu campo, outro para o seu negócio;',
        'E os outros, apoderando-se dos servos, os ultrajaram e mataram.',
        'E o rei, tendo notícia disto, encolerizou-se e, enviando os seus exércitos, destruiu aqueles homicidas, e incendiou a sua cidade.',
        'Então diz aos servos: As bodas, na verdade, estão preparadas, mas os convidados não eram dignos.',
        'Ide, pois, às saídas dos caminhos, e convidai para as bodas a todos os que encontrardes.',
        'E os servos, saindo pelos caminhos, ajuntaram todos quantos encontraram, tanto maus como bons; e a festa nupcial foi cheia de convidados.',
        'E o rei, entrando para ver os convidados, viu ali um homem que não estava trajado com veste de núpcias.',
        'E disse-lhe: Amigo, como entraste aqui, não tendo veste nupcial? E ele emudeceu.',
        'Disse, então, o rei aos servos: Amarrai-o de pés e mãos, levai-o, e lançai-o nas trevas exteriores; ali haverá pranto e ranger de dentes.',
        'Porque muitos são chamados, mas poucos escolhidos.',
        'Então, retirando-se os fariseus, consultaram entre si como o surpreenderiam nalguma palavra;',
        'E enviaram-lhe os seus discípulos, com os herodianos, dizendo: Mestre, bem sabemos que és verdadeiro, e ensinas o caminho de Deus segundo a verdade, e de ninguém se te dá, porque não olhas a aparência dos homens.',
        'Dize-nos, pois, que te parece? É lícito pagar o tributo a César, ou não?',
        'Jesus, porém, conhecendo a sua malícia, disse: Por que me experimentais, hipócritas?',
        'Mostrai-me a moeda do tributo. E eles lhe apresentaram um dinheiro.',
        'E ele diz-lhes: De quem é esta efígie e esta inscrição?',
        'Dizem-lhe eles: De César. Então ele lhes disse: Dai pois a César o que é de César, e a Deus o que é de Deus.',
        'E eles, ouvindo isto, maravilharam-se, e, deixando-o, se retiraram.',
        'No mesmo dia chegaram junto dele os saduceus, que dizem não haver ressurreição, e o interrogaram,',
        'Dizendo: Mestre, Moisés disse: Se morrer alguém, não tendo filhos, casará o seu irmão com a mulher dele, e suscitará descendência a seu irmão.',
        'Ora, houve entre nós sete irmãos; e o primeiro, tendo casado, morreu e, não tendo descendência, deixou sua mulher a seu irmão.',
        'Da mesma sorte o segundo, e o terceiro, até ao sétimo;',
        'Por fim, depois de todos, morreu também a mulher.',
        'Portanto, na ressurreição, de qual dos sete será a mulher, visto que todos a possuíram?',
        'Jesus, porém, respondendo, disse-lhes: Errais, não conhecendo as Escrituras, nem o poder de Deus.',
        'Porque na ressurreição nem casam nem são dados em casamento; mas serão como os anjos de Deus no céu.',
        'E, acerca da ressurreição dos mortos, não tendes lido o que Deus vos declarou, dizendo:',
        'Eu sou o Deus de Abraão, o Deus de Isaque, e o Deus de Jacó? Ora, Deus não é Deus dos mortos, mas dos vivos.',
        'E, as turbas, ouvindo isto, ficaram maravilhadas da sua doutrina.',
        'E os fariseus, ouvindo que ele fizera emudecer os saduceus, reuniram-se no mesmo lugar.',
        'E um deles, doutor da lei, interrogou-o para o experimentar, dizendo:',
        'Mestre, qual é o grande mandamento na lei?',
        'E Jesus disse-lhe: Amarás o Senhor teu Deus de todo o teu coração, e de toda a tua alma, e de todo o teu pensamento.',
        'Este é o primeiro e grande mandamento.',
        'E o segundo, semelhante a este, é: Amarás o teu próximo como a ti mesmo.',
        'Destes dois mandamentos dependem toda a lei e os profetas.',
        'E, estando reunidos os fariseus, interrogou-os Jesus,',
        'Dizendo: Que pensais vós do Cristo? De quem é filho? Eles disseram-lhe: De Davi.',
        'Disse-lhes ele: Como é então que Davi, em espírito, lhe chama Senhor, dizendo:',
        'Disse o Senhor ao meu Senhor:Assenta-te à minha direita,Até que eu ponha os teus inimigos por escabelo de teus pés?',
        'Se Davi, pois, lhe chama Senhor, como é seu filho?',
        'E ninguém podia responder-lhe uma palavra; nem desde aquele dia ousou mais alguém interrogá-lo.',
      ],
      [
        'Então falou Jesus à multidão, e aos seus discípulos,',
        'Dizendo: Na cadeira de Moisés estão assentados os escribas e fariseus.',
        'Todas as coisas, pois, que vos disserem que observeis, observai-as e fazei-as; mas não procedais em conformidade com as suas obras, porque dizem e não fazem;',
        'Pois atam fardos pesados e difíceis de suportar, e os põem aos ombros dos homens; eles, porém, nem com seu dedo querem movê-los;',
        'E fazem todas as obras a fim de serem vistos pelos homens; pois trazem largos filactérios, e alargam as franjas das suas vestes,',
        'E amam os primeiros lugares nas ceias e as primeiras cadeiras nas sinagogas,',
        'E as saudações nas praças, e o serem chamados pelos homens; Rabi, Rabi.',
        'Vós, porém, não queirais ser chamados Rabi, porque um só é o vosso Mestre, a saber, o Cristo, e todos vós sois irmãos.',
        'E a ninguém na terra chameis vosso pai, porque um só é o vosso Pai, o qual está nos céus.',
        'Nem vos chameis mestres, porque um só é o vosso Mestre, que é o Cristo.',
        'O maior dentre vós será vosso servo.',
        'E o que a si mesmo se exaltar será humilhado; e o que a si mesmo se humilhar será exaltado.',
        'Mas ai de vós, escribas e fariseus, hipócritas! pois que fechais aos homens o reino dos céus; e nem vós entrais nem deixais entrar aos que estão entrando.',
        'Ai de vós, escribas e fariseus, hipócritas! pois que devorais as casas das viúvas, sob pretexto de prolongadas orações; por isso sofrereis mais rigoroso juízo.',
        'Ai de vós, escribas e fariseus, hipócritas! pois que percorreis o mar e a terra para fazer um prosélito; e, depois de o terdes feito, o fazeis filho do inferno duas vezes mais do que vós.',
        'Ai de vós, condutores cegos! pois que dizeis: Qualquer que jurar pelo templo, isso nada é; mas o que jurar pelo ouro do templo, esse é devedor.',
        'Insensatos e cegos! Pois qual é maior: o ouro, ou o templo, que santifica o ouro?',
        'E aquele que jurar pelo altar isso nada é; mas aquele que jurar pela oferta que está sobre o altar, esse é devedor.',
        'Insensatos e cegos! Pois qual é maior: a oferta, ou o altar, que santifica a oferta?',
        'Portanto, o que jurar pelo altar, jura por ele e por tudo o que sobre ele está;',
        'E, o que jurar pelo templo, jura por ele e por aquele que nele habita;',
        'E, o que jurar pelo céu, jura pelo trono de Deus e por aquele que está assentado nele.',
        'Ai de vós, escribas e fariseus, hipócritas! pois que dizimais a hortelã, o endro e o cominho, e desprezais o mais importante da lei, o juízo, a misericórdia e a fé; deveis, porém, fazer estas coisas, e não omitir aquelas.',
        'Condutores cegos! que coais um mosquito e engulis um camelo.',
        'Ai de vós, escribas e fariseus, hipócritas! pois que limpais o exterior do copo e do prato, mas o interior está cheio de rapina e de intemperança.',
        'Fariseu cego! limpa primeiro o interior do copo e do prato, para que também o exterior fique limpo.',
        'Ai de vós, escribas e fariseus, hipócritas! pois que sois semelhantes aos sepulcros caiados, que por fora realmente parecem formosos, mas interiormente estão cheios de ossos de mortos e de toda a imundícia.',
        'Assim também vós exteriormente pareceis justos aos homens, mas interiormente estais cheios de hipocrisia e de iniqüidade.',
        'Ai de vós, escribas e fariseus, hipócritas! pois que edificais os sepulcros dos profetas e adornais os monumentos dos justos,',
        'E dizeis: Se existíssemos no tempo de nossos pais, nunca nos associaríamos com eles para derramar o sangue dos profetas.',
        'Assim, vós mesmos testificais que sois filhos dos que mataram os profetas.',
        'Enchei vós, pois, a medida de vossos pais.',
        'Serpentes, raça de víboras! como escapareis da condenação do inferno?',
        'Portanto, eis que eu vos envio profetas, sábios e escribas; a uns deles matareis e crucificareis; e a outros deles açoitareis nas vossas sinagogas e os perseguireis de cidade em cidade;',
        'Para que sobre vós caia todo o sangue justo, que foi derramado sobre a terra, desde o sangue de Abel, o justo, até ao sangue de Zacarias, filho de Baraquias, que matastes entre o santuário e o altar.',
        'Em verdade vos digo que todas estas coisas hão de vir sobre esta geração.',
        'Jerusalém, Jerusalém, que matas os profetas, e apedrejas os que te são enviados! quantas vezes quis eu ajuntar os teus filhos, como a galinha ajunta os seus pintos debaixo das asas, e tu não quiseste!',
        'Eis que a vossa casa vai ficar-vos deserta;',
        'Porque eu vos digo que desde agora me não vereis mais, até que digais: Bendito o que vem em nome do Senhor.',
      ],
      [
        'E, quando Jesus ia saindo do templo, aproximaram-se dele os seus discípulos para lhe mostrarem a estrutura do templo.',
        'Jesus, porém, lhes disse: Não vedes tudo isto? Em verdade vos digo que não ficará aqui pedra sobre pedra que não seja derrubada.',
        'E, estando assentado no Monte das Oliveiras, chegaram-se a ele os seus discípulos em particular, dizendo: Dize-nos, quando serão essas coisas, e que sinal haverá da tua vinda e do fim do mundo?',
        'E Jesus, respondendo, disse-lhes: Acautelai-vos, que ninguém vos engane;',
        'Porque muitos virão em meu nome, dizendo: Eu sou o Cristo; e enganarão a muitos.',
        'E ouvireis de guerras e de rumores de guerras; olhai, não vos assusteis, porque é mister que isso tudo aconteça, mas ainda não é o fim.',
        'Porquanto se levantará nação contra nação, e reino contra reino, e haverá fomes, e pestes, e terremotos, em vários lugares.',
        'Mas todas estas coisas são o princípio de dores.',
        'Então vos hão de entregar para serdes atormentados, e matar-vosão; e sereis odiados de todas as nações por causa do meu nome.',
        'Nesse tempo muitos serão escandalizados, e trair-se-ão uns aos outros, e uns aos outros se odiarào.',
        'E surgirão muitos falsos profetas, e enganarão a muitos.',
        'E, por se multiplicar a iniqüidade, o amor de muitos esfriará.',
        'Mas aquele que perseverar até ao fim, esse será salvo.',
        'E este evangelho do reino será pregado em todo o mundo, em testemunho a todas as nações, e então virá o fim.',
        'Quando, pois, virdes que a abominação da desolação, de que falou o profeta Daniel, está no lugar santo; quem lê, entenda;',
        'Então, os que estiverem na Judéia, fujam para os montes;',
        'E quem estiver sobre o telhado não desça a tirar alguma coisa de sua casa;',
        'E quem estiver no campo não volte atrás a buscar as suas vestes.',
        'Mas ai das grávidas e das que amamentarem naqueles dias!',
        'E orai para que a vossa fuga não aconteça no inverno nem no sábado;',
        'Porque haverá então grande aflição, como nunca houve desde o princípio do mundo até agora, nem tampouco há de haver.',
        'E, se aqueles dias não fossem abreviados, nenhuma carne se salvaria; mas por causa dos escolhidos serão abreviados aqueles dias.',
        'Então, se alguém vos disser: Eis que o Cristo está aqui, ou ali, não lhe deis crédito;',
        'Porque surgirão falsos cristos e falsos profetas, e farão tão grandes sinais e prodígios que, se possível fora, enganariam até os escolhidos.',
        'Eis que eu vo-lo tenho predito.',
        'Portanto, se vos disserem: Eis que ele está no deserto, não saiais. Eis que ele está no interior da casa; não acrediteis.',
        'Porque, assim como o relâmpago sai do oriente e se mostra até ao ocidente, assim será também a vinda do Filho do homem.',
        'Pois onde estiver o cadáver, aí se ajuntarão as águias.',
        'E, logo depois da aflição daqueles dias, o sol escurecerá, e a lua não dará a sua luz, e as estrelas cairão do céu, e as potências dos céus serão abaladas.',
        'Então aparecerá no céu o sinal do Filho do homem; e todas as tribos da terra se lamentarão, e verão o Filho do homem, vindo sobre as nuvens do céu, com poder e grande glória.',
        'E ele enviará os seus anjos com rijo clamor de trombeta, os quais ajuntarão os seus escolhidos desde os quatro ventos, de uma à outra extremidade dos céus.',
        'Aprendei, pois, esta parábola da figueira: Quando já os seus ramos se tornam tenros e brotam folhas, sabeis que está próximo o verão.',
        'Igualmente, quando virdes todas estas coisas, sabei que ele está próximo, às portas.',
        'Em verdade vos digo que não passará esta geração sem que todas estas coisas aconteçam.',
        'O céu e a terra passarão, mas as minhas palavras não hão de passar.',
        'Mas daquele dia e hora ninguém sabe, nem os anjos do céu, mas unicamente meu Pai.',
        'E, como foi nos dias de Noé, assim será também a vinda do Filho do homem.',
        'Porquanto, assim como, nos dias anteriores ao dilúvio, comiam, bebiam, casavam e davam-se em casamento, até ao dia em que Noé entrou na arca,',
        'E não o perceberam, até que veio o dilúvio, e os levou a todos, assim será também a vinda do Filho do homem.',
        'Então, estando dois no campo, será levado um, e deixado o outro;',
        'Estando duas moendo no moinho, será levada uma, e deixada outra.',
        'Vigiai, pois, porque não sabeis a que hora há de vir o vosso Senhor.',
        'Mas considerai isto: se o pai de família soubesse a que vigília da noite havia de vir o ladrão, vigiaria e não deixaria minar a sua casa.',
        'Por isso, estai vós apercebidos também; porque o Filho do homem há de vir à hora em que não penseis.',
        'Quem é, pois, o servo fiel e prudente, que o seu senhor constituiu sobre a sua casa, para dar o sustento a seu tempo?',
        'Bem-aventurado aquele servo que o seu senhor, quando vier, achar servindo assim.',
        'Em verdade vos digo que o porá sobre todos os seus bens.',
        'Mas se aquele mau servo disser no seu coração: O meu senhor tarde virá;',
        'E começar a espancar os seus conservos, e a comer e a beber com os ébrios,',
        'Virá o senhor daquele servo num dia em que o não espera, e à hora em que ele não sabe,',
        'E separá-lo-á, e destinará a sua parte com os hipócritas; ali haverá pranto e ranger de dentes.',
      ],
      [
        'Então o reino dos céus será semelhante a dez virgens que, tomando as suas lâmpadas, saíram ao encontro do esposo.',
        'E cinco delas eram prudentes, e cinco loucas.',
        'As loucas, tomando as suas lâmpadas, não levaram azeite consigo.',
        'Mas as prudentes levaram azeite em suas vasilhas, com as suas lâmpadas.',
        'E, tardando o esposo, tosquenejaram todas, e adormeceram.',
        'Mas à meia-noite ouviu-se um clamor: Aí vem o esposo, saí-lhe ao encontro.',
        'Então todas aquelas virgens se levantaram, e prepararam as suas lâmpadas.',
        'E as loucas disseram às prudentes: Dai-nos do vosso azeite, porque as nossas lâmpadas se apagam.',
        'Mas as prudentes responderam, dizendo: Não seja caso que nos falte a nós e a vós, ide antes aos que o vendem, e comprai-o para vós.',
        'E, tendo elas ido comprá-lo, chegou o esposo, e as que estavam preparadas entraram com ele para as bodas, e fechou-se a porta.',
        'E depois chegaram também as outras virgens, dizendo: Senhor, Senhor, abre-nos.',
        'E ele, respondendo, disse: Em verdade vos digo que vos não conheço.',
        'Vigiai, pois, porque não sabeis o dia nem a hora em que o Filho do homem há de vir.',
        'Porque isto é também como um homem que, partindo para fora da terra, chamou os seus servos, e entregou-lhes os seus bens.',
        'E a um deu cinco talentos, e a outro dois, e a outro um, a cada um segundo a sua capacidade, e ausentou-se logo para longe.',
        'E, tendo ele partido, o que recebera cinco talentos negociou com eles, e granjeou outros cinco talentos.',
        'Da mesma sorte, o que recebera dois, granjeou também outros dois.',
        'Mas o que recebera um, foi e cavou na terra e escondeu o dinheiro do seu senhor.',
        'E muito tempo depois veio o senhor daqueles servos, e fez contas com eles.',
        'Então aproximou-se o que recebera cinco talentos, e trouxe-lhe outros cinco talentos, dizendo: Senhor, entregaste-me cinco talentos; eis aqui outros cinco talentos que granjeei com eles.',
        'E o seu senhor lhe disse: Bem está, servo bom e fiel. Sobre o pouco foste fiel, sobre muito te colocarei; entra no gozo do teu senhor.',
        'E, chegando também o que tinha recebido dois talentos, disse: Senhor, entregaste-me dois talentos; eis que com eles granjeei outros dois talentos.',
        'Disse-lhe o seu senhor: Bem está, bom e fiel servo. Sobre o pouco foste fiel, sobre muito te colocarei; entra no gozo do teu senhor.',
        'Mas, chegando também o que recebera um talento, disse: Senhor, eu conhecia-te, que és um homem duro, que ceifas onde não semeaste e ajuntas onde não espalhaste;',
        'E, atemorizado, escondi na terra o teu talento; aqui tens o que é teu.',
        'Respondendo, porém, o seu senhor, disse-lhe: Mau e negligente servo; sabias que ceifo onde não semeei e ajunto onde não espalhei?',
        'Devias então ter dado o meu dinheiro aos banqueiros e, quando eu viesse, receberia o meu com os juros.',
        'Tirai-lhe pois o talento, e dai-o ao que tem os dez talentos.',
        'Porque a qualquer que tiver será dado, e terá em abundância; mas ao que não tiver até o que tem ser-lhe-á tirado.',
        'Lançai, pois, o servo inútil nas trevas exteriores; ali haverá pranto e ranger de dentes.',
        'E quando o Filho do homem vier em sua glória, e todos os santos anjos com ele, então se assentará no trono da sua glória;',
        'E todas as nações serão reunidas diante dele, e apartará uns dos outros, como o pastor aparta dos bodes as ovelhas;',
        'E porá as ovelhas à sua direita, mas os bodes à esquerda.',
        'Então dirá o Rei aos que estiverem à sua direita: Vinde, benditos de meu Pai, possuí por herança o reino que vos está preparado desde a fundação do mundo;',
        'Porque tive fome, e destes-me de comer; tive sede, e destes-me de beber; era estrangeiro, e hospedastes-me;',
        'Estava nu, e vestistes-me; adoeci, e visitastes-me; estive na prisão, e foste me ver.',
        'Então os justos lhe responderão, dizendo: Senhor, quando te vimos com fome, e te demos de comer? ou com sede, e te demos de beber?',
        'E quando te vimos estrangeiro, e te hospedamos? ou nu, e te vestimos?',
        'E quando te vimos enfermo, ou na prisão, e fomos ver-te?',
        'E, respondendo o Rei, lhes dirá: Em verdade vos digo que quando o fizestes a um destes meus pequeninos irmãos, a mim o fizestes.',
        'Então dirá também aos que estiverem à sua esquerda: Apartai-vos de mim, malditos, para o fogo eterno, preparado para o diabo e seus anjos;',
        'Porque tive fome, e não me destes de comer; tive sede, e não me destes de beber;',
        'Sendo estrangeiro, não me recolhestes; estando nu, não me vestistes; e enfermo, e na prisão, não me visitastes.',
        'Então eles também lhe responderão, dizendo: Senhor, quando te vimos com fome, ou com sede, ou estrangeiro, ou nu, ou enfermo, ou na prisão, e não te servimos?',
        'Então lhes responderá, dizendo: Em verdade vos digo que, quando a um destes pequeninos o não fizestes, não o fizestes a mim.',
        'E irão estes para o tormento eterno, mas os justos para a vida eterna.',
      ],
      [
        'E aconteceu que, quando Jesus concluiu todos estes discursos, disse aos seus discípulos:',
        'Bem sabeis que daqui a dois dias é a páscoa; e o Filho do homem será entregue para ser crucificado.',
        'Depois os príncipes dos sacerdotes, e os escribas, e os anciãos do povo reuniram-se na sala do sumo sacerdote, o qual se chamava Caifás.',
        'E consultaram-se mutuamente para prenderem Jesus com dolo e o matarem.',
        'Mas diziam: Não durante a festa, para que não haja alvoroço entre o povo.',
        'E, estando Jesus em Betânia, em casa de Simão, o leproso,',
        'Aproximou-se dele uma mulher com um vaso de alabastro, com ungüento de grande valor, e derramou-lho sobre a cabeça, quando ele estava assentado à mesa.',
        'E os seus discípulos, vendo isto, indignaram-se, dizendo: Por que é este desperdício?',
        'Pois este ungüento podia vender-se por grande preço, e dar-se o dinheiro aos pobres.',
        'Jesus, porém, conhecendo isto, disse-lhes: Por que afligis esta mulher? pois praticou uma boa ação para comigo.',
        'Porquanto sempre tendes convosco os pobres, mas a mim não me haveis de ter sempre.',
        'Ora, derramando ela este ungüento sobre o meu corpo, fê-lo preparando-me para o meu sepultamento.',
        'Em verdade vos digo que, onde quer que este evangelho for pregado em todo o mundo, também será referido o que ela fez, para memória sua.',
        'Então um dos doze, chamado Judas Iscariotes, foi ter com os príncipes dos sacerdotes,',
        'E disse: Que me quereis dar, e eu vo-lo entregarei? E eles lhe pesaram trinta moedas de prata,',
        'E desde então buscava oportunidade para o entregar.',
        'E, no primeiro dia da festa dos pães ázimos, chegaram os discípulos junto de Jesus, dizendo: Onde queres que façamos os preparativos para comeres a páscoa?',
        'E ele disse: Ide à cidade, a um certo homem, e dizei-lhe: O Mestre diz: O meu tempo está próximo; em tua casa celebrarei a páscoa com os meus discípulos.',
        'E os discípulos fizeram como Jesus lhes ordenara, e prepararam a páscoa.',
        'E, chegada a tarde, assentou-se à mesa com os doze.',
        'E, comendo eles, disse: Em verdade vos digo que um de vós me há de trair.',
        'E eles, entristecendo-se muito, começaram cada um a dizer-lhe: Porventura sou eu, Senhor?',
        'E ele, respondendo, disse: O que põe comigo a mão no prato, esse me há de trair.',
        'Em verdade o Filho do homem vai, como acerca dele está escrito, mas ai daquele homem por quem o Filho do homem é traído! Bom seria para esse homem se não houvera nascido.',
        'E, respondendo Judas, o que o traía, disse: Porventura sou eu, Rabi? Ele disse: Tu o disseste.',
        'E, quando comiam, Jesus tomou o pão, e abençoando-o, o partiu, e o deu aos discípulos, e disse: Tomai, comei, isto é o meu corpo.',
        'E, tomando o cálice, e dando graças, deu-lho, dizendo: Bebei dele todos;',
        'Porque isto é o meu sangue, o sangue do novo testamento, que é derramado por muitos, para remissão dos pecados.',
        'E digo-vos que, desde agora, não beberei deste fruto da vide, até aquele dia em que o beba novo convosco no reino de meu Pai.',
        'E, tendo cantado o hino, saíram para o Monte das Oliveiras.',
        'Então Jesus lhes disse: Todos vós esta noite vos escandalizareis em mim; porque está escrito: Ferirei o pastor, e as ovelhas do rebanho se dispersarão.',
        'Mas, depois de eu ressuscitar, irei adiante de vós para a Galiléia.',
        'Mas Pedro, respondendo, disse-lhe: Ainda que todos se escandalizem em ti, eu nunca me escandalizarei.',
        'Disse-lhe Jesus: Em verdade te digo que, nesta mesma noite, antes que o galo cante, três vezes me negarás.',
        'Disse-lhe Pedro: Ainda que me seja mister morrer contigo, não te negarei. E todos os discípulos disseram o mesmo.',
        'Então chegou Jesus com eles a um lugar chamado Getsêmani, e disse a seus discípulos: Assentai-vos aqui, enquanto vou além orar.',
        'E, levando consigo Pedro e os dois filhos de Zebedeu, começou a entristecer-se e a angustiar-se muito.',
        'Então lhes disse: A minha alma está cheia de tristeza até a morte; ficai aqui, e velai comigo.',
        'E, indo um pouco mais para diante, prostrou-se sobre o seu rosto, orando e dizendo: Meu Pai, se é possível, passe de mim este cálice; todavia, não seja como eu quero, mas como tu queres.',
        'E, voltando para os seus discípulos, achou-os adormecidos; e disse a Pedro: Então nem uma hora pudeste velar comigo?',
        'Vigiai e orai, para que não entreis em tentação; na verdade, o espírito está pronto, mas a carne é fraca.',
        'E, indo segunda vez, orou, dizendo: Pai meu, se este cálice não pode passar de mim sem eu o beber, faça-se a tua vontade.',
        'E, voltando, achou-os outra vez adormecidos; porque os seus olhos estavam pesados.',
        'E, deixando-os de novo, foi orar pela terceira vez, dizendo as mesmas palavras.',
        'Então chegou junto dos seus discípulos, e disse-lhes: Dormi agora, e repousai; eis que é chegada a hora, e o Filho do homem será entregue nas mãos dos pecadores.',
        'Levantai-vos, partamos; eis que é chegado o que me trai.',
        'E, estando ele ainda a falar, eis que chegou Judas, um dos doze, e com ele grande multidão com espadas e varapaus, enviada pelos príncipes dos sacerdotes e pelos anciãos do povo.',
        'E o que o traía tinha-lhes dado um sinal, dizendo: O que eu beijar é esse; prendei-o.',
        'E logo, aproximando-se de Jesus, disse: Eu te saúdo, Rabi; e beijou-o.',
        'Jesus, porém, lhe disse: Amigo, a que vieste? Então, aproximando-se eles, lançaram mão de Jesus, e o prenderam.',
        'E eis que um dos que estavam com Jesus, estendendo a mão, puxou da espada e, ferindo o servo do sumo sacerdote, cortou-lhe uma orelha.',
        'Então Jesus disse-lhe: Embainha a tua espada; porque todos os que lançarem mão da espada, à espada morrerão.',
        'Ou pensas tu que eu não poderia agora orar a meu Pai, e que ele não me daria mais de doze legiões de anjos?',
        'Como, pois, se cumpririam as Escrituras, que dizem que assim convém que aconteça?',
        'Então disse Jesus à multidão: Saístes, como para um salteador, com espadas e varapaus para me prender? Todos os dias me assentava junto de vós, ensinando no templo, e não me prendestes.',
        'Mas tudo isto aconteceu para que se cumpram as escrituras dos profetas. Então, todos os discípulos, deixando-o, fugiram.',
        'E os que prenderam a Jesus o conduziram à casa do sumo sacerdote Caifás, onde os escribas e os anciãos estavam reunidos.',
        'E Pedro o seguiu de longe, até ao pátio do sumo sacerdote e, entrando, assentou-se entre os criados, para ver o fim.',
        'Ora, os príncipes dos sacerdotes, e os anciãos, e todo o conselho, buscavam falso testemunho contra Jesus, para poderem dar-lhe a morte;',
        'E não o achavam; apesar de se apresentarem muitas testemunhas falsas, nào o achavam. Mas, por fim chegaram duas testemunhas falsas,',
        'E disseram: Este disse: Eu posso derrubar o templo de Deus, e reedificá-lo em três dias.',
        'E, levantando-se o sumo sacerdote, disse-lhe: Não respondes coisa alguma ao que estes depõem contra ti?',
        'Jesus, porém, guardava silêncio. E, insistindo o sumo sacerdote, disse-lhe: Conjuro-te pelo Deus vivo que nos digas se tu és o Cristo, o Filho de Deus.',
        'Disse-lhe Jesus: Tu o disseste; digo-vos, porém, que vereis em breve o Filho do homem assentado à direita do Poder, e vindo sobre as nuvens do céu.',
        'Então o sumo sacerdote rasgou as suas vestes, dizendo: Blasfemou; para que precisamos ainda de testemunhas? Eis que bem ouvistes agora a sua blasfêmia.',
        'Que vos parece? E eles, respondendo, disseram: É réu de morte.',
        'Então cuspiram-lhe no rosto e lhe davam punhadas, e outros o esbofeteavam,',
        'Dizendo: Profetiza-nos, Cristo, quem é o que te bateu?',
        'Ora, Pedro estava assentado fora, no pátio; e, aproximando-se dele uma criada, disse: Tu também estavas com Jesus, o galileu.',
        'Mas ele negou diante de todos, dizendo: Não sei o que dizes.',
        'E, saindo para o vestíbulo, outra criada o viu, e disse aos que ali estavam: Este também estava com Jesus, o Nazareno.',
        'E ele negou outra vez com juramento: Não conheço tal homem.',
        'E, daí a pouco, aproximando-se os que ali estavam, disseram a Pedro: Verdadeiramente também tu és deles, pois a tua fala te denuncia.',
        'Então começou ele a praguejar e a jurar, dizendo: Não conheço esse homem. E imediatamente o galo cantou.',
        'E lembrou-se Pedro das palavras de Jesus, que lhe dissera: Antes que o galo cante, três vezes me negarás. E, saindo dali, chorou amargamente.',
      ],
      [
        'E, chegando a manhã, todos os príncipes dos sacerdotes, e os anciãos do povo, formavam juntamente conselho contra Jesus, para o matarem;',
        'E maniatando-o, o levaram e entregaram ao presidente Pôncio Pilatos.',
        'Então Judas, o que o traíra, vendo que fora condenado, trouxe, arrependido, as trinta moedas de prata aos príncipes dos sacerdotes e aos anciãos,',
        'Dizendo: Pequei, traindo o sangue inocente. Eles, porém, disseram: Que nos importa? Isso é contigo.',
        'E ele, atirando para o templo as moedas de prata, retirou-se e foi-se enforcar.',
        'E os príncipes dos sacerdotes, tomando as moedas de prata, disseram: Não é lícito colocá-las no cofre das ofertas, porque são preço de sangue.',
        'E, tendo deliberado em conselho, compraram com elas o campo de um oleiro, para sepultura dos estrangeiros.',
        'Por isso foi chamado aquele campo, até ao dia de hoje, Campo de Sangue.',
        'Então se realizou o que vaticinara o profeta Jeremias: Tomaram as trinta moedas de prata, preço do que foi avaliado, que certos filhos de Israel avaliaram,',
        'E deram-nas pelo campo do oleiro, segundo o que o Senhor me determinou.',
        'E foi Jesus apresentado ao presidente, e o presidente o interrogou, dizendo: És tu o Rei dos Judeus? E disse-lhe Jesus: Tu o dizes.',
        'E, sendo acusado pelos príncipes dos sacerdotes e pelos anciãos, nada respondeu.',
        'Disse-lhe então Pilatos: Não ouves quanto testificam contra ti?',
        'E nem uma palavra lhe respondeu, de sorte que o presidente estava muito maravilhado.',
        'Ora, por ocasião da festa, costumava o presidente soltar um preso, escolhendo o povo aquele que quisesse.',
        'E tinham então um preso bem conhecido, chamado Barrabás.',
        'Portanto, estando eles reunidos, disse-lhes Pilatos: Qual quereis que vos solte? Barrabás, ou Jesus, chamado Cristo?',
        'Porque sabia que por inveja o haviam entregado.',
        'E, estando ele assentado no tribunal, sua mulher mandou-lhe dizer: Não entres na questão desse justo, porque num sonho muito sofri por causa dele.',
        'Mas os príncipes dos sacerdotes e os anciãos persuadiram à multidão que pedisse Barrabás e matasse Jesus.',
        'E, respondendo o presidente, disse-lhes: Qual desses dois quereis vós que eu solte? E eles disseram: Barrabás.',
        'Disse-lhes Pilatos: Que farei então de Jesus, chamado Cristo? Disseram-lhe todos: Seja crucificado.',
        'O presidente, porém, disse: Mas que mal fez ele? E eles mais clamavam, dizendo: Seja crucificado.',
        'Então Pilatos, vendo que nada aproveitava, antes o tumulto crescia, tomando água, lavou as mãos diante da multidão, dizendo: Estou inocente do sangue deste justo. Considerai isso.',
        'E, respondendo todo o povo, disse: O seu sangue caia sobre nós e sobre nossos filhos.',
        'Então soltou-lhes Barrabás, e, tendo mandado açoitar a Jesus, entregou-o para ser crucificado.',
        'E logo os soldados do presidente, conduzindo Jesus à audiência, reuniram junto dele toda a coorte.',
        'E, despindo-o, o cobriram com uma capa de escarlate;',
        'E, tecendo uma coroa de espinhos, puseram-lha na cabeça, e em sua mão direita uma cana; e, ajoelhando diante dele, o escarneciam, dizendo: Salve, Rei dos judeus.',
        'E, cuspindo nele, tiraram-lhe a cana, e batiam-lhe com ela na cabeça.',
        'E, depois de o haverem escarnecido, tiraram-lhe a capa, vestiram-lhe as suas vestes e o levaram para ser crucificado.',
        'E, quando saíam, encontraram um homem cireneu, chamado Simão, a quem constrangeram a levar a sua cruz.',
        'E, chegando ao lugar chamado Gólgota, que se diz: Lugar da Caveira,',
        'Deram-lhe a beber vinagre misturado com fel; mas ele, provando-o, não quis beber.',
        'E, havendo-o crucificado, repartiram as suas vestes, lançando sortes, para que se cumprisse o que foi dito pelo profeta: Repartiram entre si as minhas vestes, e sobre a minha túnica lançaram sortes.',
        'E, assentados, o guardavam ali.',
        'E por cima da sua cabeça puseram escrita a sua acusação: este e³ jesus, o rei dos judeus.',
        'E foram crucificados com ele dois salteadores, um à direita, e outro à esquerda.',
        'E os que passavam blasfemavam dele, meneando as cabeças,',
        'E dizendo: Tu, que destróis o templo, e em três dias o reedificas, salva-te a ti mesmo. Se és Filho de Deus, desce da cruz.',
        'E da mesma maneira também os príncipes dos sacerdotes, com os escribas, e anciãos, e fariseus, escarnecendo, diziam:',
        'Salvou os outros, e a si mesmo não pode salvar-se. Se é o Rei de Israel, desça agora da cruz, e creremos nele.',
        'Confiou em Deus; livre-o agora, se o ama; porque disse: Sou Filho de Deus.',
        'E o mesmo lhe lançaram também em rosto os salteadores que com ele estavam crucificados.',
        'E desde a hora sexta houve trevas sobre toda a terra, até à hora nona.',
        'E perto da hora nona exclamou Jesus em alta voz, dizendo: Eli, Eli, lamá sabactâni; isto é, Deus meu, Deus meu, por que me desamparaste?',
        'E alguns dos que ali estavam, ouvindo isto, diziam: Este chama por Elias,',
        'E logo um deles, correndo, tomou uma esponja, e embebeu-a em vinagre, e, pondo-a numa cana, dava-lhe de beber.',
        'Os outros, porém, diziam: Deixa, vejamos se Elias vem livrá-lo.',
        'E Jesus, clamando outra vez com grande voz, rendeu o espírito.',
        'E eis que o véu do templo se rasgou em dois, de alto a baixo; e tremeu a terra, e fenderam-se as pedras;',
        'E abriram-se os sepulcros, e muitos corpos de santos que dormiam foram ressuscitados;',
        'E, saindo dos sepulcros, depois da ressurreição dele, entraram na cidade santa, e apareceram a muitos.',
        'E o centurião e os que com ele guardavam a Jesus, vendo o terremoto, e as coisas que haviam sucedido, tiveram grande temor, e disseram: Verdadeiramente este era o Filho de Deus.',
        'E estavam ali, olhando de longe, muitas mulheres que tinham seguido Jesus desde a Galiléia, para o servir;',
        'Entre as quais estavam Maria Madalena, e Maria, mãe de Tiago e de José, e a mãe dos filhos de Zebedeu.',
        'E, vinda já a tarde, chegou um homem rico, de Arimatéia, por nome José, que também era discípulo de Jesus.',
        'Este foi ter com Pilatos, e pediu-lhe o corpo de Jesus. Então Pilatos mandou que o corpo lhe fosse dado.',
        'E José, tomando o corpo, envolveu-o num fino e limpo lençol,',
        'E o pôs no seu sepulcro novo, que havia aberto em rocha, e, rodando uma grande pedra para a porta do sepulcro, retirou-se.',
        'E estavam ali Maria Madalena e a outra Maria, assentadas defronte do sepulcro.',
        'E no dia seguinte, que é o dia depois da Preparação, reuniram-se os príncipes dos sacerdotes e os fariseus em casa de Pilatos,',
        'Dizendo: Senhor, lembramo-nos de que aquele enganador, vivendo ainda, disse: Depois de três dias ressuscitarei.',
        'Manda, pois, que o sepulcro seja guardado com segurança até ao terceiro dia, não se dê o caso que os seus discípulos vão de noite, e o furtem, e digam ao povo: Ressuscitou dentre os mortos; e assim o último erro será pior do que o primeiro.',
        'E disse-lhes Pilatos: Tendes a guarda; ide, guardai-o como entenderdes.',
        'E, indo eles, seguraram o sepulcro com a guarda, selando a pedra.',
      ],
      [
        'E, no fim do sábado, quando já despontava o primeiro dia da semana, Maria Madalena e a outra Maria foram ver o sepulcro.',
        'E eis que houvera um grande terremoto, porque um anjo do Senhor, descendo do céu, chegou, removendo a pedra da porta, e sentou-se sobre ela.',
        'E o seu aspecto era como um relâmpago, e as suas vestes brancas como neve.',
        'E os guardas, com medo dele, ficaram muito assombrados, e como mortos.',
        'Mas o anjo, respondendo, disse às mulheres: Não tenhais medo; pois eu sei que buscais a Jesus, que foi crucificado.',
        'Ele não está aqui, porque já ressuscitou, como havia dito. Vinde, vede o lugar onde o Senhor jazia.',
        'Ide pois, imediatamente, e dizei aos seus discípulos que já ressuscitou dentre os mortos. E eis que ele vai adiante de vós para a Galiléia; ali o vereis. Eis que eu vo-lo tenho dito.',
        'E, saindo elas pressurosamente do sepulcro, com temor e grande alegria, correram a anunciá-lo aos seus discípulos.',
        'E, indo elas a dar as novas aos seus discípulos, eis que Jesus lhes sai ao encontro, dizendo: Eu vos saúdo. E elas, chegando, abraçaram os seus pés, e o adoraram.',
        'Então Jesus disse-lhes: Não temais; ide dizer a meus irmãos que vão à Galiléia, e lá me verão.',
        'E, quando iam, eis que alguns da guarda, chegando à cidade, anunciaram aos príncipes dos sacerdotes todas as coisas que haviam acontecido.',
        'E, congregados eles com os anciãos, e tomando conselho entre si, deram muito dinheiro aos soldados,',
        'Dizendo: Dizei: Vieram de noite os seus discípulos e, dormindo nós, o furtaram.',
        'E, se isto chegar a ser ouvido pelo presidente, nós o persuadiremos, e vos poremos em segurança.',
        'E eles, recebendo o dinheiro, fizeram como estavam instruídos. E foi divulgado este dito entre os judeus, até ao dia de hoje.',
        'E os onze discípulos partiram para a Galiléia, para o monte que Jesus lhes tinha designado.',
        'E, quando o viram, o adoraram; mas alguns duvidaram.',
        'E, chegando-se Jesus, falou-lhes, dizendo: É-me dado todo o poder no céu e na terra.',
        'Portanto ide, fazei discípulos de todas as nações, batizando-os em nome do Pai, e do Filho, e do Espírito Santo;',
        'Ensinando-os a guardar todas as coisas que eu vos tenho mandado; e eis que eu estou convosco todos os dias, até a consumação dos séculos. Amém.',
      ],
    ],
    livro: 'mateus',
  },
  {
    abbrev: 'mc',
    capitulos: [
      [
        'Princípio do Evangelho de Jesus Cristo, Filho de Deus;',
        'Como está escrito nos profetas: Eis que eu envio o meu anjo ante a tua face, o qual preparará o teu caminho diante de ti.',
        'Voz do que clama no deserto: Preparai o caminho do Senhor, Endireitai as suas veredas.',
        'Apareceu João batizando no deserto, e pregando o batismo de arrependimento, para remissão dos pecados.',
        'E toda a província da Judéia e os de Jerusalém iam ter com ele; e todos eram batizados por ele no rio Jordão, confessando os seus pecados.',
        'E João andava vestido de pêlos de camelo, e com um cinto de couro em redor de seus lombos, e comia gafanhotos e mel silvestre.',
        'E pregava, dizendo: Após mim vem aquele que é mais forte do que eu, do qual não sou digno de, abaixando-me, desatar a correia das suas alparcas.',
        'Eu, em verdade, tenho-vos batizado com água; ele, porém, vos batizará com o Espírito Santo.',
        'E aconteceu naqueles dias que Jesus, tendo ido de Nazaré da Galiléia, foi batizado por João, no Jordão.',
        'E, logo que saiu da água, viu os céus abertos, e o Espírito, que como pomba descia sobre ele.',
        'E ouviu-se uma voz dos céus, que dizia: Tu és o meu Filho amado em quem me comprazo.',
        'E logo o Espírito o impeliu para o deserto.',
        'E ali esteve no deserto quarenta dias, tentado por Satanás. E vivia entre as feras, e os anjos o serviam.',
        'E, depois que João foi entregue à prisão, veio Jesus para a Galiléia, pregando o evangelho do reino de Deus,',
        'E dizendo: O tempo está cumprido, e o reino de Deus está próximo. Arrependei-vos, e crede no evangelho.',
        'E, andando junto do mar da Galiléia, viu Simão, e André, seu irmão, que lançavam a rede ao mar, pois eram pescadores.',
        'E Jesus lhes disse: Vinde após mim, e eu farei que sejais pescadores de homens.',
        'E, deixando logo as suas redes, o seguiram.',
        'E, passando dali um pouco mais adiante, viu Tiago, filho de Zebedeu, e João, seu irmão, que estavam no barco consertando as redes,',
        'E logo os chamou. E eles, deixando o seu pai Zebedeu no barco com os jornaleiros, foram após ele.',
        'Entraram em Cafarnaum e, logo no sábado, indo ele à sinagoga, ali ensinava.',
        'E maravilharam-se da sua doutrina, porque os ensinava como tendo autoridade, e não como os escribas.',
        'E estava na sinagoga deles um homem com um espírito imundo, o qual exclamou,',
        'Dizendo: Ah! que temos contigo, Jesus Nazareno? Vieste destruir-nos? Bem sei quem és: o Santo de Deus.',
        'E repreendeu-o Jesus, dizendo: Cala-te, e sai dele.',
        'Então o espírito imundo, convulsionando-o, e clamando com grande voz, saiu dele.',
        'E todos se admiraram, a ponto de perguntarem entre si, dizendo: Que é isto? Que nova doutrina é esta? Pois com autoridade ordena aos espíritos imundos, e eles lhe obedecem!',
        'E logo correu a sua fama por toda a província da Galiléia.',
        'E logo, saindo da sinagoga, foram à casa de Simão e de André com Tiago e João.',
        'E a sogra de Simão estava deitada com febre; e logo lhe falaram dela.',
        'Então, chegando-se a ela, tomou-a pela mão, e levantou-a; e imediatamente a febre a deixou, e servia-os.',
        'E, tendo chegado a tarde, quando já se estava pondo o sol, trouxeram-lhe todos os que se achavam enfermos, e os endemoninhados.',
        'E toda a cidade se ajuntou à porta.',
        'E curou muitos que se achavam enfermos de diversas enfermidades, e expulsou muitos demônios, porém não deixava falar os demônios, porque o conheciam.',
        'E, levantando-se de manhã, muito cedo, fazendo ainda escuro, saiu, e foi para um lugar deserto, e ali orava.',
        'E seguiram-no Simão e os que com ele estavam.',
        'E, achando-o, lhe disseram: Todos te buscam.',
        'E ele lhes disse: Vamos às aldeias vizinhas, para que eu ali também pregue; porque para isso vim.',
        'E pregava nas sinagogas deles, por toda a Galiléia, e expulsava os demônios.',
        'E aproximou-se dele um leproso que, rogando-lhe, e pondo-se de joelhos diante dele, lhe dizia: Se queres, bem podes limpar-me.',
        'E Jesus, movido de grande compaixão, estendeu a mão, e tocou-o, e disse-lhe: Quero, sê limpo.',
        'E, tendo ele dito isto, logo a lepra desapareceu, e ficou limpo.',
        'E, advertindo-o severamente, logo o despediu.',
        'E disse-lhe: Olha, não digas nada a ninguém; porém vai, mostra-te ao sacerdote, e oferece pela tua purificação o que Moisés determinou, para lhes servir de testemunho.',
        'Mas, tendo ele saído, começou a apregoar muitas coisas, e a divulgar o que acontecera; de sorte que Jesus já não podia entrar publicamente na cidade, mas conservava-se fora em lugares desertos; e de todas as partes iam ter com ele.',
      ],
      [
        'E alguns dias depois entrou outra vez em Cafarnaum, e soube-se que estava em casa.',
        'E logo se ajuntaram tantos, que nem ainda nos lugares junto à porta cabiam; e anunciava-lhes a palavra.',
        'E vieram ter com ele conduzindo um paralítico, trazido por quatro.',
        'E, não podendo aproximar-se dele, por causa da multidão, descobriram o telhado onde estava, e, fazendo um buraco, baixaram o leito em que jazia o paralítico.',
        'E Jesus, vendo a fé deles, disse ao paralítico: Filho, perdoados estão os teus pecados.',
        'E estavam ali assentados alguns dos escribas, que arrazoavam em seus corações, dizendo:',
        'Por que diz este assim blasfêmias? Quem pode perdoar pecados, senão Deus?',
        'E Jesus, conhecendo logo em seu espírito que assim arrazoavam entre si, lhes disse: Por que arrazoais sobre estas coisas em vossos corações?',
        'Qual é mais fácil? dizer ao paralítico: Estão perdoados os teus pecados; ou dizer-lhe: Levanta-te, e toma o teu leito, e anda?',
        'Ora, para que saibais que o Filho do homem tem na terra poder para perdoar pecados (disse ao paralítico),',
        'A ti te digo: Levanta-te, toma o teu leito, e vai para tua casa.',
        'E levantou-se e, tomando logo o leito, saiu em presença de todos, de sorte que todos se admiraram e glorificaram a Deus, dizendo: Nunca tal vimos.',
        'E tornou a sair para o mar, e toda a multidão ia ter com ele, e ele os ensinava.',
        'E, passando, viu Levi, filho de Alfeu, sentado na alfândega, e disse-lhe: Segue-me. E, levantando-se, o seguiu.',
        'E aconteceu que, estando sentado à mesa em casa deste, também estavam sentados à mesa com Jesus e seus discípulos muitos publicanos e pecadores; porque eram muitos, e o tinham seguido.',
        'E os escribas e fariseus, vendo-o comer com os publicanos e pecadores, disseram aos seus discípulos: Por que come e bebe ele com os publicanos e pecadores?',
        'E Jesus, tendo ouvido isto, disse-lhes: Os sãos não necessitam de médico, mas, sim, os que estão doentes; eu não vim chamar os justos, mas, sim, os pecadores ao arrependimento.',
        'Ora, os discípulos de João e os fariseus jejuavam; e foram e disseram-lhe: Por que jejuam os discípulos de João e os dos fariseus, e não jejuam os teus discípulos?',
        'E Jesus disse-lhes: Podem porventura os filhos das bodas jejuar enquanto está com eles o esposo? Enquanto têm consigo o esposo, não podem jejuar;',
        'Mas dias virão em que lhes será tirado o esposo, e então jejuarão naqueles dias.',
        'Ninguém deita remendo de pano novo em roupa velha; doutra sorte o mesmo remendo novo rompe o velho, e a rotura fica maior.',
        'E ninguém deita vinho novo em odres velhos; doutra sorte, o vinho novo rompe os odres e entorna-se o vinho, e os odres estragam-se; o vinho novo deve ser deitado em odres novos.',
        'E aconteceu que, passando ele num sábado pelas searas, os seus discípulos, caminhando, começaram a colher espigas.',
        'E os fariseus lhe disseram: Vês? Por que fazem no sábado o que não é lícito?',
        'Mas ele disse-lhes: Nunca lestes o que fez Davi, quando estava em necessidade e teve fome, ele e os que com ele estavam?',
        'Como entrou na casa de Deus, no tempo de Abiatar, sumo sacerdote, e comeu os pães da proposição, dos quais não era lícito comer senão aos sacerdotes, dando também aos que com ele estavam?',
        'E disse-lhes: O sábado foi feito por causa do homem, e não o homem por causa do sábado.',
        'Assim o Filho do homem até do sábado é Senhor.',
      ],
      [
        'E outra vez entrou na sinagoga, e estava ali um homem que tinha uma das mãos mirrada.',
        'E estavam observando-o securaria no sábado, para o acusarem.',
        'E disse ao homem que tinha a mão mirrada: Levanta-te e vem para o meio.',
        'E perguntou-lhes: É lícito no sábado fazer bem, ou fazer mal? salvar a vida, ou matar? E eles calaram-se.',
        'E, olhando para eles em redor com indignação, condoendo-se da dureza do seu coração, disse ao homem: Estende a tua mão. E ele a estendeu, e foi-lhe restituída a sua mão, sã como a outra.',
        'E, tendo saído os fariseus, tomaram logo conselho com os herodianos contra ele, procurando ver como o matariam.',
        'E retirou-se Jesus com os seus discípulos para o mar, e seguia-o uma grande multidão da Galiléia e da Judéia,',
        'E de Jerusalém, e da Iduméia, e de além do Jordão, e de perto de Tiro e de Sidom; uma grande multidão que, ouvindo quão grandes coisas fazia, vinha ter com ele.',
        'E ele disse aos seus discípulos que lhe tivessem sempre pronto um barquinho junto dele, por causa da multidão, para que o não oprimisse,',
        'Porque tinha curado a muitos, de tal maneira que todos quantos tinham algum mal se arrojavam sobre ele, para lhe tocarem.',
        'E os espíritos imundos vendo-o, prostravam-se diante dele, e clamavam, dizendo: Tu és o Filho de Deus.',
        'E ele os ameaçava muito, para que não o manifestassem.',
        'E subiu ao monte, e chamou para si os que ele quis; e vieram a ele.',
        'E nomeou doze para que estivessem com ele e os mandasse a pregar,',
        'E para que tivessem o poderde curar as enfermidades e expulsar os demônios:',
        'A Simão, a quem pôs o nome de Pedro,',
        'E a Tiago, filho de Zebedeu, e a João, irmão de Tiago, aos quais pôs o nome de Boanerges, que significa: Filhos do trovão;',
        'E a André, e a Filipe, e a Bartolomeu, e a Mateus, e a Tomé, e a Tiago, filho de Alfeu, e a Tadeu, e a Simão, o Cananita,',
        'E a Judas Iscariotes, o que o entregou.',
        'E foram para uma casa. E afluiu outra vez a multidão, de tal maneira que nem sequer podiam comer pão.',
        'E, quando os seus ouviram isto, saíram para o prender; porque diziam: Está fora de si.',
        'E os escribas, que tinham descido de Jerusalém, diziam: Tem Belzebu, e pelo príncipe dos demônios expulsa os demônios.',
        'E, chamando-os a si, disse-lhes por parábolas: Como pode Satanás expulsar Satanás?',
        'E, se um reino se dividir contra si mesmo, tal reino não pode subsistir;',
        'E, se uma casa se dividir contra si mesma, tal casa não pode subsistir.',
        'E, se Satanás se levantar contra si mesmo, e for dividido, não pode subsistir; antes tem fim.',
        'Ninguém pode roubar os bens do valente, entrando-lhe em sua casa, se primeiro não maniatar o valente; e então roubará a sua casa.',
        'Na verdade vos digo que todos os pecados serão perdoados aos filhos dos homens, e toda a sorte de blasfêmias, com que blasfemarem;',
        'Qualquer, porém, que blasfemar contra o Espírito Santo, nunca obterá perdão, mas será réu do eterno juízo',
        '(Porque diziam: Tem espírito imundo).',
        'Chegaram, então, seus irmãos e sua mãe; e, estando fora, mandaram-no chamar.',
        'E a multidão estava assentada ao redor dele, e disseram-lhe: Eis que tua mãe e teus irmãos te procuram, e estão lá fora.',
        'E ele lhes respondeu, dizendo: Quem é minha mãe e meus irmãos?',
        'E, olhando em redor para os que estavam assentados junto dele, disse: Eis aqui minha mãe e meus irmãos.',
        'Porquanto, qualquer que fizer a vontade de Deus, esse é meu irmão, e minha irmã, e minha mãe.',
      ],
      [
        'E outra vez começou a ensinar junto do mar, e ajuntou-se a ele grande multidão, de sorte que ele entrou e assentou-se num barco, sobre o mar; e toda a multidão estava em terra junto do mar.',
        'E ensinava-lhes muitas coisas por parábolas, e lhes dizia na sua doutrina:',
        'Ouvi: Eis que saiu o semeador a semear.',
        'E aconteceu que semeando ele, uma parte da semente caiu junto do caminho, e vieram as aves do céu, e a comeram;',
        'E outra caiu sobre pedregais, onde não havia muita terra, e nasceu logo, porque não tinha terra profunda;',
        'Mas, saindo o sol, queimou-se; e, porque não tinha raiz, secou-se.',
        'E outra caiu entre espinhos e, crescendo os espinhos, a sufocaram e não deu fruto.',
        'E outra caiu em boa terra e deu fruto, que vingou e cresceu; e um produziu trinta, outro sessenta, e outro cem.',
        'E disse-lhes: Quem tem ouvidos para ouvir, ouça.',
        'E, quando se achou só, osque estavam junto dele com os doze interrogaram-no acerca da parábola.',
        'E ele disse-lhes: A vós vos é dado saber os mistérios do reino de Deus, mas aos que estão de fora todas estas coisas se dizem por parábolas,',
        'Para que, vendo, vejam, e não percebam; e, ouvindo, ouçam, e não entendam; para que não se convertam, e lhes sejam perdoados os pecados.',
        'E disse-lhes: Não percebeis esta parábola? Como, pois, entendereis todas as parábolas?',
        'O que semeia, semeia a palavra;',
        'E, os que estão junto do caminho são aqueles em quem a palavra é semeada; mas, tendo-a eles ouvido, vem logo Satanás e tira a palavra que foi semeada nos seus corações.',
        'E da mesma forma os que recebem a semente sobre pedregais; os quais, ouvindo a palavra, logo com prazer a recebem;',
        'Mas não têm raiz em si mesmos, antes são temporãos; depois, sobrevindo tribulação ou perseguição, por causa da palavra, logo se escandalizam.',
        'E outros são os que recebem a semente entre espinhos, os quais ouvem a palavra;',
        'Mas os cuidados deste mundo, e os enganos das riquezas e as ambições de outras coisas, entrando, sufocam a palavra, e fica infrutífera.',
        'E estes são os que foram semeados em boa terra, os que ouvem a palavra e a recebem, e dão fruto, um trinta, e outro sessenta, e outro cem.',
        'E disse-lhes: Vem porventura a candeia para se meter debaixo do alqueire, ou debaixo da cama? não vem antes para se colocar no velador?',
        'Porque nada há encoberto que não haja de ser manifesto; e nada se faz para ficar oculto, mas para ser descoberto.',
        'Se alguém tem ouvidos para ouvir, ouça.',
        'E disse-lhes: Atendei ao que ides ouvir. Com a medida com que medirdes vos medirão a vós, e ser-vos-á ainda acrescentada a vós que ouvis.',
        'Porque ao que tem, ser-lhe-á dado; e, ao que não tem, até o que tem lhe será tirado.',
        'E dizia: O reino de Deus é assim como se um homem lançasse semente à terra.',
        'E dormisse, e se levantasse de noite ou de dia, e a semente brotasse e crescesse, não sabendo ele como.',
        'Porque a terra por si mesma frutifica, primeiro a erva, depois a espiga, por último o grão cheio na espiga.',
        'E, quando já o fruto se mostra, mete-se-lhe logo a foice, porque está chegada a ceifa.',
        'E dizia: A que assemelhare-mos o reino de Deus? ou com que parábola o representaremos?',
        'É como um grão de mostarda, que, quando se semeia na terra, é a menor de todas as sementes que há na terra;',
        'Mas, tendo sido semeado, cresce; e faz-se a maior de todas as hortaliças, e cria grandes ramos, de tal maneira que as aves do céu podem aninhar-se debaixo da sua sombra.',
        'E com muitas parábolas tais lhes dirigia a palavra, segundo o que podiam compreender.',
        'E sem parábolas nunca lhes falava; porém, tudo declarava em particular aos seus discípulos.',
        'E, naquele dia, sendo já tarde, disse-lhes: Passemos para o outro lado.',
        'E eles, deixando a multidão, o levaram consigo, assim como estava, no barco; e havia também com ele outros barquinhos.',
        'E levantou-se grande temporal de vento, e subiam as ondas por cima do barco, de maneira que já se enchia.',
        'E ele estava na popa, dormindo sobre uma almofada, e despertaram-no, dizendo-lhe: Mestre, não se te dá que pereçamos?',
        'E ele, despertando, repreendeu o vento, e disse ao mar: Cala-te, aquieta-te. E o vento se aquietou, e houve grande bonança.',
        'E disse-lhes: Por que sois tão tímidos? Ainda não tendes fé?',
        'E sentiram um grande temor, e diziam uns aos outros: Mas quem é este, que até o vento e o mar lhe obedecem?',
      ],
      [
        'E chegaram ao outro lado do mar, à província dos gadarenos.',
        'E, saindo ele do barco, lhe saiu logo ao seu encontro, dos sepulcros, um homem com espírito imundo;',
        'O qual tinha a sua morada nos sepulcros, e nem ainda com cadeias o podia alguém prender;',
        'Porque, tendo sido muitas vezes preso com grilhões e cadeias, as cadeias foram por ele feitas em pedaços, e os grilhões em migalhas, e ninguém o podia amansar.',
        'E andava sempre, de dia e de noite, clamando pelos montes, e pelos sepulcros, e ferindo-se com pedras.',
        'E, quando viu Jesus ao longe, correu e adorou-o.',
        'E, clamando com grande voz, disse: Que tenho eu contigo, Jesus, Filho do Deus Altíssimo? conjuro-te por Deus que não me atormentes.',
        '(Porque lhe dizia: Sai deste homem, espírito imundo.)',
        'E perguntou-lhe: Qual é o teu nome? E lhe respondeu, dizendo: Legião é o meu nome, porque somos muitos.',
        'E rogava-lhe muito que os não enviasse para fora daquela província.',
        'E andava ali pastando no monte uma grande manada de porcos.',
        'E todos aqueles demônios lhe rogaram, dizendo: Manda-nos para aqueles porcos, para que entremos neles.',
        'E Jesus logo lho permitiu. E, saindo aqueles espíritos imundos, entraram nos porcos; e a manada se precipitou por um despenhadeiro no mar (eram quase dois mil), e afogaram-se no mar.',
        'E os que apascentavam os porcos fugiram, e o anunciaram na cidade e nos campos; e saíram muitos a ver o que era aquilo que tinha acontecido.',
        'E foram ter com Jesus, e viram o endemoninhado, o que tivera a legião, assentado, vestido e em perfeito juízo, e temeram.',
        'E os que aquilo tinham visto contaram-lhes o que acontecera ao endemoninhado, e acerca dos porcos.',
        'E começaram a rogar-lhe que saísse dos seus termos.',
        'E, entrando ele no barco, rogava-lhe o que fora endemoninhado que o deixasse estar com ele.',
        'Jesus, porém, não lho permitiu, mas disse-lhe: Vai para tua casa, para os teus, e anuncia-lhes quão grandes coisas o Senhor te fez, e como teve misericórdia de ti.',
        'E ele foi, e começou a anunciar em Decápolis quão grandes coisas Jesus lhe fizera; e todos se maravilharam.',
        'E, passando Jesus outra vez num barco para o outro lado, ajuntou-se a ele uma grande multidão; e ele estava junto do mar.',
        'E eis que chegou um dos principais da sinagoga, por nome Jairo, e, vendo-o, prostrou-se aos seus pés,',
        'E rogava-lhe muito, dizendo: Minha filha está à morte; rogo-te que venhas e lhe imponhas as mãos, para que sare, e viva.',
        'E foi com ele, e seguia-o uma grande multidão, que o apertava.',
        'E certa mulher que, havia doze anos, tinha um fluxo de sangue,',
        'E que havia padecido muito com muitos médicos, e despendido tudo quanto tinha, nada lhe aproveitando isso, antes indo a pior;',
        'Ouvindo falar de Jesus, veio por detrás, entre a multidão, e tocou na sua veste.',
        'Porque dizia: Se tão-somente tocar nas suas vestes, sararei.',
        'E logo se lhe secou a fonte do seu sangue; e sentiu no seu corpo estar já curada daquele mal.',
        'E logo Jesus, conhecendo que a virtude de si mesmo saíra, voltou-se para a multidão, e disse: Quem tocou nas minhas vestes?',
        'E disseram-lhe os seus discípulos: Vês que a multidão te aperta, e dizes: Quem me tocou?',
        'E ele olhava em redor, para ver a que isto fizera.',
        'Então a mulher, que sabia o que lhe tinha acontecido, temendo e tremendo, aproximou-se, e prostrou-se diante dele, e disse-lhe toda a verdade.',
        'E ele lhe disse: Filha, a tua fé te salvou; vai em paz, e sê curada deste teu mal.',
        'Estando ele ainda falando, chegaram alguns do principal da sinagoga, a quem disseram: A tuafilha está morta; para que enfadas mais o Mestre?',
        'E Jesus, tendo ouvido estas palavras, disse ao principal da sinagoga: Não temas, crê somente.',
        'E não permitiu que alguém o seguisse, a não ser Pedro, Tiago, e João, irmão de Tiago.',
        'E, tendo chegado à casa do principal da sinagoga, viu o alvoroço, e os que choravam muito e pranteavam.',
        'E, entrando, disse-lhes: Por que vos alvoroçais e chorais? A menina não está morta, mas dorme.',
        'E riam-se dele; porém ele, tendo-os feito sair, tomou consigo o pai e a mãe da menina, e os que com ele estavam, e entrou onde a menina estava deitada.',
        'E, tomando a mão da menina, disse-lhe: Talita cumi; que, traduzido, é: Menina, a ti te digo, levanta-te.',
        'E logo a menina se levantou, e andava, pois já tinha doze anos; e assombraram-se com grande espanto.',
        'E mandou-lhes expressamente que ninguém o soubesse; e disse que lhe dessem de comer.',
      ],
      [
        'E, partindo dali, chegou à sua pátria, e os seus discípulos o seguiram.',
        'E, chegando o sábado, começou a ensinar na sinagoga; e muitos, ouvindo-o, se admiravam, dizendo: De onde lhe vêm estas coisas? e que sabedoria é esta que lhe foi dada? e como se fazem tais maravilhas por suas mãos?',
        'Não é este o carpinteiro, filho de Maria, e irmão de Tiago, e de José, e de Judas e de Simão? e não estão aqui conosco suas irmãs? E escandalizavam-se nele.',
        'E Jesus lhes dizia: Não há profeta sem honra senão na suapátria, entre os seus parentes, e na sua casa.',
        'E não podia fazer ali nenhuma obra maravilhosa; somente curou alguns poucos enfermos, impondo-lhes as mãos.',
        'E estava admirado da incredulidade deles. E percorreu as aldeias vizinhas, ensinando.',
        'Chamou a si os doze, e começou a enviá-los a dois e dois, e deu-lhes poder sobre os espíritos imundos;',
        'E ordenou-lhes que nada tomassem para o caminho, senão somente um bordão; nem alforje, nem pão, nem dinheiro no cinto;',
        'Mas que calçassem alparcas, e que não vestissem duas túnicas.',
        'E dizia-lhes: Na casa em que entrardes, ficai nela até partirdes dali.',
        'E tantos quantos vos não receberem, nem vos ouvirem, saindo dali, sacudi o pó que estiver debaixo dos vossos pés, em testemunho contra eles. Em verdade vos digo que haverá mais tolerância no dia de juízo para Sodoma e Gomorra, do que para os daquela cidade.',
        'E, saindo eles, pregavam que se arrependessem.',
        'E expulsavam muitos demônios, e ungiam muitos enfermos com óleo, e os curavam.',
        'E ouviu isto o rei Herodes (porque o nome de Jesus se tornara notório), e disse: João, o que batizava, ressuscitou dentre os mortos, e por isso estas maravilhas operam nele.',
        'Outros diziam: É Elias. E diziam outros: É um profeta, ou como um dos profetas.',
        'Herodes, porém, ouvindo isto, disse: Este é João, que mandei degolar; ressuscitou dentre os mortos.',
        'Porquanto o mesmo Herodes mandara prender a João, e encerrá-lo maniatado no cárcere, por causa de Herodias, mulher de Filipe, seu irmão, porquanto tinha casado com ela.',
        'Pois João dizia a Herodes: Não te é lícito possuir a mulher de teu irmão.',
        'E Herodias o espiava, e queria matá-lo, mas não podia.',
        'Porque Herodes temia a João, sabendo que era homem justo e santo; e guardava-o com segurança, e fazia muitas coisas, atendendo-o, e de boa mente o ouvia.',
        'E, chegando uma ocasião favorável em que Herodes, no dia dos seus anos, dava uma ceia aos grandes, e tribunos, e príncipes da Galiléia,',
        'Entrou a filha da mesma Herodias, e dançou, e agradou a Herodes e aos que estavam com ele à mesa. Disse então o rei à menina: Pede-me o que quiseres, e eu to darei.',
        'E jurou-lhe, dizendo: Tudo o que me pedires te darei, até metade do meu reino.',
        'E, saindo ela, perguntou a sua mãe: Que pedirei? E ela disse: A cabeça de João o Batista.',
        'E, entrando logo, apressadamente, pediu ao rei, dizendo: Quero que imediatamente me dês num prato a cabeça de João o Batista.',
        'E o rei entristeceu-se muito; todavia, por causa do juramento e dos que estavam com ele à mesa, não lha quis negar.',
        'E, enviando logo o rei o executor, mandou que lhe trouxessem ali a cabeça de João. E ele foi, e degolou-o na prisão;',
        'E trouxe a cabeça num prato, e deu-a à menina, e a menina a deu a sua mãe.',
        'E os seus discípulos, tendo ouvido isto, foram, tomaram o seu corpo, e o puseram num sepulcro.',
        'E os apóstolos ajuntaram-se a Jesus, e contaram-lhe tudo, tanto o que tinham feito como o que tinham ensinado.',
        'E ele disse-lhes: Vinde vós, aqui à parte, a um lugar deserto, e repousai um pouco. Porque havia muitos que iam e vinham, e não tinham tempo para comer.',
        'E foram sós num barco para um lugar deserto.',
        'E a multidão viu-os partir, e muitos o conheceram; e correram para lá, a pé, de todas as cidades, e ali chegaram primeiro do que eles, e aproximavam-se dele.',
        'E Jesus, saindo, viu uma grande multidão, e teve compaixão deles, porque eram como ovelhas que não têm pastor; e começou a ensinar-lhes muitas coisas.',
        'E, como o dia fosse já muito adiantado, os seus discípulos se aproximaram dele, e lhe disseram: O lugar é deserto, e o dia está já muito adiantado.',
        'Despede-os, para que vão aos lugares e aldeias circunvizinhas, e comprem pão para si; porque não têm que comer.',
        'Ele, porém, respondendo, lhes disse: Dai-lhes vós de comer. E eles disseram-lhe: Iremos nós, e compraremos duzentos dinheiros de pão para lhes darmos de comer?',
        'E ele disse-lhes: Quantos pães tendes? Ide ver. E, sabendo-o eles, disseram: Cinco pães e dois peixes.',
        'E ordenou-lhes que fizessem assentar a todos, em ranchos, sobre a erva verde.',
        'E assentaram-se repartidos de cem em cem, e de cinqüenta em cinqüenta.',
        'E, tomando ele os cinco pães e os dois peixes, levantou os olhos ao céu, abençoou e partiu os pães, e deu-os aos seus discípulos para que os pusessem diante deles. E repartiu os dois peixes por todos.',
        'E todos comeram, e ficaram fartos;',
        'E levantaram doze alcofas cheias de pedaços de pão e de peixe.',
        'E os que comeram os pães eram quase cinco mil homens.',
        'E logo obrigou os seus discípulos a subir para o barco, e passar adiante, para o outro lado, a Betsaida, enquanto ele despedia a multidão.',
        'E, tendo-os despedido, foi ao monte a orar.',
        'E, sobrevindo a tarde, estava o barco no meio do mar e ele, sozinho, em terra.',
        'E vendo que se fatigavam a remar, porque o vento lhes era contrário, perto da quarta vigília da noite aproximou-se deles, andando sobre o mar, e queria passar-lhes adiante.',
        'Mas, quando eles o viram andar sobre o mar, cuidaram que era um fantasma, e deram grandes gritos.',
        'Porque todos o viam, e perturbaram-se; mas logo falou com eles, e disse-lhes: Tende bom ânimo; sou eu, não temais.',
        'E subiu para o barco, para estar com eles, e o vento se aquietou; e entre si ficaram muito assombrados e maravilhados;',
        'Pois não tinham compreendido o milagre dos pães; antes o seu coração estava endurecido.',
        'E, quando já estavam no outro lado, dirigiram-se à terra de Genesaré, e ali atracaram.',
        'E, saindo eles do barco, logo o conheceram;',
        'E, correndo toda a terra em redor, começaram a trazer em leitos, aonde quer que sabiam que ele estava, os que se achavam enfermos.',
        'E, onde quer que entrava, ou em cidade, ou aldeias, ou no campo, apresentavam os enfermos nas praças, e rogavam-lhe que os deixasse tocar ao menos na orla da sua roupa; e todos os que lhe tocavam saravam.',
      ],
      [
        'E ajuntaram-se a ele os fariseus, e alguns dos escribas que tinham vindo de Jerusalém.',
        'E, vendo que alguns dos seus discípulos comiam pão com as mãos impuras, isto é, por lavar, os repreendiam.',
        'Porque os fariseus, e todos os judeus, conservando a tradição dos antigos, não comem sem lavar as mãos muitas vezes;',
        'E, quando voltam do mercado, se não se lavarem, não comem. E muitas outras coisas há que receberam para observar, como lavar os copos, e os jarros, e os vasos de metal e as camas.',
        'Depois perguntaram-lhe os fariseus e os escribas: Por que não andam os teus discípulos conforme a tradição dos antigos, mas comem o pão com as mãos por lavar?',
        'E ele, respondendo, disse-lhes: Bem profetizou Isaías acerca de vós, hipócritas, como está escrito:Este povo honra-me com os lábios,Mas o seu coração está longe de mim;',
        'Em vão, porém, me honram,Ensinando doutrinas que são mandamentos de homens.',
        'Porque, deixando o mandamento de Deus, retendes a tradição dos homens; como o lavar dos jarros e dos copos; e fazeis muitas outras coisas semelhantes a estas.',
        'E dizia-lhes: Bem invalidais o mandamento de Deus para guardardes a vossa tradição.',
        'Porque Moisés disse: Honra a teu pai e a tua mãe; e quem maldisser, ou o pai ou a mãe, certamente morrerá.',
        'Vós, porém, dizeis: Se um homem disser ao pai ou à mãe: Aquilo que poderias aproveitar de mim é Corbã, isto é, oferta ao Senhor;',
        'Nada mais lhe deixais fazer por seu pai ou por sua mãe,',
        'Invalidando assim a palavra de Deus pela vossa tradição, que vós ordenastes. E muitas coisas fazeis semelhantes a estas.',
        'E, chamando outra vez a multidão, disse-lhes: Ouvi-me vós, todos, e compreendei.',
        'Nada há, fora do homem, que, entrando nele, o possa contaminar; mas o que sai dele isso é que contamina o homem.',
        'Se alguém tem ouvidos para ouvir, ouça.',
        'Depois, quando deixou a multidão, e entrou em casa, os seus discípulos o interrogavam acerca desta parábola.',
        'E ele disse-lhes: Assim também vós estais sem entendimento? Não compreendeis que tudo o que de fora entra no homem não o pode contaminar,',
        'Porque não entra no seu coração, mas no ventre, e é lançado fora, ficando puras todas as comidas?',
        'E dizia: O que sai do homem isso contamina o homem.',
        'Porque do interior do coração dos homens saem os maus pensamentos, os adultérios, as fornicações, os homicídios,',
        'Os furtos, a avareza, as maldades, o engano, a dissolução, a inveja, a blasfêmia, a soberba, a loucura.',
        'Todos estes males procedem de dentro e contaminam o homem.',
        'E, levantando-se dali, foi para os termos de Tiro e de Sidom. E, entrando numa casa, não queria que alguém o soubesse, mas não pôde esconder-se;',
        'Porque uma mulher, cuja filha tinha um espírito imundo, ouvindo falar dele, foi e lançou-se aos seus pés.',
        'E esta mulher era grega, siro-fenícia de nação, e rogava-lhe que expulsasse de sua filha o demônio.',
        'Mas Jesus disse-lhe: Deixa primeiro saciar os filhos; porque não convém tomar o pão dos filhos e lançá-lo aos cachorrinhos.',
        'Ela, porém, respondeu, e disse-lhe: Sim, Senhor; mas também os cachorrinhos comem, debaixo da mesa, as migalhas dos filhos.',
        'Então ele disse-lhe: Por essa palavra, vai; o demônio já saiu de tua filha.',
        'E, indo ela para sua casa, achou a filha deitada sobre a cama, e que o demônio já tinha saído.',
        'E ele, tornando a sair dos termos de Tiro e de Sidom, foi até ao mar da Galiléia, pelos confins de Decápolis.',
        'E trouxeram-lhe um surdo, que falava dificilmente; e rogaram-lhe que pusesse a mão sobre ele.',
        'E, tirando-o à parte, de entre a multidão, pôs-lhe os dedos nos ouvidos; e, cuspindo, tocou-lhe na língua.',
        'E, levantando os olhos ao céu, suspirou, e disse: Efatá; isto é, Abre-te.',
        'E logo se abriram os seus ouvidos, e a prisão da língua se desfez, e falava perfeitamente.',
        'E ordenou-lhes que a ninguém o dissessem; mas, quanto mais lhos proibia, tanto mais o divulgavam.',
        'E, admirando-se sobremaneira, diziam: Tudo faz bem; faz ouvir os surdos e falar os mudos.',
      ],
      [
        'Naqueles dias, havendo uma grande multidão, e não tendo o que comer, Jesus chamou a si os seus discípulos, e disse-lhes:',
        'Tenho compaixão da multidão, porque há já três dias que estão comigo, e não têm o que comer.',
        'E, se os deixar ir em jejum, para suas casas, desfalecerão no caminho, porque alguns deles vieram de longe.',
        'E os seus discípulos responderam-lhe: De onde poderá alguém satisfazê-los de pão aqui no deserto?',
        'E perguntou-lhes: Quantos pães tendes? E disseram-lhe: Sete.',
        'E ordenou à multidão que se assentasse no chão. E, tomando os sete pães, e tendo dado graças, partiu-os, e deu-os aos seus discípulos, para que os pusessem diante deles, e puseram-nos diante da multidão.',
        'Tinham também alguns peixinhos; e, tendo dado graças, ordenou que também lhos pusessem diante.',
        'E comeram, e saciaram-se; e dos pedaços que sobejaram levantaram sete cestos.',
        'E os que comeram eram quase quatro mil; e despediu-os.',
        'E, entrando logo no barco, com os seus discípulos, foi para as partes de Dalmanuta.',
        'E saíram os fariseus, e começaram a disputar com ele, pedindolhe, para o tentarem, um sinal do céu.',
        'E, suspirando profundamente em seu espírito, disse: Por que pede esta geração um sinal? Em verdade vos digo que a esta geração não se dará sinal algum.',
        'E, deixando-os, tornou a entrar no barco, e foi para o outro lado.',
        'E eles se esqueceram de levar pão e, no barco, não tinham consigo senão um pão.',
        'E ordenou-lhes, dizendo: Olhai, guardai-vos do fermento dos fariseus e do fermento de Herodes.',
        'E arrazoavam entre si, dizendo: É porque não temos pão.',
        'E Jesus, conhecendo isto, disse-lhes: Para que arrazoais, que não tendes pão? não considerastes, nem compreendestes ainda? tendes ainda o vosso coração endurecido?',
        'Tendo olhos, não vedes? e tendo ouvidos, não ouvis? e não vos lembrais,',
        'Quando parti os cinco pães entre os cinco mil, quantas alcofas cheias de pedaços levantastes? Disseram-lhe: Doze.',
        'E, quando parti os sete entre os quatro mil, quantos cestos cheios de pedaços levantastes? E disseram-lhe: Sete.',
        'E ele lhes disse: Como não entendeis ainda?',
        'E chegou a Betsaida; e trouxeram-lhe um cego, e rogaram-lhe que o tocasse.',
        'E, tomando o cego pela mão, levou-o para fora da aldeia; e, cuspindo-lhe nos olhos, e impondo-lhe as mãos, perguntou-lhe se via alguma coisa.',
        'E, levantando ele os olhos, disse: Vejo os homens; pois os vejo como árvores que andam.',
        'Depois disto, tornou a pôr-lhe as mãos sobre os olhos, e o fez olhar para cima: e ele ficou restaurado, e viu a todos claramente.',
        'E mandou-o para sua casa, dizendo: Nem entres na aldeia, nem o digas a ninguém na aldeia.',
        'E saiu Jesus, e os seus discípulos, para as aldeias de Cesaréia de Filipe; e no caminho perguntou aos seus discípulos, dizendo: Quem dizem os homens que eu sou?',
        'E eles responderam: João o Batista; e outros: Elias; mas outros: Um dos profetas.',
        'E ele lhes disse: Mas vós, quem dizeis que eu sou? E, respondendo Pedro, lhe disse: Tu és o Cristo.',
        'E admoestou-os, para que a ninguém dissessem aquilo dele.',
        'E começou a ensinar-lhes que importava que o Filho do homem padecesse muito, e que fosse rejeitado pelos anciãos e príncipes dos sacerdotes, e pelos escribas, e que fosse morto, mas que depois de três dias ressuscitaria.',
        'E dizia abertamente estas palavras. E Pedro o tomou à parte, e começou a repreendê-lo.',
        'Mas ele, virando-se, e olhando para os seus discípulos, repreendeu a Pedro, dizendo: Retira-te de diante de mim, Satanás; porque não compreendes as coisas que são de Deus, mas as que são dos homens.',
        'E chamando a si a multidão, com os seus discípulos, disse-lhes: Se alguém quiser vir após mim, negue-se a si mesmo, e tome a sua cruz, e siga-me.',
        'Porque qualquer que quiser salvar a sua vida, perdê-la-á, mas, qualquer que perder a sua vida por amor de mim e do evangelho, esse a salvará.',
        'Pois, que aproveitaria ao homem ganhar todo o mundo e perder a sua alma?',
        'Ou, que daria o homem pelo resgate da sua alma?',
        'Porquanto, qualquer que, entre esta geração adúltera e pecadora, se envergonhar de mim e das minhas palavras, também o Filho do homem se envergonhará dele, quando vier na glória de seu Pai, com os santos anjos.',
      ],
      [
        'Dizia-lhes também: Em verdade vos digo que, dos que aqui estão, alguns há que não provarão a morte sem que vejam chegado o reino de Deus com poder.',
        'E seis dias depois Jesus tomou consigo a Pedro, a Tiago, e a João, e os levou sós, em particular, a um alto monte; e transfigurou-se diante deles;',
        'E as suas vestes tornaram- se resplandecentes, extremamente brancas como a neve, tais como nenhum lavadeiro sobre a terra as poderia branquear.',
        'E apareceu-lhes Elias, com Moisés, e falavam com Jesus.',
        'E Pedro, tomando a palavra, disse a Jesus: Mestre, é bom que estejamos aqui; e façamos três cabanas, uma para ti, outra para Moisés, e outra para Elias.',
        'Pois não sabia o que dizia, porque estavam assombrados.',
        'E desceu uma nuvem que os cobriu com a sua sombra, e saiu da nuvem uma voz que dizia: Este é o meu filho amado; a ele ouvi.',
        'E, tendo olhado em redor, ninguém mais viram, senão só Jesus com eles.',
        'E, descendo eles do monte, ordenou-lhes que a ninguém contassem o que tinham visto, até que o Filho do homem ressuscitasse dentre os mortos.',
        'E eles retiveram o caso entre si, perguntando uns aos outros que seria aquilo, ressuscitar dentre os mortos.',
        'E interrogaram-no, dizendo: Por que dizem os escribas que é necessário que Elias venha primeiro?',
        'E, respondendo ele, disse-lhes: Em verdade Elias virá primeiro, e todas as coisas restaurará; e, como está escrito do Filho do homem, que ele deva padecer muito e ser aviltado.',
        'Digo-vos, porém, que Elias já veio, e fizeram-lhe tudo o que quiseram, como dele está escrito.',
        'E, quando se aproximou dos discípulos, viu ao redor deles grande multidão, e alguns escribas que disputavam com eles.',
        'E logo toda a multidão, vendo-o, ficou espantada e, correndo para ele, o saudaram.',
        'E perguntou aos escribas: Que é que discutis com eles?',
        'E um da multidão, respondendo, disse: Mestre, trouxe-te o meu filho, que tem um espírito mudo;',
        'E este, onde quer que o apanhe, despedaça-o, e ele espuma, e range os dentes, e vai definhando; e eu disse aos teus discípulos que o expulsassem, e não puderam.',
        'E ele, respondendo-lhes, disse: Ó geração incrédula! até quando estarei convosco? até quando vos sofrerei ainda? Trazei-mo.',
        'E trouxeram-lho; e quando ele o viu, logo o espírito o agitou com violência, e, caindo o endemoninhado por terra, revolvia-se, escumando.',
        'E perguntou ao pai dele: Quanto tempo há que lhe sucede isto? E ele disse-lhe: Desde a infância.',
        'E muitas vezes o tem lançado no fogo, e na água, para o destruir; mas, se tu podes fazer alguma coisa, tem compaixão de nós, e ajuda-nos.',
        'E Jesus disse-lhe: Se tu podes crer, tudo é possível ao que crê.',
        'E logo o pai do menino, clamando, com lágrimas, disse: Eu creio, Senhor! ajuda a minha incredulidade.',
        'E Jesus, vendo que a multidão concorria, repreendeu o espírito imundo, dizendo-lhe: Espírito mudo e surdo, eu te ordeno: Sai dele, e não entres mais nele.',
        'E ele, clamando, e agitando-o com violência, saiu; e ficou o menino como morto, de tal maneira que muitos diziam que estava morto.',
        'Mas Jesus, tomando-o pela mão, o ergueu, e ele se levantou.',
        'E, quando entrou em casa, os seus discípulos lhe perguntaram à parte: Por que o não pudemos nós expulsar?',
        'E disse-lhes: Esta casta não pode sair com coisa alguma, a não ser com oração e jejum.',
        'E, tendo partido dali, caminharam pela Galiléia, e não queria que alguém o soubesse;',
        'Porque ensinava os seus discípulos, e lhes dizia: O Filho do homem será entregue nas mãos dos homens, e matá-lo-ão; e, morto ele, ressuscitará ao terceiro dia.',
        'Mas eles não entendiam esta palavra, e receavam interrogá-lo.',
        'E chegou a Cafarnaum e, entrando em casa, perguntou-lhes: Que estáveis vós discutindo pelo caminho?',
        'Mas eles calaram-se; porque pelo caminho tinham disputado entre si qual era o maior.',
        'E ele, assentando-se, chamou os doze, e disse-lhes: Se alguém quiser ser o primeiro, será o derradeiro de todos e o servo de todos.',
        'E, lançando mão de um menino, pô-lo no meio deles e, tomando-o nos seus braços, disse-lhes:',
        'Qualquer que receber um destes meninos em meu nome, a mim me recebe; e qualquer que a mim me receber, recebe, não a mim, mas ao que me enviou.',
        'E João lhe respondeu, dizendo: Mestre, vimos um que em teu nome expulsava demônios, o qual não nos segue; e nós lho proibimos, porque não nos segue.',
        'Jesus, porém, disse: Não lho proibais; porque ninguém há que faça milagre em meu nome e possa logo falar mal de mim.',
        'Porque quem não é contra nós, é por nós.',
        'Porquanto, qualquer que vos der a beber um copo de água em meu nome, porque sois discípulos de Cristo, em verdade vos digo que não perderá o seu galardão.',
        'E qualquer que escandalizar um destes pequeninos que crêem em mim, melhor lhe fora que lhe pusessem ao pescoço uma mó de atafona, e que fosse lançado no mar.',
        'E, se a tua mão te escandalizar, corta-a; melhor é para ti entrares na vida aleijado do que, tendo duas mãos, ires para o inferno, para o fogo que nunca se apaga,',
        'Onde o seu bicho não morre, e o fogo nunca se apaga.',
        'E, se o teu pé te escandalizar, corta-o; melhor é para ti entrares coxo na vida do que, tendo dois pés, seres lançado no inferno, no fogo que nunca se apaga,',
        'Onde o seu bicho não morre, e o fogo nunca se apaga.',
        'E, se o teu olho te escandalizar, lança-o fora; melhor é para ti entrares no reino de Deus com um só olho do que, tendo dois olhos, seres lançado no fogo do inferno,',
        'Onde o seu bicho não morre, e o fogo nunca se apaga.',
        'Porque cada um será salgado com fogo, e cada sacrifício será salgado com sal.',
        'Bom é o sal; mas, se o sal se tornar insípido, com que o temperareis? Tende sal em vós mesmos, e paz uns com os outros.',
      ],
      [
        'E, levantando-se dali, foi para os termos da Judéia, além do Jordão, e a multidão se reuniu em torno dele; e tornou a ensiná-los, como tinha por costume.',
        'E, aproximando-se dele os fariseus, perguntaram-lhe, tentando-o: É lícito ao homem repudiar sua mulher?',
        'Mas ele, respondendo, disse-lhes: Que vos mandou Moisés?',
        'E eles disseram: Moisés permitiu escrever carta de divórcio e repudiar.',
        'E Jesus, respondendo, disse-lhes: Pela dureza dos vossos corações vos deixou ele escrito esse mandamento;',
        'Porém, desde o princípio da criação, Deus os fez macho e fêmea.',
        'Por isso deixará o homem a seu pai e a sua mãe, e unir-se-á a sua mulher,',
        'E serão os dois uma só carne; e assim já não serão dois, mas uma só carne.',
        'Portanto, o que Deus ajuntou não o separe o homem.',
        'E em casa tornaram os discípulos a interrogá-lo acerca disto mesmo.',
        'E ele lhes disse: Qualquer que deixar a sua mulher e casar com outra, adultera contra ela.',
        'E, se a mulher deixar a seu marido, e casar com outro, adultera.',
        'E traziam-lhe meninos para que lhes tocasse, mas os discípulos repreendiam aos que lhos traziam.',
        'Jesus, porém, vendo isto, indignou-se, e disse-lhes: Deixai vir os meninos a mim, e não os impeçais; porque dos tais é o reino de Deus.',
        'Em verdade vos digo que qualquer que não receber o reino de Deus como menino, de maneira nenhuma entrará nele.',
        'E, tomando-os nos seus braços, e impondo-lhes as mãos, os abençoou.',
        'E, pondo-se a caminho, correu para ele um homem, o qual se ajoelhou diante dele, e lhe perguntou: Bom Mestre, que farei para herdar a vida eterna?',
        'E Jesus lhe disse: Por que me chamas bom? Ninguém há bom senão um, que é Deus.',
        'Tu sabes os mandamentos: Não adulterarás; não matarás; não furtarás; não dirás falso testemunho; não defraudarás alguém; honra a teu pai e a tua mãe.',
        'Ele, porém, respondendo, lhe disse: Mestre, tudo isso guardei desde a minha mocidade.',
        'E Jesus, olhando para ele, o amou e lhe disse: Falta-te uma coisa: vai, vende tudo quanto tens, e dá-o aos pobres, e terás um tesouro no céu; e vem, toma a cruz, e segue-me.',
        'Mas ele, pesaroso desta palavra, retirou-se triste; porque possuía muitas propriedades.',
        'Então Jesus, olhando em redor, disse aos seus discípulos: Quão dificilmente entrarão no reino de Deus os que têm riquezas!',
        'E os discípulos se admiraram destas suas palavras; mas Jesus, tornando a falar, disse-lhes: Filhos, quão difícil é, para os que confiam nas riquezas, entrar no reino de Deus!',
        'É mais fácil passar um camelo pelo fundo de uma agulha, do que entrar um rico no reino de Deus.',
        'E eles se admiravam ainda mais, dizendo entre si: Quem poderá, pois, salvar-se?',
        'Jesus, porém, olhando para eles, disse: Para os homens é impossível, mas não para Deus, porque para Deus todas as coisas são possíveis.',
        'E Pedro começou a dizer-lhe: Eis que nós tudo deixamos, e te seguimos.',
        'E Jesus, respondendo, disse: Em verdade vos digo que ninguém há, que tenha deixado casa, ou irmãos, ou irmãs, ou pai, ou mãe, ou mulher, ou filhos, ou campos, por amor de mim e do evangelho,',
        'Que não receba cem vezes tanto, já neste tempo, em casas, e irmãos, e irmãs, e mães, e filhos, e campos, com perseguições; e no século futuro a vida eterna.',
        'Porém muitos primeiros serão derradeiros, e muitos derradeiros serão primeiros.',
        'E iam no caminho, subindo para Jerusalém; e Jesus ia adiante deles. E eles maravilhavam-se, e seguiam-no atemorizados.  E, tornando a tomar consigo os doze, começou a dizer-lhes as coisas que lhe deviam sobrevir,',
        'Dizendo: Eis que nós subimos a Jerusalém, e o Filho do homem será entregue aos príncipes dos sacerdotes, e aos escribas, e o condenarão à morte, e o entregarão aos gentios.',
        'E o escarnecerão, e açoitarão, e cuspirão nele, e o matarão; e, ao terceiro dia, ressuscitará.',
        'E aproximaram-se dele Tiago e João, filhos de Zebedeu, dizendo: Mestre, queremos que nos faças o que te pedirmos.',
        'E ele lhes disse: Que quereis que vos faça?',
        'E eles lhe disseram: Concede-nos que na tua glória nos assentemos, um à tua direita, e outro à tua esquerda.',
        'Mas Jesus lhes disse: Não sabeis o que pedis; podeis vós beber o cálice que eu bebo, e ser batizados com o batismo com que eu sou batizado?',
        'E eles lhe disseram: Podemos. Jesus, porém, disse-lhes: Em verdade, vós bebereis o cálice que eu beber, e sereis batizados com o batismo com que eu sou batizado;',
        'Mas, o assentar-se à minha direita, ou à minha esquerda, não me pertence a mim concedê-lo, mas isso é para aqueles a quem está reservado.',
        'E os dez, tendo ouvido isto, começaram a indignar-se contra Tiago e João.',
        'Mas Jesus, chamando-os a si, disse-lhes: Sabeis que os que julgam ser príncipes dos gentios, deles se assenhoreiam, e os seus grandes usam de autoridade sobre eles;',
        'Mas entre vós não será assim; antes, qualquer que entre vós quiser ser grande, será vosso serviçal;',
        'E qualquer que dentre vós quiser ser o primeiro, será servo de todos.',
        'Porque o Filho do homem também não veio para ser servido, mas para servir e dar a sua vida em resgate de muitos.',
        'E depois, foram para Jericó. E, saindo ele de Jericó com seus discípulos e uma grande multidão, Bartimeu, o cego, filho de Timeu, estava assentado junto do caminho, mendigando.',
        'E, ouvindo que era Jesus de Nazaré, começou a clamar, e a dizer: Jesus, filho de Davi, tem misericórdia de mim.',
        'E muitos o repreendiam, para que se calasse; mas ele clamava cada vez mais: Filho de Davi! tem misericórdia de mim.',
        'E Jesus, parando, disse que o chamassem; e chamaram o cego, dizendo-lhe: Tem bom ânimo; levanta-te, que ele te chama.',
        'E ele, lançando de si a sua capa, levantou-se, e foi ter com Jesus.',
        'E Jesus, falando, disse-lhe: Que queres que te faça? E o cego lhe disse: Mestre, que eu tenha vista.',
        'E Jesus lhe disse: Vai, a tua fé te salvou. E logo viu, e seguiu a Jesus pelo caminho.',
      ],
      [
        'E, logo que se aproximaram de Jerusalém, de Betfagé e de Betânia, junto do Monte das Oliveiras, enviou dois dos seus discípulos,',
        'E disse-lhes: Ide à aldeia que está defronte de vós; e, logo que ali entrardes, encontrareis preso um jumentinho, sobre o qual ainda não montou homem algum; soltai-o, e trazei-mo.',
        'E, se alguém vos disser: Por que fazeis isso? dizei-lhe que o Senhor precisa dele, e logo o deixará trazer para aqui.',
        'E foram, e encontraram o jumentinho preso fora da porta, entre dois caminhos, e o soltaram.',
        'E alguns dos que ali estavam lhes disseram: Que fazeis, soltando o jumentinho?',
        'Eles, porém, disseram-lhes como Jesus lhes tinha mandado; e deixaram-nos ir.',
        'E levaram o jumentinho a Jesus, e lançaram sobre ele as suas vestes, e assentou-se sobre ele.',
        'E muitos estendiam as suas vestes pelo caminho, e outros cortavam ramos das árvores, e os espalhavam pelo caminho.',
        'E aqueles que iam adiante, e os que seguiam, clamavam, dizendo: Hosana, bendito o que vem em nome do Senhor;',
        'Bendito o reino do nosso pai Davi, que vem em nome do Senhor. Hosana nas alturas.',
        'E Jesus entrou em Jerusalém, no templo, e, tendo visto tudo em redor, como fosse já tarde, saiu para Betânia com os doze.',
        'E, no dia seguinte, quando saíram de Betânia, teve fome.',
        'E, vendo de longe uma figueira que tinha folhas, foi ver se nela acharia alguma coisa; e, chegando a ela, não achou senão folhas, porque não era tempo de figos.',
        'E Jesus, falando, disse à figueira: Nunca mais coma alguém fruto de ti. E os seus discípulos ouviram isto.',
        'E vieram a Jerusalém; e Jesus, entrando no templo, começou a expulsar os que vendiam e compravam no templo; e derrubou as mesas dos cambiadores e as cadeiras dos que vendiam pombas.',
        'E não consentia que alguém levasse algum vaso pelo templo.',
        'E os ensinava, dizendo: Não está escrito: A minha casa será chamada, por todas as nações, casa de oração? Mas vós a tendes feito covil de ladrões.',
        'E os escribas e príncipes dos sacerdotes, tendo ouvido isto, buscavam ocasião para o matar; pois eles o temiam, porque toda a multidão estava admirada acerca da sua doutrina.',
        'E, sendo já tarde, saiu para fora da cidade.',
        'E eles, passando pela manhã, viram que a figueira se tinha secado desde as raízes.',
        'E Pedro, lembrando-se, disse-lhe: Mestre, eis que a figueira, que tu amaldiçoaste, se secou.',
        'E Jesus, respondendo, disse-lhes: Tende fé em Deus;',
        'Porque em verdade vos digo que qualquer que disser a este monte: Ergue-te e lança-te no mar, e não duvidar em seu coração, mas crer que se fará aquilo que diz, tudo o que disser lhe será feito.',
        'Por isso vos digo que todas as coisas que pedirdes, orando, crede receber, e tê-las-eis.',
        'E, quando estiverdes orando, perdoai, se tendes alguma coisa contra alguém, para que vosso Pai, que está nos céus, vos perdoe as vossas ofensas.',
        'Mas, se vós não perdoardes, também vosso Pai, que está nos céus, vos não perdoará as vossas ofensas.',
        'E tornaram a Jerusalém, e, andando ele pelo templo, os principais dos sacerdotes, e os escribas, e os anciãos, se aproximaram dele.',
        'E lhe disseram: Com que autoridade fazes tu estas coisas? ou quem te deu tal autoridade para fazer estas coisas?',
        'Mas Jesus, respondendo, disse-lhes: Também eu vos perguntarei uma coisa, e respondei-me; e então vos direi com que autoridade faço estas coisas:',
        'O batismo de João era do céu ou dos homens? respondei-me.',
        'E eles arrazoavam entre si, dizendo: Se dissermos: Do céu, ele nos dirá: Então por que o não crestes?',
        'Se, porém, dissermos: Dos homens, tememos o povo. Porque todos sustentavam que João verdadeiramente era profeta.',
        'E, respondendo, disseram a Jesus: Não sabemos. E Jesus lhes replicou: Também eu vos não direi com que autoridade faço estas coisas.',
      ],
      [
        'E começou a falar-lhes por parábolas: Um homem plantou uma vinha, e cercou-a de um valado, e fundou nela um lagar, e edificou uma torre, e arrendou-a a uns lavradores, e partiu para fora da terra.',
        'E, chegado o tempo, mandou um servo aos lavradores para que recebesse, dos lavradores, do fruto da vinha.',
        'Mas estes, apoderando-se dele, o feriram e o mandaram embora vazio.',
        'E tornou a enviar-lhes outro servo; e eles, apedrejando-o, o feriram na cabeça, e o mandaram embora, tendo-o afrontado.',
        'E tornou a enviar-lhes outro, e a este mataram; e a outros muitos, dos quais a uns feriram e a outros mataram.',
        'Tendo ele, pois, ainda um seu filho amado, enviou-o também a estes por derradeiro, dizendo: Ao menos terão respeito ao meu filho.',
        'Mas aqueles lavradores disseram entre si: Este é o herdeiro; vamos, matemo-lo, e a herança será nossa.',
        'E, pegando dele, o mataram, e o lançaram fora da vinha.',
        'Que fará, pois, o senhor da vinha? Virá, e destruirá os lavradores, e dará a vinha a outros.',
        'Ainda não lestes esta Escritura:A pedra, que os edificadores rejeitaram,Esta foi posta por cabeça de esquina;',
        'Isto foi feito pelo Senhor E é coisa maravilhosa aos nossos olhos?',
        'E buscavam prendê-lo, mas temiam a multidão; porque entendiam que contra eles dizia esta parábola; e, deixando-o, foram-se.',
        'E enviaram-lhe alguns dos fariseus e dos herodianos, para que o apanhassem nalguma palavra.',
        'E, chegando eles, disseram-lhe: Mestre, sabemos que és homem de verdade, e de ninguém se te dá, porque não olhas à aparência dos homens, antes com verdade ensinas o caminho de Deus; é lícito dar o tributo a César, ou não? Daremos, ou não daremos?',
        'Então ele, conhecendo a sua hipocrisia, disse-lhes: Por que me tentais? Trazei-me uma moeda, para que a veja.',
        'E eles lha trouxeram. E disse-lhes: De quem é esta imagem e inscrição? E eles lhe disseram: De César.',
        'E Jesus, respondendo, disse-lhes: Dai pois a César o que é de César, e a Deus o que é de Deus. E maravilharam-se dele.',
        'Então os saduceus, que dizem que não há ressurreição, aproximaram-se dele, e perguntaram-lhe, dizendo:',
        'Mestre, Moisés nos escreveu que, se morresse o irmão de alguém, e deixasse a mulher e não deixasse filhos, seu irmão tomasse a mulher dele, e suscitasse descendência a seu irmão.',
        'Ora, havia sete irmãos, e o primeiro tomou a mulher, e morreu sem deixar descendência;',
        'E o segundo também a tomou e morreu, e nem este deixou descendência; e o terceiro da mesma maneira.',
        'E tomaram-na os sete, sem, contudo, terem deixado descendência. Finalmente, depois de todos, morreu também a mulher.',
        'Na ressurreição, pois, quando ressuscitarem, de qual destes será a mulher? porque os sete a tiveram por mulher.',
        'E Jesus, respondendo, disse-lhes: Porventura não errais vós em razão de não saberdes as Escrituras nem o poder de Deus?',
        'Porquanto, quando ressuscitarem dentre os mortos, nem casarão, nem se darão em casamento, mas serão como os anjos que estão nos céus.',
        'E, acerca dos mortos que houverem de ressuscitar, não tendes lido no livro de Moisés como Deus lhe falou na sarça, dizendo: Eu sou o Deus de Abraão, e o Deus de Isaque, e o Deus de Jacó?',
        'Ora, Deus não é de mortos, mas sim, é Deus de vivos. Por isso vós errais muito.',
        'Aproximou-se dele um dos escribas que os tinha ouvido disputar, e sabendo que lhes tinha respondido bem, perguntou-lhe: Qual é o primeiro de todos os mandamentos?',
        'E Jesus respondeu-lhe: O primeiro de todos os mandamentos é: Ouve, Israel, o Senhor nosso Deus é o único Senhor.',
        'Amarás, pois, ao Senhor teu Deus de todo o teu coração, e de toda a tua alma, e de todo o teu entendimento, e de todas as tuas forças; este é o primeiro mandamento.',
        'E o segundo, semelhante a este, é: Amarás o teu próximo como a ti mesmo. Não há outro mandamento maior do que estes.',
        'E o escriba lhe disse: Muito bem, Mestre, e com verdade disseste que há um só Deus, e que não há outro além dele;',
        'E que amá-lo de todo o coração, e de todo o entendimento, e de toda a alma, e de todas as forças, e amar o próximo como a si mesmo, é mais do que todos os holocaustos e sacrifícios.',
        'E Jesus, vendo que havia respondido sabiamente, disse-lhe: Não estás longe do reino de Deus. E já ninguém ousava perguntar-lhe mais nada.',
        'E, falando Jesus, dizia, ensinando no templo: Como dizem os escribas que o Cristo é filho de Davi?',
        'O próprio Davi disse pelo Espírito Santo:O Senhor disse ao meu Senhor:Assenta-te à minha direita Até que eu ponha os teus inimigos por escabelo dos teus pés.',
        'Pois, se Davi mesmo lhe chama Senhor, como é logo seu filho? E a grande multidão o ouvia de boa vontade.',
        'E, ensinando-os, dizia-lhes: Guardai-vos dos escribas, que gostam de andar com vestes compridas, e das saudações nas praças,',
        'E das primeiras cadeiras nas sinagogas, e dos primeiros assentos nas ceias;',
        'Que devoram as casas das viúvas, e isso com pretexto de largas orações. Estes receberão mais grave condenação.',
        'E, estando Jesus assentado defronte da arca do tesouro, observava a maneira como a multidão lançava o dinheiro na arca do tesouro; e muitos ricos deitavam muito.',
        'Vindo, porém, uma pobre viúva, deitou duas pequenas moedas, que valiam meio centavo.',
        'E, chamando os seus discípulos, disse-lhes: Em verdade vos digo que esta pobre viúva deitou mais do que todos os que deitaram na arca do tesouro;',
        'Porque todos ali deitaram do que lhes sobejava, mas esta, da sua pobreza, deitou tudo o que tinha, todo o seu sustento.',
      ],
      [
        'E, saindo ele do templo, disse-lhe um dos seus discípulos: Mestre, olha que pedras, e que edifícios!',
        'E, respondendo Jesus, disse-lhe: Vês estes grandes edifícios? Não ficará pedra sobre pedra que não seja derrubada.',
        'E, assentando-se ele no Monte das Oliveiras, defronte do templo, Pedro, e Tiago, e João e André lhe perguntaram em particular:',
        'Dize-nos, quando serão essas coisas, e que sinal haverá quando todas elas estiverem para se cumprir.',
        'E Jesus, respondendo-lhes, começou a dizer: Olhai que ninguém vos engane;',
        'Porque muitos virão em meu nome, dizendo: Eu sou o Cristo; e enganarão a muitos.',
        'E, quando ouvirdes de guerras e de rumores de guerras, não vos perturbeis; porque assim deve acontecer; mas ainda não será o fim.',
        'Porque se levantará nação contra nação, e reino contra reino, e haverá terremotos em diversos lugares, e haverá fomes e tribulações. Estas coisas sào os princípios das dores.',
        'Mas olhai por vós mesmos, porque vos entregarão aos concílios e às sinagogas; e sereis açoitados, e sereis apresentados perante presidentes e reis, por amor de mim, para lhes servir de testemunho.',
        'Mas importa que o evangelho seja primeiramente pregado entre todas as nações.',
        'Quando, pois, vos conduzirem e vos entregarem, não estejais solícitos de antemão pelo que haveis de dizer, nem premediteis; mas, o que vos for dado naquela hora, isso falai, porque não sois vós os que falais, mas o Espírito Santo.',
        'E o irmão entregará à morte o irmão, e o pai ao filho; e levantar-se-ão os filhos contra os pais, e os farão morrer.',
        'E sereis odiados por todos por amor do meu nome; mas quem perseverar até ao fim, esse será salvo.',
        'Ora, quando vós virdes a abominação do assolamento, que foi predita por Daniel o profeta, estar onde não deve estar (quem lê, entenda), então os que estiverem na Judéia fujam para os montes.',
        'E o que estiver sobre o telhado não desça para casa, nem entre a tomar coisa alguma de sua casa;',
        'E o que estiver no campo não volte atrás, para tomar as suas vestes.',
        'Mas ai das grávidas, e das que criarem naqueles dias!',
        'Orai, pois, para que a vossa fuga não suceda no inverno.',
        'Porque naqueles dias haverá uma aflição tal, qual nunca houve desde o princípio da criação, que Deus criou, até agora, nem jamais haverá.',
        'E, se o Senhor não abreviasse aqueles dias, nenhuma carne se salvaria; mas, por causa dos eleitosque escolheu, abreviou aqueles dias.',
        'E então, se alguém vos disser: Eis aqui o Cristo; ou: Ei-lo ali; não acrediteis.',
        'Porque se levantarão falsos cristos, e falsos profetas, e farão sinais e prodígios, para enganarem, se for possível, até os escolhidos.',
        'Mas vós vede; eis que de antemão vos tenho dito tudo.',
        'Ora, naqueles dias, depois daquela aflição, o sol se escurecerá, e a lua não dará a sua luz.',
        'E as estrelas cairão do céu, e as forças que estão nos céus serão abaladas.',
        'E então verão vir o Filho do homem nas nuvens, com grande poder e glória.',
        'E ele enviará os seus anjos, e ajuntará os seus escolhidos, desde os quatro ventos, da extremidade da terra até a extremidade do céu.',
        'Aprendei, pois, a parábola da figueira: Quando já o seu ramo se torna tenro, e brota folhas, bem sabeis que já está próximo o verão.',
        'Assim também vós, quando virdes sucederem estas coisas, sabei que já está perto, às portas.',
        'Na verdade vos digo que não passará esta geração, sem que todas estas coisas aconteçam.',
        'Passará o céu e a terra, mas as minhas palavras não passarão.',
        'Mas daquele dia e hora ninguém sabe, nem os anjos que estão no céu, nem o Filho, senão o Pai.',
        'Olhai, vigiai e orai; porque não sabeis quando chegará o tempo.',
        'É como se um homem, partindo para fora da terra, deixasse a sua casa, e desse autoridade aos seus servos, e a cada um a sua obra, e mandasse ao porteiro que vigiasse.',
        'Vigiai, pois, porque não sabeis quando virá o senhor da casa; se à tarde, se à meia-noite, se ao cantar do galo, se pela manhã,',
        'Para que, vindo de improviso, não vos ache dormindo.',
        'E as coisas que vos digo, digo-as a todos: Vigiai.',
      ],
      [
        'E dali a dois dias era a páscoa, e a festa dos pães ázimos; e os principais dos sacerdotes e os escribas buscavam como o prenderiam com dolo, e o matariam.',
        'Mas eles diziam: Não na festa, para que porventura não se faça alvoroço entre o povo.',
        'E, estando ele em betânia, assentado à mesa, em casa de Simão, o leproso, veio uma mulher, que trazia um vaso de alabastro, com ungüento de nardo puro, de muito preço, e quebrando o vaso, lho derramou sobre a cabeça.',
        'E alguns houve que em si mesmos se indignaram, e disseram: Para que se fez este desperdício de ungüento?',
        'Porque podia vender-se por mais de trezentos dinheiros, e dá-lo aos pobres. E bramavam contra ela.',
        'Jesus, porém, disse: Deixai-a, por que a molestais? Ela fez-me boa obra.',
        'Porque sempre tendes os pobres convosco, e podeis fazer-lhes bem, quando quiserdes; mas a mim nem sempre me tendes.',
        'Esta fez o que podia; antecipou-se a ungir o meu corpo para a sepultura.',
        'Em verdade vos digo que, em todas as partes do mundo onde este evangelho for pregado, também o que ela fez será contado para sua memória.',
        'E Judas Iscariotes, um dos doze, foi ter com os principais dos sacerdotes para lho entregar.',
        'E eles, ouvindo-o, folgaram, e prometeram dar-lhe dinheiro; e buscava como o entregaria em ocasião oportuna.',
        'E, no primeiro dia dos pães ázimos, quando sacrificavam a páscoa, disseram-lhe os discípulos: Aonde queres que vamos fazer os preparativos para comer a páscoa?',
        'E enviou dois dos seus discípulos, e disse-lhes: Ide à cidade, e um homem, que leva um cântaro de água, vos encontrará; segui-o.',
        'E, onde quer que entrar, dizei ao senhor da casa: O Mestre diz: Onde está o aposento em que hei de comer a páscoa com os meus discípulos?',
        'E ele vos mostrará um grande cenáculo mobilado e preparado; preparai-a ali.',
        'E, saindo os seus discípulos, foram à cidade, e acharam como lhes tinha dito, e prepararam a páscoa.',
        'E, chegada a tarde, foi com os doze.',
        'E, quando estavam assentados a comer, disse Jesus: Em verdade vos digo que um de vós, que comigo come, há de trair-me.',
        'E eles começaram a entristecer-se e a dizer-lhe um após outro: Sou eu? E outro disse: Sou eu?',
        'Mas ele, respondendo, disse-lhes: É um dos doze, que põe comigo a mão no prato.',
        'Na verdade o Filho do homem vai, como dele está escrito, mas ai daquele homem por quem o Filho do homem é traído! Bom seria para o tal homem não haver nascido.',
        'E, comendo eles, tomou Jesus pão e, abençoando-o, o partiu e deu-lho, e disse: Tomai, comei, isto é o meu corpo.',
        'E, tomando o cálice, e dando graças, deu-lho; e todos beberam dele.',
        'E disse-lhes: Isto é o meu sangue, o sangue do novo testamento, que por muitos é derramado.',
        'Em verdade vos digo que não beberei mais do fruto da vide, até àquele dia em que o beber, novo, no reino de Deus.',
        'E, tendo cantado o hino, saíram para o Monte das Oliveiras.',
        'E disse-lhes Jesus: Todos vós esta noite vos escandalizareis em mim; porque está escrito: Ferirei o pastor, e as ovelhas se dispersarão.',
        'Mas, depois que eu houver ressuscitado, irei adiante de vós para a Galiléia.',
        'E disse-lhe Pedro: Ainda que todos se escandalizem, nunca, porém, eu.',
        'E disse-lhe Jesus: Em verdade te digo que hoje, nesta noite, antes que o galo cante duas vezes, três vezes me negarás.',
        'Mas ele disse com mais veemência: Ainda que me seja necessário morrer contigo, de modo nenhum te negarei. E da mesma maneira diziam todos também.',
        'E foram a um lugar chamado Getsêmani, e disse aos seus discípulos: Assentai-vos aqui, enquanto eu oro.',
        'E tomou consigo a Pedro, e a Tiago, e a João, e começou a ter pavor, e a angustiar-se.',
        'E disse-lhes: A minha alma está profundamente triste até a morte; ficai aqui, e vigiai.',
        'E, tendo ido um pouco mais adiante, prostrou-se em terra; e orou para que, se fosse possível, passasse dele aquela hora.',
        'E disse: Aba, Pai, todas as coisas te são possíveis; afasta de mim este cálice; não seja, porém, o que eu quero, mas o que tu queres.',
        'E, chegando, achou-os dormindo; e disse a Pedro: Simão, dormes? não podes vigiar uma hora?',
        'Vigiai e orai, para que não entreis em tentação; o espírito, na verdade, está pronto, mas a carne é fraca.',
        'E foi outra vez e orou, dizendo as mesmas palavras.',
        'E, voltando, achou-os outra vez dormindo, porque os seus olhos estavam pesados, e não sabiam o que responder-lhe.',
        'E voltou terceira vez, e disse-lhes: Dormi agora, e descansai. Basta; é chegada a hora.  Eis que o Filho do homem vai ser entregue nas mãos dos pecadores.',
        'Levantai-vos, vamos; eis que está perto o que me trai.',
        'E logo, falando ele ainda, veio Judas, que era um dos doze, da parte dos principais dos sacerdotes, e dos escribas e dos anciãos, e com ele uma grande multidão com espadas e varapaus.',
        'Ora, o que o traía, tinha-lhes dado um sinal, dizendo: Aquele que eu beijar, esse é; prendei-o, e levai-o com segurança.',
        'E, logo que chegou, aproximou-se dele, e disse-lhe: Rabi, Rabi. E beijou-o.',
        'E lançaram-lhe as mãos, e o prenderam.',
        'E um dos que ali estavam presentes, puxando da espada, feriu o servo do sumo sacerdote, e cortou-lhe uma orelha.',
        'E, respondendo Jesus, disse-lhes: Saístes com espadas e varapaus a prender-me, como a um salteador?',
        'Todos os dias estava convosco ensinando no templo, e não me prendestes; mas isto é para que as Escrituras se cumpram.',
        'Então, deixando-o, todos fugiram.',
        'E um certo jovem o seguia, envolto em um lençol sobre o corpo nu. E lançaram-lhe a mão.',
        'Mas ele, largando o lençol, fugiu nu. Jesus perante o Sinédrio. Negação de Pedro',
        'E levaram Jesus ao sumo sacerdote, e ajuntaram-se todos os principais dos sacerdotes, e os anciãos e os escribas.',
        'E Pedro o seguiu de longe até dentro do pátio do sumo sacerdote, e estava assentado com os servidores, aquentando-se ao lume.',
        'E os principais dos sacerdotes e todo o concílio buscavam algum testemunho contra Jesus, para o matar, e não o achavam.',
        'Porque muitos testificavam falsamente contra ele, mas os testemunhos não eram coerentes.',
        'E, levantando-se alguns, testificaram falsamente contra ele, dizendo:',
        'Nós ouvimos-lhe dizer: Eu derrubarei este templo, construído por mãos de homens, e em três dias edificarei outro, não feito por mãos de homens.',
        'E nem assim o seu testemunho era coerente.',
        'E, levantando-se o sumo sacerdote no Sinédrio, perguntou a Jesus, dizendo: Nada respondes? Que testificam estes contra ti?',
        'Mas ele calou-se, e nada respondeu. O sumo sacerdote lhe tornou a perguntar, e disse-lhe: És tu o Cristo, Filho do Deus Bendito?',
        'E Jesus disse-lhe: Eu o sou, e vereis o Filho do homem assentado à direita do poder de Deus, e vindo sobre as nuvens do céu.',
        'E o sumo sacerdote, rasgando as suas vestes, disse: Para que necessitamos de mais testemunhas?',
        'Vós ouvistes a blasfêmia; que vos parece? E todos o consideraram culpado de morte.',
        'E alguns começaram a cuspir nele, e a cobrir-lhe o rosto, e a dar-lhe punhadas, e a dizer-lhe: Profetiza. E os servidores davam-lhe bofetadas.',
        'E, estando Pedro embaixo, noátrio, chegou uma das criadas do sumo sacerdote;',
        'E, vendo a Pedro, que se estava aquentando, olhou para ele, e disse: Tu também estavas com Jesus, o Nazareno.',
        'Mas ele negou-o, dizendo: Não o conheço, nem sei o que dizes. E saiu fora ao alpendre, e o galo cantou.',
        'E a criada, vendo-o outra vez, começou a dizer aos que ali estavam: Este é um dos tais.',
        'Mas ele o negou outra vez. E pouco depois os que ali estavam disseram outra vez a Pedro: Verdadeiramente tu és um deles, porque és também galileu, e tua fala é semelhante.',
        'E ele começou a praguejar, e a jurar: Não conheço esse homem de quem falais.',
        'E o galo cantou segunda vez. E Pedro lembrou-se da palavra que Jesus lhe tinha dito: Antes que o galo cante duas vezes, três vezes me negarás.  E, retirando-se dali, chorou.',
      ],
      [
        'E, logo ao amanhecer, os principais dos sacerdotes, com os anciãos, e os escribas, e todo o Sinédrio, tiveram conselho; e, ligando Jesus, o levaram e entregaram a Pilatos.',
        'E Pilatos lhe perguntou: Tu és o Rei dos Judeus? E ele, respondendo, disse-lhe: Tu o dizes.',
        'E os principais dos sacerdotes o acusavam de muitas coisas; porém ele nada respondia.',
        'E Pilatos o interrogou outra vez, dizendo: Nada respondes? Vê quantas coisas testificam contra ti.',
        'Mas Jesus nada mais respondeu, de maneira que Pilatos se maravilhava.',
        'Ora, no dia da festa costumava soltar-lhes um preso qualquer que eles pedissem.',
        'E havia um chamado Barrabás, que, preso com outros amotinado- res, tinha num motim cometido uma morte.',
        'E a multidão, dando gritos, começou a pedir que fizesse como sempre lhes tinha feito.',
        'E Pilatos lhes respondeu, dizendo: Quereis que vos solte o Rei dos Judeus?',
        'Porque ele bem sabia que por inveja os principais dos sacerdotes o tinham entregado.',
        'Mas os principais dos sacerdotes incitaram a multidão para que fosse solto antes Barrabás.',
        'E Pilatos, respondendo, lhes disse outra vez: Que quereis, pois, que faça daquele a quem chamais Rei dos Judeus?',
        'E eles tornaram a clamar: Crucifica-o.',
        'Mas Pilatos lhes disse: Mas que mal fez? E eles cada vez clamavam mais: Crucifica-o.',
        'Então Pilatos, querendo satisfazer a multidão, soltou-lhe Barrabás e, açoitado Jesus, o entregou para ser crucificado.',
        'E os soldados o levaram dentro à sala, que é a da audiência, e convocaram toda a coorte.',
        'E vestiram-no de púrpura, e tecendo uma coroa de espinhos, lha puseram na cabeça.',
        'E começaram a saudá-lo, dizendo: Salve, Rei dos Judeus!',
        'E feriram-no na cabeça com uma cana, e cuspiram nele e, postos de joelhos, o adoraram.',
        'E, havendo-o escarnecido, despiram-lhe a púrpura, e o vestiram com as suas próprias vestes; e o levaram para fora a fim de o crucificarem.',
        'E constrangeram um certo Simão, cireneu, pai de Alexandre e de Rufo, que por ali passava, vindo do campo, a que levasse a cruz.',
        'E levaram-no ao lugar doGólgota, que se traduz por lugar da Caveira.',
        'E deram-lhe a beber vinho com mirra, mas ele não o tomou.',
        'E, havendo-o crucificado, repartiram as suas vestes, lançando sobre elas sortes, para saber o que cada um levaria.',
        'E era a hora terceira, e o crucificaram.',
        'E por cima dele estava escrita a sua acusação: O REI DOS JUDEUS.',
        'E crucificaram com ele dois salteadores, um à sua direita, e outro à esquerda.',
        'E cumprindo-se a escritura que diz: E com os malfeitores foi contado.',
        'E os que passavam blasfemavam dele, meneando as suas cabeças, e dizendo: Ah! tu que derrubas o templo, e em três dias o edificas,',
        'Salva-te a ti mesmo, e desce da cruz.',
        'E da mesma maneira também os principais dos sacerdotes, com os escribas, diziam uns para os outros, zombando: Salvou os outros, e não pode salvar-se a si mesmo.',
        'O Cristo, o Rei de Israel, desça agora da cruz, para que o vejamos e acreditemos. Também os que com ele foram crucificados o injuriavam.',
        'E, chegada a hora sexta, houve trevas sobre toda a terra até a hora nona.',
        'E, à hora nona, Jesus exclamou com grande voz, dizendo: Eloí, Eloí, lamá sabactâni? que, traduzido, é: Deus meu, Deus meu, por que me desamparaste?',
        'E alguns dos que ali estavam, ouvindo isto, diziam: Eis que chama por Elias.',
        'E um deles correu a embeber uma esponja em vinagre e, pondo-a numa cana, deu-lho a beber, dizendo: Deixai, vejamos se virá Elias tirá-lo.',
        'E Jesus, dando um grande brado, expirou.',
        'E o véu do templo se rasgou em dois, de alto a baixo.',
        'E o centurião, que estava defronte dele, vendo que assim clamando expirara, disse: Verdadeiramente este homem era o Filho de Deus.',
        'E também ali estavam algumas mulheres, olhando de longe, entre as quais também Maria Madalena, e Maria, mãe de Tiago, o menor, e de José, e Salomé;',
        'As quais também o seguiam, e o serviam, quando estava na Galiléia; e muitas outras, que tinham subido com ele a Jerusalém.',
        'E, chegada a tarde, porquanto era o dia da preparação, isto é, a véspera do sábado,',
        'Chegou José de Arimatéia, senador honrado, que também esperava o reino de Deus, e ousadamente foi a Pilatos, e pediu o corpo de Jesus.',
        'E Pilatos se maravilhou de que já estivesse morto. E, chamando o centurião, perguntou-lhe se já havia muito que tinha morrido.',
        'E, tendo-se certificado pelo centurião, deu o corpo a José;',
        'O qual comprara um lençol fino, e, tirando-o da cruz, o envolveu nele, e o depositou num sepulcro lavrado numa rocha; e revolveu uma pedra para a porta do sepulcro.',
        'E Maria Madalena e Maria, mãe de José, observavam onde o punham.',
      ],
      [
        'E, passado o sábado, Maria Madalena, e Maria, mãe de Tiago, e Salomé, compraram aromas para irem ungi-lo.',
        'E, no primeiro dia da semana, foram ao sepulcro, de manhã cedo, ao nascer do sol.',
        'E diziam umas às outras: Quemnos revolverá a pedra da porta do sepulcro?',
        'E, olhando, viram que já a pedra estava revolvida; e era ela muito grande.',
        'E, entrando no sepulcro, viram um jovem assentado à direita, vestido de uma roupa comprida, branca; e ficaram espantadas.',
        'Ele, porém, disse-lhes: Não vos assusteis; buscais a Jesus Nazareno, que foi crucificado; já ressuscitou, não está aqui; eis aqui o lugar onde o puseram.',
        'Mas ide, dizei a seus discípulos, e a Pedro, que ele vai adiante de vós para a Galiléia; ali o vereis, como ele vos disse.',
        'E, saindo elas apressadamente, fugiram do sepulcro, porque estavam possuídas de temor e assombro; e nada diziam a ninguém porque temiam.',
        'E Jesus, tendo ressuscitado na manhã do primeiro dia da semana, apareceu primeiramente a Maria Madalena, da qual tinha expulsado sete demônios.',
        'E, partindo ela, anunciou-o àqueles que tinham estado com ele, os quais estavam tristes, e chorando.',
        'E, ouvindo eles que vivia, e que tinha sido visto por ela, não o creram.',
        'E depois manifestou-se de outra forma a dois deles, que iam de caminho para o campo.',
        'E, indo estes, anunciaram-no aos outros, mas nem ainda estes creram.',
        'Finalmente apareceu aos onze, estando eles assentados juntamente, e lançou-lhes em rosto a sua incredulidade e dureza de coração, por não haverem crido nos que o tinham visto já ressuscitado.',
        'E disse-lhes: Ide por todo omundo, pregai o evangelho a toda criatura.',
        'Quem crer e for batizado será salvo; mas quem não crer será condenado.',
        'E estes sinais seguirão aos que crerem: Em meu nome expulsarão os demônios; falarão novas línguas;',
        'Pegarão nas serpentes; e, se beberem alguma coisa mortífera, não lhes fará dano algum; e porãoas mãos sobre os enfermos, e os curarão.',
        'Ora, o Senhor, depois de lhes ter falado, foi recebido no céu, e assentou-se à direita de Deus.',
        'E eles, tendo partido, pregaram por todas as partes, cooperando com eles o Senhor, e confirmando a palavra com os sinais que se seguiram. Amém.',
      ],
    ],
    livro: 'marcos',
  },
  {
    abbrev: 'lc',
    capitulos: [
      [
        'Tendo, pois, muitos empreendido pôr em ordem a narração dos fatos que entre nós se cumpriram,',
        'Segundo nos transmitiram os mesmos que os presenciaram desde oprincípio, e foram ministros da palavra,',
        'Pareceu-me também a mim conveniente descrevê-los a ti, ó excelente Teófilo, por sua ordem, havendo-me já informado minuciosamente de tudo desde o princípio;',
        'Para que conheças a certeza das coisas de que já estás informado.',
        'Existiu, no tempo de Herodes, rei da Judéia, um sacerdote chamado Zacarias, da ordem de Abias, e cuja mulher era das filhas de Arão; e o seu nome era Isabel.',
        'E eram ambos justos perante Deus, andando sem repreensão em todos os mandamentos e preceitos do Senhor.',
        'E não tinham filhos, porque Isabel era estéril, e ambos eram avançados em idade.',
        'E aconteceu que, exercendo ele o sacerdócio diante de Deus, na ordem da sua turma,',
        'Segundo o costume sacerdotal, coube-lhe em sorte entrar no templo do Senhor para oferecer o incenso.',
        'E toda a multidão do povo estava fora, orando, à hora do incenso.',
        'E um anjo do Senhor lhe apareceu, posto em pé, à direita do altar do incenso.',
        'E Zacarias, vendo-o, turbou-se, e caiu temor sobre ele.',
        'Mas o anjo lhe disse: Zacarias, não temas, porque a tua oração foi ouvida, e Isabel, tua mulher, dará à luz um filho, e lhe porás o nome de João.',
        'E terás prazer e alegria, e muitos se alegrarão no seu nascimento,',
        'Porque será grande diante do Senhor, e não beberá vinho, nem bebida forte, e será cheio do Espírito Santo, já desde o ventre de sua mãe.',
        'E converterá muitos dos filhos de Israel ao Senhor seu Deus,',
        'E irá adiante dele no espírito e virtude de Elias, para converter os corações dos pais aos filhos, e os rebeldes à prudência dos justos, com o fim de preparar ao Senhor um povo bem disposto.',
        'Disse então Zacarias ao anjo: Como saberei isto? pois eu já sou velho, e minha mulher avançada em idade.',
        'E, respondendo o anjo, disse-lhe: Eu sou Gabriel, que assisto diante de Deus, e fui enviado a falar-te e dar-te estas alegres novas.',
        'E eis que ficarás mudo, e não poderás falar até ao dia em que estas coisas aconteçam; porquanto não creste nas minhas palavras, que a seu tempo se hão de cumprir.',
        'E o povo estava esperando a Zacarias, e maravilhava-se de que tanto se demorasse no templo.',
        'E, saindo ele, não lhes podia falar; e entenderam que tinha tido uma visão no templo. E falava por acenos, e ficou mudo.',
        'E sucedeu que, terminados os dias de seu ministério, voltou para sua casa.',
        'E, depois daqueles dias, Isabel, sua mulher, concebeu, e por cinco meses se ocultou, dizendo:',
        'Assim me fez o Senhor, nos dias em que atentou em mim, para destruir o meu opróbrio entre os homens.',
        'E, no sexto mês, foi o anjo Gabriel enviado por Deus a uma cidade da Galiléia, chamada Nazaré,',
        'A uma virgem desposada com um homem, cujo nome era José, da casa de Davi; e o nome da virgem era Maria.',
        'E, entrando o anjo aonde ela estava, disse: Salve, agraciada; o Senhor é contigo; bendita és tu entre as mulheres.',
        'E, vendo-o ela, turbou-se muito com aquelas palavras, e considerava que saudação seria esta.',
        'Disse-lhe, então, o anjo: Maria, não temas, porque achaste graça diante de Deus.',
        'E eis que em teu ventre conceberás e darás à luz um filho, e pôr-lhe-ás o nome de Jesus.',
        'Este será grande, e será chamado filho do Altíssimo; e o Senhor Deus lhe dará o trono de Davi, seu pai;',
        'E reinará eternamente na casa de Jacó, e o seu reino não terá fim.',
        'E disse Maria ao anjo: Como se fará isto, visto que não conheço homem algum?',
        'E, respondendo o anjo, disse-lhe: Descerá sobre ti o Espírito Santo, e a virtude do Altíssimo te cobrirá com a sua sombra; por isso também o Santo, que de ti há de nascer, será chamado Filho de Deus.',
        'E eis que também Isabel, tua prima, concebeu um filho em sua velhice; e é este o sexto mês para aquela que era chamada estéril;',
        'Porque para Deus nada é impossível.',
        'Disse então Maria: Eis aqui a serva do Senhor; cumpra-se em mim segundo a tua palavra. E o anjo ausentou-se dela.',
        'E, naqueles dias, levantando-se Maria, foi apressada às montanhas, a uma cidade de Judá,',
        'E entrou em casa de Zacarias, e saudou a Isabel.',
        'E aconteceu que, ao ouvir Isabel a saudação de Maria, a criancinha saltou no seu ventre; e Isabel foi cheia do Espírito Santo.',
        'E exclamou com grande voz, e disse: Bendita és tu entre as mulheres, e bendito o fruto do teu ventre.',
        'E de onde me provém isto a mim, que venha visitar-me a mãe do meu Senhor?',
        'Pois eis que, ao chegar aos meus ouvidos a voz da tua saudação, a criancinha saltou de alegria no meu ventre.',
        'Bem-aventurada a que creu, pois hão de cumprir-se as coisas que da parte do Senhor lhe foram ditas.',
        'Disse então Maria: A minha alma engrandece ao Senhor,',
        'E o meu espírito se alegra em Deus meu Salvador;',
        'Porque atentou na baixeza de sua serva; Pois eis que desde agora todas as gerações me chamarão bem-aventurada,',
        'Porque me fez grandes coisas o Poderoso; E santo é seu nome.',
        'E a sua misericórdia é de geração em geração Sobre os que o temem.',
        'Com o seu braço agiu valorosamente; Dissipou os soberbos no pensamento de seus corações.',
        'Depôs dos tronos os poderosos, E elevou os humildes.',
        'Encheu de bens os famintos, E despediu vazios os ricos.',
        'Auxiliou a Israel seu servo, Recordando-se da sua misericórdia;',
        'Como falou a nossos pais, Para com Abraão e a sua posteridade, para sempre.',
        'E Maria ficou com ela quase três meses, e depois voltou para sua casa.',
        'E completou-se para Isabel o tempo de dar à luz, e teve um filho.',
        'E os seus vizinhos e parentes ouviram que tinha Deus usado para com ela de grande misericórdia, e alegraram-se com ela.',
        'E aconteceu que, ao oitavo dia, vieram circuncidar o menino, e lhe chamavam Zacarias, o nome de seu pai.',
        'E, respondendo sua mãe, disse: Não, porém será chamado João.',
        'E disseram-lhe: Ninguém há na tua parentela que se chame por este nome.',
        'E perguntaram por acenos ao pai como queria que lhe chamassem.',
        'E, pedindo ele uma tabuinha de escrever, escreveu, dizendo: O seu nome é João. E todos se maravilharam.',
        'E logo a boca se lhe abriu, e a língua se lhe soltou; e falava, louvando a Deus.',
        'E veio temor sobre todos os seus vizinhos, e em todas as montanhas da Judéia foram divulgadas todas estas coisas.',
        'E todos os que as ouviam as conservavam em seus corações, dizendo: Quem será, pois, este menino? E a mão do Senhor estava com ele.',
        'E Zacarias, seu pai, foi cheio do Espírito Santo, e profetizou, dizendo:',
        'Bendito o Senhor Deus de Israel, Porque visitou e remiu o seu povo,',
        'E nos levantou uma salvação poderosa Na casa de Davi seu servo.',
        'Como falou pela boca dos seus santos profetas, desde o princípio do mundo;',
        'Para nos livrar dos nossos inimigos e da mão de todos os que nos odeiam;',
        'Para manifestar misericórdia a nossos pais, E lembrar-se da sua santa aliança,',
        'E do juramento que jurou a Abraão nosso pai,',
        'De conceder-nos que, Libertados da mão de nossos inimigos, o serviríamos sem temor,',
        'Em santidade e justiça perante ele, todos os dias da nossa vida.',
        'E tu, ó menino, serás chamado profeta do Altíssimo, Porque hás de ir ante a face do Senhor, a preparar os seus caminhos;',
        'Para dar ao seu povo conhecimento da salvação, Na remissão dos seus pecados;',
        'Pelas entranhas da misericórdia do nosso Deus, Com que o oriente do alto nos visitou;',
        'Para iluminar aos que estão assentados em trevas e na sombra da morte; A fim de dirigir os nossos pés pelo caminho da paz.',
        'E o menino crescia, e se robustecia em espírito. E esteve nos desertos até ao dia em que havia de mostrar-se a Israel.',
      ],
      [
        'E aconteceu naqueles dias que saiu um decreto da parte de César Augusto, para que todo o mundo se alistasse',
        '(Este primeiro alistamento foi feito sendo Quirino presidente da Síria).',
        'E todos iam alistar-se, cada um à sua própria cidade.',
        'E subiu também José da Galiléia, da cidade de Nazaré, à Judéia, à cidade de Davi, chamada Belém (porque era da casa e família de Davi),',
        'A fim de alistar-se com Maria, sua esposa, que estava grávida.',
        'E aconteceu que, estando eles ali, se cumpriram os dias em que ela havia de dar à luz.',
        'E deu à luz a seu filho primogênito, e envolveu-o em panos, e deitou-o numa manjedoura, porque não havia lugar para eles na estalagem.',
        'Ora, havia naquela mesma comarca pastores que estavam no campo, e guardavam, durante as vigílias da noite, o seu rebanho.',
        'E eis que o anjo do Senhor veio sobre eles, e a glória do Senhor os cercou de resplendor, e tiveram grande temor.',
        'E o anjo lhes disse: Não temais, porque eis aqui vos trago novas de grande alegria, que será para todo o povo:',
        'Pois, na cidade de Davi, vos nasceu hoje o Salvador, que é Cristo, o Senhor.',
        'E isto vos será por sinal: Achareis o menino envolto em panos, e deitado numa manjedoura.',
        'E, no mesmo instante, apareceu com o anjo uma multidão dos exércitos celestiais, louvando a Deus, e dizendo:',
        'Glória a Deus nas alturas, Paz na terra, boa vontade para com os homens.',
        'E aconteceu que, ausentando-se deles os anjos para o céu, disseram os pastores uns aos outros: Vamos, pois, até Belém, e vejamos isso que aconteceu, e que o Senhor nos fez saber.',
        'E foram apressadamente, e acharam Maria, e José, e o menino deitado na manjedoura.',
        'E, vendo-o, divulgaram a palavra que acerca do menino lhes fora dita;',
        'E todos os que a ouviram se maravilharam do que os pastores lhes diziam.',
        'Mas Maria guardava todas estas coisas, conferindo-as em seu coração.',
        'E voltaram os pastores, glorificando e louvando a Deus por tudo o que tinham ouvido e visto, como lhes havia sido dito.',
        'E, quando os oito dias foram cumpridos, para circuncidar o menino, foi-lhe dado o nome de Jesus, que pelo anjo lhe fora posto antes de ser concebido.',
        'E, cumprindo-se os dias da purificação dela, segundo a lei de Moisés, o levaram a Jerusalém, para o apresentarem ao Senhor',
        '(Segundo o que está escrito na lei do Senhor: Todo o macho primogênito será consagrado ao Senhor);',
        'E para darem a oferta segundo o disposto na lei do Senhor: Um par de rolas ou dois pombinhos.',
        'Havia em Jerusalém um homem cujo nome era Simeão; e este homem era justo e temente a Deus, esperando a consolação de Israel; e o Espírito Santo estava sobre ele.',
        'E fora-lhe revelado, pelo Espírito Santo, que ele não morreria antes de ter visto o Cristo do Senhor.',
        'E pelo Espírito foi ao templo e, quando os pais trouxeram o menino Jesus, para com ele procederem segundo o uso da lei,',
        'Ele, então, o tomou em seus braços, e louvou a Deus, e disse:',
        'Agora, Senhor, despedes em paz o teu servo, Segundo a tua palavra;',
        'Pois já os meus olhos viram a tua salvação,',
        'A qual tu preparaste perante a face de todos os povos;',
        'Luz para iluminar as nações, E para glória de teu povo Israel.',
        'E José, e sua mãe, se maravilharam das coisas que dele se diziam.',
        'E Simeão os abençoou, e disse a Maria, sua mãe: Eis que este é posto para queda e elevação de muitos em Israel, e para sinal que é contraditado',
        '(E uma espada traspassará também a tua própria alma); para que se manifestem os pensamentos de muitos corações.',
        'E estava ali a profetisa Ana, filha de Fanuel, da tribo de Aser. Esta era já avançada em idade, e tinha vivido com o marido sete anos, desde a sua virgindade;',
        'E era viúva, de quase oitenta e quatro anos, e não se afastava do templo, servindo a Deus em jejuns e orações, de noite e de dia.',
        'E sobrevindo na mesma hora, ela dava graças a Deus, e falava dele a todos os que esperavam a redenção em Jerusalém.',
        'E, quando acabaram de cumprir tudo segundo a lei do Senhor, voltaram à Galiléia, para a sua cidade de Nazaré.',
        'E o menino crescia, e se fortalecia em espírito, cheio de sabedoria; e a graça de Deus estava sobre ele.',
        'Ora, todos os anos iam seus pais a Jerusalém à festa da páscoa;',
        'E, tendo ele já doze anos, subiram a Jerusalém, segundo o costume do dia da festa.',
        'E, regressando eles, terminados aqueles dias, ficou o menino Jesus em Jerusalém, e não o soube José, nem sua mãe.',
        'Pensando, porém, eles que viria de companhia pelo caminho, andaram caminho de um dia, e procuravam-no entre os parentes e conhecidos;',
        'E, como o não encontrassem, voltaram a Jerusalém em busca dele.',
        'E aconteceu que, passados três dias, o acharam no templo, assentado no meio dos doutores, ouvindo-os, e interrogando-os.',
        'E todos os que o ouviam admiravam a sua inteligência e respostas.',
        'E quando o viram, maravilharam-se, e disse-lhe sua mãe: Filho, por que fizeste assim para conosco? Eis que teu pai e eu ansiosos te procurávamos.',
        'E ele lhes disse: Por que é que me procuráveis? Não sabeis que me convém tratar dos negócios de meu Pai?',
        'E eles não compreenderam as palavras que lhes dizia.',
        'E desceu com eles, e foi para Nazaré, e era-lhes sujeito. E sua mãe guardava no seu coração todas estas coisas.',
        'E crescia Jesus em sabedoria, e em estatura, e em graça para com Deus e os homens.',
      ],
      [
        'E no ano quinze do império de Tibério César, sendo Pôncio Pilatos presidente da Judéia, e Herodes tetrarca da Galiléia, e seu irmão Filipe tetrarca da Ituréia e da província de Traconites, e Lisânias tetrarca de Abilene,',
        'Sendo Anás e Caifás sumos sacerdotes, veio no deserto a palavra de Deus a João, filho de Zacarias.',
        'E percorreu toda a terra ao redor do Jordão, pregando o batismo de arrependimento, para o perdão dos pecados;',
        'Segundo o que está escrito no livro das palavras do profeta Isaías, que diz: Voz do que clama no deserto: Preparai o caminho do Senhor; Endireitai as suas veredas.',
        'Todo o vale se encherá, E se abaixará todo o monte e outeiro; E o que é tortuoso se endireitará, E os caminhos escabrosos se aplanarão;',
        'E toda a carne verá a salvação de Deus.',
        'Dizia, pois, João à multidão que saía para ser batizada por ele: Raça de víboras, quem vos ensinou a fugir da ira que está para vir?',
        'Produzi, pois, frutos dignos de arrependimento, e não comeceis a dizer em vós mesmos: Temos Abraão por pai; porque eu vos digo que até destas pedras pode Deus suscitar filhos a Abraão.',
        'E também já está posto o machado à raiz das árvores; toda a árvore, pois, que não dá bom fruto, corta-se e lança-se no fogo.',
        'E a multidão o interrogava, dizendo: Que faremos, pois?',
        'E, respondendo ele, disse-lhes: Quem tiver duas túnicas, reparta com o que não tem, e quem tiver alimentos, faça da mesma maneira.',
        'E chegaram também uns publicanos, para serem batizados, e disseram-lhe: Mestre, que devemos fazer?',
        'E ele lhes disse: Não peçais mais do que o que vos está ordenado.',
        'E uns soldados o interrogaram também, dizendo: E nós que faremos? E ele lhes disse: A ninguém trateis mal nem defraudeis, e contentai-vos com o vosso soldo.',
        'E, estando o povo em expectação, e pensando todos de João, em seus corações, se porventura seria o Cristo,',
        'Respondeu João a todos, dizendo: Eu, na verdade, batizo-vos com água, mas eis que vem aquele que é mais poderoso do que eu, do qual não sou digno de desatar a correia das alparcas; esse vos batizará com o Espírito Santo e com fogo.',
        'Ele tem a pá na sua mão; e limpará a sua eira, e ajuntará o trigo no seu celeiro, mas queimará a palha com fogo que nunca se apaga.',
        'E assim, admoestando-os, muitas outras coisas também anunciava ao povo.',
        'Sendo, porém, o tetrarca Herodes repreendido por ele por causa de Herodias, mulher de seu irmão Filipe, e por todas as maldades que Herodes tinha feito,',
        'Acrescentou a todas as outras ainda esta, a de encerrar João num cárcere.',
        'E aconteceu que, como todo o povo se batizava, sendo batizado também Jesus, orando ele, o céu se abriu;',
        'E o Espírito Santo desceu sobre ele em forma corpórea, como pomba; e ouviu-se uma voz do céu, que dizia: Tu és o meu Filho amado, em ti me comprazo.',
        'E o mesmo Jesus começava a ser de quase trinta anos, sendo (como se cuidava) filho de José, e José de Heli,',
        'E Heli de Matã, e Matã de Levi, e Levi de Melqui, e Melqui de Janai, e Janai de José,',
        'E José de Matatias, e Matatias de Amós, e Amós de Naum, e Naum de Esli, e Esli de Nagaí,',
        'E Nagaí de Máate, e Máate de Matatias, e Matatias de Semei, e Semei de José, e José de Jodá,',
        'E Jodá de Joanã, e Joanã de Resá, e Resá de Zorobabel, e Zorobabel de Salatiel, e Salatiel de Neri,',
        'E Neri de Melqui, e Melqui de Adi, e Adi de Cosã, e Cosã de Elmadã, e Elmadã de Er,',
        'E Er de Josué, e Josué de Eliézer, e Eliézer de Jorim, e Jorim de Matã, e Matã de Levi,',
        'E Levi de Simeão, e Simeão de Judá, e Judá de José, e José de Jonã, e Jonã de Eliaquim,',
        'E Eliaquim de Meleá, e Meleá de Mená, e Mená de Matatá, e Matatá de Natã, e Natã de Davi,',
        'E Davi de Jessé, e Jessé de Obede, e Obede de Boaz, e Boaz de Salá, e Salá de Naassom,',
        'E Naassom de Aminadabe, e Aminadabe de Arão, e Arão de Esrom, e Esrom Perez, e Perez de Judá,',
        'E Judá de Jacó, e Jacó de Isaque, e Isaque de Abraão, e Abraão de Terá, e Terá de Nacor,',
        'E Nacor de Seruque, e Seruque de Ragaú, e Ragaú de Fáleque, e Fáleque de Eber, e Eber de Salá,',
        'E Salá de Cainã, e Cainã de Arfaxade, e Arfaxade de Sem, e Sem de Noé, e Noé de Lameque,',
        'E Lameque de Matusalém, e Matusalém de Enoque, e Enoque de Jarete, e Jarete de Maleleel, e Maleleel de Cainã,',
        'E Cainã de Enos, e Enos de Sete, e Sete de Adão, e Adão de Deus.',
      ],
      [
        'E Jesus, cheio do Espírito Santo, voltou do Jordão e foi levado pelo Espírito ao deserto;',
        'E quarenta dias foi tentado pelo diabo, e naqueles dias não comeu coisa alguma; e, terminados eles, teve fome.',
        'E disse-lhe o diabo: Se tu és o Filho de Deus, dize a esta pedra que se transforme em pão.',
        'E Jesus lhe respondeu, dizendo: Está escrito que nem só de pão viverá o homem, mas de toda a palavra de Deus.',
        'E o diabo, levando-o a um alto monte, mostrou-lhe num momento de tempo todos os reinos do mundo.',
        'E disse-lhe o diabo: Dar-te-ei a ti todo este poder e a sua glória; porque a mim me foi entregue, e dou-o a quem quero.',
        'Portanto, se tu me adorares, tudo será teu.',
        'E Jesus, respondendo, disse-lhe: Vai-te para trás de mim, Satanás; porque está escrito: Adorarás o Senhor teu Deus, e só a ele servirás.',
        'Levou-o também a Jerusalém, e pô-lo sobre o pináculo do templo, e disse-lhe: Se tu és o Filho de Deus, lança-te daqui abaixo;',
        'Porque está escrito: Mandará aos seus anjos, acerca de ti, que te guardem,',
        'E que te sustenham nas mãos, Para que nunca tropeces com o teu pé em alguma pedra.',
        'E Jesus, respondendo, disse-lhe: Dito está: Não tentarás ao Senhor teu Deus.',
        'E, acabando o diabo toda a tentação, ausentou-se dele por algum tempo.',
        'Então, pela virtude do Espírito, voltou Jesus para a Galiléia, e a sua fama correu por todas as terras em derredor.',
        'E ensinava nas suas sinagogas, e por todos era louvado.',
        'E, chegando a Nazaré, onde fora criado, entrou num dia de sábado, segundo o seu costume, na sinagoga, e levantou-se para ler.',
        'E foi-lhe dado o livro do profeta Isaías; e, quando abriu o livro, achou o lugar em que estava escrito:',
        'O Espírito do Senhor é sobre mim, Pois que me ungiu para evangelizar os pobres. Enviou-me a curar os quebrantados de coração,',
        'A pregar liberdade aos cativos, E restauração da vista aos cegos, A pôr em liberdade os oprimidos, A anunciar o ano aceitável do Senhor.',
        'E, cerrando o livro, e tornando-o a dar ao ministro, assentou-se; e os olhos de todos na sinagoga estavam fitos nele.',
        'Então começou a dizer-lhes: Hoje se cumpriu esta Escritura em vossos ouvidos.',
        'E todos lhe davam testemunho, e se maravilhavam das palavras de graça que saíam da sua boca; e diziam: Não é este o filho de José?',
        'E ele lhes disse: Sem dúvida me direis este provérbio: Médico, cura-te a ti mesmo; faze também aqui na tua pátria tudo que ouvimos ter sido feito em Cafarnaum.',
        'E disse: Em verdade vos digo que nenhum profeta é bem recebido na sua pátria.',
        'Em verdade vos digo que muitas viúvas existiam em Israel nos dias de Elias, quando o céu se cerrou por três anos e seis meses, de sorte que em toda a terra houve grande fome;',
        'E a nenhuma delas foi enviado Elias, senão a Sarepta de Sidom, a uma mulher viúva.',
        'E muitos leprosos havia em Israel no tempo do profeta Eliseu, e nenhum deles foi purificado, senão Naamã, o siro.',
        'E todos, na sinagoga, ouvindo estas coisas, se encheram de ira.',
        'E, levantando-se, o expulsaram da cidade, e o levaram até ao cume do monte em que a cidade deles estava edificada, para dali o precipitarem.',
        'Ele, porém, passando pelo meio deles, retirou-se.',
        'E desceu a Cafarnaum, cidade da Galiléia, e os ensinava nos sábados.',
        'E admiravam a sua doutrina porque a sua palavra era com autoridade.',
        'E estava na sinagoga um homem que tinha o espírito de um demônio imundo, e exclamou em alta voz,',
        'Dizendo: Ah! que temos nós contigo, Jesus Nazareno? Vieste a destruir-nos? Bem sei quem és: O Santo de Deus.',
        'E Jesus o repreendeu, dizendo: Cala-te, e sai dele. E o demônio, lançando-o por terra no meio do povo, saiu dele sem lhe fazer mal.',
        'E veio espanto sobre todos, e falavam uns com os outros, dizendo: Que palavra é esta, que até aos espíritos imundos manda com autoridade e poder, e eles saem?',
        'E a sua fama divulgava-se por todos os lugares, em redor daquela comarca.',
        'Ora, levantando-se Jesus da sinagoga, entrou em casa de Simão; e a sogra de Simão estava enferma com muita febre, e rogaram-lhe por ela.',
        'E, inclinando-se para ela, repreendeu a febre, e esta a deixou. E ela, levantando-se logo, servia-os.',
        'E, ao pôr do sol, todos os que tinham enfermos de várias doenças lhos traziam; e, pondo as mãos sobre cada um deles, os curava.',
        'E também de muitos saíam demônios, clamando e dizendo: Tu és o Cristo, o Filho de Deus. E ele, repreendendo-os, não os deixava falar, pois sabiam que ele era o Cristo.',
        'E, sendo já dia, saiu, e foi para um lugar deserto; e a multidão o procurava, e chegou junto dele; e o detinham, para que não se ausentasse deles.',
        'Ele, porém, lhes disse: Também é necessário que eu anuncie a outras cidades o evangelho do reino de Deus; porque para isso fui enviado.',
        'E pregava nas sinagogas da Galiléia.',
      ],
      [
        'E aconteceu que, apertando-o a multidão, para ouvir a palavra de Deus, estava ele junto ao lago de Genesaré;',
        'E viu estar dois barcos junto à praia do lago; e os pescadores, havendo descido deles, estavam lavando as redes.',
        'E, entrando num dos barcos, que era o de Simão, pediu-lhe que o afastasse um pouco da terra; e, assentando-se, ensinava do barco a multidão.',
        'E, quando acabou de falar, disse a Simão: Faze-te ao mar alto, e lançai as vossas redes para pescar.',
        'E, respondendo Simão, disse-lhe: Mestre, havendo trabalhado toda a noite, nada apanhamos; mas, sobre a tua palavra, lançarei a rede.',
        'E, fazendo assim, colheram uma grande quantidade de peixes, e rompia-se-lhes a rede.',
        'E fizeram sinal aos companheiros que estavam no outro barco, para que os fossem ajudar. E foram, e encheram ambos os barcos, de maneira tal que quase iam a pique.',
        'E vendo isto Simão Pedro, prostrou-se aos pés de Jesus, dizendo: Senhor, ausenta-te de mim, que sou um homem pecador.',
        'Pois que o espanto se apoderara dele, e de todos os que com ele estavam, por causa da pesca de peixe que haviam feito.',
        'E, de igual modo, também de Tiago e João, filhos de Zebedeu, que eram companheiros de Simão. E disse Jesus a Simão: Não temas; de agora em diante serás pescador de homens.',
        'E, levando os barcos para terra, deixaram tudo, e o seguiram.',
        'E aconteceu que, quando estava numa daquelas cidades, eis que um homem cheio de lepra, vendo a Jesus, prostrou-se sobre o rosto, e rogou-lhe, dizendo: Senhor, se quiseres, bem podes limpar-me.',
        'E ele, estendendo a mão, tocou-lhe, dizendo: Quero, sê limpo. E logo a lepra desapareceu dele.',
        'E ordenou-lhe que a ninguém o dissesse. Mas vai, disse, mostra-te ao sacerdote, e oferece pela tua purificação, o que Moisés determinou para que lhes sirva de testemunho.',
        'A sua fama, porém, se propagava ainda mais, e ajuntava-se muita gente para o ouvir e para ser por ele curada das suas enfermidades.',
        'Ele, porém, retirava-se para os desertos, e ali orava.',
        'E aconteceu que, num daqueles dias, estava ensinando, e estavam ali assentados fariseus e doutores da lei, que tinham vindo de todas as aldeias da Galiléia, e da Judéia, e de Jerusalém. E a virtude do Senhor estava ali para os curar.',
        'E eis que uns homens transportaram numa cama um homem que estava paralítico, e procuravam fazê-lo entrar e pô-lo diante dele.',
        'E, não achando por onde o pudessem levar, por causa da multidão, subiram ao telhado, e por entre as telhas o baixaram com a cama, até ao meio, diante de Jesus.',
        'E, vendo ele a fé deles, disse-lhe: Homem, os teus pecados te são perdoados.',
        'E os escribas e os fariseus começaram a arrazoar, dizendo: Quem é este que diz blasfêmias? Quem pode perdoar pecados, senão só Deus?',
        'Jesus, porém, conhecendo os seus pensamentos, respondeu, e disse-lhes: Que arrazoais em vossos corações?',
        'Qual é mais fácil? dizer: Os teus pecados te são perdoados; ou dizer: Levanta-te, e anda?',
        'Ora, para que saibais que o Filho do homem tem sobre a terra poder de perdoar pecados (disse ao paralítico), a ti te digo: Levanta-te, toma a tua cama, e vai para tua casa.',
        'E, levantando-se logo diante deles, e tomando a cama em que estava deitado, foi para sua casa, glorificando a Deus.',
        'E todos ficaram maravilhados, e glorificaram a Deus; e ficaram cheios de temor, dizendo: Hoje vimos prodígios.',
        'E, depois disto, saiu, e viu um publicano, chamado Levi, assentado na recebedoria, e disse-lhe: Segue-me.',
        'E ele, deixando tudo, levantou-se e o seguiu.',
        'E fez-lhe Levi um grande banquete em sua casa; e havia ali uma multidão de publicanos e outros que estavam com eles à mesa.',
        'E os escribas deles, e os fariseus, murmuravam contra os seus discípulos, dizendo: Por que comeis e bebeis com publicanos e pecadores?',
        'E Jesus, respondendo, disse-lhes: Não necessitam de médico os que estão sãos, mas, sim, os que estão enfermos;',
        'Eu não vim chamar os justos, mas, sim, os pecadores, ao arrependimento.',
        'Disseram-lhe, então, eles: Por que jejuam os discípulos de João muitas vezes, e fazem orações, como também os dos fariseus, mas os teus comem e bebem?',
        'E ele lhes disse: Podeis vós fazer jejuar os filhos das bodas, enquanto o esposo está com eles?',
        'Dias virão, porém, em que o esposo lhes será tirado, e então, naqueles dias, jejuarão.',
        'E disse-lhes também uma parábola: Ninguém deita um pedaço de uma roupa nova para a coser em roupa velha, pois romperá a nova e o remendo não condiz com a velha.',
        'E ninguém deita vinho novo em odres velhos; de outra sorte o vinho novo romperá os odres, e entornar-se-á o vinho, e os odres se estragarão;',
        'Mas o vinho novo deve deitar-se em odres novos, e ambos juntamente se conservarão.',
        'E ninguém tendo bebido o velho quer logo o novo, porque diz: Melhor é o velho.',
      ],
      [
        'E aconteceu que, no segundo sábado após o primeiro, passou pelas searas, e os seus discípulos iam arrancando espigas e, esfregando-as com as mãos, as comiam.',
        'E alguns dos fariseus lhes disseram: Por que fazeis o que não é lícito fazer nos sábados?',
        'E Jesus, respondendo-lhes, disse: Nunca lestes o que fez Davi quando teve fome, ele e os que com ele estavam?',
        'Como entrou na casa de Deus, e tomou os pães da proposição, e os comeu, e deu também aos que estavam com ele, os quais não é lícito comer senão só aos sacerdotes?',
        'E dizia-lhes: O Filho do homem é Senhor até do sábado.',
        'E aconteceu também noutro sábado, que entrou na sinagoga, e estava ensinando; e havia ali um homem que tinha a mão direita mirrada.',
        'E os escribas e fariseus observavam-no, se o curaria no sábado, para acharem de que o acusar.',
        'Mas ele bem conhecia os seus pensamentos; e disse ao homem que tinha a mão mirrada: Levanta-te, e fica em pé no meio. E, levantando-se ele, ficou em pé.',
        'Então Jesus lhes disse: Uma coisa vos hei de perguntar: É lícito nos sábados fazer bem, ou fazer mal? salvar a vida, ou matar?',
        'E, olhando para todos em redor, disse ao homem: Estende a tua mão. E ele assim o fez, e a mão lhe foi restituída sã como a outra.',
        'E ficaram cheios de furor, e uns com os outros conferenciavam sobre o que fariam a Jesus.',
        'E aconteceu que naqueles dias subiu ao monte a orar, e passou a noite em oração a Deus.',
        'E, quando já era dia, chamou a si os seus discípulos, e escolheu doze deles, a quem também deu o nome de apóstolos:',
        'Simão, ao qual também chamou Pedro, e André, seu irmão; Tiago e João; Filipe e Bartolomeu;',
        'Mateus e Tomé; Tiago, filho de Alfeu, e Simão, chamado Zelote;',
        'E Judas, irmão de Tiago, e Judas Iscariotes, que foi o traidor.',
        'E, descendo com eles, parou num lugar plano, e também um grande número de seus discípulos, e grande multidão de povo de toda a Judéia, e de Jerusalém, e da costa marítima de Tiro e de Sidom; os quais tinham vindo para o ouvir, e serem curados das suas enfermidades,',
        'Como também os atormentados dos espíritos imundos; e eram curados.',
        'E toda a multidão procurava tocar-lhe, porque saía dele virtude, e curava a todos.',
        'E, levantando ele os olhos para os seus discípulos, dizia: Bem-aventurados vós, os pobres, porque vosso é o reino de Deus.',
        'Bem-aventurados vós, que agora tendes fome, porque sereis fartos. Bem-aventurados vós, que agora chorais, porque haveis de rir.',
        'Bem-aventurados sereis quando os homens vos odiarem e quando vos separarem, e vos injuriarem, e rejeitarem o vosso nome como mau, por causa do Filho do homem.',
        'Folgai nesse dia, exultai; porque eis que é grande o vosso galardão no céu, pois assim faziam os seus pais aos profetas.',
        'Mas ai de vós, ricos! porque já tendes a vossa consolação.',
        'Ai de vós, os que estais fartos, porque tereis fome. Ai de vós, os que agora rides, porque vos lamentareis e chorareis.',
        'Ai de vós quando todos os homens de vós disserem bem, porque assim faziam seus pais aos falsos profetas.',
        'Mas a vós, que isto ouvis, digo: Amai a vossos inimigos, fazei bem aos que vos odeiam;',
        'Bendizei os que vos maldizem, e orai pelos que vos caluniam.',
        'Ao que te ferir numa face, oferece-lhe também a outra; e ao que te houver tirado a capa, nem a túnica recuses;',
        'E dá a qualquer que te pedir; e ao que tomar o que é teu, não lho tornes a pedir.',
        'E como vós quereis que os homens vos façam, da mesma maneira lhes fazei vós, também.',
        'E se amardes aos que vos amam, que recompensa tereis? Também os pecadores amam aos que os amam.',
        'E se fizerdes bem aos que vos fazem bem, que recompensa tereis? Também os pecadores fazem o mesmo.',
        'E se emprestardes àqueles de quem esperais tornar a receber, que recompensa tereis? Também os pecadores emprestam aos pecadores, para tornarem a receber outro tanto.',
        'Amai, pois, a vossos inimigos, e fazei bem, e emprestai, sem nada esperardes, e será grande o vosso galardão, e sereis filhos do Altíssimo; porque ele é benigno até para com os ingratos e maus.',
        'Sede, pois, misericordiosos, como também vosso Pai é misericordioso.',
        'Não julgueis, e não sereis julgados; não condeneis, e não sereis condenados; soltai, e soltar-vos-ão.',
        'Dai, e ser-vos-á dado; boa medida, recalcada, sacudida e transbordando, vos deitarão no vosso regaço; porque com a mesma medida com que medirdes também vos medirão de novo.',
        'E dizia-lhes uma parábola: Pode porventura o cego guiar o cego? Não cairão ambos na cova?',
        'O discípulo não é superior a seu mestre, mas todo o que for perfeito será como o seu mestre.',
        'E por que atentas tu no argueiro que está no olho de teu irmão, e não reparas na trave que está no teu próprio olho?',
        'Ou como podes dizer a teu irmão: Irmão, deixa-me tirar o argueiro que está no teu olho, não atentando tu mesmo na trave que está no teu olho? Hipócrita, tira primeiro a trave do teu olho, e então verás bem para tirar o argueiro que está no olho de teu irmão.',
        'Porque não há boa árvore que dê mau fruto, nem má árvore que dê bom fruto.',
        'Porque cada árvore se conhece pelo seu próprio fruto; pois não se colhem figos dos espinheiros, nem se vindimam uvas dos abrolhos.',
        'O homem bom, do bom tesouro do seu coração tira o bem, e o homem mau, do mau tesouro do seu coração tira o mal, porque da abundância do seu coração fala a boca.',
        'E por que me chamais, Senhor, Senhor, e não fazeis o que eu digo?',
        'Qualquer que vem a mim e ouve as minhas palavras, e as observa, eu vos mostrarei a quem é semelhante:',
        'É semelhante ao homem que edificou uma casa, e cavou, e abriu bem fundo, e pôs os alicerces sobre a rocha; e, vindo a enchente, bateu com ímpeto a corrente naquela casa, e não a pôde abalar, porque estava fundada sobre a rocha.',
        'Mas o que ouve e não pratica é semelhante ao homem que edificou uma casa sobre terra, sem alicerces, na qual bateu com ímpeto a corrente, e logo caiu; e foi grande a ruína daquela casa.',
      ],
      [
        'E, depois de concluir todos estes discursos perante o povo, entrou em Cafarnaum.',
        'E o servo de um certo centurião, a quem muito estimava, estava doente, e moribundo.',
        'E, quando ouviu falar de Jesus, enviou-lhe uns anciãos dos judeus, rogando-lhe que viesse curar o seu servo.',
        'E, chegando eles junto de Jesus, rogaram-lhe muito, dizendo: É digno de que lhe concedas isto,',
        'Porque ama a nossa nação, e ele mesmo nos edificou a sinagoga.',
        'E foi Jesus com eles; mas, quando já estava perto da casa, enviou-lhe o centurião uns amigos, dizendo-lhe: Senhor, não te incomodes, porque não sou digno de que entres debaixo do meu telhado.',
        'E por isso nem ainda me julguei digno de ir ter contigo; dize, porém, uma palavra, e o meu criado sarará.',
        'Porque também eu sou homem sujeito à autoridade, e tenho soldados sob o meu poder, e digo a este: Vai, e ele vai; e a outro: Vem, e ele vem; e ao meu servo: Faze isto, e ele o faz.',
        'E, ouvindo isto Jesus, maravilhou-se dele, e voltando-se, disse à multidão que o seguia: Digo-vos que nem ainda em Israel tenho achado tanta fé.',
        'E, voltando para casa os que foram enviados, acharam são o servo enfermo.',
        'E aconteceu que, no dia seguinte, ele foi à cidade chamada Naim, e com ele iam muitos dos seus discípulos, e uma grande multidão;',
        'E, quando chegou perto da porta da cidade, eis que levavam um defunto, filho único de sua mãe, que era viúva; e com ela ia uma grande multidão da cidade.',
        'E, vendo-a, o Senhor moveu-se de íntima compaixão por ela, e disse-lhe: Não chores.',
        'E, chegando-se, tocou o esquife (e os que o levavam pararam), e disse: Jovem, a ti te digo: Levanta-te. E o que fora defunto assentou-se, e começou a falar.',
        'E entregou-o à sua mãe.',
        'E de todos se apoderou o temor, e glorificavam a Deus, dizendo: Um grande profeta se levantou entre nós, e Deus visitou o seu povo.',
        'E correu dele esta fama por toda a Judéia e por toda a terra circunvizinha.',
        'E os discípulos de João anunciaram-lhe todas estas coisas.',
        'E João, chamando dois dos seusdiscípulos, enviou-os a Jesus, dizendo: És tu aquele que havia de vir, ou esperamos outro?',
        'E, quando aqueles homens chegaram junto dele, disseram: João o Batista enviou-nos a perguntar-te: És tu aquele que havia de vir, ou esperamos outro?',
        'E, na mesma hora, curou muitos de enfermidades, e males, e espíritos maus, e deu vista a muitos cegos.',
        'Respondendo, então, Jesus, disse-lhes: Ide, e anunciai a João o que tendes visto e ouvido: que os cegos vêem, os coxos andam, os leprosos são purificados, os surdos ouvem, os mortos ressuscitam e aos pobres anuncia-se o evangelho.',
        'E bem-aventurado é aquele que em mim se não escandalizar.',
        'E, tendo-se retirado os mensageiros de João, começou a dizer à multidão acerca de João: Que saístes a ver no deserto? uma cana abalada pelo vento?',
        'Mas que saístes a ver? um homem trajado de vestes delicadas? Eis que os que andam com preciosas vestiduras, e em delícias, estão nos paços reais.',
        'Mas que saístes a ver? um profeta? Sim, vos digo, e muito mais do que profeta.',
        'Este é aquele de quem está escrito: Eis que envio o meu anjo diante da tua face, O qual preparará diante de ti o teu caminho.',
        'E eu vos digo que, entre os nascidos de mulheres, não há maior profeta do que João o Batista; mas o menor no reino de Deus é maior do que ele.',
        'E todo o povo que o ouviu e os publicanos, tendo sido batizados com o batismo de João, justificaram a Deus.',
        'Mas os fariseus e os doutores da lei rejeitaram o conselho de Deus contra si mesmos, não tendo sido batizados por ele.',
        'E disse o Senhor: A quem, pois, compararei os homens desta geração, e a quem são semelhantes?',
        'São semelhantes aos meninos que, assentados nas praças, clamam uns aos outros, e dizem: Tocamo-vos flauta, e não dançastes; cantamo-vos lamentações, e não chorastes.',
        'Porque veio João o Batista, que não comia pão nem bebia vinho, e dizeis: Tem demônio;',
        'Veio o Filho do homem, que come e bebe, e dizeis: Eis aí um homem comilão e bebedor de vinho, amigo dos publicanos e pecadores.',
        'Mas a sabedoria é justificada por todos os seus filhos.',
        'E rogou-lhe um dos fariseus que comesse com ele; e, entrando em casa do fariseu, assentou-se à mesa.',
        'E eis que uma mulher da cidade, uma pecadora, sabendo que ele estava à mesa em casa do fariseu, levou um vaso de alabastro com ungüento;',
        'E, estando por detrás, aos seus pés, chorando, começou a regar-lhe os pés com lágrimas, e enxugava-lhos com os cabelos da sua cabeça; e beijava-lhe os pés, e ungia-lhos com o ungüento.',
        'Quando isto viu o fariseu que o tinha convidado, falava consigo, dizendo: Se este fora profeta, bem saberia quem e qual é a mulher que lhe tocou, pois é uma pecadora.',
        'E respondendo, Jesus disse-lhe: Simão, uma coisa tenho a dizer-te. E ele disse: Dize-a, Mestre.',
        'Um certo credor tinha dois devedores: um devia-lhe quinhentos dinheiros, e outro cinqüenta.',
        'E, não tendo eles com que pagar, perdoou-lhes a ambos. Dize, pois, qual deles o amará mais?',
        'E Simão, respondendo, disse: Tenho para mim que é aquele a quem mais perdoou. E ele lhe disse: Julgaste bem.',
        'E, voltando-se para a mulher, disse a Simão: Vês tu esta mulher? Entrei em tua casa, e não me deste água para os pés; mas esta regou-me os pés com lágrimas, e os enxugou com os cabelos de sua cabeça.',
        'Não me deste ósculo, mas esta, desde que entrou, não tem cessado de me beijar os pés.',
        'Não me ungiste a cabeça com óleo, mas esta ungiu-me os pés com ungüento.',
        'Por isso te digo que os seus muitos pecados lhe são perdoados, porque muito amou; mas aquele a quem pouco é perdoado pouco ama.',
        'E disse-lhe a ela: Os teus pecados te são perdoados.',
        'E os que estavam à mesa começaram a dizer entre si: Quem é este, que até perdoa pecados?',
        'E disse à mulher: A tua fé te salvou; vai-te em paz.',
      ],
      [
        'E aconteceu, depois disto, que andava de cidade em cidade, e de aldeia em aldeia, pregando e anunciando o evangelho do reino de Deus; e os doze iam com ele,',
        'E algumas mulheres que haviam sido curadas de espíritos malignos e de enfermidades: Maria, chamada Madalena, da qual saíram sete demônios;',
        'E Joana, mulher de Cuza, procurador de Herodes, e Suzana, e muitas outras que o serviam com seus bens.',
        'E, ajuntando-se uma grande multidão, e vindo de todas as cidades ter com ele, disse por parábola:',
        'Um semeador saiu a semear a sua semente e, quando semeava, caiu alguma junto do caminho, e foi pisada, e as aves do céu a comeram;',
        'E outra caiu sobre pedra e, nascida, secou-se, pois que não tinha umidade;',
        'E outra caiu entre espinhos e crescendo com ela os espinhos, a sufocaram;',
        'E outra caiu em boa terra, e, nascida, produziu fruto, a cento por um. Dizendo ele estas coisas, clamava: Quem tem ouvidos para ouvir, ouça.',
        'E os seus discípulos o interrogaram, dizendo: Que parábola é esta?',
        'E ele disse: A vós vos é dado conhecer os mistérios do reino de Deus, mas aos outros por parábolas, para que vendo, não vejam, e ouvindo, não entendam.',
        'Esta é, pois, a parábola: A semente é a palavra de Deus;',
        'E os que estão junto do caminho, estes são os que ouvem; depois vem o diabo, e tira-lhes do coração a palavra, para que não se salvem, crendo;',
        'E os que estão sobre pedra, estes são os que, ouvindo a palavra, a recebem com alegria, mas, como não têm raiz, apenas crêem por algum tempo, e no tempo da tentação se desviam;',
        'E a que caiu entre espinhos, esses são os que ouviram e, indo pordiante, são sufocados com os cuidados e riquezas e deleites da vida, e não dão fruto com perfeição;',
        'E a que caiu em boa terra, esses são os que, ouvindo a palavra, a conservam num coração honesto e bom, e dão fruto com perseverança.',
        'E ninguém, acendendo uma candeia, a cobre com algum vaso, ou a põe debaixo da cama; mas põe-na no velador, para que os que entram vejam a luz.',
        'Porque não há coisa oculta que não haja de manifestar-se, nem escondida que não haja de saber-se e vir à luz.',
        'Vede, pois, como ouvis; porque a qualquer que tiver lhe será dado, e a qualquer que não tiver até o que parece ter lhe será tirado.',
        'E foram ter com ele sua mãe e seus irmãos, e não podiam aproximar-se dele, por causa da multidão.',
        'E foi-lhe dito: Estão lá fora tua mãe e teus irmãos, que querem ver-te.',
        'Mas, respondendo ele, disse-lhes: Minha mãe e meus irmãos são aqueles que ouvem a palavra de Deus e a executam.',
        'E aconteceu que, num daqueles dias, entrou num barco com seus discípulos, e disse-lhes: Passemos para o outro lado do lago. E partiram.',
        'E, navegando eles, adormeceu; e sobreveio uma tempestade de vento no lago, e enchiam-se de água, estando em perigo.',
        'E, chegando-se a ele, o despertaram, dizendo: Mestre, Mestre, perecemos. E ele, levantando-se, repreendeu o vento e a fúria da água; e cessaram, e fez-se bonança.',
        'E disse-lhes: Onde está a vossa fé? E eles, temendo, maravilharam-se, dizendo uns aos outros: Quem é este, que até aos ventos e à água manda, e lhe obedecem?',
        'E navegaram para a terra dos gadarenos, que está defronte da Galiléia.',
        'E, quando desceu para terra, saiu-lhe ao encontro, vindo da cidade, um homem que desde muito tempo estava possesso de demônios, e não andava vestido, nem habitava em qualquer casa, mas nos sepulcros.',
        'E, quando viu a Jesus, prostrou-se diante dele, exclamando, e dizendo com grande voz: Que tenho eu contigo, Jesus, Filho do Deus Altíssimo? Peço-te que não me atormentes.',
        'Porque tinha ordenado ao espírito imundo que saísse daquele homem; pois já havia muito tempo que o arrebatava. E guardavamno preso, com grilhões e cadeias; mas, quebrando as prisões, era impelido pelo demônio para os desertos.',
        'E perguntou-lhe Jesus, dizendo: Qual é o teu nome? E ele disse: Legião; porque tinham entrado nele muitos demônios.',
        'E rogavam-lhe que os não mandasse para o abismo.',
        'E andava ali pastando no monte uma vara de muitos porcos; e rogaram-lhe que lhes concedesse entrar neles; e concedeu-lho.',
        'E, tendo saído os demônios do homem, entraram nos porcos, e a manada precipitou-se de um despenhadeiro no lago, e afogou-se.',
        'E aqueles que os guardavam, vendo o que acontecera, fugiram, e foram anunciá-lo na cidade e nos campos.',
        'E saíram a ver o que tinha acontecido, e vieram ter com Jesus. Acharam então o homem, de quem haviam saído os demônios, vestido, e em seu juízo, assentado aos pés de Jesus; e temeram.',
        'E os que tinham visto contaram-lhes também como fora salvo aquele endemoninhado.',
        'E toda a multidão da terra dos gadarenos ao redor lhe rogou que se retirasse deles; porque estavam possuídos de grande temor. E entrando ele no barco, voltou.',
        'E aquele homem, de quem haviam saído os demônios, rogou-lhe que o deixasse estar com ele; mas Jesus o despediu, dizendo:',
        'Torna para tua casa, e conta quão grandes coisas te fez Deus. E ele foi apregoando por toda a cidade quão grandes coisas Jesus lhe tinha feito.',
        'E aconteceu que, quando voltou Jesus, a multidão o recebeu, porque todos o estavam esperando.',
        'E eis que chegou um homem de nome Jairo, que era príncipe da sinagoga; e, prostrando-se aos pés de Jesus, rogava-lhe que entrasse em sua casa;',
        'Porque tinha uma filha única, quase de doze anos, que estava à morte. E indo ele, apertava-o a multidão.',
        'E uma mulher, que tinha um fluxo de sangue, havia doze anos, e gastara com os médicos todos os seus haveres, e por nenhum pudera ser curada,',
        'Chegando por detrás dele, tocou na orla do seu vestido, e logo estancou o fluxo do seu sangue.',
        'E disse Jesus: Quem é que me tocou? E, negando todos, disse Pedro e os que estavam com ele: Mestre, a multidão te aperta e te oprime, e dizes: Quem é que me tocou?',
        'E disse Jesus: Alguém me tocou, porque bem conheci que de mim saiu virtude.',
        'Então, vendo a mulher que não podia ocultar-se, aproximou-se tremendo e, prostrando-se ante ele, declarou-lhe diante de todo o povo a causa por que lhe havia tocado, e como logo sarara.',
        'E ele lhe disse: Tem bom ânimo, filha, a tua fé te salvou; vai em paz.',
        'Estando ele ainda falando, chegou um dos do príncipe da sinagoga, dizendo: A tua filha já está morta, não incomodes o Mestre.',
        'Jesus, porém, ouvindo-o, respondeu-lhe, dizendo: Não temas; crê somente, e será salva.',
        'E, entrando em casa, a ninguém deixou entrar, senão a Pedro, e a Tiago, e a João, e ao pai e a mãe da menina.',
        'E todos choravam, e a pranteavam; e ele disse: Não choreis; não está morta, mas dorme.',
        'E riam-se dele, sabendo que estava morta.',
        'Mas ele, pondo-os todos fora, e pegando-lhe na mão, clamou, dizendo: Levanta-te, menina.',
        'E o seu espírito voltou, e ela logo se levantou; e Jesus mandou que lhe dessem de comer.',
        'E seus pais ficaram maravilhados; e ele lhes mandou que a ninguém dissessem o que havia sucedido.',
      ],
      [
        'E, convocando os seus doze discípulos, deu-lhes virtude e poder sobre todos os demônios, para curarem enfermidades.',
        'E enviou-os a pregar o reino de Deus, e a curar os enfermos.',
        'E disse-lhes: Nada leveis convosco para o caminho, nem bordões, nem alforje, nem pão, nem dinheiro; nem tenhais duas túnicas.',
        'E em qualquer casa em que entrardes, ficai ali, e de lá saireis.',
        'E se em qualquer cidade vos não receberem, saindo vós dali, sacudi o pó dos vossos pés, em testemunho contra eles.',
        'E, saindo eles, percorreram todas as aldeias, anunciando o evangelho, e fazendo curas por toda a parte.',
        'E o tetrarca Herodes ouviu todas as coisas que por ele foram feitas, e estava em dúvida, porque diziam alguns que João ressuscitara dentre os mortos; e outros que Elias tinha aparecido;',
        'E outros que um profeta dos antigos havia ressuscitado.',
        'E disse Herodes: A João mandei eu degolar; quem é, pois, este de quem ouço dizer tais coisas? E procurava vê-lo.',
        'E, regressando os apóstolos, contaram-lhe tudo o que tinham feito. E, tomando-os consigo, retirou-se para um lugar deserto de uma cidade chamada Betsaida.',
        'E, sabendo-o a multidão, o seguiu; e ele os recebeu, e falava-lhes do reino de Deus, e sarava os que necessitavam de cura.',
        'E já o dia começava a declinar; então, chegando-se a ele os doze, disseram-lhe: Despede a multidão, para que, indo aos lugares e aldeias em redor, se agasalhem, e achem o que comer; porque aqui estamos em lugar deserto.',
        'Mas ele lhes disse: Dai-lhes vós de comer. E eles disseram: Não temos senão cinco pães e dois peixes, salvo se nós próprios formos comprar comida para todo este povo.',
        'Porquanto estavam ali quase cinco mil homens. Disse, então, aos seus discípulos: Fazei-os assentar, em ranchos de cinqüenta em cinqüenta.',
        'E assim o fizeram, fazendo-os assentar a todos.',
        'E, tomando os cinco pães e os dois peixes, e olhando para o céu, abençoou-os, e partiu-os, e deu-os aos seus discípulos para os porem diante da multidão.',
        'E comeram todos, e saciaram-se; e levantaram, do que lhes sobejou, doze alcofas de pedaços.',
        'E aconteceu que, estando ele só, orando, estavam com ele os discípulos; e perguntou-lhes, dizendo: Quem diz a multidão que eu sou?',
        'E, respondendo eles, disseram: João o Batista; outros, Elias, e outros que um dos antigos profetas ressuscitou.',
        'E disse-lhes: E vós, quem dizeis que eu sou? E, respondendo Pedro, disse: O Cristo de Deus.',
        'E, admoestando-os, mandou que a ninguém referissem isso,',
        'Dizendo: É necessário que o Filho do homem padeça muitas coisas, e seja rejeitado dos anciãos edos escribas, e seja morto, e ressuscite ao terceiro dia.',
        'E dizia a todos: Se alguém quer vir após mim, negue-se a si mesmo, e tome cada dia a sua cruz, e siga-me.',
        'Porque, qualquer que quiser salvar a sua vida, perdê-la-á; mas qualquer que, por amor de mim, perder a sua vida, a salvará.',
        'Porque, que aproveita ao homem granjear o mundo todo, perdendo-se ou prejudicando-se a si mesmo?',
        'Porque, qualquer que de mim e das minhas palavras se envergonhar, dele se envergonhará o Filho do homem, quando vier na sua glória, e na do Pai e dos santos anjos.',
        'E em verdade vos digo que, dos que aqui estão, alguns há que não provarão a morte até que vejam o reino de Deus.',
        'E aconteceu que, quase oito dias depois destas palavras, tomou consigo a Pedro, a João e a Tiago, e subiu ao monte a orar.',
        'E, estando ele orando, transfigurou-se a aparência do seu rosto, e a sua roupa ficou branca e mui resplandecente.',
        'E eis que estavam falando com ele dois homens, que eram Moisés e Elias,',
        'Os quais apareceram com glória, e falavam da sua morte, a qual havia de cumprir-se em Jerusalém.',
        'E Pedro e os que estavam com ele estavam carregados de sono; e, quando despertaram, viram a sua glória e aqueles dois homens que estavam com ele.',
        'E aconteceu que, quando aqueles se apartaram dele, disse Pedro a Jesus: Mestre, bom é que nós estejamos aqui, e façamos três tendas: uma para ti, uma para Moisés, e uma para Elias, não sabendo o que dizia.',
        'E, dizendo ele isto, veio uma nuvem que os cobriu com a sua sombra; e, entrando eles na nuvem, temeram.',
        'E saiu da nuvem uma voz que dizia: Este é o meu amado Filho; a ele ouvi.',
        'E, tendo soado aquela voz, Jesus foi achado só; e eles calaram-se, e por aqueles dias não contaram a ninguém nada do que tinham visto.',
        'E aconteceu, no dia seguinte, que, descendo eles do monte, lhes saiu ao encontro uma grande multidão;',
        'E eis que um homem da multidão clamou, dizendo: Mestre, peço-te que olhes para meu filho, porque é o único que eu tenho.',
        'Eis que um espírito o toma e de repente clama, e o despedaça até espumar; e só o larga depois de o ter quebrantado.',
        'E roguei aos teus discípulos que o expulsassem, e não puderam.',
        'E Jesus, respondendo, disse: Ó geração incrédula e perversa! até quando estarei ainda convosco e vos sofrerei? Traze-me aqui o teu filho.',
        'E, quando vinha chegando, o demônio o derrubou e convulsionou; porém, Jesus repreendeu o espírito imundo, e curou o menino, e o entregou a seu pai.',
        'E todos pasmavam da majestade de Deus. E, maravilhando-se todos de todas as coisas que Jesus fazia, disse aos seus discípulos:',
        'Ponde vós estas palavras em vossos ouvidos, porque o Filho do homem será entregue nas mãos dos homens.',
        'Mas eles não entendiam esta palavra, que lhes era encoberta, para que a não compreendessem; e temiam interrogá-lo acerca desta palavra.',
        'E suscitou-se entre eles uma discussão sobre qual deles seria o maior.',
        'Mas Jesus, vendo o pensamento de seus corações, tomou um menino, pô-lo junto a si,',
        'E disse-lhes: Qualquer que receber este menino em meu nome, recebe-me a mim; e qualquer que me receber a mim, recebe o que me enviou; porque aquele que entre vós todos for o menor, esse mesmo será grande.',
        'E, respondendo João, disse: Mestre, vimos um que em teu nome expulsava os demônios, e lho proibimos, porque não te segue conosco.',
        'E Jesus lhe disse: Não o proibais, porque quem não é contra nós é por nós.',
        'E aconteceu que, completando-se os dias para a sua assunção, manifestou o firme propósito de ir a Jerusalém.',
        'E mandou mensageiros adiante de si; e, indo eles, entraram numa aldeia de samaritanos, para lhe prepararem pousada,',
        'Mas não o receberam, porque o seu aspecto era como de quem ia a Jerusalém.',
        'E os seus discípulos, Tiago e João, vendo isto, disseram: Senhor, queres que digamos que desça fogo do céu e os consuma, como Elias também fez?',
        'Voltando-se, porém, repreendeu-os, e disse: Vós não sabeis de que espírito sois.',
        'Porque o Filho do homem não veio para destruir as almas dos homens, mas para salvá-las. E foram para outra aldeia.',
        'E aconteceu que, indo eles pelo caminho, lhe disse um: Senhor, seguir-te-ei para onde quer que fores.',
        'E disse-lhe Jesus: As raposas têm covis, e as aves do céu, ninhos, mas o Filho do homem não tem onde reclinar a cabeça.',
        'E disse a outro: Segue-me. Mas ele respondeu: Senhor, deixa que primeiro eu vá a enterrar meu pai.',
        'Mas Jesus lhe observou: Deixa aos mortos o enterrar os seus mortos; porém tu vai e anuncia o reino de Deus.',
        'Disse também outro: Senhor, eu te seguirei, mas deixa-me despedir primeiro dos que estão em minha casa.',
        'E Jesus lhe disse: Ninguém, que lança mão do arado e olha para trás, é apto para o reino de Deus.',
      ],
      [
        'E depois disto designou o Senhor ainda outros setenta, e mandou-os adiante da sua face, de dois em dois, a todas as cidades e lugares aonde ele havia de ir.',
        'E dizia-lhes: Grande é, em verdade, a seara, mas os obreiros são poucos; rogai, pois, ao Senhor da seara que envie obreiros para a sua seara.',
        'Ide; eis que vos mando como cordeiros ao meio de lobos.',
        'Não leveis bolsa, nem alforje, nem alparcas; e a ninguém saudeis pelo caminho.',
        'E, em qualquer casa onde entrardes, dizei primeiro: Paz seja nesta casa.',
        'E, se ali houver algum filho de paz, repousará sobre ele a vossa paz; e, se não, voltará para vós.',
        'E ficai na mesma casa, comendo e bebendo do que eles tiverem, pois digno é o obreiro de seu salário. Não andeis de casa em casa.',
        'E, em qualquer cidade em que entrardes, e vos receberem, comei do que vos for oferecido.',
        'E curai os enfermos que nela houver, e dizei-lhes: É chegado a vós o reino de Deus.',
        'Mas em qualquer cidade, em que entrardes e vos não receberem, saindo por suas ruas, dizei:',
        'Até o pó, que da vossa cidade se nos pegou, sacudimos sobre vós. Sabei, contudo, isto, que já o reino de Deus é chegado a vós.',
        'E digo-vos que mais tolerância haverá naquele dia para Sodoma do que para aquela cidade.',
        'Ai de ti, Corazim, ai de ti, Betsaida! Porque, se em Tiro e em Sidom se fizessem as maravilhas que em vós foram feitas, já há muito, assentadas em saco e cinza, se teriam arrependido.',
        'Portanto, para Tiro e Sidom haverá menos rigor, no juízo, do que para vós.',
        'E tu, Cafarnaum, que te levantaste até ao céu, até ao inferno serás abatida.',
        'Quem vos ouve a vós, a mim me ouve; e quem vos rejeita a vós, a mim me rejeita; e quem a mim me rejeita, rejeita aquele que me enviou.',
        'E voltaram os setenta com alegria, dizendo: Senhor, pelo teu nome, até os demônios se nos sujeitam.',
        'E disse-lhes: Eu via Satanás, como raio, cair do céu.',
        'Eis que vos dou poder para pisar serpentes e escorpiões, e toda a força do inimigo, e nada vos fará dano algum.',
        'Mas, não vos alegreis porque se vos sujeitem os espíritos; alegrai-vos antes por estarem os vossos nomes escritos nos céus.',
        'Naquela mesma hora se alegrou Jesus no Espírito Santo, e disse: Graças te dou, ó Pai, Senhor do céu e da terra, que escondeste estas coisas aos sábios e inteligentes, e as revelaste às criancinhas; assim é, ó Pai, porque assim te aprouve.',
        'Tudo por meu Pai foi entregue; e ninguém conhece quem é o Filho senão o Pai, nem quem é o Pai senão o Filho, e aquele a quem o Filho o quiser revelar.',
        'E, voltando-se para os discípulos, disse-lhes em particular: Bem-aventurados os olhos que vêem o que vós vedes.',
        'Pois vos digo que muitos profetas e reis desejaram ver o que vós vedes, e não o viram; e ouvir o que ouvis, e não o ouviram.',
        'E eis que se levantou um certo doutor da lei, tentando-o, e dizendo: Mestre, que farei para herdar a vida eterna?',
        'E ele lhe disse: Que está escrito na lei? Como lês?',
        'E, respondendo ele, disse: Amarás ao Senhor teu Deus de todo o teu coração, e de toda a tua alma, e de todas as tuas forças, e de todo o teu entendimento, e ao teu próximo como a ti mesmo.',
        'E disse-lhe: Respondeste bem; faze isso, e viverás.',
        'Ele, porém, querendo justificar-se a si mesmo, disse a Jesus: E quem é o meu próximo?',
        'E, respondendo Jesus, disse: Descia um homem de Jerusalém para Jericó, e caiu nas mãos dos salteadores, os quais o despojaram, e espancando-o, se retiraram, deixando-o meio morto.',
        'E, ocasionalmente descia pelo mesmo caminho certo sacerdote; e, vendo-o, passou de largo.',
        'E de igual modo também um levita, chegando àquele lugar, e, vendo-o, passou de largo.',
        'Mas um samaritano, que ia de viagem, chegou ao pé dele e, vendo-o, moveu-se de íntima compaixão;',
        'E, aproximando-se, atou-lhe as feridas, deitando-lhes azeite e vinho; e, pondo-o sobre o seu animal, levou-o para uma estalagem, e cuidou dele;',
        'E, partindo no outro dia, tirou dois dinheiros, e deu-os ao hospedeiro, e disse-lhe: Cuida dele; e tudo o que de mais gastares eu to pagarei quando voltar.',
        'Qual, pois, destes três te parece que foi o próximo daquele que caiu nas mãos dos salteadores?',
        'E ele disse: O que usou de misericórdia para com ele. Disse, pois, Jesus: Vai, e faze da mesma maneira.',
        'E aconteceu que, indo eles de caminho, entrou Jesus numa aldeia; e certa mulher, por nome Marta, o recebeu em sua casa;',
        'E tinha esta uma irmã chamada Maria, a qual, assentando-se também aos pés de Jesus, ouvia a sua palavra.',
        'Marta, porém, andava distraída em muitos serviços; e, aproximando-se, disse: Senhor, não se te dá de que minha irmã me deixe servir só? Dize-lhe que me ajude.',
        'E respondendo Jesus, disse-lhe: Marta, Marta, estás ansiosa e afadigada com muitas coisas, mas uma só é necessária;',
        'E Maria escolheu a boa parte, a qual não lhe será tirada.',
      ],
      [
        'E aconteceu que, estando ele a orar num certo lugar, quando acabou, lhe disse um dos seus discípulos: Senhor, ensina-nos a orar, como também João ensinou aos seus discípulos.',
        'E ele lhes disse: Quando orardes, dizei: Pai nosso, que estás nos céus, santificado seja o teu nome; venha o teu reino; seja feita a tua vontade, assim na terra, como no céu.',
        'Dá-nos cada dia o nosso pão cotidiano;',
        'E perdoa-nos os nossos pecados, pois também nós perdoamos a qualquer que nos deve, e não nos conduzas à tentação, mas livra-nos do mal.',
        'Disse-lhes também: Qual de vós terá um amigo, e, se for procurá-lo à meia-noite, e lhe disser: Amigo, empresta-me três pães,',
        'Pois que um amigo meu chegou a minha casa, vindo de caminho, e não tenho que apresentar-lhe;',
        'Se ele, respondendo de dentro, disser: Não me importunes; já está a porta fechada, e os meus filhos estão comigo na cama; não posso levantar-me para tos dar;',
        'Digo-vos que, ainda que não se levante a dar-lhos, por ser seu amigo, levantar-se-á, todavia, por causa da sua importunação, e lhe dará tudo o que houver mister.',
        'E eu vos digo a vós: Pedi, e dar-se-vos-á; buscai, e achareis; batei, e abrir-se-vos-á;',
        'Porque qualquer que pede recebe; e quem busca acha; e a quem bate abrir-se-lhe-á.',
        'E qual o pai de entre vós que, se o filho lhe pedir pão, lhe dará uma pedra? Ou, também, se lhe pedir peixe, lhe dará por peixe uma serpente?',
        'Ou, também, se lhe pedir um ovo, lhe dará um escorpião?',
        'Pois se vós, sendo maus, sabeis dar boas dádivas aos vossos filhos, quanto mais dará o Pai celestial o Espírito Santo àqueles que lho pedirem?',
        'E estava ele expulsando um demônio, o qual era mudo. E aconteceu que, saindo o demônio, o mudo falou; e maravilhou-se a multidão.',
        'Mas alguns deles diziam: Ele expulsa os demônios por Belzebu, príncipe dos demônios.',
        'E outros, tentando-o, pediam-lhe um sinal do céu.',
        'Mas, conhecendo ele os seus pensamentos, disse-lhes: Todo o reino, dividido contra si mesmo, será assolado; e a casa, dividida contra si mesma, cairá.',
        'E, se também Satanás está dividido contra si mesmo, como subsistirá o seu reino? Pois dizeis que eu expulso os demônios por Belzebu.',
        'E, se eu expulso os demônios por Belzebu, por quem os expulsam vossos filhos? Eles, pois, serão os vossos juízes.',
        'Mas, se eu expulso os demônios pelo dedo de Deus, certamente a vós é chegado o reino de Deus.',
        'Quando o valente guarda, armado, a sua casa, em segurança está tudo quanto tem;',
        'Mas, sobrevindo outro mais valente do que ele, e vencendo-o, tira-lhe toda a sua armadura em que confiava, e reparte os seus despojos.',
        'Quem não é comigo é contra mim; e quem comigo não ajunta, espalha.',
        'Quando o espírito imundo tem saído do homem, anda por lugares secos, buscando repouso; e, não o achando, diz: Tornarei para minha casa, de onde saí.',
        'E, chegando, acha-a varrida e adornada.',
        'Então vai, e leva consigo outros sete espíritos piores do que ele e, entrando, habitam ali; e o último estado desse homem é pior do que o primeiro.',
        'E aconteceu que, dizendo ele estas coisas, uma mulher dentre a multidão, levantando a voz, lhe disse: Bem-aventurado o ventre que te trouxe e os peitos em que mamaste.',
        'Mas ele disse: Antes bemaventurados os que ouvem a palavra de Deus e a guardam.',
        'E, ajuntando-se a multidão, começou a dizer: Maligna é esta geração; ela pede um sinal; e não lhe será dado outro sinal, senão o sinal do profeta Jonas;',
        'Porquanto, assim como Jonas foi sinal para os ninivitas, assim o Filho do homem o será também para esta geração.',
        'A rainha do sul se levantará no juízo com os homens desta geração, e os condenará; pois até dos confins da terra veio ouvir a sabedoria de Salomão; e eis aqui está quem é maior do que Salomão.',
        'Os homens de Nínive se levantarão no juízo com esta geração, e a condenarão; pois se converteram com a pregação de Jonas; e eis aqui está quem é maior do que Jonas.',
        'E ninguém, acendendo uma candeia, a põe em oculto, nem debaixo do alqueire, mas no velador, para que os que entram vejam a luz.',
        'A candeia do corpo é o olho. Sendo, pois, o teu olho simples, também todo o teu corpo será luminoso; mas, se for mau, também o teu corpo será tenebroso.',
        'Vê, pois, que a luz que em ti há não sejam trevas.',
        'Se, pois, todo o teu corpo é luminoso, não tendo em trevas parte alguma, todo será luminoso, como quando a candeia te ilumina com o seu resplendor.',
        'E, estando ele ainda falando, rogou-lhe um fariseu que fosse jantar com ele; e, entrando, assentou-se à mesa.',
        'Mas o fariseu admirou-se, vendo que não se lavara antes de jantar.',
        'E o Senhor lhe disse: Agora vós, os fariseus, limpais o exterior do copo e do prato; mas o vosso interior está cheio de rapina e maldade.',
        'Loucos! Quem fez o exterior não fez também o interior?',
        'Antes dai esmola do que tiverdes, e eis que tudo vos será limpo.',
        'Mas ai de vós, fariseus, que dizimais a hortelã, e a arruda, e toda a hortaliça, e desprezais o juízo e o amor de Deus. Importava fazer estas coisas, e não deixar as outras.',
        'Ai de vós, fariseus, que amais os primeiros assentos nas sinagogas, e as saudações nas praças.',
        'Ai de vós, escribas e fariseus, hipócritas! que sois como as sepulturas que não aparecem, e os homens que sobre elas andam não o sabem.',
        'E, respondendo um dos doutores da lei, disse-lhe: Mestre, quando dizes isso, também nos afrontas a nós.',
        'E ele lhe disse: Ai de vós também, doutores da lei, que carregais os homens com cargas difíceis de transportar, e vós mesmos nem ainda com um dos vossos dedos tocais essas cargas.',
        'Ai de vós que edificais os sepulcros dos profetas, e vossos pais os mataram.',
        'Bem testificais, pois, que consentis nas obras de vossos pais; porque eles os mataram, e vós edificais os seus sepulcros.',
        'Por isso diz também a sabedoria de Deus: Profetas e apóstolos lhes mandarei; e eles matarão uns, e perseguirão outros;',
        'Para que desta geração seja requerido o sangue de todos os profetas que, desde a fundação do mundo, foi derramado;',
        'Desde o sangue de Abel, até ao sangue de Zacarias, que foi morto entre o altar e o templo; assim, vos digo, será requerido desta geração.',
        'Ai de vós, doutores da lei, que tirastes a chave da ciência; vós mesmos não entrastes, e impedistes os que entravam.',
        'E, dizendo-lhes ele isto, começaram os escribas e os fariseus a apertá-lo fortemente, e a fazê-lo falar acerca de muitas coisas,',
        'Armando-lhe ciladas, e procurando apanhar da sua boca alguma coisa para o acusarem.',
      ],
      [
        'Ajuntando-se entretanto muitos milhares de pessoas, de sorte que se atropelavam uns aos outros, começou a dizer aos seus discípulos: Acautelai-vos primeiramente do fermento dos fariseus, que é a hipocrisia.',
        'Mas nada há encoberto que não haja de ser descoberto; nem oculto, que não haja de ser sabido.',
        'Porquanto tudo o que em trevas dissestes, à luz será ouvido; e o que falastes ao ouvido no gabinete, sobre os telhados será apregoado.',
        'E digo-vos, amigos meus: Não temais os que matam o corpo e, depois, não têm mais que fazer.',
        'Mas eu vos mostrarei a quem deveis temer; temei aquele que, depois de matar, tem poder para lançar no inferno; sim, vos digo, a esse temei.',
        'Não se vendem cinco passarinhos por dois ceitis? E nenhum deles está esquecido diante de Deus.',
        'E até os cabelos da vossa cabeça estão todos contados. Não temais pois; mais valeis vós do que muitos passarinhos.',
        'E digo-vos que todo aquele que me confessar diante dos homens também o Filho do homem o confessará diante dos anjos de Deus.',
        'Mas quem me negar diante dos homens será negado diante dos anjos de Deus.',
        'E a todo aquele que disser uma palavra contra o Filho do homem ser-lhe-á perdoada, mas ao que blasfemar contra o Espírito Santo não lhe será perdoado.',
        'E, quando vos conduzirem às sinagogas, aos magistrados e potestades, não estejais solícitos de como ou do que haveis de responder, nem do que haveis de dizer.',
        'Porque na mesma hora vos ensinará o Espírito Santo o que vos convenha falar.',
        'E disse-lhe um da multidão: Mestre, dize a meu irmão que reparta comigo a herança.',
        'Mas ele lhe disse: Homem, quem me pôs a mim por juiz ou repartidor entre vós?',
        'E disse-lhes: Acautelai-vos e guardai-vos da avareza; porque a vida de qualquer não consiste na abundância do que possui.',
        'E propôs-lhe uma parábola, dizendo: A herdade de um homem rico tinha produzido com abundância;',
        'E arrazoava ele entre si, dizendo: Que farei? Não tenho onde recolher os meus frutos.',
        'E disse: Farei isto: Derrubarei os meus celeiros, e edificarei outros maiores, e ali recolherei todas as minhas novidades e os meus bens;',
        'E direi a minha alma: Alma, tens em depósito muitos bens para muitos anos; descansa, come, bebe e folga.',
        'Mas Deus lhe disse: Louco! esta noite te pedirão a tua alma; e o que tens preparado, para quem será?',
        'Assim é aquele que para si ajunta tesouros, e não é rico para com Deus.',
        'E disse aos seus discípulos: Portanto vos digo: Não estejais apreensivos pela vossa vida, sobre o que comereis, nem pelo corpo, sobre o que vestireis.',
        'Mais é a vida do que o sustento, e o corpo mais do que as vestes.',
        'Considerai os corvos, que nem semeiam, nem segam, nem têm despensa nem celeiro, e Deus os alimenta; quanto mais valeis vós do que as aves?',
        'E qual de vós, sendo solícito, pode acrescentar um côvado à sua estatura?',
        'Pois, se nem ainda podeis as coisas mínimas, por que estais ansiosos pelas outras?',
        'Considerai os lírios, como eles crescem; não trabalham, nem fiam; e digo-vos que nem ainda Salomão, em toda a sua glória, se vestiu como um deles.',
        'E, se Deus assim veste a erva que hoje está no campo e amanhã é lançada no forno, quanto mais a vós, homens de pouca fé?',
        'Não pergunteis, pois, que haveis de comer, ou que haveis de beber, e não andeis inquietos.',
        'Porque as nações do mundo buscam todas essas coisas; mas vosso Pai sabe que precisais delas.',
        'Buscai antes o reino de Deus, e todas estas coisas vos serão acrescentadas.',
        'Não temais, ó pequeno rebanho, porque a vosso Pai agradou dar-vos o reino.',
        'Vendei o que tendes, e dai esmolas. Fazei para vós bolsas que não se envelheçam; tesouro nos céus que nunca acabe, aonde não chega ladrão e a traça não rói.',
        'Porque, onde estiver o vosso tesouro, ali estará também o vosso coração.',
        'Estejam cingidos os vossos lombos, e acesas as vossas candeias.',
        'E sede vós semelhantes aos homens que esperam o seu senhor, quando houver de voltar das bodas, para que, quando vier, e bater, logo possam abrir-lhe.',
        'Bem-aventurados aqueles servos, os quais, quando o Senhor vier, achar vigiando! Em verdade vos digo que se cingirá, e os fará assentar à mesa e, chegando-se, os servirá.',
        'E, se vier na segunda vigília, e se vier na terceira vigília, e os achar assim, bem-aventurados são os tais servos.',
        'Sabei, porém, isto: que, se o pai de família soubesse a que hora havia de vir o ladrão, vigiaria, e não deixaria minar a sua casa.',
        'Portanto, estai vós também apercebidos; porque virá o Filho do homem à hora que não imaginais.',
        'E disse-lhe Pedro: Senhor, dizes essa parábola a nós, ou também a todos?',
        'E disse o Senhor: Qual é, pois, o mordomo fiel e prudente, a quem o senhor pôs sobre os seus servos, para lhes dar a tempo a ração?',
        'Bem-aventurado aquele servo a quem o seu senhor, quando vier, achar fazendo assim.',
        'Em verdade vos digo que sobre todos os seus bens o porá.',
        'Mas, se aquele servo disser em seu coração: O meu senhor tarda em vir; e começar a espancar os criados e criadas, e a comer, e a beber, e a embriagar-se,',
        'Virá o senhor daquele servo no dia em que o não espera, e numa hora que ele não sabe, e separá-lo-á, e lhe dará a sua parte com os infiéis.',
        'E o servo que soube a vontade do seu senhor, e não se aprontou, nem fez conforme a sua vontade, será castigado com muitos açoites;',
        'Mas o que a não soube, e fez coisas dignas de açoites, com poucos açoites será castigado. E, a qualquer que muito for dado, muito se lhe pedirá, e ao que muito se lhe confiou, muito mais se lhe pedirá.',
        'Vim lançar fogo na terra; e que mais quero, se já está aceso?',
        'Importa, porém, que seja batizado com um certo batismo; e como me angustio até que venha a cumprir-se!',
        'Cuidais vós que vim trazer paz à terra? Não, vos digo, mas antes dissensão;',
        'Porque daqui em diante estarão cinco divididos numa casa: três contra dois, e dois contra três.',
        'O pai estará dividido contra o filho, e o filho contra o pai; a mãe contra a filha, e a filha contra a mãe; a sogra contra sua nora, e a nora contra sua sogra.',
        'E dizia também à multidão: Quando vedes a nuvem que vem do ocidente, logo dizeis: Lá vem chuva, e assim sucede.',
        'E, quando assopra o sul, dizeis: Haverá calma; e assim sucede.',
        'Hipócritas, sabeis discernir a face da terra e do céu; como não sabeis então discernir este tempo?',
        'E por que não julgais também por vós mesmos o que é justo?',
        'Quando, pois, vais com o teu adversário ao magistrado, procura livrar-te dele no caminho; para que não suceda que te conduza ao juiz, e o juiz te entregue ao meirinho, e o meirinho te encerre na prisão.',
        'Digo-te que não sairás dali enquanto não pagares o derradeiro ceitil.',
      ],
      [
        'E, Naquele mesmo tempo, estavam presentes ali alguns que lhe falavam dos galileus, cujo sangue Pilatos misturara com os seus sacrifícios.',
        'E, respondendo Jesus, disse-lhes: Cuidais vós que esses galileus foram mais pecadores do que todos os galileus, por terem padecido tais coisas?',
        'Não, vos digo; antes, se não vos arrependerdes, todos de igual modo perecereis.',
        'E aqueles dezoito, sobre os quais caiu a torre de Siloé e os matou, cuidais que foram mais culpados do que todos quantos homens habitam em Jerusalém?',
        'Não, vos digo; antes, se não vos arrependerdes, todos de igual modo perecereis.',
        'E dizia esta parábola: Um certo homem tinha uma figueira plantada na sua vinha, e foi procurar nela fruto, não o achando;',
        'E disse ao vinhateiro: Eis que há três anos venho procurar fruto nesta figueira, e não o acho. Corta-a; por que ocupa ainda a terra inutilmente?',
        'E, respondendo ele, disse-lhe: Senhor, deixa-a este ano, até que eu a escave e a esterque;',
        'E, se der fruto, ficará e, se não, depois a mandarás cortar.',
        'E ensinava no sábado, numa das sinagogas.',
        'E eis que estava ali uma mulher que tinha um espírito de enfermidade, havia já dezoito anos; e andava curvada, e não podia de modo algum endireitar-se.',
        'E, vendo-a Jesus, chamou-a a si, e disse-lhe: Mulher, estás livre da tua enfermidade.',
        'E pôs as mãos sobre ela, e logo se endireitou, e glorificava a Deus.',
        'E, tomando a palavra o príncipe da sinagoga, indignado porque Jesus curava no sábado, disse à multidão: Seis dias há em que é mister trabalhar; nestes, pois, vinde para serdes curados, e não no dia de sábado.',
        'Respondeu-lhe, porém, o Senhor, e disse: Hipócrita, no sábado não desprende da manjedoura cada um de vós o seu boi, ou jumento, e não o leva a beber?',
        'E não convinha soltar desta prisão, no dia de sábado, esta filha de Abraão, a qual há dezoito anos Satanás tinha presa?',
        'E, dizendo ele isto, todos os seus adversários ficaram envergonhados, e todo o povo se alegrava por todas as coisas gloriosas que eram feitas por ele.',
        'E dizia: A que é semelhante o reino de Deus, e a que o compararei?',
        'É semelhante ao grão de mostarda que um homem, tomando-o, lançou na sua horta; e cresceu, e fez-se grande árvore, e em seus ramos se aninharam as aves do céu.',
        'E disse outra vez: A que compararei o reino de Deus?',
        'É semelhante ao fermento que uma mulher, tomando-o, escondeu em três medidas de farinha, até que tudo levedou.',
        'E percorria as cidades e as aldeias, ensinando, e caminhando para Jerusalém.',
        'E disse-lhe um: Senhor, são poucos os que se salvam? E ele lhe respondeu:',
        'Porfiai por entrar pela porta estreita; porque eu vos digo que muitos procurarão entrar, e não poderão.',
        'Quando o pai de família se levantar e cerrar a porta, e começardes, de fora, a bater à porta, dizendo: Senhor, Senhor, abre-nos; e, respondendo ele, vos disser: Não sei de onde vós sois;',
        'Então começareis a dizer: Temos comido e bebido na tua presença, e tu tens ensinado nas nossas ruas.',
        'E ele vos responderá: Digo-vos que não vos conheço nem sei de onde vós sois; apartai-vos de mim, vós todos os que praticais a iniqüidade.',
        'Ali haverá choro e ranger de dentes, quando virdes Abraão, e Isaque, e Jacó, e todos os profetas no reino de Deus, e vós lançados fora.',
        'E virão do oriente, e do ocidente, e do norte, e do sul, e assentar-se-ão à mesa no reino de Deus.',
        'E eis que derradeiros há que serão os primeiros; e primeiros há que serão os derradeiros.',
        'Naquele mesmo dia chegaram uns fariseus, dizendo-lhe: Sai, e retira-te daqui, porque Herodes quer matar-te.',
        'E respondeu-lhes: Ide, e dizei àquela raposa: Eis que eu expulso demônios, e efetuo curas, hoje e amanhã, e no terceiro dia sou consumado.',
        'Importa, porém, caminhar hoje, amanhã, e no dia seguinte, para que não suceda que morra um profeta fora de Jerusalém.',
        'Jerusalém, Jerusalém, que matas os profetas, e apedrejas os que te são enviados! Quantas vezes quis eu ajuntar os teus filhos, como a galinha os seus pintos debaixo das asas, e não quiseste?',
        'Eis que a vossa casa se vos deixará deserta. E em verdade vos digo que não me vereis até que venha o tempo em que digais: Bendito aquele que vem em nome do Senhor.',
      ],
      [
        'Aconteceu num sábado que, entrando ele em casa de um dos principais dos fariseus para comer pão, eles o estavam observando.',
        'E eis que estava ali diante dele um certo homem hidrópico.',
        'E Jesus, tomando a palavra, falou aos doutores da lei, e aos fariseus, dizendo: É lícito curar no sábado?',
        'Eles, porém, calaram-se. E, tomando-o, o curou e despediu.',
        'E respondendo-lhes disse: Qual será de vós o que, caindo-lhe num poço, em dia de sábado, o jumento ou o boi, o não tire logo?',
        'E nada lhe podiam replicar sobre isto.',
        'E disse aos convidados uma parábola, reparando como escolhiam os primeiros assentos, dizendo-lhes:',
        'Quando por alguém fores convidado às bodas, não te assentes no primeiro lugar; não aconteça que esteja convidado outro mais digno do que tu;',
        'E, vindo o que te convidou a ti e a ele, te diga: Dá o lugar a este; e então, com vergonha, tenhas de tomar o derradeiro lugar.',
        'Mas, quando fores convidado, vai, e assenta-te no derradeiro lugar, para que, quando vier o que te convidou, te diga: Amigo, sobe mais para cima. Então terás honra diante dos que estiverem contigo à mesa.',
        'Porquanto qualquer que a si mesmo se exaltar será humilhado, e aquele que a si mesmo se humilhar será exaltado.',
        'E dizia também ao que o tinha convidado: Quando deres um jantar, ou uma ceia, não chames os teus amigos, nem os teus irmãos, nem os teus parentes, nem vizinhos ricos, para que não suceda que também eles te tornem a convidar, e te seja isso recompensado.',
        'Mas, quando fizeres convite, chama os pobres, aleijados, mancos e cegos,',
        'E serás bem-aventurado; porque eles não têm com que to recompensar; mas recompensado te será na ressurreição dos justos.',
        'E, ouvindo isto, um dos que estavam com ele à mesa, disse-lhe: Bem-aventurado o que comer pão no reino de Deus.',
        'Porém, ele lhe disse: Um certo homem fez uma grande ceia, e convidou a muitos.',
        'E à hora da ceia mandou o seu servo dizer aos convidados: Vinde, que já tudo está preparado.',
        'E todos à uma começaram a escusar-se. Disse-lhe o primeiro: Comprei um campo, e importa ir vê-lo; rogo-te que me hajas por escusado.',
        'E outro disse: Comprei cinco juntas de bois, e vou experimentá-los; rogo-te que me hajas por escusado.',
        'E outro disse: Casei, e portanto não posso ir.',
        'E, voltando aquele servo, anunciou estas coisas ao seu senhor. Então o pai de família, indignado, disse ao seu servo: Sai depressa pelas ruas e bairros da cidade, e traze aqui os pobres, e aleijados, e mancos e cegos.',
        'E disse o servo: Senhor, feito está como mandaste; e ainda há lugar.',
        'E disse o senhor ao servo: Sai pelos caminhos e valados, e força-os a entrar, para que a minha casa se encha.',
        'Porque eu vos digo que nenhum daqueles homens que foram convidados provará a minha ceia.',
        'Ora, ia com ele uma grande multidão; e, voltando-se, disse-lhe:',
        'Se alguém vier a mim, e não aborrecer a seu pai, e mãe, e mulher, e filhos, e irmãos, e irmãs, e ainda também a sua própria vida, não pode ser meu discípulo.',
        'E qualquer que não levar a sua cruz, e não vier após mim, não pode ser meu discípulo.',
        'Pois qual de vós, querendo edificar uma torre, não se assenta primeiro a fazer as contas dos gastos, para ver se tem com que a acabar?',
        'Para que não aconteça que, depois de haver posto os alicerces, e não a podendo acabar, todos os que a virem comecem a escarnecer dele,',
        'Dizendo: Este homem começou a edificar e não pôde acabar.',
        'Ou qual é o rei que, indo à guerra a pelejar contra outro rei, não se assenta primeiro a tomar conselho sobre se com dez mil pode sair ao encontro do que vem contra ele com vinte mil?',
        'De outra maneira, estando o outro ainda longe, manda embaixadores, e pede condições de paz.',
        'Assim, pois, qualquer de vós, que não renuncia a tudo quanto tem, não pode ser meu discípulo.',
        'Bom é o sal; mas, se o sal degenerar, com que se há de salgar?',
        'Nem presta para a terra, nem para o monturo; lançam-no fora. Quem tem ouvidos para ouvir, ouça.',
      ],
      [
        'E Chegavam-se a ele todos os publicanos e pecadores para o ouvir.',
        'E os fariseus e os escribas murmuravam, dizendo: Este recebe pecadores, e come com eles.',
        'E ele lhes propôs esta parábola, dizendo:',
        'Que homem dentre vós, tendo cem ovelhas, e perdendo uma delas, não deixa no deserto as noventa e nove, e vai após a perdida até que venha a achá-la?',
        'E achando-a, a põe sobre os seus ombros, jubiloso;',
        'E, chegando a casa, convoca os amigos e vizinhos, dizendo-lhes: Alegrai-vos comigo, porque já achei a minha ovelha perdida.',
        'Digo-vos que assim haverá alegria no céu por um pecador que se arrepende, mais do que por noventa e nove justos que não necessitam de arrependimento.',
        'Ou qual a mulher que, tendo dez dracmas, se perder uma dracma, não acende a candeia, e varre a casa, e busca com diligência até a achar?',
        'E achando-a, convoca as amigas e vizinhas, dizendo: Alegrai-vos comigo, porque já achei a dracma perdida.',
        'Assim vos digo que há alegria diante dos anjos de Deus por um pecador que se arrepende.',
        'E disse: Um certo homem tinha dois filhos;',
        'E o mais moço deles disse ao pai: Pai, dá-me a parte dos bens que me pertence. E ele repartiu por eles a fazenda.',
        'E, poucos dias depois, o filho mais novo, ajuntando tudo, partiu para uma terra longínqua, e ali desperdiçou os seus bens, vivendo dissolutamente.',
        'E, havendo ele gastado tudo, houve naquela terra uma grande fome, e começou a padecer necessidades.',
        'E foi, e chegou-se a um dos cidadãos daquela terra, o qual o mandou para os seus campos, a apascentar porcos.',
        'E desejava encher o seu estômago com as bolotas que os porcos comiam, e ninguém lhe dava nada.',
        'E, tornando em si, disse: Quantos jornaleiros de meu pai têm abundância de pão, e eu aqui pereço de fome!',
        'Levantar-me-ei, e irei ter com meu pai, e dir-lhe-ei: Pai, pequei contra o céu e perante ti;',
        'Já não sou digno de ser chamado teu filho; faze-me como um dos teus jornaleiros.',
        'E, levantando-se, foi para seu pai; e, quando ainda estava longe, viu-o seu pai, e se moveu de íntima compaixão e, correndo, lançou-se-lhe ao pescoço e o beijou.',
        'E o filho lhe disse: Pai, pequei contra o céu e perante ti, e já não sou digno de ser chamado teu filho.',
        'Mas o pai disse aos seus servos: Trazei depressa a melhor roupa; e vesti-lho, e ponde-lhe um anel na mão, e alparcas nos pés;',
        'E trazei o bezerro cevado, e matai-o; e comamos, e alegremo-nos;',
        'Porque este meu filho estava morto, e reviveu, tinha-se perdido, e foi achado. E começaram a alegrar-se.',
        'E o seu filho mais velho estava no campo; e quando veio, e chegou perto de casa, ouviu a música e as danças.',
        'E, chamando um dos servos, perguntou-lhe que era aquilo.',
        'E ele lhe disse: Veio teu irmão; e teu pai matou o bezerro cevado, porque o recebeu são e salvo.',
        'Mas ele se indignou, e não queria entrar.',
        'E saindo o pai, instava com ele. Mas, respondendo ele, disse ao pai: Eis que te sirvo há tantos anos, sem nunca transgredir o teu mandamento, e nunca me deste um cabrito para alegrar-me com os meus amigos;',
        'Vindo, porém, este teu filho, que desperdiçou os teus bens com as meretrizes, mataste-lhe o bezerro cevado.',
        'E ele lhe disse: Filho, tu sempre estás comigo, e todas as minhas coisas são tuas;',
        'Mas era justo alegrarmo-nos e folgarmos, porque este teu irmão estava morto, e reviveu; e tinha-se perdido, e achou-se.',
      ],
      [
        'E dizia também aos seus discípulos: Havia um certo homem rico, o qual tinha um mordomo; e este foi acusado perante ele de dissipar os seus bens.',
        'E ele, chamando-o, disse-lhe: Que é isto que ouço de ti? Dá contas da tua mordomia, porque já não poderás ser mais meu mordomo.',
        'E o mordomo disse consigo: Que farei, pois que o meu senhor me tira a mordomia? Cavar, não posso; de mendigar, tenho vergonha.',
        'Eu sei o que hei de fazer, para que, quando for desapossado da mordomia, me recebam em suas casas.',
        'E, chamando a si cada um dos devedores do seu senhor, disse ao primeiro: Quanto deves ao meu senhor?',
        'E ele respondeu: Cem medidas de azeite. E disse-lhe: Toma a tua obrigação, e assentando-te já, escreve cinqüenta.',
        'Disse depois a outro: E tu, quanto deves? E ele respondeu: Cem alqueires de trigo. E disse-lhe: Toma a tua obrigação, e escreve oitenta.',
        'E louvou aquele senhor o injusto mordomo por haver procedido prudentemente, porque os filhos deste mundo são mais prudentes na sua geração do que os filhos da luz.',
        'E eu vos digo: Granjeai amigos com as riquezas da injustiça; para que, quando estas vos faltarem, vos recebam eles nos tabernáculos eternos.',
        'Quem é fiel no mínimo, também é fiel no muito; quem é injusto no mínimo, também é injusto no muito.',
        'Pois, se nas riquezas injustas não fostes fiéis, quem vos confiará as verdadeiras?',
        'E, se no alheio não fostes fiéis, quem vos dará o que é vosso?',
        'Nenhum servo pode servir dois senhores; porque, ou há de odiar um e amar o outro, ou se há de chegar a um e desprezar o outro. Não podeis servir a Deus e a Mamom.',
        'E os fariseus, que eram avarentos, ouviam todas estas coisas, e zombavam dele.',
        'E disse-lhes: Vós sois os que vos justificais a vós mesmos diante dos homens, mas Deus conhece os vossos corações, porque o que entre os homens é elevado, perante Deus é abominação.',
        'A lei e os profetas duraram até João; desde então é anunciado o reino de Deus, e todo o homem emprega força para entrar nele.',
        'E é mais fácil passar o céu e a terra do que cair um til da lei.',
        'Qualquer que deixa sua mulher, e casa com outra, adultera; e aquele que casa com a repudiada pelo marido, adultera também.',
        'Ora, havia um homem rico, e vestia-se de púrpura e de linho finíssimo, e vivia todos os dias regalada e esplendidamente.',
        'Havia também um certo mendigo, chamado Lázaro, que jazia cheio de chagas à porta daquele;',
        'E desejava alimentar-se com as migalhas que caíam da mesa do rico; e os próprios cães vinham lamber-lhe as chagas.',
        'E aconteceu que o mendigo morreu, e foi levado pelos anjos para o seio de Abraão; e morreu também o rico, e foi sepultado.',
        'E no inferno, ergueu os olhos, estando em tormentos, e viu ao longe Abraão, e Lázaro no seu seio.',
        'E, clamando, disse: Pai Abraão, tem misericórdia de mim, e manda a Lázaro, que molhe na água a ponta do seu dedo e me refresque a língua, porque estou atormentado nesta chama.',
        'Disse, porém, Abraão: Filho, lembra-te de que recebeste os teus bens em tua vida, e Lázaro somente males; e agora este é consolado e tu atormentado.',
        'E, além disso, está posto um grande abismo entre nós e vós, de sorte que os que quisessem passar daqui para vós não poderiam, nem tampouco os de lá passar para cá.',
        'E disse ele: Rogo-te, pois, ó pai, que o mandes à casa de meu pai,',
        'Pois tenho cinco irmãos; para que lhes dê testemunho, a fim de que não venham também para este lugar de tormento.',
        'Disse-lhe Abraão: Têm Moisés e os profetas; ouçam-nos.',
        'E disse ele: Não, pai Abraão; mas, se algum dentre os mortos fosse ter com eles, arrepender-se-iam.',
        'Porém, Abraão lhe disse: Se não ouvem a Moisés e aos profetas, tampouco acreditarão, ainda que algum dos mortos ressuscite.',
      ],
      [
        'E disse aos discípulos: É impossível que não venham escândalos, mas ai daquele por quem vierem!',
        'Melhor lhe fora que lhe pusessem ao pescoço uma mó de atafona, e fosse lançado ao mar, do que fazer tropeçar um destes pequenos.',
        'Olhai por vós mesmos. E, se teu irmão pecar contra ti, repreende-o e, se ele se arrepender, perdoa-lhe.',
        'E, se pecar contra ti sete vezes no dia, e sete vezes no dia vier ter contigo, dizendo: Arrependo-me; perdoa-lhe.',
        'Disseram então os apóstolos ao Senhor: Acrescenta-nos a fé.',
        'E disse o Senhor: Se tivésseis fé como um grão de mostarda, diríeis a esta amoreira: Desarraiga-te daqui, e planta-te no mar; e ela vos obedeceria.',
        'E qual de vós terá um servo a lavrar ou a apascentar gado, a quem, voltando ele do campo, diga: Chega-te, e assenta-te à mesa?',
        'E não lhe diga antes: Prepara-me a ceia, e cinge-te, e serve-me até que tenha comido e bebido, e depois comerás e beberás tu?',
        'Porventura dá graças ao tal servo, porque fez o que lhe foi mandado? Creio que não.',
        'Assim também vós, quando fizerdes tudo o que vos for mandado, dizei: Somos servos inúteis, porque fizemos somente o que devíamos fazer.',
        'E aconteceu que, indo ele a Jerusalém, passou pelo meio de Samaria e da Galiléia;',
        'E, entrando numa certa aldeia, saíram-lhe ao encontro dez homens leprosos, os quais pararam de longe;',
        'E levantaram a voz, dizendo: Jesus, Mestre, tem misericórdia de nós.',
        'E ele, vendo-os, disse-lhes: Ide, e mostrai-vos aos sacerdotes. E aconteceu que, indo eles, ficaram limpos.',
        'E um deles, vendo que estava são, voltou glorificando a Deus em alta voz;',
        'E caiu aos seus pés, com o rosto em terra, dando-lhe graças; e este era samaritano.',
        'E, respondendo Jesus, disse: Não foram dez os limpos? E onde estão os nove?',
        'Não houve quem voltasse para dar glória a Deus senão este estrangeiro?',
        'E disse-lhe: Levanta-te, e vai; a tua fé te salvou.',
        'E, interrogado pelos fariseus sobre quando havia de vir o reino de Deus, respondeu-lhes, e disse: O reino de Deus não vem com aparência exterior.',
        'Nem dirão: Ei-lo aqui, ou: Ei-lo ali; porque eis que o reino de Deus está entre vós.',
        'E disse aos discípulos: Dias virão em que desejareis ver um dos dias do Filho do homem, e não o vereis.',
        'E dir-vos-ão: Ei-lo aqui, ou: Ei-lo ali. Não vades, nem os sigais;',
        'Porque, como o relâmpago ilumina desde uma extremidade inferior do céu até à outra extremidade, assim será também o Filho do homem no seu dia.',
        'Mas primeiro convém que ele padeça muito, e seja reprovado por esta geração.',
        'E, como aconteceu nos dias de Noé, assim será também nos dias do Filho do homem.',
        'Comiam, bebiam, casavam, e davam-se em casamento, até ao dia em que Noé entrou na arca, e veio o dilúvio, e os consumiu a todos.',
        'Como também da mesma maneira aconteceu nos dias de Ló: Comiam, bebiam, compravam, vendiam, plantavam e edificavam;',
        'Mas no dia em que Ló saiu de Sodoma choveu do céu fogo e enxofre, e os consumiu a todos.',
        'Assim será no dia em que o Filho do homem se há de manifestar.',
        'Naquele dia, quem estiver no telhado, tendo as suas alfaias em casa, não desça a tomá-las; e, da mesma sorte, o que estiver no campo não volte para trás.',
        'Lembrai-vos da mulher de Ló.',
        'Qualquer que procurar salvar a sua vida, perdê-la-á, e qualquer que a perder, salvá-la-á.',
        'Digo-vos que naquela noite estarão dois numa cama; um será tomado, e outro será deixado.',
        'Duas estarão juntas, moendo; uma será tomada, e outra será deixada.',
        'Dois estarão no campo; um será tomado, e o outro será deixado.',
        'E, respondendo, disseram-lhe: Onde, Senhor? E ele lhes disse: Onde estiver o corpo, aí se ajuntarão as águias.',
      ],
      [
        'E contou-lhes também uma parábola sobre o dever de orar sempre, e nunca desfalecer,',
        'Dizendo: Havia numa cidade um certo juiz, que nem a Deus temia, nem respeitava o homem.',
        'Havia também, naquela mesma cidade, uma certa viúva, que ia ter com ele, dizendo: Faze-me justiça contra o meu adversário.',
        'E por algum tempo não quis atendê-la; mas depois disse consigo: Ainda que não temo a Deus, nem respeito os homens,',
        'Todavia, como esta viúva me molesta, hei de fazer-lhe justiça, para que enfim não volte, e me importune muito.',
        'E disse o Senhor: Ouvi o que diz o injusto juiz.',
        'E Deus não fará justiça aos seus escolhidos, que clamam a ele de dia e de noite, ainda que tardio para com eles?',
        'Digo-vos que depressa lhes fará justiça. Quando porém vier o Filho do homem, porventura achará fé na terra?',
        'E disse também esta parábola a uns que confiavam em si mesmos, crendo que eram justos, e desprezavam os outros:',
        'Dois homens subiram ao templo, para orar; um, fariseu, e o outro, publicano.',
        'O fariseu, estando em pé, orava consigo desta maneira: Ó Deus, graças te dou porque não sou como os demais homens, roubadores, injustos e adúlteros; nem ainda como este publicano.',
        'Jejuo duas vezes na semana, e dou os dízimos de tudo quanto possuo.',
        'O publicano, porém, estando em pé, de longe, nem ainda queria levantar os olhos ao céu, mas batia no peito, dizendo: Ó Deus, tem misericórdia de mim, pecador!',
        'Digo-vos que este desceu justificado para sua casa, e não aquele; porque qualquer que a si mesmo se exalta será humilhado, e qualquer que a si mesmo se humilha será exaltado.',
        'E traziam-lhe também meninos, para que ele lhes tocasse; e os discípulos, vendo isto, repreendiam-nos.',
        'Mas Jesus, chamando-os para si, disse: Deixai vir a mim os meninos, e não os impeçais, porque dos tais é o reino de Deus.',
        'Em verdade vos digo que, qualquer que não receber o reino de Deus como menino, não entrará nele.',
        'E perguntou-lhe um certo príncipe, dizendo: Bom Mestre, que hei de fazer para herdar a vida eterna?',
        'Jesus lhe disse: Por que me chamas bom? Ninguém há bom, senão um, que é Deus.',
        'Sabes os mandamentos: Não adulterarás, não matarás, não furtarás, não dirás falso testemunho, honra a teu pai e a tua mãe.',
        'E disse ele: Todas essas coisas tenho observado desde a minha mocidade.',
        'E quando Jesus ouviu isto, disse-lhe: Ainda te falta uma coisa; vende tudo quanto tens, reparte-o pelos pobres, e terás um tesouro no céu; vem, e segue-me.',
        'Mas, ouvindo ele isto, ficou muito triste, porque era muito rico.',
        'E, vendo Jesus que ele ficara muito triste, disse: Quão dificilmente entrarão no reino de Deus os que têm riquezas!',
        'Porque é mais fácil entrar um camelo pelo fundo de uma agulha do que entrar um rico no reino de Deus.',
        'E os que ouviram isto disseram: Logo quem pode salvar-se?',
        'Mas ele respondeu: As coisas que são impossíveis aos homens são possíveis a Deus.',
        'E disse Pedro: Eis que nós deixamos tudo e te seguimos.',
        'E ele lhes disse: Na verdade vos digo que ninguém há, que tenha deixado casa, ou pais, ou irmãos, ou mulher, ou filhos, pelo reino de Deus,',
        'Que não haja de receber muito mais neste mundo, e na idade vindoura a vida eterna.',
        'E, tomando consigo os doze, disse-lhes: Eis que subimos a Jerusalém, e se cumprirá no Filho do homem tudo o que pelos profetas foi escrito;',
        'Pois há de ser entregue aos gentios, e escarnecido, injuriado e cuspido;',
        'E, havendo-o açoitado, o matarão; e ao terceiro dia ressuscitará.',
        'E eles nada disto entendiam, e esta palavra lhes era encoberta, não percebendo o que se lhes dizia.',
        'E aconteceu que chegando ele perto de Jericó, estava um cego assentado junto do caminho, mendigando.',
        'E, ouvindo passar a multidão, perguntou que era aquilo.',
        'E disseram-lhe que Jesus Nazareno passava.',
        'Então clamou, dizendo: Jesus, Filho de Davi, tem misericórdia de mim.',
        'E os que iam passando repreendiam-no para que se calasse; mas ele clamava ainda mais: Filho de Davi, tem misericórdia de mim!',
        'Então Jesus, parando, mandou que lho trouxessem; e, chegando ele, perguntou-lhe,',
        'Dizendo: Que queres que te faça? E ele disse: Senhor, que eu veja.',
        'E Jesus lhe disse: Vê; a tua fé te salvou.',
        'E logo viu, e seguia-o, glorificando a Deus. E todo o povo, vendo isto, dava louvores a Deus.',
      ],
      [
        'E, tendo Jesus entrado em Jericó, ia passando.',
        'E eis que havia ali um homem chamado Zaqueu; e era este um chefe dos publicanos, e era rico.',
        'E procurava ver quem era Jesus, e não podia, por causa da multidão, pois era de pequena estatura.',
        'E, correndo adiante, subiu a uma figueira brava para o ver; porque havia de passar por ali.',
        'E quando Jesus chegou àquele lugar, olhando para cima, viu-o e disse-lhe: Zaqueu, desce depressa, porque hoje me convém pousar em tua casa.',
        'E, apressando-se, desceu, e recebeu-o alegremente.',
        'E, vendo todos isto, murmuravam, dizendo que entrara para ser hóspede de um homem pecador.',
        'E, levantando-se Zaqueu, disse ao Senhor: Senhor, eis que eu dou aos pobres metade dos meus bens; e, se nalguma coisa tenho defraudado alguém, o restituo quadruplicado.',
        'E disse-lhe Jesus: Hoje veio a salvação a esta casa, pois também este é filho de Abraão.',
        'Porque o Filho do homem veio buscar e salvar o que se havia perdido.',
        'E, ouvindo eles estas coisas, ele prosseguiu, e contou uma parábola; porquanto estava perto de Jerusalém, e cuidavam que logo se havia de manifestar o reino de Deus.',
        'Disse pois: Certo homem nobre partiu para uma terra remota, a fim de tomar para si um reino e voltar depois.',
        'E, chamando dez servos seus, deu-lhes dez minas, e disse-lhes: Negociai até que eu venha.',
        'Mas os seus concidadãos odiavam-no, e mandaram após ele embaixadores, dizendo: Não queremos que este reine sobre nós.',
        'E aconteceu que, voltando ele, depois de ter tomado o reino, disse que lhe chamassem aqueles servos, a quem tinha dado o dinheiro, para saber o que cada um tinha ganhado, negociando.',
        'E veio o primeiro, dizendo: Senhor, a tua mina rendeu dez minas.',
        'E ele lhe disse: Bem está, servo bom, porque no mínimo foste fiel, sobre dez cidades terás autoridade.',
        'E veio o segundo, dizendo: Senhor, a tua mina rendeu cinco minas.',
        'E a este disse também: Sê tu também sobre cinco cidades.',
        'E veio outro, dizendo: Senhor, aqui está a tua mina, que guardei num lenço;',
        'Porque tive medo de ti, que és homem rigoroso, que tomas o que não puseste, e segas o que não semeaste.',
        'Porém, ele lhe disse: Mau servo, pela tua boca te julgarei. Sabias que eu sou homem rigoroso, que tomo o que não pus, e sego o que não semeei;',
        'Por que não puseste, pois, o meu dinheiro no banco, para que eu, vindo, o exigisse com os juros?',
        'E disse aos que estavam com ele: Tirai-lhe a mina, e dai-a ao que tem dez minas.',
        '(E disseram-lhe eles: Senhor, ele tem dez minas. )',
        'Pois eu vos digo que a qualquer que tiver ser-lhe-á dado, mas ao que não tiver, até o que tem lhe será tirado.',
        'E quanto àqueles meus inimigos que não quiseram que eu reinasse sobre eles, trazei-os aqui, e matai-os diante de mim.',
        'E, dito isto, ia caminhando adiante, subindo para Jerusalém.',
        'E aconteceu que, chegando perto de Betfagé, e de Betânia, ao monte chamado das Oliveiras, mandou dois dos seus discípulos,',
        'Dizendo: Ide à aldeia que está defronte, e aí, ao entrar, achareis preso um jumentinho em que nenhum homem ainda montou; soltai-o e trazei-o.',
        'E, se alguém vos perguntar: Por que o soltais? assim lhe direis: Porque o Senhor o há de mister.',
        'E, indo os que haviam sido mandados, acharam como lhes dissera.',
        'E, quando soltaram o jumentinho, seus donos lhes disseram: Por que soltais o jumentinho?',
        'E eles responderam: O Senhor o há de mister.',
        'E trouxeram-no a Jesus; e, lançando sobre o jumentinho as suas vestes, puseram Jesus em cima.',
        'E, indo ele, estendiam no caminho as suas vestes.',
        'E, quando já chegava perto da descida do Monte das Oliveiras, toda a multidão dos discípulos, regozijando-se, começou a dar louvores a Deus em alta voz, por todas as maravilhas que tinham visto,',
        'Dizendo: Bendito o Rei que vem em nome do Senhor; paz no céu, e glória nas alturas.',
        'E disseram-lhe de entre a multidão alguns dos fariseus: Mestre, repreende os teus discípulos.',
        'E, respondendo ele, disse-lhes: Digo-vos que, se estes se calarem, as próprias pedras clamarão.',
        'E, quando ia chegando, vendo a cidade, chorou sobre ela,',
        'Dizendo: Ah! se tu conhecesses também, ao menos neste teu dia, o que à tua paz pertence! Mas agora isto está encoberto aos teus olhos.',
        'Porque dias virão sobre ti, em que os teus inimigos te cercarão de trincheiras, e te sitiarão, e te estreitarão de todos os lados;',
        'E te derrubarão, a ti e aos teus filhos que dentro de ti estiverem, e não deixarão em ti pedra sobre pedra, pois que não conheceste o tempo da tua visitação.',
        'E, entrando no templo, começou a expulsar todos os que nele vendiam e compravam,',
        'Dizendo-lhes: Está escrito: A minha casa é casa de oração; mas vós fizestes dela covil de salteadores.',
        'E todos os dias ensinava no templo; mas os principais dos sacerdotes, e os escribas, e os principais do povo procuravam matá-lo.',
        'E não achavam meio de o fazer, porque todo o povo pendia para ele, escutando-o.',
      ],
      [
        'E aconteceu num daqueles dias que, estando ele ensinando o povo no templo, e anunciando o evangelho, sobrevieram os principais dos sacerdotes e os escribas com os anciãos,',
        'E falaram-lhe, dizendo: Dize-nos, com que autoridade fazes estas coisas? Ou, quem é que te deu esta autoridade?',
        'E, respondendo ele, disse-lhes: Também eu vos farei uma pergunta: Dizei-me pois:',
        'O batismo de João era do céu ou dos homens?',
        'E eles arrazoavam entre si, dizendo: Se dissermos: Do céu, ele nos dirá: Então por que o não crestes?',
        'E se dissermos: Dos homens; todo o povo nos apedrejará, pois têm por certo que João era profeta.',
        'E responderam que não sabiam de onde era.',
        'E Jesus lhes disse: Tampouco vos direi com que autoridade faço isto.',
        'E começou a dizer ao povo esta parábola: Certo homem plantou uma vinha, e arrendou-a a uns lavradores, e partiu para fora da terra por muito tempo;',
        'E no tempo próprio mandou um servo aos lavradores, para que lhe dessem dos frutos da vinha; mas os lavradores, espancando-o, mandaram-no vazio.',
        'E tornou ainda a mandar outro servo; mas eles, espancando também a este, e afrontando-o, mandaram-no vazio.',
        'E tornou ainda a mandar um terceiro; mas eles, ferindo também a este, o expulsaram.',
        'E disse o senhor da vinha: Que farei? Mandarei o meu filho amado; talvez que, vendo, o respeitem.',
        'Mas, vendo-o os lavradores, arrazoaram entre si, dizendo: Este é o herdeiro; vinde, matemo-lo, para que a herança seja nossa.',
        'E, lançando-o fora da vinha, o mataram. Que lhes fará, pois, o senhor da vinha?',
        'Irá, e destruirá estes lavradores, e dará a outros a vinha. E, ouvindo eles isto, disseram: Não seja assim!',
        'Mas ele, olhando para eles, disse: Que é isto, pois, que está escrito? A pedra, que os edificadores reprovaram, Essa foi feita cabeça da esquina.',
        'Qualquer que cair sobre aquela pedra ficará em pedaços, e aquele sobre quem ela cair será feito em pó.',
        'E os principais dos sacerdotes e os escribas procuravam lançar mão dele naquela mesma hora; mas temeram o povo; porque entenderam que contra eles dissera esta parábola.',
        'E, observando-o, mandaram espias, que se fingissem justos, para o apanharem nalguma palavra, e o entregarem à jurisdição e poder do presidente.',
        'E perguntaram-lhe, dizendo: Mestre, nós sabemos que falas e ensinas bem e retamente, e que não consideras a aparência da pessoa, mas ensinas com verdade o caminho de Deus.',
        'É-nos lícito dar tributo a César ou não?',
        'E, entendendo ele a sua astúcia, disse-lhes: Por que me tentais?',
        'Mostrai-me uma moeda. De quem tem a imagem e a inscrição? E, respondendo eles, disseram: De César.',
        'Disse-lhes então: Dai, pois, a César o que é de César, e a Deus o que é de Deus.',
        'E não puderam apanhá-lo em palavra alguma diante do povo; e, maravilhados da sua resposta, calaram-se.',
        'E, chegando-se alguns dos saduceus, que dizem não haver ressurreição, perguntaram-lhe,',
        'Dizendo: Mestre, Moisés nos deixou escrito que, se o irmão de algum falecer, tendo mulher, e não deixar filhos, o irmão dele tome a mulher, e suscite posteridade a seu irmão.',
        'Houve, pois, sete irmãos, e o primeiro tomou mulher, e morreu sem filhos;',
        'E tomou-a o segundo por mulher, e ele morreu sem filhos.',
        'E tomou-a o terceiro, e igualmente também os sete; e morreram, e nào deixaram filhos.',
        'E por último, depois de todos, morreu também a mulher.',
        'Portanto, na ressurreição, de qual deles será a mulher, pois que os sete por mulher a tiveram?',
        'E, respondendo Jesus, disse-lhes: Os filhos deste mundo casam-se, e dão-se em casamento;',
        'Mas os que forem havidos por dignos de alcançar o mundo vindouro, e a ressurreição dentre os mortos, nem hão de casar, nem ser dados em casamento;',
        'Porque já não podem mais morrer; pois são iguais aos anjos, e são filhos de Deus, sendo filhos da ressurreição.',
        'E que os mortos hão de ressuscitar também o mostrou Moisés junto da sarça, quando chama ao Senhor Deus de Abraão, e Deus de Isaque, e Deus de Jacó.',
        'Ora, Deus não é Deus de mortos, mas de vivos; porque para ele vivem todos.',
        'E, respondendo alguns dos escribas, disseram: Mestre, disseste bem.',
        'E não ousavam perguntar-lhe mais coisa alguma.',
        'E ele lhes disse: Como dizem que o Cristo é filho de Davi?',
        'Visto como o mesmo Davi diz no livro dos Salmos: Disse o SENHOR ao meu Senhor: Assenta-te à minha direita,',
        'Até que eu ponha os teus inimigos por escabelo de teus pés.',
        'Se Davi lhe chama Senhor, como é ele seu filho?',
        'E, ouvindo-o todo o povo, disse Jesus aos seus discípulos:',
        'Guardai-vos dos escribas, que querem andar com vestes compridas; e amam as saudações nas praças, e as principais cadeiras nas sinagogas, e os primeiros lugares nos banquetes;',
        'Que devoram as casas das viúvas, fazendo, por pretexto, largas orações. Estes receberão maior condenação.',
      ],
      [
        'E, olhando ele, viu os ricos lançarem as suas ofertas na arca do tesouro;',
        'E viu também uma pobre viúva lançar ali duas pequenas moedas;',
        'E disse: Em verdade vos digo que lançou mais do que todos, esta pobre viúva;',
        'Porque todos aqueles deitaram para as ofertas de Deus do que lhes sobeja; mas esta, da sua pobreza, deitou todo o sustento que tinha.',
        'E, dizendo alguns a respeito do templo, que estava ornado de formosas pedras e dádivas, disse:',
        'Quanto a estas coisas que vedes, dias virão em que não se deixará pedra sobre pedra, que não seja derrubada.',
        'E perguntaram-lhe, dizendo: Mestre, quando serão, pois, estas coisas? E que sinal haverá quando isto estiver para acontecer?',
        'Disse então ele: Vede não vos enganem, porque virão muitos em meu nome, dizendo: Sou eu, e o tempo está próximo. Não vades, portanto, após eles.',
        'E, quando ouvirdes de guerras e sedições, não vos assusteis. Porque é necessário que isto aconteça primeiro, mas o fim não será logo.',
        'Então lhes disse: Levantar-se-á nação contra nação, e reino contra reino;',
        'E haverá em vários lugares grandes terremotos, e fomes e pestilências; haverá também coisas espantosas, e grandes sinais do céu.',
        'Mas antes de todas estas coisas lançarão mão de vós, e vos perseguirão, entregando-vos às sinagogas e às prisões, e conduzindo-vos à presença de reis e presidentes, por amor do meu nome.',
        'E vos acontecerá isto para testemunho.',
        'Proponde, pois, em vossos corações não premeditar como haveis de responder;',
        'Porque eu vos darei boca e sabedoria a que não poderão resistir nem contradizer todos quantos se vos opuserem.',
        'E até pelos pais, e irmãos, e parentes, e amigos sereis entregues; e matarão alguns de vós.',
        'E de todos sereis odiados por causa do meu nome.',
        'Mas não perecerá um único cabelo da vossa cabeça.',
        'Na vossa paciência possuí as vossas almas.',
        'Mas, quando virdes Jerusalém cercada de exércitos, sabei então que é chegada a sua desolação.',
        'Então, os que estiverem na Judéia, fujam para os montes; os que estiverem no meio da cidade, saiam; e os que nos campos não entrem nela.',
        'Porque dias de vingança são estes, para que se cumpram todas as coisas que estão escritas.',
        'Mas ai das grávidas, e das que criarem naqueles dias! porque haverá grande aperto na terra, e ira sobre este povo.',
        'E cairão ao fio da espada, e para todas as nações serão levados cativos; e Jerusalém será pisada pelos gentios, até que os tempos dos gentios se completem.',
        'E haverá sinais no sol e na lua e nas estrelas; e na terra angústia das nações, em perplexidade pelo bramido do mar e das ondas.',
        'Homens desmaiando de terror, na expectação das coisas que sobrevirão ao mundo; porquanto as virtudes do céu serão abaladas.',
        'E então verão vir o Filho do homem numa nuvem, com poder e grande glória.',
        'Ora, quando estas coisas começarem a acontecer, olhai para cima e levantai as vossas cabeças, porque a vossa redenção está próxima.',
        'E disse-lhes uma parábola: Olhai para a figueira, e para todas as árvores;',
        'Quando já têm rebentado, vós sabeis por vós mesmos, vendo-as, que perto está já o verão.',
        'Assim também vós, quando virdes acontecer estas coisas, sabei que o reino de Deus está perto.',
        'Em verdade vos digo que não passará esta geração até que tudo aconteça.',
        'Passará o céu e a terra, mas as minhas palavras não hão de passar.',
        'E olhai por vós, não aconteça que os vossos corações se carreguem de glutonaria, de embriaguez, e dos cuidados da vida, e venha sobre vós de improviso aquele dia.',
        'Porque virá como um laço sobre todos os que habitam na face de toda a terra.',
        'Vigiai, pois, em todo o tempo, orando, para que sejais havidos por dignos de evitar todas estas coisas que hão de acontecer, e de estar em pé diante do Filho do homem.',
        'E de dia ensinava no templo, e à noite, saindo, ficava no monte chamado das Oliveiras.',
        'E todo o povo ia ter com ele ao templo, de manhã cedo, para o ouvir.',
      ],
      [
        'Estava, pois, perto a festa dos pães ázimos, chamada a páscoa.',
        'E os principais dos sacerdotes, e os escribas, andavam procurando como o matariam; porque temiam o povo.',
        'Entrou, porém, Satanás em Judas, que tinha por sobrenome Iscariotes, o qual era do número dos doze.',
        'E foi, e falou com os principais dos sacerdotes, e com os capitães, de como lho entregaria;',
        'Os quais se alegraram, e convieram em lhe dar dinheiro.',
        'E ele concordou; e buscava oportunidade para lho entregar sem alvoroço.',
        'Chegou, porém, o dia dos ázimos, em que importava sacrificar a páscoa.',
        'E mandou a Pedro e a João, dizendo: Ide, preparai-nos a páscoa, para que a comamos.',
        'E eles lhe perguntaram: Onde queres que a preparemos?',
        'E ele lhes disse: Eis que, quando entrardes na cidade, encontrareis um homem, levando um cântaro de água; segui-o até à casa em que ele entrar.',
        'E direis ao pai de família da casa: O Mestre te diz: Onde está o aposento em que hei de comer a páscoa com os meus discípulos?',
        'Então ele vos mostrará um grande cenáculo mobilado; aí fazei preparativos.',
        'E, indo eles, acharam como lhes havia sido dito; e prepararam a páscoa.',
        'E, chegada a hora, pôs-se à mesa, e com ele os doze apóstolos.',
        'E disse-lhes: Desejei muito comer convosco esta páscoa, antes que padeça;',
        'Porque vos digo que não a comerei mais até que ela se cumpra no reino de Deus.',
        'E, tomando o cálice, e havendo dado graças, disse: Tomai-o, e reparti-o entre vós;',
        'Porque vos digo que já não beberei do fruto da vide, até que venha o reino de Deus.',
        'E, tomando o pão, e havendo dado graças, partiu-o, e deu-lho, dizendo: Isto é o meu corpo, que por vós é dado; fazei isto em memória de mim.',
        'Semelhantemente, tomou o cálice, depois da ceia, dizendo: Este cálice é o novo testamento no meu sangue, que é derramado por vós.',
        'Mas eis que a mão do que me trai está comigo à mesa.',
        'E, na verdade, o Filho do homem vai segundo o que está determinado; mas ai daquele homem por quem é traído!',
        'E começaram a perguntar entre si qual deles seria o que havia de fazer isto.',
        'E houve também entre eles contenda, sobre qual deles parecia ser o maior.',
        'E ele lhes disse: Os reis dos gentios dominam sobre eles, e os que têm autoridade sobre eles são chamados benfeitores.',
        'Mas não sereis vós assim; antes o maior entre vós seja como o menor; e quem governa como quem serve.',
        'Pois qual é maior: quem está à mesa, ou quem serve? Porventura não é quem está à mesa? Eu, porém, entre vós sou como aquele que serve.',
        'E vós sois os que tendes permanecido comigo nas minhas tentações.',
        'E eu vos destino o reino, como meu Pai mo destinou,',
        'Para que comais e bebais à minha mesa no meu reino, e vos assenteis sobre tronos, julgando as doze tribos de Israel.',
        'Disse também o Senhor: Simão, Simão, eis que Satanás vos pediu para vos cirandar como trigo;',
        'Mas eu roguei por ti, para que a tua fé não desfaleça; e tu, quando te converteres, confirma teus irmãos.',
        'E ele lhe disse: Senhor, estou pronto a ir contigo até à prisão e à morte.',
        'Mas ele disse: Digo-te, Pedro, que não cantará hoje o galo antes que três vezes negues que me conheces.',
        'E disse-lhes: Quando vos mandei sem bolsa, alforje, ou alparcas, faltou-vos porventura alguma coisa? Eles responderam: Nada.',
        'Disse-lhes pois: Mas agora, aquele que tiver bolsa, tome-a, como também o alforje; e, o que não tem espada, venda a sua capa e compre-a;',
        'Porquanto vos digo que importa que em mim se cumpra aquilo que está escrito: E com os malfeitores foi contado. Porque o que está escrito de mim terá cumprimento.',
        'E eles disseram: Senhor, eis aqui duas espadas. E ele lhes disse: Basta.',
        'E, saindo, foi, como costumava, para o Monte das Oliveiras; e também os seus discípulos o seguiram.',
        'E quando chegou àquele lugar, disse-lhes: Orai, para que não entreis em tentação.',
        'E apartou-se deles cerca de um tiro de pedra; e, pondo-se de joelhos, orava,',
        'Dizendo: Pai, se queres, passa de mim este cálice; todavia não se faça a minha vontade, mas a tua.',
        'E apareceu-lhe um anjo do céu, que o fortalecia.',
        'E, posto em agonia, orava mais intensamente. E o seu suor tornou-se como grandes gotas de sangue, que corriam até ao chão.',
        'E, levantando-se da oração, veio para os seus discípulos, e achou-os dormindo de tristeza.',
        'E disse-lhes: Por que estais dormindo? Levantai-vos, e orai, para que não entreis em tentação.',
        'E, estando ele ainda a falar, surgiu uma multidão; e um dos doze, que se chamava Judas, ia adiante dela, e chegou-se a Jesus para o beijar.',
        'E Jesus lhe disse: Judas, com um beijo trais o Filho do homem?',
        'E, vendo os que estavam com ele o que ia suceder, disseram-lhe: Senhor, feriremos à espada?',
        'E um deles feriu o servo do sumo sacerdote, e cortou-lhe a orelha direita.',
        'E, respondendo Jesus, disse: Deixai-os; basta. E, tocando-lhe a orelha, o curou.',
        'E disse Jesus aos principais dos sacerdotes, e capitães do templo, e anciãos, que tinham ido contra ele: Saístes, como a um salteador, com espadas e varapaus?',
        'Tenho estado todos os dias convosco no templo, e não estendestes as mãos contra mim, mas esta é a vossa hora e o poder das trevas.',
        'Então, prendendo-o, o levaram, e o puseram em casa do sumo sacerdote. E Pedro seguia-o de longe.',
        'E, havendo-se acendido fogo no meio do pátio, estando todos sentados, assentou-se Pedro entre eles.',
        'E como certa criada, vendo-o estar assentado ao fogo, pusesse os olhos nele, disse: Este também estava com ele.',
        'Porém, ele negou-o, dizendo: Mulher, não o conheço.',
        'E, um pouco depois, vendo-o outro, disse: Tu és também deles. Mas Pedro disse: Homem, não sou.',
        'E, passada quase uma hora, um outro afirmava, dizendo: Também este verdadeiramente estava com ele, pois também é galileu.',
        'E Pedro disse: Homem, não sei o que dizes. E logo, estando ele ainda a falar, cantou o galo.',
        'E, virando-se o Senhor, olhou para Pedro, e Pedro lembrou-se da palavra do Senhor, como lhe havia dito: Antes que o galo cante hoje, me negarás três vezes.',
        'E, saindo Pedro para fora, chorou amargamente.',
        'E os homens que detinham Jesus zombavam dele, ferindo-o.',
        'E, vendando-lhe os olhos, feriam-no no rosto, e perguntavam-lhe, dizendo: Profetiza, quem é que te feriu?',
        'E outras muitas coisas diziam contra ele, blasfemando.',
        'E logo que foi dia ajuntaram-se os anciãos do povo, e os principais dos sacerdotes e os escribas, e o conduziram ao seu concílio, e lhe perguntaram:',
        'És tu o Cristo? Dize-no-lo. Ele replicou: Se vo-lo disser, não o crereis;',
        'E também, se vos perguntar, não me respondereis, nem me soltareis.',
        'Desde agora o Filho do homem se assentará à direita do poder de Deus.',
        'E disseram todos: Logo, és tu o Filho de Deus? E ele lhes disse: Vós dizeis que eu sou.',
        'Então disseram: De que mais testemunho necessitamos? pois nós mesmos o ouvimos da sua boca.',
      ],
      [
        'E, levantando-se toda a multidão deles, o levaram a Pilatos.',
        'E começaram a acusá-lo, dizendo: Havemos achado este pervertendo a nação, proibindo dar o tributo a César, e dizendo que ele mesmo é Cristo, o rei.',
        'E Pilatos perguntou-lhe, dizendo: Tu és o Rei dos Judeus? E ele, respondendo, disse-lhe: Tu o dizes.',
        'E disse Pilatos aos principais dos sacerdotes, e à multidão: Não acho culpa alguma neste homem.',
        'Mas eles insistiam cada vez mais, dizendo: Alvoroça o povo ensinando por toda a Judéia, começando desde a Galiléia até aqui.',
        'Então Pilatos, ouvindo falar da Galiléia perguntou se aquele homem era galileu.',
        'E, sabendo que era da jurisdição de Herodes, remeteu-o a Herodes, que também naqueles dias estava em Jerusalém.',
        'E Herodes, quando viu a Jesus, alegrou-se muito; porque havia muito que desejava vê-lo, por ter ouvido dele muitas coisas; e esperava que lhe veria fazer algum sinal.',
        'E interrogava-o com muitas palavras, mas ele nada lhe respondia.',
        'E estavam os principais dos sacerdotes, e os escribas, acusando-o com grande veemência.',
        'E Herodes, com os seus soldados, desprezou-o e, escarnecendo dele, vestiu-o de uma roupa resplandecente e tornou a enviá-lo a Pilatos.',
        'E no mesmo dia, Pilatos e Herodes entre si se fizeram amigos; pois dantes andavam em inimizade um com o outro.',
        'E, convocando Pilatos os principais dos sacerdotes, e os magistrados, e o povo,',
        'Disse-lhes: Haveis-me apresentado este homem como pervertedor do povo; e eis que, examinando-o na vossa presença, nenhuma culpa, das de que o acusais, acho neste homem.',
        'Nem mesmo Herodes, porque a ele vos remeti, e eis que não tem feito coisa alguma digna de morte.',
        'Castigá-lo-ei, pois, e soltá-lo-ei.',
        'E era-lhe necessário soltar-lhes um pela festa.',
        'Mas toda a multidão clamou a uma, dizendo: Fora daqui com este, e solta-nos Barrabás.',
        'O qual fora lançado na prisão por causa de uma sedição feita na cidade, e de um homicídio.',
        'Falou, pois, outra vez Pilatos, querendo soltar a Jesus.',
        'Mas eles clamavam em contrário, dizendo: Crucifica-o, crucifica-o.',
        'Então ele, pela terceira vez, lhes disse: Mas que mal fez este? Não acho nele culpa alguma de morte. Castigá-lo-ei pois, e soltá-lo-ei.',
        'Mas eles instavam com grandes gritos, pedindo que fosse crucificado. E os seus gritos, e os dos principais dos sacerdotes, prevaleciam.',
        'Então Pilatos julgou que devia fazer o que eles pediam.',
        'E soltou-lhes o que fora lançado na prisão por uma sedição e homicídio, que era o que pediam; mas entregou Jesus à vontade deles.',
        'E quando o iam levando, tomaram um certo Simão, cireneu, que vinha do campo, e puseram-lhe a cruz às costas, para que a levasse após Jesus.',
        'E seguia-o grande multidão de povo e de mulheres, as quais batiam nos peitos, e o lamentavam.',
        'Jesus, porém, voltando-se para elas, disse: Filhas de Jerusalém, não choreis por mim; chorai antes por vós mesmas, e por vossos filhos.',
        'Porque eis que hão de vir dias em que dirão: Bem-aventuradas as estéreis, e os ventres que não geraram, e os peitos que não amamentaram!',
        'Então começarão a dizer aos montes: Caí sobre nós, e aos outeiros: Cobri-nos.',
        'Porque, se ao madeiro verde fazem isto, que se fará ao seco?',
        'E também conduziram outros dois, que eram malfeitores, para com ele serem mortos.',
        'E, quando chegaram ao lugar chamado a Caveira, ali o crucificaram, e aos malfeitores, um à direita e outro à esquerda.',
        'E dizia Jesus: Pai, perdoa-lhes, porque não sabem o que fazem. E, repartindo as suas vestes, lançaram sortes.',
        'E o povo estava olhando. E também os príncipes zombavam dele, dizendo: Aos outros salvou, salve-se a si mesmo, se este é o Cristo, o escolhido de Deus.',
        'E também os soldados o escarneciam, chegando-se a ele, e apresentando-lhe vinagre.',
        'E dizendo: Se tu és o Rei dos Judeus, salva-te a ti mesmo.',
        'E também por cima dele, estava um título, escrito em letras gregas, romanas, e hebraicas: ESTE É O REI DOS JUDEUS.',
        'E um dos malfeitores que estavam pendurados blasfemava dele, dizendo: Se tu és o Cristo, salva-te a ti mesmo, e a nós.',
        'Respondendo, porém, o outro, repreendia-o, dizendo: Tu nem ainda temes a Deus, estando na mesma condenação?',
        'E nós, na verdade, com justiça, porque recebemos o que os nossos feitos mereciam; mas este nenhum mal fez.',
        'E disse a Jesus: Senhor, lembra-te de mim, quando entrares no teu reino.',
        'E disse-lhe Jesus: Em verdade te digo que hoje estarás comigo no Paraíso.',
        'E era já quase a hora sexta, e houve trevas em toda a terra até à hora nona, escurecendo-se o sol;',
        'E rasgou-se ao meio o véu do templo.',
        'E, clamando Jesus com grande voz, disse: Pai, nas tuas mãos entrego o meu espírito. E, havendo dito isto, expirou.',
        'E o centurião, vendo o que tinha acontecido, deu glória a Deus, dizendo: Na verdade, este homem era justo.',
        'E toda a multidão que se ajuntara a este espetáculo, vendo o que havia acontecido, voltava batendo nos peitos.',
        'E todos os seus conhecidos, e as mulheres que juntamente o haviam seguido desde a Galiléia, estavam de longe vendo estas coisas.',
        'E eis que um homem por nome José, senador, homem de bem e justo,',
        'Que não tinha consentido no conselho e nos atos dos outros, de Arimatéia, cidade dos judeus, e que também esperava o reino de Deus;',
        'Esse, chegando a Pilatos, pediu o corpo de Jesus.',
        'E, havendo-o tirado, envolveu-o num lençol, e pô-lo num sepulcro escavado numa penha, onde ninguém ainda havia sido posto.',
        'E era o dia da preparação, e amanhecia o sábado.',
        'E as mulheres, que tinham vindo com ele da Galiléia, seguiram também e viram o sepulcro, e como foi posto o seu corpo.',
        'E, voltando elas, prepararam especiarias e ungüentos; e no sábado repousaram, conforme o mandamento.',
      ],
      [
        'E no primeiro dia da semana, muito de madrugada, foram elas ao sepulcro, levando as especiarias que tinham preparado, e algumas outras com elas.',
        'E acharam a pedra revolvida do sepulcro.',
        'E, entrando, não acharam o corpo do Senhor Jesus.',
        'E aconteceu que, estando elas muito perplexas a esse respeito, eis que pararam junto delas dois homens, com vestes resplandecentes.',
        'E, estando elas muito atemorizadas, e abaixando o rosto para o chão, eles lhes disseram: Por que buscais o vivente entre os mortos?',
        'Não está aqui, mas ressuscitou. Lembrai-vos como vos falou, estando ainda na Galiléia,',
        'Dizendo: Convém que o Filho do homem seja entregue nas mãos de homens pecadores, e seja crucificado, e ao terceiro dia ressuscite.',
        'E lembraram-se das suas palavras.',
        'E, voltando do sepulcro, anunciaram todas estas coisas aos onze e a todos os demais.',
        'E eram Maria Madalena, e Joana, e Maria, mãe de Tiago, e as outras que com elas estavam, as que diziam estas coisas aos apóstolos.',
        'E as suas palavras lhes pareciam como desvario, e não as creram.',
        'Pedro, porém, levantando-se, correu ao sepulcro e, abaixando-se, viu só os lençóis ali postos; e retirou-se, admirando consigo aquele caso.',
        'E eis que no mesmo dia iam dois deles para uma aldeia, que distava de Jerusalém sessenta estádios, cujo nome era Emaús.',
        'E iam falando entre si de tudo aquilo que havia sucedido.',
        'E aconteceu que, indo eles falando entre si, e fazendo perguntas um ao outro, o mesmo Jesus se aproximou, e ia com eles.',
        'Mas os olhos deles estavam como que fechados, para que o não conhecessem.',
        'E ele lhes disse: Que palavras são essas que, caminhando, trocais entre vós, e por que estais tristes?',
        'E, respondendo um, cujo nome era Cléopas, disse-lhe: És tu só peregrino em Jerusalém, e não sabes as coisas que nela têm sucedido nestes dias?',
        'E ele lhes perguntou: Quais? E eles lhe disseram: As que dizem respeito a Jesus Nazareno, que foi homem profeta, poderoso em obras e palavras diante de Deus e de todo o povo;',
        'E como os principais dos sacerdotes e os nossos príncipes o entregaram à condenação de morte, e o crucificaram.',
        'E nós esperávamos que fosse ele o que remisse Israel; mas agora, sobre tudo isso, é já hoje o terceiro dia desde que essas coisas aconteceram.',
        'É verdade que também algumas mulheres dentre nós nos maravilharam, as quais de madrugada foram ao sepulcro;',
        'E, não achando o seu corpo, voltaram, dizendo que também tinham visto uma visão de anjos, que dizem que ele vive.',
        'E alguns dos que estavam conosco foram ao sepulcro, e acharam ser assim como as mulheres haviam dito; porém, a ele não o viram.',
        'E ele lhes disse: Ó néscios, e tardos de coração para crer tudo o que os profetas disseram!',
        'Porventura não convinha que o Cristo padecesse estas coisas e entrasse na sua glória?',
        'E, começando por Moisés, e por todos os profetas, explicava-lhes o que dele se achava em todas as Escrituras.',
        'E chegaram à aldeia para onde iam, e ele fez como quem ia para mais longe.',
        'E eles o constrangeram, dizendo: Fica conosco, porque já é tarde, e já declinou o dia. E entrou para ficar com eles.',
        'E aconteceu que, estando com eles à mesa, tomando o pão, o abençoou e partiu-o, e lho deu.',
        'Abriram-se-lhes então os olhos, e o conheceram, e ele desapareceu-lhes.',
        'E disseram um para o outro: Porventura não ardia em nós o nosso coração quando, pelo caminho, nos falava, e quando nos abria as Escrituras?',
        'E na mesma hora, levantando-se, tornaram para Jerusalém, e acharam congregados os onze, e os que estavam com eles,',
        'Os quais diziam: Ressuscitou verdadeiramente o Senhor, e já apareceu a Simão.',
        'E eles lhes contaram o que lhes acontecera no caminho, e como deles fora conhecido no partir do pão.',
        'E falando eles destas coisas, o mesmo Jesus se apresentou no meio deles, e disse-lhes: Paz seja convosco.',
        'E eles, espantados e atemorizados, pensavam que viam algum espírito.',
        'E ele lhes disse: Por que estais perturbados, e por que sobem tais pensamentos aos vossos corações?',
        'Vede as minhas mãos e os meus pés, que sou eu mesmo; apalpai-me e vede, pois um espírito não tem carne nem ossos, como vedes que eu tenho.',
        'E, dizendo isto, mostrou-lhes as mãos e os pés.',
        'E, não o crendo eles ainda por causa da alegria, e estando maravilhados, disse-lhes: Tendes aqui alguma coisa que comer?',
        'Então eles apresentaram-lhe parte de um peixe assado, e um favo de mel;',
        'O que ele tomou, e comeu diante deles.',
        'E disse-lhes: São estas as palavras que vos disse estando ainda convosco: Que convinha que se cumprisse tudo o que de mim estava escrito na lei de Moisés, e nos profetas e nos Salmos.',
        'Então abriu-lhes o entendimento para compreenderem as Escrituras.',
        'E disse-lhes: Assim está escrito, e assim convinha que o Cristo padecesse, e ao terceiro dia ressuscitasse dentre os mortos,',
        'E em seu nome se pregasse o arrependimento e a remissão dos pecados, em todas as nações, começando por Jerusalém.',
        'E destas coisas sois vós testemunhas.',
        'E eis que sobre vós envio a promessa de meu Pai; ficai, porém, na cidade de Jerusalém, até que do alto sejais revestidos de poder.',
        'E levou-os fora, até betânia; e, levantando as suas mãos, os abençoou.',
        'E aconteceu que, abençoando-os ele, se apartou deles e foi elevado ao céu.',
        'E, adorando-o eles, tornaram com grande júbilo para Jerusalém.',
        'E estavam sempre no templo, louvando e bendizendo a Deus. Amém.',
      ],
    ],
    livro: 'lucas',
  },
  {
    abbrev: 'jo',
    capitulos: [
      [
        'No princípio era o Verbo, e o Verbo estava com Deus, e o Verbo era Deus.',
        'Ele estava no princípio com Deus.',
        'Todas as coisas foram feitas por ele, e sem ele nada do que foi feito se fez.',
        'Nele estava a vida, e a vida era a luz dos homens.',
        'E a luz resplandece nas trevas, e as trevas não a compreenderam.',
        'Houve um homem enviado de Deus, cujo nome era João.',
        'Este veio para testemunho, para que testificasse da luz, para que todos cressem por ele.',
        'Não era ele a luz, mas para que testificasse da luz.',
        'Ali estava a luz verdadeira, que ilumina a todo o homem que vem ao mundo.',
        'Estava no mundo, e o mundo foi feito por ele, e o mundo não o conheceu.',
        'Veio para o que era seu, e os seus não o receberam.',
        'Mas, a todos quantos o receberam, deu-lhes o poder de serem feitos filhos de Deus, aos que crêem no seu nome;',
        'Os quais não nasceram do sangue, nem da vontade da carne, nem da vontade do homem, mas de Deus.',
        'E o Verbo se fez carne, e habitou entre nós, e vimos a sua glória, como a glória do unigênito do Pai, cheio de graça e de verdade.',
        'João testificou dele, e clamou, dizendo: Este era aquele de quem eu dizia: O que vem após mim é antes de mim, porque foi primeiro do que eu.',
        'E todos nós recebemos também da sua plenitude, e graça por graça.',
        'Porque a lei foi dada por Moisés; a graça e a verdade vieram por Jesus Cristo.',
        'Deus nunca foi visto por alguém. O Filho unigênito, que está no seio do Pai, esse o revelou.',
        'E este é o testemunho de João, quando os judeus mandaram de Jerusalém sacerdotes e levitas para que lhe perguntassem: Quem és tu?',
        'E confessou, e não negou; confessou: Eu não sou o Cristo.',
        'E perguntaram-lhe: Então quê? És tu Elias? E disse: Não sou. És tu profeta? E respondeu: Não.',
        'Disseram-lhe pois: Quem és? para que demos resposta àqueles que nos enviaram; que dizes de ti mesmo?',
        'Disse: Eu sou a voz do que clama no deserto: Endireitai o caminho do Senhor, como disse o profeta Isaías.',
        'E os que tinham sido enviados eram dos fariseus.',
        'E perguntaram-lhe, e disseram-lhe: Por que batizas, pois, se tu não és o Cristo, nem Elias, nem o profeta?',
        'João respondeu-lhes, dizendo: Eu batizo com água; mas no meio devós está um a quem vós não conheceis.',
        'Este é aquele que vem após mim, que é antes de mim, do qual eu não sou digno de desatar a correia da alparca.',
        'Estas coisas aconteceram em Betabara, do outro lado do Jordão, onde João estava batizando.',
        'No dia seguinte João viu a Jesus, que vinha para ele, e disse: Eis o Cordeiro de Deus, que tira o pecado do mundo.',
        'Este é aquele do qual eu disse: Após mim vem um homem que é antes de mim, porque foi primeiro do que eu.',
        'E eu não o conhecia; mas, para que ele fosse manifestado a Israel, vim eu, por isso, batizando com água.',
        'E João testificou, dizendo: Eu vi o Espírito descer do céu como pomba, e repousar sobre ele.',
        'E eu não o conhecia, mas o que me mandou a batizar com água, esse me disse: Sobre aquele que vires descer o Espírito, e sobre ele repousar, esse é o que batiza com o Espírito Santo.',
        'E eu vi, e tenho testificado que este é o Filho de Deus.',
        'No dia seguinte João estava outra vez ali, e dois dos seus discípulos;',
        'E, vendo passar a Jesus, disse: Eis aqui o Cordeiro de Deus.',
        'E os dois discípulos ouviram-no dizer isto, e seguiram a Jesus.',
        'E Jesus, voltando-se e vendo que eles o seguiam, disse-lhes: Que buscais? E eles disseram: Rabi (que, traduzido, quer dizer Mestre), onde moras?',
        'Ele lhes disse: Vinde, e vede. Foram, e viram onde morava, e ficaram com ele aquele dia; e era já quase a hora décima.',
        'Era André, irmão de Simão Pedro, um dos dois que ouviram aquilo de João, e o haviam seguido.',
        'Este achou primeiro a seu irmão Simão, e disse-lhe: Achamos o Messias (que, traduzido, é o Cristo).',
        'E levou-o a Jesus. E, olhando Jesus para ele, disse: Tu és Simão, filho de Jonas; tu serás chamado Cefas (que quer dizer Pedro).',
        'No dia seguinte quis Jesus ir à Galiléia, e achou a Filipe, e disse-lhe: Segue-me.',
        'E Filipe era de Betsaida, cidade de André e de Pedro.',
        'Filipe achou Natanael, e disse-lhe: Havemos achado aquele de quem Moisés escreveu na lei, e os profetas: Jesus de Nazaré, filho de José.',
        'Disse-lhe Natanael: Pode vir alguma coisa boa de Nazaré? Disse-lhe Filipe: Vem, e vê.',
        'Jesus viu Natanael vir ter com ele, e disse dele: Eis aqui um verdadeiro israelita, em quem não há dolo.',
        'Disse-lhe Natanael: De onde me conheces tu? Jesus respondeu, e disse-lhe: Antes que Filipe te chamasse, te vi eu, estando tu debaixo da figueira.',
        'Natanael respondeu, e disse-lhe: Rabi, tu és o Filho de Deus; tu és o Rei de Israel.',
        'Jesus respondeu, e disse-lhe: Porque te disse: Vi-te debaixo da figueira, crês? Coisas maiores do que estas verás.',
        'E disse-lhe: Na verdade, na verdade vos digo que daqui em diante vereis o céu aberto, e os anjos de Deus subindo e descendo sobre o Filho do homem.',
      ],
      [
        'E, ao terceiro dia, fizeram-se umas bodas em Caná da Galiléia; e estava ali a mãe de Jesus.',
        'E foi também convidado Jesus e os seus discípulos para as bodas.',
        'E, faltando vinho, a mãe de Jesus lhe disse: Não têm vinho.',
        'Disse-lhe Jesus: Mulher, que tenho eu contigo? Ainda não é chegada a minha hora.',
        'Sua mãe disse aos serventes: Fazei tudo quanto ele vos disser.',
        'E estavam ali postas seis talhas de pedra, para as purificações dos judeus, e em cada uma cabiam dois ou três almudes.',
        'Disse-lhes Jesus: Enchei de água essas talhas. E encheram-nas até em cima.',
        'E disse-lhes: Tirai agora, e levai ao mestre-sala. E levaram.',
        'E, logo que o mestre-sala provou a água feita vinho (não sabendo de onde viera, se bem que o sabiam os serventes que tinham tirado a água), chamou o mestre-sala ao esposo,',
        'E disse-lhe: Todo o homem põe primeiro o vinho bom e, quando já têm bebido bem, então o inferior; mas tu guardaste até agora o bom vinho.',
        'Jesus principiou assim os seus sinais em Caná da Galiléia, e manifestou a sua glória; e os seus discípulos creram nele.',
        'Depois disto desceu a Cafarnaum, ele, e sua mãe, e seus irmãos, e seus discípulos; e ficaram ali não muitos dias.',
        'E estava próxima a páscoa dos judeus, e Jesus subiu a Jerusalém.',
        'E achou no templo os que vendiam bois, e ovelhas, e pombos, e os cambiadores assentados.',
        'E tendo feito um azorrague de cordéis, lançou todos fora do templo, também os bois e ovelhas; e espalhou o dinheiro dos cambiadores, e derribou as mesas;',
        'E disse aos que vendiam pombos: Tirai daqui estes, e não façais da casa de meu Pai casa de venda.',
        'E os seus discípulos lembraram-se do que está escrito: O zelo da tua casa me devorou.',
        'Responderam, pois, os judeus, e disseram-lhe: Que sinal nos mostras para fazeres isto?',
        'Jesus respondeu, e disse-lhes: Derribai este templo, e em três dias o levantarei.',
        'Disseram, pois, os judeus: Em quarenta e seis anos foi edificado este templo, e tu o levantarás em três dias?',
        'Mas ele falava do templo do seu corpo.',
        'Quando, pois, ressuscitou dentre os mortos, os seus discípulos lembraram-se de que lhes dissera isto; e creram na Escritura, e na palavra que Jesus tinha dito.',
        'E, estando ele em Jerusalém pela páscoa, durante a festa, muitos, vendo os sinais que fazia, creram no seu nome.',
        'Mas o mesmo Jesus não confiava neles, porque a todos conhecia;',
        'E não necessitava de que alguém testificasse do homem, porque ele bem sabia o que havia no homem.',
      ],
      [
        'E havia entre os fariseus um homem, chamado Nicodemos, príncipe dos judeus.',
        'Este foi ter de noite com Jesus, e disse-lhe: Rabi, bem sabemos que és Mestre, vindo de Deus; porque ninguém pode fazer estes sinais que tu fazes, se Deus não for com ele.',
        'Jesus respondeu, e disse-lhe: Na verdade, na verdade te digo que aquele que não nascer de novo, não pode ver o reino de Deus.',
        'Disse-lhe Nicodemos: Como pode um homem nascer, sendo velho? Pode, porventura, tornar a entrar no ventre de sua mãe, e nascer?',
        'Jesus respondeu: Na verdade, na verdade te digo que aquele que não nascer da água e do Espírito, não pode entrar no reino de Deus.',
        'O que é nascido da carne é carne, e o que é nascido do Espírito é espírito.',
        'Não te maravilhes de te ter dito: Necessário vos é nascer de novo.',
        'O vento assopra onde quer, e ouves a sua voz, mas não sabes de onde vem, nem para onde vai; assim étodo aquele que é nascido do Espírito.',
        'Nicodemos respondeu, e disse-lhe: Como pode ser isso?',
        'Jesus respondeu, e disse-lhe: Tu és mestre de Israel, e não sabes isto?',
        'Na verdade, na verdade te digo que nós dizemos o que sabemos, e testificamos o que vimos; e não aceitais o nosso testemunho.',
        'Se vos falei de coisas terrestres, e não crestes, como crereis, se vos falar das celestiais?',
        'Ora, ninguém subiu ao céu, senão o que desceu do céu, o Filho do homem, que está no céu.',
        'E, como Moisés levantou a serpente no deserto, assim importa que o Filho do homem seja levantado;',
        'Para que todo aquele que nele crê não pereça, mas tenha a vida eterna.',
        'Porque Deus amou o mundo de tal maneira que deu o seu Filho unigênito, para que todo aquele que nele crê não pereça, mas tenha a vida eterna.',
        'Porque Deus enviou o seu Filho ao mundo, não para que condenasse o mundo, mas para que o mundo fosse salvo por ele.',
        'Quem crê nele não é condenado; mas quem não crê já está condenado, porquanto não crê no nome do unigênito Filho de Deus.',
        'E a condenação é esta: Que a luz veio ao mundo, e os homens amaram mais as trevas do que a luz, porque as suas obras eram más.',
        'Porque todo aquele que faz o mal odeia a luz, e não vem para a luz, para que as suas obras não sejam reprovadas.',
        'Mas quem pratica a verdade vem para a luz, a fim de que as suas obras sejam manifestas, porque são feitas em Deus.',
        'Depois disto foi Jesus com os seus discípulos para a terra da Judéia; e estava ali com eles, e batizava.',
        'Ora, João batizava também em Enom, junto a Salim, porque havia ali muitas águas; e vinham ali, e eram batizados.',
        'Porque ainda João não tinha sido lançado na prisão.',
        'Houve então uma questão entre os discípulos de João e os judeus acerca da purificação.',
        'E foram ter com João, e disseram-lhe: Rabi, aquele que estava contigo além do Jordão, do qual tu deste testemunho, ei-lo batizando, e todos vão ter com ele.',
        'João respondeu, e disse: O homem não pode receber coisa alguma, se não lhe for dada do céu.',
        'Vós mesmos me sois testemunhas de que disse: Eu não sou o Cristo, mas sou enviado adiante dele.',
        'Aquele que tem a esposa é o esposo; mas o amigo do esposo, que lhe assiste e o ouve, alegra-se muito com a voz do esposo. Assim, pois, já este meu gozo está cumprido.',
        'É necessário que ele cresça e que eu diminua.',
        'Aquele que vem de cima é sobre todos; aquele que vem da terra é da terra e fala da terra. Aquele que vem do céu é sobre todos.',
        'E aquilo que ele viu e ouviu isso testifica; e ninguém aceita o seu testemunho.',
        'Aquele que aceitou o seu testemunho, esse confirmou que Deus é verdadeiro.',
        'Porque aquele que Deus enviou fala as palavras de Deus; pois não lhe dá Deus o Espírito por medida.',
        'O Pai ama o Filho, e todas as coisas entregou nas suas mãos.',
        'Aquele que crê no Filho tem a vida eterna; mas aquele que não crê no Filho não verá a vida, mas a ira de Deus sobre ele permanece.',
      ],
      [
        'E quando o Senhor entendeu que os fariseus tinham ouvido que Jesus fazia e batizava mais discípulos do que João',
        '(Ainda que Jesus mesmo não batizava, mas os seus discípulos),',
        'Deixou a Judéia, e foi outra vez para a Galiléia.',
        'E era-lhe necessário passar por Samaria.',
        'Foi, pois, a uma cidade de Samaria, chamada Sicar, junto da herdade que Jacó tinha dado a seu filho José.',
        'E estava ali a fonte de Jacó. Jesus, pois, cansado do caminho, assentou-se assim junto da fonte. Era isto quase à hora sexta.',
        'Veio uma mulher de Samaria tirar água. Disse-lhe Jesus: Dá-me de beber.',
        'Porque os seus discípulos tinham ido à cidade comprar comida.',
        'Disse-lhe, pois, a mulher samaritana: Como, sendo tu judeu, me pedes de beber a mim, que sou mulher samaritana? (porque os judeus não se comunicam com os samaritanos).',
        'Jesus respondeu, e disse-lhe: Se tu conheceras o dom de Deus, e quem é o que te diz: Dá-me de beber, tu lhe pedirias, e ele te daria água viva.',
        'Disse-lhe a mulher: Senhor, tu não tens com que a tirar, e o poço é fundo; onde, pois, tens a água viva?',
        'És tu maior do que o nosso pai Jacó, que nos deu o poço, bebendo ele próprio dele, e os seus filhos, e o seu gado?',
        'Jesus respondeu, e disse-lhe: Qualquer que beber desta água tornará a ter sede;',
        'Mas aquele que beber da água que eu lhe der nunca terá sede, porque a água que eu lhe der se fará nele uma fonte de água que salte para a vida eterna.',
        'Disse-lhe a mulher: Senhor, dá-me dessa água, para que não mais tenha sede, e não venha aqui tirá-la.',
        'Disse-lhe Jesus: Vai, chama o teu marido, e vem cá.',
        'A mulher respondeu, e disse: Não tenho marido. Disse-lhe Jesus: Disseste bem: Não tenho marido;',
        'Porque tiveste cinco maridos, e o que agora tens não é teu marido; isto disseste com verdade.',
        'Disse-lhe a mulher: Senhor, vejo que és profeta.',
        'Nossos pais adoraram neste monte, e vós dizeis que é em Jerusalém o lugar onde se deve adorar.',
        'Disse-lhe Jesus: Mulher, crê-me que a hora vem, em que nem neste monte nem em Jerusalém adorareis o Pai.',
        'Vós adorais o que não sabeis; nós adoramos o que sabemos porque a salvação vem dos judeus.',
        'Mas a hora vem, e agora é, em que os verdadeiros adoradores adorarão o Pai em espírito e em verdade; porque o Pai procura a tais que assim o adorem.',
        'Deus é Espírito, e importa que os que o adoram o adorem em espírito e em verdade.',
        'A mulher disse-lhe: Eu sei que o Messias (que se chama o Cristo) vem; quando ele vier, nos anunciará tudo.',
        'Jesus disse-lhe: Eu o sou, eu que falo contigo.',
        'E nisto vieram os seus discípulos, e maravilharam-se de que estivesse falando com uma mulher; todavia nenhum lhe disse: Que perguntas? ou: Por que falas com ela?',
        'Deixou, pois, a mulher o seu cântaro, e foi à cidade, e disse àqueles homens:',
        'Vinde, vede um homem que me disse tudo quanto tenho feito. Porventura não é este o Cristo?',
        'Saíram, pois, da cidade, e foram ter com ele.',
        'E entretanto os seus discípulos lhe rogaram, dizendo: Rabi, come.',
        'Ele, porém, lhes disse: Uma comida tenho para comer, que vós não conheceis.',
        'Então os discípulos diziam uns aos outros: Trouxe-lhe, porventura, alguém algo de comer?',
        'Jesus disse-lhes: A minha comida é fazer a vontade daquele que me enviou, e realizar a sua obra.',
        'Não dizeis vós que ainda há quatro meses até que venha a ceifa? Eis que eu vos digo: Levantai os vossos olhos, e vede as terras, que já estão brancas para a ceifa.',
        'E o que ceifa recebe galardão, e ajunta fruto para a vida eterna; para que, assim o que semeia como o que ceifa, ambos se regozijem.',
        'Porque nisto é verdadeiro o ditado, que um é o que semeia, e outro o que ceifa.',
        'Eu vos enviei a ceifar onde vós não trabalhastes; outros trabalharam, e vós entrastes no seu trabalho.',
        'E muitos dos samaritanos daquela cidade creram nele, pela palavra da mulher, que testificou: Disse-me tudo quanto tenho feito.',
        'Indo, pois, ter com ele os samaritanos, rogaram-lhe que ficasse com eles; e ficou ali dois dias.',
        'E muitos mais creram nele, por causa da sua palavra.',
        'E diziam à mulher: Já não é pelo teu dito que nós cremos; porque nós mesmos o temos ouvido, e sabemos que este é verdadeiramente o Cristo, o Salvador do mundo.',
        'E dois dias depois partiu dali, e foi para a Galiléia.',
        'Porque Jesus mesmo testificou que um profeta não tem honra na sua própria pátria.',
        'Chegando, pois, à Galiléia, os galileus o receberam, vistas todas as coisas que fizera em Jerusalém, no dia da festa; porque também eles tinham ido à festa.',
        'Segunda vez foi Jesus a Caná da Galiléia, onde da água fizera vinho. E havia ali um nobre, cujo filho estava enfermo em Cafarnaum.',
        'Ouvindo este que Jesus vinha da Judéia para a Galiléia, foi ter com ele, e rogou-lhe que descesse, e curasse o seu filho, porque já estava à morte.',
        'Então Jesus lhe disse: Se não virdes sinais e milagres, não crereis.',
        'Disse-lhe o nobre: Senhor, desce, antes que meu filho morra.',
        'Disse-lhe Jesus: Vai, o teu filho vive. E o homem creu na palavra que Jesus lhe disse, e partiu.',
        'E descendo ele logo, saíram-lhe ao encontro os seus servos, e lhe anunciaram, dizendo: O teu filho vive.',
        'Perguntou-lhes, pois, a que hora se achara melhor. E disseram-lhe: Ontem às sete horas a febre o deixou.',
        'Entendeu, pois, o pai que era aquela hora a mesma em que Jesus lhe disse: O teu filho vive; e creu ele, e toda a sua casa.',
        'Jesus fez este segundo milagre, quando ia da Judéia para a Galiléia.',
      ],
      [
        'Depois disto havia uma festa entre os judeus, e Jesus subiu a Jerusalém.',
        'Ora, em Jerusalém há, próximo à porta das ovelhas, um tanque, chamado em hebreu Betesda, o qual tem cinco alpendres.',
        'Nestes jazia grande multidão de enfermos, cegos, mancos e ressicados, esperando o movimento da água.',
        'Porquanto um anjo descia em certo tempo ao tanque, e agitava a água; e o primeiro que ali descia, depois do movimento da água, sarava de qualquer enfermidade que tivesse.',
        'E estava ali um homem que, havia trinta e oito anos, se achava enfermo.',
        'E Jesus, vendo este deitado, e sabendo que estava neste estado havia muito tempo, disse-lhe: Queres ficar são?',
        'O enfermo respondeu-lhe: Senhor, não tenho homem algum que, quando a água é agitada, me ponha no tanque; mas, enquanto eu vou, desce outro antes de mim.',
        'Jesus disse-lhe: Levanta-te, toma o teu leito, e anda.',
        'Logo aquele homem ficou são; e tomou o seu leito, e andava. E aquele dia era sábado.',
        'Então os judeus disseram àquele que tinha sido curado: É sábado, não te é lícito levar o leito.',
        'Ele respondeu-lhes: Aquele que me curou, ele próprio disse: Toma o teu leito, e anda.',
        'Perguntaram-lhe, pois: Quem é o homem que te disse: Toma o teu leito, e anda?',
        'E o que fora curado não sabia quem era; porque Jesus se havia retirado, em razão de naquele lugar haver grande multidão.',
        'Depois Jesus encontrou-o no templo, e disse-lhe: Eis que já estás são; não peques mais, para que não te suceda alguma coisa pior.',
        'E aquele homem foi, e anunciou aos judeus que Jesus era o que o curara.',
        'E por esta causa os judeus perseguiram a Jesus, e procuravam matá-lo, porque fazia estas coisas no sábado.',
        'E Jesus lhes respondeu: Meu Pai trabalha até agora, e eu trabalho também.',
        'Por isso, pois, os judeus ainda mais procuravam matá-lo, porque não só quebrantava o sábado, mas também dizia que Deus era seu próprio Pai, fazendo-se igual a Deus.',
        'Mas Jesus respondeu, e disse-lhes: Na verdade, na verdade vos digo que o Filho por si mesmo não pode fazer coisa alguma, se o não vir fazer o Pai; porque tudo quanto ele faz, o Filho o faz igualmente.',
        'Porque o Pai ama o Filho, e mostra-lhe tudo o que faz; e ele lhe mostrará maiores obras do que estas, para que vos maravilheis.',
        'Pois, assim como o Pai ressuscita os mortos, e os vivifica, assim também o Filho vivifica aqueles que quer.',
        'E também o Pai a ninguém julga, mas deu ao Filho todo o juízo;',
        'Para que todos honrem o Filho, como honram o Pai. Quem não honra o Filho, não honra o Pai que o enviou.',
        'Na verdade, na verdade vos digo que quem ouve a minha palavra, e crê naquele que me enviou, tem a vida eterna, e não entrará em condenação, mas passou da morte para a vida.',
        'Em verdade, em verdade vos digo que vem a hora, e agora é, em que os mortos ouvirão a voz do Filho de Deus, e os que a ouvirem viverão.',
        'Porque, como o Pai tem a vida em si mesmo, assim deu também ao Filho ter a vida em si mesmo;',
        'E deu-lhe o poder de exercer o juízo, porque é o Filho do homem.',
        'Não vos maravilheis disto; porque vem a hora em que todos os que estão nos sepulcros ouvirão a sua voz.',
        'E os que fizeram o bem sairão para a ressurreição da vida; e os que fizeram o mal para a ressurreição da condenação.',
        'Eu não posso de mim mesmo fazer coisa alguma. Como ouço, assim julgo; e o meu juízo é justo, porque não busco a minha vontade, mas a vontade do Pai que me enviou.',
        'Se eu testifico de mim mesmo, o meu testemunho não é verdadeiro.',
        'Há outro que testifica de mim, e sei que o testemunho que ele dá de mim é verdadeiro.',
        'Vós mandastes mensageiros a João, e ele deu testemunho da verdade.',
        'Eu, porém, não recebo testemunho de homem; mas digo isto, para que vos salveis.',
        'Ele era a candeia que ardia e alumiava, e vós quisestes alegrar-vos por um pouco de tempo com a sua luz.',
        'Mas eu tenho maior testemunho do que o de João; porque as obras que o Pai me deu para realizar, as mesmas obras que eu faço, testificam de mim, que o Pai me enviou.',
        'E o Pai, que me enviou, ele mesmo testificou de mim. Vós nunca ouvistes a sua voz, nem vistes o seu parecer.',
        'E a sua palavra não permanece em vós, porque naquele que ele enviou não credes vós.',
        'Examinais as Escrituras, porque vós cuidais ter nelas a vida eterna, e são elas que de mim testificam;',
        'E não quereis vir a mim para terdes vida.',
        'Eu não recebo glória dos homens;',
        'Mas bem vos conheço, que não tendes em vós o amor de Deus.',
        'Eu vim em nome de meu Pai, e não me aceitais; se outro vier em seu próprio nome, a esse aceitareis.',
        'Como podeis vós crer, recebendo honra uns dos outros, e não buscando a honra que vem só de Deus?',
        'Não cuideis que eu vos hei de acusar para com o Pai. Há um que vos acusa, Moisés, em quem vós esperais.',
        'Porque, se vós crêsseis em Moisés, creríeis em mim; porque de mim escreveu ele.',
        'Mas, se não credes nos seus escritos, como crereis nas minhas palavras?',
      ],
      [
        'Depois disto partiu Jesus para o outro lado do mar da Galiléia, que é o de Tiberíades.',
        'E grande multidão o seguia, porque via os sinais que operava sobre os enfermos.',
        'E Jesus subiu ao monte, e assentou-se ali com os seus discípulos.',
        'E a páscoa, a festa dos judeus, estava próxima.',
        'Então Jesus, levantando os olhos, e vendo que uma grande multidão vinha ter com ele, disse a Filipe: Onde compraremos pão, para estes comerem?',
        'Mas dizia isto para o experimentar; porque ele bem sabia o que havia de fazer.',
        'Filipe respondeu-lhe: Duzentos dinheiros de pão não lhes bastarão, para que cada um deles tome um pouco.',
        'E um dos seus discípulos, André, irmão de Simão Pedro, disse-lhe:',
        'Está aqui um rapaz que tem cinco pães de cevada e dois peixinhos; mas que é isto para tantos?',
        'E disse Jesus: Mandai assentar os homens. E havia muita relva naquele lugar. Assentaram-se, pois, os homens em número de quase cinco mil.',
        'E Jesus tomou os pães e, havendo dado graças, repartiu-os pelos discípulos, e os discípulos pelos que estavam assentados; e igualmente também dos peixes, quanto eles queriam.',
        'E, quando estavam saciados, disse aos seus discípulos: Recolhei os pedaços que sobejaram, para que nada se perca.',
        'Recolheram-nos, pois, e encheram doze alcofas de pedaços dos cinco pães de cevada, que sobejaram aos que haviam comido.',
        'Vendo, pois, aqueles homens o milagre que Jesus tinha feito, diziam: Este é verdadeiramente o profeta que devia vir ao mundo.',
        'Sabendo, pois, Jesus que haviam de vir arrebatá-lo, para o fazerem rei, tornou a retirar-se, ele só, para o monte.',
        'E, quando veio a tarde, os seus discípulos desceram para o mar.',
        'E, entrando no barco, atravessaram o mar em direção a Cafarnaum; e era já escuro, e ainda Jesus não tinha chegado ao pé deles.',
        'E o mar se levantou, porque um grande vento assoprava.',
        'E, tendo navegado uns vinte e cinco ou trinta estádios, viram a Jesus, andando sobre o mar e aproximando-se do barco; e temeram.',
        'Mas ele lhes disse: Sou eu, não temais.',
        'Então eles de boa mente o receberam no barco; e logo o barco chegou à terra para onde iam.',
        'No dia seguinte, a multidão que estava do outro lado do mar, vendo que não havia ali mais do que um barquinho, a não ser aquele no qual os discípulos haviam entrado, e que Jesus não entrara com os seus discípulos naquele barquinho, mas que os seus discípulos tinham ido sozinhos',
        '(Contudo, outros barquinhos tinham chegado de Tiberíades, perto do lugar onde comeram o pão, havendo o Senhor dado graças).',
        'Vendo, pois, a multidão que Jesus não estava ali nem os seus discípulos, entraram eles também nos barcos, e foram a Cafarnaum, em busca de Jesus.',
        'E, achando-o no outro lado do mar, disseram-lhe: Rabi, quando chegaste aqui?',
        'Jesus respondeu-lhes e disse: Na verdade, na verdade vos digo que me buscais, não pelos sinais que vistes, mas porque comestes do pão e vos saciastes.',
        'Trabalhai, não pela comida que perece, mas pela comida que permanece para a vida eterna, a qual o Filho do homem vos dará; porque a este o Pai, Deus, o selou.',
        'Disseram-lhe, pois: Que faremos para executarmos as obras de Deus?',
        'Jesus respondeu, e disse-lhes: A obra de Deus é esta: Que creiais naquele que ele enviou.',
        'Disseram-lhe, pois: Que sinal, pois, fazes tu, para que o vejamos, e creiamos em ti? Que operas tu?',
        'Nossos pais comeram o maná no deserto, como está escrito: Deu-lhes a comer o pão do céu.',
        'Disse-lhes, pois, Jesus: Na verdade, na verdade vos digo: Moisés não vos deu o pão do céu; mas meu Pai vos dá o verdadeiro pão do céu.',
        'Porque o pão de Deus é aquele que desce do céu e dá vida ao mundo.',
        'Disseram-lhe, pois: Senhor, dá-nos sempre desse pão.',
        'E Jesus lhes disse: Eu sou o pão da vida; aquele que vem a mim não terá fome, e quem crê em mim nunca terá sede.',
        'Mas já vos disse que também vós me vistes, e contudo não credes.',
        'Todo o que o Pai me dá virá a mim; e o que vem a mim de maneira nenhuma o lançarei fora.',
        'Porque eu desci do céu, não para fazer a minha vontade, mas a vontade daquele que me enviou.',
        'E a vontade do Pai que me enviou é esta: Que nenhum de todos aqueles que me deu se perca, mas que o ressuscite no último dia.',
        'Porquanto a vontade daquele que me enviou é esta: Que todo aquele que vê o Filho, e crê nele, tenha a vida eterna; e eu o ressuscitarei no último dia.',
        'Murmuravam, pois, dele os judeus, porque dissera: Eu sou o pão que desceu do céu.',
        'E diziam: Não é este Jesus, o filho de José, cujo pai e mãe nós conhecemos? Como, pois, diz ele: Desci do céu?',
        'Respondeu, pois, Jesus, e disse-lhes: Não murmureis entre vós.',
        'Ninguém pode vir a mim, se o Pai que me enviou o não trouxer; e eu o ressuscitarei no último dia.',
        'Está escrito nos profetas: E serão todos ensinados por Deus. Portanto, todo aquele que do Pai ouviu e aprendeu vem a mim.',
        'Não que alguém visse ao Pai, a não ser aquele que é de Deus; este tem visto ao Pai.',
        'Na verdade, na verdade vos digo que aquele que crê em mim tem a vida eterna.',
        'Eu sou o pão da vida.',
        'Vossos pais comeram o maná no deserto, e morreram.',
        'Este é o pão que desce do céu, para que o que dele comer não morra.',
        'Eu sou o pão vivo que desceu do céu; se alguém comer deste pão, viverá para sempre; e o pão que eu der é a minha carne, que eu darei pela vida do mundo.',
        'Disputavam, pois, os judeus entre si, dizendo: Como nos pode dar este a sua carne a comer?',
        'Jesus, pois, lhes disse: Na verdade, na verdade vos digo que, se não comerdes a carne do Filho do homem, e não beberdes o seu sangue, não tereis vida em vós mesmos.',
        'Quem come a minha carne e bebe o meu sangue tem a vida eterna, e eu o ressuscitarei no último dia.',
        'Porque a minha carne verdadeiramente é comida, e o meu sangue verdadeiramente é bebida.',
        'Quem come a minha carne e bebe o meu sangue permanece em mim e eu nele.',
        'Assim como o Pai, que vive, me enviou, e eu vivo pelo Pai, assim, quem de mim se alimenta, também viverá por mim.',
        'Este é o pão que desceu do céu; não é o caso de vossos pais, que comeram o maná e morreram; quem comer este pão viverá para sempre.',
        'Ele disse estas coisas na sinagoga, ensinando em Cafarnaum.',
        'Muitos, pois, dos seus discípulos, ouvindo isto, disseram: Duro é este discurso; quem o pode ouvir?',
        'Sabendo, pois, Jesus em si mesmo que os seus discípulos murmuravam disto, disse-lhes: Isto escandaliza-vos?',
        'Que seria, pois, se vísseis subir o Filho do homem para onde primeiro estava?',
        'O espírito é o que vivifica, a carne para nada aproveita; as palavras que eu vos digo são espírito e vida.',
        'Mas há alguns de vós que não crêem. Porque bem sabia Jesus, desde o princípio, quem eram os que não criam, e quem era o que o havia de entregar.',
        'E dizia: Por isso eu vos disse que ninguém pode vir a mim, se por meu Pai não lhe for concedido.',
        'Desde então muitos dos seus discípulos tornaram para trás, e já não andavam com ele.',
        'Então disse Jesus aos doze: Quereis vós também retirar-vos?',
        'Respondeu-lhe, pois, Simão Pedro: Senhor, para quem iremos nós? Tu tens as palavras da vida eterna.',
        'E nós temos crido e conhecido que tu és o Cristo, o Filho do Deus vivente.',
        'Respondeu-lhe Jesus: Não vos escolhi a vós os doze? e um de vós é um diabo.',
        'E isto dizia ele de Judas Iscariotes, filho de Simão; porque este o havia de entregar, sendo um dos doze.',
      ],
      [
        'E depois disto Jesus andava pela Galiléia, e já não queria andar pela Judéia, pois os judeus procuravam matá-lo.',
        'E estava próxima a festa dos judeus, a dos tabernáculos.',
        'Disseram-lhe, pois, seus irmãos: Sai daqui, e vai para a Judéia, para que também os teus discípulos vejam as obras que fazes.',
        'Porque não há ninguém que procure ser conhecido que faça coisa alguma em oculto. Se fazes estas coisas, manifesta-te ao mundo.',
        'Porque nem mesmo seus irmãos criam nele.',
        'Disse-lhes, pois, Jesus: Ainda não é chegado o meu tempo, mas o vosso tempo sempre está pronto.',
        'O mundo não vos pode odiar, mas ele me odeia a mim, porquanto dele testifico que as suas obras são más.',
        'Subi vós a esta festa; eu não subo ainda a esta festa, porque ainda o meu tempo não está cumprido.',
        'E, havendo-lhes dito isto, ficou na Galiléia.',
        'Mas, quando seus irmãos já tinham subido à festa, então subiu ele também, não manifestamente, mas como em oculto.',
        'Ora, os judeus procuravam-no na festa, e diziam: Onde está ele?',
        'E havia grande murmuração entre a multidão a respeito dele. Diziam alguns: Ele é bom. E outros diziam: Não, antes engana o povo.',
        'Todavia ninguém falava dele abertamente, por medo dos judeus.',
        'Mas, no meio da festa subiu Jesus ao templo, e ensinava.',
        'E os judeus maravilhavam-se, dizendo: Como sabe este letras, não as tendo aprendido?',
        'Jesus lhes respondeu, e disse: A minha doutrina não é minha, mas daquele que me enviou.',
        'Se alguém quiser fazer a vontade dele, pela mesma doutrina conhecerá se ela é de Deus, ou se eu falo de mim mesmo.',
        'Quem fala de si mesmo busca a sua própria glória; mas o que busca a glória daquele que o enviou, esse é verdadeiro, e não há nele injustiça.',
        'Não vos deu Moisés a lei? e nenhum de vós observa a lei. Por que procurais matar-me?',
        'A multidão respondeu, e disse: Tens demônio; quem procura matar-te?',
        'Respondeu Jesus, e disse-lhes: Fiz uma só obra, e todos vos maravilhais.',
        'Pelo motivo de que Moisés vos deu a circuncisão (não que fosse de Moisés, mas dos pais), no sábado circuncidais um homem.',
        'Se o homem recebe a circuncisão no sábado, para que a lei de Moisés não seja quebrantada, indignais-vos contra mim, porque no sábado curei de todo um homem?',
        'Não julgueis segundo a aparência, mas julgai segundo a reta justiça.',
        'Então alguns dos de Jerusalém diziam: Não é este o que procuram matar?',
        'E ei-lo aí está falando abertamente, e nada lhe dizem. Porventura sabem verdadeiramente os príncipes que de fato este é o Cristo?',
        'Todavia bem sabemos de onde este é; mas, quando vier o Cristo, ninguém saberá de onde ele é.',
        'Clamava, pois, Jesus no templo, ensinando, e dizendo: Vós conheceis-me, e sabeis de onde sou; e eu não vim de mim mesmo, mas aquele que me enviou é verdadeiro, o qual vós não conheceis.',
        'Mas eu conheço-o, porque dele sou e ele me enviou.',
        'Procuravam, pois, prendê-lo, mas ninguém lançou mão dele, porque ainda não era chegada a sua hora.',
        'E muitos da multidão creram nele, e diziam: Quando o Cristo vier, fará ainda mais sinais do que os que este tem feito?',
        'Os fariseus ouviram que a multidão murmurava dele estas coisas; e os fariseus e os principais dos sacerdotes mandaram servidores para o prenderem.',
        'Disse-lhes, pois, Jesus: Ainda um pouco de tempo estou convosco, e depois vou para aquele que me enviou.',
        'Vós me buscareis, e não me achareis; e onde eu estou, vós não podeis vir.',
        'Disseram, pois, os judeus uns para os outros: Para onde irá este, que o não acharemos? Irá porventura para os dispersos entre os gregos, e ensinará os gregos?',
        'Que palavra é esta que disse: Buscar-me-eis, e não me achareis; e: Aonde eu estou vós não podeis ir?',
        'E no último dia, o grande dia da festa, Jesus pôs-se em pé, e clamou, dizendo: Se alguém tem sede, venha a mim, e beba.',
        'Quem crê em mim, como diz a Escritura, rios de água viva correrão do seu ventre.',
        'E isto disse ele do Espírito que haviam de receber os que nele cressem; porque o Espírito Santo ainda não fora dado, por ainda Jesus não ter sido glorificado.',
        'Então muitos da multidão, ouvindo esta palavra, diziam: Verdadeiramente este é o Profeta.',
        'Outros diziam: Este é o Cristo; mas diziam outros: Vem, pois, o Cristo da Galiléia?',
        'Não diz a Escritura que o Cristo vem da descendência de Davi, e de Belém, da aldeia de onde era Davi?',
        'Assim entre o povo havia dissensão por causa dele.',
        'E alguns deles queriam prendê-lo, mas ninguém lançou mão dele.',
        'E os servidores foram ter com os principais dos sacerdotes e fariseus; e eles lhes perguntaram: Por que não o trouxestes?',
        'Responderam os servidores: Nunca homem algum falou assim como este homem.',
        'Responderam-lhes, pois, os fariseus: Também vós fostes enganados?',
        'Creu nele porventura algum dos principais ou dos fariseus?',
        'Mas esta multidão, que não sabe a lei, é maldita.',
        'Nicodemos, que era um deles (o que de noite fora ter com Jesus), disse-lhes:',
        'Porventura condena a nossa lei um homem sem primeiro o ouvir e ter conhecimento do que faz?',
        'Responderam eles, e disseram-lhe: És tu também da Galiléia? Examina, e verás que da Galiléia nenhum profeta surgiu.',
        'E cada um foi para sua casa.',
      ],
      [
        'Jesus, porém, foi para o Monte das Oliveiras.',
        'E pela manhã cedo tornou para o templo, e todo o povo vinha ter com ele, e, assentando-se, os ensinava.',
        'E os escribas e fariseus trouxeram-lhe uma mulher apanhada em adultério;',
        'E, pondo-a no meio, disseram-lhe: Mestre, esta mulher foi apanhada, no próprio ato, adulterando.',
        'E na lei nos mandou Moisés que as tais sejam apedrejadas. Tu, pois, que dizes?',
        'Isto diziam eles, tentando-o, para que tivessem de que o acusar. Mas Jesus, inclinando-se, escrevia com o dedo na terra.',
        'E, como insistissem, perguntando-lhe, endireitou-se, e disse-lhes: Aquele que de entre vós está sem pecado seja o primeiro que atire pedra contra ela.',
        'E, tornando a inclinar-se, escrevia na terra.',
        'Quando ouviram isto, redargüidos da consciência, saíram um a um, a começar pelos mais velhos até aos últimos; ficou só Jesus e a mulher que estava no meio.',
        'E, endireitando-se Jesus, e não vendo ninguém mais do que a mulher, disse-lhe: Mulher, onde estão aqueles teus acusadores? Ninguém te condenou?',
        'E ela disse: Ninguém, Senhor. E disse-lhe Jesus: Nem eu também te condeno; vai-te, e não peques mais.',
        'Falou-lhes, pois, Jesus outra vez, dizendo: Eu sou a luz do mundo; quem me segue não andará em trevas, mas terá a luz da vida.',
        'Disseram-lhe, pois, os fariseus: Tu testificas de ti mesmo; o teu testemunho não é verdadeiro.',
        'Respondeu Jesus, e disse-lhes: Ainda que eu testifico de mim mesmo, o meu testemunho é verdadeiro, porque sei de onde vim, e para onde vou; mas vós não sabeis de onde venho, nem para onde vou.',
        'Vós julgais segundo a carne; eu a ninguém julgo.',
        'E, se na verdade julgo, o meu juízo é verdadeiro, porque não sou eu só, mas eu e o Pai que me enviou.',
        'E na vossa lei está também escrito que o testemunho de dois homens é verdadeiro.',
        'Eu sou o que testifico de mim mesmo, e de mim testifica também o Pai que me enviou.',
        'Disseram-lhe, pois: Onde está teu Pai? Jesus respondeu: Não me conheceis a mim, nem a meu Pai; se vós me conhecêsseis a mim, também conheceríeis a meu Pai.',
        'Estas palavras disse Jesus no lugar do tesouro, ensinando no templo, e ninguém o prendeu, porque ainda não era chegada a sua hora.',
        'Disse-lhes, pois, Jesus outra vez: Eu retiro-me, e buscar-me-eis, e morrereis no vosso pecado. Para onde eu vou, não podeis vós vir.',
        'Diziam, pois, os judeus: Porventura quererá matar-se a si mesmo, pois diz: Para onde eu vou não podeis vir?',
        'E dizia-lhes: Vós sois de baixo, eu sou de cima; vós sois deste mundo, eu não sou deste mundo.',
        'Por isso vos disse que morrereis em vossos pecados, porque se não crerdes que eu sou, morrereis em vossos pecados.',
        'Disseram-lhe, pois: Quem és tu? Jesus lhes disse: Isso mesmo que já desde o princípio vos disse.',
        'Muito tenho que dizer e julgar de vós, mas aquele que me enviou é verdadeiro; e o que dele tenho ouvido, isso falo ao mundo.',
        'Mas não entenderam que ele lhes falava do Pai.',
        'Disse-lhes, pois, Jesus: Quando levantardes o Filho do homem, então conhecereis que EU SOU, e que nada faço por mim mesmo; mas isto falo como meu Pai me ensinou.',
        'E aquele que me enviou está comigo. O Pai não me tem deixado só, porque eu faço sempre o que lhe agrada.',
        'Dizendo ele estas coisas, muitos creram nele.',
        'Jesus dizia, pois, aos judeus que criam nele: Se vós permanecerdes na minha palavra, verdadeiramente sereis meus discípulos;',
        'E conhecereis a verdade, e a verdade vos libertará.',
        'Responderam-lhe: Somos descendência de Abraão, e nunca servimos a ninguém; como dizes tu: Sereis livres?',
        'Respondeu-lhes Jesus: Em verdade, em verdade vos digo que todo aquele que comete pecado é servo do pecado.',
        'Ora o servo não fica para sempre em casa; o Filho fica para sempre.',
        'Se, pois, o Filho vos libertar, verdadeiramente sereis livres.',
        'Bem sei que sois descendência de Abraão; contudo, procurais matar-me, porque a minha palavra não entra em vós.',
        'Eu falo do que vi junto de meu Pai, e vós fazeis o que também vistes junto de vosso pai.',
        'Responderam, e disseram-lhe: Nosso pai é Abraão. Jesus disse-lhes: Se fôsseis filhos de Abraão, faríeis as obras de Abraão.',
        'Mas agora procurais matar-me, a mim, homem que vos tem dito a verdade que de Deus tem ouvido; Abraão não fez isto.',
        'Vós fazeis as obras de vosso pai. Disseram-lhe, pois: Nós não somos nascidos de fornicação; temos um Pai, que é Deus.',
        'Disse-lhes, pois, Jesus: Se Deus fosse o vosso Pai, certamente me amaríeis, pois que eu saí, e vim de Deus; não vim de mim mesmo, mas ele me enviou.',
        'Por que não entendeis a minha linguagem? Por não poderdes ouvir a minha palavra.',
        'Vós tendes por pai ao diabo, e quereis satisfazer os desejos de vosso pai. Ele foi homicida desde o princípio, e não se firmou na verdade, porque não há verdade nele. Quando ele profere mentira, fala do que lhe é próprio, porque é mentiroso, e pai da mentira.',
        'Mas, porque vos digo a verdade, não me credes.',
        'Quem dentre vós me convence de pecado? E se vos digo a verdade, por que não credes?',
        'Quem é de Deus escuta as palavras de Deus; por isso vós não as escutais, porque não sois de Deus.',
        'Responderam, pois, os judeus, e disseram-lhe: Não dizemos nós bem que és samaritano, e que tens demônio?',
        'Jesus respondeu: Eu não tenho demônio, antes honro a meu Pai, e vós me desonrais.',
        'Eu não busco a minha glória; há quem a busque, e julgue.',
        'Em verdade, em verdade vos digo que, se alguém guardar a minha palavra, nunca verá a morte.',
        'Disseram-lhe, pois, os judeus: Agora conhecemos que tens demônio. Morreu Abraão e os profetas; e tu dizes: Se alguém guardar a minha palavra, nunca provará a morte.',
        'És tu maior do que o nosso pai Abraão, que morreu? E também os profetas morreram. Quem te fazes tu ser?',
        'Jesus respondeu: Se eu me glorifico a mim mesmo, a minha glória não é nada; quem me glorifica é meu Pai, o qual dizeis que é vosso Deus.',
        'E vós não o conheceis, mas eu conheço-o. E, se disser que o não conheço, serei mentiroso como vós; mas conheço-o e guardo a sua palavra.',
        'Abraão, vosso pai, exultou por ver o meu dia, e viu-o, e alegrou-se.',
        'Disseram-lhe, pois, os judeus: Ainda não tens cinqüenta anos, e viste Abraão?',
        'Disse-lhes Jesus: Em verdade, em verdade vos digo que antes que Abraão existisse, eu sou.',
        'Então pegaram em pedras para lhe atirarem; mas Jesus ocultou-se, e saiu do templo, passando pelo meio deles, e assim se retirou.',
      ],
      [
        'E, passando Jesus, viu um homem cego de nascença.',
        'E os seus discípulos lhe perguntaram, dizendo: Rabi, quem pecou, este ou seus pais, para que nascesse cego?',
        'Jesus respondeu: Nem ele pecou nem seus pais; mas foi assim para que se manifestem nele as obras de Deus.',
        'Convém que eu faça as obras daquele que me enviou, enquanto é dia; a noite vem, quando ninguém pode trabalhar.',
        'Enquanto estou no mundo, sou a luz do mundo.',
        'Tendo dito isto, cuspiu na terra, e com a saliva fez lodo, e untou com o lodo os olhos do cego.',
        'E disse-lhe: Vai, lava-te no tanque de Siloé (que significa o Enviado). Foi, pois, e lavou-se, e voltou vendo.',
        'Então os vizinhos, e aqueles que dantes tinham visto que era cego, diziam: Não é este aquele que estava assentado e mendigava?',
        'Uns diziam: É este. E outros: Parece-se com ele. Ele dizia: Sou eu.',
        'Diziam-lhe, pois: Como se te abriram os olhos?',
        'Ele respondeu, e disse: O homem, chamado Jesus, fez lodo, e untou-me os olhos, e disse-me: Vai ao tanque de Siloé, e lava-te. Então fui, e lavei-me, e vi.',
        'Disseram-lhe, pois: Onde está ele? Respondeu: Não sei.',
        'Levaram, pois, aos fariseus o que dantes era cego.',
        'E era sábado quando Jesus fez o lodo e lhe abriu os olhos.',
        'Tornaram, pois, também os fariseus a perguntar-lhe como vira, e ele lhes disse: Pôs-me lodo sobre os olhos, lavei-me, e vejo.',
        'Então alguns dos fariseus diziam: Este homem não é de Deus, pois não guarda o sábado. Diziam outros: Como pode um homem pecador fazer tais sinais? E havia dissensão entre eles.',
        'Tornaram, pois, a dizer ao cego: Tu, que dizes daquele que te abriu os olhos? E ele respondeu: Que é profeta.',
        'Os judeus, porém, não creram que ele tivesse sido cego, e que agora visse, enquanto não chamaram os pais do que agora via.',
        'E perguntaram-lhes, dizendo: É este o vosso filho, que vós dizeis ter nascido cego? Como, pois, vê agora?',
        'Seus pais lhes responderam, e disseram: Sabemos que este é o nosso filho, e que nasceu cego;',
        'Mas como agora vê, não sabemos; ou quem lhe tenha aberto os olhos, não sabemos. Tem idade, perguntai-lho a ele mesmo; e ele falará por si mesmo.',
        'Seus pais disseram isto, porque temiam os judeus. Porquanto já os judeus tinham resolvido que, se alguém confessasse ser ele o Cristo, fosse expulso da sinagoga.',
        'Por isso é que seus pais disseram: Tem idade, perguntai-lho a ele mesmo.',
        'Chamaram, pois, pela segunda vez o homem que tinha sido cego, e disseram-lhe: Dá glória a Deus; nós sabemos que esse homem é pecador.',
        'Respondeu ele pois, e disse: Se é pecador, não sei; uma coisa sei, é que, havendo eu sido cego, agora vejo.',
        'E tornaram a dizer-lhe: Que te fez ele? Como te abriu os olhos?',
        'Respondeu-lhes: Já vo-lo disse, e não ouvistes; para que o quereis tornar a ouvir? Quereis vós porventura fazer-vos também seus discípulos?',
        'Então o injuriaram, e disseram: Discípulo dele sejas tu; nós, porém, somos discípulos de Moisés.',
        'Nós bem sabemos que Deus falou a Moisés, mas este não sabemos de onde é.',
        'O homem respondeu, e disse-lhes: Nisto, pois, está a maravilha, que vós não saibais de onde ele é, e contudo me abrisse os olhos.',
        'Ora, nós sabemos que Deus não ouve a pecadores; mas, se alguém é temente a Deus, e faz a sua vontade, a esse ouve.',
        'Desde o princípio do mundo nunca se ouviu que alguém abrisse os olhos a um cego de nascença.',
        'Se este não fosse de Deus, nada poderia fazer.',
        'Responderam eles, e disseram-lhe: Tu és nascido todo em pecados, e nos ensinas a nós? E expulsaram-no.',
        'Jesus ouviu que o tinham expulsado e, encontrando-o, disse-lhe: Crês tu no Filho de Deus?',
        'Ele respondeu, e disse: Quem é ele, Senhor, para que nele creia?',
        'E Jesus lhe disse: Tu já o tens visto, e é aquele que fala contigo.',
        'Ele disse: Creio, Senhor. E o adorou.',
        'E disse-lhe Jesus: Eu vim a este mundo para juízo, a fim de que os que não vêem vejam, e os que vêem sejam cegos.',
        'E aqueles dos fariseus, que estavam com ele, ouvindo isto, disseram-lhe: Também nós somos cegos?',
        'Disse-lhes Jesus: Se fôsseis cegos, não teríeis pecado; mas como agora dizeis: Vemos; por isso o vosso pecado permanece.',
      ],
      [
        'Na verdade, na verdade vos digo que aquele que não entra pela porta no curral das ovelhas, mas sobe por outra parte, é ladrão e salteador.',
        'Aquele, porém, que entra pela porta é o pastor das ovelhas.',
        'A este o porteiro abre, e as ovelhas ouvem a sua voz, e chama pelo nome às suas ovelhas, e as traz para fora.',
        'E, quando tira para fora as suas ovelhas, vai adiante delas, e as ovelhas o seguem, porque conhecem a sua voz.',
        'Mas de modo nenhum seguirão o estranho, antes fugirão dele, porque não conhecem a voz dos estranhos.',
        'Jesus disse-lhes esta parábola; mas eles não entenderam o que era que lhes dizia.',
        'Tornou, pois, Jesus a dizer-lhes: Em verdade, em verdade vos digo que eu sou a porta das ovelhas.',
        'Todos quantos vieram antes de mim são ladrões e salteadores; mas as ovelhas não os ouviram.',
        'Eu sou a porta; se alguém entrar por mim, salvar-se-á, e entrará, e sairá, e achará pastagens.',
        'O ladrão não vem senão a roubar, a matar, e a destruir; eu vim para que tenham vida, e a tenham com abundância.',
        'Eu sou o bom Pastor; o bom Pastor dá a sua vida pelas ovelhas.',
        'Mas o mercenário, e o que não é pastor, de quem não são as ovelhas, vê vir o lobo, e deixa as ovelhas, e foge; e o lobo as arrebata e dispersa as ovelhas.',
        'Ora, o mercenário foge, porque é mercenário, e não tem cuidado das ovelhas.',
        'Eu sou o bom Pastor, e conheço as minhas ovelhas, e das minhas sou conhecido.',
        'Assim como o Pai me conhece a mim, também eu conheço o Pai, e dou a minha vida pelas ovelhas.',
        'Ainda tenho outras ovelhas que não são deste aprisco; também me convém agregar estas, e elas ouvirão a minha voz, e haverá um rebanho e um Pastor.',
        'Por isto o Pai me ama, porque dou a minha vida para tornar a tomá-la.',
        'Ninguém ma tira de mim, mas eu de mim mesmo a dou; tenho poder para a dar, e poder para tornar a tomá-la. Este mandamento recebi de meu Pai.',
        'Tornou, pois, a haver divisão entre os judeus por causa destas palavras.',
        'E muitos deles diziam: Tem demônio, e está fora de si; por que o ouvis?',
        'Diziam outros: Estas palavras não são de endemoninhado. Pode, porventura, um demônio abrir os olhos aos cegos?',
        'E em Jerusalém havia a festa da dedicação, e era inverno.',
        'E Jesus andava passeando no templo, no alpendre de Salomão.',
        'Rodearam-no, pois, os judeus, e disseram-lhe: Até quando terás a nossa alma suspensa? Se tu és o Cristo, dize-no-lo abertamente.',
        'Respondeu-lhes Jesus: Já vo-lo tenho dito, e não o credes. As obras que eu faço, em nome de meu Pai, essas testificam de mim.',
        'Mas vós não credes porque não sois das minhas ovelhas, como já vo-lo tenho dito.',
        'As minhas ovelhas ouvem a minha voz, e eu conheço-as, e elas me seguem;',
        'E dou-lhes a vida eterna, e nunca hão de perecer, e ninguém as arrebatará da minha mão.',
        'Meu Pai, que mas deu, é maior do que todos; e ninguém pode arrebatá-las da mão de meu Pai.',
        'Eu e o Pai somos um.',
        'Os judeus pegaram então outra vez em pedras para o apedrejar.',
        'Respondeu-lhes Jesus: Tenho-vos mostrado muitas obras boas procedentes de meu Pai; por qual destas obras me apedrejais?',
        'Os judeus responderam, dizendo-lhe: Não te apedrejamos por alguma obra boa, mas pela blasfêmia; porque, sendo tu homem, te fazes Deus a ti mesmo.',
        'Respondeu-lhes Jesus: Não está escrito na vossa lei: Eu disse: Sois deuses?',
        'Pois, se a lei chamou deuses àqueles a quem a palavra de Deus foi dirigida, e a Escritura não pode ser anulada,',
        '«quele a quem o Pai santificou, e enviou ao mundo, vós dizeis: Blasfemas, porque disse: Sou Filho de Deus?',
        'Se não faço as obras de meu Pai, não me acrediteis.',
        'Mas, se as faço, e não credes em mim, crede nas obras; para que conheçais e acrediteis que o Pai está em mim e eu nele.',
        'Procuravam, pois, prendê-lo outra vez, mas ele escapou-se de suas mãos,',
        'E retirou-se outra vez para além do Jordão, para o lugar onde João tinha primeiramente batizado; e ali ficou.',
        'E muitos iam ter com ele, e diziam: Na verdade João não fez sinal algum, mas tudo quanto João disse deste era verdade.',
        'E muitos ali creram nele.',
      ],
      [
        'Estava, porém, enfermo um certo Lázaro, de betânia, aldeia de Maria e de sua irmã Marta.',
        'E Maria era aquela que tinha ungido o Senhor com ungüento, e lhe tinha enxugado os pés com os seus cabelos, cujo irmão Lázaro estava enfermo.',
        'Mandaram-lhe, pois, suas irmãs dizer: Senhor, eis que está enfermo aquele que tu amas.',
        'E Jesus, ouvindo isto, disse: Esta enfermidade não é para morte, mas para glória de Deus, para que o Filho de Deus seja glorificado por ela.',
        'Ora, Jesus amava a Marta, e a sua irmã, e a Lázaro.',
        'Ouvindo, pois, que estava enfermo, ficou ainda dois dias no lugar onde estava.',
        'Depois disto, disse aos seus discípulos: Vamos outra vez para a Judéia.',
        'Disseram-lhe os discípulos: Rabi, ainda agora os judeus procuravam apedrejar-te, e tornas para lá?',
        'Jesus respondeu: Não há doze horas no dia? Se alguém andar de dia, não tropeça, porque vê a luz deste mundo;',
        'Mas, se andar de noite, tropeça, porque nele não há luz.',
        'Assim falou; e depois disse-lhes: Lázaro, o nosso amigo, dorme, mas vou despertá-lo do sono.',
        'Disseram, pois, os seus discípulos: Senhor, se dorme, estará salvo.',
        'Mas Jesus dizia isto da sua morte; eles, porém, cuidavam que falava do repouso do sono.',
        'Então Jesus disse-lhes claramente: Lázaro está morto;',
        'E folgo, por amor de vós, de que eu lá não estivesse, para que acrediteis; mas vamos ter com ele.',
        'Disse, pois, Tomé, chamado Dídimo, aos condiscípulos: Vamos nós também, para morrermos com ele.',
        'Chegando, pois, Jesus, achou que já havia quatro dias que estava na sepultura.',
        '(Ora Betânia distava de Jerusalém quase quinze estádios. )',
        'E muitos dos judeus tinham ido consolar a Marta e a Maria, acerca de seu irmão.',
        'Ouvindo, pois, Marta que Jesus vinha, saiu-lhe ao encontro; Maria, porém, ficou assentada em casa.',
        'Disse, pois, Marta a Jesus: Senhor, se tu estivesses aqui, meu irmão não teria morrido.',
        'Mas também agora sei que tudo quanto pedires a Deus, Deus to concederá.',
        'Disse-lhe Jesus: Teu irmão há de ressuscitar.',
        'Disse-lhe Marta: Eu sei que há de ressuscitar na ressurreição do último dia.',
        'Disse-lhe Jesus: Eu sou a ressurreição e a vida; quem crê em mim, ainda que esteja morto, viverá;',
        'E todo aquele que vive, e crê em mim, nunca morrerá. Crês tu isto?',
        'Disse-lhe ela: Sim, Senhor, creio que tu és o Cristo, o Filho de Deus, que havia de vir ao mundo.',
        'E, dito isto, partiu, e chamou em segredo a Maria, sua irmã, dizendo: O Mestre está cá, e chama-te.',
        'Ela, ouvindo isto, levantou-se logo, e foi ter com ele.',
        '(Pois, Jesus ainda não tinha chegado à aldeia, mas estava no lugar onde Marta o encontrara.)',
        'Vendo, pois, os judeus, que estavam com ela em casa e a consolavam, que Maria apressadamente se levantara e saíra, seguiram-na, dizendo: Vai ao sepulcro para chorar ali.',
        'Tendo, pois, Maria chegado aonde Jesus estava, e vendo-o, lançou-se aos seus pés, dizendo-lhe: Senhor, se tu estivesses aqui, meu irmão não teria morrido.',
        'Jesus pois, quando a viu chorar, e também chorando os judeus que com ela vinham, moveu-se muito em espírito, e perturbou-se.',
        'E disse: Onde o pusestes? Disseram-lhe: Senhor, vem, e vê.',
        'Jesus chorou.',
        'Disseram, pois, os judeus: Vede como o amava.',
        'E alguns deles disseram: Não podia ele, que abriu os olhos ao cego, fazer também com que este não morresse?',
        'Jesus, pois, movendo-se outra vez muito em si mesmo, veio ao sepulcro; e era uma caverna, e tinha uma pedra posta sobre ela.',
        'Disse Jesus: Tirai a pedra. Marta, irmã do defunto, disse-lhe: Senhor, já cheira mal, porque é já de quatro dias.',
        'Disse-lhe Jesus: Não te hei dito que, se creres, verás a glória de Deus?',
        'Tiraram, pois, a pedra de onde o defunto jazia. E Jesus, levantando os olhos para cima, disse: Pai, graças te dou, por me haveres ouvido.',
        'Eu bem sei que sempre me ouves, mas eu disse isto por causa da multidão que está em redor, para que creiam que tu me enviaste.',
        'E, tendo dito isto, clamou com grande voz: Lázaro, sai para fora.',
        'E o defunto saiu, tendo as mãos e os pés ligados com faixas, e o seu rosto envolto num lenço. Disse-lhes Jesus: Desligai-o, e deixai-o ir.',
        'Muitos, pois, dentre os judeus que tinham vindo a Maria, e que tinham visto o que Jesus fizera, creram nele.',
        'Mas alguns deles foram ter com os fariseus, e disseram-lhes o que Jesus tinha feito.',
        'Depois os principais dos sacerdotes e os fariseus formaram conselho, e diziam: Que faremos? porquanto este homem faz muitos sinais.',
        'Se o deixamos assim, todos crerão nele, e virão os romanos, e tirar-nos-ão o nosso lugar e a nação.',
        'E Caifás, um deles que era sumo sacerdote naquele ano, lhes disse: Vós nada sabeis,',
        'Nem considerais que nos convém que um homem morra pelo povo, e que não pereça toda a nação.',
        'Ora ele não disse isto de si mesmo, mas, sendo o sumo sacerdote naquele ano, profetizou que Jesus devia morrer pela nação.',
        'E não somente pela nação, mas também para reunir em um corpo os filhos de Deus que andavam dispersos.',
        'Desde aquele dia, pois, consultavam-se para o matarem.',
        'Jesus, pois, já não andava manifestamente entre os judeus, mas retirou-se dali para a terra junto do deserto, para uma cidade chamada Efraim; e ali ficou com os seus discípulos.',
        'E estava próxima a páscoa dos judeus, e muitos daquela região subiram a Jerusalém antes da páscoa para se purificarem.',
        'Buscavam, pois, a Jesus, e diziam uns aos outros, estando no templo: Que vos parece? Não virá à festa?',
        'Ora, os principais dos sacerdotes e os fariseus tinham dado ordem para que, se alguém soubesse onde ele estava, o denunciasse, para o prenderem.',
      ],
      [
        'Foi, pois, Jesus seis dias antes da páscoa a betânia, onde estava Lázaro, o que falecera, e a quem ressuscitara dentre os mortos.',
        'Fizeram-lhe, pois, ali uma ceia, e Marta servia, e Lázaro era um dos que estavam à mesa com ele.',
        'Então Maria, tomando um arrátel de ungüento de nardo puro, de muito preço, ungiu os pés de Jesus, e enxugou-lhe os pés com os seus cabelos; e encheu-se a casa do cheiro do ungüento.',
        'Então, um dos seus discípulos, Judas Iscariotes, filho de Simão, o que havia de traí-lo, disse:',
        'Por que não se vendeu este ungüento por trezentos dinheiros e não se deu aos pobres?',
        'Ora, ele disse isto, não pelo cuidado que tivesse dos pobres, mas porque era ladrão e tinha a bolsa, e tirava o que ali se lançava.',
        'Disse, pois, Jesus: Deixai-a; para o dia da minha sepultura guardou isto;',
        'Porque os pobres sempre os tendes convosco, mas a mim nem sempre me tendes.',
        'E muita gente dos judeus soube que ele estava ali; e foram, não só por causa de Jesus, mas também para ver a Lázaro, a quem ressuscitara dentre os mortos.',
        'E os principais dos sacerdotes tomaram deliberação para matar também a Lázaro;',
        'Porque muitos dos judeus, por causa dele, iam e criam em Jesus.',
        'No dia seguinte, ouvindo uma grande multidão, que viera à festa, que Jesus vinha a Jerusalém,',
        'Tomaram ramos de palmeiras, e saíram-lhe ao encontro, e clamavam: Hosana! Bendito o Rei de Israel que vem em nome do Senhor.',
        'E achou Jesus um jumentinho, e assentou-se sobre ele, como está escrito:',
        'Não temas, ó filha de Sião; eis que o teu Rei vem assentado sobre o filho de uma jumenta.',
        'Os seus discípulos, porém, não entenderam isto no princípio; mas, quando Jesus foi glorificado, então se lembraram de que isto estava escrito dele, e que isto lhe fizeram.',
        'A multidão, pois, que estava com ele quando Lázaro foi chamado da sepultura, testificava que ele o ressuscitara dentre os mortos.',
        'Por isso a multidão lhe saiu ao encontro, porque tinham ouvido que ele fizera este sinal.',
        'Disseram, pois, os fariseus entre si: Vedes que nada aproveitais? Eis que toda a gente vai após ele.',
        'Ora, havia alguns gregos, entre os que tinham subido a adorar no dia da festa.',
        'Estes, pois, dirigiram-se a Filipe, que era de Betsaida da Galiléia, e rogaram-lhe, dizendo: Senhor, queríamos ver a Jesus.',
        'Filipe foi dizê-lo a André, e então André e Filipe o disseram a Jesus.',
        'E Jesus lhes respondeu, dizendo: É chegada a hora em que o Filho do homem há de ser glorificado.',
        'Na verdade, na verdade vos digo que, se o grão de trigo, caindo na terra, não morrer, fica ele só; mas se morrer, dá muito fruto.',
        'Quem ama a sua vida perdê-la-á, e quem neste mundo odeia a sua vida, guardá-la-á para a vida eterna.',
        'Se alguém me serve, siga-me, e onde eu estiver, ali estará também o meu servo. E, se alguém me servir, meu Pai o honrará.',
        'Agora a minha alma está perturbada; e que direi eu? Pai, salva-me desta hora; mas para isto vim a esta hora.',
        'Pai, glorifica o teu nome. Então veio uma voz do céu que dizia: Já o tenho glorificado, e outra vez o glorificarei.',
        'Ora, a multidão que ali estava, e que a ouvira, dizia que havia sido um trovão. Outros diziam: Um anjo lhe falou.',
        'Respondeu Jesus, e disse: Não veio esta voz por amor de mim, mas por amor de vós.',
        'Agora é o juízo deste mundo; agora será expulso o príncipe deste mundo.',
        'E eu, quando for levantado da terra, todos atrairei a mim.',
        'E dizia isto, significando de que morte havia de morrer.',
        'Respondeu-lhe a multidão: Nós temos ouvido da lei, que o Cristo permanece para sempre; e como dizes tu que convém que o Filho do homem seja levantado? Quem é esse Filho do homem?',
        'Disse-lhes, pois, Jesus: A luz ainda está convosco por um pouco de tempo. Andai enquanto tendes luz, para que as trevas não vos apanhem; pois quem anda nas trevas não sabe para onde vai.',
        'Enquanto tendes luz, crede na luz, para que sejais filhos da luz. Estas coisas disse Jesus e, retirando-se, escondeu-se deles.',
        'E, ainda que tinha feito tantos sinais diante deles, não criam nele;',
        'Para que se cumprisse a palavra do profeta Isaías, que diz: Senhor, quem creu na nossa pregação? E a quem foi revelado o braço do Senhor?',
        'Por isso não podiam crer, entào Isaías disse outra vez:',
        'Cegou-lhes os olhos, e endureceu-lhes o coração, A fim de que não vejam com os olhos, e compreendam no coração, E se convertam, E eu os cure.',
        'Isaías disse isto quando viu a sua glória e falou dele.',
        'Apesar de tudo, até muitos dos principais creram nele; mas não o confessavam por causa dos fariseus, para não serem expulsos da sinagoga.',
        'Porque amavam mais a glória dos homens do que a glória de Deus.',
        'E Jesus clamou, e disse: Quem crê em mim, crê, não em mim, mas naquele que me enviou.',
        'E quem me vê a mim, vê aquele que me enviou.',
        'Eu sou a luz que vim ao mundo, para que todo aquele que crê em mim não permaneça nas trevas.',
        'E se alguém ouvir as minhas palavras, e não crer, eu não o julgo; porque eu vim, não para julgar o mundo, mas para salvar o mundo.',
        'Quem me rejeitar a mim, e não receber as minhas palavras, já tem quem o julgue; a palavra que tenho pregado, essa o há de julgar no último dia.',
        'Porque eu não tenho falado de mim mesmo; mas o Pai, que me enviou, ele me deu mandamento sobre o que hei de dizer e sobre o que hei de falar.',
        'E sei que o seu mandamento é a vida eterna. Portanto, o que eu falo, falo-o como o Pai mo tem dito.',
      ],
      [
        'Ora, antes da festa da páscoa, sabendo Jesus que já era chegada a sua hora de passar deste mundo para o Pai, como havia amado os seus, que estavam no mundo, amou-os até o fim.',
        'E, acabada a ceia, tendo já o diabo posto no coração de Judas Iscariotes, filho de Simão, que o traísse,',
        'Jesus, sabendo que o Pai tinha depositado nas suas mãos todas as coisas, e que havia saído de Deus e ia para Deus,',
        'Levantou-se da ceia, tirou as vestes, e, tomando uma toalha, cingiu-se.',
        'Depois deitou água numa bacia, e começou a lavar os pés aos discípulos, e a enxugar-lhos com a toalha com que estava cingido.',
        'Aproximou-se, pois, de Simão Pedro, que lhe disse: Senhor, tu lavas-me os pés a mim?',
        'Respondeu Jesus, e disse-lhe: O que eu faço não o sabes tu agora, mas tu o saberás depois.',
        'Disse-lhe Pedro: Nunca me lavarás os pés. Respondeu-lhe Jesus: Se eu te não lavar, não tens parte comigo.',
        'Disse-lhe Simão Pedro: Senhor, não só os meus pés, mas também as mãos e a cabeça.',
        'Disse-lhe Jesus: Aquele que está lavado não necessita de lavar senão os pés, pois no mais todo está limpo. Ora vós estais limpos, mas não todos.',
        'Porque bem sabia ele quem o havia de trair; por isso disse: Nem todos estais limpos.',
        'Depois que lhes lavou os pés, e tomou as suas vestes, e se assentou outra vez à mesa, disse-lhes: Entendeis o que vos tenho feito?',
        'Vós me chamais Mestre e Senhor, e dizeis bem, porque eu o sou.',
        'Ora, se eu, Senhor e Mestre, vos lavei os pés, vós deveis também lavar os pés uns aos outros.',
        'Porque eu vos dei o exemplo, para que, como eu vos fiz, façais vós também.',
        'Na verdade, na verdade vos digo que não é o servo maior do que o seu senhor, nem o enviado maior do que aquele que o enviou.',
        'Se sabeis estas coisas, bem-aventurados sois se as fizerdes.',
        'Não falo de todos vós; eu bem sei os que tenho escolhido; mas para que se cumpra a Escritura: O que come o pão comigo, levantou contra mim o seu calcanhar.',
        'Desde agora vo-lo digo, antes que aconteça, para que, quando acontecer, acrediteis que eu sou.',
        'Na verdade, na verdade vos digo: Se alguém receber o que eu enviar, me recebe a mim, e quem me recebe a mim, recebe aquele que me enviou.',
        'Tendo Jesus dito isto, turbou-se em espírito, e afirmou, dizendo: Na verdade, na verdade vos digo que um de vós me há de trair.',
        'Então os discípulos olhavam uns para os outros, duvidando de quem ele falava.',
        'Ora, um de seus discípulos, aquele a quem Jesus amava, estava reclinado no seio de Jesus.',
        'Então Simão Pedro fez sinal a este, para que perguntasse quem era aquele de quem ele falava.',
        'E, inclinando-se ele sobre o peito de Jesus, disse-lhe: Senhor, quem é?',
        'Jesus respondeu: É aquele a quem eu der o bocado molhado. E, molhando o bocado, o deu a Judas Iscariotes, filho de Simão.',
        'E, após o bocado, entrou nele Satanás. Disse, pois, Jesus: O que fazes, faze-o depressa.',
        'E nenhum dos que estavam assentados à mesa compreendeu a que propósito lhe dissera isto.',
        'Porque, como Judas tinha a bolsa, pensavam alguns que Jesus lhe tinha dito: Compra o que nos é necessário para a festa; ou que desse alguma coisa aos pobres.',
        'E, tendo Judas tomado o bocado, saiu logo. E era já noite.',
        'Tendo ele, pois, saído, disse Jesus: Agora é glorificado o Filho do homem, e Deus é glorificado nele.',
        'Se Deus é glorificado nele, também Deus o glorificará em si mesmo, e logo o há de glorificar.',
        'Filhinhos, ainda por um pouco estou convosco. Vós me buscareis, mas, como tenho dito aos judeus: Para onde eu vou não podeis vós ir; eu vo-lo digo também agora.',
        'Um novo mandamento vos dou: Que vos ameis uns aos outros; como eu vos amei a vós, que também vós uns aos outros vos ameis.',
        'Nisto todos conhecerão que sois meus discípulos, se vos amardes uns aos outros.',
        'Disse-lhe Simão Pedro: Senhor, para onde vais? Jesus lhe respondeu: Para onde eu vou não podes agora seguir-me, mas depois me seguirás.',
        'Disse-lhe Pedro: Por que não posso seguir-te agora? Por ti darei a minha vida.',
        'Respondeu-lhe Jesus: Tu darás a tua vida por mim? Na verdade, na verdade te digo que não cantará o galo enquanto não me tiveres negado três vezes.',
      ],
      [
        'Não se turbe o vosso coração; credes em Deus, crede também em mim.',
        'Na casa de meu Pai há muitas moradas; se não fosse assim, eu vo-lo teria dito. Vou preparar-vos lugar.',
        'E quando eu for, e vos preparar lugar, virei outra vez, e vos levarei para mim mesmo, para que onde eu estiver estejais vós também.',
        'Mesmo vós sabeis para onde vou, e conheceis o caminho.',
        'Disse-lhe Tomé: Senhor, nós não sabemos para onde vais; e como podemos saber o caminho?',
        'Disse-lhe Jesus: Eu sou o caminho, e a verdade e a vida; ninguém vem ao Pai, senão por mim.',
        'Se vós me conhecêsseis a mim, também conheceríeis a meu Pai; e já desde agora o conheceis, e o tendes visto.',
        'Disse-lhe Filipe: Senhor, mostra-nos o Pai, o que nos basta.',
        'Disse-lhe Jesus: Estou há tanto tempo convosco, e não me tendes conhecido, Filipe? Quem me vê a mim vê o Pai; e como dizes tu: Mostra-nos o Pai?',
        'Não crês tu que eu estou no Pai, e que o Pai está em mim? As palavras que eu vos digo não as digo de mim mesmo, mas o Pai, que está em mim, é quem faz as obras.',
        'Crede-me que estou no Pai, e o Pai em mim; crede-me, ao menos, por causa das mesmas obras.',
        'Na verdade, na verdade vos digo que aquele que crê em mim também fará as obras que eu faço, e as fará maiores do que estas, porque eu vou para meu Pai.',
        'E tudo quanto pedirdes em meu nome eu o farei, para que o Pai seja glorificado no Filho.',
        'Se pedirdes alguma coisa em meu nome, eu o farei.',
        'Se me amais, guardai os meus mandamentos.',
        'E eu rogarei ao Pai, e ele vos dará outro Consolador, para que fique convosco para sempre;',
        'O Espírito de verdade, que o mundo não pode receber, porque não o vê nem o conhece; mas vós o conheceis, porque habita convosco, e estará em vós.',
        'Não vos deixarei órfãos; voltarei para vós.',
        'Ainda um pouco, e o mundo não me verá mais, mas vós me vereis; porque eu vivo, e vós vivereis.',
        'Naquele dia conhecereis que estou em meu Pai, e vós em mim, e eu em vós.',
        'Aquele que tem os meus mandamentos e os guarda esse é o que me ama; e aquele que me ama será amado de meu Pai, e eu o amarei, e me manifestarei a ele.',
        'Disse-lhe Judas (não o Iscariotes): Senhor, de onde vem que te hás de manifestar a nós, e não ao mundo?',
        'Jesus respondeu, e disse-lhe: Se alguém me ama, guardará a minha palavra, e meu Pai o amará, e viremos para ele, e faremos nele morada.',
        'Quem não me ama não guarda as minhas palavras; ora, a palavra que ouvistes não é minha, mas do Pai que me enviou.',
        'Tenho-vos dito isto, estando convosco.',
        'Mas aquele Consolador, o Espírito Santo, que o Pai enviará em meu nome, esse vos ensinará todas as coisas, e vos fará lembrar de tudo quanto vos tenho dito.',
        'Deixo-vos a paz, a minha paz vos dou; não vo-la dou como o mundo a dá. Não se turbe o vosso coração, nem se atemorize.',
        'Ouvistes que eu vos disse: Vou, e venho para vós. Se me amásseis, certamente exultaríeis porque eu disse: Vou para o Pai; porque meu Pai é maior do que eu.',
        'Eu vo-lo disse agora antes que aconteça, para que, quando acontecer, vós acrediteis.',
        'Já não falarei muito convosco, porque se aproxima o príncipe deste mundo, e nada tem em mim;',
        'Mas é para que o mundo saiba que eu amo o Pai, e que faço como o Pai me mandou. Levantai-vos, vamo-nos daqui.',
      ],
      [
        'Eu sou a videira verdadeira, e meu Pai é o lavrador.',
        'Toda a vara em mim, que não dá fruto, a tira; e limpa toda aquela que dá fruto, para que dê mais fruto.',
        'Vós já estais limpos, pela palavra que vos tenho falado.',
        'Estai em mim, e eu em vós; como a vara de si mesma não pode dar fruto, se não estiver na videira, assim também vós, se não estiverdes em mim.',
        'Eu sou a videira, vós as varas; quem está em mim, e eu nele, esse dá muito fruto; porque sem mim nada podeis fazer.',
        'Se alguém não estiver em mim, será lançado fora, como a vara, e secará; e os colhem e lançam no fogo, e ardem.',
        'Se vós estiverdes em mim, e as minhas palavras estiverem em vós, pedireis tudo o que quiserdes, e vos será feito.',
        'Nisto é glorificado meu Pai, que deis muito fruto; e assim sereis meus discípulos.',
        'Como o Pai me amou, também eu vos amei a vós; permanecei no meu amor.',
        'Se guardardes os meus mandamentos, permanecereis no meu amor; do mesmo modo que eu tenho guardado os mandamentos de meu Pai, e permaneço no seu amor.',
        'Tenho-vos dito isto, para que o meu gozo permaneça em vós, e o vosso gozo seja completo.',
        'O meu mandamento é este: Que vos ameis uns aos outros, assim como eu vos amei.',
        'Ninguém tem maior amor do que este, de dar alguém a sua vida pelos seus amigos.',
        'Vós sereis meus amigos, se fizerdes o que eu vos mando.',
        'Já vos não chamarei servos, porque o servo não sabe o que faz o seu senhor; mas tenho-vos chamado amigos, porque tudo quanto ouvi de meu Pai vos tenho feito conhecer.',
        'Não me escolhestes vós a mim, mas eu vos escolhi a vós, e vos nomeei, para que vades e deis fruto, e o vosso fruto permaneça; a fim de que tudo quanto em meu nome pedirdes ao Pai ele vo-lo conceda.',
        'Isto vos mando: Que vos ameis uns aos outros.',
        'Se o mundo vos odeia, sabei que, primeiro do que a vós, me odiou a mim.',
        'Se vós fôsseis do mundo, o mundo amaria o que era seu, mas porque não sois do mundo, antes eu vos escolhi do mundo, por isso é que o mundo vos odeia.',
        'Lembrai-vos da palavra que vos disse: Não é o servo maior do que o seu senhor. Se a mim me perseguiram, também vos perseguirão a vós; se guardaram a minha palavra, também guardarão a vossa.',
        'Mas tudo isto vos farão por causa do meu nome, porque não conhecem aquele que me enviou.',
        'Se eu não viera, nem lhes houvera falado, não teriam pecado, mas agora não têm desculpa do seu pecado.',
        'Aquele que me odeia, odeia também a meu Pai.',
        'Se eu entre eles não fizesse tais obras, quais nenhum outro tem feito, não teriam pecado; mas agora, viram-nas e me odiaram a mim e a meu Pai.',
        'Mas é para que se cumpra a palavra que está escrita na sua lei: Odiaram-me sem causa.',
        'Mas, quando vier o Consolador, que eu da parte do Pai vos hei de enviar, aquele Espírito de verdade, que procede do Pai, ele testificará de mim.',
        'E vós também testificareis, pois estivestes comigo desde o princípio.',
      ],
      [
        'Tenho-vos dito estas coisas para que vos não escandalizeis.',
        'Expulsar-vos-ão das sinagogas; vem mesmo a hora em que qualquer que vos matar cuidará fazer um serviço a Deus.',
        'E isto vos farão, porque não conheceram ao Pai nem a mim.',
        'Mas tenho-vos dito isto, a fim de que, quando chegar aquela hora, vos lembreis de que já vo-lo tinha dito. E eu não vos disse isto desde o princípio, porque estava convosco.',
        'E agora vou para aquele que me enviou; e nenhum de vós me pergunta: Para onde vais?',
        'Antes, porque isto vos tenho dito, o vosso coração se encheu de tristeza.',
        'Todavia digo-vos a verdade, que vos convém que eu vá; porque, se eu não for, o Consolador não virá a vós; mas, quando eu for, vo-lo enviarei.',
        'E, quando ele vier, convencerá o mundo do pecado, e da justiça e do juízo.',
        'Do pecado, porque não crêem em mim;',
        'Da justiça, porque vou para meu Pai, e não me vereis mais;',
        'E do juízo, porque já o príncipe deste mundo está julgado.',
        'Ainda tenho muito que vos dizer, mas vós não o podeis suportar agora.',
        'Mas, quando vier aquele Espírito de verdade, ele vos guiará em toda a verdade; porque não falará de si mesmo, mas dirá tudo o que tiver ouvido, e vos anunciará o que há de vir.',
        'Ele me glorificará, porque há de receber do que é meu, e vo-lo há de anunciar.',
        'Tudo quanto o Pai tem é meu; por isso vos disse que há de receber do que é meu e vo-lo há de anunciar.',
        'Um pouco, e não me vereis; e outra vez um pouco, e ver-me-eis; porquanto vou para o Pai.',
        'Então alguns dos seus discípulos disseram uns aos outros: Que é isto que nos diz? Um pouco, e não me vereis; e outra vez um pouco, e ver-me-eis; e: Porquanto vou para o Pai?',
        'Diziam, pois: Que quer dizer isto: Um pouco? Não sabemos o que diz.',
        'Conheceu, pois, Jesus que o queriam interrogar, e disse-lhes: Indagais entre vós acerca disto que disse: Um pouco, e não me vereis, e outra vez um pouco, e ver-me-eis?',
        'Na verdade, na verdade vos digo que vós chorareis e vos lamentareis, e o mundo se alegrará, e vós estareis tristes, mas a vossa tristeza se converterá em alegria.',
        'A mulher, quando está para dar à luz, sente tristeza, porque é chegada a sua hora; mas, depois de ter dado à luz a criança, já não se lembra da aflição, pelo prazer de haver nascido um homem no mundo.',
        'Assim também vós agora, na verdade, tendes tristeza; mas outra vez vos verei, e o vosso coração se alegrará, e a vossa alegria ninguém vo-la tirará.',
        'E naquele dia nada me perguntareis. Na verdade, na verdade vos digo que tudo quanto pedirdes a meu Pai, em meu nome, ele vo-lo há de dar.',
        'Até agora nada pedistes em meu nome; pedi, e recebereis, para que o vosso gozo se cumpra.',
        'Disse-vos isto por parábolas; chega, porém, a hora em que não vos falarei mais por parábolas, mas abertamente vos falarei acerca do Pai.',
        'Naquele dia pedireis em meu nome, e não vos digo que eu rogarei por vós ao Pai;',
        'Pois o mesmo Pai vos ama, visto como vós me amastes, e crestes que saí de Deus.',
        'Saí do Pai, e vim ao mundo; outra vez deixo o mundo, e vou para o Pai.',
        'Disseram-lhe os seus discípulos: Eis que agora falas abertamente, e não dizes parábola alguma.',
        'Agora conhecemos que sabes tudo, e não precisas de que alguém te interrogue. Por isso cremos que saíste de Deus.',
        'Respondeu-lhes Jesus: Credes agora?',
        'Eis que chega a hora, e já se aproxima, em que vós sereis dispersos cada um para sua parte, e me deixareis só; mas não estou só, porque o Pai está comigo.',
        'Tenho-vos dito isto, para que em mim tenhais paz; no mundo tereis aflições, mas tende bom ânimo, eu venci o mundo.',
      ],
      [
        'Jesus falou assim e, levantando seus olhos ao céu, e disse: Pai, é chegada a hora; glorifica a teu Filho, para que também o teu Filho te glorifique a ti;',
        'Assim como lhe deste poder sobre toda a carne, para que dê a vida eterna a todos quantos lhe deste.',
        'E a vida eterna é esta: que te conheçam, a ti só, por único Deus verdadeiro, e a Jesus Cristo, a quem enviaste.',
        'Eu glorifiquei-te na terra, tendo consumado a obra que me deste a fazer.',
        'E agora glorifica-me tu, ó Pai, junto de ti mesmo, com aquela glória que tinha contigo antes que o mundo existisse.',
        'Manifestei o teu nome aos homens que do mundo me deste; eram teus, e tu mos deste, e guardaram a tua palavra.',
        'Agora já têm conhecido que tudo quanto me deste provém de ti;',
        'Porque lhes dei as palavras que tu me deste; e eles as receberam, e têm verdadeiramente conhecido que saí de ti, e creram que me enviaste.',
        'Eu rogo por eles; não rogo pelo mundo, mas por aqueles que me deste, porque são teus.',
        'E todas as minhas coisas são tuas, e as tuas coisas são minhas; e neles sou glorificado.',
        'E eu já não estou mais no mundo, mas eles estão no mundo, e eu vou para ti. Pai santo, guarda em teu nome aqueles que me deste, para que sejam um, assim como nós.',
        'Estando eu com eles no mundo, guardava-os em teu nome. Tenho guardado aqueles que tu me deste, e nenhum deles se perdeu, senão o filho da perdição, para que a Escritura se cumprisse.',
        'Mas agora vou para ti, e digo isto no mundo, para que tenham a minha alegria completa em si mesmos.',
        'Dei-lhes a tua palavra, e o mundo os odiou, porque não são do mundo, assim como eu não sou do mundo.',
        'Não peço que os tires do mundo, mas que os livres do mal.',
        'Não são do mundo, como eu do mundo não sou.',
        'Santifica-os na tua verdade; a tua palavra é a verdade.',
        'Assim como tu me enviaste ao mundo, também eu os enviei ao mundo.',
        'E por eles me santifico a mim mesmo, para que também eles sejam santificados na verdade.',
        'E não rogo somente por estes, mas também por aqueles que pela tua palavra hão de crer em mim;',
        'Para que todos sejam um, como tu, ó Pai, o és em mim, e eu em ti; que também eles sejam um em nós, para que o mundo creia que tu me enviaste.',
        'E eu dei-lhes a glória que a mim me deste, para que sejam um, como nós somos um.',
        'Eu neles, e tu em mim, para que eles sejam perfeitos em unidade, e para que o mundo conheça que tu me enviaste a mim, e que os tens amado a eles como me tens amado a mim.',
        'Pai, aqueles que me deste quero que, onde eu estiver, também eles estejam comigo, para que vejam a minha glória que me deste; porque tu me amaste antes da fundação do mundo.',
        'Pai justo, o mundo não te conheceu; mas eu te conheci, e estes conheceram que tu me enviaste a mim.',
        'E eu lhes fiz conhecer o teu nome, e lho farei conhecer mais, para que o amor com que me tens amado esteja neles, e eu neles esteja.',
      ],
      [
        'Tendo Jesus dito isto, saiu com os seus discípulos para além do ribeiro de Cedrom, onde havia um horto, no qual ele entrou e seus discípulos.',
        'E Judas, que o traía, também conhecia aquele lugar, porque Jesus muitas vezes se ajuntava ali com os seus discípulos.',
        'Tendo, pois, Judas recebido a coorte e oficiais dos principais sacerdotes e fariseus, veio para ali com lanternas, e archotes e armas.',
        'Sabendo, pois, Jesus todas as coisas que sobre ele haviam de vir, adiantou-se, e disse-lhes: A quem buscais?',
        'Responderam-lhe: A Jesus Nazareno. Disse-lhes Jesus: Sou eu. E Judas, que o traía, estava com eles.',
        'Quando, pois, lhes disse: Sou eu, recuaram, e caíram por terra.',
        'Tornou-lhes, pois, a perguntar: A quem buscais? E eles disseram: A Jesus Nazareno.',
        'Jesus respondeu: Já vos disse que sou eu; se, pois, me buscais a mim, deixai ir estes;',
        'Para que se cumprisse a palavra que tinha dito: Dos que me deste nenhum deles perdi.',
        'Então Simão Pedro, que tinha espada, desembainhou-a, e feriu o servo do sumo sacerdote, cortando-lhe a orelha direita. E o nome do servo era Malco.',
        'Mas Jesus disse a Pedro: Põe a tua espada na bainha; não beberei eu o cálice que o Pai me deu?',
        'Então a coorte, e o tribuno, e os servos dos judeus prenderam a Jesus e o maniataram.',
        'E conduziram-no primeiramente a Anás, por ser sogro de Caifás, que era o sumo sacerdote daquele ano.',
        'Ora, Caifás era quem tinha aconselhado aos judeus que convinha que um homem morresse pelo povo.',
        'E Simão Pedro e outro discípulo seguiam a Jesus. E este discípulo era conhecido do sumo sacerdote, e entrou com Jesus na sala do sumo sacerdote.',
        'E Pedro estava da parte de fora, à porta. Saiu então o outro discípulo que era conhecido do sumo sacerdote, e falou à porteira, levando Pedro para dentro.',
        'Então a porteira disse a Pedro: Não és tu também dos discípulos deste homem? Disse ele: Não sou.',
        'Ora, estavam ali os servos e os servidores, que tinham feito brasas, e se aquentavam, porque fazia frio; e com eles estava Pedro, aquentando-se também.',
        'E o sumo sacerdote interrogou Jesus acerca dos seus discípulos e da sua doutrina.',
        'Jesus lhe respondeu: Eu falei abertamente ao mundo; eu sempre ensinei na sinagoga e no templo, onde os judeus sempre se ajuntam, e nada disse em oculto.',
        'Para que me perguntas a mim? Pergunta aos que ouviram o que é que lhes ensinei; eis que eles sabem o que eu lhes tenho dito.',
        'E, tendo dito isto, um dos servidores que ali estavam, deu uma bofetada em Jesus, dizendo: Assim respondes ao sumo sacerdote?',
        'Respondeu-lhe Jesus: Se falei mal, dá testemunho do mal; e, se bem, por que me feres?',
        'E Anás mandou-o, maniatado, ao sumo sacerdote Caifás.',
        'E Simão Pedro estava ali, e aquentava-se. Disseram-lhe, pois: Não és também tu um dos seus discípulos? Ele negou, e disse: Não sou.',
        'E um dos servos do sumo sacerdote, parente daquele a quem Pedro cortara a orelha, disse: Não te vi eu no horto com ele?',
        'E Pedro negou outra vez, e logo o galo cantou.',
        'Depois levaram Jesus da casa de Caifás para a audiência. E era pela manhã cedo. E não entraram na audiência, para não se contaminarem, mas poderem comer a páscoa.',
        'Então Pilatos saiu fora e disse-lhes: Que acusação trazeis contra este homem?',
        'Responderam, e disseram-lhe: Se este não fosse malfeitor, não to entregaríamos.',
        'Disse-lhes, pois, Pilatos: Levai-o vós, e julgai-o segundo a vossa lei. Disseram-lhe então os judeus: A nós não nos é lícito matar pessoa alguma.',
        '(Para que se cumprisse a palavra que Jesus tinha dito, significando de que morte havia de morrer).',
        'Tornou, pois, a entrar Pilatos na audiência, e chamou a Jesus, e disse-lhe: Tu és o Rei dos Judeus?',
        'Respondeu-lhe Jesus: Tu dizes isso de ti mesmo, ou disseram-to outros de mim?',
        'Pilatos respondeu: Porventura sou eu judeu? A tua nação e os principais dos sacerdotes entregaram-te a mim. Que fizeste?',
        'Respondeu Jesus: O meu reino não é deste mundo; se o meu reino fosse deste mundo, pelejariam os meus servos, para que eu não fosse entregue aos judeus; mas agora o meu reino não é daqui.',
        'Disse-lhe, pois, Pilatos: Logo tu és rei? Jesus respondeu: Tu dizes que eu sou rei. Eu para isso nasci, e para isso vim ao mundo, a fim de dar testemunho da verdade. Todo aquele que é da verdade ouve a minha voz.',
        'Disse-lhe Pilatos: Que é a verdade? E, dizendo isto, tornou a ir ter com os judeus, e disse-lhes: Não acho nele crime algum.',
        'Mas vós tendes por costume que eu vos solte alguém pela páscoa. Quereis, pois, que vos solte o Rei dos Judeus?',
        'Então todos tornaram a clamar, dizendo: Este não, mas Barrabás. E Barrabás era um salteador.',
      ],
      [
        'Pilatos, pois, tomou então a Jesus, e o açoitou.',
        'E os soldados, tecendo uma coroa de espinhos, lha puseram sobre a cabeça, e lhe vestiram roupa de púrpura.',
        'E diziam: Salve, Rei dos Judeus. E davam-lhe bofetadas.',
        'Então Pilatos saiu outra vez fora, e disse-lhes: Eis aqui vo-lo trago fora, para que saibais que não acho nele crime algum.',
        'Saiu, pois, Jesus fora, levando a coroa de espinhos e roupa de púrpura. E disse-lhes Pilatos: Eis aqui o homem.',
        'Vendo-o, pois, os principais dos sacerdotes e os servos, clamaram, dizendo: Crucifica-o, crucifica-o. Disse-lhes Pilatos: Tomai-o vós, e crucificai-o; porque eu nenhum crime acho nele.',
        'Responderam-lhe os judeus: Nós temos uma lei e, segundo a nossa lei, deve morrer, porque se fez Filho de Deus.',
        'E Pilatos, quando ouviu esta palavra, mais atemorizado ficou.',
        'E entrou outra vez na audiência, e disse a Jesus: De onde és tu? Mas Jesus não lhe deu resposta.',
        'Disse-lhe, pois, Pilatos: Não me falas a mim? Não sabes tu que tenho poder para te crucificar e tenho poder para te soltar?',
        'Respondeu Jesus: Nenhum poder terias contra mim, se de cima não te fosse dado; mas aquele que me entregou a ti maior pecado tem.',
        'Desde então Pilatos procurava soltá-lo; mas os judeus clamavam, dizendo: Se soltas este, não és amigo de César; qualquer que se faz rei é contra César.',
        'Ouvindo, pois, Pilatos este dito, levou Jesus para fora, e assentou-se no tribunal, no lugar chamado Litóstrotos, e em hebraico Gabatá.',
        'E era a preparação da páscoa, e quase à hora sexta; e disse aos judeus: Eis aqui o vosso Rei.',
        'Mas eles bradaram: Tira, tira, crucifica-o. Disse-lhes Pilatos: Hei de crucificar o vosso Rei? Responderam os principais dos sacerdotes: Não temos rei, senão César.',
        'Então, consequentemente entregou-lho, para que fosse crucificado. E tomaram a Jesus, e o levaram.',
        'E, levando ele às costas a sua cruz, saiu para o lugar chamado Caveira, que em hebraico se chama Gólgota,',
        'Onde o crucificaram, e com ele outros dois, um de cada lado, e Jesus no meio.',
        'E Pilatos escreveu também um título, e pô-lo em cima da cruz; e nele estava escrito: JESUS NAZARENO, O REI DOS JUDEUS.',
        'E muitos dos judeus leram este título; porque o lugar onde Jesus estava crucificado era próximo da cidade; e estava escrito em hebraico, grego e latim.',
        'Diziam, pois, os principais sacerdotes dos judeus a Pilatos: Não escrevas, O Rei dos Judeus, mas que ele disse: Sou o Rei dos Judeus.',
        'Respondeu Pilatos: O que escrevi, escrevi.',
        'Tendo, pois, os soldados crucificado a Jesus, tomaram as suas vestes, e fizeram quatro partes, para cada soldado uma parte; e também a túnica. A túnica, porém, tecida toda de alto a baixo, não tinha costura.',
        'Disseram, pois, uns aos outros: Não a rasguemos, mas lancemos sortes sobre ela, para ver de quem será. Para que se cumprisse a Escritura que diz: Repartiram entre si as minhas vestes, E sobre a minha vestidura lançaram sortes. Os soldados, pois, fizeram estas coisas.',
        'E junto à cruz de Jesus estava sua mãe, e a irmã de sua mãe, Maria mulher de Clopas, e Maria Madalena.',
        'Ora Jesus, vendo ali sua mãe, e que o discípulo a quem ele amava estava presente, disse a sua mãe: Mulher, eis aí o teu filho.',
        'Depois disse ao discípulo: Eis aí tua mãe. E desde aquela hora o discípulo a recebeu em sua casa.',
        'Depois, sabendo Jesus que já todas as coisas estavam terminadas, para que a Escritura se cumprisse, disse: Tenho sede.',
        'Estava, pois, ali um vaso cheio de vinagre. E encheram de vinagre uma esponja, e, pondo-a num hissopo, lha chegaram à boca.',
        'E, quando Jesus tomou o vinagre, disse: Está consumado. E, inclinando a cabeça, entregou o espírito.',
        'Os judeus, pois, para que no sábado não ficassem os corpos na cruz, visto como era a preparação (pois era grande o dia de sábado), rogaram a Pilatos que se lhes quebrassem as pernas, e fossem tirados.',
        'Foram, pois, os soldados, e, na verdade, quebraram as pernas ao primeiro, e ao outro que como ele fora crucificado;',
        'Mas, vindo a Jesus, e vendo-o já morto, não lhe quebraram as pernas.',
        'Contudo um dos soldados lhe furou o lado com uma lança, e logo saiu sangue e água.',
        'E aquele que o viu testificou, e o seu testemunho é verdadeiro; e sabe que é verdade o que diz, para que também vós o creiais.',
        'Porque isto aconteceu para que se cumprisse a Escritura, que diz: Nenhum dos seus ossos será quebrado.',
        'E outra vez diz a Escritura: Verão aquele que traspassaram.',
        'Depois disto, José de Arimatéia (o que era discípulo de Jesus, mas oculto, por medo dos judeus) rogou a Pilatos que lhe permitisse tirar o corpo de Jesus. E Pilatos lho permitiu. Então foi e tirou o corpo de Jesus.',
        'E foi também Nicodemos (aquele que anteriormente se dirigira de noite a Jesus), levando quase cem arráteis de um composto de mirra e aloés.',
        'Tomaram, pois, o corpo de Jesus e o envolveram em lençóis com as especiarias, como os judeus costumam fazer, na preparação para o sepulcro.',
        'E havia um horto naquele lugar onde fora crucificado, e no horto um sepulcro novo, em que ainda ninguém havia sido posto.',
        'Ali, pois (por causa da preparação dos judeus, e por estar perto aquele sepulcro), puseram a Jesus.',
      ],
      [
        'E no primeiro dia da semana, Maria Madalena foi ao sepulcro de madrugada, sendo ainda escuro, e viu a pedra tirada do sepulcro.',
        'Correu, pois, e foi a Simão Pedro, e ao outro discípulo, a quem Jesus amava, e disse-lhes: Levaram o Senhor do sepulcro, e não sabemos onde o puseram.',
        'Então Pedro saiu com o outro discípulo, e foram ao sepulcro.',
        'E os dois corriam juntos, mas o outro discípulo correu mais apressadamente do que Pedro, e chegou primeiro ao sepulcro.',
        'E, abaixando-se, viu no chão os lençóis; todavia não entrou.',
        'Chegou, pois, Simão Pedro, que o seguia, e entrou no sepulcro, e viu no chão os lençóis,',
        'E que o lenço, que tinha estado sobre a sua cabeça, não estava com os lençóis, mas enrolado num lugar à parte.',
        'Então entrou também o outro discípulo, que chegara primeiro ao sepulcro, e viu, e creu.',
        'Porque ainda não sabiam a Escritura, que era necessário que ressuscitasse dentre os mortos.',
        'Tornaram, pois, os discípulos para casa.',
        'E Maria estava chorando fora, junto ao sepulcro. Estando ela, pois, chorando, abaixou-se para o sepulcro.',
        'E viu dois anjos vestidos de branco, assentados onde jazera o corpo de Jesus, um à cabeceira e outro aos pés.',
        'E disseram-lhe eles: Mulher, por que choras? Ela lhes disse: Porque levaram o meu Senhor, e não sei onde o puseram.',
        'E, tendo dito isto, voltou-se para trás, e viu Jesus em pé, mas não sabia que era Jesus.',
        'Disse-lhe Jesus: Mulher, por que choras? Quem buscas? Ela, cuidando que era o hortelão, disse-lhe: Senhor, se tu o levaste, dize-me onde o puseste, e eu o levarei.',
        'Disse-lhe Jesus: Maria! Ela, voltando-se, disse-lhe: Raboni, que quer dizer: Mestre.',
        'Disse-lhe Jesus: Não me detenhas, porque ainda não subi para meu Pai, mas vai para meus irmãos, e dize-lhes que eu subo para meu Pai e vosso Pai, meu Deus e vosso Deus.',
        'Maria Madalena foi e anunciou aos discípulos que vira o Senhor, e que ele lhe dissera isto.',
        'Chegada, pois, a tarde daquele dia, o primeiro da semana, e cerradas as portas onde os discípulos, com medo dos judeus, se tinham ajuntado, chegou Jesus, e pôs-se no meio, e disse-lhes: Paz seja convosco.',
        'E, dizendo isto, mostrou-lhes as suas mãos e o lado. De sorte que os discípulos se alegraram, vendo o Senhor.',
        'Disse-lhes, pois, Jesus outra vez: Paz seja convosco; assim como o Pai me enviou, também eu vos envio a vós.',
        'E, havendo dito isto, assoprou sobre eles e disse-lhes: Recebei o Espírito Santo.',
        '«queles a quem perdoardes os pecados lhes são perdoados; e àqueles a quem os retiverdes lhes são retidos.',
        'Ora, Tomé, um dos doze, chamado Dídimo, não estava com eles quando veio Jesus.',
        'Disseram-lhe, pois, os outros discípulos: Vimos o Senhor. Mas ele disse-lhes: Se eu não vir o sinal dos cravos em suas mãos, e não puser o meu dedo no lugar dos cravos, e não puser a minha mão no seu lado, de maneira nenhuma o crerei.',
        'E oito dias depois estavam outra vez os seus discípulos dentro, e com eles Tomé. Chegou Jesus, estando as portas fechadas, e apresentou-se no meio, e disse: Paz seja convosco.',
        'Depois disse a Tomé: Põe aqui o teu dedo, e vê as minhas mãos; e chega a tua mão, e põe-na no meu lado; e não sejas incrédulo, mas crente.',
        'E Tomé respondeu, e disse-lhe: Senhor meu, e Deus meu!',
        'Disse-lhe Jesus: Porque me viste, Tomé, creste; bem-aventurados os que não viram e creram.',
        'Jesus, pois, operou também em presença de seus discípulos muitos outros sinais, que não estão escritos neste livro.',
        'Estes, porém, foram escritos para que creiais que Jesus é o Cristo, o Filho de Deus, e para que, crendo, tenhais vida em seu nome.',
      ],
      [
        'Depois disto manifestou-se Jesus outra vez aos discípulos junto do mar de Tiberíades; e manifestou-se assim:',
        'Estavam juntos Simão Pedro, e Tomé, chamado Dídimo, e Natanael, que era de Caná da Galiléia, os filhos de Zebedeu, e outros dois dos seus discípulos.',
        'Disse-lhes Simão Pedro: Vou pescar. Dizem-lhe eles: Também nós vamos contigo. Foram, e subiram logo para o barco, e naquela noite nada apanharam.',
        'E, sendo já manhã, Jesus se apresentou na praia, mas os discípulos não conheceram que era Jesus.',
        'Disse-lhes, pois, Jesus: Filhos, tendes alguma coisa de comer? Responderam-lhe: Não.',
        'E ele lhes disse: Lançai a rede para o lado direito do barco, e achareis. Lançaram-na, pois, e já não a podiam tirar, pela multidão dos peixes.',
        'Então aquele discípulo, a quem Jesus amava, disse a Pedro: É o Senhor. E, quando Simão Pedro ouviu que era o Senhor, cingiu-se com a túnica (porque estava nu) e lançou-se ao mar.',
        'E os outros discípulos foram com o barco (porque não estavam distantes da terra senão quase duzentos côvados), levando a rede cheia de peixes.',
        'Logo que desceram para terra, viram ali brasas, e um peixe posto em cima, e pão.',
        'Disse-lhes Jesus: Trazei dos peixes que agora apanhastes.',
        'Simão Pedro subiu e puxou a rede para terra, cheia de cento e cinqüenta e três grandes peixes e, sendo tantos, não se rompeu a rede.',
        'Disse-lhes Jesus: Vinde, comei. E nenhum dos discípulos ousava perguntar-lhe: Quem és tu? sabendo que era o Senhor.',
        'Chegou, pois, Jesus, e tomou o pão, e deu-lhes e, semelhantemente o peixe.',
        'E já era a terceira vez que Jesus se manifestava aos seus discípulos, depois de ter ressuscitado dentre os mortos.',
        'E, depois de terem jantado, disse Jesus a Simão Pedro: Simão, filho de Jonas, amas-me mais do que estes? E ele respondeu: Sim, Senhor, tu sabes que te amo. Disse-lhe: Apascenta os meus cordeiros.',
        'Tornou a dizer-lhe segunda vez: Simão, filho de Jonas, amas-me? Disse-lhe: Sim, Senhor, tu sabes que te amo. Disse-lhe: Apascenta as minhas ovelhas.',
        'Disse-lhe terceira vez: Simão, filho de Jonas, amas-me? Simão entristeceu-se por lhe ter dito terceira vez: Amas-me? E disse-lhe: Senhor, tu sabes tudo; tu sabes que eu te amo. Jesus disse-lhe: Apascenta as minhas ovelhas.',
        'Na verdade, na verdade te digo que, quando eras mais moço, te cingias a ti mesmo, e andavas por onde querias; mas, quando já fores velho, estenderás as tuas mãos, e outro te cingirá, e te levará para onde tu não queiras.',
        'E disse isto, significando com que morte havia ele de glorificar a Deus. E, dito isto, disse-lhe: Segue-me.',
        'E Pedro, voltando-se, viu que o seguia aquele discípulo a quem Jesus amava, e que na ceia se recostara também sobre o seu peito, e que dissera: Senhor, quem é que te há de trair?',
        'Vendo Pedro a este, disse a Jesus: Senhor, e deste que será?',
        'Disse-lhe Jesus: Se eu quero que ele fique até que eu venha, que te importa a ti? Segue-me tu.',
        'Divulgou-se, pois, entre os irmãos este dito, que aquele discípulo não havia de morrer. Jesus, porém, não lhe disse que não morreria, mas: Se eu quero que ele fique até que eu venha, que te importa a ti?',
        'Este é o discípulo que testifica destas coisas e as escreveu; e sabemos que o seu testemunho é verdadeiro.',
        'Há, porém, ainda muitas outras coisas que Jesus fez; e se cada uma das quais fosse escrita, cuido que nem ainda o mundo todo poderia conter os livros que se escrevessem. Amém.',
      ],
    ],
    livro: 'joao',
  },
  {
    abbrev: 'at',
    capitulos: [
      [
        'Fiz o primeiro tratado, ó Teófilo, acerca de tudo que Jesus começou, não só a fazer, mas a ensinar,',
        'Até ao dia em que foi recebido em cima, depois de ter dado mandamentos, pelo Espírito Santo, aos apóstolos que escolhera;',
        'Aos quais também, depois de ter padecido, se apresentou vivo, com muitas e infalíveis provas, sendo visto por eles por espaço de quarenta dias, e falando das coisas concernentes ao reino de Deus.',
        'E, estando com eles, determinou-lhes que não se ausentassem de Jerusalém, mas que esperassem a promessa do Pai, que, disse ele, de mim ouvistes.',
        'Porque, na verdade, João batizou com água, mas vós sereis batizados com o Espírito Santo, não muito depois destes dias.',
        'Aqueles, pois, que se haviam reunido perguntaram-lhe, dizendo: Senhor, restaurarás tu neste tempo o reino a Israel?',
        'E disse-lhes: Não vos pertence saber os tempos ou as estações que o Pai estabeleceu pelo seu próprio poder.',
        'Mas recebereis a virtude do Espírito Santo, que há de vir sobre vós; e ser-me-eis testemunhas, tanto em Jerusalém como em toda a Judéia e Samaria, e até aos confins da terra.',
        'E, quando dizia isto, vendo-o eles, foi elevado às alturas, e uma nuvem o recebeu, ocultando-o a seus olhos.',
        'E, estando com os olhos fitos no céu, enquanto ele subia, eis que junto deles se puseram dois homens vestidos de branco.',
        'Os quais lhes disseram: Homens galileus, por que estais olhando para o céu? Esse Jesus, que dentre vós foi recebido em cima no céu, há de vir assim como para o céu o vistes ir.',
        'Então voltaram para Jerusalém, do monte chamado das Oliveiras, o qual está perto de Jerusalém, à distância do caminho de um sábado.',
        'E, entrando, subiram ao cenáculo, onde habitavam Pedro e Tiago, João e André, Filipe e Tomé, Bartolomeu e Mateus, Tiago, filho de Alfeu, Simão, o Zelote, e Judas, irmào de Tiago.',
        'Todos estes perseveravam unanimemente em oração e súplicas, com as mulheres, e Maria mãe de Jesus, e com seus irmãos.',
        'E naqueles dias, levantando-se Pedro no meio dos discípulos (ora a multidão junta era de quase cento e vinte pessoas) disse:',
        'Homens irmãos, convinha que se cumprisse a Escritura que o Espírito Santo predisse pela boca de Davi, acerca de Judas, que foi o guia daqueles que prenderam a Jesus;',
        'Porque foi contado conosco e alcançou sorte neste ministério.',
        'Ora, este adquiriu um campo com o galardão da iniqüidade; e, precipitando-se, rebentou pelo meio, e todas as suas entranhas se derramaram.',
        'E foi notório a todos os que habitam em Jerusalém; de maneira que na sua própria língua esse campo se chama Aceldama, isto é, Campo de Sangue.',
        'Porque no livro dos Salmos está escrito: Fique deserta a sua habitação, E não haja quem nela habite, e: Tome outro o seu bispado.',
        'É necessário, pois, que, dos homens que conviveram conosco todo o tempo em que o Senhor Jesus entrou e saiu dentre nós,',
        'Começando desde o batismo de João até ao dia em que de entre nós foi recebido em cima, um deles se faça conosco testemunha da sua ressurreição.',
        'E apresentaram dois: José, chamado Barsabás, que tinha por sobrenome o Justo, e Matias.',
        'E, orando, disseram: Tu, Senhor, conhecedor dos corações de todos, mostra qual destes dois tens escolhido,',
        'Para que tome parte neste ministério e apostolado, de que Judas se desviou, para ir para o seu próprio lugar.',
        'E, lançando-lhes sortes, caiu a sorte sobre Matias. E por voto comum foi contado com os onze apóstolos.',
      ],
      [
        'E, cumprindo-se o dia de Pentecostes, estavam todos concordemente no mesmo lugar;',
        'E de repente veio do céu um som, como de um vento veemente e impetuoso, e encheu toda a casa em que estavam assentados.',
        'E foram vistas por eles línguas repartidas, como que de fogo, as quais pousaram sobre cada um deles.',
        'E todos foram cheios do Espírito Santo, e começaram a falar noutras línguas, conforme o Espírito Santo lhes concedia que falassem.',
        'E em Jerusalém estavam habitando judeus, homens religiosos, de todas as nações que estão debaixo do céu.',
        'E, quando aquele som ocorreu, ajuntou-se uma multidão, e estava confusa, porque cada um os ouvia falar na sua própria língua.',
        'E todos pasmavam e se maravilhavam, dizendo uns aos outros: Pois quê! não são galileus todos esses homens que estão falando?',
        'Como, pois, os ouvimos, cada um, na nossa própria língua em que somos nascidos?',
        'Partos e medos, elamitas e os que habitam na Mesopotâmia, Judéia, Capadócia, Ponto e Asia,',
        'E Frígia e Panfília, Egito e partes da Líbia, junto a Cirene, e forasteiros romanos, tanto judeus como prosélitos,',
        'Cretenses e árabes, todos nós temos ouvido em nossas próprias línguas falar das grandezas de Deus.',
        'E todos se maravilhavam e estavam suspensos, dizendo uns para os outros: Que quer isto dizer?',
        'E outros, zombando, diziam: Estão cheios de mosto.',
        'Pedro, porém, pondo-se em pé com os onze, levantou a sua voz, e disse-lhes: Homens judeus, e todos os que habitais em Jerusalém, seja-vos isto notório, e escutai as minhas palavras.',
        'Estes homens não estão embriagados, como vós pensais, sendo a terceira hora do dia.',
        'Mas isto é o que foi dito pelo profeta Joel:',
        'E nos últimos dias acontecerá, diz Deus, Que do meu Espírito derramarei sobre toda a carne; E os vossos filhos e as vossas filhas profetizarão, Os vossos jovens terão visões, E os vossos velhos sonharão sonhos;',
        'E também do meu Espírito derramarei sobre os meus servos e as minhas servas naqueles dias, e profetizarão;',
        'E farei aparecer prodígios em cima, no céu; E sinais em baixo na terra, Sangue, fogo e vapor de fumo.',
        'O sol se converterá em trevas, E a lua em sangue, Antes de chegar o grande e glorioso dia do Senhor;',
        'E acontecerá que todo aquele que invocar o nome do Senhor será salvo.',
        'Homens israelitas, escutai estas palavras: A Jesus Nazareno, homem aprovado por Deus entre vós com maravilhas, prodígios e sinais, que Deus por ele fez no meio de vós, como vós mesmos bem sabeis;',
        'A este que vos foi entregue pelo determinado conselho e presciência de Deus, prendestes, crucificastes e matastes pelas mãos de injustos;',
        'Ao qual Deus ressuscitou, soltas as ânsias da morte, pois não era possível que fosse retido por ela;',
        'Porque dele disse Davi: Sempre via diante de mim o Senhor, Porque está à minha direita, para que eu não seja comovido;',
        'Por isso se alegrou o meu coração, e a minha língua exultou; E ainda a minha carne há de repousar em esperança;',
        'Pois não deixarás a minha alma no inferno, Nem permitirás que o teu Santo veja a corrupção;',
        'Fizeste-me conhecidos os caminhos da vida; Com a tua face me encherás de júbilo.',
        'Homens irmãos, seja-me lícito dizer-vos livremente acerca do patriarca Davi, que ele morreu e foi sepultado, e entre nós está até hoje a sua sepultura.',
        'Sendo, pois, ele profeta, e sabendo que Deus lhe havia prometido com juramento que do fruto de seus lombos, segundo a carne, levantaria o Cristo, para o assentar sobre o seu trono,',
        'Nesta previsão, disse da ressurreição de Cristo, que a sua alma não foi deixada no inferno, nem a sua carne viu a corrupção.',
        'Deus ressuscitou a este Jesus, do que todos nós somos testemunhas.',
        'De sorte que, exaltado pela destra de Deus, e tendo recebido do Pai a promessa do Espírito Santo, derramou isto que vós agora vedes e ouvis.',
        'Porque Davi não subiu aos céus, mas ele próprio diz:Disse o Senhor ao meu Senhor:Assenta-te à minha direita,',
        'Até que ponha os teus inimigos por escabelo de teus pés.',
        'Saiba pois com certeza toda a casa de Israel que a esse Jesus, a quem vós crucificastes, Deus o fez Senhor e Cristo.',
        'E, ouvindo eles isto, compungiram-se em seu coração, e perguntaram a Pedro e aos demais apóstolos: Que faremos, homens irmãos?',
        'E disse-lhes Pedro: Arrependei-vos, e cada um de vós seja batizado em nome de Jesus Cristo, para perdão dos pecados; e recebereis o dom do Espírito Santo;',
        'Porque a promessa vos diz respeito a vós, a vossos filhos, e a todos os que estão longe, a tantos quantos Deus nosso Senhor chamar.',
        'E com muitas outras palavras isto testificava, e os exortava, dizendo: Salvai-vos desta geração perversa.',
        'De sorte que foram batizados os que de bom grado receberam a sua palavra; e naquele dia agregaram-se quase três mil almas,',
        'E perseveravam na doutrina dos apóstolos, e na comunhão, e no partir do pão, e nas orações.',
        'E em toda a alma havia temor, e muitas maravilhas e sinais se faziam pelos apóstolos.',
        'E todos os que criam estavam juntos, e tinham tudo em comum.',
        'E vendiam suas propriedades e bens, e repartiam com todos, segundo cada um havia de mister.',
        'E, perseverando unânimes todos os dias no templo, e partindo o pão em casa, comiam juntos com alegria e singeleza de coração,',
        'Louvando a Deus, e caindo na graça de todo o povo. E todos os dias acrescentava o Senhor à igreja aqueles que se haviam de salvar.',
      ],
      [
        'E Pedro e João subiam juntos ao templo à hora da oração, a nona.',
        'E era trazido um homem que desde o ventre de sua mãe era coxo, o qual todos os dias punham à porta do templo, chamada Formosa, para pedir esmola aos que entravam.',
        'O qual, vendo a Pedro e a João que iam entrando no templo, pediu que lhe dessem uma esmola.',
        'E Pedro, com João, fitando os olhos nele, disse: Olha para nós.',
        'E olhou para eles, esperando receber deles alguma coisa.',
        'E disse Pedro: Não tenho prata nem ouro; mas o que tenho isso te dou. Em nome de Jesus Cristo, o Nazareno, levanta-te e anda.',
        'E, tomando-o pela mão direita, o levantou, e logo os seus pés e artelhos se firmaram.',
        'E, saltando ele, pôs-se em pé, e andou, e entrou com eles no templo, andando, e saltando, e louvando a Deus.',
        'E todo o povo o viu andar e louvar a Deus;',
        'E conheciam-no, pois era ele o que se assentava a pedir esmola à porta Formosa do templo; e ficaram cheios de pasmo e assombro, pelo que lhe acontecera.',
        'E, apegando-se o coxo, que fora curado, a Pedro e João, todo o povo correu atônito para junto deles, ao alpendre chamado de Salomão.',
        'E quando Pedro viu isto, disse ao povo: Homens israelitas, por que vos maravilhais disto? Ou, por que olhais tanto para nós, como se por nossa própria virtude ou santidade fizéssemos andar este homem?',
        'O Deus de Abraão, de Isaque e de Jacó, o Deus de nossos pais, glorificou a seu filho Jesus, a quem vós entregastes e perante a face de Pilatos negastes, tendo ele determinado que fosse solto.',
        'Mas vós negastes o Santo e o Justo, e pedistes que se vos desse um homem homicida.',
        'E matastes o Príncipe da vida, ao qual Deus ressuscitou dentre os mortos, do que nós somos testemunhas.',
        'E pela fé no seu nome fez o seu nome fortalecer a este que vedes e conheceis; sim, a fé que vem por ele, deu a este, na presença de todos vós, esta perfeita saúde.',
        'E agora, irmãos, eu sei que o fizestes por ignorância, como também os vossos príncipes.',
        'Mas Deus assim cumpriu o que já dantes pela boca de todos os seus profetas havia anunciado; que o Cristo havia de padecer.',
        'Arrependei-vos, pois, e convertei-vos, para que sejam apagados os vossos pecados, e venham assim os tempos do refrigério pela presença do Senhor,',
        'E envie ele a Jesus Cristo, que já dantes vos foi pregado.',
        'O qual convém que o céu contenha até aos tempos da restauração de tudo, dos quais Deus falou pela boca de todos os seus santos profetas, desde o princípio.',
        'Porque Moisés disse aos pais: O Senhor vosso Deus levantará de entre vossos irmãos um profeta semelhante a mim; a ele ouvireis em tudo quanto vos disser.',
        'E acontecerá que toda a alma que não escutar esse profeta será exterminada dentre o povo.',
        'Sim, e todos os profetas, desde Samuel, todos quantos depois falaram, também predisseram estes dias.',
        'Vós sois os filhos dos profetas e da aliança que Deus fez com nossos pais, dizendo a Abraão: Na tua descendência serão benditas todas as famílias da terra.',
        'Ressuscitando Deus a seu Filho Jesus, primeiro o enviou a vós, para que nisso vos abençoasse, no apartar, a cada um de vós, das vossas maldades.',
      ],
      [
        'E, estando eles falando ao povo, sobrevieram os sacerdotes, e o capitão do templo, e os saduceus,',
        'Doendo-se muito de que ensinassem o povo, e anunciassem em Jesus a ressurreição dentre os mortos.',
        'E lançaram mão deles, e os encerraram na prisão até ao dia seguinte, pois já era tarde.',
        'Muitos, porém, dos que ouviram a palavra creram, e chegou o número desses homens a quase cinco mil.',
        'E aconteceu, no dia seguinte, reunirem-se em Jerusalém os seus principais, os anciãos, os escribas,',
        'E Anás, o sumo sacerdote, e Caifás, e João, e Alexandre, e todos quantos havia da linhagem do sumo sacerdote.',
        'E, pondo-os no meio, perguntaram: Com que poder ou em nome de quem fizestes isto?',
        'Então Pedro, cheio do Espírito Santo, lhes disse: Principais do povo, e vós, anciãos de Israel,',
        'Visto que hoje somos interrogados acerca do benefício feito a um homem enfermo, e do modo como foi curado,',
        'Seja conhecido de vós todos, e de todo o povo de Israel, que em nome de Jesus Cristo, o Nazareno, aquele a quem vós crucificastes e a quem Deus ressuscitou dentre os mortos, em nome desse é que este está são diante de vós.',
        'Ele é a pedra que foi rejeitada por vós, os edificadores, a qual foi posta por cabeça de esquina.',
        'E em nenhum outro há salvação, porque também debaixo do céu nenhum outro nome há, dado entre os homens, pelo qual devamos ser salvos.',
        'Então eles, vendo a ousadia de Pedro e João, e informados de que eram homens sem letras e indoutos, maravilharam-se e reconheceram que eles haviam estado com Jesus.',
        'E, vendo estar com eles o homem que fora curado, nada tinham que dizer em contrário.',
        'Todavia, mandando-os sair fora do conselho, conferenciaram entre si,',
        'Dizendo: Que havemos de fazer a estes homens? porque a todos os que habitam em Jerusalém é manifesto que por eles foi feito um sinal notório, e não o podemos negar;',
        'Mas, para que não se divulgue mais entre o povo, ameacemo-los para que não falem mais nesse nome a homem algum.',
        'E, chamando-os, disseram-lhes que absolutamente não falassem, nem ensinassem, no nome de Jesus.',
        'Respondendo, porém, Pedro e João, lhes disseram: Julgai vós se é justo, diante de Deus, ouvir-vos antes a vós do que a Deus;',
        'Porque não podemos deixar de falar do que temos visto e ouvido.',
        'Mas eles ainda os ameaçaram mais e, não achando motivo para os castigar, deixaram-nos ir, por causa do povo; porque todos glorificavam a Deus pelo que acontecera;',
        'Pois tinha mais de quarenta anos o homem em quem se operara aquele milagre de saúde.',
        'E, soltos eles, foram para os seus, e contaram tudo o que lhes disseram os principais dos sacerdotes e os anciãos.',
        'E, ouvindo eles isto, unânimes levantaram a voz a Deus, e disseram: Senhor, tu és o Deus que fizeste o céu, e a terra, e o mar e tudo o que neles há;',
        'Que disseste pela boca de Davi, teu servo: Por que bramaram os gentios, e os povos pensaram coisas vãs?',
        'Levantaram-se os reis da terra,E os príncipes se ajuntaram à uma,Contra o Senhor e contra o seu Ungido.',
        'Porque verdadeiramente contra o teu santo Filho Jesus, que tu ungiste, se ajuntaram, não só Herodes, mas Pôncio Pilatos, com os gentios e os povos de Israel;',
        'Para fazerem tudo o que a tua mão e o teu conselho tinham anteriormente determinado que se havia de fazer.',
        'Agora, pois, ó Senhor, olha para as suas ameaças, e concede aos teus servos que falem com toda a ousadia a tua palavra;',
        'Enquanto estendes a tua mão para curar, e para que se façam sinais e prodígios pelo nome de teu santo Filho Jesus.',
        'E, tendo orado, moveu-se o lugar em que estavam reunidos; e todos foram cheios do Espírito Santo, e anunciavam com ousadia a palavra de Deus.',
        'E era um o coração e a alma da multidão dos que criam, e ninguém dizia que coisa alguma do que possuía era sua própria, mas todas as coisas lhes eram comuns.',
        'E os apóstolos davam, com grande poder, testemunho da ressurreição do Senhor Jesus, e em todos eles havia abundante graça.',
        'Não havia, pois, entre eles necessitado algum; porque todos os que possuíam herdades ou casas, vendendo-as, traziam o preço do que fora vendido, e o depositavam aos pés dos apóstolos.',
        'E repartia-se a cada um, segundo a necessidade que cada um tinha.',
        'Então José, cognominado pelos apóstolos Barnabé(que, traduzido, é Filho da consolação), levita, natural de Chipre,',
        'Possuindo uma herdade, vendeu-a, e trouxe o preço, e o depositou aos pés dos apóstolos.',
      ],
      [
        'Mas um certo homem chamado Ananias, com Safira, sua mulher, vendeu uma propriedade,',
        'E reteve parte do preço, sabendo-o também sua mulher; e, levando uma parte, a depositou aos pés dos apóstolos.',
        'Disse então Pedro: Ananias, por que encheu Satanás o teu coração, para que mentisses ao Espírito Santo, e retivesses parte do preço da herdade?',
        'Guardando-a não ficava para ti? E, vendida, não estava em teu poder? Por que formaste este desígnio em teu coração? Não mentiste aos homens, mas a Deus.',
        'E Ananias, ouvindo estas palavras, caiu e expirou. E um grande temor veio sobre todos os que isto ouviram.',
        'E, levantando-se os moços, cobriram o morto e, transportando-o para fora, o sepultaram.',
        'E, passando um espaço quase de três horas, entrou também sua mulher, não sabendo o que havia acontecido.',
        'E disse-lhe Pedro: Dize-me, vendestes por tanto aquela herdade? E ela disse: Sim, por tanto.',
        'Então Pedro lhe disse: Por que é que entre vós vos concertastes para tentar o Espírito do Senhor? Eis aí à porta os pés dos que sepultaram o teu marido, e também te levarão a ti.',
        'E logo caiu aos seus pés, e expirou. E, entrando os moços, acharam-na morta, e a sepultaram junto de seu marido.',
        'E houve um grande temor em toda a igreja, e em todos os que ouviram estas coisas.',
        'E muitos sinais e prodígios eram feitos entre o povo pelas mãos dos apóstolos. E estavam todos unanimemente no alpendre de Salomão.',
        'Dos outros, porém, ninguém ousava ajuntar-se a eles; mas o povo tinha-os em grande estima.',
        'E a multidão dos que criam no Senhor, tanto homens como mulheres, crescia cada vez mais.',
        'De sorte que transportavam os enfermos para as ruas, e os punham em leitos e em camilhas para que ao menos a sombra de Pedro, quando este passasse, cobrisse alguns deles.',
        'E até das cidades circunvizinhas concorria muita gente a Jerusalém, conduzindo enfermos e atormentados de espíritos imundos; os quais eram todos curados.',
        'E, levantando-se o sumo sacerdote, e todos os que estavam com ele (e eram eles da seita dos saduceus), encheram-se de inveja,',
        'E lançaram mão dos apóstolos, e os puseram na prisão pública.',
        'Mas de noite um anjo do Senhor abriu as portas da prisão e, tirando-os para fora, disse:',
        'Ide e apresentai-vos no templo, e dizei ao povo todas as palavras desta vida.',
        'E, ouvindo eles isto, entraram de manhã cedo no templo, e ensinavam. Chegando, porém, o sumo sacerdote e os que estavam com ele, convocaram o conselho, e a todos os anciãos dos filhos de Israel, e enviaram ao cárcere, para que de lá os trouxessem.',
        'Mas, tendo lá ido os servidores, não os acharam na prisão e, voltando, lho anunciaram,',
        'Dizendo: Achamos realmente o cárcere fechado, com toda a segurança, e os guardas, que estavam fora, diante das portas; mas, quando abrimos, ninguém achamos dentro.',
        'Então o sumo sacerdote, o capitão do templo e os chefes dos sacerdotes, ouvindo estas palavras, estavam perplexos acerca deles e do que viria a ser aquilo.',
        'E, chegando um, anunciou-lhes, dizendo: Eis que os homens que encerrastes na prisão estão no templo e ensinam ao povo.',
        'Então foi o capitão com os servidores, e os trouxe, não com violência (porque temiam ser apedrejados pelo povo).',
        'E, trazendo-os, os apresentaram ao conselho. E o sumo sacerdote os interrogou,',
        'Dizendo: Não vos admoestamos nós expressamente que não ensinásseis nesse nome? E eis que enchestes Jerusalém dessa vossa doutrina, e quereis lançar sobre nós o sangue desse homem.',
        'Porém, respondendo Pedro e os apóstolos, disseram: Mais importa obedecer a Deus do que aos homens.',
        'O Deus de nossos pais ressuscitou a Jesus, ao qual vós matastes, suspendendo-o no madeiro.',
        'Deus com a sua destra o elevou a Príncipe e Salvador, para dar a Israel o arrependimento e a remissão dos pecados.',
        'E nós somos testemunhas acerca destas palavras, nós e também o Espírito Santo, que Deus deu àqueles que lhe obedecem.',
        'E, ouvindo eles isto, se enfureciam, e deliberaram matá-los.',
        'Mas, levantando-se no conselho um certo fariseu, chamado Gamaliel, doutor da lei, venerado por todo o povo, mandou que por um pouco levassem para fora os apóstolos;',
        'E disse-lhes: Homens israelitas, acautelai-vos a respeito do que haveis de fazer a estes homens,',
        'Porque antes destes dias levantou-se Teudas, dizendo ser alguém; a este se ajuntou o número de uns quatrocentos homens; o qual foi morto, e todos os que lhe deram ouvidos foram dispersos e reduzidos a nada.',
        'Depois deste levantou-se Judas, o galileu, nos dias do alistamento, e levou muito povo após si; mas também este pereceu, e todos os que lhe deram ouvidos foram dispersos.',
        'E agora digo-vos: Dai de mão a estes homens, e deixai-os, porque, se este conselho ou esta obra é de homens, se desfará,',
        'Mas, se é de Deus, não podereis desfazê-la; para que não aconteça serdes também achados combatendo contra Deus.',
        'E concordaram com ele. E, chamando os apóstolos, e tendo-os açoitado, mandaram que não falassem no nome de Jesus, e os deixaram ir.',
        'Retiraram-se, pois, da presença do conselho, regozijando-se de terem sido julgados dignos de padecer afronta pelo nome de Jesus.',
        'E todos os dias, no templo e nas casas, não cessavam de ensinar, e de anunciar a Jesus Cristo.',
      ],
      [
        'Ora, naqueles dias, crescendo o número dos discípulos, houve uma murmuração dos gregos contra os hebreus, porque as suas viúvas eram desprezadas no ministério cotidiano.',
        'E os doze, convocando a multidão dos discípulos, disseram: Não é razoável que nós deixemos a palavra de Deus e sirvamos às mesas.',
        'Escolhei, pois, irmãos, dentre vós, sete homens de boa reputação, cheios do Espírito Santo e de sabedoria, aos quais constituamos sobre este importante negócio.',
        'Mas nós perseveraremos na oração e no ministério da palavra.',
        'E este parecer contentou a toda a multidão, e elegeram Estêvão, homem cheio de fé e do Espírito Santo, e Filipe, e Prócoro, e Nicanor, e Timão, e Parmenas e Nicolau, prosélito de Antioquia;',
        'E os apresentaram ante os apóstolos, e estes, orando, lhes impuseram as mãos.',
        'E crescia a palavra de Deus, e em Jerusalém se multiplicava muito o número dos discípulos, e grande parte dos sacerdotes obedecia à fé.',
        'E Estêvão, cheio de fé e de poder, fazia prodígios e grandes sinais entre o povo.',
        'E levantaram-se alguns que eram da sinagoga chamada dos libertinos, e dos cireneus e dos alexandrinos, e dos que eram da Cilícia e da Asia, e disputavam com Estêvão.',
        'E não podiam resistir à sabedoria, e ao Espírito com que falava.',
        'Então subornaram uns homens, para que dissessem: Ouvimos-lhe proferir palavras blasfemas contra Moisés e contra Deus.',
        'E excitaram o povo, os anciãos e os escribas; e, investindo contra ele, o arrebataram e o levaram ao conselho.',
        'E apresentaram falsas testemunhas, que diziam: Este homem não cessa de proferir palavras blasfemas contra este santo lugar e a lei;',
        'Porque nós lhe ouvimos dizer que esse Jesus Nazareno há de destruir este lugar e mudar os costumes que Moisés nos deu.',
        'Então todos os que estavam assentados no conselho, fixando os olhos nele, viram o seu rosto como o rosto de um anjo.',
      ],
      [
        'E disse o sumo sacerdote: Porventura é isto assim?',
        'E ele disse: Homens, irmãos, e pais, ouvi. O Deus da glória apareceu a nosso pai Abraão, estando na mesopotâmia, antes de habitar em Harã,',
        'E disse-lhe: Sai da tua terra e dentre a tua parentela, e dirige-te à terra que eu te mostrar.',
        'Então saiu da terra dos caldeus, e habitou em Harã. E dali, depois que seu pai faleceu, Deus o trouxe para esta terra em que habitais agora.',
        'E não lhe deu nela herança, nem ainda o espaço de um pé; mas prometeu que lhe daria a posse dela, e depois dele, à sua descendência, não tendo ele ainda filho.',
        'E falou Deus assim: Que a sua descendência seria peregrina em terra alheia, e a sujeitariam à escravidão, e a maltratariam por quatrocentos anos.',
        'E eu julgarei a nação que os tiver escravizado, disse Deus. E depois disto sairão e me servirão neste lugar.',
        'E deu-lhe a aliança da circuncisão; e assim gerou a Isaque, e o circuncidou ao oitavo dia; e Isaque a Jacó; e Jacó aos doze patriarcas.',
        'E os patriarcas, movidos de inveja, venderam José para o Egito; mas Deus era com ele.',
        'E livrou-o de todas as suas tribulações, e lhe deu graça e sabedoria ante Faraó, rei do Egito, que o constituiu governador sobre o Egito e toda a sua casa.',
        'Sobreveio então a todo o país do Egito e de Canaã fome e grande tribulação; e nossos pais não achavam alimentos.',
        'Mas tendo ouvido Jacó que no Egito havia trigo, enviou ali nossos pais, a primeira vez.',
        'E na segunda vez foi José conhecido por seus irmãos, e a sua linhagem foi manifesta a Faraó.',
        'E José mandou chamar a seu pai Jacó, e a toda a sua parentela, que era de setenta e cinco almas.',
        'E Jacó desceu ao Egito, e morreu, ele e nossos pais;',
        'E foram transportados para Siquém, e depositados na sepultura que Abraão comprara por certa soma de dinheiro aos filhos de Emor, pai de Siquém.',
        'Aproximando-se, porém, o tempo da promessa que Deus tinha feito a Abraão, o povo cresceu e se multiplicou no Egito;',
        'Até que se levantou outro rei, que não conhecia a José.',
        'Esse, usando de astúcia contra a nossa linhagem, maltratou nossos pais, ao ponto de os fazer enjeitar as suas crianças, para que não se multiplicassem.',
        'Nesse tempo nasceu Moisés, e era mui formoso, e foi criado três meses em casa de seu pai.',
        'E, sendo enjeitado, tomou-o a filha de Faraó, e o criou como seu filho.',
        'E Moisés foi instruído em toda a ciência dos egípcios; e era poderoso em suas palavras e obras.',
        'E, quando completou a idade de quarenta anos, veio-lhe ao coração ir visitar seus irmãos, os filhos de Israel.',
        'E, vendo maltratado um deles, o defendeu, e vingou o ofendido, matando o egípcio.',
        'E ele cuidava que seus irmãos entenderiam que Deus lhes havia de dar a liberdade pela sua mão; mas eles não entenderam.',
        'E no dia seguinte, pelejando eles, foi por eles visto, e quis levá-los à paz, dizendo: Homens, sois irmãos; por que vos agravais um ao outro?',
        'E o que ofendia o seu próximo o repeliu, dizendo: Quem te constituiu príncipe e juiz sobre nós?',
        'Queres tu matar-me, como ontem mataste o egípcio?',
        'E a esta palavra fugiu Moisés, e esteve como estrangeiro na terra de Midiã, onde gerou dois filhos.',
        'E, completados quarenta anos, apareceu-lhe o anjo do Senhor no deserto do monte Sinai, numa chama de fogo no meio de uma sarça.',
        'Então Moisés, quando viu isto, se maravilhou da visão; e, aproximando-se para observar, foi-lhe dirigida a voz do Senhor,',
        'Dizendo: Eu sou o Deus de teus pais, o Deus de Abraão, e o Deus de Isaque, e o Deus de Jacó. E Moisés, todo trêmulo, não ousava olhar.',
        'E disse-lhe o Senhor: Tira as alparcas dos teus pés, porque o lugar em que estás é terra santa.',
        'Tenho visto atentamente a aflição do meu povo que está no Egito, e ouvi os seus gemidos, e desci a livrá-los. Agora, pois, vem, e enviar-te-ei ao Egito.',
        'A este Moisés, ao qual haviam negado, dizendo: Quem te constituiu príncipe e juiz? a este enviou Deus como príncipe e libertador, pela mão do anjo que lhe aparecera na sarça.',
        'Foi este que os conduziu para fora, fazendo prodígios e sinais na terra do Egito, e no Mar Vermelho, e no deserto, por quarenta anos.',
        'Este é aquele Moisés que disse aos filhos de Israel: O Senhor vosso Deus vos levantará dentre vossos irmãos um profeta como eu; a ele ouvireis.',
        'Este é o que esteve entre a congregação no deserto, com o anjo que lhe falava no monte Sinai, e com nossos pais, o qual recebeu as palavras de vida para no-las dar.',
        'Ao qual nossos pais não quiseram obedecer, antes o rejeitaram e em seu coração se tornaram ao Egito,',
        'Dizendo a Arão: Faze-nos deuses que vão adiante de nós; porque a esse Moisés, que nos tirou da terra do Egito, não sabemos o que lhe aconteceu.',
        'E naqueles dias fizeram o bezerro, e ofereceram sacrifícios ao ídolo, e se alegraram nas obras das suas mãos.',
        'Mas Deus se afastou, e os abandonou a que servissem ao exército do céu, como está escrito no livro dos profetas: Porventura me oferecestes vítimas e sacrifícios No deserto por quarenta anos, ó casa de Israel?',
        'Antes tomastes o tabernáculo de Moloque,E a estrela do vosso deus Renfã, figuras que vós fizestes para as adorar.Transportar-vos-ei, pois, para além da Babilônia.',
        'Estava entre nossos pais no deserto o tabernáculo do testemunho, como ordenara aquele que disse a Moisés que o fizesse segundo o modelo que tinha visto.',
        'O qual, nossos pais, recebendo-o também, o levaram com Josué quando entraram na posse das nações que Deus lançou para fora da presença de nossos pais, até aos dias de Davi,',
        'Que achou graça diante de Deus, e pediu que pudesse achar tabernáculo para o Deus de Jacó.',
        'E Salomão lhe edificou casa;',
        'Mas o Altíssimo não habita em templos feitos por mãos de homens, como diz o profeta:',
        'O céu é o meu trono,e a terra o estrado dos meus pés.Que casa me edificareis? diz o Senhor,Ou qual é o lugar do meu repouso?',
        'Porventura não fez a minha mão todas estas coisas?',
        'Homens de dura cerviz, e incircuncisos de coração e ouvido, vós sempre resistis ao Espírito Santo; assim vós sois como vossos pais.',
        'A qual dos profetas não perseguiram vossos pais? Até mataram os que anteriormente anunciaram a vinda do Justo, do qual vós agora fostes traidores e homicidas;',
        'Vós, que recebestes a lei por ordenação dos anjos, e não a guardastes.',
        'E, ouvindo eles isto, enfureciam-se em seus corações, e rangiam os dentes contra ele.',
        'Mas ele, estando cheio do Espírito Santo, fixando os olhos no céu, viu a glória de Deus, e Jesus, que estava à direita de Deus;',
        'E disse: Eis que vejo os céus abertos, e o Filho do homem, que está em pé à mão direita de Deus.',
        'Mas eles gritaram com grande voz, taparam os seus ouvidos, e arremeteram unânimes contra ele.',
        'E, expulsando-o da cidade, o apedrejavam. E as testemunhas depuseram as suas capas aos pés de um jovem chamado Saulo.',
        'E apedrejaram a Estêvão que em invocação dizia: Senhor Jesus, recebe o meu espírito.',
        'E, pondo-se de joelhos, clamou com grande voz: Senhor, não lhes imputes este pecado. E, tendo dito isto, adormeceu.',
      ],
      [
        'E também Saulo consentiu na morte dele. E fez-se naquele dia uma grande perseguição contra a igreja que estava em Jerusalém; e todos foram dispersos pelas terras da Judéia e de Samaria, exceto os apóstolos.',
        'E uns homens piedosos foram enterrar Estêvão, e fizeram sobre ele grande pranto.',
        'E Saulo assolava a igreja, entrando pelas casas; e, arrastando homens e mulheres, os encerrava na prisão.',
        'Mas os que andavam dispersos iam por toda a parte, anunciando a palavra.',
        'E, descendo Filipe à cidade de Samaria lhes pregava a Cristo.',
        'E as multidões unanimemente prestavam atenção ao que Filipe dizia, porque ouviam e viam os sinais que ele fazia;',
        'Pois que os espíritos imundos saíam de muitos que os tinham, clamando em alta voz; e muitos paralíticos e coxos eram curados.',
        'E havia grande alegria naquela cidade.',
        'E estava ali um certo homem, chamado Simão, que anteriormente exercera naquela cidade a arte mágica, e tinha iludido o povo de Samaria, dizendo que era uma grande personagem;',
        'Ao qual todos atendiam, desde o menor até ao maior, dizendo: Este é a grande virtude de Deus.',
        'E atendiam-no, porque já desde muito tempo os havia iludido com artes mágicas.',
        'Mas, como cressem em Filipe, que lhes pregava acerca do reino de Deus, e do nome de Jesus Cristo, se batizavam, tanto homens como mulheres.',
        'E creu até o próprio Simão; e, sendo batizado, ficou de contínuo com Filipe; e, vendo os sinais e as grandes maravilhas que se faziam, estava atônito.',
        'Os apóstolos, pois, que estavam em Jerusalém, ouvindo que Samaria recebera a palavra de Deus, enviaram para lá Pedro e João.',
        'Os quais, tendo descido, oraram por eles para que recebessem o Espírito Santo',
        '(Porque sobre nenhum deles tinha ainda descido; mas somente eram batizados em nome do Senhor Jesus).',
        'Então lhes impuseram as mãos, e receberam o Espírito Santo.',
        'E Simão, vendo que pela imposição das mãos dos apóstolos era dado o Espírito Santo, lhes ofereceu dinheiro,',
        'Dizendo: Dai-me também a mim esse poder, para que aquele sobre quem eu puser as mãos receba o Espírito Santo.',
        'Mas disse-lhe Pedro: O teu dinheiro seja contigo para perdição, pois cuidaste que o dom de Deus se alcança por dinheiro.',
        'Tu não tens parte nem sorte nesta palavra, porque o teu coração não é reto diante de Deus.',
        'Arrepende-te, pois, dessa tua iniqüidade, e ora a Deus, para que porventura te seja perdoado o pensamento do teu coração;',
        'Pois vejo que estás em fel de amargura, e em laço de iniqüidade.',
        'Respondendo, porém, Simão, disse: Orai vós por mim ao Senhor, para que nada do que dissestes venha sobre mim.',
        'Tendo eles, pois, testificado e falado a palavra do Senhor, voltaram para Jerusalém e em muitas aldeias dos samaritanos anunciaram o evangelho.',
        'E o anjo do Senhor falou a Filipe, dizendo: Levanta-te, e vai para o lado do sul, ao caminho que desce de Jerusalém para Gaza, que está deserta.',
        'E levantou-se, e foi; e eis que um homem etíope, eunuco, mordomo-mor de Candace, rainha dos etíopes, o qual era superintendente de todos os seus tesouros, e tinha ido a Jerusalém para adoração,',
        'Regressava e, assentado no seu carro, lia o profeta Isaías.',
        'E disse o Espírito a Filipe: Chega-te, e ajunta-te a esse carro.',
        'E, correndo Filipe, ouviu que lia o profeta Isaías, e disse: Entendes tu o que lês?',
        'E ele disse: Como poderei entender, se alguém não me ensinar? E rogou a Filipe que subisse e com ele se assentasse.',
        'E o lugar da Escritura que lia era este: Foi levado como a ovelha para o matadouro; e, como está mudo o cordeiro diante do que o tosquia, Assim não abriu a sua boca.',
        'Na sua humilhação foi tirado o seu julgamento; E quem contará a sua geração? Porque a sua vida é tirada da terra.',
        'E, respondendo o eunuco a Filipe, disse: Rogo-te, de quem diz isto o profeta? De si mesmo, ou de algum outro?',
        'Então Filipe, abrindo a sua boca, e começando nesta Escritura, lhe anunciou a Jesus.',
        'E, indo eles caminhando, chegaram ao pé de alguma água, e disse o eunuco: Eis aqui água; que impede que eu seja batizado?',
        'E disse Filipe: É lícito, se crês de todo o coração. E, respondendo ele, disse: Creio que Jesus Cristo é o Filho de Deus.',
        'E mandou parar o carro, e desceram ambos à água, tanto Filipe como o eunuco, e o batizou.',
        'E, quando saíram da água, o Espírito do Senhor arrebatou a Filipe, e não o viu mais o eunuco; e, jubiloso, continuou o seu caminho.',
        'E Filipe se achou em Azoto e, indo passando, anunciava o evangelho em todas as cidades, até que chegou a Cesaréia.',
      ],
      [
        'E Saulo, respirando ainda ameaças e mortes contra os discípulos do Senhor, dirigiu-se ao sumo sacerdote.',
        'E pediu-lhe cartas para Damasco, para as sinagogas, a fim de que, se encontrasse alguns deste Caminho, quer homens quer mulheres, os conduzisse presos a Jerusalém.',
        'E, indo no caminho, aconteceu que, chegando perto de Damasco, subitamente o cercou um resplendor de luz do céu.',
        'E, caindo em terra, ouviu uma voz que lhe dizia: Saulo, Saulo, por que me persegues?',
        'E ele disse: Quem és, Senhor? E disse o Senhor: Eu sou Jesus, a quem tu persegues. Duro é para ti recalcitrar contra os aguilhões.',
        'E ele, tremendo e atônito, disse: Senhor, que queres que eu faça? E disse-lhe o Senhor: Levanta-te, e entra na cidade, e lá te será dito o que te convém fazer.',
        'E os homens, que iam com ele, pararam espantados, ouvindo a voz, mas não vendo ninguém.',
        'E Saulo levantou-se da terra, e, abrindo os olhos, não via a ninguém. E, guiando-o pela mão, o conduziram a Damasco.',
        'E esteve três dias sem ver, e não comeu nem bebeu.',
        'E havia em Damasco um certo discípulo chamado Ananias; e disse-lhe o Senhor em visão: Ananias! E ele respondeu: Eis-me aqui, Senhor.',
        'E disse-lhe o Senhor: Levanta-te, e vai à rua chamada Direita, e pergunta em casa de Judas por um homem de Tarso chamado Saulo; pois eis que ele está orando;',
        'E numa visão ele viu que entrava um homem chamado Ananias, e punha sobre ele a mão, para que tornasse a ver.',
        'E respondeu Ananias: Senhor, a muitos ouvi acerca deste homem, quantos males tem feito aos teus santos em Jerusalém;',
        'E aqui tem poder dos principais dos sacerdotes para prender a todos os que invocam o teu nome.',
        'Disse-lhe, porém, o Senhor: Vai, porque este é para mim um vaso escolhido, para levar o meu nome diante dos gentios, e dos reis e dos filhos de Israel.',
        'E eu lhe mostrarei quanto deve padecer pelo meu nome.',
        'E Ananias foi, e entrou na casa e, impondo-lhe as mãos, disse: Irmão Saulo, o Senhor Jesus, que te apareceu no caminho por onde vinhas, me enviou, para que tornes a ver e sejas cheio do Espírito Santo.',
        'E logo lhe caíram dos olhos como que umas escamas, e recuperou a vista; e, levantando-se, foi batizado.',
        'E, tendo comido, ficou confortado. E esteve Saulo alguns dias com os discípulos que estavam em Damasco.',
        'E logo nas sinagogas pregava a Cristo, que este é o Filho de Deus.',
        'E todos os que o ouviam estavam atônitos, e diziam: Não é este o que em Jerusalém perseguia os que invocavam este nome, e para isso veio aqui, para os levar presos aos principais dos sacerdotes?',
        'Saulo, porém, se esforçava muito mais, e confundia os judeus que habitavam em Damasco, provando que aquele era o Cristo.',
        'E, tendo passado muitos dias, os judeus tomaram conselho entre si para o matar.',
        'Mas as suas ciladas vieram ao conhecimento de Saulo; e como eles guardavam as portas, tanto de dia como de noite, para poderem tirar-lhe a vida,',
        'Tomando-o de noite os discípulos o desceram, dentro de um cesto, pelo muro.',
        'E, quando Saulo chegou a Jerusalém, procurava ajuntar-se aos discípulos, mas todos o temiam, não crendo que fosse discípulo.',
        'Então Barnabé, tomando-o consigo, o trouxe aos apóstolos, e lhes contou como no caminho ele vira ao Senhor e lhe falara, e como em Damasco falara ousadamente no nome de Jesus.',
        'E andava com eles em Jerusalém, entrando e saindo,',
        'E falava ousadamente no nome do Senhor Jesus. Falava e disputava também contra os gregos, mas eles procuravam matá-lo.',
        'Sabendo-o, porém, os irmãos, o acompanharam até Cesaréia, e o enviaram a Tarso.',
        'Assim, pois, as igrejas em toda a Judéia, e Galiléia e Samaria tinham paz, e eram edificadas; e se multiplicavam, andando no temor do Senhor e consolação do Espírito Santo.',
        'E aconteceu que, passando Pedro por toda a parte, veio também aos santos que habitavam em Lida.',
        'E achou ali certo homem, chamado Enéias, jazendo numa cama havia oito anos, o qual era paralítico.',
        'E disse-lhe Pedro: Enéias, Jesus Cristo te dá saúde; levanta-te e faze a tua cama. E logo se levantou.',
        'E viram-no todos os que habitavam em Lida e Sarona, os quais se converteram ao Senhor.',
        'E havia em Jope uma discípula chamada Tabita, que traduzido se diz Dorcas. Esta estava cheia de boas obras e esmolas que fazia.',
        'E aconteceu naqueles dias que, enfermando ela, morreu; e, tendo-a lavado, a depositaram num quarto alto.',
        'E, como Lida era perto de Jope, ouvindo os discípulos que Pedro estava ali, lhe mandaram dois homens, rogando-lhe que não se demorasse em vir ter com eles.',
        'E, levantando-se Pedro, foi com eles; e quando chegou o levaram ao quarto alto, e todas as viúvas o rodearam, chorando e mostrando as túnicas e roupas que Dorcas fizera quando estava com elas.',
        'Mas Pedro, fazendo sair a todos, pôs-se de joelhos e orou: e, voltando-se para o corpo, disse: Tabita, levanta-te. E ela abriu os olhos, e, vendo a Pedro, assentou-se.',
        'E ele, dando-lhe a mão, a levantou e, chamando os santos e as viúvas, apresentou-lha viva.',
        'E foi isto notório por toda a Jope, e muitos creram no Senhor.',
        'E ficou muitos dias em Jope, com um certo Simão curtidor.',
      ],
      [
        'E havia em Cesaréia um homem por nome Cornélio, centurião da coorte chamada italiana,',
        'Piedoso e temente a Deus, com toda a sua casa, o qual fazia muitas esmolas ao povo, e de contínuo orava a Deus.',
        'Este, quase à hora nona do dia, viu claramente numa visão um anjo de Deus, que se dirigia para ele e dizia: Cornélio.',
        'O qual, fixando os olhos nele, e muito atemorizado, disse: Que é, Senhor? E disse-lhe: As tuas orações e as tuas esmolas têm subido para memória diante de Deus;',
        'Agora, pois, envia homens a Jope, e manda chamar a Simão, que tem por sobrenome Pedro.',
        'Este está hospedado com um certo Simão curtidor, que tem a sua casa junto do mar. Ele te dirá o que deves fazer.',
        'E, retirando-se o anjo que lhe falava, chamou dois dos seus criados, e a um piedoso soldado dos que estavam ao seu serviço.',
        'E, havendo-lhes contado tudo, os enviou a Jope.',
        'E no dia seguinte, indo eles seu caminho, e estando já perto da cidade, subiu Pedro ao terraço para orar, quase à hora sexta.',
        'E tendo fome, quis comer; e, enquanto lho preparavam, sobreveio-lhe um arrebatamento de sentidos,',
        'E viu o céu aberto, e que descia um vaso, como se fosse um grande lençol atado pelas quatro pontas, e vindo para a terra.',
        'No qual havia de todos os animais quadrúpedes e feras e répteis da terra, e aves do céu.',
        'E foi-lhe dirigida uma voz: Levanta-te, Pedro, mata e come.',
        'Mas Pedro disse: De modo nenhum, Senhor, porque nunca comi coisa alguma comum e imunda.',
        'E segunda vez lhe disse a voz: Não faças tu comum ao que Deus purificou.',
        'E aconteceu isto por três vezes; e o vaso tornou a recolher-se ao céu.',
        'E estando Pedro duvidando entre si acerca do que seria aquela visão que tinha visto, eis que os homens que foram enviados por Cornélio pararam à porta, perguntando pela casa de Simão.',
        'E, chamando, perguntaram se Simão, que tinha por sobrenome Pedro, morava ali.',
        'E, pensando Pedro naquela visão, disse-lhe o Espírito: Eis que três homens te buscam.',
        'Levanta-te pois, desce, e vai com eles, não duvidando; porque eu os enviei.',
        'E, descendo Pedro para junto dos homens que lhe foram enviados por Cornélio, disse: Eis que sou eu a quem procurais; qual é a causa por que estais aqui?',
        'E eles disseram: Cornélio, o centurião, homem justo e temente a Deus, e que tem bom testemunho de toda a nação dos judeus, foi avisado por um santo anjo para que te chamasse a sua casa, e ouvisse as tuas palavras.',
        'Então, chamando-os para dentro, os recebeu em casa. E no dia seguinte foi Pedro com eles, e foram com ele alguns irmãos de Jope.',
        'E no dia imediato chegaram a Cesaréia. E Cornélio os estava esperando, tendo já convidado os seus parentes e amigos mais íntimos.',
        'E aconteceu que, entrando Pedro, saiu Cornélio a recebê-lo, e, prostrando-se a seus pés o adorou.',
        'Mas Pedro o levantou, dizendo: Levanta-te, que eu também sou homem.',
        'E, falando com ele, entrou, e achou muitos que ali se haviam ajuntado.',
        'E disse-lhes: Vós bem sabeis que não é lícito a um homem judeu ajuntar-se ou chegar-se a estrangeiros; mas Deus mostrou-me que a nenhum homem chame comum ou imundo.',
        'Por isso, sendo chamado, vim sem contradizer. Pergunto, pois, por que razão mandastes chamar-me?',
        'E disse Cornélio: Há quatro dias estava eu em jejum até esta hora, orando em minha casa à hora nona.',
        'E eis que diante de mim se apresentou um homem com vestes resplandecentes, e disse: Cornélio, a tua oração foi ouvida, e as tuas esmolas estão em memória diante de Deus.',
        'Envia, pois, a Jope, e manda chamar Simão, o que tem por sobrenome Pedro; este está hospedado em casa de Simão o curtidor, junto do mar, e ele, vindo, te falará.',
        'E logo mandei chamar-te, e bem fizeste em vir. Agora, pois, estamos todos presentes diante de Deus, para ouvir tudo quanto por Deus te é mandado.',
        'E, abrindo Pedro a boca, disse: Reconheço por verdade que Deus não faz acepção de pessoas;',
        'Mas que lhe é agradável aquele que, em qualquer nação, o teme e faz o que é justo.',
        'A palavra que ele enviou aos filhos de Israel, anunciando a paz por Jesus Cristo (este é o Senhor de todos);',
        'Esta palavra, vós bem sabeis, veio por toda a Judéia, começando pela Galiléia, depois do batismo que João pregou;',
        'Como Deus ungiu a Jesus de Nazaré com o Espírito Santo e com virtude; o qual andou fazendo bem, e curando a todos os oprimidos do diabo, porque Deus era com ele.',
        'E nós somos testemunhas de todas as coisas que fez, tanto na terra da Judéia como em Jerusalém; ao qual mataram, pendurando-o num madeiro.',
        'A este ressuscitou Deus ao terceiro dia, e fez que se manifestasse,',
        'Não a todo o povo, mas às testemunhas que Deus antes ordenara; a nós, que comemos e bebemos juntamente com ele, depois que ressuscitou dentre os mortos.',
        'E nos mandou pregar ao povo, e testificar que ele é o que por Deus foi constituído juiz dos vivos e dos mortos.',
        'A este dão testemunho todos os profetas, de que todos os que nele crêem receberão o perdão dos pecados pelo seu nome.',
        'E, dizendo Pedro ainda estas palavras, caiu o Espírito Santo sobre todos os que ouviam a palavra.',
        'E os fiéis que eram da circuncisão, todos quantos tinham vindo com Pedro, maravilharam-se de que o dom do Espírito Santo se derramasse também sobre os gentios.',
        'Porque os ouviam falar línguas, e magnificar a Deus.',
        'Respondeu, então, Pedro: Pode alguém porventura recusar a água, para que não sejam batizados estes, que também receberam como nós o Espírito Santo?',
        'E mandou que fossem batizados em nome do Senhor. Então rogaram-lhe que ficasse com eles por alguns dias.',
      ],
      [
        'E ouviram os apóstolos, e os irmãos que estavam na Judéia, que também os gentios tinham recebido a palavra de Deus.',
        'E, subindo Pedro a Jerusalém, disputavam com ele os que eram da circuncisão,',
        'Dizendo: Entraste em casa de homens incircuncisos, e comeste com eles.',
        'Mas Pedro começou a fazer-lhes uma exposição por ordem, dizendo:',
        'Estando eu orando na cidade de Jope, tive, num arrebatamento dos sentidos, uma visão; via um vaso, como um grande lençol que descia do céu e vinha até junto de mim.',
        'E, pondo nele os olhos, considerei, e vi animais da terra, quadrúpedes, e feras, e répteis e aves do céu.',
        'E ouvi uma voz que me dizia: Levanta-te, Pedro; mata e come.',
        'Mas eu disse: De maneira nenhuma, Senhor; pois, nunca em minha boca entrou coisa alguma comum ou imunda.',
        'Mas a voz respondeu-me do céu segunda vez: Não chames tu comum ao que Deus purificou.',
        'E sucedeu isto por três vezes; e tudo tornou a recolher-se ao céu.',
        'E eis que, na mesma hora, pararam, junto da casa em que eu estava, três homens que me foram enviados de Cesaréia.',
        'E disse-me o Espírito que fosse com eles, nada duvidando; e também estes seis irmãos foram comigo, e entramos em casa daquele homem;',
        'E contou-nos como vira em pé um anjo em sua casa, e lhe dissera: Envia homens a Jope, e manda chamar a Simão, que tem por sobrenome Pedro,',
        'O qual te dirá palavras com que te salves, tu e toda a tua casa.',
        'E, quando comecei a falar, caiu sobre eles o Espírito Santo, como também sobre nós ao princípio.',
        'E lembrei-me do dito do Senhor, quando disse: João certamente batizou com água; mas vós sereis batizados com o Espírito Santo.',
        'Portanto, se Deus lhes deu o mesmo dom que a nós, quando havemos crido no Senhor Jesus Cristo, quem era então eu, para que pudesse resistir a Deus?',
        'E, ouvindo estas coisas, apaziguaram-se, e glorificaram a Deus, dizendo: Na verdade até aos gentios deu Deus o arrependimento para a vida.',
        'E os que foram dispersos pela perseguição que sucedeu por causa de Estêvão caminharam até à Fenícia, Chipre e Antioquia, não anunciando a ninguém a palavra, senão somente aos judeus.',
        'E havia entre eles alguns homens chíprios e cirenenses, os quais entrando em Antioquia falaram aos gregos, anunciando o Senhor Jesus.',
        'E a mão do Senhor era com eles; e grande número creu e se converteu ao Senhor.',
        'E chegou a fama destas coisas aos ouvidos da igreja que estava em Jerusalém; e enviaram Barnabé a Antioquia.',
        'O qual, quando chegou, e viu a graça de Deus, se alegrou, e exortou a todos a que permanecessem no Senhor, com propósito de coração;',
        'Porque era homem de bem e cheio do Espírito Santo e de fé. E muita gente se uniu ao Senhor.',
        'E partiu Barnabé para Tarso, a buscar Saulo; e, achando-o, o conduziu para Antioquia.',
        'E sucedeu que todo um ano se reuniram naquela igreja, e ensinaram muita gente; e em Antioquia foram os discípulos, pela primeira vez, chamados cristãos.',
        'E naqueles dias desceram profetas de Jerusalém para Antioquia.',
        'E, levantando-se um deles, por nome Ágabo, dava a entender pelo Espírito, que haveria uma grande fome em todo o mundo, e isso aconteceu no tempo de Cláudio César.',
        'E os discípulos determinaram mandar, cada um conforme o que pudesse, socorro aos irmãos que habitavam na Judéia.',
        'O que eles com efeito fizeram, enviando-o aos anciãos por mão de Barnabé e de Saulo.',
      ],
      [
        'E por aquele mesmo tempo o rei Herodes estendeu as mãos sobre alguns da igreja, para os maltratar;',
        'E matou à espada Tiago, irmão de João.',
        'E, vendo que isso agradara aos judeus, continuou, mandando prender também a Pedro. E eram os dias dos ázimos.',
        'E, havendo-o prendido, o encerrou na prisão, entregando-o a quatro quaternos de soldados, para que o guardassem, querendo apresentá-lo ao povo depois da páscoa.',
        'Pedro, pois, era guardado na prisão; mas a igreja fazia contínua oração por ele a Deus.',
        'E quando Herodes estava para o fazer comparecer, nessa mesma noite estava Pedro dormindo entre dois soldados, ligado com duas cadeias, e os guardas diante da porta guardavam a prisão.',
        'E eis que sobreveio o anjo do Senhor, e resplandeceu uma luz na prisão; e, tocando a Pedro na ilharga, o despertou, dizendo: Levanta-te depressa. E caíram-lhe das mãos as cadeias.',
        'E disse-lhe o anjo: Cinge-te, e ata as tuas alparcas. E ele assim o fez. Disse-lhe mais: Lança às costas a tua capa, e segue-me.',
        'E, saindo, o seguia. E não sabia que era real o que estava sendo feito pelo anjo, mas cuidava que via alguma visão.',
        'E, quando passaram a primeira e segunda guarda, chegaram à porta de ferro, que dá para a cidade, a qual se lhes abriu por si mesma; e, tendo saído, percorreram uma rua, e logo o anjo se apartou dele.',
        'E Pedro, tornando a si, disse: Agora sei verdadeiramente que o Senhor enviou o seu anjo, e me livrou da mão de Herodes, e de tudo o que o povo dos judeus esperava.',
        'E, considerando ele nisto, foi à casa de Maria, mãe de João, que tinha por sobrenome Marcos, onde muitos estavam reunidos e oravam.',
        'E, batendo Pedro à porta do pátio, uma menina chamada Rode saiu a escutar;',
        'E, conhecendo a voz de Pedro, de gozo não abriu a porta, mas, correndo para dentro, anunciou que Pedro estava à porta.',
        'E disseram-lhe: Estás fora de ti. Mas ela afirmava que assim era. E diziam: É o seu anjo.',
        'Mas Pedro perseverava em bater e, quando abriram, viram-no, e se espantaram.',
        'E acenando-lhes ele com a mão para que se calassem, contou-lhes como o Senhor o tirara da prisão, e disse: Anunciai isto a Tiago e aos irmãos. E, saindo, partiu para outro lugar.',
        'E, sendo já dia, houve não pouco alvoroço entre os soldados sobre o que seria feito de Pedro.',
        'E, quando Herodes o procurou e o não achou, feita inquirição aos guardas, mandou-os justiçar. E, partindo da Judéia para Cesaréia, ficou ali.',
        'E ele estava irritado com os de Tiro e de Sidom; mas estes, vindo de comum acordo ter com ele, e obtendo a amizade de Blasto, que era o camarista do rei, pediam paz; porquanto o seu país se abastecia do país do rei.',
        'E num dia designado, vestindo Herodes as vestes reais, estava assentado no tribunal e lhes fez uma prática.',
        'E o povo exclamava: Voz de Deus, e não de homem.',
        'E no mesmo instante feriu-o o anjo do Senhor, porque não deu glória a Deus e, comido de bichos, expirou.',
        'E a palavra de Deus crescia e se multiplicava.',
        'E Barnabé e Saulo, havendo terminado aquele serviço, voltaram de Jerusalém, levando também consigo a João, que tinha por sobrenome Marcos.',
      ],
      [
        'E na igreja que estava em Antioquia havia alguns profetas e doutores, a saber: Barnabé e Simeão chamado Níger, e Lúcio, cireneu, e Manaém, que fora criado com Herodes o tetrarca, e Saulo.',
        'E, servindo eles ao Senhor, e jejuando, disse o Espírito Santo: Apartai-me a Barnabé e a Saulo para a obra a que os tenho chamado.',
        'Então, jejuando e orando, e pondo sobre eles as mãos, os despediram.',
        'E assim estes, enviados pelo Espírito Santo, desceram a Selêucia e dali navegaram para Chipre.',
        'E, chegados a Salamina, anunciavam a palavra de Deus nas sinagogas dos judeus; e tinham também a João como cooperador.',
        'E, havendo atravessado a ilha até Pafos, acharam um certo judeu mágico, falso profeta, chamado Barjesus,',
        'O qual estava com o procônsul Sérgio Paulo, homem prudente. Este, chamando a si Barnabé e Saulo, procurava muito ouvir a palavra de Deus.',
        'Mas resistia-lhes Elimas, o encantador (porque assim se interpreta o seu nome), procurando apartar da fé o procônsul.',
        'Todavia Saulo, que também se chama Paulo, cheio do Espírito Santo, e fixando os olhos nele,',
        'Disse: Ó filho do diabo, cheio de todo o engano e de toda a malícia, inimigo de toda a justiça, não cessarás de perturbar os retos caminhos do Senhor?',
        'Eis aí, pois, agora contra ti a mão do Senhor, e ficarás cego, sem ver o sol por algum tempo. E no mesmo instante a escuridão e as trevas caíram sobre ele e, andando à roda, buscava a quem o guiasse pela mão.',
        'Então o procônsul, vendo o que havia acontecido, creu, maravilhado da doutrina do Senhor.',
        'E, partindo de Pafos, Paulo e os que estavam com ele chegaram a Perge, da Panfília. Mas João, apartando-se deles, voltou para Jerusalém.',
        'E eles, saindo de Perge, chegaram a Antioquia, da Pisídia, e, entrando na sinagoga, num dia de sábado, assentaram-se;',
        'E, depois da lição da lei e dos profetas, lhes mandaram dizer os principais da sinagoga: Homens irmãos, se tendes alguma palavra de consolação para o povo, falai.',
        'E, levantando-se Paulo, e pedindo silêncio com a mão, disse: Homens israelitas, e os que temeis a Deus, ouvi:',
        'O Deus deste povo de Israel escolheu a nossos pais, e exaltou o povo, sendo eles estrangeiros na terra do Egito; e com braço poderoso os tirou dela;',
        'E suportou os seus costumes no deserto por espaço de quase quarenta anos.',
        'E, destruindo a sete nações na terra de Canaã, deu-lhes por sorte a terra deles.',
        'E, depois disto, por quase quatrocentos e cinqüenta anos, lhes deu juízes, até ao profeta Samuel.',
        'E depois pediram um rei, e Deus lhes deu por quarenta anos, a Saul filho de Cis, homem da tribo de Benjamim.',
        'E, quando este foi retirado, levantou-lhes como rei a Davi, ao qual também deu testemunho, e disse: Achei a Davi, filho de Jessé, homem conforme o meu coração, que executará toda a minha vontade.',
        'Da descendência deste, conforme a promessa, levantou Deus a Jesus para Salvador de Israel;',
        'Tendo primeiramente João, antes da vinda dele, pregado a todo o povo de Israel o batismo de arrependimento.',
        'Mas João, quando completava a carreira, disse: Quem pensais vós que eu sou? Eu não sou o Cristo; mas eis que após mim vem aquele a quem não sou digno de desatar as alparcas dos pés.',
        'Homens irmãos, filhos da geração de Abraão, e os que dentre vós temem a Deus, a vós vos é enviada a palavra desta salvação.',
        'Por não terem conhecido a este, os que habitavam em Jerusalém, e os seus príncipes, condenaram-no, cumprindo assim as vozes dos profetas que se lêem todos os sábados.',
        'E, embora não achassem alguma causa de morte, pediram a Pilatos que ele fosse morto.',
        'E, havendo eles cumprido todas as coisas que dele estavam escritas, tirando-o do madeiro, o puseram na sepultura;',
        'Mas Deus o ressuscitou dentre os mortos.',
        'E ele por muitos dias foi visto pelos que subiram com ele da Galiléia a Jerusalém, e são suas testemunhas para com o povo.',
        'E nós vos anunciamos que a promessa que foi feita aos pais, Deus a cumpriu a nós, seus filhos, ressuscitando a Jesus;',
        'Como também está escrito no salmo segundo: Meu filho és tu, hoje te gerei.',
        'E que o ressuscitaria dentre os mortos, para nunca mais tornar à corrupção, disse-o assim: As santas e fiéis bênçãos de Davi vos darei.',
        'Por isso também em outro salmo diz: Não permitirás que o teu santo veja corrupção.',
        'Porque, na verdade, tendo Davi no seu tempo servido conforme a vontade de Deus, dormiu, foi posto junto de seus pais e viu a corrupção.',
        'Mas aquele a quem Deus ressuscitou nenhuma corrupção viu.',
        'Seja-vos, pois, notório, homens irmàos, que por este se vos anuncia a remissão dos pecados.',
        'E de tudo o que, pela lei de Moisés, não pudestes ser justificados, por ele é justificado todo aquele que crê.',
        'Vede, pois, que não venha sobre vós o que está dito nos profetas:',
        'Vede, ó desprezadores, e espantai-vos e desaparecei;porque opero uma obra em vossos dias,ora tal que não crereis, se alguém vo-la contar.',
        'E, saídos os judeus da sinagoga, os gentios rogaram que no sábado seguinte lhes fossem ditas as mesmas coisas.',
        'E, despedida a sinagoga, muitos dos judeus e dos prosélitos religiosos seguiram Paulo e Barnabé; os quais, falando-lhes, os exortavam a que permanecessem na graça de Deus.',
        'E no sábado seguinte ajuntou-se quase toda a cidade para ouvir a palavra de Deus.',
        'Então os judeus, vendo a multidão, encheram-se de inveja e, blasfemando, contradiziam o que Paulo falava.',
        'Mas Paulo e Barnabé, usando de ousadia, disseram: Era mister que a vós se vos pregasse primeiro a palavra de Deus; mas, visto que a rejeitais, e não vos julgais dignos da vida eterna, eis que nos voltamos para os gentios;',
        'Porque o Senhor assim no-lo mandou:eu te pus para luz dos gentios,a fim de que sejas para salvação até os confins da terra.',
        'E os gentios, ouvindo isto, alegraram-se, e glorificavam a palavra do Senhor; e creram todos quantos estavam ordenados para a vida eterna.',
        'E a palavra do Senhor se divulgava por toda aquela província.',
        'Mas os judeus incitaram algumas mulheres religiosas e honestas, e os principais da cidade, e levantaram perseguição contra Paulo e Barnabé, e os lançaram fora dos seus termos.',
        'Sacudindo, porém, contra eles o pó dos seus pés, partiram para Icônio.',
        'E os discípulos estavam cheios de alegria e do Espírito Santo.',
      ],
      [
        'E aconteceu que em Icônio entraram juntos na sinagoga dos judeus, e falaram de tal modo que creu uma grande multidão, não só de judeus mas de gregos.',
        'Mas os judeus incrédulos incitaram e irritaram, contra os irmãos, os ânimos dos gentios.',
        'Detiveram-se, pois, muito tempo, falando ousadamente acerca do Senhor, o qual dava testemunho à palavra da sua graça, permitindo que por suas mãos se fizessem sinais e prodígios.',
        'E dividiu-se a multidão da cidade; e uns eram pelos judeus, e outros pelos apóstolos.',
        'E havendo um motim, tanto dos judeus como dos gentios, com os seus principais, para os insultarem e apedrejarem,',
        'Sabendo-o eles, fugiram para Listra e Derbe, cidades de Licaônia, e para a província circunvizinha;',
        'E ali pregavam o evangelho.',
        'E estava assentado em Listra certo homem leso dos pés, coxo desde o ventre de sua mãe, o qual nunca tinha andado.',
        'Este ouviu falar Paulo, que, fixando nele os olhos, e vendo que tinha fé para ser curado,',
        'Disse em voz alta: Levanta-te direito sobre teus pés. E ele saltou e andou.',
        'E as multidões, vendo o que Paulo fizera, levantaram a sua voz, dizendo em língua licaônica: Fizeram-se os deuses semelhantes aos homens, e desceram até nós.',
        'E chamavam Júpiter a Barnabé, e Mercúrio a Paulo; porque este era o que falava.',
        'E o sacerdote de Júpiter, cujo templo estava em frente da cidade, trazendo para a entrada da porta touros e grinaldas, queria com a multidão sacrificar-lhes.',
        'Ouvindo, porém, isto os apóstolos Barnabé e Paulo, rasgaram as suas vestes, e saltaram para o meio da multidão, clamando,',
        'E dizendo: Senhores, por que fazeis essas coisas? Nós também somos homens como vós, sujeitos às mesmas paixões, e vos anunciamos que vos convertais dessas vaidades ao Deus vivo, que fez o céu, e a terra, o mar, e tudo quanto há neles;',
        'O qual nos tempos passados deixou andar todas as nações em seus próprios caminhos.',
        'E contudo, não se deixou a si mesmo sem testemunho, beneficiando-vos lá do céu, dando-vos chuvas e tempos frutíferos, enchendo de mantimento e de alegria os vossos corações.',
        'E, dizendo isto, com dificuldade impediram que as multidões lhes sacrificassem.',
        'Sobrevieram, porém, uns judeus de Antioquia e de Icônio que, tendo convencido a multidão, apedrejaram a Paulo e o arrastaram para fora da cidade, cuidando que estava morto.',
        'Mas, rodeando-o os discípulos, levantou-se, e entrou na cidade, e no dia seguinte saiu com Barnabé para Derbe.',
        'E, tendo anunciado o evangelho naquela cidade e feito muitos discípulos, voltaram para Listra, e Icônio e Antioquia,',
        'Confirmando os ânimos dos discípulos, exortando-os a permanecer na fé, pois que por muitas tribulações nos importa entrar no reino de Deus.',
        'E, havendo-lhes, por comum consentimento, eleito anciãos em cada igreja, orando com jejuns, os encomendaram ao Senhor em quem haviam crido.',
        'Passando depois por Pisídia, dirigiram-se a Panfília.',
        'E, tendo anunciado a palavra em Perge, desceram a Atália.',
        'E dali navegaram para Antioquia, de onde tinham sido encomendados à graça de Deus para a obra que já haviam cumprido.',
        'E, quando chegaram e reuniram a igreja, relataram quão grandes coisas Deus fizera por eles, e como abrira aos gentios a porta da fé.',
        'E ficaram ali não pouco tempo com os discípulos.',
      ],
      [
        'Então alguns que tinham descido da Judéia ensinavam assim os irmãos: Se não vos circuncidardes conforme o uso de Moisés, não podeis salvar-vos.',
        'Tendo tido Paulo e Barnabé não pequena discussão e contenda contra eles, resolveu-se que Paulo e Barnabé, e alguns dentre eles, subissem a Jerusalém, aos apóstolos e aos anciãos, sobre aquela questão.',
        'E eles, sendo acompanhados pela igreja, passavam pela Fenícia e por Samaria, contando a conversão dos gentios; e davam grande alegria a todos os irmãos.',
        'E, quando chegaram a Jerusalém, foram recebidos pela igreja e pelos apóstolos e anciãos, e lhes anunciaram quão grandes coisas Deus tinha feito com eles.',
        'Alguns, porém, da seita dos fariseus, que tinham crido, se levantaram, dizendo que era mister circuncidá-los e mandar-lhes que guardassem a lei de Moisés.',
        'Congregaram-se, pois, os apóstolos e os anciãos para considerar este assunto.',
        'E, havendo grande contenda, levantou-se Pedro e disse-lhes: Homens irmãos, bem sabeis que já há muito tempo Deus me elegeu dentre nós, para que os gentios ouvissem da minha boca a palavra do evangelho, e cressem.',
        'E Deus, que conhece os corações, lhes deu testemunho, dando-lhes o Espírito Santo, assim como também a nós;',
        'E não fez diferença alguma entre eles e nós, purificando os seus corações pela fé.',
        'Agora, pois, por que tentais a Deus, pondo sobre a cerviz dos discípulos um jugo que nem nossos pais nem nós pudemos suportar?',
        'Mas cremos que seremos salvos pela graça do Senhor Jesus Cristo, como eles também.',
        'Então toda a multidão se calou e escutava a Barnabé e a Paulo, que contavam quão grandes sinais e prodígios Deus havia feito por meio deles entre os gentios.',
        'E, havendo-se eles calado, tomou Tiago a palavra, dizendo: Homens irmãos, ouvi-me:',
        'Simão relatou como primeiramente Deus visitou os gentios, para tomar deles um povo para o seu nome.',
        'E com isto concordam as palavras dos profetas; como está escrito:',
        'Depois disto voltarei,e reedificarei o tabernáculo de Davi, que está caído, levantá-lo-ei das suas ruínas, e tornarei a edificá-lo.',
        'Para que o restante dos homens busque ao Senhor,e todos os gentios, sobre os quais o meu nome é invocado,diz o Senhor, que faz todas estas coisas,',
        'Conhecidas sào a Deus, desde o princípio do mundo, todas as suas obras.',
        'Por isso julgo que não se deve perturbar aqueles, dentre os gentios, que se convertem a Deus.',
        'Mas escrever-lhes que se abstenham das contaminações dos ídolos, da fornicação, do que é sufocado e do sangue.',
        'Porque Moisés, desde os tempos antigos, tem em cada cidade quem o pregue, e cada sábado é lido nas sinagogas.',
        'Então pareceu bem aos apóstolos e aos anciãos, com toda a igreja, eleger homens dentre eles e enviá-los com Paulo e Barnabé a Antioquia, a saber: Judas, chamado Barsabás, e Silas, homens distintos entre os irmãos.',
        'E por intermédio deles escreveram o seguinte: Os apóstolos, e os anciãos e os irmãos, aos irmãos dentre os gentios que estão em Antioquia, e Síria e Cilícia, saúde.',
        'Porquanto ouvimos que alguns que saíram dentre nós vos perturbaram com palavras, e transtornaram as vossas almas, dizendo que deveis circuncidar-vos e guardar a lei, não lhes tendo nós dado mandamento,',
        'Pareceu-nos bem, reunidos concordemente, eleger alguns homens e enviá-los com os nossos amados Barnabé e Paulo,',
        'Homens que já expuseram as suas vidas pelo nome de nosso Senhor Jesus Cristo.',
        'Enviamos, portanto, Judas e Silas, os quais por palavra vos anunciarão também as mesmas coisas.',
        'Na verdade pareceu bem ao Espírito Santo e a nós, não vos impor mais encargo algum, senão estas coisas necessárias:',
        'Que vos abstenhais das coisas sacrificadas aos ídolos, e do sangue, e da carne sufocada, e da fornicação, das quais coisas bem fazeis se vos guardardes. Bem vos vá.',
        'Tendo eles então se despedido, partiram para Antioquia e, ajuntando a multidão, entregaram a carta.',
        'E, quando a leram, alegraram-se pela exortação.',
        'Depois Judas e Silas, que também eram profetas, exortaram e confirmaram os irmãos com muitas palavras.',
        'E, detendo-se ali algum tempo, os irmãos os deixaram voltar em paz para os apóstolos;',
        'Mas pareceu bem a Silas ficar ali.',
        'E Paulo e Barnabé ficaram em Antioquia, ensinando e pregando, com muitos outros, a palavra do Senhor.',
        'E alguns dias depois, disse Paulo a Barnabé: Tornemos a visitar nossos irmãos por todas as cidades em que já anunciamos a palavra do Senhor, para ver como estão.',
        'E Barnabé aconselhava que tomassem consigo a João, chamado Marcos.',
        'Mas a Paulo parecia razoável que não tomassem consigo aquele que desde a Panfília se tinha apartado deles e não os acompanhou naquela obra.',
        'E tal contenda houve entre eles, que se apartaram um do outro. Barnabé, levando consigo a Marcos, navegou para Chipre.',
        'E Paulo, tendo escolhido a Silas, partiu, encomendado pelos irmãos à graça de Deus.',
        'E passou pela Síria e Cilícia, confirmando as igrejas.',
      ],
      [
        'E chegou a Derbe e Listra. E eis que estava ali um certo discípulo por nome Timóteo, filho de uma judia que era crente, mas de pai grego;',
        'Do qual davam bom testemunho os irmãos que estavam em Listra e em Icônio.',
        'Paulo quis que este fosse com ele; e tomando-o, o circuncidou, por causa dos judeus que estavam naqueles lugares; porque todos sabiam que seu pai era grego.',
        'E, quando iam passando pelas cidades, lhes entregavam, para serem observados, os decretos que haviam sido estabelecidos pelos apóstolos e anciãos em Jerusalém.',
        'De sorte que as igrejas eram confirmadas na fé, e cada dia cresciam em número.',
        'E, passando pela Frígia e pela província da Galácia, foram impedidos pelo Espírito Santo de anunciar a palavra na Ásia.',
        'E, quando chegaram a Mísia, intentavam ir para Bitínia, mas o Espírito não lho permitiu.',
        'E, tendo passado por Mísia, desceram a Trôade.',
        'E Paulo teve de noite uma visão, em que se apresentou um homem da Macedônia, e lhe rogou, dizendo: Passa à Macedônia, e ajuda-nos.',
        'E, logo depois desta visão, procuramos partir para a Macedônia, concluindo que o Senhor nos chamava para lhes anunciarmos o evangelho.',
        'E, navegando de Trôade, fomos correndo em caminho direito para a Samotrácia e, no dia seguinte, para Neápolis;',
        'E dali para Filipos, que é a primeira cidade desta parte da Macedônia, e é uma colônia; e estivemos alguns dias nesta cidade.',
        'E no dia de sábado saímos fora das portas, para a beira do rio, onde se costumava fazer oração; e, assentando-nos, falamos às mulheres que ali se ajuntaram.',
        'E uma certa mulher, chamada Lídia, vendedora de púrpura, da cidade de Tiatira, e que servia a Deus, nos ouvia, e o Senhor lhe abriu o coração para que estivesse atenta ao que Paulo dizia.',
        'E, depois que foi batizada, ela e a sua casa, nos rogou, dizendo: Se haveis julgado que eu seja fiel ao Senhor, entrai em minha casa, e ficai ali. E nos constrangeu a isso.',
        'E aconteceu que, indo nós à oração, nos saiu ao encontro uma jovem, que tinha espírito de adivinhação, a qual, adivinhando, dava grande lucro aos seus senhores.',
        'Esta, seguindo a Paulo e a nós, clamava, dizendo: Estes homens, que nos anunciam o caminho da salvação, são servos do Deus Altíssimo.',
        'E isto fez ela por muitos dias. Mas Paulo, perturbado, voltou-se e disse ao espírito: Em nome de Jesus Cristo, te mando que saias dela. E na mesma hora saiu.',
        'E, vendo seus senhores que a esperança do seu lucro estava perdida, prenderam Paulo e Silas, e os levaram à praça, à presença dos magistrados.',
        'E, apresentando-os aos magistrados, disseram: Estes homens, sendo judeus, perturbaram a nossa cidade,',
        'E nos expõem costumes que não nos é lícito receber nem praticar, visto que somos romanos.',
        'E a multidão se levantou unida contra eles, e os magistrados, rasgando-lhes as vestes, mandaram açoitá-los com varas.',
        'E, havendo-lhes dado muitos açoites, os lançaram na prisão, mandando ao carcereiro que os guardasse com segurança.',
        'O qual, tendo recebido tal ordem, os lançou no cárcere interior, e lhes segurou os pés no tronco.',
        'E, perto da meia-noite, Paulo e Silas oravam e cantavam hinos a Deus, e os outros presos os escutavam.',
        'E de repente sobreveio um tão grande terremoto, que os alicerces do cárcere se moveram, e logo se abriram todas as portas, e foram soltas as prisões de todos.',
        'E, acordando o carcereiro, e vendo abertas as portas da prisão, tirou a espada, e quis matar-se, cuidando que os presos já tinham fugido.',
        'Mas Paulo clamou com grande voz, dizendo: Não te faças nenhum mal, que todos aqui estamos.',
        'E, pedindo luz, saltou dentro e, todo trêmulo, se prostrou ante Paulo e Silas.',
        'E, tirando-os para fora, disse: Senhores, que é necessário que eu faça para me salvar?',
        'E eles disseram: Crê no Senhor Jesus Cristo e serás salvo, tu e a tua casa.',
        'E lhe pregavam a palavra do Senhor, e a todos os que estavam em sua casa.',
        'E, tomando-os ele consigo naquela mesma hora da noite, lavou-lhes os vergões; e logo foi batizado, ele e todos os seus.',
        'E, levando-os à sua casa, lhes pôs a mesa; e, na sua crença em Deus, alegrou-se com toda a sua casa.',
        'E, sendo já dia, os magistrados mandaram quadrilheiros, dizendo: Soltai aqueles homens.',
        'E o carcereiro anunciou a Paulo estas palavras, dizendo: Os magistrados mandaram que vos soltasse; agora, pois, saí e ide em paz.',
        'Mas Paulo replicou: Açoitaram-nos publicamente e, sem sermos condenados, sendo homens romanos, nos lançaram na prisão, e agora encobertamente nos lançam fora? Não será assim; mas venham eles mesmos e tirem-nos para fora.',
        'E os quadrilheiros foram dizer aos magistrados estas palavras; e eles temeram, ouvindo que eram romanos.',
        'E, vindo, lhes dirigiram súplicas; e, tirando-os para fora, lhes pediram que saíssem da cidade.',
        'E, saindo da prisão, entraram em casa de Lídia e, vendo os irmãos, os confortaram, e depois partiram.',
      ],
      [
        'E passando por Anfípolis e Apolônia, chegaram a Tessalônica, onde havia uma sinagoga de judeus.',
        'E Paulo, como tinha por costume, foi ter com eles; e por três sábados disputou com eles sobre as Escrituras,',
        'Expondo e demonstrando que convinha que o Cristo padecesse e ressuscitasse dentre os mortos. E este Jesus, que vos anuncio, dizia ele, é o Cristo.',
        'E alguns deles creram, e ajuntaram-se com Paulo e Silas; e também uma grande multidão de gregos religiosos, e não poucas mulheres principais.',
        'Mas os judeus desobedientes, movidos de inveja, tomaram consigo alguns homens perversos, dentre os vadios e, ajuntando o povo, alvoroçaram a cidade, e assaltando a casa de Jasom, procuravam trazê-los para junto do povo.',
        'E, não os achando, trouxeram Jasom e alguns irmãos à presença dos magistrados da cidade, clamando: Estes que têm alvoroçado o mundo, chegaram também aqui;',
        'Os quais Jasom recolheu; e todos estes procedem contra os decretos de César, dizendo que há outro rei, Jesus.',
        'E alvoroçaram a multidão e os principais da cidade, que ouviram estas coisas.',
        'Tendo, porém, recebido satisfação de Jasom e dos demais, os soltaram.',
        'E logo os irmãos enviaram de noite Paulo e Silas a Beréia; e eles, chegando lá, foram à sinagoga dos judeus.',
        'Ora, estes foram mais nobres do que os que estavam em Tessalônica, porque de bom grado receberam a palavra, examinando cada dia nas Escrituras se estas coisas eram assim.',
        'De sorte que creram muitos deles, e também mulheres gregas da classe nobre, e não poucos homens.',
        'Mas, logo que os judeus de Tessalônica souberam que a palavra de Deus também era anunciada por Paulo em Beréia, foram lá, e excitaram as multidões.',
        'No mesmo instante os irmãos mandaram a Paulo que fosse até ao mar, mas Silas e Timóteo ficaram ali.',
        'E os que acompanhavam Paulo o levaram até Atenas, e, recebendo ordem para que Silas e Timóteo fossem ter com ele o mais depressa possível, partiram.',
        'E, enquanto Paulo os esperava em Atenas, o seu espírito se comovia em si mesmo, vendo a cidade tão entregue à idolatria.',
        'De sorte que disputava na sinagoga com os judeus e religiosos, e todos os dias na praça com os que se apresentavam.',
        'E alguns dos filósofos epicureus e estóicos contendiam com ele; e uns diziam: Que quer dizer este paroleiro? E outros: Parece que é pregador de deuses estranhos; porque lhes anunciava a Jesus e a ressurreição.',
        'E tomando-o, o levaram ao Areópago, dizendo: Poderemos nós saber que nova doutrina é essa de que falas?',
        'Pois coisas estranhas nos trazes aos ouvidos; queremos pois saber o que vem a ser isto',
        '(Pois todos os atenienses e estrangeiros residentes, de nenhuma outra coisa se ocupavam, senão de dizer e ouvir alguma novidade).',
        'E, estando Paulo no meio do Areópago, disse: Homens atenienses, em tudo vos vejo um tanto supersticiosos;',
        'Porque, passando eu e vendo os vossos santuários, achei também um altar em que estava escrito: AO DEUS DESCONHECIDO. Esse, pois, que vós honrais, não o conhecendo, é o que eu vos anuncio.',
        'O Deus que fez o mundo e tudo que nele há, sendo Senhor do céu e da terra, não habita em templos feitos por mãos de homens;',
        'Nem tampouco é servido por mãos de homens, como que necessitando de alguma coisa; pois ele mesmo é quem dá a todos a vida, e a respiração, e todas as coisas;',
        'E de um só sangue fez toda a geração dos homens, para habitar sobre toda a face da terra, determinando os tempos já dantes ordenados, e os limites da sua habitação;',
        'Para que buscassem ao Senhor, se porventura, tateando, o pudessem achar; ainda que não está longe de cada um de nós;',
        'Porque nele vivemos, e nos movemos, e existimos; como também alguns dos vossos poetas disseram: Pois somos também sua geração.',
        'Sendo nós, pois, geração de Deus, não havemos de cuidar que a divindade seja semelhante ao ouro, ou à prata, ou à pedra esculpida por artifício e imaginação dos homens.',
        'Mas Deus, não tendo em conta os tempos da ignorância, anuncia agora a todos os homens, e em todo o lugar, que se arrependam;',
        'Porquanto tem determinado um dia em que com justiça há de julgar o mundo, por meio do homem que destinou; e disso deu certeza a todos, ressuscitando-o dentre os mortos.',
        'E, como ouviram falar da ressurreição dos mortos, uns escarneciam, e outros diziam: Acerca disso te ouviremos outra vez.',
        'E assim Paulo saiu do meio deles.',
        'Todavia, chegando alguns homens a ele, creram; entre os quais foi Dionísio, areopagita, uma mulher por nome Dâmaris, e com eles outros.',
      ],
      [
        'E depois disto partiu Paulo de Atenas, e chegou a Corinto.',
        'E, achando um certo judeu por nome Áqüila, natural do Ponto, que havia pouco tinha vindo da Itália, e Priscila, sua mulher (pois Cláudio tinha mandado que todos os judeus saíssem de Roma), ajuntou-se com eles,',
        'E, como era do mesmo ofício, ficou com eles, e trabalhava; pois tinham por ofício fazer tendas.',
        'E todos os sábados disputava na sinagoga, e convencia a judeus e gregos.',
        'E, quando Silas e Timóteo desceram da Macedônia, foi Paulo impulsionado no espírito, testificando aos judeus que Jesus era o Cristo.',
        'Mas, resistindo e blasfemando eles, sacudiu as vestes, e disse-lhes: O vosso sangue seja sobre a vossa cabeça; eu estou limpo, e desde agora parto para os gentios.',
        'E, saindo dali, entrou em casa de um homem chamado chamado Justo, que servia a Deus, e cuja casa estava junto da sinagoga.',
        'E Crispo, principal da sinagoga, creu no Senhor com toda a sua casa; e muitos dos coríntios, ouvindo-o, creram e foram batizados.',
        'E disse o Senhor em visão a Paulo: Não temas, mas fala, e não te cales;',
        'Porque eu sou contigo, e ninguém lançará mão de ti para te fazer mal, pois tenho muito povo nesta cidade.',
        'E ficou ali um ano e seis meses, ensinando entre eles a palavra de Deus.',
        'Mas, sendo Gálio procônsul da Acaia, levantaram-se os judeus concordemente contra Paulo, e o levaram ao tribunal,',
        'Dizendo: Este persuade os homens a servir a Deus contra a lei.',
        'E, querendo Paulo abrir a boca, disse Gálio aos judeus: Se houvesse, ó judeus, algum agravo ou crime enorme, com razão vos sofreria,',
        'Mas, se a questão é de palavras, e de nomes, e da lei que entre vós há, vede-o vós mesmos; porque eu não quero ser juiz dessas coisas.',
        'E expulsou-os do tribunal.',
        'Então todos os gregos agarraram Sóstenes, principal da sinagoga, e o feriram diante do tribunal; e a Gálio nada destas coisas o incomodava.',
        'E Paulo, ficando ainda ali muitos dias, despediu-se dos irmãos, e dali navegou para a Síria, e com ele Priscila e Áqüila, tendo rapado a cabeça em Cencréia, porque tinha voto.',
        'E chegou a Éfeso, e deixou-os ali; mas ele, entrando na sinagoga, disputava com os judeus.',
        'E, rogando-lhe eles que ficasse por mais algum tempo, não conveio nisso.',
        'Antes se despediu deles, dizendo: É-me de todo preciso celebrar a solenidade que vem em Jerusalém; mas querendo Deus, outra vez voltarei a vós. E partiu de Éfeso.',
        'E, chegando a Cesaréia, subiu a Jerusalém e, saudando a igreja, desceu a Antioquia.',
        'E, estando ali algum tempo, partiu, passando sucessivamente pela província da Galácia e da Frígia, confirmando a todos os discípulos.',
        'E chegou a Éfeso um certo judeu chamado Apolo, natural de Alexandria, homem eloqüente e poderoso nas Escrituras.',
        'Este era instruído no caminho do Senhor e, fervoroso de espírito, falava e ensinava diligentemente as coisas do Senhor, conhecendo somente o batismo de João.',
        'Ele começou a falar ousadamente na sinagoga; e, quando o ouviram Priscila e Aqüila, o levaram consigo e lhe declararam mais precisamente o caminho de Deus.',
        'Querendo ele passar à Acaia, o animaram os irmãos, e escreveram aos discípulos que o recebessem; o qual, tendo chegado, aproveitou muito aos que pela graça criam.',
        'Porque com grande veemência, convencia publicamente os judeus, mostrando pelas Escrituras que Jesus era o Cristo.',
      ],
      [
        'E sucedeu que, enquanto Apolo estava em Corinto, Paulo, tendo passado por todas as regiões superiores, chegou a Éfeso; e achando ali alguns discípulos,',
        'Disse-lhes: Recebestes vós já o Espírito Santo quando crestes? E eles disseram-lhe: Nós nem ainda ouvimos que haja Espírito Santo.',
        'Perguntou-lhes, então: Em que sois batizados então? E eles disseram: No batismo de João.',
        'Mas Paulo disse: Certamente João batizou com o batismo de arrependimento, dizendo ao povo que cresse no que após ele havia de vir, isto é, em Jesus Cristo.',
        'E os que ouviram foram batizados em nome do Senhor Jesus.',
        'E, impondo-lhes Paulo as mãos, veio sobre eles o Espírito Santo; e falavam línguas, e profetizavam.',
        'E estes eram, ao todo, uns doze homens.',
        'E, entrando na sinagoga, falou ousadamente por espaço de três meses, disputando e persuadindo-os acerca do reino de Deus.',
        'Mas, como alguns deles se endurecessem e não obedecessem, falando mal do Caminho perante a multidão, retirou-se deles, e separou os discípulos, disputando todos os dias na escola de um certo Tirano.',
        'E durou isto por espaço de dois anos; de tal maneira que todos os que habitavam na Ásia ouviram a palavra do Senhor Jesus, assim judeus como gregos.',
        'E Deus pelas mãos de Paulo fazia maravilhas extraordinárias.',
        'De sorte que até os lenços e aventais se levavam do seu corpo aos enfermos, e as enfermidades fugiam deles, e os espíritos malignos saíam.',
        'E alguns dos exorcistas judeus ambulantes tentavam invocar o nome do Senhor Jesus sobre os que tinham espíritos malignos, dizendo: Esconjuro-vos por Jesus a quem Paulo prega.',
        'E os que faziam isto eram sete filhos de Ceva, judeu, principal dos sacerdotes.',
        'Respondendo, porém, o espírito maligno, disse: Conheço a Jesus, e bem sei quem é Paulo; mas vós quem sois?',
        'E, saltando neles o homem que tinha o espírito maligno, e assenhoreando-se de todos, pôde mais do que eles; de tal maneira que, nus e feridos, fugiram daquela casa.',
        'E foi isto notório a todos os que habitavam em Éfeso, tanto judeus como gregos; e caiu temor sobre todos eles, e o nome do Senhor Jesus era engrandecido.',
        'E muitos dos que tinham crido vinham, confessando e publicando os seus feitos.',
        'Também muitos dos que seguiam artes mágicas trouxeram os seus livros, e os queimaram na presença de todos e, feita a conta do seu preço, acharam que montava a cinqüenta mil peças de prata.',
        'Assim a palavra do Senhor crescia poderosamente e prevalecia.',
        'E, cumpridas estas coisas, Paulo propôs, em espírito, ir a Jerusalém, passando pela Macedônia e pela Acaia, dizendo: Depois que houver estado ali, importa-me ver também Roma.',
        'E, enviando à Macedônia dois daqueles que o serviam, Timóteo e Erasto, ficou ele por algum tempo na Ásia.',
        'E, naquele mesmo tempo, houve um não pequeno alvoroço acerca do Caminho.',
        'Porque um certo ourives da prata, por nome Demétrio, que fazia de prata nichos de Diana, dava não pouco lucro aos artífices,',
        'Aos quais, havendo-os ajuntado com os oficiais de obras semelhantes, disse: Senhores, vós bem sabeis que deste ofício temos a nossa prosperidade;',
        'E bem vedes e ouvis que não só em Éfeso, mas até quase em toda a Ásia, este Paulo tem convencido e afastado uma grande multidão, dizendo que não são deuses os que se fazem com as mãos.',
        'E não somente há o perigo de que a nossa profissão caia em descrédito, mas também de que o próprio templo da grande deusa Diana seja estimado em nada, vindo a ser destruída a majestade daquela que toda a Ásia e o mundo veneram.',
        'E, ouvindo-o, encheram-se de ira, e clamaram, dizendo: Grande é a Diana dos efésios.',
        'E encheu-se de confusão toda a cidade e, unânimes, correram ao teatro, arrebatando a Gaio e a Aristarco, macedônios, companheiros de Paulo na viagem.',
        'E, querendo Paulo apresentar-se ao povo, não lho permitiram os discípulos.',
        'E também alguns dos principais da Ásia, que eram seus amigos, lhe rogaram que não se apresentasse no teatro.',
        'Uns, pois, clamavam de uma maneira, outros de outra, porque o ajuntamento era confuso; e os mais deles não sabiam por que causa se tinham ajuntado.',
        'Então tiraram Alexandre dentre a multidão, impelindo-o os judeus para diante; e Alexandre, acenando com a mão, queria dar razão disto ao povo.',
        'Mas quando conheceram que era judeu, todos unanimemente levantaram a voz, clamando por espaço de quase duas horas: Grande é a Diana dos efésios.',
        'Então o escrivão da cidade, tendo apaziguado a multidão, disse: Homens efésios, qual é o homem que não sabe que a cidade dos efésios é a guardadora do templo da grande deusa Diana, e da imagem que desceu de Júpiter?',
        'Ora, não podendo isto ser contraditado, convém que vos aplaqueis e nada façais temerariamente;',
        'Porque estes homens que aqui trouxestes nem são sacrílegos nem blasfemam da vossa deusa.',
        'Mas, se Demétrio e os artífices que estão com ele têm alguma coisa contra alguém, há audiências e há procônsules; que se acusem uns aos outros;',
        'E, se alguma outra coisa demandais, averiguar-se-á em legítima assembléia.',
        'Na verdade até corremos perigo de que, por hoje, sejamos acusados de sedição, não havendo causa alguma com que possamos justificar este concurso.',
        'E, tendo dito isto, despediu a assembléia.',
      ],
      [
        'E, depois que cessou o alvoroço, Paulo chamou a si os discípulos e, abraçando-os, saiu para a macedônia.',
        'E, havendo andado por aquelas terras, exortando-os com muitas palavras, veio à Grécia.',
        'E, passando ali três meses, e sendo-lhe pelos judeus postas ciladas, como tivesse de navegar para a Síria, determinou voltar pela macedônia.',
        'E acompanhou-o, até à Ásia, Sópater, de Beréia, e, dos de Tessalônica, Aristarco, e Segundo, e Gaio de Derbe, e Timóteo, e, dos da Ásia, Tíquico e Trófimo.',
        'Estes, indo adiante, nos esperaram em Trôade.',
        'E, depois dos dias dos pães ázimos, navegamos de Filipos, e em cinco dias fomos ter com eles a Trôade, onde estivemos sete dias.',
        'E no primeiro dia da semana, ajuntando-se os discípulos para partir o pão, Paulo, que havia de partir no dia seguinte, falava com eles; e prolongou a prática até à meia-noite.',
        'E havia muitas luzes no cenáculo onde estavam juntos.',
        'E, estando um certo jovem, por nome Eutico, assentado numa janela, caiu do terceiro andar, tomado de um sono profundo que lhe sobreveio durante o extenso discurso de Paulo; e foi levantado morto.',
        'Paulo, porém, descendo, inclinou-se sobre ele e, abraçando-o, disse: Não vos perturbeis, que a sua alma nele está.',
        'E subindo, e partindo o pão, e comendo, ainda lhes falou largamente até à alvorada; e assim partiu.',
        'E levaram vivo o jovem, e ficaram não pouco consolados.',
        'Nós, porém, subindo ao navio, navegamos até Assôs, onde devíamos receber a Paulo, porque assim o ordenara, indo ele por terra.',
        'E, logo que se ajuntou conosco em Assôs, o recebemos, e fomos a Mitilene.',
        'E, navegando dali, chegamos no dia seguinte defronte de Quios, e no outro aportamos a Samos e, ficando em Trogílio, chegamos no dia seguinte a Mileto.',
        'Porque já Paulo tinha determinado passar ao largo de Éfeso, para não gastar tempo na Ásia. Apressava-se, pois, para estar, se lhe fosse possível, em Jerusalém no dia de Pentecostes.',
        'E de Mileto mandou a Éfeso, a chamar os anciãos da igreja.',
        'E, logo que chegaram junto dele, disse-lhes: Vós bem sabeis, desde o primeiro dia em que entrei na Ásia, como em todo esse tempo me portei no meio de vós,',
        'Servindo ao Senhor com toda a humildade, e com muitas lágrimas e tentações, que pelas ciladas dos judeus me sobrevieram;',
        'Como nada, que útil seja, deixei de vos anunciar, e ensinar publicamente e pelas casas,',
        'Testificando, tanto aos judeus como aos gregos, a conversão a Deus, e a fé em nosso Senhor Jesus Cristo.',
        'E agora, eis que, ligado eu pelo espírito, vou para Jerusalém, não sabendo o que lá me há de acontecer,',
        'Senão o que o Espírito Santo de cidade em cidade me revela, dizendo que me esperam prisões e tribulações.',
        'Mas de nada faço questão, nem tenho a minha vida por preciosa, contanto que cumpra com alegria a minha carreira, e o ministério que recebi do Senhor Jesus, para dar testemunho do evangelho da graça de Deus.',
        'E agora, na verdade, sei que todos vós, por quem passei pregando o reino de Deus, não vereis mais o meu rosto.',
        'Portanto, no dia de hoje, vos protesto que estou limpo do sangue de todos.',
        'Porque nunca deixei de vos anunciar todo o conselho de Deus.',
        'Olhai, pois, por vós, e por todo o rebanho sobre que o Espírito Santo vos constituiu bispos, para apascentardes a igreja de Deus, que ele resgatou com seu próprio sangue.',
        'Porque eu sei isto que, depois da minha partida, entrarão no meio de vós lobos cruéis, que não pouparão ao rebanho;',
        'E que de entre vós mesmos se levantarão homens que falarão coisas perversas, para atraírem os discípulos após si.',
        'Portanto, vigiai, lembrando-vos de que durante três anos, não cessei, noite e dia, de admoestar com lágrimas a cada um de vós.',
        'Agora, pois, irmãos, encomendo-vos a Deus e à palavra da sua graça; a ele que é poderoso para vos edificar e dar herança entre todos os santificados.',
        'De ninguém cobicei a prata, nem o ouro, nem o vestuário.',
        'Sim, vós mesmos sabeis que para o que me era necessário a mim, e aos que estão comigo, estas mãos me serviram.',
        'Tenho-vos mostrado em tudo que, trabalhando assim, é necessário auxiliar os enfermos, e recordar as palavras do Senhor Jesus, que disse: Mais bem-aventurada coisa é dar do que receber.',
        'E, havendo dito isto, pôs-se de joelhos, e orou com todos eles.',
        'E levantou-se um grande pranto entre todos e, lançando-se ao pescoço de Paulo, o beijavam,',
        'Entristecendo-se muito, principalmente pela palavra que dissera, que não veriam mais o seu rosto. E acompanharam-no até o navio.',
      ],
      [
        'E aconteceu que, separando-nos deles, navegamos e fomos correndo caminho direito, e chegamos a Cós, e no dia seguinte a Rodes, de onde passamos a Pátara.',
        'E, achando um navio, que ia para a Fenícia, embarcamos nele, e partimos.',
        'E, indo já à vista de Chipre, deixando-a à esquerda, navegamos para a Síria e chegamos a Tiro; porque o navio havia de ser descarregado ali.',
        'E, achando discípulos, ficamos ali sete dias; e eles pelo Espírito diziam a Paulo que não subisse a Jerusalém.',
        'E, havendo passado ali aqueles dias, saímos, e seguimos nosso caminho, acompanhando-nos todos, com suas mulheres e filhos até fora da cidade; e, postos de joelhos na praia, oramos.',
        'E, despedindo-nos uns dos outros, subimos ao navio; e eles voltaram para suas casas.',
        'E nós, concluída a navegação de Tiro, viemos a Ptolemaida; e, havendo saudado os irmãos, ficamos com eles um dia.',
        'E no dia seguinte, partindo dali Paulo, e nós que com ele estávamos, chegamos a Cesaréia; e, entrando em casa de Filipe, o evangelista, que era um dos sete, ficamos com ele.',
        'E tinha este quatro filhas virgens, que profetizavam.',
        'E, demorando-nos ali por muitos dias, chegou da Judéia um profeta, por nome Ágabo;',
        'E, vindo ter conosco, tomou a cinta de Paulo, e ligando-se os seus próprios pés e mãos, disse: Isto diz o Espírito Santo: Assim ligarão os judeus em Jerusalém o homem de quem é esta cinta, e o entregarão nas mãos dos gentios.',
        'E, ouvindo nós isto, rogamos-lhe, tanto nós como os que eram daquele lugar, que não subisse a Jerusalém.',
        'Mas Paulo respondeu: Que fazeis vós, chorando e magoando-me o coração? Porque eu estou pronto não só a ser ligado, mas ainda a morrer em Jerusalém pelo nome do Senhor Jesus.',
        'E, como não podíamos convencê-lo, nos aquietamos, dizendo: Faça-se a vontade do Senhor.',
        'E depois daqueles dias, havendo feito os nossos preparativos, subimos a Jerusalém.',
        'E foram também conosco alguns discípulos de Cesaréia, levando consigo um certo Mnasom, chíprio, discípulo antigo, com quem havíamos de hospedar-nos.',
        'E, logo que chegamos a Jerusalém, os irmãos nos receberam de muito boa vontade.',
        'E no dia seguinte, Paulo entrou conosco em casa de Tiago, e todos os anciãos vieram ali.',
        'E, havendo-os saudado, contou-lhes por miúdo o que por seu ministério Deus fizera entre os gentios.',
        'E, ouvindo-o eles, glorificaram ao Senhor, e disseram-lhe: Bem vês, irmão, quantos milhares de judeus há que crêem, e todos são zeladores da lei.',
        'E já acerca de ti foram informados de que ensinas todos os judeus que estão entre os gentios a apartarem-se de Moisés, dizendo que não devem circuncidar seus filhos, nem andar segundo o costume da lei.',
        'Que faremos pois? em todo o caso é necessário que a multidão se ajunte; porque terão ouvido que já és vindo.',
        'Faze, pois, isto que te dizemos: Temos quatro homens que fizeram voto.',
        'Toma estes contigo, e santifica-te com eles, e faze por eles os gastos para que rapem a cabeça, e todos ficarão sabendo que nada há daquilo de que foram informados acerca de ti, mas que também tu mesmo andas guardando a lei.',
        'Todavia, quanto aos que creem dos gentios, já nós havemos escrito, e achado por bem, que nada disto observem; mas que só se guardem do que se sacrifica aos ídolos, e do sangue, e do sufocado e da fornicação.',
        'Então Paulo, tomando consigo aqueles homens, entrou no dia seguinte no templo, já santificado com eles, anunciando serem já cumpridos os dias da purificação; e ficou ali até se oferecer por cada um deles a oferta.',
        'E quando os sete dias estavam quase a terminar, os judeus da Ásia, vendo-o no templo, alvoroçaram todo o povo e lançaram mão dele,',
        'Clamando: Homens israelitas, acudi; este é o homem que por todas as partes ensina a todos contra o povo e contra a lei, e contra este lugar; e, demais disto, introduziu também no templo os gregos, e profanou este santo lugar.',
        'Porque tinham visto com ele na cidade a Trófimo de Éfeso, o qual pensavam que Paulo introduzira no templo.',
        'E alvoroçou-se toda a cidade, e houve grande concurso de povo; e, pegando Paulo, o arrastaram para fora do templo, e logo as portas se fecharam.',
        'E, procurando eles matá-lo, chegou ao tribuno da coorte o aviso de que Jerusalém estava toda em confusão;',
        'O qual, tomando logo consigo soldados e centuriões, correu para eles. E, quando viram o tribuno e os soldados, cessaram de ferir a Paulo.',
        'Então, aproximando-se o tribuno, o prendeu e o mandou atar com duas cadeias, e lhe perguntou quem era e o que tinha feito.',
        'E na multidão uns clamavam de uma maneira, outros de outra; mas, como nada podia saber ao certo, por causa do alvoroço, mandou conduzi-lo para a fortaleza.',
        'E sucedeu que, chegando às escadas, os soldados tiveram de lhe pegar por causa da violência da multidão.',
        'Porque a multidão do povo o seguia, clamando: Mata-o!',
        'E, quando iam a introduzir Paulo na fortaleza, disse Paulo ao tribuno: É-me permitido dizer-te alguma coisa? E ele disse: Sabes o grego?',
        'Não és tu porventura aquele egípcio que antes destes dias fez uma sedição e levou ao deserto quatro mil salteadores?',
        'Mas Paulo lhe disse: Na verdade que sou um homem judeu, cidadão de Tarso, cidade não pouco célebre na Cilícia; rogo-te, porém, que me permitas falar ao povo.',
        'E, havendo-lho permitido, Paulo, pondo-se em pé nas escadas, fez sinal com a mão ao povo; e, feito grande silêncio, falou-lhes em língua hebraica, dizendo:',
      ],
      [
        'Homens, irmàos e pais, ouvi agora a minha defesa perante vós',
        '(E, quando ouviram falar-lhes em língua hebraica, maior silêncio guardaram). E disse:',
        'Quanto a mim, sou judeu, nascido em Tarso da Cilícia, e nesta cidade criado aos pés de Gamaliel, instruído conforme a verdade da lei de nossos pais, zelador de Deus, como todos vós hoje sois.',
        'E persegui este caminho até à morte, prendendo, e pondo em prisões, tanto homens como mulheres,',
        'Como também o sumo sacerdote me é testemunha, e todo o conselho dos anciãos. E, recebendo destes cartas para os irmãos, fui a Damasco, para trazer maniatados para Jerusalém aqueles que ali estivessem, a fim de que fossem castigados.',
        'Ora, aconteceu que, indo eu já de caminho, e chegando perto de Damasco, quase ao meio-dia, de repente me rodeou uma grande luz do céu.',
        'E caí por terra, e ouvi uma voz que me dizia: Saulo, Saulo, por que me persegues?',
        'E eu respondi: Quem és, Senhor? E disse-me: Eu sou Jesus Nazareno, a quem tu persegues.',
        'E os que estavam comigo viram, em verdade, a luz, e se atemorizaram muito, mas não ouviram a voz daquele que falava comigo.',
        'Então disse eu: Senhor, que farei? E o Senhor disse-me: Levanta-te, e vai a Damasco, e ali se te dirá tudo o que te é ordenado fazer.',
        'E, como eu não via, por causa do esplendor daquela luz, fui levado pela mão dos que estavam comigo, e cheguei a Damasco.',
        'E um certo Ananias, homem piedoso conforme a lei, que tinha bom testemunho de todos os judeus que ali moravam,',
        'Vindo ter comigo, e apresentando-se, disse-me: Saulo, irmão, recobra a vista. E naquela mesma hora o vi.',
        'E ele disse: O Deus de nossos pais de antemão te designou para que conheças a sua vontade, e vejas aquele Justo e ouças a voz da sua boca.',
        'Porque hás de ser sua testemunha para com todos os homens do que tens visto e ouvido.',
        'E agora por que te deténs? Levanta-te, e batiza-te, e lava os teus pecados, invocando o nome do Senhor.',
        'E aconteceu que, tornando eu para Jerusalém, quando orava no templo, fui arrebatado para fora de mim.',
        'E vi aquele que me dizia: Dá-te pressa e sai apressadamente de Jerusalém; porque não receberão o teu testemunho acerca de mim.',
        'E eu disse: Senhor, eles bem sabem que eu lançava na prisão e açoitava nas sinagogas os que criam em ti.',
        'E quando o sangue de Estêvão, tua testemunha, se derramava, também eu estava presente, e consentia na sua morte, e guardava as capas dos que o matavam.',
        'E disse-me: Vai, porque hei de enviar-te aos gentios de longe.',
        'E ouviram-no até esta palavra, e levantaram a voz, dizendo: Tira da terra um tal homem, porque não convém que viva.',
        'E, clamando eles, e arrojando de si as vestes, e lançando pó para o ar,',
        'O tribuno mandou que o levassem para a fortaleza, dizendo que o examinassem com açoites, para saber por que causa assim clamavam contra ele.',
        'E, quando o estavam atando com correias, disse Paulo ao centurião que ali estava: É-vos lícito açoitar um romano, sem ser condenado?',
        'E, ouvindo isto, o centurião foi, e anunciou ao tribuno, dizendo: Vê o que vais fazer, porque este homem é romano.',
        'E, vindo o tribuno, disse-lhe: Dize-me, és tu romano? E ele disse: Sim.',
        'E respondeu o tribuno: Eu com grande soma de dinheiro alcancei este direito de cidadão. Paulo disse: Mas eu o sou de nascimento.',
        'E logo dele se apartaram os que o haviam de examinar; e até o tribuno teve temor, quando soube que era romano, visto que o tinha ligado.',
        'E no dia seguinte, querendo saber ao certo a causa por que era acusado pelos judeus, soltou-o das prisões, e mandou vir o principais dos sacerdotes, e todo o seu conselho; e, trazendo Paulo, o apresentou diante deles.',
      ],
      [
        'E, pondo Paulo os olhos no conselho, disse: Homens irmãos, até ao dia de hoje tenho andado diante de Deus com toda a boa consciência.',
        'Mas o sumo sacerdote, Ananias, mandou aos que estavam junto dele que o ferissem na boca.',
        'Então Paulo lhe disse: Deus te ferirá, parede branqueada; tu estás aqui assentado para julgar-me conforme a lei, e contra a lei me mandas ferir?',
        'E os que ali estavam disseram: Injurias o sumo sacerdote de Deus?',
        'E Paulo disse: Não sabia, irmãos, que era o sumo sacerdote; porque está escrito: Não dirás mal do príncipe do teu povo.',
        'E Paulo, sabendo que uma parte era de saduceus e outra de fariseus, clamou no conselho: Homens irmãos, eu sou fariseu, filho de fariseu; no tocante à esperança e ressurreição dos mortos sou julgado.',
        'E, havendo dito isto, houve dissensão entre os fariseus e saduceus; e a multidão se dividiu.',
        'Porque os saduceus dizem que não há ressurreição, nem anjo, nem espírito; mas os fariseus reconhecem uma e outra coisa.',
        'E originou-se um grande clamor; e, levantando-se os escribas da parte dos fariseus, contendiam, dizendo: Nenhum mal achamos neste homem, e, se algum espírito ou anjo lhe falou, não lutemos contra Deus.',
        'E, havendo grande dissensão, o tribuno, temendo que Paulo fosse despedaçado por eles, mandou descer a soldadesca, para que o tirassem do meio deles, e o levassem para a fortaleza.',
        'E na noite seguinte, apresentando-se-lhe o Senhor, disse: Paulo, tem ânimo; porque, como de mim testificaste em Jerusalém, assim importa que testifiques também em Roma.',
        'E, quando já era dia, alguns dos judeus fizeram uma conspiração, e juraram, dizendo que não comeriam nem beberiam enquanto não matassem a Paulo.',
        'E eram mais de quarenta os que fizeram esta conjuração.',
        'E estes foram ter com os principais dos sacerdotes e anciãos, e disseram: Conjuramo-nos, sob pena de maldição, a nada provarmos até que matemos a Paulo.',
        'Agora, pois, vós, com o conselho, rogai ao tribuno que vo-lo traga amanhã, como que querendo saber mais alguma coisa de seus negócios, e, antes que chegue, estaremos prontos para o matar.',
        'E o filho da irmã de Paulo, tendo ouvido acerca desta cilada, foi, e entrou na fortaleza, e o anunciou a Paulo.',
        'E Paulo, chamando a si um dos centuriões, disse: Leva este jovem ao tribuno, porque tem alguma coisa que lhe comunicar.',
        'Tomando-o ele, pois, o levou ao tribuno, e disse: O preso Paulo, chamando-me a si, rogou-me que trouxesse este jovem, que tem alguma coisa para dizer-te.',
        'E o tribuno, tomando-o pela mão, e pondo-se à parte, perguntou-lhe em particular: Que tens que me contar?',
        'E disse ele: Os judeus se concertaram rogar-te que amanhã leves Paulo ao conselho, como que tendo de inquirir dele mais alguma coisa ao certo.',
        'Mas tu não os creias; porque mais de quarenta homens de entre eles lhe andam armando ciladas; os quais se obrigaram, sob pena de maldição, a não comer nem beber até que o tenham morto; e já estão apercebidos, esperando de ti promessa.',
        'Então o tribuno despediu o jovem, mandando-lhe que a ninguém dissesse que lhe havia contado aquilo.',
        'E, chamando dois centuriões, lhes disse: Aprontai para as três horas da noite duzentos soldados, e setenta de cavalaria, e duzentos archeiros para irem até Cesaréia;',
        'E aparelhai animais, para que, pondo neles a Paulo, o levem salvo ao presidente Félix.',
        'E escreveu uma carta, que continha isto:',
        'Cláudio Lísias, a Félix, potentíssimo presidente, saúde.',
        'Esse homem foi preso pelos judeus; e, estando já a ponto de ser morto por eles, sobrevim eu com a soldadesca, e o livrei, informado de que era romano.',
        'E, querendo saber a causa por que o acusavam, o levei ao seu conselho.',
        'E achei que o acusavam de algumas questões da sua lei; mas que nenhum crime havia nele digno de morte ou de prisão.',
        'E, sendo-me notificado que os judeus haviam de armar ciladas a esse homem, logo to enviei, mandando também aos acusadores que perante ti digam o que tiverem contra ele. Passa bem.',
        'Tomando, pois, os soldados a Paulo, como lhe fora mandado, o trouxeram de noite a Antipátride.',
        'E no dia seguinte, deixando aos de cavalo irem com ele, tornaram à fortaleza.',
        'Os quais, logo que chegaram a Cesaréia, e entregaram a carta ao presidente, lhe apresentaram Paulo.',
        'E o presidente, lida a carta, perguntou de que província era; e, sabendo que era da Cilícia,',
        'Disse: Ouvir-te-ei, quando também aqui vierem os teus acusadores. E mandou que o guardassem no pretório de Herodes.',
      ],
      [
        'E, cinco dias depois, o sumo sacerdote Ananias desceu com os anciãos, e um certo Tértulo, orador, os quais compareceram perante o presidente contra Paulo.',
        'E, sendo chamado, Tértulo começou a acusá-lo, dizendo: Visto como por ti temos tanta paz e por tua prudência se fazem a este povo muitos e louváveis serviços,',
        'Sempre e em todo o lugar, ó potentíssimo Félix, com todo o agradecimento o queremos reconhecer.',
        'Mas, para que não te detenha muito, rogo-te que, conforme a tua eqüidade, nos ouças por pouco tempo.',
        'Temos achado que este homem é uma peste, e promotor de sedições entre todos os judeus, por todo o mundo; e o principal defensor da seita dos nazarenos;',
        'O qual intentou também profanar o templo; e nós o prendemos, e conforme a nossa lei o quisemos julgar.',
        'Mas, sobrevindo o tribuno Lísias, no-lo tirou de entre as mãos com grande violência,',
        'Mandando aos seus acusadores que viessem a ti; e dele tu mesmo, examinando-o, poderás entender tudo o de que o acusamos.',
        'E também os judeus consentiam, dizendo serem estas coisas assim.',
        'Paulo, porém, fazendo-lhe o presidente sinal que falasse, respondeu: Porque sei que já vai para muitos anos que desta nação és juiz, com tanto melhor ânimo respondo por mim.',
        'Pois bem podes saber que não há mais de doze dias que subi a Jerusalém a adorar;',
        'E não me acharam no templo falando com alguém, nem amotinando o povo nas sinagogas, nem na cidade.',
        'Nem tampouco podem provar as coisas de que agora me acusam.',
        'Mas confesso-te isto que, conforme aquele caminho que chamam seita, assim sirvo ao Deus de nossos pais, crendo tudo quanto está escrito na lei e nos profetas.',
        'Tendo esperança em Deus, como estes mesmos também esperam, de que há de haver ressurreição de mortos, assim dos justos como dos injustos.',
        'E por isso procuro sempre ter uma consciência sem ofensa, tanto para com Deus como para com os homens.',
        'Ora, muitos anos depois, vim trazer à minha nação esmolas e ofertas.',
        'Nisto me acharam já santificado no templo, não em ajuntamentos, nem com alvoroços, uns certos judeus da Ásia,',
        'Os quais convinha que estivessem presentes perante ti, e me acusassem, se alguma coisa contra mim tivessem.',
        'Ou digam estes mesmos, se acharam em mim alguma iniqüidade, quando compareci perante o conselho,',
        'A não ser estas palavras que, estando entre eles, clamei: Hoje sou julgado por vós acerca da ressurreição dos mortos.',
        'Então Félix, havendo ouvido estas coisas, lhes pôs dilação, dizendo: Havendo-me informado melhor deste Caminho, quando o tribuno Lísias tiver descido, então tomarei inteiro conhecimento dos vossos negócios.',
        'E mandou ao centurião que o guardasse em prisão, tratando-o com brandura, e que a ninguém dos seus proibisse servi-lo ou vir ter com ele.',
        'E alguns dias depois, vindo Félix com sua mulher Drusila, que era judia, mandou chamar a Paulo, e ouviu-o acerca da fé em Cristo.',
        'E, tratando ele da justiça, e da temperança, e do juízo vindouro, Félix, espavorido, respondeu: Por agora vai-te, e em tendo oportunidade te chamarei.',
        'Esperando ao mesmo tempo que Paulo lhe desse dinheiro, para que o soltasse; pelo que também muitas vezes o mandava chamar, e falava com ele.',
        'Mas, passados dois anos, Félix teve por sucessor a Pórcio Festo; e, querendo Félix comprazer aos judeus, deixou a Paulo preso.',
      ],
      [
        'Entrando, pois, Festo na província, subiu dali a três dias de Cesaréia a Jerusalém.',
        'E o sumo sacerdote e os principais dos judeus compareceram perante ele contra Paulo, e lhe rogaram,',
        'Pedindo como favor contra ele que o fizesse vir a Jerusalém, armando ciladas para o matarem no caminho.',
        'Mas Festo respondeu que Paulo estava guardado em Cesaréia, e que ele brevemente partiria para lá.',
        'Os que, pois, disse, dentre vós, têm poder, desçam comigo e, se neste homem houver algum crime, acusem-no.',
        'E, havendo-se demorado entre eles mais de dez dias, desceu a Cesaréia; e no dia seguinte, assentando-se no tribunal, mandou que trouxessem Paulo.',
        'E, chegando ele, rodearam-no os judeus que haviam descido de Jerusalém, trazendo contra Paulo muitas e graves acusações, que não podiam provar.',
        'Mas ele, em sua defesa, disse: Eu não pequei em coisa alguma contra a lei dos judeus, nem contra o templo, nem contra César.',
        'Todavia Festo, querendo comprazer aos judeus, respondendo a Paulo, disse: Queres tu subir a Jerusalém, e ser lá perante mim julgado acerca destas coisas?',
        'Mas Paulo disse: Estou perante o tribunal de César, onde convém que seja julgado; não fiz agravo algum aos judeus, como tu muito bem sabes.',
        'Se fiz algum agravo, ou cometi alguma coisa digna de morte, não recuso morrer; mas, se nada há das coisas de que estes me acusam, ninguém me pode entregar a eles; apelo para César.',
        'Então Festo, tendo falado com o conselho, respondeu: Apelaste para César? para César irás.',
        'E, passados alguns dias, o rei Agripa e Berenice vieram a Cesaréia, a saudar Festo.',
        'E, como ali ficassem muitos dias, Festo contou ao rei os negócios de Paulo, dizendo: Um certo homem foi deixado por Félix aqui preso,',
        'Por cujo respeito os principais dos sacerdotes e os anciãos dos judeus, estando eu em Jerusalém, compareceram perante mim, pedindo sentença contra ele.',
        'Aos quais respondi não ser costume dos romanos entregar algum homem à morte, sem que o acusado tenha presentes os seus acusadores, e possa defender-se da acusação.',
        'De sorte que, chegando eles aqui juntos, no dia seguinte, sem fazer dilação alguma, assentado no tribunal, mandei que trouxessem o homem.',
        'Acerca do qual, estando presentes os acusadores, nenhuma coisa apontaram daquelas que eu suspeitava.',
        'Tinham, porém, contra ele algumas questões acerca da sua superstição, e de um tal Jesus, morto, que Paulo afirmava viver.',
        'E, estando eu perplexo acerca da inquirição desta causa, disse se queria ir a Jerusalém, e lá ser julgado acerca destas coisas.',
        'E, apelando Paulo para que fosse reservado ao conhecimento de Augusto, mandei que o guardassem até que o envie a César.',
        'Então Agripa disse a Festo: Bem quisera eu também ouvir esse homem. E ele disse: Amanhã o ouvirás.',
        'E, no dia seguinte, vindo Agripa e Berenice, com muito aparato, entraram no auditório com os tribunos e homens principais da cidade, sendo trazido Paulo por mandado de Festo.',
        'E Festo disse: Rei Agripa, e todos os senhores que estais presentes conosco; aqui vedes um homem de quem toda a multidão dos judeus me tem falado, tanto em Jerusalém como aqui, clamando que não convém que viva mais.',
        'Mas, achando eu que nenhuma coisa digna de morte fizera, e apelando ele mesmo também para Augusto, tenho determinado enviar-lho.',
        'Do qual não tenho coisa alguma certa que escreva ao meu senhor, e por isso perante vós o trouxe, principalmente perante ti, ó rei Agripa, para que, depois de interrogado, tenha alguma coisa que escrever.',
        'Porque me parece contra a razão enviar um preso, e não notificar contra ele as acusações.',
      ],
      [
        'Depois Agripa disse a Paulo: É permitido que te defendas. Então Paulo, estendendo a mão em sua defesa, respondeu:',
        'Tenho-me por feliz, ó rei Agripa, de que perante ti me haja hoje de defender de todas as coisas de que sou acusado pelos judeus;',
        'Mormente sabendo eu que tens conhecimento de todos os costumes e questões que há entre os judeus; por isso te rogo que me ouças com paciência.',
        'Quanto à minha vida, desde a mocidade, como decorreu desde o princípio entre os da minha nação, em Jerusalém, todos os judeus a conhecem,',
        'Sabendo de mim desde o princípio (se o quiserem testificar), que, conforme a mais severa seita da nossa religião, vivi fariseu.',
        'E agora pela esperança da promessa que por Deus foi feita a nossos pais estou aqui e sou julgado.',
        'À qual as nossas doze tribos esperam chegar, servindo a Deus continuamente, noite e dia. Por esta esperança, ó rei Agripa, eu sou acusado pelos judeus.',
        'Pois quê? julga-se coisa incrível entre vós que Deus ressuscite os mortos?',
        'Bem tinha eu imaginado que contra o nome de Jesus Nazareno devia eu praticar muitos atos;',
        'O que também fiz em Jerusalém. E, havendo recebido autorização dos principais dos sacerdotes, encerrei muitos dos santos nas prisões; e quando os matavam eu dava o meu voto contra eles.',
        'E, castigando-os muitas vezes por todas as sinagogas, os obriguei a blasfemar. E, enfurecido demasiadamente contra eles, até nas cidades estranhas os persegui.',
        'Sobre o que, indo então a Damasco, com poder e comissão dos principais dos sacerdotes,',
        'Ao meio-dia, ó rei, vi no caminho uma luz do céu, que excedia o esplendor do sol, cuja claridade me envolveu a mim e aos que iam comigo.',
        'E, caindo nós todos por terra, ouvi uma voz que me falava, e em língua hebraica dizia: Saulo, Saulo, por que me persegues? Dura coisa te é recalcitrar contra os aguilhões.',
        'E disse eu: Quem és, Senhor? E ele respondeu: Eu sou Jesus, a quem tu persegues;',
        'Mas levanta-te e põe-te sobre teus pés, porque te apareci por isto, para te pôr por ministro e testemunha tanto das coisas que tens visto como daquelas pelas quais te aparecerei ainda;',
        'Livrando-te deste povo, e dos gentios, a quem agora te envio,',
        'Para lhes abrires os olhos, e das trevas os converteres à luz, e do poder de Satanás a Deus; a fim de que recebam a remissão de pecados, e herança entre os que são santificados pela fé em mim.',
        'Por isso, ó rei Agripa, não fui desobediente à visão celestial.',
        'Antes anunciei primeiramente aos que estão em Damasco e em Jerusalém, e por toda a terra da Judéia, e aos gentios, que se emendassem e se convertessem a Deus, fazendo obras dignas de arrependimento.',
        'Por causa disto os judeus lançaram mão de mim no templo, e procuraram matar-me.',
        'Mas, alcançando socorro de Deus, ainda até ao dia de hoje permaneço dando testemunho tanto a pequenos como a grandes, não dizendo nada mais do que o que os profetas e Moisés disseram que devia acontecer,',
        'Isto é, que o Cristo devia padecer, e sendo o primeiro da ressurreição dentre os mortos, devia anunciar a luz a este povo e aos gentios.',
        'E, dizendo ele isto em sua defesa, disse Festo em alta voz: Estás louco, Paulo; as muitas letras te fazem delirar.',
        'Mas ele disse: Não deliro, ó potentíssimo Festo; antes digo palavras de verdade e de um são juízo.',
        'Porque o rei, diante de quem também falo com ousadia, sabe estas coisas, pois não creio que nada disto lhe é oculto; porque isto não se fez em qualquer canto.',
        'Crês tu nos profetas, ó rei Agripa? Bem sei que crês.',
        'E disse Agripa a Paulo: Por pouco me queres persuadir a que me faça cristão!',
        'E disse Paulo: Prouvera a Deus que, ou por pouco ou por muito, não somente tu, mas também todos quantos hoje me estão ouvindo, se tornassem tais qual eu sou, exceto estas cadeias.',
        'E, dizendo ele isto, levantou-se o rei, o presidente, e Berenice, e os que com eles estavam assentados.',
        'E, apartando-se dali falavam uns com os outros, dizendo: Este homem nada fez digno de morte ou de prisões.',
        'E Agripa disse a Festo: Bem podia soltar-se este homem, se não houvera apelado para César.',
      ],
      [
        'E, como se determinou que havíamos de navegar para a Itália, entregaram Paulo, e alguns outros presos, a um centurião por nome Júlio, da coorte augusta.',
        'E, embarcando nós em um navio adramitino, partimos navegando pelos lugares da costa da Ásia, estando conosco Aristarco, macedônio, de Tessalônica.',
        'E chegamos no dia seguinte a Sidom, e Júlio, tratando Paulo humanamente, lhe permitiu ir ver os amigos, para que cuidassem dele.',
        'E, partindo dali, fomos navegando abaixo de Chipre, porque os ventos eram contrários.',
        'E, tendo atravessado o mar, ao longo da Cilícia e Panfília, chegamos a Mirra, na Lícia.',
        'E, achando ali o centurião um navio de Alexandria, que navegava para a Itália, nos fez embarcar nele.',
        'E, como por muitos dias navegássemos vagarosamente, havendo chegado apenas defronte de Cnido, não nos permitindo o vento ir mais adiante, navegamos abaixo de Creta, junto de Salmone.',
        'E, consteando-a dificilmente, chegamos a um lugar chamando Bons Portos, perto do qual estava a cidade de Laséia.',
        'E, passado muito tempo, e sendo já perigosa a navegação, pois, também o jejum já tinha passado, Paulo os admoestava,',
        'Dizendo-lhes: Senhores, vejo que a navegação há de ser incômoda, e com muito dano, não só para o navio e carga, mas também para as nossas vidas.',
        'Mas o centurião cria mais no piloto e no mestre, do que no que dizia Paulo.',
        'E, como aquele porto não era cômodo para invernar, os mais deles foram de parecer que se partisse dali para ver se podiam chegar a Fenice, que é um porto de Creta que olha para o lado do vento da África e do Coro, e invernar ali.',
        'E, soprando o sul brandamente, lhes pareceu terem já o que desejavam e, fazendo-se de vela, foram de muito perto costeando Creta.',
        'Mas não muito depois deu nela um pé de vento, chamado Euro-aquilão.',
        'E, sendo o navio arrebatado, e não podendo navegar contra o vento, dando de mão a tudo, nos deixamos ir à toa.',
        'E, correndo abaixo de uma pequena ilha chamada Clauda, apenas pudemos ganhar o batel.',
        'E, levado este para cima, usaram de todos os meios, cingindo o navio; e, temendo darem à costa na Sirte, amainadas as velas, assim foram à toa.',
        'E, andando nós agitados por uma veemente tempestade, no dia seguinte aliviaram o navio.',
        'E ao terceiro dia nós mesmos, com as nossas próprias mãos, lançamos ao mar a armação do navio.',
        'E, não aparecendo, havia já muitos dias, nem sol nem estrelas, e caindo sobre nós uma não pequena tempestade, fugiu-nos toda a esperança de nos salvarmos.',
        'E, havendo já muito que não se comia, então Paulo, pondo-se em pé no meio deles, disse: Fora, na verdade, razoável, ó senhores, ter-me ouvido a mim e não partir de Creta, e assim evitariam este incômodo e esta perda.',
        'Mas agora vos admoesto a que tenhais bom ânimo, porque não se perderá a vida de nenhum de vós, mas somente o navio.',
        'Porque esta mesma noite o anjo de Deus, de quem eu sou, e a quem sirvo, esteve comigo,',
        'Dizendo: Paulo, não temas; importa que sejas apresentado a César, e eis que Deus te deu todos quantos navegam contigo.',
        'Portanto, ó senhores, tende bom ânimo; porque creio em Deus, que há de acontecer assim como a mim me foi dito.',
        'É contudo necessário irmos dar numa ilha.',
        'E, quando chegou a décima quarta noite, sendo impelidos de um e outro lado no mar Adriático, lá pela meia-noite suspeitaram os marinheiros que estavam próximos de alguma terra.',
        'E, lançando o prumo, acharam vinte braças; e, passando um pouco mais adiante, tornando a lançar o prumo, acharam quinze braças.',
        'E, temendo ir dar em alguns rochedos, lançaram da popa quatro âncoras, desejando que viesse o dia.',
        'Procurando, porém, os marinheiros fugir do navio, e tendo já deitado o batel ao mar, como que querendo lançar as âncoras pela proa,',
        'Disse Paulo ao centurião e aos soldados: Se estes não ficarem no navio, não podereis salvar-vos.',
        'Então os soldados cortaram os cabos do batel, e o deixaram cair.',
        'E, entretanto que o dia vinha, Paulo exortava a todos a que comessem alguma coisa, dizendo: É já hoje o décimo quarto dia que esperais, e permaneceis sem comer, não havendo provado nada.',
        'Portanto, exorto-vos a que comais alguma coisa, pois é para a vossa saúde; porque nem um cabelo cairá da cabeça de qualquer de vós.',
        'E, havendo dito isto, tomando o pão, deu graças a Deus na presença de todos; e, partindo-o, começou a comer.',
        'E, tendo já todos bom ânimo, puseram-se também a comer.',
        'E éramos ao todo, no navio, duzentas e setenta e seis almas.',
        'E, refeitos com a comida, aliviaram o navio, lançando o trigo ao mar.',
        'E, sendo já dia, não conheceram a terra; enxergaram, porém, uma enseada que tinha praia, e consultaram-se sobre se deveriam encalhar nela o navio.',
        'E, levantando as âncoras, deixaram-no ir ao mar, largando também as amarras do leme; e, alçando a vela maior ao vento, dirigiram-se para a praia.',
        'Dando, porém, num lugar de dois mares, encalharam ali o navio; e, fixa a proa, ficou imóvel, mas a popa abria-se com a força das ondas.',
        'Então a idéia dos soldados foi que matassem os presos para que nenhum fugisse, escapando a nado.',
        'Mas o centurião, querendo salvar a Paulo, lhes estorvou este intento; e mandou que os que pudessem nadar se lançassem primeiro ao mar, e se salvassem em terra;',
        'E os demais, uns em tábuas e outros em coisas do navio. E assim aconteceu que todos chegaram à terra a salvo.',
      ],
      [
        'E, havendo escapado, então souberam que a ilha se chamava Malta.',
        'E os bárbaros usaram conosco de não pouca humanidade; porque, acendendo uma grande fogueira, nos recolheram a todos por causa da chuva que caía, e por causa do frio.',
        'E, havendo Paulo ajuntado uma quantidade de vides, e pondo-as no fogo, uma víbora, fugindo do calor, lhe acometeu a mão.',
        'E os bárbaros, vendo-lhe a víbora pendurada na mão, diziam uns aos outros: Certamente este homem é homicida, visto como, escapando do mar, a justiça não o deixa viver.',
        'Mas, sacudindo ele a víbora no fogo, não sofreu nenhum mal.',
        'E eles esperavam que viesse a inchar ou a cair morto de repente; mas tendo esperado já muito, e vendo que nenhum incômodo lhe sobrevinha, mudando de parecer, diziam que era um deus.',
        'E ali, próximo daquele lugar, havia umas herdades que pertenciam ao principal da ilha, por nome Públio, o qual nos recebeu e hospedou benignamente por três dias.',
        'E aconteceu estar de cama enfermo de febre e disenteria o pai de Públio, que Paulo foi ver, e, havendo orado, pôs as mãos sobre ele, e o curou.',
        'Feito, pois, isto, vieram também ter com ele os demais que na ilha tinham enfermidades, e sararam.',
        'Os quais nos distinguiram também com muitas honras; e, havendo de navegar, nos proveram das coisas necessárias.',
        'E três meses depois partimos num navio de Alexandria que invernara na ilha, o qual tinha por insígnia Castor e Pólux.',
        'E, chegando a Siracusa, ficamos ali três dias.',
        'De onde, indo costeando, viemos a Régio; e soprando, um dia depois, um vento do sul, chegamos no segundo dia a Potéoli.',
        'Onde, achando alguns irmãos, nos rogaram que por sete dias ficássemos com eles; e depois nos dirigimos a Roma.',
        'E de lá, ouvindo os irmãos novas de nós, nos saíram ao encontro à Praça de Ápio e às Três Vendas, e Paulo, vendo-os, deu graças a Deus e tomou ânimo.',
        'E, logo que chegamos a Roma, o centurião entregou os presos ao capitào da guarda; mas a Paulo se lhe permitiu morar por sua conta à parte, com o soldado que o guardava.',
        'E aconteceu que, três dias depois, Paulo convocou os principais dos judeus, e, juntos eles, lhes disse: Homens irmãos, não havendo eu feito nada contra o povo, ou contra os ritos paternos, vim contudo preso desde Jerusalém, entregue nas mãos dos romanos;',
        'Os quais, havendo-me examinado, queriam soltar-me, por não haver em mim crime algum de morte.',
        'Mas, opondo-se os judeus, foi-me forçoso apelar para César, não tendo, contudo, de que acusar a minha nação.',
        'Por esta causa vos chamei, para vos ver e falar; porque pela esperança de Israel estou com esta cadeia.',
        'Então eles lhe disseram: Nós não recebemos acerca de ti carta alguma da Judéia, nem veio aqui algum dos irmãos, que nos anunciasse ou dissesse de ti mal algum.',
        'No entanto bem quiséramos ouvir de ti o que sentes; porque, quanto a esta seita, notório nos é que em toda a parte se fala contra ela.',
        'E, havendo-lhe eles assinalado um dia, muitos foram ter com ele à pousada, aos quais declarava com bom testemunho o reino de Deus, e procurava persuadi-los à fé em Jesus, tanto pela lei de Moisés como pelos profetas, desde a manhã até à tarde.',
        'E alguns criam no que se dizia; mas outros não criam.',
        'E, como ficaram entre si discordes, despediram-se, dizendo Paulo esta palavra: Bem falou o Espírito Santo a nossos pais pelo profeta Isaías,',
        'Dizendo:Vai a este povo, e dize:De ouvido ouvireis, e de maneira nenhuma entendereis;E, vendo vereis, e de maneira nenhuma percebereis.',
        'Porquanto o coração deste povo está endurecido,e com os ouvidos ouviram pesadamente,e fecharam os olhos,para que nunca com os olhos vejam,Nem com os ouvidos ouçam,Nem do coração entendam,E se convertam,E eu os cure.',
        'Seja-vos, pois, notório que esta salvação de Deus é enviada aos gentios, e eles a ouvirão.',
        'E, havendo ele dito estas palavras, partiram os judeus, tendo entre si grande contenda.',
        'E Paulo ficou dois anos inteiros na sua própria habitação que alugara, e recebia todos quantos vinham vê-lo;',
        'Pregando o reino de Deus, e ensinando com toda a liberdade as coisas pertencentes ao Senhor Jesus Cristo, sem impedimento algum.',
      ],
    ],
    livro: 'atos',
  },
  {
    abbrev: 'rm',
    capitulos: [
      [
        'Paulo, servo de Jesus Cristo, chamado para apóstolo, separado para o evangelho de Deus.',
        'O qual antes prometeu pelos seus profetas nas santas escrituras,',
        'Acerca de seu Filho, que nasceu da descendência de Davi segundo a carne,',
        'Declarado Filho de Deus em poder, segundo o Espírito de santificação, pela ressurreição dentre os mortos, Jesus Cristo, nosso Senhor,',
        'Pelo qual recebemos a graça e o apostolado, para a obediência da fé entre todas as gentes pelo seu nome,',
        'Entre as quais sois também vós chamados para serdes de Jesus Cristo.',
        'A todos os que estais em Roma, amados de Deus, chamados santos: Graça e paz de Deus nosso Pai, e do Senhor Jesus Cristo.',
        'Primeiramente dou graças ao meu Deus por Jesus Cristo, acerca de vós todos, porque em todo o mundo é anunciada a vossa fé.',
        'Porque Deus, a quem sirvo em meu espírito, no evangelho de seu Filho, me é testemunha de como incessantemente faço menção de vós,',
        'Pedindo sempre em minhas orações que nalgum tempo, pela vontade de Deus, se me ofereça boa ocasião de ir ter convosco.',
        'Porque desejo ver-vos, para vos comunicar algum dom espiritual, a fim de que sejais confortados;',
        'Isto é, para que juntamente convosco eu seja consolado pela fé mútua, assim vossa como minha.',
        'Não quero, porém, irmãos, que ignoreis que muitas vezes propus ir ter convosco (mas até agora tenho sido impedido) para também ter entre vós algum fruto, como também entre os demais gentios.',
        'Eu sou devedor, tanto a gregos como a bárbaros, tanto a sábios como a ignorantes.',
        'E assim, quanto está em mim, estou pronto para também vos anunciar o evangelho, a vós que estais em Roma.',
        'Porque não me envergonho do evangelho de Cristo, pois é o poder de Deus para salvação de todo aquele que crê; primeiro do judeu, e também do grego.',
        'Porque nele se descobre a justiça de Deus de fé em fé, como está escrito: Mas o justo viverá pela fé.',
        'Porque do céu se manifesta a ira de Deus sobre toda a impiedade e injustiça dos homens, que detêm a verdade em injustiça.',
        'Porquanto o que de Deus se pode conhecer neles se manifesta, porque Deus lho manifestou.',
        'Porque as suas coisas invisíveis, desde a criação do mundo, tanto o seu eterno poder, como a sua divindade, se entendem, e claramente se vêem pelas coisas que estão criadas, para que eles fiquem inescusáveis;',
        'Porquanto, tendo conhecido a Deus, não o glorificaram como Deus, nem lhe deram graças, antes em seus discursos se desvaneceram, e o seu coração insensato se obscureceu.',
        'Dizendo-se sábios, tornaram-se loucos.',
        'E mudaram a glória do Deus incorruptível em semelhança da imagem de homem corruptível, e de aves, e de quadrúpedes, e de répteis.',
        'Por isso também Deus os entregou às concupiscências de seus corações, à imundícia, para desonrarem seus corpos entre si;',
        'Pois mudaram a verdade de Deus em mentira, e honraram e serviram mais a criatura do que o Criador, que é bendito eternamente. Amém.',
        'Por isso Deus os abandonou às paixões infames. Porque até as suas mulheres mudaram o uso natural, no contrário à natureza.',
        'E, semelhantemente, também os homens, deixando o uso natural da mulher, se inflamaram em sua sensualidade uns para com os outros, homens com homens, cometendo torpeza e recebendo em si mesmos a recompensa que convinha ao seu erro.',
        'E, como eles não se importaram de ter conhecimento de Deus, assim Deus os entregou a um sentimento perverso, para fazerem coisas que não convêm;',
        'Estando cheios de toda a iniqüidade, fornicação, malícia, avareza, maldade; cheios de inveja, homicídio, contenda, engano, malignidade;',
        'Sendo murmuradores, detratores, aborrecedores de Deus, injuriadores, soberbos, presunçosos, inventores de males, desobedientes aos pais e às mães;',
        'Néscios, infiéis nos contratos, sem afeição natural, irreconciliáveis, sem misericórdia;',
        'Os quais, conhecendo o juízo de Deus (que são dignos de morte os que tais coisas praticam), não somente as fazem, mas também consentem aos que as fazem.',
      ],
      [
        'Portanto, és inescusável quando julgas, ó homem, quem quer que sejas, porque te condenas a ti mesmo naquilo em que julgas a outro; pois tu, que julgas, fazes o mesmo.',
        'E bem sabemos que o juízo de Deus é segundo a verdade sobre os que tais coisas fazem.',
        'E tu, ó homem, que julgas os que fazem tais coisas, cuidas que, fazendo-as tu, escaparás ao juízo de Deus?',
        'Ou desprezas tu as riquezas da sua benignidade, e paciência e longanimidade, ignorando que a benignidade de Deus te leva ao arrependimento?',
        'Mas, segundo a tua dureza e teu coração impenitente, entesouras ira para ti no dia da ira e da manifestação do juízo de Deus;',
        'O qual recompensará cada um segundo as suas obras; a saber:',
        'A vida eterna aos que, com perseverança em fazer bem, procuram glória, honra e incorrupção;',
        'Mas a indignação e a ira aos que são contenciosos, desobedientes à verdade e obedientes à iniqüidade;',
        'Tribulação e angústia sobre toda a alma do homem que faz o mal; primeiramente do judeu e também do grego;',
        'Glória, porém, e honra e paz a qualquer que pratica o bem; primeiramente ao judeu e também ao grego;',
        'Porque, para com Deus, não há acepção de pessoas.',
        'Porque todos os que sem lei pecaram, sem lei também perecerão; e todos os que sob a lei pecaram, pela lei serão julgados.',
        'Porque os que ouvem a lei não são justos diante de Deus, mas os que praticam a lei hão de ser justificados.',
        'Porque, quando os gentios, que não têm lei, fazem naturalmente as coisas que são da lei, não tendo eles lei, para si mesmos são lei;',
        'Os quais mostram a obra da lei escrita em seus corações, testificando juntamente a sua consciência, e os seus pensamentos, quer acusando-os, quer defendendo-os;',
        'No dia em que Deus há de julgar os segredos dos homens, por Jesus Cristo, segundo o meu evangelho.',
        'Eis que tu que tens por sobrenome judeu, e repousas na lei, e te glorias em Deus;',
        'E sabes a sua vontade e aprovas as coisas excelentes, sendo instruído por lei;',
        'E confias que és guia dos cegos, luz dos que estão em trevas,',
        'Instruidor dos néscios, mestre de crianças, que tens a forma da ciência e da verdade na lei;',
        'Tu, pois, que ensinas a outro, não te ensinas a ti mesmo? Tu, que pregas que não se deve furtar, furtas?',
        'Tu, que dizes que não se deve adulterar, adulteras? Tu, que abominas os ídolos, cometes sacrilégio?',
        'Tu, que te glorias na lei, desonras a Deus pela transgressão da lei?',
        'Porque, como está escrito, o nome de Deus é blasfemado entre os gentios por causa de vós.',
        'Porque a circuncisão é, na verdade, proveitosa, se tu guardares a lei; mas, se tu és transgressor da lei, a tua circuncisão se torna em incircuncisão.',
        'Se, pois, a incircuncisão guardar os preceitos da lei, porventura a incircuncisão não será reputada como circuncisão?',
        'E a incircuncisão que por natureza o é, se cumpre a lei, não te julgará porventura a ti, que pela letra e circuncisão és transgressor da lei?',
        'Porque não é judeu o que o é exteriormente, nem é circuncisão a que o é exteriormente na carne.',
        'Mas é judeu o que o é no interior, e circuncisão a que é do coração, no espírito, não na letra; cujo louvor não provém dos homens, mas de Deus.',
      ],
      [
        'Qual é logo a vantagem do judeu? Ou qual a utilidade da circuncisão?',
        'Muita, em toda a maneira, porque, primeiramente, as palavras de Deus lhe foram confiadas.',
        'Pois quê? Se alguns foram incrédulos, a sua incredulidade aniquilará a fidelidade de Deus?',
        'De maneira nenhuma; sempre seja Deus verdadeiro, e todo o homem mentiroso; como está escrito:Para que sejas justificado em tuas palavras,E venças quando fores julgado.',
        'E, se a nossa injustiça for causa da justiça de Deus, que diremos? Porventura será Deus injusto, trazendo ira sobre nós? (Falo como homem. )',
        'De maneira nenhuma; de outro modo, como julgará Deus o mundo?',
        'Mas, se pela minha mentira abundou mais a verdade de Deus para glória sua, por que sou eu ainda julgado também como pecador?',
        'E por que não dizemos (como somos blasfemados, e como alguns dizem que dizemos): Façamos males, para que venham bens? A condenação desses é justa.',
        'Pois quê? Somos nós mais excelentes? De maneira nenhuma, pois já dantes demonstramos que, tanto judeus como gregos, todos estão debaixo do pecado;',
        'Como está escrito:Não há um justo, nem um sequer.',
        'Não há ninguém que entenda;Não há ninguém que busque a Deus.',
        'Todos se extraviaram, e juntamente se fizeram inúteis.Não há quem faça o bem, não há nem um só.',
        'A sua garganta é um sepulcro aberto;Com as suas línguas tratam enganosamente;Peçonha de áspides está debaixo de seus lábios;',
        'Cuja boca está cheia de maldição e amargura.',
        'Os seus pés são ligeiros para derramar sangue.',
        'Em seus caminhos há destruição e miséria;',
        'E não conheceram o caminho da paz.',
        'Não há temor de Deus diante de seus olhos.',
        'Ora, nós sabemos que tudo o que a lei diz, aos que estão debaixo da lei o diz, para que toda a boca esteja fechada e todo o mundo seja condenável diante de Deus.',
        'Por isso nenhuma carne será justificada diante dele pelas obras da lei, porque pela lei vem o conhecimento do pecado.',
        'Mas agora se manifestou sem a lei a justiça de Deus, tendo o testemunho da lei e dos profetas;',
        'Isto é, a justiça de Deus pela fé em Jesus Cristo para todos e sobre todos os que crêem; porque não há diferença.',
        'Porque todos pecaram e destituídos estão da glória de Deus;',
        'Sendo justificados gratuitamente pela sua graça, pela redenção que há em Cristo Jesus.',
        'Ao qual Deus propôs para propiciação pela fé no seu sangue, para demonstrar a sua justiça pela remissão dos pecados dantes cometidos, sob a paciência de Deus;',
        'Para demonstração da sua justiça neste tempo presente, para que ele seja justo e justificador daquele que tem fé em Jesus.',
        'Onde está logo a jactância? É excluída. Por qual lei? Das obras? Não; mas pela lei da fé.',
        'Concluímos, pois, que o homem é justificado pela fé sem as obras da lei.',
        'É porventura Deus somente dos judeus? E não o é também dos gentios? Também dos gentios, certamente,',
        'Visto que Deus é um só, que justifica pela fé a circuncisão, e por meio da fé a incircuncisão.',
        'Anulamos, pois, a lei pela fé? De maneira nenhuma, antes estabelecemos a lei.',
      ],
      [
        'Que diremos, pois, ter alcançado Abraão, nosso pai segundo a carne?',
        'Porque, se Abraão foi justificado pelas obras, tem de que se gloriar, mas não diante de Deus.',
        'Pois, que diz a Escritura? Creu Abraão a Deus, e isso lhe foi imputado como justiça.',
        'Ora, àquele que faz qualquer obra não lhe é imputado o galardão segundo a graça, mas segundo a dívida.',
        'Mas, àquele que não pratica, mas crê naquele que justifica o ímpio, a sua fé lhe é imputada como justiça.',
        'Assim também Davi declara bem-aventurado o homem a quem Deus imputa a justiça sem as obras, dizendo:',
        'Bem-aventurados aqueles cujas maldades são perdoadas,E cujos pecados são cobertos.',
        'Bem-aventurado o homem a quem o Senhor não imputa o pecado.',
        'Vem, pois, esta bem-aventurança sobre a circuncisão somente, ou também sobre a incircuncisão? Porque dizemos que a fé foi imputada como justiça a Abraão.',
        'Como lhe foi, pois, imputada? Estando na circuncisão ou na incircuncisão? Não na circuncisão, mas na incircuncisão.',
        'E recebeu o sinal da circuncisão, selo da justiça da fé quando estava na incircuncisão, para que fosse pai de todos os que crêem, estando eles também na incircuncisão; a fim de que também a justiça lhes seja imputada;',
        'E fosse pai da circuncisão, daqueles que não somente são da circuncisão, mas que também andam nas pisadas daquela fé que teve nosso pai Abraão, que tivera na incircuncisão.',
        'Porque a promessa de que havia de ser herdeiro do mundo não foi feita pela lei a Abraão, ou à sua posteridade, mas pela justiça da fé.',
        'Porque, se os que são da lei são herdeiros, logo a fé é vã e a promessa é aniquilada.',
        'Porque a lei opera a ira. Porque onde não há lei também não há transgressão.',
        'Portanto, é pela fé, para que seja segundo a graça, a fim de que a promessa seja firme a toda a posteridade, não somente à que é da lei, mas também à que é da fé que teve Abraão, o qual é pai de todos nós,',
        '(Como está escrito: Por pai de muitas nações te constituí) perante aquele no qual creu, a saber, Deus, o qual vivifica os mortos, e chama as coisas que não são como se já fossem.',
        'O qual, em esperança, creu contra a esperança, tanto que ele tornou-se pai de muitas nações, conforme o que lhe fora dito: Assim será a tua descendência.',
        'E não enfraquecendo na fé, nào atentou para o seu próprio corpo já amortecido, pois era já de quase cem anos, nem tampouco para o amortecimento do ventre de Sara.',
        'E não duvidou da promessa de Deus por incredulidade, mas foi fortificado na fé, dando glória a Deus,',
        'E estando certíssimo de que o que ele tinha prometido também era poderoso para o fazer.',
        'Assim isso lhe foi também imputado como justiça.',
        'Ora, não só por causa dele está escrito, que lhe fosse tomado em conta,',
        'Mas também por nós, a quem será tomado em conta, os que cremos naquele que dentre os mortos ressuscitou a Jesus nosso Senhor;',
        'O qual por nossos pecados foi entregue, e ressuscitou para nossa justificação.',
      ],
      [
        'Tendo sido, pois, justificados pela fé, temos paz com Deus, por nosso Senhor Jesus Cristo;',
        'Pelo qual também temos entrada pela fé a esta graça, na qual estamos firmes, e nos gloriamos na esperança da glória de Deus.',
        'E não somente isto, mas também nos gloriamos nas tribulações; sabendo que a tribulação produz a paciência,',
        'E a paciência a experiência, e a experiência a esperança.',
        'E a esperança não traz confusão, porquanto o amor de Deus está derramado em nossos corações pelo Espírito Santo que nos foi dado.',
        'Porque Cristo, estando nós ainda fracos, morreu a seu tempo pelos ímpios.',
        'Porque apenas alguém morrerá por um justo; pois poderá ser que pelo bom alguém ouse morrer.',
        'Mas Deus prova o seu amor para conosco, em que Cristo morreu por nós, sendo nós ainda pecadores.',
        'Logo muito mais agora, tendo sido justificados pelo seu sangue, seremos por ele salvos da ira.',
        'Porque se nós, sendo inimigos, fomos reconciliados com Deus pela morte de seu Filho, muito mais, tendo sido já reconciliados, seremos salvos pela sua vida.',
        'E não somente isto, mas também nos gloriamos em Deus por nosso Senhor Jesus Cristo, pelo qual agora alcançamos a reconciliação.',
        'Portanto, como por um homem entrou o pecado no mundo, e pelo pecado a morte, assim também a morte passou a todos os homens por isso que todos pecaram.',
        'Porque até à lei estava o pecado no mundo, mas o pecado não é imputado, não havendo lei.',
        'No entanto, a morte reinou desde Adão até Moisés, até sobre aqueles que não tinham pecado à semelhança da transgressão de Adão, o qual é a figura daquele que havia de vir.',
        'Mas não é assim o dom gratuito como a ofensa. Porque, se pela ofensa de um morreram muitos, muito mais a graça de Deus, e o dom pela graça, que é de um só homem, Jesus Cristo, abundou sobre muitos.',
        'E não foi assim o dom como a ofensa, por um só que pecou. Porque o juízo veio de uma só ofensa, na verdade, para condenação, mas o dom gratuito veio de muitas ofensas para justificação.',
        'Porque, se pela ofensa de um só, a morte reinou por esse, muito mais os que recebem a abundância da graça, e do dom da justiça, reinarão em vida por um só, Jesus Cristo.',
        'Pois assim como por uma só ofensa veio o juízo sobre todos os homens para condenação, assim também por um só ato de justiça veio a graça sobre todos os homens para justificação de vida.',
        'Porque, como pela desobediência de um só homem, muitos foram feitos pecadores, assim pela obediência de um muitos serão feitos justos.',
        'Veio, porém, a lei para que a ofensa abundasse; mas, onde o pecado abundou, superabundou a graça;',
        'Para que, assim como o pecado reinou na morte, também a graça reinasse pela justiça para a vida eterna, por Jesus Cristo nosso Senhor.',
      ],
      [
        'Que diremos pois? Permaneceremos no pecado, para que a graça abunde?',
        'De modo nenhum. Nós, que estamos mortos para o pecado, como viveremos ainda nele?',
        'Ou não sabeis que todos quantos fomos batizados em Jesus Cristo fomos batizados na sua morte?',
        'De sorte que fomos sepultados com ele pelo batismo na morte; para que, como Cristo foi ressuscitado dentre os mortos, pela glória do Pai, assim andemos nós também em novidade de vida.',
        'Porque, se fomos plantados juntamente com ele na semelhança da sua morte, também o seremos na da sua ressurreição;',
        'Sabendo isto, que o nosso homem velho foi com ele crucificado, para que o corpo do pecado seja desfeito, para que não sirvamos mais ao pecado.',
        'Porque aquele que está morto está justificado do pecado.',
        'Ora, se já morremos com Cristo, cremos que também com ele viveremos;',
        'Sabendo que, tendo sido Cristo ressuscitado dentre os mortos, já não morre; a morte não mais tem domínio sobre ele.',
        'Pois, quanto a ter morrido, de uma vez morreu para o pecado; mas, quanto a viver, vive para Deus.',
        'Assim também vós considerai-vos certamente mortos para o pecado, mas vivos para Deus em Cristo Jesus nosso Senhor.',
        'Não reine, portanto, o pecado em vosso corpo mortal, para lhe obedecerdes em suas concupiscências;',
        'Nem tampouco apresenteis os vossos membros ao pecado por instrumentos de iniqüidade; mas apresentai-vos a Deus, como vivos dentre mortos, e os vossos membros a Deus, como instrumentos de justiça.',
        'Porque o pecado não terá domínio sobre vós, pois não estais debaixo da lei, mas debaixo da graça.',
        'Pois que? Pecaremos porque não estamos debaixo da lei, mas debaixo da graça? De modo nenhum.',
        'Não sabeis vós que a quem vos apresentardes por servos para lhe obedecer, sois servos daquele a quem obedeceis, ou do pecado para a morte, ou da obediência para a justiça?',
        'Mas graças a Deus que, tendo sido servos do pecado, obedecestes de coração à forma de doutrina a que fostes entregues.',
        'E, libertados do pecado, fostes feitos servos da justiça.',
        'Falo como homem, pela fraqueza da vossa carne; pois que, assim como apresentastes os vossos membros para servirem à imundícia, e à maldade para maldade, assim apresentai agora os vossos membros para servirem à justiça para santificação.',
        'Porque, quando éreis servos do pecado, estáveis livres da justiça.',
        'E que fruto tínheis então das coisas de que agora vos envergonhais? Porque o fim delas é a morte.',
        'Mas agora, libertados do pecado, e feitos servos de Deus, tendes o vosso fruto para santificação, e por fim a vida eterna.',
        'Porque o salário do pecado é a morte, mas o dom gratuito de Deus é a vida eterna, por Cristo Jesus nosso Senhor.',
      ],
      [
        'Não sabeis vós, irmãos (pois que falo aos que sabem a lei), que a lei tem domínio sobre o homem por todo o tempo que vive?',
        'Porque a mulher que está sujeita ao marido, enquanto ele viver, está-lhe ligada pela lei; mas, morto o marido, está livre da lei do marido.',
        'De sorte que, vivendo o marido, será chamada adúltera se for de outro marido; mas, morto o marido, livre está da lei, e assim não será adúltera, se for de outro marido.',
        'Assim, meus irmãos, também vós estais mortos para a lei pelo corpo de Cristo, para que sejais de outro, daquele que ressuscitou dentre os mortos, a fim de que demos fruto para Deus.',
        'Porque, quando estávamos na carne, as paixões dos pecados, que são pela lei, operavam em nossos membros para darem fruto para a morte.',
        'Mas agora temos sido libertados da lei, tendo morrido para aquilo em que estávamos retidos; para que sirvamos em novidade de espírito, e não na velhice da letra.',
        'Que diremos pois? É a lei pecado? De modo nenhum. Mas eu não conheci o pecado senão pela lei; porque eu não conheceria a concupiscência, se a lei não dissesse: Não cobiçarás.',
        'Mas o pecado, tomando ocasião pelo mandamento, operou em mim toda a concupiscência; porquanto sem a lei estava morto o pecado.',
        'E eu, nalgum tempo, vivia sem lei, mas, vindo o mandamento, reviveu o pecado, e eu morri.',
        'E o mandamento que era para vida, achei eu que me era para morte.',
        'Porque o pecado, tomando ocasião pelo mandamento, me enganou, e por ele me matou.',
        'E assim a lei é santa, e o mandamento santo, justo e bom.',
        'Logo tornou-se-me o bom em morte? De modo nenhum; mas o pecado, para que se mostrasse pecado, operou em mim a morte pelo bem; a fim de que pelo mandamento o pecado se fizesse excessivamente maligno.',
        'Porque bem sabemos que a lei é espiritual; mas eu sou carnal, vendido sob o pecado.',
        'Porque o que faço não o aprovo; pois o que quero isso não faço, mas o que aborreço isso faço.',
        'E, se faço o que não quero, consinto com a lei, que é boa.',
        'De maneira que agora já não sou eu que faço isto, mas o pecado que habita em mim.',
        'Porque eu sei que em mim, isto é, na minha carne, não habita bem algum; e com efeito o querer está em mim, mas não consigo realizar o bem.',
        'Porque não faço o bem que quero, mas o mal que não quero esse faço.',
        'Ora, se eu faço o que não quero, já o não faço eu, mas o pecado que habita em mim.',
        'Acho então esta lei em mim, que, quando quero fazer o bem, o mal está comigo.',
        'Porque, segundo o homem interior, tenho prazer na lei de Deus;',
        'Mas vejo nos meus membros outra lei, que batalha contra a lei do meu entendimento, e me prende debaixo da lei do pecado que está nos meus membros.',
        'Miserável homem que eu sou! quem me livrará do corpo desta morte?',
        'Dou graças a Deus por Jesus Cristo nosso Senhor. Assim que eu mesmo com o entendimento sirvo à lei de Deus, mas com a carne à lei do pecado.',
      ],
      [
        'Portanto, agora nenhuma condenação há para os que estão em Cristo Jesus, que não andam segundo a carne, mas segundo o Espírito.',
        'Porque a lei do Espírito de vida, em Cristo Jesus, me livrou da lei do pecado e da morte.',
        'Porquanto o que era impossível à lei, visto como estava enferma pela carne, Deus, enviando o seu Filho em semelhança da carne do pecado, pelo pecado condenou o pecado na carne;',
        'Para que a justiça da lei se cumprisse em nós, que não andamos segundo a carne, mas segundo o Espírito.',
        'Porque os que são segundo a carne inclinam-se para as coisas da carne; mas os que são segundo o Espírito para as coisas do Espírito.',
        'Porque a inclinação da carne é morte; mas a inclinação do Espírito é vida e paz.',
        'Porquanto a inclinação da carne é inimizade contra Deus, pois não é sujeita à lei de Deus, nem, em verdade, o pode ser.',
        'Portanto, os que estão na carne não podem agradar a Deus.',
        'Vós, porém, não estais na carne, mas no Espírito, se é que o Espírito de Deus habita em vós. Mas, se alguém não tem o Espírito de Cristo, esse tal não é dele.',
        'E, se Cristo está em vós, o corpo, na verdade, está morto por causa do pecado, mas o espírito vive por causa da justiça.',
        'E, se o Espírito daquele que dentre os mortos ressuscitou a Jesus habita em vós, aquele que dentre os mortos ressuscitou a Cristo também vivificará os vossos corpos mortais, pelo seu Espírito que em vós habita.',
        'De maneira que, irmãos, somos devedores, não à carne para viver segundo a carne.',
        'Porque, se viverdes segundo a carne, morrereis; mas, se pelo Espírito mortificardes as obras do corpo, vivereis.',
        'Porque todos os que são guiados pelo Espírito de Deus esses são filhos de Deus.',
        'Porque não recebestes o espírito de escravidão, para outra vez estardes em temor, mas recebestes o Espírito de adoção de filhos, pelo qual clamamos: Aba, Pai.',
        'O mesmo Espírito testifica com o nosso espírito que somos filhos de Deus.',
        'E, se nós somos filhos, somos logo herdeiros também, herdeiros de Deus, e co-herdeiros de Cristo: se é certo que com ele padecemos, para que também com ele sejamos glorificados.',
        'Porque para mim tenho por certo que as aflições deste tempo presente não são para comparar com a glória que em nós há de ser revelada.',
        'Porque a ardente expectação da criatura espera a manifestação dos filhos de Deus.',
        'Porque a criação ficou sujeita à vaidade, não por sua vontade, mas por causa do que a sujeitou,',
        'Na esperança de que também a mesma criatura será libertada da servidão da corrupção, para a liberdade da glória dos filhos de Deus.',
        'Porque sabemos que toda a criação geme e está juntamente com dores de parto até agora.',
        'E não só ela, mas nós mesmos, que temos as primícias do Espírito, também gememos em nós mesmos, esperando a adoção, a saber, a redenção do nosso corpo.',
        'Porque em esperança fomos salvos. Ora a esperança que se vê não é esperança; porque o que alguém vê como o esperará?',
        'Mas, se esperamos o que não vemos, com paciência o esperamos.',
        'E da mesma maneira também o Espírito ajuda as nossas fraquezas; porque não sabemos o que havemos de pedir como convém, mas o mesmo Espírito intercede por nós com gemidos inexprimíveis.',
        'E aquele que examina os corações sabe qual é a intenção do Espírito; e é ele que segundo Deus intercede pelos santos.',
        'E sabemos que todas as coisas contribuem juntamente para o bem daqueles que amam a Deus, daqueles que são chamados segundo o seu propósito.',
        'Porque os que dantes conheceu também os predestinou para serem conformes à imagem de seu Filho, a fim de que ele seja o primogênito entre muitos irmãos.',
        'E aos que predestinou a estes também chamou; e aos que chamou a estes também justificou; e aos que justificou a estes também glorificou.',
        'Que diremos, pois, a estas coisas? Se Deus é por nós, quem será contra nós?',
        'Aquele que nem mesmo a seu próprio Filho poupou, antes o entregou por todos nós, como nos não dará também com ele todas as coisas?',
        'Quem intentará acusação contra os escolhidos de Deus? É Deus quem os justifica.',
        'Quem é que condena? Pois é Cristo quem morreu, ou antes quem ressuscitou dentre os mortos, o qual está à direita de Deus, e também intercede por nós.',
        'Quem nos separará do amor de Cristo? A tribulação, ou a angústia, ou a perseguição, ou a fome, ou a nudez, ou o perigo, ou a espada?',
        'Como está escrito:Por amor de ti somos entregues à morte todo o dia;Somos reputados como ovelhas para o matadouro.',
        'Mas em todas estas coisas somos mais do que vencedores, por aquele que nos amou.',
        'Porque estou certo de que, nem a morte, nem a vida, nem os anjos, nem os principados, nem as potestades, nem o presente, nem o porvir,',
        'Nem a altura, nem a profundidade, nem alguma outra criatura nos poderá separar do amor de Deus, que está em Cristo Jesus nosso Senhor.',
      ],
      [
        'Em Cristo digo a verdade, não minto (dando-me testemunho a minha consciência no Espírito Santo):',
        'Que tenho grande tristeza e contínua dor no meu coração.',
        'Porque eu mesmo poderia desejar ser anátema de Cristo, por amor de meus irmãos, que são meus parentes segundo a carne;',
        'Que são israelitas, dos quais é a adoção de filhos, e a glória, e as alianças, e a lei, e o culto, e as promessas;',
        'Dos quais são os pais, e dos quais é Cristo segundo a carne, o qual é sobre todos, Deus bendito eternamente. Amém.',
        'Não que a palavra de Deus haja faltado, porque nem todos os que são de Israel são israelitas;',
        'Nem por serem descendência de Abraão são todos filhos; mas: Em Isaque será chamada a tua descendência.',
        'Isto é, não são os filhos da carne que são filhos de Deus, mas os filhos da promessa são contados como descendência.',
        'Porque a palavra da promessa é esta: Por este tempo virei, e Sara terá um filho.',
        'E não somente esta, mas também Rebeca, quando concebeu de um, de Isaque, nosso pai;',
        'Porque, não tendo eles ainda nascido, nem tendo feito bem ou mal (para que o propósito de Deus, segundo a eleição, ficasse firme, não por causa das obras, mas por aquele que chama),',
        'Foi-lhe dito a ela: O maior servirá ao menor.',
        'Como está escrito: Amei a Jacó, e odiei a Esaú.',
        'Que diremos pois? que há injustiça da parte de Deus? De maneira nenhuma.',
        'Pois diz a Moisés: Compadecer-me-ei de quem me compadecer, e terei misericórdia de quem eu tiver misericórdia.',
        'Assim, pois, isto não depende do que quer, nem do que corre, mas de Deus, que se compadece.',
        'Porque diz a Escritura a Faraó: Para isto mesmo te levantei; para em ti mostrar o meu poder, e para que o meu nome seja anunciado em toda a terra.',
        'Logo, pois, compadece-se de quem quer, e endurece a quem quer.',
        'Dir-me-ás então: Por que se queixa ele ainda? Porquanto, quem tem resistido à sua vontade?',
        'Mas, ó homem, quem és tu, que a Deus replicas? Porventura a coisa formada dirá ao que a formou: Por que me fizeste assim?',
        'Ou não tem o oleiro poder sobre o barro, para da mesma massa fazer um vaso para honra e outro para desonra?',
        'E que direis se Deus, querendo mostrar a sua ira, e dar a conhecer o seu poder, suportou com muita paciência os vasos da ira, preparados para a perdição;',
        'Para que também desse a conhecer as riquezas da sua glória nos vasos de misericórdia, que para glória já dantes preparou,',
        'Os quais somos nós, a quem também chamou, não só dentre os judeus, mas também dentre os gentios?',
        'Como também diz em Oséias:Chamarei meu povo ao que não era meu povo;E amada à que não era amada.',
        'E sucederá que no lugar em que lhes foi dito:Vós não sois meu povo;Aí serão chamados filhos do Deus vivo.',
        'Também Isaías clama acerca de Israel: Ainda que o número dos filhos de Israel seja como a areia do mar, o remanescente é que será salvo.',
        'Porque ele completará a obra e abreviá-la-á em justiça; porque o Senhor fará breve a obra sobre a terra.',
        'E como antes disse Isaías:Se o Senhor dos Exércitos nos não deixara descendência,Teríamos nos tornado como Sodoma, e teríamos sido feitos como Gomorra.',
        'Que diremos pois? Que os gentios, que não buscavam a justiça, alcançaram a justiça? Sim, mas a justiça que é pela fé.',
        'Mas Israel, que buscava a lei da justiça, não chegou à lei da justiça.',
        'Por quê? Porque não foi pela fé, mas como que pelas obras da lei; pois tropeçaram na pedra de tropeço;',
        'Como está escrito:Eis que eu ponho em Sião uma pedra de tropeço, e uma rocha de escândalo;E todo aquele que crer nela não será confundido.',
      ],
      [
        'Irmãos, o bom desejo do meu coração e a oração a Deus por Israel é para sua salvação.',
        'Porque lhes dou testemunho de que têm zelo de Deus, mas não com entendimento.',
        'Porquanto, não conhecendo a justiça de Deus, e procurando estabelecer a sua própria justiça, não se sujeitaram à justiça de Deus.',
        'Porque o fim da lei é Cristo para justiça de todo aquele que crê.',
        'Ora, Moisés descreve a justiça que é pela lei, dizendo: O homem que fizer estas coisas viverá por elas.',
        'Mas a justiça que é pela fé diz assim: Não digas em teu coração: Quem subirá ao céu? (isto é, a trazer do alto a Cristo. )',
        'Ou: Quem descerá ao abismo? (isto é, a tornar a trazer dentre os mortos a Cristo. )',
        'Mas que diz? A palavra está junto de ti, na tua boca e no teu coração; esta é a palavra da fé, que pregamos,',
        'A saber: Se com a tua boca confessares ao Senhor Jesus, e em teu coração creres que Deus o ressuscitou dentre os mortos, serás salvo.',
        'Visto que com o coração se crê para a justiça, e com a boca se faz confissão para a salvação.',
        'Porque a Escritura diz: Todo aquele que nele crer não será confundido.',
        'Porquanto não há diferença entre judeu e grego; porque um mesmo é o Senhor de todos, rico para com todos os que o invocam.',
        'Porque todo aquele que invocar o nome do Senhor será salvo.',
        'Como, pois, invocarão aquele em quem não creram? e como crerão naquele de quem não ouviram? e como ouvirão, se não há quem pregue?',
        'E como pregarão, se não forem enviados? como está escrito: Quão formosos os pés dos que anunciam o evangelho de paz; dos que trazem alegres novas de boas coisas.',
        'Mas nem todos têm obedecido ao evangelho; pois Isaías diz: Senhor, quem creu na nossa pregação?',
        'De sorte que a fé é pelo ouvir, e o ouvir pela palavra de Deus.',
        'Mas digo: Porventura não ouviram? Sim, por certo, pois Por toda a terra saiu a voz deles,E as suas palavras até aos confins do mundo.',
        'Mas digo: Porventura Israel não o soube? Primeiramente diz Moisés:Eu vos porei em ciúmes com aqueles que não são povo,Com gente insensata vos provocarei à ira.',
        'E Isaías ousadamente diz:Fui achado pelos que não me buscavam,Fui manifestado aos que por mim não perguntavam.',
        'Mas para Israel diz:Todo o dia estendi as minhas mãos a um povo rebelde e contradizente.',
      ],
      [
        'Digo, pois: Porventura rejeitou Deus o seu povo? De modo nenhum; porque também eu sou israelita, da descendência de Abraão, da tribo de Benjamim.',
        'Deus não rejeitou o seu povo, que antes conheceu. Ou não sabeis o que a Escritura diz de Elias, como fala a Deus contra Israel, dizendo:',
        'Senhor, mataram os teus profetas, e derribaram os teus altares; e só eu fiquei, e buscam a minha alma?',
        'Mas que lhe diz a resposta divina? Reservei para mim sete mil homens, que não dobraram os joelhos a Baal.',
        'Assim, pois, também agora neste tempo ficou um remanescente, segundo a eleição da graça.',
        'Mas se é por graça, já não é pelas obras; de outra maneira, a graça já não é graça. Se, porém, é pelas obras, já não é mais graça; de outra maneira a obra já não é obra.',
        'Pois quê? O que Israel buscava não o alcançou; mas os eleitos o alcançaram, e os outros foram endurecidos.',
        'Como está escrito: Deus lhes deu espírito de profundo sono, olhos para não verem, e ouvidos para não ouvirem, até ao dia de hoje.',
        'E Davi diz: Torne-se-lhes a sua mesa em laço, e em armadilha, E em tropeço, por sua retribuição;',
        'Escureçam-se-lhes os olhos para não verem, E encurvem-se-lhes continuamente as costas.',
        'Digo, pois: Porventura tropeçaram, para que caíssem? De modo nenhum, mas pela sua queda veio a salvação aos gentios, para os incitar à emulação.',
        'E se a sua queda é a riqueza do mundo, e a sua diminuição a riqueza dos gentios, quanto mais a sua plenitude!',
        'Porque convosco falo, gentios, que, enquanto for apóstolo dos gentios, exalto o meu ministério;',
        'Para ver se de alguma maneira posso incitar à emulação os da minha carne e salvar alguns deles.',
        'Porque, se a sua rejeição é a reconciliação do mundo, qual será a sua admissão, senão a vida dentre os mortos?',
        'E, se as primícias são santas, também a massa o é; se a raiz é santa, também os ramos o são.',
        'E se alguns dos ramos foram quebrados, e tu, sendo zambujeiro, foste enxertado em lugar deles, e feito participante da raiz e da seiva da oliveira,',
        'Não te glories contra os ramos; e, se contra eles te gloriares, não és tu que sustentas a raiz, mas a raiz a ti.',
        'Dirás, pois: Os ramos foram quebrados, para que eu fosse enxertado.',
        'Está bem; pela sua incredulidade foram quebrados, e tu estás em pé pela fé. Então não te ensoberbeças, mas teme.',
        'Porque, se Deus não poupou os ramos naturais, teme que não te poupe a ti também.',
        'Considera, pois, a bondade e a severidade de Deus: para com os que caíram, severidade; mas para contigo, benignidade, se permaneceres na sua benignidade; de outra maneira também tu serás cortado.',
        'E também eles, se não permanecerem na incredulidade, serão enxertados; porque poderoso é Deus para os tornar a enxertar.',
        'Porque, se tu foste cortado do natural zambujeiro e, contra a natureza, enxertado na boa oliveira, quanto mais esses, que são naturais, serão enxertados na sua própria oliveira!',
        'Porque não quero, irmãos, que ignoreis este segredo (para que não presumais de vós mesmos): que o endurecimento veio em parte sobre Israel, até que a plenitude dos gentios haja entrado.',
        'E assim todo o Israel será salvo, como está escrito: De Sião virá o Libertador, E desviará de Jacó as impiedades.',
        'E esta será a minha aliança com eles, Quando eu tirar os seus pecados.',
        'Assim que, quanto ao evangelho, são inimigos por causa de vós; mas, quanto à eleição, amados por causa dos pais.',
        'Porque os dons e a vocação de Deus são sem arrependimento.',
        'Porque assim como vós também antigamente fostes desobedientes a Deus, mas agora alcançastes misericórdia pela desobediência deles,',
        'Assim também estes agora foram desobedientes, para também alcançarem misericórdia pela misericórdia a vós demonstrada.',
        'Porque Deus encerrou a todos debaixo da desobediência, para com todos usar de misericórdia.',
        'Ó profundidade das riquezas, tanto da sabedoria, como da ciência de Deus! Quão insondáveis são os seus juízos, e quão inescrutáveis os seus caminhos!',
        'Por que quem compreendeu a mente do Senhor? ou quem foi seu conselheiro?',
        'Ou quem lhe deu primeiro a ele, para que lhe seja recompensado?',
        'Porque dele e por ele, e para ele, são todas as coisas; glória, pois, a ele eternamente. Amém.',
      ],
      [
        'Rogo-vos, pois, irmãos, pela compaixão de Deus, que apresenteis os vossos corpos em sacrifício vivo, santo e agradável a Deus, que é o vosso culto racional.',
        'E não sede conformados com este mundo, mas sede transformados pela renovação do vosso entendimento, para que experimenteis qual seja a boa, agradável, e perfeita vontade de Deus.',
        'Porque pela graça que me é dada, digo a cada um dentre vós que não pense de si mesmo além do que convém; antes, pense com moderação, conforme a medida da fé que Deus repartiu a cada um.',
        'Porque assim como em um corpo temos muitos membros, e nem todos os membros têm a mesma operação,',
        'Assim nós, que somos muitos, somos um só corpo em Cristo, mas individualmente somos membros uns dos outros.',
        'De modo que, tendo diferentes dons, segundo a graça que nos é dada, se é profecia, seja ela segundo a medida da fé;',
        'Se é ministério, seja em ministrar; se é ensinar, haja dedicação ao ensino;',
        'Ou o que exorta, use esse dom em exortar; o que reparte, faça-o com liberalidade; o que preside, com cuidado; o que exercita misericórdia, com alegria.',
        'O amor seja não fingido. Aborrecei o mal e apegai-vos ao bem.',
        'Amai-vos cordialmente uns aos outros com amor fraternal, preferindo-vos em honra uns aos outros.',
        'Não sejais vagarosos no cuidado; sede fervorosos no espírito, servindo ao Senhor;',
        'Alegrai-vos na esperança, sede pacientes na tribulação, perseverai na oração;',
        'Comunicai com os santos nas suas necessidades, segui a hospitalidade;',
        'Abençoai aos que vos perseguem, abençoai, e não amaldiçoeis.',
        'Alegrai-vos com os que se alegram; e chorai com os que choram;',
        'Sede unânimes entre vós; não ambicioneis coisas altas, mas acomodai-vos às humildes; não sejais sábios em vós mesmos;',
        'A ninguém torneis mal por mal; procurai as coisas honestas, perante todos os homens.',
        'Se for possível, quanto estiver em vós, tende paz com todos os homens.',
        'Não vos vingueis a vós mesmos, amados, mas dai lugar à ira, porque está escrito: Minha é a vingança; eu recompensarei, diz o Senhor.',
        'Portanto, se o teu inimigo tiver fome, dá-lhe de comer; se tiver sede, dá-lhe de beber; porque, fazendo isto, amontoarás brasas de fogo sobre a sua cabeça.',
        'Não te deixes vencer do mal, mas vence o mal com o bem.',
      ],
      [
        'Toda a alma esteja sujeita às potestades superiores; porque não há potestade que não venha de Deus; e as potestades que há foram ordenadas por Deus.',
        'Por isso quem resiste à potestade resiste à ordenação de Deus; e os que resistem trarão sobre si mesmos a condenação.',
        'Porque os magistrados não são terror para as boas obras, mas para as más. Queres tu, pois, não temer a potestade? Faze o bem, e terás louvor dela.',
        'Porque ela é ministro de Deus para teu bem. Mas, se fizeres o mal, teme, pois não traz debalde a espada; porque é ministro de Deus, e vingador para castigar o que faz o mal.',
        'Portanto é necessário que lhe estejais sujeitos, não somente pelo castigo, mas também pela consciência.',
        'Por esta razão também pagais tributos, porque são ministros de Deus, atendendo sempre a isto mesmo.',
        'Portanto, dai a cada um o que deveis: a quem tributo, tributo; a quem imposto, imposto; a quem temor, temor; a quem honra, honra.',
        'A ninguém devais coisa alguma, a não ser o amor com que vos ameis uns aos outros; porque quem ama aos outros cumpriu a lei.',
        'Com efeito: Não adulterarás, não matarás, não furtarás, não darás falso testemunho, não cobiçarás; e se há algum outro mandamento, tudo nesta palavra se resume: Amarás ao teu próximo como a ti mesmo.',
        'O amor não faz mal ao próximo. De sorte que o cumprimento da lei é o amor.',
        'E isto digo, conhecendo o tempo, que já é hora de despertarmos do sono; porque a nossa salvação está agora mais perto de nós do que quando aceitamos a fé.',
        'A noite é passada, e o dia é chegado. Rejeitemos, pois, as obras das trevas, e vistamo-nos das armas da luz.',
        'Andemos honestamente, como de dia; não em glutonarias, nem em bebedeiras, nem em desonestidades, nem em dissoluções, nem em contendas e inveja.',
        'Mas revesti-vos do Senhor Jesus Cristo, e não tenhais cuidado da carne em suas concupiscências.',
      ],
      [
        'Ora, quanto ao que está enfermo na fé, recebei-o, não em contendas sobre dúvidas.',
        'Porque um crê que de tudo se pode comer, e outro, que é fraco, come legumes.',
        'O que come não despreze o que não come; e o que não come, não julgue o que come; porque Deus o recebeu por seu.',
        'Quem és tu, que julgas o servo alheio? Para seu próprio senhor ele está em pé ou cai. Mas estará firme, porque poderoso é Deus para o firmar.',
        'Um faz diferença entre dia e dia, mas outro julga iguais todos os dias. Cada um esteja inteiramente seguro em sua própria mente.',
        'Aquele que faz caso do dia, para o Senhor o faz e o que não faz caso do dia para o Senhor o não faz. O que come, para o Senhor come, porque dá graças a Deus; e o que não come, para o Senhor não come, e dá graças a Deus.',
        'Porque nenhum de nós vive para si, e nenhum morre para si.',
        'Porque, se vivemos, para o Senhor vivemos; se morremos, para o Senhor morremos. De sorte que, ou vivamos ou morramos, somos do Senhor.',
        'Porque foi para isto que morreu Cristo, e ressurgiu, e tornou a viver, para ser Senhor, tanto dos mortos, como dos vivos.',
        'Mas tu, por que julgas teu irmão? Ou tu, também, por que desprezas teu irmão? Pois todos havemos de comparecer ante o tribunal de Cristo.',
        'Porque está escrito:Como eu vivo, diz o Senhor, que todo o joelho se dobrará a mim,E toda a língua confessará a Deus.',
        'De maneira que cada um de nós dará conta de si mesmo a Deus.',
        'Assim que não nos julguemos mais uns aos outros; antes seja o vosso propósito não pôr tropeço ou escândalo ao irmão.',
        'Eu sei, e estou certo no Senhor Jesus, que nenhuma coisa é de si mesma imunda, a não ser para aquele que a tem por imunda; para esse é imunda.',
        'Mas, se por causa da comida se contrista teu irmão, já não andas conforme o amor. Não destruas por causa da tua comida aquele por quem Cristo morreu.',
        'Não seja, pois, blasfemado o vosso bem;',
        'Porque o reino de Deus não é comida nem bebida, mas justiça, e paz, e alegria no Espírito Santo.',
        'Porque quem nisto serve a Cristo agradável é a Deus e aceito aos homens.',
        'Sigamos, pois, as coisas que servem para a paz e para a edificação de uns para com os outros.',
        'Não destruas por causa da comida a obra de Deus. É verdade que tudo é limpo, mas mal vai para o homem que come com escândalo.',
        'Bom é não comer carne, nem beber vinho, nem fazer outras coisas em que teu irmão tropece, ou se escandalize, ou se enfraqueça.',
        'Tens tu fé? Tem-na em ti mesmo diante de Deus. Bem-aventurado aquele que não se condena a si mesmo naquilo que aprova.',
        'Mas aquele que tem dúvidas, se come está condenado, porque não come por fé; e tudo o que não é de fé é pecado.',
      ],
      [
        'Mas nós, que somos fortes, devemos suportar as fraquezas dos fracos, e não agradar a nós mesmos.',
        'Portanto cada um de nós agrade ao seu próximo no que é bom para edificação.',
        'Porque também Cristo não agradou a si mesmo, mas, como está escrito: Sobre mim caíram as injúrias dos que te injuriavam.',
        'Porque tudo o que dantes foi escrito, para nosso ensino foi escrito, para que pela paciência e consolação das Escrituras tenhamos esperança.',
        'Ora, o Deus de paciência e consolação vos conceda o mesmo sentimento uns para com os outros, segundo Cristo Jesus,',
        'Para que concordes, a uma boca, glorifiqueis ao Deus e Pai de nosso Senhor Jesus Cristo.',
        'Portanto recebei-vos uns aos outros, como também Cristo nos recebeu para glória de Deus.',
        'Digo, pois, que Jesus Cristo foi ministro da circuncisão, por causa da verdade de Deus, para que confirmasse as promessas feitas aos pais;',
        'E para que os gentios glorifiquem a Deus pela sua misericórdia, como está escrito:Portanto eu te louvarei entre os gentios,E cantarei ao teu nome.',
        'E outra vez diz:Alegrai-vos, gentios, com o seu povo.',
        'E outra vez:Louvai ao Senhor, todos os gentios,E celebrai-o todos os povos.',
        'Outra vez diz Isaías:Uma raiz em Jessé haverá,E naquele que se levantar para reger os gentios,Os gentios esperarão.',
        'Ora o Deus de esperança vos encha de todo o gozo e paz em crença, para que abundeis em esperança pela virtude do Espírito Santo.',
        'Eu próprio, meus irmãos, certo estou, a respeito de vós, que vós mesmos estais cheios de bondade, cheios de todo o conhecimento, podendo admoestar-vos uns aos outros.',
        'Mas, irmãos, em parte vos escrevi mais ousadamente, como para vos trazer outra vez isto à memória, pela graça que por Deus me foi dada;',
        'Que seja ministro de Jesus Cristo para os gentios, ministrando o evangelho de Deus, para que seja agradável a oferta dos gentios, santificada pelo Espírito Santo.',
        'De sorte que tenho glória em Jesus Cristo nas coisas que pertencem a Deus.',
        'Porque não ousarei dizer coisa alguma, que Cristo por mim não tenha feito, para fazer obedientes os gentios, por palavra e por obras;',
        'Pelo poder dos sinais e prodígios, e pela virtude do Espírito de Deus; de maneira que desde Jerusalém, e arredores, até ao Ilírico, tenho pregado o evangelho de Jesus Cristo.',
        'E desta maneira me esforcei por anunciar o evangelho, não onde Cristo foi nomeado, para não edificar sobre fundamento alheio;',
        'Antes, como está escrito:Aqueles a quem não foi anunciado, o verão,E os que não ouviram o entenderão.',
        'Por isso também muitas vezes tenho sido impedido de ir ter convosco.',
        'Mas agora, que não tenho mais demora nestes sítios, e tendo já há muitos anos grande desejo de ir ter convosco,',
        'Quando partir para Espanha irei ter convosco; pois espero que de passagem vos verei, e que para lá seja encaminhado por vós, depois de ter gozado um pouco da vossa companhia.',
        'Mas agora vou a Jerusalém para ministrar aos santos.',
        'Porque pareceu bem à macedônia e à Acaia fazerem uma coleta para os pobres dentre os santos que estão em Jerusalém.',
        'Isto lhes pareceu bem, como devedores que são para com eles. Porque, se os gentios foram participantes dos seus bens espirituais, devem também ministrar-lhes os temporais.',
        'Assim que, concluído isto, e havendo-lhes consignado este fruto, de lá, passando por vós, irei à Espanha.',
        'E bem sei que, indo ter convosco, chegarei com a plenitude da bênção do evangelho de Cristo.',
        'E rogo-vos, irmãos, por nosso Senhor Jesus Cristo e pelo amor do Espírito, que combatais comigo nas vossas orações por mim a Deus;',
        'Para que seja livre dos rebeldes que estão na Judéia, e que esta minha administração, que em Jerusalém faço, seja bem aceita pelos santos;',
        'A fim de que, pela vontade de Deus, chegue a vós com alegria, e possa recrear-me convosco.',
        'E o Deus de paz seja com todos vós. Amém.',
      ],
      [
        'Recomendo-vos, pois, Febe, nossa irmã, a qual serve na igreja que está em Cencréia,',
        'Para que a recebais no Senhor, como convém aos santos, e a ajudeis em qualquer coisa que de vós necessitar; porque tem hospedado a muitos, como também a mim mesmo.',
        'Saudai a Priscila e a Áqüila, meus cooperadores em Cristo Jesus,',
        'Os quais pela minha vida expuseram as suas cabeças; o que não só eu lhes agradeço, mas também todas as igrejas dos gentios.',
        'Saudai também a igreja que está em sua casa. Saudai a Epêneto, meu amado, que é as primícias da Acáia em Cristo.',
        'Saudai a Maria, que trabalhou muito por nós.',
        'Saudai a Andrônico e a Júnias, meus parentes e meus companheiros na prisão, os quais se distinguiram entre os apóstolos e que foram antes de mim em Cristo.',
        'Saudai a Amplias, meu amado no Senhor.',
        'Saudai a Urbano, nosso cooperador em Cristo, e a Estáquis, meu amado.',
        'Saudai a Apeles, aprovado em Cristo. Saudai aos da família de Aristóbulo.',
        'Saudai a Herodião, meu parente. Saudai aos da família de Narciso, os que estão no Senhor.',
        'Saudai a Trifena e a Trifosa, as quais trabalham no Senhor. Saudai à amada Pérside, a qual muito trabalhou no Senhor.',
        'Saudai a Rufo, eleito no Senhor, e a sua mãe e minha.',
        'Saudai a Asíncrito, a Flegonte, a Hermes, a Pátrobas, a Hermas, e aos irmãos que estão com eles.',
        'Saudai a Filólogo e a Júlia, a Nereu e a sua irmã, e a Olimpas, e a todos os santos que com eles estão.',
        'Saudai-vos uns aos outros com santo ósculo. As igrejas de Cristo vos saúdam.',
        'E rogo-vos, irmãos, que noteis os que promovem dissensões e escândalos contra a doutrina que aprendestes; desviai-vos deles.',
        'Porque os tais não servem a nosso Senhor Jesus Cristo, mas ao seu ventre; e com suaves palavras e lisonjas enganam os corações dos simples.',
        'Quanto à vossa obediência, é ela conhecida de todos. Comprazo-me, pois, em vós; e quero que sejais sábios no bem, mas simples no mal.',
        'E o Deus de paz esmagará em breve Satanás debaixo dos vossos pés. A graça de nosso Senhor Jesus Cristo seja convosco. Amém.',
        'Saúdam-vos Timóteo, meu cooperador, e Lúcio, Jasom e Sosípatro, meus parentes.',
        'Eu, Tércio, que esta carta escrevi, vos saúdo no Senhor.',
        'Saúda-vos Gaio, meu hospedeiro, e de toda a igreja. Saúda-vos Erasto, procurador da cidade, e também o irmão Quarto.',
        'A graça de nosso Senhor Jesus Cristo seja com todos vós. Amém.',
        'Ora, àquele que é poderoso para vos confirmar segundo o meu evangelho e a pregação de Jesus Cristo, conforme a revelação do mistério que desde tempos eternos esteve oculto,',
        'Mas que se manifestou agora, e se notificou pelas Escrituras dos profetas, segundo o mandamento do Deus eterno, a todas as nações para obediência da fé;',
        'Ao único Deus, sábio, seja dada glória por Jesus Cristo para todo o sempre. Amém.',
      ],
    ],
    livro: 'romanos',
  },
  {
    abbrev: '1co',
    capitulos: [
      [
        'Paulo (chamado apóstolo de Jesus Cristo, pela vontade de Deus), e o irmão Sóstenes,',
        'À igreja de Deus que está em Corinto, aos santificados em Cristo Jesus, chamados santos, com todos os que em todo o lugar invocam o nome de nosso Senhor Jesus Cristo, Senhor deles e nosso:',
        'Graça e paz da parte de Deus nosso Pai, e do Senhor Jesus Cristo.',
        'Sempre dou graças ao meu Deus por vós pela graça de Deus que vos foi dada em Jesus Cristo.',
        'Porque em tudo fostes enriquecidos nele, em toda a palavra e em todo o conhecimento',
        '(Como o testemunho de Cristo foi mesmo confirmado entre vós).',
        'De maneira que nenhum dom vos falta, esperando a manifestação de nosso Senhor Jesus Cristo,',
        'O qual vos confirmará também até ao fim, para serdes irrepreensíveis no dia de nosso Senhor Jesus Cristo.',
        'Fiel é Deus, pelo qual fostes chamados para a comunhão de seu Filho Jesus Cristo nosso Senhor.',
        'Rogo-vos, porém, irmãos, pelo nome de nosso Senhor Jesus Cristo, que digais todos uma mesma coisa, e que não haja entre vós dissensões; antes sejais unidos em um mesmo pensamento e em um mesmo parecer.',
        'Porque a respeito de vós, irmãos meus, me foi comunicado pelos da família de Cloé que há contendas entre vós.',
        'Quero dizer com isto, que cada um de vós diz: Eu sou de Paulo, e eu de Apolo, e eu de Cefas, e eu de Cristo.',
        'Está Cristo dividido? foi Paulo crucificado por vós? ou fostes vós batizados em nome de Paulo?',
        'Dou graças a Deus, porque a nenhum de vós batizei, senão a Crispo e a Gaio,',
        'Para que ninguém diga que fostes batizados em meu nome.',
        'E batizei também a família de Estéfanas; além destes, não sei se batizei algum outro.',
        'Porque Cristo enviou-me, não para batizar, mas para evangelizar; não em sabedoria de palavras, para que a cruz de Cristo se não faça vã.',
        'Porque a palavra da cruz é loucura para os que perecem; mas para nós, que somos salvos, é o poder de Deus.',
        'Porque está escrito: Destruirei a sabedoria dos sábios, E aniquilarei a inteligência dos inteligentes.',
        'Onde está o sábio? Onde está o escriba? Onde está o inquiridor deste século? Porventura não tornou Deus louca a sabedoria deste mundo?',
        'Visto como na sabedoria de Deus o mundo não conheceu a Deus pela sua sabedoria, aprouve a Deus salvar os crentes pela loucura da pregação.',
        'Porque os judeus pedem sinal, e os gregos buscam sabedoria;',
        'Mas nós pregamos a Cristo crucificado, que é escândalo para os judeus, e loucura para os gregos.',
        'Mas para os que são chamados, tanto judeus como gregos, lhes pregamos a Cristo, poder de Deus, e sabedoria de Deus.',
        'Porque a loucura de Deus é mais sábia do que os homens; e a fraqueza de Deus é mais forte do que os homens.',
        'Porque, vede, irmãos, a vossa vocação, que não são muitos os sábios segundo a carne, nem muitos os poderosos, nem muitos os nobres que são chamados.',
        'Mas Deus escolheu as coisas loucas deste mundo para confundir as sábias; e Deus escolheu as coisas fracas deste mundo para confundir as fortes;',
        'E Deus escolheu as coisas vis deste mundo, e as desprezíveis, e as que não são, para aniquilar as que são;',
        'Para que nenhuma carne se glorie perante ele.',
        'Mas vós sois dele, em Jesus Cristo, o qual para nós foi feito por Deus sabedoria, e justiça, e santificação, e redenção;',
        'Para que, como está escrito: Aquele que se gloria glorie-se no Senhor.',
      ],
      [
        'E eu, irmãos, quando fui ter convosco, anunciando-vos o testemunho de Deus, não fui com sublimidade de palavras ou de sabedoria.',
        'Porque nada me propus saber entre vós, senão a Jesus Cristo, e este crucificado.',
        'E eu estive convosco em fraqueza, e em temor, e em grande tremor.',
        'E a minha palavra, e a minha pregação, não consistiram em palavras persuasivas de sabedoria humana, mas em demonstração de Espírito e de poder;',
        'Para que a vossa fé não se apoiasse em sabedoria dos homens, mas no poder de Deus.',
        'Todavia falamos sabedoria entre os perfeitos; não, porém, a sabedoria deste mundo, nem dos príncipes deste mundo, que se aniquilam;',
        'Mas falamos a sabedoria de Deus, oculta em mistério, a qual Deus ordenou antes dos séculos para nossa glória;',
        'A qual nenhum dos príncipes deste mundo conheceu; porque, se a conhecessem, nunca crucificariam ao Senhor da glória.',
        'Mas, como está escrito:As coisas que o olho não viu, e o ouvido não ouviu,e não subiram ao coração do homem,são as que Deus preparou para os que o amam.',
        'Mas Deus no-las revelou pelo seu Espírito; porque o Espírito penetra todas as coisas, ainda as profundezas de Deus.',
        'Porque, qual dos homens sabe as coisas do homem, senão o espírito do homem, que nele está? Assim também ninguém sabe as coisas de Deus, senão o Espírito de Deus.',
        'Mas nós não recebemos o espírito do mundo, mas o Espírito que provém de Deus, para que pudéssemos conhecer o que nos é dado gratuitamente por Deus.',
        'As quais também falamos, não com palavras que a sabedoria humana ensina, mas com as que o Espírito Santo ensina, comparando as coisas espirituais com as espirituais.',
        'Ora, o homem natural não compreende as coisas do Espírito de Deus, porque lhe parecem loucura; e não pode entendê-las, porque elas se discernem espiritualmente.',
        'Mas o que é espiritual discerne bem tudo, e ele de ninguém é discernido.',
        'Porque, quem conheceu a mente do Senhor, para que possa instruí-lo? Mas nós temos a mente de Cristo.',
      ],
      [
        'E eu, irmãos, não vos pude falar como a espirituais, mas como a carnais, como a meninos em Cristo.',
        'Com leite vos criei, e não com carne, porque ainda não podíeis, nem tampouco ainda agora podeis,',
        'Porque ainda sois carnais; pois, havendo entre vós inveja, contendas e dissensões, não sois porventura carnais, e não andais segundo os homens?',
        'Porque, dizendo um: Eu sou de Paulo; e outro: Eu de Apolo; porventura não sois carnais?',
        'Pois, quem é Paulo, e quem é Apolo, senão ministros pelos quais crestes, e conforme o que o Senhor deu a cada um?',
        'Eu plantei, Apolo regou; mas Deus deu o crescimento.',
        'Por isso, nem o que planta é alguma coisa, nem o que rega, mas Deus, que dá o crescimento.',
        'Ora, o que planta e o que rega são um; mas cada um receberá o seu galardão segundo o seu trabalho.',
        'Porque nós somos cooperadores de Deus; vós sois lavoura de Deus e edifício de Deus.',
        'Segundo a graça de Deus que me foi dada, pus eu, como sábio arquiteto, o fundamento, e outro edifica sobre ele; mas veja cada um como edifica sobre ele.',
        'Porque ninguém pode pôr outro fundamento além do que já está posto, o qual é Jesus Cristo.',
        'E, se alguém sobre este fundamento formar um edifício de ouro, prata, pedras preciosas, madeira, feno, palha,',
        'A obra de cada um se manifestará; na verdade o dia a declarará, porque pelo fogo será descoberta; e o fogo provará qual seja a obra de cada um.',
        'Se a obra que alguém edificou nessa parte permanecer, esse receberá galardão.',
        'Se a obra de alguém se queimar, sofrerá detrimento; mas o tal será salvo, todavia como pelo fogo.',
        'Não sabeis vós que sois o templo de Deus e que o Espírito de Deus habita em vós?',
        'Se alguém destruir o templo de Deus, Deus o destruirá; porque o templo de Deus, que sois vós, é santo.',
        'Ninguém se engane a si mesmo. Se alguém dentre vós se tem por sábio neste mundo, faça-se louco para ser sábio.',
        'Porque a sabedoria deste mundo é loucura diante de Deus; pois está escrito: Ele apanha os sábios na sua própria astúcia.',
        'E outra vez: O Senhor conhece os pensamentos dos sábios, que são vãos.',
        'Portanto, ninguém se glorie nos homens; porque tudo é vosso;',
        'Seja Paulo, seja Apolo, seja Cefas, seja o mundo, seja a vida, seja a morte, seja o presente, seja o futuro; tudo é vosso,',
        'E vós de Cristo, e Cristo de Deus.',
      ],
      [
        'Que os homens nos considerem como ministros de Cristo, e despenseiros dos mistérios de Deus.',
        'Além disso requer-se dos despenseiros que cada um se ache fiel.',
        'Todavia, a mim mui pouco se me dá de ser julgado por vós, ou por algum juízo humano; nem eu tampouco a mim mesmo me julgo.',
        'Porque em nada me sinto culpado; mas nem por isso me considero justificado, pois quem me julga é o Senhor.',
        'Portanto, nada julgueis antes de tempo, até que o Senhor venha, o qual também trará à luz as coisas ocultas das trevas, e manifestará os desígnios dos corações; e então cada um receberá de Deus o louvor.',
        'E eu, irmãos, apliquei estas coisas, por semelhança, a mim e a Apolo, por amor de vós; para que em nós aprendais a não ir além do que está escrito, não vos ensoberbecendo a favor de um contra outro.',
        'Porque, quem te faz diferente? E que tens tu que não tenhas recebido? E, se o recebeste, por que te glorias, como se não o houveras recebido?',
        'Já estais fartos! já estais ricos! sem nós reinais! e quisera reinásseis para que também nós viéssemos a reinar convosco!',
        'Porque tenho para mim, que Deus a nós, apóstolos, nos pôs por últimos, como condenados à morte; pois somos feitos espetáculo ao mundo, aos anjos, e aos homens.',
        'Nós somos loucos por amor de Cristo, e vós sábios em Cristo; nós fracos, e vós fortes; vós ilustres, e nós vis.',
        'Até esta presente hora sofremos fome, e sede, e estamos nus, e recebemos bofetadas, e não temos pousada certa,',
        'E nos afadigamos, trabalhando com nossas próprias mãos. Somos injuriados, e bendizemos; somos perseguidos, e sofremos;',
        'Somos blasfemados, e rogamos; até ao presente temos chegado a ser como o lixo deste mundo, e como a escória de todos.',
        'Não escrevo estas coisas para vos envergonhar; mas admoesto-vos como meus filhos amados.',
        'Porque ainda que tivésseis dez mil aios em Cristo, não teríeis, contudo, muitos pais; porque eu pelo evangelho vos gerei em Jesus Cristo.',
        'Admoesto-vos, portanto, a que sejais meus imitadores.',
        'Por esta causa vos mandei Timóteo, que é meu filho amado, e fiel no Senhor, o qual vos lembrará os meus caminhos em Cristo, como por toda a parte ensino em cada igreja.',
        'Mas alguns andam ensoberbecidos, como se eu não houvesse de ir ter convosco.',
        'Mas em breve irei ter convosco, se o Senhor quiser, e então conhecerei, não as palavras dos que andam ensoberbecidos, mas o poder.',
        'Porque o reino de Deus não consiste em palavras, mas em poder.',
        'Que quereis? Irei ter convosco com vara ou com amor e espírito de mansidão?',
      ],
      [
        'Geralmente se ouve que há entre vós fornicação, e fornicação tal, que nem ainda entre os gentios se nomeia, como é haver quem possua a mulher de seu pai.',
        'Estais ensoberbecidos, e nem ao menos vos entristecestes por não ter sido dentre vós tirado quem cometeu tal ação.',
        'Eu, na verdade, ainda que ausente no corpo, mas presente no espírito, já determinei, como se estivesse presente, que o que tal ato praticou,',
        'Em nome de nosso Senhor Jesus Cristo, juntos vós e o meu espírito, pelo poder de nosso Senhor Jesus Cristo,',
        'Seja, este tal, entregue a Satanás para destruição da carne, para que o espírito seja salvo no dia do Senhor Jesus.',
        'Não é boa a vossa jactância. Não sabeis que um pouco de fermento faz levedar toda a massa?',
        'Alimpai-vos, pois, do fermento velho, para que sejais uma nova massa, assim como estais sem fermento. Porque Cristo, nossa páscoa, foi sacrificado por nós.',
        'Por isso façamos a festa, não com o fermento velho, nem com o fermento da maldade e da malícia, mas com os ázimos da sinceridade e da verdade.',
        'Já por carta vos tenho escrito, que não vos associeis com os que se prostituem;',
        'Isto não quer dizer absolutamente com os devassos deste mundo, ou com os avarentos, ou com os roubadores, ou com os idólatras; porque então vos seria necessário sair do mundo.',
        'Mas agora vos escrevi que não vos associeis com aquele que, dizendo-se irmão, for devasso, ou avarento, ou idólatra, ou maldizente, ou beberrão, ou roubador; com o tal nem ainda comais.',
        'Porque, que tenho eu em julgar também os que estão de fora? Não julgais vós os que estão dentro?',
        'Mas Deus julga os que estão de fora. Tirai pois dentre vós a esse iníquo.',
      ],
      [
        'Ousa algum de vós, tendo algum negócio contra outro, ir a juízo perante os injustos, e não perante os santos?',
        'Não sabeis vós que os santos hão de julgar o mundo? Ora, se o mundo deve ser julgado por vós, sois porventura indignos de julgar as coisas mínimas?',
        'Não sabeis vós que havemos de julgar os anjos? Quanto mais as coisas pertencentes a esta vida?',
        'Então, se tiverdes negócios em juízo, pertencentes a esta vida, pondes para julga-los os que são de menos estima na igreja?',
        'Para vos envergonhar o digo. Não há, pois, entre vós sábios, nem mesmo um, que possa julgar entre seus irmãos?',
        'Mas o irmão vai a juízo com o irmão, e isto perante infiéis.',
        'Na verdade é já realmente uma falta entre vós, terdes demandas uns contra os outros. Por que não sofreis antes a injustiça? Por que não sofreis antes o dano?',
        'Mas vós mesmos fazeis a injustiça e fazeis o dano, e isto aos irmãos.',
        'Não sabeis que os injustos não hão de herdar o reino de Deus?',
        'Não erreis: nem os devassos, nem os idólatras, nem os adúlteros, nem os efeminados, nem os sodomitas, nem os ladrões, nem os avarentos, nem os bêbados, nem os maldizentes, nem os roubadores herdarão o reino de Deus.',
        'E é o que alguns têm sido; mas haveis sido lavados, mas haveis sido santificados, mas haveis sido justificados em nome do Senhor Jesus, e pelo Espírito do nosso Deus.',
        'Todas as coisas me são lícitas, mas nem todas as coisas convêm. Todas as coisas me são lícitas, mas eu não me deixarei dominar por nenhuma.',
        'Os alimentos são para o estômago e o estômago para os alimentos; Deus, porém, aniquilará tanto um como os outros. Mas o corpo não é para a fornicação, senão para o Senhor, e o Senhor para o corpo.',
        'Ora, Deus, que também ressuscitou o Senhor, nos ressuscitará a nós pelo seu poder.',
        'Não sabeis vós que os vossos corpos são membros de Cristo? Tomarei, pois, os membros de Cristo, e os farei membros de uma meretriz? Não, por certo.',
        'Ou não sabeis que o que se ajunta com a meretriz, faz-se um corpo com ela? Porque serão, disse, dois numa só carne.',
        'Mas o que se ajunta com o Senhor é um mesmo espírito.',
        'Fugi da fornicação. Todo o pecado que o homem comete é fora do corpo; mas o que fornica peca contra o seu próprio corpo.',
        'Ou não sabeis que o vosso corpo é o templo do Espírito Santo, que habita em vós, proveniente de Deus, e que não sois de vós mesmos?',
        'Porque fostes comprados por bom preço; glorificai, pois, a Deus no vosso corpo, e no vosso espírito, os quais pertencem a Deus.',
      ],
      [
        'Ora, quanto às coisas que me escrevestes, bom seria que o homem não tocasse em mulher;',
        'Mas, por causa da fornicação, cada um tenha a sua própria mulher, e cada uma tenha o seu próprio marido.',
        'O marido pague à mulher a devida benevolência, e da mesma sorte a mulher ao marido.',
        'A mulher não tem poder sobre o seu próprio corpo, mas tem-no o marido; e também da mesma maneira o marido não tem poder sobre o seu próprio corpo, mas tem-no a mulher.',
        'Não vos priveis um ao outro, senão por consentimento mútuo por algum tempo, para vos aplicardes ao jejum e à oração; e depois ajuntai-vos outra vez, para que Satanás não vos tente pela vossa incontinência.',
        'Digo, porém, isto como que por permissão e não por mandamento.',
        'Porque quereria que todos os homens fossem como eu mesmo; mas cada um tem de Deus o seu próprio dom, um de uma maneira e outro de outra.',
        'Digo, porém, aos solteiros e às viúvas, que lhes é bom se ficarem como eu.',
        'Mas, se não podem conter-se, casem-se. Porque é melhor casar do que abrasar-se.',
        'Todavia, aos casados mando, não eu mas o Senhor, que a mulher não se aparte do marido.',
        'Se, porém, se apartar, que fique sem casar, ou que se reconcilie com o marido; e que o marido não deixe a mulher.',
        'Mas aos outros digo eu, não o Senhor: Se algum irmão tem mulher descrente, e ela consente em habitar com ele, não a deixe.',
        'E se alguma mulher tem marido descrente, e ele consente em habitar com ela, não o deixe.',
        'Porque o marido descrente é santificado pela mulher; e a mulher descrente é santificada pelo marido; de outra sorte os vossos filhos seriam imundos; mas agora são santos.',
        'Mas, se o descrente se apartar, aparte-se; porque neste caso o irmão, ou irmã, não está sujeito à servidão; mas Deus chamou-nos para a paz.',
        'Porque, de onde sabes, ó mulher, se salvarás teu marido? ou, de onde sabes, ó marido, se salvarás tua mulher?',
        'E assim cada um ande como Deus lhe repartiu, cada um como o Senhor o chamou. É o que ordeno em todas as igrejas.',
        'É alguém chamado, estando circuncidado? fique circuncidado. É alguém chamado estando incircuncidado? não se circuncide.',
        'A circuncisão é nada e a incircuncisão nada é, mas, sim, a observância dos mandamentos de Deus.',
        'Cada um fique na vocação em que foi chamado.',
        'Foste chamado sendo servo? não te dê cuidado; e, se ainda podes ser livre, aproveita a ocasião.',
        'Porque o que é chamado pelo Senhor, sendo servo, é liberto do Senhor; e da mesma maneira também o que é chamado sendo livre, servo é de Cristo.',
        'Fostes comprados por bom preço; não vos façais servos dos homens.',
        'Irmãos, cada um fique diante de Deus no estado em que foi chamado.',
        'Ora, quanto às virgens, não tenho mandamento do Senhor; dou, porém, o meu parecer, como quem tem alcançado misericórdia do Senhor para ser fiel.',
        'Tenho, pois, por bom, por causa da instante necessidade, que é bom para o homem o estar assim.',
        'Estás ligado à mulher? não busques separar-te. Estás livre de mulher? não busques mulher.',
        'Mas, se te casares, não pecas; e, se a virgem se casar, não peca. Todavia os tais terão tribulações na carne, e eu quereria poupar-vos.',
        'Isto, porém, vos digo, irmãos, que o tempo se abrevia; o que resta é que também os que têm mulheres sejam como se não as tivessem;',
        'E os que choram, como se não chorassem; e os que folgam, como se não folgassem; e os que compram, como se não possuíssem;',
        'E os que usam deste mundo, como se dele não abusassem, porque a aparência deste mundo passa.',
        'E bem quisera eu que estivésseis sem cuidado. O solteiro cuida das coisas do Senhor, em como há de agradar ao Senhor;',
        'Mas o que é casado cuida das coisas do mundo, em como há de agradar à mulher.',
        'Há diferença entre a mulher casada e a virgem. A solteira cuida das coisas do Senhor para ser santa, tanto no corpo como no espírito; porém, a casada cuida das coisas do mundo, em como há de agradar ao marido.',
        'E digo isto para proveito vosso; não para vos enlaçar, mas para o que é decente e conveniente, para vos unirdes ao Senhor sem distração alguma.',
        'Mas, se alguém julga que trata indignamente a sua virgem, se tiver passado a flor da idade, e se for necessário, que faça o tal o que quiser; não peca; casem-se.',
        'Todavia o que está firme em seu coração, não tendo necessidade, mas com poder sobre a sua própria vontade, se resolveu no seu coração guardar a sua virgem, faz bem.',
        'De sorte que, o que a dá em casamento faz bem; mas o que não a dá em casamento faz melhor.',
        'A mulher casada está ligada pela lei todo o tempo que o seu marido vive; mas, se falecer o seu marido fica livre para casar com quem quiser, contanto que seja no Senhor.',
        'Será, porém, mais bem-aventurada se ficar assim, segundo o meu parecer, e também eu cuido que tenho o Espírito de Deus.',
      ],
      [
        'Ora, no tocante às coisas sacrificadas aos ídolos, sabemos que todos temos ciência. A ciência incha, mas o amor edifica.',
        'E, se alguém cuida saber alguma coisa, ainda não sabe como convém saber.',
        'Mas, se alguém ama a Deus, esse é conhecido dele.',
        'Assim que, quanto ao comer das coisas sacrificadas aos ídolos, sabemos que o ídolo nada é no mundo, e que não há outro Deus, senão um só.',
        'Porque, ainda que haja também alguns que se chamem deuses, quer no céu quer na terra (como há muitos deuses e muitos senhores),',
        'Todavia para nós há um só Deus, o Pai, de quem é tudo e para quem nós vivemos; e um só Senhor, Jesus Cristo, pelo qual são todas as coisas, e nós por ele.',
        'Mas nem em todos há conhecimento; porque alguns até agora comem, com consciência do ídolo, coisas sacrificadas ao ídolo; e a sua consciência, sendo fraca, fica contaminada.',
        'Ora a comida não nos faz agradáveis a Deus, porque, se comemos, nada temos de mais e, se não comemos, nada nos falta.',
        'Mas vede que essa liberdade não seja de alguma maneira escândalo para os fracos.',
        'Porque, se alguém te vir a ti, que tens ciência, sentado à mesa no templo dos ídolos, não será a consciência do que é fraco induzida a comer das coisas sacrificadas aos ídolos?',
        'E pela tua ciência perecerá o irmão fraco, pelo qual Cristo morreu.',
        'Ora, pecando assim contra os irmãos, e ferindo a sua fraca consciência, pecais contra Cristo.',
        'Por isso, se a comida escandalizar a meu irmão, nunca mais comerei carne, para que meu irmão não se escandalize.',
      ],
      [
        'Não sou eu apóstolo? Não sou livre? Não vi eu a Jesus Cristo Senhor nosso? Não sois vós a minha obra no Senhor?',
        'Se eu não sou apóstolo para os outros, ao menos o sou para vós; porque vós sois o selo do meu apostolado no Senhor.',
        'Esta é minha defesa para com os que me condenam.',
        'Não temos nós direito de comer e beber?',
        'Não temos nós direito de levar conosco uma esposa crente, como também os demais apóstolos, e os irmãos do Senhor, e Cefas?',
        'Ou só eu e Barnabé não temos direito de deixar de trabalhar?',
        'Quem jamais milita à sua própria custa? Quem planta a vinha e não come do seu fruto? Ou quem apascenta o gado e não se alimenta do leite do gado?',
        'Digo eu isto segundo os homens? Ou não diz a lei também o mesmo?',
        'Porque na lei de Moisés está escrito: Não atarás a boca ao boi que trilha o grão. Porventura tem Deus cuidado dos bois?',
        'Ou não o diz certamente por nós? Certamente que por nós está escrito; porque o que lavra deve lavrar com esperança e o que debulha deve debulhar com esperança de ser participante.',
        'Se nós vos semeamos as coisas espirituais, será muito que de vós recolhamos as carnais?',
        'Se outros participam deste poder sobre vós, por que não, e mais justamente, nós? Mas nós não usamos deste direito; antes suportamos tudo, para não pormos impedimento algum ao evangelho de Cristo.',
        'Não sabeis vós que os que administram o que é sagrado comem do que é do templo? E que os que de contínuo estão junto ao altar, participam do altar?',
        'Assim ordenou também o Senhor aos que anunciam o evangelho, que vivam do evangelho.',
        'Mas eu de nenhuma destas coisas usei, e não escrevi isto para que assim se faça comigo; porque melhor me fora morrer, do que alguém fazer vã esta minha glória.',
        'Porque, se anuncio o evangelho, não tenho de que me gloriar, pois me é imposta essa obrigação; e ai de mim, se não anunciar o evangelho!',
        'E por isso, se o faço de boa mente, terei prêmio; mas, se de má vontade, apenas uma dispensação me é confiada.',
        'Logo, que prêmio tenho? Que, evangelizando, proponha de graça o evangelho de Cristo para não abusar do meu poder no evangelho.',
        'Porque, sendo livre para com todos, fiz-me servo de todos para ganhar ainda mais.',
        'E fiz-me como judeu para os judeus, para ganhar os judeus; para os que estão debaixo da lei, como se estivesse debaixo da lei, para ganhar os que estão debaixo da lei.',
        'Para os que estão sem lei, como se estivesse sem lei (não estando sem lei para com Deus, mas debaixo da lei de Cristo), para ganhar os que estão sem lei.',
        'Fiz-me como fraco para os fracos, para ganhar os fracos. Fiz-me tudo para todos, para por todos os meios chegar a salvar alguns.',
        'E eu faço isto por causa do evangelho, para ser também participante dele.',
        'Não sabeis vós que os que correm no estádio, todos, na verdade, correm, mas um só leva o prêmio? Correi de tal maneira que o alcanceis.',
        'E todo aquele que luta de tudo se abstém; eles o fazem para alcançar uma coroa corruptível; nós, porém, uma incorruptível.',
        'Pois eu assim corro, não como a coisa incerta; assim combato, não como batendo no ar.',
        'Antes subjugo o meu corpo, e o reduzo à servidão, para que, pregando aos outros, eu mesmo não venha de alguma maneira a ficar reprovado.',
      ],
      [
        'Ora, irmãos, não quero que ignoreis que nossos pais estiveram todos debaixo da nuvem, e todos passaram pelo mar.',
        'E todos foram batizados em Moisés, na nuvem e no mar,',
        'E todos comeram de uma mesma comida espiritual,',
        'E beberam todos de uma mesma bebida espiritual, porque bebiam da pedra espiritual que os seguia; e a pedra era Cristo.',
        'Mas Deus não se agradou da maior parte deles, por isso foram prostrados no deserto.',
        'E estas coisas foram-nos feitas em figura, para que não cobicemos as coisas más, como eles cobiçaram.',
        'Não vos façais, pois, idólatras, como alguns deles, conforme está escrito: O povo assentou-se a comer e a beber, e levantou-se para folgar.',
        'E não nos forniquemos, como alguns deles fizeram; e caíram num dia vinte e três mil.',
        'E não tentemos a Cristo, como alguns deles também tentaram, e pereceram pelas serpentes.',
        'E não murmureis, como também alguns deles murmuraram, e pereceram pelo destruidor.',
        'Ora, tudo isto lhes sobreveio como figuras, e estão escritas para aviso nosso, para quem já são chegados os fins dos séculos.',
        'Aquele, pois, que cuida estar em pé, olhe não caia.',
        'Não veio sobre vós tentação, senão humana; mas fiel é Deus, que não vos deixará tentar acima do que podeis, antes com a tentação dará também o escape, para que a possais suportar.',
        'Portanto, meus amados, fugi da idolatria.',
        'Falo como a entendidos; julgai vós mesmos o que digo.',
        'Porventura o cálice de bênção, que abençoamos, não é a comunhão do sangue de Cristo? O pão que partimos não é porventura a comunhão do corpo de Cristo?',
        'Porque nós, sendo muitos, somos um só pão e um só corpo, porque todos participamos do mesmo pão.',
        'Vede a Israel segundo a carne; os que comem os sacrifícios não são porventura participantes do altar?',
        'Mas que digo? Que o ídolo é alguma coisa? Ou que o sacrificado ao ídolo é alguma coisa?',
        'Antes digo que as coisas que os gentios sacrificam, as sacrificam aos demônios, e não a Deus. E não quero que sejais participantes com os demônios.',
        'Não podeis beber o cálice do Senhor e o cálice dos demônios; não podeis ser participantes da mesa do Senhor e da mesa dos demônios.',
        'Ou irritaremos o Senhor? Somos nós mais fortes do que ele?',
        'Todas as coisas me são lícitas, mas nem todas as coisas convêm; todas as coisas me são lícitas, mas nem todas as coisas edificam.',
        'Ninguém busque o proveito próprio; antes cada um o que é de outrem.',
        'Comei de tudo quanto se vende no açougue, sem perguntar nada, por causa da consciência.',
        'Porque a terra é do Senhor e toda a sua plenitude.',
        'E, se algum dos infiéis vos convidar, e quiserdes ir, comei de tudo o que se puser diante de vós, sem nada perguntar, por causa da consciência.',
        'Mas, se alguém vos disser: Isto foi sacrificado aos ídolos, não comais, por causa daquele que vos advertiu e por causa da consciência; porque a terra é do Senhor, e toda a sua plenitude.',
        'Digo, porém, a consciência, não a tua, mas a do outro. Pois por que há de a minha liberdade ser julgada pela consciência de outrem?',
        'E, se eu com graça participo, por que sou blasfemado naquilo por que dou graças?',
        'Portanto, quer comais quer bebais, ou façais outra qualquer coisa, fazei tudo para glória de Deus.',
        'Portai-vos de modo que não deis escândalo nem aos judeus, nem aos gregos, nem à igreja de Deus.',
        'Como também eu em tudo agrado a todos, não buscando o meu próprio proveito, mas o de muitos, para que assim se possam salvar.',
      ],
      [
        'Sede meus imitadores, como também eu de Cristo.',
        'E louvo-vos, irmãos, porque em tudo vos lembrais de mim, e retendes os preceitos como vo-los entreguei.',
        'Mas quero que saibais que Cristo é a cabeça de todo o homem, e o homem a cabeça da mulher; e Deus a cabeça de Cristo.',
        'Todo o homem que ora ou profetiza, tendo a cabeça coberta, desonra a sua própria cabeça.',
        'Mas toda a mulher que ora ou profetiza com a cabeça descoberta, desonra a sua própria cabeça, porque é como se estivesse rapada.',
        'Portanto, se a mulher não se cobre com véu, tosquie-se também. Mas, se para a mulher é coisa indecente tosquiar-se ou rapar-se, que ponha o véu.',
        'O homem, pois, não deve cobrir a cabeça, porque é a imagem e glória de Deus, mas a mulher é a glória do homem.',
        'Porque o homem não provém da mulher, mas a mulher do homem.',
        'Porque também o homem não foi criado por causa da mulher, mas a mulher por causa do homem.',
        'Portanto, a mulher deve ter sobre a cabeça sinal de poderio, por causa dos anjos.',
        'Todavia, nem o homem é sem a mulher, nem a mulher sem o homem, no Senhor.',
        'Porque, como a mulher provém do homem, assim também o homem provém da mulher, mas tudo vem de Deus.',
        'Julgai entre vós mesmos: é decente que a mulher ore a Deus descoberta?',
        'Ou não vos ensina a mesma natureza que é desonra para o homem ter cabelo crescido?',
        'Mas ter a mulher cabelo crescido lhe é honroso, porque o cabelo lhe foi dado em lugar de véu.',
        'Mas, se alguém quiser ser contencioso, nós não temos tal costume, nem as igrejas de Deus.',
        'Nisto, porém, que vou dizer-vos não vos louvo; porquanto vos ajuntais, não para melhor, senão para pior.',
        'Porque antes de tudo ouço que, quando vos ajuntais na igreja, há entre vós dissensões; e em parte o creio.',
        'E até importa que haja entre vós heresias, para que os que são sinceros se manifestem entre vós.',
        'De sorte que, quando vos ajuntais num lugar, não é para comer a ceia do Senhor.',
        'Porque, comendo, cada um toma antecipadamente a sua própria ceia; e assim um tem fome e outro embriaga-se.',
        'Não tendes porventura casas para comer e para beber? Ou desprezais a igreja de Deus, e envergonhais os que nada têm? Que vos direi? Louvar-vos-ei? Nisto não vos louvo.',
        'Porque eu recebi do Senhor o que também vos ensinei: que o Senhor Jesus, na noite em que foi traído, tomou o pão;',
        'E, tendo dado graças, o partiu e disse: Tomai, comei; isto é o meu corpo que é partido por vós; fazei isto em memória de mim.',
        'Semelhantemente também, depois de cear, tomou o cálice, dizendo: Este cálice é o novo testamento no meu sangue; fazei isto, todas as vezes que beberdes, em memória de mim.',
        'Porque todas as vezes que comerdes este pão e beberdes este cálice anunciais a morte do Senhor, até que venha.',
        'Portanto, qualquer que comer este pão, ou beber o cálice do Senhor indignamente, será culpado do corpo e do sangue do Senhor.',
        'Examine-se, pois, o homem a si mesmo, e assim coma deste pão e beba deste cálice.',
        'Porque o que come e bebe indignamente, come e bebe para sua própria condenação, não discernindo o corpo do Senhor.',
        'Por causa disto há entre vós muitos fracos e doentes, e muitos que dormem.',
        'Porque, se nós nos julgássemos a nós mesmos, não seríamos julgados.',
        'Mas, quando somos julgados, somos repreendidos pelo Senhor, para não sermos condenados com o mundo.',
        'Portanto, meus irmãos, quando vos ajuntais para comer, esperai uns pelos outros.',
        'Mas, se algum tiver fome, coma em casa, para que não vos ajunteis para condenação. Quanto às demais coisas, ordená-las-ei quando for.',
      ],
      [
        'Acerca dos dons espirituais, não quero, irmãos, que sejais ignorantes.',
        'Vós bem sabeis que éreis gentios, levados aos ídolos mudos, conforme éreis guiados.',
        'Portanto, vos quero fazer compreender que ninguém que fala pelo Espírito de Deus diz: Jesus é anátema, e ninguém pode dizer que Jesus é o Senhor, senão pelo Espírito Santo.',
        'Ora, há diversidade de dons, mas o Espírito é o mesmo.',
        'E há diversidade de ministérios, mas o Senhor é o mesmo.',
        'E há diversidade de operações, mas é o mesmo Deus que opera tudo em todos.',
        'Mas a manifestação do Espírito é dada a cada um, para o que for útil.',
        'Porque a um pelo Espírito é dada a palavra da sabedoria; e a outro, pelo mesmo Espírito, a palavra da ciência;',
        'E a outro, pelo mesmo Espírito, a fé; e a outro, pelo mesmo Espírito, os dons de curar;',
        'E a outro a operação de maravilhas; e a outro a profecia; e a outro o dom de discernir os espíritos; e a outro a variedade de línguas; e a outro a interpretação das línguas.',
        'Mas um só e o mesmo Espírito opera todas estas coisas, repartindo particularmente a cada um como quer.',
        'Porque, assim como o corpo é um, e tem muitos membros, e todos os membros, sendo muitos, são um só corpo, assim é Cristo também.',
        'Pois todos nós fomos batizados em um Espírito, formando um corpo, quer judeus, quer gregos, quer servos, quer livres, e todos temos bebido de um Espírito.',
        'Porque também o corpo não é um só membro, mas muitos.',
        'Se o pé disser: Porque não sou mão, não sou do corpo; não será por isso do corpo?',
        'E se a orelha disser: Porque não sou olho não sou do corpo; não será por isso do corpo?',
        'Se todo o corpo fosse olho, onde estaria o ouvido? Se todo fosse ouvido, onde estaria o olfato?',
        'Mas agora Deus colocou os membros no corpo, cada um deles como quis.',
        'E, se todos fossem um só membro, onde estaria o corpo?',
        'Assim, pois, há muitos membros, mas um corpo.',
        'E o olho não pode dizer à mão: Não tenho necessidade de ti; nem ainda a cabeça aos pés: Não tenho necessidade de vós.',
        'Antes, os membros do corpo que parecem ser os mais fracos são necessários;',
        'E os que reputamos serem menos honrosos no corpo, a esses honramos muito mais; e aos que em nós são menos decorosos damos muito mais honra.',
        'Porque os que em nós são mais nobres não têm necessidade disso, mas Deus assim formou o corpo, dando muito mais honra ao que tinha falta dela;',
        'Para que não haja divisão no corpo, mas antes tenham os membros igual cuidado uns dos outros.',
        'De maneira que, se um membro padece, todos os membros padecem com ele; e, se um membro é honrado, todos os membros se regozijam com ele.',
        'Ora, vós sois o corpo de Cristo, e seus membros em particular.',
        'E a uns pôs Deus na igreja, primeiramente apóstolos, em segundo lugar profetas, em terceiro doutores, depois milagres, depois dons de curar, socorros, governos, variedades de línguas.',
        'Porventura são todos apóstolos? são todos profetas? são todos doutores? são todos operadores de milagres?',
        'Têm todos o dom de curar? falam todos diversas línguas? interpretam todos?',
        'Portanto, procurai com zelo os melhores dons; e eu vos mostrarei um caminho mais excelente.',
      ],
      [
        'Ainda que eu falasse as línguas dos homens e dos anjos, e não tivesse amor, seria como o metal que soa ou como o sino que tine.',
        'E ainda que tivesse o dom de profecia, e conhecesse todos os mistérios e toda a ciência, e ainda que tivesse toda a fé, de maneira tal que transportasse os montes, e não tivesse amor, nada seria.',
        'E ainda que distribuísse toda a minha fortuna para sustento dos pobres, e ainda que entregasse o meu corpo para ser queimado, e não tivesse amor, nada disso me aproveitaria.',
        'O amor é sofredor, é benigno; o amor não é invejoso; o amor não trata com leviandade, não se ensoberbece.',
        'Não se porta com indecência, não busca os seus interesses, não se irrita, não suspeita mal;',
        'Não folga com a injustiça, mas folga com a verdade;',
        'Tudo sofre, tudo crê, tudo espera, tudo suporta.',
        'O amor nunca falha; mas havendo profecias, serão aniquiladas; havendo línguas, cessarão; havendo ciência, desaparecerá;',
        'Porque, em parte, conhecemos, e em parte profetizamos;',
        'Mas, quando vier o que é perfeito, então o que o é em parte será aniquilado.',
        'Quando eu era menino, falava como menino, sentia como menino, discorria como menino, mas, logo que cheguei a ser homem, acabei com as coisas de menino.',
        'Porque agora vemos por espelho em enigma, mas então veremos face a face; agora conheço em parte, mas então conhecerei como também sou conhecido.',
        'Agora, pois, permanecem a fé, a esperança e o amor, estes três, mas o maior destes é o amor.',
      ],
      [
        'Segui o amor, e procurai com zelo os dons espirituais, mas principalmente o de profetizar.',
        'Porque o que fala em língua desconhecida não fala aos homens, senão a Deus; porque ninguém o entende, e em espírito fala mistérios.',
        'Mas o que profetiza fala aos homens, para edificação, exortação e consolação.',
        'O que fala em língua desconhecida edifica-se a si mesmo, mas o que profetiza edifica a igreja.',
        'E eu quero que todos vós faleis em línguas, mas muito mais que profetizeis; porque o que profetiza é maior do que o que fala em línguas, a não ser que também interprete para que a igreja receba edificação.',
        'E agora, irmãos, se eu for ter convosco falando em línguas, que vos aproveitaria, se não vos falasse ou por meio da revelação, ou da ciência, ou da profecia, ou da doutrina?',
        'Da mesma sorte, se as coisas inanimadas, que fazem som, seja flauta, seja cítara, não formarem sons distintos, como se conhecerá o que se toca com a flauta ou com a cítara?',
        'Porque, se a trombeta der sonido incerto, quem se preparará para a batalha?',
        'Assim também vós, se com a língua não pronunciardes palavras bem inteligíveis, como se entenderá o que se diz? porque estareis como que falando ao ar.',
        'Há, por exemplo, tanta espécie de vozes no mundo, e nenhuma delas é sem significação.',
        'Mas, se eu ignorar o sentido da voz, serei bárbaro para aquele a quem falo, e o que fala será bárbaro para mim.',
        'Assim também vós, como desejais dons espirituais, procurai abundar neles, para edificação da igreja.',
        'Por isso, o que fala em língua desconhecida, ore para que a possa interpretar.',
        'Porque, se eu orar em língua desconhecida, o meu espírito ora bem, mas o meu entendimento fica sem fruto.',
        'Que farei, pois? Orarei com o espírito, mas também orarei com o entendimento; cantarei com o espírito, mas também cantarei com o entendimento.',
        'De outra maneira, se tu bendisseres com o espírito, como dirá o que ocupa o lugar de indouto, o Amém, sobre a tua ação de graças, visto que não sabe o que dizes?',
        'Porque realmente tu dás bem as graças, mas o outro não é edificado.',
        'Dou graças ao meu Deus, porque falo mais línguas do que vós todos.',
        'Todavia eu antes quero falar na igreja cinco palavras na minha própria inteligência, para que possa também instruir os outros, do que dez mil palavras em língua desconhecida.',
        'Irmãos, não sejais meninos no entendimento, mas sede meninos na malícia, e adultos no entendimento.',
        'Está escrito na lei: Por gente de outras línguas, e por outros lábios, falarei a este povo; e ainda assim me não ouvirão, diz o Senhor.',
        'De sorte que as línguas são um sinal, não para os fiéis, mas para os infiéis; e a profecia não é sinal para os infiéis, mas para os fiéis.',
        'Se, pois, toda a igreja se congregar num lugar, e todos falarem em línguas, e entrarem indoutos ou infiéis, não dirão porventura que estais loucos?',
        'Mas, se todos profetizarem, e algum indouto ou infiel entrar, de todos é convencido, de todos é julgado.',
        'E, portanto, os segredos do seu coração ficam manifestos, e assim, lançando-se sobre o seu rosto, adorará a Deus, publicando que Deus está verdadeiramente entre vós.',
        'Que fareis pois, irmãos? Quando vos ajuntais, cada um de vós tem salmo, tem doutrina, tem revelação, tem língua, tem interpretação. Faça-se tudo para edificação.',
        'E, se alguém falar em língua desconhecida, faça-se isso por dois, ou quando muito três, e por sua vez, e haja intérprete.',
        'Mas, se não houver intérprete, esteja calado na igreja, e fale consigo mesmo, e com Deus.',
        'E falem dois ou três profetas, e os outros julguem.',
        'Mas, se a outro, que estiver assentado, for revelada alguma coisa, cale-se o primeiro.',
        'Porque todos podereis profetizar, uns depois dos outros; para que todos aprendam, e todos sejam consolados.',
        'E os espíritos dos profetas estão sujeitos aos profetas.',
        'Porque Deus não é Deus de confusão, senão de paz, como em todas as igrejas dos santos.',
        'As vossas mulheres estejam caladas nas igrejas; porque não lhes é permitido falar; mas estejam sujeitas, como também ordena a lei.',
        'E, se querem aprender alguma coisa, interroguem em casa a seus próprios maridos; porque é vergonhoso que as mulheres falem na igreja.',
        'Porventura saiu dentre vós a palavra de Deus? Ou veio ela somente para vós?',
        'Se alguém cuida ser profeta, ou espiritual, reconheça que as coisas que vos escrevo são mandamentos do Senhor.',
        'Mas, se alguém ignora isto, que ignore.',
        'Portanto, irmãos, procurai, com zelo, profetizar, e não proibais falar línguas.',
        'Mas faça-se tudo decentemente e com ordem.',
      ],
      [
        'Também vos notifico, irmãos, o evangelho que já vos tenho anunciado; o qual também recebestes, e no qual também permaneceis.',
        'Pelo qual também sois salvos se o retiverdes tal como vo-lo tenho anunciado; se não é que crestes em vão.',
        'Porque primeiramente vos entreguei o que também recebi: que Cristo morreu por nossos pecados, segundo as Escrituras,',
        'E que foi sepultado, e que ressuscitou ao terceiro dia, segundo as Escrituras.',
        'E que foi visto por Cefas, e depois pelos doze.',
        'Depois foi visto, uma vez, por mais de quinhentos irmãos, dos quais vive ainda a maior parte, mas alguns já dormem também.',
        'Depois foi visto por Tiago, depois por todos os apóstolos.',
        'E por derradeiro de todos me apareceu também a mim, como a um abortivo.',
        'Porque eu sou o menor dos apóstolos, que não sou digno de ser chamado apóstolo, pois que persegui a igreja de Deus.',
        'Mas pela graça de Deus sou o que sou; e a sua graça para comigo não foi vã, antes trabalhei muito mais do que todos eles; todavia não eu, mas a graça de Deus, que está comigo.',
        'Então, ou seja eu ou sejam eles, assim pregamos e assim haveis crido.',
        'Ora, se se prega que Cristo ressuscitou dentre os mortos, como dizem alguns dentre vós que não há ressurreição de mortos?',
        'E, se não há ressurreição de mortos, também Cristo não ressuscitou.',
        'E, se Cristo não ressuscitou, logo é vã a nossa pregação, e também é vã a vossa fé.',
        'E assim somos também considerados como falsas testemunhas de Deus, pois testificamos de Deus, que ressuscitou a Cristo, ao qual, porém, não ressuscitou, se, na verdade, os mortos não ressuscitam.',
        'Porque, se os mortos não ressuscitam, também Cristo não ressuscitou.',
        'E, se Cristo não ressuscitou, é vã a vossa fé, e ainda permaneceis nos vossos pecados.',
        'E também os que dormiram em Cristo estão perdidos.',
        'Se esperamos em Cristo só nesta vida, somos os mais miseráveis de todos os homens.',
        'Mas de fato Cristo ressuscitou dentre os mortos, e foi feito as primícias dos que dormem.',
        'Porque assim como a morte veio por um homem, também a ressurreição dos mortos veio por um homem.',
        'Porque, assim como todos morrem em Adão, assim também todos serão vivificados em Cristo.',
        'Mas cada um por sua ordem: Cristo as primícias, depois os que são de Cristo, na sua vinda.',
        'Depois virá o fim, quando tiver entregado o reino a Deus, ao Pai, e quando houver aniquilado todo o império, e toda a potestade e força.',
        'Porque convém que reine até que haja posto a todos os inimigos debaixo de seus pés.',
        'Ora, o último inimigo que há de ser aniquilado é a morte.',
        'Porque todas as coisas sujeitou debaixo de seus pés. Mas, quando diz que todas as coisas lhe estão sujeitas, claro está que se excetua aquele que lhe sujeitou todas as coisas.',
        'E, quando todas as coisas lhe estiverem sujeitas, então também o mesmo Filho se sujeitará àquele que todas as coisas lhe sujeitou, para que Deus seja tudo em todos.',
        'Doutra maneira, que farão os que se batizam pelos mortos, se absolutamente os mortos não ressuscitam? Por que se batizam eles então pelos mortos?',
        'Por que estamos nós também a toda a hora em perigo?',
        'Eu protesto que cada dia morro, gloriando-me em vós, irmãos, por Cristo Jesus nosso Senhor.',
        'Se, como homem, combati em Éfeso contra as bestas, que me aproveita isso, se os mortos não ressuscitam? Comamos e bebamos, que amanhã morreremos.',
        'Não vos enganeis: as más conversações corrompem os bons costumes.',
        'Vigiai justamente e não pequeis; porque alguns ainda não têm o conhecimento de Deus; digo-o para vergonha vossa.',
        'Mas alguém dirá: Como ressuscitarão os mortos? E com que corpo virão?',
        'Insensato! o que tu semeias não é vivificado, se primeiro não morrer.',
        'E, quando semeias, não semeias o corpo que há de nascer, mas o simples grão, como de trigo, ou de outra qualquer semente.',
        'Mas Deus dá-lhe o corpo como quer, e a cada semente o seu próprio corpo.',
        'Nem toda a carne é uma mesma carne, mas uma é a carne dos homens, e outra a carne dos animais, e outra a dos peixes e outra a das aves.',
        'E há corpos celestes e corpos terrestres, mas uma é a glória dos celestes e outra a dos terrestres.',
        'Uma é a glória do sol, e outra a glória da lua, e outra a glória das estrelas; porque uma estrela difere em glória de outra estrela.',
        'Assim também a ressurreição dentre os mortos. Semeia-se o corpo em corrupção; ressuscitará em incorrupção.',
        'Semeia-se em ignomínia, ressuscitará em glória. Semeia-se em fraqueza, ressuscitará com vigor.',
        'Semeia-se corpo natural, ressuscitará corpo espiritual. Se há corpo natural, há também corpo espiritual.',
        'Assim está também escrito: O primeiro homem, Adão, foi feito em alma vivente; o último Adão em espírito vivificante.',
        'Mas não é primeiro o espiritual, senão o natural; depois o espiritual.',
        'O primeiro homem, da terra, é terreno; o segundo homem, o Senhor, é do céu.',
        'Qual o terreno, tais são também os terrestres; e, qual o celestial, tais também os celestiais.',
        'E, assim como trouxemos a imagem do terreno, assim traremos também a imagem do celestial.',
        'E agora digo isto, irmãos: que a carne e o sangue não podem herdar o reino de Deus, nem a corrupção herdar a incorrupção.',
        'Eis aqui vos digo um mistério: Na verdade, nem todos dormiremos, mas todos seremos transformados;',
        'Num momento, num abrir e fechar de olhos, ante a última trombeta; porque a trombeta soará, e os mortos ressuscitarão incorruptíveis, e nós seremos transformados.',
        'Porque convém que isto que é corruptível se revista da incorruptibilidade, e que isto que é mortal se revista da imortalidade.',
        'E, quando isto que é corruptível se revestir da incorruptibilidade, e isto que é mortal se revestir da imortalidade, então cumprir-se-á a palavra que está escrita: Tragada foi a morte na vitória.',
        'Onde está, ó morte, o teu aguilhão? Onde está, ó inferno, a tua vitória?',
        'Ora, o aguilhão da morte é o pecado, e a força do pecado é a lei.',
        'Mas graças a Deus que nos dá a vitória por nosso Senhor Jesus Cristo.',
        'Portanto, meus amados irmãos, sede firmes e constantes, sempre abundantes na obra do Senhor, sabendo que o vosso trabalho não é vão no Senhor.',
      ],
      [
        'Ora, quanto à coleta que se faz para os santos, fazei vós também o mesmo que ordenei às igrejas da Galácia.',
        'No primeiro dia da semana cada um de vós ponha de parte o que puder ajuntar, conforme a sua prosperidade, para que não se façam as coletas quando eu chegar.',
        'E, quando tiver chegado, mandarei os que por cartas aprovardes, para levar a vossa dádiva a Jerusalém.',
        'E, se valer a pena que eu também vá, irão comigo.',
        'Irei, porém, ter convosco depois de ter passado pela macedônia (porque tenho de passar pela macedônia).',
        'E bem pode ser que fique convosco, e passe também o inverno, para que me acompanheis aonde quer que eu for.',
        'Porque não vos quero agora ver de passagem, mas espero ficar convosco algum tempo, se o Senhor o permitir.',
        'Ficarei, porém, em Éfeso até ao Pentecostes;',
        'Porque uma porta grande e eficaz se me abriu; e há muitos adversários.',
        'E, se Timóteo for, vede que esteja sem temor convosco; porque trabalha na obra do Senhor, como eu também.',
        'Portanto, ninguém o despreze, mas acompanhai-o em paz, para que venha ter comigo; pois o espero com os irmãos.',
        'E, acerca do irmão Apolo, roguei-lhe muito que fosse com os irmãos ter convosco, mas, na verdade, não teve vontade de ir agora; irá, porém, quando se lhe oferecer boa ocasião.',
        'Vigiai, estai firmes na fé; portai-vos varonilmente, e fortalecei-vos.',
        'Todas as vossas coisas sejam feitas com amor.',
        'Agora vos rogo, irmãos (sabeis que a família de Estéfanas é as primícias da Acaia, e que se tem dedicado ao ministério dos santos),',
        'Que também vos sujeiteis aos tais, e a todo aquele que auxilia na obra e trabalha.',
        'Folgo, porém, com a vinda de Estéfanas, de Fortunato e de Acaico; porque estes supriram o que da vossa parte me faltava.',
        'Porque recrearam o meu espírito e o vosso. Reconhecei, pois, aos tais.',
        'As igrejas da Ásia vos saúdam. Saúdam-vos afetuosamente no Senhor áqüila e Priscila, com a igreja que está em sua casa.',
        'Todos os irmãos vos saúdam. Saudai-vos uns aos outros com ósculo santo.',
        'Saudação da minha própria mão, de Paulo.',
        'Se alguém não ama ao Senhor Jesus Cristo, seja anátema. Maranata!',
        'A graça do Senhor Jesus Cristo seja convosco.',
        'O meu amor seja com todos vós em Cristo Jesus. Amém.',
      ],
    ],
    livro: '1corintios',
  },
  {
    abbrev: '2co',
    capitulos: [
      [
        'Paulo, apóstolo de Jesus Cristo, pela vontade de Deus, e o irmão Timóteo, à igreja de Deus, que está em Corinto, com todos os santos que estão em toda a Acaia.',
        'Graça a vós e paz da parte de Deus nosso Pai, e da do Senhor Jesus Cristo.',
        'Bendito seja o Deus e Pai de nosso Senhor Jesus Cristo, o Pai das misericórdias e o Deus de toda a consolação;',
        'Que nos consola em toda a nossa tribulação, para que também possamos consolar os que estiverem em alguma tribulação, com a consolação com que nós mesmos somos consolados por Deus.',
        'Porque, como as aflições de Cristo são abundantes em nós, assim também é abundante a nossa consolação por meio de Cristo.',
        'Mas, se somos atribulados, é para vossa consolação e salvação; ou, se somos consolados, para vossa consolação é, a qual se opera suportando com paciência as mesmas aflições que nós também padecemos;',
        'E a nossa esperança acerca de vós é firme, sabendo que, como sois participantes das aflições, assim o sereis também da consolação.',
        'Porque não queremos, irmãos, que ignoreis a tribulação que nos sobreveio na Ásia, pois que fomos sobremaneira agravados mais do que podíamos suportar, de modo tal que até da vida desesperamos.',
        'Mas já em nós mesmos tínha- mos a sentença de morte, para que não confiássemos em nós, mas em Deus, que ressuscita os mortos;',
        'O qual nos livrou de tão grande morte, e livra; em quem esperamos que também nos livrará ainda,',
        'Ajudando-nos também vós com orações por nós, para que pela mercê, que por muitas pessoas nos foi feita, por muitas também sejam dadas graças a nosso respeito.',
        'Porque a nossa glória é esta: o testemunho da nossa consciência, de que com simplicidade e sinceridade de Deus, não com sabedoria carnal, mas na graça de Deus, temos vivido no mundo, e de modo particular convosco.',
        'Porque nenhumas outras coisas vos escrevemos, senão as que já sabeis ou também reconheceis; e espero que também até ao fim as reconhecereis.',
        'Como também já em parte reconhecestes em nós, que somos a vossa glória, como também vós sereis a nossa no dia do Senhor Jesus.',
        'E com esta confiança quis primeiro ir ter convosco, para que tivésseis uma segunda graça;',
        'E por vós passar à macedônia, e da macedônia ir outra vez ter convosco, e ser guiado por vós à Judéia.',
        'E, deliberando isto, usei porventura de leviandade? Ou o que delibero, o delibero segundo a carne, para que haja em mim sim, sim, e não, não?',
        'Antes, como Deus é fiel, a nossa palavra para convosco não foi sim e não.',
        'Porque o Filho de Deus, Jesus Cristo, que entre vós foi pregado por nós, isto é, por mim, Silvano e Timóteo, não foi sim e não; mas nele houve sim.',
        'Porque todas quantas promessas há de Deus, são nele sim, e por ele o Amém, para glória de Deus por nós.',
        'Mas o que nos confirma convosco em Cristo, e o que nos ungiu, é Deus,',
        'O qual também nos selou e deu o penhor do Espírito em nossos corações.',
        'Invoco, porém, a Deus por testemunha sobre a minha alma, que para vos poupar não tenho até agora ido a Corinto;',
        'Não que tenhamos domínio sobre a vossa fé, mas porque somos cooperadores de vosso gozo; porque pela fé estais em pé.',
      ],
      [
        'Mas deliberei isto comigo mesmo: não ir mais ter convosco em tristeza.',
        'Porque, se eu vos entristeço, quem é que me alegrará, senão aquele que por mim foi contristado?',
        'E escrevi-vos isto mesmo, para que, quando lá for, não tenha tristeza da parte dos que deveriam alegrar-me; confiando em vós todos, que a minha alegria é a de todos vós.',
        'Porque em muita tribulação e angústia do coração vos escrevi, com muitas lágrimas, não para que vos entristecêsseis, mas para que conhecêsseis o amor que abundantemente vos tenho.',
        'Porque, se alguém me contristou, não me contristou a mim senão em parte, para vos não sobrecarregar a vós todos.',
        'Basta-lhe ao tal esta repreensão feita por muitos.',
        'De maneira que pelo contrário deveis antes perdoar-lhe e consolá-lo, para que o tal não seja de modo algum devorado de demasiada tristeza.',
        'Por isso vos rogo que confirmeis para com ele o vosso amor.',
        'E para isso vos escrevi também, para por esta prova saber se sois obedientes em tudo.',
        'E a quem perdoardes alguma coisa, também eu; porque, o que eu também perdoei, se é que tenho perdoado, por amor de vós o fiz na presença de Cristo; para que não sejamos vencidos por Satanás;',
        'Porque não ignoramos os seus ardís.',
        'Ora, quando cheguei a Trôade para pregar o evangelho de Cristo, e abrindo-se-me uma porta no Senhor,',
        'Não tive descanso no meu espírito, porque não achei ali meu irmão Tito; mas, despedindo-me deles, parti para a macedônia.',
        'E graças a Deus, que sempre nos faz triunfar em Cristo, e por meio de nós manifesta em todo o lugar a fragrância do seu conhecimento.',
        'Porque para Deus somos o bom perfume de Cristo, nos que se salvam e nos que se perdem.',
        'Para estes certamente cheiro de morte para morte; mas para aqueles cheiro de vida para vida. E para estas coisas quem é idôneo?',
        'Porque nós não somos, como muitos, falsificadores da palavra de Deus, antes falamos de Cristo com sinceridade, como de Deus na presença de Deus.',
      ],
      [
        'Porventura começamos outra vez a louvar-nos a nós mesmos? Ou necessitamos, como alguns, de cartas de recomendação para vós, ou de recomendação de vós?',
        'Vós sois a nossa carta, escrita em nossos corações, conhecida e lida por todos os homens.',
        'Porque já é manifesto que vós sois a carta de Cristo, ministrada por nós, e escrita, não com tinta, mas com o Espírito do Deus vivo, não em tábuas de pedra, mas nas tábuas de carne do coração.',
        'E é por Cristo que temos tal confiança em Deus;',
        'Não que sejamos capazes, por nós, de pensar alguma coisa, como de nós mesmos; mas a nossa capacidade vem de Deus,',
        'O qual nos fez também capazes de ser ministros de um novo testamento, não da letra, mas do espírito; porque a letra mata e o espírito vivifica.',
        'E, se o ministério da morte, gravado com letras em pedras, veio em glória, de maneira que os filhos de Israel não podiam fitar os olhos na face de Moisés, por causa da glória do seu rosto, a qual era transitória,',
        'Como não será de maior glória o ministério do Espírito?',
        'Porque, se o ministério da condenação foi glorioso, muito mais excederá em glória o ministério da justiça.',
        'Porque também o que foi glorificado nesta parte não foi glorificado, por causa desta excelente glória.',
        'Porque, se o que era transitório foi para glória, muito mais é em glória o que permanece.',
        'Tendo, pois, tal esperança, usamos de muita ousadia no falar.',
        'E não somos como Moisés, que punha um véu sobre a sua face, para que os filhos de Israel não olhassem firmemente para o fim daquilo que era transitório.',
        'Mas os seus sentidos foram endurecidos; porque até hoje o mesmo véu está por levantar na lição do velho testamento, o qual foi por Cristo abolido;',
        'E até hoje, quando é lido Moisés, o véu está posto sobre o coração deles.',
        'Mas, quando se converterem ao Senhor, então o véu se tirará.',
        'Ora, o Senhor é o Espírito; e onde está o Espírito do Senhor, aí há liberdade.',
        'Mas todos nós, com rosto descoberto, refletindo como um espelho a glória do Senhor, somos transformados de glória em glória na mesma imagem, como pelo Espírito do Senhor.',
      ],
      [
        'Por isso, tendo este ministério, segundo a misericórdia que nos foi feita, não desfalecemos;',
        'Antes, rejeitamos as coisas que por vergonha se ocultam, não andando com astúcia nem falsificando a palavra de Deus; e assim nos recomendamos à consciência de todo o homem, na presença de Deus, pela manifestação da verdade.',
        'Mas, se ainda o nosso evangelho está encoberto, para os que se perdem está encoberto.',
        'Nos quais o deus deste século cegou os entendimentos dos incrédulos, para que lhes não resplandeça a luz do evangelho da glória de Cristo, que é a imagem de Deus.',
        'Porque não nos pregamos a nós mesmos, mas a Cristo Jesus, o Senhor; e nós mesmos somos vossos servos por amor de Jesus.',
        'Porque Deus, que disse que das trevas resplandecesse a luz, é quem resplandeceu em nossos corações, para iluminação do conhecimento da glória de Deus, na face de Jesus Cristo.',
        'Temos, porém, este tesouro em vasos de barro, para que a excelência do poder seja de Deus, e não de nós.',
        'Em tudo somos atribulados, mas não angustiados; perplexos, mas não desanimados.',
        'Perseguidos, mas não desamparados; abatidos, mas não destruídos;',
        'Trazendo sempre por toda a parte a mortificação do Senhor Jesus no nosso corpo, para que a vida de Jesus se manifeste também nos nossos corpos;',
        'E assim nós, que vivemos, estamos sempre entregues à morte por amor de Jesus, para que a vida de Jesus se manifeste também na nossa carne mortal.',
        'De maneira que em nós opera a morte, mas em vós a vida.',
        'E temos portanto o mesmo espírito de fé, como está escrito: Cri, por isso falei; nós cremos também, por isso também falamos.',
        'Sabendo que o que ressuscitou o Senhor Jesus nos ressuscitará também por Jesus, e nos apresentará convosco.',
        'Porque tudo isto é por amor de vós, para que a graça, multiplicada por meio de muitos, faça abundar a ação de graças para glória de Deus.',
        'Por isso não desfalecemos; mas, ainda que o nosso homem exterior se corrompa, o interior, contudo, se renova de dia em dia.',
        'Porque a nossa leve e momentânea tribulação produz para nós um peso eterno de glória mui excelente;',
        'Não atentando nós nas coisas que se vêem, mas nas que se não vêem; porque as que se vêem são temporais, e as que se não vêem são eternas.',
      ],
      [
        'Porque sabemos que, se a nossa casa terrestre deste tabernáculo se desfizer, temos de Deus um edifício, uma casa não feita por mãos, eterna, nos céus.',
        'E por isso também gememos, desejando ser revestidos da nossa habitação, que é do céu;',
        'Se, todavia, estando vestidos, não formos achados nus.',
        'Porque também nós, os que estamos neste tabernáculo, gememos carregados; não porque queremos ser despidos, mas revestidos, para que o mortal seja absorvido pela vida.',
        'Ora, quem para isto mesmo nos preparou foi Deus, o qual nos deu também o penhor do Espírito.',
        'Por isso estamos sempre de bom ânimo, sabendo que, enquanto estamos no corpo, vivemos ausentes do Senhor',
        '(Porque andamos por fé, e não por vista).',
        'Mas temos confiança e desejamos antes deixar este corpo, para habitar com o Senhor.',
        'Pois que muito desejamos também ser-lhe agradáveis, quer presentes, quer ausentes.',
        'Porque todos devemos comparecer ante o tribunal de Cristo, para que cada um receba segundo o que tiver feito por meio do corpo, ou bem, ou mal.',
        'Assim que, sabendo o temor que se deve ao Senhor, persuadimos os homens à fé, mas somos manifestos a Deus; e espero que nas vossas consciências sejamos também manifestos.',
        'Porque não nos recomendamos outra vez a vós; mas damo-vos ocasião de vos gloriardes de nós, para que tenhais que responder aos que se gloriam na aparência e não no coração.',
        'Porque, se enlouquecemos, é para Deus; e, se conservamos o juízo, é para vós.',
        'Porque o amor de Cristo nos constrange, julgando nós assim: que, se um morreu por todos, logo todos morreram.',
        'E ele morreu por todos, para que os que vivem não vivam mais para si, mas para aquele que por eles morreu e ressuscitou.',
        'Assim que daqui por diante a ninguém conhecemos segundo a carne, e, ainda que também tenhamos conhecido Cristo segundo a carne, contudo agora já não o conhecemos deste modo.',
        'Assim que, se alguém está em Cristo, nova criatura é; as coisas velhas já passaram; eis que tudo se fez novo.',
        'E tudo isto provém de Deus, que nos reconciliou consigo mesmo por Jesus Cristo, e nos deu o ministério da reconciliação;',
        'Isto é, Deus estava em Cristo reconciliando consigo o mundo, não lhes imputando os seus pecados; e pôs em nós a palavra da reconciliação.',
        'De sorte que somos embaixadores da parte de Cristo, como se Deus por nós rogasse. Rogamo-vos, pois, da parte de Cristo, que vos reconcilieis com Deus.',
        'Àquele que não conheceu pecado, o fez pecado por nós; para que nele fôssemos feitos justiça de Deus.',
      ],
      [
        'E nós, cooperando também com ele, vos exortamos a que não recebais a graça de Deus em vão',
        '(Porque diz:Ouvi-te em tempo aceitável e socorri-te no dia da salvação;eis aqui agora o tempo aceitável, eis aqui agora o dia da salvação).',
        'Não dando nós escândalo em coisa alguma, para que o nosso ministério não seja censurado;',
        'Antes, como ministros de Deus, tornando-nos recomendáveis em tudo; na muita paciência, nas aflições, nas necessidades, nas angústias,',
        'Nos açoites, nas prisões, nos tumultos, nos trabalhos, nas vigílias, nos jejuns,',
        'Na pureza, na ciência, na longanimidade, na benignidade, no Espírito Santo, no amor não fingido,',
        'Na palavra da verdade, no poder de Deus, pelas armas da justiça, à direita e à esquerda,',
        'Por honra e por desonra, por infâmia e por boa fama; como enganadores, e sendo verdadeiros;',
        'Como desconhecidos, mas sendo bem conhecidos; como morrendo, e eis que vivemos; como castigados, e não mortos;',
        'Como contristados, mas sempre alegres; como pobres, mas enriquecendo a muitos; como nada tendo, e possuindo tudo.',
        'Ó coríntios, a nossa boca está aberta para vós, o nosso coração está dilatado.',
        'Não estais estreitados em nós; mas estais estreitados nos vossos próprios afetos.',
        'Ora, em recompensa disto, (falo como a filhos) dilatai-vos também vós.',
        'Não vos prendais a um jugo desigual com os infiéis; porque, que sociedade tem a justiça com a injustiça? E que comunhão tem a luz com as trevas?',
        'E que concórdia há entre Cristo e Belial? Ou que parte tem o fiel com o infiel?',
        'E que consenso tem o templo de Deus com os ídolos? Porque vós sois o templo do Deus vivente, como Deus disse: Neles habitarei, e entre eles andarei; e eu serei o seu Deus e eles serão o meu povo.',
        'Por isso saí do meio deles, e apartai-vos, diz o Senhor;E não toqueis nada imundo,E eu vos receberei;',
        'E eu serei para vós Pai,E vós sereis para mim filhos e filhas, Diz o Senhor Todo-Poderoso.',
      ],
      [
        'Ora, amados, pois que temos tais promessas, purifiquemo-nos de toda a imundícia da carne e do espírito, aperfeiçoando a santificação no temor de Deus.',
        'Recebei-nos em vossos corações; a ninguém agravamos, a ninguém corrompemos, de ninguém buscamos o nosso proveito.',
        'Não digo isto para vossa condenação; pois já antes tinha dito que estais em nossos corações para juntamente morrer e viver.',
        'Grande é a ousadia da minha fala para convosco, e grande a minha jactância a respeito de vós; estou cheio de consolação; transbordo de gozo em todas as nossas tribulações.',
        'Porque, mesmo quando chegamos à macedônia, a nossa carne não teve repouso algum; antes em tudo fomos atribulados: por fora combates, temores por dentro.',
        'Mas Deus, que consola os abatidos, nos consolou com a vinda de Tito.',
        'E não somente com a sua vinda, mas também pela consolação com que foi consolado por vós, contando-nos as vossas saudades, o vosso choro, o vosso zelo por mim, de maneira que muito me regozijei.',
        'Porquanto, ainda que vos contristei com a minha carta, não me arrependo, embora já me tivesse arrependido por ver que aquela carta vos contristou, ainda que por pouco tempo.',
        'Agora folgo, não porque fostes contristados, mas porque fostes contristados para arrependimento; pois fostes contristados segundo Deus; de maneira que por nós não padecestes dano em coisa alguma.',
        'Porque a tristeza segundo Deus opera arrependimento para a salvação, da qual ninguém se arrepende; mas a tristeza do mundo opera a morte.',
        'Porque, quanto cuidado não produziu isto mesmo em vós que, segundo Deus, fostes contristados! que apologia, que indignação, que temor, que saudades, que zelo, que vingança! Em tudo mostrastes estar puros neste negócio.',
        'Portanto, ainda que vos escrevi, não foi por causa do que fez o agravo, nem por causa do que sofreu o agravo, mas para que o vosso grande cuidado por nós fosse manifesto diante de Deus.',
        'Por isso fomos consolados pela vossa consolação, e muito mais nos alegramos pela alegria de Tito, porque o seu espírito foi recreado por vós todos.',
        'Porque, se nalguma coisa me gloriei de vós para com ele, não fiquei envergonhado; mas, como vos dissemos tudo com verdade, também a nossa glória para com Tito se achou verdadeira.',
        'E o seu entranhável afeto para convosco é mais abundante, lembrando-se da obediência de vós todos, e de como o recebestes com temor e tremor.',
        'Regozijo-me de em tudo poder confiar em vós.',
      ],
      [
        'Também, irmãos, vos fazemos conhecer a graça de Deus dada às igrejas da macedônia;',
        'Como em muita prova de tribulação houve abundância do seu gozo, e como a sua profunda pobreza abundou em riquezas da sua generosidade.',
        'Porque, segundo o seu poder (o que eu mesmo testifico) e ainda acima do seu poder, deram voluntariamente.',
        'Pedindo-nos com muitos rogos que aceitássemos a graça e a comunicação deste serviço, que se fazia para com os santos.',
        'E não somente fizeram como nós esperávamos, mas a si mesmos se deram primeiramente ao Senhor, e depois a nós, pela vontade de Deus.',
        'De maneira que exortamos a Tito que, assim como antes tinha começado, assim também acabasse esta graça entre vós.',
        'Portanto, assim como em tudo abundais em fé, e em palavra, e em ciência, e em toda a diligência, e em vosso amor para conosco, assim também abundeis nesta graça.',
        'Não digo isto como quem manda, mas para provar, pela diligência dos outros, a sinceridade de vosso amor.',
        'Porque já sabeis a graça de nosso Senhor Jesus Cristo que, sendo rico, por amor de vós se fez pobre; para que pela sua pobreza enriquecêsseis.',
        'E nisto dou o meu parecer; pois isto convém a vós que, desde o ano passado, começastes; e não foi só praticar, mas também querer.',
        'Agora, porém, completai também o já começado, para que, assim como houve a prontidão de vontade, haja também o cumprimento, segundo o que tendes.',
        'Porque, se há prontidão de vontade, será aceita segundo o que qualquer tem, e não segundo o que não tem.',
        'Mas, não digo isto para que os outros tenham alívio, e vós opressão,',
        'Mas para igualdade; neste tempo presente, a vossa abundância supra a falta dos outros, para que também a sua abundância supra a vossa falta, e haja igualdade;',
        'Como está escrito: O que muito colheu não teve demais; e o que pouco, não teve de menos.',
        'Mas, graças a Deus, que pôs a mesma solicitude por vós no coração de Tito;',
        'Pois aceitou a exortação, e muito diligente partiu voluntariamente para vós.',
        'E com ele enviamos aquele irmão cujo louvor no evangelho está espalhado em todas as igrejas.',
        'E não só isto, mas foi também escolhido pelas igrejas para companheiro da nossa viagem, nesta graça que por nós é ministrada para glória do mesmo Senhor, e prontidão do vosso ânimo;',
        'Evitando isto, que alguém nos vitupere por esta abundância, que por nós é ministrada;',
        'Pois zelamos do que é honesto, não só diante do Senhor, mas também diante dos homens.',
        'Com eles enviamos também outro nosso irmão, o qual muitas vezes, e em muitas coisas, já experimentamos ser diligente, e agora muito mais diligente ainda pela muita confiança que em vós tem.',
        'Quanto a Tito, é meu companheiro, e cooperador para convosco; quanto a nossos irmãos, são embaixadores das igrejas e glória de Cristo.',
        'Portanto, mostrai para com eles, e perante a face das igrejas, a prova do vosso amor, e da nossa glória acerca de vós.',
      ],
      [
        'Quanto à administração que se faz a favor dos santos, não necessito escrever-vos;',
        'Porque bem sei a prontidão do vosso ânimo, da qual me glorio de vós para com os macedônios; que a Acaia está pronta desde o ano passado; e o vosso zelo tem estimulado muitos.',
        'Mas enviei estes irmãos, para que a nossa glória, acerca de vós, não seja vã nesta parte; para que (como já disse) possais estar prontos,',
        'A fim de, se acaso os macedônios vierem comigo, e vos acharem desapercebidos, não nos envergonharmos nós (para não dizermos vós) deste firme fundamento de glória.',
        'Portanto, tive por coisa necessária exortar estes irmãos, para que primeiro fossem ter convosco, e preparassem de antemão a vossa bênção, já antes anunciada, para que esteja pronta como bênção, e não como avareza.',
        'E digo isto: Que o que semeia pouco, pouco também ceifará; e o que semeia em abundância, em abundância ceifará.',
        'Cada um contribua segundo propôs no seu coração; não com tristeza, ou por necessidade; porque Deus ama ao que dá com alegria.',
        'E Deus é poderoso para fazer abundar em vós toda a graça, a fim de que tendo sempre, em tudo, toda a suficiência, abundeis em toda a boa obra;',
        'Conforme está escrito:Espalhou, deu aos pobres;a sua justiça permanece para sempre.',
        'Ora, aquele que dá a semente ao que semeia, também vos dê pão para comer, e multiplique a vossa sementeira, e aumente os frutos da vossa justiça;',
        'Para que em tudo enriqueçais para toda a beneficência, a qual faz que por nós se dêem graças a Deus.',
        'Porque a administração deste serviço, não só supre as necessidades dos santos, mas também é abundante em muitas graças, que se dão a Deus.',
        'Visto como, na prova desta administração, glorificam a Deus pela submissão, que confessais quanto ao evangelho de Cristo, e pela liberalidade de vossos dons para com eles, e para com todos;',
        'E pela sua oração por vós, tendo de vós saudades, por causa da excelente graça de Deus que em vós há.',
        'Graças a Deus, pois, pelo seu dom inefável.',
      ],
      [
        'Além disto, eu, Paulo, vos rogo, pela mansidão e benignidade de Cristo, eu que, na verdade, quando presente entre vós, sou humilde, mas ausente, ousado para convosco;',
        'Rogo-vos, pois, que, quando estiver presente, não me veja obrigado a usar com confiança da ousadia que espero ter com alguns, que nos julgam, como se andássemos segundo a carne.',
        'Porque, andando na carne, não militamos segundo a carne.',
        'Porque as armas da nossa milícia não são carnais, mas sim poderosas em Deus para destruição das fortalezas;',
        'Destruindo os conselhos, e toda a altivez que se levanta contra o conhecimento de Deus, e levando cativo todo o entendimento à obediência de Cristo;',
        'E estando prontos para vingar toda a desobediência, quando for cumprida a vossa obediência.',
        'Olhais para as coisas segundo a aparência? Se alguém confia de si mesmo que é de Cristo, pense outra vez isto consigo, que, assim como ele é de Cristo, também nós de Cristo somos.',
        'Porque, ainda que eu me glorie mais alguma coisa do nosso poder, o qual o Senhor nos deu para edificação, e não para vossa destruição, não me envergonharei.',
        'Para que não pareça como se quisera intimidar-vos por cartas.',
        'Porque as suas cartas, dizem, são graves e fortes, mas a presença do corpo é fraca, e a palavra desprezível.',
        'Pense o tal isto, que, quais somos na palavra por cartas, estando ausentes, tais seremos também por obra, estando presentes.',
        'Porque não ousamos classificar-nos, ou comparar-nos com alguns, que se louvam a si mesmos; mas estes que se medem a si mesmos, e se comparam consigo mesmos, estão sem entendimento.',
        'Porém, não nos gloriaremos fora da medida, mas conforme a reta medida que Deus nos deu, para chegarmos até vós;',
        'Porque não nos estendemos além do que convém, como se não houvéssemos de chegar até vós, pois já chegamos também até vós no evangelho de Cristo,',
        'Não nos gloriando fora da medida nos trabalhos alheios; antes tendo esperança de que, crescendo a vossa fé, seremos abundantemente engrandecidos entre vós, conforme a nossa regra,',
        'Para anunciar o evangelho nos lugares que estão além de vós e não em campo de outrem, para não nos gloriarmos no que estava já preparado.',
        'Aquele, porém, que se gloria, glorie-se no Senhor.',
        'Porque não é aprovado quem a si mesmo se louva, mas, sim, aquele a quem o Senhor louva.',
      ],
      [
        'Quisera eu me suportásseis um pouco na minha loucura! Suportai-me, porém, ainda.',
        'Porque estou zeloso de vós com zelo de Deus; porque vos tenho preparado para vos apresentar como uma virgem pura a um marido, a saber, a Cristo.',
        'Mas temo que, assim como a serpente enganou Eva com a sua astúcia, assim também sejam de alguma sorte corrompidos os vossos sentidos, e se apartem da simplicidade que há em Cristo.',
        'Porque, se alguém for pregar-vos outro Jesus que nós não temos pregado, ou se recebeis outro espírito que não recebestes, ou outro evangelho que não abraçastes, com razão o sofreríeis.',
        'Porque penso que em nada fui inferior aos mais excelentes apóstolos.',
        'E, se sou rude na palavra, não o sou contudo na ciência; mas já em todas as coisas nos temos feito conhecer totalmente entre vós.',
        'Pequei, porventura, humilhando-me a mim mesmo, para que vós fôsseis exaltados, porque de graça vos anunciei o evangelho de Deus?',
        'Outras igrejas despojei eu para vos servir, recebendo delas salário; e quando estava presente convosco, e tinha necessidade, a ninguém fui pesado.',
        'Porque os irmãos que vieram da macedônia supriram a minha necessidade; e em tudo me guardei de vos ser pesado, e ainda me guardarei.',
        'Como a verdade de Cristo está em mim, esta glória não me será impedida nas regiões da Acaia.',
        'Por quê? Porque não vos amo? Deus o sabe.',
        'Mas o que eu faço o farei, para cortar ocasião aos que buscam ocasião, a fim de que, naquilo em que se gloriam, sejam achados assim como nós.',
        'Porque tais falsos apóstolos são obreiros fraudulentos, transfigurando-se em apóstolos de Cristo.',
        'E não é maravilha, porque o próprio Satanás se transfigura em anjo de luz.',
        'Não é muito, pois, que os seus ministros se transfigurem em ministros da justiça; o fim dos quais será conforme as suas obras.',
        'Outra vez digo: Ninguém me julgue insensato, ou então recebei-me como insensato, para que também me glorie um pouco.',
        'O que digo, não o digo segundo o Senhor, mas como por loucura, nesta confiança de gloriar-me.',
        'Pois que muitos se gloriam segundo a carne, eu também me gloriarei.',
        'Porque, sendo vós sensatos, de boa mente tolerais os insensatos.',
        'Pois sois sofredores, se alguém vos põe em servidão, se alguém vos devora, se alguém vos apanha, se alguém se exalta, se alguém vos fere no rosto.',
        'Envergonhado o digo, como se nós fôssemos fracos, mas no que qualquer tem ousadia (com insensatez falo) também eu tenho ousadia.',
        'São hebreus? também eu. São israelitas? também eu. São descendência de Abraão? também eu.',
        'São ministros de Cristo? (falo como fora de mim) eu ainda mais: em trabalhos, muito mais; em açoites, mais do que eles; em prisões, muito mais; em perigo de morte, muitas vezes.',
        'Recebi dos judeus cinco quarentenas de açoites menos um.',
        'Três vezes fui açoitado com varas, uma vez fui apedrejado, três vezes sofri naufrágio, uma noite e um dia passei no abismo;',
        'Em viagens muitas vezes, em perigos de rios, em perigos de salteadores, em perigos dos da minha nação, em perigos dos gentios, em perigos na cidade, em perigos no deserto, em perigos no mar, em perigos entre os falsos irmãos;',
        'Em trabalhos e fadiga, em vigílias muitas vezes, em fome e sede, em jejum muitas vezes, em frio e nudez.',
        'Além das coisas exteriores, me oprime cada dia o cuidado de todas as igrejas.',
        'Quem enfraquece, que eu também não enfraqueça? Quem se escandaliza, que eu me não abrase?',
        'Se convém gloriar-me, gloriar-me-ei no que diz respeito à minha fraqueza.',
        'O Deus e Pai de nosso Senhor Jesus Cristo, que é eternamente bendito, sabe que não minto.',
        'Em Damasco, o que governava sob o rei Aretas pôs guardas às portas da cidade dos damascenos, para me prenderem.',
        'E fui descido num cesto por uma janela da muralha; e assim escapei das suas mãos.',
      ],
      [
        'Em verdade que não convém gloriar-me; mas passarei às visões e revelações do Senhor.',
        'Conheço um homem em Cristo que há catorze anos (se no corpo, não sei, se fora do corpo, não sei; Deus o sabe) foi arrebatado ao terceiro céu.',
        'E sei que o tal homem (se no corpo, se fora do corpo, não sei; Deus o sabe)',
        'Foi arrebatado ao paraíso; e ouviu palavras inefáveis, que ao homem não é lícito falar.',
        'De alguém assim me gloriarei eu, mas de mim mesmo não me gloriarei, senão nas minhas fraquezas.',
        'Porque, se quiser gloriar-me, não serei néscio, porque direi a verdade; mas deixo isto, para que ninguém cuide de mim mais do que em mim vê ou de mim ouve.',
        'E, para que não me exaltasse pela excelência das revelações, foi-me dado um espinho na carne, a saber, um mensageiro de Satanás para me esbofetear, a fim de não me exaltar.',
        'Acerca do qual três vezes orei ao Senhor para que se desviasse de mim.',
        'E disse-me: A minha graça te basta, porque o meu poder se aperfeiçoa na fraqueza. De boa vontade, pois, me gloriarei nas minhas fraquezas, para que em mim habite o poder de Cristo.',
        'Por isso sinto prazer nas fraquezas, nas injúrias, nas necessidades, nas perseguições, nas angústias por amor de Cristo. Porque quando estou fraco então sou forte.',
        'Fui néscio em gloriar-me; vós me constrangestes. Eu devia ter sido louvado por vós, visto que em nada fui inferior aos mais excelentes apóstolos, ainda que nada sou.',
        'Os sinais do meu apostolado foram manifestados entre vós com toda a paciência, por sinais, prodígios e maravilhas.',
        'Pois, em que tendes vós sido inferiores às outras igrejas, a não ser que eu mesmo vos não fui pesado? Perdoai-me este agravo.',
        'Eis aqui estou pronto para pela terceira vez ir ter convosco, e não vos serei pesado, pois que não busco o que é vosso, mas sim a vós: porque não devem os filhos entesourar para os pais, mas os pais para os filhos.',
        'Eu de muito boa vontade gastarei, e me deixarei gastar pelas vossas almas, ainda que, amando-vos cada vez mais, seja menos amado.',
        'Mas seja assim; eu não vos fui pesado mas, sendo astuto, vos tomei com dolo.',
        'Porventura aproveitei-me de vós por algum daqueles que vos enviei?',
        'Roguei a Tito, e enviei com ele um irmão. Porventura Tito se aproveitou de vós? Não andamos porventura no mesmo espírito, sobre as mesmas pisadas?',
        'Cuidais que ainda nos desculpamos convosco? Falamos em Cristo perante Deus, e tudo isto, ó amados, para vossa edificação.',
        'Porque receio que, quando chegar, não vos ache como eu quereria, e eu seja achado de vós como não quereríeis; que de alguma maneira haja pendências, invejas, iras, porfias, detrações, mexericos, orgulhos, tumultos;',
        'Que, quando for outra vez, o meu Deus me humilhe para convosco, e chore por muitos daqueles que dantes pecaram, e não se arrependeram da imundícia, e fornicação, e desonestidade que cometeram.',
      ],
      [
        'É esta a terceira vez que vou ter convosco. Por boca de duas ou três testemunhas será confirmada toda a palavra.',
        'Já anteriormente o disse, e segunda vez o digo como quando estava presente; mas agora, estando ausente, o escrevo aos que antes pecaram e a todos os mais, que, se outra vez for, não lhes perdoarei;',
        'Visto que buscais uma prova de Cristo que fala em mim, o qual não é fraco para convosco, antes é poderoso entre vós.',
        'Porque, ainda que foi crucificado por fraqueza, vive, contudo, pelo poder de Deus. Porque nós também somos fracos nele, mas viveremos com ele pelo poder de Deus em vós.',
        'Examinai-vos a vós mesmos, se permaneceis na fé; provai-vos a vós mesmos. Ou não sabeis quanto a vós mesmos, que Jesus Cristo está em vós? Se não é que já estais reprovados.',
        'Mas espero que entendereis que nós não somos reprovados.',
        'Ora, eu rogo a Deus que não façais mal algum, não para que sejamos achados aprovados, mas para que vós façais o bem, embora nós sejamos como reprovados.',
        'Porque nada podemos contra a verdade, senão pela verdade.',
        'Porque nos regozijamos de estar fracos, quando vós estais fortes; e o que desejamos é a vossa perfeição.',
        'Portanto, escrevo estas coisas estando ausente, para que, estando presente, não use de rigor, segundo o poder que o Senhor me deu para edificação, e não para destruição.',
        'Quanto ao mais, irmãos, regozijai-vos, sede perfeitos, sede consolados, sede de um mesmo parecer, vivei em paz; e o Deus de amor e de paz será convosco.',
        'Saudai-vos uns aos outros com ósculo santo.',
        'Todos os santos vos saúdam.',
        'A graça do Senhor Jesus Cristo, e o amor de Deus, e a comunhão do Espírito Santo seja com todos vós. Amém.',
      ],
    ],
    livro: '2corintios',
  },
  {
    abbrev: 'gl',
    capitulos: [
      [
        'Paulo, apóstolo (não da parte dos homens, nem por homem algum, mas por Jesus Cristo, e por Deus Pai, que o ressuscitou dentre os mortos),',
        'E todos os irmãos que estão comigo, às igrejas da Galácia:',
        'Graça e paz da parte de Deus Pai e do nosso Senhor Jesus Cristo,',
        'O qual se deu a si mesmo por nossos pecados, para nos livrar do presente século mau, segundo a vontade de Deus nosso Pai,',
        'Ao qual seja dada glória para todo o sempre. Amém.',
        'Maravilho-me de que tão depressa passásseis daquele que vos chamou à graça de Cristo para outro evangelho;',
        'O qual não é outro, mas há alguns que vos inquietam e querem transtornar o evangelho de Cristo.',
        'Mas, ainda que nós mesmos ou um anjo do céu vos anuncie outro evangelho além do que já vos tenho anunciado, seja anátema.',
        'Assim, como já vo-lo dissemos, agora de novo também vo-lo digo. Se alguém vos anunciar outro evangelho além do que já recebestes, seja anátema.',
        'Porque, persuado eu agora a homens ou a Deus? ou procuro agradar a homens? Se estivesse ainda agradando aos homens, não seria servo de Cristo.',
        'Mas faço-vos saber, irmãos, que o evangelho que por mim foi anunciado não é segundo os homens.',
        'Porque não o recebi, nem aprendi de homem algum, mas pela revelação de Jesus Cristo.',
        'Porque já ouvistes qual foi antigamente a minha conduta no judaísmo, como sobremaneira perseguia a igreja de Deus e a assolava.',
        'E na minha nação excedia em judaísmo a muitos da minha idade, sendo extremamente zeloso das tradições de meus pais.',
        'Mas, quando aprouve a Deus, que desde o ventre de minha mãe me separou, e me chamou pela sua graça,',
        'Revelar seu Filho em mim, para que o pregasse entre os gentios, não consultei a carne nem o sangue,',
        'Nem tornei a Jerusalém, a ter com os que já antes de mim eram apóstolos, mas parti para a Arábia, e voltei outra vez a Damasco.',
        'Depois, passados três anos, fui a Jerusalém para ver a Pedro, e fiquei com ele quinze dias.',
        'E não vi a nenhum outro dos apóstolos, senão a Tiago, irmão do Senhor.',
        'Ora, acerca do que vos escrevo, eis que diante de Deus testifico que não minto.',
        'Depois fui para as partes da Síria e da Cilícia.',
        'E não era conhecido de vista das igrejas da Judéia, que estavam em Cristo;',
        'Mas somente tinham ouvido dizer: Aquele que já nos perseguiu anuncia agora a fé que antes destruía.',
        'E glorificavam a Deus a respeito de mim.',
      ],
      [
        'Depois, passados catorze anos, subi outra vez a Jerusalém com Barnabé, levando também comigo Tito.',
        'E subi por uma revelação, e lhes expus o evangelho, que prego entre os gentios, e particularmente aos que estavam em estima; para que de maneira alguma não corresse ou não tivesse corrido em vão.',
        'Mas nem ainda Tito, que estava comigo, sendo grego, foi constrangido a circuncidar-se;',
        'E isto por causa dos falsos irmãos que se intrometeram, e secretamente entraram a espiar a nossa liberdade, que temos em Cristo Jesus, para nos porem em servidão;',
        'Aos quais nem ainda por uma hora cedemos com sujeição, para que a verdade do evangelho permanecesse entre vós.',
        'E, quanto àqueles que pareciam ser alguma coisa (quais tenham sido noutro tempo, não se me dá; Deus não aceita a aparência do homem), esses, digo, que pareciam ser alguma coisa, nada me comunicaram;',
        'Antes, pelo contrário, quando viram que o evangelho da incircuncisão me estava confiado, como a Pedro o da circuncisão',
        '(Porque aquele que operou eficazmente em Pedro para o apostolado da circuncisão, esse operou também em mim com eficácia para com os gentios),',
        'E conhecendo Tiago, Cefas e João, que eram considerados como as colunas, a graça que me havia sido dada, deram-nos as destras, em comunhão comigo e com Barnabé, para que nós fôssemos aos gentios, e eles à circuncisão;',
        'Recomendando-nos somente que nos lembrássemos dos pobres, o que também procurei fazer com diligência.',
        'E, chegando Pedro à Antioquia, lhe resisti na cara, porque era repreensível.',
        'Porque, antes que alguns tivessem chegado da parte de Tiago, comia com os gentios; mas, depois que chegaram, se foi retirando, e se apartou deles, temendo os que eram da circuncisão.',
        'E os outros judeus também dissimulavam com ele, de maneira que até Barnabé se deixou levar pela sua dissimulação.',
        'Mas, quando vi que não andavam bem e direitamente conforme a verdade do evangelho, disse a Pedro na presença de todos: Se tu, sendo judeu, vives como os gentios, e não como judeu, por que obrigas os gentios a viverem como judeus?',
        'Nós somos judeus por natureza, e não pecadores dentre os gentios.',
        'Sabendo que o homem não é justificado pelas obras da lei, mas pela fé em Jesus Cristo, temos também crido em Jesus Cristo, para sermos justificados pela fé em Cristo, e não pelas obras da lei; porquanto pelas obras da lei nenhuma carne será justificada.',
        'Pois, se nós, que procuramos ser justificados em Cristo, nós mesmos também somos achados pecadores, é porventura Cristo ministro do pecado? De maneira nenhuma.',
        'Porque, se torno a edificar aquilo que destruí, constituo-me a mim mesmo transgressor.',
        'Porque eu, pela lei, estou morto para a lei, para viver para Deus.',
        'Já estou crucificado com Cristo; e vivo, não mais eu, mas Cristo vive em mim; e a vida que agora vivo na carne, vivo-a pela fé do Filho de Deus, o qual me amou, e se entregou a si mesmo por mim.',
        'Não aniquilo a graça de Deus; porque, se a justiça provém da lei, segue-se que Cristo morreu debalde.',
      ],
      [
        'Ó insensatos gálatas! quem vos fascinou para não obedecerdes à verdade, a vós, perante os olhos de quem Jesus Cristo foi evidenciado, crucificado, entre vós?',
        'Só quisera saber isto de vós: recebestes o Espírito pelas obras da lei ou pela pregação da fé?',
        'Sois vós tão insensatos que, tendo começado pelo Espírito, acabeis agora pela carne?',
        'Será em vão que tenhais padecido tanto? Se é que isso também foi em vão.',
        'Aquele, pois, que vos dá o Espírito, e que opera maravilhas entre vós, o faz pelas obras da lei, ou pela pregação da fé?',
        'Assim como Abraão creu em Deus, e isso lhe foi imputado como justiça.',
        'Sabei, pois, que os que são da fé são filhos de Abraão.',
        'Ora, tendo a Escritura previsto que Deus havia de justificar pela fé os gentios, anunciou primeiro o evangelho a Abraão, dizendo: Todas as nações serão benditas em ti.',
        'De sorte que os que são da fé são benditos com o crente Abraão.',
        'Todos aqueles, pois, que são das obras da lei estão debaixo da maldição; porque está escrito: Maldito todo aquele que não permanecer em todas as coisas que estão escritas no livro da lei, para fazê-las.',
        'E é evidente que pela lei ninguém será justificado diante de Deus, porque o justo viverá pela fé.',
        'Ora, a lei não é da fé; mas o homem, que fizer estas coisas, por elas viverá.',
        'Cristo nos resgatou da maldição da lei, fazendo-se maldição por nós; porque está escrito: Maldito todo aquele que for pendurado no madeiro;',
        'Para que a bênção de Abraão chegasse aos gentios por Jesus Cristo, e para que pela fé nós recebamos a promessa do Espírito.',
        'Irmãos, como homem falo; se a aliança de um homem for confirmada, ninguém a anula nem a acrescenta.',
        'Ora, as promessas foram feitas a Abraão e à sua descendência. Não diz: E às descendências, como falando de muitas, mas como de uma só: E à tua descendência, que é Cristo.',
        'Mas digo isto: Que tendo sido a aliança anteriormente confirmada por Deus em Cristo, a lei, que veio quatrocentos e trinta anos depois, não a invalida, de forma a abolir a promessa.',
        'Porque, se a herança provém da lei, já não provém da promessa; mas Deus pela promessa a deu gratuitamente a Abraão.',
        'Logo, para que é a lei? Foi ordenada por causa das transgressões, até que viesse a posteridade a quem a promessa tinha sido feita; e foi posta pelos anjos na mão de um medianeiro.',
        'Ora, o medianeiro não o é de um só, mas Deus é um.',
        'Logo, a lei é contra as promessas de Deus? De nenhuma sorte; porque, se fosse dada uma lei que pudesse vivificar, a justiça, na verdade, teria sido pela lei.',
        'Mas a Escritura encerrou tudo debaixo do pecado, para que a promessa pela fé em Jesus Cristo fosse dada aos crentes.',
        'Mas, antes que a fé viesse, estávamos guardados debaixo da lei, e encerrados para aquela fé que se havia de manifestar.',
        'De maneira que a lei nos serviu de aio, para nos conduzir a Cristo, para que pela fé fôssemos justificados.',
        'Mas, depois que veio a fé, já não estamos debaixo de aio.',
        'Porque todos sois filhos de Deus pela fé em Cristo Jesus.',
        'Porque todos quantos fostes batizados em Cristo já vos revestistes de Cristo.',
        'Nisto não há judeu nem grego; não há servo nem livre; não há macho nem fêmea; porque todos vós sois um em Cristo Jesus.',
        'E, se sois de Cristo, então sois descendência de Abraão, e herdeiros conforme a promessa.',
      ],
      [
        'Digo, pois, que todo o tempo que o herdeiro é menino em nada difere do servo, ainda que seja senhor de tudo;',
        'Mas está debaixo de tutores e curadores até ao tempo determinado pelo pai.',
        'Assim também nós, quando éramos meninos, estávamos reduzidos à servidão debaixo dos primeiros rudimentos do mundo.',
        'Mas, vindo a plenitude dos tempos, Deus enviou seu Filho, nascido de mulher, nascido sob a lei,',
        'Para remir os que estavam debaixo da lei, a fim de recebermos a adoção de filhos.',
        'E, porque sois filhos, Deus enviou aos vossos corações o Espírito de seu Filho, que clama: Aba, Pai.',
        'Assim que já não és mais servo, mas filho; e, se és filho, és também herdeiro de Deus por Cristo.',
        'Mas, quando não conhecíeis a Deus, servíeis aos que por natureza não são deuses.',
        'Mas agora, conhecendo a Deus, ou, antes, sendo conhecidos por Deus, como tornais outra vez a esses rudimentos fracos e pobres, aos quais de novo quereis servir?',
        'Guardais dias, e meses, e tempos, e anos.',
        'Receio de vós, que não haja trabalhado em vão para convosco.',
        'Irmãos, rogo-vos que sejais como eu, porque também eu sou como vós; nenhum mal me fizestes.',
        'E vós sabeis que primeiro vos anunciei o evangelho estando em fraqueza da carne;',
        'E não rejeitastes, nem desprezastes isso que era uma tentação na minha carne, antes me recebestes como um anjo de Deus, como Jesus Cristo mesmo.',
        'Qual é, logo, a vossa bem-aventurança? Porque vos dou testemunho de que, se possível fora, arrancaríeis os vossos olhos, e mos daríeis.',
        'Fiz-me acaso vosso inimigo, dizendo a verdade?',
        'Eles têm zelo por vós, não como convém; mas querem excluir-vos, para que vós tenhais zelo por eles.',
        'É bom ser zeloso, mas sempre do bem, e não somente quando estou presente convosco.',
        'Meus filhinhos, por quem de novo sinto as dores de parto, até que Cristo seja formado em vós;',
        'Eu bem quisera agora estar presente convosco, e mudar a minha voz; porque estou perplexo a vosso respeito.',
        'Dizei-me, os que quereis estar debaixo da lei, não ouvis vós a lei?',
        'Porque está escrito que Abraão teve dois filhos, um da escrava, e outro da livre.',
        'Todavia, o que era da escrava nasceu segundo a carne, mas, o que era da livre, por promessa.',
        'O que se entende por alegoria; porque estas são as duas alianças; uma, do monte Sinai, gerando filhos para a servidão, que é Agar.',
        'Ora, esta Agar é Sinai, um monte da Arábia, que corresponde à Jerusalém que agora existe, pois é escrava com seus filhos.',
        'Mas a Jerusalém que é de cima é livre; a qual é mãe de todos nós.',
        'Porque está escrito: Alegra-te, estéril, que não dás à luz; Esforça-te e clama, tu que não estás de parto; Porque os filhos da solitária são mais do que os da que tem marido.',
        'Mas nós, irmãos, somos filhos da promessa como Isaque.',
        'Mas, como então aquele que era gerado segundo a carne perseguia o que o era segundo o Espírito, assim é também agora.',
        'Mas que diz a Escritura? Lança fora a escrava e seu filho, porque de modo algum o filho da escrava herdará com o filho da livre.',
        'De maneira que, irmãos, somos filhos, não da escrava, mas da livre.',
      ],
      [
        'Estai, pois, firmes na liberdade com que Cristo nos libertou, e não torneis a colocar-vos debaixo do jugo da servidão.',
        'Eis que eu, Paulo, vos digo que, se vos deixardes circuncidar, Cristo de nada vos aproveitará.',
        'E de novo protesto a todo o homem, que se deixa circuncidar, que está obrigado a guardar toda a lei.',
        'Separados estais de Cristo, vós os que vos justificais pela lei; da graça tendes caído.',
        'Porque nós pelo Espírito da fé aguardamos a esperança da justiça.',
        'Porque em Jesus Cristo nem a circuncisão nem a incircuncisão tem valor algum; mas sim a fé que opera pelo amor.',
        'Corríeis bem; quem vos impediu, para que não obedeçais à verdade?',
        'Esta persuasão não vem daquele que vos chamou.',
        'Um pouco de fermento leveda toda a massa.',
        'Confio de vós, no Senhor, que nenhuma outra coisa sentireis; mas aquele que vos inquieta, seja ele quem for, sofrerá a condenação.',
        'Eu, porém, irmãos, se prego ainda a circuncisão, por que sou, pois, perseguido? Logo o escândalo da cruz está aniquilado.',
        'Eu quereria que fossem cortados aqueles que vos andam inquietando.',
        'Porque vós, irmãos, fostes chamados à liberdade. Não useis então da liberdade para dar ocasião à carne, mas servi-vos uns aos outros pelo amor.',
        'Porque toda a lei se cumpre numa só palavra, nesta: Amarás ao teu próximo como a ti mesmo.',
        'Se vós, porém, vos mordeis e devorais uns aos outros, vede não vos consumais também uns aos outros.',
        'Digo, porém: Andai em Espírito, e não cumprireis a concupiscência da carne.',
        'Porque a carne cobiça contra o Espírito, e o Espírito contra a carne; e estes opõem-se um ao outro, para que não façais o que quereis.',
        'Mas, se sois guiados pelo Espírito, não estais debaixo da lei.',
        'Porque as obras da carne são manifestas, as quais são: adultério, fornicação, impureza, lascívia,',
        'Idolatria, feitiçaria, inimizades, porfias, emulações, iras, pelejas, dissensões, heresias,',
        'Invejas, homicídios, bebedices, glutonarias, e coisas semelhantes a estas, acerca das quais vos declaro, como já antes vos disse, que os que cometem tais coisas não herdarão o reino de Deus.',
        'Mas o fruto do Espírito é: amor, gozo, paz, longanimidade, benignidade, bondade, fé, mansidão, temperança.',
        'Contra estas coisas não há lei.',
        'E os que são de Cristo crucificaram a carne com as suas paixões e concupiscências.',
        'Se vivemos em Espírito, andemos também em Espírito.',
        'Não sejamos cobiçosos de vanglórias, irritando-nos uns aos outros, invejando-nos uns aos outros.',
      ],
      [
        'Irmãos, se algum homem chegar a ser surpreendido nalguma ofensa, vós, que sois espirituais, encaminhai o tal com espírito de mansidão; olhando por ti mesmo, para que não sejas também tentado.',
        'Levai as cargas uns dos outros, e assim cumprireis a lei de Cristo.',
        'Porque, se alguém cuida ser alguma coisa, não sendo nada, engana-se a si mesmo.',
        'Mas prove cada um a sua própria obra, e terá glória só em si mesmo, e não noutro.',
        'Porque cada qual levará a sua própria carga.',
        'E o que é instruído na palavra reparta de todos os seus bens com aquele que o instrui.',
        'Não erreis: Deus não se deixa escarnecer; porque tudo o que o homem semear, isso também ceifará.',
        'Porque o que semeia na sua carne, da carne ceifará a corrupção; mas o que semeia no Espírito, do Espírito ceifará a vida eterna.',
        'E não nos cansemos de fazer bem, porque a seu tempo ceifaremos, se não houvermos desfalecido.',
        'Então, enquanto temos tempo, façamos bem a todos, mas principalmente aos domésticos da fé.',
        'Vede com que grandes letras vos escrevi por minha mão.',
        'Todos os que querem mostrar boa aparência na carne, esses vos obrigam a circuncidar-vos, somente para não serem perseguidos por causa da cruz de Cristo.',
        'Porque nem ainda esses mesmos que se circuncidam guardam a lei, mas querem que vos circuncideis, para se gloriarem na vossa carne.',
        'Mas longe esteja de mim gloriar-me, a não ser na cruz de nosso Senhor Jesus Cristo, pela qual o mundo está crucificado para mim e eu para o mundo.',
        'Porque em Cristo Jesus nem a circuncisão, nem a incircuncisão tem virtude alguma, mas sim o ser uma nova criatura.',
        'E a todos quantos andarem conforme esta regra, paz e misericórdia sobre eles e sobre o Israel de Deus.',
        'Desde agora ninguém me inquiete; porque trago no meu corpo as marcas do Senhor Jesus.',
        'A graça de nosso Senhor Jesus Cristo seja, irmãos, com o vosso espírito! Amém.',
      ],
    ],
    livro: 'galatas',
  },
  {
    abbrev: 'ef',
    capitulos: [
      [
        'Paulo, apóstolo de Jesus Cristo, pela vontade de Deus, aos santos que estão em Éfeso, e fiéis em Cristo Jesus:',
        'A vós graça, e paz da parte de Deus nosso Pai e do Senhor Jesus Cristo!',
        'Bendito o Deus e Pai de nosso Senhor Jesus Cristo, o qual nos abençoou com todas as bênçãos espirituais nos lugares celestiais em Cristo;',
        'Como também nos elegeu nele antes da fundação do mundo, para que fôssemos santos e irrepreensíveis diante dele em amor;',
        'E nos predestinou para filhos de adoção por Jesus Cristo, para si mesmo, segundo o beneplácito de sua vontade,',
        'Para louvor da glória de sua graça, pela qual nos fez agradáveis a si no Amado,',
        'Em quem temos a redenção pelo seu sangue, a remissão das ofensas, segundo as riquezas da sua graça,',
        'Que ele fez abundar para conosco em toda a sabedoria e prudência;',
        'Descobrindo-nos o mistério da sua vontade, segundo o seu beneplácito, que propusera em si mesmo,',
        'De tornar a congregar em Cristo todas as coisas, na dispensação da plenitude dos tempos, tanto as que estão nos céus como as que estão na terra;',
        'Nele, digo, em quem também fomos feitos herança, havendo sido predestinados, conforme o propósito daquele que faz todas as coisas, segundo o conselho da sua vontade;',
        'Com o fim de sermos para louvor da sua glória, nós os que primeiro esperamos em Cristo;',
        'Em quem também vós estais, depois que ouvistes a palavra da verdade, o evangelho da vossa salvação; e, tendo nele também crido, fostes selados com o Espírito Santo da promessa;',
        'O qual é o penhor da nossa herança, para redenção da possessão adquirida, para louvor da sua glória.',
        'Por isso, ouvindo eu também a fé que entre vós há no Senhor Jesus, e o vosso amor para com todos os santos,',
        'Não cesso de dar graças a Deus por vós, lembrando-me de vós nas minhas orações:',
        'Para que o Deus de nosso Senhor Jesus Cristo, o Pai da glória, vos dê em seu conhecimento o espírito de sabedoria e de revelação;',
        'Tendo iluminados os olhos do vosso entendimento, para que saibais qual seja a esperança da sua vocação, e quais as riquezas da glória da sua herança nos santos;',
        'E qual a sobreexcelente grandeza do seu poder sobre nós, os que cremos, segundo a operação da força do seu poder,',
        'Que manifestou em Cristo, ressuscitando-o dentre os mortos, e pondo-o à sua direita nos céus,',
        'Acima de todo o principado, e poder, e potestade, e domínio, e de todo o nome que se nomeia, não só neste século, mas também no vindouro;',
        'E sujeitou todas as coisas a seus pés, e sobre todas as coisas o constituiu como cabeça da igreja,',
        'Que é o seu corpo, a plenitude daquele que cumpre tudo em todos.',
      ],
      [
        'E vos vivificou, estando vós mortos em ofensas e pecados,',
        'Em que noutro tempo andastes segundo o curso deste mundo, segundo o príncipe das potestades do ar, do espírito que agora opera nos filhos da desobediência;',
        'Entre os quais todos nós também antes andávamos nos desejos da nossa carne, fazendo a vontade da carne e dos pensamentos; e éramos por natureza filhos da ira, como os outros também.',
        'Mas Deus, que é riquíssimo em misericórdia, pelo seu muito amor com que nos amou,',
        'Estando nós ainda mortos em nossas ofensas, nos vivificou juntamente com Cristo (pela graça sois salvos),',
        'E nos ressuscitou juntamente com ele e nos fez assentar nos lugares celestiais, em Cristo Jesus;',
        'Para mostrar nos séculos vindouros as abundantes riquezas da sua graça pela sua benignidade para conosco em Cristo Jesus.',
        'Porque pela graça sois salvos, por meio da fé; e isto não vem de vós, é dom de Deus.',
        'Não vem das obras, para que ninguém se glorie;',
        'Porque somos feitura sua, criados em Cristo Jesus para as boas obras, as quais Deus preparou para que andássemos nelas.',
        'Portanto, lembrai-vos de que vós noutro tempo éreis gentios na carne, e chamados incircuncisão pelos que na carne se chamam circuncisão feita pela mão dos homens;',
        'Que naquele tempo estáveis sem Cristo, separados da comunidade de Israel, e estranhos às alianças da promessa, não tendo esperança, e sem Deus no mundo.',
        'Mas agora em Cristo Jesus, vós, que antes estáveis longe, já pelo sangue de Cristo chegastes perto.',
        'Porque ele é a nossa paz, o qual de ambos os povos fez um; e, derrubando a parede de separação que estava no meio,',
        'Na sua carne desfez a inimizade, isto é, a lei dos mandamentos, que consistia em ordenanças, para criar em si mesmo dos dois um novo homem, fazendo a paz,',
        'E pela cruz reconciliar ambos com Deus em um corpo, matando com ela as inimizades.',
        'E, vindo, ele evangelizou a paz, a vós que estáveis longe, e aos que estavam perto;',
        'Porque por ele ambos temos acesso ao Pai em um mesmo Espírito.',
        'Assim que já não sois estrangeiros, nem forasteiros, mas concidadãos dos santos, e da família de Deus;',
        'Edificados sobre o fundamento dos apóstolos e dos profetas, de que Jesus Cristo é a principal pedra da esquina;',
        'No qual todo o edifício, bem ajustado, cresce para templo santo no Senhor.',
        'No qual também vós juntamente sois edificados para morada de Deus em Espírito.',
      ],
      [
        'Por esta causa eu, Paulo, sou o prisioneiro de Jesus Cristo por vós, os gentios;',
        'Se é que tendes ouvido a dispensação da graça de Deus, que para convosco me foi dada;',
        'Como me foi este mistério manifestado pela revelação, como antes um pouco vos escrevi;',
        'Por isso, quando ledes, podeis perceber a minha compreensão do mistério de Cristo,',
        'O qual noutros séculos não foi manifestado aos filhos dos homens, como agora tem sido revelado pelo Espírito aos seus santos apóstolos e profetas;',
        'A saber, que os gentios são co-herdeiros, e de um mesmo corpo, e participantes da promessa em Cristo pelo evangelho;',
        'Do qual fui feito ministro, pelo dom da graça de Deus, que me foi dado segundo a operação do seu poder.',
        'A mim, o mínimo de todos os santos, me foi dada esta graça de anunciar entre os gentios, por meio do evangelho, as riquezas incompreensíveis de Cristo,',
        'E demonstrar a todos qual seja a comunhão do mistério, que desde os séculos esteve oculto em Deus, que tudo criou por meio de Jesus Cristo;',
        'Para que agora, pela igreja, a multiforme sabedoria de Deus seja conhecida dos principados e potestades nos céus,',
        'Segundo o eterno propósito que fez em Cristo Jesus nosso Senhor,',
        'No qual temos ousadia e acesso com confiança, pela nossa fé nele.',
        'Portanto, vos peço que não desfaleçais nas minhas tribulações por vós, que são a vossa glória.',
        'Por causa disto me ponho de joelhos perante o Pai de nosso Senhor Jesus Cristo,',
        'Do qual toda a família nos céus e na terra toma o nome,',
        'Para que, segundo as riquezas da sua glória, vos conceda que sejais corroborados com poder pelo seu Espírito no homem interior;',
        'Para que Cristo habite pela fé nos vossos corações; a fim de, estando arraigados e fundados em amor,',
        'Poderdes perfeitamente compreender, com todos os santos, qual seja a largura, e o comprimento, e a altura, e a profundidade,',
        'E conhecer o amor de Cristo, que excede todo o entendimento, para que sejais cheios de toda a plenitude de Deus.',
        'Ora, àquele que é poderoso para fazer tudo muito mais abundantemente além daquilo que pedimos ou pensamos, segundo o poder que em nós opera,',
        'A esse glória na igreja, por Jesus Cristo, em todas as gerações, para todo o sempre. Amém.',
      ],
      [
        'Rogo-vos, pois, eu, o preso do Senhor, que andeis como é digno da vocação com que fostes chamados,',
        'Com toda a humildade e mansidão, com longanimidade, suportando-vos uns aos outros em amor,',
        'Procurando guardar a unidade do Espírito pelo vínculo da paz.',
        'Há um só corpo e um só Espírito, como também fostes chamados em uma só esperança da vossa vocação;',
        'Um só Senhor, uma só fé, um só batismo;',
        'Um só Deus e Pai de todos, o qual é sobre todos, e por todos e em todos vós.',
        'Mas a graça foi dada a cada um de nós segundo a medida do dom de Cristo.',
        'Por isso diz:Subindo ao alto, levou cativo o cativeiro,e deu dons aos homens.',
        'Ora, istoele subiuque é, senão que também antes tinha descido às partes mais baixas da terra?',
        'Aquele que desceu é também o mesmo que subiu acima de todos os céus, para cumprir todas as coisas.',
        'E ele mesmo deu uns para apóstolos, e outros para profetas, e outros para evangelistas, e outros para pastores e doutores,',
        'Querendo o aperfeiçoamento dos santos, para a obra do ministério, para edificação do corpo de Cristo;',
        'Até que todos cheguemos à unidade da fé, e ao conhecimento do Filho de Deus, a homem perfeito, à medida da estatura completa de Cristo,',
        'Para que não sejamos mais meninos inconstantes, levados em roda por todo o vento de doutrina, pelo engano dos homens que com astúcia enganam fraudulosamente.',
        'Antes, seguindo a verdade em amor, cresçamos em tudo naquele que é a cabeça, Cristo,',
        'Do qual todo o corpo, bem ajustado, e ligado pelo auxílio de todas as juntas, segundo a justa operação de cada parte, faz o aumento do corpo, para sua edificação em amor.',
        'E digo isto, e testifico no Senhor, para que não andeis mais como andam também os outros gentios, na vaidade da sua mente.',
        'Entenebrecidos no entendimento, separados da vida de Deus pela ignorância que há neles, pela dureza do seu coração;',
        'Os quais, havendo perdido todo o sentimento, se entregaram à dissolução, para com avidez cometerem toda a impureza.',
        'Mas vós não aprendestes assim a Cristo,',
        'Se é que o tendes ouvido, e nele fostes ensinados, como está a verdade em Jesus;',
        'Que, quanto ao trato passado, vos despojeis do velho homem, que se corrompe pelas concupiscências do engano;',
        'E vos renoveis no espírito da vossa mente;',
        'E vos revistais do novo homem, que segundo Deus é criado em verdadeira justiça e santidade.',
        'Por isso deixai a mentira, e falai a verdade cada um com o seu próximo; porque somos membros uns dos outros.',
        'Irai-vos, e não pequeis; não se ponha o sol sobre a vossa ira.',
        'Não deis lugar ao diabo.',
        'Aquele que furtava, não furte mais; antes trabalhe, fazendo com as mãos o que é bom, para que tenha o que repartir com o que tiver necessidade.',
        'Não saia da vossa boca nenhuma palavra torpe, mas só a que for boa para promover a edificação, para que dê graça aos que a ouvem.',
        'E não entristeçais o Espírito Santo de Deus, no qual estais selados para o dia da redenção.',
        'Toda a amargura, e ira, e cólera, e gritaria, e blasfêmia e toda a malícia sejam tiradas dentre vós,',
        'Antes sede uns para com os outros benignos, misericordiosos, perdoando-vos uns aos outros, como também Deus vos perdoou em Cristo.',
      ],
      [
        'Sede, pois, imitadores de Deus, como filhos amados;',
        'E andai em amor, como também Cristo vos amou, e se entregou a si mesmo por nós, em oferta e sacrifício a Deus, em cheiro suave.',
        'Mas a fornicação, e toda a impureza ou avareza, nem ainda se nomeie entre vós, como convém a santos;',
        'Nem torpezas, nem parvoíces, nem chocarrices, que não convêm; mas antes, ações de graças.',
        'Porque bem sabeis isto: que nenhum devasso, ou impuro, ou avarento, o qual é idólatra, tem herança no reino de Cristo e de Deus.',
        'Ninguém vos engane com palavras vãs; porque por estas coisas vem a ira de Deus sobre os filhos da desobediência.',
        'Portanto, não sejais seus companheiros.',
        'Porque noutro tempo éreis trevas, mas agora sois luz no Senhor; andai como filhos da luz',
        '(Porque o fruto do Espírito está em toda a bondade, e justiça e verdade);',
        'Aprovando o que é agradável ao Senhor.',
        'E não comuniqueis com as obras infrutuosas das trevas, mas antes condenai-as.',
        'Porque o que eles fazem em oculto até dizê-lo é torpe.',
        'Mas todas estas coisas se manifestam, sendo condenadas pela luz, porque a luz tudo manifesta.',
        'Por isso diz: Desperta, tu que dormes, e levanta-te dentre os mortos, e Cristo te esclarecerá.',
        'Portanto, vede prudentemente como andais, não como néscios, mas como sábios,',
        'Remindo o tempo; porquanto os dias são maus.',
        'Por isso não sejais insensatos, mas entendei qual seja a vontade do Senhor.',
        'E não vos embriagueis com vinho, em que há contenda, mas enchei-vos do Espírito;',
        'Falando entre vós em salmos, e hinos, e cânticos espirituais; cantando e salmodiando ao Senhor no vosso coração;',
        'Dando sempre graças por tudo a nosso Deus e Pai, em nome de nosso Senhor Jesus Cristo;',
        'Sujeitando-vos uns aos outros no temor de Deus.',
        'Vós, mulheres, sujeitai-vos a vossos maridos, como ao Senhor;',
        'Porque o marido é a cabeça da mulher, como também Cristo é a cabeça da igreja, sendo ele próprio o salvador do corpo.',
        'De sorte que, assim como a igreja está sujeita a Cristo, assim também as mulheres sejam em tudo sujeitas a seus maridos.',
        'Vós, maridos, amai vossas mulheres, como também Cristo amou a igreja, e a si mesmo se entregou por ela,',
        'Para a santificar, purificando-a com a lavagem da água, pela palavra,',
        'Para a apresentar a si mesmo igreja gloriosa, sem mácula, nem ruga, nem coisa semelhante, mas santa e irrepreensível.',
        'Assim devem os maridos amar as suas próprias mulheres, como a seus próprios corpos. Quem ama a sua mulher, ama-se a si mesmo.',
        'Porque nunca ninguém odiou a sua própria carne; antes a alimenta e sustenta, como também o Senhor à igreja;',
        'Porque somos membros do seu corpo, da sua carne, e dos seus ossos.',
        'Por isso deixará o homem seu pai e sua mãe, e se unirá a sua mulher; e serão dois numa carne.',
        'Grande é este mistério; digo-o, porém, a respeito de Cristo e da igreja.',
        'Assim também vós, cada um em particular, ame a sua própria mulher como a si mesmo, e a mulher reverencie o marido.',
      ],
      [
        'Vós, filhos, sede obedientes a vossos pais no Senhor, porque isto é justo.',
        'Honra a teu pai e a tua mãe, que é o primeiro mandamento com promessa;',
        'Para que te vá bem, e vivas muito tempo sobre a terra.',
        'E vós, pais, não provoqueis à ira a vossos filhos, mas criai-os na doutrina e admoestação do Senhor.',
        'Vós, servos, obedecei a vossos senhores segundo a carne, com temor e tremor, na sinceridade de vosso coração, como a Cristo;',
        'Não servindo à vista, como para agradar aos homens, mas como servos de Cristo, fazendo de coração a vontade de Deus;',
        'Servindo de boa vontade como ao Senhor, e não como aos homens.',
        'Sabendo que cada um receberá do Senhor todo o bem que fizer, seja servo, seja livre.',
        'E vós, senhores, fazei o mesmo para com eles, deixando as ameaças, sabendo também que o Senhor deles e vosso está no céu, e que para com ele não há acepção de pessoas.',
        'No demais, irmãos meus, fortalecei-vos no Senhor e na força do seu poder.',
        'Revesti-vos de toda a armadura de Deus, para que possais estar firmes contra as astutas ciladas do diabo.',
        'Porque não temos que lutar contra a carne e o sangue, mas, sim, contra os principados, contra as potestades, contra os príncipes das trevas deste século, contra as hostes espirituais da maldade, nos lugares celestiais.',
        'Portanto, tomai toda a armadura de Deus, para que possais resistir no dia mau e, havendo feito tudo, ficar firmes.',
        'Estai, pois, firmes, tendo cingidos os vossos lombos com a verdade, e vestida a couraça da justiça;',
        'E calçados os pés na preparação do evangelho da paz;',
        'Tomando sobretudo o escudo da fé, com o qual podereis apagar todos os dardos inflamados do maligno.',
        'Tomai também o capacete da salvação, e a espada do Espírito, que é a palavra de Deus;',
        'Orando em todo o tempo com toda a oração e súplica no Espírito, e vigiando nisto com toda a perseverança e súplica por todos os santos,',
        'E por mim; para que me seja dada, no abrir da minha boca, a palavra com confiança, para fazer notório o mistério do evangelho,',
        'Pelo qual sou embaixador em cadeias; para que possa falar dele livremente, como me convém falar.',
        'Ora, para que vós também possais saber dos meus negócios, e o que eu faço, Tíquico, irmão amado, e fiel ministro do Senhor, vos informará de tudo.',
        'O qual vos enviei para o mesmo fim, para que saibais do nosso estado, e ele console os vossos corações.',
        'Paz seja com os irmãos, e amor com fé da parte de Deus Pai e da do Senhor Jesus Cristo.',
        'A graça seja com todos os que amam a nosso Senhor Jesus Cristo em sinceridade. Amém.',
      ],
    ],
    livro: 'efesios',
  },
  {
    abbrev: 'fp',
    capitulos: [
      [
        'Paulo e Timóteo, servos de Jesus Cristo, a todos os santos em Cristo Jesus, que estão em Filipos, com os bispos e diáconos:',
        'Graça a vós, e paz da parte de Deus nosso Pai e da do Senhor Jesus Cristo.',
        'Dou graças ao meu Deus todas as vezes que me lembro de vós,',
        'Fazendo sempre com alegria oração por vós em todas as minhas súplicas,',
        'Pela vossa cooperação no evangelho desde o primeiro dia até agora.',
        'Tendo por certo isto mesmo, que aquele que em vós começou a boa obra a aperfeiçoará até ao dia de Jesus Cristo;',
        'Como tenho por justo sentir isto de vós todos, porque vos retenho em meu coração, pois todos vós fostes participantes da minha graça, tanto nas minhas prisões como na minha defesa e confirmação do evangelho.',
        'Porque Deus me é testemunha das saudades que de todos vós tenho, em entranhável afeição de Jesus Cristo.',
        'E peço isto: que o vosso amor cresça mais e mais em ciência e em todo o conhecimento,',
        'Para que aproveis as coisas excelentes, para que sejais sinceros, e sem escândalo algum até ao dia de Cristo;',
        'Cheios dos frutos de justiça, que são por Jesus Cristo, para glória e louvor de Deus.',
        'E quero, irmãos, que saibais que as coisas que me aconteceram contribuíram para maior proveito do evangelho;',
        'De maneira que as minhas prisões em Cristo foram manifestas por toda a guarda pretoriana, e por todos os demais lugares;',
        'E muitos dos irmãos no Senhor, tomando ânimo com as minhas prisões, ousam falar a palavra mais confiadamente, sem temor.',
        'Verdade é que também alguns pregam a Cristo por inveja e porfia, mas outros de boa vontade;',
        'Uns, na verdade, anunciam a Cristo por contenção, não puramente, julgando acrescentar aflição às minhas prisões.',
        'Mas outros, por amor, sabendo que fui posto para defesa do evangelho.',
        'Mas que importa? Contanto que Cristo seja anunciado de toda a maneira, ou com fingimento ou em verdade, nisto me regozijo, e me regozijarei ainda.',
        'Porque sei que disto me resultará salvação, pela vossa oração e pelo socorro do Espírito de Jesus Cristo,',
        'Segundo a minha intensa expectação e esperança, de que em nada serei confundido; antes, com toda a confiança, Cristo será, tanto agora como sempre, engrandecido no meu corpo, seja pela vida, seja pela morte.',
        'Porque para mim o viver é Cristo, e o morrer é ganho.',
        'Mas, se o viver na carne me der fruto da minha obra, não sei então o que deva escolher.',
        'Mas de ambos os lados estou em aperto, tendo desejo de partir, e estar com Cristo, porque isto é ainda muito melhor.',
        'Mas julgo mais necessário, por amor de vós, ficar na carne.',
        'E, tendo esta confiança, sei que ficarei, e permanecerei com todos vós para proveito vosso e gozo da fé,',
        'Para que a vossa glória cresça por mim em Cristo Jesus, pela minha nova ida a vós.',
        'Somente deveis portar-vos dignamente conforme o evangelho de Cristo, para que, quer vá e vos veja, quer esteja ausente, ouça acerca de vós que estais num mesmo espírito, combatendo juntamente com o mesmo ânimo pela fé do evangelho.',
        'E em nada vos espanteis dos que resistem, o que para eles, na verdade, é indício de perdição, mas para vós de salvação, e isto de Deus.',
        'Porque a vós vos foi concedido, em relação a Cristo, não somente crer nele, como também padecer por ele,',
        'Tendo o mesmo combate que já em mim tendes visto e agora ouvis estar em mim.',
      ],
      [
        'Portanto, se há algum conforto em Cristo, se alguma consolação de amor, se alguma comunhão no Espírito, se alguns entranháveis afetos e compaixões,',
        'Completai o meu gozo, para que sintais o mesmo, tendo o mesmo amor, o mesmo ânimo, sentindo uma mesma coisa.',
        'Nada façais por contenda ou por vanglória, mas por humildade; cada um considere os outros superiores a si mesmo.',
        'Não atente cada um para o que é propriamente seu, mas cada qual também para o que é dos outros.',
        'De sorte que haja em vós o mesmo sentimento que houve também em Cristo Jesus,',
        'Que, sendo em forma de Deus, não teve por usurpação ser igual a Deus,',
        'Mas esvaziou-se a si mesmo, tomando a forma de servo, fazendo-se semelhante aos homens;',
        'E, achado na forma de homem, humilhou-se a si mesmo, sendo obediente até à morte, e morte de cruz.',
        'Por isso, também Deus o exaltou soberanamente, e lhe deu um nome que é sobre todo o nome;',
        'Para que ao nome de Jesus se dobre todo o joelho dos que estão nos céus, e na terra, e debaixo da terra,',
        'E toda a língua confesse que Jesus Cristo é o Senhor, para glória de Deus Pai.',
        'De sorte que, meus amados, assim como sempre obedecestes, não só na minha presença, mas muito mais agora na minha ausência, assim também operai a vossa salvação com temor e tremor;',
        'Porque Deus é o que opera em vós tanto o querer como o efetuar, segundo a sua boa vontade.',
        'Fazei todas as coisas sem murmurações nem contendas;',
        'Para que sejais irrepreensíveis e sinceros, filhos de Deus inculpáveis, no meio de uma geração corrompida e perversa, entre a qual resplandeceis como astros no mundo;',
        'Retendo a palavra da vida, para que no dia de Cristo possa gloriar-me de não ter corrido nem trabalhado em vão.',
        'E, ainda que seja oferecido por libação sobre o sacrifício e serviço da vossa fé, folgo e me regozijo com todos vós.',
        'E vós também regozijai-vos e alegrai-vos comigo por isto mesmo.',
        'E espero no Senhor Jesus que em breve vos mandarei Timóteo, para que também eu esteja de bom ânimo, sabendo dos vossos negócios.',
        'Porque a ninguém tenho de igual sentimento, que sinceramente cuide do vosso estado;',
        'Porque todos buscam o que é seu, e não o que é de Cristo Jesus.',
        'Mas bem sabeis qual a sua experiência, e que serviu comigo no evangelho, como filho ao pai.',
        'De sorte que espero vo-lo enviar logo que tenha provido a meus negócios.',
        'Mas confio no Senhor, que também eu mesmo em breve irei ter convosco.',
        'Julguei, contudo, necessário mandar-vos Epafrodito, meu irmão e cooperador, e companheiro nos combates, e vosso enviado para prover às minhas necessidades.',
        'Porquanto tinha muitas saudades de vós todos, e estava muito angustiado de que tivésseis ouvido que ele estivera doente.',
        'E de fato esteve doente, e quase à morte; mas Deus se apiedou dele, e não somente dele, mas também de mim, para que eu não tivesse tristeza sobre tristeza.',
        'Por isso vo-lo enviei mais depressa, para que, vendo-o outra vez, vos regozijeis, e eu tenha menos tristeza.',
        'Recebei-o, pois, no Senhor com todo o gozo, e tende-o em honra;',
        'Porque pela obra de Cristo chegou até bem próximo da morte, não fazendo caso da vida para suprir para comigo a falta do vosso serviço.',
      ],
      [
        'Resta, irmãos meus, que vos regozijeis no Senhor. Não me aborreço de escrever-vos as mesmas coisas, e é segurança para vós.',
        'Guardai-vos dos cães, guardai-vos dos maus obreiros, guardai-vos da circuncisão;',
        'Porque a circuncisão somos nós, que servimos a Deus em espírito, e nos gloriamos em Jesus Cristo, e não confiamos na carne.',
        'Ainda que também podia confiar na carne; se algum outro cuida que pode confiar na carne, ainda mais eu:',
        'Circuncidado ao oitavo dia, da linhagem de Israel, da tribo de Benjamim, hebreu de hebreus; segundo a lei, fui fariseu;',
        'Segundo o zelo, perseguidor da igreja, segundo a justiça que há na lei, irrepreensível.',
        'Mas o que para mim era ganho reputei-o perda por Cristo.',
        'E, na verdade, tenho também por perda todas as coisas, pela excelência do conhecimento de Cristo Jesus, meu Senhor; pelo qual sofri a perda de todas estas coisas, e as considero como escória, para que possa ganhar a Cristo,',
        'E seja achado nele, não tendo a minha justiça que vem da lei, mas a que vem pela fé em Cristo, a saber, a justiça que vem de Deus pela fé;',
        'Para conhecê-lo, e à virtude da sua ressurreição, e à comunicação de suas aflições, sendo feito conforme à sua morte;',
        'Para ver se de alguma maneira posso chegar à ressurreição dentre os mortos.',
        'Não que já a tenha alcançado, ou que seja perfeito; mas prossigo para alcançar aquilo para o que fui também preso por Cristo Jesus.',
        'Irmãos, quanto a mim, não julgo que o haja alcançado; mas uma coisa faço, e é que, esquecendo-me das coisas que atrás ficam, e avançando para as que estão diante de mim,',
        'Prossigo para o alvo, pelo prêmio da soberana vocação de Deus em Cristo Jesus.',
        'Por isso todos quantos já somos perfeitos, sintamos isto mesmo; e, se sentis alguma coisa de outra maneira, também Deus vo-lo revelará.',
        'Mas, naquilo a que já chegamos, andemos segundo a mesma regra, e sintamos o mesmo.',
        'Sede também meus imitadores, irmãos, e tende cuidado, segundo o exemplo que tendes em nós, pelos que assim andam.',
        'Porque muitos há, dos quais muitas vezes vos disse, e agora também digo, chorando, que são inimigos da cruz de Cristo,',
        'Cujo fim é a perdição; cujo Deus é o ventre, e cuja glória é para confusão deles, que só pensam nas coisas terrenas.',
        'Mas a nossa cidade está nos céus, de onde também esperamos o Salvador, o Senhor Jesus Cristo,',
        'Que transformará o nosso corpo abatido, para ser conforme o seu corpo glorioso, segundo o seu eficaz poder de sujeitar também a si todas as coisas.',
      ],
      [
        'Portanto, meus amados e mui queridos irmãos, minha alegria e coroa, estai assim firmes no Senhor, amados.',
        'Rogo a Evódia, e rogo a Síntique, que sintam o mesmo no Senhor.',
        'E peço-te também a ti, meu verdadeiro companheiro, que ajudes essas mulheres que trabalharam comigo no evangelho, e com Clemente, e com os meus outros cooperadores, cujos nomes estão no livro da vida.',
        'Regozijai-vos sempre no Senhor; outra vez digo, regozijai-vos.',
        'Seja a vossa eqüidade notória a todos os homens. Perto está o Senhor.',
        'Não estejais inquietos por coisa alguma; antes as vossas petições sejam em tudo conhecidas diante de Deus pela oração e súplica, com ação de graças.',
        'E a paz de Deus, que excede todo o entendimento, guardará os vossos corações e os vossos pensamentos em Cristo Jesus.',
        'Quanto ao mais, irmãos, tudo o que é verdadeiro, tudo o que é honesto, tudo o que é justo, tudo o que é puro, tudo o que é amável, tudo o que é de boa fama, se há alguma virtude, e se há algum louvor, nisso pensai.',
        'O que também aprendestes, e recebestes, e ouvistes, e vistes em mim, isso fazei; e o Deus de paz será convosco.',
        'Ora, muito me regozijei no Senhor por finalmente reviver a vossa lembrança de mim; pois já vos tínheis lembrado, mas não tínheis tido oportunidade.',
        'Não digo isto como por necessidade, porque já aprendi a contentar-me com o que tenho.',
        'Sei estar abatido, e sei também ter abundância; em toda a maneira, e em todas as coisas estou instruído, tanto a ter fartura, como a ter fome; tanto a ter abundância, como a padecer necessidade.',
        'Posso todas as coisas em Cristo que me fortalece.',
        'Todavia fizestes bem em tomar parte na minha aflição.',
        'E bem sabeis também, ó filipenses, que, no princípio do evangelho, quando parti da macedônia, nenhuma igreja comunicou comigo com respeito a dar e a receber, senão vós somente;',
        'Porque também uma e outra vez me mandastes o necessário a tessalônica.',
        'Não que procure dádivas, mas procuro o fruto que cresça para a vossa conta.',
        'Mas bastante tenho recebido, e tenho abundância. Cheio estou, depois que recebi de Epafrodito o que da vossa parte me foi enviado, como cheiro de suavidade e sacrifício agradável e aprazível a Deus.',
        'O meu Deus, segundo as suas riquezas, suprirá todas as vossas necessidades em glória, por Cristo Jesus.',
        'Ora, a nosso Deus e Pai seja dada glória para todo o sempre. Amém.',
        'Saudai a todos os santos em Cristo Jesus. Os irmãos que estão comigo vos saúdam.',
        'Todos os santos vos saúdam, mas principalmente os que são da casa de César.',
        'A graça de nosso Senhor Jesus Cristo seja com vós todos. Amém.',
      ],
    ],
    livro: 'filipenses',
  },
  {
    abbrev: 'cl',
    capitulos: [
      [
        'Paulo, apóstolo de Jesus Cristo, pela vontade de Deus, e o irmão Timóteo,',
        'Aos santos e irmãos fiéis em Cristo, que estão em Colossos: Graça a vós, e paz da parte de Deus nosso Pai e do Senhor Jesus Cristo.',
        'Graças damos a Deus, Pai de nosso Senhor Jesus Cristo, orando sempre por vós,',
        'Porquanto ouvimos da vossa fé em Cristo Jesus, e do amor que tendes para com todos os santos;',
        'Por causa da esperança que vos está reservada nos céus, da qual já antes ouvistes pela palavra da verdade do evangelho,',
        'Que já chegou a vós, como também está em todo o mundo; e já vai frutificando, como também entre vós, desde o dia em que ouvistes e conhecestes a graça de Deus em verdade;',
        'Como aprendestes de Epafras, nosso amado conservo, que para vós é um fiel ministro de Cristo,',
        'O qual nos declarou também o vosso amor no Espírito.',
        'Por esta razão, nós também, desde o dia em que o ouvimos, não cessamos de orar por vós, e de pedir que sejais cheios do conhecimento da sua vontade, em toda a sabedoria e inteligência espiritual;',
        'Para que possais andar dignamente diante do Senhor, agradando-lhe em tudo, frutificando em toda a boa obra, e crescendo no conhecimento de Deus;',
        'Corroborados em toda a fortaleza, segundo a força da sua glória, em toda a paciência, e longanimidade com gozo;',
        'Dando graças ao Pai que nos fez idôneos para participar da herança dos santos na luz;',
        'O qual nos tirou da potestade das trevas, e nos transportou para o reino do Filho do seu amor;',
        'Em quem temos a redenção pelo seu sangue, a saber, a remissão dos pecados;',
        'O qual é imagem do Deus invisível, o primogênito de toda a criação;',
        'Porque nele foram criadas todas as coisas que há nos céus e na terra, visíveis e invisíveis, sejam tronos, sejam dominações, sejam principados, sejam potestades. Tudo foi criado por ele e para ele.',
        'E ele é antes de todas as coisas, e todas as coisas subsistem por ele.',
        'E ele é a cabeça do corpo, da igreja; é o princípio e o primogênito dentre os mortos, para que em tudo tenha a preeminência.',
        'Porque foi do agrado do Pai que toda a plenitude nele habitasse,',
        'E que, havendo por ele feito a paz pelo sangue da sua cruz, por meio dele reconciliasse consigo mesmo todas as coisas, tanto as que estão na terra, como as que estão nos céus.',
        'A vós também, que noutro tempo éreis estranhos, e inimigos no entendimento pelas vossas obras más, agora contudo vos reconciliou',
        'No corpo da sua carne, pela morte, para perante ele vos apresentar santos, e irrepreensíveis, e inculpáveis,',
        'Se, na verdade, permanecerdes fundados e firmes na fé, e não vos moverdes da esperança do evangelho que tendes ouvido, o qual foi pregado a toda criatura que há debaixo do céu, e do qual eu, Paulo, estou feito ministro.',
        'Regozijo-me agora no que padeço por vós, e na minha carne cumpro o resto das aflições de Cristo, pelo seu corpo, que é a igreja;',
        'Da qual eu estou feito ministro segundo a dispensação de Deus, que me foi concedida para convosco, para cumprir a palavra de Deus;',
        'O mistério que esteve oculto desde todos os séculos, e em todas as gerações, e que agora foi manifesto aos seus santos;',
        'Aos quais Deus quis fazer conhecer quais são as riquezas da glória deste mistério entre os gentios, que é Cristo em vós, esperança da glória;',
        'A quem anunciamos, admoestando a todo o homem, e ensinando a todo o homem em toda a sabedoria; para que apresentemos todo o homem perfeito em Jesus Cristo;',
        'E para isto também trabalho, combatendo segundo a sua eficácia, que opera em mim poderosamente.',
      ],
      [
        'Porque quero que saibais quão grande combate tenho por vós, e pelos que estão em Laodicéia, e por quantos não viram o meu rosto em carne;',
        'Para que os seus corações sejam consolados, e estejam unidos em amor, e enriquecidos da plenitude da inteligência, para conhecimento do mistério de Deus e Pai, e de Cristo,',
        'Em quem estão escondidos todos os tesouros da sabedoria e da ciência.',
        'E digo isto, para que ninguém vos engane com palavras persuasivas.',
        'Porque, ainda que esteja ausente quanto ao corpo, contudo, em espírito estou convosco, regozijando-me e vendo a vossa ordem e a firmeza da vossa fé em Cristo.',
        'Como, pois, recebestes o Senhor Jesus Cristo, assim também andai nele,',
        'Arraigados e sobreedificados nele, e confirmados na fé, assim como fostes ensinados, nela abundando em ação de graças.',
        'Tende cuidado, para que ninguém vos faça presa sua, por meio de filosofias e vãs sutilezas, segundo a tradição dos homens, segundo os rudimentos do mundo, e não segundo Cristo;',
        'Porque nele habita corporalmente toda a plenitude da divindade;',
        'E estais perfeitos nele, que é a cabeça de todo o principado e potestade;',
        'No qual também estais circuncidados com a circuncisão não feita por mão no despojo do corpo dos pecados da carne, pela circuncisão de Cristo;',
        'Sepultados com ele no batismo, nele também ressuscitastes pela fé no poder de Deus, que o ressuscitou dentre os mortos.',
        'E, quando vós estáveis mortos nos pecados, e na incircuncisão da vossa carne, vos vivificou juntamente com ele, perdoando-vos todas as ofensas,',
        'Havendo riscado a cédula que era contra nós nas suas ordenanças, a qual de alguma maneira nos era contrária, e a tirou do meio de nós, cravando-a na cruz.',
        'E, despojando os principados e potestades, os expôs publicamente e deles triunfou em si mesmo.',
        'Portanto, ninguém vos julgue pelo comer, ou pelo beber, ou por causa dos dias de festa, ou da lua nova, ou dos sábados,',
        'Que são sombras das coisas futuras, mas o corpo é de Cristo.',
        'Ninguém vos domine a seu bel-prazer com pretexto de humildade e culto dos anjos, envolvendo-se em coisas que não viu; estando debalde inchado na sua carnal compreensão,',
        'E não ligado à cabeça, da qual todo o corpo, provido e organizado pelas juntas e ligaduras, vai crescendo em aumento de Deus.',
        'Se, pois, estais mortos com Cristo quanto aos rudimentos do mundo, por que vos carregam ainda de ordenanças, como se vivêsseis no mundo, tais como:',
        'Não toques, não proves, não manuseies?',
        'As quais coisas todas perecem pelo uso, segundo os preceitos e doutrinas dos homens;',
        'As quais têm, na verdade, alguma aparência de sabedoria, em devoção voluntária, humildade, e em disciplina do corpo, mas não são de valor algum senão para a satisfação da carne.',
      ],
      [
        'Portanto, se já ressuscitastes com Cristo, buscai as coisas que são de cima, onde Cristo está assentado à destra de Deus.',
        'Pensai nas coisas que são de cima, e não nas que são da terra;',
        'Porque já estais mortos, e a vossa vida está escondida com Cristo em Deus.',
        'Quando Cristo, que é a nossa vida, se manifestar, então também vós vos manifestareis com ele em glória.',
        'Mortificai, pois, os vossos membros, que estão sobre a terra: a fornicação, a impureza, o afeição desordenada, a vil concupiscência, e a avareza, que é idolatria;',
        'Pelas quais coisas vem a ira de Deus sobre os filhos da desobediência;',
        'Nas quais, também, em outro tempo andastes, quando vivíeis nelas.',
        'Mas agora, despojai-vos também de tudo: da ira, da cólera, da malícia, da maledicência, das palavras torpes da vossa boca.',
        'Não mintais uns aos outros, pois que já vos despistes do velho homem com os seus feitos,',
        'E vos vestistes do novo, que se renova para o conhecimento, segundo a imagem daquele que o criou;',
        'Onde não há grego, nem judeu, circuncisão, nem incircuncisão, bárbaro, cita, servo ou livre; mas Cristo é tudo, e em todos.',
        'Revesti-vos, pois, como eleitos de Deus, santos e amados, de entranhas de misericórdia, de benignidade, humildade, mansidão, longanimidade;',
        'Suportando-vos uns aos outros, e perdoando-vos uns aos outros, se alguém tiver queixa contra outro; assim como Cristo vos perdoou, assim fazei vós também.',
        'E, sobre tudo isto, revesti-vos de amor, que é o vínculo da perfeição.',
        'E a paz de Deus, para a qual também fostes chamados em um corpo, domine em vossos corações; e sede agradecidos.',
        'A palavra de Cristo habite em vós abundantemente, em toda a sabedoria, ensinando-vos e admoestando-vos uns aos outros, com salmos, hinos e cânticos espirituais, cantando ao Senhor com graça em vosso coração.',
        'E, quanto fizerdes por palavras ou por obras, fazei tudo em nome do Senhor Jesus, dando por ele graças a Deus Pai.',
        'Vós, mulheres, estai sujeitas a vossos próprios maridos, como convém no Senhor.',
        'Vós, maridos, amai a vossas mulheres, e não vos irriteis contra elas.',
        'Vós, filhos, obedecei em tudo a vossos pais, porque isto é agradável ao Senhor.',
        'Vós, pais, não irriteis a vossos filhos, para que não percam o ânimo.',
        'Vós, servos, obedecei em tudo a vossos senhores segundo a carne, não servindo só na aparência, como para agradar aos homens, mas em simplicidade de coração, temendo a Deus.',
        'E tudo quanto fizerdes, fazei-o de todo o coração, como ao Senhor, e não aos homens,',
        'Sabendo que recebereis do Senhor o galardão da herança, porque a Cristo, o Senhor, servis.',
        'Mas quem fizer agravo receberá o agravo que fizer; pois não há acepção de pessoas.',
      ],
      [
        'Vós, senhores, fazei o que for de justiça e eqüidade a vossos servos, sabendo que também tendes um Senhor nos céus.',
        'Perseverai em oração, velando nela com ação de graças;',
        'Orando também juntamente por nós, para que Deus nos abra a porta da palavra, a fim de falarmos do mistério de Cristo, pelo qual estou também preso;',
        'Para que o manifeste, como me convém falar.',
        'Andai com sabedoria para com os que estão de fora, remindo o tempo.',
        'A vossa palavra seja sempre agradável, temperada com sal, para que saibais como vos convém responder a cada um.',
        'Tíquico, irmão amado e fiel ministro, e conservo no Senhor, vos fará saber o meu estado;',
        'O qual vos enviei para o mesmo fim, para que saiba do vosso estado e console os vossos corações;',
        'Juntamente com Onésimo, amado e fiel irmão, que é dos vossos; eles vos farão saber tudo o que por aqui se passa.',
        'Aristarco, que está preso comigo, vos saúda, e Marcos, o sobrinho de Barnabé, acerca do qual já recebestes mandamentos; se ele for ter convosco, recebei-o;',
        'E Jesus, chamado Justo; os quais são da circuncisão; são estes unicamente os meus cooperadores no reino de Deus; e para mim têm sido consolação.',
        'Saúda-vos Epafras, que é dos vossos, servo de Cristo, combatendo sempre por vós em orações, para que vos conserveis firmes, perfeitos e consumados em toda a vontade de Deus.',
        'Pois eu lhe dou testemunho de que tem grande zelo por vós, e pelos que estão em Laodicéia, e pelos que estão em Hierápolis.',
        'Saúda-vos Lucas, o médico amado, e Demas.',
        'Saudai aos irmãos que estão em Laodicéia e a Ninfa e à igreja que está em sua casa.',
        'E, quando esta epístola tiver sido lida entre vós, fazei que também o seja na igreja dos laodicenses, e a que veio de Laodicéia lede-a vós também.',
        'E dizei a Arquipo: Atenta para o ministério que recebeste no Senhor, para que o cumpras.',
        'Saudação de minha mão, de Paulo. Lembrai-vos das minhas prisões. A graça seja convosco. Amém.',
      ],
    ],
    livro: 'colossenses',
  },
  {
    abbrev: '1ts',
    capitulos: [
      [
        'Paulo, e Silvano, e Timóteo, à igreja dos tessalonicenses em Deus, o Pai, e no Senhor Jesus Cristo: Graça e paz tenhais de Deus nosso Pai e do Senhor Jesus Cristo.',
        'Sempre damos graças a Deus por vós todos, fazendo menção de vós em nossas orações,',
        'Lembrando-nos sem cessar da obra da vossa fé, do trabalho do amor, e da paciência da esperança em nosso Senhor Jesus Cristo, diante de nosso Deus e Pai,',
        'Sabendo, amados irmãos, que a vossa eleição é de Deus;',
        'Porque o nosso evangelho não foi a vós somente em palavras, mas também em poder, e no Espírito Santo, e em muita certeza, como bem sabeis quais fomos entre vós, por amor de vós.',
        'E vós fostes feitos nossos imitadores, e do Senhor, recebendo a palavra em muita tribulação, com gozo do Espírito Santo.',
        'De maneira que fostes exemplo para todos os fiéis na macedônia e Acaia.',
        'Porque por vós soou a palavra do Senhor, não somente na macedônia e Acaia, mas também em todos os lugares a vossa fé para com Deus se espalhou, de tal maneira que já dela não temos necessidade de falar coisa alguma;',
        'Porque eles mesmos anunciam de nós qual a entrada que tivemos para convosco, e como dos ídolos vos convertestes a Deus, para servir o Deus vivo e verdadeiro,',
        'E esperar dos céus o seu Filho, a quem ressuscitou dentre os mortos, a saber, Jesus, que nos livra da ira futura.',
      ],
      [
        'Porque vós mesmos, irmãos, bem sabeis que a nossa entrada para convosco não foi vã;',
        'Mas, mesmo depois de termos antes padecido, e sido agravados em Filipos, como sabeis, tornamo-nos ousados em nosso Deus, para vos falar o evangelho de Deus com grande combate.',
        'Porque a nossa exortação não foi com engano, nem com imundícia, nem com fraudulência;',
        'Mas, como fomos aprovados de Deus para que o evangelho nos fosse confiado, assim falamos, não como para agradar aos homens, mas a Deus, que prova os nossos corações.',
        'Porque, como bem sabeis, nunca usamos de palavras lisonjeiras, nem houve um pretexto de avareza; Deus é testemunha;',
        'E não buscamos glória dos homens, nem de vós, nem de outros, ainda que podíamos, como apóstolos de Cristo, ser-vos pesados;',
        'Antes fomos brandos entre vós, como a ama que cria seus filhos.',
        'Assim nós, sendo-vos tão afeiçoados, de boa vontade quiséramos comunicar-vos, não somente o evangelho de Deus, mas ainda as nossas próprias almas; porquanto nos éreis muito queridos.',
        'Porque bem vos lembrais, irmãos, do nosso trabalho e fadiga; pois, trabalhando noite e dia, para não sermos pesados a nenhum de vós, vos pregamos o evangelho de Deus.',
        'Vós e Deus sois testemunhas de quão santa, e justa, e irrepreensivelmente nos houvemos para convosco, os que crestes.',
        'Assim como bem sabeis de que modo vos exortávamos e consolávamos e testemunhávamos, a cada um de vós, como o pai a seus filhos;',
        'Para que vos conduzísseis dignamente para com Deus, que vos chama para o seu reino e glória.',
        'Por isso também damos, sem cessar, graças a Deus, pois, havendo recebido de nós a palavra da pregação de Deus, a recebestes, não como palavra de homens, mas (segundo é, na verdade), como palavra de Deus, a qual também opera em vós, os que crestes.',
        'Porque vós, irmãos, haveis sido feitos imitadores das igrejas de Deus que na Judéia estão em Jesus Cristo; porquanto também padecestes de vossos próprios concidadãos o mesmo que os judeus lhes fizeram a eles,',
        'Os quais também mataram o Senhor Jesus e os seus próprios profetas, e nos têm perseguido; e não agradam a Deus, e são contrários a todos os homens,',
        'E nos impedem de pregar aos gentios as palavras da salvação, a fim de encherem sempre a medida de seus pecados; mas a ira de Deus caiu sobre eles até ao fim.',
        'Nós, porém, irmãos, sendo privados de vós por um momento de tempo, de vista, mas não do coração, tanto mais procuramos com grande desejo ver o vosso rosto;',
        'Por isso bem quisemos uma e outra vez ir ter convosco, pelo menos eu, Paulo, mas Satanás no-lo impediu.',
        'Porque, qual é a nossa esperança, ou gozo, ou coroa de glória? Porventura não o sois vós também diante de nosso Senhor Jesus Cristo em sua vinda?',
        'Na verdade vós sois a nossa glória e gozo.',
      ],
      [
        'Por isso, não podendo esperar mais, achamos por bem ficar sozinhos em Atenas;',
        'E enviamos Timóteo, nosso irmão, e ministro de Deus, e nosso cooperador no evangelho de Cristo, para vos confortar e vos exortar acerca da vossa fé;',
        'Para que ninguém se comova por estas tribulações; porque vós mesmos sabeis que para isto fomos ordenados,',
        'Pois, estando ainda convosco, vos predizíamos que havíamos de ser afligidos, como sucedeu, e vós o sabeis.',
        'Portanto, não podendo eu também esperar mais, mandei-o saber da vossa fé, temendo que o tentador vos tentasse, e o nosso trabalho viesse a ser inútil.',
        'Vindo, porém, agora Timóteo de vós para nós, e trazendo-nos boas novas da vossa fé e amor, e de como sempre tendes boa lembrança de nós, desejando muito ver-nos, como nós também a vós;',
        'Por esta razão, irmãos, ficamos consolados acerca de vós, em toda a nossa aflição e necessidade, pela vossa fé,',
        'Porque agora vivemos, se estais firmes no Senhor.',
        'Porque, que ação de graças poderemos dar a Deus por vós, por todo o gozo com que nos regozijamos por vossa causa diante do nosso Deus,',
        'Orando abundantemente dia e noite, para que possamos ver o vosso rosto, e supramos o que falta à vossa fé?',
        'Ora, o mesmo nosso Deus e Pai, e nosso Senhor Jesus Cristo, encaminhem a nossa viagem para vós.',
        'E o Senhor vos aumente, e faça crescer em amor uns para com os outros, e para com todos, como também o fazemos para convosco;',
        'Para confirmar os vossos corações, para que sejais irrepreensíveis em santidade diante de nosso Deus e Pai, na vinda de nosso Senhor Jesus Cristo com todos os seus santos.',
      ],
      [
        'Finalmente, irmãos, vos rogamos e exortamos no Senhor Jesus, que assim como recebestes de nós, de que maneira convém andar e agradar a Deus, assim andai, para que possais progredir cada vez mais.',
        'Porque vós bem sabeis que mandamentos vos temos dado pelo Senhor Jesus.',
        'Porque esta é a vontade de Deus, a vossa santificação; que vos abstenhais da fornicação;',
        'Que cada um de vós saiba possuir o seu vaso em santificação e honra;',
        'Não na paixão da concupiscência, como os gentios, que não conhecem a Deus.',
        'Ninguém oprima ou engane a seu irmão em negócio algum, porque o Senhor é vingador de todas estas coisas, como também antes vo-lo dissemos e testificamos.',
        'Porque não nos chamou Deus para a imundícia, mas para a santificação.',
        'Portanto, quem despreza isto não despreza ao homem, mas sim a Deus, que nos deu também o seu Espírito Santo.',
        'Quanto, porém, ao amor fraternal, não necessitais de que vos escreva, visto que vós mesmos estais instruídos por Deus que vos ameis uns aos outros;',
        'Porque também já assim o fazeis para com todos os irmãos que estão por toda a macedônia. Exortamo-vos, porém, a que ainda nisto aumenteis cada vez mais.',
        'E procureis viver quietos, e tratar dos vossos próprios negócios, e trabalhar com vossas próprias mãos, como já vo-lo temos mandado;',
        'Para que andeis honestamente para com os que estão de fora, e não necessiteis de coisa alguma.',
        'Não quero, porém, irmãos, que sejais ignorantes acerca dos que já dormem, para que não vos entristeçais, como os demais, que não têm esperança.',
        'Porque, se cremos que Jesus morreu e ressuscitou, assim também aos que em Jesus dormem, Deus os tornará a trazer com ele.',
        'Dizemo-vos, pois, isto, pela palavra do Senhor: que nós, os que ficarmos vivos para a vinda do Senhor, não precederemos os que dormem.',
        'Porque o mesmo Senhor descerá do céu com alarido, e com voz de arcanjo, e com a trombeta de Deus; e os que morreram em Cristo ressuscitarão primeiro.',
        'Depois nós, os que ficarmos vivos, seremos arrebatados juntamente com eles nas nuvens, a encontrar o Senhor nos ares, e assim estaremos sempre com o Senhor.',
        'Portanto, consolai-vos uns aos outros com estas palavras.',
      ],
      [
        'Mas, irmãos, acerca dos tempos e das estações, não necessitais de que se vos escreva;',
        'Porque vós mesmos sabeis muito bem que o dia do Senhor virá como o ladrão de noite;',
        'Pois que, quando disserem: Há paz e segurança, então lhes sobrevirá repentina destruição, como as dores de parto àquela que está grávida, e de modo nenhum escaparão.',
        'Mas vós, irmãos, já não estais em trevas, para que aquele dia vos surpreenda como um ladrão;',
        'Porque todos vós sois filhos da luz e filhos do dia; nós não somos da noite nem das trevas.',
        'Não durmamos, pois, como os demais, mas vigiemos, e sejamos sóbrios;',
        'Porque os que dormem, dormem de noite, e os que se embebedam, embebedam-se de noite.',
        'Mas nós, que somos do dia, sejamos sóbrios, vestindo-nos da couraça da fé e do amor, e tendo por capacete a esperança da salvação;',
        'Porque Deus não nos destinou para a ira, mas para a aquisição da salvação, por nosso Senhor Jesus Cristo,',
        'Que morreu por nós, para que, quer vigiemos, quer durmamos, vivamos juntamente com ele.',
        'Por isso exortai-vos uns aos outros, e edificai-vos uns aos outros, como também o fazeis.',
        'E rogamo-vos, irmãos, que reconheçais os que trabalham entre vós e que presidem sobre vós no Senhor, e vos admoestam;',
        'E que os tenhais em grande estima e amor, por causa da sua obra. Tende paz entre vós.',
        'Rogamo-vos, também, irmãos, que admoesteis os desordeiros, consoleis os de pouco ânimo, sustenteis os fracos, e sejais pacientes para com todos.',
        'Vede que ninguém dê a outrem mal por mal, mas segui sempre o bem, tanto uns para com os outros, como para com todos.',
        'Regozijai-vos sempre.',
        'Orai sem cessar.',
        'Em tudo dai graças, porque esta é a vontade de Deus em Cristo Jesus para convosco.',
        'Não extingais o Espírito.',
        'Não desprezeis as profecias.',
        'Examinai tudo. Retende o bem.',
        'Abstende-vos de toda a aparência do mal.',
        'E o mesmo Deus de paz vos santifique em tudo; e todo o vosso espírito, e alma, e corpo, sejam plenamente conservados irrepreensíveis para a vinda de nosso Senhor Jesus Cristo.',
        'Fiel é o que vos chama, o qual também o fará.',
        'Irmãos, orai por nós.',
        'Saudai a todos os irmãos com ósculo santo.',
        'Pelo Senhor vos conjuro que esta epístola seja lida a todos os santos irmãos.',
        'A graça de nosso Senhor Jesus Cristo seja convosco. Amém.',
      ],
    ],
    livro: '1tessalonicenses',
  },
  {
    abbrev: '2ts',
    capitulos: [
      [
        'Paulo, e Silvano, e Timóteo, à igreja dos tessalonicenses, em Deus nosso Pai, e no Senhor Jesus Cristo:',
        'Graça e paz a vós da parte de Deus nosso Pai, e da do Senhor Jesus Cristo.',
        'Sempre devemos, irmãos, dar graças a Deus por vós, como é justo, porque a vossa fé cresce muitíssimo e o amor de cada um de vós aumenta de uns para com os outros,',
        'De maneira que nós mesmos nos gloriamos de vós nas igrejas de Deus por causa da vossa paciência e fé, e em todas as vossas perseguições e aflições que suportais;',
        'Prova clara do justo juízo de Deus, para que sejais havidos por dignos do reino de Deus, pelo qual também padeceis;',
        'Se de fato é justo diante de Deus que dê em paga tribulação aos que vos atribulam,',
        'E a vós, que sois atribulados, descanso conosco, quando se manifestar o Senhor Jesus desde o céu com os anjos do seu poder,',
        'Com labareda de fogo, tomando vingança dos que não conhecem a Deus e dos que não obedecem ao evangelho de nosso Senhor Jesus Cristo;',
        'Os quais, por castigo, padecerão eterna perdição, longe da face do Senhor e da glória do seu poder,',
        'Quando vier para ser glorificado nos seus santos, e para se fazer admirável naquele dia em todos os que crêem (porquanto o nosso testemunho foi crido entre vós).',
        'Por isso também rogamos sempre por vós, para que o nosso Deus vos faça dignos da sua vocação, e cumpra todo o desejo da sua bondade, e a obra da fé com poder;',
        'Para que o nome de nosso Senhor Jesus Cristo seja em vós glorificado, e vós nele, segundo a graça de nosso Deus e do Senhor Jesus Cristo.',
      ],
      [
        'Ora, irmãos, rogamo-vos, pela vinda de nosso Senhor Jesus Cristo, e pela nossa reunião com ele,',
        'Que não vos movais facilmente do vosso entendimento, nem vos perturbeis, quer por espírito, quer por palavra, quer por epístola, como de nós, como se o dia de Cristo estivesse já perto.',
        'Ninguém de maneira alguma vos engane; porque não será assim sem que antes venha a apostasia, e se manifeste o homem do pecado, o filho da perdição,',
        'O qual se opõe, e se levanta contra tudo o que se chama Deus, ou se adora; de sorte que se assentará, como Deus, no templo de Deus, querendo parecer Deus.',
        'Não vos lembrais de que estas coisas vos dizia quando ainda estava convosco?',
        'E agora vós sabeis o que o detém, para que a seu próprio tempo seja manifestado.',
        'Porque já o mistério da injustiça opera; somente há um que agora o retém até que do meio seja tirado;',
        'E então será revelado o iníquo, a quem o Senhor desfará pelo assopro da sua boca, e aniquilará pelo esplendor da sua vinda;',
        'A esse cuja vinda é segundo a eficácia de Satanás, com todo o poder, e sinais e prodígios de mentira,',
        'E com todo o engano da injustiça para os que perecem, porque não receberam o amor da verdade para se salvarem.',
        'E por isso Deus lhes enviará a operação do erro, para que creiam a mentira;',
        'Para que sejam julgados todos os que não creram a verdade, antes tiveram prazer na iniqüidade.',
        'Mas devemos sempre dar graças a Deus por vós, irmãos amados do Senhor, por vos ter Deus elegido desde o princípio para a salvação, em santificação do Espírito, e fé da verdade;',
        'Para o que pelo nosso evangelho vos chamou, para alcançardes a glória de nosso Senhor Jesus Cristo.',
        'Então, irmãos, estai firmes e retende as tradições que vos foram ensinadas, seja por palavra, seja por epístola nossa.',
        'E o próprio nosso Senhor Jesus Cristo e nosso Deus e Pai, que nos amou, e em graça nos deu uma eterna consolação e boa esperança,',
        'Console os vossos corações, e vos confirme em toda a boa palavra e obra.',
      ],
      [
        'No demais, irmãos, rogai por nós, para que a palavra do Senhor tenha livre curso e seja glorificada, como também o é entre vós;',
        'E para que sejamos livres de homens dissolutos e maus; porque a fé não é de todos.',
        'Mas fiel é o Senhor, que vos confirmará, e guardará do maligno.',
        'E confiamos quanto a vós no Senhor, que não só fazeis como fareis o que vos mandamos.',
        'Ora o Senhor encaminhe os vossos corações no amor de Deus, e na paciência de Cristo.',
        'Mandamo-vos, porém, irmãos, em nome de nosso Senhor Jesus Cristo, que vos aparteis de todo o irmão que anda desordenadamente, e não segundo a tradição que de nós recebeu.',
        'Porque vós mesmos sabeis como convém imitar-nos, pois que não nos houvemos desordenadamente entre vós,',
        'Nem de graça comemos o pão de homem algum, mas com trabalho e fadiga, trabalhando noite e dia, para não sermos pesados a nenhum de vós.',
        'Não porque não tivéssemos autoridade, mas para vos dar em nós mesmos exemplo, para nos imitardes.',
        'Porque, quando ainda estávamos convosco, vos mandamos isto, que, se alguém não quiser trabalhar, não coma também.',
        'Porquanto ouvimos que alguns entre vós andam desordenadamente, não trabalhando, antes fazendo coisas vãs.',
        'A esses tais, porém, mandamos, e exortamos por nosso Senhor Jesus Cristo, que, trabalhando com sossego, comam o seu próprio pão.',
        'E vós, irmãos, não vos canseis de fazer o bem.',
        'Mas, se alguém não obedecer à nossa palavra por esta carta, notai o tal, e não vos mistureis com ele, para que se envergonhe.',
        'Todavia não o tenhais como inimigo, mas admoestai-o como irmão.',
        'Ora, o mesmo Senhor da paz vos dê sempre paz de toda a maneira.O Senhor seja com todos vós.',
        'Saudação da minha própria mão, de mim, Paulo, que é o sinal em todas as epístolas; assim escrevo.',
        'A graça de nosso Senhor Jesus Cristo seja com todos vós. Amém.',
      ],
    ],
    livro: '2tessalonicenses',
  },
  {
    abbrev: '1tm',
    capitulos: [
      [
        'Paulo, apóstolo de Jesus Cristo, segundo o mandado de Deus, nosso Salvador, e do Senhor Jesus Cristo, esperança nossa,',
        'A Timóteo meu verdadeiro filho na fé: Graça, misericórdia e paz da parte de Deus nosso Pai, e da de Cristo Jesus, nosso Senhor.',
        'Como te roguei, quando parti para a macedônia, que ficasses em Éfeso, para advertires a alguns, que não ensinem outra doutrina,',
        'Nem se dêem a fábulas ou a genealogias intermináveis, que mais produzem questões do que edificação de Deus, que consiste na fé; assim o faço agora.',
        'Ora, o fim do mandamento é o amor de um coração puro, e de uma boa consciência, e de uma fé não fingida.',
        'Do que, desviando-se alguns, se entregaram a vãs contendas;',
        'Querendo ser mestres da lei, e não entendendo nem o que dizem nem o que afirmam.',
        'Sabemos, porém, que a lei é boa, se alguém dela usa legitimamente;',
        'Sabendo isto, que a lei não é feita para o justo, mas para os injustos e obstinados, para os ímpios e pecadores, para os profanos e irreligiosos, para os parricidas e matricidas, para os homicidas,',
        'Para os devassos, para os sodomitas, para os roubadores de homens, para os mentirosos, para os perjuros, e para o que for contrário à sã doutrina,',
        'Conforme o evangelho da glória de Deus bem-aventurado, que me foi confiado.',
        'E dou graças ao que me tem confortado, a Cristo Jesus Senhor nosso, porque me teve por fiel, pondo-me no ministério;',
        'A mim, que dantes fui blasfemo, e perseguidor, e injurioso; mas alcancei misericórdia, porque o fiz ignorantemente, na incredulidade.',
        'E a graça de nosso Senhor superabundou com a fé e amor que há em Jesus Cristo.',
        'Esta é uma palavra fiel, e digna de toda a aceitação, que Cristo Jesus veio ao mundo, para salvar os pecadores, dos quais eu sou o principal.',
        'Mas por isso alcancei misericórdia, para que em mim, que sou o principal, Jesus Cristo mostrasse toda a sua longanimidade, para exemplo dos que haviam de crer nele para a vida eterna.',
        'Ora, ao Rei dos séculos, imortal, invisível, ao único Deus sábio, seja honra e glória para todo o sempre. Amém.',
        'Este mandamento te dou, meu filho Timóteo, que, segundo as profecias que houve acerca de ti, milites por elas boa milícia;',
        'Conservando a fé, e a boa consciência, a qual alguns, rejeitando, fizeram naufrágio na fé.',
        'E entre esses foram Himeneu e Alexandre, os quais entreguei a Satanás, para que aprendam a não blasfemar.',
      ],
      [
        'Admoesto-te, pois, antes de tudo, que se façam deprecações, orações, intercessões, e ações de graças, por todos os homens;',
        'Pelos reis, e por todos os que estão em eminência, para que tenhamos uma vida quieta e sossegada, em toda a piedade e honestidade;',
        'Porque isto é bom e agradável diante de Deus nosso Salvador,',
        'Que quer que todos os homens se salvem, e venham ao conhecimento da verdade.',
        'Porque há um só Deus, e um só Mediador entre Deus e os homens, Jesus Cristo homem.',
        'O qual se deu a si mesmo em preço de redenção por todos, para servir de testemunho a seu tempo.',
        'Para o que (digo a verdade em Cristo, não minto) fui constituído pregador, e apóstolo, e doutor dos gentios na fé e na verdade.',
        'Quero, pois, que os homens orem em todo o lugar, levantando mãos santas, sem ira nem contenda.',
        'Que do mesmo modo as mulheres se ataviem em traje honesto, com pudor e modéstia, não com tranças, ou com ouro, ou pérolas, ou vestidos preciosos,',
        'Mas (como convém a mulheres que fazem profissão de servir a Deus) com boas obras.',
        'A mulher aprenda em silêncio, com toda a sujeição.',
        'Não permito, porém, que a mulher ensine, nem use de autoridade sobre o marido, mas que esteja em silêncio.',
        'Porque primeiro foi formado Adão, depois Eva.',
        'E Adão não foi enganado, mas a mulher, sendo enganada, caiu em transgressão.',
        'Salvar-se-á, porém, dando à luz filhos, se permanecer com modéstia na fé, no amor e na santificação.',
      ],
      [
        'Esta é uma palavra fiel: se alguém deseja o episcopado, excelente obra deseja.',
        'Convém, pois, que o bispo seja irrepreensível, marido de uma mulher, vigilante, sóbrio, honesto, hospitaleiro, apto para ensinar;',
        'Não dado ao vinho, não espancador, não cobiçoso de torpe ganância, mas moderado, não contencioso, não avarento;',
        'Que governe bem a sua própria casa, tendo seus filhos em sujeição, com toda a modéstia',
        '(Porque, se alguém não sabe governar a sua própria casa, terá cuidado da igreja de Deus? );',
        'Não neófito, para que, ensoberbecendo-se, não caia na condenação do diabo.',
        'Convém também que tenha bom testemunho dos que estão de fora, para que não caia em afronta, e no laço do diabo.',
        'Da mesma sorte os diáconos sejam honestos, não de língua dobre, não dados a muito vinho, não cobiçosos de torpe ganância;',
        'Guardando o mistério da fé numa consciência pura.',
        'E também estes sejam primeiro provados, depois sirvam, se forem irrepreensíveis.',
        'Da mesma sorte as esposas sejam honestas, não maldizentes, sóbrias e fiéis em tudo.',
        'Os diáconos sejam maridos de uma só mulher, e governem bem a seus filhos e suas próprias casas.',
        'Porque os que servirem bem como diáconos, adquirirão para si uma boa posição e muita confiança na fé que há em Cristo Jesus.',
        'Escrevo-te estas coisas, esperando ir ver-te bem depressa;',
        'Mas, se tardar, para que saibas como convém andar na casa de Deus, que é a igreja do Deus vivo, a coluna e firmeza da verdade.',
        'E, sem dúvida alguma, grande é o mistério da piedade: Deus se manifestou em carne, foi justificado no Espírito, visto dos anjos, pregado aos gentios, crido no mundo, recebido acima na glória.',
      ],
      [
        'Mas o Espírito expressamente diz que nos últimos tempos apostatarão alguns da fé, dando ouvidos a espíritos enganadores, e a doutrinas de demônios;',
        'Pela hipocrisia de homens que falam mentiras, tendo cauterizada a sua própria consciência;',
        'Proibindo o casamento, e ordenando a abstinência dos alimentos que Deus criou para os fiéis, e para os que conhecem a verdade, a fim de usarem deles com ações de graças;',
        'Porque toda a criatura de Deus é boa, e não há nada que rejeitar, sendo recebido com ações de graças.',
        'Porque pela palavra de Deus e pela oração é santificada.',
        'Propondo estas coisas aos irmãos, serás bom ministro de Jesus Cristo, criado com as palavras da fé e da boa doutrina que tens seguido.',
        'Mas rejeita as fábulas profanas e de velhas, e exercita-te a ti mesmo em piedade;',
        'Porque o exercício corporal para pouco aproveita, mas a piedade para tudo é proveitosa, tendo a promessa da vida presente e da que há de vir.',
        'Esta palavra é fiel e digna de toda a aceitação;',
        'Porque para isto trabalhamos e somos injuriados, pois esperamos no Deus vivo, que é o Salvador de todos os homens, principalmente dos fiéis.',
        'Manda estas coisas e ensina-as.',
        'Ninguém despreze a tua mocidade; mas sê o exemplo dos fiéis, na palavra, no trato, no amor, no espírito, na fé, na pureza.',
        'Persiste em ler, exortar e ensinar, até que eu vá.',
        'Não desprezes o dom que há em ti, o qual te foi dado por profecia, com a imposição das mãos do presbitério.',
        'Medita estas coisas; ocupa-te nelas, para que o teu aproveitamento seja manifesto a todos.',
        'Tem cuidado de ti mesmo e da doutrina. Persevera nestas coisas; porque, fazendo isto, te salvarás, tanto a ti mesmo como aos que te ouvem.',
      ],
      [
        'NÃO repreendas asperamente o ancião, mas admoesta-o como a pai; aos moços como a irmãos;',
        'As mulheres idosas, como a mães, às moças, como a irmãs, em toda a pureza.',
        'Honra as viúvas que verdadeiramente são viúvas.',
        'Mas, se alguma viúva tiver filhos, ou netos, aprendam primeiro a exercer piedade para com a sua própria família, e a recompensar seus pais; porque isto é bom e agradável diante de Deus.',
        'Ora, a que é verdadeiramente viúva e desamparada espera em Deus, e persevera de noite e de dia em rogos e orações;',
        'Mas a que vive em deleites, vivendo está morta.',
        'Manda, pois, estas coisas, para que elas sejam irrepreensíveis.',
        'Mas, se alguém não tem cuidado dos seus, e principalmente dos da sua família, negou a fé, e é pior do que o infiel.',
        'Nunca seja inscrita viúva com menos de sessenta anos, e só a que tenha sido mulher de um só marido;',
        'Tendo testemunho de boas obras: Se criou os filhos, se exercitou hospitalidade, se lavou os pés aos santos, se socorreu os aflitos, se praticou toda a boa obra.',
        'Mas não admitas as viúvas mais novas, porque, quando se tornam levianas contra Cristo, querem casar-se;',
        'Tendo já a sua condenação por haverem aniquilado a primeira fé.',
        'E, além disto, aprendem também a andar ociosas de casa em casa; e não só ociosas, mas também paroleiras e curiosas, falando o que não convém.',
        'Quero, pois, que as que são moças se casem, gerem filhos, governem a casa, e não dêem ocasião ao adversário de maldizer;',
        'Porque já algumas se desviaram, indo após Satanás.',
        'Se algum crente ou alguma crente tem viúvas, socorra-as, e não se sobrecarregue a igreja, para que se possam sustentar as que deveras são viúvas.',
        'Os presbíteros que governam bem sejam estimados por dignos de duplicada honra, principalmente os que trabalham na palavra e na doutrina;',
        'Porque diz a Escritura: Não ligarás a boca ao boi que debulha. E: Digno é o obreiro do seu salário.',
        'Não aceites acusação contra o presbítero, senão com duas ou três testemunhas.',
        'Aos que pecarem, repreende-os na presença de todos, para que também os outros tenham temor.',
        'Conjuro-te diante de Deus, e do Senhor Jesus Cristo, e dos anjos eleitos, que sem prevenção guardes estas coisas, nada fazendo por parcialidade.',
        'A ninguém imponhas precipitadamente as mãos, nem participes dos pecados alheios; conserva-te a ti mesmo puro.',
        'Não bebas mais água só, mas usa de um pouco de vinho, por causa do teu estômago e das tuas freqüentes enfermidades.',
        'Os pecados de alguns homens são manifestos, precedendo o juízo; e em alguns manifestam-se depois.',
        'Assim mesmo também as boas obras são manifestas, e as que são de outra maneira não podem ocultar-se.',
      ],
      [
        'Todos os servos que estão debaixo do jugo estimem a seus senhores por dignos de toda a honra, para que o nome de Deus e a doutrina não sejam blasfemados.',
        'E os que têm senhores crentes não os desprezem, por serem irmãos; antes os sirvam melhor, porque eles, que participam do benefício, são crentes e amados. Isto ensina e exorta.',
        'Se alguém ensina alguma outra doutrina, e se não conforma com as sãs palavras de nosso Senhor Jesus Cristo, e com a doutrina que é segundo a piedade,',
        'É soberbo, e nada sabe, mas delira acerca de questões e contendas de palavras, das quais nascem invejas, porfias, blasfêmias, ruins suspeitas,',
        'Perversas contendas de homens corruptos de entendimento, e privados da verdade, cuidando que a piedade seja causa de ganho; aparta-te dos tais.',
        'Mas é grande ganho a piedade com contentamento.',
        'Porque nada trouxemos para este mundo, e manifesto é que nada podemos levar dele.',
        'Tendo, porém, sustento, e com que nos cobrirmos, estejamos com isso contentes.',
        'Mas os que querem ser ricos caem em tentação, e em laço, e em muitas concupiscências loucas e nocivas, que submergem os homens na perdição e ruína.',
        'Porque o amor ao dinheiro é a raiz de toda a espécie de males; e nessa cobiça alguns se desviaram da fé, e se traspassaram a si mesmos com muitas dores.',
        'Mas tu, ó homem de Deus, foge destas coisas, e segue a justiça, a piedade, a fé, o amor, a paciência, a mansidão.',
        'Milita a boa milícia da fé, toma posse da vida eterna, para a qual também foste chamado, tendo já feito boa confissão diante de muitas testemunhas.',
        'Mando-te diante de Deus, que todas as coisas vivifica, e de Cristo Jesus, que diante de Pôncio Pilatos deu o testemunho de boa confissão,',
        'Que guardes este mandamento sem mácula e repreensão, até à aparição de nosso Senhor Jesus Cristo;',
        'A qual a seu tempo mostrará o bem-aventurado, e único poderoso Senhor, Rei dos reis e Senhor dos senhores;',
        'Aquele que tem, ele só, a imortalidade, e habita na luz inacessível; a quem nenhum dos homens viu nem pode ver, ao qual seja honra e poder sempiterno. Amém.',
        'Manda aos ricos deste mundo que não sejam altivos, nem ponham a esperança na incerteza das riquezas, mas em Deus, que abundantemente nos dá todas as coisas para delas gozarmos;',
        'Que façam bem, enriqueçam em boas obras, repartam de boa mente, e sejam comunicáveis;',
        'Que entesourem para si mesmos um bom fundamento para o futuro, para que possam se apoderar da vida eterna.',
        'Ó Timóteo, guarda o depósito que te foi confiado, tendo horror aos clamores vãos e profanos e às oposições da falsamente chamada ciência,',
        'A qual professando-a alguns, se desviaram da fé. A graça seja contigo. Amém.',
      ],
    ],
    livro: '1timoteo',
  },
  {
    abbrev: '2tm',
    capitulos: [
      [
        'Paulo, apóstolo de Jesus Cristo, pela vontade de Deus, segundo a promessa da vida que está em Cristo Jesus,',
        'A Timóteo, meu amado filho: Graça, misericórdia, e paz da parte de Deus Pai, e da de Cristo Jesus, Senhor nosso.',
        'Dou graças a Deus, a quem desde os meus antepassados sirvo com uma consciência pura, de que sem cessar faço memória de ti nas minhas orações noite e dia;',
        'Desejando muito ver-te, lembrando-me das tuas lágrimas, para me encher de gozo;',
        'Trazendo à memória a fé não fingida que em ti há, a qual habitou primeiro em tua avó Lóide, e em tua mãe Eunice, e estou certo de que também habita em ti.',
        'Por cujo motivo te lembro que despertes o dom de Deus que existe em ti pela imposição das minhas mãos.',
        'Porque Deus não nos deu o espírito de temor, mas de fortaleza, e de amor, e de moderação.',
        'Portanto, não te envergonhes do testemunho de nosso Senhor, nem de mim, que sou prisioneiro seu; antes participa das aflições do evangelho segundo o poder de Deus,',
        'Que nos salvou, e chamou com uma santa vocação; não segundo as nossas obras, mas segundo o seu próprio propósito e graça que nos foi dada em Cristo Jesus antes dos tempos dos séculos;',
        'E que é manifesta agora pela aparição de nosso Salvador Jesus Cristo, o qual aboliu a morte, e trouxe à luz a vida e a incorrupção pelo evangelho;',
        'Para o que fui constituído pregador, e apóstolo, e doutor dos gentios.',
        'Por cuja causa padeço também isto, mas não me envergonho; porque eu sei em quem tenho crido, e estou certo de que é poderoso para guardar o meu depósito até àquele dia.',
        'Conserva o modelo das sãs palavras que de mim tens ouvido, na fé e no amor que há em Cristo Jesus.',
        'Guarda o bom depósito pelo Espírito Santo que habita em nós.',
        'Bem sabes isto, que os que estão na Ásia todos se apartaram de mim; entre os quais foram Figelo e Hermógenes.',
        'O Senhor conceda misericórdia à casa de Onesíforo, porque muitas vezes me recreou, e não se envergonhou das minhas cadeias.',
        'Antes, vindo ele a Roma, com muito cuidado me procurou e me achou.',
        'O Senhor lhe conceda que naquele dia ache misericórdia diante do Senhor. E, quanto me ajudou em Éfeso, melhor o sabes tu.',
      ],
      [
        'Tu, pois, meu filho, fortifica-te na graça que há em Cristo Jesus.',
        'E o que de mim, entre muitas testemunhas, ouviste, confia-o a homens fiéis, que sejam idôneos para também ensinarem os outros.',
        'Tu pois, sofre as aflições, como bom soldado de Jesus Cristo.',
        'Ninguém que milita se embaraça com negócios desta vida, a fim de agradar àquele que o alistou para a guerra.',
        'E, se alguém também milita, não é coroado se não militar legitimamente.',
        'O lavrador que trabalha deve ser o primeiro a gozar dos frutos.',
        'Considera o que digo, e o Senhor te dê entendimento em tudo.',
        'Lembra-te de que Jesus Cristo, que é da descendência de Davi, ressuscitou dentre os mortos, segundo o meu evangelho;',
        'Por isso sofro trabalhos e até prisões, como um malfeitor; mas a palavra de Deus não está presa.',
        'Portanto, tudo sofro por amor dos escolhidos, para que também eles alcancem a salvação que está em Cristo Jesus com glória eterna.',
        'Palavra fiel é esta: que, se morrermos com ele, também com ele viveremos;',
        'Se sofrermos, também com ele reinaremos; se o negarmos, também ele nos negará;',
        'Se formos infiéis, ele permanece fiel; não pode negar-se a si mesmo.',
        'Traze estas coisas à memória, ordenando-lhes diante do Senhor que não tenham contendas de palavras, que para nada aproveitam e são para perversão dos ouvintes.',
        'Procura apresentar-te a Deus aprovado, como obreiro que não tem de que se envergonhar, que maneja bem a palavra da verdade.',
        'Mas evita os falatórios profanos, porque produzirão maior impiedade.',
        'E a palavra desses roerá como gangrena; entre os quais são Himeneu e Fileto;',
        'Os quais se desviaram da verdade, dizendo que a ressurreição era já feita, e perverteram a fé de alguns.',
        'Todavia o fundamento de Deus fica firme, tendo este selo: O Senhor conhece os que são seus, e qualquer que profere o nome de Cristo aparte-se da iniqüidade.',
        'Ora, numa grande casa não somente há vasos de ouro e de prata, mas também de pau e de barro; uns para honra, outros, porém, para desonra.',
        'De sorte que, se alguém se purificar destas coisas, será vaso para honra, santificado e idôneo para uso do Senhor, e preparado para toda a boa obra.',
        'Foge também das paixões da mocidade; e segue a justiça, a fé, o amor, e a paz com os que, com um coração puro, invocam o Senhor.',
        'E rejeita as questões loucas, e sem instrução, sabendo que produzem contendas.',
        'E ao servo do Senhor não convém contender, mas sim, ser manso para com todos, apto para ensinar, sofredor;',
        'Instruindo com mansidão os que resistem, a ver se porventura Deus lhes dará arrependimento para conhecerem a verdade,',
        'E tornarem a despertar, desprendendo-se dos laços do diabo, em que à vontade dele estão presos.',
      ],
      [
        'Sabe, porém, isto: que nos últimos dias sobrevirão tempos trabalhosos.',
        'Porque haverá homens amantes de si mesmos, avarentos, presunçosos, soberbos, blasfemos, desobedientes a pais e mães, ingratos, profanos,',
        'Sem afeto natural, irreconciliáveis, caluniadores, incontinentes, cruéis, sem amor para com os bons,',
        'Traidores, obstinados, orgulhosos, mais amigos dos deleites do que amigos de Deus,',
        'Tendo aparência de piedade, mas negando a eficácia dela. Destes afasta-te.',
        'Porque deste número são os que se introduzem pelas casas, e levam cativas mulheres néscias carregadas de pecados, levadas de várias concupiscências;',
        'Que aprendem sempre, e nunca podem chegar ao conhecimento da verdade.',
        'E, como Janes e Jambres resistiram a Moisés, assim também estes resistem à verdade, sendo homens corruptos de entendimento e réprobos quanto à fé.',
        'Não irão, porém, avante; porque a todos será manifesto o seu desvario, como também o foi o daqueles.',
        'Tu, porém, tens seguido a minha doutrina, modo de viver, intenção, fé, longanimidade, amor, paciência,',
        'Perseguições e aflições tais quais me aconteceram em Antioquia, em Icônio, e em Listra; quantas perseguições sofri, e o Senhor de todas me livrou;',
        'E também todos os que piamente querem viver em Cristo Jesus padecerão perseguições.',
        'Mas os homens maus e enganadores irão de mal para pior, enganando e sendo enganados.',
        'Tu, porém, permanece naquilo que aprendeste, e de que foste inteirado, sabendo de quem o tens aprendido,',
        'E que desde a tua meninice sabes as sagradas Escrituras, que podem fazer-te sábio para a salvação, pela fé que há em Cristo Jesus.',
        'Toda a Escritura é divinamente inspirada, e proveitosa para ensinar, para redargüir, para corrigir, para instruir em justiça;',
        'Para que o homem de Deus seja perfeito, e perfeitamente instruído para toda a boa obra.',
      ],
      [
        'Conjuro-te, pois, diante de Deus, e do Senhor Jesus Cristo, que há de julgar os vivos e os mortos, na sua vinda e no seu reino,',
        'Que pregues a palavra, instes a tempo e fora de tempo, redarguas, repreendas, exortes, com toda a longanimidade e doutrina.',
        'Porque virá tempo em que não suportarão a sã doutrina; mas, tendo comichão nos ouvidos, amontoarão para si doutores conforme as suas próprias concupiscências;',
        'E desviarão os ouvidos da verdade, voltando às fábulas.',
        'Mas tu, sê sóbrio em tudo, sofre as aflições, faze a obra de um evangelista, cumpre o teu ministério.',
        'Porque eu já estou sendo oferecido por aspersão de sacrifício, e o tempo da minha partida está próximo.',
        'Combati o bom combate, acabei a carreira, guardei a fé.',
        'Desde agora, a coroa da justiça me está guardada, a qual o Senhor, justo juiz, me dará naquele dia; e não somente a mim, mas também a todos os que amarem a sua vinda.',
        'Procura vir ter comigo depressa,',
        'Porque Demas me desamparou, amando o presente século, e foi para Tessalônica, Crescente para Galácia, Tito para Dalmácia.',
        'Só Lucas está comigo. Toma Marcos, e traze-o contigo, porque me é muito útil para o ministério.',
        'Também enviei Tíquico a Éfeso.',
        'Quando vieres, traze a capa que deixei em Trôade, em casa de Carpo, e os livros, principalmente os pergaminhos.',
        'Alexandre, o latoeiro, causoume muitos males; o Senhor lhe pague segundo as suas obras.',
        'Tu, guarda-te também dele, porque resistiu muito às nossas palavras.',
        'Ninguém me assistiu na minha primeira defesa, antes todos me desampararam. Que isto lhes não seja imputado.',
        'Mas o Senhor assistiu-me e fortaleceu-me, para que por mim fosse cumprida a pregação, e todos os gentios a ouvissem; e fiquei livre da boca do leão.',
        'E o Senhor me livrará de toda a má obra, e guardar-me-á para o seu reino celestial; a quem seja glória para todo o sempre. Amém.',
        'Saúda a Prisca e a Áqüila, e à casa de Onesíforo.',
        'Erasto ficou em Corinto, e deixei Trófimo doente em Mileto.',
        'Procura vir antes do inverno. Eubulo, e Prudente, e Lino, e Cláudia, e todos os irmãos te saúdam.',
        'O Senhor Jesus Cristo seja com o teu espírito. A graça seja convosco. Amém.',
      ],
    ],
    livro: '2timoteo',
  },
  {
    abbrev: 'tt',
    capitulos: [
      [
        'Paulo, servo de Deus, e apóstolo de Jesus Cristo, segundo a fé dos eleitos de Deus, e o conhecimento da verdade, que é segundo a piedade,',
        'Em esperança da vida eterna, a qual Deus, que não pode mentir, prometeu antes dos tempos dos séculos;',
        'Mas a seu tempo manifestou a sua palavra pela pregação que me foi confiada segundo o mandamento de Deus, nosso Salvador;',
        'A Tito, meu verdadeiro filho, segundo a fé comum: Graça, misericórdia, e paz da parte de Deus Pai, e da do Senhor Jesus Cristo, nosso Salvador.',
        'Por esta causa te deixei em Creta, para que pusesses em boa ordem as coisas que ainda restam, e de cidade em cidade estabelecesses presbíteros, como já te mandei:',
        'Aquele que for irrepreensível, marido de uma mulher, que tenha filhos fiéis, que não possam ser acusados de dissolução nem são desobedientes.',
        'Porque convém que o bispo seja irrepreensível, como despenseiro da casa de Deus, não soberbo, nem iracundo, nem dado ao vinho, nem espancador, nem cobiçoso de torpe ganância;',
        'Mas dado à hospitalidade, amigo do bem, moderado, justo, santo, temperante;',
        'Retendo firme a fiel palavra, que é conforme a doutrina, para que seja poderoso, tanto para admoestar com a sã doutrina, como para convencer os contradizentes.',
        'Porque há muitos desordenados, faladores, vãos e enganadores, principalmente os da circuncisão,',
        'Aos quais convém tapar a boca; homens que transtornam casas inteiras ensinando o que não convém, por torpe ganância.',
        'Um deles, seu próprio profeta, disse: Os cretenses são sempre mentirosos, bestas ruins, ventres preguiçosos.',
        'Este testemunho é verdadeiro. Portanto, repreende-os severamente, para que sejam sãos na fé.',
        'Não dando ouvidos às fábulas judaicas, nem aos mandamentos de homens que se desviam da verdade.',
        'Todas as coisas são puras para os puros, mas nada é puro para os contaminados e infiéis; antes o seu entendimento e consciência estão contaminados.',
        'Confessam que conhecem a Deus, mas negam-no com as obras, sendo abomináveis, e desobedientes, e reprovados para toda a boa obra.',
      ],
      [
        'Tu, porém, fala o que convém à sã doutrina.',
        'Os velhos, que sejam sóbrios, graves, prudentes, sãos na fé, no amor, e na paciência;',
        'As mulheres idosas, semelhantemente, que sejam sérias no seu viver, como convém a santas, não caluniadoras, não dadas a muito vinho, mestras no bem;',
        'Para que ensinem as mulheres novas a serem prudentes, a amarem seus maridos, a amarem seus filhos,',
        'A serem moderadas, castas, boas donas de casa, sujeitas a seus maridos, a fim de que a palavra de Deus não seja blasfemada.',
        'Exorta semelhantemente os jovens a que sejam moderados.',
        'Em tudo te dá por exemplo de boas obras; na doutrina mostra incorrupção, gravidade, sinceridade,',
        'Linguagem sã e irrepreensível, para que o adversário se envergonhe, não tendo nenhum mal que dizer de nós.',
        'Exorta os servos a que se sujeitem a seus senhores, e em tudo agradem, não contradizendo,',
        'Não defraudando, antes mostrando toda a boa lealdade, para que em tudo sejam ornamento da doutrina de Deus, nosso Salvador.',
        'Porque a graça salvadora de Deus se há manifestado a todos os homens,',
        'Ensinando-nos que, renunciando à impiedade e às concupiscências mundanas, vivamos neste presente século sóbria, e justa, e piamente,',
        'Aguardando a bem-aventurada esperança e o aparecimento da glória do grande Deus e nosso Senhor Jesus Cristo;',
        'O qual se deu a si mesmo por nós para nos remir de toda a iniqüidade, e purificar para si um povo seu especial, zeloso de boas obras.',
        'Fala disto, e exorta e repreende com toda a autoridade. Ninguém te despreze.',
      ],
      [
        'Admoesta-os a que se sujeitem aos principados e potestades, que lhes obedeçam, e estejam preparados para toda a boa obra;',
        'Que a ninguém infamem, nem sejam contenciosos, mas modestos, mostrando toda a mansidão para com todos os homens.',
        'Porque também nós éramos noutro tempo insensatos, desobedientes, extraviados, servindo a várias concupiscências e deleites, vivendo em malícia e inveja, odiosos, odiando-nos uns aos outros.',
        'Mas quando apareceu a benignidade e amor de Deus, nosso Salvador, para com os homens,',
        'Não pelas obras de justiça que houvéssemos feito, mas segundo a sua misericórdia, nos salvou pela lavagem da regeneração e da renovação do Espírito Santo,',
        'Que abundantemente ele derramou sobre nós por Jesus Cristo nosso Salvador;',
        'Para que, sendo justificados pela sua graça, sejamos feitos herdeiros segundo a esperança da vida eterna.',
        'Fiel é a palavra, e isto quero que deveras afirmes, para que os que crêem em Deus procurem aplicar-se às boas obras; estas coisas são boas e proveitosas aos homens.',
        'Mas não entres em questões loucas, genealogias e contendas, e nos debates acerca da lei; porque são coisas inúteis e vãs.',
        'Ao homem hereje, depois de uma e outra admoestação, evita-o,',
        'Sabendo que esse tal está pervertido, e peca, estando já em si mesmo condenado.',
        'Quando te enviar Ártemas, ou Tíquico, procura vir ter comigo a Nicópolis; porque deliberei invernar ali.',
        'Acompanha com muito cuidado Zenas, doutor da lei, e Apolo, para que nada lhes falte.',
        'E os nossos aprendam também a aplicar-se às boas obras, nas coisas necessárias, para que não sejam infrutuosos.',
        'Saúdam-te todos os que estão comigo. Saúda tu os que nos amam na fé. A graça seja com vós todos. Amém.',
      ],
    ],
    livro: 'tito',
  },
  {
    abbrev: 'fm',
    capitulos: [
      [
        'Paulo, prisioneiro de Jesus Cristo, e o irmão Timóteo, ao amado Filemom, nosso cooperador,',
        'E à nossa amada Áfia, e a Arquipo, nosso camarada, e à igreja que está em tua casa:',
        'Graça a vós e paz da parte de Deus nosso Pai, e do Senhor Jesus Cristo.',
        'Graças dou ao meu Deus, lembrando-me sempre de ti nas minhas orações;',
        'Ouvindo do teu amor e da fé que tens para com o Senhor Jesus Cristo, e para com todos os santos;',
        'Para que a comunicação da tua fé seja eficaz no conhecimento de todo o bem que em vós há por Cristo Jesus.',
        'Porque temos grande gozo e consolação do teu amor, porque por ti, ó irmão, as entranhas dos santos foram recreadas.',
        'Por isso, ainda que tenha em Cristo grande confiança para te mandar o que te convém,',
        'Todavia peço-te antes por amor, sendo eu tal como sou, Paulo o velho, e também agora prisioneiro de Jesus Cristo.',
        'Peço-te por meu filho Onésimo, que gerei nas minhas prisões;',
        'O qual noutro tempo te foi inútil, mas agora a ti e a mim muito útil; eu to tornei a enviar.',
        'E tu torna a recebê-lo como às minhas entranhas.',
        'Eu bem o quisera conservar comigo, para que por ti me servisse nas prisões do evangelho;',
        'Mas nada quis fazer sem o teu parecer, para que o teu benefício não fosse como por força, mas, voluntário.',
        'Porque bem pode ser que ele se tenha separado de ti por algum tempo, para que o retivesses para sempre,',
        'Não já como servo, antes, mais do que servo, como irmão amado, particularmente de mim, e quanto mais de ti, assim na carne como no Senhor?',
        'Assim, pois, se me tens por companheiro, recebe-o como a mim mesmo.',
        'E, se te fez algum dano, ou te deve alguma coisa, põe isso à minha conta.',
        'Eu, Paulo, de minha própria mão o escrevi; eu o pagarei, para te não dizer que ainda mesmo a ti próprio a mim te deves.',
        'Sim, irmão, eu me regozijarei de ti no Senhor; recreia as minhas entranhas no Senhor.',
        'Escrevi-te confiado na tua obediência, sabendo que ainda farás mais do que digo.',
        'E juntamente prepara-me também pousada, porque espero que pelas vossas orações vos hei de ser concedido.',
        'Saúdam-te Epafras, meu companheiro de prisão por Cristo Jesus,',
        'Marcos, Aristarco, Demas e Lucas, meus cooperadores.',
        'A graça de nosso Senhor Jesus Cristo seja com o vosso espírito. Amém.',
      ],
    ],
    livro: 'filemom',
  },
  {
    abbrev: 'hb',
    capitulos: [
      [
        'Havendo Deus antigamente falado muitas vezes, e de muitas maneiras, aos pais, pelos profetas, a nós falou-nos nestes últimos dias pelo Filho,',
        'A quem constituiu herdeiro de tudo, por quem fez também o mundo.',
        'O qual, sendo o resplendor da sua glória, e a expressa imagem da sua pessoa, e sustentando todas as coisas pela palavra do seu poder, havendo feito por si mesmo a purificação dos nossos pecados, assentou-se à destra da majestade nas alturas;',
        'Feito tanto mais excelente do que os anjos, quanto herdou mais excelente nome do que eles.',
        'Porque, a qual dos anjos disse jamais: Tu és meu Filho, Hoje te gerei? E outra vez: Eu lhe serei por Pai, E ele me será por Filho?',
        'E outra vez, quando introduz no mundo o primogênito, diz:E todos os anjos de Deus o adorem.',
        'E, quanto aos anjos, diz: Faz dos seus anjos espíritos, E de seus ministros labareda de fogo.',
        'Mas, do Filho, diz: Ó Deus, o teu trono subsiste pelos séculos dos séculos; Cetro de eqüidade é o cetro do teu reino.',
        'Amaste a justiça e odiaste a iniqüidade; por isso Deus, o teu Deus, te ungiuCom óleo de alegria mais do que a teus companheiros.',
        'E: Tu, Senhor, no princípio fundaste a terra, E os céus são obra de tuas mãos.',
        'Eles perecerão, mas tu permanecerás; E todos eles, como roupa, envelhecerão,',
        'E como um manto os enrolarás, e serão mudados. Mas tu és o mesmo, E os teus anos não acabarão.',
        'E a qual dos anjos disse jamais: Assenta-te à minha destra, Até que ponha a teus inimigos por escabelo de teus pés?',
        'Não são porventura todos eles espíritos ministradores, enviados para servir a favor daqueles que hão de herdar a salvação?',
      ],
      [
        'Portanto, convém-nos atentar com mais diligência para as coisas que já temos ouvido, para que em tempo algum nos desviemos delas.',
        'Porque, se a palavra falada pelos anjos permaneceu firme, e toda a transgressão e desobediência recebeu a justa retribuição,',
        'Como escaparemos nós, se não atentarmos para uma tão grande salvação, a qual, começando a ser anunciada pelo Senhor, foi-nos depois confirmada pelos que a ouviram;',
        'Testificando também Deus com eles, por sinais, e milagres, e várias maravilhas e dons do Espírito Santo, distribuídos por sua vontade?',
        'Porque não foi aos anjos que sujeitou o mundo futuro, de que falamos.',
        'Mas em certo lugar testificou alguém, dizendo:Que é o homem, para que dele te lembres?Ou o filho do homem, para que o visites?',
        'Tu o fizeste um pouco menor do que os anjos,De glória e de honra o coroaste,E o constituíste sobre as obras de tuas mãos;',
        'Todas as coisas lhe sujeitaste debaixo dos pés.Ora, visto que lhe sujeitou todas as coisas, nada deixou que lhe não esteja sujeito. Mas agora ainda não vemos que todas as coisas lhe estejam sujeitas.',
        'Vemos, porém, coroado de glória e de honra aquele Jesus que fora feito um pouco menor do que os anjos, por causa da paixão da morte, para que, pela graça de Deus, provasse a morte por todos.',
        'Porque convinha que aquele, para quem são todas as coisas, e mediante quem tudo existe, trazendo muitos filhos à glória, consagrasse pelas aflições o príncipe da salvação deles.',
        'Porque, assim o que santifica, como os que são santificados, são todos de um; por cuja causa não se envergonha de lhes chamar irmãos,',
        'Dizendo:Anunciarei o teu nome a meus irmãos,Cantar-te-ei louvores no meio da congregação.',
        'E outra vez: Porei nele a minha confiança. E outra vez: Eis-me aqui a mim, e aos filhos que Deus me deu.',
        'E, visto como os filhos participam da carne e do sangue, também ele participou das mesmas coisas, para que pela morte aniquilasse o que tinha o império da morte, isto é, o diabo;',
        'E livrasse todos os que, com medo da morte, estavam por toda a vida sujeitos à servidão.',
        'Porque, na verdade, ele não tomou os anjos, mas tomou a descendência de Abraão.',
        'Por isso convinha que em tudo fosse semelhante aos irmãos, para ser misericordioso e fiel sumo sacerdote naquilo que é de Deus, para expiar os pecados do povo.',
        'Porque naquilo que ele mesmo, sendo tentado, padeceu, pode socorrer aos que são tentados.',
      ],
      [
        'Por isso, irmãos santos, participantes da vocação celestial, considerai a Jesus Cristo, apóstolo e sumo sacerdote da nossa confissão,',
        'Sendo fiel ao que o constituiu, como também o foi Moisés em toda a sua casa.',
        'Porque ele é tido por digno de tanto maior glória do que Moisés, quanto maior honra do que a casa tem aquele que a edificou.',
        'Porque toda a casa é edificada por alguém, mas o que edificou todas as coisas é Deus.',
        'E, na verdade, Moisés foi fiel em toda a sua casa, como servo, para testemunho das coisas que se haviam de anunciar;',
        'Mas Cristo, como Filho, sobre a sua própria casa; a qual casa somos nós, se tão somente conservarmos firme a confiança e a glória da esperança até ao fim.',
        'Portanto, como diz o Espírito Santo: Se ouvirdes hoje a sua voz,',
        'Não endureçais os vossos corações,Como na provocação, no dia da tentação no deserto.',
        'Onde vossos pais me tentaram, me provaram,E viram por quarenta anos as minhas obras.',
        'Por isso me indignei contra esta geração, E disse: Estes sempre erram em seu coração, E não conheceram os meus caminhos.',
        'Assim jurei na minha ira Que não entrarão no meu repouso.',
        'Vede, irmãos, que nunca haja em qualquer de vós um coração mau e infiel, para se apartar do Deus vivo.',
        'Antes, exortai-vos uns aos outros todos os dias, durante o tempo que se chama Hoje, para que nenhum de vós se endureça pelo engano do pecado;',
        'Porque nos tornamos participantes de Cristo, se retivermos firmemente o princípio da nossa confiança até ao fim.',
        'Enquanto se diz: Hoje, se ouvirdes a sua voz, Não endureçais os vossos corações, como na provocação.',
        'Porque, havendo-a alguns ouvido, o provocaram; mas não todos os que saíram do Egito por meio de Moisés.',
        'Mas com quem se indignou por quarenta anos? Não foi porventura com os que pecaram, cujos corpos caíram no deserto?',
        'E a quem jurou que não entrariam no seu repouso, senão aos que foram desobedientes?',
        'E vemos que não puderam entrar por causa da sua incredulidade.',
      ],
      [
        'Temamos, pois, que, porventura, deixada a promessa de entrar no seu repouso, pareça que algum de vós fica para trás.',
        'Porque também a nós foram pregadas as boas novas, como a eles, mas a palavra da pregação nada lhes aproveitou, porquanto não estava misturada com a fé naqueles que a ouviram.',
        'Porque nós, os que temos crido, entramos no repouso, tal como disse:Assim jurei na minha ira Que não entrarão no meu repouso; embora as suas obras estivessem acabadas desde a fundação do mundo.',
        'Porque em certo lugar disse assim do dia sétimo: E repousou Deus de todas as suas obras no sétimo dia.',
        'E outra vez neste lugar:Não entrarão no meu repouso.',
        'Visto, pois, que resta que alguns entrem nele, e que aqueles a quem primeiro foram pregadas as boas novas não entraram por causa da desobediência,',
        'Determina outra vez um certo dia, Hoje, dizendo por Davi, muito tempo depois, como está dito: Hoje, se ouvirdes a sua voz, Não endureçais os vossos corações.',
        'Porque, se Josué lhes houvesse dado repouso, não falaria depois disso de outro dia.',
        'Portanto, resta ainda um repouso para o povo de Deus.',
        'Porque aquele que entrou no seu repouso, ele próprio repousou de suas obras, como Deus das suas.',
        'Procuremos, pois, entrar naquele repouso, para que ninguém caia no mesmo exemplo de desobediência.',
        'Porque a palavra de Deus é viva e eficaz, e mais penetrante do que espada alguma de dois gumes, e penetra até à divisão da alma e do espírito, e das juntas e medulas, e é apta para discernir os pensamentos e intenções do coração.',
        'E não há criatura alguma encoberta diante dele; antes todas as coisas estão nuas e patentes aos olhos daquele com quem temos de tratar.',
        'Visto que temos um grande sumo sacerdote, Jesus, Filho de Deus, que penetrou nos céus, retenhamos firmemente a nossa confissão.',
        'Porque não temos um sumo sacerdote que não possa compadecer-se das nossas fraquezas; porém, um que, como nós, em tudo foi tentado, mas sem pecado.',
        'Cheguemos, pois, com confiança ao trono da graça, para que possamos alcançar misericórdia e achar graça, a fim de sermos ajudados em tempo oportuno.',
      ],
      [
        'Porque todo o sumo sacerdote, tomado dentre os homens, é constituído a favor dos homens nas coisas concernentes a Deus, para que ofereça dons e sacrifícios pelos pecados;',
        'E possa compadecer-se ternamente dos ignorantes e errados; pois também ele mesmo está rodeado de fraqueza.',
        'E por esta causa deve ele, tanto pelo povo, como também por si mesmo, fazer oferta pelos pecados.',
        'E ninguém toma para si esta honra, senão o que é chamado por Deus, como Arão.',
        'Assim também Cristo não se glorificou a si mesmo, para se fazer sumo sacerdote, mas aquele que lhe disse: Tu és meu Filho,Hoje te gerei.',
        'Como também diz, noutro lugar: Tu és sacerdote eternamente, Segundo a ordem de Melquisedeque.',
        'O qual, nos dias da sua carne, oferecendo, com grande clamor e lágrimas, orações e súplicas ao que o podia livrar da morte, foi ouvido quanto ao que temia.',
        'Ainda que era Filho, aprendeu a obediência, por aquilo que padeceu.',
        'E, sendo ele consumado, veio a ser a causa da eterna salvação para todos os que lhe obedecem;',
        'Chamado por Deus sumo sacerdote, segundo a ordem de Melquisedeque.',
        'Do qual muito temos que dizer, de difícil interpretação; porquanto vos fizestes negligentes para ouvir.',
        'Porque, devendo já ser mestres pelo tempo, ainda necessitais de que se vos torne a ensinar quais sejam os primeiros rudimentos das palavras de Deus; e vos haveis feito tais que necessitais de leite, e não de sólido mantimento.',
        'Porque qualquer que ainda se alimenta de leite não está experimentado na palavra da justiça, porque é menino.',
        'Mas o mantimento sólido é para os perfeitos, os quais, em razão do costume, têm os sentidos exercitados para discernir tanto o bem como o mal.',
      ],
      [
        'Por isso, deixando os rudimentos da doutrina de Cristo, prossigamos até à perfeição, não lançando de novo o fundamento do arrependimento de obras mortas e de fé em Deus,',
        'E da doutrina dos batismos, e da imposição das mãos, e da ressurreição dos mortos, e do juízo eterno.',
        'E isto faremos, se Deus o permitir.',
        'Porque é impossível que os que já uma vez foram iluminados, e provaram o dom celestial, e se fizeram participantes do Espírito Santo,',
        'E provaram a boa palavra de Deus, e as virtudes do século futuro,',
        'E recaíram, sejam outra vez renovados para arrependimento; pois assim, quanto a eles, de novo crucificam o Filho de Deus, e o expõem ao vitupério.',
        'Porque a terra que embebe a chuva, que muitas vezes cai sobre ela, e produz erva proveitosa para aqueles por quem é lavrada, recebe a bênção de Deus;',
        'Mas a que produz espinhos e abrolhos, é reprovada, e perto está da maldição; o seu fim é ser queimada.',
        'Mas de vós, ó amados, esperamos coisas melhores, e coisas que acompanham a salvação, ainda que assim falamos.',
        'Porque Deus não é injusto para se esquecer da vossa obra, e do trabalho do amor que para com o seu nome mostrastes, enquanto servistes aos santos; e ainda servis.',
        'Mas desejamos que cada um de vós mostre o mesmo cuidado até ao fim, para completa certeza da esperança;',
        'Para que vos não façais negligentes, mas sejais imitadores dos que pela fé e paciência herdam as promessas.',
        'Porque, quando Deus fez a promessa a Abraão, como não tinha outro maior por quem jurasse, jurou por si mesmo,',
        'Dizendo: Certamente, abençoando te abençoarei, e multiplicando te multiplicarei.',
        'E assim, esperando com paciência, alcançou a promessa.',
        'Porque os homens certamente juram por alguém superior a eles, e o juramento para confirmação é, para eles, o fim de toda a contenda.',
        'Por isso, querendo Deus mostrar mais abundantemente a imutabilidade do seu conselho aos herdeiros da promessa, se interpôs com juramento;',
        'Para que por duas coisas imutáveis, nas quais é impossível que Deus minta, tenhamos a firme consolação, nós, os que pomos o nosso refúgio em reter a esperança proposta;',
        'A qual temos como âncora da alma, segura e firme, e que penetra até ao interior do véu,',
        'Onde Jesus, nosso precursor, entrou por nós, feito eternamente sumo sacerdote, segundo a ordem de Melquisedeque.',
      ],
      [
        'Porque este Melquisedeque, que era rei de Salém, sacerdote do Deus Altíssimo, e que saiu ao encontro de Abraão quando ele regressava da matança dos reis, e o abençoou;',
        'A quem também Abraão deu o dízimo de tudo, e primeiramente é, por interpretação, rei de justiça, e depois também rei de Salém, que é rei de paz;',
        'Sem pai, sem mãe, sem genealogia, não tendo princípio de dias nem fim de vida, mas sendo feito semelhante ao Filho de Deus, permanece sacerdote para sempre.',
        'Considerai, pois, quão grande era este, a quem até o patriarca Abraão deu os dízimos dos despojos.',
        'E os que dentre os filhos de Levi recebem o sacerdócio têm ordem, segundo a lei, de tomar o dízimo do povo, isto é, de seus irmãos, ainda que tenham saído dos lombos de Abraão.',
        'Mas aquele, cuja genealogia não é contada entre eles, tomou dízimos de Abraão, e abençoou o que tinha as promessas.',
        'Ora, sem contradição alguma, o menor é abençoado pelo maior.',
        'E aqui certamente tomam dízimos homens que morrem; ali, porém, aquele de quem se testifica que vive.',
        'E, por assim dizer, por meio de Abraão, até Levi, que recebe dízimos, pagou dízimos.',
        'Porque ainda ele estava nos lombos de seu pai quando Melquisedeque lhe saiu ao encontro.',
        'De sorte que, se a perfeição fosse pelo sacerdócio levítico (porque sob ele o povo recebeu a lei), que necessidade havia logo de que outro sacerdote se levantasse, segundo a ordem de Melquisedeque, e não fosse chamado segundo a ordem de Arão?',
        'Porque, mudando-se o sacerdócio, necessariamente se faz também mudança da lei.',
        'Porque aquele de quem estas coisas se dizem pertence a outra tribo, da qual ninguém serviu ao altar,',
        'Visto ser manifesto que nosso Senhor procedeu de Judá, e concernente a essa tribo nunca Moisés falou de sacerdócio.',
        'E muito mais manifesto é ainda, se à semelhança de Melquisedeque se levantar outro sacerdote,',
        'Que não foi feito segundo a lei do mandamento carnal, mas segundo a virtude da vida incorruptível.',
        'Porque ele assim testifica: Tu és sacerdote eternamente, Segundo a ordem de Melquisedeque.',
        'Porque o precedente mandamento é abrogado por causa da sua fraqueza e inutilidade',
        '(Pois a lei nenhuma coisa aperfeiçoou) e desta sorte é introduzida uma melhor esperança, pela qual chegamos a Deus.',
        'E visto como não é sem prestar juramento (porque certamente aqueles, sem juramento, foram feitos sacerdotes,',
        'Mas este com juramento por aquele que lhe disse: Jurou o Senhor, e não se arrependerá; Tu és sacerdote eternamente, Segundo a ordem de Melquisedeque,',
        'De tanto melhor aliança Jesus foi feito fiador.',
        'E, na verdade, aqueles foram feitos sacerdotes em grande número, porque pela morte foram impedidos de permanecer,',
        'Mas este, porque permanece eternamente, tem um sacerdócio perpétuo.',
        'Portanto, pode também salvar perfeitamente os que por ele se chegam a Deus, vivendo sempre para interceder por eles.',
        'Porque nos convinha tal sumo sacerdote, santo, inocente, imaculado, separado dos pecadores, e feito mais sublime do que os céus;',
        'Que não necessitasse, como os sumos sacerdotes, de oferecer cada dia sacrifícios, primeiramente por seus próprios pecados, e depois pelos do povo; porque isto fez ele, uma vez, oferecendo-se a si mesmo.',
        'Porque a lei constitui sumos sacerdotes a homens fracos, mas a palavra do juramento, que veio depois da lei, constitui ao Filho, perfeito para sempre.',
      ],
      [
        'Ora, a suma do que temos dito é que temos um sumo sacerdote tal, que está assentado nos céus à destra do trono da majestade,',
        'Ministro do santuário, e do verdadeiro tabernáculo, o qual o Senhor fundou, e não o homem.',
        'Porque todo o sumo sacerdote é constituído para oferecer dons e sacrifícios; por isso era necessário que este também tivesse alguma coisa que oferecer.',
        'Ora, se ele estivesse na terra, nem tão pouco sacerdote seria, havendo ainda sacerdotes que oferecem dons segundo a lei,',
        'Os quais servem de exemplo e sombra das coisas celestiais, como Moisés divinamente foi avisado, estando já para acabar o tabernáculo; porque foi dito: Olha, faze tudo conforme o modelo que no monte se te mostrou.',
        'Mas agora alcançou ele ministério tanto mais excelente, quanto é mediador de uma melhor aliança que está confirmada em melhores promessas.',
        'Porque, se aquela primeira fora irrepreensível, nunca se teria buscado lugar para a segunda.',
        'Porque, repreendendo-os, lhes diz: Eis que virão dias, diz o Senhor, Em que com a casa de Israel e com a casa de Judá estabelecerei uma nova aliança,',
        'Não segundo a aliança que fiz com seus pais No dia em que os tomei pela mão, para os tirar da terra do Egito;Como não permaneceram naquela minha aliança,Eu para eles não atentei, diz o Senhor.',
        'Porque esta é a aliança que depois daqueles diasFarei com a casa de Israel, diz o Senhor; Porei as minhas leis no seu entendimento, E em seu coração as escreverei; E eu lhes serei por Deus, E eles me serão por povo;',
        'E não ensinará cada um a seu próximo, Nem cada um ao seu irmão, dizendo: Conhece o Senhor; Porque todos me conhecerão, Desde o menor deles até ao maior.',
        'Porque serei misericordioso para com suas iniqüidades, E de seus pecados e de suas prevaricações não me lembrarei mais.',
        'Dizendo Nova aliança, envelheceu a primeira. Ora, o que foi tornado velho, e se envelhece, perto está de acabar.',
      ],
      [
        'Ora, também a primeira tinha ordenanças de culto divino, e um santuário terrestre.',
        'Porque um tabernáculo estava preparado, o primeiro, em que havia o candeeiro, e a mesa, e os pães da proposição; ao que se chama o santuário.',
        'Mas depois do segundo véu estava o tabernáculo que se chama o santo dos santos,',
        'Que tinha o incensário de ouro, e a arca da aliança, coberta de ouro toda em redor; em que estava um vaso de ouro, que continha o maná, e a vara de Arão, que tinha florescido, e as tábuas da aliança;',
        'E sobre a arca os querubins da glória, que faziam sombra no propiciatório; das quais coisas não falaremos agora particularmente.',
        'Ora, estando estas coisas assim preparadas, a todo o tempo entravam os sacerdotes no primeiro tabernáculo, cumprindo os serviços;',
        'Mas, no segundo, só o sumo sacerdote, uma vez no ano, não sem sangue, que oferecia por si mesmo e pelas culpas do povo;',
        'Dando nisto a entender o Espírito Santo que ainda o caminho do santuário não estava descoberto enquanto se conservava em pé o primeiro tabernáculo,',
        'Que é uma alegoria para o tempo presente, em que se oferecem dons e sacrifícios que, quanto à consciência, não podem aperfeiçoar aquele que faz o serviço;',
        'Consistindo somente em comidas, e bebidas, e várias abluções e justificações da carne, impostas até ao tempo da correção.',
        'Mas, vindo Cristo, o sumo sacerdote dos bens futuros, por um maior e mais perfeito tabernáculo, não feito por mãos, isto é, não desta criação,',
        'Nem por sangue de bodes e bezerros, mas por seu próprio sangue, entrou uma vez no santuário, havendo efetuado uma eterna redenção.',
        'Porque, se o sangue dos touros e bodes, e a cinza de uma novilha esparzida sobre os imundos, os santifica, quanto à purificação da carne,',
        'Quanto mais o sangue de Cristo, que pelo Espírito eterno se ofereceu a si mesmo imaculado a Deus, purificará as vossas consciências das obras mortas, para servirdes ao Deus vivo?',
        'E por isso é Mediador de um novo testamento, para que, intervindo a morte para remissão das transgressões que havia debaixo do primeiro testamento, os chamados recebam a promessa da herança eterna.',
        'Porque onde há testamento, é necessário que intervenha a morte do testador.',
        'Porque um testamento tem força onde houve morte; ou terá ele algum valor enquanto o testador vive?',
        'Por isso também o primeiro não foi consagrado sem sangue;',
        'Porque, havendo Moisés anunciado a todo o povo todos os mandamentos segundo a lei, tomou o sangue dos bezerros e dos bodes, com água, lã purpúrea e hissopo, e aspergiu tanto o mesmo livro como todo o povo,',
        'Dizendo: Este é o sangue do testamento que Deus vos tem mandado.',
        'E semelhantemente aspergiu com sangue o tabernáculo e todos os vasos do ministério.',
        'E quase todas as coisas, segundo a lei, se purificam com sangue; e sem derramamento de sangue não há remissão.',
        'De sorte que era bem necessário que as figuras das coisas que estão no céu assim se purificassem; mas as próprias coisas celestiais com sacrifícios melhores do que estes.',
        'Porque Cristo não entrou num santuário feito por mãos, figura do verdadeiro, porém no mesmo céu, para agora comparecer por nós perante a face de Deus;',
        'Nem também para a si mesmo se oferecer muitas vezes, como o sumo sacerdote cada ano entra no santuário com sangue alheio;',
        'De outra maneira, necessário lhe fora padecer muitas vezes desde a fundação do mundo. Mas agora na consumação dos séculos uma vez se manifestou, para aniquilar o pecado pelo sacrifício de si mesmo.',
        'E, como aos homens está ordenado morrerem uma vez, vindo depois disso o juízo,',
        'Assim também Cristo, oferecendo-se uma vez para tirar os pecados de muitos, aparecerá segunda vez, sem pecado, aos que o esperam para salvação.',
      ],
      [
        'Porque tendo a lei a sombra dos bens futuros, e não a imagem exata das coisas, nunca, pelos mesmos sacrifícios que continuamente se oferecem cada ano, pode aperfeiçoar os que a eles se chegam.',
        'Doutra maneira, teriam deixado de se oferecer, porque, purificados uma vez os ministrantes, nunca mais teriam consciência de pecado.',
        'Nesses sacrifícios, porém, cada ano se faz comemoração dos pecados,',
        'Porque é impossível que o sangue dos touros e dos bodes tire os pecados.',
        'Por isso, entrando no mundo, diz: Sacrifício e oferta não quiseste, Mas corpo me preparaste;',
        'Holocaustos e oblações pelo pecado não te agradaram.',
        'Então disse: Eis aqui venho(No princípio do livro está escrito de mim),Para fazer, ó Deus, a tua vontade.',
        'Como acima diz: Sacrifício e oferta, e holocaustos e oblações pelo pecado não quiseste, nem te agradaram (os quais se oferecem segundo a lei).',
        'Então disse: Eis aqui venho, para fazer, ó Deus, a tua vontade. Tira o primeiro, para estabelecer o segundo.',
        'Na qual vontade temos sido santificados pela oblação do corpo de Jesus Cristo, feita uma vez.',
        'E assim todo o sacerdote aparece cada dia, ministrando e oferecendo muitas vezes os mesmos sacrifícios, que nunca podem tirar os pecados;',
        'Mas este, havendo oferecido para sempre um único sacrifício pelos pecados, está assentado à destra de Deus,',
        'Daqui em diante esperando até que os seus inimigos sejam postos por escabelo de seus pés.',
        'Porque com uma só oblação aperfeiçoou para sempre os que são santificados.',
        'E também o Espírito Santo no-lo testifica, porque depois de haver dito:',
        'Esta é a aliança que farei com eles Depois daqueles dias, diz o Senhor:Porei as minhas leis em seus corações,E as escreverei em seus entendimentos; acrescenta:',
        'E jamais me lembrarei de seus pecados e de suas iniqüidades.',
        'Ora, onde há remissão destes, não há mais oblação pelo pecado.',
        'Tendo, pois, irmãos, ousadia para entrar no santuário, pelo sangue de Jesus,',
        'Pelo novo e vivo caminho que ele nos consagrou, pelo véu, isto é, pela sua carne,',
        'E tendo um grande sacerdote sobre a casa de Deus,',
        'Cheguemo-nos com verdadeiro coração, em inteira certeza de fé, tendo os corações purificados da má consciência, e o corpo lavado com água limpa,',
        'Retenhamos firmes a confissão da nossa esperança; porque fiel é o que prometeu.',
        'E consideremo-nos uns aos outros, para nos estimularmos ao amor e às boas obras,',
        'Não deixando a nossa congregação, como é costume de alguns, antes admoestando-nos uns aos outros; e tanto mais, quanto vedes que se vai aproximando aquele dia.',
        'Porque, se pecarmos voluntariamente, depois de termos recebido o conhecimento da verdade, já não resta mais sacrifício pelos pecados,',
        'Mas uma certa expectação horrível de juízo, e ardor de fogo, que há de devorar os adversários.',
        'Quebrantando alguém a lei de Moisés, morre sem misericórdia, só pela palavra de duas ou três testemunhas.',
        'De quanto maior castigo cuidais vós será julgado merecedor aquele que pisar o Filho de Deus, e tiver por profano o sangue da aliança com que foi santificado, e fizer agravo ao Espírito da graça?',
        'Porque bem conhecemos aquele que disse: Minha é a vingança, eu darei a recompensa, diz o Senhor. E outra vez: O Senhor julgará o seu povo.',
        'Horrenda coisa é cair nas mãos do Deus vivo.',
        'Lembrai-vos, porém, dos dias passados, em que, depois de serdes iluminados, suportastes grande combate de aflições.',
        'Em parte fostes feitos espetáculo com vitupérios e tribulações, e em parte fostes participantes com os que assim foram tratados.',
        'Porque também vos compadecestes das minhas prisões, e com alegria permitistes o roubo dos vossos bens, sabendo que em vós mesmos tendes nos céus uma possessão melhor e permanente.',
        'Não rejeiteis, pois, a vossa confiança, que tem grande e avultado galardão.',
        'Porque necessitais de paciência, para que, depois de haverdes feito a vontade de Deus, possais alcançar a promessa.',
        'Porque ainda um pouquinho de tempo, E o que há de vir virá, e não tardará.',
        'Mas o justo viverá pela fé; E, se ele recuar, a minha alma não tem prazer nele.',
        'Nós, porém, não somos daqueles que se retiram para a perdição, mas daqueles que crêem para a conservação da alma.',
      ],
      [
        'Ora, a fé é o firme fundamento das coisas que se esperam, e a prova das coisas que se não vêem.',
        'Porque por ela os antigos alcançaram testemunho.',
        'Pela fé entendemos que os mundos pela palavra de Deus foram criados; de maneira que aquilo que se vê não foi feito do que é aparente.',
        'Pela fé Abel ofereceu a Deus maior sacrifício do que Caim, pelo qual alcançou testemunho de que era justo, dando Deus testemunho dos seus dons, e por ela, depois de morto, ainda fala.',
        'Pela fé Enoque foi trasladado para não ver a morte, e não foi achado, porque Deus o trasladara; visto como antes da sua trasladação alcançou testemunho de que agradara a Deus.',
        'Ora, sem fé é impossível agradar-lhe; porque é necessário que aquele que se aproxima de Deus creia que ele existe, e que é galardoador dos que o buscam.',
        'Pela fé Noé, divinamente avisado das coisas que ainda não se viam, temeu e, para salvação da sua família, preparou a arca, pela qual condenou o mundo, e foi feito herdeiro da justiça que é segundo a fé.',
        'Pela fé Abraão, sendo chamado, obedeceu, indo para um lugar que havia de receber por herança; e saiu, sem saber para onde ia.',
        'Pela fé habitou na terra da promessa, como em terra alheia, morando em cabanas com Isaque e Jacó, herdeiros com ele da mesma promessa.',
        'Porque esperava a cidade que tem fundamentos, da qual o artífice e construtor é Deus.',
        'Pela fé também a mesma Sara recebeu a virtude de conceber, e deu à luz já fora da idade; porquanto teve por fiel aquele que lho tinha prometido.',
        'Por isso também de um, e esse já amortecido, descenderam tantos, em multidão, como as estrelas do céu, e como a areia inumerável que está na praia do mar.',
        'Todos estes morreram na fé, sem terem recebido as promessas; mas vendo-as de longe, e crendo-as e abraçando-as, confessaram que eram estrangeiros e peregrinos na terra.',
        'Porque, os que isto dizem, claramente mostram que buscam uma pátria.',
        'E se, na verdade, se lembrassem daquela de onde haviam saído, teriam oportunidade de tornar.',
        'Mas agora desejam uma melhor, isto é, a celestial. Por isso também Deus não se envergonha deles, de se chamar seu Deus, porque já lhes preparou uma cidade.',
        'Pela fé ofereceu Abraão a Isaque, quando foi provado; sim, aquele que recebera as promessas ofereceu o seu unigênito.',
        'Sendo-lhe dito: Em Isaque será chamada a tua descendência, considerou que Deus era poderoso para até dentre os mortos o ressuscitar;',
        'E daí também em figura ele o recobrou.',
        'Pela fé Isaque abençoou Jacó e Esaú, no tocante às coisas futuras.',
        'Pela fé Jacó, próximo da morte, abençoou cada um dos filhos de José, e adorou encostado à ponta do seu bordão.',
        'Pela fé José, próximo da morte, fez menção da saída dos filhos de Israel, e deu ordem acerca de seus ossos.',
        'Pela fé Moisés, já nascido, foi escondido três meses por seus pais, porque viram que era um menino formoso; e não temeram o mandamento do rei.',
        'Pela fé Moisés, sendo já grande, recusou ser chamado filho da filha de Faraó,',
        'Escolhendo antes ser maltratado com o povo de Deus, do que por um pouco de tempo ter o gozo do pecado;',
        'Tendo por maiores riquezas o vitupério de Cristo do que os tesouros do Egito; porque tinha em vista a recompensa.',
        'Pela fé deixou o Egito, não temendo a ira do rei; porque ficou firme, como vendo o invisível.',
        'Pela fé celebrou a páscoa e a aspersão do sangue, para que o destruidor dos primogênitos lhes não tocasse.',
        'Pela fé passaram o Mar Vermelho, como por terra seca; o que intentando os egípcios, se afogaram.',
        'Pela fé caíram os muros de Jericó, sendo rodeados durante sete dias.',
        'Pela fé Raabe, a meretriz, não pereceu com os incrédulos, acolhendo em paz os espias.',
        'E que mais direi? Faltar-me-ia o tempo contando de Gideão, e de Baraque, e de Sansão, e de Jefté, e de Davi, e de Samuel e dos profetas,',
        'Os quais pela fé venceram reinos, praticaram a justiça, alcançaram promessas, fecharam as bocas dos leões,',
        'Apagaram a força do fogo, escaparam do fio da espada, da fraqueza tiraram forças, na batalha se esforçaram, puseram em fuga os exércitos dos estranhos.',
        'As mulheres receberam pela ressurreição os seus mortos; uns foram torturados, não aceitando o seu livramento, para alcançarem uma melhor ressurreição;',
        'E outros experimentaram escárnios e açoites, e até cadeias e prisões.',
        'Foram apedrejados, serrados, tentados, mortos ao fio da espada; andaram vestidos de peles de ovelhas e de cabras, desamparados, aflitos e maltratados',
        '(Dos quais o mundo não era digno), errantes pelos desertos, e montes, e pelas covas e cavernas da terra.',
        'E todos estes, tendo tido testemunho pela fé, não alcançaram a promessa,',
        'Provendo Deus alguma coisa melhor a nosso respeito, para que eles sem nós não fossem aperfeiçoados.',
      ],
      [
        'Portanto nós também, pois que estamos rodeados de uma tão grande nuvem de testemunhas, deixemos todo o embaraço, e o pecado que tão de perto nos rodeia, e corramos com paciência a carreira que nos está proposta,',
        'Olhando para Jesus, autor e consumador da fé, o qual, pelo gozo que lhe estava proposto, suportou a cruz, desprezando a afronta, e assentou-se à destra do trono de Deus.',
        'Considerai, pois, aquele que suportou tais contradições dos pecadores contra si mesmo, para que não enfraqueçais, desfalecendo em vossos ânimos.',
        'Ainda não resististes até ao sangue, combatendo contra o pecado.',
        'E já vos esquecestes da exortação que argumenta convosco como filhos: Filho meu, não desprezes a correção do Senhor, E não desmaies quando por ele fores repreendido;',
        'Porque o Senhor corrige o que ama,E açoita a qualquer que recebe por filho.',
        'Se suportais a correção, Deus vos trata como filhos; porque, que filho há a quem o pai não corrija?',
        'Mas, se estais sem disciplina, da qual todos são feitos participantes, sois então bastardos, e não filhos.',
        'Além do que, tivemos nossos pais segundo a carne, para nos corrigirem, e nós os reverenciamos; não nos sujeitaremos muito mais ao Pai dos espíritos, para vivermos?',
        'Porque aqueles, na verdade, por um pouco de tempo, nos corrigiam como bem lhes parecia; mas este, para nosso proveito, para sermos participantes da sua santidade.',
        'E, na verdade, toda a correção, ao presente, não parece ser de gozo, senão de tristeza, mas depois produz um fruto pacífico de justiça nos exercitados por ela.',
        'Portanto, tornai a levantar as mãos cansadas, e os joelhos desconjuntados,',
        'E fazei veredas direitas para os vossos pés, para que o que manqueja não se desvie inteiramente, antes seja sarado.',
        'Segui a paz com todos, e a santificação, sem a qual ninguém verá o Senhor;',
        'Tendo cuidado de que ninguém se prive da graça de Deus, e de que nenhuma raiz de amargura, brotando, vos perturbe, e por ela muitos se contaminem.',
        'E ninguém seja devasso, ou profano, como Esaú, que por uma refeição vendeu o seu direito de primogenitura.',
        'Porque bem sabeis que, querendo ele ainda depois herdar a bênção, foi rejeitado, porque não achou lugar de arrependimento, ainda que com lágrimas o buscou.',
        'Porque não chegastes ao monte palpável, aceso em fogo, e à escuridão, e às trevas, e à tempestade,',
        'E ao sonido da trombeta, e à voz das palavras, a qual os que a ouviram pediram que se lhes não falasse mais;',
        'Porque não podiam suportar o que se lhes mandava: Se até um animal tocar o monte será apedrejado ou passado com um dardo.',
        'E tão terrível era a visão, que Moisés disse: Estou todo assombrado, e tremendo.',
        'Mas chegastes ao monte Sião, e à cidade do Deus vivo, à Jerusalém celestial, e aos muitos milhares de anjos;',
        'À universal assembléia e igreja dos primogênitos, que estão inscritos nos céus, e a Deus, o juiz de todos, e aos espíritos dos justos aperfeiçoados;',
        'E a Jesus, o Mediador de uma nova aliança, e ao sangue da aspersão, que fala melhor do que o de Abel.',
        'Vede que não rejeiteis ao que fala; porque, se não escaparam aqueles que rejeitaram o que na terra os advertia, muito menos nós, se nos desviarmos daquele que é dos céus;',
        'A voz do qual moveu então a terra, mas agora anunciou, dizendo: Ainda uma vez comoverei, não só a terra, senão também o céu.',
        'E esta palavra: Ainda uma vez, mostra a mudança das coisas móveis, como coisas feitas, para que as imóveis permaneçam.',
        'Por isso, tendo recebido um reino que não pode ser abalado, retenhamos a graça, pela qual sirvamos a Deus agradavelmente, com reverência e piedade;',
        'Porque o nosso Deus é um fogo consumidor.',
      ],
      [
        'Permaneça o amor fraternal.',
        'Não vos esqueçais da hospitalidade, porque por ela alguns, não o sabendo, hospedaram anjos.',
        'Lembrai-vos dos presos, como se estivésseis presos com eles, e dos maltratados, como sendo-o vós mesmos também no corpo.',
        'Venerado seja entre todos o matrimônio e o leito sem mácula; porém, aos que se dão à prostituição, e aos adúlteros, Deus os julgará.',
        'Sejam vossos costumes sem avareza, contentando-vos com o que tendes; porque ele disse: Não te deixarei, nem te desampararei.',
        'E assim com confiança ousemos dizer: O Senhor é o meu ajudador, e não temerei O que me possa fazer o homem.',
        'Lembrai-vos dos vossos pastores, que vos falaram a palavra de Deus, a fé dos quais imitai, atentando para a sua maneira de viver.',
        'Jesus Cristo é o mesmo, ontem, e hoje, e eternamente.',
        'Não vos deixeis levar em redor por doutrinas várias e estranhas, porque bom é que o coração se fortifique com graça, e não com alimentos que de nada aproveitaram aos que a eles se entregaram.',
        'Temos um altar, de que não têm direito de comer os que servem ao tabernáculo.',
        'Porque os corpos dos animais, cujo sangue é, pelo pecado, trazido pelo sumo sacerdote para o santuário, são queimados fora do arraial.',
        'E por isso também Jesus, para santificar o povo pelo seu próprio sangue, padeceu fora da porta.',
        'Saiamos, pois, a ele fora do arraial, levando o seu vitupério.',
        'Porque não temos aqui cidade permanente, mas buscamos a futura.',
        'Portanto, ofereçamos sempre por ele a Deus sacrifício de louvor, isto é, o fruto dos lábios que confessam o seu nome.',
        'E não vos esqueçais da beneficência e comunicação, porque com tais sacrifícios Deus se agrada.',
        'Obedecei a vossos pastores, e sujeitai-vos a eles; porque velam por vossas almas, como aqueles que hão de dar conta delas; para que o façam com alegria e não gemendo, porque isso não vos seria útil.',
        'Orai por nós, porque confiamos que temos boa consciência, como aqueles que em tudo querem portar-se honestamente.',
        'E rogo-vos com instância que assim o façais, para que eu mais depressa vos seja restituído.',
        'Ora, o Deus de paz, que pelo sangue da aliança eterna tornou a trazer dos mortos a nosso Senhor Jesus Cristo, grande pastor das ovelhas,',
        'Vos aperfeiçoe em toda a boa obra, para fazerdes a sua vontade, operando em vós o que perante ele é agradável por Cristo Jesus, ao qual seja glória para todo o sempre. Amém.',
        'Rogo-vos, porém, irmãos, que suporteis a palavra desta exortação; porque abreviadamente vos escrevi.',
        'Sabei que já está solto o irmão Timóteo, com o qual, se ele vier depressa, vos verei.',
        'Saudai a todos os vossos chefes e a todos os santos. Os da Itália vos saúdam.',
        'A graça seja com todos vós. Amém.',
      ],
    ],
    livro: 'hebreus',
  },
  {
    abbrev: 'tg',
    capitulos: [
      [
        'Tiago, servo de Deus, e do Senhor Jesus Cristo, às doze tribos que andam dispersas, saúde.',
        'Meus irmãos, tende grande gozo quando cairdes em várias tentações;',
        'Sabendo que a prova da vossa fé opera a paciência.',
        'Tenha, porém, a paciência a sua obra perfeita, para que sejais perfeitos e completos, sem faltar em coisa alguma.',
        'E, se algum de vós tem falta de sabedoria, peça-a a Deus, que a todos dá liberalmente, e o não lança em rosto, e ser-lhe-á dada.',
        'Peça-a, porém, com fé, em nada duvidando; porque o que duvida é semelhante à onda do mar, que é levada pelo vento, e lançada de uma para outra parte.',
        'Não pense tal homem que receberá do Senhor alguma coisa.',
        'O homem de coração dobre é inconstante em todos os seus caminhos.',
        'Mas glorie-se o irmão abatido na sua exaltação,',
        'E o rico em seu abatimento; porque ele passará como a flor da erva.',
        'Porque sai o sol com ardor, e a erva seca, e a sua flor cai, e a formosa aparência do seu aspecto perece; assim se murchará também o rico em seus caminhos.',
        'Bem-aventurado o homem que sofre a tentação; porque, quando for provado, receberá a coroa da vida, a qual o Senhor tem prometido aos que o amam.',
        'Ninguém, sendo tentado, diga: De Deus sou tentado; porque Deus não pode ser tentado pelo mal, e a ninguém tenta.',
        'Mas cada um é tentado, quando atraído e engodado pela sua própria concupiscência.',
        'Depois, havendo a concupiscência concebido, dá à luz o pecado; e o pecado, sendo consumado, gera a morte.',
        'Não erreis, meus amados irmãos.',
        'Toda a boa dádiva e todo o dom perfeito vem do alto, descendo do Pai das luzes, em quem não há mudança nem sombra de variação.',
        'Segundo a sua vontade, ele nos gerou pela palavra da verdade, para que fôssemos como primícias das suas criaturas.',
        'Portanto, meus amados irmãos, todo o homem seja pronto para ouvir, tardio para falar, tardio para se irar.',
        'Porque a ira do homem não opera a justiça de Deus.',
        'Por isso, rejeitando toda a imundícia e superfluidade de malícia, recebei com mansidão a palavra em vós enxertada, a qual pode salvar as vossas almas.',
        'E sede cumpridores da palavra, e não somente ouvintes, enganando-vos a vós mesmos.',
        'Porque, se alguém é ouvinte da palavra, e não cumpridor, é semelhante ao homem que contempla ao espelho o seu rosto natural;',
        'Porque se contempla a si mesmo, e vai-se, e logo se esquece de como era.',
        'Aquele, porém, que atenta bem para a lei perfeita da liberdade, e nisso persevera, não sendo ouvinte esquecediço, mas fazedor da obra, este tal será bem-aventurado no seu feito.',
        'Se alguém entre vós cuida ser religioso, e não refreia a sua língua, antes engana o seu coração, a religião desse é vã.',
        'A religião pura e imaculada para com Deus e Pai, é esta: Visitar os órfãos e as viúvas nas suas tribulações, e guardar-se da corrupção do mundo.',
      ],
      [
        'Meus irmãos, não tenhais a fé de nosso Senhor Jesus Cristo, Senhor da glória, em acepção de pessoas.',
        'Porque, se no vosso ajuntamento entrar algum homem com anel de ouro no dedo, com trajes preciosos, e entrar também algum pobre com sórdido traje,',
        'E atentardes para o que traz o traje precioso, e lhe disserdes: Assenta-te tu aqui num lugar de honra, e disserdes ao pobre: Tu, fica aí em pé, ou assenta-te abaixo do meu estrado,',
        'Porventura não fizestes distinção entre vós mesmos, e não vos fizestes juízes de maus pensamentos?',
        'Ouvi, meus amados irmãos: Porventura não escolheu Deus aos pobres deste mundo para serem ricos na fé, e herdeiros do reino que prometeu aos que o amam?',
        'Mas vós desonrastes o pobre. Porventura não vos oprimem os ricos, e não vos arrastam aos tribunais?',
        'Porventura não blasfemam eles o bom nome que sobre vós foi invocado?',
        'Todavia, se cumprirdes, conforme a Escritura, a lei real: Amarás a teu próximo como a ti mesmo, bem fazeis.',
        'Mas, se fazeis acepção de pessoas, cometeis pecado, e sois redargüidos pela lei como transgressores.',
        'Porque qualquer que guardar toda a lei, e tropeçar em um só ponto, tornou-se culpado de todos.',
        'Porque aquele que disse: Não cometerás adultério, também disse: Não matarás. Se tu pois não cometeres adultério, mas matares, estás feito transgressor da lei.',
        'Assim falai, e assim procedei, como devendo ser julgados pela lei da liberdade.',
        'Porque o juízo será sem misericórdia sobre aquele que não fez misericórdia; e a misericórdia triunfa do juízo.',
        'Meus irmãos, que aproveita se alguém disser que tem fé, e não tiver as obras? Porventura a fé pode salvá-lo?',
        'E, se o irmão ou a irmã estiverem nus, e tiverem falta de mantimento quotidiano,',
        'E algum de vós lhes disser: Ide em paz, aquentai-vos, e fartai-vos; e nào lhes derdes as coisas necessárias para o corpo, que proveito virá daí?',
        'Assim também a fé, se não tiver as obras, é morta em si mesma.',
        'Mas dirá alguém: Tu tens a fé, e eu tenho as obras; mostra-me a tua fé sem as tuas obras, e eu te mostrarei a minha fé pelas minhas obras.',
        'Tu crês que há um só Deus; fazes bem. Também os demônios o crêem, e estremecem.',
        'Mas, ó homem vão, queres tu saber que a fé sem as obras é morta?',
        'Porventura o nosso pai Abraão não foi justificado pelas obras, quando ofereceu sobre o altar o seu filho Isaque?',
        'Bem vês que a fé cooperou com as suas obras, e que pelas obras a fé foi aperfeiçoada.',
        'E cumpriu-se a Escritura, que diz: E creu Abraão em Deus, e foi-lhe isso imputado como justiça, e foi chamado o amigo de Deus.',
        'Vedes então que o homem é justificado pelas obras, e não somente pela fé.',
        'E de igual modo Raabe, a meretriz, não foi também justificada pelas obras, quando recolheu os emissários, e os despediu por outro caminho?',
        'Porque, assim como o corpo sem o espírito está morto, assim também a fé sem obras é morta.',
      ],
      [
        'Meus irmãos, muitos de vós não sejam mestres, sabendo que receberemos mais duro juízo.',
        'Porque todos tropeçamos em muitas coisas. Se alguém não tropeça em palavra, o tal é perfeito, e poderoso para também refrear todo o corpo.',
        'Ora, nós pomos freio nas bocas dos cavalos, para que nos obedeçam; e conseguimos dirigir todo o seu corpo.',
        'Vede também as naus que, sendo tão grandes, e levadas de impetuosos ventos, se viram com um bem pequeno leme para onde quer a vontade daquele que as governa.',
        'Assim também a língua é um pequeno membro, e gloria-se de grandes coisas. Vede quão grande bosque um pequeno fogo incendeia.',
        'A língua também é um fogo; como mundo de iniqüidade, a língua está posta entre os nossos membros, e contamina todo o corpo, e inflama o curso da natureza, e é inflamada pelo inferno.',
        'Porque toda a natureza, tanto de bestas feras como de aves, tanto de répteis como de animais do mar, se amansa e foi domada pela natureza humana;',
        'Mas nenhum homem pode domar a língua. É um mal que não se pode refrear; está cheia de peçonha mortal.',
        'Com ela bendizemos a Deus e Pai, e com ela amaldiçoamos os homens, feitos à semelhança de Deus.',
        'De uma mesma boca procede bênção e maldição. Meus irmãos, não convém que isto se faça assim.',
        'Porventura deita alguma fonte de um mesmo manancial água doce e água amargosa?',
        'Meus irmãos, pode também a figueira produzir azeitonas, ou a videira figos? Assim tampouco pode uma fonte dar água salgada e doce.',
        'Quem dentre vós é sábio e entendido? Mostre pelo seu bom trato as suas obras em mansidão de sabedoria.',
        'Mas, se tendes amarga inveja, e sentimento faccioso em vosso coração, não vos glorieis, nem mintais contra a verdade.',
        'Essa não é a sabedoria que vem do alto, mas é terrena, animal e diabólica.',
        'Porque onde há inveja e espírito faccioso aí há perturbação e toda a obra perversa.',
        'Mas a sabedoria que do alto vem é, primeiramente pura, depois pacífica, moderada, tratável, cheia de misericórdia e de bons frutos, sem parcialidade, e sem hipocrisia.',
        'Ora, o fruto da justiça semeia-se na paz, para os que exercitam a paz.',
      ],
      [
        'De onde vêm as guerras e pelejas entre vós? Porventura não vêm disto, a saber, dos vossos deleites, que nos vossos membros guerreiam?',
        'Cobiçais, e nada tendes; matais, e sois invejosos, e nada podeis alcançar; combateis e guerreais, e nada tendes, porque não pedis.',
        'Pedis, e não recebeis, porque pedis mal, para o gastardes em vossos deleites.',
        'Adúlteros e adúlteras, não sabeis vós que a amizade do mundo é inimizade contra Deus? Portanto, qualquer que quiser ser amigo do mundo constitui-se inimigo de Deus.',
        'Ou cuidais vós que em vão diz a Escritura: O Espírito que em nós habita tem ciúmes?',
        'Antes, ele dá maior graça. Portanto diz: Deus resiste aos soberbos, mas dá graça aos humildes.',
        'Sujeitai-vos, pois, a Deus, resisti ao diabo, e ele fugirá de vós.',
        'Chegai-vos a Deus, e ele se chegará a vós. Alimpai as mãos, pecadores; e, vós de duplo ânimo, purificai os corações.',
        'Senti as vossas misérias, e lamentai e chorai; converta-se o vosso riso em pranto, e o vosso gozo em tristeza.',
        'Humilhai-vos perante o Senhor, e ele vos exaltará.',
        'Irmãos, não faleis mal uns dos outros. Quem fala mal de um irmão, e julga a seu irmão, fala mal da lei, e julga a lei; e, se tu julgas a lei, já não és observador da lei, mas juiz.',
        'Há só um legislador que pode salvar e destruir. Tu, porém, quem és, que julgas a outrem?',
        'Eia agora vós, que dizeis: Hoje, ou amanhã, iremos a tal cidade, e lá passaremos um ano, e contrataremos, e ganharemos;',
        'Digo-vos que não sabeis o que acontecerá amanhã. Porque, que é a vossa vida? É um vapor que aparece por um pouco, e depois se desvanece.',
        'Em lugar do que devíeis dizer: Se o Senhor quiser, e se vivermos, faremos isto ou aquilo.',
        'Mas agora vos gloriais em vossas presunções; toda a glória tal como esta é maligna.',
        'Aquele, pois, que sabe fazer o bem e não o faz, comete pecado.',
      ],
      [
        'Eia, pois, agora vós, ricos, chorai e pranteai, por vossas misérias, que sobre vós hão de vir.',
        'As vossas riquezas estão apodrecidas, e as vossas vestes estão comidas de traça.',
        'O vosso ouro e a vossa prata se enferrujaram; e a sua ferrugem dará testemunho contra vós, e comerá como fogo a vossa carne. Entesourastes para os últimos dias.',
        'Eis que o jornal dos trabalhadores que ceifaram as vossas terras, e que por vós foi diminuído, clama; e os clamores dos que ceifaram entraram nos ouvidos do Senhor dos exércitos.',
        'Deliciosamente vivestes sobre a terra, e vos deleitastes; cevastes os vossos corações, como num dia de matança.',
        'Condenastes e matastes o justo; ele não vos resistiu.',
        'Sede pois, irmãos, pacientes até à vinda do Senhor. Eis que o lavrador espera o precioso fruto da terra, aguardando-o com paciência, até que receba a chuva temporã e serôdia.',
        'Sede vós também pacientes, fortalecei os vossos corações; porque já a vinda do Senhor está próxima.',
        'Irmãos, não vos queixeis uns contra os outros, para que não sejais condenados. Eis que o juiz está à porta.',
        'Meus irmãos, tomai por exemplo de aflição e paciência os profetas que falaram em nome do Senhor.',
        'Eis que temos por bem-aventurados os que sofreram. Ouvistes qual foi a paciência de Jó, e vistes o fim que o Senhor lhe deu; porque o Senhor é muito misericordioso e piedoso.',
        'Mas, sobretudo, meus irmãos, não jureis, nem pelo céu, nem pela terra, nem façais qualquer outro juramento; mas que a vossa palavra seja sim, sim, e não, não; para que não caiais em condenação.',
        'Está alguém entre vós aflito? Ore. Está alguém contente? Cante louvores.',
        'Está alguém entre vós doente? Chame os presbíteros da igreja, e orem sobre ele, ungindo-o com azeite em nome do Senhor;',
        'E a oração da fé salvará o doente, e o Senhor o levantará; e, se houver cometido pecados, ser-lhe-ão perdoados.',
        'Confessai as vossas culpas uns aos outros, e orai uns pelos outros, para que sareis. A oração feita por um justo pode muito em seus efeitos.',
        'Elias era homem sujeito às mesmas paixões que nós e, orando, pediu que não chovesse e, por três anos e seis meses, não choveu sobre a terra.',
        'E orou outra vez, e o céu deu chuva, e a terra produziu o seu fruto.',
        'Irmãos, se algum dentre vós se tem desviado da verdade, e alguém o converter,',
        'Saiba que aquele que fizer converter do erro do seu caminho um pecador, salvará da morte uma alma, e cobrirá uma multidão de pecados.',
      ],
    ],
    livro: 'tiago',
  },
  {
    abbrev: '1pe',
    capitulos: [
      [
        'Pedro, apóstolo de Jesus Cristo, aos estrangeiros dispersos no Ponto, Galácia, Capadócia, Ásia e Bitínia;',
        'Eleitos segundo a presciência de Deus Pai, em santificação do Espírito, para a obediência e aspersão do sangue de Jesus Cristo: Graça e paz vos sejam multiplicadas.',
        'Bendito seja o Deus e Pai de nosso Senhor Jesus Cristo que, segundo a sua grande misericórdia, nos gerou de novo para uma viva esperança, pela ressurreição de Jesus Cristo dentre os mortos,',
        'Para uma herança incorruptível, incontaminável, e que não se pode murchar, guardada nos céus para vós,',
        'Que mediante a fé estais guardados na virtude de Deus para a salvação, já prestes para se revelar no último tempo,',
        'Em que vós grandemente vos alegrais, ainda que agora importa, sendo necessário, que estejais por um pouco contristados com várias tentações,',
        'Para que a prova da vossa fé, muito mais preciosa do que o ouro que perece e é provado pelo fogo, se ache em louvor, e honra, e glória, na revelação de Jesus Cristo;',
        'Ao qual, não o havendo visto, amais; no qual, não o vendo agora, mas crendo, vos alegrais com gozo inefável e glorioso;',
        'Alcançando o fim da vossa fé, a salvação das vossas almas.',
        'Da qual salvação inquiriram e trataram diligentemente os profetas que profetizaram da graça que vos foi dada,',
        'Indagando que tempo ou que ocasião de tempo o Espírito de Cristo, que estava neles, indicava, anteriormente testificando os sofrimentos que a Cristo haviam de vir, e a glória que se lhes havia de seguir.',
        'Aos quais foi revelado que, não para si mesmos, mas para nós, eles ministravam estas coisas que agora vos foram anunciadas por aqueles que, pelo Espírito Santo enviado do céu, vos pregaram o evangelho; para as quais coisas os anjos desejam bem atentar.',
        'Portanto, cingindo os lombos do vosso entendimento, sede sóbrios, e esperai inteiramente na graça que se vos ofereceu na revelação de Jesus Cristo;',
        'Como filhos obedientes, não vos conformando com as concupiscências que antes havia em vossa ignorância;',
        'Mas, como é santo aquele que vos chamou, sede vós também santos em toda a vossa maneira de viver;',
        'Porquanto está escrito: Sede santos, porque eu sou santo.',
        'E, se invocais por Pai aquele que, sem acepção de pessoas, julga segundo a obra de cada um, andai em temor, durante o tempo da vossa peregrinação,',
        'Sabendo que não foi com coisas corruptíveis, como prata ou ouro, que fostes resgatados da vossa vã maneira de viver que por tradição recebestes dos vossos pais,',
        'Mas com o precioso sangue de Cristo, como de um cordeiro imaculado e incontaminado,',
        'O qual, na verdade, em outro tempo foi conhecido, ainda antes da fundação do mundo, mas manifestado nestes últimos tempos por amor de vós;',
        'E por ele credes em Deus, que o ressuscitou dentre os mortos, e lhe deu glória, para que a vossa fé e esperança estivessem em Deus;',
        'Purificando as vossas almas pelo Espírito na obediência à verdade, para o amor fraternal, não fingido; amai-vos ardentemente uns aos outros com um coração puro;',
        'Sendo de novo gerados, não de semente corruptível, mas da incorruptível, pela palavra de Deus, viva, e que permanece para sempre.',
        'Porque toda a carne é como a erva,e toda a glória do homem como a flor da erva.Secou-se a erva, e caiu a sua flor;',
        'Mas a palavra do Senhor permanece para sempre.E esta é a palavra que entre vós foi evangelizada.',
      ],
      [
        'Deixando, pois, toda a malícia, e todo o engano, e fingimentos, e invejas, e todas as murmurações,',
        'Desejai afetuosamente, como meninos novamente nascidos, o leite racional, não falsificado, para que por ele vades crescendo;',
        'Se é que já provastes que o Senhor é benigno;',
        'E, chegando-vos para ele, pedra viva, reprovada, na verdade, pelos homens, mas para com Deus eleita e preciosa,',
        'Vòs também, como pedras vivas, sois edificados casa espiritual e sacerdòcio santo, para oferecer sacrifícios espirituais agradáveis a Deus por Jesus Cristo.',
        'Por isso também na Escritura se contém:Eis que ponho em Sião a pedra principal da esquina, eleita e preciosa;e quem nela crer não será confundido.',
        'E assim para vòs, os que credes, é preciosa, mas, para os rebeldes,a pedra que os edificadores reprovaram,essa foi a principal da esquina,',
        'E uma pedra de tropeço e rocha de escândalo,para aqueles que tropeçam na palavra, sendo desobedientes; para o que também foram destinados.',
        'Mas vòs sois a geração eleita, o sacerdòcio real, a nação santa, o povo adquirido, para que anuncieis as virtudes daquele que vos chamou das trevas para a sua maravilhosa luz;',
        'Vós, que em outro tempo não éreis povo, mas agora sois povo de Deus; que não tínheis alcançado misericórdia, mas agora alcançastes misericórdia.',
        'Amados, peço-vos, como a peregrinos e forasteiros, que vos abstenhais das concupiscências carnais, que combatem contra a alma;',
        'Tendo o vosso viver honesto entre os gentios; para que, naquilo em que falam mal de vós, como de malfeitores, glorifiquem a Deus no dia da visitação, pelas boas obras que em vós observem.',
        'Sujeitai-vos, pois, a toda a ordenação humana por amor do Senhor; quer ao rei, como superior;',
        'Quer aos governadores, como por ele enviados para castigo dos malfeitores, e para louvor dos que fazem o bem.',
        'Porque assim é a vontade de Deus, que, fazendo bem, tapeis a boca à ignorância dos homens insensatos;',
        'Como livres, e não tendo a liberdade por cobertura da malícia, mas como servos de Deus.',
        'Honrai a todos. Amai a fraternidade. Temei a Deus. Honrai ao rei.',
        'Vós, servos, sujeitai-vos com todo o temor aos senhores, não somente aos bons e humanos, mas também aos maus.',
        'Porque é coisa agradável, que alguém, por causa da consciência para com Deus, sofra agravos, padecendo injustamente.',
        'Porque, que glória será essa, se, pecando, sois esbofeteados e sofreis? Mas se, fazendo o bem, sois afligidos e o sofreis, isso é agradável a Deus.',
        'Porque para isto sois chamados; pois também Cristo padeceu por nós, deixando-nos o exemplo, para que sigais as suas pisadas.',
        'O qual não cometeu pecado, nem na sua boca se achou engano.',
        'O qual, quando o injuriavam, não injuriava, e quando padecia não ameaçava, mas entregava-se àquele que julga justamente;',
        'Levando ele mesmo em seu corpo os nossos pecados sobre o madeiro, para que, mortos para os pecados, pudéssemos viver para a justiça; e pelas suas feridas fostes sarados.',
        'Porque éreis como ovelhas desgarradas; mas agora tendes voltado ao Pastor e Bispo das vossas almas.',
      ],
      [
        'Semelhantemente, vós, mulheres, sede sujeitas aos vossos próprios maridos; para que também, se alguns não obedecem à palavra, pelo porte de suas mulheres sejam ganhos sem palavra;',
        'Considerando a vossa vida casta, em temor.',
        'O enfeite delas não seja o exterior, no frisado dos cabelos, no uso de jóias de ouro, na compostura dos vestidos;',
        'Mas o homem encoberto no coração; no incorruptível traje de um espírito manso e quieto, que é precioso diante de Deus.',
        'Porque assim se adornavam também antigamente as santas mulheres que esperavam em Deus, e estavam sujeitas aos seus próprios maridos;',
        'Como Sara obedecia a Abraão, chamando-lhe senhor; da qual vós sois filhas, fazendo o bem, e não temendo nenhum espanto.',
        'Igualmente vós, maridos, coabitai com elas com entendimento, dando honra à mulher, como vaso mais fraco; como sendo vós os seus co-herdeiros da graça da vida; para que não sejam impedidas as vossas orações.',
        'E, finalmente, sede todos de um mesmo sentimento, compassivos, amando os irmãos, entranhavelmente misericordiosos e afáveis.',
        'Não tornando mal por mal, ou injúria por injúria; antes, pelo contrário, bendizendo; sabendo que para isto fostes chamados, para que por herança alcanceis a bênção.',
        'Porque Quem quer amar a vida, E ver os dias bons, Refreie a sua língua do mal, E os seus lábios não falem engano.',
        'Aparte-se do mal, e faça o bem; Busque a paz, e siga-a.',
        'Porque os olhos do Senhor estão sobre os justos, E os seus ouvidos atentos às suas orações; Mas o rosto do Senhor é contra os que fazem o mal.',
        'E qual é aquele que vos fará mal, se fordes seguidores do bem?',
        'Mas também, se padecerdes por amor da justiça, sois bemaventurados. E não temais com medo deles, nem vos turbeis;',
        'Antes, santificai ao Senhor Deus em vossos corações; e estai sempre preparados para responder com mansidão e temor a qualquer que vos pedir a razão da esperança que há em vós,',
        'Tendo uma boa consciência, para que, naquilo em que falam mal de vós, como de malfeitores, fiquem confundidos os que blasfemam do vosso bom porte em Cristo.',
        'Porque melhor é que padeçais fazendo bem (se a vontade de Deus assim o quer), do que fazendo mal.',
        'Porque também Cristo padeceu uma vez pelos pecados, o justo pelos injustos, para levar-nos a Deus; mortificado, na verdade, na carne, mas vivificado pelo Espírito;',
        'No qual também foi, e pregou aos espíritos em prisão;',
        'Os quais noutro tempo foram rebeldes, quando a longanimidade de Deus esperava nos dias de Noé, enquanto se preparava a arca; na qual poucas (isto é, oito) almas se salvaram pela água;',
        'Que também, como uma verdadeira figura, agora vos salva, o batismo, não do despojamento da imundícia da carne, mas da indagação de uma boa consciência para com Deus, pela ressurreição de Jesus Cristo;',
        'O qual está à destra de Deus, tendo subido ao céu, havendo-se-lhe sujeitado os anjos, e as autoridades, e as potências.',
      ],
      [
        'Ora, pois, já que Cristo padeceu por nós na carne, armai-vos também vós com este mesmo pensamento, que aquele que padeceu na carne já cessou do pecado;',
        'Para que, no tempo que vos resta na carne, não vivais mais segundo as concupiscências dos homens, mas segundo a vontade de Deus.',
        'Porque é bastante que no tempo passado da vida fizéssemos a vontade dos gentios, andando em dissoluções, concupiscências, borrachices, glutonarias, bebedices e abomináveis idolatrias;',
        'E acham estranho não correrdes com eles no mesmo desenfreamento de dissolução, blasfemando de vós.',
        'Os quais hão de dar conta ao que está preparado para julgar os vivos e os mortos.',
        'Porque por isto foi pregado o evangelho também aos mortos, para que, na verdade, fossem julgados segundo os homens na carne, mas vivessem segundo Deus em espírito;',
        'E já está próximo o fim de todas as coisas; portanto sede sóbrios e vigiai em oração.',
        'Mas, sobretudo, tende ardente amor uns para com os outros; porque o amor cobrirá a multidão de pecados.',
        'Sendo hospitaleiros uns para com os outros, sem murmurações,',
        'Cada um administre aos outros o dom como o recebeu, como bons despenseiros da multiforme graça de Deus.',
        'Se alguém falar, fale segundo as palavras de Deus; se alguém administrar, administre segundo o poder que Deus dá; para que em tudo Deus seja glorificado por Jesus Cristo, a quem pertence a glória e poder para todo o sempre. Amém.',
        'Amados, não estranheis a ardente prova que vem sobre vós para vos tentar, como se coisa estranha vos acontecesse;',
        'Mas alegrai-vos no fato de serdes participantes das aflições de Cristo, para que também na revelação da sua glória vos regozijeis e alegreis.',
        'Se pelo nome de Cristo sois vituperados, bem-aventurados sois, porque sobre vós repousa o Espírito da glória e de Deus; quanto a eles, é ele, sim, blasfemado, mas quanto a vós, é glorificado.',
        'Que nenhum de vós padeça como homicida, ou ladrão, ou malfeitor, ou como o que se entremete em negócios alheios;',
        'Mas, se padece como cristão, não se envergonhe, antes glorifique a Deus nesta parte.',
        'Porque já é tempo que comece o julgamento pela casa de Deus; e, se primeiro começa por nós, qual será o fim daqueles que são desobedientes ao evangelho de Deus?',
        'E, se o justo apenas se salva, onde aparecerá o ímpio e o pecador?',
        'Portanto também os que padecem segundo a vontade de Deus encomendem-lhe as suas almas, como ao fiel Criador, fazendo o bem.',
      ],
      [
        'Aos presbíteros, que estão entre vós, admoesto eu, que sou também presbítero com eles, e testemunha das aflições de Cristo, e participante da glória que se há de revelar:',
        'Apascentai o rebanho de Deus, que está entre vós, tendo cuidado dele, não por força, mas voluntariamente; nem por torpe ganância, mas de ânimo pronto;',
        'Nem como tendo domínio sobre a herança de Deus, mas servindo de exemplo ao rebanho.',
        'E, quando aparecer o Sumo Pastor, alcançareis a incorruptível coroa da glória.',
        'Semelhantemente vós jovens, sede sujeitos aos anciãos; e sede todos sujeitos uns aos outros, e revesti-vos de humildade, porque Deus resiste aos soberbos, mas dá graça aos humildes.',
        'Humilhai-vos, pois, debaixo da potente mão de Deus, para que a seu tempo vos exalte;',
        'Lançando sobre ele toda a vossa ansiedade, porque ele tem cuidado de vós.',
        'Sede sóbrios; vigiai; porque o diabo, vosso adversário, anda em derredor, bramando como leão, buscando a quem possa tragar;',
        'Ao qual resisti firmes na fé, sabendo que as mesmas aflições se cumprem entre os vossos irmãos no mundo.',
        'E o Deus de toda a graça, que em Cristo Jesus nos chamou à sua eterna glória, depois de havemos padecido um pouco, ele mesmo vos aperfeiçoe, confirme, fortifique e estabeleça.',
        'A ele seja a glória e o poderio para todo o sempre. Amém.',
        'Por Silvano, vosso fiel irmão, como cuido, escrevi brevemente, exortando e testificando que esta é a verdadeira graça de Deus, na qual estais firmes.',
        'A vossa co-eleita em babilônia vos saúda, e meu filho Marcos.',
        'Saudai-vos uns aos outros com ósculo de amor. Paz seja com todos vós que estais em Cristo Jesus. Amém.',
      ],
    ],
    livro: '1pedro',
  },
  {
    abbrev: '2pe',
    capitulos: [
      [
        'Simão Pedro, servo e apóstolo de Jesus Cristo, aos que conosco alcançaram fé igualmente preciosa pela justiça do nosso Deus e Salvador Jesus Cristo:',
        'Graça e paz vos sejam multiplicadas, pelo conhecimento de Deus, e de Jesus nosso Senhor;',
        'Visto como o seu divino poder nos deu tudo o que diz respeito à vida e piedade, pelo conhecimento daquele que nos chamou pela sua glória e virtude;',
        'Pelas quais ele nos tem dado grandíssimas e preciosas promessas, para que por elas fiqueis participantes da natureza divina, havendo escapado da corrupção, que pela concupiscência há no mundo.',
        'E vós também, pondo nisto mesmo toda a diligência, acrescentai à vossa fé a virtude, e à virtude a ciência,',
        'E à ciência a temperança, e à temperança a paciência, e à paciência a piedade,',
        'E à piedade o amor fraternal, e ao amor fraternal a caridade.',
        'Porque, se em vós houver e abundarem estas coisas, não vos deixarão ociosos nem estéreis no conhecimento de nosso Senhor Jesus Cristo.',
        'Pois aquele em quem não há estas coisas é cego, nada vendo ao longe, havendo-se esquecido da purificação dos seus antigos pecados.',
        'Portanto, irmãos, procurai fazer cada vez mais firme a vossa vocação e eleição; porque, fazendo isto, nunca jamais tropeçareis.',
        'Porque assim vos será amplamente concedida a entrada no reino eterno de nosso Senhor e Salvador Jesus Cristo.',
        'Por isso não deixarei de exortar-vos sempre acerca destas coisas, ainda que bem as saibais, e estejais confirmados na presente verdade.',
        'E tenho por justo, enquanto estiver neste tabernáculo, despertar-vos com admoestações,',
        'Sabendo que brevemente hei de deixar este meu tabernáculo, como também nosso Senhor Jesus Cristo já mo tem revelado.',
        'Mas também eu procurarei em toda a ocasião que depois da minha morte tenhais lembrança destas coisas.',
        'Porque não vos fizemos saber a virtude e a vinda de nosso Senhor Jesus Cristo, seguindo fábulas artificialmente compostas; mas nós mesmos vimos a sua majestade.',
        'Porquanto ele recebeu de Deus Pai honra e glória, quando da magnífica glória lhe foi dirigida a seguinte voz: Este é o meu Filho amado, em quem me tenho comprazido.',
        'E ouvimos esta voz dirigida do céu, estando nós com ele no monte santo;',
        'E temos, mui firme, a palavra dos profetas, à qual bem fazeis em estar atentos, como a uma luz que alumia em lugar escuro, até que o dia amanheça, e a estrela da alva apareça em vossos corações.',
        'Sabendo primeiramente isto: que nenhuma profecia da Escritura é de particular interpretação.',
        'Porque a profecia nunca foi produzida por vontade de homem algum, mas os homens santos de Deus falaram inspirados pelo Espírito Santo.',
      ],
      [
        'E também houve entre o povo falsos profetas, como entre vós haverá também falsos doutores, que introduzirão encobertamente heresias de perdição, e negarão o Senhor que os resgatou, trazendo sobre si mesmos repentina perdição.',
        'E muitos seguirão as suas dissoluções, pelos quais será blasfemado o caminho da verdade.',
        'E por avareza farão de vós negócio com palavras fingidas; sobre os quais já de largo tempo não será tardia a sentença, e a sua perdição não dormita.',
        'Porque, se Deus não perdoou aos anjos que pecaram, mas, havendo-os lançado no inferno, os entregou às cadeias da escuridão, ficando reservados para o juízo;',
        'E não perdoou ao mundo antigo, mas guardou a Noé, a oitava pessoa, o pregoeiro da justiça, ao trazer o dilúvio sobre o mundo dos ímpios;',
        'E condenou à destruição as cidades de Sodoma e Gomorra, reduzindo-as a cinza, e pondo-as para exemplo aos que vivessem impiamente;',
        'E livrou o justo Ló, enfadado da vida dissoluta dos homens abomináveis',
        '(Porque este justo, habitando entre eles, afligia todos os dias a sua alma justa, por isso via e ouvia sobre as suas obras injustas);',
        'Assim, sabe o Senhor livrar da tentação os piedosos, e reservar os injustos para o dia do juízo, para serem castigados;',
        'Mas principalmente aqueles que segundo a carne andam em concupiscências de imundícia, e desprezam as autoridades; atrevidos, obstinados, não receando blasfemar das dignidades;',
        'Enquanto os anjos, sendo maiores em força e poder, não pronunciam contra eles juízo blasfemo diante do Senhor.',
        'Mas estes, como animais irracionais, que seguem a natureza, feitos para serem presos e mortos, blasfemando do que não entendem, perecerão na sua corrupção,',
        'Recebendo o galardão da injustiça; pois que tais homens têm prazer nos deleites quotidianos; nódoas são eles e máculas, deleitando-se em seus enganos, quando se banqueteiam convosco;',
        'Tendo os olhos cheios de adultério, e não cessando de pecar, engodando as almas inconstantes, tendo o coração exercitado na avareza, filhos de maldição;',
        'Os quais, deixando o caminho direito, erraram seguindo o caminho de Balaão, filho de Beor, que amou o prêmio da injustiça;',
        'Mas teve a repreensão da sua transgressão; o mudo jumento, falando com voz humana, impediu a loucura do profeta.',
        'Estes são fontes sem água, nuvens levadas pela força do vento, para os quais a escuridão das trevas eternamente se reserva.',
        'Porque, falando coisas mui arrogantes de vaidades, engodam com as concupiscências da carne, e com dissoluções, aqueles que se estavam afastando dos que andam em erro,',
        'Prometendo-lhes liberdade, sendo eles mesmos servos da corrupção. Porque de quem alguém é vencido, do tal faz-se também servo.',
        'Porquanto se, depois de terem escapado das corrupções do mundo, pelo conhecimento do Senhor e Salvador Jesus Cristo, forem outra vez envolvidos nelas e vencidos, tornou-se-lhes o último estado pior do que o primeiro.',
        'Porque melhor lhes fora não conhecerem o caminho da justiça, do que, conhecendo-o, desviarem-se do santo mandamento que lhes fora dado;',
        'Deste modo sobreveio-lhes o que por um verdadeiro provérbio se diz: O cão voltou ao seu próprio vômito, e a porca lavada ao espojadouro de lama.',
      ],
      [
        'Amados, escrevo-vos agora esta segunda carta, em ambas as quais desperto com exortação o vosso ânimo sincero;',
        'Para que vos lembreis das palavras que primeiramente foram ditas pelos santos profetas, e do nosso mandamento, como apòstolos do Senhor e Salvador.',
        'Sabendo primeiro isto, que nos últimos dias virão escarnecedores, andando segundo as suas próprias concupiscências,',
        'E dizendo: Onde está a promessa da sua vinda? porque desde que os pais dormiram, todas as coisas permanecem como desde o princípio da criação.',
        'Eles voluntariamente ignoram isto, que pela palavra de Deus já desde a antiguidade existiram os céus, e a terra, que foi tirada da água e no meio da água subsiste.',
        'Pelas quais coisas pereceu o mundo de então, coberto com as águas do dilúvio,',
        'Mas os céus e a terra que agora existem pela mesma palavra se reservam como tesouro, e se guardam para o fogo, até o dia do juízo, e da perdição dos homens ímpios.',
        'Mas, amados, não ignoreis uma coisa, que um dia para o Senhor é como mil anos, e mil anos como um dia.',
        'O Senhor não retarda a sua promessa, ainda que alguns a têm por tardia; mas é longânimo para conosco, não querendo que alguns se percam, senão que todos venham a arrepender-se.',
        'Mas o dia do Senhor virá como o ladrão de noite; no qual os céus passarão com grande estrondo, e os elementos, ardendo, se desfarão, e a terra, e as obras que nela há, se queimarão.',
        'Havendo, pois, de perecer todas estas coisas, que pessoas vos convém ser em santo trato, e piedade,',
        'Aguardando, e apressando-vos para a vinda do dia de Deus, em que os céus, em fogo se desfarão, e os elementos, ardendo, se fundirão?',
        'Mas nós, segundo a sua promessa, aguardamos novos céus e nova terra, em que habita a justiça.',
        'Por isso, amados, aguardando estas coisas, procurai que dele sejais achados imaculados e irrepreensíveis em paz.',
        'E tende por salvação a longanimidade de nosso Senhor; como também o nosso amado irmão Paulo vos escreveu, segundo a sabedoria que lhe foi dada;',
        'Falando disto, como em todas as suas epístolas, entre as quais há pontos difíceis de entender, que os indoutos e inconstantes torcem, e igualmente as outras Escrituras, para sua própria perdição.',
        'Vós, portanto, amados, sabendo isto de antemão, guardai-vos de que, pelo engano dos homens abomináveis, sejais juntamente arrebatados, e descaiais da vossa firmeza;',
        'Antes crescei na graça e conhecimento de nosso Senhor e Salvador, Jesus Cristo. A ele seja dada a glória, assim agora, como no dia da eternidade. Amém.',
      ],
    ],
    livro: '2pedro',
  },
  {
    abbrev: '1jo',
    capitulos: [
      [
        'O que era desde o princípio, o que ouvimos, o que vimos com os nossos olhos, o que temos contemplado, e as nossas mãos tocaram da Palavra da vida',
        '(Porque a vida foi manifestada, e nós a vimos, e testificamos dela, e vos anunciamos a vida eterna, que estava com o Pai, e nos foi manifestada);',
        'O que vimos e ouvimos, isso vos anunciamos, para que também tenhais comunhão conosco; e a nossa comunhão é com o Pai, e com seu Filho Jesus Cristo.',
        'Estas coisas vos escrevemos, para que o vosso gozo se cumpra.',
        'E esta é a mensagem que dele ouvimos, e vos anunciamos: que Deus é luz, e não há nele trevas nenhumas.',
        'Se dissermos que temos comunhão com ele, e andarmos em trevas, mentimos, e não praticamos a verdade.',
        'Mas, se andarmos na luz, como ele na luz está, temos comunhão uns com os outros, e o sangue de Jesus Cristo, seu Filho, nos purifica de todo o pecado.',
        'Se dissermos que não temos pecado, enganamo-nos a nós mesmos, e não há verdade em nós.',
        'Se confessarmos os nossos pecados, ele é fiel e justo para nos perdoar os pecados, e nos purificar de toda a injustiça.',
        'Se dissermos que não pecamos, fazemo-lo mentiroso, e a sua palavra não está em nós.',
      ],
      [
        'Meus filhinhos, estas coisas vos escrevo, para que não pequeis; e, se alguém pecar, temos um Advogado para com o Pai, Jesus Cristo, o justo.',
        'E ele é a propiciação pelos nossos pecados, e não somente pelos nossos, mas também pelos de todo o mundo.',
        'E nisto sabemos que o conhecemos: se guardarmos os seus mandamentos.',
        'Aquele que diz: Eu conheço-o, e não guarda os seus mandamentos, é mentiroso, e nele não está a verdade.',
        'Mas qualquer que guarda a sua palavra, o amor de Deus está nele verdadeiramente aperfeiçoado; nisto conhecemos que estamos nele.',
        'Aquele que diz que está nele, também deve andar como ele andou.',
        'Irmãos, não vos escrevo mandamento novo, mas o mandamento antigo, que desde o princípio tivestes. Este mandamento antigo é a palavra que desde o princípio ouvistes.',
        'Outra vez vos escrevo um mandamento novo, que é verdadeiro nele e em vós; porque vão passando as trevas, e já a verdadeira luz ilumina.',
        'Aquele que diz que está na luz, e odeia a seu irmão, até agora está em trevas.',
        'Aquele que ama a seu irmão está na luz, e nele não há escândalo.',
        'Mas aquele que odeia a seu irmão está em trevas, e anda em trevas, e não sabe para onde deva ir; porque as trevas lhe cegaram os olhos.',
        'Filhinhos, escrevo-vos, porque pelo seu nome vos são perdoados os pecados.',
        'Pais, escrevo-vos, porque conhecestes aquele que é desde o princípio. Jovens, escrevo-vos, porque vencestes o maligno. Eu vos escrevo, filhos, porque conhecestes o Pai.',
        'Eu vos escrevi, pais, porque já conhecestes aquele que é desde o princípio. Eu vos escrevi, jovens, porque sois fortes, e a palavra de Deus está em vós, e já vencestes o maligno.',
        'Não ameis o mundo, nem o que no mundo há. Se alguém ama o mundo, o amor do Pai não está nele.',
        'Porque tudo o que há no mundo, a concupiscência da carne, a concupiscência dos olhos e a soberba da vida, não é do Pai, mas do mundo.',
        'E o mundo passa, e a sua concupiscência; mas aquele que faz a vontade de Deus permanece para sempre.',
        'Filhinhos, é já a última hora; e, como ouvistes que vem o anticristo, também agora muitos se têm feito anticristos, por onde conhecemos que é já a última hora.',
        'Saíram de nós, mas não eram de nós; porque, se fossem de nós, ficariam conosco; mas isto é para que se manifestasse que não são todos de nós.',
        'E vós tendes a unção do Santo, e sabeis todas as coisas.',
        'Não vos escrevi porque não soubésseis a verdade, mas porque a sabeis, e porque nenhuma mentira vem da verdade.',
        'Quem é o mentiroso, senão aquele que nega que Jesus é o Cristo? É o anticristo esse mesmo que nega o Pai e o Filho.',
        'Qualquer que nega o Filho, também não tem o Pai; mas aquele que confessa o Filho, tem também o Pai.',
        'Portanto, o que desde o princípio ouvistes permaneça em vós. Se em vós permanecer o que desde o princípio ouvistes, também permanecereis no Filho e no Pai.',
        'E esta é a promessa que ele nos fez: a vida eterna.',
        'Estas coisas vos escrevi acerca dos que vos enganam.',
        'E a unção que vós recebestes dele, fica em vós, e não tendes necessidade de que alguém vos ensine; mas, como a sua unção vos ensina todas as coisas, e é verdadeira, e não é mentira, como ela vos ensinou, assim nele permanecereis.',
        'E agora, filhinhos, permanecei nele; para que, quando ele se manifestar, tenhamos confiança, e não sejamos confundidos por ele na sua vinda.',
        'Se sabeis que ele é justo, sabeis que todo aquele que pratica a justiça é nascido dele.',
      ],
      [
        'Vede quão grande amor nos tem concedido o Pai, que fôssemos chamados filhos de Deus. Por isso o mundo não nos conhece; porque não o conhece a ele.',
        'Amados, agora somos filhos de Deus, e ainda não é manifestado o que havemos de ser. Mas sabemos que, quando ele se manifestar, seremos semelhantes a ele; porque assim como é o veremos.',
        'E qualquer que nele tem esta esperança purifica-se a si mesmo, como também ele é puro.',
        'Qualquer que comete pecado, também comete iniqüidade; porque o pecado é iniqüidade.',
        'E bem sabeis que ele se manifestou para tirar os nossos pecados; e nele não há pecado.',
        'Qualquer que permanece nele não peca; qualquer que peca não o viu nem o conheceu.',
        'Filhinhos, ninguém vos engane. Quem pratica justiça é justo, assim como ele é justo.',
        'Quem comete o pecado é do diabo; porque o diabo peca desde o princípio. Para isto o Filho de Deus se manifestou: para desfazer as obras do diabo.',
        'Qualquer que é nascido de Deus não comete pecado; porque a sua semente permanece nele; e não pode pecar, porque é nascido de Deus.',
        'Nisto são manifestos os filhos de Deus, e os filhos do diabo. Qualquer que não pratica a justiça, e não ama a seu irmão, não é de Deus.',
        'Porque esta é a mensagem que ouvistes desde o princípio: que nos amemos uns aos outros.',
        'Não como Caim, que era do maligno, e matou a seu irmão. E por que causa o matou? Porque as suas obras eram más e as de seu irmão justas.',
        'Meus irmãos, não vos maravilheis, se o mundo vos odeia.',
        'Nós sabemos que passamos da morte para a vida, porque amamos os irmãos. Quem não ama a seu irmão permanece na morte.',
        'Qualquer que odeia a seu irmão é homicida. E vós sabeis que nenhum homicida tem a vida eterna permanecendo nele.',
        'Conhecemos o amor nisto: que ele deu a sua vida por nós, e nós devemos dar a vida pelos irmãos.',
        'Quem, pois, tiver bens do mundo, e, vendo o seu irmão necessitado, lhe cerrar as suas entranhas, como estará nele o amor de Deus?',
        'Meus filhinhos, não amemos de palavra, nem de língua, mas por obra e em verdade.',
        'E nisto conhecemos que somos da verdade, e diante dele asseguraremos nossos corações;',
        'Sabendo que, se o nosso coração nos condena, maior é Deus do que o nosso coração, e conhece todas as coisas.',
        'Amados, se o nosso coração não nos condena, temos confiança para com Deus;',
        'E qualquer coisa que lhe pedirmos, dele a receberemos, porque guardamos os seus mandamentos, e fazemos o que é agradável à sua vista.',
        'E o seu mandamento é este: que creiamos no nome de seu Filho Jesus Cristo, e nos amemos uns aos outros, segundo o seu mandamento.',
        'E aquele que guarda os seus mandamentos nele está, e ele nele. E nisto conhecemos que ele está em nós, pelo Espírito que nos tem dado.',
      ],
      [
        'Amados, não creiais a todo o espírito, mas provai se os espíritos são de Deus, porque já muitos falsos profetas se têm levantado no mundo.',
        'Nisto conhecereis o Espírito de Deus: Todo o espírito que confessa que Jesus Cristo veio em carne é de Deus;',
        'E todo o espírito que não confessa que Jesus Cristo veio em carne não é de Deus; mas este é o espírito do anticristo, do qual já ouvistes que há de vir, e eis que já agora está no mundo.',
        'Filhinhos, sois de Deus, e já os tendes vencido; porque maior é o que está em vós do que o que está no mundo.',
        'Do mundo são, por isso falam do mundo, e o mundo os ouve.',
        'Nós somos de Deus; aquele que conhece a Deus ouve-nos; aquele que não é de Deus não nos ouve. Nisto conhecemos nós o espírito da verdade e o espírito do erro.',
        'Amados, amemo-nos uns aos outros; porque o amor é de Deus; e qualquer que ama é nascido de Deus e conhece a Deus.',
        'Aquele que não ama não conhece a Deus; porque Deus é amor.',
        'Nisto se manifestou o amor de Deus para conosco: que Deus enviou seu Filho unigênito ao mundo, para que por ele vivamos.',
        'Nisto está o amor, não em que nós tenhamos amado a Deus, mas em que ele nos amou a nós, e enviou seu Filho para propiciação pelos nossos pecados.',
        'Amados, se Deus assim nos amou, também nòs devemos amar uns aos outros.',
        'Ninguém jamais viu a Deus; se nos amamos uns aos outros, Deus está em nós, e em nós é perfeito o seu amor.',
        'Nisto conhecemos que estamos nele, e ele em nós, pois que nos deu do seu Espírito.',
        'E vimos, e testificamos que o Pai enviou seu Filho para Salvador do mundo.',
        'Qualquer que confessar que Jesus é o Filho de Deus, Deus está nele, e ele em Deus.',
        'E nós conhecemos, e cremos no amor que Deus nos tem. Deus é amor; e quem está em amor está em Deus, e Deus nele.',
        'Nisto é perfeito o amor para conosco, para que no dia do juízo tenhamos confiança; porque, qual ele é, somos nós também neste mundo.',
        'No amor não há temor, antes o perfeito amor lança fora o temor; porque o temor tem consigo a pena, e o que teme não é perfeito em amor.',
        'Nós o amamos a ele porque ele nos amou primeiro.',
        'Se alguém diz: Eu amo a Deus, e odeia a seu irmão, é mentiroso. Pois quem não ama a seu irmão, ao qual viu, como pode amar a Deus, a quem não viu?',
        'E dele temos este mandamento: que quem ama a Deus, ame também a seu irmão.',
      ],
      [
        'Todo aquele que crê que Jesus é o Cristo, é nascido de Deus; e todo aquele que ama ao que o gerou também ama ao que dele é nascido.',
        'Nisto conhecemos que amamos os filhos de Deus, quando amamos a Deus e guardamos os seus mandamentos.',
        'Porque este é o amor de Deus: que guardemos os seus mandamentos; e os seus mandamentos não são pesados.',
        'Porque todo o que é nascido de Deus vence o mundo; e esta é a vitória que vence o mundo, a nossa fé.',
        'Quem é que vence o mundo, senão aquele que crê que Jesus é o Filho de Deus?',
        'Este é aquele que veio por água e sangue, isto é, Jesus Cristo; não só por água, mas por água e por sangue. E o Espírito é o que testifica, porque o Espírito é a verdade.',
        'Porque três são os que testificam no céu: o Pai, a Palavra, e o Espírito Santo; e estes três são um.',
        'E três são os que testificam na terra: o Espírito, e a água e o sangue; e estes três concordam num.',
        'Se recebemos o testemunho dos homens, o testemunho de Deus é maior; porque o testemunho de Deus é este, que de seu Filho testificou.',
        'Quem crê no Filho de Deus, em si mesmo tem o testemunho; quem a Deus não crê mentiroso o fez, porquanto não creu no testemunho que Deus de seu Filho deu.',
        'E o testemunho é este: que Deus nos deu a vida eterna; e esta vida está em seu Filho.',
        'Quem tem o Filho tem a vida; quem não tem o Filho de Deus não tem a vida.',
        'Estas coisas vos escrevi a vós, os que credes no nome do Filho de Deus, para que saibais que tendes a vida eterna, e para que creiais no nome do Filho de Deus.',
        'E esta é a confiança que temos nele, que, se pedirmos alguma coisa, segundo a sua vontade, ele nos ouve.',
        'E, se sabemos que nos ouve em tudo o que pedimos, sabemos que alcançamos as petições que lhe fizemos.',
        'Se alguém vir pecar seu irmão, pecado que não é para morte, orará, e Deus dará a vida àqueles que não pecarem para morte. Há pecado para morte, e por esse não digo que ore.',
        'Toda a iniqüidade é pecado, e há pecado que não é para morte.',
        'Sabemos que todo aquele que é nascido de Deus não peca; mas o que de Deus é gerado conserva-se a si mesmo, e o maligno não lhe toca.',
        'Sabemos que somos de Deus, e que todo o mundo está no maligno.',
        'E sabemos que já o Filho de Deus é vindo, e nos deu entendimento para que conheçamos ao Verdadeiro; e no que é verdadeiro estamos, isto é, em seu Filho Jesus Cristo. Este é o verdadeiro Deus e a vida eterna.',
        'Filhinhos, guardai-vos dos ídolos. Amém.',
      ],
    ],
    livro: '1joao',
  },
  {
    abbrev: '2jo',
    capitulos: [
      [
        'O ancião à senhora eleita, e a seus filhos, aos quais amo na verdade, e não somente eu, mas também todos os que têm conhecido a verdade,',
        'Por amor da verdade que está em nós, e para sempre estará conosco:',
        'Graça, misericórdia e paz, da parte de Deus Pai e da do Senhor Jesus Cristo, o Filho do Pai, seja convosco na verdade e amor.',
        'Muito me alegro por achar que alguns de teus filhos andam na verdade, assim como temos recebido o mandamento do Pai.',
        'E agora, senhora, rogo-te, não como escrevendo-te um novo mandamento, mas aquele mesmo que desde o princípio tivemos: que nos amemos uns aos outros.',
        'E o amor é este: que andemos segundo os seus mandamentos. Este é o mandamento, como já desde o princípio ouvistes, que andeis nele.',
        'Porque já muitos enganadores entraram no mundo, os quais não confessam que Jesus Cristo veio em carne. Este tal é o enganador e o anticristo.',
        'Olhai por vós mesmos, para que não percamos o que temos ganho, antes recebamos o inteiro galardão.',
        'Todo aquele que prevarica, e não persevera na doutrina de Cristo, não tem a Deus. Quem persevera na doutrina de Cristo, esse tem tanto ao Pai como ao Filho.',
        'Se alguém vem ter convosco, e não traz esta doutrina, não o recebais em casa, nem tampouco o saudeis.',
        'Porque quem o saúda tem parte nas suas más obras.',
        'Tendo muito que escrever-vos, não quis fazê-lo com papel e tinta; mas espero ir ter convosco e falar face a face, para que o nosso gozo seja cumprido.',
        'Saúdam-te os filhos de tua irmã, a eleita. Amém.',
      ],
    ],
    livro: '2joao',
  },
  {
    abbrev: '3jo',
    capitulos: [
      [
        'O presbítero ao amado Gaio, a quem em verdade eu amo.',
        'Amado, desejo que te vá bem em todas as coisas, e que tenhas saúde, assim como bem vai a tua alma.',
        'Porque muito me alegrei quando os irmãos vieram, e testificaram da tua verdade, como tu andas na verdade.',
        'Não tenho maior gozo do que este, o de ouvir que os meus filhos andam na verdade.',
        'Amado, procedes fielmente em tudo o que fazes para com os irmãos, e para com os estranhos,',
        'Que em presença da igreja testificaram do teu amor; aos quais, se conduzires como é digno para com Deus, bem farás;',
        'Porque pelo seu Nome saíram, nada tomando dos gentios.',
        'Portanto, aos tais devemos receber, para que sejamos cooperadores da verdade.',
        'Tenho escrito à igreja; mas Diótrefes, que procura ter entre eles o primado, não nos recebe.',
        'Por isso, se eu for, trarei à memória as obras que ele faz, proferindo contra nós palavras maliciosas; e, não contente com isto, não recebe os irmãos, e impede os que querem recebê-los, e os lança fora da igreja.',
        'Amado, não sigas o mal, mas o bem. Quem faz o bem é de Deus; mas quem faz o mal não tem visto a Deus.',
        'Todos dão testemunho de Demétrio, até a mesma verdade; e também nós testemunhamos; e vós bem sabeis que o nosso testemunho é verdadeiro.',
        'Tinha muito que escrever, mas não quero escrever-te com tinta e pena.',
        'Espero, porém, ver-te brevemente, e falaremos face a face.',
        'Paz seja contigo. Os amigos te saúdam. Saúda os amigos por nome.',
      ],
    ],
    livro: '3joao',
  },
  {
    abbrev: 'jd',
    capitulos: [
      [
        'Judas, servo de Jesus Cristo, e irmão de Tiago, aos chamados, santificados em Deus Pai, e conservados por Jesus Cristo:',
        'Misericórdia, e paz, e amor vos sejam multiplicados.',
        'Amados, procurando eu escrever-vos com toda a diligência acerca da salvação comum, tive por necessidade escrever-vos, e exortar-vos a batalhar pela fé que uma vez foi dada aos santos.',
        'Porque se introduziram alguns, que já antes estavam escritos para este mesmo juízo, homens ímpios, que convertem em dissolução a graça de Deus, e negam a Deus, único dominador e Senhor nosso, Jesus Cristo.',
        'Mas quero lembrar-vos, como a quem já uma vez soube isto, que, havendo o Senhor salvo um povo, tirando-o da terra do Egito, destruiu depois os que não creram;',
        'E aos anjos que não guardaram o seu principado, mas deixaram a sua própria habitação, reservou na escuridão e em prisões eternas até ao juízo daquele grande dia;',
        'Assim como Sodoma e Gomorra, e as cidades circunvizinhas, que, havendo-se entregue à fornicação como aqueles, e ido após outra carne, foram postas por exemplo, sofrendo a pena do fogo eterno.',
        'E, contudo, também estes, semelhantemente adormecidos, contaminam a sua carne, e rejeitam a dominação, e vituperam as dignidades.',
        'Mas o arcanjo Miguel, quando contendia com o diabo, e disputava a respeito do corpo de Moisés, não ousou pronunciar juízo de maldição contra ele; mas disse: O Senhor te repreenda.',
        'Estes, porém, dizem mal do que não sabem; e, naquilo que naturalmente conhecem, como animais irracionais se corrompem.',
        'Ai deles! porque entraram pelo caminho de Caim, e foram levados pelo engano do prêmio de Balaão, e pereceram na contradição de Coré.',
        'Estes são manchas em vossas festas de amor, banqueteando-se convosco, e apascentando-se a si mesmos sem temor; são nuvens sem água, levadas pelos ventos de uma para outra parte; são como árvores murchas, infrutíferas, duas vezes mortas, desarraigadas;',
        'Ondas impetuosas do mar, que escumam as suas mesmas abominações; estrelas errantes, para os quais está eternamente reservada a negrura das trevas.',
        'E destes profetizou também Enoque, o sétimo depois de Adão, dizendo: Eis que é vindo o Senhor com milhares de seus santos;',
        'Para fazer juízo contra todos e condenar dentre eles todos os ímpios, por todas as suas obras de impiedade, que impiamente cometeram, e por todas as duras palavras que ímpios pecadores disseram contra ele.',
        'Estes são murmuradores, queixosos da sua sorte, andando segundo as suas concupiscências, e cuja boca diz coisas mui arrogantes, admirando as pessoas por causa do interesse.',
        'Mas vós, amados, lembrai-vos das palavras que vos foram preditas pelos apóstolos de nosso Senhor Jesus Cristo;',
        'Os quais vos diziam que nos últimos tempos haveria escarnecedores que andariam segundo as suas ímpias concupiscências.',
        'Estes são os que a si mesmos se separam, sensuais, que não têm o Espírito.',
        'Mas vós, amados, edificando-vos a vós mesmos sobre a vossa santíssima fé, orando no Espírito Santo,',
        'Conservai-vos a vós mesmos no amor de Deus, esperando a misericórdia de nosso Senhor Jesus Cristo para a vida eterna.',
        'E apiedai-vos de alguns, usando de discernimento;',
        'E salvai alguns com temor, arrebatando-os do fogo, odiando até a túnica manchada da carne.',
        'Ora, àquele que é poderoso para vos guardar de tropeçar, e apresentar-vos irrepreensíveis, com alegria, perante a sua glória,',
        'Ao único Deus sábio, Salvador nosso, seja glória e majestade, domínio e poder, agora, e para todo o sempre. Amém.',
      ],
    ],
    livro: 'judas',
  },
  {
    abbrev: 'ap',
    capitulos: [
      [
        'Revelação de Jesus Cristo, a qual Deus lhe deu, para mostrar aos seus servos as coisas que brevemente devem acontecer; e pelo seu anjo as enviou, e as notificou a João seu servo;',
        'O qual testificou da palavra de Deus, e do testemunho de Jesus Cristo, e de tudo o que tem visto.',
        'Bem-aventurado aquele que lê, e os que ouvem as palavras desta profecia, e guardam as coisas que nela estão escritas; porque o tempo está próximo.',
        'João, às sete igrejas que estão na Ásia: Graça e paz seja convosco da parte daquele que é, e que era, e que há de vir, e da dos sete espíritos que estão diante do seu trono;',
        'E da parte de Jesus Cristo, que é a fiel testemunha, o primogênito dentre os mortos e o príncipe dos reis da terra. Àquele que nos amou, e em seu sangue nos lavou dos nossos pecados,',
        'E nos fez reis e sacerdotes para Deus e seu Pai; a ele glória e poder para todo o sempre. Amém.',
        'Eis que vem com as nuvens, e todo o olho o verá, até os mesmos que o traspassaram; e todas as tribos da terra se lamentarão sobre ele. Sim. Amém.',
        'Eu sou o Alfa e o Ômega, o princípio e o fim, diz o Senhor, que é, e que era, e que há de vir, o Todo-Poderoso.',
        'Eu, João, que também sou vosso irmão, e companheiro na aflição, e no reino, e paciência de Jesus Cristo, estava na ilha chamada Patmos, por causa da palavra de Deus, e pelo testemunho de Jesus Cristo.',
        'Eu fui arrebatado no Espírito no dia do Senhor, e ouvi detrás de mim uma grande voz, como de trombeta,',
        'Que dizia: Eu sou o Alfa e o Ômega, o primeiro e o derradeiro; e o que vês, escreve-o num livro, e envia-o às sete igrejas que estão na Ásia: a Éfeso, e a Esmirna, e a Pérgamo, e a Tiatira, e a Sardes, e a Filadélfia, e a Laodicéia.',
        'E virei-me para ver quem falava comigo. E, virando-me, vi sete castiçais de ouro;',
        'E no meio dos sete castiçais um semelhante ao Filho do homem, vestido até aos pés de uma roupa comprida, e cingido pelos peitos com um cinto de ouro.',
        'E a sua cabeça e cabelos eram brancos como lã branca, como a neve, e os seus olhos como chama de fogo;',
        'E os seus pés, semelhantes a latão reluzente, como se tivessem sido refinados numa fornalha, e a sua voz como a voz de muitas águas.',
        'E ele tinha na sua destra sete estrelas; e da sua boca saía uma aguda espada de dois fios; e o seu rosto era como o sol, quando na sua força resplandece.',
        'E eu, quando o vi, caí a seus pés como morto; e ele pôs sobre mim a sua destra, dizendo-me: Não temas; Eu sou o primeiro e o último;',
        'E o que vivo e fui morto, mas eis aqui estou vivo para todo o sempre. Amém. E tenho as chaves da morte e do inferno.',
        'Escreve as coisas que tens visto, e as que são, e as que depois destas hão de acontecer;',
        'O mistério das sete estrelas, que viste na minha destra, e dos sete castiçais de ouro. As sete estrelas são os anjos das sete igrejas, e os sete castiçais, que viste, são as sete igrejas.',
      ],
      [
        'Escreve ao anjo da igreja de Éfeso: Isto diz aquele que tem na sua destra as sete estrelas, que anda no meio dos sete castiçais de ouro:',
        'Conheço as tuas obras, e o teu trabalho, e a tua paciência, e que não podes sofrer os maus; e puseste à prova os que dizem ser apóstolos, e o não são, e tu os achaste mentirosos.',
        'E sofreste, e tens paciência; e trabalhaste pelo meu nome, e não te cansaste.',
        'Tenho, porém, contra ti que deixaste o teu primeiro amor.',
        'Lembra-te, pois, de onde caíste, e arrepende-te, e pratica as primeiras obras; quando não, brevemente a ti virei, e tirarei do seu lugar o teu castiçal, se não te arrependeres.',
        'Tens, porém, isto: que odeias as obras dos nicolaítas, as quais eu também odeio.',
        'Quem tem ouvidos, ouça o que o Espírito diz às igrejas: Ao que vencer, dar-lhe-ei a comer da árvore da vida, que está no meio do paraíso de Deus.',
        'E ao anjo da igreja em Esmirna, escreve: Isto diz o primeiro e o último, que foi morto, e reviveu:',
        'Conheço as tuas obras, e tribulação, e pobreza (mas tu és rico), e a blasfêmia dos que se dizem judeus, e não o são, mas são a sinagoga de Satanás.',
        'Nada temas das coisas que hás de padecer. Eis que o diabo lançará alguns de vós na prisão, para que sejais tentados; e tereis uma tribulação de dez dias. Sê fiel até à morte, e dar-te-ei a coroa da vida.',
        'Quem tem ouvidos, ouça o que o Espírito diz às igrejas: O que vencer não receberá o dano da segunda morte.',
        'E ao anjo da igreja que está em Pérgamo escreve: Isto diz aquele que tem a espada aguda de dois fios:',
        'Conheço as tuas obras, e onde habitas, que é onde está o trono de Satanás; e reténs o meu nome, e não negaste a minha fé, ainda nos dias de Antipas, minha fiel testemunha, o qual foi morto entre vós, onde Satanás habita.',
        'Mas algumas poucas coisas tenho contra ti, porque tens lá os que seguem a doutrina de Balaão, o qual ensinava Balaque a lançar tropeços diante dos filhos de Israel, para que comessem dos sacrifícios da idolatria, e fornicassem.',
        'Assim tens também os que seguem a doutrina dos nicolaítas, o que eu odeio.',
        'Arrepende-te, pois, quando não em breve virei a ti, e contra eles batalharei com a espada da minha boca.',
        'Quem tem ouvidos, ouça o que o Espírito diz às igrejas: Ao que vencer darei eu a comer do maná escondido, e dar-lhe-ei uma pedra branca, e na pedra um novo nome escrito, o qual ninguém conhece senão aquele que o recebe.',
        'E ao anjo da igreja de Tiatira escreve: Isto diz o Filho de Deus, que tem seus olhos como chama de fogo, e os pés semelhantes ao latão reluzente:',
        'Eu conheço as tuas obras, e o teu amor, e o teu serviço, e a tua fé, e a tua paciência, e que as tuas últimas obras são mais do que as primeiras.',
        'Mas algumas poucas coisas tenho contra ti que deixas Jezabel, mulher que se diz profetisa, ensinar e enganar os meus servos, para que forniquem e comam dos sacrifícios da idolatria.',
        'E dei-lhe tempo para que se arrependesse da sua fornicação; e não se arrependeu.',
        'Eis que a porei numa cama, e sobre os que adulteram com ela virá grande tribulação, se não se arrependerem das suas obras.',
        'E ferirei de morte a seus filhos, e todas as igrejas saberão que eu sou aquele que sonda os rins e os corações. E darei a cada um de vós segundo as vossas obras.',
        'Mas eu vos digo a vós, e aos restantes que estão em Tiatira, a todos quantos não têm esta doutrina, e não conheceram, como dizem, as profundezas de Satanás, que outra carga vos não porei.',
        'Mas o que tendes, retende-o até que eu venha.',
        'E ao que vencer, e guardar até ao fim as minhas obras, eu lhe darei poder sobre as nações,',
        'E com vara de ferro as regerá; e serão quebradas como vasos de oleiro; como também recebi de meu Pai.',
        'E dar-lhe-ei a estrela da manhã.',
        'Quem tem ouvidos, ouça o que o Espírito diz às igrejas.',
      ],
      [
        'E ao anjo da igreja que está em Sardes escreve: Isto diz o que tem os sete espíritos de Deus, e as sete estrelas: Conheço as tuas obras, que tens nome de que vives, e estás morto.',
        'Sê vigilante, e confirma os restantes, que estavam para morrer; porque não achei as tuas obras perfeitas diante de Deus.',
        'Lembra-te, pois, do que tens recebido e ouvido, e guarda-o, e arrepende-te. E, se não vigiares, virei sobre ti como um ladrão, e não saberás a que hora sobre ti virei.',
        'Mas também tens em Sardes algumas poucas pessoas que não contaminaram suas vestes, e comigo andarão de branco; porquanto são dignas disso.',
        'O que vencer será vestido de vestes brancas, e de maneira nenhuma riscarei o seu nome do livro da vida; e confessarei o seu nome diante de meu Pai e diante dos seus anjos.',
        'Quem tem ouvidos, ouça o que o Espírito diz às igrejas.',
        'E ao anjo da igreja que está em Filadélfia escreve: Isto diz o que é santo, o que é verdadeiro, o que tem a chave de Davi; o que abre, e ninguém fecha; e fecha, e ninguém abre:',
        'Conheço as tuas obras; eis que diante de ti pus uma porta aberta, e ninguém a pode fechar; tendo pouca força, guardaste a minha palavra, e não negaste o meu nome.',
        'Eis que eu farei aos da sinagoga de Satanás, aos que se dizem judeus, e não são, mas mentem: eis que eu farei que venham, e adorem prostrados a teus pés, e saibam que eu te amo.',
        'Como guardaste a palavra da minha paciência, também eu te guardarei da hora da tentação que há de vir sobre todo o mundo, para tentar os que habitam na terra.',
        'Eis que venho sem demora; guarda o que tens, para que ninguém tome a tua coroa.',
        'A quem vencer, eu o farei coluna no templo do meu Deus, e dele nunca sairá; e escreverei sobre ele o nome do meu Deus, e o nome da cidade do meu Deus, a nova Jerusalém, que desce do céu, do meu Deus, e também o meu novo nome.',
        'Quem tem ouvidos, ouça o que o Espírito diz às igrejas.',
        'E ao anjo da igreja de Laodicéia escreve: Isto diz o Amém, a testemunha fiel e verdadeira, o princípio da criação de Deus:',
        'Conheço as tuas obras, que nem és frio nem quente; quem dera foras frio ou quente!',
        'Assim, porque és morno, e não és frio nem quente, vomitar-te-ei da minha boca.',
        'Como dizes: Rico sou, e estou enriquecido, e de nada tenho falta; e não sabes que és um desgraçado, e miserável, e pobre, e cego, e nu;',
        'Aconselho-te que de mim compres ouro provado no fogo, para que te enriqueças; e roupas brancas, para que te vistas, e não apareça a vergonha da tua nudez; e que unjas os teus olhos com colírio, para que vejas.',
        'Eu repreendo e castigo a todos quantos amo; sê pois zeloso, e arrepende-te.',
        'Eis que estou à porta, e bato; se alguém ouvir a minha voz, e abrir a porta, entrarei em sua casa, e com ele cearei, e ele comigo.',
        'Ao que vencer lhe concederei que se assente comigo no meu trono; assim como eu venci, e me assentei com meu Pai no seu trono.',
        'Quem tem ouvidos, ouça o que o Espírito diz às igrejas.',
      ],
      [
        'Depois destas coisas, olhei, e eis que estava uma porta aberta no céu; e a primeira voz, que como de trombeta ouvira falar comigo, disse: Sobe aqui, e mostrar-te-ei as coisas que depois destas devem acontecer.',
        'E logo fui arrebatado em espírito, e eis que um trono estava posto no céu, e um assentado sobre o trono.',
        'E o que estava assentado era, na aparência, semelhante à pedra jaspe e sardônica; e o arco celeste estava ao redor do trono, e parecia semelhante à esmeralda.',
        'E ao redor do trono havia vinte e quatro tronos; e vi assentados sobre os tronos vinte e quatro anciãos vestidos de vestes brancas; e tinham sobre suas cabeças coroas de ouro.',
        'E do trono saíam relâmpagos, e trovões, e vozes; e diante do trono ardiam sete lâmpadas de fogo, as quais são os sete espíritos de Deus.',
        'E havia diante do trono um como mar de vidro, semelhante ao cristal. E no meio do trono, e ao redor do trono, quatro animais cheios de olhos, por diante e por detrás.',
        'E o primeiro animal era semelhante a um leão, e o segundo animal semelhante a um bezerro, e tinha o terceiro animal o rosto como de homem, e o quarto animal era semelhante a uma águia voando.',
        'E os quatro animais tinham, cada um de per si, seis asas, e ao redor, e por dentro, estavam cheios de olhos; e não descansam nem de dia nem de noite, dizendo: Santo, Santo, Santo, é o Senhor Deus, o Todo-Poderoso, que era, e que é, e que há de vir.',
        'E, quando os animais davam glória, e honra, e ações de graças ao que estava assentado sobre o trono, ao que vive para todo o sempre,',
        'Os vinte e quatro anciãos prostravam-se diante do que estava assentado sobre o trono, e adoravam o que vive para todo o sempre; e lançavam as suas coroas diante do trono, dizendo:',
        'Digno és, Senhor, de receber glória, e honra, e poder; porque tu criaste todas as coisas, e por tua vontade são e foram criadas.',
      ],
      [
        'E vi na destra do que estava assentado sobre o trono um livro escrito por dentro e por fora, selado com sete selos.',
        'E vi um anjo forte, bradando com grande voz: Quem é digno de abrir o livro e de desatar os seus selos?',
        'E ninguém no céu, nem na terra, nem debaixo da terra, podia abrir o livro, nem olhar para ele.',
        'E eu chorava muito, porque ninguém fora achado digno de abrir o livro, nem de o ler, nem de olhar para ele.',
        'E disse-me um dos anciãos: Não chores; eis aqui o Leão da tribo de Judá, a raiz de Davi, que venceu, para abrir o livro e desatar os seus sete selos.',
        'E olhei, e eis que estava no meio do trono e dos quatro animais viventes e entre os anciãos um Cordeiro, como havendo sido morto, e tinha sete pontas e sete olhos, que são os sete espíritos de Deus enviados a toda a terra.',
        'E veio, e tomou o livro da destra do que estava assentado no trono.',
        'E, havendo tomado o livro, os quatro animais e os vinte e quatro anciãos prostraram-se diante do Cordeiro, tendo todos eles harpas e salvas de ouro cheias de incenso, que são as orações dos santos.',
        'E cantavam um novo cântico, dizendo: Digno és de tomar o livro, e de abrir os seus selos; porque foste morto, e com o teu sangue nos compraste para Deus de toda a tribo, e língua, e povo, e nação;',
        'E para o nosso Deus nos fizeste reis e sacerdotes; e reinaremos sobre a terra.',
        'E olhei, e ouvi a voz de muitos anjos ao redor do trono, e dos animais, e dos anciãos; e era o número deles milhões de milhões, e milhares de milhares,',
        'Que com grande voz diziam: Digno é o Cordeiro, que foi morto, de receber o poder, e riquezas, e sabedoria, e força, e honra, e glória, e ações de graças.',
        'E ouvi a toda a criatura que está no céu, e na terra, e debaixo da terra, e que estão no mar, e a todas as coisas que neles há, dizer: Ao que está assentado sobre o trono, e ao Cordeiro, sejam dadas ações de graças, e honra, e glória, e poder para todo o sempre.',
        'E os quatro animais diziam: Amém. E os vinte e quatro anciãos prostraram-se, e adoraram ao que vive para todo o sempre.',
      ],
      [
        'E, havendo o Cordeiro aberto um dos selos, olhei, e ouvi um dos quatro animais, que dizia como em voz de trovão: Vem, e vê.',
        'E olhei, e eis um cavalo branco; e o que estava assentado sobre ele tinha um arco; e foi-lhe dada uma coroa, e saiu vitorioso, e para vencer.',
        'E, havendo aberto o segundo selo, ouvi o segundo animal, dizendo: Vem, e vê.',
        'E saiu outro cavalo, vermelho; e ao que estava assentado sobre ele foi dado que tirasse a paz da terra, e que se matassem uns aos outros; e foi-lhe dada uma grande espada.',
        'E, havendo aberto o terceiro selo, ouvi dizer o terceiro animal: Vem, e vê. E olhei, e eis um cavalo preto e o que sobre ele estava assentado tinha uma balança em sua mão.',
        'E ouvi uma voz no meio dos quatro animais, que dizia: Uma medida de trigo por um dinheiro, e três medidas de cevada por um dinheiro; e não danifiques o azeite e o vinho.',
        'E, havendo aberto o quarto selo, ouvi a voz do quarto animal, que dizia: Vem, e vê.',
        'E olhei, e eis um cavalo amarelo, e o que estava assentado sobre ele tinha por nome Morte; e o inferno o seguia; e foi-lhes dado poder para matar a quarta parte da terra, com espada, e com fome, e com peste, e com as feras da terra.',
        'E, havendo aberto o quinto selo, vi debaixo do altar as almas dos que foram mortos por amor da palavra de Deus e por amor do testemunho que deram.',
        'E clamavam com grande voz, dizendo: Até quando, ó verdadeiro e santo Dominador, não julgas e vingas o nosso sangue dos que habitam sobre a terra?',
        'E foram dadas a cada um compridas vestes brancas e foi-lhes dito que repousassem ainda um pouco de tempo, até que também se completasse o número de seus conservos e seus irmãos, que haviam de ser mortos como eles foram.',
        'E, havendo aberto o sexto selo, olhei, e eis que houve um grande tremor de terra; e o sol tornou-se negro como saco de cilício, e a lua tornou-se como sangue;',
        'E as estrelas do céu caíram sobre a terra, como quando a figueira lança de si os seus figos verdes, abalada por um vento forte.',
        'E o céu retirou-se como um livro que se enrola; e todos os montes e ilhas foram removidos dos seus lugares.',
        'E os reis da terra, e os grandes, e os ricos, e os tribunos, e os poderosos, e todo o servo, e todo o livre, se esconderam nas cavernas e nas rochas das montanhas;',
        'E diziam aos montes e aos rochedos: Caí sobre nós, e escondei-nos do rosto daquele que está assentado sobre o trono, e da ira do Cordeiro;',
        'Porque é vindo o grande dia da sua ira; e quem poderá subsistir?',
      ],
      [
        'E depois destas coisas vi quatro anjos que estavam sobre os quatro cantos da terra, retendo os quatro ventos da terra, para que nenhum vento soprasse sobre a terra, nem sobre o mar, nem contra árvore alguma.',
        'E vi outro anjo subir do lado do sol nascente, e que tinha o selo do Deus vivo; e clamou com grande voz aos quatro anjos, a quem fora dado o poder de danificar a terra e o mar,',
        'Dizendo: Não danifiqueis a terra, nem o mar, nem as árvores, até que hajamos selado nas suas testas os servos do nosso Deus.',
        'E ouvi o número dos selados, e eram cento e quarenta e quatro mil selados, de todas as tribos dos filhos de Israel.',
        'Da tribo de Judá, havia doze mil selados; da tribo de Rúbem, doze mil selados; da tribo de Gade, doze mil selados;',
        'Da tribo de Aser, doze mil selados; da tribo de Naftali, doze mil selados; da tribo de Manassés, doze mil selados;',
        'Da tribo de Simeão, doze mil selados; da tribo de Levi, doze mil selados; da tribo de Issacar, doze mil selados;',
        'Da tribo de Zebulom, doze mil selados; da tribo de José, doze mil selados; da tribo de Benjamim, doze mil selados.',
        'Depois destas coisas olhei, e eis aqui uma multidão, a qual ninguém podia contar, de todas as nações, e tribos, e povos, e línguas, que estavam diante do trono, e perante o Cordeiro, trajando vestes brancas e com palmas nas suas mãos;',
        'E clamavam com grande voz, dizendo: Salvação ao nosso Deus, que está assentado no trono, e ao Cordeiro.',
        'E todos os anjos estavam ao redor do trono, e dos anciãos, e dos quatro animais; e prostraram-se diante do trono sobre seus rostos, e adoraram a Deus,',
        'Dizendo: Amém. Louvor, e glória, e sabedoria, e ação de graças, e honra, e poder, e força ao nosso Deus, para todo o sempre. Amém.',
        'E um dos anciãos me falou, dizendo: Estes que estão vestidos de vestes brancas, quem são, e de onde vieram?',
        'E eu disse-lhe: Senhor, tu sabes. E ele disse-me: Estes são os que vieram da grande tribulação, e lavaram as suas vestes e as branquearam no sangue do Cordeiro.',
        'Por isso estão diante do trono de Deus, e o servem de dia e de noite no seu templo; e aquele que está assentado sobre o trono os cobrirá com a sua sombra.',
        'Nunca mais terão fome, nunca mais terão sede; nem sol nem calma alguma cairá sobre eles.',
        'Porque o Cordeiro que está no meio do trono os apascentará, e lhes servirá de guia para as fontes vivas das águas; e Deus limpará de seus olhos toda a lágrima.',
      ],
      [
        'E, havendo aberto o sétimo selo, fez-se silêncio no céu quase por meia hora.',
        'E vi os sete anjos, que estavam diante de Deus, e foram-lhes dadas sete trombetas.',
        'E veio outro anjo, e pôs-se junto ao altar, tendo um incensário de ouro; e foi-lhe dado muito incenso, para o pôr com as orações de todos os santos sobre o altar de ouro, que está diante do trono.',
        'E a fumaça do incenso subiu com as orações dos santos desde a mão do anjo até diante de Deus.',
        'E o anjo tomou o incensário, e o encheu do fogo do altar, e o lançou sobre a terra; e houve depois vozes, e trovões, e relâmpagos e terremotos.',
        'E os sete anjos, que tinham as sete trombetas, prepararam-se para tocá-las.',
        'E o primeiro anjo tocou a sua trombeta, e houve saraiva e fogo misturado com sangue, e foram lançados na terra, que foi queimada na sua terça parte; queimou-se a terça parte das árvores, e toda a erva verde foi queimada.',
        'E o segundo anjo tocou a trombeta; e foi lançada no mar uma coisa como um grande monte ardendo em fogo, e tornou-se em sangue a terça parte do mar.',
        'E morreu a terça parte das criaturas que tinham vida no mar; e perdeu-se a terça parte das naus.',
        'E o terceiro anjo tocou a sua trombeta, e caiu do céu uma grande estrela ardendo como uma tocha, e caiu sobre a terça parte dos rios, e sobre as fontes das águas.',
        'E o nome da estrela era Absinto, e a terça parte das águas tornou-se em absinto, e muitos homens morreram das águas, porque se tornaram amargas.',
        'E o quarto anjo tocou a sua trombeta, e foi ferida a terça parte do sol, e a terça parte da lua, e a terça parte das estrelas; para que a terça parte deles se escurecesse, e a terça parte do dia não brilhasse, e semelhantemente a noite.',
        'E olhei, e ouvi um anjo voar pelo meio do céu, dizendo com grande voz: Ai! ai! ai! dos que habitam sobre a terra! por causa das outras vozes das trombetas dos três anjos que hão de ainda tocar.',
      ],
      [
        'E o quinto anjo tocou a sua trombeta, e vi uma estrela que do céu caiu na terra; e foi-lhe dada a chave do poço do abismo.',
        'E abriu o poço do abismo, e subiu fumaça do poço, como a fumaça de uma grande fornalha, e com a fumaça do poço escureceu-se o sol e o ar.',
        'E da fumaça vieram gafanhotos sobre a terra; e foi-lhes dado poder, como o poder que têm os escorpiões da terra.',
        'E foi-lhes dito que não fizessem dano à erva da terra, nem a verdura alguma, nem a árvore alguma, mas somente aos homens que não têm nas suas testas o selo de Deus.',
        'E foi-lhes permitido, não que os matassem, mas que por cinco meses os atormentassem; e o seu tormento era semelhante ao tormento do escorpião, quando fere o homem.',
        'E naqueles dias os homens buscarão a morte, e não a acharão; e desejarão morrer, e a morte fugirá deles.',
        'E o parecer dos gafanhotos era semelhante ao de cavalos aparelhados para a guerra; e sobre as suas cabeças havia umas como coroas semelhantes ao ouro; e os seus rostos eram como rostos de homens.',
        'E tinham cabelos como cabelos de mulheres, e os seus dentes eram como de leões.',
        'E tinham couraças como couraças de ferro; e o ruído das suas asas era como o ruído de carros, quando muitos cavalos correm ao combate.',
        'E tinham caudas semelhantes às dos escorpiões, e aguilhões nas suas caudas; e o seu poder era para danificar os homens por cinco meses.',
        'E tinham sobre si rei, o anjo do abismo; em hebreu era o seu nome Abadom, e em grego Apoliom.',
        'Passado é já um ai; eis que depois disso vêm ainda dois ais.',
        'E tocou o sexto anjo a sua trombeta, e ouvi uma voz que vinha das quatro pontas do altar de ouro, que estava diante de Deus,',
        'A qual dizia ao sexto anjo, que tinha a trombeta: Solta os quatro anjos, que estão presos junto ao grande rio Eufrates.',
        'E foram soltos os quatro anjos, que estavam preparados para a hora, e dia, e mês, e ano, a fim de matarem a terça parte dos homens.',
        'E o número dos exércitos dos cavaleiros era de duzentos milhões; e ouvi o número deles.',
        'E assim vi os cavalos nesta visão; e os que sobre eles cavalgavam tinham couraças de fogo, e de jacinto, e de enxofre; e as cabeças dos cavalos eram como cabeças de leões; e de suas bocas saía fogo e fumaça e enxofre.',
        'Por estes três foi morta a terça parte dos homens, isto é pelo fogo, pela fumaça, e pelo enxofre, que saíam das suas bocas.',
        'Porque o poder dos cavalos está na sua boca e nas suas caudas. Porquanto as suas caudas são semelhantes a serpentes, e têm cabeças, e com elas danificam.',
        'E os outros homens, que não foram mortos por estas pragas, não se arrependeram das obras de suas mãos, para não adorarem os demônios, e os ídolos de ouro, e de prata, e de bronze, e de pedra, e de madeira, que nem podem ver, nem ouvir, nem andar.',
        'E não se arrependeram dos seus homicídios, nem das suas feitiçarias, nem da sua fornicação, nem dos seus furtos.',
      ],
      [
        'E vi outro anjo forte, que descia do céu, vestido de uma nuvem; e por cima da sua cabeça estava o arco celeste, e o seu rosto era como o sol, e os seus pés como colunas de fogo;',
        'E tinha na sua mão um livrinho aberto. E pôs o seu pé direito sobre o mar, e o esquerdo sobre a terra;',
        'E clamou com grande voz, como quando ruge um leão; e, havendo clamado, os sete trovões emitiram as suas vozes.',
        'E, quando os sete trovões acabaram de emitir as suas vozes, eu ia escrever; mas ouvi uma voz do céu, que me dizia: Sela o que os sete trovões emitiram, e nào o escrevas.',
        'E o anjo que vi estar sobre o mar e sobre a terra levantou a sua mão ao céu,',
        'E jurou por aquele que vive para todo o sempre, o qual criou o céu e o que nele há, e a terra e o que nela há, e o mar e o que nele há, que não haveria mais demora;',
        'Mas nos dias da voz do sétimo anjo, quando tocar a sua trombeta, se cumprirá o segredo de Deus, como anunciou aos profetas, seus servos.',
        'E a voz que eu do céu tinha ouvido tornou a falar comigo, e disse: Vai, e toma o livrinho aberto da mão do anjo que está em pé sobre o mar e sobre a terra.',
        'E fui ao anjo, dizendo-lhe: Dá-me o livrinho. E ele disse-me: Toma-o, e come-o, e ele fará amargo o teu ventre, mas na tua boca será doce como mel.',
        'E tomei o livrinho da mão do anjo, e comi-o; e na minha boca era doce como mel; e, havendo-o comido, o meu ventre ficou amargo.',
        'E ele disse-me: Importa que profetizes outra vez a muitos povos, e nações, e línguas e reis.',
      ],
      [
        'E foi-me dada uma cana semelhante a uma vara; e chegou o anjo, e disse: Levanta-te, e mede o templo de Deus, e o altar, e os que nele adoram.',
        'E deixa o átrio que está fora do templo, e não o meças; porque foi dado às nações, e pisarão a cidade santa por quarenta e dois meses.',
        'E darei poder às minhas duas testemunhas, e profetizarão por mil duzentos e sessenta dias, vestidas de saco.',
        'Estas são as duas oliveiras e os dois castiçais que estão diante do Deus da terra.',
        'E, se alguém lhes quiser fazer mal, fogo sairá da sua boca, e devorará os seus inimigos; e, se alguém lhes quiser fazer mal, importa que assim seja morto.',
        'Estes têm poder para fechar o céu, para que não chova, nos dias da sua profecia; e têm poder sobre as águas para convertê-las em sangue, e para ferir a terra com toda a sorte de pragas, todas quantas vezes quiserem.',
        'E, quando acabarem o seu testemunho, a besta que sobe do abismo lhes fará guerra, e os vencerá, e os matará.',
        'E jazerão os seus corpos mortos na praça da grande cidade que espiritualmente se chama Sodoma e Egito, onde o nosso Senhor também foi crucificado.',
        'E homens de vários povos, e tribos, e línguas, e nações verão seus corpos mortos por três dias e meio, e não permitirão que os seus corpos mortos sejam postos em sepulcros.',
        'E os que habitam na terra se regozijarão sobre eles, e se alegrarão, e mandarão presentes uns aos outros; porquanto estes dois profetas tinham atormentado os que habitam sobre a terra.',
        'E depois daqueles três dias e meio o espírito de vida, vindo de Deus, entrou neles; e puseram-se sobre seus pés, e caiu grande temor sobre os que os viram.',
        'E ouviram uma grande voz do céu, que lhes dizia: Subi para aqui. E subiram ao céu em uma nuvem; e os seus inimigos os viram.',
        'E naquela mesma hora houve um grande terremoto, e caiu a décima parte da cidade, e no terremoto foram mortos sete mil homens; e os demais ficaram muito atemorizados, e deram glória ao Deus do céu.',
        'É passado o segundo ai; eis que o terceiro ai cedo virá.',
        'E o sétimo anjo tocou a sua trombeta, e houve no céu grandes vozes, que diziam: Os reinos do mundo vieram a ser de nosso Senhor e do seu Cristo, e ele reinará para todo o sempre.',
        'E os vinte e quatro anciãos, que estão assentados em seus tronos diante de Deus, prostraram-se sobre seus rostos e adoraram a Deus,',
        'Dizendo: Graças te damos, Senhor Deus Todo-Poderoso, que és, e que eras, e que hás de vir, que tomaste o teu grande poder, e reinaste.',
        'E iraram-se as nações, e veio a tua ira, e o tempo dos mortos, para que sejam julgados, e o tempo de dares o galardão aos profetas, teus servos, e aos santos, e aos que temem o teu nome, a pequenos e a grandes, e o tempo de destruíres os que destroem a terra.',
        'E abriu-se no céu o templo de Deus, e a arca da sua aliança foi vista no seu templo; e houve relâmpagos, e vozes, e trovões, e terremotos e grande saraiva.',
      ],
      [
        'E viu-se um grande sinal no céu: uma mulher vestida do sol, tendo a lua debaixo dos seus pés, e uma coroa de doze estrelas sobre a sua cabeça.',
        'E estava grávida, e com dores de parto, e gritava com ânsias de dar à luz.',
        'E viu-se outro sinal no céu; e eis que era um grande dragão vermelho, que tinha sete cabeças e dez chifres, e sobre as suas cabeças sete diademas.',
        'E a sua cauda levou após si a terça parte das estrelas do céu, e lançou-as sobre a terra; e o dragão parou diante da mulher que havia de dar à luz, para que, dando ela à luz, lhe tragasse o filho.',
        'E deu à luz um filho homem que há de reger todas as nações com vara de ferro; e o seu filho foi arrebatado para Deus e para o seu trono.',
        'E a mulher fugiu para o deserto, onde já tinha lugar preparado por Deus, para que ali fosse alimentada durante mil duzentos e sessenta dias.',
        'E houve batalha no céu; Miguel e os seus anjos batalhavam contra o dragão, e batalhavam o dragão e os seus anjos;',
        'Mas não prevaleceram, nem mais o seu lugar se achou nos céus.',
        'E foi precipitado o grande dragão, a antiga serpente, chamada o Diabo, e Satanás, que engana todo o mundo; ele foi precipitado na terra, e os seus anjos foram lançados com ele.',
        'E ouvi uma grande voz no céu, que dizia: Agora é chegada a salvação, e a força, e o reino do nosso Deus, e o poder do seu Cristo; porque já o acusador de nossos irmãos é derrubado, o qual diante do nosso Deus os acusava de dia e de noite.',
        'E eles o venceram pelo sangue do Cordeiro e pela palavra do seu testemunho; e não amaram as suas vidas até à morte.',
        'Por isso alegrai-vos, ó céus, e vós que neles habitais. Ai dos que habitam na terra e no mar; porque o diabo desceu a vós, e tem grande ira, sabendo que já tem pouco tempo.',
        'E, quando o dragão viu que fora lançado na terra, perseguiu a mulher que dera à luz o filho homem.',
        'E foram dadas à mulher duas asas de grande águia, para que voasse para o deserto, ao seu lugar, onde é sustentada por um tempo, e tempos, e metade de um tempo, fora da vista da serpente.',
        'E a serpente lançou da sua boca, atrás da mulher, água como um rio, para que pela corrente a fizesse arrebatar.',
        'E a terra ajudou a mulher; e a terra abriu a sua boca, e tragou o rio que o dragão lançara da sua boca.',
        'E o dragão irou-se contra a mulher, e foi fazer guerra ao remanescente da sua semente, os que guardam os mandamentos de Deus, e têm o testemunho de Jesus Cristo.',
      ],
      [
        'E eu pus-me sobre a areia do mar, e vi subir do mar uma besta que tinha sete cabeças e dez chifres, e sobre os seus chifres dez diademas, e sobre as suas cabeças um nome de blasfêmia.',
        'E a besta que vi era semelhante ao leopardo, e os seus pés como os de urso, e a sua boca como a de leão; e o dragão deu-lhe o seu poder, e o seu trono, e grande poderio.',
        'E vi uma das suas cabeças como ferida de morte, e a sua chaga mortal foi curada; e toda a terra se maravilhou após a besta.',
        'E adoraram o dragão que deu à besta o seu poder; e adoraram a besta, dizendo: Quem é semelhante à besta? Quem poderá batalhar contra ela?',
        'E foi-lhe dada uma boca, para proferir grandes coisas e blasfêmias; e deu-se-lhe poder para agir por quarenta e dois meses.',
        'E abriu a sua boca em blasfêmias contra Deus, para blasfemar do seu nome, e do seu tabernáculo, e dos que habitam no céu.',
        'E foi-lhe permitido fazer guerra aos santos, e vencê-los; e deu-se-lhe poder sobre toda a tribo, e língua, e nação.',
        'E adoraram-na todos os que habitam sobre a terra, esses cujos nomes não estão escritos no livro da vida do Cordeiro que foi morto desde a fundação do mundo.',
        'Se alguém tem ouvidos, ouça.',
        'Se alguém leva em cativeiro, em cativeiro irá; se alguém matar à espada, necessário é que à espada seja morto. Aqui está a paciência e a fé dos santos.',
        'E vi subir da terra outra besta, e tinha dois chifres semelhantes aos de um cordeiro; e falava como o dragão.',
        'E exerce todo o poder da primeira besta na sua presença, e faz que a terra e os que nela habitam adorem a primeira besta, cuja chaga mortal fora curada.',
        'E faz grandes sinais, de maneira que até fogo faz descer do céu à terra, à vista dos homens.',
        'E engana os que habitam na terra com sinais que lhe foi permitido que fizesse em presença da besta, dizendo aos que habitam na terra que fizessem uma imagem à besta que recebera a ferida da espada e vivia.',
        'E foi-lhe concedido que desse espírito à imagem da besta, para que também a imagem da besta falasse, e fizesse que fossem mortos todos os que não adorassem a imagem da besta.',
        'E faz que a todos, pequenos e grandes, ricos e pobres, livres e servos, lhes seja posto um sinal na sua mão direita, ou nas suas testas,',
        'Para que ninguém possa comprar ou vender, senão aquele que tiver o sinal, ou o nome da besta, ou o número do seu nome.',
        'Aqui há sabedoria. Aquele que tem entendimento, calcule o número da besta; porque é o número de um homem, e o seu número é seiscentos e sessenta e seis.',
      ],
      [
        'E olhei, e eis que estava o Cordeiro sobre o monte Sião, e com ele cento e quarenta e quatro mil, que em suas testas tinham escrito o nome de seu Pai.',
        'E ouvi uma voz do céu, como a voz de muitas águas, e como a voz de um grande trovão; e ouvi uma voz de harpistas, que tocavam com as suas harpas.',
        'E cantavam um como cântico novo diante do trono, e diante dos quatro animais e dos anciãos; e ninguém podia aprender aquele cântico, senão os cento e quarenta e quatro mil que foram comprados da terra.',
        'Estes são os que não estão contaminados com mulheres; porque são virgens. Estes são os que seguem o Cordeiro para onde quer que vá. Estes são os que dentre os homens foram comprados como primícias para Deus e para o Cordeiro.',
        'E na sua boca não se achou engano; porque são irrepreensíveis diante do trono de Deus.',
        'E vi outro anjo voar pelo meio do céu, e tinha o evangelho eterno, para o proclamar aos que habitam sobre a terra, e a toda a nação, e tribo, e língua, e povo,',
        'Dizendo com grande voz: Temei a Deus, e dai-lhe glória; porque é vinda a hora do seu juízo. E adorai aquele que fez o céu, e a terra, e o mar, e as fontes das águas.',
        'E outro anjo seguiu, dizendo: Caiu, caiu Babilônia, aquela grande cidade, que a todas as nações deu a beber do vinho da ira da sua fornicação.',
        'E seguiu-os o terceiro anjo, dizendo com grande voz: Se alguém adorar a besta, e a sua imagem, e receber o sinal na sua testa, ou na sua mão,',
        'Também este beberá do vinho da ira de Deus, que se deitou, não misturado, no cálice da sua ira; e será atormentado com fogo e enxofre diante dos santos anjos e diante do Cordeiro.',
        'E a fumaça do seu tormento sobe para todo o sempre; e não têm repouso nem de dia nem de noite os que adoram a besta e a sua imagem, e aquele que receber o sinal do seu nome.',
        'Aqui está a paciência dos santos; aqui estão os que guardam os mandamentos de Deus e a fé em Jesus.',
        'E ouvi uma voz do céu, que me dizia: Escreve: Bem-aventurados os mortos que desde agora morrem no Senhor. Sim, diz o Espírito, para que descansem dos seus trabalhos, e as suas obras os seguem.',
        'E olhei, e eis uma nuvem branca, e assentado sobre a nuvem um semelhante ao Filho do homem, que tinha sobre a sua cabeça uma coroa de ouro, e na sua mão uma foice aguda.',
        'E outro anjo saiu do templo, clamando com grande voz ao que estava assentado sobre a nuvem: Lança a tua foice, e sega; a hora de segar te é vinda, porque já a seara da terra está madura.',
        'E aquele que estava assentado sobre a nuvem meteu a sua foice à terra, e a terra foi segada.',
        'E saiu do templo, que está no céu, outro anjo, o qual também tinha uma foice aguda.',
        'E saiu do altar outro anjo, que tinha poder sobre o fogo, e clamou com grande voz ao que tinha a foice aguda, dizendo: Lança a tua foice aguda, e vindima os cachos da vinha da terra, porque já as suas uvas estão maduras.',
        'E o anjo lançou a sua foice à terra e vindimou as uvas da vinha da terra, e atirou-as no grande lagar da ira de Deus.',
        'E o lagar foi pisado fora da cidade, e saiu sangue do lagar até aos freios dos cavalos, pelo espaço de mil e seiscentos estádios.',
      ],
      [
        'E vi outro grande e admirável sinal no céu: sete anjos, que tinham as sete últimas pragas; porque nelas é consumada a ira de Deus.',
        'E vi um como mar de vidro misturado com fogo; e também os que saíram vitoriosos da besta, e da sua imagem, e do seu sinal, e do número do seu nome, que estavam junto ao mar de vidro, e tinham as harpas de Deus.',
        'E cantavam o cântico de Moisés, servo de Deus, e o cântico do Cordeiro, dizendo: Grandes e maravilhosas são as tuas obras, Senhor Deus Todo-Poderoso! Justos e verdadeiros são os teus caminhos, ó Rei dos santos.',
        'Quem te não temerá, ó Senhor, e não magnificará o teu nome? Porque só tu és santo; por isso todas as nações virão, e se prostrarão diante de ti, porque os teus juízos são manifestos.',
        'E depois disto olhei, e eis que o templo do tabernáculo do testemunho se abriu no céu.',
        'E os sete anjos que tinham as sete pragas saíram do templo, vestidos de linho puro e resplandecente, e cingidos com cintos de ouro pelos peitos.',
        'E um dos quatro animais deu aos sete anjos sete taças de ouro, cheias da ira de Deus, que vive para todo o sempre.',
        'E o templo encheu-se com a fumaça da glória de Deus e do seu poder; e ninguém podia entrar no templo, até que se consumassem as sete pragas dos sete anjos.',
      ],
      [
        'E ouvi, vinda do templo, uma grande voz, que dizia aos sete anjos: Ide, e derramaisobre a terra as sete taças da ira de Deus.',
        'E foi o primeiro, e derramou a sua taça sobre a terra, e fez-se uma chaga má e maligna nos homens que tinham o sinal da besta e que adoravam a sua imagem.',
        'E o segundo anjo derramou a sua taça no mar, que se tornou em sangue como de um morto, e morreu no mar toda a alma vivente.',
        'E o terceiro anjo derramou a sua taça nos rios e nas fontes das águas, e se tornaram em sangue.',
        'E ouvi o anjo das águas, que dizia: Justo és tu, ó Senhor, que és, e que eras, e hás de ser, porque julgaste estas coisas.',
        'Visto como derramaram o sangue dos santos e dos profetas, também tu lhes deste o sangue a beber; porque disto são merecedores.',
        'E ouvi outro do altar, que dizia: Na verdade, ó Senhor Deus Todo-Poderoso, verdadeiros e justos são os teus juízos.',
        'E o quarto anjo derramou a sua taça sobre o sol, e foi-lhe permitido que abrasasse os homens com fogo.',
        'E os homens foram abrasados com grandes calores, e blasfemaram o nome de Deus, que tem poder sobre estas pragas; e não se arrependeram para lhe darem glória.',
        'E o quinto anjo derramou a sua taça sobre o trono da besta, e o seu reino se fez tenebroso; e eles mordiam as suas línguas de dor.',
        'E por causa das suas dores, e por causa das suas chagas, blasfemaram do Deus do céu; e não se arrependeram das suas obras.',
        'E o sexto anjo derramou a sua taça sobre o grande rio Eufrates; e a sua água secou-se, para que se preparasse o caminho dos reis do oriente.',
        'E da boca do dragão, e da boca da besta, e da boca do falso profeta vi sair três espíritos imundos, semelhantes a rãs.',
        'Porque são espíritos de demônios, que fazem prodígios; os quais vão ao encontro dos reis da terra e de todo o mundo, para os congregar para a batalha, naquele grande dia do Deus Todo-Poderoso.',
        'Eis que venho como ladrão. Bem-aventurado aquele que vigia, e guarda as suas roupas, para que não ande nu, e não se vejam as suas vergonhas.',
        'E os congregaram no lugar que em hebreu se chama Armagedom.',
        'E o sétimo anjo derramou a sua taça no ar, e saiu grande voz do templo do céu, do trono, dizendo: Está feito.',
        'E houve vozes, e trovões, e relâmpagos, e houve um grande terremoto, como nunca houve desde que há homens sobre a terra; tal foi este tão grande terremoto.',
        'E a grande cidade fendeu-se em três partes, e as cidades das nações caíram; e da grande babilônia se lembrou Deus, para lhe dar o cálice do vinho da indignação da sua ira.',
        'E toda a ilha fugiu; e os montes não se acharam.',
        'E sobre os homens caiu do céu uma grande saraiva, pedras do peso de um talento; e os homens blasfemaram de Deus por causa da praga da saraiva; porque a sua praga era mui grande.',
      ],
      [
        'E veio um dos sete anjos que tinham as sete taças, e falou comigo, dizendo-me: Vem, mostrar-te-ei a condenação da grande prostituta que está assentada sobre muitas águas;',
        'Com a qual fornicaram os reis da terra; e os que habitam na terra se embebedaram com o vinho da sua fornicação.',
        'E levou-me em espírito a um deserto, e vi uma mulher assentada sobre uma besta de cor de escarlata, que estava cheia de nomes de blasfêmia, e tinha sete cabeças e dez chifres.',
        'E a mulher estava vestida de púrpura e de escarlata, e adornada com ouro, e pedras preciosas e pérolas; e tinha na sua mão um cálice de ouro cheio das abominações e da imundícia da sua fornicação;',
        'E na sua testa estava escrito o nome: Mistério, a grande babilônia, a mãe das prostituições e abominações da terra.',
        'E vi que a mulher estava embriagada do sangue dos santos, e do sangue das testemunhas de Jesus. E, vendo-a eu, maravilhei-me com grande admiração.',
        'E o anjo me disse: Por que te admiras? Eu te direi o mistério da mulher, e da besta que a traz, a qual tem sete cabeças e dez chifres.',
        'A besta que viste foi e já não é, e há de subir do abismo, e irá à perdição; e os que habitam na terra (cujos nomes não estão escritos no livro da vida, desde a fundação do mundo) se admirarão, vendo a besta que era e já não é, ainda que é.',
        'Aqui o sentido, que tem sabedoria. As sete cabeças são sete montes, sobre os quais a mulher está assentada.',
        'E são também sete reis; cinco já caíram, e um existe; outro ainda não é vindo; e, quando vier, convém que dure um pouco de tempo.',
        'E a besta que era e já não é, é ela também o oitavo, e é dos sete, e vai à perdição.',
        'E os dez chifres que viste são dez reis, que ainda não receberam o reino, mas receberão poder como reis por uma hora, juntamente com a besta.',
        'Estes têm um mesmo intento, e entregarão o seu poder e autoridade à besta.',
        'Estes combaterão contra o Cordeiro, e o Cordeiro os vencerá, porque é o Senhor dos senhores e o Rei dos reis; vencerão os que estão com ele, chamados, e eleitos, e fiéis.',
        'E disse-me: As águas que viste, onde se assenta a prostituta, são povos, e multidões, e nações, e línguas.',
        'E os dez chifres que viste na besta são os que odiarào a prostituta, e a colocarão desolada e nua, e comerão a sua carne, e a queimarão no fogo.',
        'Porque Deus tem posto em seus corações, que cumpram o seu intento, e tenham uma mesma idéia, e que dêem à besta o seu reino, até que se cumpram as palavras de Deus.',
        'E a mulher que viste é a grande cidade que reina sobre os reis da terra.',
      ],
      [
        'E depois destas coisas vi descer do céu outro anjo, que tinha grande poder, e a terra foi iluminada com a sua glória.',
        'E clamou fortemente com grande voz, dizendo: Caiu, caiu a grande Babilônia, e se tornou morada de demônios, e coito de todo espírito imundo, e coito de toda ave imunda e odiável.',
        'Porque todas as nações beberam do vinho da ira da sua fornicação, e os reis da terra fornicaram com ela; e os mercadores da terra se enriqueceram com a abundância de suas delícias.',
        'E ouvi outra voz do céu, que dizia: Sai dela, povo meu, para que não sejas participante dos seus pecados, e para que não incorras nas suas pragas.',
        'Porque já os seus pecados se acumularam até ao céu, e Deus se lembrou das iniqüidades dela.',
        'Tornai-lhe a dar como ela vos tem dado, e retribuí-lhe em dobro conforme as suas obras; no cálice em que vos deu de beber, dai-lhe a ela em dobro.',
        'Quanto ela se glorificou, e em delícias esteve, foi-lhe outro tanto de tormento e pranto; porque diz em seu coração: Estou assentada como rainha, e não sou viúva, e não verei o pranto.',
        'Portanto, num dia virão as suas pragas, a morte, e o pranto, e a fome; e será queimada no fogo; porque é forte o Senhor Deus que a julga.',
        'E os reis da terra, que fornicaram com ela, e viveram em delícias, a chorarão, e sobre ela prantearão, quando virem a fumaça do seu incêndio;',
        'Estando de longe pelo temor do seu tormento, dizendo: Ai! ai daquela grande cidade de Babilônia, aquela forte cidade! pois em uma hora veio o seu juízo.',
        'E sobre ela choram e lamentam os mercadores da terra; porque ninguém mais compra as suas mercadorias:',
        'Mercadorias de ouro, e de prata, e de pedras preciosas, e de pérolas, e de linho fino, e de púrpura, e de seda, e de escarlata; e toda a madeira odorífera, e todo o vaso de marfim, e todo o vaso de madeira preciosíssima, de bronze e de ferro, e de mármore;',
        'E canela, e perfume, e mirra, e incenso, e vinho, e azeite, e flor de farinha, e trigo, e gado, e ovelhas; e cavalos, e carros, e corpos e almas de homens.',
        'E o fruto do desejo da tua alma foi-se de ti; e todas as coisas gostosas e excelentes se foram de ti, e não mais as acharás.',
        'Os mercadores destas coisas, que dela se enriqueceram, estarão de longe, pelo temor do seu tormento, chorando e lamentando,',
        'E dizendo: Ai, ai daquela grande cidade! que estava vestida de linho fino, de púrpura, de escarlata; e adornada com ouro e pedras preciosas e pérolas! porque numa hora foram assoladas tantas riquezas.',
        'E todo piloto, e todo o que navega em naus, e todo marinheiro, e todos os que negociam no mar se puseram de longe;',
        'E, vendo a fumaça do seu incêndio, clamaram, dizendo: Que cidade é semelhante a esta grande cidade?',
        'E lançaram pó sobre as suas cabeças, e clamaram, chorando, e lamentando, e dizendo: Ai, ai daquela grande cidade! na qual todos os que tinham naus no mar se enriqueceram em razão da sua opulência; porque numa hora foi assolada.',
        'Alegra-te sobre ela, ó céu, e vós, santos apóstolos e profetas; porque já Deus julgou a vossa causa quanto a ela.',
        'E um forte anjo levantou uma pedra como uma grande mó, e lançou-a no mar, dizendo: Com igual ímpeto será lançada babilônia, aquela grande cidade, e não será jamais achada.',
        'E em ti não se ouvirá mais a voz de harpistas, e de músicos, e de flautistas, e de trombeteiros, e nenhum artífice de arte alguma se achará mais em ti; e ruído de mó em ti não se ouvirá mais;',
        'E luz de candeia não mais luzirá em ti, e voz de esposo e de esposa não mais em ti se ouvirá; porque os teus mercadores eram os grandes da terra; porque todas as nações foram enganadas pelas tuas feitiçarias.',
        'E nela se achou o sangue dos profetas, e dos santos, e de todos os que foram mortos na terra.',
      ],
      [
        'E, depois destas coisas ouvi no céu uma grande voz de uma grande multidão, que dizia: Aleluia! A salvação, e a glória, e a honra, e o poder pertencem ao Senhor nosso Deus;',
        'Porque verdadeiros e justos são os seus juízos, pois julgou a grande prostituta, que havia corrompido a terra com a sua fornicação, e das mãos dela vingou o sangue dos seus servos.',
        'E outra vez disseram: Aleluia! E a fumaça dela sobe para todo o sempre.',
        'E os vinte e quatro anciãos, e os quatro animais, prostraram-se e adoraram a Deus, que estava assentado no trono, dizendo: Amém. Aleluia!',
        'E saiu uma voz do trono, que dizia: Louvai o nosso Deus, vós, todos os seus servos, e vós que o temeis, assim pequenos como grandes.',
        'E ouvi como que a voz de uma grande multidão, e como que a voz de muitas águas, e como que a voz de grandes trovões, que dizia: Aleluia! pois já o Senhor Deus Todo-Poderoso reina.',
        'Regozijemo-nos, e alegremo-nos, e demos-lhe glória; porque vindas são as bodas do Cordeiro, e já a sua esposa se aprontou.',
        'E foi-lhe dado que se vestisse de linho fino, puro e resplandecente; porque o linho fino são as justiças dos santos.',
        'E disse-me: Escreve: Bemaventurados aqueles que são chamados à ceia das bodas do Cordeiro. E disse-me: Estas são as verdadeiras palavras de Deus.',
        'E eu lancei-me a seus pés para o adorar; mas ele disse-me: Olha não faças tal; sou teu conservo, e de teus irmãos, que têm o testemunho de Jesus. Adora a Deus; porque o testemunho de Jesus é o espírito de profecia.',
        'E vi o céu aberto, e eis um cavalo branco; e o que estava assentado sobre ele chama-se Fiel e Verdadeiro; e julga e peleja com justiça.',
        'E os seus olhos eram como chama de fogo; e sobre a sua cabeça havia muitos diademas; e tinha um nome escrito, que ninguém sabia senão ele mesmo.',
        'E estava vestido de veste tingida em sangue; e o nome pelo qual se chama é A Palavra de Deus.',
        'E seguiam-no os exércitos no céu em cavalos brancos, e vestidos de linho fino, branco e puro.',
        'E da sua boca saía uma aguda espada, para ferir com ela as nações; e ele as regerá com vara de ferro; e ele mesmo é o que pisa o lagar do vinho do furor e da ira do Deus Todo-Poderoso.',
        'E no manto e na sua coxa tem escrito este nome: Rei dos reis, e Senhor dos senhores.',
        'E vi um anjo que estava no sol, e clamou com grande voz, dizendo a todas as aves que voavam pelo meio do céu: Vinde, e ajuntai-vos à ceia do grande Deus;',
        'Para que comais a carne dos reis, e a carne dos tribunos, e a carne dos fortes, e a carne dos cavalos e dos que sobre eles se assentam; e a carne de todos os homens, livres e servos, pequenos e grandes.',
        'E vi a besta, e os reis da terra, e os seus exércitos reunidos, para fazerem guerra àquele que estava assentado sobre o cavalo, e ao seu exército.',
        'E a besta foi presa, e com ela o falso profeta, que diante dela fizera os sinais, com que enganou os que receberam o sinal da besta, e adoraram a sua imagem. Estes dois foram lançados vivos no lago de fogo que arde com enxofre.',
        'E os demais foram mortos com a espada que saía da boca do que estava assentado sobre o cavalo, e todas as aves se fartaram das suas carnes.',
      ],
      [
        'E vi descer do céu um anjo, que tinha a chave do abismo, e uma grande cadeia na sua mão.',
        'Ele prendeu o dragão, a antiga serpente, que é o Diabo e Satanás, e amarrou-o por mil anos.',
        'E lançou-o no abismo, e ali o encerrou, e pôs selo sobre ele, para que não mais engane as nações, até que os mil anos se acabem. E depois importa que seja solto por um pouco de tempo.',
        'E vi tronos; e assentaram-se sobre eles, e foi-lhes dado o poder de julgar; e vi as almas daqueles que foram degolados pelo testemunho de Jesus, e pela palavra de Deus, e que não adoraram a besta, nem a sua imagem, e não receberam o sinal em suas testas nem em suas mãos; e viveram, e reinaram com Cristo durante mil anos.',
        'Mas os outros mortos não reviveram, até que os mil anos se acabaram. Esta é a primeira ressurreição.',
        'Bem-aventurado e santo aquele que tem parte na primeira ressurreição; sobre estes não tem poder a segunda morte; mas serão sacerdotes de Deus e de Cristo, e reinarão com ele mil anos.',
        'E, acabando-se os mil anos, Satanás será solto da sua prisão,',
        'E sairá a enganar as nações que estão sobre os quatro cantos da terra, Gogue e Magogue, cujo número é como a areia do mar, para as ajuntar em batalha.',
        'E subiram sobre a largura da terra, e cercaram o arraial dos santos e a cidade amada; e de Deus desceu fogo, do céu, e os devorou.',
        'E o diabo, que os enganava, foi lançado no lago de fogo e enxofre, onde estão a besta e o falso profeta; e de dia e de noite serão atormentados para todo o sempre.',
        'E vi um grande trono branco, e o que estava assentado sobre ele, de cuja presença fugiu a terra e o céu; e não se achou lugar para eles.',
        'E vi os mortos, grandes e pequenos, que estavam diante de Deus, e abriram-se os livros; e abriu-se outro livro, que é o da vida. E os mortos foram julgados pelas coisas que estavam escritas nos livros, segundo as suas obras.',
        'E deu o mar os mortos que nele havia; e a morte e o inferno deram os mortos que neles havia; e foram julgados cada um segundo as suas obras.',
        'E a morte e o inferno foram lançados no lago de fogo. Esta é a segunda morte.',
        'E aquele que não foi achado escrito no livro da vida foi lançado no lago de fogo.',
      ],
      [
        'E vi um novo céu, e uma nova terra. Porque já o primeiro céu e a primeira terra passaram, e o mar já não existe.',
        'E eu, João, vi a santa cidade, a nova Jerusalém, que de Deus descia do céu, adereçada como uma esposa ataviada para o seu marido.',
        'E ouvi uma grande voz do céu, que dizia: Eis aqui o tabernáculo de Deus com os homens, pois com eles habitará, e eles serão o seu povo, e o mesmo Deus estará com eles, e será o seu Deus.',
        'E Deus limpará de seus olhos toda a lágrima; e não haverá mais morte, nem pranto, nem clamor, nem dor; porque já as primeiras coisas são passadas.',
        'E o que estava assentado sobre o trono disse: Eis que faço novas todas as coisas. E disse-me: Escreve; porque estas palavras são verdadeiras e fiéis.',
        'E disse-me mais: Está cumprido. Eu sou o Alfa e o Ômega, o princípio e o fim. A quem quer que tiver sede, de graça lhe darei da fonte da água da vida.',
        'Quem vencer, herdará todas as coisas; e eu serei seu Deus, e ele será meu filho.',
        'Mas, quanto aos tímidos, e aos incrédulos, e aos abomináveis, e aos homicidas, e aos que se prostituem, e aos feiticeiros, e aos idólatras e a todos os mentirosos, a sua parte será no lago que arde com fogo e enxofre; o que é a segunda morte.',
        'E veio a mim um dos sete anjos que tinham as sete taças cheias das últimas sete pragas, e falou comigo, dizendo: Vem, mostrar-te-ei a esposa, a mulher do Cordeiro.',
        'E levou-me em espírito a um grande e alto monte, e mostrou-me a grande cidade, a santa Jerusalém, que de Deus descia do céu.',
        'E tinha a glória de Deus; e a sua luz era semelhante a uma pedra preciosíssima, como a pedra de jaspe, como o cristal resplandecente.',
        'E tinha um grande e alto muro com doze portas, e nas portas doze anjos, e nomes escritos sobre elas, que são os nomes das doze tribos dos filhos de Israel.',
        'Do lado do levante tinha três portas, do lado do norte, três portas, do lado do sul, três portas, do lado do poente, três portas.',
        'E o muro da cidade tinha doze fundamentos, e neles os nomes dos doze apóstolos do Cordeiro.',
        'E aquele que falava comigo tinha uma cana de ouro, para medir a cidade, e as suas portas, e o seu muro.',
        'E a cidade estava situada em quadrado; e o seu comprimento era tanto como a sua largura. E mediu a cidade com a cana até doze mil estádios; e o seu comprimento, largura e altura eram iguais.',
        'E mediu o seu muro, de cento e quarenta e quatro côvados, conforme à medida de homem, que é a de um anjo.',
        'E a construção do seu muro era de jaspe, e a cidade de ouro puro, semelhante a vidro puro.',
        'E os fundamentos do muro da cidade estavam adornados de toda a pedra preciosa. O primeiro fundamento era jaspe; o segundo, safira; o terceiro, calcedônia; o quarto, esmeralda;',
        'O quinto, sardônica; o sexto, sárdio; o sétimo, crisólito; o oitavo, berilo; o nono, topázio; o décimo, crisópraso; o undécimo, jacinto; o duodécimo, ametista.',
        'E as doze portas eram doze pérolas; cada uma das portas era uma pérola; e a praça da cidade de ouro puro, como vidro transparente.',
        'E nela não vi templo, porque o seu templo é o Senhor Deus Todo-Poderoso, e o Cordeiro.',
        'E a cidade não necessita de sol nem de lua, para que nela resplandeçam, porque a glória de Deus a tem iluminado, e o Cordeiro é a sua lâmpada.',
        'E as nações dos salvos andarão à sua luz; e os reis da terra trarão para ela a sua glória e honra.',
        'E as suas portas não se fecharão de dia, porque ali não haverá noite.',
        'E a ela trarão a glória e honra das nações.',
        'E não entrará nela coisa alguma que contamine, e cometa abominação e mentira; mas só os que estão inscritos no livro da vida do Cordeiro.',
      ],
      [
        'E mostrou-me o rio puro da água da vida, claro como cristal, que procedia do trono de Deus e do Cordeiro.',
        'No meio da sua praça, e de um e de outro lado do rio, estava a árvore da vida, que produz doze frutos, dando seu fruto de mês em mês; e as folhas da árvore são para a saúde das nações.',
        'E ali nunca mais haverá maldição contra alguém; e nela estará o trono de Deus e do Cordeiro, e os seus servos o servirão.',
        'E verão o seu rosto, e nas suas testas estará o seu nome.',
        'E ali não haverá mais noite, e não necessitarão de lâmpada nem de luz do sol, porque o Senhor Deus os ilumina; e reinarão para todo o sempre.',
        'E disse-me: Estas palavras são fiéis e verdadeiras; e o Senhor, o Deus dos santos profetas, enviou o seu anjo, para mostrar aos seus servos as coisas que em breve hão de acontecer.',
        'Eis que presto venho: Bem-aventurado aquele que guarda as palavras da profecia deste livro.',
        'E eu, João, sou aquele que vi e ouvi estas coisas. E, havendo-as ouvido e visto, prostrei-me aos pés do anjo que mas mostrava para o adorar.',
        'E disse-me: Olha, não faças tal; porque eu sou conservo teu e de teus irmãos, os profetas, e dos que guardam as palavras deste livro. Adora a Deus.',
        'E disse-me: Não seles as palavras da profecia deste livro; porque próximo está o tempo.',
        'Quem é injusto, seja injusto ainda; e quem é sujo, seja sujo ainda; e quem é justo, seja justificado ainda; e quem é santo, seja santificado ainda.',
        'E, eis que cedo venho, e o meu galardão está comigo, para dar a cada um segundo a sua obra.',
        'Eu sou o Alfa e o Ômega, o princípio e o fim, o primeiro e o derradeiro.',
        'Bem-aventurados aqueles que guardam os seus mandamentos, para que tenham direito à árvore da vida, e possam entrar na cidade pelas portas.',
        'Mas, ficarão de fora os cães e os feiticeiros, e os que se prostituem, e os homicidas, e os idólatras, e qualquer que ama e comete a mentira.',
        'Eu, Jesus, enviei o meu anjo, para vos testificar estas coisas nas igrejas. Eu sou a raiz e a geração de Davi, a resplandecente estrela da manhã.',
        'E o Espírito e a esposa dizem: Vem. E quem ouve, diga: Vem. E quem tem sede, venha; e quem quiser, tome de graça da água da vida.',
        'Porque eu testifico a todo aquele que ouvir as palavras da profecia deste livro que, se alguém lhes acrescentar alguma coisa, Deus fará vir sobre ele as pragas que estão escritas neste livro;',
        'E, se alguém tirar quaisquer palavras do livro desta profecia, Deus tirará a sua parte do livro da vida, e da cidade santa, e das coisas que estão escritas neste livro.',
        'Aquele que testifica estas coisas diz: Certamente cedo venho. Amém. Ora vem, Senhor Jesus.',
        'A graça de nosso Senhor Jesus Cristo seja com todos vós. Amém.',
      ],
    ],
    livro: 'apocalipse',
  },
]
