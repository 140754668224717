import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import jsonData from '../constants/json/acf.json'

interface Versiculo {
  Numero: number
  Texto: string
}

interface Capitulo {
  Capitulo: number
  Versiculos: Versiculo[]
}

interface Livro {
  Nome: string
  Testamento: number
  Capitulos: Capitulo[]
}

interface Biblia {
  Livros: Livro[]
}

function LeituraBiblia() {
  const navigate = useNavigate()
  const params = useParams()
  const nomeLivroParam = params.nomeLivro
  const numCapituloParam = Number(params.capitulo)
  const bibliaData: Biblia = jsonData as Biblia

  const [showButtons, setShowButtons] = useState(false)

  const removeDiacritics = (str: string): string => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  const formatBookName = (str: string): string => {
    return removeDiacritics(str).replace(' ', '').toLowerCase()
  }

  const handleScroll = () => {
    const scrollY = window.scrollY
    const windowHeight = window.innerHeight
    const documentHeight = document.documentElement.scrollHeight

    // A lógica agora calcula 90% da altura da página
    const triggerPoint = documentHeight * 0.6

    // Quando o scroll chegar a 90% da altura da página, exibe os botões
    if (scrollY + windowHeight >= triggerPoint) {
      // 100px antes do final
      setShowButtons(true)
    } else {
      setShowButtons(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll) // Adiciona o evento de scroll
    return () => {
      window.removeEventListener('scroll', handleScroll) // Limpa o evento ao desmontar o componente
    }
  }, [])

  const BibliaComponent: React.FC<{ data: Biblia }> = ({ data }) => {
    console.log('Entrou')
    const livro = data.Livros.find(
      (livro) => formatBookName(livro.Nome) === nomeLivroParam
    )
    if (!livro) {
      return <p>Livro não encontrado.</p>
    }

    const capitulo = livro.Capitulos.find(
      (cap) => cap.Capitulo === numCapituloParam
    )
    if (!capitulo) {
      return <p>Capítulo não encontrado.</p>
    }
    // Funções de navegação
    const getNextChapter = () => {
      const nextCapitulo = livro.Capitulos.find(
        (cap) => cap.Capitulo === numCapituloParam + 1
      )
      if (nextCapitulo) {
        navigate(
          `/biblia/${formatBookName(livro.Nome)}/${nextCapitulo.Capitulo}`
        )
      }
    }

    const getPrevChapter = () => {
      const prevCapitulo = livro.Capitulos.find(
        (cap) => cap.Capitulo === numCapituloParam - 1
      )
      if (prevCapitulo) {
        navigate(
          `/biblia/${formatBookName(livro.Nome)}/${prevCapitulo.Capitulo}`
        )
      }
    }

    return (
      <div className="w-full justify-items-center font-sans">
        <div className="max-w-[min(700px,80vw)]">
          <div className="justify-items-center border-b-2 border-gray-500">
            <h2 className="text-2xl dark:text-white md:text-3xl">
              {livro.Nome}
            </h2>
            <h3 className="text-lg dark:text-white md:text-xl">
              Capítulo {capitulo.Capitulo}
            </h3>
          </div>
          <div>
            {capitulo.Versiculos.map((versiculo) => (
              <div className="mt-2">
                <p key={versiculo.Numero} className="text-base dark:text-white">
                  <strong>{versiculo.Numero}</strong>: {versiculo.Texto}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Botões flutuantes */}
        {showButtons && (
          <>
            <div className="fixed bottom-5 left-5 z-50">
              {numCapituloParam > 1 && (
                <button
                  onClick={getPrevChapter}
                  className="rounded-full bg-sky-800 p-3 text-white shadow-lg transition hover:bg-sky-700"
                >
                  <ChevronLeftIcon className="h-6 w-6" />
                </button>
              )}
            </div>

            <div className="fixed bottom-5 right-5 z-50">
              {numCapituloParam < livro.Capitulos.length && (
                <button
                  onClick={getNextChapter}
                  className="rounded-full bg-sky-800 p-3 text-white shadow-lg transition hover:bg-sky-700"
                >
                  <ChevronRightIcon className="h-6 w-6" />
                </button>
              )}
            </div>
          </>
        )}
      </div>
    )
  }

  return <BibliaComponent data={bibliaData} />
}

export default LeituraBiblia
