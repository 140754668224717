import { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import livros from '../constants/json/livros.json'

function BibliaCapitulo() {
  const { nomeLivro } = useParams<{ nomeLivro: string }>()
  const [nomeLivroBiblia, setNomeLivro] = useState<string | null>(null)
  const [capitulos, setCapitulos] = useState<number | null>(null)
  const [itens, setItens] = useState<JSX.Element[]>([])

  const removeDiacritics = (str: string): string => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  const formatBookName = useCallback((str: string): string => {
    return removeDiacritics(str).replace(/\s+/g, '').toLowerCase()
  }, [])

  useEffect(() => {
    const livro = livros.find(
      (livro) => formatBookName(livro.title) === nomeLivro
    )

    if (livro) {
      setNomeLivro(livro.title)
      setCapitulos(livro.chapters)
      const novosItens = Array.from({ length: livro.chapters }, (_, i) => (
        <Link
          to={`/biblia/${formatBookName(livro.title)}/${i + 1}`}
          key={i + 1}
        >
          <button
            className="focus:text-primary focus:border-primary active:text-primary active:border-primary dark:hover:text-primary-light dark:hover:border-primary-light dark:focus:text-primary-light dark:focus:border-primary-light dark:active:text-primary-light dark:active:border-primary-light w-full max-w-[5rem] border 
  border-gray-400 p-2.5 px-4 text-center 
  font-semibold text-inherit transition-colors duration-150 ease-in-out hover:border-sky-800 hover:bg-gray-100 hover:text-sky-800"
          >
            <h6>{i + 1}</h6>
          </button>
        </Link>
      ))
      setItens(novosItens)
    } else {
      setCapitulos(null)
    }
  }, [nomeLivro, formatBookName])

  return (
    <div className="w-full font-sans">
      {capitulos ? (
        <div className="flex min-h-screen flex-col items-center justify-start py-8 pb-16">
          <div className="justify-items-center">
            <h4 className="text-2xl dark:text-white md:text-3xl">
              {nomeLivroBiblia}
            </h4>
            <h5 className="text-lg dark:text-white md:text-xl">
              {capitulos} {capitulos === 1 ? 'capítulo' : 'capítulos'}
            </h5>
          </div>
          <div className="grid-cols-auto mt-12 grid max-w-[min(800px,_90vw)] grid-cols-[repeat(auto-fit,_minmax(4.5rem,_1fr))] items-center gap-2 dark:text-white">
            {itens}
          </div>
        </div>
      ) : (
        <div className="flex min-h-screen flex-col items-center justify-start py-8 pb-16">
          <h4>Oops...</h4>
          <p>
            Desculpe, a página que você está procurando não pôde ser encontrada.
          </p>
        </div>
      )}
    </div>
  )
}

export default BibliaCapitulo
