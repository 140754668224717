import { Link } from 'react-router-dom'

import novoTestamento from '../../constants/json/NovoTestamento.json'
import velhoTestamento from '../../constants/json/VelhoTestamento.json'

const BibliaComponent = () => {
  function removeDiacritics(str: string) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  function formatBookName(str: string) {
    return removeDiacritics(str).replace(' ', '').toLowerCase()
  }

  return (
    <div className={'biblia-container'}>
      <div className={'biblia-grid'}>
        <div className={'biblia-livrosBox items-center md:items-start'}>
          <div className="text-xl font-semibold dark:text-white">
            <h2>Antigo Testamento</h2>
          </div>
          <div className="justify-items-center">
            <ul className={'biblia-livrosColuna'}>
              {velhoTestamento.map((book) => {
                return (
                  <li key={book.title}>
                    <Link
                      to={`/biblia/${formatBookName(book.title)}`}
                      key={book.title}
                    >
                      <button
                        className={
                          'card py-[5px] pr-[15px] text-lg hover:bg-gray-100 hover:text-sky-800 dark:text-white dark:hover:text-sky-800'
                        }
                      >
                        {book.title}
                      </button>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <div className={'biblia-livrosBox items-center md:items-start'}>
          <div className="text-xl font-semibold dark:text-white ">
            <h2>Novo Testamento</h2>
          </div>
          <div className="justify-items-center">
            <ul className={'biblia-livrosColuna'}>
              {novoTestamento.map((book) => {
                return (
                  <li key={book.title}>
                    <Link
                      to={`/biblia/${formatBookName(book.title)}`}
                      key={book.title}
                    >
                      <button
                        className={
                          'card py-[5px] pr-[15px] text-lg hover:bg-gray-100 hover:text-sky-800 dark:text-white dark:hover:text-sky-800'
                        }
                      >
                        {book.title}
                      </button>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BibliaComponent
