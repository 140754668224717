import { useNavigate } from 'react-router-dom'

import { removeUserFromGroup } from '../../../lib/firebase/firebaseGroups'
import { BaseModal } from '../BaseModal'

type Props = {
  groupName: string
  isGroupPrivate: boolean
  uid: string
  isAdmin: boolean
  showErrorAlert: (message: string, options?: any | undefined) => void
  isOpen: boolean
  handleClose: () => void
}

export const ConfirmLeaveGroupModal = ({
  groupName,
  isGroupPrivate,
  uid,
  isAdmin,
  showErrorAlert,
  isOpen,
  handleClose,
}: Props) => {
  const navigate = useNavigate()

  const handleLeaveGroupButtonClick = async () => {
    const tryRemove = await removeUserFromGroup(groupName, uid, isAdmin)
    if (!tryRemove) {
      showErrorAlert(
        'Desculpe, não é possível sair deste grupo neste momento. Por favor, tente novamente mais tarde.'
      )
    }

    navigate('/grupos')
  }

  return (
    <BaseModal
      title={`Sair do grupo ${groupName}`}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <div className="transform overflow-hidden transition-all">
        <div className="my-2 flex flex-col justify-center">
          <p className="text-black dark:text-white">
            {`Tem certeza de que gostaria de deixar o grupo ${
              isGroupPrivate ? 'Privado' : 'Público'
            } ?`}
          </p>
          {isAdmin && (
            <p className="my-6 text-red-600 dark:text-red-400">
              Como você é o criador do grupo, sair deste grupo irá também
              excluir o grupo inteiro e remover automaticamente todos os
              usuários dele!
            </p>
          )}
          <button
            type="button"
            className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-sky-700 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-base"
            onClick={() => handleClose()}
          >
            Não
          </button>
          <button
            className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-slate-500 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-base"
            onClick={() => handleLeaveGroupButtonClick()}
          >
            Sair
          </button>
        </div>

        <button
          onClick={handleClose}
          tabIndex={0}
          aria-label="close"
          aria-pressed="false"
          className="absolute right-4 top-4"
        ></button>
      </div>
    </BaseModal>
  )
}
