import { Link } from 'react-router-dom'

import { buttonClasses } from './../../constants/classes'

export const Footer = () => {
  return (
    <div className="mt-10">
      <hr />
      <>
        <div className="bg-gray-100 text-center dark:bg-slate-800">
          <div className="flex items-center justify-center py-4">
            <p className="mr-4 text-sm text-black dark:text-white sm:text-lg">
              Crie uma conta para salvar suas estatísticas!
            </p>
            <Link to="/perfil" className={buttonClasses}>
              Criar
            </Link>
          </div>
        </div>

        <div className="bg-sky-800 text-center text-white dark:bg-slate-800">
          <div className="mx-auto flex max-w-5xl flex-col items-center justify-between px-4 py-8 lg:flex-row ">
            {/* Logo Section */}
            <div className="flex flex-col items-center space-y-2 lg:flex-row lg:space-y-0">
              <p className="text-xl font-bold">
                {' '}
                <Link to="/">BIBLIANDO.COM</Link>
              </p>
            </div>

            {/* Links Menu Section */}
            <div className="mt-8 flex flex-col gap-4 lg:mt-0 lg:grid lg:grid-cols-3 lg:gap-8 ">
              <ul className="space-y-2">
                <li>
                  <Link to="/" className="hover:text-yellow-400">
                    Jogar
                  </Link>
                </li>
                <li>
                  <Link to="/classificacao" className="hover:text-yellow-400">
                    Classificação
                  </Link>
                </li>
                <li>
                  <Link to="/grupos" className="hover:text-yellow-400">
                    Grupos
                  </Link>
                </li>
              </ul>
              <ul className="space-y-2">
                <li>
                  <Link to="/perfil" className="hover:text-yellow-400">
                    Perfil
                  </Link>
                </li>
                <li>
                  <Link to="/biblia" className="hover:text-yellow-400">
                    Bíblia
                  </Link>
                </li>
                <li>
                  <Link to="/ajuda" className="hover:text-yellow-400">
                    Ajuda
                  </Link>
                </li>
              </ul>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="mailto:scodeti.ltda@gmail.com"
                    className="hover:text-yellow-400"
                  >
                    Contato
                  </Link>
                </li>
                <li>
                  <Link to="/sobre" className="hover:text-yellow-400">
                    Sobre
                  </Link>
                </li>
                <li>
                  <Link to="/sobre" className="hover:text-yellow-400">
                    Política de Privacidade
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Footer Copyright */}
          <div className="py-4 text-sm text-gray-400">
            <p>© 2024 Todos os direitos reservados.</p>
          </div>
        </div>
      </>
    </div>
  )
}
