import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { HistoryModal } from './../components/modals/HistoryModal'
import { buttonClasses } from './../constants/classes'
import { UpdateMetadata } from './../constants/types'
import logo from './../img/logoFundoCinza.svg'

function UpdateHistory() {
  const defaultUpdate = {
    content: <></>,
    title: '',
    date: '',
  }

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [update, setUpdate] = useState<UpdateMetadata>(defaultUpdate)
  const navigate = useNavigate()
  const buttonEnabledClasses =
    'bg-sky-700 hover:bg-sky-800 focus-visible:outline-sky-800'

  // const todayStatsUpdate = (
  //   <div className="col-span-10 col-start-2 mb-16 mt-2 overflow-hidden bg-gray-100 text-center dark:bg-slate-800">
  //     <h2 className="my-8 text-xl font-bold dark:text-white md:text-3xl">
  //       Estatísticas de hoje
  //     </h2>
  //     <p className="mx-auto my-12 w-4/5 text-base dark:text-white md:text-xl">
  //       Veja as estatísticas da solução de hoje depois de jogar! Compare o
  //       número de palpites que você precisava para todos os outros que jogaram
  //       hoje.
  //     </p>
  //     <div className="mx-auto mb-8 w-64">
  //       <button
  //         className={`${buttonEnabledClasses} group relative flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
  //         onClick={() => navigate('/classificacao/hoje')}
  //       >
  //         Estatísticas de hoje
  //       </button>
  //     </div>
  //   </div>
  // )

  // const rebrandUpdate = (
  //   <div className="col-span-10 col-start-2 mb-16 mt-2 overflow-hidden bg-gray-100 text-center dark:bg-slate-800">
  //     <h2 className="my-8 text-xl font-bold dark:text-white md:text-3xl">
  //       <s>[Redacted Name]</s> torna-se Bibliando!
  //     </h2>
  //     <p className="mx-auto my-12 w-4/5 text-base dark:text-white md:text-xl">
  //       Devido a aconselhamento jurídico, <s>[Redacted Name]</s> mudou seu nome
  //       e branding poucas semanas após uma atualização massiva que trouxe contas
  //       online, grupos e funcionalidade de tabela de classificação para o jogo.
  //     </p>
  //     <p className="mx-auto my-12 w-4/5 text-base dark:text-white md:text-xl">
  //       O Bibliando continuou a se tornar cada vez mais popular desde então.
  //       Esperamos você continua compartilhando nosso jogo e a tarefa de
  //       curiosidades bíblicas diárias com outros!
  //     </p>
  //     <div className="mx-auto mb-8 w-64">
  //       <button
  //         className={`${buttonEnabledClasses} group relative flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
  //         onClick={() => navigate('/sobre')}
  //       >
  //         Sobre
  //       </button>
  //     </div>
  //   </div>
  // )

  // const accountsUpdate = (
  //   <div className="col-span-10 col-start-2 mb-16 mt-2 overflow-hidden bg-gray-100 text-center dark:bg-slate-800">
  //     <h2 className="my-8 text-xl font-bold dark:text-white md:text-3xl">
  //       Crie sua própria conta <br /> no Bibliando
  //     </h2>
  //     <p className="mx-auto my-12 w-4/5 text-base dark:text-white md:text-xl">
  //       Mantenha suas estatísticas com você onde quer que você vá. Com uma nova
  //       conta no Bibliando, salve suas estatísticas online e visualize-as em
  //       qualquer dispositivo.
  //     </p>
  //     <div className="mx-auto w-64">
  //       <button
  //         className={`${buttonEnabledClasses} group relative flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
  //         onClick={() => navigate('/perfil')}
  //       >
  //         <span className="absolute inset-y-0 left-0 flex items-center pl-3">
  //           <svg
  //             className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
  //             viewBox="0 0 20 20"
  //             fill="currentColor"
  //             aria-hidden="true"
  //           >
  //             <path
  //               fillRule="evenodd"
  //               d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
  //               clipRule="evenodd"
  //             />
  //           </svg>
  //         </span>
  //         Entrar
  //       </button>
  //     </div>

  //     <h2 className="mt-16 text-xl font-bold dark:text-white md:text-3xl">
  //       Competir contra outros
  //     </h2>
  //     <p className="mx-auto my-12 w-4/5 text-base dark:text-white md:text-xl">
  //       Improve your points by completing each day's game in as few of guesses
  //       as possible. Track your stats compared to others by viewing the global
  //       leaderboard!
  //     </p>
  //     <div className="mx-auto mb-16 w-64">
  //       <button
  //         className={`${buttonEnabledClasses} group relative flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
  //         onClick={() => navigate('/classificacao')}
  //       >
  //         Ver placar
  //       </button>
  //     </div>

  //     <h2 className="mt-16 text-xl font-bold dark:text-white md:text-3xl">
  //       Forme novos grupos de amigos
  //     </h2>
  //     <p className="mx-auto my-12 w-4/5 text-base dark:text-white md:text-xl">
  //       Quer restringir sua visão competitiva? Forme um grupo de Bibliandos e
  //       veja quem conhece melhor a Bíblia!
  //     </p>
  //     <div className="mx-auto mb-16 w-64">
  //       <button
  //         className={`${buttonEnabledClasses} group relative flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
  //         onClick={() => navigate('/grupos')}
  //       >
  //         Ver grupos
  //       </button>
  //     </div>
  //   </div>
  // )

  const launchUpdate = (
    <div className="col-span-10 col-start-2 mb-16 mt-2 overflow-hidden bg-gray-100 text-center dark:bg-slate-800">
      {/* <h2 className="my-8 text-xl font-bold dark:text-white md:text-3xl">
        <s>[Redacted Name]</s> ver AO VIVO!
      </h2> */}
      <p className="mx-auto my-12 w-4/5 text-base dark:text-white md:text-xl">
        Sobre o Bibliando
      </p>
      <div className="mx-auto mb-8 w-64">
        <button
          className={`${buttonEnabledClasses} group relative flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
          onClick={() => navigate('/sobre')}
        >
          Sobre
        </button>
      </div>
    </div>
  )

  return (
    <div className="grid w-full grid-cols-12 gap-4">
      <div className="col-span-10 col-start-2 mt-2 bg-gray-100 text-center dark:bg-slate-800">
        <img src={logo} alt="Bibliando Logo" className="mx-auto my-12 w-12" />
        <h1 className="mb-8 text-2xl font-bold dark:text-white md:text-3xl">
          Histórico de atualizações
        </h1>
        <p className="mx-auto mb-8 w-4/5 dark:text-white">
          Periodicamente atualizamos nosso jogo adicionando novos recursos e
          melhorias. Um resumo do que foi alterado está abaixo.
        </p>
      </div>
      <div className="col-span-10 col-start-2 mb-16 mt-2 overflow-hidden bg-gray-100 text-center dark:bg-slate-800">
        {/* <button
          onClick={() => {
            setUpdate({
              content: todayStatsUpdate,
              title: "Today's Stats Update",
              date: 'July, 2024',
            })
            setIsModalOpen(true)
          }}
          className={`my-8 ${buttonClasses}`}
        >
          July, 2024 - Today's Stats
        </button>

        <hr className="mx-auto w-1/4" />

        <button
          onClick={() => {
            setUpdate({
              content: rebrandUpdate,
              title: 'Rebrand Update',
              date: 'February, 2024',
            })
            setIsModalOpen(true)
          }}
          className={`my-8 ${buttonClasses}`}
        >
          February, 2024 - Rebrand
        </button>

        <hr className="mx-auto w-1/4" />

        <button
          onClick={() => {
            setUpdate({
              content: accountsUpdate,
              title: 'Accounts Update',
              date: 'January, 2024',
            })
            setIsModalOpen(true)
          }}
          className={`my-8 ${buttonClasses}`}
        >
          January, 2024 - Accounts
        </button> */}

        {/* <hr className="mx-auto w-1/4" /> */}

        <button
          onClick={() => {
            setUpdate({
              content: launchUpdate,
              title: 'Lançamento inicial',
              date: 'Novembro, 2024',
            })
            setIsModalOpen(true)
          }}
          className={`my-8 ${buttonClasses}`}
        >
          Novembro de 2024 - Lançamento inicial
        </button>
      </div>

      <HistoryModal
        update={update}
        isOpen={isModalOpen}
        handleClose={() => {
          setUpdate(defaultUpdate)
          setIsModalOpen(false)
        }}
      />
    </div>
  )
}

export default UpdateHistory
