import { ptBR } from 'date-fns/locale'

// export const MAX_CHALLENGES = 6
export const MAX_CHALLENGES = 5
export const ALERT_TIME_MS = 3000
export const LONG_ALERT_TIME_MS = 10000
export const REVEAL_TIME_MS = 350
export const WELCOME_INFO_MODAL_MS = 350
export const DISCOURAGE_IN_APP_BROWSERS = true
export const ENABLE_MIGRATE_STATS = false
export const BLOWFISH_KEY = 'xcQUAHsik#Thq&LG*8es2DsZ$3bw^e'
export const BLOWFISH_IV = '#45XmF^w'
export const ENABLE_ARCHIVED_GAMES = true
export const DATE_LOCALE = ptBR

export const STAT_BONUS_POINTS = {
  WIN_BONUS: 10,
  LOSE_BONUS: 1,
  SUCCESS_RATE_BONUS: 1,
  AVG_GUESS_BONUS: 1,
  STREAK_BONUS: 1,
  // WIN_BONUS: 256,
  // LOSE_BONUS: 32,
  // SUCCESS_RATE_BONUS: 64,
  // AVG_GUESS_BONUS: 1024,
  // STREAK_BONUS: 8,
}
