export const biblia = {
  gn: [
    { capitulo: 1, versiculo: 31 },
    { capitulo: 2, versiculo: 25 },
    { capitulo: 3, versiculo: 24 },
    { capitulo: 4, versiculo: 26 },
    { capitulo: 5, versiculo: 32 },
    { capitulo: 6, versiculo: 22 },
    { capitulo: 7, versiculo: 24 },
    { capitulo: 8, versiculo: 22 },
    { capitulo: 9, versiculo: 29 },
    { capitulo: 10, versiculo: 32 },
    { capitulo: 11, versiculo: 32 },
    { capitulo: 12, versiculo: 20 },
    { capitulo: 13, versiculo: 18 },
    { capitulo: 14, versiculo: 24 },
    { capitulo: 15, versiculo: 21 },
    { capitulo: 16, versiculo: 16 },
    { capitulo: 17, versiculo: 27 },
    { capitulo: 18, versiculo: 33 },
    { capitulo: 19, versiculo: 38 },
    { capitulo: 20, versiculo: 18 },
    { capitulo: 21, versiculo: 34 },
    { capitulo: 22, versiculo: 24 },
    { capitulo: 23, versiculo: 20 },
    { capitulo: 24, versiculo: 67 },
    { capitulo: 25, versiculo: 34 },
    { capitulo: 26, versiculo: 35 },
    { capitulo: 27, versiculo: 46 },
    { capitulo: 28, versiculo: 22 },
    { capitulo: 29, versiculo: 35 },
    { capitulo: 30, versiculo: 43 },
    { capitulo: 31, versiculo: 55 },
    { capitulo: 32, versiculo: 32 },
    { capitulo: 33, versiculo: 20 },
    { capitulo: 34, versiculo: 31 },
    { capitulo: 35, versiculo: 29 },
    { capitulo: 36, versiculo: 43 },
    { capitulo: 37, versiculo: 36 },
    { capitulo: 38, versiculo: 30 },
    { capitulo: 39, versiculo: 23 },
    { capitulo: 40, versiculo: 23 },
    { capitulo: 41, versiculo: 57 },
    { capitulo: 42, versiculo: 38 },
    { capitulo: 43, versiculo: 34 },
    { capitulo: 44, versiculo: 34 },
    { capitulo: 45, versiculo: 28 },
    { capitulo: 46, versiculo: 34 },
    { capitulo: 47, versiculo: 31 },
    { capitulo: 48, versiculo: 22 },
    { capitulo: 49, versiculo: 33 },
    { capitulo: 50, versiculo: 26 },
  ],
  ex: [
    { capitulo: 1, versiculo: 22 },
    { capitulo: 2, versiculo: 25 },
    { capitulo: 3, versiculo: 22 },
    { capitulo: 4, versiculo: 31 },
    { capitulo: 5, versiculo: 23 },
    { capitulo: 6, versiculo: 30 },
    { capitulo: 7, versiculo: 25 },
    { capitulo: 8, versiculo: 32 },
    { capitulo: 9, versiculo: 35 },
    { capitulo: 10, versiculo: 29 },
    { capitulo: 11, versiculo: 10 },
    { capitulo: 12, versiculo: 51 },
    { capitulo: 13, versiculo: 22 },
    { capitulo: 14, versiculo: 31 },
    { capitulo: 15, versiculo: 27 },
    { capitulo: 16, versiculo: 36 },
    { capitulo: 17, versiculo: 16 },
    { capitulo: 18, versiculo: 27 },
    { capitulo: 19, versiculo: 25 },
    { capitulo: 20, versiculo: 26 },
    { capitulo: 21, versiculo: 36 },
    { capitulo: 22, versiculo: 31 },
    { capitulo: 23, versiculo: 33 },
    { capitulo: 24, versiculo: 18 },
    { capitulo: 25, versiculo: 40 },
    { capitulo: 26, versiculo: 37 },
    { capitulo: 27, versiculo: 21 },
    { capitulo: 28, versiculo: 43 },
    { capitulo: 29, versiculo: 46 },
    { capitulo: 30, versiculo: 38 },
    { capitulo: 31, versiculo: 18 },
    { capitulo: 32, versiculo: 35 },
    { capitulo: 33, versiculo: 23 },
    { capitulo: 34, versiculo: 35 },
    { capitulo: 35, versiculo: 35 },
    { capitulo: 36, versiculo: 38 },
    { capitulo: 37, versiculo: 29 },
    { capitulo: 38, versiculo: 31 },
    { capitulo: 39, versiculo: 43 },
    { capitulo: 40, versiculo: 38 },
  ],
  lv: [
    { capitulo: 1, versiculo: 17 },
    { capitulo: 2, versiculo: 16 },
    { capitulo: 3, versiculo: 17 },
    { capitulo: 4, versiculo: 35 },
    { capitulo: 5, versiculo: 19 },
    { capitulo: 6, versiculo: 30 },
    { capitulo: 7, versiculo: 38 },
    { capitulo: 8, versiculo: 36 },
    { capitulo: 9, versiculo: 24 },
    { capitulo: 10, versiculo: 20 },
    { capitulo: 11, versiculo: 47 },
    { capitulo: 12, versiculo: 8 },
    { capitulo: 13, versiculo: 59 },
    { capitulo: 14, versiculo: 57 },
    { capitulo: 15, versiculo: 33 },
    { capitulo: 16, versiculo: 34 },
    { capitulo: 17, versiculo: 16 },
    { capitulo: 18, versiculo: 30 },
    { capitulo: 19, versiculo: 37 },
    { capitulo: 20, versiculo: 27 },
    { capitulo: 21, versiculo: 24 },
    { capitulo: 22, versiculo: 33 },
    { capitulo: 23, versiculo: 44 },
    { capitulo: 24, versiculo: 23 },
    { capitulo: 25, versiculo: 55 },
    { capitulo: 26, versiculo: 46 },
    { capitulo: 27, versiculo: 34 },
  ],
  nm: [
    { capitulo: 1, versiculo: 54 },
    { capitulo: 2, versiculo: 34 },
    { capitulo: 3, versiculo: 51 },
    { capitulo: 4, versiculo: 49 },
    { capitulo: 5, versiculo: 31 },
    { capitulo: 6, versiculo: 27 },
    { capitulo: 7, versiculo: 89 },
    { capitulo: 8, versiculo: 26 },
    { capitulo: 9, versiculo: 23 },
    { capitulo: 10, versiculo: 36 },
    { capitulo: 11, versiculo: 35 },
    { capitulo: 12, versiculo: 16 },
    { capitulo: 13, versiculo: 33 },
    { capitulo: 14, versiculo: 45 },
    { capitulo: 15, versiculo: 41 },
    { capitulo: 16, versiculo: 50 },
    { capitulo: 17, versiculo: 13 },
    { capitulo: 18, versiculo: 32 },
    { capitulo: 19, versiculo: 22 },
    { capitulo: 20, versiculo: 29 },
    { capitulo: 21, versiculo: 35 },
    { capitulo: 22, versiculo: 41 },
    { capitulo: 23, versiculo: 30 },
    { capitulo: 24, versiculo: 25 },
    { capitulo: 25, versiculo: 18 },
    { capitulo: 26, versiculo: 65 },
    { capitulo: 27, versiculo: 23 },
    { capitulo: 28, versiculo: 31 },
    { capitulo: 29, versiculo: 40 },
    { capitulo: 30, versiculo: 16 },
    { capitulo: 31, versiculo: 54 },
    { capitulo: 32, versiculo: 42 },
    { capitulo: 33, versiculo: 56 },
    { capitulo: 34, versiculo: 29 },
    { capitulo: 35, versiculo: 34 },
    { capitulo: 36, versiculo: 13 },
  ],
  dt: [
    { capitulo: 1, versiculo: 46 },
    { capitulo: 2, versiculo: 37 },
    { capitulo: 3, versiculo: 29 },
    { capitulo: 4, versiculo: 49 },
    { capitulo: 5, versiculo: 33 },
    { capitulo: 6, versiculo: 25 },
    { capitulo: 7, versiculo: 26 },
    { capitulo: 8, versiculo: 20 },
    { capitulo: 9, versiculo: 29 },
    { capitulo: 10, versiculo: 22 },
    { capitulo: 11, versiculo: 32 },
    { capitulo: 12, versiculo: 32 },
    { capitulo: 13, versiculo: 18 },
    { capitulo: 14, versiculo: 29 },
    { capitulo: 15, versiculo: 23 },
    { capitulo: 16, versiculo: 22 },
    { capitulo: 17, versiculo: 20 },
    { capitulo: 18, versiculo: 22 },
    { capitulo: 19, versiculo: 21 },
    { capitulo: 20, versiculo: 20 },
    { capitulo: 21, versiculo: 23 },
    { capitulo: 22, versiculo: 30 },
    { capitulo: 23, versiculo: 25 },
    { capitulo: 24, versiculo: 22 },
    { capitulo: 25, versiculo: 19 },
    { capitulo: 26, versiculo: 19 },
    { capitulo: 27, versiculo: 26 },
    { capitulo: 28, versiculo: 68 },
    { capitulo: 29, versiculo: 29 },
    { capitulo: 30, versiculo: 20 },
    { capitulo: 31, versiculo: 30 },
    { capitulo: 32, versiculo: 52 },
    { capitulo: 33, versiculo: 29 },
    { capitulo: 34, versiculo: 12 },
  ],
  js: [
    { capitulo: 1, versiculo: 18 },
    { capitulo: 2, versiculo: 24 },
    { capitulo: 3, versiculo: 17 },
    { capitulo: 4, versiculo: 24 },
    { capitulo: 5, versiculo: 15 },
    { capitulo: 6, versiculo: 27 },
    { capitulo: 7, versiculo: 26 },
    { capitulo: 8, versiculo: 35 },
    { capitulo: 9, versiculo: 27 },
    { capitulo: 10, versiculo: 43 },
    { capitulo: 11, versiculo: 23 },
    { capitulo: 12, versiculo: 24 },
    { capitulo: 13, versiculo: 33 },
    { capitulo: 14, versiculo: 15 },
    { capitulo: 15, versiculo: 63 },
    { capitulo: 16, versiculo: 10 },
    { capitulo: 17, versiculo: 18 },
    { capitulo: 18, versiculo: 28 },
    { capitulo: 19, versiculo: 51 },
    { capitulo: 20, versiculo: 9 },
    { capitulo: 21, versiculo: 45 },
    { capitulo: 22, versiculo: 34 },
    { capitulo: 23, versiculo: 16 },
    { capitulo: 24, versiculo: 33 },
  ],
  jz: [
    { capitulo: 1, versiculo: 36 },
    { capitulo: 2, versiculo: 23 },
    { capitulo: 3, versiculo: 31 },
    { capitulo: 4, versiculo: 24 },
    { capitulo: 5, versiculo: 31 },
    { capitulo: 6, versiculo: 40 },
    { capitulo: 7, versiculo: 25 },
    { capitulo: 8, versiculo: 35 },
    { capitulo: 9, versiculo: 57 },
    { capitulo: 10, versiculo: 18 },
    { capitulo: 11, versiculo: 40 },
    { capitulo: 12, versiculo: 15 },
    { capitulo: 13, versiculo: 25 },
    { capitulo: 14, versiculo: 20 },
    { capitulo: 15, versiculo: 20 },
    { capitulo: 16, versiculo: 31 },
    { capitulo: 17, versiculo: 13 },
    { capitulo: 18, versiculo: 31 },
    { capitulo: 19, versiculo: 30 },
    { capitulo: 20, versiculo: 48 },
    { capitulo: 21, versiculo: 25 },
  ],
  rt: [
    { capitulo: 1, versiculo: 22 },
    { capitulo: 2, versiculo: 23 },
    { capitulo: 3, versiculo: 18 },
    { capitulo: 4, versiculo: 22 },
  ],
  // "1sm": [
  '1sm': [
    { capitulo: 1, versiculo: 28 },
    { capitulo: 2, versiculo: 36 },
    { capitulo: 3, versiculo: 21 },
    { capitulo: 4, versiculo: 22 },
    { capitulo: 5, versiculo: 12 },
    { capitulo: 6, versiculo: 21 },
    { capitulo: 7, versiculo: 17 },
    { capitulo: 8, versiculo: 22 },
    { capitulo: 9, versiculo: 27 },
    { capitulo: 10, versiculo: 27 },
    { capitulo: 11, versiculo: 15 },
    { capitulo: 12, versiculo: 25 },
    { capitulo: 13, versiculo: 23 },
    { capitulo: 14, versiculo: 52 },
    { capitulo: 15, versiculo: 35 },
    { capitulo: 16, versiculo: 23 },
    { capitulo: 17, versiculo: 58 },
    { capitulo: 18, versiculo: 30 },
    { capitulo: 19, versiculo: 24 },
    { capitulo: 20, versiculo: 43 },
    { capitulo: 21, versiculo: 15 },
    { capitulo: 22, versiculo: 23 },
    { capitulo: 23, versiculo: 29 },
    { capitulo: 24, versiculo: 22 },
    { capitulo: 25, versiculo: 44 },
    { capitulo: 26, versiculo: 25 },
    { capitulo: 27, versiculo: 12 },
    { capitulo: 28, versiculo: 25 },
    { capitulo: 29, versiculo: 11 },
    { capitulo: 30, versiculo: 31 },
    { capitulo: 31, versiculo: 13 },
  ],
  '2sm': [
    { capitulo: 1, versiculo: 27 },
    { capitulo: 2, versiculo: 32 },
    { capitulo: 3, versiculo: 39 },
    { capitulo: 4, versiculo: 12 },
    { capitulo: 5, versiculo: 25 },
    { capitulo: 6, versiculo: 23 },
    { capitulo: 7, versiculo: 29 },
    { capitulo: 8, versiculo: 18 },
    { capitulo: 9, versiculo: 13 },
    { capitulo: 10, versiculo: 19 },
    { capitulo: 11, versiculo: 27 },
    { capitulo: 12, versiculo: 31 },
    { capitulo: 13, versiculo: 39 },
    { capitulo: 14, versiculo: 33 },
    { capitulo: 15, versiculo: 37 },
    { capitulo: 16, versiculo: 23 },
    { capitulo: 17, versiculo: 29 },
    { capitulo: 18, versiculo: 33 },
    { capitulo: 19, versiculo: 43 },
    { capitulo: 20, versiculo: 26 },
    { capitulo: 21, versiculo: 22 },
    { capitulo: 22, versiculo: 51 },
    { capitulo: 23, versiculo: 39 },
    { capitulo: 24, versiculo: 25 },
  ],
  '1rs': [
    { capitulo: 1, versiculo: 53 },
    { capitulo: 2, versiculo: 46 },
    { capitulo: 3, versiculo: 28 },
    { capitulo: 4, versiculo: 34 },
    { capitulo: 5, versiculo: 18 },
    { capitulo: 6, versiculo: 38 },
    { capitulo: 7, versiculo: 51 },
    { capitulo: 8, versiculo: 66 },
    { capitulo: 9, versiculo: 28 },
    { capitulo: 10, versiculo: 29 },
    { capitulo: 11, versiculo: 43 },
    { capitulo: 12, versiculo: 33 },
    { capitulo: 13, versiculo: 34 },
    { capitulo: 14, versiculo: 31 },
    { capitulo: 15, versiculo: 34 },
    { capitulo: 16, versiculo: 34 },
    { capitulo: 17, versiculo: 24 },
    { capitulo: 18, versiculo: 46 },
    { capitulo: 19, versiculo: 21 },
    { capitulo: 20, versiculo: 43 },
    { capitulo: 21, versiculo: 29 },
    { capitulo: 22, versiculo: 53 },
  ],
  '2rs': [
    { capitulo: 1, versiculo: 18 },
    { capitulo: 2, versiculo: 25 },
    { capitulo: 3, versiculo: 27 },
    { capitulo: 4, versiculo: 44 },
    { capitulo: 5, versiculo: 27 },
    { capitulo: 6, versiculo: 33 },
    { capitulo: 7, versiculo: 20 },
    { capitulo: 8, versiculo: 29 },
    { capitulo: 9, versiculo: 37 },
    { capitulo: 10, versiculo: 36 },
    { capitulo: 11, versiculo: 21 },
    { capitulo: 12, versiculo: 21 },
    { capitulo: 13, versiculo: 25 },
    { capitulo: 14, versiculo: 29 },
    { capitulo: 15, versiculo: 38 },
    { capitulo: 16, versiculo: 20 },
    { capitulo: 17, versiculo: 41 },
    { capitulo: 18, versiculo: 37 },
    { capitulo: 19, versiculo: 37 },
    { capitulo: 20, versiculo: 21 },
    { capitulo: 21, versiculo: 26 },
    { capitulo: 22, versiculo: 20 },
    { capitulo: 23, versiculo: 37 },
    { capitulo: 24, versiculo: 20 },
    { capitulo: 25, versiculo: 30 },
  ],
  '1cr': [
    { capitulo: 1, versiculo: 54 },
    { capitulo: 2, versiculo: 55 },
    { capitulo: 3, versiculo: 24 },
    { capitulo: 4, versiculo: 43 },
    { capitulo: 5, versiculo: 26 },
    { capitulo: 6, versiculo: 81 },
    { capitulo: 7, versiculo: 40 },
    { capitulo: 8, versiculo: 40 },
    { capitulo: 9, versiculo: 44 },
    { capitulo: 10, versiculo: 14 },
    { capitulo: 11, versiculo: 47 },
    { capitulo: 12, versiculo: 40 },
    { capitulo: 13, versiculo: 14 },
    { capitulo: 14, versiculo: 17 },
    { capitulo: 15, versiculo: 29 },
    { capitulo: 16, versiculo: 43 },
    { capitulo: 17, versiculo: 27 },
    { capitulo: 18, versiculo: 17 },
    { capitulo: 19, versiculo: 19 },
    { capitulo: 20, versiculo: 8 },
    { capitulo: 21, versiculo: 30 },
    { capitulo: 22, versiculo: 19 },
    { capitulo: 23, versiculo: 32 },
    { capitulo: 24, versiculo: 31 },
    { capitulo: 25, versiculo: 31 },
    { capitulo: 26, versiculo: 32 },
    { capitulo: 27, versiculo: 34 },
    { capitulo: 28, versiculo: 21 },
    { capitulo: 29, versiculo: 30 },
  ],
  '2cr': [
    { capitulo: 1, versiculo: 17 },
    { capitulo: 2, versiculo: 18 },
    { capitulo: 3, versiculo: 17 },
    { capitulo: 4, versiculo: 22 },
    { capitulo: 5, versiculo: 14 },
    { capitulo: 6, versiculo: 42 },
    { capitulo: 7, versiculo: 22 },
    { capitulo: 8, versiculo: 18 },
    { capitulo: 9, versiculo: 31 },
    { capitulo: 10, versiculo: 19 },
    { capitulo: 11, versiculo: 23 },
    { capitulo: 12, versiculo: 16 },
    { capitulo: 13, versiculo: 22 },
    { capitulo: 14, versiculo: 15 },
    { capitulo: 15, versiculo: 19 },
    { capitulo: 16, versiculo: 14 },
    { capitulo: 17, versiculo: 19 },
    { capitulo: 18, versiculo: 34 },
    { capitulo: 19, versiculo: 11 },
    { capitulo: 20, versiculo: 37 },
    { capitulo: 21, versiculo: 20 },
    { capitulo: 22, versiculo: 12 },
    { capitulo: 23, versiculo: 21 },
    { capitulo: 24, versiculo: 27 },
    { capitulo: 25, versiculo: 28 },
    { capitulo: 26, versiculo: 23 },
    { capitulo: 27, versiculo: 9 },
    { capitulo: 28, versiculo: 27 },
    { capitulo: 29, versiculo: 36 },
    { capitulo: 30, versiculo: 27 },
    { capitulo: 31, versiculo: 21 },
    { capitulo: 32, versiculo: 33 },
    { capitulo: 33, versiculo: 25 },
    { capitulo: 34, versiculo: 33 },
    { capitulo: 35, versiculo: 27 },
    { capitulo: 36, versiculo: 23 },
  ],
  ed: [
    { capitulo: 1, versiculo: 11 },
    { capitulo: 2, versiculo: 70 },
    { capitulo: 3, versiculo: 13 },
    { capitulo: 4, versiculo: 24 },
    { capitulo: 5, versiculo: 17 },
    { capitulo: 6, versiculo: 22 },
    { capitulo: 7, versiculo: 28 },
    { capitulo: 8, versiculo: 36 },
    { capitulo: 9, versiculo: 15 },
    { capitulo: 10, versiculo: 44 },
  ],
  ne: [
    { capitulo: 1, versiculo: 11 },
    { capitulo: 2, versiculo: 20 },
    { capitulo: 3, versiculo: 32 },
    { capitulo: 4, versiculo: 23 },
    { capitulo: 5, versiculo: 19 },
    { capitulo: 6, versiculo: 19 },
    { capitulo: 7, versiculo: 73 },
    { capitulo: 8, versiculo: 18 },
    { capitulo: 9, versiculo: 38 },
    { capitulo: 10, versiculo: 39 },
    { capitulo: 11, versiculo: 36 },
    { capitulo: 12, versiculo: 47 },
    { capitulo: 13, versiculo: 31 },
  ],
  et: [
    { capitulo: 1, versiculo: 22 },
    { capitulo: 2, versiculo: 23 },
    { capitulo: 3, versiculo: 15 },
    { capitulo: 4, versiculo: 17 },
    { capitulo: 5, versiculo: 14 },
    { capitulo: 6, versiculo: 14 },
    { capitulo: 7, versiculo: 10 },
    { capitulo: 8, versiculo: 17 },
    { capitulo: 9, versiculo: 32 },
    { capitulo: 10, versiculo: 3 },
  ],
  sl: [
    { capitulo: 1, versiculo: 6 },
    { capitulo: 2, versiculo: 12 },
    { capitulo: 3, versiculo: 8 },
    { capitulo: 4, versiculo: 8 },
    { capitulo: 5, versiculo: 12 },
    { capitulo: 6, versiculo: 10 },
    { capitulo: 7, versiculo: 17 },
    { capitulo: 8, versiculo: 9 },
    { capitulo: 9, versiculo: 20 },
    { capitulo: 10, versiculo: 18 },
    { capitulo: 11, versiculo: 7 },
    { capitulo: 12, versiculo: 8 },
    { capitulo: 13, versiculo: 6 },
    { capitulo: 14, versiculo: 7 },
    { capitulo: 15, versiculo: 5 },
    { capitulo: 16, versiculo: 11 },
    { capitulo: 17, versiculo: 15 },
    { capitulo: 18, versiculo: 50 },
    { capitulo: 19, versiculo: 14 },
    { capitulo: 20, versiculo: 9 },
    { capitulo: 21, versiculo: 13 },
    { capitulo: 22, versiculo: 31 },
    { capitulo: 23, versiculo: 6 },
    { capitulo: 24, versiculo: 10 },
    { capitulo: 25, versiculo: 22 },
    { capitulo: 26, versiculo: 12 },
    { capitulo: 27, versiculo: 14 },
    { capitulo: 28, versiculo: 9 },
    { capitulo: 29, versiculo: 11 },
    { capitulo: 30, versiculo: 12 },
    { capitulo: 31, versiculo: 24 },
    { capitulo: 32, versiculo: 11 },
    { capitulo: 33, versiculo: 22 },
    { capitulo: 34, versiculo: 22 },
    { capitulo: 35, versiculo: 28 },
    { capitulo: 36, versiculo: 12 },
    { capitulo: 37, versiculo: 40 },
    { capitulo: 38, versiculo: 22 },
    { capitulo: 39, versiculo: 13 },
    { capitulo: 40, versiculo: 17 },
    { capitulo: 41, versiculo: 13 },
    { capitulo: 42, versiculo: 11 },
    { capitulo: 43, versiculo: 5 },
    { capitulo: 44, versiculo: 26 },
    { capitulo: 45, versiculo: 17 },
    { capitulo: 46, versiculo: 11 },
    { capitulo: 47, versiculo: 9 },
    { capitulo: 48, versiculo: 14 },
    { capitulo: 49, versiculo: 20 },
    { capitulo: 50, versiculo: 23 },
    { capitulo: 51, versiculo: 19 },
    { capitulo: 52, versiculo: 9 },
    { capitulo: 53, versiculo: 6 },
    { capitulo: 54, versiculo: 7 },
    { capitulo: 55, versiculo: 23 },
    { capitulo: 56, versiculo: 13 },
    { capitulo: 57, versiculo: 11 },
    { capitulo: 58, versiculo: 11 },
    { capitulo: 59, versiculo: 17 },
    { capitulo: 60, versiculo: 12 },
    { capitulo: 61, versiculo: 8 },
    { capitulo: 62, versiculo: 12 },
    { capitulo: 63, versiculo: 11 },
    { capitulo: 64, versiculo: 10 },
    { capitulo: 65, versiculo: 13 },
    { capitulo: 66, versiculo: 20 },
    { capitulo: 67, versiculo: 7 },
    { capitulo: 68, versiculo: 35 },
    { capitulo: 69, versiculo: 36 },
    { capitulo: 70, versiculo: 5 },
    { capitulo: 71, versiculo: 24 },
    { capitulo: 72, versiculo: 20 },
    { capitulo: 73, versiculo: 28 },
    { capitulo: 74, versiculo: 23 },
    { capitulo: 75, versiculo: 10 },
    { capitulo: 76, versiculo: 12 },
    { capitulo: 77, versiculo: 20 },
    { capitulo: 78, versiculo: 72 },
    { capitulo: 79, versiculo: 13 },
    { capitulo: 80, versiculo: 19 },
    { capitulo: 81, versiculo: 16 },
    { capitulo: 82, versiculo: 8 },
    { capitulo: 83, versiculo: 18 },
    { capitulo: 84, versiculo: 12 },
    { capitulo: 85, versiculo: 13 },
    { capitulo: 86, versiculo: 17 },
    { capitulo: 87, versiculo: 7 },
    { capitulo: 88, versiculo: 18 },
    { capitulo: 89, versiculo: 52 },
    { capitulo: 90, versiculo: 17 },
    { capitulo: 91, versiculo: 16 },
    { capitulo: 92, versiculo: 15 },
    { capitulo: 93, versiculo: 5 },
    { capitulo: 94, versiculo: 23 },
    { capitulo: 95, versiculo: 11 },
    { capitulo: 96, versiculo: 13 },
    { capitulo: 97, versiculo: 12 },
    { capitulo: 98, versiculo: 9 },
    { capitulo: 99, versiculo: 9 },
  ],
  pv: [
    { capitulo: 1, versiculo: 33 },
    { capitulo: 2, versiculo: 22 },
    { capitulo: 3, versiculo: 35 },
    { capitulo: 4, versiculo: 27 },
    { capitulo: 5, versiculo: 23 },
    { capitulo: 6, versiculo: 35 },
    { capitulo: 7, versiculo: 27 },
    { capitulo: 8, versiculo: 36 },
    { capitulo: 9, versiculo: 18 },
    { capitulo: 10, versiculo: 32 },
    { capitulo: 11, versiculo: 31 },
    { capitulo: 12, versiculo: 28 },
    { capitulo: 13, versiculo: 25 },
    { capitulo: 14, versiculo: 35 },
    { capitulo: 15, versiculo: 33 },
    { capitulo: 16, versiculo: 33 },
    { capitulo: 17, versiculo: 28 },
    { capitulo: 18, versiculo: 24 },
    { capitulo: 19, versiculo: 29 },
    { capitulo: 20, versiculo: 30 },
    { capitulo: 21, versiculo: 31 },
    { capitulo: 22, versiculo: 29 },
    { capitulo: 23, versiculo: 35 },
    { capitulo: 24, versiculo: 34 },
    { capitulo: 25, versiculo: 28 },
    { capitulo: 26, versiculo: 28 },
    { capitulo: 27, versiculo: 27 },
    { capitulo: 28, versiculo: 28 },
    { capitulo: 29, versiculo: 27 },
    { capitulo: 30, versiculo: 33 },
    { capitulo: 31, versiculo: 31 },
  ],
  ec: [
    { capitulo: 1, versiculo: 18 },
    { capitulo: 2, versiculo: 26 },
    { capitulo: 3, versiculo: 22 },
    { capitulo: 4, versiculo: 16 },
    { capitulo: 5, versiculo: 20 },
    { capitulo: 6, versiculo: 12 },
    { capitulo: 7, versiculo: 29 },
    { capitulo: 8, versiculo: 17 },
    { capitulo: 9, versiculo: 18 },
    { capitulo: 10, versiculo: 20 },
    { capitulo: 11, versiculo: 10 },
    { capitulo: 12, versiculo: 14 },
  ],
  ct: [
    { capitulo: 1, versiculo: 17 },
    { capitulo: 2, versiculo: 17 },
    { capitulo: 3, versiculo: 11 },
    { capitulo: 4, versiculo: 16 },
    { capitulo: 5, versiculo: 16 },
    { capitulo: 6, versiculo: 13 },
    { capitulo: 7, versiculo: 13 },
    { capitulo: 8, versiculo: 14 },
  ],
  is: [
    { capitulo: 1, versiculo: 31 },
    { capitulo: 2, versiculo: 22 },
    { capitulo: 3, versiculo: 26 },
    { capitulo: 4, versiculo: 6 },
    { capitulo: 5, versiculo: 30 },
    { capitulo: 6, versiculo: 13 },
    { capitulo: 7, versiculo: 25 },
    { capitulo: 8, versiculo: 22 },
    { capitulo: 9, versiculo: 21 },
    { capitulo: 10, versiculo: 34 },
    { capitulo: 11, versiculo: 16 },
    { capitulo: 12, versiculo: 6 },
    { capitulo: 13, versiculo: 22 },
    { capitulo: 14, versiculo: 32 },
    { capitulo: 15, versiculo: 9 },
    { capitulo: 16, versiculo: 14 },
    { capitulo: 17, versiculo: 14 },
    { capitulo: 18, versiculo: 7 },
    { capitulo: 19, versiculo: 25 },
    { capitulo: 20, versiculo: 6 },
    { capitulo: 21, versiculo: 17 },
    { capitulo: 22, versiculo: 25 },
    { capitulo: 23, versiculo: 18 },
    { capitulo: 24, versiculo: 23 },
    { capitulo: 25, versiculo: 12 },
    { capitulo: 26, versiculo: 21 },
    { capitulo: 27, versiculo: 13 },
    { capitulo: 28, versiculo: 29 },
    { capitulo: 29, versiculo: 24 },
    { capitulo: 30, versiculo: 33 },
    { capitulo: 31, versiculo: 9 },
    { capitulo: 32, versiculo: 20 },
    { capitulo: 33, versiculo: 24 },
    { capitulo: 34, versiculo: 17 },
    { capitulo: 35, versiculo: 10 },
    { capitulo: 36, versiculo: 22 },
    { capitulo: 37, versiculo: 38 },
    { capitulo: 38, versiculo: 22 },
    { capitulo: 39, versiculo: 8 },
    { capitulo: 40, versiculo: 31 },
    { capitulo: 41, versiculo: 29 },
    { capitulo: 42, versiculo: 25 },
    { capitulo: 43, versiculo: 28 },
    { capitulo: 44, versiculo: 28 },
    { capitulo: 45, versiculo: 25 },
    { capitulo: 46, versiculo: 13 },
    { capitulo: 47, versiculo: 15 },
    { capitulo: 48, versiculo: 22 },
    { capitulo: 49, versiculo: 26 },
    { capitulo: 50, versiculo: 11 },
    { capitulo: 51, versiculo: 23 },
    { capitulo: 52, versiculo: 15 },
    { capitulo: 53, versiculo: 12 },
    { capitulo: 54, versiculo: 17 },
    { capitulo: 55, versiculo: 13 },
    { capitulo: 56, versiculo: 12 },
    { capitulo: 57, versiculo: 21 },
    { capitulo: 58, versiculo: 14 },
    { capitulo: 59, versiculo: 21 },
    { capitulo: 60, versiculo: 22 },
    { capitulo: 61, versiculo: 11 },
    { capitulo: 62, versiculo: 12 },
    { capitulo: 63, versiculo: 19 },
    { capitulo: 64, versiculo: 12 },
    { capitulo: 65, versiculo: 25 },
    { capitulo: 66, versiculo: 24 },
  ],
  jr: [
    { capitulo: 1, versiculo: 19 },
    { capitulo: 2, versiculo: 37 },
    { capitulo: 3, versiculo: 25 },
    { capitulo: 4, versiculo: 31 },
    { capitulo: 5, versiculo: 31 },
    { capitulo: 6, versiculo: 30 },
    { capitulo: 7, versiculo: 34 },
    { capitulo: 8, versiculo: 22 },
    { capitulo: 9, versiculo: 26 },
    { capitulo: 10, versiculo: 25 },
    { capitulo: 11, versiculo: 23 },
    { capitulo: 12, versiculo: 17 },
    { capitulo: 13, versiculo: 27 },
    { capitulo: 14, versiculo: 22 },
    { capitulo: 15, versiculo: 21 },
    { capitulo: 16, versiculo: 21 },
    { capitulo: 17, versiculo: 27 },
    { capitulo: 18, versiculo: 23 },
    { capitulo: 19, versiculo: 15 },
    { capitulo: 20, versiculo: 18 },
    { capitulo: 21, versiculo: 14 },
    { capitulo: 22, versiculo: 30 },
    { capitulo: 23, versiculo: 40 },
    { capitulo: 24, versiculo: 10 },
    { capitulo: 25, versiculo: 38 },
    { capitulo: 26, versiculo: 24 },
    { capitulo: 27, versiculo: 22 },
    { capitulo: 28, versiculo: 17 },
    { capitulo: 29, versiculo: 32 },
    { capitulo: 30, versiculo: 24 },
    { capitulo: 31, versiculo: 40 },
    { capitulo: 32, versiculo: 44 },
    { capitulo: 33, versiculo: 26 },
    { capitulo: 33, versiculo: 3 },
    { capitulo: 34, versiculo: 22 },
    { capitulo: 35, versiculo: 19 },
    { capitulo: 36, versiculo: 32 },
    { capitulo: 37, versiculo: 21 },
    { capitulo: 38, versiculo: 28 },
    { capitulo: 39, versiculo: 18 },
    { capitulo: 40, versiculo: 16 },
    { capitulo: 41, versiculo: 18 },
    { capitulo: 42, versiculo: 22 },
    { capitulo: 43, versiculo: 13 },
    { capitulo: 44, versiculo: 30 },
    { capitulo: 45, versiculo: 5 },
    { capitulo: 46, versiculo: 28 },
    { capitulo: 47, versiculo: 7 },
    { capitulo: 48, versiculo: 47 },
    { capitulo: 49, versiculo: 39 },
    { capitulo: 50, versiculo: 46 },
    { capitulo: 51, versiculo: 64 },
    { capitulo: 52, versiculo: 34 },
  ],
  lm: [
    { capitulo: 1, versiculo: 22 },
    { capitulo: 2, versiculo: 22 },
    { capitulo: 3, versiculo: 66 },
    { capitulo: 4, versiculo: 22 },
    { capitulo: 5, versiculo: 22 },
  ],
  ez: [
    { capitulo: 1, versiculo: 28 },
    { capitulo: 2, versiculo: 10 },
    { capitulo: 3, versiculo: 27 },
    { capitulo: 4, versiculo: 17 },
    { capitulo: 5, versiculo: 17 },
    { capitulo: 6, versiculo: 14 },
    { capitulo: 7, versiculo: 27 },
    { capitulo: 8, versiculo: 18 },
    { capitulo: 9, versiculo: 11 },
    { capitulo: 10, versiculo: 22 },
    { capitulo: 11, versiculo: 25 },
    { capitulo: 12, versiculo: 28 },
    { capitulo: 13, versiculo: 23 },
    { capitulo: 14, versiculo: 23 },
    { capitulo: 15, versiculo: 8 },
    { capitulo: 16, versiculo: 63 },
    { capitulo: 17, versiculo: 24 },
    { capitulo: 18, versiculo: 32 },
    { capitulo: 19, versiculo: 14 },
    { capitulo: 20, versiculo: 49 },
    { capitulo: 21, versiculo: 32 },
    { capitulo: 22, versiculo: 31 },
    { capitulo: 23, versiculo: 49 },
    { capitulo: 24, versiculo: 27 },
    { capitulo: 25, versiculo: 17 },
    { capitulo: 26, versiculo: 21 },
    { capitulo: 27, versiculo: 36 },
    { capitulo: 28, versiculo: 26 },
    { capitulo: 29, versiculo: 21 },
    { capitulo: 30, versiculo: 26 },
    { capitulo: 31, versiculo: 18 },
    { capitulo: 32, versiculo: 32 },
    { capitulo: 33, versiculo: 33 },
    { capitulo: 34, versiculo: 31 },
    { capitulo: 35, versiculo: 15 },
    { capitulo: 36, versiculo: 38 },
    { capitulo: 37, versiculo: 28 },
    { capitulo: 38, versiculo: 23 },
    { capitulo: 39, versiculo: 29 },
    { capitulo: 40, versiculo: 49 },
    { capitulo: 41, versiculo: 26 },
    { capitulo: 42, versiculo: 20 },
    { capitulo: 43, versiculo: 27 },
    { capitulo: 44, versiculo: 31 },
    { capitulo: 45, versiculo: 25 },
    { capitulo: 46, versiculo: 24 },
    { capitulo: 47, versiculo: 23 },
    { capitulo: 48, versiculo: 35 },
  ],
  dn: [
    { capitulo: 1, versiculo: 21 },
    { capitulo: 2, versiculo: 49 },
    { capitulo: 3, versiculo: 30 },
    { capitulo: 4, versiculo: 37 },
    { capitulo: 5, versiculo: 31 },
    { capitulo: 6, versiculo: 28 },
    { capitulo: 7, versiculo: 28 },
    { capitulo: 8, versiculo: 27 },
    { capitulo: 9, versiculo: 27 },
    { capitulo: 10, versiculo: 21 },
    { capitulo: 11, versiculo: 45 },
    { capitulo: 12, versiculo: 13 },
  ],
  os: [
    { capitulo: 1, versiculo: 11 },
    { capitulo: 2, versiculo: 23 },
    { capitulo: 3, versiculo: 5 },
    { capitulo: 4, versiculo: 19 },
    { capitulo: 5, versiculo: 15 },
    { capitulo: 6, versiculo: 11 },
    { capitulo: 7, versiculo: 16 },
    { capitulo: 8, versiculo: 14 },
    { capitulo: 9, versiculo: 17 },
    { capitulo: 10, versiculo: 15 },
    { capitulo: 11, versiculo: 12 },
    { capitulo: 12, versiculo: 14 },
    { capitulo: 13, versiculo: 16 },
    { capitulo: 14, versiculo: 9 },
  ],
  jl: [
    { capitulo: 1, versiculo: 20 },
    { capitulo: 2, versiculo: 32 },
    { capitulo: 3, versiculo: 21 },
  ],
  am: [
    { capitulo: 1, versiculo: 15 },
    { capitulo: 2, versiculo: 16 },
    { capitulo: 3, versiculo: 15 },
    { capitulo: 4, versiculo: 13 },
    { capitulo: 5, versiculo: 27 },
    { capitulo: 6, versiculo: 14 },
    { capitulo: 7, versiculo: 17 },
    { capitulo: 8, versiculo: 14 },
    { capitulo: 9, versiculo: 15 },
  ],
  ob: [{ capitulo: 1, versiculo: 21 }],
  jn: [
    { capitulo: 1, versiculo: 17 },
    { capitulo: 2, versiculo: 10 },
    { capitulo: 3, versiculo: 10 },
    { capitulo: 4, versiculo: 11 },
  ],
  mq: [
    { capitulo: 1, versiculo: 16 },
    { capitulo: 2, versiculo: 13 },
    { capitulo: 3, versiculo: 12 },
    { capitulo: 4, versiculo: 13 },
    { capitulo: 5, versiculo: 15 },
    { capitulo: 6, versiculo: 16 },
    { capitulo: 7, versiculo: 20 },
  ],
  na: [
    { capitulo: 1, versiculo: 15 },
    { capitulo: 2, versiculo: 13 },
    { capitulo: 3, versiculo: 19 },
  ],
  hc: [
    { capitulo: 1, versiculo: 17 },
    { capitulo: 2, versiculo: 20 },
    { capitulo: 3, versiculo: 19 },
  ],
  sf: [
    { capitulo: 1, versiculo: 18 },
    { capitulo: 2, versiculo: 15 },
    { capitulo: 3, versiculo: 20 },
  ],
  ag: [
    { capitulo: 1, versiculo: 15 },
    { capitulo: 2, versiculo: 23 },
  ],
  zc: [
    { capitulo: 1, versiculo: 21 },
    { capitulo: 2, versiculo: 13 },
    { capitulo: 3, versiculo: 10 },
    { capitulo: 4, versiculo: 14 },
    { capitulo: 5, versiculo: 11 },
    { capitulo: 6, versiculo: 15 },
    { capitulo: 7, versiculo: 14 },
    { capitulo: 8, versiculo: 23 },
    { capitulo: 9, versiculo: 17 },
    { capitulo: 10, versiculo: 12 },
    { capitulo: 11, versiculo: 17 },
    { capitulo: 12, versiculo: 14 },
    { capitulo: 13, versiculo: 9 },
    { capitulo: 14, versiculo: 21 },
  ],
  ml: [
    { capitulo: 1, versiculo: 14 },
    { capitulo: 2, versiculo: 17 },
    { capitulo: 3, versiculo: 18 },
    { capitulo: 4, versiculo: 6 },
  ],
  mt: [
    { capitulo: 1, versiculo: 25 },
    { capitulo: 2, versiculo: 23 },
    { capitulo: 3, versiculo: 17 },
    { capitulo: 4, versiculo: 25 },
    { capitulo: 5, versiculo: 48 },
    { capitulo: 6, versiculo: 34 },
    { capitulo: 7, versiculo: 29 },
    { capitulo: 8, versiculo: 34 },
    { capitulo: 9, versiculo: 38 },
    { capitulo: 10, versiculo: 42 },
    { capitulo: 11, versiculo: 30 },
    { capitulo: 12, versiculo: 50 },
    { capitulo: 13, versiculo: 58 },
    { capitulo: 14, versiculo: 36 },
    { capitulo: 15, versiculo: 39 },
    { capitulo: 16, versiculo: 28 },
    { capitulo: 17, versiculo: 27 },
    { capitulo: 18, versiculo: 35 },
    { capitulo: 19, versiculo: 30 },
    { capitulo: 20, versiculo: 34 },
    { capitulo: 21, versiculo: 46 },
    { capitulo: 22, versiculo: 46 },
    { capitulo: 23, versiculo: 39 },
    { capitulo: 24, versiculo: 51 },
    { capitulo: 25, versiculo: 46 },
    { capitulo: 26, versiculo: 75 },
    { capitulo: 27, versiculo: 66 },
    { capitulo: 28, versiculo: 20 },
  ],
  mc: [
    { capitulo: 1, versiculo: 45 },
    { capitulo: 2, versiculo: 28 },
    { capitulo: 3, versiculo: 35 },
    { capitulo: 4, versiculo: 41 },
    { capitulo: 5, versiculo: 43 },
    { capitulo: 6, versiculo: 56 },
    { capitulo: 7, versiculo: 37 },
    { capitulo: 8, versiculo: 38 },
    { capitulo: 9, versiculo: 50 },
    { capitulo: 10, versiculo: 52 },
    { capitulo: 11, versiculo: 33 },
    { capitulo: 12, versiculo: 44 },
    { capitulo: 13, versiculo: 37 },
    { capitulo: 14, versiculo: 72 },
    { capitulo: 15, versiculo: 47 },
    { capitulo: 16, versiculo: 20 },
  ],
  lc: [
    { capitulo: 1, versiculo: 80 },
    { capitulo: 2, versiculo: 52 },
    { capitulo: 3, versiculo: 38 },
    { capitulo: 4, versiculo: 44 },
    { capitulo: 5, versiculo: 39 },
    { capitulo: 6, versiculo: 49 },
    { capitulo: 7, versiculo: 50 },
    { capitulo: 8, versiculo: 56 },
    { capitulo: 9, versiculo: 62 },
    { capitulo: 10, versiculo: 42 },
    { capitulo: 11, versiculo: 54 },
    { capitulo: 12, versiculo: 59 },
    { capitulo: 13, versiculo: 35 },
    { capitulo: 14, versiculo: 35 },
    { capitulo: 15, versiculo: 32 },
    { capitulo: 16, versiculo: 31 },
    { capitulo: 17, versiculo: 37 },
    { capitulo: 18, versiculo: 43 },
    { capitulo: 19, versiculo: 48 },
    { capitulo: 20, versiculo: 47 },
    { capitulo: 21, versiculo: 38 },
    { capitulo: 22, versiculo: 71 },
    { capitulo: 23, versiculo: 56 },
    { capitulo: 24, versiculo: 53 },
  ],
  jo: [
    { capitulo: 1, versiculo: 51 },
    { capitulo: 2, versiculo: 25 },
    { capitulo: 3, versiculo: 36 },
    { capitulo: 4, versiculo: 54 },
    { capitulo: 5, versiculo: 47 },
    { capitulo: 6, versiculo: 71 },
    { capitulo: 7, versiculo: 53 },
    { capitulo: 8, versiculo: 59 },
    { capitulo: 9, versiculo: 41 },
    { capitulo: 10, versiculo: 42 },
    { capitulo: 11, versiculo: 57 },
    { capitulo: 12, versiculo: 50 },
    { capitulo: 13, versiculo: 38 },
    { capitulo: 14, versiculo: 31 },
    { capitulo: 15, versiculo: 27 },
    { capitulo: 16, versiculo: 33 },
    { capitulo: 17, versiculo: 26 },
    { capitulo: 18, versiculo: 40 },
    { capitulo: 19, versiculo: 42 },
    { capitulo: 20, versiculo: 31 },
    { capitulo: 21, versiculo: 25 },
  ],
  at: [
    { capitulo: 1, versiculo: 26 },
    { capitulo: 2, versiculo: 47 },
    { capitulo: 3, versiculo: 26 },
    { capitulo: 4, versiculo: 37 },
    { capitulo: 5, versiculo: 42 },
    { capitulo: 6, versiculo: 15 },
    { capitulo: 7, versiculo: 60 },
    { capitulo: 8, versiculo: 40 },
    { capitulo: 9, versiculo: 43 },
    { capitulo: 10, versiculo: 48 },
    { capitulo: 11, versiculo: 30 },
    { capitulo: 12, versiculo: 25 },
    { capitulo: 13, versiculo: 52 },
    { capitulo: 14, versiculo: 28 },
    { capitulo: 15, versiculo: 41 },
    { capitulo: 16, versiculo: 40 },
    { capitulo: 17, versiculo: 34 },
    { capitulo: 18, versiculo: 28 },
    { capitulo: 19, versiculo: 41 },
    { capitulo: 20, versiculo: 38 },
    { capitulo: 21, versiculo: 40 },
    { capitulo: 22, versiculo: 30 },
    { capitulo: 23, versiculo: 35 },
    { capitulo: 24, versiculo: 27 },
    { capitulo: 25, versiculo: 27 },
    { capitulo: 26, versiculo: 32 },
    { capitulo: 27, versiculo: 44 },
    { capitulo: 28, versiculo: 31 },
  ],
  rm: [
    { capitulo: 1, versiculo: 32 },
    { capitulo: 2, versiculo: 29 },
    { capitulo: 3, versiculo: 31 },
    { capitulo: 4, versiculo: 25 },
    { capitulo: 5, versiculo: 21 },
    { capitulo: 6, versiculo: 23 },
    { capitulo: 7, versiculo: 25 },
    { capitulo: 8, versiculo: 39 },
    { capitulo: 9, versiculo: 33 },
    { capitulo: 10, versiculo: 21 },
    { capitulo: 11, versiculo: 36 },
    { capitulo: 12, versiculo: 21 },
    { capitulo: 13, versiculo: 14 },
    { capitulo: 14, versiculo: 23 },
    { capitulo: 15, versiculo: 33 },
    { capitulo: 16, versiculo: 27 },
  ],
  '1co': [
    { capitulo: 1, versiculo: 31 },
    { capitulo: 2, versiculo: 16 },
    { capitulo: 3, versiculo: 23 },
    { capitulo: 4, versiculo: 21 },
    { capitulo: 5, versiculo: 13 },
    { capitulo: 6, versiculo: 20 },
    { capitulo: 7, versiculo: 40 },
    { capitulo: 8, versiculo: 13 },
    { capitulo: 9, versiculo: 27 },
    { capitulo: 10, versiculo: 33 },
    { capitulo: 11, versiculo: 34 },
    { capitulo: 12, versiculo: 31 },
    { capitulo: 13, versiculo: 13 },
    { capitulo: 14, versiculo: 40 },
    { capitulo: 15, versiculo: 58 },
    { capitulo: 16, versiculo: 24 },
  ],
  '2co': [
    { capitulo: 1, versiculo: 24 },
    { capitulo: 2, versiculo: 17 },
    { capitulo: 3, versiculo: 18 },
    { capitulo: 4, versiculo: 18 },
    { capitulo: 5, versiculo: 21 },
    { capitulo: 6, versiculo: 18 },
    { capitulo: 7, versiculo: 16 },
    { capitulo: 8, versiculo: 24 },
    { capitulo: 9, versiculo: 15 },
    { capitulo: 10, versiculo: 18 },
    { capitulo: 11, versiculo: 33 },
    { capitulo: 12, versiculo: 21 },
    { capitulo: 13, versiculo: 14 },
  ],
  gl: [
    { capitulo: 1, versiculo: 24 },
    { capitulo: 2, versiculo: 21 },
    { capitulo: 3, versiculo: 29 },
    { capitulo: 4, versiculo: 31 },
    { capitulo: 5, versiculo: 26 },
    { capitulo: 6, versiculo: 18 },
  ],
  ef: [
    { capitulo: 1, versiculo: 23 },
    { capitulo: 2, versiculo: 22 },
    { capitulo: 3, versiculo: 21 },
    { capitulo: 4, versiculo: 32 },
    { capitulo: 5, versiculo: 33 },
    { capitulo: 6, versiculo: 24 },
  ],
  fp: [
    { capitulo: 1, versiculo: 30 },
    { capitulo: 2, versiculo: 30 },
    { capitulo: 3, versiculo: 21 },
    { capitulo: 4, versiculo: 23 },
  ],
  cl: [
    { capitulo: 1, versiculo: 29 },
    { capitulo: 2, versiculo: 23 },
    { capitulo: 3, versiculo: 25 },
    { capitulo: 4, versiculo: 18 },
  ],
  '1ts': [
    { capitulo: 1, versiculo: 10 },
    { capitulo: 2, versiculo: 20 },
    { capitulo: 3, versiculo: 13 },
    { capitulo: 4, versiculo: 18 },
    { capitulo: 5, versiculo: 28 },
  ],
  '2ts': [
    { capitulo: 1, versiculo: 12 },
    { capitulo: 2, versiculo: 17 },
    { capitulo: 3, versiculo: 18 },
  ],
  '1tm': [
    { capitulo: 1, versiculo: 20 },
    { capitulo: 2, versiculo: 15 },
    { capitulo: 3, versiculo: 16 },
    { capitulo: 4, versiculo: 16 },
    { capitulo: 5, versiculo: 25 },
    { capitulo: 6, versiculo: 21 },
  ],
  '2tm': [
    { capitulo: 1, versiculo: 18 },
    { capitulo: 2, versiculo: 26 },
    { capitulo: 3, versiculo: 17 },
    { capitulo: 4, versiculo: 22 },
  ],
  tt: [
    { capitulo: 1, versiculo: 16 },
    { capitulo: 2, versiculo: 15 },
    { capitulo: 3, versiculo: 15 },
  ],
  fm: [{ capitulo: 1, versiculo: 25 }],
  hb: [
    { capitulo: 1, versiculo: 14 },
    { capitulo: 2, versiculo: 18 },
    { capitulo: 3, versiculo: 19 },
    { capitulo: 4, versiculo: 16 },
    { capitulo: 5, versiculo: 14 },
    { capitulo: 6, versiculo: 20 },
    { capitulo: 7, versiculo: 28 },
    { capitulo: 8, versiculo: 13 },
    { capitulo: 9, versiculo: 28 },
    { capitulo: 10, versiculo: 39 },
    { capitulo: 11, versiculo: 40 },
    { capitulo: 12, versiculo: 29 },
    { capitulo: 13, versiculo: 25 },
  ],
  tg: [
    { capitulo: 1, versiculo: 27 },
    { capitulo: 2, versiculo: 26 },
    { capitulo: 3, versiculo: 18 },
    { capitulo: 4, versiculo: 17 },
    { capitulo: 5, versiculo: 20 },
  ],
  '1pe': [
    { capitulo: 1, versiculo: 25 },
    { capitulo: 2, versiculo: 25 },
    { capitulo: 3, versiculo: 22 },
    { capitulo: 4, versiculo: 19 },
    { capitulo: 5, versiculo: 14 },
  ],
  '2pe': [
    { capitulo: 1, versiculo: 21 },
    { capitulo: 2, versiculo: 22 },
    { capitulo: 3, versiculo: 18 },
  ],
  '1jo': [
    { capitulo: 1, versiculo: 10 },
    { capitulo: 2, versiculo: 29 },
    { capitulo: 3, versiculo: 24 },
    { capitulo: 4, versiculo: 21 },
    { capitulo: 5, versiculo: 21 },
  ],
  '2jo': [{ capitulo: 1, versiculo: 13 }],
  '3jo': [{ capitulo: 1, versiculo: 15 }],
  jd: [{ capitulo: 1, versiculo: 25 }],
  ap: [
    { capitulo: 1, versiculo: 20 },
    { capitulo: 2, versiculo: 29 },
    { capitulo: 3, versiculo: 22 },
    { capitulo: 4, versiculo: 11 },
    { capitulo: 5, versiculo: 14 },
    { capitulo: 6, versiculo: 17 },
    { capitulo: 7, versiculo: 17 },
    { capitulo: 8, versiculo: 13 },
    { capitulo: 9, versiculo: 21 },
    { capitulo: 10, versiculo: 11 },
    { capitulo: 11, versiculo: 19 },
    { capitulo: 12, versiculo: 18 },
    { capitulo: 13, versiculo: 18 },
    { capitulo: 14, versiculo: 20 },
    { capitulo: 15, versiculo: 8 },
    { capitulo: 16, versiculo: 21 },
    { capitulo: 17, versiculo: 18 },
    { capitulo: 18, versiculo: 24 },
    { capitulo: 19, versiculo: 21 },
    { capitulo: 20, versiculo: 15 },
    { capitulo: 21, versiculo: 27 },
    { capitulo: 22, versiculo: 21 },
  ],
}
