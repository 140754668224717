export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = ['Ótimo trabalho!', 'Incrível', 'Bom trabalho!']
export const GAME_COPIED_MESSAGE = 'Jogo copiado para a área de transferência'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Não há letras suficientes'
export const INVALID_REFERENCE_MESSAGE =
  'Deve adivinhar uma referência bíblica válida'
export const HARD_MODE_ALERT_MESSAGE =
  'O modo difícil pode ser ativado apenas no início!'
export const HARD_MODE_DESCRIPTION =
  'Quaisquer dicas reveladas devem ser usadas em palpites subsequentes'
export const AUTO_FILL_MODE_DESCRIPTION =
  'Preencher automaticamente os nomes corretos dos livros nos palpites subsequentes'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'Para melhor visão das cores'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `A palavra era ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Deve usar ${guess} na posição ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Acho que deve conter ${letter}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Deletar'
export const STATISTICS_TITLE = 'Estatísticas'
export const GUESS_DISTRIBUTION_TEXT = 'Distribuição de adivinhação'
export const NEW_WORD_TEXT = 'Novo jogo em'
export const SHARE_TEXT = 'Compartilhar'
export const SHARE_FAILURE_TEXT =
  'Não é possível compartilhar os resultados. Este recurso está disponível apenas em contextos seguros (HTTPS), em alguns ou em todos os navegadores compatíveis.'
export const MIGRATE_BUTTON_TEXT = 'Transferir'
export const MIGRATE_DESCRIPTION_TEXT =
  'Transfira as estatísticas do seu navegador local para um novo dispositivo.'
export const TOTAL_GAMES_TEXT = 'Total de jogos'
export const SUCCESS_RATE_TEXT = 'Taxa de sucesso'
export const CURRENT_STREAK_TEXT = 'Sequência Atual'
export const BEST_STREAK_TEXT = 'Melhor sequência'
export const AVG_NUM_GUESSES_TEXT = 'Número médio de palpites'
export const POINTS_TEXT = 'Total de pontos'
export const DISCOURAGE_IN_APP_BROWSER_TEXT =
  'Você está usando um navegador incorporado e pode ter problemas para compartilhar ou salvar seus resultados. Recomendamos que você use o navegador padrão do seu dispositivo.'
export const UPDATE_TEXT =
  'NOVO! Veja estatísticas globais da solução atual. Clique AQUI para saber mais!'

export const DATEPICKER_TITLE = 'Escolha uma data anterior'
export const DATEPICKER_CHOOSE_TEXT = 'Escolher'
export const DATEPICKER_TODAY_TEXT = 'hoje'
export const ARCHIVE_GAME_DATE_TEXT = 'Data do jogo'

export const VERSE_TEXT_BUTTON = 'Ler'
